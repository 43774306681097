import { DialogService } from '@common/dialogs';
import { AppConfigService } from '@core';
import { isLocalLocation, openUrl } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/app-config/app-config.service";
import * as i2 from "../../../../common/dialogs/services/dialog/dialog.service";
var VersionService = /** @class */ (function () {
    function VersionService(appConfigService, dialogService) {
        this.appConfigService = appConfigService;
        this.dialogService = dialogService;
    }
    VersionService.prototype.checkWrongProjectVersionLoaded = function (project, environment) {
        if (isLocalLocation()) {
            return;
        }
        var usedVersion = this.appConfigService.version;
        var urlRegex = window.location.href.match(/^(https?:\/\/[^\/]+)(?:\/(v.+))?\/((?:app|builder)\/.+)$/);
        if (!environment || !environment.version || environment.version == usedVersion || !urlRegex) {
            return;
        }
        var redirect = urlRegex[1] + "/v" + environment.version + "/" + urlRegex[3];
        var forceProjectVersion = !project.params['optional_project_version'];
        if (forceProjectVersion) {
            openUrl(redirect);
            return;
        }
        this.dialogService
            .warning({
            title: 'Reload interface version',
            description: "\n            Your current App environment is using interface version <strong>" + environment.version + "</strong>, but you are opening\n            project with version <strong>" + usedVersion + "</strong>.\n            Would you like to reload it with project version <strong>" + environment.version + "</strong>?\n          "
        })
            .subscribe(function (confirm) {
            if (!confirm) {
                return;
            }
            openUrl(redirect);
        });
    };
    VersionService.ngInjectableDef = i0.defineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.inject(i1.AppConfigService), i0.inject(i2.DialogService)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
export { VersionService };

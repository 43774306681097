import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';

import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class CustomMenuItem extends MenuItem {
  public type = MenuItemType.Custom;
  public title: string;
  public customView: string;
  public inputs: Input[] = [];

  deserialize(data: Object): CustomMenuItem {
    super.deserialize(data);
    this.title = this.params['title'];
    this.customView = this.params['custom_view'];

    if (this.params['inputs']) {
      this.inputs = this.params['inputs'].map(item => new Input().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    data['params']['custom_view'] = this.customView;
    data['params']['inputs'] = this.inputs.map(item => item.serialize());
    return data;
  }

  copyFrom(item: CustomMenuItem) {
    super.copyFrom(item);
    this.title = item.title;
    this.customView = item.customView;
    this.inputs = cloneDeep(item.inputs);
  }

  clone(): CustomMenuItem {
    const instance = new CustomMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Custom, CustomMenuItem);

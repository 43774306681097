import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectSettingsName,
  ProjectSettingsService
} from '@modules/projects';
import { defaultFontName, projectThemes } from '@modules/theme';
import { controlValue, isColorHex, isSet } from '@shared';

import { SignUpFieldArray } from './sign-up-field.array';

@Injectable()
export class ProjectSettingsUpdateForm extends FormGroup {
  controls: {
    accent_color: FormControl;
    accent_color_custom_enabled: FormControl;
    accent_color_custom: FormControl;
    font_regular: FormControl;
    font_heading: FormControl;
    collaboration_enabled: FormControl;
    activity_log_enabled: FormControl;
    default_theme: FormControl;
    append_scripts: FormControl;
    append_styles: FormControl;
    sign_up_fields: SignUpFieldArray;
  };

  projectSettings: AllProjectSettings;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsService: ProjectSettingsService,
    private projectSettingsStore: ProjectSettingsStore,
    private projectPropertyStore: ProjectPropertyStore,
    private formUtils: FormUtils
  ) {
    super({
      accent_color: new FormControl(''),
      accent_color_custom_enabled: new FormControl(false),
      accent_color_custom: new FormControl('#2B50ED'),
      font_regular: new FormControl(defaultFontName),
      font_heading: new FormControl(defaultFontName),
      collaboration_enabled: new FormControl(false),
      activity_log_enabled: new FormControl(false),
      default_theme: new FormControl(null),
      append_scripts: new FormControl(''),
      append_styles: new FormControl(''),
      sign_up_fields: new SignUpFieldArray([])
    });
  }

  init(projectSettings: AllProjectSettings) {
    this.projectSettings = projectSettings;

    if (projectSettings) {
      if (isColorHex(projectSettings.accentColor)) {
        this.controls.accent_color.patchValue('');
        this.controls.accent_color_custom_enabled.patchValue(true);
        this.controls.accent_color_custom.patchValue(projectSettings.accentColor);
      } else {
        this.controls.accent_color.patchValue(isSet(projectSettings.accentColor) ? projectSettings.accentColor : '');
        this.controls.accent_color_custom_enabled.patchValue(false);
        this.controls.accent_color_custom.patchValue('#2B50ED');
      }

      if (isSet(projectSettings.fontRegular)) {
        this.controls.font_regular.patchValue(projectSettings.fontRegular);
      }

      if (isSet(projectSettings.fontHeading)) {
        this.controls.font_heading.patchValue(projectSettings.fontHeading);
      }

      this.controls.collaboration_enabled.patchValue(projectSettings.collaborationEnabled);
      this.controls.activity_log_enabled.patchValue(projectSettings.activityLogEnabled);
      this.controls.default_theme.patchValue(
        projectSettings.defaultTheme ? projectSettings.defaultTheme : projectThemes[0]
      );
      this.controls.append_scripts.patchValue(projectSettings.appendScripts);
      this.controls.append_styles.patchValue(projectSettings.appendStyles);

      this.controls.sign_up_fields.deserialize(projectSettings.signUpFields);
    }

    this.markAsPristine();
  }

  getAccentColor(): string {
    if (this.controls.accent_color_custom_enabled.value) {
      return this.controls.accent_color_custom.value;
    } else {
      return this.controls.accent_color.value;
    }
  }

  getAccentColor$(): Observable<string> {
    return combineLatest(
      controlValue(this.controls.accent_color_custom_enabled),
      controlValue(this.controls.accent_color_custom),
      controlValue(this.controls.accent_color)
    ).pipe(
      map(([colorCustomEnabled, colorCustom, color]) => {
        if (colorCustomEnabled) {
          return colorCustom;
        } else {
          return color;
        }
      })
    );
  }

  getInstance$(): Observable<AllProjectSettings> {
    return combineLatest(
      controlValue(this),
      this.projectPropertyStore
        .get()
        .pipe(map(properties => properties.filter(item => item.type == ProjectPropertyType.User)))
    ).pipe(
      map(([_, properties]) => {
        const instance = new AllProjectSettings();

        instance.accentColor = this.getAccentColor();
        instance.collaborationEnabled = this.controls.collaboration_enabled.value;
        instance.activityLogEnabled = this.controls.activity_log_enabled.value;
        instance.defaultTheme = this.controls.default_theme.value;
        instance.appendScripts = isSet(this.controls.append_scripts.value)
          ? this.controls.append_scripts.value
          : undefined;
        instance.appendStyles = isSet(this.controls.append_styles.value)
          ? this.controls.append_styles.value
          : undefined;

        if (this.controls.font_regular.value != defaultFontName) {
          instance.fontRegular = this.controls.font_regular.value;
        } else {
          instance.fontRegular = undefined;
        }

        if (this.controls.font_heading.value != defaultFontName) {
          instance.fontHeading = this.controls.font_heading.value;
        } else {
          instance.fontHeading = undefined;
        }

        instance.signUpFields = this.controls.sign_up_fields
          .serialize()
          .filter(field => properties.some(item => item.uid == field.property));

        return instance;
      })
    );
  }

  getInstanceFirst(): Observable<AllProjectSettings> {
    return this.getInstance$().pipe(first());
  }

  submit(): Observable<AllProjectSettings> {
    return this.getInstanceFirst().pipe(
      switchMap(allProjectSettings => {
        if (!allProjectSettings) {
          return of(undefined);
        }

        const submitSettings = [
          ProjectSettingsName.AccentColor,
          ProjectSettingsName.DefaultTheme,
          ProjectSettingsName.AccentColor,
          ProjectSettingsName.FontRegular,
          ProjectSettingsName.FontHeading,
          ProjectSettingsName.AppendScripts,
          ProjectSettingsName.AppendStyles,
          ProjectSettingsName.CollaborationEnabled,
          ProjectSettingsName.ActivityLogEnabled,
          ProjectSettingsName.SignUpFields
        ];
        const prevProjectSettings = this.projectSettings.serialize(submitSettings);
        const newProjectSettings = allProjectSettings.serialize(submitSettings);
        const updateProjectSettings = newProjectSettings
          .filter(newItem => {
            const prevItem = prevProjectSettings.find(item => item.name == newItem.name);
            return prevItem ? !isEqual(newItem.value, prevItem.value) : true;
          })
          .map(item => {
            if (!isSet(item.value)) {
              item.deleted = true;
            }

            return item;
          });

        if (!updateProjectSettings.length) {
          return of(undefined);
        }

        return this.projectSettingsService.createBulk(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          updateProjectSettings
        );
      }),
      filter(item => item),
      switchMap(() => this.projectSettingsStore.getFirst(true)),
      map(result => new AllProjectSettings().deserialize(result)),
      tap(result => {
        this.projectSettings = result;
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { TokenStructureItem } from '@modules/tokens';

@Component({
  selector: 'app-token-structure-section',
  templateUrl: './token-structure-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenStructureSectionComponent implements OnInit {
  @Input() section: TokenStructureItem;
  @Input() openedInitial = false;
  @Output() inserted = new EventEmitter<TokenStructureItem>();
  @Output() valueChanged = new EventEmitter<{ item: TokenStructureItem; value: string }>();

  opened: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.opened = this.openedInitial || !this.section.label;
  }

  setOpened(value: boolean) {
    this.opened = value;
    this.cd.markForCheck();
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }
}

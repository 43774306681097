export const mixpanelResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'user_events',
    verbose_name: 'User Events',
    verbose_name_plural: 'User Events',
    fields: [
      {
        name: 'time',
        verbose_name: 'time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'distinct_id',
        verbose_name: 'distinct id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$browser',
        verbose_name: '$browser',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$browser_version',
        verbose_name: '$browser version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$city',
        verbose_name: '$city',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$current_url',
        verbose_name: '$current url',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$device_id',
        verbose_name: '$device id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$initial_referrer',
        verbose_name: '$initial referrer',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$initial_referring_domain',
        verbose_name: '$initial referring domain',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$insert_id',
        verbose_name: '$insert id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$lib_version',
        verbose_name: '$lib version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$os',
        verbose_name: '$os',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$referrer',
        verbose_name: '$referrer',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$referring_domain',
        verbose_name: '$referring domain',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$region',
        verbose_name: '$region',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$screen_height',
        verbose_name: '$screen height',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '$screen_width',
        verbose_name: '$screen width',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'mp_country_code',
        verbose_name: 'mp country code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'mp_lib',
        verbose_name: 'mp lib',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'mp_processing_time_ms',
        verbose_name: 'mp processing time ms',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'event',
        verbose_name: 'event',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: '__user_id__',
        verbose_name: '  user id  ',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'time',
    relations: [],
    description: 'Returns data of tracked user events over a time period.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://data.mixpanel.com/api/2.0/export/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'from_date',
            value: '{{params.from_date}}'
          },
          {
            name: 'to_date',
            value: '{{params.to_date}}'
          },
          {
            name: 'where',
            value: 'properties["$distinct_id"]=="{{params.__user_id__}}"'
          },
          {
            name: 'event',
            value: '{{params.event}}'
          }
        ],
        response_type: 'text',
        response_transformer:
          "function myFunction(x) {\n  result = x['properties'];\n  result['event'] = x['event'];\n  return result;\n}\n\nfunction addValueId(obj, key, id) {\n    obj[key] = id;\n    return obj;\n}\n\nconst array = data.split(/[\\r\\n]+/)\n                 .map(entry => JSON.parse(entry)).map(myFunction);\nreturn array.map(obj => addValueId(obj, \"__user_id__\", \"1\"));\n",
        response_path: '',
        request_tokens: {}
      },
      pagination: null,
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      sorting: false
    },
    get_parameters: [
      {
        name: '__user_id__',
        verbose_name: '__user_id__',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'from_date',
        verbose_name: 'from_date',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'to_date',
        verbose_name: 'to_date',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'event',
        verbose_name: 'event',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    create_parameters: [],
    update_parameters: [],
    delete_parameters: [],
    params: {}
  }
];

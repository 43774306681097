import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ColorsSharedModule } from '@modules/colors-shared';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldsModule } from '@modules/fields';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { CalendarDayComponent } from './components/calendar-day/calendar-day.component';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { CalendarItemComponent } from './components/calendar-item/calendar-item.component';
import { CalendarMonthComponent } from './components/calendar-month/calendar-month.component';
import { CalendarWeekComponent } from './components/calendar-week/calendar-week.component';
import { CalendarYearComponent } from './components/calendar-year/calendar-year.component';
import { CalendarComponent } from './components/calendar/calendar.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    SharedModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    TipsModule,
    DialogsModule,
    ModelQueriesModule,
    ExportModule,
    AnalyticsModule,
    ThemeComponentsModule,
    ViewsComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    CalendarComponent,
    CalendarHeaderComponent,
    CalendarDayComponent,
    CalendarWeekComponent,
    CalendarMonthComponent,
    CalendarYearComponent,
    CalendarItemComponent
  ],
  exports: [CalendarComponent]
})
export class CalendarModule {}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./project-users-item-stub.component";
var styles_ProjectUsersItemStubComponent = [];
var RenderType_ProjectUsersItemStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectUsersItemStubComponent, data: {} });
export { RenderType_ProjectUsersItemStubComponent as RenderType_ProjectUsersItemStubComponent };
function View_ProjectUsersItemStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Administrators"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 1, 0, currVal_0); }); }
export function View_ProjectUsersItemStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "td", [["class", "table__column table__column_left-padding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["John Cooper"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectUsersItemStubComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["November 30, 2020"])), (_l()(), i0.ɵeld(11, 0, null, null, 0, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "td", [["class", "table__column"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.group; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.animating; _ck(_v, 8, 0, currVal_2); }); }
export function View_ProjectUsersItemStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-users-item-stub", [], null, null, null, View_ProjectUsersItemStubComponent_0, RenderType_ProjectUsersItemStubComponent)), i0.ɵdid(1, 114688, null, 0, i2.ProjectUsersItemStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectUsersItemStubComponentNgFactory = i0.ɵccf("app-project-users-item-stub, [app-project-users-item-stub]", i2.ProjectUsersItemStubComponent, View_ProjectUsersItemStubComponent_Host_0, { group: "group", animating: "animating" }, {}, []);
export { ProjectUsersItemStubComponentNgFactory as ProjectUsersItemStubComponentNgFactory };

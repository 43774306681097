<ng-container *ngIf="visible || element.loadInvisible || (customizeEnabled$ | async)">
  <div
    class="placeholder"
    [class.placeholder_active]="customizeService.layoutEnabled$ | async"
    [style.display]="element.loadInvisible && !visible && !(customizeEnabled$ | async) ? 'none' : null"
    appDropList
    [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
    [appDropListEnterPredicate]="canEnter"
    [appDropListData]="element.children"
    [appDropListSwapDistance]="20"
    [appDropListAreaMarginForward]="[-10, 0, 0, 0]"
    [appDropListAreaMarginBackward]="[0, 0, -10, 0]"
    [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
    [appDropListOutsideIndicator]="true"
    (appDropListDragStarted)="updateElementStates()"
    (appDropListEntered)="updateElementStates()"
    (appDropListExited)="updateElementStates()"
    (appDropListSorted)="updateElementStates()"
    (appDropListDragged)="updateElementStatesOnStable()"
    (appDropListDropped)="dragDrop($event)"
    appElementGroupsContainer
  >
    <app-auto-element
      *ngFor="let item of element.children; let i = index; trackBy: trackElement"
      appDrag
      [appDragData]="item"
      [element]="item"
      [context]="context"
      [customizing]="customizeService.layoutEnabled$ | async"
      [accentColor]="'accentColor' | appThemeColorHexOption | async"
      (duplicatedRequested)="duplicateItem(i)"
      (replaceRequested)="replaceItem(i, $event)"
      (deleteRequested)="deleteItem(item)"
      (moveToRequested)="moveItemTo(item, $event)"
      (visibleUpdated)="updateElementStates()"
    >
      <app-element-placeholder-stub
        *appDragPlaceholder
        [elementType]="item.type"
        class="element-placeholder"
      ></app-element-placeholder-stub>

      <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
    </app-auto-element>
    <div class="placeholder__border"></div>
  </div>
</ng-container>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isFunction from 'lodash/isFunction';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DraftChangesService } from '@modules/customize-utils';
import { ModelDescription } from '@modules/models';
import { JET_APP_RESOURCE, Resource } from '@modules/projects';
import { HttpMethod } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { QueryName } from '../../data/query-name';
var ModelApiComponent = /** @class */ (function () {
    function ModelApiComponent(resourceControllerService, draftChangesService, cd) {
        this.resourceControllerService = resourceControllerService;
        this.draftChangesService = draftChangesService;
        this.cd = cd;
        this.getPagination = true;
        this.popup = false;
        this.allQueries = [
            {
                name: QueryName.Get,
                label: 'List',
                method: HttpMethod.GET,
                color: 'blue'
            },
            {
                name: QueryName.GetDetail,
                label: 'Retrieve',
                method: HttpMethod.GET,
                color: 'blue'
            },
            {
                name: QueryName.Create,
                label: 'Create',
                method: HttpMethod.POST,
                color: 'green'
            },
            {
                name: QueryName.Update,
                label: 'Update',
                method: HttpMethod.PATCH,
                color: 'orange'
            },
            {
                name: QueryName.Delete,
                label: 'Delete',
                method: HttpMethod.DELETE,
                color: 'red'
            },
            // {
            //   name: QueryName.Aggregate,
            //   label: 'Aggregate'
            // },
            // {
            //   name: QueryName.Group,
            //   label: 'Group'
            // },
            {
                name: QueryName.Reload,
                label: 'Sync structure',
                method: HttpMethod.POST,
                color: 'green'
            }
        ];
        this.queries = [];
        this.currentQueryName = QueryName.Get;
        this.hasDraftChanges = false;
        this.queryNames = QueryName;
    }
    ModelApiComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.draftChangesService
            .getDraftChanges$({
            resource: this.resource ? this.resource.uniqueName : undefined,
            modelId: this.modelDescription ? this.modelDescription.modelId : undefined
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (changes) {
            _this.hasDraftChanges = changes.filter(function (item) { return item.count; }).length > 0;
            _this.cd.markForCheck();
        });
    };
    ModelApiComponent.prototype.ngOnDestroy = function () { };
    ModelApiComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.resource || changes.modelDescription) {
            this.queries = this.allQueries
                .filter(function (item) {
                var controller = _this.resourceControllerService.getForModelDescription(_this.resource, _this.modelDescription, true);
                if (item.name == QueryName.Get) {
                    return (!!controller.modelGet && _this.modelDescription.getQuery && _this.modelDescription.getQuery.isConfigured());
                }
                else if (item.name == QueryName.GetDetail) {
                    if (_this.resource.uniqueName == JET_APP_RESOURCE) {
                        return true;
                    }
                    else if (_this.modelDescription.getDetailQuery) {
                        return (!!controller.modelGetDetail &&
                            _this.modelDescription.getDetailQuery &&
                            _this.modelDescription.getDetailQuery.isConfigured());
                    }
                    else if (_this.modelDescription.getQuery) {
                        return (!!controller.modelGetDetail &&
                            _this.modelDescription.getQuery &&
                            _this.modelDescription.getQuery.isConfigured());
                    }
                    else {
                        return false;
                    }
                }
                else if (item.name == QueryName.Create) {
                    return (!!controller.modelCreate &&
                        _this.modelDescription.createQuery &&
                        _this.modelDescription.createQuery.isConfigured());
                }
                else if (item.name == QueryName.Update) {
                    return (!!controller.modelUpdate &&
                        _this.modelDescription.updateQuery &&
                        _this.modelDescription.updateQuery.isConfigured());
                }
                else if (item.name == QueryName.Delete) {
                    return (!!controller.modelDelete &&
                        _this.modelDescription.deleteQuery &&
                        _this.modelDescription.deleteQuery.isConfigured());
                    // } else if (item.name == QueryName.Aggregate) {
                    //   return !!controller.modelAggregate;
                    // } else if (item.name == QueryName.Group) {
                    //   return !!controller.modelGroup;
                }
                else {
                    return true;
                }
            })
                .map(function (queryItem) {
                var parameters = _this.queriesApiParameters
                    ? _this.queriesApiParameters.filter(function (item) { return !item.queries || item.queries.includes(queryItem.name); })
                    : undefined;
                return __assign({}, queryItem, (parameters
                    ? {
                        parameters: parameters.map(function (item) {
                            if (isFunction(item.parameter)) {
                                return item.parameter(queryItem.name);
                            }
                            else {
                                return item.parameter;
                            }
                        })
                    }
                    : undefined));
            });
            this.currentQueryName = this.queries[0].name;
        }
    };
    ModelApiComponent.prototype.setCurrentQueryName = function (value) {
        this.currentQueryName = value;
        this.cd.markForCheck();
    };
    Object.defineProperty(ModelApiComponent.prototype, "currentQuery", {
        get: function () {
            var _this = this;
            return this.queries.find(function (item) { return item.name == _this.currentQueryName; });
        },
        enumerable: true,
        configurable: true
    });
    return ModelApiComponent;
}());
export { ModelApiComponent };

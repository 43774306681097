<table class="table table_grid table_compact">
  <thead>
    <tr class="table__heading-row">
      <th
        *ngFor="let field of modelDescription.fields; let i = index"
        class="table__heading-column"
        [class.table__heading-column_disabled]="false"
      >
        <div class="table__heading-column-inner">
          <div class="table__heading-column-main">
            <span class="table__heading-icon" [ngClass]="'icon-' + field.item.fieldDescription.icon"></span>
            <span>{{ field.name }}</span>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let item of rows; trackBy: rowTrackByFn; let i = index"
      app-project-resource-data-templates-table-item
      class="table__row"
      [row]="item"
      [columns]="columns"
    ></tr>
  </tbody>
</table>

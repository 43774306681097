import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UniqueIdToken } from '@common/unique-id';
import { jetAppResource, jetAppStorage, Resource } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
import { StorageUploadPopupController } from '@modules/storages-components';
import { StorageService } from '@modules/storages-queries';
import { getExtension, getFilenameWithExtension, isSet, stripStart, TypedChanges } from '@shared';

interface Icon {
  name: string;
  path: string;
  url: string;
}

@Component({
  selector: 'app-icon-selector-images',
  templateUrl: './icon-selector-images.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorImagesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() current: string;
  @Input() search = '';
  @Input() loading = false;
  @Output() selected = new EventEmitter<string>();

  resource: Resource;
  storage: Storage;
  extensions = ['.png', '.gif', '.svg'];
  idToken = new UniqueIdToken();
  iconsLoading = true;
  icons: Icon[] = [];
  filteredIcons: Icon[] = [];
  draggingOver = false;

  constructor(
    private storageService: StorageService,
    private storageUploadPopupController: StorageUploadPopupController,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.resource = jetAppResource;
    this.storage = jetAppStorage;

    this.loadIcons();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<IconSelectorImagesComponent>): void {
    if (changes.search) {
      this.updateFilteredIcons();
    }
  }

  loadIcons() {
    const query = new StorageQuery();
    const action = this.storage.autoActions().find(item => item.name == 'upload');
    const path = 'icons';

    query.queryType = QueryType.Simple;
    query.simpleQuery = new query.simpleQueryClass();
    query.simpleQuery.name = action.uniqueName;

    this.storageService
      .getStorageObjects(this.resource, this.storage, query, path)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.icons = result
            ? result.objects
                .filter(item => {
                  const extension = getExtension(item.path);
                  return isSet(extension) && this.extensions.includes(`.${extension.toLowerCase()}`);
                })
                .map(item => {
                  return {
                    name: getFilenameWithExtension(item.path),
                    path: stripStart(item.path, path),
                    url: item.url
                  };
                })
            : [];
          this.iconsLoading = false;
          this.cd.markForCheck();
          this.updateFilteredIcons();
        },
        () => {
          this.iconsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateFilteredIcons() {
    if (!isSet(this.search)) {
      this.filteredIcons = this.icons;
    } else {
      this.filteredIcons = this.icons.filter(item => item.name.toLowerCase().includes(this.search));
    }

    this.cd.markForCheck();
  }

  onFileChanged(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const files = Array.from(el.files);

    el.value = null;

    this.uploadFiles(files);
  }

  uploadFiles(files: File[]) {
    this.storageUploadPopupController
      .upload({
        files: files,
        resource: this.resource,
        storage: this.storage,
        path: ['icons'],
        resolver: this.resolver,
        injector: this.injector
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadIcons();
      });
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const files = Array.from(e.dataTransfer.files);

    this.draggingOver = false;
    this.cd.markForCheck();

    if (!files.length) {
      return;
    }

    this.uploadFiles(files);
  }
}

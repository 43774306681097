var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Input } from '@modules/fields';
import { CustomMenuItem } from '@modules/menu';
import { FieldInputControl, InputFieldProvider, parametersToProviderItemsFlat } from '@modules/parameters';
import { CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';
var CustomizeBarPagesEditCustomControl = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditCustomControl, _super);
    function CustomizeBarPagesEditCustomControl(injector) {
        var _this = _super.call(this, {
            title: new FormControl('Custom item', Validators.required),
            custom_view: new FormControl(null),
            inputs: new FormControl([]),
            visible_input: new FieldInputControl({ name: 'value' })
        }) || this;
        _this.injector = injector;
        _this.inputFieldProvider = new InputFieldProvider();
        return _this;
    }
    CustomizeBarPagesEditCustomControl.inject = function (injector) {
        return Injector.create({
            providers: [
                {
                    provide: CustomizeBarPagesEditCustomControl,
                    useFactory: function () {
                        return new CustomizeBarPagesEditCustomControl(injector);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(CustomizeBarPagesEditCustomControl);
    };
    CustomizeBarPagesEditCustomControl.prototype.deserialize = function (item) {
        this.menuItem = item;
        this.controls.title.patchValue(item.title);
        this.controls.custom_view.patchValue(item.customView);
        this.controls.inputs.patchValue(item.inputs);
        this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serialize() : {});
        this.markAsPristine();
    };
    CustomizeBarPagesEditCustomControl.prototype.setInputsParameters = function (parameters) {
        var items = parametersToProviderItemsFlat((parameters || []).filter(function (item) { return item.name; }));
        this.inputFieldProvider.setProvider(items);
    };
    CustomizeBarPagesEditCustomControl.prototype.serialize = function () {
        var instance = new CustomMenuItem().deserialize(this.menuItem.serialize());
        instance.title = this.controls.title.value;
        instance.customView = this.controls.custom_view.value;
        instance.inputs = this.controls.inputs.value;
        instance.visibleInput = this.controls.visible_input.value
            ? new Input().deserialize(this.controls.visible_input.value)
            : undefined;
        return instance;
    };
    return CustomizeBarPagesEditCustomControl;
}(CustomizeBarPagesEditMenuItemControl));
export { CustomizeBarPagesEditCustomControl };
menuItemControls.push({ menuItem: CustomMenuItem, control: CustomizeBarPagesEditCustomControl });

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { GoogleAnalyticsService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { MetaService } from '@modules/meta';
import { ProjectInviteService, ProjectPublicInviteService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { LoginBaseComponent } from '../login/login-base.component';
import { LoginForm } from '../login/login.form';
import { RegisterForm } from './register.form';
var RegisterComponent = /** @class */ (function (_super) {
    __extends(RegisterComponent, _super);
    function RegisterComponent(form, loginForm, routing, currentUserStore, activatedRoute, analyticsService, googleAnalyticsService, appConfigService, authService, notificationService, metaService, projectsStore, apiService, projectInviteService, projectPublicInviteService, cd) {
        var _this = _super.call(this, loginForm, routing, currentUserStore, activatedRoute, analyticsService, googleAnalyticsService, appConfigService, authService, notificationService, metaService, projectsStore, apiService, projectInviteService, projectPublicInviteService, cd) || this;
        _this.form = form;
        _this.submitLoading = false;
        _this.domainLoading = false;
        _this.customFields = [];
        return _this;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.metaService.set({ title: 'Sign Up' });
        this.domainLoading = true;
        this.cd.markForCheck();
        combineLatest(this.activatedRoute.params, this.activatedRoute.data, this.domain$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var params = _a[0], data = _a[1], domain = _a[2];
            var publicSettings = data['public_settings'];
            var customFields = publicSettings ? publicSettings.signUpFields : [];
            _this.domainLoading = false;
            _this.form.init(customFields);
            _this.customFields = customFields;
            _this.cd.markForCheck();
            if (!params['project'] && domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
                _this.routing.navigate(['/register', domain.defaultProject], { queryParamsHandling: 'merge' });
            }
            else if (domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn) {
                var result = _this.ssoLogin(domain.ssoSettings[0]);
                if (result.externalRedirect) {
                    _this.domainLoading = true;
                    _this.cd.markForCheck();
                }
            }
            else if (domain && domain.disableEmailSignIn) {
                if (domain && domain.defaultProject && !data['ignoreSelfSignOn']) {
                    _this.routing.navigate(['/login', domain.defaultProject], { queryParamsHandling: 'merge' });
                }
                else {
                    _this.routing.navigate(['/login'], { queryParamsHandling: 'merge' });
                }
            }
        }, function () {
            _this.domainLoading = false;
            _this.cd.markForCheck();
        });
    };
    RegisterComponent.prototype.onInviteLoaded = function () {
        if (this.invite) {
            this.form.patchValue({ email: this.invite.getEmail() });
        }
    };
    RegisterComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.invalid) {
            return;
        }
        this.form.markAsSubmit();
        var projectName = this.activatedRoute.snapshot.params['project'];
        var transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
        this.submitLoading = true;
        this.cd.markForCheck();
        this.form
            .register(projectName, transferProjects ? transferProjects.split(',') : undefined)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.onRegister(result.user, result.selfSignOn);
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    RegisterComponent.prototype.onRegister = function (user, selfSignOn) {
        this.onLogin({ userCreated: true, selfSignOn: selfSignOn });
    };
    Object.defineProperty(RegisterComponent.prototype, "signInLink", {
        get: function () {
            var projectName = this.activatedRoute.snapshot.params['project'];
            var ignoreSelfSignOn = this.activatedRoute.snapshot.data['ignoreSelfSignOn'];
            if (projectName) {
                return ['/login', projectName];
            }
            else if (ignoreSelfSignOn) {
                return ['/user', 'login'];
            }
            else {
                return ['/login'];
            }
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.cleanErrorForm = function () {
        this.form.setErrors({});
    };
    return RegisterComponent;
}(LoginBaseComponent));
export { RegisterComponent };

<ng-template #element>
  <div class="sidebar-header__element">
    <div *ngIf="(title | appIsSet) || titleEditable" class="sidebar-header__title">
      <app-editable-content
        *ngIf="titleEditable"
        [value]="title"
        [cleanValue]="titleCleanValue"
        (valueChange)="titleChanged.emit($event)"
        [pen]="true"
        [penTitle]="titleEditTip ? titleEditTip : 'Rename Component'"
      ></app-editable-content>

      <ng-container *ngIf="!titleEditable">
        <ng-container *ngIf="backEnabled">
          {{ title | appCapitalize }}<ng-container *ngIf="subtitle"> - {{ subtitle }}</ng-container>
        </ng-container>

        <ng-container *ngIf="!backEnabled">
          {{ title | appCapitalize }}
        </ng-container>
      </ng-container>
    </div>

    <ng-content select="ng-container[actions]"></ng-content>
    <a
      *ngIf="deleteEnabled"
      href="javascript:void(0)"
      [appTip]="deleteTip ? deleteTip : 'Delete Component'"
      [appTipOptions]="{ side: 'left' }"
      class="button button_orange-transparent button_icon button_no-margin sidebar-header__delete"
      (click)="deleted.emit()"
    >
      <span class="icon icon-bin button__icon"></span>
    </a>
  </div>
</ng-template>

<div
  class="sidebar__header sidebar-header"
  [class.sidebar__header_compact]="!titleEnabled"
  [class.sidebar-header_parent]="parentLabel"
>
  <a *ngIf="backEnabled" href="javascript:void(0)" class="sidebar-header__link" (click)="back.next()">
    <span [ngClass]="'icon-' + backIcon"></span> {{ backLabel }}
  </a>

  <ng-container *ngIf="titleEnabled && parentLabel">
    <div class="sidebar-header__container">
      <div class="sidebar-header__parent">
        <div class="sidebar-header__parent-main" (click)="parentClick.emit($event)">
          <span class="icon-arrow_up sidebar-header__parent-icon"></span>
          <span class="sidebar-header__parent-title">{{ parentLabel }}</span>
          <span class="sidebar-header__parent-subtitle">parent</span>
        </div>
        <div class="sidebar-header__parent-content">
          <ng-container *ngTemplateOutlet="element"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="titleEnabled && !parentLabel">
    <div class="sidebar-header__container">
      <div *ngIf="subtitle && !backEnabled" class="sidebar-header__subtitle">
        {{ subtitle }}
      </div>
      <ng-container *ngTemplateOutlet="element"></ng-container>
    </div>
  </ng-container>

  <!--  <ng-container *ngIf="!backEnabled">-->
  <!--    <div *ngIf="subtitle" class="sidebar-header__subtitle">-->
  <!--      {{ subtitle }}-->
  <!--    </div>-->
  <!--    <div class="sidebar-header__container">-->
  <!--      <ng-container *ngTemplateOutlet="element"></ng-container>-->
  <!--    </div>-->
  <!--  </ng-container>-->
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { CustomizeService } from '@modules/customize';
import { LayoutService } from '@modules/layouts';

@Component({
  selector: 'app-list-layouts',
  templateUrl: './list-layouts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLayoutsComponent implements OnInit, OnDestroy {
  @Output() layoutAdd = new EventEmitter<void>();
  @Output() layoutCustomize = new EventEmitter<number>();

  constructor(
    public layoutService: LayoutService,
    public customizeService: CustomizeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  setCurrentLayoutIndex(index: number) {
    this.layoutService.currentLayoutIndex = index;
    this.cd.markForCheck();

    if (this.customizeService.enabled) {
      this.layoutCustomize.emit(index);
    }
  }

  addLayout() {
    this.layoutAdd.emit();
  }

  trackLayoutByFn(i, item: any) {
    return item.type;
  }
}

import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { isSet } from '@shared';
var NodeType;
(function (NodeType) {
    NodeType["Object"] = "object";
    NodeType["Array"] = "array";
    NodeType["Field"] = "field";
})(NodeType || (NodeType = {}));
var QueryBuilderObjectStructureNodeComponent = /** @class */ (function () {
    function QueryBuilderObjectStructureNodeComponent() {
        this.path = [];
        this.skipSelf = false;
        this.skipContent = false;
        this.deep = 0;
        this.selected = new EventEmitter();
        this.nodePath = [];
        this.nodeTypes = NodeType;
        this.nodeSelected = false;
        this.childCollapsed = [];
    }
    QueryBuilderObjectStructureNodeComponent.prototype.ngOnInit = function () { };
    QueryBuilderObjectStructureNodeComponent.prototype.ngOnDestroy = function () { };
    QueryBuilderObjectStructureNodeComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['node']) {
            this.nodeType = this.getNodeType(this.node);
            this.nodePath = this.path.concat((this.nodeName !== undefined ? [this.nodeName] : []));
            var nodeInSelectedPath = isSet(this.pathSelected) &&
                this.pathSelected.length >= 2 &&
                isEqual(this.pathSelected.slice(0, -2).slice(0, this.nodePath.length), this.nodePath);
            var defaultCollapsed_1 = !nodeInSelectedPath;
            if (this.nodeType == NodeType.Object) {
                this.nodeObjectItems = toPairs(this.node).map(function (_a) {
                    var k = _a[0], v = _a[1];
                    return {
                        key: k,
                        value: v,
                        type: _this.getNodeType(v)
                    };
                });
                this.childCollapsed = this.nodeObjectItems.map(function () { return defaultCollapsed_1; });
            }
            else if (this.nodeType == NodeType.Array) {
                this.nodeArrayItems = this.node.map(function (item) {
                    return {
                        value: item,
                        type: _this.getNodeType(item)
                    };
                });
                this.childCollapsed = this.nodeArrayItems.map(function () { return defaultCollapsed_1; });
            }
        }
        if (changes['node'] || changes['pathSelected']) {
            this.nodeSelected = isSet(this.pathSelected) && isEqual(this.nodePath, this.pathSelected);
        }
    };
    QueryBuilderObjectStructureNodeComponent.prototype.getNodeType = function (node) {
        if (isArray(node)) {
            return NodeType.Array;
        }
        else if (isPlainObject(node)) {
            return NodeType.Object;
        }
        else {
            return NodeType.Field;
        }
    };
    QueryBuilderObjectStructureNodeComponent.prototype.toggleCollapse = function (i) {
        this.childCollapsed[i] = !this.childCollapsed[i];
    };
    QueryBuilderObjectStructureNodeComponent.prototype.select = function () {
        this.selected.emit({ path: this.nodePath });
    };
    QueryBuilderObjectStructureNodeComponent.prototype.childSelected = function (event) {
        this.selected.emit(event);
    };
    return QueryBuilderObjectStructureNodeComponent;
}());
export { QueryBuilderObjectStructureNodeComponent };

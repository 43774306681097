<app-login-template
  *ngIf="!inviteLoading"
  [form]="loginForm"
  [title]="title"
  [domainLoading]="domainLoading"
  [submitLoading]="submitLoading"
  [screenLoader]="screenLoader"
  [domain]="domain"
  [currentSSO]="currentSSO"
  [invite]="invite"
  [inviteLoading]="inviteLoading"
  [publicInvite]="publicInvite"
  [publicInviteLoading]="publicInviteLoading"
  [signUpLink]="signUpLink"
  [restoreLink]="restoreLink"
  [fillScreen]="true"
  (socialLogin)="socialLogin($event)"
  (ssoLogin)="ssoLogin($event)"
  (ssoReset)="ssoReset()"
  (cleanErrorForm)="cleanErrorForm()"
  (submitJetAuth)="submitJetAuth()"
  (submitSSOAuth)="submitSSOAuth($event)"
></app-login-template>

<app-language-selector></app-language-selector>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { delayWhen, map, tap } from 'rxjs/operators';
import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { ViewSettingsStore } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceName, ResourceService } from '@modules/projects';
import { RestAPIResourceParams } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ascComparator, isSet, UnexpectedError } from '@shared';
import { getResourceSettingsComponent } from '../../data/resource-settings-components';
var ResourceEditController = /** @class */ (function () {
    function ResourceEditController(currentProjectStore, currentEnvironmentStore, resourceService, menuSettingsStore, modelDescriptionStore, actionStore, viewSettingsStore, popupService, dialogService, routing, analyticsService, resolver, injector) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceService = resourceService;
        this.menuSettingsStore = menuSettingsStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.popupService = popupService;
        this.dialogService = dialogService;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.resolver = resolver;
        this.injector = injector;
    }
    ResourceEditController.prototype.openEditPopup = function (type, resource, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var result = new Subject();
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var settings = getResourceSettingsComponent(type.name);
        if (!settings) {
            return throwError(new UnexpectedError("Unknown resource edit type: " + type.name));
        }
        this.popupService.push({
            component: settings.component,
            popupComponent: ThinDialogPopupComponent,
            inputs: __assign({ project: project, environment: environment, typeItem: type }, (isSet(resource) && { resource: resource }), (isSet(options.resourceNameEditing) && { resourceNameEditing: options.resourceNameEditing }), (isSet(options.resourceDelete) && { resourceDelete: options.resourceDelete }), (isSet(options.databaseDefaults) && { params: options.databaseDefaults }), (isSet(options.analyticsSource) && { analyticsSource: options.analyticsSource })),
            outputs: {
                saved: [
                    function (savedResource) {
                        result.next({
                            resource: savedResource,
                            created: !resource,
                            edited: !!resource
                        });
                        var resourceParams = savedResource.typeItem.name == ResourceName.RestApi
                            ? savedResource.parseParams(RestAPIResourceParams)
                            : undefined;
                        var restApiBaseUrl = resourceParams ? resourceParams.baseHttpQuery.url : undefined;
                        _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
                            Type: 'data'
                        });
                        if (!resource) {
                            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAdded, __assign({ Source: options.analyticsSource, ResourceID: type.name, ResourceDatabaseOptionType: savedResource.params['database_option']
                                    ? savedResource.params['database_option']['type']
                                    : undefined, MethodID: savedResource.params['deploy'] }, (restApiBaseUrl ? { RestApiBaseUrl: restApiBaseUrl } : {}), { Synced: savedResource.isSynced() }));
                        }
                        else {
                            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceUpdated, {
                                Source: options.analyticsSource,
                                ResourceID: type.name,
                                ResourceDatabaseOptionType: savedResource.params['database_option']
                                    ? savedResource.params['database_option']['type']
                                    : undefined,
                                MethodID: savedResource.params['deploy'],
                                Synced: savedResource.isSynced()
                            });
                        }
                    }
                ],
                canceled: [
                    function () {
                        result.next({
                            cancelled: true
                        });
                    }
                ],
                deleteRequested: [
                    function () {
                        _this.requestDeleteResource(resource).subscribe(function (deleteResult) {
                            if (deleteResult) {
                                result.next({
                                    deleted: true
                                });
                            }
                        });
                    }
                ]
            },
            popupClosed: function (data) {
                if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
                    result.next({
                        cancelled: true
                    });
                }
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return result;
    };
    ResourceEditController.prototype.createResource = function (type, options) {
        if (options === void 0) { options = {}; }
        return this.openEditPopup(type, undefined, options);
    };
    ResourceEditController.prototype.editResource = function (resource, options) {
        if (options === void 0) { options = {}; }
        return this.openEditPopup(resource.typeItem, resource, options);
    };
    ResourceEditController.prototype.deleteResource = function (resource, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.resourceService
            .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, resource)
            .pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), tap(function () {
            if (options.redirectToFirstResource) {
                var project_1 = _this.currentProjectStore.instance;
                var firstResource = _this.currentEnvironmentStore.resources
                    .filter(function (item) { return !item.typeItem.protected; })
                    .filter(function (item) { return !item.demo || (item.demo && project_1.uniqueName == DEMO_RESOURCES_PROJECT); })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()); })[0];
                if (firstResource) {
                    _this.routing.navigateApp(firstResource.link);
                }
            }
        }), delayWhen(function () { return _this.modelDescriptionStore.getFirst(true); }), delayWhen(function () { return _this.actionStore.getFirst(true); }), delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }));
    };
    ResourceEditController.prototype.requestDeleteResource = function (resource, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.dialogService
            .dialog({
            title: 'Deleting',
            description: 'Are you sure want to delete this Resource from App?',
            buttons: [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'ok',
                    label: 'Delete resource',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit,
                    executor: function () { return _this.deleteResource(resource, options); }
                }
            ]
        })
            .pipe(map(function (result) { return result.button == 'ok' && result.executorResult === true; }));
    };
    return ResourceEditController;
}());
export { ResourceEditController };

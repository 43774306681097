/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./json-viewer.component";
var styles_JsonViewerComponent = [];
var RenderType_JsonViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JsonViewerComponent, data: {} });
export { RenderType_JsonViewerComponent as RenderType_JsonViewerComponent };
export function View_JsonViewerComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_JsonViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-json-viewer", [], null, null, null, View_JsonViewerComponent_0, RenderType_JsonViewerComponent)), i0.ɵdid(1, 638976, null, 0, i1.JsonViewerComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JsonViewerComponentNgFactory = i0.ɵccf("app-json-viewer", i1.JsonViewerComponent, View_JsonViewerComponent_Host_0, { content: "content" }, {}, []);
export { JsonViewerComponentNgFactory as JsonViewerComponentNgFactory };

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { ThemeContext } from '@modules/theme-components';
import { CurrentUserStore, UserService } from '@modules/users';
import { isSet } from '@shared';

@Component({
  selector: 'app-verify-email',
  providers: [ThemeContext],
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  loading = false;
  authorized = false;
  emailVerified = false;
  verifyError: string;
  correctRequest = true;
  resent = false;
  domain: Domain;
  domainLoading = false;
  defaultLayout = defaultSignUpLayout;
  externalFonts: string[] = [];

  constructor(
    private currentUserStore: CurrentUserStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest([this.currentUserStore.get().pipe(catchError(() => of(undefined))), this.activatedRoute.queryParams])
      .pipe(untilDestroyed(this))
      .subscribe(([user, params]) => {
        this.authorized = !!user;
        this.emailVerified = user && user.emailVerified;
        this.correctRequest = this.hasVerifyParams(params);
        this.cd.markForCheck();

        if (this.hasVerifyParams(params)) {
          this.verifyEmail(params['email'], params['code']);
        }
      });

    this.domainLoading = true;
    this.cd.markForCheck();

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.domain = data.domain;
        this.externalFonts = this.getExternalFonts(this.domain);
        this.domainLoading = false;
        this.cd.markForCheck();
      },
      () => {
        this.domainLoading = false;
        this.cd.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {}

  getExternalFonts(domain: Domain): string[] {
    if (!domain) {
      return [];
    }

    return [domain.signUpFontRegular, domain.signUpFontHeading].filter(item => isSet(item));
  }

  get layout() {
    return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
  }

  hasVerifyParams(params): boolean {
    return params['code'] && params['email'];
  }

  verifyEmail(email: string, code: string) {
    this.loading = true;
    this.cd.markForCheck();

    this.userService
      .verify(email, code)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.emailVerified = true;
          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountVerified, {
            Email: email
          });
        },
        error => {
          this.verifyError =
            error instanceof ServerRequestError && error.nonFieldErrors && error.nonFieldErrors[0]
              ? error.nonFieldErrors[0]
              : 'Unknown Error';
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  resend() {
    this.userService
      .resendVerification()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.resent = true;
          this.cd.markForCheck();
          this.notificationService.success(
            'Verification email sent',
            'Please check your email and follow instructions'
          );
        },
        error => {
          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            this.notificationService.error('Failed to send', error.nonFieldErrors[0]);
          } else {
            this.notificationService.error('Failed to send', `Unknown error`);
          }
        }
      );
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }
}

<div class="form-layout" [class.form-layout_fill-screen]="fillScreen" [class.form-layout_preview]="preview">
  <div
    class="form-layout__background"
    [style.background-image]="backgroundImage ? 'url(' + backgroundImage + ')' : ''"
    [style.background-color]="(backgroundColor | appIsColorHex) ? backgroundColor : null"
    [ngClass]="
      backgroundColor && !(backgroundColor | appIsColorHex) ? 'background-color_' + backgroundColor + '_1' : ''
    "
  >
    <svg
      *ngIf="!backgroundImage"
      style="width: 100%; height: 100%;"
      preserveAspectRatio="xMidYMin slice"
      width="1124px"
      height="850px"
      viewBox="0 0 1124 850"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Customization---Login-Page-(Var-3)" transform="translate(0.000000, -50.000000)">
          <g id="Login" transform="translate(0.000000, 50.000000)">
            <g id="Mountains" opacity="1">
              <g transform="translate(-226.000000, 184.000000)" id="Oval-3">
                <path
                  *ngIf="backgroundContrastColor2"
                  [style.fill]="backgroundContrastColor2"
                  d="M1036.40719,778 C1229.15457,778 1837.81115,533.710044 1485.24559,437.861711 C1132.68003,342.013379 1103.92433,0 911.176952,0 C718.429575,0 687.407189,212.192209 687.407189,413.5 C687.407189,614.807791 843.659812,778 1036.40719,778 Z"
                  fill="url(#linearGradient-3)"
                  transform="translate(1140.228007, 389.000000) scale(-1, 1) translate(-1140.228007, -389.000000) "
                ></path>
                <path
                  *ngIf="backgroundContrastColor1"
                  [style.fill]="backgroundContrastColor1"
                  d="M1273.93303,858.5 C2019.60621,896.792809 1150.41209,533.710044 797.846535,437.861711 C445.280976,342.013379 416.525278,3.41060513e-13 223.7779,3.41060513e-13 C31.0305222,3.41060513e-13 3.41060513e-13,212.192209 3.41060513e-13,413.5 C3.41060513e-13,499.627223 45.2822726,613.782095 214.330101,688 C440.402932,787.253874 847.287548,836.590329 1273.93303,858.5 Z"
                  fill="url(#linearGradient-4)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="form-layout__foreground">
    <div class="form-layout__content-container">
      <div class="form-layout__card">
        <div class="form-layout__card-underlay form-layout__card-underlay_z_1"></div>
        <div class="form-layout__card-underlay form-layout__card-underlay_z_2"></div>
        <div class="form-layout__card-content">
          <div class="form-layout__content">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
      <div class="form-layout__footer">
        <ng-content select="[data-layout-footer]"></ng-content>
      </div>
    </div>
  </div>
</div>

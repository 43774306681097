import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import values from 'lodash/values';
import { map } from 'rxjs/operators';

import { createFormFieldFactory } from '@modules/fields';
import { getResourceTypeItemRequestName, Resource } from '@modules/projects';
import { HttpContentType, httpContentTypes, HttpMethod, HttpQuery } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';

import { QueryBuilderContext } from '../../data/query-builder-context';
import { InputTokensEvent } from '../input-tokens/input-tokens.component';
import { QueryBuilderHttpForm } from '../query-builder-http/query-builder-http.form';

@Component({
  selector: 'app-query-builder-http-request-parameters',
  templateUrl: './query-builder-http-request-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderHttpRequestParametersComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() resource: Resource;
  @Input() context: QueryBuilderContext;
  @Input() form: QueryBuilderHttpForm;
  @Input() defaultTab: string;
  @Input() enabledBodyTypes: HttpContentType[] = [
    HttpContentType.JSON,
    HttpContentType.FormUrlEncoded,
    HttpContentType.FormData,
    HttpContentType.Raw
  ];
  @Output() execute = new EventEmitter<void>();
  @Output() inputTokensEvent = new EventEmitter<InputTokensEvent>();

  @ViewChild('url_path_element') urlPathElement: ElementRef;

  createField = createFormFieldFactory();
  methodOptions = values(HttpMethod);
  tabs = [
    {
      name: 'queryParams',
      label: 'Query Params'
    },
    {
      name: 'headers',
      label: 'Headers'
    },
    {
      name: 'body',
      label: 'Body',
      takeBodyType: true
    }
  ];
  jsonPlaceholder = `{\n  "foo": params.foo\n}`;
  rawPlaceholder = `CustomMessage {\n  foo={{params.foo}}\n}`;
  currentTabName: string;
  baseHttpQuery: HttpQuery;
  httpContentTypes = HttpContentType;
  bodyTypes: { type: HttpContentType; label: string }[];
  urlPathFocused = false;

  constructor(private focusMonitor: FocusMonitor, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentTabName = this.defaultTab ? this.defaultTab : this.tabs[0].name;
    this.bodyTypes = this.enabledBodyTypes.map(item => {
      return httpContentTypes.find(i => i.type == item);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resource']) {
      const resourceParams = this.resource
        ? this.resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams)
        : undefined;
      this.baseHttpQuery = resourceParams ? resourceParams.baseHttpQuery : undefined;
      this.cd.markForCheck();
    }
  }

  ngAfterViewInit(): void {
    if (this.urlPathElement) {
      this.focusMonitor.monitor(this.urlPathElement.nativeElement).pipe(
        map(origin => {
          this.urlPathFocused = !!origin;
          this.cd.markForCheck();
        })
      );
    }
  }

  setCurrentTabName(name: string) {
    this.currentTabName = name;
    this.cd.markForCheck();
  }

  get urlPlaceholder() {
    const requestName = getResourceTypeItemRequestName(this.resource.typeItem);
    return `Enter ${requestName} URL`;
  }

  setBodyType(type: HttpContentType) {
    this.form.controls.body_type.patchValue(type);
    this.form.controls.body_transformer_visible.patchValue(false);
  }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import keys from 'lodash/keys';
import * as moment from 'moment-timezone';
import { timezones } from '../../data/timezones';
import { LANGUAGE_KEY, TIMEZONE_KEY } from '../../data/user-preferences';
import { captureException } from '../../utils/logging/logging';
import * as i0 from "@angular/core";
var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
    }
    LocalStorage.prototype.get = function (key, defaultValue) {
        return localStorage[key] !== undefined ? localStorage[key] : defaultValue;
    };
    LocalStorage.prototype.getObject = function (key) {
        try {
            return JSON.parse(localStorage[key]);
        }
        catch (e) { }
    };
    LocalStorage.prototype.set = function (key, value) {
        var _this = this;
        try {
            localStorage[key] = value;
        }
        catch (e) {
            if (e instanceof Error && e.name == 'QuotaExceededError') {
                var largeKeySize_1 = 1024 * 1024 * 0.5;
                var largeKeys = this.keys()
                    .map(function (item) {
                    var itemValue = _this.get(item);
                    var size = itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0;
                    var preview;
                    if (size >= largeKeySize_1) {
                        preview = String(itemValue).substr(0, 200);
                    }
                    return {
                        name: item,
                        size: itemValue !== undefined && itemValue !== null ? String(itemValue).length : 0,
                        preview: preview
                    };
                })
                    .sort(function (lhs, rhs) { return (lhs.size - rhs.size) * -1; });
                captureException(e, {
                    extra: {
                        local_storage_keys: largeKeys.map(function (item) {
                            return [item.name, item.size].concat((item.preview !== undefined ? [item.preview] : []));
                        })
                    }
                });
                var deleteKeys = largeKeys.filter(function (item) { return item.size >= largeKeySize_1; });
                if (deleteKeys.length) {
                    deleteKeys.forEach(function (item) {
                        console.log("[LocalStorage] Deleting too large key \"" + item.name + "\" value: " + item.preview);
                        _this.remove(item.name);
                    });
                    this.set(key, value);
                    return;
                }
            }
            throw e;
        }
    };
    LocalStorage.prototype.setObject = function (key, value) {
        localStorage[key] = JSON.stringify(value);
    };
    LocalStorage.prototype.remove = function (key) {
        if (localStorage[key] != undefined) {
            delete localStorage[key];
        }
    };
    LocalStorage.prototype.keys = function () {
        return keys(localStorage);
    };
    LocalStorage.prototype.setLanguage = function (value) {
        this.set(LANGUAGE_KEY, value);
        this.setMomentLanguage(value).then();
    };
    LocalStorage.prototype.setMomentLanguage = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            var Locale, locale, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (language == 'en') {
                            moment.locale(language);
                            return [2 /*return*/, true];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        Locale = import(/* webpackChunkName: "moment-locale" */ 'moment/locale/' + language);
                        return [4 /*yield*/, Locale];
                    case 2:
                        locale = _a.sent();
                        if (!locale) {
                            return [2 /*return*/];
                        }
                        moment.locale(language);
                        return [2 /*return*/, false];
                    case 3:
                        e_1 = _a.sent();
                        console.error("Failed to set Moment language: " + e_1);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocalStorage.prototype.getLanguage = function () {
        return this.get(LANGUAGE_KEY);
    };
    LocalStorage.prototype.setTimezone = function (value) {
        this.set(TIMEZONE_KEY, value);
        var timezone = value ? timezones.find(function (item) { return item.name == value; }) : undefined;
        var tz = timezone ? timezone.tz : undefined;
        moment.tz.setDefault(tz);
    };
    LocalStorage.prototype.getTimezone = function () {
        return this.get(TIMEZONE_KEY);
    };
    LocalStorage.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorage_Factory() { return new LocalStorage(); }, token: LocalStorage, providedIn: "root" });
    return LocalStorage;
}());
export { LocalStorage };

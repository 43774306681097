import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';

import { Color } from './color';

export enum ShadowPosition {
  Outside = 'outside',
  Inside = 'inside'
}

export class Shadow {
  color: Color;
  colorInput: Input;
  position: ShadowPosition = ShadowPosition.Outside;
  offsetX = 0;
  offsetY = 2;
  blurRadius = 4;
  spreadRadius = 0;
  enabled = true;
  enabledInput: Input;

  constructor(options: Partial<Shadow> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.offsetX = data['offset_x'];
    this.offsetY = data['offset_y'];
    this.blurRadius = data['blur_radius'];
    this.spreadRadius = data['spread_radius'];

    if (data['position']) {
      this.position = data['position'];
    }

    if (data['color']) {
      this.color = new Color().deserialize(data['color']);
    }

    if (data['color_input']) {
      this.colorInput = new Input().deserialize(data['color_input']);
    }

    if (isSet(data['enabled'])) {
      this.enabled = data['enabled'];
    }

    if (data['enabled_input']) {
      this.enabledInput = new Input().deserialize(data['enabled_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      color: this.color ? this.color.serialize() : undefined,
      color_input: this.colorInput ? this.colorInput.serialize() : null,
      position: this.position,
      offset_x: this.offsetX,
      offset_y: this.offsetY,
      blur_radius: this.blurRadius,
      spread_radius: this.spreadRadius,
      enabled: this.enabled,
      enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
    };
  }

  cssColor$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    if (this.colorInput) {
      return applyParamInput$(this.colorInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });
    } else if (this.color) {
      return of(this.color.css());
    }
  }

  cssBoxShadow$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    return combineLatest(this.cssColor$(options)).pipe(
      map(([color]) => {
        const result = [];

        if (this.position == ShadowPosition.Inside) {
          result.push('inset');
        }

        result.push(...[`${this.offsetX}px`, `${this.offsetY}px`, `${this.blurRadius}px`, `${this.spreadRadius}px`]);

        if (isSet(color)) {
          result.push(color);
        }

        return result.join(' ');
      })
    );
  }

  cssTextShadow$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    return combineLatest(this.cssColor$(options)).pipe(
      map(([color]) => {
        const result = [`${this.offsetX}px`, `${this.offsetY}px`, `${this.blurRadius}px`];

        if (isSet(color)) {
          result.push(color);
        }

        return result.join(' ');
      })
    );
  }

  enabled$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<boolean> {
    if (this.enabledInput) {
      return applyBooleanInput$(this.enabledInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      }).pipe(catchError(() => of(false)));
    } else {
      return of(true);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { ActionOutputArray } from '@modules/fields';
var ViewEditorActionsForm = /** @class */ (function (_super) {
    __extends(ViewEditorActionsForm, _super);
    function ViewEditorActionsForm() {
        return _super.call(this, {
            actions: new ActionOutputArray([])
        }) || this;
    }
    ViewEditorActionsForm.prototype.init = function (options) {
        this.controls.actions.patchValue(options.actions);
    };
    ViewEditorActionsForm.prototype.getActions = function () {
        return this.controls.actions.value;
    };
    return ViewEditorActionsForm;
}(FormGroup));
export { ViewEditorActionsForm };

<app-model-description-data-source-edit
  [control]="form.controls.export_data_source"
  [label]="'choose collection'"
  [queryOptionEmptyLabel]="'No collections'"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [resourceClasses]="'component-action-resource'"
  [queryOptionClasses]="'component-action-collection'"
  [analyticsSource]="analyticsSource + '_export'"
></app-model-description-data-source-edit>

<!--    <div class="sidebar__element">-->
<!--      <app-sidebar-field [label]="'Records'">-->
<!--        <app-input-edit-->
<!--          [itemForm]="form.controls.export_ids"-->
<!--          [context]="context"-->
<!--          [contextElement]="contextElement"-->
<!--          [contextElementPath]="contextElementPath"-->
<!--          [contextElementPaths]="contextElementPaths"-->
<!--          [fill]="true"-->
<!--          [analyticsSource]="object + '_export_ids'"-->
<!--        ></app-input-edit>-->
<!--        <ng-container description>-->
<!--          Comma separated-->
<!--        </ng-container>-->
<!--      </app-sidebar-field>-->
<!--    </div>-->

<app-sidebar-section *ngIf="queryConfigured$ | async" [title]="'Export fields'">
  <ng-container header-left>
    <div
      class="sidebar-section__title-icon sidebar-section__title-icon_clickable sidebar-section__title-action icon-search"
      [class.sidebar-section__title-icon_active]="columnsSearch"
      (mousedown)="toggleSearch()"
      [appTip]="'Search'"
      [appTipOptions]="{ side: 'top' }"
    ></div>
  </ng-container>

  <ng-container header-right>
    <div class="sidebar-section__header-links">
      <ng-container *ngIf="fieldsComponent.hasMultipleItems()">
        <a class="sidebar__link" href="javascript:void(0)" (click)="fieldsComponent.toggleAll()">
          <span *ngIf="fieldsComponent.isToggledAll()" class="button__label">Deselect All</span>
          <span *ngIf="!fieldsComponent.isToggledAll()" class="button__label">Select All</span>
        </a>
      </ng-container>
    </div>
  </ng-container>

  <div class="sidebar__list">
    <app-display-fields-edit
      [form]="form.controls.export_data_source.controls.columns"
      [modelDescription]="modelDescription"
      [searchEnabled]="columnsSearch"
      [searchFocus]="columnsSearch"
      [firstInit]="false"
      [analyticsSource]="analyticsSource"
      (searchCleared)="setColumnsSearch(false)"
      #fieldsComponent
    >
    </app-display-fields-edit>
  </div>
</app-sidebar-section>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Records per request'" [additional]="'(optional)'">
    <app-input-edit
      [itemForm]="form.controls.export_per_page"
      [staticValueField]="fieldTypes.Number"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [placeholder]="'100 by default'"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_export_ids'"
    ></app-input-edit>

    <ng-container
      *ngIf="form.controls.export_data_source.controls.query.value?.queryType == queryTypes.SQL"
      description
    >
      When using Records per request make sure export <strong>results are ordered</strong> because batch requests from
      unordered list can lead to incorrect total result
    </ng-container>
  </app-sidebar-field>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { MessageUserActivity } from '@modules/activities';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';

@Component({
  selector: 'app-message-user-activity',
  templateUrl: './message-user-activity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageUserActivityComponent implements OnInit, OnDestroy {
  @Input() item: MessageUserActivity;
  message: string;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.message = this.projectUserService.stubTextMentions(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      this.item.message
    );
    this.cd.markForCheck();

    this.projectUserService
      .replaceTextMentions(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.item.message
      )
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.message = result;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';

import { OnBeforeDestroy } from '@common/lifecycle';
import { IgnoreScrollTop } from '@common/route-scroll';

import { CustomizeService, WithBuilderMode } from '@modules/customize';
import { RoutingService } from '@modules/routing';

import { ListComponent } from '../list/list.component';

@Component({
  selector: 'app-list-route',
  templateUrl: './list-route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@WithBuilderMode
@IgnoreScrollTop({
  predicate: (newSnapshot, prevSnapshot) => {
    const model = newSnapshot.params['model'];
    const prevModel = prevSnapshot ? prevSnapshot.params['model'] : undefined;

    return model == prevModel;
  }
})
export class ListRouteComponent implements OnInit, OnDestroy, OnBeforeDestroy {
  @ViewChild(ListComponent) listComponent: ListComponent;

  modelId: string;
  params = {};

  constructor(
    public customizeService: CustomizeService,
    private activatedRoute: ActivatedRoute,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(this.activatedRoute.params, this.activatedRoute.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const snapshot = this.activatedRoute.snapshot;

        this.modelId = snapshot.params['model'];
        this.params = snapshot.queryParams;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onBeforeDestroy(): void {
    if (this.listComponent) {
      this.listComponent.onBeforeDestroy();
    }
  }

  getCollaborationParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: ['model', snapshot.params['model']].join('.')
    };
  }

  getUserActivitiesParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: ['model', snapshot.params['model']].join('.')
    };
  }

  onParamsChanged(params: Object) {
    this.routing.navigateApp(['models', this.modelId], {
      queryParams: params
    });
  }
}

import { MessageUserActivity, UserActivity } from '@modules/activities';
import { User } from '@modules/users';

export class FeedItem {
  public user: User;
  public userActivity: UserActivity | MessageUserActivity;
  public read: boolean;
  public subscription: boolean;
  public mention: boolean;
  public dateAdd: string;

  deserialize(data: Object): FeedItem {
    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }
    if (data['user_activity']) {
      this.userActivity = new UserActivity().deserialize(data['user_activity']);
    }

    this.read = data['read'];
    this.subscription = data['subscription'];
    this.mention = data['mention'];
    this.dateAdd = data['date_add'];
    return this;
  }
}

ace.define('ace/theme/xcode-contrast', ['require', 'exports', 'module', 'ace/lib/dom'], function (
  acequire,
  exports,
  module
) {
  exports.isDark = false;
  exports.cssClass = 'ace-xcode-contrast';
  exports.cssText =
    '\
.ace-xcode-contrast .ace_gutter {\
background: #FFFFFF;\
color: #333\
}\
.ace-xcode-contrast .ace_print-margin {\
width: 1px;\
background: #FFFFFF\
}\
.ace-xcode-contrast {\
background-color: #FFFFFF;\
color: #000000\
}\
.ace-xcode-contrast .ace_cursor {\
color: #000000\
}\
.ace-xcode-contrast .ace_marker-layer .ace_selection {\
background: #B5D5FF\
}\
.ace-xcode-contrast.ace_multiselect .ace_selection.ace_start {\
box-shadow: 0 0 3px 0px #F3F5F8;\
}\
.ace-xcode-contrast .ace_marker-layer .ace_step {\
background: rgb(198, 219, 174)\
}\
.ace-xcode-contrast .ace_marker-layer .ace_bracket {\
margin: -1px 0 0 -1px;\
border: 1px solid #BFBFBF\
}\
.ace-xcode-contrast .ace_marker-layer .ace_active-line {\
background: rgba(0, 0, 0, 0.071)\
}\
.ace-xcode-contrast .ace_gutter-active-line {\
background-color: rgba(0, 0, 0, 0.071)\
}\
.ace-xcode-contrast .ace_marker-layer .ace_selected-word {\
border: 1px solid #B5D5FF\
}\
.ace-xcode-contrast .ace_constant.ace_language,\
.ace-xcode-contrast .ace_keyword,\
.ace-xcode-contrast .ace_meta,\
.ace-xcode-contrast .ace_variable.ace_language {\
color: #C800A4\
}\
.ace-xcode-contrast .ace_invisible {\
color: #BFBFBF\
}\
.ace-xcode-contrast .ace_constant.ace_character,\
.ace-xcode-contrast .ace_constant.ace_other {\
color: #275A5E\
}\
.ace-xcode-contrast .ace_constant.ace_numeric {\
color: #3A00DC\
}\
.ace-xcode-contrast .ace_entity.ace_other.ace_attribute-name,\
.ace-xcode-contrast .ace_support.ace_constant,\
.ace-xcode-contrast .ace_support.ace_function {\
color: #450084\
}\
.ace-xcode-contrast .ace_fold {\
background-color: #C800A4;\
border-color: #000000\
}\
.ace-xcode-contrast .ace_entity.ace_name.ace_tag,\
.ace-xcode-contrast .ace_support.ace_class,\
.ace-xcode-contrast .ace_support.ace_type {\
color: #790EAD\
}\
.ace-xcode-contrast .ace_storage {\
color: #C900A4\
}\
.ace-xcode-contrast .ace_string {\
color: #DF0002\
}\
.ace-xcode-contrast .ace_comment {\
color: #008E00\
}\
.ace-xcode-contrast .ace_indent-guide {\
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==) right repeat-y\
}\
.ace-xcode-contrast .ace_gutter-cell {\
color: #B6BFCC\
}\
';

  var dom = acequire('../lib/dom');
  dom.importCssString(exports.cssText, exports.cssClass);
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { SingletonStore } from '@shared';
import { ProjectService } from '../../projects/services/project/project.service';
import { ProjectsStore } from './projects.store';
var CurrentProjectStore = /** @class */ (function (_super) {
    __extends(CurrentProjectStore, _super);
    function CurrentProjectStore(mode$, projectService, projectsStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.projectService = projectService;
        _this.projectsStore = projectsStore;
        _this.instance$.pipe(untilDestroyed(_this)).subscribe(function (project) {
            _this.projectsStore.current = project;
        });
        return _this;
    }
    CurrentProjectStore.prototype.fetch = function () {
        var _this = this;
        var getApiInfoKey = function (project, resource) {
            return [project.uniqueName, resource.environment, resource.uniqueName].join('.');
        };
        var apiInfos = this.instance
            ? this.instance.resources.reduce(function (acc, resource) {
                var key = getApiInfoKey(_this.instance, resource);
                if (resource.apiInfo) {
                    acc[key] = resource.apiInfo;
                }
                return acc;
            }, {})
            : {};
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.projectService.getDetail(_this.uniqueName, _this.environmentUniqueName, mode == AdminMode.Builder);
        }), tap(function (project) {
            if (!project) {
                return;
            }
            project.resources.forEach(function (resource) {
                var key = getApiInfoKey(project, resource);
                if (!resource.apiInfo && apiInfos[key]) {
                    resource.apiInfo = apiInfos[key];
                }
            });
        }));
    };
    CurrentProjectStore.prototype.ngOnDestroy = function () {
        this.projectsStore.current = undefined;
    };
    CurrentProjectStore.prototype.updateEnvironmentUser = function (environmentName, user) {
        var updated = false;
        var project = cloneDeep(this.instance);
        project.environments = project.environments.map(function (environment) {
            if (environment.uniqueName == environmentName) {
                environment.user = user;
                updated = true;
                return environment;
            }
            else {
                return environment;
            }
        });
        if (updated) {
            this.instance = project;
        }
    };
    CurrentProjectStore.prototype.updateEnvironmentGroup = function (environmentName, group) {
        var updated = false;
        var project = cloneDeep(this.instance);
        project.environments = project.environments.map(function (environment) {
            if (environment.uniqueName == environmentName) {
                environment.group = group;
                updated = true;
                return environment;
            }
            else {
                return environment;
            }
        });
        if (updated) {
            this.instance = project;
        }
    };
    CurrentProjectStore.prototype.addStorage = function (resource, storage) {
        var newInstance = cloneDeep(this.instance);
        newInstance.resources = newInstance.resources.map(function (newInstanceResource) {
            if (newInstanceResource.uniqueName == resource.uniqueName) {
                newInstanceResource.storages = newInstanceResource.storages.concat([storage]);
            }
            return newInstanceResource;
        });
        this.instance = newInstance;
    };
    CurrentProjectStore.prototype.updateStorage = function (resource, storage) {
        var newInstance = cloneDeep(this.instance);
        var replacesFound = false;
        newInstance.resources = newInstance.resources.map(function (newInstanceResource) {
            if (newInstanceResource.uniqueName == resource.uniqueName) {
                var index_1 = newInstanceResource.storages.findIndex(function (item) { return item.uniqueName == storage.uniqueName; });
                if (index_1 !== -1) {
                    if (!replacesFound) {
                        replacesFound = true;
                    }
                    newInstanceResource.storages = newInstanceResource.storages.map(function (item, i) {
                        if (i === index_1) {
                            return storage;
                        }
                        else {
                            return item;
                        }
                    });
                }
            }
            return newInstanceResource;
        });
        if (replacesFound) {
            this.instance = newInstance;
        }
    };
    CurrentProjectStore.prototype.updateStorages = function (resource, storages) {
        var newInstance = cloneDeep(this.instance);
        var replacesFound = false;
        newInstance.resources = newInstance.resources.map(function (newInstanceResource) {
            if (newInstanceResource.uniqueName == resource.uniqueName) {
                var newStorages = newInstanceResource.storages.map(function (instanceItem, i) {
                    var replaceItem = storages.find(function (item) { return item.uniqueName == instanceItem.uniqueName; });
                    if (!replacesFound && replaceItem) {
                        replacesFound = true;
                    }
                    return replaceItem || instanceItem;
                });
                if (replacesFound) {
                    newInstanceResource.storages = newStorages;
                }
            }
            return newInstanceResource;
        });
        if (replacesFound) {
            this.instance = newInstance;
        }
    };
    CurrentProjectStore.prototype.deleteStorage = function (resource, storage) {
        var newInstance = cloneDeep(this.instance);
        var replacesFound = false;
        newInstance.resources = newInstance.resources.map(function (newInstanceResource) {
            if (newInstanceResource.uniqueName == resource.uniqueName) {
                var index_2 = newInstanceResource.storages.findIndex(function (item) { return item.uniqueName == storage.uniqueName; });
                if (index_2 !== -1) {
                    if (!replacesFound) {
                        replacesFound = true;
                    }
                    newInstanceResource.storages = newInstanceResource.storages.filter(function (item, i) { return i !== index_2; });
                }
            }
            return newInstanceResource;
        });
        if (replacesFound) {
            this.instance = newInstance;
        }
    };
    return CurrentProjectStore;
}(SingletonStore));
export { CurrentProjectStore };

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-icon-selector-stub',
  templateUrl: './icon-selector-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorStubComponent implements OnInit, OnChanges {
  @Input() sectionTitle = false;
  @Input() icons = 36;

  iconsRange: number[] = [];

  constructor() {}

  ngOnInit() {
    this.updateIconsRange();
  }

  ngOnChanges(changes: TypedChanges<IconSelectorStubComponent>): void {
    if (changes.icons && !changes.icons.firstChange) {
      this.updateIconsRange();
    }
  }

  updateIconsRange() {
    this.iconsRange = range(this.icons);
  }
}

<div class="card-row collaboration-tasks__row" [class.collaboration-tasks__row_clickable]="clickable">
  <div class="card-row__inner">
    <div class="card-row__col">
      <div class="card-row__col-inner">
        <div
          [style.background-image]="task.assigned?.photo ? 'url(' + task.assigned?.photo + ')' : ''"
          class="collaboration-tasks__list-avatar"
        ></div>
        <div class="collaboration-tasks__list-assigned">
          <span class="collaboration-tasks__list-assigned-name">{{
            task.assigned?.strFull ? task.assigned?.strFull : 'Unassigned'
          }}</span>
          <span class="collaboration-tasks__list-date">{{ task.dateAdd | appTimeAgo: true }}</span>
        </div>
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <!--        <span class="collaboration-tasks__list-name">-->
        {{ task.name }}
        <!--        </span>-->
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <!--        <span class="collaboration-tasks__list-name">{{ task.status?.name }}</span>-->
        <span
          class="status"
          [ngClass]="{
            'color_green_1 background-color_green_2': task.priority === 'low' || task.priority === 'lowest',
            'color_yellow_1 background-color_yellow_2': task.priority === 'medium',
            'color_red_1 background-color_red_2': task.priority === 'high' || task.priority === 'highest'
          }"
          >{{ task.priority }}</span
        >
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <!--        <span class="collaboration-tasks__list-name">{{ task.status?.name }}</span>-->
        <span class="status">{{ task.status?.name }}</span>
      </div>
    </div>

    <!--    <div class="card-row__col">-->
    <!--      <div class="card-row__col-inner">-->
    <!--        <p class="collaboration-tasks__list-page">Some description...</p>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="card-row__col">-->
    <!--      <div class="card-row__col-inner">-->
    <!--        <div class="collaboration-tasks__list-counter">-->
    <!--          <span class="collaboration-tasks__list-counter-icon icon-chat"></span>99+-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="card-row__col" style="overflow: visible;">
      <div class="card-row__col-inner card-row__col-inner_right">
        <ng-container *ngIf="task.approveAction">
          <ng-container *ngIf="!task.approveActionResult && assignedToMe">
            <button type="button" class="button button_danger" (click)="$event.stopPropagation(); reject()">
              Reject
            </button>
            <button type="button" class="button button_green" (click)="$event.stopPropagation(); approve()">
              Approve
            </button>
          </ng-container>
          <button
            *ngIf="task.approveActionResult?.state == approveResultStates.Approved"
            type="button"
            class="button"
            disabled
          >
            Approved
          </button>
          <button
            *ngIf="task.approveActionResult?.state == approveResultStates.Rejected"
            type="button"
            class="button"
            disabled
          >
            Rejected
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { DropListService } from '@common/drag-drop2';
import { ColumnsLayoutColumnElementItem, ColumnsLayoutElementItem, CustomizeService, ElementItem, ElementType, registerElementComponent, traverseElementItems } from '@modules/customize';
import { AutoElementComponent, BaseElementComponent, DRAG_DROP_SIBLING_ACTIVE_CLASS, ElementContainerService } from '@modules/customize-elements';
import { RoutingService } from '@modules/routing';
import { addClass, isSet, MouseButton, removeClass } from '@shared';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
export var COLUMN_RESIZING_CLASS = 'app-column-resizing';
var ColumnsLayoutComponent = /** @class */ (function (_super) {
    __extends(ColumnsLayoutComponent, _super);
    function ColumnsLayoutComponent(customizeService, elementContainerService, routing, dialogService, dropListService, formElementComponent, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.elementContainerService = elementContainerService;
        _this.routing = routing;
        _this.dialogService = dialogService;
        _this.dropListService = dropListService;
        _this.formElementComponent = formElementComponent;
        _this.cd = cd;
        _this.elementGroupsContainer = new QueryList();
        _this.columnElements = new QueryList();
        _this.elementComponents = new QueryList();
        _this.dragging = false;
        _this.stickDistance = 8;
        _this.wrapperPadding = 15;
        _this.siblingEntered$ = new BehaviorSubject(undefined);
        _this.canEnter = (function () {
            return function (drag, drop) {
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    var isInsideCurrentForm_1 = false;
                    if (_this.formElementComponent) {
                        traverseElementItems(_this.formElementComponent.element, function (item) {
                            if (item === drag.data) {
                                isInsideCurrentForm_1 = true;
                            }
                        });
                    }
                    return isInsideCurrentForm_1;
                }
                else {
                    return true;
                }
            };
        })();
        _this.canEnterAfter = (function () {
            return function (drag, drop) {
                var barItem = drag.data instanceof ElementItem ? undefined : drag.data;
                if (barItem && barItem.popup) {
                    return false;
                }
                return _this.canEnter(drag, drop);
            };
        })();
        _this.trackColumn = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings ? _this.context.viewSettings.uid : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        _this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        return _this;
    }
    ColumnsLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.actionClicked
            .pipe(filter(function (item) { return item == 'add'; }), untilDestroyed(this))
            .subscribe(function () { return _this.addColumn(); });
        this.dropListService.dragging$.pipe(untilDestroyed(this)).subscribe(function (dragging) {
            if (!dragging) {
                _this.siblingEntered$.next(undefined);
            }
        });
        this.siblingEntered$.pipe(untilDestroyed(this)).subscribe(function (siblingEntered) {
            if (siblingEntered !== undefined) {
                addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
            }
            else {
                removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
            }
        });
    };
    ColumnsLayoutComponent.prototype.ngOnDestroy = function () {
        if (this.dragging) {
            removeClass(document.body, COLUMN_RESIZING_CLASS);
        }
        if (this.siblingEntered$.value !== undefined) {
            removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
        }
    };
    ColumnsLayoutComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['element'] && !changes['element'].firstChange) {
            setTimeout(function () { return _this.updateAllElementStatesOnStable(); }, 0);
        }
    };
    ColumnsLayoutComponent.prototype.ngAfterViewInit = function () {
        this.updateAllElementStates();
        this.initHandles();
    };
    ColumnsLayoutComponent.prototype.getFluidTotalWidth = function () {
        var _this = this;
        return this.columnElements
            .filter(function (_, i) { return !isSet(_this.element.columns[i].width); })
            .reduce(function (sum, current, i) {
            return sum + current.nativeElement.getBoundingClientRect().width;
        }, 0);
    };
    ColumnsLayoutComponent.prototype.getColumnWidth = function (index) {
        return this.columnElements.toArray()[index].nativeElement.getBoundingClientRect().width;
    };
    ColumnsLayoutComponent.prototype.onHandleDragStarted = function (columnIndex) {
        this.draggingIndex = columnIndex;
        this.dragging = true;
        this.cd.markForCheck();
        addClass(document.body, COLUMN_RESIZING_CLASS);
    };
    ColumnsLayoutComponent.prototype.initHandles = function () {
        var _this = this;
        fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (!_this.dragging) {
                return;
            }
            var index = _this.draggingIndex;
            var bounds = _this.columnsElement.nativeElement.getBoundingClientRect();
            var lhs = _this.columnElements.toArray()[index].nativeElement;
            var lhsBounds = lhs.getBoundingClientRect();
            var rhs = _this.columnElements.toArray()[index + 1].nativeElement;
            var rhsBounds = rhs.getBoundingClientRect();
            var minWidth = 40;
            var position = clamp(e.clientX, lhsBounds.left + _this.wrapperPadding + minWidth, rhsBounds.right - _this.wrapperPadding - minWidth);
            var stickPosition = _this.getStickPositions(bounds).find(function (item) {
                return position >= item.position - _this.stickDistance && position <= item.position + _this.stickDistance;
            });
            if (stickPosition) {
                _this.stick = stickPosition;
                position = stickPosition.position;
            }
            else {
                _this.stick = undefined;
            }
            var totalWidth = _this.getFluidTotalWidth();
            var leftWidth = position - lhsBounds.left + _this.wrapperPadding * 0.5;
            var rightWidth = rhsBounds.right - (position - _this.wrapperPadding) - _this.wrapperPadding * 0.5;
            var lhsWeight = leftWidth / totalWidth;
            var rhsWeight = rightWidth / totalWidth;
            _this.columnElements.forEach(function (item, i) {
                if (i == index || i == index + 1) {
                    return;
                }
                if (!isSet(_this.element.columns[i].width)) {
                    _this.element.columns[i].weight = item.nativeElement.getBoundingClientRect().width / totalWidth;
                }
            });
            if (!_this.element.columns[index].fit && isSet(_this.element.columns[index].width)) {
                _this.element.columns[index].width = leftWidth;
                _this.element.columns[index].fit = false;
            }
            else {
                _this.element.columns[index].weight = lhsWeight;
                _this.element.columns[index].fit = false;
            }
            if (!_this.element.columns[index + 1].fit) {
                if (isSet(_this.element.columns[index + 1].width)) {
                    _this.element.columns[index + 1].width = rightWidth;
                }
                else {
                    _this.element.columns[index + 1].weight = rhsWeight;
                }
            }
            _this.cd.markForCheck();
        });
        fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function () {
            if (!_this.dragging) {
                return;
            }
            _this.dragging = false;
            _this.draggingIndex = undefined;
            _this.stick = undefined;
            removeClass(document.body, COLUMN_RESIZING_CLASS);
            _this.customizeService.markChanged();
            _this.cd.markForCheck();
        });
    };
    ColumnsLayoutComponent.prototype.draggedOutside = function () {
        if (this.element.childrenCount() == 0) {
            this.deleteRequested.emit();
        }
    };
    ColumnsLayoutComponent.prototype.dragDropAfter = function (tabIndex, event) {
        this.elementContainerService.dragDropIntoNewColumn({
            columnsElement: this.element,
            columnIndex: tabIndex + 1,
            sourceContainer: event.previousContainer.data,
            sourceIndex: event.previousIndex,
            sourceCloneItem: event.previousContainer.cloneItems,
            context: this.context
        });
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(tabIndex);
    };
    ColumnsLayoutComponent.prototype.dragDrop = function (tabIndex, event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: this.element
            });
            // TODO: Implement onAdd
            // this.cd.detectChanges();
            // const component = this.elementComponents.find(i => i.element === elementItem);
            // this.onAdd(elementItem, item, component);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(tabIndex);
    };
    ColumnsLayoutComponent.prototype.getStickPositions = function (bounds) {
        return [1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6, 1 / 8]
            .filter(function (multiplier) { return bounds.width * multiplier >= 120; })
            .reduce(function (acc, multiplier) {
            var parts = 1 / multiplier;
            acc.push.apply(acc, range(parts)
                .slice(1)
                .map(function (i) {
                var pos = bounds.left + multiplier * bounds.width * i;
                return {
                    label: i + "/" + parts,
                    position: pos
                };
            }));
            return acc;
        }, []);
    };
    ColumnsLayoutComponent.prototype.replaceItem = function (columnIndex, index, elements) {
        this.elementContainerService.replaceElementItem(this.element.columns[columnIndex].children, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(columnIndex);
    };
    ColumnsLayoutComponent.prototype.duplicateItem = function (columnIndex, index) {
        var elementItem = this.elementContainerService.duplicateElementItem(this.element.columns[columnIndex].children, this.context, index);
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable(columnIndex);
        var component = this.elementComponents.find(function (i) { return i.element === elementItem; });
        if (component) {
            component.customize();
        }
    };
    ColumnsLayoutComponent.prototype.deleteItem = function (element) {
        var _this = this;
        this.element.columns.forEach(function (tab, columnIndex) {
            var index = tab.children.findIndex(function (item) { return item === element; });
            if (index == -1) {
                return;
            }
            _this.elementContainerService.deleteElementItem(_this.element.columns[columnIndex].children, index);
            _this.cd.markForCheck();
            _this.customizeService.markChanged();
            _this.updateElementStatesOnStable(columnIndex);
        });
        if (this.element.childrenCount() == 0) {
            this.deleteRequested.emit();
        }
    };
    ColumnsLayoutComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    ColumnsLayoutComponent.prototype.updateAllElementStatesOnStable = function () {
        var _this = this;
        this.element.columns.forEach(function (_, i) { return _this.updateElementStatesOnStable(i); });
    };
    ColumnsLayoutComponent.prototype.updateAllElementStates = function () {
        var _this = this;
        this.element.columns.forEach(function (_, i) { return _this.updateElementStates(i); });
    };
    ColumnsLayoutComponent.prototype.updateElementStatesOnStable = function (columnIndex) {
        var container = this.elementGroupsContainer.toArray()[columnIndex];
        if (container) {
            container.updateElementStatesOnStable();
        }
    };
    ColumnsLayoutComponent.prototype.updateElementStates = function (columnIndex) {
        var container = this.elementGroupsContainer.toArray()[columnIndex];
        if (container) {
            container.updateElementStates();
        }
    };
    ColumnsLayoutComponent.prototype.setFitContents = function (columnIndex) {
        var column = this.element.columns[columnIndex];
        column.width = undefined;
        column.weight = undefined;
        column.fit = true;
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    ColumnsLayoutComponent.prototype.setFluidWidth = function (columnIndex) {
        var _this = this;
        var column = this.element.columns[columnIndex];
        column.width = undefined;
        column.fit = false;
        var totalWidth = this.getFluidTotalWidth();
        this.element.columns.forEach(function (item, i) {
            if (_this.element.columns[i].fit || isSet(_this.element.columns[i].width)) {
                return;
            }
            item.weight = totalWidth ? _this.getColumnWidth(i) / totalWidth : 1;
        });
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    ColumnsLayoutComponent.prototype.setFixedWidth = function (columnIndex) {
        var _this = this;
        var column = this.element.columns[columnIndex];
        column.width = this.getColumnWidth(columnIndex);
        column.weight = undefined;
        column.fit = false;
        var totalWidth = this.getFluidTotalWidth();
        this.element.columns.forEach(function (item, i) {
            if (_this.element.columns[i].fit || isSet(_this.element.columns[i].width)) {
                return;
            }
            item.weight = totalWidth ? _this.getColumnWidth(i) / totalWidth : 1;
        });
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    ColumnsLayoutComponent.prototype.deleteColumn = function (columnIndex) {
        var _this = this;
        var components = this.element.columns[columnIndex].children.length;
        if (!components) {
            this.deleteColumnProcess(columnIndex);
            return;
        }
        this.dialogService
            .warning({
            title: "Delete columns components (" + components + ")",
            description: "\n          This column contains other components.\n          Are you sure want to delete this columns with the contained components?\n        ",
            style: 'orange'
        })
            .pipe(filter(function (result) { return result; }), untilDestroyed(this))
            .subscribe(function () { return _this.deleteColumnProcess(columnIndex); });
    };
    ColumnsLayoutComponent.prototype.deleteColumnProcess = function (columnIndex) {
        if (this.element.columns.length == 2) {
            var anotherColumn = columnIndex == 0 ? 1 : 0;
            this.replaceRequested.emit(this.element.columns[anotherColumn].children);
        }
        else {
            this.element.columns = this.element.columns.filter(function (item, i) { return i !== columnIndex; });
            this.cd.markForCheck();
            this.customizeService.markChanged();
        }
    };
    ColumnsLayoutComponent.prototype.addColumn = function () {
        var fluidColumns = this.element.columns.filter(function (item) { return isSet(item.weight); });
        var weight = fluidColumns.length
            ? fluidColumns.map(function (item) { return item.weight; }).reduce(function (a, b) { return a + b; }, 0) / fluidColumns.length
            : 1;
        var column = new ColumnsLayoutColumnElementItem();
        column.weight = weight;
        column.generateUid();
        this.element.columns.push(column);
        this.cd.detectChanges();
        this.customizeService.markChanged();
    };
    return ColumnsLayoutComponent;
}(BaseElementComponent));
export { ColumnsLayoutComponent };
registerElementComponent({
    type: ElementType.Columns,
    component: ColumnsLayoutComponent,
    label: 'Columns',
    alwaysActive: false,
    actions: [{ name: 'add', label: 'Add Column' }]
});

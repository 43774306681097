import { Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { LoadingDialogComponent } from '../../components/loading-dialog/loading-dialog.component';
import { DialogButtonHotkey, DialogButtonType } from '../../data/options';
import * as i0 from "@angular/core";
import * as i1 from "../../../popups/services/popup/popup.service";
var DialogInstance = /** @class */ (function () {
    function DialogInstance(popup, popupService) {
        this.popup = popup;
        this.popupService = popupService;
    }
    DialogInstance.prototype.close = function () {
        this.popupService.remove(this.popup);
    };
    DialogInstance.prototype.closeOnUnsubscribe = function () {
        var _this = this;
        return new Observable(function () {
            return function () {
                _this.close();
            };
        });
    };
    return DialogInstance;
}());
export { DialogInstance };
var DialogService = /** @class */ (function () {
    function DialogService(popupService, injector) {
        this.popupService = popupService;
        this.injector = injector;
    }
    DialogService.prototype.dialog = function (options) {
        var obs = new Subject();
        this.popupService.push({
            component: DialogComponent,
            popupComponentDark: options.dark,
            inputs: {
                options: options
            },
            outputs: {
                closed: [function (result) { return obs.next(result); }]
            },
            injector: this.injector
        });
        return obs;
    };
    DialogService.prototype.confirm = function (options) {
        return this.dialog(options).pipe(map(function (result) {
            if (result.executorError) {
                throw result.executorError;
            }
            return result.button == 'ok';
        }));
    };
    DialogService.prototype.warning = function (options) {
        if (!options.buttons) {
            options.buttons = [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'ok',
                    label: 'OK',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit
                }
            ];
        }
        return this.dialog(options).pipe(map(function (result) {
            if (result.executorError) {
                throw result.executorError;
            }
            return result.button == 'ok';
        }));
    };
    DialogService.prototype.alert = function (options) {
        if (!options.buttons) {
            options.buttons = [
                {
                    name: 'ok',
                    label: 'OK',
                    type: DialogButtonType.Primary,
                    hotkey: DialogButtonHotkey.Submit
                }
            ];
        }
        return this.dialog(options).pipe(map(function (result) {
            if (result.executorError) {
                throw result.executorError;
            }
            return result.button == 'ok';
        }));
    };
    DialogService.prototype.prompt = function (options) {
        if (!options.buttons) {
            options.buttons = [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'submit',
                    label: 'Submit',
                    type: DialogButtonType.Submit,
                    hotkey: DialogButtonHotkey.Submit
                }
            ];
        }
        return this.dialog(options);
    };
    DialogService.prototype.loading = function (options) {
        var popup = {
            component: LoadingDialogComponent,
            popupComponentDark: options.dark,
            inputs: {
                options: options
            }
        };
        var dialog = new DialogInstance(popup, this.popupService);
        this.popupService.push(popup);
        return dialog;
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.PopupService), i0.inject(i0.INJECTOR)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { SSOSettingsEditPopupComponent } from '../../components/sso-settings-edit-popup/sso-settings-edit-popup.component';
var SSOSettingsEditController = /** @class */ (function () {
    function SSOSettingsEditController(injector, popupService) {
        this.injector = injector;
        this.popupService = popupService;
    }
    SSOSettingsEditController.prototype.ngOnDestroy = function () { };
    SSOSettingsEditController.prototype.create = function (options) {
        var result = new ReplaySubject();
        this.popupService.push({
            component: SSOSettingsEditPopupComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentOrange: true,
            inputs: __assign({}, (options.initialValue && { initialValue: options.initialValue }), { analyticsSource: options.analyticsSource }),
            outputs: {
                created: [function (instance) { return result.next(instance); }]
            },
            injector: this.injector
        });
        return result.asObservable();
    };
    SSOSettingsEditController.prototype.edit = function (options) {
        var result = new ReplaySubject();
        this.popupService.push({
            component: SSOSettingsEditPopupComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentOrange: true,
            inputs: {
                sso: options.sso,
                analyticsSource: options.analyticsSource
            },
            outputs: {
                updated: [function (instance) { return result.next({ updated: instance }); }],
                deleted: [function (instance) { return result.next({ deleted: instance }); }]
            },
            injector: this.injector
        });
        return result.asObservable();
    };
    return SSOSettingsEditController;
}());
export { SSOSettingsEditController };

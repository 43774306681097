import { Option } from '@modules/field-components';
import { BlendingMode } from '@modules/views';

export const blendingModeOptions: Option<BlendingMode>[] = [
  {
    value: BlendingMode.Normal,
    name: 'Normal'
  },
  {
    value: BlendingMode.Darken,
    name: 'Darken'
  },
  {
    value: BlendingMode.Multiply,
    name: 'Multiply'
  },
  {
    value: BlendingMode.ColorBurn,
    name: 'Color Burn'
  },
  {
    value: BlendingMode.Lighten,
    name: 'Lighten'
  },
  {
    value: BlendingMode.Screen,
    name: 'Screen'
  },
  {
    value: BlendingMode.ColorDodge,
    name: 'Color Dodge'
  },
  {
    value: BlendingMode.Overlay,
    name: 'Overlay'
  },
  {
    value: BlendingMode.SoftLight,
    name: 'Soft Light'
  },
  {
    value: BlendingMode.HardLight,
    name: 'Hard Light'
  },
  {
    value: BlendingMode.Difference,
    name: 'Difference'
  },
  {
    value: BlendingMode.Exclusion,
    name: 'Exclusion'
  },
  {
    value: BlendingMode.Hue,
    name: 'Hue'
  },
  {
    value: BlendingMode.Saturation,
    name: 'Saturation'
  },
  {
    value: BlendingMode.Color,
    name: 'Color'
  },
  {
    value: BlendingMode.Luminosity,
    name: 'Luminosity'
  }
];

import { OnInit } from '@angular/core';
var PageColumnComponent = /** @class */ (function () {
    function PageColumnComponent() {
        this.fill = true;
        this.fillGrow = 1;
        this.verticalAlign = 'top';
        this.cls = true;
    }
    Object.defineProperty(PageColumnComponent.prototype, "fillCls", {
        get: function () {
            return this.fill;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageColumnComponent.prototype, "flexGrow", {
        get: function () {
            return this.fill ? this.fillGrow : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageColumnComponent.prototype, "verticalAlignTop", {
        get: function () {
            return this.verticalAlign == 'center';
        },
        enumerable: true,
        configurable: true
    });
    PageColumnComponent.prototype.ngOnInit = function () { };
    return PageColumnComponent;
}());
export { PageColumnComponent };

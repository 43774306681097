import { HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Plyr from 'plyr';
import { interval } from 'rxjs';

import { DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';

export interface VideoPopupLink {
  label: string;
  href?: string;
  link?: any[];
  primary?: boolean;
  action?: string;
}

@Component({
  templateUrl: 'video-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPopupComponent implements OnDestroy, AfterViewInit {
  @Input() title: string;
  @Input() src: string;
  @Input() youtubeId: string;
  @Input() autoPlay = false;
  @Input() startTime: number;
  @Input() sourceElement: Element;
  @Input() links: VideoPopupLink[] = [];
  @Output() closed = new EventEmitter<void>();
  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;
  @ViewChild('title_element') titleElement: ElementRef;
  @ViewChild('close_element') closeElement: ElementRef;
  @ViewChild('buttons_element') buttonsElement: ElementRef;
  @ViewChild('video') videoElement: ElementRef;

  tl = new TimelineMax();
  animating = false;
  player: any;

  startPlaying: number;
  duration = 0;
  currentDuration = 0;

  constructor(
    private popupComponent: BasePopupComponent,
    private analyticsService: UniversalAnalyticsService,
    private dialogService: DialogService
  ) {}

  ngAfterViewInit(): void {
    this.show();
    this.initPlayer();
    this.analyticsService.sendEvent(AnalyticsEvent.GA.Overview, AnalyticsEventAction.Open);
  }

  ngOnDestroy(): void {
    this.closed.emit();
    this.analyticsService.sendEvent(
      AnalyticsEvent.GA.Overview,
      AnalyticsEventAction.Close,
      JSON.stringify({
        totalDuration: this.totalDuration,
        currentTime: this.player ? this.player.currentTime : undefined
      })
    );

    if (this.player) {
      this.player.destroy();
    }
  }

  initPlayer() {
    if (!this.videoElement) {
      return;
    }

    this.player = new Plyr(this.videoElement.nativeElement, {
      controls: ['play-large', 'progress', 'mute', 'volume', 'settings', 'fullscreen'],
      settings: ['captions', 'quality', 'speed', 'loop']
    });

    this.player.on('playing', () => {
      this.startPlaying = this.player.currentTime;
      this.duration += this.currentDuration;
      this.currentDuration = 0;
    });

    this.player.on('timeupdate', () => {
      const newCurrentDuration = this.player.currentTime - this.startPlaying;

      if (newCurrentDuration > 0 && newCurrentDuration - this.currentDuration <= 1.5) {
        this.currentDuration = newCurrentDuration;
      } else {
        this.duration += this.currentDuration;
        this.currentDuration = 0;
      }
    });

    this.player.on('seeked', () => {
      this.analyticsService.sendEvent(
        AnalyticsEvent.GA.Overview,
        AnalyticsEventAction.Seek,
        JSON.stringify({
          totalDuration: this.totalDuration,
          currentTime: this.player.currentTime
        })
      );
    });

    interval(5000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.analyticsService.sendEvent(
          AnalyticsEvent.GA.Overview,
          AnalyticsEventAction.Emit,
          JSON.stringify({
            totalDuration: this.totalDuration,
            currentTime: this.player.currentTime
          })
        );
      });
  }

  get totalDuration() {
    return this.duration + this.currentDuration;
  }

  getYoutubeLink(): string {
    if (!this.youtubeId) {
      return;
    }

    const params = {};

    if (this.autoPlay) {
      params['autoplay'] = '1';
    }

    if (this.startTime) {
      params['start'] = String(this.startTime);
    }

    const httpParams = new HttpParams({ fromObject: params });
    return `https://www.youtube.com/embed/${this.youtubeId}?${httpParams.toString()}`;
  }

  sourceElementDelta() {
    let x = 0;
    let y = 0;

    if (this.sourceElement) {
      const bounds = this.sourceElement.getBoundingClientRect();

      x = bounds.left + bounds.width / 2 - window.innerWidth / 2;
      y = bounds.top + bounds.height / 2 - window.innerHeight / 2;
    }

    return [x, y];
  }

  show() {
    if (this.animating) {
      return;
    }

    // const [x, y] = this.sourceElementDelta();
    const showElements = [
      this.background.nativeElement,
      this.closeElement.nativeElement,
      this.buttonsElement.nativeElement
    ];

    if (this.titleElement) {
      showElements.push(this.titleElement.nativeElement);
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        showElements,
        0.6,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        }
      )
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          opacity: 0,
          y: window.innerHeight * 0.2
        },
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.animating = false;

        if (this.autoPlay && this.player) {
          this.player.play();
        }
      });
  }

  close() {
    if (this.animating) {
      return;
    }

    const [x, y] = this.sourceElementDelta();
    const hideElements = [
      this.background.nativeElement,
      this.closeElement.nativeElement,
      this.buttonsElement.nativeElement
    ];

    if (this.titleElement) {
      hideElements.push(this.titleElement.nativeElement);
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        hideElements,
        0.6,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeOut
        }
      )
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          scale: 1,
          opacity: 1,
          x: 0,
          y: 0
        },
        {
          scale: 0,
          opacity: 0,
          x: x,
          y: y,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.animating = false;
        this.popupComponent.close();
      });
  }

  miss() {
    this.dialogService
      .confirm({
        title: 'Close',
        description: 'Are you sure want to close video?'
      })
      .pipe(untilDestroyed(this))
      .subscribe(confirmed => {
        if (!confirmed) {
          return;
        }

        this.close();
      });
  }

  onLinkClick(link: VideoPopupLink) {
    if (link.action == 'close') {
      this.close();
    }
  }
}

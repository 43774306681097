import { ViewportScroller } from '@angular/common';
import { OnDestroy } from '@angular/core';
import { ActivationEnd, Router, Scroll } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { componentHasIgnoreScrollTop, getComponentIgnoreScrollTopOptions } from '../../data/ignore-scroll-top.decorator';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var RouteScrollService = /** @class */ (function () {
    function RouteScrollService(router, viewportScroller) {
        this.router = router;
        this.viewportScroller = viewportScroller;
    }
    RouteScrollService.prototype.ngOnDestroy = function () { };
    RouteScrollService.prototype.initScrollRestoration = function () {
        var _this = this;
        var prevSnapshot;
        var currentSnapshot;
        this.router.events.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (e instanceof ActivationEnd) {
                if (!e.snapshot.firstChild) {
                    prevSnapshot = currentSnapshot;
                    currentSnapshot = e.snapshot;
                }
            }
            else if (e instanceof Scroll) {
                if (e.position) {
                    // backward navigation
                    _this.viewportScroller.scrollToPosition(e.position);
                }
                else if (e.anchor) {
                    // anchor navigation
                    try {
                        _this.viewportScroller.scrollToAnchor(e.anchor);
                    }
                    catch (e) { }
                }
                else {
                    // forward navigation
                    if (!prevSnapshot || !currentSnapshot) {
                        return;
                    }
                    if (prevSnapshot.component == currentSnapshot.component &&
                        _this.hasIgnoreScrollTop(currentSnapshot, prevSnapshot)) {
                        return;
                    }
                    _this.viewportScroller.scrollToPosition([0, 0]);
                }
            }
        });
    };
    RouteScrollService.prototype.hasIgnoreScrollTop = function (newSnapshot, prevSnapshot) {
        if (newSnapshot.component && componentHasIgnoreScrollTop(newSnapshot.component)) {
            var options = getComponentIgnoreScrollTopOptions(newSnapshot.component);
            if (options && options.predicate) {
                return options.predicate(newSnapshot, prevSnapshot);
            }
            return true;
        }
        else if (newSnapshot.firstChild) {
            return this.hasIgnoreScrollTop(newSnapshot.firstChild, prevSnapshot);
        }
        else {
            return false;
        }
    };
    RouteScrollService.ngInjectableDef = i0.defineInjectable({ factory: function RouteScrollService_Factory() { return new RouteScrollService(i0.inject(i1.Router), i0.inject(i2.ViewportScroller)); }, token: RouteScrollService, providedIn: "root" });
    return RouteScrollService;
}());
export { RouteScrollService };

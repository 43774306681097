import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectSource } from '@common/select';
import { coerceArray, controlValue, generateAlphanumeric, isSet, KeyboardEventKeyCode } from '@shared';
import { DropdownComponent } from '../dropdown/dropdown.component';
export function defaultCompare(o1, o2) {
    return o1 == o2;
}
var chipsMouseEvent = '_chipsMouseEvent';
var chipsElementMouseEvent = '_chipsElementMouseEvent';
export function markChipsMouseEvent(e, id) {
    e[chipsMouseEvent] = id;
}
export function isChipsMouseEvent(e, id) {
    return e[chipsMouseEvent] == id;
}
export function markChipsElementMouseEvent(e, id) {
    e[chipsElementMouseEvent] = id;
}
export function isChipsElementMouseEvent(e, id) {
    return e[chipsElementMouseEvent] == id;
}
var defaultPlaceholder = 'Add value';
var ChipsComponent = /** @class */ (function () {
    function ChipsComponent(cd) {
        this.cd = cd;
        this.options = [];
        this.compareWith = defaultCompare;
        this.separator = ',';
        this.array = true;
        this.placeholder = defaultPlaceholder;
        this.resetEnabled = false;
        this.duplicates = false;
        this.allowNew = false;
        this.circular = false;
        this.small = false;
        this.id = generateAlphanumeric(8, { letterFirst: true });
        this.source$ = new BehaviorSubject(undefined);
        this.options$ = new BehaviorSubject([]);
        this.loadingItems = false;
        this.selectedValues = [];
        this.addedOptions = [];
        this.addControl = new FormControl('');
    }
    ChipsComponent.prototype.trackItemFn = function (i, value) {
        return value;
    };
    ChipsComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.control)
            .pipe(map(function (value) { return (isSet(value) ? coerceArray(value) : []); }), untilDestroyed(this))
            .subscribe(function (options) {
            _this.selectedValues = options;
            _this.cd.markForCheck();
            _this.dropdownComponent.updatePositionOnStable();
        });
        controlValue(this.addControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            if (_this.allowNew && isSet(_this.separator) && value.includes(_this.separator)) {
                _this.addValueFromControl();
            }
        });
    };
    ChipsComponent.prototype.ngOnDestroy = function () { };
    ChipsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.source) {
            this.source$.next(this.source);
        }
        if (changes.options) {
            this.options$.next(this.options);
        }
        if (changes.compareWith) {
            this.compareWith = this.compareWith ? this.compareWith : defaultCompare;
        }
        if (changes.placeholder) {
            this.placeholder = isSet(this.placeholder) ? this.placeholder : defaultPlaceholder;
        }
    };
    ChipsComponent.prototype.setValue = function (newValue) {
        this.control.patchValue(newValue);
    };
    ChipsComponent.prototype.resetValue = function () {
        this.setValue([]);
    };
    ChipsComponent.prototype.removeItem = function (value, index) {
        var _this = this;
        var newValue = this.selectedValues.slice().filter(function (item, i) { return i != index; });
        this.setValue(newValue);
        this.addedOptions = this.addedOptions.filter(function (item) { return !_this.compareWith(item, value); });
        this.cd.markForCheck();
    };
    ChipsComponent.prototype.addValues = function () {
        var _this = this;
        var newItems = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newItems[_i] = arguments[_i];
        }
        var _a;
        if (!this.duplicates) {
            newItems = newItems.filter(function (newValueItem) {
                return !_this.selectedValues.some(function (item) { return _this.compareWith(item, newValueItem.value); });
            });
        }
        (_a = this.addedOptions).push.apply(_a, newItems);
        this.cd.markForCheck();
        var newValue = this.selectedValues.concat(newItems.map(function (item) { return item.value; }));
        this.setValue(newValue);
    };
    ChipsComponent.prototype.addValueFromControl = function () {
        var addValuesRaw = isSet(this.separator) ? this.addControl.value.split(this.separator) : [this.addControl.value];
        var addValues = addValuesRaw.map(function (item) { return item.trim(); }).filter(function (item) { return isSet(item); });
        if (!addValues.length) {
            return;
        }
        this.addValues.apply(this, addValues.map(function (item) { return ({ value: item, name: item }); }));
        this.addControl.setValue('');
    };
    ChipsComponent.prototype.focusInput = function () {
        this.inputElement.nativeElement.focus();
    };
    ChipsComponent.prototype.open = function () {
        if (this.dropdownComponent) {
            this.dropdownComponent.open();
        }
    };
    ChipsComponent.prototype.close = function () {
        if (this.dropdownComponent) {
            this.dropdownComponent.close();
        }
    };
    ChipsComponent.prototype.markChipsMouseEvent = function (e) {
        markChipsMouseEvent(e, this.id);
    };
    ChipsComponent.prototype.markChipsElementMouseEvent = function (e) {
        markChipsElementMouseEvent(e, this.id);
    };
    ChipsComponent.prototype.closeOnMouseDown = function (e) {
        return !isChipsMouseEvent(e, this.id);
    };
    ChipsComponent.prototype.onClick = function (e) {
        if (!isChipsElementMouseEvent(e, this.id)) {
            this.focusInput();
        }
    };
    ChipsComponent.prototype.onKeydown = function (e) {
        if (this.allowNew && e.keyCode == KeyboardEventKeyCode.Enter) {
            e.preventDefault();
            this.addValueFromControl();
        }
    };
    return ChipsComponent;
}());
export { ChipsComponent };

<div class="sidebar__element">
  <app-sidebar-field [label]="'date format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'date_format',
          field: 'SelectField',
          params: {
            options: editable ? form.dateFormatEditOptions : form.dateFormatOptions,
            classes: ['select_fill', form.form.value['date_format'] == dateFormats.Custom ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>

    <app-auto-field
      *ngIf="form.form.value['date_format'] == dateFormats.Custom"
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'date_format_custom',
          field: 'CharField',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Include time'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'time',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>

  <app-sidebar-field *ngIf="form.form.value['time']" style="display: block; margin-top: 10px;">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'time_format',
          field: 'SelectField',
          params: {
            options: form.timeFormatOptions,
            classes: ['select_fill', form.form.value['time_format'] == timeFormats.Custom ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>

    <app-auto-field
      *ngIf="form.form.value['time_format'] == timeFormats.Custom"
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'time_format_custom',
          field: 'CharField',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="!editable" class="sidebar__element">
  <app-sidebar-field [label]="'Relative time (7 days ago, in 1 hour)'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'relative',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

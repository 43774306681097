import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { controlValue } from '@shared';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { ImageFillControl } from '../../controls/image-fill.control';

@Component({
  selector: 'app-image-fill-selector',
  templateUrl: './image-fill-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFillSelectorComponent implements OnInit, OnDestroy {
  @Input() control: ImageFillControl;
  @Input() viewContext: ViewContext;

  imageBackgroundSafe: SafeStyle;
  imageUploading = false;
  draggingFile = false;
  imageInputFocused = false;

  constructor(
    private editorContext: ViewEditorContext,
    private sanitizer: DomSanitizer,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectStorageService: ProjectStorageService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(
        switchMap(() => this.control.getInstance().cssBackground$({ context: this.viewContext })),
        untilDestroyed(this)
      )
      .subscribe(background => {
        this.imageBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(background);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  uploadFile(file: File) {
    const filePath = ['custom_views', this.editorContext.view$.value.id].join('/');
    const fileName = file.name;

    this.imageUploading = true;
    this.cd.markForCheck();

    this.projectStorageService
      .uploadFile(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        file,
        filePath,
        fileName
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.result) {
            this.imageUploading = false;
            this.cd.markForCheck();

            this.control.controls.image.patchValue(response.result.uploadedUrl);
          }
        },
        () => {
          this.imageUploading = false;
          this.cd.markForCheck();
        }
      );
  }

  onImageFileChange(el: HTMLInputElement) {
    if (!el.files || !el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.uploadFile(file);
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingFile = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingFile = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (!file) {
      return;
    }

    this.draggingFile = false;
    this.cd.markForCheck();

    this.uploadFile(file);
  }

  toggleImageInputEnabled() {
    const value = !this.control.controls.image_input_enabled.value;

    this.control.controls.image_input_enabled.patchValue(value);

    if (value) {
      this.imageInputFocused = true;
      this.cd.markForCheck();
    }
  }
}

import { Injectable } from '@angular/core';

import { ActionDescription, ActionType, ClosePopupAction, ViewSettingsAction } from '@modules/actions';
import {
  FieldElementItem,
  FormElementItem,
  FormSubmitElementItem,
  generateElementName,
  modelFieldToDisplayField,
  VALUE_OUTPUT
} from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { fieldsEditItemFromParameterField } from '@modules/field-components';
import { Input, InputValueType } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { Resource } from '@modules/projects';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { Template } from '@modules/template';
import { isSet } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';

@Injectable()
export class UpdateFormGenerator {
  constructor(private generatorUtils: GeneratorUtils) {}

  getUpdateParameters(modelDescription: ModelDescription) {
    return modelDescription.updateParametersOrDefaults.filter(item => item.name != modelDescription.primaryKeyField);
  }

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      templates: Template[];
      pkContextValue: string[];
      fields?: string[];
      closePopupOnSuccess?: string;
      idGenerator?: PersistentIdGenerator;
      idUniqueName?: string;
    }
  ): FormElementItem {
    const formElement = new FormElementItem();

    if (options.idGenerator && isSet(options.idUniqueName)) {
      formElement.uid = options.idGenerator ? options.idGenerator.elementId(options.idUniqueName) : undefined;
    } else {
      formElement.generateUid();
    }

    formElement.generated = true;

    const getQuery = new ModelDescriptionQuery();

    getQuery.queryType = QueryType.Simple;
    getQuery.simpleQuery = new getQuery.simpleQueryClass();
    getQuery.simpleQuery.model = modelDescription.model;

    const pkInput = new Input();

    pkInput.name = modelDescription.primaryKeyField;
    pkInput.valueType = InputValueType.Context;
    pkInput.contextValue = options.pkContextValue;

    let fieldElements = this.getUpdateParameters(modelDescription).map(item => {
      const element = new FieldElementItem();

      element.settings = { field: item.field };
      this.generatorUtils.applyElementTemplate(element, options.templates);

      if (options.idGenerator) {
        element.uid = options.idGenerator
          ? options.idGenerator.elementId(`${options.idUniqueName}_${item.name}`)
          : undefined;
      } else {
        element.generateUid();
      }

      element.settings = {
        ...fieldsEditItemFromParameterField(item),
        verboseName: item.verboseName || item.name,
        editable: true
      };
      element.updateFormField();
      element.name = generateElementName(element, {});

      const input = new Input();

      input.name = 'value';
      input.valueType = InputValueType.Context;
      input.contextValue = ['elements', formElement.uid, ITEM_OUTPUT, item.name];

      element.settings.valueInput = input;

      return element;
    });

    if (options.fields) {
      fieldElements = fieldElements
        .filter(item => options.fields.includes(item.settings.name))
        .sort((lhs, rhs) => this.generatorUtils.fieldsSort(options.fields, lhs.settings.name, rhs.settings.name));
    }

    formElement.getDataSource = new ModelDescriptionDataSource();
    formElement.getDataSource.type = DataSourceType.Query;
    formElement.getDataSource.queryResource = resource.uniqueName;
    formElement.getDataSource.query = getQuery;
    formElement.getDataSource.queryInputs = [pkInput];
    formElement.getDataSource.columns = modelDescription.fields
      .filter(item => item.type == ModelFieldType.Db)
      .map(item => modelFieldToDisplayField(item));

    formElement.children = [...fieldElements];

    const autoAction = modelDescription.autoActions().find(item => item.name == 'update');

    if (autoAction) {
      const submitElement = new FormSubmitElementItem();

      if (options.idGenerator) {
        submitElement.uid = options.idGenerator
          ? options.idGenerator.elementId(`${options.idUniqueName}___submit__`)
          : undefined;
      } else {
        submitElement.generateUid();
      }

      submitElement.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);

      formElement.children.push(submitElement);

      const action = new ViewSettingsAction();

      action.verboseNameInput = new Input().deserializeFromStatic('value', autoAction.label);
      action.sharedActionDescription = [resource.uniqueName, autoAction.uniqueName].join('.');

      action.inputs = modelDescription.updateParametersOrDefaults.map(item => {
        const input = new Input();

        input.name = item.name;
        input.valueType = InputValueType.Context;

        if (item.name == modelDescription.primaryKeyField) {
          input.contextValue = options.pkContextValue;
        } else {
          if (!options.fields || options.fields.includes(item.name)) {
            const fieldElement = fieldElements.find(element => element.settings.name == item.name);

            if (fieldElement) {
              input.contextValue = ['elements', fieldElement.uid, VALUE_OUTPUT];
            }
          } else {
            input.contextValue = [ITEM_OUTPUT, item.name];
          }
        }

        return input;
      });

      if (options.closePopupOnSuccess) {
        const closePopupAction = new ClosePopupAction();

        closePopupAction.popup = options.closePopupOnSuccess;

        const successActionDescription = new ActionDescription();

        successActionDescription.type = ActionType.ClosePopup;
        successActionDescription.closePopupAction = closePopupAction;

        const successAction = new ViewSettingsAction();

        successAction.verboseNameInput = new Input().deserializeFromStatic('value', 'Close Modal');
        successAction.actionDescription = successActionDescription;

        action.onSuccessActions = [successAction];
      }

      formElement.submitAction = action;
    }

    return formElement;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { GuideSection } from '../../data/guide-section';

@Injectable({
  providedIn: 'root'
})
export class GuideSectionService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, params = {}): Observable<GuideSection[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'guide_sections/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new GuideSection().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

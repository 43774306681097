export enum NumberValueFormat {
  Default = 'default',
  MetricPrefix = 'metric_prefix',
  Currency = 'currency',
  Percentage = 'percentage',
  Order = 'order',
  FileSize = 'file_size',
  Exponential = 'exponential'
}

export const defaultNumberFraction = 2;
export const defaultNumberFractionSeparator = '.';
export const defaultNumberThousandsSeparator = ',';

export interface IValueFormat {
  prefix?: string;
  postfix?: string;
  numberFormat?: NumberValueFormat;
  numberFraction?: number;
  numberFractionSeparator?: string;
  numberThousandsSeparator?: string;
}

import { FormControl, FormGroup } from '@angular/forms';

import { ProjectPermission, ProjectPermissionType } from '@modules/projects';

import { ProjectPermissionActionsControl, ProjectPermissionActionsValue } from './project-permission-actions.control';

export interface ProjectPermissionValue {
  id?: number;
  type: ProjectPermissionType;
  object: string;
  actions?: ProjectPermissionActionsValue;
  enabled?: boolean;
}

export class ProjectPermissionControl<T = Object> extends FormGroup {
  controls: {
    id: FormControl;
    type: FormControl;
    object: FormControl;
    actions: ProjectPermissionActionsControl;
    enabled: FormControl;
  };
  value: ProjectPermissionValue;
  data: T;

  constructor(formState?: ProjectPermissionValue, data?: T) {
    super({
      id: new FormControl(formState.id),
      type: new FormControl(formState.type),
      object: new FormControl(formState.object),
      actions: new ProjectPermissionActionsControl(formState.actions),
      enabled: new FormControl(!!formState.enabled)
    });
    this.data = data;
  }

  setValue(value: ProjectPermissionValue, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }

  patchValue(value: Partial<ProjectPermissionValue>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }

  deserialize(permission?: ProjectPermission) {
    this.patchValue({
      id: permission ? permission.id : undefined,
      enabled: !!permission
    });
    this.controls.actions.deserialize(permission ? permission.permissionActions : '');
  }

  serialize(): ProjectPermission {
    const permission = new ProjectPermission();

    permission.id = this.value.id;
    permission.permissionType = this.value.type;
    permission.permissionObject = this.value.object;
    permission.permissionActions = this.controls.actions.serialize();

    return permission;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { Frame, includesAnyLayers, LayerInteractionType, LayerType, resizeLayers } from '@modules/views';
import { registerLayerComponent } from '../../../data/layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';
var GroupLayerComponent = /** @class */ (function (_super) {
    __extends(GroupLayerComponent, _super);
    function GroupLayerComponent(editorContext, contextElement, sanitizer, cd) {
        var _this = _super.call(this, editorContext) || this;
        _this.contextElement = contextElement;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.layersInteraction = true;
        _this.displayItems = [];
        return _this;
    }
    GroupLayerComponent.prototype.trackLayerFn = function (i, item) {
        return item.id;
    };
    GroupLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.registerNestedContainer();
        if (this.editorContext) {
            this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
            this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
            this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
            this.updating$ = this.getLayerUpdating$(function () { return !_this.editorContext.isCreateTool(); });
            merge(this.editorContext
                .layerChanged$()
                .pipe(filter(function (event) { return !!_this.layer.layers.find(function (item) { return item.isSame(event.layer); }); })), this.editorContext.layerContainerChanged$().pipe(filter(function (event) { return _this.layer.layers === event.container; })))
                .pipe(untilDestroyed(this))
                .subscribe(function () { return _this.updateDisplayItems(); });
            combineLatest(this.editorContext.ctrlKeydown$, this.editorContext.customizingLayers$)
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var ctrlKeydown = _a[0], customizingLayers = _a[1];
                _this.layersInteraction = ctrlKeydown || includesAnyLayers(_this.layer.layers, customizingLayers);
                _this.cd.markForCheck();
            });
            this.initFitContainer();
        }
        this.getLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.updateNestedContainer();
            _this.updateDisplayItems();
            _this.updateLayerContext();
        });
    };
    GroupLayerComponent.prototype.ngOnDestroy = function () {
        this.unregisterNestedContainer();
    };
    GroupLayerComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (this.editorContext) {
            var layerChanged = changes.layer && !changes.layer.firstChange;
            var translateChanged = changes.translate && !changes.translate.firstChange;
            if (layerChanged) {
                this.unregisterNestedContainer();
                this.registerNestedContainer();
            }
            else if (translateChanged) {
                this.updateNestedContainer();
            }
        }
    };
    GroupLayerComponent.prototype.registerNestedContainer = function () {
        if (!this.editorContext) {
            return;
        }
        this.nestedContainer = this.editorContext.registerContainer(this.layer.layers, {
            layer: this.layer,
            translate: {
                x: this.translate.x + this.layer.frame.x,
                y: this.translate.y + this.layer.frame.y
            }
        });
    };
    GroupLayerComponent.prototype.unregisterNestedContainer = function () {
        if (!this.editorContext) {
            return;
        }
        if (!this.nestedContainer) {
            return;
        }
        this.editorContext.unregisterContainer(this.nestedContainer.container);
        this.nestedContainer = undefined;
    };
    GroupLayerComponent.prototype.updateNestedContainer = function () {
        if (!this.editorContext) {
            return;
        }
        if (!this.nestedContainer) {
            return;
        }
        this.nestedContainer.patchOptions({
            translate: {
                x: this.translate.x + this.layer.frame.x,
                y: this.translate.y + this.layer.frame.y
            }
        });
    };
    GroupLayerComponent.prototype.updateDisplayItems = function () {
        var _this = this;
        if (this.displayItemsSubscription) {
            this.displayItemsSubscription.unsubscribe();
            this.displayItemsSubscription = undefined;
        }
        var items$ = this.layer.layers
            .filter(function (item) { return item.visible; })
            .map(function (item) {
            var visible$ = item.visibleInput ? item.visible$({ context: _this.viewContext }) : of(true);
            return combineLatest(visible$).pipe(map(function (_a) {
                var visible = _a[0];
                return {
                    item: item,
                    visible: visible
                };
            }));
        });
        if (!items$.length) {
            this.displayItems = [];
            this.cd.markForCheck();
            return;
        }
        this.displayItemsSubscription = combineLatest(items$)
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.displayItems = items.filter(function (item) { return item.visible; }).map(function (item) { return item.item; });
            _this.cd.markForCheck();
        });
    };
    GroupLayerComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: this.layer.id,
                name: this.layer.name,
                icon: this.layer.icon
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: this.layer.name,
                icon: this.layer.icon
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    GroupLayerComponent.prototype.deleteLayer = function (index) {
        this.layer.layers.splice(index, 1);
        this.cd.markForCheck();
        this.editorContext.markLayerContainerChanged(this.layer.layers, ViewEditorCustomizeSource.View);
    };
    GroupLayerComponent.prototype.initFitContainer = function () {
        var _this = this;
        this.trackLayerChildrenFrames()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.editorContext.fitContainer(_this.layer, ViewEditorCustomizeSource.Layer);
        });
    };
    GroupLayerComponent.prototype.onFrameUpdate = function (frame) {
        if (!this.layer.flexLayout) {
            resizeLayers({
                layers: this.layer.layers,
                originalFrame: this.layer.frame,
                newFrame: new Frame(this.layer.frame).patch(frame)
            });
            this.editorContext.markLayersChanged(this.layer.layers, ViewEditorCustomizeSource.ParentLayer);
        }
        _super.prototype.onFrameUpdate.call(this, frame);
        this.updateNestedContainer();
    };
    return GroupLayerComponent;
}(LayerComponent));
export { GroupLayerComponent };
registerLayerComponent(LayerType.Group, GroupLayerComponent);

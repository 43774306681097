/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/menu";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i7 from "./projects-item-dropdown.component";
import * as i8 from "../../../project-settings/services/project-controller/project.controller";
var styles_ProjectsItemDropdownComponent = [];
var RenderType_ProjectsItemDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectsItemDropdownComponent, data: {} });
export { RenderType_ProjectsItemDropdownComponent as RenderType_ProjectsItemDropdownComponent };
function View_ProjectsItemDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "mat-menu-item-odd mat-menu-item"], ["mat-menu-item", ""]], [[1, "target", 0], [8, "href", 4], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._checkDisabled($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleMouseEnter() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 180224, null, 0, i4.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i5.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-design"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, 0, [" ", " "])), i0.ɵppd(5, 1)], function (_ck, _v) { var currVal_8 = _v.context.ngIf; _ck(_v, 1, 0, currVal_8); var currVal_9 = true; _ck(_v, 2, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; var currVal_2 = i0.ɵnov(_v, 2).role; var currVal_3 = i0.ɵnov(_v, 2)._highlighted; var currVal_4 = i0.ɵnov(_v, 2)._triggersSubmenu; var currVal_5 = i0.ɵnov(_v, 2)._getTabIndex(); var currVal_6 = i0.ɵnov(_v, 2).disabled.toString(); var currVal_7 = (i0.ɵnov(_v, 2).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), "Open builder")); _ck(_v, 4, 0, currVal_10); }); }
function View_ProjectsItemDropdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "mat-menu-item-odd mat-menu-item"], ["mat-menu-item", ""]], [[8, "href", 4], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, null, 0, i4.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i5.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-design"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, 0, [" ", " "])), i0.ɵppd(4, 1)], function (_ck, _v) { var currVal_7 = true; _ck(_v, 1, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getExternalHref(_v.context.ngIf); var currVal_1 = i0.ɵnov(_v, 1).role; var currVal_2 = i0.ɵnov(_v, 1)._highlighted; var currVal_3 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "Open builder")); _ck(_v, 3, 0, currVal_8); }); }
function View_ProjectsItemDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProjectsItemDropdownComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpod(3, { mode: 0, environmentName: 1 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProjectsItemDropdownComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpod(6, { mode: 0, environmentName: 1 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project.getHomeLinkWithProtocol(_ck(_v, 3, 0, _co.adminModes.Builder, (_co.environment ? _co.environment.uniqueName : undefined))).link; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.project.getHomeLinkWithProtocol(_ck(_v, 6, 0, _co.adminModes.Builder, (_co.environment ? _co.environment.uniqueName : undefined))).href; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ProjectsItemDropdownComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "mat-menu-item-odd mat-menu-item"], ["mat-menu-item", ""]], [[1, "target", 0], [8, "href", 4], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._checkDisabled($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleMouseEnter() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 180224, null, 0, i4.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i5.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-earth_planet"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, 0, [" ", "\n"])), i0.ɵppd(5, 1)], function (_ck, _v) { var currVal_8 = _v.context.ngIf; _ck(_v, 1, 0, currVal_8); var currVal_9 = true; _ck(_v, 2, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; var currVal_2 = i0.ɵnov(_v, 2).role; var currVal_3 = i0.ɵnov(_v, 2)._highlighted; var currVal_4 = i0.ɵnov(_v, 2)._triggersSubmenu; var currVal_5 = i0.ɵnov(_v, 2)._getTabIndex(); var currVal_6 = i0.ɵnov(_v, 2).disabled.toString(); var currVal_7 = (i0.ɵnov(_v, 2).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "View published")); _ck(_v, 4, 0, currVal_10); }); }
function View_ProjectsItemDropdownComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "mat-menu-item-odd mat-menu-item"], ["mat-menu-item", ""]], [[8, "href", 4], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, null, 0, i4.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i5.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon icon-earth_planet"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, 0, [" ", "\n"])), i0.ɵppd(4, 1)], function (_ck, _v) { var currVal_7 = true; _ck(_v, 1, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getExternalHref(_v.context.ngIf); var currVal_1 = i0.ɵnov(_v, 1).role; var currVal_2 = i0.ɵnov(_v, 1)._highlighted; var currVal_3 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "View published")); _ck(_v, 3, 0, currVal_8); }); }
function View_ProjectsItemDropdownComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "mat-menu-item-odd mat-menu-item-red mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.deleteProject() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, null, 0, i4.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i5.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "span", [["class", "choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, 0, [" ", "\n"])), i0.ɵppd(4, 1)], function (_ck, _v) { var currVal_6 = true; _ck(_v, 1, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).role; var currVal_1 = i0.ɵnov(_v, 1)._highlighted; var currVal_2 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "Delete App")); _ck(_v, 3, 0, currVal_7); }); }
export function View_ProjectsItemDropdownComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.LocalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsItemDropdownComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProjectsItemDropdownComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpod(5, { mode: 0, environmentName: 1 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ProjectsItemDropdownComponent_5)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpod(8, { mode: 0, environmentName: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsItemDropdownComponent_6)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.environment && _co.project.hasEnvironmentCustomizationPermission(_co.environment)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.project.getHomeLinkWithProtocol(_ck(_v, 5, 0, _co.adminModes.App, (_co.environment ? _co.environment.uniqueName : undefined))).link; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.project.getHomeLinkWithProtocol(_ck(_v, 8, 0, _co.adminModes.App, (_co.environment ? _co.environment.uniqueName : undefined))).href; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.project.defaultEnvironment && _co.project.hasEnvironmentSettingsPermission(_co.project.defaultEnvironment)); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_ProjectsItemDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-projects-item-dropdown", [], null, null, null, View_ProjectsItemDropdownComponent_0, RenderType_ProjectsItemDropdownComponent)), i0.ɵdid(1, 245760, null, 0, i7.ProjectsItemDropdownComponent, [i8.ProjectController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectsItemDropdownComponentNgFactory = i0.ɵccf("app-projects-item-dropdown", i7.ProjectsItemDropdownComponent, View_ProjectsItemDropdownComponent_Host_0, { project: "project", environment: "environment", externalToken: "externalToken" }, {}, []);
export { ProjectsItemDropdownComponentNgFactory as ProjectsItemDropdownComponentNgFactory };

<div class="dropdown-selector__search">
  <div class="dropdown-selector-search">
    <input
      type="text"
      class="dropdown-selector-search__input"
      [formControl]="searchControl"
      placeholder="Type to search..."
      [appAutofocus]="true"
      (keyup)="onSearchKey($event)"
    />
    <span class="dropdown-selector-search__icon icon-search"></span>
    <span
      *ngIf="searchControl.value | appIsSet"
      class="dropdown-selector-search__close icon-close"
      (click)="resetSearch()"
    ></span>
  </div>
</div>

<div *ngIf="loading" class="dropdown-selector__section">
  <div class="dropdown-selector__section-title">
    <span class="loading-animation"><span class="stub-text">custom pages</span></span>
  </div>
  <div class="dropdown-selector__section-content">
    <div
      *ngFor="let item of ['page', 'long page', 'page', 'page', 'long page', 'extra long page', 'page']"
      class="dropdown-selector__item dropdown-selector__item_disabled"
      (click)="chosen.emit(item.page)"
    >
      <div class="dropdown-selector__item-left">
        <div class="dropdown-selector__item-icon icon-dot"></div>
      </div>
      <div class="dropdown-selector__item-main">
        <div class="dropdown-selector__item-title">
          <span class="loading-animation"
            ><span class="stub-text">{{ item }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!loading">
  <div *ngFor="let group of filteredGroups" class="dropdown-selector__section">
    <div *ngIf="group.title | appIsSet" class="dropdown-selector__section-title">
      {{ group.title }}
    </div>
    <div class="dropdown-selector__section-content">
      <ng-container *ngFor="let item of group.items">
        <ng-container *ngIf="item.page">
          <div
            *ngIf="!currentPageAllowed && item.page.isSame(currentPage)"
            class="dropdown-selector__item dropdown-selector__item_disabled"
          >
            <div class="dropdown-selector__item-left">
              <div
                class="dropdown-selector__item-icon"
                [ngClass]="item.icon ? 'icon-' + item.icon : 'icon-document'"
              ></div>
            </div>
            <div class="dropdown-selector__item-main">
              <div class="dropdown-selector__item-title">
                {{ item.title }}
              </div>
            </div>
            <div class="dropdown-selector__item-right">
              <span class="dropdown-selector__item-optional">current page</span>
            </div>
          </div>

          <div
            *ngIf="currentPageAllowed || !item.page.isSame(currentPage)"
            class="dropdown-selector__item"
            (click)="chosen.emit(item.page)"
          >
            <div class="dropdown-selector__item-left">
              <div
                class="dropdown-selector__item-icon"
                [ngClass]="item.icon ? 'icon-' + item.icon : 'icon-document'"
              ></div>
            </div>
            <div class="dropdown-selector__item-main">
              <div class="dropdown-selector__item-title">
                {{ item.title }}
              </div>
            </div>
            <div class="dropdown-selector__item-right">
              <span *ngIf="item.page.isSame(currentPage)" class="dropdown-selector__item-optional">current page</span>
              <span
                *ngIf="!item.page.isSame(currentPage)"
                class="dropdown-selector__item-check icon-arrow_forward_2"
              ></span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!filteredGroups.length" class="dropdown-selector__message">
    Nothing found
  </div>
</ng-container>

import * as i0 from "@angular/core";
var UniqueIdToken = /** @class */ (function () {
    function UniqueIdToken() {
    }
    return UniqueIdToken;
}());
export { UniqueIdToken };
var UniqueIdService = /** @class */ (function () {
    // private ids = new Map();
    function UniqueIdService() {
        this.idCurrent = 0;
        this.ids = [];
    }
    UniqueIdService.prototype.getIdForInstance = function (instance) {
        var item = this.ids.find(function (i) { return i.instance === instance; });
        if (item) {
            return item.id;
        }
        this.ids.push({ instance: instance, id: this.idCurrent });
        return this.idCurrent++;
    };
    UniqueIdService.ngInjectableDef = i0.defineInjectable({ factory: function UniqueIdService_Factory() { return new UniqueIdService(); }, token: UniqueIdService, providedIn: "root" });
    return UniqueIdService;
}());
export { UniqueIdService };

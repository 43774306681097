import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[appDragPlaceholder]'
})
export class AppDragPlaceholder<T = any> {
  @Input() data: T;

  constructor(public templateRef: TemplateRef<T>) {}
}

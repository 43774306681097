import { FormControl, FormGroup } from '@angular/forms';
import round from 'lodash/round';

import { Frame, Translate } from '@modules/views';
import { isSet, setControlDisabled } from '@shared';

export class FrameControl extends FormGroup {
  instance: Frame;
  translate: Translate = { x: 0, y: 0 };

  controls: {
    x: FormControl;
    y: FormControl;
    width: FormControl;
    height: FormControl;
    rotation: FormControl;
  };

  constructor(state: Partial<Frame> = {}) {
    super({
      x: new FormControl(isSet(state.x) ? state.x : 0),
      y: new FormControl(isSet(state.y) ? state.y : 0),
      width: new FormControl(isSet(state.width) ? state.width : 0),
      height: new FormControl(isSet(state.height) ? state.height : 0),
      rotation: new FormControl(isSet(state.rotation) ? state.rotation : 0)
    });
  }

  deserialize(
    value: Frame,
    options: {
      translate?: Partial<Translate>;
      emitEvent?: boolean;
    } = {}
  ) {
    this.instance = value;
    this.translate = { x: 0, y: 0, ...options.translate };

    const x = value.x + this.translate.x;
    const y = value.y + this.translate.y;

    this.controls.x.patchValue(x, { emitEvent: options.emitEvent });
    this.controls.y.patchValue(y, { emitEvent: options.emitEvent });
    this.controls.width.patchValue(value.width, { emitEvent: options.emitEvent });
    this.controls.height.patchValue(value.height, { emitEvent: options.emitEvent });
    this.controls.rotation.patchValue(value.rotation, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Frame): Frame {
    if (!instance) {
      instance = new Frame();
    }

    const x = this.controls.x.value - this.translate.x;
    const y = this.controls.y.value - this.translate.y;

    instance.x = x;
    instance.y = y;
    instance.width = this.controls.width.value;
    instance.height = this.controls.height.value;
    instance.rotation = this.controls.rotation.value;

    return instance;
  }

  serialize(): Frame {
    return this.getInstance(this.instance);
  }

  getProportion(): number {
    const width = this.controls.width.value;
    const height = this.controls.height.value;

    return height != 0 ? width / height : 1;
  }

  updateWidthProportional(proportion: number) {
    if (!proportion) {
      return;
    }

    const height = this.controls.height.value;
    const width = round(height * proportion, 2);

    this.controls.width.patchValue(width);
  }

  updateHeightProportional(proportion: number) {
    if (!proportion) {
      return;
    }

    const width = this.controls.width.value;
    const height = round(width / proportion, 2);

    this.controls.height.patchValue(height);
  }

  setPositionDisabled(disabled: boolean) {
    setControlDisabled(this.controls.x, disabled);
    setControlDisabled(this.controls.y, disabled);
  }
}

<div
  class="customize-menu__data-menu"
  [class.customize-menu__data-menu_visible]="opened"
  (click)="markClickEvent($event)"
>
  <div class="customize-menu__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <app-sidebar-section>
      <div class="sidebar__list">
        <app-sidebar-field
          [label]="'Page Parameters'"
          [documentation]="'page-parameters'"
          [documentationLabel]="'See how it works'"
          [documentationLeft]="false"
        >
          <app-parameters-edit
            [control]="form.controls.parameters"
            [configurable]="{
              name: true,
              field: true,
              required: true,
              add: true,
              sortable: true
            }"
            [emptyBanner]="true"
            [analyticsSource]="analyticsSource"
          >
          </app-parameters-edit>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </div>
</div>

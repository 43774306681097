var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore } from '@shared';
import { FigmaService } from '../services/figma/figma.service';
var FigmaExternalImportListStore = /** @class */ (function (_super) {
    __extends(FigmaExternalImportListStore, _super);
    function FigmaExternalImportListStore(currentProjectStore, currentEnvironmentStore, figmaService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.figmaService = figmaService;
        _this.params = {};
        return _this;
    }
    FigmaExternalImportListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        params[PER_PAGE_PARAM] = this.perPage;
        return params;
    };
    FigmaExternalImportListStore.prototype.fetchPage = function (page, next) {
        var params = this.paramsForPage(page);
        return this.figmaService
            .getImports(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, params)
            .pipe(map(function (response) {
            return {
                items: response.results,
                hasMore: page < response.numPages,
                totalPages: response.numPages,
                perPage: response.perPage,
                count: response.count
            };
        }));
    };
    return FigmaExternalImportListStore;
}(ListStore));
export { FigmaExternalImportListStore };

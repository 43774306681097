import { Component } from '@angular/core';
import { DialogService } from '@common/dialogs';
import { ChangeComponent } from '../../components/change/change.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/dialogs/services/dialog/dialog.service";
var UnsavedChangesGuard = /** @class */ (function () {
    function UnsavedChangesGuard(dialogService) {
        this.dialogService = dialogService;
    }
    UnsavedChangesGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        if (!(component instanceof ChangeComponent)) {
            return true;
        }
        var changeComponent = component;
        if (changeComponent.hasChanges()) {
            return this.dialogService.confirm({
                title: 'Unsaved changes',
                description: 'Are you sure? You changes will be lost'
            });
        }
        return true;
    };
    UnsavedChangesGuard.ngInjectableDef = i0.defineInjectable({ factory: function UnsavedChangesGuard_Factory() { return new UnsavedChangesGuard(i0.inject(i1.DialogService)); }, token: UnsavedChangesGuard, providedIn: "root" });
    return UnsavedChangesGuard;
}());
export { UnsavedChangesGuard };

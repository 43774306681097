import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { DateFormat, defaultDateFormat, defaultTimeFormat, Input, TimeFormat } from '@modules/fields';
import { isSet } from '@shared';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class DateRangeElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.DateRange;
  public width: number;
  public verboseName: string;
  public from: Input;
  public to: Input;
  public time = true;
  public date_format: DateFormat | string = defaultDateFormat;
  public time_format: TimeFormat | string = defaultTimeFormat;
  public required = false;
  public from_placeholder = '';
  public to_placeholder = '';
  public resetEnabled = false;
  public disableInput: Input;
  public tooltip: string;
  public onChangeActions: ActionItem[] = [];

  deserialize(data: Object): DateRangeElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.verboseName = this.params['verbose_name'];
    this.required = this.params['required'];
    this.tooltip = this.params['tooltip'];

    if (this.params['from']) {
      this.from = new Input().deserialize(this.params['from']);
    }

    if (this.params['to']) {
      this.to = new Input().deserialize(this.params['to']);
    }

    if (isSet(this.params['time'])) {
      this.time = this.params['time'];
    }

    if (isSet(this.params['date_format'], true)) {
      this.date_format = this.params['date_format'];
    }

    if (isSet(this.params['time_format'], true)) {
      this.time_format = this.params['time_format'];
    }

    if (isSet(this.params['from_placeholder'], true)) {
      this.from_placeholder = this.params['from_placeholder'];
    }

    if (isSet(this.params['to_placeholder'], true)) {
      this.to_placeholder = this.params['to_placeholder'];
    }

    if (isSet(this.params['reset_enabled'])) {
      this.resetEnabled = this.params['reset_enabled'];
    }

    if (this.params['disable_input']) {
      this.disableInput = new Input().deserialize(this.params['disable_input']);
    }

    if (this.params['on_change_actions']) {
      this.onChangeActions = this.params['on_change_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      verbose_name: this.verboseName,
      from: this.from ? this.from.serialize() : null,
      to: this.to ? this.to.serialize() : null,
      time: this.time,
      date_format: this.date_format,
      time_format: this.time_format,
      required: this.required,
      from_placeholder: this.from_placeholder,
      to_placeholder: this.to_placeholder,
      reset_enabled: this.resetEnabled,
      disable_input: this.disableInput ? this.disableInput.serialize() : null,
      tooltip: this.tooltip,
      on_change_actions: this.onChangeActions.map(item => item.serialize())
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'date range';
  }

  get analyticsName(): string {
    return 'date_range';
  }

  defaultName() {
    return 'Date range';
  }
}

registerElementForType(ElementType.DateRange, DateRangeElementItem);

<a
  href="javascript:void(0)"
  class="guide-widget"
  [class.guide-widget_visible]="visible"
  [class.guide-widget_shift]="
    (intercomService.visible$ | async) || (currentProjectStore.instance$ | async)?.jobs?.length
  "
  [class.guide-widget_shift-double]="
    (intercomService.visible$ | async) && (currentProjectStore.instance$ | async)?.jobs?.length
  "
  (click)="openTask()"
>
  <span class="guide-widget__icon icon icon-check_3"></span>
  <ng-container *ngIf="currentTask">{{ currentTask.title }}</ng-container>
</a>

<div
  class="guide-message"
  [class.guide-message_visible]="completedTaskVisible"
  [class.guide-message_expanded]="firstTaskCompleted"
>
  <div class="guide-message__title">
    <ng-container *ngIf="firstTaskCompleted">First Step</ng-container>
    <ng-container *ngIf="!firstTaskCompleted">Congratulations!</ng-container>
  </div>
  <ng-container *ngIf="completedTask">
    <div class="guide-message__description">
      <ng-container *ngIf="firstTaskCompleted">
        You got your first knowledge about <strong>Jet Admin</strong>, go to <strong>Setup Guide</strong> to discover
        all operations you can do to tune <strong>Jet Admin</strong> for your needs.<br /><br />
        You can always return to <strong>Setup Guide</strong> from the <strong>Help section</strong> of the
        <strong>Left menu</strong>.
      </ng-container>
      <ng-container *ngIf="!firstTaskCompleted">
        You have successfully completed task: «{{ completedTask.title }}»
      </ng-container>
    </div>
  </ng-container>
  <div class="guide-message__buttons">
    <ng-container *ngIf="firstTaskCompleted">
      <a
        [appLink]="setupGuideLink"
        class="guide-message__buttons-item guide-message__buttons-item_primary"
        (click)="closeCompleted()"
      >
        Setup Guide
      </a>
      <a href="javascript:void(0)" class="guide-message__buttons-item" (click)="closeCompleted()">
        Cancel
      </a>
    </ng-container>
    <ng-container *ngIf="!firstTaskCompleted">
      <a
        href="javascript:void(0)"
        class="guide-message__buttons-item guide-message__buttons-item_primary"
        (click)="closeCompleted()"
      >
        OK
      </a>
      <a [appLink]="setupGuideLink" class="guide-message__buttons-item" (click)="closeCompleted()">
        Other tasks
      </a>
    </ng-container>
  </div>
</div>

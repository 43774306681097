<div
  class="sidebar-list sidebar-list_third"
  appDropList
  [appDropListConnectedToGroup]="dropListGroups"
  [appDropListData]="displayItems"
  [appDropListEnterPredicate]="isDroppable"
  [appDropListSortingDisabled]="true"
  [appDropListCloneItems]="true"
  [appDropListOutsideIndicator]="true"
  (appDropListDragged)="clearSearch.next()"
>
  <ng-container *ngFor="let item of displayItems">
    <div
      *ngIf="display == displays.Tiles"
      appDrag
      [appDragData]="item"
      class="sidebar-list__item sidebar-list__item_third"
    >
      <app-element-placeholder-stub
        *appDragPlaceholder
        [elementType]="item.type"
        class="element-placeholder"
      ></app-element-placeholder-stub>

      <app-sidebar-image-card [title]="item.title" [image]="item.image" [icon]="item.icon"></app-sidebar-image-card>
    </div>

    <div *ngIf="display == displays.List" appDrag [appDragData]="item" class="sidebar-list__item">
      <app-element-placeholder-stub
        *appDragPlaceholder
        [elementType]="item.type"
        class="element-placeholder"
      ></app-element-placeholder-stub>

      <div class="sidebar-list-item sidebar-list-item_draggable">
        <div class="sidebar-list-item__left sidebar-list-item__left_large">
          <span *ngIf="item.icon" class="sidebar-list-item__icon" [ngClass]="['icon-' + item.icon]"></span>
          <span
            *ngIf="item.image"
            class="sidebar-list-item__image sidebar-list-item__image_large"
            [style.background-image]="'/assets/images/widgets/' + item.image + '.svg' | appDeployCssUrl"
          >
          </span>
        </div>
        <div class="sidebar-list-item__main">
          <div class="sidebar-list-item__title">{{ item.title }}</div>
          <div *ngIf="item.subtitle" class="sidebar-list-item__description">{{ item.subtitle | appCapitalize }}</div>
        </div>
        <div class="sidebar-list-item__right">
          <span class="sidebar-list-item__action icon-dots_small"></span>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="collapsible && items.length > showCollapsedMax" class="sidebar-list__buttons">
    <a
      *ngIf="collapsed"
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(false)"
    >
      <span class="icon-arrow_down_2 button__icon button__icon_left"></span>
      <span class="button__label">Show all {{ title }} ({{ items.length }})</span>
    </a>
    <a
      *ngIf="!collapsed"
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(true)"
    >
      <span class="icon-arrow_up_2 button__icon button__icon_left"></span>
      <span class="button__label">Collapse {{ title }}</span>
    </a>
  </div>
</div>

import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { isContainerLayer } from '@modules/views';
import { KeyboardEventKeyCode } from '@shared';
import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';
var ViewEditorMenuEditComponent = /** @class */ (function () {
    function ViewEditorMenuEditComponent(editorContext, cd) {
        this.editorContext = editorContext;
        this.cd = cd;
        this.close = new EventEmitter();
        this.dropdownHover$ = new BehaviorSubject(false);
        this.dropdownOpened = false;
        this.dropdownPositions = [
            { originX: 'end', overlayX: 'start', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'start', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
            { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 }
        ];
    }
    ViewEditorMenuEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(merge(fromEvent(this.trigger.elementRef.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(this.trigger.elementRef.nativeElement, 'mouseleave').pipe(map(function () { return false; }))), this.dropdownHover$)
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var triggerHover = _a[0], dropdownHover = _a[1];
            _this.setDropdownOpened(triggerHover || dropdownHover);
        });
    };
    ViewEditorMenuEditComponent.prototype.ngOnDestroy = function () { };
    ViewEditorMenuEditComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    ViewEditorMenuEditComponent.prototype.toggleDropdownOpened = function () {
        this.setDropdownOpened(!this.dropdownOpened);
    };
    ViewEditorMenuEditComponent.prototype.undo = function () {
        if (this.editorContext.isUndoAvailable()) {
            this.editorContext.undo();
        }
    };
    ViewEditorMenuEditComponent.prototype.redo = function () {
        if (this.editorContext.isRedoAvailable()) {
            this.editorContext.redo();
        }
    };
    ViewEditorMenuEditComponent.prototype.isBufferLayersSet = function () {
        return this.editorContext.getBufferLayers().length > 0;
    };
    ViewEditorMenuEditComponent.prototype.cutLayers = function () {
        var initDict = { key: 'x', metaKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.X;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.copyLayers = function () {
        var initDict = { key: 'c', metaKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.C;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.pasteBufferLayers = function () {
        var initDict = { key: 'v', metaKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.V;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.isLayerSelected = function () {
        return this.editorContext.customizingLayers$.value.length > 0;
    };
    ViewEditorMenuEditComponent.prototype.isUngroupAvailable = function () {
        return this.editorContext.customizingLayers$.value.some(function (item) { return isContainerLayer(item); });
    };
    ViewEditorMenuEditComponent.prototype.isSelectAllAvailable = function () {
        var _this = this;
        return (this.editorContext.view$.value.layers.length &&
            !this.editorContext.view$.value.layers.every(function (layer) {
                return !!_this.editorContext.customizingLayers$.value.find(function (item) { return item.isSame(layer); });
            }));
    };
    ViewEditorMenuEditComponent.prototype.isSelectInverseAvailable = function () {
        return this.editorContext.view$.value.layers.length > 0;
    };
    ViewEditorMenuEditComponent.prototype.groupSelected = function () {
        var initDict = { key: 'g', metaKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.G;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.frameSelected = function () {
        var initDict = { key: 'g', metaKey: true, altKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.G;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.ungroupSelected = function () {
        var initDict = { key: 'backspace', metaKey: true };
        initDict['keyCode'] = KeyboardEventKeyCode.Backspace;
        var event = new KeyboardEvent('keydown', initDict);
        this.editorContext.emitKeydownEvent(event);
    };
    ViewEditorMenuEditComponent.prototype.selectAll = function () {
        var _a;
        var layers = this.editorContext.view$.value.layers;
        (_a = this.editorContext).setCustomizingLayer.apply(_a, layers);
    };
    ViewEditorMenuEditComponent.prototype.selectNone = function () {
        this.editorContext.resetCustomizingLayers();
    };
    ViewEditorMenuEditComponent.prototype.selectInverse = function () {
        var _this = this;
        var _a;
        var layers = this.editorContext.view$.value.layers.filter(function (layer) {
            return !_this.editorContext.customizingLayers$.value.find(function (item) { return item.isSame(layer); });
        });
        (_a = this.editorContext).setCustomizingLayer.apply(_a, layers);
    };
    ViewEditorMenuEditComponent.prototype.onItemSelected = function () {
        this.setDropdownOpened(false);
        this.close.emit();
    };
    return ViewEditorMenuEditComponent;
}());
export { ViewEditorMenuEditComponent };

<div *ngIf="!resourceField" class="sidebar__element">
  <div class="sidebar__element-segment">
    <app-sidebar-field [label]="'related collection'" [required]="true" [classes]="'component-related-resource'">
      <app-resource-field2
        [form]="form"
        [label]="false"
        [errors]="false"
        [field]="
          createField({
            name: 'related_resource',
            field: 'ResourceField',
            params: { model_resources: true, classes: ['select_fill'] }
          })
        "
      ></app-resource-field2>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element-segment">
    <app-sidebar-field [classes]="'component-related-collection'">
      <app-custom-select
        [control]="form.controls.related_model"
        [items]="resourceModelItems"
        [emptyLabel]="'No collections'"
        [classes]="['select_fill']"
        [panelClasses]="['mat-menu-panel_sidebar']"
      >
      </app-custom-select>
      <app-field-errors [form]="form" [fieldName]="'related_model'"></app-field-errors>

      <!--      <ng-container right>-->
      <!--        <a-->
      <!--          *ngIf="form.form.value['model'] && editableQueryTypes.includes(form.form.value['model']?.queryType)"-->
      <!--          href="javascript:void(0)"-->
      <!--          class="button button_bigger button_icon button_orange-alternative sidebar-field__button"-->
      <!--          [appTip]="'Edit request'"-->
      <!--          (click)="editQuery()"-->
      <!--        >-->
      <!--          <span class="icon-edit button__icon"></span>-->
      <!--        </a>-->
      <!--      </ng-container>-->
    </app-sidebar-field>
  </div>
</div>

<div *ngIf="!resourceField && modelDescription" class="sidebar__element">
  <app-sidebar-card>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'display field'">
        <ng-container actions>
          <span
            class="sidebar__field-action sidebar__field-action_button icon-function"
            [class.sidebar__field-action_active]="form.controls.custom_display_input_enabled.value"
            [appTip]="form.controls.custom_display_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
            [appTipOptions]="{ side: 'top' }"
            (click)="
              form.controls.custom_display_input_enabled.patchValue(!form.controls.custom_display_input_enabled.value)
            "
          ></span>
        </ng-container>

        <app-custom-select
          *ngIf="!form.controls.custom_display_input_enabled.value"
          [control]="form.controls.custom_display_field"
          [items]="columnOptions"
          [emptyLabel]="'No fields found'"
          [classes]="['select_fill']"
          [panelClasses]="['mat-menu-panel_sidebar']"
        >
        </app-custom-select>

        <app-input-edit
          *ngIf="form.controls.custom_display_input_enabled.value"
          [itemForm]="form.controls.custom_display_input"
          [context]="context"
          [contextElement]="columnContextElement"
          [contextElementPath]="columnContextElementPath"
          [staticValueDisabled]="true"
          [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
          [jsPlaceholder]="'return `${item.name}`;'"
          [fill]="true"
          [analyticsSource]="analyticsSource + 'custom_display_field'"
        ></app-input-edit>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'related field'">
        <app-custom-select
          [control]="form.controls.custom_primary_key"
          [items]="columnOptions"
          [emptyLabel]="'No fields found'"
          [classes]="['select_fill']"
          [panelClasses]="['mat-menu-panel_sidebar']"
        >
        </app-custom-select>

        <app-field-errors [form]="form" [fieldName]="'custom_primary_key'"></app-field-errors>
      </app-sidebar-field>
    </div>

    <div *ngIf="editable" class="sidebar__element">
      <app-sidebar-field [label]="'subtitle'" [additional]="'(optional)'">
        <ng-container actions>
          <span
            class="sidebar__field-action sidebar__field-action_button icon-function"
            [class.sidebar__field-action_active]="form.controls.subtitle_input_enabled.value"
            [appTip]="form.controls.subtitle_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
            [appTipOptions]="{ side: 'top' }"
            (click)="form.controls.subtitle_input_enabled.patchValue(!form.controls.subtitle_input_enabled.value)"
          ></span>
        </ng-container>

        <app-custom-select
          *ngIf="!form.controls.subtitle_input_enabled.value"
          [control]="form.controls.subtitle_field"
          [items]="columnOptions"
          [emptyLabel]="'No fields found'"
          [resetEnabled]="true"
          [classes]="['select_fill']"
          [panelClasses]="['mat-menu-panel_sidebar']"
        >
        </app-custom-select>

        <app-input-edit
          *ngIf="form.controls.subtitle_input_enabled.value"
          [itemForm]="form.controls.subtitle_input"
          [context]="context"
          [contextElement]="columnContextElement"
          [contextElementPath]="columnContextElementPath"
          [staticValueDisabled]="true"
          [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
          [jsPlaceholder]="'return `${item.name}`;'"
          [fill]="true"
          [analyticsSource]="analyticsSource + 'subtitle_field_input'"
        ></app-input-edit>
      </app-sidebar-field>
    </div>

    <div *ngIf="editable" class="sidebar__element">
      <app-sidebar-field [label]="'icon'" [additional]="'(optional)'">
        <ng-container actions>
          <span
            class="sidebar__field-action sidebar__field-action_button icon-function"
            [class.sidebar__field-action_active]="form.controls.icon_input_enabled.value"
            [appTip]="form.controls.icon_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
            [appTipOptions]="{ side: 'top' }"
            (click)="form.controls.icon_input_enabled.patchValue(!form.controls.icon_input_enabled.value)"
          ></span>
        </ng-container>

        <app-auto-field
          *ngIf="!form.controls.icon_input_enabled.value"
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'icon_field',
              label: 'Icon',
              field: 'IconField',
              params: { classes: ['select_fill'], allow_empty: true }
            })
          "
        ></app-auto-field>

        <app-input-edit
          *ngIf="form.controls.icon_input_enabled.value"
          [itemForm]="form.controls.icon_input"
          [context]="context"
          [contextElement]="columnContextElement"
          [contextElementPath]="columnContextElementPath"
          [staticValueDisabled]="true"
          [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
          [jsPlaceholder]="'return `${item.name}`;'"
          [fill]="true"
          [analyticsSource]="analyticsSource + 'icon_field_input'"
        ></app-input-edit>
      </app-sidebar-field>
    </div>
  </app-sidebar-card>
</div>

<!--<div class="sidebar__element">-->
<!--  <app-sidebar-field [label]="'Field Value transformer'">-->
<!--    <ng-container actions>-->
<!--      <a-->
<!--        href="javascript:void(0)"-->
<!--        class="sidebar__link"-->
<!--        style="margin-right: 10px; float: right;"-->
<!--        (click)="togglePrimaryKeyTransformerVisible()"-->
<!--      >-->
<!--        <ng-container *ngIf="form.isPrimaryKeyTransformerChanged()">-->
<!--          <ng-container *ngIf="primaryKeyTransformerVisible">Hide</ng-container>-->
<!--          <ng-container *ngIf="!primaryKeyTransformerVisible">Show</ng-container>-->
<!--        </ng-container>-->
<!--        <ng-container *ngIf="!form.isPrimaryKeyTransformerChanged()">-->
<!--          <ng-container *ngIf="primaryKeyTransformerVisible">Disable</ng-container>-->
<!--          <ng-container *ngIf="!primaryKeyTransformerVisible">Enable</ng-container>-->
<!--        </ng-container>-->
<!--      </a>-->
<!--    </ng-container>-->

<!--    <app-auto-field-->
<!--      *ngIf="primaryKeyTransformerVisible"-->
<!--      [form]="form.form"-->
<!--      [label]="false"-->
<!--      [field]="-->
<!--        createField({-->
<!--          name: 'foreign_key_transformer',-->
<!--          field: 'CodeField',-->
<!--          params: {-->
<!--            autogrow: true,-->
<!--            field: true,-->
<!--            field_contrast: true,-->
<!--            wrap: true-->
<!--          }-->
<!--        })-->
<!--      "-->
<!--    ></app-auto-field>-->
<!--  </app-sidebar-field>-->
<!--</div>-->

<!--<div class="sidebar__element">-->
<!--  <app-sidebar-field [label]="'Display Create button'" [layoutLeft]="true">-->
<!--    <app-auto-field-->
<!--      [form]="form.form"-->
<!--      [label]="false"-->
<!--      [field]="-->
<!--        createField({-->
<!--          name: 'create_button',-->
<!--          field: 'BooleanField',-->
<!--          params: { style: booleanFieldStyle.Toggle, orange: true }-->
<!--        })-->
<!--      "-->
<!--    ></app-auto-field>-->
<!--  </app-sidebar-field>-->
<!--</div>-->

<div
  *ngIf="showMentionsList"
  class="timeline-popup__mention-list dropdown-selector dropdown-selector_scrollable"
  [style.max-height.px]="400"
  xsScrollable
  [xsScrollableOptions]="{ stopPropagationAlways: true }"
  infiniteScroll
  (scrolled)="projectUserListStore.getNext()"
>
  <div
    *ngFor="let item of projectUserListStore.items$ | async"
    class="dropdown-selector__item"
    (click)="selectMention(item.user)"
  >
    <div class="dropdown-selector__item-left">
      <div
        class="dropdown-selector__item-photo"
        [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
      ></div>
    </div>
    <div class="dropdown-selector__item-main">
      <div class="dropdown-selector__item-title">{{ item.getFullName() }}</div>
    </div>
  </div>
</div>

<form class="timeline-popup__form" [formGroup]="form" (ngSubmit)="submit()" #sendForm>
  <div class="timeline-popup__form-top" #sendFormTop>
    <div
      class="timeline-popup__form-avatar"
      [style.background-image]="
        (currentUserStore.original$ | async).photo ? 'url(' + (currentUserStore.original$ | async).photo + ')' : ''
      "
    ></div>
    <textarea
      cdkTextareaAutosize
      cdkAutosizeMaxRows="10"
      (keyup)="keyUp($event)"
      (focus)="onFocus(true)"
      (blur)="onFocus(false)"
      #messageInput
      type="text"
      class="timeline-popup__input-message"
      [placeholder]="'Your Message' | localize"
      formControlName="message"
      (click)="moveTextCursor()"
    ></textarea>
  </div>

  <div class="timeline-popup__form-bottom" #sendFormBottom>
    <div class="timeline-popup__form-tools">
      <!--          <span class="icon-attach_clip timeline-popup__form-icon"></span>-->
      <!--          <span class="icon-cherry timeline-popup__form-icon"></span>-->
      <span class="timeline-popup__form-icon" (click)="addAt($event)">@</span>
      <!--          <span class="icon-number timeline-popup__form-icon"></span>-->
    </div>
    <button
      type="submit"
      [disabled]="!form.valid || disabled"
      class="button button_small button_primary timeline-popup__form-button"
      [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
      [appButtonTintColorStyle]="tintStyles.Primary"
    >
      <span class="icon-sending"></span>
      <span class="timeline-popup__form-button-text">{{ 'Send' | localize }}</span>
    </button>
  </div>
</form>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-template-preview-custom',
  templateUrl: './page-template-preview-custom.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewCustomComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

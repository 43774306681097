import { isSet } from '@shared';

import { Automation } from './automation';
import { Workflow } from './workflow';
import { WorkflowBackendRun } from './workflow-backend-run';

export class WorkflowBackendRunWithRelations extends WorkflowBackendRun {
  public automation: Automation;
  public workflow: Workflow;

  constructor(options: Partial<WorkflowBackendRunWithRelations> = {}) {
    super(options);
  }

  deserialize(data: Object) {
    super.deserialize(data);

    if (data['automation']) {
      this.automation = new Automation().deserialize(data['automation']);
    }

    if (data['workflow']) {
      this.workflow = new Workflow().deserialize(data['workflow']['params']);
    }

    return this;
  }

  getName() {
    const name = [];

    if (this.automation && isSet(this.automation.name)) {
      name.push(this.automation.name);
    } else if (this.workflow && isSet(this.workflow.name)) {
      name.push(this.workflow.name);
    }

    name.push(this.getLastDate().format('lll'));

    return name.join(' - ');
  }
}

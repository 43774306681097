<div class="project-resource-setting">
  <ng-container *ngIf="loading">
    <div class="project-resource-setting__header">
      <div class="project-resource-setting__logo" [style.background-image]="'url(' + resource.icon + ')'"></div>
      <div class="project-resource-setting__title">
        <span class="loading-animation"><span class="stub-text">Components imported</span></span>
      </div>
    </div>

    <div *ngFor="let tab of stubTabs" class="project-resource-setting__content">
      <div class="card-grid">
        <div class="card-grid__header">
          <div class="card-grid__title">
            <span class="loading-animation"
              ><span class="stub-text">{{ tab.name }}</span></span
            >
          </div>
        </div>
        <div class="card-grid__container">
          <div *ngFor="let item of tab.items" class="card-grid__item">
            <div class="card-product">
              <span class="card-product__icon loading-animation">
                <span class="stub-image stub-image_contrast"></span>
              </span>
              <span class="card-product__title">
                <span class="loading-animation"
                  ><span class="stub-text">{{ item.name }}</span></span
                >
              </span>
              <span *ngIf="item.description" class="card-product__descr">
                <span class="loading-animation"
                  ><span class="stub-text">{{ item.description }}</span></span
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="project-resource-setting__footer">
      <div class="project-resource-setting__footer-right">
        <a href="javascript:void(0)" class="button button_primary" (click)="close()">
          Continue
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <div class="project-resource-setting__header">
      <div class="project-resource-setting__logo" [style.background-image]="'url(' + resource.icon + ')'"></div>
      <div class="project-resource-setting__title">Use {{ resource.name }} components to build your App</div>
      <!--      <div class="project-resource-setting__actions">-->
      <!--        <a href="javascript:void(0)" class="button button_primary" (click)="close()">-->
      <!--          Continue-->
      <!--        </a>-->
      <!--      </div>-->
    </div>

    <div class="project-resource-setting__tabs">
      <div class="border-tabs">
        <a
          href="javascript:void(0)"
          *ngFor="let tab of tabs; let i = index"
          class="border-tabs__item"
          [class.border-tabs__item_active]="currentTab === tab"
          (click)="openTab(i)"
        >
          {{ tab.name }}
        </a>
      </div>
    </div>

    <div *ngFor="let tab of tabs" class="project-resource-setting__content">
      <div class="card-grid">
        <div class="card-grid__header">
          <div class="card-grid__title">{{ tab.name }}</div>
          <div class="card-grid__counter">({{ tab.items.length }})</div>
        </div>
        <div class="card-grid__container" #section_grid>
          <div *ngFor="let item of tab.items" class="card-grid__item">
            <div class="card-product">
              <span class="card-product__icon" [ngClass]="['card-product__icon_' + (item.icon || 'collection')]"></span>
              <span class="card-product__title">{{ item.name }}</span>
              <span *ngIf="item.description" class="card-product__descr">{{ item.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="project-resource-setting__footer">
      <div class="project-resource-setting__footer-right">
        <a href="javascript:void(0)" class="button button_primary" (click)="close()">
          Continue
        </a>
      </div>
    </div>
  </ng-container>
</div>

import { Injectable, Input, NgZone, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { auditTime, debounceTime, filter, map, pairwise, shareReplay, switchMap } from 'rxjs/operators';

import { AppDropListGroup } from '@common/drag-drop2';
import { environment } from '@env/environment';
import { ViewContextAction, ViewSettings } from '@modules/customize';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, ProjectPropertyStorage, Resource } from '@modules/projects';
import { firstSet } from '@shared';

import { ElementItem } from './elements/items/base';
import { ViewContextElement } from './view-context-element';
import { ViewContextElementType } from './view-context-element-type';
import { ViewContextOutput } from './view-context-output';
import { ViewContextToken } from './view-context-token';

export interface ContextItem {
  element: ViewContextElement;
  parent?: ViewContextElement;
}

@Injectable()
export class ViewContext implements OnDestroy {
  parent: ViewContext;
  resource: Resource;
  modelDescription: ModelDescription;
  model: Model;
  models: Model[];
  viewSettings: ViewSettings;
  pageStorage: ProjectPropertyStorage;
  dropListGroups: AppDropListGroup[] = [];
  form: FormGroup;
  // TODO: Move to localContext
  clickEvent: MouseEvent;
  objectType: string;
  objectId: string;
  updateInterval = 60;

  private _elements = new BehaviorSubject<ContextItem[]>([]);
  private _elementsPaused$ = new BehaviorSubject<boolean>(false);
  private _sharedElements$: Observable<ContextItem[]>;
  private _outputValues = {};
  private _sharedOutputs$: Observable<{ item: ContextItem; outputs: ViewContextOutput[] }[]>;
  private _sharedOutputValues$: Observable<Object>;

  constructor(public readonly currentEnvironmentStore: CurrentEnvironmentStore) {
    this._sharedElements$ = this.getElements$();
    this._sharedOutputs$ = this.getOutputs$();
    this._sharedOutputValues$ = this.getOutputValues$();

    // if (!environment.production) {
    //   this.outputs$.pipe(untilDestroyed(this)).subscribe(value => {
    //     window['jet_ctx'] = value;
    //   });
    // }
  }

  ngOnDestroy(): void {}

  clear(type?: ViewContextElementType) {
    if (type) {
      this._elements.next(this._elements.value.filter(item => item.element.type != type));
    } else {
      this._elements.next([]);
    }
  }

  getElements$(): Observable<ContextItem[]> {
    return merge(
      this._elements.pipe(filter(() => !this._elementsPaused$.value)),
      this._elementsPaused$.pipe(
        pairwise(),
        filter(([prev, current]) => prev && !current),
        map(() => this._elements.value)
      )
    ).pipe(shareReplay(1));
  }

  get elements(): ContextItem[] {
    return this._elements.value;
  }

  get elements$(): Observable<ContextItem[]> {
    return this._sharedElements$;
  }

  pauseElements() {
    if (!this._elementsPaused$.value) {
      this._elementsPaused$.next(true);
    }
  }

  resumeElements() {
    if (this._elementsPaused$.value) {
      this._elementsPaused$.next(false);
    }
  }

  // TODO: Refactor
  getElementItems(): ElementItem[] {
    return this.elements
      .filter(i => i.element.type == ViewContextElementType.Element)
      .map(i => {
        const elementItem = new ElementItem();
        elementItem.uid = i.element.uniqueName;
        elementItem.name = i.element.name;
        return elementItem;
      });
  }

  isRegisteredElement(element: ViewContextElement): boolean {
    return this._elements.value.some(item => item.element === element);
  }

  registerElement(element: ViewContextElement, parent?: ViewContextElement) {
    if (this._elements.value.find(item => item.element === element)) {
      return;
    }

    this._elements.next([
      ...this._elements.value,
      {
        element: element,
        parent: parent
      }
    ]);
    this.markHasChanges();
  }

  unregisterElement(element: ViewContextElement) {
    const value = this._elements.value;
    const removeItems = [];
    const removeWithChildren = (parent: ViewContextElement) => {
      removeItems.push(parent);
      value.filter(item => item.parent === parent).forEach(item => removeWithChildren(item.element));
    };

    removeWithChildren(element);
    this._elements.next(value.filter(item => !removeItems.includes(item.element)));
    this.markHasChanges();
  }

  markElementsHasChanges() {
    this._elements.next(this._elements.value);
  }

  markHasChanges() {
    this._outputValues = undefined;
  }

  getElementPath(element: ViewContextElement): (string | number)[] {
    if (!element) {
      return;
    }

    let contextElement = this._elements.value.find(item => item.element === element);
    const pathReversed = [];

    while (true) {
      if (!contextElement) {
        return;
      }

      pathReversed.push(contextElement.element.uniqueName);

      if (!contextElement.parent) {
        break;
      }

      contextElement = this._elements.value.find(item => item.element === contextElement.parent);
    }

    return ['elements', ...pathReversed.reverse()];
  }

  getElementChildren(parent?: ViewContextElement): ViewContextElement[] {
    return this._elements.value.filter(item => item.parent === parent).map(item => item.element);
  }

  getElement$(uniqueName: string): ViewContextElement {
    const element = this._elements.value.find(item => item.element.uniqueName === uniqueName);
    return element ? element.element : undefined;
  }

  getOutputTokens$(
    human = false,
    showInternal = false,
    showExternal = true,
    allowSkip = true,
    forPaths?: (string | number)[][]
  ): Observable<ViewContextToken[]> {
    const mapOutputs = (
      element: ViewContextElement,
      items: ViewContextOutput[],
      prefix: (string | number)[],
      insideExternal = false
    ) => {
      const result = items.filter(item => {
        const token = [...prefix, item.uniqueName];
        return !item.external || showExternal || (forPaths && forPaths.find(path => isEqual(path, token)));
      });
      const singleResult = result.length == 1 ? result[0] : undefined;

      if (allowSkip && singleResult && singleResult.allowSkip && singleResult.children) {
        const token = [...prefix, singleResult.uniqueName];
        return mapOutputs(element, singleResult.children, token, insideExternal || singleResult.external);
      } else {
        return result.map(item => {
          const token = [...prefix, item.uniqueName];

          return {
            name: firstSet(item.name, item.uniqueName),
            subtitle: item.subtitle,
            uniqueName: item.uniqueName,
            icon: item.icon,
            iconOrange: item.iconOrange,
            fieldType: item.fieldType,
            fieldParams: item.fieldParams,
            token: token,
            element: element,
            documentation: item.documentation,
            action: item.action,
            children: item.children
              ? mapOutputs(element, item.children, token, insideExternal || item.external)
              : undefined
          };
        });
      }
    };

    return this.outputs$.pipe(
      map(elements => {
        const iterateElements = (
          elementType: ViewContextElementType,
          prefix?: string,
          parent?: ViewContextElement,
          path: (string | number)[] = []
        ): ViewContextToken[] => {
          return elements
            .filter(item => item.item.element.type == elementType)
            .filter(item => item.item.parent === parent)
            .map(item => {
              const itemPathItem =
                human && elementType == ViewContextElementType.Element && !parent
                  ? item.item.element.name
                  : item.item.element.uniqueName;
              const fullPath = [...path, itemPathItem];
              const prefixedFullPath = [...(prefix ? [prefix] : []), ...path, itemPathItem];

              return {
                name: item.item.element.name,
                icon: item.item.element.icon,
                uniqueName: itemPathItem,
                element: item.item.element,
                allowSkip: item.item.element.allowSkip,
                token: item.item.element.insert ? fullPath : undefined,
                documentation: item.item.element.documentation,
                action: item.item.element.action,
                children: [
                  ...iterateElements(elementType, prefix, item.item.element, fullPath),
                  ...mapOutputs(
                    item.item.element,
                    item.outputs
                      .filter(output => {
                        if (!output.internal) {
                          return true;
                        }

                        return showInternal;
                      })
                      .filter(output => {
                        if (!output.byPathOnly) {
                          return true;
                        }

                        if (!forPaths) {
                          return false;
                        }

                        const outputPath = [...prefixedFullPath, output.uniqueName];
                        return forPaths.some(forPath => isEqual(outputPath, forPath));
                      }),
                    prefixedFullPath
                  )
                ]
              };
            });
        };

        const tokens = [
          ...iterateElements(ViewContextElementType.Global),
          {
            name: 'Components',
            uniqueName: 'elements',
            children: iterateElements(ViewContextElementType.Element, 'elements').filter(
              item => item.children && item.children.length
            )
          }
        ];

        if (this.model && this.modelDescription) {
          tokens.push({
            name: 'Record',
            uniqueName: 'record',
            children: this.modelDescription.dbFields.map(item => {
              return {
                name: item.verboseName || item.name,
                uniqueName: item.name,
                icon: item.fieldDescription ? item.fieldDescription.icon : undefined,
                fieldType: item.field,
                fieldParams: item.params,
                token: ['record', item.name],
                children: undefined
              };
            })
          });
        }

        return tokens;
      })
    );
  }

  getActionTokens$(human = false, showInternal = false, showExternal = true): Observable<ViewContextToken[]> {
    const mapActions = (items: ViewContextAction[], prefix: (string | number)[], insideExternal = false) => {
      return items
        .filter(item => showExternal || (!showExternal && !item.external && !insideExternal))
        .map(item => {
          const token = [...prefix, item.uniqueName];

          return {
            name: firstSet(item.name, item.uniqueName),
            uniqueName: item.uniqueName,
            icon: item.icon,
            token: token
          };
        });
    };

    return combineLatest(
      this.elements$.pipe(
        switchMap(items => {
          if (!items.length) {
            return of([]);
          }

          return combineLatest(
            items.map(item =>
              item.element.actions$.pipe(
                map(actions => {
                  return {
                    item: item,
                    actions: actions
                  };
                })
              )
            )
          );
        })
      )
    ).pipe(
      map(result => {
        const elements: { item: ContextItem; actions: ViewContextAction[] }[] = result[0];
        const iterateElements = (
          elementType: ViewContextElementType,
          prefix?: string,
          parent?: ViewContextElement,
          path: (string | number)[] = []
        ): ViewContextToken[] => {
          return elements
            .filter(item => item.item.element.type == elementType)
            .filter(item => item.item.parent === parent)
            .map(item => {
              const itemPathItem =
                human && elementType == ViewContextElementType.Element && !parent
                  ? item.item.element.name
                  : item.item.element.uniqueName;
              const fullPath = [...path, itemPathItem];
              const prefixedFullPath = [...(prefix ? [prefix] : []), ...path, itemPathItem];

              return {
                name: item.item.element.name,
                icon: item.item.element.icon,
                uniqueName: itemPathItem,
                allowSkip: item.item.element.allowSkip,
                documentation: item.item.element.documentation,
                action: item.item.element.action,
                children: [
                  ...iterateElements(elementType, prefix, item.item.element, fullPath),
                  ...mapActions(
                    item.actions.filter(action => !action.internal || showInternal),
                    prefixedFullPath
                  )
                ]
              };
            });
        };

        const tokens = [
          ...iterateElements(ViewContextElementType.Global),
          {
            name: 'Components',
            uniqueName: 'elements',
            children: iterateElements(ViewContextElementType.Element, 'elements').filter(
              item => item.children && item.children.length
            )
          }
        ];

        return tokens;
      })
    );
  }

  getElementTokens$(
    element: ViewContextElement,
    contextElementPath?: (string | number)[],
    contextElementPaths?: (string | number)[][],
    human = false
  ): Observable<ViewContextToken[]> {
    contextElementPath = contextElementPath || [];
    contextElementPaths = contextElementPaths || [];

    const elementPath = this.getElementPath(element);

    if (!elementPath) {
      return of([]);
    }

    const path = contextElementPath ? [...elementPath, ...contextElementPath] : [...elementPath];
    const forPaths = [contextElementPath, ...contextElementPaths].map(item => {
      return [...elementPath, ...item];
    });

    return this.getOutputTokens$(human, true, false, true, forPaths).pipe(
      map(tokens => {
        let currentTokens = tokens;

        for (let i = 0; i < path.length; ++i) {
          const pathElement = path[i];
          const token = currentTokens.find(item => item.uniqueName == pathElement);

          if (!token || !token.children) {
            return [];
          }

          currentTokens = token.children;
        }

        const trimElementPath = (items: ViewContextToken[]) => {
          return items.map(token => {
            const trimSize = elementPath.reduce((acc, item, i) => {
              if (acc.length == i && item == token.token[i]) {
                acc.push(item);
              }
              return acc;
            }, []).length;

            return {
              ...token,
              token: token.token ? token.token.slice(trimSize) : undefined,
              children: token.children ? trimElementPath(token.children) : undefined
            };
          });
        };

        return trimElementPath(currentTokens);
      })
    );
  }

  tokenPath$(
    path: (string | number)[],
    element?: ViewContextElement,
    contextElementPath?: (string | number)[],
    contextElementPaths?: (string | number)[][],
    includeTrailing = false
  ): Observable<(string | number)[]> {
    const getTokenPathFromOptions = (tokens: ViewContextToken[], options: (string | number)[][]) => {
      for (const pathOption of options) {
        let tokensCurrent = tokens;
        let result = [];
        let trailing = [];

        for (let i = 0; i < pathOption.length; ++i) {
          const pathItem = pathOption[i];
          const pathToken = tokensCurrent.find(item => item.uniqueName == pathItem);

          if (!pathToken) {
            result = undefined;
            break;
          }

          result.push(pathToken.name);

          if (pathToken && pathToken.children) {
            tokensCurrent = pathToken.children;
          } else {
            trailing = pathOption.slice(i + 1);
            break;
          }
        }

        if (!result) {
          continue;
        }

        return {
          path: pathOption,
          result: result,
          trailing: trailing
        };
      }
    };

    if (!path) {
      return of([]);
    }

    let pathsOptions: (string | number)[][] = [path];

    const elementPath = this.getElementPath(element);
    const forPaths = elementPath
      ? [contextElementPath, ...contextElementPaths].map(item => {
          return [...elementPath, ...item];
        })
      : undefined;

    if (elementPath) {
      pathsOptions = [[...elementPath, ...path], ...pathsOptions];
    }

    return this.getOutputTokens$(false, true, true, false, forPaths).pipe(
      map(tokens => {
        const result = getTokenPathFromOptions(tokens, pathsOptions);

        if (!result) {
          return;
        }

        if (elementPath && isEqual(result.path.slice(0, elementPath.length), elementPath)) {
          return [
            'Current element',
            ...result.result.slice(elementPath.length),
            ...(includeTrailing ? result.trailing : [])
          ];
        } else {
          return [...result.result, ...(includeTrailing ? result.trailing : [])];
        }
      })
    );
  }

  getToken$(
    path: (string | number)[],
    element?: ViewContextElement,
    contextElementPath?: (string | number)[],
    contextElementPaths?: (string | number)[][]
  ): Observable<ViewContextToken> {
    const getTokenPathFromOptions = (tokens: ViewContextToken[], options: (string | number)[][]) => {
      for (const pathOption of options) {
        let tokensCurrent = tokens;
        // let result = [];
        let result: ViewContextToken;
        // let trailing = [];

        for (let i = 0; i < pathOption.length; ++i) {
          const pathItem = pathOption[i];
          const pathToken = tokensCurrent.find(item => item.uniqueName == pathItem);

          if (!pathToken) {
            result = undefined;
            break;
          }

          // result.push(pathToken.name);

          if (i == pathOption.length - 1) {
            result = pathToken;
          } else if (pathToken && pathToken.children) {
            tokensCurrent = pathToken.children;
          } else {
            result = undefined;
            break;
            // trailing = pathOption.slice(i + 1);
            // break;
          }
        }

        if (!result) {
          continue;
        }

        return result;

        // if (!result) {
        //   continue;
        // }
        //
        // return {
        //   path: pathOption,
        //   result: result,
        //   trailing: trailing
        // };
      }
    };

    if (!path) {
      return of(undefined);
    }

    let pathsOptions: (string | number)[][] = [path];

    const elementPath = this.getElementPath(element);
    const forPaths = elementPath
      ? [contextElementPath, ...contextElementPaths].map(item => {
          return [...elementPath, ...item];
        })
      : undefined;

    if (elementPath) {
      pathsOptions = [[...elementPath, ...path], ...pathsOptions];
    }

    return this.getOutputTokens$(false, true, true, false, forPaths).pipe(
      map(tokens => {
        return getTokenPathFromOptions(tokens, pathsOptions);
      })
    );
  }

  getActionTokenPath$(
    path: (string | number)[],
    human = false,
    showInternal = false,
    showExternal = true
  ): Observable<ViewContextToken[]> {
    return this.getActionTokens$(human, showInternal, showExternal).pipe(
      map(rootItems => {
        const getNode = (
          items: ViewContextToken[],
          nodePath: (string | number)[],
          currentResult: ViewContextToken[]
        ): ViewContextToken[] => {
          const node = items.find(item => item.uniqueName == nodePath[0]);

          if (node && node.children) {
            return getNode(node.children, nodePath.slice(1), [...currentResult, node]);
          }

          if (nodePath.length > 1) {
            return [];
          }

          return [...currentResult, node];
        };

        return getNode(rootItems, path, []);
      })
    );
  }

  getElementAction(path: (string | number)[]): ViewContextAction {
    if (!path) {
      return;
    }

    let parent: ViewContextElement;

    for (let i = 0; i < path.length; ++i) {
      if (i == 0 && path[i] == 'elements') {
        continue;
      } else if (i == path.length - 1) {
        if (!parent) {
          return;
        }

        return parent.actions.find(item => item.uniqueName == path[i]);
      }

      const contextItem = this.elements.find(item => item.element.uniqueName == path[i] && item.parent == parent);

      if (!contextItem) {
        return;
      }

      parent = contextItem.element;
    }
  }

  getElementAction$(path: (string | number)[]): Observable<ViewContextAction> {
    if (!path) {
      return of(undefined);
    }

    return this.elements$.pipe(
      map(elements => {
        let parent: ViewContextElement;

        for (let i = 0; i < path.length; ++i) {
          if (i == 0 && path[i] == 'elements') {
            continue;
          } else if (i == path.length - 1) {
            if (!parent) {
              return;
            }

            return parent.actions.find(item => item.uniqueName == path[i]);
          }

          const contextItem = elements.find(item => item.element.uniqueName == path[i] && item.parent == parent);

          if (!contextItem) {
            return;
          }

          parent = contextItem.element;
        }
      })
    );
  }

  elementActionPath$(path: (string | number)[]): Observable<ViewContextToken[]> {
    return this.getActionTokenPath$(path).pipe(
      map(result => {
        return result.filter((item, i) => {
          const prevItem = result[i - 1];

          if (prevItem && prevItem.allowSkip && prevItem.children && prevItem.children.length == 1) {
            return false;
          }

          return true;
        }, []);
      })
    );
  }

  elementOutputValues(type: ViewContextElementType): Object {
    const items = this.elements.filter(item => item.element.type == type);

    if (!items.length) {
      return {};
    }

    const iterateElements = (parent?: ViewContextElement): Object => {
      return fromPairs(
        items
          .filter(item => item.parent === parent)
          .map(item => {
            const key = item.element.uniqueName;
            const value = isArray(item.element.outputsValue)
              ? item.element.outputsValue
              : {
                  ...iterateElements(item.element),
                  ...item.element.outputsValue
                };
            return [key, value];
          })
      );
    };

    return iterateElements();
  }

  elementOutputValues$(type: ViewContextElementType): Observable<Object> {
    return this.elements$.pipe(
      debounceTime(100),
      switchMap(items => {
        items = items.filter(item => item.element.type == type);

        if (!items.length) {
          return of([]);
        }

        return combineLatest(
          items.map(item =>
            item.element.outputsValue$.pipe(
              map(value => {
                return {
                  item: item,
                  value: value
                };
              })
            )
          )
        );
      }),
      map((elements: { item: ContextItem; value: any }[]) => {
        const iterateElements = (parent?: ViewContextElement): Object => {
          return fromPairs(
            elements
              .filter(item => item.item.parent === parent)
              .map(item => {
                const key = item.item.element.uniqueName;
                const value = isArray(item.value)
                  ? item.value
                  : {
                      ...iterateElements(item.item.element),
                      ...item.value
                    };
                return [key, value];
              })
          );
        };

        return iterateElements();
      })
    );
  }

  getOutputs$(): Observable<{ item: ContextItem; outputs: ViewContextOutput[] }[]> {
    return this.elements$.pipe(
      switchMap(items => {
        if (!items.length) {
          return of([]);
        }

        return combineLatest(
          items.map(item =>
            item.element.outputs$.pipe(
              map(outputs => {
                return {
                  item: item,
                  outputs: outputs
                };
              })
            )
          )
        );
      })
    );
  }

  get outputs$(): Observable<{ item: ContextItem; outputs: ViewContextOutput[] }[]> {
    return this._sharedOutputs$;
  }

  getOutputValues(): Object {
    const globals = this.elementOutputValues(ViewContextElementType.Global);
    const elements = this.elementOutputValues(ViewContextElementType.Element);

    const result = {
      ...globals,
      elements: elements
    };

    if (this.model) {
      result['record'] = {
        ...fromPairs(
          this.modelDescription.dbFields.map(item => {
            return [item.name, undefined];
          })
        ),
        ...this.model.getAttributes()
      };
    } else if (this.modelDescription) {
      result['record'] = undefined;
    }

    return result;
  }

  getOutputValues$(): Observable<Object> {
    return combineLatest(
      this.elementOutputValues$(ViewContextElementType.Global),
      this.elementOutputValues$(ViewContextElementType.Element)
    ).pipe(
      map(([globals, elements]) => {
        const result = {
          ...globals,
          elements: elements
        };

        if (this.model) {
          result['record'] = {
            ...fromPairs(
              this.modelDescription.dbFields.map(item => {
                return [item.name, undefined];
              })
            ),
            ...this.model.getAttributes()
          };
        } else if (this.modelDescription) {
          result['record'] = undefined;
        }

        return result;
      }),
      // TODO: Think about more optimal context updates
      auditTime(this.updateInterval),
      // distinctUntilChanged((lhs: Object, rhs: Object) => isEqual(lhs, rhs)),
      shareReplay(1)
    );
  }

  get outputValues(): Object {
    if (!this._outputValues) {
      this._outputValues = this.getOutputValues();
    }
    return this._outputValues;
  }

  get outputValues$(): Observable<Object> {
    return this._sharedOutputValues$;
  }
}

@Injectable()
export class ListItemViewContext extends ViewContext {
  clickEvent: MouseEvent;

  constructor(currentEnvironmentStore: CurrentEnvironmentStore, private viewContext: ViewContext) {
    super(currentEnvironmentStore);
    this.parent = viewContext;
  }
}

import { ElementRef, OnChanges } from '@angular/core';
import { AppConfigService } from '@core';
import { addClass, isSet, removeClass } from '@shared';
import { getEmojiConverter, getIconData } from '../../utils/icons';
var convertor = getEmojiConverter();
var IconDirective = /** @class */ (function () {
    function IconDirective(appConfigService, el) {
        this.appConfigService = appConfigService;
        this.el = el;
    }
    Object.defineProperty(IconDirective.prototype, "backgroundImage", {
        get: function () {
            return isSet(this.image) ? "url(" + this.image + ")" : null;
        },
        enumerable: true,
        configurable: true
    });
    IconDirective.prototype.ngOnChanges = function (changes) {
        if (changes.icon) {
            var prevIcon = this.currentIcon;
            var icon = getIconData(changes.icon.currentValue, {
                mediaBaseUrl: this.appConfigService.getMediaBaseUrl()
            });
            this.currentIcon = icon;
            if (prevIcon && prevIcon.icon) {
                removeClass(this.el.nativeElement, "icon-" + prevIcon.icon);
            }
            if (icon && icon.icon) {
                addClass(this.el.nativeElement, "icon-" + icon.icon);
            }
            if (icon && icon.emoji) {
                this.el.nativeElement.innerHTML = convertor.replace_colons(":" + icon.emoji + ":");
            }
            else if (prevIcon && prevIcon.emoji) {
                this.el.nativeElement.innerHTML = '';
            }
            this.image = icon && icon.image ? icon.image : undefined;
        }
    };
    return IconDirective;
}());
export { IconDirective };

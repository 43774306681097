/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./oauth-response.component";
import * as i2 from "@angular/router";
var styles_OAuthResponseComponent = [];
var RenderType_OAuthResponseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OAuthResponseComponent, data: {} });
export { RenderType_OAuthResponseComponent as RenderType_OAuthResponseComponent };
export function View_OAuthResponseComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_OAuthResponseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-oauth-response", [], null, null, null, View_OAuthResponseComponent_0, RenderType_OAuthResponseComponent)), i0.ɵdid(1, 245760, null, 0, i1.OAuthResponseComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OAuthResponseComponentNgFactory = i0.ɵccf("app-oauth-response", i1.OAuthResponseComponent, View_OAuthResponseComponent_Host_0, {}, {}, []);
export { OAuthResponseComponentNgFactory as OAuthResponseComponentNgFactory };

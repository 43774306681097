var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval, merge } from 'rxjs';
import { bufferCount, filter, map, startWith, tap } from 'rxjs/operators';
import { DynamicComponent } from '@common/dynamic-component';
import { BasePopupComponent, PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import { Frame, FrameTranslate, Layer, SideConstraint, SizeConstraint } from '@modules/views';
import { getOffset, isSet, KeyboardEventKeyCode, MouseButton, noKeyModifiers, parseNumber, pointsDistance } from '@shared';
import { getLayerComponent } from '../../data/layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
import { ViewEditorMultipleLayers } from '../../services/view-editor-multiple-layers/view-editor-multiple-layers';
import { measureFrames, snapFrame } from '../../utils/guides';
import { isViewBoundsMouseDown } from '../layers/base/layer-resizable.directive';
var markViewLayerClickProperty = '_markViewLayerClickProperty';
export function markViewLayerClick(clickEvent) {
    clickEvent[markViewLayerClickProperty] = true;
}
export function isViewLayerClick(clickEvent) {
    return !!clickEvent[markViewLayerClickProperty];
}
var AutoLayerComponent = /** @class */ (function () {
    function AutoLayerComponent(editorContext, multipleLayers, popupService, popupComponent, zone, cd, analyticsService) {
        var _this = this;
        this.editorContext = editorContext;
        this.multipleLayers = multipleLayers;
        this.popupService = popupService;
        this.popupComponent = popupComponent;
        this.zone = zone;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.layout = false;
        this.translate = { x: 0, y: 0 };
        this.layerDelete = new EventEmitter();
        this.layerUpdated$ = this.editorContext
            ? this.editorContext.layerChanged$().pipe(filter(function (event) { return event.layer.isSame(_this.layer) && event.source != ViewEditorCustomizeSource.Layer; }), map(function (event) { return event.layer; }))
            : undefined;
        this.loadingExecute = false;
        this.moveThreshold = 5;
        this.framesMeasuring = false;
        this.framesMeasuringSubscriptions = [];
    }
    Object.defineProperty(AutoLayerComponent.prototype, "layerId", {
        get: function () {
            return this.layer.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoLayerComponent.prototype, "layerName", {
        get: function () {
            return this.layer.name;
        },
        enumerable: true,
        configurable: true
    });
    AutoLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initComponent();
        if (this.editorContext) {
            this.editorContext
                .trackKeydown()
                .pipe(filter(function () { return _this.editorContext.isCustomizingLayer(_this.layer); }), untilDestroyed(this))
                .subscribe(function (e) {
                var moveLayer;
                if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
                    var delta = e.shiftKey ? 10 : 1;
                    moveLayer = { updateFrame: { y: _this.layer.frame.y - delta }, addFrame: { y: -delta } };
                }
                else if (e.keyCode == KeyboardEventKeyCode.ArrowRight) {
                    var delta = e.shiftKey ? 10 : 1;
                    moveLayer = { updateFrame: { x: _this.layer.frame.x + delta }, addFrame: { x: delta } };
                }
                else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
                    var delta = e.shiftKey ? 10 : 1;
                    moveLayer = { updateFrame: { y: _this.layer.frame.y + delta }, addFrame: { y: delta } };
                }
                else if (e.keyCode == KeyboardEventKeyCode.ArrowLeft) {
                    var delta = e.shiftKey ? 10 : 1;
                    moveLayer = { updateFrame: { x: _this.layer.frame.x - delta }, addFrame: { x: -delta } };
                }
                if (moveLayer) {
                    e.stopPropagation();
                    if (_this.editorContext.isCustomizingMultipleLayers()) {
                        _this.multipleLayers.patchFrame(moveLayer.addFrame, { add: true });
                    }
                    else {
                        _this.patchLayerFrame(moveLayer.updateFrame);
                        _this.showSnapGuides();
                    }
                }
                else if (e.keyCode == KeyboardEventKeyCode.Alt && _this.editorContext.isCustomizingLayer(_this.layer)) {
                    e.stopPropagation();
                    _this.startFramesMeasure();
                }
            });
            this.editorContext
                .trackKeydown()
                .pipe(filter(function () { return _this.editorContext.isCustomizingLayer(_this.layer); }), filter(function (e) {
                return (e.keyCode >= KeyboardEventKeyCode.Number0 &&
                    e.keyCode <= KeyboardEventKeyCode.Number9 &&
                    noKeyModifiers(e));
            }), tap(function (e) { return e.stopPropagation(); }), map(function (e) { return ({ number: e.keyCode - KeyboardEventKeyCode.Number0, date: new Date() }); }), startWith(undefined), bufferCount(2, 1), map(function (value) { return value.filter(function (item) { return item && new Date().getTime() - item.date.getTime() <= 500; }); }), untilDestroyed(this))
                .subscribe(function (value) {
                if (value.length == 1 && value[0].number === 0) {
                    _this.layer.opacity = 1;
                }
                else {
                    _this.layer.opacity = value.reduce(function (acc, item, i) { return acc + item.number * Math.pow(10, -(i + 1)); }, 0);
                }
                _this.editorContext.markLayersChanged([_this.layer], ViewEditorCustomizeSource.AutoLayer);
            });
            merge(this.editorContext
                .layerChanged$()
                .pipe(filter(function (event) { return event.layer.isSame(_this.layer) && event.source != ViewEditorCustomizeSource.AutoLayer; })), this.editorContext
                .globalLayersChange$()
                .pipe(filter(function (event) { return event.source != ViewEditorCustomizeSource.AutoLayer; })))
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.updateLayerFrameTransform();
                _this.updateFramesMeasure();
            });
            interval(1000)
                .pipe(filter(function () { return !_this.editorContext.movingLayer$.value && !_this.editorContext.resizingLayer$.value; }), untilDestroyed(this))
                .subscribe(function () { return _this.updateLayoutFrame(); });
        }
    };
    AutoLayerComponent.prototype.ngOnDestroy = function () {
        if (this.editorContext) {
            this.editorContext.removeHoverLayer(this.layer, this);
            this.editorContext.unregisterLayerContainer(this.layer);
        }
    };
    AutoLayerComponent.prototype.ngOnChanges = function (changes) {
        if ([changes.layer, changes.container, changes.translate].some(function (item) { return item && !item.firstChange; })) {
            if (changes.layer &&
                (changes.layer.previousValue ? changes.layer.previousValue.type : undefined) !==
                    (changes.layer.currentValue ? changes.layer.currentValue.type : undefined)) {
                this.initComponent();
            }
            else {
                this.updateComponent();
            }
        }
        if (changes.layer) {
            if (this.editorContext) {
                this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.HistoryMove);
            }
            else {
                this.updateLayerFrameTransform();
                this.updateFramesMeasure();
            }
        }
        if (changes.layout) {
            this.updateLayerFrameTransform();
            this.updateFramesMeasure();
        }
        if (changes.container && this.editorContext) {
            this.editorContext.registerLayerContainer(this.layer, this.container);
        }
    };
    AutoLayerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.updateLayerFrameTransform();
        if (this.editorContext) {
            this.zone.runOutsideAngular(function () {
                fromEvent(_this.rootElement.nativeElement, 'mousedown')
                    .pipe(filter(function () { return !_this.layout; }), filter(function (e) { return e.button == MouseButton.Main; }), filter(function (e) {
                    return (!_this.editorContext.isCreateTool() &&
                        !isViewBoundsMouseDown(e) &&
                        !_this.editorContext.customizingGradient$.value &&
                        !_this.editorContext.navigateMode$.value);
                }), filter(function () { return _this.editorContext.isTopHoverLayer(_this.layer); }), filter(function () {
                    if (_this.editorContext.isCustomizingMultipleLayers()) {
                        return _this.editorContext.customizingLayers$.value.some(function (item) { return item.isSame(_this.layer); });
                    }
                    else {
                        return true;
                    }
                }), untilDestroyed(_this))
                    .subscribe(function (downEvent) {
                    var subscriptions = [];
                    var originalX = _this.layer.frame.x;
                    var originalY = _this.layer.frame.y;
                    var thresholdPassed = false;
                    var originalMultipleLayers = cloneDeep(_this.editorContext.customizingLayers$.value);
                    var originalMultipleLayersFrame = _this.multipleLayers.frame$.value;
                    var exceptLayers = _this.editorContext.isCustomizingMultipleLayers()
                        ? _this.editorContext.customizingLayers$.value
                        : [_this.layer];
                    var otherFrames = _this.editorContext.getFrames({
                        onlyContainers: [_this.container],
                        exceptLayers: exceptLayers
                    });
                    downEvent.stopPropagation();
                    subscriptions.push(fromEvent(document, 'mousemove')
                        .pipe(untilDestroyed(_this))
                        .subscribe(function (moveEvent) {
                        moveEvent.preventDefault();
                        if (!thresholdPassed) {
                            var originDistance = pointsDistance(downEvent.clientX, downEvent.clientY, moveEvent.clientX, moveEvent.clientY);
                            if (originDistance >= _this.moveThreshold) {
                                thresholdPassed = true;
                                _this.editorContext.movingLayer$.next(_this.layer);
                                _this.editorContext.removeHorizontalGuides();
                                _this.editorContext.removeVerticalGuides();
                            }
                        }
                        if (thresholdPassed) {
                            var scale = _this.editorContext.viewportScale$.value;
                            var deltaX = (moveEvent.clientX - downEvent.clientX) / scale;
                            var deltaY = (moveEvent.clientY - downEvent.clientY) / scale;
                            if (_this.editorContext.isCustomizingMultipleLayers()) {
                                var newFrame = new Frame(originalMultipleLayersFrame).patch({
                                    x: Math.round(originalMultipleLayersFrame.x + deltaX),
                                    y: Math.round(originalMultipleLayersFrame.y + deltaY)
                                });
                                var snap = snapFrame({
                                    base: new FrameTranslate({
                                        frame: newFrame
                                    }),
                                    others: otherFrames,
                                    horizontalSnapX: true,
                                    verticalSnapY: true
                                });
                                newFrame.patch(snap.updateFrame);
                                _this.multipleLayers.patchFrame(__assign({}, (newFrame.x != originalMultipleLayersFrame.x && { x: newFrame.x }), (newFrame.y != originalMultipleLayersFrame.y && { y: newFrame.y })), {
                                    originalFrame: originalMultipleLayersFrame,
                                    originalLayers: originalMultipleLayers
                                });
                            }
                            else {
                                var container = _this.editorContext.getContainer(_this.container);
                                var newFrame = new Frame(_this.layer.frame).patch({
                                    x: Math.round(originalX + deltaX),
                                    y: Math.round(originalY + deltaY)
                                });
                                var snap = snapFrame({
                                    base: new FrameTranslate({
                                        frame: newFrame,
                                        translate: container ? container.options.translate : undefined
                                    }),
                                    others: otherFrames,
                                    horizontalSnapX: true,
                                    verticalSnapY: true
                                });
                                newFrame.patch(snap.updateFrame);
                                _this.editorContext.setHorizontalGuides(snap.horizontalGuides);
                                _this.editorContext.setVerticalGuides(snap.verticalGuides);
                                var updateFrame = newFrame.diff(_this.layer.frame);
                                _this.patchLayerFrame(updateFrame);
                                if (!_this.editorContext.isCustomizingLayer(_this.layer)) {
                                    _this.customize();
                                }
                            }
                        }
                    }));
                    subscriptions.push(fromEvent(document, 'mouseup')
                        .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(_this))
                        .subscribe(function (upEvent) {
                        upEvent.preventDefault();
                        subscriptions.forEach(function (item) { return item.unsubscribe(); });
                        _this.editorContext.movingLayer$.next(undefined);
                        _this.editorContext.removeHorizontalGuides();
                        _this.editorContext.removeVerticalGuides();
                        _this.editorContext.markLayersChanged([_this.layer], ViewEditorCustomizeSource.AutoLayer);
                    }));
                });
            });
        }
    };
    AutoLayerComponent.prototype.startFramesMeasure = function () {
        var _this = this;
        var subscriptions = [];
        this.framesMeasuring = true;
        this.framesMeasuringSubscriptions = subscriptions;
        this.editorContext.removeHorizontalGuides();
        this.editorContext.removeVerticalGuides();
        subscriptions.push(this.editorContext
            .getTopHoverLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateFramesMeasure(); }));
        subscriptions.push(merge(fromEvent(document, 'keyup').pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Alt; }), tap(function (e) { return e.preventDefault(); })), this.editorContext.resizingLayer$.pipe(filter(function (layer) { return !!layer; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.stopFramesMeasure(); }));
    };
    AutoLayerComponent.prototype.updateFramesMeasure = function () {
        if (!this.framesMeasuring) {
            return;
        }
        var hoverLayer = this.editorContext.getTopHoverLayer();
        var hoverFrame;
        if (hoverLayer && !hoverLayer.isSame(this.layer)) {
            var container = this.editorContext.getLayerContainer(hoverLayer);
            hoverFrame = new FrameTranslate({
                frame: hoverLayer.frame,
                translate: container ? container.options.translate : undefined
            });
        }
        else if (!hoverLayer) {
            hoverFrame = new FrameTranslate({
                frame: this.editorContext.view$.value.frame
            });
        }
        if (hoverFrame) {
            var container = this.editorContext.getContainer(this.container);
            var frame = new FrameTranslate({
                frame: this.layer.frame,
                translate: container ? container.options.translate : undefined
            });
            var measures = measureFrames(frame, hoverFrame);
            this.editorContext.setHorizontalGuides(measures.horizontalGuides);
            this.editorContext.setVerticalGuides(measures.verticalGuides);
        }
        else {
            this.editorContext.removeHorizontalGuides();
            this.editorContext.removeVerticalGuides();
        }
    };
    AutoLayerComponent.prototype.stopFramesMeasure = function () {
        this.framesMeasuringSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.framesMeasuringSubscriptions = [];
        this.editorContext.removeHorizontalGuides();
        this.editorContext.removeVerticalGuides();
        this.framesMeasuring = false;
    };
    AutoLayerComponent.prototype.getComponentInputs = function () {
        return {
            layer: this.layer,
            layerUpdated$: this.layerUpdated$,
            container: this.container,
            translate: this.translate,
            createdLayer: this.createdLayer,
            customizeEnabled: !!this.editorContext,
            viewContext: this.viewContext,
            analyticsSource: this.analyticsSource
        };
    };
    AutoLayerComponent.prototype.initComponent = function () {
        var _this = this;
        var component = getLayerComponent(this.layer.type);
        if (!component) {
            this.componentData = undefined;
            this.cd.markForCheck();
            console.error("No such layer type registered: " + this.layer.type);
            return;
        }
        if (this.editorContext) {
            this.createdLayer = this.editorContext.initCreatedLayer(this.layer);
        }
        this.componentData = {
            component: component,
            inputs: this.getComponentInputs(),
            outputs: {
                layerCustomizeMouseEnter: [
                    function () {
                        _this.updateLayoutFrame();
                        _this.editorContext.addHoverLayer(_this.layer, _this);
                    }
                ],
                layerCustomizeMouseLeave: [
                    function () {
                        _this.editorContext.removeHoverLayer(_this.layer, _this);
                    }
                ],
                layerCustomize: [
                    function () {
                        _this.customize();
                    }
                ],
                layerAddCustomizing: [
                    function () {
                        _this.addCustomizing();
                    }
                ],
                layerRemoveCustomizing: [
                    function () {
                        _this.removeCustomizing();
                    }
                ],
                layerDelete: [
                    function () {
                        _this.delete();
                    }
                ],
                updateFrame: [
                    function (frame) {
                        _this.patchLayerFrame(frame, ViewEditorCustomizeSource.Layer);
                    }
                ]
            }
        };
        this.cd.markForCheck();
    };
    AutoLayerComponent.prototype.updateComponent = function (options) {
        if (options === void 0) { options = {}; }
        if (!this.dynamicComponent ||
            !this.dynamicComponent.currentComponent ||
            !this.dynamicComponent.currentComponent.instance) {
            return;
        }
        var ref = this.dynamicComponent.currentComponent;
        var inputs = this.getComponentInputs();
        var changes = toPairs(inputs).reduce(function (acc, _a) {
            var prop = _a[0], currentValue = _a[1];
            var prevValue = ref.instance[prop];
            if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
                acc[prop] = new SimpleChange(prevValue, currentValue, false);
                ref.instance[prop] = currentValue;
            }
            return acc;
        }, {});
        this.componentData.inputs = inputs;
        if (values(changes).length) {
            if (ref.instance['ngOnChanges']) {
                ref.instance['ngOnChanges'](changes);
            }
            ref.changeDetectorRef.markForCheck();
        }
    };
    AutoLayerComponent.prototype.updateLayerFrameTransform = function () {
        if (!this.rootElement) {
            return;
        }
        var rotation = parseNumber(this.layer.frame.rotation, 0);
        var attrs = {
            'mix-blend-mode': this.layer.cssMixBlendMode()
        };
        var hasTransform = false;
        if (rotation) {
            attrs['rotation'] = rotation;
            hasTransform = true;
        }
        else {
            attrs['rotation'] = 0;
        }
        if (this.layer.reflectHorizontal) {
            attrs['scaleX'] = -1;
            hasTransform = true;
        }
        else {
            attrs['scaleX'] = 1;
        }
        if (this.layer.reflectVertical) {
            attrs['scaleY'] = -1;
            hasTransform = true;
        }
        else {
            attrs['scaleY'] = 1;
        }
        if (this.layout) {
            if (hasTransform) {
                attrs['x'] = 0;
                attrs['y'] = 0;
            }
            attrs['width'] = this.layer.frame.width;
            attrs['height'] = this.layer.frame.height;
        }
        else if (this.editorContext) {
            attrs['top'] = 0;
            attrs['left'] = 0;
            attrs['x'] = this.layer.frame.x;
            attrs['y'] = this.layer.frame.y;
            attrs['width'] = this.layer.frame.width;
            attrs['height'] = this.layer.frame.height;
            hasTransform = true;
        }
        else {
            var constraints = this.layer.getConstraintsOrDefault();
            if (constraints.isHorizontalSides(SideConstraint.Fixed)) {
                attrs['left'] = this.layer.frame.x;
                attrs['right'] = this.frame.width - (this.layer.frame.x + this.layer.frame.width);
            }
            else if (constraints.width == SizeConstraint.Fluid) {
                var width = (this.layer.frame.width / this.frame.width) * 100;
                attrs['width'] = width + "%";
            }
            else {
                attrs['width'] = this.layer.frame.width;
            }
            if (constraints.isVerticalSides(SideConstraint.Fixed)) {
                attrs['top'] = this.layer.frame.y;
                attrs['bottom'] = this.frame.height - (this.layer.frame.y + this.layer.frame.height);
            }
            else if (constraints.height == SizeConstraint.Fluid) {
                var height = (this.layer.frame.height / this.frame.height) * 100;
                attrs['height'] = height + "%";
            }
            else {
                attrs['height'] = this.layer.frame.height;
            }
            if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fluid) {
                var xCenter = this.layer.frame.x + this.layer.frame.width * 0.5;
                var left = (xCenter / this.frame.width) * 100;
                attrs['left'] = left + "%";
                attrs['xPercent'] = -50;
                hasTransform = true;
            }
            else if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fixed) {
                attrs['right'] = this.frame.width - (this.layer.frame.x + this.layer.frame.width);
            }
            else {
                attrs['left'] = this.layer.frame.x;
            }
            if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fluid) {
                var yCenter = this.layer.frame.y + this.layer.frame.height * 0.5;
                var top_1 = (yCenter / this.frame.height) * 100;
                attrs['top'] = top_1 + "%";
                attrs['yPercent'] = -50;
                hasTransform = true;
            }
            else if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fixed) {
                attrs['bottom'] = this.frame.height - (this.layer.frame.y + this.layer.frame.height);
            }
            else {
                attrs['top'] = this.layer.frame.y;
            }
        }
        var clearProps = [];
        var clearableProps = ['top', 'right', 'bottom', 'left', 'width', 'height'];
        clearableProps.forEach(function (prop) {
            if (!attrs.hasOwnProperty(prop)) {
                clearProps.push(prop);
            }
        });
        if (this.layer.widthFluid) {
            clearProps.push('width');
        }
        if (this.layer.heightFluid) {
            clearProps.push('height');
        }
        if (!hasTransform) {
            clearProps.push('transform');
        }
        if (clearProps.length) {
            attrs['clearProps'] = clearProps.join(',');
        }
        TweenMax.set(this.rootElement.nativeElement, attrs);
    };
    AutoLayerComponent.prototype.updateLayoutFrame = function () {
        if (!this.layout) {
            return;
        }
        var offset = getOffset(this.rootElement.nativeElement, this.containerElement);
        var frame = {};
        if (this.layer.frame.x != offset.left) {
            frame.x = offset.left;
        }
        if (this.layer.frame.y != offset.top) {
            frame.y = offset.top;
        }
        if (keys(frame).length) {
            this.patchLayerFrame(frame, ViewEditorCustomizeSource.AutoLayerBounds);
        }
    };
    AutoLayerComponent.prototype.patchLayerFrame = function (frame, source) {
        if (source === void 0) { source = ViewEditorCustomizeSource.AutoLayer; }
        this.layer.frame.patch(frame);
        if (isSet(frame.width) && this.layer.widthFluid) {
            this.layer.widthFluid = false;
        }
        if (isSet(frame.height) && this.layer.heightFluid) {
            this.layer.heightFluid = false;
        }
        this.editorContext.markLayersChanged([this.layer], source);
        this.updateLayerFrameTransform();
        this.updateFramesMeasure();
    };
    AutoLayerComponent.prototype.showSnapGuides = function () {
        var container = this.editorContext.getContainer(this.container);
        var otherFrames = this.editorContext.getFrames({
            onlyContainers: [this.container],
            exceptLayers: [this.layer]
        });
        var snap = snapFrame({
            base: new FrameTranslate({
                frame: this.layer.frame,
                translate: container ? container.options.translate : undefined
            }),
            others: otherFrames,
            horizontalSnapX: true,
            verticalSnapY: true,
            snapDistance: 0
        });
        this.editorContext.setHorizontalGuides(snap.horizontalGuides, { durationMs: 600 });
        this.editorContext.setVerticalGuides(snap.verticalGuides, { durationMs: 600 });
    };
    AutoLayerComponent.prototype.delete = function () {
        this.removeCustomizing();
        this.layerDelete.emit();
    };
    AutoLayerComponent.prototype.customize = function () {
        this.editorContext.setCustomizingLayer(this.layer);
    };
    AutoLayerComponent.prototype.addCustomizing = function () {
        this.editorContext.addCustomizingLayer(this.layer);
    };
    AutoLayerComponent.prototype.removeCustomizing = function () {
        this.editorContext.removeCustomizingLayer(this.layer);
    };
    AutoLayerComponent.prototype.onClick = function (e) {
        markViewLayerClick(e);
    };
    return AutoLayerComponent;
}());
export { AutoLayerComponent };

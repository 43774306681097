import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CameraDevice } from 'html5-qrcode';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scanner-cameras-dropdown',
  templateUrl: './scanner-cameras-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScannerCamerasDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() origin: CdkOverlayOrigin;
  @Input() opened = false;
  @Input() cameras: CameraDevice[] = [];
  @Input() selectedCamera: string;
  @Output() selectCamera = new EventEmitter<CameraDevice>();
  @Output() close = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 4
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 4
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: -4
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: -4
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: -4,
      offsetY: 0
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 4,
      offsetY: 0
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

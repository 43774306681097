// TODO: Refactor import
import { getElementByType } from '../../../customize/data/elements/element-items';
import { ElementItem } from '../../../customize/data/elements/items/base';

import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';

export class ElementLayer extends Layer {
  public type = LayerType.Element;
  public element: ElementItem;
  public icon = 'components_2';
  public defaultName = 'component';

  constructor(options: Partial<ElementLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['width_fluid']) {
      this.widthFluid = data['width_fluid'];
    }

    if (data['height_fluid']) {
      this.heightFluid = data['height_fluid'];
    }

    if (data['element']) {
      const Element = getElementByType(data['element']['type']);
      this.element = new Element().deserialize(data['element']);
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      width_fluid: this.widthFluid,
      height_fluid: this.heightFluid,
      element: this.element ? this.element.serialize() : undefined
    };
  }
}

registerLayerType(LayerType.Element, ElementLayer);

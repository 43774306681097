import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import { SENTRY_APP_ERROR } from '@core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
Sentry.init({
    dsn: environment.ravenDSN,
    release: "jet-frontend@" + environment.version,
    integrations: [new RewriteFrames()],
    ignoreErrors: ['Non-Error exception captured', 'ResizeObserver loop limit exceeded'],
    denyUrls: [/cdn\.paddle\.com/i, /cdn\.ckeditor\.com/i, /node_modules\/plyr\//i, /extensions\//i, /^chrome:\/\//i],
    autoSessionTracking: true
});
Sentry.addGlobalEventProcessor(function (event, hint) {
    if (!event.extra || !event.extra[SENTRY_APP_ERROR]) {
        return null;
    }
    return event;
});
if (environment.production) {
    enableProdMode();
}
document.addEventListener('DOMContentLoaded', function () {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        // .then(() => {
        //   if ('serviceWorker' in navigator && environment.production) {
        //     navigator.serviceWorker.register('ngsw-worker.js');
        //   }
        // })
        .catch(function (err) { return console.error(err); });
});

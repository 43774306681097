/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page.component";
var styles_PageComponent = [];
var RenderType_PageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
export function View_PageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page", [], [[2, "page", null], [2, "page_padding-left", null], [2, "page_padding-horizontal", null]], null, null, View_PageComponent_0, RenderType_PageComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).paddingLeftCls; var currVal_2 = i0.ɵnov(_v, 1).paddingHorizontalCls; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var PageComponentNgFactory = i0.ɵccf("app-page", i1.PageComponent, View_PageComponent_Host_0, { paddingLeft: "paddingLeft", paddingHorizontal: "paddingHorizontal" }, {}, ["*"]);
export { PageComponentNgFactory as PageComponentNgFactory };

import { GuideTask } from './guide-task';

export class GuideSection {
  public title: string;
  public subtitle: string;
  public image: string;
  public ordering: number;
  public tasks: GuideTask[];

  deserialize(data: Object): GuideSection {
    this.title = data['title'];
    this.subtitle = data['subtitle'];
    this.image = data['image'];
    this.ordering = data['ordering'];

    if (data['tasks']) {
      this.tasks = data['tasks'].map(item => new GuideTask().deserialize(item));
    }

    return this;
  }

  get progress() {
    if (!this.tasks.length) {
      return 0;
    }

    return this.tasks.filter(item => item.completed).length / this.tasks.length;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user/user.service';
var UserSelectSource = /** @class */ (function (_super) {
    __extends(UserSelectSource, _super);
    function UserSelectSource(userService) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    UserSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.userService.get(searchQuery).pipe(map(function (result) {
            _this.page += 1;
            return result.map(function (item) {
                return {
                    value: item,
                    name: item.firstName + " - " + item.email
                };
            });
        }));
    };
    UserSelectSource.prototype.fetchByValue = function (value) {
        if (!value || !value['model']) {
            return of(undefined);
        }
        return this.userService.get(value['email']).pipe(map(function (result) {
            var item = result.find(function (i) { return i.email == value['email']; });
            if (!item) {
                return;
            }
            return {
                value: item,
                name: item.firstName + " - " + item.email
            };
        }));
    };
    UserSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    UserSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    UserSelectSource.prototype.setStaticOptions = function (options) { };
    return UserSelectSource;
}(SelectSource));
export { UserSelectSource };

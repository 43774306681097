import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { CustomViewSettings, PagePermissionsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  PermissionsGroup,
  ProjectGroup,
  ProjectGroupService,
  ProjectGroupStore
} from '@modules/projects';

import { ProjectModelPermissionsArray } from './project-model-permissions.array';
import { ProjectPagePermissionsArray } from './project-page-permissions.array';
import { ProjectPermissionsControl } from './project-permissions.control';

export function duplicateNameValidator(): AsyncValidatorFn {
  return (control: AbstractControl) => {
    const projectGroupService = control.parent
      ? (control.parent['projectGroupService'] as ProjectGroupService)
      : undefined;
    const currentProjectStore = control.parent
      ? (control.parent['currentProjectStore'] as CurrentProjectStore)
      : undefined;
    const currentEnvironmentStore = control.parent
      ? (control.parent['currentEnvironmentStore'] as CurrentEnvironmentStore)
      : undefined;
    const group = control.parent ? (control.parent['group'] as ProjectGroup) : undefined;

    if (control.value === null || !projectGroupService) {
      return of(null);
    }

    return timer(200).pipe(
      switchMap(() => {
        return projectGroupService.get(
          currentProjectStore.instance.uniqueName,
          currentEnvironmentStore.instance.uniqueName
        );
      }),
      map((groups: ProjectGroup[]) => {
        const groupMatch = groups
          .filter(item => !group || group.uid != item.uid)
          .find(item => item.name.toLowerCase().trim() === control.value.toLowerCase().trim());
        if (groupMatch) {
          return { local: ['Team with such name already exists'] };
        }
        return null;
      })
    );
  };
}

@Injectable()
export class ProjectGroupChangeForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    // super_group: FormControl;
    properties: FormControl;
    permissions: ProjectPermissionsControl;
    model_permissions: ProjectModelPermissionsArray;
    page_permissions: ProjectPagePermissionsArray;
  };
  group: ProjectGroup;

  constructor(
    private modelPermissionsArray: ProjectModelPermissionsArray,
    private pagePermissionsArray: ProjectPagePermissionsArray,
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private projectGroupStore: ProjectGroupStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectGroupService: ProjectGroupService,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private pagePermissionsService: PagePermissionsService
  ) {
    super({
      name: new FormControl('', [Validators.required], [duplicateNameValidator()]),
      // super_group: new FormControl(false),
      properties: new FormControl({}),
      permissions: new ProjectPermissionsControl(),
      model_permissions: modelPermissionsArray,
      page_permissions: pagePermissionsArray
    });
  }

  ngOnDestroy(): void {}

  init(group?: ProjectGroup) {
    this.group = group;

    if (this.group) {
      this.controls.name.setValue(this.group.name);
      // this.controls.super_group.setValue(this.group.superGroup);
      this.controls.properties.setValue(this.group.properties);
      this.controls.permissions.deserialize(this.group.permissions);
      this.controls.model_permissions.deserialize(this.group.permissions);

      if (this.group.permissionsVersion == 1 && !this.group.permissionsGroup) {
        this.controls.page_permissions.initialized$.subscribe(() => {
          this.controls.page_permissions.setEveryControlAllActions(true);
        });
      } else {
        this.controls.page_permissions.deserialize(this.group.permissions);
      }

      if (this.group.protected) {
        if (this.group.permissionsGroup == PermissionsGroup.FullAccess) {
          this.controls.permissions.setEveryControl(true);
          this.controls.page_permissions.setEveryControlAllActions(true);
          this.controls.model_permissions.setEveryControlAllActions(true);
        } else if (this.group.permissionsGroup == PermissionsGroup.Editor) {
          this.controls.page_permissions.setEveryControlAllActions(true);
          this.controls.model_permissions.setEveryControlAllActions(true);
        } else if (this.group.permissionsGroup == PermissionsGroup.ReadOnly) {
          this.controls.page_permissions.setEveryControlAction('read', true);
          this.controls.model_permissions.setEveryControlAction('read', true);
        }
      }
    }

    combineLatest(
      this.viewSettingsStore
        .get()
        .pipe(
          map(viewSettings => viewSettings.filter(item => item.view == ViewSettingsType.Custom) as CustomViewSettings[])
        ),
      this.actionStore.get(),
      this.controls.page_permissions.valueChanges
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([viewSettings, actions, pagePermissions]) => {
        const newValue = this.pagePermissionsService.getModelPermissionsFromPagePermissions(
          this.controls.page_permissions.serialize(),
          this.controls.model_permissions.permissions,
          viewSettings,
          actions
        );

        const currentValue = this.controls.model_permissions.serialize();

        if (!isEqual(currentValue, newValue)) {
          this.controls.model_permissions.deserialize(newValue);
        }
      });
  }

  isPropertiesSet() {
    return this.controls.properties.value && !isEqual(this.controls.properties.value, {});
  }

  submit(): Observable<ProjectGroup> {
    this.markAsDirty();

    const value = this.value;
    const group = new ProjectGroup();

    if (this.group) {
      group.uid = this.group.uid;
    }

    group.name = value['name'];
    // group.superGroup = value['super_group'];
    group.properties = value['properties'];

    if (!group.protected) {
      group.permissions = [
        ...this.controls.permissions.serialize(),
        ...this.controls.model_permissions.serialize(),
        ...this.controls.page_permissions.serialize()
      ];
    }

    group.permissionsVersion = 2;

    let obs: Observable<ProjectGroup>;

    if (this.group) {
      obs = this.projectGroupService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        group
      );
    } else {
      obs = this.projectGroupService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        group
      );
    }

    return obs.pipe(
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      delayWhen(() => this.projectGroupStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

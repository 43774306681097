import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MenuContext } from '@modules/menu-components';

import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';

@Component({
  selector: 'app-section-menu-item-popover',
  templateUrl: './section-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionMenuItemPopoverComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditSectionControl;
  @Output() close = new EventEmitter<void>();

  analyticsSource = 'section_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

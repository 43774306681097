import { ElementRef, EmbeddedViewRef, EventEmitter, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { MouseButton } from '@shared';
import { getRootNode, replaceElement } from '../../utils/document';
import { handleDragMouseDownEvent, isDragMouseDownEventHandled } from '../../utils/handle-drag-mouse-down';
import { isDragMouseDownEventPrevented } from '../../utils/prevent-drag-mouse-down';
import { AppDragPlaceholder } from '../drag-placeholder/drag-placeholder.directive';
import { AppDragPreview } from '../drag-preview/drag-preview.directive';
var AppDrag = /** @class */ (function () {
    function AppDrag(el, viewContainerRef) {
        this.el = el;
        this.viewContainerRef = viewContainerRef;
        this.started = new EventEmitter();
        this.draggingOver = new EventEmitter();
        this.dropList$ = new BehaviorSubject(undefined);
        this.handles = [];
        this.dragStartThreshold = 5;
        this.subscriptions = [];
    }
    AppDrag.prototype.ngOnInit = function () {
        var _this = this;
        this.dropList$
            .pipe(switchMap(function (dropList) {
            if (dropList) {
                return dropList.disabled$.pipe(map(function (disabled) { return (disabled ? undefined : dropList); }));
            }
            else {
                return of(undefined);
            }
        }), distinctUntilChanged(function (lhs, rhs) { return lhs === rhs; }), untilDestroyed(this))
            .subscribe(function (dropList) {
            if (dropList) {
                _this.initDrag(dropList);
            }
            else {
                _this.deinitDrag();
            }
        });
    };
    AppDrag.prototype.ngOnDestroy = function () { };
    AppDrag.prototype.getElement = function () {
        return this.elOverride || this.el.nativeElement;
    };
    AppDrag.prototype.setElement = function (newElement) {
        var currentElement = this.getElement();
        replaceElement(currentElement, newElement);
        this.elOverride = newElement;
    };
    AppDrag.prototype.resetElement = function () {
        if (!this.elOverride) {
            return;
        }
        var currentElement = this.getElement();
        replaceElement(currentElement, this.el.nativeElement);
        this.elOverride = undefined;
    };
    AppDrag.prototype.registerHandle = function (handle) {
        if (this.handles.every(function (item) { return item !== handle; })) {
            this.handles.push(handle);
        }
    };
    AppDrag.prototype.unregisterHandle = function (handle) {
        this.handles = this.handles.filter(function (item) { return item !== handle; });
    };
    AppDrag.prototype.setDropList = function (dropList) {
        if (this.dropList$.value === dropList) {
            return;
        }
        else if (this.dropList$.value) {
            this.clearDropList(this.dropList$.value);
        }
        this.dropList$.next(dropList);
    };
    AppDrag.prototype.clearDropList = function (dropList) {
        if (this.dropList$.value === dropList) {
            this.dropList$.next(undefined);
        }
    };
    AppDrag.prototype.initDrag = function (dropList) {
        var _this = this;
        this.deinitDrag();
        this.subscriptions.push(fromEvent(this.el.nativeElement, 'mousedown')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), filter(function (e) { return !isDragMouseDownEventHandled(e) && !isDragMouseDownEventPrevented(e); }), filter(function (e) {
            handleDragMouseDownEvent(e);
            if (_this.handles.length) {
                return !!_this.handles
                    .filter(function (item) { return !item.isDisabled(); })
                    .map(function (item) { return item.element.nativeElement; })
                    .find(function (handle) {
                    return !!e.target && (e.target === handle || handle.contains(e.target));
                });
            }
            else {
                return true;
            }
        }), untilDestroyed(this))
            .subscribe(function (e) {
            _this.waitDragStart(dropList, e);
        }));
        this.subscriptions.push(combineLatest(merge(fromEvent(this.el.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(this.el.nativeElement, 'mouseleave').pipe(map(function () { return false; }))), dropList.getDraggingState$())
            .pipe(map(function (_a) {
            var hover = _a[0], state = _a[1];
            return hover && !!state ? { drag: state.drag } : undefined;
        }), distinctUntilChanged(function (lhs, rhs) {
            var lhsDrag = lhs ? lhs.drag : undefined;
            var rhsDrag = rhs ? rhs.drag : undefined;
            return lhsDrag === rhsDrag;
        }), untilDestroyed(this))
            .subscribe(function (e) {
            _this.draggingOver.emit(e);
        }));
    };
    AppDrag.prototype.deinitDrag = function () {
        if (!this.subscriptions.length) {
            return;
        }
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.subscriptions = [];
    };
    AppDrag.prototype.waitDragStart = function (dropList, downEvent) {
        var _this = this;
        var subscriptions = [];
        subscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (moveEvent) {
            moveEvent.preventDefault();
            var distanceX = Math.abs(moveEvent.clientX - downEvent.clientX);
            var distanceY = Math.abs(moveEvent.clientY - downEvent.clientY);
            if (distanceX + distanceY >= _this.dragStartThreshold) {
                _this.dragStart(dropList, downEvent);
                subscriptions.forEach(function (item) { return item.unsubscribe(); });
            }
        }));
        subscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function () {
            subscriptions.forEach(function (item) { return item.unsubscribe(); });
        }));
    };
    AppDrag.prototype.dragStart = function (dropList, e) {
        var dragStartEvent = { source: this, event: e };
        dropList.dragStart(dragStartEvent);
        this.started.emit(dragStartEvent);
    };
    AppDrag.prototype.initPlaceholder = function () {
        this.destroyPlaceholder();
        if (this.placeholderTemplate) {
            var view = this.viewContainerRef.createEmbeddedView(this.placeholderTemplate.templateRef);
            view.detectChanges();
            this.setElement(getRootNode(view));
            this.placeholderView = view;
        }
    };
    AppDrag.prototype.getCustomPreview = function () {
        if (this.previewTemplate) {
            var view = this.viewContainerRef.createEmbeddedView(this.previewTemplate.templateRef);
            view.detectChanges();
            this.customPreviewView = view;
            return getRootNode(view);
        }
    };
    AppDrag.prototype.destroyPlaceholder = function () {
        this.resetElement();
        if (this.placeholderView) {
            this.placeholderView.destroy();
            this.placeholderView = undefined;
        }
    };
    AppDrag.prototype.destroyCustomPreview = function () {
        if (this.customPreviewView) {
            this.customPreviewView.destroy();
            this.customPreviewView = undefined;
        }
    };
    AppDrag.prototype.isPlaceholderMissing = function () {
        return this.placeholderTemplate && !this.placeholderView;
    };
    AppDrag.prototype.getBounds = function () {
        return this.getElement().getBoundingClientRect();
    };
    return AppDrag;
}());
export { AppDrag };

<ng-template #content><ng-content></ng-content></ng-template>
<ng-template #label_buttons>
  <ng-container *ngFor="let item of labelButtons">
    <a
      *ngIf="item.link"
      [appLink]="item.link"
      class="button button_tiny"
      [class.button_icon]="!item.label"
      [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
      [appButtonTintColorStyle]="tintStyles.Default"
      tabindex="-1"
      [appTip]="item.tip"
    >
      <span class="icon button__icon" [class.button__icon_left]="item.label" [appIcon]="item.icon"> </span>
      <span *ngIf="item.label" class="button__label">{{ item.label }}</span>
    </a>
    <a
      *ngIf="item.click"
      class="button button_tiny"
      [class.button_icon]="!item.label"
      [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
      [appButtonTintColorStyle]="tintStyles.Default"
      tabindex="-1"
      [appTip]="item.tip"
      (click)="item.click($event)"
    >
      <span class="icon button__icon" [class.button__icon_left]="item.label" [appIcon]="item.icon"> </span>
      <span *ngIf="item.label" class="button__label">{{ item.label }}</span>
    </a>
  </ng-container>
</ng-template>

<ng-container *ngIf="!readonly">
  <div
    *ngIf="label"
    class="field"
    [class.field_manual-margin]="manualMargin"
    [class.field_fill-vertical]="fillVertical"
  >
    <label *ngIf="field.label | appIsSet" class="field__label" [for]="field.name | appUniqueId: idToken">
      <span class="field__label-text">{{ field.label }}</span>
      <span
        *ngIf="tooltip | appIsSet"
        class="field__label-help help-icon icon-help"
        [appTip]="tooltip"
        [appTipOptions]="{ side: 'top' }"
      ></span>
      <span *ngIf="requiredAsterisk && field.required" class="field__label-required">*</span>
      <span *ngIf="!requiredAsterisk && !field.required" class="field__label-optional"
        >({{ 'optional' | localize }})</span
      >
      <ng-container *ngTemplateOutlet="label_buttons"></ng-container>
    </label>
    <div class="field__content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <div *ngIf="field.description" class="field__description">
        <app-render [content]="field.description"></app-render>
      </div>
    </div>
    <app-field-errors *ngIf="errors" [form]="form" [fieldName]="field.name"></app-field-errors>
  </div>

  <ng-container *ngIf="!label">
    <div class="field__content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <app-field-errors *ngIf="errors" [form]="form" [fieldName]="field.name"></app-field-errors>
  </ng-container>
</ng-container>

<ng-container *ngIf="readonly">
  <div
    *ngIf="label"
    class="field"
    [class.field_manual-margin]="manualMargin"
    [class.field_fill-vertical]="fillVertical"
  >
    <label *ngIf="field.label | appIsSet" class="field__label" [for]="field.name | appUniqueId: idToken">
      <span class="field__label-text">{{ field.label }}</span>
      <span
        *ngIf="tooltip | appIsSet"
        class="field__label-help help-icon icon-help"
        [appTip]="tooltip"
        [appTipOptions]="{ side: 'top' }"
      ></span>
      <ng-container *ngTemplateOutlet="label_buttons"></ng-container>
    </label>
    <div class="field__content" [class.field__content_truncate]="truncate">
      <!--      <div class="field__value">-->
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <!--      </div>-->
      <div *ngIf="field.description" class="field__description">
        <app-render [content]="field.description"></app-render>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!label">
    <div class="field__content" [class.field__content_truncate]="truncate">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<app-menu-item
  [icon]="menuItem.icon"
  [title]="menuItem.title | appCapitalize"
  [link]="link"
  [menuBlock]="menuBlock"
  [children]="menuItem.children"
  [childrenVertical]="childrenVertical"
  [primary]="primary"
  [dropdown]="dropdown"
  [forceActive]="forceActive"
  [forceOpened]="forceOpened"
  (dropdownOpen)="dropdownOpen.emit()"
  (dropdownClose)="dropdownClose.emit()"
>
</app-menu-item>

import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { GradientStop } from '@modules/views';
import { controlValue, isSet } from '@shared';

import { ColorControl } from './color.control';

export class GradientStopControl extends FormGroup {
  instance: GradientStop;

  controls: {
    position: FormControl;
    color: ColorControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
  };

  constructor(state: Partial<GradientStop> = {}) {
    super({
      position: new FormControl(isSet(state.position) ? state.position : 0),
      color: new ColorControl(isSet(state.color) ? state.color : {}),
      color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
      color_input: new FieldInputControl({ name: 'value' })
    });
  }

  deserialize(value: GradientStop, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.position.patchValue(value.position, { emitEvent: options.emitEvent });

    if (value.color) {
      this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
    }

    this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
    this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });
  }

  getColor$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    return combineLatest(
      controlValue(this.controls.color).pipe(map(() => this.controls.color.serialize())),
      controlValue<boolean>(this.controls.color_input_enabled),
      controlValue(this.controls.color_input).pipe(map(() => this.controls.color_input.serialize()))
    ).pipe(
      switchMap(([color, colorInputEnabled, colorInput]) => {
        if (colorInputEnabled && colorInput) {
          return applyParamInput$(colorInput, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext,
            defaultValue: ''
          });
        } else if (!colorInputEnabled && color) {
          return of(color.css());
        }
      })
    );
  }

  getInstance(instance?: GradientStop): GradientStop {
    if (!instance) {
      instance = new GradientStop();
      instance.generateId();
    }

    instance.position = this.controls.position.value;

    if (this.controls.color_input_enabled.value) {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = this.controls.color_input.value
        ? new Input().deserialize(this.controls.color_input.value)
        : undefined;
    } else {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = undefined;
    }

    return instance;
  }

  getId(): string {
    return this.instance ? this.instance.id : undefined;
  }

  serialize(): GradientStop {
    return this.getInstance(this.instance);
  }
}

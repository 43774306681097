import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ParameterArray, ParameterField } from '@modules/fields';

@Injectable()
export class ViewEditorParametersForm extends FormGroup {
  controls: {
    parameters: ParameterArray;
    test_parameters: FormControl;
  };

  constructor() {
    super({
      parameters: new ParameterArray([]),
      test_parameters: new FormControl({})
    });
  }

  init(options: { parameters: ParameterField[]; testParameters: Object }) {
    this.controls.parameters.patchValue(options.parameters);
    this.controls.test_parameters.patchValue(options.testParameters);
  }

  updateTestParametersValue(name: string, value: any) {
    const params = { ...this.controls.test_parameters.value, [name]: value };
    this.controls.test_parameters.patchValue(params);
  }

  getParameters(): ParameterField[] {
    return this.controls.parameters.value;
  }

  getTestParameters(): Object {
    return this.controls.test_parameters.value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { isAbsoluteUrl } from '../../utils/url/url';

@Pipe({
  name: 'appIsAbsoluteUrl'
})
export class IsAbsoluteUrlPipe implements PipeTransform {
  transform(value: any): any {
    return isAbsoluteUrl(value);
  }
}

<div
  class="customize-title customize-title_interactive"
  [class.customize-title_active]="editing"
  (click)="onClick($event)"
  #root
>
  <ng-container *ngIf="!editing">
    <div class="customize-title__text">{{ title || 'Title' }}</div>
    <div class="customize-title__text-action icon-pen"></div>
  </ng-container>

  <ng-container *ngIf="editing">
    <input class="customize-title__input" [(ngModel)]="editingValue" [appAutofocus]="true" (keyup)="onKeyUp($event)" />

    <div class="customize-title__input-actions">
      <a
        href="javascript:void(0)"
        class="customize-title__input-action"
        (click)="$event.stopPropagation(); cancelEditing()"
      >
        <div class="customize-title__input-action-inner icon-close"></div>
      </a>

      <a
        href="javascript:void(0)"
        class="customize-title__input-action customize-title__input-action_primary"
        (click)="$event.stopPropagation(); saveEditing()"
      >
        <div class="customize-title__input-action-inner icon-check_2"></div>
      </a>
    </div>
  </ng-container>
</div>

import { FormControl, FormGroup } from '@angular/forms';

import { isSet } from '@shared';

import { FieldType } from '../data/field-type';
import { ParameterValue } from '../data/parameter-value';
import { ParameterNameControl } from './parameter-name.control';

export class ParameterValueControl extends FormGroup {
  instance: ParameterValue;
  controls: {
    name: ParameterNameControl;
    value: FormControl;
    field: FormControl;
    params: FormControl;
    required: FormControl;
  };

  constructor() {
    super({
      name: new ParameterNameControl(''),
      value: new FormControl(''),
      field: new FormControl(FieldType.Text),
      params: new FormControl({}),
      required: new FormControl(true)
    });
  }

  deserialize(item: ParameterValue) {
    this.instance = item;

    this.controls.name.patchValue(item.name);
    this.controls.value.patchValue(item.value);
    this.controls.field.patchValue(item.field);
    this.controls.params.patchValue(item.params);
    this.controls.required.patchValue(item.required);

    this.markAsPristine();
  }

  serialize(): ParameterValue {
    const result = new ParameterValue();

    if (this.instance) {
      result.deserialize(this.instance.serialize());
    }

    result.name = this.controls.name.value;
    result.value = this.controls.value.value;
    result.field = this.controls.field.value;
    result.params = this.controls.params.value;
    result.required = this.controls.required.value;

    return result;
  }

  hasName() {
    return isSet(this.controls.name.value);
  }
}

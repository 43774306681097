<td *ngFor="let column of columnListItems; trackBy: trackByFn; let i = index" class="table__column">
  <div
    *ngIf="column.listItem"
    [class.table__column-inner]="!(column.column.field == fieldTypes.Text && column.column.params['wide'])"
  >
    <app-auto-field
      *ngIf="column.listItem.str == undefined"
      [field]="createField(column.column)"
      [label]="false"
      [readonly]="true"
      [style.pointer-events]="'none'"
      [value]="row.model.getAttribute(column.column.name)"
      (click)="$event.stopPropagation()"
    >
    </app-auto-field>
    <ng-container *ngIf="column.listItem.str != undefined">
      {{ column.listItem.str | appSafeAsync }}
    </ng-container>
  </div>

  <ng-container *ngIf="!column.listItem">
    Error
  </ng-container>
</td>

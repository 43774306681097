import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldType } from '@modules/fields';
import { forceObservable, isSet, TypedChanges } from '@shared';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { FormulaToken } from '../../data/formula-token';
import { ViewContextTokenProvider } from '../../services/view-context-token-provider/view-context-token-provider';

@Component({
  selector: 'app-view-context-token-popover-section-item',
  templateUrl: './view-context-token-popover-section-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverSectionItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: FormulaSectionItem;
  @Input() activeToken: FormulaSectionItem;
  @Input() descriptionAlwaysVisible = false;
  @Output() hover = new EventEmitter<void>();
  @Output() out = new EventEmitter<void>();
  @Output() opened = new EventEmitter<FormulaSection>();
  @Output() selected = new EventEmitter<FormulaSectionItem>();

  valueDisplay: string;
  valueLoading = false;
  overrideItem: FormulaSectionItem;

  constructor(private tokenProvider: ViewContextTokenProvider, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ViewContextTokenPopoverSectionItemComponent>): void {
    if (changes.item) {
      this.updateValue(this.item.item);
    }
  }

  get effectiveItem(): FormulaSectionItem {
    return this.overrideItem || this.item;
  }

  updateValue(token: FormulaToken) {
    if (!token || !isSet(token.value)) {
      this.valueDisplay = undefined;
      this.valueLoading = false;
      this.cd.markForCheck();
      return;
    }

    const tokenValue$ = forceObservable(token.value);

    this.valueDisplay = undefined;
    this.valueLoading = true;
    this.cd.markForCheck();

    tokenValue$.pipe(untilDestroyed(this)).subscribe(
      tokenValue => {
        this.valueDisplay = this.tokenProvider.getValueDisplay(tokenValue, {
          name: token.label,
          type: token.fieldType,
          params: token.fieldParams
        });
        this.overrideItem = this.getOverrideItem(token, tokenValue);
        this.valueLoading = false;
        this.cd.markForCheck();
      },
      () => {
        this.valueLoading = false;
        this.cd.markForCheck();
      }
    );
  }

  getOverrideItem(token: FormulaToken, tokenValue: any): FormulaSectionItem {
    if (token.fieldType == FieldType.JSON && isSet(tokenValue)) {
      const structure =
        token.fieldParams && token.fieldParams['display_fields'] && token.fieldParams['structure']
          ? token.fieldParams['structure']
          : undefined;
      const jsonItem = this.tokenProvider.mapJsonToken(token.token, this.item.path, token.label, tokenValue, 0, {
        structure: structure
      });

      return jsonItem && jsonItem.section && jsonItem.section.items.length ? jsonItem : undefined;
    }
  }
}

import { FormControl, FormGroup } from '@angular/forms';

import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { defaultIconFillIcon, IconFill } from '@modules/views';
import { isSet } from '@shared';

import { ColorControl } from './color.control';

export class IconFillControl extends FormGroup {
  instance: IconFill;

  controls: {
    icon: FormControl;
    icon_input: FieldInputControl;
    icon_input_enabled: FormControl;
    color: ColorControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
    size: FormControl;
  };

  constructor(state: Partial<IconFill> = {}) {
    super({
      icon: new FormControl(isSet(state.icon) ? state.icon : defaultIconFillIcon),
      icon_input: new FieldInputControl({ name: 'value' }),
      icon_input_enabled: new FormControl(isSet(state.iconInput) ? !!state.iconInput : false),
      color: new ColorControl(isSet(state.color) ? state.color : {}),
      color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
      color_input: new FieldInputControl({ name: 'value' }),
      size: new FormControl(isSet(state.size) ? state.size : undefined)
    });
  }

  deserialize(value: IconFill, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.icon.patchValue(value.icon || defaultIconFillIcon, { emitEvent: options.emitEvent });
    this.controls.icon_input.patchValue(value.iconInput ? value.iconInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });
    this.controls.icon_input_enabled.patchValue(!!value.iconInput, { emitEvent: options.emitEvent });

    if (value.color) {
      this.controls.color.deserialize(value.color);
    }

    this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
    this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });

    this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: IconFill): IconFill {
    if (!instance) {
      instance = new IconFill();
    }

    if (this.controls.icon_input_enabled.value) {
      instance.icon = this.controls.icon.value;
      instance.iconInput = this.controls.icon_input.value
        ? new Input().deserialize(this.controls.icon_input.value)
        : undefined;
    } else {
      instance.icon = this.controls.icon.value;
      instance.iconInput = undefined;
    }

    if (this.controls.color_input_enabled.value) {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = this.controls.color_input.value
        ? new Input().deserialize(this.controls.color_input.value)
        : undefined;
    } else {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = undefined;
    }

    instance.size = this.controls.size.value;

    return instance;
  }

  serialize(): IconFill {
    return this.getInstance(this.instance);
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { controlValue, isElementInViewport, isSet, KeyboardEventKeyCode, scrollTo, scrollToElement2 } from '@shared';
import { ViewContextTokenProvider } from '../../services/view-context-token-provider/view-context-token-provider';
import { findFormulaTokenSibling, findSectionToken, searchFormulaSections } from '../../utils/view-context-tokens';
var referencePopoverMouseEvent = '_referencePopoverMouseEvent';
export function markReferencePopoverMouseEvent(e, id) {
    e[referencePopoverMouseEvent] = id;
}
export function isReferencePopoverMouseEvent(e, id) {
    return e[referencePopoverMouseEvent] == id;
}
var ViewContextTokenPopoverComponent = /** @class */ (function () {
    function ViewContextTokenPopoverComponent(provider, cd) {
        var _this = this;
        this.provider = provider;
        this.cd = cd;
        this.extraSections = [];
        this.hideSections = [];
        this.search = '';
        this.selected = new EventEmitter();
        this.close = new EventEmitter();
        this.loading = false;
        this.sections$ = new BehaviorSubject(undefined);
        this.displaySections$ = new BehaviorSubject([]);
        this.displayTabSections$ = this.displaySections$.pipe(map(function (sections) {
            return sections.filter(function (section) { return section.items.length; }).filter(function (section) { return !section.hideTab && section.icon; });
        }));
        this.openedSections = [];
        this.selectedSectionName$ = new BehaviorSubject(undefined);
        this.selectedSection$ = new BehaviorSubject(undefined);
        this.activeToken$ = new BehaviorSubject(undefined);
        this.hoverToken$ = new BehaviorSubject(undefined);
        this.searchControl = new FormControl('');
        this.selectFunction$ = new BehaviorSubject(undefined);
        this.search$ = new BehaviorSubject('');
        this.keyboardNavigation = false;
        this.trackSection = (function () {
            return function (i, item) { return item.token || item.label || i; };
        })();
        this.markReferencePopoverMouseEvent = function (e) { return markReferencePopoverMouseEvent(e, _this.id); };
    }
    ViewContextTokenPopoverComponent.prototype.ngOnInit = function () {
        this.initSections();
        this.initDisplaySections();
        this.initSelectedSection();
        this.initFunctionReference();
        this.initHotkeys();
    };
    ViewContextTokenPopoverComponent.prototype.ngOnDestroy = function () { };
    ViewContextTokenPopoverComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectFunction) {
            this.selectFunction$.next(this.selectFunction);
        }
        if (changes.search) {
            this.search$.next(this.search);
            this.searchControl.patchValue('');
        }
    };
    ViewContextTokenPopoverComponent.prototype.initSections = function () {
        var _this = this;
        combineLatest(this.provider.getContextElementSection(this.contextElement, this.contextElementPath, this.contextElementPaths), this.provider.getSections$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var elementSection = _a[0], sections = _a[1];
            sections = _this.extraSections.filter(function (item) { return item.pinned; }).concat((elementSection && elementSection.items.length ? [elementSection] : []), sections.map(function (section) {
                if (_this.contextElement) {
                    return __assign({}, section, { items: section.items.filter(function (item) {
                            var itemContextElement = item.data
                                ? item.data['ignoreContextElement']
                                : undefined;
                            if (itemContextElement) {
                                return itemContextElement !== _this.contextElement;
                            }
                            else {
                                return true;
                            }
                        }) });
                }
                else {
                    return section;
                }
            }), _this.extraSections.filter(function (item) { return !item.pinned; }));
            if (_this.hideSections.length) {
                sections = sections.filter(function (section) { return !_this.hideSections.includes(section.name); });
            }
            _this.sections$.next(sections);
        });
    };
    ViewContextTokenPopoverComponent.prototype.initDisplaySections = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        var search$ = combineLatest(controlValue(this.searchControl), this.search$).pipe(map(function (_a) {
            var searchControl = _a[0], searchInput = _a[1];
            return (isSet(searchControl) ? searchControl : searchInput);
        }));
        combineLatest(this.sections$, search$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var sections = _a[0], search = _a[1];
            if (sections) {
                var displaySections = sections;
                if (isSet(search)) {
                    var filteredSections = searchFormulaSections(sections, search);
                    if (findSectionToken(filteredSections, { nested: true })) {
                        displaySections = filteredSections;
                    }
                }
                _this.displaySections$.next(displaySections);
                _this.loading = false;
                _this.cd.markForCheck();
            }
            else {
                _this.loading = true;
                _this.cd.markForCheck();
            }
            _this.resetActiveToken();
            _this.resetHoverToken();
        });
    };
    ViewContextTokenPopoverComponent.prototype.initSelectedSection = function () {
        var _this = this;
        combineLatest(this.selectedSectionName$, this.displaySections$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var sectionName = _a[0], sections = _a[1];
            _this.selectedSection$.next(sections.find(function (item) { return isSet(item.label) && item.label == sectionName; }));
        });
    };
    ViewContextTokenPopoverComponent.prototype.initFunctionReference = function () {
        var _this = this;
        combineLatest(this.hoverToken$, this.sections$, this.selectFunction$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var hoverToken = _a[0], sections = _a[1], selectFunction = _a[2];
            var functionsSection = selectFunction && sections ? sections.find(function (item) { return item.name == 'functions'; }) : undefined;
            var selectFunctionItem = selectFunction && functionsSection
                ? functionsSection.items.find(function (item) {
                    return item.item && item.item.function && item.item.function.name == selectFunction;
                })
                : undefined;
            if (hoverToken && hoverToken.item && hoverToken.item.function) {
                _this.showFunctionReference = hoverToken.item.function;
            }
            else if (selectFunctionItem) {
                _this.showFunctionReference = selectFunctionItem ? selectFunctionItem.item.function : undefined;
            }
            else {
                _this.showFunctionReference = undefined;
            }
            _this.cd.markForCheck();
        });
    };
    ViewContextTokenPopoverComponent.prototype.initHotkeys = function () {
        var _this = this;
        fromEvent(document, 'keydown')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var activeToken = _this.activeToken$.value;
            if (e.keyCode == KeyboardEventKeyCode.Tab && !e.shiftKey && activeToken) {
                e.stopPropagation();
                e.preventDefault();
                if (activeToken.item) {
                    _this.onItemSelected(activeToken.item);
                }
                else if (activeToken.section && activeToken.section.items.length) {
                    _this.addOpenedSection(activeToken.section);
                    _this.keyboardNavigation = true;
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.Tab && e.shiftKey && _this.openedSection) {
                e.stopPropagation();
                e.preventDefault();
                _this.popOpenedSection();
            }
            else if (e.keyCode == KeyboardEventKeyCode.ArrowUp && activeToken) {
                e.stopPropagation();
                e.preventDefault();
                var sections = _this.getEffectiveSections();
                var sibling = findFormulaTokenSibling(sections, activeToken, false);
                if (sibling) {
                    _this.activeToken$.next(sibling);
                    _this.keyboardNavigation = true;
                    _this.scrollToItem(sibling);
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.ArrowDown && activeToken) {
                e.stopPropagation();
                e.preventDefault();
                var sections = _this.getEffectiveSections();
                var sibling = findFormulaTokenSibling(sections, activeToken, true);
                if (sibling) {
                    _this.activeToken$.next(sibling);
                    _this.keyboardNavigation = true;
                    _this.scrollToItem(sibling);
                }
            }
        });
        fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.keyboardNavigation = false;
        });
    };
    ViewContextTokenPopoverComponent.prototype.getEffectiveSections = function () {
        var _this = this;
        if (this.openedSection) {
            return [this.openedSection];
        }
        else if (this.selectedSection$.value) {
            return [this.selectedSection$.value];
        }
        else {
            return this.displaySections$.value.map(function (section) {
                var maxItems = isSet(_this.searchControl.value) ? 20 : 6;
                if (section.items.length > maxItems) {
                    return __assign({}, section, { items: section.items.slice(0, maxItems) });
                }
                else {
                    return section;
                }
            });
        }
    };
    ViewContextTokenPopoverComponent.prototype.scrollToItem = function (item) {
        var selector = "[data-formula-token-path=\"" + item.path + "\"]";
        var element = this.scrollableElement.nativeElement.querySelector(selector);
        if (!element) {
            return false;
        }
        if (isElementInViewport(element, this.scrollableElement.nativeElement)) {
            return;
        }
        scrollToElement2(this.scrollableElement.nativeElement, element, 0, -40);
    };
    Object.defineProperty(ViewContextTokenPopoverComponent.prototype, "openedSection", {
        get: function () {
            return this.openedSections[this.openedSections.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewContextTokenPopoverComponent.prototype, "openedSectionParent", {
        get: function () {
            return this.openedSections[this.openedSections.length - 2];
        },
        enumerable: true,
        configurable: true
    });
    ViewContextTokenPopoverComponent.prototype.setOpenedSections = function (sections) {
        this.openedSections = sections;
        this.cd.markForCheck();
        this.resetActiveToken();
        this.resetHoverToken();
    };
    ViewContextTokenPopoverComponent.prototype.addOpenedSection = function (section) {
        this.setOpenedSections(this.openedSections.concat([section]));
        this.cd.detectChanges();
        scrollTo(this.scrollableElement.nativeElement, 0);
    };
    ViewContextTokenPopoverComponent.prototype.popOpenedSection = function () {
        this.setOpenedSections(this.openedSections.slice(0, this.openedSections.length - 1));
    };
    ViewContextTokenPopoverComponent.prototype.clearOpenedSections = function () {
        this.setOpenedSections([]);
    };
    ViewContextTokenPopoverComponent.prototype.setSelectedSectionName = function (name) {
        this.selectedSectionName$.next(name);
        this.resetActiveToken();
        this.resetHoverToken();
        this.clearOpenedSections();
        this.cd.detectChanges();
        scrollTo(this.scrollableElement.nativeElement, 0);
    };
    ViewContextTokenPopoverComponent.prototype.resetActiveToken = function () {
        var sections = this.getEffectiveSections();
        var token = findSectionToken(sections.filter(function (item) { return !item.horizontal; }), { nested: false });
        this.activeToken$.next(token);
    };
    ViewContextTokenPopoverComponent.prototype.resetHoverToken = function () {
        this.hoverToken$.next(undefined);
    };
    ViewContextTokenPopoverComponent.prototype.onItemHover = function (item) {
        if (!this.keyboardNavigation) {
            this.activeToken$.next(item);
            this.hoverToken$.next(item);
        }
    };
    ViewContextTokenPopoverComponent.prototype.onItemOut = function () {
        this.hoverToken$.next(undefined);
    };
    ViewContextTokenPopoverComponent.prototype.onItemSelected = function (token) {
        this.setSelectedSectionName(undefined);
        this.clearSearch();
        this.selected.emit(token);
    };
    ViewContextTokenPopoverComponent.prototype.clearSearch = function () {
        this.searchControl.setValue('');
    };
    ViewContextTokenPopoverComponent.prototype.scrollToTop = function () {
        scrollTo(this.scrollableElement.nativeElement, 0);
    };
    ViewContextTokenPopoverComponent.prototype.onReferencePopoverContentChanged = function () {
        if (this.referencePopoverOverlay && this.referencePopoverOverlay.overlayRef) {
            this.referencePopoverOverlay.overlayRef.updatePosition();
        }
    };
    return ViewContextTokenPopoverComponent;
}());
export { ViewContextTokenPopoverComponent };

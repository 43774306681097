<div
  class="list"
  [class.list_scrollable]="scrollable"
  [class.list_flexible]="!scrollable"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport list__viewport_scrollable list__viewport_bounds">
      <div class="list__component kanban-board">
        <app-kanban-board-stub *ngIf="loading" [animating]="loading"></app-kanban-board-stub>

        <div *ngIf="!loading && !isAvailable" class="kanban-board-message">
          <app-error [compact]="true" [fill]="scrollable" [title]="'Not configured'">
            Your <strong>Jet Backend Plugin</strong> does not support <strong>Kanban board</strong> layout. Please,
            update to the latest version
          </app-error>
        </div>

        <div *ngIf="!loading && isAvailable && !configured" class="kanban-board-message">
          <app-error [compact]="true" [fill]="scrollable" [title]="'Not configured' | localize">
            {{ 'Unfortunately, this component is not configured yet' | localize }}
          </app-error>
        </div>

        <ng-container *ngIf="!loading && isAvailable && configured">
          <app-kanban-board-column
            *ngFor="let stage of listState.stages; let i = index; trackBy: trackStageFn"
            class="kanban-board__column"
            [stage]="stage"
            [stageIndex]="i"
            [listState]="listState"
            [settings]="settings"
            [dataSource]="listState.dataSource"
            [modelDescription]="listState.modelDescription"
            [visibleColumns]="visibleColumns"
            [selectedItem]="selectedItem"
            [checkedItems]="checkedItems"
            [context]="context"
            [contextElement]="contextElement"
            [uniqueName]="getStageName(i)"
            [droppable]="droppable"
            [accentColor]="accentColor"
            [style.width.%]="100 / listState.stages.length"
            (fetchStarted)="onFetch()"
            (fetchFinished)="onFetched()"
            (updateSelected)="setSelectedItem($event)"
            (updateChecked)="setChecked($event)"
            (dragStarted)="onDragStarted(i, $event)"
            (dragFinished)="onDragFinished(i, $event)"
            (itemAdded)="onItemAdded(i, $event)"
            (modelUpdated)="onModelUpdated($event)"
          >
          </app-kanban-board-column>
        </ng-container>
      </div>
    </div>
  </div>
</div>

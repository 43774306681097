import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';

import { IntercomService } from '@modules/analytics';
import { CurrentProjectStore, Project, ProjectJob } from '@modules/projects';

import { ProjectJobsComponent } from '../project-jobs/project-jobs.component';

@Component({
  selector: 'app-demo-widget',
  templateUrl: './demo-widget.component.html'
})
export class DemoWidgetComponent implements OnInit, OnDestroy {
  project: Project;
  jobs: ProjectJob[];
  jobsOpened = false;
  popup: ProjectJobsComponent;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    public intercomService: IntercomService,
    private popupService: PopupService
  ) {}

  ngOnInit() {
    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(project => {
      this.project = project;
      this.jobs = project && project.jobs.length ? project.jobs : undefined;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  showJobs() {
    this.jobsOpened = true;
    this.popupService.push({
      component: ProjectJobsComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        project: this.project,
        createDemo: false
      },
      outputs: {
        opened: [
          popup => {
            this.popup = popup;
          }
        ],
        closed: [
          () => {
            this.jobsOpened = false;
            this.cd.markForCheck();
          }
        ]
      },
      injector: this.injector
    });
  }

  closeJobs() {
    if (this.popup) {
      this.jobsOpened = false;
      this.popup.close();
    }
  }

  toggleJobs() {
    if (this.jobsOpened) {
      this.closeJobs();
    } else {
      this.showJobs();
    }
  }
}

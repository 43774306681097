import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { TypedChanges } from '../../utils/angular/angular';
import { isSet } from '../../utils/common/common';
import { removeChildren } from '../../utils/document-utils/document-utils';

@Component({
  selector: 'app-custom-code',
  templateUrl: './custom-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCodeComponent implements OnInit, OnChanges {
  @Input() appendScripts: string;
  @Input() appendStyles: string;

  @ViewChild('scripts') scriptsElement: ElementRef;
  @ViewChild('styles') stylesElement: ElementRef;

  scripts: string;
  styles: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<CustomCodeComponent>): void {
    if (changes.appendScripts) {
      this.updateScripts();
    }

    if (changes.appendStyles) {
      this.updateStyles();
    }
  }

  updateScripts() {
    if (!this.scriptsElement || this.scripts == this.appendScripts) {
      return;
    }

    removeChildren(this.scriptsElement.nativeElement);

    if (!isSet(this.appendScripts)) {
      return;
    }

    const node = document.createElement('script');

    node.innerHTML = this.appendScripts;

    this.scriptsElement.nativeElement.appendChild(node);
    this.scripts = this.appendScripts;
  }

  updateStyles() {
    if (!this.stylesElement || this.styles == this.appendStyles) {
      return;
    }

    removeChildren(this.stylesElement.nativeElement);

    if (!isSet(this.appendStyles)) {
      return;
    }

    const node = document.createElement('style');

    node.type = 'text/css';
    node.innerHTML = this.appendStyles;

    this.stylesElement.nativeElement.appendChild(node);
    this.styles = this.appendStyles;
  }
}

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export enum ConditionWorkflowStepType {
  Boolean = 'boolean',
  Switch = 'switch'
}

export class ConditionWorkflowStepItem {
  public uid: string;
  public label: string;
  public condition: Input;
  public steps: WorkflowStep[] = [];

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.label = data['label'];

    if (data['condition']) {
      this.condition = new Input().deserialize(data['condition']);
    }

    if (data['steps']) {
      this.steps = data['steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      label: this.label,
      condition: this.condition ? this.condition.serialize() : undefined,
      steps: this.steps.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

export class ConditionWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Condition;
  public conditionType = ConditionWorkflowStepType.Boolean;
  public items: ConditionWorkflowStepItem[] = [];
  public elseSteps: WorkflowStep[] = [];
  public elseLabel: string;

  deserialize(data: Object) {
    super.deserialize(data);
    this.conditionType = data['condition_type'];
    this.elseLabel = data['else_label'];

    if (data['items']) {
      this.items = data['items'].map(item => new ConditionWorkflowStepItem().deserialize(item));
    }

    if (data['else_steps']) {
      this.elseSteps = data['else_steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      condition_type: this.conditionType,
      items: this.items.map(item => item.serialize()),
      else_steps: this.elseSteps.map(item => item.serialize()),
      else_label: this.elseLabel
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Condition';
  }

  get analyticsName(): string {
    if (this.type) {
      return ['condition', this.type].join('_');
    } else {
      return 'condition';
    }
  }

  getIcon() {
    if (this.conditionType == ConditionWorkflowStepType.Boolean) {
      return 'switch';
    } else if (this.conditionType == ConditionWorkflowStepType.Switch) {
      return 'versions';
    } else {
      return super.getIcon();
    }
  }
}

registerWorkflowStepType(WorkflowStepType.Condition, ConditionWorkflowStep);

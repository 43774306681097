var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { FieldType, ValueFormat } from '@modules/fields';
import { isSet } from '@shared';
import { ValueFormatControl } from '../../value-format-edit/value-format.control';
var CharFieldViewParamsForm = /** @class */ (function () {
    function CharFieldViewParamsForm(fb, valueFormatControl) {
        this.fb = fb;
        this.valueFormatControl = valueFormatControl;
        this.modelDbFieldOptions = [];
        this.form = this.fb.group({
            wide: new FormControl(false),
            multiline: new FormControl(false),
            rows: new FormControl(8),
            value_format: valueFormatControl
        });
    }
    CharFieldViewParamsForm.prototype.init = function (control, context) {
        var _this = this;
        this.control = control;
        this.context = context;
        if (control.value) {
            var value = {};
            if (isSet(control.value['wide'])) {
                value['wide'] = control.value['wide'];
            }
            if (isSet(control.value['multiline'])) {
                value['multiline'] = control.value['multiline'];
            }
            if (isSet(control.value['rows'])) {
                value['rows'] = control.value['rows'];
            }
            this.form.patchValue(value, { emitEvent: false });
        }
        var valueFormat = control.value && control.value['value_format']
            ? new ValueFormat().deserialize(control.value['value_format'])
            : undefined;
        this.valueFormatControl.deserialize(valueFormat, { field: FieldType.Text });
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
        this.updateModelDbFieldOptions();
    };
    CharFieldViewParamsForm.prototype.submit = function () {
        var data = this.form.value;
        var valueFormat = this.valueFormatControl.serialize();
        data['value_format'] = valueFormat ? valueFormat.serialize() : undefined;
        this.control.patchValue(__assign({}, this.control.value, data));
    };
    CharFieldViewParamsForm.prototype.updateModelDbFieldOptions = function () {
        if (!this.context || !this.context['modelDescription']) {
            this.modelDbFieldOptions = [];
            return;
        }
        this.modelDbFieldOptions = this.context['modelDescription'].dbFields.map(function (item) {
            return {
                name: item.name,
                value: item.verboseName
            };
        });
    };
    return CharFieldViewParamsForm;
}());
export { CharFieldViewParamsForm };

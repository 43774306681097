import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, EventEmitter, Injector, Type, ViewContainerRef } from '@angular/core';
import keys from 'lodash/keys';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var DynamicComponentService = /** @class */ (function () {
    function DynamicComponentService(resolver, appRef) {
        this.resolver = resolver;
        this.appRef = appRef;
    }
    DynamicComponentService.prototype.setBindings = function (componentRef, data) {
        var component = componentRef.instance;
        var componentName = component.constructor ? component.constructor.name : component.toString();
        if (data.inputs) {
            for (var _i = 0, _a = keys(data.inputs); _i < _a.length; _i++) {
                var key = _a[_i];
                component[key] = data.inputs[key];
            }
        }
        if (data.outputs) {
            for (var _b = 0, _c = keys(data.outputs); _b < _c.length; _b++) {
                var key = _c[_b];
                if (!component.hasOwnProperty(key)) {
                    console.error("No such @Output \"" + key + "\" in \"" + componentName + "\"");
                    continue;
                }
                else if (!(component[key] instanceof Observable)) {
                    console.error("\"" + key + "\" is not a valid @Output in " + componentName);
                    continue;
                }
                else if (!component[key]) {
                    console.error("\"" + key + "\" @Output is set to " + component[key] + " in " + componentName);
                    continue;
                }
                var output = component[key];
                output.subscribe.apply(output, data.outputs[key]);
            }
        }
    };
    DynamicComponentService.prototype.createComponent = function (parentInjector, data, container) {
        var factory = (data.resolver || this.resolver).resolveComponentFactory(data.component);
        var injector = Injector.create({
            providers: data.providers || [],
            parent: parentInjector
        });
        if (container) {
            var newNode = document.createElement(factory.selector);
            container.appendChild(newNode);
            container = newNode;
        }
        var componentRef = factory.create(injector, undefined, container);
        this.setBindings(componentRef, data);
        if (container) {
            this.appRef.attachView(componentRef.hostView);
        }
        return componentRef;
    };
    DynamicComponentService.prototype.createComponentInViewContainer = function (parentInjector, data, container, index) {
        var factory = (data.resolver || this.resolver).resolveComponentFactory(data.component);
        var injector = Injector.create({
            providers: data.providers || [],
            parent: parentInjector
        });
        var componentRef = container.createComponent(factory, index, injector);
        this.setBindings(componentRef, data);
        return componentRef;
    };
    DynamicComponentService.prototype.appendComponentToElement = function (parentInjector, data, el) {
        var componentRef = this.createComponent(parentInjector, data, el);
        var domElem = componentRef.hostView.rootNodes[0];
        el.appendChild(domElem);
        return componentRef;
    };
    DynamicComponentService.prototype.appendComponentToBody = function (parentInjector, data) {
        return this.appendComponentToElement(parentInjector, data, document.body);
    };
    DynamicComponentService.ngInjectableDef = i0.defineInjectable({ factory: function DynamicComponentService_Factory() { return new DynamicComponentService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef)); }, token: DynamicComponentService, providedIn: "root" });
    return DynamicComponentService;
}());
export { DynamicComponentService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var PasswordFieldComponent = /** @class */ (function (_super) {
    __extends(PasswordFieldComponent, _super);
    function PasswordFieldComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PasswordFieldComponent.prototype, "inputSmall", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
        },
        enumerable: true,
        configurable: true
    });
    return PasswordFieldComponent;
}(FieldComponent));
export { PasswordFieldComponent };
registerFieldComponent(FieldType.Password, PasswordFieldComponent);

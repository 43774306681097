import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PER_PAGE_PARAM } from '@modules/models';
import { InstanceStore } from '@shared';

import { CustomViewTemplateService } from '../services/custom-view-template/custom-view-template.service';

@Injectable()
export class CustomViewTemplateCounterStore extends InstanceStore<number> implements OnDestroy {
  constructor(private customViewTemplateService: CustomViewTemplateService) {
    super();
  }

  ngOnDestroy(): void {}

  fetchData(): Observable<number> {
    return this.customViewTemplateService.get({ [PER_PAGE_PARAM]: 1 }).pipe(map(response => response.count));
  }

  getApprox$(): Observable<number> {
    return this.get$().pipe(
      map(value => {
        return value >= 10 ? Math.floor(value / 10) * 10 : undefined;
      })
    );
  }

  getApproxFirst$(): Observable<number> {
    return this.getApprox$().pipe(first());
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CustomizeBarValueWidgetEditDataComponent } from '../customize-bar-value-widget-edit-data/customize-bar-value-widget-edit-data.component';

@Component({
  selector: 'app-customize-bar-compare-value-widget-edit-data',
  templateUrl: './customize-bar-compare-value-widget-edit-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCompareValueWidgetEditDataComponent extends CustomizeBarValueWidgetEditDataComponent {
  @Input() nameControl: FormControl;
  @Input() growthNegativeControl: FormControl;
}

export enum SmartSuiteApplicationFieldType {
  TextField = 'textfield',
  TextAreaField = 'textareafield',
  RichTextAreaField = 'richtextareafield',
  HiddenTextField = 'hidden_textfield',
  EmailField = 'emailfield',
  AddressField = 'addressfield',
  PhoneField = 'phonefield',
  LinkField = 'linkfield',
  IpAddressField = 'ipaddressfield',
  DateField = 'datefield',
  DateRangeField = 'daterangefield',
  DueDateField = 'duedatefield',
  NumberField = 'numberfield',
  AutoNumberField = 'autonumberfield',
  RatingField = 'ratingfield',
  NumberSliderField = 'numbersliderfield',
  PercentCompleteField = 'percentcompletefield',
  YesNoField = 'yesnofield',
  SingleSelectField = 'singleselectfield',
  MultipleSelectField = 'multipleselectfield',
  LinkedRecordField = 'linkedrecordfield',
  TagsField = 'tagsfield',
  StatusField = 'statusfield',
  FileField = 'filefield',
  ColorPickerField = 'colorpickerfield',
  UserField = 'userfield',
  FullNameField = 'fullnamefield',
  RecordTitleField = 'recordtitlefield',
  AvailabilityStatusField = 'availabilitystatusfield',
  ChecklistField = 'checklistfield',
  CommentsCountField = 'commentscountfield',
  FirstCreatedField = 'firstcreatedfield',
  LastUpdatedField = 'lastupdatedfield',
  FormulaField = 'formulafield',
  CountField = 'countfield',
  LookupField = 'lookupfield',
  RollupField = 'rollupfield'
}

export interface SmartSuiteApplicationField {
  slug: string;
  label: string;
  field_type: SmartSuiteApplicationFieldType;
  params: {
    primary: boolean;
    restore_application_id: unknown | null;
    linked_field_slug: unknown | null;
    max_length?: unknown | null;
    composite: unknown | null;
    delimiter: string;
    required: boolean;
    unique: boolean;
    hidden: boolean;
    help_text: string;
    help_text_display_format: string;
    placeholder: string;
    default_value: unknown | null;
    width: number;
    system: boolean;
    valid: boolean;
    is_migrating: boolean;
    system_label: string;
    entries_allowed?: 'single' | 'multiple';
    display_format?: string;
    control_type?: string;
    edit_values?: boolean;
    edit_help_texts?: boolean;
    new_choices_allowed?: boolean;
    choices?: {
      value: unknown;
      label: string;
      value_help_text: unknown | null;
      value_color: string;
      value_order: number;
      allowed_transitions: string[];
    }[];
    include_filter?: boolean;
    field_filter?: {
      operator: 'and' | 'or';
      fields: string[];
    };
    notifications_enabled?: boolean;
    linked_application?: string;
    date_format?: string;
    include_time?: boolean;
    nested?: SmartSuiteApplicationField[];
    display_fields?: string[];
    tag_type?: string;
    view_mode?: string;
    items_in_row?: number;
    default_country?: unknown | null;
    custom_default_label?: string;
    visible_fields?: string[];
    column_widths?: Record<string, unknown>;
    filter_data?: unknown | null;
    sort_data?: unknown | null;
    do_not_create_backlink?: boolean;
    select_record_modal_visible_fields?: unknown | null;
    select_record_modal_filter_data?: unknown | null;
    select_record_modal_sort_data?: unknown | null;
    symmetric_field?: unknown | null;
    autocreated_symmetric_field?: boolean;
    is_auto_generated?: boolean;
    formula?: string;
    is_advanced?: boolean;
    target_field_structure?: SmartSuiteApplicationField;
    include_labels?: boolean;
    suffix?: string;
    prefix?: string;
    linked_field?: string;
    color?: string;
    deleted_filter?: unknown[];
    include_filters?: boolean;
    target_application?: boolean;
    drill_in?: {
      visible_fields: string[];
    };
    gradient_color?: unknown | null;
    min_value?: number;
    max_value?: number;
    value_increment?: number;
    allow_negative?: boolean;
    precision?: number;
    separator?: boolean;
    validators?: unknown[];
  };
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../common/tips/directives/tip/tip.directive";
import * as i3 from "../google-sheets-resource-settings-file/google-sheets-resource-settings-file.component.ngfactory";
import * as i4 from "../google-sheets-file-source";
import * as i5 from "@angular/common/http";
import * as i6 from "../google-sheets-resource-settings-file/google-sheets-resource-settings-file.component";
import * as i7 from "../../../../../../shared/components/loader/loader.component.ngfactory";
import * as i8 from "../../../../../../shared/components/loader/loader.component";
import * as i9 from "../../../../../../shared/pipes/deploy-css-url/deploy-css-url.pipe";
import * as i10 from "./google-sheets-resource-settings-files.component";
var styles_GoogleSheetsResourceSettingsFilesComponent = [];
var RenderType_GoogleSheetsResourceSettingsFilesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoogleSheetsResourceSettingsFilesComponent, data: {} });
export { RenderType_GoogleSheetsResourceSettingsFilesComponent as RenderType_GoogleSheetsResourceSettingsFilesComponent };
function View_GoogleSheetsResourceSettingsFilesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "item-selector-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" docs.google.com/spreadsheets/d/", " "]))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit.value["file"] == null) ? null : _v.parent.parent.context.$implicit.value["file"].id); _ck(_v, 1, 0, currVal_0); }); }
function View_GoogleSheetsResourceSettingsFilesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "item-selector-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" File is not correctly set up "]))], null, null); }
function View_GoogleSheetsResourceSettingsFilesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "item-selector-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_v.parent.context.$implicit.invalid || (_v.parent.context.$implicit === _co.selectedFile)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.invalid && (_v.parent.context.$implicit !== _co.selectedFile)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.value["verbose_name"] || ((_v.parent.context.$implicit.value["file"] == null) ? null : _v.parent.context.$implicit.value["file"].name)); _ck(_v, 2, 0, currVal_0); }); }
function View_GoogleSheetsResourceSettingsFilesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "item-selector-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" File is not correctly set up "]))], null, null); }
function View_GoogleSheetsResourceSettingsFilesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "item-selector-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" New file "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.invalid && (_v.parent.context.$implicit !== _co.selectedFile)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_GoogleSheetsResourceSettingsFilesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "item-selector-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFile(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i2.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Delete file"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GoogleSheetsResourceSettingsFilesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "item-selector__list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "item-selector-list-item"]], [[2, "item-selector-list-item_active", null], [2, "item-selector-list-item_error", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "item-selector-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "item-selector-list-item__icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "item-selector-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_7)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit.value["file"]; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_v.context.$implicit.value["file"]; _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.form.form.controls.files.controls.length > 1); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.selectedFile); var currVal_1 = _v.context.$implicit.invalid; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "/assets/images/resources/googlesheets-color.svg")); _ck(_v, 3, 0, currVal_2); }); }
function View_GoogleSheetsResourceSettingsFilesComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "item-selector__sections-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "item-selector__section-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Settings"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "item-selector__detail background-inputs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "app-google-sheets-resource-settings-file", [], null, [[null, "updating"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updating" === en)) {
        var pd_0 = (_co.setFileUpdating($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_GoogleSheetsResourceSettingsFileComponent_0, i3.RenderType_GoogleSheetsResourceSettingsFileComponent)), i0.ɵprd(512, null, i4.GoogleSheetsFileSource, i4.GoogleSheetsFileSource, [i5.HttpClient]), i0.ɵdid(6, 770048, null, 0, i6.GoogleSheetsResourceSettingsFileComponent, [i5.HttpClient, i4.GoogleSheetsFileSource, i0.NgZone, i0.ChangeDetectorRef], { form: [0, "form"], accessToken: [1, "accessToken"] }, { updating: "updating" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedFile; var currVal_1 = _co.accessToken; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
function View_GoogleSheetsResourceSettingsFilesComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "popup__overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i0.ɵdid(2, 114688, null, 0, i8.LoaderComponent, [], { fill: [0, "fill"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GoogleSheetsResourceSettingsFilesComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.DeployCssUrlPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 18, "div", [["class", "item-selector"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "item-selector__back"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Back "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "item-selector__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Import files from Google Sheets"])), (_l()(), i0.ɵeld(7, 0, null, null, 12, "div", [["class", "item-selector__sections"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "item-selector__sections-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "item-selector__section-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Files"])), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "item-selector__list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_1)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "item-selector__list-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "a", [["class", "item-selector__list-link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "item-selector__list-link-icon icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Add a File "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_8)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GoogleSheetsResourceSettingsFilesComponent_9)), i0.ɵdid(21, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.form.controls.files.controls; _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.selectedFile; _ck(_v, 19, 0, currVal_1); var currVal_2 = _co.fileUpdating; _ck(_v, 21, 0, currVal_2); }, null); }
export function View_GoogleSheetsResourceSettingsFilesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-google-sheets-resource-settings-files", [], null, null, null, View_GoogleSheetsResourceSettingsFilesComponent_0, RenderType_GoogleSheetsResourceSettingsFilesComponent)), i0.ɵdid(1, 245760, null, 0, i10.GoogleSheetsResourceSettingsFilesComponent, [i5.HttpClient, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleSheetsResourceSettingsFilesComponentNgFactory = i0.ɵccf("app-google-sheets-resource-settings-files", i10.GoogleSheetsResourceSettingsFilesComponent, View_GoogleSheetsResourceSettingsFilesComponent_Host_0, { form: "form" }, { back: "back" }, []);
export { GoogleSheetsResourceSettingsFilesComponentNgFactory as GoogleSheetsResourceSettingsFilesComponentNgFactory };

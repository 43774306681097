var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Resource } from '../../data/resource';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ResourceService = /** @class */ (function () {
    function ResourceService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ResourceService.prototype.get = function (projectName, environmentName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
            var headers = new HttpHeaders();
            var httpParams = __assign({}, params, (draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Resource().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ResourceService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "resources/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new Resource().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ResourceService.prototype.createBulk = function (projectName, environmentName, instances, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instances.map(function (item) { return item.serialize(fields); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new Resource().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ResourceService.prototype.create = function (projectName, environmentName, instance, fields) {
        return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.post(url, resource.serialize(), { headers: headers });
        //   }),
        //   map(result => new Resource().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ResourceService.prototype.update = function (projectName, environmentName, instance, fields) {
        return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `resources/${id}/`);
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.patch(url, resource.serialize(fields), { headers: headers });
        //   }),
        //   map(result => new Resource().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ResourceService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `resources/${resource.uniqueName}/`
        //     );
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers });
        //   }),
        //   map(result => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ResourceService.ngInjectableDef = i0.defineInjectable({ factory: function ResourceService_Factory() { return new ResourceService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ResourceService, providedIn: "root" });
    return ResourceService;
}());
export { ResourceService };

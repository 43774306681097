import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { FieldOutput, Input, ParameterField } from '@modules/fields';

// TODO: Refactor import
import { CustomView, CustomViewSource } from '../../../../custom-views/data/custom-view';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class CustomElementItem extends ElementItem {
  public type = ElementType.Custom;
  public width: number;
  public height: number;
  public source: CustomViewSource;
  public customView: string;
  public customViewTemporary: CustomView;
  public parameters: ParameterField[] = [];
  public inputs: Input[] = [];
  public outputs: FieldOutput[] = [];
  public actions: { name: string; action: ActionItem }[] = [];

  deserialize(data: Object): CustomElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.source = this.params['source'];
    this.customView = this.params['custom_view'];

    if (this.params['custom_view_temporary']) {
      this.customViewTemporary = new CustomView().deserialize(this.params['custom_view_temporary']);
    }

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (this.params['inputs']) {
      this.inputs = this.params['inputs'].map(item => new Input().deserialize(item));
    }

    if (this.params['outputs']) {
      this.outputs = this.params['outputs'].map(item => new FieldOutput().deserialize(item));
    }

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => ({
        name: item['name'],
        action: new ActionItem().deserialize(item['action'])
      }));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      height: this.height,
      source: this.source
    };

    if (this.customView) {
      this.params['custom_view'] = this.customView;
    } else if (this.customViewTemporary) {
      this.params['custom_view'] = this.customViewTemporary.uniqueName;
    }

    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['inputs'] = this.inputs.map(item => item.serialize());
    this.params['outputs'] = this.outputs.map(item => item.serialize());
    this.params['actions'] = this.actions.map(item => ({ name: item.name, action: item.action.serialize() }));

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  copy(element: CustomElementItem) {
    super.copy(element);

    this.customView = element.customView;
    this.customViewTemporary = element.customViewTemporary;
  }

  get typeStr(): string {
    return 'custom created component';
  }

  get analyticsName(): string {
    return 'custom';
  }

  defaultName() {
    return 'Custom';
  }
}

registerElementForType(ElementType.Custom, CustomElementItem);

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { createFormFieldFactory } from '@modules/fields';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { MessagesConverterForm } from './messages-converter.form';

@Component({
  selector: 'app-messages-converter',
  templateUrl: './messages-converter.component.html',
  providers: [MessagesConverterForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesConverterComponent implements OnInit, OnDestroy {
  submitLoading = false;
  createField = createFormFieldFactory();

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public form: MessagesConverterForm,
    public currentProjectStore: CurrentProjectStore,
    private menuService: MenuService,
    private routingService: RoutingService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.Default;
  }

  ngOnDestroy(): void {}

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        resource => {
          this.notificationService.success('Synced');

          this.submitLoading = false;
          this.cd.markForCheck();

          if (resource) {
            this.routingService.navigateApp(resource.link);
          }
        },
        error => {
          this.notificationService.error('Error');

          this.submitLoading = false;
          this.cd.markForCheck();

          console.error(error);
        }
      );
  }
}

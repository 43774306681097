import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';

import { isSet, limitObjectLength } from '@shared';

import { Item } from '../query-builder-response-keys/query-builder-response-keys.component';

export interface ResponsePath {
  name: string;
  label: string;
  chooseLabel: string;
}

export interface ResponsePathSelectEvent {
  pathName: string;
  value: string[];
}

@Component({
  selector: 'app-query-builder-response-transform',
  templateUrl: './query-builder-response-transform.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderResponseTransformComponent implements OnInit, OnChanges {
  @Input() paths: ResponsePath[] = [];
  @Input() response: any;
  @Input() pathValues: { [k: string]: string[] };
  @Input() pathEnabled: string;
  @Input() emptyLabel = 'Transform';
  @Output() selectedPath = new EventEmitter<ResponsePathSelectEvent>();
  @Output() selectedJS = new EventEmitter<void>();
  @ViewChild('response_trigger') responseTrigger: MatMenuTrigger;

  pathItems: Item[];
  currentValueDisplay: string[] = [];
  currentValueIcon: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['response']) {
      this.updatePathItems();
    }

    if (changes['pathEnabled'] || changes['pathValues']) {
      this.updateCurrentValue();
    }
  }

  updatePathItems() {
    this.pathItems = isSet(this.response) ? this.processNode(this.response) : undefined;
    this.cd.markForCheck();
  }

  updateCurrentValue() {
    if (!this.pathEnabled) {
      this.currentValueDisplay = ['JavaScript transform'];
      this.currentValueIcon = 'arrow_down_2';
    } else {
      const path = this.paths.find(item => item.name == this.pathEnabled);
      const pathValue = path ? this.pathValues[path.name] : undefined;

      if (path && pathValue && pathValue.length) {
        this.currentValueDisplay = [path.label, ...pathValue];
        this.currentValueIcon = undefined;
      } else {
        this.currentValueDisplay = [this.emptyLabel];
        this.currentValueIcon = 'arrow_down_2';
      }
    }

    this.cd.markForCheck();
  }

  processNode(node: any, path: string[] = []): Item[] {
    const response = limitObjectLength(node, 20);

    if (isArray(response)) {
      return response.map((value, i) => {
        const itemPath = [...path, i.toString()];

        return {
          label: `Element #${i + 1}`,
          response: value,
          path: itemPath,
          children: this.processNode(value, itemPath)
        };
      });
    } else if (isPlainObject(response)) {
      return toPairs(response).map(([key, value]) => {
        const itemPath = [...path, key];

        return {
          label: key,
          response: value,
          path: itemPath,
          children: this.processNode(value, itemPath)
        };
      });
    } else {
      return [];
    }
  }

  close() {
    this.responseTrigger.closeMenu();
  }
}

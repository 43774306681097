import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { getDefaultValue, ModelDbField } from '@modules/models';

@Injectable()
export class MassEditFieldsForm {
  form: FormGroup;
  field: ModelDbField;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({});
  }

  addControl(field: ModelDbField) {
    this.form.addControl(
      field.name,
      new FormControl(getDefaultValue(field), field.required ? [Validators.required] : [])
    );
  }

  removeControl(field: ModelDbField) {
    this.form.removeControl(field.name);
  }

  toggleControl(field: ModelDbField) {
    if (this.form.contains(field.name)) {
      this.removeControl(field);
    } else {
      this.addControl(field);
    }
  }
}

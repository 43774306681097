import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import round from 'lodash/round';

import { Option } from '@modules/field-components';
import { mathjs } from '@modules/fields';
import { isSet, KeyboardEventKeyCode, parseNumber, setControlDisabled, TypedChanges } from '@shared';

@Component({
  selector: 'app-compact-input',
  templateUrl: './compact-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompactInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactInputComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() optionValue: any = undefined;
  @Input() options: Option[] = [];
  @Input() optionsDisabled = false;
  @Input() prefix: string;
  @Input() postfix: string;
  @Input() placeholder = '';
  @Input() widthPx: number = null;
  @Input() number = false;
  @Input() numberOptions: { min?: number; max?: number } = {};
  @Input() numberDefault = 0;
  @Input() numberControls = true;
  @Input() segmentLeft = false;
  @Input() segment = false;
  @Input() segmentRight = false;
  @Output() keyup = new EventEmitter<KeyboardEvent>();
  @Output() input = new EventEmitter<void>();
  @Output() change = new EventEmitter<void>();
  @Output() selectOption = new EventEmitter<any>();

  @ViewChild('input_element') inputElement: ElementRef;

  valueControl = new FormControl();
  currentOption: Option;
  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];

  constructor(public sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  onChange = (value: any) => undefined;
  onTouched = () => undefined;

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CompactInputComponent>): void {
    if (changes.optionValue || changes.options) {
      this.updateCurrentOption();
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (isSet(value)) {
      const cleanValue = this.cleanValue(value);
      this.valueControl.patchValue(cleanValue);
    } else {
      this.valueControl.patchValue(null);
    }
  }

  setDisabledState(isDisabled: boolean) {
    setControlDisabled(this.valueControl, isDisabled);
    this.cd.markForCheck();
  }

  cleanValue(value: any) {
    if (!isSet(value)) {
      return null;
    }

    if (this.number) {
      let valueNumber = parseNumber(value, this.numberDefault);

      if (isSet(this.numberOptions.min)) {
        valueNumber = Math.max(valueNumber, this.numberOptions.min);
      }

      if (isSet(this.numberOptions.max)) {
        valueNumber = Math.min(valueNumber, this.numberOptions.max);
      }

      return valueNumber;
    } else {
      return value;
    }
  }

  updateValue() {
    const value = this.valueControl.value;

    if (this.number) {
      if (isSet(value)) {
        try {
          const rootNode = mathjs.parse(value);
          const code = rootNode.compile();
          const valueNumber = parseNumber(code.evaluate(), this.numberDefault);
          this.valueControl.patchValue(valueNumber);
          this.onChange(valueNumber);
        } catch (e) {
          const cleanValue = this.cleanValue(this.formControl.value);
          this.valueControl.patchValue(cleanValue);
        }
      } else {
        this.onChange(null);
      }
    } else {
      this.onChange(value);
    }
  }

  updateCurrentOption() {
    this.currentOption = this.options.find(item => item.value == this.optionValue);
    this.cd.markForCheck();
  }

  getDelta(e: MouseEvent | KeyboardEvent): number {
    if (e.shiftKey) {
      return 10;
    } else if (e.altKey) {
      return 0.1;
    } else {
      return 1;
    }
  }

  increment(e: MouseEvent | KeyboardEvent) {
    if (this.formControl.disabled) {
      return;
    }

    const delta = this.getDelta(e);
    const value = this.valueControl.value;
    const valueNumber = isSet(value) ? parseNumber(value, this.numberDefault) : this.numberDefault;
    const newValue = round(valueNumber + delta, 2);
    const cleanValue = this.cleanValue(newValue);

    this.valueControl.patchValue(cleanValue);
    this.updateValue();
    this.change.emit();

    setTimeout(() => this.inputElement.nativeElement.select(), 0);
  }

  decrement(e: MouseEvent | KeyboardEvent) {
    if (this.formControl.disabled) {
      return;
    }

    const delta = this.getDelta(e);
    const value = this.valueControl.value;
    const valueNumber = isSet(value) ? parseNumber(value, this.numberDefault) : this.numberDefault;
    const newValue = round(valueNumber - delta, 2);
    const cleanValue = this.cleanValue(newValue);

    this.valueControl.patchValue(cleanValue);
    this.updateValue();
    this.change.emit();

    setTimeout(() => this.inputElement.nativeElement.select(), 0);
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }

  onControlKey(e: KeyboardEvent) {
    if (this.formControl.disabled) {
      return;
    }

    if (this.number) {
      if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
        e.stopPropagation();
        e.preventDefault();

        this.increment(e);
      } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
        e.stopPropagation();
        e.preventDefault();

        this.decrement(e);
      } else if (e.keyCode == KeyboardEventKeyCode.Enter) {
        this.updateValue();

        setTimeout(() => this.inputElement.nativeElement.select(), 0);
      }
    }

    this.keyup.emit(e);
  }

  onControlInput() {
    if (!this.number) {
      this.updateValue();
    }

    this.input.emit();
  }

  onControlChange() {
    if (this.number) {
      this.updateValue();
    }

    this.change.emit();
  }
}

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ColumnsLayoutColumnElementItem } from './columns-layout-column';

export class ColumnsLayoutElementItem extends ElementItem {
  public type = ElementType.Columns;
  public columns: ColumnsLayoutColumnElementItem[] = [];
  public loadInvisible = false;

  deserialize(data: Object): ColumnsLayoutElementItem {
    super.deserialize(data);

    if (this.params['columns']) {
      this.columns = this.params['columns'].map(item => new ColumnsLayoutColumnElementItem().deserialize(item));
    }

    if (this.params['load_invisible'] != undefined) {
      this.loadInvisible = this.params['load_invisible'];
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['columns'] = this.columns.map(item => item.serialize());
    data['params']['load_invisible'] = this.loadInvisible;
    return data;
  }

  get analyticsName(): string {
    return 'columns';
  }

  defaultName() {
    return 'Columns';
  }

  childrenCount() {
    return this.columns.reduce((acc, item) => acc + item.children.length, 0);
  }
}

registerElementForType(ElementType.Columns, ColumnsLayoutElementItem);

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

export const confirmationDefaultTitle = 'Confirm';
export const confirmationDefaultDescription = 'Are you sure you want to proceed?';
export const confirmationDefaultSubmitLabel = 'Confirm';
export const confirmationDefaultCancelLabel = 'Cancel';

export class Confirmation {
  title: Input;
  description: Input;
  submitLabel: string;
  submitTint: string;
  submitIcon: string;
  cancelLabel: string;
  cancelTint: string;
  cancelIcon: string;

  deserialize(data: Object): Confirmation {
    this.submitLabel = data['submit_label'];
    this.submitTint = data['submit_tint'];
    this.submitIcon = data['submit_icon'];
    this.cancelLabel = data['cancel_label'];
    this.cancelTint = data['cancel_tint'];
    this.cancelIcon = data['cancel_icon'];

    if (data['title']) {
      this.title = new Input().deserialize(data['title']);
    }

    if (data['description']) {
      this.description = new Input().deserialize(data['description']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      title: this.title ? this.title.serialize() : undefined,
      description: this.description ? this.description.serialize() : undefined,
      submit_label: this.submitLabel,
      submit_tint: this.submitTint,
      submit_icon: this.submitIcon,
      cancel_label: this.cancelLabel,
      cancel_tint: this.cancelTint,
      cancel_icon: this.cancelIcon
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Type } from '@angular/core';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { isSet } from '@shared';
import { Snapshot } from '../../data/snapshot';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../centrifugo/services/centrifugo/centrifugo.service";
var SnapshotService = /** @class */ (function () {
    function SnapshotService(http, apiService, centrifugoService) {
        this.http = http;
        this.apiService = apiService;
        this.centrifugoService = centrifugoService;
    }
    SnapshotService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'snapshots');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new SnapshotService.GetResponse(Snapshot).deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SnapshotService.prototype.create = function (projectName, environmentName, name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'snapshots');
            var data = __assign({}, (isSet(name) && { name: name }));
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Snapshot().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SnapshotService.prototype.update = function (projectName, environmentName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "snapshots/" + instance.id);
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new Snapshot().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SnapshotService.prototype.apply = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "snapshots/" + instance.id + "/apply");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SnapshotService.prototype.subscribeSnapshot = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$environment_snapshot:environment_snapshot', instance.id].join('/');
            return _this.centrifugoService.subscribeState(channel).pipe(map(function (msg) { return new Snapshot().deserialize(msg.data); }));
        }));
    };
    SnapshotService.ngInjectableDef = i0.defineInjectable({ factory: function SnapshotService_Factory() { return new SnapshotService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CentrifugoService)); }, token: SnapshotService, providedIn: "root" });
    return SnapshotService;
}());
export { SnapshotService };
(function (SnapshotService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse(cls) {
            this.cls = cls;
            this.createAllowed = false;
        }
        GetResponse.prototype.deserialize = function (data) {
            var _this = this;
            this.createAllowed = data['create_allowed'];
            this.results = data['results'].map(function (item) { return new _this.cls().deserialize(item); });
            this.count = data['count'];
            this.perPage = data['per_page'];
            this.numPages = data['num_pages'];
            return this;
        };
        return GetResponse;
    }());
    SnapshotService.GetResponse = GetResponse;
})(SnapshotService || (SnapshotService = {}));

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loader-small.component";
var styles_LoaderSmallComponent = [];
var RenderType_LoaderSmallComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderSmallComponent, data: {} });
export { RenderType_LoaderSmallComponent as RenderType_LoaderSmallComponent };
export function View_LoaderSmallComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, ":svg:svg", [["class", "loader-small"], ["height", "38"], ["stroke", "currentColor"], ["viewBox", "0 0 38 38"], ["width", "38"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, ":svg:g", [["stroke-width", "2"], ["transform", "translate(1 1)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "18"], ["cy", "18"], ["r", "18"], ["stroke-opacity", ".5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, ":svg:path", [["d", "M36 18c0-9.94-8.06-18-18-18"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:animateTransform", [["attributeName", "transform"], ["dur", "1s"], ["from", "0 18 18"], ["repeatCount", "indefinite"], ["to", "360 18 18"], ["type", "rotate"]], null, null, null, null, null))], null, null); }
export function View_LoaderSmallComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [], null, null, null, View_LoaderSmallComponent_0, RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderSmallComponentNgFactory = i0.ɵccf("app-loader-small", i1.LoaderSmallComponent, View_LoaderSmallComponent_Host_0, {}, {}, []);
export { LoaderSmallComponentNgFactory as LoaderSmallComponentNgFactory };

import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { createFormFieldFactory, FieldType, getFieldDescriptionByType, ParameterValueControl } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, KeyboardEventKeyCode, TypedChanges } from '@shared';

@Component({
  selector: 'app-parameter-values-item',
  templateUrl: './parameter-values-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterValuesItemComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: ParameterValueControl;
  @Input() openedInitial = false;
  @Input() collapseContext: SidebarCollapseContext;
  @Output() deleteRequested = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild('field_type_overlay', { read: CdkConnectedOverlay }) fieldTypeOverlay: CdkConnectedOverlay;

  createField = createFormFieldFactory();
  opened = false;
  rename = false;
  renameControl = new FormControl('');
  popoverOpened = false;
  hovered$ = new BehaviorSubject<boolean>(false);
  initCollapseContextSubscription: Subscription;
  fieldIcon$: Observable<string>;
  fieldTypeDropdownOpened = false;
  fieldTypeDropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.fieldIcon$ = controlValue<FieldType>(this.control.controls.field).pipe(
      map(value => getFieldDescriptionByType(value).icon)
    );
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ParameterValuesItemComponent>): void {
    if (changes.collapseContext) {
      this.initCollapseContext();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  initCollapseContext() {
    if (this.initCollapseContextSubscription) {
      this.initCollapseContextSubscription.unsubscribe();
      this.initCollapseContextSubscription = undefined;
    }

    if (this.collapseContext) {
      this.initCollapseContextSubscription = this.collapseContext.opened$
        .pipe(untilDestroyed(this))
        .subscribe(opened => {
          this.opened = opened === this;
          this.cd.markForCheck();
        });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  getParams(): Object {
    return {
      ...this.control.controls.params.value,
      classes: ['select_fill', 'input_fill']
    };
  }

  setOpened(opened: boolean) {
    if (this.collapseContext) {
      if (opened && this.collapseContext.opened !== this) {
        this.collapseContext.opened = this;
      } else if (!opened && this.collapseContext.opened === this) {
        this.collapseContext.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  setRename(rename: boolean, save = true) {
    this.rename = rename;
    this.cd.markForCheck();

    if (this.rename) {
      this.renameControl.patchValue(this.control.controls.name.value);
    } else if (!rename && save) {
      this.control.controls.name.patchValue(this.renameControl.value);
      // this.itemForm.controls.path.patchValue([this.renameControl.value]);
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.setRename(false);
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.setRename(false, false);
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  setPopoverOpened(opened: boolean) {
    this.popoverOpened = opened;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  setFieldTypeDropdownOpened(value: boolean) {
    this.fieldTypeDropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.fieldTypeOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.fieldTypeOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.fieldTypeDropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.fieldTypeDropdownPositions.filter(item => item !== position);

        if (position) {
          this.fieldTypeOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.fieldTypeOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

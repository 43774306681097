import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CustomViewTemplate } from '@modules/views';

@Component({
  selector: 'app-custom-view-templates-card',
  templateUrl: './custom-view-templates-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesCardComponent implements OnInit {
  @Input() item: CustomViewTemplate;
  @Input() interactive = false;

  constructor() {}

  ngOnInit() {}
}

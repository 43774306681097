import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';

import { DragDrop2Module } from '@common/drag-drop2';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ImportDataItemComponent } from './components/import-data-item/import-data-item.component';
import { ImportDataComponent } from './components/import-data/import-data.component';
import { ImportModelsComponent } from './components/import-models/import-models.component';

@NgModule({
  imports: [
    CommonModule,
    FieldComponentsModule,
    SharedModule,
    UiModule,
    FieldsModule,
    UniqueIdModule,
    ReactiveFormsModule,
    TipsModule,
    SelectModule,
    OverlayModule,
    ObserversModule,
    MatMenuModule,
    DragDropModule,
    DragDrop2Module
  ],
  declarations: [ImportModelsComponent, ImportDataComponent, ImportDataItemComponent],
  exports: [ImportModelsComponent],
  entryComponents: [ImportModelsComponent]
})
export class ImportModule {}

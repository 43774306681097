import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';
import { Storage } from '@modules/storages';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface SupabaseStorageParamsOptions {
  buckets: string[];
  url: string;
  key: string;
}

@Injectable()
export class SupabaseStorageGeneratorService extends ResourceGeneratorService<SupabaseStorageParamsOptions> {
  constructor(@Inject(ROUTE_ADMIN_MODE) private mode: AdminMode, private secretTokenService: SecretTokenService) {
    super();
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<SupabaseStorageParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        catchError(error => {
          if (error instanceof ServerRequestError && error.status == 404) {
            return of(undefined);
          } else {
            return throwError(error);
          }
        }),
        map(result => {
          return {
            buckets: resource.params['buckets'] || [],
            url: resource.params['url'],
            key: result.value
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: SupabaseStorageParamsOptions
  ): Observable<ResourceParamsResult> {
    const storages = options.buckets.map(bucket => {
      const storage = new Storage();

      storage.uniqueName = bucket;
      storage.name = bucket;
      storage.params = { bucket: bucket };

      storage.uploadQuery = new StorageQuery();
      storage.uploadQuery.queryType = QueryType.Simple;
      storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

      storage.getQuery = new StorageQuery();
      storage.getQuery.queryType = QueryType.Simple;
      storage.getQuery.simpleQuery = new storage.getQuery.simpleQueryClass();

      storage.removeQuery = new StorageQuery();
      storage.removeQuery.queryType = QueryType.Simple;
      storage.removeQuery.simpleQuery = new storage.removeQuery.simpleQueryClass();

      storage.createDirectoryQuery = new StorageQuery();
      storage.createDirectoryQuery.queryType = QueryType.Simple;
      storage.createDirectoryQuery.simpleQuery = new storage.createDirectoryQuery.simpleQueryClass();

      storage.getObjectUrlQuery = new StorageQuery();
      storage.getObjectUrlQuery.queryType = QueryType.Simple;
      storage.getObjectUrlQuery.simpleQuery = new storage.getObjectUrlQuery.simpleQueryClass();

      return storage;
    });

    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = options.key;

    return of({
      resourceParams: {
        buckets: options.buckets,
        url: options.url
      },
      storages: storages.map(item => item.serialize()),
      secretTokens: [token.serialize()]
    });
  }
}

import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var InputOverlayService = /** @class */ (function () {
    function InputOverlayService() {
        this._active$ = new BehaviorSubject(undefined);
    }
    InputOverlayService.prototype.setActive = function (instance) {
        if (this._active$.value !== instance) {
            this._active$.next(instance);
        }
    };
    InputOverlayService.prototype.unsetActive = function (instance) {
        if (this._active$.value === instance) {
            this._active$.next(undefined);
        }
    };
    InputOverlayService.prototype.getActive$ = function () {
        return this._active$.asObservable();
    };
    InputOverlayService.ngInjectableDef = i0.defineInjectable({ factory: function InputOverlayService_Factory() { return new InputOverlayService(); }, token: InputOverlayService, providedIn: "root" });
    return InputOverlayService;
}());
export { InputOverlayService };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-background-tab',
  templateUrl: './background-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundTabComponent implements OnInit {
  @Input() name: string;
  @Input() link: any[];
  @Input() label: string;
  @Input() active = false;
  @Output() tabClick = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}
}

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { DocumentService } from '@core';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import {
  CustomizeService,
  getModelAttributesByColumns,
  getModelBulkAttributesByColumns,
  ListDefaultSelection,
  ListGroup,
  rawListViewSettingsColumnsToViewContextOutputs,
  TableSettings,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import {
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  LOADING_VALUE,
  NOT_SET_VALUE
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  CHECKED_ITEMS_OUTPUT,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { isSet, KeyboardEventKeyCode } from '@shared';

import { TableGroupComponent } from '../table-group/table-group.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { TableItemComponent } from '../table-item/table-item.component';
import {
  getListStateColumns,
  getListStateDefaultSelection,
  getListStateFetch,
  getListStateFetchNewParams,
  TableState
} from './table-state';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent extends ListLayoutComponent<TableSettings, TableState>
  implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @ViewChildren(TableGroupComponent) groupComponents = new QueryList<TableGroupComponent>();
  @ViewChild('table_element') tableElement: ElementRef;
  @ViewChild('scrollable_element') scrollableElement: ElementRef;
  @ViewChild('fixed_table') fixedTable: ElementRef;
  @ViewChild('table_header_fixed') tableHeaderFixed: TableHeaderComponent;
  @ViewChild('table_header') tableHeader: TableHeaderComponent;

  layout = ListLayoutType.Table;
  visibleColumns: DisplayField[] = [];
  selectedItem: ListItem;
  checkedItems: { [k: string]: Model } = {};
  checkedInverse = false;
  totalItems = 0;
  totalVisibleItems = 0;
  customizing = false;
  loading = true;
  configured = true;

  constructor(
    private modelService: ModelService,
    private actionControllerService: ActionControllerService,
    public customizeService: CustomizeService,
    private documentService: DocumentService,
    private notificationService: NotificationService,
    private zone: NgZone,
    injector: Injector,
    cd: ChangeDetectorRef,
    @Optional() customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initResizes();
    this.initContext();
    this.initHotkeys();
  }

  ngOnDestroy(): void {}

  ngAfterViewChecked(): void {
    this.updateTableSizes();
  }

  getListState(
    settings: TableSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<TableState> {
    const page = parseInt(params[PAGE_PARAM], 10) || 1;

    params = cloneDeep(params);

    delete params[NEXT_PAGE_SCROLL_PARAM];

    if (!sort.length && isSet(settings.sortingField)) {
      sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
    }

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(
      map(([staticData, inputParams, modelDescription]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings ? settings.dataSource : undefined,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          groupCollapse: settings.groupCollapse,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          page: page,
          perPage: settings ? settings.perPage : undefined,
          sortingField: settings ? settings.sortingField : undefined,
          sortingAsc: settings ? settings.sortingAsc : undefined,
          groupField: settings ? settings.groupField : undefined,
          groups: settings ? settings.groups : undefined,
          defaultSelection: settings ? settings.defaultSelection : undefined
        };
      })
    );
  }

  onStateUpdated(state: TableState) {
    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateContextOutputs(state);
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));

      if (newParams) {
        this.setSelectedItem(undefined);
        this.groupComponents.forEach(item => item.page$.next(1));
      }

      this.fetch(state);
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
        this.cd.detectChanges();
        this.updateTableSizes();
      }

      if (
        !isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
        state.defaultSelection == ListDefaultSelection.First
      ) {
        const component = this.groupComponents.toArray()[0];
        const componentItems = component ? component.getItems() : undefined;
        this.setSelectedItem(componentItems ? componentItems[0] : undefined);
      }
    }
  }

  updateLoading() {
    this.loading = this.groupComponents.toArray().some(item => item.loading);
    this.cd.markForCheck();
  }

  getStateQueryOptions(state: TableState): GetQueryOptions {
    const component = this.groupComponents.toArray()[0];
    return component ? component.getBaseStateQueryOptions() : {};
  }

  fetch(state: TableState) {
    this.configured = state.dataSource && state.dataSource.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.selectedItem = undefined;
    this.checkedItems = {};
    this.checkedInverse = false;
    this.updateCheckedContext();
    this.cd.markForCheck();
  }

  onFetch() {
    this.loading = true;
    this.setChecked({});
    this.cd.markForCheck();

    this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
  }

  onFetched(items: ListItem[]) {
    if (this.selectedItem && items) {
      this.checkSelectedModelIsActual(items);
    }

    this.updateLoading();

    if (!this.loading) {
      const isEmpty = this.groupComponents.toArray().every(item => {
        const groupItems = item.getItems();
        return groupItems && !groupItems.length;
      });
      this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
    }

    this.updateTotalItems();
  }

  reloadData() {
    super.reloadData();
    this.groupComponents.forEach(item => item.reloadData());
  }

  initResizes() {
    this.customizeService.layoutEnabled$
      .pipe(
        switchMap(enabled => {
          this.customizing = enabled;
          return this.zone.onStable;
        }),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(() => this.updateTableSizes());

    this.documentService.viewportSizeChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateTableSizes();
    });
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Row',
        icon: 'deselect',
        parameters: [],
        handler: () => {
          this.setSelectedItem(undefined);
          this.setChecked({});
        }
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetFilters()
      }
    ]);
  }

  initHotkeys() {
    fromEvent<KeyboardEvent>(document, 'keydown', { capture: true })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Escape && this.isAnyChecked) {
          this.uncheckAll();
          e.stopPropagation();
        }
      });
  }

  updateContextOutputs(state: TableState) {
    const columns = state.dataSource ? state.dataSource.columns : [];

    this.contextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Row',
        icon: 'fileds',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: rawListViewSettingsColumnsToViewContextOutputs(
          columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Row',
        icon: 'hand',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Row selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Row selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: CHECKED_ITEMS_OUTPUT,
        name: 'Checked Rows',
        icon: 'check',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      }
    ]);
  }

  updateSelectedContext() {
    if (this.selectedItem) {
      const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
      this.contextElement.setOutputValue(
        SELECTED_ITEM_OUTPUT,
        getModelAttributesByColumns(this.selectedItem.model, columns)
      );
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }
  }

  updateCheckedContext() {
    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const models: Model[] = values(this.checkedItems);
    this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
    // Backward compatibility
    this.contextElement.setOutputValue('selected_items', getModelBulkAttributesByColumns(models, columns));
  }

  // onDragChanged(dragging) {
  //   const items = this.draggable.items.map(item => item.data);
  //
  //   if (dragging) {
  //     this.prevItems = items;
  //   } else {
  //     this.items = items;
  //
  //     const orderingField = this.params.modelDescription.orderingField;
  //     const data = detectMoveUpdates<ListItem>(this.items, this.prevItems);
  //
  //     if (!data) {
  //       return;
  //     }
  //
  //     this.modelService.reorder(
  //       this.params.modelDescription.modelId,
  //       data.forward,
  //       data.segmentFrom.pk,
  //       data.segmentTo.pk,
  //       data.item.pk
  //     ).subscribe(() => {
  //       const newOrder = data.forward ? data.segmentTo[orderingField] : data.segmentFrom[orderingField];
  //       this.items
  //         .filter(item => item[orderingField] >= data.segmentFrom[orderingField] && item[orderingField] <= data.segmentTo[orderingField])
  //         .forEach(item => {
  //           if (data.forward) {
  //             item[orderingField] -= 1;
  //           } else {
  //             item[orderingField] += 1;
  //           }
  //         });
  //       data.item[orderingField] = newOrder;
  //       this.cd.markForCheck();
  //     });
  //   }
  // }

  updateTableSizes() {
    if (!this.scrollable) {
      return;
    }

    this.updateFixedTableSize();
  }

  updateFixedTableSize() {
    if (!this.tableHeaderFixed || !this.tableHeader) {
      return;
    }

    this.tableHeaderFixed.copySize(this.tableHeader);
  }

  onHorizontalScroll() {
    this.updateFixedTableScroll();
  }

  // onVerticalScroll() {
  //   if (!this.scrollable) {
  //     return;
  //   }
  //
  //   const scrollTop = getWindowScrollTop();
  //   const scrolled = scrollTop > 0;
  //
  //   if (scrolled != this.scrolled) {
  //     this.scrolled = scrolled;
  //     this.cd.markForCheck();
  //   }
  //
  //   if (this.listStore.items == undefined) {
  //     return;
  //   }
  //
  //   const viewportHeight = window.innerHeight;
  //   const contentHeight = document.body.offsetHeight;
  //   const viewportBottom = scrollTop + viewportHeight;
  //   // const firstIndexInvisible = this.rowPositions.findIndex(item => item.top > viewportBottom);
  //   // let lastIndexVisible;
  //   // if (firstIndexInvisible == -1) {
  //   //   lastIndexVisible = this.rowPositions.length - 1;
  //   // } else if (firstIndexInvisible == 0) {
  //   //   lastIndexVisible = 0;
  //   // } else {
  //   //   lastIndexVisible = firstIndexInvisible - 1;
  //   // }
  //   // const lastPageVisible = this.listStore.fromPage + Math.floor(lastIndexVisible / this.listStore.perPage);
  //
  //   if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
  //     this.onScrollFinished();
  //   }
  // }

  trackGroupFn(i, item: ListGroup) {
    return item.uid;
  }

  get columnsCount(): number {
    if (this.visibleColumns.length) {
      return this.visibleColumns.length;
    } else {
      return this.scrollable ? 8 : 5;
    }
  }

  updateFixedTableScroll() {
    if (!this.fixedTable || !this.scrollableElement) {
      return;
    }

    const scrollLeft = this.scrollableElement.nativeElement.scrollLeft;

    this.fixedTable.nativeElement['scrollLeft'] = scrollLeft;
  }

  updateVisibleColumns(state: TableState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  isItemSelected(item: ListItem, index: number) {
    return this.itemEquals(this.selectedItem, item);
  }

  isItemChecked(item: ListItem, group: number, index: number) {
    const pk = item.model.primaryKey || `${group}_${index}`;

    return (!this.checkedInverse && this.checkedItems[pk]) || (this.checkedInverse && !this.checkedItems[pk]);
  }

  get isAnyChecked() {
    return (
      (!this.checkedInverse && keys(this.checkedItems).length) ||
      (this.checkedInverse && keys(this.checkedItems).length < this.totalVisibleItems)
    );
  }

  getTotalItems(): number {
    return this.groupComponents.reduce((acc, item) => acc + (item.getCount() || 0), 0);
  }

  getTotalVisibleItems(): number {
    return this.groupComponents.reduce((acc, component) => {
      const items = component.getItems();
      return acc + (items ? items.length : 0);
    }, 0);
  }

  updateTotalItems() {
    this.totalItems = this.getTotalItems();
    this.totalVisibleItems = this.getTotalVisibleItems();
    this.cd.markForCheck();
  }

  setSelectedItem(item: ListItem) {
    this.selectedItem = item;
    this.cd.markForCheck();
    this.updateSelectedContext();
  }

  toggleSelectedItem(item: ListItem, element: HTMLElement, click = false) {
    if (this.selectedItem === item) {
      this.setSelectedItem(undefined);
    } else {
      this.setSelectedItem(item);
    }
    this.checkedItems = {};
    this.checkedInverse = false;
    this.updateCheckedContext();
    this.cd.markForCheck();

    if (click && this.settings.rowClickAction && this.selectedItem) {
      this.actionControllerService
        .execute(this.settings.rowClickAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.selectedItem.model.getAttributes()
          },
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }

  setChecked(value: { [k: string]: Model }, checkedInverse = false) {
    this.checkedItems = value;
    this.checkedInverse = checkedInverse;
    this.cd.markForCheck();
    this.updateCheckedContext();
  }

  toggleChecked(row: TableItemComponent, group: number, index: number) {
    const pk = row.row.model.primaryKey || `${group}_${index}`;
    const checked = this.isItemChecked(row.row, group, index);

    if ((!this.checkedInverse && !checked) || (this.checkedInverse && checked)) {
      const checkedItems = clone(this.checkedItems);
      checkedItems[pk] = row.row.model;
      this.checkedItems = checkedItems;
    } else {
      const checkedItems = clone(this.checkedItems);
      delete checkedItems[pk];
      this.checkedItems = checkedItems;
    }

    this.setSelectedItem(undefined);
    this.updateCheckedContext();
  }

  checkAll() {
    const checkedItems = this.groupComponents.reduce((acc, item) => {
      return { ...acc, ...item.getCheckedAll() };
    }, {});
    this.setSelectedItem(undefined);
    this.setChecked(checkedItems);
  }

  uncheckAll() {
    this.setSelectedItem(undefined);
    this.setChecked({});
  }

  updateColumnValue(currentItem: ListItem, options: { name: string; value: any }) {
    const newItem: ListItem = {
      columns: currentItem.columns.map(item => {
        if (item.column === options.name) {
          return {
            ...item,
            str: undefined
          };
        } else {
          return item;
        }
      }),
      model: currentItem.model.clone()
    };
    const columnFiltered = this.listState.filters.some(item => item.field[0] == options.name);
    const columnSorted = this.listState.sort.some(item => item.field == options.name);

    let value = options.value;
    const field = this.listState.modelDescription.dbField(options.name);

    if (field && field.fieldDescription.serializeValue != undefined && value != undefined) {
      value = field.fieldDescription.serializeValue(value, field);
    }

    newItem.model.setRawAttribute(options.name, value);
    newItem.model.setAttribute(options.name, options.value);

    const prevRows = this.groupComponents.map(item => {
      const prev = item.getItems();
      item.updateRow(newItem);
      return prev;
    });

    this.modelService
      .update(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        this.listState.modelDescription.modelId,
        newItem.model,
        [options.name]
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          if (columnFiltered || columnSorted) {
            this.reloadData();
          }
        },
        () => {
          this.groupComponents.forEach((item, i) => {
            item.setItems(prevRows[i]);
          });
        }
      );
  }

  onModelUpdated(model: Model) {
    if (this.selectedItem && this.selectedItem.model.isSame(model)) {
      this.updateSelectedContext();
    }

    const checkedModels: Model[] = values(this.checkedItems);

    if (checkedModels.some(item => item.isSame(model))) {
      this.updateCheckedContext();
    }
  }

  onColumnResize(index: number, width: number) {
    this.groupComponents.forEach(item => item.setColumnWidth(index, width));
  }

  onColumnResizeFinished(index: number, width: number) {
    this.groupComponents.forEach(item => item.setColumnWidth(index, width, true));
  }

  public getAnyModel(): Model {
    for (const component of this.groupComponents.toArray()) {
      const items = component.getItems();
      if (items && items.length) {
        return items[0].model;
      }
    }
  }

  checkSelectedModelIsActual(items: ListItem[]) {
    const actualSelectedItem = items.find(item => item.model.isSame(this.selectedItem.model));

    if (
      actualSelectedItem &&
      !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())
    ) {
      this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
      this.cd.markForCheck();
      this.onModelUpdated(this.selectedItem.model);
    }
  }
}

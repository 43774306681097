var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ProjectUserListStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { isSet, KeyboardEventKeyCode } from '@shared';
var CustomizePreviewUserDropdownComponent = /** @class */ (function () {
    function CustomizePreviewUserDropdownComponent(currentUserStore, projectUserListStore, cd) {
        this.currentUserStore = currentUserStore;
        this.projectUserListStore = projectUserListStore;
        this.cd = cd;
        this.selectUser = new EventEmitter();
        this.resetUser = new EventEmitter();
        this.loading = true;
        this.searchControl = new FormControl('');
        this.update = new Subject();
    }
    CustomizePreviewUserDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUserStore.original$.pipe(untilDestroyed(this)).subscribe(function (originalUser) {
            _this.originalUser = originalUser;
            _this.cd.markForCheck();
        });
        merge(of(this.searchControl.value), this.searchControl.valueChanges.pipe(debounceTime(200)), this.update)
            .pipe(switchMap(function (search) {
            _this.projectUserListStore.params = __assign({ not_user_uid: _this.currentUserStore.original.uid }, (isSet(search) && { search: search }));
            _this.projectUserListStore.reset();
            _this.loading = true;
            _this.cd.markForCheck();
            return _this.projectUserListStore.getNext();
        }), untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizePreviewUserDropdownComponent.prototype.ngOnDestroy = function () { };
    CustomizePreviewUserDropdownComponent.prototype.resetSearch = function () {
        var value = '';
        this.searchControl.patchValue(value, { emitEvent: false });
        this.update.next(value);
    };
    CustomizePreviewUserDropdownComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.resetSearch();
        }
    };
    return CustomizePreviewUserDropdownComponent;
}());
export { CustomizePreviewUserDropdownComponent };

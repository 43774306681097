import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import { FlexLayout, FlexLayoutAlign, FlexLayoutOrientation } from '@modules/views';
import { controlValue, isSet } from '@shared';

export enum FlexLayoutOrientationOption {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  HorizontalWrap = 'horizontal_wrap',
  VerticalWrap = 'vertical_wrap'
}

export function deserializeFlexLayoutOrientationOption(
  orientation: FlexLayoutOrientation,
  wrap: boolean
): FlexLayoutOrientationOption {
  if (orientation == FlexLayoutOrientation.Horizontal && !wrap) {
    return FlexLayoutOrientationOption.Horizontal;
  } else if (orientation == FlexLayoutOrientation.Horizontal && wrap) {
    return FlexLayoutOrientationOption.HorizontalWrap;
  } else if (orientation == FlexLayoutOrientation.Vertical && !wrap) {
    return FlexLayoutOrientationOption.Vertical;
  } else if (orientation == FlexLayoutOrientation.Vertical && wrap) {
    return FlexLayoutOrientationOption.VerticalWrap;
  } else {
    return FlexLayoutOrientationOption.Horizontal;
  }
}

export function serializeFlexLayoutOrientationOption(
  option: FlexLayoutOrientationOption
): { orientation: FlexLayoutOrientation; wrap: boolean } {
  if (option == FlexLayoutOrientationOption.Horizontal) {
    return { orientation: FlexLayoutOrientation.Horizontal, wrap: false };
  } else if (option == FlexLayoutOrientationOption.HorizontalWrap) {
    return { orientation: FlexLayoutOrientation.Horizontal, wrap: true };
  } else if (option == FlexLayoutOrientationOption.Vertical) {
    return { orientation: FlexLayoutOrientation.Vertical, wrap: false };
  } else if (option == FlexLayoutOrientationOption.VerticalWrap) {
    return { orientation: FlexLayoutOrientation.Vertical, wrap: true };
  } else {
    return { orientation: FlexLayoutOrientation.Horizontal, wrap: false };
  }
}

export interface FlexLayoutPaddingControlValue {
  padding_top: number;
  padding_right: number;
  padding_bottom: number;
  padding_left: number;
}

export class FlexLayoutPaddingControl extends FormGroup {
  instance: FlexLayout;

  controls: {
    padding_top: FormControl;
    padding_right: FormControl;
    padding_bottom: FormControl;
    padding_left: FormControl;
  };

  constructor(state: Partial<FlexLayout> = {}) {
    super({
      padding_top: new FormControl(isSet(state.paddingTop) ? state.paddingTop : 0),
      padding_right: new FormControl(isSet(state.paddingRight) ? state.paddingRight : 0),
      padding_bottom: new FormControl(isSet(state.paddingBottom) ? state.paddingBottom : 0),
      padding_left: new FormControl(isSet(state.paddingLeft) ? state.paddingLeft : 0)
    });
  }

  deserialize(value: FlexLayout, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.padding_top.patchValue(value.paddingTop, { emitEvent: options.emitEvent });
    this.controls.padding_right.patchValue(value.paddingRight, { emitEvent: options.emitEvent });
    this.controls.padding_bottom.patchValue(value.paddingBottom, { emitEvent: options.emitEvent });
    this.controls.padding_left.patchValue(value.paddingLeft, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: FlexLayout): FlexLayout {
    if (!instance) {
      instance = new FlexLayout();
    }

    instance.paddingTop = this.controls.padding_top.value;
    instance.paddingRight = this.controls.padding_right.value;
    instance.paddingBottom = this.controls.padding_bottom.value;
    instance.paddingLeft = this.controls.padding_left.value;

    return instance;
  }

  serialize(): FlexLayout {
    return this.getInstance(this.instance);
  }

  onDisabledChange = (value: boolean) => undefined;

  registerOnChange(fn: Function): void {
    this.valueChanges.subscribe(value => fn(value));
  }

  registerOnDisabledChange(fn: (disabled: boolean) => void): void {
    this.onDisabledChange = fn;
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    this.onDisabledChange(false);
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    this.onDisabledChange(true);
  }
}

export class FlexLayoutControl extends FormGroup {
  instance: FlexLayout;

  controls: {
    orientation_option: FormControl;
    align_horizontal: FormControl;
    align_vertical: FormControl;
    gap_horizontal: FormControl;
    gap_vertical: FormControl;
    padding: FlexLayoutPaddingControl;
  };

  orientationOptions: Option<FlexLayoutOrientationOption>[] = [
    {
      value: FlexLayoutOrientationOption.Horizontal,
      name: '',
      subtitle: 'Horizontal layout',
      icon: 'arrow_forward'
    },
    {
      value: FlexLayoutOrientationOption.Vertical,
      name: '',
      subtitle: 'Vertical layout',
      icon: 'arrow_down'
    },
    {
      value: FlexLayoutOrientationOption.HorizontalWrap,
      name: '',
      subtitle: 'Horizontal wrap layout',
      icon: 'arrow_backward_turn'
    },
    {
      value: FlexLayoutOrientationOption.VerticalWrap,
      name: '',
      subtitle: 'Vertical wrap layout',
      icon: 'arrow_up_turn'
    }
  ];

  constructor(state: Partial<FlexLayout> = {}) {
    super({
      orientation_option: new FormControl(
        isSet(state.orientation)
          ? deserializeFlexLayoutOrientationOption(state.orientation, state.wrap)
          : FlexLayoutOrientationOption.Horizontal
      ),
      wrap: new FormControl(isSet(state.wrap) ? state.wrap : false),
      align_horizontal: new FormControl(isSet(state.alignHorizontal) ? state.alignHorizontal : FlexLayoutAlign.Start),
      align_vertical: new FormControl(isSet(state.alignVertical) ? state.alignVertical : FlexLayoutAlign.Start),
      gap_horizontal: new FormControl(isSet(state.gapHorizontal) ? state.gapHorizontal : 0),
      gap_vertical: new FormControl(isSet(state.gapVertical) ? state.gapVertical : 0),
      padding: new FlexLayoutPaddingControl(state)
    });
  }

  deserialize(value: FlexLayout, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.orientation_option.patchValue(deserializeFlexLayoutOrientationOption(value.orientation, value.wrap), {
      emitEvent: options.emitEvent
    });
    this.controls.align_horizontal.patchValue(value.alignHorizontal, { emitEvent: options.emitEvent });
    this.controls.align_vertical.patchValue(value.alignVertical, { emitEvent: options.emitEvent });
    this.controls.gap_horizontal.patchValue(value.gapHorizontal, { emitEvent: options.emitEvent });
    this.controls.gap_vertical.patchValue(value.gapVertical, { emitEvent: options.emitEvent });
    this.controls.padding.deserialize(value, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: FlexLayout): FlexLayout {
    if (!instance) {
      instance = new FlexLayout();
    }

    const { orientation, wrap } = serializeFlexLayoutOrientationOption(this.controls.orientation_option.value);

    instance.orientation = orientation;
    instance.wrap = wrap;
    instance.alignHorizontal = this.controls.align_horizontal.value;
    instance.alignVertical = this.controls.align_vertical.value;
    instance.gapHorizontal = this.controls.gap_horizontal.value;
    instance.gapVertical = this.controls.gap_vertical.value;
    instance.paddingTop = this.controls.padding.controls.padding_top.value;
    instance.paddingRight = this.controls.padding.controls.padding_right.value;
    instance.paddingBottom = this.controls.padding.controls.padding_bottom.value;
    instance.paddingLeft = this.controls.padding.controls.padding_left.value;

    return instance;
  }

  serialize(): FlexLayout {
    return this.getInstance(this.instance);
  }

  isHorizontalOrientation$(): Observable<boolean> {
    return controlValue(this.controls.orientation_option.value).pipe(
      map(value => {
        const { orientation } = serializeFlexLayoutOrientationOption(value);
        return orientation == FlexLayoutOrientation.Horizontal;
      })
    );
  }

  isVerticalOrientation$(): Observable<boolean> {
    return controlValue(this.controls.orientation_option).pipe(
      map(value => {
        const { orientation } = serializeFlexLayoutOrientationOption(value);
        return orientation == FlexLayoutOrientation.Vertical;
      })
    );
  }

  isWrapOrientation$(): Observable<boolean> {
    return controlValue(this.controls.orientation_option).pipe(
      map(value => {
        const { wrap } = serializeFlexLayoutOrientationOption(value);
        return wrap;
      })
    );
  }
}

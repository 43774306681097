import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { FieldLookup } from '@modules/field-lookups';
import { FieldDescriptionLookup } from '@modules/fields';
import { SEARCH_PARAM } from '@modules/models';
import { JET_APP_RESOURCE } from '@modules/projects';
import { indent, indentLines } from '@shared';

import { ApiParameter } from '../../../data/api-parameter';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

@Component({
  selector: 'app-model-api-group-query',
  templateUrl: './model-api-group-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiGroupQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  parameters: ApiParameter[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl(),
      params: {
        _y_func: ':_y_func',
        _y_column: ':_y_column',
        _x_column: ':_x_column',
        _x_lookup: ':_x_lookup'
      }
    });
  }

  getParametersAuto(): ApiParameter[] {
    const aggregations = [
      AggregateFunc.Count,
      AggregateFunc.Sum,
      AggregateFunc.Min,
      AggregateFunc.Max,
      AggregateFunc.Avg
    ];
    const fieldAggregations = aggregations.filter(item => item != AggregateFunc.Count);
    const lookups = [
      DatasetGroupLookup.DateDay,
      DatasetGroupLookup.DateWeek,
      DatasetGroupLookup.DateMonth,
      DatasetGroupLookup.DateQuarter,
      DatasetGroupLookup.DateYear,
      DatasetGroupLookup.DateHour,
      DatasetGroupLookup.DateMinute,
      DatasetGroupLookup.DateSecond
    ];

    return [
      {
        type: ApiParameterType.Query,
        name: '_y_func',
        fieldTypeLabel: 'string',
        description: `Type of aggregation to apply, should be one of: ${aggregations.join(', ')}`,
        required: true
      },
      {
        type: ApiParameterType.Query,
        name: '_y_column',
        fieldTypeLabel: 'string',
        description: `Field to use for aggregation. Required for the following _y_func: ${fieldAggregations.join(', ')}`
      },
      {
        type: ApiParameterType.Query,
        name: '_x_column',
        fieldTypeLabel: 'string',
        description: `Field to use for grouping`,
        required: true
      },
      {
        type: ApiParameterType.Query,
        name: '_x_lookup',
        fieldTypeLabel: 'string',
        description: `Transformation to apply for grouping. Can be one of the following: ${lookups.join(', ')}`
      },
      ...this.modelDescription.dbFields.map(field => {
        const mapLookup = (lookupInfo: FieldDescriptionLookup): ApiParameter => {
          const lookup = lookupInfo.type as FieldLookup;

          return {
            type: ApiParameterType.Query,
            name: this.getFieldParamName(field.name, lookup.lookup),
            fieldTypeLabel: lookupInfo.field,
            fieldTypeDescription: {
              field: field.field,
              params: field.params
            },
            description: `Return results where the ${field.verboseName} field ${lookup.verboseName} this value.`
          };
        };

        const main = field.fieldDescription.lookups
          .filter(lookupInfo => !(lookupInfo.type as FieldLookup).lookup)
          .map(lookupInfo => mapLookup(lookupInfo))[0];
        const children = field.fieldDescription.lookups
          .filter(lookupInfo => (lookupInfo.type as FieldLookup).lookup)
          .map(lookupInfo => mapLookup(lookupInfo));

        const first = main ? main : children[0];
        const others = main ? children : children.slice(1);

        return {
          ...first,
          ...(others.length ? { children: others } : {})
        };
      }),
      {
        type: ApiParameterType.Query,
        name: SEARCH_PARAM,
        fieldTypeLabel: 'string',
        description: `Search results TEXT fields with the specified value. Search is case insensitive.`
      }
    ];
  }

  getQueryUrl() {
    return `/aggregate`;
  }

  getResponseData(): Object {
    const modelData1 = {
      group: '2020-08-01T00:00:00',
      y_func: 218
    };
    const modelData2 = {
      group: '2020-08-02T00:00:00',
      y_func: 43
    };

    return [
      '[',
      indentLines(JSON.stringify(modelData1, null, this.indentSize), this.indentSize) + ',',
      indentLines(JSON.stringify(modelData2, null, this.indentSize), this.indentSize) + ',',
      indent(`{...},`, this.indentSize),
      indent(`{...}`, this.indentSize),
      ']'
    ].join('\n');
  }
}

import { ActivatedRouteSnapshot, RouteReuseStrategy } from '@angular/router';

export class ComponentRouteReuseStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): {} {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future && curr && future.component && future.component == curr.component) {
      return true;
    }

    return future && curr && future.routeConfig === curr.routeConfig;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { serializeDataSourceColumns } from '@modules/list-components';
import { PAGE_PARAM } from '@modules/models';
export function getListStateFetch(state) {
    return {
        dataSource: state.dataSource
            ? __assign({}, state.dataSource.serialize(), { columns: serializeDataSourceColumns(state.dataSource.columns) }) : undefined,
        dataSourceStaticData: state.dataSourceStaticData,
        dataSourceParams: state.dataSourceParams,
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        sort: state.sort,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet,
        perPage: state.perPage,
        sortingField: state.sortingField,
        sortingAsc: state.sortingAsc
    };
}
export function getListStateFetchNewParams(state) {
    return {
        params: pickBy(state.dataSourceParams, function (v, k) { return PAGE_PARAM != k; }),
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        sort: state.sort
    };
}
export function getListStateColumns(state) {
    return {
        columns: state.dataSource ? state.dataSource.columns : undefined
    };
}
export function getListStateSelection(state) {
    return {
        multipleSelection: state.multipleSelection
    };
}
export function getListStateDefaultSelection(state) {
    return {
        defaultSelection: state.defaultSelection
    };
}

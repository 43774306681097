var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSet } from '@shared';
import { ActionStore } from './action.store';
var CustomActionSelectSource = /** @class */ (function (_super) {
    __extends(CustomActionSelectSource, _super);
    function CustomActionSelectSource(actionStore) {
        var _this = _super.call(this) || this;
        _this.actionStore = actionStore;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    CustomActionSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.actionStore.getFirst().pipe(map(function (result) {
            _this.page += 1;
            return result
                .filter(function (item) { return (isSet(searchQuery) ? item.name.toLowerCase().includes(searchQuery.toLowerCase()) : true); })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.name
                };
            });
        }));
    };
    CustomActionSelectSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.actionStore.getDetailFirst(value).pipe(map(function (item) {
            return {
                value: item.name,
                name: item.name
            };
        }));
    };
    CustomActionSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    CustomActionSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    CustomActionSelectSource.prototype.setStaticOptions = function (options) { };
    return CustomActionSelectSource;
}(SelectSource));
export { CustomActionSelectSource };

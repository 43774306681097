import { EventEmitter, Injector, Input, Output } from '@angular/core';

import { AppDrag, AppDropListGroup } from '@common/drag-drop2';
import { DynamicComponentService } from '@common/dynamic-component';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import { CustomizeBarService } from '@modules/customize-bar';
import { ResourceTypeItem } from '@modules/projects';

import { CustomizeBarTab } from '../change-customize-bar.component';

export class ChangeCustomizeBarItemsBase {
  @Input() context: ViewContext;
  @Input() dropListGroups: AppDropListGroup[] = [];
  @Output() selectTab = new EventEmitter<CustomizeBarTab>();
  @Output() selectTemplatesTabResource = new EventEmitter<ResourceTypeItem>();

  customizeBarTabs = CustomizeBarTab;

  constructor(
    protected injector: Injector,
    protected dynamicComponentService: DynamicComponentService,
    protected customizeBarService: CustomizeBarService,
    protected analyticsService: UniversalAnalyticsService
  ) {}
}

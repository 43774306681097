import isInteger from 'lodash/isInteger';
import * as moment from 'moment';

import { defaultFieldType, FieldType } from '../data/field-type';
import { OutputFormat } from '../data/fields/date-time-field/output-format';

export interface ValueDetector {
  apply: (value: any) => boolean;
  handler: (value: any) => { field: FieldType; params?: Object };
}
const valueDetectors: ValueDetector[] = [];

export function registerValueDetector(detector: ValueDetector) {
  valueDetectors.push(detector);
}

const timeFormats = ['hh:mm:ss A', 'hh:mm:ss a', 'h:mm:ss A', 'h:mm:ss a', 'HH:mm:ss', 'H:mm:ss'];

const dateFormats = [
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'YYYY.MM.DD',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'MM.DD.YYYY',
  'M/DD/YYYY',
  'M-DD-YYYY',
  'D.MM.YYYY',
  'D/MM/YYYY',
  'D-MM-YYYY',
  'M.DD.YYYY',
  'MM/DD/YY',
  'MM-DD-YY',
  'DD.MM.YY',
  'DD/MM/YY',
  'DD-MM-YY',
  'MM.DD.YY',
  'M/DD/YY',
  'M-DD-YY',
  'D.MM.YY',
  'D/MM/YY',
  'D-MM-YY',
  'M.DD.YY'
];
const dateTimeFormats: { format: string; dateFormat: string; timeFormat: string }[] = dateFormats.reduce(
  (acc, dateFormat) => {
    timeFormats.forEach(timeFormat => {
      acc.push({
        format: `${dateFormat} ${timeFormat}`,
        dateFormat: dateFormat,
        timeFormat: timeFormat
      });
    });
    return acc;
  },
  []
);
const dateTimeStringFormats = dateTimeFormats.map(item => item.format);

export function detectFieldByValue(value: any): { field: FieldType; params?: Object } {
  if (typeof value == 'number') {
    if (isInteger(value)) {
      return { field: FieldType.Number };
    } else {
      return {
        field: FieldType.Number,
        params: {
          value_format: {
            number_fraction: 2
          }
        }
      };
    }
  } else if (typeof value == 'boolean') {
    return { field: FieldType.Boolean };
  } else if (value instanceof Date) {
    const parsed = moment(value);
    const dateOnly = parsed.startOf('day').isSame(parsed);
    return {
      field: FieldType.DateTime,
      params: dateOnly ? { date: true, time: false } : undefined
    };
  } else if (typeof value == 'string') {
    if (['true', 'false'].includes(value.toLowerCase())) {
      return { field: FieldType.Boolean };
    } else if (moment(value, [moment.ISO_8601], true).isValid()) {
      return { field: FieldType.DateTime };
    } else if (moment(value, dateTimeStringFormats, true).isValid()) {
      const parsed = moment(value, dateTimeStringFormats, true);
      const usedFormatString = parsed['_f'];
      const format = usedFormatString ? dateTimeFormats.find(item => item.format == usedFormatString) : undefined;

      return {
        field: FieldType.DateTime,
        params: {
          output_format: OutputFormat.String,
          date_format: format ? format.dateFormat : undefined,
          time_format: format ? format.timeFormat : undefined
        }
      };
    } else if (moment(value, dateFormats, true).isValid()) {
      const parsed = moment(value, dateFormats, true);
      const usedFormatString = parsed['_f'];
      return {
        field: FieldType.DateTime,
        params: { output_format: OutputFormat.String, date: true, time: false, date_format: usedFormatString }
      };
    } else if (value.match(/^\d+[.,]\d{1,6}$/)) {
      return { field: FieldType.Number, params: { fraction: 2 } };
    }

    try {
      const urlParts = new URL(value);
      const path = urlParts.pathname.toLowerCase();
      const images = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

      if (images.some(item => path.endsWith(`.${item}`))) {
        return { field: FieldType.Image };
      } else if (path.match(/^.+\.\w{2,4}$/)) {
        return { field: FieldType.File };
      }
    } catch (e) {}
  }

  const valueDetector = valueDetectors.find(item => item.apply(value));
  if (valueDetector) {
    return valueDetector.handler(value);
  }

  return { field: defaultFieldType };
}

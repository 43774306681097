import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { ModelAction } from '@modules/models';
import { Environment, Project, Resource } from '@modules/projects';
import { isSet } from '@shared';

import { Automation } from '../../data/automation';
import { AutomationTriggerWebhook } from '../../data/automation-trigger-hook';
import { WorkflowBackendRunWithRelations } from '../../data/workflow-backend-run-with-relations';

@Injectable({
  providedIn: 'root'
})
export class AutomationService {
  constructor(private apiService: ApiService, private http: HttpClient, private centrifugoService: CentrifugoService) {}

  get(project: Project, environment: Environment, params = {}): Observable<Automation[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'automations/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: params });
      }),
      map(result => result.map(item => new Automation().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(project: Project, environment: Environment, id: string): Observable<Automation> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `automations/${id}/`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers });
      }),
      map(result => new Automation().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(project: Project, environment: Environment, instance: Automation): Observable<Automation> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'automations/');
        let headers = new HttpHeaders();
        const data = instance.serialize();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new Automation().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(project: Project, environment: Environment, instance: Automation, fields?: string[]): Observable<Automation> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `automations/${instance.uid}/`
        );
        let headers = new HttpHeaders();
        const data = instance.serialize(fields);

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch<Object>(url, data, { headers: headers });
      }),
      map(result => new Automation().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(project: Project, environment: Environment, instance: Automation): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `automations/${instance.uid}/`
        );
        let headers = new HttpHeaders();
        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  run(project: Project, environment: Environment, id: string, draft: boolean): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `automations/${id}/run`
        );
        let headers = new HttpHeaders();
        const data = {
          draft: draft
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getAutomationTriggerWebhook(
    project: Project,
    environment: Environment,
    instance: Automation
  ): Observable<AutomationTriggerWebhook> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'hooks');
        let headers = new HttpHeaders();
        const data = {
          automationUid: instance.uid
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new AutomationTriggerWebhook().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  automationTriggerWebhookReinitializeStructure(
    project: Project,
    environment: Environment,
    webhook: AutomationTriggerWebhook
  ): Observable<AutomationTriggerWebhook> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `hooks/${webhook.token}/reinitialize_structure`
        );
        let headers = new HttpHeaders();
        const data = {};

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new AutomationTriggerWebhook().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeAutomationTriggerWebhook(
    project: Project,
    environment: Environment,
    webhook: AutomationTriggerWebhook
  ): Observable<AutomationTriggerWebhook> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = [
          '$automation_webhook:automation_webhook',
          project.uniqueName,
          environment.uniqueName,
          webhook.token
        ].join('/');
        return this.centrifugoService
          .subscribeState(channel)
          .pipe(map(msg => new AutomationTriggerWebhook().deserialize(msg.data)));
      })
    );
  }

  trackModelChange(
    project: Project,
    environment: Environment,
    resource: Resource,
    model: string,
    action: ModelAction,
    uid: any,
    modelData: Object
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsMethodURL('model_change');
        let headers = new HttpHeaders();
        const data = {
          project: project.uniqueName,
          environment: environment.uniqueName,
          resource_unique_name: resource.uniqueName,
          model: model,
          action: action,
          data: modelData
        };

        if (isSet(uid)) {
          data['id'] = uid;
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeAutomation(project: Project, environment: Environment, automation: Automation): Observable<Automation> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$automation:automation', project.uniqueName, environment.uniqueName, automation.uid].join(
          '/'
        );
        return this.centrifugoService.subscribeState(channel).pipe(map(msg => new Automation().deserialize(msg.data)));
      })
    );
  }

  subscribeWorkflowRun(
    project: Project,
    environment: Environment,
    run: WorkflowBackendRunWithRelations
  ): Observable<WorkflowBackendRunWithRelations> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$workflow_run:workflow_run', run.id].join('/');
        return this.centrifugoService
          .subscribeState(channel)
          .pipe(map(msg => new WorkflowBackendRunWithRelations().deserialize(msg.data)));
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export type PageColumnsInnerPaddingSize = 'none' | 'xs' | 's' | 'm';

@Component({
  selector: 'app-page-columns',
  templateUrl: './page-columns.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageColumnsComponent implements OnInit {
  @HostBinding('class.page-columns') cls = true;
  @HostBinding('class.page-columns_border') border = false;
  @Input() innerPaddingSize: PageColumnsInnerPaddingSize;
  @Input() @HostBinding('class.page-columns_disabled') disabled = false;

  @HostBinding('class.page-columns_inner-padding-size_none') get paddingSizeNone() {
    return this.innerPaddingSize == 'none';
  }

  @HostBinding('class.page-columns_inner-padding-size_xs') get paddingSizeXs() {
    return this.innerPaddingSize == 'xs';
  }

  @HostBinding('class.page-columns_inner-padding-size_s') get paddingSizeS() {
    return this.innerPaddingSize == 's';
  }

  @HostBinding('class.page-columns_inner-padding-size_m') get paddingSizeM() {
    return this.innerPaddingSize == 'm';
  }

  constructor() {}

  ngOnInit() {}
}

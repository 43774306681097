/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./popup2-title.component";
var styles_Popup2TitleComponent = [];
var RenderType_Popup2TitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2TitleComponent, data: {} });
export { RenderType_Popup2TitleComponent as RenderType_Popup2TitleComponent };
export function View_Popup2TitleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["class", "popup2-title"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 2), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup2-title"; var currVal_1 = _ck(_v, 2, 0, ("popup2-title_align_" + _co.align), ("popup2-title_size_" + _co.size)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_Popup2TitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-title", [], null, null, null, View_Popup2TitleComponent_0, RenderType_Popup2TitleComponent)), i0.ɵdid(1, 114688, null, 0, i2.Popup2TitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2TitleComponentNgFactory = i0.ɵccf("app-popup2-title", i2.Popup2TitleComponent, View_Popup2TitleComponent_Host_0, { align: "align", size: "size" }, {}, ["*"]);
export { Popup2TitleComponentNgFactory as Popup2TitleComponentNgFactory };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { Color, Frame, GradientStop, GradientType, Translate } from '@modules/views';
import { controlValue, MouseButton, pointsDistance, radToDeg, square, TypedChanges } from '@shared';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { GradientStopControl } from '../../controls/gradient-stop.control';
import { GradientControl } from '../../controls/gradient.control';

const markCustomizeGradientMouseDownProperty = '_markCustomizeGradientMouseDownProperty';

export function markCustomizeGradientMouseDown(mouseDownEvent: MouseEvent) {
  mouseDownEvent[markCustomizeGradientMouseDownProperty] = true;
}

export function isCustomizeGradientMouseDown(mouseDownEvent: MouseEvent) {
  return !!mouseDownEvent[markCustomizeGradientMouseDownProperty];
}

@Component({
  selector: 'app-gradient-position',
  templateUrl: './gradient-position.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradientPositionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: GradientControl;
  @Input() viewContext: ViewContext;
  @Input() activeStop: string;
  @Input() frame: Frame;
  @Input() translate: Translate = { x: 0, y: 0 };
  @Input() boundsElement: HTMLElement;

  @ViewChild('root') rootElement: ElementRef;

  controlSubscription: Subscription;
  fromX = 0;
  fromY = 0;
  length = 0;
  angle = 0;
  frame$ = new BehaviorSubject<Frame>(undefined);
  transform: SafeStyle;
  gradientTypes = GradientType;

  trackStopControlFn(i, item: GradientStopControl) {
    return item.getId() || `index_${i}`;
  }

  constructor(
    private editorContext: ViewEditorContext,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<GradientPositionComponent>): void {
    if (changes.control) {
      this.initControl();
    }

    if (changes.frame) {
      this.frame$.next(this.frame);
    }
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.rootElement.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(e => markCustomizeGradientMouseDown(e));
  }

  initControl() {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
      this.controlSubscription = undefined;
    }

    this.controlSubscription = combineLatest(
      controlValue(this.control.controls.type),
      controlValue(this.control.controls.from.controls.x),
      controlValue(this.control.controls.from.controls.y),
      controlValue(this.control.controls.to.controls.x),
      controlValue(this.control.controls.to.controls.y),
      this.frame$.pipe(filter(bounds => !!bounds))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([type, fromX, fromY, toX, toY, frame]) => {
        const deltaX = frame ? (fromX - toX) * frame.width : 0;
        const deltaY = frame ? (toY - fromY) * frame.height : 0;
        const angle = this.getLineAngle(deltaX, deltaY);

        this.fromX = fromX;
        this.fromY = fromY;
        this.length = frame ? Math.sqrt(square(deltaX) + square(deltaY)) : 0;
        this.transform = angle ? this.sanitizer.bypassSecurityTrustStyle(`rotate(${angle}deg)`) : undefined;
        this.cd.markForCheck();
      });
  }

  getLineAngle(deltaX: number, deltaY: number) {
    if (deltaY != 0) {
      const angle = radToDeg(Math.atan(deltaX / deltaY));

      if (deltaX >= 0 && deltaY >= 0) {
        return 90 + angle;
      } else if (deltaX >= 0 && deltaY < 0) {
        return 270 + angle;
      } else if (deltaX < 0 && deltaY < 0) {
        return 270 + angle;
      } else if (deltaX < 0 && deltaY >= 0) {
        return 90 + angle;
      }
    } else {
      return deltaX >= 0 ? 180 : 0;
    }
  }

  setActiveStop(control: GradientStopControl) {
    this.editorContext.updateCustomizingGradient({
      activeStop: control ? control.getId() : undefined
    });
  }

  addStop(e: MouseEvent) {
    e.stopPropagation();

    const bounds = this.boundsElement.getBoundingClientRect();
    const x = round((e.clientX - bounds.left) / bounds.width, 2);
    const y = round((e.clientY - bounds.top) / bounds.height, 2);
    const position = clamp(this.getStopPositionFromPoint(x, y), 0.01, 0.99);
    const color = new Color({ red: 255, green: 255, blue: 255 });
    const stop = new GradientStop({ position: position, color: color });

    stop.generateId();

    const control = this.control.controls.stops.appendControl(stop);
    this.setActiveStop(control);
  }

  getStopPositionFromPoint(x: number, y: number): number {
    x = x * this.frame.width;
    y = y * this.frame.height;

    const from = {
      x: this.control.controls.from.controls.x.value * this.frame.width,
      y: this.control.controls.from.controls.y.value * this.frame.height
    };
    const to = {
      x: this.control.controls.to.controls.x.value * this.frame.width,
      y: this.control.controls.to.controls.y.value * this.frame.height
    };

    if (to.x - from.x == 0) {
      const startDistance = to.y >= from.y ? y - from.y : this.frame.height - y;
      const fromToDistance = Math.abs(to.y - from.y);
      const stopPosition = startDistance / fromToDistance;

      return clamp(round(stopPosition, 2), 0, 1);
    } else if (to.y - from.y == 0) {
      const startDistance = to.x >= from.x ? x - from.x : this.frame.width - x;
      const fromToDistance = Math.abs(to.x - from.x);
      const stopPosition = startDistance / fromToDistance;

      return clamp(round(stopPosition, 2), 0, 1);
    } else {
      const k = (to.y - from.y) / (to.x - from.x);
      const b = (to.x * from.y - from.x * to.y) / (to.x - from.x);
      const normalB = y + x / k;
      const position = {
        x: ((normalB - b) * k) / (square(k) + 1),
        y: (normalB * square(k) + b) / (square(k) + 1)
      };

      const fromToDistance = Math.sqrt(square(to.x - from.x) + square(to.y - from.y));
      const endDistance = Math.sqrt(square(to.x - position.x) + square(to.y - position.y));
      const startDistance =
        endDistance <= fromToDistance ? Math.sqrt(square(position.x - from.x) + square(position.y - from.y)) : 0;
      const stopPosition = startDistance / fromToDistance;

      return clamp(round(stopPosition, 2), 0, 1);
    }
  }

  getAspectRatioStopPositionFromPoint(x: number, y: number): number {
    x = x * this.frame.width;
    y = y * this.frame.height;

    const from = {
      x: this.control.controls.from.controls.x.value * this.frame.width,
      y: this.control.controls.from.controls.y.value * this.frame.height
    };
    const to = {
      x: this.control.controls.to.controls.x.value * this.frame.width,
      y: this.control.controls.to.controls.y.value * this.frame.height
    };

    const l = pointsDistance(to.x, to.y, from.x, from.y);

    if (to.x - from.x == 0) {
      const d = Math.abs(x - from.x);
      const width = Math.abs(d) / l;

      return round(width, 2);
    } else if (to.y - from.y == 0) {
      const d = Math.abs(y - from.y);
      const width = Math.abs(d) / l;

      return round(width, 2);
    } else {
      const k = (to.y - from.y) / (to.x - from.x);
      const normalB = from.y + from.x / k;
      const pointB = y - k * x;
      const px = (k * (normalB - pointB)) / (square(k) + 1);
      const py = (normalB * square(k) + pointB) / (square(k) + 1);
      const d = pointsDistance(px, py, from.x, from.y);
      const width = Math.abs(d) / l;

      return round(width, 2);
    }
  }

  onStopPositionChange(options: {
    control: GradientStopControl;
    first?: boolean;
    last?: boolean;
    x: number;
    y: number;
  }) {
    this.setActiveStop(options.control);

    if (options.first) {
      this.control.controls.from.patchValue({ x: options.x, y: options.y });
      options.control.controls.position.patchValue(0);
    } else if (options.last) {
      this.control.controls.to.patchValue({ x: options.x, y: options.y });
      options.control.controls.position.patchValue(1);
    } else {
      const position = clamp(this.getStopPositionFromPoint(options.x, options.y), 0.01, 0.99);
      options.control.controls.position.patchValue(position);
    }
  }

  onAspectRatioStopPositionChange(x: number, y: number) {
    const position = this.getAspectRatioStopPositionFromPoint(x, y);
    this.control.controls.aspect_ratio.patchValue(position);
  }
}

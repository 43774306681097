import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';

import { AppDrag } from '../drag/drag.directive';
import { APP_DRAG } from '../drag/drag.token';
import { APP_DRAG_HANDLE } from './drag-handle.token';

@Directive({
  selector: '[appDragHandle]',
  providers: [{ provide: APP_DRAG_HANDLE, useExisting: AppDragHandle }]
})
export class AppDragHandle implements OnDestroy, AfterViewInit {
  @Input('appDragHandleDisabled') disabled = false;

  constructor(
    public element: ElementRef,
    @Inject(APP_DRAG) @Optional() @SkipSelf() public readonly parentDrag: AppDrag
  ) {}

  ngOnDestroy(): void {
    if (this.parentDrag) {
      this.parentDrag.unregisterHandle(this);
    }
  }

  ngAfterViewInit(): void {
    if (this.parentDrag) {
      this.parentDrag.registerHandle(this);
    }
  }

  public isDisabled() {
    return this.disabled;
  }
}

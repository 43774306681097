<app-popup2 [size]="'m'">
  <app-page-block>
    <div class="feature-overview-popup">
      <div class="feature-overview-popup__subtitle">{{ subtitle }}</div>
      <div class="feature-overview-popup__title" [innerHTML]="title"></div>
      <div class="feature-overview-popup__description" [innerHTML]="description"></div>
    </div>
  </app-page-block>

  <app-popup2-footer>
    <app-page-block>
      <app-stack [align]="'right'">
        <a href="javascript:void(0)" class="button popup2__footer-item" (click)="cancel()">Cancel</a>
        <a href="javascript:void(0)" class="button button_primary popup2__footer-item" (click)="upgradePlan()"
          >Upgrade Plan</a
        >
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>

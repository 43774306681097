<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top', offsetX: 0, offsetY: -20 },
    { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="iconPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openIconPopover(false)"
  (detach)="openIconPopover(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <app-icon-selector
        [current]="logoIconControl.value"
        (selected)="setValue({ icon: $event }); openIconPopover(false)"
      ></app-icon-selector>
    </div>
  </div>
</ng-template>

<mat-menu #item_dropdown="matMenu">
  <label mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" for="logo_file">
    <span class="choose-items-item__dropdown-icon icon-cloud_upload"></span>
    Upload logo
  </label>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openIconPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-blocks"></span>
    Choose icon
  </button>

  <button
    *ngFor="let item of logoOptions"
    mat-menu-item
    class="mat-menu-item-odd"
    [disabled]="!(item.data.url | appIsSet) && !(item.data.initials | appIsSet)"
    [disableRipple]="true"
    (click)="setValue({ option: item.value })"
  >
    <span *ngIf="item.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
    {{ item.name }}
    <ng-container *ngIf="!(item.data.url | appIsSet) && !(item.data.initials | appIsSet)">(not set)</ng-container>
  </button>
</mat-menu>

<div class="logo-uploader">
  <div class="logo-uploader__left">
    <app-project-logo
      class="logo-uploader__logo"
      [color]="colorCustomEnabledControl.value ? colorCustomControl.value : colorControl.value"
      [colorDefault]="'white'"
      [logo]="logoUrl"
      [initials]="(logoInitials | appIsSet) ? logoInitials : initials"
      [icon]="logoIconControl && logoIconControl.value ? 'icon-' + logoIconControl.value : undefined"
      [size]="'sm'"
      [borderRadius]="'s'"
      [borderRadiusPercent]="
        borderRadiusControl && (borderRadiusControl.value | appIsSet) ? borderRadiusControl.value * 0.5 : undefined
      "
      [border]="true"
      [fill]="logoFillControl.value"
      (click)="onLogoClick()"
      cdkOverlayOrigin
      #root_trigger="cdkOverlayOrigin"
    ></app-project-logo>
  </div>

  <div class="logo-uploader__main">
    <div class="logo-uploader__row">
      <div class="logo-uploader__column logo-uploader__column_relative logo-uploader__column_fill">
        <input class="logo-uploader__file" type="file" id="logo_file" (change)="onFileChange($event.target)" />

        <div
          *ngIf="uploading"
          class="button button_small button_orange-border button_disabled button_fill logo-uploader__button"
          [matMenuTriggerFor]="item_dropdown"
          #options_menu_trigger
        >
          <app-loader-small class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">Uploading...</span>
        </div>

        <ng-container *ngIf="!uploading">
          <label
            *ngIf="!hasMultipleTypes"
            for="logo_file"
            class="button button_small button_orange-border button_fill logo-uploader__button"
            #logo_file
          >
            <span class="button__icon button__icon_left icon-cloud_upload"></span>
            <span class="button__label">
              <ng-container *ngIf="isSet()">Change</ng-container>
              <ng-container *ngIf="!isSet()">Upload logo</ng-container>
            </span>
          </label>

          <div
            *ngIf="hasMultipleTypes"
            class="button button_small button_orange-border button_fill logo-uploader__button"
            [matMenuTriggerFor]="item_dropdown"
            #options_menu_trigger
          >
            <span class="button__icon button__icon_left icon-edit"></span>
            <span class="button__label">
              <ng-container *ngIf="isSet()">Change</ng-container>
              <ng-container *ngIf="!isSet()">Choose logo</ng-container>
            </span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isFillSupported()" class="logo-uploader__column">
        <a
          href="javascript:void(0)"
          class="button button_small button_orange-border button_icon logo-uploader__button"
          [class.button_disabled]="uploading"
          [appTip]="logoFillControl.value ? 'Fit image' : 'Fill image'"
          [appTipOptions]="{ side: 'top' }"
          (click)="logoFillControl.setValue(!logoFillControl.value)"
        >
          <span
            class="button__icon"
            [class.icon-align_vertical_center]="logoFillControl.value"
            [class.icon-resize_vertical]="!logoFillControl.value"
          ></span>
        </a>
      </div>

      <div *ngIf="isSet()" class="logo-uploader__column">
        <a
          href="javascript:void(0)"
          class="button button_danger-secondary button_small button_icon logo-uploader__button"
          [class.button_disabled]="uploading"
          (click)="clear()"
        >
          <span class="button__icon icon-bin"></span>
        </a>
      </div>
    </div>
    <div class="logo-uploader__row">
      <app-color-options
        [resetEnabled]="true"
        [resetBackgroundColor]="defaultColorBackground"
        [resetBorderColor]="defaultColorBorder"
        [resetValue]="defaultColor"
        [colors]="colors"
        [colorControl]="colorControl"
        [customColorEnabledControl]="colorCustomEnabledControl"
        [customColorControl]="colorCustomControl"
        [size]="'sm'"
      ></app-color-options>
    </div>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { SalesforceGeneratorService } from '@modules/resource-generators';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var SalesforceResourceSettingsForm = /** @class */ (function (_super) {
    __extends(SalesforceResourceSettingsForm, _super);
    function SalesforceResourceSettingsForm(salesforceGeneratorService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.salesforceGeneratorService = salesforceGeneratorService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.tokenName = 'oauth_access_token';
        _this.form = new AppFormGroup({
            domain: new FormControl('', Validators.required),
            key: new FormControl('', Validators.required),
            secret: new FormControl('', Validators.required),
            access_token: new FormControl(null, Validators.required),
            params: new FormControl(null, Validators.required)
        });
        return _this;
    }
    SalesforceResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.salesforceGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                domain: result.domain,
                key: result.key,
                secret: result.secret,
                access_token: result.access_token,
                params: result.token_params
            });
        }));
    };
    SalesforceResourceSettingsForm.prototype.getOptions = function () {
        return {
            domain: this.form.value['domain'],
            key: this.form.value['key'],
            secret: this.form.value['secret'],
            access_token: this.form.value['access_token'],
            token_params: this.form.value['params']
        };
    };
    SalesforceResourceSettingsForm.prototype.isAccountParametersValid = function () {
        return this.form.controls['domain'].valid && this.form.controls['key'].valid && this.form.controls['secret'].valid;
    };
    SalesforceResourceSettingsForm.prototype.isOptionsValid = function () {
        return this.salesforceGeneratorService.isOptionsValid(this.getOptions());
    };
    SalesforceResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        var options = this.getOptions();
        return this.salesforceGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    return SalesforceResourceSettingsForm;
}(BaseResourceSettingsForm));
export { SalesforceResourceSettingsForm };

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, openUrl } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../projects/stores/projects.store";
import * as i2 from "../../routing/services/routing/routing.service";
var DefaultEnvironmentRedirectGuard = /** @class */ (function () {
    function DefaultEnvironmentRedirectGuard(projectsStore, routingService) {
        this.projectsStore = projectsStore;
        this.routingService = routingService;
    }
    DefaultEnvironmentRedirectGuard.prototype.canActivateChild = function (childRoute, state) {
        var _this = this;
        return this.projectsStore.getFirst().pipe(map(function (projects) {
            var modeSegment = childRoute.data['routeWithVersion'] ? 1 : 0;
            var modeName = childRoute.parent.url[modeSegment].path;
            var projectNameSegment = childRoute.data['routeWithVersion'] ? 2 : 1;
            var projectName = childRoute.parent.url[projectNameSegment].path;
            var project = projects ? projects.find(function (item) { return item.uniqueName == projectName; }) : undefined;
            var appLink = childRoute.url.map(function (item) { return item.path; });
            if (!project) {
                // Ignore error to show error inside RedirectComponent
                return true;
            }
            if (project.defaultEnvironment) {
                var link = project.linkWithProtocol(appLink, {
                    environmentName: project.defaultEnvironment.uniqueName,
                    mode: modeName
                });
                if (link.link) {
                    _this.routingService.navigate(link.link, { fragment: childRoute.fragment });
                }
                else if (link.href) {
                    if (isSet(childRoute.fragment)) {
                        link.href = link.href + "#" + childRoute.fragment;
                    }
                    openUrl(link.href);
                }
            }
            return false;
        }), catchError(function () {
            _this.routingService.navigate(['/']);
            return of(false);
        }));
    };
    DefaultEnvironmentRedirectGuard.ngInjectableDef = i0.defineInjectable({ factory: function DefaultEnvironmentRedirectGuard_Factory() { return new DefaultEnvironmentRedirectGuard(i0.inject(i1.ProjectsStore), i0.inject(i2.RoutingService)); }, token: DefaultEnvironmentRedirectGuard, providedIn: "root" });
    return DefaultEnvironmentRedirectGuard;
}());
export { DefaultEnvironmentRedirectGuard };

import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, take, throttleTime } from 'rxjs/operators';

import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CodeFieldComponent } from '@modules/field-components';
import { createFormFieldFactory, getJavaScriptSyntaxError, InputValueType } from '@modules/fields';
import { contextToFormulaValue, FieldInputControl, transformFormulaElementAccessors } from '@modules/parameters';
import { isSet, removeClass } from '@shared';

// Refactor imports
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';

import { FormulaInsert, FormulaToken } from '../../data/formula-token';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';

@Component({
  selector: 'app-inline-input-edit',
  templateUrl: './inline-input-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineInputEditComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() itemForm: FieldInputControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() editable$: BehaviorSubject<boolean>;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() classes: string[] = [];
  @Input() analyticsSource: string;

  @ViewChild('input_popover') inputPopover: ElementRef;

  @ViewChild('editor_overlay', { read: CdkConnectedOverlay }) editorOverlay: CdkConnectedOverlay;
  @ViewChild(ViewContextTokenPopoverOverlayComponent)
  tokenPopoverOverlayComponent: ViewContextTokenPopoverOverlayComponent;
  @ViewChild('js_field') jsField: AutoFieldComponent;
  @ViewChild(InputEditFormulaValueComponent) formulaValueComponent: InputEditFormulaValueComponent;

  createField = createFormFieldFactory();
  control: FormControl;
  initialValue: Object;
  jsError: string;
  contentHover$ = new BehaviorSubject<boolean>(false);
  editorHover$ = new BehaviorSubject<boolean>(false);
  editorOpened$ = combineLatest(this.contentHover$, this.editorHover$).pipe(
    throttleTime(10, asyncScheduler, { leading: true, trailing: true }),
    map(([contentHover, editorHover]) => contentHover || editorHover)
  );
  editorActive = false;
  editorPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      weight: 4,
      offsetX: -4
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      weight: 3,
      offsetX: 4
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      weight: 2,
      offsetX: -4
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      weight: 1,
      offsetX: 4
    }
  ];
  resizeTypes = ResizeType;
  startedFormulaEditing = false;
  inputValueTypes = InputValueType;

  constructor(
    private focusMonitor: FocusMonitor,
    private analyticsService: UniversalAnalyticsService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const toExternalValue = transformFormulaElementAccessors(value, this.context, false);
      this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
      this.itemForm.controls['formula_value'].patchValue(toExternalValue);
      this.itemForm.markAsDirty();
    });

    this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      this.updateInputErrors();
      this.onInteracting();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
        Source: this.analyticsSource
      });
    });

    this.setInitialValue();

    // Workaround for overlays overlap issue
    if (this.focusedInitial) {
      this.contentHover$.next(true);
    }
  }

  ngOnDestroy(): void {
    if (this.startedFormulaEditing) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, {
        ...getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue),
        Source: this.analyticsSource
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.focusedInitial) {
      this.activateEditor();
      this.focusedInitial = false;
    }
  }

  clearInputErrors() {
    this.jsError = undefined;
    this.cd.markForCheck();
  }

  updateInputErrors() {
    const value = this.itemForm.value;

    this.clearInputErrors();

    if (value['value_type'] == InputValueType.Js) {
      if (isSet(value['js_value'])) {
        this.jsError = getJavaScriptSyntaxError(value['js_value']);
        this.cd.markForCheck();
      }
    }
  }

  activateEditor() {
    if (this.editorActive) {
      return;
    }

    this.editorActive = true;
    this.cd.markForCheck();

    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        removeClass(this.editorOverlay.overlayRef.backdropElement, 'popover2-backdrop2');
      });

    this.focusValueOnStable();
  }

  deactivateEditor() {
    this.editorActive = false;
    this.contentHover$.next(false);
    this.editorHover$.next(false);
    this.cd.markForCheck();
  }

  onEditorOpened() {
    this.onInteracting();
  }

  onInteracting() {
    if (!this.startedFormulaEditing) {
      this.startedFormulaEditing = true;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
        Source: this.analyticsSource
      });
    }
  }

  focusValue() {
    if (this.formulaValueComponent) {
      this.formulaValueComponent.focus();
    }

    if (this.jsField) {
      this.jsField.focus();
    }
  }

  focusValueOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.focusValue());
  }

  switchToFormula() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
    this.itemForm.markAsDirty();
  }

  switchToJs() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Js);
    this.itemForm.markAsDirty();
  }

  switchToTextInputs() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.TextInputs);
    this.itemForm.markAsDirty();
  }

  setInitialValue() {
    const formValue = this.itemForm.value;

    const fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
    this.control.patchValue(fromExternalValue, { emitEvent: false });

    this.initialValue = formValue;
    this.itemForm.markAsPristine();
    this.updateInputErrors();
  }

  onEditorOverlayContentChanged() {
    if (this.editorOverlay) {
      this.editorOverlay.overlayRef.updatePosition();
    }

    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.updatePosition();
    }
  }

  insertJsToken(item: FormulaInsert | FormulaToken) {
    if (!isSet(item.insert) && !isSet(item.formula)) {
      return;
    }

    if (
      this.jsField &&
      this.jsField.dynamicComponent.currentComponent &&
      this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent
    ) {
      const ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
      const selectionRange = ace.editor.selection.getRange();
      let insert: string;

      if (isSet(item.formula)) {
        insert = item.formula;
      } else {
        const formulaValue = contextToFormulaValue(item.insert);
        insert = transformFormulaElementAccessors(formulaValue, this.context, false);
      }

      const insertRow = selectionRange.start.row;
      const insertLast = isSet(item['label']) ? (item as FormulaToken).label : item.insert[item.insert.length - 1];
      const insertRowValue = ace.editor.session.getLine(insertRow);
      const varName = typeof insertLast == 'number' ? `item${insertLast}` : camelCase(String(insertLast));
      const prefixMatch = insertRowValue.match(/^([\s\t]+)/);
      const prefix = prefixMatch ? prefixMatch[1] : '';

      ace.editor.session.insert({ row: insertRow, column: 0 }, `${prefix}let ${varName} = ${insert};\n`);
    }
  }

  onJsTokenSelected(item: FormulaInsert | FormulaToken) {
    if (isSet(item.insert) || isSet(item.formula)) {
      this.insertJsToken(item);
    }
  }
}

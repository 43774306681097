/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i2 from "../page-template-preview-page/page-template-preview-page.component.ngfactory";
import * as i3 from "../../../customize/data/view-context-element";
import * as i4 from "../../../customize/data/view-context";
import * as i5 from "../page-template-preview-page/page-template-preview-page.component";
import * as i6 from "../../../projects/stores/current-environment.store";
import * as i7 from "./page-template-preview-other-page.component";
var styles_PageTemplatePreviewOtherPageComponent = [];
var RenderType_PageTemplatePreviewOtherPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewOtherPageComponent, data: {} });
export { RenderType_PageTemplatePreviewOtherPageComponent as RenderType_PageTemplatePreviewOtherPageComponent };
export function View_PageTemplatePreviewOtherPageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-page-template-preview-page", [["class", "choose-template__detail-browsers-item-inner"]], null, null, null, i2.View_PageTemplatePreviewPageComponent_0, i2.RenderType_PageTemplatePreviewPageComponent)), i0.ɵprd(131584, null, i3.ViewContextElement, i3.ViewContextElement, [i4.ViewContext]), i0.ɵdid(3, 4833280, null, 0, i5.PageTemplatePreviewPageComponent, [i4.ViewContext, i3.ViewContextElement], { page: [0, "page"], context: [1, "context"], header: [2, "header"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "choose-template__detail-browsers-item-label"]], [[2, "choose-template__detail-browsers-item-label_visible", null]], null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", "\n"])), i0.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page; var currVal_1 = _co.context; var currVal_2 = false; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.labelsVisible; _ck(_v, 4, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.page.name)); _ck(_v, 5, 0, currVal_4); }); }
export function View_PageTemplatePreviewOtherPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-other-page", [], null, null, null, View_PageTemplatePreviewOtherPageComponent_0, RenderType_PageTemplatePreviewOtherPageComponent)), i0.ɵprd(131584, null, i4.ViewContext, i4.ViewContext, [i6.CurrentEnvironmentStore]), i0.ɵdid(2, 114688, null, 0, i7.PageTemplatePreviewOtherPageComponent, [i4.ViewContext], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PageTemplatePreviewOtherPageComponentNgFactory = i0.ɵccf("app-page-template-preview-other-page", i7.PageTemplatePreviewOtherPageComponent, View_PageTemplatePreviewOtherPageComponent_Host_0, { page: "page", labelsVisible: "labelsVisible" }, {}, []);
export { PageTemplatePreviewOtherPageComponentNgFactory as PageTemplatePreviewOtherPageComponentNgFactory };

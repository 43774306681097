import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MetaService } from '@modules/meta';

@Component({
  templateUrl: './demo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoComponent implements OnInit {
  constructor(private metaService: MetaService) {}

  ngOnInit() {
    this.metaService.set({ title: 'Not available' });
  }
}

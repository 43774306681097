<div
  class="layer-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (click)="onMouseClick($event)"
>
  <div class="group-layer" [style.opacity]="layer.opacity" [style.cursor]="layer.getCursor() || null" #layer_element>
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="layer_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div class="group-layer__layers" [class.group-layer__layers_no-interaction]="!layersInteraction" #container_element>
      <app-auto-layer
        *ngFor="let item of displayItems; let i = index; trackBy: trackLayerFn"
        [layer]="item"
        [container]="layer.layers"
        [containerElement]="container_element"
        [frame]="layer.frame"
        [translate]="nestedContainer?.options.translate || { x: 0, y: 0 }"
        [viewContext]="viewContext"
        [analyticsSource]="analyticsSource"
        (layerDelete)="deleteLayer(i)"
      ></app-auto-layer>
    </div>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container
      *appViewEditorViewportOverlay="{
        frame: layer.frame,
        translate: translate
      }"
    >
      <app-view-editor-bounds
        *ngIf="editorContext"
        class="group-layer__bounds"
        [class.group-layer__bounds_hover]="hover$ | async"
        [class.group-layer__bounds_active]="
          (customizing$ | async) &&
          !(editorContext.movingLayer$ | async) &&
          !(editorContext.customizingGradient$ | async)
        "
        [frame]="layer.frame"
        [translate]="translate"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [constrainProportions]="!!layer.constrainProportion"
        [active]="customizing$ | async"
        [resizeHorizontal]="(customizing$ | async) && !(customizingMultiple$ | async)"
        [resizeVertical]="(customizing$ | async) && !(customizingMultiple$ | async)"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateFrame)="onFrameUpdate($event)"
      ></app-view-editor-bounds>
    </ng-container>
  </ng-container>
</div>

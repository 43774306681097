var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { createFormFieldFactory, FieldType, FormField, getFieldDescriptionByType, ParameterControl } from '@modules/fields';
import { controlValue, isSet, setControlEnabled } from '@shared';
var QueryBuilderParametersEditItemComponent = /** @class */ (function () {
    function QueryBuilderParametersEditItemComponent(cd) {
        this.cd = cd;
        this.autofocus = false;
        this.deleteRequested = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.valueControl = new FormControl(null);
        this.controlName = 'field';
        this.popoverOpened = false;
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    QueryBuilderParametersEditItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.form = new FormGroup((_a = {},
            _a[this.controlName] = this.valueControl,
            _a));
        controlValue(this.control)
            .pipe(map(function () { return _this.control.serialize(); }), untilDestroyed(this))
            .subscribe(function (field) {
            if (field.field == FieldType.Boolean) {
                _this.formField = new FormField().deserialize({
                    name: _this.controlName,
                    field: FieldType.Select,
                    params: {
                        valueEquals: _this.booleanValueEquals,
                        options: [
                            { value: null, name: 'Not specified' },
                            { value: false, name: 'No' },
                            { value: true, name: 'Yes' }
                        ],
                        classes: ['select_fill']
                    }
                });
            }
            else {
                var classes = field.params && field.params['classes'] ? field.params['classes'] : [];
                _this.formField = new FormField();
                _this.formField.name = _this.controlName;
                _this.formField.field = field.field;
                _this.formField.params = __assign({}, field.params, { classes: ['select_fill', 'input_fill'].concat(classes), background: true, thumb_inside: true });
            }
            _this.formField.resetEnabled = true;
            _this.formField.placeholder = _this.controlPlaceholder;
            _this.cd.markForCheck();
            setControlEnabled(_this.valueControl, isSet(field.name));
        });
        this.valueControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.valueChange.emit(value);
        });
        this.fieldIcon$ = controlValue(this.control.controls.field).pipe(map(function (value) { return getFieldDescriptionByType(value).icon; }));
        this.control.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (typeof value == 'string') {
                var cleanValue = value.replace(/[^\w]/g, '_').replace(/^\d/, '_');
                if (cleanValue != value) {
                    _this.control.controls.name.patchValue(cleanValue);
                }
            }
        });
    };
    QueryBuilderParametersEditItemComponent.prototype.ngOnDestroy = function () { };
    QueryBuilderParametersEditItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            if (isSet(this.value)) {
                this.valueControl.patchValue(this.value, { emitEvent: false });
            }
            else {
                this.valueControl.patchValue(null, { emitEvent: false });
            }
        }
    };
    QueryBuilderParametersEditItemComponent.prototype.setPopoverOpened = function (opened) {
        this.popoverOpened = opened;
        this.cd.markForCheck();
    };
    QueryBuilderParametersEditItemComponent.prototype.onPopoverContentChanged = function () {
        this.cdkConnectedOverlay.overlayRef.updatePosition();
    };
    Object.defineProperty(QueryBuilderParametersEditItemComponent.prototype, "controlPlaceholder", {
        get: function () {
            return isSet(this.control.controls.description.value)
                ? this.control.controls.description.value
                : "Test value for {{params." + (this.control.controls.name.value || 'name') + "}}";
        },
        enumerable: true,
        configurable: true
    });
    return QueryBuilderParametersEditItemComponent;
}());
export { QueryBuilderParametersEditItemComponent };

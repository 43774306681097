import { OnInit } from '@angular/core';
import defaults from 'lodash/defaults';
import { DefaultDialogOptions } from '../dialog/dialog.component';
var LoadingDialogComponent = /** @class */ (function () {
    function LoadingDialogComponent() {
    }
    LoadingDialogComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(LoadingDialogComponent.prototype, "currentOptions", {
        get: function () {
            return defaults(this.options, DefaultDialogOptions);
        },
        enumerable: true,
        configurable: true
    });
    return LoadingDialogComponent;
}());
export { LoadingDialogComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tip-hotkey.component";
var styles_TipHotkeyComponent = [];
var RenderType_TipHotkeyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TipHotkeyComponent, data: {} });
export { RenderType_TipHotkeyComponent as RenderType_TipHotkeyComponent };
function View_TipHotkeyComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u21E7"]))], null, null); }
function View_TipHotkeyComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"]))], null, null); }
function View_TipHotkeyComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2325"]))], null, null); }
function View_TipHotkeyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shiftKey; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.metaKey; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.altKey; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.key.toUpperCase(); _ck(_v, 7, 0, currVal_3); }); }
function View_TipHotkeyComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ctrl"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "tip__hotkey-operator"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], null, null); }
function View_TipHotkeyComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Shift"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "tip__hotkey-operator"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], null, null); }
function View_TipHotkeyComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Alt"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "tip__hotkey-operator"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["+"]))], null, null); }
function View_TipHotkeyComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_8)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.metaKey; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.shiftKey; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.altKey; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.key.toUpperCase(); _ck(_v, 7, 0, currVal_3); }); }
export function View_TipHotkeyComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "tip__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipHotkeyComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMac; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isMac; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TipHotkeyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tip-hotkey", [], null, null, null, View_TipHotkeyComponent_0, RenderType_TipHotkeyComponent)), i0.ɵdid(1, 114688, null, 0, i2.TipHotkeyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TipHotkeyComponentNgFactory = i0.ɵccf("app-tip-hotkey", i2.TipHotkeyComponent, View_TipHotkeyComponent_Host_0, { key: "key", shiftKey: "shiftKey", altKey: "altKey", metaKey: "metaKey" }, {}, []);
export { TipHotkeyComponentNgFactory as TipHotkeyComponentNgFactory };

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { CustomizeBarContext } from '@modules/customize-bar';
import { ContainerLayer, EllipseLayer, LayerType } from '@modules/views';

import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { BorderControl } from '../../controls/border.control';
import { FillControl } from '../../controls/fill.control';
import { ShadowControl } from '../../controls/shadow.control';
import { FillControlComponent } from '../../ui/fill-control/fill-control.component';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { EllipseCustomizeLayerForm } from './ellipse-customize-layer.form';

@Component({
  selector: 'app-ellipse-customize-layer',
  templateUrl: './ellipse-customize-layer.component.html',
  providers: [EllipseCustomizeLayerForm, CustomizeBarContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EllipseCustomizeLayerComponent extends CustomizeLayerComponent<EllipseLayer> implements OnInit, OnDestroy {
  @ViewChildren(FillControlComponent) fillControlComponents = new QueryList<FillControlComponent>();

  containerLayer: ContainerLayer;
  fillControlOpened: FillControlComponent;
  settingsComponents: DynamicComponentArguments[] = [];

  constructor(
    public form: EllipseCustomizeLayerForm,
    private editorContext: ViewEditorContext,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const layerContainer = this.editorContext.getLayerContainer(this.layer);

    this.form.init(this.layer, { firstInit: true, container: layerContainer });
    this.trackFormUpdates(this.form, () => this.form.submit());

    combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
      .pipe(auditTime(10), untilDestroyed(this))
      .subscribe(([layer, container]) => {
        this.pauseFormUpdates();
        this.form.init(layer, {
          firstInit: false,
          container: container
        });
        this.resumeFormUpdates();
      });

    this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(layer => {
      this.containerLayer = layer;
      this.cd.markForCheck();
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  fillsDragDrop(event: CdkDragDrop<FillControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.fills.controls, event.previousIndex, event.currentIndex);
      this.form.controls.fills.updateValueAndValidity();
    }
  }

  bordersDragDrop(event: CdkDragDrop<BorderControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.borders.controls, event.previousIndex, event.currentIndex);
      this.form.controls.borders.updateValueAndValidity();
    }
  }

  shadowsDragDrop(event: CdkDragDrop<ShadowControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.shadows.controls, event.previousIndex, event.currentIndex);
      this.form.controls.shadows.updateValueAndValidity();
    }
  }

  updateFillControlOpened() {
    this.fillControlOpened = this.fillControlComponents.find(item => item.isColorOpened());
    this.cd.markForCheck();
  }
}

registerCustomizeLayerComponent(LayerType.Ellipse, EllipseCustomizeLayerComponent);

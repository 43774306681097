import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TipHotkeyComponent } from './components/tip-hotkey/tip-hotkey.component';
import { TipComponent } from './components/tip/tip.component';
import { TipDirective } from './directives/tip/tip.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TipComponent, TipDirective, TipHotkeyComponent],
  exports: [TipDirective, TipHotkeyComponent],
  entryComponents: [TipComponent]
})
export class TipsModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ElementType, registerElementComponent, SpacingElementItem, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';

@Component({
  selector: 'app-spacing-element',
  templateUrl: './spacing-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpacingElementComponent extends BaseElementComponent {
  @Input() element: SpacingElementItem;
}

registerElementComponent({
  type: ElementType.Spacing,
  component: SpacingElementComponent,
  label: 'Spacing',
  actions: []
});

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { sendGridResourceParamsActionDescriptions } from '../../data/send-grid/send-grid-resource-params-action-descriptions.stub';
import { sendGridResourceParamsParams } from '../../data/send-grid/send-grid-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface SendGridParamsOptions {
  key: string;
}

@Injectable()
export class SendGridGeneratorService extends ResourceGeneratorService<SendGridParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: SendGridParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://api.sendgrid.com/v3/user/profile';
    query.headers = [{ name: 'Authorization', value: `Bearer ${options.key}` }];

    return this.httpQueryService.request(query).pipe(
      map(response => {
        const company = response.body['company'];
        const firstName = response.body['first_name'];
        const lastName = response.body['last_name'];

        if (company) {
          return { accountName: company };
        } else if (firstName || lastName) {
          return { accountName: [firstName, lastName].filter(item => item).join(' ') };
        } else {
          return {};
        }
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 403) {
          error = new ServerRequestError('API key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<SendGridParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            key: result.value
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: SendGridParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = sendGridResourceParamsParams;
    const actionDescriptions = sendGridResourceParamsActionDescriptions;
    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = options.key;

    return of({
      resourceParams: resourceParams,
      actionDescriptions: actionDescriptions,
      secretTokens: [token.serialize()]
    });
  }
}

export const zendeskResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'tickets',
    verbose_name: 'Ticket',
    verbose_name_plural: 'Tickets',
    hidden: '',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'via',
        verbose_name: 'via',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'select',
        required: true,
        null: false,
        params: {
          inputs: [],
          options: [
            {
              name: 'Problem',
              color: 'green',
              value: 'problem'
            },
            {
              name: 'Task',
              color: 'green',
              value: 'task'
            },
            {
              name: 'Question',
              color: 'green',
              value: 'question'
            },
            {
              name: 'Incident',
              color: 'green',
              value: 'incident'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'due_at',
        verbose_name: 'due_at',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'fields',
        verbose_name: 'fields',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'select',
        required: true,
        null: false,
        params: {
          inputs: [],
          options: [
            {
              name: 'new',
              color: 'black',
              value: 'new'
            },
            {
              name: 'open',
              color: 'black',
              value: 'open'
            },
            {
              name: 'pending',
              color: 'black',
              value: 'pending'
            },
            {
              name: 'hold',
              color: 'black',
              value: 'hold'
            },
            {
              name: 'solved',
              color: 'black',
              value: 'solved'
            },
            {
              name: 'closed',
              color: 'black',
              value: 'closed'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'subject',
        verbose_name: 'subject',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'brand_id',
        verbose_name: 'brand_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'group_id',
        verbose_name: 'group_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        field: 'select',
        required: true,
        null: false,
        params: {
          inputs: [],
          options: [
            {
              name: 'low',
              color: 'green',
              value: 'low'
            },
            {
              name: 'high',
              color: 'red',
              value: 'high'
            },
            {
              name: 'normal',
              color: 'yellow',
              value: 'normal'
            },
            {
              name: 'urgent',
              color: 'maroon',
              value: 'urgent'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'is_public',
        verbose_name: 'is_public',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'recipient',
        verbose_name: 'recipient',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        field: 'datetime',
        required: true,
        null: false,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'problem_id',
        verbose_name: 'problem_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated_at',
        field: 'datetime',
        required: true,
        null: false,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'assignee_id',
        verbose_name: 'assignee_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'raw_subject',
        verbose_name: 'raw_subject',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'email_cc_ids',
        verbose_name: 'email_cc_ids',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'follower_ids',
        verbose_name: 'follower_ids',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'followup_ids',
        verbose_name: 'followup_ids',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'requester_id',
        verbose_name: 'requester_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'submitter_id',
        verbose_name: 'submitter_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'custom_fields',
        verbose_name: 'custom_fields',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'has_incidents',
        verbose_name: 'has_incidents',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'forum_topic_id',
        verbose_name: 'forum_topic_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'organization_id',
        verbose_name: 'organization_id',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'collaborator_ids',
        verbose_name: 'collaborator_ids',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'allow_attachments',
        verbose_name: 'allow_attachments',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'allow_channelback',
        verbose_name: 'allow_channelback',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'satisfaction_rating',
        verbose_name: 'satisfaction_rating',
        field: 'text',
        required: true,
        null: false,
        params: {}
      },
      {
        name: 'sharing_agreement_ids',
        verbose_name: 'sharing_agreement_ids',
        field: 'text',
        required: true,
        null: false,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    ordering_field: '',
    default_order_by: '',
    display_field: 'subject',
    primary_key_field: 'id',
    relations: [],
    description: '',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/tickets.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'sort_by',
            value: 'created_at'
          },
          {
            name: 'sort_order',
            value: 'desc'
          },
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          'return data.tickets.filter(item => {\r\nif (params.type && item.type != params.type) {\r\nreturn false;\r\n}\r\nif (params.status && item.status != params.status) {\r\nreturn false;\r\n}\r\nif (params.priority && item.priority != params.priority) {\r\nreturn false;\r\n}\r\nif (params.created_at && item.created_at != params.created_at) {\r\nreturn false;\r\n}\r\nreturn true;\r\n})',
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn data['next_page']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'url',
          sortable: false
        },
        {
          name: 'id',
          sortable: false
        },
        {
          name: 'external_id',
          sortable: false
        },
        {
          name: 'via',
          sortable: false
        },
        {
          name: 'created_at',
          sortable: false
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'type',
          sortable: false
        },
        {
          name: 'subject',
          sortable: false
        },
        {
          name: 'raw_subject',
          sortable: false
        },
        {
          name: 'description',
          sortable: false
        },
        {
          name: 'priority',
          sortable: false
        },
        {
          name: 'status',
          sortable: false
        },
        {
          name: 'recipient',
          sortable: false
        },
        {
          name: 'requester_id',
          sortable: false
        },
        {
          name: 'submitter_id',
          sortable: false
        },
        {
          name: 'assignee_id',
          sortable: true
        },
        {
          name: 'organization_id',
          sortable: false
        },
        {
          name: 'group_id',
          sortable: false
        },
        {
          name: 'collaborator_ids',
          sortable: false
        },
        {
          name: 'follower_ids',
          sortable: false
        },
        {
          name: 'email_cc_ids',
          sortable: false
        },
        {
          name: 'forum_topic_id',
          sortable: false
        },
        {
          name: 'problem_id',
          sortable: false
        },
        {
          name: 'has_incidents',
          sortable: false
        },
        {
          name: 'is_public',
          sortable: false
        },
        {
          name: 'due_at',
          sortable: false
        },
        {
          name: 'tags',
          sortable: false
        },
        {
          name: 'custom_fields',
          sortable: false
        },
        {
          name: 'satisfaction_rating',
          sortable: false
        },
        {
          name: 'sharing_agreement_ids',
          sortable: false
        },
        {
          name: 'fields',
          sortable: false
        },
        {
          name: 'followup_ids',
          sortable: false
        },
        {
          name: 'brand_id',
          sortable: false
        },
        {
          name: 'allow_channelback',
          sortable: false
        },
        {
          name: 'allow_attachments',
          sortable: true
        }
      ]
    },
    get_parameters: [
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/tickets/{{params.id}}.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'ticket',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'via',
        verbose_name: 'via',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'subject',
        verbose_name: 'subject',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'raw_subject',
        verbose_name: 'raw_subject',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'recipient',
        verbose_name: 'recipient',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'requester_id',
        verbose_name: 'requester_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'submitter_id',
        verbose_name: 'submitter_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'assignee_id',
        verbose_name: 'assignee_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'organization_id',
        verbose_name: 'organization_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'group_id',
        verbose_name: 'group_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'collaborator_ids',
        verbose_name: 'collaborator_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'follower_ids',
        verbose_name: 'follower_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'email_cc_ids',
        verbose_name: 'email_cc_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'forum_topic_id',
        verbose_name: 'forum_topic_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'problem_id',
        verbose_name: 'problem_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'has_incidents',
        verbose_name: 'has_incidents',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'is_public',
        verbose_name: 'is_public',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'due_at',
        verbose_name: 'due_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'custom_fields',
        verbose_name: 'custom_fields',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'satisfaction_rating',
        verbose_name: 'satisfaction_rating',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'sharing_agreement_ids',
        verbose_name: 'sharing_agreement_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'fields',
        verbose_name: 'fields',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'followup_ids',
        verbose_name: 'followup_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'brand_id',
        verbose_name: 'brand_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'allow_channelback',
        verbose_name: 'allow_channelback',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'allow_attachments',
        verbose_name: 'allow_attachments',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://{{account_name}}.zendesk.com/api/v2/tickets/{{params.id}}.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "ticket": {\n        "status": "{{params.status}}",\n        "comment": { \n            "body": "{{params.body}}",\n            "author_id": "{{params.author_id}}" \n            \n        }\n        \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'via',
        verbose_name: 'via',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'subject',
        verbose_name: 'subject',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'raw_subject',
        verbose_name: 'raw_subject',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'recipient',
        verbose_name: 'recipient',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'requester_id',
        verbose_name: 'requester_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'submitter_id',
        verbose_name: 'submitter_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'assignee_id',
        verbose_name: 'assignee_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'organization_id',
        verbose_name: 'organization_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'group_id',
        verbose_name: 'group_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'collaborator_ids',
        verbose_name: 'collaborator_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'follower_ids',
        verbose_name: 'follower_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'email_cc_ids',
        verbose_name: 'email_cc_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'forum_topic_id',
        verbose_name: 'forum_topic_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'problem_id',
        verbose_name: 'problem_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'has_incidents',
        verbose_name: 'has_incidents',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'is_public',
        verbose_name: 'is_public',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'due_at',
        verbose_name: 'due_at',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'custom_fields',
        verbose_name: 'custom_fields',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'satisfaction_rating',
        verbose_name: 'satisfaction_rating',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'sharing_agreement_ids',
        verbose_name: 'sharing_agreement_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'fields',
        verbose_name: 'fields',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'followup_ids',
        verbose_name: 'followup_ids',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'brand_id',
        verbose_name: 'brand_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'allow_channelback',
        verbose_name: 'allow_channelback',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'allow_attachments',
        verbose_name: 'allow_attachments',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'organizations',
    verbose_name: 'Organization',
    verbose_name_plural: 'Organizations',
    fields: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'url',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shared_tickets',
        verbose_name: 'shared tickets',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shared_comments',
        verbose_name: 'shared comments',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'datetime',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'datetime',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'domain_names',
        verbose_name: 'domain names',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'details',
        verbose_name: 'details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes',
        verbose_name: 'notes',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'group_id',
        verbose_name: 'group id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'organization_fields',
        verbose_name: 'organization fields',
        field: 'json',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Return a list of organizations.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/organizations.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['organizations'];",
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'url',
          sortable: true
        },
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'shared_tickets',
          sortable: true
        },
        {
          name: 'shared_comments',
          sortable: true
        },
        {
          name: 'external_id',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'domain_names',
          sortable: true
        },
        {
          name: 'details',
          sortable: true
        },
        {
          name: 'notes',
          sortable: true
        },
        {
          name: 'group_id',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'organization_fields',
          sortable: true
        }
      ]
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/organizations/{{params.id}}.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['organization'];",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://{{account_name}}.zendesk.com/api/v2/organizations.json',
        auth_params: {},
        body_type: 'Raw',
        body: '{\n    "organization": {\n        "name": "{{params.name}}"\n        \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['organization'];",
        response_path: '',
        body_transformer: 'return data;',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://{{account_name}}.zendesk.com/api/v2/organizations/{{params.id}}.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "organization": {\n        "notes": "{{params.notes}}",\n        "name": "{{params.name}}",\n        "details": "{{params.details}}"\n        \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['organization'];",
        response_path: '',
        body_transformer: 'return data;',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes',
        verbose_name: 'notes',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'details',
        verbose_name: 'details',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://{{account_name}}.zendesk.com/api/v2/organizations/{{params.id}}.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'ticket_comments',
    verbose_name: 'Ticket Comments',
    verbose_name_plural: 'Ticket Comments',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'author_id',
        verbose_name: 'author id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'body',
        verbose_name: 'body',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'html_body',
        verbose_name: 'html body',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'plain_body',
        verbose_name: 'plain body',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'public',
        verbose_name: 'public',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'attachments',
        verbose_name: 'attachments',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'audit_id',
        verbose_name: 'audit id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'via',
        verbose_name: 'via',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ticket_id',
        verbose_name: 'ticket_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Retrieves the comments on a ticket.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/tickets/{{params.ticket_id}}/comments.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          },
          {
            name: 'sort_order',
            value: 'desc'
          },
          {
            name: 'include_inline_images',
            value: '{{params.include_inline_images}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          'function addValueId(obj, key, id) {\n    obj[key] = id;\n    return obj;\n}\n\nreturn data[\'comments\'].map(obj => addValueId(obj, "ticket_id", params.ticket_id));\n',
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'via',
          sortable: true
        },
        {
          name: 'body',
          sortable: true
        },
        {
          name: 'type',
          sortable: true
        },
        {
          name: 'public',
          sortable: true
        },
        {
          name: 'audit_id',
          sortable: true
        },
        {
          name: 'metadata',
          sortable: true
        },
        {
          name: 'author_id',
          sortable: true
        },
        {
          name: 'html_body',
          sortable: true
        },
        {
          name: 'ticket_id',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'plain_body',
          sortable: true
        },
        {
          name: 'attachments',
          sortable: true
        }
      ]
    },
    get_parameters: [
      {
        name: 'ticket_id',
        verbose_name: 'ticket_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'include_inline_images',
        verbose_name: 'include_inline_images',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://{{account_name}}.zendesk.com/api/v2/tickets/{{params.id}}.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "ticket": {\n        "comment": {\n            "body": "{{params.body}}",\n            "author_id": "{{params.author_id}}"\n            }\n        \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['ticket'];",
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'body',
        verbose_name: 'body',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'author_id',
        verbose_name: 'author_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'ticket_metrics',
    verbose_name: 'Ticket Metrics',
    verbose_name_plural: 'Ticket Metrics',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'url',
        verbose_name: 'url',
        field: 'url',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reopens',
        verbose_name: 'reopens',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'replies',
        verbose_name: 'replies',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'solved_at',
        verbose_name: 'solved at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'ticket_id',
        verbose_name: 'ticket id',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'assigned_at',
        verbose_name: 'assigned at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'group_stations',
        verbose_name: 'group stations',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assignee_stations',
        verbose_name: 'assignee stations',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status_updated_at',
        verbose_name: 'status updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'assignee_updated_at',
        verbose_name: 'assignee updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'requester_updated_at',
        verbose_name: 'requester updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'initially_assigned_at',
        verbose_name: 'initially assigned at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'reply_time_in_minutes',
        verbose_name: 'reply time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'latest_comment_added_at',
        verbose_name: 'latest comment added at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'on_hold_time_in_minutes',
        verbose_name: 'on hold time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'agent_wait_time_in_minutes',
        verbose_name: 'agent wait time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'requester_wait_time_in_minutes',
        verbose_name: 'requester wait time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'full_resolution_time_in_minutes',
        verbose_name: 'full resolution time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_resolution_time_in_minutes',
        verbose_name: 'first resolution time in minutes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of tickets with their metrics.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/ticket_metrics.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['ticket_metrics'];",
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'url',
          sortable: true
        },
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'ticket_id',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'group_stations',
          sortable: true
        },
        {
          name: 'assignee_stations',
          sortable: true
        },
        {
          name: 'reopens',
          sortable: true
        },
        {
          name: 'replies',
          sortable: true
        },
        {
          name: 'assignee_updated_at',
          sortable: true
        },
        {
          name: 'requester_updated_at',
          sortable: true
        },
        {
          name: 'status_updated_at',
          sortable: true
        },
        {
          name: 'initially_assigned_at',
          sortable: true
        },
        {
          name: 'assigned_at',
          sortable: true
        },
        {
          name: 'solved_at',
          sortable: true
        },
        {
          name: 'latest_comment_added_at',
          sortable: true
        },
        {
          name: 'reply_time_in_minutes',
          sortable: true
        },
        {
          name: 'first_resolution_time_in_minutes',
          sortable: true
        },
        {
          name: 'full_resolution_time_in_minutes',
          sortable: true
        },
        {
          name: 'agent_wait_time_in_minutes',
          sortable: true
        },
        {
          name: 'requester_wait_time_in_minutes',
          sortable: true
        },
        {
          name: 'on_hold_time_in_minutes',
          sortable: true
        }
      ]
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/ticket_metrics/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['ticket_metric'];",
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ticket_id',
        verbose_name: 'ticket_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'group_stations',
        verbose_name: 'group_stations',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assignee_stations',
        verbose_name: 'assignee_stations',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reopens',
        verbose_name: 'reopens',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'replies',
        verbose_name: 'replies',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assignee_updated_at',
        verbose_name: 'assignee_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'requester_updated_at',
        verbose_name: 'requester_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status_updated_at',
        verbose_name: 'status_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'initially_assigned_at',
        verbose_name: 'initially_assigned_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assigned_at',
        verbose_name: 'assigned_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'solved_at',
        verbose_name: 'solved_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'latest_comment_added_at',
        verbose_name: 'latest_comment_added_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reply_time_in_minutes',
        verbose_name: 'reply_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_resolution_time_in_minutes',
        verbose_name: 'first_resolution_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'full_resolution_time_in_minutes',
        verbose_name: 'full_resolution_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'agent_wait_time_in_minutes',
        verbose_name: 'agent_wait_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'requester_wait_time_in_minutes',
        verbose_name: 'requester_wait_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'on_hold_time_in_minutes',
        verbose_name: 'on_hold_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ticket_id',
        verbose_name: 'ticket_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'group_stations',
        verbose_name: 'group_stations',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assignee_stations',
        verbose_name: 'assignee_stations',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reopens',
        verbose_name: 'reopens',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'replies',
        verbose_name: 'replies',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assignee_updated_at',
        verbose_name: 'assignee_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'requester_updated_at',
        verbose_name: 'requester_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status_updated_at',
        verbose_name: 'status_updated_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'initially_assigned_at',
        verbose_name: 'initially_assigned_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assigned_at',
        verbose_name: 'assigned_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'solved_at',
        verbose_name: 'solved_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'latest_comment_added_at',
        verbose_name: 'latest_comment_added_at',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reply_time_in_minutes',
        verbose_name: 'reply_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_resolution_time_in_minutes',
        verbose_name: 'first_resolution_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'full_resolution_time_in_minutes',
        verbose_name: 'full_resolution_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'agent_wait_time_in_minutes',
        verbose_name: 'agent_wait_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'requester_wait_time_in_minutes',
        verbose_name: 'requester_wait_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'on_hold_time_in_minutes',
        verbose_name: 'on_hold_time_in_minutes',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'user_identities',
    verbose_name: 'User Identity',
    verbose_name_plural: 'User Identities',
    fields: [
      {
        name: 'url',
        verbose_name: 'url',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.users'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'verified',
        verbose_name: 'verified',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'primary',
        verbose_name: 'primary',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'undeliverable_count',
        verbose_name: 'undeliverable count',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deliverable_state',
        verbose_name: 'deliverable state',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns all user identities for a given user.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://jetadmin5760.zendesk.com/api/v2/users/{{params.user_id}}/identities.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'page[size]',
            value: '{{paging.page}}'
          },
          {
            name: 'page[after]',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'page[before]',
            value: '{{paging.cursor_prev}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['identities']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://jetadmin5760.zendesk.com/api/v2/end_users/{{params.id}}/identities.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "identity": {\n        "type": "{{params.type}}", \n        "value": "{{params.value}}"\n    \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://jetadmin5760.zendesk.com/api/v2/users/{{params.user_id}}/identities/{{params.id}}.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "identity": {\n        "value": "{{params.value}}",\n        "verified": "{{params.verified}}"\n        \n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'value',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'verified',
        verbose_name: 'verified',
        description: '',
        field: 'boolean',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://jetadmin5760.zendesk.com/api/v2/users/{{params.teuser_id}}/identities/{{params.id}}.json',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'users',
    verbose_name: 'Users',
    verbose_name_plural: 'Users',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'url',
        verbose_name: 'url',
        field: 'url',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'time_zone',
        verbose_name: 'time zone',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'iana_time_zone',
        verbose_name: 'iana time zone',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shared_phone_number',
        verbose_name: 'shared phone number',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'photo',
        verbose_name: 'photo',
        field: 'json',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'locale_id',
        verbose_name: 'locale id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'locale',
        verbose_name: 'locale',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'organization_id',
        verbose_name: 'organization',
        field: 'related_model',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.organizations'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'role',
        verbose_name: 'role',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'verified',
        verbose_name: 'verified',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'alias',
        verbose_name: 'alias',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active',
        verbose_name: 'active',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shared',
        verbose_name: 'shared',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shared_agent',
        verbose_name: 'shared agent',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_login_at',
        verbose_name: 'last login at',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'two_factor_auth_enabled',
        verbose_name: 'two factor auth enabled',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'signature',
        verbose_name: 'signature',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'details',
        verbose_name: 'details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes',
        verbose_name: 'notes',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role_type',
        verbose_name: 'role type',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'custom_role_id',
        verbose_name: 'custom role id',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'moderator',
        verbose_name: 'moderator',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ticket_restriction',
        verbose_name: 'ticket restriction',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'only_private_comments',
        verbose_name: 'only private comments',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'restricted_agent',
        verbose_name: 'restricted agent',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'suspended',
        verbose_name: 'suspended',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_group_id',
        verbose_name: 'default group id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'report_csv',
        verbose_name: 'report csv',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_fields',
        verbose_name: 'user fields',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of all users in the workspace.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/users.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'sort_by',
            value: '{{sorting.field}}'
          },
          {
            name: 'sort_order',
            value: "{% if (sorting.field) { %}{{sorting.asc ? 'ASC': 'DESC'}}{% } %}"
          },
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['users'];",
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['next_page']",
      pagination_total_function: "// ex. data['count']\nreturn data['count']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'url',
          sortable: true
        },
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'email',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'time_zone',
          sortable: true
        },
        {
          name: 'iana_time_zone',
          sortable: true
        },
        {
          name: 'phone',
          sortable: true
        },
        {
          name: 'shared_phone_number',
          sortable: true
        },
        {
          name: 'photo',
          sortable: true
        },
        {
          name: 'locale_id',
          sortable: true
        },
        {
          name: 'locale',
          sortable: true
        },
        {
          name: 'organization_id',
          sortable: true
        },
        {
          name: 'role',
          sortable: true
        },
        {
          name: 'verified',
          sortable: true
        },
        {
          name: 'external_id',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'alias',
          sortable: true
        },
        {
          name: 'active',
          sortable: true
        },
        {
          name: 'shared',
          sortable: true
        },
        {
          name: 'shared_agent',
          sortable: true
        },
        {
          name: 'last_login_at',
          sortable: true
        },
        {
          name: 'two_factor_auth_enabled',
          sortable: true
        },
        {
          name: 'signature',
          sortable: true
        },
        {
          name: 'details',
          sortable: true
        },
        {
          name: 'notes',
          sortable: true
        },
        {
          name: 'role_type',
          sortable: true
        },
        {
          name: 'custom_role_id',
          sortable: true
        },
        {
          name: 'moderator',
          sortable: true
        },
        {
          name: 'ticket_restriction',
          sortable: true
        },
        {
          name: 'only_private_comments',
          sortable: true
        },
        {
          name: 'restricted_agent',
          sortable: true
        },
        {
          name: 'suspended',
          sortable: true
        },
        {
          name: 'default_group_id',
          sortable: true
        },
        {
          name: 'report_csv',
          sortable: true
        },
        {
          name: 'user_fields',
          sortable: true
        }
      ]
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{account_name}}.zendesk.com/api/v2/users/{{params.id}}.json',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['user'];",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://{{account_name}}.zendesk.com/api/v2/users.json',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\n    "user": {\n        "name": "{{params.name}}",\n        "email": "{{params.email}}",\n        "role": "{{params.role}}",\n        "custom_role_id": "{{params.custom_role_id}}"\n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['users'];",
        response_path: '',
        body_transformer: 'return data;',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role',
        verbose_name: 'role',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'custom_role_id',
        verbose_name: 'custom_role_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://{{account_name}}.zendesk.com/api/v2/users/{{params.id}}.json',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n    "user": {\n        "name": "{{params.name}}",\n        "email": "{{params.email}}"\n    }\n    \n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['users'];",
        response_path: '',
        body_transformer: 'return data;',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://{{account_name}}.zendesk.com/api/v2/users/{{params.id}}.json',
        auth_params: {},
        body_type: 'FormData',
        body: [],
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  }
];

import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Subject } from 'rxjs';

import { PopoverService } from '@common/popover';
import { UniversalAnalyticsService } from '@modules/analytics';
import { FieldsEditComponent, FieldsEditForm } from '@modules/field-components';
import { AutofocusDirective, controlValue, isSet } from '@shared';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';

// TODO: Remove unused
@Component({
  selector: 'app-customize-bar-fields-edit',
  templateUrl: './customize-bar-fields-edit.component.html',
  providers: [FieldsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarFieldsEditComponent extends FieldsEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemName = 'field';
  @Input() collapsible = true;
  @Input() humanUniqueName = false;
  @Input() toggle = true;
  @Input() searchEnabled = false;
  @Input() searchFocus = false;
  @Input() firstInit = false;
  @Output() searchCleared = new EventEmitter<void>();

  @ViewChild('search_autofocus', { read: AutofocusDirective }) searchAutoFocus: AutofocusDirective;

  displayItems: FormGroup[] = [];
  maxDisplayInitial = 8;
  collapsed = true;
  search = '';
  searchUpdated = new Subject<string>();
  sendParametersAnalytics = false;

  constructor(
    public form: FieldsEditForm,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    protected analyticsService: UniversalAnalyticsService,
    protected popoverService: PopoverService,
    protected injector: Injector,
    protected cd: ChangeDetectorRef
  ) {
    super(form, analyticsService, popoverService, injector, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    merge(controlValue(this.form.form), this.searchUpdated)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateDisplayItems());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchEnabled'] && !this.searchEnabled) {
      this.clearSearch();
    }
  }

  editItem(itemForm: FormGroup) {
    const initialElement = cloneDeep(itemForm.value);
    this.customizeBarService
      .customizeColumn({
        context: this.customizeBarContext,
        column: itemForm.value,
        configurable: {
          verboseName: true,
          action: this.configurable.action,
          sortable: this.configurable.sortable,
          editable: this.configurable.editable,
          value: !!itemForm.value['flex']
        },
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        append: true,
        firstInit: this.firstInit
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Updated) {
          itemForm.patchValue(e.args['result']);
          itemForm.markAsDirty();
        } else if (e.type == CustomizeBarEditEventType.Canceled) {
          itemForm.patchValue(initialElement);
          itemForm.markAsDirty();
        }
      });

    this.clearSearch();
  }

  getMoveToTopPosition(itemForm: FormGroup) {
    const currentIndex = this.form.form.controls.indexOf(itemForm);
    const lastVisibleIndex = this.form.form.controls
      .map((item, i) => i < currentIndex && !!item.value['visible'])
      .lastIndexOf(true);
    return lastVisibleIndex != -1 ? lastVisibleIndex + 1 : currentIndex;
  }

  moveToTop(itemForm: FormGroup) {
    const currentIndex = this.form.form.controls.indexOf(itemForm);
    const newIndex = this.getMoveToTopPosition(itemForm);

    if (!itemForm.value['visible']) {
      itemForm.patchValue({ visible: true });
    }

    moveItemInArray(this.form.form.controls, currentIndex, newIndex);
    this.updateDisplayItems();
    this.cd.detectChanges();
  }

  removeItem(itemForm: FormGroup) {
    this.form.arrayDelete(itemForm);
  }

  getAddItemUniqueField(field: string, prefix: string, separator = '_', skipFirst = false) {
    const value = this.form.form.value;
    let nextNumber = 1;
    let result: string;

    do {
      result = skipFirst && nextNumber == 1 ? prefix : `${prefix}${separator}${nextNumber}`;
      ++nextNumber;
    } while (value.find(group => group[field] == result));

    return result;
  }

  addItem() {
    const uniqueName = this.humanUniqueName
      ? this.getAddItemUniqueField('name', 'Custom Field', ' ', true)
      : this.getAddItemUniqueField('name', '_jet_field');
    const verboseName = this.getAddItemUniqueField('verboseName', 'Custom Field', ' ', true);
    const item = this.form.createItem({
      name: uniqueName,
      verboseName: verboseName,
      flex: true,
      editable: this.configurable.editable
    });
    this.form.arrayAppend(item);
    this.updateDisplayItems();
    this.editItem(item);

    this.clearSearch();
  }

  updateDisplayItems() {
    const processSearch = str => (str || '').trim().toLowerCase();
    const search = processSearch(this.search);

    if (isSet(search)) {
      this.displayItems = (this.form.form.controls as FormGroup[]).filter(item => {
        return (
          processSearch(item.value['verboseName']).indexOf(search) !== -1 ||
          processSearch(item.value['name']).indexOf(search) !== -1
        );
      });
    } else if (this.collapsible && this.collapsed) {
      this.displayItems = this.form.form.controls.slice(0, this.maxDisplayInitial) as FormGroup[];
    } else {
      this.displayItems = this.form.form.controls as FormGroup[];
    }

    this.cd.markForCheck();
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }

  public isToggledAll(): boolean {
    return this.form.isToggledAll();
  }

  public toggleAll() {
    this.form.toggleAll();
  }

  public isEmpty() {
    return this.displayItems.length == 0;
  }

  clearSearch() {
    if (!isSet(this.search)) {
      return;
    }

    this.search = '';
    this.searchUpdated.next();
    this.cd.markForCheck();
    this.searchCleared.emit();
  }

  onSearchBlur() {
    if (!isSet(this.search)) {
      this.searchCleared.emit();
    }
  }

  focusSearch() {
    if (this.searchAutoFocus) {
      this.searchAutoFocus.focus();
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import { FieldType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import {
  Environment,
  Project,
  Resource,
  ResourceType,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import {
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ModelDescriptionSimpleQuery,
  QueryType
} from '@modules/queries';
import {
  ResourceControllerService,
  ResourceParamsResult,
  SMART_SUITE_ACCOUNT_ID_PARAM,
  SMART_SUITE_APPLICATION_ID_PARAM,
  SMART_SUITE_ID_FIELD_NAME,
  SMART_SUITE_SOLUTION_ID_PARAM,
  SmartSuiteApplicationField,
  SmartSuiteApplicationFieldType,
  SmartSuiteApplicationResponse,
  SmartSuiteResourceController,
  SOURCE_FIELD_TYPE
} from '@modules/resources';
import { forceObservable } from '@shared';

import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import {
  defaultSmartSuiteFieldMapping,
  smartSuiteFieldMapping,
  SmartSuiteFieldMappingFieldWithParams
} from './smart-suite-field-mapping';

export interface SmartSuiteParamsOptions {
  accountId: string;
  key: string;
  solutionId: string;
}

@Injectable()
export class SmartSuiteGeneratorService extends ResourceGeneratorService<SmartSuiteParamsOptions> {
  tokenName = 'api_key';
  controller: SmartSuiteResourceController;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private resourceControllerService: ResourceControllerService,
    private secretTokenService: SecretTokenService,
    private apiService: ApiService
  ) {
    super();
    this.controller = this.resourceControllerService.get<SmartSuiteResourceController>(ResourceType.SmartSuite);
  }

  isOptionsValid(options: SmartSuiteParamsOptions): Observable<IsOptionsValidResult> {
    return this.controller.getApplications(options).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('API Key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<SmartSuiteParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        this.tokenName,
        this.mode == AdminMode.Builder
      )
      .pipe(
        catchError(() => of(undefined)),
        map((secretToken: SecretToken) => {
          return {
            key: secretToken.value,
            accountId: resource.params[SMART_SUITE_ACCOUNT_ID_PARAM],
            solutionId: resource.params[SMART_SUITE_SOLUTION_ID_PARAM]
          };
        })
      );
  }

  getModelDescriptions(accountId: string, key: string, solutionId: string): Observable<ModelDescription[]> {
    return this.controller.getApplications({ accountId: accountId, key: key, solutionId: solutionId }).pipe(
      switchMap(applications => {
        if (!applications.length) {
          return of([]);
        }

        return combineLatest(applications.map(application => this.getModelDescription(application, applications)));
      })
    );
  }

  getModelDescription(
    application: SmartSuiteApplicationResponse,
    applications: SmartSuiteApplicationResponse[]
  ): Observable<ModelDescription> {
    return this.getModelDescriptionFields(application, applications).pipe(
      map(fields => {
        const modelDescription = new ModelDescription();

        modelDescription.project = '{{project}}';
        modelDescription.resource = '{{resource}}';
        modelDescription.model = application.slug;
        modelDescription.verboseName = application.name;
        modelDescription.verboseNamePlural = application.name;
        modelDescription.primaryKeyField = SMART_SUITE_ID_FIELD_NAME;
        modelDescription.displayField = application.primary_field;
        modelDescription.params = {
          [SMART_SUITE_APPLICATION_ID_PARAM]: application.id
        };

        modelDescription.fields = fields;

        const getSimpleQuery = new ModelDescriptionSimpleQuery();
        getSimpleQuery.model = modelDescription.model;

        modelDescription.getQuery = new ListModelDescriptionQuery();
        modelDescription.getQuery.queryType = QueryType.Simple;
        modelDescription.getQuery.simpleQuery = getSimpleQuery;
        modelDescription.getQuery.sortingFields = modelDescription.dbFields.map(item => {
          return {
            name: item.name,
            sortable: ![SMART_SUITE_ID_FIELD_NAME].includes(item.name)
          };
        });

        const getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
        getDetailSimpleQuery.model = modelDescription.model;

        modelDescription.getDetailQuery = new ModelDescriptionQuery();
        modelDescription.getDetailQuery.queryType = QueryType.Simple;
        modelDescription.getDetailQuery.simpleQuery = getDetailSimpleQuery;
        modelDescription.getDetailParametersUseDefaults = true;

        const createSimpleQuery = new ModelDescriptionSimpleQuery();
        createSimpleQuery.model = modelDescription.model;

        modelDescription.createQuery = new ModelDescriptionQuery();
        modelDescription.createQuery.queryType = QueryType.Simple;
        modelDescription.createQuery.simpleQuery = createSimpleQuery;
        modelDescription.createParametersUseDefaults = true;

        const updateSimpleQuery = new ModelDescriptionSimpleQuery();
        updateSimpleQuery.model = modelDescription.model;

        modelDescription.updateQuery = new ModelDescriptionQuery();
        modelDescription.updateQuery.queryType = QueryType.Simple;
        modelDescription.updateQuery.simpleQuery = updateSimpleQuery;
        modelDescription.updateParametersUseDefaults = true;

        const deleteSimpleQuery = new ModelDescriptionSimpleQuery();
        deleteSimpleQuery.model = modelDescription.model;

        modelDescription.deleteQuery = new ModelDescriptionQuery();
        modelDescription.deleteQuery.queryType = QueryType.Simple;
        modelDescription.deleteQuery.simpleQuery = deleteSimpleQuery;
        modelDescription.deleteParametersUseDefaults = true;

        return modelDescription;
      })
    );
  }

  getModelDescriptionFields(
    application: SmartSuiteApplicationResponse,
    applications: SmartSuiteApplicationResponse[]
  ): Observable<ModelField[]> {
    if (!application.structure.length) {
      return of([]);
    }

    return combineLatest(
      application.structure.map(field => this.getModelDescriptionField(application, field, applications))
    ).pipe(
      map(fields => {
        const primaryKey = new ModelField();
        const dbField = new ModelDbField();

        dbField.name = SMART_SUITE_ID_FIELD_NAME;
        dbField.verboseName = 'id';
        dbField.field = FieldType.Text;
        dbField.editable = false;
        dbField.params = {
          [SOURCE_FIELD_TYPE]: SmartSuiteApplicationFieldType.TextField
        };
        dbField.updateFieldDescription();

        primaryKey.name = dbField.name;
        primaryKey.type = ModelFieldType.Db;
        primaryKey.item = dbField;

        return [primaryKey, ...fields];
      })
    );
  }

  getModelDescriptionField(
    application: SmartSuiteApplicationResponse,
    item: SmartSuiteApplicationField,
    applications: SmartSuiteApplicationResponse[]
  ): Observable<ModelField> {
    const result = new ModelField();
    const dbField = new ModelDbField();

    dbField.name = item.slug;
    dbField.verboseName = item.label;
    dbField.field = FieldType.Text;
    dbField.required = item.params.required;
    dbField.editable = ![
      SmartSuiteApplicationFieldType.AutoNumberField,
      SmartSuiteApplicationFieldType.CommentsCountField,
      SmartSuiteApplicationFieldType.FirstCreatedField,
      SmartSuiteApplicationFieldType.LastUpdatedField
    ].includes(item.field_type);
    dbField.updateFieldDescription();

    result.name = item.slug;
    result.type = ModelFieldType.Db;
    result.item = dbField;

    const mapping = smartSuiteFieldMapping.find(i => i.type == item.field_type);
    const resultMapping = mapping ? mapping.mapping : defaultSmartSuiteFieldMapping;
    let fieldWithParams: Observable<SmartSuiteFieldMappingFieldWithParams>;

    if (resultMapping.getField) {
      fieldWithParams = forceObservable(
        resultMapping.getField({
          name: item.slug,
          field: item,
          applications: applications
        })
      );
    } else if (resultMapping.getParams) {
      fieldWithParams = forceObservable(
        resultMapping.getParams({
          name: item.slug,
          field: item,
          applications: applications
        })
      ).pipe(
        map(params => {
          return {
            field: resultMapping.field,
            params: params
          };
        })
      );
    } else {
      fieldWithParams = of({
        field: resultMapping.field,
        params: resultMapping.params
      });
    }

    return fieldWithParams.pipe(
      map(({ field, params }) => {
        result.item.field = field;
        result.item.params = {
          ...params,
          [SOURCE_FIELD_TYPE]: item.field_type
        };
        return result;
      })
    );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: SmartSuiteParamsOptions
  ): Observable<ResourceParamsResult> {
    return this.getModelDescriptions(options.accountId, options.key, options.solutionId).pipe(
      map((modelDescriptions: ModelDescription[]) => {
        const token = new SecretToken();

        token.resource = '{{resource}}';
        token.name = this.tokenName;
        token.type = SecretTokenType.Static;
        token.value = options.key;

        const resourceParams = {
          [SMART_SUITE_ACCOUNT_ID_PARAM]: options.accountId,
          [SMART_SUITE_SOLUTION_ID_PARAM]: options.solutionId
        };

        return {
          resourceParams: resourceParams,
          modelDescriptions: modelDescriptions.map(item => item.serialize()),
          secretTokens: [token.serialize()]
        };
      })
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { WorkflowExecuteEvent } from '@modules/action-queries';
import { ViewContextElement } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { Workflow, WorkflowRun } from '@modules/workflow';

export interface CreatedElement {
  element: any;
  contextElement?: ViewContextElement;
}

export interface WorkflowState {
  workflow: Workflow;
  parameters: ParameterField[];
}

@Injectable()
export class WorkflowEditContext implements OnDestroy {
  public testExecuteEvents$ = new Subject<WorkflowExecuteEvent>();
  public run$ = new BehaviorSubject<WorkflowRun>(undefined);
  public state: WorkflowState;

  private history$ = new BehaviorSubject<WorkflowState[]>([]);
  private historyIndex$ = new BehaviorSubject<number>(0);
  private historyRecord$ = new Subject<void>();
  private createdElements: CreatedElement[] = [];

  constructor() {
    this.historyRecord$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => this.recordHistory());
  }

  ngOnDestroy(): void {}

  init(state: WorkflowState) {
    this.state = state;
    this.history$.next([cloneDeep(state)]);
    this.historyIndex$.next(0);
  }

  markChanged() {
    this.historyRecord$.next();
  }

  state$(): Observable<WorkflowState> {
    return combineLatest(this.history$, this.historyIndex$).pipe(
      map(([history, historyIndex]) => {
        return history[historyIndex];
      })
    );
  }

  recordHistory() {
    const history = [...this.history$.value.slice(0, this.historyIndex$.value + 1), cloneDeep(this.state)];
    this.history$.next(history);
    this.historyIndex$.next(history.length - 1);
  }

  applyHistory(index: number) {
    const state = this.history$.value[index];

    if (!state) {
      return;
    }

    this.state.workflow.deserialize(state.workflow.serialize());
    this.state.parameters.splice(0, this.state.parameters.length, ...state.parameters);

    this.historyIndex$.next(index);
  }

  isHistoryAvailable$(delta: number): Observable<boolean> {
    return combineLatest(this.history$, this.historyIndex$).pipe(
      map(([history, historyIndex]) => {
        return history[historyIndex + delta] !== undefined;
      })
    );
  }

  undo() {
    this.applyHistory(this.historyIndex$.value - 1);
  }

  redo() {
    this.applyHistory(this.historyIndex$.value + 1);
  }

  isUndoAvailable$(): Observable<boolean> {
    return this.isHistoryAvailable$(-1);
  }

  isRedoAvailable$(): Observable<boolean> {
    return this.isHistoryAvailable$(1);
  }

  registerCreatedElement(element: any, options: { contextElement?: ViewContextElement } = {}) {
    this.createdElements.push({
      element: element,
      contextElement: options.contextElement
    });
  }

  updateCreatedElement(element: any, options: { contextElement?: ViewContextElement } = {}): CreatedElement {
    const createdElement = this.createdElements.find(item => item.element === element);

    if (!createdElement) {
      return;
    }

    this.createdElements = this.createdElements.map(item => {
      if (item === createdElement) {
        if (options.contextElement) {
          item.contextElement = options.contextElement;
        }
      }

      return item;
    });
    return createdElement;
  }

  initCreatedElement(element: any): CreatedElement {
    const createdElement = this.createdElements.find(item => item.element === element);

    if (!createdElement) {
      return;
    }

    this.createdElements = this.createdElements.filter(item => item !== createdElement);
    return createdElement;
  }
}

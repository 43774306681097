/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i2 from "../interactions-section-cursor-dropdown/interactions-section-cursor-dropdown.component.ngfactory";
import * as i3 from "../interactions-section-cursor-dropdown/interactions-section-cursor-dropdown.component";
import * as i4 from "../compact-wide-button/compact-wide-button.component.ngfactory";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "../compact-wide-button/compact-wide-button.component";
import * as i7 from "./interactions-section-cursor.component";
var styles_InteractionsSectionCursorComponent = [];
var RenderType_InteractionsSectionCursorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InteractionsSectionCursorComponent, data: {} });
export { RenderType_InteractionsSectionCursorComponent as RenderType_InteractionsSectionCursorComponent };
export function View_InteractionsSectionCursorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-interactions-section-cursor-dropdown", [], null, [[null, "selected"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.control.controls.cursor.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_InteractionsSectionCursorDropdownComponent_0, i2.RenderType_InteractionsSectionCursorDropdownComponent)), i0.ɵdid(2, 770048, null, 0, i3.InteractionsSectionCursorDropdownComponent, [i0.ChangeDetectorRef], { value: [0, "value"], origin: [1, "origin"], forceOpened: [2, "forceOpened"] }, { selected: "selected", close: "close" }), (_l()(), i0.ɵeld(3, 0, null, null, 3, "app-compact-wide-button", [["cdkOverlayOrigin", ""]], null, [[null, "click"], [null, "rightClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } if (("rightClick" === en)) {
        var pd_1 = (_co.arrayControl.removeControl(_co.control) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_CompactWideButtonComponent_0, i4.RenderType_CompactWideButtonComponent)), i0.ɵdid(4, 16384, [["trigger", 4]], 0, i5.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(5, 114688, null, 0, i6.CompactWideButtonComponent, [], { label: [0, "label"], additional: [1, "additional"], interactive: [2, "interactive"], leftIcon: [3, "leftIcon"], leftBackground: [4, "leftBackground"], rightIcon: [5, "rightIcon"], rightInteractive: [6, "rightInteractive"], rightTooltip: [7, "rightTooltip"] }, { rightClick: "rightClick" }), i0.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.cursor.value; var currVal_1 = i0.ɵnov(_v, 4); var currVal_2 = _co.dropdownOpened; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Cursor"; var currVal_4 = i0.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.control.controls.cursor.value)); var currVal_5 = true; var currVal_6 = "cursor"; var currVal_7 = true; var currVal_8 = "close"; var currVal_9 = true; var currVal_10 = "Switch to default"; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
export function View_InteractionsSectionCursorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-interactions-section-cursor", [], null, null, null, View_InteractionsSectionCursorComponent_0, RenderType_InteractionsSectionCursorComponent)), i0.ɵdid(1, 245760, null, 0, i7.InteractionsSectionCursorComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractionsSectionCursorComponentNgFactory = i0.ɵccf("app-interactions-section-cursor", i7.InteractionsSectionCursorComponent, View_InteractionsSectionCursorComponent_Host_0, { control: "control", arrayControl: "arrayControl" }, {}, []);
export { InteractionsSectionCursorComponentNgFactory as InteractionsSectionCursorComponentNgFactory };

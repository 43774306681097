import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  Injector,
  Type,
  ViewContainerRef
} from '@angular/core';
import keys from 'lodash/keys';
import { Observable } from 'rxjs';

export interface DynamicComponentArguments<T = any> {
  component: Type<T>;
  inputs?: Partial<T>;
  outputs?: { [P in keyof T]?: ((...args) => any)[] };
  providers?: any[];
  injector?: Injector;
  resolver?: ComponentFactoryResolver;
  disablePointerEvents?: boolean;
  modal?: boolean;
  closeRequested?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService {
  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef) {}

  setBindings<T>(componentRef: ComponentRef<T>, data: DynamicComponentArguments) {
    const component = componentRef.instance;
    const componentName = component.constructor ? component.constructor.name : component.toString();

    if (data.inputs) {
      for (const key of keys(data.inputs)) {
        component[key] = data.inputs[key];
      }
    }

    if (data.outputs) {
      for (const key of keys(data.outputs)) {
        if (!component.hasOwnProperty(key)) {
          console.error(`No such @Output "${key}" in "${componentName}"`);
          continue;
        } else if (!(component[key] instanceof Observable)) {
          console.error(`"${key}" is not a valid @Output in ${componentName}`);
          continue;
        } else if (!component[key]) {
          console.error(`"${key}" @Output is set to ${component[key]} in ${componentName}`);
          continue;
        }

        const output: EventEmitter<any> = component[key];
        output.subscribe.apply(output, data.outputs[key]);
      }
    }
  }

  createComponent<T>(parentInjector: Injector, data: DynamicComponentArguments, container?: Element): ComponentRef<T> {
    const factory = (data.resolver || this.resolver).resolveComponentFactory<T>(data.component);
    const injector = Injector.create({
      providers: data.providers || [],
      parent: parentInjector
    });

    if (container) {
      const newNode = document.createElement(factory.selector);
      container.appendChild(newNode);
      container = newNode;
    }

    const componentRef = factory.create(injector, undefined, container);

    this.setBindings(componentRef, data);

    if (container) {
      this.appRef.attachView(componentRef.hostView);
    }

    return componentRef;
  }

  createComponentInViewContainer<T>(
    parentInjector: Injector,
    data: DynamicComponentArguments,
    container: ViewContainerRef,
    index?: number
  ): ComponentRef<T> {
    const factory = (data.resolver || this.resolver).resolveComponentFactory<T>(data.component);
    const injector = Injector.create({
      providers: data.providers || [],
      parent: parentInjector
    });

    const componentRef = container.createComponent(factory, index, injector);

    this.setBindings(componentRef, data);

    return componentRef;
  }

  appendComponentToElement<T>(parentInjector: Injector, data: DynamicComponentArguments, el: Element) {
    const componentRef = this.createComponent<T>(parentInjector, data, el);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    el.appendChild(domElem);

    return componentRef;
  }

  appendComponentToBody<T>(parentInjector: Injector, data: DynamicComponentArguments) {
    return this.appendComponentToElement<T>(parentInjector, data, document.body);
  }
}

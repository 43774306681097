import isArray from 'lodash/isArray';

export function coerceArray<T = any>(obj: T | T[]): T[] {
  return isArray(obj) ? obj : [obj];
}

export function moveInArray(arr: any[], obj: any, index: number) {
  const otherItems = arr.filter(item => item !== obj);
  return [...otherItems.slice(0, index), obj, ...otherItems.slice(index)];
}

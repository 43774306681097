import { Injectable, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';

import { Approve } from '@modules/actions';
import { TaskQueue, TaskQueueStore } from '@modules/collaboration';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, isRequiredInputsSet } from '@modules/fields';
import {
  FieldInputControl,
  FieldInputRequiredValidator,
  InputFieldProvider,
  InputFieldProviderItem,
  parametersToProviderItems
} from '@modules/parameters';
import { controlValid, controlValue } from '@shared';

@Injectable()
export class CustomizeBarApproveEditForm implements OnDestroy {
  control: FormControl;
  form = new FormGroup({
    task_queue: new FormControl(null, Validators.required),
    task_name: new FieldInputControl({ name: 'value' }, FieldInputRequiredValidator),
    task_inputs: new FormControl([], this.validateInputs()),
    task_create_status: new FormControl('', Validators.required),
    task_approve_status: new FormControl(''),
    task_reject_status: new FormControl(''),
    task_assignee: new FormControl(null),
    on_task_create_actions: new FormControl([], undefined, this.validateActions()),
    on_reject_actions: new FormControl([], undefined, this.validateActions())
  });

  context: ViewContext;
  contextElement: ViewContextElement;
  inputFieldProvider = new InputFieldProvider();

  constructor(private taskQueueStore: TaskQueueStore, public elementConfigurationService: ElementConfigurationService) {
    this.form.controls['task_queue'].valueChanges.subscribe(() => {
      this.onQueueChange();
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  validateInputs(): ValidatorFn {
    return control => {
      if (!this.form) {
        return;
      }

      const fields = this.inputFieldProvider.fields;
      const inputs: Input[] = control.value;

      if (!isRequiredInputsSet(fields, inputs)) {
        return { required: true };
      }
    };
  }

  validateActions(): AsyncValidatorFn {
    return control => {
      if (!this.form) {
        return of(null);
      }

      if (!control.value || !control.value.length) {
        return of(null);
      }

      return combineLatest(control.value.map(item => this.elementConfigurationService.isActionConfigured(item))).pipe(
        map(result => {
          if (result.some(configured => !configured)) {
            return { required: true };
          }
        })
      );
    };
  }

  init(
    control: FormControl,
    context: ViewContext,
    contextElement: ViewContextElement,
    firstInit = false
  ): Observable<void> {
    this.control = control;
    this.context = context;
    this.contextElement = contextElement;

    const value = control.value as Approve;
    const queueObs = value ? this.taskQueueStore.getDetailFirst(value.taskQueue) : of(undefined);

    return queueObs.pipe(
      map(queue => {
        if (value) {
          const mapInputToValue = (item: Input): Object => {
            return item
              ? {
                  name: item.name,
                  value_type: item.valueType,
                  static_value: item.staticValue,
                  context_value: item.contextValue,
                  filter_field: item.filterField,
                  filter_lookup: item.filterLookup,
                  formula_value: item.formulaValue
                }
              : {};
          };

          this.form.patchValue(
            {
              task_queue: queue,
              task_name: mapInputToValue(value.taskName),
              task_inputs: value.taskInputs,
              task_assignee: value.taskAssignee,
              task_create_status: value.taskCreateStatus,
              task_approve_status: value.taskApproveStatus,
              task_reject_status: value.taskRejectStatus,
              on_task_create_actions: value.onTaskCreateActions,
              on_reject_actions: value.onRejectActions
            },
            { emitEvent: false }
          );

          this.updateInputFieldProvider().subscribe();
        }

        if (!firstInit) {
          this.form.markAsDirty();
        }
      })
    );
  }

  updateInputFieldProvider() {
    return combineLatest(controlValue<TaskQueue>(this.form.controls['task_queue'])).pipe(
      first(),
      map(([queue]): InputFieldProviderItem[] => {
        return queue ? parametersToProviderItems(queue.parameters) : [];
      }),
      tap(items => {
        this.inputFieldProvider.setProvider(items);
      })
    );
  }

  controlsValid$(controls: string[]): Observable<boolean> {
    return combineLatest(controls.map(item => controlValid(this.form.controls[item]))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  onQueueChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  selectQueue(queue: TaskQueue) {
    this.form.patchValue({
      task_queue: queue
    });
    this.form.markAsDirty();
  }

  submit(): Approve {
    const mapValueToInput = (item: Object): Input => {
      const result = new Input();

      result.name = item['name'];
      result.valueType = item['value_type'];
      result.staticValue = item['static_value'];
      result.contextValue = item['context_value'];
      result.filterField = item['filter_field'];
      result.filterLookup = item['filter_lookup'];
      result.formulaValue = item['formula_value'];

      return result;
    };

    const value = this.form.value;
    const instance: Approve = this.control.value || new Approve();

    instance.taskName = mapValueToInput(value['task_name']);
    instance.taskInputs = value['task_inputs'];
    instance.taskQueue = value['task_queue'] ? value['task_queue'].uid : undefined;
    instance.taskAssignee = value['task_assignee'] || undefined;
    instance.taskCreateStatus = value['task_create_status'] || undefined;
    instance.taskApproveStatus = value['task_approve_status'] || undefined;
    instance.taskRejectStatus = value['task_reject_status'] || undefined;
    instance.onTaskCreateActions = value['on_task_create_actions'] || [];
    instance.onRejectActions = value['on_reject_actions'] || [];

    return instance;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import range from 'lodash/range';

@Pipe({
  name: 'appRange'
})
export class RangePipe implements PipeTransform {
  transform(value: number): any {
    return range(value);
  }
}

import * as i0 from "@angular/core";
var LayoutStore = /** @class */ (function () {
    function LayoutStore() {
    }
    LayoutStore.prototype.layoutKey = function (key) {
        return ['layout', key].join('_');
    };
    LayoutStore.prototype.getCurrentLayout = function (key) {
        return localStorage[this.layoutKey(key)];
    };
    LayoutStore.prototype.setCurrentLayout = function (key, layout) {
        var layoutKey = this.layoutKey(key);
        localStorage[layoutKey] = layout;
    };
    LayoutStore.prototype.resetCurrentLayout = function (key) {
        var layoutKey = this.layoutKey(key);
        if (localStorage[layoutKey] != undefined) {
            delete localStorage[layoutKey];
        }
    };
    LayoutStore.ngInjectableDef = i0.defineInjectable({ factory: function LayoutStore_Factory() { return new LayoutStore(); }, token: LayoutStore, providedIn: "root" });
    return LayoutStore;
}());
export { LayoutStore };

<div
  class="list"
  [class.list_scrollable]="scrollable"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport list__viewport_bounds" appElementResize (elementResize)="onResize()" #viewport_element>
      <app-error *ngIf="!configured" [title]="'Not configured' | localize">
        Unfortunately, this component is not configured yet
      </app-error>

      <ng-container *ngIf="configured">
        <ng-container *ngIf="(settings.groupField | appIsSet) && listState.groups?.length">
          <ng-container *ngFor="let group of listState.groups; trackBy: trackGroupFn; let i = index; let last = last">
            <app-grid-group
              [group]="group"
              [groupIndex]="i"
              [groupLast]="last"
              [listState]="listState"
              [title]="title"
              [modelDescription]="listState.modelDescription"
              [visibleColumns]="visibleColumns"
              [rowCards]="rowCards"
              [view]="cardView$ | async"
              [gridWidth]="gridWidth"
              [cardDefaultHeight]="cardDefaultHeight"
              [selectedItem]="selectedItem"
              [checkedItems]="checkedItems"
              [settings]="settings"
              [scrollable]="scrollable"
              [context]="context"
              [contextElement]="contextElement"
              [viewId]="viewId"
              [accentColor]="accentColor"
              (fetchStarted)="onFetch()"
              (fetchFinished)="onFetched($event)"
              (modelUpdated)="onModelUpdated($event)"
              (updateSelected)="setSelectedItem($event)"
              (selectToggle)="toggleSelectedItem($event.item, i, $event.index, $event.element, true)"
              #group_component
            ></app-grid-group>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!(settings.groupField | appIsSet) || !listState.groups?.length">
          <app-grid-group
            [groupIndex]="0"
            [listState]="listState"
            [title]="title"
            [modelDescription]="listState.modelDescription"
            [visibleColumns]="visibleColumns"
            [rowCards]="rowCards"
            [view]="cardView$ | async"
            [gridWidth]="gridWidth"
            [cardDefaultHeight]="cardDefaultHeight"
            [selectedItem]="selectedItem"
            [checkedItems]="checkedItems"
            [settings]="settings"
            [scrollable]="scrollable"
            [context]="context"
            [contextElement]="contextElement"
            [viewId]="viewId"
            [accentColor]="accentColor"
            (fetchStarted)="onFetch()"
            (fetchFinished)="onFetched($event)"
            (modelUpdated)="onModelUpdated($event)"
            (updateSelected)="setSelectedItem($event)"
            (selectToggle)="toggleSelectedItem($event.item, 0, $event.index, $event.element, true)"
            #group_component
          ></app-grid-group>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ActionItemArray } from './action-item.array';
import { PageQueryArray } from './page-query-array.service';
export var validateActions = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value || !control.value.length) {
        return of(null);
    }
    return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
        if (result.some(function (configured) { return !configured; })) {
            return { required: true };
        }
    }));
};
var CustomPageQueriesForm = /** @class */ (function (_super) {
    __extends(CustomPageQueriesForm, _super);
    function CustomPageQueriesForm(elementConfigurationService, customizeBarModelDataArray) {
        var _this = _super.call(this, {
            queries: customizeBarModelDataArray,
            open_actions: new ActionItemArray([], undefined, validateActions)
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomPageQueriesForm.prototype.init = function (viewSettings) {
        this.viewSettings = viewSettings;
        if (viewSettings) {
            this.controls.queries.deserialize(viewSettings.queries);
            this.controls.open_actions.deserialize(viewSettings.openActions);
        }
    };
    return CustomPageQueriesForm;
}(FormGroup));
export { CustomPageQueriesForm };

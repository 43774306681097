import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { applyBooleanInput$ } from '@modules/fields';
import {
  MenuBlock,
  MenuBlockLayout,
  MenuBlockLayouts,
  MenuService,
  MenuSettings,
  MenuSettingsStore
} from '@modules/menu';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { ProjectGroupStore } from '@modules/projects';
import { getWindowScrollTop } from '@shared';

import { MenuContext } from '../../data/menu-context';
import { MenuPrimaryComponent } from '../menu-primary/menu-primary.component';

const markMenuClickProperty = '_markMenuClickProperty';

function markMenuClick(clickEvent: MouseEvent) {
  clickEvent[markMenuClickProperty] = true;
}

function isMenuClick(clickEvent: MouseEvent) {
  return !!clickEvent[markMenuClickProperty];
}

@Component({
  selector: 'app-menu-wrapper',
  templateUrl: './menu-wrapper.component.html',
  providers: [
    MenuContext,
    {
      provide: ViewContextTokenProvider,
      useFactory: (menuContext: MenuContext, projectGroupStore: ProjectGroupStore) => {
        return new ViewContextTokenProvider(menuContext, projectGroupStore);
      },
      deps: [MenuContext, ProjectGroupStore]
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() title: string;
  @Input() desktopMenu = false;
  @Input() mobileMenu = false;
  @Input() mobileOpened = false;

  @ViewChild(MenuPrimaryComponent) primary: MenuPrimaryComponent;
  @ViewChild('mobile_menu_background') mobileMenuBackgroundElement: ElementRef;

  menuSettings: MenuSettings;
  topMenuBlocks: MenuBlock[];
  topContentMenuBlocks: MenuBlock[];
  leftMenuBlocks: MenuBlock[];
  mobileMenuBlocks: MenuBlock[];
  scrolled = false;
  menuBlockLayouts = MenuBlockLayouts;
  allSettings$: Observable<AllProjectSettings>;

  trackMenuBlockFn(i, item: MenuBlock) {
    return item.uid;
  }

  constructor(
    private context: MenuContext,
    public menuSettingsStore: MenuSettingsStore,
    private projectSettingsStore: ProjectSettingsStore,
    public menuService: MenuService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.menuSettingsStore
      .get()
      .pipe(
        catchError(() => of<MenuSettings>(undefined)),
        switchMap(menuSettings => {
          if (!menuSettings) {
            return of({ menuSettings: undefined, blocks: undefined });
          }

          const $blocks = menuSettings.blocks.map(block => {
            if (block.enabledInput) {
              return applyBooleanInput$(block.enabledInput, {
                context: this.context
              }).pipe(map(enabled => ({ block: block, enabled: enabled })));
            } else {
              return of({ block: block, enabled: block.enabled });
            }
          });

          if (!$blocks.length) {
            return of({ menuSettings: menuSettings, blocks: [] });
          }

          return combineLatest($blocks).pipe(
            map(blocks => {
              return {
                menuSettings: menuSettings,
                blocks: blocks.filter(item => item.enabled).map(item => item.block)
              };
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.menuSettings = value.menuSettings;

        const menuBlocks = this.menuSettings ? value.blocks : undefined;

        this.leftMenuBlocks = menuBlocks ? menuBlocks.filter(item => MenuBlockLayouts.isLeft(item.layout)) : undefined;
        this.topMenuBlocks = menuBlocks ? menuBlocks.filter(item => item.layout == MenuBlockLayout.TopThin) : undefined;
        this.topContentMenuBlocks = menuBlocks
          ? menuBlocks.filter(item => item.layout == MenuBlockLayout.TopContentThin)
          : undefined;
        this.mobileMenuBlocks = menuBlocks ? this.getMobileMenuBlocks(menuBlocks) : undefined;
        this.cd.markForCheck();
      });

    this.allSettings$ = this.projectSettingsStore.getAllSettings$();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll', { passive: true })
      .pipe(map(() => getWindowScrollTop()))
      .pipe(
        startWith(getWindowScrollTop()),
        map(() => getWindowScrollTop() > 0),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(scrolled => {
        this.scrolled = scrolled;
        this.cd.markForCheck();
      });
  }

  asMenuBlocks(value: any): MenuBlock[] {
    return value as MenuBlock[];
  }

  getMobileMenuBlocks(desktopBlocks: MenuBlock[]): MenuBlock[] {
    const wideBlock = desktopBlocks.find(item => item.layout == MenuBlockLayout.LeftWide) || desktopBlocks[0];

    return desktopBlocks.slice(0, 2).map(desktopBlock => {
      const mobileBlock = desktopBlock.clone();
      mobileBlock.layout = desktopBlock === wideBlock ? MenuBlockLayout.LeftWide : MenuBlockLayout.LeftThin;
      return mobileBlock;
    });
  }

  toggleMenuOpened() {
    this.menuService.toggleOpened();
  }

  onMobileMenuBackgroundClick(e: MouseEvent) {
    if (!isMenuClick(e)) {
      this.menuService.opened = false;
    }
  }

  onMobileMenuClick(e: MouseEvent) {
    markMenuClick(e);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./resource-summary.component";
import * as i3 from "../../../../common/popups/components/base-popup/base-popup.component";
var styles_ResourceSummaryComponent = [];
var RenderType_ResourceSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceSummaryComponent, data: {} });
export { RenderType_ResourceSummaryComponent as RenderType_ResourceSummaryComponent };
function View_ResourceSummaryComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "card-product__descr"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["class", "loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 3, 0, currVal_0); }); }
function View_ResourceSummaryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "card-grid__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "card-product"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "card-product__icon loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "stub-image stub-image_contrast"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "card-product__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_0); }); }
function View_ResourceSummaryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "project-resource-setting__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "card-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "card-grid__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "card-grid__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "card-grid__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_3)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_0); }); }
function View_ResourceSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "project-resource-setting__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "project-resource-setting__logo"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "project-resource-setting__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Components imported"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_2)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "project-resource-setting__footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "project-resource-setting__footer-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "button button_primary"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Continue "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.stubTabs; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.resource.icon) + ")"); _ck(_v, 2, 0, currVal_0); }); }
function View_ResourceSummaryComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "border-tabs__item"], ["href", "javascript:void(0)"]], [[2, "border-tabs__item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTab(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentTab === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_ResourceSummaryComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "card-product__descr"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceSummaryComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "card-grid__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "card-product"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "card-product__icon"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "card-product__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_9)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "card-product__icon"; var currVal_1 = _ck(_v, 4, 0, ("card-product__icon_" + (_v.context.$implicit.icon || "collection"))); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_ResourceSummaryComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "project-resource-setting__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "card-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "card-grid__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "card-grid__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "card-grid__counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["(", ")"])), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["section_grid", 1]], null, 2, "div", [["class", "card-grid__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_8)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.items; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.items.length; _ck(_v, 6, 0, currVal_1); }); }
function View_ResourceSummaryComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "project-resource-setting__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "project-resource-setting__logo"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "project-resource-setting__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["Use ", " components to build your App"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "project-resource-setting__tabs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "border-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_6)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_7)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "project-resource-setting__footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "project-resource-setting__footer-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "a", [["class", "button button_primary"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Continue "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tabs; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.tabs; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.resource.icon) + ")"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.resource.name; _ck(_v, 4, 0, currVal_1); }); }
export function View_ResourceSummaryComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { sectionGridElements: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "project-resource-setting"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceSummaryComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ResourceSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-resource-summary", [], null, null, null, View_ResourceSummaryComponent_0, RenderType_ResourceSummaryComponent)), i0.ɵdid(1, 4440064, null, 0, i2.ResourceSummaryComponent, [i3.BasePopupComponent, i0.ElementRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceSummaryComponentNgFactory = i0.ɵccf("app-resource-summary", i2.ResourceSummaryComponent, View_ResourceSummaryComponent_Host_0, { resource: "resource", tabs: "tabs" }, { closed: "closed" }, []);
export { ResourceSummaryComponentNgFactory as ResourceSummaryComponentNgFactory };

export enum cropFormats {
  Original = 'original',
  Custom = 'custom',
  Square = '1:1',
  Format_5x4 = '5:4',
  Format_4x3 = '4:3',
  Format_3x2 = '3:2',
  Format_7x5 = '7:5',
  Format_16x9 = '16:9'
}

export const imageFieldCrops = [
  { value: undefined, name: 'None' },
  { value: cropFormats.Square, name: 'Square' },
  { value: cropFormats.Format_5x4, name: '5:4' },
  { value: cropFormats.Format_4x3, name: '4:3' },
  { value: cropFormats.Format_3x2, name: '3:2' },
  { value: cropFormats.Format_7x5, name: '7:5' },
  { value: cropFormats.Format_16x9, name: '16:9' },
  { value: cropFormats.Original, name: 'Original' },
  { value: cropFormats.Custom, name: 'Custom' }
];

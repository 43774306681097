import { Injectable } from '@angular/core';
import keys from 'lodash/keys';
import { merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionStorage {
  private _updated$ = new Subject<void>();

  constructor() {}

  public get(key: string): string {
    return sessionStorage.getItem(key);
  }

  public get$(key: string): Observable<string> {
    return merge(of(this.get(key)), this.updated$.pipe(map(() => this.get(key)))).pipe(distinctUntilChanged());
  }

  public set(key: string, value: string) {
    sessionStorage.setItem(key, String(value));
    this._updated$.next();
  }

  public remove(key: string) {
    if (sessionStorage[key] != undefined) {
      sessionStorage.removeItem(key);
      this._updated$.next();
    }
  }

  public keys(): string[] {
    return keys(sessionStorage);
  }

  public get updated$(): Observable<void> {
    return this._updated$.asObservable();
  }
}

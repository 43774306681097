import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Power2, TimelineMax, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';

import { TintStyle } from '@modules/actions';
import { MessageUserActivity, UserActivityType } from '@modules/activities';
import { CurrentProjectStore, ProjectUserListStore } from '@modules/projects';
import { CurrentUserStore, User } from '@modules/users';
import { KeyboardEventKeyCode } from '@shared';

export interface SendEvent {
  activity: MessageUserActivity;
  mentions: string[];
}

@Component({
  selector: 'app-timeline-popup-footer',
  templateUrl: './timeline-popup-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectUserListStore]
})
export class TimelinePopupFooterComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() params = {};
  @Output() messageChange = new EventEmitter<void>();
  @Input() disabled = false;
  @Output() send = new EventEmitter<SendEvent>();
  @ViewChild('sendForm') sendForm: ElementRef<HTMLElement>;
  @ViewChild('sendFormTop') sendFormTop: ElementRef<HTMLElement>;
  @ViewChild('sendFormBottom') sendFormBottom: ElementRef<HTMLElement>;
  @ViewChild('messageInput') messageInput: ElementRef<HTMLInputElement>;

  form: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required, this.spaceValidator])
  });

  tlShowSendForm = new TimelineMax();
  projectUsers: User[] = [];
  showMentionsList = false;
  selectedMention: string;
  insertIndex: number;
  currentCursorPosition: number;
  messageInputFocused = false;
  tintStyles = TintStyle;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    public projectUserListStore: ProjectUserListStore,
    public currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectUserListStore.params = {
      not_user_uid: this.currentUserStore.original.uid
    };

    this.projectUserListStore.getNext().pipe(untilDestroyed(this)).subscribe();

    this.form.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => this.messageChange.emit());
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent<KeyboardEvent>(this.messageInput.nativeElement, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event.keyCode === KeyboardEventKeyCode.Enter && !event.shiftKey) {
          event.preventDefault();
          this.submit();
        }
      });

    // this.setSendFormPosition();
    // this.initSendFormAnimation();

    this.messageInput.nativeElement.focus();
  }

  spaceValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {
      if (control.value.trim() === '') {
        return { onlySpaces: true };
      }
    }
    return null;
  }

  initSendFormAnimation(): void {
    this.tlShowSendForm
      .clear()
      .to(
        this.sendForm.nativeElement,
        0.3,
        {
          height: 116,
          ease: Power2.easeInOut
        },
        0
      )
      .to(
        this.sendFormBottom.nativeElement,
        0.3,
        {
          x: 0,
          ease: Power2.easeInOut
        },
        0
      )
      .pause();
  }

  setSendFormPosition(): void {
    TweenMax.set(this.sendForm.nativeElement, {
      height: 58
    });
    TweenMax.set(this.sendFormBottom.nativeElement, {
      x: 180
    });
  }

  selectMention(user: User): void {
    if (!user) {
      return;
    }

    this.selectedMention = user.username.split('@')[0];
    if (this.insertIndex !== undefined) {
      const before = this.form.value.message.slice(0, this.insertIndex);
      const after = this.form.value.message.slice(this.insertIndex);

      let message: string;
      let selectionIndex: number;

      if (before[before.length - 2] === ' ' || before[before.length - 2] === undefined) {
        message = before + this.selectedMention + ' ' + after;
        selectionIndex = this.insertIndex + this.selectedMention.length + 1;
      } else {
        message = this.form.value.message.slice(0, this.insertIndex - 1) + ' @' + this.selectedMention + ' ' + after;
        selectionIndex = this.insertIndex + this.selectedMention.length + 2;
      }

      this.insertMessage(message, selectionIndex);
      this.showMentionsList = false;
      this.insertIndex = undefined;
      this.cd.markForCheck();
    }
  }

  insertMessage(message: string, selectionIndex: number) {
    this.form.patchValue({
      message: message
    });
    this.messageInput.nativeElement.focus();
    this.messageInput.nativeElement.setSelectionRange(selectionIndex, selectionIndex);
    this.cd.markForCheck();
  }

  keyUp(event: KeyboardEvent) {
    this.currentCursorPosition = this.messageInput.nativeElement.selectionStart;
    if (event.keyCode === KeyboardEventKeyCode.Shift) {
      return;
    }
    if (event.key === '@') {
      this.showMentionsList = true;
      this.insertIndex = this.messageInput.nativeElement.selectionStart;
      this.cd.markForCheck();
    } else {
      this.showMentionsList = false;
      this.insertIndex = undefined;
      this.cd.markForCheck();
    }
  }

  addAt(event: MouseEvent) {
    event.preventDefault();

    if (this.currentCursorPosition !== undefined) {
      let message: string;
      const value = this.form.value.message;

      if (this.form.value.message) {
        message = value.slice(0, this.currentCursorPosition) + '@' + value.slice(this.currentCursorPosition);
      } else {
        message = '@';
      }

      this.insertIndex = this.currentCursorPosition + 1;
      this.insertMessage(message, this.insertIndex);
      this.showMentionsList = true;
      this.cd.detectChanges();
    }
  }

  moveTextCursor() {
    this.currentCursorPosition = this.messageInput.nativeElement.selectionStart;
  }

  onFocus(value) {
    this.messageInputFocused = value;
    this.cd.markForCheck();

    if (this.messageInputFocused) {
      this.currentCursorPosition = this.messageInput.nativeElement.selectionStart;
      this.tlShowSendForm.reversed(false).resume();
    } else {
      this.cd.markForCheck();
      if (!this.messageInputFocused && !this.form.valid) {
        this.tlShowSendForm.reversed(true).resume();
      }
    }
  }

  reset() {
    this.form.reset();
  }

  focus() {
    this.messageInput.nativeElement.focus();
  }

  parseMentions(message: string): [string, string[]] {
    const mentionsRegExp = /\B@([\w\-._]+)/g;
    const mentionIds: string[] = [];
    const mentions = message.match(mentionsRegExp);

    if (mentions) {
      mentions
        .map(mention => mention.split('@').join(''))
        .forEach(mention => {
          const userMatched = this.projectUsers.find(user => user.username.split('@')[0] === mention);
          if (userMatched) {
            mentionIds.push(userMatched.uid);
            message = message.replace(`@${mention}`, `{{user.${userMatched.uid}}}`);
          }
        });
    }

    return [message, mentionIds];
  }

  submit(): void {
    this.showMentionsList = false;
    this.cd.markForCheck();

    if (!this.form.valid || this.disabled) {
      return;
    }

    const [message, mentionIds] = this.parseMentions(this.form.value.message);

    const userActivity = new MessageUserActivity();
    userActivity.activityType = UserActivityType.Message;
    userActivity.objectType = this.params['object_type'];
    userActivity.objectId = this.params['object_id'];
    userActivity.message = message.replace(/\n+/g, '\n').trim();

    this.send.emit({
      activity: userActivity,
      mentions: mentionIds
    });
  }
}

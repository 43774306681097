import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectInvite,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';

@Injectable()
export class ProjectUserForm {
  form: FormGroup;
  user: ProjectUser;

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private fb: FormBuilder
  ) {}

  init(user?: ProjectUser, groups?: ProjectGroup[]) {
    this.user = user;

    const controls = {
      group: new FormControl('', Validators.required),
      properties: new FormControl({})
    };

    if (!user || !user.userEmail) {
      controls['user_email'] = new FormControl('', Validators.required);
    }

    this.form = this.fb.group(controls);

    if (user) {
      this.form.patchValue(
        {
          user_email: user.user ? user.user.email : user.userEmail,
          group: user.group,
          properties: user.properties
        },
        { emitEvent: false }
      );
    } else if (groups && groups.length) {
      this.form.patchValue(
        {
          group: groups[0]
        },
        { emitEvent: false }
      );
    }
  }

  setGroup(group: ProjectGroup) {
    this.form.patchValue({ group: group }, { emitEvent: false, onlySelf: true });
    // this.form.markAsDirty();
  }

  isPropertiesSet() {
    return this.form.value['properties'] && !isEqual(this.form.value['properties'], {});
  }

  create() {
    this.form.markAsDirty();

    const value = this.form.value;
    const user = new ProjectUser();

    user.userEmail = value['user_email'];
    user.group = value['group'];
    user.properties = value['properties'];

    return this.projectUserService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, user)
      .pipe(
        catchError(error => {
          this.formUtils.showFormErrors(this.form, error);
          return throwError(error);
        })
      );
  }

  submit(): Observable<ProjectInvite | ProjectUser> {
    this.form.markAsDirty();

    const value = this.form.value;
    const user = new ProjectUser();

    if (this.user && this.user.uid) {
      user.uid = this.user.uid;
    } else {
      user.userEmail = value['user_email'];
    }

    user.group = value['group'];
    user.properties = value['properties'];

    let obs: Observable<ProjectInvite | ProjectUser>;

    if (this.user && this.user.uid) {
      obs = this.projectUserService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        user
      );
    } else {
      obs = this.projectUserService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        user
      );
    }

    obs.subscribe(
      () => {},
      error => this.formUtils.showFormErrors(this.form, error)
    );

    return obs;
  }

  clean() {
    this.form.controls['user_email'].reset('');
    this.form.controls['properties'].reset({});
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}

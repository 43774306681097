import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResizableHandleDirective } from './directives/resizable-handle/resizable-handle.directive';
import { ResizableDirective } from './directives/resizable/resizable.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ResizableDirective, ResizableHandleDirective],
  exports: [ResizableDirective, ResizableHandleDirective]
})
export class ResizableModule {}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i2 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i3 from "../../../../../parameters-components/components/inputs-edit/inputs-edit.component.ngfactory";
import * as i4 from "../../../../../parameters-components/components/inputs-edit/inputs-edit.form";
import * as i5 from "../../../../../parameters-components/components/inputs-edit/inputs-edit.component";
import * as i6 from "../../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i7 from "@angular/common";
import * as i8 from "./customize-bar-action-edit-parameters-query.component";
var styles_CustomizeBarActionEditParametersQueryComponent = [];
var RenderType_CustomizeBarActionEditParametersQueryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditParametersQueryComponent, data: {} });
export { RenderType_CustomizeBarActionEditParametersQueryComponent as RenderType_CustomizeBarActionEditParametersQueryComponent };
export function View_CustomizeBarActionEditParametersQueryComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-sidebar-section", [], null, null, null, i1.View_SidebarSectionComponent_0, i1.RenderType_SidebarSectionComponent)), i0.ɵdid(1, 114688, null, 0, i2.SidebarSectionComponent, [], { title: [0, "title"], description: [1, "description"] }, null), (_l()(), i0.ɵeld(2, 0, null, 3, 7, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "sidebar-list component-action-inputs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "app-inputs-edit", [], null, [[null, "addInput"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addInput" === en)) {
        var pd_0 = (_co.addQueryInput.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_InputsEditComponent_0, i3.RenderType_InputsEditComponent)), i0.ɵprd(131584, null, i4.InputsEditForm, i4.InputsEditForm, []), i0.ɵdid(6, 245760, null, 0, i5.InputsEditComponent, [i4.InputsEditForm, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], { control: [0, "control"], parameterProvider: [1, "parameterProvider"], editable: [2, "editable"], context: [3, "context"], contextElement: [4, "contextElement"], contextElementPath: [5, "contextElementPath"], contextElementPaths: [6, "contextElementPaths"], userInput: [7, "userInput"], collapseContext: [8, "collapseContext"], listWrapper: [9, "listWrapper"], displayValueTypes: [10, "displayValueTypes"], classes: [11, "classes"], object: [12, "object"] }, { addInput: "addInput" }), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpad(8, 2), i0.ɵpad(9, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Specify Inputs"; var currVal_1 = "Manually or use value from other components"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.form.controls.inputs; var currVal_3 = _co.form.inputFieldProvider; var currVal_4 = i0.ɵunv(_v, 6, 2, i0.ɵnov(_v, 7).transform(_co.form.getActionEditable$())); var currVal_5 = _co.context; var currVal_6 = _co.contextElement; var currVal_7 = _co.contextElementPath; var currVal_8 = _co.contextElementPaths; var currVal_9 = _co.userInput; var currVal_10 = _co.inputsCollapseContext; var currVal_11 = false; var currVal_12 = (_co.userInput ? _ck(_v, 8, 0, _co.inputValueTypes.Formula, _co.inputValueTypes.Prompt) : _ck(_v, 9, 0, _co.inputValueTypes.Formula)); var currVal_13 = "component-action-inputs-item"; var currVal_14 = _co.analyticsSource; _ck(_v, 6, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
export function View_CustomizeBarActionEditParametersQueryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-parameters-query", [], null, null, null, View_CustomizeBarActionEditParametersQueryComponent_0, RenderType_CustomizeBarActionEditParametersQueryComponent)), i0.ɵdid(1, 114688, null, 0, i8.CustomizeBarActionEditParametersQueryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditParametersQueryComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-parameters-query", i8.CustomizeBarActionEditParametersQueryComponent, View_CustomizeBarActionEditParametersQueryComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", userInput: "userInput", analyticsSource: "analyticsSource" }, { addQueryInput: "addQueryInput" }, []);
export { CustomizeBarActionEditParametersQueryComponentNgFactory as CustomizeBarActionEditParametersQueryComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { SimpleQuery } from './simple-query';
var ValueWidgetSimpleQuery = /** @class */ (function (_super) {
    __extends(ValueWidgetSimpleQuery, _super);
    function ValueWidgetSimpleQuery() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ValueWidgetSimpleQuery.prototype.deserialize = function (data) {
        this.model = data['model'];
        return this;
    };
    ValueWidgetSimpleQuery.prototype.serialize = function (fields) {
        var data = {
            model: this.model
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ValueWidgetSimpleQuery.prototype.isConfigured = function () {
        return this.model != undefined;
    };
    return ValueWidgetSimpleQuery;
}(SimpleQuery));
export { ValueWidgetSimpleQuery };

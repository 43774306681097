import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';

import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentProjectStore } from '@modules/projects';

import { FeatureService } from '../../services/feature/feature.service';

@Component({
  selector: 'app-feature-overview',
  templateUrl: './feature-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureOverviewComponent implements OnInit, OnDestroy {
  @Input() fill = false;
  @Input() background = true;
  @Input() feature: string;
  @Input() button = true;
  // @Input() action: string;
  // @Output() actionClicked = new EventEmitter<void>();
  @Output() buttonClicked = new EventEmitter<void>();

  adminModes = AdminMode;

  // constructor(private featureService: FeatureService, private analyticsService: UniversalAnalyticsService) {}
  constructor(@Optional() public currentProjectStore: CurrentProjectStore) {}

  ngOnInit() {
    // this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.FeaturePreviewShow, {
    //   Feature: this.feature
    // });
  }

  ngOnDestroy(): void {}

  // get pro() {
  //   return this.featureService.isFeaturePro(this.feature);
  // }
  //
  // get available() {
  //   return this.featureService.isFeatureAvailable(this.feature);
  // }
  //
  // activate() {
  //   this.featureService.activateFeature(this.feature).pipe(untilDestroyed(this)).subscribe();
  //   this.onActivated();
  // }
  //
  // onActivated() {
  //   this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.FeaturePreviewActivate, {
  //     Feature: this.feature
  //   });
  // }
  //
  // triggerAction() {
  //   this.actionClicked.emit();
  //   this.activate();
  // }
}

<app-upload-template
  [upload]="isUploadable()"
  [accept]="'image/*'"
  [name]="name"
  [control]="control"
  [autofocus]="autofocus"
  [disabled]="!storage$.value"
  [background]="background"
  [fit]="fit"
  [cropFormat]="cropFormat"
  [cropFormatCustom]="cropFormatCustom"
  [preview]="uploadedFile?.url"
  [uploadProgress]="uploadProgress"
  [uploadError]="uploadError"
  [accentColor]="accentColor"
  (clearValue)="clearCurrentValue()"
  (fileSelected)="onFileSelected($event)"
>
  <ng-container *ngIf="uploadedFile" upload-preview>
    <app-image-preview
      class="upload__background"
      [url]="uploadedFile?.url"
      [value]="control.value"
      [background]="background"
      [fit]="fit"
      [resize]="resize"
      [compact]="compact"
    ></app-image-preview>
  </ng-container>

  <ng-container *ngIf="imagePreviewComponent" upload-actions>
    <a
      href="javascript:void(0)"
      class="upload__button upload__button_type_zoom-in"
      (click)="$event.stopPropagation(); imagePreviewComponent.openLightbox($event, true)"
      [appTip]="'Open'"
      [appTipOptions]="{ side: 'top', margin: -4 }"
    >
    </a>
  </ng-container>
</app-upload-template>

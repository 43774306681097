<td class="card-table__column" style="width: 110px;">
  <div class="card-table__column-inner">
    <div
      class="field__preview field__preview_border"
      [class.field__preview_image]="!item.viewPreview"
      [class.field__preview_contain]="item.viewPreview"
      [style.width.px]="80"
      [style.height.px]="50"
      [style.background-image]="item.viewPreview ? ('url(\'' + item.viewPreview + '\')' | appSafeStyle) : null"
    ></div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner" style="padding-left: 0;">
    {{ item.name }}
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <span class="card-table__grey">
      {{ item.createdAt | appFormatDate: 'lll' }}
    </span>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_right">
    <button
      type="button"
      class="button button_extra-small button_orange-transparent"
      [disabled]="importLoading"
      (click)="applyImport(item.id)"
    >
      <app-loader-small *ngIf="importLoading" class="button__icon button__icon_left"></app-loader-small>
      <span *ngIf="!importLoading" class="button__icon button__icon_left icon-download"></span>
      <span class="button__label">Import</span>
    </button>
  </div>
</td>

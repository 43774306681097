var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFormGroup } from '@common/form-utils';
import { HttpContentType, HttpMethod, HttpQuery } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var ZapierResourceSettingsForm = /** @class */ (function (_super) {
    __extends(ZapierResourceSettingsForm, _super);
    function ZapierResourceSettingsForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.form = new AppFormGroup({});
        return _this;
    }
    ZapierResourceSettingsForm.prototype.getParams = function () {
        var params = new RestAPIResourceParams();
        var baseHttpQuery = new HttpQuery();
        baseHttpQuery.method = HttpMethod.POST;
        baseHttpQuery.bodyType = HttpContentType.FormData;
        params.baseHttpQuery = baseHttpQuery;
        return {
            resourceName: this.resourceForm.value['name'],
            resourceParams: params.serialize()
        };
    };
    return ZapierResourceSettingsForm;
}(BaseResourceSettingsForm));
export { ZapierResourceSettingsForm };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import isFunction from 'lodash/isFunction';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DraftChangesService } from '@modules/customize-utils';
import { Model, ModelDescription } from '@modules/models';
import { JET_APP_RESOURCE, Resource } from '@modules/projects';
import { HttpMethod } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { TypedChanges } from '@shared';

import { ApiParameter } from '../../data/api-parameter';
import { QueriesApiParameter } from '../../data/queries-api-parameter';
import { QueryName } from '../../data/query-name';

interface QueryItem {
  name: QueryName;
  label: string;
  method: HttpMethod;
  color: string;
  parameters?: ApiParameter[];
}

@Component({
  selector: 'app-model-api',
  templateUrl: './model-api.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() getPagination = true;
  @Input() serializer: (model: Model) => Object;
  @Input() queriesApiParameters: QueriesApiParameter[];
  @Input() popup = false;

  allQueries: QueryItem[] = [
    {
      name: QueryName.Get,
      label: 'List',
      method: HttpMethod.GET,
      color: 'blue'
    },
    {
      name: QueryName.GetDetail,
      label: 'Retrieve',
      method: HttpMethod.GET,
      color: 'blue'
    },
    {
      name: QueryName.Create,
      label: 'Create',
      method: HttpMethod.POST,
      color: 'green'
    },
    {
      name: QueryName.Update,
      label: 'Update',
      method: HttpMethod.PATCH,
      color: 'orange'
    },
    {
      name: QueryName.Delete,
      label: 'Delete',
      method: HttpMethod.DELETE,
      color: 'red'
    },
    // {
    //   name: QueryName.Aggregate,
    //   label: 'Aggregate'
    // },
    // {
    //   name: QueryName.Group,
    //   label: 'Group'
    // },
    {
      name: QueryName.Reload,
      label: 'Sync structure',
      method: HttpMethod.POST,
      color: 'green'
    }
  ];
  queries: QueryItem[] = [];
  currentQueryName = QueryName.Get;
  hasDraftChanges = false;
  queryNames = QueryName;

  constructor(
    private resourceControllerService: ResourceControllerService,
    private draftChangesService: DraftChangesService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.draftChangesService
      .getDraftChanges$({
        resource: this.resource ? this.resource.uniqueName : undefined,
        modelId: this.modelDescription ? this.modelDescription.modelId : undefined
      })
      .pipe(untilDestroyed(this))
      .subscribe(changes => {
        this.hasDraftChanges = changes.filter(item => item.count).length > 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelApiComponent>): void {
    if (changes.resource || changes.modelDescription) {
      this.queries = this.allQueries
        .filter(item => {
          const controller = this.resourceControllerService.getForModelDescription(
            this.resource,
            this.modelDescription,
            true
          );

          if (item.name == QueryName.Get) {
            return (
              !!controller.modelGet && this.modelDescription.getQuery && this.modelDescription.getQuery.isConfigured()
            );
          } else if (item.name == QueryName.GetDetail) {
            if (this.resource.uniqueName == JET_APP_RESOURCE) {
              return true;
            } else if (this.modelDescription.getDetailQuery) {
              return (
                !!controller.modelGetDetail &&
                this.modelDescription.getDetailQuery &&
                this.modelDescription.getDetailQuery.isConfigured()
              );
            } else if (this.modelDescription.getQuery) {
              return (
                !!controller.modelGetDetail &&
                this.modelDescription.getQuery &&
                this.modelDescription.getQuery.isConfigured()
              );
            } else {
              return false;
            }
          } else if (item.name == QueryName.Create) {
            return (
              !!controller.modelCreate &&
              this.modelDescription.createQuery &&
              this.modelDescription.createQuery.isConfigured()
            );
          } else if (item.name == QueryName.Update) {
            return (
              !!controller.modelUpdate &&
              this.modelDescription.updateQuery &&
              this.modelDescription.updateQuery.isConfigured()
            );
          } else if (item.name == QueryName.Delete) {
            return (
              !!controller.modelDelete &&
              this.modelDescription.deleteQuery &&
              this.modelDescription.deleteQuery.isConfigured()
            );
            // } else if (item.name == QueryName.Aggregate) {
            //   return !!controller.modelAggregate;
            // } else if (item.name == QueryName.Group) {
            //   return !!controller.modelGroup;
          } else {
            return true;
          }
        })
        .map(queryItem => {
          const parameters = this.queriesApiParameters
            ? this.queriesApiParameters.filter(item => !item.queries || item.queries.includes(queryItem.name))
            : undefined;

          return {
            ...queryItem,
            ...(parameters
              ? {
                  parameters: parameters.map(item => {
                    if (isFunction(item.parameter)) {
                      return item.parameter(queryItem.name);
                    } else {
                      return item.parameter;
                    }
                  })
                }
              : undefined)
          };
        });
      this.currentQueryName = this.queries[0].name;
    }
  }

  setCurrentQueryName(value: QueryName) {
    this.currentQueryName = value;
    this.cd.markForCheck();
  }

  get currentQuery(): QueryItem {
    return this.queries.find(item => item.name == this.currentQueryName);
  }
}

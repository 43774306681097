import keys from 'lodash/keys';
import { CookieService } from 'ngx-cookie';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie/src/cookie.service";
var CookieStorage = /** @class */ (function () {
    function CookieStorage(cookieService) {
        this.cookieService = cookieService;
    }
    CookieStorage.prototype.get = function (key) {
        return this.cookieService.get(key);
    };
    CookieStorage.prototype.set = function (key, value) {
        var expires = new Date();
        expires.setDate(expires.getDate() + 30);
        this.cookieService.put(key, value, { expires: expires });
    };
    CookieStorage.prototype.remove = function (key) {
        this.cookieService.remove(key);
    };
    CookieStorage.prototype.keys = function () {
        return keys(this.cookieService.getAll());
    };
    CookieStorage.ngInjectableDef = i0.defineInjectable({ factory: function CookieStorage_Factory() { return new CookieStorage(i0.inject(i1.CookieService)); }, token: CookieStorage, providedIn: "root" });
    return CookieStorage;
}());
export { CookieStorage };

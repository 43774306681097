<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="title | appCapitalize"
    [backLabel]="'All components'"
    [deleteEnabled]="viewSettings?.uniqueName ? true : false"
    [deleteTip]="'Delete Page'"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-section *ngIf="form.changeViewSettings && viewSettings?.uniqueName">
      <!--      <div class="sidebar__element">-->
      <!--        <app-sidebar-field [label]="'label'" [required]="true">-->
      <!--          <app-auto-field-->
      <!--            [form]="form.form"-->
      <!--            [label]="false"-->
      <!--            [field]="createField({ name: 'name', field: 'CharField', params: { classes: ['input_fill'] } })"-->
      <!--          ></app-auto-field>-->
      <!--        </app-sidebar-field>-->
      <!--      </div>-->

      <div *ngIf="form.changeViewSettings && viewSettings?.uniqueName" class="sidebar__element">
        <app-sidebar-field [label]="'collection'" [required]="true">
          <app-auto-field
            [form]="form"
            [label]="false"
            [field]="
              createField({ name: 'model', field: 'ModelDescriptionField', params: { classes: ['select_fill'] } })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>

    <app-sidebar-section *ngIf="!viewSettings?.uniqueName && modelDescription">
      <div class="sidebar__element">
        <app-sidebar-field [label]="'collection'">
          <input type="text" class="input input_fill" [value]="modelDescription.verboseName | appCapitalize" disabled />
        </app-sidebar-field>
      </div>
    </app-sidebar-section>

    <app-sidebar-section [title]="'Page Parameters'" [documentation]="'page-parameters'">
      <div class="sidebar__list">
        <app-parameters-edit
          [control]="form.controls.parameters"
          [configurable]="{
            name: true,
            field: true,
            required: true,
            add: true,
            sortable: true
          }"
          [analyticsSource]="analyticsObject"
        >
        </app-parameters-edit>
      </div>
    </app-sidebar-section>

    <app-sidebar-section [title]="'Page Queries'">
      <div class="sidebar__list">
        <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
          <div *ngFor="let control of form.controls.queries.controls; let i = index" cdkDrag class="sidebar-list__item">
            <div
              *ngIf="control.controls.type.value == modelDataTypes.Detail"
              class="sidebar-list-item sidebar-list-item_clickable"
              [class.sidebar-list-item_error]="control.invalid"
              [appTip]="control.controls.detail_data_source.valid !== false ? undefined : 'Not configured'"
              (click)="editQuery(control, i)"
            >
              <div class="sidebar-list-item__drag"></div>
              <div class="sidebar-list-item__left">
                <img
                  *ngIf="(control.controls.detail_data_source.getResource$() | async)?.icon as icon"
                  class="sidebar-list-item__icon"
                  [src]="icon"
                />
                <span
                  *ngIf="!(control.controls.detail_data_source.getResource$() | async)?.icon"
                  class="sidebar-list-item__icon icon-document"
                ></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">{{ getQueryTitle(control, i) }}</div>
                <div
                  *ngIf="control.controls.detail_data_source.getResource$() | async as resource"
                  class="sidebar-list-item__description"
                >
                  One record - {{ resource.name }}
                  {{ control.controls.detail_data_source.controls.query.value?.queryTypeStr }}
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <a
                  href="javascript:void(0)"
                  [appTip]="'Delete'"
                  [appTipOptions]="{ side: 'top' }"
                  class="sidebar-list-item__action icon-bin"
                  (click)="$event.stopPropagation(); removeQuery(control)"
                ></a>
              </div>
            </div>

            <div
              *ngIf="control.controls.type.value == modelDataTypes.List"
              class="sidebar-list-item sidebar-list-item_clickable"
              [class.sidebar-list-item_error]="control.invalid"
              [appTip]="control.controls.list_data_source.valid !== false ? undefined : 'Not configured'"
              (click)="editQuery(control, i)"
            >
              <div class="sidebar-list-item__drag"></div>
              <div class="sidebar-list-item__left">
                <img
                  *ngIf="(control.controls.list_data_source.getResource$() | async)?.icon as icon"
                  class="sidebar-list-item__icon"
                  [src]="icon"
                />
                <span
                  *ngIf="!(control.controls.list_data_source.getResource$() | async)?.icon"
                  class="sidebar-list-item__icon icon-documents"
                ></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">{{ getQueryTitle(control, i) }}</div>
                <div
                  *ngIf="control.controls.list_data_source.getResource$() | async as resource"
                  class="sidebar-list-item__description"
                >
                  List of records - {{ resource.name }}
                  {{ control.controls.list_data_source.controls.query.value?.queryTypeStr }}
                </div>
              </div>
              <div class="sidebar-list-item__right">
                <a
                  href="javascript:void(0)"
                  [appTip]="'Delete'"
                  [appTipOptions]="{ side: 'top' }"
                  class="sidebar-list-item__action icon-bin"
                  (click)="$event.stopPropagation(); removeQuery(control)"
                ></a>
              </div>
            </div>
          </div>

          <mat-menu #dropdown="matMenu">
            <ng-template matMenuContent>
              <button
                mat-menu-item
                [class.mat-menu-item-odd]="true"
                [disableRipple]="true"
                (click)="addQuery(modelDataTypes.Detail)"
              >
                <span class="choose-items-item__dropdown-icon icon-document"></span>
                Get one record
              </button>

              <button
                mat-menu-item
                [class.mat-menu-item-odd]="true"
                [disableRipple]="true"
                (click)="addQuery(modelDataTypes.List)"
              >
                <span class="choose-items-item__dropdown-icon icon-documents"></span>
                Get list of records
              </button>
            </ng-template>
          </mat-menu>

          <div *ngIf="!form.controls.queries.length" class="sidebar-list__item">
            <div
              class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"
              [matMenuTriggerFor]="dropdown"
            >
              <div class="sidebar-list-item__drag"></div>
              <div class="sidebar-list-item__left">
                <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
                  <span class="icon-cloud_download button__icon"></span>
                </span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">Query</div>
                <div class="sidebar-list-item__description">Not specified</div>
              </div>
            </div>
          </div>

          <div class="sidebar-list__buttons">
            <a
              href="javascript:void(0)"
              class="button button_orange-transparent button_small sidebar-list__buttons-item"
              [matMenuTriggerFor]="dropdown"
            >
              <span class="icon icon-plus button__icon button__icon_left"></span>
              <span class="button__label">Add Query</span>
            </a>
          </div>
        </div>
      </div>
    </app-sidebar-section>

    <!--    <app-sidebar-section [title]="'Template Store'">-->
    <!--      <div class="sidebar__element">-->
    <!--        <button type="button" class="button button_orange-alternative button_fill button_bigger" id="suggest_template">-->
    <!--          <span class="icon button__icon_left icon-shopping_cart button__icon"></span>-->
    <!--          <span class="button__label">Submit to Template Store</span>-->
    <!--          <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>-->
    <!--        </button>-->
    <!--        <div class="sidebar-field__description">-->
    <!--          Let people discover and copy your app. List your app for free, or pick a price and sell it.-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </app-sidebar-section>-->

    <app-sidebar-section
      *ngIf="(currentUserStore.instance$ | async)?.isStaff && viewSettings?.uniqueName"
      [title]="'Template Info (Staff)'"
      class="staff-block"
    >
      <div class="sidebar__element">
        <input
          type="number"
          class="input input_fill"
          placeholder="Template ID (optional)"
          [(ngModel)]="sourceTemplateId"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
      <div class="sidebar__element">
        <button type="button" class="button button_orange button_fill" (click)="updateTemplateInfo()">
          Update
        </button>
      </div>
    </app-sidebar-section>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    *ngIf="viewSettings?.uniqueName"-->
  <!--    [saveDisabled]="form.invalid || form.disabled"-->
  <!--    [errors]="form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
  <!--  <app-sidebar-submit *ngIf="!viewSettings?.uniqueName" [saveEnabled]="false" (cancelClick)="cancel()">-->
  <!--  </app-sidebar-submit>-->
</form>

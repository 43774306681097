var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { CustomizeService, ElementType, registerElementComponent, ViewContextElement, WidgetElementItem } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { AutoWidgetComponent } from '@modules/dashboard-components';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
function getElementStateName(state) {
    return {
        name: state.name
    };
}
function getElementStateActions(state) {
    return {
        reloadDataSupported: state.reloadDataSupported
    };
}
var AutoWidgetElementComponent = /** @class */ (function (_super) {
    __extends(AutoWidgetElementComponent, _super);
    function AutoWidgetElementComponent(customizeService, viewContextElement, popup, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.popup = popup;
        _this.cd = cd;
        _this.state = {};
        return _this;
    }
    AutoWidgetElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    AutoWidgetElementComponent.prototype.ngOnDestroy = function () { };
    AutoWidgetElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
        }
        if (changes.element) {
            this.elementOnChange(this.element);
        }
    };
    AutoWidgetElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.element$
            .pipe(map(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    AutoWidgetElementComponent.prototype.getElementState = function (element) {
        return {
            name: element.name,
            reloadDataSupported: element.widget.isReloadDataSupported()
        };
    };
    AutoWidgetElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateName(state), getElementStateName(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateActions(state), getElementStateActions(this.state))) {
            this.initContextActions(state);
        }
    };
    AutoWidgetElementComponent.prototype.initContext = function () {
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'chart',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    AutoWidgetElementComponent.prototype.initContextActions = function (state) {
        var _this = this;
        var actions = state
            ? [
                {
                    uniqueName: 'update_data',
                    name: 'Update Data',
                    icon: 'repeat',
                    parameters: [],
                    handler: function () { return _this.reloadData(); }
                }
            ]
            : [];
        this.viewContextElement.setActions(actions);
    };
    AutoWidgetElementComponent.prototype.updateContextInfo = function (state) {
        this.viewContextElement.initInfo({
            name: state.name
        });
    };
    AutoWidgetElementComponent.prototype.getData$ = function () {
        if (this.autoWidgetComponent) {
            return this.autoWidgetComponent.getData$();
        }
    };
    AutoWidgetElementComponent.prototype.reloadData = function () {
        if (this.autoWidgetComponent) {
            this.autoWidgetComponent.reloadData();
        }
    };
    return AutoWidgetElementComponent;
}(BaseElementComponent));
export { AutoWidgetElementComponent };
registerElementComponent({
    type: ElementType.Widget,
    component: AutoWidgetElementComponent,
    label: 'Component',
    actions: []
});

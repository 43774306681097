<div class="compact-wide-button" [class.compact-wide-button_interactive]="interactive">
  <div
    *ngIf="leftIcon"
    class="compact-wide-button__left"
    [class.compact-wide-button__left_background]="leftBackground"
    [class.compact-wide-button__left_interactive]="leftInteractive"
    [appTip]="leftTooltip"
    [appTipOptions]="{ side: 'top' }"
    (click)="$event.stopPropagation(); leftClick.emit()"
  >
    <div class="compact-wide-button__icon" [ngClass]="['icon-' + leftIcon]"></div>
  </div>

  <div
    class="compact-wide-button__main"
    [class.compact-wide-button__main_padding_left]="leftBackground"
    [class.compact-wide-button__main_padding_right]="rightBackground"
  >
    <div class="compact-wide-button__label">{{ label }}</div>
    <div *ngIf="additional | appIsSet" class="compact-wide-button__additional">{{ additional }}</div>
  </div>

  <div
    *ngIf="rightIcon"
    class="compact-wide-button__right"
    [class.compact-wide-button__right_background]="rightBackground"
    [class.compact-wide-button__right_interactive]="rightInteractive"
    [appTip]="rightTooltip"
    [appTipOptions]="{ side: 'top' }"
    (click)="$event.stopPropagation(); rightClick.emit()"
  >
    <div class="compact-wide-button__icon" [ngClass]="['icon-' + rightIcon]"></div>
  </div>
</div>

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { defaultAccentColor } from '@modules/colors';
import { MenuBlockLayout, MenuBlockLayouts, MenuGeneratorService, MenuItemType } from '@modules/menu';
import { MenuContext, MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, fileToBase64, isSet } from '@shared';
import { MenuBlockControl } from '../../../layout-routes/components/project-settings/menu-block.control';
import { MenuUpdateForm } from '../../../layout-routes/components/project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../../../layout-routes/components/project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../../../layout-routes/components/project-settings/project-update.form';
var AdminTemplateComponent = /** @class */ (function () {
    function AdminTemplateComponent(context, currentUserStore, menuGeneratorService, sanitizer, cd) {
        this.context = context;
        this.currentUserStore = currentUserStore;
        this.menuGeneratorService = menuGeneratorService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.contentDisabled = false;
        this.blockControlPreviewHover = new EventEmitter();
        this.blockControlPreviewClick = new EventEmitter();
        this.leftMenuBlocks = [];
        this.topMenuBlocks = [];
        this.topContentMenuBlocks = [];
        this.externalFonts = [];
        this.defaultAccentColor = defaultAccentColor;
        this.stepsProgress = 0;
        this.blockControlHover$ = new BehaviorSubject(undefined);
        this.blockControlActive$ = new BehaviorSubject(undefined);
        this.blockControlPreviewHover$ = new BehaviorSubject(undefined);
    }
    AdminTemplateComponent.prototype.trackMenuBlockItemFn = function (i, item) {
        return item.control.instance.uid;
    };
    AdminTemplateComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    AdminTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(this.projectForm.valueChanges, this.settingsForm.valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.projectForm.controls.logo), controlValue(this.projectForm.controls.logo_file))
            .pipe(switchMap(function (_a) {
            var url = _a[0], file = _a[1];
            if (file) {
                return fileToBase64(file);
            }
            else {
                return of(url);
            }
        }), untilDestroyed(this))
            .subscribe(function (url) {
            _this.logoUrl = url;
            _this.cd.markForCheck();
        });
        controlValue(this.menuForm.controls.blocks)
            .pipe(
        // auditTime(60),
        switchMap(function () {
            return combineLatest(_this.menuForm.controls.blocks.controls.map(function (control) {
                return control
                    .getEnabled$({ context: _this.context })
                    .pipe(map(function (enabled) { return ({ control: control, enabled: enabled }); }));
            })).pipe(map(function (items) { return items.filter(function (item) { return item.enabled; }).map(function (item) { return item.control; }); }));
        }), untilDestroyed(this))
            .subscribe(function (menuBlockControls) {
            var menuBlocks = menuBlockControls.map(function (control) {
                var block = control.serialize();
                var color = control.getColor();
                var firstMenuItem = block.getAllItems().filter(function (item) { return [MenuItemType.Simple].includes(item.type); })[0];
                var isLight = MenuPrimary.isLight(color);
                var primary = MenuBlockLayouts.isPrimary(control.controls.layout.value);
                var style = primary ? _this.getPrimaryMenuStyleVars(color) : _this.getSecondaryMenuStyleVars(color);
                block.startItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.startItems);
                block.centerItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.centerItems);
                block.endItems = _this.menuGeneratorService.cleanMenuItemsAppMode(block.endItems);
                return {
                    block: block,
                    control: control,
                    firstMenuItem: firstMenuItem,
                    primary: primary,
                    isLight: isLight,
                    style: style
                };
            });
            _this.leftMenuBlocks = menuBlocks.filter(function (item) { return MenuBlockLayouts.isLeft(item.block.layout); });
            _this.topMenuBlocks = menuBlocks.filter(function (item) { return item.block.layout == MenuBlockLayout.TopThin; });
            _this.topContentMenuBlocks = menuBlocks.filter(function (item) { return item.block.layout == MenuBlockLayout.TopContentThin; });
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.settingsForm.controls.font_regular), controlValue(this.settingsForm.controls.font_heading))
            .pipe(untilDestroyed(this))
            .subscribe(function (fonts) {
            _this.externalFonts = fonts.filter(function (item) { return isSet(item); });
            _this.cd.markForCheck();
        });
        var stepIndex = 1;
        var itemWidth = 1 / 4;
        this.stepsProgress = itemWidth * stepIndex + itemWidth * 0.5;
        combineLatest(this.blockControlHover$, this.blockControlActive$, this.blockControlPreviewHover$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var blockControlHover = _a[0], blockControlActive = _a[1], blockControlPreviewHover = _a[2];
            if (blockControlPreviewHover) {
                _this.blockControlFocus = blockControlPreviewHover;
            }
            else if (blockControlHover && blockControlHover.isVisible()) {
                _this.blockControlFocus = blockControlHover;
            }
            else if (blockControlActive && blockControlActive.isVisible()) {
                _this.blockControlFocus = blockControlActive;
            }
            else {
                _this.blockControlFocus = undefined;
            }
            _this.cd.markForCheck();
        });
        this.blockControlPreviewHover$
            .pipe(untilDestroyed(this))
            .subscribe(function (value) { return _this.blockControlPreviewHover.emit(value); });
    };
    AdminTemplateComponent.prototype.ngOnDestroy = function () { };
    AdminTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (changes.blockControlHover) {
            this.blockControlHover$.next(this.blockControlHover);
        }
        if (changes.blockControlActive) {
            this.blockControlActive$.next(this.blockControlActive);
        }
    };
    AdminTemplateComponent.prototype.getPrimaryMenuStyleVars = function (accentColor) {
        var vars = MenuPrimary.getVars(accentColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    AdminTemplateComponent.prototype.getSecondaryMenuStyleVars = function (accentColor) {
        var vars = MenuSecondary.getVars(accentColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    AdminTemplateComponent.prototype.asMenuBlockItems = function (value) {
        return value;
    };
    return AdminTemplateComponent;
}());
export { AdminTemplateComponent };

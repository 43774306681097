import { Inject, Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { ViewSettingsStore } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet, SingletonStore } from '@shared';

import { MenuItem } from '../../data/menu-item';
import { MenuBlock, MenuBlockLayout, MenuSettings } from '../../data/menu-settings';
import { MenuGeneratorService } from '../../services/menu-generator/menu-generator.service';
import { MenuSettingsService } from '../../services/menu-settings/menu-settings.service';

@Injectable()
export class MenuSettingsStore extends SingletonStore<MenuSettings> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private menuSettingsService: MenuSettingsService,
    private menuGeneratorService: MenuGeneratorService,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<MenuSettings> {
    if (!this.currentProjectStore.instance) {
      return of(undefined);
    }

    return combineLatest(
      this.mode$.pipe(
        switchMap(mode =>
          this.menuSettingsService.get(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            mode == AdminMode.Builder
          )
        )
      ),
      this.modelDescriptionStore.getFirst().pipe(catchError(() => of([]))),
      this.viewSettingsStore.getFirst().pipe(catchError(() => of([])))
    ).pipe(
      map(([settings, models, viewSettings]) => {
        return this.menuGeneratorService.validate(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          settings,
          models,
          viewSettings
        );
      })
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<MenuSettings> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return options.includeDeleted || !result.deleted ? result : undefined;
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<MenuSettings> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return options.includeDeleted || !result.deleted ? result : undefined;
      })
    );
  }

  addItems(menuItems: MenuItem[]): Observable<MenuSettings> {
    if (!menuItems.length) {
      return this.getFirst();
    }

    return this.getFirst().pipe(
      switchMap(settings => {
        settings = cloneDeep(settings);

        let block = settings.blocks
          .filter(item => item.enabled)
          .find(item => item.layout == MenuBlockLayout.TopThin && !isSet(item.accentColor));

        if (!block) {
          block = new MenuBlock();

          block.generateUid();
          block.layout = MenuBlockLayout.TopThin;

          settings.blocks.push(block);
        }

        block.startItems = [...menuItems, ...block.startItems];

        return this.menuSettingsService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          settings
        );
      }),
      delayWhen(() => {
        return this.getFirst(true);
      })
    );
  }
}

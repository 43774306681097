<div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
  <ng-template #edit_tip> <span class="icon-pen va-m"></span> Edit as formula </ng-template>

  <div class="input-tag input-tag_fill" (click)="edit.emit()">
    <span class="input-tag__main" [appTip]="edit_tip" [appTipOptions]="{ side: 'top' }">
      <ng-container *ngIf="(valueControl.value | appIsSet) && valuePath">
        <ng-container *ngFor="let item of valuePath; let i = index; let last = last">
          <span [class.text_muted]="i >= 2">{{ item }}</span>
          <span *ngIf="!last" [class.text_muted]="i >= 1"> · </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!(valueControl.value | appIsSet)">Is not set</ng-container>
    </span>

    <span class="input-tag__right">
      <span
        class="input-tag__control icon-close"
        (click)="$event.stopPropagation(); reset.emit()"
        [appTip]="'Clear'"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </span>
  </div>
</div>

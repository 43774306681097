<div class="sidebar inactive">
  <div class="sidebar__scrollable">
    <div>
      <div class="sidebar__header sidebar-header">
        <div class="sidebar-header__container">
          <div class="sidebar-header__title">
            <span [class.loading-animation]="animating"><span class="stub-text">table</span></span>
          </div>
        </div>
      </div>

      <div class="sidebar__content">
        <div class="sidebar-section sidebar-section_border">
          <div class="sidebar__element">
            <div class="sidebar-field">
              <div class="sidebar-field__label">
                <div class="sidebar-field__label-main">
                  <span class="sidebar-field__label-title">
                    <span [class.loading-animation]="animating"><span class="stub-text">Data</span></span>
                  </span>
                </div>
              </div>
              <div class="sidebar-field__content">
                <div class="sidebar-field__field">
                  <div class="sidebar-list-item sidebar-list-item_clickable">
                    <div class="sidebar-list-item__left">
                      <span class="stub-image stub-image_contrast"></span>
                    </div>
                    <div class="sidebar-list-item__main">
                      <div class="sidebar-list-item__title">
                        <span [class.loading-animation]="animating">
                          <span class="stub-text">jet_admin_test</span>
                        </span>
                      </div>
                      <div class="sidebar-list-item__description">
                        <span [class.loading-animation]="animating">
                          <span class="stub-text">(Demo) Google Sheets</span>
                        </span>
                      </div>
                    </div>
                    <div class="sidebar-list-item__right">
                      <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
                    </div>
                  </div>
                </div>
                <div class="sidebar-field__right sidebar-field__right_hidden"></div>
              </div>
              <div class="sidebar-field__break"></div>
              <div class="sidebar-field__description sidebar-field__description_hidden"></div>
            </div>
          </div>
        </div>

        <div *ngFor="let item of [1, 2, 3]" class="sidebar-collapse">
          <a class="sidebar-collapse__header" href="javascript:void(0)">
            <div class="sidebar-collapse__header-left">
              <span
                class="sidebar-collapse__header-icon sidebar-collapse__header-icon_arrow icon-arrow_forward_2"
              ></span>
            </div>
            <div class="sidebar-collapse__header-main">
              <div class="sidebar-collapse__header-title">
                <span [class.loading-animation]="animating"><span class="stub-text">Columns</span></span>
              </div>
              <div class="sidebar-collapse__header-description">
                <span [class.loading-animation]="animating"><span class="stub-text">Customize columns</span></span>
              </div>
            </div>
            <div class="sidebar-collapse__header-right"></div>
          </a>
        </div>

        <div class="sidebar-section sidebar-section_border">
          <div class="sidebar-section__header">
            <div class="sidebar-section__title">
              <span [class.loading-animation]="animating"><span class="stub-text">Template Store</span></span>
            </div>
          </div>
          <div class="sidebar__element">
            <button class="button button_orange-alternative button_fill button_bigger" type="button">
              <span class="button__label">
                <span [class.loading-animation]="animating">
                  <span class="stub-text">
                    Submit to
                  </span>
                </span>
              </span>
              <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>
            </button>
            <div class="sidebar-field__description">
              <span [class.loading-animation]="animating">
                <span class="stub-text">
                  Let people discover and copy your app
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isSet } from '@shared';

import { ProjectUserService } from '../services/project-user/project-user.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class ProjectUserSelectSource extends SelectSource {
  allowEmpty = false;
  emptyName = '---';
  userValue = false;

  private page = 1;
  private totalPages = 1;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService
  ) {
    super();
  }

  get emptyOption() {
    return {
      value: null,
      name: this.emptyName
    };
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = isSet(searchQuery) ? searchQuery.trim() : '';

    const params = { ...(isSet(searchQuery) && { search: searchQuery }) };

    return this.projectUserService
      .getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        params
      )
      .pipe(
        map(result => {
          const perPage = 20;
          const totalPages = Math.ceil(result.count / perPage);

          this.page += 1;
          this.totalPages = totalPages;

          return [
            ...(this.allowEmpty ? [this.emptyOption] : []),
            ...result.results
              .filter(item => !this.userValue || item.user)
              .map(item => {
                return {
                  value: this.userValue ? item.user.uid : item.uid,
                  name: item.user ? `${item.user.firstName} - ${item.user.email}` : `${item.userEmail} (invited)`
                };
              })
          ];
        })
      );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    if (this.userValue) {
      return this.projectUserService
        .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
          user_uid: value
        })
        .pipe(
          map(response => {
            const result = response.filter(item => item.user)[0];

            if (!result) {
              return;
            }

            return {
              value: result.user.uid,
              name: result.user ? `${result.user.firstName} - ${result.user.email}` : `${result.userEmail} (invited)`
            };
          })
        );
    } else {
      return this.projectUserService
        .getDetail(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          value
        )
        .pipe(
          map(result => {
            if (!result) {
              return;
            }

            return {
              value: result.uid,
              name: result.user ? `${result.user.firstName} - ${result.user.email}` : `${result.userEmail} (invited)`
            };
          })
        );
    }
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

import { ViewSettingsAction } from '@modules/actions';
import { ActionDropdownElementItem, ActionElementItem, AlignHorizontal, ElementType } from '@modules/customize';
import { Input } from '@modules/fields';
import { isSet } from '@shared';

export const changeCustomizeBarComponentsBasic = [
  {
    title: 'Title',
    image: 'heading',
    type: ElementType.Text,
    defaultParams: {
      markup: {
        blocks: [
          {
            type: 'header',
            data: {
              text: 'Header',
              level: 1
            }
          }
        ]
      }
    }
  },
  {
    title: 'Text',
    image: 'text',
    type: ElementType.Text,
    defaultParams: {
      markup: {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dolor sed viverra ipsum nunc aliquet bibendum enim. In massa tempor nec feugiat. Nunc aliquet bibendum enim facilisis gravida. Nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Amet luctus venenatis lectus magna fringilla. Volutpat maecenas volutpat blandit aliquam etiam erat velit scelerisque in.'
            }
          }
        ]
      }
    }
  },
  {
    title: 'Image',
    image: 'image',
    type: ElementType.Image,
    defaultParams: { url: '', width: 320, height: 240 }
  },
  {
    title: 'Button',
    image: 'button',
    action: 'Add Button',
    type: ElementType.Action,
    defaultParams: { action: undefined },
    postCreate(element: ActionElementItem): ActionElementItem {
      if (isSet(element.name)) {
        if (!element.actionItem) {
          element.actionItem = new ViewSettingsAction();
        }

        element.actionItem.verboseNameInput = new Input().deserializeFromStatic('value', element.name);
      }

      return element;
    }
  },
  {
    title: 'Button group',
    image: 'button_group',
    action: 'Add Button group',
    type: ElementType.ActionGroup,
    defaultParams: { action: undefined },
    postCreate(element: ActionDropdownElementItem): ActionDropdownElementItem {
      if (isSet(element.name)) {
        element.verboseNameInput = new Input().deserializeFromStatic('value', element.name);
      }

      return element;
    }
  },
  {
    title: 'Dropdown',
    image: 'button_dropdown',
    action: 'Add Dropdown Button',
    type: ElementType.ActionDropdown,
    defaultParams: { action: undefined },
    postCreate(element: ActionDropdownElementItem): ActionDropdownElementItem {
      if (isSet(element.name)) {
        element.verboseNameInput = new Input().deserializeFromStatic('value', element.name);
      }

      return element;
    }
  },
  {
    title: 'Alert',
    image: 'alert',
    action: 'Add Alert',
    type: ElementType.Alert,
    defaultParams: {}
  },
  {
    title: 'Back',
    image: 'back',
    action: 'Add Back',
    type: ElementType.Back,
    defaultParams: { action: undefined }
  },
  {
    title: 'Steps',
    image: 'steps',
    action: 'Add Steps',
    type: ElementType.Steps,
    defaultParams: {}
  }
];

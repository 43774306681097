<div class="page-header__row">
  <div class="page-header__main">
    <div *ngIf="backLink" class="page-header__main-left">
      <a [appLink]="backLink" class="page-header__back icon-arrow_backward_3"></a>
    </div>
    <div class="page-header__main-content">
      <div class="page-header__logo">
        <ng-content select="app-page-logo"></ng-content>
      </div>
      <div class="page-header__title">
        <ng-content select="app-page-title"></ng-content>
      </div>
      <div class="page-header__description">
        <ng-content select="app-page-description"></ng-content>
      </div>
      <div class="page-header__additional">
        <ng-content select="app-page-header-additional"></ng-content>
      </div>
    </div>
  </div>
  <div class="page-header__right">
    <ng-content select="app-page-header-right"></ng-content>
  </div>
</div>
<ng-content></ng-content>

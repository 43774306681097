var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { WorkflowExecuteEventType } from '@modules/action-queries';
import { DataSourceType } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { SidebarTabsComponent } from '@modules/sidebar';
import { WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';
import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { ModelDescriptionDataSourceEditComponent } from '../../../model-description-data-source-edit/model-description-data-source-edit.component';
import { WorkflowStepStatus } from '../../steps/base-workflow-step/base-workflow-step.component';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { IteratorCustomizeWorkflowStepForm } from './iterator-customize-workflow-step.form';
var IteratorCustomizeWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(IteratorCustomizeWorkflowStepComponent, _super);
    function IteratorCustomizeWorkflowStepComponent(form, workflowEditContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.workflowEditContext = workflowEditContext;
        _this.cd = cd;
        _this.createField = createFormFieldFactory();
        _this.typeOptions = [
            {
                value: DataSourceType.Query,
                name: 'Load data',
                icon: 'cloud_download'
            },
            {
                value: DataSourceType.Input,
                name: 'Specify iterate',
                icon: 'input'
            }
        ];
        _this.status = WorkflowStepStatus.NotExecuted;
        _this.statuses = WorkflowStepStatus;
        _this.dataSourceTypes = DataSourceType;
        return _this;
    }
    IteratorCustomizeWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.step, { firstInit: false });
        this.sortableColumnOptions$ = this.form.controls.data_source.getSortableColumnOptions$();
        this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();
        this.trackRun();
        this.trackExecuteStatus();
        if (this.workflowEditable) {
            this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.submit(result);
            });
        }
    };
    IteratorCustomizeWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    IteratorCustomizeWorkflowStepComponent.prototype.trackRun = function () {
        var _this = this;
        this.workflowEditContext.run$
            .pipe(map(function (run) {
            if (!run) {
                return;
            }
            return run.stepRuns.find(function (item) { return item.uid == _this.step.uid; });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.stepRun = value;
            if (_this.stepRun) {
                if (isSet(value.error)) {
                    _this.status = WorkflowStepStatus.Failed;
                }
                else {
                    _this.status = WorkflowStepStatus.Success;
                }
            }
            _this.cd.markForCheck();
        });
    };
    IteratorCustomizeWorkflowStepComponent.prototype.trackExecuteStatus = function () {
        var _this = this;
        this.workflowEditContext.testExecuteEvents$.pipe(untilDestroyed(this)).subscribe(function (event) {
            if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
                _this.status = WorkflowStepStatus.NotExecuted;
            }
            else if (event.type == WorkflowExecuteEventType.StepStarted && event.step.uid == _this.step.uid) {
                _this.status = WorkflowStepStatus.Executing;
            }
            else if (event.type == WorkflowExecuteEventType.StepFinished && event.step.uid == _this.step.uid) {
                if (event.success) {
                    _this.status = WorkflowStepStatus.Success;
                }
                else {
                    _this.status = WorkflowStepStatus.Failed;
                }
            }
            _this.cd.markForCheck();
        });
    };
    IteratorCustomizeWorkflowStepComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.stepChange.emit(value);
    };
    IteratorCustomizeWorkflowStepComponent.prototype.execute = function () {
        this.stepExecute.emit();
        this.tabsComponent.setVisibleTab(this.tabsComponent.tabs[1]);
    };
    IteratorCustomizeWorkflowStepComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return IteratorCustomizeWorkflowStepComponent;
}(CustomizeWorkflowStepComponent));
export { IteratorCustomizeWorkflowStepComponent };
registerCustomizeWorkflowStepComponent(WorkflowStepType.Iterator, IteratorCustomizeWorkflowStepComponent);

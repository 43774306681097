import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupRef, PopupService } from '@common/popups';
import { Resource, ResourceTypeItem } from '@modules/projects';
import { isSet } from '@shared';

import { ChooseSyncModeComponent } from '../../components/choose-sync-mode/choose-sync-mode.component';
import { ConfirmSyncModeComponent } from '../../components/confirm-sync-mode/confirm-sync-mode.component';

@Injectable()
export class ChooseSyncController {
  constructor(
    private injector: Injector,
    private popupService: PopupService,
    private resolver: ComponentFactoryResolver
  ) {}

  chooseSyncMode(control: AbstractControl, options: { typeItem: ResourceTypeItem }): Observable<void> {
    const result = new Subject<void>();

    this.popupService.push({
      component: ChooseSyncModeComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        control: control,
        typeItem: options.typeItem
      },
      outputs: {
        chosen: [
          () => {
            result.next();
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }

  showConfirmSyncMode(
    object: string,
    sync: boolean,
    options: { resource: Resource; typeItem: ResourceTypeItem; closeOnConfirm?: boolean }
  ): PopupRef<ConfirmSyncModeComponent> {
    return this.popupService.showComponent<ConfirmSyncModeComponent>({
      component: ConfirmSyncModeComponent,
      injector: this.injector,
      inputs: {
        object: object,
        sync: sync,
        resource: options.resource,
        typeItem: options.typeItem,
        ...(isSet(options.closeOnConfirm) && { closeOnConfirm: options.closeOnConfirm })
      }
    });
  }

  confirmSyncMode(
    object: string,
    sync: boolean,
    options: { resource: Resource; typeItem: ResourceTypeItem }
  ): Observable<void> {
    return this.showConfirmSyncMode(object, sync, { ...options, closeOnConfirm: true }).instance.confirm;
  }
}

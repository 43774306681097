<div class="view-editor">
  <app-popup2-close [outside]="true" (click)="onCloseClick()"></app-popup2-close>

  <div *ngIf="editorContext.showInterface$ | async" class="view-editor__header view-editor-header">
    <div class="view-editor-header__left">
      <div class="view-editor-header__item view-editor-header__item_margin_l view-editor-header__item_first">
        <app-view-editor-menu-button
          [componentLabel]="componentLabel"
          [submitLabel]="submitLabel"
          [stateSelectedEnabled]="stateSelectedEnabled"
          [templatesEnabled]="templatesEnabled"
          [analyticsSource]="analyticsSource"
          (saveEditor)="submit()"
          (closeEditor)="onCloseClick()"
          (rename)="setNameEditing(true)"
          (importFigmaNode)="importFigmaNode()"
          (importSketchFile)="importSketchFile()"
        ></app-view-editor-menu-button>
      </div>

      <app-view-editor-tools
        [viewContext]="viewContext"
        class="view-editor-header__item view-editor-header__item_first"
      ></app-view-editor-tools>

      <div class="view-editor-header-buttons view-editor-header__item">
        <div
          *ngFor="let item of viewMenuItems; let first = first; let last = last"
          class="view-editor-header-button view-editor-header__item"
          [class.view-editor-header-button_active]="currentViewMenu == item.type"
          [class.view-editor-header__item_first]="first"
          [class.view-editor-header__item_last]="last"
          (click)="onViewMenuClick(item.type)"
        >
          <div
            *ngIf="item.icon"
            class="view-editor-header-button__icon view-editor-header-button__icon_left"
            [ngClass]="'icon-' + item.icon"
            [style.font-size.px]="(item.iconSize | appIsSet) ? item.iconSize : null"
          ></div>
          <div class="view-editor-header-button__label">{{ item.label }}</div>

          <div
            *ngIf="item.type == viewMenus.Parameters"
            class="view-editor-header-button__counter"
            [class.view-editor-header-button__counter_visible]="
              currentView.parameters.length > 0 && currentViewMenu != item.type
            "
          >
            {{ currentView.parameters.length }}
          </div>

          <div
            *ngIf="item.type == viewMenus.Actions"
            class="view-editor-header-button__counter"
            [class.view-editor-header-button__counter_visible]="
              currentView.actions.length > 0 && currentViewMenu != item.type
            "
          >
            {{ currentView.actions.length }}
          </div>
        </div>
      </div>
    </div>
    <div class="view-editor-header__main">
      <div
        *ngIf="nameEditingEnabled"
        class="view-editor-header-input"
        [class.view-editor-header-input_active]="nameEditing"
        (click)="setNameEditing(true)"
      >
        <input
          *ngIf="nameEditing"
          class="view-editor-header-input__control"
          [(ngModel)]="currentView.name"
          [appAutofocus]="true"
          appKeyEvent
          (enterUp)="input.blur()"
          (blur)="cleanName(); setNameEditing(false)"
          #input
        />
        <div *ngIf="!nameEditing" class="view-editor-header-input__control">{{ currentView.name }}</div>
        <div *ngIf="!nameEditing" class="view-editor-header-input__icon icon-pen"></div>
      </div>
    </div>
    <div class="view-editor-header__right">
      <div class="view-editor-header__item">
        <app-zoom-control [viewport]="viewport_element"></app-zoom-control>
      </div>

      <div class="view-editor-header__item">
        <ng-template #undo_tip> Undo <app-tip-hotkey [metaKey]="true" [key]="'Z'"></app-tip-hotkey> </ng-template>

        <button
          class="button button_icon button_grey button_segment-left button_no-margin"
          [class.button_disabled]="!(isUndoAvailable$ | async)"
          [appTip]="undo_tip"
          [appTipOptions]="{ side: 'bottom' }"
          (click)="editorContext.undo()"
        >
          <span class="button__icon icon-undo"></span>
        </button>

        <ng-template #redo_tip>
          Redo <app-tip-hotkey [metaKey]="true" [shiftKey]="true" [key]="'Z'"></app-tip-hotkey>
        </ng-template>

        <button
          class="button button_icon button_grey button_segment-right button_no-margin"
          [class.button_disabled]="!(isRedoAvailable$ | async)"
          [appTip]="redo_tip"
          [appTipOptions]="{ side: 'bottom' }"
          (click)="editorContext.redo()"
        >
          <span class="button__icon icon-redo"></span>
        </button>
      </div>

      <a
        href="javascript:void(0)"
        class="button button_primary button_orange view-editor-header__item view-editor-header__item_last"
        (click)="submit()"
      >
        <span class="button__label">{{ submitLabel }}</span>
      </a>
    </div>
  </div>

  <div class="view-editor__main">
    <div *ngIf="editorContext.showInterface$ | async" class="view-editor__left-sidebar">
      <app-view-editor-navigator [view]="currentView"></app-view-editor-navigator>

      <app-view-editor-parameters
        [componentLabel]="componentLabel"
        [parameters]="currentView.parameters"
        [parametersEditable]="true"
        [stateSelectedEnabled]="stateSelectedEnabled"
        [testParameters]="currentView.testParameters"
        [testState]="currentView.testState"
        [opened]="currentViewMenu == viewMenus.Parameters"
        (parametersChanged)="updateParameters($event)"
        (testParametersChanged)="updateTestParameters($event)"
        (testStateChanged)="updateTestState($event)"
        (closed)="closeViewMenu(viewMenus.Parameters)"
      ></app-view-editor-parameters>

      <app-view-editor-actions
        [actions]="currentView.actions"
        [opened]="currentViewMenu == viewMenus.Actions"
        (actionsChanged)="updateActions($event)"
        (closed)="closeViewMenu(viewMenus.Actions)"
      ></app-view-editor-actions>
    </div>

    <div class="view-editor__viewport" #viewport_element>
      <app-view-editor-viewport
        [view]="currentView"
        [viewContext]="viewContext"
        [componentLabel]="componentLabel"
        [stateSelectedEnabled]="stateSelectedEnabled"
        [templatesEnabled]="templatesEnabled"
        [dark]="currentView.testState[DARK_THEME_OUTPUT]"
        (importFigmaNode)="importFigmaNode()"
        (importSketchFile)="importSketchFile()"
      ></app-view-editor-viewport>
    </div>

    <div
      *ngIf="editorContext.showInterface$ | async"
      class="view-editor__right-sidebar theme_dark"
      [class.view-editor__right-sidebar_disabled]="
        !(editorContext.customizingLayers$ | async).length && !(editorContext.customizingView$ | async)
      "
    >
      <app-view-editor-sidebar [viewContext]="viewContext"></app-view-editor-sidebar>
    </div>
  </div>
</div>

export function serializeFieldParamName(fieldName: string, lookup?: string, exclude: boolean = false): string {
  const parts = [];

  if (exclude) {
    parts.push('exclude');
  }

  parts.push(fieldName.replace(/__/g, '%5F%5F'));

  if (lookup) {
    parts.push(lookup);
  }

  return parts.join('__');
}

export function deserializeFieldParamName(param: string): { name: string; lookup: string; exclude: boolean } {
  // const param = key.split('__', 3);
  // let exclude, name, lookupStr;
  //
  // if (param[0] == 'exclude') {
  //   exclude = true;
  //   name = param[1];
  //   lookup = param[2];
  // } else {
  //   exclude = false;
  //   name = param[0];
  //   lookup = param[1];
  // }
  const excludePrefix = 'exclude__';
  const exclude = param.startsWith(excludePrefix);

  if (exclude) {
    param = param.substring(excludePrefix.length);
  }

  const lookupMatch = param.match(/([\s\S]+)__(\w+)$/);
  const lookup = lookupMatch ? lookupMatch[2] : '';
  const field = lookupMatch ? lookupMatch[1] : param;

  return {
    name: field.replace(/%5F%5F/g, '__'),
    lookup: lookup,
    exclude: exclude
  };
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../image-codes/components/scanner/scanner.component.ngfactory";
import * as i2 from "../../../image-codes/components/scanner/scanner.component";
import * as i3 from "../../../../common/notifications/services/notification/notification.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../customize/data/view-context-element";
import * as i6 from "../../../customize/data/view-context";
import * as i7 from "./scanner-element.component";
import * as i8 from "../../../customize/services/customize/customize.service";
import * as i9 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i10 from "../custom-page-popup/custom-page-popup.component";
var styles_ScannerElementComponent = [];
var RenderType_ScannerElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScannerElementComponent, data: {} });
export { RenderType_ScannerElementComponent as RenderType_ScannerElementComponent };
function View_ScannerElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-scanner", [], null, [[null, "scan"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scan" === en)) {
        var pd_0 = (_co.onScan($event, i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ScannerComponent_0, i1.RenderType_ScannerComponent)), i0.ɵdid(1, 4964352, [["scan_element", 4]], 0, i2.ScannerComponent, [i0.ElementRef, i3.NotificationService, i0.ChangeDetectorRef], { scanningInitial: [0, "scanningInitial"], scanConfirm: [1, "scanConfirm"] }, { scan: "scan" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.scanningInitial; var currVal_1 = _co.element.scanConfirm; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ScannerElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ScannerElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeEnabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ScannerElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-scanner-element", [], null, null, null, View_ScannerElementComponent_0, RenderType_ScannerElementComponent)), i0.ɵprd(131584, null, i5.ViewContextElement, i5.ViewContextElement, [i6.ViewContext]), i0.ɵdid(2, 770048, null, 0, i7.ScannerElementComponent, [i8.CustomizeService, i5.ViewContextElement, i9.ActionControllerService, [2, i10.CustomPagePopupComponent], i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ScannerElementComponentNgFactory = i0.ɵccf("app-scanner-element", i7.ScannerElementComponent, View_ScannerElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { ScannerElementComponentNgFactory as ScannerElementComponentNgFactory };

import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class BackElementItem extends ElementItem {
  public type = ElementType.Back;
  public previousPageAction: ActionItem;
  public previousPageTitleInput: Input;
  public titleInput: Input;

  deserialize(data: Object): BackElementItem {
    super.deserialize(data);

    if (this.params['title_input']) {
      this.titleInput = new Input().deserialize(this.params['title_input']);
    } else {
      this.titleInput = undefined;
    }

    if (this.params['previous_page_action']) {
      this.previousPageAction = new ActionItem().deserialize(this.params['previous_page_action']);
    } else {
      this.previousPageAction = undefined;
    }

    if (this.params['previous_page_title_input']) {
      this.previousPageTitleInput = new Input().deserialize(this.params['previous_page_title_input']);
    } else {
      this.previousPageTitleInput = undefined;
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      title_input: this.titleInput ? this.titleInput.serialize() : undefined,
      previous_page_action: this.previousPageAction ? this.previousPageAction.serialize() : undefined,
      previous_page_title_input: this.previousPageTitleInput ? this.previousPageTitleInput.serialize() : undefined
    };

    return super.serialize();
  }

  get analyticsName(): string {
    return 'back';
  }

  defaultName() {
    return 'Back';
  }
}

registerElementForType(ElementType.Back, BackElementItem);

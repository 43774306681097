import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';

import { FieldsDisplayEditOverlayComponent } from './components/fields-display-edit-overlay/fields-display-edit-overlay.component';
import { FieldsDisplayEditComponent } from './components/fields-display-edit/fields-display-edit.component';
import { FilterEditOverlayComponent } from './components/filter-edit-overlay/filter-edit-overlay.component';
import { FilterEditValueComponent } from './components/filter-edit-value/filter-edit-value.component';
import { FilterEditComponent } from './components/filter-edit/filter-edit.component';
import { FiltersListItemComponent } from './components/filters-list-item/filters-list-item.component';
import { FiltersListComponent } from './components/filters-list/filters-list.component';
import { ModelFieldMenuComponent } from './components/model-field-menu/model-field-menu.component';
import { SelectDataSourceFieldComponent } from './components/select-data-source-field/select-data-source-field.component';
import { SelectModelFieldStubComponent } from './components/select-model-field-stub/select-model-field-stub.component';
import { SelectModelFieldComponent } from './components/select-model-field/select-model-field.component';
import { SortAddOverlayComponent } from './components/sort-add-overlay/sort-add-overlay.component';
import { SortAddComponent } from './components/sort-add/sort-add.component';
import { SortEditItemComponent } from './components/sort-edit-item/sort-edit-item.component';
import { SortEditOverlayComponent } from './components/sort-edit-overlay/sort-edit-overlay.component';
import { SortEditComponent } from './components/sort-edit/sort-edit.component';
import { SortItemComponent } from './components/sort-item/sort-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    FieldsModule,
    ReactiveFormsModule,
    OverlayModule,
    FieldComponentsModule,
    LocalizeModule,
    TipsModule,
    SharedModule,
    DragDropModule,
    ThemeComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    FilterEditComponent,
    FiltersListComponent,
    FiltersListItemComponent,
    FilterEditOverlayComponent,
    ModelFieldMenuComponent,
    SortEditComponent,
    SortEditOverlayComponent,
    SortAddOverlayComponent,
    SortAddComponent,
    SortItemComponent,
    SortEditItemComponent,
    SelectModelFieldComponent,
    SelectDataSourceFieldComponent,
    SelectModelFieldStubComponent,
    FieldsDisplayEditOverlayComponent,
    FieldsDisplayEditComponent,
    FilterEditValueComponent
  ],
  exports: [
    FilterEditComponent,
    FiltersListComponent,
    FilterEditOverlayComponent,
    ModelFieldMenuComponent,
    SortEditComponent,
    SortEditOverlayComponent,
    SortAddOverlayComponent,
    SortAddComponent,
    SortItemComponent,
    SelectModelFieldComponent,
    SelectDataSourceFieldComponent,
    FieldsDisplayEditOverlayComponent
  ]
})
export class FiltersComponentsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxSelectModule } from 'ng-gxselect';
import { AceEditorModule } from 'ng2-ace-editor';

import { DynamicComponentModule } from '@common/dynamic-component';
import { ImageEditorModule } from '@common/image-editor';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { AutoFieldComponent } from './components/auto-field/auto-field.component';
import { ToggleContentComponent } from './components/toggle-content/toggle-content.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    NgGxDatepickerModule,
    AceEditorModule,
    ImageEditorModule,
    SharedModule,
    DynamicComponentModule
  ],
  declarations: [AutoFieldComponent, ToggleContentComponent],
  exports: [AutoFieldComponent, ToggleContentComponent],
  entryComponents: [AutoFieldComponent, ToggleContentComponent]
})
export class FieldsModule {}

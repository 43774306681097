/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ng-gxscrollable";
import * as i2 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i3 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i4 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i5 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i6 from "../../../field-components/components/action-outputs-edit/action-outputs-edit.component.ngfactory";
import * as i7 from "../../../field-components/components/action-outputs-edit/action-outputs-edit.component";
import * as i8 from "./view-editor-actions.form";
import * as i9 from "./view-editor-actions.component";
import * as i10 from "../../../../common/popups/components/base-popup/base-popup.component";
var styles_ViewEditorActionsComponent = [];
var RenderType_ViewEditorActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorActionsComponent, data: {} });
export { RenderType_ViewEditorActionsComponent as RenderType_ViewEditorActionsComponent };
export function View_ViewEditorActionsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "view-editor__data-menu"]], [[2, "view-editor__data-menu_visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markClickEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "view-editor__data-menu-viewport"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(2, 12664832, null, 0, i1.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(3, { stopPropagationAlways: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 6, "app-sidebar-section", [], null, null, null, i2.View_SidebarSectionComponent_0, i2.RenderType_SidebarSectionComponent)), i0.ɵdid(5, 114688, null, 0, i3.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, 3, 4, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "app-sidebar-field", [], null, null, null, i4.View_SidebarFieldComponent_0, i4.RenderType_SidebarFieldComponent)), i0.ɵdid(8, 114688, null, 0, i5.SidebarFieldComponent, [], { label: [0, "label"], subtitle: [1, "subtitle"] }, null), (_l()(), i0.ɵeld(9, 0, null, 3, 1, "app-action-outputs-edit", [], null, null, null, i6.View_ActionOutputsEditComponent_0, i6.RenderType_ActionOutputsEditComponent)), i0.ɵdid(10, 114688, null, 0, i7.ActionOutputsEditComponent, [], { control: [0, "control"], analyticsSource: [1, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, true); _ck(_v, 2, 0, currVal_1); _ck(_v, 5, 0); var currVal_2 = "Component Actions"; var currVal_3 = "Component actions allow you to trigger actions outside of this component"; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.form.controls.actions; var currVal_5 = _co.analyticsSource; _ck(_v, 10, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 0, 0, currVal_0); }); }
export function View_ViewEditorActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-view-editor-actions", [], null, null, null, View_ViewEditorActionsComponent_0, RenderType_ViewEditorActionsComponent)), i0.ɵprd(512, null, i8.ViewEditorActionsForm, i8.ViewEditorActionsForm, []), i0.ɵdid(2, 770048, null, 0, i9.ViewEditorActionsComponent, [[2, i10.BasePopupComponent], i8.ViewEditorActionsForm, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ViewEditorActionsComponentNgFactory = i0.ɵccf("app-view-editor-actions", i9.ViewEditorActionsComponent, View_ViewEditorActionsComponent_Host_0, { actions: "actions", opened: "opened" }, { actionsChanged: "actionsChanged", closed: "closed" }, []);
export { ViewEditorActionsComponentNgFactory as ViewEditorActionsComponentNgFactory };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import { Segue, SegueType } from '@modules/actions';
import {
  SUBMIT_RESULT_OUTPUT,
  ViewContext,
  ViewContextElement,
  ViewSettings,
  ViewSettingsStore
} from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { CustomSelectItem, CustomSelectItemButton } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-link',
  templateUrl: './customize-bar-action-edit-type-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeLinkComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  linkOptions: CustomSelectItem<Segue>[];
  page: ViewSettings;
  segueTypes = SegueType;

  constructor(
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsQueries: ViewSettingsQueries,
    private actionControllerService: ActionControllerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form
      .linkOptions$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.linkOptions = [
          ...result.map(item => {
            return {
              option: {
                ...item,
                icon: 'document'
              }
            };
          }),
          {
            button: {
              name: 'add_page',
              label: 'Add Page',
              icon: 'plus'
            },
            stickyBottom: true,
            orange: true,
            large: true
          }
        ];
        this.cd.markForCheck();
      });

    this.form
      .linkPage$()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.page = viewSettings;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  openPage() {
    if (!this.page) {
      return;
    }

    const action = this.form.submit().action;

    action.approve = undefined;
    action.onSuccessActions = [];
    action.onErrorActions = [];

    this.actionControllerService
      .execute(action, {
        context: this.context,
        contextElement: this.contextElement,
        saveResultTo: SUBMIT_RESULT_OUTPUT,
        analyticsSource: this.analyticsSource + '_settings'
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onButtonItemClick(button: CustomSelectItemButton) {
    if (button.name == 'add_page') {
      this.viewSettingsStore
        .getDistinctName('New Page')
        .pipe(
          switchMap(name => this.viewSettingsQueries.promptCreatePage({ defaultName: name })),
          untilDestroyed(this)
        )
        .subscribe(page => {
          if (page) {
            this.form.controls.link.patchValue({ type: SegueType.Page, page: page.uniqueName });
          }
        });
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';

import { BasePopupComponent } from '@common/popups';

import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { MixpanelResourceSettingsForm } from './mixpanel-resource-settings.form';

@Component({
  selector: 'app-mixpanel-resource-settings',
  templateUrl: './mixpanel-resource-settings.component.html',
  providers: [MixpanelResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MixpanelResourceSettingsComponent extends BaseResourceSettingsComponent {
  constructor(
    public form: MixpanelResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }
}

registerResourceSettingsComponent(ResourceName.Mixpanel, MixpanelResourceSettingsComponent);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { LocalizeModule } from '@common/localize';
import { ActivitiesComponentsModule } from '@modules/activities-components';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { TaskPopupComponent } from './components/task-popup/task-popup.component';
import { TaskQueueEditPopupComponent } from './components/task-queue-edit-popup/task-queue-edit-popup.component';

@NgModule({
  imports: [
    CommonModule,
    NgGxScrollableModule,
    ReactiveFormsModule,
    FieldsModule,
    FieldComponentsModule,
    SharedModule,
    UiModule,
    NgGxSelectModule,
    LocalizeModule,
    ActivitiesComponentsModule,
    RoutingModule
  ],
  declarations: [TaskPopupComponent, TaskQueueEditPopupComponent],
  exports: [TaskPopupComponent, TaskQueueEditPopupComponent],
  entryComponents: [TaskPopupComponent, TaskQueueEditPopupComponent]
})
export class CollaborationComponentsModule {}

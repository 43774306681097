import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AppConfigService } from '@core';

@Directive({
  selector: 'appAutofocus, [appAutofocus]'
})
export class AutofocusDirective implements OnChanges {
  @Input() appAutofocus = false;

  constructor(private el: ElementRef, private appConfigService: AppConfigService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.appConfigService.isPlatformServer()) {
      return;
    }

    if (changes['appAutofocus'].currentValue) {
      setTimeout(() => {
        this.focus();
      }, 0);
    }
  }

  focus() {
    this.el.nativeElement.focus();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "../../../../common/tips/directives/tip/tip.directive";
import * as i4 from "./input-edit-context-value.component";
var styles_InputEditContextValueComponent = [];
var RenderType_InputEditContextValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputEditContextValueComponent, data: {} });
export { RenderType_InputEditContextValueComponent as RenderType_InputEditContextValueComponent };
function View_InputEditContextValueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-pen va-m"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Edit as formula "]))], null, null); }
function View_InputEditContextValueComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], [[2, "text_muted", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \u00B7 "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.index >= 1); _ck(_v, 0, 0, currVal_0); }); }
function View_InputEditContextValueComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], [[2, "text_muted", null]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditContextValueComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = !_v.context.last; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index >= 2); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_InputEditContextValueComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputEditContextValueComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valuePath; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputEditContextValueComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Is not set"]))], null, null); }
export function View_InputEditContextValueComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "input input_container"]], [[2, "input_fill", null]], null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(0, [["edit_tip", 2]], null, 0, null, View_InputEditContextValueComponent_1)), (_l()(), i0.ɵeld(4, 0, null, null, 13, "div", [["class", "input-tag input-tag_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "span", [["class", "input-tag__main"]], null, null, null, null, null)), i0.ɵdid(6, 737280, null, 0, i3.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(7, { side: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InputEditContextValueComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InputEditContextValueComponent_5)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1), (_l()(), i0.ɵeld(14, 0, null, null, 3, "span", [["class", "input-tag__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "span", [["class", "input-tag__control icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.reset.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 737280, null, 0, i3.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(17, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "input input_container"; var currVal_2 = _co.classes; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = i0.ɵnov(_v, 3); var currVal_4 = _ck(_v, 7, 0, "top"); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = (i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), _co.valueControl.value)) && _co.valuePath); _ck(_v, 9, 0, currVal_5); var currVal_6 = !i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.valueControl.value)); _ck(_v, 12, 0, currVal_6); var currVal_7 = "Clear"; var currVal_8 = _ck(_v, 17, 0, "top"); _ck(_v, 16, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; _ck(_v, 1, 0, currVal_0); }); }
export function View_InputEditContextValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-edit-context-value", [], null, null, null, View_InputEditContextValueComponent_0, RenderType_InputEditContextValueComponent)), i0.ɵdid(1, 770048, null, 0, i4.InputEditContextValueComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputEditContextValueComponentNgFactory = i0.ɵccf("app-input-edit-context-value", i4.InputEditContextValueComponent, View_InputEditContextValueComponent_Host_0, { valueControl: "valueControl", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", classes: "classes", fill: "fill" }, { edit: "edit", reset: "reset" }, []);
export { InputEditContextValueComponentNgFactory as InputEditContextValueComponentNgFactory };

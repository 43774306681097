var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RouteReuseStrategy } from '@angular/router';
var ComponentRouteReuseStrategy = /** @class */ (function (_super) {
    __extends(ComponentRouteReuseStrategy, _super);
    function ComponentRouteReuseStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ComponentRouteReuseStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    ComponentRouteReuseStrategy.prototype.store = function (route, handle) { };
    ComponentRouteReuseStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    ComponentRouteReuseStrategy.prototype.retrieve = function (route) {
        return null;
    };
    ComponentRouteReuseStrategy.prototype.shouldReuseRoute = function (future, curr) {
        if (future && curr && future.component && future.component == curr.component) {
            return true;
        }
        return future && curr && future.routeConfig === curr.routeConfig;
    };
    return ComponentRouteReuseStrategy;
}(RouteReuseStrategy));
export { ComponentRouteReuseStrategy };

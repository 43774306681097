export function fromLegacyModel(model: string) {
  if (!model || window['legacyModels']) {
    return model;
  }

  return model.replace(';', '_');
}

export function processLegacyModelDescriptions(modelDescriptions: Object[]) {
  if (!window['legacyModels']) {
    const remove = [];

    modelDescriptions.forEach(item => {
      const parts = item['model'].split(';', 2);

      if (parts.length != 2) {
        return;
      }

      if (!modelDescriptions.find(newItem => newItem['model'] == `${parts[0]}_${parts[1]}`)) {
        return;
      }

      remove.push(item['model']);
    });

    modelDescriptions = modelDescriptions.filter(item => !remove.includes(item['model']));
  }

  return modelDescriptions;
}

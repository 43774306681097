import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DocumentService } from '@core';

import { isElementInViewport } from '../../utils/document-utils/document-utils';

export interface VisibleParams {
  firstOnly?: number;
  intervalCheck?: number;
}

@Directive({
  selector: '[appElement]'
})
export class ElementDirective implements OnInit, OnDestroy, AfterViewChecked {
  @Input() appElementDetectVisible: VisibleParams | boolean = false;
  @Output() appElementFirstVisible = new EventEmitter<void>();
  @Output() appElementVisible = new EventEmitter<boolean>();
  @Output() appElementInit = new EventEmitter<ElementRef>();
  @Output() appElementDestroy = new EventEmitter<ElementRef>();

  visibleOptions: VisibleParams;
  firstVisible = false;
  visible = false;
  visibilitySubscriptions: Subscription[] = [];
  observer: IntersectionObserver;
  elementAttached = false;

  constructor(private el: ElementRef, private documentService: DocumentService) {}

  ngOnInit(): void {
    if (this.appElementDetectVisible) {
      this.initVisible();
    }

    this.appElementInit.emit(this.el);
  }

  ngOnDestroy(): void {
    this.visibilitySubscriptions.forEach(item => item.unsubscribe());

    if (this.observer) {
      this.observer.disconnect();
    }

    this.appElementDestroy.emit(this.el);
  }

  ngAfterViewChecked(): void {
    if (this.appElementDetectVisible) {
      this.checkAttached();
    }
  }

  checkAttached() {
    if (this.elementAttached) {
      return;
    }

    const attached = document.contains(this.el.nativeElement);

    if (attached) {
      this.elementAttached = true;
      this.onAttached();
    }
  }

  onAttached() {
    this.checkVisibility();
  }

  initVisible() {
    if (typeof this.appElementDetectVisible === 'object') {
      this.visibleOptions = this.appElementDetectVisible;
    } else if (this.appElementDetectVisible) {
      this.visibleOptions = {};
    } else {
      return;
    }

    this.visibleOptions = defaults({}, this.visibleOptions, {
      firstOnly: true,
      intervalCheck: undefined
    });

    const IntersectionObserver = window['IntersectionObserver'];

    if (IntersectionObserver) {
      this.observer = new IntersectionObserver((entries, observe) => {
        const visible = entries.some(item => item['isIntersecting']);
        this.setVisibility(visible);
      });
      this.observer.observe(this.el.nativeElement);

      // // TODO: Optimize check (needed to JSON field select)
      // timer(0)
      //   .pipe(untilDestroyed(this))
      //   .subscribe(() => this.checkVisibility());
    } else {
      if (this.visibleOptions.intervalCheck) {
        this.visibilitySubscriptions.push(
          interval(this.visibleOptions.intervalCheck)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.checkVisibility())
        );
      }

      this.visibilitySubscriptions.push(
        fromEvent(window, 'scroll')
          .pipe(debounceTime(30), untilDestroyed(this))
          .subscribe(() => this.checkVisibility())
      );

      this.visibilitySubscriptions.push(
        this.documentService.viewportSizeChanges$.pipe(untilDestroyed(this)).subscribe(() => this.checkVisibility())
      );

      setTimeout(() => this.checkVisibility(), 0);
    }
  }

  checkVisibility() {
    const visible = isElementInViewport(this.el.nativeElement);

    this.setVisibility(visible);
  }

  setVisibility(visible) {
    if (visible == this.visible) {
      return;
    }

    this.visible = visible;
    this.appElementVisible.next(this.visible);

    if (this.visible && !this.firstVisible) {
      this.firstVisible = true;
      this.appElementFirstVisible.emit();

      if (this.visibleOptions.firstOnly) {
        this.visibilitySubscriptions.forEach(item => item.unsubscribe());
        this.visibilitySubscriptions = [];

        if (this.observer) {
          this.observer.disconnect();
        }
      }
    }
  }
}

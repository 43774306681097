<a
  href="javascript:void(0)"
  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
  [class.token-popup-list-item_active]="false"
  (click)="clickItem.emit(item)"
>
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__line">
      <span *ngIf="item.icon" class="token-popup-list-item__left">
        <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
      </span>
      <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.label">{{
        item.label
      }}</span>
      <span class="token-popup-list-item__right token-popup-list-item__right_wrap">
        {{ value || item.name }}
      </span>
    </div>
  </div>
</a>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { SelectSource } from '@common/select';
import { Option } from '@modules/field-components';
import { isSet, TypedChanges } from '@shared';

import { defaultCompare } from '../chips/chips.component';

@Component({
  selector: 'app-chips-item',
  templateUrl: './chips-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value: any;
  @Input() source: SelectSource;
  @Input() options: Option[] = [];
  @Input() addedOptions: Option[] = [];
  @Input() compareWith: (o1: any, o2: any) => boolean = defaultCompare;
  @Output() remove = new EventEmitter<MouseEvent>();

  loading = true;
  item: Option;
  value$ = new BehaviorSubject<any>(undefined);
  source$ = new BehaviorSubject<SelectSource>(undefined);
  options$ = new BehaviorSubject<Option[]>([]);
  addedOptions$ = new BehaviorSubject<Option[]>([]);

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initOption();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: TypedChanges<ChipsItemComponent>): void {
    if (changes.value) {
      this.value$.next(this.value);
    }

    if (changes.source) {
      this.source$.next(this.source);
    }

    if (changes.options) {
      this.options$.next(this.options);
    }

    if (changes.addedOptions) {
      this.addedOptions$.next(this.addedOptions);
    }
  }

  initOption() {
    combineLatest(
      this.value$.pipe(distinctUntilChanged((lhs, rhs) => this.compareWith(lhs, rhs))),
      this.source$,
      this.options$
    )
      .pipe(
        switchMap(([value, source, options]) => {
          this.loading = false;
          this.cd.markForCheck();

          const addedOption = this.addedOptions.find(option => this.compareWith(option.value, value));

          if (addedOption) {
            return of(addedOption);
          } else if (source) {
            this.loading = true;
            this.cd.markForCheck();

            if (!isSet(value)) {
              return of<Option>(undefined);
            }

            return (source.fetchByValue([value]) as Observable<Option[]>).pipe(
              map(result => result[0]),
              catchError(() => {
                return of<Option>({ value: value, name: value });
              })
            );
          } else {
            const existingOption = options.find(option => this.compareWith(option.value, value));
            if (existingOption) {
              return of(existingOption);
            } else {
              return of<Option>({ value: value, name: value });
            }
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(option => {
        this.item = option;
        this.loading = false;
        this.cd.markForCheck();
      });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-zig-zag-connection',
  templateUrl: './zig-zag-connection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZigZagConnectionComponent implements OnInit, OnChanges {
  @Input() left = false;
  @Input() width: number;
  @Input() height: number;
  @Input() horizontalY: number;
  @Input() color = '#ccd3de';

  viewBox: string;
  pathD: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.viewBox = `0 0 ${this.width} ${this.height}`;
    this.pathD = this.getPathD(this.width, this.height, this.horizontalY, this.left);
  }

  getPathD(width: number, height: number, horizontalY: number, left: boolean) {
    if (left) {
      return [
        `M${width - 1},0`,
        `C${width - 1},${horizontalY} ${width - 1},${horizontalY} ${(width - 1) / 2},${horizontalY}`,
        `C1,${horizontalY} 1,${horizontalY} 1,${height}`
      ].join(' ');
    } else {
      return [
        `M1,0`,
        `C1,${horizontalY} 1,${horizontalY} ${(width - 1) / 2},${horizontalY}`,
        `C${width - 1},${horizontalY} ${width - 1},${horizontalY} ${width - 1},${height}`
      ].join(' ');
    }
  }
}

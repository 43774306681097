<td class="table__column table__column_left-padding"></td>
<td class="table__column">
  <span [class.loading-animation]="animating">
    <span class="stub-text">John Cooper</span>
  </span>
</td>
<td *ngIf="group" class="table__column">
  <span [class.loading-animation]="animating">
    <span class="stub-text">Administrators</span>
  </span>
</td>
<td class="table__column">
  <span [class.loading-animation]="animating">
    <span class="stub-text">November 30, 2020</span>
  </span>
</td>
<td class="table__column"></td>
<td class="table__column"></td>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { SqlQuery } from '@modules/queries';
var QueryBuilderSqlForm = /** @class */ (function (_super) {
    __extends(QueryBuilderSqlForm, _super);
    function QueryBuilderSqlForm() {
        return _super.call(this, {
            query: new FormControl(''),
            request_response: new FormControl(undefined),
            request_response_columns: new FormControl(undefined),
            request_tokens: new FormControl({})
        }) || this;
    }
    QueryBuilderSqlForm.prototype.init = function (query) {
        if (query) {
            this.controls.query.patchValue(query.query);
            this.controls.request_response.patchValue(query.requestResponse);
            this.controls.request_response_columns.patchValue(query.requestResponseColumns);
            this.controls.request_tokens.patchValue(query.requestTokens);
        }
    };
    QueryBuilderSqlForm.prototype.getInstance = function () {
        var instance = new SqlQuery();
        instance.query = this.controls.query.value;
        instance.requestResponse = this.controls.request_response.value;
        instance.requestResponseColumns = this.controls.request_response_columns.value;
        instance.requestTokens = this.controls.request_tokens.value;
        return instance;
    };
    return QueryBuilderSqlForm;
}(FormGroup));
export { QueryBuilderSqlForm };

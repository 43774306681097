import { Input } from '@modules/fields';

import { MenuItem } from './menu-item';
import { MenuItemAction } from './menu-item-action';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export enum ImageMenuItemOption {
  UserPhoto = 'user_photo',
  ProjectLogo = 'project_logo'
}

export class ImageMenuItem extends MenuItem {
  public type = MenuItemType.Image;
  public imageOption: ImageMenuItemOption;
  public imageIcon: string;
  public imageCustom: string;
  public imageFill = false;
  public imageColor: string;
  public imageSize = 32;
  public imageBorderRadius = 50;
  public titleInput: Input;
  public subtitleInput: Input;
  public action: MenuItemAction;

  deserialize(data: Object): ImageMenuItem {
    super.deserialize(data);

    this.imageOption = this.params['image_option'];
    this.imageIcon = this.params['image_icon'];
    this.imageCustom = this.params['image_custom'];
    this.imageFill = this.params['image_fill'];
    this.imageColor = this.params['image_color'];
    this.imageSize = this.params['image_size'];
    this.imageBorderRadius = this.params['image_border_radius'];

    if (this.params['title_input']) {
      this.titleInput = new Input().deserialize(this.params['title_input']);
    }

    if (this.params['subtitle_input']) {
      this.subtitleInput = new Input().deserialize(this.params['subtitle_input']);
    }

    if (this.params['action']) {
      this.action = new MenuItemAction().deserialize(this.params['action']);
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['image_option'] = this.imageOption;
    data['params']['image_icon'] = this.imageIcon;
    data['params']['image_custom'] = this.imageCustom;
    data['params']['image_fill'] = this.imageFill;
    data['params']['image_color'] = this.imageColor;
    data['params']['image_size'] = this.imageSize;
    data['params']['image_border_radius'] = this.imageBorderRadius;
    data['params']['title_input'] = this.titleInput ? this.titleInput.serialize() : null;
    data['params']['subtitle_input'] = this.subtitleInput ? this.subtitleInput.serialize() : null;
    data['params']['action'] = this.action ? this.action.serialize() : undefined;
    return data;
  }

  copyFrom(item: ImageMenuItem) {
    super.copyFrom(item);
    this.imageOption = item.imageOption;
    this.imageIcon = item.imageIcon;
    this.imageCustom = item.imageCustom;
    this.imageFill = item.imageFill;
    this.imageColor = item.imageColor;
    this.imageSize = item.imageSize;
    this.imageBorderRadius = item.imageBorderRadius;
    this.titleInput = item.titleInput ? new Input().deserialize(item.titleInput.serialize()) : undefined;
    this.subtitleInput = item.subtitleInput ? new Input().deserialize(item.subtitleInput.serialize()) : undefined;
  }

  clone(): ImageMenuItem {
    const instance = new ImageMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Image, ImageMenuItem);

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { Input, InputFilterField, InputValueType } from '@modules/fields';
import { isSet } from '@shared';

import { transformFormulaElementAccessors } from './formula';

export function getInputRepresentation(
  input: Input,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextElementPath?: (string | number)[];
    contextElementPaths?: (string | number)[][];
    filterFields?: InputFilterField[];
  }
): string | Observable<string> {
  if (input.valueType == InputValueType.Prompt) {
    return `is specified by user`;
  } else if (input.valueType == InputValueType.EmptyString) {
    return `is empty string`;
  } else if (input.valueType == InputValueType.Null) {
    return `is null`;
  } else if (input.valueType == InputValueType.StaticValue) {
    if (isSet(input.staticValue)) {
      return `is ${input.staticValue}`;
    } else {
      return;
    }
  } else if (input.valueType == InputValueType.Filter) {
    // return this.form.filterFields$().pipe(
    //   map(fields => {
    const field = options.filterFields ? options.filterFields.find(item => item.name == input.filterField) : undefined;
    const lookup = field ? field.lookups.find(item => item.name == input.filterLookup) : undefined;

    if (field && lookup) {
      return `is field filter ${field.name} ${lookup.name}`;
    } else {
      return `is field filter`;
    }
    // })
    // );
  } else if (input.valueType == InputValueType.Context) {
    if (isSet(input.contextValue) && options.context) {
      return options.context
        .tokenPath$(
          input.contextValue,
          options.contextElement,
          options.contextElementPath,
          options.contextElementPaths,
          true
        )
        .pipe(
          map(item => {
            if (!item) {
              return;
            }

            const items = item.reverse();

            if (items.length > 1) {
              return `is ${items[0]} from ${items.slice(1).join(' · ')}`;
            } else {
              return `is value from ${items[0]}`;
            }
          })
        );
    } else {
      return `is value from component`;
    }
  } else if (input.valueType == InputValueType.Formula) {
    if (isSet(input.formulaValue) && options.context) {
      return options.context.elements$.pipe(
        map(() => {
          const formula = transformFormulaElementAccessors(input.formulaValue, options.context, true);
          return `is ${formula}`;
        })
      );
    } else {
      return `formula not set`;
    }
  } else if (input.valueType == InputValueType.TextInputs) {
    return 'is Text with Inputs';
  } else if (input.valueType == InputValueType.Js) {
    return isSet(input.jsValue) ? input.jsValue : `is JavaScript`;
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { PageBlockMarginSize } from '../page-block/page-block.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnInit {
  @HostBinding('class.page-block') blockCls = true;
  @HostBinding('class.page-header') cls = true;
  @Input() marginSize: PageBlockMarginSize;
  @Input() backLink: any[];
  @Input() @HostBinding('class.page-block_first') first = false;
  @Input() @HostBinding('class.page-block_last') last = false;

  @HostBinding('class.page-block_margin-size_s') get marginSizeS() {
    return this.marginSize == 's';
  }

  @HostBinding('class.page-block_margin-size_m') get marginSizeM() {
    return this.marginSize == 'm';
  }

  @HostBinding('class.page-block_margin-size_l') get marginSizeL() {
    return this.marginSize == 'l';
  }

  constructor() {}

  ngOnInit() {}
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <app-page style="display: block; padding-left: 20px;">
    <app-section [top]="true">
      <app-page-header [backLink]="(currentProjectStore.instance$ | async)?.templatesLink">
        <app-page-title>
          <ng-container *ngIf="template">{{ template.name }}</ng-container>
          <ng-container *ngIf="!template">Create Template</ng-container>
        </app-page-title>
        <app-page-description *ngIf="template">Editing template</app-page-description>

        <app-page-header-right>
          <a
            href="javascript:void(0)"
            class="button page-header__right-item"
            [class.button_disabled]="false"
            (click)="dump()"
          >
            <span class="icon-cloud_download button__icon button__icon_left"></span>
            <span class="button__label">Download template</span>
          </a>

          <input
            class="input input_file"
            type="file"
            (change)="onRestoreFileChanged($event.target)"
            [id]="'upload_dump_file'"
          />
          <label
            href="javascript:void(0)"
            class="button page-header__right-item"
            [class.button_disabled]="false"
            [for]="'upload_dump_file'"
          >
            <span class="icon-cloud_upload button__icon button__icon_left"></span>
            <span class="button__label">Import template</span>
          </label>
        </app-page-header-right>
      </app-page-header>
    </app-section>

    <app-section>
      <app-page-block [size]="'m'" [paddingSize]="'none'">
        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({
                name: 'type',
                label: 'type',
                field: 'SelectField',
                required: true,
                params: { options: form.typeOptions }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({ name: 'name', label: 'name', field: 'CharField', required: true, params: { wide: true } })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'subtitle', label: 'subtitle', field: 'CharField', params: { wide: true } })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({
                name: 'description',
                label: 'description',
                field: 'CharField',
                params: { wide: true, multiline: true, rows: 4 }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({
                name: 'logo',
                label: 'logo',
                field: 'FileField',
                params: {
                  path: 'templates/logos',
                  storage_resource: form.getUploaderResource()?.uniqueName,
                  storage_name: form.getUploaderResourceStorage()?.uniqueName,
                  ignore_old_django_format: true,
                  editor: false
                }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'logo_fill', label: 'Logo fill', field: 'BooleanField' })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'icon', label: 'icon', field: 'IconField' })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({ name: 'color', label: 'color', field: 'ColorField', params: { custom_colors: true } })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'featured', label: 'featured', field: 'BooleanField' })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({
                name: 'copy_project',
                label: 'Copy project (unique name)',
                field: 'CharField',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <!--      <app-auto-field [form]="form" [field]="createField({ name: 'resource_type_items', label: 'resources', field: 'CharField' })"></app-auto-field>-->

        <!--      <div class="field">-->
        <!--        <label class="field__label">for resources</label>-->
        <!--        <select formControlName="resource_type_items" style="width: 320px; height: 180px" multiple>-->
        <!--          <option *ngFor="let item of resourceTypeItems" [value]="item.name">{{item.label}}</option>-->
        <!--        </select>-->
        <!--      </div>-->

        <app-page-block>
          <app-page-heading>Tags</app-page-heading>
        </app-page-block>

        <app-page-block *ngFor="let itemForm of form.arrayControls('tags')" [marginSize]="'s'">
          <app-page-card [border]="true">
            <app-page-block [paddingSize]="'s'">
              <app-auto-field
                [form]="itemForm"
                [field]="createField({ name: 'name', label: 'name', required: true, field: 'CharField' })"
              ></app-auto-field>
            </app-page-block>

            <app-page-block [paddingSize]="'s'">
              <app-auto-field
                [form]="itemForm"
                [field]="
                  createField({
                    name: 'color',
                    label: 'color',
                    required: true,
                    field: 'ColorField',
                    params: { custom_colors: true }
                  })
                "
              ></app-auto-field>
            </app-page-block>

            <app-page-block [paddingSize]="'s'">
              <button type="button" class="button button_danger-secondary" (click)="form.arrayRemove('tags', itemForm)">
                Remove Tag
              </button>
            </app-page-block>
          </app-page-card>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <button
            type="button"
            class="button"
            (click)="form.arrayAppend('tags', form.createTagItem({ name: 'Tag', color: 'blue' }))"
          >
            Add Tag
          </button>
        </app-page-block>

        <app-page-block>
          <app-page-heading>Images</app-page-heading>
        </app-page-block>

        <app-page-block *ngFor="let itemForm of form.arrayControls('images')" [marginSize]="'s'">
          <app-page-card [border]="true">
            <app-page-block [paddingSize]="'s'">
              <app-auto-field
                [form]="itemForm"
                [field]="
                  createField({
                    name: 'image',
                    label: 'image',
                    field: 'FileField',
                    required: true,
                    params: {
                      path: 'templates/previews',
                      storage_resource: form.getUploaderResource()?.uniqueName,
                      storage_name: form.getUploaderResourceStorage()?.uniqueName,
                      ignore_old_django_format: true,
                      editor: false
                    }
                  })
                "
              ></app-auto-field>
            </app-page-block>

            <app-page-block [paddingSize]="'s'">
              <button
                type="button"
                class="button button_danger-secondary"
                (click)="form.arrayRemove('images', itemForm)"
              >
                Remove Image
              </button>
            </app-page-block>
          </app-page-card>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <button type="button" class="button" (click)="form.arrayAppend('images', form.createImageItem())">
            Add Image
          </button>
        </app-page-block>

        <app-page-block *ngIf="form.value['type'] == templateTypes.Widget" [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="
              createField({ name: 'update_element', label: 'update element from session', field: 'BooleanField' })
            "
          ></app-auto-field>
          Element: {{ form.value['element'] | json }}
        </app-page-block>

        <app-page-block *ngIf="form.value['type'] == templateTypes.Page" [marginSize]="'s'">
          <div class="field">
            <label class="field__label">pages</label>
            <select formControlName="pages" style="width: 320px; height: 180px;" multiple>
              <option *ngFor="let item of viewSettings" [value]="item.uniqueName"
                >{{ item.name }} ({{ item.uniqueName }})</option
              >
            </select>
          </div>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'ordering', label: 'ordering', required: true, field: 'NumberField' })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <app-auto-field
            [form]="form"
            [field]="createField({ name: 'active', label: 'active', required: true, field: 'BooleanField' })"
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
        </app-page-block>
      </app-page-block>

      <app-section-footer>
        <app-page-block>
          <button type="submit" class="button button_primary" [disabled]="form.invalid">
            <ng-container *ngIf="template">Update Template</ng-container>
            <ng-container *ngIf="!template">Create Template</ng-container>
          </button>
        </app-page-block>
      </app-section-footer>
    </app-section>
  </app-page>
</form>

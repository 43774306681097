import { RoutingService } from '@modules/routing';
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/services/routing/routing.service";
var ProjectPermissionGuard = /** @class */ (function () {
    function ProjectPermissionGuard(routing) {
        this.routing = routing;
    }
    ProjectPermissionGuard.prototype.canActivate = function (next, state) {
        if (!next.data['projectPermission']) {
            return true;
        }
        return true;
        // return this.currentProjectStore.getFirst().pipe(
        //   map(project => {
        //     if (!project) {
        //       return false;
        //     }
        //
        //     if (next.data['projectPermission']['model']) {
        //       let modelId: string;
        //       const conf = next.data['projectPermission']['model'];
        //
        //       if (conf['static']) {
        //         modelId = conf['static'];
        //       } else if (conf['param']) {
        //         modelId = next.params[conf['param']];
        //       }
        //
        //       if (!project.hasModelPermission(modelId, 'r')) {
        //         this.routing.navigateApp(project.homeLink, undefined, project.uniqueName);
        //         return false;
        //       }
        //
        //       return true;
        //     } else {
        //       return true;
        //     }
        //   })
        // );
    };
    ProjectPermissionGuard.ngInjectableDef = i0.defineInjectable({ factory: function ProjectPermissionGuard_Factory() { return new ProjectPermissionGuard(i0.inject(i1.RoutingService)); }, token: ProjectPermissionGuard, providedIn: "root" });
    return ProjectPermissionGuard;
}());
export { ProjectPermissionGuard };

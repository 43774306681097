import { FocusMonitor } from '@angular/cdk/a11y';
import { ContentObserver } from '@angular/cdk/observers';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, ComponentRef, ElementRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { Range } from 'ace-builds';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { isElementHasChild } from '@shared';
// Refactor imports
import { CodeFieldComponent } from '@modules/field-components/components/code-field/code-field.component';
import { SqlFieldComponent } from '@modules/field-components/components/sql-field/sql-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';
import { CONTEXT_TOKEN, InputTokensComponent, InputTokensEventType, SEARCH_TOKEN } from '../../components/input-tokens/input-tokens.component';
var TokenInputDirective = /** @class */ (function () {
    function TokenInputDirective(el, autoField, overlay, focusMonitor, contentObserver, injector) {
        this.el = el;
        this.autoField = autoField;
        this.overlay = overlay;
        this.focusMonitor = focusMonitor;
        this.contentObserver = contentObserver;
        this.injector = injector;
        this.appTokenInput = {};
        this.appTokenEvent = new EventEmitter();
        this.defaultQuote = { open: '{{', close: '}}' };
        this.originOffsetX = 0;
        this.focus$ = new BehaviorSubject(false);
        this.originMove$ = new Subject();
        this.search$ = new BehaviorSubject(undefined);
    }
    TokenInputDirective.prototype.ngOnInit = function () { };
    TokenInputDirective.prototype.ngOnDestroy = function () {
        if (this.contentObserverSubscription) {
            this.contentObserverSubscription.unsubscribe();
        }
        if (this.popoverRef) {
            this.popoverRef.destroy();
        }
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    };
    TokenInputDirective.prototype.ngAfterViewInit = function () {
        this.initEditor();
        this.initOverlay();
        this.initPopover();
    };
    TokenInputDirective.prototype.initEditor = function () {
        var _this = this;
        if (this.autoField &&
            this.autoField.dynamicComponent.currentComponent &&
            (this.autoField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent ||
                this.autoField.dynamicComponent.currentComponent.instance instanceof SqlFieldComponent)) {
            this.ace = this.autoField.dynamicComponent.currentComponent.instance.ace;
            if (this.appTokenInput.trigger) {
                this.origin = this.appTokenInput.origin;
                this.focus$ = fromEvent(window, 'mousedown').pipe(map(function (e) { return isElementHasChild(_this.appTokenInput.trigger, e.target, true); }));
            }
            else if (this.ace) {
                var focusObs_1 = new BehaviorSubject(false);
                var lead_1;
                this.origin = this.ace.editor.renderer.$cursorLayer.cursor || this.ace.editor.container;
                this.originOffsetX = 0;
                this.ace.editor.on('focus', function () { return focusObs_1.next(true); });
                this.ace.editor.on('blur', function () { return focusObs_1.next(false); });
                this.ace.editor.on('click', function (e) {
                    if (lead_1 && e.$pos && lead_1.row == e.$pos.row && lead_1.column == e.$pos.column) {
                        focusObs_1.next(!focusObs_1.value);
                    }
                    else {
                        focusObs_1.next(true);
                    }
                });
                this.ace.editor.session.selection.on('changeCursor', function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    var _ = args[0], selection = args[1];
                    setTimeout(function () { return _this.originMove$.next(); }, 0);
                    lead_1 = selection.getSelectionLead();
                    var line = _this.ace.editor.session.getLine(lead_1.row);
                    _this.updateSearch(line, lead_1.column);
                    // if (this.search$.value !== undefined) {
                    //   focusObs.next(true);
                    // } else {
                    //   focusObs.next(false);
                    // }
                    focusObs_1.next(false);
                });
                this.focus$ = focusObs_1.asObservable();
            }
        }
        else if (this.el && this.el.nativeElement instanceof HTMLInputElement) {
            this.input = this.el.nativeElement;
            this.origin = this.input;
            this.originOffsetX = 0;
            this.focus$ = merge(of(false), this.focusMonitor.monitor(this.input).pipe(map(function (origin) { return !!origin; })));
            merge(fromEvent(this.input, 'keyup'), fromEvent(this.input, 'mousedown'), fromEvent(this.input, 'focus'))
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                setTimeout(function () {
                    var value = _this.input.value;
                    var position = _this.input.selectionStart;
                    _this.updateSearch(value, position);
                }, 0);
            });
        }
    };
    TokenInputDirective.prototype.updateSearch = function (value, position) {
        var quote = this.appTokenInput.quote || this.defaultQuote;
        var leftMatch = value.substring(0, position).match(new RegExp(quote.open + "([\\w.]+)?(" + quote.close + ")?$"));
        var rightMatch = value.substring(position).match(new RegExp("^([\\w.]+)(?:" + quote.close + ")?"));
        if (leftMatch) {
            var left = leftMatch[1] || '';
            var right = rightMatch ? rightMatch[1] : '';
            var search = leftMatch[2] ? left : left + right;
            this.search$.next({
                query: search,
                exact: !quote.open.length && !quote.close.length
            });
            this.searchRange = [
                position - leftMatch[0].length,
                position + (!leftMatch[2] && rightMatch ? rightMatch[0].length : 0)
            ];
        }
        else {
            this.search$.next(undefined);
            this.searchRange = undefined;
        }
    };
    TokenInputDirective.prototype.initOverlay = function () {
        var _this = this;
        var positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.origin)
            .withPositions([
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                offsetX: this.originOffsetX + -8,
                weight: 4
            },
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                offsetX: this.originOffsetX + 8,
                weight: 3
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom',
                offsetX: this.originOffsetX + -8,
                weight: 2
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'end',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'bottom',
                offsetX: this.originOffsetX + 8,
                weight: 1
            }
        ])
            .withPush(true)
            .withGrowAfterOpen(true);
        this.overlayRef = this.overlay.create({
            panelClass: ['overlay'],
            positionStrategy: positionStrategy
        });
        this.originMove$.pipe(untilDestroyed(this)).subscribe(function () { return _this.overlayRef.updatePosition(); });
    };
    TokenInputDirective.prototype.initPopover = function () {
        var _this = this;
        combineLatest(this.focus$, merge(of(false), fromEvent(window, 'mousedown').pipe(map(function (e) {
            if (_this.popoverRef &&
                isElementHasChild(_this.popoverRef.location.nativeElement, e.target, true)) {
                return true;
            }
            var overlayContainer = document.querySelector('.cdk-overlay-container');
            if (overlayContainer && isElementHasChild(overlayContainer, e.target)) {
                return;
            }
            return false;
        }), filter(function (e) { return e !== undefined; }))))
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var inputFocus = _a[0], clickedPopover = _a[1];
            var open = inputFocus || clickedPopover;
            if (open && !_this.overlayRef.hasAttached()) {
                _this.openPopover();
            }
            else if (!open && _this.overlayRef.hasAttached()) {
                _this.closePopover();
            }
        });
    };
    TokenInputDirective.prototype.openPopover = function () {
        var _this = this;
        var injector = Injector.create({
            providers: [
                { provide: CONTEXT_TOKEN, useValue: this.appTokenInput.context },
                { provide: SEARCH_TOKEN, useValue: this.search$ }
            ],
            parent: this.injector
        });
        var portal = new ComponentPortal(InputTokensComponent, null, injector);
        this.popoverRef = this.overlayRef.attach(portal);
        this.popoverRef.instance.inserted.pipe(untilDestroyed(this)).subscribe(function (item) { return _this.insertToken(item); });
        this.popoverRef.instance.event.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (e.type == InputTokensEventType.Submit) {
                _this.closePopover();
            }
            _this.appTokenEvent.emit(e);
        });
        this.contentObserverSubscription = this.contentObserver
            .observe(this.popoverRef.location)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.overlayRef.updatePosition(); });
    };
    TokenInputDirective.prototype.closePopover = function () {
        this.overlayRef.detach();
        if (this.contentObserverSubscription) {
            this.contentObserverSubscription.unsubscribe();
        }
    };
    TokenInputDirective.prototype.getReplaceRange = function () {
        if (this.ace) {
            if (this.searchRange) {
                var lead = this.ace.editor.session.selection.getSelectionLead();
                return new Range(lead.row, this.searchRange[0], lead.row, this.searchRange[1]);
            }
            else {
                return this.ace.editor.selection.getRange();
            }
        }
        else if (this.input) {
            return [this.input.selectionStart, this.input.selectionEnd];
        }
    };
    TokenInputDirective.prototype.insertEditorText = function (text, range) {
        if (this.ace) {
            this.ace.editor.session.replace(range, text);
            this.appTokenInput.control.patchValue(this.ace.editor.getValue());
        }
        else if (this.input) {
            var selectionStart = range[0], selectionEnd = range[1];
            var selectionRange = selectionStart != selectionEnd;
            var selectionMin = Math.min(selectionStart, selectionEnd);
            var selectionMax = Math.max(selectionStart, selectionEnd);
            var currentValue = String(this.input.value);
            var value = void 0;
            var position = void 0;
            if (this.searchRange && !selectionRange) {
                value = [
                    currentValue.substring(0, this.searchRange[0]),
                    text,
                    currentValue.substring(this.searchRange[1] + 1)
                ].join('');
                position = this.searchRange[0] + text.length;
            }
            else {
                value = [currentValue.substring(0, selectionMin), text, currentValue.substring(selectionMax)].join('');
                position = selectionEnd >= selectionStart ? selectionStart + text.length : selectionEnd;
            }
            this.appTokenInput.control.patchValue(value);
            this.input.focus();
            this.input.setSelectionRange(position, position);
        }
    };
    TokenInputDirective.prototype.quote = function (token) {
        var quote = this.appTokenInput.quote || this.defaultQuote;
        return [quote.open, token, quote.close].join('');
    };
    TokenInputDirective.prototype.secretQuote = function (token) {
        return "{-" + token + "-}";
    };
    TokenInputDirective.prototype.insertToken = function (token) {
        var insert = token.data && token.data['secret'] ? this.secretQuote(token.name) : this.quote(token.name);
        var selectionRange = this.getReplaceRange();
        this.insertEditorText(insert, selectionRange);
    };
    TokenInputDirective.prototype.insertText = function (text) {
        var selectionRange = this.getReplaceRange();
        this.insertEditorText(text, selectionRange);
    };
    return TokenInputDirective;
}());
export { TokenInputDirective };

<app-bind-component
  *ngIf="control.controls.type.value == dataSourceTypes.Query"
  [inputs]="control.controls.query_inputs | appControlValue | async"
  [selfModelDescription]="modelDescription$ | async"
  [selfColumns]="control.controls.columns | appControlValue | async"
  [context]="context"
  [element]="element"
  [targetBindPrimaryKey]="targetBindPrimaryKey"
  [selfBindPrimaryKey]="selfBindPrimaryKey"
  [inline]="inline"
  [analyticsSource]="analyticsSource"
  (updateInputs)="control.controls.query_inputs.patchValue($event)"
></app-bind-component>

<div *ngIf="(group && isVisible) || (customizeService.enabled$ | async) || !group" [class.list-group]="group">
  <div
    *ngIf="group"
    class="list-group__header"
    [class.list-group__header_interactive]="settings.groupCollapse"
    (mouseenter)="hoverSeparator$.next(true)"
    (mouseleave)="hoverSeparator$.next(false)"
    (click)="toggleCollapsed()"
  >
    <div
      *ngIf="settings.groupCollapse"
      class="list-group__header-arrow"
      [class.list-group__header-arrow_hover]="hoverSeparator$ | async"
      [class.list-group__header-arrow_active]="collapsed$ | async"
      [class.icon-arrow_forward_2]="collapsed$ | async"
      [class.icon-arrow_down_2]="!(collapsed$ | async)"
    ></div>

    <div class="list-group__header-title">
      <span
        class="status"
        [ngClass]="
          !(group.color | appIsColorHex)
            ? ['color_' + group.color + '_1', 'background-color_' + group.color + '_2']
            : ['']
        "
        [style.color]="(group.color | appIsColorHex) ? group.color : null"
        [style.background-color]="(group.color | appIsColorHex) ? backgroundCustomColor(group.color) : null"
      >
        <ng-container *ngIf="group.name | appIsSet">{{ group.name }}</ng-container>
        <ng-container *ngIf="!(group.name | appIsSet) && (group.value | appIsSet)">{{ group.value }}</ng-container>
        <ng-container *ngIf="!(group.name | appIsSet) && !(group.value | appIsSet)">---</ng-container>
      </span>
    </div>

    <span
      *ngIf="!isVisible && (customizeService.enabled$ | async)"
      class="list-group__header-indicator icon-eye"
      [appTip]="'Component is hidden'"
    ></span>

    <span *ngIf="settings.groupCounter && listStore.items !== undefined" class="list-group__header-counter">
      {{ listStore.count$ | async }}
    </span>
  </div>

  <div
    *ngIf="(group && !settings.groupCollapse) || (group && !(collapsed$ | async)) || !group"
    [class.list-group__content]="group"
    [class.list-group__content_last]="groupLast"
  >
    <app-grid-stub
      *ngIf="loading"
      [animating]="true"
      [columns]="visibleColumns.length ? visibleColumns.length : scrollable ? 8 : 5"
      [rows]="scrollable ? 8 : loadingItems"
      [rowCards]="rowCards"
      [cardHeight]="cardDefaultHeight"
      [gapHorizontal]="settings.gapHorizontal"
      [gapVertical]="settings.gapVertical"
      [scrollable]="scrollable"
      [height]="heightBeforeLoading"
    >
    </app-grid-stub>

    <div *ngIf="!loading && error" class="grid-message">
      <app-error [title]="'Loading failed' | localize">
        {{ error }}
      </app-error>
    </div>

    <ng-container *ngIf="!loading && !error && items != undefined">
      <div
        class="grid"
        [style.margin-left.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
        [style.margin-right.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
        [style.margin-top.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 : null"
        [style.margin-bottom.px]="(settings.gapVertical | appIsSet) ? -settings.gapVertical * 0.5 : null"
      >
        <app-grid-item
          *ngFor="let item of items; let i = index; trackBy: trackByFn"
          [item]="item"
          [modelDescription]="listState.modelDescription"
          [settings]="settings"
          [visibleColumns]="visibleColumns"
          [rowCards]="rowCards"
          [view]="view"
          [context]="context"
          [contextElement]="contextElement"
          [selected]="isItemSelected(item, i)"
          [containerWidth]="gridWidth"
          [accentColor]="accentColor"
          (select)="selectToggle.emit({ item: item, element: $event.element, index: i })"
          (modelUpdated)="modelUpdated.emit($event)"
        >
        </app-grid-item>
      </div>

      <div *ngIf="items.length == 0" class="grid-message">
        <app-error [title]="'Nothing found' | localize" [fill]="scrollable">
          {{
            'Unfortunately, no {0} matching your query found'
              | localize: [title ? (title | appCapitalize) : ('records' | localize)]
          }}
        </app-error>
      </div>
    </ng-container>

    <ng-container *ngIf="settings.displayFooter">
      <app-list-stub-footer *ngIf="loading" [count]="true" [animating]="loading" class="list__footer">
      </app-list-stub-footer>

      <app-list-store-footer
        *ngIf="!loading && !error"
        [title]="title"
        [listStore]="listStore"
        [viewId]="viewId"
        (pageSelected)="page$.next($event)"
        (reload)="reloadData()"
        class="list__footer"
      >
      </app-list-store-footer>
    </ng-container>
  </div>
</div>

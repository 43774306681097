var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { HttpQuery, RateLimit } from '@modules/queries';
var RestAPIResourceParams = /** @class */ (function () {
    function RestAPIResourceParams() {
        this.params = {};
    }
    RestAPIResourceParams.prototype.deserialize = function (data) {
        this.params = data;
        if (data['base_http_query']) {
            this.baseHttpQuery = new HttpQuery().deserialize(data['base_http_query']);
        }
        else if (data['base_query']) {
            this.baseHttpQuery = new HttpQuery().deserialize(data['base_query']);
        }
        else {
            this.baseHttpQuery = new HttpQuery();
        }
        if (data['rate_limit']) {
            this.rateLimit = new RateLimit().deserialize(data['rate_limit']);
        }
        this.customProxy = data['custom_proxy'];
        return this;
    };
    RestAPIResourceParams.prototype.serialize = function (fields) {
        var data = __assign({}, this.params, { base_http_query: this.baseHttpQuery ? this.baseHttpQuery.serialize() : undefined, rate_limit: this.rateLimit ? this.rateLimit.serialize() : undefined, custom_proxy: this.customProxy });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return RestAPIResourceParams;
}());
export { RestAPIResourceParams };

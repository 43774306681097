/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup2-close.component";
var styles_Popup2CloseComponent = [];
var RenderType_Popup2CloseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2CloseComponent, data: {} });
export { RenderType_Popup2CloseComponent as RenderType_Popup2CloseComponent };
export function View_Popup2CloseComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "popup2__close icon-close"]], [[2, "popup2__close_outside", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.outside; _ck(_v, 0, 0, currVal_0); }); }
export function View_Popup2CloseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-close", [], null, null, null, View_Popup2CloseComponent_0, RenderType_Popup2CloseComponent)), i0.ɵdid(1, 114688, null, 0, i1.Popup2CloseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2CloseComponentNgFactory = i0.ɵccf("app-popup2-close", i1.Popup2CloseComponent, View_Popup2CloseComponent_Host_0, { outside: "outside" }, {}, []);
export { Popup2CloseComponentNgFactory as Popup2CloseComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, NgZone, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import uniqWith from 'lodash/uniqWith';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { datasetGroupDateLookups, groupEquals } from '@modules/charts';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ChartType, ChartWidget, singleColorDatasetChartTypes } from '@modules/dashboard';
import { Input } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { MarginControl } from '../../margin-control/margin-control.component';
import { ChartWidgetDataSourceControl } from '../../model-description-data-source-edit/chart-widget-data-source';
import { CustomizeBarBaseWidgetEditForm } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.form';
import { ChartWidgetEditFormDatasetArray } from './chart-widget-edit-dataset.array';
import { ChartWidgetEditFormDatasetControl } from './chart-widget-edit-dataset.control';
import { ChartWidgetGroupArray } from './chart-widget-group.array';
import { ChartWidgetGroupControl } from './chart-widget-group.control';
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.value).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
var CustomizeBarChartWidgetEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarChartWidgetEditForm, _super);
    function CustomizeBarChartWidgetEditForm(injector, elementConfigurationService) {
        var _this = _super.call(this, {
            title: new FieldInputControl({ name: 'value' }),
            dataset_column: new FormControl(''),
            datasets: new ChartWidgetEditFormDatasetArray([], Validators.required),
            groups: new ChartWidgetGroupArray([]),
            chart_type: new FormControl(''),
            y_format: new FormControl(''),
            min: new FormControl(''),
            max: new FormControl(''),
            custom_query_x_column: new FormControl(true),
            custom_date_range: new FormControl(true),
            show_reload: new FormControl(true),
            legend: new FormControl(true),
            percentage: new FormControl(false),
            smooth: new FormControl(false),
            name: new FormControl(''),
            click_action: new FormControl(undefined, undefined, validateAction),
            visible_input: new FieldInputControl({ name: 'value' }),
            tooltip: new FormControl(''),
            margin: new MarginControl()
        }) || this;
        _this.injector = injector;
        _this.elementConfigurationService = elementConfigurationService;
        _this.chartTypeOptions = [
            { value: ChartType.Line, name: 'Line', image: 'chart' },
            { value: ChartType.Bar, name: 'Bar', image: 'ranks' },
            { value: ChartType.StackedBar, name: 'Stacked Bar', image: 'ranks' },
            { value: ChartType.Pie, name: 'Pie', image: 'diagram' },
            { value: ChartType.Doughnut, name: 'Doughnut', image: 'diagram_2' },
            { value: ChartType.Radar, name: 'Radar', image: 'diagram' },
            { value: ChartType.PolarArea, name: 'Polar Area', image: 'diagram_2' },
            { value: ChartType.Scatter, name: 'Scatter', image: 'chart' },
            { value: ChartType.Bubble, name: 'Bubble', image: 'chart' }
        ];
        _this.yFormatChartTypes = [
            ChartType.Bar,
            ChartType.StackedBar,
            ChartType.Line,
            ChartType.Radar,
            ChartType.PolarArea,
            ChartType.Scatter
        ];
        _this.minMaxChartTypes = [ChartType.Bar, ChartType.StackedBar, ChartType.Line, ChartType.Scatter];
        _this.percentageChartTypes = [ChartType.Bar, ChartType.StackedBar];
        _this.smoothChartTypes = [ChartType.Line];
        return _this;
    }
    CustomizeBarChartWidgetEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarChartWidgetEditForm.prototype.createChartWidgetDataSourceControl = function () {
        return Injector.create({
            providers: [
                {
                    provide: ChartWidgetDataSourceControl,
                    deps: [
                        ModelDescriptionStore,
                        CurrentEnvironmentStore,
                        ProjectSettingsService,
                        ResourceControllerService,
                        ResourceGeneratorResolver,
                        ModelService,
                        ActionService,
                        QueryService,
                        NotificationService,
                        NgZone
                    ]
                }
            ],
            parent: this.injector
        }).get(ChartWidgetDataSourceControl);
    };
    CustomizeBarChartWidgetEditForm.prototype.createChartWidgetDatasetControl = function () {
        var dataSourceControl = this.createChartWidgetDataSourceControl();
        return Injector.create({
            providers: [
                {
                    provide: ChartWidgetEditFormDatasetControl,
                    useFactory: function () {
                        return new ChartWidgetEditFormDatasetControl(dataSourceControl);
                    },
                    deps: []
                }
            ],
            parent: this.injector
        }).get(ChartWidgetEditFormDatasetControl);
    };
    CustomizeBarChartWidgetEditForm.prototype.init = function (widget, name, visibleInput, margin, firstInit, component) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.widget = widget;
        this.component = component;
        if (widget) {
            this.controls.title.patchValue(widget.nameInput ? widget.nameInput.serialize() : {});
            this.controls.chart_type.patchValue(widget.chartType);
            this.controls.dataset_column.patchValue(widget.datasetColumnEnabled ? widget.datasetColumn : undefined);
            this.controls.y_format.patchValue(widget.yFormat);
            this.controls.min.patchValue(widget.min);
            this.controls.max.patchValue(widget.max);
            this.controls.custom_query_x_column.patchValue(widget.customQueryXColumn);
            this.controls.custom_date_range.patchValue(widget.customDateRange);
            this.controls.show_reload.patchValue(widget.showReload);
            this.controls.legend.patchValue(widget.legend);
            this.controls.percentage.patchValue(widget.percentage);
            this.controls.smooth.patchValue(widget.smooth);
            this.controls.click_action.patchValue(widget.clickAction);
            this.controls.visible_input.patchValue(visibleInput ? visibleInput.serialize() : {});
            this.controls.tooltip.patchValue(widget.tooltip);
            this.controls.margin.patchValue(margin);
            if (widget.datasets) {
                var controls = widget.datasets.map(function (item) {
                    return _this.createDataset(item);
                });
                this.controls.datasets.set(controls);
            }
            if (widget.groups) {
                var controls = widget.groups.map(function (item) {
                    return _this.createGroup(item);
                });
                this.controls.groups.set(controls);
            }
        }
        if (name) {
            this.controls.name.patchValue(name);
        }
        this.getComponentGroups$()
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            groups = groups || [];
            var addGroups = groups
                .filter(function (group) {
                return !_this.controls.groups.controls.find(function (control) { return control.controls.value.value === group; });
            })
                .map(function (group) {
                var item = _this.createGroup();
                item.controls.value.patchValue(group);
                return item;
            });
            var checkGroups = groups.slice();
            var removableGroups = _this.controls.groups.controls.filter(function (item) {
                var checkGroupIndex = checkGroups.indexOf(item.controls.value.value);
                if (checkGroupIndex !== -1) {
                    checkGroups.splice(checkGroupIndex, 1);
                    return false;
                }
                else {
                    return true;
                }
            });
            addGroups.forEach(function (group) { return _this.controls.groups.append(group); });
            _this.controls.groups.controls.forEach(function (group) {
                var removable = removableGroups.includes(group);
                if (group.isSet()) {
                    group.controls.removable.patchValue(removable);
                }
                else if (!group.isSet() && removable) {
                    _this.controls.groups.remove(group);
                }
            });
        });
        if (!firstInit) {
            this.markAsDirty();
        }
        else {
            this.markAsPristine();
        }
    };
    CustomizeBarChartWidgetEditForm.prototype.firstResource$ = function () {
        var _this = this;
        return controlValue(this.controls.datasets).pipe(delay(0), switchMap(function () {
            if (!_this.controls.datasets.length) {
                return of(undefined);
            }
            return _this.controls.datasets.controls[0].controls.data_source.getResource$();
        }));
    };
    CustomizeBarChartWidgetEditForm.prototype.firstModelDescription$ = function () {
        var _this = this;
        return controlValue(this.controls.datasets).pipe(delay(0), switchMap(function () {
            if (!_this.controls.datasets.length) {
                return of(undefined);
            }
            return _this.controls.datasets.controls[0].controls.data_source.getModelDescription$();
        }));
    };
    CustomizeBarChartWidgetEditForm.prototype.xDateLookup$ = function () {
        var _this = this;
        return controlValue(this.controls.datasets).pipe(delay(0), map(function () {
            if (!_this.controls.datasets.length) {
                return true;
            }
            return _this.controls.datasets.controls.every(function (item) {
                return datasetGroupDateLookups.includes(item.controls.data_source.controls.x_column_lookup.value);
            });
        }));
    };
    CustomizeBarChartWidgetEditForm.prototype.isSingleColorDataset = function () {
        return singleColorDatasetChartTypes.includes(this.controls.chart_type.value);
    };
    CustomizeBarChartWidgetEditForm.prototype.isSingleColorDataset$ = function () {
        return controlValue(this.controls.chart_type).pipe(map(function (value) { return singleColorDatasetChartTypes.includes(value); }));
    };
    CustomizeBarChartWidgetEditForm.prototype.createDataset = function (instance) {
        var form = this.createChartWidgetDatasetControl();
        form.deserialize(instance);
        form.markAsDirty();
        return form;
    };
    CustomizeBarChartWidgetEditForm.prototype.createGroup = function (instance) {
        var form = new ChartWidgetGroupControl();
        form.deserialize(instance);
        form.markAsDirty();
        return form;
    };
    CustomizeBarChartWidgetEditForm.prototype.getComponentGroups$ = function () {
        if (!this.component) {
            return of(undefined);
        }
        return combineLatest(this.component.getData$(), controlValue(this.controls.dataset_column), this.isSingleColorDataset$()).pipe(map(function (_a) {
            var datasets = _a[0], datasetColumn = _a[1], isSingleColorDataset = _a[2];
            if (!datasets) {
                return;
            }
            var groups = [];
            if (isSet(datasetColumn) && isSingleColorDataset) {
                groups.push.apply(groups, datasets.map(function (dataset) { return dataset.name; }));
            }
            else {
                var allGroups = datasets.reduce(function (acc, dataset) {
                    var isDateLookup = datasetGroupDateLookups.includes(dataset.groupLookup);
                    if (!isDateLookup) {
                        acc.push.apply(acc, dataset.dataset.map(function (item) { return item.group; }));
                    }
                    return acc;
                }, []);
                groups.push.apply(groups, uniqWith(allGroups, function (lhs, rhs) { return groupEquals(lhs, rhs); }));
            }
            return groups;
        }));
    };
    CustomizeBarChartWidgetEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isWidgetChartConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarChartWidgetEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.click_action]);
    };
    CustomizeBarChartWidgetEditForm.prototype.submit = function () {
        var instance = this.widget ? cloneDeep(this.widget) : new ChartWidget();
        instance.nameInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.datasets = this.controls.datasets.controls.map(function (item) { return item.serialize(); });
        instance.groups = this.controls.groups.controls.map(function (item) { return item.serialize(); });
        instance.chartType = this.controls.chart_type.value;
        instance.datasetColumnEnabled = isSet(this.controls.dataset_column.value);
        instance.datasetColumn = this.controls.dataset_column.value;
        instance.yFormat = this.controls.y_format.value;
        instance.min = this.controls.min.value;
        instance.max = this.controls.max.value;
        instance.customQueryXColumn = this.controls.custom_query_x_column.value;
        instance.customDateRange = this.controls.custom_date_range.value;
        instance.showReload = this.controls.show_reload.value;
        instance.legend = this.controls.legend.value;
        instance.percentage = this.controls.percentage.value;
        instance.smooth = this.controls.smooth.value;
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        if (this.controls.click_action.value) {
            instance.clickAction = this.controls.click_action.value;
        }
        else {
            instance.clickAction = undefined;
        }
        return {
            widget: instance,
            name: this.controls.name.value,
            visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined,
            margin: this.controls.margin.value
        };
    };
    return CustomizeBarChartWidgetEditForm;
}(CustomizeBarBaseWidgetEditForm));
export { CustomizeBarChartWidgetEditForm };

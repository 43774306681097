<tr class="table__heading-row">
  <th
    *ngFor="let i of scrollable ? [1, 2, 3, 4, 5, 6, 7, 8] : columnsRange; let first = first"
    class="table__heading-column table__stub-column"
    [attr.colspan]="first ? 2 : null"
    [class.table__heading-column_left-padding]="first"
  >
    <span [class.loading-animation]="animating"><span class="stub-text">Column</span></span>
  </th>
</tr>

import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { TimelinePopupFooterComponent } from './components/timeline-popup-footer/timeline-popup-footer.component';
import { TimelinePopupComponent } from './components/timeline-popup/timeline-popup.component';
import { UserActivitiesDescriptionComponent } from './components/user-activities-description/user-activities-description.component';
import { UserActivitiesPopupItemComponent } from './components/user-activities-popup-item/user-activities-popup-item.component';
import { UserActivitiesPopupComponent } from './components/user-activities-popup/user-activities-popup.component';
import { ActionUserActivityComponent } from './components/user-activities/action-user-activity/action-user-activity.component';
import { MessageUserActivityComponent } from './components/user-activities/message-user-activity/message-user-activity.component';
@NgModule({
  imports: [
    CommonModule,
    NgGxSelectModule,
    LocalizeModule,
    ReactiveFormsModule,
    SharedModule,
    RoutingModule,
    ModelQueriesModule,
    TipsModule,
    NgGxScrollableModule,
    TextFieldModule,
    FieldComponentsModule,
    ThemeComponentsModule,
    UiModule,
    InfiniteScrollModule,
    ColorsSharedModule
  ],
  declarations: [
    UserActivitiesPopupComponent,
    UserActivitiesPopupItemComponent,
    UserActivitiesDescriptionComponent,
    TimelinePopupComponent,
    MessageUserActivityComponent,
    ActionUserActivityComponent,
    TimelinePopupFooterComponent
  ],
  exports: [
    UserActivitiesPopupComponent,
    UserActivitiesPopupItemComponent,
    UserActivitiesDescriptionComponent,
    TimelinePopupComponent,
    MessageUserActivityComponent,
    TimelinePopupFooterComponent
  ],
  entryComponents: [UserActivitiesPopupComponent, TimelinePopupComponent]
})
export class ActivitiesComponentsModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Provider
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { UniqueIdToken } from '@common/unique-id';
import { ACTION_SERVICE_IMPORT_COMPONENT } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ExportService } from '@modules/export';
import { CustomSelectItem } from '@modules/field-components';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { ColumnsModelListStore } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { controlValue, getExtension, getFilenameWithExtension, isSet } from '@shared';

import {
  ModelImportCancelledEvent,
  ModelImportEventType,
  ModelImportFinishedEvent,
  ModelImportProgressEvent
} from '../../data/model-import.event';
import { IMPORT_OBJECT_ERROR_KEY, ImportService } from '../../services/import/import.service';
import { ImportModelsFieldArray } from './import-models-field.array';
import { ImportFileContentResult, ImportModelsForm } from './import-models.form';

export const ACTION_SERVICE_IMPORT_COMPONENT_PROVIDER: Provider = {
  provide: ACTION_SERVICE_IMPORT_COMPONENT,
  useFactory: actionServiceImportComponentFactory
};

export function actionServiceImportComponentFactory(): any {
  return ImportModelsComponent;
}

@Component({
  selector: 'app-import-models',
  templateUrl: './import-models.component.html',
  providers: [ImportModelsFieldArray, ImportModelsForm, ColumnsModelListStore, ExportService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportModelsComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() analyticsSource: string;
  @Output() cancelled = new EventEmitter<ModelImportCancelledEvent>();
  @Output() imported = new EventEmitter<ModelImportFinishedEvent>();

  createField = createFormFieldFactory();
  idToken = new UniqueIdToken();
  submitLoading = false;
  draggingOver = false;

  fileContent: ImportFileContentResult;
  fileContentInfo: string;
  sourceOptions: CustomSelectItem<string>[] = [];
  previewFilename: string;
  previewExtension: string;
  previewSize: number;

  importModelDescription: ModelDescription;
  successCount: number;
  failedCount: number;
  totalCount: number;
  progress: number;
  result: ModelImportFinishedEvent;

  constructor(
    public form: ImportModelsForm,
    @Optional() protected popupComponent: BasePopupComponent,
    private importService: ImportService,
    private exportService: ExportService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.resource, this.modelDescription);

    controlValue<File>(this.form.controls.file)
      .pipe(untilDestroyed(this))
      .subscribe(file => {
        if (file) {
          this.previewFilename = getFilenameWithExtension(file.name);
          this.previewExtension = getExtension(file.name);
          this.previewSize = file.size;
        } else {
          this.previewFilename = undefined;
          this.previewExtension = undefined;
          this.previewSize = undefined;
        }

        this.cd.markForCheck();
      });

    this.form
      .getFileContent$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const fields = result && result.content ? result.content.fields : [];

        this.fileContent = result;
        this.fileContentInfo =
          result && result.content
            ? `${result.content.data.length} records, ${result.content.fields.length} fields`
            : undefined;
        this.sourceOptions = fields.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          return {
            option: {
              value: item.name,
              name: item.verboseName || item.name,
              icon: fieldDescription.icon
            }
          };
        });
        this.cd.markForCheck();
      });

    controlValue(this.form.controls.fields)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.markForCheck());

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportOpened, {
      ResourceID: this.resource.typeItem.name,
      ResourceType: this.resource.typeItem.resourceType,
      NewCollection: !this.modelDescription,
      CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
      Source: this.analyticsSource
    });
  }

  ngOnDestroy(): void {}

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    el.value = null;
    this.form.controls.file.setValue(file);
    this.form.markAsPristine();
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const file = e.dataTransfer.files[0];

    this.draggingOver = false;
    this.cd.markForCheck();

    if (file) {
      this.form.controls.file.setValue(file);
      this.form.markAsPristine();
    }
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  backToUpload() {
    if (this.progress === undefined) {
      this.form.controls.file.patchValue(undefined);
    }
  }

  submit() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportStarted, {
      ResourceID: this.resource.typeItem.name,
      ResourceType: this.resource.typeItem.resourceType,
      NewCollection: !this.modelDescription,
      CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
      FileName: this.previewFilename,
      FileExtension: this.previewExtension,
      FileSize: this.previewSize,
      Source: this.analyticsSource
    });

    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        event => {
          if (event.type == ModelImportEventType.Started) {
            this.importModelDescription = event.modelDescription;
            this.successCount = 0;
            this.failedCount = 0;
            this.totalCount = event.totalCount;
            this.progress = 0;
          } else if (event.type == ModelImportEventType.Progress) {
            const progressEvent = event as ModelImportProgressEvent;

            this.importModelDescription = event.modelDescription;
            this.successCount = progressEvent.successCount;
            this.failedCount = progressEvent.failedCount;
            this.progress = progressEvent.totalCount ? progressEvent.processedCount / progressEvent.totalCount : 1;
          } else if (event.type == ModelImportEventType.Finished) {
            const finishedEvent = event as ModelImportFinishedEvent;

            this.importModelDescription = event.modelDescription;
            this.successCount = finishedEvent.successCount;
            this.failedCount = finishedEvent.failedCount;
            this.progress = 1;
            this.result = finishedEvent;

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportFinished, {
              ResourceID: this.resource.typeItem.name,
              ResourceType: this.resource.typeItem.resourceType,
              NewCollection: !this.modelDescription,
              CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
              FileName: this.previewFilename,
              FileExtension: this.previewExtension,
              FileSize: this.previewSize,
              SuccessCount: finishedEvent.successCount,
              FailedCount: finishedEvent.failedCount,
              TotalCount: finishedEvent.totalCount,
              Source: this.analyticsSource
            });
          }
          this.cd.markForCheck();
        },
        error => {
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          console.error(error);
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportFailed, {
            ResourceID: this.resource.typeItem.name,
            ResourceType: this.resource.typeItem.resourceType,
            NewCollection: !this.modelDescription,
            CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
            FileName: this.previewFilename,
            FileExtension: this.previewExtension,
            FileSize: this.previewSize,
            Error: errorMessage,
            Source: this.analyticsSource
          });
        }
      );
  }

  downloadReport() {
    const title = ['import_errors', ...(this.modelDescription ? [this.modelDescription.verboseNamePlural] : [])].join(
      '_'
    );
    const columns = [
      { name: IMPORT_OBJECT_ERROR_KEY, verboseName: 'import error' },
      ...this.fileContent.content.fields
    ];
    const data = this.result.objectResults.map(item => {
      return columns.map(column => item[column.name]);
    });
    const fileFormatType = this.importService.getFileFormatType(this.form.controls.file.value);

    this.exportService.downloadData(title, columns, data, fileFormatType).pipe(untilDestroyed(this)).subscribe();
  }

  finish() {
    this.close();
    this.imported.emit(this.result);
  }

  cancel() {
    const processedCount =
      isSet(this.successCount) && isSet(this.successCount) ? this.successCount + this.failedCount : undefined;

    this.cancelled.emit({
      type: ModelImportEventType.Cancelled,
      modelDescription: this.importModelDescription,
      processedCount: processedCount,
      successCount: this.successCount,
      failedCount: this.failedCount,
      totalCount: this.totalCount
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportCancelled, {
      ResourceID: this.resource.typeItem.name,
      ResourceType: this.resource.typeItem.resourceType,
      NewCollection: !this.modelDescription,
      CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
      FileName: this.previewFilename,
      FileExtension: this.previewExtension,
      FileSize: this.previewSize,
      SuccessCount: this.successCount,
      FailedCount: this.failedCount,
      TotalCount: this.totalCount,
      Source: this.analyticsSource
    });

    this.close();
  }
}

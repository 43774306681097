import { Directive, Input } from '@angular/core';

import {
  PageBlockMarginSize,
  PageBlockPaddingSize,
  PageBlockSize
} from '../../components/page-block/page-block.component';

export interface PageBlockDefaults {
  size?: PageBlockSize;
  marginSize?: PageBlockMarginSize;
  paddingSize?: PageBlockPaddingSize;
}

@Directive({
  selector: '[appPageBlockDefaults]'
})
export class PageBlockDefaultsDirective {
  @Input() appPageBlockDefaults: PageBlockDefaults = {};

  constructor() {}
}

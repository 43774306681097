import { EmbeddedViewRef } from '@angular/core';

import { addClass } from '@shared';

import { Point } from '../data/point';

export function isPointInside(point: Point, bounds: ClientRect, margin = [0, 0, 0, 0]) {
  return (
    point.y >= bounds.top + margin[0] &&
    point.x <= bounds.right - margin[1] &&
    point.y <= bounds.bottom - margin[2] &&
    point.x >= bounds.left + margin[3]
  );
}

export function reorderElements(elements: { element: HTMLElement; fromIndex: number }[]) {
  elements.forEach((item, i) => {
    if (i === item.fromIndex) {
      return;
    }

    const element = item.element;
    const nextElement = elements[i + 1] ? elements[i + 1].element : null;
    element.parentNode.insertBefore(element, nextElement);
  });
}

export function moveElement(element: HTMLElement, index: number) {
  if (!element.parentNode) {
    return;
  }

  const prevIndex = Array.prototype.indexOf.call(element.parentNode.children, element);
  const insertBefore =
    index > prevIndex ? element.parentElement.children[index + 1] : element.parentElement.children[index];
  element.parentNode.insertBefore(element, insertBefore || null);
}

export function removeElement(element: HTMLElement) {
  if (element.parentElement) {
    element.parentElement.removeChild(element);
  }
}

export function replaceElement(element: HTMLElement, newElement: HTMLElement) {
  if (element.parentNode) {
    element.parentNode.replaceChild(newElement, element);
  }
}

export function matchElementSize(element: HTMLElement, fromElement: HTMLElement, sizeMatchSelector: string) {
  const elementMatch = sizeMatchSelector ? element.querySelector<HTMLElement>(sizeMatchSelector) : element;
  const fromElementMatch = sizeMatchSelector ? fromElement.querySelector<HTMLElement>(sizeMatchSelector) : fromElement;

  elementMatch.style.width = `${fromElementMatch.offsetWidth}px`;
  elementMatch.style.height = `${fromElementMatch.offsetHeight}px`;
  elementMatch.style.margin = '0';
  elementMatch.style.boxSizing = 'border-box';
}

export function applyElementPreviewStyles(preview: HTMLElement): HTMLElement {
  preview.removeAttribute('id');
  preview.style.position = 'fixed';
  preview.style.top = '0';
  preview.style.left = '0';
  preview.style.pointerEvents = 'none';
  preview.style.zIndex = '1000';

  return preview;
}

export function createElementPreview(element: HTMLElement, sizeMatchSelector: string) {
  const preview = element.cloneNode(true) as HTMLElement;

  applyElementPreviewStyles(preview);
  matchElementSize(preview, element, sizeMatchSelector);

  return preview;
}

export const DRAG_OUTSIDE_INDICATOR_CLASS = 'app-drag-outside-indicator';
export const DRAG_OUTSIDE_INDICATOR_TOP_CLASS = 'app-drag-outside-indicator_top';
export const DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS = 'app-drag-outside-indicator_bottom';

export function createOutsideIndicator() {
  const element = document.createElement('div');

  addClass(element, DRAG_OUTSIDE_INDICATOR_CLASS);

  return element;
}

export function getElementDepth(element: HTMLElement) {
  let el = element;
  let i = 0;
  while ((el = el && el.parentElement)) {
    ++i;
  }
  return i;
}

export function getRootNode(viewRef: EmbeddedViewRef<any>): HTMLElement {
  const rootNodes: Node[] = viewRef.rootNodes;

  if (rootNodes.length === 1 && rootNodes[0].nodeType === document.ELEMENT_NODE) {
    return rootNodes[0] as HTMLElement;
  }

  const wrapper = document.createElement('div');
  rootNodes.forEach(node => wrapper.appendChild(node));
  return wrapper;
}

export function getElementViewport(element: HTMLElement): HTMLElement {
  const iterate = el => {
    if (!el || !(el instanceof Element)) {
      return;
    }

    if (el.getAttribute('xsScrollable') != null) {
      return el;
    } else if (['scroll', 'auto'].includes(getComputedStyle(el)['overflow'])) {
      return el;
    } else if (el === document.body) {
      return el;
    }

    return iterate(el.parentNode);
  };

  return iterate(element);
}

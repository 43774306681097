import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Notification } from '../../data/notification';
import { NotificationType } from '../../data/notification-type';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notifications = new BehaviorSubject<Notification[]>([]);
  private _topPadding = new BehaviorSubject<boolean>(false);

  get notifications(): Notification[] {
    return this._notifications.value;
  }

  set notifications(value: Notification[]) {
    this._notifications.next(value);
  }

  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  get topPadding(): boolean {
    return this._topPadding.value;
  }

  set topPadding(value: boolean) {
    this._topPadding.next(value);
  }

  get topPadding$(): Observable<boolean> {
    return this._topPadding.asObservable();
  }

  show(notification: Notification) {
    this.notifications = [...this.notifications, notification];
  }

  success(title: string, description?: string) {
    this.show({ type: NotificationType.Success, title: title, description: description });
  }

  error(title: string, description?: string) {
    this.show({ type: NotificationType.Error, title: title, description: description });
  }

  warning(title: string, description?: string) {
    this.show({ type: NotificationType.Warning, title: title, description: description });
  }

  info(title: string, description?: string) {
    this.show({ type: NotificationType.Info, title: title, description: description });
  }

  remove(notification: Notification) {
    this.notifications = this.notifications.filter(item => item !== notification);
  }
}

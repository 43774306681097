import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { CustomViewTemplatesController } from '../../services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';

@Component({
  selector: 'app-view-editor-menu-root',
  templateUrl: './view-editor-menu-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorMenuRootComponent implements OnInit, OnDestroy {
  @Input() trigger: CdkOverlayOrigin;
  @Input() componentLabel = 'component';
  @Input() submitLabel = 'Save';
  @Input() stateSelectedEnabled = false;
  @Input() templatesEnabled = true;
  @Input() analyticsSource: string;
  @Output() saveEditor = new EventEmitter<void>();
  @Output() closeEditor = new EventEmitter<void>();
  @Output() rename = new EventEmitter<void>();
  @Output() importFigmaNode = new EventEmitter<void>();
  @Output() importSketchFile = new EventEmitter<void>();

  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];

  constructor(
    public editorContext: ViewEditorContext,
    private customViewTemplatesController: CustomViewTemplatesController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        viewCustomizeEnabled: false,
        stateSelectedEnabled: this.stateSelectedEnabled,
        componentLabel: this.componentLabel,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(viewResult => {
        this.editorContext.updateView(viewResult.view);
      });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { quillDeltaToHtml$, TextEditorComponent } from '@common/text-editor';
import { ViewContextElement } from '@modules/customize';
import { applyParamInput, FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { ThemeService } from '@modules/theme';
import { AlignHorizontal, AlignVertical, FillType, LayerInteractionType, LayerType, TextTruncate } from '@modules/views';
import { isSet, KeyboardEventKeyCode } from '@shared';
import { registerLayerComponent } from '../../../data/layer-components';
import { CreatedLayerSource, ViewEditorContext, ViewEditorCustomizeSource } from '../../../services/view-editor-context/view-editor.context';
import { markViewLayerClick } from '../../auto-layer/auto-layer.component';
import { LayerComponent } from '../base/layer.component';
var TextLayerComponent = /** @class */ (function (_super) {
    __extends(TextLayerComponent, _super);
    function TextLayerComponent(editorContext, contextElement, themeService, el, sanitizer, cd) {
        var _this = _super.call(this, editorContext) || this;
        _this.contextElement = contextElement;
        _this.themeService = themeService;
        _this.el = el;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.editing = false;
        _this.editingOnCreate = false;
        _this.alignHorizontal = AlignHorizontal;
        _this.alignVertical = AlignVertical;
        _this.textTruncates = TextTruncate;
        return _this;
    }
    TextLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.editorContext) {
            this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
            this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
            this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
            this.updating$ = this.getLayerUpdating$(function () { return !_this.editorContext.isCreateTool(); });
            if (this.createdLayer && this.createdLayer.source == CreatedLayerSource.Tool) {
                setTimeout(function () { return _this.startEditing({ onCreate: true }); }, 0);
            }
            this.editorContext
                .isCustomizingLayer$(this.layer)
                .pipe(untilDestroyed(this))
                .subscribe(function (customizing) {
                if (!customizing) {
                    _this.finishEditing();
                }
            });
            this.editorContext
                .trackKeydown()
                .pipe(filter(function (e) { return _this.editorContext.isCustomizingLayer(_this.layer); }), untilDestroyed(this))
                .subscribe(function (e) {
                if (e.keyCode == KeyboardEventKeyCode.Enter && (e.metaKey || e.ctrlKey) && !_this.editing) {
                    e.stopPropagation();
                    _this.toggleEditing();
                }
                else if (e.keyCode == KeyboardEventKeyCode.Escape && _this.editing) {
                    e.stopPropagation();
                    _this.finishEditing();
                }
            });
            this.trackLayerFluidSize(this.textElement.nativeElement)
                .pipe(untilDestroyed(this))
                .subscribe(function () { return _this.cd.markForCheck(); });
        }
        else {
            this.html$ = this.layer$.pipe(switchMap(function (layer) {
                return quillDeltaToHtml$(layer.quillDelta, {
                    applyContextFormula: function (input) { return applyParamInput(input, { context: _this.viewContext, defaultValue: '' }); },
                    addContextFormulaClass: true,
                    context: _this.viewContext
                });
            }));
        }
        this.getLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.updateColor();
            _this.updateTextStroke();
            _this.updateTextShadow();
            _this.updateFontStyle();
            _this.updateTextTransform();
            _this.updateTextDecoration();
            _this.updateLayerContext();
        });
    };
    TextLayerComponent.prototype.ngOnDestroy = function () { };
    TextLayerComponent.prototype.saveQuillDelta = function (value) {
        this.layer.quillDelta = value;
        this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Layer);
    };
    TextLayerComponent.prototype.updateColor = function () {
        var _this = this;
        if (this.colorSubscription) {
            this.colorSubscription.unsubscribe();
            this.colorSubscription = undefined;
        }
        var fills$ = this.layer.fills.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var css$ = item.type == FillType.Color
                ? item.css$({ frame: _this.layer.frame, context: _this.viewContext })
                : of(undefined);
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(css$, enabled$).pipe(map(function (_a) {
                var css = _a[0], enabled = _a[1];
                return {
                    id: item.id,
                    color: isSet(css.background) ? _this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
                    enabled: enabled
                };
            }));
        });
        if (!fills$.length) {
            this.color = undefined;
            this.cd.markForCheck();
            return;
        }
        this.colorSubscription = combineLatest(fills$)
            .pipe(untilDestroyed(this))
            .subscribe(function (fills) {
            _this.color = last(fills.filter(function (item) { return item.enabled; }).map(function (item) { return item.color; }));
            _this.cd.markForCheck();
        });
    };
    TextLayerComponent.prototype.updateTextStroke = function () {
        var _this = this;
        if (this.textStrokeSubscription) {
            this.textStrokeSubscription.unsubscribe();
            this.textStrokeSubscription = undefined;
        }
        var borders$ = this.layer.borders.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var textStroke$ = item.cssTextStroke$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(textStroke$, enabled$).pipe(map(function (_a) {
                var textStroke = _a[0], enabled = _a[1];
                return {
                    textStroke: isSet(textStroke) ? _this.sanitizer.bypassSecurityTrustStyle(textStroke) : undefined,
                    enabled: enabled
                };
            }));
        });
        if (!borders$.length) {
            this.textStroke = undefined;
            this.cd.markForCheck();
            return;
        }
        this.textStrokeSubscription = combineLatest(borders$)
            .pipe(untilDestroyed(this))
            .subscribe(function (borders) {
            _this.textStroke = last(borders.filter(function (item) { return item.enabled && isSet(item.textStroke); }).map(function (item) { return item.textStroke; }));
            _this.cd.markForCheck();
        });
    };
    TextLayerComponent.prototype.updateTextShadow = function () {
        var _this = this;
        if (this.textShadowSubscription) {
            this.textShadowSubscription.unsubscribe();
            this.textShadowSubscription = undefined;
        }
        var shadows$ = this.layer.shadows
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var textShadow$ = item.cssTextShadow$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(textShadow$, enabled$).pipe(map(function (_a) {
                var textShadow = _a[0], enabled = _a[1];
                return {
                    textShadow: textShadow,
                    enabled: enabled
                };
            }));
        });
        if (!shadows$.length) {
            this.textShadow = undefined;
            this.cd.markForCheck();
            return;
        }
        this.textShadowSubscription = combineLatest(shadows$)
            .pipe(untilDestroyed(this))
            .subscribe(function (shadows) {
            _this.textShadow = _this.sanitizer.bypassSecurityTrustStyle(shadows
                .filter(function (item) { return item.enabled; })
                .map(function (item) { return item.textShadow; })
                .join(','));
            _this.cd.markForCheck();
        });
    };
    TextLayerComponent.prototype.updateFontStyle = function () {
        this.fontStyle = this.layer.font
            ? this.sanitizer.bypassSecurityTrustStyle(this.layer.font.cssFontStyle())
            : undefined;
        this.cd.markForCheck();
    };
    TextLayerComponent.prototype.updateTextTransform = function () {
        this.textTransform = this.sanitizer.bypassSecurityTrustStyle(this.layer.cssTextTransform());
        this.cd.markForCheck();
    };
    TextLayerComponent.prototype.updateTextDecoration = function () {
        this.textDecoration = this.sanitizer.bypassSecurityTrustStyle(this.layer.cssTextDecoration());
        this.cd.markForCheck();
    };
    TextLayerComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: this.layer.id,
                name: this.layer.name,
                icon: this.layer.icon
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: this.layer.name,
                icon: this.layer.icon
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    TextLayerComponent.prototype.startEditing = function (options) {
        if (options === void 0) { options = {}; }
        if (this.editing || !this.customizeEnabled) {
            return;
        }
        this.editing = true;
        this.editingOnCreate = !!options.onCreate;
        this.cd.detectChanges();
        this.layerCustomize.emit();
        if (this.textEditorComponent) {
            this.textEditorComponent.selectAll();
        }
    };
    TextLayerComponent.prototype.finishEditing = function () {
        if (!this.editing) {
            return;
        }
        this.editing = false;
        this.editingOnCreate = false;
        this.cd.markForCheck();
        if (this.textEditorComponent && this.textEditorComponent.isEmpty()) {
            this.layerDelete.emit();
        }
    };
    TextLayerComponent.prototype.toggleEditing = function () {
        if (this.editing) {
            this.finishEditing();
        }
        else {
            this.startEditing();
        }
    };
    TextLayerComponent.prototype.onTextEditorMouseDown = function (event) {
        if (this.editorContext.isCustomizingLayer(this.layer) && this.editing) {
            event.stopPropagation();
        }
    };
    TextLayerComponent.prototype.onTextEditorEscapeClick = function () {
        var _this = this;
        setTimeout(function () { return _this.finishEditing(); });
    };
    TextLayerComponent.prototype.onToolbarClick = function (event) {
        markViewLayerClick(event);
    };
    return TextLayerComponent;
}(LayerComponent));
export { TextLayerComponent };
registerLayerComponent(LayerType.Text, TextLayerComponent);

import isArray from 'lodash/isArray';
import * as moment from 'moment';

import {
  exactFieldLookup,
  gteFieldLookup,
  gtFieldLookup,
  inFieldLookup,
  isCurrentMonthFieldLookup,
  isCurrentQuarterFieldLookup,
  isCurrentWeekFieldLookup,
  isCurrentYearFieldLookup,
  isFutureFieldLookup,
  isLastMonthFieldLookup,
  isLastQuarterFieldLookup,
  isLastWeekFieldLookup,
  isLastXDaysFieldLookup,
  isLastYearFieldLookup,
  isNullFieldLookup,
  isPastFieldLookup,
  isPreviousMonthFieldLookup,
  isPreviousQuarterFieldLookup,
  isPreviousWeekFieldLookup,
  isPreviousXDaysFieldLookup,
  isPreviousYearFieldLookup,
  isTodayFieldLookup,
  isYesterdayFieldLookup,
  lteFieldLookup,
  ltFieldLookup
} from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';

function getGranularity(field: BaseField): moment.unitOfTime.StartOf {
  return field && field.params['time'] === false ? 'day' : undefined;
}

export const dateTimeAirtableFormulas = [
  {
    field: FieldType.DateTime,
    lookup: gteFieldLookup,
    formula: (lookupValue: moment.Moment, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = lookupValue.clone().startOf(granularity);
      return `OR(IS_SAME({${field.name}}, "${lookupMoment.toISOString()}"), IS_AFTER({${
        field.name
      }}, "${lookupMoment.toISOString()}"))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: gtFieldLookup,
    formula: (lookupValue: moment.Moment, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = lookupValue.clone().startOf(granularity);
      return `IS_AFTER({${field.name}}, "${lookupMoment.toISOString()}")`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: lteFieldLookup,
    formula: (lookupValue: moment.Moment, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = lookupValue.clone().startOf(granularity);
      return `OR(IS_SAME({${field.name}}, "${lookupMoment.toISOString()}"), IS_BEFORE({${
        field.name
      }}, "${lookupMoment.toISOString()}"))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: ltFieldLookup,
    formula: (lookupValue: moment.Moment, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = lookupValue.clone().startOf(granularity);
      return `IS_BEFORE({${field.name}}, "${lookupMoment.toISOString()}")`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPastFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = moment().startOf(granularity);
      return `IS_BEFORE({${field.name}}, "${lookupMoment.toISOString()}")`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isFutureFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = moment().startOf(granularity);
      return `IS_AFTER({${field.name}}, "${lookupMoment.toISOString()}")`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isTodayFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const from = moment().startOf('day');
      const to = moment().endOf('day');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isYesterdayFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const yesterday = moment().subtract(1, 'day');
      const from = yesterday.clone().startOf('day');
      const to = yesterday.clone().endOf('day');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastWeekFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const from = moment().startOf('week');
      const to = moment().endOf('week');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentWeekFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const now = moment();
      const from = now.clone().startOf('week');
      const to = now.clone().endOf('week');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousWeekFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const prevWeek = moment().subtract(1, 'week');
      const from = prevWeek.clone().startOf('week');
      const to = prevWeek.clone().endOf('week');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastMonthFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const from = moment().startOf('month');
      const to = moment().endOf('month');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentMonthFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const now = moment();
      const from = now.clone().startOf('month');
      const to = now.clone().endOf('month');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousMonthFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const prevMonth = moment().subtract(1, 'month');
      const from = prevMonth.clone().startOf('month');
      const to = prevMonth.clone().endOf('month');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastQuarterFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const from = moment().startOf('quarter');
      const to = moment().endOf('quarter');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentQuarterFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const now = moment();
      const from = now.clone().startOf('quarter');
      const to = now.clone().endOf('quarter');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousQuarterFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const prevQuarter = moment().subtract(1, 'quarter');
      const from = prevQuarter.clone().startOf('quarter');
      const to = prevQuarter.clone().endOf('quarter');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastYearFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const from = moment().startOf('year');
      const to = moment().endOf('year');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentYearFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const now = moment();
      const from = now.clone().startOf('year');
      const to = now.clone().endOf('year');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousYearFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }
      const prevYear = moment().subtract(1, 'year');
      const from = prevYear.clone().startOf('year');
      const to = prevYear.clone().endOf('year');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastXDaysFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const from = moment().subtract(lookupValue, 'days');
      const to = moment();
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousXDaysFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const from = moment().subtract(lookupValue * 2, 'days');
      const to = moment().subtract(lookupValue, 'days');
      return `OR(IS_SAME({${field.name}}, "${from.toISOString()}"), AND(IS_AFTER({${
        field.name
      }}, "${from.toISOString()}"), IS_BEFORE({${field.name}}, "${to.toISOString()}")))`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: exactFieldLookup,
    formula: (lookupValue: moment.Moment, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }
      const granularity = getGranularity(field);
      const lookupMoment = lookupValue.clone().startOf(granularity);
      return `IS_SAME({${field.name}}, "${lookupMoment.toISOString()}")`;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isNullFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      // return lookupValue ? fieldValue === null : fieldValue !== null;
      return;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: inFieldLookup,
    formula: (lookupValue: moment.Moment[], field?: BaseField): string => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return;
      }

      if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const granularity = getGranularity(field);
      const predicates = lookupValue.map(item => {
        const lookupMoment = item.clone().startOf(granularity);
        return `IS_SAME({${field.name}}, "${lookupMoment.toISOString()}")`;
      });
      return `OR(${predicates.join(',')})`;
    }
  }
];

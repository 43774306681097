<div class="browser-window">
  <div *ngIf="header" class="browser-window__header">
    <app-browser-header
      [address]="page.name | appCapitalize"
      [addressIconHasDefault]="false"
      [addressSearchIcon]="false"
    ></app-browser-header>
  </div>

  <div class="browser-window__content">
    <div class="browser-window__content-inner">
      <div appElementGroupsContainer class="browser-window__elements">
        <app-auto-element
          *ngFor="let item of page.elements; let i = index; trackBy: trackElement"
          [element]="item"
          [context]="context"
          (visibleUpdated)="updateElementStates()"
        >
        </app-auto-element>
      </div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./integrations-code.component";
var styles_IntegrationsCodeComponent = [];
var RenderType_IntegrationsCodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IntegrationsCodeComponent, data: {} });
export { RenderType_IntegrationsCodeComponent as RenderType_IntegrationsCodeComponent };
export function View_IntegrationsCodeComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_IntegrationsCodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-integrations-code", [], null, null, null, View_IntegrationsCodeComponent_0, RenderType_IntegrationsCodeComponent)), i0.ɵdid(1, 638976, null, 0, i1.IntegrationsCodeComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationsCodeComponentNgFactory = i0.ɵccf("app-integrations-code", i1.IntegrationsCodeComponent, View_IntegrationsCodeComponent_Host_0, { projectSettings: "projectSettings" }, {}, []);
export { IntegrationsCodeComponentNgFactory as IntegrationsCodeComponentNgFactory };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import round from 'lodash/round';

import { DelayWorkflowStep, WorkflowStepType } from '@modules/workflow';
import { TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

@Component({
  selector: 'app-delay-workflow-step',
  templateUrl: './delay-workflow-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelayWorkflowStepComponent extends WorkflowStepComponent<DelayWorkflowStep> implements OnInit, OnChanges {
  title: string;
  icon: string;

  constructor(workflowEditContext: WorkflowEditContext, cd: ChangeDetectorRef) {
    super(undefined, workflowEditContext, cd);
  }

  ngOnInit() {
    this.title = this.getTitle();
    this.icon = this.step.getIcon();
    this.trackRun();
    this.trackExecuteStatus();

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<DelayWorkflowStepComponent>): void {
    if (changes.step) {
      this.title = this.getTitle();
      this.icon = this.step.getIcon();
    }
  }

  getTitle() {
    const seconds = this.step.delay;
    const minutes = seconds / 60;
    const hours = minutes / 60;

    if (hours >= 1) {
      return `${round(hours, 1)} ${hours == 1 ? 'hour' : 'hours'}`;
    } else if (minutes >= 1) {
      return `${round(minutes, 1)} ${minutes == 1 ? 'minute' : 'minutes'}`;
    } else {
      return `${round(seconds, 1)} ${seconds == 1 ? 'second' : 'seconds'}`;
    }
  }
}

registerWorkflowStepComponent(WorkflowStepType.Delay, DelayWorkflowStepComponent);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../features/components/feature-overview/feature-overview.component.ngfactory";
import * as i2 from "../../../features/components/feature-overview/feature-overview.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "@angular/common";
import * as i5 from "../../../features/pipes/feature-active/feature-active.pipe";
import * as i6 from "../../../features/services/feature/feature.service";
import * as i7 from "./conversations.component";
var styles_ConversationsComponent = [];
var RenderType_ConversationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConversationsComponent, data: {} });
export { RenderType_ConversationsComponent as RenderType_ConversationsComponent };
function View_ConversationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "app-feature-overview", [], null, null, null, i1.View_FeatureOverviewComponent_0, i1.RenderType_FeatureOverviewComponent)), i0.ɵdid(1, 245760, null, 0, i2.FeatureOverviewComponent, [[2, i3.CurrentProjectStore]], { fill: [0, "fill"], feature: [1, "feature"] }, null), (_l()(), i0.ɵeld(2, 0, null, 1, 3, "div", [["data-title", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enhance Teamwork in the Workplace with "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Collaboration"])), (_l()(), i0.ɵeld(6, 0, null, 2, 7, "div", [["data-description", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Assign tasks and leave notes"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Chat with your teammates directly through Jet"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Make sure everyone on the team is up-to-date"]))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "collaboration"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ConversationsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 3, null, View_ConversationsComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(0, i5.FeatureActivePipe, [i6.FeatureService]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = !i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("collaboration")))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConversationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-conversations", [], null, null, null, View_ConversationsComponent_0, RenderType_ConversationsComponent)), i0.ɵdid(1, 114688, null, 0, i7.ConversationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConversationsComponentNgFactory = i0.ɵccf("app-conversations", i7.ConversationsComponent, View_ConversationsComponent_Host_0, {}, {}, []);
export { ConversationsComponentNgFactory as ConversationsComponentNgFactory };

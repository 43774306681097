var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { ProjectPermissionType } from '@modules/projects';
import { ascComparator } from '@shared';
import { ProjectObjectPermissionsArray } from './project-object-permissions.array';
import { ProjectPermissionControl } from './project-permission.control';
var ProjectPagePermissionsArray = /** @class */ (function (_super) {
    __extends(ProjectPagePermissionsArray, _super);
    function ProjectPagePermissionsArray(viewSettingsStore) {
        var _this = _super.call(this) || this;
        _this.viewSettingsStore = viewSettingsStore;
        _this.initControls();
        return _this;
    }
    ProjectPagePermissionsArray.prototype.ngOnDestroy = function () { };
    ProjectPagePermissionsArray.prototype.initControls = function () {
        var _this = this;
        this.viewSettingsStore
            .getFirst()
            .pipe(untilDestroyed(this))
            .subscribe(function (viewSettings) {
            var existingControls = [];
            viewSettings
                .filter(function (item) { return item.view == ViewSettingsType.Custom; })
                .forEach(function (page) {
                var existingControl = _this.controls.find(function (item) { return item.value.object == page.uid; });
                if (existingControl) {
                    existingControls.push(existingControl);
                }
                else {
                    var control_1 = new ProjectPermissionControl({
                        type: ProjectPermissionType.Page,
                        object: page.uid
                    }, { page: page });
                    var permission = _this.permissions.find(function (item) { return item.permissionType == control_1.value.type && item.permissionObject == control_1.value.object; });
                    control_1.deserialize(permission);
                    _this.push(control_1);
                    existingControls.push(control_1);
                }
            });
            var deleteControls = _this.controls.filter(function (item) { return !existingControls.includes(item); });
            deleteControls.forEach(function (item) { return _this.removeAt(_this.controls.indexOf(item)); });
            _this.controls = _this.controls.sort(function (lhs, rhs) {
                return ascComparator((lhs.data.page.name || '').toLowerCase(), (rhs.data.page.name || '').toLowerCase());
            });
            _this._initialized$.next(true);
        });
    };
    return ProjectPagePermissionsArray;
}(ProjectObjectPermissionsArray));
export { ProjectPagePermissionsArray };

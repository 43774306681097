<app-sidebar-section [title]="'Specify Inputs'" [description]="'Manually or use value from other components'">
  <div class="sidebar__list">
    <div class="sidebar-list component-action-inputs">
      <app-inputs-edit
        [control]="form.controls.inputs"
        [editable]="form.getActionEditable$() | async"
        [parameterProvider]="form.inputFieldProvider"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [userInput]="userInput"
        [collapseContext]="inputsCollapseContext"
        [listWrapper]="false"
        [displayValueTypes]="userInput ? [inputValueTypes.Formula, inputValueTypes.Prompt] : [inputValueTypes.Formula]"
        [classes]="'component-action-inputs-item'"
        [object]="analyticsSource"
        (addInput)="addQueryInput.emit()"
      >
      </app-inputs-edit>
    </div>
  </div>
</app-sidebar-section>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Dexie from 'dexie';
import * as moment from 'moment';
import { ProjectPropertyType } from '../../data/project-property';
var ProjectPropertyValueDB = /** @class */ (function (_super) {
    __extends(ProjectPropertyValueDB, _super);
    function ProjectPropertyValueDB(databaseName) {
        var _this = _super.call(this, databaseName) || this;
        _this.databaseName = databaseName;
        _this.version(1).stores({
            global: '[project+environment+name], value, dateUpdated',
            page: '[project+environment+pageUid+name], value, dateUpdated'
        });
        return _this;
    }
    ProjectPropertyValueDB.prototype.getPropertyTable = function (type) {
        if (type == ProjectPropertyType.Global) {
            return this.global;
        }
        else if (type == ProjectPropertyType.Page) {
            return this.page;
        }
    };
    ProjectPropertyValueDB.prototype.getPropertyCriteria = function (projectName, environmentName, property, options) {
        if (options === void 0) { options = {}; }
        if (property.type == ProjectPropertyType.Global) {
            return {
                project: projectName,
                environment: environmentName,
                name: property.uid
            };
        }
        else if (property.type == ProjectPropertyType.Page) {
            return {
                project: projectName,
                environment: environmentName,
                pageUid: options.pageUid,
                name: property.uid
            };
        }
    };
    ProjectPropertyValueDB.prototype.getGlobalPropertyPk = function (criteria) {
        return [criteria.project, criteria.environment, criteria.name];
    };
    ProjectPropertyValueDB.prototype.getPagePropertyPk = function (criteria) {
        return [criteria.project, criteria.environment, criteria.pageUid, criteria.name];
    };
    ProjectPropertyValueDB.prototype.getPropertyValue = function (type, criteria) {
        var table = this.getPropertyTable(type);
        return table.get(criteria).then(function (record) {
            return record ? record.value : undefined;
        });
    };
    ProjectPropertyValueDB.prototype.putPropertyValue = function (type, criteria, value) {
        var data = __assign({}, criteria, { value: value, dateUpdated: moment().toISOString() });
        if (type == ProjectPropertyType.Global) {
            return this.global.put(data);
        }
        else if (type == ProjectPropertyType.Page) {
            return this.page.put(data);
        }
    };
    ProjectPropertyValueDB.prototype.deleteProperty = function (type, criteria) {
        if (type == ProjectPropertyType.Global) {
            var pk = this.getGlobalPropertyPk(criteria);
            return this.global.delete(pk);
        }
        else if (type == ProjectPropertyType.Page) {
            var pk = this.getPagePropertyPk(criteria);
            return this.page.delete(pk);
        }
    };
    return ProjectPropertyValueDB;
}(Dexie));
export { ProjectPropertyValueDB };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Widget } from '../../data/widgets/items/widget';
import { getWidgetByType } from '../../data/widgets/widgets';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var WidgetService = /** @class */ (function () {
    function WidgetService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    WidgetService.prototype.get = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'widgets/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) {
            return result.map(function (item) {
                var element = getWidgetByType(item['widget_type']);
                if (!element) {
                    return new Widget().deserialize(item);
                }
                return new element().deserialize(item);
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WidgetService.prototype.getDetail = function (projectName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "widgets/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) {
            var element = getWidgetByType(result['type']);
            if (!element) {
                return new Widget().deserialize(result);
            }
            return new element().deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WidgetService.prototype.create = function (projectName, widget) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "widgets/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, widget.serialize(), { headers: headers });
        }), map(function (result) {
            var element = getWidgetByType(result['widget_type']);
            if (!element) {
                return new Widget().deserialize(result);
            }
            return new element().deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WidgetService.prototype.update = function (projectName, widget, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "widgets/" + widget.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, widget.serialize(fields), { headers: headers });
        }), map(function (result) {
            var widgetType = widget.constructor;
            return new widgetType().deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WidgetService.prototype.delete = function (projectName, widget) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "widgets/" + widget.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WidgetService.ngInjectableDef = i0.defineInjectable({ factory: function WidgetService_Factory() { return new WidgetService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: WidgetService, providedIn: "root" });
    return WidgetService;
}());
export { WidgetService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { isSet } from '@shared';
function getElementStateProgress(state) {
    return {
        currentItemIndex: state.currentItemIndex,
        itemsCount: state.itemsCount
    };
}
var StepsElementComponent = /** @class */ (function (_super) {
    __extends(StepsElementComponent, _super);
    function StepsElementComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.state = {};
        _this.progress = 0;
        _this.trackStepItem = (function () {
            return function (i, item) {
                return item.item.uid;
            };
        })();
        return _this;
    }
    StepsElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    StepsElementComponent.prototype.ngOnDestroy = function () { };
    StepsElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.elementOnChange(this.element);
        }
    };
    StepsElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.element$
            .pipe(switchMap(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    StepsElementComponent.prototype.getElementState = function (element) {
        var _this = this;
        var currentItem$ = element.currentItem
            ? applyParamInput$(element.currentItem, {
                context: this.context,
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of(undefined);
        var itemsVisible$ = element.items.length
            ? combineLatest(element.items.map(function (item) {
                if (!item.visibleInput) {
                    return of({ item: item, visible: true });
                }
                else {
                    return applyBooleanInput$(item.visibleInput, { context: _this.context }).pipe(map(function (visible) {
                        return { item: item, visible: visible };
                    }));
                }
            }))
            : of([]);
        return combineLatest(this.customizeService.enabled$, currentItem$, itemsVisible$).pipe(map(function (_a) {
            var customize = _a[0], currentItem = _a[1], itemsVisible = _a[2];
            var items = itemsVisible.filter(function (item) {
                if (customize) {
                    return true;
                }
                else {
                    return item.visible;
                }
            });
            var currentItemIndex = items.findIndex(function (item) { return item.item.value == currentItem; });
            return {
                items: items,
                itemsCount: items.length,
                currentItem: currentItem,
                currentItemIndex: currentItemIndex,
                currentItemColor: currentItemIndex !== -1 ? items[currentItemIndex].item.color : undefined
            };
        }));
    };
    StepsElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateProgress(state), getElementStateProgress(this.state))) {
            this.updateProgress(state);
        }
    };
    StepsElementComponent.prototype.updateProgress = function (state) {
        if (state.currentItemIndex === -1 || !isSet(state.itemsCount) || state.itemsCount === 0) {
            this.progress = 0;
            this.cd.markForCheck();
            return;
        }
        var itemWidth = 1 / state.itemsCount;
        if (state.currentItemIndex === 0) {
            this.progress = 0;
        }
        else if (state.currentItemIndex === state.itemsCount - 1) {
            this.progress = 1;
        }
        else {
            this.progress = itemWidth * state.currentItemIndex + itemWidth * 0.5;
        }
        this.cd.markForCheck();
    };
    return StepsElementComponent;
}(BaseElementComponent));
export { StepsElementComponent };
registerElementComponent({
    type: ElementType.Steps,
    component: StepsElementComponent,
    label: 'Image',
    actions: []
});

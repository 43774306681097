import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MenuSection, MenuService } from '@modules/menu';
import { MetaService } from '@modules/meta';

import { NavigationItem } from '../../data/navigation-item';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _items = new BehaviorSubject<NavigationItem[]>([]);
  private _routeSnapshot = new BehaviorSubject<ActivatedRouteSnapshot>(null);

  get items(): NavigationItem[] {
    return this._items.value;
  }

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  set items(value: NavigationItem[]) {
    this._items.next(value);
  }

  get routeSnapshot(): ActivatedRouteSnapshot {
    return this._routeSnapshot.value;
  }

  get routeSnapshot$(): Observable<ActivatedRouteSnapshot> {
    return this._routeSnapshot.asObservable();
  }

  constructor(private router: Router, private metaService: MetaService, private menuService: MenuService) {
    this.router.events
      .pipe(filter(item => item instanceof NavigationStart))
      .subscribe((e: NavigationEnd) => this.pushItem(e.url));

    this.metaService.titlePrimary$.subscribe(title => {
      if (!this.currentItem) {
        return;
      }

      this.currentItem.title = title;
      this.items = this.items;
    });

    this.menuService.section$.subscribe(section => {
      if (!this.currentItem) {
        return;
      }

      this.currentItem.section = section;
      this.items = this.items;
    });

    this.router.events
      .pipe(filter(item => item instanceof ActivationStart))
      .subscribe(event => this._routeSnapshot.next((event as ActivationStart).snapshot));
  }

  get currentItem() {
    if (!this.items.length) {
      return;
    }
    return this.items[this.items.length - 1];
  }

  pushItem(url: string) {
    const urlTree = this.router.parseUrl(url);
    const group = urlTree.root.children['primary'] || urlTree.root;
    const link = group.segments.map(it => it.path);

    link[0] = `/${link[0]}`;

    this.items = [
      ...this.items,
      {
        link: link,
        queryParams: urlTree.queryParams,
        section: this.menuService.section
      }
    ];
  }

  previousNavigationItem$(menuSection?: MenuSection): Observable<NavigationItem> {
    return this.items$.pipe(
      map(items => {
        return items
          .slice(0, -1)
          .reverse()
          .find(item => item.title && (menuSection == undefined || item.section == menuSection));
      })
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { BorderArray } from '../controls/border.array';
import { CornerRadiusControl } from '../controls/corner-radius.control';
import { FillArray } from '../controls/fill.array';
import { FrameControl } from '../controls/frame.control';
import { LayerInteractionArray } from '../controls/layer-interaction.array';
import { ShadowArray } from '../controls/shadow.array';
import { ValueResizeControl } from '../controls/value-resize.control';
var CustomizeViewForm = /** @class */ (function (_super) {
    __extends(CustomizeViewForm, _super);
    function CustomizeViewForm() {
        return _super.call(this, {
            frame: new FrameControl(),
            width_resize: new ValueResizeControl(),
            height_resize: new ValueResizeControl(),
            constrain_proportion: new FormControl(undefined),
            clip_content: new FormControl(false),
            resize_content: new FormControl(true),
            opacity: new FormControl(1),
            fills: new FillArray([]),
            borders: new BorderArray([]),
            shadows: new ShadowArray([]),
            corner_radius: new CornerRadiusControl(),
            interactions: new LayerInteractionArray([])
        }) || this;
    }
    CustomizeViewForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        this.controls.frame.deserialize(instance.frame);
        this.controls.width_resize.deserialize(instance.widthResize);
        this.controls.height_resize.deserialize(instance.heightResize);
        this.controls.constrain_proportion.patchValue(instance.constrainProportion);
        this.controls.clip_content.patchValue(instance.clipContent);
        this.controls.resize_content.patchValue(instance.resizeContent);
        this.controls.opacity.patchValue(instance.opacity);
        this.controls.fills.deserialize(instance.fills);
        this.controls.borders.deserialize(instance.borders);
        this.controls.shadows.deserialize(instance.shadows);
        this.controls.corner_radius.deserialize(instance.cornerRadius);
        this.controls.interactions.deserialize(instance.interactions);
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeViewForm.prototype.updateWidthProportional = function () {
        this.controls.frame.updateWidthProportional(this.controls.constrain_proportion.value);
    };
    CustomizeViewForm.prototype.updateHeightProportional = function () {
        this.controls.frame.updateHeightProportional(this.controls.constrain_proportion.value);
    };
    CustomizeViewForm.prototype.toggleConstrainProportion = function () {
        if (this.controls.constrain_proportion.value) {
            this.controls.constrain_proportion.patchValue(undefined);
        }
        else {
            this.controls.constrain_proportion.patchValue(this.controls.frame.getProportion());
        }
    };
    CustomizeViewForm.prototype.submit = function () {
        var instance = this.instance;
        instance.frame = this.controls.frame.serialize();
        instance.widthResize = this.controls.width_resize.serialize();
        instance.heightResize = this.controls.height_resize.serialize();
        instance.constrainProportion = this.controls.constrain_proportion.value;
        instance.clipContent = this.controls.clip_content.value;
        instance.resizeContent = this.controls.resize_content.value;
        instance.opacity = this.controls.opacity.value;
        instance.fills = this.controls.fills.serialize();
        instance.borders = this.controls.borders.serialize();
        instance.shadows = this.controls.shadows.serialize();
        instance.cornerRadius = this.controls.corner_radius.serialize();
        instance.interactions = this.controls.interactions.serialize();
        return instance;
    };
    return CustomizeViewForm;
}(FormGroup));
export { CustomizeViewForm };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';
import { Color } from './color';
import { Frame } from './frame';
import { Gradient } from './gradient';
import { IconFill } from './icon-fill';
import { ImageFill } from './image-fill';
export var FillType;
(function (FillType) {
    FillType["Color"] = "color";
    FillType["Gradient"] = "gradient";
    FillType["Image"] = "image";
    FillType["Icon"] = "icon";
})(FillType || (FillType = {}));
var Fill = /** @class */ (function () {
    function Fill(options) {
        if (options === void 0) { options = {}; }
        this.type = FillType.Color;
        this.opacity = 1;
        this.enabled = true;
        Object.assign(this, options);
    }
    Fill.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.opacity = data['opacity'];
        if (data['type']) {
            this.type = data['type'];
        }
        if (data['color']) {
            this.color = new Color().deserialize(data['color']);
        }
        if (data['color_input']) {
            this.colorInput = new Input().deserialize(data['color_input']);
        }
        if (data['gradient']) {
            this.gradient = new Gradient().deserialize(data['gradient']);
        }
        if (data['image_fill']) {
            this.imageFill = new ImageFill().deserialize(data['image_fill']);
        }
        if (data['icon_fill']) {
            this.iconFill = new IconFill().deserialize(data['icon_fill']);
        }
        if (isSet(data['enabled'])) {
            this.enabled = data['enabled'];
        }
        if (data['enabled_input']) {
            this.enabledInput = new Input().deserialize(data['enabled_input']);
        }
        if (!this.id) {
            this.generateId();
        }
        return this;
    };
    Fill.prototype.serialize = function () {
        return {
            id: this.id,
            type: this.type,
            color: this.color ? this.color.serialize() : undefined,
            color_input: this.colorInput ? this.colorInput.serialize() : null,
            gradient: this.gradient ? this.gradient.serialize() : undefined,
            image_fill: this.imageFill ? this.imageFill.serialize() : undefined,
            icon_fill: this.iconFill ? this.iconFill.serialize() : undefined,
            opacity: this.opacity,
            enabled: this.enabled,
            enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
        };
    };
    Fill.prototype.generateId = function () {
        this.id = generateAlphanumeric(4, { letterFirst: true });
    };
    Fill.prototype.css$ = function (options) {
        if (options === void 0) { options = {}; }
        options = __assign({ frame: new Frame({ width: 1, height: 1 }) }, options);
        if (this.type == FillType.Color && this.colorInput) {
            return applyParamInput$(this.colorInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            }).pipe(map(function (value) { return ({ background: value }); }));
        }
        else if (this.type == FillType.Color && this.color) {
            return of({
                background: this.color.css()
            });
        }
        else if (this.type == FillType.Gradient && this.gradient) {
            return this.gradient.css$({
                frame: options.frame,
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            });
        }
        else if (this.type == FillType.Image && this.imageFill) {
            return this.imageFill.cssBackground$(options).pipe(map(function (background) {
                return {
                    background: background
                };
            }));
        }
        return of({});
    };
    Fill.prototype.enabled$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.enabledInput) {
            return applyBooleanInput$(this.enabledInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            }).pipe(catchError(function () { return of(false); }));
        }
        else {
            return of(true);
        }
    };
    return Fill;
}());
export { Fill };

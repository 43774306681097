var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpUrlEncodingCodec } from '@angular/common/http';
import * as i0 from "@angular/core";
var CustomHttpUrlEncodingCodec = /** @class */ (function (_super) {
    __extends(CustomHttpUrlEncodingCodec, _super);
    function CustomHttpUrlEncodingCodec() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomHttpUrlEncodingCodec.prototype.encodeValue = function (v) {
        if (!v) {
            return v;
        }
        return v.toString().replace(/;/g, '%3B').replace(/\+/g, '%2B');
    };
    CustomHttpUrlEncodingCodec.ngInjectableDef = i0.defineInjectable({ factory: function CustomHttpUrlEncodingCodec_Factory() { return new CustomHttpUrlEncodingCodec(); }, token: CustomHttpUrlEncodingCodec, providedIn: "root" });
    return CustomHttpUrlEncodingCodec;
}(HttpUrlEncodingCodec));
export { CustomHttpUrlEncodingCodec };

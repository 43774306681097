import { AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import keys from 'lodash/keys';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionItem } from '@modules/actions';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ActionOutput } from '@modules/fields';

export const validateAction: AsyncValidatorFn = control => {
  const parent = control.parent as ActionOutputFormGroup;

  if (!parent) {
    return of(null);
  }

  if (!control.value) {
    return of(null);
  }

  return parent.elementConfigurationService.isActionConfigured(control.value).pipe(
    map(configured => {
      if (!configured) {
        return { required: true };
      }
    })
  );
};

export class ActionOutputFormGroup extends FormGroup {
  constructor(public elementConfigurationService: ElementConfigurationService) {
    super({});
  }

  deserialize(outputs: ActionOutput[], actions: { name: string; action: ActionItem }[]) {
    keys(this.controls)
      .filter(name => !outputs.some(item => item.name == name))
      .forEach(name => this.removeControl(name));

    outputs.forEach(output => {
      const actionsItem = actions.find(item => item.name == output.name);
      const action = actionsItem ? actionsItem.action : undefined;

      if (this.contains(output.name)) {
        this.controls[output.name].patchValue(action);
      } else {
        const control = new FormControl(action, undefined, validateAction);
        this.setControl(output.name, control);
      }
    });
  }

  serialize(): { name: string; action: ActionItem }[] {
    return keys(this.controls)
      .map(name => {
        const control = this.controls[name];
        return { name: name, action: control ? control.value : undefined };
      })
      .filter(item => item.action);
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { SEARCH_PARAM } from '@modules/models';
import { indent, indentLines } from '@shared';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';
var ModelApiGroupQueryComponent = /** @class */ (function (_super) {
    __extends(ModelApiGroupQueryComponent, _super);
    function ModelApiGroupQueryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.parameters = [];
        return _this;
    }
    ModelApiGroupQueryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchModel();
        this.updateParameters();
    };
    ModelApiGroupQueryComponent.prototype.updateParameters = function () {
        this.parameters = this.getParameters();
        this.cd.markForCheck();
    };
    ModelApiGroupQueryComponent.prototype.updateCurl = function () {
        this.curl = this.getCurlRequest({
            url: this.getAbsoluteApiUrl(),
            params: {
                _y_func: ':_y_func',
                _y_column: ':_y_column',
                _x_column: ':_x_column',
                _x_lookup: ':_x_lookup'
            }
        });
    };
    ModelApiGroupQueryComponent.prototype.getParametersAuto = function () {
        var _this = this;
        var aggregations = [
            AggregateFunc.Count,
            AggregateFunc.Sum,
            AggregateFunc.Min,
            AggregateFunc.Max,
            AggregateFunc.Avg
        ];
        var fieldAggregations = aggregations.filter(function (item) { return item != AggregateFunc.Count; });
        var lookups = [
            DatasetGroupLookup.DateDay,
            DatasetGroupLookup.DateWeek,
            DatasetGroupLookup.DateMonth,
            DatasetGroupLookup.DateQuarter,
            DatasetGroupLookup.DateYear,
            DatasetGroupLookup.DateHour,
            DatasetGroupLookup.DateMinute,
            DatasetGroupLookup.DateSecond
        ];
        return [
            {
                type: ApiParameterType.Query,
                name: '_y_func',
                fieldTypeLabel: 'string',
                description: "Type of aggregation to apply, should be one of: " + aggregations.join(', '),
                required: true
            },
            {
                type: ApiParameterType.Query,
                name: '_y_column',
                fieldTypeLabel: 'string',
                description: "Field to use for aggregation. Required for the following _y_func: " + fieldAggregations.join(', ')
            },
            {
                type: ApiParameterType.Query,
                name: '_x_column',
                fieldTypeLabel: 'string',
                description: "Field to use for grouping",
                required: true
            },
            {
                type: ApiParameterType.Query,
                name: '_x_lookup',
                fieldTypeLabel: 'string',
                description: "Transformation to apply for grouping. Can be one of the following: " + lookups.join(', ')
            }
        ].concat(this.modelDescription.dbFields.map(function (field) {
            var mapLookup = function (lookupInfo) {
                var lookup = lookupInfo.type;
                return {
                    type: ApiParameterType.Query,
                    name: _this.getFieldParamName(field.name, lookup.lookup),
                    fieldTypeLabel: lookupInfo.field,
                    fieldTypeDescription: {
                        field: field.field,
                        params: field.params
                    },
                    description: "Return results where the " + field.verboseName + " field " + lookup.verboseName + " this value."
                };
            };
            var main = field.fieldDescription.lookups
                .filter(function (lookupInfo) { return !lookupInfo.type.lookup; })
                .map(function (lookupInfo) { return mapLookup(lookupInfo); })[0];
            var children = field.fieldDescription.lookups
                .filter(function (lookupInfo) { return lookupInfo.type.lookup; })
                .map(function (lookupInfo) { return mapLookup(lookupInfo); });
            var first = main ? main : children[0];
            var others = main ? children : children.slice(1);
            return __assign({}, first, (others.length ? { children: others } : {}));
        }), [
            {
                type: ApiParameterType.Query,
                name: SEARCH_PARAM,
                fieldTypeLabel: 'string',
                description: "Search results TEXT fields with the specified value. Search is case insensitive."
            }
        ]);
    };
    ModelApiGroupQueryComponent.prototype.getQueryUrl = function () {
        return "/aggregate";
    };
    ModelApiGroupQueryComponent.prototype.getResponseData = function () {
        var modelData1 = {
            group: '2020-08-01T00:00:00',
            y_func: 218
        };
        var modelData2 = {
            group: '2020-08-02T00:00:00',
            y_func: 43
        };
        return [
            '[',
            indentLines(JSON.stringify(modelData1, null, this.indentSize), this.indentSize) + ',',
            indentLines(JSON.stringify(modelData2, null, this.indentSize), this.indentSize) + ',',
            indent("{...},", this.indentSize),
            indent("{...}", this.indentSize),
            ']'
        ].join('\n');
    };
    return ModelApiGroupQueryComponent;
}(ModelApiBaseQueryComponent));
export { ModelApiGroupQueryComponent };

import toPairs from 'lodash/toPairs';

import * as mimeTypes from '../../data/mime-types.json';

export function getMimeExtension(mime: string): string {
  if (typeof mime !== 'string') {
    return;
  }

  mime = mime.toLowerCase();

  if (mime == 'application/octet-stream') {
    return;
  }

  const mimeType = mimeTypes[mime];
  return mimeType && mimeType['extensions'] ? mimeType['extensions'][0] : undefined;
}

export function getExtensionMime(extension: string): string {
  if (typeof extension !== 'string') {
    return;
  }

  extension = extension.toLowerCase();

  const mimeType = toPairs(mimeTypes).find(([k, v]) => v['extensions'] && v['extensions'].includes(extension));
  return mimeType ? mimeType[0] : undefined;
}

export function isImageMime(mime: string): boolean {
  if (typeof mime !== 'string') {
    return;
  }

  return mime.startsWith('image/');
}

import * as moment from 'moment';

export enum PaymentProvider {
  Paddle = 'paddle'
}

export enum Currency {
  usd = 'usd',
  eur = 'eur',
  rub = 'rub'
}

export class Transaction {
  public amount: number;
  public amountCurrency: Currency;
  public confirmed = false;
  public processed = false;
  public date: any;
  public dateAdd: any;
  public description: string;
  public provider: PaymentProvider;
  public providerId: string;
  public params = {};
  public receiptUrl: string;

  deserialize(data: Object): Transaction {
    this.description = data['description'];
    this.amount = parseFloat(data['amount']);
    this.amountCurrency = data['amount_currency'];
    this.confirmed = data['confirmed'];
    this.processed = data['processed'];
    this.date = data['date'] ? moment(data['date']) : undefined;
    this.dateAdd = data['date_add'] ? moment(data['date_add']) : undefined;
    this.provider = data['provider'];
    this.providerId = data['provider_id'];

    if (data['params']) {
      this.params = data['params'];
      this.receiptUrl = this.params['receipt_url'];
    }

    return this;
  }
}

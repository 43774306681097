import { ActionOutput, ParameterField } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';

import { Border } from './border';
import { CornerRadius } from './corner-radius';
import { Fill } from './fill';
import { Frame } from './frame';
import { Layer } from './layer';
import {
  CursorLayerInteraction,
  deserializeLayerInteraction,
  LayerInteraction,
  LayerInteractionType
} from './layer-interaction';
import { deserializerLayer } from './layer-types';
import { Shadow } from './shadow';
import { ValueResize } from './value-resize';

export class View {
  public id: string;
  public name: string;
  public frame: Frame;
  public widthResize: ValueResize = new ValueResize();
  public heightResize: ValueResize = new ValueResize();
  public constrainProportion: number;
  public clipContent = false;
  public resizeContent = true;
  public opacity = 1;
  public fills: Fill[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];
  public cornerRadius: CornerRadius = new CornerRadius();
  public interactions: LayerInteraction[] = [];
  public layers: Layer[] = [];
  public parameters: ParameterField[] = [];
  public actions: ActionOutput[] = [];
  public testParameters = {};
  public testState = {};

  constructor(options: Partial<View> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.id = data['id'];
    this.name = data['name'];
    this.testParameters = data['test_parameters'] || {};
    this.testState = data['test_state'] || {};

    if (data.hasOwnProperty('opacity')) {
      this.opacity = data['opacity'];
    }

    if (data['frame']) {
      this.frame = new Frame().deserialize(data['frame']);
    }

    if (data['width_resize']) {
      this.widthResize = new ValueResize().deserialize(data['width_resize']);
    }

    if (data['height_resize']) {
      this.heightResize = new ValueResize().deserialize(data['height_resize']);
    }

    if (data['constrain_proportion']) {
      this.constrainProportion = data['constrain_proportion'];
    }

    if (isSet(data['clip_content'])) {
      this.clipContent = data['clip_content'];
    }

    if (isSet(data['resize_content'])) {
      this.resizeContent = data['resize_content'];
    }

    if (data['fills']) {
      this.fills = data['fills'].map(item => new Fill().deserialize(item));
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    if (data['corner_radius']) {
      this.cornerRadius = new CornerRadius().deserialize(data['corner_radius']);
    }

    if (data['interactions']) {
      this.interactions = data['interactions'].map(item => deserializeLayerInteraction(item));
    }

    if (data['layers']) {
      this.layers = data['layers'].map(item => deserializerLayer(item)).filter(item => item);
    }

    if (data['parameters']) {
      this.parameters = data['parameters'].map(status => new ParameterField().deserialize(status));
    }

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ActionOutput().deserialize(item));
    }

    if (!this.id) {
      this.generateId();
    }

    return this;
  }

  serialize(): Object {
    return {
      id: this.id,
      name: this.name,
      opacity: this.opacity,
      frame: this.frame ? this.frame.serialize() : undefined,
      width_resize: this.widthResize ? this.widthResize.serialize() : undefined,
      height_resize: this.heightResize ? this.heightResize.serialize() : undefined,
      constrain_proportion: this.constrainProportion,
      clip_content: this.clipContent,
      resize_content: this.resizeContent,
      fills: this.fills.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize()),
      corner_radius: this.cornerRadius ? this.cornerRadius.serialize() : undefined,
      interactions: this.interactions.map(item => item.serialize()),
      layers: this.layers.map(item => item.serialize()),
      parameters: this.parameters.map(item => item.serialize()),
      actions: this.actions.map(item => item.serialize()),
      test_parameters: this.testParameters,
      test_state: this.testState
    };
  }

  generateId() {
    this.id = generateAlphanumeric(4, { letterFirst: true });
  }

  getCursor(): string {
    const interaction = this.interactions.find(item => item.type == LayerInteractionType.Cursor);

    if (interaction instanceof CursorLayerInteraction) {
      return interaction.cursor;
    }
  }
}

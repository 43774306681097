<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content" style="min-width: 300px;">
          <div class="token-popup__item">
            <a
              *ngFor="let button of buttons; let first = first"
              href="javascript:void(0)"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              [class.token-popup-list-item_active]="false"
              [style.margin-top.px]="first ? 0 : 5"
              (click)="buttonClick.emit(button); setDropdownOpened(false)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + button.icon"></span>
                  </span>

                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                    {{ button.label }}
                  </span>
                </div>

                <div
                  *ngIf="button.description | appIsSet"
                  class="token-popup-list-item__line"
                  [class.token-popup-list-item__line_active]="false"
                >
                  <div
                    class="token-popup-list-item__description"
                    [innerHTML]="button.description | appCapitalize"
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

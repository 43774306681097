import pickBy from 'lodash/pickBy';
import * as moment from 'moment';
import { SSOSettings } from '@modules/sso';
import { User } from '@modules/users';
import { LogHttpRequest } from './log-http-request';
var Log = /** @class */ (function () {
    function Log() {
        this.publicParams = {};
        this.publicParamsUnused = {};
    }
    Log.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.trace = data['trace'];
        this.level = data['level'];
        this.source = data['source'];
        this.action = data['action'];
        this.text = data['text'];
        this.publicParams = data['public_params'];
        this.dateAdd = moment(data['date_add']);
        if (data['sso_settings']) {
            this.ssoSettings = new SSOSettings().deserialize(data['sso_settings']);
        }
        if (data['user']) {
            this.user = new User().deserialize(data['user']);
        }
        if (this.publicParams['http_request']) {
            this.httpRequest = new LogHttpRequest().deserialize(this.publicParams['http_request']);
        }
        this.publicParamsUnused = pickBy(this.publicParams, function (v, k) { return !['http_request'].includes(k); });
        return this;
    };
    Object.defineProperty(Log.prototype, "traceShort", {
        get: function () {
            if (!this.trace) {
                return;
            }
            return this.trace.slice(0, 4);
        },
        enumerable: true,
        configurable: true
    });
    return Log;
}());
export { Log };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsLastMonthFieldLookup = /** @class */ (function (_super) {
    __extends(IsLastMonthFieldLookup, _super);
    function IsLastMonthFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_last_month';
        _this.param = 'isLastMonth';
        _this.verboseName = localize('is Last Month');
        _this.icon = 'calendar';
        return _this;
    }
    IsLastMonthFieldLookup.prototype.from = function (value) {
        return moment().subtract(1, 'months').toISOString();
    };
    IsLastMonthFieldLookup.prototype.to = function (value) {
        return moment().toISOString();
    };
    IsLastMonthFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not last month', [field]);
            }
            else {
                return localize('is not last month');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is last month', [field]);
            }
            else {
                return localize('last month');
            }
        }
    };
    return IsLastMonthFieldLookup;
}(BetweenFieldLookup));
export { IsLastMonthFieldLookup };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { catchError, delayWhen, share } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { FieldType, ParameterValue, ParameterValueArray } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, jetAppResource, jetAppStorage, ProjectGroupService, socialBackends } from '@modules/projects';
import { createSSOSettings, CustomSSOSettings, JetBridgeSSOSettings, OAuth2SSOSettings, SAML2SSOSettings, SharedCustomSSOType, SSOSettingsService, SSOType } from '@modules/sso';
import { Workflow } from '@modules/workflow';
import { isSet } from '@shared';
var OAuth2SSOForm = /** @class */ (function (_super) {
    __extends(OAuth2SSOForm, _super);
    function OAuth2SSOForm() {
        return _super.call(this, {
            oauth_params: new FormControl({}, Validators.required),
            user_details_workflow: new FormControl(undefined)
        }) || this;
    }
    return OAuth2SSOForm;
}(FormGroup));
export { OAuth2SSOForm };
var SAML2SSOForm = /** @class */ (function (_super) {
    __extends(SAML2SSOForm, _super);
    function SAML2SSOForm() {
        return _super.call(this, {
            metadata: new FormControl('', Validators.required),
            entity_id: new FormControl('', Validators.required)
        }) || this;
    }
    return SAML2SSOForm;
}(FormGroup));
export { SAML2SSOForm };
var CustomSSOForm = /** @class */ (function (_super) {
    __extends(CustomSSOForm, _super);
    function CustomSSOForm() {
        return _super.call(this, {
            shared_custom_sso: new FormControl(undefined),
            parameters: new ParameterValueArray([]),
            auth_workflow: new FormControl(undefined, Validators.required),
            refresh_token_workflow: new FormControl(undefined)
        }) || this;
    }
    return CustomSSOForm;
}(FormGroup));
export { CustomSSOForm };
var JetBridgeSSOForm = /** @class */ (function (_super) {
    __extends(JetBridgeSSOForm, _super);
    function JetBridgeSSOForm() {
        return _super.call(this, {
            url: new FormControl({}, Validators.required)
        }) || this;
    }
    return JetBridgeSSOForm;
}(FormGroup));
export { JetBridgeSSOForm };
var ProjectSSOItemForm = /** @class */ (function (_super) {
    __extends(ProjectSSOItemForm, _super);
    function ProjectSSOItemForm(formUtils, currentProjectStore, currentEnvironmentStore, ssoSettingsService, projectGroupService) {
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            image: new FormControl(''),
            type: new FormControl('', Validators.required),
            default_group: new FormControl(null, Validators.required),
            oauth2: new OAuth2SSOForm(),
            custom: new CustomSSOForm(),
            saml2: new SAML2SSOForm(),
            jet_bridge: new JetBridgeSSOForm()
        }) || this;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.ssoSettingsService = ssoSettingsService;
        _this.projectGroupService = projectGroupService;
        _this.typeOptions = [
            { value: SSOType.SAML2, name: 'SAML 2.0' },
            { value: SSOType.OAuth2, name: 'OAuth 2.0' },
            { value: SSOType.Custom, name: 'Custom authentication' },
            { value: SSOType.JetBridge, name: 'Jet Bridge' }
        ];
        _this.storageResource = jetAppResource;
        _this.storage = jetAppStorage;
        return _this;
    }
    ProjectSSOItemForm.prototype.ngOnDestroy = function () { };
    ProjectSSOItemForm.prototype.init = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.instance = options.instance;
        if (options.instance) {
            this.controls.name.patchValue(options.instance.name);
            this.controls.image.patchValue(options.instance.image);
            this.controls.type.patchValue(options.instance.type);
            var project = this.currentProjectStore.instance;
            var environment = this.currentProjectStore.instance.defaultEnvironment;
            this.projectGroupService
                .get(project.uniqueName, environment ? environment.uniqueName : undefined)
                .pipe(untilDestroyed(this))
                .subscribe(function (groups) {
                var group = groups ? groups.find(function (item) { return item.uid == options.instance.defaultGroup; }) : null;
                _this.controls.default_group.patchValue(group);
            });
            if (options.instance.type == SSOType.SAML2 && options.instance instanceof SAML2SSOSettings) {
                this.controls.saml2.controls.metadata.patchValue(options.instance.metadata);
                this.controls.saml2.controls.entity_id.patchValue(options.instance.entityId);
            }
            else if (options.instance.type == SSOType.OAuth2 && options.instance instanceof OAuth2SSOSettings) {
                // TODO: Workaround for circular import
                options.instance.userDetailsWorkflow = options.instance.userDetailsWorkflowData
                    ? new Workflow().deserialize(options.instance.userDetailsWorkflowData)
                    : undefined;
                this.controls.oauth2.controls.oauth_params.patchValue(__assign({}, options.instance.publicParams, options.instance.params));
                this.controls.oauth2.controls.user_details_workflow.patchValue(options.instance.userDetailsWorkflow);
            }
            else if (options.instance.type == SSOType.Custom && options.instance instanceof CustomSSOSettings) {
                this.controls.custom.controls.shared_custom_sso.patchValue(options.instance.sharedCustomSSO);
                if (options.instance.sharedCustomSSO == SharedCustomSSOType.Firebase) {
                    var forceParameters = [
                        {
                            name: 'Web API Key',
                            field: FieldType.Text,
                            required: true
                        }
                    ];
                    var existingParameters_1 = options.instance.parameters;
                    var parameters = forceParameters.map(function (forceParameter) {
                        var existing = existingParameters_1.find(function (item) { return item.name == forceParameter.name; });
                        if (existing) {
                            return existing;
                        }
                        else {
                            return new ParameterValue(forceParameter);
                        }
                    });
                    this.controls.custom.controls.parameters.patchValue(parameters);
                }
                else if (options.instance.sharedCustomSSO == SharedCustomSSOType.Supabase) {
                    var forceParameters = [
                        {
                            name: 'Reference ID',
                            field: FieldType.Text,
                            required: true
                        },
                        {
                            name: 'API key',
                            field: FieldType.Text,
                            required: true
                        }
                    ];
                    var existingParameters_2 = options.instance.parameters;
                    var parameters = forceParameters.map(function (forceParameter) {
                        var existing = existingParameters_2.find(function (item) { return item.name == forceParameter.name; });
                        if (existing) {
                            return existing;
                        }
                        else {
                            return new ParameterValue(forceParameter);
                        }
                    });
                    this.controls.custom.controls.parameters.patchValue(parameters);
                }
                else {
                    this.controls.custom.controls.parameters.patchValue(options.instance.parameters);
                }
                // TODO: Workaround for circular import
                options.instance.authWorkflow = options.instance.authWorkflowData
                    ? new Workflow().deserialize(options.instance.authWorkflowData)
                    : undefined;
                options.instance.refreshTokenWorkflow = options.instance.refreshTokenWorkflowData
                    ? new Workflow().deserialize(options.instance.refreshTokenWorkflowData)
                    : undefined;
                this.controls.custom.controls.auth_workflow.patchValue(options.instance.authWorkflow);
                this.controls.custom.controls.refresh_token_workflow.patchValue(options.instance.refreshTokenWorkflow);
            }
            else if (options.instance.type == SSOType.JetBridge && options.instance instanceof JetBridgeSSOSettings) {
                this.controls.jet_bridge.controls.url.patchValue(options.instance.url);
            }
        }
        else if (options.initialValue) {
            if (isSet(options.initialValue.name)) {
                this.controls.name.patchValue(options.initialValue.name);
            }
            if (isSet(options.initialValue.type)) {
                this.controls.type.patchValue(options.initialValue.type);
            }
            if (options.initialValue.type == SSOType.OAuth2 && isSet(options.initialValue.params['backend'])) {
                this.controls.oauth2.controls.oauth_params.patchValue({
                    backend: options.initialValue.params['backend']
                });
            }
        }
        this.markAsPristine();
    };
    ProjectSSOItemForm.prototype.isValid = function () {
        return ([this.controls.name, this.controls.type, this.controls.default_group].every(function (item) { return item.valid; }) &&
            (this.controls.type.value != SSOType.SAML2 || this.controls.saml2.valid) &&
            (this.controls.type.value != SSOType.OAuth2 || this.controls.oauth2.valid) &&
            (this.controls.type.value != SSOType.JetBridge || this.controls.jet_bridge.valid));
    };
    ProjectSSOItemForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var instance = this.instance ? cloneDeep(this.instance) : createSSOSettings(this.controls.type.value);
        var project = this.currentProjectStore.instance;
        var fields = ['domain', 'name', 'type', 'default_group', 'public_params'];
        instance.domain = project && isSet(project.domain) ? project.domain.domain : undefined;
        instance.name = this.controls.name.value;
        instance.image = this.controls.image.value;
        instance.type = this.controls.type.value;
        instance.defaultGroup = this.controls.default_group.value ? this.controls.default_group.value.uid : null;
        instance.publicParams = {};
        if (instance.type == SSOType.OAuth2) {
            instance.params = {};
            fields.push('params');
        }
        else if (instance.type == SSOType.JetBridge) {
            instance.params = {};
            fields.push('params');
        }
        if (this.instance) {
            instance.uid = this.instance.uid;
            if (instance.type == SSOType.SAML2 && instance instanceof SAML2SSOSettings) {
                instance.entityId = this.controls.saml2.controls.entity_id.value;
                fields.push('entity_id');
                if (this.controls.saml2.controls.metadata.value instanceof File) {
                    instance.metadataFile = this.controls.saml2.controls.metadata.value;
                    fields.push('metadata');
                }
            }
            else if (instance.type == SSOType.OAuth2 && instance instanceof OAuth2SSOSettings) {
                var params_1 = this.controls.oauth2.controls.oauth_params.value;
                var publicParams_1 = ['backend'];
                if (params_1['backend']) {
                    var backend = socialBackends.find(function (item) { return item.name == params_1['backend']; });
                    params_1['backend_path'] = backend ? backend.path : undefined;
                }
                instance.workflowsProject = this.currentProjectStore.instance.uniqueName;
                instance.workflowsEnvironment = this.currentEnvironmentStore.instance.uniqueName;
                instance.userDetailsWorkflow = this.controls.oauth2.controls.user_details_workflow.value;
                instance.publicParams = __assign({}, instance.publicParams, pickBy(params_1, function (v, k) { return publicParams_1.includes(k); }));
                instance.params = __assign({}, instance.params, pickBy(params_1, function (v, k) { return !publicParams_1.includes(k); }));
                fields.push('workflows_project');
                fields.push('workflows_environment');
                fields.push('user_details_workflow');
            }
            else if (instance.type == SSOType.Custom && instance instanceof CustomSSOSettings) {
                instance.workflowsProject = this.currentProjectStore.instance.uniqueName;
                instance.workflowsEnvironment = this.currentEnvironmentStore.instance.uniqueName;
                instance.sharedCustomSSO = this.controls.custom.controls.shared_custom_sso.value;
                instance.parameters = this.controls.custom.controls.parameters.value;
                instance.authWorkflow = this.controls.custom.controls.auth_workflow.value;
                instance.refreshTokenWorkflow = this.controls.custom.controls.refresh_token_workflow.value;
                fields.push('workflows_project');
                fields.push('workflows_environment');
                fields.push('shared_custom_sso');
                fields.push('parameters');
                fields.push('auth_workflow');
                fields.push('refresh_token_workflow');
            }
            else if (instance.type == SSOType.JetBridge && instance instanceof JetBridgeSSOSettings) {
                instance.url = this.controls.jet_bridge.controls.url.value;
                fields.push('url');
            }
        }
        var obs;
        if (this.instance) {
            obs = this.ssoSettingsService.update(this.currentProjectStore.instance.uniqueName, instance, fields);
        }
        else {
            obs = this.ssoSettingsService.create(this.currentProjectStore.instance.uniqueName, instance, fields);
        }
        return obs.pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }), share());
    };
    return ProjectSSOItemForm;
}(FormGroup));
export { ProjectSSOItemForm };

<div *ngIf="showTabsAlways || tabs.length > 1" class="sidebar-tabs2-wrapper">
  <div class="sidebar-tabs2">
    <div
      *ngFor="let item of tabs"
      class="sidebar-tabs2__item"
      [class.sidebar-tabs2__item_active]="isActive(item)"
      [class.sidebar-tabs2__item_disabled]="item.disabled$ | async"
      [appTip]="!isActive(item) ? (item.error$ | async) : undefined"
      [appTipOptions]="{ side: 'top' }"
      (click)="setVisibleTab(item)"
    >
      {{ item.label$ | async }}

      <span
        *ngIf="(item.error$ | async) && !isActive(item)"
        class="sidebar-tabs2__item-error icon-warning_filled"
      ></span>
    </div>
  </div>
</div>

<ng-content></ng-content>

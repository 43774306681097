import { Injectable } from '@angular/core';
import toPairs from 'lodash/toPairs';

import { ActionDescription } from '@modules/actions';
import { CurrentEnvironmentStore, ProjectPermission, ProjectPermissionType } from '@modules/projects';
import {
  ActionQuery,
  ChartWidgetQuery,
  ModelDescriptionQuery,
  Query,
  QueryType,
  ValueWidgetQuery
} from '@modules/queries';

import { CustomViewSettings } from '../../data/view-settings/custom';
import { traverseElementItemQueries } from '../../utils/traverse';

@Injectable()
export class PagePermissionsService {
  constructor(private currentEnvironmentStore: CurrentEnvironmentStore) {}

  getModelPermissionsFromPagePermissions(
    pagePermissions: ProjectPermission[],
    existingModelPermissions: ProjectPermission[],
    viewSettings: CustomViewSettings[],
    actions: ActionDescription[]
  ): ProjectPermission[] {
    const modelPermissions: { [modelId: string]: { read?: boolean; write?: boolean; delete?: boolean } } = {};
    const addModelPermission = (modelId: string, add: { read?: boolean; write?: boolean; delete?: boolean }) => {
      modelPermissions[modelId] = {
        ...modelPermissions[modelId],
        ...add
      };
    };

    pagePermissions.forEach(permission => {
      const page = viewSettings.find(item => item.uid == permission.permissionObject);

      if (!page) {
        return;
      }

      traverseElementItemQueries(page, actions, (resourceName: string, query: Query) => {
        const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);

        if (!resource || resource.demo) {
          return;
        }

        if (
          (query instanceof ModelDescriptionQuery ||
            query instanceof ValueWidgetQuery ||
            query instanceof ChartWidgetQuery) &&
          query.queryType == QueryType.Simple &&
          query.simpleQuery
        ) {
          const modelId = [resourceName, query.simpleQuery.model].join('.');

          if (permission.permissionActions.includes('r')) {
            addModelPermission(modelId, { read: true });
          }
        } else if (query instanceof ActionQuery && query.queryType == QueryType.Simple && query.simpleQuery) {
          const action = actions.find(item => item.resource == resourceName && item.name == query.simpleQuery.name);

          if (action && action.model) {
            const modelId = [resourceName, action.model].join('.');

            if (
              permission.permissionActions.includes('r') &&
              (action.modelAction == 'get' || action.modelAction == 'get_detail')
            ) {
              addModelPermission(modelId, { read: true });
            } else if (
              permission.permissionActions.includes('w') &&
              (action.modelAction == 'create' || action.modelAction == 'update')
            ) {
              addModelPermission(modelId, { write: true });
            } else if (permission.permissionActions.includes('d') && action.modelAction == 'delete') {
              addModelPermission(modelId, { delete: true });
            }
          }
        }
      });
    });

    return toPairs(modelPermissions).map(([modelId, permissionActions]) => {
      const modelPermission = new ProjectPermission();
      const existingPermission = existingModelPermissions.find(item => item.permissionObject == modelId);

      if (existingPermission && existingPermission.id) {
        modelPermission.id = existingPermission.id;
      }

      modelPermission.permissionType = ProjectPermissionType.Model;
      modelPermission.permissionObject = modelId;
      modelPermission.permissionActions = [
        permissionActions.read ? 'r' : '',
        permissionActions.write ? 'w' : '',
        permissionActions.delete ? 'd' : ''
      ].join('');

      return modelPermission;
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { CollapseIndicatorPosition } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { MarginControl } from '../margin-control/margin-control.component';
import { AccordionItemArray } from './accordion-item.array';
var CustomizeBarAccordionEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarAccordionEditForm, _super);
    function CustomizeBarAccordionEditForm() {
        var _this = _super.call(this, {
            title: new FormControl(''),
            items: new AccordionItemArray([]),
            multiple_opened: new FormControl(false),
            collapse_indicator_position: new FormControl(CollapseIndicatorPosition.Right),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl()
        }) || this;
        _this.collapseIndicatorPositionOptions = [
            {
                value: CollapseIndicatorPosition.None,
                name: 'None'
            },
            {
                value: CollapseIndicatorPosition.Left,
                name: 'Left'
            },
            {
                value: CollapseIndicatorPosition.Right,
                name: 'Right'
            }
        ];
        return _this;
    }
    CustomizeBarAccordionEditForm.prototype.init = function (element) {
        this.element = element;
        this.controls.title.patchValue(element.name ? element.name : 'Tabs');
        this.controls.items.deserialize(element.items);
        this.controls.multiple_opened.patchValue(element.multipleOpened);
        this.controls.collapse_indicator_position.patchValue(element.collapseIndicatorPosition || CollapseIndicatorPosition.Right);
        this.controls.margin.patchValue(element.margin);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
        this.markAsPristine();
    };
    CustomizeBarAccordionEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.title.value;
        instance.items = this.controls.items.serialize();
        instance.multipleOpened = this.controls.multiple_opened.value;
        instance.collapseIndicatorPosition = this.controls.collapse_indicator_position.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.margin = this.controls.margin.value;
        return instance;
    };
    return CustomizeBarAccordionEditForm;
}(FormGroup));
export { CustomizeBarAccordionEditForm };

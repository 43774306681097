var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { TweenMax } from 'gsap';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { addClass, isDescendant, isSet, MouseButton, removeClass } from '@shared';
import { DragAxis, DropListOrientation } from '../../data/types';
import { DropListService } from '../../services/drop-list/drop-list.service';
import { ScrollService } from '../../services/scroll/scroll.service';
import { coerceArray, moveInArray } from '../../utils/common';
import { applyElementPreviewStyles, createElementPreview, createOutsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS, DRAG_OUTSIDE_INDICATOR_TOP_CLASS, getElementDepth, getElementViewport, isPointInside, moveElement, removeElement, replaceElement } from '../../utils/document';
import { AppDrag } from '../drag/drag.directive';
import { AppDropListGroup } from '../drop-list-group/drop-list-group.directive';
var DRAG_PREVIEW_CLASS = 'app-drag-preview';
var DRAG_PLACEHOLDER_CLASS = 'app-drag-placeholder';
var DRAG_DROP_DRAGGING_CLASS = 'app-drag-drop-dragging';
var AppDropList = /** @class */ (function () {
    function AppDropList(el, group, changeDetectorRef, dropListService, scrollService) {
        this.el = el;
        this.group = group;
        this.changeDetectorRef = changeDetectorRef;
        this.dropListService = dropListService;
        this.scrollService = scrollService;
        this.connectedTo = [];
        this.connectedToGroup = [];
        this.ignoreParentGroup = false;
        this.orientation = DropListOrientation.Vertical;
        this.swapDistance = 0;
        this.sortingDisabled = false;
        this.cloneItems = false;
        this.areaMargin = [0, 0, 0, 0];
        this.outsideIndicator = false;
        this.disabled = false;
        this.destroy = false;
        this.prevent = false;
        this.priority = 0;
        this.params = {};
        this.dragStarted = new EventEmitter();
        this.dropped = new EventEmitter();
        this.entered = new EventEmitter();
        this.exited = new EventEmitter();
        this.sorted = new EventEmitter();
        this.dragged = new EventEmitter();
        this.draggables = new QueryList();
        this.disabled$ = new BehaviorSubject(this.disabled);
        this._draggingState = new BehaviorSubject(undefined);
        this.siblings = [];
        this.currentDrags = [];
        this.subscriptions = [];
    }
    AppDropList.prototype.ngOnInit = function () {
        var _this = this;
        this.dropListService.registerDropList(this);
        if (this.group && !this.ignoreParentGroup) {
            this.registerForGroup(this.group);
        }
        coerceArray(this.connectedToGroup)
            .filter(function (item) { return item; })
            .forEach(function (item) {
            _this.registerForGroup(item);
        });
        this.disabled$.pipe(untilDestroyed(this)).subscribe(function (disabled) {
            if (disabled) {
                _this.deinitDropList();
            }
            else {
                _this.initDropList();
            }
        });
    };
    AppDropList.prototype.ngOnDestroy = function () {
        var _this = this;
        this.currentDrags.forEach(function (item) { return item.clearDropList(_this); });
        this.dropListService.unregisterDropList(this);
        if (this.group && !this.ignoreParentGroup) {
            this.unregisterFromGroup(this.group);
        }
        coerceArray(this.connectedToGroup)
            .filter(function (item) { return item; })
            .forEach(function (item) {
            _this.unregisterFromGroup(item);
        });
        this.scrollService.stopScroll();
    };
    AppDropList.prototype.ngOnChanges = function (changes) {
        if (changes['connectedTo']) {
            this.updateSiblings();
        }
        if (changes['viewport']) {
            this.updateViewport();
        }
        if (changes['disabled']) {
            this.disabled$.next(this.disabled);
        }
    };
    AppDropList.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.initDrags();
        this.draggables.changes.pipe(untilDestroyed(this)).subscribe(function (e) {
            _this.initDrags();
        });
    };
    Object.defineProperty(AppDropList.prototype, "draggingState", {
        get: function () {
            return this._draggingState.value;
        },
        set: function (value) {
            this._draggingState.next(value);
        },
        enumerable: true,
        configurable: true
    });
    AppDropList.prototype.getDraggingState = function () {
        return this._draggingState.value;
    };
    AppDropList.prototype.getDraggingState$ = function () {
        return this._draggingState.asObservable();
    };
    AppDropList.prototype.isOrientationHorizontal = function () {
        return this.orientation == DropListOrientation.Horizontal;
    };
    AppDropList.prototype.registerForGroup = function (group) {
        var _this = this;
        group.registerDropList(this);
        group.dropListsUpdated$.pipe(untilDestroyed(this)).subscribe(function () {
            _this.updateSiblings();
        });
        this.updateSiblings();
    };
    AppDropList.prototype.unregisterFromGroup = function (group) {
        group.unregisterDropList(this);
    };
    AppDropList.prototype.updateViewport = function () {
        if (this.overrideViewport) {
            this.viewport = this.overrideViewport;
        }
        else {
            this.viewport = getElementViewport(this.el.nativeElement);
        }
    };
    AppDropList.prototype.getViewport = function () {
        if (!this.viewport) {
            this.updateViewport();
        }
        return this.viewport;
    };
    AppDropList.prototype.initDropList = function () {
        var _this = this;
        this.deinitDropList();
        this.subscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(filter(function () { return !!_this.draggingState; }), startWith(undefined), pairwise(), untilDestroyed(this))
            .subscribe(function (_a) {
            var prevEvent = _a[0], event = _a[1];
            var previousPosition = prevEvent
                ? {
                    x: prevEvent.x,
                    y: prevEvent.y
                }
                : _this.draggingState.startPosition;
            var previousPositionValue = _this.isOrientationHorizontal() ? previousPosition.x : previousPosition.y;
            var positionValue = _this.isOrientationHorizontal() ? event.x : event.y;
            var moveDirection;
            if (positionValue > previousPositionValue) {
                moveDirection = true;
            }
            else if (positionValue < previousPositionValue) {
                moveDirection = false;
            }
            event.preventDefault();
            _this.dragMove(event, moveDirection);
            var viewport = _this.getViewport();
            _this.scrollService.scrollNearBounds(viewport, event);
        }));
        this.subscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), filter(function () { return !!_this.draggingState; }), untilDestroyed(this))
            .subscribe(function (e) { return _this.dragFinish(e); }));
        this.subscriptions.push(fromEvent(window, 'scroll')
            .pipe(filter(function () { return !!_this.draggingState; }), untilDestroyed(this))
            .subscribe(function () {
            _this.updateIndicator();
        }));
        // this.updateBounds();
    };
    AppDropList.prototype.deinitDropList = function () {
        if (!this.subscriptions.length) {
            return;
        }
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.subscriptions = [];
    };
    AppDropList.prototype.initDrags = function () {
        var _this = this;
        var draggables = this.draggables.toArray();
        draggables.forEach(function (item) { return item.setDropList(_this); });
        this.currentDrags = draggables;
    };
    AppDropList.prototype.dragStart = function (e) {
        this.dropListService.dragging$.next(true);
        this.dropListService.draggingDropList$.next(this);
        this.dropListService.draggingDragStart$.next(e);
        var position = { x: e.event.clientX, y: e.event.clientY };
        var customPreview = e.source.getCustomPreview();
        var preview = customPreview
            ? applyElementPreviewStyles(customPreview)
            : createElementPreview(e.source.getElement(), this.previewSizeMatchSelector);
        var dragBounds = e.source.getElement().getBoundingClientRect();
        if (!this.cloneItems) {
            e.source.initPlaceholder();
        }
        addClass(preview, DRAG_PREVIEW_CLASS);
        addClass(e.source.getElement(), DRAG_PLACEHOLDER_CLASS);
        addClass(document.body, DRAG_DROP_DRAGGING_CLASS);
        this.draggingState = {
            drag: e.source,
            fromIndex: this.currentDrags.indexOf(e.source),
            fromDropList: this,
            startPosition: position,
            startDelta: {
                x: e.event.clientX - dragBounds.left,
                y: e.event.clientY - dragBounds.top
            },
            moveDirection: true,
            lastSwapPosition: position,
            preview: preview,
            outsideIndicator: this.createIndicator()
        };
        document.body.appendChild(preview);
        this.updateDragMirror(position, true);
        this.dragStarted.emit({
            currentIndex: this.draggingState.fromIndex,
            item: this.draggingState.drag
        });
    };
    AppDropList.prototype.dragMove = function (e, moveDirection) {
        var _this = this;
        var position = { x: e.clientX, y: e.clientY };
        var swapLast = this.draggingState.lastSwapPosition;
        var swapDirection = this.isOrientationHorizontal() ? position.x >= swapLast.x : position.y >= swapLast.y;
        if (moveDirection !== undefined) {
            this.draggingState = __assign({}, this.draggingState, { moveDirection: moveDirection });
        }
        var positionDropList = this.siblings
            .filter(function (item) { return !item.disabled; })
            .filter(function (item) { return !isDescendant(_this.draggingState.drag.getElement(), item.el.nativeElement); })
            .filter(function (item) { return !item.enterPredicate || item.enterPredicate(_this.draggingState.drag, _this); })
            .sort(function (lhs, rhs) {
            return (-1 * (getElementDepth(lhs.el.nativeElement) - getElementDepth(rhs.el.nativeElement)) +
                -1 * (lhs.priority - rhs.priority));
        })
            .find(function (item) { return item.isPointInsideBounds(position, swapDirection); });
        var targetDropList = positionDropList || this;
        this.updateDragMirror(position);
        if (targetDropList.prevent) {
            return;
        }
        var currentIndex = this.currentDrags.indexOf(this.draggingState.drag);
        var index = this.getIndexForPosition(targetDropList, position);
        // Disable sorting in current DropList with disabled sorting
        if (targetDropList === this && this.sortingDisabled) {
            return;
        }
        // Restore original position if transferring to initial DropList with disabled sorting
        if (targetDropList !== this &&
            targetDropList.sortingDisabled &&
            this.draggingState.fromDropList === targetDropList) {
            index = this.draggingState.fromIndex;
        }
        if (targetDropList !== this || currentIndex !== index) {
            if (targetDropList.movePredicate && !targetDropList.movePredicate(this.draggingState.drag, this)) {
                return;
            }
            this.draggingState = __assign({}, this.draggingState, { lastSwapPosition: position });
            this.moveToDropList(targetDropList, index);
        }
    };
    AppDropList.prototype.moveToDropList = function (dropList, index) {
        if (dropList === this) {
            var previousIndex = this.currentDrags.indexOf(this.draggingState.drag);
            this.reorderCurrentDragWithIndex(index);
            this.updateIndicator();
            this.sorted.emit({
                previousIndex: previousIndex,
                currentIndex: index,
                container: this,
                item: this.draggingState.drag
            });
        }
        else {
            dropList.receiveDragging(this);
            dropList.reorderCurrentDragWithIndex(index);
            this.updateIndicator();
            dropList.entered.emit({
                container: dropList,
                item: dropList.draggingState.drag,
                currentIndex: index
            });
        }
    };
    AppDropList.prototype.dragFinish = function (e) {
        var draggingState = this.draggingState;
        var currentIndex = this.currentDrags.indexOf(draggingState.drag);
        var position = { x: e.clientX, y: e.clientY };
        this.moveToDropList(draggingState.fromDropList, draggingState.fromIndex);
        draggingState.fromDropList.cleanUpDrag();
        this.scrollService.stopScroll();
        var dragDropEvent = {
            previousIndex: draggingState.fromIndex,
            currentIndex: currentIndex,
            item: draggingState.drag,
            container: this,
            previousContainer: draggingState.fromDropList,
            isPointerOverContainer: this.isPointInsideBounds(position),
            data: draggingState.data
        };
        this.dropped.emit(dragDropEvent);
        this.dropListService.dropped$.next(dragDropEvent);
        if (draggingState.fromDropList !== this) {
            draggingState.fromDropList.dragged.emit(dragDropEvent);
        }
        this.changeDetectorRef.markForCheck();
        draggingState.fromDropList.changeDetectorRef.markForCheck();
        this.dropListService.dragging$.next(false);
        this.dropListService.draggingDropList$.next(undefined);
        this.dropListService.draggingDragStart$.next(undefined);
    };
    AppDropList.prototype.receiveDragging = function (fromDropList) {
        var drag = fromDropList.draggingState.drag;
        var state = fromDropList.removeDragging();
        fromDropList.exited.emit({
            container: fromDropList,
            item: drag
        });
        this.addDragging(state);
        this.dropListService.draggingDropList$.next(this);
    };
    AppDropList.prototype.addDragging = function (state) {
        var el = state.drag.getElement();
        this.draggingState = state;
        if (this.destroy) {
            removeElement(el);
        }
        else {
            this.el.nativeElement.appendChild(el);
        }
        this.currentDrags = this.currentDrags.concat([state.drag]);
        // this.updateBounds();
        if (this.draggingState.drag.isPlaceholderMissing()) {
            this.draggingState.drag.initPlaceholder();
        }
        // Remove clone if transferring back to source DropList with cloneItems
        if (this.draggingState.fromDropList === this && this.cloneItems) {
            if (this.draggingState.sourceDragClone) {
                removeElement(this.draggingState.sourceDragClone);
            }
            this.draggingState = __assign({}, this.draggingState, { sourceDragClone: undefined });
        }
    };
    AppDropList.prototype.removeDragging = function () {
        var _this = this;
        var el = this.draggingState.drag.getElement();
        // Clone item in source DropList with cloneItems
        if (this.draggingState.fromDropList === this && this.cloneItems) {
            var sourceDragClone = el.cloneNode(true);
            removeClass(sourceDragClone, DRAG_PLACEHOLDER_CLASS);
            replaceElement(el, sourceDragClone);
            this.draggingState = __assign({}, this.draggingState, { sourceDragClone: sourceDragClone });
        }
        else {
            removeElement(el);
        }
        this.currentDrags = this.currentDrags.filter(function (item) { return item !== _this.draggingState.drag; });
        var state = this.draggingState;
        this.draggingState = undefined;
        // this.updateBounds();
        return state;
    };
    AppDropList.prototype.updateSiblings = function () {
        var _this = this;
        var connectedLists = coerceArray(this.connectedTo)
            .filter(function (item) { return item; })
            .map(function (selector) {
            if (selector instanceof AppDropList) {
                return selector;
            }
            else if (typeof selector == 'string') {
                var dropList = _this.dropListService.dropLists.find(function (item) { return item.el.nativeElement.id == selector; });
                if (!dropList && (typeof ngDevMode === 'undefined' || ngDevMode)) {
                    console.warn("AppDropList could not find connected drop list with id \"" + selector + "\"");
                }
                return dropList;
            }
        })
            .filter(function (item) { return isSet(item); });
        this.siblings = uniq([
            this
        ].concat(connectedLists, (this.group && !this.group.disabled && !this.ignoreParentGroup ? this.group.dropLists : []), coerceArray(this.connectedToGroup)
            .filter(function (item) { return item && !item.disabled; })
            .reduce(function (acc, item) {
            acc.push.apply(acc, item.dropLists);
            return acc;
        }, [])));
    };
    AppDropList.prototype.updateBounds = function () {
        this.bounds = this.el.nativeElement.getBoundingClientRect();
    };
    AppDropList.prototype.getIndexForPosition = function (dropList, position) {
        var _this = this;
        var currentDragItem = {
            drag: this.draggingState.drag,
            position: this.isOrientationHorizontal() ? position.x : position.y
        };
        var dropListDragItems = dropList.currentDrags
            .filter(function (item) { return item !== _this.draggingState.drag; })
            .map(function (item) {
            var bounds = item.getBounds();
            var itemPosition;
            if (_this.draggingState.moveDirection) {
                itemPosition = _this.isOrientationHorizontal()
                    ? bounds.left + _this.swapDistance
                    : bounds.top + _this.swapDistance;
            }
            else {
                itemPosition = _this.isOrientationHorizontal()
                    ? bounds.right - _this.swapDistance
                    : bounds.bottom - _this.swapDistance;
            }
            return {
                drag: item,
                position: itemPosition
            };
        });
        return dropListDragItems.concat([currentDragItem]).sort(function (lhs, rhs) { return lhs.position - rhs.position; })
            .findIndex(function (item) { return item.drag === _this.draggingState.drag; });
    };
    AppDropList.prototype.reorderCurrentDragWithIndex = function (index) {
        moveElement(this.draggingState.drag.getElement(), index);
        this.currentDrags = moveInArray(this.currentDrags, this.draggingState.drag, index);
    };
    AppDropList.prototype.isPointInsideBounds = function (point, swapDirection) {
        // if (!this.bounds) {
        this.updateBounds();
        // }
        var margin;
        if (swapDirection === true) {
            margin = this.areaMarginForward || this.areaMargin;
        }
        else if (swapDirection === false) {
            margin = this.areaMarginBackward || this.areaMargin;
        }
        else {
            margin = this.areaMargin;
        }
        return isPointInside(point, this.bounds, margin);
    };
    AppDropList.prototype.updateDragMirror = function (position, initial) {
        if (initial === void 0) { initial = false; }
        var positionX = this.lockAxis && this.lockAxis == DragAxis.Y ? this.draggingState.startPosition.x : position.x;
        var positionY = this.lockAxis && this.lockAxis == DragAxis.X ? this.draggingState.startPosition.y : position.y;
        var previewPositionY = positionY - this.draggingState.startDelta.y;
        var previewPositionX = positionX - this.draggingState.startDelta.x;
        TweenMax.set(this.draggingState.preview, {
            x: previewPositionX,
            y: previewPositionY
        });
        if (this.previewMissPointerStartDelta && initial) {
            var bounds = this.draggingState.preview.getBoundingClientRect();
            if (position.x > bounds.right || position.x < bounds.left) {
                this.draggingState = __assign({}, this.draggingState, { startDelta: this.previewMissPointerStartDelta });
            }
            if (position.y > bounds.bottom || position.y < bounds.top) {
                this.draggingState = __assign({}, this.draggingState, { startDelta: this.previewMissPointerStartDelta });
            }
            this.updateDragMirror(position);
        }
    };
    AppDropList.prototype.mergeDraggingStateData = function (data) {
        var state = this.draggingState;
        if (!state) {
            return;
        }
        this.draggingState = __assign({}, state, { data: __assign({}, (state.data || {}), data) });
    };
    AppDropList.prototype.createIndicator = function () {
        if (!this.outsideIndicator) {
            return;
        }
        var element = createOutsideIndicator();
        document.body.appendChild(element);
        return element;
    };
    AppDropList.prototype.updateIndicator = function () {
        if (!this.draggingState || !this.draggingState.outsideIndicator) {
            return;
        }
        var bounds = this.draggingState.drag.getElement().getBoundingClientRect();
        if (bounds.bottom <= 50) {
            addClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
            removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
        }
        else if (bounds.top >= window.innerHeight) {
            removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
            addClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
        }
        else {
            removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
            removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
        }
    };
    AppDropList.prototype.cleanUpDrag = function () {
        this.draggingState.drag.destroyPlaceholder();
        this.draggingState.drag.destroyCustomPreview();
        removeElement(this.draggingState.preview);
        if (this.draggingState.outsideIndicator) {
            removeElement(this.draggingState.outsideIndicator);
        }
        removeClass(this.draggingState.drag.getElement(), DRAG_PLACEHOLDER_CLASS);
        removeClass(document.body, DRAG_DROP_DRAGGING_CLASS);
        // Clean clone item from source DropList
        if (this.draggingState.sourceDragClone) {
            removeElement(this.draggingState.sourceDragClone);
        }
        this.draggingState = undefined;
    };
    return AppDropList;
}());
export { AppDropList };

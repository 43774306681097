import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { hubspotResourceParamsMenuSettings } from '../../data/hubspot/hubspot-resource-params-menu-settings.stub';
import { hubspotResourceParamsModelDescriptions } from '../../data/hubspot/hubspot-resource-params-model-descriptions.stub';
import { hubspotResourceParamsParams } from '../../data/hubspot/hubspot-resource-params-params.stub';
import { hubspotResourceParamsViewSettings } from '../../data/hubspot/hubspot-resource-params-view-settings.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface HubspotParamsOptions {
  access_token: string;
  token_params: Object;
}

@Injectable()
export class HubspotGeneratorService extends ResourceGeneratorService<HubspotParamsOptions> {
  tokenName = 'oauth_access_token';

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: HubspotParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://api.hubapi.com/crm/v3/objects/tickets';
    query.headers = [{ name: 'Authorization', value: `Bearer ${options.access_token}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('API key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<HubspotParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        this.tokenName,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(secretToken => {
          return {
            access_token: secretToken.value,
            token_params: secretToken.params
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: HubspotParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = hubspotResourceParamsParams;
    const modelDescriptions = hubspotResourceParamsModelDescriptions;
    const viewSettings = hubspotResourceParamsViewSettings;
    const menuSettings = hubspotResourceParamsMenuSettings;
    const token = new SecretToken();

    token.resource = '{{resource}}';
    token.name = this.tokenName;
    token.type = SecretTokenType.OAuth;
    token.value = options.access_token;

    try {
      token.params = options.token_params;
    } catch (e) {
      token.params = {};
    }

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      viewSettings: viewSettings,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()]
    });
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppConfigService } from '@core';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
var MenuProfileComponent = /** @class */ (function () {
    function MenuProfileComponent(projectsStore, currentEnvironmentStore, currentUserStore, cd, intercomService, routing, themeService, customizeService, appConfigService, analyticsService) {
        this.projectsStore = projectsStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.currentUserStore = currentUserStore;
        this.cd = cd;
        this.intercomService = intercomService;
        this.routing = routing;
        this.themeService = themeService;
        this.customizeService = customizeService;
        this.appConfigService = appConfigService;
        this.analyticsService = analyticsService;
        this.themeChange = true;
        this.analyticsEvents = AnalyticsEvent;
    }
    MenuProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectsStore.lastUsed$.pipe(untilDestroyed(this)).subscribe(function (currentProject) {
            _this.currentProject = currentProject;
            _this.cd.markForCheck();
        });
    };
    MenuProfileComponent.prototype.ngOnDestroy = function () { };
    MenuProfileComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    MenuProfileComponent.prototype.logout = function () {
        this.routing.navigate(['/logout']);
    };
    MenuProfileComponent.prototype.toggleTheme = function () {
        if (!this.themeService) {
            return;
        }
        this.themeService.toggleTheme();
    };
    MenuProfileComponent.prototype.toggleStaffEnabled = function () {
        if (!this.themeService) {
            return;
        }
        this.themeService.toggleStaffEnabled();
    };
    MenuProfileComponent.prototype.onItemClicked = function (name, params) {
        if (params === void 0) { params = {}; }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.UserMenu.ItemClicked, __assign({ Name: name }, params, { Source: this.analyticsSource }));
    };
    return MenuProfileComponent;
}());
export { MenuProfileComponent };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { ModelDescription } from '@modules/models';
import { Environment, Project } from '@modules/projects';

import { deserializeUserActivity } from '../../data/deserialize-user-activity';
import { UserActivity } from '../../data/user-activity';
import { UserActivityGrouped } from '../../data/user-activity-grouped';
import { UserActivityType } from '../../data/user-activity-type';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private centrifugoService: CentrifugoService,
    private parameterCodec: CustomHttpParameterCodec
  ) {}

  get(projectName: string, environmentName: string, params = {}): Observable<UserActivityService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params, encoder: this.parameterCodec });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new UserActivityService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  group(projectName: string, environmentName: string, params = {}): Observable<UserActivityService.GroupResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/group/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new UserActivityService.GroupResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeUserActivities(
    projectName: string,
    environmentName: string,
    objectType: string,
    objectId: any
  ): Observable<UserActivity> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$user_activity', projectName, environmentName, objectType, objectId].join('/');

        return this.centrifugoService.subscribe(channel).pipe(
          filter(msg => msg.data['type'] == 'user_activity'),
          map(msg => deserializeUserActivity(msg.data['user_activity']))
        );
      })
    );
  }

  createInstance(
    projectName: string,
    environmentName: string,
    instance: UserActivity,
    mentions: string[] = []
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/');
        let headers = new HttpHeaders();
        const data = {
          ...instance.serialize(),
          mentions: mentions
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    projectName: string,
    environmentName: string,
    activityType: UserActivityType,
    objectType: string,
    objectId: string,
    viewSettings: string,
    params: Object
  ): Observable<boolean> {
    const instance = new UserActivity();

    instance.activityType = activityType;
    instance.objectType = objectType;
    instance.objectId = objectId;
    instance.viewSettings = viewSettings;
    instance.params = params;

    return this.createInstance(projectName, environmentName, instance);
  }

  currentProjectCreateModel(
    project: Project,
    environment: Environment,
    activity: UserActivityType,
    modelId: string,
    params: Object
  ) {
    const objectType = ['model', modelId].join('.');
    return this.create(project.uniqueName, environment.uniqueName, activity, objectType, undefined, undefined, params);
  }

  currentProjectCreateModelInstance(
    project: Project,
    environment: Environment,
    activity: UserActivityType,
    modelId: string,
    id: any,
    viewSettings: string,
    params: Object
  ) {
    const objectType = ['model', modelId].join('.');
    return this.create(project.uniqueName, environment.uniqueName, activity, objectType, id, viewSettings, params);
  }

  currentProjectCreateDashboardInstance(
    project: Project,
    environment: Environment,
    activity: UserActivityType,
    id: any,
    params: Object
  ) {
    return this.create(project.uniqueName, environment.uniqueName, activity, 'dashboard', id, undefined, params);
  }

  logModelCreate(
    project: Project,
    environment: Environment,
    modelDescription: ModelDescription,
    options: {
      id?: any;
      data?: Object;
      viewSettings?: string;
    } = {}
  ) {
    return this.currentProjectCreateModelInstance(
      project,
      environment,
      UserActivityType.ModelCreate,
      modelDescription.modelId,
      options.id,
      options.viewSettings,
      {
        resource: modelDescription.resource,
        model: modelDescription.model,
        id: options.id,
        instance: options.data
      }
    );
  }

  logModelUpdate(
    project: Project,
    environment: Environment,
    modelDescription: ModelDescription,
    id: any,
    options: {
      changes?: { key: string; from: any; to: any }[];
      viewSettings?: string;
    } = {}
  ) {
    return this.currentProjectCreateModelInstance(
      project,
      environment,
      UserActivityType.ModelUpdate,
      modelDescription.modelId,
      id,
      options.viewSettings,
      {
        resource: modelDescription.resource,
        model: modelDescription.model,
        id: id,
        changes: options.changes
      }
    );
  }

  logModelDelete(
    project: Project,
    environment: Environment,
    modelDescription: ModelDescription,
    options: {
      id?: any;
      ids?: any[];
      inverseIds?: boolean;
      data?: Object;
      viewSettings?: string;
    } = {}
  ) {
    return this.currentProjectCreateModelInstance(
      project,
      environment,
      UserActivityType.ModelDelete,
      modelDescription.modelId,
      options.id || '',
      options.viewSettings,
      {
        resource: modelDescription.resource,
        model: modelDescription.model,
        id: options.id,
        ids: options.ids,
        instance: options.data
      }
    );
  }
}

export namespace UserActivityService {
  export class GetResponse {
    public results: UserActivity[];
    public hasMore: boolean;
    public next: string;
    public previous: string;
    public count: number;
    public perPage = 20;

    deserialize(data: Object) {
      this.results = data['results'].map(item => deserializeUserActivity(item));
      this.hasMore = !!data['next'];
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }

    get numPages() {
      return Math.ceil(this.count / this.perPage);
    }
  }

  export class GroupResponse {
    public results: UserActivityGrouped[];
    public next: string;
    public previous: string;
    public count: number;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new UserActivityGrouped().deserialize(item));
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }
  }
}

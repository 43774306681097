/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "@angular/cdk/bidi";
import * as i3 from "./view-editor-menu-edit.component";
import * as i4 from "../../services/view-editor-context/view-editor.context";
var styles_ViewEditorMenuEditComponent = [];
var RenderType_ViewEditorMenuEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorMenuEditComponent, data: {} });
export { RenderType_ViewEditorMenuEditComponent as RenderType_ViewEditorMenuEditComponent };
function View_ViewEditorMenuEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 114, "div", [["class", "compact-select-options compact-select-options_max-height_l"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.dropdownHover$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.dropdownHover$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.undo();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Undo"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Z"])), (_l()(), i0.ɵeld(12, 0, null, null, 12, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.redo();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Redo"])), (_l()(), i0.ɵeld(17, 0, null, null, 7, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 6, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u21E7"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(23, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Z"])), (_l()(), i0.ɵeld(25, 0, null, null, 0, "div", [["class", "compact-select-options__separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.cutLayers();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cut"])), (_l()(), i0.ɵeld(31, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(35, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["X"])), (_l()(), i0.ɵeld(37, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.copyLayers();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(39, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Copy"])), (_l()(), i0.ɵeld(42, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(44, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(46, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["C"])), (_l()(), i0.ɵeld(48, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.pasteBufferLayers();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(49, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(50, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(51, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Paste"])), (_l()(), i0.ɵeld(53, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(54, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(55, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(57, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["V"])), (_l()(), i0.ɵeld(59, 0, null, null, 0, "div", [["class", "compact-select-options__separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(60, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.groupSelected();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(61, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(62, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(63, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Group selected"])), (_l()(), i0.ɵeld(65, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(66, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(67, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(69, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["G"])), (_l()(), i0.ɵeld(71, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.frameSelected();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(72, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(73, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(74, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Frame selected"])), (_l()(), i0.ɵeld(76, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(77, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(78, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2325 \u2318"])), (_l()(), i0.ɵeld(80, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["G"])), (_l()(), i0.ɵeld(82, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.ungroupSelected();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(83, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(84, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(85, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ungroup selected"])), (_l()(), i0.ɵeld(87, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(88, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(89, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(91, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u232B"])), (_l()(), i0.ɵeld(93, 0, null, null, 0, "div", [["class", "compact-select-options__separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(94, 0, null, null, 10, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectAll();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(95, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(96, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(97, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Select all"])), (_l()(), i0.ɵeld(99, 0, null, null, 5, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(100, 0, null, null, 4, "div", [["class", "compact-select-option__hotkey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(101, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-modifier"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u2318"])), (_l()(), i0.ɵeld(103, 0, null, null, 1, "span", [["class", "compact-select-option__hotkey-symbol"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["A"])), (_l()(), i0.ɵeld(105, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectNone();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(106, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(107, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(108, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Select none"])), (_l()(), i0.ɵeld(110, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectInverse();
        var pd_0 = (_co.onItemSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(111, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(112, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(113, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Select inverse"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editorContext.isUndoAvailable(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.editorContext.isRedoAvailable(); _ck(_v, 12, 0, currVal_1); var currVal_2 = !_co.isLayerSelected(); _ck(_v, 26, 0, currVal_2); var currVal_3 = !_co.isLayerSelected(); _ck(_v, 37, 0, currVal_3); var currVal_4 = !_co.isBufferLayersSet(); _ck(_v, 48, 0, currVal_4); var currVal_5 = !_co.isLayerSelected(); _ck(_v, 60, 0, currVal_5); var currVal_6 = !_co.isLayerSelected(); _ck(_v, 71, 0, currVal_6); var currVal_7 = !_co.isUngroupAvailable(); _ck(_v, 82, 0, currVal_7); var currVal_8 = !_co.isSelectAllAvailable(); _ck(_v, 94, 0, currVal_8); var currVal_9 = !_co.isLayerSelected(); _ck(_v, 105, 0, currVal_9); var currVal_10 = !_co.isSelectInverseAvailable(); _ck(_v, 110, 0, currVal_10); }); }
export function View_ViewEditorMenuEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ViewEditorMenuEditComponent_1)), i0.ɵdid(1, 671744, null, 0, i1.CdkConnectedOverlay, [i1.Overlay, i0.TemplateRef, i0.ViewContainerRef, i1.ɵc, [2, i2.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(2, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trigger; var currVal_1 = _co.dropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 2, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ViewEditorMenuEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-menu-edit", [], null, null, null, View_ViewEditorMenuEditComponent_0, RenderType_ViewEditorMenuEditComponent)), i0.ɵdid(1, 245760, null, 0, i3.ViewEditorMenuEditComponent, [i4.ViewEditorContext, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorMenuEditComponentNgFactory = i0.ɵccf("app-view-editor-menu-edit", i3.ViewEditorMenuEditComponent, View_ViewEditorMenuEditComponent_Host_0, { trigger: "trigger" }, { close: "close" }, []);
export { ViewEditorMenuEditComponentNgFactory as ViewEditorMenuEditComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';

import { CustomizeService, CustomViewSettings, PopupPosition, PopupStyle, ViewContext } from '@modules/customize';
import { Option } from '@modules/field-components';
import { controlValue } from '@shared';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-open-popup',
  templateUrl: './customize-bar-action-edit-type-open-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeOpenPopupComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() analyticsSource: string;
  @Output() submit = new EventEmitter<void>();

  popupOptions: Option<string>[] = [];
  selectedPopupOption: Option;
  popupStyles = PopupStyle;
  popupPositions = PopupPosition;

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updatePopupOptions();

    controlValue(this.form.controls.open_popup)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedPopupOption = this.popupOptions.filter(item => item.value == value)[0];
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  updatePopupOptions() {
    if (this.context.viewSettings && this.context.viewSettings instanceof CustomViewSettings) {
      this.popupOptions = this.context.viewSettings.popups.map(item => {
        return {
          value: item.uid,
          name: item.name,
          icon: item.getIcon()
        };
      });
    }
  }

  createPopup(
    options: {
      width?: number;
      defaultName?: string;
      style?: PopupStyle;
      position?: PopupPosition;
      overlay?: boolean;
    } = {}
  ) {
    if (this.customizeService.handler && this.customizeService.handler.createPopup) {
      const popup = this.customizeService.handler.createPopup(false, {
        width: options.width,
        defaultName: options.defaultName,
        style: options.style,
        position: options.position,
        overlay: options.overlay,
        analyticsSource: 'customize_action'
      });
      this.updatePopupOptions();
      this.form.controls.open_popup.patchValue(popup.uid);
      this.submit.emit();

      // TODO: Fix for not saving before destroy (change to submitImmediate); 500ms min delay
      timer(200 + 60 + 400)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          if (this.customizeService.handler.openPopup) {
            this.customizeService.handler.openPopup(popup.uid, { openComponents: true });
          }
        });
    }
  }

  openPopup(uid: string) {
    if (this.customizeService.handler && this.customizeService.handler.openPopup) {
      this.customizeService.handler.openPopup(uid, { openComponents: true });
    }
  }
}

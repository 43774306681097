<div class="compact-inputs-element">
  <div class="hue-space" [style.background]="hueSpaceBackground" #hue_space_element>
    <div class="hue-space__saturation"></div>
    <div class="hue-space__lightness"></div>

    <div
      class="hue-space__point"
      [style.left.%]="(hueSpaceX | appIsSet) ? 100 * hueSpaceX : saturationHSBControl.value"
      [style.top.%]="(hueSpaceY | appIsSet) ? 100 * hueSpaceY : 100 - brightnessControl.value"
      [style.background]="hueSpaceThumbBackground"
    ></div>
  </div>
</div>

<div class="compact-inputs-element">
  <app-slider
    [baseControl]="hueGlobalControl"
    [min]="0"
    [max]="360"
    [debounce]="0"
    [valueLabel]="false"
    [labels]="false"
    [trackBackground]="hueBackground"
    [fillBackground]="'transparent'"
    [thumbBorderColor]="hueThumbBorder"
    [thumbInside]="true"
    (baseChange)="setHue($event)"
  ></app-slider>
</div>

<div class="compact-inputs-element">
  <app-slider
    [baseControl]="alphaControl"
    [min]="0"
    [max]="100"
    [debounce]="0"
    [valueLabel]="false"
    [labels]="false"
    [trackBackground]="alphaBackground"
    [fillBackground]="'transparent'"
    [thumbBorderColor]="hueThumbBorder"
    [thumbInside]="true"
    (baseChange)="submitColor()"
  ></app-slider>
</div>

<div class="compact-inputs-element">
  <div class="compact-inputs-row">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="hexControl"
        [prefix]="'#'"
        [placeholder]="'000000'"
        (change)="setHex(hexControl.value)"
      ></app-compact-input>
    </div>

    <div class="compact-inputs-row__item">
      <app-compact-input
        [formControl]="alphaControl"
        [postfix]="'%'"
        [widthPx]="60"
        [number]="true"
        [numberOptions]="{ min: 0, max: 100 }"
        (change)="submitColor()"
      ></app-compact-input>
    </div>

    <div class="compact-inputs-row__item">
      <div class="compact-button compact-button_primary icon-dropper" (click)="pickColor()"></div>
    </div>
  </div>
</div>

<div class="compact-inputs-element">
  <div class="compact-inputs-row compact-inputs-row_vertical-align_top">
    <div class="compact-inputs-row__item">
      <app-compact-select
        [formControl]="colorModelControl"
        [options]="colorModelOptions"
        [placeholder]="'Type'"
        (change)="saveColorModel(); submitColor()"
      ></app-compact-select>

      <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Model</div>
    </div>

    <ng-container *ngIf="colorModelControl.value == colorModels.RGB">
      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="redControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 255 }"
          (change)="setRGB({ red: redControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Red</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="greenControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 255 }"
          (change)="setRGB({ green: greenControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Green</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="blueControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 255 }"
          (change)="setRGB({ blue: blueControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Blue</div>
      </div>
    </ng-container>

    <ng-container *ngIf="colorModelControl.value == colorModels.HSL">
      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="hueControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 360 }"
          (change)="setHSL({ hue: hueControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Hue</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="saturationHSLControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 100 }"
          (change)="setHSL({ saturationHSL: saturationHSLControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Saturation</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="luminosityControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 100 }"
          (change)="setHSL({ luminosity: luminosityControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Luminosity</div>
      </div>
    </ng-container>

    <ng-container *ngIf="colorModelControl.value == colorModels.HSB">
      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="hueControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 360 }"
          (change)="setHSB({ hue: hueControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Hue</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="saturationHSBControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 100 }"
          (change)="setHSB({ saturationHSB: saturationHSBControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Saturation</div>
      </div>

      <div class="compact-inputs-row__item">
        <app-compact-input
          [formControl]="brightnessControl"
          [number]="true"
          [numberOptions]="{ min: 0, max: 100 }"
          (change)="setHSB({ brightness: brightnessControl.value })"
        ></app-compact-input>

        <div class="compact-inputs-row__subtitle compact-inputs-row__subtitle_small">Brightness</div>
      </div>
    </ng-container>
  </div>
</div>

<div class="sidebar__header2">
  <div class="sidebar-header2">
    <div class="sidebar-header2__top">
      <div class="sidebar-header2__top-main">
        <div class="sidebar-header2-button sidebar-header2__top-item" (click)="close()">
          <span class="sidebar-header2-button__icon sidebar-header2-button__icon_left icon-close"></span>
          <span class="sidebar-header2-button__label">Close</span>
        </div>
      </div>
    </div>

    <div class="sidebar-header2__title">Run history</div>
  </div>
</div>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__list">
      <app-sidebar-field>
        <div *ngIf="(!(loading$ | async) || (items$ | async)?.length) && !error" class="sidebar-list">
          <app-workflow-runs-item
            *ngFor="let item of items$ | async; trackBy: trackItemFn"
            class="sidebar-list__item"
            [item]="item"
            (click)="openItem(item)"
          ></app-workflow-runs-item>

          <div *ngIf="hasMore && !(loading$ | async)" style="margin-top: 20px; text-align: center;">
            <a href="javascript:void(0)" class="button button_background button_small" (click)="getNext()">
              <span class="button__label">Load more</span>
            </a>
          </div>
        </div>

        <div *ngIf="(loading$ | async) && !(items$ | async)?.length" class="sidebar-list">
          <div *ngFor="let item of [1, 2, 3, 4]" class="sidebar-list__item">
            <div class="sidebar-list-item">
              <div class="sidebar-list-item__left">
                <span class="sidebar-list-item__icon"></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">
                  <span [class.loading-animation]="true"><span class="stub-text">Created</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="error">
          <app-error [title]="'Loading failed' | localize" [compact]="true">
            {{ error }}
          </app-error>
        </div>

        <div *ngIf="!(loading$ | async) && !(items$ | async)?.length && !error">
          <app-error [title]="'Nothing found' | localize" [compact]="true">
            This workflow has no runs yet
          </app-error>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

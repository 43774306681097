import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, ComponentRef, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { TweenMax } from 'gsap';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';

import { isSet } from '@shared';

import { DataTooltipComponent, DataTooltipDataset } from '../../components/data-tooltip/data-tooltip.component';

@Injectable()
export class DataTooltipController implements OnDestroy {
  screenPadding = 10;
  tipMargin = 15;
  tooltipPortal = new ComponentPortal(DataTooltipComponent, null, this.injector);
  tooltipComponentRef: ComponentRef<DataTooltipComponent>;

  constructor(private injector: Injector) {}

  ngOnDestroy(): void {
    this.close();
  }

  show(options: {
    group?: string;
    datasets?: DataTooltipDataset[];
    datasetActiveIndex?: number;
    valueFormat?: string;
    colorLine?: boolean;
    colorCircle?: boolean;
    x: number;
    y: number;
    portalOutlet: CdkPortalOutlet;
    reuse?: boolean;
  }) {
    if (!options.reuse) {
      this.close();
    }

    const newComponent = !options.reuse || !this.tooltipComponentRef;
    if (newComponent) {
      this.tooltipComponentRef = options.portalOutlet.attachComponentPortal(this.tooltipPortal);
    }

    if (isSet(options.group)) {
      this.tooltipComponentRef.instance.group = options.group;
    }

    if (isSet(options.datasets)) {
      this.tooltipComponentRef.instance.datasets = options.datasets;
    }

    if (isSet(options.datasetActiveIndex)) {
      this.tooltipComponentRef.instance.datasetActiveIndex = options.datasetActiveIndex;
    }

    if (isSet(options.valueFormat)) {
      this.tooltipComponentRef.instance.valueFormat = options.valueFormat;
    }

    if (isSet(options.colorLine)) {
      this.tooltipComponentRef.instance.colorLine = options.colorLine;
    }

    if (isSet(options.colorCircle)) {
      this.tooltipComponentRef.instance.colorCircle = options.colorCircle;
    }

    this.tooltipComponentRef.injector.get(ChangeDetectorRef).markForCheck();

    this.move(options.x, options.y, !newComponent);
  }

  move(x: number, y: number, animate = false) {
    if (!this.tooltipComponentRef) {
      return;
    }

    this.tooltipComponentRef.injector
      .get(NgZone)
      .onStable.pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const element = this.tooltipComponentRef.location.nativeElement;
        const contentWidth = this.tooltipComponentRef.instance.getContentWidth();
        const contentHeight = this.tooltipComponentRef.instance.getContentHeight();
        const containerBounds = element.parentElement.getBoundingClientRect();
        const customizing = !!document.querySelector('.admin_customize');
        const menuTop = !!document.querySelector('.admin__content_menu-top');
        const topPadding = customizing ? 50 + this.screenPadding : this.screenPadding + (menuTop ? 50 : 0);
        const bottomPadding = this.screenPadding;
        const leftPadding = customizing ? 50 + this.screenPadding : this.screenPadding;
        const rightPadding = this.screenPadding;
        const minX = leftPadding + 0.5 * contentWidth - containerBounds.left;
        const maxX = window.innerWidth - (rightPadding + 0.5 * contentWidth) - containerBounds.left;
        const aboveHeight = y + containerBounds.top - this.tipMargin - topPadding;
        const belowHeight = window.innerHeight - (y + containerBounds.top + this.tipMargin) - bottomPadding;
        const above = contentHeight <= aboveHeight || aboveHeight >= belowHeight;
        const maxHeight = above ? aboveHeight : belowHeight;

        x = clamp(x, minX, maxX);

        TweenMax.to(element, animate ? 0.2 : 0, {
          x: x,
          y: above ? y - this.tipMargin : y + this.tipMargin,
          xPercent: -50,
          yPercent: above ? -100 : 0,
          ease: undefined
        });

        this.tooltipComponentRef.instance.setMaxHeight(maxHeight);
      });
  }

  close() {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.destroy();
      this.tooltipComponentRef = undefined;
    }
  }
}

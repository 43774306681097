import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CurrentProjectStore, ProjectService } from '@modules/projects';

@Injectable()
export class ProjectCopyInterfaceForm {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private projectService: ProjectService
  ) {
    this.form = this.fb.group({
      project_copy: new FormControl(null, Validators.required),
      interface_settings: new FormControl({}, Validators.required)
    });
  }

  submit() {
    return this.projectService.copySettings(
      this.currentProjectStore.instance,
      this.form.value['project_copy']
      // this.form.value['interface_settings']
    );
  }
}

import toPairs from 'lodash/toPairs';
import { getFieldDescriptionByType } from '@modules/fields';
import { filterItemsFromQueryParams, matchFilterItem } from '@modules/filter-utils';
import { ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { isSet, objectsSortPredicate } from '@shared';
export function applyFrontendFiltering(resultBody, params, columns) {
    var filterItems = filterItemsFromQueryParams(params);
    var search = params[SEARCH_PARAM];
    var orderBy = params[ORDER_BY_PARAM];
    resultBody = resultBody.filter(function (item) {
        return filterItems.every(function (filterItem) {
            var column = columns.find(function (i) { return i.name == filterItem.field; });
            if (!column) {
                return true;
            }
            var fieldValue = item[column.name];
            return matchFilterItem(column, fieldValue, filterItem);
        });
    });
    if (isSet(search)) {
        search = String(search).toLowerCase();
        resultBody = resultBody.filter(function (item) {
            return toPairs(item).some(function (_a) {
                var name = _a[0], value = _a[1];
                var field = columns.find(function (i) { return i.name == name; });
                if (!field) {
                    return false;
                }
                var fieldDescription = getFieldDescriptionByType(field.field);
                if (fieldDescription.deserializeValue) {
                    value = fieldDescription.deserializeValue(value, field);
                }
                if (fieldDescription.valueToStr) {
                    value = fieldDescription.valueToStr(value, { field: field });
                }
                return String(value).toLowerCase().includes(search);
            });
        });
    }
    if (isSet(orderBy)) {
        resultBody = resultBody.sort(objectsSortPredicate(orderBy));
    }
    return resultBody;
}
export function applyFrontendPagination(response, params, paginate) {
    var page = params[PAGE_PARAM] || 1;
    var perPage = params[PER_PAGE_PARAM] || 20;
    var from = (page - 1) * perPage;
    var to = page * perPage - 1;
    response.count = response.results.length;
    response.hasMore = response.count - 1 > to;
    response.perPage = perPage;
    if (paginate) {
        response.results = response.results.slice(from, to + 1);
    }
}

<div
  class="line-layer__bounds view-editor-bounds"
  [class.line-layer__bounds_hover]="hover"
  [class.line-layer__bounds_active]="active"
  [style.top.%]="fromY * 100"
  [style.left.%]="fromX * 100"
  [style.width.em]="length / 10"
  [style.transform]="transform"
>
  <div
    appLayerResizable
    (appLayerResizableResizeStarted)="onPointResizeStarted()"
    (appLayerResizableResize)="applyPointResize($event, false)"
    (appLayerResizableResizeFinished)="onPointResizeFinished()"
    class="view-editor-bounds__handle view-editor-bounds__handle_position_top-left view-editor-bounds__handle_cursor_move"
  ></div>

  <div
    appLayerResizable
    (appLayerResizableResizeStarted)="onPointResizeStarted()"
    (appLayerResizableResize)="applyPointResize($event, true)"
    (appLayerResizableResizeFinished)="onPointResizeFinished()"
    class="view-editor-bounds__handle view-editor-bounds__handle_position_top-right view-editor-bounds__handle_cursor_move"
  ></div>

  <div class="line-layer__bounds-line"></div>

  <div
    class="view-editor-bounds__dot view-editor-bounds__dot_position_top-left"
    [class.view-editor-bounds__dot_active]="
      active && !(editorContext.movingLayer$ | async) && !(editorContext.customizingGradient$ | async)
    "
  ></div>

  <div
    class="view-editor-bounds__dot view-editor-bounds__dot_position_top-right"
    [class.view-editor-bounds__dot_active]="
      active && !(editorContext.movingLayer$ | async) && !(editorContext.customizingGradient$ | async)
    "
  ></div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectToken } from '../../data/project-token';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectTokenService = /** @class */ (function () {
    function ProjectTokenService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectTokenService.prototype.create = function (project, token) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/project_tokens/");
            var headers = new HttpHeaders();
            var data = token.serialize();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new ProjectToken().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectTokenService.prototype.update = function (project, token, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/project_tokens/" + token.token + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, token.serialize(fields), { headers: headers });
        }), map(function (result) { return new ProjectToken().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectTokenService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectTokenService_Factory() { return new ProjectTokenService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectTokenService, providedIn: "root" });
    return ProjectTokenService;
}());
export { ProjectTokenService };

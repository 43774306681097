import { Pipe, PipeTransform } from '@angular/core';

import { LocalStorage } from '@core';

@Pipe({
  name: 'appBannerVisible',
  pure: false
})
export class BannerVisiblePipe implements PipeTransform {
  constructor(private localStorage: LocalStorage) {}

  transform(uid: string): any {
    return this.localStorage.get(`hide_banner_${uid}`) != '1';
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { InputFieldProviderItem, listModelDescriptionInputFieldProvider, modelDescriptionHasAutoParameters } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryService, QueryType } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue } from '@shared';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';
var ListModelDescriptionDataSourceControl = /** @class */ (function (_super) {
    __extends(ListModelDescriptionDataSourceControl, _super);
    function ListModelDescriptionDataSourceControl(modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone) {
        return _super.call(this, modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone) || this;
    }
    ListModelDescriptionDataSourceControl.prototype.createModelQuery = function () {
        return new ListModelDescriptionQuery();
    };
    ListModelDescriptionDataSourceControl.prototype.getInputFieldProvider$ = function () {
        return combineLatest(controlValue(this.controls.type), this.getResource$(), this.getModelDescription$(), controlValue(this.controls.query_parameters), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var type = _a[0], resource = _a[1], modelDescription = _a[2], parameters = _a[3], getQuery = _a[4], columns = _a[5];
            return listModelDescriptionInputFieldProvider(type, resource, modelDescription, parameters, getQuery, columns);
        }));
    };
    ListModelDescriptionDataSourceControl.prototype.isListQuery = function () {
        return true;
    };
    ListModelDescriptionDataSourceControl.prototype.isFieldSortable = function (type, query, field, resource, modelDescription) {
        if (field.flex) {
            return false;
        }
        if (type == DataSourceType.Query && query) {
            if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
                return true;
            }
            else if (query.queryType == QueryType.Simple) {
                return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
            }
            else if (query instanceof ListModelDescriptionQuery) {
                return query.isFieldSortable(field);
            }
            else {
                return false;
            }
        }
        else if (type == DataSourceType.Input) {
            return true;
        }
        else {
            return false;
        }
    };
    ListModelDescriptionDataSourceControl.prototype.getSortableColumnOptions$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), this.getResource$(), this.getModelDescription$(), controlValue(this.controls.query), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var type = _a[0], resource = _a[1], modelDescription = _a[2], getQuery = _a[3], columns = _a[4];
            if (!columns) {
                return [];
            }
            return columns
                .filter(function (item) {
                return _this.isFieldSortable(type, getQuery, item, resource, modelDescription);
            })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name
                };
            });
        }));
    };
    ListModelDescriptionDataSourceControl.instanceCls = ListModelDescriptionDataSource;
    return ListModelDescriptionDataSourceControl;
}(ModelDescriptionDataSourceControl));
export { ListModelDescriptionDataSourceControl };

import { forwardRef, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoutingService } from '@modules/routing';

import { CurrentProjectStore } from '../../stores/current-project.store';

@Injectable({
  providedIn: 'root'
})
export class ProjectPermissionGuard implements CanActivate {
  constructor(private routing: RoutingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!next.data['projectPermission']) {
      return true;
    }

    return true;
    // return this.currentProjectStore.getFirst().pipe(
    //   map(project => {
    //     if (!project) {
    //       return false;
    //     }
    //
    //     if (next.data['projectPermission']['model']) {
    //       let modelId: string;
    //       const conf = next.data['projectPermission']['model'];
    //
    //       if (conf['static']) {
    //         modelId = conf['static'];
    //       } else if (conf['param']) {
    //         modelId = next.params[conf['param']];
    //       }
    //
    //       if (!project.hasModelPermission(modelId, 'r')) {
    //         this.routing.navigateApp(project.homeLink, undefined, project.uniqueName);
    //         return false;
    //       }
    //
    //       return true;
    //     } else {
    //       return true;
    //     }
    //   })
    // );
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { FieldType, ValueFormat } from '@modules/fields';

import { ValueFormatControl } from '../../value-format-edit/value-format.control';

@Injectable()
export class NumberFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;

  constructor(private fb: FormBuilder, private valueFormatControl: ValueFormatControl) {
    this.form = this.fb.group({
      value_format: valueFormatControl
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue({}, { emitEvent: false });
    }

    const valueFormat =
      control.value && control.value['value_format']
        ? new ValueFormat().deserialize(control.value['value_format'])
        : undefined;
    this.valueFormatControl.deserialize(valueFormat, { field: FieldType.Number });

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = this.form.value;
    const valueFormat = this.valueFormatControl.serialize();

    data['value_format'] = valueFormat ? valueFormat.serialize() : undefined;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

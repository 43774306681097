import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewsStore } from '@modules/custom-views';
import {
  CalendarSettings,
  CustomizeService,
  rawListViewSettingsColumnsToViewContextOutputs,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import {
  applyParamInput$,
  applyParamInputs$,
  createFormFieldFactory,
  DisplayFieldType,
  FieldType,
  LOADING_VALUE,
  NOT_SET_VALUE
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  CHECKED_ITEMS_OUTPUT,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { DATE_PARAM, Model, NEXT_PAGE_SCROLL_PARAM, TYPE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';

import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { CalendarDayComponent } from '../calendar-day/calendar-day.component';
import { CalendarMonthComponent } from '../calendar-month/calendar-month.component';
import { CalendarWeekComponent } from '../calendar-week/calendar-week.component';
import {
  CalendarState,
  getListStateColumns,
  getListStateFetch,
  getListStateFetchNewParams,
  getListStateSelection
} from './calendar-state';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent extends ListLayoutComponent<CalendarSettings, CalendarState>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild(CalendarDayComponent) dayComponent: CalendarDayComponent;
  @ViewChild(CalendarWeekComponent) weekComponent: CalendarWeekComponent;
  @ViewChild(CalendarMonthComponent) monthComponent: CalendarMonthComponent;

  layout = ListLayoutType.Calendar;
  calendarTypes = CalendarType;
  configured = true;
  createField = createFormFieldFactory();
  loading = false;

  constructor(
    public customViewsStore: CustomViewsStore,
    public customizeService: CustomizeService,
    injector: Injector,
    cd: ChangeDetectorRef,
    customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.initContext();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getTypeComponent(state: CalendarState): CalendarBaseComponent {
    if (state.type == CalendarType.Day) {
      return this.dayComponent;
    } else if (state.type == CalendarType.Week) {
      return this.weekComponent;
    } else if (state.type == CalendarType.Month) {
      return this.monthComponent;
    }
  }

  getListState(
    settings: CalendarSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<CalendarState> {
    params = cloneDeep(params);

    delete params[NEXT_PAGE_SCROLL_PARAM];

    if (!sort.length && isSet(settings.sortingField)) {
      sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
    }

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(
      map(([staticData, inputParams, modelDescription]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings.dataSource,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          dateField: settings ? settings.dateField : undefined,
          type: params[TYPE_PARAM] || CalendarType.Month,
          date: params[DATE_PARAM] ? moment(params[DATE_PARAM]) : moment(),
          sortingField: settings ? settings.sortingField : undefined,
          sortingAsc: settings ? settings.sortingAsc : undefined,
          multipleSelection: settings.multipleSelection
        };
      })
    );
  }

  onStateUpdated(state: CalendarState) {
    if (
      !isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
      !isEqual(getListStateSelection(state), getListStateSelection(this.listState))
    ) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
      let paramsNeedUpdate = false;

      if (newParams && this.setPage(1)) {
        paramsNeedUpdate = true;
      }

      if (!paramsNeedUpdate) {
        this.fetch(state);
      }
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
        this.updateStoreColumns(state);
      }
    }

    const component = this.getTypeComponent(state);

    if (component) {
      component.onStateUpdated(state, this.listState);
    }
  }

  updateVisibleColumns(state: CalendarState) {
    const component = this.getTypeComponent(state);

    if (component) {
      component.updateVisibleColumns(state);
    }
  }

  updateStoreColumns(state: CalendarState) {
    const component = this.getTypeComponent(state);

    if (component) {
      component.updateStoreColumns(state);
    }
  }

  fetch(state: CalendarState) {
    this.configured = state.dataSource && state.dataSource.isConfigured() && state.settings.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.cd.markForCheck();

    this.contextElement.patchOutputValueMeta(EMPTY_OUTPUT, { loading: true });

    const component = this.getTypeComponent(state);

    if (!this.configured) {
      this.loading = false;
      this.cd.markForCheck();

      if (component) {
        component.resetItems();
      }

      return;
    }

    if (state.inputsNotSet) {
      this.loading = false;
      this.cd.markForCheck();

      if (component) {
        component.resetItems();
      }

      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    if (state.inputsLoading) {
      if (component) {
        component.resetItems();
      }
      return;
    }

    if (component) {
      component.fetch(state);

      component.setSelectedItem(undefined, false);
      component.setChecked([], false);
      component.updateSelectedContext();
    }
  }

  clearSelectedItem() {
    const component = this.getTypeComponent(this.listState);

    if (component) {
      component.setSelectedItem(undefined);
    }
  }

  clearChecked() {
    const component = this.getTypeComponent(this.listState);

    if (component) {
      component.setChecked([]);
    }
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Card',
        icon: 'deselect',
        parameters: [],
        handler: () => {
          this.clearSelectedItem();
          this.clearChecked();
        }
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetFilters()
      }
    ]);
  }

  updateContextOutputs(state: CalendarState) {
    const columns = state.dataSource ? state.dataSource.columns : [];

    this.contextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Card',
        icon: 'duplicate_2',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: rawListViewSettingsColumnsToViewContextOutputs(
          columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Card',
        icon: 'hand',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Card selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Card selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      ...(state.multipleSelection
        ? [
            {
              uniqueName: CHECKED_ITEMS_OUTPUT,
              name: 'Checked Cards',
              icon: 'check',
              children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            }
          ]
        : [])
    ]);

    const component = this.getTypeComponent(state);

    if (component) {
      component.updateSelectedContext();
    }
  }

  public getAnyModel(): Model {
    const component = this.getTypeComponent(this.listState);

    if (!component) {
      return;
    }

    return component.getAnyModel();
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { EditableContentModule } from '@common/editable-content';
import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { ResizableModule } from '@common/resizable';
import { TextEditorModule } from '@common/text-editor';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { AnalyticsModule } from '@modules/analytics';
import { CalendarModule } from '@modules/calendar';
import { ColorsSharedModule } from '@modules/colors-shared';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeElementsModule } from '@modules/customize-elements';
import { CustomizeSharedModule } from '@modules/customize-shared';
import { CustomizeUiModule } from '@modules/customize-ui';
import { DashboardComponentsModule } from '@modules/dashboard-components';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { GridModule } from '@modules/grid';
import { IconsModule } from '@modules/icons';
import { ImageCodesModule } from '@modules/image-codes';
import { KanbanBoardModule } from '@modules/kanban-board';
import { MapModule } from '@modules/map';
import { MenuComponentsModule } from '@modules/menu-components';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { QueriesComponentsModule } from '@modules/queries-components';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { TableModule } from '@modules/table';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TimelineModule } from '@modules/timeline';
import { TutorialModule } from '@modules/tutorial';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AccordionComponent } from './components/accordion/accordion.component';
import { ActionDropdownElementItemComponent } from './components/action-dropdown-element/action-dropdown-element-item/action-dropdown-element-item.component';
import { ActionDropdownElementComponent } from './components/action-dropdown-element/action-dropdown-element.component';
import { ActionElementComponent } from './components/action-element/action-element.component';
import { ActionGroupElementComponent } from './components/action-group-element/action-group-element.component';
import { ActionGroupElementItemComponent } from './components/action-group-element/alert-element-action/action-group-element-item.component';
import { ActionMenuItemComponent } from './components/action-menu/action-menu-item/action-menu-item.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { AlertElementActionComponent } from './components/alert-element/alert-element-action/alert-element-action.component';
import { AlertElementComponent } from './components/alert-element/alert-element.component';
import { AutoFieldElementComponent } from './components/auto-field-element/auto-field-element.component';
import { AutoWidgetElementComponent } from './components/auto-widget-element/auto-widget-element.component';
import { BackElementComponent } from './components/back-element/back-element.component';
import { BarCodeElementComponent } from './components/bar-code-element/bar-code-element.component';
import { CardLayoutComponent } from './components/card-layout/card-layout.component';
import { CollapseSectionComponent } from './components/collapse-section/collapse-section.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { ColumnsLayoutHandleComponent } from './components/columns-layout/columns-layout-handle/columns-layout-handle.component';
import { ColumnsLayoutComponent } from './components/columns-layout/columns-layout.component';
import { ContainerAppStubComponent } from './components/container-app-stub/container-app-stub.component';
import { ContainerBuilderStubComponent } from './components/container-builder-stub/container-builder-stub.component';
import { CustomElementComponent } from './components/custom-element/custom-element.component';
import { CustomPageParametersComponent } from './components/custom-page-parameters/custom-page-parameters.component';
import { CustomPagePopupComponent } from './components/custom-page-popup/custom-page-popup.component';
import { CustomPagePopupsMenuSectionItemComponent } from './components/custom-page-popups-menu-section-item/custom-page-popups-menu-section-item.component';
import { CustomPagePopupsMenuSectionComponent } from './components/custom-page-popups-menu-section/custom-page-popups-menu-section.component';
import { CustomPagePopupsMenuComponent } from './components/custom-page-popups-menu/custom-page-popups-menu.component';
import { CustomPagePopupsComponent } from './components/custom-page-popups/custom-page-popups.component';
import { CustomPagePropertiesAddMenuComponent } from './components/custom-page-properties-add-menu/custom-page-properties-add-menu.component';
import { CustomPagePropertiesSectionItemComponent } from './components/custom-page-properties-section-item/custom-page-properties-section-item.component';
import { CustomPagePropertiesSectionComponent } from './components/custom-page-properties-section/custom-page-properties-section.component';
import { CustomPagePropertiesComponent } from './components/custom-page-properties/custom-page-properties.component';
import { CustomPageQueriesItemComponent } from './components/custom-page-queries/custom-page-queries-item/custom-page-queries-item.component';
import { CustomPageQueriesComponent } from './components/custom-page-queries/custom-page-queries.component';
import { CustomizeSelectionComponent } from './components/customize-selection/customize-selection.component';
import { DateRangeElementComponent } from './components/date-range-element/date-range-element.component';
import { DefaultElementComponent } from './components/default-element/default-element.component';
import { ElementDragPreviewComponent } from './components/element-drag-preview/element-drag-preview.component';
import { ElementPlaceholderStubComponent } from './components/element-placeholder-stub/element-placeholder-stub.component';
import { FileViewerElementComponent } from './components/file-viewer-element/file-viewer-element.component';
import { FilterElementItemComponent } from './components/filter-element/filter-element-item/filter-element-item.component';
import { FilterElementComponent } from './components/filter-element/filter-element.component';
import { FormElementComponent } from './components/form-element/form-element.component';
import { FormSubmitElementComponent } from './components/form-submit-element/form-submit-element.component';
import { IFrameElementComponent } from './components/iframe-element/iframe-element.component';
import { ImageElementComponent } from './components/image-element/image-element.component';
import { ListElementComponent } from './components/list-element/list-element.component';
import { ModelElementItemComponent } from './components/model-element/model-element-item/model-element-item.component';
import { ModelElementComponent } from './components/model-element/model-element.component';
import { QrCodeElementComponent } from './components/qr-code-element/qr-code-element.component';
import { RangeSliderElementComponent } from './components/range-slider-element/range-slider-element.component';
import { RootLayoutComponent } from './components/root-layout/root-layout.component';
import { ScannerElementComponent } from './components/scanner-element/scanner-element.component';
import { SectionLayoutComponent } from './components/section-layout/section-layout.component';
import { SeparatorElementComponent } from './components/separator-element/separator-element.component';
import { SpacingElementComponent } from './components/spacing-element/spacing-element.component';
import { StackLayoutComponent } from './components/stack-layout/stack-layout.component';
import { StepsElementComponent } from './components/steps-element/steps-element.component';
import { TabsLayoutNavigationItemComponent } from './components/tabs-layout-navigation-item/tabs-layout-navigation-item.component';
import { TabsLayoutComponent } from './components/tabs-layout/tabs-layout.component';
import { TextElementComponent } from './components/text-element/text-element.component';
import { TitleElementComponent } from './components/title-element/title-element.component';
import { CustomElementDirective } from './directives/custom-element/custom-element.directive';
import { ElementGroupsContainerDirective } from './directives/element-groups-container/element-groups-container.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingModule,
    SharedModule,
    DynamicComponentModule,
    NgGxSelectModule,
    DragDropModule,
    TipsModule,
    FieldsModule,
    FieldComponentsModule,
    DashboardComponentsModule,
    TableModule,
    CalendarModule,
    GridModule,
    KanbanBoardModule,
    MapModule,
    TimelineModule,
    QueriesComponentsModule,
    MenuComponentsModule,
    CustomizeBarModule,
    AnalyticsModule,
    TextEditorModule,
    LocalizeModule,
    MatMenuModule,
    FeaturesModule,
    DragDrop2Module,
    EditableContentModule,
    ResizableModule,
    SidebarModule,
    NgGxScrollableModule,
    OverlayModule,
    DragDropModule,
    FormUtilsModule,
    ThemeComponentsModule,
    TutorialModule,
    ParametersComponentsModule,
    ViewsComponentsModule,
    CustomizeElementsModule,
    CustomizeUiModule,
    ColorsSharedModule,
    ImageCodesModule,
    UiModule,
    NgGxDatepickerModule,
    UniqueIdModule,
    CustomizeSharedModule,
    IconsModule
  ],
  declarations: [
    SectionLayoutComponent,
    DefaultElementComponent,
    CardLayoutComponent,
    StackLayoutComponent,
    ColumnsLayoutComponent,
    AutoFieldElementComponent,
    TabsLayoutComponent,
    AutoWidgetElementComponent,
    ActionElementComponent,
    ActionGroupElementComponent,
    ActionGroupElementItemComponent,
    CustomizeSelectionComponent,
    TitleElementComponent,
    TextElementComponent,
    ImageElementComponent,
    ListElementComponent,
    ModelElementComponent,
    FormElementComponent,
    FormSubmitElementComponent,
    RootLayoutComponent,
    CustomElementComponent,
    BackElementComponent,
    ActionDropdownElementComponent,
    IFrameElementComponent,
    QrCodeElementComponent,
    BarCodeElementComponent,
    ScannerElementComponent,
    AlertElementComponent,
    AlertElementActionComponent,
    ElementPlaceholderStubComponent,
    ElementGroupsContainerDirective,
    SpacingElementComponent,
    SeparatorElementComponent,
    CustomPagePopupsComponent,
    CustomPagePopupsMenuComponent,
    CustomPagePopupsMenuSectionComponent,
    CustomPagePopupsMenuSectionItemComponent,
    CustomPagePopupComponent,
    ColumnsLayoutHandleComponent,
    ElementDragPreviewComponent,
    ActionDropdownElementItemComponent,
    ModelElementItemComponent,
    StepsElementComponent,
    ContainerBuilderStubComponent,
    ContainerAppStubComponent,
    FilterElementComponent,
    FilterElementItemComponent,
    FileViewerElementComponent,
    RangeSliderElementComponent,
    DateRangeElementComponent,
    CollapseComponent,
    AccordionComponent,
    TabsLayoutNavigationItemComponent,
    CustomElementDirective,
    CustomPagePropertiesComponent,
    CustomPagePropertiesSectionComponent,
    CustomPagePropertiesSectionItemComponent,
    CustomPagePropertiesAddMenuComponent,
    CustomPageParametersComponent,
    CustomPageQueriesComponent,
    CustomPageQueriesItemComponent,
    CollapseSectionComponent,
    ActionMenuComponent,
    ActionMenuItemComponent
  ],
  exports: [
    RootLayoutComponent,
    CustomizeSelectionComponent,
    CustomPagePopupsComponent,
    CustomPagePopupsMenuComponent,
    CustomPagePopupComponent,
    ElementPlaceholderStubComponent,
    ElementDragPreviewComponent,
    ElementGroupsContainerDirective,
    ContainerBuilderStubComponent,
    ContainerAppStubComponent,
    CustomPagePropertiesComponent,
    CustomPageParametersComponent,
    CustomPageQueriesComponent
  ],
  entryComponents: [
    SectionLayoutComponent,
    DefaultElementComponent,
    CardLayoutComponent,
    StackLayoutComponent,
    ColumnsLayoutComponent,
    AutoFieldElementComponent,
    TabsLayoutComponent,
    AutoWidgetElementComponent,
    ActionElementComponent,
    ActionGroupElementComponent,
    ActionDropdownElementComponent,
    TitleElementComponent,
    TextElementComponent,
    ImageElementComponent,
    ListElementComponent,
    ModelElementComponent,
    FormElementComponent,
    FormSubmitElementComponent,
    CustomElementComponent,
    BackElementComponent,
    IFrameElementComponent,
    QrCodeElementComponent,
    BarCodeElementComponent,
    ScannerElementComponent,
    AlertElementComponent,
    SpacingElementComponent,
    SeparatorElementComponent,
    StepsElementComponent,
    FilterElementComponent,
    FileViewerElementComponent,
    RangeSliderElementComponent,
    DateRangeElementComponent,
    CollapseComponent,
    AccordionComponent,
    ActionMenuComponent
  ]
})
export class CustomizeComponentsModule {}

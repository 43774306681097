import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-background-layout',
  templateUrl: './background-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundLayoutComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() fillScreen = false;
  @Input() preview = false;

  constructor() {}

  ngOnInit() {}
}

import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

import { HttpQueryParameters, HttpQueryService } from '@modules/queries';
import { HTTP_STATUS_CODES, limitObjectLength } from '@shared';

@Component({
  selector: 'app-query-builder-http-response',
  templateUrl: './query-builder-http-response.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderHttpResponseComponent implements OnInit, OnChanges {
  @Input() requestParameters: HttpQueryParameters;
  @Input() response: HttpResponseBase;
  @Input() responseTitle = 'HTTP Response';
  @Input() responseShowHeaders = true;
  @Input() responseBody: any;
  @Input() responseActual = false;

  responseStatus: string;
  responseBodyDisplay: any;

  constructor(private httpQueryService: HttpQueryService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['response']) {
      this.updateResponse();
    }
  }

  updateResponse() {
    let responseDisplay: any;

    this.responseStatus = this.response ? HTTP_STATUS_CODES[this.response.status] : undefined;

    if (this.response instanceof HttpErrorResponse) {
      responseDisplay = this.response.error;
    } else {
      responseDisplay = this.responseBody;
    }

    this.responseBodyDisplay = limitObjectLength(responseDisplay, 10, true);
    this.cd.markForCheck();
  }

  isArray(value) {
    return isArray(value);
  }

  isObject(value) {
    return isPlainObject(value);
  }
}

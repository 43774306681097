import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { RoutingService } from '../../services/routing/routing.service';

@Directive({
  selector: '[appIsLinkActiveRouterLink], [appIsLinkActiveAppLink], [appIsLinkActiveHref]',
  exportAs: 'appIsLinkActive'
})
export class IsLinkActiveDirective implements OnInit, OnDestroy, OnChanges {
  @Input() appIsLinkActiveRouterLink: any[];
  @Input() appIsLinkActiveAppLink: any[];
  @Input() appIsLinkActiveHref: string;
  @Input() appIsLinkActiveQueryParams: Object;
  @Input() appIsLinkActiveExactActive = false;
  @Output() appIsLinkActiveActivate = new EventEmitter<void>();
  @Output() appIsLinkActiveDeactivate = new EventEmitter<void>();

  public active = false;

  constructor(private router: Router, private routing: RoutingService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActive();
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateActive();
  }

  updateActive() {
    let active: boolean;

    if (this.appIsLinkActiveRouterLink) {
      active = this.routing.isRouterLinkActive(this.appIsLinkActiveRouterLink, {
        queryParams: this.appIsLinkActiveQueryParams,
        exact: this.appIsLinkActiveExactActive
      });
    } else if (this.appIsLinkActiveAppLink) {
      active = this.routing.isAppLinkActive(this.appIsLinkActiveAppLink, {
        queryParams: this.appIsLinkActiveQueryParams,
        exact: this.appIsLinkActiveExactActive
      });
    } else {
      active = this.router.url == this.appIsLinkActiveHref;
    }

    if (active == this.active) {
      return;
    }

    this.active = active;
    this.cd.markForCheck();

    if (this.active) {
      this.appIsLinkActiveActivate.emit();
    } else {
      this.appIsLinkActiveDeactivate.emit();
    }
  }
}

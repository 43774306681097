<div class="notifications" [class.notifications_top-padding]="notificationService.topPadding$ | async">
  <app-notification
    *ngFor="let item of items; trackBy: trackItemFn"
    [notification]="item.instance"
    class="notifications__item"
    [style.top.px]="item.position"
    #notification_element
  >
  </app-notification>
</div>

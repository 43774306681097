import { Observable } from 'rxjs';

export function readFileArrayBuffer(file: File): Observable<ArrayBuffer> {
  return new Observable<ArrayBuffer>(observer => {
    const reader = new FileReader();

    reader.onload = () => {
      observer.next(reader.result as ArrayBuffer);
      observer.complete();
    };
    reader.onerror = e => {
      observer.error(e);
    };
    reader.readAsArrayBuffer(file);

    return () => {
      reader.onload = null;
      reader.onerror = null;
    };
  });
}

export function readFileText(file: File, encoding = 'utf-8'): Observable<string> {
  return new Observable<string>(observer => {
    const reader = new FileReader();

    reader.onload = () => {
      observer.next(reader.result as string);
      observer.complete();
    };
    reader.onerror = e => {
      observer.error(e);
    };
    reader.readAsText(file, encoding);

    return () => {
      reader.onload = null;
      reader.onerror = null;
    };
  });
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../query-builder-response-keys/query-builder-response-keys.component.ngfactory";
import * as i2 from "../query-builder-response-keys/query-builder-response-keys.component";
import * as i3 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i4 from "@angular/material/menu";
import * as i5 from "@angular/common";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "./query-builder-response-transform.component";
var styles_QueryBuilderResponseTransformComponent = [];
var RenderType_QueryBuilderResponseTransformComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderResponseTransformComponent, data: {} });
export { RenderType_QueryBuilderResponseTransformComponent as RenderType_QueryBuilderResponseTransformComponent };
function View_QueryBuilderResponseTransformComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-query-builder-response-keys", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.selectedPath.emit({ pathName: _v.context.$implicit.name, value: $event }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_QueryBuilderResponseKeysComponent_0, i1.RenderType_QueryBuilderResponseKeysComponent)), i0.ɵdid(2, 49152, [["children", 4]], 0, i2.QueryBuilderResponseKeysComponent, [], { items: [0, "items"] }, { selected: "selected" }), (_l()(), i0.ɵeld(3, 16777216, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "mouseenter"], [null, "mousedown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._handleMousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5)._handleClick($event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.selectedPath.emit({ pathName: _v.context.$implicit.name, value: [] }) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i3.View_MatMenuItem_0, i3.RenderType_MatMenuItem)), i0.ɵdid(4, 180224, [[2, 4]], 0, i4.MatMenuItem, [i0.ElementRef, i5.DOCUMENT, i6.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), i0.ɵdid(5, 1196032, null, 0, i4.MatMenuTrigger, [i7.Overlay, i0.ElementRef, i0.ViewContainerRef, i4.MAT_MENU_SCROLL_STRATEGY, [2, i4.MatMenu], [6, i4.MatMenuItem], [2, i8.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵted(6, 0, [" ", " "])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pathItems; _ck(_v, 2, 0, currVal_0); var currVal_8 = true; _ck(_v, 4, 0, currVal_8); var currVal_9 = i0.ɵnov(_v, 2).menu; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 4).role; var currVal_2 = i0.ɵnov(_v, 4)._highlighted; var currVal_3 = i0.ɵnov(_v, 4)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 4)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 4).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 4).disabled || null); var currVal_7 = (i0.ɵnov(_v, 5).menuOpen || null); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _v.context.$implicit.chooseLabel; _ck(_v, 6, 0, currVal_10); }); }
function View_QueryBuilderResponseTransformComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseTransformComponent_2)), i0.ɵdid(1, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectedJS.emit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatMenuItem_0, i3.RenderType_MatMenuItem)), i0.ɵdid(3, 180224, [[2, 4]], 0, i4.MatMenuItem, [i0.ElementRef, i5.DOCUMENT, i6.FocusMonitor, [2, i4.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵted(-1, 0, [" JavaScript transform "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paths; _ck(_v, 1, 0, currVal_0); var currVal_7 = true; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 3).role; var currVal_2 = i0.ɵnov(_v, 3)._highlighted; var currVal_3 = i0.ɵnov(_v, 3)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 3)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 3).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 3).disabled || null); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_QueryBuilderResponseTransformComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "icon-arrow_forward_2"]], null, null, null, null, null))], null, null); }
function View_QueryBuilderResponseTransformComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseTransformComponent_4)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = !_v.context.last; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_QueryBuilderResponseTransformComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, ("icon-" + _co.currentValueIcon)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QueryBuilderResponseTransformComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { responseTrigger: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "mat-menu", [], null, null, null, i3.View_MatMenu_0, i3.RenderType_MatMenu)), i0.ɵprd(6144, null, i4.ɵf24, null, [i4.MatMenu]), i0.ɵdid(3, 1294336, [["menu", 4]], 2, i4.MatMenu, [i0.ElementRef, i0.NgZone, i4.MAT_MENU_DEFAULT_OPTIONS], { overlapTrigger: [0, "overlapTrigger"] }, null), i0.ɵqud(603979776, 2, { items: 1 }), i0.ɵqud(335544320, 3, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QueryBuilderResponseTransformComponent_1)), i0.ɵdid(7, 147456, [[3, 4]], 0, i4.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i5.DOCUMENT], null, null), (_l()(), i0.ɵeld(8, 16777216, null, null, 5, "a", [["aria-haspopup", "true"], ["class", "input input_fill"], ["href", "javascript:void(0)"], ["style", "margin-left: 10px;"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 9)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 1196032, [[1, 4], ["response_trigger", 4]], 0, i4.MatMenuTrigger, [i7.Overlay, i0.ElementRef, i0.ViewContainerRef, i4.MAT_MENU_SCROLL_STRATEGY, [2, i4.MatMenu], [8, null], [2, i8.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseTransformComponent_3)), i0.ɵdid(11, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseTransformComponent_5)), i0.ɵdid(13, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 3, 0, currVal_0); var currVal_2 = i0.ɵnov(_v, 3); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.currentValueDisplay; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.currentValueIcon; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 9).menuOpen || null); _ck(_v, 8, 0, currVal_1); }); }
export function View_QueryBuilderResponseTransformComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-response-transform", [], null, null, null, View_QueryBuilderResponseTransformComponent_0, RenderType_QueryBuilderResponseTransformComponent)), i0.ɵdid(1, 638976, null, 0, i9.QueryBuilderResponseTransformComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderResponseTransformComponentNgFactory = i0.ɵccf("app-query-builder-response-transform", i9.QueryBuilderResponseTransformComponent, View_QueryBuilderResponseTransformComponent_Host_0, { paths: "paths", response: "response", pathValues: "pathValues", pathEnabled: "pathEnabled", emptyLabel: "emptyLabel" }, { selectedPath: "selectedPath", selectedJS: "selectedJS" }, []);
export { QueryBuilderResponseTransformComponentNgFactory as QueryBuilderResponseTransformComponentNgFactory };

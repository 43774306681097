import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { localize } from '@common/localize';
import { CurrentProjectStore, ProjectGroup, ProjectGroupService, ProjectGroupStore } from '@modules/projects';
import { controlValue, objectsSortPredicate } from '@shared';

import {
  ProjectGroupEditController,
  ProjectGroupEditResult
} from '../../services/project-group-edit/project-group-edit.controller';

interface ValueOption {
  value: ProjectGroup;
  name: string;
  description?: string;
  icon?: string;
}

interface ValueOptionSection {
  name?: string;
  items: ValueOption[];
  add?: boolean;
  edit?: boolean;
}

@Component({
  selector: 'app-project-group-dropdown',
  templateUrl: './project-group-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appProjectGroupDropdown'
})
export class ProjectGroupDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() origin: CdkOverlayOrigin;
  @Input() headerTitle: string;
  @Input() headerIcon: string;
  @Input() allowNull = false;
  @Input() environmentName: string;
  @Output() change = new EventEmitter<ProjectGroup>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  public currentOption: ValueOption;

  groups: ProjectGroup[] = [];
  options: ValueOption[] = [];
  sections: ValueOptionSection[] = [];
  dropdownOpened = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(
    private projectGroupEditController: ProjectGroupEditController,
    private projectGroupService: ProjectGroupService,
    private projectGroupStore: ProjectGroupStore,
    private currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue<ProjectGroup>(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.updateCurrentOption(value);
      });

    if (this.environmentName) {
      return this.projectGroupService
        .get(this.currentProjectStore.instance.uniqueName, this.environmentName)
        .pipe(untilDestroyed(this))
        .subscribe(groups => {
          this.groups = groups || [];
          this.updateOptions();
        });
    } else {
      this.projectGroupStore
        .get()
        .pipe(untilDestroyed(this))
        .subscribe(groups => {
          this.groups = groups || [];
          this.updateOptions();
        });
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    if (this.control.disabled) {
      return;
    }

    this.setDropdownOpened(true);
  }

  updateOptions() {
    this.options = this.groups.map(item => {
      return {
        value: item,
        name: item.name,
        description: item.getDescription(),
        icon: item.getIcon()
      };
    });

    const projectGroups = this.options.filter(item => !item.value.permissionsGroup);
    const builtInGroups = this.options.filter(item => item.value.permissionsGroup);

    this.sections = [
      ...(projectGroups.length
        ? [
            {
              name: localize('App Teams'),
              items: projectGroups.sort(objectsSortPredicate('name')),
              add: true,
              edit: true
            }
          ]
        : []),
      {
        name: localize('Built-In Teams'),
        items: builtInGroups.sort(objectsSortPredicate('name')),
        add: projectGroups.length == 0,
        edit: true
      }
    ];

    if (this.allowNull) {
      this.sections.push({
        items: [
          {
            value: null,
            name: localize('Clear Team'),
            icon: 'close'
          }
        ]
      });
    }

    this.updateCurrentOption(this.control.value);
    this.cd.markForCheck();
  }

  updateCurrentOption(value: ProjectGroup) {
    this.currentOption = this.options.find(item => value && item.value.uid == value.uid);
    this.cd.markForCheck();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setValue(item: ValueOption) {
    this.control.setValue(item.value);
    this.control.markAsDirty();
    this.change.emit(item.value);
    this.setDropdownOpened(false);
  }

  createGroup(): void {
    this.setDropdownOpened(false);

    this.projectGroupEditController
      .create()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.control.setValue(result);
        this.change.emit(result);
      });
  }

  editGroup(group: ProjectGroup) {
    this.setDropdownOpened(false);

    this.projectGroupEditController
      .edit(group)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const currentValue = this.control.value as ProjectGroup;

        if (result.result == ProjectGroupEditResult.Edited) {
          this.control.setValue(result.group);
        } else if (
          result.result == ProjectGroupEditResult.Deleted &&
          currentValue &&
          currentValue.uid == result.group.uid
        ) {
          this.control.setValue(undefined);
          this.change.emit(undefined);
        }
      });
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { GuideTask } from '../../data/guide-task';

@Injectable({
  providedIn: 'root'
})
export class GuideTaskProgressService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  complete(projectName: string, environmentName: string, task: GuideTask): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'guide_task_progresses/');
        let headers = new HttpHeaders();
        const data = {
          project: projectName,
          task: task.uniqueName
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-loader.component";
import * as i2 from "../../services/page-loading/page-loading.service";
var styles_PageLoaderComponent = [];
var RenderType_PageLoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageLoaderComponent, data: {} });
export { RenderType_PageLoaderComponent as RenderType_PageLoaderComponent };
export function View_PageLoaderComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { element: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["element", 1]], null, 0, "div", [["class", "page-loader"]], null, null, null, null, null))], null, null); }
export function View_PageLoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-loader", [], null, null, null, View_PageLoaderComponent_0, RenderType_PageLoaderComponent)), i0.ɵdid(1, 4440064, null, 0, i1.PageLoaderComponent, [i2.PageLoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLoaderComponentNgFactory = i0.ɵccf("app-page-loader", i1.PageLoaderComponent, View_PageLoaderComponent_Host_0, {}, {}, []);
export { PageLoaderComponentNgFactory as PageLoaderComponentNgFactory };

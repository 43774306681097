/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/section/section.component.ngfactory";
import * as i2 from "../../../../ui/components/section/section.component";
import * as i3 from "../../../table/components/table-stub/table-stub.component.ngfactory";
import * as i4 from "../../../table/components/table-stub/table-stub.component";
import * as i5 from "../../../../core/services/document/document.service";
import * as i6 from "./apply-template.component";
import * as i7 from "../../../projects/stores/current-project.store";
import * as i8 from "../../../projects/stores/current-environment.store";
import * as i9 from "@angular/router";
import * as i10 from "../../../template/services/template/template.service";
import * as i11 from "../../../template-components/services/template-apply-controller/template-apply.controller";
import * as i12 from "../../../projects/services/environment/environment.service";
import * as i13 from "../../../model-queries/stores/model-description.store";
import * as i14 from "../../../action-queries/stores/action.store";
import * as i15 from "../../../customize/stores/view-settings.store";
import * as i16 from "../../../menu/services/menu/menu.service";
import * as i17 from "../../../menu/stores/menu-settings/menu-settings.store";
import * as i18 from "../../../routing/services/routing/routing.service";
import * as i19 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_ApplyTemplateComponent = [];
var RenderType_ApplyTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ApplyTemplateComponent, data: {} });
export { RenderType_ApplyTemplateComponent as RenderType_ApplyTemplateComponent };
export function View_ApplyTemplateComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-section", [], [[2, "section", null], [2, "section_top", null], [2, "section_no-top-margin", null], [2, "section_no-bottom-margin", null], [2, "section_screen", null], [2, "section_disabled", null], [2, "section_full-border-radius", null], [2, "section_small-border-radius", null]], null, null, i1.View_SectionComponent_0, i1.RenderType_SectionComponent)), i0.ɵdid(1, 114688, null, 0, i2.SectionComponent, [], { screen: [0, "screen"] }, null), (_l()(), i0.ɵeld(2, 0, null, 1, 1, "app-table-stub", [], null, null, null, i3.View_TableStubComponent_0, i3.RenderType_TableStubComponent)), i0.ɵdid(3, 4440064, null, 0, i4.TableStubComponent, [i5.DocumentService, i0.ChangeDetectorRef], { animating: [0, "animating"], scrollable: [1, "scrollable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = true; _ck(_v, 1, 0, currVal_8); var currVal_9 = _co.loading; var currVal_10 = true; _ck(_v, 3, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).top; var currVal_2 = i0.ɵnov(_v, 1).noTopMargin; var currVal_3 = i0.ɵnov(_v, 1).noBottomMargin; var currVal_4 = i0.ɵnov(_v, 1).screen; var currVal_5 = i0.ɵnov(_v, 1).disabled; var currVal_6 = i0.ɵnov(_v, 1).fullBorderRadius; var currVal_7 = i0.ɵnov(_v, 1).smallBorderRadius; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ApplyTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-apply-template", [], null, null, null, View_ApplyTemplateComponent_0, RenderType_ApplyTemplateComponent)), i0.ɵdid(1, 245760, null, 0, i6.ApplyTemplateComponent, [i7.CurrentProjectStore, i8.CurrentEnvironmentStore, i9.ActivatedRoute, i10.TemplateService, i11.TemplateApplyController, i12.EnvironmentService, i13.ModelDescriptionStore, i14.ActionStore, i15.ViewSettingsStore, i16.MenuService, i17.MenuSettingsStore, i0.Injector, i18.RoutingService, i0.ChangeDetectorRef, i19.UniversalAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplyTemplateComponentNgFactory = i0.ɵccf("app-apply-template", i6.ApplyTemplateComponent, View_ApplyTemplateComponent_Host_0, {}, {}, []);
export { ApplyTemplateComponentNgFactory as ApplyTemplateComponentNgFactory };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

import { ResourceAddModelAddedEvent, ResourceAddModelComponent } from '../../../data/resource-add-model-components';

@Component({
  selector: 'app-jet-database-import-file',
  templateUrl: './jet-database-import-file.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JetDatabaseImportFileComponent implements ResourceAddModelComponent {
  @Input() resource: Resource;
  @Input() analyticsSource: string;
  @Output() cancelled = new EventEmitter<void>();
  @Output() added = new EventEmitter<ResourceAddModelAddedEvent>();

  constructor(@Optional() protected popupComponent: BasePopupComponent) {}

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  finish(result: ModelDescription) {
    this.close();
    this.added.emit({ modelDescription: result, reloadData: true });
  }

  cancel(result?: ModelDescription) {
    if (result) {
      this.finish(result);
    } else {
      this.cancelled.emit();
      this.close();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { SidebarCollapseContext } from '@modules/sidebar';
import { WorkflowStepType } from '@modules/workflow';
import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ForkCustomizeWorkflowStepForm } from './fork-customize-workflow-step.form';
var ForkCustomizeWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(ForkCustomizeWorkflowStepComponent, _super);
    function ForkCustomizeWorkflowStepComponent(form, dialogService, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.dialogService = dialogService;
        _this.cd = cd;
        _this.collapseContext = new SidebarCollapseContext();
        return _this;
    }
    ForkCustomizeWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.step, { firstInit: false });
        if (this.workflowEditable) {
            this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.submit(result);
            });
        }
    };
    ForkCustomizeWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    ForkCustomizeWorkflowStepComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.stepChange.emit(value);
    };
    ForkCustomizeWorkflowStepComponent.prototype.addItem = function () {
        var control = this.form.controls.items.createControl();
        this.form.controls.items.push(control);
        this.createdItem = control;
        this.cd.markForCheck();
    };
    ForkCustomizeWorkflowStepComponent.prototype.removeItem = function (control, i) {
        // this.dialogService
        //   .warning({
        //     title: 'Delete Branch',
        //     description: `Are you sure want to delete <strong>Branch ${i + 1}</strong>?`,
        //     style: 'orange'
        //   })
        //   .pipe(untilDestroyed(this))
        //   .subscribe(result => {
        //     if (result) {
        this.form.controls.items.removeControl(control);
        this.cd.markForCheck();
        //   }
        // });
    };
    ForkCustomizeWorkflowStepComponent.prototype.dragDropItem = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
            this.form.controls.items.updateValueAndValidity();
            this.cd.markForCheck();
        }
    };
    return ForkCustomizeWorkflowStepComponent;
}(CustomizeWorkflowStepComponent));
export { ForkCustomizeWorkflowStepComponent };
registerCustomizeWorkflowStepComponent(WorkflowStepType.Fork, ForkCustomizeWorkflowStepComponent);

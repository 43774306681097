<form [formGroup]="form">
  <app-popup2 [size]="'m'">
    <div class="choose-template__header">
      <div class="choose-template__detail-title">
        Import from Sketch file
      </div>
    </div>

    <app-page-block>
      <div class="field">
        <div class="field__label">
          <span class="field__label-text">File</span>
          <span class="field__label-optional">(.sketch)</span>
        </div>
        <div class="field__value">
          <app-page-columns [innerPaddingSize]="'s'">
            <app-page-column>
              <div
                class="upload"
                [class.upload_empty]="!form.controls.file.value"
                [class.upload_uploadable]="true"
                [class.upload_active]="draggingOver"
                style="height: 120px;"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)"
              >
                <div *ngIf="form.controls.file.value" class="upload__preview">
                  <div class="upload__background upload__background_padding">
                    <app-file-preview
                      [previewFilename]="previewFilename"
                      [previewExtension]="previewExtension"
                      [previewSize]="previewSize"
                      [additional]="
                        pageOptions
                          ? (pageOptions.length | i18nPlural: { '=1': '# page', other: '# pages' })
                          : undefined
                      "
                    ></app-file-preview>
                  </div>
                </div>

                <ng-container *ngIf="!form.controls.file.value">
                  <div class="upload__placeholder">
                    <div class="upload__placeholder-icon icon-cloud_upload"></div>
                    <div class="upload__placeholder-label">
                      Drop file here or
                      <label [for]="'upload' | appUniqueId: idToken" class="upload__placeholder-button">
                        Choose file
                      </label>
                    </div>
                  </div>
                </ng-container>

                <div class="upload__controls">
                  <input
                    class="input input_file"
                    type="file"
                    accept=".sketch"
                    (change)="onFileChange($event.target)"
                    [id]="'upload' | appUniqueId: idToken"
                  />

                  <ng-container *ngIf="form.controls.file.value">
                    <div class="upload__buttons">
                      <a
                        href="javascript:void(0)"
                        class="upload__button icon-bin"
                        (click)="form.controls.file.setValue(null)"
                        [appTip]="'Clear'"
                        [appTipOptions]="{ side: 'top', margin: -4 }"
                      ></a>

                      <label
                        [for]="'upload' | appUniqueId: idToken"
                        class="upload__button icon-cloud_upload"
                        [appTip]="'Upload new'"
                        [appTipOptions]="{ side: 'top', margin: -4 }"
                      >
                      </label>

                      <ng-content select="ng-container[upload-actions]"></ng-content>
                    </div>
                  </ng-container>
                </div>

                <label
                  *ngIf="!form.controls.file.value"
                  class="upload__overlay"
                  [for]="'upload' | appUniqueId: idToken"
                >
                </label>
              </div>
            </app-page-column>

            <app-page-column *ngIf="!fileLoading && pageOptions && artboardOptions" [fill]="false">
              <div
                class="field__preview field__preview_border"
                [class.field__preview_image]="!previewImage"
                [class.field__preview_contain]="previewImage"
                [style.width.px]="180"
                [style.height.px]="120"
                [style.background-image]="previewImage ? ('url(' + previewImage + ')' | appSafeStyle) : null"
              ></div>
            </app-page-column>
          </app-page-columns>
        </div>
      </div>
    </app-page-block>

    <ng-container *ngIf="fileLoading">
      <app-page-block>
        <div class="field">
          <div class="field__label">Page</div>
          <div class="field__value">
            <div class="input input_fill input_disabled">
              <span [class.loading-animation]="true"><span class="stub-text">Value</span></span>
            </div>
          </div>
        </div>
      </app-page-block>

      <app-page-block>
        <div class="field">
          <div class="field__label">Artboard</div>
          <div class="field__value">
            <div class="input input_fill input_disabled">
              <span [class.loading-animation]="true"><span class="stub-text">Value</span></span>
            </div>
          </div>
        </div>
      </app-page-block>
    </ng-container>

    <ng-container *ngIf="!fileLoading && pageOptions && artboardOptions">
      <app-page-block>
        <app-auto-field
          [form]="form"
          [field]="
            createField({
              name: 'page',
              label: 'page',
              field: 'SelectField',
              placeholder: 'Choose Page',
              required: true,
              params: { options: pageOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div *ngIf="!form.controls.page.value" class="field">
          <div class="field__label">Artboard</div>
          <div class="field__value">
            <div class="input input_fill input_disabled">
              <span [class.loading-animation]="false"><span class="stub-text">Value</span></span>
            </div>
          </div>
        </div>

        <app-auto-field
          *ngIf="form.controls.page.value"
          [form]="form"
          [field]="
            createField({
              name: 'artboard',
              label: 'Artboard',
              field: 'SelectField',
              placeholder: 'Choose Artboard',
              required: true,
              params: { options: artboardOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>
    </ng-container>

    <app-page-block>
      <app-form-errors [form]="form"></app-form-errors>
    </app-page-block>

    <div class="choose-template__footer">
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup2__footer-item" (click)="cancel()">
            Cancel
          </button>

          <button
            type="button"
            class="button button_primary popup2__footer-item"
            [class.button_disabled]="!form.valid || importLoading"
            (click)="importFile()"
          >
            <app-loader-small *ngIf="importLoading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Import artboard</span>
          </button>
        </app-stack>
      </app-page-block>
    </div>
  </app-popup2>
</form>

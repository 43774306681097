/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./demo-widget.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../analytics/services/intercom/intercom.service";
import * as i5 from "../../../../common/popups/services/popup/popup.service";
var styles_DemoWidgetComponent = [];
var RenderType_DemoWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DemoWidgetComponent, data: {} });
export { RenderType_DemoWidgetComponent as RenderType_DemoWidgetComponent };
export function View_DemoWidgetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "demo-widget icon"], ["href", "javascript:void(0)"]], [[2, "icon-briefcase", null], [2, "icon-close", null], [2, "demo-widget_active", null], [2, "demo-widget_visible", null], [2, "demo-widget_shift", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleJobs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.jobsOpened; var currVal_1 = _co.jobsOpened; var currVal_2 = _co.jobsOpened; var currVal_3 = _co.jobs; var currVal_4 = i0.ɵunv(_v, 0, 4, i0.ɵnov(_v, 1).transform(_co.intercomService.visible$)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_DemoWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-demo-widget", [], null, null, null, View_DemoWidgetComponent_0, RenderType_DemoWidgetComponent)), i0.ɵdid(1, 245760, null, 0, i2.DemoWidgetComponent, [i3.CurrentProjectStore, i0.Injector, i0.ChangeDetectorRef, i4.IntercomService, i5.PopupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemoWidgetComponentNgFactory = i0.ɵccf("app-demo-widget", i2.DemoWidgetComponent, View_DemoWidgetComponent_Host_0, {}, {}, []);
export { DemoWidgetComponentNgFactory as DemoWidgetComponentNgFactory };

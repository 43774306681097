import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BasePopupComponent } from '@common/popups';

@Component({
  selector: 'app-simple-popup',
  templateUrl: './simple-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePopupComponent extends BasePopupComponent {}

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';

import { Color } from './color';

export enum BorderStyle {
  Solid = 'solid',
  Dashed = 'dashed',
  Dotted = 'dotted',
  Double = 'Double',
  Groove = 'groove',
  Ridge = 'ridge',
  Inset = 'inset',
  Outset = 'outset'
}

export enum BorderPosition {
  Inside = 'inside',
  Center = 'center',
  Outside = 'outside'
}

export class Border {
  color: Color;
  colorInput: Input;
  thickness = 1;
  style: BorderStyle = BorderStyle.Solid;
  position: BorderPosition = BorderPosition.Inside;
  enabled = true;
  enabledInput: Input;

  constructor(options: Partial<Border> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (isSet(data['thickness'])) {
      this.thickness = data['thickness'];
    }

    if (isSet(data['style'])) {
      this.style = data['style'];
    }

    if (isSet(data['position'])) {
      this.position = data['position'];
    }

    if (data['color']) {
      this.color = new Color().deserialize(data['color']);
    }

    if (data['color_input']) {
      this.colorInput = new Input().deserialize(data['color_input']);
    }

    if (isSet(data['enabled'])) {
      this.enabled = data['enabled'];
    }

    if (data['enabled_input']) {
      this.enabledInput = new Input().deserialize(data['enabled_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      color: this.color ? this.color.serialize() : undefined,
      color_input: this.colorInput ? this.colorInput.serialize() : null,
      thickness: this.thickness,
      style: this.style,
      position: this.position,
      enabled: this.enabled,
      enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
    };
  }

  cssColor$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    if (this.colorInput) {
      return applyParamInput$(this.colorInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });
    } else if (this.color) {
      return of(this.color.css());
    }
  }

  cssBorder$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    return combineLatest(this.cssColor$(options)).pipe(
      map(([color]) => {
        const result = [`${this.thickness}px`, this.style];

        if (isSet(color)) {
          result.push(color);
        }

        return result.join(' ');
      })
    );
  }

  cssTextStroke$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    return combineLatest(this.cssColor$(options)).pipe(
      map(([color]) => {
        const result = [`${this.thickness}px`];

        if (isSet(color)) {
          result.push(color);
        }

        return result.join(' ');
      })
    );
  }

  enabled$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<boolean> {
    if (this.enabledInput) {
      return applyBooleanInput$(this.enabledInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      }).pipe(catchError(() => of(false)));
    } else {
      return of(true);
    }
  }
}

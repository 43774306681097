import {
  FieldType,
  FormFieldSerialized,
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from '@modules/fields';

export const storageGetObjectsStructure = {
  type: JsonStructureNodeType.Array,
  name: null,
  label: undefined,
  params: {
    item: {
      type: JsonStructureNodeType.Object,
      name: null,
      label: undefined,
      params: {
        items: [
          {
            type: JsonStructureNodeType.Field,
            name: 'url',
            label: 'url',
            params: {
              field: FieldType.URL
            } as FormFieldSerialized
          },
          {
            type: JsonStructureNodeType.Field,
            name: 'path',
            label: 'path',
            params: {
              field: FieldType.Text
            } as FormFieldSerialized
          },
          {
            type: JsonStructureNodeType.Field,
            name: 'type',
            label: 'type',
            params: {
              field: FieldType.Text
            } as FormFieldSerialized
          }
        ] as JsonStructureNode[]
      } as JsonStructureObjectParams
    } as JsonStructureNode
  } as JsonStructureArrayParams
};

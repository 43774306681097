import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Token } from '@modules/tokens';

@Component({
  selector: 'app-input-tokens-list',
  templateUrl: './input-tokens-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTokensListComponent implements OnInit {
  @Input() title: string;
  @Input() items: Token[] = [];
  @Input() tokenValues = {};
  @Output() clickItem = new EventEmitter<Token>();

  constructor() {}

  ngOnInit() {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../query-builder-simple/query-builder-simple.component.ngfactory";
import * as i3 from "../query-builder-simple/query-builder-simple.component";
import * as i4 from "../query-builder-http/query-builder-http.component.ngfactory";
import * as i5 from "../query-builder-http/query-builder-http.form";
import * as i6 from "../query-builder-http/query-builder-http.component";
import * as i7 from "../../../queries/services/query/query.service";
import * as i8 from "../../../queries/services/http-query/http-query.service";
import * as i9 from "../../../../core/services/local-storage/local.storage";
import * as i10 from "../../../../common/notifications/services/notification/notification.service";
import * as i11 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i12 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i13 from "../query-builder-sql/query-builder-sql.component.ngfactory";
import * as i14 from "../query-builder-sql/query-builder-sql.form";
import * as i15 from "../query-builder-sql/query-builder-sql.component";
import * as i16 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i17 from "../../../ai/services/ai/ai.service";
import * as i18 from "../../../model-queries/stores/model-description.store";
import * as i19 from "../query-builder-object/query-builder-object.component.ngfactory";
import * as i20 from "../query-builder-object/query-builder-object.form";
import * as i21 from "../query-builder-object/query-builder-object.component";
import * as i22 from "../../../resources/services/firebase-resource-controller/firebase-resource-controller.service";
import * as i23 from "../../../../common/token/services/insert-token/insert-token.service";
import * as i24 from "./query-builder.form";
import * as i25 from "./query-builder.component";
import * as i26 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i27 from "../../../users/stores/current-user.store";
import * as i28 from "../../../projects/stores/current-project.store";
import * as i29 from "../../../api/services/api/api.service";
var styles_QueryBuilderComponent = [];
var RenderType_QueryBuilderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderComponent, data: {} });
export { RenderType_QueryBuilderComponent as RenderType_QueryBuilderComponent };
function View_QueryBuilderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "query-builder__tabs-item"], ["href", "javascript:void(0)"]], [[2, "query-builder__tabs-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.form.controls.query_type.patchValue(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "query-builder__tabs-item-inner"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.form.controls.query_type.value == _v.context.$implicit.value); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_1); }); }
function View_QueryBuilderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "query-builder__tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_QueryBuilderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.tabs == null) ? null : _co.tabs.length) > 1); _ck(_v, 1, 0, currVal_0); }, null); }
function View_QueryBuilderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_QueryBuilderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-query-builder-simple", [], null, [[null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } if (("canceled" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_QueryBuilderSimpleComponent_0, i2.RenderType_QueryBuilderSimpleComponent)), i0.ɵdid(1, 114688, null, 0, i3.QueryBuilderSimpleComponent, [], null, { saved: "saved", canceled: "canceled" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QueryBuilderComponent_5)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = i0.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_0); }, null); }
function View_QueryBuilderComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_QueryBuilderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-query-builder-http", [], null, [[null, "executed"], [null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("executed" === en)) {
        var pd_0 = (_co.onHttpExecuted($event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } if (("canceled" === en)) {
        var pd_2 = (_co.cancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_QueryBuilderHttpComponent_0, i4.RenderType_QueryBuilderHttpComponent)), i0.ɵprd(131584, null, i5.QueryBuilderHttpForm, i5.QueryBuilderHttpForm, []), i0.ɵdid(2, 770048, null, 0, i6.QueryBuilderHttpComponent, [i5.QueryBuilderHttpForm, i7.QueryService, i8.HttpQueryService, i9.LocalStorage, i10.NotificationService, i11.DialogService, i12.UniversalAnalyticsService, i0.ChangeDetectorRef], { resource: [0, "resource"], context: [1, "context"], control: [2, "control"], queryForm: [3, "queryForm"], requireResponse: [4, "requireResponse"], arrayResponse: [5, "arrayResponse"], options: [6, "options"], customSections: [7, "customSections"], parametersControl: [8, "parametersControl"], source: [9, "source"] }, { executed: "executed", saved: "saved", canceled: "canceled" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QueryBuilderComponent_7)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.context; var currVal_2 = _co.form.controls.http_query; var currVal_3 = _co.form; var currVal_4 = _co.requireResponse; var currVal_5 = _co.arrayResponse; var currVal_6 = _co.httpOptions; var currVal_7 = _co.customSections; var currVal_8 = _co.parametersControl; var currVal_9 = _co.source; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i0.ɵnov(_v.parent, 0); _ck(_v, 4, 0, currVal_10); }, null); }
function View_QueryBuilderComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_QueryBuilderComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-query-builder-sql", [], null, [[null, "executed"], [null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("executed" === en)) {
        var pd_0 = (_co.onSqlExecuted($event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } if (("canceled" === en)) {
        var pd_2 = (_co.cancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i13.View_QueryBuilderSqlComponent_0, i13.RenderType_QueryBuilderSqlComponent)), i0.ɵprd(512, null, i14.QueryBuilderSqlForm, i14.QueryBuilderSqlForm, []), i0.ɵdid(2, 770048, null, 0, i15.QueryBuilderSqlComponent, [i14.QueryBuilderSqlForm, i7.QueryService, i16.ResourceControllerService, i17.AiService, i18.ModelDescriptionStore, i10.NotificationService, i11.DialogService, i12.UniversalAnalyticsService, i0.ChangeDetectorRef], { resource: [0, "resource"], context: [1, "context"], control: [2, "control"], requireResponse: [3, "requireResponse"], arrayResponse: [4, "arrayResponse"], options: [5, "options"], customSections: [6, "customSections"], parametersControl: [7, "parametersControl"], source: [8, "source"] }, { executed: "executed", saved: "saved", canceled: "canceled" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_9)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.context; var currVal_2 = _co.form.controls.sql_query; var currVal_3 = _co.requireResponse; var currVal_4 = _co.arrayResponse; var currVal_5 = _co.sqlOptions; var currVal_6 = _co.customSections; var currVal_7 = _co.parametersControl; var currVal_8 = _co.source; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i0.ɵnov(_v.parent, 0); _ck(_v, 4, 0, currVal_9); }, null); }
function View_QueryBuilderComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_QueryBuilderComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-query-builder-object", [], null, [[null, "executed"], [null, "saved"], [null, "canceled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("executed" === en)) {
        var pd_0 = (_co.onObjectExecuted($event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } if (("canceled" === en)) {
        var pd_2 = (_co.cancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i19.View_QueryBuilderObjectComponent_0, i19.RenderType_QueryBuilderObjectComponent)), i0.ɵprd(512, null, i20.QueryBuilderObjectForm, i20.QueryBuilderObjectForm, []), i0.ɵdid(2, 245760, null, 0, i21.QueryBuilderObjectComponent, [i22.FirebaseResourceController, i20.QueryBuilderObjectForm, i16.ResourceControllerService, i12.UniversalAnalyticsService, i0.ChangeDetectorRef], { resource: [0, "resource"], context: [1, "context"], control: [2, "control"], requireResponse: [3, "requireResponse"], arrayResponse: [4, "arrayResponse"], options: [5, "options"], customSections: [6, "customSections"], parametersControl: [7, "parametersControl"], source: [8, "source"] }, { executed: "executed", saved: "saved", canceled: "canceled" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_11)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.context; var currVal_2 = _co.form.controls.object_query; var currVal_3 = _co.requireResponse; var currVal_4 = _co.arrayResponse; var currVal_5 = _co.objectOptions; var currVal_6 = _co.customSections; var currVal_7 = _co.parametersControl; var currVal_8 = _co.source; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i0.ɵnov(_v.parent, 0); _ck(_v, 4, 0, currVal_9); }, null); }
export function View_QueryBuilderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(0, [["tabs_template", 2]], null, 0, null, View_QueryBuilderComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_8)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderComponent_10)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.form.controls.query_type.value == _co.queryType.Simple); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.form.controls.query_type.value == _co.queryType.Http); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.form.controls.query_type.value == _co.queryType.SQL); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.form.controls.query_type.value == _co.queryType.Object); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_QueryBuilderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-query-builder", [], null, null, null, View_QueryBuilderComponent_0, RenderType_QueryBuilderComponent)), i0.ɵprd(135680, null, i23.InsertTokenService, i23.InsertTokenService, []), i0.ɵprd(512, null, i24.QueryBuilderForm, i24.QueryBuilderForm, []), i0.ɵdid(3, 245760, null, 0, i25.QueryBuilderComponent, [i24.QueryBuilderForm, i26.BasePopupComponent, i27.CurrentUserStore, i28.CurrentProjectStore, i29.ApiService, i7.QueryService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var QueryBuilderComponentNgFactory = i0.ɵccf("app-query-builder", i25.QueryBuilderComponent, View_QueryBuilderComponent_Host_0, { queryClass: "queryClass", queryTypes: "queryTypes", resource: "resource", context: "context", requireResponse: "requireResponse", arrayResponse: "arrayResponse", query: "query", currentUserTokens: "currentUserTokens", customSections: "customSections", parametersControl: "parametersControl", httpOptions: "httpOptions", sqlOptions: "sqlOptions", objectOptions: "objectOptions", source: "source" }, { result: "result" }, []);
export { QueryBuilderComponentNgFactory as QueryBuilderComponentNgFactory };

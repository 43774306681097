import { BehaviorSubject } from 'rxjs';
import { CookieStorage } from '@core';
import { CurrentProjectStore } from '../../../projects/stores/current-project.store';
var DemoBannerService = /** @class */ (function () {
    function DemoBannerService(currentProjectStore, cookieStorage) {
        var _this = this;
        this.currentProjectStore = currentProjectStore;
        this.cookieStorage = cookieStorage;
        this._enabled = new BehaviorSubject(false);
        this._visible = new BehaviorSubject(false);
        this._visible.next(this.cookieStorage.get('banner_visibility') != 'false');
        this.currentProjectStore.instance$.subscribe(function (project) {
            _this._enabled.next(project && project.demo);
        });
    }
    Object.defineProperty(DemoBannerService.prototype, "enabled", {
        get: function () {
            return this._enabled.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoBannerService.prototype, "enabled$", {
        get: function () {
            return this._enabled.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoBannerService.prototype, "visible", {
        get: function () {
            return this._visible.value;
        },
        set: function (value) {
            this._visible.next(value);
            this.cookieStorage.set('banner_visibility', value.toString());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DemoBannerService.prototype, "visible$", {
        get: function () {
            return this._visible.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return DemoBannerService;
}());
export { DemoBannerService };

<app-section [cls]="section">
  <app-page-header>
    <app-page-title>Create a {{ modelDescription.verboseName }}</app-page-title>
    <app-page-description>Creates a {{ modelDescription.verboseName }} object.</app-page-description>
  </app-page-header>

  <app-section-column>
    <app-header-parameters [parameters]="headerParameters" [loading]="headerParametersLoading"></app-header-parameters>

    <ng-container *ngIf="parameters.length">
      <app-page-block>
        <app-page-heading>Request parameters</app-page-heading>
      </app-page-block>

      <app-page-block>
        <div class="code-parameters">
          <div *ngFor="let item of parameters" class="code-parameters__item">
            <app-api-parameter [item]="item"></app-api-parameter>
          </div>
        </div>
      </app-page-block>

      <app-page-block [marginSize]="'l'">
        <hr />
      </app-page-block>
    </ng-container>

    <app-page-block>
      <app-page-heading>Returned result</app-page-heading>
      <app-page-description>
        Returns the {{ modelDescription.verboseName }} object if the create succeeded. Returns an error if create
        parameters are invalid.
      </app-page-description>
    </app-page-block>
  </app-section-column>

  <app-section-column>
    <div class="side-code side-code_sticky">
      <app-page-block>
        <div class="code-block">
          <div class="code-block-header">
            <div class="code-block-header__main">
              <span class="code-block-header__method color_green_1">POST</span>
              <span class="code-block-header__url">{{ getUrlPath() }}</span>
            </div>
            <div class="code-block-header__right">
              <div
                class="code-block-header__action icon-duplicate"
                (click)="copy(curl || getAbsoluteApiUrl(), 'CURL')"
              ></div>
            </div>
          </div>
          <div *ngIf="curl" class="code-block-content">
            <div class="code-block__code">{{ curl }}</div>
          </div>
        </div>
      </app-page-block>

      <app-page-block class="side-code__fill">
        <div class="code-block">
          <div class="code-block-header code-block-header_no-padding">
            <div class="code-block-header__main code-block-header__tabs">
              <div
                *ngFor="let item of dataTabs"
                class="code-block-header__tab"
                [class.code-block-header__tab_active]="item.name == currentDataTabName"
                (click)="setCurrentQueryName(item.name)"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="code-block-content">
            <div *ngIf="currentDataTabName == dataTabNames.Request" class="code-block__code">
              {{ getRequestDataStr() }}
            </div>

            <div *ngIf="currentDataTabName == dataTabNames.Response" class="code-block__code">
              {{ getResponseData() }}
            </div>
          </div>
        </div>
      </app-page-block>
    </div>
  </app-section-column>
</app-section>

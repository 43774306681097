import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { UserService } from '@modules/users';

@Injectable()
export class RestoreForm {
  form: FormGroup;
  email = new FormControl('', Validators.required);

  constructor(private formUtils: FormUtils, private fb: FormBuilder, private userService: UserService) {
    this.form = this.fb.group({
      email: this.email
    });
  }

  submit(): Observable<boolean> {
    this.form.markAsDirty();

    return this.userService.restore(this.form.value['email']).pipe(
      catchError(error => {
        this.formUtils.showFormErrors(this.form, error);
        return throwError(error);
      })
    );
  }
}

<div
  class="marketplace-card"
  [class.marketplace-card_interactive]="interactive"
  [class.marketplace-card_disabled]="item.active === false"
>
  <div class="marketplace-card__image">
    <div
      class="marketplace-card__image-inner"
      [style.background-image]="
        (item.thumbnail | appIsAbsoluteUrl) ? ('url(\'' + item.thumbnail + '\')' | appSafeStyle) : null
      "
    ></div>
  </div>
  <div class="marketplace-card__content">
    <div
      class="marketplace-icon marketplace-card__icon"
      [style.background-color]="(item.iconColor | appIsColorHex) ? item.iconColor : null"
      [ngClass]="[
        item.icon ? 'icon-' + item.icon : 'icon-canvas',
        !(item.iconColor | appIsColorHex) ? 'background-color_' + item.iconColor + '_1' : ''
      ]"
    ></div>

    <div class="marketplace-card__info">
      <div class="marketplace-card__title">{{ item.name }}</div>
      <div class="marketplace-card__subtitle">{{ item.shortDescription }}</div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-bar-input-edit/customize-bar-input-edit.component.ngfactory";
import * as i2 from "../../../customize-bar-input-edit/customize-bar-input-edit.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i5 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i6 from "../../../../../field-components/components/custom-select/custom-select.component.ngfactory";
import * as i7 from "../../../../../field-components/components/custom-select/custom-select.component";
import * as i8 from "./customize-bar-action-edit-type-set-property.component";
import * as i9 from "../../../../../projects/stores/project-property.store";
import * as i10 from "../../../../services/project-property-edit-controller/project-property-edit.controller";
var styles_CustomizeBarActionEditTypeSetPropertyComponent = [];
var RenderType_CustomizeBarActionEditTypeSetPropertyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeSetPropertyComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeSetPropertyComponent as RenderType_CustomizeBarActionEditTypeSetPropertyComponent };
function View_CustomizeBarActionEditTypeSetPropertyComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "field__errors"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "field__error"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["required"]))], null, null); }
function View_CustomizeBarActionEditTypeSetPropertyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-customize-bar-input-edit", [], null, null, null, i1.View_CustomizeBarInputEditComponent_0, i1.RenderType_CustomizeBarInputEditComponent)), i0.ɵdid(2, 770048, null, 0, i2.CustomizeBarInputEditComponent, [i0.ChangeDetectorRef], { label: [0, "label"], itemForm: [1, "itemForm"], context: [2, "context"], contextElement: [3, "contextElement"], contextElementPath: [4, "contextElementPath"], contextElementPaths: [5, "contextElementPaths"], staticValueField: [6, "staticValueField"], staticValueParams: [7, "staticValueParams"], fill: [8, "fill"], analyticsSource: [9, "analyticsSource"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CustomizeBarActionEditTypeSetPropertyComponent_2)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Value"; var currVal_1 = _co.form.controls.set_property_action_value; var currVal_2 = _co.context; var currVal_3 = _co.contextElement; var currVal_4 = _co.contextElementPath; var currVal_5 = _co.contextElementPaths; var currVal_6 = ((_co.currentProperty.field == null) ? null : _co.currentProperty.field.field); var currVal_7 = ((_co.currentProperty.field == null) ? null : _co.currentProperty.field.params); var currVal_8 = true; var currVal_9 = (_co.analyticsSource + "_set_property_action_value"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.form.controls.set_property_action_value.invalid && _co.form.dirty); _ck(_v, 4, 0, currVal_10); }, null); }
export function View_CustomizeBarActionEditTypeSetPropertyComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-sidebar-field", [], null, null, null, i4.View_SidebarFieldComponent_0, i4.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i5.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 3, "app-custom-select", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.onButtonItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CustomSelectComponent_0, i6.RenderType_CustomSelectComponent)), i0.ɵdid(4, 770048, null, 0, i7.CustomSelectComponent, [i0.ChangeDetectorRef], { control: [0, "control"], items: [1, "items"], classes: [2, "classes"], panelClasses: [3, "panelClasses"], capitalize: [4, "capitalize"] }, { buttonClick: "buttonClick" }), i0.ɵpad(5, 1), i0.ɵpad(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeSetPropertyComponent_1)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Variable"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form.controls.set_property_action_property; var currVal_2 = _co.propertyOptions; var currVal_3 = _ck(_v, 5, 0, "select_fill"); var currVal_4 = _ck(_v, 6, 0, "mat-menu-panel_sidebar"); var currVal_5 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.currentProperty; _ck(_v, 8, 0, currVal_6); }, null); }
export function View_CustomizeBarActionEditTypeSetPropertyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-set-property", [], null, null, null, View_CustomizeBarActionEditTypeSetPropertyComponent_0, RenderType_CustomizeBarActionEditTypeSetPropertyComponent)), i0.ɵdid(1, 245760, null, 0, i8.CustomizeBarActionEditTypeSetPropertyComponent, [i9.ProjectPropertyStore, i10.ProjectPropertyEditController, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeSetPropertyComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-set-property", i8.CustomizeBarActionEditTypeSetPropertyComponent, View_CustomizeBarActionEditTypeSetPropertyComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeSetPropertyComponentNgFactory as CustomizeBarActionEditTypeSetPropertyComponentNgFactory };

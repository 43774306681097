import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';

const menuItems = {};

export function getMenuItemByType(type: MenuItemType): any {
  return menuItems[type];
}

export function registerMenuItemForType(type: MenuItemType, cls: any) {
  menuItems[type] = cls;
}

export function deserializeMenuItem(item: Object): MenuItem {
  if (item['type'] == 'page' || item['type'] == 'link') {
    item['type'] = MenuItemType.Simple;
  }

  const menuItemCls = getMenuItemByType(item['type']);

  if (!menuItemCls) {
    return;
  }

  return new menuItemCls().deserialize(item);
}

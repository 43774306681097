import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, publishLast, refCount, retry, switchMap, timeout } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import { Region } from '../../data/region';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/app-config/app-config.service";
var RegionService = /** @class */ (function () {
    function RegionService(http, apiService, appConfigService) {
        this.http = http;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
    }
    RegionService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('regions/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Region().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RegionService.prototype.getDefault = function () {
        return this.get().pipe(map(function (result) { return result.find(function (item) { return item.default; }); }));
    };
    RegionService.prototype.getDefaultJetBridge = function () {
        if (this.appConfigService.jetBridgeRegions) {
            return this.getDefault().pipe(map(function (result) {
                if (!result) {
                    return;
                }
                return {
                    region: result,
                    url: result.jetBridgeCloudUrl
                };
            }));
        }
        else {
            return of({
                url: this.appConfigService.jetBridgeCloudBaseUrl
            });
        }
    };
    RegionService.prototype.getDetail = function (uid) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("regions/" + uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new Region().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    RegionService.prototype.getLatency = function (region, timeoutMs, retries) {
        if (timeoutMs === void 0) { timeoutMs = 10000; }
        if (retries === void 0) { retries = 1; }
        var url = region.jetBridgeCloudUrl;
        var startFrom = new Date().getTime();
        return this.http.get(url).pipe(map(function () {
            return new Date().getTime() - startFrom;
        }), timeout(timeoutMs), retry(retries), catchError(function () { return of(undefined); }), publishLast(), refCount());
    };
    RegionService.ngInjectableDef = i0.defineInjectable({ factory: function RegionService_Factory() { return new RegionService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.AppConfigService)); }, token: RegionService, providedIn: "root" });
    return RegionService;
}());
export { RegionService };

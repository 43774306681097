import { EventEmitter, Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, skip, switchMap, tap } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { Frame, isContainerLayer, Layer, Size, Translate } from '@modules/views';
import { elementResize$, TypedChanges } from '@shared';

import {
  CreatedLayer,
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';

export abstract class LayerComponent<T extends Layer = Layer> implements OnInit, OnChanges {
  @Input() layer: T;
  @Input() layerUpdated$: Observable<T>;
  @Input() container: Layer[];
  @Input() translate: Translate = { x: 0, y: 0 };
  @Input() createdLayer: CreatedLayer;
  @Input() customizeEnabled = false;
  @Input() viewContext: ViewContext;
  @Input() analyticsSource: string;
  @Output() layerCustomizeMouseEnter = new EventEmitter<void>();
  @Output() layerCustomizeMouseLeave = new EventEmitter<void>();
  @Output() layerCustomize = new EventEmitter<void>();
  @Output() layerAddCustomizing = new EventEmitter<void>();
  @Output() layerRemoveCustomizing = new EventEmitter<void>();
  @Output() layerDelete = new EventEmitter<void>();
  @Output() updateFrame = new EventEmitter<Partial<Frame>>();

  layer$ = new BehaviorSubject<T>(undefined);
  updating$: Observable<boolean>;
  createTool = false;

  constructor(@Optional() public editorContext: ViewEditorContext) {}

  ngOnInit(): void {
    this.layer$.next(this.layer);
  }

  ngOnChanges(changes: TypedChanges<LayerComponent>): void {
    if (changes.layer) {
      this.layer$.next(this.layer);
    }
  }

  getLayer$(): Observable<T> {
    const obs$: Observable<T>[] = [this.layer$];

    if (this.customizeEnabled) {
      obs$.push(this.layerUpdated$);
    }

    return merge(...obs$);
  }

  getLayerUpdating$(predicate?: () => boolean): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const subscription = this.getLayer$()
        .pipe(
          skip(1),
          filter(() => !predicate || predicate()),
          tap(() => observer.next(true)),
          debounceTime(2000)
        )
        .subscribe(() => observer.next(false));

      return () => {
        subscription.unsubscribe();
      };
    }).pipe(shareReplay(1));
  }

  trackLayerFluidSize(boundsElement: HTMLElement): Observable<void> {
    return this.getLayer$().pipe(
      filter(layer => layer.widthFluid || layer.heightFluid),
      switchMap<Layer, Size>(() => {
        return elementResize$(boundsElement).pipe(
          filter(e => !e.initial),
          map(() => {
            return {
              width: boundsElement.offsetWidth,
              height: boundsElement.offsetHeight
            };
          })
        );
      }),
      filter(size => !isEqual(size, this.layer.frame.getSize())),
      map(size => {
        if (this.layer.widthFluid) {
          this.layer.frame.width = size.width;
        }

        if (this.layer.heightFluid) {
          this.layer.frame.height = size.height;
        }

        this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Layer);
      })
    );
  }

  trackLayerChildrenFrames(predicate?: (layer: T) => boolean): Observable<unknown> {
    return merge(
      this.getLayer$().pipe(
        map(layer => {
          return isContainerLayer(layer) ? layer.layers : [];
        })
      ),
      this.editorContext.layerContainerChanged$().pipe(
        filter(event => {
          if (isContainerLayer(this.layer)) {
            return this.layer.layers === event.container && event.source != ViewEditorCustomizeSource.Layer;
          } else {
            return false;
          }
        }),
        map(event => event.container)
      )
    ).pipe(
      distinctUntilChanged((lhs, rhs) => {
        const lhsLayers = lhs.map(item => item.id);
        const rhsLayers = rhs.map(item => item.id);
        return isEqual(lhsLayers, rhsLayers);
      }),
      switchMap(layers => {
        if (!layers.length) {
          return of([]);
        }

        return combineLatest(
          ...layers.map(layer => {
            return merge(
              of(layer),
              this.editorContext.layerChanged$().pipe(
                filter(event => {
                  return (
                    event.layer.isSame(layer) &&
                    ![ViewEditorCustomizeSource.ParentLayer, ViewEditorCustomizeSource.AutoLayerBounds].includes(
                      event.source
                    )
                  );
                }),
                filter(() => !this.editorContext.movingLayer$.value && !this.editorContext.resizingLayer$.value),
                map(event => event.layer)
              )
            ).pipe(
              map(value => {
                const nestedContainer = isContainerLayer(this.layer)
                  ? this.editorContext.getContainer(this.layer.layers)
                  : undefined;
                const translate: Translate = nestedContainer ? nestedContainer.options.translate : { x: 0, y: 0 };

                const x = value.frame.x + translate.x;
                const y = value.frame.y + translate.y;

                return {
                  x: x,
                  y: y,
                  size: value.frame.getSize()
                };
              }),
              distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs))
            );
          })
        ).pipe(debounceTime(0));
      }),
      filter(() => !predicate || predicate(this.layer))
    );
  }

  onFrameUpdate(frame: Partial<Frame>) {
    this.updateFrame.emit(frame);
  }

  onMouseEnter() {
    if (this.editorContext) {
      this.layerCustomizeMouseEnter.emit();
    }
  }

  onMouseLeave() {
    if (this.editorContext) {
      this.layerCustomizeMouseLeave.emit();
    }
  }

  onMouseUp() {
    this.createTool = this.editorContext ? this.editorContext.isCreateTool() : undefined;
  }

  onMouseClick(e: MouseEvent) {
    const navigateMode = this.editorContext ? this.editorContext.navigateMode$.value : undefined;

    if (this.customizeEnabled && !this.createTool && !navigateMode) {
      e.stopPropagation();

      if (e.shiftKey) {
        if (this.editorContext.isCustomizingLayer(this.layer) && this.editorContext.isCustomizingMultipleLayers()) {
          this.layerRemoveCustomizing.emit();
        } else {
          this.layerAddCustomizing.emit();
        }
      } else {
        if (!this.editorContext.isCustomizingLayer(this.layer)) {
          this.layerCustomize.emit();
        }
      }
    }
  }
}

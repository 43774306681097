import { LANGUAGE_KEY } from '@core';
import { environment } from '@env/environment';
import { format, getNavigatorLanguages, isSet } from '@shared';

import { DEFAULT_LANGUAGE, localeReplaceKey, localesReplaces } from '../data/locales';

export interface LocalizeOptions {
  args?: any[];
  context?: string;
}

export function getCurrentLanguage(): string {
  const languages = [
    ...(isSet(localStorage[LANGUAGE_KEY]) ? [localStorage[LANGUAGE_KEY]] : []),
    ...getNavigatorLanguages()
  ];
  return languages.find(item => item == DEFAULT_LANGUAGE || localesReplaces.hasOwnProperty(item)) || DEFAULT_LANGUAGE;
}

function getLocaleReplace(
  label: any,
  options: {
    context?: string;
  } = {}
): string {
  const language = getCurrentLanguage();

  if (!language || language == DEFAULT_LANGUAGE) {
    return label;
  }

  const key = localeReplaceKey(label, { context: options.context });

  if (!localesReplaces[language].hasOwnProperty(key)) {
    if (!environment.production) {
      console.debug('No localize replace found for:');
      console.debug(key);
    }

    return label;
  }

  return localesReplaces[language][key];
}

export function localizeAdv(label: any, options: LocalizeOptions = {}): string {
  const result = getLocaleReplace(label, { context: options.context });
  return format(result, options.args);
}

export function localize(label: any, args?: any[]): string {
  return localizeAdv(label, { args: args });
}

import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PopoverService } from '@common/popover';
import { RawListViewSettingsColumn } from '@modules/customize';
import { Input as FieldInput, ParameterField } from '@modules/fields';
import { addFilterItemToQueryParams } from '@modules/filter-utils';
import { FilterItem } from '@modules/filters';
import { AddFilterPopupComponent } from '@modules/filters-edit';
import { ModelContext } from '@modules/models';

// TODO: Remove unused
@Injectable()
export class ListFiltersController {
  constructor(private popoverService: PopoverService, private injector: Injector) {}

  applyFilterItem(options: {
    params: Object;
    columns: RawListViewSettingsColumn[];
    parameters: ParameterField[];
    inputs: FieldInput[];
    field?: string;
    value?: FilterItem;
    context?: ModelContext;
    sourceElement?: HTMLElement;
  }): Observable<FilterItem> {
    const result = new ReplaySubject<FilterItem>();

    this.popoverService.create(
      {
        component: AddFilterPopupComponent,
        inputs: {
          columns: options.columns,
          parameters: options.parameters,
          inputs: options.inputs,
          field: options.field,
          context: options.context
        },
        outputs: {
          added: [(item: FilterItem) => result.next(item)]
        },
        injector: this.injector
      },
      options.sourceElement
    );

    return result.asObservable();
  }

  addFilter(options: {
    params: Object;
    columns: RawListViewSettingsColumn[];
    parameters: ParameterField[];
    inputs: FieldInput[];
    field?: string;
    value?: FilterItem;
    context?: ModelContext;
    sourceElement?: HTMLElement;
  }): Observable<Object> {
    return this.applyFilterItem(options).pipe(
      map(filterItem => {
        return addFilterItemToQueryParams(options.params, filterItem);
      })
    );
  }
}

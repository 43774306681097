var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { deserializeUserActivity } from '../../data/deserialize-user-activity';
import { UserActivity } from '../../data/user-activity';
import { UserActivityGrouped } from '../../data/user-activity-grouped';
import { UserActivityType } from '../../data/user-activity-type';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../centrifugo/services/centrifugo/centrifugo.service";
import * as i4 from "../../../../core/services/custom-http-parameter-codec/custom-http-parameter-codec";
var UserActivityService = /** @class */ (function () {
    function UserActivityService(http, apiService, centrifugoService, parameterCodec) {
        this.http = http;
        this.apiService = apiService;
        this.centrifugoService = centrifugoService;
        this.parameterCodec = parameterCodec;
    }
    UserActivityService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params, encoder: _this.parameterCodec });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new UserActivityService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserActivityService.prototype.group = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/group/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new UserActivityService.GroupResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserActivityService.prototype.subscribeUserActivities = function (projectName, environmentName, objectType, objectId) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$user_activity', projectName, environmentName, objectType, objectId].join('/');
            return _this.centrifugoService.subscribe(channel).pipe(filter(function (msg) { return msg.data['type'] == 'user_activity'; }), map(function (msg) { return deserializeUserActivity(msg.data['user_activity']); }));
        }));
    };
    UserActivityService.prototype.createInstance = function (projectName, environmentName, instance, mentions) {
        var _this = this;
        if (mentions === void 0) { mentions = []; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/');
            var headers = new HttpHeaders();
            var data = __assign({}, instance.serialize(), { mentions: mentions });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserActivityService.prototype.create = function (projectName, environmentName, activityType, objectType, objectId, viewSettings, params) {
        var instance = new UserActivity();
        instance.activityType = activityType;
        instance.objectType = objectType;
        instance.objectId = objectId;
        instance.viewSettings = viewSettings;
        instance.params = params;
        return this.createInstance(projectName, environmentName, instance);
    };
    UserActivityService.prototype.currentProjectCreateModel = function (project, environment, activity, modelId, params) {
        var objectType = ['model', modelId].join('.');
        return this.create(project.uniqueName, environment.uniqueName, activity, objectType, undefined, undefined, params);
    };
    UserActivityService.prototype.currentProjectCreateModelInstance = function (project, environment, activity, modelId, id, viewSettings, params) {
        var objectType = ['model', modelId].join('.');
        return this.create(project.uniqueName, environment.uniqueName, activity, objectType, id, viewSettings, params);
    };
    UserActivityService.prototype.currentProjectCreateDashboardInstance = function (project, environment, activity, id, params) {
        return this.create(project.uniqueName, environment.uniqueName, activity, 'dashboard', id, undefined, params);
    };
    UserActivityService.prototype.logModelCreate = function (project, environment, modelDescription, options) {
        if (options === void 0) { options = {}; }
        return this.currentProjectCreateModelInstance(project, environment, UserActivityType.ModelCreate, modelDescription.modelId, options.id, options.viewSettings, {
            resource: modelDescription.resource,
            model: modelDescription.model,
            id: options.id,
            instance: options.data
        });
    };
    UserActivityService.prototype.logModelUpdate = function (project, environment, modelDescription, id, options) {
        if (options === void 0) { options = {}; }
        return this.currentProjectCreateModelInstance(project, environment, UserActivityType.ModelUpdate, modelDescription.modelId, id, options.viewSettings, {
            resource: modelDescription.resource,
            model: modelDescription.model,
            id: id,
            changes: options.changes
        });
    };
    UserActivityService.prototype.logModelDelete = function (project, environment, modelDescription, options) {
        if (options === void 0) { options = {}; }
        return this.currentProjectCreateModelInstance(project, environment, UserActivityType.ModelDelete, modelDescription.modelId, options.id || '', options.viewSettings, {
            resource: modelDescription.resource,
            model: modelDescription.model,
            id: options.id,
            ids: options.ids,
            instance: options.data
        });
    };
    UserActivityService.ngInjectableDef = i0.defineInjectable({ factory: function UserActivityService_Factory() { return new UserActivityService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CentrifugoService), i0.inject(i4.CustomHttpParameterCodec)); }, token: UserActivityService, providedIn: "root" });
    return UserActivityService;
}());
export { UserActivityService };
(function (UserActivityService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
            this.perPage = 20;
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return deserializeUserActivity(item); });
            this.hasMore = !!data['next'];
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        Object.defineProperty(GetResponse.prototype, "numPages", {
            get: function () {
                return Math.ceil(this.count / this.perPage);
            },
            enumerable: true,
            configurable: true
        });
        return GetResponse;
    }());
    UserActivityService.GetResponse = GetResponse;
    var GroupResponse = /** @class */ (function () {
        function GroupResponse() {
        }
        GroupResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new UserActivityGrouped().deserialize(item); });
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        return GroupResponse;
    }());
    UserActivityService.GroupResponse = GroupResponse;
})(UserActivityService || (UserActivityService = {}));

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../color-picker/color-picker.component.ngfactory";
import * as i3 from "../color-picker/color-picker.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i7 from "../../../../shared/pipes/is-color-hex/is-color-hex.pipe";
import * as i8 from "./color-options.component";
var styles_ColorOptionsComponent = [];
var RenderType_ColorOptionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorOptionsComponent, data: {} });
export { RenderType_ColorOptionsComponent as RenderType_ColorOptionsComponent };
function View_ColorOptionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "color-selector2__item"]], [[2, "color-selector2__item_contrast", null], [4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.customColorEnabledControl.patchValue(false);
        var pd_0 = (_co.colorControl.patchValue(_co.resetValue) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1), i0.ɵpad(4, 2), i0.ɵppd(5, 1), i0.ɵppd(6, 1), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "color-selector2__item-border"]], [[2, "color-selector2__item-border_active", null]], null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "color-selector2__item"; var currVal_3 = _ck(_v, 4, 0, (!i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.resetBackgroundColor)) ? "icon-close" : ""), ((_co.resetBackgroundColor && !i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.resetBackgroundColor))) ? (("background-color_" + _co.resetBackgroundColor) + "_1") : "")); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_5 = "color-selector2__item-border"; var currVal_6 = (_co.resetBorderColor ? (("border-color_" + _co.resetBorderColor) + "_1") : ""); _ck(_v, 8, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 0, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.resetBackgroundColor)); var currVal_1 = (i0.ɵunv(_v, 0, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 1), _co.resetBackgroundColor)) ? _co.resetBackgroundColor : null); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = (!_co.customColorEnabledControl.value && (_co.colorControl.value == _co.resetValue)); _ck(_v, 7, 0, currVal_4); }); }
function View_ColorOptionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "color-selector2__item"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.customColorEnabledControl.patchValue(false);
        var pd_0 = (_co.colorControl.patchValue(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(2, 1), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "color-selector2__item-border"]], [[2, "color-selector2__item-border_active", null], [4, "border-color", null]], null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(6, 1), i0.ɵppd(7, 1)], function (_ck, _v) { var currVal_1 = "color-selector2__item"; var currVal_2 = (!i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.color)) ? (("background-color_" + _v.context.$implicit.color) + "_1") : ""); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_5 = "color-selector2__item-border"; var currVal_6 = (!i0.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.color)) ? (("border-color_" + _v.context.$implicit.color) + "_1") : ""); _ck(_v, 5, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.color)) ? _v.context.$implicit.color : null); _ck(_v, 0, 0, currVal_0); var currVal_3 = (!_co.customColorEnabledControl.value && (_co.colorControl.value === _v.context.$implicit.value)); var currVal_4 = (i0.ɵunv(_v, 4, 1, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 1), _v.context.$implicit.color)) ? _v.context.$implicit.color : null); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
function View_ColorOptionsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "popover2__inner popover2__inner_no-margin-collapse"]], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-color-picker", [], null, null, null, i2.View_ColorPickerComponent_0, i2.RenderType_ColorPickerComponent)), i0.ɵdid(3, 245760, null, 0, i3.ColorPickerComponent, [i0.ChangeDetectorRef], { hexControl: [0, "hexControl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customColorControl; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = 295; _ck(_v, 1, 0, currVal_0); }); }
function View_ColorOptionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 11, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setColorOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setColorOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ColorOptionsComponent_4)), i0.ɵdid(2, 671744, null, 0, i4.CdkConnectedOverlay, [i4.Overlay, i0.TemplateRef, i0.ViewContainerRef, i4.ɵc, [2, i5.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpod(3, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(4, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(5, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(6, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(7, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(8, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(9, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(10, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpad(11, 8), i0.ɵpad(12, 2), (_l()(), i0.ɵeld(13, 0, null, null, 2, "div", [["class", "color-selector2__item color-selector2__item_palette"]], [[2, "color-selector2__item_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["cdkOverlayOrigin", ""], ["class", "color-selector2__item-border border-color_blue_1"]], [[2, "color-selector2__item-border_active", null], [4, "border-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCustomColorClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, [["color_trigger", 4]], 0, i4.CdkOverlayOrigin, [i0.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 15); var currVal_1 = _ck(_v, 11, 0, _ck(_v, 3, 0, "start", "start", "bottom", "top", (0 - 8), (0 - 8)), _ck(_v, 4, 0, "center", "center", "bottom", "top", 0, (0 - 8)), _ck(_v, 5, 0, "end", "end", "bottom", "top", 8, (0 - 8)), _ck(_v, 6, 0, "start", "start", "top", "bottom", (0 - 8), 8), _ck(_v, 7, 0, "center", "center", "top", "bottom", 0, 8), _ck(_v, 8, 0, "end", "end", "top", "bottom", 8, 8), _ck(_v, 9, 0, "end", "start", "center", "center", 0, 0), _ck(_v, 10, 0, "start", "end", "center", "center", 0, 0)); var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 12, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.colorOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = (!_co.colorControl.disabled && _co.customColorControl.disabled); _ck(_v, 13, 0, currVal_8); var currVal_9 = _co.customColorEnabledControl.value; var currVal_10 = _co.customColorControl.value; _ck(_v, 14, 0, currVal_9, currVal_10); }); }
export function View_ColorOptionsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.IsSetPipe, []), i0.ɵpid(0, i7.IsColorHexPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "color-selector2"]], [[2, "color-selector2_disabled", null]], null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorOptionsComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorOptionsComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorOptionsComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "color-selector2"; var currVal_2 = ("color-selector2_size_" + _co.size); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.resetEnabled; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.colors; _ck(_v, 7, 0, currVal_4); var currVal_5 = (_co.customColorEnabledControl && _co.customColorControl); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorControl.disabled; _ck(_v, 2, 0, currVal_0); }); }
export function View_ColorOptionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-options", [], null, null, null, View_ColorOptionsComponent_0, RenderType_ColorOptionsComponent)), i0.ɵdid(1, 245760, null, 0, i8.ColorOptionsComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorOptionsComponentNgFactory = i0.ɵccf("app-color-options", i8.ColorOptionsComponent, View_ColorOptionsComponent_Host_0, { resetEnabled: "resetEnabled", resetBackgroundColor: "resetBackgroundColor", resetBorderColor: "resetBorderColor", resetValue: "resetValue", colors: "colors", colorControl: "colorControl", customColorEnabledControl: "customColorEnabledControl", customColorControl: "customColorControl", size: "size" }, {}, []);
export { ColorOptionsComponentNgFactory as ColorOptionsComponentNgFactory };

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user/user.service';

@Injectable()
export class UserSelectSource extends SelectSource {
  private page = 1;
  private totalPages = 1;

  constructor(private userService: UserService) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.userService.get(searchQuery).pipe(
      map(result => {
        this.page += 1;

        return result.map(item => {
          return {
            value: item,
            name: `${item.firstName} - ${item.email}`
          };
        });
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value || !value['model']) {
      return of(undefined);
    }

    return this.userService.get(value['email']).pipe(
      map(result => {
        const item = result.find(i => i.email == value['email']);

        if (!item) {
          return;
        }

        return {
          value: item,
          name: `${item.firstName} - ${item.email}`
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';

import { GuideTask } from '../../../data/guide-task';
import { GuidePopupService } from '../../../services/guide-popup/guide-popup.service';
import { GuideService } from '../../../services/guide/guide.service';

@Component({
  selector: 'app-guide-popup-task',
  templateUrl: './guide-popup-task.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuidePopupTaskComponent implements OnInit, OnDestroy {
  @Input() task: GuideTask;

  constructor(
    private guidePopupService: GuidePopupService,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService,
    private guideService: GuideService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  openDocumentation() {
    this.guidePopupService.openDocumentation(this.task.documentation);
  }

  cancelTask() {
    this.dialogService
      .confirm({
        title: 'Cancel Task',
        description: 'Are you sure want to cancel current task?'
      })
      .pipe(untilDestroyed(this))
      .subscribe(confirmed => {
        if (!confirmed) {
          return;
        }

        this.guideService.clearCurrentTask();
        this.guidePopupService.close();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.TutorialCancelTask, {
          TaskID: this.task.uniqueName
        });
      });
  }
}

import { EditableField } from './base-field';
import { DefaultType } from './default';
import { FieldType } from './field-type';
import { FieldDescription, getFieldDescriptionByType, parseFieldType } from './fields';

export class FieldOutput implements EditableField {
  public name: string;
  public verboseName: string;
  public description: string;
  public icon: string;
  public field: FieldType;
  public required = false;
  public defaultType: DefaultType = undefined;
  public defaultValue: string;
  public params = {};
  public fieldDescription: FieldDescription;

  deserialize(data: Object): FieldOutput {
    this.name = data['name'];
    this.verboseName = data['verbose_name'] || this.name;
    this.description = data['description'];
    this.field = parseFieldType(data['field']);
    this.required = data['required'];
    this.defaultValue = data['default_value'];

    if (data['default_type']) {
      this.defaultType = data['default_type'];
    }

    if (data['params']) {
      this.params = data['params'];
    }

    this.updateFieldDescription();

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      verbose_name: this.verboseName,
      description: this.description,
      field: this.field,
      required: this.required,
      default_type: this.defaultType,
      default_value: this.defaultValue,
      params: this.params
    };
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-route',
  templateUrl: './login-route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginRouteComponent implements OnInit, OnDestroy {
  ngOnInit() {}

  ngOnDestroy(): void {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import range from 'lodash/range';
import { map } from 'rxjs/operators';
import { controlValue, isSet } from '@shared';
import { ParameterValueControl } from './parameter-value.control';
var ParameterValueArray = /** @class */ (function (_super) {
    __extends(ParameterValueArray, _super);
    function ParameterValueArray(controls) {
        if (controls === void 0) { controls = []; }
        var _this = _super.call(this, controls) || this;
        _this._group = new FormGroup({});
        return _this;
    }
    ParameterValueArray.prototype.patchValue = function (value, options) {
        this.deserialize(value);
    };
    ParameterValueArray.prototype.setValue = function (value, options) {
        this.deserialize(value);
    };
    Object.defineProperty(ParameterValueArray.prototype, "value", {
        get: function () {
            return this.serialize();
        },
        set: function (value) { },
        enumerable: true,
        configurable: true
    });
    ParameterValueArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.deserialize(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    ParameterValueArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); }).filter(function (item) { return isSet(item.name); });
    };
    ParameterValueArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    ParameterValueArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    ParameterValueArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    ParameterValueArray.prototype.createControl = function (item, value) {
        var control = new ParameterValueControl();
        if (item) {
            control.deserialize(item);
        }
        if (value) {
            control.patchValue(value);
            control.markAsPristine();
        }
        return control;
    };
    ParameterValueArray.prototype.prependControl = function (item, value) {
        var control = this.createControl(item, value);
        this.insert(0, control);
        return control;
    };
    ParameterValueArray.prototype.appendControl = function (item, value) {
        var control = this.createControl(item, value);
        this.push(control);
        return control;
    };
    ParameterValueArray.prototype.getGroup$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () {
            _this.controls
                .filter(function (control) { return !_this._group.contains(control.controls.name.value); })
                .forEach(function (control) { return _this._group.addControl(control.controls.name.value, control.controls.value); });
            keys(_this._group.controls)
                .filter(function (name) { return !_this.controls.find(function (control) { return control.controls.name.value == name; }); })
                .forEach(function (name) { return _this._group.removeControl(name); });
            return _this._group;
        }));
    };
    return ParameterValueArray;
}(FormArray));
export { ParameterValueArray };

import { Injectable, Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of, Subject } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { BaseField, DisplayField, FieldDisplaySettings } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';

import { CustomizeBarModelFieldEditComponent } from '../../components/customize-bar-model-field-edit/customize-bar-model-field-edit.component';
import { CustomizeBarResourceFieldEditComponent } from '../../components/customize-bar-resource-field-edit/customize-bar-resource-field-edit.component';
import { CustomizeBarContext } from '../customize-bar-context/customize-bar.context';

export interface ModelFieldEditResult {
  result?: ModelDescription;
  cancelled?: boolean;
}

@Injectable()
export class ModelFieldEditController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceControllerService: ResourceControllerService,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private analyticsService: UniversalAnalyticsService
  ) {}

  createModelField(options: {
    injector: Injector;
    customizeBarContext: CustomizeBarContext;
    resource: Resource;
    modelDescription: ModelDescription;
    defaults?: BaseField;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextElementPath?: (string | number)[];
    contextElementPaths?: (string | number)[][];
    trackConfigured?: boolean;
    firstInit?: boolean;
    analyticsSource?: string;
    append?: boolean;
    modal?: boolean;
  }) {
    const result = new Subject<ModelFieldEditResult>();
    const controller = this.resourceControllerService.get(options.resource.type);
    const resourceField =
      controller.supportModelDescriptionManagement(options.resource) && !options.modelDescription.virtual;
    const resourceFieldCreate = resourceField && !!controller.modelDescriptionFieldCreate;

    if (options.defaults instanceof DisplayField) {
      options.customizeBarContext.setSettingsComponent(
        {
          component: CustomizeBarModelFieldEditComponent,
          inputs: {
            resource: options.resource,
            modelDescription: options.modelDescription,
            defaults: options.defaults,
            context: options.context,
            contextElement: options.contextElement,
            contextElementPath: options.contextElementPath,
            contextElementPaths: options.contextElementPaths,
            analyticsSource: options.analyticsSource,
            trackConfigured: options.trackConfigured,
            firstInit: options.firstInit
          },
          outputs: {
            saved: [
              modelDescription => {
                result.next({
                  result: modelDescription
                });
              }
            ],
            cancelled: [
              () => {
                result.next({
                  cancelled: true
                });
              }
            ]
          },
          injector: options.injector,
          modal: options.modal
        },
        { append: options.append }
      );
    } else if (resourceFieldCreate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldStartedToSetUp, {
        ResourceTypeID: options.resource.typeItem.name,
        ResourceID: options.modelDescription.resource,
        CollectionID: options.modelDescription.model,
        Create: true,
        Source: options.analyticsSource
      });

      options.customizeBarContext.setSettingsComponent(
        {
          component: CustomizeBarResourceFieldEditComponent,
          inputs: {
            resource: options.resource,
            modelDescription: options.modelDescription,
            defaults: options.defaults,
            context: options.context,
            contextElement: options.contextElement,
            analyticsSource: options.analyticsSource
          },
          outputs: {
            saved: [
              modelDescription => {
                result.next({
                  result: modelDescription
                });
              }
            ],
            cancelled: [
              () => {
                result.next({
                  cancelled: true
                });
              }
            ]
          },
          injector: options.injector,
          modal: options.modal
        },
        { append: options.append, modal: options.modal }
      );
    } else {
      return of({});
    }

    return result.pipe(
      tap(e => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
          Type: 'data'
        });

        if (e.result) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldSuccessfullySetUp, {
            ResourceTypeID: options.resource.typeItem.name,
            ResourceID: options.modelDescription.resource,
            CollectionID: options.modelDescription.model,
            Create: true,
            Source: options.analyticsSource
          });
        } else if (e.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldSetUpCancelled, {
            ResourceTypeID: options.resource.typeItem.name,
            ResourceID: options.modelDescription.resource,
            CollectionID: options.modelDescription.model,
            Create: true,
            Source: options.analyticsSource
          });
        }
      })
    );
  }

  editModelField(options: {
    injector: Injector;
    customizeBarContext: CustomizeBarContext;
    resource: Resource;
    modelDescription: ModelDescription;
    field: ModelField;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextElementPath?: (string | number)[];
    contextElementPaths?: (string | number)[][];
    analyticsSource?: string;
    trackConfigured?: boolean;
    firstInit?: boolean;
    append?: boolean;
    modal?: boolean;
  }) {
    const result = new Subject<ModelFieldEditResult>();
    const controller = this.resourceControllerService.get(options.resource.type);
    const resourceField =
      controller.supportModelDescriptionManagement(options.resource) && !options.modelDescription.virtual;
    const resourceFieldUpdate = resourceField && !!controller.modelDescriptionFieldUpdate;

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldStartedToSetUp, {
      ResourceTypeID: options.resource.typeItem.name,
      ResourceID: options.modelDescription.resource,
      CollectionID: options.modelDescription.model,
      Create: false,
      FieldID: options.field.name,
      Source: options.analyticsSource
    });

    if (options.field.type == ModelFieldType.Db && resourceFieldUpdate) {
      options.customizeBarContext.setSettingsComponent(
        {
          component: CustomizeBarResourceFieldEditComponent,
          inputs: {
            resource: options.resource,
            modelDescription: options.modelDescription,
            field: options.field.item,
            context: options.context,
            contextElement: options.contextElement,
            analyticsSource: options.analyticsSource
          },
          outputs: {
            saved: [
              modelDescription => {
                result.next({
                  result: modelDescription
                });
              }
            ],
            cancelled: [
              () => {
                result.next({
                  cancelled: true
                });
              }
            ]
          },
          injector: options.injector,
          modal: options.modal
        },
        { append: options.append, modal: options.modal }
      );
    } else {
      options.customizeBarContext.setSettingsComponent(
        {
          component: CustomizeBarModelFieldEditComponent,
          inputs: {
            resource: options.resource,
            modelDescription: options.modelDescription,
            modelField: options.field,
            context: options.context,
            contextElement: options.contextElement,
            contextElementPath: options.contextElementPath,
            contextElementPaths: options.contextElementPaths,
            analyticsSource: options.analyticsSource,
            trackConfigured: options.trackConfigured,
            firstInit: options.firstInit
          },
          outputs: {
            saved: [
              modelDescription => {
                result.next({
                  result: modelDescription
                });
              }
            ],
            cancelled: [
              () => {
                result.next({
                  cancelled: true
                });
              }
            ]
          },
          injector: options.injector,
          modal: options.modal
        },
        { append: options.append }
      );
    }

    return result.pipe(
      tap(e => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
          Type: 'data'
        });

        if (e.result) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldSuccessfullySetUp, {
            ResourceTypeID: options.resource.typeItem.name,
            ResourceID: options.modelDescription.resource,
            CollectionID: options.modelDescription.model,
            Create: false,
            FieldID: options.field.name,
            Source: options.analyticsSource
          });
        } else if (e.cancelled) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionFieldSetUpCancelled, {
            ResourceTypeID: options.resource.typeItem.name,
            ResourceID: options.modelDescription.resource,
            CollectionID: options.modelDescription.model,
            Create: false,
            FieldID: options.field.name,
            Source: options.analyticsSource
          });
        }
      })
    );
  }

  deleteModelField(options: {
    resource: Resource;
    modelDescription: ModelDescription;
    field: ModelField;
    analyticsSource?: string;
  }): Observable<ModelDescription> {
    return this.modelDescriptionStore.getDetailFirst(options.modelDescription.modelId).pipe(
      switchMap(modelDescription => {
        const controller = this.resourceControllerService.get(options.resource.type);
        const resourceField =
          controller.supportModelDescriptionManagement(options.resource) && !options.modelDescription.virtual;
        const resourceFieldUpdate = resourceField && !!controller.modelDescriptionFieldUpdate;
        const instance = cloneDeep(modelDescription);

        instance.deleteField(options.field.name);

        if (options.field.type == ModelFieldType.Db && resourceFieldUpdate) {
          return controller
            .modelDescriptionFieldDelete(options.resource, options.modelDescription, options.field.name)
            .pipe(
              map(() => instance),
              tap(result => this.modelDescriptionStore.updateItem(result)),
              delayWhen(result => this.actionStore.syncAutoActions(result))
            );
        } else if (options.field.type != ModelFieldType.Db) {
          return this.modelDescriptionService
            .update(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              instance
            )
            .pipe(
              tap(result => this.modelDescriptionStore.updateItem(result)),
              delayWhen(result => this.actionStore.syncAutoActions(result))
            );
        } else {
          return of(undefined);
        }
      })
    );
  }

  reorderModelField(options: {
    modelDescription: ModelDescription;
    name: string;
    afterName: string;
  }): Observable<ModelDescription> {
    return this.modelDescriptionStore.getDetailFirst(options.modelDescription.modelId).pipe(
      map(modelDescription => {
        const instance = cloneDeep(modelDescription);
        instance.moveField(options.name, options.afterName);
        return instance;
      }),
      delayWhen(modelDescription => {
        return this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          modelDescription
        );
      }),
      tap(result => this.modelDescriptionStore.updateItem(result))
    );
  }

  applyFieldSettings(options: {
    modelDescription: ModelDescription;
    fieldSettings: FieldDisplaySettings[];
  }): Observable<ModelDescription> {
    return this.modelDescriptionStore.getDetailFirst(options.modelDescription.modelId).pipe(
      map(modelDescription => {
        const instance = cloneDeep(modelDescription);
        instance.applyFieldSettings(options.fieldSettings);
        return instance;
      }),
      delayWhen(modelDescription => {
        return this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          modelDescription
        );
      }),
      tap(result => this.modelDescriptionStore.updateItem(result))
    );
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { View } from '@modules/views';

import { BorderArray } from '../controls/border.array';
import { CornerRadiusControl } from '../controls/corner-radius.control';
import { FillArray } from '../controls/fill.array';
import { FrameControl } from '../controls/frame.control';
import { LayerInteractionArray } from '../controls/layer-interaction.array';
import { ShadowArray } from '../controls/shadow.array';
import { ValueResizeControl } from '../controls/value-resize.control';

@Injectable()
export class CustomizeViewForm extends FormGroup {
  instance: View;

  controls: {
    frame: FrameControl;
    width_resize: ValueResizeControl;
    height_resize: ValueResizeControl;
    constrain_proportion: FormControl;
    clip_content: FormControl;
    resize_content: FormControl;
    opacity: FormControl;
    fills: FillArray;
    borders: BorderArray;
    shadows: ShadowArray;
    corner_radius: CornerRadiusControl;
    interactions: LayerInteractionArray;
  };

  constructor() {
    super({
      frame: new FrameControl(),
      width_resize: new ValueResizeControl(),
      height_resize: new ValueResizeControl(),
      constrain_proportion: new FormControl(undefined),
      clip_content: new FormControl(false),
      resize_content: new FormControl(true),
      opacity: new FormControl(1),
      fills: new FillArray([]),
      borders: new BorderArray([]),
      shadows: new ShadowArray([]),
      corner_radius: new CornerRadiusControl(),
      interactions: new LayerInteractionArray([])
    });
  }

  init(instance: View, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    this.controls.frame.deserialize(instance.frame);
    this.controls.width_resize.deserialize(instance.widthResize);
    this.controls.height_resize.deserialize(instance.heightResize);
    this.controls.constrain_proportion.patchValue(instance.constrainProportion);
    this.controls.clip_content.patchValue(instance.clipContent);
    this.controls.resize_content.patchValue(instance.resizeContent);
    this.controls.opacity.patchValue(instance.opacity);
    this.controls.fills.deserialize(instance.fills);
    this.controls.borders.deserialize(instance.borders);
    this.controls.shadows.deserialize(instance.shadows);
    this.controls.corner_radius.deserialize(instance.cornerRadius);
    this.controls.interactions.deserialize(instance.interactions);

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  updateWidthProportional() {
    this.controls.frame.updateWidthProportional(this.controls.constrain_proportion.value);
  }

  updateHeightProportional() {
    this.controls.frame.updateHeightProportional(this.controls.constrain_proportion.value);
  }

  toggleConstrainProportion() {
    if (this.controls.constrain_proportion.value) {
      this.controls.constrain_proportion.patchValue(undefined);
    } else {
      this.controls.constrain_proportion.patchValue(this.controls.frame.getProportion());
    }
  }

  submit(): View {
    const instance = this.instance;

    instance.frame = this.controls.frame.serialize();
    instance.widthResize = this.controls.width_resize.serialize();
    instance.heightResize = this.controls.height_resize.serialize();
    instance.constrainProportion = this.controls.constrain_proportion.value;
    instance.clipContent = this.controls.clip_content.value;
    instance.resizeContent = this.controls.resize_content.value;
    instance.opacity = this.controls.opacity.value;
    instance.fills = this.controls.fills.serialize();
    instance.borders = this.controls.borders.serialize();
    instance.shadows = this.controls.shadows.serialize();
    instance.cornerRadius = this.controls.corner_radius.serialize();
    instance.interactions = this.controls.interactions.serialize();

    return instance;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { GoogleCloudStorageGeneratorService } from '@modules/resource-generators';
import { isSet } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var GoogleCloudStorageResourceSettingsForm = /** @class */ (function (_super) {
    __extends(GoogleCloudStorageResourceSettingsForm, _super);
    function GoogleCloudStorageResourceSettingsForm(googleCloudStorageGeneratorService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.googleCloudStorageGeneratorService = googleCloudStorageGeneratorService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({
            service_token: new FormControl(null, [Validators.required, _this.validateServiceToken()]),
            project_id: new FormControl(null, Validators.required),
            access_token: new FormControl(null, Validators.required)
        });
        _this.form.controls['service_token'].valueChanges
            .pipe(switchMap(function (value) {
            return _this.googleCloudStorageGeneratorService.readServiceToken(value).pipe(catchError(function () { return of(undefined); }));
        }))
            .subscribe(function (result) {
            _this.form.patchValue(result
                ? {
                    project_id: result.projectId,
                    access_token: result.accessToken
                }
                : {
                    project_id: '',
                    access_token: ''
                });
        });
        return _this;
    }
    GoogleCloudStorageResourceSettingsForm.prototype.validateServiceToken = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var group = control.parent;
            if (!group) {
                return;
            }
            if (group.value['service_token'] && (!group.value['project_id'] || !group.value['access_token'])) {
                return { local: ['Service key is not valid or not enough permissions'] };
            }
        };
    };
    GoogleCloudStorageResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.googleCloudStorageGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                service_token: result.serviceToken,
                project_id: result.projectId,
                access_token: result.accessToken
            });
        }));
    };
    GoogleCloudStorageResourceSettingsForm.prototype.getOptions = function () {
        return {
            serviceToken: this.form.value['service_token'].trim(),
            projectId: this.form.value['project_id'],
            accessToken: this.form.value['access_token']
        };
    };
    GoogleCloudStorageResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        return this.googleCloudStorageGeneratorService
            .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
            .pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    return GoogleCloudStorageResourceSettingsForm;
}(BaseResourceSettingsForm));
export { GoogleCloudStorageResourceSettingsForm };

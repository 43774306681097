<ng-container *ngFor="let item of viewportOverlays; trackBy: trackViewportOverlayFn">
  <div
    *ngIf="item.options.frame"
    class="view-editor-viewport__overlay-item"
    [class.view-editor-viewport__overlay-interactive]="item.options.interactive"
    [style.left.em]="((item.options.frame?.x || 0) + (item.options.translate?.x || 0)) / 10"
    [style.top.em]="((item.options.frame?.y || 0) + (item.options.translate?.y || 0)) / 10"
    [style.width.em]="item.options.frame.width / 10"
    [style.height.em]="item.options.frame.height / 10"
    [style.transform]="item.options.transform | appSafeStyle"
  >
    <ng-template [cdkPortalOutlet]="item.portal"></ng-template>
  </div>

  <div *ngIf="!item.options.frame" [class.view-editor-viewport__overlay-interactive]="item.options.interactive">
    <ng-template [cdkPortalOutlet]="item.portal"></ng-template>
  </div>
</ng-container>

import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  cleanElementName,
  FilterElementItem,
  Margin,
  ViewContext,
  ViewContextElement,
  WidgetElementItem
} from '@modules/customize';
import { Widget } from '@modules/dashboard';
import { createFormFieldFactory, Input as FieldInput } from '@modules/fields';
import { Resource } from '@modules/projects';

import { CustomizeBarEditComponent } from '../../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../../utils/analytics';
import { CustomizeBarBaseWidgetEditForm } from './customize-bar-base-widget-edit.form';

export abstract class CustomizeBarBaseWidgetEditComponent<T extends Widget>
  implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: WidgetElementItem;
  @Input() widget: T;
  @Input() visibleInput: FieldInput;
  @Input() visibleEditable: boolean;
  @Input() margin: Margin;
  @Input() marginEditable: boolean;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() component: any;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  result: { widget: T; name?: string; visibleInput?: FieldInput; margin?: Margin };
  resource: Resource;
  configurationStarted = false;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarBaseWidgetEditForm<T>,
    protected customizeBarContext: CustomizeBarContext,
    protected analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.widget, this.title, this.visibleInput, this.margin, this.firstInit, this.component);

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        switchMap(result => this.form.isConfigured(result.widget)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.widget.analyticsName,
          ResourceType: this.resource ? this.resource.typeItem.name : undefined,
          ResourceDemo: this.resource ? this.resource.demo : false
        });
      });
  }

  ngOnDestroy(): void {}

  onTitleChanged(title: string) {
    this.form.controls.name.setValue(title);
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result.widget) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.widget.analyticsName,
            ResourceType: this.resource ? this.resource.typeItem.name : undefined,
            ResourceDemo: this.resource ? this.resource.demo : false
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  emitUpdate(submit = false) {
    const args = { result: this.result.widget, submit: submit };

    if (this.title) {
      args['title'] = this.result.name;
    }

    if (this.visibleEditable) {
      args['visible_input'] = this.result.visibleInput;
    }

    if (this.marginEditable) {
      args['margin'] = this.result.margin;
    }

    if (this.widget) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }
}

<div class="compact-inputs-element compact-inputs-element_margin_l">
  <div class="compact-inputs-row">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <div class="slider slider_dark" [class.slider_active]="false" [class.slider_thumb_inside]="true">
        <div class="slider__track-wrapper">
          <div
            class="slider__track"
            [style.background]="trackBackgroundSafe"
            (click)="createGradientStop($event)"
            #gradient_stop_track
          >
            <div class="slider__track-fill-start" [style.background]="'transparent'"></div>
            <div class="slider__track-fill" [style.background]="'transparent'">
              <div
                *ngFor="let stopControl of control.controls.stops.controls; trackBy: trackStopControlFn"
                appGradientStopPosition
                class="slider__position slider__value-origin"
                [class.slider__position_active]="(activeStopId | appIsSet) && activeStopId == stopControl.getId()"
                [style.left.%]="stopControl.controls.position.value * 100"
                [gradientStopBounds]="gradient_stop_track"
                (click)="setCurrentStopControl(stopControl); $event.stopPropagation()"
                (gradientStopMouseDown)="setCurrentStopControl(stopControl)"
                (gradientStopSetPosition)="stopControl.controls.position.patchValue($event)"
                #gradient_stop_position="appGradientStopPosition"
              >
                <div
                  class="slider__value"
                  [class.slider__value_active]="gradient_stop_position.dragging$ | async"
                  [class.slider__value_position_top]="true"
                  [class.slider__value_position_bottom]="false"
                >
                  {{ stopControl.controls.position.value | appFormatNumber: '0%' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="compact-inputs-group__item-row-item" style="margin: -6px 0;">
      <div
        class="compact-button"
        [appTip]="'Invert gradient'"
        [appTipOptions]="{ side: 'top' }"
        (click)="control.invertStops()"
      >
        <span class="icon-reflect"></span>
      </div>
    </div>

    <div class="compact-inputs-group__item-row-item" style="margin: -6px 6px -6px 2px;">
      <div
        class="compact-button"
        [appTip]="'Rotate gradient'"
        [appTipOptions]="{ side: 'top' }"
        (click)="control.rotateStops()"
      >
        <span class="icon-repeat"></span>
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let stopControl of control.controls.stops.controls">
  <app-color-with-input-selector
    *ngIf="(activeStopId | appIsSet) && stopControl.getId() == activeStopId"
    [colorControl]="stopControl.controls.color"
    [colorInputEnabledControl]="stopControl.controls.color_input_enabled"
    [colorInputControl]="stopControl.controls.color_input"
    [viewContext]="viewContext"
  ></app-color-with-input-selector>
</ng-container>

<form [formGroup]="form.form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="title || element.name || (titleEditable ? 'back' : 'Title')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backEnabled]="backEnabled"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    (deleteClick)="delete()"
    [subtitle]="'Field'"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'title'"
              [required]="true"
              [itemForm]="form.form.controls['title_input']"
              [context]="context"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_title'"
            >
              <div *ngIf="form.form.controls['title_input'].invalid && form.form.dirty" class="field__errors">
                <div class="field__error">required</div>
              </div>
            </app-customize-bar-input-edit>
          </div>
        </app-sidebar-section>

        <app-sidebar-section [title]="'Back Link'">
          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'text'"
              [itemForm]="form.form.controls['previous_page_title_input']"
              [context]="context"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_back_title'"
            ></app-customize-bar-input-edit>
          </div>

          <div class="sidebar__element">
            <app-action-single-edit
              [options]="{ originEnabled: true }"
              [title]="'Back Action'"
              [control]="form.form.controls['previous_page_action']"
              [context]="context"
              [defaultSubtitle]="'Not specified'"
              [icon]="'cursor'"
              [object]="element.analyticsGenericName"
              [firstInit]="firstInit"
            >
            </app-action-single-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.form.controls['visible_input']"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.form.controls['margin']"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>

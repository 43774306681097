import { LatLngLiteral } from '@agm/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as Color from 'color';
import clone from 'lodash/clone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { colors, parseColor } from '@modules/colors';
import { BaseField, FieldType, getFieldDescriptionByType, registerFieldComponent } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { forceObservable, isColorHex, MapDarkStyles, MapStyles, numberToHex } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-geography-field',
  templateUrl: 'geography-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeographyFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {
  formSubscription: Subscription;
  viewportZoom = 14;
  viewportCoordinates: LatLngLiteral;
  markerCoordinates: LatLngLiteral;
  currentCoordinates: LatLngLiteral = { lat: undefined, lng: undefined };

  constructor(
    private themeService: ThemeService,
    private appConfigService: AppConfigService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      this.initForm();
    } else {
      this.setValue();
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.initForm();
    }

    if (changes['value']) {
      this.setValue();
    }
  }

  initForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.form && this.field && this.form.controls[this.field.name]) {
      this.formSubscription = this.form.controls[this.field.name].valueChanges
        .pipe(delay(0), untilDestroyed(this))
        .subscribe(() => {
          this.setValue();
        });
    }

    this.setValue();
  }

  setValue() {
    const value = this.currentValue;
    const defaultCoords = { lat: 52.5139252, lng: 13.3895913 };

    if (value) {
      this.markerCoordinates = clone(value);
      this.viewportCoordinates = clone(this.markerCoordinates);
      this.currentCoordinates = clone(this.markerCoordinates);
    } else {
      this.markerCoordinates = defaultCoords;

      if (this.currentCoordinates.lat && this.currentCoordinates.lng) {
        this.currentCoordinates = { lat: undefined, lng: undefined };
      }

      this.viewportCoordinates = clone(this.markerCoordinates);

      Observable.create(observer => {
        navigator.geolocation.getCurrentPosition((pos: Position) => {
          observer.next(pos);
          observer.complete();
        });
      })
        .pipe(untilDestroyed(this))
        .subscribe(pos => {
          this.markerCoordinates = { lat: pos.coords.latitude, lng: pos.coords.longitude };
          this.viewportCoordinates = clone(this.markerCoordinates);
          this.cd.markForCheck();
        });
    }

    this.cd.markForCheck();
  }

  updateValue() {
    let value: LatLngLiteral;

    if (this.currentCoordinates && this.currentCoordinates.lat && this.currentCoordinates.lng) {
      value = clone(this.currentCoordinates);
    }

    if (this.form && this.field) {
      const formValue = {};
      formValue[this.field.name] = value;
      this.form.patchValue(formValue);
    }
  }

  get mapStyles$() {
    return this.themeService.theme$.pipe(
      map(theme => {
        return theme == 'dark' ? MapDarkStyles : MapStyles;
      })
    );
  }

  get backgroundColor$() {
    return this.themeService.theme$.pipe(
      map(theme => {
        return theme == 'dark' ? '#343C43' : '#fff';
      })
    );
  }

  get markerIcon$() {
    return this.themeService.theme$.pipe(
      map(theme => {
        const markerColor = this.accentColor;
        const colorHex = isColorHex(markerColor)
          ? markerColor.substring(1)
          : colors.filter(item => item.name == markerColor).map(item => numberToHex(item.hex))[0];
        const clr = colorHex ? parseColor('#' + colorHex) : undefined;
        const isDark = !clr || clr.contrast(Color('white')) >= 2;
        const contrastColor = !clr || isDark ? 'FFF' : clr.darken(0.8).hex().toUpperCase().substring(1);
        const size = 26;
        const url = new URL(`${this.appConfigService.serverBaseUrl}/assets/marker.svg`);

        if (colorHex) {
          url.searchParams.append('color', colorHex);
        }

        if (contrastColor) {
          url.searchParams.append('contrast_color', contrastColor);
        }

        if (theme == 'dark') {
          url.searchParams.append('dark', '1');
        }

        if (size) {
          url.searchParams.append('size', size.toString());
        }

        return {
          url: url.toString(),
          anchor: {
            x: size * 0.5,
            y: size * 0.5
          }
        };
      })
    );
  }

  get valueToStr() {
    // TODO: Fix as operator
    return forceObservable(
      getFieldDescriptionByType(this.field.field).valueToStr(this.currentValue, {
        field: this.field as BaseField,
        context: this.context
      })
    ).pipe(
      map(value => {
        if (!value) {
          return this.formatEmpty(value);
        }

        return value;
      })
    );
  }

  onDrag(e: { coords: LatLngLiteral }) {
    this.currentCoordinates = e.coords;
    this.cd.markForCheck();
  }

  onDragEnd(e: { coords: LatLngLiteral }) {
    this.currentCoordinates = e.coords;
    this.updateValue();
  }
}

registerFieldComponent(FieldType.Location, GeographyFieldComponent);

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { ModelCreateComponent } from '../../components/model-create/model-create.component';
import { ModelCreateOptions } from '../../components/model-create/model-create.form';
import { ModelPrimaryKeyComponent } from '../../components/model-primary-key/model-primary-key.component';

@Injectable()
export class ModelEditController {
  constructor(
    private injector: Injector,
    private popupService: PopupService,
    private routing: RoutingService,
    private resolver: ComponentFactoryResolver
  ) {}

  create(resource: Resource, options: ModelCreateOptions = {}): Observable<ModelDescription> {
    const result = new Subject<ModelDescription>();

    this.popupService.push({
      component: ModelCreateComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        resource: resource,
        options: options
      },
      outputs: {
        created: [
          (model: ModelDescription) => {
            result.next(model);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }

  setPrimaryKey(modelDescription: ModelDescription): Observable<ModelDescription> {
    const result = new Subject<ModelDescription>();

    this.popupService.push({
      component: ModelPrimaryKeyComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        modelDescription: modelDescription
      },
      outputs: {
        saved: [
          (model: ModelDescription) => {
            result.next(model);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }
}

<app-popup2 [size]="'l'">
  <div class="marketplace">
    <ng-container *ngIf="loading">
      <div class="marketplace__header">
        <div class="marketplace__title">
          <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
        </div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <div class="marketplace-detail">
          <div class="marketplace-detail__main">
            <div class="marketplace-detail__gallery">
              <div class="marketplace-detail-gallery">
                <div class="marketplace-detail-gallery__image marketplace-detail-gallery__image_background"></div>
              </div>
            </div>
          </div>

          <div class="marketplace-detail__right">
            <div class="marketplace-detail__block marketplace-detail__block_first">
              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_first">
                <div class="marketplace-detail-header">
                  <div class="marketplace-detail-header__info">
                    <div class="marketplace-detail-header__title">
                      <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
                    </div>
                    <div class="marketplace-detail-header__subtitle">
                      <span [class.loading-animation]="true"><span class="stub-text">subtitle</span></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_last">
                <div class="marketplace-detail-buttons">
                  <div
                    class="marketplace-button marketplace-button_primary marketplace-button_disabled marketplace-detail-buttons__item"
                  >
                    <span [class.loading-animation]="true"><span class="stub-text">button</span></span>
                  </div>

                  <div class="marketplace-button marketplace-button_disabled marketplace-detail-buttons__item">
                    <span [class.loading-animation]="true"><span class="stub-text">button</span></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block marketplace-detail__block_last">
              <div class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">
                  <span [class.loading-animation]="true"><span class="stub-text">label</span></span>
                </div>
                <div class="marketplace-detail-info__content">
                  <span [class.loading-animation]="true"><span class="stub-text">description</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && error">
      <div class="marketplace__header">
        <div class="marketplace__title">Components marketplace detail</div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <div class="table__empty">
          <app-error [title]="'Component failed to load' | localize" [compact]="true">
            {{ error }}
          </app-error>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && !error">
      <div class="marketplace__header">
        <div class="marketplace__title">
          <div class="marketplace__title-item marketplace__title-item_link" (click)="back.emit()">
            Components marketplace
          </div>

          <div class="marketplace__title-arrow icon-arrow_forward_2"></div>

          <div class="marketplace__title-item">
            {{ template.name }}
          </div>
        </div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <div class="marketplace-detail">
          <div class="marketplace-detail__main">
            <div class="marketplace-detail__gallery">
              <div class="marketplace-detail-gallery">
                <div
                  *ngIf="template.view"
                  class="marketplace-detail-gallery__image marketplace-detail-gallery__image_fixed"
                  [style.width.px]="template.view.frame.width"
                  [style.height.px]="template.view.frame.height"
                  [style.transform]="previewScale ? 'scale(' + previewScale + ')' : null"
                >
                  <app-custom-element-view
                    class="marketplace-detail-gallery__image-inner"
                    [view]="template.view"
                    [params]="template.view.testParameters"
                  ></app-custom-element-view>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__description">{{ template.description }}</div>
          </div>

          <div class="marketplace-detail__right">
            <div class="marketplace-detail__block marketplace-detail__block_first">
              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_first">
                <div class="marketplace-detail-header">
                  <div
                    class="marketplace-icon marketplace-icon_size_b marketplace-detail-header__icon"
                    [style.background-color]="(template.iconColor | appIsColorHex) ? template.iconColor : null"
                    [ngClass]="[
                      template.icon ? 'icon-' + template.icon : 'icon-canvas',
                      !(template.iconColor | appIsColorHex) ? 'background-color_' + template.iconColor + '_1' : ''
                    ]"
                  ></div>
                  <div class="marketplace-detail-header__info">
                    <div class="marketplace-detail-header__title">{{ template.name }}</div>
                    <div class="marketplace-detail-header__subtitle">{{ template.shortDescription }}</div>
                  </div>
                </div>
              </div>

              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_last">
                <div class="marketplace-detail-buttons">
                  <div
                    class="marketplace-button marketplace-button_primary marketplace-detail-buttons__item"
                    (click)="useView()"
                  >
                    Use this component
                  </div>

                  <div
                    *ngIf="viewCustomizeEnabled"
                    class="marketplace-button marketplace-detail-buttons__item"
                    (click)="customizeView()"
                  >
                    Customize with Designer
                  </div>

                  <div
                    *ngIf="(currentUserStore.instance$ | async)?.isStaff"
                    class="marketplace-button marketplace-detail-buttons__item staff-block staff-block_background"
                    (click)="customizeTemplate.emit(template)"
                  >
                    Manage Template
                  </div>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block">
              <div class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">Rating</div>
                <div class="marketplace-detail-info__content">
                  <app-custom-view-template-rating [template]="template"></app-custom-view-template-rating>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block">
              <div *ngIf="template.types.length" class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">Can be used for</div>
                <div class="marketplace-detail-info__content">
                  <div class="marketplace-detail-tags">
                    <div
                      *ngFor="let item of template.getTypeItems()"
                      class="marketplace-detail-tag marketplace-detail-tag_interactive marketplace-detail-tags__item"
                      (click)="showTemplateType.emit(item.type)"
                    >
                      <span
                        *ngIf="item.icon"
                        class="marketplace-detail-tag__icon"
                        [ngClass]="'icon-' + item.icon"
                      ></span>

                      <span class="marketplace-detail-tag__label">
                        {{ item.label }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block">
              <div class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">Made by</div>
                <div class="marketplace-detail-info__content">
                  <span
                    class="marketplace-detail-info__icon marketplace-detail-info__icon_jet"
                    style="margin-right: 8px;"
                  ></span>
                  <strong>Jet Admin</strong>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block marketplace-detail__block_last">
              <div *ngIf="template.dateAdd.isAfter(template.dateChange)" class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">Updated</div>
                <div class="marketplace-detail-info__content">{{ template.dateChange | appFormatDate: 'll' }}</div>
              </div>

              <div *ngIf="!template.dateAdd.isAfter(template.dateChange)" class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">Published</div>
                <div class="marketplace-detail-info__content">{{ template.dateAdd | appFormatDate: 'll' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-popup2>

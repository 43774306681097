var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewChecked, ChangeDetectorRef, ElementRef, Injector, NgZone, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { DocumentService } from '@core';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, getModelAttributesByColumns, getModelBulkAttributesByColumns, ListDefaultSelection, rawListViewSettingsColumnsToViewContextOutputs, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { CHECKED_ITEMS_OUTPUT, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, NO_SELECTED_ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, KeyboardEventKeyCode } from '@shared';
import { TableGroupComponent } from '../table-group/table-group.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { getListStateColumns, getListStateDefaultSelection, getListStateFetch, getListStateFetchNewParams } from './table-state';
var TableComponent = /** @class */ (function (_super) {
    __extends(TableComponent, _super);
    function TableComponent(modelService, actionControllerService, customizeService, documentService, notificationService, zone, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        var _this = _super.call(this, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) || this;
        _this.modelService = modelService;
        _this.actionControllerService = actionControllerService;
        _this.customizeService = customizeService;
        _this.documentService = documentService;
        _this.notificationService = notificationService;
        _this.zone = zone;
        _this.groupComponents = new QueryList();
        _this.layout = ListLayoutType.Table;
        _this.visibleColumns = [];
        _this.checkedItems = {};
        _this.checkedInverse = false;
        _this.totalItems = 0;
        _this.totalVisibleItems = 0;
        _this.customizing = false;
        _this.loading = true;
        _this.configured = true;
        return _this;
    }
    TableComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initResizes();
        this.initContext();
        this.initHotkeys();
    };
    TableComponent.prototype.ngOnDestroy = function () { };
    TableComponent.prototype.ngAfterViewChecked = function () {
        this.updateTableSizes();
    };
    TableComponent.prototype.getListState = function (settings, params, filters, search, sort) {
        var _this = this;
        var page = parseInt(params[PAGE_PARAM], 10) || 1;
        params = cloneDeep(params);
        delete params[NEXT_PAGE_SCROLL_PARAM];
        if (!sort.length && isSet(settings.sortingField)) {
            sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
        }
        var staticData$ = settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
            ? applyParamInput$(settings.dataSource.input, {
                context: this.context,
                defaultValue: [],
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of([]);
        var inputParams$ = settings.dataSource
            ? applyParamInputs$({}, settings.dataSource.queryInputs, {
                context: this.context,
                parameters: settings.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(map(function (_a) {
            var staticData = _a[0], inputParams = _a[1], modelDescription = _a[2];
            var resource = settings.dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == settings.dataSource.queryResource; })
                : undefined;
            return {
                settings: settings,
                dataSource: settings ? settings.dataSource : undefined,
                dataSourceStaticData: staticData,
                dataSourceParams: __assign({}, inputParams, params),
                userParams: params,
                filters: filters,
                search: search,
                sort: sort,
                groupCollapse: settings.groupCollapse,
                resource: resource,
                modelDescription: modelDescription,
                inputsLoading: [inputParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [inputParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                }),
                page: page,
                perPage: settings ? settings.perPage : undefined,
                sortingField: settings ? settings.sortingField : undefined,
                sortingAsc: settings ? settings.sortingAsc : undefined,
                groupField: settings ? settings.groupField : undefined,
                groups: settings ? settings.groups : undefined,
                defaultSelection: settings ? settings.defaultSelection : undefined
            };
        }));
    };
    TableComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
            this.updateContextOutputs(state);
            this.updateVisibleColumns(state);
        }
        if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
            var newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
            if (newParams) {
                this.setSelectedItem(undefined);
                this.groupComponents.forEach(function (item) { return item.page$.next(1); });
            }
            this.fetch(state);
        }
        else {
            if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
                this.cd.detectChanges();
                this.updateTableSizes();
            }
            if (!isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
                state.defaultSelection == ListDefaultSelection.First) {
                var component = this.groupComponents.toArray()[0];
                var componentItems = component ? component.getItems() : undefined;
                this.setSelectedItem(componentItems ? componentItems[0] : undefined);
            }
        }
    };
    TableComponent.prototype.updateLoading = function () {
        this.loading = this.groupComponents.toArray().some(function (item) { return item.loading; });
        this.cd.markForCheck();
    };
    TableComponent.prototype.getStateQueryOptions = function (state) {
        var component = this.groupComponents.toArray()[0];
        return component ? component.getBaseStateQueryOptions() : {};
    };
    TableComponent.prototype.fetch = function (state) {
        this.configured = state.dataSource && state.dataSource.isConfigured();
        this.parameters = this.getParameters(state);
        this.inputs = this.getInputs(state);
        this.selectedItem = undefined;
        this.checkedItems = {};
        this.checkedInverse = false;
        this.updateCheckedContext();
        this.cd.markForCheck();
    };
    TableComponent.prototype.onFetch = function () {
        this.loading = true;
        this.setChecked({});
        this.cd.markForCheck();
        this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
    };
    TableComponent.prototype.onFetched = function (items) {
        if (this.selectedItem && items) {
            this.checkSelectedModelIsActual(items);
        }
        this.updateLoading();
        if (!this.loading) {
            var isEmpty = this.groupComponents.toArray().every(function (item) {
                var groupItems = item.getItems();
                return groupItems && !groupItems.length;
            });
            this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
        }
        this.updateTotalItems();
    };
    TableComponent.prototype.reloadData = function () {
        _super.prototype.reloadData.call(this);
        this.groupComponents.forEach(function (item) { return item.reloadData(); });
    };
    TableComponent.prototype.initResizes = function () {
        var _this = this;
        this.customizeService.layoutEnabled$
            .pipe(switchMap(function (enabled) {
            _this.customizing = enabled;
            return _this.zone.onStable;
        }), take(1), untilDestroyed(this))
            .subscribe(function () { return _this.updateTableSizes(); });
        this.documentService.viewportSizeChanges$.pipe(untilDestroyed(this)).subscribe(function () {
            _this.updateTableSizes();
        });
    };
    TableComponent.prototype.initContext = function () {
        var _this = this;
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_selected_item',
                name: 'Reset Selected Row',
                icon: 'deselect',
                parameters: [],
                handler: function () {
                    _this.setSelectedItem(undefined);
                    _this.setChecked({});
                }
            },
            {
                uniqueName: 'clear_filters',
                name: 'Reset Filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.resetFilters(); }
            }
        ]);
    };
    TableComponent.prototype.initHotkeys = function () {
        var _this = this;
        fromEvent(document, 'keydown', { capture: true })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Escape && _this.isAnyChecked) {
                _this.uncheckAll();
                e.stopPropagation();
            }
        });
    };
    TableComponent.prototype.updateContextOutputs = function (state) {
        var columns = state.dataSource ? state.dataSource.columns : [];
        this.contextElement.setOutputs([
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Row',
                icon: 'fileds',
                internal: true,
                byPathOnly: true,
                allowSkip: true,
                children: rawListViewSettingsColumnsToViewContextOutputs(columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            },
            {
                uniqueName: SELECTED_ITEM_OUTPUT,
                name: 'Selected Row',
                icon: 'hand',
                children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            },
            {
                uniqueName: HAS_SELECTED_ITEM_OUTPUT,
                name: 'Is any Row selected',
                icon: 'select_all',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: NO_SELECTED_ITEM_OUTPUT,
                name: 'No Row selected',
                icon: 'deselect',
                fieldType: FieldType.Boolean,
                defaultValue: true
            },
            {
                uniqueName: EMPTY_OUTPUT,
                name: 'Is Empty',
                icon: 'uncheck',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: CHECKED_ITEMS_OUTPUT,
                name: 'Checked Rows',
                icon: 'check',
                children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            }
        ]);
    };
    TableComponent.prototype.updateSelectedContext = function () {
        if (this.selectedItem) {
            var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(this.selectedItem.model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
    };
    TableComponent.prototype.updateCheckedContext = function () {
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        var models = values(this.checkedItems);
        this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
        // Backward compatibility
        this.contextElement.setOutputValue('selected_items', getModelBulkAttributesByColumns(models, columns));
    };
    // onDragChanged(dragging) {
    //   const items = this.draggable.items.map(item => item.data);
    //
    //   if (dragging) {
    //     this.prevItems = items;
    //   } else {
    //     this.items = items;
    //
    //     const orderingField = this.params.modelDescription.orderingField;
    //     const data = detectMoveUpdates<ListItem>(this.items, this.prevItems);
    //
    //     if (!data) {
    //       return;
    //     }
    //
    //     this.modelService.reorder(
    //       this.params.modelDescription.modelId,
    //       data.forward,
    //       data.segmentFrom.pk,
    //       data.segmentTo.pk,
    //       data.item.pk
    //     ).subscribe(() => {
    //       const newOrder = data.forward ? data.segmentTo[orderingField] : data.segmentFrom[orderingField];
    //       this.items
    //         .filter(item => item[orderingField] >= data.segmentFrom[orderingField] && item[orderingField] <= data.segmentTo[orderingField])
    //         .forEach(item => {
    //           if (data.forward) {
    //             item[orderingField] -= 1;
    //           } else {
    //             item[orderingField] += 1;
    //           }
    //         });
    //       data.item[orderingField] = newOrder;
    //       this.cd.markForCheck();
    //     });
    //   }
    // }
    TableComponent.prototype.updateTableSizes = function () {
        if (!this.scrollable) {
            return;
        }
        this.updateFixedTableSize();
    };
    TableComponent.prototype.updateFixedTableSize = function () {
        if (!this.tableHeaderFixed || !this.tableHeader) {
            return;
        }
        this.tableHeaderFixed.copySize(this.tableHeader);
    };
    TableComponent.prototype.onHorizontalScroll = function () {
        this.updateFixedTableScroll();
    };
    // onVerticalScroll() {
    //   if (!this.scrollable) {
    //     return;
    //   }
    //
    //   const scrollTop = getWindowScrollTop();
    //   const scrolled = scrollTop > 0;
    //
    //   if (scrolled != this.scrolled) {
    //     this.scrolled = scrolled;
    //     this.cd.markForCheck();
    //   }
    //
    //   if (this.listStore.items == undefined) {
    //     return;
    //   }
    //
    //   const viewportHeight = window.innerHeight;
    //   const contentHeight = document.body.offsetHeight;
    //   const viewportBottom = scrollTop + viewportHeight;
    //   // const firstIndexInvisible = this.rowPositions.findIndex(item => item.top > viewportBottom);
    //   // let lastIndexVisible;
    //   // if (firstIndexInvisible == -1) {
    //   //   lastIndexVisible = this.rowPositions.length - 1;
    //   // } else if (firstIndexInvisible == 0) {
    //   //   lastIndexVisible = 0;
    //   // } else {
    //   //   lastIndexVisible = firstIndexInvisible - 1;
    //   // }
    //   // const lastPageVisible = this.listStore.fromPage + Math.floor(lastIndexVisible / this.listStore.perPage);
    //
    //   if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
    //     this.onScrollFinished();
    //   }
    // }
    TableComponent.prototype.trackGroupFn = function (i, item) {
        return item.uid;
    };
    Object.defineProperty(TableComponent.prototype, "columnsCount", {
        get: function () {
            if (this.visibleColumns.length) {
                return this.visibleColumns.length;
            }
            else {
                return this.scrollable ? 8 : 5;
            }
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.updateFixedTableScroll = function () {
        if (!this.fixedTable || !this.scrollableElement) {
            return;
        }
        var scrollLeft = this.scrollableElement.nativeElement.scrollLeft;
        this.fixedTable.nativeElement['scrollLeft'] = scrollLeft;
    };
    TableComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    TableComponent.prototype.isItemSelected = function (item, index) {
        return this.itemEquals(this.selectedItem, item);
    };
    TableComponent.prototype.isItemChecked = function (item, group, index) {
        var pk = item.model.primaryKey || group + "_" + index;
        return (!this.checkedInverse && this.checkedItems[pk]) || (this.checkedInverse && !this.checkedItems[pk]);
    };
    Object.defineProperty(TableComponent.prototype, "isAnyChecked", {
        get: function () {
            return ((!this.checkedInverse && keys(this.checkedItems).length) ||
                (this.checkedInverse && keys(this.checkedItems).length < this.totalVisibleItems));
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.getTotalItems = function () {
        return this.groupComponents.reduce(function (acc, item) { return acc + (item.getCount() || 0); }, 0);
    };
    TableComponent.prototype.getTotalVisibleItems = function () {
        return this.groupComponents.reduce(function (acc, component) {
            var items = component.getItems();
            return acc + (items ? items.length : 0);
        }, 0);
    };
    TableComponent.prototype.updateTotalItems = function () {
        this.totalItems = this.getTotalItems();
        this.totalVisibleItems = this.getTotalVisibleItems();
        this.cd.markForCheck();
    };
    TableComponent.prototype.setSelectedItem = function (item) {
        this.selectedItem = item;
        this.cd.markForCheck();
        this.updateSelectedContext();
    };
    TableComponent.prototype.toggleSelectedItem = function (item, element, click) {
        if (click === void 0) { click = false; }
        var _a;
        if (this.selectedItem === item) {
            this.setSelectedItem(undefined);
        }
        else {
            this.setSelectedItem(item);
        }
        this.checkedItems = {};
        this.checkedInverse = false;
        this.updateCheckedContext();
        this.cd.markForCheck();
        if (click && this.settings.rowClickAction && this.selectedItem) {
            this.actionControllerService
                .execute(this.settings.rowClickAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.selectedItem.model.getAttributes(),
                    _a),
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    TableComponent.prototype.setChecked = function (value, checkedInverse) {
        if (checkedInverse === void 0) { checkedInverse = false; }
        this.checkedItems = value;
        this.checkedInverse = checkedInverse;
        this.cd.markForCheck();
        this.updateCheckedContext();
    };
    TableComponent.prototype.toggleChecked = function (row, group, index) {
        var pk = row.row.model.primaryKey || group + "_" + index;
        var checked = this.isItemChecked(row.row, group, index);
        if ((!this.checkedInverse && !checked) || (this.checkedInverse && checked)) {
            var checkedItems = clone(this.checkedItems);
            checkedItems[pk] = row.row.model;
            this.checkedItems = checkedItems;
        }
        else {
            var checkedItems = clone(this.checkedItems);
            delete checkedItems[pk];
            this.checkedItems = checkedItems;
        }
        this.setSelectedItem(undefined);
        this.updateCheckedContext();
    };
    TableComponent.prototype.checkAll = function () {
        var checkedItems = this.groupComponents.reduce(function (acc, item) {
            return __assign({}, acc, item.getCheckedAll());
        }, {});
        this.setSelectedItem(undefined);
        this.setChecked(checkedItems);
    };
    TableComponent.prototype.uncheckAll = function () {
        this.setSelectedItem(undefined);
        this.setChecked({});
    };
    TableComponent.prototype.updateColumnValue = function (currentItem, options) {
        var _this = this;
        var newItem = {
            columns: currentItem.columns.map(function (item) {
                if (item.column === options.name) {
                    return __assign({}, item, { str: undefined });
                }
                else {
                    return item;
                }
            }),
            model: currentItem.model.clone()
        };
        var columnFiltered = this.listState.filters.some(function (item) { return item.field[0] == options.name; });
        var columnSorted = this.listState.sort.some(function (item) { return item.field == options.name; });
        var value = options.value;
        var field = this.listState.modelDescription.dbField(options.name);
        if (field && field.fieldDescription.serializeValue != undefined && value != undefined) {
            value = field.fieldDescription.serializeValue(value, field);
        }
        newItem.model.setRawAttribute(options.name, value);
        newItem.model.setAttribute(options.name, options.value);
        var prevRows = this.groupComponents.map(function (item) {
            var prev = item.getItems();
            item.updateRow(newItem);
            return prev;
        });
        this.modelService
            .update(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.listState.modelDescription.modelId, newItem.model, [options.name])
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (columnFiltered || columnSorted) {
                _this.reloadData();
            }
        }, function () {
            _this.groupComponents.forEach(function (item, i) {
                item.setItems(prevRows[i]);
            });
        });
    };
    TableComponent.prototype.onModelUpdated = function (model) {
        if (this.selectedItem && this.selectedItem.model.isSame(model)) {
            this.updateSelectedContext();
        }
        var checkedModels = values(this.checkedItems);
        if (checkedModels.some(function (item) { return item.isSame(model); })) {
            this.updateCheckedContext();
        }
    };
    TableComponent.prototype.onColumnResize = function (index, width) {
        this.groupComponents.forEach(function (item) { return item.setColumnWidth(index, width); });
    };
    TableComponent.prototype.onColumnResizeFinished = function (index, width) {
        this.groupComponents.forEach(function (item) { return item.setColumnWidth(index, width, true); });
    };
    TableComponent.prototype.getAnyModel = function () {
        for (var _i = 0, _a = this.groupComponents.toArray(); _i < _a.length; _i++) {
            var component = _a[_i];
            var items = component.getItems();
            if (items && items.length) {
                return items[0].model;
            }
        }
    };
    TableComponent.prototype.checkSelectedModelIsActual = function (items) {
        var _this = this;
        var actualSelectedItem = items.find(function (item) { return item.model.isSame(_this.selectedItem.model); });
        if (actualSelectedItem &&
            !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())) {
            this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
            this.cd.markForCheck();
            this.onModelUpdated(this.selectedItem.model);
        }
    };
    return TableComponent;
}(ListLayoutComponent));
export { TableComponent };

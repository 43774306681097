import { PopupService } from '@common/popups';
import { PopoverComponent } from '../../components/popover/popover.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../popups/services/popup/popup.service";
var PopoverService = /** @class */ (function () {
    function PopoverService(popupService) {
        this.popupService = popupService;
    }
    PopoverService.prototype.create = function (data, anchor) {
        this.popupService.push({
            component: PopoverComponent,
            inputs: {
                data: data,
                anchor: anchor
            },
            injector: data.injector
        });
    };
    PopoverService.ngInjectableDef = i0.defineInjectable({ factory: function PopoverService_Factory() { return new PopoverService(i0.inject(i1.PopupService)); }, token: PopoverService, providedIn: "root" });
    return PopoverService;
}());
export { PopoverService };

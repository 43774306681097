import { Injectable, OnDestroy } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getColorHexStr } from '@modules/colors';
import { isSet } from '@shared';

import { getThemeVars, themeVars } from '../utils/common';
import { ThemeOptions } from './theme-options';

@Injectable()
export class ThemeContext implements OnDestroy {
  private _options = new BehaviorSubject<ThemeOptions>({});

  constructor() {}

  ngOnDestroy(): void {
    this.clearContainersStyle();
  }

  get options(): ThemeOptions {
    return this._options.value;
  }

  set options(value: ThemeOptions) {
    this._options.next(value);
    this.updateContainersStyle();
  }

  getOption(value: keyof ThemeOptions): any {
    return this.options[value];
  }

  getOptions$(): Observable<ThemeOptions> {
    return this._options.asObservable();
  }

  getOption$(value: keyof ThemeOptions): Observable<any> {
    return this.getOptions$().pipe(map(item => item[value]));
  }

  getColorHex(value: keyof ThemeOptions): string {
    const color = this.getOption(value);
    if (!isSet(color)) {
      return;
    }

    return getColorHexStr(color);
  }

  getColorHex$(value: keyof ThemeOptions): Observable<string> {
    return this.getOption$(value).pipe(
      map(color => {
        if (!isSet(color)) {
          return;
        }

        return getColorHexStr(color);
      })
    );
  }

  getPopupsContainer(): HTMLElement {
    return document.querySelector('.popups');
  }

  getOverlayContainer(): HTMLElement {
    return document.querySelector('.app-overlay-container');
  }

  updateContainersStyle(): void {
    const vars = getThemeVars(this.options);
    const popupsContainer = this.getPopupsContainer();
    const overlayContainer = this.getOverlayContainer();

    toPairs(vars).forEach(([k, v]) => {
      const property = `--${k}`;

      if (isSet(v)) {
        popupsContainer.style.setProperty(property, String(v));
        overlayContainer.style.setProperty(property, String(v));
      } else {
        popupsContainer.style.removeProperty(property);
        overlayContainer.style.removeProperty(property);
      }
    });
  }

  clearContainersStyle(): void {
    const popupsContainer = this.getPopupsContainer();
    const overlayContainer = this.getOverlayContainer();

    themeVars.forEach(k => {
      const property = `--${k}`;
      popupsContainer.style.removeProperty(property);
      overlayContainer.style.removeProperty(property);
    });
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i2 from "../../../../shared/components/loader/loader.component";
import * as i3 from "../dashboard/dashboard.component.ngfactory";
import * as i4 from "../../../customize/data/view-context";
import * as i5 from "../../../projects/stores/current-environment.store";
import * as i6 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../../../projects/stores/current-project.store";
import * as i9 from "../dashboard/dashboard.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../dashboard/services/widget/widget.service";
import * as i12 from "../../../dashboard/services/dashboard/dashboard.service";
import * as i13 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i14 from "../../../meta/services/meta.service";
import * as i15 from "../../../activities/services/user-activity/user-activity.service";
import * as i16 from "../../../../core/services/document/document.service";
import * as i17 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i18 from "../../../routing/services/routing/routing.service";
import * as i19 from "@angular/common";
import * as i20 from "./index.component";
import * as i21 from "../../../dashboard/stores/dashboard.store";
var styles_IndexComponent = [];
var RenderType_IndexComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IndexComponent, data: {} });
export { RenderType_IndexComponent as RenderType_IndexComponent };
function View_IndexComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], { title: [0, "title"], fill: [1, "fill"] }, null), (_l()(), i0.ɵted(-1, 0, [" We are fetching dashboard data, please, wait... "]))], function (_ck, _v) { var currVal_0 = "Loading"; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_IndexComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-dashboard", [], null, null, null, i3.View_DashboardComponent_0, i3.RenderType_DashboardComponent)), i0.ɵprd(131584, null, i4.ViewContext, i4.ViewContext, [i5.CurrentEnvironmentStore]), i0.ɵprd(131584, null, i6.CustomizeBarContext, i6.CustomizeBarContext, [i7.CustomizeService, i8.CurrentProjectStore, i5.CurrentEnvironmentStore]), i0.ɵdid(3, 4964352, [[1, 4]], 0, i9.DashboardComponent, [i10.ActivatedRoute, i11.WidgetService, i8.CurrentProjectStore, i5.CurrentEnvironmentStore, i4.ViewContext, i7.CustomizeService, i12.DashboardService, i13.DialogService, i14.MetaService, i15.UserActivityService, i16.DocumentService, i17.UniversalAnalyticsService, i0.ChangeDetectorRef, i18.RoutingService, i6.CustomizeBarContext], { dashboard: [0, "dashboard"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dashboard; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_IndexComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { dashboardComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "index"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "index__dashboard"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IndexComponent_1)), i0.ɵdid(4, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IndexComponent_2)), i0.ɵdid(6, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dashboard; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_IndexComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_IndexComponent_0, RenderType_IndexComponent)), i0.ɵdid(1, 245760, null, 0, i20.IndexComponent, [i21.DashboardStore, i12.DashboardService, i8.CurrentProjectStore, i5.CurrentEnvironmentStore, i18.RoutingService, i0.ChangeDetectorRef, i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IndexComponentNgFactory = i0.ɵccf("ng-component", i20.IndexComponent, View_IndexComponent_Host_0, {}, {}, []);
export { IndexComponentNgFactory as IndexComponentNgFactory };

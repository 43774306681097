import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-empty',
  templateUrl: './project-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectEmptyComponent implements OnInit {
  @Input() animating = false;

  constructor() {}

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ViewSettingsAction } from '@modules/actions';
import { ModelDescriptionDataSource } from '@modules/data-sources';
import { Input } from '@modules/fields';
import { ModelDescriptionQuery } from '@modules/queries';
import { migrateModelDescriptionDataSource } from '../../../utils/migration';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ModelElementItem = /** @class */ (function (_super) {
    __extends(ModelElementItem, _super);
    function ModelElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Model;
        _this.columnActions = [];
        return _this;
    }
    ModelElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.tooltip = this.params['tooltip'];
        if (this.params['title_input']) {
            this.titleInput = new Input().deserialize(this.params['title_input']);
        }
        else if (this.params['title']) {
            // Backward compatibility
            this.titleInput = new Input().deserializeFromStatic('value', this.params['title']);
        }
        if (this.params['data_source']) {
            this.dataSource = new ModelDescriptionDataSource().deserialize(this.params['data_source']);
        }
        else if (this.params['resource']) {
            // Backward compatibility
            this.dataSource = migrateModelDescriptionDataSource(ModelDescriptionDataSource, ModelDescriptionQuery, {
                resource: this.params['resource'],
                query: this.params['get_query'],
                parameters: this.params['parameters'],
                inputs: this.params['inputs'],
                columns: this.params['columns']
            });
        }
        if (this.params['column_actions']) {
            this.columnActions = this.params['column_actions']
                .filter(function (item) { return item['actions']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: item['actions'].map(function (subItem) { return new ViewSettingsAction().deserialize(subItem); })
                };
            });
        }
        else if (this.params['data_source'] && this.params['data_source']['columns']) {
            // Backward compatibility
            this.columnActions = this.params['data_source']['columns']
                .filter(function (item) { return item['action']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: [new ViewSettingsAction().deserialize(item['action'])]
                };
            });
        }
        return this;
    };
    ModelElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['title_input'] = this.titleInput ? this.titleInput.serialize() : null;
        this.params['data_source'] = this.dataSource ? this.dataSource.serialize() : undefined;
        this.params['column_actions'] = this.columnActions.map(function (item) {
            return {
                name: item.name,
                actions: item.actions.map(function (action) { return action.serialize(); })
            };
        });
        this.params['tooltip'] = this.tooltip;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ModelElementItem.prototype, "typeStr", {
        get: function () {
            return 'displayed detailed record data';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelElementItem.prototype, "analyticsName", {
        get: function () {
            return 'detail';
        },
        enumerable: true,
        configurable: true
    });
    ModelElementItem.prototype.defaultName = function () {
        return 'Detail';
    };
    return ModelElementItem;
}(ElementItem));
export { ModelElementItem };
registerElementForType(ElementType.Model, ModelElementItem);

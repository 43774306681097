<div
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="openable"
  [class.sidebar-collapse_block]="block"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="disabled"
  [ngClass]="classes"
>
  <div
    class="sidebar-collapse__header"
    [class.sidebar-collapse__header_error]="error"
    [appTip]="!opened && error ? error : undefined"
    (click)="toggleOpened()"
  >
    <div *ngIf="!block" class="sidebar-collapse__header-left">
      <span class="sidebar-collapse__header-icon sidebar-collapse__header-icon_arrow icon-arrow_forward_2"></span>
    </div>
    <div *ngIf="block && drag" class="sidebar-collapse__header-left">
      <div class="sidebar-collapse__header-drag"></div>
    </div>
    <div class="sidebar-collapse__header-main">
      <div class="sidebar-collapse__header-line">
        <div class="sidebar-collapse__header-title">
          {{ title }}
        </div>

        <ng-container *ngIf="titleAdditional">
          <ng-container *ngIf="titleAdditionalOptions.length">
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
              [cdkConnectedOverlayPositions]="titleAdditionalOptionsPositions"
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="titleAdditionalOptionsOpened"
              [cdkConnectedOverlayPush]="true"
              [cdkConnectedOverlayGrowAfterOpen]="true"
              [cdkConnectedOverlayHasBackdrop]="true"
              [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
              (backdropClick)="setTitleAdditionalOptionsOpened(false)"
              (detach)="setTitleAdditionalOptionsOpened(false)"
            >
              <div class="popover2 overlay-content overlay-content_animated">
                <div class="popover2__inner">
                  <div class="popover2__viewport">
                    <div class="popover2__content" style="max-width: 300px;">
                      <div class="token-popup__item">
                        <a
                          *ngFor="let option of titleAdditionalOptions; let first = first"
                          href="javascript:void(0)"
                          class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                          [class.token-popup-list-item_active]="titleAdditionalSelectedOption === option.value"
                          [style.margin-top.px]="first ? 0 : 5"
                          (click)="
                            titleAdditionalOptionSelected.emit(option.value); setTitleAdditionalOptionsOpened(false)
                          "
                        >
                          <div class="token-popup-list-item__inner">
                            <div class="token-popup-list-item__line">
                              <span class="token-popup-list-item__left">
                                <span
                                  class="token-popup-list-item__icon"
                                  [class.icon-check]="titleAdditionalSelectedOption === option.value"
                                  [class.icon-uncheck]="titleAdditionalSelectedOption !== option.value"
                                ></span>
                              </span>

                              <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                                {{ option.name }}
                              </span>
                            </div>

                            <div
                              *ngIf="option.subtitle | appIsSet"
                              class="token-popup-list-item__line"
                              [class.token-popup-list-item__line_active]="false"
                            >
                              <div
                                class="token-popup-list-item__description"
                                [innerHTML]="option.subtitle | appCapitalize"
                              ></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="popover2__arrow"></div>
                </div>
              </div>
            </ng-template>

            <div
              class="sidebar-collapse__header-title-action"
              (click)="onTitleAdditionalOptionsClick($event)"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            >
              <div
                class="sidebar-collapse__header-additional"
                [class.sidebar-collapse__header-additional_error]="titleAdditionalRed"
              >
                {{ titleAdditional }}
              </div>

              <span
                class="sidebar-collapse__header-title-action-icon"
                [class.icon-arrow_up_2]="titleAdditionalOptionsOpened"
                [class.icon-arrow_down_2]="!titleAdditionalOptionsOpened"
              ></span>
            </div>
          </ng-container>

          <div
            *ngIf="!titleAdditionalOptions.length"
            class="sidebar-collapse__header-additional sidebar-collapse__header-title-additional"
            [class.sidebar-collapse__header-additional_error]="titleAdditionalRed"
          >
            {{ titleAdditional }}
          </div>
        </ng-container>
      </div>

      <div
        *ngIf="!opened && (description | appIsSet)"
        class="sidebar-collapse__header-description"
        [class.sidebar-collapse__header-description_wrap]="descriptionWrap"
      >
        {{ description }}
      </div>

      <div *ngIf="opened && (descriptionWhenOpened | appIsSet)" class="sidebar-collapse__header-description">
        {{ descriptionWhenOpened }}
      </div>
    </div>
    <div class="sidebar-collapse__header-right">
      <ng-content select="ng-container[right]"></ng-content>
      <span
        *ngIf="error"
        class="sidebar-collapse__header-icon sidebar-collapse__header-icon_error icon-warning_filled"
      ></span>
      <span
        *ngIf="arrow && !error"
        class="sidebar-collapse__header-icon sidebar-collapse__header-icon_arrow icon-arrow_forward_2"
      ></span>
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content" [class.sidebar-collapse__content_padding]="padding">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="modelDescription && !loading" class="delete">
  <div class="section section_top">
    <div class="section__inner">
      <div class="change__header">
        <h1 class="change__title">
          <a [appLink]="modelDescription.link" [queryParams]="listParams" class="change__title-back">
            <span class="icon icon-arrow_backward_3"></span> {{ modelDescription.verboseNamePlural }}
          </a>
          – Delete
        </h1>
      </div>

      <div style="margin: 20px 30px;">
        <app-alert [title]="'Warning'" [danger]="true" [border]="true">
          You are going to delete <strong>{{ modelDescription.verboseNamePlural }}</strong> items
          <ng-container *ngIf="count != undefined">: {{ count }}</ng-container>
        </app-alert>
      </div>

      <div *ngIf="getItemList" class="card">
        <div class="card__inner">
          <ul>
            <li>The following items will be removed:</li>
            <li>---</li>
            <li *ngFor="let item of items">
              <a [appLink]="item.getLink()" [innerHTML]="item | appModelStr | appStub | async"></a>
            </li>
            <li *ngIf="items.length < count">...{{ count - items.length }} more</li>
          </ul>
        </div>
      </div>

      <div class="change__submit">
        <a
          href="javascript:void(0)"
          class="button button_primary button_danger"
          [class.button_disabled]="deleteStarted || deleteProcessed == count"
          style="margin-right: 10px;"
          (click)="confirm()"
        >
          <app-loader-small
            *ngIf="deleteStarted && deleteProcessed < count"
            class="button__icon button__icon_left"
          ></app-loader-small>
          <span class="button__label">Confirm</span>
        </a>
        <a *ngIf="!deleteStarted" [appLink]="modelDescription.link" [queryParams]="listParams" class="button">
          <span class="button__label">Cancel</span>
        </a>
      </div>

      <ng-container *ngIf="deleteStarted">
        <app-bar-progress
          [label]="deleteProcessed == count ? 'Finished' : 'Deleting...'"
          [progress]="deleteProcessed / count"
          [processed]="deleteProcessed"
          [errors]="deleteProcessed - deleteDeleted"
          [total]="count"
        >
        </app-bar-progress>

        <div *ngIf="deleteProcessed == count" class="change__submit">
          <a [appLink]="modelDescription.link" [queryParams]="listParams" class="button button_primary">
            <span class="button__label">Continue</span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

import { Input } from '@modules/fields';

@Pipe({
  name: 'appFieldInput'
})
export class FieldInputPipe implements PipeTransform {
  transform(value: Object): Input {
    const input = new Input();

    if (value) {
      input.deserialize(value);
    }

    return input;
  }
}

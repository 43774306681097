import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { Option } from '@modules/field-components';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import {
  FirebaseDatabaseOption,
  FirebaseDatabasesResponse,
  FirebaseDatabaseState,
  FirebaseDatabaseType,
  FirebaseFirestoreCollectionIdsResponse,
  FirebaseResourceController
} from '@modules/resources';
import { controlValue, isSet, readFileText } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { FirebaseResourceSettingsForm } from './firebase-resource-settings.form';

@Component({
  selector: 'app-firebase-resource-settings',
  templateUrl: './firebase-resource-settings.component.html',
  providers: [FirebaseResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirebaseResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  databaseOptions: Option<FirebaseDatabaseOption>[] = [];
  databaseOptionsLoading = false;
  databaseOptionsError: string;

  constructor(
    private chooseSyncController: ChooseSyncController,
    private firebaseResourceController: FirebaseResourceController,
    public form: FirebaseResourceSettingsForm,
    popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest(
      controlValue(this.form.form.controls['project_id']),
      controlValue(this.form.form.controls['access_token'])
    )
      .pipe(
        debounceTime(200),
        switchMap<
          [string, string],
          {
            formValid: boolean;
            firestoreResponse?: FirebaseFirestoreCollectionIdsResponse;
            realtimeDatabaseResponse?: FirebaseDatabasesResponse;
          }
        >(([projectId, accessToken]) => {
          this.databaseOptionsLoading = true;
          this.databaseOptionsError = undefined;
          this.cd.markForCheck();

          if (!isSet(projectId) || !isSet(accessToken)) {
            return of({
              formValid: false
            });
          }

          return combineLatest(
            this.firebaseResourceController
              .getFirestoreCollectionIds(projectId, accessToken)
              .pipe(catchError(() => of(undefined))),
            this.firebaseResourceController
              .getRealtimeDatabases(projectId, accessToken)
              .pipe(catchError(() => of(undefined)))
          ).pipe(
            map(([firestoreResponse, realtimeDatabaseResponse]) => {
              return {
                formValid: true,
                firestoreResponse: firestoreResponse,
                realtimeDatabaseResponse: realtimeDatabaseResponse
              };
            })
          );
        })
      )
      .subscribe(result => {
        const options: Option<FirebaseDatabaseOption>[] = [];

        if (result.firestoreResponse) {
          options.push({ value: { type: FirebaseDatabaseType.Firestore }, name: 'Firestore' });
        }

        if (result.realtimeDatabaseResponse && result.realtimeDatabaseResponse.instances) {
          options.push(
            ...result.realtimeDatabaseResponse.instances
              .filter(item => item.state == FirebaseDatabaseState.Active)
              .map(item => {
                const databaseName = item.name.split('/').slice(-1)[0];
                return {
                  value: { type: FirebaseDatabaseType.Realtime, id: item.databaseUrl },
                  name: `Realtime - ${databaseName}`
                };
              })
          );
        }

        this.databaseOptions = options;
        this.databaseOptionsLoading = false;

        if (!result.formValid) {
          this.databaseOptionsError = undefined;
        } else if (!result.firestoreResponse && !result.realtimeDatabaseResponse) {
          this.databaseOptionsError =
            'Error loading databases, please check your Service Token and whether Firestore or Realtime database is enabled in your Firebase project';
        } else {
          this.databaseOptionsError = undefined;
        }

        this.cd.markForCheck();
      });

    this.form.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  onFileChange(field: string, el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    readFileText(file)
      .pipe(untilDestroyed(this))
      .subscribe(content => {
        this.form.form.patchValue({ [field]: content });
        this.form.form.markAsDirty();
      });
  }

  chooseSync() {
    const databaseOption: FirebaseDatabaseOption = this.form.form.controls['database_option'].value;

    if (databaseOption && databaseOption.type == FirebaseDatabaseType.Realtime) {
      this.form.form.controls['sync'].patchValue(false);
      this.submit();
    } else {
      this.chooseSyncController
        .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
        .pipe(untilDestroyed(this))
        .subscribe(() => this.submit());
    }
  }
}

registerResourceSettingsComponent(ResourceName.Firebase, FirebaseResourceSettingsComponent);

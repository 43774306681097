/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./sign-up-field-control.component";
var styles_SignUpFieldControlComponent = [];
var RenderType_SignUpFieldControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignUpFieldControlComponent, data: {} });
export { RenderType_SignUpFieldControlComponent as RenderType_SignUpFieldControlComponent };
function View_SignUpFieldControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "sidebar-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-list-item__icon"; var currVal_1 = ("icon-" + _co.property.fieldDescription.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SignUpFieldControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.property.fieldDescription.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_SignUpFieldControlComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "sidebar-list-item sidebar-list-item_clickable"]], [[2, "sidebar-list-item_draggable", null], [2, "sidebar-list-item_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editProperty() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "sidebar-list-item__drag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignUpFieldControlComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignUpFieldControlComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "a", [["class", "sidebar-list-item__action icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.remove.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.property == null) ? null : _co.property.fieldDescription); _ck(_v, 4, 0, currVal_2); var currVal_4 = ((_co.property == null) ? null : _co.property.fieldDescription); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (((_co.property == null) ? null : _co.property.name) || "no name"); _ck(_v, 7, 0, currVal_3); }); }
export function View_SignUpFieldControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sign-up-field-control", [], null, null, null, View_SignUpFieldControlComponent_0, RenderType_SignUpFieldControlComponent)), i0.ɵdid(1, 245760, null, 0, i2.SignUpFieldControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignUpFieldControlComponentNgFactory = i0.ɵccf("app-sign-up-field-control", i2.SignUpFieldControlComponent, View_SignUpFieldControlComponent_Host_0, { control: "control", property: "property", analyticsSource: "analyticsSource" }, { edit: "edit", remove: "remove" }, []);
export { SignUpFieldControlComponentNgFactory as SignUpFieldControlComponentNgFactory };

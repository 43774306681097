import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { intercomResourceParamsMenuSettings } from '../../data/intercom/intercom-resource-params-menu-settings.stub';
import { intercomResourceParamsModelDescriptions } from '../../data/intercom/intercom-resource-params-model-descriptions.stub';
import { intercomResourceParamsParams } from '../../data/intercom/intercom-resource-params-params.stub';
import { intercomResourceParamsViewSettings } from '../../data/intercom/intercom-resource-params-view-settings.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface IntercomParamsOptions {
  key: string;
}

@Injectable()
export class IntercomGeneratorService extends ResourceGeneratorService<IntercomParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: IntercomParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://api.intercom.io/contacts';
    query.headers = [{ name: 'Authorization', value: `Bearer ${options.key}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('API Key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<IntercomParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            key: result.value
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: IntercomParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = intercomResourceParamsParams;
    const modelDescriptions = intercomResourceParamsModelDescriptions;
    const viewSettings = intercomResourceParamsViewSettings;
    const menuSettings = intercomResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = options.key;

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      viewSettings: viewSettings,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()]
    });
  }
}

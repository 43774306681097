import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UserActivity, UserActivityDisplayService, UserActivityType } from '@modules/activities';
import { CustomViewSettings, ElementType, ViewSettingsStore } from '@modules/customize';
import { isSet } from '@shared';

@Component({
  selector: 'app-user-activities-item, [app-user-activities-item]',
  templateUrl: './user-activities-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivitiesItemComponent implements OnInit, OnDestroy {
  @Input() item: UserActivity;

  relations: Object;
  pageInfo: {
    page: CustomViewSettings;
    element?: {
      type: ElementType;
      label: string;
    };
  };
  userActivityTypes = UserActivityType;

  constructor(
    private userActivityDisplayService: UserActivityDisplayService,
    private viewSettingsStore: ViewSettingsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateRelations();
    this.updatePageInfo();
  }

  ngOnDestroy(): void {}

  updateRelations() {
    this.userActivityDisplayService
      .getActionRelations(this.item)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.relations = result;
          this.cd.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  updatePageInfo() {
    if (!isSet(this.item.viewSettings)) {
      this.pageInfo = undefined;
      this.cd.markForCheck();
      return;
    }

    this.viewSettingsStore
      .getDetailByUidFirst<CustomViewSettings>(this.item.viewSettings)
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.pageInfo = viewSettings
          ? {
              page: viewSettings
            }
          : undefined;
        this.cd.markForCheck();
      });
  }
}

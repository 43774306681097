<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<app-layout
  *ngIf="!domainLoading"
  class="theme-enabled"
  appDomainThemeOptions
  [domain]="domain"
  [layout]="layout"
  [backgroundImage]="domain?.signUpBackgroundImage"
  [backgroundColor]="domain?.signUpBackgroundColor"
  [fillScreen]="true"
>
  <app-page-block [marginSize]="'l'" [paddingSize]="'none'">
    <app-page-header [marginSize]="'l'" [first]="layout.startsWith('poster_')">
      <app-page-logo [align]="'center'">
        <app-project-logo
          *ngIf="isWhiteLabel && domain && (domain.logo || domain.initials)"
          [color]="domain.logoColor ? domain.logoColor : ''"
          [logo]="domain.logo ? domain.logo : ''"
          [initials]="domain.initials ? domain.initials : ''"
        ></app-project-logo>

        <app-project-logo
          *ngIf="!isWhiteLabel"
          [logo]="'/assets/images/logo.svg' | appDeployUrl"
          [size]="'sm'"
        ></app-project-logo>
      </app-page-logo>

      <app-page-title [align]="'center'" [strong]="true">
        {{ 'Reset your password' | localize }}
      </app-page-title>

      <app-page-description [align]="'center'">
        <ng-template [appTr]="{}">
          Almost done, just enter your new password below. <br />Must be al least 8 characters.
        </ng-template>
      </app-page-description>
    </app-page-header>

    <app-page-block *ngIf="(form?.form | appFormErrors)?.length">
      <div class="error-notification">
        <div class="error-notification__background"></div>
        <div class="error-notification__content">
          <a href="javascript:void(0)" (click)="cleanErrorForm()" class="error-notification__close icon-close"></a>
          <div class="error-notification__title">{{ 'Unable to Change Password' | localize }}</div>
          <div *ngFor="let error of form.form | appFormErrors" class="error-notification__description">
            {{ error }}
          </div>
        </div>
      </div>
    </app-page-block>

    <form [formGroup]="form.form" (ngSubmit)="submit()">
      <app-page-block>
        <app-text-control
          [control]="form.form.controls['password']"
          [iconLeft]="'lock_close'"
          [placeholder]="'Password' | localize"
          [autofocus]="true"
          [autocomplete]="'new-password'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control
          [control]="form.form.controls['password_repeat']"
          [iconLeft]="'lock_close'"
          [placeholder]="'Repeat Password' | localize"
          [autocomplete]="'new-password'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <button
          type="submit"
          class="button button_bigger button_fill button_no-margin"
          [class.button_primary]="!domain?.signUpAccentColor"
          [appButtonTintColor]="domain?.signUpAccentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
          [disabled]="form.form.invalid || form.form.disabled || loading"
          (click)="submit()"
        >
          <app-loader-small *ngIf="loading" class="button__icon"></app-loader-small>
          <span *ngIf="!loading" class="button__label">{{ 'Reset password & Sign In' | localize }}</span>
        </button>
      </app-page-block>
    </form>
  </app-page-block>

  <ng-container *ngIf="layout == 'form'" data-layout-footer>
    <a [routerLink]="signInLink" queryParamsHandling="preserve" class="link-block form-layout__footer-item">
      {{ 'Return to Sign In' | localize }}
    </a>
  </ng-container>

  <app-page-block *ngIf="layout != 'form'" [marginSize]="'l'" data-layout-footer>
    <a [routerLink]="signInLink" queryParamsHandling="preserve" class="link-block">
      {{ 'Return to Sign In' | localize }}
    </a>
  </app-page-block>
</app-layout>

<app-language-selector></app-language-selector>

<app-custom-code
  *ngIf="domain"
  [appendScripts]="domain.appendScripts"
  [appendStyles]="domain.appendStyles"
></app-custom-code>

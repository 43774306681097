import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
var SidebarCollapseContext = /** @class */ (function () {
    function SidebarCollapseContext() {
        this._opened = new BehaviorSubject(undefined);
    }
    Object.defineProperty(SidebarCollapseContext.prototype, "opened", {
        get: function () {
            return this._opened.value;
        },
        set: function (value) {
            this._opened.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarCollapseContext.prototype, "opened$", {
        get: function () {
            return this._opened.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return SidebarCollapseContext;
}());
export { SidebarCollapseContext };
var SidebarCollapseComponent = /** @class */ (function () {
    function SidebarCollapseComponent(cd) {
        this.cd = cd;
        this.titleAdditionalRed = false;
        this.titleAdditionalOptions = [];
        this.descriptionWrap = false;
        this.block = false;
        this.openedInitial = false;
        this.openable = true;
        this.disabled = false;
        this.arrow = false;
        this.drag = false;
        this.padding = true;
        this.titleAdditionalOptionSelected = new EventEmitter();
        this.openedChanged = new EventEmitter();
        this.opened = false;
        this.titleAdditionalOptionsOpened = false;
        this.titleAdditionalOptionsPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center'
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center'
            }
        ];
    }
    SidebarCollapseComponent.prototype.ngOnInit = function () {
        this.init();
    };
    SidebarCollapseComponent.prototype.ngOnDestroy = function () { };
    SidebarCollapseComponent.prototype.ngOnChanges = function (changes) {
        if (changes['context'] && !changes['context'].firstChange) {
            this.init();
        }
    };
    SidebarCollapseComponent.prototype.ngAfterViewInit = function () {
        this.setTitleAdditionalOptionsPositionObserver();
    };
    SidebarCollapseComponent.prototype.init = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
            this.contextSubscription = undefined;
        }
        if (this.context) {
            this.contextSubscription = this.context.opened$.pipe(untilDestroyed(this)).subscribe(function (opened) {
                _this.opened = opened === _this;
                _this.cd.markForCheck();
            });
        }
        if (this.openedInitial) {
            this.setOpened(true);
        }
    };
    SidebarCollapseComponent.prototype.isOpened = function () {
        return this.opened;
    };
    SidebarCollapseComponent.prototype.setOpened = function (opened) {
        if (!this.openable) {
            return;
        }
        if (this.context) {
            if (opened && this.context.opened !== this) {
                this.context.opened = this;
            }
            else if (!opened && this.context.opened === this) {
                this.context.opened = undefined;
            }
        }
        else {
            this.opened = opened;
            this.cd.markForCheck();
        }
        this.openedChanged.emit(this.opened);
    };
    SidebarCollapseComponent.prototype.toggleOpened = function () {
        if (!this.openable) {
            return;
        }
        this.setOpened(!this.opened);
    };
    SidebarCollapseComponent.prototype.setTitleAdditionalOptionsOpened = function (value) {
        this.titleAdditionalOptionsOpened = value;
        this.cd.markForCheck();
    };
    SidebarCollapseComponent.prototype.onTitleAdditionalOptionsClick = function (event) {
        if (this.opened) {
            event.stopPropagation();
        }
        this.setTitleAdditionalOptionsOpened(true);
    };
    SidebarCollapseComponent.prototype.setTitleAdditionalOptionsPositionObserver = function () {
        var _this = this;
        if (this.titleAdditionalOptionsPositionsSubscription) {
            this.titleAdditionalOptionsPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.titleAdditionalOptionsPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.titleAdditionalOptionsPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.titleAdditionalOptionsPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return SidebarCollapseComponent;
}());
export { SidebarCollapseComponent };

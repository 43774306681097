/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../field-components/components/previews/image-preview/image-preview.component.ngfactory";
import * as i2 from "../../../field-components/components/previews/image-preview/image-preview.component";
import * as i3 from "../../../../common/popups/services/popup/popup.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../project-api/services/project-api/project-api.service";
import * as i6 from "@angular/common";
import * as i7 from "./image-element.component";
import * as i8 from "../../../customize/services/customize/customize.service";
import * as i9 from "../../../projects/stores/current-environment.store";
import * as i10 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i11 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i12 from "../../../../common/notifications/services/notification/notification.service";
var styles_ImageElementComponent = [];
var RenderType_ImageElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageElementComponent, data: {} });
export { RenderType_ImageElementComponent as RenderType_ImageElementComponent };
function View_ImageElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-image-preview", [], [[4, "cursor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(i0.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ImagePreviewComponent_0, i1.RenderType_ImagePreviewComponent)), i0.ɵdid(2, 4964352, [["image_element", 4]], 0, i2.ImagePreviewComponent, [i3.PopupService, i4.DomSanitizer, i5.ProjectApiService, i0.ChangeDetectorRef, i0.Injector], { value: [0, "value"], url: [1, "url"], noBackground: [2, "noBackground"], fit: [3, "fit"], type: [4, "type"], lightBox: [5, "lightBox"], preview: [6, "preview"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.value; var currVal_2 = _co.preview; var currVal_3 = true; var currVal_4 = _co.element.fit; var currVal_5 = _co.element.displayType; var currVal_6 = _co.element.lightbox; var currVal_7 = _co.element.showPreview; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.element.clickAction ? "pointer" : null); _ck(_v, 1, 0, currVal_0); }); }
export function View_ImageElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ImageElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeEnabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImageElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-element", [], null, null, null, View_ImageElementComponent_0, RenderType_ImageElementComponent)), i0.ɵdid(1, 770048, null, 0, i7.ImageElementComponent, [i8.CustomizeService, i0.ChangeDetectorRef, i9.CurrentEnvironmentStore, i10.ResourceControllerService, i11.ActionControllerService, i12.NotificationService, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageElementComponentNgFactory = i0.ɵccf("app-image-element", i7.ImageElementComponent, View_ImageElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { ImageElementComponentNgFactory as ImageElementComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

import { AppConfigService } from '@core';
import { TintStyle } from '@modules/actions';
import { AnalyticsEvent } from '@modules/analytics';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectInvite, ProjectPublicInvite } from '@modules/projects';
import { SSOSettings, SSOType } from '@modules/sso';
import { ThemeContext } from '@modules/theme-components';
import { isSet, TypedChanges } from '@shared';

import { LoginForm } from '../login/login.form';

@Component({
  selector: 'app-login-template',
  templateUrl: './login-template.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginTemplateComponent implements OnInit, OnChanges {
  @Input() form: LoginForm;
  @Input() title = 'Good morning';
  @Input() domainLoading = false;
  @Input() submitLoading = false;
  @Input() screenLoader = false;
  @Input() domain: Domain;
  @Input() currentSSO: SSOSettings;
  @Input() invite: ProjectInvite;
  @Input() inviteLoading = false;
  @Input() publicInvite: ProjectPublicInvite;
  @Input() publicInviteLoading = false;
  @Input() signUpLink: any[];
  @Input() restoreLink: any[];
  @Input() fillScreen = false;
  @Input() preview = false;
  @Output() socialLogin = new EventEmitter<string>();
  @Output() ssoLogin = new EventEmitter<SSOSettings>();
  @Output() ssoReset = new EventEmitter<void>();
  @Output() cleanErrorForm = new EventEmitter<void>();
  @Output() submitJetAuth = new EventEmitter<void>();
  @Output() submitSSOAuth = new EventEmitter<SSOSettings>();

  createField = createFormFieldFactory();
  defaultLayout = defaultSignUpLayout;
  externalFonts: string[] = [];
  tintStyles = TintStyle;
  analyticsEvents = AnalyticsEvent;

  constructor(public appConfigService: AppConfigService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<LoginTemplateComponent>): void {
    if (changes.domain) {
      this.externalFonts = this.getExternalFonts(this.domain);
    }
  }

  getExternalFonts(domain: Domain): string[] {
    if (!domain) {
      return [];
    }

    return [domain.signUpFontRegular, domain.signUpFontHeading].filter(item => isSet(item));
  }

  get layout() {
    return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
  }

  get headerHasContent() {
    const hasLogo = !this.isWhiteLabel || (this.domain && (this.domain.logo || this.domain.initials));
    const hasTitle = !this.domain || this.domain.signInTitle;
    const hasDescription = this.domain && this.domain.signInDescription;

    return hasLogo || hasTitle || hasDescription;
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  get isGoogleSignEnabled() {
    const disabledGoogleSignIn = this.domain ? this.domain.disableGoogleSignIn : false;
    return !this.isWhiteLabel && this.appConfigService.socialAuthGoogleEnabled && !disabledGoogleSignIn;
  }

  get isFacebookSignEnabled() {
    const disabledFacebookSignIn = this.domain ? this.domain.disableFacebookSignIn : false;
    return !this.isWhiteLabel && this.appConfigService.socialAuthFacebookEnabled && !disabledFacebookSignIn;
  }

  get isSignInWithEnabled() {
    const ssoSettings = this.domain ? this.domain.ssoSettings : [];
    return this.isGoogleSignEnabled || this.isFacebookSignEnabled || ssoSettings.length;
  }

  get isFormEnabled() {
    return !(this.domain && this.domain.disableEmailSignIn) || this.currentSSO;
  }

  startSSOLogin(sso: SSOSettings) {
    this.ssoLogin.emit(sso);
  }

  resetSSOLogin() {
    this.ssoReset.emit();
  }

  onSubmit() {
    if (this.currentSSO) {
      this.submitSSOAuth.emit(this.currentSSO);
    } else {
      this.submitJetAuth.emit();
    }
  }
}

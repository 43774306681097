import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent, TimeFormat } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { TimeFieldViewParamsForm } from './time-field-view-params.form';

@Component({
  selector: 'app-time-field-view-params',
  templateUrl: './time-field-view-params.component.html',
  providers: [TimeFieldViewParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeViewFieldParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();
  timeFormats = TimeFormat;

  constructor(public form: TimeFieldViewParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }
}

registerFieldViewParamsComponent(FieldType.Time, TimeViewFieldParamsComponent);

import { FormArray } from '@angular/forms';
import range from 'lodash/range';

import { ChartWidgetGroup } from '@modules/dashboard';

import { ChartWidgetGroupControl } from './chart-widget-group.control';

export class ChartWidgetGroupArray extends FormArray {
  controls: ChartWidgetGroupControl[];

  deserialize(instances: ChartWidgetGroup[]) {
    const controls = instances.map(item => new ChartWidgetGroupControl().deserialize(item));
    this.set(controls);
  }

  serialize(): ChartWidgetGroup[] {
    return this.controls.map(item => item.serialize());
  }

  set(controls: ChartWidgetGroupControl[]) {
    range(this.controls.length).forEach(() => this.removeAt(0));
    controls.forEach(item => this.push(item));
    this.updateValueAndValidity();
  }

  append(control: ChartWidgetGroupControl) {
    this.push(control);
    this.updateValueAndValidity();
  }

  remove(control: ChartWidgetGroupControl) {
    const index = this.controls.findIndex(item => item === control);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }
}

<app-page-block>
  <div class="field">
    <div class="field__label">
      spreadsheet
    </div>
    <div class="field__value">
      <gxs-select
        [formControl]="form.controls.file"
        [source]="filesSource"
        [placeholder]="'Choose File'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 0,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      >
      </gxs-select>
    </div>
    <div *ngIf="form.controls.file.value" class="field__description field__description_small">
      <a [href]="'https://docs.google.com/spreadsheets/d/' + form.controls.file.value.id" target="_blank">
        View in Google Sheets
      </a>
    </div>
  </div>
</app-page-block>

<ng-template #range_description>
  with column headers, e.g. A:D or A1:B99
</ng-template>

<ng-container *ngIf="!isEditable">
  <app-page-block>
    <div class="field">
      <div class="field__label">name</div>
      <div class="field__value">
        <div class="input input_fill input_disabled">
          <span [class.loading-animation]="sheetLoading$ | async"><span class="stub-text">Filename</span></span>
        </div>
      </div>
    </div>
  </app-page-block>

  <app-page-block>
    <div class="field">
      <div class="field__label">sheet</div>
      <div class="field__value">
        <div class="input input_fill input_disabled">
          <span [class.loading-animation]="sheetLoading$ | async"><span class="stub-text">Sheet 1</span></span>
        </div>
      </div>
    </div>
  </app-page-block>

  <app-page-block>
    <div class="field">
      <div class="field__label">
        range
      </div>
      <div class="field__value">
        <div class="input input_fill input_disabled">
          <span [class.loading-animation]="sheetLoading$ | async"><span class="stub-text">A1:B999</span></span>
        </div>
      </div>
      <div class="field__description">
        <ng-container *ngTemplateOutlet="range_description"></ng-container>
      </div>
    </div>
  </app-page-block>
</ng-container>

<ng-container *ngIf="isEditable">
  <app-page-block>
    <app-auto-field
      [form]="form"
      [field]="
        createField({
          name: 'verbose_name',
          label: 'name',
          field: 'CharField',
          placeholder: 'Enter display name',
          required: true,
          params: { classes: ['input_fill'] }
        })
      "
    ></app-auto-field>
  </app-page-block>

  <app-page-block>
    <app-auto-field
      [form]="form"
      [field]="
        createField({
          name: 'sheet',
          label: 'sheet',
          field: 'SelectField',
          placeholder: 'Choose Sheet',
          required: true,
          params: { options: sheetOptions, valueEquals: sheetOptionsEquals, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-page-block>

  <app-page-block>
    <div class="field">
      <div class="field__label">
        range
      </div>
      <div class="field__value">
        <app-auto-field
          *ngIf="!(rangeLoading$ | async)"
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'range',
              field: 'CharField',
              placeholder: 'Enter range',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
        <div *ngIf="rangeLoading$ | async" class="input input_fill input_disabled">
          <span [class.loading-animation]="rangeLoading$ | async"><span class="stub-text">A1:B999</span></span>
        </div>
      </div>
      <div class="field__description">
        <ng-container *ngTemplateOutlet="range_description"></ng-container>
      </div>
    </div>
  </app-page-block>
</ng-container>

<div *ngIf="delete" class="item-selector__detail-buttons">
  <a
    href="javascript:void(0)"
    class="button button_danger-secondary"
    [class.button_disabled]="deleteDisabled"
    (click)="deleted.emit()"
  >
    <span class="icon-bin button__icon button__icon_left"></span>
    <span class="button__label">Delete</span>
  </a>
</div>

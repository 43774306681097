import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent implements OnInit {
  @Input() label: string;
  @Input() labelPosition: 'left' | 'right' = 'right';
  @Input() trackLabel: string;
  @Input() orange = false;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() loading = false;

  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html'
})
export class FieldErrorsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldName: string;

  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import {
  CarouselControlsPosition,
  CarouselSettings,
  CarouselStep,
  ListDefaultSelection,
  ViewContext
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Option } from '@modules/field-components';
import { DisplayField, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { View } from '@modules/views';
import { controlValue, generateAlphanumeric, isSet } from '@shared';

import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { MarginControl } from '../../margin-control/margin-control.component';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { GridCardsPerRow } from '../customize-bar-grid-settings/customize-bar-grid-settings.form';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';

@Injectable()
export class CustomizeBarCarouselSettingsForm extends CustomizeBarListLayoutSettingsForm<CarouselSettings> {
  context: ViewContext;

  controls: {
    header: FormControl;
    title: FieldInputControl;
    data_source: ListModelDescriptionDataSourceControl;
    controls_position: FormControl;
    step: FormControl;
    step_custom: FormControl;
    scroll_navigation: FormControl;
    drag_navigation: FormControl;
    snap_on_navigate: FormControl;
    card_view_unique_name: FormControl;
    card_view: FormControl;
    card_view_mappings: FormControl;
    search_enabled: FormControl;
    search_live: FormControl;
    card_click_action: FormControl;
    actions: FormControl;
    model_actions: FormControl;
    columns_actions: FieldActionsArray;
    custom_actions: ActionOutputFormGroup;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    per_page: FormControl;
    cards_per_row: FormControl;
    cards_per_row_custom: FormControl;
    multiple_selection: FormControl;
    first_item_default_selection: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    display_footer: FormControl;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;
    gap_horizontal: FormControl;
    margin: MarginControl;
  };

  ignoreSubmitControls: AbstractControl[] = [this.controls.card_view];
  submitLoading$ = new BehaviorSubject<boolean>(false);

  controlsPositionOptions: Option<CarouselControlsPosition>[] = [
    {
      name: 'Without controls',
      value: CarouselControlsPosition.None
    },
    {
      name: 'At sides',
      value: CarouselControlsPosition.SideOverlay
    },
    {
      name: 'In header',
      value: CarouselControlsPosition.Header
    }
  ];

  stepOptions: Option<CarouselStep>[] = [
    {
      name: '1 row',
      value: CarouselStep.Row
    },
    {
      name: 'Custom',
      value: CarouselStep.Custom
    }
  ];

  cardsPerRowOptions: Option<GridCardsPerRow>[] = [
    {
      name: 'Auto',
      value: GridCardsPerRow.Auto
    },
    {
      name: 'Specify number of cards',
      value: GridCardsPerRow.Custom
    }
  ];

  constructor(
    private modelOptionsSource: ModelOptionsSource,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ name: 'value' }),
        data_source: dataSourceControl,
        controls_position: new FormControl(CarouselControlsPosition.SideOverlay),
        step: new FormControl(CarouselStep.Row),
        step_custom: new FormControl(null),
        scroll_navigation: new FormControl(true),
        drag_navigation: new FormControl(false),
        snap_on_navigate: new FormControl(true),
        card_view_unique_name: new FormControl(null),
        card_view: new FormControl(null),
        card_view_mappings: new FormControl([]),
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        card_click_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        columns_actions: new FieldActionsArray([]),
        custom_actions: new ActionOutputFormGroup(elementConfigurationService),
        sorting_field: new FormControl(undefined),
        sorting_asc: new FormControl(true),
        per_page: new FormControl(undefined),
        cards_per_row: new FormControl(GridCardsPerRow.Auto),
        cards_per_row_custom: new FormControl(null),
        multiple_selection: new FormControl(false),
        first_item_default_selection: new FormControl(false),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        display_footer: new FormControl(true),
        visible_input: new FieldInputControl({ name: 'value' }),
        name: new FormControl(''),
        tooltip: new FormControl(''),
        gap_horizontal: new FormControl(null),
        margin: new MarginControl()
      }
    );
    dataSourceControl.setRequired(true);
  }

  init(options: CustomizeListOptions<CarouselSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;

    const customView$ = isSet(options.settings.cardCustomView)
      ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
      : of(undefined);

    return customView$.pipe(
      tap(customView => {
        const value = {
          header: options.settings.header,
          title: options.settings.titleInput ? options.settings.titleInput.serialize() : {},
          controls_position: options.settings.controlsPosition,
          step: options.settings.step,
          step_custom: options.settings.stepCustom,
          scroll_navigation: options.settings.scrollNavigation,
          drag_navigation: options.settings.dragNavigation,
          snap_on_navigate: options.settings.snapOnNavigate,
          card_view_unique_name: options.settings.cardCustomView,
          card_view: customView ? customView.view : null,
          card_view_mappings: options.settings.cardCustomViewMappings,
          search_enabled: options.settings.searchEnabled,
          search_live: options.settings.searchLive,
          card_click_action: options.settings.cardClickAction,
          actions: options.settings.actions,
          model_actions: options.settings.modelActions,
          columns_actions: options.settings.columnActions,
          sorting_field: options.settings.sortingField || null,
          sorting_asc: options.settings.sortingAsc,
          per_page: options.settings.perPage,
          ...(isSet(options.settings.cardsPerRow)
            ? {
                cards_per_row: GridCardsPerRow.Custom,
                cards_per_row_custom: options.settings.cardsPerRow
              }
            : {
                cards_per_row: GridCardsPerRow.Auto
              }),
          multiple_selection: options.settings.multipleSelection,
          first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
          display_filters: options.settings.displayFilters,
          filter_fields: options.settings.filterFields.map(item => {
            return {
              name: item.name,
              enabled: true
            };
          }),
          display_footer: options.settings.displayFooter,
          visible_input: options.visibleInput ? options.visibleInput.serialize() : {},
          tooltip: options.settings.tooltip,
          gap_horizontal: options.settings.gapHorizontal,
          margin: options.margin
        };

        if (options.nameEditable) {
          value['name'] = options.name;
        }

        this.patchValue(value, { emitEvent: false });

        this.controls.data_source.deserialize(options.settings.dataSource);
        this.controls.custom_actions.deserialize(
          customView && customView.view ? customView.view.actions : [],
          options.settings.customActions
        );

        this.trackChanges(options);
        this.initPerPageClean(this.controls.per_page);

        if (!options.firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  trackChanges(options: CustomizeListOptions<CarouselSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });

    this.controls.card_view.valueChanges
      .pipe(
        debounceTime(60),
        switchMap(value => {
          this.submitLoading$.next(true);

          return this.submitCardView(this.controls.card_view_unique_name.value, value);
        }),
        tap(uniqueName => {
          this.controls.card_view_unique_name.patchValue(uniqueName);

          this.submitLoading$.next(false);
        }),
        catchError(error => {
          this.submitLoading$.next(false);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getCardView(): View {
    return this.controls.card_view.value;
  }

  isConfigured(instance: CarouselSettings): Observable<boolean> {
    return this.elementConfigurationService.isListCarouselConfigured(instance, { restrictDemo: true });
  }

  submitCardView(uniqueName: string, view?: View): Observable<string> {
    if (!view) {
      return of(undefined);
    }

    const customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);

    return customView$.pipe(
      switchMap(customView => {
        if (customView) {
          const instance = cloneDeep(customView);
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.uniqueName =
            isSet(this.pageUid) && isSet(this.elementUid)
              ? [CustomViewType.ListItem, this.pageUid, this.elementUid].join('.')
              : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
          instance.viewType = CustomViewType.ListItem;
          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }

  submit(): CustomizeListResult<CarouselSettings> {
    const instance: CarouselSettings = cloneDeep(this.settings);

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.controlsPosition = this.controls.controls_position.value;
    instance.step = this.controls.step.value;
    instance.stepCustom = this.controls.step_custom.value;
    instance.scrollNavigation = this.controls.scroll_navigation.value;
    instance.dragNavigation = this.controls.drag_navigation.value;
    instance.snapOnNavigate = this.controls.snap_on_navigate.value;
    instance.cardCustomView = this.controls.card_view_unique_name.value;
    instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;

    if (this.controls.card_click_action.value) {
      instance.cardClickAction = this.controls.card_click_action.value;
    } else {
      instance.cardClickAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.columnActions = this.controls.columns_actions.value;
    instance.customActions = this.controls.custom_actions.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.perPage = this.controls.per_page.value;

    if (
      this.controls.cards_per_row.value == GridCardsPerRow.Custom &&
      isSet(this.controls.cards_per_row_custom.value)
    ) {
      instance.cardsPerRow = this.controls.cards_per_row_custom.value;
    } else {
      instance.cardsPerRow = null;
    }

    instance.multipleSelection = this.controls.multiple_selection.value;
    instance.defaultSelection = this.controls.first_item_default_selection.value
      ? ListDefaultSelection.First
      : undefined;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.displayFooter = this.controls.display_footer.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) ||
      !!instance.searchEnabled ||
      !!instance.displayFilters ||
      (instance.actions && instance.actions.length > 0) ||
      instance.controlsPosition == CarouselControlsPosition.Header;
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    instance.gapHorizontal = this.controls.gap_horizontal.value;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      margin: this.controls.margin.value
    };
  }
}

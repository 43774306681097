import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

import { PageLoadingService } from '../../services/page-loading/page-loading.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('element') element: ElementRef;

  constructor(private pageLoadingService: PageLoadingService) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.pageLoadingService
      .hasLoaders$()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(loading => {
        if (loading) {
          TweenMax.fromTo(
            this.element.nativeElement,
            10,
            {
              width: '0%'
            },
            {
              width: '50%',
              ease: Power2.easeOut
            }
          );
          TweenMax.to(this.element.nativeElement, 0.6, {
            yPercent: 100,
            ease: Power2.easeOut
          });
        } else {
          TweenMax.to(this.element.nativeElement, 1.0, {
            width: '100%',
            yPercent: 0,
            ease: Power2.easeOut
          });
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Task } from '../task';
import { TaskCompleteAction, TaskScenario } from '../task-scenario';

@Injectable({
  providedIn: 'root'
})
export class CreateGroupTask extends Task {
  init(): Observable<TaskScenario> | TaskScenario {
    return new TaskScenario([
      {
        link: ['project', 'teams'],
        items: [
          {
            name: 'click_create_group',
            tip: {
              text: 'Create a new Team with particular permissions',
              selector: '#create-group-page-button',
              completeAction: TaskCompleteAction.Click
            }
          }
        ]
      },
      {
        link: ['project', 'teams', 'create'],
        items: [
          {
            name: 'enter_name',
            tip: {
              text: 'Enter Team name',
              selector: '[id^=name]',
              completeAction: TaskCompleteAction.Input
            }
          },
          {
            name: 'set_permissions',
            tip: {
              text: 'Set some Project and Collection permissions for new Team',
              selector: '#group-permissions'
            }
          },
          {
            name: 'create_group',
            tip: {
              text: 'Create new Team',
              selector: '[type="submit"]',
              completeAction: TaskCompleteAction.Click
            }
          }
        ]
      },
      {
        link: ['project', 'teams'],
        noAutoRedirect: true,
        items: [
          {
            name: 'go_to_users',
            tip: {
              text: 'Go to Users',
              selector: '#project-menu-users',
              completeAction: TaskCompleteAction.Click
            }
          }
        ]
      },
      {
        link: ['project', 'users'],
        items: [
          {
            name: 'select_user',
            tip: {
              text: 'Select user to assign new Team',
              selector: '#project-users'
            }
          }
        ]
      },
      {
        link: ['project', 'users', '\\d+'],
        noAutoRedirect: true,
        items: [
          {
            name: 'select_group',
            tip: {
              text: 'Choose newly created Team',
              selector: '#user-group-selector'
            }
          },
          {
            name: 'save_user',
            tip: {
              text: 'Save user',
              selector: '[type="submit"]',
              completeAction: TaskCompleteAction.Click
            }
          },
          {
            name: 'finished'
          }
        ]
      }
    ]);
  }
}

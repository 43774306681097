<app-register-template
  *ngIf="!inviteLoading"
  [form]="form"
  [domainLoading]="domainLoading"
  [submitLoading]="submitLoading"
  [screenLoader]="screenLoader"
  [domain]="domain"
  [invite]="invite"
  [inviteLoading]="inviteLoading"
  [publicInvite]="publicInvite"
  [publicInviteLoading]="publicInviteLoading"
  [customFields]="customFields"
  [signInLink]="signInLink"
  [fillScreen]="true"
  (socialLogin)="socialLogin($event)"
  (ssoLogin)="ssoLogin($event)"
  (cleanErrorForm)="cleanErrorForm()"
  (submit)="submit()"
></app-register-template>

<app-language-selector></app-language-selector>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
export var BooleanFieldStyle;
(function (BooleanFieldStyle) {
    BooleanFieldStyle["Checkbox"] = "checkbox";
    BooleanFieldStyle["Toggle"] = "toggle";
})(BooleanFieldStyle || (BooleanFieldStyle = {}));
var BooleanFieldComponent = /** @class */ (function (_super) {
    __extends(BooleanFieldComponent, _super);
    function BooleanFieldComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.styles = BooleanFieldStyle;
        _this.defaultStyle = BooleanFieldStyle.Checkbox;
        return _this;
    }
    Object.defineProperty(BooleanFieldComponent.prototype, "style", {
        get: function () {
            return this.field.params['style'] || this.defaultStyle;
        },
        enumerable: true,
        configurable: true
    });
    BooleanFieldComponent.prototype.toggle = function () {
        var _a;
        if (this.control && this.control.disabled) {
            return;
        }
        this.form.patchValue((_a = {},
            _a[this.field.name] = !this.currentValue,
            _a));
    };
    return BooleanFieldComponent;
}(FieldComponent));
export { BooleanFieldComponent };
registerFieldComponent(FieldType.Boolean, BooleanFieldComponent);

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content">
          <div class="token-popup__item">
            <a
              *ngFor="let option of options; let first = first"
              href="javascript:void(0)"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              [class.token-popup-list-item_active]="option.value == type"
              [style.margin-top.px]="first ? 0 : 5"
              (click)="typeSelect.emit(option.value); setDropdownOpened(false)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span *ngIf="option.icon" class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + option.icon"></span>
                  </span>

                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                    {{ option.name }}
                  </span>
                </div>

                <div
                  *ngIf="option.subtitle | appIsSet"
                  class="token-popup-list-item__line"
                  [class.token-popup-list-item__line_active]="false"
                >
                  <div class="token-popup-list-item__description" [innerHTML]="option.subtitle | appCapitalize"></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="query-builder-list">
  <div class="query-builder-list__content">
    <div *ngFor="let item of protectedControls" class="query-builder-list__item">
      <div *ngIf="item.controls.description.value" class="query-builder-list-item-label">
        <strong>{{ item.controls.verboseName.value || item.controls.name.value }}</strong>
        {{ item.controls.description.value }}
      </div>
      <div class="query-builder-list-item">
        <div class="query-builder-list-item__left" style="padding-left: 20px;"></div>
        <div class="query-builder-list-item__main">
          <input
            class="input input_fill"
            [(ngModel)]="parameterValues[item.value['name']]"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="setTokenValue(item, parameterValues[item.value['name']])"
            [placeholder]="
              item.value['placeholder']
                ? controlPlaceholder(item.value['name']) + ' (' + item.value['placeholder'] + ')'
                : controlPlaceholder(item.value['name'])
            "
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="query-builder-list">
  <div class="query-builder-list__content">
    <div *ngFor="let item of commonControls" class="query-builder-list__item">
      <app-query-builder-parameters-edit-item
        [control]="item"
        [value]="parameterValues[item.controls.name.value]"
        [autofocus]="commonControls.length == 1 && !item.hasName()"
        (valueChange)="setTokenValue(item, $event)"
        (deleteRequested)="removeItem(item)"
      ></app-query-builder-parameters-edit-item>
    </div>
  </div>
  <div class="query-builder-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small query-builder-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add Input</span>
    </a>
  </div>
</div>

export enum ExportFormatType {
  JSON = 'json',
  CSV = 'csv',
  XLSX = 'xlsx',
  XLS = 'xls',
  HTML = 'html',
  TXT = 'txt'
}

export interface ExportFormat {
  type: ExportFormatType;
  extension: string;
  label: string;
}

export const exportFormatBookTypes = {
  [ExportFormatType.CSV]: 'csv',
  [ExportFormatType.XLSX]: 'xlsx',
  [ExportFormatType.XLS]: 'xls',
  [ExportFormatType.HTML]: 'html',
  [ExportFormatType.TXT]: 'txt'
};

export const exportFormats: ExportFormat[] = [
  {
    type: ExportFormatType.CSV,
    extension: 'csv',
    label: 'CSV'
  },
  {
    type: ExportFormatType.XLSX,
    extension: 'xlsx',
    label: 'Excel (XLSX)'
  },
  {
    type: ExportFormatType.XLS,
    extension: 'xls',
    label: 'Excel (XLS)'
  },
  {
    type: ExportFormatType.JSON,
    extension: 'json',
    label: 'JSON'
  },
  {
    type: ExportFormatType.HTML,
    extension: 'html',
    label: 'HTML'
  },
  {
    type: ExportFormatType.TXT,
    extension: 'txt',
    label: 'Notepad (TXT)'
  }
];

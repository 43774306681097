import pickBy from 'lodash/pickBy';

import { ActionItem, ApproveResult } from '@modules/actions';
import { User } from '@modules/users';

import { TaskPriority } from './task-priority.enum';
import { TaskStatus } from './task-status';

export class Task {
  public uid: string;
  public queue: string;
  public name: string;
  public author: User;
  public assigned: User;
  public status: TaskStatus;
  public priority: TaskPriority;
  public objectType: string;
  public objectId: string;
  public params = {};
  public dateAdd: string;
  public parameterValues = {};
  public approveAction: ActionItem;
  public approveActionParams: Object;
  public approveActionResult: ApproveResult;

  deserialize(data: Object): Task {
    if (data['uid']) {
      this.uid = data['uid'];
    }
    if (data['queue']) {
      this.queue = data['queue'];
    }
    if (data['name']) {
      this.name = data['name'];
    }
    if (data['author']) {
      this.author = new User().deserialize(data['author']);
    }
    if (data['assigned']) {
      this.assigned = new User().deserialize(data['assigned']);
    }
    if (data['status']) {
      this.status = new TaskStatus().deserialize(data['status']);
    }
    if (data['priority']) {
      this.priority = data['priority'];
    }
    if (data['object_type']) {
      this.objectType = data['object_type'];
    }
    if (data['object_id']) {
      this.objectId = data['object_id'];
    }
    if (data['params']) {
      this.params = data['params'];
    }
    if (data['date_add']) {
      this.dateAdd = data['date_add'];
    }

    if (this.params['approve_action']) {
      this.approveAction = new ActionItem().deserialize(this.params['approve_action']);
    }

    this.approveActionParams = this.params['approve_action_params'];

    if (this.params['approve_action_result']) {
      this.approveActionResult = new ApproveResult().deserialize(this.params['approve_action_result']);
    }

    if (this.params['parameter_values']) {
      this.parameterValues = this.params['parameter_values'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params['parameter_values'] = this.parameterValues;
    this.params['approve_action'] = this.approveAction ? this.approveAction.serialize() : undefined;
    this.params['approve_action_params'] = this.approveActionParams;
    this.params['approve_action_result'] = this.approveActionResult ? this.approveActionResult.serialize() : undefined;

    let data: Object = {
      queue: this.queue,
      name: this.name,
      assigned: this.assigned ? this.assigned.uid : null,
      status: this.status ? this.status.uid : undefined,
      priority: this.priority,
      object_type: this.objectType,
      object_id: this.objectId,
      params: this.params
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { DialogService } from '@common/dialogs';

import { ChangeComponent } from '../../components/change/change.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<Component> {
  constructor(private dialogService: DialogService) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!(component instanceof ChangeComponent)) {
      return true;
    }

    const changeComponent = <ChangeComponent>component;

    if (changeComponent.hasChanges()) {
      return this.dialogService.confirm({
        title: 'Unsaved changes',
        description: 'Are you sure? You changes will be lost'
      });
    }

    return true;
  }
}

import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { S3CompatibleGeneratorService, S3CompatibleParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
import { StorageBucketArray, validateStorageBucketArray } from './storage-bucket.array';

export class Form extends AppFormGroup {
  controls: {
    endpoint: FormControl;
    buckets: StorageBucketArray;
    access_key_id: FormControl;
    secret_access_key: FormControl;
  };

  constructor() {
    super({
      endpoint: new FormControl('', Validators.required),
      buckets: new StorageBucketArray([''], validateStorageBucketArray()),
      access_key_id: new FormControl('', Validators.required),
      secret_access_key: new FormControl('', Validators.required)
    });
  }
}

@Injectable()
export class S3CompatibleResourceSettingsForm extends BaseResourceSettingsForm<S3CompatibleParamsOptions> {
  form = new Form();

  constructor(
    private s3CompatibleGeneratorService: S3CompatibleGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.s3CompatibleGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          endpoint: result.endpoint,
          access_key_id: result.access_key_id,
          secret_access_key: result.secret_access_key
        });

        this.form.controls.buckets.deserialize(result.buckets.length ? result.buckets : ['']);
      })
    );
  }

  getOptions(): S3CompatibleParamsOptions {
    return {
      endpoint: this.form.value['endpoint'],
      buckets: this.form.value['buckets'],
      access_key_id: this.form.value['access_key_id'],
      secret_access_key: this.form.value['secret_access_key']
    };
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.s3CompatibleGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name']
          };
        })
      );
  }
}

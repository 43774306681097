import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  ElementComponent,
  elementImage,
  ElementItem,
  ElementType,
  FieldElementItem,
  getElementComponentByType,
  ListElementItem,
  WidgetElementItem
} from '@modules/customize';
import { ChartType, ChartWidget, ValueWidget } from '@modules/dashboard';
import { getFieldDescriptionByType } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ThemeService } from '@modules/theme';
import { deployUrl } from '@shared';

@Component({
  selector: 'app-element-drag-preview',
  templateUrl: './element-drag-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementDragPreviewComponent implements OnChanges {
  @Input() element: ElementItem;

  elementComponent: ElementComponent;
  name: string;
  image: string;
  imagePath: string;

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.elementComponent = this.element ? getElementComponentByType(this.element.type) : undefined;
    this.name = this.getDisplayName();
    this.image = this.getImage() || 'table_list';
    this.imagePath = this.getImagePath();
  }

  getDisplayName(): string {
    if (!this.element) {
      return;
    }

    return this.element.name || this.elementComponent.label;
  }

  getImage(): string {
    return elementImage(this.element);
  }

  getImagePath(): string {
    if (this.themeService.theme == 'dark') {
      return `url(${deployUrl('/assets/images/widgets/' + this.image + '_dark.svg')})`;
    } else {
      return `url(${deployUrl('/assets/images/widgets/' + this.image + '.svg')})`;
    }
  }
}

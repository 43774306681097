import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { delayWhen, map, switchMap } from 'rxjs/operators';

import { SessionStorage } from '@core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ApiService, ServerRequestError } from '@modules/api';
import { CustomViewSettings, ViewSettingsService } from '@modules/customize';
import { ProjectPublicAccessService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
import { Link } from '@shared';

@Component({
  selector: 'app-project-public-access',
  templateUrl: './project-public-access.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPublicAccessComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private projectPublicAccessService: ProjectPublicAccessService,
    private apiService: ApiService,
    private currentUserStore: CurrentUserStore,
    private projectsStore: ProjectsStore,
    private viewSettingsService: ViewSettingsService,
    private routing: RoutingService,
    private sessionStorage: SessionStorage,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  loading = false;
  notFound = false;
  publicAccessNotEnabled = false;
  publicAccessNotEnabledInFree = false;
  error = false;
  showMenu = true;

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          const code = params['code'];

          this.loading = true;
          this.notFound = false;
          this.publicAccessNotEnabled = false;
          this.publicAccessNotEnabledInFree = false;
          this.error = false;
          this.cd.markForCheck();

          return this.projectPublicAccessService.accept(code);
        }),
        delayWhen(result => {
          const iframe = this.activatedRoute.snapshot.queryParams['iframe'];
          const iframeKey = `iframe_${result.publicAccess.project.uniqueName}`;

          if (iframe) {
            this.sessionStorage.set(iframeKey, '1');
          } else if (this.sessionStorage.get(iframeKey)) {
            this.sessionStorage.remove(iframeKey);
          }

          this.apiService.enableSessionScope();
          this.apiService.saveToken(result.userToken);

          return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]);
        }),
        switchMap(result => {
          let page$: Observable<CustomViewSettings>;

          if (result.publicAccess.pageUid) {
            page$ = this.viewSettingsService
              .get(result.publicAccess.project.uniqueName, result.publicAccess.environment.uniqueName)
              .pipe(
                map<any, CustomViewSettings>(pages => {
                  return pages.find(
                    item => item instanceof CustomViewSettings && item.uid == result.publicAccess.pageUid
                  );
                })
              );
          } else {
            page$ = of(undefined);
          }

          return combineLatest(of(result), page$);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        ([result, page]) => {
          const project = result.publicAccess.project;
          const environment = result.publicAccess.environment;
          let link: Link;
          const queryParams = {
            ...result.publicAccess.pageParams,
            ...this.activatedRoute.snapshot.queryParams
          };

          if (page) {
            link = project.linkWithProtocol(page.link, { environmentName: environment.uniqueName });
          } else {
            link = project.getHomeLinkWithProtocol({ environmentName: environment.uniqueName });
          }

          this.routing.navigateLink(link, { queryParams: queryParams });

          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError) {
            if (error.response && error.response.status == 404) {
              this.notFound = true;
            } else if (error.fieldErrors['code'] == 'FEATURE_NOT_ENABLED') {
              this.publicAccessNotEnabled = true;
            } else if (error.fieldErrors['code'] == 'PUBLIC_ACCESS_NOT_ENABLED_IN_FREE') {
              this.publicAccessNotEnabledInFree = true;
            }
          }

          this.loading = false;
          this.error = true;
          this.cd.markForCheck();
        }
      );

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.showMenu = !params['iframe'];
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { ActionLayerInteraction, createLayerInteraction, CursorLayerInteraction } from '@modules/views';
var LayerInteractionControl = /** @class */ (function (_super) {
    __extends(LayerInteractionControl, _super);
    function LayerInteractionControl(type, state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            action: new FormControl(state.action),
            cursor: new FormControl(state.cursor)
        }) || this;
        _this.type = type;
        return _this;
    }
    LayerInteractionControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        if (value instanceof ActionLayerInteraction) {
            this.controls.action.patchValue(value.action, { emitEvent: options.emitEvent });
        }
        else if (value instanceof CursorLayerInteraction) {
            this.controls.cursor.patchValue(value.cursor, { emitEvent: options.emitEvent });
        }
    };
    LayerInteractionControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = createLayerInteraction(this.type);
        }
        if (instance instanceof ActionLayerInteraction) {
            instance.action = this.controls.action.value;
        }
        else if (instance instanceof CursorLayerInteraction) {
            instance.cursor = this.controls.cursor.value;
        }
        return instance;
    };
    LayerInteractionControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return LayerInteractionControl;
}(FormGroup));
export { LayerInteractionControl };

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Project, ProjectGroup, ProjectGroupService, ProjectPublicAccess, ProjectPublicAccessService, ProjectPublicInvite, ProjectPublicInviteService, ProjectUserService } from '@modules/projects';
import { isSet } from '@shared';
var PublishMenuComponent = /** @class */ (function () {
    function PublishMenuComponent(currentProjectStore, currentEnvironmentStore, projectUserService, projectGroupService, projectPublicInviteService, projectPublicAccessService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectUserService = projectUserService;
        this.projectGroupService = projectGroupService;
        this.projectPublicInviteService = projectPublicInviteService;
        this.projectPublicAccessService = projectPublicAccessService;
        this.cd = cd;
        this.published = false;
        this.draftChangesTotal = 0;
        this.close = new EventEmitter();
        this.showChanges = new EventEmitter();
        this.deleteDraft = new EventEmitter();
        this.publishDraft = new EventEmitter();
        this.editDomain = new EventEmitter();
        this.openAppUrl = new EventEmitter();
        this.openShareInvite = new EventEmitter();
        this.openSharePublicLink = new EventEmitter();
        this.openSharePublicAccess = new EventEmitter();
        this.openSignUp = new EventEmitter();
        this.loading = false;
        this.signUpEnabled = false;
        this.usersCount = 0;
        this.publicInvites = [];
        this.publicAccess = [];
    }
    PublishMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.project = result;
            _this.signUpEnabled = result ? !!result.defaultGroup : false;
            _this.cd.markForCheck();
        });
    };
    PublishMenuComponent.prototype.ngOnDestroy = function () { };
    PublishMenuComponent.prototype.ngOnChanges = function (changes) {
        if (changes.published) {
            this.fetchShared();
        }
    };
    PublishMenuComponent.prototype.fetchShared = function () {
        var _this = this;
        var _a;
        if (!this.published) {
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        var signUpGroup$ = isSet(this.currentProjectStore.instance.defaultGroup)
            ? this.projectGroupService.getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.currentProjectStore.instance.defaultGroup)
            : of(undefined);
        combineLatest(this.projectUserService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {}, _a[PER_PAGE_PARAM] = 1, _a)), signUpGroup$, this.projectPublicInviteService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName), this.projectPublicAccessService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var users = _a[0], signUpGroup = _a[1], publicInvites = _a[2], publicAccess = _a[3];
            _this.usersCount = users.count;
            _this.signUpGroup = signUpGroup;
            _this.publicInvites = publicInvites;
            _this.publicAccess = publicAccess;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    return PublishMenuComponent;
}());
export { PublishMenuComponent };

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgGxSelectModule } from 'ng-gxselect';

import { BannersModule } from '@common/banners';
import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ApiModule } from '@modules/api';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { HeaderModule } from '@modules/header';
import { MenuComponentsModule } from '@modules/menu-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { TemplateComponentsModule } from '@modules/template-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { OAuthResponseComponent } from './components/oauth-response/oauth-response.component';
import { ProjectCreatePopupComponent } from './components/project-create-popup/project-create-popup.component';
import { ProjectCreate3Component } from './components/project-create3/project-create3.component';
import { ProjectInviteComponent } from './components/project-invite/project-invite.component';
import { ProjectPublicAccessComponent } from './components/project-public-access/project-public-access.component';
import { ProjectPublicInviteComponent } from './components/project-public-invite/project-public-invite.component';
import { ProjectRegisterComponent } from './components/project-register/project-register.component';
import { ProjectsItemDropdownComponent } from './components/projects-item-dropdown/projects-item-dropdown.component';
import { ProjectsItemComponent } from './components/projects-item/projects-item.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { routes } from './projects-routes.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    ApiModule,
    FieldsModule,
    FieldComponentsModule,
    MenuComponentsModule,
    SharedModule,
    ModelQueriesModule,
    TipsModule,
    ProjectsComponentsModule,
    TemplateComponentsModule,
    AnalyticsModule,
    RouterModule.forChild(routes),
    RoutingModule,
    BannersModule,
    TableModule,
    UiModule,
    HeaderModule,
    MatMenuModule,
    OverlayModule,
    FeaturesModule,
    LocalizeModule,
    FormUtilsModule
  ],
  declarations: [
    ProjectRegisterComponent,
    ProjectsComponent,
    OAuthResponseComponent,
    ProjectCreate3Component,
    ProjectInviteComponent,
    ProjectPublicInviteComponent,
    ProjectPublicAccessComponent,
    ProjectCreatePopupComponent,
    ProjectsItemDropdownComponent,
    ProjectsItemComponent
  ],
  entryComponents: [ProjectCreatePopupComponent]
})
export class ProjectsRoutesModule {}

import pickBy from 'lodash/pickBy';

import { ActionItem, ActionType } from '@modules/actions';
import { isSet } from '@shared';

import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class ActionWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Action;
  public action: ActionItem;
  public resultSteps = false;
  public successSteps: WorkflowStep[] = [];
  public errorSteps: WorkflowStep[] = [];

  deserialize(data: Object) {
    super.deserialize(data);

    if (data['action']) {
      this.action = new ActionItem().deserialize(data['action']);
    }

    if (isSet(data['result_steps'])) {
      this.resultSteps = data['result_steps'];
    }

    if (data['success_steps']) {
      this.successSteps = data['success_steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    if (data['error_steps']) {
      this.errorSteps = data['error_steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      action: this.action ? this.action.serialize() : undefined,
      result_steps: this.resultSteps,
      success_steps: this.successSteps.map(item => item.serialize()),
      error_steps: this.errorSteps.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    if (this.action && this.action.actionDescription) {
      return this.action.actionDescription.typeStr;
    } else {
      return super.defaultName();
    }
  }

  get analyticsName(): string {
    return 'action';

    if (this.action && this.action.actionDescription) {
      return ['action', this.action.actionDescription.type].join('_');
    } else if (this.action && this.action.sharedActionDescription) {
      return ['action', ActionType.Query].join('_');
    } else {
      return 'action';
    }
  }

  getIcon() {
    if (this.action && this.action.actionDescription) {
      return this.action.actionDescription.typeIcon;
    } else {
      return super.defaultName();
    }
  }
}

registerWorkflowStepType(WorkflowStepType.Action, ActionWorkflowStep);

import { ElementRef, forwardRef, OnChanges, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import defaults from 'lodash/defaults';
import * as moment from 'moment';
import { isSet } from '../../utils/common/common';
export var DefaultDateValueAccessorOptions = {
    format: 'DD.MM.YYYY HH:mm:ss',
    date: true,
    time: true
};
export var DATE_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DateValueAccessorDirective; }),
    multi: true
};
export var timeParseFormats = [moment.ISO_8601, 'hh:mm:ss A', 'HH:mm:ss', 'hh:mm A', 'HH:mm'];
export function parseTime(value, format) {
    var result = moment(value, format || timeParseFormats);
    result.toString = function () { return result.format('HH:mm:SS'); };
    result.toJSON = function () { return result.format('HH:mm:SS'); };
    return result;
}
var DateValueAccessorDirective = /** @class */ (function () {
    function DateValueAccessorDirective(renderer, el) {
        var _this = this;
        this.renderer = renderer;
        this.el = el;
        this.onInputEvent = function (value) {
            value = _this.toValue(value);
            _this.controlValue = value;
            _this.onChange(value);
        };
        this.onValueEvent = function (value) {
            value = _this.toValue(value);
            _this.controlValue = value;
            _this.onChange(value);
        };
        this.onBlurEvent = function () {
            _this.onTouched();
        };
        this.onChange = function (_) { };
        this.onTouched = function () { };
    }
    DateValueAccessorDirective.prototype.ngOnChanges = function (changes) {
        if (changes.options && !changes.options.firstChange) {
            this.setValue(this.controlValue);
        }
    };
    Object.defineProperty(DateValueAccessorDirective.prototype, "currentOptions", {
        get: function () {
            return defaults(this.options, DefaultDateValueAccessorOptions);
        },
        enumerable: true,
        configurable: true
    });
    DateValueAccessorDirective.prototype.writeValue = function (value) {
        this.controlValue = value;
        this.setValue(value);
    };
    DateValueAccessorDirective.prototype.setValue = function (value) {
        if (!isSet(value)) {
            this.renderer.setProperty(this.el.nativeElement, 'value', null);
            return;
        }
        var timeOnly = !this.currentOptions.date && this.currentOptions.time;
        var dt = timeOnly ? parseTime(value) : moment(value);
        if (!dt.isValid()) {
            return;
        }
        var str = dt.format(this.currentOptions.format);
        this.renderer.setProperty(this.el.nativeElement, 'value', str);
    };
    DateValueAccessorDirective.prototype.toValue = function (value) {
        var parsed = moment(value, this.currentOptions.format);
        if (!parsed.isValid()) {
            return null;
        }
        var result;
        if (this.currentOptions.date && this.currentOptions.time) {
            result = parsed.toISOString();
        }
        else if (this.currentOptions.date && !this.currentOptions.time) {
            result = parsed.format('YYYY-MM-DD');
        }
        else if (!this.currentOptions.date && this.currentOptions.time) {
            result = parsed.format('HH:mm:ss');
        }
        return result;
    };
    DateValueAccessorDirective.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DateValueAccessorDirective.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DateValueAccessorDirective.prototype.setDisabledState = function (isDisabled) {
        this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    };
    return DateValueAccessorDirective;
}());
export { DateValueAccessorDirective };

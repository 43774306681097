import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { Company, CompanyService, Project } from '@modules/projects';

@Injectable()
export class CompanyInfoForm {
  form: FormGroup;
  project: Project;
  industryOptions = [
    'FoodTech',
    'Mobility',
    'FinTech',
    'Betting',
    'Ecommerce',
    'Education',
    'Advertisement',
    'E-Sports',
    'Information Security',
    'Insurance',
    'Travel',
    'Real Estate',
    'Healthcare',
    'BioTech',
    'IoT',
    'CleanTech',
    'Sports',
    'Beauty',
    'HRTech',
    'SaaS'
  ]
    .sort((lhs, rhs) => (lhs < rhs ? -1 : 1))
    .concat(['Other']);
  sizeOptions = ['1-5', '6-10', '11-25', '25-50', '51-100', '101-250', '251-500', '501-1000', '1000+'];

  constructor(private formUtils: FormUtils, private fb: FormBuilder, private companyService: CompanyService) {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      industry: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      size: new FormControl('', Validators.required)
    });
  }

  init(project: Project) {
    this.project = project;
  }

  submit() {
    this.form.markAsDirty();

    const value = this.form.value;
    const company = new Company();

    company.name = value['name'];
    company.industry = value['industry'];
    company.role = value['role'];
    company.size = value['size'];

    return this.companyService.create(this.project, company).pipe(
      catchError(error => {
        this.formUtils.showFormErrors(this.form, error);
        return throwError(error);
      })
    );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/popup2/popup2.component.ngfactory";
import * as i2 from "../../../../ui/components/popup2/popup2.component";
import * as i3 from "../../../../ui/components/popup2-close/popup2-close.component.ngfactory";
import * as i4 from "../../../../ui/components/popup2-close/popup2-close.component";
import * as i5 from "../model-api/model-api.component.ngfactory";
import * as i6 from "../model-api/model-api.component";
import * as i7 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i8 from "../../../customize-utils/services/draft-changes/draft-changes.service";
import * as i9 from "./model-api-popup.component";
import * as i10 from "../../../../common/popups/data/popup-ref";
var styles_ModelApiPopupComponent = [];
var RenderType_ModelApiPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelApiPopupComponent, data: {} });
export { RenderType_ModelApiPopupComponent as RenderType_ModelApiPopupComponent };
export function View_ModelApiPopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "popup popup_contrast"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-popup2", [], null, null, null, i1.View_Popup2Component_0, i1.RenderType_Popup2Component)), i0.ɵdid(2, 114688, null, 0, i2.Popup2Component, [], { size: [0, "size"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "app-popup2-close", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_Popup2CloseComponent_0, i3.RenderType_Popup2CloseComponent)), i0.ɵdid(4, 114688, null, 0, i4.Popup2CloseComponent, [], { outside: [0, "outside"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "app-model-api", [], null, null, null, i5.View_ModelApiComponent_0, i5.RenderType_ModelApiComponent)), i0.ɵdid(6, 770048, null, 0, i6.ModelApiComponent, [i7.ResourceControllerService, i8.DraftChangesService, i0.ChangeDetectorRef], { resource: [0, "resource"], modelDescription: [1, "modelDescription"], popup: [2, "popup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "xxl"; _ck(_v, 2, 0, currVal_0); var currVal_1 = true; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.resource; var currVal_3 = _co.modelDescription; var currVal_4 = true; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ModelApiPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-api-popup", [], null, null, null, View_ModelApiPopupComponent_0, RenderType_ModelApiPopupComponent)), i0.ɵdid(1, 114688, null, 0, i9.ModelApiPopupComponent, [[2, i10.PopupRef]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelApiPopupComponentNgFactory = i0.ɵccf("app-model-api-popup", i9.ModelApiPopupComponent, View_ModelApiPopupComponent_Host_0, { resource: "resource", modelDescription: "modelDescription" }, {}, []);
export { ModelApiPopupComponentNgFactory as ModelApiPopupComponentNgFactory };

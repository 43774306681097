<div class="compact-inputs-row compact-inputs-row_vertical-align_top">
  <div *ngIf="typeControl.value == types.All" class="compact-inputs-row__item">
    <app-compact-input
      [formControl]="allControl"
      [postfix]="'px'"
      [placeholder]="'0'"
      [widthPx]="60"
      [number]="true"
      [numberOptions]="{ min: 0 }"
      (change)="updateLocalValue(allControl, allControl.value)"
    ></app-compact-input>
  </div>

  <ng-container *ngIf="typeControl.value == types.Custom">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="topLeftControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(topLeftControl, topLeftControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-corner_top_left"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="topRightControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(topRightControl, topRightControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-corner_top_right"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="bottomRightControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(bottomRightControl, bottomRightControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-corner_bottom_right"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="bottomLeftControl"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(bottomLeftControl, bottomLeftControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-corner_bottom_left"></span>
      </div>
    </div>
  </ng-container>

  <div class="compact-inputs-row__item">
    <app-compact-segmented-control
      [formControl]="typeControl"
      [options]="typeOptions"
      (change)="updateValue()"
    ></app-compact-segmented-control>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./separator-menu-item.component";
var styles_SeparatorMenuItemComponent = [];
var RenderType_SeparatorMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SeparatorMenuItemComponent, data: {} });
export { RenderType_SeparatorMenuItemComponent as RenderType_SeparatorMenuItemComponent };
export function View_SeparatorMenuItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "menu2-separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "menu2-separator__inner"]], null, null, null, null, null))], null, null); }
export function View_SeparatorMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-separator-menu-item", [], null, null, null, View_SeparatorMenuItemComponent_0, RenderType_SeparatorMenuItemComponent)), i0.ɵdid(1, 245760, null, 0, i1.SeparatorMenuItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeparatorMenuItemComponentNgFactory = i0.ɵccf("app-separator-menu-item", i1.SeparatorMenuItemComponent, View_SeparatorMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { SeparatorMenuItemComponentNgFactory as SeparatorMenuItemComponentNgFactory };

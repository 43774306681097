import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable, Subscription, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomViewSettings, ViewContext } from '@modules/customize';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectProperty,
  ProjectPropertyService,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import {
  ascComparator,
  isBodyHasChild,
  isElementHasChild,
  isSet,
  KeyboardEventKeyCode,
  nodeListToArray,
  TypedChanges
} from '@shared';

import { PropertyItem } from '../custom-page-properties-section-item/custom-page-properties-section-item.component';
import { PropertySection } from '../custom-page-properties-section/custom-page-properties-section.component';

const customPagePropertiesClickEventProperty = '_customPagePropertiesClickEvent';

export function markCustomPagePropertiesClickEvent(clickEvent: MouseEvent) {
  clickEvent[customPagePropertiesClickEventProperty] = true;
}

export function isCustomPagePropertiesClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[customPagePropertiesClickEventProperty];
}

@Component({
  selector: 'app-custom-page-properties',
  templateUrl: './custom-page-properties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePropertiesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() viewSettings: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() opened = false;
  @Output() closed = new EventEmitter<void>();

  searchControl = new FormControl('');
  propertiesCount = 0;
  sections: PropertySection[] = [];
  filteredSections: PropertySection[] = [];
  globalValues = {};
  pageValues = {};
  userValues = {};
  groupValues = {};
  pageSubscriptions: Subscription[] = [];
  analyticsSource = 'page_properties';
  blurSubscription: Subscription;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectPropertyService: ProjectPropertyService,
    private projectPropertyEditController: ProjectPropertyEditController,
    private projectPropertyStore: ProjectPropertyStore,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredSections());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomPagePropertiesComponent>): void {
    if (changes.viewSettings) {
      this.initSections();
    }

    if (changes.opened && this.opened) {
      this.onOpened();
    } else if (changes.opened && !changes.opened.firstChange && !this.opened) {
      this.onClosed();
    }
  }

  initSections() {
    this.close();

    this.pageSubscriptions.forEach(item => item.unsubscribe());
    this.pageSubscriptions = [];

    this.projectPropertyStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        const pageProperties = properties.filter(item => {
          return item.type == ProjectPropertyType.Page && isSet(item.pageUid) && item.pageUid == this.viewSettings.uid;
        });
        const globalProperties = properties.filter(item => {
          return item.type == ProjectPropertyType.Global;
        });
        const userProperties = properties.filter(item => {
          return item.type == ProjectPropertyType.User;
        });
        const groupProperties = properties.filter(item => {
          return item.type == ProjectPropertyType.Group;
        });

        this.propertiesCount =
          pageProperties.length + globalProperties.length + userProperties.length + groupProperties.length;
        this.sections = [
          {
            name: 'page',
            label: 'Current page',
            icon: 'documents',
            documentation: 'variables',
            descriptionTitle: 'Page variables',
            descriptionText: 'Save page state to variables and access from components',
            buttons: [
              {
                label: 'Temporary page variable',
                description: 'Value exists during user session, resets on refresh',
                icon: 'time',
                handler: () => {
                  return this.createProjectProperty(ProjectPropertyType.Page, {
                    defaultName: 'variable',
                    defaultValueEnabled: true,
                    pageUid: this.viewSettings.uid
                  });
                }
              },
              {
                label: 'Stored page variable',
                description: 'Value is saved in browser across sessions',
                icon: 'download',
                handler: () => {
                  return this.createProjectProperty(ProjectPropertyType.Page, {
                    defaultName: 'variable',
                    defaultValueEnabled: true,
                    pageUid: this.viewSettings.uid,
                    saveLocal: true
                  });
                }
              }
            ],
            buttonsLabel: 'Create page variable',
            buttonsIcon: 'plus',
            items: this.mapPropertyItems(pageProperties, { saveLocalEnabled: true }),
            itemsTotal: pageProperties.length,
            showEmpty: true,
            getValues: () => this.pageValues,
            ...((!this.viewSettings || !this.viewSettings.uid) && {
              disabledTitle: 'Page is not set up',
              disabledText: 'Add any components to this page first'
            })
          },
          {
            name: 'global',
            label: 'Globals',
            icon: 'home',
            descriptionTitle: 'Global variables',
            descriptionText: 'Global variables can be saved or accessed from any page',
            buttons: [
              {
                label: 'Temporary global variable',
                description: 'Value exists during user session, resets on refresh',
                icon: 'time',
                handler: () => {
                  return this.createProjectProperty(ProjectPropertyType.Global, {
                    defaultName: 'variable',
                    defaultValueEnabled: true
                  });
                }
              },
              {
                label: 'Stored global variable',
                description: 'Value is saved in browser across sessions',
                icon: 'download',
                handler: () => {
                  return this.createProjectProperty(ProjectPropertyType.Global, {
                    defaultName: 'variable',
                    defaultValueEnabled: true,
                    saveLocal: true
                  });
                }
              }
            ],
            buttonsLabel: 'Create global variable',
            buttonsIcon: 'plus',
            items: this.mapPropertyItems(globalProperties, { saveLocalEnabled: true }),
            itemsTotal: globalProperties.length,
            showEmpty: true,
            getValues: () => this.globalValues
          },
          {
            name: 'user',
            label: 'Current user',
            icon: 'user',
            descriptionTitle: 'User properties',
            descriptionText: 'User properties are linked to current user and accessed from any page',
            buttons: [
              {
                label: 'Create user property',
                icon: 'plus',
                handler: () => this.createProjectProperty(ProjectPropertyType.User)
              }
            ],
            items: this.mapPropertyItems(userProperties),
            itemsTotal: userProperties.length,
            showEmpty: true,
            getValues: () => this.userValues,
            defaultBannerHidden: true
          },
          {
            name: 'group',
            label: 'Current team',
            icon: 'users_teams',
            descriptionTitle: 'Team properties',
            descriptionText: 'Team properties are linked to current team and accessed from any page',
            buttons: [
              {
                label: 'Create team property',
                icon: 'plus',
                handler: () => this.createProjectProperty(ProjectPropertyType.Group)
              }
            ],
            items: this.mapPropertyItems(groupProperties),
            itemsTotal: groupProperties.length,
            showEmpty: true,
            getValues: () => this.groupValues,
            defaultBannerHidden: true
          }
        ];
        this.updateFilteredSections();
        this.cd.markForCheck();

        this.pageSubscriptions.push(
          this.currentEnvironmentStore.globalStorage
            .getValues$()
            .pipe(untilDestroyed(this))
            .subscribe(value => {
              this.globalValues = value;
              this.cd.markForCheck();
            })
        );

        if (this.context && this.context.pageStorage) {
          this.pageSubscriptions.push(
            this.context.pageStorage
              .getValues$()
              .pipe(untilDestroyed(this))
              .subscribe(value => {
                this.pageValues = value;
                this.cd.markForCheck();
              })
          );
        } else {
          this.pageValues = {};
          this.cd.markForCheck();
        }

        this.pageSubscriptions.push(
          this.currentEnvironmentStore.instance$.pipe(untilDestroyed(this)).subscribe(value => {
            this.userValues = value && value.user ? value.user.properties : {};
            this.groupValues = value && value.group ? value.group.properties : {};
            this.cd.markForCheck();
          })
        );
      });
  }

  mapPropertyItems(pageProperties: ProjectProperty[], options: { saveLocalEnabled?: boolean } = {}): PropertyItem[] {
    return pageProperties
      .map(item => {
        return {
          id: item.uid,
          label: item.name,
          icon: item.fieldDescription.icon,
          labelIcon: item.saveLocal ? 'download' : undefined,
          ...(item.field && {
            field: item.field.field,
            params: item.field.params
          }),
          renameLabel: 'Rename',
          renameHandler: value => this.renameProjectProperty(item, value),
          editLabel: 'Edit',
          editHandler: () => this.editProjectProperty(item),
          ...(options.saveLocalEnabled && {
            actions: item.saveLocal
              ? [
                  {
                    label: 'Make temporary',
                    icon: 'time',
                    handler: () => this.setSaveLocalProjectProperty(item, false)
                  }
                ]
              : [
                  {
                    label: 'Make stored',
                    icon: 'download',
                    handler: () => this.setSaveLocalProjectProperty(item, true)
                  }
                ]
          }),
          deleteLabel: 'Delete',
          deleteHandler: () => this.deleteProjectProperty(item)
        };
      })
      .sort((lhs, rhs) => ascComparator(lhs.label.toLowerCase(), rhs.label.toLowerCase()));
  }

  getFilteredSections(): PropertySection[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.sections;
    }

    return this.sections
      .map(section => {
        return {
          ...section,
          items: section.items.filter(item => {
            return item.label.toLowerCase().includes(search);
          })
        };
      })
      .filter(item => item.items.length || item.showEmpty);
  }

  updateFilteredSections() {
    this.filteredSections = this.getFilteredSections();
    this.cd.markForCheck();
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.cd.markForCheck();

    this.onClosed();
  }

  onOpened() {
    setTimeout(() => {
      this.blurSubscription = fromEvent<MouseEvent>(window.document, 'click')
        .pipe(
          filter(e => {
            if (isCustomPagePropertiesClickEvent(e)) {
              return false;
            }

            const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popups'));
            if (
              overlays.some(overlay => isElementHasChild(overlay, e.target as HTMLElement)) ||
              !isBodyHasChild(e.target as HTMLElement)
            ) {
              return false;
            }

            return true;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.close());
    }, 0);
  }

  onClosed() {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
      this.blurSubscription = undefined;
    }

    this.closed.emit();
  }

  markClickEvent(e: MouseEvent) {
    markCustomPagePropertiesClickEvent(e);
  }

  clearSearch() {
    this.searchControl.patchValue('');
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }

  createProjectProperty(
    type: ProjectPropertyType,
    options: {
      defaultName?: string;
      defaultValueEnabled?: boolean;
      pageUid?: string;
      saveLocal?: boolean;
    } = {}
  ) {
    this.projectPropertyEditController
      .create({
        type: type,
        defaultName: options.defaultName,
        defaultValueEnabled: options.defaultValueEnabled,
        pageUid: options.pageUid,
        saveLocal: options.saveLocal,
        context: this.context,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  renameProjectProperty(property: ProjectProperty, name: string): Observable<boolean> {
    const instance = cloneDeep(property) as ProjectProperty;

    instance.name = name;

    return this.projectPropertyService
      .update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        ['name']
      )
      .pipe(
        map(() => true),
        delayWhen(() => this.projectPropertyStore.getFirst(true)),
        catchError(error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Rename Failed', error.errors[0]);
          } else {
            this.notificationService.error('Rename Failed', error);
          }

          return throwError(error);
        })
      );
  }

  setSaveLocalProjectProperty(property: ProjectProperty, value: boolean): Observable<boolean> {
    const instance = cloneDeep(property) as ProjectProperty;

    instance.saveLocal = value;

    return this.projectPropertyService
      .update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance,
        ['params']
      )
      .pipe(
        map(() => {
          if (property.type == ProjectPropertyType.Global) {
            if (value) {
              this.currentEnvironmentStore.globalStorage.persist(property);
            } else {
              this.currentEnvironmentStore.globalStorage.makeTemporary(property);
            }
          } else if (property.type == ProjectPropertyType.Page && this.context && this.context.pageStorage) {
            if (value) {
              this.context.pageStorage.persist(property);
            } else {
              this.context.pageStorage.makeTemporary(property);
            }
          }

          return true;
        }),
        delayWhen(() => this.projectPropertyStore.getFirst(true)),
        catchError(error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Save Failed', error.errors[0]);
          } else {
            this.notificationService.error('Save Failed', error);
          }

          return throwError(error);
        })
      );
  }

  editProjectProperty(property: ProjectProperty) {
    this.projectPropertyEditController
      .edit({
        property: property,
        defaultValueEnabled: [ProjectPropertyType.Global, ProjectPropertyType.Page].includes(property.type),
        pageUid: this.viewSettings.uid,
        context: this.context,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  deleteProjectProperty(property: ProjectProperty): Observable<boolean> {
    return this.projectPropertyEditController.delete({ property: property, analyticsSource: this.analyticsSource });
  }
}

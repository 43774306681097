import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class RewardfulService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private currentUserStore: CurrentUserStore) {
    const interval = setInterval(() => {
      if (!this.rewardful) {
        return;
      }

      this._loaded.next(true);
      clearInterval(interval);
    }, 1000);

    this.loaded$
      .pipe(
        switchMap(() => this.currentUserStore.get()),
        filter(user => !!user),
        switchMap(user => {
          const referral = this.getReferral();

          if (isSet(referral) && user.params.rewardful != referral) {
            user.params.rewardful = referral;
            return this.currentUserStore.update(['params']);
          } else {
            return of(undefined);
          }
        }),
        catchError(() => of(undefined))
      )
      .subscribe();
  }

  get rewardful(): any {
    return window['Rewardful'];
  }

  get loaded$() {
    return this._loaded.pipe(filter(item => item == true));
  }

  getReferral(): string {
    if (!this.rewardful) {
      return;
    }

    return this.rewardful.referral;
  }
}

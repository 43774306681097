<div class="toggle-content">
  <div class="toggle-content__container">
    <div *ngIf="tabLabel || title" class="toggle-content__header">
      <div *ngIf="title" class="toggle-content__title">{{ title }}</div>
      <button
        *ngIf="tabLabel"
        class="toggle-content__button button button_small button_no-margin"
        [class.button_orange]="tabEnabled"
        [class.button_primary]="tabEnabled"
        [class.button_background]="!tabEnabled"
        type="button"
        (click)="toggleTabEnabled()"
      >
        {{ tabLabel }}
      </button>
    </div>
    <div class="toggle-content__body">
      <ng-content select="ng-container[default]" *ngIf="!tabEnabled"> </ng-content>
      <ng-content select="ng-container[tab]" *ngIf="tabEnabled"> </ng-content>
    </div>
  </div>
</div>

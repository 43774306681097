import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { PopupService } from '@common/popups';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Project } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

import { OnboardingPopupComponent } from '../../components/onboarding-popup/onboarding-popup.component';

@Injectable()
export class OnboardingService {
  _visible = new BehaviorSubject<boolean>(false);

  constructor(
    private popupService: PopupService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentUserStore: CurrentUserStore,
    private injector: Injector
  ) {}

  isShown(project: Project): boolean {
    return this.currentUserStore.instance.params.hasProjectOnboardingShowed(project.uniqueName);
  }

  setShown(project: Project) {
    this.currentUserStore.instance.params.addProjectOnboardingShowed(project.uniqueName);
    this.currentUserStore.update(['params']).subscribe();
  }

  set visible(value: boolean) {
    this._visible.next(value);
  }

  get visible(): boolean {
    return this._visible.value;
  }

  get visible$(): Observable<boolean> {
    return this._visible.asObservable();
  }

  showOnboardingPopup() {
    this.visible = true;
    this.popupService.push({
      component: OnboardingPopupComponent,
      outputs: {
        closed: [() => (this.visible = false)]
      },
      injector: this.injector
    });
  }

  showOnboardingPopupIfNeeded(project: Project) {
    return;
    // combineLatest(this.modelDescriptionStore.getFirst(), this.currentUserStore.getFirst()).subscribe(
    //   ([modelDescriptions, currentUser]) => {
    //     if (
    //       !this.isShown(project) &&
    //       !this.visible &&
    //       modelDescriptions &&
    //       modelDescriptions.length &&
    //       currentUser &&
    //       !project.demo &&
    //       project.hasProjectCustomizationPermission()
    //     ) {
    //       this.setShown(project);
    //       this.showOnboardingPopup();
    //     }
    //   }
    // );
  }
}

import { isSet, stripEnd } from '@shared';

import { Font, fonts } from '../data/fonts';

export function getFontFamily(font: Font): string {
  return [font.googleFontsName, ...(isSet(font.fallback) ? [font.fallback] : [])].join(',');
}

export function getFontUrlByName(name: string): string {
  const font = fonts.find(item => item.name == name);

  if (!font) {
    return;
  }

  return getFontUrl(font);
}

export function getFontUrl(font: Font, text?: string): string {
  if (font.default) {
    return;
  }

  const url = new URL('https://fonts.googleapis.com/css2');

  url.searchParams.append('family', `${font.googleFontsName}:wght@100;300;400;500;700`);
  url.searchParams.append('subset', 'cyrillic');

  if (isSet(text)) {
    url.searchParams.append('text', text);
  }

  url.searchParams.append('display', 'swap');

  return url.toString();
}

export function parseFontPostscriptName(
  name: string
): {
  fontFamily?: string;
  weight: number;
  italic: boolean;
} {
  let italic = false;
  let weight = 400;

  if (name.endsWith('Italic')) {
    italic = true;
    name = stripEnd(name, 'Italic');
  }

  const [fontStr, weightStr] = name.split('-');

  if (weightStr == 'Thin') {
    weight = 100;
  } else if (weightStr == 'ExtraLight') {
    weight = 200;
  } else if (weightStr == 'Light') {
    weight = 300;
  } else if (weightStr == 'Regular') {
    weight = 400;
  } else if (weightStr == 'Medium') {
    weight = 500;
  } else if (weightStr == 'SemiBold') {
    weight = 600;
  } else if (weightStr == 'Bold') {
    weight = 700;
  } else if (weightStr == 'ExtraBold') {
    weight = 800;
  } else if (weightStr == 'Black') {
    weight = 900;
  } else if (weightStr == 'ExtraBlack') {
    weight = 950;
  }

  const font = fonts.find(item => {
    const cleanName = item.name.toLowerCase().replace(/ /g, '');
    return cleanName == fontStr.toLowerCase();
  });
  const fontFamily = font ? font.name : undefined;

  return {
    fontFamily: fontFamily,
    weight: weight,
    italic: italic
  };
}

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CurrentUserStore } from '@modules/users';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { YandexMetrikaService } from '../yandex-metrika/yandex-metrika.service';
var ChatraService = /** @class */ (function () {
    function ChatraService(currentUserStore, googleAnalyticsService, yandexMetrikaService) {
        var _this = this;
        this.currentUserStore = currentUserStore;
        this.googleAnalyticsService = googleAnalyticsService;
        this.yandexMetrikaService = yandexMetrikaService;
        this._loaded = new BehaviorSubject(false);
        this._showed = new BehaviorSubject(false);
        var interval = setInterval(function () {
            if (!_this.chatra) {
                return;
            }
            _this.init();
            clearInterval(interval);
        }, 1000);
    }
    Object.defineProperty(ChatraService.prototype, "loaded$", {
        get: function () {
            return this._loaded.pipe(filter(function (item) { return item == true; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatraService.prototype, "showed$", {
        get: function () {
            return this._showed.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatraService.prototype, "showed", {
        get: function () {
            return this._showed.value;
        },
        enumerable: true,
        configurable: true
    });
    ChatraService.prototype.init = function () {
        var _this = this;
        this.currentUserStore.instance$.subscribe(function (user) {
            if (!_this.chatra || !user) {
                return;
            }
            _this._loaded.next(true);
            _this.chatra('updateIntegrationData', {
                name: user.firstName + " " + user.lastName,
                email: user.email,
                username: user.username,
                gaClientId: _this.googleAnalyticsService.clientId,
                ymClientId: _this.yandexMetrikaService.clientId
            });
        });
    };
    Object.defineProperty(ChatraService.prototype, "chatra", {
        get: function () {
            return window['Chatra'];
        },
        enumerable: true,
        configurable: true
    });
    ChatraService.prototype.openChat = function (focus) {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.chatra('openChat', focus);
        });
    };
    ChatraService.prototype.show = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.chatra('show');
            _this._showed.next(true);
        });
    };
    ChatraService.prototype.hide = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.chatra('hide');
            _this._showed.next(false);
        });
    };
    return ChatraService;
}());
export { ChatraService };

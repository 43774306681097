/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./external-fonts.component";
import * as i3 from "@angular/platform-browser";
var styles_ExternalFontsComponent = [];
var RenderType_ExternalFontsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExternalFontsComponent, data: {} });
export { RenderType_ExternalFontsComponent as RenderType_ExternalFontsComponent };
function View_ExternalFontsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "link", [["rel", "stylesheet"]], [[8, "href", 5]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
export function View_ExternalFontsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalFontsComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeUrls; var currVal_1 = _co.trackUrl; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ExternalFontsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-external-fonts", [], null, null, null, View_ExternalFontsComponent_0, RenderType_ExternalFontsComponent)), i0.ɵdid(1, 638976, null, 0, i2.ExternalFontsComponent, [i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalFontsComponentNgFactory = i0.ɵccf("app-external-fonts", i2.ExternalFontsComponent, View_ExternalFontsComponent_Host_0, { fonts: "fonts" }, {}, []);
export { ExternalFontsComponentNgFactory as ExternalFontsComponentNgFactory };

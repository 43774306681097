<div class="code-parameter">
  <div class="code-parameter__title">
    <span class="code-parameter__name">{{ item.name }}</span>
    <span *ngIf="item.fieldTypeLabel" class="code-parameter__type">{{ item.fieldTypeLabel }}</span>
    <span *ngIf="item.required" class="code-parameter__required">required</span>
  </div>

  <div *ngIf="item.description || typeDescription" class="code-parameter__description">
    <div *ngIf="item.description" [innerHTML]="item.description"></div>
    <div *ngIf="typeDescription" [innerHTML]="typeDescription"></div>
  </div>

  <div *ngIf="item.value | appIsSet" class="code-parameter__value-wrapper">
    <input class="code-parameter__value" [value]="item.value" (focus)="self.select()" #self />
    <a
      href="javascript:void(0)"
      class="code-parameter__value-button icon-duplicate"
      (click)="copy(item.value, item.name + ' header')"
    >
    </a>
  </div>

  <div *ngIf="item.apiKey" class="code-parameter__description">
    <ng-container *ngIf="item.value | appIsSet">
      <a [appLink]="currentProjectStore.instance.settingsLink"><span class="icon-console"></span> Manage API keys</a>
    </ng-container>

    <ng-container *ngIf="!(item.value | appIsSet)">
      <a [appLink]="currentProjectStore.instance.settingsLink"><span class="icon-console"></span> Create API key</a>
    </ng-container>
  </div>

  <div *ngIf="item.children?.length">
    <div class="code-parameter__button code-parameter-spoiler" [class.code-parameter-spoiler_active]="childrenExpanded">
      <div class="code-parameter-spoiler__header" (click)="toggleChildrenExpanded()">
        <span
          class="code-parameter-spoiler__icon"
          [class.icon-arrow_down_2]="childrenExpanded"
          [class.icon-arrow_forward_2]="!childrenExpanded"
        ></span>
        <span class="code-parameter-spoiler__label">
          <ng-container *ngIf="childrenExpanded">Hide child parameters</ng-container>
          <ng-container *ngIf="!childrenExpanded">Show child parameters</ng-container>
        </span>
      </div>

      <div class="code-parameter-spoiler__content">
        <div class="code-parameters">
          <ng-container *ngFor="let child of item.children">
            <div class="code-parameters__item">
              <div class="code-parameter">
                <div class="code-parameter__title">
                  <span class="code-parameter__name">{{ child.name }}</span>
                  <span *ngIf="child.fieldTypeLabel" class="code-parameter__type">{{ child.fieldTypeLabel }}</span>
                </div>
                <div
                  *ngIf="child.description"
                  class="code-parameter__description"
                  [innerHTML]="child.description"
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

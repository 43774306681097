var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DataSourceType } from '@modules/data-sources';
import { getFieldDescriptionByType, Input, InputValueType, lookupMatchers, ParameterField } from '@modules/fields';
import { forceObservable } from '@shared';
import { queryHasFrontendAutoParameters, queryHasResourceAutoParameters } from '../utils/auto-parameters';
var InputFieldProvider = /** @class */ (function () {
    function InputFieldProvider() {
        this._value = new BehaviorSubject([]);
    }
    Object.defineProperty(InputFieldProvider.prototype, "value", {
        get: function () {
            return this._value.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFieldProvider.prototype, "value$", {
        get: function () {
            return this._value.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    InputFieldProvider.prototype.itemsToFields = function (value) {
        var getFields = function (items) {
            return items.reduce(function (prev, item) {
                if (item.field) {
                    prev.push(item.field);
                }
                if (item.children) {
                    prev.push.apply(prev, getFields(item.children));
                }
                return prev;
            }, []);
        };
        return getFields(value);
    };
    Object.defineProperty(InputFieldProvider.prototype, "fields", {
        get: function () {
            return this.itemsToFields(this.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFieldProvider.prototype, "fields$", {
        get: function () {
            var _this = this;
            return this.value$.pipe(map(function (value) { return _this.itemsToFields(value); }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        },
        enumerable: true,
        configurable: true
    });
    InputFieldProvider.prototype.setProvider = function (provider) {
        var _this = this;
        this.clearProvider();
        this.subscription = forceObservable(provider).subscribe(function (value) {
            _this._value.next(value);
        });
    };
    InputFieldProvider.prototype.clearProvider = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    };
    return InputFieldProvider;
}());
export { InputFieldProvider };
export function fieldsToProviderItems(fields) {
    return fields.map(function (item) {
        return {
            label: item.verboseName || item.name,
            field: __assign({}, item)
        };
    });
}
export function parametersToProviderItemsFlat(parameters) {
    return parameters.map(function (item) {
        return {
            label: item.verboseName || item.name,
            field: __assign({}, item)
        };
    });
}
export function parametersToProviderItems(parameters) {
    var noGroupKey = '__no-group__';
    return toPairs(parameters.reduce(function (acc, item) {
        var group = item.group || noGroupKey;
        var groupIcon = item.groupIcon;
        if (!acc[group]) {
            acc[group] = {
                icon: groupIcon,
                items: []
            };
        }
        var fieldDescription = getFieldDescriptionByType(item.field);
        acc[group].items.push({
            label: item.verboseName || item.name,
            icon: item.icon || fieldDescription.icon,
            field: __assign({}, item)
        });
        return acc;
    }, {})).reduce(function (acc, pairs) {
        var group = pairs[0];
        var groupData = pairs[1];
        if (group == noGroupKey) {
            acc.push.apply(acc, groupData.items);
        }
        else {
            acc.push({
                label: group,
                icon: groupData.icon,
                children: groupData.items
            });
        }
        return acc;
    }, []);
}
export function autoParametersFromModelGet(resource, modelDescription, getQuery, columns, type) {
    if (columns === void 0) { columns = []; }
    var result = [];
    if (queryHasResourceAutoParameters(resource, getQuery, modelDescription)) {
        result.push.apply(result, modelDescription.dbFields.reduce(function (fieldsResult, item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            return fieldDescription.lookups.reduce(function (acc, lookup) {
                var params = __assign({}, fieldDescription.defaultParams, pickBy(item.params, function (v, k) { return lookup.fieldParams && lookup.fieldParams.includes(k); }), fieldDescription.forceParams);
                [false, true].forEach(function (exclude) {
                    var instance = new ParameterField();
                    var prefix = exclude ? 'exclude' : '';
                    var name = [prefix, item.name, lookup.type.lookup].filter(function (i) { return !!i; }).join('__');
                    var verboseName = [prefix, item.verboseName || item.name, lookup.type.verboseName]
                        .filter(function (i) { return !!i; })
                        .join(' ');
                    instance.group = item.verboseName || item.name;
                    instance.groupIcon = fieldDescription.icon;
                    // instance.name = serializeFieldParamName(item.name, lookup.type.lookup);
                    instance.name = name;
                    instance.verboseName = verboseName;
                    instance.icon = lookup.type ? lookup.type.icon : undefined;
                    instance.field = lookup.field;
                    instance.required = false;
                    instance.params = params;
                    instance.updateFieldDescription();
                    acc.push(instance);
                });
                return acc;
            }, fieldsResult);
        }, []));
    }
    else if (type == DataSourceType.Input ||
        type == DataSourceType.Workflow ||
        queryHasFrontendAutoParameters(resource, getQuery, modelDescription)) {
        result.push.apply(result, columns.reduce(function (acc, column) {
            var fieldDescription = getFieldDescriptionByType(column.field);
            fieldDescription.lookups.forEach(function (lookup) {
                var params = __assign({}, fieldDescription.defaultParams, pickBy(column.params, function (v, k) { return lookup.fieldParams && lookup.fieldParams.includes(k); }), fieldDescription.forceParams);
                var lookupMatcher = lookupMatchers.find(function (i) { return i.field == column.field && i.lookup === lookup.type; });
                if (!lookupMatcher) {
                    return;
                }
                [false, true].forEach(function (exclude) {
                    var instance = new ParameterField();
                    var prefix = exclude ? 'exclude' : '';
                    var name = [prefix, column.name, lookup.type.lookup].filter(function (item) { return !!item; }).join('__');
                    var verboseName = [prefix, column.verboseName || column.name, lookup.type.verboseName]
                        .filter(function (item) { return !!item; })
                        .join(' ');
                    instance.group = column.verboseName || column.name;
                    instance.groupIcon = fieldDescription.icon;
                    // instance.name = serializeFieldParamName(column.name, lookup.type.lookup, exclude);
                    instance.name = name;
                    instance.verboseName = verboseName;
                    instance.icon = lookup.type ? lookup.type.icon : undefined;
                    instance.field = lookup.field;
                    instance.required = false;
                    instance.params = params;
                    instance.updateFieldDescription();
                    acc.push(instance);
                });
            });
            return acc;
        }, []));
    }
    return result;
}
export function autoInputsFromModelGet(resource, modelDescription, getQuery, columns, type) {
    if (columns === void 0) { columns = []; }
    var result = [];
    if (queryHasResourceAutoParameters(resource, getQuery, modelDescription)) {
        result.push.apply(result, modelDescription.dbFields.reduce(function (fieldsResult, item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            return fieldDescription.lookups.reduce(function (lookupsResult, lookup) {
                var instance = new Input();
                var name = [item.name, lookup.type.lookup].filter(function (i) { return !!i; }).join('__');
                // instance.name = serializeFieldParamName(item.name, lookup.type.lookup);
                instance.name = name;
                instance.valueType = InputValueType.Filter;
                instance.filterField = item.name;
                instance.filterLookup = lookup.type.lookup;
                lookupsResult.push(instance);
                return lookupsResult;
            }, fieldsResult);
        }, []));
    }
    else if (type == DataSourceType.Input ||
        type == DataSourceType.Workflow ||
        queryHasFrontendAutoParameters(resource, getQuery, modelDescription)) {
        result.push.apply(result, columns.reduce(function (acc, column) {
            var fieldDescription = getFieldDescriptionByType(column.field);
            fieldDescription.lookups.forEach(function (lookup) {
                var lookupMatcher = lookupMatchers.find(function (i) { return i.field == column.field && i.lookup === lookup.type; });
                if (!lookupMatcher) {
                    return;
                }
                [false, true].forEach(function (exclude) {
                    var input = new Input();
                    var prefix = exclude ? 'exclude' : '';
                    var name = [prefix, column.name, lookup.type.lookup].filter(function (item) { return !!item; }).join('__');
                    // input.name = serializeFieldParamName(column.name, lookup.type.lookup, exclude);
                    input.name = name;
                    input.valueType = InputValueType.Filter;
                    input.filterField = column.name;
                    input.filterLookup = lookup.type.lookup;
                    acc.push(input);
                });
            });
            return acc;
        }, []));
    }
    return result;
}
// export function parametersFromModelGet(
//   resource: Resource,
//   modelDescription: ModelDescription,
//   parameters: ParameterField[],
//   modelDescriptionQuery?: ListModelDescriptionQuery,
//   columns: RawListViewSettingsColumn[] = []
// ): ParameterField[] {
//   const result: ParameterField[] = [];
//
//   if (modelDescription) {
//     result.push(...modelDescription.getParameters);
//   } else if (parameters) {
//     result.push(...parameters.filter(item => item.name));
//   }
//
//   if (
//     modelDescriptionQuery &&
//     ((modelDescriptionQuery.simpleQuery &&
//       modelDescription &&
//       modelDescription.getQuery &&
//       modelDescription.getQuery.frontendFiltering) ||
//       (!modelDescriptionQuery.simpleQuery && modelDescriptionQuery.frontendFiltering))
//   ) {
//     result.push(
//       ...columns.reduce((acc, column) => {
//         const fieldDescription = getFieldDescriptionByType(column.field);
//         fieldDescription.lookups.forEach(lookup => {
//           const lookupMatcher = lookupMatchers.find(i => i.field == column.field && i.lookup === lookup.type);
//
//           if (!lookupMatcher) {
//             return;
//           }
//
//           ['', 'exclude'].forEach(prefix => {
//             const name = [prefix, column.name, lookup.type.lookup].filter(item => !!item).join('__');
//
//             if (result.find(item => item.name == name)) {
//               return;
//             }
//
//             const parameter = new ParameterField();
//             const verboseName = [prefix, column.verboseName || column.name, lookup.type.verboseName]
//               .filter(item => !!item)
//               .join(' ');
//
//             parameter.group = column.verboseName || column.name;
//             parameter.name = name;
//             parameter.verboseName = verboseName;
//             parameter.field = lookup.field;
//             parameter.required = false;
//             parameter.params = lookup.extraParams || {};
//             parameter.updateFieldDescription();
//
//             acc.push(parameter);
//           });
//         });
//
//         return acc;
//       }, [])
//     );
//   }
//
//   return result;
// }
export function inputFieldProviderItemsFromModelGet(resource, modelDescription, modelDescriptionQuery, columns, type) {
    if (columns === void 0) { columns = []; }
    return parametersToProviderItems((modelDescription ? modelDescription.getParameters : []).concat(autoParametersFromModelGet(resource, modelDescription, modelDescriptionQuery, columns, type)));
}
export function inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters) {
    var result = [];
    if (parameters) {
        result.push.apply(result, parametersToProviderItems(parameters.filter(function (item) { return item.name; })));
    }
    if (modelDescription) {
        var modelParameters = modelDescription.getDetailParametersOrDefaults;
        result.push.apply(result, parametersToProviderItems(modelParameters));
    }
    return result;
}
export function listModelDescriptionInputFieldProvider(dataSourceType, resource, modelDescription, parameters, getQuery, columns) {
    return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, dataSourceType));
}

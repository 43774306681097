import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BasePopupComponent } from '@common/popups';
import { ResourceName, ResourceTypeItem } from '@modules/projects';

@Component({
  selector: 'app-choose-sync-mode',
  templateUrl: './choose-sync-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseSyncModeComponent implements OnInit {
  @Input() control: FormControl;
  @Input() typeItem: ResourceTypeItem;
  @Output() chosen = new EventEmitter<void>();

  resourceNames = ResourceName;

  constructor(@Optional() protected popupComponent: BasePopupComponent) {}

  ngOnInit() {}

  submit() {
    this.chosen.emit();
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

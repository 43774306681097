import clone from 'lodash/clone';

export interface OrdersDiff {
  item: any;
  diff: number;
}

function detectMoveForward(ordersDiff: OrdersDiff[], forward) {
  let from: number;
  let to: number;
  let itemMoved: any;
  let moveDetected = false;

  const items = forward ? ordersDiff : clone(ordersDiff).reverse();
  const siblingsDiff = forward ? -1 : 1;

  for (let i = 0; i < items.length; ++i) {
    const diff = items[i].diff;

    if (!moveDetected && diff == siblingsDiff) {
      moveDetected = true;
      from = forward ? i : items.length - i - 1;
    } else if (moveDetected && diff != siblingsDiff) {
      to = forward ? i : items.length - i - 1;
      itemMoved = items[i].item;
      break;
    }
  }

  if (from == to && from == undefined) {
    return;
  }

  return {
    from: from,
    to: to,
    item: itemMoved
  };
}

function detectMove(ordersDiff: OrdersDiff[]) {
  const move = detectMoveForward(ordersDiff, true);

  if (move) {
    return move;
  }

  return detectMoveForward(ordersDiff, false);
}

export function detectMoveUpdates<T>(
  items: T[],
  prevItems: T[]
): {
  forward: boolean;
  segmentFrom: T;
  segmentTo: T;
  item: T;
} {
  const ordersDiff = items.map((item, index) => {
    const prevIndex = prevItems.findIndex(prevItem => prevItem === item);
    return { item: item, diff: index - prevIndex };
  });

  const move = detectMove(ordersDiff);

  if (!move) {
    return;
  }

  let data;

  if (move.to >= move.from) {
    data = {
      forward: true,
      segmentFrom: items[move.from],
      segmentTo: items[move.to - 1],
      item: items[move.to]
    };
  } else {
    data = {
      forward: false,
      segmentFrom: items[move.to + 1],
      segmentTo: items[move.from],
      item: items[move.to]
    };
  }

  return data;
}

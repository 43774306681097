import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ParameterArray, ParameterField } from '@modules/fields';
import { Resource } from '@modules/projects';
import { ObjectQuery, ObjectQueryOperation } from '@modules/queries';
import {
  FirebaseResourceController,
  OBJECT_QUERY_KEY_LABEL,
  OBJECT_QUERY_KEY_NAME,
  ResourceControllerService
} from '@modules/resources';
import { controlValue, filterObject, limitObjectLength } from '@shared';

import { QueryBuilderContext } from '../../data/query-builder-context';
import { QueryBuilderCustomComponent } from '../query-builder/query-builder.component';
import { QueryBuilderObjectForm, QueryBuilderObjectOptions } from './query-builder-object.form';

enum ResultsSection {
  Parameters,
  Result
}

@Component({
  selector: 'app-query-builder-object',
  templateUrl: './query-builder-object.component.html',
  providers: [QueryBuilderObjectForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderObjectComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() context: QueryBuilderContext;
  @Input() control: AbstractControl;
  @Input() requireResponse = false;
  @Input() arrayResponse = false;
  @Input() options: QueryBuilderObjectOptions = {};
  @Input() customSections: QueryBuilderCustomComponent[] = [];
  @Input() parametersControl: ParameterArray;
  @Input() source: string;
  @Output() executed = new EventEmitter<ObjectQuery>();
  @Output() saved = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<boolean>();

  objectLoading = false;
  loading = false;

  requestCreate = false;
  response: any;
  responseRaw$ = new BehaviorSubject<any>(undefined);
  responseActual = false;
  error: string;

  resultsSection = ResultsSection.Result;
  resultsSections = ResultsSection;
  customSectionComponentsTop: DynamicComponentArguments[] = [];
  customSectionComponentsBottom: DynamicComponentArguments[] = [];
  customSectionComponentsPreview: DynamicComponentArguments[] = [];

  saveHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageVisible$ = combineLatest(
    this.saveHovered.pipe(
      filter(value => (value && this.isResponseMissing()) || !value),
      debounce(value => timer(value ? 0 : 200))
    ),
    this.requireResponseMessageHovered
  ).pipe(
    map(([saveHovered, requireResponseMessageHovered]) => {
      return saveHovered || requireResponseMessageHovered;
    })
  );
  object: Object;
  objectQueryOperations = ObjectQueryOperation;

  constructor(
    private firebaseResourceController: FirebaseResourceController,
    public form: QueryBuilderObjectForm,
    private resourceControllerService: ResourceControllerService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const query = this.control.value as ObjectQuery;

    this.form.init(this.control.value, this.arrayResponse, this.currentOptions);
    //   this.context.sqlForm = this.form;
    //   this.context.paginationTokens = false;
    //   this.context.sortingTokens = false;

    if (query) {
      this.requestCreate = false;
      this.context.tokenValues = query.requestTokens;

      if (query.requestResponse !== undefined) {
        this.response = query.requestResponse;
        this.responseActual = true;
        this.cd.markForCheck();
      }
    } else {
      this.requestCreate = true;
      this.context.tokenValues = {};
    }

    merge(
      ...values<AbstractControl>(this.form.controls)
        .filter(control => {
          return [this.form.controls.request_response, this.form.controls.request_tokens].every(
            item => item !== control
          );
        })
        .map(control => control.valueChanges)
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(() => {
        this.responseActual = false;
        this.cd.markForCheck();
      });

    this.updateCustomSections();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.StartToSetUp, {
      ResourceType: this.resource.typeItem.name,
      Source: this.source
    });

    this.fetchObject();

    combineLatest(
      controlValue(this.form.controls.operation),
      controlValue(this.form.controls.query),
      controlValue(this.form.controls.object_to_array)
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([operation]) => {
        this.executeDisplay(operation == ObjectQueryOperation.Get);
      });

    controlValue(this.form.controls.operation)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resultsSection =
          this.form.controls.operation.value == ObjectQueryOperation.Get
            ? ResultsSection.Result
            : ResultsSection.Parameters;
        this.cd.markForCheck();
      });

    combineLatest(controlValue(this.form.controls.operation), this.responseRaw$)
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([operation, response]) => {
        const responseObject = isArray(response) ? response[0] : values(response)[0];
        const objectKeyParameter = (required = true, description?: string) => {
          const result = new ParameterField();

          result.name = OBJECT_QUERY_KEY_NAME;
          result.verboseName = OBJECT_QUERY_KEY_LABEL;
          result.required = required;
          result.protected = true;
          result.description = description;

          if (!required) {
            result.placeholder = 'Populated automatically if empty';
          }

          result.updateFieldDescription();

          return result;
        };

        if (operation == ObjectQueryOperation.Create) {
          const parameters = responseObject
            ? keys(responseObject).map(key => {
                const result = new ParameterField();

                result.name = key;
                result.required = true;
                result.updateFieldDescription();

                return result;
              })
            : [];

          this.parametersControl.patchValue([
            objectKeyParameter(false, 'Create Object with the following key'),
            ...parameters
          ]);
        } else if (operation == ObjectQueryOperation.Update) {
          const parameters = responseObject
            ? keys(responseObject).map(key => {
                const result = new ParameterField();

                result.name = key;
                result.required = true;
                result.updateFieldDescription();

                return result;
              })
            : [];

          this.parametersControl.patchValue([
            objectKeyParameter(true, 'Update Object with the following key'),
            ...parameters
          ]);
        } else if (operation == ObjectQueryOperation.Delete) {
          this.parametersControl.patchValue([objectKeyParameter(true, 'Delete Object with the following key')]);
        } else {
          this.parametersControl.patchValue([]);
        }
      });
  }

  ngOnDestroy(): void {}

  get currentOptions(): QueryBuilderObjectOptions {
    return {
      ...this.options
    };
  }

  setResultsSection(resultsSection) {
    this.resultsSection = resultsSection;
    this.cd.markForCheck();
  }

  updateCustomSections() {
    this.customSectionComponentsTop = this.customSections
      .filter(item => item.position == 'top')
      .map(item => item.component);
    this.customSectionComponentsBottom = this.customSections
      .filter(item => item.position == 'bottom')
      .map(item => item.component);
    this.customSectionComponentsPreview = this.customSections
      .filter(item => item.position == 'preview')
      .map(item => item.component);
    this.cd.markForCheck();
  }

  fetchObject() {
    this.objectLoading = true;
    this.cd.markForCheck();

    const controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;

    controller
      .objectGet(this.resource)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.object = result;
          this.objectLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.object = undefined;
          this.objectLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  execute() {
    const query = this.form.getInstance();

    if (query.operation == ObjectQueryOperation.Get) {
      return this.executeDisplay();
    } else {
      return this.executeOperation();
    }
  }

  executeDisplay(showResponse = true) {
    const query = this.form.getInstance();
    const saveTokens = this.context.serializeTokenValues(true);

    this.loading = true;
    this.response = undefined;
    this.responseRaw$.next(undefined);
    this.error = undefined;

    if (showResponse) {
      this.resultsSection = ResultsSection.Result;
    }

    this.cd.markForCheck();

    const controller: FirebaseResourceController = this.resource
      ? this.resourceControllerService.get(this.resource.type)
      : undefined;

    controller
      .objectGet(this.resource, query.query, { ...query.queryOptions, objectToArray: false })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.response = result;
          this.responseRaw$.next(result);

          this.response = controller.objectProcess(this.response, query.queryOptions);

          this.responseActual = true;
          this.loading = false;
          this.context.lastExecutedResponse = this.response;
          this.form.controls.request_response.patchValue(limitObjectLength(this.response, 20));
          this.form.controls.request_tokens.patchValue(filterObject(saveTokens, item => !(item instanceof Blob)));
          this.executed.emit(query);
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        },
        error => {
          if (error.fieldErrors['error']) {
            this.error = error.fieldErrors['error'];
          } else {
            this.error = 'Unknown error';
          }

          this.responseActual = true;
          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.UnsuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        }
      );
  }

  executeOperation() {
    const query = this.form.getInstance();
    const requestTokens = this.context.serializeTokenValues();

    this.loading = true;
    // this.response = undefined;
    this.error = undefined;
    this.cd.markForCheck();

    const controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;

    controller
      .objectQuery(this.resource, query, requestTokens['params'])
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          // this.response = result;
          // this.responseActual = true;
          this.loading = false;
          // this.context.lastExecutedResponse = this.response;
          // this.form.patchValue({
          //   request_response: limitObjectLength(this.response, 20),
          //   request_tokens: filterObject(tokens, item => !(item instanceof Blob))
          // });
          this.executed.emit(query);
          this.cd.markForCheck();

          this.fetchObject();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        },
        error => {
          if (error.fieldErrors['error']) {
            this.error = error.fieldErrors['error'];
          } else {
            this.error = 'Unknown error';
          }

          // this.responseActual = true;
          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.UnsuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        }
      );
  }

  cancel() {
    this.canceled.emit();
  }

  saveProcess() {
    const query = this.form.getInstance();
    this.control.patchValue(query);
    this.saved.emit();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullySetUp, {
      ResourceType: this.resource.typeItem.name
    });
  }

  isResponseMissing() {
    return this.requireResponse && !this.responseActual;
  }

  save() {
    if (this.isResponseMissing()) {
      return;
    }

    this.saveProcess();
  }

  submit() {
    this.save();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';

import { UniversalAnalyticsService } from '@modules/analytics';
import { CarouselSettings } from '@modules/customize';
import { ResourceGeneratorResolver } from '@modules/resource-generators';

import { CustomizeBarListLayoutSettingsDataSourceComponent } from '../../customize-bar-list-layout-settings/customize-bar-list-layout-settings-data-source.component';
import { CustomizeBarCarouselSettingsForm } from '../customize-bar-carousel-settings.form';

@Component({
  selector: 'app-customize-bar-carousel-settings-data-source',
  templateUrl: './customize-bar-carousel-settings-data-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCarouselSettingsDataSourceComponent
  extends CustomizeBarListLayoutSettingsDataSourceComponent<CarouselSettings>
  implements OnDestroy {
  @Input() form: CustomizeBarCarouselSettingsForm;

  constructor(
    resourceGeneratorResolver: ResourceGeneratorResolver,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(resourceGeneratorResolver, analyticsService, cd);
  }
}

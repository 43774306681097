import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { Domain } from '@modules/domain';
import { Project } from '@modules/projects';
import { TemplateItem } from '@modules/template-components';

import { ProjectCreatePopupComponent } from '../../components/project-create-popup/project-create-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ProjectCreateController {
  constructor(
    private injector: Injector,
    private popupService: PopupService,
    private resolver: ComponentFactoryResolver
  ) {}

  openCreatePopup(
    options: { applyTemplateItem?: TemplateItem; domain?: Domain; dark?: boolean } = {}
  ): Observable<Project> {
    const result = new Subject<Project>();

    this.popupService.push({
      component: ProjectCreatePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentDark: options.dark,
      inputs: {
        applyTemplateItem: options.applyTemplateItem,
        domain: options.domain
      },
      outputs: {
        created: [
          project => {
            result.next(project);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }
}

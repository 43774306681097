import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Environment } from '../../data/environment';
import { PaymentDetails } from '../../data/payment-details';
import { Project } from '../../data/project';
import { ProjectUser } from '../../data/project-user';
import { Subscription } from '../../data/subscription';
import { Transaction } from '../../data/transaction';

@Injectable({
  providedIn: 'root'
})
export class ProjectBillingService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  getSubscription(projectName: string, params = {}): Observable<Subscription> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'subscription/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new Subscription().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getSubscriptionUsers(
    projectName: string,
    environmentName: string,
    params = {}
  ): Observable<ProjectBillingService.GetSubscriptionUsersResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'subscription_users/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new ProjectBillingService.GetSubscriptionUsersResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getPaymentDetails(project: Project): Observable<PaymentDetails> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(project.uniqueName, `subscription/payment_details/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(result => new PaymentDetails().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  cancelSubscription(project: Project): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(project.uniqueName, `subscription/cancel/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getTransactions(projectName: string, params = {}): Observable<ProjectBillingService.GetTransactionsResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'transactions/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: httpParams });
      }),
      map(result => new ProjectBillingService.GetTransactionsResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace ProjectBillingService {
  export class GetTransactionsResponse {
    public results: Transaction[];
    public next: string;
    public previous: string;
    public count: number;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new Transaction().deserialize(item));
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }
  }

  export class EnvironmentUsersResponse {
    public environment: Environment;
    public results: ProjectUser[] = [];
    public next = false;
    public previous = false;
    public count: number;
    public page: number;
    public pageSize: number;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new ProjectUser().deserialize(item));
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];
      this.page = data['page'];
      this.pageSize = data['page_size'];

      if (data['environment']) {
        this.environment = new Environment().deserialize(data['environment']);
      }

      return this;
    }
  }

  export class GetSubscriptionUsersResponse {
    public environments: EnvironmentUsersResponse[] = [];
    public uniqueCount: number;

    deserialize(data: Object) {
      this.environments = data['environments'].map(item => new EnvironmentUsersResponse().deserialize(item));
      this.uniqueCount = data['unique_count'];

      return this;
    }
  }
}

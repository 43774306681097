var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delayWhen, map, switchMap } from 'rxjs/operators';
import { SessionStorage } from '@core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ApiService, ServerRequestError } from '@modules/api';
import { CustomViewSettings, ViewSettingsService } from '@modules/customize';
import { ProjectPublicAccessService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';
var ProjectPublicAccessComponent = /** @class */ (function () {
    function ProjectPublicAccessComponent(activatedRoute, projectPublicAccessService, apiService, currentUserStore, projectsStore, viewSettingsService, routing, sessionStorage, analyticsService, cd) {
        this.activatedRoute = activatedRoute;
        this.projectPublicAccessService = projectPublicAccessService;
        this.apiService = apiService;
        this.currentUserStore = currentUserStore;
        this.projectsStore = projectsStore;
        this.viewSettingsService = viewSettingsService;
        this.routing = routing;
        this.sessionStorage = sessionStorage;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.loading = false;
        this.notFound = false;
        this.publicAccessNotEnabled = false;
        this.publicAccessNotEnabledInFree = false;
        this.error = false;
        this.showMenu = true;
    }
    ProjectPublicAccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params
            .pipe(switchMap(function (params) {
            var code = params['code'];
            _this.loading = true;
            _this.notFound = false;
            _this.publicAccessNotEnabled = false;
            _this.publicAccessNotEnabledInFree = false;
            _this.error = false;
            _this.cd.markForCheck();
            return _this.projectPublicAccessService.accept(code);
        }), delayWhen(function (result) {
            var iframe = _this.activatedRoute.snapshot.queryParams['iframe'];
            var iframeKey = "iframe_" + result.publicAccess.project.uniqueName;
            if (iframe) {
                _this.sessionStorage.set(iframeKey, '1');
            }
            else if (_this.sessionStorage.get(iframeKey)) {
                _this.sessionStorage.remove(iframeKey);
            }
            _this.apiService.enableSessionScope();
            _this.apiService.saveToken(result.userToken);
            return combineLatest([_this.currentUserStore.getFirst(true), _this.projectsStore.updateIfLoaded()]);
        }), switchMap(function (result) {
            var page$;
            if (result.publicAccess.pageUid) {
                page$ = _this.viewSettingsService
                    .get(result.publicAccess.project.uniqueName, result.publicAccess.environment.uniqueName)
                    .pipe(map(function (pages) {
                    return pages.find(function (item) { return item instanceof CustomViewSettings && item.uid == result.publicAccess.pageUid; });
                }));
            }
            else {
                page$ = of(undefined);
            }
            return combineLatest(of(result), page$);
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var result = _a[0], page = _a[1];
            var project = result.publicAccess.project;
            var environment = result.publicAccess.environment;
            var link;
            var queryParams = __assign({}, result.publicAccess.pageParams, _this.activatedRoute.snapshot.queryParams);
            if (page) {
                link = project.linkWithProtocol(page.link, { environmentName: environment.uniqueName });
            }
            else {
                link = project.getHomeLinkWithProtocol({ environmentName: environment.uniqueName });
            }
            _this.routing.navigateLink(link, { queryParams: queryParams });
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            if (error instanceof ServerRequestError) {
                if (error.response && error.response.status == 404) {
                    _this.notFound = true;
                }
                else if (error.fieldErrors['code'] == 'FEATURE_NOT_ENABLED') {
                    _this.publicAccessNotEnabled = true;
                }
                else if (error.fieldErrors['code'] == 'PUBLIC_ACCESS_NOT_ENABLED_IN_FREE') {
                    _this.publicAccessNotEnabledInFree = true;
                }
            }
            _this.loading = false;
            _this.error = true;
            _this.cd.markForCheck();
        });
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(function (params) {
            _this.showMenu = !params['iframe'];
            _this.cd.markForCheck();
        });
    };
    ProjectPublicAccessComponent.prototype.ngOnDestroy = function () { };
    return ProjectPublicAccessComponent;
}());
export { ProjectPublicAccessComponent };

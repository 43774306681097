import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { EmailAddressEditPopupComponent } from './components/email-address-edit-popup/email-address-edit-popup.component';
import { EmailTemplateEditPopupComponent } from './components/email-template-edit-popup/email-template-edit-popup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    FieldsModule,
    FieldComponentsModule,
    SharedModule
  ],
  declarations: [EmailTemplateEditPopupComponent, EmailAddressEditPopupComponent],
  exports: [EmailAddressEditPopupComponent],
  entryComponents: [EmailTemplateEditPopupComponent, EmailAddressEditPopupComponent]
})
export class EmailComponentsModule {}

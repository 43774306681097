import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { TaskQueue } from '../data/task-queue';
import { TaskQueueService } from '../services/task-queue/task-queue.service';

@Injectable()
export class TaskQueueStore extends SingletonStore<TaskQueue[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private taskQueueService: TaskQueueService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<TaskQueue[]> {
    return this.mode$.pipe(
      switchMap(mode =>
        this.taskQueueService.get(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          {},
          mode == AdminMode.Builder
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<TaskQueue[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<TaskQueue[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getDetail(uid: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate, options).pipe(
      map(tasks => {
        if (!tasks) {
          return;
        }

        return tasks.find(queue => queue.uid === uid);
      })
    );
  }

  getDetailFirst(uid: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.getFirst(forceUpdate, options).pipe(
      map(tasks => {
        if (!tasks) {
          return;
        }

        return tasks.find(queue => queue.uid === uid);
      })
    );
  }
}

<div *ngIf="project" class="publish-menu overlay-content overlay-content_animated theme_dark">
  <ng-container *ngIf="!loading">
    <div *ngIf="published" class="publish-menu-section">
      <div class="publish-menu-background-item">
        <div class="publish-menu-background-item__left">
          <span class="status color_green_1 background-color_green_2">
            Published
          </span>
        </div>
        <div class="publish-menu-background-item__main">
          <div class="publish-menu-background-item__title">
            Your App is published <span class="emoji-tada" style="margin-left: 4px; font-size: 16px;"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="publish-menu-section">
      <div class="publish-menu-section__content">
        <ng-container *ngIf="!published">
          <div *ngIf="draftChangesTotal" class="publish-menu__item">
            <div class="publish-menu-card-item publish-menu-card-item_dark2">
              <div class="publish-menu-card-item__left">
                <span class="publish-menu-card-item__indicator"></span>
              </div>
              <div class="publish-menu-card-item__main">
                <a href="javascript:void(0)" class="publish-menu-card-item__link" (click)="showChanges.emit()">
                  {{ draftChangesTotal | i18nPlural: { '=1': '# change', other: '# changes' } }}
                  <span class="icon-arrow_forward_2"></span>
                </a>
              </div>
              <div class="publish-menu-card-item__right publish-menu-card-item__actions">
                <div class="publish-menu-button publish-menu-card-item__action" (click)="deleteDraft.emit()">
                  <span class="publish-menu-button__icon"></span>
                  <span class="publish-menu-button__label">Discard</span>
                </div>

                <div
                  class="publish-menu-button publish-menu-button_purple publish-menu-card-item__action"
                  (click)="publishDraft.emit()"
                >
                  <span class="publish-menu-button__icon"></span>
                  <span class="publish-menu-button__label">Publish</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!draftChangesTotal" class="publish-menu__item">
            <div class="publish-menu-card-item publish-menu-card-item_dark2">
              <div class="publish-menu-card-item__left">
                <span class="publish-menu-card-item__indicator publish-menu-card-item__indicator_green"></span>
              </div>
              <div class="publish-menu-card-item__main">
                <span class="publish-menu-card-item__text">
                  All changes are published
                </span>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!project.domain?.custom">
          <div class="publish-menu__item" (click)="openAppUrl.emit(project.domain)">
            <div class="publish-menu-card-item publish-menu-card-item_interactive">
              <div class="publish-menu-card-item__left">
                <span class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-external_link"></span>
              </div>
              <div class="publish-menu-card-item__main">
                <div class="publish-menu-card-item__title publish-menu-card-item__title_bright">
                  <ng-container *ngIf="project.domain"
                    >{{ project.domain.actualDomain }}/app/{{ project?.uniqueName }}</ng-container
                  >
                  <ng-container *ngIf="!project.domain">app.jetadmin.io/app/{{ project?.uniqueName }}</ng-container>
                </div>
                <div class="publish-menu-card-item__subtitle publish-menu-card-item__subtitle_hover">
                  Open published App
                </div>
              </div>
            </div>
          </div>

          <div class="publish-menu__item">
            <div
              class="publish-menu-card-item publish-menu-card-item_border publish-menu-card-item_interactive"
              (click)="editDomain.emit()"
            >
              <div class="publish-menu-card-item__left">
                <span
                  class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-open_site_countries"
                ></span>
              </div>
              <div class="publish-menu-card-item__main">
                <div class="publish-menu-card-item__title publish-menu-card-item__title_hover">
                  Connect Custom domain
                </div>
              </div>
              <div class="publish-menu-card-item__right">
                <span class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-plus"></span>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="project.domain?.custom" class="publish-menu__item" (click)="openAppUrl.emit(project.domain)">
          <div class="publish-menu-card-item publish-menu-card-item_interactive">
            <div class="publish-menu-card-item__left">
              <span class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-external_link"></span>
            </div>
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title publish-menu-card-item__title_bright">
                {{ project.domain.actualDomain }}/app/{{ project?.uniqueName }}
              </div>
              <div class="publish-menu-card-item__subtitle">
                Open published App
              </div>
            </div>
            <div class="publish-menu-card-item__right">
              <span
                class="publish-menu-card-item__icon icon-gear"
                [appTip]="'Edit Custom Domain'"
                [appTipOptions]="{ side: 'top' }"
                (click)="$event.stopPropagation(); editDomain.emit()"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="published" class="publish-menu-section">
      <div class="publish-menu-section__title">Who can access published App</div>
      <div class="publish-menu-section__content">
        <div class="publish-menu__item">
          <div
            class="publish-menu-card-item publish-menu-card-item_interactive"
            [class.publish-menu-card-item_dark]="usersCount"
            [class.publish-menu-card-item_border]="!usersCount"
            (click)="openShareInvite.emit()"
          >
            <div class="publish-menu-card-item__left">
              <span class="publish-menu-card-item__icon icon-email"></span>
            </div>
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title publish-menu-card-item__title_hover">Members</div>
              <div *ngIf="usersCount" class="publish-menu-card-item__subtitle">
                {{ usersCount | i18nPlural: { '=1': '# member', other: '# members' } }}
              </div>
            </div>
            <div class="publish-menu-card-item__right">
              <span class="publish-menu-card-item__action-label publish-menu-card-item__action-label_hover">
                Manage
                <span
                  class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-arrow_forward_2"
                ></span>
              </span>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div
            class="publish-menu-card-item publish-menu-card-item_interactive"
            [class.publish-menu-card-item_dark]="publicInvites.length"
            [class.publish-menu-card-item_border]="!publicInvites.length"
            (click)="openSharePublicLink.emit()"
          >
            <div class="publish-menu-card-item__left">
              <span class="publish-menu-card-item__icon icon-link"></span>
            </div>
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title publish-menu-card-item__title_hover">Invite with Link</div>
              <div *ngIf="publicInvites.length" class="publish-menu-card-item__subtitle">
                {{ publicInvites.length | i18nPlural: { '=1': '# link', other: '# links' } }}
              </div>
            </div>
            <div class="publish-menu-card-item__right">
              <span
                *ngIf="publicInvites.length"
                class="publish-menu-card-item__action-label publish-menu-card-item__action-label_hover"
              >
                Manage
                <span
                  class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-arrow_forward_2"
                ></span>
              </span>

              <app-toggle *ngIf="!publicInvites.length" [selected]="false" [orange]="true"></app-toggle>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div
            class="publish-menu-card-item publish-menu-card-item_interactive"
            [class.publish-menu-card-item_dark]="publicAccess.length"
            [class.publish-menu-card-item_border]="!publicAccess.length"
            (click)="openSharePublicAccess.emit()"
          >
            <div class="publish-menu-card-item__left">
              <span class="publish-menu-card-item__icon icon-earth_planet"></span>
            </div>
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title publish-menu-card-item__title_hover">Public access</div>
              <div *ngIf="publicAccess.length" class="publish-menu-card-item__subtitle">
                {{ publicAccess.length | i18nPlural: { '=1': '# page', other: '# pages' } }}
              </div>
            </div>
            <div class="publish-menu-card-item__right">
              <span
                *ngIf="publicAccess.length"
                class="publish-menu-card-item__action-label publish-menu-card-item__action-label_hover"
              >
                Manage
                <span
                  class="publish-menu-card-item__icon publish-menu-card-item__icon_hover icon-arrow_forward_2"
                ></span>
              </span>

              <app-toggle *ngIf="!publicAccess.length" [selected]="false" [orange]="true"></app-toggle>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div
            class="publish-menu-card-item publish-menu-card-item_interactive"
            [class.publish-menu-card-item_dark]="signUpEnabled"
            [class.publish-menu-card-item_border]="!signUpEnabled"
            (click)="openSignUp.emit()"
          >
            <div class="publish-menu-card-item__left">
              <span class="publish-menu-card-item__icon icon-signup"></span>
            </div>
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title publish-menu-card-item__title_hover">Sign Up page</div>
              <div *ngIf="signUpEnabled && signUpGroup" class="publish-menu-card-item__subtitle">
                Join <strong>{{ signUpGroup.name }}</strong> team
              </div>
            </div>
            <div class="publish-menu-card-item__right">
              <app-toggle [selected]="signUpEnabled" [orange]="true"></app-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="publish-menu__item">
      <div class="publish-menu-card-item">
        <div class="publish-menu-card-item__main">
          <div class="publish-menu-card-item__title">
            <span [class.loading-animation]="true"><span class="stub-text">app.jetadmin.io</span></span>
          </div>
          <div class="publish-menu-card-item__subtitle">
            <span [class.loading-animation]="true"><span class="stub-text">Open</span></span>
          </div>
        </div>
      </div>
    </div>

    <div class="publish-menu-section">
      <div class="publish-menu-section__title">
        <span [class.loading-animation]="true"><span class="stub-text">who access</span></span>
      </div>
      <div class="publish-menu-section__content">
        <div class="publish-menu__item">
          <div class="publish-menu-card-item" [class.publish-menu-card-item_dark]="true">
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">Members</span></span>
              </div>
              <div class="publish-menu-card-item__subtitle">
                <span [class.loading-animation]="true"><span class="stub-text">99 members</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div class="publish-menu-card-item" [class.publish-menu-card-item_dark]="true">
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">Invite link</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div class="publish-menu-card-item" [class.publish-menu-card-item_dark]="true">
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">Public access</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="publish-menu__item">
          <div class="publish-menu-card-item" [class.publish-menu-card-item_dark]="true">
            <div class="publish-menu-card-item__main">
              <div class="publish-menu-card-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">Sign Up</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Environment } from '../../data/environment';
import { PaymentDetails } from '../../data/payment-details';
import { ProjectUser } from '../../data/project-user';
import { Subscription } from '../../data/subscription';
import { Transaction } from '../../data/transaction';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectBillingService = /** @class */ (function () {
    function ProjectBillingService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectBillingService.prototype.getSubscription = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'subscription/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new Subscription().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectBillingService.prototype.getSubscriptionUsers = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'subscription_users/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectBillingService.GetSubscriptionUsersResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectBillingService.prototype.getPaymentDetails = function (project) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(project.uniqueName, "subscription/payment_details/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return new PaymentDetails().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectBillingService.prototype.cancelSubscription = function (project) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(project.uniqueName, "subscription/cancel/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectBillingService.prototype.getTransactions = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'transactions/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectBillingService.GetTransactionsResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectBillingService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectBillingService_Factory() { return new ProjectBillingService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectBillingService, providedIn: "root" });
    return ProjectBillingService;
}());
export { ProjectBillingService };
(function (ProjectBillingService) {
    var GetTransactionsResponse = /** @class */ (function () {
        function GetTransactionsResponse() {
        }
        GetTransactionsResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new Transaction().deserialize(item); });
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        return GetTransactionsResponse;
    }());
    ProjectBillingService.GetTransactionsResponse = GetTransactionsResponse;
    var EnvironmentUsersResponse = /** @class */ (function () {
        function EnvironmentUsersResponse() {
            this.results = [];
            this.next = false;
            this.previous = false;
        }
        EnvironmentUsersResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new ProjectUser().deserialize(item); });
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            this.page = data['page'];
            this.pageSize = data['page_size'];
            if (data['environment']) {
                this.environment = new Environment().deserialize(data['environment']);
            }
            return this;
        };
        return EnvironmentUsersResponse;
    }());
    ProjectBillingService.EnvironmentUsersResponse = EnvironmentUsersResponse;
    var GetSubscriptionUsersResponse = /** @class */ (function () {
        function GetSubscriptionUsersResponse() {
            this.environments = [];
        }
        GetSubscriptionUsersResponse.prototype.deserialize = function (data) {
            this.environments = data['environments'].map(function (item) { return new EnvironmentUsersResponse().deserialize(item); });
            this.uniqueCount = data['unique_count'];
            return this;
        };
        return GetSubscriptionUsersResponse;
    }());
    ProjectBillingService.GetSubscriptionUsersResponse = GetSubscriptionUsersResponse;
})(ProjectBillingService || (ProjectBillingService = {}));

import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Option } from '@modules/field-components';
import { isSet, parseNumber } from '@shared';

import { CornerRadiusControl, CornerRadiusControlValue } from '../../controls/corner-radius.control';

enum Type {
  All = 'all',
  Custom = 'custom'
}

@Component({
  selector: 'app-corner-radius-control',
  templateUrl: './corner-radius-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CornerRadiusControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CornerRadiusControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() formControl: CornerRadiusControl;

  typeControl = new FormControl();
  allControl = new FormControl(0);
  topLeftControl = new FormControl(0);
  topRightControl = new FormControl(0);
  bottomRightControl = new FormControl(0);
  bottomLeftControl = new FormControl(0);

  typeOptions: Option<Type>[] = [
    {
      value: Type.All,
      icon: 'square_rounded',
      name: '',
      subtitle: 'Equal corners'
    },
    {
      value: Type.Custom,
      icon: 'corners',
      name: '',
      subtitle: 'Independent corners'
    }
  ];
  types = Type;

  onChange = (value: CornerRadiusControlValue) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit() {}

  registerOnChange(fn: (value: CornerRadiusControlValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: CornerRadiusControlValue): void {
    const topLeftNumber = isSet(value.top_left) ? parseNumber(value.top_left, 0) : 0;
    const topLeftClean = Math.max(topLeftNumber, 0);

    const topRightNumber = isSet(value.top_right) ? parseNumber(value.top_right, 0) : 0;
    const topRightClean = Math.max(topRightNumber, 0);

    const bottomRightNumber = isSet(value.bottom_right) ? parseNumber(value.bottom_right, 0) : 0;
    const bottomRightClean = Math.max(bottomRightNumber, 0);

    const bottomLeftNumber = isSet(value.bottom_left) ? parseNumber(value.bottom_left, 0) : 0;
    const bottomLeftClean = Math.max(bottomLeftNumber, 0);

    const equalCorners = [topRightClean, bottomRightClean, bottomLeftClean].every(item => item == topLeftClean);

    if (!equalCorners || !this.typeControl.value) {
      this.typeControl.patchValue(equalCorners ? Type.All : Type.Custom);
    }

    this.allControl.patchValue(topLeftClean);
    this.topLeftControl.patchValue(topLeftClean);
    this.topRightControl.patchValue(topRightClean);
    this.bottomRightControl.patchValue(bottomRightClean);
    this.bottomLeftControl.patchValue(bottomLeftClean);
  }

  updateLocalValue(control: FormControl, value: string) {
    const valueNumber = isSet(value) ? parseNumber(value, 0) : 0;
    const cleanValue = Math.max(valueNumber, 0);

    control.patchValue(cleanValue);

    this.updateValue();
  }

  updateValue() {
    const value: CornerRadiusControlValue =
      this.typeControl.value == Type.Custom
        ? {
            top_left: this.topLeftControl.value,
            top_right: this.topRightControl.value,
            bottom_right: this.bottomRightControl.value,
            bottom_left: this.bottomLeftControl.value
          }
        : {
            top_left: this.allControl.value,
            top_right: this.allControl.value,
            bottom_right: this.allControl.value,
            bottom_left: this.allControl.value
          };

    this.onChange(value);
    this.onTouched();
  }
}

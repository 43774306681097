import { Injectable, NgZone } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { RawListViewSettingsColumn } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { Option } from '@modules/field-components';
import { ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import {
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  listModelDescriptionInputFieldProvider,
  modelDescriptionHasAutoParameters,
  parametersToProviderItems
} from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryService, QueryType } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue } from '@shared';

import { ModelDescriptionDataSourceControl } from './model-description-data-source';

@Injectable()
export class ListModelDescriptionDataSourceControl extends ModelDescriptionDataSourceControl<
  ListModelDescriptionDataSource
> {
  public static instanceCls = ListModelDescriptionDataSource;

  constructor(
    modelDescriptionStore: ModelDescriptionStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    projectSettingsService: ProjectSettingsService,
    resourceControllerService: ResourceControllerService,
    resourceGeneratorResolver: ResourceGeneratorResolver,
    modelService: ModelService,
    actionService: ActionService,
    queryService: QueryService,
    notificationService: NotificationService,
    zone: NgZone
  ) {
    super(
      modelDescriptionStore,
      currentEnvironmentStore,
      projectSettingsService,
      resourceControllerService,
      resourceGeneratorResolver,
      modelService,
      actionService,
      queryService,
      notificationService,
      zone
    );
  }

  createModelQuery(): ModelDescriptionQuery {
    return new ListModelDescriptionQuery();
  }

  getInputFieldProvider$(): Observable<InputFieldProviderItem[]> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      this.getResource$(),
      this.getModelDescription$(),
      controlValue<ParameterField[]>(this.controls.query_parameters),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([type, resource, modelDescription, parameters, getQuery, columns]): InputFieldProviderItem[] => {
        return listModelDescriptionInputFieldProvider(type, resource, modelDescription, parameters, getQuery, columns);
      })
    );
  }

  isListQuery() {
    return true;
  }

  isFieldSortable(
    type: DataSourceType,
    query: ListModelDescriptionQuery,
    field: RawListViewSettingsColumn,
    resource?: Resource,
    modelDescription?: ModelDescription
  ) {
    if (field.flex) {
      return false;
    }

    if (type == DataSourceType.Query && query) {
      if (modelDescriptionHasAutoParameters(resource, modelDescription)) {
        return true;
      } else if (query.queryType == QueryType.Simple) {
        return modelDescription && modelDescription.getQuery && modelDescription.getQuery.isFieldSortable(field);
      } else if (query instanceof ListModelDescriptionQuery) {
        return query.isFieldSortable(field);
      } else {
        return false;
      }
    } else if (type == DataSourceType.Input) {
      return true;
    } else {
      return false;
    }
  }

  getSortableColumnOptions$(): Observable<Option<string>[]> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      this.getResource$(),
      this.getModelDescription$(),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([type, resource, modelDescription, getQuery, columns]) => {
        if (!columns) {
          return [];
        }

        return columns
          .filter(item => {
            return this.isFieldSortable(type, getQuery, item, resource, modelDescription);
          })
          .map(item => {
            return {
              value: item.name,
              name: item.verboseName || item.name
            };
          });
      })
    );
  }
}

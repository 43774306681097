import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import toPairs from 'lodash/toPairs';

export const EMPTY_CLS = () => {};
EMPTY_CLS.prototype.toString = () => 'EMPTY';
export const EMPTY = new EMPTY_CLS();

function objectPathNormalize(path: string | PropertyKey[]): PropertyKey[] {
  if (typeof path == 'string') {
    path = path
      .replace(/\[["']?/g, '.')
      .replace(/["']?]/g, '.')
      .split('.');
  }

  return path.filter(item => item !== '');
}

export function objectGet(obj: Object, path: string | PropertyKey[], defaultValue?: any) {
  if (path === undefined || path === null) {
    return;
  }

  path = objectPathNormalize(path);

  return path.reduce((prev, item) => {
    if (!prev || !prev.hasOwnProperty(item)) {
      return defaultValue !== undefined ? defaultValue : EMPTY;
    }
    return prev[item];
  }, obj);
}

export function objectSet(obj: Object, path: string | PropertyKey[], value: any) {
  let instance = obj;

  path = objectPathNormalize(path);

  if (!path.length) {
    return (obj = value);
  }

  instance = path.slice(0, -1).reduce((prev, item) => {
    if (!prev) {
      return;
    }
    if (prev[item] == undefined) {
      prev[item] = {};
    }
    return prev[item];
  }, obj);

  return (instance[path[path.length - 1]] = value);
}

export function mapValuesDeep(obj, iteree) {
  if (isArray(obj)) {
    return obj.map(item => mapValuesDeep(item, iteree));
  } else if (isObject(obj)) {
    return mapValues(obj, v => mapValuesDeep(v, iteree));
  } else {
    return iteree(obj);
  }
}

export function limitObjectLength(obj: any, length: number, addTrimmedObject = false) {
  if (obj instanceof Blob) {
    return {
      type: obj.type,
      size: obj.size
    };
  }

  const iter = item => {
    if (isPlainObject(item)) {
      keys(item).forEach(key => {
        item[key] = iter(item[key]);
      });
      return item;
    } else if (isArray(item)) {
      let array = item as any[];
      const arraySize = array.length;
      const trim = arraySize > length;

      if (trim) {
        array = array.slice(0, length);
      }

      array = array.map(subItem => iter(subItem));

      if (trim && addTrimmedObject) {
        array.push(`{ ... Trimmed elements: ${arraySize - length} }`);
      }

      return array;
    } else {
      return item;
    }
  };

  return iter(cloneDeep(obj));
}

export function filterObject(obj: any, callbackfn: (obj: any) => boolean) {
  const skip = {};
  const iter = item => {
    if (!callbackfn(item)) {
      return skip;
    }

    if (isPlainObject(item)) {
      const acc = {};

      keys(item).forEach(key => {
        const result = iter(item[key]);

        if (result !== skip) {
          acc[key] = result;
        }
      });

      return acc;
    } else if (isArray(item)) {
      return item.reduce((acc, subItem) => {
        const result = iter(subItem);

        if (result !== skip) {
          acc.push(subItem);
        }

        return acc;
      }, []);
    } else {
      return item;
    }
  };

  const final = iter(cloneDeep(obj));

  if (final === skip) {
    return;
  }

  return final;
}

type compareType<T> = string | symbol | ((item: T) => any);

export function areObjectsEqual<T>(lhs: T, rhs: T, compare: compareType<T>[]) {
  const serialize = (obj: T) => {
    return compare.map(item => {
      if (isFunction(item)) {
        const func = item as (item: T) => boolean;
        return func(obj);
      } else {
        const key = item as string | symbol;
        return obj[key];
      }
    });
  };

  return isEqual(serialize(lhs), serialize(rhs));
}

export function strictObject(obj: Object, exc: (key: PropertyKey) => Error): Object {
  if (typeof Proxy === 'undefined') {
    return obj;
  }

  return new Proxy(obj, {
    get(target, property) {
      if (
        property == 'length' ||
        property == '_isBuffer' ||
        property == 'constructor' ||
        property == Symbol.toStringTag
      ) {
        return;
      } else if (property == 'hasOwnProperty') {
        return (v: PropertyKey) => objectGet(target, [v]) !== EMPTY;
      }

      const result = objectGet(target, [property]);

      if (result === EMPTY) {
        throw exc(property);
      }

      return result;
    }
  });
}

export function aggregateObjects(data: Object[]): Object {
  return data.reduce((acc, item) => {
    toPairs(item).forEach(([key, value]) => {
      if (!acc.hasOwnProperty(key)) {
        acc[key] = value;
      } else if (
        acc.hasOwnProperty(key) &&
        (acc[key] == null || acc[key] === undefined) &&
        !(value === null || value === undefined)
      ) {
        acc[key] = item[key];
      }
    });
    return acc;
  }, {});
}

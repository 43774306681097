<div class="lightbox">
  <img
    *ngIf="!video"
    class="lightbox__preview"
    [src]="url"
    [style.max-width.px]="1240"
    [style.max-height.px]="maxHeight"
    #preview
  />

  <video
    *ngIf="video"
    class="lightbox__preview"
    [src]="url"
    [style.max-width.px]="1240"
    [style.max-height.px]="maxHeight"
    autoplay
    loop
    muted
    #preview
  ></video>

  <div
    *ngIf="canZoom"
    class="lightbox__zoom-area"
    [style.opacity]="opacityZoom"
    (mouseenter)="showZoom()"
    (mouseleave)="hideZoom()"
    (mousemove)="mousemove($event)"
  >
    <img
      *ngIf="!video"
      [src]="url"
      class="lightbox__img"
      [ngStyle]="{ transform: 'translate(' + offsetX + 'px, ' + offsetY + 'px)' }"
    />

    <video
      *ngIf="video"
      [src]="url"
      class="lightbox__img"
      [ngStyle]="{ transform: 'translate(' + offsetX + 'px, ' + offsetY + 'px)' }"
      autoplay
      loop
      muted
    ></video>
  </div>
</div>

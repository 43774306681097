var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ParameterArray } from '@modules/fields';
var CustomPageParametersForm = /** @class */ (function (_super) {
    __extends(CustomPageParametersForm, _super);
    function CustomPageParametersForm() {
        return _super.call(this, {
            parameters: new ParameterArray([])
        }) || this;
    }
    CustomPageParametersForm.prototype.init = function (viewSettings) {
        this.viewSettings = viewSettings;
        if (viewSettings) {
            this.controls.parameters.patchValue(viewSettings.parameters);
        }
    };
    CustomPageParametersForm.prototype.submit = function () {
        var instance = cloneDeep(this.viewSettings);
        instance.parameters = this.controls.parameters.value;
        return instance;
    };
    return CustomPageParametersForm;
}(FormGroup));
export { CustomPageParametersForm };

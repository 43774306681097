import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ApiService } from '@modules/api';
import { Project } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-demo-project-toast',
  templateUrl: './demo-project-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoProjectToastComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() shift = false;

  demoUser = false;

  constructor(
    private currentUserStore: CurrentUserStore,
    private apiService: ApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUserStore.instance$.pipe(untilDestroyed(this)).subscribe(() => {
      this.demoUser = !!this.apiService.getProjectToken();
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}

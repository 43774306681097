import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';

import { CopyTextDirective } from '../../directives/copy-text/copy-text.directive';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeComponent implements OnInit, OnDestroy {
  @Input() language: string;
  @Input() copyEnabled = true;
  @ViewChild(CopyTextDirective) copyText: CopyTextDirective;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  copy() {
    this.copyText
      .copy()
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info('Copied', 'Code was copied to clipboard');
      });
  }
}

<form [formGroup]="customCodeForm" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Scripts and Styles'"
    [subtitle]="'App settings'"
    [backEnabled]="false"
  ></app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-section>
      <div class="sidebar__element">
        <app-sidebar-field [label]="'Add global JavaScript'">
          <app-auto-field
            [form]="customCodeForm"
            [label]="false"
            [field]="
              createField({
                name: 'append_scripts',
                field: 'CodeField',
                placeholder: 'document.createElement(\'script\');',
                params: {
                  language: 'javascript',
                  autogrow: true,
                  field: true,
                  field_contrast: true,
                  wrap: true,
                  classes: ['input_fill']
                }
              })
            "
          ></app-auto-field>

          <ng-container description>
            Load 3rd party libraries or run custom JavaScript for the current App.
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element">
        <app-sidebar-field [label]="'Add global CSS'">
          <app-auto-field
            [form]="customCodeForm"
            [label]="false"
            [field]="
              createField({
                name: 'append_styles',
                field: 'CodeField',
                placeholder: '.example { color: blue; }',
                params: {
                  language: 'css',
                  autogrow: true,
                  field: true,
                  field_contrast: true,
                  wrap: true,
                  classes: ['input_fill']
                }
              })
            "
          ></app-auto-field>

          <ng-container description>
            Overrides current App styles with custom CSS.
          </ng-container>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </div>

  <app-sidebar-submit
    *ngIf="customCodeForm.dirty"
    [saveDisabled]="customCodeForm.invalid || customCodeForm.disabled"
    [saveLoading]="loading"
    [errors]="customCodeForm | appFormErrors"
    [cancelEnabled]="false"
  >
  </app-sidebar-submit>
</form>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Point } from '@modules/views';
import { isSet } from '@shared';
var PointControl = /** @class */ (function (_super) {
    __extends(PointControl, _super);
    function PointControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            x: new FormControl(isSet(state.x) ? state.x : 0),
            y: new FormControl(isSet(state.y) ? state.y : 0)
        }) || this;
    }
    PointControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.x.patchValue(value.x, { emitEvent: options.emitEvent });
        this.controls.y.patchValue(value.y, { emitEvent: options.emitEvent });
    };
    PointControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Point();
        }
        instance.x = this.controls.x.value;
        instance.y = this.controls.y.value;
        return instance;
    };
    PointControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return PointControl;
}(FormGroup));
export { PointControl };

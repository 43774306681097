<div class="onboarding-popup-background" #background></div>
<div class="onboarding-popup-container">
  <div class="onboarding-popup" #root (click)="$event.stopPropagation()">
    <div class="onboarding-popup__image">
      <div class="onboarding-popup__image-inner"></div>
    </div>
    <div class="onboarding-popup__title">
      <strong>Congratulations!</strong><br />
      Glad to see you at Jet Admin
    </div>
    <div class="onboarding-popup__description">
      Start discovering operations you can do to tune Jet Admin for your needs by completing your first task.
    </div>
    <div class="onboarding-popup__button">
      <a href="javascript:void(0)" class="button button_primary button_fill" (click)="getStarted()">
        <span class="button__label">Start</span>
      </a>
    </div>
  </div>
</div>

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ElementItem, ElementType, FormElementItem, getTokenSubtitle, ITEM_OUTPUT, ListElementItem, ModelElementItem, ViewContext, ViewContextElementType } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { DisplayFieldType, FieldType, getFieldDescriptionByType, Input as FieldInput, InputValueType } from '@modules/fields';
import { SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescriptionStore } from '@modules/model-queries';
import { forceModelId, ModelDescription } from '@modules/models';
import { isSet } from '@shared';
var BindComponentComponent = /** @class */ (function () {
    function BindComponentComponent(modelDescriptionStore, analyticsService, cd) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.inputs = [];
        this.selfColumns = [];
        this.targetBindField = true;
        this.targetBindPrimaryKey = true;
        this.selfBindPrimaryKey = true;
        this.inline = false;
        this.updateInputs = new EventEmitter();
        this.inputs$ = new BehaviorSubject([]);
        this.selfModelDescription$ = new BehaviorSubject(undefined);
        this.selfColumns$ = new BehaviorSubject([]);
        this.opened = false;
        this.hasInputs = false;
        this.sections = [];
        this.items$ = new BehaviorSubject([]);
        this.dataSourceTypes = DataSourceType;
    }
    BindComponentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initItems();
        this.items$.pipe(untilDestroyed(this)).subscribe(function (items) {
            _this.sections = _this.groupItems(items);
            _this.cd.markForCheck();
        });
        combineLatest(this.items$, this.inputs$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var items = _a[0], queryInputs = _a[1];
            _this.hasInputs = queryInputs.length > 0;
            _this.boundItem = items.find(function (componentItem) {
                var selfParameters = componentItem.selfParameter
                    ? [componentItem.selfParameter]
                    : componentItem.selfParameters.map(function (i) { return i.name; });
                return selfParameters
                    .map(function (selfParameter) {
                    return isSet(selfParameter) ? queryInputs.find(function (i) { return i.name == selfParameter; }) : undefined;
                })
                    .filter(function (queryInput) { return queryInput && queryInput.valueType == InputValueType.Context; })
                    .some(function (queryInput) {
                    if (componentItem.targetContextValue) {
                        return isEqual(componentItem.targetContextValue, queryInput.contextValue);
                    }
                    else if (componentItem.targetContextValues) {
                        return componentItem.targetContextValues.some(function (item) {
                            return isEqual(item.targetContextValue, queryInput.contextValue);
                        });
                    }
                    else {
                        return false;
                    }
                });
            });
            _this.cd.markForCheck();
        });
    };
    BindComponentComponent.prototype.ngOnDestroy = function () { };
    BindComponentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.inputs) {
            this.inputs$.next(this.inputs);
        }
        if (changes.selfModelDescription) {
            this.selfModelDescription$.next(this.selfModelDescription);
        }
        if (changes.selfColumns) {
            this.selfColumns$.next(this.selfColumns);
        }
    };
    BindComponentComponent.prototype.initItems = function () {
        var _this = this;
        combineLatest(this.context.elements$, this.selfModelDescription$, this.selfColumns$, this.modelDescriptionStore.get())
            .pipe(map(function (_a) {
            var elements = _a[0], selfModelDescription = _a[1], selfColumns = _a[2], modelDescriptions = _a[3];
            var mapElement = function (contextItem) {
                var selfParameter;
                var selfParameters = [];
                var targetDataSource;
                var targetElementPath;
                var targetGetContextValue;
                var targetContextValue;
                var targetContextValues = [];
                if (contextItem.element.element instanceof ListElementItem) {
                    var layoutIndex = 0;
                    var settings = contextItem.element.element.layouts[layoutIndex];
                    if (settings) {
                        targetDataSource = settings.dataSource;
                        targetElementPath = ['elements', contextItem.element.uniqueName, layoutIndex.toString()];
                        targetGetContextValue = function (field) { return targetElementPath.concat([SELECTED_ITEM_OUTPUT, field]); };
                    }
                }
                else if (contextItem.element.element instanceof FormElementItem) {
                    targetDataSource = contextItem.element.element.getDataSource;
                    targetElementPath = ['elements', contextItem.element.uniqueName];
                    targetGetContextValue = function (field) { return targetElementPath.concat([ITEM_OUTPUT, field]); };
                }
                else if (contextItem.element.element instanceof ModelElementItem) {
                    targetDataSource = contextItem.element.element.dataSource;
                    targetElementPath = ['elements', contextItem.element.uniqueName];
                    targetGetContextValue = function (field) { return targetElementPath.concat([ITEM_OUTPUT, field]); };
                }
                if (!_this.targetBindField) {
                    selfParameter = 'value';
                    targetContextValue = targetElementPath;
                }
                else if (targetDataSource && targetDataSource.query && targetDataSource.query.isConfigured()) {
                    var targetResource_1 = targetDataSource.queryResource;
                    var targetModel = targetDataSource.query && targetDataSource.query.simpleQuery
                        ? targetDataSource.query.simpleQuery.model
                        : undefined;
                    var targetColumns = targetDataSource.columns || [];
                    var targetModelId_1 = isSet(targetResource_1) && isSet(targetModel) ? [targetResource_1, targetModel].join('.') : undefined;
                    var targetModelDescription = isSet(targetModelId_1)
                        ? modelDescriptions.find(function (item) { return item.isSame(targetModelId_1); })
                        : undefined;
                    if (selfModelDescription &&
                        targetModelDescription &&
                        targetModelDescription.isSame(selfModelDescription)) {
                        selfParameter = selfModelDescription.primaryKeyField;
                        targetContextValue = targetGetContextValue(targetModelDescription.primaryKeyField);
                    }
                    else {
                        if (_this.selfBindField) {
                            selfParameter = _this.selfBindField;
                        }
                        else if (_this.selfBindPrimaryKey && selfModelDescription && isSet(selfModelDescription)) {
                            selfParameter = selfModelDescription.primaryKeyField;
                        }
                        else {
                            var relatedField = isSet(targetModelId_1)
                                ? selfColumns
                                    .filter(function (item) { return !item.flex; })
                                    .filter(function (item) { return item.field == FieldType.RelatedModel; })
                                    .find(function (item) {
                                    var modelId = item.params['related_model'] && selfModelDescription
                                        ? forceModelId(item.params['related_model']['model'], selfModelDescription.resource)
                                        : undefined;
                                    return modelId == targetModelId_1;
                                })
                                : undefined;
                            if (relatedField) {
                                selfParameter = relatedField.name;
                            }
                            else {
                                selfParameters = selfColumns
                                    .filter(function (item) { return !item.flex; })
                                    .map(function (item) {
                                    var fieldDescription = getFieldDescriptionByType(item.field);
                                    return {
                                        name: item.verboseName || item.name,
                                        icon: fieldDescription ? fieldDescription.icon : undefined,
                                        selfParameter: item.name
                                    };
                                });
                            }
                        }
                        if (_this.targetBindPrimaryKey &&
                            targetModelDescription &&
                            isSet(targetModelDescription.primaryKeyField)) {
                            targetContextValue = targetGetContextValue(targetModelDescription.primaryKeyField);
                        }
                        else {
                            var relatedField = selfModelDescription
                                ? targetColumns
                                    .filter(function (item) { return item.type != DisplayFieldType.Computed; })
                                    .filter(function (item) { return item.field == FieldType.RelatedModel; })
                                    .find(function (item) {
                                    var modelId = item.params['related_model']
                                        ? forceModelId(item.params['related_model']['model'], targetResource_1)
                                        : undefined;
                                    return modelId == selfModelDescription.modelId;
                                })
                                : undefined;
                            if (relatedField) {
                                targetContextValue = targetGetContextValue(relatedField.name);
                            }
                            else {
                                targetContextValues = targetColumns
                                    .filter(function (item) { return item.type != DisplayFieldType.Computed; })
                                    .map(function (item) {
                                    var fieldDescription = getFieldDescriptionByType(item.field);
                                    return {
                                        name: item.verboseName || item.name,
                                        icon: fieldDescription ? fieldDescription.icon : undefined,
                                        targetContextValue: targetGetContextValue(item.name)
                                    };
                                });
                            }
                        }
                    }
                }
                if (!isSet(selfParameter) &&
                    !selfParameters.length &&
                    !isSet(targetContextValue) &&
                    !targetContextValues.length) {
                    return;
                }
                return {
                    name: contextItem.element.name,
                    contextElement: contextItem.element,
                    icon: contextItem.element.icon,
                    selfParameter: selfParameter,
                    selfParameters: selfParameters,
                    targetContextValue: targetContextValue,
                    targetContextValues: targetContextValues
                };
            };
            return elements
                .filter(function (item) { return item.element.type == ViewContextElementType.Element && !item.parent; })
                .filter(function (item) { return !_this.element || item.element.uniqueName != _this.element.uid; })
                .filter(function (item) {
                if (_this.targetElementType) {
                    return item.element.element && item.element.element.type == _this.targetElementType;
                }
                else {
                    return true;
                }
            })
                .map(function (item) { return mapElement(item); })
                .filter(function (item) { return item; });
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.items$.next(result);
            _this.cd.markForCheck();
        });
    };
    BindComponentComponent.prototype.groupItems = function (items) {
        return toPairs(items.reduce(function (acc, item) {
            var key = getTokenSubtitle(item.contextElement) || '';
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {})).map(function (_a) {
            var name = _a[0], children = _a[1];
            return {
                name: name,
                children: children
            };
        });
    };
    BindComponentComponent.prototype.bindComponent = function (selfParameter, targetContextValue, componentItem) {
        var input = new FieldInput();
        input.name = selfParameter;
        input.valueType = InputValueType.Context;
        input.contextValue = targetContextValue;
        var newValue = [input].concat(this.inputs.filter(function (item) { return item.name != selfParameter; }));
        this.updateInputs.emit(newValue);
        if (componentItem) {
            var element = componentItem.contextElement.element;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindSuccessfullySetUp, {
                WasBound: !!this.boundItem,
                ComponentType: element ? element.analyticsGenericName : undefined,
                Source: this.analyticsSource
            });
        }
    };
    BindComponentComponent.prototype.setOpened = function (value) {
        this.opened = value;
        this.cd.markForCheck();
    };
    BindComponentComponent.prototype.setSelectedItem = function (value) {
        this.selectedItem = value;
        this.cd.markForCheck();
        if (value) {
            var element = value.contextElement.element;
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindChooseField, {
                WasBound: !!this.boundItem,
                ComponentType: element ? element.analyticsGenericName : undefined,
                Source: this.analyticsSource
            });
        }
    };
    BindComponentComponent.prototype.toggleOpened = function () {
        this.setOpened(!this.opened);
    };
    BindComponentComponent.prototype.onOpen = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindStarted, {
            WasBound: !!this.boundItem,
            Source: this.analyticsSource
        });
    };
    BindComponentComponent.prototype.onCancel = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindCancelled, {
            WasBound: !!this.boundItem,
            Source: this.analyticsSource
        });
    };
    return BindComponentComponent;
}());
export { BindComponentComponent };

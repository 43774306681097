import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnInit,
  QueryList
} from '@angular/core';

import { SectionTabComponent } from '../section-tab/section-tab.component';

@Component({
  selector: 'app-section-tabs',
  templateUrl: './section-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTabsComponent implements OnInit, AfterContentChecked {
  @ContentChildren(SectionTabComponent) tabComponents = new QueryList<SectionTabComponent>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterContentChecked(): void {
    this.cd.markForCheck();
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';
import { FeedItem } from '../../data/feed-item';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/custom-http-parameter-codec/custom-http-parameter-codec";
var FeedItemService = /** @class */ (function () {
    function FeedItemService(http, apiService, parameterCodec) {
        this.http = http;
        this.apiService = apiService;
        this.parameterCodec = parameterCodec;
    }
    FeedItemService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'feed_items/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params, encoder: _this.parameterCodec });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new FeedItemService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FeedItemService.prototype.setMarkRead = function (projectName, environmentName, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'feed_items/mark_read/');
            var headers = new HttpHeaders();
            var data = {
                date_add_lte: moment().format()
            };
            if (params) {
                if (params['user_activity_object_type']) {
                    data['user_activity_object_type'] = params['user_activity_object_type'];
                }
                if (params['user_activity_object_id']) {
                    data['user_activity_object_id'] = params['user_activity_object_id'];
                }
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    FeedItemService.ngInjectableDef = i0.defineInjectable({ factory: function FeedItemService_Factory() { return new FeedItemService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CustomHttpParameterCodec)); }, token: FeedItemService, providedIn: "root" });
    return FeedItemService;
}());
export { FeedItemService };
(function (FeedItemService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
            this.perPage = 20;
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new FeedItem().deserialize(item); });
            this.hasMore = !!data['next'];
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        Object.defineProperty(GetResponse.prototype, "numPages", {
            get: function () {
                return Math.ceil(this.count / this.perPage);
            },
            enumerable: true,
            configurable: true
        });
        return GetResponse;
    }());
    FeedItemService.GetResponse = GetResponse;
})(FeedItemService || (FeedItemService = {}));

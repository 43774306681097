import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsModule } from '@modules/analytics';
import { RoutingModule } from '@modules/routing';
import { UsersModule } from '@modules/users';
import { UiModule } from '@ui';

import { FeatureOverviewLineComponent } from './components/feature-overview-line/feature-overview-line.component';
import { FeatureOverviewPopupComponent } from './components/feature-overview-popup/feature-overview-popup.component';
import { FeatureOverviewComponent } from './components/feature-overview/feature-overview.component';
import { FeatureActivePipe } from './pipes/feature-active/feature-active.pipe';

@NgModule({
  imports: [CommonModule, RoutingModule, UsersModule, AnalyticsModule, UiModule],
  declarations: [
    FeatureOverviewComponent,
    FeatureOverviewLineComponent,
    FeatureOverviewPopupComponent,
    FeatureActivePipe
  ],
  exports: [FeatureOverviewComponent, FeatureOverviewLineComponent, FeatureOverviewPopupComponent, FeatureActivePipe],
  entryComponents: [FeatureOverviewPopupComponent]
})
export class FeaturesModule {}

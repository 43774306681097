import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, of } from 'rxjs';
import { filter, map, pairwise, switchMap } from 'rxjs/operators';
import { DropListService } from '@common/drag-drop2';
import { findLayer, isContainerLayer, Layer } from '@modules/views';
import { isSet, KeyboardEventKeyCode } from '@shared';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
var ViewEditorNavigatorItemComponent = /** @class */ (function () {
    function ViewEditorNavigatorItemComponent(editorContext, dropListService, cd) {
        this.editorContext = editorContext;
        this.dropListService = dropListService;
        this.cd = cd;
        this.flexLayout = false;
        this.indent = 0;
        this.search = '';
        this.itemCustomize = new EventEmitter();
        this.itemAddCustomizing = new EventEmitter();
        this.itemRemoveCustomizing = new EventEmitter();
        this.visibleUpdated = new EventEmitter();
        this.indentRange = [];
        this.nameEditing = false;
        this.transferring = false;
        this.layersOpened = false;
    }
    ViewEditorNavigatorItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
        this.dropListService.draggingDragStart$.pipe(untilDestroyed(this)).subscribe(function (dragStart) {
            if (dragStart) {
                var dragLayer_1 = dragStart.source.data;
                var customizingLayers = _this.editorContext.customizingLayers$.value;
                var transferLayers = customizingLayers.length > 1 && customizingLayers.find(function (item) { return item.isSame(dragLayer_1); })
                    ? customizingLayers
                    : [dragLayer_1];
                _this.transferring = transferLayers.some(function (item) { return item.isSame(_this.layer); });
                _this.cd.markForCheck();
            }
            else {
                _this.transferring = false;
                _this.cd.markForCheck();
            }
        });
        this.editorContext
            .layerChanged$()
            .pipe(filter(function (event) { return event.layer.isSame(_this.layer); }), untilDestroyed(this))
            .subscribe(function () { return _this.cd.markForCheck(); });
        if (isContainerLayer(this.layer)) {
            var container_1 = this.layer.layers;
            merge(of(container_1), this.editorContext.layerContainerChanged$().pipe(filter(function (event) { return event.container === container_1; }), map(function (event) { return event.container; })))
                .pipe(switchMap(function (layers) {
                return _this.editorContext.customizingLayers$.pipe(map(function (customizingLayers) {
                    return layers.filter(function (layer) { return customizingLayers.find(function (item) { return item.isSame(layer); }); });
                }), pairwise(), filter(function (_a) {
                    var prev = _a[0], current = _a[1];
                    return current.some(function (currentItem) { return !prev.find(function (prevItem) { return prevItem.isSame(currentItem); }); });
                }));
            }), untilDestroyed(this))
                .subscribe(function () {
                if (!_this.layersOpened) {
                    _this.layersOpened = true;
                    _this.cd.markForCheck();
                }
            });
            this.editorContext.customizingLayers$
                .pipe(filter(function (layers) { return layers.length && !_this.layersOpened; }), untilDestroyed(this))
                .subscribe(function (layers) {
                if (findLayer(container_1, function (layer) { return layers.some(function (item) { return item.isSame(layer); }); })) {
                    _this.setOpened(true);
                }
            });
        }
    };
    ViewEditorNavigatorItemComponent.prototype.ngOnDestroy = function () {
        this.editorContext.removeHoverLayer(this.layer, this);
    };
    ViewEditorNavigatorItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.layer) {
            this.containerLayer = this.layer && isContainerLayer(this.layer) ? this.layer : undefined;
            if (changes.layer.firstChange) {
                this.layersOpened = this.containerLayer ? this.containerLayer.layersOpened : false;
            }
        }
        if (changes.indent) {
            this.indentRange = range(this.indent);
        }
    };
    ViewEditorNavigatorItemComponent.prototype.startEditing = function () {
        var _this = this;
        this.nameValue = this.layer.name;
        this.nameEditing = true;
        this.cd.detectChanges();
        this.inputElement.nativeElement.focus();
        setTimeout(function () { return _this.inputElement.nativeElement.select(); }, 0);
    };
    ViewEditorNavigatorItemComponent.prototype.finishEditing = function (save) {
        if (save === void 0) { save = true; }
        this.nameEditing = false;
        this.cd.markForCheck();
        if (save && isSet(this.nameValue)) {
            this.layer.name = this.nameValue;
            this.cd.markForCheck();
            this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Navigator);
        }
        this.nameValue = undefined;
    };
    ViewEditorNavigatorItemComponent.prototype.onClick = function (e) {
        if (e.metaKey || e.ctrlKey) {
            if (this.editorContext.isCustomizingLayer(this.layer) && this.editorContext.isCustomizingMultipleLayers()) {
                this.itemRemoveCustomizing.emit();
            }
            else {
                this.itemAddCustomizing.emit({ shift: false });
            }
        }
        else if (e.shiftKey) {
            this.itemAddCustomizing.emit({ shift: e.shiftKey });
        }
        else {
            this.itemCustomize.emit();
        }
    };
    ViewEditorNavigatorItemComponent.prototype.onMouseEnter = function () {
        if (this.dropListService.dragging$.value) {
            return;
        }
        this.editorContext.addHoverLayer(this.layer, this);
    };
    ViewEditorNavigatorItemComponent.prototype.onMouseLeave = function () {
        this.editorContext.removeHoverLayer(this.layer, this);
    };
    ViewEditorNavigatorItemComponent.prototype.onKeyUp = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            this.finishEditing();
        }
        else if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.finishEditing(false);
        }
    };
    ViewEditorNavigatorItemComponent.prototype.toggleVisible = function () {
        this.layer.visible = !this.layer.visible;
        this.cd.markForCheck();
        this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Navigator);
        this.visibleUpdated.emit();
    };
    ViewEditorNavigatorItemComponent.prototype.setOpened = function (value) {
        this.layersOpened = value;
        this.cd.markForCheck();
        if (this.containerLayer) {
            this.containerLayer.layersOpened = value;
        }
    };
    ViewEditorNavigatorItemComponent.prototype.toggleOpened = function () {
        this.setOpened(!this.layersOpened);
    };
    return ViewEditorNavigatorItemComponent;
}());
export { ViewEditorNavigatorItemComponent };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { View, ViewMapping } from '@modules/views';
import { controlValue, EMPTY, isSet } from '@shared';

import { CustomViewMapParametersForm, CustomViewMapParametersSourceControl } from './custom-view-map-parameters.form';

@Component({
  selector: 'app-custom-view-map-parameters',
  templateUrl: './custom-view-map-parameters.component.html',
  providers: [CustomViewMapParametersForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewMapParametersComponent implements OnInit, OnDestroy {
  @Input() sourceParameters: ParameterField[] = [];
  @Input() view: View;
  @Input() mappings: ViewMapping[];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Output() selectMapping = new EventEmitter<ViewMapping[]>();
  @Output() cancelled = new EventEmitter<void>();

  viewScale = 1;
  controlSourceParameterInputEnabled: CustomViewMapParametersSourceControl;
  testParameters = {};
  testSetParameters = {};

  constructor(
    public form: CustomViewMapParametersForm,
    @Optional() public popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init({
      sourceParameters: this.sourceParameters,
      view: this.view,
      mappings: this.mappings
    });

    const frame = this.view.frame;
    const maxWidth = 400;

    this.viewScale = frame.width > maxWidth ? maxWidth / frame.width : undefined;
    this.cd.markForCheck();

    if (this.contextElement) {
      const sourceParameterValues = fromPairs(
        this.sourceParameters.map(item => {
          const value = this.contextElement.getFieldValue(item.name);
          return [item.name, value];
        })
      );

      combineLatest(
        this.form.controls.map(control => {
          return controlValue(control).pipe(
            switchMap(() => {
              if (control.controls.source_parameter_input_enabled.value) {
                const input = control.getSourceParameterInput();
                return applyParamInput$(input, {
                  context: this.context,
                  contextElement: this.contextElement,
                  localContext: {
                    [ITEM_OUTPUT]: sourceParameterValues
                  }
                }).pipe(map(value => (value !== EMPTY ? value : undefined)));
              } else {
                const sourceParameter = control.controls.source_parameter.value;
                const value = sourceParameter !== EMPTY ? sourceParameterValues[sourceParameter] : EMPTY;
                return of(value);
              }
            }),
            map(testValue => {
              return [control.target.name, testValue];
            })
          );
        })
      )
        .pipe(untilDestroyed(this))
        .subscribe(testValues => {
          const testValuesClean = fromPairs(
            testValues.filter(([k, v]) => isSet(v)).map(([k, v]) => [k, v !== EMPTY ? v : undefined])
          );

          this.testParameters = { ...this.view.testParameters, ...testValuesClean };
          this.testSetParameters = { ...testValuesClean };
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  toggleSourceParameterInput(control: CustomViewMapParametersSourceControl) {
    control.toggleSourceParameterInput();

    if (control.controls.source_parameter_input_enabled.value) {
      this.controlSourceParameterInputEnabled = control;
    }
  }

  submit() {
    const result = this.form.submit();

    this.selectMapping.emit(result);
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }
}

import { Injectable } from '@angular/core';
import assign from 'lodash/assign';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';

import { ApiService } from '@modules/api';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { FieldType, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import {
  BUILT_IN_PARAMS,
  CURSOR_NEXT_PARAM,
  CURSOR_PREV_PARAM,
  Model,
  ORDER_BY_PARAM,
  PAGE_PARAM,
  PER_PAGE_PARAM,
  SEARCH_PARAM
} from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { HttpQueryService, QueryPagination, QueryType } from '@modules/queries';
import { ActionExecuteParams } from '@modules/resources/data/resource-controller';
import { CurrentUserStore } from '@modules/users';
import { generateUUID, isSet } from '@shared';

import { strictParamsTokens } from '../../utils/common';

@Injectable()
export class QueryTokensService {
  constructor(
    private currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private httpQueryService: HttpQueryService,
    private apiService: ApiService,
    private projectApiService: ProjectApiService
  ) {}

  mergeTokens(...tokens: Object[]) {
    return assign({}, ...tokens);
  }

  generalTokens(): Object {
    const tokens = {
      jet: {
        uuid: generateUUID()
      }
    };
    const user = this.currentUserStore.instance;
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;

    // TODO: Remove deprecated tokens
    tokens['user_uid'] = user ? user.uid : undefined;
    tokens['user_email'] = user ? user.email : undefined;
    tokens['user_token'] = this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined;
    tokens['user_properties'] = environment && environment.user ? environment.user.properties : {};
    tokens['user_group_uid'] = environment && environment.group ? environment.group.uid : undefined;
    tokens['user_group_properties'] = environment && environment.group ? environment.group.properties : {};

    tokens['user'] = {
      uid: environment && environment.user ? environment.user.uid : undefined,
      email: user ? user.email : undefined,
      token: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
      project_token: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
      properties: environment && environment.user ? environment.user.properties : {},
      group: {
        uid: environment && environment.group ? environment.group.uid : undefined,
        properties: environment && environment.group ? environment.group.properties : {}
      }
    };

    tokens['project'] = {
      unique_name: project ? project.uniqueName : undefined
    };

    tokens['env'] = {
      unique_name: environment ? environment.uniqueName : undefined
    };

    return tokens;
  }

  modelGetTokens(params: Object, parameters: ParameterField[], userQuery: boolean): Object {
    const tokens = {};

    const filters = {};

    if (params) {
      toPairs(params).forEach(([key, value]) => {
        if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
          return;
        }

        filters[key] = value;
      });
    }

    tokens['params'] = this.serializeParams(parameters, filters);
    tokens['search'] = params[SEARCH_PARAM];

    // if (userQuery) {
    //   tokens['params'] = strictParamsTokens(tokens['params']);
    // }

    if (params[ORDER_BY_PARAM]) {
      const ascending = params[ORDER_BY_PARAM][0] != '-';
      const field = ascending ? params[ORDER_BY_PARAM] : params[ORDER_BY_PARAM].substring(1);

      // TODO: Remove deprecated tokens
      tokens['sorting_field'] = field;
      tokens['sorting_asc'] = ascending;
      tokens['sorting_desc'] = !ascending;

      tokens['sorting'] = {
        field: field,
        asc: ascending,
        desc: !ascending
      };
    } else {
      // TODO: Remove deprecated tokens
      tokens['sorting_field'] = undefined;
      tokens['sorting_asc'] = undefined;
      tokens['sorting_desc'] = undefined;

      tokens['sorting'] = {
        field: undefined,
        asc: undefined,
        desc: undefined
      };
    }

    return tokens;
  }

  modelGetDetailTokens(params: Object, parameters: ParameterField[], userQuery: boolean): Object {
    const tokens = {};
    const inputs = {};

    if (params) {
      toPairs(params).forEach(([key, value]) => {
        if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
          return;
        }

        inputs[key] = value;
      });
    }

    tokens['params'] = this.serializeParams(parameters, inputs);

    // if (userQuery) {
    //   tokens['params'] = strictParamsTokens(tokens['params']);
    // }

    return tokens;
  }

  parametersMeta(parameters?: ParameterField[]) {
    if (!parameters) {
      return {};
    }

    return fromPairs(parameters.map(item => [item.name, item.serialize()]));
  }

  modelCreateTokens(data: Object, parameters?: ParameterField[]): Object {
    const tokens = {
      params: data,
      fields: parameters ? parameters.map(item => item.name) : undefined,
      fieldsMeta: this.parametersMeta(parameters)
    };

    // TODO: Remove deprecated tokens
    tokens['record'] = data;

    return tokens;
  }

  modelUpdateTokens(data: Object, parameters?: ParameterField[], primaryKeyValue?: any, fields?: string[]): Object {
    const tokens = {
      params: data,
      fields: parameters ? parameters.map(item => item.name) : undefined,
      fieldsMeta: this.parametersMeta(parameters),
      onlyFields: fields
    };

    // TODO: Remove deprecated tokens
    tokens['PK'] = primaryKeyValue;
    tokens['record'] = data;

    return tokens;
  }

  modelDeleteTokens(data: Object, primaryKeyValue?: any): Object {
    const tokens = {
      params: data
    };

    // TODO: Remove deprecated tokens
    tokens['PK'] = primaryKeyValue;

    return tokens;
  }

  modelAggregateTokens(
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[],
    params: Object,
    userQuery: boolean
  ): Object {
    const tokens = {};
    const filters = {};

    if (params) {
      toPairs(params).forEach(([key, value]) => {
        if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
          return;
        }

        filters[key] = value;
      });
    }

    tokens['params'] = this.serializeParams(parameters, filters);

    // if (userQuery) {
    //   tokens['params'] = strictParamsTokens(tokens['params']);
    // }

    tokens['y_func'] = yFunc;
    tokens['y_column'] = yColumn;

    return tokens;
  }

  modelGroupTokens(
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[],
    params: Object,
    userQuery: boolean
  ): Object {
    const tokens = {};
    const filters = {};

    if (params) {
      toPairs(params).forEach(([key, value]) => {
        if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
          return;
        }

        filters[key] = value;
      });
    }

    tokens['params'] = this.serializeParams(parameters, filters);

    // if (userQuery) {
    //   tokens['params'] = strictParamsTokens(tokens['params']);
    // }

    const indexName = (name: string, i: number): string => {
      if (i == 0) {
        return name;
      } else {
        return `${name}_${i + 1}`;
      }
    };

    xColumns.forEach((item, i) => {
      tokens[indexName('x_column', i)] = item.xColumn;
      tokens[indexName('x_lookup', i)] = item.xLookup;
    });

    tokens['y_func'] = yFunc;
    tokens['y_column'] = yColumn;

    return tokens;
  }

  paginationTokens(pagination: QueryPagination, params: Object): Object {
    const tokens = {};

    if (pagination == QueryPagination.Page || pagination == QueryPagination.Offset) {
      const page = params[PAGE_PARAM] || 1;
      const perPage = params[PER_PAGE_PARAM] || 20;

      // TODO: Remove deprecated tokens
      tokens['page'] = page;
      tokens['offset'] = (page - 1) * perPage;
      tokens['limit'] = perPage;

      tokens['paging'] = {
        page: page,
        offset: (page - 1) * perPage,
        limit: perPage
      };
    } else if (pagination == QueryPagination.Cursor) {
      const perPage = params[PER_PAGE_PARAM] || 20;

      // TODO: Remove deprecated tokens
      tokens['cursor_first'] = params[CURSOR_PREV_PARAM];
      tokens['cursor_last'] = params[CURSOR_NEXT_PARAM];
      tokens['limit'] = perPage;

      tokens['paging'] = {
        cursor_prev: params[CURSOR_PREV_PARAM],
        cursor_next: params[CURSOR_NEXT_PARAM],
        limit: perPage
      };
    }

    return tokens;
  }

  uploadFileTokens(file: File, path: string, fileName?: string) {
    return {
      file: file,
      file_name: isSet(fileName) ? fileName : file.name,
      file_mime_type: file.type,
      path: path
    };
  }

  storageGetObjectsTokens(path: string) {
    return {
      path: path
    };
  }

  storageDeleteObjectTokens(path: string) {
    return {
      path: path
    };
  }

  storageCreateFolderTokens(path: string) {
    return {
      path: path
    };
  }

  storageGetObjectUrlTokens(path: string, expiresInSec?: number) {
    return {
      path: path,
      expires: expiresInSec
    };
  }

  actionExecuteTokens(params: ActionExecuteParams, parameters: ParameterField[], userQuery: boolean): Object {
    const tokens = {};

    if (params) {
      tokens['model'] = params.model ? params.model : undefined;
      tokens['id'] = params.id ? params.id : undefined;
      tokens['ids'] = params.ids ? params.ids : undefined;
      tokens['inverseIds'] = params.inverseIds ? params.inverseIds : undefined;
      tokens['params'] = this.serializeParams(parameters, params.actionParams);
      // tokens['data'] = params.actionParams ? params.actionParams : {}; // Backward compatibility
      tokens['record'] = params.modelInstance ? params.modelInstance.serialize() : {};

      // if (userQuery) {
      //   tokens['params'] = strictParamsTokens(tokens['params']);
      // }
    }

    return tokens;
  }

  typeHasArrayValue(parameter: ParameterField): boolean {
    if (parameter.field == FieldType.JSON) {
      return true;
    } else if (parameter.field == FieldType.MultipleSelect) {
      return true;
    } else if (parameter.field == FieldType.Location) {
      return true;
    } else {
      return false;
    }
  }

  serializeParams(parameters: ParameterField[], params: Object) {
    return {
      ...params,
      ...parameters.reduce((acc, parameter) => {
        let value = params ? params[parameter.name] : undefined;

        if (isSet(value, true)) {
          const fieldDescription = getFieldDescriptionByType(parameter.field);
          if (fieldDescription.serializeValue) {
            // TODO: Add fields isArray parameter
            if (isArray(value) && !this.typeHasArrayValue(parameter)) {
              value = value.map(item => fieldDescription.serializeValue(item, parameter));
            } else {
              value = fieldDescription.serializeValue(value, parameter);
            }
          }
        }

        acc[parameter.name] = value;
        return acc;
      }, {})
    };
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DragDrop2Module } from '@common/drag-drop2';
import { ResizableModule } from '@common/resizable';
import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { AutoElementBorderComponent } from './components/auto-element-border/auto-element-border.component';
import { AutoElementToolbarComponent } from './components/auto-element-toolbar/auto-element-toolbar.component';
import { ChoosePageDropdownComponent } from './components/choose-page-dropdown/choose-page-dropdown.component';
import { ElementWrapperComponent } from './components/element-wrapper/element-wrapper.component';
import { WorkflowButtonComponent } from './components/workflow-item-edit/workflow-button.component';

@NgModule({
  imports: [
    CommonModule,
    ResizableModule,
    TipsModule,
    OverlayModule,
    ReactiveFormsModule,
    SharedModule,
    DragDrop2Module
  ],
  declarations: [
    AutoElementBorderComponent,
    AutoElementToolbarComponent,
    ChoosePageDropdownComponent,
    ElementWrapperComponent,
    WorkflowButtonComponent
  ],
  exports: [
    AutoElementBorderComponent,
    AutoElementToolbarComponent,
    ChoosePageDropdownComponent,
    ElementWrapperComponent,
    WorkflowButtonComponent
  ]
})
export class CustomizeUiModule {}

import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { FilterItem2, Sort } from '@modules/filters';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CURSOR_NEXT_PARAM, CURSOR_PREV_PARAM, Model, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import {
  applyQueryOptionsFilterInputs,
  GetQueryOptions,
  ModelResponse,
  ResourceControllerService
} from '@modules/resources';
import { isSet, ListStore, ListStoreFetchResponse } from '@shared';

@Injectable()
export class ModelListStore<T = Model> extends ListStore<T> {
  dataSource: ListModelDescriptionDataSource;
  useDataSourceColumns = false;
  staticData = [];
  params = {};
  queryOptions: {
    filters?: FilterItem2[];
    params?: Object;
    search?: string;
    sort?: Sort[];
  };
  context: ViewContext;
  contextElement: ViewContextElement;
  localContext: Object;

  constructor(
    protected service: ModelService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected modelDescriptionStore: ModelDescriptionStore,
    protected modelDescriptionDataSourceService: ModelDescriptionDataSourceService,
    protected resourceControllerService: ResourceControllerService
  ) {
    super();
  }

  deserializeModelAttributes() {
    if (!this.items) {
      return;
    }

    this.items = this.items.map(item => {
      if (item instanceof Model) {
        item.deserializeAttributes(this.dataSource.columns);
      }
      return item;
    });
  }

  fetchModelPage(page: number, next: boolean): Observable<ListStoreFetchResponse<Model>> {
    let obs$: Observable<ModelResponse.GetResponse>;

    if (this.queryOptions) {
      let queryOptions: GetQueryOptions = {};

      queryOptions.paging = queryOptions.paging || {};
      queryOptions.paging.page = page;

      if (this.perPage) {
        queryOptions.paging.limit = this.perPage;
      }

      if (next) {
        const cursorNext = this.nextPageCursors[page - 1];

        if (isSet(cursorNext)) {
          queryOptions.paging.cursorNext = cursorNext;
        }
      } else {
        const cursorPrev = this.prevPageCursors[page + 1];

        if (isSet(cursorPrev)) {
          queryOptions.paging.cursorPrev = cursorPrev;
        }
      }

      queryOptions.filters = this.queryOptions.filters;
      queryOptions.params = this.queryOptions.params;
      queryOptions.search = this.queryOptions.search;
      queryOptions.sort = this.queryOptions.sort;
      queryOptions.columns = this.dataSource ? this.dataSource.columns : undefined;

      queryOptions = applyQueryOptionsFilterInputs(this.dataSource, queryOptions);

      obs$ = this.modelDescriptionDataSourceService.getAdv({
        project: this.currentProjectStore.instance,
        environment: this.currentEnvironmentStore.instance,
        dataSource: this.dataSource,
        queryOptions: queryOptions,
        staticData: this.staticData,
        context: this.context,
        contextElement: this.contextElement,
        localContext: this.localContext
      });
    } else {
      const params = this.params ? clone(this.params) : {};

      params[PAGE_PARAM] = page;

      if (this.perPage) {
        params[PER_PAGE_PARAM] = this.perPage;
      }

      if (next) {
        const cursorNext = this.nextPageCursors[page - 1];

        if (isSet(cursorNext)) {
          params[CURSOR_NEXT_PARAM] = cursorNext;
        }
      } else {
        const cursorPrev = this.prevPageCursors[page + 1];

        if (isSet(cursorPrev)) {
          params[CURSOR_PREV_PARAM] = cursorPrev;
        }
      }

      obs$ = this.modelDescriptionDataSourceService.get({
        project: this.currentProjectStore.instance,
        environment: this.currentEnvironmentStore.instance,
        dataSource: this.dataSource,
        params: params,
        staticData: this.staticData,
        context: this.context,
        contextElement: this.contextElement,
        localContext: this.localContext
      });
    }

    return obs$.pipe(
      map<ModelResponse.GetResponse, ListStoreFetchResponse<Model>>(response => {
        if (!response) {
          return;
        }

        let perPage: number;
        let totalPages: number;

        if (response.perPage) {
          perPage = response.perPage;
          this.perPage = perPage;
        } else if (this.perPage) {
          perPage = this.perPage;
        } else if (response.results.length) {
          perPage = response.results.length;
          this.perPage = perPage;
        }

        if (response.numPages) {
          totalPages = response.numPages;
        } else if (response.count && perPage) {
          totalPages = Math.ceil(response.count / perPage);
        }

        return {
          items: response.results,
          hasMore: response.hasMore,
          totalPages: totalPages,
          fetchPerPage: perPage,
          count: response.count,
          cursorPrev: response.cursorPrev,
          cursorNext: response.cursorNext
        };
      }),
      map(result => {
        if (result && result.items) {
          result.items.forEach(item => {
            if (
              this.useDataSourceColumns &&
              item instanceof Model &&
              this.dataSource.columns &&
              this.dataSource.columns.length
            ) {
              item.deserializeAttributes(this.dataSource.columns);
            }
          });
        }

        return result;
      })
    );
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<T>> {
    return this.fetchModelPage(page, next) as Observable<ListStoreFetchResponse<any>>;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

import { LocalStorage } from '@core';

import { TypedChanges } from '../../utils/angular/angular';
import { isSet } from '../../utils/common/common';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit, OnChanges {
  @Input() warning = false;
  @Input() info = false;
  @Input() icon: string;
  @Input() uniqueName: string;
  @Input() expands = true;
  @Input() buttonsCenter = false;
  @Input() shift = false;

  minimized = false;

  constructor(private localStorage: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ToastComponent>): void {
    if (changes.uniqueName) {
      this.minimized = this.loadMinimized();
    }
  }

  setMinimized(value: boolean) {
    this.minimized = value;
    this.cd.markForCheck();
    this.saveMinimized();
  }

  getMinimizedKey() {
    return `toast_minimized_${this.uniqueName}`;
  }

  loadMinimized(): boolean {
    if (!isSet(this.uniqueName)) {
      return false;
    }

    const key = this.getMinimizedKey();
    return this.localStorage.get(key) == '1';
  }

  saveMinimized() {
    if (!isSet(this.uniqueName)) {
      return;
    }

    const key = this.getMinimizedKey();
    if (this.minimized) {
      this.localStorage.set(key, '1');
    } else {
      this.localStorage.remove(key);
    }
  }
}

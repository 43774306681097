import { AbstractControl } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { localize } from '@common/localize';
import { ServerRequestError } from '@modules/api';
import { AppFormGroup } from '../data/app.form-group';
import * as i0 from "@angular/core";
var FormUtils = /** @class */ (function () {
    function FormUtils() {
    }
    FormUtils.prototype.showFormErrors = function (form, error) {
        if (!(error instanceof ServerRequestError)) {
            return;
        }
        if (error.nonFieldErrors && error.nonFieldErrors.length) {
            if (form instanceof AppFormGroup) {
                form.setServerErrors(error.nonFieldErrors);
            }
            else {
                form.setErrors({
                    server: error.nonFieldErrors
                });
            }
        }
        for (var field in error.fieldErrors) {
            if (form.controls[field] && form.controls[field] instanceof AbstractControl) {
                form.controls[field].setErrors({
                    server: error.fieldErrors[field]
                });
            }
        }
    };
    FormUtils.prototype.getFirstError = function (form) {
        var hasError = false;
        var nonFieldErrors = this.parseErrors(form.errors);
        var fieldErrors = {};
        if (nonFieldErrors.length) {
            hasError = true;
        }
        for (var _i = 0, _a = keys(form.controls); _i < _a.length; _i++) {
            var name_1 = _a[_i];
            fieldErrors[name_1] = this.parseErrors(form.controls[name_1].errors);
            if (fieldErrors[name_1].length) {
                hasError = true;
            }
        }
        if (!hasError) {
            return;
        }
        var error = new ServerRequestError();
        error.nonFieldErrors = nonFieldErrors.map(function (item) { return item.message; });
        error.fieldErrors = fromPairs(toPairs(fieldErrors).map(function (_a) {
            var name = _a[0], value = _a[1];
            return [name, value.map(function (item) { return item.message; })];
        }));
        return error;
    };
    FormUtils.prototype.parseErrors = function (errors) {
        if (!errors) {
            return [];
        }
        var result = [];
        toPairs(errors).forEach(function (_a) {
            var name = _a[0], value = _a[1];
            if (name == 'required') {
                result.push({ name: name, message: localize('required') });
            }
            else if (name == 'email') {
                result.push({ name: name, message: localize('incorrect Email'), important: true });
            }
            else if (name == 'server') {
                result.push({ name: name, message: value, important: true });
            }
            else if (name == 'local') {
                result.push({ name: name, message: value, important: true });
            }
            else if (name == 'local_dirty') {
                result.push({ name: name, message: value });
            }
            else if (name == 'minlength') {
                result.push({ name: name, message: localize('Minimum password length {0}', [value.requiredLength]) });
            }
        });
        return result;
    };
    FormUtils.prototype.getControlErrors = function (control, options) {
        if (options === void 0) { options = {}; }
        if (!control || !control.errors) {
            return [];
        }
        var form = control.parent;
        var submit = form ? form['submit'] : undefined;
        if (options.touchOnly && submit !== true && !control.touched) {
            return [];
        }
        var fieldErrors = control.errors;
        var errors = this.parseErrors(fieldErrors);
        var importantErrors = errors.filter(function (item) { return item.important; });
        if (submit === false && control.pristine) {
            return importantErrors.map(function (item) { return item.message; });
        }
        else if (submit !== false && form.pristine && control.pristine) {
            return importantErrors.map(function (item) { return item.message; });
        }
        else {
            return errors.map(function (item) { return item.message; });
        }
    };
    FormUtils.prototype.getFieldErrors = function (form, field, options) {
        if (options === void 0) { options = {}; }
        if (!form || !form.controls[field]) {
            return [];
        }
        return this.getControlErrors(form.controls[field], options);
    };
    FormUtils.prototype.getFormErrors = function (form) {
        if (!form) {
            return [];
        }
        if (!form.errors && !(form instanceof AppFormGroup && form.serverErrors.length)) {
            return [];
        }
        var errors = this.parseErrors(form.errors);
        if (form instanceof AppFormGroup) {
            errors.push.apply(errors, form.serverErrors.map(function (item) {
                return { name: 'server', message: item, important: true };
            }));
        }
        if (form.pristine) {
            return errors.filter(function (item) { return item.important; }).map(function (item) { return item.message; });
        }
        if (form['submit'] === false && form.pristine) {
            return errors.filter(function (item) { return item.important; }).map(function (item) { return item.message; });
        }
        else if (form['submit'] !== false && form.pristine) {
            return errors.filter(function (item) { return item.important; }).map(function (item) { return item.message; });
        }
        return errors.map(function (item) { return item.message; });
    };
    FormUtils.ngInjectableDef = i0.defineInjectable({ factory: function FormUtils_Factory() { return new FormUtils(); }, token: FormUtils, providedIn: "root" });
    return FormUtils;
}());
export { FormUtils };

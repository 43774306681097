import { Injectable } from '@angular/core';

import { ResourceName } from '@modules/projects';

import { AirtableGeneratorService } from '../airtable-generator/airtable-generator.service';
import { AmazonS3GeneratorService } from '../amazon-s3-generator/amazon-s3-generator.service';
import { AmplitudeGeneratorService } from '../amplitude-generator/amplitude-generator.service';
import { AzureBlobGeneratorService } from '../azure-blob-generator/azure-blob-generator.service';
import { DatabaseGeneratorService } from '../database-generator/database-generator.service';
import { DigitalOceanSpacesGeneratorService } from '../digital-ocean-spaces-generator/digital-ocean-spaces-generator.service';
import { DjangoGeneratorService } from '../django-generator/django-generator.service';
import { FirebaseGeneratorService } from '../firebase-generator/firebase-generator.service';
import { GoogleCloudStorageGeneratorService } from '../google-cloud-storage-generator/google-cloud-storage-generator.service';
import { GoogleSheetsGeneratorService } from '../google-sheets-generator/google-sheets-generator.service';
import { HubspotGeneratorService } from '../hubspot-generator/hubspot-generator.service';
import { IntercomGeneratorService } from '../intercom-generator/intercom-generator.service';
import { MailchimpGeneratorService } from '../mailchimp-generator/mailchimp-generator.service';
import { MailgunGeneratorService } from '../mailgun-generator/mailgun-generator.service';
import { MixpanelGeneratorService } from '../mixpanel-generator/mixpanel-generator.service';
import { OpenAIGeneratorService } from '../openai-generator/openai-generator.service';
import { PipedriveGeneratorService } from '../pipedrive-generator/pipedrive-generator.service';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { S3CompatibleGeneratorService } from '../s3-compatible-generator/s3-compatible-generator.service';
import { SendGridGeneratorService } from '../send-grid-generator/send-grid-generator.service';
import { SlackGeneratorService } from '../slack-generator/slack-generator.service';
import { SmartSuiteGeneratorService } from '../smart-suite-generator/smart-suite-generator.service';
import { StripeGeneratorService } from '../stripe-generator/stripe-generator.service';
import { SupabaseStorageGeneratorService } from '../supabase-storage-generator/supabase-storage-generator.service';
import { TwilioGeneratorService } from '../twilio-generator/twilio-generator.service';
import { XanoGeneratorService } from '../xano-generator/xano-generator.service';
import { ZendeskGeneratorService } from '../zendesk-generator/zendesk-generator.service';

@Injectable()
export class ResourceGeneratorResolver {
  generators = {};

  constructor(
    private databaseGeneratorService: DatabaseGeneratorService,
    private djangoGeneratorService: DjangoGeneratorService,
    private amplitudeGeneratorService: AmplitudeGeneratorService,
    private firebaseGeneratorService: FirebaseGeneratorService,
    private googleSheetsGeneratorService: GoogleSheetsGeneratorService,
    private hubspotGeneratorService: HubspotGeneratorService,
    private intercomGeneratorService: IntercomGeneratorService,
    private mailchimpGeneratorService: MailchimpGeneratorService,
    private mailgunGeneratorService: MailgunGeneratorService,
    private mixpanelGeneratorService: MixpanelGeneratorService,
    private pipedriveGeneratorService: PipedriveGeneratorService,
    private sendGridGeneratorService: SendGridGeneratorService,
    private slackGeneratorService: SlackGeneratorService,
    private stripeGeneratorService: StripeGeneratorService,
    private twilioGeneratorService: TwilioGeneratorService,
    private openAIGeneratorService: OpenAIGeneratorService,
    private zendeskGeneratorService: ZendeskGeneratorService,
    private azureBlobGeneratorService: AzureBlobGeneratorService,
    private amazonS3GeneratorService: AmazonS3GeneratorService,
    private digitalOceanSpacesGeneratorService: DigitalOceanSpacesGeneratorService,
    private s3CompatibleGeneratorService: S3CompatibleGeneratorService,
    private googleCloudStorageGeneratorService: GoogleCloudStorageGeneratorService,
    private supabaseStorageGeneratorService: SupabaseStorageGeneratorService,
    private airtableGeneratorService: AirtableGeneratorService,
    private smartSuiteGeneratorService: SmartSuiteGeneratorService,
    private xanoGeneratorService: XanoGeneratorService
  ) {
    this.register(ResourceName.PostgreSQL, this.databaseGeneratorService);
    this.register(ResourceName.MySQL, this.databaseGeneratorService);
    this.register(ResourceName.MariaDB, this.databaseGeneratorService);
    this.register(ResourceName.MicrosoftSQL, this.databaseGeneratorService);
    this.register(ResourceName.Oracle, this.databaseGeneratorService);
    this.register(ResourceName.BigQuery, this.databaseGeneratorService);
    this.register(ResourceName.Snowflake, this.databaseGeneratorService);
    this.register(ResourceName.Redshift, this.databaseGeneratorService);
    this.register(ResourceName.AlloyDB, this.databaseGeneratorService);
    this.register(ResourceName.Supabase, this.databaseGeneratorService);
    this.register(ResourceName.Xano, this.xanoGeneratorService);
    this.register(ResourceName.SQLite, this.databaseGeneratorService);
    this.register(ResourceName.JetDatabase, this.databaseGeneratorService);
    this.register(ResourceName.Django, this.djangoGeneratorService);
    this.register(ResourceName.Amplitude, this.amplitudeGeneratorService);
    this.register(ResourceName.Firebase, this.firebaseGeneratorService);
    this.register(ResourceName.GoogleSheets, this.googleSheetsGeneratorService);
    this.register(ResourceName.HubSpot, this.hubspotGeneratorService);
    this.register(ResourceName.Intercom, this.intercomGeneratorService);
    this.register(ResourceName.Mailchimp, this.mailchimpGeneratorService);
    this.register(ResourceName.Mailgun, this.mailgunGeneratorService);
    this.register(ResourceName.Mixpanel, this.mixpanelGeneratorService);
    this.register(ResourceName.Pipedrive, this.pipedriveGeneratorService);
    this.register(ResourceName.SendGrid, this.sendGridGeneratorService);
    this.register(ResourceName.Slack, this.slackGeneratorService);
    this.register(ResourceName.Stripe, this.stripeGeneratorService);
    this.register(ResourceName.Twilio, this.twilioGeneratorService);
    this.register(ResourceName.OpenAI, this.openAIGeneratorService);
    this.register(ResourceName.Zendesk, this.zendeskGeneratorService);
    this.register(ResourceName.AzureBlob, this.azureBlobGeneratorService);
    this.register(ResourceName.AmazonS3, this.amazonS3GeneratorService);
    this.register(ResourceName.DigitalOceanSpaces, this.digitalOceanSpacesGeneratorService);
    this.register(ResourceName.S3Compatible, this.s3CompatibleGeneratorService);
    this.register(ResourceName.GoogleCloudStorage, this.googleCloudStorageGeneratorService);
    this.register(ResourceName.SupabaseStorage, this.supabaseStorageGeneratorService);
    this.register(ResourceName.Airtable, this.airtableGeneratorService);
    this.register(ResourceName.SmartSuite, this.smartSuiteGeneratorService);
  }

  register(type: string, generator: ResourceGeneratorService<any>) {
    this.generators[type] = generator;
  }

  get<T extends ResourceGeneratorService<any>>(type: string): T {
    return this.generators[type];
  }
}

<div class="sidebar__list" [ngClass]="classes">
  <app-inputs-edit
    [control]="control.controls.query_inputs"
    [parameterProvider]="control.inputFieldProvider"
    [fieldsControl]="fieldsControl"
    [editable]="editable"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [userInput]="userInput"
    [classes]="itemClasses"
    [object]="analyticsSource"
    (addInput)="addInput.emit()"
  >
  </app-inputs-edit>
</div>

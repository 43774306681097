/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/components/toast/toast.component.ngfactory";
import * as i4 from "../../../../shared/components/toast/toast.component";
import * as i5 from "../../../../core/services/local-storage/local.storage";
import * as i6 from "./demo-project-toast.component";
import * as i7 from "../../../users/stores/current-user.store";
import * as i8 from "../../../api/services/api/api.service";
var styles_DemoProjectToastComponent = [];
var RenderType_DemoProjectToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DemoProjectToastComponent, data: {} });
export { RenderType_DemoProjectToastComponent as RenderType_DemoProjectToastComponent };
function View_DemoProjectToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "toast2__button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-hand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Get Started"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/projects", "create"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DemoProjectToastComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "toast2__button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵpod(2, { transfer_projects: 0 }), i0.ɵpad(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-magic_wand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Apply Template"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, ((_co.project == null) ? null : _co.project.uniqueName)); var currVal_3 = _ck(_v, 3, 0, "/register"); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DemoProjectToastComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-toast", [], null, null, null, i3.View_ToastComponent_0, i3.RenderType_ToastComponent)), i0.ɵdid(1, 638976, null, 0, i4.ToastComponent, [i5.LocalStorage, i0.ChangeDetectorRef], { icon: [0, "icon"], uniqueName: [1, "uniqueName"], buttonsCenter: [2, "buttonsCenter"], shift: [3, "shift"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This is demo application "])), (_l()(), i0.ɵeld(4, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Build your own applications in 5 minutes: Admin Panel, Dashboard, Customer portal "])), (_l()(), i0.ɵeld(7, 0, null, 1, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemoProjectToastComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemoProjectToastComponent_2)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "eye_2"; var currVal_1 = "demo_project"; var currVal_2 = true; var currVal_3 = _co.shift; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = !_co.demoUser; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.demoUser; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_DemoProjectToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-demo-project-toast", [], null, null, null, View_DemoProjectToastComponent_0, RenderType_DemoProjectToastComponent)), i0.ɵdid(1, 245760, null, 0, i6.DemoProjectToastComponent, [i7.CurrentUserStore, i8.ApiService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemoProjectToastComponentNgFactory = i0.ɵccf("app-demo-project-toast", i6.DemoProjectToastComponent, View_DemoProjectToastComponent_Host_0, { project: "project", shift: "shift" }, {}, []);
export { DemoProjectToastComponentNgFactory as DemoProjectToastComponentNgFactory };

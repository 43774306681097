import { Observable } from 'rxjs';

export interface Size {
  width: number;
  height: number;
}

export function getImageSize(url: string): Observable<Size> {
  return new Observable<{ width: number; height: number }>(observer => {
    const img = new Image();

    img.onload = () => {
      observer.next({
        width: img.width,
        height: img.height
      });
      observer.complete();
    };
    img.onerror = e => {
      observer.error(e);
    };
    img.src = url;

    return () => {
      img.onload = null;
      img.onerror = null;
      img.src = '';
    };
  });
}

export function getVideoSize(url: string): Observable<Size> {
  return new Observable<{ width: number; height: number }>(observer => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      () => {
        observer.next({
          width: video.videoWidth,
          height: video.videoHeight
        });
        observer.complete();
      },
      false
    );

    video.src = url;

    return () => {
      video.src = '';
    };
  });
}

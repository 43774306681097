import {
  DefaultType,
  EditableField,
  FieldDescription,
  FieldType,
  FilterableField,
  FormField,
  getFieldDescriptionByType,
  parseFieldType,
  SortableField,
  ValidatorType
} from '@modules/fields';
import { isSet } from '@shared';

export class ModelDbField implements EditableField, FilterableField, SortableField {
  public name: string;
  public verboseName: string;
  public description: string;
  public field: FieldType;
  public dbField: string;
  public required = true;
  public null = false;
  public editable: boolean;
  public isRelation: boolean;
  public filterable: boolean;
  public sortable: boolean;
  public dbColumn: string;
  public defaultType: DefaultType = undefined;
  public defaultValue: string;
  public placeholder: string;
  public validatorType: ValidatorType;
  public validatorParams: Object;
  public params = {};
  public fieldDescription: FieldDescription;
  private _formField: FormField;

  deserialize(data: Object): ModelDbField {
    this.name = data['name'];
    this.description = data['description'];
    this.field = parseFieldType(data['field']);
    this.dbField = data['db_field'];
    this.editable = data['editable'];
    this.isRelation = data['is_relation'];
    this.filterable = data['filterable'];
    this.dbColumn = data['db_column'];
    this.defaultValue = data['default_value'];
    this.placeholder = data['placeholder'];
    this.validatorType = data['validator_type'];
    this.validatorParams = data['validator_params'];

    if (data['data_source_name']) {
      this.verboseName = data['data_source_name'];
    } else {
      this.verboseName = data['verbose_name'];
    }

    this.autoFillVerboseName();

    if (data['data_source_field']) {
      this.field = parseFieldType(data['data_source_field']);
    } else {
      this.field = parseFieldType(data['field']);
    }

    if (data['null'] != undefined) {
      this.null = data['null'];
    }

    if (data['default_type']) {
      this.defaultType = data['default_type'];
    }

    if (data['required'] != undefined) {
      this.required = data['required'];
    } else if (data['required'] == undefined && this.null == true) {
      this.required = false;
    }

    if (data['sortable'] != undefined) {
      this.sortable = data['sortable'];
    } else {
      this.sortable = this.filterable;
    }

    const item = getFieldDescriptionByType(this.field);
    this.params = { ...item.defaultParams, ...data['params'], ...data['data_source_params'], ...item.forceParams };

    this.updateFieldDescription();

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      verbose_name: this.verboseName,
      field: this.field,
      db_field: this.dbField,
      required: this.required,
      null: this.null,
      editable: this.editable,
      is_relation: this.isRelation,
      filterable: this.filterable,
      sortable: this.sortable,
      db_column: this.dbColumn,
      default_type: this.defaultType,
      default_value: this.defaultValue,
      placeholder: this.placeholder,
      validator_type: this.validatorType,
      validator_params: this.validatorParams,
      params: this.params,
      description: this.description
    };
  }

  get formField(): FormField {
    if (!this._formField) {
      this._formField = new FormField().deserialize({
        name: this.name,
        label: this.verboseName,
        field: this.field,
        required: this.required,
        editable: this.editable,
        params: this.params,
        description: this.description
      });
    }

    return this._formField;
  }

  autoFillVerboseName() {
    if (!isSet(this.verboseName) && isSet(this.name) && typeof this.name === 'string') {
      this.verboseName = this.name;

      if (this.field == FieldType.RelatedModel && this.verboseName.toLowerCase().substr(-3) == '_id') {
        this.verboseName = this.verboseName.substr(0, this.verboseName.length - 3);
      }

      this.verboseName = this.verboseName.replace(/_/g, ' ');
    }
  }

  resetFormField() {
    this._formField = undefined;
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }

  get lookups() {
    return this.fieldDescription.lookups;
  }

  applyOverrides(overrideField: EditableField) {
    this.verboseName = overrideField.verboseName;
    this.description = overrideField.description;
    this.required = overrideField.required;
    this.editable = overrideField.editable;
    this.placeholder = overrideField.placeholder;
    this.validatorType = overrideField.validatorType;
    this.validatorParams = overrideField.validatorParams;
    this.params = {
      ...overrideField.params,
      ...this.params
    };
  }
}

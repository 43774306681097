import { Injectable, Injector, StaticProvider } from '@angular/core';

import { Factory } from '@core';

import { ViewContext } from '../data/view-context';
import { ViewContextElement } from '../data/view-context-element';

@Injectable({
  providedIn: 'root'
})
export class ViewContextElementFactory {
  constructor(private factory: Factory) {}

  create(injector: Injector, options: { providers?: StaticProvider[] } = {}) {
    return this.factory.create<ViewContextElement>(ViewContextElement, {
      dependencies: [ViewContext],
      injector: injector,
      providers: options.providers
    });
  }
}

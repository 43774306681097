import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CurrentUserStore } from '@modules/users';
import * as i0 from "@angular/core";
import * as i1 from "../../../users/stores/current-user.store";
import * as i2 from "ngx-cookie/src/cookie.service";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(currentUserStore, cookieService) {
        var _this = this;
        this.currentUserStore = currentUserStore;
        this.cookieService = cookieService;
        this._loaded = new BehaviorSubject(false);
        var interval = setInterval(function () {
            if (!_this.ga || !_this.clientId) {
                return;
            }
            _this.init();
            _this._loaded.next(true);
            clearInterval(interval);
        }, 1000);
    }
    GoogleAnalyticsService.prototype.init = function () {
        var _this = this;
        this.currentUserStore.instance$.subscribe(function (user) {
            if (!_this.ga || !user) {
                return;
            }
            _this.ga('create', 'UA-103103655-25', { alwaysSendReferrer: true });
            _this.ga('set', 'userId', user.username);
            _this.ga('set', 'dimension2', user.username);
        });
    };
    Object.defineProperty(GoogleAnalyticsService.prototype, "ga", {
        get: function () {
            return window['ga'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleAnalyticsService.prototype, "loaded$", {
        get: function () {
            return this._loaded.pipe(filter(function (item) { return item == true; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleAnalyticsService.prototype, "clientId", {
        get: function () {
            var value = this.cookieService.get('_ga');
            if (!value) {
                return;
            }
            var parts = value.split('.');
            if (parts.length < 4) {
                return;
            }
            return parts[2] + "." + parts[3];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleAnalyticsService.prototype, "clientId$", {
        get: function () {
            var _this = this;
            return this.loaded$.pipe(map(function () {
                return _this.clientId;
            }));
        },
        enumerable: true,
        configurable: true
    });
    GoogleAnalyticsService.prototype.setLocationReferrer = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.ga('set', 'referrer', window.location.origin);
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.CurrentUserStore), i0.inject(i2.CookieService)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };

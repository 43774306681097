var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup } from '@angular/forms';
import { Font, FontStyle } from '@modules/views';
import { isSet } from '@shared';
var weightOptions = [
    {
        value: 100,
        name: 'Thin'
    },
    {
        value: 200,
        name: 'Extra Light'
    },
    {
        value: 300,
        name: 'Light'
    },
    {
        value: 400,
        name: 'Regular'
    },
    {
        value: 500,
        name: 'Medium'
    },
    {
        value: 600,
        name: 'Semi Bold'
    },
    {
        value: 700,
        name: 'Bold'
    },
    {
        value: 800,
        name: 'Extra Bold'
    },
    {
        value: 900,
        name: 'Black'
    }
];
var FontControl = /** @class */ (function (_super) {
    __extends(FontControl, _super);
    function FontControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            family: new FormControl(isSet(state.family) ? state.family : 'Fira Sans'),
            type: new FormControl(__assign({ weight: 400, style: FontStyle.Normal }, (isSet(state.weight) && { weight: state.weight }), (isSet(state.style) && { style: state.style }))),
            size: new FormControl(isSet(state.size) ? state.size : 14)
        }) || this;
        _this.typeOptions = [FontStyle.Normal, FontStyle.Italic].reduce(function (acc, item) {
            acc.push.apply(acc, weightOptions.map(function (weight) {
                var name = weight.name;
                var styles = ["font-weight: " + weight.value];
                if (item == FontStyle.Italic) {
                    name = weight.name + " Italic";
                    styles.push('font-style: italic');
                }
                return {
                    value: { weight: weight.value, style: item },
                    name: name,
                    style: styles.join(';')
                };
            }));
            return acc;
        }, []);
        return _this;
    }
    FontControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        if (isSet(value.family)) {
            this.controls.family.patchValue(value.family, { emitEvent: options.emitEvent });
        }
        var type = this.typeOptions.find(function (item) { return item.value.weight == value.weight && item.value.style == value.style; });
        if (type) {
            this.controls.type.patchValue(type.value, { emitEvent: options.emitEvent });
        }
        this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
    };
    FontControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Font();
        }
        instance.family = this.controls.family.value;
        if (this.controls.type.value) {
            instance.weight = this.controls.type.value.weight;
            instance.style = this.controls.type.value.style;
        }
        instance.size = this.controls.size.value;
        return instance;
    };
    FontControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return FontControl;
}(FormGroup));
export { FontControl };

import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import Delta from 'quill-delta';
import { asyncScheduler, concat, Observable, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ElementOutputMeta, ViewContext, ViewContextElement } from '@modules/customize';
import { AppError, cacheCall, cleanWrapSpaces, EMPTY, isSet, objectGet } from '@shared';

// TODO: Refactor import
import { quillDeltaToHtml } from '../../../common/text-editor/utils/quill-delta-to-html';
import { quillDeltaToMarkdown } from '../../../common/text-editor/utils/quill-delta-to-markdown/common';
import { quillDeltaToText } from '../../../common/text-editor/utils/quill-delta-to-text';

import { BaseField } from '../data/base-field';
import { Input, MarkupType } from '../data/input';
import { contextInputValueTypes, InputValueType } from '../data/input-value-type';
import { ParameterField } from '../data/parameter-field';
import { mathjs } from './math';
import { HASH, NOW, RANDOM, UUID } from './math-custom-functions';

export function mathJsIsSingleToken(node: any) {
  if (node && node['isAccessorNode']) {
    return mathJsIsSingleToken(node.object);
  } else if (node && node['isSymbolNode']) {
    return true;
  } else {
    return false;
  }
}

class FormulaError extends AppError {
  constructor(message) {
    super(message);
    this.name = 'FormulaError';

    Object.setPrototypeOf(this, FormulaError.prototype);
  }
}

export function elementOutputMeta(name: string): string {
  return `${name}__jetmeta`;
}

export const LOADING_VALUE_CLS = () => {};
LOADING_VALUE_CLS.prototype.toString = () => 'LOADING_VALUE';
export const LOADING_VALUE = new LOADING_VALUE_CLS();

export const NOT_SET_VALUE_CLS = () => {};
NOT_SET_VALUE_CLS.prototype.toString = () => 'NOT_SET_VALUE';
export const NOT_SET_VALUE = new NOT_SET_VALUE_CLS();

export function isEmpty(value: any, emptyValues?: any[]) {
  if (emptyValues) {
    return emptyValues.some(emptyValue => isEqual(value, emptyValue));
  } else {
    return !isSet(value);
  }
}

export function addValidChecks(
  obj: Object,
  options: {
    raiseErrors?: boolean;
    handleLoading?: boolean;
  } = {}
): Object {
  if (!options.raiseErrors && !options.handleLoading) {
    return obj;
  }

  if (isPlainObject(obj)) {
    const result = {};

    toPairs(obj).forEach(([k, v]) => {
      Object.defineProperty(result, k, {
        get: function () {
          const meta: ElementOutputMeta = obj[elementOutputMeta(k)];

          if (options.raiseErrors && meta && meta.error === true) {
            throw new AppError('Is not valid');
          }

          if (options.handleLoading && meta && meta.loading === true) {
            throw LOADING_VALUE;
          }

          return addValidChecks(v, options);
        },
        enumerable: true
      });
    });

    return result;
  } else {
    return obj;
  }
}

export function getJetCtx(
  options: {
    context?: ViewContext;
  } = {}
): Object {
  const env = options.context ? options.context.currentEnvironmentStore.instance : undefined;
  const page = options.context ? options.context.viewSettings : undefined;
  return {
    env: env,
    page: page
  };
}

export function executeTextInputs(
  type: MarkupType,
  textInputs: Delta,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    dynamicFunctionsContext?: Object;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
  } = {}
) {
  try {
    let result;

    const applyContextFormula = (input: Input) => applyParamInput(input, { ...options, defaultValue: '' });

    try {
      if (type == MarkupType.Text) {
        result = quillDeltaToText(textInputs, { applyContextFormula: applyContextFormula });
      } else if (type == MarkupType.Markdown) {
        result = quillDeltaToMarkdown(textInputs, { applyContextFormula: applyContextFormula });
      } else if (type == MarkupType.HTML) {
        result = quillDeltaToHtml(textInputs, { applyContextFormula: applyContextFormula });
      }
    } catch (e) {
      if (e === LOADING_VALUE) {
        result = LOADING_VALUE;
      } else {
        throw e;
      }
    }

    if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
      return undefined;
    }

    return result;
  } catch (e) {
    if (!environment.production) {
      console.error(`Failed executing Text with Inputs: ${textInputs}`, e);
    }

    throw new FormulaError('Failed executing Text with Inputs');
  }
}

export function executeJavaScript(
  js: string,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    dynamicFunctionsContext?: Object;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
  } = {}
) {
  const jetCtx = getJetCtx(options);
  const outputs = options.context ? options.context.outputValues : {};

  try {
    let elementCtx: Object;
    const elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;

    if (elementPath) {
      const obj = objectGet(outputs, elementPath);

      if (obj !== EMPTY) {
        elementCtx = obj;
      }
    }

    const scope = addValidChecks({
      ...outputs,
      ...elementCtx,
      ...options.dynamicFunctionsContext,
      ...options.localContext,
      jet: jetCtx
    });
    let result;

    try {
      const x = new Function(...keys(scope), js);
      result = x(...values(scope));
    } catch (e) {
      if (e === LOADING_VALUE) {
        result = LOADING_VALUE;
      } else {
        throw e;
      }
    }

    if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
      return undefined;
    }

    return result;
  } catch (e) {
    if (!environment.production) {
      console.error(`Failed executing JS: ${js}`, e, outputs);
    }

    throw new FormulaError('Failed executing JavaScript');
  }
}

export function applyParamInput(
  input: Input,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    dynamicFunctionsContext?: Object;
    field?: BaseField;
    defaultValue?: any;
    raiseErrors?: boolean;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
    handleLoading?: boolean;
  } = {}
): any {
  if (!input) {
    return options.defaultValue;
  }

  options = defaults(options, { defaultValue: EMPTY });

  if (input.valueType == InputValueType.StaticValue) {
    if (isSet(input.staticValue)) {
      if (input.array && !isArray(input.staticValue)) {
        return [input.staticValue];
      } else {
        return input.staticValue;
      }
    } else {
      return;
    }
  } else if (input.valueType == InputValueType.EmptyString) {
    return '';
  } else if (input.valueType == InputValueType.Null) {
    return null;
  } else if (input.valueType == InputValueType.Context) {
    const jetCtx = getJetCtx(options);
    const outputs = options.context ? options.context.outputValues : {};

    let elementCtx: Object;
    const elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;

    if (elementPath) {
      const obj = objectGet(outputs, elementPath);

      if (obj !== EMPTY) {
        elementCtx = obj;
      }
    }

    const ctx = addValidChecks(
      {
        ...outputs,
        ...elementCtx,
        ...options.dynamicFunctionsContext,
        ...options.localContext,
        jet: jetCtx
      },
      options
    );

    let result;

    try {
      result = objectGet(ctx, input.contextValue, EMPTY);
    } catch (e) {
      if (e === LOADING_VALUE) {
        result = LOADING_VALUE;
      } else {
        throw e;
      }
    }

    if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
      return undefined;
    }

    if (result !== EMPTY) {
      return result;
    }
  } else if (input.valueType == InputValueType.Formula) {
    if (isSet(input.formulaValue)) {
      const jetCtx = getJetCtx(options);
      const outputs = options.context ? options.context.outputValues : {};

      try {
        const rootNode = mathjs.parse(cleanWrapSpaces(input.formulaValue)).transform(node => {
          if (node['isIndexNode']) {
            const dimension = node.dimensions[0];

            if (!node.isObjectProperty() && dimension && typeof dimension.value == 'number') {
              dimension.value = dimension.value + 1;
            }
          }
          return node;
        });

        const code = rootNode.compile();

        let elementCtx: Object;
        const elementPath = options.context ? options.context.getElementPath(options.contextElement) : undefined;

        if (elementPath) {
          const obj = objectGet(outputs, elementPath);

          if (obj !== EMPTY) {
            elementCtx = obj;
          }
        }

        const scope = addValidChecks(
          {
            ...outputs,
            ...elementCtx,
            ...options.dynamicFunctionsContext,
            ...options.localContext,
            jet: jetCtx
          },
          options
        );
        let result;

        try {
          result = code.evaluate(scope);

          if (result && result['isResultSet']) {
            result = result.entries[result.entries.length - 1];
          }
        } catch (e) {
          if (e === LOADING_VALUE) {
            result = LOADING_VALUE;
          } else {
            throw e;
          }
        }

        if (options.ignoreEmpty && isEmpty(result, options.emptyValues)) {
          return undefined;
        }

        return result;
      } catch (e) {
        if (!environment.production) {
          console.error(`Failed executing formula: ${input.formulaValue}`, e, outputs);
        }

        throw new FormulaError('Failed executing formula');
      }
    }
  } else if (input.valueType == InputValueType.TextInputs) {
    if (input.textInputsValue) {
      return executeTextInputs(input.textInputsType, input.textInputsValue, options);
    }
  } else if (input.valueType == InputValueType.Js) {
    if (isSet(input.jsValue)) {
      return executeJavaScript(input.jsValue, options);
    }
  }

  return options.defaultValue;
}

export function applyParamInput$<T = any>(
  input: Input,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    field?: BaseField;
    defaultValue?: any;
    raiseErrors?: boolean;
    handleLoading?: boolean;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
    debounce?: number;
  } = {}
): Observable<T> {
  const dynamicFunctionsContext = {
    NOW: cacheCall(NOW),
    RANDOM: cacheCall(RANDOM),
    HASH: cacheCall(HASH),
    UUID: cacheCall(UUID)
  };
  const debounce = options.debounce !== undefined ? options.debounce : 10;
  const obs =
    options.context && contextInputValueTypes.includes(input.valueType)
      ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(
          throttleTime(debounce, asyncScheduler, { leading: true, trailing: true })
        )
      : of({});

  return obs.pipe(
    map(() => {
      try {
        return applyParamInput(input, { dynamicFunctionsContext: dynamicFunctionsContext, ...options });
      } catch (e) {
        return options.defaultValue;
      }
    })
  );
}

export function applyParamInputs(
  params: Object,
  inputs: Input[],
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    dynamicFunctionsContext?: Object;
    parameters?: ParameterField[];
    handleLoading?: boolean;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
    raiseErrors?: boolean;
  }
): Object {
  if (!inputs.length) {
    return params;
  }

  const resultParams = cloneDeep(params);

  // if (context && context.model) {
  //   ctx['record'] = context.model.getAttributes();
  // }
  //
  // if (context && context.contextParameters) {
  //   ctx['context'] = context.contextParameterValues;
  // }

  inputs
    .filter(item => item.valueType != InputValueType.Prompt)
    .forEach(item => {
      const parameter = options.parameters ? options.parameters.find(i => i.name == item.name) : undefined;

      try {
        const result = applyParamInput(item, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext,
          dynamicFunctionsContext: options.dynamicFunctionsContext,
          handleLoading: options.handleLoading,
          ignoreEmpty: options.ignoreEmpty,
          emptyValues: options.emptyValues,
          raiseErrors: options.raiseErrors,
          field: parameter
        });

        if (
          item.required &&
          ![InputValueType.Null, InputValueType.EmptyString, InputValueType.Js].includes(item.valueType) &&
          (result === EMPTY || isEmpty(result, options.emptyValues))
        ) {
          resultParams[item.name] = NOT_SET_VALUE;
        } else if (result !== EMPTY) {
          resultParams[item.name] = result;
        }
      } catch (e) {
        if (options.raiseErrors) {
          throw e;
        }
      }
    });

  return resultParams;
}

export function applyParamInputs$(
  params: Object,
  inputs: Input[],
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    parameters?: ParameterField[];
    raiseErrors?: boolean;
    errorValue?: any;
    handleLoading?: boolean;
    ignoreEmpty?: boolean;
    emptyValues?: any[];
    debounce?: number;
  }
): Observable<Object> {
  if (!inputs.length) {
    return of(params);
  }

  const dynamicFunctionsContext = {
    NOW: cacheCall(NOW),
    RANDOM: cacheCall(RANDOM),
    HASH: cacheCall(HASH),
    UUID: cacheCall(UUID)
  };
  const debounce = options.debounce !== undefined ? options.debounce : 10;
  const obs =
    options.context && inputs.some(item => contextInputValueTypes.includes(item.valueType))
      ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(
          throttleTime(debounce, asyncScheduler, { leading: true, trailing: true })
        )
      : of({});

  return obs.pipe(
    map(() => {
      try {
        return applyParamInputs(params, inputs, {
          context: options.context,
          contextElement: options.contextElement,
          dynamicFunctionsContext: dynamicFunctionsContext,
          localContext: options.localContext,
          parameters: options.parameters,
          handleLoading: options.handleLoading,
          ignoreEmpty: options.ignoreEmpty,
          emptyValues: options.emptyValues,
          raiseErrors: options.raiseErrors
        });
      } catch (e) {
        if (isSet(options.errorValue)) {
          return options.errorValue;
        }

        throw e;
      }
    })
  );
}

export function applyBooleanInput(input: Input, context?: ViewContext): boolean {
  if (!input || !input.isSet()) {
    return true;
  }

  try {
    const value = applyParamInput(input, { context: context });
    return cleanBooleanValue(value);
  } catch (e) {
    return false;
  }
}

export function applyBooleanInput$(
  input: Input,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  } = {}
): Observable<boolean> {
  if (!input || !input.isSet()) {
    return of(true);
  }

  return applyParamInput$(input, {
    context: options.context,
    contextElement: options.contextElement,
    localContext: options.localContext
  }).pipe(map(value => cleanBooleanValue(value)));
}

export const ERROR_VALUE_CLS = () => {};
ERROR_VALUE_CLS.prototype.toString = () => 'ERROR_VALUE';
export const ERROR_VALUE = new ERROR_VALUE_CLS();

export function getInputsValid$(
  inputs: Input[],
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
    parameters?: ParameterField[];
    handleLoading?: boolean;
    ignoreEmpty?: boolean;
    debounce?: number;
  } = {}
): Observable<boolean> {
  return applyParamInputs$({}, inputs, {
    ...options,
    raiseErrors: true,
    errorValue: ERROR_VALUE
  }).pipe(
    map(value => {
      if (value === ERROR_VALUE) {
        return false;
      }

      return true;
    })
  );
}

export function cleanBooleanValue(value: any): boolean {
  // if (value === EMPTY || !isSet(value)) {
  if (value === EMPTY) {
    return false;
  }

  if (value === '0' || value === 'false') {
    return false;
  }

  return !!value;
}

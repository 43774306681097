import { BaseField, FieldDescription, FieldType } from '@modules/fields';
import { isSet } from '@shared';

// TODO: Refactor import
import { getFieldDescriptionByType, parseFieldType } from '../../fields/data/fields';

export class Field implements BaseField {
  public name: string;
  public verboseName: string;
  public field: FieldType;
  public params = {};
  public required = false;
  public placeholder: string;
  public fieldDescription: FieldDescription;

  constructor(options: Partial<Field> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): Field {
    this.name = data['name'];
    this.verboseName = data['verbose_name'] || this.name;
    this.field = parseFieldType(data['field']);

    if (data['params']) {
      this.params = data['params'];
    }

    if (isSet(data['required'])) {
      this.required = data['required'];
    }

    if (isSet(data['placeholder'])) {
      this.placeholder = data['placeholder'];
    }

    this.updateFieldDescription();

    return this;
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { applyParamInput, defaultFieldType, Input, InputValueType, isRequiredInputsSet, ParameterArray, ParameterField } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItemsFlat } from '@modules/parameters';
import { QueryService } from '@modules/queries';
import { WorkflowResult } from '@modules/workflow';
import { controlValue } from '@shared';
export var validateInputs = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var fields = parent.inputFieldProvider.fields;
    var inputs = control.value;
    if (!isRequiredInputsSet(fields, inputs)) {
        return { required: true };
    }
};
export var WorkflowResultType;
(function (WorkflowResultType) {
    WorkflowResultType["Auto"] = "auto";
    WorkflowResultType["Manual"] = "manual";
})(WorkflowResultType || (WorkflowResultType = {}));
var CustomizeWorkflowResultForm = /** @class */ (function (_super) {
    __extends(CustomizeWorkflowResultForm, _super);
    function CustomizeWorkflowResultForm(queryService) {
        var _this = _super.call(this, {
            type: new FormControl(WorkflowResultType.Auto),
            parameters: new ParameterArray([]),
            inputs: new FormControl([], validateInputs),
            array: new FormControl(false),
            array_input: new FieldInputControl({ name: 'value' })
        }) || this;
        _this.queryService = queryService;
        _this.inputFieldProvider = new InputFieldProvider();
        _this.typeOptions = [
            {
                value: WorkflowResultType.Auto,
                name: 'Use last step',
                icon: 'magic_wand'
            },
            {
                value: WorkflowResultType.Manual,
                name: 'Specify outputs',
                icon: 'edit'
            }
        ];
        _this.inputFieldProvider.fields$.subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
        return _this;
    }
    CustomizeWorkflowResultForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeWorkflowResultForm.prototype.init = function (result, resultOutputs) {
        var _this = this;
        this.result = result;
        this.resultOutputs = resultOutputs;
        if (result) {
            if (resultOutputs && resultOutputs.length && result) {
                this.controls.inputs.patchValue(result.inputs);
            }
            else if (result) {
                this.controls.type.patchValue(WorkflowResultType.Manual);
                this.controls.parameters.patchValue(result.parameters);
                this.controls.inputs.patchValue(result.inputs);
                this.controls.array.patchValue(result.array);
                this.controls.array_input.patchValue(result.arrayInput ? result.arrayInput.serialize() : {});
            }
            else {
                this.controls.type.patchValue(WorkflowResultType.Auto);
            }
            this.markAsPristine();
        }
        if (resultOutputs && resultOutputs.length) {
            this.updateInputFieldProvider().subscribe();
        }
        else {
            controlValue(this.controls.parameters).subscribe(function () { return _this.updateInputFieldProvider().subscribe(); });
        }
        this.controls.array.valueChanges.subscribe(function () {
            _this.controls.parameters.patchValue([]);
            _this.controls.inputs.patchValue([]);
        });
    };
    CustomizeWorkflowResultForm.prototype.updateInputFieldProvider = function () {
        var _this = this;
        if (this.resultOutputs && this.resultOutputs.length) {
            var items = this.resultOutputs.map(function (item) {
                return {
                    label: item.verboseName || item.name,
                    field: __assign({}, item)
                };
            });
            this.inputFieldProvider.setProvider(items);
            return of(items);
        }
        else {
            return controlValue(this.controls.parameters).pipe(first(), map(function (parameters) {
                return parametersToProviderItemsFlat((parameters || []).filter(function (item) { return item.name; }));
            }), tap(function (items) {
                _this.inputFieldProvider.setProvider(items);
            }));
        }
    };
    CustomizeWorkflowResultForm.prototype.getArrayInputDefaultParameters = function (isDataArray, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var getContextColumns = function (value) {
            return options.context.getToken$(value.contextValue, options.contextElement).pipe(map(function (result) {
                if (!result || !result.children || !result.children.length) {
                    return;
                }
                var columns = isDataArray && result.children[0].children && result.children[0].children.length
                    ? result.children[0].children
                    : result.children;
                return columns.map(function (item) {
                    return {
                        name: item.uniqueName,
                        verboseName: item.name,
                        field: item.fieldType || defaultFieldType,
                        params: item.fieldParams || {}
                    };
                });
            }));
        };
        return controlValue(this.controls.array_input).pipe(switchMap(function (value) {
            var input = value ? new Input().deserialize(value) : undefined;
            if (!input) {
                return of(undefined);
            }
            var contextColumns$ = options.context && input.valueType == InputValueType.Context ? getContextColumns(input) : of(undefined);
            return contextColumns$.pipe(map(function (contextColumns) {
                if (contextColumns) {
                    return contextColumns;
                }
                try {
                    var data = applyParamInput(input, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: options.localContext,
                        defaultValue: {}
                    });
                    return isDataArray
                        ? _this.queryService.autoDetectGetFields(data)
                        : _this.queryService.autoDetectGetDetailFields(data);
                }
                catch (e) { }
            }));
        }), map(function (result) {
            if (!result) {
                return;
            }
            return result.map(function (item) {
                var parameter = new ParameterField();
                parameter.name = item.name;
                parameter.verboseName = item.verboseName;
                parameter.field = item.field;
                parameter.params = item.params;
                return parameter;
            });
        }));
    };
    CustomizeWorkflowResultForm.prototype.isParametersSame$ = function (lhs, rhs) {
        lhs = lhs.filter(function (item) { return !item['flex']; });
        rhs = rhs.filter(function (item) { return !item['flex']; });
        return lhs.length == rhs.length && lhs.every(function (lhsItem) { return !!rhs.find(function (rhsItem) { return rhsItem.name == lhsItem.name; }); });
    };
    CustomizeWorkflowResultForm.prototype.getAutoDetectedParameters$ = function (options) {
        if (options === void 0) { options = {}; }
        return this.getArrayInputDefaultParameters(true, options);
    };
    CustomizeWorkflowResultForm.prototype.setColumns = function (columns, options) {
        if (options === void 0) { options = {}; }
        this.controls.parameters.setValue(columns);
        if (options.markAsDirty) {
            this.controls.parameters.markAsDirty();
        }
    };
    CustomizeWorkflowResultForm.prototype.resetArrayInputParameters = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.getAutoDetectedParameters$({
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext
        })
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (columns) {
            if (columns) {
                _this.setColumns(columns, { markAsDirty: options.markAsDirty });
            }
        });
    };
    CustomizeWorkflowResultForm.prototype.submit = function () {
        if (this.resultOutputs && this.resultOutputs.length) {
            var result = cloneDeep(this.result) || new WorkflowResult();
            result.array = false;
            result.inputs = this.controls.inputs.value;
            return result;
        }
        else if (this.controls.type.value == WorkflowResultType.Auto) {
            return undefined;
        }
        else {
            var result = cloneDeep(this.result) || new WorkflowResult();
            result.parameters = this.controls.parameters.value;
            result.inputs = this.controls.inputs.value;
            result.array = this.controls.array.value;
            if (this.controls.array_input.value) {
                result.arrayInput = new Input().deserialize(this.controls.array_input.value);
            }
            else {
                result.arrayInput = undefined;
            }
            return result;
        }
    };
    return CustomizeWorkflowResultForm;
}(FormGroup));
export { CustomizeWorkflowResultForm };

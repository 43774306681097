import { Injectable, Injector, NgZone } from '@angular/core';
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ModelData } from '@modules/customize';
import { ListModelDescriptionDataSourceControl, ModelDescriptionDataSourceControl } from '@modules/customize-bar';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';

import { PageQueryControl } from './page-query-control.service';

@Injectable()
export class PageQueryArray extends FormArray {
  controls: PageQueryControl[];

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    private injector: Injector
  ) {
    super([]);
  }

  deserialize(value: ModelData[]) {
    const controls = (value || [])
      .map(item => {
        const control = this.createControl();

        if (!control) {
          return;
        }

        control.deserialize(item);
        return control;
      })
      .filter(item => item != undefined);

    this.setControls(controls);
  }

  setControls(controls: PageQueryControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: PageQueryControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  prependControl(options: { value?: Object; instance?: ModelData } = {}) {
    const control = this.createControl();

    if (!control) {
      return;
    }

    control.deserialize(options.instance);

    if (options.value) {
      control.patchValue(options.value);
      this.markAsPristine();
    }

    this.insert(0, control);
    return control;
  }

  appendControl(options: { value?: Object; instance?: ModelData } = {}) {
    const control = this.createControl();

    if (!control) {
      return;
    }

    control.deserialize(options.instance);

    if (options.value) {
      control.patchValue(options.value);
      this.markAsPristine();
    }

    this.push(control);
    return control;
  }

  createControl(): PageQueryControl {
    return Injector.create({
      providers: [
        {
          provide: ModelDescriptionDataSourceControl,
          deps: [
            ModelDescriptionStore,
            CurrentEnvironmentStore,
            ProjectSettingsService,
            ResourceControllerService,
            ResourceGeneratorResolver,
            ModelService,
            ActionService,
            QueryService,
            NotificationService,
            NgZone
          ]
        },
        {
          provide: ListModelDescriptionDataSourceControl,
          deps: [
            ModelDescriptionStore,
            CurrentEnvironmentStore,
            ProjectSettingsService,
            ResourceControllerService,
            ResourceGeneratorResolver,
            ModelService,
            ActionService,
            QueryService,
            NotificationService,
            NgZone
          ]
        },
        {
          provide: PageQueryControl,
          deps: [
            CurrentEnvironmentStore,
            ModelDescriptionStore,
            ResourceControllerService,
            ModelDescriptionDataSourceControl,
            ListModelDescriptionDataSourceControl
          ]
        }
      ],
      parent: this.injector
    }).get<PageQueryControl>(PageQueryControl);
  }

  serialize(): ModelData[] {
    return this.controls.map(control => control.serialize());
  }
}

<div class="view-editor-header-segmented-button-container">
  <app-view-editor-tools-item
    *ngFor="let tool of tools; let i = index; let first = first; let last = last"
    [tool]="tool"
    [opened]="toolOpenedIndex == i"
    [first]="first"
    [last]="last"
    [elementTemplates]="elementTemplates"
    [elementTemplatesLoading]="elementTemplatesLoading"
    [viewContext]="viewContext"
    (openTool)="setToolOpenedIndex(i)"
    (closeTool)="setToolOpenedIndex(undefined)"
  ></app-view-editor-tools-item>
</div>

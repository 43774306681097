import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ModelDataType, ViewContext } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, QueryBuilderService, SqlResultsSection } from '@modules/queries-components';
import { controlValid } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ModelDescriptionDataSourceEditComponent } from '../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarModelDataControl } from '../customize-bar-model-data.control';
import { CustomizeBarPageEditForm } from '../customize-bar-page-edit.form';
var CustomizeBarPageEditQueryComponent = /** @class */ (function () {
    function CustomizeBarPageEditQueryComponent(queryBuilderService, analyticsService, customizeBarContext) {
        this.queryBuilderService = queryBuilderService;
        this.analyticsService = analyticsService;
        this.customizeBarContext = customizeBarContext;
        this.deleteEnabled = false;
        this.delete = new EventEmitter();
        this.modelDataTypes = ModelDataType;
        this.dataSourceTypes = DataSourceType;
    }
    CustomizeBarPageEditQueryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control.controls.detail_data_source
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.object,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.control.controls.detail_data_source.getResource$().pipe(skip(1)), this.control.controls.detail_data_source.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.object,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        controlValid(this.control.controls.detail_data_source)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
                ComponentTypeID: _this.object
            });
        });
        controlValid(this.control.controls.detail_data_source.controls.query_resource)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
                ComponentTypeID: _this.object
            });
        });
        controlValid(this.control.controls.detail_data_source.controls.query_inputs)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
                ComponentTypeID: _this.object
            });
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
            ComponentTypeID: this.object
        });
    };
    CustomizeBarPageEditQueryComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarPageEditQueryComponent.prototype.back = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarPageEditQueryComponent.prototype.cancel = function () {
        this.back();
    };
    CustomizeBarPageEditQueryComponent.prototype.submit = function () {
        this.back();
    };
    CustomizeBarPageEditQueryComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return CustomizeBarPageEditQueryComponent;
}());
export { CustomizeBarPageEditQueryComponent };

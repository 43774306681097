<app-customize-bar-header [title]="'Emails'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize Email templates you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section
    [interactive]="!domain || !domain.custom"
    [disabled]="!domain || !domain.custom"
    (click)="onCustomDomainRequiredClick()"
  >
    <div class="sidebar__element">
      <app-sidebar-field [label]="'From Email address'">
        <div class="input-icon-wrapper">
          <div
            class="input input_fill input_template input_clickable input_icon-right"
            (mouseenter)="emailsFromHover.next(true)"
            (mouseleave)="emailsFromHover.next(false)"
            (click)="editEmailsFrom()"
          >
            {{ domain?.emailConfigFrom || appConfigService.defaultEmailFrom }}
          </div>
          <div class="input-icon input-icon_right icon-pen" [class.input-icon_bright]="emailsFromHover | async"></div>
        </div>

        <ng-container description>
          This address will be used for all transactional Emails and messages send through
          <strong class="no-wrap">App built-ins <span class="icon-arrow_forward_2"></span> Send email</strong>
        </ng-container>
      </app-sidebar-field>
    </div>

    <div *ngIf="!domain || !domain.custom" class="sidebar__element">
      <app-alert [warning]="true"> Only when using <strong class="no-wrap">Custom domain</strong> </app-alert>
    </div>
  </app-sidebar-section>

  <app-sidebar-section
    [title]="'Transactional emails'"
    [description]="'Customize templates text and content'"
    [interactive]="!domain"
    [disabled]="!domain"
    (click)="onCustomDomainRequiredClick()"
  >
    <div class="sidebar__list">
      <div *ngIf="emailItemsLoading" class="sidebar-list">
        <div *ngFor="let item of [1, 2, 3]" cdkDrag class="sidebar-list__item">
          <div class="sidebar-list-item">
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">
                <span [class.loading-animation]="true"><span class="stub-text">email template</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!emailItemsLoading && emailItems" class="sidebar-list">
        <div *ngFor="let item of emailItems" class="sidebar-list__item">
          <div class="sidebar-list-item sidebar-list-item_clickable" (click)="editEmailItem(item)">
            <div *ngIf="item.info.icon" class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon" [ngClass]="'icon-' + item.info.icon"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">{{ item.info.label }}</div>
              <div *ngIf="item.custom" class="sidebar-list-item__description">
                Using custom template
              </div>
            </div>
            <div class="sidebar-list-item__right">
              <a
                *ngIf="item.custom"
                href="javascript:void(0)"
                class="sidebar-list-item__action sidebar-list-item__action_button icon-repeat"
                [appTip]="'Reset to default'"
                [appTipOptions]="{ side: 'left' }"
                (click)="$event.stopPropagation(); resetEmailItem(item)"
              >
              </a>
              <span
                *ngIf="!item.custom"
                class="sidebar-list-item__action sidebar-list-item__action_button icon-edit"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-sidebar-section>
</div>

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { WorkflowStepType } from '../workflow-step-type';
import { registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class ExitWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Exit;
  public errorText: Input;

  deserialize(data: Object) {
    super.deserialize(data);

    if (data['error_text']) {
      this.errorText = new Input().deserialize(data['error_text']);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      error_text: this.errorText ? this.errorText.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Exit';
  }

  get analyticsName(): string {
    return 'exit';
  }

  getIcon() {
    return 'log_out';
  }
}

registerWorkflowStepType(WorkflowStepType.Exit, ExitWorkflowStep);

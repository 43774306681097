export enum PaymentMethod {
  Card = 'card'
}

export enum CardType {
  Mastercard = 'master',
  Visa = 'visa',
  AmericanExpress = 'american_express',
  Discover = 'discover',
  JCB = 'jcb',
  MastercardMaestro = 'maestro',
  DinersClub = 'diners_club',
  UnionPay = 'unionpay'
}

export class PaymentDetails {
  public paymentMethod: PaymentMethod;
  public cardType: CardType;
  public cardLastFourDigits: string;
  public cardExpiryDate: string;
  public updateUrl: string;

  deserialize(data: Object): PaymentDetails {
    this.paymentMethod = data['payment_method'];
    this.cardType = data['card_type'];
    this.cardLastFourDigits = data['card_last_four_digits'];
    this.cardExpiryDate = data['card_expiry_date'];
    this.updateUrl = data['update_url'];

    return this;
  }

  get cardTypeStr() {
    if (this.cardType == CardType.Mastercard) {
      return 'Mastercard';
    } else if (this.cardType == CardType.Visa) {
      return 'Visa';
    } else if (this.cardType == CardType.AmericanExpress) {
      return 'American Express';
    } else if (this.cardType == CardType.Discover) {
      return 'Discover';
    } else if (this.cardType == CardType.JCB) {
      return 'JCB';
    } else if (this.cardType == CardType.MastercardMaestro) {
      return 'Mastercard Maestro';
    } else if (this.cardType == CardType.DinersClub) {
      return 'Diners Club';
    } else if (this.cardType == CardType.UnionPay) {
      return 'Union Pay';
    }
  }
}

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class IFrameElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.IFrame;
  public url: Input;
  public width: number;
  public height: number;
  public border = false;

  deserialize(data: Object): IFrameElementItem {
    super.deserialize(data);
    this.url = this.params['url'];
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.border = this.params['border'];

    if (this.params['url']) {
      // Backward compatibility
      if (typeof this.params['url'] == 'string') {
        this.url = new Input().deserializeFromStatic('value', this.params['url']);
      } else {
        this.url = new Input().deserialize(this.params['url']);
      }
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['url'] = this.url ? this.url.serialize() : null;
    this.params['width'] = this.width;
    this.params['height'] = this.height;
    this.params['border'] = this.border;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'embed external page';
  }

  get analyticsName(): string {
    return 'iframe';
  }

  defaultName() {
    return 'iFrame';
  }
}

registerElementForType(ElementType.IFrame, IFrameElementItem);

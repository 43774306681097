import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { flattenItemOptions } from '../custom-select.component';
var CustomSelectDropdownComponent = /** @class */ (function () {
    function CustomSelectDropdownComponent(cd) {
        this.cd = cd;
        this.items = [];
        this.searchEnabled = false;
        this.capitalize = true;
        this.panelClasses = [];
        this.optionClick = new EventEmitter();
        this.buttonClick = new EventEmitter();
        this.displaySearch = false;
        this.search = '';
        this.filteredItems = [];
    }
    CustomSelectDropdownComponent.prototype.ngOnInit = function () { };
    CustomSelectDropdownComponent.prototype.ngOnChanges = function (changes) {
        if (changes['items']) {
            this.displaySearch = this.searchEnabled && this.items && this.items.length > 4;
            this.updateFilteredItems();
        }
    };
    CustomSelectDropdownComponent.prototype.updateFilteredItems = function () {
        var _this = this;
        var items = this.items || [];
        var filteredItems = this.search
            ? items.filter(function (item) {
                if (item.option) {
                    return item.option.name.toLowerCase().indexOf(_this.search.toLowerCase()) != -1;
                }
                else if (item.button) {
                    return item.button.label.toLowerCase().indexOf(_this.search.toLowerCase()) != -1;
                }
                else {
                    return false;
                }
            })
            : items;
        var isChildrenActive = function (item) {
            return (item.children && !!flattenItemOptions(item.children).find(function (childOption) { return childOption === _this.currentOption; }));
        };
        this.filteredItems = filteredItems.map(function (item) {
            if (item.option) {
                return {
                    item: item,
                    active: item.option === _this.currentOption || isChildrenActive(item)
                };
            }
            else if (item.button) {
                return {
                    item: item,
                    active: isChildrenActive(item)
                };
            }
        });
        this.cd.markForCheck();
    };
    Object.defineProperty(CustomSelectDropdownComponent.prototype, "panelClassesStr", {
        get: function () {
            var classes = this.panelClasses.slice();
            // if (this.searchEnabled) {
            classes.push('mat-menu-panel_no-top-padding');
            // }
            // if (this.filteredOptions.find(item => item.data && item.data['stickyBottom'])) {
            classes.push('mat-menu-panel_no-bottom-padding');
            // }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    return CustomSelectDropdownComponent;
}());
export { CustomSelectDropdownComponent };

import { ChangeDetectorRef, OnInit } from '@angular/core';
import { SideConstraint, SizeConstraint } from '@modules/views';
import { ConstraintsControl } from '../../controls/constraints.control';
var Control;
(function (Control) {
    Control["Top"] = "top";
    Control["Right"] = "right";
    Control["Bottom"] = "bottom";
    Control["Left"] = "left";
    Control["Width"] = "width";
    Control["Height"] = "height";
})(Control || (Control = {}));
var ConstraintsControlComponent = /** @class */ (function () {
    function ConstraintsControlComponent(cd) {
        var _a;
        this.cd = cd;
        this.controlsState = (_a = {},
            _a[Control.Top] = { hover: false, changedAfterHover: false },
            _a[Control.Right] = { hover: false, changedAfterHover: false },
            _a[Control.Bottom] = { hover: false, changedAfterHover: false },
            _a[Control.Left] = { hover: false, changedAfterHover: false },
            _a[Control.Width] = { hover: false, changedAfterHover: false },
            _a[Control.Height] = { hover: false, changedAfterHover: false },
            _a);
        this.sideConstraints = SideConstraint;
        this.sizeConstraints = SizeConstraint;
        this.controls = Control;
    }
    ConstraintsControlComponent.prototype.ngOnInit = function () { };
    ConstraintsControlComponent.prototype.onControlHover = function (control) {
        this.controlsState[control].hover = true;
        this.controlsState[control].changedAfterHover = false;
        this.cd.markForCheck();
    };
    ConstraintsControlComponent.prototype.onControlOut = function (control) {
        this.controlsState[control].hover = false;
        this.controlsState[control].changedAfterHover = false;
        this.cd.markForCheck();
    };
    ConstraintsControlComponent.prototype.onControlChange = function (control) {
        this.controlsState[control].changedAfterHover = true;
        this.cd.markForCheck();
    };
    ConstraintsControlComponent.prototype.isControlHoverDisplay = function (control) {
        return this.controlsState[control].hover && !this.controlsState[control].changedAfterHover;
    };
    ConstraintsControlComponent.prototype.isSidesHover = function () {
        var _this = this;
        return [Control.Top, Control.Right, Control.Bottom, Control.Left].some(function (item) { return _this.controlsState[item].hover; });
    };
    return ConstraintsControlComponent;
}());
export { ConstraintsControlComponent };

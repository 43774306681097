import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppDrag, AppDropListGroup } from '@common/drag-drop2';
import { DynamicComponentArguments } from '@common/dynamic-component';
import { SessionStorage } from '@core';
import { ElementItem, ViewContext } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { CurrentEnvironmentStore, Resource, ResourceTypeItem } from '@modules/projects';
import { CUSTOMIZE_BAR_OPEN_RESOURCE_TEMPLATES } from '@modules/template-components';
import { scrollTo } from '@shared';

import { CustomizeBarItem } from '../../data/customize-bar-item';

export enum CustomizeBarTab {
  Components,
  Templates
}

@Component({
  selector: 'app-change-customize-bar',
  templateUrl: './change-customize-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() context: ViewContext;
  @Input() dropListGroups: AppDropListGroup[] = [];
  @Input() customizeDisabled = false;
  @ViewChild('root') root: ElementRef;
  @ViewChild('highlight') highlight: ElementRef;

  tabItems = [
    { tab: CustomizeBarTab.Components, label: 'Components' },
    { tab: CustomizeBarTab.Templates, label: 'Templates' }
  ];
  tabs = CustomizeBarTab;
  selectedTab = CustomizeBarTab.Components;
  settingsComponents: DynamicComponentArguments[] = [];
  templatesInitialResource: Resource;
  templatesInitialResourceType: ResourceTypeItem;
  firstVisible = true;
  tl = new TimelineMax();

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customizeBarContext: CustomizeBarContext,
    private sessionStorage: SessionStorage,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.detectChanges();
      this.scrollToTop();
    });

    this.customizeBarContext.highlighted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.tl.clear().fromTo(
        this.highlight.nativeElement,
        1.4,
        {
          opacity: 0.5
        },
        {
          opacity: 0,
          ease: Power2.easeOut
        }
      );
    });

    this.sessionStorage
      .get$(CUSTOMIZE_BAR_OPEN_RESOURCE_TEMPLATES)
      .pipe(
        switchMap(resourceName => {
          if (!resourceName) {
            return of(undefined);
          }

          return this.currentEnvironmentStore
            .getResourcesFirst()
            .pipe(map(resources => resources.find(item => item.uniqueName == resourceName)));
        }),
        untilDestroyed(this)
      )
      .subscribe(resource => {
        this.sessionStorage.remove(CUSTOMIZE_BAR_OPEN_RESOURCE_TEMPLATES);

        if (resource) {
          this.setSelectedTemplatesTabResource(resource);
        }
      });
  }

  ngOnDestroy(): void {}

  setSelectedTab(tab: CustomizeBarTab) {
    this.selectedTab = tab;
    this.templatesInitialResource = undefined;
    this.templatesInitialResourceType = undefined;
    this.firstVisible = false;
    this.cd.detectChanges();
    this.scrollToTop();
  }

  setSelectedTemplatesTabResource(resource: Resource) {
    this.selectedTab = CustomizeBarTab.Templates;
    this.templatesInitialResource = resource;
    this.templatesInitialResourceType = undefined;
    this.firstVisible = false;
    this.cd.detectChanges();
    this.scrollToTop();
  }

  setSelectedTemplatesTabResourceType(resource: ResourceTypeItem) {
    this.selectedTab = CustomizeBarTab.Templates;
    this.templatesInitialResource = undefined;
    this.templatesInitialResourceType = resource;
    this.firstVisible = false;
    this.cd.detectChanges();
    this.scrollToTop();
  }

  scrollToTop(animated = true) {
    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  isDroppable(item: AppDrag<CustomizeBarItem | ElementItem>): boolean {
    return !(item.data instanceof ElementItem);
  }
}

<app-toast
  [icon]="'email'"
  [uniqueName]="'email_verification'"
  [expands]="!sent"
  [buttonsCenter]="true"
  [shift]="shift"
>
  <ng-container data-title>
    Please verify your email address
  </ng-container>

  <ng-container data-description>
    <p>
      We have sent the validation link to <strong>{{ currentUserStore.instance?.email }}</strong>
      to verify your email address. If you haven't received email from use you can resend it using the button below.
    </p>
  </ng-container>

  <ng-container data-buttons>
    <div *ngIf="!sent" class="toast2__button" (click)="resend()">
      <div class="toast2__button-icon icon-repeat"></div>
      <div class="toast2__button-label">Resend verification email</div>
    </div>
  </ng-container>
</app-toast>

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ResourceType } from '@modules/projects';
import { ResourceControllerService, SmartSuiteResourceController } from '@modules/resources';

@Injectable()
export class SmartSuiteSolutionSource extends SelectSource {
  controller: SmartSuiteResourceController;
  accountId: string;
  key: string;
  loadedPage = false;

  constructor(private resourceControllerService: ResourceControllerService) {
    super();
    this.controller = this.resourceControllerService.get<SmartSuiteResourceController>(ResourceType.SmartSuite);
  }

  fetch(searchQuery: string, markLoaded = true): Observable<Option[]> {
    return this.controller.getSolutions({ accountId: this.accountId, key: this.key }).pipe(
      map(result => {
        return result
          .map(item => {
            return {
              value: item.id,
              name: item.name
            };
          })
          .filter(item => {
            if (!searchQuery) {
              return true;
            }

            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
          });
      }),
      tap(() => {
        if (markLoaded) {
          this.loadedPage = true;
        }
      })
    );
  }

  fetchByValue(value: string): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    return this.fetch('', false).pipe(map(items => items.find(i => i.value == value)));
  }

  isFetchAvailable(): boolean {
    return !this.loadedPage;
  }

  resetPagination() {
    this.loadedPage = false;
  }

  setStaticOptions(options: Option[]) {}
}

<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<app-layout
  *ngIf="!domainLoading"
  class="theme-enabled"
  appDomainThemeOptions
  [domain]="domain"
  [layout]="domain?.signUpLayout || defaultLayout"
  [backgroundImage]="domain?.signUpBackgroundImage"
  [backgroundColor]="domain?.signUpBackgroundColor"
  [fillScreen]="fillScreen"
  [preview]="preview"
>
  <app-page-block [marginSize]="'l'" [paddingSize]="'none'">
    <app-page-header
      *ngIf="inviteLoading || publicInviteLoading"
      [marginSize]="'l'"
      [first]="layout.startsWith('poster_')"
    >
      <app-page-title [align]="'center'" [strong]="true">
        <span [class.loading-animation]="true"><span class="stub-text">Get Started</span></span>
      </app-page-title>
    </app-page-header>

    <ng-container *ngIf="!inviteLoading && !publicInviteLoading">
      <ng-template #project_logo>
        <app-project-logo
          *ngIf="isWhiteLabel && domain && (domain.logo || domain.initials)"
          [color]="domain.logoColor ? domain.logoColor : ''"
          [logo]="domain.logo ? domain.logo : ''"
          [initials]="domain.initials ? domain.initials : ''"
        ></app-project-logo>

        <app-project-logo
          *ngIf="!isWhiteLabel"
          [logo]="'/assets/images/logo.svg' | appDeployUrl"
          [size]="'sm'"
        ></app-project-logo>
      </ng-template>

      <app-page-header
        *ngIf="!invite && !publicInvite && headerHasContent"
        [marginSize]="'l'"
        [first]="layout.startsWith('poster_')"
      >
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title *ngIf="domain?.signInTitle" [align]="'center'" [strong]="true">
          {{ domain.signInTitle }}
        </app-page-title>

        <app-page-description *ngIf="domain?.signInDescription" [align]="'center'">
          {{ domain.signInDescription }}
        </app-page-description>

        <app-page-title *ngIf="!domain" [align]="'center'" [strong]="true">
          {{ title }}!
          <div class="auth-form__accent">{{ 'Welcome Back.' | localize }}</div>
        </app-page-title>
      </app-page-header>

      <app-page-header *ngIf="invite" [marginSize]="'l'" [first]="layout.startsWith('poster_')">
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title [align]="'center'" [strong]="true">
          Join <span class="auth-form__accent">{{ invite.project.name }}</span>
          <ng-container *ngIf="!isWhiteLabel"> on Jet</ng-container>
        </app-page-title>

        <div class="auth-form__banner">
          <ng-container *ngIf="invite.inviter">
            <strong>{{ invite.inviter.strFull }} ({{ invite.inviter.email }})</strong> has invited you to join
          </ng-container>
          <ng-container *ngIf="!invite.inviter">
            You were invited to join
          </ng-container>
          <ng-container *ngIf="!isWhiteLabel"> the Jet workspace </ng-container>
          <strong>{{ invite.project.name }}</strong
          >.
        </div>
      </app-page-header>

      <app-page-header *ngIf="publicInvite" [marginSize]="'l'" [first]="layout.startsWith('poster_')">
        <app-page-logo [align]="'center'">
          <ng-container *ngTemplateOutlet="project_logo"></ng-container>
        </app-page-logo>

        <app-page-title [align]="'center'" [strong]="true">
          Join <span class="auth-form__accent">{{ publicInvite.project.name }}</span>
          <ng-container *ngIf="!isWhiteLabel"> on Jet</ng-container>
        </app-page-title>

        <div class="auth-form__banner">
          You are joining
          <ng-container *ngIf="!isWhiteLabel"> the Jet workspace </ng-container>
          <strong>{{ publicInvite.project.name }}</strong
          >.
        </div>
      </app-page-header>
    </ng-container>

    <app-page-block *ngIf="(form?.form | appFormErrors)?.length">
      <div class="error-notification">
        <div class="error-notification__background"></div>
        <div class="error-notification__content">
          <a href="javascript:void(0)" (click)="cleanErrorForm.emit()" class="error-notification__close icon-close"></a>
          <div class="error-notification__title">{{ 'Unable to Sign In' | localize }}</div>
          <div *ngFor="let error of form.form | appFormErrors" class="error-notification__description">
            {{ error }}
          </div>
        </div>
      </div>
    </app-page-block>

    <ng-container *ngIf="currentSSO && (!domain?.disableEmailSignIn || domain?.ssoSettings.length > 1)">
      <app-page-block>
        <div class="button2" (click)="resetSSOLogin()">
          <div class="button2__icon button2__icon_left icon-arrow_backward_2"></div>
          <div class="button2__label">
            Back to Sign in options
          </div>
        </div>
      </app-page-block>

      <app-page-block>
        <app-page-separator>
          <ng-template [appTr]="{ provider: currentSSO.name }" let-ctx>Sign in with {{ ctx['provider'] }}</ng-template>
        </app-page-separator>
      </app-page-block>
    </ng-container>

    <ng-container *ngIf="!currentSSO">
      <app-page-block *ngIf="isGoogleSignEnabled || isFacebookSignEnabled">
        <app-page-columns [innerPaddingSize]="'xs'">
          <app-page-column *ngIf="isGoogleSignEnabled">
            <div class="button2" (click)="socialLogin.emit('google-oauth2')">
              <div class="button2__icon button2__icon_left button2__icon_image_google"></div>
              <div class="button2__label">Google</div>
            </div>
          </app-page-column>

          <app-page-column *ngIf="isFacebookSignEnabled">
            <div class="button2" (click)="socialLogin.emit('facebook')">
              <div class="button2__icon button2__icon_left button2__icon_image_facebook"></div>
              <div class="button2__label">Facebook</div>
            </div>
          </app-page-column>
        </app-page-columns>
      </app-page-block>

      <ng-container *ngIf="domain?.ssoSettings.length">
        <app-page-block *ngFor="let item of domain.ssoSettings">
          <div class="button2" (click)="startSSOLogin(item)">
            <div
              *ngIf="item.image"
              class="button2__icon button2__icon_left"
              [style.background-image]="'url(' + item.image + ')'"
            ></div>
            <div *ngIf="!item.image" class="button2__icon button2__icon_left icon-earth_planet"></div>
            <div class="button2__label">
              <ng-template [appTr]="{ provider: item.name }" let-ctx>Sign in with {{ ctx['provider'] }}</ng-template>
            </div>
          </div>
        </app-page-block>
      </ng-container>

      <app-page-block *ngIf="isSignInWithEnabled && isFormEnabled">
        <app-page-separator>{{ 'or login with' | localize }}</app-page-separator>
      </app-page-block>
    </ng-container>

    <form *ngIf="isFormEnabled && form" [formGroup]="form.form" (ngSubmit)="onSubmit()">
      <app-page-block>
        <app-text-control
          [control]="form.form.controls['username']"
          [iconLeft]="'user'"
          [placeholder]="'Enter E-mail' | localize"
          [autofocus]="true"
          [autocomplete]="'username'"
        ></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-password-control
          [control]="form.form.controls['password']"
          [iconLeft]="'lock_close'"
          [placeholder]="'Enter password' | localize"
          [autocomplete]="'current-password'"
        ></app-password-control>
      </app-page-block>

      <app-page-block>
        <button
          type="submit"
          class="button button_bigger button_fill button_no-margin"
          [class.button_primary]="!domain?.signUpAccentColor"
          [appButtonTintColor]="domain?.signUpAccentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
          [disabled]="submitLoading"
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__label">{{ 'Login' | localize }}</span>
        </button>
      </app-page-block>
    </form>

    <ng-container *ngIf="isFormEnabled && !form">
      <app-page-block>
        <app-text-control [iconLeft]="'user'" [placeholder]="'Enter E-mail' | localize"></app-text-control>
      </app-page-block>

      <app-page-block>
        <app-text-control [iconLeft]="'lock_close'" [placeholder]="'Enter password' | localize"></app-text-control>
      </app-page-block>

      <app-page-block>
        <button
          type="button"
          class="button button_bigger button_fill button_no-margin"
          [class.button_primary]="!domain?.signUpAccentColor"
          [appButtonTintColor]="domain?.signUpAccentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
        >
          <app-loader-small *ngIf="submitLoading" class="button__icon"></app-loader-small>
          <span *ngIf="!submitLoading" class="button__label">{{ 'Login' | localize }}</span>
        </button>
      </app-page-block>
    </ng-container>

    <app-page-block *ngIf="!domain" style="margin-bottom: -10px;">
      <div class="auth-form__text">
        {{ 'By signing in you’re okay with our' | localize }} <br />
        <a href="https://www.jetadmin.io/terms-of-service" class="auth-form__link" target="_blank">
          {{ 'Terms of Service' | localize }}
        </a>
        {{ 'and' | localize }}
        <a href="https://www.jetadmin.io/privacy-policy" class="auth-form__link" target="_blank">
          {{ 'Privacy Policy' | localize }}
        </a>
      </div>
    </app-page-block>

    <app-page-block *ngIf="(domain && domain?.termsOfService) || domain?.privacyPolicy" style="margin-bottom: -10px;">
      <div class="auth-form__text">
        {{ 'By signing in you’re okay with our' | localize }} <br />
        <a *ngIf="domain?.termsOfService" [href]="domain?.termsOfService" class="auth-form__link" target="_blank">
          {{ 'Terms of Service' | localize }}
        </a>
        <ng-container *ngIf="domain?.termsOfService && domain?.privacyPolicy"> and </ng-container>
        <a *ngIf="domain?.privacyPolicy" [href]="domain?.privacyPolicy" class="auth-form__link" target="_blank">
          {{ 'Privacy Policy' | localize }}
        </a>
      </div>
    </app-page-block>
  </app-page-block>

  <ng-container *ngIf="layout == 'form'" data-layout-footer>
    <a
      *ngIf="!domain?.disableEmailSignUp || invite"
      [routerLink]="signUpLink"
      [queryParams]="{ sso: undefined }"
      queryParamsHandling="merge"
      class="link-block form-layout__footer-item"
      [appClickEvent]="analyticsEvents.SignUp.ClickedCreateAccount"
    >
      {{ 'Create new account' | localize }}
    </a>

    <span
      *ngIf="((!domain?.disableEmailSignUp && !domain?.disableEmailSignIn) || invite) && !currentSSO"
      class="form-layout__footer-separator"
    ></span>

    <a
      *ngIf="(!domain?.disableEmailSignIn || invite) && !currentSSO"
      [routerLink]="restoreLink"
      [queryParams]="{ sso: undefined }"
      queryParamsHandling="merge"
      class="link-block form-layout__footer-item"
    >
      {{ 'Forgot my password' | localize }}
    </a>
  </ng-container>

  <app-page-block *ngIf="layout != 'form'" [marginSize]="'l'" data-layout-footer>
    <app-page-columns>
      <app-page-column *ngIf="!domain?.disableEmailSignUp || invite">
        <a
          [routerLink]="signUpLink"
          [queryParams]="{ sso: undefined }"
          queryParamsHandling="merge"
          class="link-block"
          [appClickEvent]="analyticsEvents.SignUp.ClickedCreateAccount"
        >
          {{ 'Create new account' | localize }}
        </a>
      </app-page-column>

      <app-page-column *ngIf="(!domain?.disableEmailSignIn || invite) && !currentSSO">
        <a [routerLink]="restoreLink" [queryParams]="{ sso: undefined }" queryParamsHandling="merge" class="link-block">
          {{ 'Forgot my password' | localize }}
        </a>
      </app-page-column>
    </app-page-columns>
  </app-page-block>
</app-layout>

<app-custom-code
  *ngIf="domain"
  [appendScripts]="domain.appendScripts"
  [appendStyles]="domain.appendStyles"
></app-custom-code>

<div *ngIf="screenLoader" class="login-loader">
  <div class="login-loader__loader">
    <div class="table__loading">
      <app-loader [title]="'Loading' | localize">
        {{ 'Please wait...' | localize }}
      </app-loader>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicComponent } from './components/dynamic/dynamic.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicComponent],
  exports: [DynamicComponent]
})
export class DynamicComponentModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { delayWhen, first, map, switchMap, tap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { MenuGroup, MenuGroupItem, MenuPagesService, MenuService, MenuSettingsStore } from '@modules/menu';
import { NavigationService } from '@modules/navigation';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { AppLinkActiveDirective, RoutingService } from '@modules/routing';
import { PageTemplatesController } from '@modules/template-queries';
import { isSet, KeyboardEventKeyCode, objectsSortPredicate } from '@shared';

@Component({
  selector: 'app-customize-menu-pages',
  templateUrl: './customize-menu-pages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuPagesComponent implements OnInit, OnDestroy {
  @Output() startInteracting = new EventEmitter<void>();
  @Output() finishInteracting = new EventEmitter<void>();
  @Output() closeMenu = new EventEmitter<void>();

  @ViewChildren(AppLinkActiveDirective) linkActiveDirectives = new QueryList<AppLinkActiveDirective>();

  loading = false;
  editing = false;
  selectedPages: { [k: string]: boolean } = {};
  lastSelectedPage: MenuGroupItem;
  createPageLoading = false;
  deletePagesLoading = false;

  search = '';
  searchUpdated = new Subject<string>();
  items: MenuGroupItem[] = [];
  groups: MenuGroup[] = [];
  filteredQuery = '';
  filteredItems: MenuGroupItem[] = [];
  filteredGroups: MenuGroup[] = [];
  analyticsEvents = AnalyticsEvent;
  analyticsSource = 'menu_pages';

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private menuService: MenuService,
    private menuSettingsStore: MenuSettingsStore,
    private notificationService: NotificationService,
    private viewSettingsQueries: ViewSettingsQueries,
    private pageTemplatesController: PageTemplatesController,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private menuPagesService: MenuPagesService,
    private navigationService: NavigationService,
    private dialogService: DialogService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getGroups();
    this.searchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateFiltered());
  }

  ngOnDestroy(): void {}

  getGroups() {
    this.loading = true;
    this.cd.markForCheck();

    this.menuPagesService
      .getGroups()
      .pipe(untilDestroyed(this))
      .subscribe(
        groups => {
          this.items = groups.reduce((acc, group) => {
            acc.push(...group.items);
            return acc;
          }, []);
          this.groups = groups;
          this.updateFiltered();
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  setEditing(editing: boolean) {
    if (this.editing == editing) {
      return;
    }

    this.editing = editing;
    this.selectedPages = {};
    this.lastSelectedPage = undefined;
    this.cd.markForCheck();

    if (this.editing) {
      this.startInteracting.emit();
    } else {
      this.finishInteracting.emit();
    }
  }

  createPage() {
    // this.routing.navigateApp(this.currentProjectStore.instance.newPageLink);
    this.pageTemplatesController
      .open({
        cancelEnabled: true,
        newPage: true,
        analyticsSource: this.analyticsSource
      })
      // .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.startPage) {
          this.routing.navigateApp(result.startPage.link);
        } else if (result.newPage) {
          this.routing.navigateApp(this.currentProjectStore.instance.newPageLink);
        }
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.PageCreateClicked);
  }

  deleteSelectedPages() {
    const pageUids: string[] = this.selectedPagesUids;
    let deletedCurrentRoute: string;

    return this.dialogService
      .dialog({
        title: `Delete pages (${pageUids.length})`,
        description: 'Are you sure want to delete selected pages?',
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'yes',
            label: 'OK',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => {
              if (!pageUids.length) {
                return of(0);
              }

              this.deletePagesLoading = true;
              this.cd.markForCheck();

              deletedCurrentRoute = this.linkActiveDirectives
                .filter(item => item.isActive)
                .map(item => {
                  const menuItem = item.appLinkActiveData['item'] as MenuGroupItem;
                  return menuItem ? menuItem.pageUid : undefined;
                })
                .find(item => pageUids.includes(item));

              return this.deleteSelectedPagesProcess(pageUids);
            }
          }
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (result.executorResult) {
            this.deletePagesLoading = false;
            this.setEditing(false);
            this.cd.markForCheck();

            if (deletedCurrentRoute) {
              const handler = this.customizeService.handler;
              const handlerPage = handler && handler.getPage ? handler.getPage() : undefined;

              if (handlerPage && handlerPage.uid == deletedCurrentRoute) {
                this.customizeService.unsetHandler(handler);
              }

              this.routing.navigateApp(this.currentProjectStore.instance.homeLink);
            }
          }
        },
        () => {
          this.deletePagesLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  deleteSelectedPagesProcess(pageUids: string[]): Observable<number> {
    return combineLatest(
      pageUids.map(uid => {
        const viewSettings = new ViewSettings();
        viewSettings.uid = uid;
        return this.viewSettingsService
          .delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            viewSettings
          )
          .pipe(
            tap(() => {
              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                PageID: viewSettings.uniqueName
              });
            })
          );
      })
    ).pipe(
      map(() => pageUids.length),
      delayWhen(() => this.viewSettingsStore.getFirst(true)),
      delayWhen(() => this.menuSettingsStore.getFirst(true))
    );
  }

  updateFiltered() {
    const compareObj = (links: MenuGroupItem[]): Object => {
      return links.map(item => ({ title: item.title, link: item.link })).sort(objectsSortPredicate('title'));
    };

    let result = this.groups;
    const search = this.search.toLowerCase().trim();
    const duplicateGroup = this.groups.find(item => item.duplicate);
    const otherItems = this.groups
      .filter(item => !item.duplicate)
      .reduce((acc, item) => {
        acc.push(...item.items);
        return acc;
      }, []);

    if (isSet(search)) {
      result = result
        .filter(group => !group.searchIgnore)
        .map(group => {
          return {
            ...group,
            items: group.items.filter(item => {
              return item.title.toLowerCase().includes(search);
            })
          };
        });
    } else if (duplicateGroup && isEqual(compareObj(duplicateGroup.items), compareObj(otherItems))) {
      result = [duplicateGroup];
    }

    this.filteredQuery = search;
    this.filteredItems = result.reduce((acc, group) => {
      acc.push(...group.items);
      return acc;
    }, []);
    this.filteredGroups = result.filter(group => group.items.length || group.showEmpty);
    this.cd.markForCheck();
  }

  resetSearch() {
    this.search = '';
    this.updateFiltered();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.resetSearch();
    } else {
      this.searchUpdated.next();
    }
  }

  onItemClick(clickItem: MenuGroupItem, event: MouseEvent) {
    if (!this.editing) {
      this.closeMenu.emit();
    } else {
      event.preventDefault();
      event.stopPropagation();

      if (event.shiftKey) {
        const allItems = this.filteredGroups.reduce((acc, item) => {
          acc.push(...item.items);
          return acc;
        }, []);

        const fromIndex = this.lastSelectedPage ? allItems.indexOf(this.lastSelectedPage) : -1;
        const toIndex = allItems.indexOf(clickItem);
        const indexLower = Math.min(fromIndex, toIndex);
        const indexHigher = Math.max(fromIndex, toIndex);

        for (let i = indexLower + 1; i <= indexHigher - 1; ++i) {
          this.setSelectedPage(allItems[i], true);
        }
      }

      this.toggleSelectedPage(clickItem, true);
    }
  }

  setSelectedPage(item: MenuGroupItem, selected: boolean) {
    if (!item.pageUid) {
      return;
    }

    this.selectedPages[item.pageUid] = selected;
    this.cd.markForCheck();
  }

  toggleSelectedPage(item: MenuGroupItem, click = false) {
    if (!item.pageUid) {
      return;
    }

    const currentValue = !!this.selectedPages[item.pageUid];
    const newValue = !currentValue;

    this.selectedPages[item.pageUid] = newValue;

    if (click && newValue) {
      this.lastSelectedPage = item;
    }

    this.cd.markForCheck();
  }

  get selectedPagesUids(): string[] {
    return toPairs(this.selectedPages)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
  }
}

/** Possible axis along which dragging can be locked. */
export enum DragAxis {
  X = 'x',
  Y = 'y'
}

export type DragAxisType = string | DragAxis;

/** Possible orientations for a drop list. */
export enum DropListOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

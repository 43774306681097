var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getInputValueTypeStr, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Fill, FillType } from '@modules/views';
import { controlValue, isSet } from '@shared';
import { ColorControl } from './color.control';
import { GradientControl } from './gradient.control';
import { IconFillControl } from './icon-fill.control';
import { ImageFillControl } from './image-fill.control';
var FillControl = /** @class */ (function (_super) {
    __extends(FillControl, _super);
    function FillControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            type: new FormControl(isSet(state.type) ? state.type : FillType.Color),
            color: new ColorControl(isSet(state.color) ? state.color : {}),
            color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
            color_input: new FieldInputControl({ name: 'value' }),
            gradient: new GradientControl(isSet(state.gradient) ? state.gradient : {}),
            image_fill: new ImageFillControl(isSet(state.imageFill) ? state.imageFill : {}),
            icon_fill: new IconFillControl(isSet(state.iconFill) ? state.iconFill : {}),
            opacity: new FormControl(isSet(state.opacity) ? state.opacity : 1),
            enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
            enabled_input: new FieldInputControl({ name: 'value' })
        }) || this;
    }
    FillControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.type.patchValue(value.type, { emitEvent: options.emitEvent });
        if (value.color) {
            this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
        }
        this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
        this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serialize() : {}, {
            emitEvent: options.emitEvent
        });
        if (value.gradient) {
            this.controls.gradient.deserialize(value.gradient, { emitEvent: options.emitEvent });
        }
        if (value.imageFill) {
            this.controls.image_fill.deserialize(value.imageFill, { emitEvent: options.emitEvent });
        }
        if (value.iconFill) {
            this.controls.icon_fill.deserialize(value.iconFill, { emitEvent: options.emitEvent });
        }
        this.controls.opacity.patchValue(value.opacity, { emitEvent: options.emitEvent });
        this.controls.enabled.patchValue(value.enabled, { emitEvent: options.emitEvent });
        this.controls.enabled_input.patchValue(value.enabledInput ? value.enabledInput.serialize() : {});
    };
    FillControl.prototype.setGradientType = function (type) {
        var prevType = this.controls.type.value;
        var defaultColor = prevType == FillType.Color ? this.controls.color.getInstance() : undefined;
        this.patchValue({
            type: FillType.Gradient,
            gradient: {
                type: type
            }
        });
        this.controls.gradient.validateStops(defaultColor);
    };
    FillControl.prototype.getColorInputRepresentation = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.color_input_enabled), controlValue(this.controls.color_input).pipe(map(function () { return _this.controls.color_input.serialize(); }))).pipe(map(function (_a) {
            var colorInputEnabled = _a[0], colorInput = _a[1];
            if (colorInputEnabled && colorInput) {
                return getInputValueTypeStr(colorInput.valueType, { context: 'component' });
            }
        }));
    };
    FillControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Fill();
            instance.generateId();
        }
        instance.type = this.controls.type.value;
        if (instance.type == FillType.Color || instance.color || instance.colorInput) {
            if (this.controls.color_input_enabled.value) {
                instance.color = this.controls.color.getInstance(instance.color);
                instance.colorInput = this.controls.color_input.value
                    ? new Input().deserialize(this.controls.color_input.value)
                    : undefined;
            }
            else {
                instance.color = this.controls.color.getInstance(instance.color);
                instance.colorInput = undefined;
            }
        }
        if (instance.type == FillType.Gradient || instance.gradient) {
            instance.gradient = this.controls.gradient.getInstance(instance.gradient);
        }
        if (instance.type == FillType.Image || instance.imageFill) {
            instance.imageFill = this.controls.image_fill.getInstance(instance.imageFill);
        }
        if (instance.type == FillType.Icon || instance.iconFill) {
            instance.iconFill = this.controls.icon_fill.getInstance(instance.iconFill);
        }
        instance.opacity = this.controls.opacity.value;
        instance.enabled = this.controls.enabled.value;
        instance.enabledInput = this.controls.enabled_input.value
            ? new Input().deserialize(this.controls.enabled_input.value)
            : undefined;
        return instance;
    };
    FillControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return FillControl;
}(FormGroup));
export { FillControl };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsCurrentYearFieldLookup = /** @class */ (function (_super) {
    __extends(IsCurrentYearFieldLookup, _super);
    function IsCurrentYearFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_current_year';
        _this.param = 'isCurrentYear';
        _this.verboseName = localize('is Current Year');
        _this.icon = 'calendar';
        return _this;
    }
    IsCurrentYearFieldLookup.prototype.from = function (value) {
        return moment().startOf('year').toISOString();
    };
    IsCurrentYearFieldLookup.prototype.to = function (value) {
        return moment().endOf('year').toISOString();
    };
    IsCurrentYearFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not current year', [field]);
            }
            else {
                return localize('is not current year');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is current year', [field]);
            }
            else {
                return localize('current year');
            }
        }
    };
    return IsCurrentYearFieldLookup;
}(BetweenFieldLookup));
export { IsCurrentYearFieldLookup };

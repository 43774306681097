var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ActionDescription, ViewSettingsAction } from '@modules/actions';
import { AlignHorizontal } from '../../align-horizontal';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ActionElementItem = /** @class */ (function (_super) {
    __extends(ActionElementItem, _super);
    function ActionElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Action;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        return _this;
    }
    ActionElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.tooltip = this.params['tooltip'];
        if (this.params['action_item']) {
            this.actionItem = new ViewSettingsAction().deserialize(this.params['action_item']);
        }
        else if (this.params['action']) {
            this.actionItem = new ViewSettingsAction();
            this.actionItem.sharedActionDescription = new ActionDescription().deserialize(this.params['action']).id;
        }
        return this;
    };
    ActionElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['action_item'] = this.actionItem ? this.actionItem.serialize() : undefined;
        this.params['tooltip'] = this.tooltip;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ActionElementItem.prototype, "typeStr", {
        get: function () {
            return 'trigger operations or transitions';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActionElementItem.prototype, "analyticsName", {
        get: function () {
            return 'action';
        },
        enumerable: true,
        configurable: true
    });
    ActionElementItem.prototype.defaultName = function () {
        return 'Button';
    };
    return ActionElementItem;
}(ElementItem));
export { ActionElementItem };
registerElementForType(ElementType.Action, ActionElementItem);

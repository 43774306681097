/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./custom-view-templates-card-stub.component";
var styles_CustomViewTemplatesCardStubComponent = [];
var RenderType_CustomViewTemplatesCardStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewTemplatesCardStubComponent, data: {} });
export { RenderType_CustomViewTemplatesCardStubComponent as RenderType_CustomViewTemplatesCardStubComponent };
export function View_CustomViewTemplatesCardStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "marketplace-card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "marketplace-card__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "marketplace-card__image-inner marketplace-card__image-inner_background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "marketplace-card__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "marketplace-card__info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "marketplace-card__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["name"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "marketplace-card__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["subtitle"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 6, 0, currVal_0); var currVal_1 = true; _ck(_v, 10, 0, currVal_1); }); }
export function View_CustomViewTemplatesCardStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-templates-card-stub", [], null, null, null, View_CustomViewTemplatesCardStubComponent_0, RenderType_CustomViewTemplatesCardStubComponent)), i0.ɵdid(1, 114688, null, 0, i1.CustomViewTemplatesCardStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewTemplatesCardStubComponentNgFactory = i0.ɵccf("app-custom-view-templates-card-stub", i1.CustomViewTemplatesCardStubComponent, View_CustomViewTemplatesCardStubComponent_Host_0, {}, {}, []);
export { CustomViewTemplatesCardStubComponentNgFactory as CustomViewTemplatesCardStubComponentNgFactory };

import { OnInit } from '@angular/core';
var SectionColumnComponent = /** @class */ (function () {
    function SectionColumnComponent() {
        this.cls = true;
        this.size = 'fill';
    }
    Object.defineProperty(SectionColumnComponent.prototype, "sizeS", {
        get: function () {
            return this.size == 's';
        },
        enumerable: true,
        configurable: true
    });
    SectionColumnComponent.prototype.ngOnInit = function () { };
    return SectionColumnComponent;
}());
export { SectionColumnComponent };

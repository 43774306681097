import { Injectable, OnDestroy, Optional } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, filter, map, skip, switchMap } from 'rxjs/operators';

import { BasePopupComponent, PopupService } from '@common/popups';
import { ElementItem } from '@modules/customize';
import {
  ContainerLayer,
  findLayer,
  Frame,
  frameFromFrames,
  FrameTranslate,
  getAllLayers,
  Layer,
  Translate,
  View
} from '@modules/views';
import { capitalize, isControlElement, isSet, KeyboardEventKeyCode, Size } from '@shared';

import { GradientControl } from '../../components/controls/gradient.control';

export enum CreatedLayerSource {
  Tool = 'tool',
  Buffer = 'buffer',
  Hotkey = 'hotkey'
}

export interface CreatedLayer {
  layer: Layer;
  source: CreatedLayerSource;
}

export enum ViewEditorTool {
  Selection = 'selection',
  Hand = 'hand',
  Rectangle = 'rectangle',
  Ellipse = 'ellipse',
  Line = 'line',
  Text = 'text',
  Element = 'element',
  Image = 'image',
  Frame = 'frame'
}

export function isViewEditorCreateTool(tool: ViewEditorTool): boolean {
  return [
    ViewEditorTool.Rectangle,
    ViewEditorTool.Ellipse,
    ViewEditorTool.Line,
    ViewEditorTool.Text,
    ViewEditorTool.Image,
    ViewEditorTool.Element,
    ViewEditorTool.Frame
  ].includes(tool);
}

export enum ViewEditorCustomizeSource {
  AutoLayer = 'auto_layer',
  AutoLayerBounds = 'auto_layer_bounds',
  Layer = 'layer',
  ParentLayer = 'parent_layer',
  View = 'view',
  CustomizeLayer = 'customize_layer',
  CustomizeMultipleLayers = 'customize_multiple_layers',
  CustomizeView = 'customize_view',
  Navigator = 'navigator',
  HistoryMove = 'history_move',
  ViewParameters = 'view_parameters'
}

export interface LayerChangeEvent<T extends Layer = Layer> {
  layer: T;
  source: ViewEditorCustomizeSource;
}

export interface LayerContainerChangeEvent {
  container: Layer[];
  source: ViewEditorCustomizeSource;
}

export interface GlobalLayersChangeEvent {
  source: ViewEditorCustomizeSource;
}

export interface ViewChangeEvent {
  view: View;
  source: ViewEditorCustomizeSource;
}

export enum ViewEditorGuideColor {
  Red = 'red',
  Blue = 'blue'
}

export enum ViewEditorGuideSymbol {
  Line = 'line'
}

export interface ViewEditorGuide {
  x: number;
  y: number;
  length: number;
  label?: string;
  color?: ViewEditorGuideColor;
  dashed?: boolean;
  startSymbol?: ViewEditorGuideSymbol;
  endSymbol?: ViewEditorGuideSymbol;
}

export interface CustomizeGradient {
  frame: FrameTranslate;
  control: GradientControl;
  activeStop?: string;
}

export interface ViewEditorContainerOptions {
  layer?: ContainerLayer;
  translate: Translate;
}

export const defaultViewEditorContainerOptions: ViewEditorContainerOptions = {
  translate: { x: 0, y: 0 }
};

export class ViewEditorContainer {
  private _options = new BehaviorSubject<ViewEditorContainerOptions>(defaultViewEditorContainerOptions);

  constructor(public readonly container: Layer[], options: Partial<ViewEditorContainerOptions>) {
    this.patchOptions(options);
  }

  get options(): ViewEditorContainerOptions {
    return this._options.value;
  }

  set options(value: ViewEditorContainerOptions) {
    this._options.next(value);
  }

  get options$(): Observable<ViewEditorContainerOptions> {
    return this._options.asObservable();
  }

  patchOptions(options: Partial<ViewEditorContainerOptions>) {
    this._options.next({
      ...this._options.value,
      ...options
    });
  }
}

interface HoverLayer {
  layer: Layer;
  handler: any;
}

@Injectable()
export class ViewEditorContext implements OnDestroy {
  public view$ = new BehaviorSubject<View>(undefined);
  public viewportX$ = new BehaviorSubject<number>(0);
  public viewportY$ = new BehaviorSubject<number>(0);
  public viewportScale$ = new BehaviorSubject<number>(1);
  public tool$ = new BehaviorSubject<ViewEditorTool>(ViewEditorTool.Selection);
  public toolFile$ = new BehaviorSubject<{ fileName: string; url: string; size?: Size }>(undefined);
  public toolElement$ = new BehaviorSubject<{
    element: ElementItem;
    width?: number;
    widthFluid: boolean;
    height?: number;
    heightFluid: boolean;
  }>(undefined);
  public isCreateTool$ = this.tool$.pipe(map(tool => isViewEditorCreateTool(tool)));
  public hoverLayers$ = new BehaviorSubject<HoverLayer[]>([]);
  public movingLayer$ = new BehaviorSubject<Layer>(undefined);
  public hoverView$ = new BehaviorSubject<boolean>(false);
  public resizingLayer$ = new BehaviorSubject<Layer>(undefined);
  public customizingLayers$ = new BehaviorSubject<Layer[]>([]);
  public resizingView$ = new BehaviorSubject<boolean>(false);
  public customizingView$ = new BehaviorSubject<boolean>(false);
  public customizingGradient$ = new BehaviorSubject<CustomizeGradient>(undefined);
  public showInterface$ = new BehaviorSubject<boolean>(true);
  public horizontalGuides$: Observable<ViewEditorGuide[]>;
  public verticalGuides$: Observable<ViewEditorGuide[]>;
  public navigateMode$ = new BehaviorSubject<boolean>(false);
  public ctrlKeydown$ = new BehaviorSubject<boolean>(false);
  public readonly viewportScaleMin = 0.01;
  public readonly viewportScaleMax = 256;

  private history$ = new BehaviorSubject<View[]>([]);
  private historyIndex$ = new BehaviorSubject<number>(0);
  private historyMaxSize = 100;
  private historyChangeDetected$ = new Subject<void>();
  private containers = new BehaviorSubject<{ container: Layer[]; instance: ViewEditorContainer }[]>([]);
  private layerContainers = new BehaviorSubject<{ layer: Layer; container: Layer[] }[]>([]);
  private createdLayers: CreatedLayer[] = [];
  private _layerChanged$ = new Subject<LayerChangeEvent>();
  private _layerContainerChanged$ = new Subject<LayerContainerChangeEvent>();
  private _globalLayersChange$ = new Subject<GlobalLayersChangeEvent>();
  private _layersBuffer$ = new BehaviorSubject<Layer[]>([]);
  private _viewChanged$ = new Subject<ViewChangeEvent>();
  private _horizontalGuides$ = new BehaviorSubject<ViewEditorGuide[]>([]);
  private _horizontalGuidesSubscription: Subscription;
  private _verticalGuides$ = new BehaviorSubject<ViewEditorGuide[]>([]);
  private _verticalGuidesSubscription: Subscription;
  private scaleBreakpoints = [0.01, 0.02, 0.03, 0.06, 0.13, 0.25, 0.5, 1, 2, 4, 8, 16, 32, 64, 128, 256];
  private keydownHandlers = [];
  private spaceKeydown$ = new BehaviorSubject<boolean>(false);

  constructor(private popupService: PopupService, @Optional() private popupComponent: BasePopupComponent) {
    this.initEvents();

    this.historyChangeDetected$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => this.historySave());

    this.horizontalGuides$ = this._horizontalGuides$.asObservable();
    this.verticalGuides$ = this._verticalGuides$.asObservable();

    combineLatest(this.tool$.pipe(map(tool => tool == ViewEditorTool.Hand)), this.spaceKeydown$)
      .pipe(
        map(([handTool, spaceDown]) => handTool || spaceDown),
        untilDestroyed(this)
      )
      .subscribe(value => this.navigateMode$.next(value));
  }

  ngOnDestroy(): void {}

  init(view: View) {
    this.view$.next(cloneDeep(view));
    this.history$.next([cloneDeep(view)]);
    this.historyIndex$.next(0);

    this.view$.pipe(skip(1), untilDestroyed(this)).subscribe(currentView => {
      const customizingLayers = this.customizingLayers$.value;
      if (customizingLayers.length) {
        const newLayers = customizingLayers
          .map(layer => findLayer(currentView.layers, item => item.isSame(layer)))
          .filter(item => item);
        this.customizingLayers$.next(newLayers);
      }
    });
  }

  updateView(view: View) {
    this.view$.next(view);
    this.historyChangeDetected$.next();
  }

  historySave() {
    const prevHistory = this.history$.value.slice(0, this.historyIndex$.value + 1).slice(this.historyMaxSize * -1);
    const history = [...prevHistory, cloneDeep(this.view$.value)];

    this.history$.next(history);
    this.historyIndex$.next(history.length - 1);
  }

  historyMove(index: number) {
    const state = this.history$.value[index];

    if (!state) {
      return;
    }

    this.view$.next(cloneDeep(state));
    this.historyIndex$.next(index);
  }

  isHistoryAvailable(delta: number): boolean {
    const history = this.history$.value;
    const historyIndex = this.historyIndex$.value;
    return history[historyIndex + delta] !== undefined;
  }

  isHistoryAvailable$(delta: number): Observable<boolean> {
    return combineLatest(this.history$, this.historyIndex$).pipe(
      map(([history, historyIndex]) => {
        return history[historyIndex + delta] !== undefined;
      })
    );
  }

  isUndoAvailable(): boolean {
    return this.isHistoryAvailable(-1);
  }

  isUndoAvailable$(): Observable<boolean> {
    return this.isHistoryAvailable$(-1);
  }

  isRedoAvailable(): boolean {
    return this.isHistoryAvailable(1);
  }

  isRedoAvailable$(): Observable<boolean> {
    return this.isHistoryAvailable$(1);
  }

  undo() {
    this.historyMove(this.historyIndex$.value - 1);
  }

  redo() {
    this.historyMove(this.historyIndex$.value + 1);
  }

  customizeView() {
    if (this.customizingView$.value) {
      return;
    }

    this.customizingView$.next(true);

    this.resetCustomizingLayers();
  }

  resetCustomizeView() {
    if (!this.customizingView$.value) {
      return;
    }

    this.customizingView$.next(false);
  }

  startCustomizingGradient(value: CustomizeGradient) {
    this.customizingGradient$.next(value);
  }

  updateCustomizingGradient(value: Partial<CustomizeGradient>) {
    if (!this.customizingGradient$.value) {
      return;
    }

    const currentValue = this.customizingGradient$.value;
    const newValue = { ...currentValue, ...value };

    if (isEqual(currentValue, newValue)) {
      return;
    }

    this.customizingGradient$.next(newValue);
  }

  finishCustomizingGradient() {
    if (!this.customizingGradient$.value) {
      return;
    }

    this.customizingGradient$.next(undefined);
  }

  registerContainer(container: Layer[], options: Partial<ViewEditorContainerOptions> = {}): ViewEditorContainer {
    const existingContainer = this.getContainer(container);
    if (existingContainer) {
      return;
    }

    const instance = new ViewEditorContainer(container, options);

    this.containers.next([
      ...this.containers.value,
      {
        container: container,
        instance: instance
      }
    ]);

    return instance;
  }

  unregisterContainer(container: Layer[]) {
    const existingContainer = this.getContainer(container);
    if (!existingContainer) {
      return;
    }

    this.containers.next(this.containers.value.filter(item => item.container !== container));

    if (this.layerContainers.value.find(item => item.container === container)) {
      this.layerContainers.next(this.layerContainers.value.filter(item => item.container !== container));
    }
  }

  getContainer(container: Layer[]): ViewEditorContainer {
    const result = this.containers.value.find(item => item.container === container);
    return result ? result.instance : undefined;
  }

  getContainer$(container: Layer[]): Observable<ViewEditorContainer> {
    return this.containers.pipe(
      map(value => {
        const result = value.find(item => item.container === container);
        return result ? result.instance : undefined;
      })
    );
  }

  registerLayerContainer(layer: Layer, container: Layer[]) {
    if (this.layerContainers.value.find(item => item.layer.isSame(layer) && item.container === container)) {
      return;
    }

    this.layerContainers.next([
      ...this.layerContainers.value.filter(item => !item.layer.isSame(layer)),
      { layer: layer, container: container }
    ]);
  }

  unregisterLayerContainer(layer: Layer) {
    if (!this.layerContainers.value.find(item => item.layer.isSame(layer))) {
      return;
    }

    this.layerContainers.next(this.layerContainers.value.filter(item => !item.layer.isSame(layer)));
  }

  getLayerContainer(layer: Layer): ViewEditorContainer {
    const layerContainer = this.layerContainers.value.find(item => item.layer.isSame(layer));
    if (!layerContainer) {
      return;
    }

    return this.getContainer(layerContainer.container);
  }

  getLayerContainer$(layer: Layer): Observable<ViewEditorContainer> {
    return this.layerContainers.pipe(
      switchMap(value => {
        const layerContainer = value.find(item => item.layer.isSame(layer));
        if (!layerContainer) {
          return of(undefined);
        }

        return this.getContainer$(layerContainer.container);
      })
    );
  }

  getContainerLayer$(layer: Layer): Observable<ContainerLayer> {
    return this.getLayerContainer$(layer).pipe(
      switchMap(container => {
        if (!container) {
          return of(undefined);
        }

        return container.options$.pipe(map(options => options.layer));
      }),
      filter(item => item)
    ) as Observable<ContainerLayer>;
  }

  generateLayerName(layer: Layer, options: { defaultName?: string; ignoreLayers?: Layer[] } = {}): string {
    const defaultName = isSet(options.defaultName) ? options.defaultName : capitalize(layer.defaultName);
    const allLayers = getAllLayers(this.view$.value.layers, {
      filter: item => {
        if (!options.ignoreLayers) {
          return true;
        }

        return !options.ignoreLayers.some(ignoreLayer => ignoreLayer.isSame(item));
      }
    });
    const names = allLayers.reduce((acc, item) => {
      acc[item.name] = true;
      return acc;
    }, {});
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (names.hasOwnProperty(newName));

    return newName;
  }

  registerCreatedLayer(layer: Layer, source: CreatedLayerSource) {
    this.createdLayers.push({
      layer: layer,
      source: source
    });
  }

  initCreatedLayer(layer: Layer): CreatedLayer {
    const createdElement = this.createdLayers.find(item => item.layer === layer);

    if (!createdElement) {
      return;
    }

    this.createdLayers = this.createdLayers.filter(item => item !== createdElement);
    return createdElement;
  }

  isHoverLayer(layer: Layer, handler: any) {
    return !!this.hoverLayers$.value.find(item => item.layer.isSame(layer) && item.handler === handler);
  }

  addHoverLayer(layer: Layer, handler: any) {
    if (this.isHoverLayer(layer, handler)) {
      return;
    }

    this.hoverLayers$.next([...this.hoverLayers$.value, { layer: layer, handler: handler }]);
  }

  removeHoverLayer(layer: Layer, handler: any) {
    if (!this.isHoverLayer(layer, handler)) {
      return;
    }

    this.hoverLayers$.next(
      this.hoverLayers$.value.filter(item => {
        if (item.layer.isSame(layer) && item.handler === handler) {
          return false;
        } else {
          return true;
        }
      })
    );
  }

  getTopHoverLayerFromValue(value: HoverLayer[]): Layer {
    const lastItem = last(value);
    return lastItem ? lastItem.layer : undefined;
  }

  getTopHoverLayer(): Layer {
    return this.getTopHoverLayerFromValue(this.hoverLayers$.value);
  }

  getTopHoverLayer$(): Observable<Layer> {
    return this.hoverLayers$.pipe(map(value => this.getTopHoverLayerFromValue(value)));
  }

  isTopHoverLayer(layer: Layer): boolean {
    const item = this.getTopHoverLayer();
    return item && item.isSame(layer);
  }

  isTopHoverLayer$(layer: Layer): Observable<boolean> {
    return this.getTopHoverLayer$().pipe(map(item => item && item.isSame(layer)));
  }

  getLastCustomizingLayer(): Layer {
    return last(this.customizingLayers$.value);
  }

  isCustomizingLayer(layer: Layer) {
    return !!this.customizingLayers$.value.find(item => item.isSame(layer));
  }

  isCustomizingLayer$(layer: Layer): Observable<boolean> {
    return this.customizingLayers$.pipe(map(value => !!value.find(item => item.isSame(layer))));
  }

  isCustomizingSingleLayer(): boolean {
    return this.customizingLayers$.value.length == 1;
  }

  isCustomizingSingleLayer$(): Observable<boolean> {
    return this.customizingLayers$.pipe(map(value => value.length == 1));
  }

  isCustomizingMultipleLayers(): boolean {
    return this.customizingLayers$.value.length > 1;
  }

  isCustomizingMultipleLayers$(): Observable<boolean> {
    return this.customizingLayers$.pipe(map(value => value.length > 1));
  }

  setCustomizingLayer(...layers: Layer[]) {
    this.customizingLayers$.next(layers);

    this.resetCustomizeView();
  }

  addCustomizingLayer(...layers: Layer[]) {
    const existingItems = this.customizingLayers$.value;
    const addItems = layers.filter(newItem => !existingItems.find(item => item.isSame(newItem)));

    if (!addItems.length) {
      return;
    }

    const newItems = [...existingItems, ...addItems];
    this.customizingLayers$.next(newItems);

    this.resetCustomizeView();
  }

  removeCustomizingLayer(...layers: Layer[]) {
    const existingItems = this.customizingLayers$.value;
    const removeItems = layers.filter(newItem => existingItems.find(item => item.isSame(newItem)));

    if (!removeItems.length) {
      return;
    }

    const newItems = existingItems.filter(existingItem => !removeItems.find(item => item.isSame(existingItem)));
    this.customizingLayers$.next(newItems);
  }

  resetCustomizingLayers() {
    if (!this.customizingLayers$.value.length) {
      return;
    }

    this.customizingLayers$.next([]);
  }

  bufferLayers(layers: Layer[]) {
    this._layersBuffer$.next(layers);
  }

  getBufferLayers(): Layer[] {
    return this._layersBuffer$.value;
  }

  layerChanged$(): Observable<LayerChangeEvent> {
    return this._layerChanged$.asObservable();
  }

  markLayersChanged(layers: Layer[], source: ViewEditorCustomizeSource) {
    if (!layers.length) {
      return;
    }

    layers.forEach(layer => {
      this._layerChanged$.next({ layer: layer, source: source });
    });

    if (source != ViewEditorCustomizeSource.HistoryMove) {
      this.historyChangeDetected$.next();
    }
  }

  layerContainerChanged$(): Observable<LayerContainerChangeEvent> {
    return this._layerContainerChanged$.asObservable();
  }

  markLayerContainerChanged(container: Layer[], source: ViewEditorCustomizeSource) {
    this._layerContainerChanged$.next({ container: container, source: source });

    if (source != ViewEditorCustomizeSource.HistoryMove) {
      this.historyChangeDetected$.next();
    }
  }

  globalLayersChange$(): Observable<GlobalLayersChangeEvent> {
    return this._globalLayersChange$.asObservable();
  }

  markGlobalLayersChange(source: ViewEditorCustomizeSource) {
    this._globalLayersChange$.next({ source: source });

    if (source != ViewEditorCustomizeSource.HistoryMove) {
      this.historyChangeDetected$.next();
    }
  }

  viewChanged$(): Observable<ViewChangeEvent> {
    return this._viewChanged$.asObservable();
  }

  markViewChanged(view: View, source: ViewEditorCustomizeSource) {
    this._viewChanged$.next({ view: view, source: source });

    if (source != ViewEditorCustomizeSource.HistoryMove) {
      this.historyChangeDetected$.next();
    }
  }

  isCreateTool(): boolean {
    return isViewEditorCreateTool(this.tool$.value);
  }

  zoomIn() {
    const newScale = this.scaleBreakpoints.find(item => item > this.viewportScale$.value);
    if (isSet(newScale)) {
      this.viewportScale$.next(newScale);
    }
  }

  zoomOut() {
    const newScale = [...this.scaleBreakpoints].reverse().find(item => item < this.viewportScale$.value);
    if (isSet(newScale)) {
      this.viewportScale$.next(newScale);
    }
  }

  isViewInsideViewport(viewport: HTMLElement, contentPadding = 40): boolean {
    const x = this.view$.value.frame.x;
    const y = this.view$.value.frame.y;
    const width = this.view$.value.frame.width;
    const height = this.view$.value.frame.height;
    const viewportX = this.viewportX$.value;
    const viewportY = this.viewportY$.value;
    const viewportScale = this.viewportScale$.value;
    const viewportBounds = viewport.getBoundingClientRect();

    return [
      (x - width * 0.5) / viewportScale >= viewportX - viewportBounds.width * 0.5 + contentPadding,
      (y - height * 0.5) / viewportScale >= viewportY - viewportBounds.height * 0.5 + contentPadding,
      (x + width * 0.5) / viewportScale <= viewportX + viewportBounds.width * 0.5 - contentPadding,
      (y + height * 0.5) / viewportScale <= viewportY + viewportBounds.height * 0.5 - contentPadding
    ].every(item => item);
  }

  fitToContent(viewport: HTMLElement, contentPadding = 40) {
    const x = this.view$.value.frame.x;
    const y = this.view$.value.frame.y;
    const width = this.view$.value.frame.width;
    const height = this.view$.value.frame.height;
    const viewportBounds = viewport.getBoundingClientRect();
    const scaleX = width ? (viewportBounds.width - contentPadding * 2) / width : 1;
    const scaleY = height ? (viewportBounds.height - contentPadding * 2) / height : 1;
    const scale = Math.min(scaleX, scaleY);

    this.viewportX$.next(x);
    this.viewportY$.next(y);
    this.viewportScale$.next(scale);
  }

  initEvents() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(() => {
          return this.popupService.last() === this.popupComponent.data && !isControlElement(document.activeElement);
        }),
        untilDestroyed(this)
      )
      .subscribe(event => {
        this.emitKeydownEvent(event);
      });

    this.trackKeydown()
      .pipe(
        filter(e => {
          if (e.keyCode == KeyboardEventKeyCode.Space) {
            e.stopPropagation();
            return !e.repeat;
          } else {
            return false;
          }
        }),
        switchMap(() => {
          this.spaceKeydown$.next(true);
          return fromEvent<KeyboardEvent>(document, 'keyup').pipe(filter(e => e.keyCode == KeyboardEventKeyCode.Space));
        }),
        untilDestroyed(this)
      )
      .subscribe(() => this.spaceKeydown$.next(false));

    merge(
      fromEvent<KeyboardEvent>(document, 'keydown').pipe(
        filter(event => [KeyboardEventKeyCode.Ctrl, KeyboardEventKeyCode.Meta].includes(event.keyCode)),
        map(() => true)
      ),
      fromEvent<KeyboardEvent>(document, 'keyup').pipe(
        filter(event => [KeyboardEventKeyCode.Ctrl, KeyboardEventKeyCode.Meta].includes(event.keyCode)),
        map(() => false)
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(keydown => {
        this.ctrlKeydown$.next(keydown);
      });
  }

  emitKeydownEvent(event: KeyboardEvent) {
    for (const handler of [...this.keydownHandlers].reverse()) {
      handler(event);

      if (event.cancelBubble) {
        break;
      }
    }
  }

  trackKeydown(): Observable<KeyboardEvent> {
    return new Observable<KeyboardEvent>(observer => {
      const handler = e => {
        observer.next(e);
      };

      this.keydownHandlers.push(handler);

      return () => {
        const index = this.keydownHandlers.findIndex(item => item === handler);
        if (index !== -1) {
          this.keydownHandlers.splice(index, 1);
        }
      };
    });
  }

  setHorizontalGuides(guides: ViewEditorGuide[], options: { durationMs?: number } = {}) {
    if (this._horizontalGuidesSubscription) {
      this._horizontalGuidesSubscription.unsubscribe();
      this._horizontalGuidesSubscription = undefined;
    }

    this._horizontalGuides$.next(guides);

    if (isSet(options.durationMs)) {
      const subscription = timer(options.durationMs)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          subscription.unsubscribe();

          if (this._horizontalGuidesSubscription === subscription) {
            this._horizontalGuidesSubscription = undefined;
          }

          this.removeHorizontalGuides();
        });

      this._horizontalGuidesSubscription = subscription;
    }
  }

  setVerticalGuides(guides: ViewEditorGuide[], options: { durationMs?: number } = {}) {
    if (this._verticalGuidesSubscription) {
      this._verticalGuidesSubscription.unsubscribe();
      this._verticalGuidesSubscription = undefined;
    }

    this._verticalGuides$.next(guides);

    if (isSet(options.durationMs)) {
      const subscription = timer(options.durationMs)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          subscription.unsubscribe();

          if (this._verticalGuidesSubscription === subscription) {
            this._verticalGuidesSubscription = undefined;
          }

          this.removeVerticalGuides();
        });

      this._verticalGuidesSubscription = subscription;
    }
  }

  removeHorizontalGuides() {
    this.setHorizontalGuides([]);
  }

  removeVerticalGuides() {
    this.setVerticalGuides([]);
  }

  getFrames(options: { onlyContainers?: Layer[][]; exceptLayers?: Layer[] } = {}): FrameTranslate[] {
    const view = this.view$.value;
    let layers: Layer[];

    const filterLayer = (layer: Layer): boolean => {
      if (options.exceptLayers) {
        return !options.exceptLayers.find(exceptLayer => exceptLayer.isSame(layer));
      } else {
        return true;
      }
    };

    if (options.onlyContainers) {
      layers = options.onlyContainers
        .reduce((acc, container) => {
          acc.push(...container);
          return acc;
        }, [])
        .filter(layer => filterLayer(layer));
    } else {
      layers = getAllLayers(view.layers, {
        filter: layer => filterLayer(layer)
      });
    }

    const result: FrameTranslate[] = [];

    if (!options.onlyContainers || options.onlyContainers.includes(view.layers)) {
      result.push(
        new FrameTranslate({
          frame: view.frame
        })
      );
    }

    result.push(
      ...layers.map(item => {
        const container = this.getLayerContainer(item);
        return new FrameTranslate({
          frame: item.frame,
          translate: container ? container.options.translate : undefined
        });
      })
    );

    return result;
  }

  fitContainer(layer: ContainerLayer, source: ViewEditorCustomizeSource) {
    const parentContainer = this.getLayerContainer(layer);
    const frames = layer.layers
      .filter(item => !item.absoluteLayout)
      .map(item => {
        return new FrameTranslate({
          frame: item.frame,
          translate: parentContainer ? parentContainer.options.translate : undefined
        });
      });
    const frame = frameFromFrames(frames) || new Frame(layer.frame).patch({ width: 0, height: 0 });

    if (layer.flexLayout) {
      frame.x -= layer.flexLayout.paddingLeft;
      frame.y -= layer.flexLayout.paddingTop;
      frame.width += layer.flexLayout.paddingLeft + layer.flexLayout.paddingRight;
      frame.height += layer.flexLayout.paddingTop + layer.flexLayout.paddingBottom;
    }

    if (parentContainer) {
      frame.x -= parentContainer.options.translate.x;
      frame.y -= parentContainer.options.translate.y;
    }

    frame.x += layer.frame.x;
    frame.y += layer.frame.y;

    const change = !isEqual(layer.frame, frame);
    if (!change) {
      return;
    }

    const originalFrameX = layer.frame.x;
    const originalFrameY = layer.frame.y;

    layer.frame.patch(frame);

    const nestedContainer = this.getContainer(layer.layers);
    if (nestedContainer && parentContainer) {
      nestedContainer.patchOptions({
        translate: {
          x: parentContainer.options.translate.x + layer.frame.x,
          y: parentContainer.options.translate.y + layer.frame.y
        }
      });
    }

    const frameDeltaX = layer.frame.x - originalFrameX;
    const frameDeltaY = layer.frame.y - originalFrameY;

    if (layer.frame.x != originalFrameX || layer.frame.y != originalFrameY) {
      layer.layers.forEach(item => {
        item.frame.x -= frameDeltaX;
        item.frame.y -= frameDeltaY;
      });

      this.markLayersChanged(layer.layers, ViewEditorCustomizeSource.ParentLayer);
    }

    this.markLayersChanged([layer], source);
  }
}

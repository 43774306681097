import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import defaults from 'lodash/defaults';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { PopupService } from '@common/popups';
import { ResizeFinishedEvent, ResizeType } from '@common/resizable';
import { SessionStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  CustomizeService,
  CustomViewSettings,
  ignoreElementCustomize,
  isElementCustomizeIgnored,
  markElementClickEvent,
  PopupSettings,
  PopupStyle,
  ViewContext,
  ViewContextElement,
  ViewSettings
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { applyParamInput, getFieldDescriptionByType } from '@modules/fields';
import { EMPTY, isControlElement, KeyboardEventKeyCode } from '@shared';

// TODO: Refactor import
import { BUILDER_ELEMENT_BUFFER } from '../../../customize-elements/services/element-container/element-container.service';

const customPagePopupClickEventProperty = '_customPagePopupClickEvent';

export function markCustomPagePopupClickEvent(clickEvent: MouseEvent) {
  clickEvent[customPagePopupClickEventProperty] = true;
}

export function isCustomPagePopupClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[customPagePopupClickEventProperty];
}

@Component({
  selector: 'app-custom-page-popup',
  templateUrl: './custom-page-popup.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePopupComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() popup: PopupSettings;
  @Input() viewSettings: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() customizing = false;
  @Input() popupsContextElement: ViewContextElement;
  @Input() visible = false;
  @Input() closeEnabled = true;
  @Output() editPopup = new EventEmitter<void>();
  @Output() deletePopup = new EventEmitter<boolean>();
  @Output() closePopup = new EventEmitter<void>();
  @Output() duplicatePopup = new EventEmitter<void>();
  @Output() movePopupToPage = new EventEmitter<ViewSettings>();

  @ViewChild(CdkConnectedOverlay) movePagesOverlay: CdkConnectedOverlay;

  hover = false;
  configured = true;
  customizingElement = false;
  resizingElement = false;
  resizeStepSize = 10;
  customizeComponentData = new BehaviorSubject<DynamicComponentArguments>(undefined);
  interactionsDisabled = false;
  width: number;
  resizeTypes = ResizeType;
  title: string;
  contextSubscription: Subscription;
  minSize = { width: 260 };
  popupStyles = PopupStyle;
  movePagesOpened = false;
  movePagesPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetY: 4
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: -4
    }
  ];

  constructor(
    private customizeService: CustomizeService,
    private customizeBarService: CustomizeBarService,
    @Optional() private customizeBarContext: CustomizeBarContext,
    private contextElement: ViewContextElement,
    private popupService: PopupService,
    private sessionStorage: SessionStorage,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.width = this.popup.width;

    this.customizeService.lastHovered$
      .pipe(
        debounceTime(1), // TODO: Workaround for slow ngOnDestroy
        untilDestroyed(this)
      )
      .subscribe(lastHovered => {
        this.hover = lastHovered === this;
        this.cd.markForCheck();
      });

    if (this.customizeBarContext) {
      combineLatest(this.customizeComponentData, this.customizeBarContext.settingsComponents$)
        .pipe(untilDestroyed(this))
        .subscribe(([customizeComponentData, components]) => {
          this.customizingElement =
            customizeComponentData && components.find(item => item === customizeComponentData) != undefined;
          this.cd.markForCheck();
        });
    }

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(
          () => this.customizingElement && !this.popupService.items.length && !isControlElement(document.activeElement)
        ),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
          this.closeCustomize();
        } else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
          this.deletePopup.emit();
        } else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.X) {
          this.copyElement(true);
        } else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.C) {
          this.copyElement();
        }
      });
  }

  ngOnDestroy(): void {
    this.customizeService.removeHover(this);
    this.closeCustomize();
    this.contextElement.unregister();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['popup'] || changes['context']) {
      this.init();
    }

    if (changes['popup']) {
      this.initContextElement();
      this.updateContextOutputs();
    }

    if (changes['visible'] && !this.visible) {
      this.closeCustomize();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  get displayName() {
    if (!this.popup) {
      return;
    }

    return this.popup.name || 'modal';
  }

  init() {
    this.initContextObserver();
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    this.updateInputs();

    this.contextSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.updateInputs());
  }

  initContextElement() {
    this.contextElement.initGlobal({ uniqueName: this.popup.uid, name: this.popup.name }, this.popupsContextElement);
  }

  updateContextOutputs() {
    this.contextElement.setOutputs(
      this.popup.parameters.map(item => {
        const fieldDescription = getFieldDescriptionByType(item.field);
        return {
          uniqueName: item.name,
          name: item.verboseName || item.name,
          icon: fieldDescription.icon
        };
      })
    );
  }

  setContextOutputValues(values: Object) {
    this.contextElement.setOutputValues(fromPairs(this.popup.parameters.map(item => [item.name, values[item.name]])));
  }

  updateInputs() {
    let title: string;

    if (this.popup.title) {
      try {
        const value = applyParamInput(this.popup.title, { context: this.context });

        if (value !== EMPTY) {
          title = value;
        }
      } catch (e) {}
    }

    this.title = title;
    this.cd.markForCheck();
  }

  setElementSize() {
    this.popup.width = this.width;
  }

  markElementEvent(e: MouseEvent) {
    markElementClickEvent(e);
  }

  customize(options: { event?: MouseEvent; firstInit?: boolean; highlight?: boolean; setupOnCreate?: boolean } = {}) {
    options = defaults(options, { firstInit: false });

    if (options.event) {
      if (isElementCustomizeIgnored(options.event)) {
        return;
      } else {
        ignoreElementCustomize(options.event);
      }
    }

    if (this.customizingElement) {
      if (options.highlight) {
        this.customizeBarContext.highlight();
      }

      return;
    }

    this.customizeBarService
      .customizePopup({ context: this.customizeBarContext, popup: this.popup, viewContext: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e && e.type === CustomizeBarEditEventType.Deleted) {
          this.deletePopup.emit();
        } else if (e && e.type === CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as PopupSettings;
          this.setElementSize();
          this.onCustomized(instance);
        }
      });

    this.customizeComponentData.next(this.customizeBarContext.settingsComponent);
  }

  closeCustomize() {
    if (this.customizeComponentData.value) {
      this.customizeBarContext.closeSettingsComponent(this.customizeComponentData.value);
      this.customizeComponentData.next(undefined);
    }
  }

  copyElement(cut = false) {
    this.sessionStorage.set(
      BUILDER_ELEMENT_BUFFER,
      JSON.stringify({
        popup: this.popup.serialize(),
        persistent: !cut
      })
    );

    if (cut) {
      this.deletePopup.emit(true);

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Cut);
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Copy);
    }
  }

  onCustomized(popup: PopupSettings) {
    this.popup.patch(popup);
    this.updateInputs();
    this.initContextElement();
    this.updateContextOutputs();
    this.customizeService.markChanged();
    this.cd.markForCheck();
    this.editPopup.emit();
  }

  close() {
    this.closePopup.emit();
  }

  onHover(hover) {
    if (hover) {
      this.customizeService.addHover(this);
    } else {
      this.customizeService.removeHover(this);
    }
  }

  onResizeStarted() {
    this.interactionsDisabled = true;
    this.resizingElement = true;
    this.cd.markForCheck();
  }

  onResize(event: ResizeFinishedEvent) {
    if (event.types.includes(ResizeType.Horizontal) && event.widthChanged) {
      this.width = event.width;
    }

    this.setElementSize();
    this.interactionsDisabled = false;
    this.resizingElement = false;
    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  setMovePagesOpened(value) {
    this.movePagesOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (!this.movePagesOverlay) {
      return;
    }

    this.movePagesOverlay.positionChange.pipe(untilDestroyed(this)).subscribe((e: ConnectedOverlayPositionChange) => {
      const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
      const position = this.movePagesPositions.find(item =>
        propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
      );
      const otherPosition = this.movePagesPositions.filter(item => item !== position);

      if (position) {
        this.movePagesOverlay.overlayRef.addPanelClass(position.panelClass);
      }

      otherPosition.forEach(item => this.movePagesOverlay.overlayRef.removePanelClass(item.panelClass));
    });
  }

  onMouseDown(event: MouseEvent) {
    markCustomPagePopupClickEvent(event);
  }
}

<div class="sidebar__element">
  <app-sidebar-field [label]="'output format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'output_format',
          field: 'SelectField',
          params: { options: form.outputFormatOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="form.form.value['output_format'] == outputFormats.Storage" class="sidebar__element">
  <app-sidebar-card>
    <div class="sidebar__element">
      <app-sidebar-field>
        <gxs-select
          [placeholder]="'choose' | localize | appCapitalize"
          [formControl]="form.form.controls['storage']"
          [source]="storageSelectSource"
          [options]="{
            theme: 'jet',
            search: true,
            searchMinimumLength: 1,
            searchDebounce: 0,
            classes: ['select_fill'],
            labels: {} | localizeSelect
          }"
        >
        </gxs-select>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field
        [label]="'Path inside storage'"
        [additional]="'(optional)'"
        [documentation]="'components/fields/media'"
        [documentationLabel]="'See how it works'"
        [documentationSource]="analyticsSource + '_path'"
      >
        <app-input-edit
          [itemForm]="form.form.controls['path']"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [placeholder]="'Path'"
          [fill]="true"
          [analyticsSource]="analyticsSource + '_path'"
        ></app-input-edit>

        <ng-container description>
          Example: product/photo/
        </ng-container>
      </app-sidebar-field>
    </div>
  </app-sidebar-card>
</div>

<div class="sidebar__row sidebar__element">
  <div *ngIf="!(element && editable)" class="sidebar__row-item">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'display'">
        <app-auto-field
          [form]="form.form"
          [label]="false"
          [field]="
            createField({
              name: 'type',
              field: 'SelectField',
              params: { options: element ? form.typeElementOptions : form.typeOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </div>
  <div class="sidebar__row-item">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'fit'">
        <app-auto-field
          [form]="form.form"
          [label]="false"
          [field]="
            createField({
              name: 'fit',
              field: 'SelectField',
              params: { options: form.fitOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </div>
</div>

<div *ngIf="form.form.value['output_format'] == outputFormats.Storage && editable" class="sidebar__element">
  <app-sidebar-field [label]="'crop on upload'" [additional]="'(optional)'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'crop_format',
          field: 'SelectField',
          params: {
            options: form.imageFieldCrops,
            classes: ['select_fill', form.form.value['crop_format'] === cropFormats.Custom ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>

  <app-sidebar-field *ngIf="form.form.controls['crop_format'].value == cropFormats.Custom">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'crop_format_custom',
          field: 'CharField',
          required: false,
          placeholder: 'Example: 5:4',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<!--  <app-auto-field-->
<!--    [form]="form.form"-->
<!--    [field]="createField({ name: 'resize', label: 'resize', field: 'BooleanField' })"-->
<!--  ></app-auto-field>-->
<!--  <app-auto-field-->
<!--    [form]="form.form"-->
<!--    [field]="createField({ name: 'width', label: 'width', field: 'IntegerField' })"-->
<!--  ></app-auto-field>-->
<!--  <app-auto-field-->
<!--    [form]="form.form"-->
<!--    [field]="createField({ name: 'height', label: 'height', field: 'IntegerField' })"-->
<!--  ></app-auto-field>-->

<div *ngIf="!editable" class="sidebar__element">
  <app-sidebar-field [label]="'Show preview on hover'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'show_preview',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="!editable" class="sidebar__element">
  <app-sidebar-field [label]="'Show fullscreen on click'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'lightbox',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="editable" class="sidebar__element">
  <app-sidebar-field [label]="'Show crop on upload'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'editor',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FeedItem } from '@modules/collaboration';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { User } from '@modules/users';

@Component({
  selector: 'app-collaboration-messages-item, [app-collaboration-messages-item]',
  templateUrl: './collaboration-messages-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationMessagesItemComponent implements OnInit, OnDestroy {
  @Input() item: FeedItem;

  message: string;

  constructor(
    private el: ElementRef,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const message = this.item.userActivity.params['message'];

    this.message = this.projectUserService.stubTextMentions(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      message
    );
    this.cd.markForCheck();

    this.projectUserService
      .replaceTextMentions(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        message
      )
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.message = result;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  public get element(): ElementRef {
    return this.el;
  }

  public get feedItem(): FeedItem {
    return this.item;
  }
}

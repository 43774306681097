import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalizeModule } from '@common/localize';
import { AnalyticsModule } from '@modules/analytics';
import { DemoModule } from '@modules/demo';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';

import { MenuProfileComponent } from './components/menu-profile/menu-profile.component';

@NgModule({
  imports: [CommonModule, RoutingModule, DemoModule, AnalyticsModule, TutorialModule, LocalizeModule],
  declarations: [MenuProfileComponent],
  exports: [MenuProfileComponent]
})
export class MenuProfileModule {}

import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import last from 'lodash/last';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, pairwise } from 'rxjs/operators';
import { arrayReverse, arraySet, ascComparator, isSet } from '@shared';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
import { ViewEditorNavigatorService } from '../../services/view-editor-navigator/view-editor-navigator.service';
export function transferArrayItems(transferItems, transferAnchor, targetArray, targetIndex) {
    var insertIndex = targetIndex;
    var popItems = transferItems
        .sort(function (lhs, rhs) {
        if (lhs.array === rhs.array) {
            var lhsIndex = lhs.array.findIndex(function (item) { return item === lhs.item; });
            var rhsIndex = rhs.array.findIndex(function (item) { return item === rhs.item; });
            return ascComparator(lhsIndex, rhsIndex);
        }
        else {
            var lhsIndex = transferItems.findIndex(function (item) { return item === lhs; });
            var rhsIndex = transferItems.findIndex(function (item) { return item === rhs; });
            return ascComparator(lhsIndex, rhsIndex);
        }
    })
        .reverse()
        .reduce(function (acc, transferItem) {
        var index = transferItem.array.findIndex(function (item) { return item === transferItem.item; });
        var popItem = transferItem.array.splice(index, 1)[0];
        acc.push(popItem);
        if (transferItem.array === targetArray && transferItem.item !== transferAnchor && index < targetIndex) {
            --insertIndex;
        }
        return acc;
    }, []);
    targetArray.splice.apply(targetArray, [insertIndex, 0].concat(arrayReverse(popItems)));
}
var ViewEditorNavigatorItemsComponent = /** @class */ (function () {
    function ViewEditorNavigatorItemsComponent(editorContext, viewEditorNavigatorService, sanitizer, cd) {
        this.editorContext = editorContext;
        this.viewEditorNavigatorService = viewEditorNavigatorService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.items = [];
        this.flexLayout = false;
        this.indent = 0;
        this.search = '';
        this.indentRange = [];
        this.state = { container: [], displayItems: [], displayReverse: true };
    }
    ViewEditorNavigatorItemsComponent.prototype.trackLayerFn = function (i, item) {
        return item.id;
    };
    ViewEditorNavigatorItemsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.viewEditorNavigatorService.registerComponent(this);
        this.updateIndent();
        this.editorContext.customizingLayers$
            .pipe(distinctUntilChanged(), pairwise(), untilDestroyed(this))
            .subscribe(function (_a) {
            var prev = _a[0], current = _a[1];
            var newCurrent = current.filter(function (currentItem) { return !prev.find(function (prevItem) { return prevItem.isSame(currentItem); }); });
            _this.customizingLayersLastAdded = last(newCurrent);
        });
        this.editorContext
            .layerContainerChanged$()
            .pipe(filter(function (event) { return _this.items === event.container; }), untilDestroyed(this))
            .subscribe(function () { return _this.updateState(); });
    };
    ViewEditorNavigatorItemsComponent.prototype.ngOnDestroy = function () {
        this.viewEditorNavigatorService.unregisterComponent(this);
    };
    ViewEditorNavigatorItemsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.items || changes.search || changes.flexLayout) {
            this.updateState();
        }
        if (changes.indent && !changes.indent.firstChange) {
            this.updateIndent();
        }
    };
    ViewEditorNavigatorItemsComponent.prototype.updateState = function () {
        var displayReverse = !this.flexLayout;
        this.state = {
            container: this.items,
            displayItems: this.getDisplayItems(displayReverse),
            displayReverse: displayReverse
        };
        this.cd.markForCheck();
    };
    ViewEditorNavigatorItemsComponent.prototype.updateIndent = function () {
        var vars = ["--navigator-items-index: " + this.indent, "--navigator-items-nested: " + (this.indent > 0 ? 1 : 0)];
        this.indentRange = range(this.indent);
        this.style = this.sanitizer.bypassSecurityTrustStyle(vars.join(';'));
    };
    ViewEditorNavigatorItemsComponent.prototype.getDisplayItems = function (displayReverse) {
        var _this = this;
        var search = isSet(this.search) ? this.search.trim().toLowerCase() : '';
        var items = this.items.filter(function (item) {
            if (!isSet(_this.search)) {
                return true;
            }
            return item.name.toLowerCase().indexOf(search) !== -1;
        });
        return arrayReverse(items, displayReverse);
    };
    ViewEditorNavigatorItemsComponent.prototype.customizeItem = function (layer) {
        this.editorContext.setCustomizingLayer(layer);
    };
    ViewEditorNavigatorItemsComponent.prototype.addCustomizingItem = function (layer, shift) {
        var _this = this;
        var _a;
        if (shift && this.customizingLayersLastAdded) {
            var lastIndex = this.items.findIndex(function (item) { return item.isSame(_this.customizingLayersLastAdded); });
            var newIndex = this.items.findIndex(function (item) { return item.isSame(layer); });
            var addItems = newIndex >= lastIndex
                ? this.items.slice(lastIndex + 1, newIndex + 1)
                : this.items.slice(newIndex, lastIndex).reverse();
            (_a = this.editorContext).addCustomizingLayer.apply(_a, addItems);
        }
        else {
            this.editorContext.addCustomizingLayer(layer);
        }
    };
    ViewEditorNavigatorItemsComponent.prototype.removeCustomizingItem = function (layer) {
        this.editorContext.removeCustomizingLayer(layer);
    };
    ViewEditorNavigatorItemsComponent.prototype.dragDrop = function (event) {
        var _this = this;
        var _a;
        var previousState = event.previousContainer.data;
        var state = event.container.data;
        var dragLayer = previousState.displayItems[event.previousIndex];
        var customizingLayers = this.editorContext.customizingLayers$.value;
        var transferLayers = customizingLayers.length > 1 && customizingLayers.find(function (item) { return item.isSame(dragLayer); })
            ? customizingLayers
            : [dragLayer];
        if (event.previousContainer === event.container) {
            transferArrayItems(transferLayers.map(function (item) { return ({ item: item, array: state.displayItems }); }), dragLayer, state.displayItems, event.currentIndex);
            arraySet(state.container, arrayReverse(state.displayItems, state.displayReverse));
            this.editorContext.markLayerContainerChanged(state.container, ViewEditorCustomizeSource.Navigator);
        }
        else {
            var previousContainer = this.editorContext.getContainer(previousState.container);
            var previousTranslate = previousContainer ? previousContainer.options.translate : { x: 0, y: 0 };
            var container = this.editorContext.getContainer(state.container);
            var translate = container ? container.options.translate : { x: 0, y: 0 };
            var deltaPositionX_1 = previousTranslate.x - translate.x;
            var deltaPositionY_1 = previousTranslate.y - translate.y;
            transferLayers.forEach(function (layer) {
                layer.frame.x += deltaPositionX_1;
                layer.frame.y += deltaPositionY_1;
            });
            transferArrayItems(transferLayers.map(function (layer) {
                var component = _this.viewEditorNavigatorService.components.find(function (componentItem) {
                    return componentItem.state.displayItems.some(function (item) { return item.isSame(layer); });
                });
                return {
                    item: layer,
                    array: component.state.displayItems
                };
            }), dragLayer, state.displayItems, event.currentIndex);
            arraySet(state.container, arrayReverse(state.displayItems, state.displayReverse));
            arraySet(previousState.container, arrayReverse(previousState.displayItems, previousState.displayReverse));
            this.editorContext.markLayersChanged(transferLayers, ViewEditorCustomizeSource.Navigator);
            this.editorContext.markLayerContainerChanged(state.container, ViewEditorCustomizeSource.Navigator);
            this.editorContext.markLayerContainerChanged(previousState.container, ViewEditorCustomizeSource.Navigator);
            (_a = this.editorContext).setCustomizingLayer.apply(_a, transferLayers);
        }
    };
    ViewEditorNavigatorItemsComponent.prototype.onItemVisibleUpdated = function (layer) {
        this.editorContext.markLayersChanged([layer], ViewEditorCustomizeSource.Navigator);
        this.editorContext.markLayerContainerChanged(this.state.container, ViewEditorCustomizeSource.Navigator);
    };
    return ViewEditorNavigatorItemsComponent;
}());
export { ViewEditorNavigatorItemsComponent };

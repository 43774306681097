var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isSet } from '@shared';
import { Border } from '../border';
import { CornerRadius } from '../corner-radius';
import { Fill } from '../fill';
import { FlexLayout } from '../flex-layout';
import { ContainerLayer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';
var FrameLayer = /** @class */ (function (_super) {
    __extends(FrameLayer, _super);
    function FrameLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Frame;
        _this.clipContent = false;
        _this.fills = [];
        _this.borders = [];
        _this.shadows = [];
        _this.cornerRadius = new CornerRadius();
        return _this;
    }
    FrameLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (isSet(data['width_fluid'])) {
            this.widthFluid = data['width_fluid'];
        }
        if (isSet(data['height_fluid'])) {
            this.heightFluid = data['height_fluid'];
        }
        if (isSet(data['clip_content'])) {
            this.clipContent = data['clip_content'];
        }
        if (data['flex_layout']) {
            this.flexLayout = new FlexLayout().deserialize(data['flex_layout']);
        }
        if (data['fills']) {
            this.fills = data['fills'].map(function (item) { return new Fill().deserialize(item); });
        }
        if (data['borders']) {
            this.borders = data['borders'].map(function (item) { return new Border().deserialize(item); });
        }
        if (data['shadows']) {
            this.shadows = data['shadows'].map(function (item) { return new Shadow().deserialize(item); });
        }
        if (data['corner_radius']) {
            this.cornerRadius = new CornerRadius().deserialize(data['corner_radius']);
        }
        return this;
    };
    FrameLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { width_fluid: this.widthFluid, height_fluid: this.heightFluid, clip_content: this.clipContent, flex_layout: this.flexLayout ? this.flexLayout.serialize() : undefined, fills: this.fills.map(function (item) { return item.serialize(); }), borders: this.borders.map(function (item) { return item.serialize(); }), shadows: this.shadows.map(function (item) { return item.serialize(); }), corner_radius: this.cornerRadius ? this.cornerRadius.serialize() : undefined });
    };
    Object.defineProperty(FrameLayer.prototype, "icon", {
        get: function () {
            return 'corners';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrameLayer.prototype, "defaultName", {
        get: function () {
            return 'frame';
        },
        enumerable: true,
        configurable: true
    });
    return FrameLayer;
}(ContainerLayer));
export { FrameLayer };
registerLayerType(LayerType.Frame, FrameLayer, { container: true });

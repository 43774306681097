<div class="sidebar-button-group">
  <div class="sidebar-button-group-gallery" (click)="openCustomViewTemplates()">
    <div class="sidebar-button-group-gallery__background"></div>
    <div class="sidebar-button-group-gallery__overlay"></div>

    <div class="sidebar-button-group-gallery__info">
      <div class="sidebar-button-group-gallery__info-main">
        <div class="sidebar-button-group-gallery__info-title">
          Explore Marketplace
        </div>
        <div class="sidebar-button-group-gallery__info-subtitle">
          <ng-container *ngIf="templatesApprox | appIsSet">{{ templatesApprox }}+ components</ng-container>
          <ng-container *ngIf="!(templatesApprox | appIsSet)">Components library</ng-container>
        </div>
      </div>

      <div class="sidebar-button-group-gallery__info-right">
        <div class="sidebar-button-group-gallery__info-arrow icon-arrow_forward_2"></div>
      </div>
    </div>
  </div>
</div>

<div class="sidebar-button-group">
  <!--  <div class="sidebar-button-group__item sidebar-button-group__item_fill">-->
  <!--    <div-->
  <!--      class="sidebar-button-group-info-button sidebar-button-group-info-button_purple-gradient"-->
  <!--      (click)="openCustomViewTemplates()"-->
  <!--    >-->
  <!--      <div class="sidebar-button-group-info-button__left">-->
  <!--        <div class="sidebar-button-group-info-button__icon icon-blocks"></div>-->
  <!--      </div>-->
  <!--      <div class="sidebar-button-group-info-button__main">-->
  <!--        <div class="sidebar-button-group-info-button__title">Explore Marketplace</div>-->
  <!--        <div class="sidebar-button-group-info-button__subtitle">-->
  <!--          <ng-container *ngIf="templatesApprox | appIsSet">{{ templatesApprox }}+ components</ng-container>-->
  <!--          <ng-container *ngIf="!(templatesApprox | appIsSet)">Components library</ng-container>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="sidebar-button-group-info-button__right">-->
  <!--        <div class="sidebar-button-group-info-button__arrow icon-arrow_forward_2"></div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="sidebar-button-group__item">
    <div
      class="sidebar-button-group-button sidebar-button-group-button_icon sidebar-button-group-button_purple"
      (click)="importFigmaNode()"
      [appTip]="'Import from Figma'"
      [appTipOptions]="{ side: 'bottom' }"
    >
      <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_figma"></div>
    </div>
  </div>

  <div class="sidebar-button-group__item">
    <div
      class="sidebar-button-group-button sidebar-button-group-button_icon sidebar-button-group-button_purple"
      (click)="importSketchFile()"
      [appTip]="'Import from Sketch'"
      [appTipOptions]="{ side: 'bottom' }"
    >
      <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_sketch"></div>
    </div>
  </div>

  <div class="sidebar-button-group__item sidebar-button-group__item_fill">
    <div class="sidebar-button-group-button sidebar-button-group-button_purple" (click)="createNewView()">
      <div class="sidebar-button-group-button__icon sidebar-button-group-button__icon_left icon-plus"></div>
      <div class="sidebar-button-group-button__label">Create new</div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "../../../../field-components/components/font-menu-overlay/font-menu-overlay.component.ngfactory";
import * as i3 from "../../../../field-components/components/font-menu-overlay/font-menu-overlay.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/common";
import * as i6 from "./compact-font-control.component";
var styles_CompactFontControlComponent = [];
var RenderType_CompactFontControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompactFontControlComponent, data: {} });
export { RenderType_CompactFontControlComponent as RenderType_CompactFontControlComponent };
function View_CompactFontControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-select__value"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.value; _ck(_v, 1, 0, currVal_0); }); }
function View_CompactFontControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-select__placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_CompactFontControlComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-font-menu-overlay", [], null, [[null, "fontSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("fontSelect" === en)) {
        var pd_0 = (_co.control.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FontMenuOverlayComponent_0, i2.RenderType_FontMenuOverlayComponent)), i0.ɵdid(3, 4440064, [["overlay", 4]], 0, i3.FontMenuOverlayComponent, [i0.ChangeDetectorRef], { value: [0, "value"], origin: [1, "origin"] }, { fontSelect: "fontSelect" }), (_l()(), i0.ɵeld(4, 0, null, null, 11, "div", [["cdkOverlayOrigin", ""], ["class", "compact-select"]], [[2, "compact-select_focus", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, [["overlay_origin", 4]], 0, i4.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "compact-select__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CompactFontControlComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_CompactFontControlComponent_2)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(12, 1), i0.ɵppd(13, 1), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "compact-select__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "div", [["class", "compact-select__arrow icon-arrow_down_2"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.value; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.control.value)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (!i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), _co.control.value)) && i0.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), _co.placeholder))); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 3).isOpened(); _ck(_v, 4, 0, currVal_2); }); }
export function View_CompactFontControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compact-font-control", [], null, null, null, View_CompactFontControlComponent_0, RenderType_CompactFontControlComponent)), i0.ɵdid(1, 245760, null, 0, i6.CompactFontControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompactFontControlComponentNgFactory = i0.ɵccf("app-compact-font-control", i6.CompactFontControlComponent, View_CompactFontControlComponent_Host_0, { control: "control", placeholder: "placeholder" }, {}, []);
export { CompactFontControlComponentNgFactory as CompactFontControlComponentNgFactory };

import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AppDragDrop } from '@common/drag-drop2';
import {
  ITEM_OUTPUT,
  rawListViewSettingsColumnsToViewContextOutputs,
  SUBMIT_RESULT_OUTPUT,
  ViewContextElement
} from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput$, DisplayFieldType, FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { QueryService, QueryType } from '@modules/queries';
import { cleanWorkflowStepName, IteratorWorkflowStep, WorkflowStep, WorkflowStepType } from '@modules/workflow';
import { isSet, TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { workflowStepsToken } from '../../workflow/workflow.component';
import { WorkflowRunParams, WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

interface State {
  step?: IteratorWorkflowStep;
  iterate?: any;
  name?: string;
  icon?: string;
  type?: WorkflowStepType;
  dataSource?: ListModelDescriptionDataSource;
  modelDescription?: ModelDescription;
}

function getStepStateContextInfo(state: State): Object {
  return {
    name: state.name,
    type: state.type,
    icon: state.icon
  };
}

function getStepStateContextOutputs(state: State): Object {
  return {
    iterate: state.iterate,
    columns: state.dataSource ? state.dataSource.columns : undefined,
    primaryKeyField: state.modelDescription ? state.modelDescription.primaryKeyField : undefined
  };
}

@Component({
  selector: 'app-iterator-workflow-step',
  templateUrl: './iterator-workflow-step.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IteratorWorkflowStepComponent extends WorkflowStepComponent<IteratorWorkflowStep>
  implements OnInit, OnChanges {
  icon: string;

  trackStep = (() => {
    return (i, item: WorkflowStep) => {
      return item ? item.uid : i;
    };
  })();

  constructor(
    @Inject(workflowStepsToken) private workflowStepsContextElement: ViewContextElement,
    private queryService: QueryService,
    private modelDescriptionStore: ModelDescriptionStore,
    contextElement: ViewContextElement,
    workflowEditContext: WorkflowEditContext,
    cd: ChangeDetectorRef
  ) {
    super(contextElement, workflowEditContext, cd);
  }

  ngOnInit() {
    this.initContext();
    this.updateIcon();
    this.trackExecuteStatus({ successOnStart: true });
    this.stepOnChange(this.step);
    this.trackChanges();

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<IteratorWorkflowStepComponent>): void {
    if (changes.step) {
      if (!changes.step.firstChange) {
        this.stepOnChange(this.step);
      }
    }
  }

  getStepState(step: IteratorWorkflowStep): Observable<State> {
    const iterate$ =
      step && step.dataSource && step.dataSource.type == DataSourceType.Input
        ? applyParamInput$(step.dataSource.input, {
            context: this.context,
            defaultValue: [],
            raiseErrors: false
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});

    const modelDescription$ = step ? this.getQueryModelDescription(step.dataSource) : of(undefined);

    return combineLatest(iterate$, modelDescription$).pipe(
      map(([iterate, modelDescription]) => {
        return {
          step: step,
          name: step ? step.name : undefined,
          icon: step ? step.getIcon() : undefined,
          type: step ? step.type : undefined,
          iterate: iterate,
          dataSource: step ? step.dataSource : undefined,
          modelDescription: modelDescription
        };
      })
    );
  }

  getQueryModelDescription(dataSource: ListModelDescriptionDataSource) {
    if (
      !dataSource ||
      !dataSource.query ||
      dataSource.query.queryType != QueryType.Simple ||
      !dataSource.query.simpleQuery
    ) {
      return of(undefined);
    }

    const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  onStateUpdated(state: State) {
    if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getStepStateContextOutputs(state), getStepStateContextOutputs(this.state))) {
      this.updateContextOutputs(state);
    }
  }

  initContext() {
    this.contextElement.initGlobal(
      {
        uniqueName: this.step.uid,
        name: this.step.name || this.step.type,
        getFieldValue: (field, outputs) => {
          return outputs[field];
        }
      },
      this.workflowStepsContextElement
    );
  }

  updateIcon() {
    this.icon = this.step.getIcon();
  }

  updateContextInfo(state: State) {
    this.contextElement.initInfo(
      {
        name: state.name || state.type,
        icon: state.icon || 'power'
      },
      true
    );
  }

  updateContextOutputs(state: State) {
    if (state.dataSource) {
      this.contextElement.setOutputs([
        {
          uniqueName: ITEM_OUTPUT,
          name: 'Current Item',
          icon: 'duplicate_2',
          external: true,
          children: rawListViewSettingsColumnsToViewContextOutputs(
            state.dataSource.columns.filter(item => item.type != DisplayFieldType.Computed),
            state.modelDescription
          )
        }
      ]);
    } else {
      this.contextElement.setOutputs([
        {
          uniqueName: ITEM_OUTPUT,
          name: 'Current Item',
          icon: 'duplicate_2',
          fieldType: FieldType.JSON,
          external: true
        }
      ]);
    }

    this.contextElement.setOutputValue(ITEM_OUTPUT, state.iterate);
  }

  dragDrop(event: AppDragDrop<WorkflowStep[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.workflowEditContext.markChanged();
  }

  addStep(step: WorkflowStep, options: { addBeforeIndex?: number } = {}) {
    this.workflowEditContext.registerCreatedElement(step);

    if (isSet(options.addBeforeIndex)) {
      this.step.steps.splice(options.addBeforeIndex, 0, step);
    } else {
      this.step.steps.push(step);
    }

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  duplicateStep(index: number) {
    const step = cloneDeep(this.step.steps[index]);
    const defaultName = `Copy of ${step.name}`;

    step.generateUid();
    step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);

    this.workflowEditContext.registerCreatedElement(step);
    this.step.steps.splice(index + 1, 0, step);

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  deleteStep(index: number) {
    this.step.steps.splice(index, 1);
    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  onRunUpdated(event: WorkflowRunParams) {
    if (this.contextElement) {
      const item = event.params && event.params['iterate'] ? event.params['iterate'][0] : undefined;
      const result = event.success ? event.result : event.error;

      this.contextElement.setOutputValue(ITEM_OUTPUT, item);
      this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
    }
  }

  stepsHasNextConnection(): boolean {
    return !this.step.steps.length || last(this.step.steps).type != WorkflowStepType.Exit;
  }
}

registerWorkflowStepComponent(WorkflowStepType.Iterator, IteratorWorkflowStepComponent);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "./scanner-cameras-dropdown.component";
var styles_ScannerCamerasDropdownComponent = [];
var RenderType_ScannerCamerasDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScannerCamerasDropdownComponent, data: {} });
export { RenderType_ScannerCamerasDropdownComponent as RenderType_ScannerCamerasDropdownComponent };
function View_ScannerCamerasDropdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "dropdown-selector__item-optional"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["current"]))], null, null); }
function View_ScannerCamerasDropdownComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "dropdown-selector__item-check icon-arrow_forward_2"]], null, null, null, null, null))], null, null); }
function View_ScannerCamerasDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "dropdown-selector__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCamera.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "dropdown-selector__item-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "dropdown-selector__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "dropdown-selector__item-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerCamerasDropdownComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerCamerasDropdownComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedCamera == _v.context.$implicit.id); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.selectedCamera != _v.context.$implicit.id); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
function View_ScannerCamerasDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "dropdown-selector dropdown-selector_small-border-radius"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "dropdown-selector__section dropdown-selector__section_margin_xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "dropdown-selector__section-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Choose camera "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "dropdown-selector__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerCamerasDropdownComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cameras; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ScannerCamerasDropdownComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.close.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ScannerCamerasDropdownComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i2.CdkConnectedOverlay, [i2.Overlay, i0.TemplateRef, i0.ViewContainerRef, i2.ɵc, [2, i3.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.opened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ScannerCamerasDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-scanner-cameras-dropdown", [], null, null, null, View_ScannerCamerasDropdownComponent_0, RenderType_ScannerCamerasDropdownComponent)), i0.ɵdid(1, 4440064, null, 0, i4.ScannerCamerasDropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScannerCamerasDropdownComponentNgFactory = i0.ɵccf("app-scanner-cameras-dropdown", i4.ScannerCamerasDropdownComponent, View_ScannerCamerasDropdownComponent_Host_0, { origin: "origin", opened: "opened", cameras: "cameras", selectedCamera: "selectedCamera" }, { selectCamera: "selectCamera", close: "close" }, []);
export { ScannerCamerasDropdownComponentNgFactory as ScannerCamerasDropdownComponentNgFactory };

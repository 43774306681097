<div class="sidebar__element">
  <app-sidebar-field [label]="'link'" [required]="true">
    <div class="control">
      <div class="control__input">
        <app-custom-select
          [control]="form.controls.link"
          [items]="linkOptions"
          [compareWith]="form.linkValueEquals"
          [emptyLabel]="'No pages found'"
          [classes]="['select_fill']"
          [panelClasses]="['mat-menu-panel_sidebar']"
          (buttonClick)="onButtonItemClick($event)"
        >
        </app-custom-select>
      </div>
      <div *ngIf="page" class="control__right">
        <a
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative control__button"
          [appTip]="'Open Page'"
          (click)="openPage()"
        >
          <span class="icon icon-open_folder button__icon"></span>
        </a>
      </div>
    </div>

    <app-field-errors [form]="form" [fieldName]="'link'"></app-field-errors>
  </app-sidebar-field>
</div>

<div *ngIf="form.controls.link.value?.type != segueTypes.PreviousPage" class="sidebar__element">
  <app-sidebar-field [label]="'Open a new tab'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'new_tab',
          field: 'SelectField',
          params: {
            options: form.newTabOptions,
            classes: ['select_fill', form.value['new_tab'] === 'custom' ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>

    <app-input-edit
      *ngIf="form.value['new_tab'] === 'custom'"
      [itemForm]="form.controls.new_tab_custom"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="true"
      [classes]="['input_segment-bottom']"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_external_link_new_tab'"
    ></app-input-edit>
    <ng-container *ngIf="form.value['new_tab'] === 'custom'" description>
      Example: EQ(elements.file_name, "jpg")
    </ng-container>
  </app-sidebar-field>
</div>

<!--    <div *ngIf="modelSegueTypes.includes(form.value['link']?.type)" class="sidebar__element">-->
<!--      <app-sidebar-field [label]="'collection'" [required]="true">-->
<!--        <app-auto-field-->
<!--          [form]="form"-->
<!--          [label]="false"-->
<!--          [field]="createField({ name: 'model', field: 'ModelDescriptionField', params: { classes: ['select_fill'] } })"-->
<!--        ></app-auto-field>-->
<!--      </app-sidebar-field>-->
<!--    </div>-->

export enum ListLayoutType {
  Table = 'table',
  Map = 'map',
  KanbanBoard = 'kanban_board',
  Calendar = 'calendar',
  Grid = 'grid',
  Carousel = 'carousel',
  Timeline = 'timeline',
  Timetable = 'timetable',
  SideList = 'side_list'
}

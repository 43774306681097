import { ComponentRef, OnChanges, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { DynamicComponent } from '@common/dynamic-component';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Dashboard, Widget, WidgetType } from '@modules/dashboard';
import { ChartWidgetComponent } from '../chart-widget/chart-widget.component';
import { UnknownWidgetComponent } from '../unknown-widget/unknown-widget.component';
import { ValueWidgetComponent } from '../value-widget/value-widget.component';
var AutoWidgetComponent = /** @class */ (function () {
    function AutoWidgetComponent() {
        this.fill = true;
        this.mapping = [
            { type: WidgetType.Chart, component: ChartWidgetComponent },
            { type: WidgetType.Value, component: ValueWidgetComponent }
        ];
        this.defaultMappingComponent = UnknownWidgetComponent;
    }
    AutoWidgetComponent.prototype.ngOnDestroy = function () { };
    AutoWidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes.widget) {
            var prevType = changes.widget.previousValue ? changes.widget.previousValue.type : undefined;
            var currentType = changes.widget.currentValue ? changes.widget.currentValue.type : undefined;
            if (prevType != currentType) {
                this.initWidget();
            }
            else {
                this.updateWidget();
            }
        }
        else if (changes.accentColor) {
            this.updateWidget();
        }
    };
    AutoWidgetComponent.prototype.initWidget = function () {
        var _this = this;
        var mapping = this.mapping.find(function (item) { return item.type == _this.widget.type; });
        var component = mapping ? mapping.component : this.defaultMappingComponent;
        this.componentData = {
            component: component,
            inputs: {
                dashboard: this.dashboard,
                widget: this.widget,
                context: this.context,
                contextElement: this.contextElement,
                fill: this.fill,
                accentColor: this.accentColor
            }
        };
    };
    AutoWidgetComponent.prototype.updateWidget = function () {
        if (!this.dynamicComponent ||
            !this.dynamicComponent.currentComponent ||
            !this.dynamicComponent.currentComponent.instance) {
            return;
        }
        var ref = this.dynamicComponent.currentComponent;
        var changes = {};
        var inputs = {
            dashboard: this.dashboard,
            widget: this.widget,
            context: this.context,
            contextElement: this.contextElement,
            fill: this.fill,
            accentColor: this.accentColor
        };
        toPairs(inputs).forEach(function (_a) {
            var name = _a[0], value = _a[1];
            if (ref.instance[name] === value) {
                return;
            }
            changes[name] = new SimpleChange(ref.instance[name], value, false);
            ref.instance[name] = value;
        });
        if (values(changes).length) {
            ref.changeDetectorRef.markForCheck();
            if (ref.instance['ngOnChanges']) {
                ref.instance['ngOnChanges'](changes);
            }
        }
    };
    AutoWidgetComponent.prototype.getData$ = function () {
        if (this.dynamicComponent && this.dynamicComponent.currentComponent.instance.getData$) {
            return this.dynamicComponent.currentComponent.instance.getData$();
        }
    };
    AutoWidgetComponent.prototype.reloadData = function () {
        if (this.dynamicComponent && this.dynamicComponent.currentComponent.instance.reloadData) {
            this.dynamicComponent.currentComponent.instance.reloadData();
        }
    };
    return AutoWidgetComponent;
}());
export { AutoWidgetComponent };

import { Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import defaults from 'lodash/defaults';
import * as moment from 'moment';

import { TypedChanges } from '../../utils/angular/angular';
import { isSet } from '../../utils/common/common';

export interface DateValueAccessorOptions {
  format?: string;
  date?: boolean;
  time?: boolean;
}

export const DefaultDateValueAccessorOptions: DateValueAccessorOptions = {
  format: 'DD.MM.YYYY HH:mm:ss',
  date: true,
  time: true
};

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateValueAccessorDirective),
  multi: true
};

export const timeParseFormats = [moment.ISO_8601, 'hh:mm:ss A', 'HH:mm:ss', 'hh:mm A', 'HH:mm'];

export function parseTime(value: any, format?: string): moment.Moment {
  const result = moment(value, format || timeParseFormats);
  result.toString = () => result.format('HH:mm:SS');
  result.toJSON = () => result.format('HH:mm:SS');
  return result;
}

@Directive({
  selector: '[appDateValue]',
  providers: [DATE_VALUE_ACCESSOR]
})
export class DateValueAccessorDirective implements ControlValueAccessor, OnChanges {
  @Input('appDateValue') options: DateValueAccessorOptions;

  controlValue: string;

  @HostListener('input', ['$event.target.value']) onInputEvent = value => {
    value = this.toValue(value);
    this.controlValue = value;

    this.onChange(value);
  };
  @HostListener('change', ['$event.target.value']) onValueEvent = value => {
    value = this.toValue(value);
    this.controlValue = value;

    this.onChange(value);
  };
  @HostListener('blur', []) onBlurEvent = () => {
    this.onTouched();
  };

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnChanges(changes: TypedChanges<DateValueAccessorDirective>): void {
    if (changes.options && !changes.options.firstChange) {
      this.setValue(this.controlValue);
    }
  }

  get currentOptions(): DateValueAccessorOptions {
    return defaults(this.options, DefaultDateValueAccessorOptions);
  }

  writeValue(value: string): void {
    this.controlValue = value;
    this.setValue(value);
  }

  setValue(value: string) {
    if (!isSet(value)) {
      this.renderer.setProperty(this.el.nativeElement, 'value', null);
      return;
    }

    const timeOnly = !this.currentOptions.date && this.currentOptions.time;
    const dt = timeOnly ? parseTime(value) : moment(value);

    if (!dt.isValid()) {
      return;
    }

    const str = dt.format(this.currentOptions.format);
    this.renderer.setProperty(this.el.nativeElement, 'value', str);
  }

  toValue(value: string) {
    const parsed = moment(value, this.currentOptions.format);

    if (!parsed.isValid()) {
      return null;
    }

    let result;

    if (this.currentOptions.date && this.currentOptions.time) {
      result = parsed.toISOString();
    } else if (this.currentOptions.date && !this.currentOptions.time) {
      result = parsed.format('YYYY-MM-DD');
    } else if (!this.currentOptions.date && this.currentOptions.time) {
      result = parsed.format('HH:mm:ss');
    }

    return result;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }
}

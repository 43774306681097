import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { EmailTemplate } from '../../data/email-template';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string): Observable<EmailTemplate[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'email_templates/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => result.map(item => new EmailTemplate().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, environmentName: string, name: string): Observable<EmailTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `email_templates/${name}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new EmailTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: EmailTemplate): Observable<EmailTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'email_templates/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, instance.serialize(), { headers: headers });
      }),
      map(result => new EmailTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, environmentName: string, instance: EmailTemplate): Observable<EmailTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `email_templates/${instance.name}/`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, instance.serialize(), { headers: headers });
      }),
      map(result => new EmailTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName: string, environmentName: string, instance: EmailTemplate): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `email_templates/${instance.name}/`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { ExitWorkflowStep } from '@modules/workflow';

@Injectable()
export class ExitCustomizeWorkflowStepForm extends FormGroup {
  instance: ExitWorkflowStep;

  controls: {
    name: FormControl;
    error_text: FieldInputControl;
  };

  constructor() {
    super({
      name: new FormControl(''),
      error_text: new FieldInputControl({ name: 'value' })
    });
  }

  init(instance: ExitWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.error_text.patchValue(instance.errorText ? instance.errorText.serialize() : {});
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): ExitWorkflowStep {
    const instance = cloneDeep(this.instance) as ExitWorkflowStep;

    instance.name = this.controls.name.value;

    if (this.controls.error_text.value) {
      instance.errorText = new Input().deserialize(this.controls.error_text.value);
    } else {
      instance.errorText = undefined;
    }

    return instance;
  }
}

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
var NotFoundComponent = /** @class */ (function () {
    function NotFoundComponent(activatedRoute, cd) {
        this.activatedRoute = activatedRoute;
        this.cd = cd;
    }
    NotFoundComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.cd.markForCheck();
        });
    };
    NotFoundComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(NotFoundComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    return NotFoundComponent;
}());
export { NotFoundComponent };

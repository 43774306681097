import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Model } from '@modules/models';

@Injectable()
export class ModelUtilsService {
  constructor() {}

  strFallback(model: Model): string {
    if (model.dbStr) {
      return model.dbStr;
    } else {
      return `ID: ${model.primaryKey}`;
    }
  }

  str(model: Model): Observable<string> {
    if (!model) {
      return of(undefined);
    }

    if (!model.modelDescription || !model.modelDescription.displayField) {
      return of(this.strFallback(model));
    }

    return of(model.getAttribute(model.modelDescription.displayField));
  }
}

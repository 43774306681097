import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FieldType, fileFieldTypes, FormField } from '@modules/fields';
import { TypedChanges } from '@shared';

import { PopupToken } from '../input-tokens/input-tokens.component';

@Component({
  selector: 'app-input-token-value',
  templateUrl: './input-token-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTokenValueComponent implements OnInit, OnChanges {
  @Input() selectedTokenValue: FormControl;
  @Input() selectedToken: PopupToken;
  @Input() useFileObjects = false;
  @Input() autofocus = false;
  @Output() back = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();

  formField: FormField;
  form: FormGroup;
  controlName = 'field';
  fileInput = false;
  fieldTypes = FieldType;

  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form = new FormGroup({
      [this.controlName]: this.selectedTokenValue
    });
  }

  ngOnChanges(changes: TypedChanges<InputTokenValueComponent>): void {
    if (changes.selectedToken) {
      if (this.selectedToken.field == FieldType.Boolean) {
        this.formField = new FormField();
        this.formField.name = this.controlName;
        this.formField.field = FieldType.Select;
        this.formField.params = {
          valueEquals: this.booleanValueEquals,
          options: [
            { value: null, name: 'Not specified' },
            { value: false, name: 'No' },
            { value: true, name: 'Yes' }
          ],
          classes: ['select_fill']
        };
      } else {
        const classes =
          this.selectedToken.params && this.selectedToken.params['classes'] ? this.selectedToken.params['classes'] : [];

        this.formField = new FormField();
        this.formField.name = this.controlName;
        this.formField.field = this.selectedToken.field;
        this.formField.params = {
          ...this.selectedToken.params,
          classes: ['select_fill', 'input_fill', ...classes],
          background: true,
          thumb_inside: true
        };
      }

      this.formField.resetEnabled = !this.selectedToken.fixedValue;
      this.formField.placeholder = this.controlPlaceholder;
      this.fileInput = this.useFileObjects && fileFieldTypes.includes(this.selectedToken.field);
      this.cd.markForCheck();
    }
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.selectedTokenValue.patchValue(file);
  }

  get controlPlaceholder() {
    return `Test value for {{${this.selectedToken.name || 'name'}}}`;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { isLightColor } from '@modules/colors';
import { ShareMenuItem } from '@modules/menu';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { ShareController } from '@modules/share';
import { User } from '@modules/users';
import { isSet, TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-share-menu-item',
  templateUrl: './share-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: ShareMenuItem;

  @HostBinding('style') style: SafeStyle;

  loading = false;
  editAccess = false;
  displayUsers: User[];
  displayUsersCount = 4;
  otherUsersCount = 0;
  colorLight = true;
  colorUnset = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private shareController: ShareController,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.displayUsersCount = !this.horizontal && this.primary ? 1 : 4;
    this.cd.markForCheck();

    combineLatest(this.currentProjectStore.get(), this.currentEnvironmentStore.instance$)
      .pipe(untilDestroyed(this))
      .subscribe(([project, environment]) => {
        this.editAccess = project.hasEnvironmentAccessPermission(environment);
        this.cd.markForCheck();
      });

    this.projectUserService
      .getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
        [PER_PAGE_PARAM]: this.displayUsersCount
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.displayUsers = result.results
            .filter(user => user.user)
            .map(user => user.user)
            .slice(0, this.displayUsersCount + 1)
            .reverse();
          this.otherUsersCount = result.count - this.displayUsers.length;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.updateStyle();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ShareMenuItemComponent>): void {
    if (changes.menuBlock && !changes.menuBlock.firstChange) {
      this.updateStyle();
    }
  }

  updateStyle(): void {
    const vars = {
      ...(this.menuBlock && isSet(this.menuBlock.accentColor) && { 'menu-accent-color': this.menuBlock.accentColor })
    };
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    this.style = this.sanitizer.bypassSecurityTrustStyle(styles);
    this.colorUnset = !this.menuBlock || !isSet(this.menuBlock.accentColor);
    this.colorLight = !this.colorUnset ? isLightColor(this.menuBlock.accentColor) : undefined;
    this.cd.markForCheck();
  }

  share() {
    if (!this.editAccess) {
      return;
    }

    this.shareController.open().pipe(untilDestroyed(this)).subscribe();
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectSettings } from '../../data/project-settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectSettingsService = /** @class */ (function () {
    function ProjectSettingsService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectSettingsService.prototype.get = function (projectName, environmentName, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
            var headers = new HttpHeaders();
            var params = __assign({}, (draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new ProjectSettings().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectSettingsService.prototype.createBulk = function (projectName, environmentName, instances) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instances.map(function (item) { return item.serialize(); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new ProjectSettings().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectSettingsService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers, params: params });
        }), map(function (result) { return new ProjectSettings().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectSettingsService_Factory() { return new ProjectSettingsService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectSettingsService, providedIn: "root" });
    return ProjectSettingsService;
}());
export { ProjectSettingsService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSet } from '@shared';
import { TaskQueueStore } from './task-queue.store';
var TaskStatusSelectSource = /** @class */ (function (_super) {
    __extends(TaskStatusSelectSource, _super);
    function TaskStatusSelectSource(taskQueueStore) {
        var _this = _super.call(this) || this;
        _this.taskQueueStore = taskQueueStore;
        _this.allowEmpty = false;
        _this.emptyName = '---';
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    Object.defineProperty(TaskStatusSelectSource.prototype, "emptyOption", {
        get: function () {
            return {
                value: null,
                name: this.emptyName
            };
        },
        enumerable: true,
        configurable: true
    });
    TaskStatusSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.taskQueueStore.getDetailFirst(this.queue).pipe(map(function (result) {
            _this.page += 1;
            var results = result.statuses
                .filter(function (item) {
                return isSet(searchQuery) ? [item.name].some(function (str) { return str.toLowerCase().includes(searchQuery.toLowerCase()); }) : true;
            })
                .map(function (item) {
                return {
                    value: item.uid,
                    name: item.name
                };
            });
            if (!_this.allowEmpty) {
                return results;
            }
            return [_this.emptyOption].concat(results);
        }));
    };
    TaskStatusSelectSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.taskQueueStore.getDetailFirst(this.queue).pipe(map(function (result) {
            if (result) {
                var resource = result.statuses.find(function (i) { return i.uid == value; });
                if (!resource) {
                    return;
                }
                return {
                    value: resource.uid,
                    name: resource.name
                };
            }
        }));
    };
    TaskStatusSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    TaskStatusSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    TaskStatusSelectSource.prototype.setStaticOptions = function (options) { };
    return TaskStatusSelectSource;
}(SelectSource));
export { TaskStatusSelectSource };

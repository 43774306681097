import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ModelLinkMenuItem } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { RoutingService } from '@modules/routing';
import { TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-model-link-menu-item',
  templateUrl: './model-link-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelLinkMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: ModelLinkMenuItem;

  link: any[];

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.updateLink();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelLinkMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.updateLink();
    }
  }

  updateLink() {
    const modelId = this.menuItem ? this.menuItem.model : undefined;

    this.modelDescriptionStore
      .getDetail(modelId)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.link = result ? result.link : undefined;
        this.cd.markForCheck();
      });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Frame, View, ViewMapping } from '@modules/views';
import { ViewEditorController } from '@modules/views-components';
import { controlValue, isSet } from '@shared';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditCustomControl } from '../customize-bar-pages-edit/custom-control';
import { CustomizeBarPagesEditMenuItemControl } from '../customize-bar-pages-edit/customize-bar-pages-edit.form';

@Component({
  selector: 'app-custom-menu-item-edit',
  templateUrl: './custom-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMenuItemEditComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditCustomControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  view: View;
  submitLoading = false;
  editPopoverOpened = false;
  analyticsSource = 'custom_menu_item';

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private context: MenuContext,
    private contextTokenProvider: ViewContextTokenProvider,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private viewEditorController: ViewEditorController,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.createdOptions && this.createdOptions.customize) {
      this.openViewEditor();
    }

    controlValue(this.control.controls.custom_view)
      .pipe(
        switchMap(uniqueName => this.customViewsStore.getDetailFirst(uniqueName)),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.view = customView ? customView.view : undefined;
        this.cd.markForCheck();
        this.control.setInputsParameters(this.view ? this.view.parameters : []);
      });

    this.control.inputFieldProvider.fields$.pipe(untilDestroyed(this)).subscribe(() => {
      this.control.controls.inputs.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.control.inputFieldProvider.clearProvider();
  }

  onTitleChanged(value: string) {
    this.control.controls.title.patchValue(value);
    this.cd.markForCheck();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  createView(): View {
    const result = new View();

    result.generateId();
    result.name = this.control.controls.title.value;
    result.frame = MenuBlockLayouts.isHorizontal(this.layout)
      ? new Frame({ width: 120, height: 50 })
      : new Frame({ width: 250, height: 50 });

    return result;
  }

  openViewEditor() {
    let view = this.view;
    const create = !view;

    if (!view) {
      view = this.createView();
    }

    return this.viewEditorController
      .open({
        create: create,
        view: view,
        componentLabel: this.control.controls.title.value,
        submitLabel: create ? 'Create menu item' : 'Save changes',
        nameEditingEnabled: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        const uniqueName = create ? undefined : this.control.controls.custom_view.value;
        this.saveCustomViewField(result.view, { uniqueName: uniqueName });
      });
  }

  saveCustomViewField(view: View, options: { uniqueName?: string; mapping?: ViewMapping[] } = {}) {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.submitCardView({ uniqueName: options.uniqueName, view: view })
      .pipe(untilDestroyed(this))
      .subscribe(
        customView => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (!customView) {
            return;
          }

          this.control.controls.title.patchValue(view.name);
          this.control.controls.custom_view.patchValue(customView);
        },
        error => {
          this.submitLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  submitCardView(options: { uniqueName?: string; view?: View } = {}): Observable<string> {
    if (!options.view) {
      return of(undefined);
    }

    const customView$ = isSet(options.uniqueName)
      ? this.customViewsStore.getDetailFirst(options.uniqueName)
      : of<CustomView>(undefined);

    return customView$.pipe(
      switchMap(customView => {
        const menuItemUid = this.control.menuItem.id;
        const fields = ['unique_name', 'view_type', 'view', 'params'];

        if (customView) {
          const instance = cloneDeep<CustomView>(customView);

          instance.view = options.view;
          instance.menuItemUid = menuItemUid;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();

          instance.uniqueName = [CustomViewType.MenuItem, menuItemUid].join('.');
          instance.viewType = CustomViewType.ItemColumn;
          instance.view = options.view;
          instance.menuItemUid = menuItemUid;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }
}

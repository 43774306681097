<app-sidebar-header
  [title]="title"
  [titleEnabled]="titleEnabled"
  [titleEditable]="titleEditable"
  [titleCleanValue]="titleCleanValue"
  [subtitle]="subtitle"
  [backEnabled]="backEnabled"
  [backLabel]="backLabel"
  [deleteTip]="deleteTip"
  [parentLabel]="parentLabel"
  (back)="back()"
  (titleChanged)="titleChanged.next($event)"
  [deleteEnabled]="deleteEnabled"
  (deleted)="deleteClick.emit($event)"
  (parentClick)="parentClick.emit($event)"
>
  <ng-container actions>
    <ng-content select="ng-container[actions]"></ng-content>
  </ng-container>
</app-sidebar-header>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { throwError } from 'rxjs';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectProperty, ProjectPropertyField, ProjectPropertyService, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { isSet } from '@shared';
var ProjectPropertyEditPopupForm = /** @class */ (function (_super) {
    __extends(ProjectPropertyEditPopupForm, _super);
    function ProjectPropertyEditPopupForm(formUtils, currentProjectStore, currentEnvironmentStore, projectPropertyService, projectPropertyStore, modelDescriptionStore, actionStore) {
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            field: new FormControl(FieldType.Text, Validators.required),
            params: new FormControl({}),
            default_value: new FormControl(),
            required: new FormControl(false),
            placeholder: new FormControl('')
        }) || this;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectPropertyService = projectPropertyService;
        _this.projectPropertyStore = projectPropertyStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionStore = actionStore;
        _this.defaultName = 'property';
        _this.defaultValueEnabled = false;
        _this.requiredEnabled = false;
        _this.placeholderEnabled = false;
        return _this;
    }
    ProjectPropertyEditPopupForm.prototype.ngOnDestroy = function () { };
    ProjectPropertyEditPopupForm.prototype.generatePropertyName = function (type) {
        var _this = this;
        var names = this.projectPropertyStore.instance
            .filter(function (item) {
            if (item.type != type || item.deleted) {
                return false;
            }
            if (type == ProjectPropertyType.Page) {
                return item.pageUid == _this.pageUid;
            }
            else {
                return true;
            }
        })
            .reduce(function (acc, item) {
            acc[item.name.toLowerCase()] = item;
            return acc;
        }, {});
        var defaultName = this.defaultName;
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (names.hasOwnProperty(newName.toLowerCase()));
        return newName;
    };
    ProjectPropertyEditPopupForm.prototype.init = function (type, instance, options) {
        if (options === void 0) { options = {}; }
        this.type = type;
        this.instance = instance;
        this.pageUid = options.pageUid;
        this.saveLocal = options.saveLocal;
        this.context = options.context;
        if (isSet(options.defaultName)) {
            this.defaultName = options.defaultName;
        }
        if (isSet(options.defaultValueEnabled)) {
            this.defaultValueEnabled = options.defaultValueEnabled;
        }
        if (isSet(options.requiredEnabled)) {
            this.requiredEnabled = options.requiredEnabled;
        }
        if (isSet(options.placeholderEnabled)) {
            this.placeholderEnabled = options.placeholderEnabled;
        }
        if (instance) {
            this.controls.name.patchValue(instance.name);
            if (instance.field) {
                this.controls.field.patchValue(instance.field.field);
                this.controls.params.patchValue(instance.field.params);
            }
            this.controls.default_value.patchValue(instance.defaultValue);
        }
        else {
            var defaultName = this.generatePropertyName(this.type);
            this.controls.name.patchValue(defaultName);
        }
        if (isSet(options.required)) {
            this.controls.required.patchValue(options.required);
        }
        if (isSet(options.placeholder)) {
            this.controls.placeholder.patchValue(options.placeholder);
        }
    };
    ProjectPropertyEditPopupForm.prototype.getInstance = function () {
        var result = this.instance ? cloneDeep(this.instance) : new ProjectProperty();
        if (!this.instance) {
            result.type = this.type;
        }
        result.name = this.controls.name.value;
        if (!result.field) {
            result.field = new ProjectPropertyField();
        }
        result.field.field = this.controls.field.value;
        result.field.params = this.controls.params.value || {};
        if (this.defaultValueEnabled) {
            result.defaultValue = this.controls.default_value.value;
        }
        if (isSet(this.pageUid)) {
            result.pageUid = this.pageUid;
        }
        if (isSet(this.saveLocal)) {
            result.saveLocal = this.saveLocal;
        }
        result.updateFieldDescription();
        return result;
    };
    ProjectPropertyEditPopupForm.prototype.submit = function () {
        var _this = this;
        var instance = this.getInstance();
        var obs$;
        if (this.instance) {
            obs$ = this.projectPropertyService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        else {
            obs$ = this.projectPropertyService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        return obs$.pipe(delayWhen(function () { return _this.projectPropertyStore.getFirst(true); }), tap(function (result) {
            if ([ProjectPropertyType.User, ProjectPropertyType.Group].includes(_this.type)) {
                _this.modelDescriptionStore.getFirst(true);
                _this.actionStore.getFirst(true);
            }
            if (_this.type == ProjectPropertyType.Global &&
                !isSet(_this.currentEnvironmentStore.globalStorage.getValue(result))) {
                _this.currentEnvironmentStore.globalStorage.setValue(result, result.defaultValue, false);
            }
            else if (_this.type == ProjectPropertyType.Page &&
                _this.context &&
                !isSet(_this.context.pageStorage.getValue(result))) {
                _this.context.pageStorage.setValue(result, result.defaultValue, false);
            }
        }), tap(function (result) {
            _this.instance = result;
        }), map(function (result) {
            return __assign({ property: result }, (_this.requiredEnabled && { required: _this.controls.required.value }), (_this.placeholderEnabled && { placeholder: _this.controls.placeholder.value }));
        }), catchError(function (error) {
            _this.markAsDirty();
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectPropertyEditPopupForm;
}(FormGroup));
export { ProjectPropertyEditPopupForm };

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActionService } from '@modules/action-queries';
import { ViewContext } from '@modules/customize';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { Workflow } from '@modules/workflow';
import { controlValue } from '@shared';
import { WorkflowEditController } from '../../../../services/workflow-edit-controller/workflow-edit.controller';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';
var CustomizeBarActionEditTypeWorkflowComponent = /** @class */ (function () {
    function CustomizeBarActionEditTypeWorkflowComponent(actionService, workflowEditController, contextTokenProvider, cd) {
        this.actionService = actionService;
        this.workflowEditController = workflowEditController;
        this.contextTokenProvider = contextTokenProvider;
        this.cd = cd;
        this.parameters = [];
    }
    CustomizeBarActionEditTypeWorkflowComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(controlValue(this.form.controls.workflow), controlValue(this.form.controls.action_params))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var workflow = _a[0], parameters = _a[1];
            _this.workflow = workflow;
            _this.parameters = parameters;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarActionEditTypeWorkflowComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditTypeWorkflowComponent.prototype.edit = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var workflow;
        var parameters = cloneDeep(this.parameters);
        if (this.workflow) {
            workflow = cloneDeep(this.workflow);
        }
        else {
            workflow = new Workflow();
            workflow.generateUid();
        }
        this.workflowEditController
            .open({
            create: !this.workflow,
            workflow: workflow,
            workflowRun: workflow.testRun,
            workflowEditable: true,
            parametersEnabled: true,
            parameters: parameters,
            context: this.context,
            contextTokenProvider: this.contextTokenProvider,
            triggerLabel: this.form.options.actionLabel,
            customizeTrigger: options.customizeTrigger,
            historyEnabled: true,
            resultEnabled: true,
            analyticsSource: ['component', this.analyticsSource].join('_')
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            result.workflow.testRun = result.workflowRun;
            _this.form.controls.workflow.patchValue(result.workflow);
            _this.form.controls.action_params.patchValue(result.parameters);
            _this.cd.markForCheck();
        });
    };
    return CustomizeBarActionEditTypeWorkflowComponent;
}());
export { CustomizeBarActionEditTypeWorkflowComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';
import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
export var ConditionWorkflowStepType;
(function (ConditionWorkflowStepType) {
    ConditionWorkflowStepType["Boolean"] = "boolean";
    ConditionWorkflowStepType["Switch"] = "switch";
})(ConditionWorkflowStepType || (ConditionWorkflowStepType = {}));
var ConditionWorkflowStepItem = /** @class */ (function () {
    function ConditionWorkflowStepItem() {
        this.steps = [];
    }
    ConditionWorkflowStepItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.label = data['label'];
        if (data['condition']) {
            this.condition = new Input().deserialize(data['condition']);
        }
        if (data['steps']) {
            this.steps = data['steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        return this;
    };
    ConditionWorkflowStepItem.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            label: this.label,
            condition: this.condition ? this.condition.serialize() : undefined,
            steps: this.steps.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ConditionWorkflowStepItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    return ConditionWorkflowStepItem;
}());
export { ConditionWorkflowStepItem };
var ConditionWorkflowStep = /** @class */ (function (_super) {
    __extends(ConditionWorkflowStep, _super);
    function ConditionWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Condition;
        _this.conditionType = ConditionWorkflowStepType.Boolean;
        _this.items = [];
        _this.elseSteps = [];
        return _this;
    }
    ConditionWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.conditionType = data['condition_type'];
        this.elseLabel = data['else_label'];
        if (data['items']) {
            this.items = data['items'].map(function (item) { return new ConditionWorkflowStepItem().deserialize(item); });
        }
        if (data['else_steps']) {
            this.elseSteps = data['else_steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        return this;
    };
    ConditionWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { condition_type: this.conditionType, items: this.items.map(function (item) { return item.serialize(); }), else_steps: this.elseSteps.map(function (item) { return item.serialize(); }), else_label: this.elseLabel });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ConditionWorkflowStep.prototype.defaultName = function () {
        return 'Condition';
    };
    Object.defineProperty(ConditionWorkflowStep.prototype, "analyticsName", {
        get: function () {
            if (this.type) {
                return ['condition', this.type].join('_');
            }
            else {
                return 'condition';
            }
        },
        enumerable: true,
        configurable: true
    });
    ConditionWorkflowStep.prototype.getIcon = function () {
        if (this.conditionType == ConditionWorkflowStepType.Boolean) {
            return 'switch';
        }
        else if (this.conditionType == ConditionWorkflowStepType.Switch) {
            return 'versions';
        }
        else {
            return _super.prototype.getIcon.call(this);
        }
    };
    return ConditionWorkflowStep;
}(WorkflowStep));
export { ConditionWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Condition, ConditionWorkflowStep);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LocalStorage } from '@core';
import { ApiService } from '@modules/api';
import { isSet, SingletonStore } from '@shared';
import { UserService } from '../services/user/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/user/user.service";
import * as i2 from "../../api/services/api/api.service";
import * as i3 from "../../../core/services/local-storage/local.storage";
var CurrentUserStore = /** @class */ (function (_super) {
    __extends(CurrentUserStore, _super);
    function CurrentUserStore(userService, apiService, localStorage) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.apiService = apiService;
        _this.localStorage = localStorage;
        _this._original$ = new BehaviorSubject(undefined);
        _this._override$ = new BehaviorSubject(undefined);
        _this.get();
        return _this;
    }
    CurrentUserStore.prototype.fetchUser = function () {
        var _this = this;
        if (!this.apiService.getAuthorization()) {
            return of(undefined);
        }
        return this.userService.getCurrent(true).pipe(tap(function (user) {
            if (!user) {
                return;
            }
            if (isSet(user.language)) {
                _this.localStorage.setLanguage(user.language);
            }
            if (isSet(user.timezone)) {
                _this.localStorage.setTimezone(user.timezone);
            }
        }));
    };
    CurrentUserStore.prototype.fetch = function () {
        var _this = this;
        return combineLatest(this.fetchUser(), this._override$).pipe(map(function (_a) {
            var user = _a[0], userOverride = _a[1];
            _this._original$.next(user);
            if (isSet(userOverride)) {
                return userOverride;
            }
            else {
                return user;
            }
        }));
    };
    CurrentUserStore.prototype.update = function (fields) {
        return this.userService.update(this.instance, fields);
    };
    Object.defineProperty(CurrentUserStore.prototype, "original", {
        get: function () {
            return this._original$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserStore.prototype, "original$", {
        get: function () {
            return this._original$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserStore.prototype, "override", {
        get: function () {
            return this._override$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentUserStore.prototype, "override$", {
        get: function () {
            return this._override$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CurrentUserStore.prototype.setOverride = function (value) {
        this._override$.next(value);
        this.instance = value ? value : this.original;
    };
    CurrentUserStore.prototype.clearOverride = function () {
        this.setOverride(undefined);
    };
    CurrentUserStore.ngInjectableDef = i0.defineInjectable({ factory: function CurrentUserStore_Factory() { return new CurrentUserStore(i0.inject(i1.UserService), i0.inject(i2.ApiService), i0.inject(i3.LocalStorage)); }, token: CurrentUserStore, providedIn: "root" });
    return CurrentUserStore;
}(SingletonStore));
export { CurrentUserStore };

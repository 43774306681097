import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { FieldType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { capitalize, isSet } from '@shared';

import { ApiParameter } from '../../data/api-parameter';

@Component({
  selector: 'app-api-parameter',
  templateUrl: './api-parameter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiParameterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ApiParameter;

  typeDescription: string;
  childrenExpanded = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.typeDescription = this.item.fieldTypeDescription
        ? this.getTypeDescription(this.item.fieldTypeDescription.field, this.item.fieldTypeDescription.params)
        : undefined;
    }
  }

  setChildrenExpanded(value) {
    this.childrenExpanded = value;
    this.cd.markForCheck();
  }

  toggleChildrenExpanded() {
    this.setChildrenExpanded(!this.childrenExpanded);
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  getTypeDescription(type: FieldType, params?: Object) {
    if (!type) {
      return;
    }

    if (type == FieldType.Number) {
      return 'A number value. This field allows negative and positive numbers.';
    } else if (type == FieldType.Text) {
      if (params && params['multiline']) {
        return 'Multiple lines of text.';
      } else {
        return 'A single line of text.';
      }
    } else if (type == FieldType.RichText) {
      return 'HTML-formatted text.';
    } else if (type == FieldType.Boolean) {
      return 'This field is "true" when checked and otherwise "false".';
    } else if (type == FieldType.URL) {
      return 'A valid URL (e.g. https://www.jetadmin.io/contact).';
    } else if (type == FieldType.Password) {
      return 'Password value.';
    } else if (type == FieldType.JSON) {
      return 'JSON object';
    } else if (type == FieldType.DateTime) {
      if (params && params['time']) {
        return 'ISO date and time with timezone specified, e.g. "2020-07-01T17:55:13+02:00".';
      } else {
        return 'ISO date, e.g. "2020-09-05".';
      }
    } else if (type == FieldType.Time) {
      return 'Exact time, e.g. "12:50:18".';
    } else if (type == FieldType.File) {
      return 'File field represented by full URL.';
    } else if (type == FieldType.Image) {
      return 'Image file represented by full URL.';
    } else if (type == FieldType.Video) {
      return 'Video file represented by full URL.';
    } else if (type == FieldType.Audio) {
      return 'Audio file represented by full URL.';
    } else if (type == FieldType.Select) {
      return 'Selected option value.';
    } else if (type == FieldType.MultipleSelect) {
      return 'Array of selected option values.';
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { ServerRequestError } from '@modules/api';
import { Domain } from '@modules/domain';
import { DNSCNameRecord } from '@modules/emails';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { capitalize, isSet } from '@shared';

import { EmailAddressEditForm } from './email-address-edit.form';

@Component({
  selector: 'app-email-address-edit-popup',
  templateUrl: './email-address-edit-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmailAddressEditForm]
})
export class EmailAddressEditPopupComponent implements OnInit, OnDestroy {
  @Output() added = new EventEmitter<Domain>();
  @Output() deleted = new EventEmitter<Domain>();

  createField = createFormFieldFactory();
  domain: Domain;
  dnsRecords: DNSCNameRecord[];
  dnsRecordsLoading = true;
  dnsRecordsError: string;
  dnsRecordsAdded = false;
  submitLoading = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    public form: EmailAddressEditForm,
    public appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.domain = project.domain;
        this.form.init(project.domain);
        this.cd.markForCheck();

        this.form
          .initiateCustomEmail()
          .pipe(untilDestroyed(this))
          .subscribe(
            result => {
              this.dnsRecords = result;
              this.dnsRecordsLoading = false;
              this.cd.markForCheck();
            },
            error => {
              this.dnsRecordsLoading = false;

              if (error instanceof ServerRequestError && error.errors.length) {
                this.dnsRecordsError = error.errors[0];
              } else {
                this.dnsRecordsError = error;
              }

              this.cd.markForCheck();
            }
          );
      });
  }

  ngOnDestroy(): void {}

  backToDNSRecords() {
    this.dnsRecordsAdded = false;
    this.cd.markForCheck();
  }

  continueSetup() {
    this.dnsRecordsAdded = true;
    this.cd.markForCheck();
  }

  continueSetupOrSubmit() {
    if (!this.dnsRecordsAdded) {
      this.continueSetup();
    } else if (this.dnsRecordsAdded && this.form.valid && !this.submitLoading) {
      this.submit();
    }
  }

  submit() {
    if (!isSet(this.domain.emailConfigFrom)) {
      this.submitLoading = true;
      this.cd.markForCheck();

      this.form
        .setupCustomEmail()
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.added.emit(this.domain);

            this.notificationService.success(
              'Email address is set',
              `From Email address was set to <strong>${this.form.controls.email_from.value}</strong>`
            );

            this.close();
          },
          error => {
            console.error(error);
            this.submitLoading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      this.submitLoading = true;
      this.cd.markForCheck();

      const domain = this.form.domain;

      this.form
        .deleteCustomEmail()
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            if (result) {
              this.deleted.emit(domain);

              this.notificationService.success('Email address disconnected', `From Email address was disconnected`);
            }

            this.close();
          },
          error => {
            console.error(error);
            this.submitLoading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  close() {
    this.popupComponent.close();
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }
}

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { gtFieldLookup, lteFieldLookup } from '../base-lookups';
import { FieldLookup } from './base';

export class IsFutureFieldLookup extends FieldLookup {
  public lookup = 'is_future';
  public param = 'isFuture';
  public verboseName = localize('is future');
  public icon = 'calendar';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not in the future', [field]);
      } else {
        return localize('is not in the future');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is in the future', [field]);
      } else {
        return localize('in the future');
      }
    }
  }

  compute(
    field: string[],
    value: any,
    exclude: boolean
  ): { field: string[]; lookup: FieldLookup; value: any; exclude?: boolean }[] {
    return exclude
      ? [
          {
            field: field,
            lookup: lteFieldLookup,
            value: moment().toISOString()
          }
        ]
      : [
          {
            field: field,
            lookup: gtFieldLookup,
            value: moment().toISOString()
          }
        ];
  }
}

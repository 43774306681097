var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { intercomResourceParamsMenuSettings } from '../../data/intercom/intercom-resource-params-menu-settings.stub';
import { intercomResourceParamsModelDescriptions } from '../../data/intercom/intercom-resource-params-model-descriptions.stub';
import { intercomResourceParamsParams } from '../../data/intercom/intercom-resource-params-params.stub';
import { intercomResourceParamsViewSettings } from '../../data/intercom/intercom-resource-params-view-settings.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var IntercomGeneratorService = /** @class */ (function (_super) {
    __extends(IntercomGeneratorService, _super);
    function IntercomGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        return _this;
    }
    IntercomGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = 'https://api.intercom.io/contacts';
        query.headers = [{ name: 'Authorization', value: "Bearer " + options.key }];
        return this.httpQueryService.request(query).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
                error = new ServerRequestError('API Key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    IntercomGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            return {
                key: result.value
            };
        }));
    };
    IntercomGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = intercomResourceParamsParams;
        var modelDescriptions = intercomResourceParamsModelDescriptions;
        var viewSettings = intercomResourceParamsViewSettings;
        var menuSettings = intercomResourceParamsMenuSettings;
        var token = new SecretToken();
        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.key;
        return of({
            resourceParams: resourceParams,
            modelDescriptions: modelDescriptions,
            viewSettings: viewSettings,
            menuSettings: menuSettings,
            secretTokens: [token.serialize()]
        });
    };
    return IntercomGeneratorService;
}(ResourceGeneratorService));
export { IntercomGeneratorService };

<div class="sidebar__element">
  <app-sidebar-field [label]="'Variable'">
    <app-custom-select
      [control]="form.controls.set_property_action_property"
      [items]="propertyOptions"
      [classes]="['select_fill']"
      [panelClasses]="['mat-menu-panel_sidebar']"
      [capitalize]="false"
      (buttonClick)="onButtonItemClick($event)"
    >
    </app-custom-select>
  </app-sidebar-field>
</div>

<div *ngIf="currentProperty" class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'Value'"
    [itemForm]="form.controls.set_property_action_value"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="currentProperty.field?.field"
    [staticValueParams]="currentProperty.field?.params"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_set_property_action_value'"
  >
    <div *ngIf="form.controls.set_property_action_value.invalid && form.dirty" class="field__errors">
      <div class="field__error">required</div>
    </div>
  </app-customize-bar-input-edit>
</div>

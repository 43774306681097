import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { ActionService } from '@modules/action-queries';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldOutput, InputValueType } from '@modules/fields';
import { WorkflowResult } from '@modules/workflow';

import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowResultForm, WorkflowResultType } from './customize-workflow-result.form';

@Component({
  selector: 'app-customize-workflow-result',
  templateUrl: './customize-workflow-result.component.html',
  providers: [CustomizeWorkflowResultForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeWorkflowResultComponent implements OnInit, OnDestroy {
  @Input() result: WorkflowResult;
  @Input() resultOutputs: FieldOutput[];
  @Input() workflowEditable = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() analyticsSource: string;
  @Output() resultChange = new EventEmitter<WorkflowResult>();
  @Output() closeCustomize = new EventEmitter<void>();

  createField = createFormFieldFactory();
  workflowResultTypes = WorkflowResultType;
  inputValueTypes = InputValueType;
  outputs: FieldOutput[] = [];
  arrayOutput = false;
  outputsSubscription: Subscription;

  constructor(
    public form: CustomizeWorkflowResultForm,
    private workflowEditContext: WorkflowEditContext,
    private actionService: ActionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.result, this.resultOutputs);
    this.initOutputs();

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        map(() => this.form.submit()),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.submit(result);
        this.initOutputs();
      });
  }

  ngOnDestroy(): void {}

  initOutputs() {
    if (this.outputsSubscription) {
      this.outputsSubscription.unsubscribe();
      this.outputsSubscription = undefined;
    }

    this.outputsSubscription = this.workflowEditContext
      .state$()
      .pipe(
        switchMap(state => this.actionService.getWorkflowOutputs(state.workflow)),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.outputs = result.outputs;
        this.arrayOutput = result.arrayOutput;
        this.cd.markForCheck();
      });
  }

  submit(value?: WorkflowResult) {
    if (!value) {
      value = this.form.submit();
    }

    this.resultChange.emit(value);
  }

  resetParameters() {
    this.form.resetArrayInputParameters({
      context: this.context,
      contextElement: this.contextElement,
      markAsDirty: true
    });
  }
}

import { OverlayRef } from '@angular/cdk/overlay';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { Observable, Subject } from 'rxjs';

export const POPUP_REF_OPTIONS = new InjectionToken<any>('POPUP_REF_OPTIONS');

@Injectable()
export class PopupRef<T = any> {
  public overlayRef: OverlayRef;
  public animate: boolean;
  public instance?: T;
  public popup?: HTMLElement;
  public backdrop?: HTMLElement;

  tl = new TimelineMax();
  _closed$ = new Subject<void>();

  constructor(@Optional() @Inject(POPUP_REF_OPTIONS) options: Partial<PopupRef> = {}) {
    Object.assign(this, options);
  }

  show() {
    if (!this.animate) {
      return;
    }

    this.tl
      .clear()
      .fromTo(
        this.backdrop || [],
        0.15,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        },
        0
      )
      .fromTo(
        this.popup,
        0.15,
        {
          y: -10,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1
        },
        0
      )
      .pause(0)
      .play();
  }

  close() {
    if (!this.animate) {
      this.dispose();
      return;
    }

    this.tl
      .clear()
      .to(
        this.popup,
        0.15,
        {
          opacity: 0,
          y: 10
        },
        0
      )
      .to(
        this.backdrop || [],
        0.2,
        {
          opacity: 0
        },
        0
      )
      .add(() => {
        this.dispose();
      })
      .pause(0)
      .play();
  }

  dispose() {
    this.overlayRef.dispose();
    this._closed$.next();
  }

  get closed$(): Observable<void> {
    return this._closed$.asObservable();
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import keys from 'lodash/keys';
import { map } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { FieldType, OptionsType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ResourceType, SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { ListModelDescriptionQuery, ModelDescriptionQuery, ModelDescriptionSimpleQuery, QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService, XANO_COLLECTION_MODEL_CREATE_PARAM_NAME, XANO_COLLECTION_MODEL_DELETE_PARAM_NAME, XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME, XANO_COLLECTION_MODEL_GET_PARAM_NAME, XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME, XANO_PRIMARY_KEY_FIELD_NAME } from '@modules/resources';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { XANO_CREATE_REGEX, XANO_DELETE_REGEX, XANO_GET_DETAIL_REGEX, XANO_GET_REGEX, XANO_RELATION_2_REGEX, XANO_RELATION_REGEX, XANO_STORAGE_NAME, XANO_UPDATE_REGEX } from './xano-constants';
var XanoLegacyGeneratorService = /** @class */ (function (_super) {
    __extends(XanoLegacyGeneratorService, _super);
    function XanoLegacyGeneratorService(mode, resourceControllerService, secretTokenService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.resourceControllerService = resourceControllerService;
        _this.secretTokenService = secretTokenService;
        _this.controller = _this.resourceControllerService.get(ResourceType.Xano).legacyController;
        return _this;
    }
    XanoLegacyGeneratorService.prototype.isOptionsValid = function (options) {
        return this.controller.getSchema(options.apiBaseUrl).pipe(map(function (swaggerApi) {
            if (!keys(swaggerApi.paths).length) {
                throw new ServerRequestError('Not API endpoints found');
            }
            return {
                accountName: options.apiBaseUrl
            };
        }));
    };
    XanoLegacyGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            return {
                apiBaseUrl: resource.params['api_base_url'],
                authHeader: result.value
            };
        }));
    };
    XanoLegacyGeneratorService.prototype.fetchApi = function (options) {
        var _this = this;
        return this.controller.getSchema(options.apiBaseUrl).pipe(map(function (swaggerApi) {
            var parsedModelDescriptions = new Map();
            for (var _i = 0, _a = keys(swaggerApi.paths); _i < _a.length; _i++) {
                var path = _a[_i];
                var schema = swaggerApi.paths[path];
                var splitPath = path.split('/');
                var modelDescription = parsedModelDescriptions.get(splitPath[1]);
                if (modelDescription === undefined) {
                    modelDescription = new ModelDescription();
                    modelDescription.project = '{{project}}';
                    modelDescription.resource = '{{resource}}';
                    modelDescription.model = splitPath[1];
                    modelDescription.verboseName = modelDescription.model;
                    modelDescription.verboseNamePlural = modelDescription.model;
                    modelDescription.primaryKeyField = XANO_PRIMARY_KEY_FIELD_NAME;
                    parsedModelDescriptions.set(splitPath[1], modelDescription);
                }
                _this.parseModelDescriptionSchema(modelDescription, schema);
            }
            var modelDescriptions = Array.from(parsedModelDescriptions.values()).filter(function (item) { return item.getQuery && item.fields.length !== 0; });
            modelDescriptions.forEach(function (modelDescription) {
                modelDescription.fields
                    .filter(function (field) { return field.item.field === FieldType.RelatedModel; })
                    .forEach(function (field) {
                    var schema = field.item.params['schema'];
                    delete field.item.params['schema'];
                    var _a = _this.detectCollectionSchemaField(field.name, schema, false), actualFieldType = _a.fieldType, actualParams = _a.params;
                    var relatedModel = modelDescriptions.find(function (item) { return item.model == field.item.params['related_model']['model']; });
                    var relatedModelPk = relatedModel && isSet(relatedModel.primaryKeyField)
                        ? relatedModel.fields.find(function (item) { return item.name == relatedModel.primaryKeyField; })
                        : undefined;
                    if (!relatedModel || !relatedModelPk || relatedModelPk.item.field != actualFieldType) {
                        field.item.field = actualFieldType;
                        field.item.params = actualParams;
                    }
                });
            });
            var storage = _this.createStorageFromSchema(swaggerApi.paths['/upload/attachment']);
            return {
                modelDescriptions: modelDescriptions,
                storage: storage
            };
        }));
    };
    XanoLegacyGeneratorService.prototype.parseModelDescriptionSchema = function (modelDescription, schema) {
        for (var method in schema) {
            if (schema[method].summary.match(XANO_GET_REGEX)) {
                modelDescription.getQuery = this.generateModelDescriptionGet(modelDescription);
                modelDescription.params[XANO_COLLECTION_MODEL_GET_PARAM_NAME] = method;
                if (schema[method].responses['200'] !== undefined) {
                    modelDescription.fields = this.parseModelDescriptionSchemaFields(schema[method].responses['200']);
                }
            }
            else if (schema[method].summary.match(XANO_GET_DETAIL_REGEX)) {
                modelDescription.getDetailQuery = this.generateModelDescriptionGetDetail(modelDescription);
                modelDescription.getDetailParametersUseDefaults = true;
                modelDescription.params[XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME] = method;
            }
            else if (schema[method].summary.match(XANO_CREATE_REGEX)) {
                modelDescription.createQuery = this.generateModelDescriptionCreate(modelDescription);
                modelDescription.createParametersUseDefaults = true;
                modelDescription.params[XANO_COLLECTION_MODEL_CREATE_PARAM_NAME] = method;
            }
            else if (schema[method].summary.match(XANO_UPDATE_REGEX)) {
                modelDescription.updateQuery = this.generateModelDescriptionUpdate(modelDescription);
                modelDescription.updateParametersUseDefaults = true;
                modelDescription.params[XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME] = method;
            }
            else if (schema[method].summary.match(XANO_DELETE_REGEX)) {
                modelDescription.deleteQuery = this.generateModelDescriptionDelete(modelDescription);
                modelDescription.deleteParametersUseDefaults = true;
                modelDescription.params[XANO_COLLECTION_MODEL_DELETE_PARAM_NAME] = method;
            }
        }
    };
    XanoLegacyGeneratorService.prototype.generateModelDescriptionGet = function (modelDescription) {
        var getSimpleQuery = new ModelDescriptionSimpleQuery();
        getSimpleQuery.model = modelDescription.model;
        var getQuery = new ListModelDescriptionQuery();
        getQuery.queryType = QueryType.Simple;
        getQuery.simpleQuery = getSimpleQuery;
        return getQuery;
    };
    XanoLegacyGeneratorService.prototype.generateModelDescriptionGetDetail = function (modelDescription) {
        var getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
        getDetailSimpleQuery.model = modelDescription.model;
        var getDetailQuery = new ModelDescriptionQuery();
        getDetailQuery.queryType = QueryType.Simple;
        getDetailQuery.simpleQuery = getDetailSimpleQuery;
        return getDetailQuery;
    };
    XanoLegacyGeneratorService.prototype.generateModelDescriptionCreate = function (modelDescription) {
        var createSimpleQuery = new ModelDescriptionSimpleQuery();
        createSimpleQuery.model = modelDescription.model;
        var createQuery = new ModelDescriptionQuery();
        createQuery.queryType = QueryType.Simple;
        createQuery.simpleQuery = createSimpleQuery;
        return createQuery;
    };
    XanoLegacyGeneratorService.prototype.generateModelDescriptionUpdate = function (modelDescription) {
        var updateSimpleQuery = new ModelDescriptionSimpleQuery();
        updateSimpleQuery.model = modelDescription.model;
        var updateQuery = new ModelDescriptionQuery();
        updateQuery.queryType = QueryType.Simple;
        updateQuery.simpleQuery = updateSimpleQuery;
        return updateQuery;
    };
    XanoLegacyGeneratorService.prototype.generateModelDescriptionDelete = function (modelDescription) {
        var deleteSimpleQuery = new ModelDescriptionSimpleQuery();
        deleteSimpleQuery.model = modelDescription.model;
        var deleteQuery = new ModelDescriptionQuery();
        deleteQuery.queryType = QueryType.Simple;
        deleteQuery.simpleQuery = deleteSimpleQuery;
        return deleteQuery;
    };
    XanoLegacyGeneratorService.prototype.parseModelDescriptionSchemaFields = function (responseSchema) {
        var result = [];
        var schema;
        if (responseSchema.content['application/json'].schema) {
            schema = responseSchema.content['application/json'].schema;
        }
        if (schema === undefined) {
            return [];
        }
        var items = {};
        if (schema.type === 'object' && schema.properties && schema.properties['items'] !== undefined) {
            items = schema.properties['items']['items'];
        }
        if (schema.type === 'array') {
            items = schema.items;
        }
        if (items.type !== 'object') {
            return [];
        }
        for (var _i = 0, _a = keys(items.properties); _i < _a.length; _i++) {
            var propertyKey = _a[_i];
            var property = items.properties[propertyKey];
            if (property.type === undefined) {
                continue;
            }
            result.push(this.parseModelDescriptionSchemaField(propertyKey, property));
        }
        return result;
    };
    XanoLegacyGeneratorService.prototype.parseModelDescriptionSchemaField = function (propertyKey, schema) {
        var _a = this.detectCollectionSchemaField(propertyKey, schema), fieldType = _a.fieldType, params = _a.params;
        var field = new ModelField();
        var dbField = new ModelDbField();
        dbField.name = propertyKey;
        dbField.verboseName = propertyKey;
        dbField.required = schema.nullable === undefined ? true : !schema.nullable;
        dbField.editable = propertyKey != XANO_PRIMARY_KEY_FIELD_NAME;
        dbField.field = fieldType;
        dbField.params = params;
        dbField.updateFieldDescription();
        field.name = dbField.name;
        field.type = ModelFieldType.Db;
        field.item = dbField;
        return field;
    };
    XanoLegacyGeneratorService.prototype.detectCollectionSchemaRelationField = function (propertyKey) {
        for (var _i = 0, _a = [XANO_RELATION_REGEX, XANO_RELATION_2_REGEX]; _i < _a.length; _i++) {
            var regex = _a[_i];
            var match = propertyKey.match(regex);
            if (match) {
                return match[1];
            }
        }
        return null;
    };
    XanoLegacyGeneratorService.prototype.detectCollectionSchemaField = function (propertyKey, schema, allowRelation) {
        if (allowRelation === void 0) { allowRelation = true; }
        var params;
        var fieldType = this.detectPrimitiveFieldType(schema.type);
        var relationField = allowRelation ? this.detectCollectionSchemaRelationField(propertyKey) : null;
        if (schema.format === 'timestamptz') {
            params = {
                date: true,
                time: true
            };
            fieldType = FieldType.DateTime;
        }
        else if (schema.format === 'date') {
            params = {
                date: true,
                time: false
            };
            fieldType = FieldType.DateTime;
        }
        else if (schema.enum !== undefined) {
            params = {
                options_type: OptionsType.Static,
                options: schema.enum.map(function (item) {
                    return {
                        value: item,
                        name: item
                    };
                })
            };
            fieldType = FieldType.Select;
        }
        else if (allowRelation && relationField !== null && schema.type !== 'array') {
            params = {
                value_format: {
                    number_fraction: 0
                },
                related_model: {
                    model: relationField
                },
                schema: schema
            };
            fieldType = FieldType.RelatedModel;
        }
        else if (schema.type === 'object') {
            if (schema.properties !== undefined &&
                schema.properties['path'] !== undefined &&
                schema.properties['url'] !== undefined &&
                schema.properties['mime'] !== undefined) {
                fieldType = FieldType.File;
                params = {
                    storage_resource: '{{resource}}',
                    storage_name: XANO_STORAGE_NAME
                };
            }
        }
        else if (schema.type === 'integer') {
            params = {
                value_format: {
                    number_fraction: 0
                }
            };
        }
        return { fieldType: fieldType, params: params };
    };
    XanoLegacyGeneratorService.prototype.detectPrimitiveFieldType = function (type) {
        switch (type) {
            case 'integer': {
                return FieldType.Number;
            }
            case 'boolean': {
                return FieldType.Boolean;
            }
            case 'number': {
                return FieldType.Number;
            }
            case 'object': {
                return FieldType.JSON;
            }
            case 'string': {
                return FieldType.Text;
            }
            case 'array': {
                return FieldType.JSON;
            }
            default: {
                return FieldType.Text;
            }
        }
    };
    XanoLegacyGeneratorService.prototype.createStorageFromSchema = function (schema) {
        if (!schema) {
            return;
        }
        var storage = new Storage();
        var method = keys(schema)[0];
        var file_parameter = keys(schema[method].requestBody)[0];
        storage.uniqueName = XANO_STORAGE_NAME;
        storage.name = 'Attachments';
        storage.uploadQuery = new StorageQuery();
        storage.uploadQuery.queryType = QueryType.Simple;
        storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();
        storage.extraParams = {
            upload: {
                method: method,
                file_parameter: file_parameter
            }
        };
        return storage;
    };
    XanoLegacyGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        return this.fetchApi(options).pipe(map(function (result) {
            if (!result.modelDescriptions.length) {
                throw new ServerRequestError('No CRUD API endpoints found');
            }
            var resourceParams = {
                api_base_url: options.apiBaseUrl
            };
            var token = new SecretToken();
            token.name = HTTP_QUERY_KEY_AUTH_NAME;
            token.type = SecretTokenType.Static;
            token.value = options.authHeader;
            return {
                resourceParams: resourceParams,
                modelDescriptions: result.modelDescriptions.map(function (item) { return item.serialize(); }),
                secretTokens: [token.serialize()],
                storages: result.storage ? [result.storage.serialize()] : []
            };
        }));
    };
    return XanoLegacyGeneratorService;
}(ResourceGeneratorService));
export { XanoLegacyGeneratorService };

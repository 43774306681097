<section class="layout-editor">
  <div class="layout-editor__inner">
    <div class="layout-editor__content" (click)="onPreviewBackgroundClick()">
      <div class="layout-editor__layout browser-window">
        <div class="browser-window__header">
          <app-browser-header
            [title]="(domain?.name | appIsSet) ? domain?.name : metaService.jetMetaData.titleShort"
            [address]="projectHome"
            [addressIcon]="domain?.icon"
          ></app-browser-header>
        </div>
        <div class="browser-window__content">
          <div class="browser-window__content-inner browser-window__content-inner_interactive">
            <app-admin-template
              [projectForm]="projectForm"
              [menuForm]="menuForm"
              [settingsForm]="settingsForm"
              [blockControlHover]="blockControlHover$.value"
              [blockControlActive]="blockControlActive$.value"
              [contentDisabled]="menuForm.controls.blocks.length > 0"
              (blockControlPreviewHover)="blockControlPreviewHover$.next($event)"
              (blockControlPreviewClick)="$event.event.stopPropagation(); onBlockControlPreviewClick($event.control)"
            ></app-admin-template>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-editor__sidebar">
      <div *ngIf="!loading" class="sidebar">
        <div class="sidebar__scrollable sidebar__scrollable_native" #root>
          <div *ngFor="let item of settingsComponents; let last = last" [hidden]="!last">
            <app-dynamic-component [componentData]="item"></app-dynamic-component>
          </div>
        </div>
      </div>

      <app-sidebar-empty *ngIf="loading" [animating]="true"></app-sidebar-empty>
    </div>
  </div>
</section>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { BaseMultipleUploadComponent } from '../base-multiple-upload.component';
var MultipleImageUploadComponent = /** @class */ (function (_super) {
    __extends(MultipleImageUploadComponent, _super);
    function MultipleImageUploadComponent(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) {
        return _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) || this;
    }
    return MultipleImageUploadComponent;
}(BaseMultipleUploadComponent));
export { MultipleImageUploadComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsCurrentQuarterFieldLookup = /** @class */ (function (_super) {
    __extends(IsCurrentQuarterFieldLookup, _super);
    function IsCurrentQuarterFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_current_quarter';
        _this.param = 'isCurrentQuarter';
        _this.verboseName = localize('is Current Quarter');
        _this.icon = 'calendar';
        return _this;
    }
    IsCurrentQuarterFieldLookup.prototype.from = function (value) {
        return moment().startOf('quarter').toISOString();
    };
    IsCurrentQuarterFieldLookup.prototype.to = function (value) {
        return moment().endOf('quarter').toISOString();
    };
    IsCurrentQuarterFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not current quarter', [field]);
            }
            else {
                return localize('is not current quarter');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is current quarter', [field]);
            }
            else {
                return localize('current quarter');
            }
        }
    };
    return IsCurrentQuarterFieldLookup;
}(BetweenFieldLookup));
export { IsCurrentQuarterFieldLookup };

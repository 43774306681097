import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  encodeValue(v: any): string {
    if (!v) {
      return v;
    }
    return v.toString().replace(/;/g, '%3B').replace(/\+/g, '%2B');
  }
}

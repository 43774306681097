import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import {
  IsOptionsValidResult,
  SalesforceGeneratorService,
  SalesforceParamsOptions
} from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class SalesforceResourceSettingsForm extends BaseResourceSettingsForm<SalesforceParamsOptions> {
  tokenName = 'oauth_access_token';

  form = new AppFormGroup({
    domain: new FormControl('', Validators.required),
    key: new FormControl('', Validators.required),
    secret: new FormControl('', Validators.required),
    access_token: new FormControl(null, Validators.required),
    params: new FormControl(null, Validators.required)
  });

  constructor(
    private salesforceGeneratorService: SalesforceGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.salesforceGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          domain: result.domain,
          key: result.key,
          secret: result.secret,
          access_token: result.access_token,
          params: result.token_params
        });
      })
    );
  }

  getOptions(): SalesforceParamsOptions {
    return {
      domain: this.form.value['domain'],
      key: this.form.value['key'],
      secret: this.form.value['secret'],
      access_token: this.form.value['access_token'],
      token_params: this.form.value['params']
    };
  }

  isAccountParametersValid() {
    return this.form.controls['domain'].valid && this.form.controls['key'].valid && this.form.controls['secret'].valid;
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.salesforceGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const options = this.getOptions();
    return this.salesforceGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name']
        };
      })
    );
  }
}

<div
  class="toast2"
  [class.toast2_info]="info"
  [class.toast2_warning]="warning"
  [class.toast2_visible]="!minimized"
  [class.toast2_expands]="expands"
>
  <div class="toast2__content">
    <div class="toast2__description">
      <ng-content select="[data-description]"></ng-content>
    </div>

    <div class="toast2__buttons" [class.toast2__buttons_center]="buttonsCenter">
      <ng-content select="[data-buttons]"></ng-content>
    </div>
  </div>

  <div class="toast2__inner">
    <div class="toast2__icon" [ngClass]="icon ? 'icon-' + icon : 'icon-warning_filled'"></div>
    <div class="toast2__main toast2__title">
      <ng-content select="[data-title]"></ng-content>
    </div>
    <div class="toast2__close icon-close" (click)="setMinimized(true)"></div>
  </div>
</div>

<div
  class="toast2-badge"
  [class.toast2-badge_visible]="minimized"
  [class.toast2-badge_info]="info"
  [class.toast2-badge_warning]="warning"
  [class.toast2-badge_shift]="shift"
  [ngClass]="icon ? 'icon-' + icon : 'icon-warning_filled'"
  (click)="setMinimized(false)"
></div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./custom-code.component";
var styles_CustomCodeComponent = [];
var RenderType_CustomCodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomCodeComponent, data: {} });
export { RenderType_CustomCodeComponent as RenderType_CustomCodeComponent };
export function View_CustomCodeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { scriptsElement: 0 }), i0.ɵqud(402653184, 2, { stylesElement: 0 }), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["styles", 1]], null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["scripts", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CustomCodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-code", [], null, null, null, View_CustomCodeComponent_0, RenderType_CustomCodeComponent)), i0.ɵdid(1, 638976, null, 0, i1.CustomCodeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomCodeComponentNgFactory = i0.ɵccf("app-custom-code", i1.CustomCodeComponent, View_CustomCodeComponent_Host_0, { appendScripts: "appendScripts", appendStyles: "appendStyles" }, {}, []);
export { CustomCodeComponentNgFactory as CustomCodeComponentNgFactory };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class CustomizeBarCustomCodeEditForm extends FormGroup {
  controls: {
    append_scripts: FormControl;
    append_styles: FormControl;
  };

  constructor() {
    super({
      append_scripts: new FormControl(''),
      append_styles: new FormControl('')
    });
  }

  init(appendScripts: string, appendStyles: string) {
    this.controls.append_scripts.patchValue(appendScripts);
    this.controls.append_styles.patchValue(appendStyles);

    this.markAsPristine();
  }
}

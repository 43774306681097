import * as moment from 'moment';
import { Plan } from './plan';
import { SubscriptionPeriod } from './subscription-period';
var SubscriptionFreeTrial = /** @class */ (function () {
    function SubscriptionFreeTrial() {
    }
    SubscriptionFreeTrial.prototype.deserialize = function (data) {
        this.days = data['days'];
        return this;
    };
    return SubscriptionFreeTrial;
}());
export { SubscriptionFreeTrial };
var Subscription = /** @class */ (function () {
    function Subscription() {
        this.isActive = false;
        this.onPremise = false;
        this.periods = [];
        this.params = {};
        // get currentPeriod() {
        //   return this.periods.find(item => !item.dateEnd);
        // }
        //
        // get nextCharge() {
        //   if (!this.currentPeriod) {
        //     return;
        //   }
        //
        //   return this.currentPeriod.dateBegin.clone().add('month', 1);
        // }
        // get currentActivities() {
        //   const period = this.currentPeriod;
        //
        //   if (!period) {
        //     return 0;
        //   }
        //
        //   return period.activities;
        // }
        // get currentMultiplier() {
        //   return Math.ceil(this.currentActivities / this.plan.activitiesPerPrice);
        // }
        // get currentActivitiesMax() {
        //   if (this.plan.activitiesMax) {
        //     return this.plan.activitiesMax;
        //   }
        //
        //   return this.plan.activitiesPerPrice * this.currentMultiplier;
        // }
        // get currentActivitiesPercentage() {
        //   return Math.min(this.currentActivities / this.currentActivitiesMax, 1) * 100;
        // }
        // get currentPrice() {
        //   return this.plan.price * this.currentMultiplier;
        // }
    }
    Subscription.prototype.deserialize = function (data) {
        this.dateBegin = data['date_begin'] ? moment(data['date_begin']) : undefined;
        this.dateEnd = data['date_end'] ? moment(data['date_end']) : undefined;
        this.dateEndActual = data['date_end_actual'] ? moment(data['date_end_actual']) : undefined;
        this.isActive = data['is_active'];
        this.billingPeriod = data['billing_period'];
        if (data['periods']) {
            this.periods = data['periods'].map(function (item) { return new SubscriptionPeriod().deserialize(item); });
        }
        if (data['plan']) {
            this.plan = new Plan().deserialize(data['plan']);
        }
        if (data['params']) {
            this.params = data['params'];
            this.price = this.params['price'];
            this.subscriptionId = this.params['subscription_id'];
            if (this.params['free_trial']) {
                this.freeTrial = new SubscriptionFreeTrial().deserialize(this.params['free_trial']);
            }
            this.dateCancelled = this.params['date_cancelled'] ? moment(this.params['date_cancelled']) : undefined;
        }
        if (data['on_premise'] != undefined) {
            this.onPremise = data['on_premise'];
        }
        return this;
    };
    Object.defineProperty(Subscription.prototype, "cancelled", {
        get: function () {
            return !!this.dateCancelled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Subscription.prototype, "externalSubscription", {
        get: function () {
            return !!this.subscriptionId;
        },
        enumerable: true,
        configurable: true
    });
    Subscription.prototype.isCancellable = function () {
        return this.isActive && !this.freeTrial && this.externalSubscription && !this.cancelled && !this.onPremise;
    };
    Subscription.prototype.isActiveNotCancelled = function () {
        return this.isActive && !this.cancelled;
    };
    return Subscription;
}());
export { Subscription };

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { controlValue } from '@shared';
var ColorPickerComponent = /** @class */ (function () {
    function ColorPickerComponent(cd) {
        this.cd = cd;
        this.hexControlInternalUpdate = false;
        this.hueControl = new FormControl(0);
        this.saturationControl = new FormControl(0);
        this.luminosityControl = new FormControl(0);
        // hexControl = new FormControl('#2B50ED');
        this.hueBackground = 'linear-gradient(to right, #FF0000 0%, #FF7D00 12.38%, #FFFF00 19.52%, #00FF00 35.71%, #00FFFF 51.43%, #0000FF 65.72%, #FF00FF 83.33%, #FF0000 100%)';
    }
    ColorPickerComponent.prototype.ngOnInit = function () {
        this.initHex();
    };
    ColorPickerComponent.prototype.ngOnDestroy = function () { };
    ColorPickerComponent.prototype.initHex = function () {
        var _this = this;
        this.hexControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (value.length > 0) {
                var cleanValue = value.toUpperCase().replace(/[^0-9A-F]/g, '');
                if (cleanValue[0] != '#') {
                    cleanValue = '#' + cleanValue;
                }
                cleanValue = cleanValue.substring(0, 7);
                if (cleanValue != value) {
                    _this.hexControl.patchValue(cleanValue);
                }
            }
        });
        combineLatest(controlValue(this.hueControl), controlValue(this.saturationControl), controlValue(this.luminosityControl))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var hueRaw = _a[0], saturationRaw = _a[1], luminosityRaw = _a[2];
            var colorful = saturationRaw != 0 && luminosityRaw != 0 && luminosityRaw != 100;
            var hue = hueRaw || 0;
            var saturation = clamp(saturationRaw || 0, 20, 100);
            var luminosity = clamp(luminosityRaw || 0, 20, 80);
            var saturationL = saturationRaw > 0 ? saturation : 0;
            _this.hueThumbBorder = colorful ? Color({ h: hue, s: 100, l: 30 }).hex() : '#768191';
            _this.saturationBackground = "linear-gradient(to right, hsl(" + hue + ", 0%, " + luminosity + "%) 0%, hsl(" + hue + ", 100%, " + luminosity + "%) 100%)";
            _this.luminosityBackground = "linear-gradient(to right, hsl(" + hue + ", " + saturationL + "%, 0%) 0%, hsl(" + hue + ", " + saturationL + "%, 50%) 50%, hsl(" + hue + ", " + saturation + "%, 100%) 100%)";
            _this.cd.markForCheck();
        });
        controlValue(this.hexControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            var internalUpdate = false;
            if (_this.hexControlInternalUpdate) {
                _this.hexControlInternalUpdate = false;
                internalUpdate = true;
            }
            if (value.length == 0) {
                return;
            }
            try {
                var clr = Color(value);
                var hsl = clr.hsl();
                var _a = hsl.color, hue = _a[0], saturation = _a[1], luminosity = _a[2];
                if (!internalUpdate) {
                    _this.hueControl.patchValue(hue);
                    _this.saturationControl.patchValue(saturation);
                    _this.luminosityControl.patchValue(luminosity);
                }
            }
            catch (e) { }
        });
    };
    ColorPickerComponent.prototype.getCurrentColor = function () {
        try {
            return Color(this.hexControl.value);
        }
        catch (e) {
            return Color({
                h: this.hueControl.value,
                s: this.saturationControl.value,
                l: this.luminosityControl.value
            });
        }
    };
    ColorPickerComponent.prototype.setHue = function (value) {
        var clr = this.getCurrentColor().hue(value);
        this.hexControl.patchValue(clr.hex().toUpperCase());
        this.hexControlInternalUpdate = true;
    };
    ColorPickerComponent.prototype.setSaturation = function (value) {
        var clr = this.getCurrentColor().saturationl(value);
        this.hexControl.patchValue(clr.hex().toUpperCase());
        this.hexControlInternalUpdate = true;
    };
    ColorPickerComponent.prototype.setLuminosity = function (value) {
        var clr = this.getCurrentColor().lightness(value);
        this.hexControl.patchValue(clr.hex().toUpperCase());
        this.hexControlInternalUpdate = true;
    };
    return ColorPickerComponent;
}());
export { ColorPickerComponent };

<table class="table table_grid table_compact">
  <thead>
    <mat-menu #field_source_dropdown="matMenu">
      <ng-template matMenuContent let-item="field">
        <ng-container *ngIf="asImportDataField(item) as item">
          <button
            *ngIf="isPrimaryKey(item)"
            mat-menu-item
            [class.mat-menu-item-active]="!(item.control.controls.source.value | appIsSet)"
            class="mat-menu-item-odd"
            [disableRipple]="true"
            (click)="
              item.control.controls.source.patchValue(undefined);
              item.control.controls.active.patchValue(true);
              item.control.markAsDirty()
            "
          >
            <span class="choose-items-item__dropdown-icon icon-key"></span>
            Use auto
          </button>

          <button
            *ngFor="let option of sourceOptions"
            mat-menu-item
            [class.mat-menu-item-active]="item.control.controls.source.value == option.option.value"
            class="mat-menu-item-odd"
            [disableRipple]="true"
            (click)="
              item.control.controls.source.patchValue(option.option.value);
              item.control.controls.active.patchValue(true);
              item.control.markAsDirty()
            "
          >
            <span
              *ngIf="option.option.icon"
              class="choose-items-item__dropdown-icon"
              [ngClass]="'icon-' + option.option.icon"
            ></span>
            {{ option.option.name }}
          </button>
        </ng-container>
      </ng-template>
    </mat-menu>

    <mat-menu #field_actions_dropdown="matMenu">
      <ng-template matMenuContent let-item="field">
        <ng-container *ngIf="asImportDataField(item) as item">
          <ng-container *ngIf="sourceOptionsEnabled">
            <button
              mat-menu-item
              class="mat-menu-item-odd"
              [class.mat-menu-item-active]="item.control.controls.source.value | appIsSet"
              [disableRipple]="true"
              [matMenuTriggerFor]="field_source_dropdown"
              [matMenuTriggerData]="{ field: item }"
            >
              <span class="icon-hand choose-items-item__dropdown-icon"></span> Import from
            </button>

            <button
              *ngIf="item.control.controls.source.value | appIsSet"
              mat-menu-item
              class="mat-menu-item-odd"
              [disableRipple]="true"
              (click)="setOpenedFieldCustomize(item.field.name)"
            >
              <span class="icon-gear choose-items-item__dropdown-icon"></span>
              Import format
            </button>

            <button
              *ngIf="
                item.control.controls.active.value &&
                (item.control.controls.source.value | appIsSet) &&
                !item.primaryKey
              "
              mat-menu-item
              class="mat-menu-item-odd"
              [disabled]="item.required && item.control.controls.active.value"
              [disableRipple]="true"
              (click)="disableField(item)"
            >
              <span class="icon-bin choose-items-item__dropdown-icon"></span>
              Remove from import
            </button>
          </ng-container>

          <ng-container *ngIf="!sourceOptionsEnabled">
            <button
              *ngIf="!item.control.controls.active.value && !item.primaryKey"
              mat-menu-item
              class="mat-menu-item-odd"
              [disableRipple]="true"
              (click)="enableField(item)"
            >
              <span class="icon-plus choose-items-item__dropdown-icon"></span>
              Add to import
            </button>

            <button
              *ngIf="!item.primaryKey"
              mat-menu-item
              class="mat-menu-item-odd"
              [disabled]="!item.control.controls.active.value"
              [disableRipple]="true"
              (click)="setOpenedFieldCustomize(item.field.name)"
            >
              <span class="icon-edit choose-items-item__dropdown-icon"></span> Customize field
            </button>

            <button
              mat-menu-item
              class="mat-menu-item-odd"
              [disableRipple]="true"
              [disabled]="isPrimaryKey(item)"
              [class.mat-menu-item-active]="isPrimaryKey(item)"
              (click)="makePrimaryKey(item)"
            >
              <span class="icon-key choose-items-item__dropdown-icon"></span>
              Use as primary key
            </button>

            <button
              *ngIf="item.control.controls.active.value && !item.primaryKey"
              mat-menu-item
              class="mat-menu-item-odd"
              [disableRipple]="true"
              (click)="disableField(item)"
            >
              <span class="icon-bin choose-items-item__dropdown-icon"></span>
              Remove from import
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-menu>

    <tr
      class="table__heading-row"
      appDropList
      [appDropListDisabled]="sourceOptionsEnabled"
      [appDropListOrientation]="'horizontal'"
      [appDropListLockAxis]="'x'"
      (appDropListDropped)="dragDropOption($event)"
    >
      <ng-container *ngFor="let item of fields; trackBy: trackByFn; let i = index">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay']"
          [cdkConnectedOverlayPositions]="[
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - 8 },
            {
              originX: 'center',
              overlayX: 'center',
              originY: 'bottom',
              overlayY: 'top',
              offsetX: 0,
              offsetY: -8 + 8
            },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 8 + 8, offsetY: 0 }
          ]"
          [cdkConnectedOverlayOrigin]="customize_field_trigger"
          [cdkConnectedOverlayOpen]="(openedFieldCustomize | appIsSet) && openedFieldCustomize === item.field.name"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          (backdropClick)="setOpenedFieldCustomize(undefined)"
          (detach)="setOpenedFieldCustomize(undefined)"
        >
          <div (cdkObserveContent)="onPopoverContentChanged()">
            <div class="popover2">
              <div class="popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse">
                <div class="popover2__viewport popover2__viewport_scrollable">
                  <app-fields-edit-item
                    [control]="item.control.controls.field"
                    [configurable]="{
                      name: false,
                      verboseName: true,
                      editable: true,
                      required: false
                    }"
                    (saveRequested)="setOpenedFieldCustomize(undefined)"
                  ></app-fields-edit-item>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <th
          appDrag
          class="table__heading-column button-tag2-hover"
          [class.table__heading-column_bright]="true"
          [class.table__heading-column_active]="field_actions_trigger.menuOpen"
          [class.table__heading-column_interactive]="true"
          [class.table__heading-column_disabled]="!item.control.controls.active.value"
          [matMenuTriggerFor]="field_actions_dropdown"
          [matMenuTriggerData]="{ field: item }"
          #field_actions_trigger="matMenuTrigger"
          cdkOverlayOrigin
          #customize_field_trigger="cdkOverlayOrigin"
        >
          <div *appDragPreview></div>

          <div class="table__heading-column-inner">
            <ng-container *ngIf="sourceOptionsEnabled">
              <div class="table__heading-column-main">
                <ng-container *ngIf="(item.control.controls.source.value | appIsSet) || isPrimaryKey(item)">
                  <div class="button-tag2" style="margin: -5px 0;">
                    <span *ngIf="isPrimaryKey(item)" class="table__heading-icon icon-key"></span>
                    <span
                      *ngIf="!isPrimaryKey(item)"
                      class="table__heading-icon"
                      [ngClass]="'icon-' + item.fieldDescription.icon"
                    ></span>

                    <ng-container *ngIf="item.control.controls.source.value | appIsSet">
                      {{ item.control.controls.source.value | appCapitalize }}
                    </ng-container>
                    <ng-container *ngIf="!(item.control.controls.source.value | appIsSet)">
                      auto
                    </ng-container>
                  </div>
                  <div class="button-tag2-arrow button-tag2-arrow_right icon-arrow_forward"></div>
                </ng-container>

                <span
                  *ngIf="!(item.control.controls.source.value | appIsSet) && !isPrimaryKey(item)"
                  class="table__heading-icon"
                  [ngClass]="'icon-' + item.fieldDescription.icon"
                ></span>
                <span>{{ item.field.verboseName || item.field.name }}</span>
              </div>

              <div class="table__heading-column-fill"></div>

              <div class="table__heading-column-right">
                <div
                  *ngIf="!(item.control.controls.source.value | appIsSet) && !item.primaryKey"
                  class="button-tag2 button-tag2_active"
                  style="margin: -5px 0;"
                >
                  Choose
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!sourceOptionsEnabled">
              <div class="table__heading-column-main">
                <span *ngIf="isPrimaryKey(item)" class="table__heading-icon table__heading-icon_orange icon-key"></span>
                <span
                  *ngIf="!isPrimaryKey(item)"
                  class="table__heading-icon"
                  [ngClass]="'icon-' + item.fieldDescription.icon"
                ></span>

                <span>{{ item.field.verboseName || item.field.name }}</span>

                <div *ngIf="item.primaryKey" class="table__heading-secondary" style="margin-left: 4px;">
                  auto
                </div>
              </div>

              <div class="table__heading-column-fill"></div>

              <div class="table__heading-column-right">
                <div class="table__heading-action table__heading-action_always icon-arrow_down_2"></div>
              </div>
            </ng-container>
          </div>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody *ngIf="data && displayData">
    <tr
      *ngFor="let item of displayData; let i = index"
      app-import-data-item
      class="table__row"
      [row]="item"
      [index]="i"
      [autoValueStub]="sourceOptionsEnabled"
      [fields]="fields"
    ></tr>

    <tr *ngIf="data.length > displayData.length">
      <td class="table__row-separator" [attr.colspan]="fields.length">
        <div class="table-row-separator table-row-separator_fit-content">
          <span class="table-row-separator__title table-row-separator__title_secondary">
            {{ data.length - displayData.length }} more items
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

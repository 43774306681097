import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ListContextService {
  _editingField = new BehaviorSubject<any>(undefined);

  constructor() {}

  get editingField(): any {
    return this._editingField.value;
  }

  set editingField(value: any) {
    this._editingField.next(value);
  }

  get editingField$(): Observable<any> {
    return this._editingField.asObservable();
  }
}

<div
  *ngIf="visible || (customizeEnabled$ | async)"
  class="form"
  appElement
  [appElementDetectVisible]="true"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div *ngIf="title" class="form__title">
    <span class="form__title-text">{{ title }}</span>

    <span
      *ngIf="element.tooltip | appIsSet"
      class="form__title-help help-icon icon-help"
      [appTip]="element.tooltip"
      [appTipOptions]="{ side: 'top' }"
    ></span>
  </div>

  <div class="form__fieldset form__fieldset_manual-margin">
    <ng-container *ngIf="loading || !model">
      <div *ngFor="let item of stubRange; let i = index" class="field form__field">
        <label class="field__label">
          <span [class.loading-animation]="loading"
            ><span class="stub-text">{{ stubs[i % stubs.length].label }}</span></span
          >
        </label>
        <span [class.loading-animation]="loading"
          ><span class="stub-text">{{ stubs[i % stubs.length].value }}</span></span
        >
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && model">
      <app-model-element-item
        *ngFor="let item of visibleColumns"
        [item]="item"
        [model]="model"
        [columnActions]="elementState.element.columnActions"
        [context]="context"
        [contextElement]="viewContextElement"
        class="form__field"
      ></app-model-element-item>
    </ng-container>
  </div>
</div>

import pickBy from 'lodash/pickBy';

import { Widget } from './widgets/items/widget';

export class Dashboard {
  public id: number;
  public project: string;
  public name: string;
  public ordering: number;
  public dateAdd: Date;
  public widgets: Widget[];
  public visible = true;

  deserialize(data: Object): Dashboard {
    this.id = data['id'];
    this.project = data['project'];
    this.name = data['name'];
    this.ordering = data['ordering'];
    this.dateAdd = data['date_add'];
    this.visible = data['visible'];

    if (data['widgets']) {
      this.widgets = data['widgets'].map(item => new Widget().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      id: this.id,
      project: this.project,
      name: this.name,
      ordering: this.ordering,
      date_add: this.dateAdd,
      visible: this.visible
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get link() {
    return ['dashboards', this.id];
  }
}

import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface TemplateItem {
  id: string;
  template: TemplateRef<unknown>;
  options: TemplateItemOptions;
}

interface TemplateItemOptions {
  order?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizeToolbarBottomService {
  _templates = new BehaviorSubject<TemplateItem[]>([]);

  get templates(): TemplateItem[] {
    return this._templates.value;
  }

  get templates$(): Observable<TemplateItem[]> {
    return this._templates.asObservable();
  }

  setTemplate(id: string, template: TemplateRef<unknown>, options: TemplateItemOptions = {}) {
    if (this._templates.value.find(item => item.id == id && item.template === template)) {
      return;
    }

    const templateItem = { id: id, template: template, options: options };
    const templateItems = [...this._templates.value.filter(item => item.id != id), templateItem];
    this._templates.next(templateItems);
  }

  removeTemplate(id: string) {
    if (!this._templates.value.find(item => item.id == id)) {
      return;
    }

    const templateItems = [...this._templates.value.filter(item => item.id != id)];
    this._templates.next(templateItems);
  }
}

<ng-template let-menuBlocks="menuBlocks" let-horizontalMenu="horizontalMenu" #menu>
  <div
    *ngIf="asMenuBlocks(menuBlocks) as menuBlocks"
    class="menu2 theme-enabled"
    [class.menu2_horizontal]="horizontalMenu"
    appProjectThemeOptions
    [projectSettings]="allSettings$ | async"
  >
    <ng-container *ngFor="let block of menuBlocks; trackBy: trackMenuBlockFn">
      <div *ngIf="menuBlockLayouts.isPrimary(block.layout)" class="menu2__side">
        <app-menu-primary [block]="block" [horizontal]="horizontalMenu"></app-menu-primary>
      </div>

      <div *ngIf="!menuBlockLayouts.isPrimary(block.layout)" class="menu2__main">
        <app-menu-secondary [block]="block" [horizontal]="horizontalMenu"></app-menu-secondary>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template let-menuBlocks="menuBlocks" let-horizontalMenu="horizontalMenu" #menu_placeholder>
  <ng-container *ngIf="asMenuBlocks(menuBlocks) as menuBlocks">
    <ng-container *ngFor="let block of menuBlocks; trackBy: trackMenuBlockFn">
      <ng-container *ngIf="horizontalMenu">
        <div
          class="admin__vertical-top-placeholder"
          [class.admin__vertical-top-placeholder_primary]="menuBlockLayouts.isPrimary(block.layout)"
          [class.admin__vertical-top-placeholder_secondary]="!menuBlockLayouts.isPrimary(block.layout)"
        ></div>
      </ng-container>

      <ng-container *ngIf="!horizontalMenu">
        <div
          class="admin__horizontal-left-placeholder"
          [class.admin__horizontal-left-placeholder_primary]="menuBlockLayouts.isPrimary(block.layout)"
          [class.admin__horizontal-left-placeholder_secondary]="!menuBlockLayouts.isPrimary(block.layout)"
        ></div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngIf="menuSettings">
  <div class="admin__vertical">
    <div *ngIf="desktopMenu && topMenuBlocks?.length" class="admin__vertical-top">
      <ng-container
        *ngTemplateOutlet="menu_placeholder; context: { menuBlocks: topMenuBlocks, horizontalMenu: true }"
      ></ng-container>
    </div>

    <div class="admin__vertical-main">
      <div class="admin__horizontal">
        <div *ngIf="desktopMenu && leftMenuBlocks?.length" class="admin__horizontal-left">
          <ng-container
            *ngTemplateOutlet="menu_placeholder; context: { menuBlocks: leftMenuBlocks, horizontalMenu: false }"
          ></ng-container>
        </div>

        <div class="admin__horizontal-main">
          <div class="admin__vertical">
            <div *ngIf="desktopMenu && topContentMenuBlocks?.length" class="admin__vertical-top">
              <ng-container
                *ngTemplateOutlet="menu; context: { menuBlocks: topContentMenuBlocks, horizontalMenu: true }"
              ></ng-container>
            </div>

            <div class="admin__vertical-main">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="desktopMenu && leftMenuBlocks?.length" class="admin__horizontal-left-fixed">
    <ng-container
      *ngTemplateOutlet="menu_placeholder; context: { menuBlocks: topMenuBlocks, horizontalMenu: true }"
    ></ng-container>

    <div class="admin__horizontal-left-fixed-main">
      <ng-container
        *ngTemplateOutlet="menu; context: { menuBlocks: leftMenuBlocks, horizontalMenu: false }"
      ></ng-container>
    </div>
  </div>

  <div class="admin__vertical-top-fixed" [class.admin__vertical-top-fixed_shadow]="scrolled">
    <ng-container *ngIf="desktopMenu && topMenuBlocks?.length">
      <ng-container
        *ngTemplateOutlet="menu; context: { menuBlocks: topMenuBlocks, horizontalMenu: true }"
      ></ng-container>
    </ng-container>

    <div class="admin__vertical-top-fixed-after">
      <ng-content select="[data-menu-top-fixed-after]"></ng-content>
    </div>
  </div>

  <ng-container *ngIf="mobileMenu">
    <div class="admin__mobile-header" [class.admin__mobile-header_visible]="true">
      {{ title }}

      <a
        *ngIf="mobileMenuBlocks?.length"
        href="javascript:void(0)"
        class="admin__mobile-header-button admin__mobile-header-button_left"
        (click)="toggleMenuOpened()"
      >
        <span class="icon-menu"></span>
      </a>
    </div>

    <ng-container *ngIf="mobileMenuBlocks?.length">
      <div
        class="admin__content-overlay"
        [class.admin__content-overlay_visible]="mobileOpened"
        (click)="onMobileMenuBackgroundClick($event)"
        #mobile_menu_background
      ></div>

      <div
        class="admin__mobile-menu"
        [class.admin__mobile-menu_visible]="mobileOpened"
        (click)="onMobileMenuClick($event)"
      >
        <ng-container
          *ngTemplateOutlet="menu; context: { menuBlocks: mobileMenuBlocks, horizontalMenu: false }"
        ></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, first, map, publishLast, refCount, skip } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { BasePopupComponent, PopupService } from '@common/popups';
export var CLOSE_BY_BACKGROUND_CLICK = 'CLOSE_BY_BACKGROUND_CLICK';
var DialogPopupComponent = /** @class */ (function (_super) {
    __extends(DialogPopupComponent, _super);
    function DialogPopupComponent(dialogService, el, popupService) {
        var _this = _super.call(this, el, popupService) || this;
        _this.dialogService = dialogService;
        _this.tl = new TimelineMax();
        _this._animating = new BehaviorSubject(false);
        _this.opaque = false;
        _this.wide = true;
        _this.contrast = false;
        _this.transparent = false;
        _this.showRequested = false;
        _this.closeRequested = false;
        return _this;
    }
    DialogPopupComponent.prototype.initProviders = function () {
        this.data.providers = (this.data.providers || []).concat([
            { provide: BasePopupComponent, useValue: this },
            { provide: DialogPopupComponent, useValue: this }
        ]);
    };
    DialogPopupComponent.prototype.ngAfterViewInit = function () {
        this.show();
    };
    Object.defineProperty(DialogPopupComponent.prototype, "animating", {
        get: function () {
            return this._animating.value;
        },
        set: function (value) {
            this._animating.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogPopupComponent.prototype, "animating$", {
        get: function () {
            return this._animating.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialogPopupComponent.prototype, "loaded$", {
        get: function () {
            return this.animating$.pipe(skip(1), filter(function (item) { return item == false; }), map(function () { }), first(), publishLast(), refCount());
        },
        enumerable: true,
        configurable: true
    });
    DialogPopupComponent.prototype.show = function () {
        var _this = this;
        if (this.closeRequested) {
            return;
        }
        this.showRequested = true;
        this.animating = true;
        this.tl
            .clear()
            .fromTo(this.background.nativeElement, 0.15, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        }, 0)
            .fromTo(this.root.nativeElement, 0.15, {
            y: -10,
            opacity: 0
        }, {
            y: 0,
            opacity: 1
        }, 0)
            .add(function () {
            _this.animating = false;
        });
    };
    DialogPopupComponent.prototype.close = function (data) {
        var _this = this;
        if (this.closeRequested) {
            return;
        }
        if (!this.showRequested) {
            this.closed.emit(data);
            this.popupService.remove(this.data);
            return;
        }
        this.closeRequested = true;
        this.data.closeRequested = true;
        this.animating = true;
        this.closed.emit(data);
        this.tl
            .clear()
            .to(this.root.nativeElement, 0.15, {
            opacity: 0,
            y: 10
        }, 0)
            .to(this.background.nativeElement, 0.2, {
            opacity: 0
        }, 0)
            .add(function () {
            _this.animating = false;
            _this.popupService.remove(_this.data);
        });
    };
    DialogPopupComponent.prototype.onContainerMouseDown = function (e) {
        this.containerClickSourceTarget = e.target;
    };
    DialogPopupComponent.prototype.onContainerMouseClick = function (e, container) {
        if (e.target !== container || this.containerClickSourceTarget !== container) {
            return;
        }
        if (this.animating || this.disableClose) {
            return;
        }
        this.confirmClose(CLOSE_BY_BACKGROUND_CLICK);
    };
    DialogPopupComponent.prototype.confirmClose = function (data) {
        var _this = this;
        if (this.closeWithoutConfirm) {
            this.close(data);
        }
        else {
            this.dialogService
                .confirm({
                title: this.closeTitle,
                description: this.closeDescription,
                style: this.orange ? 'orange' : undefined,
                dark: this.dark
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (confirmed) {
                if (!confirmed) {
                    return;
                }
                _this.close(data);
            });
        }
    };
    return DialogPopupComponent;
}(BasePopupComponent));
export { DialogPopupComponent };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  Frame,
  FrameTranslate,
  getFramePointPosition,
  getLinePointsWithOrientation,
  Layer,
  Point,
  PointTranslate,
  Translate
} from '@modules/views';
import { isSet } from '@shared';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { snapPoint } from '../../../utils/guides';
import { LayerResizableResizeEvent } from '../base/layer-resizable.directive';
import { getLineOrientation } from '../line/line-layer.component';

export interface LineLayerBounds {
  frame: Partial<Frame>;
  points?: { from: Partial<Point>; to: Partial<Point> };
}

@Component({
  selector: 'app-line-layer-bounds',
  templateUrl: './line-layer-bounds.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineLayerBoundsComponent implements OnInit, OnDestroy {
  @Input() frame: Frame;
  @Input() translate: Translate = { x: 0, y: 0 };
  @Input() points: Point[] = [];
  @Input() fromX = 0;
  @Input() fromY = 0;
  @Input() length = 0;
  @Input() transform: SafeStyle;
  @Input() snapFrame = false;
  @Input() snapFrameContainer: Layer[];
  @Input() snapFrameExceptLayers: Layer[];
  @Input() active = false;
  @Input() hover = false;
  @Output() resizeStarted = new EventEmitter<void>();
  @Output() resizeFinished = new EventEmitter<void>();
  @Output() updateLayer = new EventEmitter<LineLayerBounds>();

  resizePositions: { from: Partial<Point>; to: Partial<Point> };
  resizeSnapFrames: FrameTranslate[];
  resizeNewBounds: LineLayerBounds;
  updateLayerRaw = new Subject<LineLayerBounds>();

  constructor(public editorContext: ViewEditorContext) {}

  ngOnInit() {
    this.updateLayerRaw
      .pipe(
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        untilDestroyed(this)
      )
      .subscribe(value => this.updateLayer.emit(value));
  }

  ngOnDestroy(): void {}

  onPointResizeStarted() {
    this.resizePositions = {
      from: getFramePointPosition(this.frame, this.points[0]),
      to: getFramePointPosition(this.frame, this.points[1])
    };
    this.resizeStarted.emit();

    if (this.snapFrame) {
      this.resizeSnapFrames = this.editorContext.getFrames({
        onlyContainers: [this.snapFrameContainer],
        exceptLayers: this.snapFrameExceptLayers
      });

      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
    }
  }

  snapPoint(point: Partial<Point>) {
    const snap = snapPoint({
      point: new PointTranslate({
        point: { x: point.x, y: point.y },
        translate: this.translate
      }),
      otherFrames: this.resizeSnapFrames
    });

    if (isSet(snap.updatePoint.x)) {
      point.x = snap.updatePoint.x;
    }

    if (isSet(snap.updatePoint.y)) {
      point.y = snap.updatePoint.y;
    }

    this.editorContext.setHorizontalGuides(snap.horizontalGuides);
    this.editorContext.setVerticalGuides(snap.verticalGuides);
  }

  applyPointResize(event: LayerResizableResizeEvent, end: boolean) {
    const positionFrom = { ...this.resizePositions.from };
    const positionTo = { ...this.resizePositions.to };
    const pointNew = end ? positionTo : positionFrom;

    pointNew.x = round(pointNew.x + event.deltaX, 2);
    pointNew.y = round(pointNew.y + event.deltaY, 2);

    if (this.snapFrame) {
      this.snapPoint(pointNew);
    }

    const { xInverse: xInverseNew, yInverse: yInverseNew } = getLineOrientation(positionFrom, positionTo);
    const pointsNew = getLinePointsWithOrientation(xInverseNew, yInverseNew);

    let frame: Partial<Frame>;

    if (!xInverseNew && !yInverseNew) {
      // from top left
      frame = {
        x: positionFrom.x,
        y: positionFrom.y,
        width: positionTo.x - positionFrom.x,
        height: positionTo.y - positionFrom.y
      };
    } else if (xInverseNew && !yInverseNew) {
      // from top right
      frame = {
        x: positionTo.x,
        y: positionFrom.y,
        width: positionFrom.x - positionTo.x,
        height: positionTo.y - positionFrom.y
      };
    } else if (xInverseNew && yInverseNew) {
      // from bottom right
      frame = {
        x: positionTo.x,
        y: positionTo.y,
        width: positionFrom.x - positionTo.x,
        height: positionFrom.y - positionTo.y
      };
    } else if (!xInverseNew && yInverseNew) {
      // from bottom left
      frame = {
        x: positionFrom.x,
        y: positionTo.y,
        width: positionTo.x - positionFrom.x,
        height: positionFrom.y - positionTo.y
      };
    }

    const bounds = {
      frame: frame,
      points: pointsNew
    };

    this.resizeNewBounds = bounds;
    this.updateLayerRaw.next(bounds);
  }

  onPointResizeFinished() {
    const newBounds = this.resizeNewBounds;

    this.resizeSnapFrames = undefined;
    this.resizePositions = undefined;
    this.resizeNewBounds = undefined;

    this.resizeFinished.emit();

    if (newBounds) {
      this.updateLayer.emit(newBounds);
    }

    if (this.snapFrame) {
      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { ModelDescriptionQuery } from './model-description-query';
import { QueryType } from './query-type';
export var QueryPagination;
(function (QueryPagination) {
    QueryPagination["Page"] = "page";
    QueryPagination["Offset"] = "offset";
    QueryPagination["Cursor"] = "cursor";
})(QueryPagination || (QueryPagination = {}));
export var DefaultQueryPaginationHasMoreFunction = "// ex. data['hasMore']\nreturn ";
export var DefaultQueryPaginationTotalFunction = "// ex. data['count']\nreturn ";
export var DefaultQueryPaginationCursorPrevFunction = "// ex. data['prev_cursor']\nreturn ";
export var DefaultQueryPaginationCursorNextFunction = "// ex. data['next_cursor']\nreturn ";
var ListModelDescriptionQuery = /** @class */ (function (_super) {
    __extends(ListModelDescriptionQuery, _super);
    function ListModelDescriptionQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.paginationHasMorePagesPath = [];
        _this.paginationHasMoreTotalPagesPath = [];
        _this.paginationHasMoreTotalRecordsPath = [];
        _this.paginationTotalPath = [];
        _this.paginationCursorPrevPath = [];
        _this.paginationCursorNextPath = [];
        _this.sorting = false;
        _this.frontendFiltering = true;
        return _this;
    }
    ListModelDescriptionQuery.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.pagination = data['pagination'];
        this.paginationHasMoreFunction = data['pagination_has_more_function'];
        if (data['pagination_has_more_pages_path']) {
            this.paginationHasMorePagesPath = data['pagination_has_more_pages_path'];
        }
        if (data['pagination_has_more_total_pages_path']) {
            this.paginationHasMoreTotalPagesPath = data['pagination_has_more_total_pages_path'];
        }
        if (data['pagination_has_more_total_records_path']) {
            this.paginationHasMoreTotalRecordsPath = data['pagination_has_more_total_records_path'];
        }
        this.paginationPerPage = data['pagination_per_page'];
        this.paginationPerPageMin = data['pagination_per_page_min'];
        this.paginationPerPageMax = data['pagination_per_page_max'];
        this.paginationTotalPath = data['pagination_total_path'];
        this.paginationCursorPrevPath = data['pagination_cursor_prev_path'];
        this.paginationCursorNextPath = data['pagination_cursor_next_path'];
        this.paginationTotalFunction = data['pagination_total_function'];
        this.paginationCursorPrevFunction = data['pagination_cursor_prev_function'];
        this.paginationCursorNextFunction = data['pagination_cursor_next_function'];
        this.sortingFields = data['sorting_fields'];
        this.frontendFiltering = data['frontend_filtering'];
        if (data['sorting'] != undefined) {
            this.sorting = data['sorting'];
        }
        return this;
    };
    ListModelDescriptionQuery.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this), { pagination: this.pagination, pagination_has_more_function: this.paginationHasMoreFunction, pagination_total_function: this.paginationTotalFunction, pagination_cursor_prev_function: this.paginationCursorPrevFunction, pagination_cursor_next_function: this.paginationCursorNextFunction, pagination_total_path: this.paginationTotalPath, pagination_per_page: this.paginationPerPage, pagination_per_page_min: this.paginationPerPageMin, pagination_per_page_max: this.paginationPerPageMax, pagination_cursor_prev_path: this.paginationCursorPrevPath, pagination_cursor_next_path: this.paginationCursorNextPath, pagination_has_more_pages_path: this.paginationHasMorePagesPath, pagination_has_more_total_pages_path: this.paginationHasMoreTotalPagesPath, pagination_has_more_total_records_path: this.paginationHasMoreTotalRecordsPath, sorting: this.sorting, sorting_fields: this.sortingFields, frontend_filtering: this.frontendFiltering });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ListModelDescriptionQuery.prototype.isFieldSortable = function (field) {
        if ([QueryType.Simple, QueryType.SQL].includes(this.queryType)) {
            return !field.flex;
        }
        else if (this.frontendFiltering) {
            return !field.flex;
        }
        else {
            return (this.sorting &&
                this.sortingFields &&
                this.sortingFields.find(function (item) { return item.name == field.name && item.sortable; }) != undefined);
        }
    };
    return ListModelDescriptionQuery;
}(ModelDescriptionQuery));
export { ListModelDescriptionQuery };

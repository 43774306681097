var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionStore } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { ParameterArray } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { AutomationTriggerType, getAutomationTriggerByType, IntervalAutomationTrigger, ModelAutomationTrigger, TimetableAutomationTrigger, TimetableType, WebhookAutomationTrigger } from '@modules/workflow';
import { ascComparator, controlValue, isSet } from '@shared';
var CustomizeWorkflowTriggerForm = /** @class */ (function (_super) {
    __extends(CustomizeWorkflowTriggerForm, _super);
    function CustomizeWorkflowTriggerForm(currentEnvironmentStore, actionStore, modelDescriptionStore) {
        var _this = _super.call(this, {
            trigger_type: new FormControl(undefined),
            trigger_interval: new FormControl(15),
            trigger_timetable_type: new FormControl(undefined),
            trigger_timetable_time: new FormControl(undefined),
            trigger_timetable_days_of_week: new FormControl([]),
            trigger_timetable_days: new FormControl([]),
            trigger_timetable_months: new FormControl([]),
            trigger_timetable_date: new FormControl(undefined),
            trigger_model_resource: new FormControl(undefined),
            trigger_model_action: new FormControl(undefined),
            trigger_webhook_token: new FormControl(undefined),
            parameters: new ParameterArray([]),
            test_parameters: new FormControl({})
        }) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.actionStore = actionStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.options = {};
        _this.triggerTypeOptions = [
            { value: AutomationTriggerType.Interval, name: 'At regular intervals', icon: 'time' },
            { value: AutomationTriggerType.Timetable, name: 'Based on a schedule', icon: 'calendar' },
            { value: AutomationTriggerType.Webhook, name: 'When Webhook received', icon: 'antenna' },
            { value: AutomationTriggerType.Model, name: 'When data changes', icon: 'data' },
            { value: AutomationTriggerType.Manual, name: 'Manual run', icon: 'hand' }
        ];
        _this.dayOfWeekOptions = range(7).map(function (i) {
            return {
                value: i + 1,
                name: moment().startOf('isoWeek').add(i, 'day').format('dddd')
            };
        });
        _this.dayOptions = range(31).map(function (i) {
            return {
                value: i + 1,
                name: String(i + 1)
            };
        });
        _this.monthOptions = range(12).map(function (i) {
            return {
                value: i + 1,
                name: moment().startOf('year').add(i, 'month').format('MMMM')
            };
        });
        _this.timetableTypeOptions = [
            { option: { value: TimetableType.EveryDay, name: 'Every day' } },
            { option: { value: TimetableType.EveryWeek, name: 'Days of the week' } },
            { option: { value: TimetableType.EveryMonth, name: 'Days of the month' } },
            { option: { value: TimetableType.Once, name: 'Once' } },
            { option: { value: TimetableType.Custom, name: 'Specify dates' } }
        ];
        _this.modelActionEquals = (function () {
            return function (lhs, rhs) {
                var lhsModel = lhs ? lhs.model : undefined;
                var lhsAction = lhs ? lhs.action : undefined;
                var rhsModel = rhs ? rhs.model : undefined;
                var rhsAction = rhs ? rhs.action : undefined;
                return lhsModel == rhsModel && lhsAction == rhsAction;
            };
        })();
        return _this;
    }
    CustomizeWorkflowTriggerForm.prototype.ngOnDestroy = function () { };
    CustomizeWorkflowTriggerForm.prototype.init = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.options = options;
        if (options.trigger) {
            this.controls.trigger_type.patchValue(options.trigger.type);
            if (options.trigger instanceof WebhookAutomationTrigger) {
                this.controls.trigger_webhook_token.patchValue(options.trigger.webhookToken);
            }
            else if (options.trigger instanceof IntervalAutomationTrigger) {
                this.controls.trigger_interval.patchValue(options.trigger.interval);
            }
            else if (options.trigger instanceof TimetableAutomationTrigger) {
                this.controls.trigger_timetable_type.patchValue(options.trigger.timetableType);
                this.controls.trigger_timetable_time.patchValue(options.trigger.timetableTime ? options.trigger.timetableTime.format('HH:mm') : '');
                this.controls.trigger_timetable_days_of_week.patchValue(options.trigger.timetableDaysOfWeek || []);
                this.controls.trigger_timetable_days.patchValue(options.trigger.timetableDays || []);
                this.controls.trigger_timetable_months.patchValue(options.trigger.timetableMonths || []);
                this.controls.trigger_timetable_date.patchValue(options.trigger.timetableDate ? options.trigger.timetableDate.format('L HH:mm') : '');
            }
            else if (options.trigger instanceof ModelAutomationTrigger) {
                var action = isSet(options.trigger.model) && isSet(options.trigger.action)
                    ? {
                        model: options.trigger.model,
                        action: options.trigger.action
                    }
                    : undefined;
                this.controls.trigger_model_resource.patchValue(options.trigger.resource);
                this.controls.trigger_model_action.patchValue(action);
            }
        }
        if (options.parameters) {
            this.controls.parameters.patchValue(options.parameters);
        }
        if (options.testParameters) {
            this.controls.test_parameters.patchValue(options.testParameters);
        }
        this.controls.trigger_model_resource.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.controls.trigger_model_action.patchValue(undefined); });
        this.markAsPristine();
    };
    CustomizeWorkflowTriggerForm.prototype.triggerModelResource$ = function () {
        var _this = this;
        return controlValue(this.controls.trigger_model_resource).pipe(map(function (value) { return _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == value; }); }));
    };
    CustomizeWorkflowTriggerForm.prototype.triggerModelActionItems$ = function () {
        return combineLatest(this.triggerModelResource$(), this.actionStore.get(), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], actionDescriptions = _a[1], modelDescriptions = _a[2];
            if (!resource) {
                return [];
            }
            var options = [];
            if (actionDescriptions) {
                var groupedOptions = values(actionDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) { return item.type == ActionType.Query; })
                    .filter(function (item) { return item.modelAction && !['get', 'get_detail'].includes(item.modelAction); })
                    .reduce(function (acc, actionDescription) {
                    var modelDescription = actionDescription.model
                        ? modelDescriptions.find(function (item) { return item.resource == resource.uniqueName && item.model == actionDescription.model; })
                        : undefined;
                    if (modelDescription && (!resource.demo || modelDescription.featured)) {
                        if (!acc[modelDescription.modelId]) {
                            acc[modelDescription.modelId] = {
                                modelDescription: modelDescription,
                                items: []
                            };
                        }
                        var label = actionDescription.verboseName;
                        var icon = void 0;
                        if (actionDescription.modelAction == 'create') {
                            label = 'Record created';
                            icon = 'plus_circle';
                        }
                        else if (actionDescription.modelAction == 'update') {
                            label = 'Record updated';
                            icon = 'edit';
                        }
                        else if (actionDescription.modelAction == 'delete') {
                            label = 'Record deleted';
                            icon = 'bin';
                        }
                        acc[modelDescription.modelId].items.push({
                            option: {
                                value: { model: actionDescription.model, action: actionDescription.modelAction },
                                name: label,
                                icon: icon
                            },
                            subtitle: 'Actions',
                            valueLabel: label,
                            valueIcon: icon
                        });
                    }
                    return acc;
                }, {}));
                options.push.apply(options, groupedOptions
                    .sort(function (lhs, rhs) {
                    var lhsName = lhs.modelDescription
                        ? String(lhs.modelDescription.verboseNamePlural).toLowerCase()
                        : '';
                    var rhsName = rhs.modelDescription
                        ? String(rhs.modelDescription.verboseNamePlural).toLowerCase()
                        : '';
                    return ascComparator(lhsName, rhsName);
                })
                    .reduce(function (acc, item) {
                    if (item.modelDescription) {
                        acc.push({
                            button: {
                                name: item.modelDescription.model,
                                label: item.modelDescription.verboseNamePlural || item.modelDescription.model,
                                icon: 'components'
                            },
                            children: item.items,
                            subtitle: 'Collections'
                        });
                    }
                    else {
                        acc.push.apply(acc, item.items.sort(function (lhs, rhs) {
                            return ascComparator(String(lhs.option.name).toLowerCase(), String(rhs.option.name).toLowerCase());
                        }));
                    }
                    return acc;
                }, []));
            }
            return options;
        }));
    };
    CustomizeWorkflowTriggerForm.prototype.getTriggerInstance = function () {
        var trigger;
        var triggerTypeCls = getAutomationTriggerByType(this.controls.trigger_type.value);
        if (triggerTypeCls) {
            trigger = new triggerTypeCls();
            if (trigger instanceof WebhookAutomationTrigger) {
                trigger.webhookToken = this.controls.trigger_webhook_token.value;
            }
            else if (trigger instanceof IntervalAutomationTrigger) {
                trigger.interval = parseFloat(this.controls.trigger_interval.value || 15);
            }
            else if (trigger instanceof TimetableAutomationTrigger) {
                var timetableTime = this.controls.trigger_timetable_time.value
                    ? moment(this.controls.trigger_timetable_time.value, ['HH:mm:ss', 'HH:mm'])
                    : undefined;
                var timetableDate = this.controls.trigger_timetable_date.value
                    ? moment(this.controls.trigger_timetable_date.value)
                    : undefined;
                trigger.timetableType = this.controls.trigger_timetable_type.value;
                trigger.timetableTime = timetableTime && timetableTime.isValid() ? timetableTime : undefined;
                trigger.timetableDaysOfWeek = this.controls.trigger_timetable_days_of_week.value;
                trigger.timetableDays = this.controls.trigger_timetable_days.value;
                trigger.timetableMonths = this.controls.trigger_timetable_months.value;
                trigger.timetableDate = timetableDate && timetableDate.isValid() ? timetableDate : undefined;
            }
            else if (trigger instanceof ModelAutomationTrigger) {
                trigger.resource = this.controls.trigger_model_resource.value;
                if (isSet(this.controls.trigger_model_action.value)) {
                    var _a = this.controls.trigger_model_action.value, model = _a.model, action = _a.action;
                    trigger.model = model;
                    trigger.action = action;
                }
                else {
                    trigger.action = undefined;
                }
            }
        }
        return trigger;
    };
    CustomizeWorkflowTriggerForm.prototype.submit = function () {
        var trigger = this.options.triggerEditable ? this.getTriggerInstance() : undefined;
        return {
            trigger: trigger,
            testParameters: this.controls.test_parameters.value,
            parameters: this.controls.parameters.value
        };
    };
    return CustomizeWorkflowTriggerForm;
}(FormGroup));
export { CustomizeWorkflowTriggerForm };

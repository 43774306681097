import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { AppConfigService } from '@core';
import { TintStyle } from '@modules/actions';
import { AnalyticsEvent } from '@modules/analytics';
import { defaultSignUpLayout, Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectInvite, ProjectPublicInvite } from '@modules/projects';
import { SSOSettings } from '@modules/sso';
import { isSet } from '@shared';
import { LoginForm } from '../login/login.form';
var LoginTemplateComponent = /** @class */ (function () {
    function LoginTemplateComponent(appConfigService, cd) {
        this.appConfigService = appConfigService;
        this.cd = cd;
        this.title = 'Good morning';
        this.domainLoading = false;
        this.submitLoading = false;
        this.screenLoader = false;
        this.inviteLoading = false;
        this.publicInviteLoading = false;
        this.fillScreen = false;
        this.preview = false;
        this.socialLogin = new EventEmitter();
        this.ssoLogin = new EventEmitter();
        this.ssoReset = new EventEmitter();
        this.cleanErrorForm = new EventEmitter();
        this.submitJetAuth = new EventEmitter();
        this.submitSSOAuth = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.defaultLayout = defaultSignUpLayout;
        this.externalFonts = [];
        this.tintStyles = TintStyle;
        this.analyticsEvents = AnalyticsEvent;
    }
    LoginTemplateComponent.prototype.ngOnInit = function () { };
    LoginTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (changes.domain) {
            this.externalFonts = this.getExternalFonts(this.domain);
        }
    };
    LoginTemplateComponent.prototype.getExternalFonts = function (domain) {
        if (!domain) {
            return [];
        }
        return [domain.signUpFontRegular, domain.signUpFontHeading].filter(function (item) { return isSet(item); });
    };
    Object.defineProperty(LoginTemplateComponent.prototype, "layout", {
        get: function () {
            return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "headerHasContent", {
        get: function () {
            var hasLogo = !this.isWhiteLabel || (this.domain && (this.domain.logo || this.domain.initials));
            var hasTitle = !this.domain || this.domain.signInTitle;
            var hasDescription = this.domain && this.domain.signInDescription;
            return hasLogo || hasTitle || hasDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "isGoogleSignEnabled", {
        get: function () {
            var disabledGoogleSignIn = this.domain ? this.domain.disableGoogleSignIn : false;
            return !this.isWhiteLabel && this.appConfigService.socialAuthGoogleEnabled && !disabledGoogleSignIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "isFacebookSignEnabled", {
        get: function () {
            var disabledFacebookSignIn = this.domain ? this.domain.disableFacebookSignIn : false;
            return !this.isWhiteLabel && this.appConfigService.socialAuthFacebookEnabled && !disabledFacebookSignIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "isSignInWithEnabled", {
        get: function () {
            var ssoSettings = this.domain ? this.domain.ssoSettings : [];
            return this.isGoogleSignEnabled || this.isFacebookSignEnabled || ssoSettings.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginTemplateComponent.prototype, "isFormEnabled", {
        get: function () {
            return !(this.domain && this.domain.disableEmailSignIn) || this.currentSSO;
        },
        enumerable: true,
        configurable: true
    });
    LoginTemplateComponent.prototype.startSSOLogin = function (sso) {
        this.ssoLogin.emit(sso);
    };
    LoginTemplateComponent.prototype.resetSSOLogin = function () {
        this.ssoReset.emit();
    };
    LoginTemplateComponent.prototype.onSubmit = function () {
        if (this.currentSSO) {
            this.submitSSOAuth.emit(this.currentSSO);
        }
        else {
            this.submitJetAuth.emit();
        }
    };
    return LoginTemplateComponent;
}());
export { LoginTemplateComponent };

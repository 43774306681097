import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ActionDescription } from '@modules/actions';
import { AdminMode, ROUTE_ADMIN_MODE, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { ActionDescriptionService } from '../services/action-description/action-description.service';

@Injectable()
export class ActionStore extends SingletonStore<ActionDescription[]> {
  overrides: ActionDescription[];

  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private actionDescriptionService: ActionDescriptionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  getOverrides(options: { draft?: boolean; forceUpdate?: boolean } = {}): Observable<ActionDescription[]> {
    if (options.forceUpdate && this.overrides) {
      this.overrides = undefined;
    }

    if (this.overrides) {
      return of(this.overrides);
    }

    return this.actionDescriptionService
      .getOverrides(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, options)
      .pipe(
        tap(result => {
          this.overrides = result;
        })
      );
  }

  getActions(options: { draft?: boolean; forceUpdate?: boolean } = {}): Observable<ActionDescription[]> {
    return combineLatest(
      this.actionDescriptionService.getFromResources(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance
      ),
      this.actionDescriptionService.getAutoActions(),
      this.getOverrides(options)
    ).pipe(
      map(([fromResource, autoActions, overrideResult]) => {
        const originalResult: ActionDescription[] = [...fromResource, ...autoActions];
        this.actionDescriptionService.applyOverrides(originalResult, overrideResult);
        return originalResult;
      })
    );
  }

  protected fetch(): Observable<ActionDescription[]> {
    return this.mode$.pipe(
      switchMap(mode =>
        this.getActions({ draft: mode == AdminMode.Builder, forceUpdate: true }).pipe(
          map(result => {
            if (!result) {
              return [];
            }

            return result;
          }),
          catchError(() => {
            return of([]);
          })
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ActionDescription[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ActionDescription[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getDetail(id: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.isSame(id));
      })
    );
  }

  getDetailFirst(id: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.isSame(id));
      })
    );
  }

  public addItem(actionDescription: ActionDescription) {
    this.addItems([actionDescription]);
  }

  public addItems(actionDescription: ActionDescription[]) {
    this.instance = [...this.instance, ...actionDescription];
  }

  public updateItem(actionDescription: ActionDescription) {
    const index = this.instance.findIndex(item => item.isSame(actionDescription));

    if (index !== -1) {
      this.instance = this.instance.map((item, i) => {
        if (i === index) {
          return actionDescription;
        } else {
          return item;
        }
      });
    }
  }

  public getUpdateItems(actionDescriptions: ActionDescription[]): ActionDescription[] {
    let replacesFound = false;
    const newInstance = this.instance.map((instanceItem, i) => {
      const replaceItem = actionDescriptions.find(item => item.isSame(instanceItem));

      if (!replacesFound && replaceItem) {
        replacesFound = true;
      }

      return replaceItem || instanceItem;
    });

    if (replacesFound) {
      return newInstance;
    }
  }

  public updateItems(actionDescriptions: ActionDescription[]) {
    const updatedItems = this.getUpdateItems(actionDescriptions);

    if (updatedItems) {
      this.instance = updatedItems;
    }
  }

  public syncItems(newItems: ActionDescription[], itemsFilter?: (item: ActionDescription) => boolean) {
    const currentItemsByName: { [k: string]: ActionDescription } = this.instance.reduce((acc, item) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const syncItems = newItems.reduce(
      (acc, item) => {
        if (currentItemsByName[item.name]) {
          acc.update.push(item);
        } else {
          acc.add.push(item);
        }

        return acc;
      },
      { add: [], update: [], delete: [] }
    );

    const updatedItems = syncItems.update.length ? this.getUpdateItems(syncItems.update) : undefined;
    const preserveItems = updatedItems || this.instance;

    if (itemsFilter) {
      const newItemsByName: { [k: string]: ActionDescription } = newItems.reduce((acc, item) => {
        acc[item.name] = item;
        return acc;
      }, {});

      syncItems.delete = preserveItems.filter(item => itemsFilter(item) && !newItemsByName[item.name]);
    }

    if (syncItems.add.length || syncItems.delete.length || updatedItems) {
      this.instance = [
        ...preserveItems.filter(preserveItem => !syncItems.delete.find(item => item === preserveItem)),
        ...syncItems.add
      ];
    }
  }

  public deleteItem(actionDescription: ActionDescription) {
    const index = this.instance.findIndex(item => item.isSame(actionDescription));

    if (index !== -1) {
      this.instance = this.instance.filter((item, i) => i !== index);
    }
  }

  public syncAutoActions(modelDescription: ModelDescription): Observable<boolean> {
    return combineLatest(
      this.actionDescriptionService.getAutoActions({ modelDescription: modelDescription }),
      this.getOverrides({ draft: this.mode == AdminMode.Builder })
    ).pipe(
      map(([originalResult, overrideResult]) => {
        this.actionDescriptionService.applyOverrides(originalResult, overrideResult, { onlyExistent: true });
        this.syncItems(
          originalResult,
          item => item.resource == modelDescription.resource && item.model == modelDescription.model
        );

        return true;
      })
    );
  }
}

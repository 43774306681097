/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-page-popups-menu-section/custom-page-popups-menu-section.component.ngfactory";
import * as i2 from "../custom-page-popups-menu-section/custom-page-popups-menu-section.component";
import * as i3 from "../../../../core/services/local-storage/local.storage";
import * as i4 from "ng-gxscrollable";
import * as i5 from "@angular/common";
import * as i6 from "./custom-page-popups-menu.component";
import * as i7 from "../../../customize/services/customize/customize.service";
var styles_CustomPagePopupsMenuComponent = [];
var RenderType_CustomPagePopupsMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomPagePopupsMenuComponent, data: {} });
export { RenderType_CustomPagePopupsMenuComponent as RenderType_CustomPagePopupsMenuComponent };
function View_CustomPagePopupsMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "menu3__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-custom-page-popups-menu-section", [], null, [[null, "openPopup"], [null, "closePopup"], [null, "customizePopup"], [null, "movePopupToPage"], [null, "duplicatePopup"], [null, "deletePopup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openPopup" === en)) {
        _co.openPopup.emit($event);
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("closePopup" === en)) {
        _co.closePopup.emit($event);
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } if (("customizePopup" === en)) {
        _co.customizePopup.emit($event);
        var pd_2 = (_co.close() !== false);
        ad = (pd_2 && ad);
    } if (("movePopupToPage" === en)) {
        _co.movePopupToPage.emit($event);
        var pd_3 = (_co.close() !== false);
        ad = (pd_3 && ad);
    } if (("duplicatePopup" === en)) {
        _co.duplicatePopup.emit($event);
        var pd_4 = (_co.close() !== false);
        ad = (pd_4 && ad);
    } if (("deletePopup" === en)) {
        var pd_5 = (_co.deletePopup.emit($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_CustomPagePopupsMenuSectionComponent_0, i1.RenderType_CustomPagePopupsMenuSectionComponent)), i0.ɵdid(2, 114688, null, 0, i2.CustomPagePopupsMenuSectionComponent, [i3.LocalStorage, i0.ChangeDetectorRef], { section: [0, "section"], openedPopup: [1, "openedPopup"], viewSettings: [2, "viewSettings"] }, { openPopup: "openPopup", closePopup: "closePopup", customizePopup: "customizePopup", movePopupToPage: "movePopupToPage", duplicatePopup: "duplicatePopup", deletePopup: "deletePopup" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.openedPopup; var currVal_2 = _co.viewSettings; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CustomPagePopupsMenuComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "customize-menu__data-menu"]], [[2, "customize-menu__data-menu_visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markClickEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "customize-menu__data-menu-viewport"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(2, 12664832, null, 0, i4.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(3, { stopPropagationAlways: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "menu3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "menu3__items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsMenuComponent_1)), i0.ɵdid(7, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, true); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.sections; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomPagePopupsMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-page-popups-menu", [], null, null, null, View_CustomPagePopupsMenuComponent_0, RenderType_CustomPagePopupsMenuComponent)), i0.ɵdid(1, 770048, null, 0, i6.CustomPagePopupsMenuComponent, [i7.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPagePopupsMenuComponentNgFactory = i0.ɵccf("app-custom-page-popups-menu", i6.CustomPagePopupsMenuComponent, View_CustomPagePopupsMenuComponent_Host_0, { viewSettings: "viewSettings", popups: "popups", openedPopup: "openedPopup", opened: "opened" }, { openPopup: "openPopup", closePopup: "closePopup", createPopup: "createPopup", customizePopup: "customizePopup", movePopupToPage: "movePopupToPage", duplicatePopup: "duplicatePopup", deletePopup: "deletePopup", closed: "closed" }, []);
export { CustomPagePopupsMenuComponentNgFactory as CustomPagePopupsMenuComponentNgFactory };

<div class="choose-template__content-navigation-list">
  <div class="choose-template-list">
    <div *ngFor="let item of templates; let i = index" class="choose-template-list__item">
      <div
        class="choose-template-list-item choose-template-list-item_complex choose-template-list-item_border choose-template-list-item_interactive"
        [class.choose-template-list-item_active]="selectedTemplate === item"
        (click)="setSelectedTemplate(item); submit()"
      >
        <div class="choose-template-list-item__row">
          <div *ngIf="item.image" class="choose-template-list-item__left">
            <span class="choose-template-list-item__image" [style.background-image]="getTemplateIcon(item)"></span>
          </div>
          <div class="choose-template-list-item__main">
            <div class="choose-template-list-item__title">{{ item.name }}</div>
            <div *ngIf="item.description" class="choose-template-list-item__subtitle">
              {{ item.description }}
            </div>
          </div>
        </div>
        <div
          *ngIf="item.variants?.length"
          class="choose-template-list-item__row choose-template-list-item__active-only"
        >
          <div class="choose-template-list-item__switch">
            <div
              *ngFor="let variant of item.variants"
              class="choose-template-list-item__switch-item"
              [class.choose-template-list-item__switch-item_active]="variant.type == selectedTemplateVariant"
              (click)="$event.stopPropagation(); setSelectedTemplateVariant(variant.type); submit()"
            >
              {{ variant.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <app-page-block *ngIf="hasDraftChanges">
    <app-alert [purple]="true" [title]="'This collection has unpublished changes'" [icon]="'warning_filled'">
      Please note that API returns only published App data
    </app-alert>
  </app-page-block>

  <app-model-api-get-query
    *ngIf="currentQuery?.name == queryNames.Get"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [pagination]="getPagination"
    [section]="!popup"
  ></app-model-api-get-query>

  <app-model-api-get-detail-query
    *ngIf="currentQuery?.name == queryNames.GetDetail"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-get-detail-query>

  <app-model-api-create-query
    *ngIf="currentQuery?.name == queryNames.Create"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-create-query>

  <app-model-api-update-query
    *ngIf="currentQuery?.name == queryNames.Update"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-update-query>

  <app-model-api-delete-query
    *ngIf="currentQuery?.name == queryNames.Delete"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-delete-query>

  <app-model-api-aggregate-query
    *ngIf="currentQuery?.name == queryNames.Aggregate"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-aggregate-query>

  <app-model-api-group-query
    *ngIf="currentQuery?.name == queryNames.Group"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-group-query>

  <app-model-api-reload-query
    *ngIf="currentQuery?.name == queryNames.Reload"
    [resource]="resource"
    [modelDescription]="modelDescription"
    [serializer]="serializer"
    [overrideParameters]="currentQuery.parameters"
    [section]="!popup"
  ></app-model-api-reload-query>
</ng-template>

<ng-container *ngIf="!popup">
  <app-page-block *ngIf="!popup" [paddingSize]="'s'">
    <app-background-tabs (tabClick)="setCurrentQueryName($event)">
      <app-background-tab
        *ngFor="let item of queries"
        [name]="item.name"
        [label]="item.label"
        [active]="item.name == currentQueryName"
      ></app-background-tab>
    </app-background-tabs>
  </app-page-block>

  <app-page-block *ngIf="hasDraftChanges" [paddingSize]="'s'">
    <app-alert [purple]="true" [title]="'This collection has unpublished changes'" [icon]="'warning_filled'">
      Please note that API returns only published App data
    </app-alert>
  </app-page-block>

  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<div *ngIf="popup" class="popup-sidebar">
  <div class="popup-sidebar__sidebar">
    <div class="popup-sidebar-menu">
      <div class="popup-sidebar-menu__title">Endpoints</div>

      <div
        *ngFor="let item of queries"
        class="popup-sidebar-menu-item popup-sidebar-menu__item"
        [class.popup-sidebar-menu-item_active]="item.name == currentQueryName"
        (click)="setCurrentQueryName(item.name)"
      >
        <div class="popup-sidebar-menu-item__left">
          <div class="popup-sidebar-menu-item__tag" [ngClass]="'background-color_' + item.color + '_1'">
            {{ item.method }}
          </div>
        </div>
        <div class="popup-sidebar-menu-item__main">
          <div class="popup-sidebar-menu-item__title">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-sidebar__main">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InputOverlayService {
  private _active$ = new BehaviorSubject<any>(undefined);

  constructor() {}

  setActive(instance: any) {
    if (this._active$.value !== instance) {
      this._active$.next(instance);
    }
  }

  unsetActive(instance: any) {
    if (this._active$.value === instance) {
      this._active$.next(undefined);
    }
  }

  getActive$(): Observable<any> {
    return this._active$.asObservable();
  }
}

import { Border } from '../border';
import { Frame } from '../frame';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Point } from '../point';
import { Shadow } from '../shadow';
import { Translate } from '../translate';

export function getLinePointsWithOrientation(
  xInverse: boolean,
  yInverse: boolean
): { from: Partial<Point>; to: Partial<Point> } {
  if (!xInverse && !yInverse) {
    return { from: { x: 0, y: 0 }, to: { x: 1, y: 1 } };
  } else if (!xInverse && yInverse) {
    return { from: { x: 0, y: 1 }, to: { x: 1, y: 0 } };
  } else if (xInverse && !yInverse) {
    return { from: { x: 1, y: 0 }, to: { x: 0, y: 1 } };
  } else if (xInverse && yInverse) {
    return { from: { x: 1, y: 1 }, to: { x: 0, y: 0 } };
  }
}

export class LineLayer extends Layer {
  public type = LayerType.Line;
  public icon = 'line';
  public defaultName = 'line';
  public points: Point[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];

  constructor(options: Partial<LineLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['points']) {
      this.points = data['points'].map(item => new Point().deserialize(item));
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      points: this.points.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize())
    };
  }

  applyPoints(from: Partial<Point>, to: Partial<Point>) {
    this.points = [from, to].map((point, i) => {
      const result = this.points[i] || new Point();

      result.x = point.x;
      result.y = point.y;

      return result;
    });
  }

  applyPointsWithOrientation(xInverse: boolean, yInverse: boolean) {
    const points = getLinePointsWithOrientation(xInverse, yInverse);
    this.applyPoints(points.from, points.to);
  }

  applyMouseEventsFrame(frame: Frame, options: { translate?: Translate; xInverse?: boolean; yInverse?: boolean } = {}) {
    super.applyMouseEventsFrame(frame, options);
    this.applyPointsWithOrientation(options.xInverse, options.yInverse);
  }
}

registerLayerType(LayerType.Line, LineLayer);

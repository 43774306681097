export { ProjectResourceDataTemplatesComponent } from './components/project-resource-data-templates/project-resource-data-templates.component';
export { ChooseSyncController } from './services/choose-sync/choose-sync.controller';
export { ResourceChoosePagesAutoItemData } from './components/resource-choose-pages/resource-choose-pages.component';
export { ResourceModelEditController } from './services/resource-model-edit-controller/resource-model-edit.controller';
export {
  ResourceChoosePagesController,
  ResourceChoosePagesOptions,
  ResourceChoosePagesResult
} from './services/resource-choose-pages-controller/resource-choose-pages.controller';
export {
  ResourceEditController,
  ResourceEditResult
} from './services/resource-edit-controller/resource-edit.controller';
export { ResourceSummaryContent, ResourceSummaryService } from './services/resource-summary/resource-summary.service';
export { ResourceFilter } from './data/resource-filter';
export {
  ResourceChoosePagesComponent,
  ResourceChoosePagesItem
} from './components/resource-choose-pages/resource-choose-pages.component';
export { ResourceSummaryComponent } from './components/resource-summary/resource-summary.component';
export { getResourceSettingsComponent, ResourceSettingsComponent } from './data/resource-settings-components';
export {
  getResourceAddModelComponents,
  ResourceAddModelComponentItem,
  ResourceAddModelAddedEvent
} from './data/resource-add-model-components';
export { StripeResourceSettingsComponent } from './components/resource-settings/stripe-resource-settings/stripe-resource-settings.component';
export { CompanyInfoService } from './services/company-info/company-info.service';
export { CompanyInfoComponent } from './components/company-info/company-info.component';
export { IsProjectCreatedGuard } from './guards/is-project-created.guard';
export { DomainProjectGuard, ROUTE_LOADED_PROJECTS_STORE } from './guards/domain-project.guard';
export { ProjectsComponentsModule } from './projects-components.module';

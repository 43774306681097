import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicComponentModule } from '@common/dynamic-component';
import { SharedModule } from '@shared';

import { ContrastDialogPopupComponent } from './components/contrast-dialog-popup/contrast-dialog-popup.component';
import { DialogPopupComponent } from './components/dialog-popup/dialog-popup.component';
import { MediumDialogPopupComponent } from './components/medium-dialog-popup/medium-dialog-popup.component';
import { OpaqueDialogPopupComponent } from './components/opaque-dialog-popup/opaque-dialog-popup.component';
import { OpaqueThinDialogPopupComponent } from './components/opaque-thin-dialog-popup/opaque-thin-dialog-popup.component';
import { SimplePopupComponent } from './components/simple-popup/simple-popup.component';
import { ThinDialogPopupComponent } from './components/thin-dialog-popup/thin-dialog-popup.component';
import { TransparentDialogPopupComponent } from './components/transparent-dialog-popup/transparent-dialog-popup.component';

@NgModule({
  imports: [CommonModule, SharedModule, DynamicComponentModule],
  declarations: [
    DialogPopupComponent,
    OpaqueDialogPopupComponent,
    ThinDialogPopupComponent,
    ContrastDialogPopupComponent,
    MediumDialogPopupComponent,
    OpaqueThinDialogPopupComponent,
    SimplePopupComponent,
    TransparentDialogPopupComponent
  ],
  exports: [
    DialogPopupComponent,
    OpaqueDialogPopupComponent,
    ThinDialogPopupComponent,
    ContrastDialogPopupComponent,
    MediumDialogPopupComponent,
    OpaqueThinDialogPopupComponent,
    SimplePopupComponent,
    TransparentDialogPopupComponent
  ],
  entryComponents: [
    DialogPopupComponent,
    OpaqueDialogPopupComponent,
    ThinDialogPopupComponent,
    ContrastDialogPopupComponent,
    MediumDialogPopupComponent,
    OpaqueThinDialogPopupComponent,
    SimplePopupComponent,
    TransparentDialogPopupComponent
  ]
})
export class DialogPopupModule {}

<ng-container *ngIf="(visible || (customizeEnabled$ | async)) && (embedUrl | appIsSet)">
  <iframe
    *ngIf="element.viewerType == fileViewerTypes.Google || isSafari"
    class="embed-element"
    [class.embed-element_border]="element.border"
    [src]="embedUrl | appSafeUrl"
  ></iframe>

  <embed
    *ngIf="element.viewerType != fileViewerTypes.Google && !isSafari"
    class="embed-element"
    [class.embed-element_border]="element.border"
    [src]="embedUrl | appSafeUrl"
  />
</ng-container>

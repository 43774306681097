import { ChangeDetectorRef, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appInputFocusable]'
})
export class InputFocusableDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'focus')
      .pipe(untilDestroyed(this))
      .subscribe(e => this.cd.detectChanges());

    fromEvent<MouseEvent>(this.el.nativeElement, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(e => this.cd.detectChanges());
  }

  ngOnDestroy(): void {}
}

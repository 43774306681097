import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { cleanElementName, TabsLayoutElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { QueryBuilderContext } from '@modules/queries-components';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarTabsEditForm } from './customize-bar-tabs-edit.form';
import { TabsLayoutItemControl } from './tabs-layout-item.control';

@Component({
  selector: 'app-customize-bar-tabs-edit',
  templateUrl: './customize-bar-tabs-edit.component.html',
  providers: [CustomizeBarTabsEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarTabsEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: TabsLayoutElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  loading = false;
  collapseContext = new SidebarCollapseContext();
  createdItem: TabsLayoutItemControl;
  booleanFieldStyle = BooleanFieldStyle;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarTabsEditForm,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.element);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      this.emitUpdate(this.form.submit());
    });
  }

  ngOnDestroy(): void {}

  onTitleChanged(title: string) {
    this.form.controls.title.setValue(title);
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: TabsLayoutElementItem, submit = false) {
    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  addItem() {
    const control = this.form.controls.items.createControl();
    this.form.controls.items.push(control);
    this.createdItem = control;
    this.cd.markForCheck();
  }

  removeItem(control: TabsLayoutItemControl, i: number) {
    this.form.controls.items.removeControl(control);
    this.cd.markForCheck();
  }

  dragDrop(event: CdkDragDrop<TabsLayoutItemControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
      this.form.controls.items.updateValueAndValidity();
    }
  }
}

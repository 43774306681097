import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { IsOptionsValidResult, MailgunGeneratorService, MailgunParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class MailgunResourceSettingsForm extends BaseResourceSettingsForm<MailgunParamsOptions> {
  form = new AppFormGroup({
    key: new FormControl('', Validators.required)
  });

  constructor(
    private mailgunGeneratorService: MailgunGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.mailgunGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          key: result.key
        });
      })
    );
  }

  getOptions(): MailgunParamsOptions {
    return {
      key: this.form.value['key']
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.mailgunGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.mailgunGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name']
          };
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Task } from '../task';
import { TaskScenario } from '../task-scenario';

@Injectable({
  providedIn: 'root'
})
export class ChangeChartTypeTask extends Task {
  init(): Observable<TaskScenario> | TaskScenario {
    return new TaskScenario([
      {
        link: [],
        items: []
      }
    ]);
  }
}

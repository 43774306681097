import cloneDeep from 'lodash/cloneDeep';
import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';
import { AlignHorizontal } from '../../align-horizontal';
import { AlignVertical } from '../../align-vertical';
var ElementItem = /** @class */ (function () {
    function ElementItem() {
        this.margin = {};
        this.alignHorizontalDefault = AlignHorizontal.Justify;
        this.params = {};
    }
    // TODO: remove ModelDescription
    ElementItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.type = data['type'];
        this.alignHorizontal = data['align_horizontal'];
        this.alignVertical = data['align_vertical'];
        this.params = data['params'] ? cloneDeep(data['params']) : {};
        if (!this.uid) {
            this.generateUid();
        }
        if (data['margin']) {
            this.margin = data['margin'];
        }
        if (data['visible_input']) {
            this.visibleInput = new Input().deserialize(data['visible_input']);
        }
        return this;
    };
    Object.defineProperty(ElementItem.prototype, "alignHorizontalOrDefault", {
        get: function () {
            return this.alignHorizontal || this.alignHorizontalDefault;
        },
        enumerable: true,
        configurable: true
    });
    ElementItem.prototype.serialize = function () {
        return {
            uid: this.uid,
            name: this.name,
            type: this.type,
            margin: this.margin ? this.margin : {},
            visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
            align_horizontal: this.alignHorizontal,
            align_vertical: this.alignVertical,
            params: this.params
        };
    };
    ElementItem.prototype.copy = function (element) {
        this.deserialize(element.serialize());
    };
    Object.defineProperty(ElementItem.prototype, "typeStr", {
        get: function () {
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementItem.prototype, "analyticsName", {
        get: function () {
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementItem.prototype, "analyticsGenericName", {
        get: function () {
            if (!this.analyticsName) {
                return;
            }
            return ['component', this.analyticsName].join('_');
        },
        enumerable: true,
        configurable: true
    });
    ElementItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    ElementItem.prototype.defaultName = function () {
        return 'element';
    };
    ElementItem.prototype.isInline = function () {
        return [AlignHorizontal.Left, AlignHorizontal.Center, AlignHorizontal.Right].includes(this.alignHorizontalOrDefault);
    };
    ElementItem.prototype.isInlineVertical = function () {
        return [AlignVertical.Top, AlignVertical.Center, AlignVertical.Bottom].includes(this.alignVertical);
    };
    ElementItem.prototype.childrenCount = function () {
        return 0;
    };
    return ElementItem;
}());
export { ElementItem };

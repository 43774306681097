<td class="share-table__column">
  <div class="table-user table-user_photo">
    <span
      class="table-user__photo user-photo"
      [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
    ></span>
    <div class="table-user__user">
      <ng-container *ngIf="item.user">{{ item.user.strFull }}</ng-container>
      <ng-container *ngIf="!item.user">{{ item.userEmail | appEmailName }} ({{ 'invited' | localize }})</ng-container>
    </div>
    <div class="table-user__email">
      <ng-container *ngIf="item.user">{{ item.user.email }}</ng-container>
      <ng-container *ngIf="!item.user">{{ item.userEmail }}</ng-container>
    </div>
  </div>
</td>

<td class="share-table__column">
  <app-project-group-control [control]="selectedGroup" [small]="true"></app-project-group-control>
</td>

<td class="share-table__column share-table__column_actions">
  <a
    href="javascript:void(0)"
    class="share-table__delete"
    [class.share-table__delete_disabled]="item.user?.uid == currentUserStore.instance.uid"
    (click)="delete(item)"
    [appTip]="'Remove member' | localize"
    [appTipOptions]="{ side: 'top' }"
  >
    <span class="icon-bin"></span>
  </a>
</td>

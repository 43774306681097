import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClickEventDirective } from './directives/click-event/click-event.directive';
import { AnalyticsService } from './services/analytics/analytics.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { IntercomService } from './services/intercom/intercom.service';
import { RewardfulService } from './services/rewardful/rewardful.service';
import { SentryService } from './services/sentry/sentry.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ClickEventDirective],
  exports: [ClickEventDirective]
})
export class AnalyticsModule {
  constructor(
    private analyticsService: AnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private intercomService: IntercomService,
    private sentryService: SentryService,
    private rewardfulService: RewardfulService
  ) {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { GoogleSheetsGeneratorService } from '@modules/resource-generators';
import { controlValue, generateUUID } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
export var validateRange = function (control) {
    if (!control.value) {
        return null;
    }
    var match1 = control.value.match(/^[A-Z]+:[A-Z]+$/);
    var match2 = control.value.match(/^[A-Z]+(\d+):[A-Z]+(\d+)$/);
    if (!match1 && !match2) {
        return { local: ['Wrong range format'] };
    }
    return null;
};
var GoogleSheetsFileControl = /** @class */ (function (_super) {
    __extends(GoogleSheetsFileControl, _super);
    function GoogleSheetsFileControl(options) {
        if (options === void 0) { options = {}; }
        return _super.call(this, {
            uid: new FormControl(generateUUID(), Validators.required),
            file: new FormControl(undefined, Validators.required),
            unique_name: new FormControl(''),
            verbose_name: new FormControl('', [
                Validators.required
            ].concat((options.verboseNameValidator && [options.verboseNameValidator]))),
            sheet: new FormControl('', Validators.required),
            range: new FormControl('', [Validators.required, validateRange])
        }) || this;
    }
    return GoogleSheetsFileControl;
}(FormGroup));
export { GoogleSheetsFileControl };
var GoogleSheetsFileArray = /** @class */ (function (_super) {
    __extends(GoogleSheetsFileArray, _super);
    function GoogleSheetsFileArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GoogleSheetsFileArray;
}(FormArray));
export { GoogleSheetsFileArray };
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form() {
        return _super.call(this, {
            access_token: new FormControl(null, Validators.required),
            params: new FormControl(null, Validators.required),
            files: new GoogleSheetsFileArray([]),
            sync: new FormControl(true)
        }) || this;
    }
    return Form;
}(AppFormGroup));
export { Form };
var GoogleSheetsResourceSettingsForm = /** @class */ (function (_super) {
    __extends(GoogleSheetsResourceSettingsForm, _super);
    function GoogleSheetsResourceSettingsForm(googleSheetsGeneratorService, analyticsService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.googleSheetsGeneratorService = googleSheetsGeneratorService;
        _this.analyticsService = analyticsService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.tokenName = 'oauth_access_token';
        _this.modelDescriptionNameEditing = true;
        _this.form = new Form();
        return _this;
    }
    GoogleSheetsResourceSettingsForm.prototype.validateExisting = function () {
        var _this = this;
        return function (control) {
            var parent = control.parent;
            if (!parent) {
                return;
            }
            var array = parent.parent;
            if (!array) {
                return;
            }
            var group = array.controls.find(function (item) { return item.controls['verbose_name'] === control; });
            var uniqueName = _this.googleSheetsGeneratorService.getUniqueName(control.value);
            if (uniqueName &&
                array.controls
                    .filter(function (item) { return item !== group; })
                    .find(function (item) { return _this.googleSheetsGeneratorService.getUniqueName(item.value['verbose_name']) == uniqueName; })) {
                return { local: ['File with such name already added'] };
            }
        };
    };
    GoogleSheetsResourceSettingsForm.prototype.createItem = function (state) {
        var _this = this;
        var group = new GoogleSheetsFileControl({ verboseNameValidator: this.validateExisting() });
        if (state) {
            group.patchValue(state);
        }
        controlValue(group.controls.verbose_name)
            .pipe(distinctUntilChanged(), filter(function (value) { return typeof value === 'string'; }), untilDestroyed(this))
            .subscribe(function (value) {
            var cleanValue = value.replace(/\./g, '_');
            if (cleanValue !== value) {
                group.controls.verbose_name.patchValue(cleanValue);
            }
        });
        controlValue(group.controls.unique_name)
            .pipe(distinctUntilChanged(), filter(function (value) { return typeof value === 'string'; }), untilDestroyed(this))
            .subscribe(function (value) {
            var cleanValue = value.replace(/\./g, '_');
            if (cleanValue !== value) {
                group.controls.unique_name.patchValue(cleanValue);
            }
        });
        group.controls.verbose_name.valueChanges
            .pipe(distinctUntilChanged(), delay(0), untilDestroyed(this))
            .subscribe(function () {
            _this.form.controls.files.controls
                .filter(function (item) { return item !== group; })
                .forEach(function (otherGroup) {
                otherGroup.controls.verbose_name.updateValueAndValidity();
            });
        });
        return group;
    };
    GoogleSheetsResourceSettingsForm.prototype.itemsSet = function (controls) {
        var array = this.form.controls['files'];
        range(array.controls.length).forEach(function () { return array.removeAt(0); });
        controls.forEach(function (item) { return array.push(item); });
        this.form.updateValueAndValidity();
    };
    GoogleSheetsResourceSettingsForm.prototype.itemsAppend = function (control) {
        var array = this.form.controls['files'];
        array.push(control);
        array.updateValueAndValidity();
    };
    GoogleSheetsResourceSettingsForm.prototype.itemsRemove = function (control) {
        var array = this.form.controls['files'];
        var index = array.controls.findIndex(function (item) { return item === control; });
        array.removeAt(index);
        array.updateValueAndValidity();
    };
    GoogleSheetsResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.googleSheetsGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                access_token: result.access_token,
                params: result.token_params,
                sync: !!_this.resource.isSynced()
            });
            _this.itemsSet(result.files.map(function (item) { return _this.createItem(item); }));
        }));
    };
    GoogleSheetsResourceSettingsForm.prototype.getOptions = function () {
        return {
            access_token: this.form.value['access_token'],
            token_params: this.form.value['params'],
            files: this.form.value['files']
        };
    };
    GoogleSheetsResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        var options = this.getOptions();
        return this.googleSheetsGeneratorService
            .generateParams(this.project, this.environment, this.typeItem, options)
            .pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'], sync: _this.form.value['sync'], syncModelDescriptions: _this.getParamsSyncModelDescriptions(result) });
        }), catchError(function (error) {
            if (!_this.resource) {
                var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
                    ResourceID: _this.typeItem.name,
                    Error: errorMessage,
                    Options: options,
                    ResourceContents: _this.googleSheetsGeneratorService.latestFileContents
                });
            }
            return throwError(error);
        }));
    };
    return GoogleSheetsResourceSettingsForm;
}(BaseResourceSettingsForm));
export { GoogleSheetsResourceSettingsForm };

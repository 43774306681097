import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var TicketService = /** @class */ (function () {
    function TicketService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    TicketService.prototype.create = function (subject, name, text) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("tickets/");
            var headers = new HttpHeaders();
            var data = new HttpParams({
                fromObject: {
                    subject: subject,
                    name: name,
                    text: text
                }
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    TicketService.ngInjectableDef = i0.defineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: TicketService, providedIn: "root" });
    return TicketService;
}());
export { TicketService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter } from 'rxjs/operators';
import { ButtonMenuItem, MenuGeneratorService, MenuItemActionService } from '@modules/menu';
import { isSet } from '@shared';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
import { dropdownPositionsHorizontal, dropdownPositionsVertical } from '../menu-item/menu-item.component';
var ButtonMenuItemComponent = /** @class */ (function (_super) {
    __extends(ButtonMenuItemComponent, _super);
    function ButtonMenuItemComponent(menuGeneratorService, menuItemActionService, router, cd) {
        var _this = _super.call(this) || this;
        _this.menuGeneratorService = menuGeneratorService;
        _this.menuItemActionService = menuItemActionService;
        _this.router = router;
        _this.cd = cd;
        _this.children = [];
        _this.menuItemHovered$ = new BehaviorSubject(undefined);
        _this.menuDropdownHovered$ = new BehaviorSubject(false);
        _this.menuChildDropdownOpened$ = new BehaviorSubject(false);
        _this.dropdownOpened = false;
        _this.popoverPositions = [];
        _this.menuDropdownSubscription = [];
        return _this;
    }
    ButtonMenuItemComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    ButtonMenuItemComponent.prototype.ngOnInit = function () {
        this.updateChildren();
        this.updateLink();
        this.initMenuDropdown();
        this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
        this.cd.markForCheck();
    };
    ButtonMenuItemComponent.prototype.ngOnDestroy = function () { };
    ButtonMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.updateChildren();
            this.updateLink();
            this.initMenuDropdown();
        }
    };
    ButtonMenuItemComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    ButtonMenuItemComponent.prototype.updateChildren = function () {
        this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
        this.cd.markForCheck();
    };
    ButtonMenuItemComponent.prototype.updateLink = function () {
        var _this = this;
        this.menuItemActionService
            .getActionExecution(this.menuItem.action, { context: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.link = result.link;
            _this.href = result.href;
            _this.queryParams = result.queryParams;
            _this.handler = result.handler;
            _this.cd.markForCheck();
        });
    };
    ButtonMenuItemComponent.prototype.isIconButton = function () {
        return (this.primary && !this.horizontal) || !isSet(this.menuItem.title);
    };
    ButtonMenuItemComponent.prototype.initMenuDropdown = function () {
        var _this = this;
        this.menuDropdownSubscription.forEach(function (item) { return item.unsubscribe(); });
        this.menuDropdownSubscription = [];
        if (!this.menuItem.children.length) {
            return;
        }
        this.menuDropdownSubscription.push(combineLatest(this.menuItemHovered$.pipe(debounce(function (origin) { return timer(origin ? 0 : 100); })), this.menuDropdownHovered$, this.menuChildDropdownOpened$)
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var itemOrigin = _a[0], dropdownHovered = _a[1], childDropdownOpened = _a[2];
            if (itemOrigin && !_this.dropdownOpened) {
                _this.openDropdown(itemOrigin);
            }
            else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && _this.dropdownOpened) {
                _this.closeDropdown();
            }
        }));
        this.menuDropdownSubscription.push(merge(this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.closeDropdown(); }));
    };
    ButtonMenuItemComponent.prototype.openDropdown = function (origin) {
        this.origin = origin;
        this.dropdownOpened = true;
        this.cd.markForCheck();
        this.dropdownOpen.emit();
    };
    ButtonMenuItemComponent.prototype.closeDropdown = function () {
        this.dropdownOpened = false;
        this.cd.markForCheck();
        this.dropdownClose.emit();
    };
    ButtonMenuItemComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    ButtonMenuItemComponent.prototype.onClick = function () {
        if (this.handler) {
            this.handler().pipe(untilDestroyed(this)).subscribe();
        }
    };
    return ButtonMenuItemComponent;
}(BaseMenuItemComponent));
export { ButtonMenuItemComponent };

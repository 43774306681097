import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EditableContentModule } from '@common/editable-content';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ListComponentsModule } from '@modules/list-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { SnapshotPopupItemComponent } from './components/snapshot-popup-item/snapshot-popup-item.component';
import { SnapshotPopupComponent } from './components/snapshot-popup/snapshot-popup.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocalizeModule,
    TipsModule,
    ListComponentsModule,
    UiModule,
    EditableContentModule
  ],
  declarations: [SnapshotPopupComponent, SnapshotPopupItemComponent],
  entryComponents: [SnapshotPopupComponent]
})
export class SnapshotsComponentsModule {}

import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import Quill from 'quill';
import Delta from 'quill-delta';
import { BehaviorSubject } from 'rxjs';

import { ignoreDragDrop } from '@common/drag-drop';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldInputControl, FieldInputRequiredValidator } from '@modules/parameters';
import { ThemeService } from '@modules/theme';
import { KeyboardEventKeyCode, removeElement, TypedChanges } from '@shared';
import { PageBlockPaddingSize } from '@ui';

// TODO: Refactor imports
import { InlineInputEditComponent } from '../../../../modules/parameters-components/components/inline-input-edit/inline-input-edit.component';

import './quill-context-formula';
import { InlineInputDynamicComponent, inlineInputInitialProperty } from './quill-context-formula';

// interface FormulaBlockData {
//   value?: Object;
// }

function deltasEqual(delta1: Delta, delta2: Delta) {
  if (delta1 && delta2) {
    return JSON.stringify(delta1) == JSON.stringify(delta2);
  } else {
    return isEqual(delta1, delta2);
  }
}

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html'
})
export class TextEditorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() value: Delta;
  @Input() editable = true;
  @Input() textParagraphs = false;
  @Input() toolbarElement: ElementRef<HTMLElement>;
  @Input() toolbarHeadersEnabled = true;
  @Input() toolbarTextStyleEnabled = true;
  @Input() toolbarInputLabel = false;
  @Input() toolbarListsEnabled = true;
  @Input() toolbarAlignEnabled = true;
  @Input() toolbarCleanEnabled = true;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() schema: 'light' | 'dark' | 'grey' = 'light';
  @Input() paddingSize: PageBlockPaddingSize = 'none';
  @Input() analyticsSource: string;
  @Output() changed = new EventEmitter<Delta>();
  @Output() escapeClick = new EventEmitter<void>();

  @ViewChild('toolbar') toolbar: ElementRef;
  @ViewChild('editorjs') elEditor: ElementRef;
  @ViewChild('toolbar_left') toolbarLeft: TemplateRef<any>;

  // editor: EditorJS;
  editor2: Quill;
  editable$ = new BehaviorSubject<boolean>(true);
  editorToolbar: {
    viewRef: EmbeddedViewRef<any>;
    element: HTMLElement;
  };
  inputComponents: InlineInputDynamicComponent[] = [];
  empty = false;

  onTextChange = (() => {
    return (delta, oldDelta, source) => {
      const value = this.editor2.getContents();

      this.empty = this.isEmpty();
      this.cd.markForCheck();

      if (deltasEqual(this.value, value)) {
        return;
      }

      this.changed.emit(value);
    };
  })();

  constructor(
    public themeService: ThemeService,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    // if (this.editor) {
    //   this.editor.destroy();
    // }

    if (this.editor2) {
      this.editor2.off('text-change', this.onTextChange);
    }

    if (this.editorToolbar) {
      this.editorToolbar.viewRef.destroy();
    }

    this.inputComponents.forEach(item => item.destroy());
  }

  ngOnChanges(changes: TypedChanges<TextEditorComponent>): void {
    if (changes.editable) {
      if (this.editor2) {
        if (this.editable) {
          this.editor2.enable();
        } else {
          this.editor2.disable();
        }
      }

      this.editable$.next(this.editable);
    }

    if (changes.value && !changes.value.firstChange) {
      if (this.editor2) {
        if (this.value) {
          const value = this.editor2.getContents();

          if (!deltasEqual(this.value, value)) {
            this.editor2.setContents(this.value, 'silent');
          }
        }
      }
    }

    if (changes.toolbarElement && this.toolbarElement) {
      if (this.editorToolbar) {
        this.toolbarElement.nativeElement.appendChild(this.editorToolbar.element);
      }
    }
  }

  ngAfterViewInit(): void {
    // this.initEditor();
    this.initEditor2();
  }

  createInputComponent(container: HTMLElement, value?: Object): InlineInputDynamicComponent {
    const formGroup = new FieldInputControl({ name: 'value', ...value }, FieldInputRequiredValidator);
    const portalHost = new DomPortalHost(container, this.componentFactoryResolver, this.appRef, this.injector);
    const portal = new ComponentPortal<InlineInputEditComponent>(
      InlineInputEditComponent,
      this.viewContainerRef,
      this.injector
    );
    const ref = portalHost.attach(portal);

    ref.instance.itemForm = formGroup;
    ref.instance.context = this.context;
    ref.instance.contextElement = this.contextElement;
    ref.instance.contextElementPath = this.contextElementPath;
    ref.instance.contextElementPaths = this.contextElementPaths;
    ref.instance.editable$ = this.editable$;
    ref.instance.analyticsSource = this.analyticsSource;

    if (value[inlineInputInitialProperty]) {
      ref.instance.focusedInitial = true;
      delete value[inlineInputInitialProperty];
    }

    ref.changeDetectorRef.detectChanges();

    const result = new InlineInputDynamicComponent(ref, portalHost);
    this.inputComponents.push(result);
    return result;
  }

  disposeInputComponent(component: InlineInputDynamicComponent) {
    component.destroy();
    this.inputComponents = this.inputComponents.filter(item => item !== component);
  }

  createEditorToolbarElement(): {
    viewRef: EmbeddedViewRef<any>;
    element: HTMLElement;
  } {
    const viewRef = this.viewContainerRef.createEmbeddedView(this.toolbarLeft);

    viewRef.detectChanges();

    const element = viewRef.rootNodes[0] as HTMLElement;
    removeElement(element);

    return {
      viewRef: viewRef,
      element: element
    };
  }

  initEditor2() {
    this.editorToolbar = this.createEditorToolbarElement();
    this.editor2 = new Quill(this.elEditor.nativeElement, {
      theme: 'snow',
      bounds: this.elEditor.nativeElement,
      placeholder: 'Enter content...',
      readOnly: !this.editable,
      modules: {
        contextFormula: {
          createComponentFn: (container: HTMLElement, value?: Object) => {
            return this.createInputComponent(container, value);
          },
          disposeComponentFn: (ref: InlineInputDynamicComponent) => {
            this.disposeInputComponent(ref);
          }
        },
        toolbar: {
          container: this.editorToolbar.element
        }
      }
    });

    this.editor2.keyboard.addBinding({ key: KeyboardEventKeyCode.Escape }, () => this.escapeClick.emit());

    if (this.toolbarElement) {
      this.toolbarElement.nativeElement.appendChild(this.editorToolbar.element);
    }

    if (this.value) {
      this.editor2.setContents(this.value, 'silent');

      const history = this.editor2.getModule('history');
      if (history) {
        history.clear();
      }
    }

    this.empty = this.isEmpty();
    this.cd.markForCheck();

    this.editor2.on('text-change', this.onTextChange);
  }

  getLength(): number {
    if (!this.editor2) {
      return;
    }

    return this.editor2.getLength();
  }

  isEmpty(): boolean {
    if (!this.editor2) {
      return;
    }

    const contents = this.editor2.getContents();
    return contents.ops.length == 1 && contents.ops[0].insert == '\n';
  }

  selectAll() {
    if (!this.editor2) {
      return;
    }

    this.editor2.setSelection(0, this.getLength());
  }

  // initEditor() {
  //   const createComponentRef = (container: HTMLElement, value?: Object) => {
  //     const formGroup = new FieldInputControl({ name: 'value', ...value }, FieldInputRequiredValidator);
  //     return this.dynamicComponentService.createComponent<InlineInputEditComponent>(
  //       this.injector,
  //       {
  //         component: InlineInputEditComponent,
  //         analyticsSource: this.analyticsSource,
  //         inputs: {
  //           itemForm: formGroup,
  //           context: this.context
  //         }
  //       },
  //       container
  //     );
  //   };
  //
  //   class FormulaBlock {
  //     static get toolbox() {
  //       return {
  //         title: 'Computed Text',
  //         icon: '<span class="icon-function" style="font-size: 18px"></span>'
  //       };
  //     }
  //
  //     data: FormulaBlockData = {};
  //     componentRef: ComponentRef<InlineInputEditComponent>;
  //
  //     constructor({ api, config, data }) {
  //       this.data = {
  //         value: data.value || {
  //           value_type: 'formula',
  //           formula_value: `FORMAT('This is an {0}', 'example')`
  //         }
  //       };
  //     }
  //
  //     render() {
  //       const root = document.createElement('div');
  //       addClass(root, 'ce-paragraph cdx-block');
  //       this.componentRef = createComponentRef(root, this.data.value);
  //       return root;
  //     }
  //
  //     save(): FormulaBlockData {
  //       const value = this.componentRef ? this.componentRef.instance.itemForm.value : undefined;
  //       return {
  //         value: value
  //       };
  //     }
  //
  //     removed() {
  //       if (this.componentRef) {
  //         this.componentRef.destroy();
  //       }
  //     }
  //   }
  //
  //   this.editor = new EditorJS({
  //     holder: this.elEditor.nativeElement,
  //     placeholder: 'Enter text',
  //     tools: {
  //       header: {
  //         class: Header,
  //         config: {
  //           placeholder: 'Header',
  //           levels: [1, 2, 3],
  //           defaultLevel: 1
  //         },
  //         inlineToolbar: ['link']
  //       },
  //       formula: FormulaBlock
  //     },
  //     data: this.value,
  //     minHeight: 0,
  //     onChange: () => {
  //       this.change();
  //     }
  //   });
  // }

  // change() {
  //   this.editor
  //     .save()
  //     .then(outputData => {
  //       this.changed.next(outputData);
  //     })
  //     .catch(error => {
  //       console.log('Saving failed: ', error);
  //     });
  // }

  onMouseDown(event: MouseEvent) {
    ignoreDragDrop(event);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Environment } from '@modules/projects';

import { Project } from '../../data/project';

@Injectable({
  providedIn: 'root'
})
export class KeygenService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  generate(project: Project, environment: Environment): Observable<{ private_key: string; public_key: string }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'keygen/');
        let headers = new HttpHeaders();
        const data = {};
        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  clear(project: Project, environment: Environment): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'keygen/');
        let headers = new HttpHeaders();
        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

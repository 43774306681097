import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageEditorComponent } from './components/image-editor/image-editor.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ImageEditorComponent],
  exports: [ImageEditorComponent],
  entryComponents: [ImageEditorComponent]
})
export class ImageEditorModule {}

import pickBy from 'lodash/pickBy';

export enum ApproveResultState {
  Approved = 'approved',
  Rejected = 'rejected'
}

export class ApproveResult {
  public state: ApproveResultState;
  public result: Object;
  public reason: string;

  deserialize(data: Object) {
    this.state = data['state'];
    this.result = data['result'];
    this.reason = data['reason'];

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      state: this.state,
      result: this.result,
      reason: this.reason
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subscription } from 'rxjs';

import { TintStyle } from '@modules/actions';
import {
  AlignHorizontal,
  ElementType,
  FormSubmitElementItem,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';

import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-submit-element',
  templateUrl: './form-submit-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewContextElement]
})
export class FormSubmitElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: FormSubmitElementItem;

  verboseName: string;
  verboseNameSubscription: Subscription;
  disabled = false;
  disabledSubscription: Subscription;
  style: TintStyle = TintStyle.Primary;
  tintStyles = TintStyle;
  alignHorizontals = AlignHorizontal;
  isFormSubmitDisabled$: Observable<boolean>;

  constructor(@Optional() public formElement: FormElementComponent, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.isFormSubmitDisabled$ = this.formElement ? this.formElement.isSubmitDisabled$() : of(true);
    this.initVerboseName();
    this.initStyle();
    this.initDisabled();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element']) {
      this.initVerboseName();
      this.initStyle();
      this.initDisabled();
    }
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.element.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.element.verboseNameInput, {
      context: this.context,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  initStyle() {
    this.style = this.element.style ? this.element.style : TintStyle.Primary;
    this.cd.markForCheck();
  }

  getElementDisabled$() {
    return this.element.disabledInput && this.element.disabledInput.isSet()
      ? applyBooleanInput$(this.element.disabledInput, {
          context: this.context
        })
      : of(false);
  }

  initDisabled() {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    if (!this.element) {
      this.disabled = false;
      this.cd.markForCheck();
      return;
    }

    this.getElementDisabled$()
      .pipe(untilDestroyed(this))
      .subscribe(inputDisabled => {
        this.disabled = inputDisabled;
        this.cd.markForCheck();
      });
  }
}

registerElementComponent({
  type: ElementType.FormSubmit,
  component: FormSubmitElementComponent,
  alwaysActive: false,
  label: 'Form submit',
  actions: []
});

import { FocusMonitor } from '@angular/cdk/a11y';
import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appFocusMonitor]',
  exportAs: 'appFocusMonitor'
})
export class FocusMonitorDirective implements OnInit, OnDestroy {
  private _focused$ = new BehaviorSubject<boolean>(false);

  constructor(private el: ElementRef, private focusMonitor: FocusMonitor) {}

  ngOnInit(): void {
    this.focusMonitor
      .monitor(this.el.nativeElement)
      .pipe(
        map(origin => !!origin),
        untilDestroyed(this)
      )
      .subscribe(focused => {
        this._focused$.next(focused);
      });
  }

  ngOnDestroy(): void {}

  get focused(): boolean {
    return this._focused$.value;
  }

  get focused$(): Observable<boolean> {
    return this._focused$.asObservable();
  }
}

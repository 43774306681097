import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApiModule } from '@modules/api';
import { SharedModule } from '@shared';

import './data/button-menu-item';
import './data/image-menu-item';
import './data/model-link-menu-item';
import './data/section-menu-item';
import './data/separator-menu-item';
import './data/share-menu-item';
import './data/simple-menu-item';

@NgModule({
  imports: [CommonModule, ApiModule, SharedModule]
})
export class MenuModule {}

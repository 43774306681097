<app-auto-field
  [form]="form"
  [field]="
    createField({
      name: 'control',
      field: valueField.field,
      placeholder: 'Enter value...' | localize,
      params: valueField.params
    })
  "
  [label]="false"
  [errors]="false"
  [autofocus]="autoFocus"
>
</app-auto-field>

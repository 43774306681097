import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { SqlQuery } from '@modules/queries';

@Injectable()
export class QueryBuilderSqlForm extends FormGroup {
  controls: {
    query: FormControl;
    request_response: FormControl;
    request_response_columns: FormControl;
    request_tokens: FormControl;
  };

  constructor() {
    super({
      query: new FormControl(''),
      request_response: new FormControl(undefined),
      request_response_columns: new FormControl(undefined),
      request_tokens: new FormControl({})
    });
  }

  init(query?: SqlQuery) {
    if (query) {
      this.controls.query.patchValue(query.query);
      this.controls.request_response.patchValue(query.requestResponse);
      this.controls.request_response_columns.patchValue(query.requestResponseColumns);
      this.controls.request_tokens.patchValue(query.requestTokens);
    }
  }

  getInstance(): SqlQuery {
    const instance = new SqlQuery();

    instance.query = this.controls.query.value;
    instance.requestResponse = this.controls.request_response.value;
    instance.requestResponseColumns = this.controls.request_response_columns.value;
    instance.requestTokens = this.controls.request_tokens.value;

    return instance;
  }
}

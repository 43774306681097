<div class="container-stub__item container-stub__item_card">
  <div class="container-stub-placeholder container-stub-placeholder_border">
    <div class="container-stub-placeholder__image container-stub-placeholder__image_type_drag-n-drop"></div>
    <div class="container-stub-placeholder__title">Start to drag-and-drop <br />components</div>
    <!--    <div class="container-builder-stub-placeholder__description">Completely new empty application</div>-->

    <ng-container *ngIf="templates">
      <div class="container-stub-placeholder__label">OR</div>
      <div class="container-stub-placeholder__actions">
        <!--        <button-->
        <!--          type="button"-->
        <!--          class="button button_primary button_orange-alternative"-->
        <!--          (click)="chooseTemplate()"-->
        <!--          [appClickEvent]="analyticsEvents.Template.ClickedChooseTemplate"-->
        <!--        >-->
        <!--          Import App from Resource-->
        <!--        </button>-->
        <button type="button" class="button button_primary button_orange-alternative" (click)="templatesClick.emit()">
          Use Page templates
        </button>
      </div>
    </ng-container>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-completion',
  templateUrl: './customize-bar-action-edit-completion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditCompletionComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() object: string;
  @Input() firstInit = false;

  constructor() {}

  ngOnInit() {}
}

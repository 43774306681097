<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="!!section"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
>
  <div
    class="popover2 overlay-content overlay-content_animated"
    (mouseenter)="hover.emit(true)"
    (mouseleave)="hover.emit(false)"
  >
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content" style="min-width: 300px;">
          <div
            *ngFor="let item of section.items"
            class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
            [class.token-popup-list-item_orange]="value == item.value && false"
            [class.token-popup-list-item_active]="value == item.value"
            (click)="select.emit(item.value)"
          >
            <div class="token-popup-list-item__inner">
              <div class="token-popup-list-item__line">
                <span *ngIf="item.icon" class="token-popup-list-item__left">
                  <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                </span>

                <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                  {{ item.name }}
                </span>
              </div>
              <div
                *ngIf="item.subtitle"
                class="token-popup-list-item__line"
                [class.token-popup-list-item__line_active]="false"
              >
                <div class="token-popup-list-item__description">
                  {{ item.subtitle | appCapitalize }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popover2__arrow"></div>
    </div>
  </div>
</ng-template>

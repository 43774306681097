import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Power1, TweenMax } from 'gsap';

import { isSet } from '../../utils/common/common';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleProgressComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() progress = 0;
  @Input() label: string;
  @Input() inline = false;
  @ViewChild('circle') circle: ElementRef;
  @ViewChild('percent') percent: ElementRef;

  _circleProgress = 0;

  ngOnInit() {
    this.circleProgress = this.progress;
  }

  ngOnChanges(changes: SimpleChanges): void {
    TweenMax.to(this, 0.6, {
      circleProgress: isSet(this.progress) ? this.progress : 0.15,
      ease: Power1.easeOut,
      onUpdate: () => this.updatePercentElement()
    });
  }

  ngAfterViewInit(): void {
    this.updatePercentElement();
    this.updateStroke();
  }

  get circleProgress() {
    return this._circleProgress;
  }

  set circleProgress(progress) {
    if (!isSet(progress)) {
      this._circleProgress = undefined;
      return;
    }

    this._circleProgress = progress;
    this.updateStroke();
  }

  updatePercentElement() {
    if (!this.percent) {
      return;
    }

    this.percent.nativeElement.innerHTML =
      isSet(this.progress) && isSet(this.circleProgress)
        ? `${Math.round(this.circleProgress * 100)}%`
        : `<span class="stub-text">X%</span>`;
  }

  updateStroke() {
    if (!this.circle) {
      return;
    }

    const from = 0;
    const to = this._circleProgress || 0;
    const el = this.circle.nativeElement;
    const length = el.getTotalLength();
    el.style['stroke-dasharray'] = [length * (to - from), length * (1 - (to - from))].join(' ');
    el.style['stroke-dashoffset'] = -length * from;
  }
}

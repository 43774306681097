import { FieldType, ParameterField } from '@modules/fields';
import { JET_APP_RESOURCE } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';

import { ActionDescription, QueryAction } from './action-description';
import { ActionType } from './action-type';

export const JET_APP_SEND_EMAIL = 'send_email';

export function getJetAppSendEmailActionDescription() {
  const result = new ActionDescription();

  result.name = JET_APP_SEND_EMAIL;
  result.resource = JET_APP_RESOURCE;
  result.type = ActionType.Query;
  result.verboseName = 'send email';

  const params = [
    {
      name: 'to',
      verboseName: 'to',
      field: FieldType.Text,
      required: true
    },
    {
      name: 'subject',
      verboseName: 'subject',
      field: FieldType.Text,
      required: true
    },
    {
      name: 'text',
      verboseName: 'text',
      field: FieldType.Text,
      required: false,
      params: {
        multiline: true
      }
    },
    {
      name: 'text_html',
      verboseName: 'text (html)',
      field: FieldType.RichText,
      required: false
    }
  ];

  result.actionParams = params.map(item => {
    const field = new ParameterField();

    field.name = item.name;
    field.verboseName = item.verboseName;
    field.field = item.field;
    field.required = item.required;
    field.params = item.params;
    field.updateFieldDescription();

    return field;
  });

  result.queryAction = new QueryAction();
  result.queryAction.query = new ActionQuery();
  result.queryAction.query.queryType = QueryType.Simple;
  result.queryAction.query.simpleQuery = new result.queryAction.query.simpleQueryClass();
  result.queryAction.query.simpleQuery.name = JET_APP_SEND_EMAIL;

  return result;
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WithBuilderMode } from '@modules/customize';
import { Dashboard, DashboardService, DashboardStore } from '@modules/dashboard';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { DashboardComponent } from '../dashboard/dashboard.component';
var IndexComponent = /** @class */ (function () {
    function IndexComponent(dashboardStore, dashboardService, currentProjectStore, currentEnvironmentStore, routing, cd, activatedRoute) {
        this.dashboardStore = dashboardStore;
        this.dashboardService = dashboardService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.routing = routing;
        this.cd = cd;
        this.activatedRoute = activatedRoute;
        this.items = [];
        this.loading = false;
    }
    IndexComponent.prototype.ngOnInit = function () {
        this.loadDashboard();
    };
    IndexComponent.prototype.ngOnDestroy = function () { };
    IndexComponent.prototype.onBeforeDestroy = function () {
        if (this.dashboardComponent) {
            this.dashboardComponent.onBeforeDestroy();
        }
    };
    IndexComponent.prototype.loadDashboard = function () {
        var _this = this;
        combineLatest(this.dashboardStore.get(), this.activatedRoute.params)
            .pipe(switchMap(function () {
            var id = _this.activatedRoute.snapshot.params['id'];
            _this.loading = true;
            _this.cd.markForCheck();
            var obs;
            if (id) {
                obs = _this.dashboardStore.getDetailFirst(id);
            }
            else {
                obs = _this.dashboardStore.getFirst().pipe(switchMap(function (result) {
                    if (!result.length) {
                        return _this.dashboardService
                            .create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, new Dashboard().deserialize({
                            project: _this.currentProjectStore.instance.uniqueName,
                            name: 'First',
                            ordering: 1
                        }))
                            .pipe(tap(function (dashboard) {
                            _this.dashboardStore.getFirst(true);
                        }));
                    }
                    return of(result[0]);
                }));
            }
            return obs;
        }), untilDestroyed(this))
            .subscribe(function (dashboard) {
            var id = _this.activatedRoute.snapshot.params['id'];
            if (!id) {
                _this.routing.navigateApp(dashboard.link, { replaceUrl: true });
                return;
            }
            _this.dashboard = dashboard;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    IndexComponent = __decorate([
        WithBuilderMode,
        __metadata("design:paramtypes", [DashboardStore,
            DashboardService,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            RoutingService,
            ChangeDetectorRef,
            ActivatedRoute])
    ], IndexComponent);
    return IndexComponent;
}());
export { IndexComponent };

import { ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { DynamicComponentService } from '@common/dynamic-component';
import { TipComponent } from '@common/tips';
import { isSet, nodeListToArray } from '@shared';

import { TaskCompleteAction, TaskScenarioItemTip } from '../../data/task-scenario';
import { GuideFocusService } from '../guide-focus/guide-focus.service';
import { GuideService } from '../guide/guide.service';
import { CurrentTaskState } from '../guide/guide.service';

@Injectable()
export class TutorialTipService implements OnDestroy {
  state: CurrentTaskState;
  statePageItemName: string;
  el: Element;
  options: TaskScenarioItemTip;
  componentRef: ComponentRef<TipComponent>;
  subscriptions: Subscription[] = [];

  constructor(
    private guideService: GuideService,
    private guideFocusService: GuideFocusService,
    private injector: Injector,
    private dynamicComponentService: DynamicComponentService
  ) {
    interval(60).subscribe(() => {
      this.state = this.guideService.state;
      this.statePageItemName = this.guideService.statePageItemName;
      this.options = this.guideService.statePageItem ? this.guideService.statePageItem.tip : undefined;

      let el;

      if (this.options) {
        let items = nodeListToArray(document.querySelectorAll(this.options.selector));

        if (this.options.selectorFilter) {
          items = this.options.selectorFilter(items);
        }

        el = items[0];
      }

      if (this.el === el) {
        return;
      }

      if (this.el) {
        this.hide();
        this.deinitEvents();
      }

      this.el = el;

      if (this.el) {
        this.show();
        this.initEvents();
      }
    });
  }

  ngOnDestroy(): void {
    this.hide();
    this.deinitEvents();
  }

  initEvents() {
    if (this.subscriptions.length) {
      return;
    }

    if (this.options.completeAction == TaskCompleteAction.Hover) {
      this.subscriptions.push(
        fromEvent<MouseEvent>(this.el, 'mouseenter').subscribe(() => {
          this.onActivated();
        })
      );
    } else if (this.options.completeAction == TaskCompleteAction.Click) {
      this.subscriptions.push(
        fromEvent<MouseEvent>(this.el, 'click').subscribe(() => {
          this.onActivated();
        })
      );
    } else if (this.options.completeAction == TaskCompleteAction.Input) {
      this.subscriptions.push(
        fromEvent<MouseEvent>(this.el, 'input')
          .pipe(
            debounceTime(1000),
            filter(() => isSet((this.el as HTMLInputElement).value))
          )
          .subscribe(() => {
            this.onActivated();
          })
      );
    }
  }

  deinitEvents() {
    if (!this.subscriptions.length) {
      return;
    }

    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];
  }

  show() {
    if (this.componentRef) {
      this.hide();
    }

    if (!this.options.text) {
      return;
    }

    const componentRef: ComponentRef<TipComponent> = this.dynamicComponentService.appendComponentToBody(this.injector, {
      component: TipComponent,
      inputs: {
        content: this.options.text,
        options: {
          hoverable: false,
          stick: true,
          style: 'bright'
        },
        sourceElement: this.el
      },
      outputs: {
        closed: [
          () => {
            componentRef.destroy();

            if (this.componentRef == componentRef) {
              this.componentRef = undefined;
            }
          }
        ],
        moved: [
          position => {
            if (!this.guideService.state) {
              return;
            }
            this.guideFocusService.position = { x: position.x, y: position.y };
          }
        ]
      }
    });

    this.componentRef = componentRef;
  }

  hide() {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.instance.close();
  }

  onActivated() {
    this.guideService.completeTaskProgress(this.state.task.uniqueName, this.state.page, this.statePageItemName);
  }
}

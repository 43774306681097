/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./import-figma-node-item-stub.component";
var styles_ImportFigmaNodeItemStubComponent = [];
var RenderType_ImportFigmaNodeItemStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImportFigmaNodeItemStubComponent, data: {} });
export { RenderType_ImportFigmaNodeItemStubComponent as RenderType_ImportFigmaNodeItemStubComponent };
export function View_ImportFigmaNodeItemStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "td", [["class", "card-table__column"], ["style", "width: 110px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "card-table__column-inner"], ["style", "padding-left: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Name"])), (_l()(), i0.ɵeld(7, 0, null, null, 5, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "span", [["class", "card-table__grey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "card-table__column-inner"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 4, 0, currVal_0); var currVal_1 = true; _ck(_v, 10, 0, currVal_1); }); }
export function View_ImportFigmaNodeItemStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-import-figma-node-item-stub", [], null, null, null, View_ImportFigmaNodeItemStubComponent_0, RenderType_ImportFigmaNodeItemStubComponent)), i0.ɵdid(1, 114688, null, 0, i1.ImportFigmaNodeItemStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportFigmaNodeItemStubComponentNgFactory = i0.ɵccf("app-import-figma-node-item-stub, [app-import-figma-node-item-stub]", i1.ImportFigmaNodeItemStubComponent, View_ImportFigmaNodeItemStubComponent_Host_0, {}, {}, []);
export { ImportFigmaNodeItemStubComponentNgFactory as ImportFigmaNodeItemStubComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { DataSyncJob, DataSyncJobTask, DataSyncJobTaskStore } from '@modules/data-sync';
import { Resource } from '@modules/projects';

@Component({
  selector: 'app-data-sync-job-tasks',
  templateUrl: './data-sync-job-tasks.component.html',
  providers: [DataSyncJobTaskStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSyncJobTasksComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() job: DataSyncJob;
  @Output() closed = new EventEmitter<void>();

  items$: Observable<DataSyncJobTask[]>;
  loading$: Observable<boolean>;
  hasMore$: Observable<boolean>;
  error: string;

  constructor(
    private store: DataSyncJobTaskStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.jobId = this.job.id;
    this.loading$ = this.store.loading$;
    this.items$ = this.store.items$;
    this.hasMore$ = this.store.hasMore$;

    this.store
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {},
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  getNext() {
    this.store.getNext().pipe(untilDestroyed(this)).subscribe();
  }

  close() {
    this.popupComponent.close();
    this.closed.emit();
  }
}

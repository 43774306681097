var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { FieldType, OptionsType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ResourceType, SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { ListModelDescriptionQuery, ModelDescriptionQuery, ModelDescriptionSimpleQuery, QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService, XANO_COLLECTION_TABLE_ID_NAME, XANO_PRIMARY_KEY_FIELD_NAME, XanoFieldType } from '@modules/resources';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { XANO_STORAGE_NAME } from './xano-constants';
import { XanoLegacyGeneratorService } from './xano-legacy-generator.service';
var XanoGeneratorService = /** @class */ (function (_super) {
    __extends(XanoGeneratorService, _super);
    function XanoGeneratorService(mode, resourceControllerService, secretTokenService, legacyGenerator) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.resourceControllerService = resourceControllerService;
        _this.secretTokenService = secretTokenService;
        _this.legacyGenerator = legacyGenerator;
        _this.controller = _this.resourceControllerService.get(ResourceType.Xano);
        return _this;
    }
    XanoGeneratorService.prototype.isOptionsValid = function (options) {
        return this.controller.getWorkspace(options.accessToken, options.domain, options.workspaceId).pipe(map(function (result) {
            return {
                accountName: result.name
            };
        }), catchError(function (error) {
            var errorMessage = error instanceof ServerRequestError &&
                error.response instanceof HttpErrorResponse &&
                error.status >= 400 &&
                error.status < 500 &&
                error.response.error &&
                error.response.error['message']
                ? error.response.error['message']
                : undefined;
            if (errorMessage) {
                error = new ServerRequestError(errorMessage);
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    XanoGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            var domain = resource.params['domain'];
            var workspaceId = resource.params['workspace_id'];
            if (!isSet(domain) && !isSet(workspaceId)) {
                // Backward compatibility
                return {
                    apiBaseUrl: resource.params['api_base_url'],
                    authHeader: result.value
                };
            }
            else {
                return {
                    accessToken: result.value,
                    domain: domain,
                    workspaceId: workspaceId
                };
            }
        }));
    };
    XanoGeneratorService.prototype.fetchApi = function (options) {
        var _this = this;
        if (!isSet(options.domain) && !isSet(options.workspaceId)) {
            // Backward compatibility
            return this.legacyGenerator.fetchApi(options);
        }
        return this.controller.getTableSchemas(options.accessToken, options.domain, options.workspaceId).pipe(map(function (tableSchemas) {
            var modelDescriptions = tableSchemas.map(function (tableSchema) {
                var _a;
                var modelDescription = new ModelDescription();
                modelDescription.project = '{{project}}';
                modelDescription.resource = '{{resource}}';
                modelDescription.model = tableSchema.table.name;
                modelDescription.verboseName = modelDescription.model;
                modelDescription.verboseNamePlural = modelDescription.model;
                modelDescription.primaryKeyField = XANO_PRIMARY_KEY_FIELD_NAME;
                modelDescription.params = (_a = {}, _a[XANO_COLLECTION_TABLE_ID_NAME] = tableSchema.table.id, _a);
                modelDescription.getQuery = _this.generateModelDescriptionGet(modelDescription);
                modelDescription.fields = tableSchema.fields.map(function (field) {
                    var _a = _this.detectCollectionSchemaField(field, tableSchemas), fieldType = _a.fieldType, params = _a.params;
                    var result = new ModelField();
                    var dbField = new ModelDbField();
                    dbField.name = field.name;
                    dbField.required = false;
                    dbField.null = field.nullable;
                    dbField.editable = field.name != XANO_PRIMARY_KEY_FIELD_NAME;
                    dbField.filterable = true;
                    dbField.field = fieldType;
                    dbField.params = params;
                    dbField.updateFieldDescription();
                    result.name = dbField.name;
                    result.type = ModelFieldType.Db;
                    result.item = dbField;
                    return result;
                });
                modelDescription.getDetailQuery = _this.generateModelDescriptionGetDetail(modelDescription);
                modelDescription.getDetailParametersUseDefaults = true;
                modelDescription.createQuery = _this.generateModelDescriptionCreate(modelDescription);
                modelDescription.createParametersUseDefaults = true;
                modelDescription.updateQuery = _this.generateModelDescriptionUpdate(modelDescription);
                modelDescription.updateParametersUseDefaults = true;
                modelDescription.deleteQuery = _this.generateModelDescriptionDelete(modelDescription);
                modelDescription.deleteParametersUseDefaults = true;
                return modelDescription;
            });
            var storage = _this.createStorage();
            return {
                modelDescriptions: modelDescriptions,
                storage: storage
            };
        }));
    };
    XanoGeneratorService.prototype.generateModelDescriptionGet = function (modelDescription) {
        var getSimpleQuery = new ModelDescriptionSimpleQuery();
        getSimpleQuery.model = modelDescription.model;
        var getQuery = new ListModelDescriptionQuery();
        getQuery.queryType = QueryType.Simple;
        getQuery.simpleQuery = getSimpleQuery;
        return getQuery;
    };
    XanoGeneratorService.prototype.generateModelDescriptionGetDetail = function (modelDescription) {
        var getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
        getDetailSimpleQuery.model = modelDescription.model;
        var getDetailQuery = new ModelDescriptionQuery();
        getDetailQuery.queryType = QueryType.Simple;
        getDetailQuery.simpleQuery = getDetailSimpleQuery;
        return getDetailQuery;
    };
    XanoGeneratorService.prototype.generateModelDescriptionCreate = function (modelDescription) {
        var createSimpleQuery = new ModelDescriptionSimpleQuery();
        createSimpleQuery.model = modelDescription.model;
        var createQuery = new ModelDescriptionQuery();
        createQuery.queryType = QueryType.Simple;
        createQuery.simpleQuery = createSimpleQuery;
        return createQuery;
    };
    XanoGeneratorService.prototype.generateModelDescriptionUpdate = function (modelDescription) {
        var updateSimpleQuery = new ModelDescriptionSimpleQuery();
        updateSimpleQuery.model = modelDescription.model;
        var updateQuery = new ModelDescriptionQuery();
        updateQuery.queryType = QueryType.Simple;
        updateQuery.simpleQuery = updateSimpleQuery;
        return updateQuery;
    };
    XanoGeneratorService.prototype.generateModelDescriptionDelete = function (modelDescription) {
        var deleteSimpleQuery = new ModelDescriptionSimpleQuery();
        deleteSimpleQuery.model = modelDescription.model;
        var deleteQuery = new ModelDescriptionQuery();
        deleteQuery.queryType = QueryType.Simple;
        deleteQuery.simpleQuery = deleteSimpleQuery;
        return deleteQuery;
    };
    XanoGeneratorService.prototype.detectCollectionSchemaField = function (field, tableSchemas) {
        var fieldType;
        var params;
        if (field.tableref_id !== undefined) {
            var relatedTableSchema = tableSchemas.find(function (item) { return item.table.id == field.tableref_id; });
            fieldType = FieldType.RelatedModel;
            params = __assign({}, (relatedTableSchema && {
                related_model: {
                    model: relatedTableSchema.table.name
                }
            }));
        }
        else if (field.type == XanoFieldType.Password) {
            fieldType = FieldType.Password;
        }
        else if (field.type == XanoFieldType.Int) {
            fieldType = FieldType.Number;
            params = {
                value_format: {
                    number_fraction: 0
                }
            };
        }
        else if (field.type == XanoFieldType.Decimal) {
            fieldType = FieldType.Number;
        }
        else if (field.type == XanoFieldType.Bool) {
            fieldType = FieldType.Boolean;
        }
        else if (field.type == XanoFieldType.Enum) {
            fieldType = FieldType.Select;
            params = __assign({ options_type: OptionsType.Static }, (field.values && {
                options: field.values.map(function (item) {
                    return {
                        value: item,
                        name: item
                    };
                })
            }));
        }
        else if (field.type == XanoFieldType.Timestamp) {
            fieldType = FieldType.DateTime;
            params = {
                date: true,
                time: true
            };
        }
        else if (field.type == XanoFieldType.Date) {
            fieldType = FieldType.DateTime;
            params = {
                date: true,
                time: false
            };
        }
        else if (field.type == XanoFieldType.Attachment) {
            fieldType = FieldType.File;
            params = {
                storage_resource: '{{resource}}',
                storage_name: XANO_STORAGE_NAME,
                multiple: field.style == 'list'
            };
        }
        else if (field.type == XanoFieldType.Image) {
            fieldType = FieldType.Image;
            params = {
                storage_resource: '{{resource}}',
                storage_name: XANO_STORAGE_NAME,
                multiple: field.style == 'list'
            };
        }
        else if (field.type == XanoFieldType.Audio) {
            fieldType = FieldType.Audio;
            params = {
                storage_resource: '{{resource}}',
                storage_name: XANO_STORAGE_NAME,
                multiple: field.style == 'list'
            };
        }
        else if (field.type == XanoFieldType.Video) {
            fieldType = FieldType.Video;
            params = {
                storage_resource: '{{resource}}',
                storage_name: XANO_STORAGE_NAME,
                multiple: field.style == 'list'
            };
        }
        else if (field.type == XanoFieldType.Object) {
            fieldType = FieldType.JSON;
        }
        else if (field.type == XanoFieldType.JSON) {
            fieldType = FieldType.JSON;
        }
        else if (field.type == XanoFieldType.GeoPoint) {
            fieldType = FieldType.Location;
        }
        else {
            fieldType = FieldType.Text;
        }
        return { fieldType: fieldType, params: params };
    };
    XanoGeneratorService.prototype.createStorage = function () {
        var storage = new Storage();
        storage.uniqueName = XANO_STORAGE_NAME;
        storage.name = 'Files';
        storage.uploadQuery = new StorageQuery();
        storage.uploadQuery.queryType = QueryType.Simple;
        storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();
        storage.getQuery = new StorageQuery();
        storage.getQuery.queryType = QueryType.Simple;
        storage.getQuery.simpleQuery = new storage.getQuery.simpleQueryClass();
        storage.removeQuery = new StorageQuery();
        storage.removeQuery.queryType = QueryType.Simple;
        storage.removeQuery.simpleQuery = new storage.removeQuery.simpleQueryClass();
        return storage;
    };
    XanoGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        return this.fetchApi(options).pipe(map(function (result) {
            if (!result.modelDescriptions.length) {
                if (!isSet(options.domain) && !isSet(options.workspaceId)) {
                    // Backward compatibility
                    throw new ServerRequestError('No CRUD API endpoints found');
                }
                else {
                    throw new ServerRequestError('No tables found');
                }
            }
            var resourceParams = {
                domain: options.domain,
                workspace_id: options.workspaceId
            };
            var token = new SecretToken();
            token.name = HTTP_QUERY_KEY_AUTH_NAME;
            token.type = SecretTokenType.Static;
            token.value = options.accessToken;
            return {
                resourceParams: resourceParams,
                modelDescriptions: result.modelDescriptions.map(function (item) { return item.serialize(); }),
                secretTokens: [token.serialize()],
                storages: result.storage ? [result.storage.serialize()] : []
            };
        }));
    };
    return XanoGeneratorService;
}(ResourceGeneratorService));
export { XanoGeneratorService };

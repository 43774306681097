import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import isObject from 'lodash/isObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';

import { BooleanFieldStyle } from '@modules/field-components';
import {
  createFormFieldFactory,
  detectJSONFieldStructure,
  FieldType,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams,
  registerFieldViewParamsComponent
} from '@modules/fields';
import { controlValue, EMPTY, isSet } from '@shared';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { JsonFieldViewParamsForm } from './json-field-view-params.form';

@Component({
  selector: 'app-json-field-view-params',
  templateUrl: './json-field-view-params.component.html',
  providers: [JsonFieldViewParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;

  constructor(public form: JsonFieldViewParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);

    controlValue(this.form.form.controls['display_fields'])
      .pipe(distinctUntilChanged(), startWith(undefined), pairwise(), untilDestroyed(this))
      .subscribe(([prev, current]) => {
        if ((prev === false || !this.form.form.value['structure']) && current) {
          this.autoDetectStructure();
        }
      });
  }

  ngOnDestroy(): void {}

  parseContextValue() {
    const value = this.contextElement.getFieldValue(this.field.name);

    if (isSet(value) && !isObject(value)) {
      try {
        return JSON.parse(value);
      } catch (e) {}
    }

    return value;
  }

  getDefaultStructure(): JsonStructureNode {
    return {
      type: JsonStructureNodeType.Object,
      name: null,
      label: undefined,
      params: {
        items: []
      } as JsonStructureObjectParams
    };
  }

  autoDetectStructure() {
    if (!this.contextElement || !this.field) {
      return;
    }

    const value = this.parseContextValue();
    const detectedStructure = value !== EMPTY ? detectJSONFieldStructure(value) : undefined;
    const structure = detectedStructure || this.getDefaultStructure();

    this.form.form.patchValue({ structure: structure });
  }
}

registerFieldViewParamsComponent(FieldType.JSON, JsonFieldViewParamsComponent);

import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Input, ParameterField } from '@modules/fields';
import { CustomMenuItem } from '@modules/menu';
import { FieldInputControl, InputFieldProvider, parametersToProviderItemsFlat } from '@modules/parameters';

import { CustomizeBarPagesEditMenuItemControl, menuItemControls } from './customize-bar-pages-edit.form';

@Injectable()
export class CustomizeBarPagesEditCustomControl extends CustomizeBarPagesEditMenuItemControl {
  controls: {
    title: FormControl;
    custom_view: FormControl;
    inputs: FormControl;
    visible_input: FieldInputControl;
  };

  inputFieldProvider = new InputFieldProvider();

  constructor(private injector: Injector) {
    super({
      title: new FormControl('Custom item', Validators.required),
      custom_view: new FormControl(null),
      inputs: new FormControl([]),
      visible_input: new FieldInputControl({ name: 'value' })
    });
  }

  static inject(injector: Injector): CustomizeBarPagesEditCustomControl {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditCustomControl,
          useFactory: () => {
            return new CustomizeBarPagesEditCustomControl(injector);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditCustomControl);
  }

  deserialize(item: CustomMenuItem) {
    this.menuItem = item;

    this.controls.title.patchValue(item.title);
    this.controls.custom_view.patchValue(item.customView);
    this.controls.inputs.patchValue(item.inputs);
    this.controls.visible_input.patchValue(item.visibleInput ? item.visibleInput.serialize() : {});

    this.markAsPristine();
  }

  setInputsParameters(parameters: ParameterField[]) {
    const items = parametersToProviderItemsFlat((parameters || []).filter(item => item.name));
    this.inputFieldProvider.setProvider(items);
  }

  serialize(): CustomMenuItem {
    const instance = new CustomMenuItem().deserialize(this.menuItem.serialize());

    instance.title = this.controls.title.value;
    instance.customView = this.controls.custom_view.value;
    instance.inputs = this.controls.inputs.value;
    instance.visibleInput = this.controls.visible_input.value
      ? new Input().deserialize(this.controls.visible_input.value)
      : undefined;

    return instance;
  }
}

menuItemControls.push({ menuItem: CustomMenuItem, control: CustomizeBarPagesEditCustomControl });

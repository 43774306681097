export const sendGridResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'send_email',
    params: {
      type: 'query',
      verbose_name: 'Send email',
      dynamic_status: false,
      action_params: [
        {
          name: 'from_email',
          verbose_name: 'from_email',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {
            classes: ['select_fill', 'input_fill', 'input_segment-bottom', 'select_segment-bottom']
          }
        },
        {
          name: 'to_email',
          verbose_name: 'to_email',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'subject',
          verbose_name: 'subject',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {
            html: false,
            json: false,
            rows: 8,
            wide: false,
            safe_str: false,
            multiline: false
          }
        },
        {
          name: 'body',
          verbose_name: 'body',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {
            html: true,
            json: false,
            rows: 8,
            wide: true,
            safe_str: false,
            multiline: true
          }
        }
      ],
      description: 'Sends an email.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.sendgrid.com/v3/mail/send',
            auth_params: {},
            body_type: 'Raw',
            body:
              '{\n  "personalizations": [\n    {\n      "to": [\n        {\n          "email": "{{params.to_email}}"\n        }\n      ],\n      "subject": "{{params.subject}}"\n    }\n  ],\n  "from": {\n    "email": "{{params.from_email}}"\n  },\n  "content": [\n    {\n      "type": "text/html",\n      "value": {{JSON.stringify(params.body)}}\n    }\n  ]\n}',
            headers: [
              {
                name: 'Content-Type',
                value: 'application/json'
              },
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: '',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['errors'] && data['errors'][0] && data['errors'][0]['message']) {\n  // display error message if any\n  return data['errors'][0]['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  }
];

import { FieldType, ParameterField } from '@modules/fields';
import { JET_APP_RESOURCE } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ModelDbField } from './model-db-field';
import { ModelDescription, ModelField, ModelFieldType } from './model-description';
export var JET_APP_GROUPS = 'groups';
export function getJetAppGroupModelDescription(properties) {
    var result = new ModelDescription();
    result.resource = JET_APP_RESOURCE;
    result.model = JET_APP_GROUPS;
    result.verboseName = 'team';
    result.verboseNamePlural = 'teams';
    result.displayField = 'name';
    result.primaryKeyField = 'uid';
    var fields = [
        {
            name: 'uid',
            verboseName: 'ID',
            field: FieldType.Text
        },
        {
            name: 'name',
            verboseName: 'name',
            field: FieldType.Text
        }
    ].concat(properties.map(function (item) {
        return {
            name: item.uid,
            verboseName: item.name,
            field: item.field ? item.field.field : FieldType.Text,
            params: item.field ? item.field.params : {}
        };
    }));
    result.fields = fields.map(function (item) {
        var field = new ModelField();
        field.name = item.name;
        field.type = ModelFieldType.Db;
        field.item = new ModelDbField();
        field.item.name = item.name;
        field.item.verboseName = item.verboseName;
        field.item.field = item.field;
        field.item.params = item.params || {};
        field.item.updateFieldDescription();
        return field;
    });
    result.getQuery = new ListModelDescriptionQuery();
    result.getQuery.queryType = QueryType.Simple;
    result.getQuery.simpleQuery = new result.getQuery.simpleQueryClass();
    result.getQuery.simpleQuery.model = result.model;
    // jetAppGroups.getParameters = this.getModelDescriptionGetParameters(jetAppGroups);
    result.getDetailQuery = new ModelDescriptionQuery();
    result.getDetailQuery.queryType = QueryType.Simple;
    result.getDetailQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.getDetailQuery.simpleQuery.model = result.model;
    // jetAppGroups.getDetailParameters = this.getModelDescriptionGetParameters(jetAppGroups);
    result.getDetailParametersUseDefaults = true;
    result.updateQuery = new ModelDescriptionQuery();
    result.updateQuery.queryType = QueryType.Simple;
    result.updateQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.updateQuery.simpleQuery.model = result.model;
    var propertiesNames = properties.map(function (item) { return item.uid; });
    result.updateParameters = fields
        .filter(function (item) { return ['uid', 'name'].concat(propertiesNames).includes(item.name); })
        .map(function (item) {
        var parameter = new ParameterField();
        parameter.name = item.name;
        parameter.verboseName = item.verboseName;
        parameter.field = item.field;
        parameter.params = item.params || {};
        parameter.required = item.name == 'uid';
        parameter.updateFieldDescription();
        return parameter;
    });
    return result;
}

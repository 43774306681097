import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DocumentService } from '@core';
import { TintStyle } from '@modules/actions';
import { getStyleProperty } from '@shared';

@Component({
  selector: 'app-table-stub',
  templateUrl: './table-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() animating = false;
  @Input() scrollable = false;
  @Input() title: string;
  @Input() rowActions = false;
  @Input() rowActionsButtons = false;
  @Input() selection = false;
  @Input() orange = false;
  @ViewChild('header_element') headerElement: ElementRef;

  maxHeight: number;
  tintStyles = TintStyle;

  constructor(private documentService: DocumentService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (!this.scrollable) {
      return;
    }

    this.documentService.viewportSizeChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateMaxHeight();
    });

    this.updateMaxHeight();
  }

  updateMaxHeight() {
    if (!this.headerElement) {
      return;
    }

    const banner = document.getElementsByClassName('banner')[0] as HTMLElement;
    const height = [
      banner ? banner.offsetHeight : 0,
      getStyleProperty(this.headerElement.nativeElement, 'marginTop'),
      this.headerElement.nativeElement.offsetHeight,
      getStyleProperty(this.headerElement.nativeElement, 'marginBottom'),
      // getStyleProperty(this.footer.nativeElement, 'marginTop'),
      // this.footer.nativeElement.offsetHeight,
      // getStyleProperty(this.footer.nativeElement, 'marginBottom'),
      1
    ].reduce((prev, current) => prev + current, 0);

    this.maxHeight = window.innerHeight - height;
    this.cd.markForCheck();
  }
}

import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule, NO_ERRORS_SCHEMA, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule } from '@angular/router';
import * as moment from 'moment';
import { CookieModule } from 'ngx-cookie';
import * as numeral from 'numeral';
import 'numeral/locales';

import { DragDrop2Module } from '@common/drag-drop2';
import { NotificationsModule } from '@common/notifications';
import { PageLoadingModule } from '@common/page-loading';
import { PopupsModule } from '@common/popups';
import { RouteScrollService } from '@common/route-scroll';
import { SmoothScrollModule } from '@common/smooth-scroll';
import { captureException, CoreModule, ScriptsService, SessionStorage } from '@core';
import { AdminModule } from '@modules/admin';
import { AnalyticsModule } from '@modules/analytics';
import { ApiModule } from '@modules/api';
import { AuthModule } from '@modules/auth';
import { CustomViewsModule } from '@modules/custom-views';
import { DemoCreateModule } from '@modules/demo-create';
import { GlobalsModule } from '@modules/globals';
import { MigrationsModule } from '@modules/migrations';
import { NavigationModule } from '@modules/navigation';
import { ProjectsModule } from '@modules/projects';
import { ProjectsRoutesModule } from '@modules/projects-routes';
import { usersInterceptorProviders, UsersModule } from '@modules/users';
import { UsersRoutesModule } from '@modules/users-routes';
import { VersionsModule } from '@modules/versions';
import { addClass, addMatSelectCoerceArray, AppError, getNavigatorLanguages, isSet, SharedModule } from '@shared';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routes';

const IGNORE_GOOGLE_TAG_MANAGER_KEY = 'ignore_google_tag_manager';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any) {
    console.error(error);

    if (environment.ravenDSN) {
      this.captureError(error);
    }
  }

  captureError(error: any) {
    const actualException = error.originalError || error;

    if (actualException instanceof AppError) {
      return;
    }

    captureException(actualException);
  }
}

export class GlobalOverlayContainer extends OverlayContainer {
  protected _createContainer() {
    this._containerElement = document.querySelector<HTMLElement>('.app-overlay-container');
  }
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({
      appId: 'jet'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    ApiModule,
    PopupsModule,
    CustomViewsModule,
    UsersModule,
    UsersRoutesModule,
    AuthModule,
    ProjectsModule,
    ProjectsRoutesModule,
    AdminModule,
    MigrationsModule,
    AnalyticsModule,
    NotificationsModule,
    NavigationModule,
    DemoCreateModule,
    SmoothScrollModule,
    VersionsModule,
    GlobalsModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'disabled'
    }),
    CookieModule.forRoot(),
    DragDrop2Module,
    PageLoadingModule
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent],
  providers: [
    ...usersInterceptorProviders,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: OverlayContainer, useClass: GlobalOverlayContainer }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(
    private routeScrollService: RouteScrollService,
    private sessionStorage: SessionStorage,
    private scriptsService: ScriptsService
  ) {
    document.documentElement.setAttribute('env', environment.environment);

    const languages = getNavigatorLanguages();

    this.initPlatformClasses();
    this.routeScrollService.initScrollRestoration();
    addMatSelectCoerceArray();
    // this.initNumeral(languages);
    // this.initMoment(languages);

    if (
      environment.production &&
      isSet(environment.googleTagManager) &&
      !this.sessionStorage.get(IGNORE_GOOGLE_TAG_MANAGER_KEY)
    ) {
      this.scriptsService.initTagManager(environment.googleTagManager);
    }
  }

  initPlatformClasses() {
    function isMacintosh() {
      return navigator.platform.includes('Mac');
    }

    function isWindows() {
      return navigator.platform.includes('Win');
    }

    if (isMacintosh()) {
      addClass(document.body, 'body_mac');
    } else {
      addClass(document.body, 'body_not-mac');
    }

    if (isWindows()) {
      addClass(document.body, 'body_windows');
    } else {
      addClass(document.body, 'body_not-windows');
    }
  }

  initNumeral(languages: string[]) {
    languages.forEach(language => {
      if (numeral.locales[language]) {
        numeral.locale(language);
        return false;
      }
    });
  }

  async initMoment(languages: string[]) {
    await languages.forEach(async language => {
      try {
        const Locale = import(/* webpackChunkName: "moment-locale" */ 'moment/locale/' + language);
        const locale = await Locale;

        if (!locale) {
          return;
        }

        moment.locale(language);
        return false;
      } catch (e) {}
    });
  }
}

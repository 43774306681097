import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CurrentProjectStore, ProjectService } from '@modules/projects';

@Injectable()
export class ProjectResetInterfaceForm {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private projectService: ProjectService
  ) {
    this.form = this.fb.group({
      interface_settings: new FormControl({}, Validators.required)
    });
  }

  submit() {
    return this.projectService.resetSettings(this.currentProjectStore.instance, this.form.value['interface_settings']);
  }
}

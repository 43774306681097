import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelDescriptionSelectSource } from '@modules/model-queries';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-model-description-field',
  templateUrl: './model-description-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ModelDescriptionSelectSource]
})
export class ModelDescriptionFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {
  valueStr: string;

  constructor(public source: ModelDescriptionSelectSource, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.field.params['resource'] != undefined) {
      this.source.resource = this.field.params['resource'];
    }

    if (this.field.params['allow_empty'] != undefined) {
      this.source.allowEmpty = this.field.params['allow_empty'];
    }

    if (this.field.params['empty_name'] != undefined) {
      this.source.emptyName = this.field.params['empty_name'];
    }

    this.source.models = this.field.params['models'];

    this.initValueStr();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.initValueStr();
    }
  }

  initValueStr() {
    if (!this.readonly) {
      return;
    }

    if (this.currentValue) {
      this.valueStr = undefined;
      this.source
        .fetchByValue(this.currentValue)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.valueStr = result.name;
          this.cd.markForCheck();
        });
    } else {
      this.valueStr = '---';
      this.cd.markForCheck();
    }
  }
}

registerFieldComponent(FieldType.ModelDescription, ModelDescriptionFieldComponent);

import { Pipe, PipeTransform } from '@angular/core';

import { localize } from '../../utils/localize';

@Pipe({
  name: 'localizeSelect'
})
export class LocalizeSelectPipe implements PipeTransform {
  transform(value: any): any {
    return {
      loading: localize('loading...'),
      notFound: localize('Nothing found'),
      searchPlaceholder: localize('Search...'),
      searchShouldBeLonger: localize('Enter at least {0} characters')
    };
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-stub-content',
  templateUrl: './table-stub-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubContentComponent implements OnInit {
  @Input() grid = false;
  @Input() compact = false;
  @Input() header = true;
  @Input() columns = 5;
  @Input() rows = 4;
  @Input() rowActions = false;
  @Input() rowActionsButtons = false;
  @Input() selection = false;
  @Input() animating = false;
  @Input() scrollable = false;
  @Input() height: number;

  constructor() {}

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { CustomizeService, ElementType, getModelAttributesByColumns, rawListViewSettingsColumnsToViewContextOutputs, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { AggregateDisplayField, applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, LOADING_VALUE, LookupDisplayField, NOT_SET_VALUE, OptionsType } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { ascComparator } from '@shared';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
export var ITEM_OUTPUT = 'item';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) { return [DisplayFieldType.Base, DisplayFieldType.Lookup, DisplayFieldType.Aggregate].includes(item.type); })
        .map(function (item) {
        return __assign({ name: item.name }, (item.field == FieldType.RelatedModel && item.params
            ? {
                custom_primary_key: item.params['custom_primary_key'],
                custom_display_field: item.params['custom_display_field'],
                custom_display_field_input: item.params['custom_display_field_input']
            }
            : {}), ([FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(item.field) &&
            item.params &&
            item.params['options_type'] == OptionsType.Query
            ? {
                value_field: item.params['value_field'],
                label_field: item.params['label_field'],
                label_field_input: item.params['label_field_input']
            }
            : {}), (item instanceof LookupDisplayField
            ? {
                path: item.path
            }
            : {}), (item instanceof AggregateDisplayField
            ? {
                path: item.path,
                func: item.func,
                column: item.column
            }
            : {}));
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
function getElementStateFetch(state) {
    return {
        dataSource: state.dataSource
            ? __assign({}, state.dataSource.serialize(), { columns: serializeDataSourceColumns(state.dataSource.columns) }) : undefined,
        staticData: state.staticData,
        params: state.params,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet
    };
}
function getElementStateColumns(state) {
    return {
        columns: state.dataSource ? state.dataSource.columns : undefined
    };
}
function getElementStateName(state) {
    return {
        name: state.element ? state.element.name : undefined
    };
}
var ModelElementComponent = /** @class */ (function (_super) {
    __extends(ModelElementComponent, _super);
    function ModelElementComponent(customizeService, modelService, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionService, modelDescriptionDataSourceService, dataSourceGeneratorService, notificationService, cd, viewContextElement, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionService = actionService;
        _this.modelDescriptionDataSourceService = modelDescriptionDataSourceService;
        _this.dataSourceGeneratorService = dataSourceGeneratorService;
        _this.notificationService = notificationService;
        _this.cd = cd;
        _this.viewContextElement = viewContextElement;
        _this.popup = popup;
        _this.firstVisible$ = new BehaviorSubject(false);
        _this.elementState = {};
        _this.loading = false;
        _this.visibleColumns = [];
        _this.stubRangeDefault = range(3);
        _this.stubRange = _this.stubRangeDefault;
        _this.stubs = [
            {
                label: 'label',
                value: 'long value'
            },
            {
                label: 'long label',
                value: 'value'
            },
            {
                label: 'very long label',
                value: 'value'
            },
            {
                label: 'label',
                value: 'very long value'
            }
        ];
        return _this;
    }
    ModelElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.trackModelUpdates();
        this.elementOnChange(this.element);
        this.trackChanges();
        this.initTitle();
    };
    ModelElementComponent.prototype.ngOnDestroy = function () { };
    ModelElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.initContext();
            this.elementOnChange(this.element);
            this.initTitle();
        }
    };
    ModelElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.firstVisible$
            .pipe(first(function (value) { return value; }), switchMap(function () { return _this.element$; }), switchMap(function (element) {
            return _this.dataSourceGeneratorService
                .applyDataSourceDefaults(ModelDescriptionDataSource, element.dataSource)
                .pipe(map(function (dataSource) {
                element.dataSource = dataSource;
                return element;
            }));
        }), switchMap(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.elementState = state;
        });
    };
    ModelElementComponent.prototype.getElementState = function (element) {
        var staticData$ = element.dataSource && element.dataSource.type == DataSourceType.Input && element.dataSource.input
            ? applyParamInput$(element.dataSource.input, {
                context: this.context,
                defaultValue: {},
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        var dataSourceParams$ = element.dataSource
            ? applyParamInputs$({}, element.dataSource.queryInputs, {
                context: this.context,
                parameters: element.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(element.dataSource)).pipe(map(function (_a) {
            var staticData = _a[0], dataSourceParams = _a[1], modelDescription = _a[2];
            return {
                element: element,
                dataSource: element.dataSource,
                staticData: staticData,
                params: dataSourceParams,
                modelDescription: modelDescription,
                inputsLoading: [dataSourceParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [dataSourceParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                })
            };
        }));
    };
    ModelElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.elementState))) {
            this.updateStubs(state);
            this.updateVisibleColumns(state);
            this.updateContextOutputs(state);
        }
        if (!isEqual(getElementStateName(state), getElementStateName(this.elementState))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
            this.fetch(state);
        }
    };
    ModelElementComponent.prototype.fetch = function (state) {
        var _this = this;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = undefined;
        }
        if (!state.dataSource) {
            this.model = undefined;
            this.updateContextValue();
            this.cd.markForCheck();
            return;
        }
        if (state.inputsNotSet) {
            this.model = undefined;
            this.loading = false;
            this.cd.markForCheck();
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: true });
        if (state.inputsLoading) {
            return;
        }
        var queryOptions = paramsToGetQueryOptions(state.params);
        queryOptions.columns = state.dataSource ? state.dataSource.columns : undefined;
        this.loadingSubscription = this.modelDescriptionDataSourceService
            .getDetailAdv({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            dataSource: state.dataSource,
            queryOptions: queryOptions,
            staticData: state.staticData,
            context: this.context
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (model) {
            _this.model = model;
            _this.updateContextValue();
            _this.loading = false;
            _this.cd.markForCheck();
            _this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        }, function () {
            _this.model = undefined;
            _this.updateContextValue();
            _this.loading = false;
            _this.cd.markForCheck();
            _this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        });
    };
    ModelElementComponent.prototype.initTitle = function () {
        var _this = this;
        if (this.titleSubscription) {
            this.titleSubscription.unsubscribe();
            this.titleSubscription = undefined;
        }
        if (!this.element.titleInput) {
            this.title = undefined;
            this.cd.markForCheck();
            return;
        }
        this.titleSubscription = applyParamInput$(this.element.titleInput, {
            context: this.context,
            contextElement: this.viewContextElement,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.title = value;
            _this.cd.markForCheck();
        });
    };
    ModelElementComponent.prototype.getQueryModelDescription = function (dataSource) {
        if (!dataSource ||
            !dataSource.query ||
            dataSource.query.queryType != QueryType.Simple ||
            !dataSource.query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    ModelElementComponent.prototype.reloadData = function () {
        this.fetch(this.elementState);
    };
    ModelElementComponent.prototype.updateStubs = function (state) {
        this.stubRange =
            state.dataSource && state.dataSource.columns.length
                ? range(state.dataSource.columns.filter(function (item) { return item.visible; }).length)
                : this.stubRangeDefault;
        this.cd.markForCheck();
    };
    ModelElementComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource ? state.dataSource.columns.filter(function (item) { return item.visible; }) : [];
        this.cd.markForCheck();
    };
    ModelElementComponent.prototype.initContext = function () {
        var _this = this;
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'pages',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        this.viewContextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            }
        ]);
    };
    ModelElementComponent.prototype.updateContextInfo = function (state) {
        this.viewContextElement.initInfo({
            name: state.element.name,
            getFieldValue: function (field, outputs) {
                return outputs[ITEM_OUTPUT] ? outputs[ITEM_OUTPUT][field] : undefined;
            }
        }, true);
    };
    ModelElementComponent.prototype.updateContextOutputs = function (state) {
        var children = state.dataSource
            ? rawListViewSettingsColumnsToViewContextOutputs(state.dataSource.columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            : [];
        this.viewContextElement.setOutputs([
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Record',
                icon: 'duplicate_2',
                allowSkip: true,
                external: true,
                children: children
            }
        ]);
    };
    ModelElementComponent.prototype.updateContextValue = function () {
        var output = this.model && this.elementState.dataSource
            ? getModelAttributesByColumns(this.model, this.elementState.dataSource.columns)
            : undefined;
        this.viewContextElement.setOutputValue(ITEM_OUTPUT, output);
        // Backward compatibility
        this.viewContextElement.setOutputValue('model', output);
    };
    ModelElementComponent.prototype.trackModelUpdates = function () {
        var _this = this;
        this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (isModelUpdateEventMatch(e, _this.elementState.modelDescription, _this.model)) {
                _this.model = patchModel(_this.model, e.model);
                _this.cd.markForCheck();
                _this.updateContextValue();
            }
        });
    };
    return ModelElementComponent;
}(BaseElementComponent));
export { ModelElementComponent };
registerElementComponent({
    type: ElementType.Model,
    component: ModelElementComponent,
    alwaysActive: false,
    label: 'Detail',
    actions: []
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { getJetAppSendEmailActionDescription, JET_APP_SEND_EMAIL } from '@modules/actions';
import { getJetAppGroupModelDescription, getJetAppUserModelDescription, JET_APP_GROUPS, JET_APP_USERS, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, EmailService, ProjectGroup, ProjectGroupService, ProjectPropertyStore, ProjectPropertyType, ProjectUser, ProjectUserService } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { Storage, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';
// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';
import { ResourceController } from '../../data/resource-controller';
import { applyFrontendFiltering, applyFrontendPagination } from '../../utils/filters';
import { ProjectStorageService } from '../project-storage/project-storage.service';
var JetAppResourceController = /** @class */ (function (_super) {
    __extends(JetAppResourceController, _super);
    function JetAppResourceController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filtersExcludable = true;
        _this.filtersLookups = true;
        _this.relationFilter = true;
        return _this;
    }
    JetAppResourceController.prototype.init = function () {
        this.currentProjectStore = this.initService(CurrentProjectStore);
        this.currentEnvironmentStore = this.initService(CurrentEnvironmentStore);
        this.projectUserService = this.initService(ProjectUserService);
        this.projectGroupService = this.initService(ProjectGroupService);
        this.projectPropertyStore = this.initService(ProjectPropertyStore);
        this.emailService = this.initService(EmailService);
        this.projectStorageService = this.initService(ProjectStorageService);
    };
    JetAppResourceController.prototype.supportedQueryTypes = function (queryClass) {
        return [QueryType.Simple];
    };
    JetAppResourceController.prototype.modelDescriptionGet = function (resource, draft) {
        if (draft === void 0) { draft = false; }
        return this.projectPropertyStore.getFirst().pipe(map(function (properties) {
            var userProperties = properties.filter(function (item) { return item.type == ProjectPropertyType.User; });
            var users = getJetAppUserModelDescription(userProperties);
            var groupProperties = properties.filter(function (item) { return item.type == ProjectPropertyType.Group; });
            var groups = getJetAppGroupModelDescription(groupProperties);
            return [users, groups];
        }));
    };
    JetAppResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        params = params || {};
        if (modelDescription.model == JET_APP_USERS) {
            return this.usersGet(resource, modelDescription, params);
        }
        else if (modelDescription.model == JET_APP_GROUPS) {
            return this.groupsGet(resource, modelDescription, params);
        }
    };
    JetAppResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        var _a;
        if (isSet(id)) {
            params = __assign({}, params, (_a = {}, _a[idField] = id, _a));
        }
        return this.modelGet(resource, modelDescription, params).pipe(map(function (result) { return result.results[0]; }));
    };
    JetAppResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        if (modelDescription.model == JET_APP_USERS) {
            return this.usersCreate(resource, modelDescription, modelInstance);
        }
    };
    JetAppResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        if (modelDescription.model == JET_APP_USERS) {
            return this.usersUpdate(resource, modelDescription, modelInstance);
        }
        else if (modelDescription.model == JET_APP_GROUPS) {
            return this.groupsUpdate(resource, modelDescription, modelInstance);
        }
    };
    JetAppResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        if (modelDescription.model == JET_APP_USERS) {
            return this.usersDelete(resource, modelDescription, modelInstance);
        }
    };
    JetAppResourceController.prototype.serializeUser = function (item) {
        return __assign({ uid: item.uid, email: item.getEmail(), first_name: item.getFirstName(), last_name: item.getLastName(), group: item.group ? item.group.uid : undefined, date_add: item.dateAdd ? item.dateAdd.toISOString() : undefined }, item.properties);
    };
    JetAppResourceController.prototype.usersGet = function (resource, modelDescription, params) {
        var _this = this;
        return this.projectUserService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(map(function (models) {
            var result = models.map(function (item) {
                return _this.serializeUser(item);
            });
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            result = applyFrontendFiltering(result, params, columns);
            var data = {
                results: result,
                count: result.length
            };
            var response = _this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);
            applyFrontendPagination(response, params, true);
            return response;
        }), map(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
            });
            return response;
        }));
    };
    JetAppResourceController.prototype.getUserInstance = function (modelInstance) {
        var instanceFields = ['uid', 'email', 'first_name', 'last_name', 'group'];
        var instance = new ProjectUser();
        var group = new ProjectGroup();
        group.uid = modelInstance.getAttribute('group');
        instance.uid = modelInstance.getAttribute('uid');
        instance.userEmail = modelInstance.getAttribute('email');
        instance.group = group;
        instance.properties = fromPairs(toPairs(modelInstance.getAttributes()).filter(function (_a) {
            var name = _a[0], value = _a[1];
            return !instanceFields.includes(name);
        }));
        return instance;
    };
    JetAppResourceController.prototype.usersCreate = function (resource, modelDescription, modelInstance) {
        var _this = this;
        var instance = this.getUserInstance(modelInstance);
        var group = new ProjectGroup();
        group.uid = modelInstance.getAttribute('group');
        return this.projectUserService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(map(function (result) {
            var data = _this.serializeUser(result);
            var model = _this.createModel().deserialize(modelDescription.model, data);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }));
    };
    JetAppResourceController.prototype.usersUpdate = function (resource, modelDescription, modelInstance) {
        var _this = this;
        return this.projectUserService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, modelInstance.getAttribute('uid'))
            .pipe(switchMap(function (instance) {
            var newInstance = _this.getUserInstance(modelInstance);
            instance.uid = newInstance.uid;
            instance.userEmail = newInstance.userEmail;
            instance.group = newInstance.group;
            instance.properties = __assign({}, instance.properties, newInstance.properties);
            return _this.projectUserService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance);
        }), tap(function (result) {
            if (result.uid == _this.currentEnvironmentStore.instance.user.uid) {
                _this.currentEnvironmentStore.updateUser(result);
            }
        }), map(function (result) {
            var data = _this.serializeUser(result);
            var model = _this.createModel().deserialize(modelDescription.model, data);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }));
    };
    JetAppResourceController.prototype.usersDelete = function (resource, modelDescription, modelInstance) {
        var user = new ProjectUser();
        user.uid = modelInstance.getAttribute('uid');
        return this.projectUserService
            .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, user)
            .pipe(map(function () { return true; }));
    };
    JetAppResourceController.prototype.serializeGroup = function (item) {
        return __assign({ uid: item.uid, name: item.name, description: item.description, permissions_group: item.permissionsGroup }, item.properties);
    };
    JetAppResourceController.prototype.getGroupInstance = function (modelInstance) {
        var instanceFields = ['uid', 'name'];
        var instance = new ProjectGroup();
        instance.uid = modelInstance.getAttribute('uid');
        instance.name = modelInstance.getAttribute('name');
        instance.properties = fromPairs(toPairs(modelInstance.getAttributes()).filter(function (_a) {
            var name = _a[0], value = _a[1];
            return !instanceFields.includes(name);
        }));
        return instance;
    };
    JetAppResourceController.prototype.groupsGet = function (resource, modelDescription, params) {
        var _this = this;
        return this.projectGroupService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(map(function (models) {
            var result = models.map(function (item) { return _this.serializeGroup(item); });
            var columns = modelDescription.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToRawListViewSettingsColumn(item); });
            result = applyFrontendFiltering(result, params, columns);
            var data = {
                results: result,
                count: result.length
            };
            var response = _this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);
            applyFrontendPagination(response, params, true);
            return response;
        }), map(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
            });
            return response;
        }));
    };
    JetAppResourceController.prototype.groupsUpdate = function (resource, modelDescription, modelInstance) {
        var _this = this;
        return this.projectGroupService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, modelInstance.getAttribute('uid'))
            .pipe(switchMap(function (instance) {
            var newInstance = _this.getUserInstance(modelInstance);
            instance.uid = newInstance.uid;
            instance.name = newInstance.getLastName();
            instance.properties = __assign({}, instance.properties, newInstance.properties);
            return _this.projectGroupService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance);
        }), tap(function (result) {
            if (result.uid == _this.currentEnvironmentStore.instance.group.uid) {
                _this.currentEnvironmentStore.updateGroup(result);
            }
        }), map(function (result) {
            var data = _this.serializeGroup(result);
            var model = _this.createModel().deserialize(modelDescription.model, data);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }));
    };
    JetAppResourceController.prototype.actionDescriptionGet = function (resource) {
        var sendEmail = getJetAppSendEmailActionDescription();
        return of([sendEmail]);
    };
    JetAppResourceController.prototype.actionExecute = function (resource, query, parameters, params, rawErrors) {
        if (parameters === void 0) { parameters = []; }
        if (query.simpleQuery && query.simpleQuery.name == JET_APP_SEND_EMAIL) {
            return this.emailService
                .send(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, params.actionParams['to'], params.actionParams['subject'], params.actionParams['text'], params.actionParams['text_html'])
                .pipe(map(function (result) {
                return {
                    json: result
                };
            }));
        }
    };
    JetAppResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        return this.projectStorageService.uploadFile(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, file, path, fileName);
    };
    JetAppResourceController.prototype.getStorageObjects = function (resource, storage, query, path) {
        return this.projectStorageService.getObjects(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, path);
    };
    JetAppResourceController.prototype.deleteStorageObject = function (resource, storage, query, path) {
        return this.projectStorageService.deleteObject(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, path);
    };
    JetAppResourceController.prototype.createStorageFolder = function (resource, storage, query, path) {
        return this.projectStorageService.createFolder(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, path);
    };
    return JetAppResourceController;
}(ResourceController));
export { JetAppResourceController };

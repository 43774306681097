import { GuideTask } from './guide-task';
var GuideSection = /** @class */ (function () {
    function GuideSection() {
    }
    GuideSection.prototype.deserialize = function (data) {
        this.title = data['title'];
        this.subtitle = data['subtitle'];
        this.image = data['image'];
        this.ordering = data['ordering'];
        if (data['tasks']) {
            this.tasks = data['tasks'].map(function (item) { return new GuideTask().deserialize(item); });
        }
        return this;
    };
    Object.defineProperty(GuideSection.prototype, "progress", {
        get: function () {
            if (!this.tasks.length) {
                return 0;
            }
            return this.tasks.filter(function (item) { return item.completed; }).length / this.tasks.length;
        },
        enumerable: true,
        configurable: true
    });
    return GuideSection;
}());
export { GuideSection };

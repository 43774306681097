<div class="element__border" [class.element__border_error]="!error" [class.element__border_always]="!error"></div>

<ng-container *ngIf="resizeEnabled.width || resizeEnabled.height">
  <!-- Hidden bounds -->

  <span
    class="element__bound element__bound_position_left"
    *ngIf="widthLeftResizable"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"
    [appResizableHandleReverseHorizontal]="true"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
  ></span>
  <div
    *ngIf="widthRightResizable"
    class="element__bound element__bound_position_right"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
  ></div>

  <div
    *ngIf="heightTopResizable"
    class="element__bound element__bound_position_top"
    [appResizableHandle]="[resizeTypes.Vertical]"
    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"
    [appResizableHandleReverseVertical]="true"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
  ></div>

  <div
    *ngIf="heightBottomResizable"
    class="element__bound element__bound_position_bottom"
    [appResizableHandle]="[resizeTypes.Vertical]"
    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
  ></div>

  <!-- Handle right -->

  <div
    *ngIf="widthRightResizable"
    class="element__handle element__handle_position_right"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"
    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"
    [appResizableHandleReverseHorizontal]="false"
    [appResizableHandleReverseVertical]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
  ></div>

  <!-- Handle bottom right -->

  <!--  <div-->
  <!--    *ngIf="widthRightResizable && heightBottomResizable"-->
  <!--    class="element__handle element__handle_position_bottom-right"-->
  <!--    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="false"-->
  <!--    [appResizableHandleReverseVertical]="false"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleMinSize]="resizeMinSize"-->
  <!--    [appResizableHandleMaxSize]="resizeMaxSize"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--    <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
  <!--      <path d="M8.917 4A6.017 6.017 0 014 8.917" stroke="currentColor" stroke-width="6" stroke-linecap="round"></path>-->
  <!--    </svg>-->
  <!--  </div>-->

  <!-- Handle bottom -->

  <div
    *ngIf="heightBottomResizable"
    class="element__handle element__handle_position_bottom"
    [appResizableHandle]="[resizeTypes.Vertical]"
    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"
    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"
    [appResizableHandleReverseHorizontal]="false"
    [appResizableHandleReverseVertical]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
  ></div>

  <!-- Handle bottom left -->

  <!--  <div-->
  <!--    *ngIf="widthRightResizable && heightBottomResizable"-->
  <!--    class="element__handle element__handle_position_bottom-left"-->
  <!--    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="true"-->
  <!--    [appResizableHandleReverseVertical]="false"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleMinSize]="resizeMinSize"-->
  <!--    [appResizableHandleMaxSize]="resizeMaxSize"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--  </div>-->

  <!-- Handle left -->

  <div
    *ngIf="widthLeftResizable"
    class="element__handle element__handle_position_left"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"
    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"
    [appResizableHandleReverseHorizontal]="true"
    [appResizableHandleReverseVertical]="false"
    [appResizableHandleStepHorizontal]="resizeStep.width"
    [appResizableHandleStepVertical]="resizeStep.height"
    [appResizableHandleMinSize]="resizeMinSize"
    [appResizableHandleMaxSize]="resizeMaxSize"
    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"
    [appResizableHandleCheckVerticalBounds]="false"
  ></div>

  <!-- Square top left -->

  <!--  <ng-template #top_left_else>-->
  <!--    <div class="element__square element__square_position_top-left">-->
  <!--      <div class="element__square-inner"></div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

  <!--  <div-->
  <!--    *ngIf="resizeTypesTopLeft as resizeTypes; else top_left_else"-->
  <!--    class="element__square element__square_active element__square_position_top-left"-->
  <!--    [class.element__square_type_horizontal]="widthLeftResizable && !heightTopResizable"-->
  <!--    [class.element__square_type_vertical]="!widthLeftResizable && heightTopResizable"-->
  <!--    [class.element__square_type_both]="widthLeftResizable && heightTopResizable"-->
  <!--    [appResizableHandle]="resizeTypes"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="true"-->
  <!--    [appResizableHandleReverseVertical]="true"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--    <div class="element__square-inner"></div>-->
  <!--  </div>-->

  <!-- Square top right -->

  <!--  <ng-template #top_right_else>-->
  <!--    <div class="element__square element__square_position_top-right">-->
  <!--      <div class="element__square-inner"></div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

  <!--  <div-->
  <!--    *ngIf="resizeTypesTopRight as resizeTypes; else top_right_else"-->
  <!--    class="element__square element__square_active element__square_position_top-right"-->
  <!--    [class.element__square_type_horizontal]="widthRightResizable && !heightTopResizable"-->
  <!--    [class.element__square_type_vertical]="!widthRightResizable && heightTopResizable"-->
  <!--    [class.element__square_type_both-reverse]="widthRightResizable && heightTopResizable"-->
  <!--    [appResizableHandle]="resizeTypes"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="false"-->
  <!--    [appResizableHandleReverseVertical]="true"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--    <div class="element__square-inner"></div>-->
  <!--  </div>-->

  <!-- Square bottom left -->

  <!--  <ng-template #bottom_left_else>-->
  <!--    <div class="element__square element__square_position_bottom-left">-->
  <!--      <div class="element__square-inner"></div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

  <!--  <div-->
  <!--    *ngIf="resizeTypesBottomLeft as resizeTypes; else bottom_left_else"-->
  <!--    class="element__square element__square_active element__square_position_bottom-left"-->
  <!--    [class.element__square_type_horizontal]="widthLeftResizable && !heightBottomResizable"-->
  <!--    [class.element__square_type_vertical]="!widthLeftResizable && heightBottomResizable"-->
  <!--    [class.element__square_type_both-reverse]="widthLeftResizable && heightBottomResizable"-->
  <!--    [appResizableHandle]="resizeTypes"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="true"-->
  <!--    [appResizableHandleReverseVertical]="false"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--    <div class="element__square-inner"></div>-->
  <!--  </div>-->

  <!-- Square bottom right -->

  <!--  <ng-template #bottom_right_else>-->
  <!--    <div class="element__square element__square_position_bottom-right">-->
  <!--      <div class="element__square-inner"></div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

  <!--  <div-->
  <!--    *ngIf="resizeTypesBottomRight as resizeTypes; else bottom_right_else"-->
  <!--    class="element__square element__square_active element__square_position_bottom-right"-->
  <!--    [class.element__square_type_horizontal]="widthRightResizable && !heightBottomResizable"-->
  <!--    [class.element__square_type_vertical]="!widthRightResizable && heightBottomResizable"-->
  <!--    [class.element__square_type_both]="widthRightResizable && heightBottomResizable"-->
  <!--    [appResizableHandle]="resizeTypes"-->
  <!--    [appResizableHandleSpeedHorizontal]="alignHorizontal == alignsHorizontal.Center ? 2 : 1"-->
  <!--    [appResizableHandleSpeedVertical]="alignVertical == alignsVertical.Center ? 2 : 1"-->
  <!--    [appResizableHandleReverseHorizontal]="false"-->
  <!--    [appResizableHandleReverseVertical]="false"-->
  <!--    [appResizableHandleStepHorizontal]="resizeStep.width"-->
  <!--    [appResizableHandleStepVertical]="resizeStep.height"-->
  <!--    [appResizableHandleCheckHorizontalBounds]="!containerHorizontal"-->
  <!--    [appResizableHandleCheckVerticalBounds]="false"-->
  <!--  >-->
  <!--    <div class="element__square-inner"></div>-->
  <!--  </div>-->
</ng-container>

import { ContainerLayer, Layer } from './layer';
import { LayerType } from './layer-type';

export interface LayerTypeItem {
  type: LayerType;
  cls: typeof Layer;
  options: LayerTypeOptions;
}

export interface LayerTypeOptions {
  container?: boolean;
}

const layersByType: LayerTypeItem[] = [];

export function getLayerByType(type: LayerType): LayerTypeItem {
  return layersByType.find(item => item.type == type);
}

export function registerLayerType(type: LayerType, cls: typeof Layer, options: LayerTypeOptions = {}) {
  layersByType.push({ type: type, cls: cls, options: options });
}

export function deserializerLayer(data: Object) {
  const item = getLayerByType(data['type']);
  if (!item) {
    console.error(`Unsupported layer type: ${data['type']}`);
    return;
  }
  return new item.cls().deserialize(data);
}

export function isContainerLayer(layer: Layer): layer is ContainerLayer {
  const item = getLayerByType(layer.type);
  return item ? !!item.options.container : false;
}

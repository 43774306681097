/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "../action-type-dropdown/action-type-dropdown.component.ngfactory";
import * as i4 from "../action-type-dropdown/action-type-dropdown.component";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "./action-type-field.component";
var styles_ActionTypeFieldComponent = [];
var RenderType_ActionTypeFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionTypeFieldComponent, data: {} });
export { RenderType_ActionTypeFieldComponent as RenderType_ActionTypeFieldComponent };
function View_ActionTypeFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "select__input-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select__input-icon"; var currVal_1 = ("icon-" + _co.currentValueIcon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ActionTypeFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentValueLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ActionTypeFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_ActionTypeFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-action-type-dropdown", [], null, [[null, "select"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        _co.setDropdownOpened(false);
        var pd_0 = (_co.control.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ActionTypeDropdownComponent_0, i3.RenderType_ActionTypeDropdownComponent)), i0.ɵdid(2, 4964352, null, 0, i4.ActionTypeDropdownComponent, [i0.ChangeDetectorRef], { opened: [0, "opened"], origin: [1, "origin"], value: [2, "value"], typesOnly: [3, "typesOnly"], actionOriginEnabled: [4, "actionOriginEnabled"] }, { select: "select", close: "close" }), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "select"]], [[2, "select_theme_jet", null], [2, "select_theme_jet_orange", null], [2, "select_fill", null]], null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 11, "a", [["cdkOverlayOrigin", ""], ["class", "select__input"], ["href", "javascript:void(0)"]], [[2, "select__input_opened", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, [["origin", 4]], 0, i5.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionTypeFieldComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 6, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActionTypeFieldComponent_2)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(12, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActionTypeFieldComponent_3)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(15, 1), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "select__input-arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropdownOpened; var currVal_1 = i0.ɵnov(_v, 6); var currVal_2 = _co.control.value; var currVal_3 = _co.typesOnly; var currVal_4 = _co.actionOriginEnabled; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_8 = "select"; var currVal_9 = _co.classes; _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_11 = _co.currentValueIcon; _ck(_v, 8, 0, currVal_11); var currVal_12 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), _co.currentValueLabel)); _ck(_v, 11, 0, currVal_12); var currVal_13 = !i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 0), _co.currentValueLabel)); _ck(_v, 14, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = !_co.orange; var currVal_6 = _co.orange; var currVal_7 = _co.fill; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.dropdownOpened; _ck(_v, 5, 0, currVal_10); }); }
export function View_ActionTypeFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-type-field", [], null, null, null, View_ActionTypeFieldComponent_0, RenderType_ActionTypeFieldComponent)), i0.ɵdid(1, 770048, null, 0, i6.ActionTypeFieldComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionTypeFieldComponentNgFactory = i0.ɵccf("app-action-type-field", i6.ActionTypeFieldComponent, View_ActionTypeFieldComponent_Host_0, { control: "control", typesOnly: "typesOnly", actionOriginEnabled: "actionOriginEnabled", orange: "orange", fill: "fill", placeholder: "placeholder", classes: "classes" }, {}, []);
export { ActionTypeFieldComponentNgFactory as ActionTypeFieldComponentNgFactory };

import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { User } from '@modules/users';

export class ApiKey {
  public token: string;
  public name: string;
  public revoked = false;
  public userRevoked: User;
  public dateAdd: moment.Moment;
  public dateRevoked: moment.Moment;

  deserialize(data: Object): ApiKey {
    this.name = data['name'];
    this.token = data['token'];
    this.revoked = data['revoked'];

    if (data['user_revoked']) {
      this.userRevoked = new User().deserialize(data['user_revoked']);
    }

    if (data['date_add']) {
      this.dateAdd = moment(data['date_add']);
    }

    if (data['date_revoked']) {
      this.dateRevoked = moment(data['date_revoked']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

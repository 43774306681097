import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { WorkflowStepType } from '../workflow-step-type';
import { registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class TransformWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Transform;
  public value: Input;

  deserialize(data: Object) {
    super.deserialize(data);

    if (data['value']) {
      this.value = new Input().deserialize(data['value']);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      value: this.value ? this.value.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Transform';
  }

  get analyticsName(): string {
    return 'transform';
  }

  getIcon() {
    return 'reflect';
  }
}

registerWorkflowStepType(WorkflowStepType.Transform, TransformWorkflowStep);

import { ChangeDetectorRef, ComponentRef, DoCheck, Injector, OnDestroy, Type, ViewContainerRef } from '@angular/core';
import { DynamicComponentService } from '../../services/dynamic-component/dynamic-component.service';
var DynamicComponent = /** @class */ (function () {
    function DynamicComponent(injector, dynamicComponentService, vc) {
        this.injector = injector;
        this.dynamicComponentService = dynamicComponentService;
        this.vc = vc;
    }
    Object.defineProperty(DynamicComponent.prototype, "componentData", {
        set: function (data) {
            var componentRef = this.dynamicComponentService.createComponent(data.injector || this.injector, data);
            this.vc.insert(componentRef.hostView);
            if (this.currentComponent) {
                this.currentComponent.destroy();
            }
            this.currentComponent = componentRef;
            // this.currentComponent.changeDetectorRef.detectChanges();
            if (this.currentComponent.instance['OnInit']) {
                this.currentComponent.instance['OnInit']();
            }
        },
        enumerable: true,
        configurable: true
    });
    DynamicComponent.prototype.ngDoCheck = function () {
        if (this.currentComponent) {
            try {
                var cd = this.currentComponent.injector.get(ChangeDetectorRef);
                cd.markForCheck();
            }
            catch (e) { }
        }
    };
    DynamicComponent.prototype.ngOnDestroy = function () {
        if (this.currentComponent) {
            this.currentComponent.destroy();
        }
    };
    return DynamicComponent;
}());
export { DynamicComponent };

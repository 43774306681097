import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomView } from '../../data/custom-view';

@Injectable({
  providedIn: 'root'
})
export class CustomViewLoaderService {
  load(view: CustomView): Observable<boolean> {
    if (this.isLoaded(view.tagName)) {
      return of(true);
    }

    const obs = [];

    if (view.filesJs) {
      view.filesJs.forEach(item => {
        obs.push(this.loadScript(`${view.distBaseAbsoluteUrl}${item}`));
      });
    }

    if (view.filesCss) {
      view.filesCss.forEach(item => {
        obs.push(this.loadStyle(`${view.distBaseAbsoluteUrl}${item}`));
      });
    }

    if (!obs.length) {
      return of(true);
    }

    return combineLatest(obs).pipe(map(result => result.every(item => item)));
  }

  isLoaded(name: string): boolean {
    return !!customElements.get(name);
  }

  loadScript(url: string): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const node = document.createElement('script');

    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = () => result.next(true);
    node.onerror = () => result.next(false);
    document.getElementsByTagName('body')[0].appendChild(node);

    return result.asObservable();
  }

  loadStyle(url: string): Observable<boolean> {
    const result = new ReplaySubject<boolean>(1);
    const node = document.createElement('link');

    node.href = url;
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.onload = () => result.next(true);
    node.onerror = () => result.next(false);
    document.getElementsByTagName('body')[0].appendChild(node);

    return result.asObservable();
  }
}

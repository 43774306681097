<div *ngIf="loading" class="chips__tag">
  <div class="chips__tag-label">
    <span [class.loading-animation]="true"><span class="stub-text">item</span></span>
  </div>
</div>

<div *ngIf="!loading" class="chips__tag">
  <div class="chips__tag-label">
    <ng-container *ngIf="item">
      {{ (item.name | appIsSet) ? item.name : item.value }}
    </ng-container>

    <ng-container *ngIf="!item">
      {{ value }}
    </ng-container>
  </div>

  <div class="chips__tag-button icon-close" (click)="remove.emit($event)"></div>
</div>

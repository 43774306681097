import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { MarkupType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';
var InputEditTextInputsValueComponent = /** @class */ (function () {
    function InputEditTextInputsValueComponent(themeService, cd) {
        this.themeService = themeService;
        this.cd = cd;
        this.staticValueDisabled = false;
        this.displayValueTypesEnabled = true;
        this.fill = false;
        this.fillVertical = false;
        this.header = false;
        this.darker = false;
        this.borderRadius = true;
        this.switchToDefault = new EventEmitter();
        this.switchToFormula = new EventEmitter();
        this.opened = false;
        this.typeOptions = [
            {
                value: MarkupType.Text,
                name: 'Plain Text'
            },
            {
                value: MarkupType.HTML,
                name: 'HTML'
            },
            {
                value: MarkupType.Markdown,
                name: 'Markdown'
            }
        ];
        this.richTextTypes = [MarkupType.HTML, MarkupType.Markdown];
    }
    InputEditTextInputsValueComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(InputEditTextInputsValueComponent.prototype, "markupOption", {
        get: function () {
            var _this = this;
            return isSet(this.typeControl.value)
                ? this.typeOptions.find(function (item) { return item.value == _this.typeControl.value; })
                : undefined;
        },
        enumerable: true,
        configurable: true
    });
    InputEditTextInputsValueComponent.prototype.setOpened = function (value) {
        this.opened = value;
        this.cd.markForCheck();
    };
    InputEditTextInputsValueComponent.prototype.save = function (value) {
        this.valueControl.patchValue(value);
    };
    return InputEditTextInputsValueComponent;
}());
export { InputEditTextInputsValueComponent };

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CurrentProjectStore, Project } from '@modules/projects';

import { ProjectUniqueNameComponent } from '../../components/project-unique-name/project-unique-name.component';

@Injectable()
export class ProjectUniqueNameController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  edit(): Observable<Project> {
    const obs$ = new ReplaySubject<Project>();

    this.popupService.push({
      component: ProjectUniqueNameComponent,
      popupComponent: ThinDialogPopupComponent,
      outputs: {
        updated: [
          result => {
            obs$.next(result);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$.asObservable();
  }
}

<app-compact-wide-button
  [label]="'Click action'"
  [interactive]="true"
  [leftIcon]="'hand'"
  [leftBackground]="true"
  [rightIcon]="'close'"
  [rightInteractive]="true"
  [rightTooltip]="'Remove action'"
  (click)="customizeAction()"
  (rightClick)="arrayControl.removeControl(control)"
></app-compact-wide-button>

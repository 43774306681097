import { GuideTaskProgress } from './guide-task-progress';
var GuideTask = /** @class */ (function () {
    function GuideTask() {
    }
    GuideTask.prototype.deserialize = function (data) {
        this.uniqueName = data['unique_name'];
        this.title = data['title'];
        this.description = data['description'];
        this.duration = data['duration'];
        this.documentation = data['documentation'];
        this.video = data['video'];
        this.autoComplete = data['auto_complete'];
        this.ordering = data['ordering'];
        if (data['progress']) {
            this.progress = new GuideTaskProgress().deserialize(data['progress']);
        }
        return this;
    };
    Object.defineProperty(GuideTask.prototype, "completed", {
        get: function () {
            return this.autoComplete || this.progress != undefined;
        },
        enumerable: true,
        configurable: true
    });
    return GuideTask;
}());
export { GuideTask };

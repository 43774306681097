import { Injectable, OnDestroy } from '@angular/core';
import clone from 'lodash/clone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, from, merge, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { isSet } from '@shared';

import { ProjectProperty, ProjectPropertyType } from '../../data/project-property';
import { ProjectPropertyStore } from '../../stores/project-property.store';
import { ProjectPropertyValueDB, ProjectPropertyValueTableCriteria } from './project-property-value.db';

export enum ProjectPropertyStorageEventType {
  Update = 'update',
  Loaded = 'loaded'
}

export interface ProjectPropertyStorageUpdateEvent {
  type: ProjectPropertyStorageEventType.Update;
  uid: string;
  value: any;
  state: Object;
}

export interface ProjectPropertyStorageLoadedEvent {
  type: ProjectPropertyStorageEventType.Loaded;
  state: Object;
}

export type ProjectPropertyStorageEvent = ProjectPropertyStorageUpdateEvent | ProjectPropertyStorageLoadedEvent;

@Injectable()
export class ProjectPropertyStorage implements OnDestroy {
  events = new Subject<ProjectPropertyStorageEvent>();
  db: ProjectPropertyValueDB;

  private state = {};
  private loaded = false;

  constructor(
    private projectName: string,
    private environmentName: string,
    private projectPropertyStore: ProjectPropertyStore,
    private type: ProjectPropertyType,
    private options: { pageUid?: string } = {}
  ) {
    this.db = new ProjectPropertyValueDB('state');

    this.getDefaultState()
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this.state = state;
        this.loaded = true;

        this.events.next({
          type: ProjectPropertyStorageEventType.Loaded,
          state: clone(this.state)
        });
      });
  }

  ngOnDestroy(): void {}

  getPropertyCriteria(property: ProjectProperty): ProjectPropertyValueTableCriteria {
    return this.db.getPropertyCriteria(this.projectName, this.environmentName, property, {
      pageUid: this.options.pageUid
    });
  }

  getDefaultState(): Observable<Object> {
    return this.projectPropertyStore.getFirst().pipe(
      switchMap(properties => {
        const propertyValues = properties
          .filter(item => {
            if (item.type != this.type) {
              return false;
            }

            if (this.type == ProjectPropertyType.Page) {
              return isSet(item.pageUid) && item.pageUid == this.options.pageUid;
            } else {
              return true;
            }
          })
          .map(property => {
            if (!property.saveLocal && !isSet(property.defaultValue)) {
              return;
            }

            let savedValue$: Observable<any> = of(undefined);

            if (property.saveLocal) {
              const criteria = this.getPropertyCriteria(property);
              if (criteria) {
                savedValue$ = from(this.db.getPropertyValue(property.type, criteria));
              }
            }

            return savedValue$.pipe(
              map(value => {
                if (value === undefined && isSet(property.defaultValue)) {
                  return {
                    property: property,
                    value: property.defaultValue
                  };
                }

                return {
                  property: property,
                  value: value
                };
              })
            );
          })
          .filter(item => item);

        if (!propertyValues.length) {
          return of([]);
        }

        return combineLatest(propertyValues);
      }),
      map(result => {
        return result.reduce((acc, item) => {
          if (item.value !== undefined) {
            acc[item.property.uid] = item.value;
          }

          return acc;
        }, {});
      })
    );
  }

  runForLoaded(handler: (...arg: any) => any) {
    if (!this.loaded) {
      this.events
        .pipe(
          filter(item => item.type == ProjectPropertyStorageEventType.Loaded),
          first()
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => handler());
    } else {
      handler();
    }
  }

  setValue(property: ProjectProperty, value: any, persist = true) {
    this.runForLoaded(() => {
      this.state[property.uid] = value;
      this.events.next({
        type: ProjectPropertyStorageEventType.Update,
        uid: property.uid,
        value: value,
        state: clone(this.state)
      });

      if (persist && property.saveLocal) {
        this.persistValue(property, value);
      }
    });
  }

  persist(property: ProjectProperty) {
    this.persistValue(property, this.state[property.uid]);
  }

  persistValue(property: ProjectProperty, value: any) {
    const criteria = this.getPropertyCriteria(property);
    this.db.putPropertyValue(property.type, criteria, value).then();
  }

  makeTemporary(property: ProjectProperty) {
    const criteria = this.getPropertyCriteria(property);
    this.db.deleteProperty(property.type, criteria).then();
  }

  getValues(): Object {
    return this.state;
  }

  getValues$(): Observable<Object> {
    return merge(of(this.state), this.events.pipe(map(value => value.state)));
  }

  getValue(property: ProjectProperty): any {
    return this.state[property.uid];
  }

  getValue$(property: ProjectProperty): Observable<any> {
    return merge(of(this.state), this.events.pipe(map(value => value.state))).pipe(map(state => state[property.uid]));
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./qr-code.component";
var styles_QrCodeComponent = [];
var RenderType_QrCodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QrCodeComponent, data: {} });
export { RenderType_QrCodeComponent as RenderType_QrCodeComponent };
function View_QrCodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "qr-code__error"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_QrCodeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_QrCodeComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.error)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_QrCodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-qr-code", [], [[2, "qr-code", null], [4, "min-width", "px"], [4, "background-image", null]], null, null, View_QrCodeComponent_0, RenderType_QrCodeComponent)), i0.ɵdid(1, 770048, null, 0, i3.QrCodeComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).minWidth; var currVal_2 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var QrCodeComponentNgFactory = i0.ɵccf("app-qr-code", i3.QrCodeComponent, View_QrCodeComponent_Host_0, { value: "value", fillColor: "fillColor", backgroundColor: "backgroundColor" }, {}, []);
export { QrCodeComponentNgFactory as QrCodeComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./save-error-menu.component";
var styles_SaveErrorMenuComponent = [];
var RenderType_SaveErrorMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SaveErrorMenuComponent, data: {} });
export { RenderType_SaveErrorMenuComponent as RenderType_SaveErrorMenuComponent };
export function View_SaveErrorMenuComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "publish-menu overlay-content overlay-content_animated theme_dark"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "publish-menu-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "div", [["class", "publish-menu-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "publish-menu__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "publish-menu-card-item publish-menu-card-item_dark2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "publish-menu-card-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "publish-menu-card-item__icon publish-menu-card-item__icon_red icon-warning_filled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "publish-menu-card-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "publish-menu-card-item__title publish-menu-card-item__title_bright"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Failed to save changes "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "publish-menu-card-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "publish-menu-card-item__right publish-menu-card-item__actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "div", [["class", "publish-menu-button publish-menu-button_yellow publish-menu-card-item__action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChanges.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "publish-menu-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "span", [["class", "publish-menu-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save again"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveError; _ck(_v, 11, 0, currVal_0); }); }
export function View_SaveErrorMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-save-error-menu", [], null, null, null, View_SaveErrorMenuComponent_0, RenderType_SaveErrorMenuComponent)), i0.ɵdid(1, 114688, null, 0, i1.SaveErrorMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SaveErrorMenuComponentNgFactory = i0.ɵccf("app-save-error-menu", i1.SaveErrorMenuComponent, View_SaveErrorMenuComponent_Host_0, { saveError: "saveError" }, { saveChanges: "saveChanges" }, []);
export { SaveErrorMenuComponentNgFactory as SaveErrorMenuComponentNgFactory };

import * as i0 from "@angular/core";
var OnBeforeDestroyGuard = /** @class */ (function () {
    function OnBeforeDestroyGuard() {
    }
    OnBeforeDestroyGuard.prototype.getLastRoute = function (route) {
        if (route.firstChild) {
            return this.getLastRoute(route.firstChild);
        }
        else {
            return route;
        }
    };
    OnBeforeDestroyGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        var currentRouteLast = this.getLastRoute(currentState.root);
        var nextRouteLast = nextState ? this.getLastRoute(nextState.root) : undefined;
        var currentRouteComponent = currentRouteLast ? currentRouteLast.component : undefined;
        var nextRouteComponent = nextRouteLast ? nextRouteLast.component : undefined;
        if (nextRouteComponent && nextRouteComponent === currentRouteComponent) {
            return true;
        }
        if (component && component.onBeforeDestroy) {
            component.onBeforeDestroy();
        }
        return true;
    };
    OnBeforeDestroyGuard.ngInjectableDef = i0.defineInjectable({ factory: function OnBeforeDestroyGuard_Factory() { return new OnBeforeDestroyGuard(); }, token: OnBeforeDestroyGuard, providedIn: "root" });
    return OnBeforeDestroyGuard;
}());
export { OnBeforeDestroyGuard };

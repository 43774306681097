import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ColumnsLayoutColumnElementItem,
  ColumnsLayoutElementItem,
  CustomViewSettings,
  SeparatorElementItem,
  validateElementNames,
  ViewSettingsStore
} from '@modules/customize';
import { ModelDescription } from '@modules/models';
import { Project, Resource } from '@modules/projects';
import { Template } from '@modules/template';
import { capitalize } from '@shared';

import { PersistentIdGenerator } from '../../utils/elements';
import { BackGenerator } from '../back-generator/back-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { TextGenerator } from '../text-generator/text-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';

@Injectable()
export class CrudPagesGenerator {
  constructor(
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private createFormGenerator: CreateFormGenerator,
    private updateFormGenerator: UpdateFormGenerator,
    private detailGenerator: DetailGenerator,
    private deleteButtonGenerator: DeleteButtonGenerator,
    private backGenerator: BackGenerator,
    private listGenerator: ListGenerator,
    private filterGenerator: FilterGenerator,
    private textGenerator: TextGenerator,
    private generatorUtils: GeneratorUtils
  ) {}

  getCreatePage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    uniqueName: string,
    options: {
      templates: Template[];
      fields?: string[];
      idGenerator?: PersistentIdGenerator;
    }
  ): CustomViewSettings {
    const viewSettings = new CustomViewSettings();
    const pageName = [modelDescription.verboseName, 'Create'].join(' - ');

    viewSettings.project = project.uniqueName;
    viewSettings.uniqueName = uniqueName;
    viewSettings.name = pageName;
    viewSettings.resource = resource.uniqueName;
    viewSettings.model = modelDescription.model;
    viewSettings.configuredElements = 2;
    viewSettings.configuredActionElements = 1;
    viewSettings.newlyCreated = true;

    const columnsElement = new ColumnsLayoutElementItem();
    const leftColumn = new ColumnsLayoutColumnElementItem();
    const centerColumn = new ColumnsLayoutColumnElementItem();
    const rightColumn = new ColumnsLayoutColumnElementItem();

    columnsElement.uid = options.idGenerator ? options.idGenerator.elementId('columnsElement') : undefined;
    leftColumn.uid = options.idGenerator ? options.idGenerator.elementId('leftColumn') : undefined;
    centerColumn.uid = options.idGenerator ? options.idGenerator.elementId('centerColumn') : undefined;
    rightColumn.uid = options.idGenerator ? options.idGenerator.elementId('rightColumn') : undefined;

    const titleElement = this.textGenerator.getElement({
      h2: ['New', capitalize(modelDescription.verboseName)].join(' '),
      uid: options.idGenerator ? options.idGenerator.elementId('titleElement') : undefined
    });

    const separatorTopElement = new SeparatorElementItem();

    separatorTopElement.uid = options.idGenerator ? options.idGenerator.elementId('separatorTopElement') : undefined;
    separatorTopElement.margin = { top: 0 };

    const formElement = this.createFormGenerator.getElement(resource, modelDescription, {
      templates: options.templates,
      fields: options.fields,
      idGenerator: options.idGenerator,
      idUniqueName: 'formElement'
    });

    const separatorBottomElement = new SeparatorElementItem();

    separatorBottomElement.uid = options.idGenerator
      ? options.idGenerator.elementId('separatorBottomElement')
      : undefined;
    separatorBottomElement.margin = { bottom: 0 };

    formElement.children = [
      ...formElement.children.slice(0, formElement.children.length - 1),
      separatorBottomElement,
      formElement.children[formElement.children.length - 1]
    ];

    leftColumn.weight = 1;
    centerColumn.width = 720;
    rightColumn.weight = 1;

    centerColumn.children = [titleElement, separatorTopElement, formElement];
    columnsElement.columns = [leftColumn, centerColumn, rightColumn];

    viewSettings.elements = [columnsElement];

    validateElementNames(viewSettings.elements);

    return viewSettings;
  }

  prepareCreatePage(
    project: Project,
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      createFields?: string[];
      idGenerator?: PersistentIdGenerator;
    } = {}
  ): Observable<CustomViewSettings> {
    const baseCreateUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, 'create');

    return combineLatest(
      this.viewSettingsStore.getDistinctUniqueName(baseCreateUniqueName),
      this.generatorUtils.getDefaultComponentTemplates()
    ).pipe(
      map(([createUniqueName, templates]) => {
        return this.getCreatePage(project, resource, modelDescription, createUniqueName, {
          templates: templates,
          fields: options.createFields,
          idGenerator: options.idGenerator
        });
      })
    );
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ElementType, registerElementComponent, SeparatorElementItem, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';

@Component({
  selector: 'app-separator-element',
  templateUrl: './separator-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatorElementComponent extends BaseElementComponent {
  @Input() element: SeparatorElementItem;
}

registerElementComponent({
  type: ElementType.Separator,
  component: SeparatorElementComponent,
  label: 'Separator',
  actions: []
});

import pickBy from 'lodash/pickBy';

export enum ProjectSettingsName {
  DefaultTheme = 'default_theme',
  Layout = 'layout',
  AccentColor = 'global_accent_color',
  FontRegular = 'font_regular',
  FontHeading = 'font_heading',
  AppendScripts = 'append_scripts',
  AppendStyles = 'append_styles',
  CollaborationEnabled = 'collaboration_enabled',
  ActivityLogEnabled = 'activity_log_enabled',
  SignUpFields = 'sign_up_fields',
  Home = 'home',
  Crisp = 'crisp',
  FacebookPixel = 'facebook_pixel',
  Drift = 'drift',
  GoogleAnalytics = 'google_analytics',
  GoogleTagManager = 'google_tag_manager',
  Hubspot = 'hubspot',
  Intercom = 'intercom',
  Hotjar = 'hotjar'
}

export class ProjectSettings {
  public environment: string;
  public name: ProjectSettingsName;
  public value = {};
  public draft = false;
  public deleted = false;
  public dateAdd: string;

  constructor(options: Partial<ProjectSettings> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): ProjectSettings {
    this.environment = data['environment'];
    this.name = data['name'];
    this.value = data['value'];
    this.draft = data['draft'];
    this.deleted = data['deleted'];
    this.dateAdd = data['date_add'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name,
      value: this.value,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  getLabel(): string {
    if (this.name == ProjectSettingsName.DefaultTheme) {
      return 'Visual theme';
    } else if (this.name == ProjectSettingsName.Layout) {
      return 'Layout';
    } else if (this.name == ProjectSettingsName.AccentColor) {
      return 'Accent color';
    } else if (this.name == ProjectSettingsName.FontRegular) {
      return 'Regular font';
    } else if (this.name == ProjectSettingsName.FontHeading) {
      return 'Heading font';
    } else if (this.name == ProjectSettingsName.AppendScripts) {
      return 'Global JS';
    } else if (this.name == ProjectSettingsName.AppendStyles) {
      return 'Global CSS';
    } else if (this.name == ProjectSettingsName.CollaborationEnabled) {
      return 'Collaboration feature';
    } else if (this.name == ProjectSettingsName.ActivityLogEnabled) {
      return 'Activity log feature';
    } else if (this.name == ProjectSettingsName.SignUpFields) {
      return 'Sign Up fields';
    } else if (this.name == ProjectSettingsName.Home) {
      return 'Home page';
    } else if (this.name == ProjectSettingsName.Crisp) {
      return 'Crisp';
    } else if (this.name == ProjectSettingsName.Drift) {
      return 'Drift Chat';
    } else if (this.name == ProjectSettingsName.FacebookPixel) {
      return 'Facebook Pixel';
    } else if (this.name == ProjectSettingsName.GoogleAnalytics) {
      return 'Google Analytics';
    } else if (this.name == ProjectSettingsName.GoogleTagManager) {
      return 'Google Tag Manager';
    } else if (this.name == ProjectSettingsName.Hotjar) {
      return 'Hotjar';
    } else if (this.name == ProjectSettingsName.Hubspot) {
      return 'HubSpot';
    } else if (this.name == ProjectSettingsName.Intercom) {
      return 'Intercom';
    }
  }
}

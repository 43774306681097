import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { delayWhen, map } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueue, TaskQueueStore } from '@modules/collaboration';
import { CustomView, CustomViewsStore } from '@modules/custom-views';
import { ViewSettings, ViewSettingsStore } from '@modules/customize';
import { DraftChangeItem as EntityDraftChangeItem, PublishService } from '@modules/customize-utils';
import { MenuSettings, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  DraftItemsType,
  EnvironmentService,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectSettings,
  Resource,
  SecretToken
} from '@modules/projects';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

import { DraftChangeItem, DraftChangeItemType } from '../draft-changes-item/draft-changes-item.component';

@Component({
  selector: 'app-draft-changes',
  templateUrl: './draft-changes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraftChangesComponent implements OnInit, OnDestroy {
  loading = true;
  publishDraftLoading = false;
  deleteDraftLoading = false;
  changes: DraftChangeItem[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private customViewsStore: CustomViewsStore,
    private taskQueueStore: TaskQueueStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectSettingsStore: ProjectSettingsStore,
    private environmentService: EnvironmentService,
    private publishService: PublishService,
    private analyticsService: UniversalAnalyticsService,
    private dialogService: DialogService,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initChanges();
  }

  ngOnDestroy(): void {}

  initChanges() {
    combineLatest<
      [
        ViewSettings[],
        ModelDescription[],
        ActionDescription[],
        MenuSettings,
        CustomView[],
        Resource[],
        TaskQueue[],
        ProjectProperty[],
        ProjectSettings[]
      ]
    >(
      this.viewSettingsStore.get(false, { includeDeleted: true }),
      this.modelDescriptionStore.get(false, { includeDeleted: true }),
      this.actionStore.get(false, { includeDeleted: true }),
      this.menuSettingsStore.get(false, { includeDeleted: true }),
      this.customViewsStore.get(false, { includeDeleted: true }),
      combineLatest(this.currentProjectStore.get(false), this.currentEnvironmentStore.instance$).pipe(
        map(([project, environment]) => {
          return project.getEnvironmentResources(environment.uniqueName, { includeDeleted: true });
        })
      ),
      this.taskQueueStore.get(false, { includeDeleted: true }),
      this.projectPropertyStore.get(false, { includeDeleted: true }),
      this.projectSettingsStore.get(false, { includeDeleted: true })
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          viewSettings,
          modelDescriptions,
          actionDescriptions,
          menuSettings,
          customViews,
          resources,
          taskQueues,
          projectProperties,
          projectSettings
        ]) => {
          const secretTokens = resources.reduce<{ item: SecretToken; resource: Resource }[]>((acc, resource) => {
            acc.push(
              ...resource.secretTokensWithDeleted.map(item => ({
                item: item,
                resource: resource
              }))
            );
            return acc;
          }, []);
          const storages = resources.reduce<{ item: Storage; resource: Resource }[]>((acc, resource) => {
            acc.push(
              ...resource.storagesWithDeleted.map(item => ({
                item: item,
                resource: resource
              }))
            );
            return acc;
          }, []);
          const detectChangeType = (item: { deleted: boolean }, previousItem?: any) => {
            if (item.deleted) {
              return DraftChangeItemType.Deleted;
            } else if (previousItem) {
              return DraftChangeItemType.Updated;
            } else {
              return DraftChangeItemType.Created;
            }
          };

          this.changes = [
            {
              type: DraftItemsType.ViewSettings,
              label: 'Pages',
              items: viewSettings
                .filter(item => item.draft)
                .map(item => {
                  const resource = resources.find(i => i.uniqueName == item.resource);
                  const name = resource ? [resource.name, item.name].join(' - ') : item.name;
                  const previousItem = viewSettings.find(i => !i.draft && i.uniqueName == item.uniqueName);

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.uid
                  };
                }),
              count: viewSettings.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.MenuSettings,
              label: 'Menu settings',
              items: [],
              singleItem: true,
              count: menuSettings && menuSettings.draft ? 1 : 0
            },
            {
              type: DraftItemsType.Resources,
              label: 'Resources',
              items: resources
                .filter(item => item.draft)
                .map(item => {
                  const name = item.name;
                  const previousItem = resources.find(i => !i.draft && i.uniqueName == item.uniqueName);

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.uniqueName
                  };
                }),
              count: resources.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ModelDescriptions,
              label: 'Collections',
              items: modelDescriptions
                .filter(item => item.draft)
                .map(item => {
                  const resource = resources.find(i => i.uniqueName == item.resource);
                  const name = resource ? [resource.name, item.verboseNamePlural].join(' - ') : item.verboseNamePlural;
                  const previousItem = modelDescriptions.find(i => !i.draft && i.isSame(item));

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.modelId
                  };
                }),
              count: modelDescriptions.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ActionDescriptions,
              label: 'Actions',
              items: actionDescriptions
                .filter(item => item.draft)
                .map(item => {
                  const resource = resources.find(i => i.uniqueName == item.resource);
                  const name = resource ? [resource.name, item.verboseName].join(' - ') : item.verboseName;
                  const previousItem = actionDescriptions.find(i => !i.draft && i.isSame(item));

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.id
                  };
                }),
              count: actionDescriptions.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.SecretTokens,
              label: 'Credentials',
              items: secretTokens
                .filter(item => item.item.draft)
                .map(item => {
                  const name = [item.resource.name, item.item.name].join(' - ');
                  const previousItem = secretTokens.find(
                    i => !i.item.draft && i.item.resource == item.item.resource && i.item.name == item.item.name
                  );

                  return {
                    type: detectChangeType(item.item, previousItem),
                    name: name,
                    id: [item.resource.uniqueName, item.item.name].join('.')
                  };
                }),
              count: secretTokens.filter(item => item.item.draft).length
            },
            {
              type: DraftItemsType.Storages,
              label: 'Storages',
              items: storages
                .filter(item => item.item.draft)
                .map(item => {
                  const name = [item.resource.name, item.item.name].join(' - ');
                  const previousItem = storages.find(
                    i =>
                      !i.item.draft &&
                      i.resource.uniqueName == item.resource.uniqueName &&
                      i.item.uniqueName == item.item.uniqueName
                  );

                  return {
                    type: detectChangeType(item.item, previousItem),
                    name: name,
                    id: [item.resource.uniqueName, item.item.uniqueName].join('.')
                  };
                }),
              count: storages.filter(item => item.item.draft).length
            },
            {
              type: DraftItemsType.CustomViews,
              label: 'Custom views',
              items: customViews
                .filter(item => item.draft)
                .map(item => {
                  const page = isSet(item.pageUid) ? viewSettings.find(i => i.uid == item.pageUid) : undefined;
                  const name = item.getViewTypeStr({ page: page ? page.name : undefined, element: item.elementUid });
                  const previousItem = customViews.find(i => !i.draft && i.uniqueName == item.uniqueName);

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.uniqueName
                  };
                }),
              count: customViews.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.TaskQueues,
              label: 'Task queues',
              items: taskQueues
                .filter(item => item.draft)
                .map(item => {
                  const name = item.name;
                  const previousItem = taskQueues.find(i => !i.draft && i.uid == item.uid);

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.uid
                  };
                }),
              count: taskQueues.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ProjectProperties,
              label: 'Properties',
              items: projectProperties
                .filter(item => item.draft)
                .map(item => {
                  const name = item.name;
                  const previousItem = projectProperties.find(
                    i => !i.draft && i.type == item.type && i.uid == item.uid
                  );

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: [item.type, item.uid].join('.')
                  };
                }),
              count: projectProperties.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ProjectSettings,
              label: 'App settings',
              items: projectSettings
                .filter(item => item.draft)
                .map(item => {
                  const name = item.getLabel() || item.name;
                  const previousItem = projectSettings.find(i => !i.draft && i.name == item.name);

                  return {
                    type: detectChangeType(item, previousItem),
                    name: name,
                    id: item.name
                  };
                }),
              count: projectSettings.filter(item => item.draft).length
            }
          ].filter(item => item.count);

          this.loading = false;
          this.cd.markForCheck();

          if (!this.changes.length) {
            this.close();
          }
        }
      );
  }

  close() {
    this.popupComponent.close();
  }

  countEntity(item: DraftChangeItem, options: EnvironmentService.EntitiesOptions = {}) {
    let all = false;
    let count: number;

    if (item.singleItem) {
      all = true;
    } else {
      if (isSet(options.viewSettings)) {
        count = options.viewSettings.length;
      } else if (isSet(options.modelDescriptions)) {
        count = options.modelDescriptions.length;
      } else if (isSet(options.actionDescriptions)) {
        count = options.actionDescriptions.length;
      } else if (isSet(options.resources)) {
        count = options.resources.length;
      } else if (isSet(options.secretTokens)) {
        count = options.secretTokens.length;
      } else if (isSet(options.storages)) {
        count = options.storages.length;
      } else if (isSet(options.customViews)) {
        count = options.customViews.length;
      } else if (isSet(options.taskQueues)) {
        count = options.taskQueues.length;
      } else {
        all = true;
        count = item.count;
      }
    }

    return {
      all: all,
      count: count
    };
  }

  publishDraft(item: DraftChangeItem, options: EnvironmentService.EntitiesOptions = {}) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPublish, {
      ItemsType: options.itemsType,
      ItemsOptions: options
    });

    this.publishDraftLoading = true;
    this.cd.markForCheck();

    return this.environmentService
      .publishDraft(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        options
      )
      .pipe(
        delayWhen(() => this.publishService.reloadChanges([{ type: item.type }])),
        delayWhen(() => this.publishService.updatePagePermissions()),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.publishDraftLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Published, {
            ItemsType: options.itemsType,
            ItemsOptions: options
          });
        },
        () => {
          this.publishDraftLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.PublishFailed, {
            ItemsType: options.itemsType,
            ItemsOptions: options
          });
        }
      );
  }

  deleteDraft(item: DraftChangeItem, options: EnvironmentService.EntitiesOptions = {}) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickDiscard, {
      ItemsType: options.itemsType,
      ItemsOptions: options
    });

    this.deleteDraftLoading = true;
    this.cd.markForCheck();

    const { all, count } = this.countEntity(item, options);

    this.dialogService
      .dialog({
        title: all
          ? `Are you sure want to discard all ${item.label} draft changes${count !== undefined ? ` (${count})` : ''}?`
          : `Are you sure want to discard selected ${item.label} draft changes${
              count !== undefined ? ` (${count})` : ''
            }?`,
        description: all
          ? `
          All ${item.label} will be restored to published state.<br>
          Other environments will not be affected.
        `
          : `
          Only selected ${item.label} will be restored to published state.<br>
          Other environments will not be affected.
        `,
        style: 'orange',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Discard changes',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => {
              return this.environmentService.deleteDraft(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                options
              );
            }
          }
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (result.button == 'ok') {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Discarded, {
              ItemsType: options.itemsType,
              ItemsOptions: options
            });

            window.location.reload();
          } else {
            this.deleteDraftLoading = false;
            this.cd.markForCheck();

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardCancelled, {
              ItemsType: options.itemsType,
              ItemsOptions: options
            });
          }
        },
        () => {
          this.deleteDraftLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardFailed, {
            ItemsType: options.itemsType,
            ItemsOptions: options
          });
        }
      );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./icon-selector-stub.component";
var styles_IconSelectorStubComponent = [];
var RenderType_IconSelectorStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSelectorStubComponent, data: {} });
export { RenderType_IconSelectorStubComponent as RenderType_IconSelectorStubComponent };
function View_IconSelectorStubComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "select-icon__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "select-icon-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "select-icon-item__icon select-icon-item__icon_stub"]], [[2, "loading-animation", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }); }
function View_IconSelectorStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorStubComponent_2)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconsRange; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IconSelectorStubComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_IconSelectorStubComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorStubComponent_4)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_IconSelectorStubComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_IconSelectorStubComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "select-icon__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "select-icon__section-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["category"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "select-icon__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorStubComponent_6)), i0.ɵdid(7, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v.parent, 0); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }); }
export function View_IconSelectorStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(0, [["stub_template", 2]], null, 0, null, View_IconSelectorStubComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorStubComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorStubComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.sectionTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sectionTitle; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IconSelectorStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-stub", [], null, null, null, View_IconSelectorStubComponent_0, RenderType_IconSelectorStubComponent)), i0.ɵdid(1, 638976, null, 0, i2.IconSelectorStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSelectorStubComponentNgFactory = i0.ɵccf("app-icon-selector-stub", i2.IconSelectorStubComponent, View_IconSelectorStubComponent_Host_0, { sectionTitle: "sectionTitle", icons: "icons" }, {}, []);
export { IconSelectorStubComponentNgFactory as IconSelectorStubComponentNgFactory };

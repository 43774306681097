var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { TimelinePopupComponent, UserActivitiesController } from '@modules/activities-components';
import { CustomizeService, ViewSettingsStore } from '@modules/customize';
import { applyParamInput, applyParamInputs } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroupService, ProjectPropertyStore, ProjectPropertyType, ProjectUserService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { controlValue, isAbsoluteUrl, isSet } from '@shared';
import { getMenuItemSystemActionTypeInfo, MenuItemActionType, MenuItemSystemActionType } from '../../data/menu-item-action';
var MenuItemActionService = /** @class */ (function () {
    function MenuItemActionService(currentProjectStore, currentEnvironmentStore, viewSettingsStore, themeService, routing, userActivitiesController, projectPropertyStore, projectUserService, projectGroupService, customizeService, popupService, injector) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsStore = viewSettingsStore;
        this.themeService = themeService;
        this.routing = routing;
        this.userActivitiesController = userActivitiesController;
        this.projectPropertyStore = projectPropertyStore;
        this.projectUserService = projectUserService;
        this.projectGroupService = projectGroupService;
        this.customizeService = customizeService;
        this.popupService = popupService;
        this.injector = injector;
    }
    MenuItemActionService.prototype.getActionExecution = function (action, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!action) {
            return of({});
        }
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        if (action.type == MenuItemActionType.Page) {
            if (!project.hasEnvironmentPagePermission(environment, action.pageUid, 'r')) {
                return of({});
            }
            return this.viewSettingsStore.getDetailByUid(action.pageUid).pipe(map(function (page) {
                var params = applyParamInputs({}, action.inputs, {
                    context: options.context,
                    parameters: page ? page.parameters : undefined
                });
                return {
                    link: page ? _this.routing.appLink(page.link) : undefined,
                    queryParams: params
                    // link: result ? result.link : undefined
                };
            }));
        }
        else if (action.type == MenuItemActionType.URL) {
            if (!isSet(action.url)) {
                return of({});
            }
            if (isAbsoluteUrl(action.url)) {
                return of({
                    href: action.url
                });
            }
            else {
                return of({
                    link: [action.url]
                });
            }
        }
        else if (action.type == MenuItemActionType.System) {
            if (action.systemType == MenuItemSystemActionType.Home) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.homeLink)
                    // link: this.currentProjectStore.instance.homeLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Profile) {
                return of({
                    link: ['/profile/update']
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Users) {
                if (!project.hasEnvironmentAccessPermission(environment)) {
                    return of({});
                }
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsUsersLink)
                    // link: this.currentProjectStore.instance.settingsUsersLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ActivityLog) {
                return of({
                    handler: function () {
                        _this.openActivityLogPopup();
                        return of(true);
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ActivityLogPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsUserActivitiesLink)
                    // link: this.currentProjectStore.instance.settingsUserActivitiesLink
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Collaboration) {
                return of({
                    handler: function () {
                        _this.openTimelinePopup();
                        return of(true);
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.CollaborationTasksPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('tasks'))
                    // link: this.currentProjectStore.instance.settingsCollaborationLink('tasks')
                });
            }
            else if (action.systemType == MenuItemSystemActionType.CollaborationMessagesPage) {
                return of({
                    link: this.routing.appLink(this.currentProjectStore.instance.settingsCollaborationLink('messages'))
                    // link: this.currentProjectStore.instance.settingsCollaborationLink('messages')
                });
            }
            else if (action.systemType == MenuItemSystemActionType.ToggleTheme) {
                return of({
                    handler: function () {
                        _this.themeService.toggleTheme();
                        return of(true);
                    }
                });
            }
            else if (action.systemType == MenuItemSystemActionType.Logout) {
                return of({
                    link: ['/logout']
                });
            }
        }
        else if (action.type == MenuItemActionType.SetProperty) {
            if (!isSet(action.setPropertyValue) || !action.setPropertyValue) {
                return of({});
            }
            return this.projectPropertyStore.getDetail(action.setPropertyUid).pipe(map(function (property) {
                if (!property) {
                    return {};
                }
                return {
                    handler: function () {
                        var _a, _b;
                        var value = applyParamInput(action.setPropertyValue, {
                            context: options.context,
                            defaultValue: ''
                        });
                        if (property.type == ProjectPropertyType.Global && _this.currentEnvironmentStore.globalStorage) {
                            _this.currentEnvironmentStore.globalStorage.setValue(property, value);
                            return of(true);
                        }
                        else if (property.type == ProjectPropertyType.User) {
                            var item = cloneDeep(_this.currentEnvironmentStore.instance.user);
                            item.properties = __assign({}, item.properties, (_a = {}, _a[property.uid] = value, _a));
                            return _this.projectUserService
                                .update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item, ['properties'])
                                .pipe(tap(function (result) {
                                if (result.uid == _this.currentEnvironmentStore.instance.user.uid) {
                                    _this.currentEnvironmentStore.updateUser(result);
                                }
                            }));
                        }
                        else if (property.type == ProjectPropertyType.Group) {
                            var item = cloneDeep(_this.currentEnvironmentStore.instance.group);
                            item.properties = __assign({}, item.properties, (_b = {}, _b[property.uid] = value, _b));
                            return _this.projectGroupService
                                .update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item, ['properties'])
                                .pipe(tap(function (result) {
                                if (result.uid == _this.currentEnvironmentStore.instance.group.uid) {
                                    _this.currentEnvironmentStore.updateGroup(result);
                                }
                            }));
                        }
                        else {
                            return of(undefined);
                        }
                    }
                };
            }));
        }
        return of({});
    };
    MenuItemActionService.prototype.getActionValueDisplay$ = function (control, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(controlValue(control.controls.type), controlValue(control.controls.page_uid), controlValue(control.controls.url), controlValue(control.controls.system_type), controlValue(control.controls.set_property_uid).pipe(switchMap(function (value) { return _this.projectPropertyStore.getDetail(value); }))).pipe(switchMap(function (_a) {
            var type = _a[0], pageUid = _a[1], url = _a[2], systemType = _a[3], property = _a[4];
            if (type == MenuItemActionType.Page) {
                return _this.viewSettingsStore.getDetailByUid(pageUid).pipe(map(function (page) {
                    if (!page) {
                        return {
                            label: 'Open page (not selected)',
                            labelShort: 'Open page',
                            icon: 'document'
                        };
                    }
                    return {
                        label: "Open page - " + page.name,
                        labelShort: "/" + page.uniqueName,
                        icon: 'document'
                    };
                }));
            }
            else if (type == MenuItemActionType.URL) {
                return of({
                    label: options.excludeUrl || !isSet(url) ? 'Open URL' : "Open URL - " + url,
                    labelShort: options.excludeUrl ? 'Open URL' : url,
                    icon: 'external_link'
                });
            }
            else if (type == MenuItemActionType.System) {
                if (!isSet(systemType)) {
                    return of({
                        label: 'Built-in action',
                        icon: 'gear'
                    });
                }
                var info = getMenuItemSystemActionTypeInfo(systemType);
                return of({
                    label: info.actionLabel,
                    labelShort: info.label,
                    icon: info.icon
                });
            }
            else if (type == MenuItemActionType.SetProperty) {
                return of({
                    label: property ? "Set Variable - " + property.name : 'Set Variable',
                    labelShort: 'Set Variable',
                    icon: 'variable'
                });
            }
            return of(undefined);
        }));
    };
    MenuItemActionService.prototype.openTimelinePopup = function () {
        var _this = this;
        if (this.popupService.items.find(function (item) { return item === _this.timelinePopup; })) {
            return;
        }
        var handler = this.customizeService.handler;
        var params = handler && handler.getCollaborationParams ? handler.getCollaborationParams() : {};
        this.timelinePopup = this.popupService.push({
            component: TimelinePopupComponent,
            disablePointerEvents: true,
            enableWindowScroll: true,
            inputs: { baseParams: params },
            injector: this.injector
        });
    };
    MenuItemActionService.prototype.openActivityLogPopup = function () {
        var _this = this;
        if (this.popupService.items.find(function (item) { return item === _this.activityLogPopup; })) {
            return;
        }
        var handler = this.customizeService.handler;
        var params = handler && handler.getUserActivitiesParams ? handler.getUserActivitiesParams() : {};
        this.activityLogPopup = this.userActivitiesController.open(params);
    };
    return MenuItemActionService;
}());
export { MenuItemActionService };

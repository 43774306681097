/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../previews/video-preview/video-preview.component.ngfactory";
import * as i2 from "../../previews/video-preview/video-preview.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../previews/file-preview/file-preview.component.ngfactory";
import * as i5 from "../../previews/file-preview/file-preview.component";
import * as i6 from "@angular/common";
import * as i7 from "../upload-template/upload-template.component.ngfactory";
import * as i8 from "../upload-template/upload-template.component";
import * as i9 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i10 from "./multiple-video-upload.component";
import * as i11 from "../../../../projects/stores/current-project.store";
import * as i12 from "../../../../projects/stores/current-environment.store";
import * as i13 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i14 from "../../../../storages-queries/services/storage/storage.service";
var styles_MultipleVideoUploadComponent = [];
var RenderType_MultipleVideoUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultipleVideoUploadComponent, data: {} });
export { RenderType_MultipleVideoUploadComponent as RenderType_MultipleVideoUploadComponent };
function View_MultipleVideoUploadComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-preview", [], null, null, null, i1.View_VideoPreviewComponent_0, i1.RenderType_VideoPreviewComponent)), i0.ɵdid(1, 573440, null, 0, i2.VideoPreviewComponent, [i3.DomSanitizer, i0.ChangeDetectorRef], { preview: [0, "preview"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.url); _ck(_v, 1, 0, currVal_0); }, null); }
function View_MultipleVideoUploadComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-preview", [], null, null, null, i4.View_FilePreviewComponent_0, i4.RenderType_FilePreviewComponent)), i0.ɵdid(1, 114688, null, 0, i5.FilePreviewComponent, [], { previewFilename: [0, "previewFilename"], previewExtension: [1, "previewExtension"], previewSize: [2, "previewSize"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.filename); var currVal_1 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.extension); var currVal_2 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.uploadFileSize); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MultipleVideoUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "upload__background upload__background_video upload__background_interactive"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleVideoUploadComponent_3)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleVideoUploadComponent_4)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.compact; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.compact; _ck(_v, 5, 0, currVal_1); }, null); }
function View_MultipleVideoUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-upload-template", [["class", "multiple-files-uploader__item"]], null, [[null, "clearValue"], [null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearValue" === en)) {
        var pd_0 = (_co.clearCurrentValue(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("fileSelected" === en)) {
        var pd_1 = (_co.onFileSelected($event, _v.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_UploadTemplateComponent_0, i7.RenderType_UploadTemplateComponent)), i0.ɵdid(1, 245760, null, 0, i8.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], accept: [1, "accept"], name: [2, "name"], control: [3, "control"], autofocus: [4, "autofocus"], disabled: [5, "disabled"], background: [6, "background"], fit: [7, "fit"], cropFormat: [8, "cropFormat"], cropFormatCustom: [9, "cropFormatCustom"], preview: [10, "preview"], uploadProgress: [11, "uploadProgress"], uploadError: [12, "uploadError"], accentColor: [13, "accentColor"] }, { clearValue: "clearValue", fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MultipleVideoUploadComponent_2)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 1, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "a", [["class", "upload__button icon-cloud_download"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.download(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 737280, null, 0, i9.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(7, { side: 0, margin: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadable(); var currVal_1 = "video/*"; var currVal_2 = _co.name; var currVal_3 = _co.control; var currVal_4 = _co.autofocus; var currVal_5 = !_co.storage$.value; var currVal_6 = _co.background; var currVal_7 = _co.fit; var currVal_8 = _co.cropFormat; var currVal_9 = _co.cropFormatCustom; var currVal_10 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); var currVal_11 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.uploadProgress); var currVal_12 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.uploadError); var currVal_13 = _co.accentColor; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); var currVal_14 = _v.context.$implicit; _ck(_v, 3, 0, currVal_14); var currVal_15 = "Download"; var currVal_16 = _ck(_v, 7, 0, "top", (0 - 4)); _ck(_v, 6, 0, currVal_15, currVal_16); }, null); }
function View_MultipleVideoUploadComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "multiple-files-uploader__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "multiple-files-uploader__spoiler"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["+", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.uploadedFiles.length - 3); _ck(_v, 2, 0, currVal_0); }); }
export function View_MultipleVideoUploadComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "multiple-files-uploader"]], [[2, "multiple-files-uploader_compact", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleVideoUploadComponent_1)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleVideoUploadComponent_5)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-upload-template", [["class", "multiple-files-uploader__item"]], null, [[null, "filesSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelected" === en)) {
        var pd_0 = (_co.onFilesSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_UploadTemplateComponent_0, i7.RenderType_UploadTemplateComponent)), i0.ɵdid(6, 245760, null, 0, i8.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], multiple: [1, "multiple"], accept: [2, "accept"], name: [3, "name"], control: [4, "control"], autofocus: [5, "autofocus"], disabled: [6, "disabled"], background: [7, "background"], fit: [8, "fit"], cropFormat: [9, "cropFormat"], cropFormatCustom: [10, "cropFormatCustom"], uploadProgress: [11, "uploadProgress"], uploadError: [12, "uploadError"], accentColor: [13, "accentColor"] }, { filesSelected: "filesSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.compact ? _co.uploadedFiles.slice(0, 3) : _co.uploadedFiles); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.compact && (_co.uploadedFiles.length > 3)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.isUploadable(); var currVal_4 = true; var currVal_5 = "video/*"; var currVal_6 = _co.name; var currVal_7 = _co.control; var currVal_8 = _co.autofocus; var currVal_9 = !_co.storage$.value; var currVal_10 = _co.background; var currVal_11 = _co.fit; var currVal_12 = _co.cropFormat; var currVal_13 = _co.cropFormatCustom; var currVal_14 = ((_co.upload == null) ? null : _co.upload.uploadProgress); var currVal_15 = ((_co.upload == null) ? null : _co.upload.uploadError); var currVal_16 = _co.accentColor; _ck(_v, 6, 1, [currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.compact; _ck(_v, 0, 0, currVal_0); }); }
export function View_MultipleVideoUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-multiple-video-upload", [], null, null, null, View_MultipleVideoUploadComponent_0, RenderType_MultipleVideoUploadComponent)), i0.ɵdid(1, 770048, null, 0, i10.MultipleVideoUploadComponent, [i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i13.ResourceControllerService, i14.StorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipleVideoUploadComponentNgFactory = i0.ɵccf("app-multiple-video-upload", i10.MultipleVideoUploadComponent, View_MultipleVideoUploadComponent_Host_0, { name: "name", control: "control", storeFormat: "storeFormat", outputFormat: "outputFormat", storageResourceName: "storageResourceName", storageName: "storageName", path: "path", autofocus: "autofocus", disabled: "disabled", background: "background", fit: "fit", resize: "resize", editor: "editor", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor" }, { uploadedUpdated: "uploadedUpdated" }, []);
export { MultipleVideoUploadComponentNgFactory as MultipleVideoUploadComponentNgFactory };

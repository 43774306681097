import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ApiParameter } from '../../../data/api-parameter';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

@Component({
  selector: 'app-model-api-reload-query',
  templateUrl: './model-api-reload-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiReloadQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  modelApi = false;
  parameters: ApiParameter[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl(),
      method: 'POST'
    });
  }

  getQueryUrl() {
    return '/reload';
  }

  getResponseData(): Object {
    return JSON.stringify({ success: true }, null, 2);
  }
}

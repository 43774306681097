/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "./compact-wide-button.component";
var styles_CompactWideButtonComponent = [];
var RenderType_CompactWideButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompactWideButtonComponent, data: {} });
export { RenderType_CompactWideButtonComponent as RenderType_CompactWideButtonComponent };
function View_CompactWideButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "compact-wide-button__left"]], [[2, "compact-wide-button__left_background", null], [2, "compact-wide-button__left_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.leftClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-wide-button__icon"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.leftTooltip; var currVal_3 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "compact-wide-button__icon"; var currVal_5 = _ck(_v, 5, 0, ("icon-" + _co.leftIcon)); _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leftBackground; var currVal_1 = _co.leftInteractive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CompactWideButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-wide-button__additional"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.additional; _ck(_v, 1, 0, currVal_0); }); }
function View_CompactWideButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "compact-wide-button__right"]], [[2, "compact-wide-button__right_background", null], [2, "compact-wide-button__right_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.rightClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-wide-button__icon"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.rightTooltip; var currVal_3 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "compact-wide-button__icon"; var currVal_5 = _ck(_v, 5, 0, ("icon-" + _co.rightIcon)); _ck(_v, 4, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rightBackground; var currVal_1 = _co.rightInteractive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CompactWideButtonComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "compact-wide-button"]], [[2, "compact-wide-button_interactive", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompactWideButtonComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "compact-wide-button__main"]], [[2, "compact-wide-button__main_padding_left", null], [2, "compact-wide-button__main_padding_right", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "compact-wide-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CompactWideButtonComponent_2)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompactWideButtonComponent_3)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.leftIcon; _ck(_v, 3, 0, currVal_1); var currVal_5 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.additional)); _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.rightIcon; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.interactive; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.leftBackground; var currVal_3 = _co.rightBackground; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.label; _ck(_v, 6, 0, currVal_4); }); }
export function View_CompactWideButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compact-wide-button", [], null, null, null, View_CompactWideButtonComponent_0, RenderType_CompactWideButtonComponent)), i0.ɵdid(1, 114688, null, 0, i4.CompactWideButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompactWideButtonComponentNgFactory = i0.ɵccf("app-compact-wide-button", i4.CompactWideButtonComponent, View_CompactWideButtonComponent_Host_0, { label: "label", additional: "additional", interactive: "interactive", leftIcon: "leftIcon", leftBackground: "leftBackground", leftInteractive: "leftInteractive", leftTooltip: "leftTooltip", rightIcon: "rightIcon", rightBackground: "rightBackground", rightInteractive: "rightInteractive", rightTooltip: "rightTooltip" }, { leftClick: "leftClick", rightClick: "rightClick" }, []);
export { CompactWideButtonComponentNgFactory as CompactWideButtonComponentNgFactory };

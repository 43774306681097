import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-contrast-dialog-popup',
  templateUrl: './contrast-dialog-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContrastDialogPopupComponent extends DialogPopupComponent {
  contrast = true;
  medium = true;
}

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import {
  DateFormat,
  defaultDateFormat,
  defaultTimeFormat,
  momentDateFormats,
  momentTimeFormats,
  TimeFormat
} from '@modules/fields';
import { isSet } from '@shared';

@Injectable()
export class DateTimeFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;

  dateFormatOptions = [
    { value: DateFormat.Local, name: `Local (${moment().format(momentDateFormats[DateFormat.Local])})` },
    { value: DateFormat.Friendly, name: `Friendly (${moment().format(momentDateFormats[DateFormat.Friendly])})` },
    { value: DateFormat.US, name: `US (${moment().format(momentDateFormats[DateFormat.US])})` },
    { value: DateFormat.European, name: `European (${moment().format(momentDateFormats[DateFormat.European])})` },
    { value: DateFormat.ISO, name: `ISO (${moment().format(momentDateFormats[DateFormat.ISO])})` },
    { value: DateFormat.Custom, name: 'Custom' }
  ];

  dateFormatEditOptions = this.dateFormatOptions.filter(item => item.value !== DateFormat.Friendly);

  timeFormatOptions = [
    {
      value: TimeFormat.HourMinute24,
      name: `24 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute24])})`
    },
    {
      value: TimeFormat.HourMinuteSecond24,
      name: `24 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond24])})`
    },
    {
      value: TimeFormat.HourMinute12,
      name: `12 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute12])})`
    },
    {
      value: TimeFormat.HourMinuteSecond12,
      name: `12 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond12])})`
    },
    {
      value: TimeFormat.Custom,
      name: 'Custom'
    }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      time: new FormControl(true),
      relative: new FormControl(false),
      date_format: new FormControl(defaultDateFormat),
      date_format_custom: new FormControl(momentDateFormats[DateFormat.US]),
      time_format: new FormControl(defaultTimeFormat),
      time_format_custom: new FormControl(momentTimeFormats[TimeFormat.HourMinuteSecond24])
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      const value = {};

      if (isSet(control.value['time'])) {
        value['time'] = control.value['time'];
      }

      if (isSet(control.value['relative'])) {
        value['relative'] = control.value['relative'];
      }

      if (isSet(control.value['date_format']) && momentDateFormats[control.value['date_format']]) {
        value['date_format'] = control.value['date_format'];
      } else if (isSet(control.value['date_format'])) {
        value['date_format'] = DateFormat.Custom;
        value['date_format_custom'] = control.value['date_format'];
      }

      if (isSet(control.value['time_format']) && momentTimeFormats[control.value['time_format']]) {
        value['time_format'] = control.value['time_format'];
      } else if (isSet(control.value['time_format'])) {
        value['time_format'] = TimeFormat.Custom;
        value['time_format_custom'] = control.value['time_format'];
      }

      this.form.patchValue(value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = {
      time: this.form.value['time'],
      relative: this.form.value['relative'],
      date_format:
        this.form.value['date_format'] == DateFormat.Custom && isSet(this.form.value['date_format_custom'])
          ? this.form.value['date_format_custom']
          : this.form.value['date_format'],
      time_format:
        this.form.value['time_format'] == TimeFormat.Custom && isSet(this.form.value['time_format_custom'])
          ? this.form.value['time_format_custom']
          : this.form.value['time_format']
    };

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainStore } from '@modules/domain';
import { defaultNumberFractionSeparator, defaultNumberThousandsSeparator } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { FormatNumberPipe, isSet } from '@shared';
var DomainFormatNumberPipe = /** @class */ (function (_super) {
    __extends(DomainFormatNumberPipe, _super);
    function DomainFormatNumberPipe(domainStore, currentProjectStore) {
        var _this = _super.call(this) || this;
        _this.domainStore = domainStore;
        _this.currentProjectStore = currentProjectStore;
        return _this;
    }
    DomainFormatNumberPipe.prototype.getDomain$ = function () {
        if (this.currentProjectStore) {
            return this.currentProjectStore.get().pipe(map(function (project) { return (project ? project.domain : undefined); }));
        }
        else if (this.domainStore) {
            return this.domainStore.get();
        }
        else {
            return of(undefined);
        }
    };
    DomainFormatNumberPipe.prototype.transform = function (value, format) {
        var result = _super.prototype.transform.call(this, value, format);
        if (!isSet(result)) {
            return result;
        }
        return this.getDomain$().pipe(map(function (domain) {
            var numberFractionSeparator = domain ? domain.numberFractionSeparator : undefined;
            var numberThousandsSeparator = domain ? domain.numberThousandsSeparator : undefined;
            return String(result).replace(/[,.]/g, function (symbol) {
                if (symbol == ',') {
                    return isSet(numberThousandsSeparator, true) ? numberThousandsSeparator : defaultNumberThousandsSeparator;
                }
                else if (symbol == '.') {
                    return isSet(numberFractionSeparator) ? numberFractionSeparator : defaultNumberFractionSeparator;
                }
                else {
                    return symbol;
                }
            });
        }));
    };
    return DomainFormatNumberPipe;
}(FormatNumberPipe));
export { DomainFormatNumberPipe };

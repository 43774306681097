import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isSet } from '@shared';

import { CustomViewType } from '../data/custom-view';
import { CustomViewsStore } from './custom-views.store';

@Injectable()
export class CustomViewSelectSource extends SelectSource {
  viewType: CustomViewType;

  private page = 1;
  private totalPages = 1;

  constructor(private customViewsStore: CustomViewsStore) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.customViewsStore.getFirst().pipe(
      map(result => {
        this.page += 1;

        return result
          .filter(item =>
            isSet(searchQuery)
              ? [item.uniqueName, item.name].some(str => str.toLowerCase().includes(searchQuery.toLowerCase()))
              : true
          )
          .filter(item => (this.viewType ? item.viewType == this.viewType : true))
          .map(item => {
            return {
              value: item.uniqueName,
              name: item.name
            };
          });
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    return this.customViewsStore.getDetailFirst(value).pipe(
      map(item => {
        if (!item) {
          return;
        }

        return {
          value: item.uniqueName,
          name: item.name
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { GuideFocusService } from '../../services/guide-focus/guide-focus.service';

@Component({
  selector: 'app-guide-focus',
  templateUrl: './guide-focus.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideFocusComponent implements OnInit, OnDestroy {
  focusTransform: SafeStyle;

  constructor(
    private guideFocusService: GuideFocusService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.guideFocusService.position$.pipe(untilDestroyed(this)).subscribe(position => {
      this.focusTransform = position
        ? this.sanitizer.bypassSecurityTrustStyle(`translate3d(${position.x}px, ${position.y}px, 0)`)
        : undefined;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {}
}

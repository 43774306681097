import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { Observable, of } from 'rxjs';

import {
  applyParamInput,
  applyParamInput$,
  applyStaticValueFormat,
  DisplayField,
  FieldType,
  getFieldDescriptionByType,
  ValueFormat
} from '@modules/fields';
import { Model, ModelDbField, ModelDescription, ModelField, ModelFieldType, ModelFlexField } from '@modules/models';
import { isSet } from '@shared';

import { RawListViewSettingsColumn } from '../data/raw-list-view-settings-column';
import { ViewContext } from '../data/view-context';
import { ViewContextElement } from '../data/view-context-element';
import { ViewContextOutput } from '../data/view-context-output';
import { ViewContextToken } from '../data/view-context-token';

export function modelFieldItemToRawListViewSettingsColumn(
  field: ModelDbField | ModelFlexField | DisplayField,
  addVisible = true
): RawListViewSettingsColumn {
  return {
    name: field.name,
    verboseName: field.verboseName,
    description: field.description,
    field: field.field,
    params: cloneDeep(field.params),
    ...(field instanceof ModelDbField
      ? {
          editable: field.editable,
          sortable: field.sortable,
          filterable: field.filterable
        }
      : {}),
    ...(addVisible ? { visible: true } : {})
  };
}

export function modelFieldToRawListViewSettingsColumn(field: ModelField, addVisible = true): RawListViewSettingsColumn {
  return {
    ...modelFieldItemToRawListViewSettingsColumn(field.item, addVisible),
    name: field.name
  };
}

export function modelFieldItemToDisplayField(
  field: ModelDbField | ModelFlexField | DisplayField,
  addVisible = true
): DisplayField {
  if (field instanceof DisplayField) {
    return field;
  } else {
    return new DisplayField().deserialize({
      name: field.name,
      verboseName: field.verboseName,
      description: field.description,
      field: field.field,
      params: cloneDeep(field.params),
      ...(field instanceof ModelDbField
        ? {
            editable: field.editable,
            filterable: field.filterable,
            sortable: field.sortable
          }
        : {}),
      ...(addVisible ? { visible: true } : {})
    });
  }
}

export function modelFieldToDisplayField(field: ModelField, addVisible = true): DisplayField {
  const result = modelFieldItemToDisplayField(field.item, addVisible);
  result.name = field.name;
  return result;
}

export function rawListViewSettingsColumnToModelFieldDbItem(item: RawListViewSettingsColumn): ModelDbField {
  const field = new ModelDbField();

  field.name = item.name;
  field.verboseName = item.verboseName;
  field.field = item.field;
  field.params = item.params;
  field.updateFieldDescription();

  return field;
}

export function rawListViewSettingsColumnToModelField(item: RawListViewSettingsColumn): ModelField {
  const field = new ModelField();

  field.name = item.name;
  field.item = rawListViewSettingsColumnToModelFieldDbItem(item);
  field.type = ModelFieldType.Db;

  return field;
}

export function rawListViewSettingsColumnsToViewContextOutputs(
  items: RawListViewSettingsColumn[],
  modelDescription?: ModelDescription
): ViewContextOutput[] {
  return [...items]
    .sort((lhs, rhs) => {
      const primaryKeyLhs = modelDescription && modelDescription.primaryKeyField == lhs.name ? 1 : 0;
      const primaryKeyRhs = modelDescription && modelDescription.primaryKeyField == rhs.name ? 1 : 0;

      return (primaryKeyLhs - primaryKeyRhs) * -1;
    })
    .map(item => {
      const primaryKey = modelDescription && modelDescription.primaryKeyField == item.name;
      const subtitle = primaryKey ? 'primary key' : undefined;
      let icon: string;

      if (primaryKey) {
        icon = 'key';
      } else {
        const fieldDescription = getFieldDescriptionByType(item.field);
        icon = fieldDescription ? fieldDescription.icon : undefined;
      }

      return {
        uniqueName: item.name,
        name: item.verboseName,
        subtitle: subtitle,
        icon: icon,
        iconOrange: primaryKey,
        fieldType: item.field,
        fieldParams: item.params
      };
    });
}

export function rawListViewSettingsColumnsToDisplayField(item: RawListViewSettingsColumn): DisplayField {
  const result = new DisplayField({
    name: item.name,
    verboseName: item.verboseName,
    description: item.description,
    field: item.field,
    params: cloneDeep(item.params),
    visible: item.visible
  });
  result.updateFieldDescription();
  return result;
}

export function displayFieldsToViewContextOutputs(
  items: DisplayField[],
  modelDescription?: ModelDescription
): ViewContextOutput[] {
  return rawListViewSettingsColumnsToViewContextOutputs(items, modelDescription);
}

export function getDefaultRawListViewSettingsColumnVisible(
  column: RawListViewSettingsColumn,
  options: { modelDescription?: ModelDescription; defaultJsonVisibility?: boolean } = {}
): boolean {
  if (column.field == FieldType.JSON && isSet(options.defaultJsonVisibility)) {
    return options.defaultJsonVisibility;
  } else if (options.modelDescription && options.modelDescription.primaryKeyField == column.name) {
    return false;
  } else {
    return true;
  }
}

export function applyRawListViewSettingsColumnsDefaultVisible(
  columns: RawListViewSettingsColumn[],
  options: { maxVisible?: number; modelDescription?: ModelDescription; defaultJsonVisibility?: boolean } = {}
) {
  let visibleCount = 0;

  return columns.map(item => {
    let visible: boolean;

    if (item.hasOwnProperty('visible')) {
      visible = item.visible;
    } else if (isSet(options.maxVisible) && visibleCount >= options.maxVisible) {
      visible = false;
    } else {
      visible = getDefaultRawListViewSettingsColumnVisible(item, {
        modelDescription: options.modelDescription,
        defaultJsonVisibility: options.defaultJsonVisibility
      });
    }

    if (visible) {
      ++visibleCount;
    }

    return {
      ...item,
      visible: visible
    };
  });
}

export function applyDisplayFieldsDefaultVisible(
  columns: DisplayField[],
  options: { maxVisible?: number; modelDescription?: ModelDescription; defaultJsonVisibility?: boolean } = {}
): DisplayField[] {
  let visibleCount = 0;

  columns.forEach(item => {
    let visible: boolean;

    if (isSet(options.maxVisible) && visibleCount >= options.maxVisible) {
      visible = false;
    } else {
      visible = getDefaultRawListViewSettingsColumnVisible(item, {
        modelDescription: options.modelDescription,
        defaultJsonVisibility: options.defaultJsonVisibility
      });
    }

    if (visible) {
      ++visibleCount;
    }

    item.visible = visible;
  });

  return columns;
}

export function getModelAttributesByColumns(model: Model, columns: RawListViewSettingsColumn[]) {
  return fromPairs(
    columns.map(item => {
      return [item.name, model.getAttribute(item.name)];
    })
  );
}

export function getModelBulkAttributesByColumns(models: Model[], columns: RawListViewSettingsColumn[]) {
  return fromPairs(
    columns.map(item => {
      return [item.name, models.map(model => model.getAttribute(item.name))];
    })
  );
}

export function flattenContextTokens(items: ViewContextToken[]): ViewContextToken[] {
  return items.reduce((acc, item) => {
    acc.push(item);

    if (item.children) {
      acc.push(...flattenContextTokens(item.children));
    }

    return acc;
  }, []);
}

export function applyValueFormat$<T>(
  value: T,
  valueFormat: ValueFormat,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
  }
): Observable<string> {
  if (!valueFormat) {
    return of(value as any);
  }

  if (valueFormat.formatInput) {
    return applyParamInput$(valueFormat.formatInput, {
      context: options.context,
      contextElement: options.contextElement,
      localContext: {
        value: value
      },
      defaultValue: ''
    });
  } else {
    return of(applyStaticValueFormat(value, valueFormat));
  }
}

export function applyValueFormat<T>(
  value: T,
  valueFormat: ValueFormat,
  options: {
    context?: ViewContext;
    contextElement?: ViewContextElement;
  }
): string {
  if (!valueFormat) {
    return value as any;
  }

  if (valueFormat.formatInput) {
    try {
      return applyParamInput(valueFormat.formatInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: {
          value: value
        },
        defaultValue: ''
      });
    } catch (e) {
      return '';
    }
  } else {
    return applyStaticValueFormat(value, valueFormat);
  }
}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class YandexMetrikaService {
  constructor(private cookieService: CookieService) {}

  get clientId() {
    return this.cookieService.get('_ym_uid');
  }
}

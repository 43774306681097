import round from 'lodash/round';

import { Frame, FrameTranslate, Point, PointTranslate } from '@modules/views';
import { isSet } from '@shared';

import {
  ViewEditorContainer,
  ViewEditorGuide,
  ViewEditorGuideColor,
  ViewEditorGuideSymbol
} from '../services/view-editor-context/view-editor.context';

export function snapFrame(options: {
  base: FrameTranslate;
  others: FrameTranslate[];
  horizontalSnapX?: boolean;
  horizontalSnapWidth?: boolean;
  horizontalOriginSnaps?: number[];
  verticalSnapY?: boolean;
  verticalSnapHeight?: boolean;
  verticalOriginSnaps?: number[];
  snapDistance?: number;
}): {
  updateFrame: Partial<Frame>;
  horizontalGuides: ViewEditorGuide[];
  verticalGuides: ViewEditorGuide[];
} {
  options.horizontalOriginSnaps = options.horizontalOriginSnaps || [0, 0.5, 1];
  options.verticalOriginSnaps = options.verticalOriginSnaps || [0, 0.5, 1];
  options.snapDistance = isSet(options.snapDistance) ? options.snapDistance : 2;

  const updateFrame: Partial<Frame> = {};

  const snaps: {
    horizontal: {
      snapY: number;
      snapHeight: number;
      distance: { frameX: number; originX: number; value: number };
    }[];
    horizontalClosest?: { frameX: number; originX: number; value: number };
    vertical: {
      snapX: number;
      snapWidth: number;
      distance: { frameY: number; originY: number; value: number };
    }[];
    verticalClosest?: { frameY: number; originY: number; value: number };
  } = {
    horizontal: [],
    vertical: []
  };

  [...options.others].reverse().forEach(frame => {
    [frame.x, frame.x + frame.frame.width * 0.5, frame.x + frame.frame.width].forEach(x => {
      options.horizontalOriginSnaps
        .map(item => options.base.frame.width * item)
        .forEach(originX => {
          const distanceValue = Math.abs(options.base.x + originX - x);
          if (distanceValue <= options.snapDistance) {
            const distance = {
              frameX: x,
              originX: originX,
              value: distanceValue
            };
            snaps.horizontal.push({
              snapY: frame.y,
              snapHeight: frame.frame.height,
              distance: distance
            });

            if (!snaps.horizontalClosest || snaps.horizontalClosest.value > distanceValue) {
              snaps.horizontalClosest = distance;
            }
          }
        });
    });

    [frame.y, frame.y + frame.frame.height * 0.5, frame.y + frame.frame.height].forEach(y => {
      options.verticalOriginSnaps
        .map(item => options.base.frame.height * item)
        .forEach(originY => {
          const distanceValue = Math.abs(options.base.y + originY - y);
          if (distanceValue <= options.snapDistance) {
            const distance = {
              frameY: y,
              originY: originY,
              value: distanceValue
            };
            snaps.vertical.push({
              snapX: frame.x,
              snapWidth: frame.frame.width,
              distance: distance
            });

            if (!snaps.verticalClosest || snaps.verticalClosest.value > distanceValue) {
              snaps.verticalClosest = distance;
            }
          }
        });
    });
  });

  const horizontalGuides: ViewEditorGuide[] = [];
  const verticalGuides: ViewEditorGuide[] = [];

  if (snaps.horizontalClosest) {
    if (options.horizontalSnapX && !options.horizontalSnapWidth) {
      updateFrame.x = snaps.horizontalClosest.frameX - snaps.horizontalClosest.originX;
    } else if (options.horizontalSnapX && options.horizontalSnapWidth) {
      const right = options.base.x + options.base.frame.width;
      updateFrame.x = snaps.horizontalClosest.frameX - snaps.horizontalClosest.originX;
      updateFrame.width = right - updateFrame.x;
    } else if (options.horizontalSnapWidth) {
      updateFrame.width = snaps.horizontalClosest.frameX - options.base.x;
    }

    const snapsClosest = snaps.horizontal.filter(item => {
      return (
        item.distance.frameX == snaps.horizontalClosest.frameX &&
        item.distance.originX == snaps.horizontalClosest.originX
      );
    });
    const updatedFrame = { ...options.base.frame, x: options.base.x, y: options.base.y, ...updateFrame };
    const minY = Math.min(...snapsClosest.map(item => item.snapY), updatedFrame.y);
    const maxY = Math.max(
      ...snapsClosest.map(item => item.snapY + item.snapHeight),
      updatedFrame.y + updatedFrame.height
    );
    const height = maxY - minY;

    verticalGuides.push({
      x: snaps.horizontalClosest.frameX,
      y: minY,
      length: height
    });
  }

  if (snaps.verticalClosest) {
    if (options.verticalSnapY && !options.verticalSnapHeight) {
      updateFrame.y = snaps.verticalClosest.frameY - snaps.verticalClosest.originY;
    } else if (options.verticalSnapY && options.verticalSnapHeight) {
      const bottom = options.base.y + options.base.frame.height;
      updateFrame.y = snaps.verticalClosest.frameY - snaps.verticalClosest.originY;
      updateFrame.height = bottom - updateFrame.y;
    } else if (options.verticalSnapHeight) {
      updateFrame.height = snaps.verticalClosest.frameY - options.base.y;
    }

    const snapsClosest = snaps.vertical.filter(item => {
      return (
        item.distance.frameY == snaps.verticalClosest.frameY && item.distance.originY == snaps.verticalClosest.originY
      );
    });
    const updatedFrame = { ...options.base.frame, x: options.base.x, y: options.base.y, ...updateFrame };
    const minX = Math.min(...snapsClosest.map(item => item.snapX), updatedFrame.x);
    const maxX = Math.max(
      ...snapsClosest.map(item => item.snapX + item.snapWidth),
      updatedFrame.x + updatedFrame.width
    );
    const width = maxX - minX;

    horizontalGuides.push({
      x: minX,
      y: snaps.verticalClosest.frameY,
      length: width
    });
  }

  if (isSet(updateFrame.x) && options.base.translate.x) {
    updateFrame.x -= options.base.translate.x;
  }

  if (isSet(updateFrame.y) && options.base.translate.y) {
    updateFrame.y -= options.base.translate.y;
  }

  return {
    updateFrame: updateFrame,
    horizontalGuides: horizontalGuides,
    verticalGuides: verticalGuides
  };
}

export function snapPoint(options: {
  point: PointTranslate;
  otherFrames: FrameTranslate[];
  snapDistance?: number;
}): {
  updatePoint: Partial<Point>;
  horizontalGuides: ViewEditorGuide[];
  verticalGuides: ViewEditorGuide[];
} {
  const frame = new Frame({ x: options.point.point.x, y: options.point.point.y, width: 0, height: 0 });
  const snap = snapFrame({
    base: new FrameTranslate({
      frame: frame,
      translate: options.point.translate
    }),
    others: options.otherFrames,
    horizontalSnapX: true,
    horizontalOriginSnaps: [0],
    verticalSnapY: true,
    verticalOriginSnaps: [0],
    snapDistance: options.snapDistance
  });

  return {
    updatePoint: {
      x: snap.updateFrame.x,
      y: snap.updateFrame.y
    },
    horizontalGuides: snap.horizontalGuides,
    verticalGuides: snap.verticalGuides
  };
}

export function measureFrames(
  frame: FrameTranslate,
  hoverFrame: FrameTranslate
): { horizontalGuides: ViewEditorGuide[]; verticalGuides: ViewEditorGuide[] } {
  const horizontalGuides: ViewEditorGuide[] = [];
  const verticalGuides: ViewEditorGuide[] = [];

  const frameXRight = frame.x + frame.width;
  const frameXCenter = frame.x + frame.width * 0.5;
  const frameYCenter = frame.y + frame.height * 0.5;
  const frameYBottom = frame.y + frame.height;
  const hoverFrameXRight = hoverFrame.x + hoverFrame.width;
  const hoverFrameYBottom = hoverFrame.y + hoverFrame.height;
  const xGuides: { x: number; width: number; leftToRight: boolean }[] = [];
  const yGuides: { y: number; height: number; topToBottom: boolean }[] = [];

  if (frameXRight < hoverFrame.x) {
    const distanceRight = hoverFrame.x - frameXRight;
    xGuides.push({ x: frameXRight, width: distanceRight, leftToRight: true });
  } else if (frameXRight > hoverFrame.x && frame.x < hoverFrameXRight) {
    const distanceLeft = frame.x - hoverFrame.x;
    const distanceRight = hoverFrameXRight - frameXRight;

    if (distanceLeft > 0) {
      xGuides.push({ x: hoverFrame.x, width: distanceLeft, leftToRight: false });
    } else if (distanceLeft < 0) {
      xGuides.push({ x: frame.x, width: distanceLeft * -1, leftToRight: true });
    }

    if (distanceRight > 0) {
      xGuides.push({ x: frameXRight, width: distanceRight, leftToRight: true });
    } else if (distanceRight < 0) {
      xGuides.push({ x: hoverFrameXRight, width: distanceRight * -1, leftToRight: false });
    }
  } else if (frame.x > hoverFrameXRight) {
    const distanceLeft = frame.x - hoverFrameXRight;
    xGuides.push({ x: hoverFrameXRight, width: distanceLeft, leftToRight: false });
  }

  xGuides.forEach(guide => {
    horizontalGuides.push({
      x: guide.x,
      y: frameYCenter,
      length: guide.width,
      label: `${round(guide.width, 2)}`,
      color: ViewEditorGuideColor.Blue,
      startSymbol: ViewEditorGuideSymbol.Line,
      endSymbol: ViewEditorGuideSymbol.Line
    });

    if (frameYCenter < hoverFrame.y) {
      verticalGuides.push({
        x: guide.leftToRight ? guide.x + guide.width : guide.x,
        y: frame.y,
        length: hoverFrame.y - frame.y,
        dashed: true
      });
    } else if (frameYCenter > hoverFrameYBottom) {
      verticalGuides.push({
        x: guide.leftToRight ? guide.x + guide.width : guide.x,
        y: hoverFrameYBottom,
        length: frameYBottom - hoverFrameYBottom,
        dashed: true
      });
    }
  });

  if (frameYBottom < hoverFrame.y) {
    const distanceBottom = hoverFrame.y - frameYBottom;
    yGuides.push({ y: frameYBottom, height: distanceBottom, topToBottom: true });
  } else if (frameYBottom > hoverFrame.y && frame.y < hoverFrameYBottom) {
    const distanceTop = frame.y - hoverFrame.y;
    const distanceBottom = hoverFrameYBottom - frameYBottom;

    if (distanceTop > 0) {
      yGuides.push({ y: hoverFrame.y, height: distanceTop, topToBottom: false });
    } else if (distanceTop < 0) {
      yGuides.push({ y: frame.y, height: distanceTop * -1, topToBottom: true });
    }

    if (distanceBottom > 0) {
      yGuides.push({ y: frameYBottom, height: distanceBottom, topToBottom: true });
    } else if (distanceBottom < 0) {
      yGuides.push({ y: hoverFrameYBottom, height: distanceBottom * -1, topToBottom: false });
    }
  } else if (frame.y > hoverFrameYBottom) {
    const distanceBottom = frame.y - hoverFrameYBottom;
    yGuides.push({ y: hoverFrameYBottom, height: distanceBottom, topToBottom: false });
  }

  yGuides.forEach(guide => {
    verticalGuides.push({
      x: frameXCenter,
      y: guide.y,
      length: guide.height,
      label: `${round(guide.height, 2)}`,
      color: ViewEditorGuideColor.Blue,
      startSymbol: ViewEditorGuideSymbol.Line,
      endSymbol: ViewEditorGuideSymbol.Line
    });

    if (frameXCenter < hoverFrame.x) {
      horizontalGuides.push({
        x: frame.x,
        y: guide.topToBottom ? guide.y + guide.height : guide.y,
        length: hoverFrame.x - frame.x,
        dashed: true
      });
    } else if (frameXCenter > hoverFrameXRight) {
      horizontalGuides.push({
        x: hoverFrameXRight,
        y: guide.topToBottom ? guide.y + guide.height : guide.y,
        length: frameXRight - hoverFrameXRight,
        dashed: true
      });
    }
  });

  return {
    horizontalGuides: horizontalGuides,
    verticalGuides: verticalGuides
  };
}

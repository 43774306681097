import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, Input } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';

import { BlendingMode } from './blending-mode';
import { Constraints } from './constraints';
import { FlexLayout } from './flex-layout';
import { Frame } from './frame';
import { CursorLayerInteraction, deserializeLayerInteraction, LayerInteraction } from './layer-interaction';
import { LayerInteractionType } from './layer-interaction';
import { LayerType } from './layer-type';
import { deserializerLayer } from './layer-types';
import { Translate } from './translate';

export const defaultConstraints = new Constraints();

export class Layer {
  public type: LayerType;
  public id: string;
  public name: string;
  public icon: string;
  public defaultName: string;
  public frame: Frame;
  public widthFluid = false;
  public heightFluid = false;
  public absoluteLayout = false;
  public constrainProportion: number;
  public reflectHorizontal = false;
  public reflectVertical = false;
  public constraints: Constraints;
  public opacity = 1;
  public blendingMode: BlendingMode = BlendingMode.Normal;
  public visible = true;
  public visibleInput: Input;
  public interactions: LayerInteraction[] = [];

  constructor(options: Partial<Layer> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.id = data['id'];
    this.name = data['name'];
    this.opacity = data['opacity'];

    if (data['frame']) {
      this.frame = new Frame().deserialize(data['frame']);
    }

    if (data['constrain_proportion']) {
      this.constrainProportion = data['constrain_proportion'];
    }

    if (data['reflect_horizontal'] != undefined) {
      this.reflectHorizontal = data['reflect_horizontal'];
    }

    if (data['reflect_vertical'] != undefined) {
      this.reflectVertical = data['reflect_vertical'];
    }

    if (data['constraints']) {
      this.constraints = new Constraints().deserialize(data['constraints']);
    }

    if (isSet(data['blending_mode'])) {
      this.blendingMode = data['blending_mode'];
    }

    if (isSet(data['visible'])) {
      this.visible = data['visible'];
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    }

    if (data['interactions']) {
      this.interactions = data['interactions'].map(item => deserializeLayerInteraction(item));
    }

    if (!this.id) {
      this.generateId();
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      frame: this.frame ? this.frame.serialize() : undefined,
      constrain_proportion: this.constrainProportion,
      reflect_horizontal: this.reflectHorizontal,
      reflect_vertical: this.reflectVertical,
      constraints: this.constraints ? this.constraints.serialize() : undefined,
      opacity: this.opacity,
      blending_mode: this.blendingMode,
      visible: this.visible,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
      interactions: this.interactions.map(item => item.serialize())
    };
  }

  generateId() {
    this.id = generateAlphanumeric(8, { letterFirst: true });
  }

  isSame(layer: Layer): boolean {
    return this.id == layer.id;
  }

  getConstraintsOrDefault(): Constraints {
    return this.constraints || defaultConstraints;
  }

  cssMixBlendMode(): string {
    return this.blendingMode;
  }

  hasDisableTextSelectionInteraction(): boolean {
    return this.interactions.some(item => item.type == LayerInteractionType.DisableTextSelection);
  }

  getCursor(): string {
    const interaction = this.interactions.find(item => item.type == LayerInteractionType.Cursor);

    if (interaction instanceof CursorLayerInteraction) {
      return interaction.cursor;
    }
  }

  visible$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<boolean> {
    if (this.visibleInput) {
      return applyBooleanInput$(this.visibleInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      }).pipe(catchError(() => of(false)));
    } else {
      return of(true);
    }
  }

  applyMouseEventsFrame(frame: Frame, options: { translate?: Translate; xInverse?: boolean; yInverse?: boolean } = {}) {
    const translateX = options.translate ? options.translate.x : undefined;
    const translateY = options.translate ? options.translate.y : undefined;

    this.frame.patch({
      ...frame,
      x: frame.x - (translateX || 0),
      y: frame.y - (translateY || 0)
    });
  }
}

export interface IContainerLayer {
  layers: Layer[];
  layersOpened: boolean;
}

export class ContainerLayer extends Layer implements IContainerLayer {
  public layers: Layer[] = [];
  public layersOpened = false;
  public flexLayout: FlexLayout;

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['layers']) {
      this.layers = data['layers'].map(item => deserializerLayer(item)).filter(item => item);
    }

    if (isSet(data['layers_opened'])) {
      this.layersOpened = data['layers_opened'];
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      layers: this.layers.map(item => item.serialize()),
      layers_opened: this.layersOpened
    };
  }
}

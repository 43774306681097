import { Observable, of } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { Color } from './color';

export class GradientStop {
  id: string;
  position = 0;
  color: Color;
  colorInput: Input;

  constructor(options: Partial<GradientStop> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.id = data['id'];
    this.position = data['position'];

    if (data['color']) {
      this.color = new Color().deserialize(data['color']);
    }

    if (data['color_input']) {
      this.colorInput = new Input().deserialize(data['color_input']);
    }

    if (!this.id) {
      this.generateId();
    }

    return this;
  }

  serialize(): Object {
    return {
      id: this.id,
      position: this.position,
      color: this.color ? this.color.serialize() : undefined,
      color_input: this.colorInput ? this.colorInput.serialize() : null
    };
  }

  generateId() {
    this.id = generateAlphanumeric(4, { letterFirst: true });
  }

  cssColor$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    if (this.colorInput) {
      return applyParamInput$(this.colorInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });
    } else if (this.color) {
      return of(this.color.css());
    }
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { AggregateFunc } from '@modules/charts';
import { BaseField, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { isSet } from '@shared';
import { ModelOptionsSource } from '../../services/model-options-source';
var SelectModelFieldComponent = /** @class */ (function () {
    function SelectModelFieldComponent(modelDescriptionStore, modelOptionsSource, cd) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelOptionsSource = modelOptionsSource;
        this.cd = cd;
        this.relationsEnabled = true;
        this.onlyNestedFields = false;
        this.fieldsSelectEnabled = true;
        this.relationsSelectEnabled = true;
        this.aggregationsEnabled = false;
        this.path = [];
        this.nameSelected = new EventEmitter();
        this.selected = new EventEmitter();
        this.back = new EventEmitter();
        this.loading = true;
        this.searchControl = new FormControl('');
        this.maxRelationsDepth = 4;
        this.options = [];
        this.filteredFieldOptions = [];
        this.filteredRelationOptions = [];
        this.selectedOptionPath = [];
        this.aggregations = [
            {
                func: AggregateFunc.Count,
                label: function (name) { return "Count number of " + name; }
            },
            {
                func: AggregateFunc.Sum,
                label: function (name) { return "Sum of " + name + " field"; },
                fieldDropdown: true
            },
            {
                func: AggregateFunc.Min,
                label: function (name) { return "Minimum of " + name + " field"; },
                fieldDropdown: true
            },
            {
                func: AggregateFunc.Max,
                label: function (name) { return "Maximum of " + name + " field"; },
                fieldDropdown: true
            },
            {
                func: AggregateFunc.Avg,
                label: function (name) { return "Average of " + name + " field"; },
                fieldDropdown: true
            }
        ].map(function (item) {
            return __assign({}, item, { fieldDropdownOpened$: new BehaviorSubject(false) });
        });
        this.selectedAggregationIndex$ = new BehaviorSubject(undefined);
        this.fieldDropdownPositions = [
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center',
                offsetX: 0
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center',
                offsetX: 0
            }
        ];
    }
    SelectModelFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFilteredOptions(); });
    };
    SelectModelFieldComponent.prototype.ngOnDestroy = function () { };
    SelectModelFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes.modelDescription || changes.fields) {
            this.initOptions();
            this.updateFilteredOptions();
        }
    };
    SelectModelFieldComponent.prototype.initOptions = function () {
        var _this = this;
        if (this.optionsSubscription) {
            this.optionsSubscription.unsubscribe();
            this.optionsSubscription = undefined;
        }
        if (this.modelDescription) {
            this.optionsSubscription = this.modelOptionsSource
                .getOptions$(this.modelDescription, {
                path: this.path,
                onlyNestedFields: this.onlyNestedFields,
                relationsEnabled: this.relationsEnabled,
                maxRelationsDepth: this.maxRelationsDepth
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (options) {
                _this.options = options
                    .filter(function (item) {
                    if (item.relatedModelDescription) {
                        return true;
                    }
                    else {
                        return _this.isSelectAllowed(item);
                    }
                })
                    .filter(function (item) {
                    if (_this.optionsFilter) {
                        return _this.optionsFilter(item);
                    }
                    else {
                        return true;
                    }
                });
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
        else if (this.fields) {
            var depth_1 = this.path.length + 1;
            return this.modelDescriptionStore
                .get()
                .pipe(untilDestroyed(this))
                .subscribe(function (modelDescriptions) {
                _this.options = _this.fields
                    .filter(function () { return _this.fieldsSelectEnabled; })
                    .filter(function (field) {
                    if (!_this.onlyNestedFields) {
                        return true;
                    }
                    if (depth_1 == 1) {
                        return field.field == FieldType.RelatedModel;
                    }
                    else {
                        return true;
                    }
                })
                    .map(function (field) {
                    var fieldDescription = getFieldDescriptionByType(field.field);
                    var relatedModelDescription;
                    if (field.field == FieldType.RelatedModel && _this.relationsEnabled && depth_1 < _this.maxRelationsDepth) {
                        var relatedModelId_1 = field.params ? field.params['related_model']['model'] : undefined;
                        relatedModelDescription = relatedModelId_1
                            ? modelDescriptions.find(function (item) { return item.isSame(relatedModelId_1); })
                            : undefined;
                    }
                    return {
                        name: field.name,
                        verboseName: field.verboseName || field.name,
                        icon: fieldDescription.icon,
                        field: field,
                        relatedModelDescription: relatedModelDescription
                    };
                })
                    .filter(function (item) {
                    if (_this.optionsFilter) {
                        return _this.optionsFilter(item);
                    }
                    else {
                        return true;
                    }
                });
                _this.loading = false;
                _this.cd.markForCheck();
            });
        }
        else {
            this.options = [];
            this.loading = false;
            this.cd.markForCheck();
        }
    };
    SelectModelFieldComponent.prototype.getFilteredOptions = function (options) {
        var _this = this;
        if (this.onlyFields) {
            options = options.filter(function (option) { return _this.onlyFields.find(function (item) { return item.name == option.name; }); });
        }
        var search = this.searchControl.value.toLowerCase().trim();
        if (!isSet(search)) {
            return options;
        }
        return options.filter(function (option) {
            return [option.verboseName, option.name].some(function (item) {
                return isSet(item) && item.toLowerCase().includes(search);
            });
        });
    };
    SelectModelFieldComponent.prototype.updateFilteredOptions = function () {
        var options = this.getFilteredOptions(this.options);
        this.filteredFieldOptions = options.filter(function (item) { return item.field; });
        this.filteredRelationOptions = options.filter(function (item) { return item.relation; });
        this.cd.markForCheck();
    };
    SelectModelFieldComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
        this.updateFilteredOptions();
    };
    SelectModelFieldComponent.prototype.setSelectedFieldOption = function (item) {
        this.selectedOption = item;
        this.selectedOptionPath = item ? this.path.concat([item]) : undefined;
        this.cd.markForCheck();
    };
    SelectModelFieldComponent.prototype.isSelectAllowed = function (option) {
        if (option.field) {
            return this.fieldsSelectEnabled;
        }
        else if (option.relatedModelDescription) {
            return this.relationsSelectEnabled;
        }
        else {
            return true;
        }
    };
    SelectModelFieldComponent.prototype.selectOption = function (selectItem, options) {
        if (options === void 0) { options = {}; }
        options = defaults(options, { appendPath: true });
        var mapPath = function (item) {
            return {
                name: item.name,
                verboseName: item.verboseName,
                icon: item.icon,
                field: item.field,
                relation: item.relation
            };
        };
        var path = options.appendPath
            ? this.path.map(function (item) { return mapPath(item); }).concat([mapPath(selectItem)]) : this.path.map(function (item) { return mapPath(item); });
        this.nameSelected.emit(selectItem.name);
        this.selected.emit({
            name: selectItem.name,
            verboseName: selectItem.verboseName,
            field: selectItem.field,
            relation: selectItem.relation,
            path: path,
            aggregation: options.aggregation
        });
    };
    return SelectModelFieldComponent;
}());
export { SelectModelFieldComponent };

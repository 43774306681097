import { Injector } from '@angular/core';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ViewSettingsStore } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ResourceSummaryComponent } from '../../components/resource-summary/resource-summary.component';
var ResourceSummaryService = /** @class */ (function () {
    function ResourceSummaryService(modelDescriptionStore, actionStore, viewSettingsStore, popupService, injector) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.popupService = popupService;
        this.injector = injector;
    }
    ResourceSummaryService.prototype.open = function (resource) {
        var _this = this;
        return this.getContent(resource).pipe(switchMap(function (content) {
            var result = new ReplaySubject();
            if (content.count) {
                _this.popupService.push({
                    component: ResourceSummaryComponent,
                    popupComponent: ThinDialogPopupComponent,
                    inputs: {
                        tabs: _this.getSections(content),
                        resource: resource
                    },
                    popupClosed: function () {
                        result.next(content);
                    },
                    injector: _this.injector
                });
            }
            else {
                result.next(content);
            }
            return result;
        }));
    };
    ResourceSummaryService.prototype.getContent = function (resource) {
        return combineLatest(this.modelDescriptionStore
            .getFirst()
            .pipe(map(function (result) { return result.filter(function (item) { return item.resource == resource.uniqueName; }); })), this.actionStore.getFirst().pipe(map(function (result) { return result.filter(function (item) { return item.resource == resource.uniqueName; }); })), this.viewSettingsStore
            .getFirst()
            .pipe(map(function (result) { return result.filter(function (item) { return item.uniqueName && item.resource == resource.uniqueName; }); }))).pipe(map(function (_a) {
            var modelDescriptions = _a[0], actionDescriptions = _a[1], viewSettings = _a[2];
            return {
                models: modelDescriptions,
                actions: actionDescriptions,
                pages: viewSettings,
                count: [modelDescriptions, actionDescriptions, viewSettings]
                    .filter(function (item) { return item != undefined; })
                    .reduce(function (acc, item) { return acc + item.length; }, 0)
            };
        }));
    };
    ResourceSummaryService.prototype.getSections = function (content) {
        var tabs = [];
        if (content.models && content.models.length) {
            tabs.push({
                name: 'Lists',
                items: content.models.map(function (item) {
                    return {
                        name: item.verboseNamePlural,
                        description: item.description,
                        icon: 'collection'
                    };
                })
            });
        }
        if (content.actions && content.actions.length) {
            tabs.push({
                name: 'Buttons',
                items: content.actions.map(function (item) {
                    return {
                        name: item.verboseName,
                        description: item.description,
                        icon: 'action'
                    };
                })
            });
        }
        if (content.pages && content.pages.length) {
            tabs.push({
                name: 'Pages',
                items: content.pages.map(function (item) {
                    return {
                        name: item.name,
                        // description: item.description,
                        icon: 'page'
                    };
                })
            });
        }
        // if (modelDescriptions && modelDescriptions.length) {
        //   tabs.push({
        //     name: 'Pages',
        //     items: flatten(
        //       modelDescriptions.map(item => {
        //         return [
        //           {
        //             name: `${item.verboseNamePlural} - List`,
        //             icon: 'page'
        //           },
        //           {
        //             name: `${item.verboseNamePlural} - Create`,
        //             icon: 'page'
        //           },
        //           {
        //             name: `${item.verboseNamePlural} - Update`,
        //             icon: 'page'
        //           }
        //         ];
        //       })
        //     )
        //   });
        // }
        return tabs;
    };
    return ResourceSummaryService;
}());
export { ResourceSummaryService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "../../../../shared/pipes/safe-url/safe-url.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./iframe-element.component";
import * as i6 from "../../../customize/services/customize/customize.service";
var styles_IFrameElementComponent = [];
var RenderType_IFrameElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IFrameElementComponent, data: {} });
export { RenderType_IFrameElementComponent as RenderType_IFrameElementComponent };
function View_IFrameElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iframe", [["class", "embed-element"]], [[2, "embed-element_border", null], [8, "src", 5]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.border; var currVal_1 = i0.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 1), _co.embedUrl)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_IFrameElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), i0.ɵpid(0, i2.SafeUrlPipe, [i3.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_IFrameElementComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.visible || i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.customizeEnabled$))) && i0.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.embedUrl))); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_IFrameElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-iframe-element", [], null, null, null, View_IFrameElementComponent_0, RenderType_IFrameElementComponent)), i0.ɵdid(1, 770048, null, 0, i5.IFrameElementComponent, [i6.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IFrameElementComponentNgFactory = i0.ɵccf("app-iframe-element", i5.IFrameElementComponent, View_IFrameElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { IFrameElementComponentNgFactory as IFrameElementComponentNgFactory };

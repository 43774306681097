var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectGroupService } from '../services/project-group/project-group.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var ProjectGroupSource = /** @class */ (function (_super) {
    __extends(ProjectGroupSource, _super);
    function ProjectGroupSource(currentProjectStore, currentEnvironmentStore, projectGroupService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectGroupService = projectGroupService;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    ProjectGroupSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        return this.projectGroupService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(map(function (result) {
            _this.page += 1;
            return result
                .filter(function (item) { return !searchQuery || item.name.toLowerCase().includes(searchQuery.toLowerCase()); })
                .map(function (item) {
                return {
                    value: item.uid,
                    name: item.name
                };
            });
        }));
    };
    ProjectGroupSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.projectGroupService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(map(function (result) {
            var project = result.find(function (item) { return item.uid == value; });
            if (!project) {
                return;
            }
            return {
                value: project.uid,
                name: project.name
            };
        }));
    };
    ProjectGroupSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    ProjectGroupSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    ProjectGroupSource.prototype.setStaticOptions = function (options) { };
    return ProjectGroupSource;
}(SelectSource));
export { ProjectGroupSource };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { CollapseElementItem, CollapseIndicatorPosition } from '@modules/customize';
import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

import { MarginControl } from '../margin-control/margin-control.component';

@Injectable()
export class CustomizeBarCollapseEditForm extends FormGroup {
  controls: {
    name: FormControl;
    title: FieldInputControl;
    icon: FormControl;
    opened_input: FieldInputControl;
    tooltip: FormControl;
    collapse_indicator_position: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  element: CollapseElementItem;

  collapseIndicatorPositionOptions: Option<CollapseIndicatorPosition>[] = [
    {
      value: CollapseIndicatorPosition.None,
      name: 'None'
    },
    {
      value: CollapseIndicatorPosition.Left,
      name: 'Left'
    },
    {
      value: CollapseIndicatorPosition.Right,
      name: 'Right'
    }
  ];

  constructor() {
    super({
      name: new FormControl(''),
      title: new FieldInputControl({ name: 'value' }),
      icon: new FormControl(null),
      opened_input: new FieldInputControl({ name: 'value' }),
      tooltip: new FormControl(''),
      collapse_indicator_position: new FormControl(CollapseIndicatorPosition.Right),
      visible_input: new FieldInputControl({ name: 'value' }),
      margin: new MarginControl()
    });
  }

  init(element: CollapseElementItem) {
    this.element = element;

    this.controls.name.patchValue(element.name ? element.name : 'Collapse');
    this.controls.title.patchValue(element.titleInput ? element.titleInput.serialize() : {});
    this.controls.icon.patchValue(element.icon);
    this.controls.opened_input.patchValue(element.openedInput ? element.openedInput.serialize() : {});
    this.controls.tooltip.patchValue(element.tooltip);
    this.controls.collapse_indicator_position.patchValue(
      element.collapseIndicatorPosition || CollapseIndicatorPosition.Right
    );
    this.controls.margin.patchValue(element.margin);
    this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});

    this.markAsPristine();
  }

  submit(): CollapseElementItem {
    const instance = cloneDeep(this.element) as CollapseElementItem;

    instance.name = this.controls.name.value;
    instance.titleInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.icon = this.controls.icon.value;

    if (this.controls.opened_input.value) {
      instance.openedInput = new Input().deserialize(this.controls.opened_input.value);
    } else {
      instance.openedInput = undefined;
    }

    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    instance.collapseIndicatorPosition = this.controls.collapse_indicator_position.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    instance.margin = this.controls.margin.value;

    return instance;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogsModule } from '@common/dialogs';
import { NotificationsModule } from '@common/notifications';
import { ApiModule } from '@modules/api';

@NgModule({
  imports: [CommonModule, ApiModule, NotificationsModule, DialogsModule]
})
export class ProjectApiModule {}

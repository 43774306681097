/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i3 from "../../../fields/components/auto-field/auto-field.component";
import * as i4 from "./input-token-value.component";
var styles_InputTokenValueComponent = [];
var RenderType_InputTokenValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputTokenValueComponent, data: {} });
export { RenderType_InputTokenValueComponent as RenderType_InputTokenValueComponent };
function View_InputTokenValueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.selectedToken.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InputTokenValueComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i2.View_AutoFieldComponent_0, i2.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i3.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], autofocus: [3, "autofocus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.formField; var currVal_2 = false; var currVal_3 = _co.autofocus; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_InputTokenValueComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTokenValue.value.name; _ck(_v, 1, 0, currVal_0); }); }
function View_InputTokenValueComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "input__placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Example file for test request "]))], null, null); }
function View_InputTokenValueComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "button button_orange button_small"], ["href", "javascript:void(0)"], ["style", "margin: 0 8px 0 0;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedTokenValue.patchValue(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "icon icon-bin button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Clear file"]))], null, null); }
function View_InputTokenValueComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Change file"]))], null, null); }
function View_InputTokenValueComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose file"]))], null, null); }
function View_InputTokenValueComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "input input_fill input_small"]], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["style", "margin-top: 8px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "input", [["class", "input input_file"], ["type", "file"]], [[8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_6)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 6, "label", [["class", "button button_orange button_small"], ["style", "margin: 0 8px 0 0;"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "icon icon-cloud_upload button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_7)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_8)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedTokenValue.value; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.selectedTokenValue.value; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.selectedTokenValue.value; _ck(_v, 9, 0, currVal_4); var currVal_6 = _co.selectedTokenValue.value; _ck(_v, 14, 0, currVal_6); var currVal_7 = !_co.selectedTokenValue.value; _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = 300; _ck(_v, 1, 0, currVal_0); var currVal_3 = "file_upload"; _ck(_v, 7, 0, currVal_3); var currVal_5 = "file_upload"; _ck(_v, 10, 0, currVal_5); }); }
export function View_InputTokenValueComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "token-popup__item token-popup__border-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "token-popup-list-item__inner token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "button button_orange button_icon button_extra-small"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button__icon icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(9, null, [" ", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_2)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokenValueComponent_3)), i0.ɵdid(17, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedToken; _ck(_v, 8, 0, currVal_0); var currVal_2 = (!_co.fileInput && _co.formField); _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.fileInput; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedToken.label || _co.selectedToken.name); _ck(_v, 9, 0, currVal_1); }); }
export function View_InputTokenValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-token-value", [], null, null, null, View_InputTokenValueComponent_0, RenderType_InputTokenValueComponent)), i0.ɵdid(1, 638976, null, 0, i4.InputTokenValueComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputTokenValueComponentNgFactory = i0.ɵccf("app-input-token-value", i4.InputTokenValueComponent, View_InputTokenValueComponent_Host_0, { selectedTokenValue: "selectedTokenValue", selectedToken: "selectedToken", useFileObjects: "useFileObjects", autofocus: "autofocus" }, { back: "back", submit: "submit" }, []);
export { InputTokenValueComponentNgFactory as InputTokenValueComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Frame, getFramePointPosition, getLinePointsWithOrientation, PointTranslate } from '@modules/views';
import { isSet } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { snapPoint } from '../../../utils/guides';
import { getLineOrientation } from '../line/line-layer.component';
var LineLayerBoundsComponent = /** @class */ (function () {
    function LineLayerBoundsComponent(editorContext) {
        this.editorContext = editorContext;
        this.translate = { x: 0, y: 0 };
        this.points = [];
        this.fromX = 0;
        this.fromY = 0;
        this.length = 0;
        this.snapFrame = false;
        this.active = false;
        this.hover = false;
        this.resizeStarted = new EventEmitter();
        this.resizeFinished = new EventEmitter();
        this.updateLayer = new EventEmitter();
        this.updateLayerRaw = new Subject();
    }
    LineLayerBoundsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateLayerRaw
            .pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), untilDestroyed(this))
            .subscribe(function (value) { return _this.updateLayer.emit(value); });
    };
    LineLayerBoundsComponent.prototype.ngOnDestroy = function () { };
    LineLayerBoundsComponent.prototype.onPointResizeStarted = function () {
        this.resizePositions = {
            from: getFramePointPosition(this.frame, this.points[0]),
            to: getFramePointPosition(this.frame, this.points[1])
        };
        this.resizeStarted.emit();
        if (this.snapFrame) {
            this.resizeSnapFrames = this.editorContext.getFrames({
                onlyContainers: [this.snapFrameContainer],
                exceptLayers: this.snapFrameExceptLayers
            });
            this.editorContext.removeHorizontalGuides();
            this.editorContext.removeVerticalGuides();
        }
    };
    LineLayerBoundsComponent.prototype.snapPoint = function (point) {
        var snap = snapPoint({
            point: new PointTranslate({
                point: { x: point.x, y: point.y },
                translate: this.translate
            }),
            otherFrames: this.resizeSnapFrames
        });
        if (isSet(snap.updatePoint.x)) {
            point.x = snap.updatePoint.x;
        }
        if (isSet(snap.updatePoint.y)) {
            point.y = snap.updatePoint.y;
        }
        this.editorContext.setHorizontalGuides(snap.horizontalGuides);
        this.editorContext.setVerticalGuides(snap.verticalGuides);
    };
    LineLayerBoundsComponent.prototype.applyPointResize = function (event, end) {
        var positionFrom = __assign({}, this.resizePositions.from);
        var positionTo = __assign({}, this.resizePositions.to);
        var pointNew = end ? positionTo : positionFrom;
        pointNew.x = round(pointNew.x + event.deltaX, 2);
        pointNew.y = round(pointNew.y + event.deltaY, 2);
        if (this.snapFrame) {
            this.snapPoint(pointNew);
        }
        var _a = getLineOrientation(positionFrom, positionTo), xInverseNew = _a.xInverse, yInverseNew = _a.yInverse;
        var pointsNew = getLinePointsWithOrientation(xInverseNew, yInverseNew);
        var frame;
        if (!xInverseNew && !yInverseNew) {
            // from top left
            frame = {
                x: positionFrom.x,
                y: positionFrom.y,
                width: positionTo.x - positionFrom.x,
                height: positionTo.y - positionFrom.y
            };
        }
        else if (xInverseNew && !yInverseNew) {
            // from top right
            frame = {
                x: positionTo.x,
                y: positionFrom.y,
                width: positionFrom.x - positionTo.x,
                height: positionTo.y - positionFrom.y
            };
        }
        else if (xInverseNew && yInverseNew) {
            // from bottom right
            frame = {
                x: positionTo.x,
                y: positionTo.y,
                width: positionFrom.x - positionTo.x,
                height: positionFrom.y - positionTo.y
            };
        }
        else if (!xInverseNew && yInverseNew) {
            // from bottom left
            frame = {
                x: positionFrom.x,
                y: positionTo.y,
                width: positionTo.x - positionFrom.x,
                height: positionFrom.y - positionTo.y
            };
        }
        var bounds = {
            frame: frame,
            points: pointsNew
        };
        this.resizeNewBounds = bounds;
        this.updateLayerRaw.next(bounds);
    };
    LineLayerBoundsComponent.prototype.onPointResizeFinished = function () {
        var newBounds = this.resizeNewBounds;
        this.resizeSnapFrames = undefined;
        this.resizePositions = undefined;
        this.resizeNewBounds = undefined;
        this.resizeFinished.emit();
        if (newBounds) {
            this.updateLayer.emit(newBounds);
        }
        if (this.snapFrame) {
            this.editorContext.removeHorizontalGuides();
            this.editorContext.removeVerticalGuides();
        }
    };
    return LineLayerBoundsComponent;
}());
export { LineLayerBoundsComponent };

import * as Color from 'color';

// TODO: Refactor import
import { isSet } from '../../../shared/utils/common/common';
import { numberToHex } from '../../../shared/utils/numbers/numbers';

import { colors } from '../data/colors';

export function getColorHex(color: string): string | number {
  if (!color) {
    return;
  }

  if (color.startsWith('#')) {
    return color;
  } else if (!color.startsWith('#')) {
    const predefinedColor = colors.find(item => item.name == color);
    if (predefinedColor) {
      return predefinedColor.hex;
    }
  }
}

export function getColorHexStr(color: string): string {
  const result = getColorHex(color);

  if (!isSet(result)) {
    return;
  }

  if (typeof result === 'number') {
    return '#' + numberToHex(result);
  } else {
    return result;
  }
}

export function isLightColor(color: string): boolean {
  if (!color) {
    return false;
  }

  const colorHex = getColorHex(color);

  if (!colorHex) {
    return false;
  }

  try {
    const clr = Color(colorHex);
    const isDark = clr.contrast(Color('white')) >= 2;

    return !isDark;
  } catch (e) {
    return false;
  }
}

export function parseColor(color: string | number, defaultColor?: string | number): Color {
  if (isSet(color)) {
    try {
      return Color(color);
    } catch (e) {}
  }

  if (isSet(defaultColor)) {
    return Color(defaultColor);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tip-hotkey',
  templateUrl: './tip-hotkey.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipHotkeyComponent implements OnInit {
  @Input() key: string;
  @Input() shiftKey = false;
  @Input() altKey = false;
  @Input() metaKey = false;

  isMac: boolean;

  constructor() {}

  ngOnInit() {
    this.isMac = this.getIsMac();
  }

  getIsMac() {
    return navigator.platform.includes('Mac');
  }
}

<div class="projects theme_dark">
  <div class="projects__background"></div>
  <div class="projects__inner">
    <div class="projects__header">
      <app-header [isWhiteLabel]="isWhiteLabel" [analyticsSource]="'projects_header'">
        <div *ngIf="!loading && (projects$ | async)?.length > 0" class="projects-search desktop-only">
          <input
            type="text"
            class="projects-search__input"
            [formControl]="searchControl"
            [placeholder]="'Type to search...' | localize"
            [appAutofocus]="true"
            (keyup)="onSearchKey($event)"
          />
          <span class="projects-search__icon icon-search"></span>
          <span
            *ngIf="searchControl.value | appIsSet"
            class="projects-search__close icon-close"
            (click)="resetSearch()"
          ></span>
        </div>
      </app-header>
    </div>

    <div class="projects__content projects__content_center">
      <!--      <div class="projects__content-inner">-->
      <!--        <div class="projects__info">-->
      <!--          <div class="projects__subtitle">Your apps</div>-->
      <!--          <div class="projects__title">Welcome back, {{ (currentUserStore.instance$ | async)?.strShort }}!</div>-->
      <!--          <div class="projects__description">-->
      <!--            <div class="projects__description-inner">-->
      <!--              <ng-container *ngIf="!loading">-->
      <!--                Select the app you want to work on or create a new one.-->
      <!--              </ng-container>-->
      <!--              <app-loader *ngIf="loading">-->
      <!--                Loading your apps, please, wait...-->
      <!--              </app-loader>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->

      <ng-container *ngIf="loading">
        <div class="projects__element">
          <div class="projects-section">
            <div class="projects-section__title">
              <span [class.loading-animation]="true">
                <span class="stub-text">My Apps</span>
              </span>
            </div>
            <div class="projects-section__content">
              <div class="projects-list">
                <div *ngFor="let item of [1, 2, 3]" class="projects-list__item">
                  <div class="projects-list-item">
                    <div class="projects-list-item__logo">
                      <span [class.loading-animation]="true">
                        <span class="stub-image stub-image_size_l"></span>
                      </span>
                    </div>
                    <div class="projects-list-item__title">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">App</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="projects__element desktop-only">
          <div class="projects-section projects-section_background">
            <div class="projects-section__title">
              <span [class.loading-animation]="true">
                <span class="stub-text">Templates</span>
              </span>
            </div>
            <div class="projects-section__content">
              <div class="projects-list">
                <div *ngFor="let item of [1, 2, 3, 4, 5]" class="projects-list__item">
                  <div class="projects-list-item">
                    <div class="projects-list-item__logo">
                      <span [class.loading-animation]="true">
                        <span class="stub-image stub-image_size_l"></span>
                      </span>
                    </div>
                    <div class="projects-list-item__title">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">App</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading">
        <!--        <div *ngIf="'projects_tutorial' | appBannerVisible" class="projects__banner">-->
        <!--          <div class="projects-banner">-->
        <!--            <div class="projects-banner__left">-->
        <!--              <span class="projects-banner__icon"></span>-->
        <!--            </div>-->
        <!--            <div class="projects-banner__main">-->
        <!--              <div class="projects-banner__title">Learn Jet Admin in 5 minutes</div>-->
        <!--              <div class="projects-banner__description">-->
        <!--                Walk through the core concepts by building an app with real data.-->
        <!--                <a href="javascript:void(0)" class="projects-run-tutorial"-->
        <!--                  >Take the tutorial <span class="icon-arrow_forward_2"></span-->
        <!--                ></a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <a-->
        <!--              href="javascript:void(0)"-->
        <!--              class="projects-banner__close icon-close"-->
        <!--              [appHideBanner]="'projects_tutorial'"-->
        <!--            ></a>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="projects__element">
          <div class="projects-section">
            <div class="projects-section__title">
              <ng-container *ngIf="openBuilderLink">{{ 'Choose App' | localize }}</ng-container>
              <ng-container *ngIf="!openBuilderLink">{{ 'My Apps' | localize }}</ng-container>
            </div>
            <div class="projects-section__content">
              <div
                class="projects-list"
                [class.projects-list_hovered]="itemHovered"
                (mouseenter)="setItemHovered(true)"
                (mouseleave)="setItemHovered(false)"
              >
                <div *ngIf="!isWhiteLabel" class="projects-list__item">
                  <div class="projects-list-item projects-list-item_interactive" (click)="createNewProject()">
                    <div class="projects-list-item__logo">
                      <app-project-logo
                        [color]="!projectsFiltered?.length ? 'blue' : ''"
                        [icon]="'icon-plus'"
                        [isButton]="true"
                      ></app-project-logo>
                    </div>
                    <div class="projects-list-item__title">{{ 'New App' | localize }}</div>
                  </div>
                </div>

                <app-projects-item
                  *ngFor="let item of projectsFiltered || []; trackBy: projectsTrackByFn"
                  class="projects-list__item"
                  [project]="item"
                  [externalToken]="externalToken"
                  [openBuilderLink]="openBuilderLink"
                ></app-projects-item>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="featuredTemplatesFiltered?.length && !isWhiteLabel" class="projects__element desktop-only">
          <div class="projects-section projects-section_background">
            <div class="projects-section__title">{{ 'Start from a template' | localize }}</div>
            <div class="projects-section__content">
              <div
                class="projects-list"
                [class.projects-list_hovered]="itemHovered"
                (mouseenter)="setItemHovered(true)"
                (mouseleave)="setItemHovered(false)"
              >
                <div
                  *ngFor="let item of featuredTemplatesFiltered; trackBy: templatesTrackByFn"
                  class="projects-list__item"
                >
                  <a
                    href="javascript:void(0)"
                    class="projects-list-item projects-list-item_interactive"
                    (click)="previewTemplate(item)"
                  >
                    <div class="projects-list-item__logo">
                      <app-project-logo
                        class="projects-list-item__logo-inner projects-list-item__logo-inner_fade"
                        [color]="item.color"
                        [logo]="item.logo"
                        [icon]="item.icon ? 'icon-' + item.icon : undefined"
                        [initials]="item.name | appInitials"
                        [fill]="item.logoFill"
                      ></app-project-logo>
                      <div class="projects-list-item__logo-label">
                        <span class="icon-eye projects-list-item__logo-label-icon"></span> {{ 'Preview' | localize }}
                      </div>
                    </div>
                    <div class="projects-list-item__title">{{ item.name }}</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

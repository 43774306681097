var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Border } from '../border';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Point } from '../point';
import { Shadow } from '../shadow';
export function getLinePointsWithOrientation(xInverse, yInverse) {
    if (!xInverse && !yInverse) {
        return { from: { x: 0, y: 0 }, to: { x: 1, y: 1 } };
    }
    else if (!xInverse && yInverse) {
        return { from: { x: 0, y: 1 }, to: { x: 1, y: 0 } };
    }
    else if (xInverse && !yInverse) {
        return { from: { x: 1, y: 0 }, to: { x: 0, y: 1 } };
    }
    else if (xInverse && yInverse) {
        return { from: { x: 1, y: 1 }, to: { x: 0, y: 0 } };
    }
}
var LineLayer = /** @class */ (function (_super) {
    __extends(LineLayer, _super);
    function LineLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Line;
        _this.icon = 'line';
        _this.defaultName = 'line';
        _this.points = [];
        _this.borders = [];
        _this.shadows = [];
        return _this;
    }
    LineLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['points']) {
            this.points = data['points'].map(function (item) { return new Point().deserialize(item); });
        }
        if (data['borders']) {
            this.borders = data['borders'].map(function (item) { return new Border().deserialize(item); });
        }
        if (data['shadows']) {
            this.shadows = data['shadows'].map(function (item) { return new Shadow().deserialize(item); });
        }
        return this;
    };
    LineLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { points: this.points.map(function (item) { return item.serialize(); }), borders: this.borders.map(function (item) { return item.serialize(); }), shadows: this.shadows.map(function (item) { return item.serialize(); }) });
    };
    LineLayer.prototype.applyPoints = function (from, to) {
        var _this = this;
        this.points = [from, to].map(function (point, i) {
            var result = _this.points[i] || new Point();
            result.x = point.x;
            result.y = point.y;
            return result;
        });
    };
    LineLayer.prototype.applyPointsWithOrientation = function (xInverse, yInverse) {
        var points = getLinePointsWithOrientation(xInverse, yInverse);
        this.applyPoints(points.from, points.to);
    };
    LineLayer.prototype.applyMouseEventsFrame = function (frame, options) {
        if (options === void 0) { options = {}; }
        _super.prototype.applyMouseEventsFrame.call(this, frame, options);
        this.applyPointsWithOrientation(options.xInverse, options.yInverse);
    };
    return LineLayer;
}(Layer));
export { LineLayer };
registerLayerType(LayerType.Line, LineLayer);

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-separator',
  templateUrl: './page-separator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

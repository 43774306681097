import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, skip } from 'rxjs/operators';

import { DEFAULT_LANGUAGE, getCurrentLanguage, getLanguages } from '@common/localize';
import { LocalStorage } from '@core';
import { controlValue } from '@shared';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  languageControl = new FormControl(DEFAULT_LANGUAGE);
  languageOptions: { name: string; value: string }[] = [];
  currentLanguageOption: { name: string; value: string };

  constructor(private localStorage: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const language = getCurrentLanguage();

    this.languageControl.setValue(language);
    this.languageOptions = getLanguages();

    controlValue<string>(this.languageControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.currentLanguageOption = this.languageOptions.find(item => item.value == value);
        this.cd.markForCheck();
      });

    controlValue<string>(this.languageControl)
      .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
      .subscribe(value => {
        this.localStorage.setLanguage(value);

        setTimeout(() => window.location.reload(), 200);
      });
  }

  ngOnDestroy(): void {}
}

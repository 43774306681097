import { Inject, Injectable, InjectionToken, Injector, Type } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettings } from '@modules/customize';
import { Resource } from '@modules/projects';

export const PAGE_TEMPLATES_COMPONENT = new InjectionToken<any>('PAGE_TEMPLATES_COMPONENT');

export interface PageTemplatesOptions {
  title?: string;
  resource?: Resource;
  cancelEnabled?: boolean;
  newPage?: boolean;
  analyticsSource?: string;
}

export interface PageTemplatesResult {
  created?: boolean;
  startPage?: ViewSettings;
  newPage?: boolean;
  cancelled?: boolean;
}

@Injectable()
export class PageTemplatesController {
  constructor(
    @Inject(PAGE_TEMPLATES_COMPONENT) private pageTemplatesComponent: Type<any>,
    private injector: Injector,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  open(options: PageTemplatesOptions = {}): Observable<PageTemplatesResult> {
    const obs = new ReplaySubject<PageTemplatesResult>();

    this.popupService.push({
      component: this.pageTemplatesComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentDisableClose: !options.cancelEnabled,
      popupComponentOrange: true,
      popupComponentCloseWithoutConfirm: true,
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs.next({
            cancelled: true
          });

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.Cancelled, {
            Source: options.analyticsSource
          });
        }
      },
      inputs: {
        title: options.title,
        resource: options.resource,
        cancelEnabled: options.cancelEnabled,
        newPage: options.newPage,
        analyticsSource: options.analyticsSource
      },
      outputs: {
        createdPages: [
          result => {
            obs.next({
              created: true,
              startPage: result.startPage
            });
          }
        ],
        createNewPage: [
          () => {
            obs.next({
              newPage: true
            });
          }
        ],
        cancelled: [
          () => {
            obs.next({
              cancelled: true
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.Cancelled, {
              Source: options.analyticsSource
            });
          }
        ]
      },
      injector: this.injector
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.Opened, {
      Source: options.analyticsSource
    });

    return obs.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

import { RoutingService } from '@modules/routing';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsUserAuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService, private routing: RoutingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const canActivate = new ReplaySubject<boolean>();

    this.authService
      .isUserAuthorized()
      .pipe(first())
      .subscribe(
        authorized => canActivate.next(authorized),
        () => canActivate.next(false)
      );

    canActivate.subscribe(allow => {
      if (!allow) {
        this.authService.logout();

        const queryParams = {};
        const url = this.routing.getRouteUrl(route);
        const redirect = encodeURIComponent(
          JSON.stringify({
            url: url,
            params: route.queryParams
          })
        );
        const referrer = route.queryParams['referrer'];

        if (redirect) {
          queryParams['redirect'] = redirect;
        }

        if (referrer) {
          queryParams['referrer'] = referrer;
        }

        const ignoreSelfSignOn = route.data['ignoreSelfSignOn'];

        if (route.data['registerWithoutAuthenticated']) {
          this.routing.navigate(ignoreSelfSignOn ? ['/user/register'] : ['/register'], { queryParams: queryParams });
        } else {
          this.routing.navigate(ignoreSelfSignOn ? ['/user/login'] : ['/login'], { queryParams: queryParams });
        }
      }
    });

    return canActivate;
  }
}

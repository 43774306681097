import { FormArray } from '@angular/forms';
export { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

export function moveItemInFormArray(array: FormArray, fromIndex: number, toIndex: number): void {
  if (fromIndex === toIndex) {
    return;
  }

  const control = array.at(fromIndex);
  array.removeAt(fromIndex);
  array.insert(toIndex, control);
}

export function transferFormArrayItem(
  currentArray: FormArray,
  targetArray: FormArray,
  currentIndex: number,
  targetIndex: number
): void {
  const control = currentArray.at(currentIndex);
  currentArray.removeAt(currentIndex);
  targetArray.insert(targetIndex, control);
}

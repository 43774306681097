import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { PopupRef } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { EmailTemplate } from '@modules/emails';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { EmailTemplateEditForm } from './email-template-edit.form';

@Component({
  selector: 'app-email-template-edit-popup',
  templateUrl: './email-template-edit-popup.component.html',
  providers: [EmailTemplateEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailTemplateEditPopupComponent implements OnInit, OnDestroy {
  @Input() emailTemplate: EmailTemplate;
  @Input() baseEmailTemplate: EmailTemplate;
  @Input() analyticsSource: string;
  @Output() created = new EventEmitter<EmailTemplate>();
  @Output() updated = new EventEmitter<EmailTemplate>();

  loading = false;
  submitLoading = false;
  createField = createFormFieldFactory();

  constructor(
    @Optional() private popupRef: PopupRef,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public form: EmailTemplateEditForm,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init({ instance: this.emailTemplate, baseInstance: this.baseEmailTemplate });

    if (this.emailTemplate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.UpdateStarted, {
        Name: this.emailTemplate.name,
        Source: this.analyticsSource
      });
    } else if (this.baseEmailTemplate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.AddStarted, {
        Name: this.baseEmailTemplate.name,
        Source: this.analyticsSource
      });
    }
  }

  ngOnDestroy(): void {}

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (this.emailTemplate) {
            this.updated.emit(result);

            this.notificationService.success('Saved', 'Email template was successfully updated');

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Updated, {
              Name: this.emailTemplate.name,
              Source: this.analyticsSource
            });
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Saved, {
              Name: this.emailTemplate.name,
              Source: this.analyticsSource
            });
          } else if (this.baseEmailTemplate) {
            this.created.emit(result);

            this.notificationService.success('Customized', 'Email template application was successfully customized');

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Added, {
              Name: this.baseEmailTemplate.name,
              Source: this.analyticsSource
            });
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Saved, {
              Name: this.baseEmailTemplate.name,
              Source: this.analyticsSource
            });
          }

          this.close();
        },
        () => {
          this.notificationService.error('Error', 'Saving Email template failed');

          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  cancel() {
    this.close();

    if (this.emailTemplate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.UpdateCancelled, {
        Name: this.emailTemplate.name,
        Source: this.analyticsSource
      });
    } else if (this.baseEmailTemplate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.AddCancelled, {
        Name: this.baseEmailTemplate.name,
        Source: this.analyticsSource
      });
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.EmailTemplate.Cancelled, {
        Name: this.baseEmailTemplate.name,
        Source: this.analyticsSource
      });
    }
  }
}

import { Border } from '../border';
import { CornerRadius } from '../corner-radius';
import { Fill, FillType } from '../fill';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';

export class RectangleLayer extends Layer {
  public type = LayerType.Rectangle;
  public fills: Fill[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];
  public cornerRadius: CornerRadius = new CornerRadius();

  constructor(options: Partial<RectangleLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['fills']) {
      this.fills = data['fills'].map(item => new Fill().deserialize(item));
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    if (data['corner_radius']) {
      this.cornerRadius = new CornerRadius().deserialize(data['corner_radius']);
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      fills: this.fills.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize()),
      corner_radius: this.cornerRadius ? this.cornerRadius.serialize() : undefined
    };
  }

  public get icon(): string {
    if (this.fills.find(item => item.type == FillType.Image)) {
      return 'image';
    } else {
      return 'rectangle';
    }
  }

  public get defaultName(): string {
    if (this.fills.find(item => item.type == FillType.Image)) {
      return 'image';
    } else {
      return 'rectangle';
    }
  }
}

registerLayerType(LayerType.Rectangle, RectangleLayer);

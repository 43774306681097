import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class QrCodeElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.QrCode;
  public value: Input;
  public width: number;
  public height: number;
  public fillColor: string;
  public backgroundColor = 'white';

  deserialize(data: Object): QrCodeElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.fillColor = this.params['fill_color'];
    this.backgroundColor = this.params['background_color'];

    if (this.params['value']) {
      this.value = new Input().deserialize(this.params['value']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['value'] = this.value ? this.value.serialize() : null;
    this.params['width'] = this.width;
    this.params['height'] = this.height;
    this.params['fill_color'] = this.fillColor;
    this.params['background_color'] = this.backgroundColor;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'QR code';
  }

  get analyticsName(): string {
    return 'qr_code';
  }

  defaultName() {
    return 'QR code';
  }
}

registerElementForType(ElementType.QrCode, QrCodeElementItem);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { scrollTo } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';

@Component({
  selector: 'app-workflow-sidebar',
  templateUrl: './workflow-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowSidebarComponent implements OnInit, OnDestroy {
  settingsComponents: DynamicComponentArguments[] = [];
  lastSettingsComponent: DynamicComponentArguments;
  firstVisible = true;

  @ViewChild('root') root: ElementRef;

  constructor(private customizeBarContext: CustomizeBarContext, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.lastSettingsComponent = last(this.settingsComponents);
      this.cd.detectChanges();
      this.scrollToTop();
    });
  }

  ngOnDestroy(): void {}

  scrollToTop(animated = true) {
    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }
}

<ng-container *ngIf="!loading">
  <div *ngFor="let item of filteredItems" class="select-icon__item">
    <a
      href="javascript:void(0)"
      class="select-icon-item select-icon-item_interactive"
      [class.select-icon-item_current]="current == item.name"
      [appTip]="item.label"
      [appTipOptions]="{ showDelay: 300 }"
      (click)="selected.emit(item.name)"
    >
      <span class="select-icon-item__icon" [ngClass]="['icon-' + item.name]"></span>
    </a>
  </div>

  <div *ngIf="(search | appIsSet) && !filteredItems.length" class="select-icon__items-empty">
    No icons found
  </div>
</ng-container>

<app-icon-selector-stub *ngIf="loading"></app-icon-selector-stub>

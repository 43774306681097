import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Frame, FrameTranslate, Translate } from '@modules/views';

export interface ViewEditorViewportOverlayItem {
  id: string;
  template: TemplateRef<unknown>;
  options: ViewEditorViewportOverlayItemOptions;
}

export interface ViewEditorViewportOverlayItemOptions {
  frame?: Frame;
  translate?: Translate;
  transform?: string;
  interactive?: boolean;
  order?: any;
}

@Injectable()
export class ViewEditorViewportOverlayService {
  private _templates = new BehaviorSubject<ViewEditorViewportOverlayItem[]>([]);
  private _templateChecked = new Subject<string>();

  get templates(): ViewEditorViewportOverlayItem[] {
    return this._templates.value;
  }

  get templates$(): Observable<ViewEditorViewportOverlayItem[]> {
    return this._templates.asObservable();
  }

  setTemplate(id: string, template: TemplateRef<unknown>, options: ViewEditorViewportOverlayItemOptions = {}) {
    const templateItem = { id: id, template: template, options: options };
    const templateItems = [...this._templates.value.filter(item => item.id != id), templateItem];
    this._templates.next(templateItems);
  }

  removeTemplate(id: string) {
    if (!this._templates.value.find(item => item.id == id)) {
      return;
    }

    const templateItems = [...this._templates.value.filter(item => item.id != id)];
    this._templates.next(templateItems);
  }

  markTemplateChecked(id: string) {
    this._templateChecked.next(id);
  }

  templateChecked$(): Observable<string> {
    return this._templateChecked.asObservable();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { combineLatest, Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiInfo, ApiService, versionCompare } from '@modules/api';
import { ModelDescriptionService } from '@modules/model-queries';
import { Environment, Project } from '@modules/projects';

import { Migration } from '../../data/migration';

@Injectable()
export class MigrationService {
  constructor(
    private http: HttpClient,
    private modelDescriptionService: ModelDescriptionService,
    private apiService: ApiService
  ) {}

  get(projectName: string, environmentName: string): Observable<Migration[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'migrations/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers });
      }),
      map(result => result.map(item => new Migration().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  filterPossible(migrations: Migration[], apiInfo: ApiInfo) {
    if (!apiInfo) {
      return [];
    }

    return migrations
      .filter(item => item.bridgeType == apiInfo.type && apiInfo.versionGreaterThanOrEquals(item.bridgeVersion))
      .sort((lhs, rhs) => versionCompare(lhs.bridgeVersion, rhs.bridgeVersion));
  }

  apply(project: Project, environment: Environment, migration: Migration, forward = true): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const dependencies = migration.bridgeDependencies
          .map(name => {
            if (name == 'model_descriptions') {
              return this.modelDescriptionService
                .getFromResources(project, environment)
                .pipe(map(dep => [name, dep.map(item => item.serialize())]));
            }
          })
          .filter(item => item != undefined);
        const dependenciesObs: Observable<Object> = dependencies.length
          ? combineLatest(...dependencies).pipe(map(deps => fromPairs(deps)))
          : of({});

        return dependenciesObs;
      }),
      switchMap(deps => {
        const url = this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'migrations/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);
        const data = {
          migration: migration.name,
          bridge_dependencies: deps,
          forward: forward
        };

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ModelLinkMenuItem } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { RoutingService } from '@modules/routing';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
var ModelLinkMenuItemComponent = /** @class */ (function (_super) {
    __extends(ModelLinkMenuItemComponent, _super);
    function ModelLinkMenuItemComponent(modelDescriptionStore, routing, cd) {
        var _this = _super.call(this) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.routing = routing;
        _this.cd = cd;
        return _this;
    }
    ModelLinkMenuItemComponent.prototype.ngOnInit = function () {
        this.updateLink();
    };
    ModelLinkMenuItemComponent.prototype.ngOnDestroy = function () { };
    ModelLinkMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.updateLink();
        }
    };
    ModelLinkMenuItemComponent.prototype.updateLink = function () {
        var _this = this;
        var modelId = this.menuItem ? this.menuItem.model : undefined;
        this.modelDescriptionStore
            .getDetail(modelId)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.link = result ? result.link : undefined;
            _this.cd.markForCheck();
        });
    };
    return ModelLinkMenuItemComponent;
}(BaseMenuItemComponent));
export { ModelLinkMenuItemComponent };

<div
  class="customize-menu__data-menu"
  [class.customize-menu__data-menu_visible]="opened"
  (click)="markClickEvent($event)"
>
  <div class="customize-menu__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <app-custom-page-queries-item
      *ngIf="editQueryControl"
      [control]="editQueryControl"
      [parentForm]="form"
      [context]="context"
      [object]="analyticsSource"
      (delete)="removeQuery(editQueryControl)"
      (close)="closeQueryEdit()"
    ></app-custom-page-queries-item>

    <ng-container *ngIf="editActionControl">
      <app-customize-bar-action-edit
        [options]="{
          actionItem: editActionControl.value,
          titleEditable: true,
          title:
            (editActionControl.value.name | appIsSet)
              ? editActionControl.value.name
              : form.controls.open_actions.getControlNameDefault(editActionControlIndex)
        }"
        [wrapperEnabled]="true"
        [titleCleanValue]="actionNameCleanValue"
        [context]="context"
        [backPopSettingsComponent]="false"
        [object]="analyticsSource"
        (event)="onActionEditEvent($event)"
        (back)="closeActionEdit()"
      ></app-customize-bar-action-edit>
    </ng-container>

    <ng-container *ngIf="!editQueryControl && !editActionControl">
      <mat-menu #dropdown="matMenu">
        <ng-template matMenuContent>
          <button
            mat-menu-item
            [class.mat-menu-item-odd]="true"
            [disableRipple]="true"
            (click)="addQuery(modelDataTypes.Detail)"
          >
            <span class="choose-items-item__dropdown-icon icon-document"></span>
            Get one record
          </button>

          <button
            mat-menu-item
            [class.mat-menu-item-odd]="true"
            [disableRipple]="true"
            (click)="addQuery(modelDataTypes.List)"
          >
            <span class="choose-items-item__dropdown-icon icon-documents"></span>
            Get list of records
          </button>
        </ng-template>
      </mat-menu>

      <app-sidebar-section>
        <div class="sidebar__list">
          <app-sidebar-field
            [label]="'Load data'"
            [documentation]="'page-queries'"
            [documentationLabel]="'See how it works'"
            [documentationLeft]="false"
          >
            <div
              cdkDropList
              [cdkDropListLockAxis]="'y'"
              class="sidebar-list"
              (cdkDropListDropped)="queryDragDrop($event)"
            >
              <div
                *ngFor="let control of form.controls.queries.controls; let i = index"
                cdkDrag
                class="sidebar-list__item"
              >
                <div
                  *ngIf="control.controls.type.value == modelDataTypes.Detail"
                  class="sidebar-list-item sidebar-list-item_clickable"
                  [class.sidebar-list-item_error]="control.invalid"
                  [appTip]="control.controls.detail_data_source.valid !== false ? undefined : 'Not configured'"
                  (click)="editQuery(control)"
                >
                  <div class="sidebar-list-item__drag"></div>
                  <div class="sidebar-list-item__left">
                    <img
                      *ngIf="(control.controls.detail_data_source.getResource$() | async)?.icon as icon"
                      class="sidebar-list-item__icon"
                      [src]="icon"
                    />
                    <span
                      *ngIf="!(control.controls.detail_data_source.getResource$() | async)?.icon"
                      class="sidebar-list-item__icon icon-document"
                    ></span>
                  </div>
                  <div class="sidebar-list-item__main">
                    <div class="sidebar-list-item__title">{{ getQueryTitle(control, i) }}</div>
                    <div
                      *ngIf="control.controls.detail_data_source.getResource$() | async as resource"
                      class="sidebar-list-item__description"
                    >
                      One record - {{ resource.name }}
                      {{ control.controls.detail_data_source.controls.query.value?.queryTypeStr }}
                    </div>
                  </div>
                  <div class="sidebar-list-item__right">
                    <a
                      href="javascript:void(0)"
                      [appTip]="'Delete'"
                      [appTipOptions]="{ side: 'top' }"
                      class="sidebar-list-item__action icon-bin"
                      (click)="$event.stopPropagation(); removeQuery(control)"
                    ></a>
                  </div>
                </div>

                <div
                  *ngIf="control.controls.type.value == modelDataTypes.List"
                  class="sidebar-list-item sidebar-list-item_clickable"
                  [class.sidebar-list-item_error]="control.invalid"
                  [appTip]="control.controls.list_data_source.valid !== false ? undefined : 'Not configured'"
                  (click)="editQuery(control)"
                >
                  <div class="sidebar-list-item__drag"></div>
                  <div class="sidebar-list-item__left">
                    <img
                      *ngIf="(control.controls.list_data_source.getResource$() | async)?.icon as icon"
                      class="sidebar-list-item__icon"
                      [src]="icon"
                    />
                    <span
                      *ngIf="!(control.controls.list_data_source.getResource$() | async)?.icon"
                      class="sidebar-list-item__icon icon-documents"
                    ></span>
                  </div>
                  <div class="sidebar-list-item__main">
                    <div class="sidebar-list-item__title">{{ getQueryTitle(control, i) }}</div>
                    <div
                      *ngIf="control.controls.list_data_source.getResource$() | async as resource"
                      class="sidebar-list-item__description"
                    >
                      List of records - {{ resource.name }}
                      {{ control.controls.list_data_source.controls.query.value?.queryTypeStr }}
                    </div>
                  </div>
                  <div class="sidebar-list-item__right">
                    <a
                      href="javascript:void(0)"
                      [appTip]="'Delete'"
                      [appTipOptions]="{ side: 'top' }"
                      class="sidebar-list-item__action icon-bin"
                      (click)="$event.stopPropagation(); removeQuery(control)"
                    ></a>
                  </div>
                </div>
              </div>

              <div *ngIf="form.controls.queries.length" class="sidebar-list__buttons">
                <a
                  href="javascript:void(0)"
                  class="button button_orange-transparent button_small sidebar-list__buttons-item"
                  [matMenuTriggerFor]="dropdown"
                >
                  <span class="icon icon-plus button__icon button__icon_left"></span>
                  <span class="button__label">Add Query</span>
                </a>
              </div>
            </div>
          </app-sidebar-field>

          <div *ngIf="!form.controls.queries.length" class="menu3-section__banner">
            <div class="menu3-section-banner menu3-section-banner_orange">
              <div class="menu3-section-banner__icon icon-data"></div>
              <div class="menu3-section-banner__title">Load data</div>
              <div class="menu3-section-banner__description">
                Pre-load data for specified parameters and use it in page components
              </div>

              <div class="menu3-section-banner__buttons">
                <div class="menu3-section-banner__button" [matMenuTriggerFor]="dropdown">
                  <div class="menu3-section-banner__button-icon icon-plus"></div>
                  <div class="menu3-section-banner__button-label">Add Query</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar__list">
          <app-sidebar-field [label]="'When page opens'">
            <div
              cdkDropList
              [cdkDropListLockAxis]="'y'"
              class="sidebar-list"
              (cdkDropListDropped)="actionDragDrop($event)"
            >
              <div
                *ngFor="let control of form.controls.open_actions.controls; let i = index"
                cdkDrag
                class="sidebar-list__item"
              >
                <app-actions-edit-item
                  [title]="control.value ? control.value.name : undefined"
                  [action]="control.value"
                  [context]="context"
                  [defaultTitle]="form.controls.open_actions.getControlNameDefault(i)"
                  [draggable]="true"
                  [remove]="true"
                  [execute]="true"
                  [error]="(form.controls.open_actions | appFormFieldErrors: i).length ? 'Not configured' : undefined"
                  (open)="editAction(control, i)"
                  (removeClick)="form.controls.open_actions.removeControl(control)"
                  (executeClick)="executeAction(control.value)"
                >
                </app-actions-edit-item>
              </div>

              <div *ngIf="!form.controls.open_actions.length" class="menu3-section__banner">
                <div class="menu3-section-banner menu3-section-banner_orange">
                  <div class="menu3-section-banner__icon icon-power"></div>
                  <div class="menu3-section-banner__title">Run actions</div>
                  <div class="menu3-section-banner__description">
                    Perform various actions when page opens like <strong>Open Overlay</strong>,
                    <strong>Send HTTP query</strong> or <strong>Run custom Workflow</strong>.
                  </div>

                  <div class="menu3-section-banner__buttons">
                    <div class="menu3-section-banner__button" (click)="addAction()">
                      <div class="menu3-section-banner__button-icon icon-plus"></div>
                      <div class="menu3-section-banner__button-label">Add Action</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sidebar-list__buttons">
                <a
                  href="javascript:void(0)"
                  class="button button_orange-transparent button_small sidebar-list__buttons-item"
                  (click)="addAction()"
                >
                  <span class="icon icon-plus button__icon button__icon_left"></span>
                  <span class="button__label">Add Action</span>
                </a>
              </div>
            </div>
          </app-sidebar-field>
        </div>
      </app-sidebar-section>
    </ng-container>
  </div>
</div>

import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';

import { ThemeContext } from '../../data/theme-context';
import { ThemeOptions } from '../../data/theme-options';
import { getThemeVars } from '../../utils/common';

@Directive({
  selector: '[appThemeOptions]'
})
export class ThemeOptionsDirective implements OnInit, OnDestroy, OnChanges {
  @Input() accentColor: string;
  @Input() fontRegular: string;
  @Input() fontHeading: string;

  @HostBinding('style') style: SafeStyle;

  constructor(private themeContext: ThemeContext, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStyle();
  }

  getThemeOptions(): ThemeOptions {
    return {
      accentColor: this.accentColor,
      fontRegular: this.fontRegular,
      fontHeading: this.fontHeading
    };
  }

  updateStyle(): void {
    const options = this.getThemeOptions();
    const vars = getThemeVars(options);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    this.style = this.sanitizer.bypassSecurityTrustStyle(styles);
    this.themeContext.options = options;
  }
}

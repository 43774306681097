/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../fill-color-overlay/fill-color-overlay.component.ngfactory";
import * as i2 from "../fill-color-overlay/fill-color-overlay.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../icons/directives/icon/icon.directive";
import * as i5 from "../../../../../core/services/app-config/app-config.service";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./fill-color-button.component";
import * as i9 from "@angular/platform-browser";
var styles_FillColorButtonComponent = [];
var RenderType_FillColorButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FillColorButtonComponent, data: {} });
export { RenderType_FillColorButtonComponent as RenderType_FillColorButtonComponent };
function View_FillColorButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-fill-color-overlay", [], null, [[null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.setOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FillColorOverlayComponent_0, i1.RenderType_FillColorOverlayComponent)), i0.ɵdid(1, 4440064, null, 0, i2.FillColorOverlayComponent, [i0.ElementRef], { control: [0, "control"], viewContext: [1, "viewContext"], view: [2, "view"], layer: [3, "layer"], fillTypesEnabled: [4, "fillTypesEnabled"] }, { blur: "blur" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.viewContext; var currVal_2 = _co.view; var currVal_3 = _co.layer; var currVal_4 = _co.fillTypesEnabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_FillColorButtonComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "compact-color-button__fill-inner"]], [[4, "background", null], [4, "width", null], [4, "height", null], [4, "transform", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorBackground; var currVal_1 = _co.colorWidth; var currVal_2 = _co.colorHeight; var currVal_3 = _co.colorTransform; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_FillColorButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compact-color-button__fill"]], [[2, "compact-color-button__fill_inner", null], [4, "background", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FillColorButtonComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.colorTransform; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorTransform; var currVal_1 = (_co.colorTransform ? null : _co.colorBackground); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FillColorButtonComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-color-button__icon"]], [[4, "color", null], [4, "background-image", null]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i4.IconDirective, [i5.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.colorIcon.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorIcon.color; var currVal_1 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FillColorButtonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FillColorButtonComponent_1)), i0.ɵdid(1, 671744, null, 0, i6.CdkConnectedOverlay, [i6.Overlay, i0.TemplateRef, i0.ViewContainerRef, i6.ɵc, [2, i7.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["cdkOverlayOrigin", ""], ["class", "compact-color-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, [["color_trigger", 4]], 0, i6.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "compact-color-button__fill compact-color-button__fill_transparent"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FillColorButtonComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FillColorButtonComponent_4)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4); var currVal_1 = _co.colorPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 2, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.colorOpened; var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.colorBackground; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.colorIcon; _ck(_v, 9, 0, currVal_9); }, null); }
export function View_FillColorButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-fill-color-button", [], null, null, null, View_FillColorButtonComponent_0, RenderType_FillColorButtonComponent)), i0.ɵdid(1, 245760, null, 0, i8.FillColorButtonComponent, [i9.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FillColorButtonComponentNgFactory = i0.ɵccf("app-fill-color-button", i8.FillColorButtonComponent, View_FillColorButtonComponent_Host_0, { control: "control", viewContext: "viewContext", view: "view", layer: "layer", fillTypesEnabled: "fillTypesEnabled" }, { openedChange: "openedChange" }, []);
export { FillColorButtonComponentNgFactory as FillColorButtonComponentNgFactory };

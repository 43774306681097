import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';

import { isSet } from '@shared';

enum NodeType {
  Object = 'object',
  Array = 'array',
  Field = 'field'
}

export interface PathSelectedEvent {
  path: PropertyKey[];
}

@Component({
  selector: 'app-query-builder-object-structure-node',
  templateUrl: './query-builder-object-structure-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderObjectStructureNodeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() node: any;
  @Input() nodeName: string;
  @Input() path: PropertyKey[] = [];
  @Input() pathSelected: PropertyKey[];
  @Input() skipSelf = false;
  @Input() skipContent = false;
  @Input() deep = 0;
  @Output() selected = new EventEmitter<PathSelectedEvent>();

  nodePath: PropertyKey[] = [];
  nodeType: NodeType;
  nodeTypes = NodeType;
  nodeObjectItems: { key: string; value: any; type: NodeType }[];
  nodeArrayItems: { value: any; type: NodeType }[];
  nodeSelected = false;

  childCollapsed: boolean[] = [];

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['node']) {
      this.nodeType = this.getNodeType(this.node);
      this.nodePath = [...this.path, ...(this.nodeName !== undefined ? [this.nodeName] : [])];

      const nodeInSelectedPath =
        isSet(this.pathSelected) &&
        this.pathSelected.length >= 2 &&
        isEqual(this.pathSelected.slice(0, -2).slice(0, this.nodePath.length), this.nodePath);
      const defaultCollapsed = !nodeInSelectedPath;

      if (this.nodeType == NodeType.Object) {
        this.nodeObjectItems = toPairs(this.node).map(([k, v]) => {
          return {
            key: k,
            value: v,
            type: this.getNodeType(v)
          };
        });
        this.childCollapsed = this.nodeObjectItems.map(() => defaultCollapsed);
      } else if (this.nodeType == NodeType.Array) {
        this.nodeArrayItems = this.node.map(item => {
          return {
            value: item,
            type: this.getNodeType(item)
          };
        });
        this.childCollapsed = this.nodeArrayItems.map(() => defaultCollapsed);
      }
    }

    if (changes['node'] || changes['pathSelected']) {
      this.nodeSelected = isSet(this.pathSelected) && isEqual(this.nodePath, this.pathSelected);
    }
  }

  getNodeType(node: any): NodeType {
    if (isArray(node)) {
      return NodeType.Array;
    } else if (isPlainObject(node)) {
      return NodeType.Object;
    } else {
      return NodeType.Field;
    }
  }

  toggleCollapse(i: number) {
    this.childCollapsed[i] = !this.childCollapsed[i];
  }

  select() {
    this.selected.emit({ path: this.nodePath });
  }

  childSelected(event: PathSelectedEvent) {
    this.selected.emit(event);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../color-selector-advanced/color-selector-advanced.component.ngfactory";
import * as i2 from "../color-selector-advanced/color-selector-advanced.component";
import * as i3 from "../../../services/pick-color/pick-color.service";
import * as i4 from "../../../../../core/services/local-storage/local.storage";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i7 from "../../../../parameters-components/components/input-edit/input-edit.component.ngfactory";
import * as i8 from "../../../../parameters-components/components/input-edit/input-edit.component";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i11 from "@angular/common";
import * as i12 from "./color-with-input-selector.component";
var styles_ColorWithInputSelectorComponent = [];
var RenderType_ColorWithInputSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColorWithInputSelectorComponent, data: {} });
export { RenderType_ColorWithInputSelectorComponent as RenderType_ColorWithInputSelectorComponent };
function View_ColorWithInputSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-color-selector-advanced", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i1.View_ColorSelectorAdvancedComponent_0, i1.RenderType_ColorSelectorAdvancedComponent)), i0.ɵdid(2, 4440064, null, 0, i2.ColorSelectorAdvancedComponent, [i3.PickColorService, i4.LocalStorage, i0.ChangeDetectorRef], { formControl: [0, "formControl"] }, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.ColorSelectorAdvancedComponent]), i0.ɵdid(4, 540672, null, 0, i5.FormControlDirective, [[8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵdid(5, 81920, null, 0, i6.FormControlSelectorDirective, [i0.ElementRef, [2, i5.FormControlDirective], [2, i5.FormControlName]], null, null), i0.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i0.ɵdid(7, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "compact-button-tag button-tags__item_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColorInputEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "span", [["class", "compact-button-tag__icon icon-function"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "compact-button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Color with Formula"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.colorControl; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.colorControl; _ck(_v, 4, 0, currVal_8); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 7).ngClassValid; var currVal_5 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ColorWithInputSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-input-edit", [], null, null, null, i7.View_InputEditComponent_0, i7.RenderType_InputEditComponent)), i0.ɵdid(3, 770048, null, 0, i8.InputEditComponent, [i9.FocusMonitor, i10.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], { itemForm: [0, "itemForm"], context: [1, "context"], staticValueDisabled: [2, "staticValueDisabled"], focusedInitial: [3, "focusedInitial"], formulaPlaceholder: [4, "formulaPlaceholder"], fill: [5, "fill"], dark: [6, "dark"], darker: [7, "darker"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "compact-button-tag button-tags__item_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColorInputEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "compact-button-tag__icon icon-function"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "compact-button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Disable Formula"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colorInputControl; var currVal_1 = _co.viewContext; var currVal_2 = true; var currVal_3 = _co.colorInputFocused; var currVal_4 = _co.formulaPlaceholder; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ColorWithInputSelectorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorWithInputSelectorComponent_1)), i0.ɵdid(1, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColorWithInputSelectorComponent_2)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.colorInputEnabledControl.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.colorInputEnabledControl.value; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ColorWithInputSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-color-with-input-selector", [], null, null, null, View_ColorWithInputSelectorComponent_0, RenderType_ColorWithInputSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i12.ColorWithInputSelectorComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColorWithInputSelectorComponentNgFactory = i0.ɵccf("app-color-with-input-selector", i12.ColorWithInputSelectorComponent, View_ColorWithInputSelectorComponent_Host_0, { colorControl: "colorControl", colorInputEnabledControl: "colorInputEnabledControl", colorInputControl: "colorInputControl", viewContext: "viewContext" }, {}, []);
export { ColorWithInputSelectorComponentNgFactory as ColorWithInputSelectorComponentNgFactory };

import pickBy from 'lodash/pickBy';

import { User } from '@modules/users';

import {
  actionType,
  messageType,
  UserActivityType,
  userActivityTypeDescriptions,
  userActivityTypeIcons
} from './user-activity-type';
import { userActivityTypeLabels } from './user-activity-type';

export class UserActivity {
  public user: User;
  public activityType: UserActivityType;
  public objectType: string;
  public objectId: any;
  public viewSettings: any;
  public params: Object = {};
  public dateAdd: string;

  deserialize(data: Object): UserActivity {
    this.activityType = data['activity_type'];
    this.objectType = data['object_type'];
    this.objectId = data['object_id'];
    this.viewSettings = data['view_settings'];
    this.dateAdd = data['date_add'];
    this.params = data['params'];

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      activity_type: this.activityType,
      object_type: this.objectType,
      object_id: this.objectId,
      view_settings: this.viewSettings,
      params: this.params
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get label() {
    return userActivityTypeLabels[this.activityType] || this.activityType;
  }

  description(ctx: { user: string }) {
    if (userActivityTypeDescriptions[this.activityType]) {
      return userActivityTypeDescriptions[this.activityType](ctx);
    } else {
      return this.activityType;
    }
  }

  get icon() {
    return userActivityTypeIcons[this.activityType];
  }

  isActionActivityType() {
    return [...actionType].some(item => this.activityType === item);
  }

  isMessageActivityType() {
    return [...messageType].some(item => this.activityType === item);
  }
}

import * as moment from 'moment';

export class SubscriptionPeriod {
  public dateBegin: any;
  public dateEnd: any;
  public activities: number;

  deserialize(data: Object): SubscriptionPeriod {
    this.dateBegin = data['date_begin'] ? moment(data['date_begin']) : undefined;
    this.dateEnd = data['date_end'] ? moment(data['date_end']) : undefined;
    this.activities = data['activities'];

    return this;
  }
}

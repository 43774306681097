<div class="compact-border-button" [class.compact-border-button_interactive]="interactive">
  <div
    *ngIf="leftIcon"
    class="compact-border-button__left"
    [class.compact-border-button__left_background]="leftBackground"
    [class.compact-border-button__left_interactive]="leftInteractive"
    (click)="leftClick.emit()"
  >
    <div class="compact-border-button__icon" [ngClass]="['icon-' + leftIcon]"></div>
  </div>

  <div class="compact-border-button__main">
    <div class="compact-border-button__label">{{ label }}</div>
  </div>

  <div
    *ngIf="rightIcon"
    class="compact-border-button__right"
    [class.compact-border-button__right_background]="rightBackground"
    [class.compact-border-button__right_interactive]="rightInteractive"
    (click)="rightClick.emit()"
  >
    <div class="compact-border-button__icon" [ngClass]="['icon-' + rightIcon]"></div>
  </div>
</div>

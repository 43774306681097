import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import {
  ChangeViewSettings,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore,
  ViewSettingsType
} from '@modules/customize';
import { FieldType, ParameterField } from '@modules/fields';
import { MenuItemAction, MenuItemActionType, MenuItemType, MenuSettingsStore, SimpleMenuItem } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore, Environment, Project } from '@modules/projects';
import { ModelDescriptionQuery } from '@modules/queries';
import { splitmax } from '@shared';

@Injectable()
export class ViewSettingsQueries {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private dialogService: DialogService
  ) {}

  createPage(options: {
    project: Project;
    environment: Environment;
    view: ViewSettingsType;
    uniqueName?: string;
    name: string;
    modelId?: string;
    addToMenu?: boolean;
  }): Observable<ViewSettings> {
    return (options.uniqueName
      ? of(options.uniqueName)
      : this.viewSettingsStore.getDistinctUniqueNameForName(options.name)
    ).pipe(
      switchMap(uniqueName => {
        let viewSettings: ViewSettings;

        if (options.view == ViewSettingsType.Change) {
          viewSettings = new ChangeViewSettings();
        } else {
          viewSettings = new ViewSettings();
        }

        viewSettings.view = options.view;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = options.name;

        if (viewSettings instanceof ChangeViewSettings) {
          const [resource, model] = splitmax(options.modelId, '.', 2);

          viewSettings.getResource = resource;
          viewSettings.getQuery = new ModelDescriptionQuery();
          viewSettings.getQuery.simpleQuery = new viewSettings.getQuery.simpleQueryClass();
          viewSettings.getQuery.simpleQuery.model = model;

          const parameter = new ParameterField();

          parameter.name = 'id';
          parameter.verboseName = 'record';
          parameter.field = FieldType.RelatedModel;
          parameter.params = { related_model: { model: options.modelId } };
          parameter.required = false;

          viewSettings.parameters = [parameter];
        }

        return this.viewSettingsService.create(
          options.project.uniqueName,
          options.environment.uniqueName,
          viewSettings,
          ['uid', 'view', 'unique_name', 'name', 'params', 'deleted']
        );
      }),
      delayWhen(() => {
        return this.viewSettingsStore.getFirst(true);
      })
      // delayWhen(result => {
      //   if (!options.addToMenu) {
      //     return of(undefined);
      //   }
      //
      //   return this.addPageToMenu(result);
      // })
    );
  }

  // addPageToMenu(viewSettings: ViewSettings) {
  //   const item = new SimpleMenuItem();
  //
  //   item.generateId();
  //
  //   item.action = new MenuItemAction();
  //   item.action.type = MenuItemActionType.Page;
  //   item.action.pageUid = viewSettings.uid;
  //   item.action.pageUniqueName = viewSettings.uniqueName;
  //
  //   return this.menuSettingsStore.addItems([item]);
  // }

  validateExisting(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!this.currentProjectStore.instance) {
        return of(null);
      }

      return this.viewSettingsStore.getFirst().pipe(
        map(viewSettings => {
          if (
            control.value &&
            viewSettings.find(item => {
              const uniqueName = slugify(control.value, { trim: true }).replace(/-+/g, '-');
              return item.uniqueName == uniqueName;
            })
          ) {
            return { local: ['Page with such name already exists'] };
          }
        })
      );
    };
  }

  promptCreatePage(
    options: {
      defaultName?: string;
      addToMenu?: boolean;
    } = {}
  ): Observable<ViewSettings> {
    return this.dialogService
      .prompt({
        title: 'Create a new page',
        inputs: [
          {
            name: 'name',
            label: 'Page Name',
            type: FieldType.Text,
            default: options.defaultName,
            placeholder: 'Enter a page name',
            validators: [Validators.required],
            asyncValidators: [this.validateExisting()]
            // description: 'Page will have the following URL:\napp-dev.jetadmin.io/app/qa_test/page/hello'
          }
        ],
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'submit',
            label: 'Create Page',
            type: DialogButtonType.Submit,
            hotkey: DialogButtonHotkey.Submit,
            executor: result =>
              of(result).pipe(
                filter(item => item.form != undefined),
                switchMap(item => {
                  return this.createPage({
                    project: this.currentProjectStore.instance,
                    environment: this.currentEnvironmentStore.instance,
                    view: ViewSettingsType.Custom,
                    name: item.form['name'],
                    addToMenu: options.addToMenu
                  });
                })
              )
          }
        ]
      })
      .pipe(
        map(result => {
          if (result.executorResult instanceof ViewSettings) {
            return result.executorResult;
          }
        })
      );
  }
}

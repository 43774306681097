<div class="compact-inputs-element">
  <app-compact-select [formControl]="control.controls.type" [options]="control.typeOptions"></app-compact-select>
</div>

<div *ngIf="!control.controls.image_input_enabled.value" class="compact-inputs-element">
  <div
    class="image-uploader2"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <input class="image-uploader2__file" type="file" id="image_fill_file" (change)="onImageFileChange($event.target)" />

    <div class="image-uploader2__preview" [style.background]="imageBackgroundSafe"></div>

    <div
      class="image-uploader2__overlay"
      [class.image-uploader2__overlay_active]="imageUploading || draggingFile || !control.controls.image.value"
      [class.image-uploader2__overlay_dark]="imageUploading || draggingFile"
    >
      <app-loader *ngIf="imageUploading" class="image-uploader2__loader" [inverse]="true"></app-loader>

      <div *ngIf="!imageUploading && draggingFile" class="image-uploader2__buttons">
        <div class="image-uploader2__button image-uploader2__button_focus">
          <div class="image-uploader2__button-icon image-uploader2__button-icon_left icon-cloud_upload"></div>
          <div class="image-uploader2__button-label">Upload file</div>
        </div>
      </div>

      <div *ngIf="!imageUploading && !draggingFile" class="image-uploader2__buttons">
        <label class="image-uploader2__button" for="image_fill_file">
          <span class="image-uploader2__button-icon image-uploader2__button-icon_left icon-open_folder"></span>
          <span class="image-uploader2__button-label">
            <ng-container *ngIf="control.controls.image.value">Change Image</ng-container>
            <ng-container *ngIf="!control.controls.image.value">Choose Image</ng-container>
          </span>
        </label>

        <div
          *ngIf="control.controls.image.value"
          class="image-uploader2__button image-uploader2__button_icon"
          (click)="control.controls.image.patchValue(undefined)"
        >
          <span class="image-uploader2__button-icon icon-bin"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!control.controls.image_input_enabled.value" class="compact-inputs-element">
  <div class="compact-inputs-row">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input [formControl]="control.controls.image" [prefix]="'URL'"></app-compact-input>
    </div>

    <div class="compact-inputs-row__item">
      <div
        class="compact-button compact-button_primary icon-function"
        [appTip]="'Set up with Formula'"
        [appTipOptions]="{ side: 'top' }"
        (click)="toggleImageInputEnabled()"
      ></div>
    </div>
  </div>
</div>

<ng-container *ngIf="control.controls.image_input_enabled.value">
  <div class="compact-inputs-element">
    <app-input-edit
      [itemForm]="control.controls.image_input"
      [context]="viewContext"
      [staticValueDisabled]="true"
      [focusedInitial]="imageInputFocused"
      [formulaPlaceholder]="'URL with Formula'"
      [fill]="true"
      [dark]="true"
      [darker]="true"
    ></app-input-edit>
  </div>

  <div class="compact-inputs-element">
    <div class="compact-button-tag button-tags__item_fill" (click)="toggleImageInputEnabled()">
      <span class="compact-button-tag__icon icon-function"></span>
      <span class="compact-button-tag__label">Disable Formula</span>
    </div>
  </div>
</ng-container>

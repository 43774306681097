import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { EditableContentModule } from '@common/editable-content';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ProjectBillingDetailsPopupEnvironmentComponent } from './components/project-billing-details-popup-environment/project-billing-details-popup-environment.component';
import { ProjectBillingDetailsPopupComponent } from './components/project-billing-details-popup/project-billing-details-popup.component';
import { ProjectBillingInfoSubscriptionStubComponent } from './components/project-billing-info-subscription-stub/project-billing-info-subscription-stub.component';
import { ProjectBillingInfoSubscriptionComponent } from './components/project-billing-info-subscription/project-billing-info-subscription.component';
import { ProjectBillingInfoComponent } from './components/project-billing-info/project-billing-info.component';
import { ProjectBillingPlansStubComponent } from './components/project-billing-plans-stub/project-billing-plans-stub.component';
import { ProjectBillingPlansComponent } from './components/project-billing-plans/project-billing-plans.component';
import { ProjectChooseVersionComponent } from './components/project-choose-version/project-choose-version.component';
import { ProjectCopyInterfaceComponent } from './components/project-copy-interface/project-copy-interface.component';
import { ProjectEnvironmentCreatePopupComponent } from './components/project-environment-create-popup/project-environment-create-popup.component';
import { ProjectEnvironmentMergePopupComponent } from './components/project-environment-merge-popup/project-environment-merge-popup.component';
import { ProjectGroupChangePopupComponent } from './components/project-group-change-popup/project-group-change-popup.component';
import { ProjectPropertyChangeItemComponent } from './components/project-group-change-properties-item/project-property-change-item.component';
import { ProjectPropertyChangeComponent } from './components/project-group-change-properties/project-property-change.component';
import { ProjectGroupChangeComponent } from './components/project-group-change/project-group-change.component';
import { ProjectGroupControlComponent } from './components/project-group-control/project-group-control.component';
import { ProjectGroupCreatePopupComponent } from './components/project-group-create-popup/project-group-create-popup.component';
import { ProjectGroupDropdownComponent } from './components/project-group-dropdown/project-group-dropdown.component';
import { ProjectGroupEditPopupComponent } from './components/project-group-edit-popup/project-group-edit-popup.component';
import { ProjectInviteItemComponent } from './components/project-invite-item/project-invite-item.component';
import { ProjectMenuComponent } from './components/project-menu/project-menu.component';
import { ProjectMigrationsComponent } from './components/project-migrations/project-migrations.component';
import { ProjectPermissionActionsComponent } from './components/project-permission-actions/project-permission-actions.component';
import { ProjectResetInterfaceComponent } from './components/project-reset-interface/project-reset-interface.component';
import { ProjectUsersItemStubComponent } from './components/project-users-item-stub/project-users-item-stub.component';
import { ProjectUsersItemComponent } from './components/project-users-item/project-users-item.component';
import { ProjectVersionsComponent } from './components/project-versions/project-versions.component';
import { SelectUserInterfaceComponent } from './components/select-user-interface/select-user-interface.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    NgGxScrollableModule,
    FieldsModule,
    FieldComponentsModule,
    SharedModule,
    TipsModule,
    TutorialModule,
    LocalizeModule,
    UiModule,
    AnalyticsModule,
    SelectModule,
    EditableContentModule,
    OverlayModule,
    ThemeComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    ProjectGroupChangeComponent,
    ProjectGroupChangePopupComponent,
    ProjectPropertyChangeComponent,
    ProjectMigrationsComponent,
    ProjectVersionsComponent,
    SelectUserInterfaceComponent,
    ProjectCopyInterfaceComponent,
    ProjectResetInterfaceComponent,
    ProjectBillingInfoComponent,
    ProjectChooseVersionComponent,
    ProjectMenuComponent,
    ProjectGroupCreatePopupComponent,
    ProjectGroupEditPopupComponent,
    ProjectEnvironmentCreatePopupComponent,
    ProjectEnvironmentMergePopupComponent,
    ProjectBillingPlansComponent,
    ProjectBillingPlansStubComponent,
    ProjectUsersItemComponent,
    ProjectUsersItemStubComponent,
    ProjectInviteItemComponent,
    ProjectPermissionActionsComponent,
    ProjectGroupControlComponent,
    ProjectPropertyChangeItemComponent,
    ProjectGroupDropdownComponent,
    ProjectBillingDetailsPopupComponent,
    ProjectBillingDetailsPopupEnvironmentComponent,
    ProjectBillingInfoSubscriptionComponent,
    ProjectBillingInfoSubscriptionStubComponent
  ],
  entryComponents: [
    ProjectGroupChangeComponent,
    ProjectCopyInterfaceComponent,
    ProjectResetInterfaceComponent,
    ProjectGroupCreatePopupComponent,
    ProjectGroupEditPopupComponent,
    ProjectEnvironmentCreatePopupComponent,
    ProjectEnvironmentMergePopupComponent,
    ProjectBillingDetailsPopupComponent
  ],
  exports: [
    ProjectGroupChangeComponent,
    ProjectGroupChangePopupComponent,
    ProjectPropertyChangeComponent,
    ProjectMigrationsComponent,
    ProjectVersionsComponent,
    SelectUserInterfaceComponent,
    ProjectCopyInterfaceComponent,
    ProjectResetInterfaceComponent,
    ProjectBillingInfoComponent,
    ProjectBillingInfoSubscriptionComponent,
    ProjectBillingInfoSubscriptionStubComponent,
    ProjectChooseVersionComponent,
    ProjectMenuComponent,
    ProjectGroupCreatePopupComponent,
    ProjectGroupEditPopupComponent,
    ProjectEnvironmentCreatePopupComponent,
    ProjectEnvironmentMergePopupComponent,
    ProjectBillingPlansComponent,
    ProjectUsersItemComponent,
    ProjectUsersItemStubComponent,
    ProjectInviteItemComponent,
    ProjectGroupControlComponent,
    ProjectGroupDropdownComponent
  ]
})
export class ProjectSettingsComponentsModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { Domain } from '@modules/domain';
import { HomeTriggerOutput, ProjectInvite, ProjectInviteService } from '@modules/projects';
import { RoutingService } from '@modules/routing';

@Component({
  selector: 'app-project-invite',
  templateUrl: './project-invite.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectInviteComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private projectInviteService: ProjectInviteService,
    private routing: RoutingService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  loading = false;
  submitLoading = false;
  notFound = false;
  error = false;
  code: string;
  invite: ProjectInvite;
  domain: Domain;

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.loading = true;
          this.notFound = false;
          this.error = false;
          this.code = params['code'];
          this.cd.markForCheck();

          return this.projectInviteService.getByCode(this.code);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        invite => {
          this.invite = invite;
          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Opened, {
            ProjectID: this.invite.project.uniqueName
          });
        },
        error => {
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          if (error instanceof ServerRequestError && error.response && error.response.status == 404) {
            this.notFound = true;
          }

          this.loading = false;
          this.error = true;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.OpenedFailed, {
            Error: errorMessage
          });
        }
      );

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.domain = data.domain;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  accept() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.projectInviteService
      .accept(this.code)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.routing.navigateLink(
            this.invite.project.getHomeLinkWithProtocol({ environmentName: this.invite.environment.uniqueName }),
            { queryParams: { src: HomeTriggerOutput.AcceptInvite } }
          );
          this.cd.markForCheck();

          this.notificationService.success(
            'Accepted',
            `You have successfully joined project ${this.invite.project.name}`
          );

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Accepted, {
            ProjectID: this.invite.project.uniqueName,
            CustomizationPermission: this.invite.project.hasGroupCustomizationPermission(this.invite.group)
          });

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UsersChanged, {
            ProjectID: this.invite.project.uniqueName,
            UsersCount: uniq(this.invite.projectUsers.map(item => item.getEmail())).length + 1
          });
        },
        error => {
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          this.notificationService.error("Can't Accept", errorMessage);

          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.AcceptedFailed, {
            ProjectID: this.invite.project.uniqueName,
            Error: errorMessage
          });
        }
      );
  }

  reject() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.projectInviteService
      .reject(this.code)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.routing.navigate(['/']);
          this.submitLoading = false;
          this.cd.markForCheck();

          this.notificationService.info('Rejected', `You have rejected to join project ${this.invite.project.name}`);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.Rejected, {
            ProjectID: this.invite.project.uniqueName
          });
        },
        error => {
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          this.notificationService.error("Can't Reject", errorMessage);

          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Invitation.RejectedFailed, {
            ProjectID: this.invite.project.uniqueName,
            Error: errorMessage
          });
        }
      );
  }
}

import isObject from 'lodash/isObject';
import repeat from 'lodash/repeat';
import trimEnd from 'lodash/trimEnd';
import { defaultConverters, getQuillConverters } from './converters';
import { MarkdownNode } from './markdown-node';
export function quillDeltaToMarkdown(delta, options) {
    if (!delta) {
        return;
    }
    var converters = getQuillConverters(options);
    return new QuillToMarkdownConverter({ converters: converters }).convert(delta.ops);
}
var QuillToMarkdownConverter = /** @class */ (function () {
    function QuillToMarkdownConverter(options) {
        if (options === void 0) { options = {}; }
        this.converters = defaultConverters;
        Object.assign(this, options);
    }
    QuillToMarkdownConverter.prototype.newLine = function () {
        var node = new MarkdownNode({ open: '', close: '\n' });
        this.lineNode = node;
        this.rootNode.appendNode(node);
    };
    QuillToMarkdownConverter.prototype.isBlock = function (op) {
        if (!op.attributes) {
            return false;
        }
        for (var _i = 0, _a = Object.keys(op.attributes); _i < _a.length; _i++) {
            var attr = _a[_i];
            if (this.converters.block[attr]) {
                return true;
            }
        }
        return false;
    };
    QuillToMarkdownConverter.prototype.isSameBlockGroup = function (op) {
        if (!this.blockGroup || !op.attributes) {
            return false;
        }
        for (var _i = 0, _a = Object.keys(op.attributes); _i < _a.length; _i++) {
            var attr = _a[_i];
            if (this.blockGroup.name == attr && this.blockGroup.value == op.attributes[attr]) {
                return true;
            }
        }
        return false;
    };
    QuillToMarkdownConverter.prototype.convert = function (ops) {
        var _this = this;
        this.rootNode = new MarkdownNode();
        this.newLine();
        for (var i = 0; i < ops.length; ++i) {
            var op = ops[i];
            if (isObject(op.insert)) {
                if (op.insert) {
                    for (var _i = 0, _a = Object.keys(op.insert); _i < _a.length; _i++) {
                        var embed = _a[_i];
                        if (this.converters.embed[embed]) {
                            var value = op.insert[embed];
                            var appendNode = this.converters.embed[embed](value, op.attributes, this.lineNode);
                            if (!appendNode) {
                                continue;
                            }
                            this.lineNode.appendNode(appendNode);
                        }
                    }
                }
            }
            else if (this.isBlock(op)) {
                var _loop_1 = function (attr) {
                    if (this_1.converters.block[attr]) {
                        var value = op.attributes[attr];
                        if (!this_1.blockGroup || !this_1.isSameBlockGroup(op)) {
                            this_1.blockGroup = { name: attr, value: value, nodes: [this_1.lineNode] };
                        }
                        else {
                            this_1.blockGroup.nodes.push(this_1.lineNode);
                        }
                        var wrap_1 = this_1.converters.block[attr](value, this_1.blockGroup);
                        if (!wrap_1) {
                            return "continue";
                        }
                        this_1.lineNode.children.forEach(function (child) { return wrap_1.appendNode(child); });
                        this_1.lineNode.appendNode(wrap_1);
                    }
                };
                var this_1 = this;
                for (var _b = 0, _c = Object.keys(op.attributes); _b < _c.length; _b++) {
                    var attr = _c[_b];
                    _loop_1(attr);
                }
                var lines = op.insert.split('\n');
                lines.slice(1).forEach(function () { return _this.newLine(); });
            }
            else if (typeof op.insert === 'string') {
                var lines = op.insert.split('\n');
                for (var l = 0; l < lines.length; ++l) {
                    var line = lines[l];
                    if (l > 0) {
                        this.newLine();
                    }
                    if (line === '') {
                        continue;
                    }
                    var indentMatch = !this.lineNode.children.length ? line.match(/^(\s+)/) : undefined;
                    if (indentMatch) {
                        line = repeat('&nbsp;', indentMatch[1].length) + line.substring(indentMatch[1].length);
                    }
                    var appendNode = new MarkdownNode({ text: line });
                    if (op.attributes) {
                        for (var _d = 0, _e = Object.keys(op.attributes); _d < _e.length; _d++) {
                            var attr = _e[_d];
                            if (this.converters.inline[attr]) {
                                var value = op.attributes[attr];
                                var _f = this.converters.inline[attr](value), open_1 = _f[0], close_1 = _f[1];
                                var wrap = new MarkdownNode({ open: open_1, close: close_1 });
                                wrap.appendNode(appendNode);
                                appendNode = wrap;
                            }
                        }
                    }
                    this.lineNode.appendNode(appendNode);
                }
            }
        }
        for (var i = 0; i < this.rootNode.children.length; ++i) {
            var prev = this.rootNode.children[i - 1];
            var current = this.rootNode.children[i];
            if (prev &&
                prev.children.length &&
                !prev.children.some(function (item) { return item.block; }) &&
                prev.close == '\n' &&
                current.children.length &&
                !current.children.some(function (item) { return item.block; })) {
                prev.close = "<br>" + prev.close;
            }
        }
        var result = this.rootNode.render();
        return trimEnd(result) + '\n';
    };
    return QuillToMarkdownConverter;
}());
export { QuillToMarkdownConverter };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Border } from '../border';
import { Fill } from '../fill';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';
var EllipseLayer = /** @class */ (function (_super) {
    __extends(EllipseLayer, _super);
    function EllipseLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Ellipse;
        _this.icon = 'circle_2';
        _this.defaultName = 'ellipse';
        _this.fills = [];
        _this.borders = [];
        _this.shadows = [];
        return _this;
    }
    EllipseLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['fills']) {
            this.fills = data['fills'].map(function (item) { return new Fill().deserialize(item); });
        }
        if (data['borders']) {
            this.borders = data['borders'].map(function (item) { return new Border().deserialize(item); });
        }
        if (data['shadows']) {
            this.shadows = data['shadows'].map(function (item) { return new Shadow().deserialize(item); });
        }
        return this;
    };
    EllipseLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { fills: this.fills.map(function (item) { return item.serialize(); }), borders: this.borders.map(function (item) { return item.serialize(); }), shadows: this.shadows.map(function (item) { return item.serialize(); }) });
    };
    return EllipseLayer;
}(Layer));
export { EllipseLayer };
registerLayerType(LayerType.Ellipse, EllipseLayer);

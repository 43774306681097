import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-text-to-sql-overlay',
  templateUrl: './text-to-sql-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appTextToSqlOverlay'
})
export class TextToSqlOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() origin: CdkOverlayOrigin;
  @Input() trigger: HTMLElement;
  @Input() triggerManual = false;
  @Output() submitQuery = new EventEmitter<string>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  example = 'A query to list tickets id, status which has more than 2 comments in the last 3 months';
  queryControl = new FormControl(this.example, Validators.required);
  dropdownOpened = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8 - 16,
      offsetY: -8 + 8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8 + 16,
      offsetY: -8 + 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8 - 16,
      offsetY: 8 - 8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8 + 16,
      offsetY: 8 - 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 8
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.triggerManual) {
      let trigger: HTMLElement;

      if (this.trigger) {
        trigger = this.trigger;
      } else if (this.origin) {
        trigger = this.origin.elementRef.nativeElement;
      }

      if (trigger) {
        fromEvent(trigger, 'click')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.open();
          });
      }
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  onBackgroundClick() {
    this.setDropdownOpened(false);
  }

  reset() {
    this.queryControl.patchValue(this.example);
    this.queryControl.markAsPristine();
  }

  submit() {
    this.submitQuery.emit(this.queryControl.value);
    this.setDropdownOpened(false);
  }
}

import { EventEmitter, Input, Output } from '@angular/core';

import { MenuBlock, MenuItem } from '@modules/menu';

import { MenuContext } from '../../../data/menu-context';

export abstract class BaseMenuItemComponent {
  @Input() menuItem: MenuItem;
  @Input() menuBlock: MenuBlock;
  @Input() childrenVertical = false;
  @Input() primary = false;
  @Input() dropdown = false;
  @Input() horizontal = false;
  @Input() forceActive = false;
  @Input() forceOpened = false;
  @Input() context: MenuContext;
  @Output() dropdownOpen = new EventEmitter<void>();
  @Output() dropdownClose = new EventEmitter<void>();
}

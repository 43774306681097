var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, Injector, NgZone, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, filter, first, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { SelectComponent } from '@common/select';
import { ActionService } from '@modules/action-queries';
import { modelFieldToDisplayField, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { createFormFieldFactory, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelEditController, ModelQueryEditController } from '@modules/model-components';
import { ModelFieldType } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { ResourceModelEditController } from '@modules/projects-components';
import { ListModelDescriptionQuery, ModelDescriptionQuery, ObjectQueryOperation, QueryType } from '@modules/queries';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';
import { RoutingService } from '@modules/routing';
import { Workflow } from '@modules/workflow';
import { controlValue } from '@shared';
import { WorkflowEditController } from '../../services/workflow-edit-controller/workflow-edit.controller';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';
var ModelDescriptionDataSourceEditComponent = /** @class */ (function () {
    function ModelDescriptionDataSourceEditComponent(queryContext, resourceModelEditController, queryBuilderService, modelEditController, modelQueryEditController, workflowEditController, contextTokenProvider, actionService, notificationService, routing, injector, zone, cd) {
        this.queryContext = queryContext;
        this.resourceModelEditController = resourceModelEditController;
        this.queryBuilderService = queryBuilderService;
        this.modelEditController = modelEditController;
        this.modelQueryEditController = modelQueryEditController;
        this.workflowEditController = workflowEditController;
        this.contextTokenProvider = contextTokenProvider;
        this.actionService = actionService;
        this.notificationService = notificationService;
        this.routing = routing;
        this.injector = injector;
        this.zone = zone;
        this.cd = cd;
        this.typeIconButton = false;
        this.typeOrange = false;
        this.search = false;
        this.extraTokens = [];
        this.createField = createFormFieldFactory();
        this.search$ = new BehaviorSubject(false);
        this.extraTokens$ = new BehaviorSubject([]);
        this.reloadLoading = false;
        this.dataSourceTypes = DataSourceType;
        this.parameters = [];
    }
    ModelDescriptionDataSourceEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resourceFieldParams = __assign({ classes: ['select_fill'] }, this.control.resourceFieldParams);
        this.reloadAllowed$ = this.control.getResourceReloadAllowed$();
        this.queryOptions$ = this.control.getQueryOptions$();
        this.queryEditable$ = this.control.getQueryEditable$();
        this.queryConfigured$ = this.control.getQueryConfigured$();
        this.modelDescriptionCustom$ = this.control.getModelDescriptionCustom$();
        this.autoDetectedColumns$ = this.control.getAutoDetectedColumns$(this.control.isListQuery(), {
            context: this.context,
            contextElement: this.contextElement
        });
        this.queryContext.parametersControl = this.control.controls.query_parameters;
        combineLatest(this.search$, controlValue(this.control.controls.query_parameters), this.extraTokens$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var search = _a[0], parameters = _a[1], extraTokens = _a[2];
            _this.updateTokens({
                search: search,
                parameters: parameters,
                extraTokens: extraTokens
            });
        });
        this.control.queryDefaultSet.pipe(untilDestroyed(this)).subscribe(function (query) {
            if (query.queryType != QueryType.Simple) {
                _this.editCustomQuery();
            }
        });
        combineLatest(controlValue(this.control.controls.workflow), controlValue(this.control.controls.query_parameters))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var workflow = _a[0], parameters = _a[1];
            _this.workflow = workflow;
            _this.parameters = parameters;
            _this.cd.markForCheck();
        });
        this.control.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe(function (type) {
            if (type == DataSourceType.Workflow && !_this.control.controls.workflow.value) {
                _this.editWorkflow();
            }
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.ngOnDestroy = function () { };
    ModelDescriptionDataSourceEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.search) {
            this.search$.next(this.search);
        }
        if (changes.extraTokens) {
            this.extraTokens$.next(this.extraTokens);
        }
    };
    ModelDescriptionDataSourceEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.zone.onStable.pipe(take(1), delay(100), untilDestroyed(this)).subscribe(function () {
            if (_this.typeSelect && !_this.control.controls.type.value) {
                _this.typeSelect.open();
            }
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.updateTokens = function (options) {
        var tokens = [];
        var componentTokens = [];
        if (options.search) {
            componentTokens.push({
                name: 'search',
                field: FieldType.Text,
                label: 'Search query',
                icon: 'search'
            });
        }
        if (options.extraTokens && options.extraTokens.length) {
            componentTokens.push.apply(componentTokens, options.extraTokens.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(FieldType.Number);
                return {
                    name: item.name,
                    field: item.field,
                    label: item.verboseName,
                    icon: fieldDescription.icon
                };
            }));
        }
        if (componentTokens.length) {
            tokens.push({
                name: undefined,
                label: 'Component parameters',
                children: componentTokens
            });
        }
        tokens.push({
            name: undefined,
            label: 'Inputs',
            data: {
                parameter_tokens: true
            },
            children: options.parameters
                .filter(function (item) { return item.name; })
                .map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    name: ['params', item.name].join('.'),
                    field: item.field,
                    params: item.params,
                    label: item.verboseName || item.name,
                    icon: fieldDescription.icon
                };
            })
        });
        this.queryContext.tokens = tokens;
    };
    ModelDescriptionDataSourceEditComponent.prototype.onQueryOptionOptionClick = function (option) {
        if (option.value.queryType != QueryType.Simple) {
            this.editCustomQuery();
        }
    };
    ModelDescriptionDataSourceEditComponent.prototype.onQueryOptionButtonClick = function (button) {
        if (button.name == 'add_model') {
            this.addModel(button.data['addModelComponent']);
        }
    };
    ModelDescriptionDataSourceEditComponent.prototype.addModel = function (addModelComponent) {
        var _this = this;
        combineLatest([this.control.getResource$()])
            .pipe(first(), switchMap(function (_a) {
            var resource = _a[0];
            return _this.resourceModelEditController.addModel(resource, addModelComponent, {
                source: _this.analyticsSource
            });
        }), untilDestroyed(this))
            .subscribe(function (result) {
            if (result.link) {
                _this.routing.navigateApp(result.link);
            }
            else {
                var value = _this.control.getQueryOptionValue(result.modelDescription);
                _this.control.controls.query.setValue(value);
                _this.control.controls.query.markAsDirty();
            }
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.editCustomQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var query = this.control.controls.query.value;
        combineLatest([this.control.getResource$(), this.control.getResourceBaseHttpQuery$()])
            .pipe(first(), filter(function (_a) {
            var resource = _a[0], resourceBaseHttpQuery = _a[1];
            return resource;
        }), switchMap(function (_a) {
            var resource = _a[0], resourceBaseHttpQuery = _a[1];
            var queryCls = _this.control.getInstanceCls().queryCls;
            return _this.queryBuilderService.edit({
                injector: _this.injector,
                queryClass: queryCls,
                queryTypes: [query.queryType],
                resource: resource,
                resourceType: resource.type,
                context: _this.queryContext,
                requireResponse: true,
                query: query,
                parametersControl: _this.queryContext.parametersControl,
                httpOptions: {
                    baseQuery: resourceBaseHttpQuery,
                    initialResultsSection: options.initialHttpResultsSection
                },
                sqlOptions: {
                    initialResultsSection: options.initialSqlResultsSection
                },
                objectOptions: {
                    forceOperation: ObjectQueryOperation.Get
                },
                source: _this.analyticsSource
            });
        }), untilDestroyed(this))
            .subscribe(function (e) {
            if (e.saved) {
                _this.onCustomQuerySaved(e.saved);
            }
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.onCustomQuerySaved = function (event) {
        var query = event.query;
        this.control.controls.query.setValue(query);
        this.control.controls.query.markAsDirty();
        this.control.setAutoDetectColumns(query, this.control.isListQuery(), { merge: true, markAsDirty: true });
    };
    ModelDescriptionDataSourceEditComponent.prototype.editSavedQuery = function (modelDescription, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        combineLatest([
            this.control.getResource$(),
            this.control.getResourceBaseHttpQuery$(),
            this.control.getCustomQueryType$()
        ])
            .pipe(first(), switchMap(function (_a) {
            var resource = _a[0], resourceBaseHttpQuery = _a[1], queryType = _a[2];
            var query;
            var queryClass;
            var parameters;
            if (_this.control.isListQuery()) {
                query = modelDescription.getQuery;
                queryClass = ListModelDescriptionQuery;
                parameters = modelDescription.getParameters;
            }
            else {
                if (modelDescription.getDetailQuery) {
                    query = modelDescription.getDetailQuery;
                    queryClass = ModelDescriptionQuery;
                    parameters = modelDescription.getDetailParameters;
                }
                else {
                    query = modelDescription.getQuery;
                    queryClass = ListModelDescriptionQuery;
                    parameters = modelDescription.getParameters;
                }
            }
            if (!query) {
                query = new queryClass();
                query.queryType = queryType;
            }
            // TODO: Temporary solution for model parameters
            _this.queryContext.parametersControl.setValue(parameters);
            return _this.modelQueryEditController.editGetQuery({
                resource: resource,
                modelDescription: modelDescription,
                query: query,
                queryClass: queryClass,
                queryContext: _this.queryContext,
                httpOptions: {
                    baseQuery: resourceBaseHttpQuery,
                    initialResultsSection: options.initialHttpResultsSection
                },
                sqlOptions: {
                    initialResultsSection: options.initialSqlResultsSection
                },
                parametersControl: _this.queryContext.parametersControl,
                analyticsSource: _this.analyticsSource
            });
        }), untilDestroyed(this))
            .subscribe(function (model) {
            // TODO: Temporary solution for model parameters
            _this.queryContext.parametersControl.setValue([]);
            var columns = model.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) { return modelFieldToDisplayField(item); });
            _this.control.mergeColumns(columns, { markAsDirty: true });
            _this.control.setQueryUpdated({ markAsDirty: true });
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.editQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        combineLatest(this.queryEditable$, this.modelDescriptionCustom$)
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (_a) {
            var queryEditable = _a[0], modelDescription = _a[1];
            if (_this.control.controls.type.value == DataSourceType.Workflow) {
                _this.editWorkflow({ customizeTrigger: true });
            }
            else if (queryEditable) {
                _this.editCustomQuery(options);
            }
            else if (modelDescription) {
                _this.editSavedQuery(modelDescription, options);
            }
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.createSavedQuery = function () {
        var _this = this;
        combineLatest([this.control.getResource$(), this.control.getResourceBaseHttpQuery$()])
            .pipe(first(), switchMap(function (_a) {
            var resource = _a[0];
            return _this.modelEditController.create(resource, {
                query: _this.control.controls.query.value,
                parameters: _this.control.controls.query_parameters.value,
                columns: _this.control.controls.columns.value
            });
        }), untilDestroyed(this))
            .subscribe(function (result) {
            var value = _this.control.getQueryOptionValue(result);
            _this.control.controls.query.setValue(value);
            _this.control.controls.query.markAsDirty();
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.resetColumns = function () {
        this.control.resetInputColumns({
            context: this.context,
            contextElement: this.contextElement,
            markAsDirty: true
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.reload = function () {
        var _this = this;
        this.reloadLoading = true;
        this.cd.markForCheck();
        this.control
            .reloadResource()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.reloadLoading = false;
            _this.cd.markForCheck();
            if (result) {
                _this.notificationService.success('Synchronized', 'Resource was successfully updated');
            }
            else {
                _this.notificationService.error('Synchronized not available', 'No synchronization mechanics found');
            }
        }, function (e) {
            console.error(e);
            _this.notificationService.error('Synchronized Failed', 'Resource structure update failed');
        });
    };
    ModelDescriptionDataSourceEditComponent.prototype.editWorkflow = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var workflow;
        var parameters = cloneDeep(this.parameters);
        if (this.workflow) {
            workflow = cloneDeep(this.workflow);
        }
        else {
            workflow = new Workflow();
            workflow.generateUid();
        }
        this.workflowEditController
            .open({
            create: !this.workflow,
            workflow: workflow,
            workflowRun: workflow.testRun,
            workflowEditable: true,
            parametersEnabled: true,
            parameters: parameters,
            context: this.context,
            contextTokenProvider: this.contextTokenProvider,
            triggerLabel: 'Load data',
            customizeTrigger: options.customizeTrigger,
            historyEnabled: true,
            resultEnabled: true,
            analyticsSource: ['component', this.analyticsSource].join('_')
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            result.workflow.testRun = result.workflowRun;
            _this.control.controls.workflow.patchValue(result.workflow);
            _this.control.controls.query_parameters.patchValue(result.parameters);
            _this.cd.markForCheck();
        });
    };
    return ModelDescriptionDataSourceEditComponent;
}());
export { ModelDescriptionDataSourceEditComponent };

import { Inject, InjectionToken, NgZone, OnDestroy, Optional, Type } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { delayWhen, distinctUntilChanged, first, map, skip, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import {
  applyDisplayFieldsDefaultVisible,
  rawListViewSettingsColumnsToDisplayField,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DataSource, DataSourceType } from '@modules/data-sources';
import { CustomSelectItem, Option } from '@modules/field-components';
import {
  applyParamInput,
  BaseField,
  defaultFieldType,
  DisplayField,
  DisplayFieldType,
  Input,
  InputValueType,
  isInputSet,
  isRequiredInputsSet,
  ParameterArray
} from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { FieldInputControl, InputFieldProvider, InputFieldProviderItem } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { editableQueryTypes, HttpQuery, ModelDescriptionQuery, Query, QueryService, QueryType } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService, RestAPIResourceParams } from '@modules/resources';
import { Workflow } from '@modules/workflow';
import { controlValue, isSet } from '@shared';

import { DisplayFieldArray } from '../display-fields-edit/display-field.array';

export const validateType: ValidatorFn = control => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateResource: ValidatorFn = control => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  const type = parent.controls.type.value;

  if (![DataSourceType.Query].includes(type)) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateQuery: ValidatorFn = control => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  const type = parent.controls.type.value;

  if (![DataSourceType.Query].includes(type)) {
    return;
  }

  const query = control.value as Query;

  if (!query || !query.isConfigured()) {
    return { required: true };
  }
};

export const validateInput: ValidatorFn = control => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  const type = parent.controls.type.value;

  if (![DataSourceType.Input].includes(type)) {
    return;
  }

  if (!isInputSet(control.value)) {
    return { required: true };
  }
};

export const validateInputs: ValidatorFn = control => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  const type = parent.controls.type.value;

  if (![DataSourceType.Query].includes(type)) {
    return;
  }

  const fields = parent.inputFieldProvider.fields;
  const inputs: Input[] = control.value;

  if (!isRequiredInputsSet(fields, inputs)) {
    return { required: true };
  }
};

export const validateColumns: ValidatorFn = (control: DisplayFieldArray) => {
  const parent = control.parent as DataSourceControl;

  if (!parent || parent.validator !== Validators.required) {
    return;
  }

  if (!control.controls.length) {
    return { required: true };
  }
};

export const DATA_SOURCE_EXTRA_CONTROLS = new InjectionToken<string>('DATA_SOURCE_EXTRA_CONTROLS');

export interface DataSourceControls {
  type: FormControl;
  query_resource: FormControl;
  query: FormControl;
  query_parameters: ParameterArray;
  query_inputs: FormControl;
  input: FieldInputControl;
  workflow: FormControl;
  columns: DisplayFieldArray;
}

export abstract class DataSourceControl<T extends DataSource = DataSource> extends FormGroup implements OnDestroy {
  public static instanceCls: Type<DataSource>;

  instance: T;
  controls: {
    type: FormControl;
    query_resource: FormControl;
    query: FormControl;
    query_parameters: ParameterArray;
    query_inputs: FormControl;
    input: FieldInputControl;
    workflow: FormControl;
    columns: DisplayFieldArray;
  };
  resourceFieldParams: Object = {};
  inputFieldProvider = new InputFieldProvider();
  queryDefaultSet = new Subject<Query>();
  defaultJsonVisibility = false;

  typeOptions: Option<DataSourceType>[] = [
    { value: DataSourceType.Query, name: 'Load Data', icon: 'cloud_download' },
    { value: DataSourceType.Workflow, name: 'Load using Workflow', icon: 'workflow' },
    { value: DataSourceType.Input, name: 'Specify Data', icon: 'link' }
  ];

  abstract queryOptionEquals: (lhs: ModelDescriptionQuery, rhs: ModelDescriptionQuery) => boolean;

  constructor(
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected projectSettingsService: ProjectSettingsService,
    protected resourceControllerService: ResourceControllerService,
    protected resourceGeneratorResolver: ResourceGeneratorResolver,
    protected modelService: ModelService,
    protected actionService: ActionService,
    protected queryService: QueryService,
    protected notificationService: NotificationService,
    protected zone: NgZone,
    @Inject(DATA_SOURCE_EXTRA_CONTROLS)
    @Optional()
    extraControls: {
      [key: string]: AbstractControl;
    } = {}
  ) {
    super({
      type: new FormControl(DataSourceType.Query, validateType),
      query_resource: new FormControl('', validateResource),
      query: new FormControl(null, validateQuery),
      query_parameters: new ParameterArray([]),
      query_inputs: new FormControl([], validateInputs),
      input: new FieldInputControl({ name: 'value' }, validateInput),
      workflow: new FormControl(null),
      columns: new DisplayFieldArray([], validateColumns),
      ...extraControls
    });

    this.inputFieldProvider.setProvider(this.getInputFieldProvider$());

    this.inputFieldProvider.fields$.pipe(untilDestroyed(this)).subscribe(() => {
      this.controls.query_inputs.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  setRequired(required: boolean) {
    if (required) {
      this.setValidators(Validators.required);
      values(this.controls).forEach(item => item.updateValueAndValidity());
    } else {
      this.clearValidators();
      values(this.controls).forEach(item => item.updateValueAndValidity());
    }
  }

  deserializeExtraControls?(instance: T, initial = true) {}

  clear() {
    this.controls.type.setValue(DataSourceType.Query);
    this.controls.query_resource.setValue('');
    this.controls.query.setValue(null);
    this.controls.query_parameters.setValue([]);
    this.controls.query_inputs.setValue([]);
    this.controls.input.clearValue({ name: 'value' });
    this.controls.workflow.setValue(null);
    this.controls.columns.setValue([]);
  }

  deserialize(instance: T, initial = true) {
    this.instance = instance;

    if (instance) {
      this.controls.type.setValue(instance.type);
      this.controls.query_resource.setValue(instance.queryResource);
      this.controls.query.setValue(instance.query);
      this.controls.query_parameters.setValue(instance.queryParameters);
      this.controls.query_inputs.setValue(instance.queryInputs);
      this.controls.workflow.setValue(instance.workflow);
      this.controls.columns.setValue(instance.columns);

      if (instance.input) {
        this.controls.input.patchValue(instance.input.serialize());
      } else {
        this.controls.input.clearValue({ name: 'value' });
      }
    } else {
      this.clear();
    }

    if (this.deserializeExtraControls) {
      this.deserializeExtraControls(instance, initial);
    }

    if (initial) {
      this.markAsPristine();

      controlValue<DataSourceType>(this.controls.type)
        .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
        .subscribe(value => this.onTypeChange(value));

      this.controls.query_resource.valueChanges
        .pipe(
          distinctUntilChanged(),
          switchMap(() => this.getQueryOptions$().pipe(first())),
          untilDestroyed(this)
        )
        .subscribe(items => {
          const options = items.filter(item => item.option).map(item => item.option);

          if (options[0]) {
            this.zone.run(() => {
              this.controls.query.setValue(options[0].value);
              this.queryDefaultSet.next(options[0].value);
            });
          }
        });

      controlValue<Workflow>(this.controls.workflow)
        .pipe(skip(1), untilDestroyed(this))
        .subscribe(value => this.onWorkflowChange(value));
    }
  }

  serialize(): T {
    if (!this.controls.type.value) {
      return;
    }

    const instanceCls = this.getInstanceCls();
    const result = new instanceCls();

    result.type = this.controls.type.value;

    if (result.type == DataSourceType.Query) {
      result.queryResource = this.controls.query_resource.value;
      result.query = this.controls.query.value;
      result.queryParameters = this.controls.query_parameters.value;
      result.queryInputs = this.controls.query_inputs.value;
    } else if (result.type == DataSourceType.Input) {
      result.input = this.controls.input.value ? new Input().deserialize(this.controls.input.value) : undefined;
      result.queryInputs = this.controls.query_inputs.value;
    } else if (result.type == DataSourceType.Workflow) {
      result.workflow = this.controls.workflow.value;
      result.queryParameters = this.controls.query_parameters.value;
      result.queryInputs = this.controls.query_inputs.value;
    }

    result.columns = this.controls.columns.value;

    return result;
  }

  getInstanceCls(): Type<T> {
    return (this.constructor as typeof DataSourceControl).instanceCls as Type<T>;
  }

  getResource$(): Observable<Resource> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      controlValue<string>(this.controls.query_resource)
    ).pipe(
      switchMap(([type, resource]) => {
        if (type != DataSourceType.Query) {
          return of(undefined);
        }

        return this.currentEnvironmentStore.resources$.pipe(
          map(resources => {
            return resources.find(item => item.uniqueName == resource);
          })
        );
      })
    );
  }

  getResourceBaseHttpQuery$(): Observable<HttpQuery> {
    return this.getResource$().pipe(
      map(resource => {
        if (!resource) {
          return;
        }

        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        return resourceParams.baseHttpQuery;
      })
    );
  }

  getResourceReloadAllowed$(): Observable<boolean> {
    return this.getResource$().pipe(
      map(resource => {
        if (!resource) {
          return false;
        }

        if (resource) {
          const generator = this.resourceGeneratorResolver.get(resource.typeItem.name);
          return this.projectSettingsService.syncAllowed(resource, generator) && !resource.demo;
        } else {
          return false;
        }
      })
    );
  }

  getCustomQueryType$(): Observable<QueryType> {
    return this.getResource$().pipe(
      map(resource => {
        if (!resource) {
          return;
        }

        const controller = this.resourceControllerService.get(resource.type);
        const queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
        return editableQueryTypes.find(item => queryTypes.includes(item));
      })
    );
  }

  getQueryEditable$(): Observable<boolean> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      controlValue<Query>(this.controls.query)
    ).pipe(
      map(([type, query]) => {
        if (type == DataSourceType.Query) {
          return query && editableQueryTypes.includes(query.queryType);
        } else if (type == DataSourceType.Workflow) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getDataConfigured$(options: { columnsOptional?: boolean } = {}): Observable<boolean> {
    return controlValue(this).pipe(
      map(() => {
        if (this.controls.type.value == DataSourceType.Query) {
          return (
            isSet(this.controls.query_resource.value) &&
            isSet(this.controls.query.value) &&
            this.controls.query.value.isConfigured() &&
            (options.columnsOptional || this.controls.columns.value.length > 0)
          );
        } else if (this.controls.type.value == DataSourceType.Input) {
          return isInputSet(this.controls.input.value) && this.controls.columns.controls.length > 0;
        } else if (this.controls.type.value == DataSourceType.Workflow) {
          return this.controls.workflow.value && this.controls.columns.controls.length > 0;
        } else {
          return false;
        }
      })
    );
  }

  getQueryConfigured$(): Observable<boolean> {
    return controlValue(this).pipe(
      map(() => {
        if (this.controls.type.value == DataSourceType.Query) {
          return isSet(this.controls.query.value) && this.controls.query.value.isConfigured();
        } else if (this.controls.type.value == DataSourceType.Input) {
          return isInputSet(this.controls.input.value);
        } else if (this.controls.type.value == DataSourceType.Workflow) {
          return this.controls.workflow.value;
        } else {
          return false;
        }
      })
    );
  }

  getInput$(): Observable<Input> {
    return controlValue<Object>(this.controls.input).pipe(
      map(value => (value ? new Input().deserialize(value) : undefined))
    );
  }

  getColumnOptions$(): Observable<Option<string>[]> {
    return controlValue<DisplayField[]>(this.controls.columns).pipe(
      map(columnsValue => {
        if (!columnsValue) {
          return [];
        }

        return columnsValue
          .filter(item => item.type == DisplayFieldType.Base)
          .map(item => {
            return {
              value: item.name,
              name: item.verboseName || item.name,
              icon: item.icon
            };
          });
      })
    );
  }

  abstract getQueryOptions$(): Observable<CustomSelectItem<Query>[]>;

  abstract getInputFieldProvider$(): Observable<InputFieldProviderItem[]>;

  setQueryUpdated(options: { markAsDirty?: boolean } = {}) {
    const query: Query = cloneDeep(this.controls.query.value);

    query.updated = moment();

    this.controls.query.setValue(query);

    if (options.markAsDirty) {
      this.controls.query.markAsDirty();
    }
  }

  reloadResource(): Observable<boolean> {
    return this.getResource$().pipe(
      first(),
      switchMap(resource => {
        if (!resource) {
          return;
        }

        const generator = this.resourceGeneratorResolver.get(resource.typeItem.name);

        if (!generator) {
          return of(false);
        }

        return this.projectSettingsService
          .syncResource(resource, generator, { mergeExisting: true })
          .pipe(map(() => true));
      }),
      delayWhen(() => this.onResourceReloaded())
    );
  }

  onTypeChange(type: DataSourceType) {
    if (type == DataSourceType.Input) {
      this.setColumns([]);
    }
  }

  onResourceReloaded(): Observable<void> {
    return of(undefined);
  }

  getWorkflowColumns(workflow: Workflow): Observable<DisplayField[]> {
    return this.actionService.getWorkflowOutputs(workflow).pipe(
      map(result => {
        return result.outputs.map(item => {
          const field = new DisplayField({
            name: item.name,
            verboseName: item.verboseName,
            field: item.field,
            params: item.params
          });
          field.updateFieldDescription();
          return field;
        });
      })
    );
  }

  onWorkflowChange(workflow: Workflow) {
    this.getWorkflowColumns(workflow)
      .pipe(untilDestroyed(this))
      .subscribe(columns => {
        this.setColumns(columns);
        this.controls.query_parameters.setValue([]);
      });
  }

  setColumns(columns: DisplayField[], options: { markAsDirty?: boolean; modelDescription?: ModelDescription } = {}) {
    columns = applyDisplayFieldsDefaultVisible(columns, {
      maxVisible: 6,
      modelDescription: options.modelDescription,
      defaultJsonVisibility: this.defaultJsonVisibility
    });

    this.controls.columns.setValue(columns);

    if (options.markAsDirty) {
      this.controls.columns.markAsDirty();
    }
  }

  mergeColumns(newColumns: DisplayField[], options: { markAsDirty?: boolean } = {}) {
    const prevColumns = (this.controls.columns.value as DisplayField[]) || [];
    const result = [
      ...prevColumns.filter(item => newColumns.find(i => i.name == item.name) || item.type != DisplayFieldType.Base),
      ...newColumns.filter(item => !prevColumns.find(i => i.name == item.name))
    ];

    this.setColumns(result, options);
  }

  setAutoDetectColumns(query: Query, isDataArray: boolean, options: { merge?: boolean; markAsDirty?: boolean } = {}) {
    const responseColumns = this.queryService.getAutoDetectColumns(query, isDataArray);

    if (!responseColumns) {
      this.notificationService.error('Failed to detect fields', 'Response is incorrect');
      return;
    }

    const columns = responseColumns.map(field => rawListViewSettingsColumnsToDisplayField(field));

    if (options.merge) {
      this.mergeColumns(columns, { markAsDirty: options.markAsDirty });
    } else {
      this.setColumns(columns, { markAsDirty: options.markAsDirty });
    }
  }

  isColumnsSame$(lhs: BaseField[], rhs: BaseField[]): boolean {
    lhs = lhs.filter(item => !item['flex']);
    rhs = rhs.filter(item => !item['flex']);

    return lhs.length == rhs.length && lhs.every(lhsItem => !!rhs.find(rhsItem => rhsItem.name == lhsItem.name));
  }

  isListQuery() {
    return false;
  }

  getSimpleQueryDefaultColumns(): Observable<DisplayField[]> {
    return of([]);
  }

  getCustomQueryDefaultColumns(query: Query, isDataArray: boolean): Observable<BaseField[]> {
    const data = query.getRequestResponse();
    const columns = isDataArray
      ? this.queryService.autoDetectGetFields(data)
      : this.queryService.autoDetectGetDetailFields(data);
    return of(columns);
  }

  getInputDefaultColumns(
    isDataArray: boolean,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<BaseField[]> {
    const getContextColumns = (value: Input): Observable<BaseField[]> => {
      return options.context.getToken$(value.contextValue, options.contextElement).pipe(
        map(result => {
          if (!result || !result.children || !result.children.length) {
            return;
          }

          const columns =
            isDataArray && result.children[0].children && result.children[0].children.length
              ? result.children[0].children
              : result.children;

          return columns.map(item => {
            return {
              name: item.uniqueName,
              verboseName: item.name,
              field: item.fieldType || defaultFieldType,
              params: item.fieldParams || {}
            };
          });
        })
      );
    };

    return controlValue(this.controls.input).pipe(
      switchMap(value => {
        const input = value ? new Input().deserialize(value) : undefined;

        if (!input) {
          return of(undefined);
        }

        const contextColumns$ =
          options.context && input.valueType == InputValueType.Context ? getContextColumns(input) : of(undefined);

        return contextColumns$.pipe(
          map(contextColumns => {
            if (contextColumns) {
              return contextColumns;
            }

            try {
              const data = applyParamInput(input, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: {}
              });

              return isDataArray
                ? this.queryService.autoDetectGetFields(data)
                : this.queryService.autoDetectGetDetailFields(data);
            } catch (e) {}
          })
        );
      })
    );
  }

  getAutoDetectedColumns$(
    isDataArray: boolean,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<DisplayField[]> {
    if (this.controls.type.value == DataSourceType.Query) {
      const query = this.controls.query.value as Query;
      if (!query) {
        return of(undefined);
      }

      if (query.queryType == QueryType.Simple) {
        return this.getSimpleQueryDefaultColumns();
      } else {
        return this.getCustomQueryDefaultColumns(query, isDataArray).pipe(
          map(columns => {
            if (!columns) {
              return;
            }

            return columns.map(item => rawListViewSettingsColumnsToDisplayField(item));
          })
        );
      }
    } else {
      return this.getInputDefaultColumns(isDataArray, options).pipe(
        map(columns => {
          if (!columns) {
            return;
          }

          return columns.map(item => rawListViewSettingsColumnsToDisplayField(item));
        })
      );
    }
  }

  resetInputColumns(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      markAsDirty?: boolean;
    } = {}
  ) {
    const isDataArray = this.isListQuery();

    return this.getAutoDetectedColumns$(isDataArray, {
      context: options.context,
      contextElement: options.contextElement,
      localContext: options.localContext
    })
      .pipe(first(), untilDestroyed(this))
      .subscribe(columns => {
        if (columns) {
          this.setColumns(columns, { markAsDirty: options.markAsDirty });
        }
      });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { BarCodeFormat } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue } from '@shared';
import { MarginControl } from '../margin-control/margin-control.component';
var CustomizeBarBarCodeEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarBarCodeEditForm, _super);
    function CustomizeBarBarCodeEditForm(currentProjectStore, currentEnvironmentStore, elementConfigurationService) {
        var _this = _super.call(this, {
            format: new FormControl(BarCodeFormat.CODE128),
            value: new FieldInputControl({ name: 'value' }, Validators.required),
            fill_color: new FormControl(''),
            background_color: new FormControl('white'),
            display_text: new FormControl(true),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.elementConfigurationService = elementConfigurationService;
        _this.formatOptions = [
            { value: BarCodeFormat.CODE128, name: 'CODE128 auto', subtitle: 'Example 1234' },
            { value: BarCodeFormat.CODE128A, name: 'CODE128 A', subtitle: 'EXAMPLE1234' },
            { value: BarCodeFormat.CODE128B, name: 'CODE128 B', subtitle: 'Example1234' },
            { value: BarCodeFormat.CODE128C, name: 'CODE128 C', subtitle: '12345678' },
            { value: BarCodeFormat.EAN13, name: 'EAN13', subtitle: '1234567890128' },
            { value: BarCodeFormat.EAN8, name: 'EAN8', subtitle: '12345670' },
            { value: BarCodeFormat.EAN5, name: 'EAN5', subtitle: '12340' },
            { value: BarCodeFormat.EAN2, name: 'EAN2', subtitle: '10' },
            { value: BarCodeFormat.UPC, name: 'UPC', subtitle: '123456789999' },
            { value: BarCodeFormat.CODE39, name: 'CODE39', subtitle: 'EXAMPLE TEXT' },
            { value: BarCodeFormat.ITF14, name: 'ITF14', subtitle: '10012345000017' },
            { value: BarCodeFormat.ITF, name: 'ITF', subtitle: '123456' },
            { value: BarCodeFormat.MSI, name: 'MSI', subtitle: '123456' },
            { value: BarCodeFormat.MSI10, name: 'MSI10', subtitle: '123456' },
            { value: BarCodeFormat.MSI11, name: 'MSI11', subtitle: '123456' },
            { value: BarCodeFormat.MSI1010, name: 'MSI1010', subtitle: '123456' },
            { value: BarCodeFormat.MSI1110, name: 'MSI1110', subtitle: '123456' },
            { value: BarCodeFormat.Pharmacode, name: 'Pharmacode', subtitle: '1234' },
            { value: BarCodeFormat.Codabar, name: 'Codabar', subtitle: '1234567890' }
        ];
        return _this;
    }
    CustomizeBarBarCodeEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarBarCodeEditForm.prototype.init = function (element, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            format: element.format || BarCodeFormat.CODE128,
            value: element.value ? element.value.serialize() : {},
            fill_color: element.fillColor,
            background_color: element.backgroundColor,
            display_text: element.displayText,
            visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        this.controls.format.valueChanges.pipe(untilDestroyed(this)).subscribe(function (formatValue) {
            var format = _this.formatOptions.find(function (item) { return item.value == formatValue; });
            if (format) {
                _this.controls.value.controls.value_type.patchValue(InputValueType.StaticValue);
                _this.controls.value.controls.static_value.patchValue(format.subtitle);
            }
        });
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarBarCodeEditForm.prototype.getSelectedFormat$ = function () {
        var _this = this;
        return controlValue(this.controls.format).pipe(map(function (value) { return _this.formatOptions.find(function (item) { return item.value == value; }); }));
    };
    CustomizeBarBarCodeEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isBarCodeConfigured(instance);
    };
    CustomizeBarBarCodeEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = cloneDeep(this.element);
        instance.format = value['format'];
        instance.value = value['value'] ? new FieldInput().deserialize(value['value']) : undefined;
        instance.fillColor = value['fill_color'];
        instance.backgroundColor = value['background_color'];
        instance.displayText = value['display_text'];
        instance.margin = value['margin'];
        if (value['visible_input']) {
            instance.visibleInput = new Input().deserialize(value['visible_input']);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarBarCodeEditForm;
}(FormGroup));
export { CustomizeBarBarCodeEditForm };

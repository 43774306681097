export class AppVersion {
  public name: string;
  public active = false;
  public params = {};
  public dateAdd: string;
  public notice: string;
  public latest = false;

  deserialize(data: Object): AppVersion {
    this.name = data['name'];
    this.active = data['active'];
    this.dateAdd = data['date_add'];

    if (data['params']) {
      this.params = data['params'];
    }

    if (data['latest'] != undefined) {
      this.latest = data['latest'];
    }

    if (this.params) {
      this.notice = this.params['notice'];
    }

    return this;
  }

  get str() {
    if (this.notice) {
      return `${this.name} - ${this.notice}`;
    } else {
      return this.name;
    }
  }
}

import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  createFormFieldFactory,
  FieldType,
  FormField,
  getFieldDescriptionByType,
  ParameterControl
} from '@modules/fields';
import { controlValue, isSet, setControlEnabled, TypedChanges } from '@shared';

@Component({
  selector: 'app-query-builder-parameters-edit-item',
  templateUrl: './query-builder-parameters-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderParametersEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: ParameterControl;
  @Input() value: any;
  @Input() autofocus = false;
  @Output() deleteRequested = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<any>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  createField = createFormFieldFactory();
  formField: FormField;
  valueControl = new FormControl(null);
  form: FormGroup;
  controlName = 'field';
  popoverOpened = false;
  fieldIcon$: Observable<string>;
  fieldTypes = FieldType;

  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form = new FormGroup({
      [this.controlName]: this.valueControl
    });

    controlValue(this.control)
      .pipe(
        map(() => this.control.serialize()),
        untilDestroyed(this)
      )
      .subscribe(field => {
        if (field.field == FieldType.Boolean) {
          this.formField = new FormField().deserialize({
            name: this.controlName,
            field: FieldType.Select,
            params: {
              valueEquals: this.booleanValueEquals,
              options: [
                { value: null, name: 'Not specified' },
                { value: false, name: 'No' },
                { value: true, name: 'Yes' }
              ],
              classes: ['select_fill']
            }
          });
        } else {
          const classes = field.params && field.params['classes'] ? field.params['classes'] : [];

          this.formField = new FormField();
          this.formField.name = this.controlName;
          this.formField.field = field.field;
          this.formField.params = {
            ...field.params,
            classes: ['select_fill', 'input_fill', ...classes],
            background: true,
            thumb_inside: true
          };
        }

        this.formField.resetEnabled = true;
        this.formField.placeholder = this.controlPlaceholder;
        this.cd.markForCheck();

        setControlEnabled(this.valueControl, isSet(field.name));
      });

    this.valueControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.valueChange.emit(value);
    });

    this.fieldIcon$ = controlValue<FieldType>(this.control.controls.field).pipe(
      map(value => getFieldDescriptionByType(value).icon)
    );

    this.control.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (typeof value == 'string') {
        const cleanValue = value.replace(/[^\w]/g, '_').replace(/^\d/, '_');

        if (cleanValue != value) {
          this.control.controls.name.patchValue(cleanValue);
        }
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<QueryBuilderParametersEditItemComponent>): void {
    if (changes.value) {
      if (isSet(this.value)) {
        this.valueControl.patchValue(this.value, { emitEvent: false });
      } else {
        this.valueControl.patchValue(null, { emitEvent: false });
      }
    }
  }

  setPopoverOpened(opened: boolean) {
    this.popoverOpened = opened;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  get controlPlaceholder() {
    return isSet(this.control.controls.description.value)
      ? this.control.controls.description.value
      : `Test value for {{params.${this.control.controls.name.value || 'name'}}}`;
  }
}

<div
  cdkDropList
  class="choose-template-list2"
  [cdkDropListLockAxis]="'y'"
  (cdkDropListDropped)="dragDropOption($event)"
>
  <div *ngFor="let control of control.controls; let i = index" cdkDrag class="choose-template-list2__item">
    <div
      class="choose-template-list2-item choose-template-list2-item_interactive"
      [class.choose-template-list2-item_active]="control.controls.enabled.value"
      (click)="control.toggleEnabled()"
    >
      <div class="choose-template-list2-item__left">
        <div
          class="big-square-checkbox big-square-checkbox_orange"
          [class.big-square-checkbox_checked]="control.controls.enabled.value"
        ></div>
      </div>

      <div class="choose-template-list2-item__main">
        <div class="choose-template-list2-item__title">{{ control.instance.verboseName || control.instance.name }}</div>
        <div class="choose-template-list-item__subtitle">
          {{ control.fieldDescription.label }}
        </div>
      </div>

      <div class="choose-template-list2-item__right">
        <span class="choose-template-list2-item__icon icon-dots_small"></span>
      </div>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DraggableItemDirective } from './directives/draggable-item/draggable-item.directive';
import { DraggableDirective } from './directives/draggable/draggable.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DraggableDirective, DraggableItemDirective],
  exports: [DraggableDirective, DraggableItemDirective]
})
export class DragDropModule {}

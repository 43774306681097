import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionDescription, ActionItem, ActionType, DownloadAction, QueryAction } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { FeatureService } from '@modules/features';
import { CustomSelectItemButton } from '@modules/field-components';
import {
  CurrentProjectStore,
  isResourceTypeItem3rdParty,
  isResourceTypeItemCustom,
  Resource,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import {
  ActionWorkflowStep,
  cleanWorkflowStepName,
  ConditionWorkflowStep,
  ConditionWorkflowStepItem,
  ConditionWorkflowStepType,
  DelayWorkflowStep,
  ExitWorkflowStep,
  ForkWorkflowStep,
  ForkWorkflowStepItem,
  IteratorWorkflowStep,
  TransformWorkflowStep,
  WorkflowStep,
  WorkflowStepType
} from '@modules/workflow';

import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { AddStepOption } from '../add-step-dropdown/add-step-dropdown.component';

@Component({
  selector: 'app-add-step-menu',
  templateUrl: './add-step-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStepMenuComponent implements OnInit, OnDestroy {
  @Input() actionTypesEnabled: ActionType[];
  @Output() add = new EventEmitter<WorkflowStep>();

  @ViewChild(MatMenu) menu: MatMenu;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private resourceEditController: ResourceEditController,
    private featureService: FeatureService,
    private workflowEditContext: WorkflowEditContext,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  openResourceSettings(typeItem: ResourceTypeItem, type: WorkflowStepType, actionType: ActionType) {
    if (
      isResourceTypeItem3rdParty(typeItem) &&
      !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Business Apps</strong>',
        description: `
          Connect data from various business apps. You can see all your data and take action in one place.
        `
      });
      return;
    } else if (
      isResourceTypeItemCustom(typeItem) &&
      !this.currentProjectStore.instance.features.isCustomResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Custom Queries</strong>',
        description: `
          Connect data from custom queries. You can see all your data and take action in one place.
        `
      });
      return;
    }

    // if (!resource) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
      Source: 'component',
      ResourceID: typeItem.name
    });
    // }

    this.resourceEditController
      .openEditPopup(typeItem, undefined, {
        resourceNameEditing: true,
        analyticsSource: 'component'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.resource) {
          return;
        }

        this.addStep(type, actionType, { resource: result.resource });
      });
  }

  createStep(
    type: WorkflowStepType,
    actionType: ActionType,
    options: {
      resource?: Resource;
      conditionType?: ConditionWorkflowStepType;
    } = {}
  ): WorkflowStep {
    if (type == WorkflowStepType.Action) {
      const result = new ActionWorkflowStep();

      result.action = new ActionItem();
      result.action.actionDescription = new ActionDescription();
      result.action.actionDescription.type = actionType;

      if (actionType == ActionType.Query) {
        result.action.actionDescription.resource = options.resource.uniqueName;
        result.action.actionDescription.queryAction = new QueryAction();
      } else if (actionType == ActionType.Download) {
        result.action.actionDescription.resource = options.resource.uniqueName;
        result.action.actionDescription.downloadAction = new DownloadAction();
      }

      const name = [result.defaultName()];

      if (options.resource) {
        name.push(options.resource.name);
      }

      result.generateUid();
      result.name = cleanWorkflowStepName(name.join(' - '), result, this.workflowEditContext.state.workflow.steps);

      return result;
    } else if (type == WorkflowStepType.Condition) {
      const result = new ConditionWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);
      result.conditionType = options.conditionType;

      const trueItem = new ConditionWorkflowStepItem();
      const falseItem = new ConditionWorkflowStepItem();

      result.items = [trueItem, falseItem];

      return result;
    } else if (type == WorkflowStepType.Fork) {
      const result = new ForkWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);

      const item1 = new ForkWorkflowStepItem();
      const item2 = new ForkWorkflowStepItem();

      result.items = [item1, item2];

      return result;
    } else if (type == WorkflowStepType.Iterator) {
      const result = new IteratorWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);
      result.dataSource = new ListModelDescriptionDataSource();
      result.dataSource.type = DataSourceType.Query;
      // result.dataSource.type = DataSourceType.Input;
      // result.dataSource.input = new FieldInput();
      // result.dataSource.input.valueType = InputValueType.Formula;
      // result.dataSource.input.formulaValue = JSON.stringify([{ name: 'Example 1' }, { name: 'Example 2' }]);
      // result.dataSource.columns = [new DisplayField({ name: 'name', field: FieldType.Text })];

      return result;
    } else if (type == WorkflowStepType.Delay) {
      const result = new DelayWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);
      result.delay = 3;

      return result;
    } else if (type == WorkflowStepType.Transform) {
      const result = new TransformWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);

      return result;
    } else if (type == WorkflowStepType.Exit) {
      const result = new ExitWorkflowStep();

      result.generateUid();
      result.name = cleanWorkflowStepName(result.defaultName(), result, this.workflowEditContext.state.workflow.steps);

      return result;
    }
  }

  addStep(
    type: WorkflowStepType,
    actionType: ActionType,
    options: {
      resource?: Resource;
      conditionType?: ConditionWorkflowStepType;
    } = {}
  ) {
    const step = this.createStep(type, actionType, options);
    this.add.emit(step);
  }

  onButtonClick(button: CustomSelectItemButton, trigger?: MatMenuTrigger) {
    if (trigger) {
      trigger.closeMenu();
    }

    if (button.name == AddStepOption.CreateResource) {
      this.openResourceSettings(button.data['resourceTypeItem'], button.data['type'], button.data['actionType']);
    } else if (button.name == AddStepOption.AddStep) {
      this.addStep(button.data['type'], button.data['actionType'], {
        resource: button.data['resource'],
        conditionType: button.data['conditionType']
      });
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ProjectProperty } from '@modules/projects';

import { SignUpFieldControl } from '../../project-settings/sign-up-field.control';

@Component({
  selector: 'app-sign-up-field-control',
  templateUrl: './sign-up-field-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpFieldControlComponent implements OnInit, OnDestroy {
  @Input() control: SignUpFieldControl;
  @Input() property: ProjectProperty;
  @Input() analyticsSource: string;
  @Output() edit = new EventEmitter<{ control: SignUpFieldControl; property: ProjectProperty }>();
  @Output() remove = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  editProperty() {
    this.edit.emit({ control: this.control, property: this.property });
  }
}

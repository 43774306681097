/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/observers";
import * as i2 from "../../../field-components/components/fields-edit-item/fields-edit-item.component.ngfactory";
import * as i3 from "../../../field-components/components/fields-edit-item/fields-edit-item.component";
import * as i4 from "../../../customize/data/view-context";
import * as i5 from "../../../customize/data/view-context-element";
import * as i6 from "../../../parameters-components/services/view-context-token-provider/view-context-token-provider";
import * as i7 from "../../../../common/popover/components/popover/popover.component";
import * as i8 from "../../../projects/stores/current-environment.store";
import * as i9 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i10 from "../../../fields/components/auto-field/auto-field.component";
import * as i11 from "@angular/common";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/forms";
import * as i15 from "../../../../shared/components/autofocus/autofocus.directive";
import * as i16 from "../../../../core/services/app-config/app-config.service";
import * as i17 from "../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i18 from "../../../../shared/components/toggle/toggle.component.ngfactory";
import * as i19 from "../../../../shared/components/toggle/toggle.component";
import * as i20 from "./query-builder-parameters-edit-item.component";
var styles_QueryBuilderParametersEditItemComponent = [];
var RenderType_QueryBuilderParametersEditItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderParametersEditItemComponent, data: {} });
export { RenderType_QueryBuilderParametersEditItemComponent as RenderType_QueryBuilderParametersEditItemComponent };
function View_QueryBuilderParametersEditItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "query-builder-list-item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.description.value; _ck(_v, 1, 0, currVal_0); }); }
function View_QueryBuilderParametersEditItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, [[null, "cdkObserveContent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkObserveContent" === en)) {
        var pd_0 = (_co.onPopoverContentChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 1196032, null, 0, i1.CdkObserveContent, [i1.ContentObserver, i0.ElementRef, i0.NgZone], null, { event: "cdkObserveContent" }), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "popover2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "app-fields-edit-item", [], null, [[null, "saveRequested"], [null, "deleteRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveRequested" === en)) {
        var pd_0 = (_co.setPopoverOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("deleteRequested" === en)) {
        var pd_1 = (_co.deleteRequested.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FieldsEditItemComponent_0, i2.RenderType_FieldsEditItemComponent)), i0.ɵdid(6, 245760, null, 0, i3.FieldsEditItemComponent, [[2, i4.ViewContext], [2, i5.ViewContextElement], [2, i6.ViewContextTokenProvider], [2, i7.PopoverComponent], i8.CurrentEnvironmentStore, i0.ChangeDetectorRef], { control: [0, "control"], configurable: [1, "configurable"] }, { saveRequested: "saveRequested", deleteRequested: "deleteRequested" }), i0.ɵpod(7, { verboseName: 0, field: 1, required: 2, params: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _ck(_v, 7, 0, true, true, true, true); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
function View_QueryBuilderParametersEditItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "query-builder-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-auto-field", [], null, null, null, i9.View_AutoFieldComponent_0, i9.RenderType_AutoFieldComponent)), i0.ɵdid(2, 770048, null, 0, i10.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.formField; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_QueryBuilderParametersEditItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderParametersEditItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 30, "div", [["class", "query-builder-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 13, "div", [["class", "query-builder-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 6, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setPopoverOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setPopoverOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_QueryBuilderParametersEditItemComponent_2)), i0.ɵdid(6, 671744, [[1, 4]], 0, i12.CdkConnectedOverlay, [i12.Overlay, i0.TemplateRef, i0.ViewContainerRef, i12.ɵc, [2, i13.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpod(7, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(8, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpod(9, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpad(10, 3), i0.ɵpad(11, 1), (_l()(), i0.ɵeld(12, 0, [["button", 1]], null, 5, "a", [["cdkOverlayOrigin", ""], ["class", "button button_icon button_orange-alternative button_small"], ["href", "javascript:void(0)"], ["style", "margin: 0;"]], [[2, "button_primary", null], [2, "button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPopoverOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, [["trigger", 4]], 0, i12.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "span", [["class", "icon button__icon"]], null, null, null, null, null)), i0.ɵdid(15, 278528, null, 0, i11.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpad(17, 1), (_l()(), i0.ɵeld(18, 0, null, null, 8, "div", [["class", "query-builder-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 7, "input", [["class", "input input_fill"], ["placeholder", "Field name"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(20, 16384, null, 0, i14.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i14.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i14.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i14.DefaultValueAccessor]), i0.ɵdid(22, 540672, null, 0, i14.FormControlDirective, [[8, null], [8, null], [6, i14.NG_VALUE_ACCESSOR], [2, i14.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i14.NgControl, null, [i14.FormControlDirective]), i0.ɵdid(24, 16384, null, 0, i14.NgControlStatus, [[4, i14.NgControl]], null, null), i0.ɵdid(25, 540672, null, 0, i15.AutofocusDirective, [i0.ElementRef, i16.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null), i0.ɵdid(26, 81920, null, 0, i17.FormControlSelectorDirective, [i0.ElementRef, [2, i14.FormControlDirective], [2, i14.FormControlName]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderParametersEditItemComponent_3)), i0.ɵdid(28, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(29, 0, null, null, 4, "div", [["class", "query-builder-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 2, "a", [["href", "javascript:void(0)"], ["style", "margin-right: 8px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.control.controls.required.patchValue(!_co.control.controls.required.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 1, "app-toggle", [], null, null, null, i18.View_ToggleComponent_0, i18.RenderType_ToggleComponent)), i0.ɵdid(32, 114688, null, 0, i19.ToggleComponent, [], { label: [0, "label"], labelPosition: [1, "labelPosition"], orange: [2, "orange"], selected: [3, "selected"] }, null), (_l()(), i0.ɵeld(33, 0, null, null, 0, "a", [["class", "query-builder-list-item__action icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteRequested.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.description.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 13); var currVal_2 = _ck(_v, 10, 0, _ck(_v, 7, 0, "center", "center", "top", "bottom", 0, (8 - 8)), _ck(_v, 8, 0, "center", "center", "bottom", "top", 0, ((0 - 8) + 8)), _ck(_v, 9, 0, "end", "start", "center", "center", (8 + 8), 0)); var currVal_3 = "popover2-backdrop"; var currVal_4 = _ck(_v, 11, 0, "overlay"); var currVal_5 = _co.popoverOpened; var currVal_6 = true; var currVal_7 = true; var currVal_8 = true; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = "icon button__icon"; var currVal_12 = _ck(_v, 17, 0, ("icon-" + i0.ɵunv(_v, 15, 1, i0.ɵnov(_v, 16).transform(_co.fieldIcon$)))); _ck(_v, 15, 0, currVal_11, currVal_12); var currVal_20 = _co.control.controls.name; _ck(_v, 22, 0, currVal_20); var currVal_21 = _co.autofocus; _ck(_v, 25, 0, currVal_21); _ck(_v, 26, 0); var currVal_22 = _co.formField; _ck(_v, 28, 0, currVal_22); var currVal_23 = "Required"; var currVal_24 = "left"; var currVal_25 = true; var currVal_26 = _co.control.controls.required.value; _ck(_v, 32, 0, currVal_23, currVal_24, currVal_25, currVal_26); }, function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.popoverOpened; var currVal_10 = _co.popoverOpened; _ck(_v, 12, 0, currVal_9, currVal_10); var currVal_13 = i0.ɵnov(_v, 24).ngClassUntouched; var currVal_14 = i0.ɵnov(_v, 24).ngClassTouched; var currVal_15 = i0.ɵnov(_v, 24).ngClassPristine; var currVal_16 = i0.ɵnov(_v, 24).ngClassDirty; var currVal_17 = i0.ɵnov(_v, 24).ngClassValid; var currVal_18 = i0.ɵnov(_v, 24).ngClassInvalid; var currVal_19 = i0.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }); }
export function View_QueryBuilderParametersEditItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-parameters-edit-item", [], null, null, null, View_QueryBuilderParametersEditItemComponent_0, RenderType_QueryBuilderParametersEditItemComponent)), i0.ɵdid(1, 770048, null, 0, i20.QueryBuilderParametersEditItemComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderParametersEditItemComponentNgFactory = i0.ɵccf("app-query-builder-parameters-edit-item", i20.QueryBuilderParametersEditItemComponent, View_QueryBuilderParametersEditItemComponent_Host_0, { control: "control", value: "value", autofocus: "autofocus" }, { deleteRequested: "deleteRequested", valueChange: "valueChange" }, []);
export { QueryBuilderParametersEditItemComponentNgFactory as QueryBuilderParametersEditItemComponentNgFactory };

import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

import { PopupDynamicComponentArguments, PopupService } from '@common/popups';

import { UserActivitiesPopupComponent } from '../../components/user-activities-popup/user-activities-popup.component';

@Injectable()
export class UserActivitiesController {
  closeObs: Subject<void>;

  constructor(private popupService: PopupService, private injector: Injector) {}

  open(baseParams: Object): PopupDynamicComponentArguments {
    if (this.closeObs) {
      this.closeObs.next();
    }

    const closeObs = new Subject<void>();

    this.closeObs = closeObs;

    return this.popupService.push({
      component: UserActivitiesPopupComponent,
      disablePointerEvents: true,
      enableWindowScroll: true,
      inputs: {
        baseParams: baseParams,
        closeObs: closeObs.asObservable()
      },
      outputs: {
        closeObsEvent: [
          () => {
            if (this.closeObs === closeObs) {
              this.closeObs = undefined;
            }
          }
        ]
      },
      popupClosed: () => {
        if (this.closeObs === closeObs) {
          this.closeObs = undefined;
        }
      },
      injector: this.injector
    });
  }
}

import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

export class SignUpField {
  public property: string;
  public required = false;
  public placeholder: string;

  deserialize(data: Object): SignUpField {
    this.property = data['property'];

    if (isSet(data['required'])) {
      this.required = data['required'];
    }

    if (isSet(data['placeholder'])) {
      this.placeholder = data['placeholder'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      property: this.property,
      required: this.required,
      placeholder: this.placeholder
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

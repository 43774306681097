import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelQueriesModule } from '@modules/model-queries';
import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, SharedModule, ModelQueriesModule]
})
export class ModelsListModule {}

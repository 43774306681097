<div class="calendar-month">
  <table class="calendar-month-table">
    <tr class="calendar-month-table__tr">
      <th *ngFor="let day of weekDays" class="calendar-month-table__th">{{ day.date.format('dddd') }}</th>
    </tr>
    <tr *ngFor="let week of weeks" class="calendar-month-table__tr">
      <td
        *ngFor="let day of week; let d = index"
        class="calendar-month-table__td"
        [class.calendar-month-table__td--weekend]="day.weekend"
        [class.calendar-month-table__td--active]="day.today"
      >
        <div
          class="calendar-month-table__date"
          [class.calendar-month-table__date--month]="day.date.date() == 1"
          [appTintColor]="'accentColor' | appThemeColorHexOption | async"
          [appTintColorStyle]="tintStyles.Primary"
        >
          <span *ngIf="day.date.date() === 1" class="calendar-month-table__date-month"
            >{{ day.date.format('MMMM') }}
          </span>
          <span class="calendar-month-table__date-week">{{ day.date.format('dd') }}</span>
          <a
            href="javascript:void(0)"
            (click)="setParams(dayParams(day.date))"
            class="calendar-month-table__date-number"
          >
            {{ day.date.format('D') }}
          </a>
        </div>
        <ng-container *ngIf="items[day.key] as items">
          <app-calendar-item
            *ngFor="let item of items.slice(0, 2); let i = index"
            [item]="item"
            [listState]="listState"
            [modelDescription]="modelDescription"
            [settings]="settings"
            [visibleColumns]="visibleColumns"
            [context]="context"
            [contextElement]="contextElement"
            [selected]="isItemSelected(item, i)"
            (select)="toggleSelectedItem(item, i, $event.element, true)"
            (modelUpdated)="onModelUpdated($event)"
          ></app-calendar-item>

          <a
            *ngIf="items.length > 2"
            href="javascript:void(0)"
            (click)="setParams(dayParams(day.date))"
            type="button"
            class="calendar-card__more-items"
          >
            + {{ items.length - 2 }} More Items
          </a>
        </ng-container>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="loading" class="calendar-loader">
  <div class="calendar-loader__content">
    <app-loader [fill]="true"></app-loader>
  </div>
</div>

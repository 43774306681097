<div class="header">
  <a *ngIf="!isWhiteLabel" [routerLink]="['/']" class="header__left">
    <div class="header__logo"></div>
  </a>
  <div *ngIf="isWhiteLabel" class="header__left"></div>

  <div class="header__main">
    <ng-content></ng-content>
  </div>

  <div class="header__right">
    <div *ngIf="!isWhiteLabel" class="dropdown-anchor header__dropdown-info">
      <span class="button-info button-info_light icon-help"></span>
      <div class="dropdown dropdown_position_bottom-left dropdown_menu">
        <div class="dropdown__inner">
          <div class="dropdown__section">
            <a href="javascript:void(0)" class="dropdown__item" (click)="openChat()">
              <span class="icon-chat dropdown__item-icon"></span>
              <span class="dropdown__item-label">{{ 'Contact Support' | localize }}</span>
            </a>
            <a href="https://docs.jetadmin.io/" class="dropdown__item" target="_blank">
              <span class="icon-documents dropdown__item-icon"></span>
              <span class="dropdown__item-label">{{ 'Documentation' | localize }}</span>
            </a>
            <a href="https://feedback.jetadmin.io/" class="dropdown__item" target="_blank">
              <span class="icon-idea dropdown__item-icon"></span>
              <span class="dropdown__item-label">{{ 'Feature Requests' | localize }}</span>
            </a>
            <a href="https://feedback.jetadmin.io/changelog/" class="dropdown__item" target="_blank">
              <span class="icon-news dropdown__item-icon"></span>
              <span class="dropdown__item-label">{{ 'See What’s New' | localize }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="dropdown-anchor header__dropdown-profile">
      <span
        class="button-profile"
        [appTutorialTip]="{
          tip: 'Open User menu',
          task: 'switch_to_dark_theme',
          page: 0,
          pageItem: 'hover_user_menu',
          action: 'hover'
        }"
        [style.background-image]="
          (currentUserStore.instance$ | async)?.photo ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')' : ''
        "
      ></span>
      <div class="dropdown dropdown_position_bottom-left dropdown_menu">
        <app-menu-profile
          [theme]="'dark'"
          [position]="'bottom-left'"
          [themeChange]="false"
          [analyticsSource]="analyticsSource"
        ></app-menu-profile>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ContainerLayer } from '@modules/views';

@Component({
  selector: 'app-container-layer-control',
  templateUrl: './container-layer-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerLayerControlComponent implements OnInit {
  @Input() containerLayer: ContainerLayer;
  @Input() absoluteControl: FormControl;

  constructor() {}

  ngOnInit() {}
}

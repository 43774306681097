<div class="dot-pagination" [appTintColor]="accentColor" [appTintColorStyle]="tintStyles.Primary">
  <div class="dot-pagination__viewport" [style.max-width.px]="pageSize * maxSize">
    <div class="dot-pagination__dots" [style.transform]="'translate(' + translate + 'px)'">
      <div *ngFor="let item of pagesRange" class="dot-pagination__dot-bounds" (click)="selectPage.emit(item)">
        <div
          class="dot-pagination__dot"
          [class.dot-pagination__dot_active]="item === page"
          [class.dot-pagination__dot_small]="(item - page | appAbs) == (size / 2 | appFloor) + 1"
          [class.dot-pagination__dot_smaller]="(item - page | appAbs) >= (size / 2 | appFloor) + 2"
        ></div>
      </div>
    </div>
  </div>

  <div
    class="dot-pagination__overflow dot-pagination__overflow_position_left"
    [class.dot-pagination__overflow_visible]="pages > maxSize && page > (size / 2 | appFloor)"
  ></div>

  <div
    class="dot-pagination__overflow dot-pagination__overflow_position_right"
    [class.dot-pagination__overflow_visible]="pages > maxSize && page < pages - (size / 2 | appFloor)"
  ></div>
</div>

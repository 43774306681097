<div class="feature-overview" [class.feature-overview_fill]="fill">
  <div *ngIf="background" class="feature-overview__background"></div>
  <div class="feature-overview__content">
    <div class="feature-overview__info">
      <div class="feature-overview__info-inner">
        <div class="feature-overview__subtitle">
          <!--          <ng-container *ngIf="pro">Paid Feature</ng-container>-->
          <!--          <ng-container *ngIf="!pro">Free Feature</ng-container>-->
          <ng-content select="[data-subtitle]"></ng-content>
        </div>
        <div class="feature-overview__title">
          <ng-content select="[data-title]"></ng-content>
        </div>
        <div class="feature-overview__description">
          <ng-content select="[data-description]"></ng-content>
        </div>
        <div *ngIf="button && currentProjectStore" class="feature-overview__buttons">
          <a
            [appLink]="(currentProjectStore.instance$ | async).settingsBillingLink"
            [appLinkMode]="adminModes.Builder"
            class="button button_primary"
            (click)="buttonClicked.emit()"
          >
            Upgrade Plan
          </a>
          <!--          <ng-container *ngIf="available">-->
          <!--            <a *ngIf="action" href="javascript:void(0)" class="button button_primary" (click)="triggerAction()">-->
          <!--              <span class="button__label">{{ action }}</span>-->
          <!--            </a>-->
          <!--            <a *ngIf="pro" href="javascript:void(0)" class="button button_primary" (click)="activate()">-->
          <!--              <span class="button__label">Try for Free</span>-->
          <!--            </a>-->
          <!--            <a *ngIf="!pro && !action" href="javascript:void(0)" class="button button_primary" (click)="activate()">-->
          <!--              <span class="button__label">Start Using</span>-->
          <!--            </a>-->
          <!--          </ng-container>-->
          <!--          <ng-container *ngIf="!available">-->
          <!--            <a-->
          <!--              href="https://www.jetadmin.io/contact"-->
          <!--              class="button button_primary"-->
          <!--              (click)="onActivated()"-->
          <!--              target="_blank"-->
          <!--            >-->
          <!--              <span class="button__label">Contact Us</span>-->
          <!--            </a>-->
          <!--          </ng-container>-->
        </div>
      </div>
      <div *ngIf="feature" class="feature-overview__image">
        <div class="feature-overview__image-width">
          <div class="feature-overview__image-height">
            <div
              class="feature-overview__image-inner"
              [ngClass]="['feature-overview__image-inner_type_' + feature]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--<div *ngIf="pro" class="feature-overview__footer">-->
    <!--* This feature is available for free on non-Production environments. Upgrading to the Pro plan will be required to use it on Production.-->
    <!--</div>-->
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppDragDrop } from '../../data/drag-drop';
import { DragData } from '../playground/playground.component';

@Component({
  selector: 'app-playground-item',
  templateUrl: './playground-item.component.html',
  styleUrls: ['./playground-item.component.scss']
})
export class PlaygroundItemComponent implements OnInit {
  @Input() item: DragData;
  @Input() handle = false;
  @Input() children = false;

  @Output() dropped = new EventEmitter<AppDragDrop<DragData[]>>();

  constructor() {}

  ngOnInit() {}
}

<div *ngIf="!loading" class="sidebar-list-item-wrapper">
  <div
    class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_purple"
    [class.sidebar-list-item_shadow]="true"
    [class.sidebar-list-item_disabled]="dropdown_trigger.menuOpen"
    [matMenuTriggerFor]="dropdown"
    #dropdown_trigger="matMenuTrigger"
    (mousedown)="$event.stopPropagation()"
  >
    <div class="sidebar-list-item__left">
      <span
        class="button button_icon button_purple-gradient button_small"
        [class.button_disabled]="!control.value"
        style="margin: 0;"
      >
        <span class="button__icon icon-canvas" [style.font-size.px]="18"></span>
      </span>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <ng-container *ngIf="control.value">
          {{ (control.value.name | appIsSet) ? control.value.name : 'No name' }}
        </ng-container>

        <ng-container *ngIf="!control.value">
          Not specified
        </ng-container>
      </div>
      <div class="sidebar-list-item__description">
        {{ subtitle }}
      </div>
    </div>

    <div class="sidebar-list-item__right">
      <a
        *ngIf="!control.value"
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-plus"
        style="margin-left: 8px;"
      >
      </a>

      <a
        *ngIf="control.value"
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-arrow_down_2"
        style="margin-left: 8px;"
      >
      </a>
    </div>
  </div>

  <div class="sidebar-list-item-wrapper__footer">
    <div class="sidebar-list-item-wrapper__footer-row">
      <div
        class="sidebar-list-item-subbutton sidebar-list-item-subbutton_interactive"
        [class.sidebar-list-item-subbutton_disabled]="dropdown_trigger.menuOpen"
        (click)="openCustomViewTemplates()"
      >
        <div class="sidebar-list-item-subbutton__icon icon-blocks"></div>
        <div class="sidebar-list-item-subbutton__label">
          <ng-container *ngIf="templatesApprox | appIsSet">Explore {{ templatesApprox }}+ components</ng-container>
          <ng-container *ngIf="!(templatesApprox | appIsSet)">Explore components library</ng-container>
        </div>
      </div>
    </div>
  </div>

  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openViewEditor()">
        <span class="choose-items-item__dropdown-icon icon-canvas"></span>
        <ng-container *ngIf="control.value">Customize with Designer</ng-container>
        <ng-container *ngIf="!control.value">Create with Designer</ng-container>
      </button>

      <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openCustomViewTemplates()">
        <span class="choose-items-item__dropdown-icon icon-blocks"></span>
        Use Marketplace component
      </button>

      <button
        *ngIf="mappingsControl && control.value"
        mat-menu-item
        class="mat-menu-item-odd"
        [disableRipple]="true"
        (click)="changeMapping()"
      >
        <span class="choose-items-item__dropdown-icon icon-plug"></span>
        Change data binding
      </button>

      <button
        *ngIf="(currentUserStore.instance$ | async)?.isStaff"
        mat-menu-item
        class="mat-menu-item-odd staff-block staff-block_background"
        [disabled]="!control.value"
        [disableRipple]="true"
        (click)="updateCustomViewTemplate()"
      >
        <span class="choose-items-item__dropdown-icon icon-redo"></span>
        Save to marketplace
      </button>

      <button
        *ngIf="deleteEnabled && control.value"
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-red"
        [disableRipple]="true"
        (click)="resetCardView()"
      >
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
        Remove custom component
      </button>
    </ng-template>
  </mat-menu>
</div>

<div *ngIf="loading" class="sidebar-list-item-wrapper">
  <div class="sidebar-list-item sidebar-list-item_purple" [class.sidebar-list-item_shadow]="true">
    <div class="sidebar-list-item__left">
      <span
        class="button button_icon button_purple-gradient button_small"
        [class.button_disabled]="true"
        style="margin: 0;"
      >
        <span class="button__icon icon-canvas" [style.font-size.px]="18"></span>
      </span>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <span [class.loading-animation]="loading"><span class="stub-text">name</span></span>
      </div>
      <div class="sidebar-list-item__description">
        <span [class.loading-animation]="loading"><span class="stub-text">custom</span></span>
      </div>
    </div>
  </div>

  <div class="sidebar-list-item-wrapper__footer">
    <div class="sidebar-list-item-wrapper__footer-row">
      <div class="sidebar-list-item-subbutton">
        <div class="sidebar-list-item-subbutton__label">
          <span [class.loading-animation]="loading"><span class="stub-text">action</span></span>
        </div>
      </div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-title.component";
var styles_PageTitleComponent = [];
var RenderType_PageTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTitleComponent, data: {} });
export { RenderType_PageTitleComponent as RenderType_PageTitleComponent };
export function View_PageTitleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [["class", "page-title"]], [[2, "page-title_align_center", null], [2, "page-title_align_right", null], [2, "page-title_strong", null]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.align == "center"); var currVal_1 = (_co.align == "right"); var currVal_2 = _co.strong; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PageTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-title", [], null, null, null, View_PageTitleComponent_0, RenderType_PageTitleComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTitleComponentNgFactory = i0.ɵccf("app-page-title", i1.PageTitleComponent, View_PageTitleComponent_Host_0, { align: "align", strong: "strong" }, {}, ["*"]);
export { PageTitleComponentNgFactory as PageTitleComponentNgFactory };

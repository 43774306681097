<ng-template #content>
  <app-customize-bar-action-edit-type
    [options]="options"
    [form]="form"
    [label]="label"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [firstInit]="firstInit"
    [setupOnCreate]="setupOnCreate"
    [analyticsSource]="object"
    (submit)="submitImmediate.emit()"
  ></app-customize-bar-action-edit-type>

  <app-customize-bar-action-edit-parameters
    *ngIf="options.parametersEditable && operationTypeValid$ | async"
    [form]="form"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [userInput]="true"
    [analyticsSource]="object"
    (addQueryInput)="addQueryInput()"
  ></app-customize-bar-action-edit-parameters>
</ng-template>

<app-customize-bar-action-edit-wrapper
  *ngIf="wrapper"
  [options]="options"
  [form]="form"
  (submit)="submit()"
  (cancel)="cancel()"
>
  <ng-content *ngTemplateOutlet="content"></ng-content>
</app-customize-bar-action-edit-wrapper>

<ng-container *ngIf="!wrapper">
  <ng-content *ngTemplateOutlet="content"></ng-content>
</ng-container>

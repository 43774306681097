import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApiModule } from '@modules/api';
import { SharedModule } from '@shared';

@NgModule({
  imports: [CommonModule, ApiModule, SharedModule]
})
export class ProjectsModule {}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "../../../../icons/directives/icon/icon.directive";
import * as i4 from "../../../../../core/services/app-config/app-config.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i7 from "../../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i8 from "@angular/material/menu";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i11 from "./action-menu-item.component";
import * as i12 from "../../../../action-queries/services/action/action.service";
import * as i13 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i14 from "../../../../customize/services/customize/customize.service";
import * as i15 from "../../../../../common/notifications/services/notification/notification.service";
var styles_ActionMenuItemComponent = [];
var RenderType_ActionMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionMenuItemComponent, data: {} });
export { RenderType_ActionMenuItemComponent as RenderType_ActionMenuItemComponent };
function View_ActionMenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "mat-menu-item-icon"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ActionMenuItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "mat-menu-item-icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i3.IconDirective, [i4.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.icon; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0); }); }
function View_ActionMenuItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "mat-menu-item-icon icon-power"]], null, null, null, null, null))], null, null); }
function View_ActionMenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionMenuItemComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionMenuItemComponent_5)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.icon; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.item.icon; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ActionMenuItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Component is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActionMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""], ["style", "position: relative;"]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.executeAction(_co.item) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatMenuItem_0, i7.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, null, 0, i8.MatMenuItem, [i0.ElementRef, i5.DOCUMENT, i9.FocusMonitor, [2, i8.ɵf24]], { disabled: [0, "disabled"], disableRipple: [1, "disableRipple"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ActionMenuItemComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ActionMenuItemComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(6, 0, [" ", " "])), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_ActionMenuItemComponent_6)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.disabled || _co.submitLoading); var currVal_7 = true; _ck(_v, 1, 0, currVal_6, currVal_7); var currVal_8 = _co.submitLoading; _ck(_v, 3, 0, currVal_8); var currVal_9 = !_co.submitLoading; _ck(_v, 5, 0, currVal_9); var currVal_11 = (!_co.visible && i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.customizeEnabled$))); _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).role; var currVal_1 = i0.ɵnov(_v, 1)._highlighted; var currVal_2 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_10 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), _co.verboseName)); _ck(_v, 6, 0, currVal_10); }); }
export function View_ActionMenuItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i10.CapitalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActionMenuItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeEnabled$))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActionMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-menu-item", [], null, null, null, View_ActionMenuItemComponent_0, RenderType_ActionMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i11.ActionMenuItemComponent, [i12.ActionService, i13.ActionControllerService, i14.CustomizeService, i15.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionMenuItemComponentNgFactory = i0.ɵccf("app-action-menu-item", i11.ActionMenuItemComponent, View_ActionMenuItemComponent_Host_0, { item: "item", context: "context", contextElement: "contextElement", localContext: "localContext", analyticsSource: "analyticsSource" }, { selected: "selected" }, []);
export { ActionMenuItemComponentNgFactory as ActionMenuItemComponentNgFactory };

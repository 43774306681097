import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';

import { CustomizeActionOptions, CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-display',
  templateUrl: './customize-bar-action-edit-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditDisplayComponent implements OnInit {
  @Input() options: CustomizeActionOptions = {};
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() object: string;

  createField = createFormFieldFactory();

  constructor() {}

  ngOnInit() {}
}

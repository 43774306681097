import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DoCheck,
  Injector,
  Input,
  OnDestroy,
  Type,
  ViewContainerRef
} from '@angular/core';

import { DynamicComponentService } from '../../services/dynamic-component/dynamic-component.service';
import { DynamicComponentArguments } from '../../services/dynamic-component/dynamic-component.service';

@Component({
  selector: 'app-dynamic-component',
  template: ''
})
export class DynamicComponent<T = any> implements DoCheck, OnDestroy {
  currentComponent: ComponentRef<T>;

  constructor(
    private injector: Injector,
    private dynamicComponentService: DynamicComponentService,
    private vc: ViewContainerRef
  ) {}

  @Input() set componentData(data: DynamicComponentArguments) {
    const componentRef = this.dynamicComponentService.createComponent<T>(data.injector || this.injector, data);

    this.vc.insert(componentRef.hostView);

    if (this.currentComponent) {
      this.currentComponent.destroy();
    }

    this.currentComponent = componentRef;
    // this.currentComponent.changeDetectorRef.detectChanges();

    if (this.currentComponent.instance['OnInit']) {
      this.currentComponent.instance['OnInit']();
    }
  }

  ngDoCheck(): void {
    if (this.currentComponent) {
      try {
        const cd = this.currentComponent.injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
        cd.markForCheck();
      } catch (e) {}
    }
  }

  ngOnDestroy(): void {
    if (this.currentComponent) {
      this.currentComponent.destroy();
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, first, map } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { QrCodeElementItem, ViewContext } from '@modules/customize';
import { CustomizeBarEditComponent } from '@modules/customize-bar/data/customize-bar-edit-component';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarQrCodeEditForm } from './customize-bar-qr-code-edit.form';

@Component({
  selector: 'app-customize-bar-qr-code-edit',
  templateUrl: './customize-bar-qr-code-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomizeBarQrCodeEditForm]
})
export class CustomizeBarQrCodeEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() titleEnabled = true;
  @Input() element: QrCodeElementItem;
  @Input() context: ViewContext;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  result: QrCodeElementItem;
  configurationStarted = false;
  booleanFieldStyle = BooleanFieldStyle;

  constructor(
    private cd: ChangeDetectorRef,
    private customizeBarContext: CustomizeBarContext,
    public form: CustomizeBarQrCodeEditForm,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.element, this.firstInit);

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(value => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        map(result => this.form.isConfigured(result)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.element.analyticsName
        });
      });
  }

  ngOnDestroy(): void {}

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  close() {
    if (this.configurationStarted && this.form.isConfigured(this.result)) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
        ComponentTypeID: this.element.analyticsName
      });
    }

    this.customizeBarContext.popSettingsComponent();
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  emitUpdate(submit = false) {
    const args = { result: this.result, submit: submit };

    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
    }
  }
}

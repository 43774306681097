import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AppDragDrop } from '@common/drag-drop2';
import { ActionType } from '@modules/actions';
import { ViewContext } from '@modules/customize';
import { ThemeService } from '@modules/theme';
import { cleanWorkflowStepName, WorkflowStep, WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';

import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';

@Component({
  selector: 'app-fork-workflow-step-item',
  templateUrl: './fork-workflow-step-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForkWorkflowStepItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() steps: WorkflowStep[] = [];
  @Input() workflowEditable = false;
  @Input() index: number;
  @Input() active = false;
  @Input() containerElement: HTMLElement;
  @Input() context: ViewContext;
  @Input() actionTypesEnabled: ActionType[];
  @Input() analyticsSource: string;
  @Output() stepClick = new EventEmitter<void>();

  @HostBinding('class.workflow-fork__child') cls = true;

  zigZagLeft = false;
  zigZagX = 0;
  zigZagWidth = 0;

  trackStep = (() => {
    return (i, item: WorkflowStep) => {
      return item ? item.uid : i;
    };
  })();

  constructor(
    private el: ElementRef,
    private workflowEditContext: WorkflowEditContext,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => this.updateConnections(), 0);

    this.workflowEditContext
      .state$()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateConnections());
  }

  get connectionColor(): string {
    return this.themeService.theme == 'dark' ? '#353e48' : '#ccd3de';
  }

  updateConnections() {
    const containerBounds = this.containerElement.getBoundingClientRect();
    const leftChildBounds = this.el.nativeElement.getBoundingClientRect();

    if (leftChildBounds.left + leftChildBounds.width / 2 >= containerBounds.left + containerBounds.width / 2) {
      this.zigZagLeft = false;
      this.zigZagWidth =
        leftChildBounds.left + leftChildBounds.width / 2 - (containerBounds.left + containerBounds.width / 2) + 2;
      this.zigZagX = containerBounds.width / 2 - 1;
      this.cd.markForCheck();
    } else {
      this.zigZagLeft = true;
      this.zigZagWidth =
        containerBounds.left + containerBounds.width / 2 - (leftChildBounds.left + leftChildBounds.width / 2) + 2;
      this.zigZagX = containerBounds.width / 2 - this.zigZagWidth + 1;
      this.cd.markForCheck();
    }
  }

  dragDrop(event: AppDragDrop<WorkflowStep[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.workflowEditContext.markChanged();
  }

  addStep(step: WorkflowStep, options: { addBeforeIndex?: number } = {}) {
    this.workflowEditContext.registerCreatedElement(step);

    if (isSet(options.addBeforeIndex)) {
      this.steps.splice(options.addBeforeIndex, 0, step);
    } else {
      this.steps.push(step);
    }

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  duplicateStep(index: number) {
    const step = cloneDeep(this.steps[index]);
    const defaultName = `Copy of ${step.name}`;

    step.generateUid();
    step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);

    this.workflowEditContext.registerCreatedElement(step);
    this.steps.splice(index + 1, 0, step);

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  deleteStep(index: number) {
    this.steps.splice(index, 1);
    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  stepsHasNextConnection(): boolean {
    return !this.steps.length || last(this.steps).type != WorkflowStepType.Exit;
  }
}

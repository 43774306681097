import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { copyTextToClipboard } from '@common/code';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { DraftChangesService } from '@modules/customize-utils';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { PER_PAGE_PARAM } from '@modules/models';
import { ProjectGroupEditController, ProjectUserForm } from '@modules/project-settings-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroupStore,
  ProjectInvite,
  ProjectInviteService,
  ProjectPropertyType,
  ProjectPublicInvite,
  ProjectPublicInviteService,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';
import { RoutingService } from '@modules/routing';

export enum ShareTab {
  Share = 'share',
  Public = 'public',
  Members = 'members'
}

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  providers: [ProjectUserForm]
})
export class ShareComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() orange = false;
  @Input() initialTab: ShareTab;
  @Output() userDeleted = new EventEmitter<ProjectUser>();
  @Output() inviteAdded = new EventEmitter<ProjectInvite>();
  @Output() inviteDeleted = new EventEmitter<ProjectInvite>();
  @Output() publicInviteAdded = new EventEmitter<ProjectPublicInvite>();
  @Output() publicInviteDeleted = new EventEmitter<ProjectPublicInvite>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  groupsLoading = true;
  membersLoading = true;
  tabs = ShareTab;
  currentTab: ShareTab;
  users: ProjectUser[];
  usersCount: number;
  invites: ProjectInvite[];
  invitesCount: number;
  createInviteLoading = false;
  createInviteResult: ProjectInvite;
  publicInvites: ProjectPublicInvite[];
  publicInvitesLoading = false;
  createPublicInviteLoading = false;
  createPublicInviteGroupControl = new FormControl();
  webBaseUrl: string;
  inviteSettingsOpened = false;
  booleanFieldStyle = BooleanFieldStyle;
  projectPropertyTypes = ProjectPropertyType;
  hasDraftChanges = false;

  createField = createFormFieldFactory();

  itemOwner = { value: 'Owner', name: 'Owner' };

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public form: ProjectUserForm,
    private projectGroupStore: ProjectGroupStore,
    private cd: ChangeDetectorRef,
    private projectUserService: ProjectUserService,
    private projectInviteService: ProjectInviteService,
    private projectPublicInviteService: ProjectPublicInviteService,
    private notificationService: NotificationService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private popupComponent: BasePopupComponent,
    private featureService: FeatureService,
    private appConfigService: AppConfigService,
    private projectGroupEditController: ProjectGroupEditController,
    private draftChangesService: DraftChangesService
  ) {}

  ngOnInit() {
    this.currentTab = this.initialTab || ShareTab.Share;

    this.projectGroupStore
      .getFirst(true)
      .pipe(untilDestroyed(this))
      .subscribe(
        groups => {
          this.groupsLoading = false;
          this.cd.markForCheck();
          this.form.init(undefined, groups);
        },
        () => {
          this.groupsLoading = false;
          this.cd.markForCheck();
        }
      );

    combineLatest(
      this.projectUserService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      ),
      this.projectInviteService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        { [PER_PAGE_PARAM]: 5 }
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([users, invites]) => {
          this.users = users.results;
          this.usersCount = users.count;
          this.invites = invites.results;
          this.invitesCount = invites.count;
          this.membersLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.membersLoading = false;
          this.cd.markForCheck();
        }
      );

    this.loadPublicInvites();

    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(project => {
      this.webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
      this.cd.markForCheck();
    });

    this.draftChangesService
      .getDraftChanges$()
      .pipe(untilDestroyed(this))
      .subscribe(changes => {
        this.hasDraftChanges = changes.filter(item => item.count).length > 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.sendTabOpenAnalytics(true);
    }, 0);
  }

  setCurrentTab(currentTab: ShareTab) {
    if (this.currentTab == currentTab) {
      return;
    }

    this.currentTab = currentTab;
    this.cd.markForCheck();
    this.sendTabOpenAnalytics();
  }

  openSignUp() {
    this.routing.navigateApp(this.currentProjectStore.instance.settingsSignUpLink('sign_up'));
    this.close();
  }

  sendTabOpenAnalytics(initial = false) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.OpenTab, {
      Tab: this.currentTab,
      Initial: initial
    });
  }

  loadPublicInvites() {
    this.publicInvitesLoading = true;
    this.cd.markForCheck();

    this.projectPublicInviteService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.publicInvites = result;
          this.publicInvitesLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.publicInvitesLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getUsers() {
    this.membersLoading = true;
    this.cd.markForCheck();

    combineLatest(
      this.projectUserService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      ),
      this.projectInviteService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        { [PER_PAGE_PARAM]: 5 }
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([users, invites]) => {
          this.users = users.results;
          this.usersCount = users.count;
          this.invites = invites.results;
          this.invitesCount = invites.count;
          this.membersLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.membersLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  setInviteSettingsOpened(value: boolean) {
    this.inviteSettingsOpened = value;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  createGroup(): void {
    this.projectGroupEditController.create();
  }

  onUserDeleted(item: ProjectUser) {
    this.getUsers();
    this.userDeleted.emit(item);
  }

  onInviteDeleted(item: ProjectInvite) {
    this.getUsers();
    this.inviteDeleted.emit(item);
  }

  onPublicInviteDeleted(item: ProjectPublicInvite) {
    this.loadPublicInvites();
    this.publicInviteDeleted.emit(item);
  }

  checkFeatureEnabled(): boolean {
    if (
      !this.currentProjectStore.instance.features.isUsersEnabled() ||
      this.currentProjectStore.instance.isSubscriptionEnded()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Collaborate on a project with <strong>Users & Teams</strong>',
        description: `
          Manage permissions for specific users and teams without any line of code. Add users to individual dashboards and
          manage their access rights to specific contents directly from Jet’s interface.
        `
      });

      return false;
    } else {
      return true;
    }
  }

  submit() {
    if (!this.checkFeatureEnabled()) {
      return;
    }

    if (this.createInviteLoading) {
      return;
    }

    this.createInviteResult = undefined;
    this.createInviteLoading = true;
    this.cd.markForCheck();

    const group = this.form.form.value['group'];

    this.form
      .create()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.createInviteResult = result;
          this.createInviteLoading = false;
          this.form.clean();
          this.cd.markForCheck();
          this.getUsers();
          this.inviteAdded.emit(result);
          // this.notificationService.success('Created', 'User was successfully invited to current App');

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberInvited, {
            Email: result.getEmail(),
            Mode: this.mode,
            CustomizationPermission: this.currentProjectStore.instance.hasGroupCustomizationPermission(group),
            Source: 'share_popup'
          });
        },
        error => {
          this.createInviteLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('User Add Failed', error.errors[0]);
          } else {
            this.notificationService.error('User Add Failed', error);
          }
        }
      );
  }

  createPublicInvite() {
    if (!this.checkFeatureEnabled()) {
      return;
    }

    this.createPublicInviteLoading = true;
    this.cd.markForCheck();

    const instance = new ProjectPublicInvite();

    instance.group = this.createPublicInviteGroupControl.value;

    this.projectPublicInviteService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loadPublicInvites();
          this.createPublicInviteGroupControl.setValue(undefined);
          this.createPublicInviteLoading = false;
          this.cd.markForCheck();
          this.publicInviteAdded.emit(result);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicLinkCreated, {
            Code: result.code,
            Source: 'share_popup'
          });
        },
        () => {
          this.createPublicInviteLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }

  getInviteLink() {
    return this.createInviteResult.link(this.webBaseUrl);
  }

  copyInviteCode() {
    const text = this.getInviteLink();

    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
      });
  }

  getSignUpLink() {
    return `${this.webBaseUrl}/register/${this.currentProjectStore.instance.uniqueName}`;
  }

  copySignUpCode() {
    const text = this.getSignUpLink();

    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
      });
  }
}

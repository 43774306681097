<div class="sidebar__element">
  <div class="sidebar__element-segment">
    <app-sidebar-field [label]="'choose operation'" [required]="true" [classes]="'component-action-resource'">
      <app-resource-field2
        [form]="form"
        [label]="false"
        [errors]="false"
        [field]="
          createField({
            name: 'resource',
            field: 'ResourceField',
            params: { action_resources: true, storage_resources: true, classes: ['select_fill'] }
          })
        "
      ></app-resource-field2>
    </app-sidebar-field>
  </div>
  <div class="sidebar__element-segment">
    <app-sidebar-field [classes]="'component-action-operation'">
      <app-custom-select
        [control]="form.controls.action"
        [items]="resourceQueryActionItems"
        [compareWith]="form.actionValueEquals"
        [emptyLabel]="'No operations'"
        [classes]="['select_fill']"
        [panelClasses]="['mat-menu-panel_sidebar']"
      >
      </app-custom-select>
      <app-field-errors [form]="form" [fieldName]="'query'"></app-field-errors>

      <ng-container right>
        <a
          *ngIf="resource && editableQueryTypes.includes(form.controls.action.value?.queryType)"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
          [appTip]="'Edit request'"
          (click)="editCustomQuery()"
        >
          <span class="icon-edit button__icon"></span>
        </a>

        <a
          *ngIf="resource && form.controls.action.value?.queryType == queryTypes.Simple && actionDescriptionQuery"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
          [appTip]="'Edit request'"
          (click)="editSavedQuery()"
        >
          <span class="icon-edit button__icon"></span>
        </a>
      </ng-container>

      <ng-container description>
        <a
          *ngIf="
            form.controls.query.value?.isConfigured &&
            form.controls.query.value?.isConfigured() &&
            editableQueryTypes.includes(form.controls.action.value?.queryType)
          "
          href="javascript:void(0)"
          (click)="createAction()"
        >
          <span class="icon-save" style="margin-right: 4px;"></span> Save to reuse
        </a>
      </ng-container>
    </app-sidebar-field>
  </div>
</div>

<div *ngIf="showDraftChanges && hasDraftChanges" class="sidebar__element">
  <app-alert [purple]="true" [title]="'Unpublished operation'" [icon]="'warning_filled'" [compact]="true">
    Automations work with published App
  </app-alert>
</div>

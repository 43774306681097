var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var SupabaseStorageGeneratorService = /** @class */ (function (_super) {
    __extends(SupabaseStorageGeneratorService, _super);
    function SupabaseStorageGeneratorService(mode, secretTokenService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        return _this;
    }
    SupabaseStorageGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(catchError(function (error) {
            if (error instanceof ServerRequestError && error.status == 404) {
                return of(undefined);
            }
            else {
                return throwError(error);
            }
        }), map(function (result) {
            return {
                buckets: resource.params['buckets'] || [],
                url: resource.params['url'],
                key: result.value
            };
        }));
    };
    SupabaseStorageGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var storages = options.buckets.map(function (bucket) {
            var storage = new Storage();
            storage.uniqueName = bucket;
            storage.name = bucket;
            storage.params = { bucket: bucket };
            storage.uploadQuery = new StorageQuery();
            storage.uploadQuery.queryType = QueryType.Simple;
            storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();
            storage.getQuery = new StorageQuery();
            storage.getQuery.queryType = QueryType.Simple;
            storage.getQuery.simpleQuery = new storage.getQuery.simpleQueryClass();
            storage.removeQuery = new StorageQuery();
            storage.removeQuery.queryType = QueryType.Simple;
            storage.removeQuery.simpleQuery = new storage.removeQuery.simpleQueryClass();
            storage.createDirectoryQuery = new StorageQuery();
            storage.createDirectoryQuery.queryType = QueryType.Simple;
            storage.createDirectoryQuery.simpleQuery = new storage.createDirectoryQuery.simpleQueryClass();
            storage.getObjectUrlQuery = new StorageQuery();
            storage.getObjectUrlQuery.queryType = QueryType.Simple;
            storage.getObjectUrlQuery.simpleQuery = new storage.getObjectUrlQuery.simpleQueryClass();
            return storage;
        });
        var token = new SecretToken();
        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.key;
        return of({
            resourceParams: {
                buckets: options.buckets,
                url: options.url
            },
            storages: storages.map(function (item) { return item.serialize(); }),
            secretTokens: [token.serialize()]
        });
    };
    return SupabaseStorageGeneratorService;
}(ResourceGeneratorService));
export { SupabaseStorageGeneratorService };

import { EventEmitter, OnInit } from '@angular/core';
var WorkflowStepCardComponent = /** @class */ (function () {
    function WorkflowStepCardComponent() {
        this.indicatorLoader = false;
        this.active = false;
        this.contrast = false;
        this.contrast2 = false;
        this.disabled = false;
        this.interactive = false;
        this.loading = false;
        this.connectorTop = true;
        this.connectorBottom = true;
        this.duplicateEnabled = false;
        this.deleteEnabled = false;
        this.executeEnabled = false;
        this.stepDuplicate = new EventEmitter();
        this.stepDelete = new EventEmitter();
        this.stepExecute = new EventEmitter();
        this.cls = true;
    }
    Object.defineProperty(WorkflowStepCardComponent.prototype, "activeClass", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkflowStepCardComponent.prototype, "contrastClass", {
        get: function () {
            return this.contrast;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkflowStepCardComponent.prototype, "contrast2Class", {
        get: function () {
            return this.contrast2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkflowStepCardComponent.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkflowStepCardComponent.prototype, "interactiveClass", {
        get: function () {
            return this.interactive;
        },
        enumerable: true,
        configurable: true
    });
    WorkflowStepCardComponent.prototype.ngOnInit = function () { };
    return WorkflowStepCardComponent;
}());
export { WorkflowStepCardComponent };

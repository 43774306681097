/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-separator.component";
var styles_PageSeparatorComponent = [];
var RenderType_PageSeparatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageSeparatorComponent, data: {} });
export { RenderType_PageSeparatorComponent as RenderType_PageSeparatorComponent };
export function View_PageSeparatorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "page-separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "page-separator__dash"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "page-separator__label"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "page-separator__dash"]], null, null, null, null, null))], null, null); }
export function View_PageSeparatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-separator", [], null, null, null, View_PageSeparatorComponent_0, RenderType_PageSeparatorComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageSeparatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageSeparatorComponentNgFactory = i0.ɵccf("app-page-separator", i1.PageSeparatorComponent, View_PageSeparatorComponent_Host_0, {}, {}, ["*"]);
export { PageSeparatorComponentNgFactory as PageSeparatorComponentNgFactory };

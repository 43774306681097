import { DatasetGroupLookup } from '@modules/charts';

export const bubbleSampleData = [
  {
    name: undefined,
    color: 'gray',
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: 10, group2: 10, value: 10 },
      { group: 15, group2: 30, value: 30 },
      { group: 15, group2: 40, value: 40 },
      { group: 18, group2: 20, value: 10 },
      { group: 18, group2: 30, value: 10 },
      { group: 20, group2: 40, value: 20 },
      { group: 20, group2: 35, value: 30 },
      { group: 20, group2: 30, value: 10 },
      { group: 45, group2: 40, value: 10 },
      { group: 50, group2: 40, value: 40 },
      { group: 50, group2: 30, value: 10 },
      { group: 60, group2: 60, value: 20 },
      { group: 60, group2: 50, value: 10 },
      { group: 80, group2: 60, value: 40 },
      { group: 80, group2: 80, value: 50 },
      { group: 90, group2: 80, value: 20 },
      { group: 90, group2: 60, value: 10 }
    ]
  }
];

import pickBy from 'lodash/pickBy';

import { Input, ParameterField } from '@modules/fields';

export class WorkflowResult {
  public parameters: ParameterField[] = [];
  public inputs: Input[] = [];
  public array = false;
  public arrayInput: Input;

  deserialize(data: Object) {
    this.array = data['array'];

    if (data['parameters']) {
      this.parameters = data['parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['inputs']) {
      this.inputs = data['inputs'].map(item => new Input().deserialize(item));
    }

    if (data['array_input']) {
      this.arrayInput = new Input().deserialize(data['array_input']);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      parameters: this.parameters.map(item => item.serialize()),
      inputs: this.inputs.map(item => item.serialize()),
      array: this.array,
      array_input: this.arrayInput ? this.arrayInput.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HideBannerDirective } from './directives/hide-banner/hide-banner.directive';
import { BannerVisiblePipe } from './pipes/banner-visible/banner-visible.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [BannerVisiblePipe, HideBannerDirective],
  exports: [BannerVisiblePipe, HideBannerDirective]
})
export class BannersModule {}

<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'Text to copy'"
    [required]="true"
    [itemForm]="form.controls.copy_to_clipboard_action_value"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_copy_to_clipboard_value'"
  ></app-customize-bar-input-edit>
</div>

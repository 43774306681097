import { FieldLookup, serializeFieldParamName } from '@modules/field-lookups';

export class FilterItem {
  field: string;
  lookup: FieldLookup;
  value: any;
  exclude: boolean;

  deserialize(data: Object): FilterItem {
    this.field = data['field'];
    this.value = data['value'];
    this.exclude = data['exclude'];

    return this;
  }

  serialize(): Object {
    return {
      field: this.field,
      value: this.value,
      exclude: this.exclude
    };
  }

  getName(): string {
    return serializeFieldParamName(this.field, this.lookup ? this.lookup.lookup : undefined, this.exclude);
  }
}

import isArray from 'lodash/isArray';

import { BaseField, getFieldDescriptionByType, lookupMatchers } from '@modules/fields';
import { FilterItem } from '@modules/filters';

export function matchFilterItem(field: BaseField, value: any, filterItem: FilterItem) {
  const fieldDescription = getFieldDescriptionByType(field.field);
  const lookup = filterItem.lookup
    ? fieldDescription.lookups.find(item => item.type.lookup == filterItem.lookup.lookup)
    : undefined;
  const lookupArray = lookup ? lookup.array : undefined;
  const lookupFieldDescription = getFieldDescriptionByType(lookup ? lookup.field : undefined);
  let filterValue = filterItem.value;

  const matcher = lookupMatchers.find(i => i.field == field.field && i.lookup === filterItem.lookup);

  if (!matcher) {
    return true;
  }

  if (fieldDescription.deserializeValue) {
    value = fieldDescription.deserializeValue(value, field);
  }

  if (lookupFieldDescription.deserializeValue) {
    if (lookupArray && isArray(filterValue)) {
      filterValue = filterValue.map(item => lookupFieldDescription.deserializeValue(item));
    } else {
      filterValue = lookupFieldDescription.deserializeValue(filterValue);
    }
  }

  if (filterValue === undefined) {
    return true;
  }

  const matched = matcher.match(value, filterValue, field);
  return filterItem.exclude ? !matched : matched;
}

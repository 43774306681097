import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-carousel-pagination',
  templateUrl: './carousel-pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselPaginationComponent implements OnInit, OnChanges {
  @Input() currentIndex = -1;
  @Input() items: number;
  @Input() stepSize: number;
  @Input() displaySize: number;
  @Input() accentColor: string;
  @Output() selectIndex = new EventEmitter<number>();

  page: number;
  pages: number;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<CarouselPaginationComponent>): void {
    if (changes.currentIndex || changes.items || changes.stepSize || changes.displaySize) {
      this.pages = Math.ceil(this.items / this.stepSize);

      if (this.currentIndex >= this.items - this.displaySize) {
        this.page = this.pages;
      } else {
        this.page = Math.floor(this.currentIndex / this.stepSize) + 1;
      }
    }
  }

  onPageSelected(page: number) {
    const index = (page - 1) * this.stepSize;
    this.selectIndex.emit(index);
  }
}

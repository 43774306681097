import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Draggabilly from 'draggabilly';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as Packery from 'packery';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, pairwise, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { DynamicComponentArguments } from '@common/dynamic-component';
import { DocumentService } from '@core';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import {
  CustomizeHandler,
  CustomizeService,
  CustomizeType,
  ViewContext,
  ViewContextElementType
} from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Dashboard, DashboardService, Widget, WidgetService } from '@modules/dashboard';
import { MetaService } from '@modules/meta';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { generateUUID, scrollWindowTo } from '@shared';

import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';

export interface DashboardPageState {
  createWidgets: Widget[];
  updateWidgets: Widget[];
  deleteWidgets: Widget[];
  widgetPositions: Widget[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  providers: [CustomizeBarContext, ViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit, CustomizeHandler<DashboardPageState> {
  @Input() dashboard: Dashboard;
  @ViewChild('dashboard_element_wrapper') dashboardElementWrapper: ElementRef;
  @ViewChild('dashboard_element') dashboardElement: ElementRef;
  @ViewChildren('dashboard_item_element') dashboardItemElements = new QueryList<ElementRef>();
  @ViewChildren(DashboardItemComponent) dashboardItems = new QueryList<DashboardItemComponent>();

  loading = false;
  widgets: Widget[];
  widgetsDashboard: Dashboard;
  initialWidgets: Widget[];
  grid: Packery;
  columns = 10;
  rowHeight = 100;
  resizingWidget: Widget;
  columnWidth: number;
  customizeComponentData: DynamicComponentArguments;

  constructor(
    private activatedRoute: ActivatedRoute,
    private widgetService: WidgetService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public context: ViewContext,
    public customizeService: CustomizeService,
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    private metaService: MetaService,
    private userActivityService: UserActivityService,
    private documentService: DocumentService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef,
    private routingService: RoutingService,
    private customizeBarContext: CustomizeBarContext
  ) {}

  ngOnInit(): void {
    merge(this.activatedRoute.params, this.activatedRoute.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const snapshot = this.activatedRoute.snapshot;
        this.context.objectType = 'dashboard';
        this.context.objectId = snapshot.params['id'];
        this.cd.markForCheck();
      });

    this.metaService.set({ title: 'Dashboard' });
    this.customizeService.layoutCustomization = {
      subtitle: '',
      title: 'Edit Dashboard',
      image: 'dashboard',
      clickEvent: [AnalyticsEvent.Deprecated.CustomizeInterfaceChooseDashboard, { DashboardID: this.dashboard.id }],
      description: [
        { icon: 'dashboard', label: 'Create multiple dashboards' },
        { icon: 'chart', label: 'Add and edit Chart Components: \nLine, Bar, Pie, List and others' },
        { icon: 'magic_wand', label: 'Use the SQL Builder for more complex queries' }
      ]
    };

    this.customizeService.layoutEnabled$.pipe(untilDestroyed(this)).subscribe(enabled => {
      if (enabled) {
        this.customizeService.setHandler(this);
        this.updateCustomizeHandleInfo();
      } else {
        this.customizeService.unsetHandler(this);
      }
    });
  }

  ngOnDestroy(): void {
    this.customizeService.layoutCustomization = undefined;
    this.customizeService.unsetHandler(this);
    this.closeCustomize();
  }

  onBeforeDestroy(): void {
    this.context.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getWidgets();
    this.updateCustomizeHandleInfo();
  }

  ngAfterViewInit(): void {
    this.documentService.viewportSize$
      .pipe(
        debounceTime(100),
        map(() => {
          return {
            width: window.innerWidth,
            height: window.innerHeight
          };
        }),
        pairwise(),
        untilDestroyed(this)
      )
      .subscribe(([prevSize, newSize]) => {
        this.updateColumnWidth();
        this.cd.markForCheck();
        this.updateWidgets();

        if (newSize.width != prevSize.width) {
          const widgets = this.widgets;

          this.widgets = undefined;
          this.cd.detectChanges();

          this.widgets = widgets;
          this.cd.detectChanges();
        }

        this.initGrid();
      });

    this.updateColumnWidth();
    this.cd.markForCheck();
  }

  getCollaborationParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: 'dashboard',
      object_id: snapshot.params['id']
    };
  }

  getUserActivitiesParams(): Object {
    const snapshot = this.activatedRoute.snapshot;
    return {
      object_type: 'dashboard',
      object_id: snapshot.params['id']
    };
  }

  getWidgets() {
    const dashboard = this.dashboard;

    this.loading = true;
    this.cd.markForCheck();

    const obs = this.widgetService.get(this.currentProjectStore.instance.uniqueName, { dashboard: dashboard.id }).pipe(
      untilDestroyed(this),
      tap(result => {
        this.widgets = undefined;
        this.context.clear(ViewContextElementType.Element);
        this.cd.detectChanges();
        this.widgets = result;
        this.widgetsDashboard = dashboard;
        this.initialWidgets = cloneDeep(this.widgets);
        this.cd.detectChanges();
        this.initGrid();
        this.customizeService.startTrackChanges();

        this.userActivityService
          .currentProjectCreateDashboardInstance(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            UserActivityType.DashboardList,
            dashboard.id,
            {
              id: dashboard.id
            }
          )
          .subscribe(() => {});
      })
    );

    obs.pipe(untilDestroyed(this)).subscribe(
      () => {
        this.loading = false;
        this.cd.markForCheck();
      },
      e => {
        console.log(e);
        this.loading = false;
        this.cd.markForCheck();
      }
    );

    return obs;
  }

  initGrid() {
    if (!this.dashboardElement) {
      return;
    }

    this.grid = new Packery(this.dashboardElement.nativeElement, {
      itemSelector: '.dashboard__item',
      columnWidth: this.columnWidth,
      rowHeight: this.rowHeight,
      percentPosition: true,
      initLayout: false,
      resize: false
    });
    this.dashboardItemElements.forEach(item => this.initGridItem(item.nativeElement));
    this.initWidgetPositions();

    this.grid.on('dragItemPositioned', () => {
      // this.grid.shiftLayout();
    });
  }

  initGridItem(el) {
    const draggie = new Draggabilly(el, {
      handle: '.dashboard-item__draggable'
    });
    this.grid.bindDraggabillyEvents(draggie);
  }

  updateColumnWidth() {
    this.columnWidth = Math.floor(this.dashboardElementWrapper.nativeElement.offsetWidth / this.columns);
  }

  initWidgetPositions() {
    if (!this.grid) {
      return;
    }

    this.grid._resetLayout();
    this.grid.items = [...this.widgets]
      .sort((lhs, rhs) => {
        if (lhs.y == rhs.y) {
          return lhs.x - rhs.x;
        } else {
          return lhs.y - rhs.y;
        }
      })
      .map(widget => {
        const item = this.grid.items.find(i => i.element.dataset['id'] == widget.id);

        item.rect.x = this.grid.columnWidth * widget.x;
        item.rect.y = this.grid.rowHeight * widget.y;

        return item;
      });

    this.grid.shiftLayout();
  }

  get widgetPosition() {
    const yBottom = this.widgets.map(item => item.y + item.height);

    return {
      x: 0,
      y: yBottom.length ? Math.max(...yBottom) : 0
    };
  }

  onWidgetSizeChanged() {
    this.cd.detectChanges();
    this.grid.shiftLayout();
  }

  onWidgetSizeChangeConfirmed() {}

  deleteWidget(widget) {
    this.dialogService
      .warning({
        title: 'Are you sure?',
        description: 'Widget will be deleted completely'
      })
      .pipe(untilDestroyed(this))
      .subscribe(confirm => {
        if (confirm) {
          this.onWidgetDeleteRequested(widget);
        }
      });
  }

  copyWidget(copyWidget: Widget) {
    const widget = cloneDeep(copyWidget);

    widget.id = undefined;
    widget.x = this.widgetPosition.x;
    widget.y = this.widgetPosition.y;
    widget.dashboard = this.dashboard.id;

    this.onWidgetAdded(widget);
  }

  onWidgetAdded(widget) {
    this.widgets.push(widget);
    this.cd.detectChanges();

    const el = this.dashboardItemElements.last.nativeElement;

    this.grid.appended(el);
    this.initGridItem(el);

    const widgetElements = this.dashboardItemElements.toArray();
    const widgetElement = widgetElements[widgetElements.length - 1];

    scrollWindowTo(widgetElement.nativeElement, 0.6);

    const component = this.dashboardItems.find(item => item.widget === widget);

    component.editWidget();

    this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidget, {
      WidgetTypeID: widget['chartType'] || widget.type,
      WidgetID: widget.id
    });
  }

  onWidgetDeleteRequested(widget) {
    this.widgets = this.widgets.filter(item => item.id != widget.id);
    this.cd.markForCheck();

    this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Deleted);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidget, {
      WidgetTypeID: widget['chartType'] || widget.type,
      WidgetID: widget.id
    });
  }

  onWidgetResizingChanged(widget) {
    this.resizingWidget = widget;
    this.cd.markForCheck();
  }

  toggleCustomize() {
    this.customizeService.toggleEnabled(CustomizeType.Layout);
  }

  updateWidgets() {
    if (!this.grid) {
      return;
    }

    this.widgets = this.grid.items
      .map(item => {
        const widget = this.widgets.find(w => w.id == item.element.dataset['id']);

        if (!widget) {
          return;
        }

        widget.x = Math.round(item.rect.x / this.grid.columnWidth);
        widget.y = Math.round(item.rect.y / this.grid.rowHeight);
        widget.width = Math.round(item.rect.width / this.grid.columnWidth);
        widget.height = Math.round(item.rect.height / this.grid.rowHeight);

        return widget;
      })
      .filter(item => item != undefined);

    this.cd.markForCheck();
  }

  updateCustomizeHandleInfo() {
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: this.dashboard ? ['Customizing Dashboard', this.dashboard.name] : undefined
    });
  }

  getChangesState(): DashboardPageState {
    const settingsChanges = ['name', 'params'];
    const positionChanges = ['id', 'x', 'y', 'width', 'height'];

    const createWidgets = this.widgets.filter(item => !this.initialWidgets.find(i => i.id == item.id));
    const updateWidgets = this.widgets.filter(item => {
      const initialWidget = this.initialWidgets.find(i => i.id == item.id);

      if (!initialWidget) {
        return false;
      }

      return !isEqual(item.serialize(settingsChanges), initialWidget.serialize(settingsChanges));
    });
    const deleteWidgets = this.initialWidgets.filter(item => !this.widgets.find(i => i.id == item.id));
    const widgetPositions = !isEqual(
      this.widgets.map(item => item.serialize(positionChanges)),
      this.initialWidgets.map(item => item.serialize(positionChanges))
    )
      ? this.widgets
      : undefined;

    return {
      createWidgets: createWidgets,
      updateWidgets: updateWidgets,
      deleteWidgets: deleteWidgets,
      widgetPositions: widgetPositions
    };
  }

  setChangesState(state: DashboardPageState) {}

  isChangesStateEqual(lhs: DashboardPageState, rhs: DashboardPageState): boolean {
    return (
      isEqual(
        lhs.createWidgets.map(item => item.serialize()),
        rhs.createWidgets.map(item => item.serialize())
      ) &&
      isEqual(
        lhs.updateWidgets.map(item => item.serialize()),
        rhs.updateWidgets.map(item => item.serialize())
      ) &&
      isEqual(
        lhs.deleteWidgets.map(item => item.serialize()),
        rhs.deleteWidgets.map(item => item.serialize())
      ) &&
      isEqual(
        lhs.widgetPositions.map(item => item.serialize()),
        rhs.widgetPositions.map(item => item.serialize())
      )
    );
  }

  saveChangesState(state: DashboardPageState): Observable<DashboardPageState> {
    this.updateWidgets();

    const requests = [
      ...state.createWidgets.map((item: Widget) => {
        item.dashboard = this.dashboard.id;
        return this.widgetService.create(this.currentProjectStore.instance.uniqueName, item).pipe(
          tap(widget => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidgetSuccess,
              {
                WidgetTypeID: widget.type,
                WidgetID: widget.id
              }
            );
          }),
          catchError(error => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidgetError,
              {
                WidgetTypeID: item.type,
                WidgetID: item.id,
                Error: error.toString()
              }
            );
            return of(undefined);
          }),
          map(instance => {
            return { widget: item, instance: instance };
          })
        );
      }),
      ...state.updateWidgets.map((item: Widget) => {
        return this.widgetService.update(this.currentProjectStore.instance.uniqueName, item).pipe(
          tap(widget => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidgetSuccess,
              {
                WidgetTypeID: widget.type,
                WidgetID: widget.id
              }
            );
          }),
          catchError(error => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidgetError,
              {
                WidgetTypeID: item.type,
                WidgetID: item.id,
                Error: error.toString()
              }
            );
            return of(undefined);
          }),
          map(instance => {
            return { widget: item, instance: instance };
          })
        );
      }),
      ...state.deleteWidgets.map((item: Widget) => {
        return this.widgetService.delete(this.currentProjectStore.instance.uniqueName, item).pipe(
          tap(() => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidgetSuccess,
              {
                WidgetTypeID: item.type,
                WidgetID: item.id
              }
            );
          }),
          catchError(error => {
            this.analyticsService.sendSimpleEvent(
              AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidgetError,
              {
                WidgetTypeID: item.type,
                WidgetID: item.id,
                Error: error.toString()
              }
            );
            return of(undefined);
          }),
          map(instance => {
            return { widget: item, instance: undefined };
          })
        );
      })
    ];

    if (!requests.length) {
      return of(state);
    }

    return combineLatest(...requests).pipe(
      tap((updates: { widget: Widget; instance: Widget }[]) => {
        this.widgets = this.widgets
          .map(item => {
            const update = updates.find(i => i.widget === item);

            if (update) {
              update.instance['_uid'] = item['_uid'];
              return update.instance;
            } else {
              return item;
            }
          })
          .filter(item => item != undefined);
        this.initialWidgets = cloneDeep(this.widgets);
        this.cd.markForCheck();
      }),
      switchMap(() => this.savePositionsCustomize(state)),
      map(() => {
        return state;
      })
    );
  }

  savePositionsCustomize(state: DashboardPageState): Observable<DashboardPageState> {
    if (!state.widgetPositions) {
      return of(state);
    }

    return this.dashboardService
      .setWidgets(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.dashboard,
        state.widgetPositions
      )
      .pipe(map(() => state));
  }

  reload(): Observable<any> {
    return this.getWidgets();
  }

  moveToDashboard(widget: Widget, dashboard: Dashboard) {
    const instance = cloneDeep(widget);
    instance.dashboard = dashboard.id;
    this.widgetService
      .update(this.currentProjectStore.instance.uniqueName, instance, ['dashboard'])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routingService.navigateApp(dashboard.link));
  }

  updateItem(widget: Widget, instance: Widget) {
    this.widgets = this.widgets.map(item => {
      if (item === widget) {
        return instance;
      } else {
        return item;
      }
    });
    this.cd.markForCheck();

    this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Updated);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidget, {
      WidgetTypeID: widget.type,
      WidgetID: widget.id
    });

    this.customizeService.markChanged();
  }

  trackByFn(i, item: Widget) {
    if (item['_uid'] || !item.id) {
      if (!item['_uid']) {
        item['_uid'] = generateUUID();
      }

      return `uid_${item['_uid']}`;
    } else {
      return `id_${item.id}`;
    }
  }

  closeCustomize() {
    if (this.customizeComponentData) {
      this.customizeBarContext.closeSettingsComponent(this.customizeComponentData);
      this.customizeComponentData = undefined;
    }
  }
}

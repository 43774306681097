import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { isSet } from '@shared';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { FormulaInsert } from '../../data/formula-token';

@Component({
  selector: 'app-view-context-token-popover-section',
  templateUrl: './view-context-token-popover-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title = false;
  @Input() section: FormulaSection;
  @Input() activeToken: FormulaSectionItem;
  @Input() descriptionAlwaysVisible = false;
  @Input() limitItems: number;
  @Input() showSubtitle = false;
  @Output() hover = new EventEmitter<FormulaSectionItem>();
  @Output() out = new EventEmitter<FormulaSectionItem>();
  @Output() opened = new EventEmitter<FormulaSection>();
  @Output() selected = new EventEmitter<FormulaInsert>();
  @Output() showMoreClick = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  sections: { title: string; items: FormulaSectionItem[] }[] = [];
  showMore = false;

  trackItem = (() => {
    return (i, item: FormulaSectionItem) => {
      return i;
    };
  })();

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      const group = this.showSubtitle && !this.section.horizontal;

      if (group) {
        this.sections = this.groupSections(this.section);
        this.showMore = false;
      } else {
        const maxItems = this.limitItems && this.section.items.length > this.limitItems ? this.limitItems : undefined;

        this.sections = this.singleSection(this.section, maxItems);
        this.showMore = isSet(maxItems);
      }
    }
  }

  groupSections(section: FormulaSection): { title: string; items: FormulaSectionItem[] }[] {
    return toPairs(
      section.items.reduce<{ [k: string]: FormulaSectionItem[] }>((acc, item) => {
        const key = item.subtitle || '';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {})
    ).map(([subtitle, items]) => {
      return {
        title: subtitle,
        items: items
      };
    });
  }

  singleSection(section: FormulaSection, maxItems?: number): { title: string; items: FormulaSectionItem[] }[] {
    return [
      {
        title: this.title ? section.label : undefined,
        items: maxItems !== undefined ? this.section.items.slice(0, maxItems) : this.section.items
      }
    ];
  }

  onItemSelected(item: FormulaSectionItem) {
    if (item.section) {
      this.selected.emit(item.section);
    } else if (item.item) {
      this.selected.emit(item.item);
    }
  }

  onSectionOpened(section: FormulaSection) {
    if (!section.items.length) {
      return;
    }

    this.opened.emit(section);
  }

  onSectionActionClick() {
    this.section.action
      .handler()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.insertToken) {
          this.selected.emit({
            token: result.insertToken,
            insert: result.insertToken
          });
        }
      });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentUserStore, Employment, User, UserLevel } from '@modules/users';
import { isSet } from '@shared';

export const validateCompanyWebsite: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const parent = control.parent as ProjectCreateUserForm;

  if (parent.controls.employment.value != Employment.Company) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

@Injectable()
export class ProjectCreateUserForm extends FormGroup implements OnDestroy {
  controls: {
    employment: FormControl;
    company_website: FormControl;
    phone: FormControl;
  };

  levelOptions = [
    { value: UserLevel.Beginner, name: '📙 Beginner. I am new to databases and business apps' },
    { value: UserLevel.Intermediate, name: '🤙 Intermediate. I have experience with databases and business apps' },
    { value: UserLevel.Advanced, name: '😎 Advanced. I am an expert with databases and business apps' }
  ];

  constructor(
    private formUtils: FormUtils,
    private analyticsService: UniversalAnalyticsService,
    private currentUserStore: CurrentUserStore
  ) {
    super({
      employment: new FormControl(Employment.Company, Validators.required),
      company_website: new FormControl('', validateCompanyWebsite),
      phone: new FormControl('')
    });

    this.controls.employment.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.controls.company_website.updateValueAndValidity();
    });

    this.markAsSubmit(false);
  }

  ngOnDestroy(): void {}

  markAsSubmit(submit: boolean) {
    this['submit'] = submit;

    if (submit) {
      this.markAsDirty();
    }
  }

  send(): Observable<User> {
    this.markAsSubmit(true);

    this.currentUserStore.instance.params.userInfo = {
      employment: this.controls.employment.value,
      company_website: this.controls.company_website.value,
      phone: this.controls.phone.value
    };

    return this.currentUserStore.update(['params']).pipe(
      tap(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UserInfoEntered, {
          Employment: this.controls.employment.value,
          CompanyWebsite: this.controls.company_website.value,
          Phone: this.controls.phone.value
        });
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

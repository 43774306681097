var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { ThemeService } from '@modules/theme';
import { BaseChartComponent } from '../base-chart/base-chart.component';
var PolarAreaChartComponent = /** @class */ (function (_super) {
    __extends(PolarAreaChartComponent, _super);
    function PolarAreaChartComponent(themeService) {
        var _this = _super.call(this, themeService) || this;
        _this.themeService = themeService;
        _this.animate = true;
        _this.legend = true;
        return _this;
    }
    PolarAreaChartComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (['legend'].some(function (item) { return changes.hasOwnProperty(item); })) {
            this.initChart();
        }
    };
    PolarAreaChartComponent.prototype.chartOptions = function () {
        return {
            type: 'polarArea'
        };
    };
    PolarAreaChartComponent.prototype.lineChartData = function () {
        var _this = this;
        if (!this.datasetsCurrent) {
            return [];
        }
        return this.datasetsCurrent.map(function (dataset) {
            var instance = cloneDeep(dataset.dataset);
            var backgroundColor = _this.segmentColors(false, dataset.dataset.length).map(function (item, i) {
                if (instance[i] && instance[i].color) {
                    return instance[i].color;
                }
                return item;
            });
            return {
                data: instance.map(function (item) { return item.value; }),
                label: dataset.name,
                backgroundColor: backgroundColor,
                borderWidth: 2,
                borderColor: _this.themeService.theme == 'dark' ? '#2C343A' : '#fff',
                // hoverBackgroundColor: colors,
                // hoverBorderWidth: 2,
                hoverBorderColor: _this.themeService.theme == 'dark' ? '#2C343A' : '#fff'
            };
        });
    };
    PolarAreaChartComponent.prototype.lineChartOptions = function () {
        var _this = this;
        var parentWidth = this.chartElement.nativeElement.parentNode.offsetWidth;
        return {
            responsive: true,
            maintainAspectRatio: false,
            animation: this.animate
                ? {
                    duration: 1000,
                    easing: 'easeInOutBack'
                }
                : false,
            legend: this.legend
                ? {
                    position: parentWidth == 0 || parentWidth >= 300 ? 'right' : 'bottom',
                    labels: {
                        fontColor: '#7D91A5',
                        fontSize: 10,
                        fontFamily: "'Fira Sans', sans-serif",
                        usePointStyle: true,
                        padding: 8,
                        filter: function (item, chart) {
                            return item['index'] < 8;
                        }
                    }
                }
                : false,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) { return _this.tooltipLabel(tooltipItem, data, 1); }
                }
            }
        };
    };
    PolarAreaChartComponent.prototype.onThemeChange = function () {
        this.chart.data.datasets = this.lineChartData();
        this.chart.update();
    };
    return PolarAreaChartComponent;
}(BaseChartComponent));
export { PolarAreaChartComponent };

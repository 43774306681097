<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="colorPositions"
  [cdkConnectedOverlayOrigin]="color_trigger"
  [cdkConnectedOverlayOpen]="colorOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setOpened(false)"
  (detach)="setOpened(false)"
>
  <app-fill-color-overlay
    [control]="control"
    [viewContext]="viewContext"
    [view]="view"
    [layer]="layer"
    [fillTypesEnabled]="fillTypesEnabled"
    (blur)="setOpened(false)"
  ></app-fill-color-overlay>
</ng-template>

<div class="compact-color-button" cdkOverlayOrigin #color_trigger="cdkOverlayOrigin" (click)="setOpened(true)">
  <div class="compact-color-button__fill compact-color-button__fill_transparent"></div>

  <div
    *ngIf="colorBackground"
    class="compact-color-button__fill"
    [class.compact-color-button__fill_inner]="colorTransform"
    [style.background]="colorTransform ? null : colorBackground"
  >
    <span
      *ngIf="colorTransform"
      class="compact-color-button__fill-inner"
      [style.background]="colorBackground"
      [style.width]="colorWidth"
      [style.height]="colorHeight"
      [style.transform]="colorTransform"
    ></span>
  </div>

  <div
    *ngIf="colorIcon"
    class="compact-color-button__icon"
    [style.color]="colorIcon.color"
    [appIcon]="colorIcon.icon"
  ></div>
</div>

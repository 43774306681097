import repeat from 'lodash/repeat';
import trim from 'lodash/trim';

import { isSet } from '../common/common';

export function capitalize(value: string) {
  if (!value) {
    return value;
  }

  const valueStr = value.toString();
  const capitalized = valueStr.substr(0, 1).toUpperCase();

  if (capitalized[0] == valueStr[0]) {
    return valueStr;
  }

  return capitalized + valueStr.substr(1);
}

// Возврашает инициалы из строки
export function initials(value: string): string {
  return value
    ? value
        .replace('_', '-')
        .replace('.', '-')
        .replace(' ', '-')
        .split('-')
        .slice(0, 2)
        .map(item => item[0])
        .join('')
    : '';
}

export function trimAll(value: string) {
  const characters = [' ', '⠀', '‍'];
  return trim(value, characters.join());
}

export function cleanWrapSpaces(str: string) {
  if (!(typeof str == 'string')) {
    return str;
  }

  const regex = new RegExp(String.fromCharCode(160), 'g');
  return str.replace(regex, ' ');
}

export function stripTags(value: string): string {
  if (!isSet(value) || typeof value !== 'string') {
    return value;
  }

  return value.replace(/<\S[^><]*>/g, '');
}

export function indent(str: string, n: number): string {
  return repeat(' ', n) + str;
}

export function indentLines(str: string, n: number): string {
  return str
    .split(/\r?\n/)
    .map(item => indent(item, n))
    .join('\n');
}

export function stripStart(str: string, searchString: string, caseInsensitive = false): string {
  if (typeof str !== 'string') {
    return str;
  }

  const match = caseInsensitive
    ? str.toLowerCase().startsWith(searchString.toLowerCase())
    : str.startsWith(searchString);
  if (match) {
    return str.substr(searchString.length);
  } else {
    return str;
  }
}

export function stripEnd(str: string, searchString: string, caseInsensitive = false): string {
  if (typeof str !== 'string') {
    return str;
  }

  const match = caseInsensitive ? str.toLowerCase().endsWith(searchString.toLowerCase()) : str.endsWith(searchString);
  if (match) {
    return str.slice(0, -1 * searchString.length);
  } else {
    return str;
  }
}

export function strip(str: string, searchString: string, caseInsensitive = false): string {
  return stripEnd(stripStart(str, searchString, caseInsensitive), searchString, caseInsensitive);
}

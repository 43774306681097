<app-popup2 [size]="'m'">
  <app-popup2-header>
    <app-popup2-title *ngIf="files.length == 1">Upload File</app-popup2-title>
    <app-popup2-title *ngIf="files.length != 1">Upload Files</app-popup2-title>

    <app-popup2-description *ngIf="files.length == 1">Uploading file to {{ storage.name }}</app-popup2-description>
    <app-popup2-description *ngIf="files.length != 1">Uploading files to {{ storage.name }}</app-popup2-description>
  </app-popup2-header>

  <ng-container *ngIf="state">
    <app-page-block *ngIf="state.files.length > 1">
      <app-bar-progress
        [label]="'Total progress'"
        [progress]="state.processed / state.files.length"
        [processed]="state.processed"
        [total]="state.files.length"
      >
      </app-bar-progress>
    </app-page-block>

    <app-page-block *ngFor="let item of state.files; let i = index">
      <app-bar-progress
        [label]="item.file.name"
        [labelWrap]="true"
        [progress]="item.uploadProgress"
        [error]="item.error"
        [showPercentage]="true"
        [secondary]="state.files.length > 1"
      >
      </app-bar-progress>
    </app-page-block>
  </ng-container>

  <app-popup2-footer>
    <app-page-block>
      <app-stack [align]="'right'">
        <a href="javascript:void(0)" class="button popup2__footer-item" (click)="cancel()">
          <span class="button__label">Cancel</span>
        </a>

        <a
          href="javascript:void(0)"
          class="button button_primary popup2__footer-item"
          [class.button_disabled]="uploadLoading"
          (click)="finish()"
        >
          <span class="button__label">
            <ng-container *ngIf="uploadLoading">Uploading</ng-container>
            <ng-container *ngIf="!uploadLoading">Done</ng-container>
          </span>
        </a>
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>

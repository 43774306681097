var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { Domain, DomainService } from '@modules/domain';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
var ProjectDomainForm = /** @class */ (function (_super) {
    __extends(ProjectDomainForm, _super);
    function ProjectDomainForm(currentProjectStore, currentEnvironmentStore, formUtils, domainService) {
        var _this = _super.call(this, {
            domain: new FormControl('', [
                Validators.required,
                Validators.pattern(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/)
            ]),
            domain_entered: new FormControl(null, Validators.required)
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.formUtils = formUtils;
        _this.domainService = domainService;
        _this.controls.domain.valueChanges.subscribe(function (rawValue) {
            var cleanValue = _this.cleanValue(rawValue);
            if (cleanValue !== rawValue) {
                _this.controls.domain.setValue(cleanValue);
            }
            _this.controls.domain_entered.patchValue(false);
        });
        return _this;
    }
    ProjectDomainForm.prototype.cleanValue = function (value) {
        if (!isSet(value)) {
            value = '';
        }
        return value
            .toLowerCase()
            .replace(/[^a-z0-9\-.]/g, '')
            .replace(/\.+/g, '.');
    };
    ProjectDomainForm.prototype.init = function (project) {
        this.instance = project.domain;
    };
    ProjectDomainForm.prototype.add = function () {
        var _this = this;
        var instance = new Domain();
        instance.domain = this.controls.domain.value;
        return this.domainService
            .createCustom(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, instance)
            .pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    ProjectDomainForm.prototype.delete = function () {
        var _this = this;
        return this.domainService
            .deleteCustom(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.instance)
            .pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectDomainForm;
}(FormGroup));
export { ProjectDomainForm };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import toPairs from 'lodash/toPairs';
import { isFormulaAccessorItemDotNotation } from '@modules/parameters';
import { isSet } from '@shared';
var FormulaElementDescriptor = /** @class */ (function () {
    function FormulaElementDescriptor(tag, options) {
        if (options === void 0) { options = {}; }
        this.classes = new Set();
        this.children = [];
        this.onCreateHandlers = [];
        this.tag = tag.toUpperCase();
        Object.assign(this, options);
    }
    FormulaElementDescriptor.prototype.addClass = function () {
        var _this = this;
        var classes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            classes[_i] = arguments[_i];
        }
        classes.forEach(function (item) { return _this.classes.add(item); });
    };
    FormulaElementDescriptor.prototype.appendChild = function (element) {
        this.children.push(element);
    };
    FormulaElementDescriptor.prototype.onCreate = function (callback) {
        this.onCreateHandlers.push(callback);
    };
    return FormulaElementDescriptor;
}());
export { FormulaElementDescriptor };
export function renderFormulaNode(node, options) {
    if (options === void 0) { options = {}; }
    if (node.isFunctionNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-func');
        var label = new FormulaElementDescriptor('span');
        label.addClass('formula-func-label');
        label.text = node.name.toUpperCase();
        root.appendChild(label);
        var parenthesisOpen = new FormulaElementDescriptor('span');
        parenthesisOpen.addClass('formula-punctuation');
        parenthesisOpen.text = '(';
        root.appendChild(parenthesisOpen);
        var prevArg = void 0;
        for (var _i = 0, _a = node.args; _i < _a.length; _i++) {
            var arg = _a[_i];
            if (prevArg) {
                var comma = new FormulaElementDescriptor('span');
                comma.addClass('formula-punctuation', 'formula-punctuation_comma');
                comma.text = ',';
                root.appendChild(comma);
            }
            var argEl = renderFormulaNode(arg, __assign({}, options, { parentNode: node }));
            root.appendChild(argEl);
            prevArg = arg;
        }
        var parenthesisClose = new FormulaElementDescriptor('span');
        parenthesisClose.addClass('formula-punctuation');
        parenthesisClose.text = ')';
        root.appendChild(parenthesisClose);
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isConstantNode) {
        var root = new FormulaElementDescriptor('span');
        if (typeof node.value === 'string') {
            if (options.parentNode &&
                options.parentNode.isAccessorNode &&
                options.parentNode.index.dotNotation &&
                isFormulaAccessorItemDotNotation(node.value)) {
                root.addClass('formula-symbol');
                root.text = node.value;
            }
            else {
                root.addClass('formula-string');
                root.text = JSON.stringify(node.value);
            }
        }
        else if (typeof node.value === 'number') {
            root.addClass('formula-number');
            root.text = String(node.value);
        }
        else if (typeof node.value === 'boolean') {
            root.addClass('formula-boolean');
            root.text = String(node.value);
        }
        else if (node.value === null || node.value === undefined) {
            root.addClass('formula-symbol');
            root.text = String(node.value);
        }
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isSymbolNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-symbol');
        root.text = node.name;
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isParenthesisNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-parenthesis');
        var parenthesisOpen = new FormulaElementDescriptor('span');
        parenthesisOpen.addClass('formula-punctuation');
        parenthesisOpen.text = '(';
        root.appendChild(parenthesisOpen);
        var content = renderFormulaNode(node.content, __assign({}, options, { parentNode: node }));
        root.appendChild(content);
        var parenthesisClose = new FormulaElementDescriptor('span');
        parenthesisClose.addClass('formula-punctuation');
        parenthesisClose.text = ')';
        root.appendChild(parenthesisClose);
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isOperatorNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-operator-node');
        var operator = new FormulaElementDescriptor('span');
        operator.addClass('formula-operator');
        operator.text = node.op;
        var arg0 = renderFormulaNode(node.args[0], __assign({}, options, { parentNode: node }));
        var arg1 = node.args[1] ? renderFormulaNode(node.args[1], __assign({}, options, { parentNode: node })) : undefined;
        if (arg1) {
            operator.addClass('formula-operator_binary');
            root.appendChild(arg0);
            root.appendChild(operator);
            root.appendChild(arg1);
        }
        else if (['-', '+'].includes(node.op)) {
            root.appendChild(operator);
            root.appendChild(arg0);
        }
        else {
            root.appendChild(arg0);
            root.appendChild(operator);
        }
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isAccessorNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-token');
        var object = renderFormulaNode(node.object, __assign({}, options, { parentNode: node }));
        root.appendChild(object);
        if (node.index.dotNotation) {
            var dot = new FormulaElementDescriptor('span');
            dot.addClass('formula-punctuation');
            dot.text = '.';
            root.appendChild(dot);
            var index = renderFormulaNode(node.index.dimensions[0], __assign({}, options, { parentNode: node }));
            root.appendChild(index);
        }
        else {
            var bracketOpen = new FormulaElementDescriptor('span');
            bracketOpen.addClass('formula-punctuation');
            bracketOpen.text = '[';
            root.appendChild(bracketOpen);
            var index = renderFormulaNode(node.index.dimensions[0], __assign({}, options, { parentNode: node }));
            root.appendChild(index);
            var bracketClose = new FormulaElementDescriptor('span');
            bracketClose.addClass('formula-punctuation');
            bracketClose.text = ']';
            root.appendChild(bracketClose);
        }
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isObjectNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-object');
        var braceOpen = new FormulaElementDescriptor('span');
        braceOpen.addClass('formula-punctuation');
        braceOpen.text = '{';
        root.appendChild(braceOpen);
        var prevValue = void 0;
        for (var _b = 0, _c = toPairs(node.properties); _b < _c.length; _b++) {
            var _d = _c[_b], name_1 = _d[0], value = _d[1];
            if (prevValue) {
                var comma = new FormulaElementDescriptor('span');
                comma.addClass('formula-punctuation', 'formula-punctuation_comma');
                comma.text = ',';
                root.appendChild(comma);
            }
            var key = new FormulaElementDescriptor('span');
            key.addClass('formula-string');
            key.text = "\"" + name_1 + "\"";
            root.appendChild(key);
            var colon = new FormulaElementDescriptor('span');
            colon.addClass('formula-punctuation', 'formula-punctuation_colon');
            colon.text = ':';
            root.appendChild(colon);
            var valueEl = renderFormulaNode(value, __assign({}, options, { parentNode: node }));
            root.appendChild(valueEl);
            prevValue = value;
        }
        var braceClose = new FormulaElementDescriptor('span');
        braceClose.addClass('formula-punctuation');
        braceClose.text = '}';
        root.appendChild(braceClose);
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isArrayNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-object');
        var bracketOpen = new FormulaElementDescriptor('span');
        bracketOpen.addClass('formula-punctuation');
        bracketOpen.text = '[';
        root.appendChild(bracketOpen);
        var prevValue = void 0;
        for (var _e = 0, _f = node.items; _e < _f.length; _e++) {
            var value = _f[_e];
            if (prevValue) {
                var comma = new FormulaElementDescriptor('span');
                comma.addClass('formula-punctuation', 'formula-punctuation_comma');
                comma.text = ',';
                root.appendChild(comma);
            }
            var valueEl = renderFormulaNode(value, __assign({}, options, { parentNode: node }));
            root.appendChild(valueEl);
            prevValue = value;
        }
        var bracketClose = new FormulaElementDescriptor('span');
        bracketClose.addClass('formula-punctuation');
        bracketClose.text = ']';
        root.appendChild(bracketClose);
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isBlockNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-blocks');
        var prevBlock = void 0;
        for (var _g = 0, _h = node.blocks; _g < _h.length; _g++) {
            var block = _h[_g];
            if (prevBlock) {
                var comma = new FormulaElementDescriptor('span');
                comma.addClass('formula-punctuation', 'formula-punctuation_comma');
                comma.text = ';';
                root.appendChild(comma);
            }
            var argEl = renderFormulaNode(block.node, __assign({}, options, { parentNode: node }));
            root.appendChild(argEl);
            prevBlock = block;
        }
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else if (node.isFunctionAssignmentNode) {
        var root = new FormulaElementDescriptor('span');
        root.addClass('formula-func-assign');
        var label = new FormulaElementDescriptor('span');
        label.addClass('formula-func-label');
        label.text = node.name;
        root.appendChild(label);
        var parenthesisOpen = new FormulaElementDescriptor('span');
        parenthesisOpen.addClass('formula-punctuation');
        parenthesisOpen.text = '(';
        root.appendChild(parenthesisOpen);
        var prevArg = void 0;
        for (var _j = 0, _k = node.params; _j < _k.length; _j++) {
            var arg = _k[_j];
            if (prevArg) {
                var comma = new FormulaElementDescriptor('span');
                comma.addClass('formula-punctuation', 'formula-punctuation_comma');
                comma.text = ',';
                root.appendChild(comma);
            }
            var argEl = new FormulaElementDescriptor('span');
            argEl.addClass('formula-symbol');
            argEl.text = arg;
            root.appendChild(argEl);
            prevArg = arg;
        }
        var parenthesisClose = new FormulaElementDescriptor('span');
        parenthesisClose.addClass('formula-punctuation');
        parenthesisClose.text = ')';
        root.appendChild(parenthesisClose);
        var equals = new FormulaElementDescriptor('span');
        equals.addClass('formula-operator', 'formula-operator_binary');
        equals.text = '=';
        root.appendChild(equals);
        var expression = renderFormulaNode(node.expr, __assign({}, options, { parentNode: node }));
        root.appendChild(expression);
        if (options.setupDescriptor) {
            options.setupDescriptor(root, node);
        }
        return root;
    }
    else {
        throw new Error("Unsupported node: " + node);
    }
}
export function renderFormulaElementDescriptors(container, elements) {
    container.childNodes.forEach(function (item) {
        if (item.nodeType != document.ELEMENT_NODE) {
            container.removeChild(item);
        }
    });
    var _loop_1 = function (i) {
        var _a;
        var element = elements[i];
        var domElement;
        if (!container.children[i]) {
            domElement = document.createElement(element.tag);
            container.appendChild(domElement);
        }
        else if (container.children[i].tagName != element.tag) {
            domElement = document.createElement(element.tag);
            container.replaceChild(domElement, container.children[i]);
        }
        else {
            domElement = container.children[i];
        }
        var removeClasses = [];
        domElement.classList.forEach(function (className) {
            if (!element.classes.has(className)) {
                removeClasses.push(className);
            }
        });
        (_a = domElement.classList).remove.apply(_a, removeClasses);
        element.classes.forEach(function (className) {
            if (!domElement.classList.contains(className)) {
                domElement.classList.add(className);
            }
        });
        if (isSet(element.text)) {
            if (domElement.innerText != element.text) {
                domElement.innerText = element.text;
            }
        }
        else {
            renderFormulaElementDescriptors(domElement, element.children);
        }
        element.onCreateHandlers.forEach(function (handler) { return handler(domElement); });
    };
    for (var i = 0; i < elements.length; ++i) {
        _loop_1(i);
    }
    Array.from(container.children)
        .slice(elements.length)
        .forEach(function (item) { return container.removeChild(item); });
}
export function getRootFormulaToken(element) {
    if (element.classList.contains('formula-token') || element.classList.contains('formula-symbol')) {
        var parentResult = element.parentElement ? getRootFormulaToken(element.parentElement) : null;
        return parentResult || element;
    }
    else {
        return null;
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

import { AllProjectSettings } from '@modules/all-project-settings';
import { getCrispCode } from '@modules/integrations-components/utils/integrations/crisp';
import { getDriftCode } from '@modules/integrations-components/utils/integrations/drift';
import { getFacebookPixelCode } from '@modules/integrations-components/utils/integrations/facebook-pixel';
import { getGoogleAnalyticsCode } from '@modules/integrations-components/utils/integrations/google-analytics';
import { getHotjarCode } from '@modules/integrations-components/utils/integrations/hotjar';
import { getHubspotCode } from '@modules/integrations-components/utils/integrations/hubspot';
import { getIntercomCode } from '@modules/integrations-components/utils/integrations/intercom';
import { isSet, removeChildren, TypedChanges } from '@shared';

import { getGoogleTagManagerCode } from '../../utils/integrations/google-tag-manager';

@Component({
  selector: 'app-integrations-code',
  templateUrl: './integrations-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationsCodeComponent implements OnInit, OnChanges {
  @Input() projectSettings: AllProjectSettings;

  scripts: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.updateScripts();
  }

  ngOnChanges(changes: TypedChanges<IntegrationsCodeComponent>): void {}

  updateScripts() {
    const scripts = [];

    if (this.projectSettings.crisp && isSet(this.projectSettings.crisp.id)) {
      scripts.push(getCrispCode(this.projectSettings.crisp.id));
    }

    if (this.projectSettings.drift && isSet(this.projectSettings.drift.id)) {
      scripts.push(getDriftCode(this.projectSettings.drift.id));
    }

    if (this.projectSettings.facebookPixel && isSet(this.projectSettings.facebookPixel.id)) {
      scripts.push(getFacebookPixelCode(this.projectSettings.facebookPixel.id));
    }

    if (this.projectSettings.googleAnalytics && isSet(this.projectSettings.googleAnalytics.id)) {
      scripts.push(getGoogleAnalyticsCode(this.projectSettings.googleAnalytics.id));
    }

    if (this.projectSettings.googleTagManager && isSet(this.projectSettings.googleTagManager.id)) {
      scripts.push(getGoogleTagManagerCode(this.projectSettings.googleTagManager.id));
    }

    if (this.projectSettings.hotjar && isSet(this.projectSettings.hotjar.site_id)) {
      scripts.push(getHotjarCode(this.projectSettings.hotjar.site_id));
    }

    if (this.projectSettings.hubspot && isSet(this.projectSettings.hubspot.id)) {
      scripts.push(getHubspotCode(this.projectSettings.hubspot.id));
    }

    if (this.projectSettings.intercom && isSet(this.projectSettings.intercom.workspace_id)) {
      scripts.push(getIntercomCode(this.projectSettings.intercom.workspace_id));
    }

    const scriptsHtml = scripts.join('\n');

    if (this.scripts == scriptsHtml) {
      return;
    }

    removeChildren(this.el.nativeElement);

    if (!isSet(scriptsHtml)) {
      return;
    }

    this.setHTML(scriptsHtml);
    this.scripts = scriptsHtml;
  }

  setHTML(html: string) {
    this.el.nativeElement.innerHTML = html;

    Array.from<HTMLScriptElement>(this.el.nativeElement.querySelectorAll('script')).forEach(oldScriptEl => {
      const newScriptEl = document.createElement('script');

      Array.from(oldScriptEl.attributes).forEach(attr => {
        newScriptEl.setAttribute(attr.name, attr.value);
      });

      const scriptText = document.createTextNode(oldScriptEl.innerHTML);
      newScriptEl.appendChild(scriptText);

      oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
  }
}

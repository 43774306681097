import { Pipe, PipeTransform } from '@angular/core';

import { UniqueIdService, UniqueIdToken } from '../../services/unique-id/unique-id.service';

@Pipe({
  name: 'appUniqueId'
})
export class UniqueIdPipe implements PipeTransform {
  constructor(private uniqueIdService: UniqueIdService) {}

  transform(value: any, token: UniqueIdToken): any {
    return `${value}_${this.uniqueIdService.getIdForInstance(token)}`;
  }
}

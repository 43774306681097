import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';

import { ActionType, DownloadActionType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-parameters',
  templateUrl: './customize-bar-action-edit-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditParametersComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() analyticsSource: string;
  @Output() addQueryInput = new EventEmitter<void>();

  actionTypes = ActionType;
  downloadActionTypes = DownloadActionType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    merge(this.form.controls.type.valueChanges, this.form.controls.download_action_type.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-bar-input-edit/customize-bar-input-edit.component.ngfactory";
import * as i2 from "../../../customize-bar-input-edit/customize-bar-input-edit.component";
import * as i3 from "./customize-bar-action-edit-type-copy-to-clipboard.component";
var styles_CustomizeBarActionEditTypeCopyToClipboardComponent = [];
var RenderType_CustomizeBarActionEditTypeCopyToClipboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeCopyToClipboardComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeCopyToClipboardComponent as RenderType_CustomizeBarActionEditTypeCopyToClipboardComponent };
export function View_CustomizeBarActionEditTypeCopyToClipboardComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-customize-bar-input-edit", [], null, null, null, i1.View_CustomizeBarInputEditComponent_0, i1.RenderType_CustomizeBarInputEditComponent)), i0.ɵdid(2, 770048, null, 0, i2.CustomizeBarInputEditComponent, [i0.ChangeDetectorRef], { label: [0, "label"], required: [1, "required"], itemForm: [2, "itemForm"], context: [3, "context"], contextElement: [4, "contextElement"], contextElementPath: [5, "contextElementPath"], contextElementPaths: [6, "contextElementPaths"], fill: [7, "fill"], analyticsSource: [8, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Text to copy"; var currVal_1 = true; var currVal_2 = _co.form.controls.copy_to_clipboard_action_value; var currVal_3 = _co.context; var currVal_4 = _co.contextElement; var currVal_5 = _co.contextElementPath; var currVal_6 = _co.contextElementPaths; var currVal_7 = true; var currVal_8 = (_co.analyticsSource + "_copy_to_clipboard_value"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_CustomizeBarActionEditTypeCopyToClipboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-copy-to-clipboard", [], null, null, null, View_CustomizeBarActionEditTypeCopyToClipboardComponent_0, RenderType_CustomizeBarActionEditTypeCopyToClipboardComponent)), i0.ɵdid(1, 114688, null, 0, i3.CustomizeBarActionEditTypeCopyToClipboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeCopyToClipboardComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-copy-to-clipboard", i3.CustomizeBarActionEditTypeCopyToClipboardComponent, View_CustomizeBarActionEditTypeCopyToClipboardComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeCopyToClipboardComponentNgFactory as CustomizeBarActionEditTypeCopyToClipboardComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Fill } from '@modules/views';
import { FillControl } from './fill.control';
var FillArray = /** @class */ (function (_super) {
    __extends(FillArray, _super);
    function FillArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FillArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i] || _this.appendControl();
            control.deserialize(item);
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    FillArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    FillArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    FillArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    FillArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    FillArray.prototype.createControl = function (item) {
        var control = new FillControl();
        if (!item) {
            item = new Fill();
            item.generateId();
        }
        control.deserialize(item);
        control.markAsPristine();
        return control;
    };
    FillArray.prototype.appendControl = function (item) {
        var control = this.createControl(item);
        this.push(control);
        return control;
    };
    FillArray.prototype.prependControl = function (item) {
        var control = this.createControl(item);
        this.insert(0, control);
        return control;
    };
    return FillArray;
}(FormArray));
export { FillArray };

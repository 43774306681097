import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-tip',
  templateUrl: './preview-tip.component.html'
})
export class PreviewTipComponent implements OnInit {
  @Input() url: string;
  @Input() width: number;
  @Input() height: number;

  ngOnInit() {}
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { CustomizeService } from '@modules/customize';
var CustomizeHandlerHeaderLeftDirective = /** @class */ (function () {
    function CustomizeHandlerHeaderLeftDirective(tpl, customizeService) {
        this.tpl = tpl;
        this.customizeService = customizeService;
    }
    CustomizeHandlerHeaderLeftDirective.prototype.ngOnInit = function () { };
    CustomizeHandlerHeaderLeftDirective.prototype.ngOnDestroy = function () {
        if (this.customizeService.handlerHeaderLeft === this.tpl) {
            this.customizeService.handlerHeaderLeft = undefined;
        }
    };
    CustomizeHandlerHeaderLeftDirective.prototype.ngOnChanges = function (changes) {
        if (changes.options) {
            this.updateTemplate();
        }
    };
    CustomizeHandlerHeaderLeftDirective.prototype.getOptions = function () {
        return __assign({ enabled: true }, this.options);
    };
    CustomizeHandlerHeaderLeftDirective.prototype.updateTemplate = function () {
        var options = this.getOptions();
        this.customizeService.handlerHeaderLeft = options.enabled ? this.tpl : undefined;
    };
    return CustomizeHandlerHeaderLeftDirective;
}());
export { CustomizeHandlerHeaderLeftDirective };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { TaskListStore, TaskPriority, TaskQueueStore } from '@modules/collaboration';
import { TaskPopupComponent, TaskQueueEditController } from '@modules/collaboration-components';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserSelectSource } from '@modules/projects';
import { getWindowScrollTop, isSet } from '@shared';
var CollaborationTasksComponent = /** @class */ (function () {
    function CollaborationTasksComponent(taskQueueEditController, popupService, router, activatedRoute, taskListStore, currentProjectStore, currentEnvironmentStore, projectUserSelectSource, customizeService, taskQueueStore, injector, cd) {
        this.taskQueueEditController = taskQueueEditController;
        this.popupService = popupService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.taskListStore = taskListStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectUserSelectSource = projectUserSelectSource;
        this.customizeService = customizeService;
        this.taskQueueStore = taskQueueStore;
        this.injector = injector;
        this.cd = cd;
        this.baseParams = {
            order_by: '-date_add'
        };
        this.form = new FormGroup({
            assigned: new FormControl(null),
            priority: new FormControl(null),
            date_add_gte: new FormControl(),
            date_add_lte: new FormControl(),
            status: new FormControl(null),
            queue: new FormControl(null)
        });
        this.formatDate = 'DD.MM.YYYY';
        this.priorityOptions = [
            { name: 'All Priority', value: null },
            { name: 'Lowest', value: TaskPriority.Lowest },
            { name: 'Low', value: TaskPriority.Low },
            { name: 'Medium', value: TaskPriority.Medium },
            { name: 'High', value: TaskPriority.High },
            { name: 'Highest', value: TaskPriority.Highest }
        ];
        this.selectedItemIndex = undefined;
    }
    CollaborationTasksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tasks$ = this.taskListStore.items$;
        this.tasksLoading$ = this.taskListStore.loading$;
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.projectUserSelectSource.allowEmpty = true;
        this.projectUserSelectSource.emptyName = 'All';
        this.projectUserSelectSource.userValue = true;
        this.getTasks();
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(function (params) {
            var formValue = _this.form.value;
            var patchValue = {};
            ['date_add_gte', 'date_add_lte', 'priority', 'assigned', 'queue', 'status'].forEach(function (name) {
                if (params[name]) {
                    patchValue[name] = params[name];
                }
                else {
                    patchValue[name] = null;
                }
            });
            if (!isEqual(formValue, patchValue)) {
                _this.form.patchValue(__assign({}, patchValue));
            }
            _this.getTasks(params);
        });
        this.activatedRoute.queryParams
            .pipe(switchMap(function (params) { return _this.taskQueueStore.getDetail(params['queue']); }), untilDestroyed(this))
            .subscribe(function (queue) {
            _this.currentQueue = queue;
            _this.cd.markForCheck();
        });
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            var params = {};
            ['date_add_gte', 'date_add_lte', 'priority', 'assigned', 'queue', 'status'].forEach(function (name) {
                if (!isSet(value[name])) {
                    if (isSet(params[name])) {
                        delete params[name];
                    }
                }
                else if (name === 'date_add_gte') {
                    params[name] = moment(value[name]).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
                else if (name === 'date_add_lte') {
                    params[name] = moment(value[name]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
                else {
                    params[name] = value[name];
                }
            });
            _this.router.navigate([], {
                relativeTo: _this.activatedRoute,
                queryParams: params
            });
        });
        fromEvent(window, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var scrollTop = getWindowScrollTop();
            var viewportHeight = window.innerHeight;
            var viewportBottom = scrollTop + viewportHeight;
            var contentHeight = document.body.offsetHeight;
            if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
                _this.onScrollFinished();
            }
        });
    };
    CollaborationTasksComponent.prototype.ngOnDestroy = function () { };
    CollaborationTasksComponent.prototype.onScrollFinished = function () {
        this.taskListStore.getNext();
    };
    CollaborationTasksComponent.prototype.getTasks = function (params) {
        if (params === void 0) { params = {}; }
        this.taskListStore.projectName = this.currentProjectStore.instance.uniqueName;
        this.taskListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
        this.taskListStore.params = __assign({}, this.baseParams, params);
        this.taskListStore.reset();
        this.taskListStore.getNext();
    };
    CollaborationTasksComponent.prototype.activeToggle = function (item, index) {
        if (this.closeTaskPopup) {
            this.closeTaskPopup.next();
            this.closeTaskPopup = undefined;
        }
        if (index === this.selectedItemIndex) {
            this.selectedItemIndex = undefined;
            this.cd.markForCheck();
        }
        else {
            this.createPopup(item, index);
            this.selectedItemIndex = index;
            this.cd.markForCheck();
        }
    };
    CollaborationTasksComponent.prototype.updateItem = function (index, item) {
        this.taskListStore.items = this.taskListStore.items.map(function (original, i) {
            if (i == index) {
                return item;
            }
            else {
                return original;
            }
        });
        this.cd.markForCheck();
    };
    CollaborationTasksComponent.prototype.createPopup = function (item, index) {
        var _this = this;
        var closeTaskPopup = new Subject();
        this.taskPopup = this.popupService.push({
            component: TaskPopupComponent,
            disablePointerEvents: true,
            enableWindowScroll: true,
            inputs: {
                itemUid: item.uid,
                closeObs: closeTaskPopup
            },
            outputs: {
                updated: [
                    function (result) {
                        _this.updateItem(index, result);
                    }
                ],
                closeObsEvent: [
                    function () {
                        _this.selectedItemIndex = undefined;
                        if (_this.closeTaskPopup === closeTaskPopup) {
                            _this.closeTaskPopup = undefined;
                        }
                        _this.cd.markForCheck();
                    }
                ]
            },
            injector: this.injector
        });
        this.closeTaskPopup = closeTaskPopup;
    };
    CollaborationTasksComponent.prototype.editQueue = function (queue) {
        var _this = this;
        this.taskQueueEditController
            .editTaskQueue(queue, { analyticsSource: 'collaboration_task_queue' })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.deleted) {
                _this.router.navigate([], {
                    relativeTo: _this.activatedRoute,
                    queryParams: {}
                });
            }
        });
    };
    return CollaborationTasksComponent;
}());
export { CollaborationTasksComponent };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPreviewComponent implements OnChanges {
  @Input() preview: string;

  previewFile: string;
  previewEmbed: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onPreviewUpdate(this.preview);
  }

  onPreviewUpdate(preview: string) {
    const youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(
      preview
    );
    const vimeoMatch = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(
      preview
    );
    let previewFile: string;
    let previewEmbed: string;

    if (youtubeMatch) {
      previewEmbed = `https://www.youtube.com/embed/${youtubeMatch[5]}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
    } else if (vimeoMatch) {
      previewEmbed = `https://player.vimeo.com/video/${vimeoMatch[4]}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    } else {
      previewFile = preview;
    }

    this.previewFile = previewFile;
    this.previewEmbed = previewEmbed ? this.sanitizer.bypassSecurityTrustResourceUrl(previewEmbed) : undefined;
    this.cd.markForCheck();
  }
}

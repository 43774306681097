/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./set-token.component";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../routing/services/routing/routing.service";
import * as i4 from "../../../projects/stores/projects.store";
import * as i5 from "../../../users/stores/current-user.store";
import * as i6 from "@angular/router";
var styles_SetTokenComponent = [];
var RenderType_SetTokenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SetTokenComponent, data: {} });
export { RenderType_SetTokenComponent as RenderType_SetTokenComponent };
export function View_SetTokenComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["Loading...\n"]))], null, null); }
export function View_SetTokenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-set-token", [], null, null, null, View_SetTokenComponent_0, RenderType_SetTokenComponent)), i0.ɵdid(1, 245760, null, 0, i1.SetTokenComponent, [i2.ApiService, i3.RoutingService, i4.ProjectsStore, i5.CurrentUserStore, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SetTokenComponentNgFactory = i0.ɵccf("app-set-token", i1.SetTokenComponent, View_SetTokenComponent_Host_0, {}, {}, []);
export { SetTokenComponentNgFactory as SetTokenComponentNgFactory };

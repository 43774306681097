var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import round from 'lodash/round';
import { Frame, FrameTranslate } from '@modules/views';
import { isSet } from '@shared';
import { ViewEditorGuideColor, ViewEditorGuideSymbol } from '../services/view-editor-context/view-editor.context';
export function snapFrame(options) {
    options.horizontalOriginSnaps = options.horizontalOriginSnaps || [0, 0.5, 1];
    options.verticalOriginSnaps = options.verticalOriginSnaps || [0, 0.5, 1];
    options.snapDistance = isSet(options.snapDistance) ? options.snapDistance : 2;
    var updateFrame = {};
    var snaps = {
        horizontal: [],
        vertical: []
    };
    options.others.slice().reverse().forEach(function (frame) {
        [frame.x, frame.x + frame.frame.width * 0.5, frame.x + frame.frame.width].forEach(function (x) {
            options.horizontalOriginSnaps
                .map(function (item) { return options.base.frame.width * item; })
                .forEach(function (originX) {
                var distanceValue = Math.abs(options.base.x + originX - x);
                if (distanceValue <= options.snapDistance) {
                    var distance = {
                        frameX: x,
                        originX: originX,
                        value: distanceValue
                    };
                    snaps.horizontal.push({
                        snapY: frame.y,
                        snapHeight: frame.frame.height,
                        distance: distance
                    });
                    if (!snaps.horizontalClosest || snaps.horizontalClosest.value > distanceValue) {
                        snaps.horizontalClosest = distance;
                    }
                }
            });
        });
        [frame.y, frame.y + frame.frame.height * 0.5, frame.y + frame.frame.height].forEach(function (y) {
            options.verticalOriginSnaps
                .map(function (item) { return options.base.frame.height * item; })
                .forEach(function (originY) {
                var distanceValue = Math.abs(options.base.y + originY - y);
                if (distanceValue <= options.snapDistance) {
                    var distance = {
                        frameY: y,
                        originY: originY,
                        value: distanceValue
                    };
                    snaps.vertical.push({
                        snapX: frame.x,
                        snapWidth: frame.frame.width,
                        distance: distance
                    });
                    if (!snaps.verticalClosest || snaps.verticalClosest.value > distanceValue) {
                        snaps.verticalClosest = distance;
                    }
                }
            });
        });
    });
    var horizontalGuides = [];
    var verticalGuides = [];
    if (snaps.horizontalClosest) {
        if (options.horizontalSnapX && !options.horizontalSnapWidth) {
            updateFrame.x = snaps.horizontalClosest.frameX - snaps.horizontalClosest.originX;
        }
        else if (options.horizontalSnapX && options.horizontalSnapWidth) {
            var right = options.base.x + options.base.frame.width;
            updateFrame.x = snaps.horizontalClosest.frameX - snaps.horizontalClosest.originX;
            updateFrame.width = right - updateFrame.x;
        }
        else if (options.horizontalSnapWidth) {
            updateFrame.width = snaps.horizontalClosest.frameX - options.base.x;
        }
        var snapsClosest = snaps.horizontal.filter(function (item) {
            return (item.distance.frameX == snaps.horizontalClosest.frameX &&
                item.distance.originX == snaps.horizontalClosest.originX);
        });
        var updatedFrame = __assign({}, options.base.frame, { x: options.base.x, y: options.base.y }, updateFrame);
        var minY = Math.min.apply(Math, snapsClosest.map(function (item) { return item.snapY; }).concat([updatedFrame.y]));
        var maxY = Math.max.apply(Math, snapsClosest.map(function (item) { return item.snapY + item.snapHeight; }).concat([updatedFrame.y + updatedFrame.height]));
        var height = maxY - minY;
        verticalGuides.push({
            x: snaps.horizontalClosest.frameX,
            y: minY,
            length: height
        });
    }
    if (snaps.verticalClosest) {
        if (options.verticalSnapY && !options.verticalSnapHeight) {
            updateFrame.y = snaps.verticalClosest.frameY - snaps.verticalClosest.originY;
        }
        else if (options.verticalSnapY && options.verticalSnapHeight) {
            var bottom = options.base.y + options.base.frame.height;
            updateFrame.y = snaps.verticalClosest.frameY - snaps.verticalClosest.originY;
            updateFrame.height = bottom - updateFrame.y;
        }
        else if (options.verticalSnapHeight) {
            updateFrame.height = snaps.verticalClosest.frameY - options.base.y;
        }
        var snapsClosest = snaps.vertical.filter(function (item) {
            return (item.distance.frameY == snaps.verticalClosest.frameY && item.distance.originY == snaps.verticalClosest.originY);
        });
        var updatedFrame = __assign({}, options.base.frame, { x: options.base.x, y: options.base.y }, updateFrame);
        var minX = Math.min.apply(Math, snapsClosest.map(function (item) { return item.snapX; }).concat([updatedFrame.x]));
        var maxX = Math.max.apply(Math, snapsClosest.map(function (item) { return item.snapX + item.snapWidth; }).concat([updatedFrame.x + updatedFrame.width]));
        var width = maxX - minX;
        horizontalGuides.push({
            x: minX,
            y: snaps.verticalClosest.frameY,
            length: width
        });
    }
    if (isSet(updateFrame.x) && options.base.translate.x) {
        updateFrame.x -= options.base.translate.x;
    }
    if (isSet(updateFrame.y) && options.base.translate.y) {
        updateFrame.y -= options.base.translate.y;
    }
    return {
        updateFrame: updateFrame,
        horizontalGuides: horizontalGuides,
        verticalGuides: verticalGuides
    };
}
export function snapPoint(options) {
    var frame = new Frame({ x: options.point.point.x, y: options.point.point.y, width: 0, height: 0 });
    var snap = snapFrame({
        base: new FrameTranslate({
            frame: frame,
            translate: options.point.translate
        }),
        others: options.otherFrames,
        horizontalSnapX: true,
        horizontalOriginSnaps: [0],
        verticalSnapY: true,
        verticalOriginSnaps: [0],
        snapDistance: options.snapDistance
    });
    return {
        updatePoint: {
            x: snap.updateFrame.x,
            y: snap.updateFrame.y
        },
        horizontalGuides: snap.horizontalGuides,
        verticalGuides: snap.verticalGuides
    };
}
export function measureFrames(frame, hoverFrame) {
    var horizontalGuides = [];
    var verticalGuides = [];
    var frameXRight = frame.x + frame.width;
    var frameXCenter = frame.x + frame.width * 0.5;
    var frameYCenter = frame.y + frame.height * 0.5;
    var frameYBottom = frame.y + frame.height;
    var hoverFrameXRight = hoverFrame.x + hoverFrame.width;
    var hoverFrameYBottom = hoverFrame.y + hoverFrame.height;
    var xGuides = [];
    var yGuides = [];
    if (frameXRight < hoverFrame.x) {
        var distanceRight = hoverFrame.x - frameXRight;
        xGuides.push({ x: frameXRight, width: distanceRight, leftToRight: true });
    }
    else if (frameXRight > hoverFrame.x && frame.x < hoverFrameXRight) {
        var distanceLeft = frame.x - hoverFrame.x;
        var distanceRight = hoverFrameXRight - frameXRight;
        if (distanceLeft > 0) {
            xGuides.push({ x: hoverFrame.x, width: distanceLeft, leftToRight: false });
        }
        else if (distanceLeft < 0) {
            xGuides.push({ x: frame.x, width: distanceLeft * -1, leftToRight: true });
        }
        if (distanceRight > 0) {
            xGuides.push({ x: frameXRight, width: distanceRight, leftToRight: true });
        }
        else if (distanceRight < 0) {
            xGuides.push({ x: hoverFrameXRight, width: distanceRight * -1, leftToRight: false });
        }
    }
    else if (frame.x > hoverFrameXRight) {
        var distanceLeft = frame.x - hoverFrameXRight;
        xGuides.push({ x: hoverFrameXRight, width: distanceLeft, leftToRight: false });
    }
    xGuides.forEach(function (guide) {
        horizontalGuides.push({
            x: guide.x,
            y: frameYCenter,
            length: guide.width,
            label: "" + round(guide.width, 2),
            color: ViewEditorGuideColor.Blue,
            startSymbol: ViewEditorGuideSymbol.Line,
            endSymbol: ViewEditorGuideSymbol.Line
        });
        if (frameYCenter < hoverFrame.y) {
            verticalGuides.push({
                x: guide.leftToRight ? guide.x + guide.width : guide.x,
                y: frame.y,
                length: hoverFrame.y - frame.y,
                dashed: true
            });
        }
        else if (frameYCenter > hoverFrameYBottom) {
            verticalGuides.push({
                x: guide.leftToRight ? guide.x + guide.width : guide.x,
                y: hoverFrameYBottom,
                length: frameYBottom - hoverFrameYBottom,
                dashed: true
            });
        }
    });
    if (frameYBottom < hoverFrame.y) {
        var distanceBottom = hoverFrame.y - frameYBottom;
        yGuides.push({ y: frameYBottom, height: distanceBottom, topToBottom: true });
    }
    else if (frameYBottom > hoverFrame.y && frame.y < hoverFrameYBottom) {
        var distanceTop = frame.y - hoverFrame.y;
        var distanceBottom = hoverFrameYBottom - frameYBottom;
        if (distanceTop > 0) {
            yGuides.push({ y: hoverFrame.y, height: distanceTop, topToBottom: false });
        }
        else if (distanceTop < 0) {
            yGuides.push({ y: frame.y, height: distanceTop * -1, topToBottom: true });
        }
        if (distanceBottom > 0) {
            yGuides.push({ y: frameYBottom, height: distanceBottom, topToBottom: true });
        }
        else if (distanceBottom < 0) {
            yGuides.push({ y: hoverFrameYBottom, height: distanceBottom * -1, topToBottom: false });
        }
    }
    else if (frame.y > hoverFrameYBottom) {
        var distanceBottom = frame.y - hoverFrameYBottom;
        yGuides.push({ y: hoverFrameYBottom, height: distanceBottom, topToBottom: false });
    }
    yGuides.forEach(function (guide) {
        verticalGuides.push({
            x: frameXCenter,
            y: guide.y,
            length: guide.height,
            label: "" + round(guide.height, 2),
            color: ViewEditorGuideColor.Blue,
            startSymbol: ViewEditorGuideSymbol.Line,
            endSymbol: ViewEditorGuideSymbol.Line
        });
        if (frameXCenter < hoverFrame.x) {
            horizontalGuides.push({
                x: frame.x,
                y: guide.topToBottom ? guide.y + guide.height : guide.y,
                length: hoverFrame.x - frame.x,
                dashed: true
            });
        }
        else if (frameXCenter > hoverFrameXRight) {
            horizontalGuides.push({
                x: hoverFrameXRight,
                y: guide.topToBottom ? guide.y + guide.height : guide.y,
                length: frameXRight - hoverFrameXRight,
                dashed: true
            });
        }
    });
    return {
        horizontalGuides: horizontalGuides,
        verticalGuides: verticalGuides
    };
}

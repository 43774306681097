/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i2 from "@angular/material/menu";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../shared/pipes/capitalize/capitalize.pipe";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./language-selector.component";
import * as i9 from "../../../core/services/local-storage/local.storage";
var styles_LanguageSelectorComponent = [];
var RenderType_LanguageSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LanguageSelectorComponent, data: {} });
export { RenderType_LanguageSelectorComponent as RenderType_LanguageSelectorComponent };
function View_LanguageSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[2, "mat-menu-item-active", null], [2, "mat-menu-item-odd", null], [1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.languageControl.patchValue(_v.context.$implicit.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[1, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "])), i0.ɵppd(3, 1)], function (_ck, _v) { var currVal_8 = true; _ck(_v, 1, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.value == _co.languageControl.value); var currVal_1 = true; var currVal_2 = i0.ɵnov(_v, 1).role; var currVal_3 = i0.ɵnov(_v, 1)._highlighted; var currVal_4 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_5 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_6 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_7 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.name)); _ck(_v, 2, 0, currVal_9); }); }
function View_LanguageSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LanguageSelectorComponent_2)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageOptions; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LanguageSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLanguageOption.name; _ck(_v, 1, 0, currVal_0); }); }
function View_LanguageSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose language"]))], null, null); }
export function View_LanguageSelectorComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "overlay-controls overlay-controls_position_right-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "mat-menu", [], null, null, null, i1.View_MatMenu_0, i1.RenderType_MatMenu)), i0.ɵprd(6144, null, i2.ɵf24, null, [i2.MatMenu]), i0.ɵdid(4, 1294336, [["dropdown", 4]], 2, i2.MatMenu, [i0.ElementRef, i0.NgZone, i2.MAT_MENU_DEFAULT_OPTIONS], null, null), i0.ɵqud(603979776, 1, { items: 1 }), i0.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LanguageSelectorComponent_1)), i0.ɵdid(8, 147456, [[2, 4]], 0, i2.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i3.DOCUMENT], null, null), (_l()(), i0.ɵeld(9, 16777216, null, null, 7, "div", [["aria-haspopup", "true"], ["class", "overlay-selector overlay-controls__item"]], [[2, "overlay-selector_active", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 10)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 10)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 1196032, [["trigger", 4]], 0, i2.MatMenuTrigger, [i6.Overlay, i0.ElementRef, i0.ViewContainerRef, i2.MAT_MENU_SCROLL_STRATEGY, [2, i2.MatMenu], [8, null], [2, i7.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "overlay-selector__label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LanguageSelectorComponent_3)), i0.ɵdid(13, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LanguageSelectorComponent_4)), i0.ɵdid(15, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 0, "div", [["class", "overlay-selector__arrow icon-arrow_down_2"]], [[2, "icon-arrow_up_2", null], [2, "icon-arrow_down_2", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_2 = i0.ɵnov(_v, 4); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.currentLanguageOption; _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.currentLanguageOption; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 10).menuOpen; var currVal_1 = (i0.ɵnov(_v, 10).menuOpen || null); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_5 = i0.ɵnov(_v, 10).menuOpen; var currVal_6 = !i0.ɵnov(_v, 10).menuOpen; _ck(_v, 16, 0, currVal_5, currVal_6); }); }
export function View_LanguageSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-language-selector", [], null, null, null, View_LanguageSelectorComponent_0, RenderType_LanguageSelectorComponent)), i0.ɵdid(1, 245760, null, 0, i8.LanguageSelectorComponent, [i9.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LanguageSelectorComponentNgFactory = i0.ɵccf("app-language-selector", i8.LanguageSelectorComponent, View_LanguageSelectorComponent_Host_0, {}, {}, []);
export { LanguageSelectorComponentNgFactory as LanguageSelectorComponentNgFactory };

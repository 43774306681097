import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ChartType, singleColorDatasetChartTypes, ValueWidget } from '@modules/dashboard';
import { BooleanFieldStyle } from '@modules/field-components';
import { ModelDescription } from '@modules/models';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ChartWidgetDataSourceControl } from '../../model-description-data-source-edit/chart-widget-data-source';
import { ValueWidgetDataSourceControl } from '../../model-description-data-source-edit/value-widget-data-source';
import { CustomizeBarBaseWidgetEditComponent } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.component';
import { ChartWidgetEditFormDatasetControl } from '../customize-bar-chart-widget-edit/chart-widget-edit-dataset.control';
import { CustomizeBarChartWidgetEditDatasetComponent } from '../customize-bar-chart-widget-edit/customize-bar-chart-widget-edit-dataset/customize-bar-chart-widget-edit-dataset.component';
import { CustomizeBarCompareValueWidgetEditDataComponent } from './customize-bar-compare-value-widget-edit-data/customize-bar-compare-value-widget-edit-data.component';
import { CustomizeBarValueWidgetEditDataComponent } from './customize-bar-value-widget-edit-data/customize-bar-value-widget-edit-data.component';
import {
  CustomizeBarValueWidgetEditForm,
  VALUE_WIDGET_CHART_DATASET,
  VALUE_WIDGET_COMPARE_DATA_SOURCE,
  VALUE_WIDGET_DATA_SOURCE
} from './customize-bar-value-widget-edit.form';

@Component({
  selector: 'app-customize-bar-value-widget-edit',
  templateUrl: './customize-bar-value-widget-edit.component.html',
  providers: [
    { provide: VALUE_WIDGET_DATA_SOURCE, useClass: ValueWidgetDataSourceControl },
    { provide: VALUE_WIDGET_COMPARE_DATA_SOURCE, useClass: ValueWidgetDataSourceControl },
    ChartWidgetDataSourceControl,
    {
      provide: VALUE_WIDGET_CHART_DATASET,
      useClass: ChartWidgetEditFormDatasetControl,
      deps: [ChartWidgetDataSourceControl]
    },
    CustomizeBarValueWidgetEditForm
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarValueWidgetEditComponent extends CustomizeBarBaseWidgetEditComponent<ValueWidget>
  implements OnInit, OnDestroy {
  modelDescription: ModelDescription;
  collapseContext = new SidebarCollapseContext();
  dataConfigured = true;
  actionsValid$: Observable<boolean>;
  booleanFieldStyle = BooleanFieldStyle;

  constructor(
    private cd: ChangeDetectorRef,
    public form: CustomizeBarValueWidgetEditForm,
    customizeBarContext: CustomizeBarContext,
    analyticsService: UniversalAnalyticsService
  ) {
    super(form, customizeBarContext, analyticsService);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest(this.form.controls.data_source.getResource$(), this.form.controls.data_source.getModelDescription$())
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        [this.resource, this.modelDescription] = result;
        this.cd.markForCheck();
      });

    controlValid(this.form.controls.data_source)
      .pipe(
        map((configured, i) => {
          if (configured && i > 0) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
              ComponentTypeID: this.widget.analyticsName
            });
          }

          return configured;
        }),
        untilDestroyed(this)
      )
      .subscribe(configured => {
        this.dataConfigured = configured;
        this.cd.markForCheck();
      });

    this.actionsValid$ = this.form.actionsValid$();

    controlValid(this.form.controls.data_source.controls.query_resource)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
          ComponentTypeID: this.widget.analyticsName
        });
      });

    controlValid(this.form.controls.data_source.controls.query_inputs)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
          ComponentTypeID: this.widget.analyticsName
        });
      });

    if (this.setupOnCreate) {
      this.editValueDataSource();
    }
  }

  editValueDataSource(options: { addInput?: boolean } = {}) {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarValueWidgetEditDataComponent,
      inputs: {
        widget: this.widget,
        control: this.form.controls.data_source,
        chartForm: this.form,
        context: this.context,
        contextElement: this.contextElement,
        addInput: options.addInput
      },
      outputs: {
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  editCompareValueDataSource(options: { addInput?: boolean } = {}) {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarCompareValueWidgetEditDataComponent,
      inputs: {
        widget: this.widget,
        control: this.form.controls.compare_data_source,
        nameControl: this.form.controls.compare_name,
        growthNegativeControl: this.form.controls.compare_growth_negative,
        chartForm: this.form,
        context: this.context,
        contextElement: this.contextElement,
        addInput: options.addInput
      },
      outputs: {
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  isSingleColorDatasetChart(): boolean {
    return singleColorDatasetChartTypes.includes(ChartType.Line);
  }

  editChartDataset(control: ChartWidgetEditFormDatasetControl, options: { addInput?: boolean } = {}) {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarChartWidgetEditDatasetComponent,
      inputs: {
        widget: this.widget,
        chartType: ChartType.Line,
        form: control,
        defaultTitle: `Value Chart`,
        context: this.context,
        colorEnabled: true,
        labelEnabled: false,
        singleColorDataset: this.isSingleColorDatasetChart(),
        firstInit: this.firstInit,
        setupOnCreate: this.setupOnCreate,
        addInput: options.addInput
      },
      outputs: {
        delete: [e => {}]
      }
    });
  }
}

<td class="table__column table__column_left-padding"></td>

<td class="table__column">
  <div class="table__column-inner">
    <a [appLink]="item.link">
      <div class="table-user table-user_photo">
        <span
          class="table-user__photo user-photo"
          [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
        >
        </span>
        <ng-container *ngIf="item.user">
          <div class="table-user__title">
            {{ item.user.strFull }}
            <span *ngIf="(currentUserStore.instance$ | async)?.uid == item.user.uid" class="table-user__title-comment">
              ({{ 'You' | localize }})
            </span>
          </div>
          <div class="table-user__subtitle">{{ item.user.email }}</div>
        </ng-container>
        <ng-container *ngIf="!item.user">
          <div class="table-user__title">
            {{ item.userEmail }} <span class="table-user__title-comment">({{ 'invited' | localize }})</span>
          </div>
        </ng-container>
      </div>
    </a>
  </div>
</td>

<td *ngIf="group" class="table__column">
  <app-project-group-control [control]="selectedGroup" [small]="true"></app-project-group-control>
</td>

<td class="table__column">
  <div class="table__column-inner">{{ item.dateAdd.format('LLL') }}</div>
</td>

<td class="table__column table__column_right">
  <a class="button button_icon" [appLink]="item.link" [appTip]="'Edit' | localize" [appTipOptions]="{ side: 'top' }">
    <span class="icon icon-pen button__icon"></span>
  </a>

  <button
    (click)="delete(item)"
    [disabled]="deleteLoading"
    [appTip]="'Delete' | localize"
    [appTipOptions]="{ side: 'top' }"
    class="button button_icon button_bg-transparent"
    [class.button_disabled]="item.user?.uid == currentUserStore.instance.uid"
  >
    <app-loader-small *ngIf="deleteLoading" class="button__icon"> </app-loader-small>
    <span *ngIf="!deleteLoading" class="icon icon-bin button__icon"></span>
  </button>
</td>

<td class="table__column"></td>

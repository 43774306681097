import { FormControl, FormGroup } from '@angular/forms';

import {
  ActionLayerInteraction,
  createLayerInteraction,
  CursorLayerInteraction,
  LayerInteraction,
  LayerInteractionType
} from '@modules/views';

export class LayerInteractionControl extends FormGroup {
  instance: LayerInteraction;

  controls: {
    action: FormControl;
    cursor: FormControl;
  };

  constructor(
    public readonly type: LayerInteractionType,
    state: Partial<LayerInteraction & ActionLayerInteraction & CursorLayerInteraction> = {}
  ) {
    super({
      action: new FormControl(state.action),
      cursor: new FormControl(state.cursor)
    });
  }

  deserialize(value: LayerInteraction, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    if (value instanceof ActionLayerInteraction) {
      this.controls.action.patchValue(value.action, { emitEvent: options.emitEvent });
    } else if (value instanceof CursorLayerInteraction) {
      this.controls.cursor.patchValue(value.cursor, { emitEvent: options.emitEvent });
    }
  }

  getInstance(instance?: LayerInteraction): LayerInteraction {
    if (!instance) {
      instance = createLayerInteraction(this.type);
    }

    if (instance instanceof ActionLayerInteraction) {
      instance.action = this.controls.action.value;
    } else if (instance instanceof CursorLayerInteraction) {
      instance.cursor = this.controls.cursor.value;
    }

    return instance;
  }

  serialize(): LayerInteraction {
    return this.getInstance(this.instance);
  }
}

export enum StorageObjectType {
  Folder = 'folder',
  File = 'file'
}

export class StorageObject {
  public path: string;
  public url: string;
  public type: StorageObjectType;
  public size: number;
  public created: string;
  public updated: string;

  constructor(options: Partial<StorageObject> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): StorageObject {
    if (!data) {
      return;
    }
    this.path = data['path'];
    this.url = data['url'];
    this.type = data['type'];
    this.size = data['size'];
    this.created = data['created'];
    this.updated = data['updated'];

    return this;
  }

  serialize(): Object {
    return {
      path: this.path,
      url: this.url,
      type: this.type,
      size: this.size,
      created: this.created,
      updated: this.updated
    };
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playground.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../playground-item/playground-item.component.ngfactory";
import * as i3 from "../../directives/drag/drag.token";
import * as i4 from "../../directives/drag/drag.directive";
import * as i5 from "../playground-item/playground-item.component";
import * as i6 from "../../directives/drag-placeholder/drag-placeholder.directive";
import * as i7 from "../../directives/drop-list-group/drop-list-group.directive";
import * as i8 from "../../directives/drop-list/drop-list.directive";
import * as i9 from "../../services/drop-list/drop-list.service";
import * as i10 from "../../services/scroll/scroll.service";
import * as i11 from "@angular/common";
import * as i12 from "./playground.component";
var styles_PlaygroundComponent = [i0.styles];
var RenderType_PlaygroundComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlaygroundComponent, data: {} });
export { RenderType_PlaygroundComponent as RenderType_PlaygroundComponent };
function View_PlaygroundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "app-playground-item", [["appDrag", ""]], null, [[null, "dropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PlaygroundItemComponent_0, i2.RenderType_PlaygroundItemComponent)), i1.ɵprd(6144, null, i3.APP_DRAG, null, [i4.AppDrag]), i1.ɵdid(2, 212992, [[1, 4]], 2, i4.AppDrag, [i1.ElementRef, i1.ViewContainerRef], { data: [0, "data"] }, null), i1.ɵqud(335544320, 2, { placeholderTemplate: 0 }), i1.ɵqud(335544320, 3, { previewTemplate: 0 }), i1.ɵdid(5, 114688, null, 0, i5.PlaygroundItemComponent, [], { item: [0, "item"], children: [1, "children"] }, { dropped: "dropped" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = true; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_PlaygroundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "app-playground-item", [["appDrag", ""]], null, [[null, "dropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PlaygroundItemComponent_0, i2.RenderType_PlaygroundItemComponent)), i1.ɵprd(6144, null, i3.APP_DRAG, null, [i4.AppDrag]), i1.ɵdid(2, 212992, [[4, 4]], 2, i4.AppDrag, [i1.ElementRef, i1.ViewContainerRef], { data: [0, "data"] }, null), i1.ɵqud(335544320, 5, { placeholderTemplate: 0 }), i1.ɵqud(335544320, 6, { previewTemplate: 0 }), i1.ɵdid(5, 114688, null, 0, i5.PlaygroundItemComponent, [], { item: [0, "item"], handle: [1, "handle"], children: [2, "children"] }, { dropped: "dropped" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = true; var currVal_3 = true; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PlaygroundComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "example-custom-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Custom Placeholder"]))], null, null); }
function View_PlaygroundComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "app-playground-item", [["appDrag", ""]], null, [[null, "dropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PlaygroundItemComponent_0, i2.RenderType_PlaygroundItemComponent)), i1.ɵprd(6144, null, i3.APP_DRAG, null, [i4.AppDrag]), i1.ɵdid(2, 212992, [[7, 4]], 2, i4.AppDrag, [i1.ElementRef, i1.ViewContainerRef], { data: [0, "data"] }, null), i1.ɵqud(335544320, 8, { placeholderTemplate: 0 }), i1.ɵqud(335544320, 9, { previewTemplate: 0 }), i1.ɵdid(5, 114688, null, 0, i5.PlaygroundItemComponent, [], { item: [0, "item"], children: [1, "children"] }, { dropped: "dropped" }), (_l()(), i1.ɵand(0, null, null, 1, null, View_PlaygroundComponent_4)), i1.ɵdid(7, 16384, [[8, 4]], 0, i6.AppDragPlaceholder, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = true; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_PlaygroundComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["appDropListGroup", ""], ["class", "grid"]], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i7.AppDropListGroup, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "grid__columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "grid__columns-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "grid__cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["appDropList", ""], ["class", "container"], ["id", "list1"]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 4931584, null, 1, i8.AppDropList, [i1.ElementRef, [2, i7.AppDropListGroup], i1.ChangeDetectorRef, i9.DropListService, i10.ScrollService], { connectedTo: [0, "connectedTo"], data: [1, "data"] }, { dropped: "appDropListDropped" }), i1.ɵqud(603979776, 1, { draggables: 1 }), i1.ɵpad(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundComponent_1)), i1.ɵdid(10, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "grid__columns-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "grid__cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["appDropList", ""], ["class", "container"], ["id", "list2"]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 4931584, [["list2_cmp", 4]], 1, i8.AppDropList, [i1.ElementRef, [2, i7.AppDropListGroup], i1.ChangeDetectorRef, i9.DropListService, i10.ScrollService], { connectedTo: [0, "connectedTo"], data: [1, "data"] }, { dropped: "appDropListDropped" }), i1.ɵqud(603979776, 4, { draggables: 1 }), i1.ɵpad(16, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundComponent_2)), i1.ɵdid(18, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "grid__cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "div", [["appDropList", ""], ["class", "container"], ["id", "list3"]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 4931584, null, 1, i8.AppDropList, [i1.ElementRef, [2, i7.AppDropListGroup], i1.ChangeDetectorRef, i9.DropListService, i10.ScrollService], { connectedTo: [0, "connectedTo"], data: [1, "data"], enterPredicate: [2, "enterPredicate"], sortingDisabled: [3, "sortingDisabled"], cloneItems: [4, "cloneItems"] }, { dropped: "appDropListDropped" }), i1.ɵqud(603979776, 7, { draggables: 1 }), i1.ɵpad(23, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundComponent_3)), i1.ɵdid(25, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, i1.ɵnov(_v, 14)); var currVal_1 = _co.list1; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.list1; _ck(_v, 10, 0, currVal_2); var currVal_3 = _ck(_v, 16, 0, "list1"); var currVal_4 = _co.list2; _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = _co.list2; _ck(_v, 18, 0, currVal_5); var currVal_6 = _ck(_v, 23, 0, "list1", i1.ɵnov(_v, 14)); var currVal_7 = _co.list3; var currVal_8 = _co.isDroppable; var currVal_9 = true; var currVal_10 = true; _ck(_v, 21, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.list3; _ck(_v, 25, 0, currVal_11); }, null); }
export function View_PlaygroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playground", [], null, null, null, View_PlaygroundComponent_0, RenderType_PlaygroundComponent)), i1.ɵdid(1, 245760, null, 0, i12.PlaygroundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaygroundComponentNgFactory = i1.ɵccf("app-playground", i12.PlaygroundComponent, View_PlaygroundComponent_Host_0, {}, {}, []);
export { PlaygroundComponentNgFactory as PlaygroundComponentNgFactory };

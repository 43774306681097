/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./list-related-field.component";
import * as i3 from "../../../model-queries/stores/model-description.store";
import * as i4 from "../../../../common/popover/services/popover/popover.service";
import * as i5 from "../../../../common/popover/components/popover/popover.component";
var styles_ListRelatedFieldComponent = [];
var RenderType_ListRelatedFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListRelatedFieldComponent, data: {} });
export { RenderType_ListRelatedFieldComponent as RenderType_ListRelatedFieldComponent };
function View_ListRelatedFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "table-editor__item table-editor__item_add"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFlexField(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "table-editor__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.verboseName; _ck(_v, 2, 0, currVal_0); }); }
function View_ListRelatedFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["list_item", 1]], null, 2, "a", [["class", "table-editor__item table-editor__item_link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showRelation(_v.context.$implicit, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "table-editor__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.verboseName; _ck(_v, 2, 0, currVal_0); }); }
function View_ListRelatedFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "table-editor__list-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "table-editor__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "icon icon-link"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Related "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListRelatedFieldComponent_3)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.relatedFields; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ListRelatedFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "table-editor table-editor_simple"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "table-editor__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "table-editor__list-inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListRelatedFieldComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListRelatedFieldComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modelDescription.dbFields; var currVal_1 = _co.trackByFn; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.relatedFields.length; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ListRelatedFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-related-field", [], null, null, null, View_ListRelatedFieldComponent_0, RenderType_ListRelatedFieldComponent)), i0.ɵdid(1, 245760, null, 0, i2.ListRelatedFieldComponent, [i3.ModelDescriptionStore, i4.PopoverService, i5.PopoverComponent, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListRelatedFieldComponentNgFactory = i0.ɵccf("app-list-related-field", i2.ListRelatedFieldComponent, View_ListRelatedFieldComponent_Host_0, { path: "path" }, { selected: "selected" }, []);
export { ListRelatedFieldComponentNgFactory as ListRelatedFieldComponentNgFactory };

<div class="tip-position" #root>
  <div
    class="tip"
    [class.tip_animate_show]="visible"
    [class.tip_animate_hide]="!visible"
    [class.tip_hoverable]="options.hoverable"
    [class.tip_flexible]="options.flexible"
    [class.tip_opaque]="options.opaque"
    [ngClass]="['tip_style_' + options.style, 'tip_side_' + side]"
  >
    <span
      *ngIf="!template"
      class="tip__inner"
      [innerHTML]="content"
      [style.max-width]="options.maxWidth || null"
    ></span>

    <span *ngIf="template" class="tip__inner" [style.max-width]="options.maxWidth || null">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </span>
  </div>
</div>

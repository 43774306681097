<form [formGroup]="form" (ngSubmit)="submit()">
  <app-popup2 [size]="'m'">
    <app-popup2-header>
      <app-popup2-title>Edit {{ action?.verboseName }} action</app-popup2-title>
      <app-popup2-description>
        Update action name and description
      </app-popup2-description>
    </app-popup2-header>

    <app-page-block>
      <div class="field">
        <label class="field__label field__label_bright">Display name</label>
        <input class="input input_fill" type="text" formControlName="verbose_name" />
        <app-field-errors [form]="form" [fieldName]="'verbose_name'"></app-field-errors>
      </div>
    </app-page-block>

    <app-page-block>
      <div class="field">
        <label class="field__label field__label_bright">Description</label>
        <input class="input input_fill" type="text" formControlName="description" />
        <app-field-errors [form]="form" [fieldName]="'description'"></app-field-errors>
      </div>
    </app-page-block>

    <app-page-block>
      <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup2__footer-item" (click)="close()" [disabled]="submitLoading">
            Close
          </button>

          <button
            type="submit"
            class="button button_primary popup2__footer-item"
            [disabled]="submitLoading || !form.valid"
          >
            <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"> </app-loader-small>
            Save
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>

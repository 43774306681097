import { Optional, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ThemeContext } from '../../data/theme-context';
import { ThemeOptions } from '../../data/theme-options';

@Pipe({
  name: 'appThemeColorHexOption'
})
export class ThemeColorHexOptionPipe implements PipeTransform {
  constructor(@Optional() private themeContext: ThemeContext) {}

  transform(value: keyof ThemeOptions): Observable<any> {
    if (!this.themeContext) {
      return of(undefined);
    }

    return this.themeContext.getColorHex$(value);
  }
}

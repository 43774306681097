import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, of, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomizeService } from '@modules/customize';
import { FeatureService } from '@modules/features';
import { MenuCustomSection, MenuService } from '@modules/menu';
import { NavigationService } from '@modules/navigation';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
var CustomizeMenuComponent = /** @class */ (function () {
    function CustomizeMenuComponent(menuService, customizeService, navigationService, featureService, taskQueueStore, routingService, router, cd, currentProjectStore, analyticsService) {
        var _this = this;
        this.menuService = menuService;
        this.customizeService = customizeService;
        this.navigationService = navigationService;
        this.featureService = featureService;
        this.taskQueueStore = taskQueueStore;
        this.routingService = routingService;
        this.router = router;
        this.cd = cd;
        this.currentProjectStore = currentProjectStore;
        this.analyticsService = analyticsService;
        this.sectionMenuItemActive$ = new BehaviorSubject(undefined);
        this.sectionInteracting$ = new BehaviorSubject(undefined);
        this.menuItemHover$ = new BehaviorSubject(undefined);
        this.menuItemDropdownHover$ = new BehaviorSubject(false);
        this.dropdownSection$ = new BehaviorSubject(undefined);
        this.hasTaskQueues = false;
        this.menuCustomSection = MenuCustomSection;
        this.analyticsEvents = AnalyticsEvent;
        this.menuItems = [
            {
                icon: 'design',
                label: 'Pages',
                section: MenuCustomSection.Pages,
                dropdown: true
            },
            {
                icon: 'data',
                label: 'Data',
                section: MenuCustomSection.Data,
                dropdown: true
            },
            {
                icon: 'dashboard',
                label: 'App Settings',
                section: MenuCustomSection.Layout,
                onClick: function () {
                    _this.routingService.navigateApp(_this.currentProjectStore.instance.settingsLayoutLink('appearance'));
                },
                clickEventType: AnalyticsEvent.BuilderMenu.LayoutClicked
            },
            {
                icon: 'signin',
                label: 'Sign In & Sign Up',
                section: MenuCustomSection.SignUp,
                onClick: function () {
                    _this.routingService.navigateApp(_this.currentProjectStore.instance.settingsSignUpLink('appearance'));
                },
                clickEventType: AnalyticsEvent.BuilderMenu.SignUpClicked
            },
            {
                icon: 'workflow',
                label: 'Automations',
                section: MenuCustomSection.Automations,
                onClick: function () {
                    _this.routingService.navigateApp(_this.currentProjectStore.instance.automationsLink);
                },
                clickEventType: AnalyticsEvent.BuilderMenu.AutomationsClicked
            },
            {
                icon: 'teams',
                label: 'Collaboration',
                section: MenuCustomSection.Collaboration,
                dropdown: true,
                requireTaskQueues: true,
                onClick: function () { return _this.openCollaboration(); },
                clickEventType: AnalyticsEvent.BuilderMenu.CollaborationClicked
            }
        ];
    }
    CustomizeMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.taskQueueStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.hasTaskQueues = result && result.length > 0;
            _this.cd.markForCheck();
        });
        this.initMenuInteractions();
    };
    CustomizeMenuComponent.prototype.ngOnDestroy = function () { };
    CustomizeMenuComponent.prototype.onSectionMenuItemMouseEnter = function (item) {
        if (this.sectionInteracting$.value) {
            return;
        }
        this.sectionMenuItemActive$.next(item.section);
        this.menuItemHover$.next(item.section);
    };
    CustomizeMenuComponent.prototype.onSectionMenuItemMouseLeave = function (item) {
        if (this.sectionInteracting$.value) {
            return;
        }
        if (this.menuItemHover$.value == item.section) {
            this.menuItemHover$.next(undefined);
        }
    };
    CustomizeMenuComponent.prototype.initMenuInteractions = function () {
        var _this = this;
        combineLatest(this.sectionMenuItemActive$, this.sectionInteracting$, this.menuItemHover$.pipe(debounce(function (section) { return timer(section ? 0 : 100); })), this.menuItemDropdownHover$.pipe(debounce(function (hover) { return timer(hover ? 0 : 100); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var sectionMenuItemActive = _a[0], sectionInteracting = _a[1], menuItemHover = _a[2], menuItemDropdownHover = _a[3];
            var section = sectionInteracting || sectionMenuItemActive;
            var menuItem = section ? _this.menuItems.find(function (item) { return item.section == section; }) : undefined;
            if (menuItem && menuItem.dropdown && (menuItemHover || menuItemDropdownHover || sectionInteracting)) {
                _this.dropdownSection$.next(section);
            }
            else {
                _this.dropdownSection$.next(undefined);
            }
            _this.cd.markForCheck();
        });
        merge(of({}), this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.dropdownSection$.next(undefined);
            _this.cd.markForCheck();
        });
        combineLatest(this.dropdownSection$, this.getRouteSection$())
            .pipe(debounceTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var selectedSection = _a[0], routeSection = _a[1];
            if (selectedSection) {
                _this.currentSection = selectedSection;
            }
            else {
                _this.currentSection = routeSection;
            }
            _this.cd.markForCheck();
        });
    };
    CustomizeMenuComponent.prototype.getRouteSection$ = function () {
        return this.navigationService.routeSnapshot$.pipe(map(function (snapshot) {
            var getSection = function (item) {
                if (item.data.hasOwnProperty('section')) {
                    return item.data['section'];
                }
                else if (item.parent) {
                    return getSection(item.parent);
                }
            };
            return getSection(snapshot);
        }));
    };
    CustomizeMenuComponent.prototype.openCollaboration = function () {
        if (!this.currentProjectStore.instance.features.isTasksEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Collaborate on a project with <strong>Collaboration</strong>',
                description: "\n          Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly\n          through Jet and make sure everyone is up-to-date.\n        "
            });
            return;
        }
        this.customizeService.offMenu();
    };
    CustomizeMenuComponent.prototype.closeAll = function () {
        this.dropdownSection$.next(undefined);
        this.cd.markForCheck();
        this.customizeService.offMenu();
    };
    CustomizeMenuComponent.prototype.startInteracting = function (section) {
        this.sectionInteracting$.next(section);
    };
    CustomizeMenuComponent.prototype.finishInteracting = function (section) {
        if (this.sectionInteracting$.value === section) {
            this.sectionInteracting$.next(undefined);
        }
    };
    CustomizeMenuComponent.prototype.onItemClick = function (item) {
        if (this.sectionInteracting$.value) {
            return;
        }
        if (item.onClick) {
            item.onClick();
        }
        if (item.clickEventType) {
            this.analyticsService.sendSimpleEvent(item.clickEventType);
        }
    };
    return CustomizeMenuComponent;
}());
export { CustomizeMenuComponent };

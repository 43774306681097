import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DialogService } from '@common/dialogs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CalendarSettings } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarCalendarSettingsDataSourceComponent } from './customize-bar-calendar-settings-data-source/customize-bar-calendar-settings-data-source.component';
import { CustomizeBarCalendarSettingsForm } from './customize-bar-calendar-settings.form';

@Component({
  selector: 'app-customize-bar-calendar-settings',
  templateUrl: './customize-bar-calendar-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListModelDescriptionDataSourceControl, CustomizeBarCalendarSettingsForm]
})
export class CustomizeBarCalendarSettingsComponent extends CustomizeBarListLayoutSettingsComponent<CalendarSettings>
  implements OnInit, OnDestroy {
  itemContextElementPath = [ITEM_OUTPUT];
  selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
  dataSourceEditComponent = CustomizeBarCalendarSettingsDataSourceComponent;
  collapseContext = new SidebarCollapseContext();
  columnParameters$: Observable<ParameterField[]>;
  actionsRequiredFields = ['card_click_action', 'actions', 'model_actions'];
  customViewTemplateTypes = CustomViewTemplateType;

  constructor(
    public form: CustomizeBarCalendarSettingsForm,
    customizeBarContext: CustomizeBarContext,
    dialogService: DialogService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, customizeBarContext, dialogService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
  }
}

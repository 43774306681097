import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Domain } from '@modules/domain';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { CurrentProjectStore, Project } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';

import { CustomizeBarMenuEditComponent } from '../customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';

@Component({
  selector: 'app-menu-settings',
  templateUrl: './menu-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomizeBarContext, ProjectUpdateForm, MenuUpdateForm, ProjectSettingsUpdateForm]
})
export class MenuSettingsComponent implements OnInit, OnDestroy, CustomizeHandler {
  @ViewChild('root') root: ElementRef;
  @ViewChildren(DynamicComponent) dynamicComponents = new QueryList<DynamicComponent>();

  settingsComponents: DynamicComponentArguments[] = [];
  project: Project;
  projectHome: string;
  domain: Domain;
  loading = false;
  blockControlHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlPreviewHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlActive$ = new BehaviorSubject<MenuBlockControl>(undefined);

  constructor(
    public projectForm: ProjectUpdateForm,
    public menuForm: MenuUpdateForm,
    public settingsForm: ProjectSettingsUpdateForm,
    private menuService: MenuService,
    private customizeBarContext: CustomizeBarContext,
    private currentProjectStore: CurrentProjectStore,
    private projectSettingsStore: ProjectSettingsStore,
    private customizeService: CustomizeService,
    private menuSettingsStore: MenuSettingsStore,
    private appConfigService: AppConfigService,
    public metaService: MetaService,
    private analyticsService: UniversalAnalyticsService,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.None;
    this.loading = true;
    this.cd.markForCheck();

    this.customizeBarContext.setSettingsComponent({
      component: CustomizeBarMenuEditComponent,
      inputs: {
        menuForm: this.menuForm,
        settingsForm: this.settingsForm,
        blockControlPreviewHover$: this.blockControlPreviewHover$
      },
      outputs: {
        blockControlHover: [value => this.blockControlHover$.next(value)]
      }
    });

    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      inAppDisabled: true
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(value => {
      this.settingsComponents = value;
      this.cd.detectChanges();
      this.scrollToTop();

      const pagesEditComponent: CustomizeBarPagesEditComponent = this.dynamicComponents
        .map(item => item.currentComponent.instance)
        .find(component => component instanceof CustomizeBarPagesEditComponent);
      const blockControlActive = pagesEditComponent ? pagesEditComponent.blockControl : undefined;

      this.blockControlActive$.next(blockControlActive);
      this.cd.markForCheck();
    });

    combineLatest(
      this.currentProjectStore.getFirst(),
      this.projectSettingsStore.getAllSettingsFirst$(),
      this.menuSettingsStore.getFirst()
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([project, projectSettings, menuSettings]) => {
          const webBaseUrl =
            project && project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;

          this.project = project;
          this.domain = project ? project.domain : undefined;
          this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
          this.loading = false;
          this.cd.markForCheck();

          this.projectForm.init(project);
          this.menuForm.init(menuSettings);
          this.settingsForm.init(projectSettings);

          this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectForm.submit().subscribe(() => this.onSave());
          });

          this.menuForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.menuForm.submit().subscribe(() => this.onSave());
          });

          this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.settingsForm.submit().subscribe(() => this.onSave());
          });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const webBaseUrl =
          result && result.domain ? `https://${result.domain.actualDomain}` : this.appConfigService.webBaseUrl;

        this.project = result;
        this.domain = result ? result.domain : undefined;
        this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
        this.cd.markForCheck();

        this.projectForm.project = this.project;
      });
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
    this.customizeService.unsetHandler(this);
  }

  scrollToTop(animated = true) {
    if (!this.root) {
      return;
    }

    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  onBlockControlPreviewClick(block: MenuBlockControl) {
    const uid = block.instance ? block.instance.uid : undefined;

    if (isSet(uid)) {
      const link = this.currentProjectStore.instance.settingsMenuLink(uid);
      this.routing.navigateApp(link);
    }
  }

  onPreviewBackgroundClick() {
    const link = this.currentProjectStore.instance.settingsMenuLink();
    this.routing.navigateApp(link);
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'menu_settings'
    });
  }

  // TODO: Fix empty handler
  closePopup(uid?: string): void {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import { createFormFieldFactory, FieldType, NumberValueFormat } from '@modules/fields';

import { ValueFormatControl } from './value-format.control';

@Component({
  selector: 'app-value-format-edit',
  templateUrl: './value-format-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueFormatEditComponent implements OnInit, OnChanges {
  @Input() control: ValueFormatControl;
  @Input() field: FieldType;
  @Input() formatInputEnabled = false;
  @Input() prefixEnabled = false;
  @Input() postfixEnabled = false;
  @Input() numberFormatOptionsEnabled = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  inputFocusedInitial = false;
  isNumber = false;
  numberFormatOption$: Observable<Option<NumberValueFormat>>;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.numberFormatOption$ = this.control.getNumberFormatOption$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      this.isNumber = this.field == FieldType.Number;
      this.cd.markForCheck();
    }
  }

  setInput(value: boolean) {
    if (value) {
      this.inputFocusedInitial = true;
      this.cd.markForCheck();
    }

    this.control.controls.format_input_enabled.patchValue(value);
  }

  toggleInput() {
    this.setInput(!this.control.controls.format_input_enabled.value);
  }
}

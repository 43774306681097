import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { of, throwError, timer } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { FormUtils } from '@common/form-utils';
import { AUTO_OPTION_COLORS } from '@modules/colors';
import { CurrentProjectStore, Environment, EnvironmentService } from '@modules/projects';

export function duplicateNameValidator(): AsyncValidatorFn {
  return control => {
    if (!control.value) {
      return of(null);
    }

    const parent = control.parent as ProjectEnvironmentCreatePopupForm;

    if (!parent) {
      return;
    }

    return timer(200).pipe(
      switchMap(() => parent.currentProjectStore.getFirst()),
      map(project => {
        const uniqueName = slugify(control.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
        const exists = project.environments.find(item => {
          return item.uniqueName == uniqueName || item.name.toLowerCase().trim() === control.value.toLowerCase().trim();
        });

        if (exists) {
          return { local: ['Environment with such name already exists'] };
        }

        return null;
      })
    );
  };
}

@Injectable()
export class ProjectEnvironmentCreatePopupForm extends FormGroup {
  controls: {
    name: FormControl;
    color: FormControl;
  };

  constructor(
    private environmentService: EnvironmentService,
    private formUtils: FormUtils,
    public currentProjectStore: CurrentProjectStore
  ) {
    super({
      name: new FormControl('', [Validators.required], [duplicateNameValidator()]),
      color: new FormControl('blue', [Validators.required])
    });
  }

  init() {
    const colors = this.currentProjectStore.instance.environments.map(item => item.color);
    const autoColor = AUTO_OPTION_COLORS.find(item => !colors.includes(item));

    this.controls.color.reset(autoColor || 'blue');
  }

  duplicateNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (control.value === null) {
        return of(null);
      }

      return timer(200).pipe(
        switchMap(() => this.currentProjectStore.getFirst()),
        map(project => {
          const uniqueName = slugify(control.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
          const exists = project.environments.find(item => {
            return (
              item.uniqueName == uniqueName || item.name.toLowerCase().trim() === control.value.toLowerCase().trim()
            );
          });

          if (exists) {
            return { local: ['Environment with such name already exists'] };
          }

          return null;
        })
      );
    };
  }

  submit() {
    this.markAsDirty();

    const instance = new Environment();

    instance.name = this.controls.name.value;
    instance.uniqueName = slugify(this.controls.name.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
    instance.color = this.controls.color.value;

    return this.environmentService.create(this.currentProjectStore.instance.uniqueName, instance).pipe(
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

<app-popup2 [size]="'s'">
  <app-popup2-header>
    <app-popup2-title [size]="'s'">Specify Primary Key</app-popup2-title>
    <app-popup2-description>
      Primary key is required to implement this query. You can change primary key later on the Manage fields page.
    </app-popup2-description>
  </app-popup2-header>

  <app-page-block>
    <app-select [control]="form.controls.primaryKey" [options]="form.fieldOptions" [fill]="true"></app-select>
    <app-field-errors [form]="form" [fieldName]="'primary_key_field'"></app-field-errors>
  </app-page-block>

  <app-popup2-footer>
    <app-page-block>
      <app-stack [align]="'right'">
        <a
          *ngIf="cancelEnabled"
          href="javascript:void(0)"
          class="button popup2__footer-item"
          [class.button_disabled]="loading"
          (click)="cancel()"
        >
          <span class="button__label">Cancel</span>
        </a>

        <button
          type="button"
          class="button button_primary popup2__footer-item"
          [disabled]="form.invalid || loading"
          (click)="submit()"
        >
          <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">Continue</span>
        </button>
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsCurrentMonthFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_current_month';
  public param = 'isCurrentMonth';
  public verboseName = localize('is Current Month');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('month').toISOString();
  }

  to(value: string): string {
    return moment().endOf('month').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not current month', [field]);
      } else {
        return localize('is not current month');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is current month', [field]);
      } else {
        return localize('current month');
      }
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Domain } from '@modules/domain';

@Component({
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit, OnDestroy {
  domain: Domain;

  constructor(private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.domain = data.domain;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }
}

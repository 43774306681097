import { Injectable } from '@angular/core';

import { ActionDescription, ActionItem, ActionType, LinkAction, SegueType } from '@modules/actions';
import { BackElementItem } from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';

@Injectable()
export class BackGenerator {
  constructor() {}

  getElement(options: {
    titleStatic?: string;
    titleFormula?: string;
    backStatic?: string;
    backPage?: string;
    uid?: string;
  }): BackElementItem {
    const backElement = new BackElementItem();

    if (options.uid) {
      backElement.uid = options.uid;
    } else {
      backElement.generateUid();
    }

    backElement.titleInput = new Input();
    backElement.titleInput.name = 'value';

    if (options.titleStatic) {
      backElement.titleInput.valueType = InputValueType.StaticValue;
      backElement.titleInput.staticValue = options.titleStatic;
    } else if (options.titleFormula) {
      backElement.titleInput.valueType = InputValueType.Formula;
      backElement.titleInput.formulaValue = options.titleFormula;
    }

    backElement.previousPageTitleInput = new Input();
    backElement.previousPageTitleInput.name = 'value';
    backElement.previousPageTitleInput.valueType = InputValueType.StaticValue;
    backElement.previousPageTitleInput.staticValue = options.backStatic;

    if (options.backPage) {
      backElement.previousPageAction = new ActionItem();
      backElement.previousPageAction.actionDescription = new ActionDescription();
      backElement.previousPageAction.actionDescription.type = ActionType.Link;
      backElement.previousPageAction.actionDescription.linkAction = new LinkAction();
      backElement.previousPageAction.actionDescription.linkAction.type = SegueType.Page;
      backElement.previousPageAction.actionDescription.linkAction.page = options.backPage;
    }

    return backElement;
  }
}

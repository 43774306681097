<ng-container *ngIf="!loading">
  <app-select-model-field
    [modelDescription]="modelDescription"
    [fields]="fields"
    [onlyFields]="onlyFields"
    [searchPlaceholder]="searchPlaceholder"
    [relationsEnabled]="relationsEnabled"
    [onlyNestedFields]="onlyNestedFields"
    [fieldsSelectEnabled]="fieldsSelectEnabled"
    [relationsSelectEnabled]="relationsSelectEnabled"
    [aggregationsEnabled]="aggregationsEnabled"
    [optionsFilter]="optionsFilter"
    (nameSelected)="nameSelected.emit($event)"
    (selected)="selected.emit($event)"
  ></app-select-model-field>
</ng-container>

<ng-container *ngIf="loading">
  <app-select-model-field-stub></app-select-model-field-stub>
</ng-container>

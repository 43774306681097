var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { isSet } from '@shared';
var OptionEnabledControl = /** @class */ (function (_super) {
    __extends(OptionEnabledControl, _super);
    function OptionEnabledControl(value) {
        if (value === void 0) { value = {}; }
        return _super.call(this, {
            name: new FormControl(isSet(value.name) ? value.name : undefined),
            enabled: new FormControl(isSet(value.enabled) ? value.enabled : false)
        }) || this;
    }
    OptionEnabledControl.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
    };
    OptionEnabledControl.prototype.setValue = function (value, options) {
        _super.prototype.setValue.call(this, value, options);
    };
    return OptionEnabledControl;
}(FormGroup));
export { OptionEnabledControl };

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldsModule } from '@modules/fields';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { KanbanBoardColumnComponent } from './components/kanban-board-column/kanban-board-column.component';
import { KanbanBoardItemComponent } from './components/kanban-board-item/kanban-board-item.component';
import { KanbanBoardStubContentComponent } from './components/kanban-board-stub-content/kanban-board-stub-content.component';
import { KanbanBoardStubComponent } from './components/kanban-board-stub/kanban-board-stub.component';
import { KanbanBoardComponent } from './components/kanban-board/kanban-board.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    SharedModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    TipsModule,
    LocalizeModule,
    DragDropModule,
    DialogsModule,
    ModelQueriesModule,
    ExportModule,
    AnalyticsModule,
    FixedComponentModule,
    ViewsComponentsModule
  ],
  declarations: [
    KanbanBoardComponent,
    KanbanBoardItemComponent,
    KanbanBoardColumnComponent,
    KanbanBoardStubComponent,
    KanbanBoardStubContentComponent
  ],
  exports: [KanbanBoardComponent, KanbanBoardStubComponent]
})
export class KanbanBoardModule {}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { AppVersion } from '../../data/app-version';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var AppVersionService = /** @class */ (function () {
    function AppVersionService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    AppVersionService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('app_versions/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new AppVersion().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AppVersionService.ngInjectableDef = i0.defineInjectable({ factory: function AppVersionService_Factory() { return new AppVersionService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: AppVersionService, providedIn: "root" });
    return AppVersionService;
}());
export { AppVersionService };

import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn
} from '@angular/forms';
import range from 'lodash/range';

import { SortingField } from '@modules/queries';

export class SortingFieldsControl extends FormArray {
  constructor({
    formState,
    validatorOrOpts,
    asyncValidator
  }: {
    formState?: any;
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null;
  }) {
    super([], validatorOrOpts, asyncValidator);
    this.setItems(formState, { emitEvent: false });
  }

  createItem(defaults: SortingField = {}): FormGroup {
    return new FormGroup({
      name: new FormControl(defaults['name']),
      sortable: new FormControl(defaults['sortable'])
    });
  }

  addItem(defaults?: SortingField): FormGroup {
    const group = this.createItem(defaults);
    this.push(group);
    return group;
  }

  removeItems() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  setItems(value: SortingField[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.removeItems();
    value.forEach(itemValue => {
      const control = this.addItem();
      control.patchValue(itemValue, options);
    });
  }

  setValue(value: SortingField[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.setItems(value, options);
  }

  patchValue(value: SortingField[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.setItems(value, options);
  }
}

import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import values from 'lodash/values';
import { map } from 'rxjs/operators';
import { createFormFieldFactory } from '@modules/fields';
import { getResourceTypeItemRequestName, Resource } from '@modules/projects';
import { HttpContentType, httpContentTypes, HttpMethod } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import { QueryBuilderContext } from '../../data/query-builder-context';
import { QueryBuilderHttpForm } from '../query-builder-http/query-builder-http.form';
var QueryBuilderHttpRequestParametersComponent = /** @class */ (function () {
    function QueryBuilderHttpRequestParametersComponent(focusMonitor, cd) {
        this.focusMonitor = focusMonitor;
        this.cd = cd;
        this.enabledBodyTypes = [
            HttpContentType.JSON,
            HttpContentType.FormUrlEncoded,
            HttpContentType.FormData,
            HttpContentType.Raw
        ];
        this.execute = new EventEmitter();
        this.inputTokensEvent = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.methodOptions = values(HttpMethod);
        this.tabs = [
            {
                name: 'queryParams',
                label: 'Query Params'
            },
            {
                name: 'headers',
                label: 'Headers'
            },
            {
                name: 'body',
                label: 'Body',
                takeBodyType: true
            }
        ];
        this.jsonPlaceholder = "{\n  \"foo\": params.foo\n}";
        this.rawPlaceholder = "CustomMessage {\n  foo={{params.foo}}\n}";
        this.httpContentTypes = HttpContentType;
        this.urlPathFocused = false;
    }
    QueryBuilderHttpRequestParametersComponent.prototype.ngOnInit = function () {
        this.currentTabName = this.defaultTab ? this.defaultTab : this.tabs[0].name;
        this.bodyTypes = this.enabledBodyTypes.map(function (item) {
            return httpContentTypes.find(function (i) { return i.type == item; });
        });
    };
    QueryBuilderHttpRequestParametersComponent.prototype.ngOnChanges = function (changes) {
        if (changes['resource']) {
            var resourceParams = this.resource
                ? this.resource.parseParams(RestAPIResourceParams)
                : undefined;
            this.baseHttpQuery = resourceParams ? resourceParams.baseHttpQuery : undefined;
            this.cd.markForCheck();
        }
    };
    QueryBuilderHttpRequestParametersComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.urlPathElement) {
            this.focusMonitor.monitor(this.urlPathElement.nativeElement).pipe(map(function (origin) {
                _this.urlPathFocused = !!origin;
                _this.cd.markForCheck();
            }));
        }
    };
    QueryBuilderHttpRequestParametersComponent.prototype.setCurrentTabName = function (name) {
        this.currentTabName = name;
        this.cd.markForCheck();
    };
    Object.defineProperty(QueryBuilderHttpRequestParametersComponent.prototype, "urlPlaceholder", {
        get: function () {
            var requestName = getResourceTypeItemRequestName(this.resource.typeItem);
            return "Enter " + requestName + " URL";
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderHttpRequestParametersComponent.prototype.setBodyType = function (type) {
        this.form.controls.body_type.patchValue(type);
        this.form.controls.body_transformer_visible.patchValue(false);
    };
    return QueryBuilderHttpRequestParametersComponent;
}());
export { QueryBuilderHttpRequestParametersComponent };

export const mailchimpResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'activity',
    verbose_name: 'Activity',
    verbose_name_plural: 'Activities',
    fields: [
      {
        name: 'day',
        verbose_name: 'day',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'emails_sent',
        verbose_name: 'emails_sent',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'unique_opens',
        verbose_name: 'unique_opens',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'recipient_clicks',
        verbose_name: 'recipient_clicks',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'hard_bounce',
        verbose_name: 'hard_bounce',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'soft_bounce',
        verbose_name: 'soft_bounce',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'subs',
        verbose_name: 'subs',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'unsubs',
        verbose_name: 'unsubs',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'other_adds',
        verbose_name: 'other_adds',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'other_removes',
        verbose_name: 'other_removes',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'action',
    relations: [],
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0//lists/{{params.list_id}}/activity',
        auth_params: {},
        body_type: 'JSON',
        body: '{\n    "list_id": params.list_id\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'offset',
            value: '{{paging.offset}}'
          },
          {
            name: 'count',
            value: '{{paging.limit}}'
          }
        ],
        response_type: 'json',
        response_path: 'activity',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_total_path: ['total_items'],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: ['total_items'],
      sorting: false,
      sorting_fields: [
        {
          name: 'day',
          sortable: true
        },
        {
          name: 'emails_sent',
          sortable: true
        },
        {
          name: 'unique_opens',
          sortable: true
        },
        {
          name: 'recipient_clicks',
          sortable: true
        },
        {
          name: 'hard_bounce',
          sortable: true
        },
        {
          name: 'soft_bounce',
          sortable: true
        },
        {
          name: 'subs',
          sortable: true
        },
        {
          name: 'unsubs',
          sortable: true
        },
        {
          name: 'other_adds',
          sortable: true
        },
        {
          name: 'other_removes',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [
      {
        name: 'list_id',
        verbose_name: 'audience',
        description: '',
        field: 'related_model',
        required: true,
        default_value: '',
        placeholder: '',
        params: {
          related_model: {
            model: '{{resource}}.audience'
          },
          custom_primary_key: 'id',
          custom_display_field: 'name'
        }
      }
    ],
    get_inputs: [],
    get_detail_parameters: [
      {
        name: 'action',
        verbose_name: 'action',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'action',
        verbose_name: 'action',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'action',
        verbose_name: 'action',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'audience',
    verbose_name: 'Audience',
    verbose_name_plural: 'Audiences',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'stats',
        verbose_name: 'stats',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'member_count',
                  type: 'field',
                  label: 'member_count',
                  params: {
                    name: 'member_count',
                    field: 'IntegerField',
                    label: 'member_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'unsubscribe_count',
                  type: 'field',
                  label: 'unsubscribe_count',
                  params: {
                    name: 'unsubscribe_count',
                    field: 'IntegerField',
                    label: 'unsubscribe_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'cleaned_count',
                  type: 'field',
                  label: 'cleaned_count',
                  params: {
                    name: 'cleaned_count',
                    field: 'IntegerField',
                    label: 'cleaned_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'member_count_since_send',
                  type: 'field',
                  label: 'member_count_since_send',
                  params: {
                    name: 'member_count_since_send',
                    field: 'IntegerField',
                    label: 'member_count_since_send',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'unsubscribe_count_since_send',
                  type: 'field',
                  label: 'unsubscribe_count_since_send',
                  params: {
                    name: 'unsubscribe_count_since_send',
                    field: 'IntegerField',
                    label: 'unsubscribe_count_since_send',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'cleaned_count_since_send',
                  type: 'field',
                  label: 'cleaned_count_since_send',
                  params: {
                    name: 'cleaned_count_since_send',
                    field: 'IntegerField',
                    label: 'cleaned_count_since_send',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'campaign_count',
                  type: 'field',
                  label: 'campaign_count',
                  params: {
                    name: 'campaign_count',
                    field: 'IntegerField',
                    label: 'campaign_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'campaign_last_sent',
                  type: 'field',
                  label: 'campaign_last_sent',
                  params: {
                    name: 'campaign_last_sent',
                    field: 'DateTimeField',
                    label: 'campaign_last_sent',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'merge_field_count',
                  type: 'field',
                  label: 'merge_field_count',
                  params: {
                    name: 'merge_field_count',
                    field: 'IntegerField',
                    label: 'merge_field_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'avg_sub_rate',
                  type: 'field',
                  label: 'avg_sub_rate',
                  params: {
                    name: 'avg_sub_rate',
                    field: 'IntegerField',
                    label: 'avg_sub_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'avg_unsub_rate',
                  type: 'field',
                  label: 'avg_unsub_rate',
                  params: {
                    name: 'avg_unsub_rate',
                    field: 'IntegerField',
                    label: 'avg_unsub_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'target_sub_rate',
                  type: 'field',
                  label: 'target_sub_rate',
                  params: {
                    name: 'target_sub_rate',
                    field: 'IntegerField',
                    label: 'target_sub_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'open_rate',
                  type: 'field',
                  label: 'open_rate',
                  params: {
                    name: 'open_rate',
                    field: 'FloatField',
                    label: 'open_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'click_rate',
                  type: 'field',
                  label: 'click_rate',
                  params: {
                    name: 'click_rate',
                    field: 'FloatField',
                    label: 'click_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'last_sub_date',
                  type: 'field',
                  label: 'last_sub_date',
                  params: {
                    name: 'last_sub_date',
                    field: 'DateTimeField',
                    label: 'last_sub_date',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'last_unsub_date',
                  type: 'field',
                  label: 'last_unsub_date',
                  params: {
                    name: 'last_unsub_date',
                    field: 'DateTimeField',
                    label: 'last_unsub_date',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: '_links',
        verbose_name: '_links',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                name: null,
                type: 'object',
                label: null,
                params: {
                  items: [
                    {
                      name: 'rel',
                      type: 'field',
                      label: 'rel',
                      params: {
                        name: 'rel',
                        field: 'CharField',
                        label: 'rel',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'href',
                      type: 'field',
                      label: 'href',
                      params: {
                        name: 'href',
                        field: 'CharField',
                        label: 'href',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'method',
                      type: 'field',
                      label: 'method',
                      params: {
                        name: 'method',
                        field: 'CharField',
                        label: 'method',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'targetSchema',
                      type: 'field',
                      label: 'targetSchema',
                      params: {
                        name: 'targetSchema',
                        field: 'CharField',
                        label: 'targetSchema',
                        params: {},
                        required: false
                      }
                    }
                  ]
                }
              }
            }
          },
          display_fields: true
        }
      },
      {
        name: 'web_id',
        verbose_name: 'web_id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'contact',
        verbose_name: 'contact',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'company',
                  type: 'field',
                  label: 'company',
                  params: {
                    name: 'company',
                    field: 'CharField',
                    label: 'company',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'address1',
                  type: 'field',
                  label: 'address1',
                  params: {
                    name: 'address1',
                    field: 'CharField',
                    label: 'address1',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'address2',
                  type: 'field',
                  label: 'address2',
                  params: {
                    name: 'address2',
                    field: 'CharField',
                    label: 'address2',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'city',
                  type: 'field',
                  label: 'city',
                  params: {
                    name: 'city',
                    field: 'CharField',
                    label: 'city',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'state',
                  type: 'field',
                  label: 'state',
                  params: {
                    name: 'state',
                    field: 'CharField',
                    label: 'state',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'zip',
                  type: 'field',
                  label: 'zip',
                  params: {
                    name: 'zip',
                    field: 'CharField',
                    label: 'zip',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'country',
                  type: 'field',
                  label: 'country',
                  params: {
                    name: 'country',
                    field: 'CharField',
                    label: 'country',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'phone',
                  type: 'field',
                  label: 'phone',
                  params: {
                    name: 'phone',
                    field: 'CharField',
                    label: 'phone',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'modules',
        verbose_name: 'modules',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'visibility',
        verbose_name: 'visibility',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'has_welcome',
        verbose_name: 'has_welcome',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'list_rating',
        verbose_name: 'list_rating',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'date_created',
        verbose_name: 'date_created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'double_optin',
        verbose_name: 'double_optin',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'beamer_address',
        verbose_name: 'beamer_address',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'use_archive_bar',
        verbose_name: 'use_archive_bar',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'campaign_defaults',
        verbose_name: 'campaign_defaults',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'from_name',
                  type: 'field',
                  label: 'from_name',
                  params: {
                    name: 'from_name',
                    field: 'CharField',
                    label: 'from_name',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'from_email',
                  type: 'field',
                  label: 'from_email',
                  params: {
                    name: 'from_email',
                    field: 'CharField',
                    label: 'from_email',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'subject',
                  type: 'field',
                  label: 'subject',
                  params: {
                    name: 'subject',
                    field: 'CharField',
                    label: 'subject',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'language',
                  type: 'field',
                  label: 'language',
                  params: {
                    name: 'language',
                    field: 'CharField',
                    label: 'language',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'email_type_option',
        verbose_name: 'email_type_option',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subscribe_url_long',
        verbose_name: 'subscribe_url_long',
        field: 'url',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notify_on_subscribe',
        verbose_name: 'notify_on_subscribe',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'permission_reminder',
        verbose_name: 'permission_reminder',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subscribe_url_short',
        verbose_name: 'subscribe_url_short',
        field: 'url',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'marketing_permissions',
        verbose_name: 'marketing_permissions',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notify_on_unsubscribe',
        verbose_name: 'notify_on_unsubscribe',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    display_field: 'name',
    primary_key_field: 'id',
    relations: [],
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0/lists',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'offset',
            value: '{{paging.offset}}'
          },
          {
            name: 'count',
            value: '{{paging.limit}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['lists'];",
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_total_function: "// ex. data['count']\nreturn data['total_items']",
      pagination_total_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: ['total_items'],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'web_id',
          sortable: true
        },
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'contact',
          sortable: true
        },
        {
          name: 'permission_reminder',
          sortable: true
        },
        {
          name: 'use_archive_bar',
          sortable: true
        },
        {
          name: 'campaign_defaults',
          sortable: true
        },
        {
          name: 'notify_on_subscribe',
          sortable: true
        },
        {
          name: 'notify_on_unsubscribe',
          sortable: true
        },
        {
          name: 'date_created',
          sortable: true
        },
        {
          name: 'list_rating',
          sortable: true
        },
        {
          name: 'email_type_option',
          sortable: true
        },
        {
          name: 'subscribe_url_short',
          sortable: true
        },
        {
          name: 'subscribe_url_long',
          sortable: true
        },
        {
          name: 'beamer_address',
          sortable: true
        },
        {
          name: 'visibility',
          sortable: true
        },
        {
          name: 'double_optin',
          sortable: true
        },
        {
          name: 'has_welcome',
          sortable: true
        },
        {
          name: 'marketing_permissions',
          sortable: true
        },
        {
          name: 'modules',
          sortable: true
        },
        {
          name: 'stats',
          sortable: true
        },
        {
          name: '_links',
          sortable: true
        }
      ]
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0/lists/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'campaigns',
    verbose_name: 'Campaigns',
    verbose_name_plural: 'Campaigns',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'select',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          options: [
            {
              name: 'regular',
              color: 'blue',
              value: 'regular'
            },
            {
              name: 'plaintext',
              color: 'teal',
              value: 'plaintext'
            },
            {
              name: 'absplit',
              color: '',
              value: 'absplit'
            },
            {
              name: 'variate',
              color: '',
              value: 'variate'
            },
            {
              name: 'rss',
              color: '',
              value: 'rss'
            }
          ],
          options_type: 'static'
        }
      },
      {
        name: '_links',
        verbose_name: '_links',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                name: null,
                type: 'object',
                label: null,
                params: {
                  items: [
                    {
                      name: 'rel',
                      type: 'field',
                      label: 'rel',
                      params: {
                        name: 'rel',
                        field: 'CharField',
                        label: 'rel',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'href',
                      type: 'field',
                      label: 'href',
                      params: {
                        name: 'href',
                        field: 'CharField',
                        label: 'href',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'method',
                      type: 'field',
                      label: 'method',
                      params: {
                        name: 'method',
                        field: 'CharField',
                        label: 'method',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'targetSchema',
                      type: 'field',
                      label: 'targetSchema',
                      params: {
                        name: 'targetSchema',
                        field: 'CharField',
                        label: 'targetSchema',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'schema',
                      type: 'field',
                      label: 'schema',
                      params: {
                        name: 'schema',
                        field: 'CharField',
                        label: 'schema',
                        params: {},
                        required: false
                      }
                    }
                  ]
                }
              }
            }
          },
          display_fields: true
        }
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'select',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          options: [
            {
              name: 'save',
              color: '',
              value: 'save'
            },
            {
              name: 'paused',
              color: '',
              value: 'paused'
            },
            {
              name: 'schedule',
              color: '',
              value: 'schedule'
            },
            {
              name: 'sending',
              color: '',
              value: 'sending'
            },
            {
              name: 'sent',
              color: '',
              value: 'sent'
            }
          ],
          options_type: 'static'
        }
      },
      {
        name: 'web_id',
        verbose_name: 'web_id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'settings',
        verbose_name: 'settings',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'subject_line',
                  type: 'field',
                  label: 'subject_line',
                  params: {
                    name: 'subject_line',
                    field: 'CharField',
                    label: 'subject_line',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'title',
                  type: 'field',
                  label: 'title',
                  params: {
                    name: 'title',
                    field: 'CharField',
                    label: 'title',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'from_name',
                  type: 'field',
                  label: 'from_name',
                  params: {
                    name: 'from_name',
                    field: 'CharField',
                    label: 'from_name',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'reply_to',
                  type: 'field',
                  label: 'reply_to',
                  params: {
                    name: 'reply_to',
                    field: 'CharField',
                    label: 'reply_to',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'use_conversation',
                  type: 'field',
                  label: 'use_conversation',
                  params: {
                    name: 'use_conversation',
                    field: 'CharField',
                    label: 'use_conversation',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'to_name',
                  type: 'field',
                  label: 'to_name',
                  params: {
                    name: 'to_name',
                    field: 'CharField',
                    label: 'to_name',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'folder_id',
                  type: 'field',
                  label: 'folder_id',
                  params: {
                    name: 'folder_id',
                    field: 'CharField',
                    label: 'folder_id',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'authenticate',
                  type: 'field',
                  label: 'authenticate',
                  params: {
                    name: 'authenticate',
                    field: 'CharField',
                    label: 'authenticate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'auto_footer',
                  type: 'field',
                  label: 'auto_footer',
                  params: {
                    name: 'auto_footer',
                    field: 'CharField',
                    label: 'auto_footer',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'inline_css',
                  type: 'field',
                  label: 'inline_css',
                  params: {
                    name: 'inline_css',
                    field: 'CharField',
                    label: 'inline_css',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'auto_tweet',
                  type: 'field',
                  label: 'auto_tweet',
                  params: {
                    name: 'auto_tweet',
                    field: 'CharField',
                    label: 'auto_tweet',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'fb_comments',
                  type: 'field',
                  label: 'fb_comments',
                  params: {
                    name: 'fb_comments',
                    field: 'CharField',
                    label: 'fb_comments',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'timewarp',
                  type: 'field',
                  label: 'timewarp',
                  params: {
                    name: 'timewarp',
                    field: 'CharField',
                    label: 'timewarp',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'template_id',
                  type: 'field',
                  label: 'template_id',
                  params: {
                    name: 'template_id',
                    field: 'IntegerField',
                    label: 'template_id',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'drag_and_drop',
                  type: 'field',
                  label: 'drag_and_drop',
                  params: {
                    name: 'drag_and_drop',
                    field: 'CharField',
                    label: 'drag_and_drop',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'tracking',
        verbose_name: 'tracking',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'opens',
                  type: 'field',
                  label: 'opens',
                  params: {
                    name: 'opens',
                    field: 'CharField',
                    label: 'opens',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'html_clicks',
                  type: 'field',
                  label: 'html_clicks',
                  params: {
                    name: 'html_clicks',
                    field: 'CharField',
                    label: 'html_clicks',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'text_clicks',
                  type: 'field',
                  label: 'text_clicks',
                  params: {
                    name: 'text_clicks',
                    field: 'CharField',
                    label: 'text_clicks',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'goal_tracking',
                  type: 'field',
                  label: 'goal_tracking',
                  params: {
                    name: 'goal_tracking',
                    field: 'CharField',
                    label: 'goal_tracking',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'ecomm360',
                  type: 'field',
                  label: 'ecomm360',
                  params: {
                    name: 'ecomm360',
                    field: 'CharField',
                    label: 'ecomm360',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'google_analytics',
                  type: 'field',
                  label: 'google_analytics',
                  params: {
                    name: 'google_analytics',
                    field: 'CharField',
                    label: 'google_analytics',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'clicktale',
                  type: 'field',
                  label: 'clicktale',
                  params: {
                    name: 'clicktale',
                    field: 'CharField',
                    label: 'clicktale',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'send_time',
        verbose_name: 'send_time',
        field: 'datetime',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'recipients',
        verbose_name: 'recipients',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'list_id',
                  type: 'field',
                  label: 'list_id',
                  params: {
                    name: 'list_id',
                    field: 'CharField',
                    label: 'list_id',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'list_is_active',
                  type: 'field',
                  label: 'list_is_active',
                  params: {
                    name: 'list_is_active',
                    field: 'CharField',
                    label: 'list_is_active',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'list_name',
                  type: 'field',
                  label: 'list_name',
                  params: {
                    name: 'list_name',
                    field: 'CharField',
                    label: 'list_name',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'segment_text',
                  type: 'field',
                  label: 'segment_text',
                  params: {
                    name: 'segment_text',
                    field: 'CharField',
                    label: 'segment_text',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'recipient_count',
                  type: 'field',
                  label: 'recipient_count',
                  params: {
                    name: 'recipient_count',
                    field: 'IntegerField',
                    label: 'recipient_count',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'resendable',
        verbose_name: 'resendable',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'archive_url',
        verbose_name: 'archive_url',
        field: 'url',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'create_time',
        verbose_name: 'create_time',
        field: 'datetime',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'emails_sent',
        verbose_name: 'emails_sent',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'content_type',
        verbose_name: 'content_type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'delivery_status',
        verbose_name: 'delivery_status',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'enabled',
                  type: 'field',
                  label: 'enabled',
                  params: {
                    name: 'enabled',
                    field: 'CharField',
                    label: 'enabled',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'long_archive_url',
        verbose_name: 'long_archive_url',
        field: 'url',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'needs_block_refresh',
        verbose_name: 'needs_block_refresh',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    display_field: 'id',
    primary_key_field: 'id',
    relations: [],
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0/campaigns',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'offset',
            value: '{{paging.offset}}'
          },
          {
            name: 'count',
            value: '{{paging.limit}}'
          }
        ],
        response_type: 'json',
        response_path: 'campaigns',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_total_path: ['total_items'],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: ['total_items'],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'web_id',
          sortable: true
        },
        {
          name: 'type',
          sortable: true
        },
        {
          name: 'create_time',
          sortable: true
        },
        {
          name: 'archive_url',
          sortable: true
        },
        {
          name: 'long_archive_url',
          sortable: true
        },
        {
          name: 'status',
          sortable: true
        },
        {
          name: 'emails_sent',
          sortable: true
        },
        {
          name: 'send_time',
          sortable: true
        },
        {
          name: 'content_type',
          sortable: true
        },
        {
          name: 'needs_block_refresh',
          sortable: true
        },
        {
          name: 'resendable',
          sortable: true
        },
        {
          name: 'recipients',
          sortable: true
        },
        {
          name: 'settings',
          sortable: true
        },
        {
          name: 'tracking',
          sortable: true
        },
        {
          name: 'delivery_status',
          sortable: true
        },
        {
          name: '_links',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0/campaigns/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '{\n    "id": params.id\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'members',
    verbose_name: 'Members',
    verbose_name_plural: 'Members',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'email_address',
        verbose_name: 'email_address',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'unique_email_id',
        verbose_name: 'unique_email_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'contact_id',
        verbose_name: 'contact_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'full_name',
        verbose_name: 'full_name',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'web_id',
        verbose_name: 'web_id',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'email_type',
        verbose_name: 'email_type',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'consents_to_one_to_one_messaging',
        verbose_name: 'consents_to_one_to_one_messaging',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'merge_fields',
        verbose_name: 'merge_fields',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'FNAME',
                  type: 'field',
                  label: 'FNAME',
                  params: {
                    name: 'FNAME',
                    field: 'text',
                    label: 'FNAME',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'LNAME',
                  type: 'field',
                  label: 'LNAME',
                  params: {
                    name: 'LNAME',
                    field: 'text',
                    label: 'LNAME',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'ADDRESS',
                  type: 'object',
                  label: 'ADDRESS',
                  params: {
                    items: [
                      {
                        name: 'addr1',
                        type: 'field',
                        label: 'addr1',
                        params: {
                          name: 'addr1',
                          field: 'text',
                          label: 'addr1',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'addr2',
                        type: 'field',
                        label: 'addr2',
                        params: {
                          name: 'addr2',
                          field: 'text',
                          label: 'addr2',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'city',
                        type: 'field',
                        label: 'city',
                        params: {
                          name: 'city',
                          field: 'text',
                          label: 'city',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'state',
                        type: 'field',
                        label: 'state',
                        params: {
                          name: 'state',
                          field: 'text',
                          label: 'state',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'zip',
                        type: 'field',
                        label: 'zip',
                        params: {
                          name: 'zip',
                          field: 'text',
                          label: 'zip',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'country',
                        type: 'field',
                        label: 'country',
                        params: {
                          name: 'country',
                          field: 'text',
                          label: 'country',
                          params: {},
                          required: false
                        }
                      }
                    ]
                  }
                },
                {
                  name: 'PHONE',
                  type: 'field',
                  label: 'PHONE',
                  params: {
                    name: 'PHONE',
                    field: 'text',
                    label: 'PHONE',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'BIRTHDAY',
                  type: 'field',
                  label: 'BIRTHDAY',
                  params: {
                    name: 'BIRTHDAY',
                    field: 'text',
                    label: 'BIRTHDAY',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'stats',
        verbose_name: 'stats',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'avg_open_rate',
                  type: 'field',
                  label: 'avg_open_rate',
                  params: {
                    name: 'avg_open_rate',
                    field: 'number',
                    label: 'avg_open_rate',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'avg_click_rate',
                  type: 'field',
                  label: 'avg_click_rate',
                  params: {
                    name: 'avg_click_rate',
                    field: 'number',
                    label: 'avg_click_rate',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'ip_signup',
        verbose_name: 'ip_signup',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'timestamp_signup',
        verbose_name: 'timestamp_signup',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'ip_opt',
        verbose_name: 'ip_opt',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'timestamp_opt',
        verbose_name: 'timestamp_opt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'member_rating',
        verbose_name: 'member_rating',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'last_changed',
        verbose_name: 'last_changed',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'language',
        verbose_name: 'language',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'vip',
        verbose_name: 'vip',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'email_client',
        verbose_name: 'email_client',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'latitude',
                  type: 'field',
                  label: 'latitude',
                  params: {
                    name: 'latitude',
                    field: 'number',
                    label: 'latitude',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'longitude',
                  type: 'field',
                  label: 'longitude',
                  params: {
                    name: 'longitude',
                    field: 'number',
                    label: 'longitude',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'gmtoff',
                  type: 'field',
                  label: 'gmtoff',
                  params: {
                    name: 'gmtoff',
                    field: 'number',
                    label: 'gmtoff',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'dstoff',
                  type: 'field',
                  label: 'dstoff',
                  params: {
                    name: 'dstoff',
                    field: 'number',
                    label: 'dstoff',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'country_code',
                  type: 'field',
                  label: 'country_code',
                  params: {
                    name: 'country_code',
                    field: 'text',
                    label: 'country_code',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'timezone',
                  type: 'field',
                  label: 'timezone',
                  params: {
                    name: 'timezone',
                    field: 'text',
                    label: 'timezone',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'region',
                  type: 'field',
                  label: 'region',
                  params: {
                    name: 'region',
                    field: 'text',
                    label: 'region',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'tags_count',
        verbose_name: 'tags_count',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                type: 'object',
                params: {
                  items: []
                }
              }
            }
          },
          display_fields: true
        }
      },
      {
        name: 'list_id',
        verbose_name: 'list_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: '_links',
        verbose_name: '_links',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        default_value: 'undefined',
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                name: null,
                type: 'object',
                label: null,
                params: {
                  items: [
                    {
                      name: 'rel',
                      type: 'field',
                      label: 'rel',
                      params: {
                        name: 'rel',
                        field: 'CharField',
                        label: 'rel',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'href',
                      type: 'field',
                      label: 'href',
                      params: {
                        name: 'href',
                        field: 'CharField',
                        label: 'href',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'method',
                      type: 'field',
                      label: 'method',
                      params: {
                        name: 'method',
                        field: 'CharField',
                        label: 'method',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'targetSchema',
                      type: 'field',
                      label: 'targetSchema',
                      params: {
                        name: 'targetSchema',
                        field: 'CharField',
                        label: 'targetSchema',
                        params: {},
                        required: false
                      }
                    }
                  ]
                }
              }
            }
          },
          display_fields: true
        }
      }
    ],
    flex_fields: [],
    segments: [],
    display_field: 'email_address',
    primary_key_field: 'id',
    relations: [],
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://{{server}}.api.mailchimp.com/3.0//lists/{{params.list_id}}/members',
        auth_params: {},
        body_type: 'JSON',
        body: '{\n    "list_id": params.list_id\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'offset',
            value: '{{paging.offset}}'
          },
          {
            name: 'count',
            value: '{{paging.limit}}'
          }
        ],
        response_type: 'json',
        response_path: 'members',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_total_path: ['total_items'],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: ['total_items'],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'vip',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'stats',
          sortable: true
        },
        {
          name: '_links',
          sortable: true
        },
        {
          name: 'ip_opt',
          sortable: true
        },
        {
          name: 'source',
          sortable: true
        },
        {
          name: 'status',
          sortable: true
        },
        {
          name: 'web_id',
          sortable: true
        },
        {
          name: 'list_id',
          sortable: true
        },
        {
          name: 'language',
          sortable: true
        },
        {
          name: 'location',
          sortable: true
        },
        {
          name: 'full_name',
          sortable: true
        },
        {
          name: 'ip_signup',
          sortable: true
        },
        {
          name: 'contact_id',
          sortable: true
        },
        {
          name: 'email_type',
          sortable: true
        },
        {
          name: 'tags_count',
          sortable: true
        },
        {
          name: 'email_client',
          sortable: true
        },
        {
          name: 'last_changed',
          sortable: true
        },
        {
          name: 'merge_fields',
          sortable: true
        },
        {
          name: 'email_address',
          sortable: true
        },
        {
          name: 'member_rating',
          sortable: true
        },
        {
          name: 'timestamp_opt',
          sortable: true
        },
        {
          name: 'unique_email_id',
          sortable: true
        },
        {
          name: 'timestamp_signup',
          sortable: true
        },
        {
          name: 'consents_to_one_to_one_messaging',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [
      {
        name: 'list_id',
        verbose_name: 'audience',
        description: '',
        field: 'related_model',
        required: true,
        default_value: '',
        placeholder: '',
        params: {
          related_model: {
            model: '{{resource}}.audience'
          },
          custom_primary_key: 'id',
          custom_display_field: 'name'
        }
      }
    ],
    get_inputs: [],
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  }
];

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../shared/components/autofocus/autofocus.directive";
import * as i3 from "../../../../core/services/app-config/app-config.service";
import * as i4 from "../../../../shared/directives/key-event/key-event.directive";
import * as i5 from "../input-token-value/input-token-value.component.ngfactory";
import * as i6 from "../input-token-value/input-token-value.component";
import * as i7 from "@angular/common";
import * as i8 from "../input-tokens-list-item/input-tokens-list-item.component.ngfactory";
import * as i9 from "../input-tokens-list-item/input-tokens-list-item.component";
import * as i10 from "../../../../common/tips/directives/tip/tip.directive";
import * as i11 from "../../../../shared/components/toggle/toggle.component.ngfactory";
import * as i12 from "../../../../shared/components/toggle/toggle.component";
import * as i13 from "../input-tokens-list/input-tokens-list.component.ngfactory";
import * as i14 from "../input-tokens-list/input-tokens-list.component";
import * as i15 from "./input-tokens.component";
import * as i16 from "../../../users/stores/current-user.store";
import * as i17 from "../../../projects/stores/current-project.store";
import * as i18 from "../../../api/services/api/api.service";
var styles_InputTokensComponent = [];
var RenderType_InputTokensComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputTokensComponent, data: {} });
export { RenderType_InputTokensComponent as RenderType_InputTokensComponent };
function View_InputTokensComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 18, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 17, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "token-popup-list-item__inner token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "button button_orange button_icon button_extra-small"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSpecifyAddParameter(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "button__icon icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 7, "input", [["appKeyEvent", ""], ["class", "input input_fill input_small"], ["placeholder", "Specify input name"], ["style", "width: 170px;"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "enterUp"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("enterUp" === en)) {
        var pd_4 = (_co.addParameterFromInput() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.setAddParameterName($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(11, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(13, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(14, 540672, null, 0, i2.AutofocusDirective, [i0.ElementRef, i3.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null), i0.ɵdid(15, 4341760, null, 0, i4.KeyEventDirective, [i0.ElementRef], null, { enterUp: "enterUp" }), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "token-popup-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "button", [["class", "button button_primary button_orange button_small"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addParameterFromInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "span", [["class", "button_label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.addParameterName; _ck(_v, 11, 0, currVal_7); var currVal_8 = true; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 13).ngClassValid; var currVal_5 = i0.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = !_co.addParameterName; _ck(_v, 17, 0, currVal_9); }); }
function View_InputTokensComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-token-value", [], null, [[null, "back"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.updatedTokensFiltered(undefined) !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.submitTokenValue() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_InputTokenValueComponent_0, i5.RenderType_InputTokenValueComponent)), i0.ɵdid(1, 638976, null, 0, i6.InputTokenValueComponent, [i0.ChangeDetectorRef], { selectedTokenValue: [0, "selectedTokenValue"], selectedToken: [1, "selectedToken"], useFileObjects: [2, "useFileObjects"], autofocus: [3, "autofocus"] }, { back: "back", submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTokenValue; var currVal_1 = _co.selectedToken; var currVal_2 = _co.context.useFileObjects; var currVal_3 = (_co.selectedToken === _co.createdToken); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_InputTokensComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "token-popup-list-item__inner token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "button button_orange button_icon button_extra-small"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updatedTokensFiltered(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button__icon icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["No such parameter \"", "\""])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "token-popup-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "button button_primary button_orange button_small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addParameter(_co.search) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "button_label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.search; _ck(_v, 7, 0, currVal_0); }); }
function View_InputTokensComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_6)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchSuggestAdd; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputTokensComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list-item", [], null, [[null, "clickItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItem" === en)) {
        var pd_0 = (_co.insert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_InputTokensListItemComponent_0, i8.RenderType_InputTokensListItemComponent)), i0.ɵdid(1, 638976, null, 0, i9.InputTokensListItemComponent, [], { item: [0, "item"], tokenValues: [1, "tokenValues"] }, { clickItem: "clickItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.tokenValues; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSpecifyAddParameter(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Input"]))], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + "plus"); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "token-popup__item token-popup__border-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_8)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_9)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.children; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_v.context.$implicit.data && _v.context.$implicit.data["parameter_tokens"]); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_0); }); }
function View_InputTokensComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list-item", [], null, [[null, "clickItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItem" === en)) {
        var pd_0 = (_co.insert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_InputTokensListItemComponent_0, i8.RenderType_InputTokensListItemComponent)), i0.ɵdid(1, 638976, null, 0, i9.InputTokensListItemComponent, [], { item: [0, "item"], tokenValues: [1, "tokenValues"] }, { clickItem: "clickItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.tokenValues; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_14)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paginationTokensFiltered; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputTokensComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "token-popup-section__title-icon token-popup-section__title-icon_left"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "token-popup-section__title-link token-popup-section__title-link_orange icon-edit"], ["href", "javascript:void(0)"], ["style", "margin-left: 12px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setChoosePagination(true);
        _co.context.form.setPagination(undefined);
        var pd_0 = (_co.updatedTokensFiltered(_co.search) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 737280, null, 0, i10.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_13)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "token-popup-section__title-icon token-popup-section__title-icon_left"; var currVal_1 = _ck(_v, 4, 0, ("icon-" + ((_co.currentPaginationOption == null) ? null : _co.currentPaginationOption.icon))); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = "Change pagination"; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.paginationTokensFiltered; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.currentPaginationOption == null) ? null : _co.currentPaginationOption.name); _ck(_v, 6, 0, currVal_2); }); }
function View_InputTokensComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pagination \u2192 Choose Type"]))], null, null); }
function View_InputTokensComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pagination"]))], null, null); }
function View_InputTokensComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setChoosePagination(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enable pagination"]))], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + "plus"); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPagination(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "token-popup-list-item__left token-popup-list-item__left_fixed"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + _v.context.$implicit.icon); _ck(_v, 5, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
function View_InputTokensComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_20)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setChoosePagination(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "token-popup-list-item__left token-popup-list-item__left_fixed"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No pagination "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paginationOptions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputTokensComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_16)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_17)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_18)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_19)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.choosePagination; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.choosePagination; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.choosePagination; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.choosePagination; _ck(_v, 10, 0, currVal_3); }, null); }
function View_InputTokensComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "token-popup__item token-popup__border-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_12)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_15)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context.form.controls.pagination.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.context.form.controls.pagination.value; _ck(_v, 4, 0, currVal_1); }, null); }
function View_InputTokensComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_11)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.search && _co.context.form.controls.pagination.value) && ((_co.paginationTokensFiltered == null) ? null : _co.paginationTokensFiltered.length)) || !_co.search); _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputTokensComponent_23(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "token-popup-section__title-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-toggle", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSorting(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ToggleComponent_0, i11.RenderType_ToggleComponent)), i0.ɵdid(2, 114688, null, 0, i12.ToggleComponent, [], { orange: [0, "orange"], selected: [1, "selected"] }, null)], function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_24(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list-item", [], null, [[null, "clickItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItem" === en)) {
        var pd_0 = (_co.insert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_InputTokensListItemComponent_0, i8.RenderType_InputTokensListItemComponent)), i0.ɵdid(1, 638976, null, 0, i9.InputTokensListItemComponent, [], { item: [0, "item"], tokenValues: [1, "tokenValues"] }, { clickItem: "clickItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.tokenValues; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_25(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [["class", "token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSorting(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enable sorting"]))], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + "plus"); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
function View_InputTokensComponent_22(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "token-popup__item token-popup__border-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Sorting "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_23)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_24)), i0.ɵdid(8, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_25)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context.form.controls.sorting.value; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.sortingTokensFiltered; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.context.form.controls.sorting.value; _ck(_v, 10, 0, currVal_2); }, null); }
function View_InputTokensComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_22)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.search && _co.context.form.controls.sorting.value) && ((_co.sortingTokensFiltered == null) ? null : _co.sortingTokensFiltered.length)) || !_co.search); _ck(_v, 2, 0, currVal_0); }, null); }
function View_InputTokensComponent_26(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "token-popup__item token-popup__border-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-input-tokens-list", [], null, [[null, "clickItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItem" === en)) {
        var pd_0 = (_co.insert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_InputTokensListComponent_0, i13.RenderType_InputTokensListComponent)), i0.ɵdid(2, 114688, null, 0, i14.InputTokensListComponent, [], { title: [0, "title"], items: [1, "items"], tokenValues: [2, "tokenValues"] }, { clickItem: "clickItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.label; var currVal_1 = _v.context.$implicit.children; var currVal_2 = _co.tokenValues; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_InputTokensComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_5)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_7)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_10)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_21)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_26)), i0.ɵdid(10, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.mainTokensFiltered == null) ? null : _co.mainTokensFiltered.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.mainTokensFiltered; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.context.paginationTokens; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.context.sortingTokens; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.otherTokensFiltered; _ck(_v, 10, 0, currVal_4); }, null); }
function View_InputTokensComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_3)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_4)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedToken; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.selectedToken; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InputTokensComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "popover2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "popover2__content"]], [[4, "min-width", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_1)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensComponent_2)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.specifyAddParameter; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.specifyAddParameter; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = 300; _ck(_v, 3, 0, currVal_0); }); }
export function View_InputTokensComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens", [], null, null, null, View_InputTokensComponent_0, RenderType_InputTokensComponent)), i0.ɵdid(1, 245760, null, 0, i15.InputTokensComponent, [i15.CONTEXT_TOKEN, i15.SEARCH_TOKEN, i16.CurrentUserStore, i17.CurrentProjectStore, i18.ApiService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputTokensComponentNgFactory = i0.ɵccf("app-input-tokens", i15.InputTokensComponent, View_InputTokensComponent_Host_0, {}, { inserted: "inserted", event: "event" }, []);
export { InputTokensComponentNgFactory as InputTokensComponentNgFactory };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuComponentsModule } from '@modules/menu-components';
import { TableModule } from '@modules/table';
import { UiModule } from '@ui';

import { ApplyTemplateComponent } from './components/apply-template/apply-template.component';

@NgModule({
  imports: [CommonModule, MenuComponentsModule, UiModule, TableModule],
  declarations: [ApplyTemplateComponent]
})
export class TemplateRoutesModule {}

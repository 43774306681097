import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class ScannerElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Scanner;
  public width: number;
  public height: number;
  public scanningInitial = false;
  public ignoreDuplicateScans = true;
  public scanConfirm = false;
  public onScanActions: ActionItem[] = [];

  deserialize(data: Object): ScannerElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.height = this.params['height'];

    if (this.params.hasOwnProperty('scanning_initial')) {
      this.scanningInitial = this.params['scanning_initial'];
    }

    if (this.params.hasOwnProperty('ignore_duplicate_scans')) {
      this.ignoreDuplicateScans = this.params['ignore_duplicate_scans'];
    }

    if (this.params.hasOwnProperty('scan_confirm')) {
      this.scanConfirm = this.params['scan_confirm'];
    }

    if (this.params['on_scan_actions']) {
      this.onScanActions = this.params['on_scan_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      height: this.height,
      scanning_initial: this.scanningInitial,
      ignore_duplicate_scans: this.ignoreDuplicateScans,
      scan_confirm: this.scanConfirm,
      on_scan_actions: this.onScanActions.map(item => item.serialize())
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'scanner';
  }

  get analyticsName(): string {
    return 'scanner';
  }

  defaultName() {
    return 'Scanner';
  }
}

registerElementForType(ElementType.Scanner, ScannerElementItem);

<div
  class="customize-menu__data-menu"
  [class.customize-menu__data-menu_visible]="opened"
  (click)="markClickEvent($event)"
>
  <div class="customize-menu__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <div class="menu3">
      <div class="menu3__header">
        <div class="menu3__header-main">
          <!--          <div class="menu3__title-container menu3__header-row">-->
          <!--            <div class="menu3__header-row-main">-->
          <!--              <div class="menu3__title">Variables</div>-->
          <!--            </div>-->
          <!--            <div class="menu3__header-row-right">-->
          <!--              <a href="javascript:void(0)" class="menu3__close icon-close" (click)="close()"></a>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="menu3-input">
            <input
              class="menu3-input__control"
              placeholder="Search variables..."
              [formControl]="searchControl"
              [appAutofocus]="opened"
              (keyup)="onSearchKey($event)"
            />
            <span class="menu3-input__icon icon-search"></span>
            <span
              *ngIf="searchControl.value | appIsSet"
              class="menu3-input__close icon-close"
              (click)="clearSearch()"
            ></span>
          </div>
        </div>
      </div>

      <div class="menu3__items">
        <div *ngFor="let section of filteredSections" class="menu3__section">
          <app-custom-page-properties-section
            [section]="section"
            [values]="section.getValues()"
          ></app-custom-page-properties-section>
        </div>
      </div>
    </div>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ChartWidgetGroup } from '@modules/dashboard';
import { isSet } from '@shared';
var ChartWidgetGroupControl = /** @class */ (function (_super) {
    __extends(ChartWidgetGroupControl, _super);
    function ChartWidgetGroupControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            value: new FormControl(isSet(state.value) ? state.value : ''),
            name: new FormControl(isSet(state.name) ? state.name : ''),
            color: new FormControl(isSet(state.color) ? state.color : ''),
            removable: new FormControl(false)
        }) || this;
        _this.controls.value.disable();
        return _this;
    }
    ChartWidgetGroupControl.prototype.deserialize = function (instance) {
        this.instance = instance;
        if (instance) {
            this.controls.value.patchValue(instance.value);
            this.controls.name.patchValue(instance.name);
            this.controls.color.patchValue(instance.color);
        }
        else {
            this.controls.value.patchValue('');
            this.controls.name.patchValue('');
            this.controls.color.patchValue('');
        }
        this.markAsPristine();
        return this;
    };
    ChartWidgetGroupControl.prototype.serialize = function () {
        var result = cloneDeep(this.instance) || new ChartWidgetGroup();
        result.value = this.controls.value.value;
        result.name = this.controls.name.value;
        result.color = this.controls.color.value;
        return result;
    };
    ChartWidgetGroupControl.prototype.isSet = function () {
        return isSet(this.controls.name.value) || isSet(this.controls.color.value);
    };
    return ChartWidgetGroupControl;
}(FormGroup));
export { ChartWidgetGroupControl };

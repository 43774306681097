import { Injectable, Injector, Type } from '@angular/core';

import { ModelDescription } from '@modules/models';
import { Resource, ResourceType } from '@modules/projects';

import { ResourceController } from '../../data/resource-controller';

@Injectable()
export class ResourceControllerService {
  constructor(private injector: Injector) {}

  controllers: { [key: string]: ResourceController } = {};

  createInstance(cls: Type<ResourceController>): ResourceController {
    return Injector.create({
      providers: [{ provide: cls, deps: [Injector] }],
      parent: this.injector
    }).get<ResourceController>(cls);
  }

  register(type: ResourceType, cls: Type<ResourceController>) {
    this.controllers[type] = this.createInstance(cls);
  }

  get<T extends ResourceController = ResourceController>(type: ResourceType): T {
    return this.controllers[type] as T;
  }

  getForResource<T extends ResourceController = ResourceController>(resource: Resource, useSync = false): T {
    const resourceType =
      useSync && (resource.isSynced() || resource.hasCollectionSync()) ? ResourceType.JetBridge : resource.type;
    return this.get<T>(resourceType);
  }

  getForModelDescription<T extends ResourceController = ResourceController>(
    resource: Resource,
    modelDescription: ModelDescription,
    useSync = false
  ): T {
    const resourceType =
      useSync && (resource.isSynced(modelDescription.model) || modelDescription.isSynced())
        ? ResourceType.JetBridge
        : resource.type;
    return this.get<T>(resourceType);
  }
}

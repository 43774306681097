var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { ActionType } from '@modules/actions';
import { controlValue, isSet } from '@shared';
import { actionTypeOptions } from './action-type-options';
var ActionTypeDropdownComponent = /** @class */ (function () {
    function ActionTypeDropdownComponent(cd) {
        this.cd = cd;
        this.opened = false;
        this.actionOriginEnabled = false;
        this.select = new EventEmitter();
        this.close = new EventEmitter();
        this.searchControl = new FormControl('');
        this.sections = actionTypeOptions;
        this.disableInteractions = false;
        this.dropdownPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center'
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center'
            }
        ];
    }
    ActionTypeDropdownComponent.prototype.trackSectionFn = function (i, item) {
        return item.id;
    };
    ActionTypeDropdownComponent.prototype.trackSectionItemFn = function (i, item) {
        return item.value;
    };
    ActionTypeDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.searchControl)
            .pipe(map(function (search) { return search.toLowerCase().trim(); }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (search) {
            _this.updateFilteredSectionsForSearch(search);
        });
    };
    ActionTypeDropdownComponent.prototype.ngOnDestroy = function () { };
    ActionTypeDropdownComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.value) {
            this.activeSection = isSet(this.value)
                ? this.sections.find(function (section) { return section.items.some(function (item) { return item.value == _this.value; }); })
                : undefined;
        }
        if (changes.typesOnly || changes.actionOriginEnabled) {
            this.updateSections();
            this.updateFilteredSections();
        }
        if (changes.opened) {
            if (this.disableInteractionsSubscription) {
                this.disableInteractionsSubscription.unsubscribe();
                this.disableInteractionsSubscription = undefined;
            }
        }
        if (changes.opened && this.opened) {
            this.disableInteractions = true;
            this.disableInteractionsSubscription = timer(120)
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.disableInteractions = false;
                _this.cd.markForCheck();
            });
        }
    };
    ActionTypeDropdownComponent.prototype.ngAfterViewInit = function () {
        this.setDropdownPositionObserver();
    };
    ActionTypeDropdownComponent.prototype.updateSections = function () {
        var _this = this;
        this.sections = actionTypeOptions
            .map(function (section) {
            return __assign({}, section, { items: section.items.filter(function (item) {
                    if (item.originRequired && !_this.actionOriginEnabled) {
                        return false;
                    }
                    else if (_this.typesOnly) {
                        return _this.typesOnly.includes(item.value);
                    }
                    else {
                        return true;
                    }
                }) });
        })
            .filter(function (section) { return section.items.length; });
    };
    ActionTypeDropdownComponent.prototype.updateFilteredSectionsForSearch = function (search) {
        this.filteredSections = isSet(search)
            ? this.sections
                .map(function (section) {
                return __assign({}, section, { items: section.items.filter(function (item) { return item.name.toLowerCase().includes(search); }) });
            })
                .filter(function (section) { return section.items.length; })
            : undefined;
        this.cd.markForCheck();
    };
    ActionTypeDropdownComponent.prototype.updateFilteredSections = function () {
        var search = this.searchControl.value.toLowerCase().trim();
        this.updateFilteredSectionsForSearch(search);
    };
    ActionTypeDropdownComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
    };
    ActionTypeDropdownComponent.prototype.setHoverSection = function (section, origin) {
        this.hoverSection = { section: section, origin: origin, hover$: new BehaviorSubject(false) };
        this.cd.markForCheck();
    };
    ActionTypeDropdownComponent.prototype.resetHoverSection = function () {
        this.hoverSection = undefined;
        this.cd.markForCheck();
        if (this.hoverSubscription) {
            this.hoverSubscription.unsubscribe();
            this.hoverSubscription = undefined;
        }
    };
    ActionTypeDropdownComponent.prototype.onSectionHover = function (section, origin) {
        var _this = this;
        if (section.disableNested) {
            return;
        }
        if (this.hoverSection) {
            this.resetHoverSection();
        }
        this.setHoverSection(section, origin);
        var originHover$ = merge(of(true), fromEvent(origin.elementRef.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(origin.elementRef.nativeElement, 'mouseleave').pipe(map(function () { return false; })));
        this.hoverSubscription = combineLatest(originHover$.pipe(debounce(function (hover) { return timer(hover ? 0 : 100); })), this.hoverSection.hover$)
            .pipe(map(function (_a) {
            var originHover = _a[0], hoverSectionHover = _a[1];
            return originHover || hoverSectionHover;
        }), untilDestroyed(this))
            .subscribe(function (hover) {
            if (!hover) {
                _this.resetHoverSection();
            }
        });
    };
    ActionTypeDropdownComponent.prototype.onSectionClick = function (section) {
        if (section.disableNested) {
            this.select.emit(section.items[0].value);
        }
    };
    ActionTypeDropdownComponent.prototype.setDropdownPositionObserver = function () {
        var _this = this;
        if (this.dropdownPositionsSubscription) {
            this.dropdownPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.dropdownPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.dropdownPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.dropdownPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return ActionTypeDropdownComponent;
}());
export { ActionTypeDropdownComponent };

<div class="publish-menu overlay-content overlay-content_animated theme_dark">
  <div class="publish-menu-section">
    <div class="publish-menu-section__content">
      <div class="publish-menu__item">
        <div class="publish-menu-card-item publish-menu-card-item_dark2">
          <div class="publish-menu-card-item__left">
            <span class="publish-menu-card-item__icon publish-menu-card-item__icon_red icon-warning_filled"></span>
          </div>

          <div class="publish-menu-card-item__main">
            <div class="publish-menu-card-item__title publish-menu-card-item__title_bright">
              Failed to save changes
            </div>
            <div class="publish-menu-card-item__subtitle">
              {{ saveError }}
            </div>
          </div>

          <div class="publish-menu-card-item__right publish-menu-card-item__actions">
            <div
              class="publish-menu-button publish-menu-button_yellow publish-menu-card-item__action"
              (click)="saveChanges.emit()"
            >
              <span class="publish-menu-button__icon"></span>
              <span class="publish-menu-button__label">Save again</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

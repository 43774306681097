import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ActionOutputControl, ParameterControl } from '@modules/fields';
import { isSet } from '@shared';

@Component({
  selector: 'app-action-outputs-edit-item',
  templateUrl: './action-outputs-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionOutputsEditItemComponent implements OnInit {
  @Input() control: ActionOutputControl;
  @Input() parametersEnabled = false;
  @Output() delete = new EventEmitter<void>();

  hovered$ = new BehaviorSubject<boolean>(false);
  iconPopoverOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  setIconPopoverOpened(value: boolean) {
    this.iconPopoverOpened = value;
    this.cd.markForCheck();
  }

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.control.controls.parameters.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addParameter() {
    const name = this.getDistinctName('param');
    this.control.controls.parameters.appendControl(undefined, {
      name: name
    });
  }

  deleteParameter(control: ParameterControl) {
    this.control.controls.parameters.removeControl(control);
  }

  dragDrop(event: CdkDragDrop<ParameterControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls.parameters.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}

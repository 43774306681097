import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Provider } from '@angular/core';

import { ACTION_SERVICE_ACTION_MENU_COMPONENT, ActionServiceActionMenuComponent } from '@modules/action-queries';

export const ACTION_SERVICE_ACTION_MENU_COMPONENT_PROVIDER: Provider = {
  provide: ACTION_SERVICE_ACTION_MENU_COMPONENT,
  useFactory: actionServiceActionMenuComponentFactory
};

export function actionServiceActionMenuComponentFactory(): any {
  return ActionMenuComponent;
}

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent extends ActionServiceActionMenuComponent implements OnInit, OnDestroy {
  constructor(private overlayRef: OverlayRef) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  close() {
    this.overlayRef.dispose();
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LocationStrategy } from '@angular/common';
import { OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { AdminMode } from '@modules/admin-mode';
import { RoutingService } from '../../services/routing/routing.service';
var AppLinkDirective = /** @class */ (function (_super) {
    __extends(AppLinkDirective, _super);
    function AppLinkDirective(routingService, router, route, locationStrategy) {
        var _this = _super.call(this, router, route, locationStrategy) || this;
        _this.routingService = routingService;
        return _this;
    }
    AppLinkDirective.prototype.ngOnInit = function () { };
    AppLinkDirective.prototype.ngOnChanges = function (changes) {
        if (changes['appLink']) {
            this.updateLink();
        }
        _super.prototype.ngOnChanges.call(this, changes);
    };
    AppLinkDirective.prototype.updateLink = function () {
        this.routerLink = this.routingService.appLink(this.appLink, {
            projectName: this.appLinkProject,
            environmentName: this.appLinkEnvironment,
            modeName: this.appLinkMode
        });
        this['updateTargetUrlAndHref']();
    };
    return AppLinkDirective;
}(RouterLinkWithHref));
export { AppLinkDirective };

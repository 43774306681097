import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContextElement, ViewContextOutput } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { BorderPosition, EllipseLayer, FillType, Frame, LayerInteractionType, LayerType } from '@modules/views';
import { isSet } from '@shared';

import { registerLayerComponent } from '../../../data/layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';

interface FillItem {
  id: string;
  background?: SafeStyle;
  width?: string;
  height?: string;
  transform?: SafeStyle;
  icon?: {
    icon: string;
    color?: string;
    size: number;
  };
  opacity?: number;
  enabled: boolean;
}

interface BorderItem {
  border: SafeStyle;
  position: number;
  enabled: boolean;
}

@Component({
  selector: 'app-ellipse-layer',
  templateUrl: './ellipse-layer.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EllipseLayerComponent extends LayerComponent<EllipseLayer> implements OnInit, OnDestroy {
  hover$: Observable<boolean>;
  customizing$: Observable<boolean>;
  customizingMultiple$: Observable<boolean>;
  fills: FillItem[] = [];
  fillsSubscription: Subscription;
  borders: BorderItem[] = [];
  bordersSubscription: Subscription;
  boxShadow: SafeStyle;
  boxShadowSubscription: Subscription;

  trackFillFn(i, item: FillItem) {
    return item.id;
  }

  constructor(
    @Optional() editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super(editorContext);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.editorContext) {
      this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
      this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
      this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
      this.updating$ = this.getLayerUpdating$(() => !this.editorContext.isCreateTool());
    }

    this.getLayer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateFills();
        this.updateBorders();
        this.updateBoxShadows();
        this.updateLayerContext();
      });
  }

  ngOnDestroy(): void {}

  updateFills() {
    if (this.fillsSubscription) {
      this.fillsSubscription.unsubscribe();
      this.fillsSubscription = undefined;
    }

    const fills$ = [...this.layer.fills]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const icon$ =
          item.type == FillType.Icon && item.iconFill
            ? item.iconFill.display$({ context: this.viewContext }).pipe(
                map(value => {
                  return {
                    icon: value.icon,
                    color: value.color,
                    size: isSet(item.iconFill.size)
                      ? item.iconFill.size
                      : Math.min(this.layer.frame.width, this.layer.frame.height)
                  };
                })
              )
            : of(undefined);
        const css$ = item.css$({ frame: this.layer.frame, context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(icon$, css$, enabled$).pipe(
          map(([icon, css, enabled]) => {
            return {
              id: item.id,
              background: isSet(css.background) ? this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
              width: css.width,
              height: css.height,
              transform: isSet(css.transform) ? this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
              icon: icon,
              opacity: item.type != FillType.Color ? item.opacity : null,
              enabled: enabled
            };
          })
        );
      });

    if (!fills$.length) {
      this.fills = [];
      this.cd.markForCheck();
      return;
    }

    this.fillsSubscription = combineLatest(fills$)
      .pipe(untilDestroyed(this))
      .subscribe(fills => {
        this.fills = fills.filter(item => item.enabled);
        this.cd.markForCheck();
      });
  }

  updateBorders() {
    if (this.bordersSubscription) {
      this.bordersSubscription.unsubscribe();
      this.bordersSubscription = undefined;
    }

    const borders$ = [...this.layer.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const border$ = item.cssBorder$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(border$, enabled$).pipe(
          map(([border, enabled]) => {
            let position: number;

            if (item.position == BorderPosition.Center) {
              position = -item.thickness * 0.5;
            } else if (item.position == BorderPosition.Outside) {
              position = -item.thickness;
            } else {
              position = 0;
            }

            return {
              border: isSet(border) ? this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
              position: position,
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.borders = [];
      this.cd.markForCheck();
      return;
    }

    this.bordersSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.borders = borders.filter(item => item.enabled && isSet(item.border));
        this.cd.markForCheck();
      });
  }

  updateBoxShadows() {
    if (this.boxShadowSubscription) {
      this.boxShadowSubscription.unsubscribe();
      this.boxShadowSubscription = undefined;
    }

    const shadows$ = this.layer.shadows
      .filter(item => item.enabled)
      .map(item => {
        const boxShadow$ = item.cssBoxShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(boxShadow$, enabled$).pipe(
          map(([boxShadow, enabled]) => {
            return {
              boxShadow: boxShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.boxShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.boxShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.boxShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.boxShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateLayerContext() {
    const hoverOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: this.layer.id,
        name: this.layer.name,
        icon: this.layer.icon
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: this.layer.name,
          icon: this.layer.icon
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  onFrameUpdate(frame: Partial<Frame>) {
    super.onFrameUpdate(frame);
    this.updateFills();
  }
}

registerLayerComponent(LayerType.Ellipse, EllipseLayerComponent);

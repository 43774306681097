import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ActionOutput } from '../data/action-output';
import { ParameterArray } from './parameter.array';

export class ActionOutputControl extends FormGroup {
  instance: ActionOutput;
  controls: {
    name: FormControl;
    verboseName: FormControl;
    icon: FormControl;
    parameters: ParameterArray;
  };

  constructor() {
    super({
      name: new FormControl(''),
      verboseName: new FormControl(''),
      icon: new FormControl(''),
      parameters: new ParameterArray([])
    });
  }

  deserialize(instance: ActionOutput) {
    this.instance = instance;

    this.controls.name.patchValue(instance.name);
    this.controls.verboseName.patchValue(instance.verboseName);
    this.controls.icon.patchValue(instance.icon);
    this.controls.parameters.patchValue(instance.parameters);

    this.markAsPristine();
  }

  serialize(): ActionOutput {
    const result = this.instance ? cloneDeep(this.instance) : new ActionOutput();

    result.name = this.controls.name.value;
    result.verboseName = this.controls.verboseName.value;
    result.icon = this.controls.icon.value;
    result.parameters = this.controls.parameters.value;

    return result;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import clamp from 'lodash/clamp';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CustomViewsStore } from '@modules/custom-views';
import { CustomizeService, getModelAttributesByColumns, getModelBulkAttributesByColumns, ListDefaultSelection, rawListViewSettingsColumnsToViewContextOutputs, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { CHECKED_ITEMS_OUTPUT, ColumnsModelListStore, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, NO_SELECTED_ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ListLayoutComponent, serializeDataSourceColumns } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { getWindowScrollTop, isSet } from '@shared';
function getListStateFetch(state) {
    return {
        dataSource: state.dataSource
            ? __assign({}, state.dataSource.serialize(), { columns: serializeDataSourceColumns(state.dataSource.columns) }) : undefined,
        dataSourceStaticData: state.dataSourceStaticData,
        dataSourceParams: state.dataSourceParams,
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        sort: state.sort,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet,
        dateField: state.dateField,
        perPage: state.perPage,
        sortingField: state.sortingField,
        sortingAsc: state.sortingAsc
    };
}
function getListStateFetchNewParams(state) {
    return {
        params: pickBy(state.dataSourceParams, function (v, k) { return PAGE_PARAM != k; }),
        filters: state.filters ? state.filters.map(function (item) { return item.serialize(); }) : [],
        search: state.search,
        sort: state.sort
    };
}
function getListStateColumns(state) {
    return {
        columns: state.dataSource ? state.dataSource.columns : undefined
    };
}
function getListStateSelection(state) {
    return {
        multipleSelection: state.multipleSelection
    };
}
function getListStateDefaultSelection(state) {
    return {
        defaultSelection: state.defaultSelection
    };
}
var TimelineComponent = /** @class */ (function (_super) {
    __extends(TimelineComponent, _super);
    function TimelineComponent(listStore, customizeService, customViewsStore, actionControllerService, notificationService, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        var _this = _super.call(this, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) || this;
        _this.listStore = listStore;
        _this.customizeService = customizeService;
        _this.customViewsStore = customViewsStore;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.layout = ListLayoutType.Timeline;
        _this.visibleColumns = [];
        _this.displayPage = 1;
        _this.loading = true;
        _this.loadingItems = 4;
        _this.configured = true;
        _this.checkedItems = {};
        _this.scrolled = false;
        return _this;
    }
    TimelineComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initDataUpdates();
        this.initContext();
    };
    TimelineComponent.prototype.ngOnDestroy = function () { };
    TimelineComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
    };
    TimelineComponent.prototype.getListState = function (settings, params, filters, search, sort) {
        var _this = this;
        var page = parseInt(params[PAGE_PARAM], 10) || 1;
        params = cloneDeep(params);
        delete params[NEXT_PAGE_SCROLL_PARAM];
        if (!sort.length && isSet(settings.sortingField)) {
            sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
        }
        var staticData$ = settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
            ? applyParamInput$(settings.dataSource.input, {
                context: this.context,
                defaultValue: [],
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of([]);
        var inputParams$ = settings.dataSource
            ? applyParamInputs$({}, settings.dataSource.queryInputs, {
                context: this.context,
                parameters: settings.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(map(function (_a) {
            var staticData = _a[0], inputParams = _a[1], modelDescription = _a[2];
            var resource = settings.dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == settings.dataSource.queryResource; })
                : undefined;
            return {
                settings: settings,
                dataSource: settings.dataSource,
                dataSourceStaticData: staticData,
                dataSourceParams: __assign({}, inputParams, params),
                userParams: params,
                filters: filters,
                search: search,
                sort: sort,
                resource: resource,
                modelDescription: modelDescription,
                inputsLoading: [inputParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [inputParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                }),
                dateField: settings ? settings.dateField : undefined,
                page: page,
                loadedPage: _this.listStore.fromPage <= page && page <= _this.listStore.currentPage,
                perPage: settings ? settings.perPage : undefined,
                sortingField: settings ? settings.sortingField : undefined,
                sortingAsc: settings ? settings.sortingAsc : undefined,
                multipleSelection: settings.multipleSelection,
                defaultSelection: settings ? settings.defaultSelection : undefined
            };
        }));
    };
    TimelineComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
            !isEqual(getListStateSelection(state), getListStateSelection(this.listState))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
            this.updateVisibleColumns(state);
        }
        if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
            var newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
            var paramsNeedUpdate = false;
            if (newParams) {
                this.setSelectedItem(undefined, false);
                this.setChecked([], false);
                this.updateSelectedContext();
                if (this.setPage(1)) {
                    paramsNeedUpdate = true;
                }
            }
            if (!paramsNeedUpdate) {
                this.fetch(state);
            }
        }
        else {
            if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
                if (this.listStore.dataSource) {
                    this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
                    this.listStore.deserializeModelAttributes();
                }
            }
            if (!isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
                state.defaultSelection == ListDefaultSelection.First) {
                var firstItem = this.items ? this.items[0] : undefined;
                this.setSelectedItem(firstItem, false);
                this.setChecked(firstItem ? [firstItem] : [], false);
                this.updateSelectedContext();
            }
        }
    };
    TimelineComponent.prototype.fetch = function (state) {
        var _this = this;
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = undefined;
        }
        this.configured =
            state.dataSource && state.dataSource.isConfigured() && state.settings && state.settings.isConfigured();
        this.parameters = this.getParameters(state);
        this.inputs = this.getInputs(state);
        this.cd.markForCheck();
        this.contextElement.patchOutputValueMeta(EMPTY_OUTPUT, { loading: true });
        if (!this.configured) {
            this.listStore.dataSource = undefined;
            this.listStore.params = {};
            this.listStore.queryOptions = undefined;
            this.loading = false;
            this.cd.markForCheck();
            this.listStore.reset();
            return;
        }
        if (state.inputsNotSet) {
            this.items = undefined;
            this.loading = false;
            this.error = undefined;
            this.cd.markForCheck();
            this.listStore.reset();
            return;
        }
        if (isSet(this.items) && this.items.length) {
            this.loadingItems = this.items.length;
        }
        this.items = undefined;
        this.loading = true;
        this.error = undefined;
        this.heightBeforeLoading = this.timelineElement
            ? this.timelineElement.nativeElement.getBoundingClientRect().height
            : undefined;
        this.cd.markForCheck();
        if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
        }
        if (state.inputsLoading) {
            this.listStore.reset();
            return;
        }
        var queryOptions = paramsToGetQueryOptions(state.dataSourceParams);
        queryOptions.filters = queryOptions.filters.concat(state.filters);
        queryOptions.search = state.search;
        queryOptions.sort = state.sort;
        this.listStore.dataSource = state.dataSource;
        this.listStore.useDataSourceColumns = true;
        this.listStore.staticData = state.dataSourceStaticData;
        this.listStore.queryOptions = queryOptions;
        this.listStore.context = this.context;
        this.listStore.contextElement = this.contextElement;
        this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;
        this.listStore.reset(state.page);
        this.cd.detectChanges();
        this.fetchSubscription = this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.items = result;
            _this.scrolled = false;
            _this.cd.markForCheck();
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
                if (_this.settings.defaultSelection == ListDefaultSelection.First && state.page == 1) {
                    var firstItem = _this.items ? _this.items[0] : undefined;
                    _this.setSelectedItem(firstItem, false);
                    _this.setChecked(firstItem ? [firstItem] : [], false);
                    _this.updateSelectedContext();
                }
            }
            else if (_this.selectedItem) {
                _this.checkSelectedModelIsActual();
            }
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else if (isPlainObject(error)) {
                _this.error = JSON.stringify(error);
            }
            else if (error.hasOwnProperty('message')) {
                console.error(error);
                _this.error = error.message;
            }
            else {
                console.error(error);
                _this.error = error;
            }
            _this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });
            if (_this.settings.defaultSelection && !_this.selectedItem) {
                _this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
            }
            _this.cd.markForCheck();
        });
    };
    TimelineComponent.prototype.initDataUpdates = function () {
        var _this = this;
        this.listStore.items$.pipe(untilDestroyed(this)).subscribe(function (items) {
            _this.items = items;
            _this.itemsMain = items ? items.slice(0, items.length - 1) : undefined;
            _this.itemsLast = items ? items[items.length - 1] : undefined;
            _this.cd.markForCheck();
        });
        this.listStore.loading$
            .pipe(skip(1))
            .pipe(untilDestroyed(this))
            .subscribe(function (loading) {
            _this.loading = loading;
            _this.cd.markForCheck();
            _this.initScroll();
        });
    };
    TimelineComponent.prototype.initScroll = function () {
        var _this = this;
        if (this.scrollableSubscription) {
            this.scrollableSubscription.unsubscribe();
        }
        this.scrollableSubscription = fromEvent(window, 'scroll', { passive: true })
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.onScroll(); });
    };
    TimelineComponent.prototype.onScroll = function () {
        if (!this.scrollable) {
            return;
        }
        var scrollTop = getWindowScrollTop();
        var scrolled = scrollTop > 0;
        if (scrolled != this.scrolled) {
            this.scrolled = scrolled;
            this.cd.markForCheck();
        }
        if (this.listStore.items == undefined) {
            return;
        }
        var viewportHeight = window.innerHeight;
        var contentHeight = document.body.offsetHeight;
        var viewportBottom = scrollTop + viewportHeight;
        if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
            this.onScrollFinished();
        }
    };
    TimelineComponent.prototype.onScrollFinished = function () {
        this.listStore.getNext().pipe(untilDestroyed(this)).subscribe();
    };
    TimelineComponent.prototype.trackByFn = function (i, item) {
        return item.model.primaryKey || i;
    };
    TimelineComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    TimelineComponent.prototype.initContext = function () {
        var _this = this;
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_selected_item',
                name: 'Reset Selected Card',
                icon: 'deselect',
                parameters: [],
                handler: function () {
                    _this.setSelectedItem(undefined);
                    _this.setChecked([]);
                }
            },
            {
                uniqueName: 'clear_filters',
                name: 'Reset Filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.resetFilters(); }
            }
        ]);
    };
    TimelineComponent.prototype.updateContextOutputs = function (state) {
        var columns = state.dataSource ? state.dataSource.columns : [];
        this.contextElement.setOutputs([
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Card',
                icon: 'duplicate_2',
                internal: true,
                byPathOnly: true,
                allowSkip: true,
                children: rawListViewSettingsColumnsToViewContextOutputs(columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            },
            {
                uniqueName: SELECTED_ITEM_OUTPUT,
                name: 'Selected Card',
                icon: 'hand',
                children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            },
            {
                uniqueName: HAS_SELECTED_ITEM_OUTPUT,
                name: 'Is any Card selected',
                icon: 'select_all',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: NO_SELECTED_ITEM_OUTPUT,
                name: 'No Card selected',
                icon: 'deselect',
                fieldType: FieldType.Boolean,
                defaultValue: true
            },
            {
                uniqueName: EMPTY_OUTPUT,
                name: 'Is Empty',
                icon: 'uncheck',
                fieldType: FieldType.Boolean,
                defaultValue: false
            }
        ].concat((state.multipleSelection
            ? [
                {
                    uniqueName: CHECKED_ITEMS_OUTPUT,
                    name: 'Checked Cards',
                    icon: 'check',
                    children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
                }
            ]
            : [])));
        this.updateSelectedContext();
    };
    TimelineComponent.prototype.updateSelectedContext = function () {
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        if (this.selectedItem) {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(this.selectedItem.model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
        if (this.settings.multipleSelection) {
            var models = values(this.checkedItems);
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
        }
        else {
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
        }
    };
    TimelineComponent.prototype.isItemSelected = function (item, index) {
        if (this.settings.multipleSelection) {
            return this.isItemChecked(item, index);
        }
        else {
            return this.itemEquals(this.selectedItem, item);
        }
    };
    TimelineComponent.prototype.isItemChecked = function (item, index) {
        var pk = item.model.primaryKey || "" + index;
        return this.checkedItems[pk];
    };
    TimelineComponent.prototype.setSelectedItem = function (item, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.selectedItem = item;
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    TimelineComponent.prototype.setChecked = function (value, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.checkedItems = value.reduce(function (acc, item) {
            var pk = item.model.primaryKey;
            acc[pk] = item.model;
            return acc;
        }, {});
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    TimelineComponent.prototype.toggleSelectedItem = function (item, index, element, click) {
        if (click === void 0) { click = false; }
        var _a;
        if (this.selectedItem === item) {
            this.setSelectedItem(undefined, false);
        }
        else {
            this.setSelectedItem(item, false);
        }
        if (this.settings.multipleSelection) {
            var pk = item.model.primaryKey || index;
            var checked = this.isItemChecked(item, index);
            if (!checked) {
                var checkedItems = clone(this.checkedItems);
                checkedItems[pk] = item.model;
                this.checkedItems = checkedItems;
            }
            else {
                var checkedItems = clone(this.checkedItems);
                delete checkedItems[pk];
                this.checkedItems = checkedItems;
            }
        }
        this.updateSelectedContext();
        if (click && this.settings.cardClickAction && this.selectedItem) {
            this.actionControllerService
                .execute(this.settings.cardClickAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ITEM_OUTPUT] = this.selectedItem.model.getAttributes(),
                    _a),
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    TimelineComponent.prototype.onModelUpdated = function (model) {
        if (this.selectedItem && this.selectedItem.model.isSame(model)) {
            this.updateSelectedContext();
        }
        var checkedModels = values(this.checkedItems);
        if (checkedModels.some(function (item) { return item.isSame(model); })) {
            this.updateSelectedContext();
        }
    };
    TimelineComponent.prototype.getAnyModel = function () {
        if (!this.listStore.items || !this.listStore.items.length) {
            return;
        }
        return this.listStore.items[0].model;
    };
    TimelineComponent.prototype.checkSelectedModelIsActual = function () {
        var _this = this;
        var actualSelectedItem = this.items.find(function (item) { return item.model.isSame(_this.selectedItem.model); });
        if (actualSelectedItem &&
            !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())) {
            this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
            this.cd.markForCheck();
            this.onModelUpdated(this.selectedItem.model);
        }
    };
    return TimelineComponent;
}(ListLayoutComponent));
export { TimelineComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./fixed-container.component";
import * as i2 from "../../services/fixed/fixed.service";
var styles_FixedContainerComponent = [];
var RenderType_FixedContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FixedContainerComponent, data: {} });
export { RenderType_FixedContainerComponent as RenderType_FixedContainerComponent };
export function View_FixedContainerComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_FixedContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-fixed-container", [], null, null, null, View_FixedContainerComponent_0, RenderType_FixedContainerComponent)), i0.ɵdid(1, 114688, null, 0, i1.FixedContainerComponent, [i2.FixedService, i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FixedContainerComponentNgFactory = i0.ɵccf("app-fixed-container", i1.FixedContainerComponent, View_FixedContainerComponent_Host_0, {}, {}, []);
export { FixedContainerComponentNgFactory as FixedContainerComponentNgFactory };

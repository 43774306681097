import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, merge, of } from 'rxjs';
import { debounceTime, filter, map, publishLast, refCount, shareReplay, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { UserActivityService } from '../user-activity/user-activity.service';
import * as i0 from "@angular/core";
import * as i1 from "../user-activity/user-activity.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../api/services/api/api.service";
var UserActivityCountService = /** @class */ (function () {
    function UserActivityCountService(userActivityService, http, apiService) {
        this.userActivityService = userActivityService;
        this.http = http;
        this.apiService = apiService;
        this.countObjectIdQueue = {};
        this.countObjectIdQueueDebounce = 200;
    }
    UserActivityCountService.prototype.countObjectIds = function (projectName, environmentName, activityType, objectType, objectIds) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/count/');
            var headers = new HttpHeaders();
            var data = {
                activity_type: activityType,
                object_type: objectType,
                object_ids: objectIds
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    UserActivityCountService.prototype.countObjectId = function (projectName, environmentName, activityType, objectType, objectId) {
        var _this = this;
        var key = [projectName, environmentName, activityType, objectType].join('_');
        if (!this.countObjectIdQueue[key]) {
            var objectIds = new BehaviorSubject([objectId]);
            var objectIdsRequest = objectIds.pipe(debounceTime(this.countObjectIdQueueDebounce), switchMap(function (value) {
                _this.countObjectIdQueue[key] = undefined;
                return _this.countObjectIds(projectName, environmentName, activityType, objectType, value);
            }), shareReplay());
            this.countObjectIdQueue[key] = {
                objectIds: objectIds,
                response: objectIdsRequest
            };
        }
        else {
            var objectIds = this.countObjectIdQueue[key].objectIds;
            if (!objectIds.value.includes(objectId)) {
                objectIds.next(objectIds.value.concat([objectId]));
            }
        }
        return this.countObjectIdQueue[key].response.pipe(map(function (result) {
            var count = result.result.find(function (item) { return item.object_id == objectId; });
            return count ? count.count : 0;
        }));
    };
    UserActivityCountService.prototype.subscribeCountObjectId = function (projectName, environmentName, activityType, objectType, objectId) {
        var _this = this;
        return this.countObjectId(projectName, environmentName, activityType, objectType, objectId).pipe(switchMap(function (count) {
            return merge(of(count), _this.userActivityService.subscribeUserActivities(projectName, environmentName, objectType, objectId).pipe(filter(function (item) { return item.activityType == activityType; }), map(function () { return ++count; })));
        }));
    };
    UserActivityCountService.ngInjectableDef = i0.defineInjectable({ factory: function UserActivityCountService_Factory() { return new UserActivityCountService(i0.inject(i1.UserActivityService), i0.inject(i2.HttpClient), i0.inject(i3.ApiService)); }, token: UserActivityCountService, providedIn: "root" });
    return UserActivityCountService;
}());
export { UserActivityCountService };

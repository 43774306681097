import { OnDestroy } from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var CustomizeBarContext = /** @class */ (function () {
    function CustomizeBarContext(customizeService, currentProjectStore, currentEnvironmentStore) {
        var _this = this;
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this._settingsComponents = new BehaviorSubject([]);
        this._highlighted = new Subject();
        this.customizeService.enabled$
            .pipe(filter(function (enabled) { return !enabled; }), untilDestroyed(this))
            .subscribe(function () {
            _this.resetSettingsComponent();
        });
    }
    CustomizeBarContext.prototype.ngOnDestroy = function () { };
    CustomizeBarContext.prototype.setSettingsComponent = function (component, options) {
        // const lastComponent = this.settingsComponent;
        //
        // if (isEqual(
        //   lastComponent ? [lastComponent.component, lastComponent.inputs] : undefined,
        //   [component.component, component.inputs]
        // )) {
        //   return;
        // }
        if (options === void 0) { options = {}; }
        if (options.append) {
            this.appendSettingsComponent(component);
        }
        else {
            this._settingsComponents.next([component]);
        }
    };
    CustomizeBarContext.prototype.appendSettingsComponent = function (component) {
        var value = this._settingsComponents.value.concat([component]);
        this._settingsComponents.next(value);
    };
    CustomizeBarContext.prototype.popSettingsComponent = function () {
        var value = this._settingsComponents.value;
        value.pop();
        this._settingsComponents.next(value);
    };
    CustomizeBarContext.prototype.resetSettingsComponent = function () {
        this._settingsComponents.next([]);
    };
    CustomizeBarContext.prototype.closeSettingsComponent = function (component) {
        var value = this._settingsComponents.value;
        var index = value.indexOf(component);
        if (index == -1) {
            return;
        }
        this._settingsComponents.next(value.slice(0, index));
    };
    Object.defineProperty(CustomizeBarContext.prototype, "settingsComponents$", {
        get: function () {
            return this._settingsComponents.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeBarContext.prototype, "settingsComponents", {
        get: function () {
            return this._settingsComponents.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeBarContext.prototype, "settingsComponent", {
        get: function () {
            return last(this._settingsComponents.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeBarContext.prototype, "settingsComponent$", {
        get: function () {
            return this._settingsComponents.pipe(map(function (item) { return last(item); }));
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarContext.prototype.highlight = function () {
        this._highlighted.next();
    };
    Object.defineProperty(CustomizeBarContext.prototype, "highlighted$", {
        get: function () {
            return this._highlighted.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return CustomizeBarContext;
}());
export { CustomizeBarContext };

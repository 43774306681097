import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { CodeFieldComponent } from '@modules/field-components';
import { AutoFieldComponent, createFormFieldFactory, getJavaScriptSyntaxError } from '@modules/fields';
import { contextToFormulaValue, transformFormulaElementAccessors } from '@modules/parameters';
import { FormulaInsert, FormulaToken, ViewContextTokenPopoverOverlayComponent } from '@modules/parameters-components';
import { controlValue, isSet } from '@shared';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-run-javascript',
  templateUrl: './customize-bar-action-edit-type-run-javascript.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeRunJavaScriptComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  @ViewChild('js_field') jsField: AutoFieldComponent;
  @ViewChild(ViewContextTokenPopoverOverlayComponent)
  tokenPopoverOverlayComponent: ViewContextTokenPopoverOverlayComponent;

  createField = createFormFieldFactory();
  jsPlaceholder = `alert('Hello World');`;
  fillVertical = false;
  dark = false;
  darker = false;
  fill = true;
  classes: string[] = [];
  jsError: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.form.controls.run_javascript)
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(() => {
        this.updateInputErrors();
      });
  }

  ngOnDestroy(): void {}

  openTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.open();
    }
  }

  closeTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.close();
    }
  }

  isInputOverlayOpened() {
    return false;
  }

  updateInputErrors() {
    const value = this.form.controls.run_javascript.value;

    this.jsError = getJavaScriptSyntaxError(value);
    this.cd.markForCheck();
  }

  insertJsToken(item: FormulaInsert | FormulaToken) {
    if (!isSet(item.insert) && !isSet(item.formula)) {
      return;
    }

    if (
      this.jsField &&
      this.jsField.dynamicComponent.currentComponent &&
      this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent
    ) {
      const ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
      const selectionRange = ace.editor.selection.getRange();
      let insert: string;

      if (isSet(item.formula)) {
        insert = item.formula;
      } else {
        const formulaValue = contextToFormulaValue(item.insert);
        insert = transformFormulaElementAccessors(formulaValue, this.context, false);
      }

      const insertRow = selectionRange.start.row;
      const insertLast = isSet(item['label']) ? (item as FormulaToken).label : item.insert[item.insert.length - 1];
      const insertRowValue = ace.editor.session.getLine(insertRow);
      const varName = typeof insertLast == 'number' ? `item${insertLast}` : camelCase(String(insertLast));
      const prefixMatch = insertRowValue.match(/^([\s\t]+)/);
      const prefix = prefixMatch ? prefixMatch[1] : '';

      ace.editor.session.insert({ row: insertRow, column: 0 }, `${prefix}let ${varName} = ${insert};\n`);
    }
  }

  onJsTokenSelected(item: FormulaInsert | FormulaToken) {
    if (isSet(item.insert) || isSet(item.formula)) {
      this.insertJsToken(item);
    }
  }
}

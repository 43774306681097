/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./tabs-layout-navigation-item.component";
import * as i5 from "../../../customize/services/customize/customize.service";
var styles_TabsLayoutNavigationItemComponent = [];
var RenderType_TabsLayoutNavigationItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TabsLayoutNavigationItemComponent, data: {} });
export { RenderType_TabsLayoutNavigationItemComponent as RenderType_TabsLayoutNavigationItemComponent };
function View_TabsLayoutNavigationItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TabsLayoutNavigationItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No title"]))], null, null); }
function View_TabsLayoutNavigationItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "tabs2-navigation__item-help help-icon icon-help_filled"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tab.tooltip; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TabsLayoutNavigationItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "tabs2-navigation__item-indicator icon-eye"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Tab is hidden"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabsLayoutNavigationItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TabsLayoutNavigationItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TabsLayoutNavigationItemComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TabsLayoutNavigationItemComponent_3)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TabsLayoutNavigationItemComponent_4)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.title)); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.tab.tooltip)); _ck(_v, 8, 0, currVal_2); var currVal_3 = (!_co.isVisible && i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.customizeService.enabled$))); _ck(_v, 11, 0, currVal_3); }, null); }
export function View_TabsLayoutNavigationItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tabs-layout-navigation-item", [], [[2, "tabs2-navigation__item", null], [2, "tabs2-navigation__item_hidden", null], [2, "tabs2-navigation__item_active", null]], null, null, View_TabsLayoutNavigationItemComponent_0, RenderType_TabsLayoutNavigationItemComponent)), i0.ɵdid(1, 770048, null, 0, i4.TabsLayoutNavigationItemComponent, [i5.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).isHidden; var currVal_2 = i0.ɵnov(_v, 1).active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var TabsLayoutNavigationItemComponentNgFactory = i0.ɵccf("app-tabs-layout-navigation-item", i4.TabsLayoutNavigationItemComponent, View_TabsLayoutNavigationItemComponent_Host_0, { tab: "tab", context: "context", contextElement: "contextElement", isVisible: "isVisible", active: "active" }, {}, []);
export { TabsLayoutNavigationItemComponentNgFactory as TabsLayoutNavigationItemComponentNgFactory };

import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { RoutingService } from '@modules/routing';
import { AuthService } from '../services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
import * as i2 from "../../routing/services/routing/routing.service";
var IsAuthenticatedGuard = /** @class */ (function () {
    function IsAuthenticatedGuard(authService, routing) {
        this.authService = authService;
        this.routing = routing;
    }
    IsAuthenticatedGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var canActivate = new ReplaySubject();
        this.authService
            .isAuthorized()
            .pipe(first())
            .subscribe(function (authorized) { return canActivate.next(authorized); }, function () { return canActivate.next(false); });
        canActivate.subscribe(function (allow) {
            if (!allow) {
                _this.routing.navigateUnauthenticated(route);
            }
        });
        return canActivate;
    };
    IsAuthenticatedGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsAuthenticatedGuard_Factory() { return new IsAuthenticatedGuard(i0.inject(i1.AuthService), i0.inject(i2.RoutingService)); }, token: IsAuthenticatedGuard, providedIn: "root" });
    return IsAuthenticatedGuard;
}());
export { IsAuthenticatedGuard };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { quillDeltaToHtml$ } from '@common/text-editor';
import { AdminMode } from '@modules/admin-mode';
import { CustomizeService, CustomizeType, ElementType, registerElementComponent, TextElementItem } from '@modules/customize';
import { AutoElementComponent, BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { ThemeService } from '@modules/theme';
var TextElementComponent = /** @class */ (function (_super) {
    __extends(TextElementComponent, _super);
    function TextElementComponent(mode$, mode, customizeService, autoElementComponent, themeService, cd) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.mode = mode;
        _this.customizeService = customizeService;
        _this.autoElementComponent = autoElementComponent;
        _this.themeService = themeService;
        _this.cd = cd;
        _this.adminModes = AdminMode;
        return _this;
    }
    TextElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.updateHtml();
    };
    TextElementComponent.prototype.ngAfterViewInit = function () {
        this.setToolbarElement();
    };
    TextElementComponent.prototype.ngOnDestroy = function () { };
    TextElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element || changes.context) {
            this.updateHtml();
        }
    };
    Object.defineProperty(TextElementComponent.prototype, "isEditable", {
        get: function () {
            return this.customizeService.enabled == CustomizeType.Layout && this.elementActive;
        },
        enumerable: true,
        configurable: true
    });
    TextElementComponent.prototype.save = function (value) {
        this.element.quillDelta = value;
        this.updated.emit(cloneDeep(this.element));
    };
    TextElementComponent.prototype.updateHtml = function () {
        var _this = this;
        if (this.mode == AdminMode.App) {
            this.html$ = quillDeltaToHtml$(this.element.quillDelta, {
                applyContextFormula: function (input) { return applyParamInput(input, { context: _this.context, defaultValue: '' }); },
                addContextFormulaClass: true,
                context: this.context
            });
        }
        else {
            this.html$ = undefined;
        }
    };
    TextElementComponent.prototype.setToolbarElement = function () {
        var _this = this;
        if (this.mode != AdminMode.Builder) {
            return;
        }
        timer(0)
            .pipe(switchMap(function () {
            var toolbarComponent = _this.autoElementComponent.autoElementToolbarComponent;
            if (!toolbarComponent) {
                return of(undefined);
            }
            if (toolbarComponent.toolbarVcr) {
                return of(toolbarComponent.toolbarVcr);
            }
            else {
                return toolbarComponent.toolbarElementInit.asObservable();
            }
        }), untilDestroyed(this))
            .subscribe(function (toolbarElement) {
            if (toolbarElement) {
                _this.toolbarElement = toolbarElement;
                _this.cd.markForCheck();
            }
        });
    };
    return TextElementComponent;
}(BaseElementComponent));
export { TextElementComponent };
registerElementComponent({
    type: ElementType.Text,
    component: TextElementComponent,
    label: 'Text',
    actions: [],
    toolbar: true
});

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';

import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ORDER_BY_PARAM } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  Project,
  Resource,
  ResourceType
} from '@modules/projects';
import { ChartWidgetQuery, ListModelDescriptionQuery, QueryType, ValueWidgetQuery } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import {
  FirebaseResourceController,
  JetBridgeResourceController,
  ModelResponse,
  ResourceControllerService,
  RestApiResourceControllerService
} from '@modules/resources';

@Injectable()
export class WidgetQueryService {
  constructor(
    private modelService: ModelService,
    private modelDescriptionStore: ModelDescriptionStore,
    private queryTokensService: QueryTokensService,
    private resourceControllerService: ResourceControllerService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  public aggregate(
    project: Project,
    environment: Environment,
    modelId: string,
    func: AggregateFunc,
    column: string,
    params?: {}
  ): Observable<ModelResponse.GetResponse> {
    params = params || {};
    return this.modelService
      .getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getQuery')
      .pipe(
        switchMap(modelParams => {
          const [resource, modelDescription, controller] = modelParams;
          return controller.modelAggregate(resource, modelDescription, func, column, params);
        }),
        share()
      );
  }

  public aggregateQuery(
    resource: Resource,
    query: ValueWidgetQuery,
    func: AggregateFunc,
    column: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<any> {
    if (!resource || !query) {
      return of(undefined);
    }

    params = params || {};

    if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of(undefined);
          }

          const applyResult = applySegments(params, {}, modelDescription);

          applyResult.params = applyParamsComputedLookups(applyResult.params);

          const project = this.currentProjectStore.instance;
          const environment = this.currentEnvironmentStore.instance;

          return this.aggregate(project, environment, modelDescription.modelId, func, column, applyResult.params);
        })
      );
    } else if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.httpQuery = query.httpQuery;

      return controller.getHttpAggregate(resource, listQuery, func, column, parameters, params, columns);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      const controller = this.resourceControllerService.getForResource<JetBridgeResourceController>(resource, true);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.sqlQuery = query.sqlQuery;

      params = applyParamsComputedLookups(params);

      return controller.getSqlAggregate(resource, listQuery, func, column, parameters, params, columns);
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      const controller = this.resourceControllerService.get<FirebaseResourceController>(resource.type);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.objectQuery = query.objectQuery;

      return controller.getAggregateQueryObject(resource, listQuery, func, column, parameters, params, columns);
    } else {
      return of(undefined);
    }
  }

  public group(
    project: Project,
    environment: Environment,
    modelId: string,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]> {
    params = params || {};
    return this.modelService
      .getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getQuery')
      .pipe(
        switchMap(modelParams => {
          const [resource, modelDescription, controller] = modelParams;

          if (params[ORDER_BY_PARAM] === undefined && modelDescription.defaultOrderBy) {
            params[ORDER_BY_PARAM] = modelDescription.defaultOrderBy;
          }

          return controller.modelGroup(resource, modelDescription, xColumns, yFunc, yColumn, params);
        }),
        share()
      );
  }

  public groupQuery(
    resource: Resource,
    query: ChartWidgetQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<DataGroup[]> {
    if (!resource || !query) {
      return of(undefined);
    }

    params = params || {};

    if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of(undefined);
          }

          const applyResult = applySegments(params, {}, modelDescription);

          applyResult.params = applyParamsComputedLookups(applyResult.params);

          const project = this.currentProjectStore.instance;
          const environment = this.currentEnvironmentStore.instance;

          return this.group(
            project,
            environment,
            modelDescription.modelId,
            xColumns,
            yFunc,
            yColumn,
            applyResult.params
          );
        })
      );
    } else if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.httpQuery = query.httpQuery;

      return controller.getGroup(resource, listQuery, xColumns, yFunc, yColumn, parameters, params, columns);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      const controller = this.resourceControllerService.getForResource<JetBridgeResourceController>(resource, true);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.sqlQuery = query.sqlQuery;

      params = applyParamsComputedLookups(params);

      return controller.getSqlGroup(resource, listQuery, xColumns, yFunc, yColumn, parameters, params, columns);
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      const controller = this.resourceControllerService.get<FirebaseResourceController>(resource.type);
      const listQuery = new ListModelDescriptionQuery();

      listQuery.queryType = query.queryType;
      listQuery.objectQuery = query.objectQuery;

      return controller.getGroupQueryObject(resource, listQuery, xColumns, yFunc, yColumn, parameters, params, columns);
    } else {
      return of(undefined);
    }
  }
}

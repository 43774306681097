<ng-template let-trigger="trigger" #popup>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
    [cdkConnectedOverlayPositions]="[
      { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center' },
      { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top' },
      { originX: 'start', overlayX: 'end', originY: 'bottom', overlayY: 'bottom' }
    ]"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="setOpened(false); onCancel()"
    (detach)="setOpened(false)"
  >
    <div class="popover2">
      <div class="popover2__inner">
        <div class="token-popup__header">
          <div class="token-popup-header">
            <span class="token-popup-header__icon icon-plug"></span>
            <ng-container *ngIf="selectedItem">
              <ng-container *ngIf="selectedItem.selfParameters.length">Choose binding Field</ng-container>
              <ng-container *ngIf="selectedItem.targetContextValues.length">Choose binding Field</ng-container>
            </ng-container>
            <ng-container *ngIf="!selectedItem">Choose binding Component</ng-container>
          </div>
        </div>

        <div class="popover2__viewport popover2__viewport_scrollable">
          <div class="popover2__content" style="min-width: 300px;">
            <ng-container *ngIf="selectedItem">
              <div class="token-popup__item">
                <a
                  href="javascript:void(0)"
                  class="token-popup-list-item token-popup-list-item_orange"
                  (click)="setSelectedItem(undefined)"
                >
                  <div class="token-popup-list-item__inner token-popup-list-item__line">
                    <span class="token-popup-list-item__left"><span class="icon-arrow_backward_2"></span></span>
                    <span class="token-popup-list-item__main">Back</span>
                  </div>
                </a>

                <div
                  *ngFor="let item of selectedItem.targetContextValues"
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  (click)="
                    bindComponent(selectedItem.selfParameter, item.targetContextValue, selectedItem);
                    setOpened(false);
                    setSelectedItem(undefined)
                  "
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span *ngIf="item.icon" class="token-popup-list-item__left">
                        <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                      </span>
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.name">
                        {{ item.name }}
                      </span>
                      <span class="token-popup-list-item__right token-popup-list-item__right_wrap"></span>
                    </div>
                  </div>
                </div>

                <div
                  *ngFor="let item of selectedItem.selfParameters"
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  (click)="
                    bindComponent(item.selfParameter, selectedItem.targetContextValue, selectedItem);
                    setOpened(false);
                    setSelectedItem(undefined)
                  "
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span *ngIf="item.icon" class="token-popup-list-item__left">
                        <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                      </span>
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.name">
                        {{ item.name }}
                      </span>
                      <span class="token-popup-list-item__right token-popup-list-item__right_wrap"></span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!selectedItem">
              <div *ngFor="let section of sections" class="token-popup__item">
                <div class="token-popup-section">
                  <div *ngIf="section.name" class="token-popup-section__title">
                    <div class="token-popup-section__title-main">{{ section.name }}</div>
                  </div>

                  <div class="token-popup-section__content">
                    <ng-container *ngFor="let item of section.children">
                      <div
                        *ngIf="item.selfParameter && item.targetContextValue"
                        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                        (click)="bindComponent(item.selfParameter, item.targetContextValue, item); setOpened(false)"
                      >
                        <div class="token-popup-list-item__inner">
                          <div class="token-popup-list-item__line">
                            <span *ngIf="item.icon" class="token-popup-list-item__left">
                              <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                            </span>
                            <span
                              class="token-popup-list-item__main token-popup-list-item__main_wrap"
                              [title]="item.name"
                            >
                              {{ item.name }}
                            </span>
                            <span class="token-popup-list-item__right token-popup-list-item__right_wrap"></span>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (item.selfParameters.length && item.targetContextValue) ||
                          (item.selfParameter && item.targetContextValues.length)
                        "
                        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                        (click)="setSelectedItem(item)"
                      >
                        <div class="token-popup-list-item__inner">
                          <div class="token-popup-list-item__line">
                            <span *ngIf="item.icon" class="token-popup-list-item__left">
                              <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                            </span>
                            <span
                              class="token-popup-list-item__main token-popup-list-item__main_wrap"
                              [title]="item.name"
                            >
                              {{ item.name }}
                              <span class="token-popup-list-item__main-additional">
                                <ng-container *ngIf="item.selfParameters.length">choose field</ng-container>
                                <ng-container *ngIf="item.targetContextValues.length">choose field</ng-container>
                              </span>
                            </span>
                            <span class="token-popup-list-item__right token-popup-list-item__right_wrap">
                              <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="!item.targetContextValue && !item.targetContextValues.length"
                        class="token-popup-list-item"
                      >
                        <div class="token-popup-list-item__inner">
                          <div class="token-popup-list-item__line">
                            <span *ngIf="item.icon" class="token-popup-list-item__left">
                              <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                            </span>
                            <span
                              class="token-popup-list-item__main token-popup-list-item__main_wrap"
                              [title]="item.name"
                            >
                              <span class="token-popup-list-item__main-additional">
                                {{ item.name }}
                              </span>
                            </span>
                            <span class="token-popup-list-item__right token-popup-list-item__right_wrap">
                              (not configured)
                            </span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div *ngIf="!sections.length" class="token-popup__item">
                <div class="token-popup-list-item">
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__tip">
                      No component on the page to bind to.<br />
                      Drag-and-drop components to the page first
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="popover2__arrow"></div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-container *ngIf="inline">
  <ng-container *ngTemplateOutlet="popup; context: { trigger: trigger }"></ng-container>

  <span
    class="sidebar__field-action sidebar__field-action_button sidebar__field-action_button-blue icon-plug"
    [class.sidebar__field-action_active]="boundItem"
    [appTip]="boundItem ? 'Bound to ' + (boundItem.name | appCapitalize) : 'Bind to Component'"
    [appTipOptions]="{ side: 'left' }"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="toggleOpened(); onOpen()"
  ></span>
</ng-container>

<ng-container *ngIf="!inline">
  <ng-container *ngTemplateOutlet="popup; context: { trigger: trigger }"></ng-container>

  <div
    class="round-button round-button_fill"
    [class.round-button_active]="!hasInputs"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="toggleOpened(); onOpen()"
  >
    <div class="round-button__icon icon-plug"></div>
    <div class="round-button__label">
      <ng-container *ngIf="boundItem">Bound to {{ boundItem.name | appCapitalize }}</ng-container>
      <ng-container *ngIf="!boundItem">Bind to Component</ng-container>
    </div>
  </div>
</ng-container>

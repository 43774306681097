import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isPopupMouseEvent } from '@common/popups';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { generateAlphanumeric, isSet, TypedChanges } from '@shared';

import { FormulaSection } from '../../data/formula-section';
import { FormulaInsert } from '../../data/formula-token';
import { isReferencePopoverMouseEvent } from '../view-context-token-popover/view-context-token-popover.component';
import { viewContextTokenPopoverOverlayPositions } from './view-context-token-popover-overlay-positions';

const tokenPopoverMouseEvent = '_tokenPopoverMouseEvent';
const openOnFocusMouseEvent = '_openOnFocusMouseEvent';

export function markTokenPopoverMouseEvent(e: MouseEvent, id: string) {
  e[tokenPopoverMouseEvent] = id;
}

export function isTokenPopoverMouseEvent(e: MouseEvent, id: string) {
  return e[tokenPopoverMouseEvent] == id;
}

export function markOpenOnFocusMouseEvent(e: MouseEvent, id: string) {
  e[openOnFocusMouseEvent] = id;
}

export function isOpenOnFocusMouseEvent(e: MouseEvent, id: string) {
  return e[openOnFocusMouseEvent] == id;
}

@Component({
  selector: 'app-view-context-token-popover-overlay',
  templateUrl: './view-context-token-popover-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverOverlayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() origin: CdkOverlayOrigin;
  @Input() control: FormControl;
  @Input() openOnFocus: HTMLElement;
  @Input() forceOpened: boolean;
  @Input() maxHeight: number;
  @Input() extraSections: FormulaSection[] = [];
  @Input() hideSections: string[] = [];
  @Input() search = '';
  @Input() selectFunction: string;
  @Output() selected = new EventEmitter<FormulaInsert>();
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  @ViewChild('token_popover_overlay', { read: CdkConnectedOverlay }) tokenPopoverOverlay: CdkConnectedOverlay;

  id = generateAlphanumeric(8, { letterFirst: true });
  tokenPopoverOpened = false;
  tokenPopoverBlurSubscription: Subscription;
  openOnFocusSubscriptions: Subscription[] = [];
  popoverPositions = viewContextTokenPopoverOverlayPositions;

  markTokenPopoverMouseEvent = (e: MouseEvent) => markTokenPopoverMouseEvent(e, this.id);

  constructor(private focusMonitor: FocusMonitor, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ViewContextTokenPopoverOverlayComponent>): void {
    if (changes.openOnFocus) {
      this.initOpenOnFocus();
    } else if (changes.forceOpened && this.forceOpened === true) {
      this.open();
    } else if (changes.forceOpened && this.forceOpened === false) {
      this.close();
    }
  }

  open() {
    if (this.tokenPopoverOpened) {
      return;
    }

    this.tokenPopoverOpened = true;
    this.cd.detectChanges(); // TODO: Workaround for initial focus
    this.initTokenPopoverBlur();

    this.opened.emit();
  }

  close() {
    if (!this.tokenPopoverOpened) {
      return;
    }

    this.tokenPopoverOpened = false;
    this.cd.detectChanges();
    this.deinitTokenPopoverBlur();

    this.closed.emit();
  }

  initTokenPopoverBlur() {
    this.deinitTokenPopoverBlur();

    if (isSet(this.forceOpened)) {
      return;
    }

    this.tokenPopoverBlurSubscription = fromEvent<MouseEvent>(window, 'mousedown')
      .pipe(
        filter(e => {
          return (
            !isTokenPopoverMouseEvent(e, this.id) &&
            !isReferencePopoverMouseEvent(e, this.id) &&
            !isOpenOnFocusMouseEvent(e, this.id) &&
            !isPopupMouseEvent(e)
          );
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.close();
      });
  }

  deinitTokenPopoverBlur() {
    if (this.tokenPopoverBlurSubscription) {
      this.tokenPopoverBlurSubscription.unsubscribe();
      this.tokenPopoverBlurSubscription = undefined;
    }
  }

  initOpenOnFocus() {
    this.openOnFocusSubscriptions.forEach(item => item.unsubscribe());
    this.openOnFocusSubscriptions = [];

    if (!this.openOnFocus) {
      return;
    }

    this.openOnFocusSubscriptions.push(
      this.focusMonitor
        .monitor(this.openOnFocus)
        .pipe(
          filter(origin => !!origin && !this.tokenPopoverOpened),
          untilDestroyed(this)
        )
        .subscribe(() => this.open())
    );

    this.openOnFocusSubscriptions.push(
      fromEvent<MouseEvent>(this.openOnFocus, 'mousedown')
        .pipe(untilDestroyed(this))
        .subscribe(e => markOpenOnFocusMouseEvent(e, this.id))
    );
  }

  updatePosition() {
    if (this.tokenPopoverOverlay && this.tokenPopoverOverlay.overlayRef) {
      this.tokenPopoverOverlay.overlayRef.updatePosition();
    }
  }

  onTokenPopoverContentChanged() {
    this.updatePosition();
  }
}

import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

import { ActionType } from '@modules/actions';
import { controlValue, isSet, TypedChanges } from '@shared';

import { actionTypeOptions, ActionTypeSection, ActionTypeSectionItem } from './action-type-options';

@Component({
  selector: 'app-action-type-dropdown',
  templateUrl: './action-type-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionTypeDropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() opened = false;
  @Input() origin: CdkOverlayOrigin;
  @Input() value: ActionType;
  @Input() typesOnly: ActionType[];
  @Input() actionOriginEnabled = false;
  @Output() select = new EventEmitter<ActionType>();
  @Output() close = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  searchControl = new FormControl('');
  sections = actionTypeOptions;
  filteredSections: ActionTypeSection[];
  activeSection: ActionTypeSection;
  hoverSection: { section: ActionTypeSection; origin: CdkOverlayOrigin; hover$: BehaviorSubject<boolean> };
  hoverSubscription: Subscription;
  disableInteractions = false;
  disableInteractionsSubscription: Subscription;
  dropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  dropdownPositionsSubscription: Subscription;

  trackSectionFn(i, item: ActionTypeSection) {
    return item.id;
  }

  trackSectionItemFn(i, item: ActionTypeSectionItem) {
    return item.value;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue<string>(this.searchControl)
      .pipe(
        map(search => search.toLowerCase().trim()),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(search => {
        this.updateFilteredSectionsForSearch(search);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ActionTypeDropdownComponent>): void {
    if (changes.value) {
      this.activeSection = isSet(this.value)
        ? this.sections.find(section => section.items.some(item => item.value == this.value))
        : undefined;
    }

    if (changes.typesOnly || changes.actionOriginEnabled) {
      this.updateSections();
      this.updateFilteredSections();
    }

    if (changes.opened) {
      if (this.disableInteractionsSubscription) {
        this.disableInteractionsSubscription.unsubscribe();
        this.disableInteractionsSubscription = undefined;
      }
    }

    if (changes.opened && this.opened) {
      this.disableInteractions = true;
      this.disableInteractionsSubscription = timer(120)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.disableInteractions = false;
          this.cd.markForCheck();
        });
    }
  }

  ngAfterViewInit(): void {
    this.setDropdownPositionObserver();
  }

  updateSections() {
    this.sections = actionTypeOptions
      .map(section => {
        return {
          ...section,
          items: section.items.filter(item => {
            if (item.originRequired && !this.actionOriginEnabled) {
              return false;
            } else if (this.typesOnly) {
              return this.typesOnly.includes(item.value);
            } else {
              return true;
            }
          })
        };
      })
      .filter(section => section.items.length);
  }

  updateFilteredSectionsForSearch(search: string) {
    this.filteredSections = isSet(search)
      ? this.sections
          .map(section => {
            return {
              ...section,
              items: section.items.filter(item => item.name.toLowerCase().includes(search))
            };
          })
          .filter(section => section.items.length)
      : undefined;
    this.cd.markForCheck();
  }

  updateFilteredSections() {
    const search = this.searchControl.value.toLowerCase().trim();
    this.updateFilteredSectionsForSearch(search);
  }

  clearSearch() {
    this.searchControl.patchValue('');
  }

  setHoverSection(section: ActionTypeSection, origin: CdkOverlayOrigin) {
    this.hoverSection = { section: section, origin: origin, hover$: new BehaviorSubject<boolean>(false) };
    this.cd.markForCheck();
  }

  resetHoverSection() {
    this.hoverSection = undefined;
    this.cd.markForCheck();

    if (this.hoverSubscription) {
      this.hoverSubscription.unsubscribe();
      this.hoverSubscription = undefined;
    }
  }

  onSectionHover(section: ActionTypeSection, origin: CdkOverlayOrigin) {
    if (section.disableNested) {
      return;
    }

    if (this.hoverSection) {
      this.resetHoverSection();
    }

    this.setHoverSection(section, origin);

    const originHover$ = merge(
      of(true),
      fromEvent(origin.elementRef.nativeElement, 'mouseenter').pipe(map(() => true)),
      fromEvent(origin.elementRef.nativeElement, 'mouseleave').pipe(map(() => false))
    );

    this.hoverSubscription = combineLatest(
      originHover$.pipe(debounce(hover => timer(hover ? 0 : 100))),
      this.hoverSection.hover$
    )
      .pipe(
        map(([originHover, hoverSectionHover]) => originHover || hoverSectionHover),
        untilDestroyed(this)
      )
      .subscribe(hover => {
        if (!hover) {
          this.resetHoverSection();
        }
      });
  }

  onSectionClick(section: ActionTypeSection) {
    if (section.disableNested) {
      this.select.emit(section.items[0].value);
    }
  }

  setDropdownPositionObserver() {
    if (this.dropdownPositionsSubscription) {
      this.dropdownPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.dropdownPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.dropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.dropdownPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { HttpQueryAuthType } from '@modules/queries';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { RestApiResourceSettingsForm } from './rest-api-resource-settings.form';
var RestApiResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(RestApiResourceSettingsComponent, _super);
    function RestApiResourceSettingsComponent(form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.form = form;
        _this.httpQueryAuthTypes = HttpQueryAuthType;
        return _this;
    }
    RestApiResourceSettingsComponent.prototype.onResourceAccessToken = function (token) {
        if (token) {
            this.form.form.patchValue({ access_token_name: "{-" + token.name + "-}" });
        }
    };
    return RestApiResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { RestApiResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.RestApi, RestApiResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.GraphQL, RestApiResourceSettingsComponent);

import { ActionType } from '@modules/actions';
import { Option } from '@modules/field-components';
import { generateAlphanumeric } from '@shared';

export type ActionTypeSectionItem = Option<ActionType> & { originRequired?: boolean };

export interface ActionTypeSection {
  id: string;
  title: string;
  subtitle: string;
  icon: string;
  items: ActionTypeSectionItem[];
  disableNested?: boolean;
  highlight?: boolean;
}

export const actionTypeOptions: ActionTypeSection[] = [
  {
    title: 'Data',
    subtitle: 'Run operation, export/import data',
    icon: 'cloud_upload',
    items: [
      {
        value: ActionType.Query,
        name: 'Run Operation',
        subtitle: 'Run collection, action or storage queries, custom SQL/HTTP',
        icon: 'cloud_upload'
      },
      { value: ActionType.Export, name: 'Export Data', icon: 'download' },
      { value: ActionType.Import, name: 'Import Data', icon: 'upload' }
    ]
  },
  {
    title: 'Navigation',
    subtitle: 'Navigate to page or open URL',
    icon: 'redo',
    items: [
      { value: ActionType.Link, name: 'Navigate to Page', icon: 'redo' },
      { value: ActionType.ExternalLink, name: 'Open URL', icon: 'model_link' }
    ]
  },
  {
    title: 'Overlays',
    subtitle: 'Open or close page overlays',
    icon: 'copy',
    items: [
      { value: ActionType.OpenActionMenu, name: 'Open Actions dropdown', icon: 'fileds', originRequired: true },
      { value: ActionType.OpenPopup, name: 'Open Overlay', icon: 'copy' },
      { value: ActionType.ClosePopup, name: 'Close Overlay', icon: 'windows' }
    ]
  },
  {
    title: 'Interactions',
    subtitle: 'Notifications, clipboard, components',
    icon: 'notification',
    items: [
      { value: ActionType.ShowNotification, name: 'Show Notification', icon: 'notification' },
      { value: ActionType.CopyToClipboard, name: 'Copy to Clipboard', icon: 'documents' },
      { value: ActionType.ElementAction, name: 'Run Component action', icon: 'components' }
    ]
  },
  {
    title: 'Advanced',
    subtitle: 'Set variable, run javascript, etc.',
    icon: 'console',
    items: [
      { value: ActionType.SetProperty, name: 'Set Variable', icon: 'variable' },
      { value: ActionType.RunJavaScript, name: 'Run JavaScript', icon: 'console' },
      { value: ActionType.Download, name: 'Download File', icon: 'save' },
      { value: ActionType.ScanCode, name: 'Scan QR/Bar code', icon: 'qr_code' }
    ]
  },
  {
    title: 'Run Workflow',
    subtitle: 'Combine other actions to create various workflows',
    icon: 'workflow',
    items: [{ value: ActionType.Workflow, name: 'Run Workflow', icon: 'workflow' }],
    disableNested: true,
    highlight: true
  }
].map(item => {
  return {
    ...item,
    id: generateAlphanumeric(4, { letterFirst: true })
  };
});

import { LocationStrategy } from '@angular/common';
import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { QueryParamsHandling } from '@angular/router/src/config';

import { AdminMode } from '@modules/admin-mode';

import { RoutingService } from '../../services/routing/routing.service';

@Directive({
  selector: '[appLink]'
})
export class AppLinkDirective extends RouterLinkWithHref implements OnInit, OnChanges {
  @Input() appLink: any[];
  @Input() queryParams: { [k: string]: any };
  @Input() fragment!: string;
  @Input() queryParamsHandling!: QueryParamsHandling;
  @Input() preserveFragment!: boolean;
  @Input() skipLocationChange!: boolean;
  @Input() replaceUrl!: boolean;
  @Input() appLinkProject: string;
  @Input() appLinkEnvironment: string;
  @Input() appLinkMode: AdminMode;

  constructor(
    private routingService: RoutingService,
    router: Router,
    route: ActivatedRoute,
    locationStrategy: LocationStrategy
  ) {
    super(router, route, locationStrategy);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: {}): any {
    if (changes['appLink']) {
      this.updateLink();
    }

    super.ngOnChanges(changes);
  }

  updateLink() {
    this.routerLink = this.routingService.appLink(this.appLink, {
      projectName: this.appLinkProject,
      environmentName: this.appLinkEnvironment,
      modeName: this.appLinkMode
    });
    this['updateTargetUrlAndHref']();
  }
}

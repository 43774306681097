var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSet } from '@shared';
import { CustomViewsStore } from './custom-views.store';
var CustomViewSelectSource = /** @class */ (function (_super) {
    __extends(CustomViewSelectSource, _super);
    function CustomViewSelectSource(customViewsStore) {
        var _this = _super.call(this) || this;
        _this.customViewsStore = customViewsStore;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    CustomViewSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.customViewsStore.getFirst().pipe(map(function (result) {
            _this.page += 1;
            return result
                .filter(function (item) {
                return isSet(searchQuery)
                    ? [item.uniqueName, item.name].some(function (str) { return str.toLowerCase().includes(searchQuery.toLowerCase()); })
                    : true;
            })
                .filter(function (item) { return (_this.viewType ? item.viewType == _this.viewType : true); })
                .map(function (item) {
                return {
                    value: item.uniqueName,
                    name: item.name
                };
            });
        }));
    };
    CustomViewSelectSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.customViewsStore.getDetailFirst(value).pipe(map(function (item) {
            if (!item) {
                return;
            }
            return {
                value: item.uniqueName,
                name: item.name
            };
        }));
    };
    CustomViewSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    CustomViewSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    CustomViewSelectSource.prototype.setStaticOptions = function (options) { };
    return CustomViewSelectSource;
}(SelectSource));
export { CustomViewSelectSource };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { generateAlphanumeric } from '@shared';
import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
var ForkWorkflowStepItem = /** @class */ (function () {
    function ForkWorkflowStepItem() {
        this.steps = [];
    }
    ForkWorkflowStepItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        if (data['steps']) {
            this.steps = data['steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        return this;
    };
    ForkWorkflowStepItem.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            steps: this.steps.map(function (item) { return item.serialize(); })
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ForkWorkflowStepItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    return ForkWorkflowStepItem;
}());
export { ForkWorkflowStepItem };
var ForkWorkflowStep = /** @class */ (function (_super) {
    __extends(ForkWorkflowStep, _super);
    function ForkWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Fork;
        _this.items = [];
        return _this;
    }
    ForkWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['items']) {
            this.items = data['items'].map(function (item) { return new ForkWorkflowStepItem().deserialize(item); });
        }
        return this;
    };
    ForkWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { items: this.items.map(function (item) { return item.serialize(); }) });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ForkWorkflowStep.prototype.defaultName = function () {
        return 'Parallelize';
    };
    Object.defineProperty(ForkWorkflowStep.prototype, "analyticsName", {
        get: function () {
            return 'fork';
        },
        enumerable: true,
        configurable: true
    });
    ForkWorkflowStep.prototype.getIcon = function () {
        return 'matches';
    };
    return ForkWorkflowStep;
}(WorkflowStep));
export { ForkWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Fork, ForkWorkflowStep);

<app-sidebar-header
  [title]="'Menu customization'"
  [subtitle]="fromAppSettings ? undefined : 'Pages'"
  [backEnabled]="fromAppSettings"
  (back)="backToAppSettings()"
>
</app-sidebar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div
      appDropList
      [appDropListData]="menuForm.controls.blocks.controls"
      [appDropListLockAxis]="dragAxis.Y"
      (appDropListDropped)="dragDrop($event)"
    >
      <div
        *ngFor="let item of blockItems; trackBy: trackMenuBlockItemFn"
        class="sidebar__element"
        appDrag
        [appDragData]="item"
        (mouseenter)="blockControlHover$.next(item.control)"
        (mouseleave)="blockControlHover$.next(undefined)"
      >
        <app-menu-block-edit
          [title]="item.title"
          [control]="item.control"
          [layoutGroups]="layoutGroups"
          [active]="blockControlPreviewHover$.value === item.control"
          [disabled]="blockControlPreviewHover$.value && blockControlPreviewHover$.value !== item.control"
          (edit)="navigateMenuBlockItem(item)"
          (remove)="menuForm.controls.blocks.removeControl(item.control)"
        ></app-menu-block-edit>
      </div>
    </div>

    <div *ngIf="blockItems.length < menuForm.controls.blocks.controlsFixedLength" class="sidebar__element">
      <app-menu-block-add [layoutGroups]="layoutGroups" (add)="addMenuBlock($event)"></app-menu-block-add>
    </div>
  </app-sidebar-section>
</div>

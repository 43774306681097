var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { of } from 'rxjs';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { InstanceStore } from '@shared';
import { SlackService } from '../services/slack/slack.service';
var SlackChannelStore = /** @class */ (function (_super) {
    __extends(SlackChannelStore, _super);
    function SlackChannelStore(currentProjectStore, currentEnvironmentStore, slackService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.slackService = slackService;
        return _this;
    }
    SlackChannelStore.prototype.ngOnDestroy = function () { };
    SlackChannelStore.prototype.isEnabled = function () {
        return this.currentProjectStore.instance.features.isSlackChannelEnabled();
    };
    SlackChannelStore.prototype.fetchData = function () {
        if (!this.isEnabled()) {
            return of(undefined);
        }
        return this.slackService.getSlackChannel(this.currentProjectStore.uniqueName, this.currentEnvironmentStore.uniqueName);
    };
    return SlackChannelStore;
}(InstanceStore));
export { SlackChannelStore };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, Type } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalendarComponent } from '@modules/calendar';
import { CustomizeService, ElementType, getListLayoutSettingsClass, ListElementItem, registerElementComponent, ViewContextElement, ViewContextElementFactory } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { BaseElementComponent } from '@modules/customize-elements';
import { ViewSettingsGeneratorService } from '@modules/customize-generators';
import { Input as FieldInput } from '@modules/fields';
import { GridComponent } from '@modules/grid';
import { KanbanBoardComponent } from '@modules/kanban-board';
import { LayoutService, listLayouts, ListLayoutType } from '@modules/layouts';
import { MapComponent } from '@modules/map';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { TableComponent } from '@modules/table';
import { TimelineComponent } from '@modules/timeline';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var ListElementComponent = /** @class */ (function (_super) {
    __extends(ListElementComponent, _super);
    function ListElementComponent(customizeService, modelService, modelDescriptionStore, currentProjectStore, currentEnvironmentStore, customizeBarContext, customizeBarService, viewContextElementFactory, injector, viewSettingsGeneratorService, viewContextElement, cd, layoutService, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.modelService = modelService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.customizeBarContext = customizeBarContext;
        _this.customizeBarService = customizeBarService;
        _this.viewContextElementFactory = viewContextElementFactory;
        _this.injector = injector;
        _this.viewSettingsGeneratorService = viewSettingsGeneratorService;
        _this.viewContextElement = viewContextElement;
        _this.cd = cd;
        _this.layoutService = layoutService;
        _this.popup = popup;
        _this.params = {};
        _this.hideParams = [];
        _this.layoutTypes = ListLayoutType;
        _this.contextElements = [];
        return _this;
    }
    ListElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'table',
            allowSkip: true,
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        this.viewId = [this.currentProjectStore.instance.uniqueName, 'element', this.element.uid].join('_');
        this.updateLayouts();
        combineLatest(this.layoutService.currentLayout$, this.layoutService.currentLayoutIndex$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var layout = _a[0], index = _a[1];
            _this.layout = layout;
            _this.layoutIndex = index;
            _this.cd.markForCheck();
        });
    };
    ListElementComponent.prototype.ngOnDestroy = function () { };
    ListElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
            this.updateLayouts();
        }
    };
    ListElementComponent.prototype.getCurrentLayoutIndex = function () {
        return this.layoutService.currentLayoutIndex;
    };
    ListElementComponent.prototype.getContextElements = function () {
        return this.contextElements.map(function (item) { return item.element; });
    };
    ListElementComponent.prototype.getListComponent = function () {
        return (this.tableComponent ||
            this.kanbanBoardComponent ||
            this.mapComponent ||
            this.calendarComponent ||
            this.gridComponent ||
            this.timelineComponent);
    };
    // subscribeParams(modelDescription) {
    //   if (this.formSubscription) {
    //     this.formSubscription.unsubscribe();
    //   }
    //
    //   if (!this.context) {
    //     return;
    //   }
    //
    //   this.formSubscription = this.context.form.controls[this.context.modelDescription.primaryKeyField].valueChanges
    //     .pipe(
    //       delay(0),
    //       untilDestroyed(this)
    //     )
    //     .subscribe(() => {
    //       this.updateParams(modelDescription, this.context.model);
    //     });
    //
    //   this.updateParams(modelDescription, this.context.model);
    // }
    //
    // updateParams(modelDescription, model: Model) {
    //   if (!model) {
    //     this.params.next(undefined);
    //     return;
    //   }
    //
    //   const params = this.element.relation.backFilterParams(this.context.modelDescription, [model]);
    //
    //   this.params.next({
    //     modelDescription: modelDescription,
    //     queryParams: params
    //   });
    // }
    ListElementComponent.prototype.updateLayouts = function () {
        var _this = this;
        combineLatest(this.element.layouts.map(function (item) { return _this.viewSettingsGeneratorService.applyListLayoutDefaults(item); }))
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.element.layouts = result;
        });
        var layouts = this.element.layouts.map(function (item) { return item.type; });
        this.layoutService.init(layouts, this.viewId);
        this.updateContextElements();
    };
    ListElementComponent.prototype.updateContextElements = function () {
        var _this = this;
        this.contextElements
            .filter(function (item, i) { return _this.element && !_this.element.layouts[i]; })
            .forEach(function (item) { return item.element.unregister(); });
        this.contextElements = this.element.layouts
            ? this.element.layouts.map(function (layout, i) {
                var existing = _this.contextElements[i];
                var listLayout = listLayouts.find(function (item) { return item.type == layout.type; });
                var fieldFilters = listLayout && listLayout.filters;
                var icon = listLayout ? listLayout.icon : undefined;
                if (existing) {
                    existing.element.initInfo({
                        uniqueName: i.toString(),
                        name: listLayout.label,
                        icon: icon,
                        fieldFilters: fieldFilters,
                        getFieldValue: function (field, outputs) {
                            var component = _this.getListComponent();
                            var model = component ? component.getAnyModel() : undefined;
                            return model ? model.getAttribute(field) : undefined;
                        }
                    }, true);
                    return existing;
                }
                var element = _this.viewContextElementFactory.create(_this.injector);
                element.initElement({
                    uniqueName: i.toString(),
                    name: listLayout.label,
                    icon: icon,
                    fieldFilters: fieldFilters,
                    getFieldValue: function (field, outputs) {
                        var component = _this.getListComponent();
                        var model = component ? component.getAnyModel() : undefined;
                        return model ? model.getAttribute(field) : undefined;
                    }
                }, _this.viewContextElement);
                return { element: element, layout: layout };
            })
            : [];
        this.cd.markForCheck();
    };
    ListElementComponent.prototype.openSettings = function (index) {
        var _this = this;
        var settings = this.element.layouts[index];
        var element = this.context.getElementChildren(this.viewContextElement)[index];
        this.customizeBarService
            .customizeListLayoutSettings({
            context: this.customizeBarContext,
            settings: settings,
            title: this.element.name,
            titleEditable: true,
            viewContext: this.viewContextElement.context,
            viewContextElement: element,
            deleteEnabled: this.element.layouts.length > 1
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                var title = e.args['title'];
                _this.onSettingsChanged(index, instance, title);
            }
            else if (e.type == CustomizeBarEditEventType.Canceled) {
                _this.onSettingsChanged(index, settings, _this.element.name);
            }
            else if (e.type == CustomizeBarEditEventType.Deleted) {
                var newElement = cloneDeep(_this.element);
                newElement.layouts = newElement.layouts.filter(function (item, i) { return i != index; });
                _this.updated.emit(newElement);
            }
        });
    };
    ListElementComponent.prototype.onParamsChanged = function (params) {
        this.params = params;
        this.cd.markForCheck();
    };
    ListElementComponent.prototype.onSettingsChanged = function (index, settings, name) {
        var element = cloneDeep(this.element);
        element.name = name;
        element.layouts = element.layouts.map(function (item, i) {
            if (i == index) {
                return settings;
            }
            else {
                return item;
            }
        });
        this.viewContextElement.initInfo({ name: name }, true);
        this.updated.emit(element);
    };
    ListElementComponent.prototype.addLayout = function () {
        var element = cloneDeep(this.element);
        var listLayout = listLayouts.find(function (item) { return !element.layouts.find(function (i) { return i.type == item.type; }); }) || listLayouts[0];
        var cls = getListLayoutSettingsClass(listLayout.type);
        var instance = new cls();
        instance.titleInput = new FieldInput().deserializeFromStatic('value', listLayout.label);
        element.layouts = element.layouts.concat([instance]);
        this.updated.emit(element);
        var index = element.layouts.length - 1;
        this.layoutService.currentLayoutIndex = index;
        this.openSettings(index);
    };
    ListElementComponent.prototype.customizeLayout = function (index) {
        this.openSettings(index);
    };
    return ListElementComponent;
}(BaseElementComponent));
export { ListElementComponent };
registerElementComponent({
    type: ElementType.List,
    component: ListElementComponent,
    alwaysActive: false,
    label: 'List',
    actions: []
});

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import './data/elements/items/action';
import './data/elements/items/action-dropdown';
import './data/elements/items/card-layout';
import './data/elements/items/columns-layout';
import './data/elements/items/field';
import './data/elements/items/section-layout';
import './data/elements/items/tabs-layout';
import './data/elements/items/text';
import './data/elements/items/title';
import './data/elements/items/widget';

@NgModule({
  imports: [CommonModule]
})
export class CustomizeModule {}

import { ActionItem } from '@modules/actions';
import { DisplayField } from '@modules/fields';
import { Model } from '@modules/models';
import { isSet } from '@shared';

import { ListItemColumn } from './list-item-column';

export interface ListItem {
  columns: ListItemColumn[];
  model: Model;
}

export interface ColumnListItem {
  listItem: ListItemColumn;
  column: DisplayField;
  columnAction?: ActionItem;
  width?: number;
}

export function listItemEquals(lhs: ListItem, rhs: ListItem) {
  return (
    lhs === rhs ||
    (lhs &&
      rhs &&
      isSet(lhs.model.primaryKey) &&
      isSet(rhs.model.primaryKey) &&
      lhs.model.primaryKey == rhs.model.primaryKey)
  );
}

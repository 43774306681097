import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';

import { trimAll, unwrapElement } from '@shared';

import { localize } from '../../utils/localize';

@Directive({
  selector: '[appTr]'
})
export class LocalizeDirective implements OnInit {
  @Input('appTr') context: { [k: string]: any } = {};

  constructor(private templateRef: TemplateRef<any>, private vcr: ViewContainerRef) {}

  ngOnInit(): void {
    const context = fromPairs(toPairs(this.context).map(([k, v], i) => [k, `{${i}}`]));
    const view = this.vcr.createEmbeddedView(this.templateRef, {
      ...context,
      $implicit: context
    });

    view.detectChanges();

    const renderedHtml = trimAll(
      view.rootNodes
        .map(item => {
          if (item.nodeType == 3) {
            return item.textContent;
          } else {
            return item.outerHTML;
          }
        })
        .join('')
    );

    this.vcr.clear();

    const content = localize(renderedHtml, values(this.context));
    const htmlContainer = document.createElement('div');
    const vcrEl = this.vcr.element.nativeElement;

    htmlContainer.innerHTML = content;
    vcrEl.parentNode.insertBefore(htmlContainer, vcrEl);
    unwrapElement(htmlContainer);
  }
}

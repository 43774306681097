/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./list-stub-footer.component";
var styles_ListStubFooterComponent = [];
var RenderType_ListStubFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListStubFooterComponent, data: {} });
export { RenderType_ListStubFooterComponent as RenderType_ListStubFooterComponent };
function View_ListStubFooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "pagination__pages-item"]], [[2, "pagination__pages-item_loading", null], [2, "pagination__pages-item_selected", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["1"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.animating; _ck(_v, 1, 0, currVal_2); }); }
function View_ListStubFooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "list__pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [["class", "pagination__control"]], [[2, "pagination__control_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["<"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "pagination__pages"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListStubFooterComponent_2)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(9, 3), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [["class", "pagination__control"]], [[2, "pagination__control_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [">"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 9, 0, true, false, false); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.animating; _ck(_v, 3, 0, currVal_1); var currVal_3 = true; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.animating; _ck(_v, 11, 0, currVal_4); }); }
function View_ListStubFooterComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "list__status list-footer__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["items"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 1, 0, currVal_0); }); }
export function View_ListStubFooterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "list-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "list-footer__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListStubFooterComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "list-footer__right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListStubFooterComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pagination; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.count; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ListStubFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-stub-footer", [], null, null, null, View_ListStubFooterComponent_0, RenderType_ListStubFooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.ListStubFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListStubFooterComponentNgFactory = i0.ɵccf("app-list-stub-footer", i2.ListStubFooterComponent, View_ListStubFooterComponent_Host_0, { pagination: "pagination", count: "count", animating: "animating" }, {}, []);
export { ListStubFooterComponentNgFactory as ListStubFooterComponentNgFactory };

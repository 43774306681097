/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../register-template/register-template.component.ngfactory";
import * as i2 from "../../../theme-components/data/theme-context";
import * as i3 from "../register-template/register-template.component";
import * as i4 from "../../../../core/services/app-config/app-config.service";
import * as i5 from "../../../routing/services/routing/routing.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../ui/components/language-selector/language-selector.component.ngfactory";
import * as i8 from "../../../../ui/components/language-selector/language-selector.component";
import * as i9 from "../../../../core/services/local-storage/local.storage";
import * as i10 from "./register.form";
import * as i11 from "../../../auth/services/auth/auth.service";
import * as i12 from "../../../api/services/api/api.service";
import * as i13 from "../../../users/services/user/user.service";
import * as i14 from "../../../../common/form-utils/services/form-utils";
import * as i15 from "../login/login.form";
import * as i16 from "@angular/forms";
import * as i17 from "./register.component";
import * as i18 from "../../../users/stores/current-user.store";
import * as i19 from "@angular/router";
import * as i20 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i21 from "../../../analytics/services/google-analytics/google-analytics.service";
import * as i22 from "../../../../common/notifications/services/notification/notification.service";
import * as i23 from "../../../meta/services/meta.service";
import * as i24 from "../../../projects/stores/projects.store";
import * as i25 from "../../../projects/services/project-invite/project-invite.service";
import * as i26 from "../../../projects/services/project-public-invite/project-public-invite.service";
var styles_RegisterComponent = [];
var RenderType_RegisterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
function View_RegisterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-register-template", [], null, [[null, "socialLogin"], [null, "ssoLogin"], [null, "cleanErrorForm"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("socialLogin" === en)) {
        var pd_0 = (_co.socialLogin($event) !== false);
        ad = (pd_0 && ad);
    } if (("ssoLogin" === en)) {
        var pd_1 = (_co.ssoLogin($event) !== false);
        ad = (pd_1 && ad);
    } if (("cleanErrorForm" === en)) {
        var pd_2 = (_co.cleanErrorForm() !== false);
        ad = (pd_2 && ad);
    } if (("submit" === en)) {
        var pd_3 = (_co.submit() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_RegisterTemplateComponent_0, i1.RenderType_RegisterTemplateComponent)), i0.ɵprd(135680, null, i2.ThemeContext, i2.ThemeContext, []), i0.ɵdid(2, 638976, null, 0, i3.RegisterTemplateComponent, [i4.AppConfigService, i5.RoutingService], { form: [0, "form"], domainLoading: [1, "domainLoading"], submitLoading: [2, "submitLoading"], screenLoader: [3, "screenLoader"], domain: [4, "domain"], customFields: [5, "customFields"], invite: [6, "invite"], inviteLoading: [7, "inviteLoading"], publicInvite: [8, "publicInvite"], publicInviteLoading: [9, "publicInviteLoading"], signInLink: [10, "signInLink"], fillScreen: [11, "fillScreen"] }, { socialLogin: "socialLogin", ssoLogin: "ssoLogin", cleanErrorForm: "cleanErrorForm", submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.domainLoading; var currVal_2 = _co.submitLoading; var currVal_3 = _co.screenLoader; var currVal_4 = _co.domain; var currVal_5 = _co.customFields; var currVal_6 = _co.invite; var currVal_7 = _co.inviteLoading; var currVal_8 = _co.publicInvite; var currVal_9 = _co.publicInviteLoading; var currVal_10 = _co.signInLink; var currVal_11 = true; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_RegisterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegisterComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-language-selector", [], null, null, null, i7.View_LanguageSelectorComponent_0, i7.RenderType_LanguageSelectorComponent)), i0.ɵdid(3, 245760, null, 0, i8.LanguageSelectorComponent, [i9.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inviteLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_RegisterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i0.ɵprd(512, null, i10.RegisterForm, i10.RegisterForm, [i11.AuthService, i12.ApiService, i13.UserService, i14.FormUtils]), i0.ɵprd(512, null, i15.LoginForm, i15.LoginForm, [i14.FormUtils, i16.FormBuilder, i11.AuthService, i12.ApiService]), i0.ɵdid(3, 245760, null, 0, i17.RegisterComponent, [i10.RegisterForm, i15.LoginForm, i5.RoutingService, i18.CurrentUserStore, i19.ActivatedRoute, i20.UniversalAnalyticsService, i21.GoogleAnalyticsService, i4.AppConfigService, i11.AuthService, i22.NotificationService, i23.MetaService, i24.ProjectsStore, i12.ApiService, i25.ProjectInviteService, i26.ProjectPublicInviteService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var RegisterComponentNgFactory = i0.ɵccf("app-register", i17.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { SpacingElementItem } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

@Injectable()
export class CustomizeBarSpacingEditForm {
  element: SpacingElementItem;
  form = new FormGroup({
    title: new FormControl(''),
    visible_input: new FieldInputControl({ name: 'value' })
  });

  constructor() {}

  init(element: SpacingElementItem) {
    this.element = element;

    const value = {
      title: element.name ? element.name : 'Spacing',
      visible_input: element.visibleInput ? element.visibleInput.serialize() : {}
    };

    this.form.patchValue(value, { emitEvent: false });
  }

  submit(): SpacingElementItem {
    const value = this.form.value;
    const instance = cloneDeep(this.element) as SpacingElementItem;

    instance.name = value['title'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}

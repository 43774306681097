import clone from 'lodash/clone';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, map as rxMap, switchMap } from 'rxjs/operators';
import { modelFieldToDisplayField } from '@modules/customize';
import { exactFieldLookup, inFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { paramsToGetQueryOptions, prepareDataSourceColumnForGet, ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
import { ModelDescriptionStore } from '../../stores/model-description.store';
import { ModelService } from '../model/model.service';
var ReducedModelService = /** @class */ (function () {
    function ReducedModelService(modelDescriptionStore, modelService, currentProjectStore, currentEnvironmentStore, resourceControllerService) {
        var _this = this;
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelService = modelService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceControllerService = resourceControllerService;
        this.requests = [];
        this.requestsUpdated = new Subject();
        this.sqlRequests = [];
        this.sqlRequestsUpdated = new Subject();
        this.requestsUpdated.pipe(debounceTime(300)).subscribe(function () {
            var requestsByModel = flow([function (items) { return groupBy(items, function (item) { return _this.requestKey(item); }); }, toPairs])(_this.requests);
            _this.requests = [];
            requestsByModel.forEach(function (_a) {
                var key = _a[0], value = _a[1];
                var modelId = value[0].model;
                var commonParams = value[0].commonParams;
                var queryOptions = commonParams ? paramsToGetQueryOptions(commonParams) : {};
                queryOptions.filters = queryOptions.filters || [];
                queryOptions.paging = { limit: 10000 };
                toPairs(groupBy(flatten(value.map(function (item) { return toPairs(item.params); })), function (item) { return item[0]; })).forEach(function (_a) {
                    var field = _a[0], pairs = _a[1];
                    if (pairs.length == 1) {
                        var filterItem = new FilterItem2({
                            field: [field],
                            lookup: exactFieldLookup,
                            value: pairs[0][1]
                        });
                        queryOptions.filters.push(filterItem);
                    }
                    else {
                        var filterItem = new FilterItem2({
                            field: [field],
                            lookup: inFieldLookup,
                            value: pairs.map(function (pair) { return pair[1]; })
                        });
                        queryOptions.filters.push(filterItem);
                    }
                });
                return _this.modelDescriptionStore
                    .getDetailFirst(modelId)
                    .pipe(switchMap(function (modelDescription) {
                    var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
                    queryOptions.columns =
                        resource && modelDescription
                            ? modelDescription.fields
                                .map(function (item) { return modelFieldToDisplayField(item, false); })
                                .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                            : [];
                    return _this.modelService.getAdv(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, modelId, queryOptions);
                }), catchError(function () { return of(undefined); }))
                    .subscribe(function (result) {
                    value.forEach(function (request) {
                        if (!result) {
                            request.obs.forEach(function (obs) { return obs.next([]); });
                            return;
                        }
                        var requestResults = result.results.filter(function (item) {
                            return toPairs(request.params).every(function (_a) {
                                var k = _a[0], v = _a[1];
                                return item.getAttribute(k) == v;
                            });
                        });
                        request.obs.forEach(function (obs) { return obs.next(requestResults); });
                    });
                });
            });
        });
        this.sqlRequestsUpdated.pipe(debounceTime(300)).subscribe(function () {
            var requests = _this.sqlRequests;
            var resources = uniq(_this.sqlRequests.map(function (item) { return item.resource; }));
            _this.sqlRequests = [];
            resources.forEach(function (resource) {
                var resourceRequests = requests.filter(function (item) { return item.resource === resource; });
                _this.modelService
                    .sqls(resource, resourceRequests.map(function (item) {
                    return {
                        query: item.query,
                        tokens: item.params
                    };
                }))
                    .subscribe(function (result) {
                    result.forEach(function (item, i) {
                        resourceRequests[i].obs.forEach(function (obs) { return obs.next(item); });
                    });
                });
            });
        });
    }
    ReducedModelService.prototype.requestKey = function (request) {
        var paramNames = keys(request.params).sort(function (lhs, rhs) {
            return lhs < rhs ? -1 : lhs < rhs ? 0 : 1;
        });
        return request.model + "_" + paramNames.join('_');
    };
    ReducedModelService.prototype.get = function (modelId, params, commonParams) {
        if (params === void 0) { params = {}; }
        if (commonParams === void 0) { commonParams = {}; }
        var obs = new Subject();
        var request = {
            model: modelId,
            params: params,
            commonParams: commonParams,
            obs: []
        };
        var existing = this.requests.find(function (item) {
            return (item.model == request.model && isEqual(item.params, request.params) && isEqual(item.commonParams, commonParams));
        });
        if (!existing) {
            this.requests.push(request);
        }
        else {
            request = existing;
        }
        request.obs.push(obs);
        this.requestsUpdated.next();
        return obs;
    };
    ReducedModelService.prototype.getDetail = function (modelId, idField, id, params, commonParams) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (commonParams === void 0) { commonParams = {}; }
        if (!isSet(id) || id == 'null') {
            return of(undefined);
        }
        var resources = this.currentProjectStore.instance.getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName);
        return this.modelService.getForModel(resources, modelId).pipe(switchMap(function (modelParams) {
            var resource = modelParams[0], modelDescription = modelParams[1], controller = modelParams[2];
            if (controller.filtersLookups) {
                var instanceParams = clone(params);
                instanceParams[idField] = id;
                return _this.get(modelId, instanceParams, commonParams).pipe(rxMap(function (result) {
                    return result.length ? result[0] : undefined;
                }));
            }
            else {
                return controller.modelGetDetail(resource, modelDescription, idField, id, params);
            }
        }));
    };
    ReducedModelService.prototype.sql = function (resource, query, tokens) {
        var _this = this;
        var controller = this.resourceControllerService.get(resource.type);
        if (!controller || !controller.checkApiInfo) {
            return of(undefined);
        }
        return controller.checkApiInfo(resource).pipe(switchMap(function () {
            var apiInfo = resource ? resource.apiInfo : undefined;
            if (!apiInfo || apiInfo.isCompatibleJetBridge({ jetBridge: '0.3.7', jetDjango: '0.6.0' })) {
                var obs = new Subject();
                var request_1 = {
                    resource: resource,
                    query: query,
                    params: tokens,
                    obs: []
                };
                var existing = _this.sqlRequests.find(function (item) {
                    return item.resource == resource && item.query == request_1.query && isEqual(item.params, request_1.params);
                });
                if (!existing) {
                    _this.sqlRequests.push(request_1);
                }
                else {
                    request_1 = existing;
                }
                request_1.obs.push(obs);
                _this.sqlRequestsUpdated.next();
                return obs;
            }
            else {
                return _this.modelService.sql(resource, query, tokens);
            }
        }));
    };
    return ReducedModelService;
}());
export { ReducedModelService };

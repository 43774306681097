<div class="view-editor-navigator">
  <div class="view-editor-navigator__header">
    <div class="view-editor-navigator__header-item">
      <div class="compact-input" [class.compact-input_focus]="focus.focused$ | async" (click)="input.focus()">
        <div class="compact-input__left">
          <span class="compact-input__icon icon-search"></span>
        </div>

        <input
          type="text"
          class="compact-input__control"
          [formControl]="searchControl"
          [placeholder]="'Search layers'"
          (keyup)="onSearchKey($event)"
          appFocusMonitor
          #input
          #focus="appFocusMonitor"
        />

        <div *ngIf="searchControl.value | appIsSet" class="compact-input__right">
          <span class="compact-input__close icon-close" (click)="clearSearch()"></span>
        </div>
      </div>
    </div>

    <div class="view-editor-navigator__header-item view-editor-navigator__header-item_fill">
      <div
        class="compact-list-item compact-list-item_fill compact-list-item_bright compact-list-item_interactive"
        [class.compact-list-item_active]="editorContext.customizingView$ | async"
        (click)="editorContext.customizeView()"
      >
        <div class="compact-list-item__left">
          <div class="compact-list-item__arrow icon-arrow_down_2"></div>
          <div class="compact-list-item__icon icon-canvas"></div>
        </div>

        <div class="compact-list-item__main">
          <div class="compact-list-item__title">Canvas</div>
        </div>
      </div>
    </div>
  </div>

  <div class="view-editor-navigator__content">
    <div class="view-editor-navigator__content-viewport" xsScrollable #scrollable="scrollableDirective">
      <app-view-editor-navigator-items
        appDropListGroup
        [items]="view.layers"
        [search]="searchControl.value"
      ></app-view-editor-navigator-items>
    </div>

    <gxs-scrollbar [theme]="'jet-mini'" [scrollable]="scrollable"></gxs-scrollbar>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { PopupPosition, PopupSettings, PopupStyle } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, ParameterArray } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';

export const validateActions: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarPopupEditForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value || !control.value.length) {
    return of(null);
  }

  return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
    map(result => {
      if (result.some(configured => !configured)) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomizeBarPopupEditForm extends FormGroup {
  popup: PopupSettings;
  styleOptions = [
    {
      style: PopupStyle.Modal,
      position: PopupPosition.Center,
      name: 'Modal',
      image: 'popup-modal'
    },
    {
      style: PopupStyle.ModalBackground,
      position: PopupPosition.Center,
      name: 'Modal with background',
      image: 'popup-modal-background'
    },
    {
      style: PopupStyle.Modal,
      position: PopupPosition.SideRight,
      name: 'Slide right',
      image: 'popup-side-right'
    },
    {
      style: PopupStyle.ModalBackground,
      position: PopupPosition.SideRight,
      name: 'Slide right with background',
      image: 'popup-side-right-background'
    },
    {
      style: PopupStyle.Modal,
      position: PopupPosition.Origin,
      name: 'Dropdown',
      image: 'popup-origin'
    },
    {
      style: PopupStyle.ModalBackground,
      position: PopupPosition.Origin,
      name: 'Dropdown with background',
      image: 'popup-origin-background'
    }
  ];

  controls: {
    name: FormControl;
    title: FieldInputControl;
    style: FormControl;
    position: FormControl;
    overlay: FormControl;
    close_button: FormControl;
    close_on_blur: FormControl;
    parameters: ParameterArray;
    open_actions: FormControl;
    close_actions: FormControl;
  };

  constructor(public elementConfigurationService: ElementConfigurationService) {
    super({
      name: new FormControl(''),
      title: new FieldInputControl({ name: 'value' }),
      style: new FormControl(PopupStyle.Modal),
      position: new FormControl(PopupPosition.Center),
      overlay: new FormControl(true),
      close_button: new FormControl(true),
      close_on_blur: new FormControl(true),
      parameters: new ParameterArray([]),
      open_actions: new FormControl([], undefined, validateActions),
      close_actions: new FormControl([], undefined, validateActions)
    });
  }

  init(popup: PopupSettings, firstInit = false) {
    this.popup = popup;

    this.controls.name.patchValue(popup.name);
    this.controls.title.patchValue(popup.title ? popup.title.serialize() : {});
    this.controls.style.patchValue(popup.style);
    this.controls.position.patchValue(popup.position);
    this.controls.overlay.patchValue(popup.overlay);
    this.controls.close_button.patchValue(popup.closeButton);
    this.controls.close_on_blur.patchValue(popup.closeOnBlur);
    this.controls.parameters.patchValue(popup.parameters);
    this.controls.open_actions.patchValue(popup.openActions);
    this.controls.close_actions.patchValue(popup.closeActions);

    this.markAsPristine();
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.open_actions, this.controls.close_actions]);
  }

  submit(): PopupSettings {
    const instance: PopupSettings = cloneDeep(this.popup);

    instance.name = this.controls.name.value;
    instance.title = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.style = this.controls.style.value;
    instance.position = this.controls.position.value;
    instance.overlay = this.controls.overlay.value;
    instance.closeButton = this.controls.close_button.value;
    instance.closeOnBlur = this.controls.close_on_blur.value;
    instance.parameters = this.controls.parameters.value;

    if (this.controls.open_actions.value) {
      instance.openActions = this.controls.open_actions.value;
    }

    if (this.controls.close_actions.value) {
      instance.closeActions = this.controls.close_actions.value;
    }

    return instance;
  }
}

import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxSelectModule } from 'ng-gxselect';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { CodeModule } from '@common/code';
import { DynamicComponentModule } from '@common/dynamic-component';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { AiModule } from '@modules/ai';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { SidebarModule } from '@modules/sidebar';
import { TokensComponentsModule } from '@modules/tokens-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { InputTokenValueComponent } from './components/input-token-value/input-token-value.component';
import { InputTokensListItemComponent } from './components/input-tokens-list-item/input-tokens-list-item.component';
import { InputTokensListComponent } from './components/input-tokens-list/input-tokens-list.component';
import { InputTokensComponent } from './components/input-tokens/input-tokens.component';
import { QueryBuilderCollapseComponent } from './components/query-builder-collapse/query-builder-collapse.component';
import { QueryBuilderDataResponseComponent } from './components/query-builder-data-response/query-builder-data-response.component';
import { QueryBuilderHttpRequestParametersComponent } from './components/query-builder-http-request-parameters/query-builder-http-request-parameters.component';
import { QueryBuilderHttpResponseComponent } from './components/query-builder-http-response/query-builder-http-response.component';
import { QueryBuilderHttpComponent } from './components/query-builder-http/query-builder-http.component';
import { QueryBuilderListQueryParametersComponent } from './components/query-builder-list-query-parameters/query-builder-list-query-parameters.component';
import { QueryBuilderObjectStructureNodeComponent } from './components/query-builder-object-structure-node/query-builder-object-structure-node.component';
import { QueryBuilderObjectStructureComponent } from './components/query-builder-object-structure/query-builder-object-structure.component';
import { QueryBuilderObjectComponent } from './components/query-builder-object/query-builder-object.component';
import { QueryBuilderParametersEditItemComponent } from './components/query-builder-parameters-edit-item/query-builder-parameters-edit-item.component';
import { QueryBuilderParametersEditComponent } from './components/query-builder-parameters-edit/query-builder-parameters-edit.component';
import { QueryBuilderParametersTypeMenuComponent } from './components/query-builder-parameters-type-menu/query-builder-parameters-type-menu.component';
import { QueryBuilderParametersComponent } from './components/query-builder-parameters/query-builder-parameters.component';
import { QueryBuilderResponseKeysComponent } from './components/query-builder-response-keys/query-builder-response-keys.component';
import { QueryBuilderResponseTransformComponent } from './components/query-builder-response-transform/query-builder-response-transform.component';
import { QueryBuilderSimpleComponent } from './components/query-builder-simple/query-builder-simple.component';
import { QueryBuilderSqlComponent } from './components/query-builder-sql/query-builder-sql.component';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';
import { TextToSqlOverlayComponent } from './components/text-to-sql-overlay/text-to-sql-overlay.component';
import { TokenInputDirective } from './directives/token-input/token-input.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    NgGxSelectModule,
    FieldsModule,
    FieldComponentsModule,
    TokensComponentsModule,
    FeaturesModule,
    SharedModule,
    DynamicComponentModule,
    LocalizeModule,
    MatMenuModule,
    SidebarModule,
    OverlayModule,
    ObserversModule,
    TipsModule,
    CodeModule,
    TutorialModule,
    AiModule,
    SelectModule,
    UiModule
  ],
  declarations: [
    QueryBuilderComponent,
    QueryBuilderParametersComponent,
    QueryBuilderParametersTypeMenuComponent,
    QueryBuilderHttpRequestParametersComponent,
    QueryBuilderHttpResponseComponent,
    QueryBuilderHttpComponent,
    QueryBuilderSqlComponent,
    QueryBuilderSimpleComponent,
    QueryBuilderListQueryParametersComponent,
    QueryBuilderDataResponseComponent,
    QueryBuilderResponseKeysComponent,
    QueryBuilderCollapseComponent,
    TokenInputDirective,
    InputTokensComponent,
    InputTokensListComponent,
    InputTokensListItemComponent,
    QueryBuilderResponseTransformComponent,
    QueryBuilderParametersEditComponent,
    QueryBuilderObjectComponent,
    QueryBuilderObjectStructureComponent,
    QueryBuilderObjectStructureNodeComponent,
    QueryBuilderParametersEditItemComponent,
    InputTokenValueComponent,
    TextToSqlOverlayComponent
  ],
  entryComponents: [QueryBuilderComponent, InputTokensComponent],
  exports: [QueryBuilderComponent, QueryBuilderHttpRequestParametersComponent, QueryBuilderHttpResponseComponent]
})
export class QueriesComponentsModule {}

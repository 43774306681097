import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import toPairs from 'lodash/toPairs';

import { FieldOutput } from '@modules/fields';
import { isSet, removeChildren, TypedChanges } from '@shared';

interface Listener {
  name: string;
  listener: any;
}

@Directive({
  selector: '[appCustomElement]'
})
export class CustomElementDirective implements OnInit, OnDestroy, OnChanges {
  @Input() tagName: string;
  @Input() outputs: FieldOutput[] = [];
  @Input() inputs: Object = {};
  @Output() outputEmitted = new EventEmitter<{ name: string; event: any }>();

  element: HTMLElement;
  listeners: Listener[] = [];

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.createElement();
    this.updateElementInputs();
    this.updateElementOutputs();
  }

  ngOnDestroy(): void {
    this.destroyElement();
  }

  ngOnChanges(changes: TypedChanges<CustomElementDirective>): void {
    let recreatedElement = false;

    if (changes.tagName && !changes.tagName.firstChange) {
      this.destroyElement();
      this.createElement();
      recreatedElement = true;
    }

    if (recreatedElement || (changes.inputs && !changes.inputs.firstChange)) {
      this.updateElementInputs();
    }

    if (recreatedElement || (changes.outputs && !changes.outputs.firstChange)) {
      this.updateElementOutputs();
    }
  }

  createElement() {
    if (!isSet(this.tagName)) {
      return;
    }

    this.element = document.createElement(this.tagName);
    this.el.nativeElement.appendChild(this.element);
  }

  destroyElement() {
    if (!this.element) {
      return;
    }

    this.listeners.forEach(item => this.element.removeEventListener(item.name, item.listener));
    this.listeners = [];

    removeChildren(this.el.nativeElement);

    this.element = undefined;
  }

  updateElementInputs() {
    if (!this.element) {
      return;
    }

    toPairs(this.inputs).forEach(([key, value]) => this.element.setAttribute(key, String(value)));
  }

  updateElementOutputs() {
    if (!this.element) {
      return;
    }

    const addListeners: Listener[] = [];
    const removeListeners: Listener[] = [];

    this.outputs.forEach(output => {
      if (this.listeners.find(item => item.name == output.name)) {
        return;
      }

      const listener = (event: CustomEvent) => {
        const value = event ? event.detail : undefined;
        this.outputEmitted.emit({ name: output.name, event: value });
      };

      this.element.addEventListener(output.name, listener);
      addListeners.push({ name: output.name, listener: listener });
    });

    this.listeners
      .filter(listener => !this.outputs.find(item => item.name == listener.name))
      .forEach(item => {
        this.element.removeEventListener(item.name, item.listener);
        removeListeners.push(item);
      });

    this.listeners = [
      ...this.listeners.filter(listener => !removeListeners.find(item => item.name == listener.name)),
      ...addListeners
    ];
  }
}

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div
    class="compact-select-options compact-select-options_max-height_l"
    (mouseenter)="dropdownHover$.next(true)"
    (mouseleave)="dropdownHover$.next(false)"
  >
    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!editorContext.isUndoAvailable()"
      (click)="undo(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Undo</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">Z</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!editorContext.isRedoAvailable()"
      (click)="redo(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Redo</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⇧</span>
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">Z</span>
        </div>
      </div>
    </div>

    <div class="compact-select-options__separator"></div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isLayerSelected()"
      (click)="cutLayers(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Cut</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">X</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isLayerSelected()"
      (click)="copyLayers(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Copy</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">C</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isBufferLayersSet()"
      (click)="pasteBufferLayers(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Paste</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">V</span>
        </div>
      </div>
    </div>

    <div class="compact-select-options__separator"></div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isLayerSelected()"
      (click)="groupSelected(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Group selected</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">G</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isLayerSelected()"
      (click)="frameSelected(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Frame selected</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌥ ⌘</span>
          <span class="compact-select-option__hotkey-symbol">G</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isUngroupAvailable()"
      (click)="ungroupSelected(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Ungroup selected</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">⌫</span>
        </div>
      </div>
    </div>

    <div class="compact-select-options__separator"></div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isSelectAllAvailable()"
      (click)="selectAll(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Select all</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">A</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isLayerSelected()"
      (click)="selectNone(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Select none</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="!isSelectInverseAvailable()"
      (click)="selectInverse(); onItemSelected()"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Select inverse</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

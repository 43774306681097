export const openaiResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'correct_grammar',
    params: {
      type: 'query',
      verbose_name: 'Correct grammar',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please correct the following text:" + params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'analyze_sentiment',
    params: {
      type: 'query',
      verbose_name: 'Analyze sentiment',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please classify the following text as positive, neutral, or negative: "+ params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'speech_to_text',
    params: {
      type: 'query',
      verbose_name: 'Speech to text',
      action_params: [
        {
          name: 'audio',
          verbose_name: '',
          description: '',
          field: 'audio',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        }
      ],
      outputs: [
        {
          name: 'text',
          verbose_name: 'text',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/audio/transcriptions',
            auth_params: {},
            body_type: 'FormData',
            body: [
              {
                name: 'file',
                value: '{{params.audio}}',
                type: 'file'
              },
              {
                name: 'model',
                value: 'whisper-1',
                type: 'text'
              }
            ],
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'multipart/form-data'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: '',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'summarize',
    params: {
      type: 'query',
      verbose_name: 'Summarize',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please summarize the following text:" + params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'answer_question',
    params: {
      type: 'query',
      verbose_name: 'Answer question',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'extract_keywords',
    params: {
      type: 'query',
      verbose_name: 'Extract keywords',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please extract the keywords from the following text:" + params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'suggest_emoji',
    params: {
      type: 'query',
      verbose_name: 'Suggest emoji',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please suggest an emoji that " + params.message}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'generate_image',
    params: {
      type: 'query',
      verbose_name: 'Generate image',
      action_params: [
        {
          name: 'description',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'size',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: '',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: '256x256',
                value: '256x256',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: '512x512',
                value: '512x512',
                subtitle: '',
                icon: ''
              },
              {
                color: 'red',
                name: '1024x1024',
                value: '1024x1024',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'url',
          verbose_name: 'url',
          field: 'image',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/images/generations',
            auth_params: {},
            body_type: 'JSON',
            body: '{\n  "prompt": params.description,\n  "n": 1,\n  ...(params.size && {"size": params.size})\n  }',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: 'data.0',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'translate_audio',
    params: {
      type: 'query',
      verbose_name: 'translate audio to English',
      action_params: [
        {
          name: 'audio',
          verbose_name: '',
          description: '',
          field: 'audio',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        }
      ],
      outputs: [
        {
          name: 'text',
          verbose_name: 'text',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/audio/translations',
            auth_params: {},
            body_type: 'FormData',
            body: [
              {
                name: 'file',
                value: '{{params.audio}}',
                type: 'file'
              },
              {
                name: 'model',
                value: 'whisper-1',
                type: 'text'
              }
            ],
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: '',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'suggest_color',
    params: {
      type: 'query',
      verbose_name: 'Suggest color',
      action_params: [
        {
          name: 'message',
          verbose_name: '',
          description: '',
          field: 'text',
          required: true,
          default_value: '',
          placeholder: '',
          params: {}
        },
        {
          name: 'temperature',
          verbose_name: '',
          description:
            'What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'default is 1',
          params: {}
        },
        {
          name: 'max_tokens',
          verbose_name: 'max tokens',
          description:
            'The maximum number of tokens to generate. One token generally corresponds to ~4 characters (¾ of a word) of text for common English text, so 100 tokens ~= 75 words.',
          field: 'number',
          required: false,
          default_value: '',
          placeholder: 'no limit by default',
          params: {}
        },
        {
          name: 'model',
          verbose_name: '',
          description: '',
          field: 'select',
          required: false,
          default_value: '',
          placeholder: 'default is gpt-3.5-turbo',
          params: {
            options_type: 'static',
            options: [
              {
                color: 'blue',
                name: 'gpt-3.5-turbo',
                value: 'gpt-3.5-turbo',
                subtitle: '',
                icon: ''
              },
              {
                color: 'green',
                name: 'gpt-4-turbo-preview',
                value: 'gpt-4-turbo-preview',
                subtitle: '',
                icon: ''
              }
            ],
            parameters: [],
            inputs: [],
            columns: []
          }
        }
      ],
      outputs: [
        {
          name: 'result',
          verbose_name: 'result',
          field: 'text',
          required: false,
          params: {}
        }
      ],
      array_output: false,
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.openai.com/v1/chat/completions',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n  "model": params.model || "gpt-3.5-turbo",\n  "messages": [{"role": "user", "content": "Please suggest a color for " + params.message + " in hex format."}],\n  "temperature": params.temperature,\n  "max_tokens": params.max_tokens\n}',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              },
              {
                name: 'Content-Type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_transformer:
              'return {\n  result: data.choices.length ? data.choices[0].message.content : null\n};',
            error_transformer:
              "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}",
            request_tokens: {}
          }
        }
      }
    }
  }
];

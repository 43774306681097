import { Params, PRIMARY_OUTLET, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import keys from 'lodash/keys';

export function containsTree(container: UrlTree, containee: UrlTree, exact: boolean): boolean {
  if (exact) {
    return (
      equalQueryParams(container.queryParams, containee.queryParams) &&
      equalSegmentGroups(container.root, containee.root)
    );
  }

  return (
    containsQueryParams(container.queryParams, containee.queryParams) &&
    containsSegmentGroup(container.root, containee.root)
  );
}

function equalQueryParams(container: Params, containee: Params): boolean {
  // TODO: This does not handle array params correctly.
  return shallowEqual(container, containee);
}

function equalSegmentGroups(container: UrlSegmentGroup, containee: UrlSegmentGroup): boolean {
  if (!equalPath(container.segments, containee.segments)) {
    return false;
  }

  if (container.numberOfChildren !== containee.numberOfChildren) {
    return false;
  }

  for (const c of keys(containee.children)) {
    if (!container.children[c]) {
      return false;
    }
    if (!equalSegmentGroups(container.children[c], containee.children[c])) {
      return false;
    }
  }

  return true;
}

function containsQueryParams(container: Params, containee: Params): boolean {
  // TODO: This does not handle array params correctly.
  return (
    Object.keys(containee).length <= Object.keys(container).length &&
    Object.keys(containee).every(key => containee[key] === container[key])
  );
}

function containsSegmentGroup(container: UrlSegmentGroup, containee: UrlSegmentGroup): boolean {
  return containsSegmentGroupHelper(container, containee, containee.segments);
}

function containsSegmentGroupHelper(
  container: UrlSegmentGroup,
  containee: UrlSegmentGroup,
  containeePaths: UrlSegment[]
): boolean {
  if (container.segments.length > containeePaths.length) {
    const current = container.segments.slice(0, containeePaths.length);
    if (!equalPath(current, containeePaths)) {
      return false;
    }
    if (containee.hasChildren()) {
      return false;
    }
    return true;
  } else if (container.segments.length === containeePaths.length) {
    if (!equalPath(container.segments, containeePaths)) {
      return false;
    }
    for (const c of keys(containee.children)) {
      if (!container.children[c]) {
        return false;
      }
      if (!containsSegmentGroup(container.children[c], containee.children[c])) {
        return false;
      }
    }
    return true;
  } else {
    const current = containeePaths.slice(0, container.segments.length);
    const next = containeePaths.slice(container.segments.length);
    if (!equalPath(container.segments, current)) {
      return false;
    }
    if (!container.children[PRIMARY_OUTLET]) {
      return false;
    }
    return containsSegmentGroupHelper(container.children[PRIMARY_OUTLET], containee, next);
  }
}

function equalPath(as: UrlSegment[], bs: UrlSegment[]): boolean {
  if (as.length !== bs.length) {
    return false;
  }
  return as.every((a, i) => a.path === bs[i].path);
}

function shallowEqual(a: { [x: string]: any }, b: { [x: string]: any }): boolean {
  const k1 = Object.keys(a);
  const k2 = Object.keys(b);
  if (k1.length != k2.length) {
    return false;
  }
  let key: string;
  for (let i = 0; i < k1.length; i++) {
    key = k1[i];
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { PageLoadingService } from '@common/page-loading';

import { PublicSettings } from '../data/public-settings';
import { ProjectService } from '../services/project/project.service';

@Injectable({ providedIn: 'root' })
export class PublicSettingsResolver implements Resolve<PublicSettings> {
  constructor(private projectService: ProjectService, private pageLoadingService: PageLoadingService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PublicSettings> {
    const projectName = route.params['project'];
    if (!projectName) {
      return of(undefined);
    }

    const loader = {};
    this.pageLoadingService.addLoader(loader);

    return this.projectService.getPublicSettings(projectName).pipe(
      catchError(() => of(undefined)),
      tap(() => this.pageLoadingService.removeLoader(loader))
    );
  }
}

import { Injectable, Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import range from 'lodash/range';

import { ModelDescription } from '@modules/models';

import { ImportModelsFieldControl } from './import-models-field.control';

@Injectable()
export class ImportModelsFieldArray extends FormArray {
  modelDescription: ModelDescription;
  controls: ImportModelsFieldControl[];

  constructor(private injector: Injector) {
    super([]);
  }

  init(modelDescription: ModelDescription) {
    this.modelDescription = modelDescription;
  }

  createControl(): ImportModelsFieldControl {
    const control = Injector.create({
      providers: [{ provide: ImportModelsFieldControl, deps: [] }],
      parent: this.injector
    }).get<ImportModelsFieldControl>(ImportModelsFieldControl);

    control.init(this.modelDescription);

    return control;
  }

  setControls(controls: ImportModelsFieldControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }
}

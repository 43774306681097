import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { FormUtils } from '@common/form-utils';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { detectFieldByValue, FieldType, OutputFormat, TimeOutputFormat } from '@modules/fields';
import { MenuGeneratorService, MenuItemType, MenuSettings, ModelLinkMenuItem, SectionMenuItem } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ProjectSettingsService } from '@modules/project-settings';
import {
  CryptService,
  Environment,
  Project,
  ProjectTokenService,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import {
  HttpContentType,
  HttpMethod,
  HttpQuery,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryPagination,
  QueryService,
  QueryType
} from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { ResourceParamsResult } from '@modules/resources';
import { AppError, generateUUID, isSet } from '@shared';

// TODO: Refactor import
import { SheetValue } from '@modules/projects-components/components/resource-settings/google-sheets-resource-settings/google-sheets-resource-settings-file/google-sheets-resource-settings-file.component';
import { GoogleDriveFile } from '@modules/projects-components/data/google-sheets-resource-params.data';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

const primaryKey = '__jet_pk__';
const readOnlyFields = [primaryKey];

const responseTransformerMapItem = `
function mapItem(item, pk, fields) {
  const model = {
    ${primaryKey}: pk
  };
  fields.forEach((name, i) => {
    if (item[i] !== undefined) {
      model[name] = item[i];
    } else {
      model[name] = '';
    }
  });
  return model;
}`;

const responseTransformerGetItems = `
function isSet(value) {
  return !(value === null || value === undefined || value === '');
}
function getItems(valueRanges) {
  const r = /^[^!]+!+[A-z]+(\\d+):[A-z]+(\\d+)$/.exec(valueRanges[1]['range']);
  const startFrom = r ? parseInt(r[1]) : 1;
  const values = valueRanges[0]['values'].concat(valueRanges[1]['values'] || [[]]);
  const fields = values[0].map((item, i) => isSet(item) ? item : 'field_' + (i + 1));
  return {
    fields: fields,
    items: values.slice(1).map((item, i) => mapItem(item, startFrom + i - 1, fields))
  };
}`;

const maxRows = 99999;
const listResponseTransformer = `${responseTransformerMapItem}
${responseTransformerGetItems}
var result = getItems(data['valueRanges']);
return result.items;
`;

const detailResponseTransformer = `${responseTransformerGetItems}
${responseTransformerMapItem}
var result = getItems(data['valueRanges']);
return result.items[0];`;

const createResponseTransformer = (fieldNames: string[]) => `
const range = data['updates']['updatedData']['range'].split('!', 2);
const coords = range[1].split(':', 2);
const pk = parseInt(coords[0].substring(1)) - 1;
const fieldNames = JSON.parse(${JSON.stringify(JSON.stringify(fieldNames.filter(item => item != primaryKey)))});
const values = data['updates']['updatedData']['values'][0];
const result = {
  ${primaryKey}: pk
};

values.forEach((item, i) => result[fieldNames[i]] = item);

return result;
`;

const updateResponseTransformer = (fieldNames: string[]) => `
const range = data['updatedData']['range'].split('!', 2);
const coords = range[1].split(':', 2);
const pk = parseInt(coords[0].substring(1)) - 1;
const fieldNames = JSON.parse(${JSON.stringify(JSON.stringify(fieldNames.filter(item => item != primaryKey)))});
const values = data['updatedData']['values'][0];
const result = {
  ${primaryKey}: pk
};

values.forEach((item, i) => result[fieldNames[i]] = item);

return result;
`;

const bodyTransformer = (fields: ModelField[]) => {
  const fieldNames = fields.filter(item => item.name != primaryKey).map(item => item.name);
  return `
const fieldNames = JSON.parse(${JSON.stringify(JSON.stringify(fieldNames))});
return {
  values: [fieldNames.map(field => {
    if (data[field] === undefined) {
        return null;
    } else if (data[field] === null) {
        return 'NULL';
    } else {
        return data[field];
    }
  })]
};
`;
};

const errorTransformer = `// add custom transformation here
if (http.code >= 200 && http.code < 400) {
  // no error if success code
  return null;
} else if (http.code == 400 && data && data['error'] && data['error']['message'] && data['error']['message'].includes('exceeds grid limits')) {
  return null;
} else if (data['error'] && data['error']['message']) {
  // display error message if any
  return data['error']['message'];
} else {
  // display error without message otherwise
  return true;
}`;

export const googleDriveFileIdParam = 'google_drive_file_id';
export const googleDriveFileUidParam = 'google_drive_file_uid';

export interface GoogleSheetsParamsOptionsFile {
  uid: string;
  unique_name?: string;
  verbose_name: string;
  sheet: SheetValue;
  range: string;
  file: GoogleDriveFile;
}

export interface GoogleSheetsParamsOptions {
  access_token: string;
  token_params: Object;
  files: GoogleSheetsParamsOptionsFile[];
}

export interface SpreadsheetSheetRowValue {
  effectiveFormat: {
    numberFormat: {
      type: string;
    };
  };
  effectiveValue: Object;
  formattedValue: any;
  userEnteredValue: Object;
}

export interface SpreadsheetSheetData {
  columnMetadata: {
    pixelSize: number;
  }[];
  rowMetadata: {
    pixelSize: number;
  }[];
  rowData?: {
    values?: SpreadsheetSheetRowValue[];
  }[];
}

export interface SpreadsheetSheetProperties {
  gridProperties: {
    columnCount: number;
    rowCount: number;
  };
  index: number;
  sheetId: number;
  sheetType: string;
  title: string;
}

export interface SpreadsheetResponse {
  properties: Object;
  sheets: {
    properties: SpreadsheetSheetProperties;
    data: SpreadsheetSheetData[];
  }[];
  spreadsheetId: string;
  spreadsheetUrl: string;
}

@Injectable()
export class GoogleSheetsGeneratorService extends ResourceGeneratorService<GoogleSheetsParamsOptions> {
  tokenName = 'oauth_access_token';
  latestFileContents: SpreadsheetSheetData[];

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private cryptService: CryptService,
    private appConfigService: AppConfigService,
    private menuGeneratorService: MenuGeneratorService,
    private apiService: ApiService,
    private injector: Injector,
    private http: HttpClient,
    private modelDescriptionStore: ModelDescriptionStore,
    private queryService: QueryService,
    private queryTokensService: QueryTokensService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService
  ) {
    super();
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<GoogleSheetsParamsOptions> {
    return combineLatest(
      this.secretTokenService.getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        this.tokenName,
        this.mode == AdminMode.Builder
      ),
      this.modelDescriptionStore.getFirst()
    ).pipe(
      map(([secretToken, modelDescriptions]) => {
        // TODO: Add Google Sheet params class
        const files: GoogleSheetsParamsOptionsFile[] = resource.params['files']
          .map(file => {
            if (!file['file']) {
              return;
            }

            const modelDescription = modelDescriptions
              .filter(item => item.resource == resource.uniqueName && item.params)
              .find(item => {
                if (file['uid']) {
                  return item.params[googleDriveFileUidParam] == file['uid'];
                } else {
                  return item.params[googleDriveFileIdParam] == file['file'].id;
                }
              });

            if (!modelDescription) {
              return;
            }

            return {
              uid: file['uid'] || generateUUID(),
              unique_name: modelDescription.model,
              verbose_name: modelDescription.verboseNamePlural,
              sheet: file['sheet'],
              range: file['range'],
              file: file['file']
            };
          })
          .filter(item => item != undefined);

        return {
          access_token: secretToken.value,
          token_params: secretToken.params,
          files: files
        };
      })
    );
  }

  getUniqueName(name: string) {
    return slugify(name, { trim: true, separator: '_' }).replace(/_+/g, '_');
  }

  createModelDescriptionFields(
    options: GoogleSheetsParamsOptions,
    spreadsheetId: string,
    modelDescriptionName: string,
    sheetName: string,
    xFrom: string,
    yFrom: number,
    xTo: string
  ): Observable<{
    fields: ModelField[];
    sheetProperties: SpreadsheetSheetProperties;
  }> {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`;
    const setUpHeaders = [
      {
        name: 'Authorization',
        value: `Bearer ${options.access_token}`
      }
    ];
    const setUpParams = [
      { name: 'ranges', value: `'${sheetName}'!${xFrom}${yFrom}:${xTo}${yFrom}` },
      { name: 'ranges', value: `'${sheetName}'!${xFrom}${0 + 2}:${xTo}${0 + 20 + 1}` },
      { name: 'includeGridData', value: 'true' }
    ];

    const headers = setUpHeaders.reduce((prev, current) => prev.append(current.name, current.value), new HttpHeaders());
    const queryParams = setUpParams.reduce(
      (prev, current) => prev.append(current.name, current.value),
      new HttpParams()
    );

    return this.http
      .get<SpreadsheetResponse>(url, { headers: headers, params: queryParams })
      .pipe(
        map(result => {
          const data = result.sheets[0].data;
          const properties = result.sheets[0].properties;
          const headerRange = data[0];
          const dataRange = data[1];

          this.latestFileContents = data;

          if (!dataRange.rowData) {
            throw new AppError(
              `File "${modelDescriptionName}" has no data for selected range. It should contain at least one record (excluding headers).`
            );
          }

          const cleanHeader = (value: SpreadsheetSheetRowValue, index: number) => {
            if (value && value.formattedValue && isSet(value.formattedValue)) {
              return value.formattedValue;
            } else {
              return `field_${index + 1}`;
            }
          };

          const rows: {
            name: string;
            type: FieldType;
            params: Object;
            value: any;
          }[][] = (dataRange.rowData || []).map(row => [
            {
              name: primaryKey,
              type: FieldType.Number,
              params: {},
              value: 1
            },
            ...(row.values || []).map((item, col) => {
              const [valueType, value] =
                item.effectiveValue && keys(item.effectiveValue).length
                  ? toPairs(item.effectiveValue)[0]
                  : [undefined, undefined];
              const name = cleanHeader(headerRange.rowData[0].values[col], col);
              let type: FieldType;
              let params = {};

              if (valueType == 'numberValue') {
                const numberType = item.effectiveFormat.numberFormat
                  ? item.effectiveFormat.numberFormat.type
                  : undefined;
                if (numberType == 'DATE') {
                  type = FieldType.DateTime;
                  params['output_format'] = OutputFormat.SerialNumberDate;
                  params['date'] = true;
                  params['time'] = false;
                } else if (numberType == 'DATE_TIME') {
                  type = FieldType.DateTime;
                  params['output_format'] = OutputFormat.SerialNumber;
                } else if (numberType == 'TIME') {
                  type = FieldType.Time;
                  params['output_format'] = TimeOutputFormat.Number;
                }
              }

              if (type == undefined) {
                const valueField = detectFieldByValue(value);
                type = valueField.field;
                params = valueField.params || params;
              }

              return {
                name: name,
                type: type,
                params: params,
                value: value
              };
            })
          ]);

          // Detect fields by first non empty cell
          const fields = rows.reduce((acc, row) => {
            row.forEach(column => {
              if (acc[column.name] || !isSet(column.value)) {
                return;
              }
              acc[column.name] = {
                type: column.type,
                params: column.params
              };
            });
            return acc;
          }, {});

          // Fallback cells without content to first row settings
          if (rows.length) {
            rows[0].forEach(column => {
              if (fields[column.name]) {
                return;
              }
              fields[column.name] = {
                type: column.type,
                params: column.params
              };
            });
          }

          const resultFields = [
            primaryKey,
            ...headerRange.rowData[0].values.map((headerItem, i) => cleanHeader(headerItem, i))
          ].map(name => {
            const item = fields[name];
            const field = new ModelField();
            const dbField = new ModelDbField();

            dbField.name = name;

            if (dbField.name == primaryKey) {
              dbField.verboseName = 'row';
            }

            if (item) {
              dbField.field = item['type'] as FieldType;
              dbField.params = item['params'];
            }

            dbField.editable = !readOnlyFields.includes(name);
            dbField.filterable = !readOnlyFields.includes(name);
            dbField.sortable = !readOnlyFields.includes(name);
            dbField.required = false;
            dbField.updateFieldDescription();

            field.name = name;
            field.type = ModelFieldType.Db;
            field.item = dbField;

            return field;
          });

          resultFields.reduce((acc, item) => {
            const key = String(item.name).toLowerCase();
            if (acc.hasOwnProperty(key)) {
              throw new AppError(
                `File "${modelDescriptionName}" has multiple fields with the same name: ${item.name}.`
              );
            }
            acc[key] = true;
            return acc;
          }, {});

          return {
            fields: resultFields,
            sheetProperties: properties
          };
        })
      );
  }

  createModelDescription(
    options: GoogleSheetsParamsOptions,
    file: GoogleSheetsParamsOptionsFile
  ): Observable<ModelDescription> {
    if (!file.file || !file.sheet || !file.range) {
      return of(undefined);
    }

    const modelDescription = new ModelDescription();

    modelDescription.project = '{{project}}';
    modelDescription.resource = '{{resource}}';
    modelDescription.model = file.unique_name || this.getUniqueName(file.verbose_name);
    modelDescription.verboseName = file.verbose_name;
    modelDescription.verboseNamePlural = file.verbose_name;
    modelDescription.primaryKeyField = primaryKey;
    modelDescription.params = {
      [googleDriveFileUidParam]: file.uid
    };

    const queryHeaders = [
      {
        name: 'Authorization',
        value: `Bearer {-${this.tokenName}-}`
      }
    ];

    // const sheet = item.sheet as SheetValue;
    // const file = item.file as GoogleDriveFile;

    const sheetName = file.sheet.name;
    const coords = file.range.match(/^([A-Z]+)(\d+)?:([A-Z]+)(\d+)?$/);
    const xFrom = coords[1];
    const yFrom = parseInt(coords[2], 10) || 1;
    const xTo = coords[3];

    return this.createModelDescriptionFields(
      options,
      file.file.id,
      file.verbose_name,
      sheetName,
      xFrom,
      yFrom,
      xTo
    ).pipe(
      map(result => {
        modelDescription.fields = result.fields;
        modelDescription.defaultFields = cloneDeep(result.fields);

        modelDescription.getQuery = new ListModelDescriptionQuery();
        const getHttpQuery = new HttpQuery();

        getHttpQuery.method = HttpMethod.GET;
        getHttpQuery.url = `https://sheets.googleapis.com/v4/spreadsheets/${file.file.id}/values:batchGet`;
        getHttpQuery.headers = queryHeaders;
        getHttpQuery.queryParams = [
          { name: 'ranges', value: `'${sheetName}'!${xFrom}${yFrom}:${xTo}${yFrom}` },
          { name: 'ranges', value: `'${sheetName}'!${xFrom}${yFrom + 1}:${xTo}${maxRows}` },
          { name: 'valueRenderOption', value: 'UNFORMATTED_VALUE' }
        ];
        getHttpQuery.responseTransformer = listResponseTransformer;
        getHttpQuery.errorTransformer = errorTransformer;

        modelDescription.getQuery.queryType = QueryType.Http;
        modelDescription.getQuery.httpQuery = getHttpQuery;
        modelDescription.getQuery.pagination = QueryPagination.Offset;
        modelDescription.getQuery.frontendFiltering = true;
        modelDescription.getQuery.sorting = true;

        // modelDescription.getDetailQuery = new ModelDescriptionQuery();
        // const getDetailHttpQuery = new HttpQuery();
        //
        // getDetailHttpQuery.method = HttpMethod.GET;
        // getDetailHttpQuery.url = getHttpQuery.url;
        // getDetailHttpQuery.headers = queryHeaders;
        // getDetailHttpQuery.queryParams = [
        //   { name: 'ranges', value: `'${sheetName}'!${xFrom}${yFrom}:${xTo}${yFrom}` },
        //   {
        //     name: 'ranges',
        //     value: `'${sheetName}'!${xFrom}{{(parseInt(params.${primaryKey}) || ${yFrom})+1}}:${xTo}{{(parseInt(params.${primaryKey}) || ${maxRows})+1}}`
        //   },
        //   { name: 'valueRenderOption', value: 'UNFORMATTED_VALUE' }
        // ];
        // getDetailHttpQuery.responseTransformer = detailResponseTransformer;
        // getDetailHttpQuery.errorTransformer = errorTransformer;
        //
        // modelDescription.getDetailQuery.queryType = QueryType.Http;
        // modelDescription.getDetailQuery.httpQuery = getDetailHttpQuery;
        // modelDescription.getDetailParameters = [...modelDescription.getParameters];

        if (file.file.capabilities && file.file.capabilities.canEdit) {
          modelDescription.createQuery = new ModelDescriptionQuery();
          const createHttpQuery = new HttpQuery();

          createHttpQuery.method = HttpMethod.POST;
          createHttpQuery.url = `https://sheets.googleapis.com/v4/spreadsheets/${
            file.file.id
          }/values/'${encodeURIComponent(sheetName)}'!${file.range}:append`;
          createHttpQuery.headers = queryHeaders;
          createHttpQuery.queryParams = [
            { name: 'valueInputOption', value: 'raw' },
            { name: 'includeValuesInResponse', value: 'true' },
            { name: 'responseValueRenderOption', value: 'UNFORMATTED_VALUE' }
          ];
          createHttpQuery.bodyTransformer = bodyTransformer(result.fields);
          createHttpQuery.responseTransformer = createResponseTransformer(result.fields.map(item => item.name));
          createHttpQuery.errorTransformer = errorTransformer;

          modelDescription.createQuery.queryType = QueryType.Http;
          modelDescription.createQuery.httpQuery = createHttpQuery;
          modelDescription.createParametersUseDefaults = true;
        }

        if (file.file.capabilities && file.file.capabilities.canEdit) {
          modelDescription.updateQuery = new ModelDescriptionQuery();
          const updateHttpQuery = new HttpQuery();

          updateHttpQuery.method = HttpMethod.PUT;
          updateHttpQuery.url = `https://sheets.googleapis.com/v4/spreadsheets/${
            file.file.id
          }/values/'${encodeURIComponent(
            sheetName
          )}'!${xFrom}{{parseInt(params.${primaryKey})+1}}:${xTo}{{parseInt(params.${primaryKey})+1}}`;
          updateHttpQuery.headers = queryHeaders;
          updateHttpQuery.queryParams = [
            { name: 'valueInputOption', value: 'raw' },
            { name: 'includeValuesInResponse', value: 'true' },
            { name: 'responseValueRenderOption', value: 'UNFORMATTED_VALUE' }
          ];
          updateHttpQuery.bodyTransformer = bodyTransformer(result.fields);
          updateHttpQuery.responseTransformer = updateResponseTransformer(result.fields.map(item => item.name));
          updateHttpQuery.errorTransformer = errorTransformer;

          modelDescription.updateQuery.queryType = QueryType.Http;
          modelDescription.updateQuery.httpQuery = updateHttpQuery;
          modelDescription.updateParametersUseDefaults = true;
        }

        if (file.file.capabilities && file.file.capabilities.canEdit) {
          modelDescription.deleteQuery = new ModelDescriptionQuery();
          const deleteHttpQuery = new HttpQuery();

          deleteHttpQuery.method = HttpMethod.POST;
          deleteHttpQuery.url = `https://sheets.googleapis.com/v4/spreadsheets/${file.file.id}:batchUpdate`;
          deleteHttpQuery.headers = queryHeaders;
          deleteHttpQuery.bodyType = HttpContentType.Raw;
          deleteHttpQuery.body = JSON.stringify(
            {
              requests: [
                {
                  deleteDimension: {
                    range: {
                      sheetId: result.sheetProperties.sheetId,
                      dimension: 'ROWS',
                      startIndex: `{{params.${primaryKey}}}`,
                      endIndex: `{{parseInt(params.${primaryKey})+1}}`
                    }
                  }
                }
              ]
            },
            undefined,
            2
          );
          deleteHttpQuery.errorTransformer = errorTransformer;

          modelDescription.deleteQuery.queryType = QueryType.Http;
          modelDescription.deleteQuery.httpQuery = deleteHttpQuery;
          modelDescription.deleteParametersUseDefaults = true;
        }

        return modelDescription;
      }),
      this.apiService.catchApiError<ModelDescription, ModelDescription>()
    );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: GoogleSheetsParamsOptions
  ): Observable<ResourceParamsResult> {
    const obs = options.files.map(item => this.createModelDescription(options, item));

    return combineLatest(obs).pipe(
      map((modelDescriptions: ModelDescription[]) => modelDescriptions.filter(item => item != undefined)),
      map((modelDescriptions: ModelDescription[]) => {
        const token = new SecretToken();

        token.resource = '{{resource}}';
        token.name = this.tokenName;
        token.type = SecretTokenType.OAuth;
        token.value = options.access_token;

        try {
          token.params = options.token_params;
        } catch (e) {
          token.params = {};
        }

        const resourceParams = {
          files: options.files.map(item => {
            return {
              uid: item['uid'],
              sheet: item['sheet'],
              range: item['range'],
              file: item['file']
            };
          })
        };

        return {
          resourceParams: resourceParams,
          modelDescriptions: modelDescriptions.map(item => item.serialize()),
          secretTokens: [token.serialize()]
        };
      })
    );
  }
}

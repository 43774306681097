var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@common/dialogs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarTimelineSettingsDataSourceComponent } from './customize-bar-timeline-settings-data-source/customize-bar-timeline-settings-data-source.component';
import { CustomizeBarTimelineSettingsForm } from './customize-bar-timeline-settings.form';
var CustomizeBarTimelineSettingsComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarTimelineSettingsComponent, _super);
    function CustomizeBarTimelineSettingsComponent(form, customizeBarContext, dialogService, analyticsService, cd) {
        var _this = _super.call(this, form, customizeBarContext, dialogService, analyticsService, cd) || this;
        _this.form = form;
        _this.itemContextElementPath = [ITEM_OUTPUT];
        _this.selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
        _this.dataSourceEditComponent = CustomizeBarTimelineSettingsDataSourceComponent;
        _this.collapseContext = new SidebarCollapseContext();
        _this.actionsRequiredFields = ['card_click_action', 'actions', 'model_actions'];
        _this.customViewTemplateTypes = CustomViewTemplateType;
        return _this;
    }
    CustomizeBarTimelineSettingsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
    };
    return CustomizeBarTimelineSettingsComponent;
}(CustomizeBarListLayoutSettingsComponent));
export { CustomizeBarTimelineSettingsComponent };

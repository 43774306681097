/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./compact-checkbox.component";
var styles_CompactCheckboxComponent = [];
var RenderType_CompactCheckboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompactCheckboxComponent, data: {} });
export { RenderType_CompactCheckboxComponent as RenderType_CompactCheckboxComponent };
export function View_CompactCheckboxComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "compact-checkbox"]], [[2, "compact-checkbox_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateValue(!_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "compact-checkbox__checkmark"]], [[2, "icon-check_2", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "compact-checkbox__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.value; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); }); }
export function View_CompactCheckboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-compact-checkbox", [], null, null, null, View_CompactCheckboxComponent_0, RenderType_CompactCheckboxComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CompactCheckboxComponent]), i0.ɵdid(2, 114688, null, 0, i2.CompactCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CompactCheckboxComponentNgFactory = i0.ɵccf("app-compact-checkbox", i2.CompactCheckboxComponent, View_CompactCheckboxComponent_Host_0, { formControl: "formControl", label: "label" }, { change: "change" }, []);
export { CompactCheckboxComponentNgFactory as CompactCheckboxComponentNgFactory };

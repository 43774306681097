export function isColorHex(color: any): boolean {
  if (typeof color == 'string') {
    return color.startsWith('#');
  } else {
    return false;
  }
}

export function isColorRgb(color: any): boolean {
  if (typeof color == 'string') {
    return color.startsWith('rgb(') || color.startsWith('rgba(');
  } else {
    return false;
  }
}

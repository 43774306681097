<div class="sidebar__element">
  <div *ngIf="typeIconButton" class="sidebar-icon-buttons">
    <div
      *ngFor="let item of typeOptions || control.typeOptions"
      class="sidebar-icon-button"
      [class.sidebar-icon-button_active]="control.controls.type.value == item.value"
      (click)="control.controls.type.patchValue(item.value)"
    >
      <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>
      <div class="sidebar-icon-button__label">{{ item.name }}</div>
    </div>
  </div>

  <app-sidebar-field *ngIf="!typeIconButton" [formGroup]="control">
    <app-select
      [control]="control.controls.type"
      [placeholder]="'Choose data source'"
      [orange]="typeOrange"
      [fill]="true"
      [expand]="true"
      [options]="typeOptions || control.typeOptions"
      [searchEnabled]="false"
      #type_select
    >
    </app-select>
  </app-sidebar-field>
</div>

<div *ngIf="(control.controls.type | appControlValue | async) == dataSourceTypes.Query" class="sidebar__element">
  <div class="sidebar__element-segment">
    <app-sidebar-field [label]="label" [required]="true" [classes]="resourceClasses">
      <ng-container actions>
        <span
          *ngIf="(reloadAllowed$ | async) && !reloadLoading"
          class="sidebar__field-action icon-repeat"
          [appTip]="'Sync Now'"
          [appTipOptions]="{ side: 'top' }"
          (click)="reload()"
        ></span>
        <app-loader-small *ngIf="reloadLoading" class="sidebar__field-action"></app-loader-small>
      </ng-container>

      <app-resource-field2
        [form]="control"
        [label]="false"
        [errors]="false"
        [field]="
          createField({
            name: 'query_resource',
            field: 'ResourceField',
            params: resourceFieldParams
          })
        "
      ></app-resource-field2>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element-segment">
    <app-sidebar-field [classes]="queryOptionClasses">
      <app-custom-select
        [control]="control.controls.query"
        [items]="queryOptions$ | async"
        [compareWith]="control.queryOptionEquals"
        [emptyLabel]="queryOptionEmptyLabel"
        [classes]="['select_fill']"
        [panelClasses]="['mat-menu-panel_sidebar']"
        (optionClick)="onQueryOptionOptionClick($event)"
        (buttonClick)="onQueryOptionButtonClick($event)"
      >
      </app-custom-select>
      <app-field-errors [form]="control" [fieldName]="'query'"></app-field-errors>

      <ng-container right>
        <a
          *ngIf="queryEditable$ | async"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
          [appTip]="'Edit request'"
          (click)="editCustomQuery()"
        >
          <span class="icon-edit button__icon"></span>
        </a>

        <a
          *ngIf="modelDescriptionCustom$ | async as modelDescription"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
          [appTip]="'Edit request'"
          (click)="editSavedQuery(modelDescription)"
        >
          <span class="icon-edit button__icon"></span>
        </a>
      </ng-container>

      <ng-container description>
        <a
          *ngIf="(queryConfigured$ | async) && (queryEditable$ | async)"
          href="javascript:void(0)"
          (click)="createSavedQuery()"
        >
          <span class="icon-save" style="margin-right: 4px;"></span> Save to reuse
        </a>
      </ng-container>
    </app-sidebar-field>
  </div>
</div>

<div *ngIf="(control.controls.type | appControlValue | async) == dataSourceTypes.Input" class="sidebar__element">
  <app-sidebar-field [label]="'Data'">
    <app-input-edit
      [itemForm]="control.controls.input"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [placeholder]="'Value'"
      [fill]="true"
    ></app-input-edit>

    <ng-container description>
      Example: elements.table.selected_item
    </ng-container>
  </app-sidebar-field>

  <div
    *ngIf="
      (control.controls.input | appControlValue | async | appFieldInput)?.isSet() &&
      (autoDetectedColumns$ | async) as columns
    "
    class="sidebar__element"
    style="padding: 0;"
  >
    <ng-container *ngIf="columns.length">
      <ng-container
        *ngIf="control.isColumnsSame$(control.controls.columns | appControlValue | async, columns); else changed"
      >
        <app-alert [secondary]="true" [compact]="true" [center]="true">
          Data has <strong>{{ columns.length }}</strong>
          {{ columns.length | i18nPlural: { '=1': 'field', other: 'fields' } }}
        </app-alert>
      </ng-container>

      <ng-template #changed>
        <app-alert [warning]="true" [compact]="true" [center]="true">
          New data has <strong>{{ columns.length }}</strong>
          {{ columns.length | i18nPlural: { '=1': 'field', other: 'fields' } }} (<a
            href="javascript:void(0)"
            (click)="resetColumns()"
            >Reload</a
          >)
        </app-alert>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!columns.length">
      <app-alert [warning]="true" [compact]="true" [center]="true">
        Data has no fields
      </app-alert>
    </ng-container>
  </div>
</div>

<div *ngIf="(control.controls.type | appControlValue | async) == dataSourceTypes.Workflow" class="sidebar__element">
  <app-workflow-button [workflow]="workflow" (editClick)="editWorkflow()"></app-workflow-button>
</div>

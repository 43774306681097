import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class GuideUtilsService {
  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  // TODO: get first top
  getAnyModelDescription(): Observable<ModelDescription> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => {
        if (!modelDescriptions || !modelDescriptions.length) {
          return;
        }

        return modelDescriptions[0];
      })
    );
  }

  getAnyModel(): Observable<Model> {
    return this.getAnyModelDescription().pipe(
      switchMap(modelDescription => {
        return this.modelService.get(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          modelDescription.model
        );
      }),
      map(result => {
        return result.results[0];
      })
    );
  }
}

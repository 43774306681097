import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { elementResize$, ElementResizeEvent } from '../../utils/document-utils/document-utils';

@Directive({
  selector: '[appElementResize]'
})
export class ElementResizeDirective implements OnInit, OnDestroy {
  @Output() elementResize = new EventEmitter<ElementResizeEvent>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    elementResize$(this.el.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(e => this.elementResize.emit(e));
  }

  ngOnDestroy(): void {}
}

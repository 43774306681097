var _a;
export var ExportFormatType;
(function (ExportFormatType) {
    ExportFormatType["JSON"] = "json";
    ExportFormatType["CSV"] = "csv";
    ExportFormatType["XLSX"] = "xlsx";
    ExportFormatType["XLS"] = "xls";
    ExportFormatType["HTML"] = "html";
    ExportFormatType["TXT"] = "txt";
})(ExportFormatType || (ExportFormatType = {}));
export var exportFormatBookTypes = (_a = {},
    _a[ExportFormatType.CSV] = 'csv',
    _a[ExportFormatType.XLSX] = 'xlsx',
    _a[ExportFormatType.XLS] = 'xls',
    _a[ExportFormatType.HTML] = 'html',
    _a[ExportFormatType.TXT] = 'txt',
    _a);
export var exportFormats = [
    {
        type: ExportFormatType.CSV,
        extension: 'csv',
        label: 'CSV'
    },
    {
        type: ExportFormatType.XLSX,
        extension: 'xlsx',
        label: 'Excel (XLSX)'
    },
    {
        type: ExportFormatType.XLS,
        extension: 'xls',
        label: 'Excel (XLS)'
    },
    {
        type: ExportFormatType.JSON,
        extension: 'json',
        label: 'JSON'
    },
    {
        type: ExportFormatType.HTML,
        extension: 'html',
        label: 'HTML'
    },
    {
        type: ExportFormatType.TXT,
        extension: 'txt',
        label: 'Notepad (TXT)'
    }
];

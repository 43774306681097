import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { AnalyticsEvent } from '@modules/analytics';
import { TaskQueue, TaskQueueStore } from '@modules/collaboration';
import { TaskQueueEditController } from '@modules/collaboration-components';
import { MenuService } from '@modules/menu';
import { CurrentProjectStore, Project } from '@modules/projects';
import { RoutingService } from '@modules/routing';

@Component({
  selector: 'app-customize-menu-queues',
  templateUrl: './customize-menu-queues.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuQueuesComponent implements OnInit, OnDestroy {
  @Output() closeMenu = new EventEmitter<void>();

  currentProject: Project;
  queues$: Observable<TaskQueue[]>;
  analyticsEvents = AnalyticsEvent;

  constructor(
    private routingService: RoutingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskQueueEditController: TaskQueueEditController,
    private currentProjectStore: CurrentProjectStore,
    private menuService: MenuService,
    private taskQueueStore: TaskQueueStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.queues$ = this.taskQueueStore.get();
    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(currentProject => {
      this.currentProject = currentProject;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  createQueue() {
    this.taskQueueEditController
      .createTaskQueue({ analyticsSource: 'customize_menu_collaboration' })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.created) {
          this.routingService.navigateApp(this.currentProject.settingsCollaborationTasksLink, {
            queryParams: {
              queue: result.queue.uid
            }
          });
        }
      });
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SessionStorage } from '@core';
import { ActionDescriptionParamsExecuteSessionKey } from '@modules/action-queries';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  providers: [ModelListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  params: Object;
  ids: number[];
  inverseIds = false;
  modelDescription: ModelDescription;
  getItemList = false;
  items: Model[];
  count: number;
  deleteStarted = false;
  deleteProcessed = 0;
  deleteDeleted = 0;

  constructor(
    private injector: Injector,
    private modelService: ModelService,
    private modelListStore: ModelListStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private userActivityService: UserActivityService,
    private activatedRoute: ActivatedRoute,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private sessionStorage: SessionStorage,
    private metaService: MetaService,
    private cd: ChangeDetectorRef
  ) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
      .pipe(
        switchMap<[Object, Object], [ActivatedRouteSnapshot, ModelDescription]>(() => {
          const snapshot = this.activatedRoute.snapshot;

          try {
            this.params = JSON.parse(this.sessionStorage.get(ActionDescriptionParamsExecuteSessionKey));
          } catch (e) {
            this.params = {};
          }

          this.loading = true;
          this.ids = this.params['ids'] || [];
          this.inverseIds = this.params['inverseIds'];
          this.modelDescription = undefined;
          this.items = undefined;
          this.count = undefined;
          this.deleteStarted = false;
          this.deleteProcessed = 0;
          this.deleteDeleted = 0;

          this.cd.markForCheck();

          return this.modelDescriptionStore.getDetailFirst(snapshot.params['model']).pipe(
            map<ModelDescription, [ActivatedRouteSnapshot, ModelDescription]>(item => [snapshot, item])
          );
        }),
        switchMap(([snapshot, modelDescription]) => {
          const resource = this.currentEnvironmentStore.resources.find(
            item => item.uniqueName == modelDescription.resource
          );

          this.modelDescription = modelDescription;
          this.getItemList = resource && resource.type == ResourceType.JetBridge;

          if (!this.getItemList) {
            this.count = this.ids.length;
            this.cd.markForCheck();
            return of(undefined);
          }

          const params = {};

          if (this.inverseIds) {
            params[`exclude__${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
          } else {
            params[`${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
          }

          this.modelListStore.dataSource = new ListModelDescriptionDataSource({
            type: DataSourceType.Query,
            queryResource: this.modelDescription.resource,
            query: this.modelDescription.getQuery
          });
          this.modelListStore.params = { ...this.params, ...params };

          if (this.modelListStore.params[PAGE_PARAM] != undefined) {
            delete this.modelListStore.params[PAGE_PARAM];
          }

          if (this.modelListStore.params['ids'] != undefined) {
            delete this.modelListStore.params['ids'];
          }

          if (this.modelListStore.params['inverseIds'] != undefined) {
            delete this.modelListStore.params['inverseIds'];
          }

          const applyResult = applySegments(this.modelListStore.params, {}, this.modelDescription);

          applyResult.params = applyParamsComputedLookups(applyResult.params);

          this.cd.markForCheck();

          return this.modelService.get(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            snapshot.params['model'],
            applyResult.params,
            keys(applyResult.body).length ? applyResult.body : undefined
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.loading = false;

          if (result) {
            this.items = result.results;
            this.count = result.count;
          }

          this.metaService.set({
            title: [this.count ? `Delete ${this.count}` : 'Delete', this.modelDescription.verboseNamePlural]
          });

          this.cd.markForCheck();
        },
        e => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  processPage(): Observable<any> {
    this.modelListStore.reset();

    if (!this.getItemList) {
      const result = new ReplaySubject<boolean>();
      const model = this.createModel();

      model.setUp(this.modelDescription);
      model.setPrimaryKey(this.ids[this.deleteProcessed]);

      this.modelService
        .delete(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          this.modelDescription.modelId,
          model
        )
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.deleteProcessed += 1;
            this.deleteDeleted += 1;
            this.cd.markForCheck();
            result.next(true);
          },
          () => {
            this.deleteProcessed += 1;
            this.cd.markForCheck();
            result.next(false);
          }
        );

      return result.pipe(
        filter(() => this.deleteProcessed < this.count),
        switchMap(() => this.processPage())
      );
    } else {
      return this.modelListStore.getNext().pipe(
        switchMap(results => {
          const obs = results
            .map(item => item.initialPrimaryKey)
            .map(pk =>
              switchMap(() => {
                const req = new ReplaySubject<boolean>();
                const model = this.createModel().deserialize(this.modelDescription.model, {});

                model.setUp(this.modelDescription);
                model.setPrimaryKey(pk);

                this.modelService
                  .delete(
                    this.currentProjectStore.instance,
                    this.currentEnvironmentStore.instance,
                    this.modelDescription.modelId,
                    model
                  )
                  .pipe(untilDestroyed(this))
                  .subscribe(
                    () => {
                      this.deleteProcessed += 1;
                      this.deleteDeleted += 1;
                      this.cd.markForCheck();
                      req.next(true);
                    },
                    () => {
                      this.deleteProcessed += 1;
                      this.cd.markForCheck();
                      req.next(false);
                    }
                  );

                return req;
              })
            );

          let result = of({});

          obs.forEach(item => (result = result.pipe(item)));

          return result;
        }),
        filter(() => this.modelListStore.hasMore),
        switchMap(() => this.processPage())
      );
    }
  }

  confirm() {
    this.deleteStarted = true;
    this.cd.markForCheck();

    this.processPage().pipe(untilDestroyed(this)).subscribe();
  }

  get listParams() {
    const params = { ...this.params };

    if (params[PAGE_PARAM] != undefined) {
      delete params[PAGE_PARAM];
    }

    if (params['ids'] != undefined) {
      delete params['ids'];
    }

    if (params['inverseIds'] != undefined) {
      delete params['inverseIds'];
    }

    return params;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';
import clone from 'lodash/clone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { colors, parseColor } from '@modules/colors';
import { FieldType, getFieldDescriptionByType, registerFieldComponent } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { forceObservable, isColorHex, MapDarkStyles, MapStyles, numberToHex } from '@shared';
import { FieldComponent } from '../field/field.component';
var GeographyFieldComponent = /** @class */ (function (_super) {
    __extends(GeographyFieldComponent, _super);
    function GeographyFieldComponent(themeService, appConfigService, cd) {
        var _this = _super.call(this) || this;
        _this.themeService = themeService;
        _this.appConfigService = appConfigService;
        _this.cd = cd;
        _this.viewportZoom = 14;
        _this.currentCoordinates = { lat: undefined, lng: undefined };
        return _this;
    }
    GeographyFieldComponent.prototype.ngOnInit = function () {
        if (this.form) {
            this.initForm();
        }
        else {
            this.setValue();
        }
    };
    GeographyFieldComponent.prototype.ngOnDestroy = function () { };
    GeographyFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['form']) {
            this.initForm();
        }
        if (changes['value']) {
            this.setValue();
        }
    };
    GeographyFieldComponent.prototype.initForm = function () {
        var _this = this;
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        if (this.form && this.field && this.form.controls[this.field.name]) {
            this.formSubscription = this.form.controls[this.field.name].valueChanges
                .pipe(delay(0), untilDestroyed(this))
                .subscribe(function () {
                _this.setValue();
            });
        }
        this.setValue();
    };
    GeographyFieldComponent.prototype.setValue = function () {
        var _this = this;
        var value = this.currentValue;
        var defaultCoords = { lat: 52.5139252, lng: 13.3895913 };
        if (value) {
            this.markerCoordinates = clone(value);
            this.viewportCoordinates = clone(this.markerCoordinates);
            this.currentCoordinates = clone(this.markerCoordinates);
        }
        else {
            this.markerCoordinates = defaultCoords;
            if (this.currentCoordinates.lat && this.currentCoordinates.lng) {
                this.currentCoordinates = { lat: undefined, lng: undefined };
            }
            this.viewportCoordinates = clone(this.markerCoordinates);
            Observable.create(function (observer) {
                navigator.geolocation.getCurrentPosition(function (pos) {
                    observer.next(pos);
                    observer.complete();
                });
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (pos) {
                _this.markerCoordinates = { lat: pos.coords.latitude, lng: pos.coords.longitude };
                _this.viewportCoordinates = clone(_this.markerCoordinates);
                _this.cd.markForCheck();
            });
        }
        this.cd.markForCheck();
    };
    GeographyFieldComponent.prototype.updateValue = function () {
        var value;
        if (this.currentCoordinates && this.currentCoordinates.lat && this.currentCoordinates.lng) {
            value = clone(this.currentCoordinates);
        }
        if (this.form && this.field) {
            var formValue = {};
            formValue[this.field.name] = value;
            this.form.patchValue(formValue);
        }
    };
    Object.defineProperty(GeographyFieldComponent.prototype, "mapStyles$", {
        get: function () {
            return this.themeService.theme$.pipe(map(function (theme) {
                return theme == 'dark' ? MapDarkStyles : MapStyles;
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeographyFieldComponent.prototype, "backgroundColor$", {
        get: function () {
            return this.themeService.theme$.pipe(map(function (theme) {
                return theme == 'dark' ? '#343C43' : '#fff';
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeographyFieldComponent.prototype, "markerIcon$", {
        get: function () {
            var _this = this;
            return this.themeService.theme$.pipe(map(function (theme) {
                var markerColor = _this.accentColor;
                var colorHex = isColorHex(markerColor)
                    ? markerColor.substring(1)
                    : colors.filter(function (item) { return item.name == markerColor; }).map(function (item) { return numberToHex(item.hex); })[0];
                var clr = colorHex ? parseColor('#' + colorHex) : undefined;
                var isDark = !clr || clr.contrast(Color('white')) >= 2;
                var contrastColor = !clr || isDark ? 'FFF' : clr.darken(0.8).hex().toUpperCase().substring(1);
                var size = 26;
                var url = new URL(_this.appConfigService.serverBaseUrl + "/assets/marker.svg");
                if (colorHex) {
                    url.searchParams.append('color', colorHex);
                }
                if (contrastColor) {
                    url.searchParams.append('contrast_color', contrastColor);
                }
                if (theme == 'dark') {
                    url.searchParams.append('dark', '1');
                }
                if (size) {
                    url.searchParams.append('size', size.toString());
                }
                return {
                    url: url.toString(),
                    anchor: {
                        x: size * 0.5,
                        y: size * 0.5
                    }
                };
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeographyFieldComponent.prototype, "valueToStr", {
        get: function () {
            var _this = this;
            // TODO: Fix as operator
            return forceObservable(getFieldDescriptionByType(this.field.field).valueToStr(this.currentValue, {
                field: this.field,
                context: this.context
            })).pipe(map(function (value) {
                if (!value) {
                    return _this.formatEmpty(value);
                }
                return value;
            }));
        },
        enumerable: true,
        configurable: true
    });
    GeographyFieldComponent.prototype.onDrag = function (e) {
        this.currentCoordinates = e.coords;
        this.cd.markForCheck();
    };
    GeographyFieldComponent.prototype.onDragEnd = function (e) {
        this.currentCoordinates = e.coords;
        this.updateValue();
    };
    return GeographyFieldComponent;
}(FieldComponent));
export { GeographyFieldComponent };
registerFieldComponent(FieldType.Location, GeographyFieldComponent);

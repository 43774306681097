var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { environment } from '@env/environment';
import { SSOSettings } from '@modules/sso';
import { initials } from '@shared';
export function getActualDomain(domain, custom) {
    if (!custom) {
        return domain + "." + environment.subdomainsBaseDomain;
    }
    return domain;
}
var EmailConfig = /** @class */ (function () {
    function EmailConfig() {
    }
    EmailConfig.prototype.deserialize = function (data) {
        if (data['host'] !== undefined) {
            this.host = data['host'];
        }
        if (data['port'] !== undefined) {
            this.port = data['port'];
        }
        if (data['username'] !== undefined) {
            this.username = data['username'];
        }
        if (data['password'] !== undefined) {
            this.password = data['password'];
        }
        if (data['from'] !== undefined) {
            this.from = data['from'];
        }
        return this;
    };
    EmailConfig.prototype.serialize = function (fields) {
        var data = {
            host: this.host,
            port: this.port,
            username: this.username,
            password: this.password,
            from: this.from
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    return EmailConfig;
}());
export { EmailConfig };
export var SignUpLayout;
(function (SignUpLayout) {
    SignUpLayout["PosterRight"] = "poster_right";
    SignUpLayout["PosterLeft"] = "poster_left";
    SignUpLayout["Form"] = "form";
    SignUpLayout["Background"] = "background";
})(SignUpLayout || (SignUpLayout = {}));
export var defaultSignUpLayout = SignUpLayout.Form;
var Domain = /** @class */ (function () {
    function Domain() {
        this.custom = true;
        this.whiteLabel = false;
        this.ssoSettings = [];
        this.params = {};
        this.privateParams = {};
        this.disableEmailSignIn = false;
        this.disableEmailSignUp = false;
        this.disableGoogleSignIn = false;
        this.disableFacebookSignIn = false;
        this.makeDefaultProject = false;
        this.signUpLayout = SignUpLayout.Form;
    }
    Domain.prototype.deserialize = function (data) {
        this.domain = data['domain'];
        this.whiteLabel = data['white_label'];
        this.title = data['title'];
        this.description = data['description'];
        this.logoColor = data['color'];
        this.logo = data['logo'];
        this.name = data['name'];
        this.initials = initials(this.name);
        this.defaultProject = data['default_project'];
        this.titleShort = data['title_short'];
        this.title = data['title'];
        this.description = data['description'];
        this.image = data['image'];
        this.icon = data['icon'];
        this.privacyPolicy = data['privacy_policy'];
        this.termsOfService = data['terms_of_service'];
        this.language = data['language'];
        this.timezone = data['timezone'];
        this.emailConfigFrom = data['email_config_from'];
        if (data['custom'] !== undefined) {
            this.custom = data['custom'];
        }
        if (data['sso_settings']) {
            this.ssoSettings = data['sso_settings'].map(function (item) { return new SSOSettings().deserialize(item); });
        }
        if (data['params']) {
            this.params = data['params'];
            if (this.params['disable_email_sign_in'] !== undefined) {
                this.disableEmailSignIn = this.params['disable_email_sign_in'];
            }
            if (this.params['disable_email_sign_up'] !== undefined) {
                this.disableEmailSignUp = this.params['disable_email_sign_up'];
            }
            if (this.params['disable_google_sign_in'] !== undefined) {
                this.disableGoogleSignIn = this.params['disable_google_sign_in'];
            }
            if (this.params['disable_facebook_sign_in'] !== undefined) {
                this.disableFacebookSignIn = this.params['disable_facebook_sign_in'];
            }
            if (this.params['public_app_image_top'] !== undefined) {
                this.publicAppImageTop = this.params['public_app_image_top'];
            }
            if (this.params['public_app_image_middle'] !== undefined) {
                this.publicAppImageMiddle = this.params['public_app_image_middle'];
            }
            if (this.params['public_app_image_bottom'] !== undefined) {
                this.publicAppImageBottom = this.params['public_app_image_bottom'];
            }
            if (this.params['public_app_image_bottom_overlay'] !== undefined) {
                this.publicAppImageBottomOverlay = this.params['public_app_image_bottom_overlay'];
            }
            if (this.params['sign_up_layout'] !== undefined) {
                this.signUpLayout = this.params['sign_up_layout'];
            }
            if (this.params['sign_up_background_color'] !== undefined) {
                this.signUpBackgroundColor = this.params['sign_up_background_color'];
            }
            if (this.params['sign_up_accent_color'] !== undefined) {
                this.signUpAccentColor = this.params['sign_up_accent_color'];
            }
            if (this.params['sign_up_font_regular'] !== undefined) {
                this.signUpFontRegular = this.params['sign_up_font_regular'];
            }
            if (this.params['sign_up_font_heading'] !== undefined) {
                this.signUpFontHeading = this.params['sign_up_font_heading'];
            }
            if (this.params['sign_up_background_image'] !== undefined) {
                this.signUpBackgroundImage = this.params['sign_up_background_image'];
            }
            if (this.params['sign_up_title'] !== undefined) {
                this.signUpTitle = this.params['sign_up_title'];
            }
            if (this.params['sign_up_description'] !== undefined) {
                this.signUpDescription = this.params['sign_up_description'];
            }
            if (this.params['sign_in_title'] !== undefined) {
                this.signInTitle = this.params['sign_in_title'];
            }
            if (this.params['sign_in_description'] !== undefined) {
                this.signInDescription = this.params['sign_in_description'];
            }
            this.numberFractionSeparator = this.params['number_fraction_separator'];
            this.numberThousandsSeparator = this.params['number_thousands_separator'];
            this.appendScripts = this.params['append_scripts'];
            this.appendStyles = this.params['append_styles'];
        }
        if (data['private_params']) {
            this.privateParams = data['private_params'];
            if (this.privateParams['email_config']) {
                this.emailConfig = new EmailConfig().deserialize(this.privateParams['email_config']);
            }
        }
        return this;
    };
    Domain.prototype.serialize = function (fields) {
        var params = __assign({}, this.params);
        params['disable_email_sign_in'] = this.disableEmailSignIn;
        params['disable_email_sign_up'] = this.disableEmailSignUp;
        params['disable_google_sign_in'] = this.disableGoogleSignIn;
        params['disable_facebook_sign_in'] = this.disableFacebookSignIn;
        params['sign_up_layout'] = this.signUpLayout;
        params['sign_up_background_color'] = this.signUpBackgroundColor;
        params['sign_up_accent_color'] = this.signUpAccentColor;
        params['sign_up_font_regular'] = this.signUpFontRegular;
        params['sign_up_font_heading'] = this.signUpFontHeading;
        params['sign_up_background_image'] = this.signUpBackgroundImage;
        params['sign_up_title'] = this.signUpTitle;
        params['sign_up_description'] = this.signUpDescription;
        params['sign_in_title'] = this.signInTitle;
        params['sign_in_description'] = this.signInDescription;
        params['number_fraction_separator'] = this.numberFractionSeparator;
        params['number_thousands_separator'] = this.numberThousandsSeparator;
        params['append_scripts'] = this.appendScripts;
        params['append_styles'] = this.appendStyles;
        var privateParams = __assign({}, this.privateParams);
        privateParams['email_config'] = this.emailConfig ? this.emailConfig.serialize() : undefined;
        var data = {
            domain: this.domain,
            make_default_project: this.makeDefaultProject,
            white_label: this.whiteLabel,
            name: this.name,
            title: this.title,
            description: this.description,
            logo: this.logo,
            color: this.logoColor,
            icon: this.icon,
            image: this.image,
            privacy_policy: this.privacyPolicy,
            terms_of_service: this.termsOfService,
            language: this.language,
            timezone: this.timezone,
            params: params,
            private_params: privateParams
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Domain.prototype, "actualDomain", {
        get: function () {
            return getActualDomain(this.domain, this.custom);
        },
        enumerable: true,
        configurable: true
    });
    return Domain;
}());
export { Domain };

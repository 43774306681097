import { AppError } from '../errors/errors';

export class JsFunctionError extends AppError {
  public error: string;
  public line: number;
  public column: number;

  constructor(public e: any) {
    super(e);

    const excMatch = e.stack.match(/^([^\n]+)\n/);
    const paramsMatch = e.stack.match(/at\s.+\s\(.+,\s<anonymous>:(\d+):(\d+)\)/);

    this.error = excMatch ? excMatch[1] : undefined;
    this.line = paramsMatch ? paramsMatch[1] : undefined;
    this.column = paramsMatch ? paramsMatch[2] : undefined;

    Object.setPrototypeOf(this, JsFunctionError.prototype);
  }
}

export function getFunctionBody(func) {
  if (!func) {
    return func;
  }

  return func.toString().match(/function[^{]+\{([\s\S]*)\}$/)[1];
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { AppDrag, getElementViewport } from '@common/drag-drop2';
import { CustomizeBarItem } from '@modules/change-components';
import {
  elementItemImages,
  ElementType,
  ListElementItem,
  ModelElementItem,
  modelFieldToDisplayField,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource, ModelDescriptionDataSource } from '@modules/data-sources';
import { FieldType, Input as FieldInput, InputValueType } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { SidebarCollapseContext } from '@modules/sidebar';

// TODO: Refactor import
import { isElementTypeGroupable } from '../../../customize/utils/elements';

@Component({
  selector: 'app-related-model-components',
  templateUrl: './related-model-components.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedModelComponentsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() modelDescription: ModelDescription;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementModelOutput: string[];
  @Input() collapseContext: SidebarCollapseContext;

  viewport: HTMLElement;
  items: CustomizeBarItem[] = [];
  displayItems: CustomizeBarItem[] = [];
  itemsSubscription: Subscription;
  collapsed = true;
  showCollapsedMax = 3;

  constructor(
    private el: ElementRef,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.viewport = getElementViewport(this.el.nativeElement);
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modelDescription'] || changes['context'] || changes['contextElement']) {
      this.updateItems();
    }
  }

  updateItems() {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }

    if (!this.context || !this.contextElement) {
      this.items = [];
      this.cd.markForCheck();
      return;
    }

    const contextElementPath = this.context.getElementPath(this.contextElement);

    this.itemsSubscription = this.modelDescriptionStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        const currentResource = this.currentEnvironmentStore.resources.find(
          i => i.uniqueName == this.modelDescription.resource
        );

        this.items = items
          .filter(modelDescription => {
            const resource = this.currentEnvironmentStore.resources.find(
              item => item.uniqueName == modelDescription.resource
            );

            if (!resource) {
              return false;
            }

            return !resource.demo || (resource.demo && currentResource && currentResource.demo);
          })
          .filter(modelDescription => modelDescription.modelId != this.modelDescription.modelId)
          .reduce((acc, modelDescription) => {
            const oneToOneField = this.modelDescription.dbFields.find(
              field =>
                field.field == FieldType.RelatedModel &&
                field.params['related_model'] &&
                modelDescription.isSame(field.params['related_model']['model'])
            );
            const isOneToManyField = modelDescription.dbFields.find(
              field =>
                field.field == FieldType.RelatedModel &&
                field.params['related_model'] &&
                this.modelDescription.isSame(field.params['related_model']['model'])
            );
            const resource = this.currentEnvironmentStore.resources.find(
              item => item.uniqueName == modelDescription.resource
            );

            if (oneToOneField) {
              const element = new ModelElementItem();

              element.type = ElementType.Model;

              element.dataSource = new ModelDescriptionDataSource();

              element.dataSource.type = DataSourceType.Query;
              element.dataSource.queryResource = resource.uniqueName;
              element.dataSource.query = new ListModelDescriptionQuery();
              element.dataSource.query.queryType = QueryType.Simple;
              element.dataSource.query.simpleQuery = new element.dataSource.query.simpleQueryClass();
              element.dataSource.query.simpleQuery.model = modelDescription.model;
              element.dataSource.columns = modelDescription.fields
                .filter(item => item.type == ModelFieldType.Db)
                .map(item => modelFieldToDisplayField(item));

              if (this.contextElementModelOutput && contextElementPath) {
                const input = new FieldInput();
                const relatedKey = oneToOneField.params['custom_primary_key'] || modelDescription.primaryKeyField;

                input.name = relatedKey;
                input.valueType = InputValueType.Context;
                input.contextValue = [...contextElementPath, ...this.contextElementModelOutput, oneToOneField.name];

                element.dataSource.queryInputs = [input];
              }

              element.params = element.serialize(['params'])['params'];

              acc.push({
                title: modelDescription.verboseName || modelDescription.verboseNamePlural || modelDescription.model,
                subtitle: modelDescription.description || resource.name,
                image: elementItemImages[element.type],
                type: ElementType.Model,
                defaultParams: element.params,
                resource: resource
              });
            }

            if (isOneToManyField) {
              const element = new ListElementItem();
              const layoutType = ListLayoutType.Table;
              const layoutSettings = element.getOrCreateLayout(layoutType);

              element.type = ElementType.List;

              layoutSettings.titleInput = new FieldInput().deserializeFromStatic(
                'value',
                modelDescription.verboseNamePlural
              );
              layoutSettings.dataSource = new ListModelDescriptionDataSource();
              layoutSettings.dataSource.type = DataSourceType.Query;
              layoutSettings.dataSource.queryResource = resource.uniqueName;
              layoutSettings.dataSource.query = new ListModelDescriptionQuery();
              layoutSettings.dataSource.query.frontendFiltering = true;
              layoutSettings.dataSource.query.queryType = QueryType.Simple;
              layoutSettings.dataSource.query.simpleQuery = new layoutSettings.dataSource.query.simpleQueryClass();
              layoutSettings.dataSource.query.simpleQuery.model = modelDescription.model;
              layoutSettings.dataSource.columns = modelDescription.fields
                .filter(item => item.type == ModelFieldType.Db)
                .map(item => modelFieldToDisplayField(item));

              if (this.contextElementModelOutput && contextElementPath) {
                const input = new FieldInput();
                const relatedKey =
                  isOneToManyField.params['custom_primary_key'] || this.modelDescription.primaryKeyField;

                input.name = isOneToManyField.name;
                input.valueType = InputValueType.Context;
                input.contextValue = [...contextElementPath, ...this.contextElementModelOutput, relatedKey];

                layoutSettings.dataSource.queryInputs = [input];
              }

              element.params = element.serialize(['params'])['params'];

              acc.push({
                title: modelDescription.verboseNamePlural || modelDescription.verboseName || modelDescription.model,
                subtitle: modelDescription.description || resource.name,
                image: elementItemImages[element.type],
                type: ElementType.List,
                defaultParams: element.params,
                resource: resource
              });
            }

            return acc;
          }, []);
        this.cd.markForCheck();
        this.updateDisplayItems();
      });
  }

  updateDisplayItems() {
    if (this.collapsed) {
      this.displayItems = this.items.slice(0, this.showCollapsedMax);
    } else {
      this.displayItems = this.items;
    }
    this.cd.markForCheck();
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }

  isDroppable(item: AppDrag<CustomizeBarItem>): boolean {
    return false;
  }

  isGroupable(type: ElementType) {
    return isElementTypeGroupable(type);
  }
}

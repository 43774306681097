import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import last from 'lodash/last';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, pairwise } from 'rxjs/operators';

import { AppDragDrop } from '@common/drag-drop2';
import { Layer, Translate } from '@modules/views';
import { arrayReverse, arraySet, ascComparator, isSet, TypedChanges } from '@shared';

import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
import {
  IViewEditorNavigatorItemsComponent,
  ViewEditorNavigatorItemsState,
  ViewEditorNavigatorService
} from '../../services/view-editor-navigator/view-editor-navigator.service';

export function transferArrayItems<T = any>(
  transferItems: { item: T; array: T[] }[],
  transferAnchor: T,
  targetArray: T[],
  targetIndex: number
) {
  let insertIndex = targetIndex;
  const popItems = transferItems
    .sort((lhs, rhs) => {
      if (lhs.array === rhs.array) {
        const lhsIndex = lhs.array.findIndex(item => item === lhs.item);
        const rhsIndex = rhs.array.findIndex(item => item === rhs.item);
        return ascComparator(lhsIndex, rhsIndex);
      } else {
        const lhsIndex = transferItems.findIndex(item => item === lhs);
        const rhsIndex = transferItems.findIndex(item => item === rhs);
        return ascComparator(lhsIndex, rhsIndex);
      }
    })
    .reverse()
    .reduce((acc, transferItem) => {
      const index = transferItem.array.findIndex(item => item === transferItem.item);
      const [popItem] = transferItem.array.splice(index, 1);

      acc.push(popItem);

      if (transferItem.array === targetArray && transferItem.item !== transferAnchor && index < targetIndex) {
        --insertIndex;
      }

      return acc;
    }, []);

  targetArray.splice(insertIndex, 0, ...arrayReverse(popItems));
}

@Component({
  selector: 'app-view-editor-navigator-items',
  templateUrl: './view-editor-navigator-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorNavigatorItemsComponent
  implements OnInit, OnDestroy, OnChanges, IViewEditorNavigatorItemsComponent {
  @Input() items: Layer[] = [];
  @Input() flexLayout = false;
  @Input() indent = 0;
  @Input() search = '';

  indentRange: number[] = [];
  style: SafeStyle;
  state: ViewEditorNavigatorItemsState = { container: [], displayItems: [], displayReverse: true };
  customizingLayersLastAdded: Layer;

  trackLayerFn(i, item: Layer) {
    return item.id;
  }

  constructor(
    private editorContext: ViewEditorContext,
    private viewEditorNavigatorService: ViewEditorNavigatorService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.viewEditorNavigatorService.registerComponent(this);

    this.updateIndent();

    this.editorContext.customizingLayers$
      .pipe(distinctUntilChanged(), pairwise(), untilDestroyed(this))
      .subscribe(([prev, current]) => {
        const newCurrent = current.filter(currentItem => !prev.find(prevItem => prevItem.isSame(currentItem)));
        this.customizingLayersLastAdded = last(newCurrent);
      });

    this.editorContext
      .layerContainerChanged$()
      .pipe(
        filter(event => this.items === event.container),
        untilDestroyed(this)
      )
      .subscribe(() => this.updateState());
  }

  ngOnDestroy(): void {
    this.viewEditorNavigatorService.unregisterComponent(this);
  }

  ngOnChanges(changes: TypedChanges<ViewEditorNavigatorItemsComponent>): void {
    if (changes.items || changes.search || changes.flexLayout) {
      this.updateState();
    }

    if (changes.indent && !changes.indent.firstChange) {
      this.updateIndent();
    }
  }

  updateState() {
    const displayReverse = !this.flexLayout;

    this.state = {
      container: this.items,
      displayItems: this.getDisplayItems(displayReverse),
      displayReverse: displayReverse
    };
    this.cd.markForCheck();
  }

  updateIndent() {
    const vars = [`--navigator-items-index: ${this.indent}`, `--navigator-items-nested: ${this.indent > 0 ? 1 : 0}`];

    this.indentRange = range(this.indent);
    this.style = this.sanitizer.bypassSecurityTrustStyle(vars.join(';'));
  }

  getDisplayItems(displayReverse: boolean): Layer[] {
    const search = isSet(this.search) ? this.search.trim().toLowerCase() : '';
    const items = this.items.filter(item => {
      if (!isSet(this.search)) {
        return true;
      }

      return item.name.toLowerCase().indexOf(search) !== -1;
    });

    return arrayReverse(items, displayReverse);
  }

  customizeItem(layer: Layer) {
    this.editorContext.setCustomizingLayer(layer);
  }

  addCustomizingItem(layer: Layer, shift?: boolean) {
    if (shift && this.customizingLayersLastAdded) {
      const lastIndex = this.items.findIndex(item => item.isSame(this.customizingLayersLastAdded));
      const newIndex = this.items.findIndex(item => item.isSame(layer));
      const addItems =
        newIndex >= lastIndex
          ? this.items.slice(lastIndex + 1, newIndex + 1)
          : this.items.slice(newIndex, lastIndex).reverse();

      this.editorContext.addCustomizingLayer(...addItems);
    } else {
      this.editorContext.addCustomizingLayer(layer);
    }
  }

  removeCustomizingItem(layer: Layer) {
    this.editorContext.removeCustomizingLayer(layer);
  }

  dragDrop(event: AppDragDrop<ViewEditorNavigatorItemsState>) {
    const previousState = event.previousContainer.data;
    const state = event.container.data;
    const dragLayer = previousState.displayItems[event.previousIndex];
    const customizingLayers = this.editorContext.customizingLayers$.value;
    const transferLayers =
      customizingLayers.length > 1 && customizingLayers.find(item => item.isSame(dragLayer))
        ? customizingLayers
        : [dragLayer];

    if (event.previousContainer === event.container) {
      transferArrayItems(
        transferLayers.map(item => ({ item: item, array: state.displayItems })),
        dragLayer,
        state.displayItems,
        event.currentIndex
      );
      arraySet(state.container, arrayReverse(state.displayItems, state.displayReverse));

      this.editorContext.markLayerContainerChanged(state.container, ViewEditorCustomizeSource.Navigator);
    } else {
      const previousContainer = this.editorContext.getContainer(previousState.container);
      const previousTranslate: Translate = previousContainer ? previousContainer.options.translate : { x: 0, y: 0 };
      const container = this.editorContext.getContainer(state.container);
      const translate: Translate = container ? container.options.translate : { x: 0, y: 0 };

      const deltaPositionX = previousTranslate.x - translate.x;
      const deltaPositionY = previousTranslate.y - translate.y;

      transferLayers.forEach(layer => {
        layer.frame.x += deltaPositionX;
        layer.frame.y += deltaPositionY;
      });

      transferArrayItems(
        transferLayers.map(layer => {
          const component = this.viewEditorNavigatorService.components.find(componentItem => {
            return componentItem.state.displayItems.some(item => item.isSame(layer));
          });
          return {
            item: layer,
            array: component.state.displayItems
          };
        }),
        dragLayer,
        state.displayItems,
        event.currentIndex
      );

      arraySet(state.container, arrayReverse(state.displayItems, state.displayReverse));
      arraySet(previousState.container, arrayReverse(previousState.displayItems, previousState.displayReverse));

      this.editorContext.markLayersChanged(transferLayers, ViewEditorCustomizeSource.Navigator);
      this.editorContext.markLayerContainerChanged(state.container, ViewEditorCustomizeSource.Navigator);
      this.editorContext.markLayerContainerChanged(previousState.container, ViewEditorCustomizeSource.Navigator);

      this.editorContext.setCustomizingLayer(...transferLayers);
    }
  }

  onItemVisibleUpdated(layer: Layer) {
    this.editorContext.markLayersChanged([layer], ViewEditorCustomizeSource.Navigator);
    this.editorContext.markLayerContainerChanged(this.state.container, ViewEditorCustomizeSource.Navigator);
  }
}

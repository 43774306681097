import { FormArray } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';

import { ChartWidgetEditFormDatasetControl } from './chart-widget-edit-dataset.control';

export class ChartWidgetEditFormDatasetArray extends FormArray {
  controls: ChartWidgetEditFormDatasetControl[];

  set(groups: ChartWidgetEditFormDatasetControl[]) {
    range(this.controls.length).forEach(() => this.removeAt(0));
    groups.forEach(item => this.push(item));
    this.updateValueAndValidity();
  }

  append(group: ChartWidgetEditFormDatasetControl) {
    this.push(group);
    this.updateValueAndValidity();
  }

  remove(group: ChartWidgetEditFormDatasetControl) {
    const index = this.controls.findIndex(item => item === group);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }

  copy(group: ChartWidgetEditFormDatasetControl) {
    this.push(cloneDeep(group));
    this.updateValueAndValidity();
  }
}

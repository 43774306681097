<div class="customize-toolbar theme_dark">
  <div *ngIf="project" class="customize-toolbar__logo">
    <a [appLink]="project.homeLink">
      <app-project-logo
        [color]="project.logoColor ? project.logoColor : ''"
        [logo]="project.logo ? project.logo : ''"
        [initials]="project.initials ? project.initials : ''"
        [size]="'xs'"
        [borderRadius]="'s'"
        [fill]="project.logoFill"
        [badgeColor]="environment?.color"
      ></app-project-logo>
    </a>
    <div class="customize-toolbar__logo-dropdown">
      <div class="customize-toolbar-dropdown">
        <div class="customize-toolbar-dropdown__button">
          <a href="javascript:void(0)" class="customize-toolbar-dropdown-button" (click)="openProjects()">
            <span class="icon-arrow_backward_2"></span> My Apps
          </a>
        </div>
        <div *ngIf="environments?.length" class="customize-toolbar-dropdown__list">
          <div class="customize-toolbar-dropdown-list">
            <div class="customize-toolbar-dropdown-list__title">Environments</div>
            <div
              *ngFor="let item of environments"
              class="customize-toolbar-dropdown-list__item"
              [class.customize-toolbar-dropdown-list__item_active]="item.uniqueName == environment?.uniqueName"
              (click)="setEnvironment(item)"
            >
              <div class="customize-toolbar-dropdown-list__item-left">
                <div
                  class="customize-toolbar-dropdown-list__item-circle"
                  [ngClass]="['background-color_' + item.color + '_1']"
                ></div>
              </div>
              <div class="customize-toolbar-dropdown-list__item-main">{{ item.name }}</div>
              <div
                class="customize-toolbar-dropdown-list__item-right"
                [class.customize-toolbar-dropdown-list__item-right_always]="item.uniqueName == environment?.uniqueName"
              >
                <a
                  href="javascript:void(0)"
                  class="customize-toolbar-dropdown-list__item-icon icon-gear"
                  (click)="$event.stopPropagation(); openEnvironmentSettings(item)"
                ></a>
              </div>
            </div>
            <div class="customize-toolbar-dropdown-list__actions">
              <a href="javascript:void(0)" class="customize-toolbar-dropdown-list__item" (click)="createEnvironment()">
                <div class="customize-toolbar-dropdown-list__item-left">
                  <div class="customize-toolbar-dropdown-list__item-icon icon-plus_circle"></div>
                </div>
                <div class="customize-toolbar-dropdown-list__item-main">Add new Environment</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="customize-toolbar__left">
    <ng-template *ngIf="handlerHeaderLeftPortal" [cdkPortalOutlet]="handlerHeaderLeftPortal"></ng-template>
  </div>

  <div class="customize-toolbar__main">
    <ng-template *ngIf="handlerHeaderCenterPortal" [cdkPortalOutlet]="handlerHeaderCenterPortal"></ng-template>

    <div
      *ngIf="info"
      class="customize-toolbar__breadcrumbs"
      [class.customize-toolbar__breadcrumbs_shift]="menuCustomizing"
    >
      <ng-container *ngIf="info">
        <ng-container *ngFor="let item of info.breadcrumbs; let last = last">
          <div class="customize-toolbar__breadcrumbs-item">
            {{ item | appCapitalize }}
          </div>
          <div *ngIf="!last" class="customize-toolbar__breadcrumbs-separator">/</div>
        </ng-container>
        <app-customize-title
          *ngIf="handlerRename"
          class="customize-toolbar__breadcrumbs-item"
          [title]="info.title"
          (updateTitle)="onUpdateTitle($event)"
        ></app-customize-title>
        <ng-container *ngIf="!handlerRename && info.title">
          <div class="customize-toolbar__breadcrumbs-separator">/</div>
          <div class="customize-toolbar__breadcrumbs-item">{{ info.title }}</div>
        </ng-container>
      </ng-container>
    </div>

    <!--    <div *ngIf="!info" class="customize-toolbar__breadcrumbs">-->
    <!--      <div class="customize-toolbar__breadcrumbs-item">Customizing</div>-->
    <!--    </div>-->

    <a
      *ngIf="handlerDuplicate"
      href="javascript:void(0)"
      class="button button_icon button_orange-transparent button_primary button_extra-small customize-toolbar__button"
      [class.button_disabled]="handlerDuplicateLoading"
      [appTip]="'Duplicate page'"
      [appTipOptions]="{ side: 'bottom' }"
      (click)="duplicate()"
    >
      <app-loader-small
        *ngIf="handlerDuplicateLoading"
        class="button__icon"
        style="display: inline-block; margin-top: 2px;"
      ></app-loader-small>
      <span *ngIf="!handlerDuplicateLoading" class="button__icon icon-duplicate_2"></span>
    </a>
    <a
      *ngIf="handlerSettings"
      href="javascript:void(0)"
      class="button button_icon button_orange-transparent button_primary button_extra-small customize-toolbar__button"
      [appTip]="'Page settings'"
      [appTipOptions]="{ side: 'bottom' }"
      (click)="openSettings()"
    >
      <span class="button__icon icon-gear"></span>
    </a>
  </div>

  <!-- <div class="customize-toolbar__devices devices">
    <div class="devices__item devices__item_desktop devices__item_active"></div>
    <div class="devices__item devices__item_mobile"></div>
  </div> -->

  <!-- <div class="customize-toolbar__workflow"></div> -->

  <div class="customize-toolbar__right">
    <div
      *ngIf="customizeService.handler && customizeService.handler.setChangesState"
      class="customize-toolbar__section"
    >
      <ng-template #undo_tip> Undo <app-tip-hotkey [metaKey]="true" [key]="'Z'"></app-tip-hotkey> </ng-template>

      <button
        class="button button_icon button_grey button_segment-left"
        [class.button_disabled]="!(customizeService.isUndoAvailable$ | async)"
        [appTip]="undo_tip"
        [appTipOptions]="{ side: 'bottom' }"
        (click)="customizeService.undoChangesHistory()"
      >
        <span class="button__icon icon-undo"></span>
      </button>

      <ng-template #redo_tip>
        Redo <app-tip-hotkey [metaKey]="true" [shiftKey]="true" [key]="'Z'"></app-tip-hotkey>
      </ng-template>

      <button
        class="button button_icon button_grey button_segment-right"
        [class.button_disabled]="!(customizeService.isRedoAvailable$ | async)"
        [appTip]="redo_tip"
        [appTipOptions]="{ side: 'bottom' }"
        (click)="customizeService.redoChangesHistory()"
      >
        <span class="button__icon icon-redo"></span>
      </button>
    </div>

    <a
      *ngIf="snapshotsEnabled"
      href="javascript:void(0)"
      class="button button_icon button_grey"
      [appTip]="'Version History'"
      [appTipOptions]="{ side: 'bottom' }"
      (click)="onSnapshotsClick()"
    >
      <span class="button__icon icon-history"></span>
    </a>

    <div class="customize-toolbar__section">
      <button *ngIf="!demoUser && stubResources.length" class="button button_purple" (click)="configureStubResources()">
        <span class="button__label">Connect your Resources</span>
      </button>

      <!--      <button-->
      <!--        *ngIf="customizeService.handler"-->
      <!--        class="customize-toolbar__save button"-->
      <!--        [class.button_orange]="hasChange"-->
      <!--        [class.button_primary]="hasChange"-->
      <!--        [class.button_grey]="!hasChange"-->
      <!--        [disabled]="saveLoading || !hasChange"-->
      <!--        (click)="saveChanges()"-->
      <!--      >-->
      <!--        <app-loader-small *ngIf="saveLoading" class="button__icon button__icon_left"> </app-loader-small>-->
      <!--        <span class="button__label">Save page</span>-->
      <!--      </button>-->

      <a
        *ngIf="demoUser"
        [routerLink]="['/register']"
        [queryParams]="{ transfer_projects: project?.uniqueName }"
        class="button button_purple"
        [appClickEvent]="analyticsEvents.Publish.ClickPublish"
      >
        <span class="button__label">Publish</span>
      </a>

      <ng-container *ngIf="!demoUser">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_position_bottom-right']"
          [cdkConnectedOverlayPositions]="[
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetX: 0,
              offsetY: 0
            }
          ]"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="publishOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          (backdropClick)="setPublishOpened(false)"
          (detach)="setPublishOpened(false)"
        >
          <app-publish-menu
            [published]="published"
            [draftChangesTotal]="draftChangesTotal"
            (close)="setPublishOpened(false)"
            (showChanges)="showChanges(); setPublishOpened(false)"
            (deleteDraft)="deleteDraft(); setPublishOpened(false)"
            (publishDraft)="publishDraft(); setPublishOpened(false)"
            (editDomain)="editDomain(); setPublishOpened(false)"
            (openAppUrl)="openAppUrl($event); setPublishOpened(false)"
            (openShareInvite)="share(); setPublishOpened(false)"
            (openSharePublicLink)="share(); setPublishOpened(false)"
            (openSharePublicAccess)="share(shareTabs.Public); setPublishOpened(false)"
            (openSignUp)="openSignUp(); setPublishOpened(false)"
          ></app-publish-menu>
        </ng-template>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_position_bottom-right']"
          [cdkConnectedOverlayPositions]="[
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetX: 0,
              offsetY: 0
            }
          ]"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="saveError && saveErrorOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
        >
          <app-save-error-menu
            [saveError]="saveError"
            (saveChanges)="saveChanges(); setSaveErrorOpened(false)"
            (mouseenter)="saveErrorHover$.next(true)"
            (mouseleave)="saveErrorHover$.next(false)"
          ></app-save-error-menu>
        </ng-template>

        <div
          *ngIf="savingChanges"
          class="button button_purple button_segment-left"
          style="vertical-align: top; width: 106px;"
        >
          <app-loader-small class="button__icon button__icon_left" style="margin-top: 2px;"></app-loader-small>
          <span class="button__label">Saving</span>
        </div>

        <a
          *ngIf="publishLoading"
          href="javascript:void(0)"
          class="button button_purple button_segment-left button_disabled"
          style="vertical-align: top;"
        >
          <app-loader-small class="button__icon button__icon_left" style="margin-top: 2px;"></app-loader-small>
          <span class="button__label">Publishing</span>
        </a>

        <ng-container *ngIf="!savingChanges && !publishLoading">
          <a
            href="javascript:void(0)"
            class="button button_purple button_segment-left customize-toolbar__publish"
            style="vertical-align: top; width: 106px;"
            (click)="preview()"
            (mouseenter)="previewHover$.next(true)"
            (mouseleave)="previewHover$.next(false)"
          >
            <span
              class="button__icon button__icon_left"
              [class.icon-play_2]="!saveError"
              [class.icon-warning_filled]="saveError"
            >
            </span>
            <span class="button__label">Preview</span>
            <span
              *ngIf="saveError"
              class="customize-toolbar__publish-indicator customize-toolbar__publish-indicator_red"
            ></span>
            <span
              *ngIf="!saveError && draftChanges.length"
              class="customize-toolbar__publish-indicator customize-toolbar__publish-indicator_yellow"
            ></span>
          </a>
        </ng-container>

        <a
          href="javascript:void(0)"
          class="button button_purple button_border button_icon button_segment-right"
          style="vertical-align: top;"
          (click)="setPublishOpened(true)"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
        >
          <span class="button__icon"><span class="icon-arrow_down_3"></span></span>
        </a>
      </ng-container>

      <a
        *ngIf="!demoUser && !stubResources.length && project?.hasEnvironmentAccessPermission(environment)"
        href="javascript:void(0)"
        class="button button_grey"
        [appClickEvent]="analyticsEvents.Share.ClickedShare"
        (click)="share()"
      >
        Share
      </a>
    </div>

    <div class="customize-toolbar__section">
      <!--      <div class="dropdown-anchor customize-toolbar__dropdown-info">-->
      <!--        <span class="button-info button-info_light icon-help"></span>-->
      <!--        <div class="dropdown dropdown_position_bottom-left dropdown_menu dropdown_position_bottom">-->
      <!--          <div class="dropdown__inner">-->
      <!--            <div class="dropdown__section">-->
      <!--              <a href="javascript:void(0)" class="dropdown__item" (click)="openChat()">-->
      <!--                <span class="icon-chat dropdown__item-icon"></span>-->
      <!--                <span class="dropdown__item-label">Contact Support</span>-->
      <!--              </a>-->
      <!--              <a href="https://docs.jetadmin.io/" class="dropdown__item" target="_blank">-->
      <!--                <span class="icon-documents dropdown__item-icon"></span>-->
      <!--                <span class="dropdown__item-label">Documentation</span>-->
      <!--              </a>-->
      <!--              <a href="https://feedback.jetadmin.io/" class="dropdown__item" target="_blank">-->
      <!--                <span class="icon-idea dropdown__item-icon"></span>-->
      <!--                <span class="dropdown__item-label">Feature Requests</span>-->
      <!--              </a>-->
      <!--              <a href="https://feedback.jetadmin.io/changelog/" class="dropdown__item" target="_blank">-->
      <!--                <span class="icon-news dropdown__item-icon"></span>-->
      <!--                <span class="dropdown__item-label">See What’s New</span>-->
      <!--              </a>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="dropdown-anchor customize-toolbar__dropdown-profile">
        <span
          class="button-profile"
          [appTutorialTip]="{
            tip: 'Open User menu',
            task: 'switch_to_dark_theme',
            page: 0,
            pageItem: 'hover_user_menu',
            action: 'hover'
          }"
          [style.background-image]="
            (currentUserStore.instance$ | async)?.photo
              ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')'
              : ''
          "
        ></span>

        <div class="dropdown dropdown_position_bottom-left dropdown_menu dropdown_position_bottom">
          <app-menu-profile
            [theme]="'dark'"
            [position]="'bottom-left'"
            [analyticsSource]="'builder_menu'"
          ></app-menu-profile>
        </div>
      </div>
    </div>
  </div>
</div>

<app-customize-preview
  [active]="!(customizeService.enabled$ | async)"
  [draftChanges]="draftChanges"
  [draftChangesTotal]="draftChangesTotal"
  [savingChanges]="savingChanges"
  [publishLoading]="publishLoading"
  (exitPreview)="exitPreview()"
  (deleteDraft)="deleteDraft()"
  (publishDraft)="publishDraft()"
  (showChanges)="showChanges()"
></app-customize-preview>

<app-customize-help></app-customize-help>

<div class="mobile-only">
  <div class="modal-message">
    <div class="modal-message__content">
      <div class="modal-message__icon icon-configure"></div>
      <div class="modal-message__title">Builder is not available on mobile</div>
      <a href="javascript:void(0)" class="modal-message__button" (click)="switchToApp(project?.domain)">
        Open Public URL
      </a>
    </div>
  </div>
</div>

import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { EditableContentComponent } from './components/editable-content/editable-content.component';

@NgModule({
  imports: [CommonModule, FormsModule, TextFieldModule, SharedModule, TipsModule],
  declarations: [EditableContentComponent],
  exports: [EditableContentComponent]
})
export class EditableContentModule {}

import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import keys from 'lodash/keys';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { getCurrentLanguage } from '@common/localize';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { Field } from '@modules/projects';
import { User, UserService } from '@modules/users';
import { isSet, NonEmptyValidator, trimAll } from '@shared';

export function validatePasswordRepeat(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const group = control.parent as RegisterForm;
    if (!group) {
      return null;
    }

    if (
      isSet(group.controls.password.value) &&
      isSet(group.controls.password_repeat.value) &&
      group.controls.password.value != group.controls.password_repeat.value
    ) {
      return {
        local: ['Passwords do not match']
      };
    }
  };
}

export class RegisterCustomFieldsControl extends FormGroup {
  constructor() {
    super({});
  }

  init(customFields: Field[] = []) {
    customFields.forEach(field => {
      const control = new FormControl(undefined, field.required ? Validators.required : undefined);
      this.setControl(field.name, control);
    });

    keys(this.controls)
      .filter(name => !customFields.find(item => item.name == name))
      .forEach(name => this.removeControl(name));
  }
}

@Injectable()
export class RegisterForm extends FormGroup {
  controls: {
    email: FormControl;
    first_name: FormControl;
    last_name: FormControl;
    password: FormControl;
    password_repeat: FormControl;
    no_email_subscription: FormControl;
    agree_with_privacy_policy: FormControl;
    custom_fields: RegisterCustomFieldsControl;
  };

  submit = false;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private userService: UserService,
    private formUtils: FormUtils
  ) {
    super({
      email: new FormControl('', [Validators.required, Validators.email]),
      first_name: new FormControl('', [Validators.required, NonEmptyValidator("This field can't be empty")]),
      last_name: new FormControl(''),
      password: new FormControl('', Validators.required),
      password_repeat: new FormControl('', [Validators.required, validatePasswordRepeat()]),
      no_email_subscription: new FormControl(false),
      agree_with_privacy_policy: new FormControl(true, Validators.requiredTrue),
      custom_fields: new RegisterCustomFieldsControl()
    });
  }

  init(customFields: Field[] = []) {
    this.controls.custom_fields.init(customFields);
  }

  markAsSubmit() {
    this.submit = true;
    this.markAsDirty();
  }

  register(projectName?: string, transferDemoProjects?: string[]): Observable<{ user: User; selfSignOn: boolean }> {
    const user = new User();

    user.username = trimAll(this.controls.email.value);
    user.email = trimAll(this.controls.email.value);
    user.firstName = trimAll(this.controls.first_name.value);
    user.lastName = trimAll(this.controls.last_name.value);
    user.password = this.controls.password.value;
    user.emailSubscription = !this.controls.no_email_subscription.value;

    const projectToken = this.apiService.getProjectToken();
    const language = getCurrentLanguage();
    const customFields = this.controls.custom_fields.value;

    return this.userService
      .create(user, {
        projectName: projectName,
        projectToken: projectToken,
        transferDemoProjects: transferDemoProjects,
        language: language,
        customFields: customFields
      })
      .pipe(
        delayWhen(() => {
          return this.authService.login(this.controls.email.value, this.controls.password.value);
        }),
        catchError(error => {
          this.markAsDirty();
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

import { ConnectedPosition } from '@angular/cdk/overlay';

export const viewContextTokenPopoverOverlayPositions: ConnectedPosition[] = [
  {
    panelClass: ['overlay_position_left-center'],
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    weight: 10
  },
  {
    panelClass: ['overlay_position_left-bottom'],
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: -8,
    weight: 9
  },
  {
    panelClass: ['overlay_position_left-top'],
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: 8,
    weight: 8
  },
  {
    panelClass: ['overlay_position_bottom-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: -8,
    weight: 7
  },
  {
    panelClass: ['overlay_position_bottom-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: 8,
    weight: 6
  },
  {
    panelClass: ['overlay_position_top-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: -8,
    weight: 5
  },
  {
    panelClass: ['overlay_position_top-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: 8,
    weight: 4
  },
  {
    panelClass: ['overlay_position_right-center'],
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    weight: 3
  },
  {
    panelClass: ['overlay_position_right-bottom'],
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: -8,
    weight: 2
  },
  {
    panelClass: ['overlay_position_right-top'],
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: 8,
    weight: 1
  }
];

import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { ModelDescriptionService } from '../services/model-description/model-description.service';

@Injectable()
export class ModelDescriptionStore extends SingletonStore<ModelDescription[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private modelDescriptionService: ModelDescriptionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<ModelDescription[]> {
    return this.currentProjectStore.getFirst().pipe(
      switchMap(() => {
        if (!this.currentProjectStore.instance || !this.currentEnvironmentStore.instance) {
          return of([]);
        }

        return this.mode$.pipe(
          switchMap(mode =>
            this.modelDescriptionService.get(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              mode == AdminMode.Builder
            )
          )
        );
      }),
      catchError(e => {
        console.error('[MODEL_DESCRIPTION_STORE_FETCH]', e);
        return throwError(e);
      })
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ModelDescription[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ModelDescription[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  public getDetail(
    modelId: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<ModelDescription> {
    return this.get(forceUpdate, options).pipe(
      map(results => {
        if (!results) {
          return;
        }

        return results.find(item => item.isSame(modelId));
      })
    );
  }

  public getDetailFirst(
    modelId: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<ModelDescription> {
    return this.getFirst(forceUpdate, options).pipe(
      map(results => {
        if (!results) {
          return;
        }

        return results.find(item => item.isSame(modelId));
      })
    );
  }

  public addItem(modelDescription: ModelDescription) {
    this.instance = [...this.instance, modelDescription];
  }

  public updateItem(modelDescription: ModelDescription) {
    const index = this.instance.findIndex(item => item.isSame(modelDescription));

    if (index !== -1) {
      this.instance = this.instance.map((item, i) => {
        if (i === index) {
          return modelDescription;
        } else {
          return item;
        }
      });
    }
  }

  public updateItems(modelDescription: ModelDescription[]) {
    let replacesFound = false;
    const newInstance = this.instance.map((instanceItem, i) => {
      const replaceItem = modelDescription.find(item => item.isSame(instanceItem));

      if (!replacesFound && replaceItem) {
        replacesFound = true;
      }

      return replaceItem || instanceItem;
    });

    if (replacesFound) {
      this.instance = newInstance;
    }
  }

  public deleteItem(modelDescription: ModelDescription) {
    const index = this.instance.findIndex(item => item.isSame(modelDescription));

    if (index !== -1) {
      this.instance = this.instance.filter((item, i) => i !== index);
    }
  }
}

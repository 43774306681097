var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { ActionItem, ActionType } from '@modules/actions';
import { isSet } from '@shared';
import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
var ActionWorkflowStep = /** @class */ (function (_super) {
    __extends(ActionWorkflowStep, _super);
    function ActionWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Action;
        _this.resultSteps = false;
        _this.successSteps = [];
        _this.errorSteps = [];
        return _this;
    }
    ActionWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['action']) {
            this.action = new ActionItem().deserialize(data['action']);
        }
        if (isSet(data['result_steps'])) {
            this.resultSteps = data['result_steps'];
        }
        if (data['success_steps']) {
            this.successSteps = data['success_steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        if (data['error_steps']) {
            this.errorSteps = data['error_steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        return this;
    };
    ActionWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { action: this.action ? this.action.serialize() : undefined, result_steps: this.resultSteps, success_steps: this.successSteps.map(function (item) { return item.serialize(); }), error_steps: this.errorSteps.map(function (item) { return item.serialize(); }) });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ActionWorkflowStep.prototype.defaultName = function () {
        if (this.action && this.action.actionDescription) {
            return this.action.actionDescription.typeStr;
        }
        else {
            return _super.prototype.defaultName.call(this);
        }
    };
    Object.defineProperty(ActionWorkflowStep.prototype, "analyticsName", {
        get: function () {
            return 'action';
            if (this.action && this.action.actionDescription) {
                return ['action', this.action.actionDescription.type].join('_');
            }
            else if (this.action && this.action.sharedActionDescription) {
                return ['action', ActionType.Query].join('_');
            }
            else {
                return 'action';
            }
        },
        enumerable: true,
        configurable: true
    });
    ActionWorkflowStep.prototype.getIcon = function () {
        if (this.action && this.action.actionDescription) {
            return this.action.actionDescription.typeIcon;
        }
        else {
            return _super.prototype.defaultName.call(this);
        }
    };
    return ActionWorkflowStep;
}(WorkflowStep));
export { ActionWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Action, ActionWorkflowStep);

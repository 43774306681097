<div
  class="color-selector2"
  [class.color-selector2_disabled]="colorControl.disabled"
  [ngClass]="'color-selector2_size_' + size"
>
  <div
    *ngIf="resetEnabled"
    class="color-selector2__item"
    [class.color-selector2__item_contrast]="!(resetBackgroundColor | appIsSet)"
    [style.background-color]="(resetBackgroundColor | appIsColorHex) ? resetBackgroundColor : null"
    [ngClass]="[
      !(resetBackgroundColor | appIsSet) ? 'icon-close' : '',
      resetBackgroundColor && !(resetBackgroundColor | appIsColorHex)
        ? 'background-color_' + resetBackgroundColor + '_1'
        : ''
    ]"
    (click)="customColorEnabledControl.patchValue(false); colorControl.patchValue(resetValue)"
  >
    <div
      class="color-selector2__item-border"
      [ngClass]="resetBorderColor ? 'border-color_' + resetBorderColor + '_1' : ''"
      [class.color-selector2__item-border_active]="!customColorEnabledControl.value && colorControl.value == resetValue"
    ></div>
  </div>

  <div
    *ngFor="let color of colors"
    class="color-selector2__item"
    [style.background-color]="(color.color | appIsColorHex) ? color.color : null"
    [ngClass]="!(color.color | appIsColorHex) ? 'background-color_' + color.color + '_1' : ''"
    (click)="customColorEnabledControl.patchValue(false); colorControl.patchValue(color.value)"
  >
    <div
      class="color-selector2__item-border"
      [class.color-selector2__item-border_active]="
        !customColorEnabledControl.value && colorControl.value === color.value
      "
      [style.border-color]="(color.color | appIsColorHex) ? color.color : null"
      [ngClass]="!(color.color | appIsColorHex) ? 'border-color_' + color.color + '_1' : ''"
    ></div>
  </div>

  <ng-container *ngIf="customColorEnabledControl && customColorControl">
    <ng-template
      (backdropClick)="setColorOpened(false)"
      (detach)="setColorOpened(false)"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="colorOpened"
      [cdkConnectedOverlayOrigin]="color_trigger"
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 },
        { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 },
        { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 },
        { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 },
        { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
        { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 },
        { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
        { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
      ]"
      [cdkConnectedOverlayPush]="true"
      cdkConnectedOverlay
    >
      <div class="popover2 overlay-content overlay-content_animated">
        <div class="popover2__inner popover2__inner_no-margin-collapse" [style.width.px]="295">
          <app-color-picker [hexControl]="customColorControl"></app-color-picker>
        </div>
      </div>
    </ng-template>

    <div
      class="color-selector2__item color-selector2__item_palette"
      [class.color-selector2__item_disabled]="!colorControl.disabled && customColorControl.disabled"
    >
      <div
        class="color-selector2__item-border border-color_blue_1"
        [class.color-selector2__item-border_active]="customColorEnabledControl.value"
        [style.border-color]="customColorControl.value"
        cdkOverlayOrigin
        #color_trigger="cdkOverlayOrigin"
        (click)="onCustomColorClick()"
      ></div>
    </div>
  </ng-container>
</div>

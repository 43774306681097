import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SelectSource } from '@common/select';
import { isSet } from '@shared';
import { defaultCompare } from '../chips/chips.component';
var ChipsItemComponent = /** @class */ (function () {
    function ChipsItemComponent(cd) {
        this.cd = cd;
        this.options = [];
        this.addedOptions = [];
        this.compareWith = defaultCompare;
        this.remove = new EventEmitter();
        this.loading = true;
        this.value$ = new BehaviorSubject(undefined);
        this.source$ = new BehaviorSubject(undefined);
        this.options$ = new BehaviorSubject([]);
        this.addedOptions$ = new BehaviorSubject([]);
    }
    ChipsItemComponent.prototype.ngOnInit = function () {
        this.initOption();
    };
    ChipsItemComponent.prototype.ngOnDestroy = function () { };
    ChipsItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            this.value$.next(this.value);
        }
        if (changes.source) {
            this.source$.next(this.source);
        }
        if (changes.options) {
            this.options$.next(this.options);
        }
        if (changes.addedOptions) {
            this.addedOptions$.next(this.addedOptions);
        }
    };
    ChipsItemComponent.prototype.initOption = function () {
        var _this = this;
        combineLatest(this.value$.pipe(distinctUntilChanged(function (lhs, rhs) { return _this.compareWith(lhs, rhs); })), this.source$, this.options$)
            .pipe(switchMap(function (_a) {
            var value = _a[0], source = _a[1], options = _a[2];
            _this.loading = false;
            _this.cd.markForCheck();
            var addedOption = _this.addedOptions.find(function (option) { return _this.compareWith(option.value, value); });
            if (addedOption) {
                return of(addedOption);
            }
            else if (source) {
                _this.loading = true;
                _this.cd.markForCheck();
                if (!isSet(value)) {
                    return of(undefined);
                }
                return source.fetchByValue([value]).pipe(map(function (result) { return result[0]; }), catchError(function () {
                    return of({ value: value, name: value });
                }));
            }
            else {
                var existingOption = options.find(function (option) { return _this.compareWith(option.value, value); });
                if (existingOption) {
                    return of(existingOption);
                }
                else {
                    return of({ value: value, name: value });
                }
            }
        }), untilDestroyed(this))
            .subscribe(function (option) {
            _this.item = option;
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    return ChipsItemComponent;
}());
export { ChipsItemComponent };

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, share } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { EmailTemplate, EmailTemplateService } from '@modules/emails';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class EmailTemplateEditForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    subject: FormControl;
    html_content: FormControl;
  };

  instance: EmailTemplate;
  baseInstance: EmailTemplate;

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private emailTemplateService: EmailTemplateService
  ) {
    super({
      name: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      html_content: new FormControl('', Validators.required)
    });
  }

  ngOnDestroy(): void {}

  init(options: { instance?: EmailTemplate; baseInstance?: EmailTemplate } = {}) {
    this.instance = options.instance;
    this.baseInstance = options.baseInstance;

    if (options.instance) {
      this.controls.name.patchValue(options.instance.name);
      this.controls.subject.patchValue(options.instance.subject);
      this.controls.html_content.patchValue(options.instance.htmlContent);
    } else if (options.baseInstance) {
      this.controls.name.patchValue(options.baseInstance.name);
      this.controls.subject.patchValue(options.baseInstance.subject);
      this.controls.html_content.patchValue(options.baseInstance.htmlContent);
    }

    this.markAsPristine();
  }

  submit(): Observable<EmailTemplate> {
    this.markAsDirty();

    const instance = this.instance ? cloneDeep(this.instance) : new EmailTemplate();

    instance.name = this.controls.name.value;
    instance.subject = this.controls.subject.value;
    instance.htmlContent = this.controls.html_content.value;

    let obs: Observable<EmailTemplate>;

    if (this.instance) {
      obs = this.emailTemplateService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    } else {
      obs = this.emailTemplateService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    }

    return obs.pipe(
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      }),
      share()
    );
  }
}

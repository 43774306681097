import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ViewContext } from '@modules/customize';
import { scrollTo } from '@shared';
import { getCustomizeLayerComponent } from '../../data/customize-layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
var ViewEditorSidebarComponent = /** @class */ (function () {
    function ViewEditorSidebarComponent(editorContext, cd) {
        this.editorContext = editorContext;
        this.cd = cd;
        this.viewUpdated$ = this.editorContext.viewChanged$().pipe(filter(function (event) { return event.source != ViewEditorCustomizeSource.CustomizeView; }), map(function (event) { return event.view; }));
    }
    ViewEditorSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.editorContext.customizingLayers$, this.editorContext.customizingView$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customizingLayers = _a[0], customizingView = _a[1];
            var singleLayer = customizingLayers.length == 1 ? customizingLayers[0] : undefined;
            if (singleLayer) {
                var component = getCustomizeLayerComponent(singleLayer.type);
                if (!component) {
                    _this.customizeLayerComponent = undefined;
                    _this.customizeView = undefined;
                    _this.cd.markForCheck();
                    return;
                }
                _this.customizeLayerComponent = {
                    component: component,
                    inputs: {
                        layer: singleLayer,
                        layerUpdated$: _this.editorContext.layerChanged$().pipe(filter(function (event) { return event.layer.isSame(singleLayer) && event.source != ViewEditorCustomizeSource.CustomizeLayer; }), map(function (event) { return event.layer; })),
                        viewContext: _this.viewContext
                    },
                    outputs: {
                        layerChange: [
                            function (result) {
                                _this.editorContext.markLayersChanged([result], ViewEditorCustomizeSource.CustomizeLayer);
                            }
                        ],
                        closeCustomize: [
                            function () {
                                _this.editorContext.resetCustomizingLayers();
                            }
                        ]
                    }
                };
                _this.customizeView = undefined;
                _this.cd.detectChanges();
                _this.scrollToTop();
            }
            else if (customizingView) {
                _this.customizeView = _this.editorContext.view$.value;
                _this.customizeLayerComponent = undefined;
                _this.cd.detectChanges();
                _this.scrollToTop();
            }
            else {
                _this.customizeView = undefined;
                _this.customizeLayerComponent = undefined;
                _this.cd.markForCheck();
            }
        });
    };
    ViewEditorSidebarComponent.prototype.ngOnDestroy = function () { };
    ViewEditorSidebarComponent.prototype.scrollToTop = function (animated) {
        if (animated === void 0) { animated = true; }
        var duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
        scrollTo(this.root.nativeElement, 0, duration);
    };
    ViewEditorSidebarComponent.prototype.onViewChange = function (result) {
        this.editorContext.markViewChanged(result, ViewEditorCustomizeSource.CustomizeView);
    };
    ViewEditorSidebarComponent.prototype.onViewCloseCustomize = function () {
        this.editorContext.resetCustomizeView();
    };
    return ViewEditorSidebarComponent;
}());
export { ViewEditorSidebarComponent };

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ResizeType } from '@common/resizable';
import { AlignHorizontal, AlignVertical } from '@modules/customize';

@Component({
  selector: 'app-auto-element-border',
  templateUrl: './auto-element-border.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoElementBorderComponent implements OnInit, OnChanges {
  @Input() error = true;
  @Input() resizeSupported: { width: boolean; height: boolean } = { width: false, height: false };
  @Input() resizeEnabled: { width: boolean; height: boolean } = { width: false, height: false };
  @Input() resizeStep: { width?: number; height?: number } = {};
  @Input() resizeMinSize: { width?: number; height?: number } = {};
  @Input() resizeMaxSize: { width?: number; height?: number } = {};
  @Input() alignHorizontal: AlignHorizontal;
  @Input() alignVertical: AlignVertical;
  @Input() containerHorizontal = false;

  resizeTypes = ResizeType;
  alignsHorizontal = AlignHorizontal;
  alignsVertical = AlignVertical;

  widthLeftResizable = false;
  widthRightResizable = false;
  heightTopResizable = false;
  heightBottomResizable = false;
  resizeTypesTopLeft: ResizeType[] = [];
  resizeTypesTopRight: ResizeType[] = [];
  resizeTypesBottomLeft: ResizeType[] = [];
  resizeTypesBottomRight: ResizeType[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateResize();
  }

  updateResize() {
    this.widthLeftResizable = this.getWidthLeftResizable();
    this.widthRightResizable = this.getWidthRightResizable();
    this.heightTopResizable = this.getHeightTopResizable();
    this.heightBottomResizable = this.getHeightBottomResizable();
    this.resizeTypesTopLeft = this.getResizeTypesTopLeft();
    this.resizeTypesTopRight = this.getResizeTypesTopRight();
    this.resizeTypesBottomLeft = this.getResizeTypesBottomLeft();
    this.resizeTypesBottomRight = this.getResizeTypesBottomRight();
  }

  getWidthLeftResizable() {
    return (
      !this.containerHorizontal &&
      this.resizeSupported.width &&
      [AlignHorizontal.Center, AlignHorizontal.Right].includes(this.alignHorizontal)
    );
  }

  getWidthRightResizable() {
    return (
      this.resizeSupported.width &&
      (this.containerHorizontal ||
        !this.alignHorizontal ||
        [AlignHorizontal.Justify, AlignHorizontal.Left, AlignHorizontal.Center].includes(this.alignHorizontal))
    );
  }

  getHeightTopResizable() {
    return (
      this.containerHorizontal &&
      this.resizeSupported.height &&
      [AlignVertical.Center, AlignVertical.Bottom].includes(this.alignVertical)
    );
  }

  getHeightBottomResizable() {
    return (
      this.resizeSupported.height &&
      (!this.containerHorizontal ||
        !this.alignVertical ||
        [AlignVertical.Justify, AlignVertical.Top, AlignVertical.Center].includes(this.alignVertical))
    );
  }

  getResizeTypesTopLeft() {
    const result = [];

    if (this.widthLeftResizable) {
      result.push(ResizeType.Horizontal);
    }

    if (this.heightTopResizable) {
      result.push(ResizeType.Vertical);
    }

    return result.length ? result : undefined;
  }

  getResizeTypesTopRight() {
    const result = [];

    if (this.widthRightResizable) {
      result.push(ResizeType.Horizontal);
    }

    if (this.heightTopResizable) {
      result.push(ResizeType.Vertical);
    }

    return result.length ? result : undefined;
  }

  getResizeTypesBottomLeft() {
    const result = [];

    if (this.widthLeftResizable) {
      result.push(ResizeType.Horizontal);
    }

    if (this.heightBottomResizable) {
      result.push(ResizeType.Vertical);
    }

    return result.length ? result : undefined;
  }

  getResizeTypesBottomRight() {
    const result = [];

    if (this.widthRightResizable) {
      result.push(ResizeType.Horizontal);
    }

    if (this.heightBottomResizable) {
      result.push(ResizeType.Vertical);
    }

    return result.length ? result : undefined;
  }
}

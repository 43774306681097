import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectSettingsService } from '@modules/project-settings';
import {
  Environment,
  isResourceTypeItemCompatible,
  isResourceTypeItemReplicable,
  JET_APP_RESOURCE,
  Project,
  ProjectToken,
  ProjectTokenService,
  Resource,
  ResourceDeploy,
  ResourceService,
  ResourceType,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService, ResourceGeneratorResolver } from '@modules/resource-generators';
import { Template, TemplateUsedResource } from '@modules/template';
import { generateAlphanumeric, generateUUID, isSet } from '@shared';

import { ChooseResourcesComponent } from '../../components/choose-resources/choose-resources.component';

export interface SelectResourcesResult {
  resources?: { token: string; resource: Resource; created?: boolean }[];
  useDemo?: boolean;
  cancelled?: boolean;
}

@Injectable()
export class SelectResourcesController {
  constructor(
    private popupService: PopupService,
    private resourceEditController: ResourceEditController,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private menuSettingsStore: MenuSettingsStore,
    private resourceGeneratorResolver: ResourceGeneratorResolver,
    private projectTokenService: ProjectTokenService,
    private resourceService: ResourceService,
    private regionService: RegionService,
    private projectSettingsService: ProjectSettingsService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector
  ) {}

  selectTemplateResourcesIfNeeded(
    project: Project,
    environment: Environment,
    template: Template,
    options: {
      useDemoResources?: boolean;
      analyticsSource?: string;
    } = {}
  ): Observable<SelectResourcesResult> {
    const selectResources = template.usedResources.map(item => {
      return {
        type: item.type,
        typeItem: item.typeItem,
        token: item.token
      };
    });
    const autoCreated = template.usedResources.filter(
      item => isResourceTypeItemReplicable(item.typeItem) || isSet(item.options)
    ).length;
    const demoAvailable = autoCreated == template.usedResources.length;

    if (demoAvailable && options.useDemoResources) {
      return of({ resources: [], useDemo: true });
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToAddMultipleResource, {
      TemplateID: template.name
    });

    return this.selectResourcesIfNeeded(project, environment, selectResources, {
      demoAvailable: demoAvailable,
      analyticsSource: options.analyticsSource
    }).pipe(
      tap(result => {
        if (result.resources) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.MultipleResourceSuccessfullyAdded, {
            TemplateID: template.name
          });
        }
      })
    );
  }

  selectResourcesIfNeeded(
    project: Project,
    environment: Environment,
    selectResources: { type: ResourceType; typeItem: ResourceTypeItem; token: string }[],
    options: {
      demoAvailable?: boolean;
      analyticsSource?: string;
    } = {}
  ): Observable<SelectResourcesResult> {
    if (!selectResources.length) {
      return of<SelectResourcesResult>({ resources: [] });
    }

    const selectedObs = new ReplaySubject<SelectResourcesResult>();
    const resources = project
      .getEnvironmentResources(environment.uniqueName)
      .filter(item => !item.demo && item.uniqueName != JET_APP_RESOURCE);
    // const selectResources = template.usedResources.filter(item => !item.options);

    // Ask user to create or select used resources
    if (
      selectResources.length == 1 &&
      !resources.find(item => isResourceTypeItemCompatible(item.typeItem, selectResources[0].typeItem))
    ) {
      // If only 1 resource is required to select and there is no such resource type in the project yet
      // Then open "Resource create" popup without "Select resource" popup
      const selectResource = selectResources[0];

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
        Source: options.analyticsSource,
        ResourceID: selectResource.typeItem.name
      });

      this.resourceEditController
        .createResource(selectResource.typeItem, {
          resourceNameEditing: true,
          analyticsSource: options.analyticsSource
        })
        .subscribe(result => {
          if (result.cancelled) {
            selectedObs.next({ cancelled: true });
          } else {
            selectedObs.next({
              resources: [
                {
                  ...selectResource,
                  resource: result.resource,
                  created: true
                }
              ]
            });
          }
        });
    } else if (selectResources.length == 0) {
      // If only no resources are required to select
      selectedObs.next({ resources: [] });
    } else {
      // Otherwise open "Select resource" popup
      this.popupService.push({
        component: ChooseResourcesComponent,
        popupComponent: ThinDialogPopupComponent,
        inputs: {
          project: project,
          selectResources: selectResources,
          demoAvailable: options.demoAvailable,
          analyticsSource: options.analyticsSource
        },
        outputs: {
          selected: [
            result => {
              selectedObs.next({ resources: result });
            }
          ],
          demoSelected: [
            () => {
              selectedObs.next({ resources: [], useDemo: true });
            }
          ],
          cancelled: [() => selectedObs.next({ cancelled: true })]
        },
        injector: this.injector
      });
    }

    return selectedObs;
  }

  createTemplateReplicatedResource(
    project: Project,
    environment: Environment,
    template: Template,
    item: TemplateUsedResource,
    useResource?: Resource
  ): Observable<{ type: ResourceType; typeItem: ResourceTypeItem; token: string; options?: any; resource: Resource }> {
    if (useResource) {
      return this.projectSettingsService
        .saveResource(
          project,
          environment,
          useResource,
          false,
          {
            modelDescriptions: item.models
              ? item.models.map(model => {
                  model.modelDescription.resource = useResource.uniqueName;
                  return model.modelDescription.serialize();
                })
              : undefined,
            replicateModelDescriptions: true,
            models: item.models
              ? item.models.map(model => {
                  return {
                    model: model.modelDescription.model,
                    items: model.instances
                  };
                })
              : undefined
          },
          false
        )
        .pipe(
          map(result => {
            return {
              ...item,
              resource: result
            };
          })
        );
    } else {
      const generator = this.resourceGeneratorResolver.get<DatabaseGeneratorService>(item.typeItem.name);
      const instance = new Resource();

      instance.uniqueName = [item.typeItem.name, generateAlphanumeric(4)].join('_');
      instance.name = item.typeItem.label;
      instance.type = item.typeItem.resourceType;
      instance.typeItem = item.typeItem;
      instance.deploy = ResourceDeploy.Direct;
      instance.token = generateUUID();

      return this.resourceService.create(project.uniqueName, environment.uniqueName, instance).pipe(
        switchMap(resource => {
          return generator.createJetDatabase(project, environment, resource).pipe(
            switchMap(jetDatabase => {
              const address = jetDatabase.url.split(':', 2);
              return this.regionService.getDefaultJetBridge().pipe(
                map(jetBridge => {
                  return {
                    deploy: ResourceDeploy.Direct,
                    region: jetBridge.region ? jetBridge.region.uid : undefined,
                    url: jetBridge.url,
                    database_host: address[0],
                    database_port: address[1],
                    database_name: jetDatabase.databaseName,
                    database_user: jetDatabase.userName,
                    database_password: jetDatabase.password,
                    database_schema: resource.uniqueName,
                    token: resource.token
                  };
                }),
                switchMap(options => {
                  return generator.generateParams(project, environment, resource.typeItem, options);
                }),
                switchMap(resourceParams => {
                  return this.projectSettingsService
                    .saveResource(
                      project,
                      environment,
                      resource,
                      true,
                      {
                        ...resourceParams,
                        resourceParams: {
                          ...resourceParams.resourceParams,
                          template_stub_data: template.id
                        },
                        modelDescriptions: item.models
                          ? item.models.map(model => {
                              model.modelDescription.resource = resource.uniqueName;
                              return model.modelDescription.serialize();
                            })
                          : undefined,
                        replicateModelDescriptions: true,
                        models: item.models
                          ? item.models.map(model => {
                              return {
                                model: model.modelDescription.model,
                                items: model.instances
                              };
                            })
                          : undefined
                      },
                      false
                    )
                    .pipe(
                      map(result => {
                        return {
                          ...item,
                          resource: result
                        };
                      })
                    );
                })
              );
            })
          );
        })
      );
    }
  }

  createTemplateResourceWithOptions(
    project: Project,
    environment: Environment,
    template: Template,
    item: TemplateUsedResource
  ): Observable<{ type: ResourceType; typeItem: ResourceTypeItem; token: string; options?: any; resource: Resource }> {
    const generator = this.resourceGeneratorResolver.get(item.typeItem.name);

    return of(generator.tokenRequired).pipe(
      switchMap(tokenRequired => {
        if (!tokenRequired) {
          return of(undefined);
        }

        const token = new ProjectToken();

        token.resourceType = item.typeItem.resourceType;
        token.resourceTypeItem = item.typeItem.name;
        token.activated = true;

        return this.projectTokenService.create(project, token);
      }),
      switchMap(projectToken => {
        const options = { ...item.options };

        if (projectToken) {
          options['token'] = projectToken.token;
        }

        return generator.generateParams(project, environment, item.typeItem, options);
      }),
      switchMap(result => {
        const resource = new Resource();

        resource.name = item.typeItem.label;
        resource.type = item.type;
        resource.typeItem = item.typeItem;
        resource.deploy = item.options['deploy'];

        return this.projectSettingsService
          .saveResource(
            project,
            environment,
            resource,
            true,
            {
              resourceParams: {
                ...(result.resourceParams || {}),
                template_stub_data: template.id
              },
              modelDescriptions: result.modelDescriptions,
              resourceModelDescriptions: result.resourceModelDescriptions,
              actionDescriptions: result.actionDescriptions,
              secretTokens: result.secretTokens,
              storages: result.storages,
              extraTokens: result.extraTokens,
              mergeExisting: true
            },
            false
          )
          .pipe(
            map(instance => {
              return {
                ...item,
                resource: instance
              };
            })
          );
      })
    );
  }

  createTemplateResources(
    project: Project,
    environment: Environment,
    template: Template,
    useExistingUsedResource?: Resource
  ): Observable<
    { type: ResourceType; typeItem: ResourceTypeItem; token: string; options?: any; resource: Resource }[]
  > {
    return combineLatest(
      template.usedResources
        .map(item => {
          if (isResourceTypeItemReplicable(item.typeItem)) {
            return this.createTemplateReplicatedResource(project, environment, template, item, useExistingUsedResource);
          } else if (item.options && this.resourceGeneratorResolver.get(item.typeItem.name)) {
            return this.createTemplateResourceWithOptions(project, environment, template, item);
          }
        })
        .filter(item => item != undefined)
    ).pipe(
      delayWhen(() => {
        return combineLatest(
          this.modelDescriptionStore.getFirst(true),
          this.actionStore.getFirst(true),
          this.menuSettingsStore.getFirst(true)
        );
      })
    );
  }
}

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { Resource } from '@modules/projects';
import { Storage } from '@modules/storages';

import {
  StorageUploadPopupComponent,
  StorageUploadState
} from '../../components/storage-upload-popup/storage-upload-popup.component';

@Injectable()
export class StorageUploadPopupController {
  constructor(private popupService: PopupService) {}

  upload(options: {
    files: File[];
    resource: Resource;
    storage: Storage;
    path?: string[];
    injector: Injector;
    resolver: ComponentFactoryResolver;
  }): Observable<StorageUploadState> {
    const obs$ = new ReplaySubject<StorageUploadState>();
    const getCancelledState = (): StorageUploadState => {
      return {
        files: options.files.map(item => {
          return {
            file: item,
            path: [...(options.path || []), item.name].join('/'),
            uploadProgress: 0
          };
        }),
        processed: 0,
        succeeded: 0,
        failed: 0,
        cancelled: true
      };
    };

    this.popupService.push({
      component: StorageUploadPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        files: options.files,
        resource: options.resource,
        storage: options.storage,
        ...(options.path && {
          path: options.path
        })
      },
      outputs: {
        finished: [
          result => {
            obs$.next(result);
          }
        ],
        cancelled: [
          result => {
            obs$.next(result);
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs$.next(getCancelledState());
        }
      },
      resolver: options.resolver,
      injector: options.injector
    });

    return obs$;
  }
}

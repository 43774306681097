import { Type } from '@angular/core';

import { LayerType } from '@modules/views';

import { LayerComponent } from '../components/layers/base/layer.component';

const layerComponentsByType: { type: LayerType; cls: Type<LayerComponent> }[] = [];

export function getLayerComponent(type: LayerType): Type<LayerComponent> {
  const instance = layerComponentsByType.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerLayerComponent(type: LayerType, cls: Type<LayerComponent>) {
  layerComponentsByType.push({ type: type, cls: cls });
}

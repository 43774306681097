import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';

import { FeedItem } from '../../data/feed-item';

@Injectable({
  providedIn: 'root'
})
export class FeedItemService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private parameterCodec: CustomHttpParameterCodec
  ) {}

  get(projectName: string, environmentName: string, params = {}): Observable<FeedItemService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'feed_items/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params, encoder: this.parameterCodec });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new FeedItemService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  setMarkRead(projectName: string, environmentName: string, params?: {}): Observable<{ updated: number }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'feed_items/mark_read/');
        let headers = new HttpHeaders();

        const data: Object = {
          date_add_lte: moment().format()
        };
        if (params) {
          if (params['user_activity_object_type']) {
            data['user_activity_object_type'] = params['user_activity_object_type'];
          }

          if (params['user_activity_object_id']) {
            data['user_activity_object_id'] = params['user_activity_object_id'];
          }
        }
        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace FeedItemService {
  export class GetResponse {
    public results: FeedItem[];
    public hasMore: boolean;
    public next: string;
    public previous: string;
    public count: number;
    public perPage = 20;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new FeedItem().deserialize(item));
      this.hasMore = !!data['next'];
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }

    get numPages() {
      return Math.ceil(this.count / this.perPage);
    }
  }
}

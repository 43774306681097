export enum UserActivityType {
  DashboardList = 'dashboard_list',
  ModelList = 'model_list',
  ModelDetail = 'model_detail',
  ModelCreate = 'model_create',
  ModelUpdate = 'model_update',
  ModelDelete = 'model_delete',
  ModelMassDelete = 'model_mass_delete',
  ModelMassEdit = 'model_mass_edit',
  Message = 'message'
}

export const userActivityTypeLabels = {
  [UserActivityType.DashboardList]: 'view dashboard',
  [UserActivityType.ModelList]: 'view collection',
  [UserActivityType.ModelDetail]: 'view record',
  [UserActivityType.ModelCreate]: 'create record',
  [UserActivityType.ModelUpdate]: 'update record',
  [UserActivityType.ModelDelete]: 'delete record',
  [UserActivityType.ModelMassDelete]: 'delete multiple records'
};

export const userActivityTypeDescriptions: { [k: string]: (ctx: { user: string }) => string } = {
  [UserActivityType.DashboardList]: ctx => `${ctx.user} viewed dashboard`,
  [UserActivityType.ModelList]: ctx => `${ctx.user} viewed collection`,
  [UserActivityType.ModelDetail]: ctx => `${ctx.user} viewed record`,
  [UserActivityType.ModelCreate]: ctx => `${ctx.user} created record`,
  [UserActivityType.ModelUpdate]: ctx => `${ctx.user} updated record`,
  [UserActivityType.ModelDelete]: ctx => `${ctx.user} deleted record`,
  [UserActivityType.ModelMassDelete]: ctx => `${ctx.user} delete multiple records`
};

export const userActivityTypeIcons = {
  [UserActivityType.DashboardList]: 'chart',
  [UserActivityType.ModelList]: 'eye',
  [UserActivityType.ModelDetail]: 'eye',
  [UserActivityType.ModelCreate]: 'new_document',
  [UserActivityType.ModelUpdate]: 'pen',
  [UserActivityType.ModelDelete]: 'bin',
  [UserActivityType.ModelMassDelete]: 'delete'
};

export const messageType = [UserActivityType.Message];

export const actionType = [
  UserActivityType.DashboardList,
  UserActivityType.ModelList,
  UserActivityType.ModelDetail,
  UserActivityType.ModelCreate,
  UserActivityType.ModelUpdate,
  UserActivityType.ModelDelete,
  UserActivityType.ModelMassDelete,
  UserActivityType.ModelMassEdit
];

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';

import { ForkWorkflowStep, ForkWorkflowStepItem, WorkflowStepType } from '@modules/workflow';
import { TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

@Component({
  selector: 'app-fork-workflow-step',
  templateUrl: './fork-workflow-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForkWorkflowStepComponent extends WorkflowStepComponent<ForkWorkflowStep> implements OnInit, OnChanges {
  icon: string;

  trackStepItem = (() => {
    return (i, item: ForkWorkflowStepItem) => {
      return item ? item.uid : i;
    };
  })();

  constructor(workflowEditContext: WorkflowEditContext, cd: ChangeDetectorRef) {
    super(undefined, workflowEditContext, cd);
  }

  ngOnInit() {
    this.updateIcon();
    this.trackExecuteStatus({ successOnStart: true });

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<ForkWorkflowStepComponent>): void {
    if (changes.step) {
      this.updateIcon();
    }
  }

  updateIcon() {
    this.icon = this.step.getIcon();
  }
}

registerWorkflowStepComponent(WorkflowStepType.Fork, ForkWorkflowStepComponent);

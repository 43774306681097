/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./select-model-field-stub.component";
var styles_SelectModelFieldStubComponent = [];
var RenderType_SelectModelFieldStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectModelFieldStubComponent, data: {} });
export { RenderType_SelectModelFieldStubComponent as RenderType_SelectModelFieldStubComponent };
function View_SelectModelFieldStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 7, 0, currVal_1); }); }
export function View_SelectModelFieldStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "popover2__content"], ["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "token-popup-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "token-popup-search__input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Search "])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "token-popup__item"], ["style", "margin-top: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SelectModelFieldStubComponent_1)), i0.ɵdid(12, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(13, 4)], function (_ck, _v) { var currVal_1 = _ck(_v, 13, 0, "name", "category", "active", "date_add"); _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 5, 0, currVal_0); }); }
export function View_SelectModelFieldStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-model-field-stub", [], null, null, null, View_SelectModelFieldStubComponent_0, RenderType_SelectModelFieldStubComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectModelFieldStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectModelFieldStubComponentNgFactory = i0.ɵccf("app-select-model-field-stub", i2.SelectModelFieldStubComponent, View_SelectModelFieldStubComponent_Host_0, {}, {}, []);
export { SelectModelFieldStubComponentNgFactory as SelectModelFieldStubComponentNgFactory };

export enum BlendingMode {
  Normal = 'normal',
  Darken = 'darken',
  Multiply = 'multiply',
  ColorBurn = 'color-burn',

  Lighten = 'lighten',
  Screen = 'screen',
  ColorDodge = 'color-dodge',

  Overlay = 'overlay',
  SoftLight = 'soft-light',
  HardLight = 'hard-light',

  Difference = 'difference',
  Exclusion = 'exclusion',

  Hue = 'hue',
  Saturation = 'saturation',
  Color = 'color',
  Luminosity = 'luminosity'
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../sidebar/components/sidebar-header/sidebar-header.component.ngfactory";
import * as i2 from "../../../sidebar/components/sidebar-header/sidebar-header.component";
import * as i3 from "./customize-bar-header.component";
import * as i4 from "../../services/customize-bar-context/customize-bar.context";
var styles_CustomizeBarHeaderComponent = [];
var RenderType_CustomizeBarHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarHeaderComponent, data: {} });
export { RenderType_CustomizeBarHeaderComponent as RenderType_CustomizeBarHeaderComponent };
export function View_CustomizeBarHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-sidebar-header", [], null, [[null, "back"], [null, "titleChanged"], [null, "deleted"], [null, "parentClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } if (("titleChanged" === en)) {
        var pd_1 = (_co.titleChanged.next($event) !== false);
        ad = (pd_1 && ad);
    } if (("deleted" === en)) {
        var pd_2 = (_co.deleteClick.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("parentClick" === en)) {
        var pd_3 = (_co.parentClick.emit($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_SidebarHeaderComponent_0, i1.RenderType_SidebarHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.SidebarHeaderComponent, [], { title: [0, "title"], titleEnabled: [1, "titleEnabled"], titleEditable: [2, "titleEditable"], titleCleanValue: [3, "titleCleanValue"], subtitle: [4, "subtitle"], backEnabled: [5, "backEnabled"], backLabel: [6, "backLabel"], deleteEnabled: [7, "deleteEnabled"], deleteTip: [8, "deleteTip"], parentLabel: [9, "parentLabel"] }, { back: "back", titleChanged: "titleChanged", deleted: "deleted", parentClick: "parentClick" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, null, null, null, null, null, null, null)), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.titleEnabled; var currVal_2 = _co.titleEditable; var currVal_3 = _co.titleCleanValue; var currVal_4 = _co.subtitle; var currVal_5 = _co.backEnabled; var currVal_6 = _co.backLabel; var currVal_7 = _co.deleteEnabled; var currVal_8 = _co.deleteTip; var currVal_9 = _co.parentLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_CustomizeBarHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-header", [], null, null, null, View_CustomizeBarHeaderComponent_0, RenderType_CustomizeBarHeaderComponent)), i0.ɵdid(1, 180224, null, 0, i3.CustomizeBarHeaderComponent, [[2, i4.CustomizeBarContext]], null, null)], null, null); }
var CustomizeBarHeaderComponentNgFactory = i0.ɵccf("app-customize-bar-header", i3.CustomizeBarHeaderComponent, View_CustomizeBarHeaderComponent_Host_0, { title: "title", titleEnabled: "titleEnabled", titleEditable: "titleEditable", titleCleanValue: "titleCleanValue", backEnabled: "backEnabled", backLabel: "backLabel", backPopSettingsComponent: "backPopSettingsComponent", subtitle: "subtitle", deleteEnabled: "deleteEnabled", deleteTip: "deleteTip", parentLabel: "parentLabel" }, { titleChanged: "titleChanged", deleteClick: "deleteClick", parentClick: "parentClick", backClick: "backClick" }, ["ng-container[actions]"]);
export { CustomizeBarHeaderComponentNgFactory as CustomizeBarHeaderComponentNgFactory };

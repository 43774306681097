import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';

@Injectable({
  providedIn: 'root'
})
export class NoOpenPopupsGuard implements CanDeactivate<Component> {
  constructor(private popupService: PopupService, private dialogService: DialogService) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const closePopups = this.popupService.items.filter(item => item.popupCloseOnDeactivate && !item.closeRequested);

    if (!closePopups.length) {
      return true;
    }

    return this.dialogService
      .confirm({
        title: 'Close',
        description: 'Are you sure want to close?',
        style: closePopups[0].popupComponentOrange ? 'orange' : undefined
      })
      .pipe(
        map(confirmed => {
          if (confirmed) {
            closePopups.filter(item => item.popupRequestClose).forEach(item => item.popupRequestClose.next());
          }

          return false;
        })
      );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i2 from "../../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i3 from "../json-field-structure/json-field-structure.component.ngfactory";
import * as i4 from "../json-field-structure/json-field-structure.component";
import * as i5 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i6 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i7 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i8 from "../../../../fields/components/auto-field/auto-field.component";
import * as i9 from "@angular/common";
import * as i10 from "./json-field-view-params.form";
import * as i11 from "@angular/forms";
import * as i12 from "./json-field-view-params.component";
var styles_JsonFieldViewParamsComponent = [];
var RenderType_JsonFieldViewParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JsonFieldViewParamsComponent, data: {} });
export { RenderType_JsonFieldViewParamsComponent as RenderType_JsonFieldViewParamsComponent };
function View_JsonFieldViewParamsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-sidebar-section", [], null, null, null, i1.View_SidebarSectionComponent_0, i1.RenderType_SidebarSectionComponent)), i0.ɵdid(1, 114688, null, 0, i2.SidebarSectionComponent, [], { title: [0, "title"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "sidebar-section__header-links"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "a", [["class", "sidebar__link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.autoDetectStructure() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "icon-repeat"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Reset "])), (_l()(), i0.ɵeld(7, 0, null, 3, 2, "div", [["class", "sidebar__element sidebar__element_overflow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-json-field-structure", [], null, null, null, i3.View_JsonFieldStructureComponent_0, i3.RenderType_JsonFieldStructureComponent)), i0.ɵdid(9, 245760, null, 0, i4.JsonFieldStructureComponent, [i0.ChangeDetectorRef], { control: [0, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Fields Structure"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.form.form.controls["structure"]; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_JsonFieldViewParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-sidebar-field", [], null, null, null, i5.View_SidebarFieldComponent_0, i5.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i6.SidebarFieldComponent, [], { label: [0, "label"], layoutLeft: [1, "layoutLeft"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 3, "app-auto-field", [], null, null, null, i7.View_AutoFieldComponent_0, i7.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i8.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpod(5, { style: 0, orange: 1 }), i0.ɵpod(6, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_JsonFieldViewParamsComponent_1)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "display as fields"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.form.form; var currVal_3 = _co.createField(_ck(_v, 6, 0, "display_fields", "BooleanField", _ck(_v, 5, 0, _co.booleanFieldStyle.Toggle, true))); var currVal_4 = false; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.form.form.value["display_fields"]; _ck(_v, 8, 0, currVal_5); }, null); }
export function View_JsonFieldViewParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-json-field-view-params", [], null, null, null, View_JsonFieldViewParamsComponent_0, RenderType_JsonFieldViewParamsComponent)), i0.ɵprd(512, null, i10.JsonFieldViewParamsForm, i10.JsonFieldViewParamsForm, [i11.FormBuilder]), i0.ɵdid(2, 245760, null, 0, i12.JsonFieldViewParamsComponent, [i10.JsonFieldViewParamsForm], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var JsonFieldViewParamsComponentNgFactory = i0.ɵccf("app-json-field-view-params", i12.JsonFieldViewParamsComponent, View_JsonFieldViewParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { JsonFieldViewParamsComponentNgFactory as JsonFieldViewParamsComponentNgFactory };

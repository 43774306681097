import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { createFormFieldFactory } from '@modules/fields';
import { MenuService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ChangeCustomViewForm } from './change-custom-view.form';
var ChangeCustomViewComponent = /** @class */ (function () {
    function ChangeCustomViewComponent(currentProjectStore, currentEnvironmentStore, routing, activatedRoute, customViewService, customViewsStore, dialogService, form, notificationService, menuService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.routing = routing;
        this.activatedRoute = activatedRoute;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.dialogService = dialogService;
        this.form = form;
        this.notificationService = notificationService;
        this.menuService = menuService;
        this.cd = cd;
        this.loading = false;
        this.customViewTypes = CustomViewType;
        this.createField = createFormFieldFactory();
    }
    ChangeCustomViewComponent.prototype.ngOnInit = function () { };
    ChangeCustomViewComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomViewComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.activatedRoute.params
            .pipe(filter(function (params) { return params['unique_name'] != undefined; }), switchMap(function (params) {
            _this.loading = true;
            _this.cd.markForCheck();
            return _this.customViewsStore.getDetail(params['unique_name'], true);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.customView = result;
            _this.form.init(_this.customView);
            _this.updateDistPreview();
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    Object.defineProperty(ChangeCustomViewComponent.prototype, "distFilename", {
        get: function () {
            if (!this.distPreview) {
                return;
            }
            return this.distPreview.split('/').pop();
        },
        enumerable: true,
        configurable: true
    });
    ChangeCustomViewComponent.prototype.updateDistPreview = function () {
        if (this.form.form.value['dist'] instanceof File) {
            this.distPreview = this.form.form.value['dist'].name;
        }
        else {
            this.distPreview = this.form.form.value['dist'];
        }
        this.cd.markForCheck();
    };
    ChangeCustomViewComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.form.form.patchValue({ dist: file, params: {} });
        this.form.form.markAsDirty();
        this.updateDistPreview();
    };
    ChangeCustomViewComponent.prototype.requestDelete = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: 'Are you sure want to delete this FlexView?'
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.customViewService
                .delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, _this.customView)
                .pipe(untilDestroyed(_this))
                .subscribe(function () {
                _this.notificationService.success('Deleted', 'FlexView was successfully deleted');
                _this.customViewsStore.get(true);
                _this.routing.navigateApp(_this.currentProjectStore.instance.settingsCustomViewsLink);
            });
        });
    };
    ChangeCustomViewComponent.prototype.submit = function () {
        var _this = this;
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (_this.customView) {
                _this.notificationService.success('Saved', 'FlexView was successfully updated');
            }
            else {
                _this.notificationService.success('Created', 'FlexView was successfully added');
            }
            _this.routing.navigateApp(result.changeLink);
        }, function () {
            _this.notificationService.error('Error', 'Saving FlexView failed');
            _this.cd.markForCheck();
        });
    };
    return ChangeCustomViewComponent;
}());
export { ChangeCustomViewComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { AdminMode } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';
import { ViewSettingsType } from '../data/view-settings/base';
import { ViewSettingsService } from '../services/view-settings/view-settings.service';
var ViewSettingsStore = /** @class */ (function (_super) {
    __extends(ViewSettingsStore, _super);
    function ViewSettingsStore(mode$, viewSettingsService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.viewSettingsService = viewSettingsService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    ViewSettingsStore.prototype.fetch = function () {
        var _this = this;
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.viewSettingsService.get(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, mode == AdminMode.Builder);
        }));
    };
    ViewSettingsStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ViewSettingsStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ViewSettingsStore.prototype.getDetailFirst = function (uniqueName, view, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.uniqueName == uniqueName && (!view || item.view == view); });
        }));
    };
    ViewSettingsStore.prototype.getDetailByUid = function (uid, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.uid == uid; });
        }));
    };
    ViewSettingsStore.prototype.getDetailByUidFirst = function (uid, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.uid == uid; });
        }));
    };
    ViewSettingsStore.prototype.getChangeViewSettingsFirst = function (modelId, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.view == ViewSettingsType.Change && item.isSame(modelId); });
        }));
    };
    ViewSettingsStore.prototype.getListViewSettingsFirst = function (modelId, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.view == ViewSettingsType.List && item.isSame(modelId); });
        }));
    };
    ViewSettingsStore.prototype.getDistinctUniqueName = function (baseUniqueName, instance, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (viewSettings) {
            var otherViewSettings = viewSettings.filter(function (item) { return !instance || item.uid != instance.uid; });
            var uniqueName;
            var index = 1;
            var match = baseUniqueName.match(/\d+$/);
            if (match) {
                baseUniqueName = baseUniqueName.slice(0, match.index);
                index = parseInt(match[0], 10);
            }
            do {
                uniqueName = index > 1 ? [baseUniqueName, index].join('') : baseUniqueName;
                ++index;
            } while (otherViewSettings.find(function (item) { return item.uniqueName == uniqueName; }));
            return uniqueName;
        }));
    };
    ViewSettingsStore.prototype.getDistinctUniqueNameForName = function (name, instance, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        var baseUniqueName = slugify(name, { trim: true }).replace(/-+/g, '-');
        return this.getDistinctUniqueName(baseUniqueName, instance, forceUpdate, options);
    };
    ViewSettingsStore.prototype.getDistinctName = function (baseName, instance, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (viewSettings) {
            var otherViewSettings = viewSettings.filter(function (item) { return !instance || item.uid != instance.uid; });
            var name;
            var index = 1;
            do {
                name = index > 1 ? baseName + " " + index : baseName;
                ++index;
            } while (otherViewSettings.find(function (item) { return item.name == name; }));
            return name;
        }));
    };
    ViewSettingsStore.prototype.addItem = function (viewSettings) {
        this.instance = this.instance.concat([viewSettings]);
    };
    ViewSettingsStore.prototype.updateItem = function (viewSettings) {
        var index = this.instance.findIndex(function (item) { return item.uid == viewSettings.uid; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([viewSettings], this.instance.slice(index + 1));
        }
    };
    return ViewSettingsStore;
}(SingletonStore));
export { ViewSettingsStore };

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CustomViewSettings, ViewContext } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { controlValue } from '@shared';
import { CustomizeBarActionEditForm, OPENED_MODAL_VALUE } from '../../customize-bar-action-edit.form';
var CustomizeBarActionEditTypeClosePopupComponent = /** @class */ (function () {
    function CustomizeBarActionEditTypeClosePopupComponent(cd) {
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.popupOptions = [];
    }
    CustomizeBarActionEditTypeClosePopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updatePopupOptions();
        controlValue(this.form.controls.close_popup)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.selectedPopupOption = _this.popupOptions.filter(function (item) { return item.value == value; })[0];
            _this.cd.markForCheck();
        });
    };
    CustomizeBarActionEditTypeClosePopupComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditTypeClosePopupComponent.prototype.updatePopupOptions = function () {
        if (this.context.viewSettings && this.context.viewSettings instanceof CustomViewSettings) {
            this.popupOptions = [
                {
                    value: OPENED_MODAL_VALUE,
                    name: 'Any opened Modal',
                    icon: 'deselect'
                }
            ].concat(this.context.viewSettings.popups.map(function (item) {
                return {
                    value: item.uid,
                    name: item.name,
                    icon: item.getIcon()
                };
            }));
        }
    };
    return CustomizeBarActionEditTypeClosePopupComponent;
}());
export { CustomizeBarActionEditTypeClosePopupComponent };

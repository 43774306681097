import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, tap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { FieldType } from '@modules/fields';
import { airtableFilterItemFormula, filterItemsFromQueryParams } from '@modules/filter-utils';
import {
  CURSOR_NEXT_PARAM,
  Model,
  ModelDescription,
  ORDER_BY_PARAM,
  PER_PAGE_PARAM,
  SEARCH_PARAM
} from '@modules/models';
import { Resource } from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, QueryType } from '@modules/queries';
import { HttpQueryOptions } from '@modules/queries/services/http-query/http-query.service';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { ResourceController } from '../../data/resource-controller';
import {
  AIRTABLE_CREATED_TIME,
  AIRTABLE_PRIMARY_KEY,
  AIRTABLE_TABLE_VIEW_ID_PARAM,
  SOURCE_FIELD_TYPE
} from './airtable-constants';
import { AirtableFieldType, airtableReadOnlyFieldTypes } from './airtable-field-type';
import {
  AirtableBasesResponse,
  AirtableCreateResponse,
  AirtableDeleteResponse,
  AirtableGetResponse,
  AirtableRecordResponse,
  AirtableTablesResponse,
  AirtableUpdateResponse
} from './airtable-responses';

@Injectable()
export class AirtableResourceController extends ResourceController {
  private apiService: ApiService;
  private httpQueryService: HttpQueryService;
  private http: HttpClient;

  tokenName = 'oauth_access_token';
  tokenNamePersonalAccessToken = 'personal_access_token';
  tokenNameLegacy = 'api_key';

  init() {
    this.apiService = this.initService<ApiService>(ApiService);
    this.httpQueryService = this.initService<HttpQueryService>(HttpQueryService);
    this.http = this.initService<HttpClient>(HttpClient);
  }

  supportedQueryTypes(queryClass: any): QueryType[] {
    return [QueryType.Simple];
  }

  getBases(options: { accessToken?: string; key?: string }): Observable<AirtableBasesResponse> {
    const url = this.apiService.methodURL('airtable/bases/');
    const data = {
      ...(isSet(options.accessToken) && { access_token: options.accessToken }),
      ...(isSet(options.key) && { api_key: options.key })
    };

    return this.http.post(url, data).pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getBaseTables(options: { base: string; accessToken?: string; key?: string }): Observable<AirtableTablesResponse> {
    const url = this.apiService.methodURL(`airtable/bases/${options.base}/tables/`);
    const data = {
      ...(isSet(options.accessToken) && { access_token: options.accessToken }),
      ...(isSet(options.key) && { api_key: options.key })
    };

    return this.http.post(url, data).pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getBaseTableRecords(options: {
    base: string;
    table: string;
    accessToken?: string;
    key?: string;
    viewId?: string;
    formula?: string;
  }): Observable<AirtableGetResponse> {
    const query = new HttpQuery();
    const key = isSet(options.accessToken) ? options.accessToken : options.key;

    query.url = `https://api.airtable.com/v0/${options.base}/${options.table}`;
    query.headers = [{ name: 'Authorization', value: `Bearer ${key}` }];
    query.queryParams = [];

    if (options.viewId) {
      query.queryParams.push({
        name: 'view',
        value: options.viewId
      });
    }

    if (options.formula) {
      query.queryParams.push({
        name: 'filterByFormula',
        value: options.formula
      });
    }

    return this.httpQueryService
      .requestBody<AirtableGetResponse>(query)
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  deserializeModel(record: AirtableRecordResponse, modelDescription: ModelDescription): Object {
    const fields = toPairs(record.fields).map(([name, value]) => {
      const field = modelDescription.dbFields.find(i => i.name == name);
      const sourceType: AirtableFieldType = field ? field.params[SOURCE_FIELD_TYPE] : undefined;

      if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
        value = isArray(value) && value.length ? value.map(item => item.url) : [];
      } else if (sourceType && sourceType == AirtableFieldType.MultipleLookupValues) {
        value = isArray(value) && value.length ? value[0] : undefined;
      }

      return [name, value];
    });

    const missingFields = modelDescription.dbFields
      .filter(item => !fields.find(([k]) => k == item.name))
      .map(item => [item.name, null]);

    return {
      ...fromPairs(fields),
      ...fromPairs(missingFields),
      [AIRTABLE_PRIMARY_KEY]: record.id,
      [AIRTABLE_CREATED_TIME]: record.createdTime
    };
  }

  serializeModel(instance: Model, modelDescription: ModelDescription, fields?: string[]): AirtableRecordResponse {
    const data = toPairs(instance.serialize(fields))
      .filter(([k, v]) => ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(k))
      .filter(([k, v]) => {
        const field = modelDescription.dbFields.find(i => i.name == k);
        return field && field.editable;
      })
      .map(([name, value]) => {
        const field = modelDescription.dbFields.find(i => i.name == name);
        const sourceType: AirtableFieldType = field ? field.params[SOURCE_FIELD_TYPE] : undefined;

        if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
          if (isSet(value)) {
            value = isArray(value)
              ? value.filter(item => item !== null).map(item => ({ url: item }))
              : [{ url: value }];
          } else {
            value = [];
          }
        } else if (sourceType && sourceType == AirtableFieldType.MultipleSelects) {
          value = isArray(value) ? value : [value];
          value = value.filter(item => item !== null);
        } else if (sourceType && sourceType == AirtableFieldType.MultipleRecordLinks) {
          value = isArray(value) ? value : [value];
          value = value.filter(item => item !== null);
        }

        return [name, value];
      });

    return {
      ...(isSet(instance.initialPrimaryKey) && { id: instance.initialPrimaryKey }),
      fields: fromPairs(data)
    };
  }

  getTokenName(resource: Resource): string {
    if (resource.secretTokens.find(item => item.name == this.tokenName)) {
      return this.tokenName;
    } else if (resource.secretTokens.find(item => item.name == this.tokenNamePersonalAccessToken)) {
      return this.tokenNamePersonalAccessToken;
    } else {
      return this.tokenNameLegacy;
    }
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const viewId: string = modelDescription.params[AIRTABLE_TABLE_VIEW_ID_PARAM];
    const search: string = params[SEARCH_PARAM];
    const cursorNext = params[CURSOR_NEXT_PARAM];
    const perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : undefined;
    let orderField: string = params[ORDER_BY_PARAM];
    let orderAscending = true;

    if (isSet(orderField) && orderField.startsWith('-')) {
      orderField = orderField.substring(1);
      orderAscending = false;
    }

    const [base, table] = modelDescription.model.split('_');

    query.url = `https://api.airtable.com/v0/${base}/${table}`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` }];
    query.queryParams = [];

    if (perPage) {
      query.queryParams.push({ name: 'pageSize', value: String(perPage) });
    }

    if (viewId) {
      query.queryParams.push({ name: 'view', value: viewId });
    }

    const predicates: string[] = [];

    if (isSet(search)) {
      let searchPredicates = modelDescription.dbFields
        .filter(item =>
          [FieldType.Text, FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton, FieldType.URL].includes(
            item.field
          )
        )
        .filter(item => ![AIRTABLE_CREATED_TIME].includes(item.name))
        .filter(item => !airtableReadOnlyFieldTypes.includes(item.params[SOURCE_FIELD_TYPE]))
        .map(item => {
          const fieldValue = item.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${item.name}}`;
          return `SEARCH("${search.toLowerCase()}", LOWER(${fieldValue}))`;
        });
      let totalLength = 0;

      searchPredicates = searchPredicates.reduce((acc, item) => {
        if (totalLength + item.length <= 1024) {
          acc.push(item);
          totalLength += item.length;
        }

        return acc;
      }, []);

      predicates.push(`OR(${searchPredicates.join(',')})`);
    }

    if (isSet(cursorNext)) {
      query.queryParams.push({ name: 'offset', value: cursorNext });
    }

    if (isSet(orderField)) {
      query.queryParams.push({ name: 'sort[0][field]', value: orderField });

      if (!orderAscending) {
        query.queryParams.push({ name: 'sort[0][direction]', value: 'desc' });
      }
    }

    const filterItems = filterItemsFromQueryParams(params);

    filterItems.forEach(filterItem => {
      const field = modelDescription.dbFields.find(i => i.name == filterItem.field);

      if (!field) {
        return;
      }

      const formula = airtableFilterItemFormula(field, filterItem);

      if (!formula) {
        return;
      }

      predicates.push(formula);
    });

    if (predicates.length > 1) {
      query.queryParams.push({ name: 'filterByFormula', value: `AND(${predicates.join(',')})` });
    } else if (predicates.length == 1) {
      query.queryParams.push({ name: 'filterByFormula', value: predicates[0] });
    }

    return this.httpQueryService.requestBody<AirtableGetResponse>(query, options).pipe(
      map(result => {
        const data = {
          results: result.records.map(item => this.deserializeModel(item, modelDescription)),
          has_more: !!result.offset,
          cursor_next: result.offset,
          per_page: params[PER_PAGE_PARAM]
        };

        return this.createGetResponse().deserialize(data, modelDescription.model, undefined);
      }),
      tap(response => {
        response.results.forEach(item => {
          item.deserializeAttributes(modelDescription.dbFields);
          item.setUp(modelDescription);
        });

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    params = params || {};

    if (isSet(id)) {
      params = { ...params, [idField]: id };
    }

    return this.modelGet(resource, modelDescription, params).pipe(map(result => result.results[0]));
    //   const options: HttpQueryOptions = { resource: resource.uniqueName };
    //   const query = new HttpQuery();
    //   const [base, table] = modelDescription.model.split('_');
    //
    //   query.method = HttpMethod.GET;
    //   query.url = `https://api.airtable.com/v0/${base}/${table}/${id}`;
    //   query.headers = [{ name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` }];
    //
    //   return this.httpQueryService.requestBody<AirtableRecordResponse>(query, options).pipe(
    //     map(result => {
    //       const instance = this.createModel().deserialize(modelDescription.model, {
    //         ...result.fields,
    //         [AIRTABLE_PRIMARY_KEY]: result.id,
    //         [AIRTABLE_CREATED_TIME]: result.createdTime
    //       });
    //       const instance = this.createModel().deserialize(modelDescription.model, this.deserializeModel(result, modelDescription));
    //       instance.setUp(modelDescription);
    //       instance.deserializeAttributes(modelDescription.dbFields);
    //       return instance;
    //     }),
    //     this.apiService.catchApiError(),
    //     publishLast(),
    //     refCount()
    //   );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const [base, table] = modelDescription.model.split('_');

    query.method = HttpMethod.POST;
    query.url = `https://api.airtable.com/v0/${base}/${table}`;
    query.headers = [
      { name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` },
      { name: 'Content-Type', value: 'application/json' }
    ];
    query.body = {
      records: [this.serializeModel(modelInstance, modelDescription, fields)]
    };

    return this.httpQueryService.requestBody<AirtableCreateResponse>(query, options).pipe(
      map(result => {
        const data = result.records.map(item => this.deserializeModel(item, modelDescription))[0];
        const instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const [base, table] = modelDescription.model.split('_');

    query.method = HttpMethod.PATCH;
    query.url = `https://api.airtable.com/v0/${base}/${table}`;
    query.headers = [
      { name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` },
      { name: 'Content-Type', value: 'application/json' }
    ];
    query.body = {
      records: [this.serializeModel(modelInstance, modelDescription, fields)]
    };

    return this.httpQueryService.requestBody<AirtableUpdateResponse>(query, options).pipe(
      map(result => {
        const data = result.records.map(item => this.deserializeModel(item, modelDescription))[0];
        const instance = this.createModel().deserialize(modelDescription.model, data);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const [base, table] = modelDescription.model.split('_');

    query.method = HttpMethod.DELETE;
    query.url = `https://api.airtable.com/v0/${base}/${table}/${modelInstance.initialPrimaryKey}`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` }];

    return this.httpQueryService
      .requestBody<AirtableDeleteResponse>(query, options)
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }
}

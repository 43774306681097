import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { cleanWorkflowStepName, WorkflowStep } from '@modules/workflow';

import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';

@Component({
  selector: 'app-customize-workflow-step-header',
  templateUrl: './customize-workflow-step-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeWorkflowStepHeaderComponent implements OnInit {
  @Input() step: WorkflowStep;
  @Input() nameControl: FormControl;
  @Input() nameValue: string;
  @Input() deleteEnabled = false;
  @Input() executeEnabled = false;
  @Input() executeLoading = false;
  @Output() close = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() execute = new EventEmitter<void>();

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanWorkflowStepName(value, this.step, this.workflowEditContext.state.workflow.steps);
    };
  })();

  constructor(private workflowEditContext: WorkflowEditContext) {}

  ngOnInit() {}
}

import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { ConstantNode, IndexNode } from 'mathjs';

import { ElementType, ViewContext, ViewContextToken } from '@modules/customize';
import { mathjs } from '@modules/fields';
import { cleanWrapSpaces, isSet } from '@shared';

export interface FormulaUsages {
  elements?: string[];
  elementProperties?: string[];
  user?: string[];
  userProperties?: boolean;
  teamProperties?: boolean;
  pageParameters?: boolean;
  functions?: string[];
}

export function getFormulaUsages(value: string, context?: ViewContext) {
  const result: FormulaUsages = {
    userProperties: false,
    teamProperties: false,
    pageParameters: false
  };
  // const elementToken = contextTokens ? contextTokens.find(item => item.uniqueName == 'elements') : undefined;
  // const elementTokens = elementToken ? elementToken.children : [];

  const prevNodes = [];
  const usedElements = {};
  const usedElementProperties = {};
  const usedUser = {};
  const usedFunctions = {};

  mathjs.parse(cleanWrapSpaces(value)).transform(node => {
    if (node.isSymbolNode) {
      if (node.name == 'user_properties') {
        result.userProperties = true;
      } else if (node.name == 'team_properties') {
        result.teamProperties = true;
      } else if (node.name == 'page') {
        result.pageParameters = true;
      }
    } else if (node.isAccessorNode && node.object && node.object.isSymbolNode) {
      if (node.object.name == 'user') {
        usedUser[node.name] = true;
      } else if (node.object.name == 'elements') {
        const contextElement = context
          ? context.elements.find(contextItem => {
              if (contextItem.parent) {
                return false;
              }

              return contextItem.element.uniqueName == node.name;
            })
          : undefined;
        const element = contextElement ? contextElement.element.element : undefined;

        if (element) {
          const key = element.analyticsName;
          usedElements[key] = true;

          if (element.type == ElementType.List) {
            const propertyNode = prevNodes[prevNodes.length - 2];
            if (
              propertyNode &&
              propertyNode.isAccessorNode &&
              propertyNode.object &&
              propertyNode.object.isAccessorNode &&
              propertyNode.object.object === node
            ) {
              usedElementProperties[`${key}_${propertyNode.name}`] = true;
            }
          } else {
            const propertyNode = prevNodes[prevNodes.length - 1];
            if (propertyNode && propertyNode.isAccessorNode && propertyNode.object === node) {
              usedElementProperties[`${key}_${propertyNode.name}`] = true;
            }
          }
        }
      }
    } else if (node.isFunctionNode) {
      usedFunctions[node.name.toUpperCase()] = true;
    }

    prevNodes.push(node);
    return node;
  });

  return {
    ...result,
    elements: keys(usedElements),
    elementProperties: keys(usedElementProperties),
    user: keys(usedElements),
    functions: keys(usedFunctions)
  };
}

export function transformFormulaAccessors(
  formulaValue: string,
  contextTokens: ViewContextToken[],
  toHuman = true
): string {
  const elementToken = contextTokens.find(item => item.uniqueName == 'elements');
  const elementTokens = elementToken ? elementToken.children : [];

  try {
    const math = mathjs.parse(cleanWrapSpaces(formulaValue));
    const rootNode = math.transform(node => {
      if (node.isAccessorNode) {
        let item = node;

        while (item) {
          if (item.object.isAccessorNode) {
            item = item.object;
          } else {
            if (item.object.isSymbolNode && item.object.name == 'elements') {
              const token = elementTokens.find(i => {
                return toHuman ? i.uniqueName == item.name : i.name == item.name;
              });

              if (token) {
                const newIndex = toHuman ? token.name : token.uniqueName;

                if (isSet(newIndex)) {
                  item.index = new IndexNode([new ConstantNode(newIndex)], isFormulaAccessorItemDotNotation(newIndex));
                }
              }
            }

            break;
          }
        }
      }

      return node;
    });

    if (rootNode) {
      if (rootNode.isConstantNode && rootNode.value == undefined) {
        return formulaValue;
      } else {
        return rootNode.toString();
      }
    } else {
      return formulaValue;
    }
  } catch (e) {
    return formulaValue;
  }
}

export function transformFormulaElementAccessors(formulaValue: string, context: ViewContext, toHuman = true): string {
  try {
    const math = mathjs.parse(cleanWrapSpaces(formulaValue));
    const rootNode = math.transform(node => {
      if (node.isAccessorNode) {
        let item = node;

        while (item) {
          if (item.object.isAccessorNode) {
            item = item.object;
          } else {
            if (item.object.isSymbolNode && item.object.name == 'elements') {
              const contextElement = context.elements.find(contextItem => {
                if (contextItem.parent) {
                  return false;
                }

                return toHuman ? contextItem.element.uniqueName == item.name : contextItem.element.name == item.name;
              });

              if (contextElement) {
                const newIndex = toHuman ? contextElement.element.name : contextElement.element.uniqueName;

                if (isSet(newIndex)) {
                  item.index = new IndexNode([new ConstantNode(newIndex)], isFormulaAccessorItemDotNotation(newIndex));
                }
              }
            }

            break;
          }
        }
      }

      return node;
    });

    if (rootNode) {
      if (rootNode.isConstantNode && rootNode.value == undefined) {
        return formulaValue;
      } else {
        return rootNode.toString();
      }
    } else {
      return formulaValue;
    }
  } catch (e) {
    return formulaValue;
  }
}

export function isFormulaAccessorItemDotNotation(accessorItem: string): boolean {
  const startsWithLetter = !!accessorItem.match(/^[A-z](\w+)?$/);
  const isExponent = !!accessorItem.match(/^[eE]\d/);
  const systemNames = ['mod', 'to', 'in', 'and', 'xor', 'or', 'not'];
  return startsWithLetter && !isExponent && !systemNames.includes(accessorItem);
}

// export function addFormulaAccessors(token: string): string {
//   return token.split('.').reduce((acc, item, i) => {
//     if (i == 0) {
//       return item;
//     } else if (isFormulaAccessorItemDotNotation(item)) {
//       return `${acc}.${item}`;
//     } else {
//       return `${acc}["${item.replace(/\n/g, '\\n')}"]`;
//     }
//   }, '');
// }
//
// export function removeFormulaAccessors(token: string): string {
//   return token.replace(/\["([^"]*)"]/g, '.$1');
// }

export function contextToFormulaValue(contextValue: (string | number)[]): string {
  if (!isArray(contextValue)) {
    return contextValue;
  }

  return contextValue.reduce<string>((acc, item, i) => {
    if (i == 0) {
      return String(item);
    } else if (isFormulaAccessorItemDotNotation(String(item))) {
      return `${acc}.${item}`;
    } else {
      if (typeof item === 'string') {
        item = item.replace(/\n/g, '\\n');
      }

      if (typeof item == 'number') {
        return `${acc}[${item}]`;
      } else {
        return `${acc}["${item}"]`;
      }
    }
  }, '');
}

export function singleTokenFormulaToContextValue(formulaValue: string): (string | number)[] {
  if (typeof formulaValue !== 'string') {
    return formulaValue;
  }

  try {
    const result = [];
    let node = mathjs.parse(cleanWrapSpaces(formulaValue));

    while (node) {
      if (node && node.isAccessorNode) {
        const name = node.index.dimensions[0].value;
        result.push(name);
        node = node.object;
      } else if (node && node.isSymbolNode) {
        result.push(node.name);
        break;
      } else {
        return;
      }
    }

    return result.reverse();
  } catch (e) {
    return;
  }
}

import { capitalize, getNavigatorLanguages } from '@shared';
import { DEFAULT_LANGUAGE, locales } from '../data/locales';
import { getCurrentLanguage } from './localize';
export function getLanguages() {
    var navigatorLanguage = getNavigatorLanguages();
    var language = getCurrentLanguage();
    return [
        { value: DEFAULT_LANGUAGE, name: 'English' }
    ].concat(locales
        .filter(function (item) {
        if (item.hidden) {
            return navigatorLanguage.includes(item.language) || language == item.language;
        }
        else {
            return true;
        }
    })
        .map(function (item) {
        return { value: item.language, name: capitalize(item.name) };
    }));
}

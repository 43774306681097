import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var EmailAddressService = /** @class */ (function () {
    function EmailAddressService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    EmailAddressService.prototype.initiateCustomEmail = function (projectName, environmentName, domain) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "domains/" + domain + "/initiate_custom_email");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return result.cnames; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailAddressService.prototype.setupCustomEmail = function (projectName, environmentName, domain, emailFrom) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "domains/" + domain + "/setup_custom_email");
            var headers = new HttpHeaders();
            var data = {
                email_from: emailFrom
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailAddressService.prototype.deleteCustomEmail = function (projectName, environmentName, domain) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "domains/" + domain + "/delete_custom_email");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailAddressService.ngInjectableDef = i0.defineInjectable({ factory: function EmailAddressService_Factory() { return new EmailAddressService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: EmailAddressService, providedIn: "root" });
    return EmailAddressService;
}());
export { EmailAddressService };

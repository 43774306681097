var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as JSZip from 'jszip';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { CustomViewFileType, CustomViewService } from '@modules/custom-views';
var ChangeCustomViewFilesComponent = /** @class */ (function () {
    function ChangeCustomViewFilesComponent(customViewService, cd) {
        this.customViewService = customViewService;
        this.cd = cd;
    }
    ChangeCustomViewFilesComponent.prototype.ngOnInit = function () { };
    ChangeCustomViewFilesComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomViewFilesComponent.prototype.ngOnChanges = function (changes) { };
    ChangeCustomViewFilesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.distControl.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(function () { return _this.updateFiles(); });
        if (this.distFilesControl) {
            this.distFilesControl.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(function () { return _this.updateFiles(); });
        }
        this.updateFiles();
    };
    ChangeCustomViewFilesComponent.prototype.updateFiles = function () {
        var _this = this;
        var zip = new JSZip();
        var value = this.filesControl.value;
        var valueJs = value['js'] || [];
        var valueCss = value['css'] || [];
        var obs;
        if (this.distFilesControl && this.distFilesControl.value) {
            obs = of(this.distFilesControl.value);
        }
        else if (this.distControl.value instanceof File) {
            obs = of(this.distControl.value);
        }
        else if (typeof this.distControl.value == 'string') {
            obs = this.customViewService.downloadDist(this.distControl.value);
        }
        else {
            this.files = undefined;
            this.cd.markForCheck();
            return;
        }
        obs
            .pipe(switchMap(function (file) { return from(zip.loadAsync(file)); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.files = values(result.files)
                .filter(function (item) { return !item.dir; })
                .map(function (item) {
                var extension = item.name.split('.').pop().toLowerCase();
                var fileType = CustomViewFileType.Unknown;
                var checked = false;
                if (extension == 'js') {
                    fileType = CustomViewFileType.JS;
                    checked = valueJs.includes(item.name);
                }
                else if (extension == 'css') {
                    fileType = CustomViewFileType.CSS;
                    checked = valueCss.includes(item.name);
                }
                var disabled = fileType == CustomViewFileType.Unknown;
                return {
                    name: item.name,
                    fileType: fileType,
                    disabled: disabled,
                    checked: checked && !disabled
                };
            })
                .sort(function (lhs, rhs) {
                var lhsIndex = -1;
                var rhsIndex = -1;
                if (lhs.fileType == CustomViewFileType.JS) {
                    lhsIndex = valueJs.indexOf(lhs.name);
                }
                else if (lhs.fileType == CustomViewFileType.CSS) {
                    lhsIndex = valueCss.indexOf(lhs.name);
                }
                if (rhs.fileType == CustomViewFileType.JS) {
                    rhsIndex = valueJs.indexOf(rhs.name);
                }
                else if (rhs.fileType == CustomViewFileType.CSS) {
                    rhsIndex = valueCss.indexOf(rhs.name);
                }
                return (lhs.checked && !rhs.checked) ||
                    (lhs.checked == rhs.checked &&
                        lhs.fileType == CustomViewFileType.JS &&
                        rhs.fileType == CustomViewFileType.CSS) ||
                    (lhs.checked == rhs.checked && lhs.fileType == rhs.fileType && lhsIndex < rhsIndex)
                    ? -1
                    : lhsIndex == rhsIndex && lhs.checked == rhs.checked && lhs.fileType == rhs.fileType
                        ? 0
                        : 1;
            });
            _this.cd.markForCheck();
        }, function (error) {
            _this.files = undefined;
            _this.cd.markForCheck();
            console.error('error', error);
        });
    };
    ChangeCustomViewFilesComponent.prototype.updateParams = function () {
        var js = this.files.filter(function (item) { return item.fileType == CustomViewFileType.JS && item.checked; }).map(function (item) { return item.name; });
        var css = this.files
            .filter(function (item) { return item.fileType == CustomViewFileType.CSS && item.checked; })
            .map(function (item) { return item.name; });
        this.setFiles(js, css);
    };
    ChangeCustomViewFilesComponent.prototype.setFiles = function (js, css) {
        var params = __assign({}, this.filesControl.value, { js: js, css: css });
        this.filesControl.patchValue(params);
        this.filesControl.markAsDirty();
    };
    ChangeCustomViewFilesComponent.prototype.onOrderingUpdated = function (result) {
        this.files = result.map(function (item) { return item.data; });
        this.cd.markForCheck();
        this.updateParams();
    };
    ChangeCustomViewFilesComponent.prototype.setAllSelect = function (select) {
        this.files = this.files.map(function (item) {
            if (!item.disabled) {
                item.checked = select;
            }
            return item;
        });
        this.cd.markForCheck();
        this.updateParams();
    };
    return ChangeCustomViewFilesComponent;
}());
export { ChangeCustomViewFilesComponent };

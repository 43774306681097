import pickBy from 'lodash/pickBy';

export enum EmailTemplateName {
  EmailVerification = 'email_verification',
  UserRestore = 'user_restore',
  ProjectUserInvitation = 'project_user_invitation',
  EmailChange = 'email_change'
}

export interface EmailTemplateInfo {
  name: EmailTemplateName;
  label: string;
  icon: string;
}

export const emailTemplates: EmailTemplateInfo[] = [
  {
    name: EmailTemplateName.EmailVerification,
    label: 'Email verification',
    icon: 'email'
  },
  {
    name: EmailTemplateName.UserRestore,
    label: 'Password restore',
    icon: 'key'
  },
  {
    name: EmailTemplateName.ProjectUserInvitation,
    label: 'User invitation',
    icon: 'human_being'
  },
  {
    name: EmailTemplateName.EmailChange,
    label: 'Email Change',
    icon: 'email'
  }
];

export class EmailTemplate {
  public name: string;
  public subject: string;
  public content: string;
  public htmlContent: string;
  public language = '';
  public defaultTemplate: string;
  public created: string;
  public lastUpdated: string;
  public protected = false;

  deserialize(data: Object): EmailTemplate {
    this.name = data['name'];
    this.subject = data['subject'];
    this.content = data['content'];
    this.htmlContent = data['html_content'];
    this.language = data['language'];
    this.defaultTemplate = data['default_template'];
    this.created = data['created'];
    this.lastUpdated = data['last_updated'];

    if (data.hasOwnProperty('protected')) {
      this.protected = data['protected'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name,
      subject: this.subject,
      content: this.content,
      html_content: this.htmlContent,
      language: this.language,
      default_template: this.defaultTemplate,
      created: this.created,
      last_updated: this.lastUpdated
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get info(): EmailTemplateInfo {
    return emailTemplates.find(item => item.name == this.name);
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import shuffle from 'lodash/shuffle';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { isTokenExpired, serializeTokenOptions } from '@modules/api';
import { AuthService, TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { MetaService } from '@modules/meta';
import { Project, ProjectDeployment, ProjectService, ProjectsStore, resourceTypeItems } from '@modules/projects';
import { ROUTE_LOADED_PROJECTS_STORE } from '@modules/projects-components';
import { RoutingService } from '@modules/routing';
import { TemplateService, TemplateType } from '@modules/template';
import { adminPanelTemplateItem, TemplateItemType, TemplatePreviewComponent, toTemplateItem } from '@modules/template-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isSet, KeyboardEventKeyCode } from '@shared';
import { ProjectCreateController } from '../../services/project-create/project-create.controller';
import { PROJECT_CREATE_TEMPLATE_ITEM } from '../project-create-popup/project-create-popup.component';
function filterItems(items, lookups, search) {
    if (!isSet(items)) {
        return;
    }
    if (!isSet(search)) {
        return items;
    }
    return items.filter(function (item) {
        return lookups(item).some(function (str) { return String(str).toLowerCase().indexOf(search) != -1; });
    });
}
var ProjectsComponent = /** @class */ (function () {
    function ProjectsComponent(projectsStore, projectService, projectCreateController, currentUserStore, routing, metaService, cd, activatedRoute, authService, templateService, popupService, dialogService, analyticsService) {
        this.projectsStore = projectsStore;
        this.projectService = projectService;
        this.projectCreateController = projectCreateController;
        this.currentUserStore = currentUserStore;
        this.routing = routing;
        this.metaService = metaService;
        this.cd = cd;
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.templateService = templateService;
        this.popupService = popupService;
        this.dialogService = dialogService;
        this.analyticsService = analyticsService;
        this.loading = true;
        this.searchControl = new FormControl('');
        this.projects$ = new BehaviorSubject([]);
        this.templates = [];
        this.featuredTemplates$ = new BehaviorSubject([]);
        this.featuredTemplatesFiltered = [];
        this.itemHovered = false;
        this.analyticsEvents = AnalyticsEvent;
    }
    ProjectsComponent.prototype.ngOnInit = function () {
        this.metaService.set({ title: 'My Projects' });
        var reloadProjects = !this.routing.popRouterAttr(ROUTE_LOADED_PROJECTS_STORE);
        this.fetchProjects(reloadProjects);
        this.initSearch();
        this.initQueryParams();
    };
    ProjectsComponent.prototype.ngOnDestroy = function () { };
    ProjectsComponent.prototype.fetchProjects = function (reload) {
        var _this = this;
        if (reload === void 0) { reload = true; }
        this.loading = true;
        this.cd.markForCheck();
        combineLatest(this.projectsStore.get(reload), this.activatedRoute.data.pipe(map(function (data) { return data.domain; })), this.templateService.get({ type: TemplateType.Page }), this.getExternalToken())
            .pipe(map(function (_a) {
            var projects = _a[0], domain = _a[1], templates = _a[2];
            projects = (projects || []).filter(function (project) {
                return project.environments.some(function (item) { return project.hasEnvironmentPermissions(item); });
            });
            if (domain && domain.whiteLabel && domain.domain) {
                projects = projects.filter(function (item) { return item.isDomain(domain); });
            }
            projects = _this.projectService.sortProjectsLastUsed(projects);
            return { projects: projects, domain: domain, templates: templates };
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var projects = _a.projects, domain = _a.domain, templates = _a.templates;
            var templateItems = templates.map(function (item) { return toTemplateItem(item); });
            var featuredTemplates = shuffle([
                adminPanelTemplateItem
            ].concat(templateItems.filter(function (item) { return item.template.featured; })));
            var otherTemplates = shuffle(templateItems.filter(function (item) { return !item.template.featured; }));
            _this.projects$.next(projects);
            _this.domain = domain;
            _this.templates = featuredTemplates.concat(otherTemplates);
            _this.featuredTemplates$.next(featuredTemplates);
            _this.refreshFiltered();
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.currentUserStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.cd.detectChanges(); });
    };
    ProjectsComponent.prototype.initSearch = function () {
        var _this = this;
        combineLatest(this.projects$, this.featuredTemplates$, controlValue(this.searchControl).pipe(debounceTime(60)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var projects = _a[0], templates = _a[1], search = _a[2];
            return _this.updateFiltered(projects, templates, search);
        });
    };
    ProjectsComponent.prototype.updateFiltered = function (projects, templates, search) {
        var searchClean = search.toLowerCase().trim();
        this.projectsFiltered = filterItems(projects, function (item) { return [item.name, item.uniqueName]; }, searchClean);
        this.featuredTemplatesFiltered = filterItems(templates, function (item) { return [item.name]; }, searchClean);
        this.cd.markForCheck();
    };
    ProjectsComponent.prototype.refreshFiltered = function () {
        this.updateFiltered(this.projects$.value, this.featuredTemplates$.value, this.searchControl.value);
    };
    ProjectsComponent.prototype.resetSearch = function () {
        this.searchControl.patchValue('');
        this.refreshFiltered();
    };
    ProjectsComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.resetSearch();
        }
    };
    ProjectsComponent.prototype.initQueryParams = function () {
        var _this = this;
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(function (params) {
            _this.openBuilderLink = isSet(params['builder_link']) ? params['builder_link'].split('/') : undefined;
            _this.cd.markForCheck();
        });
        this.activatedRoute.queryParams
            .pipe(switchMap(function (params) {
            if (params['apply_template']) {
                return _this.templateService.getDetail(params['apply_template']).pipe(map(function (template) {
                    if (!template) {
                        return;
                    }
                    return toTemplateItem(template);
                }));
            }
            else if (params['apply_template_admin_panel']) {
                return of(adminPanelTemplateItem);
            }
            else if (params['apply_template_admin_panel_resource']) {
                var resource = resourceTypeItems.find(function (item) { return item.name == params['apply_template_admin_panel_resource']; });
                return of(__assign({}, adminPanelTemplateItem, { resource: resource }));
            }
            else if (params['create']) {
                return of(undefined);
            }
            else {
                return EMPTY;
            }
        }), untilDestroyed(this))
            .subscribe(function (item) {
            if (!item) {
                _this.createNewProject(item);
                return;
            }
            if (item.type == TemplateItemType.AdminPanel) {
                _this.createNewProject(item);
            }
            else {
                _this.createTemplateProject(item);
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Apply, {
                TemplateID: item.name,
                ResourceType: item.resource ? item.resource.name : undefined,
                Source: 'external'
            });
        });
    };
    ProjectsComponent.prototype.setItemHovered = function (value) {
        this.itemHovered = value;
        this.cd.markForCheck();
    };
    Object.defineProperty(ProjectsComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    ProjectsComponent.prototype.createNewProject = function (applyTemplateItem, force) {
        var _this = this;
        var project$ = applyTemplateItem && force
            ? this.createProjectForTemplate(applyTemplateItem)
            : this.projectCreateController.openCreatePopup({
                applyTemplateItem: applyTemplateItem,
                domain: this.domain,
                dark: true
            });
        project$
            .pipe(switchMap(function (result) {
            var link;
            if (_this.openBuilderLink) {
                link = _this.openBuilderLink;
            }
            else if (result.deployment == ProjectDeployment.OnPremise) {
                link = result.onPremiseLink;
            }
            else {
                link = result.homeLink;
            }
            return _this.getCreatedProjectParams(result, link);
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a.project, link = _a.link, fragment = _a.fragment;
            var queryParams = {};
            if (applyTemplateItem) {
                var serialized = {
                    type: applyTemplateItem.type,
                    resource: applyTemplateItem.resource,
                    template: applyTemplateItem.template ? applyTemplateItem.template.id : undefined
                };
                queryParams[PROJECT_CREATE_TEMPLATE_ITEM] = JSON.stringify(serialized);
            }
            _this.routing.navigateLink(link, { queryParams: queryParams, queryParamsHandling: 'merge', fragment: fragment });
            _this.onProjectCreated(project, applyTemplateItem);
        });
    };
    ProjectsComponent.prototype.getCreatedProjectParams = function (project, appLink) {
        var _this = this;
        if (appLink === void 0) { appLink = []; }
        var link = project.linkWithProtocol(appLink, {
            environmentName: project.defaultEnvironment ? project.defaultEnvironment.uniqueName : 'prod',
            mode: AdminMode.Builder
        });
        if (!link.href) {
            return of({ project: project, link: link });
        }
        return this.getExternalToken().pipe(map(function (token) {
            var fragment = _this.getExternalTokenFragment(token);
            return {
                project: project,
                link: link,
                fragment: fragment
            };
        }));
    };
    ProjectsComponent.prototype.getExternalTokenFragment = function (token) {
        var tokenStr = btoa(JSON.stringify(serializeTokenOptions(token)));
        return TOKEN_FRAGMENT_KEY + "=" + tokenStr;
    };
    ProjectsComponent.prototype.getExternalToken = function () {
        var _this = this;
        if (this.externalToken && !isTokenExpired(this.externalToken.accessTokenExpires)) {
            return of(this.externalToken);
        }
        return this.authService.generateToken().pipe(tap(function (token) { return (_this.externalToken = token); }));
    };
    ProjectsComponent.prototype.previewTemplate = function (template) {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
            TemplateID: template.name,
            Source: 'home_templates'
        });
        this.popupService.push({
            component: TemplatePreviewComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentCloseWithoutConfirm: true,
            inputs: {
                templates: this.templates,
                selectedTemplate: template,
                cancel: true,
                analyticsSource: ''
            },
            outputs: {
                templateApply: [
                    function (result) {
                        if (result.template) {
                            _this.createTemplateProject(result);
                        }
                        else {
                            _this.createNewProject(result, true);
                        }
                    }
                ],
                adminPanelTemplateApply: [
                    function (result) {
                        _this.createNewProject(result, true);
                    }
                ]
            }
        });
    };
    ProjectsComponent.prototype.createProjectForTemplate = function (template) {
        var _this = this;
        var logoFile$ = isSet(template.logo)
            ? this.projectService.getLogoFile(template.logo)
            : of(undefined);
        return logoFile$.pipe(switchMap(function (logoFile) {
            var instance = new Project();
            var name = _this.projectService.popNewProjectName();
            instance.name = isSet(name) ? name : template.name;
            instance.logoColor = template.color;
            instance.logoFile = logoFile;
            instance.logoFill = template.logoFill;
            return _this.projectService.create(instance, undefined, ['name', 'color', 'logo', 'params']);
        }));
    };
    ProjectsComponent.prototype.createTemplateProject = function (template) {
        var _this = this;
        if (template.template && isSet(template.template.copyProject)) {
            var loadingDialog_1 = this.dialogService.loading({
                title: 'Applying template',
                description: 'We are preparing template for you, please, wait...',
                dark: true
            });
            loadingDialog_1.closeOnUnsubscribe().pipe(untilDestroyed(this)).subscribe();
            var instance = new Project();
            instance.name = template.name;
            return this.projectService
                .createFromTemplate(instance, template.template.copyProject)
                .pipe(switchMap(function (result) { return _this.getCreatedProjectParams(result); }), untilDestroyed(this))
                .subscribe(function (_a) {
                var project = _a.project, link = _a.link, fragment = _a.fragment;
                loadingDialog_1.close();
                _this.routing.navigateLink(link, { fragment: fragment });
                _this.onProjectCreated(project, template);
            }, function () {
                loadingDialog_1.close();
            });
        }
        else {
            this.createProjectForTemplate(template)
                .pipe(switchMap(function (result) { return _this.getCreatedProjectParams(result, ['templates', 'apply', template.template.id]); }), untilDestroyed(this))
                .subscribe(function (_a) {
                var project = _a.project, link = _a.link, fragment = _a.fragment;
                _this.routing.navigateLink(link, { fragment: fragment });
                _this.onProjectCreated(project, template);
            });
        }
    };
    ProjectsComponent.prototype.onProjectCreated = function (project, templateItem) {
        var onboardingEventParams = __assign({}, (templateItem &&
            templateItem.template && {
            TemplateID: templateItem.template.name
        }), (templateItem &&
            templateItem.type == TemplateItemType.AdminPanel &&
            !templateItem.resource && {
            OnboardingTemplateAdminPanel: true
        }), (templateItem &&
            templateItem.type == TemplateItemType.AdminPanel &&
            templateItem.resource && {
            OnboardingTemplateAdminPanelResource: templateItem.resource.name
        }));
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.ProjectCreated, __assign({ ProjectID: project.uniqueName, About: project.about }, onboardingEventParams
        // Type: value['type']
        ));
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Subscription.SuccessfulSubscribed, {
            ProjectID: project.uniqueName,
            Plan: project.subscription ? project.subscription.plan.uniqueName : undefined,
            Price: project.subscription ? project.subscription.price : undefined,
            FreeTrial: project.subscription ? !!project.subscription.freeTrial : undefined,
            DateBegin: project.subscription && project.subscription.dateBegin
                ? project.subscription.dateBegin.toISOString()
                : undefined,
            DateEnd: project.subscription && project.subscription.dateEnd ? project.subscription.dateEnd.toISOString() : undefined
        });
    };
    ProjectsComponent.prototype.projectsTrackByFn = function (i, item) {
        return item.uniqueName;
    };
    ProjectsComponent.prototype.templatesTrackByFn = function (i, item) {
        return [item.type].concat((item.template ? [item.template.id] : [])).join('_');
    };
    return ProjectsComponent;
}());
export { ProjectsComponent };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() fill = false;
  @Input() compact = false;
  @Input() inline = false;
  @Input() inverse = false;

  constructor() {}

  ngOnInit() {}
}

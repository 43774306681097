import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type TitleAlign = 'left' | 'center' | 'right';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent implements OnInit {
  @Input() align: TitleAlign = 'left';
  @Input() strong = false;

  constructor() {}

  ngOnInit() {}
}

<app-actions-edit-item
  [actionItemClass]="options.actionItemClass"
  [title]="title"
  [action]="action"
  [context]="context"
  [contextElement]="contextElement"
  [disabled]="defaultDisabled && !enabledControl.value"
  [defaultSubtitle]="defaultSubtitle"
  [remove]="remove && enabledControl.value"
  [error]="customError !== undefined ? customError : form.invalid && form.parent?.dirty ? 'Not configured' : undefined"
  [icon]="icon"
  [classes]="classes"
  (open)="editActionItem()"
  (removeClick)="setEnabled(false)"
>
</app-actions-edit-item>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var IFrameElementItem = /** @class */ (function (_super) {
    __extends(IFrameElementItem, _super);
    function IFrameElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.IFrame;
        _this.border = false;
        return _this;
    }
    IFrameElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.url = this.params['url'];
        this.width = this.params['width'];
        this.height = this.params['height'];
        this.border = this.params['border'];
        if (this.params['url']) {
            // Backward compatibility
            if (typeof this.params['url'] == 'string') {
                this.url = new Input().deserializeFromStatic('value', this.params['url']);
            }
            else {
                this.url = new Input().deserialize(this.params['url']);
            }
        }
        return this;
    };
    IFrameElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['url'] = this.url ? this.url.serialize() : null;
        this.params['width'] = this.width;
        this.params['height'] = this.height;
        this.params['border'] = this.border;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(IFrameElementItem.prototype, "typeStr", {
        get: function () {
            return 'embed external page';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IFrameElementItem.prototype, "analyticsName", {
        get: function () {
            return 'iframe';
        },
        enumerable: true,
        configurable: true
    });
    IFrameElementItem.prototype.defaultName = function () {
        return 'iFrame';
    };
    return IFrameElementItem;
}(ElementItem));
export { IFrameElementItem };
registerElementForType(ElementType.IFrame, IFrameElementItem);

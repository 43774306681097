import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ModelData, ModelDataType } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, generateUUID } from '@shared';

import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';

@Injectable()
export class CustomizeBarModelDataControl extends FormGroup implements OnDestroy {
  instance: ModelData;

  controls: {
    type: FormControl;
    uid: FormControl;
    name: FormControl;
    detail_data_source: ModelDescriptionDataSourceControl;
    list_data_source: ListModelDescriptionDataSourceControl;
  };

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    detailDataSourceControl: ModelDescriptionDataSourceControl,
    listDataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super({
      type: new FormControl(),
      uid: new FormControl(generateUUID().substring(0, 4)),
      name: new FormControl(),
      detail_data_source: detailDataSourceControl,
      list_data_source: listDataSourceControl
    });

    controlValue(this.controls.type)
      .pipe(untilDestroyed(this))
      .subscribe(type => {
        if (type == ModelDataType.Detail) {
          this.controls.detail_data_source.setRequired(true);
          this.controls.list_data_source.setRequired(false);
        }

        if (type == ModelDataType.List) {
          this.controls.detail_data_source.setRequired(false);
          this.controls.list_data_source.setRequired(true);
        }
      });
  }

  ngOnDestroy(): void {}

  deserialize(item?: ModelData) {
    this.instance = item;

    if (item) {
      this.controls.type.patchValue(item.type);
      this.controls.uid.patchValue(item.uid);
      this.controls.name.patchValue(item.name);
    }

    this.controls.detail_data_source.deserialize(item ? item.detailDataSource : undefined);
    this.controls.list_data_source.deserialize(item ? item.listDataSource : undefined);

    this.markAsPristine();
  }

  serialize(): ModelData {
    const instance = new ModelData();

    if (this.instance) {
      instance.deserialize(this.instance.serialize());
    }

    instance.type = this.controls.type.value;
    instance.uid = this.controls.uid.value;
    instance.name = this.controls.name.value;

    if (this.controls.type.value == ModelDataType.Detail) {
      instance.detailDataSource = this.controls.detail_data_source.serialize();
    }

    if (this.controls.type.value == ModelDataType.List) {
      instance.listDataSource = this.controls.list_data_source.serialize();
    }

    return instance;
  }
}

import { FormControl, FormGroup } from '@angular/forms';

import { CornerRadius } from '@modules/views';
import { isSet } from '@shared';

export interface CornerRadiusControlValue {
  top_left: number;
  top_right: number;
  bottom_right: number;
  bottom_left: number;
}

export class CornerRadiusControl extends FormGroup {
  instance: CornerRadius;

  controls: {
    top_left: FormControl;
    top_right: FormControl;
    bottom_right: FormControl;
    bottom_left: FormControl;
  };

  _applyFormState: any;

  constructor(state: Partial<CornerRadius> = {}) {
    super({
      top_left: new FormControl(isSet(state.topLeft) ? state.topLeft : 0),
      top_right: new FormControl(isSet(state.topRight) ? state.topRight : 0),
      bottom_right: new FormControl(isSet(state.bottomRight) ? state.bottomRight : 0),
      bottom_left: new FormControl(isSet(state.bottomLeft) ? state.bottomLeft : 0)
    });
  }

  deserialize(value: CornerRadius, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.top_left.patchValue(value.topLeft, options);
    this.controls.top_right.patchValue(value.topRight, options);
    this.controls.bottom_right.patchValue(value.bottomRight, options);
    this.controls.bottom_left.patchValue(value.bottomLeft, options);
  }

  getInstance(instance?: CornerRadius): CornerRadius {
    if (!instance) {
      instance = new CornerRadius();
    }

    instance.topLeft = this.controls.top_left.value;
    instance.topRight = this.controls.top_right.value;
    instance.bottomRight = this.controls.bottom_right.value;
    instance.bottomLeft = this.controls.bottom_left.value;

    return instance;
  }

  serialize(): CornerRadius {
    return this.getInstance(this.instance);
  }

  onDisabledChange = (value: boolean) => undefined;

  registerOnChange(fn: Function): void {
    this.valueChanges.subscribe(value => fn(value));
  }

  registerOnDisabledChange(fn: (disabled: boolean) => void): void {
    this.onDisabledChange = fn;
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    this.onDisabledChange(false);
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    this.onDisabledChange(true);
  }
}

import { Injectable, Injector } from '@angular/core';
import clone from 'lodash/clone';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';

import { BaseField, getFieldDescriptionByType } from '@modules/fields';
import { EMPTY, isSet } from '@shared';

import { detectPrimaryKey } from '../utils/common/common';
import { ModelDescription } from './model-description';

export const NO_KEY_ATTRIBUTE = 'result';

@Injectable()
export class Model {
  public modelDescription: ModelDescription;
  private rawAttributes = {};
  private attributes = {};
  private relations: { [k: string]: { path: string[]; field: string; value: any | any[] } } = {};

  public primaryKey: any;
  public initialPrimaryKey: any;
  public dbStr: string;
  public previousSibling: Model;
  public nextSibling: Model;
  public model: string;
  public noAttributesKey = false;

  constructor(private injector: Injector) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  getRelationKey(options: { path: string[]; field: string }): string {
    return JSON.stringify([options.path, options.field]);
  }

  getRelationValue(options: { path: string[]; field: string; defaultValue?: any }): any | any[] {
    const key = this.getRelationKey(options);
    const defaultValue = options.defaultValue !== undefined ? options.defaultValue : EMPTY;
    return this.relations.hasOwnProperty(key) ? this.relations[key].value : defaultValue;
  }

  deserialize(
    model: string,
    data: Object = {},
    relations: { path: string[]; field: string; value: any | any[] }[] = []
  ): Model {
    this.model = model;

    if (data['__str__'] != undefined) {
      this.dbStr = data['__str__'];
      delete data['__str__'];
    }

    if (!isPlainObject(data)) {
      this.setRawAttribute(NO_KEY_ATTRIBUTE, data);
      this.noAttributesKey = true;
    } else {
      keys(data).forEach(key => {
        this.setRawAttribute(key, data[key]);
      });
    }

    if (data['__previous_sibling__']) {
      this.previousSibling = this.createModel().deserialize(this.model, data['__previous_sibling__']);
    }

    if (data['__next_sibling__']) {
      this.nextSibling = this.createModel().deserialize(this.model, data['__next_sibling__']);
    }

    this.relations = relations.reduce((acc, item) => {
      const key = this.getRelationKey(item);
      acc[key] = item;
      return acc;
    }, {});

    return this;
  }

  setUp(modelDescription: ModelDescription) {
    this.model = modelDescription.model;
    this.modelDescription = modelDescription;
    this.updatePrimaryKey();

    if (this.previousSibling) {
      this.previousSibling.setUp(modelDescription);
    }

    if (this.nextSibling) {
      this.nextSibling.setUp(modelDescription);
    }
  }

  setPrimaryKey(value: any) {
    this.primaryKey = value;

    if (!isSet(this.initialPrimaryKey) && isSet(value)) {
      this.initialPrimaryKey = value;
    }
  }

  updatePrimaryKey() {
    if (this.modelDescription && this.modelDescription.primaryKeyField) {
      const primaryKey = this.getAttribute(this.modelDescription.primaryKeyField);
      this.setPrimaryKey(primaryKey);
    } else {
      const primaryKeyField = detectPrimaryKey(keys(this.getAttributes()));
      if (isSet(primaryKeyField)) {
        const primaryKey = this.getAttribute(primaryKeyField);
        this.setPrimaryKey(primaryKey);
      }
    }
  }

  deserializeAttributes(fields: BaseField[]) {
    const jetFormat = this.modelDescription ? this.modelDescription.syncResource : false;

    fields.forEach(item => {
      let value = this.getRawAttribute(item.name);
      const fieldDescription = getFieldDescriptionByType(item.field);

      if (fieldDescription && fieldDescription.deserializeValue) {
        let field = item;

        if (jetFormat && item.params && item.params['output_format']) {
          field = {
            ...item,
            params: { ...item.params, output_format: undefined }
          };
        }

        value = fieldDescription.deserializeValue(value, field);
      }

      this.setAttribute(item.name, value);
    });

    this.updatePrimaryKey();
  }

  serialize(fields?: string[]): Object {
    let data: Object = clone(this.getAttributes());

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }

    if (this.modelDescription) {
      this.modelDescription.dbFields
        .filter(item => item.fieldDescription.serializeValue != undefined && data[item.name] != undefined)
        .forEach(item => {
          data[item.name] = item.fieldDescription.serializeValue(data[item.name], item);
        });
    }

    return data;
  }

  getRawAttribute(name) {
    return this.rawAttributes[name];
  }

  hasRawAttribute(name) {
    return this.rawAttributes.hasOwnProperty(name);
  }

  getRawAttributes() {
    return this.rawAttributes;
  }

  setRawAttribute(name, value) {
    this.rawAttributes[name] = value;
  }

  setRawAttributes(data: Object) {
    this.rawAttributes = { ...data };
  }

  getRawData() {
    if (this.noAttributesKey) {
      return this.getRawAttribute(NO_KEY_ATTRIBUTE);
    } else {
      return this.rawAttributes;
    }
  }

  getAttribute(name) {
    return this.attributes[name];
  }

  hasAttribute(name) {
    return this.attributes.hasOwnProperty(name);
  }

  getAttributes() {
    return this.attributes;
  }

  setAttribute(name, value) {
    this.attributes[name] = value;
  }

  setAttributes(data: Object) {
    this.attributes = { ...data };
  }

  getData() {
    if (this.noAttributesKey) {
      return this.getAttribute(NO_KEY_ATTRIBUTE);
    } else {
      return this.attributes;
    }
  }

  get modelId() {
    if (!this.modelDescription || !this.modelDescription.resource) {
      return this.model;
    }
    return [this.modelDescription.resource, this.model].join('.');
  }

  getLink() {
    if (!this.modelDescription) {
      return;
    }
    return this.modelDescription.changeLink(this.primaryKey);
  }

  get userActivityLink() {
    return ['models', this.modelId, this.primaryKey, 'user_activities'];
  }

  isSame(model: Model) {
    if (!model) {
      return false;
    }

    return isSet(this.primaryKey) && isSet(model.primaryKey) && this.primaryKey == model.primaryKey;
  }

  clone(): Model {
    const result = this.createModel();

    result.modelDescription = this.modelDescription;
    result.rawAttributes = this.rawAttributes;
    result.attributes = this.attributes;
    result.primaryKey = this.primaryKey;
    result.initialPrimaryKey = this.initialPrimaryKey;
    result.dbStr = this.dbStr;
    result.previousSibling = this.previousSibling;
    result.nextSibling = this.nextSibling;
    result.model = this.model;
    result.noAttributesKey = this.noAttributesKey;

    return result;
  }
}

import * as i0 from "@angular/core";
var ElementComponentsService = /** @class */ (function () {
    function ElementComponentsService() {
        this.elementComponents = [];
    }
    ElementComponentsService.prototype.register = function (element, component) {
        this.elementComponents.push({
            element: element,
            component: component
        });
    };
    ElementComponentsService.prototype.unregister = function (element) {
        this.elementComponents = this.elementComponents.filter(function (item) { return item.element !== element; });
    };
    ElementComponentsService.prototype.get = function (element) {
        var result = this.elementComponents.find(function (item) { return item.element === element; });
        return result ? result.component : undefined;
    };
    ElementComponentsService.ngInjectableDef = i0.defineInjectable({ factory: function ElementComponentsService_Factory() { return new ElementComponentsService(); }, token: ElementComponentsService, providedIn: "root" });
    return ElementComponentsService;
}());
export { ElementComponentsService };

import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PopupService } from '@common/popups';
import { CookieStorage } from '@core';
import { deployUrl } from '@shared';

import { VideoPopupComponent } from '../../components/video-popup/video-popup.component';

@Injectable({
  providedIn: 'root'
})
export class IntroService {
  _visible = new BehaviorSubject<boolean>(false);

  constructor(private popupService: PopupService, private cookieStorage: CookieStorage, private injector: Injector) {}

  get shown(): boolean {
    return this.cookieStorage.get('intro_shown') == 'true';
  }

  set shown(value: boolean) {
    this.cookieStorage.set('intro_shown', value.toString());
  }

  set visible(value: boolean) {
    this._visible.next(value);
  }

  get visible(): boolean {
    return this._visible.value;
  }

  get visible$(): Observable<boolean> {
    return this._visible.asObservable();
  }

  showOverview(sourceElement?: Element, autoPlay = false) {
    this.visible = true;
    this.popupService.push({
      component: VideoPopupComponent,
      inputs: {
        title: 'Jet – Quick Overview',
        src: deployUrl('/assets/video/demo.mp4'),
        autoPlay: autoPlay,
        sourceElement: sourceElement,
        links: [
          {
            label: 'Continue',
            action: 'close'
          },
          {
            label: 'Create App',
            link: ['/projects', 'create']
          },
          {
            label: 'Documentation',
            href: 'https://docs.jetadmin.io'
          },
          {
            label: 'Jet Website',
            href: 'https://jetadmin.io/'
          }
        ]
      },
      outputs: {
        closed: [() => (this.visible = false)]
      },
      injector: this.injector
    });
  }

  showOverviewIfNotShown(sourceElement?: Element) {
    if (this.shown || this.visible) {
      return;
    }

    this.shown = true;
    this.showOverview(sourceElement);
  }
}

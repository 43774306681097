import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-context-token-popover-stub',
  templateUrl: './view-context-token-popover-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverStubComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import { createFormFieldFactory, DisplayField, FieldType } from '@modules/fields';
import { ColumnListItem, ListItem } from '@modules/list';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-project-resource-data-templates-table-item, [app-project-resource-data-templates-table-item]',
  templateUrl: './project-resource-data-templates-table-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectResourceDataTemplatesTableItemComponent implements OnInit, OnChanges {
  @Input() row: ListItem;
  @Input() columns: DisplayField[];

  createField = createFormFieldFactory();
  columnListItems: ColumnListItem[] = [];
  fieldTypes = FieldType;

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ProjectResourceDataTemplatesTableItemComponent>): void {
    if (changes.row || changes.columns) {
      this.updateItems();
    }
  }

  updateItems() {
    this.columnListItems = this.columns.map(item => {
      const column = cloneDeep(item);
      const itemColumn = this.row.columns.find(i => i.column == item.name);

      if (item.field == FieldType.JSON && item.params['display_fields']) {
        column.params = {
          ...column.params,
          display_fields: false
        };
      }

      return {
        listItem: itemColumn,
        column: column
      };
    });
  }

  trackByFn(i, item: ColumnListItem) {
    return item ? item.column.name : i;
  }
}

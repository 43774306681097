var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from '../services/project/project.service';
var ProjectSelectSource = /** @class */ (function (_super) {
    __extends(ProjectSelectSource, _super);
    function ProjectSelectSource(projectService) {
        var _this = _super.call(this) || this;
        _this.projectService = projectService;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    ProjectSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        var params = { page: this.page };
        if (searchQuery != undefined) {
            params['_search'] = searchQuery;
        }
        return this.projectService.get(params).pipe(map(function (result) {
            _this.totalPages = Math.ceil(result.count / 20);
            _this.page += 1;
            return result.results
                .filter(function (item) {
                return !searchQuery ||
                    item.uniqueName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.name.toLowerCase().includes(searchQuery.toLowerCase());
            })
                .map(function (item) {
                return {
                    value: item,
                    name: item.name
                };
            });
        }));
    };
    ProjectSelectSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.projectService.get().pipe(map(function (result) {
            var project = result.results.find(function (item) { return item.uniqueName == value.uniqueName; });
            if (!project) {
                return;
            }
            return {
                value: project,
                name: project.name
            };
        }));
    };
    ProjectSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    ProjectSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    ProjectSelectSource.prototype.setStaticOptions = function (options) { };
    return ProjectSelectSource;
}(SelectSource));
export { ProjectSelectSource };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CustomizeService, FilterElementInput, FilterStyle, ListElementItem } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { getFieldDescriptionByType, Input } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider, listModelDescriptionInputFieldProvider } from '@modules/parameters';
import { CurrentEnvironmentStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';
import { MarginControl } from '../margin-control/margin-control.component';
var CustomizeBarFilterElementInputControl = /** @class */ (function (_super) {
    __extends(CustomizeBarFilterElementInputControl, _super);
    function CustomizeBarFilterElementInputControl() {
        return _super.call(this, {
            settings: new FormControl(undefined),
            tooltip: new FormControl('')
        }) || this;
    }
    CustomizeBarFilterElementInputControl.prototype.serialize = function () {
        var result = cloneDeep(this.instance) || new FilterElementInput();
        result.name = this.field.name;
        result.settings = this.controls.settings.value;
        result.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        return result;
    };
    return CustomizeBarFilterElementInputControl;
}(FormGroup));
export { CustomizeBarFilterElementInputControl };
var CustomizeBarFilterElementInputArray = /** @class */ (function (_super) {
    __extends(CustomizeBarFilterElementInputArray, _super);
    function CustomizeBarFilterElementInputArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomizeBarFilterElementInputArray.prototype.serialize = function () {
        return this.controls.map(function (item) { return item.serialize(); });
    };
    CustomizeBarFilterElementInputArray.prototype.set = function (controls) {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
        controls.forEach(function (item) { return _this.push(item); });
        this.updateValueAndValidity();
    };
    CustomizeBarFilterElementInputArray.prototype.append = function (control) {
        this.push(control);
        this.updateValueAndValidity();
    };
    CustomizeBarFilterElementInputArray.prototype.remove = function (control) {
        var index = this.controls.findIndex(function (item) { return item === control; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    return CustomizeBarFilterElementInputArray;
}(FormArray));
export { CustomizeBarFilterElementInputArray };
var CustomizeBarFilterEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarFilterEditForm, _super);
    function CustomizeBarFilterEditForm(customizeService, elementConfigurationService, currentEnvironmentStore, modelDescriptionStore) {
        var _this = _super.call(this, {
            url: new FieldInputControl({ name: 'value' }, { validators: Validators.required, updateOn: 'blur' }),
            name: new FormControl(''),
            element: new FormControl(''),
            element_inputs: new CustomizeBarFilterElementInputArray([]),
            style: new FormControl(FilterStyle.Wrap),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl()
        }) || this;
        _this.customizeService = customizeService;
        _this.elementConfigurationService = elementConfigurationService;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.inputFieldProvider = new InputFieldProvider();
        _this.styleOptions = [
            {
                value: FilterStyle.Background,
                name: 'Background',
                image: 'filter-style-background'
            },
            {
                value: FilterStyle.Wrap,
                name: 'Wrap',
                image: 'filter-style-wrap'
            }
        ];
        return _this;
    }
    CustomizeBarFilterEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarFilterEditForm.prototype.init = function (element, viewContext, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        this.context = viewContext;
        this.controls.name.patchValue(element.name ? element.name : 'Filter');
        this.controls.element.patchValue(element.elements[0]);
        this.controls.style.patchValue(element.style);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
        this.controls.margin.patchValue(element.margin);
        this.filterElement$()
            .pipe(switchMap(function (filterElement) {
            var dataSource = filterElement && filterElement.layouts[0] ? filterElement.layouts[0].dataSource : undefined;
            var resource = dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == dataSource.queryResource; })
                : undefined;
            var modelId = dataSource && dataSource.query && dataSource.query.simpleQuery
                ? [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.')
                : undefined;
            return combineLatest(of(dataSource), of(resource), _this.modelDescriptionStore.getDetailFirst(modelId));
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var dataSource = _a[0], resource = _a[1], modelDescription = _a[2];
            var provider = dataSource
                ? listModelDescriptionInputFieldProvider(dataSource.type, resource, modelDescription, dataSource.queryParameters, dataSource.query, dataSource.columns)
                : [];
            _this.inputFieldProvider.setProvider(provider);
        });
        combineLatest(this.filterElement$(), this.inputFieldProvider.fields$)
            .pipe(map(function (_a) {
            var listElement = _a[0], fields = _a[1];
            if (!listElement || !listElement.layouts[0] || !listElement.layouts[0].dataSource) {
                return [];
            }
            return listElement.layouts[0].dataSource.queryInputs
                .map(function (input) {
                return {
                    input: input,
                    field: fields.find(function (item) { return item.name == input.name; })
                };
            })
                .filter(function (item) { return item.field && item.input.contextValueStartsWith(['elements', _this.element.uid]); })
                .map(function (item) { return item.field; });
        }), untilDestroyed(this))
            .subscribe(function (fields) {
            var controls = fields
                .map(function (field) {
                var control = _this.controls.element_inputs.controls.find(function (item) { return item.field.name == field.name; });
                var controlIndex = element.elementInputs.findIndex(function (item) { return item.name == field.name; });
                if (!control) {
                    var settings = element.elementInputs.find(function (item) { return item.name == field.name; });
                    control = new CustomizeBarFilterElementInputControl();
                    control.field = field;
                    control.fieldDescription = getFieldDescriptionByType(field.field);
                    control.controls.settings.patchValue(settings ? settings.settings : undefined);
                    control.controls.tooltip.patchValue(settings ? settings.tooltip : '');
                }
                return {
                    control: control,
                    index: controlIndex
                };
            })
                .sort(function (lhs, rhs) {
                if (lhs.index !== -1 && rhs.index !== -1) {
                    return lhs.index - rhs.index;
                }
                else if (lhs.index !== -1 && rhs.index === -1) {
                    return -1;
                }
                else if (lhs.index === -1 && rhs.index !== -1) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
                .map(function (item) { return item.control; });
            _this.controls.element_inputs.set(controls);
        });
        if (!firstInit) {
            this.markAsDirty();
        }
        else {
            this.markAsPristine();
        }
    };
    CustomizeBarFilterEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isFilterConfigured(instance);
    };
    CustomizeBarFilterEditForm.prototype.filterElement$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.element), this.context.elements$).pipe(switchMap(function (_a) {
            var uniqueName = _a[0];
            var contextElement = _this.context.getElement$(uniqueName);
            var element = contextElement && contextElement.element instanceof ListElementItem ? contextElement.element : undefined;
            if (!element) {
                return of({
                    element: undefined,
                    serialized: undefined
                });
            }
            return merge(of({}), _this.customizeService.changed$).pipe(map(function () {
                return {
                    element: element,
                    serialized: element ? element.serialize() : undefined
                };
            }));
        }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs.serialized, rhs.serialized); }), map(function (item) { return item.element; }));
    };
    CustomizeBarFilterEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.name.value;
        instance.elements = isSet(this.controls.element.value) ? [this.controls.element.value] : [];
        instance.elementInputs = this.controls.element_inputs.serialize();
        instance.style = this.controls.style.value;
        instance.margin = this.controls.margin.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarFilterEditForm;
}(FormGroup));
export { CustomizeBarFilterEditForm };

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'app-render',
  templateUrl: './render.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderComponent implements OnInit, OnChanges {
  @Input() content?: string | TemplateRef<any>;
  @Input() context = {};

  template: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.template = this.content instanceof TemplateRef ? this.content : undefined;
  }
}

import { BehaviorSubject } from 'rxjs';
import { NotificationType } from '../../data/notification-type';
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this._notifications = new BehaviorSubject([]);
        this._topPadding = new BehaviorSubject(false);
    }
    Object.defineProperty(NotificationService.prototype, "notifications", {
        get: function () {
            return this._notifications.value;
        },
        set: function (value) {
            this._notifications.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationService.prototype, "notifications$", {
        get: function () {
            return this._notifications.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationService.prototype, "topPadding", {
        get: function () {
            return this._topPadding.value;
        },
        set: function (value) {
            this._topPadding.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationService.prototype, "topPadding$", {
        get: function () {
            return this._topPadding.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    NotificationService.prototype.show = function (notification) {
        this.notifications = this.notifications.concat([notification]);
    };
    NotificationService.prototype.success = function (title, description) {
        this.show({ type: NotificationType.Success, title: title, description: description });
    };
    NotificationService.prototype.error = function (title, description) {
        this.show({ type: NotificationType.Error, title: title, description: description });
    };
    NotificationService.prototype.warning = function (title, description) {
        this.show({ type: NotificationType.Warning, title: title, description: description });
    };
    NotificationService.prototype.info = function (title, description) {
        this.show({ type: NotificationType.Info, title: title, description: description });
    };
    NotificationService.prototype.remove = function (notification) {
        this.notifications = this.notifications.filter(function (item) { return item !== notification; });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };

import { Injectable } from '@angular/core';

import { DialogService } from '@common/dialogs';
import { AppConfigService } from '@core';
import { Environment, Project } from '@modules/projects';
import { isLocalLocation, openUrl } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  constructor(private appConfigService: AppConfigService, private dialogService: DialogService) {}

  checkWrongProjectVersionLoaded(project: Project, environment: Environment) {
    if (isLocalLocation()) {
      return;
    }

    const usedVersion = this.appConfigService.version;
    const urlRegex = window.location.href.match(/^(https?:\/\/[^\/]+)(?:\/(v.+))?\/((?:app|builder)\/.+)$/);

    if (!environment || !environment.version || environment.version == usedVersion || !urlRegex) {
      return;
    }

    const redirect = `${urlRegex[1]}/v${environment.version}/${urlRegex[3]}`;
    const forceProjectVersion = !project.params['optional_project_version'];

    if (forceProjectVersion) {
      openUrl(redirect);
      return;
    }

    this.dialogService
      .warning({
        title: 'Reload interface version',
        description: `
            Your current App environment is using interface version <strong>${environment.version}</strong>, but you are opening
            project with version <strong>${usedVersion}</strong>.
            Would you like to reload it with project version <strong>${environment.version}</strong>?
          `
      })
      .subscribe(confirm => {
        if (!confirm) {
          return;
        }

        openUrl(redirect);
      });
  }
}

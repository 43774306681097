import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';

// TODO: Refactor imports
import { Size } from '../../../shared/utils/media/media';

import { AppConfigService } from '../app-config/app-config.service';

export enum ViewportType {
  Desktop,
  Tablet,
  Phone
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService implements OnDestroy {
  private tabletMaxWidth = 992;
  private phoneMaxWidth = 480;

  private _viewportWidth = new BehaviorSubject<number>(undefined);
  private _viewportHeight = new BehaviorSubject<number>(undefined);
  private _viewportType = new BehaviorSubject<ViewportType>(undefined);

  constructor(private zone: NgZone, private appConfigService: AppConfigService) {
    if (this.appConfigService.isPlatformServer()) {
      return;
    }

    merge(of({}), fromEvent(window, 'resize'))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._viewportWidth.next(window.innerWidth);
        this._viewportHeight.next(window.innerHeight);
      });

    this._viewportWidth.pipe(untilDestroyed(this)).subscribe(width => {
      this.updateViewportType(width);
    });
  }

  ngOnDestroy(): void {}

  get viewportWidth(): number {
    return this._viewportWidth.value;
  }

  get viewportWidth$(): Observable<number> {
    return this._viewportWidth.pipe(filter(item => item !== undefined));
  }

  get viewportWidthChanges$(): Observable<number> {
    return this.viewportWidth$.pipe(skip(1));
  }

  get viewportHeight(): number {
    return this._viewportHeight.value;
  }

  get viewportHeight$(): Observable<number> {
    return this._viewportHeight.pipe(filter(item => item !== undefined));
  }

  get viewportHeightChanges$(): Observable<number> {
    return this.viewportHeight$.pipe(skip(1));
  }

  get viewportSize$(): Observable<Size> {
    return combineLatest(this.viewportWidth$, this.viewportHeight$).pipe(
      map(([width, height]) => {
        return { width: width, height: height };
      })
    );
  }

  get viewportSizeChanges$(): Observable<Size> {
    return this.viewportSize$.pipe(skip(1));
  }

  get viewportType(): ViewportType {
    return this._viewportType.value;
  }

  get viewportType$(): Observable<ViewportType> {
    return this._viewportType.pipe(filter(item => item !== undefined));
  }

  isDesktopViewportType(value: ViewportType): boolean {
    return value == ViewportType.Desktop;
  }

  isTabletViewportType(value: ViewportType): boolean {
    return value == ViewportType.Tablet;
  }

  isPhoneViewportType(value: ViewportType): boolean {
    return value == ViewportType.Phone;
  }

  isMobileViewportType(value: ViewportType): boolean {
    return [ViewportType.Tablet, ViewportType.Phone].includes(value);
  }

  isTablet(): boolean {
    return this.isTabletViewportType(this.viewportType);
  }

  isTablet$(): Observable<boolean> {
    return this.viewportType$.pipe(map(value => this.isTabletViewportType(value)));
  }

  isPhone(): boolean {
    return this.isPhoneViewportType(this.viewportType);
  }

  isPhone$(): Observable<boolean> {
    return this.viewportType$.pipe(map(value => this.isPhoneViewportType(value)));
  }

  isMobile(): boolean {
    return this.isMobileViewportType(this.viewportType);
  }

  isMobile$(): Observable<boolean> {
    return this.viewportType$.pipe(map(value => this.isMobileViewportType(value)));
  }

  getViewportType(viewportWidth: number): ViewportType {
    if (viewportWidth <= this.phoneMaxWidth) {
      return ViewportType.Phone;
    } else if (viewportWidth <= this.tabletMaxWidth) {
      return ViewportType.Tablet;
    } else {
      return ViewportType.Desktop;
    }
  }

  updateViewportType(viewportWidth: number) {
    const viewportType = this.getViewportType(viewportWidth);

    if (this._viewportType.value != viewportType) {
      this._viewportType.next(viewportType);
    }
  }
}

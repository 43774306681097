import isArray from 'lodash/isArray';

import { ParameterField } from '@modules/fields';

import { getElementByType } from '../elements/element-items';
import { ElementItem } from '../elements/items/base';
import { PopupSettings } from '../popup';
import { ViewSettings, ViewSettingsType } from './base';

export class CustomViewSettings extends ViewSettings {
  public view = ViewSettingsType.Custom;
  public elements: ElementItem[] = [];
  public popups: PopupSettings[] = [];

  deserialize(data: Object) {
    super.deserialize(data);

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    const mapElement = item => {
      const element = getElementByType(item['type']);
      if (!element) {
        console.error(`Unsupported element type: ${item['type']}`);
        return;
      }
      return new element().deserialize(item);
    };

    if (this.params && this.params['elements']) {
      this.elements = this.params['elements'].map(mapElement).filter(item => item != undefined);
    } else if (this.params && isArray(this.params)) {
      this.elements = this.params.map(mapElement).filter(item => item != undefined);
    }

    if (this.params['popups']) {
      this.popups = this.params['popups'].map(item => new PopupSettings().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['elements'] = this.elements.map(item => item.serialize());
    this.params['popups'] = this.popups.map(item => item.serialize());
    return super.serialize(fields);
  }
}

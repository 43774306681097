/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-logo.component";
var styles_PageLogoComponent = [];
var RenderType_PageLogoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageLogoComponent, data: {} });
export { RenderType_PageLogoComponent as RenderType_PageLogoComponent };
export function View_PageLogoComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageLogoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-logo", [], [[2, "page-logo", null], [2, "page-logo_align_left", null], [2, "page-logo_align_center", null], [2, "page-logo_align_right", null]], null, null, View_PageLogoComponent_0, RenderType_PageLogoComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageLogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).alignLeft; var currVal_2 = i0.ɵnov(_v, 1).alignCenter; var currVal_3 = i0.ɵnov(_v, 1).alignRight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var PageLogoComponentNgFactory = i0.ɵccf("app-page-logo", i1.PageLogoComponent, View_PageLogoComponent_Host_0, { align: "align" }, {}, ["*"]);
export { PageLogoComponentNgFactory as PageLogoComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ScannerElementItem = /** @class */ (function (_super) {
    __extends(ScannerElementItem, _super);
    function ScannerElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Scanner;
        _this.scanningInitial = false;
        _this.ignoreDuplicateScans = true;
        _this.scanConfirm = false;
        _this.onScanActions = [];
        return _this;
    }
    ScannerElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.height = this.params['height'];
        if (this.params.hasOwnProperty('scanning_initial')) {
            this.scanningInitial = this.params['scanning_initial'];
        }
        if (this.params.hasOwnProperty('ignore_duplicate_scans')) {
            this.ignoreDuplicateScans = this.params['ignore_duplicate_scans'];
        }
        if (this.params.hasOwnProperty('scan_confirm')) {
            this.scanConfirm = this.params['scan_confirm'];
        }
        if (this.params['on_scan_actions']) {
            this.onScanActions = this.params['on_scan_actions'].map(function (item) { return new ActionItem().deserialize(item); });
        }
        return this;
    };
    ScannerElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            height: this.height,
            scanning_initial: this.scanningInitial,
            ignore_duplicate_scans: this.ignoreDuplicateScans,
            scan_confirm: this.scanConfirm,
            on_scan_actions: this.onScanActions.map(function (item) { return item.serialize(); })
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ScannerElementItem.prototype, "typeStr", {
        get: function () {
            return 'scanner';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScannerElementItem.prototype, "analyticsName", {
        get: function () {
            return 'scanner';
        },
        enumerable: true,
        configurable: true
    });
    ScannerElementItem.prototype.defaultName = function () {
        return 'Scanner';
    };
    return ScannerElementItem;
}(ElementItem));
export { ScannerElementItem };
registerElementForType(ElementType.Scanner, ScannerElementItem);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../list-layouts/list-layouts.component.ngfactory";
import * as i2 from "../list-layouts/list-layouts.component";
import * as i3 from "../../../layouts/services/layout/layout.service";
import * as i4 from "../../../customize/services/customize/customize.service";
import * as i5 from "../list-search/list-search.component.ngfactory";
import * as i6 from "../list-search/list-search.component";
import * as i7 from "../../../filters-components/components/filter-edit-overlay/filter-edit-overlay.component.ngfactory";
import * as i8 from "../../../filters-components/components/filter-edit-overlay/filter-edit-overlay.component";
import * as i9 from "../../../../common/tips/directives/tip/tip.directive";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "../../../colors-shared/directives/button-tint-color/button-tint-color.directive";
import * as i12 from "@angular/platform-browser";
import * as i13 from "./list-actions-item/list-actions-item.component.ngfactory";
import * as i14 from "./list-actions-item/list-actions-item.component";
import * as i15 from "../../../action-queries/services/action/action.service";
import * as i16 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i17 from "../../../../common/notifications/services/notification/notification.service";
import * as i18 from "@angular/common";
import * as i19 from "@angular/cdk/bidi";
import * as i20 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i21 from "./list-actions.component";
import * as i22 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i23 from "../../../projects/stores/current-project.store";
import * as i24 from "../../../custom-views/stores/custom-views.store";
var styles_ListActionsComponent = [];
var RenderType_ListActionsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListActionsComponent, data: {} });
export { RenderType_ListActionsComponent as RenderType_ListActionsComponent };
function View_ListActionsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-layouts", [], null, [[null, "layoutAdd"], [null, "layoutCustomize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("layoutAdd" === en)) {
        var pd_0 = (_co.layoutAdd.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("layoutCustomize" === en)) {
        var pd_1 = (_co.layoutCustomize.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ListLayoutsComponent_0, i1.RenderType_ListLayoutsComponent)), i0.ɵdid(1, 245760, null, 0, i2.ListLayoutsComponent, [i3.LayoutService, i4.CustomizeService, i0.ChangeDetectorRef], null, { layoutAdd: "layoutAdd", layoutCustomize: "layoutCustomize" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ListActionsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-search", [], null, [[null, "searchUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchUpdated" === en)) {
        var pd_0 = (_co.searchChanged.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ListSearchComponent_0, i5.RenderType_ListSearchComponent)), i0.ɵdid(1, 770048, null, 0, i6.ListSearchComponent, [i0.ChangeDetectorRef], { listTitle: [0, "listTitle"], search: [1, "search"], focus: [2, "focus"], live: [3, "live"], accentColor: [4, "accentColor"] }, { searchUpdated: "searchUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listTitle; var currVal_1 = _co.search; var currVal_2 = _co.focus; var currVal_3 = _co.searchLive; var currVal_4 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ListActionsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-filter-edit-overlay", [], null, [[null, "filtersUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersUpdated" === en)) {
        var pd_0 = (_co.filtersUpdated.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FilterEditOverlayComponent_0, i7.RenderType_FilterEditOverlayComponent)), i0.ɵdid(2, 4440064, null, 0, i8.FilterEditOverlayComponent, [i0.ChangeDetectorRef], { dataSource: [0, "dataSource"], filters: [1, "filters"], optionsFilter: [2, "optionsFilter"], origin: [3, "origin"] }, { filtersUpdated: "filtersUpdated" }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "a", [["cdkOverlayOrigin", ""], ["class", "button button_icon list__actions-item"], ["href", "javascript:void(0)"]], [[8, "style", 2], [2, "button_tint-color", null]], null, null, null, null)), i0.ɵdid(4, 737280, null, 0, i9.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵppd(5, 1), i0.ɵpod(6, { side: 0 }), i0.ɵdid(7, 16384, [["addFilterOrigin", 4]], 0, i10.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(8, 540672, null, 0, i11.ButtonTintColorDirective, [i12.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "button__icon icon-filter_2"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.filters; var currVal_2 = _co.optionsFilter; var currVal_3 = i0.ɵnov(_v, 7); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "Filter")); var currVal_7 = _ck(_v, 6, 0, "top"); _ck(_v, 4, 0, currVal_6, currVal_7); var currVal_8 = _co.accentColor; var currVal_9 = _co.tintStyles.Default; _ck(_v, 8, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_4 = i0.ɵnov(_v, 8).style; var currVal_5 = i0.ɵnov(_v, 8).cls; _ck(_v, 3, 0, currVal_4, currVal_5); }); }
function View_ListActionsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-actions-item", [], [[2, "element__indicator-wrapper", null], [2, "element__indicator-wrapper_block", null], [2, "hidden", null]], [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.closeOtherActionsDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ListActionsItemComponent_0, i13.RenderType_ListActionsItemComponent)), i0.ɵdid(1, 770048, null, 0, i14.ListActionsItemComponent, [i4.CustomizeService, i15.ActionService, i16.ActionControllerService, i17.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], pinned: [1, "pinned"], context: [2, "context"], contextElement: [3, "contextElement"], accentColor: [4, "accentColor"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit; var currVal_4 = false; var currVal_5 = _co.context; var currVal_6 = _co.contextElement; var currVal_7 = _co.accentColor; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).indicatorWrapperBlock; var currVal_2 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ListActionsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.otherActionsDropdownHover$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.otherActionsDropdownHover$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"], ["style", "min-width: 220px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_6)), i0.ɵdid(5, 278528, null, 0, i18.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.otherActions; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ListActionsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.closeOtherActionsDropdown() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.closeOtherActionsDropdown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ListActionsComponent_5)), i0.ɵdid(2, 671744, [[1, 4]], 0, i10.CdkConnectedOverlay, [i10.Overlay, i0.TemplateRef, i0.ViewContainerRef, i10.ɵc, [2, i19.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["cdkOverlayOrigin", ""], ["class", "button button_icon list__actions-item"]], [[8, "style", 2], [2, "button_tint-color", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.otherActionsHover$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.otherActionsHover$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, [["other_actions_origin", 4]], 0, i10.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(6, 540672, null, 0, i11.ButtonTintColorDirective, [i12.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "button__icon icon-more"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5); var currVal_1 = _co.otherActionsDropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.otherActionsDropdownOpened; var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.accentColor; var currVal_11 = _co.tintStyles.Default; _ck(_v, 6, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_8 = i0.ɵnov(_v, 6).style; var currVal_9 = i0.ɵnov(_v, 6).cls; _ck(_v, 4, 0, currVal_8, currVal_9); }); }
function View_ListActionsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-actions-item", [], [[2, "element__indicator-wrapper", null], [2, "element__indicator-wrapper_block", null], [2, "hidden", null]], null, null, i13.View_ListActionsItemComponent_0, i13.RenderType_ListActionsItemComponent)), i0.ɵdid(1, 770048, null, 0, i14.ListActionsItemComponent, [i4.CustomizeService, i15.ActionService, i16.ActionControllerService, i17.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], pinned: [1, "pinned"], context: [2, "context"], contextElement: [3, "contextElement"], compact: [4, "compact"], accentColor: [5, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit; var currVal_4 = true; var currVal_5 = _co.context; var currVal_6 = _co.contextElement; var currVal_7 = _co.compactActions; var currVal_8 = _co.accentColor; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).indicatorWrapperBlock; var currVal_2 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ListActionsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i20.LocalizePipe, []), i0.ɵqud(671088640, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_1)), i0.ɵdid(3, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_2)), i0.ɵdid(5, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_3)), i0.ɵdid(7, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_4)), i0.ɵdid(9, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsComponent_7)), i0.ɵdid(11, 278528, null, 0, i18.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layouts; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.searchEnabled; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.filtersEnabled; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.otherActions.length; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.pinnedActions; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ListActionsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-actions", [], null, null, null, View_ListActionsComponent_0, RenderType_ListActionsComponent)), i0.ɵdid(1, 4964352, null, 0, i21.ListActionsComponent, [i22.UniversalAnalyticsService, i0.ChangeDetectorRef, i23.CurrentProjectStore, i24.CustomViewsStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListActionsComponentNgFactory = i0.ɵccf("app-list-actions", i21.ListActionsComponent, View_ListActionsComponent_Host_0, { listTitle: "listTitle", dataSource: "dataSource", queryParams: "queryParams", filtersEnabled: "filtersEnabled", filters: "filters", filterFields: "filterFields", layouts: "layouts", searchEnabled: "searchEnabled", search: "search", searchLive: "searchLive", inputs: "inputs", parameters: "parameters", focus: "focus", columns: "columns", actions: "actions", compactActions: "compactActions", context: "context", contextElement: "contextElement", accentColor: "accentColor" }, { layoutAdd: "layoutAdd", layoutCustomize: "layoutCustomize", paramsChanged: "paramsChanged", filtersUpdated: "filtersUpdated", searchChanged: "searchChanged" }, []);
export { ListActionsComponentNgFactory as ListActionsComponentNgFactory };

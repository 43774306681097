import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import isObject from 'lodash/isObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

export interface PropertyItemAction {
  label: any;
  icon?: any;
  handler?: () => Observable<any>;
}

export interface PropertyItem {
  id: string;
  label: string;
  icon?: string;
  labelIcon?: string;
  field?: FieldType;
  params?: Object;
  renameLabel?: any;
  renameHandler?: (value: string) => Observable<any>;
  editLabel?: any;
  editHandler?: () => void;
  actions?: PropertyItemAction[];
  deleteLabel?: any;
  deleteHandler?: () => Observable<any>;
}

@Component({
  selector: 'app-custom-page-properties-section-item',
  templateUrl: './custom-page-properties-section-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePropertiesSectionItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: PropertyItem;
  @Input() value: any;

  rename = false;
  renameLoading = false;
  renameControl = new FormControl();
  actionLoading = false;
  deleteLoading = false;
  valueDisplay: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomPagePropertiesSectionItemComponent>): void {
    if (changes.value) {
      this.valueDisplay = this.getValueDisplay();
    }
  }

  startRename() {
    this.renameControl.patchValue(this.item.label);
    this.rename = true;
    this.cd.markForCheck();
  }

  cancelRename() {
    this.rename = false;
    this.cd.markForCheck();
  }

  finishRename() {
    const value = this.renameControl.value.trim();

    if (!isSet(value) || this.item.label == value) {
      this.rename = false;
      this.cd.markForCheck();
      return;
    }

    this.renameLoading = true;
    this.cd.markForCheck();

    this.item
      .renameHandler(value)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.rename = false;
          this.renameLoading = false;
          this.item.label = value;
          this.cd.detectChanges();
        },
        () => {
          this.rename = false;
          this.renameLoading = false;
          this.cd.detectChanges();
        }
      );
  }

  onRenameKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishRename();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.cancelRename();
    }
  }

  editItem() {
    this.item.editHandler();
  }

  handleItemAction(action: PropertyItemAction) {
    this.actionLoading = true;
    this.cd.markForCheck();

    action
      .handler()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.actionLoading = false;
          this.cd.detectChanges();
        },
        () => {
          this.actionLoading = false;
          this.cd.detectChanges();
        }
      );
  }

  deleteItem() {
    this.deleteLoading = true;
    this.cd.markForCheck();

    this.item
      .deleteHandler()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.deleteLoading = false;
          this.cd.detectChanges();
        },
        () => {
          this.deleteLoading = false;
          this.cd.detectChanges();
        }
      );
  }

  getValueDisplay(): any {
    const fieldDescription = getFieldDescriptionByType(this.item.field);

    if (!isSet(this.value)) {
      return fieldDescription.label;
    }

    let value = this.value;

    if (fieldDescription.serializeValue) {
      value = fieldDescription.serializeValue(value, {
        name: this.item.label,
        field: this.item.field,
        params: this.item.params
      });
    }

    if (!isSet(value)) {
      return fieldDescription.label;
    }

    if (isObject(value)) {
      try {
        value = JSON.stringify(value);
      } catch (e) {}
    }

    return String(value).substring(0, 40);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize/data/view-context-element";
import * as i2 from "../../../customize/data/view-context";
import * as i3 from "./spacing-element.component";
var styles_SpacingElementComponent = [];
var RenderType_SpacingElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SpacingElementComponent, data: {} });
export { RenderType_SpacingElementComponent as RenderType_SpacingElementComponent };
export function View_SpacingElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "spacing-element"]], null, null, null, null, null))], null, null); }
export function View_SpacingElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-spacing-element", [], null, null, null, View_SpacingElementComponent_0, RenderType_SpacingElementComponent)), i0.ɵprd(135680, null, i1.ViewContextElement, i1.ViewContextElement, [i2.ViewContext]), i0.ɵdid(2, 49152, null, 0, i3.SpacingElementComponent, [], null, null)], null, null); }
var SpacingElementComponentNgFactory = i0.ɵccf("app-spacing-element", i3.SpacingElementComponent, View_SpacingElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { SpacingElementComponentNgFactory as SpacingElementComponentNgFactory };

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { ResizableModule } from '@common/resizable';
import { TextEditorModule } from '@common/text-editor';
import { TextareaAutosizeModule } from '@common/textarea-autosize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { SidebarModule } from '@modules/sidebar';
import { TokensComponentsModule } from '@modules/tokens-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { InlineInputEditComponent } from './components/inline-input-edit/inline-input-edit.component';
import { InputEditContextValueComponent } from './components/input-edit-context-value/input-edit-context-value.component';
import { InputEditFormulaValueComponent } from './components/input-edit-formula-value/input-edit-formula-value.component';
import { InputEditTextInputsValueComponent } from './components/input-edit-text-inputs-value/input-edit-text-inputs-value.component';
import { InputEditComponent } from './components/input-edit/input-edit.component';
import { InputOverlayComponent } from './components/input-overlay/input-overlay.component';
import { InputsEditItemComponent } from './components/inputs-edit-item/inputs-edit-item.component';
import { InputsEditContextTokensComponent } from './components/inputs-edit/inputs-edit-context-tokens/inputs-edit-context-tokens.component';
import { InputsEditComponent } from './components/inputs-edit/inputs-edit.component';
import { ParametersEditWithInputsItemComponent } from './components/parameters-edit-with-inputs-item/parameters-edit-with-inputs-item.component';
import { ParametersEditWithInputsComponent } from './components/parameters-edit-with-inputs/parameters-edit-with-inputs.component';
import { ViewContextTokenPopoverFunctionFormulaComponent } from './components/view-context-token-popover-function-formula/view-context-token-popover-function-formula.component';
import { ViewContextTokenPopoverFunctionComponent } from './components/view-context-token-popover-function/view-context-token-popover-function.component';
import { ViewContextTokenPopoverOverlayComponent } from './components/view-context-token-popover-overlay/view-context-token-popover-overlay.component';
import { ViewContextTokenPopoverSectionItemComponent } from './components/view-context-token-popover-section-item/view-context-token-popover-section-item.component';
import { ViewContextTokenPopoverSectionComponent } from './components/view-context-token-popover-section/view-context-token-popover-section.component';
import { ViewContextTokenPopoverStubComponent } from './components/view-context-token-popover-stub/view-context-token-popover-stub.component';
import { ViewContextTokenPopoverComponent } from './components/view-context-token-popover/view-context-token-popover.component';
import { InputOverlayDirective } from './directives/input-overlay/input-overlay.directive';
import { FormatFormulaDescriptionPipe } from './pipes/format-formula-description/format-formula-description.pipe';
import { FormulaTokenEqualPipe } from './pipes/formula-token-equal/formula-token-equal.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    OverlayModule,
    MatMenuModule,
    TokensComponentsModule,
    ObserversModule,
    FieldsModule,
    AnalyticsModule,
    TipsModule,
    TextFieldModule,
    FieldComponentsModule,
    TextareaAutosizeModule,
    ResizableModule,
    SharedModule,
    DragDropModule,
    TutorialModule,
    UiModule,
    TextEditorModule
  ],
  declarations: [
    InputsEditComponent,
    InputsEditContextTokensComponent,
    InputsEditItemComponent,
    InputEditComponent,
    ViewContextTokenPopoverComponent,
    ViewContextTokenPopoverStubComponent,
    ViewContextTokenPopoverOverlayComponent,
    ViewContextTokenPopoverSectionComponent,
    ViewContextTokenPopoverSectionItemComponent,
    ViewContextTokenPopoverFunctionComponent,
    FormatFormulaDescriptionPipe,
    InlineInputEditComponent,
    ParametersEditWithInputsComponent,
    ParametersEditWithInputsItemComponent,
    InputOverlayDirective,
    InputOverlayComponent,
    InputEditContextValueComponent,
    InputEditFormulaValueComponent,
    ViewContextTokenPopoverFunctionFormulaComponent,
    FormulaTokenEqualPipe,
    InputEditTextInputsValueComponent
  ],
  exports: [
    InputsEditComponent,
    InputsEditItemComponent,
    InputEditComponent,
    ParametersEditWithInputsComponent,
    ParametersEditWithInputsItemComponent,
    InputOverlayDirective,
    ViewContextTokenPopoverOverlayComponent,
    InputEditFormulaValueComponent
  ],
  entryComponents: [InputEditComponent, InlineInputEditComponent, InputOverlayComponent]
})
export class ParametersComponentsModule {}

<app-customize-toolbar></app-customize-toolbar>

<div class="customize-menu">
  <svg class="customize-menu__corner" height="6" viewBox="0 0 6 6" width="6" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m6 0c-3.23839694 0-5.87757176 2.56557489-5.99586153 5.77506174l-.00413847.22493826v-6z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>

  <div class="customize-menu__top">
    <ng-container *ngFor="let item of menuItems">
      <a
        *ngIf="!item.requireTaskQueues || (item.requireTaskQueues && hasTaskQueues)"
        href="javascript:void(0)"
        class="customize-menu__button"
        [class.customize-menu__button_active]="currentSection == item.section"
        appLongHover
        [longHoverInDelay]="dropdownSection$.value ? 100 : 0"
        [longHoverOutDelay]="100"
        [appTip]="!item.dropdown ? item.label : undefined"
        [appTipOptions]="{ side: 'right' }"
        (click)="onItemClick(item)"
        (longHoverIn)="onSectionMenuItemMouseEnter(item)"
        (longHoverOut)="onSectionMenuItemMouseLeave(item)"
      >
        <span class="customize-menu__button-inner" [ngClass]="'icon-' + item.icon"></span>
      </a>
    </ng-container>
  </div>

  <div class="customize-menu__bottom">
    <app-customize-toolbar-bottom-container></app-customize-toolbar-bottom-container>
  </div>
</div>

<div
  class="customize-menu__container theme_dark"
  [class.customize-menu__container_visible]="dropdownSection$ | async | appIsSet"
  (mouseenter)="menuItemDropdownHover$.next(true)"
  (mouseleave)="menuItemDropdownHover$.next(false)"
>
  <div
    class="customize-menu__container-content"
    [class.customize-menu__container-content_visible]="(dropdownSection$ | async) == menuCustomSection.Pages"
  >
    <app-customize-menu-pages
      *ngIf="(dropdownSection$ | async) == menuCustomSection.Pages"
      (startInteracting)="startInteracting(menuCustomSection.Pages)"
      (finishInteracting)="finishInteracting(menuCustomSection.Pages)"
      (closeMenu)="closeAll()"
    ></app-customize-menu-pages>
  </div>

  <div
    class="customize-menu__container-content"
    [class.customize-menu__container-content_visible]="(dropdownSection$ | async) == menuCustomSection.Data"
  >
    <app-customize-menu-resources
      *ngIf="(dropdownSection$ | async) == menuCustomSection.Data"
      (closeMenu)="closeAll()"
    ></app-customize-menu-resources>
  </div>

  <div
    class="customize-menu__container-content"
    [class.customize-menu__container-content_visible]="(dropdownSection$ | async) == menuCustomSection.Collaboration"
  >
    <app-customize-menu-queues
      *ngIf="(dropdownSection$ | async) == menuCustomSection.Collaboration"
      (closeMenu)="closeAll()"
    ></app-customize-menu-queues>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { localize } from '@common/localize';
import { ActionStore } from '@modules/action-queries';
import {
  ActionDescription,
  ActionItem,
  ActionType,
  ExportAction,
  LinkAction,
  SegueType,
  TintStyle,
  ViewSettingsAction
} from '@modules/actions';
import {
  CustomizeService,
  ListLayoutSettings,
  ListViewSettings,
  modelFieldItemToDisplayField,
  TableSettings
} from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { CHECKED_ITEMS_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource, ResourceType } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';

import { DataSourceGeneratorService } from '../data-source-generator/data-source-generator.service';

interface LayoutDefaultsParams {
  layout: ListLayoutType;
  columns: number;
  perPage?: number;
  // searchQueryField?: string;
  updateQueryField?: string;
  groupQueryField?: string;
  modelActions?: string;
  clickAction?: string;
}

@Injectable()
export class ViewSettingsGeneratorService {
  layoutSettings = [
    {
      layout: ListLayoutType.Table,
      // searchQueryField: 'searchQuery',
      updateQueryField: 'updateQuery',
      modelActions: 'modelActions',
      clickAction: 'rowClickAction',
      columns: 8,
      perPage: 20
    },
    {
      layout: ListLayoutType.Map,
      // searchQueryField: 'searchQuery',
      clickAction: 'cardClickAction',
      columns: 6
    },
    {
      layout: ListLayoutType.KanbanBoard,
      clickAction: 'cardClickAction',
      columns: 4
    },
    {
      layout: ListLayoutType.Calendar,
      // searchQueryField: 'searchQuery',
      groupQueryField: 'groupQuery',
      clickAction: 'cardClickAction',
      columns: 2
    },
    {
      layout: ListLayoutType.Grid,
      // searchQueryField: 'searchQuery',
      clickAction: 'cardClickAction',
      columns: 6
    },
    {
      layout: ListLayoutType.Carousel,
      // searchQueryField: 'searchQuery',
      clickAction: 'cardClickAction',
      columns: 4
    },
    {
      layout: ListLayoutType.Timeline,
      // searchQueryField: 'searchQuery',
      clickAction: 'cardClickAction',
      columns: 3
    }
  ];

  constructor(
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionStore: ActionStore,
    public customizeService: CustomizeService
  ) {}

  applyModelListLayoutDefaults(
    settings: ListLayoutSettings,
    modelDescription: ModelDescription,
    params: LayoutDefaultsParams
  ): ListLayoutSettings {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource);
    const resourceType = resource ? resource.type : undefined;

    if (settings.titleInput === undefined) {
      settings.titleInput = new Input().deserializeFromStatic('value', modelDescription.verboseNamePlural);
    }

    settings.dataSource = this.dataSourceGeneratorService.applyModelDataSourceDefaults<ListModelDescriptionDataSource>(
      ListModelDescriptionDataSource,
      settings.dataSource,
      modelDescription,
      { maxColumns: params.columns }
    ) as ListModelDescriptionDataSource;

    if (settings instanceof TableSettings) {
      const defaultColumnActions = this.dataSourceGeneratorService.getModelColumnActions(
        modelDescription,
        settings.dataSource.columns
      );

      settings.columnActions = [
        ...settings.columnActions,
        ...defaultColumnActions.filter(
          columnActions => !settings.columnActions.find(item => item.name == columnActions.name)
        )
      ];
    }

    // if (params.searchQueryField && resourceType == ResourceType.JetBridge) {
    //   if (!settings['searchResource']) {
    //     settings['searchResource'] = modelDescription.resource;
    //   }
    //
    //   settings[params.searchQueryField] = this.applyModelQueryDefaults(
    //     modelDescription,
    //     ListModelDescriptionQuery,
    //     settings[params.searchQueryField]
    //   );
    // }

    if (params.updateQueryField && resourceType == ResourceType.JetBridge) {
      if (!settings['updateResource']) {
        settings['updateResource'] = modelDescription.resource;
      }

      settings[params.updateQueryField] = this.dataSourceGeneratorService.applyModelQueryDefaults(
        modelDescription,
        ModelDescriptionQuery,
        settings[params.updateQueryField]
      );
    }

    if (params.groupQueryField && resourceType == ResourceType.JetBridge) {
      if (!settings['groupResource']) {
        settings['groupResource'] = modelDescription.resource;
      }

      settings[params.groupQueryField] = this.dataSourceGeneratorService.applyModelQueryDefaults(
        modelDescription,
        ModelDescriptionQuery,
        settings[params.groupQueryField]
      );
    }

    if (!settings.sortingField && modelDescription.defaultOrderBy && modelDescription.defaultOrderBy.length) {
      settings.sortingField = modelDescription.defaultOrderBy;

      if (settings.sortingField.startsWith('-')) {
        settings.sortingField = settings.sortingField.slice(1);
        settings.sortingAsc = false;
      } else {
        settings.sortingAsc = true;
      }
    }

    return settings;
  }

  addModelListLayoutDefaultActions(
    settings: ListLayoutSettings,
    resource: Resource,
    modelDescription: ModelDescription,
    params: LayoutDefaultsParams,
    legacyPages = false
  ): ListLayoutSettings {
    if (settings.actions) {
      this.getDefaultListActions(resource, modelDescription, legacyPages).forEach(item => {
        if (
          !settings.actions.find(i => {
            if (!i.protected || !i.actionDescription) {
              return false;
            }

            if (item.actionDescription && item.actionDescription.linkAction) {
              return (
                i.actionDescription.linkAction &&
                i.actionDescription.linkAction.type == item.actionDescription.linkAction.type
              );
            } else if (item.actionDescription && item.actionDescription.exportAction) {
              return (
                i.actionDescription.exportAction &&
                i.actionDescription.exportAction.getModelId() &&
                i.actionDescription.exportAction.getModelId() == item.actionDescription.exportAction.getModelId()
              );
            } else {
              return false;
            }
          })
        ) {
          settings.actions.push(item);
        }
      });
    }

    if (params.modelActions && settings[params.modelActions]) {
      this.getDefaultListModelActions(resource, modelDescription).forEach(item => {
        if (
          !settings[params.modelActions].find(i => {
            if (!i.protected || !i.actionDescription) {
              return false;
            }

            if (item.actionDescription && item.actionDescription.linkAction) {
              return (
                i.actionDescription.linkAction &&
                i.actionDescription.linkAction.type == item.actionDescription.linkAction.type
              );
            } else if (item.actionDescription && item.actionDescription.exportAction) {
              return (
                i.actionDescription.exportAction &&
                i.actionDescription.exportAction.getModelId() &&
                i.actionDescription.exportAction.getModelId() == item.actionDescription.exportAction.getModelId()
              );
            } else {
              return false;
            }
          })
        ) {
          settings[params.modelActions].push(item);
        }
      });
    }

    if (params.clickAction) {
      if (!settings[params.clickAction]) {
        const action = this.getDefaultListClickAction(modelDescription, legacyPages);

        if (action) {
          settings[params.clickAction] = action;
        }
      }
    }

    return settings;
  }

  getDefaultListActions(
    resource: Resource,
    modelDescription: ModelDescription,
    legacyPages = false
  ): ViewSettingsAction[] {
    return [
      ...(legacyPages
        ? [
            {
              label: localize('Create'),
              icon: 'plus',
              type: ActionType.Link,
              segueType: SegueType.ModelCreate,
              pinned: true,
              color: 'bright-blue',
              requireQuery: 'createQuery'
            }
          ]
        : []),
      {
        label: localize('Export'),
        icon: 'download',
        type: ActionType.Export,
        pinned: false
      }
      // {
      //   label: localize('Activity Log'),
      //   icon: 'book',
      //   type: ActionType.Link,
      //   segueType: SegueType.ModelActivityLog,
      //   pinned: false
      // }
    ]
      .map(item => {
        if (item.requireQuery && !modelDescription[item.requireQuery]) {
          return;
        }

        const actionDescription = new ActionDescription();
        const action = new ViewSettingsAction();

        actionDescription.verboseName = item.label;
        actionDescription.icon = item.icon;
        actionDescription.type = item.type;

        if (item.type == ActionType.Link) {
          actionDescription.linkAction = new LinkAction();
          actionDescription.linkAction.type = item.segueType;
          actionDescription.linkAction.model = modelDescription.modelId;
        } else if (item.type == ActionType.Export) {
          actionDescription.exportAction = new ExportAction();
          actionDescription.exportAction.dataSource = new ListModelDescriptionDataSource();
          actionDescription.exportAction.dataSource.type = DataSourceType.Query;
          actionDescription.exportAction.dataSource.queryResource = modelDescription.resource;
          actionDescription.exportAction.dataSource.query = new ListModelDescriptionQuery();
          actionDescription.exportAction.dataSource.query.queryType = QueryType.Simple;
          actionDescription.exportAction.dataSource.query.simpleQuery = new actionDescription.exportAction.dataSource.query.simpleQueryClass();
          actionDescription.exportAction.dataSource.query.simpleQuery.model = modelDescription.model;
          actionDescription.exportAction.dataSource.columns = modelDescription.dbFields
            .map(field => modelFieldItemToDisplayField(field))
            .map(field => prepareDataSourceColumnForGet(resource, modelDescription, field));
        }

        action.verboseNameInput = new Input().deserializeFromStatic('value', actionDescription.verboseName);
        action.icon = item.icon;
        action.actionDescription = actionDescription;
        action.pinned = item.pinned;
        action.style = TintStyle.Primary;
        action.tint = item.color;

        return action;
      })
      .filter(item => item != undefined);
  }

  getDefaultListModelActions(resource: Resource, modelDescription: ModelDescription): ViewSettingsAction[] {
    return [
      // {
      //   label: localize('Duplicate'),
      //   icon: 'duplicate_2',
      //   type: ActionType.Link,
      //   segueType: SegueType.ModelCreate,
      //   contextOutput: CHECKED_ITEMS_OUTPUT,
      //   parameterName: '_duplicate',
      //   parameterVerboseName: 'Duplicate Record',
      //   requireQuery: 'updateQuery'
      // },
      // {
      //   label: localize('Mass Edit'),
      //   icon: 'pen',
      //   type: ActionType.Link,
      //   segueType: SegueType.ModelMassEdit,
      //   contextOutput: CHECKED_ITEMS_OUTPUT,
      //   parameterName: 'ids',
      //   parameterVerboseName: 'Record',
      //   requireQuery: 'updateQuery'
      // },
      {
        label: localize('Export'),
        icon: 'download',
        type: ActionType.Export,
        segueType: undefined,
        contextOutput: CHECKED_ITEMS_OUTPUT,
        // parameterName: 'ids',
        parameterName: modelDescription.primaryKeyField,
        parameterVerboseName: 'Record'
      },
      {
        label: localize('Delete'),
        icon: 'bin',
        type: ActionType.Query,
        contextOutput: CHECKED_ITEMS_OUTPUT,
        parameterName: modelDescription.primaryKeyField,
        parameterVerboseName: 'Record',
        requireQuery: 'deleteQuery'
      }
    ]
      .map(item => {
        if (item.requireQuery && !modelDescription[item.requireQuery]) {
          return;
        }

        const actionDescription = new ActionDescription();
        const input = new Input();
        const action = new ViewSettingsAction();

        input.name = item.parameterName;
        input.valueType = InputValueType.Context;
        input.contextValue = [item.contextOutput, modelDescription.primaryKeyField];

        let inputs = [input];

        actionDescription.verboseName = item.label;
        actionDescription.icon = item.icon;
        actionDescription.type = item.type;

        if (item.type == ActionType.Link) {
          actionDescription.linkAction = new LinkAction();
          actionDescription.linkAction.type = item.segueType;
          actionDescription.linkAction.model = modelDescription.modelId;

          action.actionDescription = actionDescription;
        } else if (item.type == ActionType.Export) {
          actionDescription.exportAction = new ExportAction();
          actionDescription.exportAction.dataSource = new ListModelDescriptionDataSource();
          actionDescription.exportAction.dataSource.type = DataSourceType.Query;
          actionDescription.exportAction.dataSource.queryResource = modelDescription.resource;
          actionDescription.exportAction.dataSource.query = new ListModelDescriptionQuery();
          actionDescription.exportAction.dataSource.query.queryType = QueryType.Simple;
          actionDescription.exportAction.dataSource.query.simpleQuery = new actionDescription.exportAction.dataSource.query.simpleQueryClass();
          actionDescription.exportAction.dataSource.query.simpleQuery.model = modelDescription.model;
          actionDescription.exportAction.dataSource.columns = modelDescription.dbFields
            .map(field => modelFieldItemToDisplayField(field))
            .map(field => prepareDataSourceColumnForGet(resource, modelDescription, field));

          action.actionDescription = actionDescription;
        } else if (item.type == ActionType.Query) {
          const actionId = [modelDescription.resource, modelDescription.autoActionUniqueName('delete')].join('.');

          action.sharedActionDescription = actionId;

          const sharedAction = this.actionStore.instance.find(i => i.id == actionId);

          if (sharedAction && sharedAction.actionParams) {
            inputs = sharedAction.actionParams.map(parameter => {
              const instance = new Input();

              instance.name = parameter.name;

              if (modelDescription.dbFields.find(i => i.name == parameter.name)) {
                instance.valueType = InputValueType.Context;
                instance.contextValue = [item.contextOutput, parameter.name];
              } else {
                instance.valueType = InputValueType.Prompt;
              }

              return instance;
            });
          }
        }

        action.verboseNameInput = new Input().deserializeFromStatic('value', actionDescription.verboseName);
        action.icon = item.icon;
        action.inputs = inputs;

        return action;
      })
      .filter(item => item != undefined);
  }

  getDefaultListClickAction(modelDescription: ModelDescription, legacyPages = false): ActionItem {
    if (!modelDescription.getDetailQuery && !modelDescription.getQuery) {
      return;
    }

    if (!legacyPages) {
      return;
    }

    const actionDescription = new ActionDescription();
    const input = new Input();
    const action = new ActionItem();

    actionDescription.verboseName = 'Open';
    actionDescription.icon = 'eye';
    actionDescription.type = ActionType.Link;
    actionDescription.linkAction = new LinkAction();
    actionDescription.linkAction.type = SegueType.ModelChange;
    actionDescription.linkAction.model = modelDescription.modelId;

    input.name = 'id';
    input.valueType = InputValueType.Context;
    input.contextValue = [SELECTED_ITEM_OUTPUT, modelDescription.primaryKeyField];

    action.verboseNameInput = new Input().deserializeFromStatic('value', actionDescription.verboseName);
    action.actionDescription = actionDescription;
    action.inputs = [input];

    return action;
  }

  applyListLayoutDefaults(layout: ListLayoutSettings): Observable<ListLayoutSettings> {
    const layoutSettings = this.layoutSettings.find(item => item.layout == layout.type);

    if (
      !layoutSettings ||
      !layout.dataSource ||
      layout.dataSource.type != DataSourceType.Query ||
      !layout.dataSource.query ||
      layout.dataSource.query.queryType != QueryType.Simple ||
      !layout.dataSource.query.simpleQuery
    ) {
      return of(layout);
    }

    const modelId = [layout.dataSource.queryResource, layout.dataSource.query.simpleQuery.model].join('.');

    return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
      map(modelDescription => {
        if (!modelDescription) {
          return layout;
        }

        return this.applyModelListLayoutDefaults(layout, modelDescription, layoutSettings);
      })
    );
  }

  applyModelListDefaults(
    layouts: ListLayoutSettings[],
    resource: Resource,
    modelDescription: ModelDescription,
    legacyPages = false
  ) {
    return layouts.map(layout => {
      const layoutSettings = this.layoutSettings.find(item => item.layout == layout.type);

      if (!layoutSettings) {
        return layout;
      }

      if (
        !layout.dataSource ||
        layout.dataSource.type != DataSourceType.Query ||
        !layout.dataSource.query ||
        layout.dataSource.query.queryType == QueryType.Simple
      ) {
        layout = this.applyModelListLayoutDefaults(layout, modelDescription, layoutSettings);
        layout = this.addModelListLayoutDefaultActions(layout, resource, modelDescription, layoutSettings, legacyPages);
        return layout;
      } else {
        return layout;
      }
    });
  }

  applyListDefaults(viewSettings: ListViewSettings) {
    if (!viewSettings.layouts.length) {
      viewSettings.layouts = [new TableSettings()];
    }

    return viewSettings;
  }
}

import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';

export class ManualAutomationTrigger extends AutomationTrigger {
  type = AutomationTriggerType.Manual;

  deserialize(data: Object): this {
    return this;
  }

  serialize() {
    return {};
  }
}

registerAutomationTriggerForType(AutomationTriggerType.Manual, ManualAutomationTrigger);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { ProjectPermissionType } from '@modules/projects';
import { ProjectPermissionControl } from './project-permission.control';
var ProjectPermissionsControl = /** @class */ (function (_super) {
    __extends(ProjectPermissionsControl, _super);
    function ProjectPermissionsControl() {
        return _super.call(this, {
            project_settings: new ProjectPermissionControl({
                type: ProjectPermissionType.Project,
                object: 'project_settings'
            }),
            project_billing: new ProjectPermissionControl({ type: ProjectPermissionType.Project, object: 'project_billing' }),
            project_access: new ProjectPermissionControl({ type: ProjectPermissionType.Project, object: 'project_access' }),
            project_customization: new ProjectPermissionControl({
                type: ProjectPermissionType.Project,
                object: 'project_customization'
            })
        }) || this;
    }
    ProjectPermissionsControl.prototype.deserialize = function (permissions) {
        toPairs(this.controls).forEach(function (_a) {
            var name = _a[0], control = _a[1];
            var permission = permissions.find(function (item) { return item.permissionType == control.value.type && item.permissionObject == name; });
            control.deserialize(permission);
        });
    };
    ProjectPermissionsControl.prototype.serialize = function () {
        return values(this.controls)
            .filter(function (control) { return control.value.enabled; })
            .map(function (control) { return control.serialize(); });
    };
    ProjectPermissionsControl.prototype.setEveryControl = function (value) {
        this.controls.project_settings.patchValue({
            type: ProjectPermissionType.Project,
            object: 'project_settings',
            enabled: value
        });
        this.controls.project_billing.patchValue({
            type: ProjectPermissionType.Project,
            object: 'project_billing',
            enabled: value
        });
        this.controls.project_access.patchValue({
            type: ProjectPermissionType.Project,
            object: 'project_access',
            enabled: value
        });
        this.controls.project_customization.patchValue({
            type: ProjectPermissionType.Project,
            object: 'project_customization',
            enabled: value
        });
    };
    return ProjectPermissionsControl;
}(FormGroup));
export { ProjectPermissionsControl };

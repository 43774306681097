import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collaboration-tasks-stub',
  templateUrl: './collaboration-tasks-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationTasksStubComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

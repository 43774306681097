import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, share } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { FieldType, ParameterValue, ParameterValueArray } from '@modules/fields';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  jetAppResource,
  jetAppStorage,
  ProjectGroupService,
  socialBackends
} from '@modules/projects';
import {
  createSSOSettings,
  CustomSSOSettings,
  JetBridgeSSOSettings,
  OAuth2SSOSettings,
  SAML2SSOSettings,
  SharedCustomSSOType,
  SSOSettings,
  SSOSettingsService,
  SSOType
} from '@modules/sso';
import { Workflow } from '@modules/workflow';
import { isSet } from '@shared';

export class OAuth2SSOForm extends FormGroup {
  controls: {
    oauth_params: FormControl;
    user_details_workflow: FormControl;
  };

  constructor() {
    super({
      oauth_params: new FormControl({}, Validators.required),
      user_details_workflow: new FormControl(undefined)
    });
  }
}

export class SAML2SSOForm extends FormGroup {
  controls: {
    metadata: FormControl;
    entity_id: FormControl;
  };

  constructor() {
    super({
      metadata: new FormControl('', Validators.required),
      entity_id: new FormControl('', Validators.required)
    });
  }
}

export class CustomSSOForm extends FormGroup {
  controls: {
    shared_custom_sso: FormControl;
    parameters: ParameterValueArray;
    auth_workflow: FormControl;
    refresh_token_workflow: FormControl;
  };

  constructor() {
    super({
      shared_custom_sso: new FormControl(undefined),
      parameters: new ParameterValueArray([]),
      auth_workflow: new FormControl(undefined, Validators.required),
      refresh_token_workflow: new FormControl(undefined)
    });
  }
}

export class JetBridgeSSOForm extends FormGroup {
  controls: {
    url: FormControl;
  };

  constructor() {
    super({
      url: new FormControl({}, Validators.required)
    });
  }
}

@Injectable()
export class ProjectSSOItemForm extends FormGroup implements OnDestroy {
  controls: {
    name: FormControl;
    image: FormControl;
    type: FormControl;
    default_group: FormControl;
    oauth2: OAuth2SSOForm;
    saml2: SAML2SSOForm;
    custom: CustomSSOForm;
    jet_bridge: JetBridgeSSOForm;
  };

  instance: SSOSettings;

  typeOptions = [
    { value: SSOType.SAML2, name: 'SAML 2.0' },
    { value: SSOType.OAuth2, name: 'OAuth 2.0' },
    { value: SSOType.Custom, name: 'Custom authentication' },
    { value: SSOType.JetBridge, name: 'Jet Bridge' }
  ];

  storageResource = jetAppResource;
  storage = jetAppStorage;

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private ssoSettingsService: SSOSettingsService,
    private projectGroupService: ProjectGroupService
  ) {
    super({
      name: new FormControl('', Validators.required),
      image: new FormControl(''),
      type: new FormControl('', Validators.required),
      default_group: new FormControl(null, Validators.required),
      oauth2: new OAuth2SSOForm(),
      custom: new CustomSSOForm(),
      saml2: new SAML2SSOForm(),
      jet_bridge: new JetBridgeSSOForm()
    });
  }

  ngOnDestroy(): void {}

  init(options: { instance?: SSOSettings; initialValue?: Partial<SSOSettings> } = {}) {
    this.instance = options.instance;

    if (options.instance) {
      this.controls.name.patchValue(options.instance.name);
      this.controls.image.patchValue(options.instance.image);
      this.controls.type.patchValue(options.instance.type);

      const project = this.currentProjectStore.instance;
      const environment = this.currentProjectStore.instance.defaultEnvironment;

      this.projectGroupService
        .get(project.uniqueName, environment ? environment.uniqueName : undefined)
        .pipe(untilDestroyed(this))
        .subscribe(groups => {
          const group = groups ? groups.find(item => item.uid == options.instance.defaultGroup) : null;
          this.controls.default_group.patchValue(group);
        });

      if (options.instance.type == SSOType.SAML2 && options.instance instanceof SAML2SSOSettings) {
        this.controls.saml2.controls.metadata.patchValue(options.instance.metadata);
        this.controls.saml2.controls.entity_id.patchValue(options.instance.entityId);
      } else if (options.instance.type == SSOType.OAuth2 && options.instance instanceof OAuth2SSOSettings) {
        // TODO: Workaround for circular import
        options.instance.userDetailsWorkflow = options.instance.userDetailsWorkflowData
          ? new Workflow().deserialize(options.instance.userDetailsWorkflowData)
          : undefined;

        this.controls.oauth2.controls.oauth_params.patchValue({
          ...options.instance.publicParams,
          ...options.instance.params
        });
        this.controls.oauth2.controls.user_details_workflow.patchValue(options.instance.userDetailsWorkflow);
      } else if (options.instance.type == SSOType.Custom && options.instance instanceof CustomSSOSettings) {
        this.controls.custom.controls.shared_custom_sso.patchValue(options.instance.sharedCustomSSO);

        if (options.instance.sharedCustomSSO == SharedCustomSSOType.Firebase) {
          const forceParameters: Partial<ParameterValue>[] = [
            {
              name: 'Web API Key',
              field: FieldType.Text,
              required: true
            }
          ];
          const existingParameters = options.instance.parameters;
          const parameters = forceParameters.map(forceParameter => {
            const existing = existingParameters.find(item => item.name == forceParameter.name);
            if (existing) {
              return existing;
            } else {
              return new ParameterValue(forceParameter);
            }
          });

          this.controls.custom.controls.parameters.patchValue(parameters);
        } else if (options.instance.sharedCustomSSO == SharedCustomSSOType.Supabase) {
          const forceParameters: Partial<ParameterValue>[] = [
            {
              name: 'Reference ID',
              field: FieldType.Text,
              required: true
            },
            {
              name: 'API key',
              field: FieldType.Text,
              required: true
            }
          ];
          const existingParameters = options.instance.parameters;
          const parameters = forceParameters.map(forceParameter => {
            const existing = existingParameters.find(item => item.name == forceParameter.name);
            if (existing) {
              return existing;
            } else {
              return new ParameterValue(forceParameter);
            }
          });

          this.controls.custom.controls.parameters.patchValue(parameters);
        } else {
          this.controls.custom.controls.parameters.patchValue(options.instance.parameters);
        }

        // TODO: Workaround for circular import
        options.instance.authWorkflow = options.instance.authWorkflowData
          ? new Workflow().deserialize(options.instance.authWorkflowData)
          : undefined;
        options.instance.refreshTokenWorkflow = options.instance.refreshTokenWorkflowData
          ? new Workflow().deserialize(options.instance.refreshTokenWorkflowData)
          : undefined;

        this.controls.custom.controls.auth_workflow.patchValue(options.instance.authWorkflow);
        this.controls.custom.controls.refresh_token_workflow.patchValue(options.instance.refreshTokenWorkflow);
      } else if (options.instance.type == SSOType.JetBridge && options.instance instanceof JetBridgeSSOSettings) {
        this.controls.jet_bridge.controls.url.patchValue(options.instance.url);
      }
    } else if (options.initialValue) {
      if (isSet(options.initialValue.name)) {
        this.controls.name.patchValue(options.initialValue.name);
      }

      if (isSet(options.initialValue.type)) {
        this.controls.type.patchValue(options.initialValue.type);
      }

      if (options.initialValue.type == SSOType.OAuth2 && isSet(options.initialValue.params['backend'])) {
        this.controls.oauth2.controls.oauth_params.patchValue({
          backend: options.initialValue.params['backend']
        });
      }
    }

    this.markAsPristine();
  }

  isValid(): boolean {
    return (
      [this.controls.name, this.controls.type, this.controls.default_group].every(item => item.valid) &&
      (this.controls.type.value != SSOType.SAML2 || this.controls.saml2.valid) &&
      (this.controls.type.value != SSOType.OAuth2 || this.controls.oauth2.valid) &&
      (this.controls.type.value != SSOType.JetBridge || this.controls.jet_bridge.valid)
    );
  }

  submit(): Observable<SSOSettings> {
    this.markAsDirty();

    const instance = this.instance ? cloneDeep(this.instance) : createSSOSettings(this.controls.type.value);
    const project = this.currentProjectStore.instance;
    const fields = ['domain', 'name', 'type', 'default_group', 'public_params'];

    instance.domain = project && isSet(project.domain) ? project.domain.domain : undefined;
    instance.name = this.controls.name.value;
    instance.image = this.controls.image.value;
    instance.type = this.controls.type.value;
    instance.defaultGroup = this.controls.default_group.value ? this.controls.default_group.value.uid : null;
    instance.publicParams = {};

    if (instance.type == SSOType.OAuth2) {
      instance.params = {};
      fields.push('params');
    } else if (instance.type == SSOType.JetBridge) {
      instance.params = {};
      fields.push('params');
    }

    if (this.instance) {
      instance.uid = this.instance.uid;

      if (instance.type == SSOType.SAML2 && instance instanceof SAML2SSOSettings) {
        instance.entityId = this.controls.saml2.controls.entity_id.value;
        fields.push('entity_id');

        if (this.controls.saml2.controls.metadata.value instanceof File) {
          instance.metadataFile = this.controls.saml2.controls.metadata.value;
          fields.push('metadata');
        }
      } else if (instance.type == SSOType.OAuth2 && instance instanceof OAuth2SSOSettings) {
        const params = this.controls.oauth2.controls.oauth_params.value;
        const publicParams = ['backend'];

        if (params['backend']) {
          const backend = socialBackends.find(item => item.name == params['backend']);
          params['backend_path'] = backend ? backend.path : undefined;
        }

        instance.workflowsProject = this.currentProjectStore.instance.uniqueName;
        instance.workflowsEnvironment = this.currentEnvironmentStore.instance.uniqueName;
        instance.userDetailsWorkflow = this.controls.oauth2.controls.user_details_workflow.value;
        instance.publicParams = {
          ...instance.publicParams,
          ...pickBy(params, (v, k) => publicParams.includes(k))
        };
        instance.params = {
          ...instance.params,
          ...pickBy(params, (v, k) => !publicParams.includes(k))
        };
        fields.push('workflows_project');
        fields.push('workflows_environment');
        fields.push('user_details_workflow');
      } else if (instance.type == SSOType.Custom && instance instanceof CustomSSOSettings) {
        instance.workflowsProject = this.currentProjectStore.instance.uniqueName;
        instance.workflowsEnvironment = this.currentEnvironmentStore.instance.uniqueName;
        instance.sharedCustomSSO = this.controls.custom.controls.shared_custom_sso.value;
        instance.parameters = this.controls.custom.controls.parameters.value;
        instance.authWorkflow = this.controls.custom.controls.auth_workflow.value;
        instance.refreshTokenWorkflow = this.controls.custom.controls.refresh_token_workflow.value;
        fields.push('workflows_project');
        fields.push('workflows_environment');
        fields.push('shared_custom_sso');
        fields.push('parameters');
        fields.push('auth_workflow');
        fields.push('refresh_token_workflow');
      } else if (instance.type == SSOType.JetBridge && instance instanceof JetBridgeSSOSettings) {
        instance.url = this.controls.jet_bridge.controls.url.value;
        fields.push('url');
      }
    }

    let obs: Observable<SSOSettings>;

    if (this.instance) {
      obs = this.ssoSettingsService.update(this.currentProjectStore.instance.uniqueName, instance, fields);
    } else {
      obs = this.ssoSettingsService.create(this.currentProjectStore.instance.uniqueName, instance, fields);
    }

    return obs.pipe(
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      }),
      share()
    );
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup2-header',
  templateUrl: './popup2-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2HeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

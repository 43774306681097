import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import {
  createFormFieldFactory,
  FieldType,
  GeographyOutputFormat,
  registerFieldDataParamsComponent
} from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { GeographyFieldDataParamsForm } from './geography-field-data-params.form';

@Component({
  selector: 'app-geography-field-data-params',
  templateUrl: './geography-field-data-params.component.html',
  providers: [GeographyFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeographyFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  geographyOutputFormats = GeographyOutputFormat;

  constructor(public form: GeographyFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.Location, GeographyFieldDataParamsComponent);

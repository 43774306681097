import { Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';

import { nodeListToArray } from '@shared';

@Directive({
  selector: '[appElementGroupsContainer]',
  exportAs: 'appElementGroupsContainer'
})
export class ElementGroupsContainerDirective implements OnDestroy {
  constructor(private zone: NgZone, private el: ElementRef) {}

  ngOnDestroy(): void {}

  public updateElementStatesOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateElementStates());
  }

  public updateElementStates() {
    const elements = nodeListToArray<HTMLElement>(
      this.el.nativeElement.querySelectorAll(':scope > app-auto-element, :scope > .element-placeholder')
    ).filter(item => !item.hasAttribute('element-hidden'));
    const isGroupable = (element: HTMLElement) => {
      return (
        element &&
        ((element.tagName == 'APP-AUTO-ELEMENT' && element.hasAttribute('element-groupable')) ||
          (element.classList.contains('element-placeholder') &&
            element.children[0] &&
            element.children[0].hasAttribute('element-groupable')))
      );
    };
    const setGroup = (element, position) => {
      const updatePosition = name => {
        if (position == name) {
          element.setAttribute(`element-group-${name}`, 'true');
        } else {
          element.removeAttribute(`element-group-${name}`);
        }
      };

      updatePosition('start');
      updatePosition('inside');
      updatePosition('end');
    };

    for (let i = 0; i < elements.length; ++i) {
      const element = elements[i];
      const prevElement = elements[i - 1];
      const nextElement = elements[i + 1];

      if (isGroupable(element)) {
        if (!isGroupable(prevElement) && isGroupable(nextElement)) {
          setGroup(element, 'start');
        } else if (isGroupable(prevElement) && isGroupable(nextElement)) {
          setGroup(element, 'inside');
        } else if (isGroupable(prevElement) && !isGroupable(nextElement)) {
          setGroup(element, 'end');
        } else {
          setGroup(element, null);
        }
      } else {
        setGroup(element, null);
      }
    }
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PopoverService } from '@common/popover';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { isSet } from '@shared';
import { FieldsEditItemComponent } from '../fields-edit-item/fields-edit-item.component';
import { FieldsEditForm } from './fields-edit.form';
var FieldsEditComponent = /** @class */ (function () {
    function FieldsEditComponent(form, analyticsService, popoverService, injector, cd) {
        this.form = form;
        this.analyticsService = analyticsService;
        this.popoverService = popoverService;
        this.injector = injector;
        this.cd = cd;
        this.configurable = {};
        this.buttons = [];
        this.addLabel = 'Add Input';
        this.addDefaults = {};
        this.addBaseName = 'param';
        this.createField = createFormFieldFactory();
        this.createNewItemIfEmpty = false;
        this.formOptions = {};
        this.hovered$ = new BehaviorSubject(undefined);
        this.sendParametersAnalytics = true;
    }
    FieldsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.configurable = this.configurable;
        this.form.serializeItem = this.serializeItem;
        this.form.deserializeItem = this.deserializeItem;
        this.form.init(this.control, this.createNewItemIfEmpty, this.formOptions);
        this.form.formUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        if (this.sendParametersAnalytics) {
            this.form.form.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(function (value) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
                    Object: _this.object
                });
            });
        }
    };
    FieldsEditComponent.prototype.ngOnDestroy = function () { };
    FieldsEditComponent.prototype.openItemSettings = function (form, sourceElement) {
        var _this = this;
        this.popoverService.create({
            component: FieldsEditItemComponent,
            inputs: {
                form: form,
                configurable: {
                    name: true,
                    editable: this.configurable.editable,
                    params: this.configurable.action,
                    required: true
                }
            },
            outputs: {
                deleteRequested: [
                    function () {
                        _this.deleteItem(form);
                    }
                ]
            },
            injector: this.injector
        }, sourceElement);
    };
    FieldsEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.form.controls, event.previousIndex, event.currentIndex);
            this.form.form.updateValueAndValidity();
        }
    };
    FieldsEditComponent.prototype.deleteItem = function (form) {
        this.form.arrayDelete(form);
        if (this.sendParametersAnalytics) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
                Object: this.object
            });
        }
    };
    FieldsEditComponent.prototype.getDistinctName = function (baseName, template, startIndex) {
        if (template === void 0) { template = function (n, i) { return n + "_" + i; }; }
        if (startIndex === void 0) { startIndex = 1; }
        var names = this.form.form.controls.map(function (item) {
            var value = item.value['name'];
            return isSet(value) ? value : '';
        });
        var name;
        var index = startIndex;
        do {
            name = template(baseName, index);
            ++index;
        } while (names.find(function (item) { return item.toLowerCase() == name.toLowerCase(); }));
        return name;
    };
    FieldsEditComponent.prototype.addItem = function () {
        var name = this.getDistinctName(this.addBaseName);
        this.form.arrayAppend(this.form.createItem(__assign({ name: name }, this.addDefaults)));
        if (this.sendParametersAnalytics) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
                Object: this.object
            });
        }
    };
    return FieldsEditComponent;
}());
export { FieldsEditComponent };

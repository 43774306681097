/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/pipes/is-color-hex/is-color-hex.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./background-layout.component";
var styles_BackgroundLayoutComponent = [];
var RenderType_BackgroundLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackgroundLayoutComponent, data: {} });
export { RenderType_BackgroundLayoutComponent as RenderType_BackgroundLayoutComponent };
export function View_BackgroundLayoutComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsColorHexPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "background-layout"]], [[2, "background-layout_fill-screen", null], [2, "background-layout_preview", null], [4, "background-color", null]], null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(3, 1), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "background-layout__content"]], null, null, null, null, null)), i0.ɵncd(null, 0), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "background-layout"; var currVal_4 = ((_co.backgroundColor && !i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.backgroundColor))) ? (("background-color_" + _co.backgroundColor) + "_1") : ""); _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fillScreen; var currVal_1 = _co.preview; var currVal_2 = (i0.ɵunv(_v, 1, 2, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.backgroundColor)) ? _co.backgroundColor : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_BackgroundLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-background-layout", [], null, null, null, View_BackgroundLayoutComponent_0, RenderType_BackgroundLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i3.BackgroundLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundLayoutComponentNgFactory = i0.ɵccf("app-background-layout", i3.BackgroundLayoutComponent, View_BackgroundLayoutComponent_Host_0, { backgroundColor: "backgroundColor", fillScreen: "fillScreen", preview: "preview" }, {}, ["*", "[data-layout-footer]"]);
export { BackgroundLayoutComponentNgFactory as BackgroundLayoutComponentNgFactory };

import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { WidgetType } from '../widget-type';
var Widget = /** @class */ (function () {
    function Widget() {
    }
    Widget.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.type = data['widget_type'];
        this.x = data['x'];
        this.y = data['y'];
        this.width = data['width'];
        this.height = data['height'];
        this.tooltip = data['tooltip'];
        this.params = JSON.parse(data['params']);
        this.dateAdd = data['date_add'];
        this.dashboard = data['dashboard'];
        if (this.params['name_input']) {
            this.nameInput = new Input().deserialize(this.params['name_input']);
        }
        else if (data['name']) {
            // Backward compatibility
            this.nameInput = new Input().deserializeFromStatic('value', data['name']);
        }
        return this;
    };
    Widget.prototype.serialize = function (fields) {
        var data = {
            id: this.id,
            widget_type: this.type,
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            tooltip: this.tooltip,
            params: JSON.stringify(this.params),
            dashboard: this.dashboard ? this.dashboard : undefined
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Widget.prototype, "typeStr", {
        get: function () {
            if (this.type == WidgetType.Chart) {
                return 'Chart';
            }
            else if (this.type == WidgetType.Value) {
                return 'Single Value';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Widget.prototype, "analyticsName", {
        get: function () {
            if (this.type == WidgetType.Chart) {
                return 'chart';
            }
            else if (this.type == WidgetType.Value) {
                return 'single_value';
            }
        },
        enumerable: true,
        configurable: true
    });
    Widget.prototype.isReloadDataSupported = function () {
        return [WidgetType.Value, WidgetType.Chart].includes(this.type);
    };
    Object.defineProperty(Widget.prototype, "analyticsGenericName", {
        get: function () {
            if (!this.analyticsName) {
                return;
            }
            return ['component', this.analyticsName].join('_');
        },
        enumerable: true,
        configurable: true
    });
    return Widget;
}());
export { Widget };

<div class="sidebar__header2">
  <div class="sidebar-header2">
    <div class="sidebar-header2__top">
      <div class="sidebar-header2__top-main">
        <div class="sidebar-header2-button sidebar-header2__top-item" (click)="close.emit()">
          <span class="sidebar-header2-button__icon sidebar-header2-button__icon_left icon-close"></span>
          <span class="sidebar-header2-button__label">Close</span>
        </div>
      </div>
      <div class="sidebar-header2__top-right">
        <div
          *ngIf="executeEnabled"
          class="sidebar-header2-button sidebar-header2-button_orange sidebar-header2__top-item"
          [class.sidebar-header2-button_disabled]="executeLoading"
          (click)="execute.emit()"
        >
          <app-loader-small
            *ngIf="executeLoading"
            class="sidebar-header2-button__icon sidebar-header2-button__icon_left"
            style="font-size: 13px;"
          ></app-loader-small>
          <span
            *ngIf="!executeLoading"
            class="sidebar-header2-button__icon sidebar-header2-button__icon_left icon-play"
          ></span>
          <span class="sidebar-header2-button__label">Test Step</span>
        </div>

        <div
          *ngIf="deleteEnabled"
          class="sidebar-header2-button sidebar-header2__top-item"
          [appTip]="'Delete Step'"
          [appTipOptions]="{ side: 'bottom' }"
          (click)="delete.emit()"
        >
          <span class="sidebar-header2-button__icon icon-bin"></span>
        </div>
      </div>
    </div>

    <div *ngIf="nameControl" class="sidebar-header2__title sidebar-header2__title_editable">
      <app-editable-content
        [value]="nameControl.value"
        [cleanValue]="titleCleanValue"
        (valueChange)="nameControl.patchValue($event)"
        [pen]="true"
        [penHover]="true"
        [penTitle]="'Rename step'"
      ></app-editable-content>
    </div>

    <div *ngIf="!nameControl" class="sidebar-header2__title">
      {{ nameValue }}
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { CustomViewTemplate } from '../../data/custom-view-template';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
export var CustomViewTemplateMetricType;
(function (CustomViewTemplateMetricType) {
    CustomViewTemplateMetricType["View"] = "view";
    CustomViewTemplateMetricType["Usage"] = "usage";
})(CustomViewTemplateMetricType || (CustomViewTemplateMetricType = {}));
var CustomViewTemplateService = /** @class */ (function () {
    function CustomViewTemplateService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    CustomViewTemplateService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('custom_view_templates/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new CustomViewTemplateService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.getDetail = function (id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new CustomViewTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.create = function (instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/");
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new CustomViewTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.update = function (instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/" + instance.id + "/");
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new CustomViewTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.delete = function (instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/" + instance.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.rate = function (id, rating) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/" + id + "/rate/");
            var data = { rating: rating };
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) {
            return {
                newRating: result.new_rating
            };
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.prototype.metric = function (id, type) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("custom_view_templates/" + id + "/metric/");
            var data = { type: type };
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.success; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CustomViewTemplateService.ngInjectableDef = i0.defineInjectable({ factory: function CustomViewTemplateService_Factory() { return new CustomViewTemplateService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: CustomViewTemplateService, providedIn: "root" });
    return CustomViewTemplateService;
}());
export { CustomViewTemplateService };
(function (CustomViewTemplateService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
            this.perPage = 20;
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new CustomViewTemplate().deserialize(item); });
            this.hasMore = !!data['next'];
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        Object.defineProperty(GetResponse.prototype, "numPages", {
            get: function () {
                return Math.ceil(this.count / this.perPage);
            },
            enumerable: true,
            configurable: true
        });
        return GetResponse;
    }());
    CustomViewTemplateService.GetResponse = GetResponse;
})(CustomViewTemplateService || (CustomViewTemplateService = {}));

<app-sidebar-field
  [label]="label"
  [required]="required"
  [additional]="additional"
  [documentation]="documentation"
  [documentationLabel]="documentationLabel"
  [documentationSource]="documentationSource"
  [classes]="labelClasses"
>
  <ng-container *ngIf="!documentation" actions>
    <span
      class="sidebar__field-action sidebar__field-action_button icon-function"
      [class.sidebar__field-action_active]="!staticValueType"
      [appTip]="staticValueType ? 'Set up with Formula' : 'Disable Formula'"
      [appTipOptions]="{ side: 'top' }"
      (click)="toggleFormula()"
    ></span>
  </ng-container>

  <ng-container actions-right>
    <app-bind-component
      *ngIf="bindComponents"
      [inputs]="[itemForm.value | appFieldInput]"
      [context]="context"
      [element]="element"
      [targetBindPrimaryKey]="false"
      [selfBindPrimaryKey]="false"
      [selfBindField]="'value'"
      [inline]="true"
      [analyticsSource]="analyticsSource"
      (updateInputs)="itemForm.patchValue($event[0].serialize())"
    ></app-bind-component>

    <span
      *ngIf="documentation"
      class="sidebar__field-action sidebar__field-action_button icon-function"
      [class.sidebar__field-action_active]="!staticValueType"
      [appTip]="staticValueType ? 'Set up with Formula' : 'Disable Formula'"
      [appTipOptions]="{ side: 'left' }"
      (click)="toggleFormula()"
    ></span>
  </ng-container>

  <ng-container *ngIf="staticValueType">
    <app-auto-field
      *ngIf="staticValueField == fieldTypes.Boolean"
      [form]="itemForm"
      [label]="false"
      [field]="
        createField({
          name: 'static_value',
          field: fieldTypes.Select,
          placeholder: placeholder,
          params: {
            valueEquals: booleanValueEquals,
            options: [
              { value: null, name: 'Not specified' },
              { value: false, name: 'False' },
              { value: true, name: 'True' }
            ],
            classes: fill ? ['select_fill'] : []
          },
          reset_enabled: resetEnabled
        })
      "
      [autofocus]="focusedInitial"
      [context]="context"
      [contextElement]="contextElement"
    ></app-auto-field>

    <app-auto-field
      *ngIf="staticValueField != fieldTypes.Boolean"
      [form]="itemForm"
      [label]="false"
      [field]="
        createField({
          name: 'static_value',
          placeholder: placeholder,
          field: staticValueField,
          params: staticValueParamsDisplay,
          reset_enabled: resetEnabled
        })
      "
      [autofocus]="focusedInitial"
      [context]="context"
      [contextElement]="contextElement"
      [class.field_content-fill-vertical]="staticValueVerticalHeight"
      [style.display]="'block'"
      [style.height.px]="staticValueVerticalHeight"
    ></app-auto-field>
  </ng-container>

  <app-input-edit
    *ngIf="!staticValueType"
    [itemForm]="itemForm"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="staticValueField"
    [staticValueParams]="staticValueParams"
    [filterFields]="filterFields"
    [userInput]="userInput"
    [focusedInitial]="overrideFocusedInitial || focusedInitial"
    [placeholder]="placeholder"
    [formulaPlaceholder]="formulaPlaceholder"
    [jsPlaceholder]="jsPlaceholder"
    [resetEnabled]="resetEnabled"
    [displayValueTypesEnabled]="false"
    [classes]="classes"
    [fill]="fill"
    [analyticsSource]="analyticsSource"
  ></app-input-edit>

  <ng-content></ng-content>
</app-sidebar-field>

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { ViewContext } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectProperty,
  ProjectPropertyField,
  ProjectPropertyService,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import { isSet } from '@shared';

export interface ProjectPropertyEditResult {
  property: ProjectProperty;
  required?: boolean;
  placeholder?: string;
}

@Injectable()
export class ProjectPropertyEditPopupForm extends FormGroup implements OnDestroy {
  type: ProjectPropertyType;
  instance: ProjectProperty;
  defaultName = 'property';
  defaultValueEnabled = false;
  requiredEnabled = false;
  placeholderEnabled = false;
  pageUid: string;
  saveLocal: boolean;
  context: ViewContext;

  controls: {
    name: FormControl;
    field: FormControl;
    params: FormControl;
    default_value: FormControl;
    required: FormControl;
    placeholder: FormControl;
  };

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectPropertyService: ProjectPropertyService,
    private projectPropertyStore: ProjectPropertyStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore
  ) {
    super({
      name: new FormControl('', Validators.required),
      field: new FormControl(FieldType.Text, Validators.required),
      params: new FormControl({}),
      default_value: new FormControl(),
      required: new FormControl(false),
      placeholder: new FormControl('')
    });
  }

  ngOnDestroy(): void {}

  generatePropertyName(type: ProjectPropertyType) {
    const names = this.projectPropertyStore.instance
      .filter(item => {
        if (item.type != type || item.deleted) {
          return false;
        }

        if (type == ProjectPropertyType.Page) {
          return item.pageUid == this.pageUid;
        } else {
          return true;
        }
      })
      .reduce((acc, item) => {
        acc[item.name.toLowerCase()] = item;
        return acc;
      }, {});
    const defaultName = this.defaultName;
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (names.hasOwnProperty(newName.toLowerCase()));

    return newName;
  }

  init(
    type: ProjectPropertyType,
    instance?: ProjectProperty,
    options: {
      defaultName?: string;
      defaultValueEnabled?: boolean;
      requiredEnabled?: boolean;
      required?: boolean;
      placeholderEnabled?: boolean;
      placeholder?: string;
      pageUid?: string;
      saveLocal?: boolean;
      context?: ViewContext;
    } = {}
  ) {
    this.type = type;
    this.instance = instance;
    this.pageUid = options.pageUid;
    this.saveLocal = options.saveLocal;
    this.context = options.context;

    if (isSet(options.defaultName)) {
      this.defaultName = options.defaultName;
    }

    if (isSet(options.defaultValueEnabled)) {
      this.defaultValueEnabled = options.defaultValueEnabled;
    }

    if (isSet(options.requiredEnabled)) {
      this.requiredEnabled = options.requiredEnabled;
    }

    if (isSet(options.placeholderEnabled)) {
      this.placeholderEnabled = options.placeholderEnabled;
    }

    if (instance) {
      this.controls.name.patchValue(instance.name);

      if (instance.field) {
        this.controls.field.patchValue(instance.field.field);
        this.controls.params.patchValue(instance.field.params);
      }

      this.controls.default_value.patchValue(instance.defaultValue);
    } else {
      const defaultName = this.generatePropertyName(this.type);
      this.controls.name.patchValue(defaultName);
    }

    if (isSet(options.required)) {
      this.controls.required.patchValue(options.required);
    }

    if (isSet(options.placeholder)) {
      this.controls.placeholder.patchValue(options.placeholder);
    }
  }

  getInstance(): ProjectProperty {
    const result: ProjectProperty = this.instance ? cloneDeep(this.instance) : new ProjectProperty();

    if (!this.instance) {
      result.type = this.type;
    }

    result.name = this.controls.name.value;

    if (!result.field) {
      result.field = new ProjectPropertyField();
    }

    result.field.field = this.controls.field.value;
    result.field.params = this.controls.params.value || {};

    if (this.defaultValueEnabled) {
      result.defaultValue = this.controls.default_value.value;
    }

    if (isSet(this.pageUid)) {
      result.pageUid = this.pageUid;
    }

    if (isSet(this.saveLocal)) {
      result.saveLocal = this.saveLocal;
    }

    result.updateFieldDescription();

    return result;
  }

  submit(): Observable<ProjectPropertyEditResult> {
    const instance = this.getInstance();

    let obs$: Observable<ProjectProperty>;

    if (this.instance) {
      obs$ = this.projectPropertyService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    } else {
      obs$ = this.projectPropertyService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    }

    return obs$.pipe(
      delayWhen(() => this.projectPropertyStore.getFirst(true)),
      tap(result => {
        if ([ProjectPropertyType.User, ProjectPropertyType.Group].includes(this.type)) {
          this.modelDescriptionStore.getFirst(true);
          this.actionStore.getFirst(true);
        }

        if (
          this.type == ProjectPropertyType.Global &&
          !isSet(this.currentEnvironmentStore.globalStorage.getValue(result))
        ) {
          this.currentEnvironmentStore.globalStorage.setValue(result, result.defaultValue, false);
        } else if (
          this.type == ProjectPropertyType.Page &&
          this.context &&
          !isSet(this.context.pageStorage.getValue(result))
        ) {
          this.context.pageStorage.setValue(result, result.defaultValue, false);
        }
      }),
      tap(result => {
        this.instance = result;
      }),
      map(result => {
        return {
          property: result,
          ...(this.requiredEnabled && { required: this.controls.required.value }),
          ...(this.placeholderEnabled && { placeholder: this.controls.placeholder.value })
        };
      }),
      catchError(error => {
        this.markAsDirty();
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

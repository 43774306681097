import pickBy from 'lodash/pickBy';

export class TaskStatus {
  public uid: string;
  public name: string;
  public category: string;
  public params: Object;
  public ordering: number;
  public dateAdd: string;

  deserialize(data: Object): TaskStatus {
    if (data['uid']) {
      this.uid = data['uid'];
    }
    if (data['name']) {
      this.name = data['name'];
    }
    if (data['category']) {
      this.category = data['category'];
    }
    if (data['params']) {
      this.params = data['params'];
    }
    if (data['ordering']) {
      this.ordering = data['ordering'];
    }
    if (data['date_add']) {
      this.dateAdd = data['date_add'];
    }
    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      uid: this.uid ? this.uid : undefined,
      name: this.name,
      category: this.category,
      params: this.params,
      ordering: this.ordering
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

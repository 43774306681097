var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { UserService } from '@modules/users';
import { isSet } from '@shared';
export function validateCurrentPassword() {
    return function (control) {
        if (!control.value) {
            return;
        }
        var parent = control.parent;
        if (!parent) {
            return;
        }
        if (parent.user && !parent.user.hasPassword) {
            return;
        }
        if (!isSet(control.value)) {
            return { required: true };
        }
    };
}
var ChangeEmailForm = /** @class */ (function (_super) {
    __extends(ChangeEmailForm, _super);
    function ChangeEmailForm(formUtils, fb, userService) {
        var _this = _super.call(this, {
            password: new FormControl('', validateCurrentPassword()),
            email: new FormControl('', Validators.required)
        }) || this;
        _this.formUtils = formUtils;
        _this.fb = fb;
        _this.userService = userService;
        return _this;
    }
    ChangeEmailForm.prototype.init = function (user) {
        this.user = user;
        this.markAsPristine();
    };
    ChangeEmailForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var newEmail = this.controls.email.value;
        var currentPassword = this.user && !this.user.hasPassword ? undefined : this.controls.password.value;
        return this.userService.changeEmail(newEmail, currentPassword).pipe(tap(function () {
            _this.controls.email.patchValue('');
            _this.controls.password.patchValue('');
            _this.markAsPristine();
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ChangeEmailForm;
}(FormGroup));
export { ChangeEmailForm };

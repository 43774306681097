var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UserActivity } from './user-activity';
var MessageUserActivity = /** @class */ (function (_super) {
    __extends(MessageUserActivity, _super);
    function MessageUserActivity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MessageUserActivity.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.isJSON(this.params)) {
            this.message = JSON.parse(this.params)['message'];
        }
        else {
            this.message = this.params['message'];
        }
        return this;
    };
    MessageUserActivity.prototype.serialize = function (fields) {
        this.params = {
            message: this.message
        };
        return _super.prototype.serialize.call(this, fields);
    };
    MessageUserActivity.prototype.isJSON = function (text) {
        if (typeof text !== 'string') {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    return MessageUserActivity;
}(UserActivity));
export { MessageUserActivity };

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup2-footer',
  templateUrl: './popup2-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

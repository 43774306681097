<div class="sidebar__element">
  <div class="sidebar-icon-buttons">
    <div
      *ngFor="let item of typeOptions"
      class="sidebar-icon-button"
      [class.sidebar-icon-button_active]="form.controls.download_action_type.value == item.value"
      (click)="form.controls.download_action_type.patchValue(item.value)"
    >
      <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>
      <div class="sidebar-icon-button__label">{{ item.name }}</div>
    </div>
  </div>
</div>

<ng-container *ngIf="form.controls.download_action_type.value == downloadActionTypes.Query">
  <div class="sidebar__element">
    <div class="sidebar__element-segment">
      <app-sidebar-field [label]="'choose operation'" [required]="true" [classes]="'component-action-resource'">
        <app-resource-field2
          [form]="form"
          [label]="false"
          [errors]="false"
          [field]="
            createField({
              name: 'resource',
              field: 'ResourceField',
              params: { action_resources: true, storage_resources: true, classes: ['select_fill'] }
            })
          "
        ></app-resource-field2>
      </app-sidebar-field>
    </div>
    <div class="sidebar__element-segment">
      <app-sidebar-field [classes]="'component-action-operation'">
        <app-custom-select
          [control]="form.controls.action"
          [items]="resourceActionItems"
          [compareWith]="form.actionValueEquals"
          [emptyLabel]="'No operations'"
          [classes]="['select_fill']"
          [panelClasses]="['mat-menu-panel_sidebar']"
        >
        </app-custom-select>
        <app-field-errors [form]="form" [fieldName]="'query'"></app-field-errors>

        <ng-container right>
          <a
            *ngIf="resource && editableQueryTypes.includes(form.controls.action.value?.queryType)"
            href="javascript:void(0)"
            class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
            [appTip]="'Edit request'"
            (click)="editCustomQuery()"
          >
            <span class="icon-edit button__icon"></span>
          </a>

          <a
            *ngIf="resource && form.controls.action.value?.queryType == queryTypes.Simple && actionDescriptionQuery"
            href="javascript:void(0)"
            class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
            [appTip]="'Edit request'"
            (click)="editSavedQuery()"
          >
            <span class="icon-edit button__icon"></span>
          </a>
        </ng-container>

        <ng-container description>
          <a
            *ngIf="
              form.controls.query.value?.isConfigured &&
              form.controls.query.value?.isConfigured() &&
              editableQueryTypes.includes(form.controls.action.value?.queryType)
            "
            href="javascript:void(0)"
            (click)="createAction()"
          >
            <span class="icon-save" style="margin-right: 4px;"></span> Save to reuse
          </a>
        </ng-container>
      </app-sidebar-field>
    </div>
  </div>

  <div *ngIf="form.controls.query.valid" class="sidebar__element">
    <app-sidebar-field [label]="'choose file field'" [additional]="'(optional)'">
      <app-auto-field
        [form]="form"
        [label]="false"
        [field]="
          createField({
            name: 'download_action_file_column',
            field: 'SelectField',
            placeholder: 'Use response as file',
            params: {
              options: fileColumnOptions,
              classes: ['select_fill']
            }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>
</ng-container>

<div *ngIf="form.controls.download_action_type.value == downloadActionTypes.Input" class="sidebar__element">
  <app-sidebar-field [label]="'File URL'">
    <app-input-edit
      [itemForm]="form.controls.download_action_input"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="true"
      [focusedInitial]="true"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_download_action_input'"
    ></app-input-edit>
  </app-sidebar-field>
</div>

<div *ngIf="showDraftChanges && hasDraftChanges" class="sidebar__element">
  <app-alert [purple]="true" [title]="'Unpublished operation'" [icon]="'warning_filled'" [compact]="true">
    Automations work with published App
  </app-alert>
</div>

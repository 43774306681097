import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-workflow-step-card',
  templateUrl: './workflow-step-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowStepCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() image: string;
  @Input() indicatorColor: string;
  @Input() indicatorIcon: string;
  @Input() indicatorLoader = false;
  @Input() active = false;
  @Input() contrast = false;
  @Input() contrast2 = false;
  @Input() disabled = false;
  @Input() interactive = false;
  @Input() loading = false;
  @Input() connectorTop = true;
  @Input() connectorBottom = true;
  @Input() duplicateEnabled = false;
  @Input() deleteEnabled = false;
  @Input() executeEnabled = false;
  @Output() stepDuplicate = new EventEmitter<void>();
  @Output() stepDelete = new EventEmitter<void>();
  @Output() stepExecute = new EventEmitter<void>();

  @HostBinding('class.workflow-step') cls = true;
  @HostBinding('class.workflow-step_active') get activeClass() {
    return this.active;
  }
  @HostBinding('class.workflow-step_contrast') get contrastClass() {
    return this.contrast;
  }
  @HostBinding('class.workflow-step_contrast2') get contrast2Class() {
    return this.contrast2;
  }
  @HostBinding('class.workflow-step_disabled') get disabledClass() {
    return this.disabled;
  }
  @HostBinding('class.workflow-step_interactive') get interactiveClass() {
    return this.interactive;
  }

  constructor() {}

  ngOnInit() {}
}

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="customize.emit()">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize
  </button>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="duplicate.emit()">
    <span class="choose-items-item__dropdown-icon icon-duplicate_2"></span>
    Duplicate
  </button>

  <ng-template
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    [cdkConnectedOverlayOpen]="movePagesOpened"
    [cdkConnectedOverlayOrigin]="move_pages_trigger"
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'theme_dark']"
    [cdkConnectedOverlayPositions]="movePagesPositions"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="setMovePagesOpened(false)"
    (detach)="setMovePagesOpened(false)"
    cdkConnectedOverlay
  >
    <app-choose-page-dropdown
      class="menu-pages-item__dropdown overlay-content overlay-content_animated"
      [currentPage]="viewSettings"
      [currentPageAllowed]="false"
      [brightShadow]="true"
      (chosen)="moveToPage.emit($event); setMovePagesOpened(false)"
    ></app-choose-page-dropdown>
  </ng-template>

  <button
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    cdkOverlayOrigin
    #move_pages_trigger="cdkOverlayOrigin"
    (click)="$event.stopPropagation(); setMovePagesOpened(true)"
  >
    <span class="choose-items-item__dropdown-icon icon-redo"></span>
    Move to another Page
  </button>

  <button mat-menu-item class="mat-menu-item-odd mat-menu-item-red" [disableRipple]="true" (click)="delete.emit()">
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete
  </button>
</mat-menu>

<div
  class="menu3-item menu3-item_interactive"
  [class.menu3-item_active]="active || movePagesOpened"
  (click)="selected.emit()"
>
  <div class="menu3-item__row menu3-item__row_interactive">
    <div class="menu3-item__left">
      <div
        class="menu3-item__icon"
        [class.menu3-item__icon_state_non-drag]="false"
        [ngClass]="item.getIcon() ? 'icon-' + item.getIcon() : 'icon-text'"
      ></div>
    </div>

    <div class="menu3-item__main">
      <div class="menu3-item__title">
        {{ item.name }}
      </div>
    </div>

    <div class="menu3-item__right">
      <div
        class="menu3-item__action menu3-item__action_orange menu3-item__right-item icon-more"
        [class.menu3-item__action_active]="item_dropdown_trigger.menuOpen"
        [matMenuTriggerFor]="item_dropdown"
        #item_dropdown_trigger="matMenuTrigger"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      ></div>
    </div>
  </div>
</div>

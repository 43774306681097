import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { AccordionItem } from '@modules/customize';

import { AccordionItemControl } from './accordion-item.control';

export class AccordionItemArray extends FormArray {
  controls: AccordionItemControl[];

  deserialize(value: AccordionItem[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): AccordionItem[] {
    return this.controls.map(control => control.serialize());
  }

  patchValue(value: AccordionItem[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.setValue(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  setValue(value: AccordionItem[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }

  setControls(controls: AccordionItemControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: AccordionItemControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: AccordionItem): AccordionItemControl {
    const control = new AccordionItemControl();

    if (item) {
      control.deserialize(item);
    }

    control.markAsPristine();
    return control;
  }

  appendControl(item?: AccordionItem): AccordionItemControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }
}

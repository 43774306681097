var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { AccordionItem } from './accordion-item';
import { ElementItem } from './base';
import { CollapseIndicatorPosition } from './collapse';
var AccordionElementItem = /** @class */ (function (_super) {
    __extends(AccordionElementItem, _super);
    function AccordionElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Accordion;
        _this.items = [];
        _this.multipleOpened = false;
        _this.collapseIndicatorPosition = CollapseIndicatorPosition.Right;
        return _this;
    }
    AccordionElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.multipleOpened = this.params['multiple_opened'];
        if (this.params['items']) {
            this.items = this.params['items'].map(function (item) { return new AccordionItem().deserialize(item); });
        }
        if (this.params['collapse_indicator_position']) {
            this.collapseIndicatorPosition = this.params['collapse_indicator_position'];
        }
        return this;
    };
    AccordionElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            items: this.items.map(function (item) { return item.serialize(); }),
            multiple_opened: this.multipleOpened,
            collapse_indicator_position: this.collapseIndicatorPosition
        };
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(AccordionElementItem.prototype, "analyticsName", {
        get: function () {
            return 'accordion';
        },
        enumerable: true,
        configurable: true
    });
    AccordionElementItem.prototype.defaultName = function () {
        return 'Accordion';
    };
    AccordionElementItem.prototype.childrenCount = function () {
        return this.items.reduce(function (acc, item) { return acc + item.children.length; }, 0);
    };
    return AccordionElementItem;
}(ElementItem));
export { AccordionElementItem };
registerElementForType(ElementType.Accordion, AccordionElementItem);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescriptionService } from '@modules/model-queries';
import { Resource } from '@modules/projects';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var DjangoGeneratorService = /** @class */ (function (_super) {
    __extends(DjangoGeneratorService, _super);
    function DjangoGeneratorService(modelDescriptionService, menuGeneratorService, injector) {
        var _this = _super.call(this) || this;
        _this.modelDescriptionService = modelDescriptionService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        return _this;
    }
    DjangoGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return of({
            url: resource.params['url']
        });
    };
    DjangoGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var result = {
            resourceToken: options.token,
            resourceParams: {
                url: options.url
            }
        };
        var instance = new Resource();
        instance.type = typeItem.resourceType;
        instance.typeItem = typeItem;
        instance.token = result.resourceToken;
        instance.params = result.resourceParams || {};
        return this.modelDescriptionService.getFromResource(instance).pipe(catchError(function () { return of([]); }), map(function (modelDescriptions) {
            if (!modelDescriptions || !modelDescriptions.length) {
                return result;
            }
            return __assign({}, result);
        }));
    };
    return DjangoGeneratorService;
}(ResourceGeneratorService));
export { DjangoGeneratorService };

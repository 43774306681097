import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { RawListViewSettingsColumn } from '@modules/customize';
import { FieldDescription, getFieldDescriptionByType } from '@modules/fields';
import { isSet } from '@shared';

export class PageTemplatesItemFieldControl extends FormGroup {
  instance: RawListViewSettingsColumn;
  fieldDescription: FieldDescription;

  controls: {
    enabled: FormControl;
  };

  constructor(state: { enabled?: boolean } = {}) {
    super({
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : '')
    });
  }

  deserialize(instance: RawListViewSettingsColumn) {
    this.instance = instance;
    this.fieldDescription = getFieldDescriptionByType(instance ? instance.field : undefined);

    if (instance) {
      this.controls.enabled.patchValue(instance.visible);
    } else {
      this.controls.enabled.patchValue(true);
    }

    this.markAsPristine();

    return this;
  }

  serialize(): RawListViewSettingsColumn {
    const result = cloneDeep(this.instance);

    result.visible = this.controls.enabled.value;

    return result;
  }

  toggleEnabled() {
    this.controls.enabled.patchValue(!this.controls.enabled.value);
  }
}

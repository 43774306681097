import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicComponentModule } from '@common/dynamic-component';
import { SharedModule } from '@shared';

import { PopoverComponent } from './components/popover/popover.component';

@NgModule({
  imports: [CommonModule, SharedModule, DynamicComponentModule],
  declarations: [PopoverComponent],
  exports: [PopoverComponent],
  entryComponents: [PopoverComponent]
})
export class PopoverModule {}

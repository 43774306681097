import { ElementType } from './element-type';
import { ElementItem } from './items/base';

const elementItems: { type: ElementType; instance: typeof ElementItem }[] = [];

export function getElementByType(type: ElementType): typeof ElementItem {
  const instance = elementItems.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.instance;
}

export function registerElementForType(type: ElementType, cls: typeof ElementItem) {
  elementItems.push({ type: type, instance: cls });
}

<div class="guide-popup__content">
  <div class="guide-popup__subtitle">Duration – {{ task.duration }}</div>
  <div class="guide-popup__title">{{ task.title }}</div>
  <p class="guide-popup__description">{{ task.description }}</p>
  <div class="guide-popup__actions">
    <a
      *ngIf="task.documentation"
      href="javascript:void(0)"
      class="guide-popup__actions-item guide-popup__actions-item_color_yellow"
      (click)="openDocumentation()"
    >
      Documentation
    </a>
    <a
      *ngIf="task"
      href="javascript:void(0)"
      class="guide-popup__actions-item guide-popup__actions-item_color_red"
      (click)="cancelTask()"
    >
      Cancel task
    </a>
  </div>
</div>

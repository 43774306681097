/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../notification/notification.component.ngfactory";
import * as i2 from "../notification/notification.component";
import * as i3 from "../../services/notification/notification.service";
import * as i4 from "@angular/common";
import * as i5 from "./notifications.component";
var styles_NotificationsComponent = [];
var RenderType_NotificationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0]], null, 1, "app-notification", [["class", "notifications__item"]], [[4, "top", "px"]], null, null, i1.View_NotificationComponent_0, i1.RenderType_NotificationComponent)), i0.ɵdid(1, 4440064, [["notification_element", 4]], 0, i2.NotificationComponent, [i3.NotificationService], { notification: [0, "notification"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.instance; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.position; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { notificationElements: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "notifications"]], [[2, "notifications_top-padding", null]], null, null, null, null)), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i0.ɵdid(4, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; var currVal_2 = _co.trackItemFn; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.notificationService.topPadding$)); _ck(_v, 1, 0, currVal_0); }); }
export function View_NotificationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i0.ɵdid(1, 4440064, null, 0, i5.NotificationsComponent, [i3.NotificationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i0.ɵccf("app-notifications", i5.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };

import keys from 'lodash/keys';
import { merge, of, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var SessionStorage = /** @class */ (function () {
    function SessionStorage() {
        this._updated$ = new Subject();
    }
    SessionStorage.prototype.get = function (key) {
        return sessionStorage.getItem(key);
    };
    SessionStorage.prototype.get$ = function (key) {
        var _this = this;
        return merge(of(this.get(key)), this.updated$.pipe(map(function () { return _this.get(key); }))).pipe(distinctUntilChanged());
    };
    SessionStorage.prototype.set = function (key, value) {
        sessionStorage.setItem(key, String(value));
        this._updated$.next();
    };
    SessionStorage.prototype.remove = function (key) {
        if (sessionStorage[key] != undefined) {
            sessionStorage.removeItem(key);
            this._updated$.next();
        }
    };
    SessionStorage.prototype.keys = function () {
        return keys(sessionStorage);
    };
    Object.defineProperty(SessionStorage.prototype, "updated$", {
        get: function () {
            return this._updated$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    SessionStorage.ngInjectableDef = i0.defineInjectable({ factory: function SessionStorage_Factory() { return new SessionStorage(); }, token: SessionStorage, providedIn: "root" });
    return SessionStorage;
}());
export { SessionStorage };

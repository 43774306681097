import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup2-close',
  templateUrl: './popup2-close.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2CloseComponent implements OnInit {
  @Input() outside = false;

  constructor() {}

  ngOnInit() {}
}

<div class="http-query__parameters">
  <table class="http-query-parameters">
    <tr class="http-query-parameters__header-row">
      <th class="http-query-parameters__header-column" style="width: 50%;">Key</th>
      <th class="http-query-parameters__header-column" style="width: 50%;">Value</th>
      <th class="http-query-parameters__header-column" style="width: 38px;"></th>
    </tr>
    <tr
      *ngFor="let control of arrayControl.controls; let i = index; let last = last"
      [formGroup]="control"
      class="http-query-parameters__row"
    >
      <td class="http-query-parameters__column">
        <div class="http-query-parameters__control">
          <input
            formControlName="name"
            class="input http-query-parameters__input http-query-parameters__control-input"
            [class.http-query-parameters__control-input_has-right]="typeEnabled"
            type="text"
            placeholder="Key"
            [appTokenInput]="{ context: context, control: control.controls.name }"
            (appTokenEvent)="inputTokensEvent.emit($event)"
          />

          <ng-container *ngIf="typeEnabled">
            <app-query-builder-parameters-type-menu
              [type]="control.controls.type.value"
              [origin]="type_menu_origin"
              (typeSelect)="control.controls.type.patchValue($event)"
              #type_menu="appQueryBuilderParametersTypeMenu"
            ></app-query-builder-parameters-type-menu>

            <div
              class="http-query-parameters__control-right"
              [class.http-query-parameters__control-right_always]="
                type_menu.isOpened() || control.controls.type.value == httpParameterTypes.File
              "
            >
              <div
                class="http-query-parameters__dropdown"
                [class.http-query-parameters__dropdown_opened]="type_menu.isOpened()"
                cdkOverlayOrigin
                #type_menu_origin="cdkOverlayOrigin"
              >
                <div class="http-query-parameters__dropdown-main">
                  <span class="http-query-parameters__dropdown-label">{{
                    control.controls.type.value | appCapitalize
                  }}</span>
                </div>
                <div class="http-query-parameters__dropdown-right">
                  <span class="http-query-parameters__dropdown-icon icon-arrow_down_2"></span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </td>
      <td class="http-query-parameters__column">
        <input
          formControlName="value"
          class="input http-query-parameters__input"
          type="text"
          [placeholder]="'Value or ' + '{' + '{' + 'token' + '}' + '}'"
          [appTokenInput]="{ context: context, control: control.controls.value }"
          (appTokenEvent)="inputTokensEvent.emit($event)"
        />
      </td>
      <td class="http-query-parameters__column http-query-parameters__column_fit">
        <button
          *ngIf="control.isSet() || !last; else control_not_set"
          class="query-builder-list-item__action icon-bin"
          (click)="arrayControl.removeAt(i)"
        ></button>

        <ng-template #control_not_set>
          <div
            *ngIf="arrayControl.controls.length == 1"
            class="query-builder-list-item__action query-builder-list-item__action_disabled icon-bin"
          ></div>
        </ng-template>
      </td>
    </tr>
  </table>
</div>

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ActionOutput, ActionOutputArray } from '@modules/fields';

@Injectable()
export class ViewEditorActionsForm extends FormGroup {
  controls: {
    actions: ActionOutputArray;
  };

  constructor() {
    super({
      actions: new ActionOutputArray([])
    });
  }

  init(options: { actions: ActionOutput[] }) {
    this.controls.actions.patchValue(options.actions);
  }

  getActions(): ActionOutput[] {
    return this.controls.actions.value;
  }
}

<app-add-step-menu [actionTypesEnabled]="actionTypesEnabled" (add)="addStep($event)" #add_step_menu></app-add-step-menu>

<app-zig-zag-connection
  class="workflow-fork__child-connection"
  [style.top.px]="0"
  [style.left.px]="zigZagX"
  [left]="zigZagLeft"
  [width]="zigZagWidth"
  [height]="110"
  [horizontalY]="30"
  [color]="active ? '#ea643980' : connectionColor"
></app-zig-zag-connection>

<app-zig-zag-connection
  *ngIf="stepsHasNextConnection()"
  class="workflow-fork__child-connection"
  [style.bottom.px]="-4"
  [style.left.px]="zigZagX"
  [left]="!zigZagLeft"
  [width]="zigZagWidth"
  [height]="80"
  [horizontalY]="40"
  [color]="connectionColor"
></app-zig-zag-connection>

<div class="workflow-fork__child-header">
  <div class="workflow-fork__child-label" [class.workflow-fork__child-label_active]="active" (click)="stepClick.emit()">
    {{ label }}
  </div>
</div>
<div class="workflow-fork__child-content">
  <div
    class="workflow-container"
    appDropList
    [appDropListData]="steps"
    [appDropListDisabled]="!workflowEditable"
    [appDropListSwapDistance]="20"
    [appDropListAreaMarginForward]="[-110, -15, -64, -15]"
    [appDropListAreaMarginBackward]="[-64, -15, -80, -15]"
    [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: 20 }"
    (appDropListDropped)="dragDrop($event)"
  >
    <app-auto-workflow-step
      *ngFor="let item of steps; let i = index; let first = first; trackBy: trackStep"
      class="workflow-container__item"
      appDrag
      [appDragData]="item"
      [step]="item"
      [prevStep]="steps[i - 1]"
      [workflowEditable]="workflowEditable"
      [index]="i"
      [context]="context"
      [actionTypesEnabled]="actionTypesEnabled"
      [analyticsSource]="analyticsSource"
      (stepAddBefore)="addStep($event, { addBeforeIndex: i })"
      (stepDuplicate)="duplicateStep(i)"
      (stepDelete)="deleteStep(i)"
    >
      <app-workflow-step-card
        *appDragPreview
        [title]="item.name"
        [subtitle]="item.type"
        [active]="true"
        [connectorTop]="false"
        [connectorBottom]="false"
      ></app-workflow-step-card>
    </app-auto-workflow-step>
  </div>

  <div *ngIf="stepsHasNextConnection()" class="workflow-connection workflow-connection_fill">
    <div class="workflow-connection__bounds">
      <div class="workflow-connection__dash">
        <div
          *ngIf="workflowEditable"
          class="workflow-connection__button workflow-connection__button_position_top-padding icon-plus"
          [class.workflow-connection__button_active]="trigger.menuOpen"
          [matMenuTriggerFor]="add_step_menu.menu"
          [matMenuTriggerData]="{ trigger: trigger }"
          #trigger="matMenuTrigger"
        ></div>
      </div>
    </div>
  </div>
</div>
<div class="workflow-fork__child-footer"></div>

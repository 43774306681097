/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-column.component";
var styles_PageColumnComponent = [];
var RenderType_PageColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageColumnComponent, data: {} });
export { RenderType_PageColumnComponent as RenderType_PageColumnComponent };
export function View_PageColumnComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-column", [], [[2, "page-columns__item", null], [2, "page-columns__item_fill", null], [4, "flex-grow", null], [2, "page-columns__item_vertical-align_center", null]], null, null, View_PageColumnComponent_0, RenderType_PageColumnComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageColumnComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).fillCls; var currVal_2 = i0.ɵnov(_v, 1).flexGrow; var currVal_3 = i0.ɵnov(_v, 1).verticalAlignTop; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var PageColumnComponentNgFactory = i0.ɵccf("app-page-column", i1.PageColumnComponent, View_PageColumnComponent_Host_0, { fill: "fill", fillGrow: "fillGrow", verticalAlign: "verticalAlign" }, {}, ["*"]);
export { PageColumnComponentNgFactory as PageColumnComponentNgFactory };

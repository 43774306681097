import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, Resource, ResourceDeploy, ResourceService, SecretTokenService } from '@modules/projects';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';
import { generateAlphanumeric, generateUUID } from '@shared';

// // TODO: Refactor import
import { DatabaseParamsOptions } from '@modules/resource-generators/services/database-generator/database-generator.service';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class JetDatabaseResourceSettingsForm extends BaseResourceSettingsForm<DatabaseParamsOptions> {
  form = new AppFormGroup({});

  constructor(
    private databaseGeneratorService: DatabaseGeneratorService,
    private regionService: RegionService,
    private resourceService: ResourceService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  options: DatabaseParamsOptions;

  initResourceValue(): Observable<void> {
    return this.databaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.options = result;
      })
    );
  }

  getOptions(): Observable<DatabaseParamsOptions> {
    return this.regionService.getDefaultJetBridge().pipe(
      map(jetBridge => {
        return {
          deploy: ResourceDeploy.Direct,
          region: jetBridge.region ? jetBridge.region.uid : undefined,
          url: jetBridge.url,
          ...this.options
        };
      })
    );
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.getOptions().pipe(
      switchMap(options =>
        this.databaseGeneratorService.generateParams(this.project, this.environment, this.typeItem, options)
      ),
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name']
        };
      })
    );
  }

  submit(): Observable<Resource> {
    this.deploy = ResourceDeploy.Direct;
    const create = !this.resource;

    if (create) {
      const instance = new Resource();

      instance.uniqueName = [this.typeItem.name, generateAlphanumeric(4)].join('_');
      instance.name = this.resourceForm.value['name'];
      instance.type = this.typeItem.resourceType;
      instance.typeItem = this.typeItem;

      return this.resourceService.create(this.project.uniqueName, this.environment.uniqueName, instance).pipe(
        switchMap(resource => {
          this.resource = resource;

          return this.databaseGeneratorService.createJetDatabase(this.project, this.environment, resource).pipe(
            catchError(e => {
              this.resource = undefined;

              return this.resourceService
                .delete(this.project.uniqueName, this.environment.uniqueName, resource)
                .pipe(switchMap(() => throwError(e)));
            })
          );
        }),
        catchError(error => {
          console.error(error);
          this.formUtils.showFormErrors(this.form, error);
          return throwError(error);
        }),
        switchMap(result => {
          const address = result.url.split(':', 2);
          this.options = {
            deploy: ResourceDeploy.Direct,
            database_host: address[0],
            database_port: address[1],
            database_name: result.databaseName,
            database_user: result.userName,
            database_password: result.password,
            database_schema: this.resource.uniqueName,
            token: this.resource.token || generateUUID()
          };

          return super.submit();
        })
      );
    } else {
      return super.submit();
    }
  }
}

import * as moment from 'moment';

import { Deserializable } from '@shared';

export enum DataSyncJobTaskStatus {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Failed = 'Failed'
}

export enum DataSyncJobTaskType {
  SyncStructure = 'SYNC_STRUCTURE',
  SyncData = 'SYNC_DATA',
  SyncRecord = 'SYNC_RECORD'
}

export enum DataSyncJobRecordUpdateTaskParamsType {
  Add = 'added',
  Remove = 'removed',
  Modified = 'modified'
}

export interface DataSyncJobRecordUpdateTaskParams {
  type: DataSyncJobRecordUpdateTaskParamsType;
  tableName: string;
  collectionRecordInPrimitiveStructure: Record<string, unknown>;
}

export class DataSyncJobTask implements Deserializable<DataSyncJobTask> {
  public id: string;
  public status: DataSyncJobTaskStatus;
  public type: DataSyncJobTaskType;
  public params: Object = {};
  public dateRun: string;
  public dateFinished: string;
  public createdAt: string;
  public updatedAt: string;

  deserialize(data: Object): DataSyncJobTask {
    this.id = data['id'];
    this.status = data['status'];
    this.type = data['type'];
    this.dateRun = data['dateRun'];
    this.dateFinished = data['dateFinished'];
    this.createdAt = data['createdAt'];
    this.updatedAt = data['updatedAt'];

    if (data['params']) {
      this.params = data['params'];
    }

    return this;
  }

  isActive() {
    return [DataSyncJobTaskStatus.Pending, DataSyncJobTaskStatus.Running].includes(this.status);
  }

  isHanged() {
    return this.isActive() && this.createdAt && moment().diff(moment(this.createdAt), 'hours') >= 1;
  }

  getIcon(): string {
    if (this.status == DataSyncJobTaskStatus.Succeeded) {
      return 'check_3';
    } else if (this.status == DataSyncJobTaskStatus.Pending) {
      return 'time';
    } else if (this.status == DataSyncJobTaskStatus.Running) {
      return 'play';
    } else if (this.status == DataSyncJobTaskStatus.Failed) {
      return 'close';
    }
  }

  getStatusLabel(): string {
    if (this.status == DataSyncJobTaskStatus.Succeeded) {
      return 'synced';
    } else if (this.status == DataSyncJobTaskStatus.Pending) {
      return 'pending';
    } else if (this.status == DataSyncJobTaskStatus.Running) {
      return 'syncing';
    } else if (this.status == DataSyncJobTaskStatus.Failed) {
      return 'failed';
    }
  }

  getLastDate() {
    return this.dateFinished || this.dateRun || this.createdAt;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { getColorHexStr } from '@modules/colors';
import { BarCodeFormat } from '@modules/customize';
import { elementResize$, isSet, removeChildren } from '@shared';
var BarCodeComponent = /** @class */ (function () {
    function BarCodeComponent(el, cd) {
        this.el = el;
        this.cd = cd;
        this.format = BarCodeFormat.CODE128;
        this.value = '';
        this.displayText = true;
        this.cls = true;
    }
    Object.defineProperty(BarCodeComponent.prototype, "minWidth", {
        get: function () {
            return isSet(this.error) ? 120 : null;
        },
        enumerable: true,
        configurable: true
    });
    BarCodeComponent.prototype.ngOnInit = function () {
        var _this = this;
        elementResize$(this.el.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.render(); });
    };
    BarCodeComponent.prototype.ngOnDestroy = function () { };
    BarCodeComponent.prototype.ngOnChanges = function (changes) {
        if ([changes.format, changes.value, changes.fillColor, changes.backgroundColor, changes.displayText].some(function (item) { return item && !item.firstChange; })) {
            this.render();
        }
    };
    BarCodeComponent.prototype.render = function () {
        var boundsHeight = this.el.nativeElement.offsetHeight;
        var textMargin = 2;
        var fontSize = 20;
        var height = boundsHeight - (this.displayText ? textMargin + fontSize : 0);
        var fillColor = isSet(this.fillColor) ? getColorHexStr(this.fillColor) : undefined;
        var backgroundColor = isSet(this.backgroundColor) ? getColorHexStr(this.backgroundColor) : undefined;
        this.error = undefined;
        this.cd.markForCheck();
        try {
            JsBarcode(this.svgElement.nativeElement, this.value, __assign({ format: this.format }, (fillColor && { lineColor: fillColor }), { background: backgroundColor || 'transparent', height: height, displayValue: this.displayText, margin: 0 }));
        }
        catch (e) {
            console.error(e);
            this.error = 'Invalid value';
            this.cd.markForCheck();
            removeChildren(this.svgElement.nativeElement);
        }
    };
    return BarCodeComponent;
}());
export { BarCodeComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./container-builder-stub.component";
var styles_ContainerBuilderStubComponent = [];
var RenderType_ContainerBuilderStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContainerBuilderStubComponent, data: {} });
export { RenderType_ContainerBuilderStubComponent as RenderType_ContainerBuilderStubComponent };
function View_ContainerBuilderStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "container-stub-placeholder__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["OR"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "container-stub-placeholder__actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "button button_primary button_orange-alternative"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.templatesClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Use Page templates "]))], null, null); }
export function View_ContainerBuilderStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "container-stub__item container-stub__item_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "container-stub-placeholder container-stub-placeholder_border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "container-stub-placeholder__image container-stub-placeholder__image_type_drag-n-drop"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "container-stub-placeholder__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Start to drag-and-drop "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["components"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContainerBuilderStubComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templates; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_ContainerBuilderStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-container-builder-stub", [], [[2, "container-stub", null], [2, "container-stub_fill", null]], null, null, View_ContainerBuilderStubComponent_0, RenderType_ContainerBuilderStubComponent)), i0.ɵdid(1, 114688, null, 0, i2.ContainerBuilderStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).marginSizeS; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ContainerBuilderStubComponentNgFactory = i0.ɵccf("app-container-builder-stub", i2.ContainerBuilderStubComponent, View_ContainerBuilderStubComponent_Host_0, { fill: "fill", templates: "templates" }, { templatesClick: "templatesClick" }, []);
export { ContainerBuilderStubComponentNgFactory as ContainerBuilderStubComponentNgFactory };

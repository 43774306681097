import { Observable } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';

export enum ElementConfiguration {
  Started = 'started',
  Configured = 'configured'
}

export function trackConfigured() {
  return (source: Observable<boolean>): Observable<ElementConfiguration> => {
    return source.pipe(
      map(item => !!item),
      startWith(undefined),
      distinctUntilChanged(),
      pairwise(),
      map(([wasConfigured, isConfigured]) => {
        if ((wasConfigured === undefined || wasConfigured === true) && isConfigured === false) {
          return ElementConfiguration.Started;
        } else if (wasConfigured === false && isConfigured === true) {
          return ElementConfiguration.Configured;
        }
      })
    );
  };
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupService } from '@common/popups';
import { TimelinePopupComponent } from '../../components/timeline-popup/timeline-popup.component';
var TimelineController = /** @class */ (function () {
    function TimelineController(popupService, injector) {
        this.popupService = popupService;
        this.injector = injector;
    }
    TimelineController.prototype.open = function (objectType, objectId, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (this.closeObs) {
            this.closeObs.next();
        }
        params = __assign({ object_type: objectType, object_id: objectId }, params);
        var closeObs = new Subject();
        this.closeObs = closeObs;
        return this.popupService.push({
            component: TimelinePopupComponent,
            disablePointerEvents: true,
            enableWindowScroll: true,
            inputs: {
                baseParams: params,
                closeObs: closeObs.asObservable()
            },
            outputs: {
                closeObsEvent: [
                    function () {
                        if (_this.closeObs === closeObs) {
                            _this.closeObs = undefined;
                        }
                    }
                ]
            },
            popupClosed: function () {
                if (_this.closeObs === closeObs) {
                    _this.closeObs = undefined;
                }
            },
            injector: this.injector
        });
    };
    return TimelineController;
}());
export { TimelineController };

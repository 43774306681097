import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { SidebarCollapseContext } from '@modules/sidebar';
import { ForkWorkflowStep, WorkflowStepType } from '@modules/workflow';

import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ForkCustomizeWorkflowStepItemControl } from './fork-customize-workflow-step-item.control';
import { ForkCustomizeWorkflowStepForm } from './fork-customize-workflow-step.form';

@Component({
  selector: 'app-fork-customize-workflow-step',
  templateUrl: './fork-customize-workflow-step.component.html',
  providers: [ForkCustomizeWorkflowStepForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForkCustomizeWorkflowStepComponent extends CustomizeWorkflowStepComponent<ForkWorkflowStep>
  implements OnInit, OnDestroy {
  collapseContext = new SidebarCollapseContext();
  createdItem: ForkCustomizeWorkflowStepItemControl;

  constructor(
    public form: ForkCustomizeWorkflowStepForm,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.step, { firstInit: false });

    if (this.workflowEditable) {
      this.form.valueChanges
        .pipe(
          debounceTime(200),
          map(() => this.form.submit()),
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.submit(result);
        });
    }
  }

  ngOnDestroy(): void {}

  submit(value?: ForkWorkflowStep) {
    if (!value) {
      value = this.form.submit();
    }

    this.stepChange.emit(value);
  }

  addItem() {
    const control = this.form.controls.items.createControl();
    this.form.controls.items.push(control);
    this.createdItem = control;
    this.cd.markForCheck();
  }

  removeItem(control: ForkCustomizeWorkflowStepItemControl, i: number) {
    // this.dialogService
    //   .warning({
    //     title: 'Delete Branch',
    //     description: `Are you sure want to delete <strong>Branch ${i + 1}</strong>?`,
    //     style: 'orange'
    //   })
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (result) {
    this.form.controls.items.removeControl(control);
    this.cd.markForCheck();
    //   }
    // });
  }

  dragDropItem(event: CdkDragDrop<ForkCustomizeWorkflowStepItemControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
      this.form.controls.items.updateValueAndValidity();
      this.cd.markForCheck();
    }
  }
}

registerCustomizeWorkflowStepComponent(WorkflowStepType.Fork, ForkCustomizeWorkflowStepComponent);

import { Token } from '../data/token';

export function flattenTokens(items: Token[]): Token[] {
  return items.reduce((acc: Token[], item: Token) => {
    if (!item.children) {
      acc.push(item);
    } else if (item.children) {
      acc.push(...flattenTokens(item.children));
    }

    return acc;
  }, []);
}

import { ModelDescription } from '@modules/models';

export enum ModelImportEventType {
  Started = 'started',
  Progress = 'progress',
  Finished = 'finished',
  Cancelled = 'cancelled'
}

export interface ModelImportStartedEvent {
  type: ModelImportEventType;
  modelDescription: ModelDescription;
  totalCount: number;
}

export interface ModelImportProgressEvent {
  type: ModelImportEventType;
  modelDescription: ModelDescription;
  processedCount: number;
  successCount: number;
  failedCount: number;
  totalCount: number;
}

export interface ModelImportFinishedEvent {
  type: ModelImportEventType;
  modelDescription: ModelDescription;
  processedCount: number;
  successCount: number;
  failedCount: number;
  totalCount: number;
  objectResults: Object[];
}

export interface ModelImportCancelledEvent {
  type: ModelImportEventType;
  modelDescription: ModelDescription;
  processedCount: number;
  successCount: number;
  failedCount: number;
  totalCount: number;
}

export type ModelImportEvent =
  | ModelImportStartedEvent
  | ModelImportProgressEvent
  | ModelImportFinishedEvent
  | ModelImportCancelledEvent;

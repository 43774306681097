import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { localize } from '@common/localize';
import { ActionType } from '@modules/actions';
import { Option } from '@modules/field-components';
import { controlValue, isSet, TypedChanges } from '@shared';

import { actionTypeOptions } from '../action-type-dropdown/action-type-options';

@Component({
  selector: 'app-action-type-field',
  templateUrl: './action-type-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionTypeFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormControl;
  @Input() typesOnly: ActionType[];
  @Input() actionOriginEnabled = false;
  @Input() orange = false;
  @Input() fill = false;
  @Input() placeholder: string = localize('Choose');
  @Input() classes: string | string[];

  currentValueLabel: string;
  currentValueIcon: string;
  currentValueSubscription: Subscription;
  dropdownOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initCurrentValue();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ActionTypeFieldComponent>): void {
    if (changes.placeholder) {
      this.placeholder = this.placeholder || localize('Choose');
    }
  }

  initCurrentValue() {
    if (this.currentValueSubscription) {
      this.currentValueSubscription.unsubscribe();
      this.currentValueSubscription = undefined;
    }

    this.currentValueSubscription = controlValue<ActionType>(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const currentItem = isSet(value)
          ? actionTypeOptions.reduce<Option<ActionType>>((acc, section) => {
              if (acc) {
                return acc;
              }

              return section.items.find(item => item.value == value);
            }, undefined)
          : undefined;

        this.currentValueLabel = currentItem ? currentItem.name : undefined;
        this.currentValueIcon = currentItem ? currentItem.icon : undefined;
        this.cd.markForCheck();
      });
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { copyTextToClipboard } from '../../utils/common';

@Directive({
  selector: '[appCopyText]'
})
export class CopyTextDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  copy(): Observable<boolean> {
    const text = this.el.nativeElement.textContent;
    return copyTextToClipboard(text);
  }
}

import pickBy from 'lodash/pickBy';
import { View } from '@modules/views';
import { isSet } from '@shared';
export var CustomViewType;
(function (CustomViewType) {
    CustomViewType["Common"] = "common";
    CustomViewType["ModelDescription"] = "model_desc";
    CustomViewType["Model"] = "model";
    CustomViewType["Component"] = "component";
    CustomViewType["ListItem"] = "list_item";
    CustomViewType["ItemColumn"] = "item_column";
    CustomViewType["MenuItem"] = "menu_item";
})(CustomViewType || (CustomViewType = {}));
export var CustomViewSource;
(function (CustomViewSource) {
    CustomViewSource["View"] = "view";
    CustomViewSource["HTML"] = "html";
    CustomViewSource["CustomElement"] = "custom_element";
})(CustomViewSource || (CustomViewSource = {}));
export var CustomViewFileType;
(function (CustomViewFileType) {
    CustomViewFileType[CustomViewFileType["JS"] = 0] = "JS";
    CustomViewFileType[CustomViewFileType["CSS"] = 1] = "CSS";
    CustomViewFileType[CustomViewFileType["Unknown"] = 2] = "Unknown";
})(CustomViewFileType || (CustomViewFileType = {}));
export var defaultCustomViewHtml = "<label for=\"file\">\n    Downloading progress:\n</label>\n<br>\n<progress id=\"file\" value=\"32\" max=\"100\">\n    32%\n</progress>";
var CustomView = /** @class */ (function () {
    function CustomView() {
        this.source = CustomViewSource.View;
        this.params = {};
        this.filesJs = [];
        this.filesCss = [];
        this.draft = false;
        this.deleted = false;
    }
    CustomView.prototype.deserialize = function (data) {
        this.uniqueName = data['unique_name'];
        this.name = data['name'];
        this.viewType = data['view_type'];
        this.source = data['source'];
        this.dist = data['dist'];
        this.distBaseAbsoluteUrl = data['dist_base_absolute_url'];
        this.html = data['html'];
        this.dateAdd = data['date_add'];
        if (data['view']) {
            this.view = new View().deserialize(data['view']);
        }
        if (isSet(data['params'])) {
            this.params = JSON.parse(data['params']);
        }
        if (this.params['tag_name']) {
            this.tagName = this.params['tag_name'];
        }
        if (this.params['files']) {
            if (this.params['files']['js']) {
                this.filesJs = this.params['files']['js'];
            }
            if (this.params['files']['css']) {
                this.filesCss = this.params['files']['css'];
            }
        }
        if (this.params['page_uid']) {
            this.pageUid = this.params['page_uid'];
        }
        if (this.params['element_uid']) {
            this.elementUid = this.params['element_uid'];
        }
        if (this.params['column_unique_name']) {
            this.columnUniqueName = this.params['column_unique_name'];
        }
        if (this.params['menu_item_uid']) {
            this.menuItemUid = this.params['menu_item_uid'];
        }
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        return this;
    };
    CustomView.prototype.serialize = function (fields) {
        this.params = {};
        this.params['tag_name'] = this.tagName;
        this.params['files'] = {
            js: this.filesJs,
            css: this.filesCss
        };
        this.params['page_uid'] = this.pageUid;
        this.params['element_uid'] = this.elementUid;
        this.params['column_unique_name'] = this.columnUniqueName;
        this.params['menu_item_uid'] = this.menuItemUid;
        var data = {
            unique_name: this.uniqueName,
            name: this.name,
            view_type: this.viewType,
            source: this.source,
            dist: this.dist || '',
            html: this.html || '',
            view: this.view ? this.view.serialize() : null,
            params: JSON.stringify(this.params),
            draft: this.draft,
            deleted: this.deleted
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    CustomView.prototype.commonLink = function () {
        return ['flex', this.uniqueName];
    };
    CustomView.prototype.modelDescriptionLink = function (modelId) {
        return ['models', modelId, 'flex', this.uniqueName];
    };
    CustomView.prototype.modelLink = function (modelId, id) {
        return ['models', modelId, id, 'flex', this.uniqueName];
    };
    Object.defineProperty(CustomView.prototype, "link", {
        get: function () {
            return this.viewType == CustomViewType.Common ? ['flex', this.uniqueName] : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomView.prototype, "changeLink", {
        get: function () {
            return ['flexviews_edit', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    CustomView.prototype.getViewTypeStr = function (options) {
        if (options === void 0) { options = {}; }
        if (this.viewType == CustomViewType.Common) {
            return "Standalone (" + this.uniqueName + ")";
        }
        else if (this.viewType == CustomViewType.ModelDescription) {
            return "Collection (" + this.uniqueName + ")";
        }
        else if (this.viewType == CustomViewType.Model) {
            return "Record (" + this.uniqueName + ")";
        }
        else if (this.viewType == CustomViewType.Component) {
            return [
                'Page component'
            ].concat((options.page ? [options.page] : []), (options.element ? [options.element] : [])).join(' - ');
        }
        else if (this.viewType == CustomViewType.ListItem) {
            return ['List card'].concat((options.page ? [options.page] : []), (options.element ? [options.element] : [])).join(' - ');
        }
    };
    return CustomView;
}());
export { CustomView };

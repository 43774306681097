import { ApplicationRef, ComponentRef, ElementRef, EmbeddedViewRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import isArray from 'lodash/isArray';
import { nodeListToArray } from '@shared';
import { DraggableService } from '../../services/draggable/draggable.service';
var DraggableDirective = /** @class */ (function () {
    function DraggableDirective(el, appRef, draggableService) {
        this.el = el;
        this.appRef = appRef;
        this.draggableService = draggableService;
        this.options = {};
        this.itemsUpdated = new EventEmitter();
        this.dragChanged = new EventEmitter();
        this.dragStarted = new EventEmitter();
        this.dragFinished = new EventEmitter();
        this.itemAdded = new EventEmitter();
        this.itemRemoved = new EventEmitter();
        this.items = [];
    }
    DraggableDirective.prototype.ngOnInit = function () {
        this.draggableService.add(this);
        this.updateScrollableParent();
    };
    DraggableDirective.prototype.ngOnDestroy = function () {
        this.draggableService.remove(this);
    };
    DraggableDirective.prototype.addItem = function (item, silent) {
        if (silent === void 0) { silent = false; }
        if (this.items.find(function (i) { return i === item; })) {
            return;
        }
        this.items.push(item);
        this.updatePositionFor(item, { silent: silent });
        if (!silent) {
            this.itemsUpdated.emit(this.items);
            this.itemAdded.emit(item);
        }
    };
    DraggableDirective.prototype.removeItem = function (item, silent) {
        if (silent === void 0) { silent = false; }
        this.items = this.items.filter(function (i) { return i !== item; });
        if (!silent) {
            this.itemsUpdated.emit(this.items);
            this.itemRemoved.emit(item);
        }
    };
    DraggableDirective.prototype.addExternalItem = function (item) {
        if (this.items.find(function (i) { return i === item; })) {
            return;
        }
        var copy = item.draggingFrom.options['copy'];
        var copyAllowed = !!copy && !item.copied && (!isArray(copy) || copy.includes(this.name));
        var deletable = item.draggingFrom.options['deletable'];
        var deletableAllowed = !!deletable && this !== item.draggingFrom && (!isArray(deletable) || deletable.includes(this.name));
        if (copyAllowed) {
            var clone = item.el.nativeElement.cloneNode(true);
            clone.classList.remove('dragging');
            item.el.nativeElement.parentNode.insertBefore(clone, item.el.nativeElement);
            item.copied = true;
            var directive = item.createDirective(new ElementRef(clone), item.parent);
            directive.data = item.data;
            directive.ngAfterViewInit();
        }
        var addNode = !deletableAllowed && (!copy || this !== item.draggingFrom);
        if (item.el.nativeElement.parentNode) {
            item.el.nativeElement.parentNode.removeChild(item.el.nativeElement);
        }
        if (item.parent.options['containerRef']) {
            var index = item.parent.options['containerRef'].indexOf(item.viewRef);
            if (index != -1) {
                item.parent.options['containerRef'].detach(index);
            }
        }
        if (item.draggingFrom.options['createComponent']) {
            var componentRef = item.draggingFrom.options['createComponent'](item, this, this.el.nativeElement);
            // this.viewContainerRef.insert(componentRef.hostView);
            // componentRef.changeDetectorRef.detectChanges();
            var domElem = componentRef.hostView.rootNodes[0];
            item.el = new ElementRef(domElem);
            item.viewRef = componentRef.hostView;
            item.init();
            // this.viewContainerRef.insert(componentRef.hostView);
            if (this.options['containerRef']) {
                this.appRef.detachView(componentRef.hostView);
                this.options['containerRef'].insert(componentRef.hostView);
            }
            item.parent.removeItem(item);
            if (addNode) {
                this.addItem(item);
            }
        }
        else {
            if (addNode) {
                this.el.nativeElement.appendChild(item.el.nativeElement);
            }
            if (this.options['containerRef']) {
                this.options['containerRef'].insert(item.viewRef);
            }
            item.parent.removeItem(item);
            if (addNode) {
                this.addItem(item);
            }
        }
        item.parent = this;
    };
    DraggableDirective.prototype.updatePositionFor = function (item, options) {
        if (options === void 0) { options = {}; }
        if (this.options['sortable'] == false) {
            return;
        }
        var items = this.items.sort(function (lhs, rhs) { return lhs.elementIndex - rhs.elementIndex; });
        var oldIndex = items.findIndex(function (i) { return i === item; });
        var newIndex = items
            .map(function (i) {
            return {
                item: i,
                top: options.mousePosition && i === item ? options.mousePosition.y : i.bounds.top
            };
        })
            .sort(function (lhs, rhs) { return lhs.top - rhs.top; })
            .findIndex(function (i) { return i.item === item; });
        if (oldIndex == newIndex) {
            return;
        }
        var el = item.el.nativeElement;
        var parent = this.el.nativeElement;
        if (newIndex > oldIndex) {
            parent.insertBefore(el, parent.children[newIndex + 1]);
        }
        else {
            parent.insertBefore(el, parent.children[newIndex]);
        }
        var nodes = nodeListToArray(parent.children);
        this.items = this.items.sort(function (lhs, rhs) {
            return nodes.indexOf(lhs.el.nativeElement) - nodes.indexOf(rhs.el.nativeElement);
        });
        if (!options.silent) {
            this.itemsUpdated.emit(this.items);
        }
    };
    DraggableDirective.prototype.isPointInside = function (x, y) {
        var bounds = this.el.nativeElement.getBoundingClientRect();
        return x >= bounds.left && x <= bounds.left + bounds.width && y >= bounds.top && y <= bounds.top + bounds.height;
    };
    Object.defineProperty(DraggableDirective.prototype, "depth", {
        get: function () {
            var depth = 0;
            var el = this.el.nativeElement;
            while (el.parentNode) {
                ++depth;
                el = el.parentNode;
            }
            return depth;
        },
        enumerable: true,
        configurable: true
    });
    DraggableDirective.prototype.updateScrollableParent = function () {
        if (this.options['scrollable']) {
            this.scrollableParent = this.options['scrollable'];
            return;
        }
        var iterate = function (el) {
            if (!el || !(el instanceof Element)) {
                return;
            }
            if (el.getAttribute('xsScrollable') != null) {
                return el;
            }
            else if (['scroll', 'auto'].includes(getComputedStyle(el)['overflow'])) {
                return el;
            }
            else if (el === document.body) {
                return el;
            }
            return iterate(el.parentNode);
        };
        this.scrollableParent = iterate(this.el.nativeElement);
    };
    return DraggableDirective;
}());
export { DraggableDirective };

import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, ChangeDetectorRef, ElementRef, EventEmitter, Injector, OnChanges, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { SimplePopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent } from '@modules/analytics';
import { LightboxComponent } from '@modules/field-components/components/image-field/lightbox/lightbox.component';
import { isSet, nodeListToArray, scrollToElement, splitmax, TypedChanges } from '@shared';
import { TaskService } from '../../../services/task/task.service';
var Rate;
(function (Rate) {
    Rate["Poor"] = "poor";
    Rate["OK"] = "ok";
    Rate["Excellent"] = "excellent";
})(Rate || (Rate = {}));
var GuidePopupDocumentationComponent = /** @class */ (function () {
    function GuidePopupDocumentationComponent(taskService, sanitizer, popupService, injector, cd) {
        this.taskService = taskService;
        this.sanitizer = sanitizer;
        this.popupService = popupService;
        this.injector = injector;
        this.cd = cd;
        this.close = new EventEmitter();
        this.loading = false;
        this.history = [];
        this.subscriptions = [];
        this.rates = Rate;
        this.analyticsEvents = AnalyticsEvent;
    }
    GuidePopupDocumentationComponent.prototype.ngOnDestroy = function () { };
    GuidePopupDocumentationComponent.prototype.ngOnChanges = function (changes) {
        if (changes.path) {
            this.openDocumentation();
        }
    };
    GuidePopupDocumentationComponent.prototype.openDocumentation = function () {
        this.history = [];
        this.openPage({ path: this.path });
    };
    GuidePopupDocumentationComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        var findRef = function (el) {
            if (el.classList && el.classList.contains('ref')) {
                return {
                    type: 'ref',
                    element: el
                };
            }
            else if (el.classList && el.classList.contains('img-ref')) {
                return {
                    type: 'img-ref',
                    element: el
                };
            }
            else if (el.parentElement) {
                return findRef(el.parentElement);
            }
        };
        fromEvent(this.contentElement.nativeElement, 'click').subscribe(function (e) {
            var ref = e.target instanceof HTMLElement ? findRef(e.target) : undefined;
            if (ref && ref.type == 'ref') {
                var attrs = ref.element.attributes;
                var page = attrs.getNamedItem('data-page');
                if (isSet(page)) {
                    _this.openPage({ filePath: _this.filePath, relativePath: page.value });
                    e.preventDefault();
                }
            }
            else if (ref && ref.type == 'img-ref') {
                var href = ref.element.getAttribute('href');
                _this.openLightbox(href, ref.element);
                e.preventDefault();
            }
        });
    };
    GuidePopupDocumentationComponent.prototype.openPage = function (selector) {
        var _this = this;
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.subscriptions = [];
        this.title = undefined;
        this.description = undefined;
        this.content = undefined;
        this.rate = undefined;
        this.loading = true;
        this.error = undefined;
        this.pageUrl = undefined;
        this.cd.markForCheck();
        var fetchPageSubscription = this.taskService
            .getDocumentationContent(selector)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var _a = splitmax(result.filePath, '#', 2), url = _a[0], anchor = _a[1];
            _this.title = result.title;
            _this.description = result.description;
            _this.content = _this.sanitizer.bypassSecurityTrustHtml(result.content);
            _this.rate = undefined;
            _this.loading = false;
            _this.pageUrl = result.url;
            _this.filePath = result.filePath;
            _this.history = _this.history.concat([selector]);
            _this.cd.detectChanges();
            if (isSet(anchor)) {
                var element = _this.scrollElement.nativeElement.querySelector("#" + anchor);
                if (element) {
                    scrollToElement(_this.scrollElement.nativeElement, element, 0, -40);
                }
            }
            var fetchLinkLabelsSubscription = _this.fetchLinkLabels();
            if (fetchLinkLabelsSubscription) {
                _this.subscriptions.push(fetchLinkLabelsSubscription);
            }
        }, function (e) {
            _this.content = undefined;
            _this.rate = undefined;
            _this.loading = false;
            _this.history = _this.history.concat([selector]);
            if (e instanceof HttpErrorResponse && e.status == 404) {
                _this.error = 'Not Found';
            }
            else {
                _this.error = 'Unknown error';
            }
            _this.cd.markForCheck();
            console.error(e);
        });
        this.subscriptions.push(fetchPageSubscription);
    };
    GuidePopupDocumentationComponent.prototype.openLightbox = function (url, sourceElement) {
        this.popupService.push({
            component: LightboxComponent,
            popupComponent: SimplePopupComponent,
            inputs: {
                url: url,
                origin: sourceElement,
                zoom: false
            },
            injector: this.injector
        });
    };
    GuidePopupDocumentationComponent.prototype.back = function () {
        var selector = this.history[this.history.length - 2];
        this.history = this.history.slice(0, -2);
        this.openPage(selector);
    };
    GuidePopupDocumentationComponent.prototype.fetchLinkLabels = function () {
        var _this = this;
        var linkElements = nodeListToArray(this.contentElement.nativeElement.querySelectorAll('.ref.ref_styled[data-page]')).reduce(function (acc, item) {
            var labelElement = item.querySelector('.ref__label');
            if (!labelElement) {
                return acc;
            }
            var linkPage = item.getAttribute('data-page');
            if (!acc[linkPage]) {
                acc[linkPage] = [];
            }
            acc[linkPage].push(labelElement);
            return acc;
        }, {});
        if (!keys(linkElements).length) {
            return;
        }
        return combineLatest(toPairs(linkElements).map(function (_a) {
            var path = _a[0], elements = _a[1];
            return _this.taskService
                .getDocumentationContent({ filePath: _this.filePath, relativePath: path }, { noContent: true })
                .pipe(map(function (content) {
                return { elements: elements, title: content.title };
            }));
        }))
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            items.forEach(function (item) {
                if (!item.title) {
                    return;
                }
                item.elements.forEach(function (element) {
                    element.innerText = item.title;
                });
            });
        });
    };
    GuidePopupDocumentationComponent.prototype.setRate = function (value) {
        this.rate = value;
        this.cd.markForCheck();
    };
    return GuidePopupDocumentationComponent;
}());
export { GuidePopupDocumentationComponent };

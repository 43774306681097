import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppDrag, AppDragDrop, AppDropList } from '@common/drag-drop2';
import { PopupService } from '@common/popups';
import { SessionStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components/data/customize-bar-item';
import {
  ChangeViewSettings,
  CustomizeService,
  CustomViewSettings,
  ElementItem,
  ElementType,
  PopupSettings,
  ViewContext,
  ViewSettings
} from '@modules/customize';
import { AutoElementComponent, BUILDER_ELEMENT_BUFFER, ElementContainerService } from '@modules/customize-elements';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ChooseTemplateSection, ChooseTemplateService } from '@modules/template-components';
import { PageTemplatesController } from '@modules/template-queries';
import { isControlElement, KeyboardEventKeyCode, openUrl, scrollTo, scrollWindowTo } from '@shared';

import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';

@Component({
  selector: 'app-root-layout',
  templateUrl: './root-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootLayoutComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() elements: ElementItem[];
  @Input() context: ViewContext;
  @Input() page: CustomViewSettings | ChangeViewSettings;
  @Input() popup: PopupSettings;
  @Input() templates = true;
  @Input() root = false;
  @Input() fill = true;
  @Input() active = false;

  @ViewChild(ElementGroupsContainerDirective) elementGroupsContainer: ElementGroupsContainerDirective;
  @ViewChildren(AutoElementComponent) elementComponents = new QueryList<AutoElementComponent>();

  analyticsEvents = AnalyticsEvent;

  canEnter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
        return false;
      } else {
        return true;
      }
    };
  })();

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(
    public customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private injector: Injector,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private chooseTemplateService: ChooseTemplateService,
    private pageTemplatesController: PageTemplatesController,
    private sessionStorage: SessionStorage,
    private popupService: PopupService,
    // private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeService
      .createElement$()
      .pipe(
        filter(() => this.isActive()),
        untilDestroyed(this)
      )
      .subscribe(barItem => {
        const elementItem = this.elementContainerService.createElementItem(barItem, this.context);
        this.createElement(elementItem);
      });

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(() => !isControlElement(document.activeElement) && !this.popupService.items.length && this.isActive()),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.V) {
          this.pasteElement();
        }
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['elements'] && !changes['elements'].firstChange) {
      setTimeout(() => this.updateElementStatesOnStable(), 0);
    }
  }

  ngAfterViewInit(): void {
    this.updateElementStates();
  }

  isActive(): boolean {
    const openedPopup =
      this.customizeService.handler && this.customizeService.handler.getOpenedPopup
        ? this.customizeService.handler && this.customizeService.handler.getOpenedPopup()
        : undefined;

    return (
      this.active && ((!this.popup && !openedPopup) || (this.popup && openedPopup && openedPopup.uid == this.popup.uid))
    );
  }

  dragDrop(event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    const item = event.previousContainer.data[event.previousIndex];
    const barItem = item instanceof ElementItem ? undefined : (item as CustomizeBarItem);

    if (barItem && barItem.popup) {
      if (this.customizeService.handler && this.customizeService.handler.createPopup) {
        this.customizeService.handler.createPopup(true, {
          ...(barItem.defaultParams && {
            width: barItem.defaultParams['width'],
            style: barItem.defaultParams['style'],
            position: barItem.defaultParams['position']
          }),
          analyticsSource: 'components_library'
        });
        this.elementContainerService.sendAddPopupAnalytics();
      }

      return;
    }

    const siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
    const siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
    const siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
    const siblingAnchor: ElementItem = event.data ? event.data['siblingAnchor'] : undefined;
    const siblingAnchorContainer: AppDropList = event.data ? event.data['siblingAnchorContainer'] : undefined;

    if (siblingLeftEntered || siblingRightEntered) {
      const anchorContainer: ElementItem[] = siblingSelf ? event.container.data : siblingAnchorContainer.data;
      const anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);

      this.elementContainerService.dragDropIntoSiblingColumn({
        sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
        sourceIndex: event.previousIndex,
        sourceCloneItem: event.previousContainer.cloneItems,
        anchorContainer: anchorContainer,
        anchorIndex: anchorIndex,
        anchorSelf: siblingSelf,
        left: siblingLeftEntered,
        context: this.context,
        parent: undefined
      });
    } else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data as ElementItem[], event.previousIndex, event.currentIndex);
      } else if (event.previousContainer.cloneItems) {
        const elementItem = this.elementContainerService.copyElementItem(
          event.previousContainer.data as CustomizeBarItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex,
          this.context
        );
        this.customizeService.registerCreatedElement(elementItem, barItem);
      } else {
        transferArrayItem(
          event.previousContainer.data as ElementItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  duplicateItem(index: number) {
    const elementItem = this.elementContainerService.duplicateElementItem(this.elements, this.context, index);
    this.cd.detectChanges();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();

    const component = this.elementComponents.find(i => i.element === elementItem);

    if (component) {
      component.customize();
    }
  }

  replaceItem(index: number, elements: ElementItem[]) {
    this.elementContainerService.replaceElementItem(this.elements, index, elements);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  createElement(element: ElementItem) {
    this.elementContainerService.prependElementItem(this.page, this.popup, element, this.context);

    this.onElementCreated(element);
  }

  pasteElement() {
    const createdElement = this.elementContainerService.addSavedElement(
      this.page,
      this.popup,
      BUILDER_ELEMENT_BUFFER,
      this.context
    );

    if (createdElement.element) {
      this.onElementCreated(createdElement.element);

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Paste, {
        ComponentTypeID: createdElement.element.analyticsName
      });
    } else if (createdElement.popup) {
      this.onPopupCreated(createdElement.popup);

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Paste);
    }
  }

  onElementCreated(element: ElementItem) {
    this.customizeService.registerCreatedElement(element);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();

    this.scrollToTop();
  }

  onPopupCreated(popup: PopupSettings) {
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();

    if (this.customizeService.handler.openPopup && this.customizeService.handler.openPopup) {
      setTimeout(() => {
        this.customizeService.handler.openPopup(popup.uid, { customize: true });
      });
    }

    this.scrollToTop();
  }

  scrollToTop() {
    const popupElement = document.querySelector(
      '.custom-page-popup-container_scrollable.custom-page-popup-container_visible'
    );

    if (popupElement) {
      scrollTo(popupElement, 0);
    } else {
      scrollWindowTo(0);
    }
  }

  deleteItem(element: ElementItem, immediate = false) {
    const index = this.elements.findIndex(item => item === element);

    if (index == -1) {
      return;
    }

    this.elementContainerService.deleteElementItem(this.elements, index);
    this.cd.markForCheck();
    this.customizeService.markChanged(immediate);
    this.updateElementStatesOnStable();
  }

  moveItemTo(element: ElementItem, link: any[]) {
    this.deleteItem(element);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(link));
  }

  get dropListBottomMargin() {
    return window.innerHeight * -1;
  }

  updateElementStatesOnStable() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStatesOnStable();
    }
  }

  updateElementStates() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStates();
    }
  }

  chooseTemplate() {
    this.chooseTemplateService.chooseTemplate(this.injector, {
      currentPage: this.page,
      chooseSection: false,
      initialSection: ChooseTemplateSection.Resources,
      cancel: true,
      analyticsSource: 'new_page',
      analyticsInnerSource: 'templates_popup'
    });
  }

  openPageTemplates() {
    this.pageTemplatesController
      .open({
        cancelEnabled: true,
        analyticsSource: 'new_page'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.startPage) {
          this.routing.navigateApp(result.startPage.link, { replaceUrl: true });
        }
      });
  }

  getAppUrl() {
    return window.location.href.replace('/app/', '/builder/');
  }

  openAppUrl() {
    const url = this.getAppUrl();
    openUrl(url, true);
  }
}

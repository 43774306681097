import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { inputAppend } from '@shared';

@Injectable()
export class InsertTokenService implements OnDestroy {
  lastFocusElement: Element;

  constructor() {
    fromEvent(window, 'focus', { capture: true })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const element = document.activeElement;

        if (this.isInput(element) || this.isAceEditor(element)) {
          this.lastFocusElement = document.activeElement;
        }
      });
  }

  ngOnDestroy(): void {}

  isInput(element) {
    return element instanceof HTMLInputElement;
  }

  isAceEditor(element) {
    return element && element.parentNode && (element.parentNode as HTMLElement).classList.contains('ace_editor');
  }

  insert(token: string) {
    const element = this.lastFocusElement;

    if (this.isInput(element)) {
      inputAppend(element, token);
    } else if (this.isAceEditor(element)) {
      const editor = element.parentNode['env'] ? element.parentNode['env']['editor'] : undefined;
      if (editor) {
        editor.session.replace(editor.selection.getRange(), token);
      }
    }
  }
}

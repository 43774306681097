import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class ChangeCustomViewForm {
  form: FormGroup;
  instance: CustomView;

  viewTypeOptions = [
    { value: CustomViewType.Common, name: 'Common' },
    { value: CustomViewType.ModelDescription, name: 'Collection' },
    { value: CustomViewType.Model, name: 'Record' }
  ];

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private fb: FormBuilder
  ) {
    this.init(undefined);
  }

  init(instance: CustomView) {
    this.instance = instance;
    this.form = this.fb.group({
      unique_name: new FormControl('jet-', Validators.required),
      name: new FormControl('', Validators.required),
      view_type: new FormControl('common', Validators.required),
      model_description: new FormControl(),
      dist: new FormControl('', Validators.required),
      params: new FormControl({}, Validators.required)
    });

    if (instance) {
      this.form.patchValue({
        unique_name: instance.uniqueName,
        name: instance.name,
        view_type: instance.viewType,
        model_description: instance.params['model_description']
          ? { model: instance.params['model_description'] }
          : instance.params['model_description'],
        dist: instance.dist,
        params: instance.params
      });
    } else {
      this.form.patchValue({ model_description: undefined }); // workaround to set undefined
    }
  }

  submit(): Observable<CustomView> {
    this.form.markAsDirty();

    const value = this.form.value;
    const instance = new CustomView();
    const params = {
      ...value['params'],
      model_description: value['model_description'] ? value['model_description']['model'] : value['model_description']
    };

    instance.uniqueName = value['unique_name'];
    instance.name = value['name'];
    instance.viewType = value['view_type'];
    instance.dist = value['dist'];
    instance.params = params;

    let obs: Observable<CustomView>;

    if (this.instance) {
      obs = this.customViewService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    } else {
      obs = this.customViewService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    }

    obs = obs.pipe(switchMap(() => this.customViewsStore.getDetail(instance.uniqueName, true)));

    obs.subscribe(
      () => {},
      error => this.formUtils.showFormErrors(this.form, error)
    );

    return obs;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';

import { colors, defaultAccentColor } from '@modules/colors';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ThemeContext } from '@modules/theme-components';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-color-field',
  templateUrl: 'color-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorFieldComponent extends FieldComponent {
  items = colors;
  editPopoverOpened = false;
  defaultAccentColor = defaultAccentColor;

  constructor(@Optional() private themeContext: ThemeContext, private cd: ChangeDetectorRef) {
    super();
  }

  get allowEmpty(): boolean {
    return this.field && this.field.params ? !!this.field.params['allow_empty'] : false;
  }

  get emptyColor(): string {
    return this.field && this.field.params ? this.field.params['empty_color'] : undefined;
  }

  get emptyAccentColor(): boolean {
    return this.field && this.field.params ? !!this.field.params['empty_accent_color'] : false;
  }

  get hasEmptyColor(): boolean {
    return isSet(this.emptyColor) || isSet(this.emptyAccentColor);
  }

  get currentValueEffective(): string {
    const value = this.currentValue;

    if (!isSet(value) && this.emptyAccentColor) {
      const accentColor = this.themeContext ? this.themeContext.getColorHex('accentColor') : undefined;
      return accentColor || defaultAccentColor;
    } else if (!isSet(value) && isSet(this.emptyColor)) {
      return this.emptyColor;
    }

    return value;
  }

  get color() {
    if (!this.currentValue) {
      return;
    }
    return this.items.find(item => item.name == this.currentValue);
  }

  openEditPopover(value) {
    if (this.control && this.control.disabled) {
      return;
    }

    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  setColor(color: string) {
    const patch = {};
    patch[this.field.name] = color;
    this.form.patchValue(patch);
  }
}

registerFieldComponent(FieldType.Color, ColorFieldComponent);

import { FormArray, ValidatorFn } from '@angular/forms';
import range from 'lodash/range';

import { KanbanBoardStage } from '@modules/customize';
import { isSet } from '@shared';

import { KanbanStageControl } from './kanban-stage.control';

export const validateStages: ValidatorFn = (control: KanbanStageArray) => {
  if (!control.value.length || control.value.some(item => !isSet(item.name))) {
    return { required: true };
  }
};

export class KanbanStageArray extends FormArray {
  controls: KanbanStageControl[];

  constructor(controls: KanbanStageControl[]) {
    super(controls, validateStages);
  }

  deserialize(value: KanbanBoardStage[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): KanbanBoardStage[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(groups: KanbanStageControl[]) {
    this.removeControls();
    groups.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(group: KanbanStageControl) {
    const index = this.controls.findIndex(item => item === group);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }

  createControl(stage?: KanbanBoardStage) {
    const control = new KanbanStageControl();

    if (stage) {
      control.deserialize(stage);
    }

    control.markAsPristine();
    return control;
  }

  appendControl(item?: KanbanBoardStage): KanbanStageControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  removeEmpty() {
    while (true) {
      const group = this.controls.find(item => !isSet(item.controls.value.value) && !isSet(item.controls.name.value));
      if (!group) {
        break;
      }

      this.removeControl(group);
    }
  }

  addMissingByValues(newValues: any[]) {
    this.removeEmpty();

    newValues
      .filter(value => value !== undefined)
      .forEach(value => {
        const stage = this.createControl();
        let name = value;

        if (name === null) {
          name = 'NULL';
        } else if (name === '') {
          name = 'Empty';
        }

        stage.controls.value.patchValue(value);
        stage.controls.name.patchValue(name);

        this.push(stage);
      });
  }
}

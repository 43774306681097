import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ViewContext } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Frame } from '@modules/views';
import { controlValue } from '@shared';
import { ColorControl } from '../../controls/color.control';
var ColorButtonComponent = /** @class */ (function () {
    function ColorButtonComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.colorOpened = false;
        this.colorPreviewSize = new Frame({ width: 32 - 2, height: 26 - 2 });
        this.colorPositions = [
            { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
        ];
    }
    ColorButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(controlValue(this.colorControl).pipe(map(function () { return _this.colorControl.serialize(); })), this.colorInputEnabledControl ? controlValue(this.colorInputEnabledControl) : of(false), this.colorInputControl
            ? controlValue(this.colorInputControl).pipe(map(function () { return _this.colorInputControl.serialize(); }))
            : of(undefined))
            .pipe(switchMap(function (_a) {
            var color = _a[0], colorInputEnabled = _a[1], colorInput = _a[2];
            if (colorInputEnabled && colorInput) {
                return applyParamInput$(colorInput, {
                    context: _this.viewContext,
                    defaultValue: ''
                });
            }
            else if (!colorInputEnabled && color) {
                return of(color.css());
            }
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.colorBackground = value;
            _this.cd.markForCheck();
        });
    };
    ColorButtonComponent.prototype.ngOnDestroy = function () { };
    ColorButtonComponent.prototype.setOpened = function (value) {
        this.colorOpened = value;
        this.cd.markForCheck();
    };
    ColorButtonComponent.prototype.open = function () {
        this.setOpened(true);
    };
    ColorButtonComponent.prototype.close = function () {
        this.setOpened(false);
    };
    return ColorButtonComponent;
}());
export { ColorButtonComponent };

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { FieldLookup } from './base';

export class ExactFieldLookup extends FieldLookup {
  public lookup = '';
  public param = 'eq';
  public verboseName = localize('equals');
  public icon = 'equals';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} does not equal {1}', [field, value]);
      } else {
        return localize('does not equal');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} equals {1}', [field, value]);
      } else {
        return localize('equals');
      }
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../register/register.component.ngfactory";
import * as i2 from "../register/register.form";
import * as i3 from "../../../auth/services/auth/auth.service";
import * as i4 from "../../../api/services/api/api.service";
import * as i5 from "../../../users/services/user/user.service";
import * as i6 from "../../../../common/form-utils/services/form-utils";
import * as i7 from "../login/login.form";
import * as i8 from "@angular/forms";
import * as i9 from "../register/register.component";
import * as i10 from "../../../routing/services/routing/routing.service";
import * as i11 from "../../../users/stores/current-user.store";
import * as i12 from "@angular/router";
import * as i13 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i14 from "../../../analytics/services/google-analytics/google-analytics.service";
import * as i15 from "../../../../core/services/app-config/app-config.service";
import * as i16 from "../../../../common/notifications/services/notification/notification.service";
import * as i17 from "../../../meta/services/meta.service";
import * as i18 from "../../../projects/stores/projects.store";
import * as i19 from "../../../projects/services/project-invite/project-invite.service";
import * as i20 from "../../../projects/services/project-public-invite/project-public-invite.service";
import * as i21 from "./register-route.component";
var styles_RegisterRouteComponent = [];
var RenderType_RegisterRouteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterRouteComponent, data: {} });
export { RenderType_RegisterRouteComponent as RenderType_RegisterRouteComponent };
export function View_RegisterRouteComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-register", [], null, null, null, i1.View_RegisterComponent_0, i1.RenderType_RegisterComponent)), i0.ɵprd(512, null, i2.RegisterForm, i2.RegisterForm, [i3.AuthService, i4.ApiService, i5.UserService, i6.FormUtils]), i0.ɵprd(512, null, i7.LoginForm, i7.LoginForm, [i6.FormUtils, i8.FormBuilder, i3.AuthService, i4.ApiService]), i0.ɵdid(3, 245760, null, 0, i9.RegisterComponent, [i2.RegisterForm, i7.LoginForm, i10.RoutingService, i11.CurrentUserStore, i12.ActivatedRoute, i13.UniversalAnalyticsService, i14.GoogleAnalyticsService, i15.AppConfigService, i3.AuthService, i16.NotificationService, i17.MetaService, i18.ProjectsStore, i4.ApiService, i19.ProjectInviteService, i20.ProjectPublicInviteService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_RegisterRouteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-register-route", [], null, null, null, View_RegisterRouteComponent_0, RenderType_RegisterRouteComponent)), i0.ɵdid(1, 245760, null, 0, i21.RegisterRouteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterRouteComponentNgFactory = i0.ɵccf("app-register-route", i21.RegisterRouteComponent, View_RegisterRouteComponent_Host_0, {}, {}, []);
export { RegisterRouteComponentNgFactory as RegisterRouteComponentNgFactory };

import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, InputValueType } from '@modules/fields';
import { getInputRepresentation } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, forceObservable, isSet } from '@shared';
import { InputsEditForm } from '../inputs-edit/inputs-edit.form';
var InputsEditItemComponent = /** @class */ (function () {
    function InputsEditItemComponent(analyticsService, cd) {
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.openedInitial = false;
        this.focusedOnOpen = false;
        this.placeholder = 'Text';
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.staticValueDisabled = false;
        this.collapse = true;
        this.remove = false;
        this.displayRequiredIndicator = false;
        this.requiredEditable = true;
        this.userInput = false;
        this.displayValueTypes = [InputValueType.Formula];
        this.createField = createFormFieldFactory();
        this.titleAdditionalOptions = [
            {
                value: true,
                name: 'Required to load data',
                subtitle: 'Component <u>will not display data</u> if this parameter is empty string, NULL or is not set'
            },
            {
                value: false,
                name: 'Optional to load data',
                subtitle: 'Component <u>will ignore this parameter</u> if it is empty string, NULL or is not set'
            }
        ];
        this.booleanFieldStyle = BooleanFieldStyle;
    }
    InputsEditItemComponent.prototype.ngOnInit = function () { };
    InputsEditItemComponent.prototype.ngOnDestroy = function () { };
    InputsEditItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes['itemForm']) {
            this.initItemGroup();
        }
    };
    InputsEditItemComponent.prototype.initItemGroup = function () {
        var _this = this;
        if (this.infoSubscription) {
            this.infoSubscription.unsubscribe();
        }
        if (this.analyticsSubscription) {
            this.analyticsSubscription.unsubscribe();
        }
        if (!this.itemForm) {
            return;
        }
        this.infoSubscription = combineLatest(
        // Title
        this.form.parameter$(this.itemForm).pipe(map(function (parameter) { return _this.getTitle(_this.itemForm.value['name'], parameter); })), 
        // Description
        this.form.parameter$(this.itemForm).pipe(map(function (parameter) { return _this.getDescription(parameter); })), 
        // Value description
        combineLatest(this.form.parameter$(this.itemForm), this.form.value$(this.itemForm)).pipe(switchMap(function (_a) {
            var parameter = _a[0], value = _a[1];
            return _this.getValueDescription(parameter, value);
        })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var title = _a[0], description = _a[1], valueDescription = _a[2];
            _this.title = title;
            _this.description = description;
            _this.valueDescription = valueDescription;
            _this.cd.markForCheck();
        });
        this.analyticsSubscription = this.itemForm.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            var input = _this.form.serializeValueItem(value);
            if (!input) {
                return;
            }
            if ((input.valueType == InputValueType.StaticValue && input.staticValue) ||
                (input.valueType == InputValueType.Filter && input.filterField && input.filterLookup) ||
                (input.valueType == InputValueType.Context && input.contextValue) ||
                (input.valueType == InputValueType.Formula && input.formulaValue) ||
                (input.valueType == InputValueType.TextInputs && input.textInputsValue && input.textInputsValue.ops.length)) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.SuccessfullySetUp, {
                    Object: _this.object,
                    Type: input.valueType
                });
            }
        });
        this.titleAdditional$ = combineLatest(controlValue(this.itemForm.controls['field']), controlValue(this.itemForm.controls['required']), this.form.parameter$(this.itemForm)).pipe(map(function (_a) {
            var field = _a[0], inputRequired = _a[1], parameter = _a[2];
            if (!field) {
                return;
            }
            else if (inputRequired || (parameter && parameter.required)) {
                return 'required';
            }
            else {
                return 'optional';
            }
        }));
    };
    InputsEditItemComponent.prototype.getTitle = function (name, parameter) {
        if (!parameter) {
            return name;
        }
        return parameter.verboseName || parameter.name;
    };
    InputsEditItemComponent.prototype.getInputRepresentation = function (input, parameter) {
        var _this = this;
        return this.form.filterFields$().pipe(switchMap(function (filterFields) {
            var result = getInputRepresentation(input, {
                context: _this.context,
                contextElement: _this.contextElement,
                contextElementPath: _this.contextElementPath,
                contextElementPaths: _this.contextElementPaths,
                filterFields: filterFields
            });
            return forceObservable(result);
        }), map(function (result) {
            return isSet(result) ? result : parameter.description;
        }));
    };
    InputsEditItemComponent.prototype.getDescription = function (parameter) {
        if (!parameter) {
            return 'Not existing';
        }
        else if (!parameter.field) {
            return;
        }
        return parameter.description;
    };
    InputsEditItemComponent.prototype.getValueDescription = function (parameter, value) {
        if (!parameter) {
            return of('Not existing');
        }
        else if (!parameter.field) {
            return of(null);
        }
        var input = this.form.serializeValueItem(value);
        if (!input) {
            return of(null);
        }
        if (!input.isSet() && isSet(parameter.description)) {
            return of(null);
        }
        return this.getInputRepresentation(input, parameter);
    };
    InputsEditItemComponent.prototype.removeItem = function () {
        this.form.arrayRemove(this.itemForm);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Deleted, {
            Object: this.object
        });
    };
    return InputsEditItemComponent;
}());
export { InputsEditItemComponent };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, QueryAction } from '@modules/actions';
import { ApiService } from '@modules/api';
import { ViewContextElement } from '@modules/customize';
import { FieldOutput, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';
import {
  Automation,
  AutomationService,
  AutomationTrigger,
  AutomationTriggerWebhook,
  IntervalAutomationTrigger,
  ModelAutomationTrigger,
  TimetableAutomationTrigger,
  TimetableType,
  WebhookAutomationTrigger,
  Workflow
} from '@modules/workflow';
import { forceObservable, isSet, TypedChanges } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowTriggerComponent } from '../customize-workflow-trigger/customize-workflow-trigger.component';

@Component({
  selector: 'app-workflow-trigger',
  templateUrl: './workflow-trigger.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowTriggerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() triggerEditable = false;
  @Input() trigger: AutomationTrigger;
  @Input() triggerOutputs: FieldOutput[];
  @Input() triggerData: any;
  @Input() workflow: Workflow;
  @Input() workflowEditable = false;
  @Input() parametersEnabled = false;
  @Input() parameters: ParameterField[];
  @Input() automation: Automation;
  @Input() label: string;
  @Input() icon: string;
  @Input() customizeInitial = false;
  @Output() triggerChanged = new EventEmitter<AutomationTrigger>();

  customizeComponentData$ = new BehaviorSubject<DynamicComponentArguments>(undefined);
  customizing$ = new BehaviorSubject<boolean>(false);
  triggerDescription: string;
  webhookSubscription: Subscription;
  webhookUpdated$ = new Subject<AutomationTriggerWebhook>();

  constructor(
    public customizeBarContext: CustomizeBarContext,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private automationService: AutomationService,
    private modelDescriptionStore: ModelDescriptionStore,
    private workflowEditContext: WorkflowEditContext,
    private actionService: ActionService,
    private contextElement: ViewContextElement,
    private apiService: ApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.contextElement.initGlobal({
      uniqueName: 'workflow',
      name: this.parametersEnabled ? 'Workflow parameters' : 'Trigger parameters'
    });
    this.updateContextOutputs();

    if (this.workflow && this.workflow.testParameters) {
      this.contextElement.setOutputValues(this.workflow.testParameters);
    }

    combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
      .pipe(
        debounceTime(10),
        map(([customizeComponentData, components]) => {
          return customizeComponentData && components[0] === customizeComponentData;
        }),
        startWith(false)
      )
      .pipe(untilDestroyed(this))
      .subscribe(value => this.customizing$.next(value));

    if (this.customizeInitial) {
      this.customize();
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<WorkflowTriggerComponent>): void {
    if (changes.triggerOutputs || changes.trigger || changes.parametersEnabled || changes.parameters) {
      this.updateTriggerDescription();
    }
  }

  updateContextOutputs() {
    if (this.webhookSubscription) {
      this.webhookSubscription.unsubscribe();
      this.webhookSubscription = undefined;
    }

    if (this.triggerOutputs) {
      this.contextElement.setOutputs(
        this.triggerOutputs.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          const icon = isSet(item.icon) ? item.icon : fieldDescription.icon;

          return {
            uniqueName: item.name,
            name: item.verboseName,
            icon: icon,
            // iconOrange: primaryKey,
            fieldType: item.field,
            fieldParams: item.params,
            external: true
          };
        })
      );
    } else if (this.triggerEditable) {
      if (this.trigger instanceof ModelAutomationTrigger) {
        const actionDescription = new ActionDescription();

        actionDescription.resource = this.trigger.resource;
        actionDescription.type = ActionType.Query;
        actionDescription.model = this.trigger.model;
        actionDescription.modelAction = this.trigger.action;
        actionDescription.queryAction = new QueryAction();
        actionDescription.queryAction.query = new ActionQuery();
        actionDescription.queryAction.query.queryType = QueryType.Simple;
        actionDescription.queryAction.query.simpleQuery = new actionDescription.queryAction.query.simpleQueryClass();
        actionDescription.queryAction.query.simpleQuery.name = `__${this.trigger.model}__${this.trigger.action}`;

        const action = new ActionItem();

        action.actionDescription = actionDescription;

        this.actionService
          .getActionOutputs(action)
          .pipe(untilDestroyed(this))
          .subscribe(result => {
            this.contextElement.setOutputs(
              result.outputs.map(item => {
                const fieldDescription = getFieldDescriptionByType(item.field);
                const icon = fieldDescription ? fieldDescription.icon : undefined;

                return {
                  uniqueName: item.name,
                  name: item.verboseName,
                  icon: icon,
                  // iconOrange: primaryKey,
                  fieldType: item.field,
                  fieldParams: item.params,
                  external: true
                };
              })
            );
          });
      } else if (this.trigger instanceof WebhookAutomationTrigger) {
        this.automationService
          .getAutomationTriggerWebhook(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            this.automation
          )
          .pipe(untilDestroyed(this))
          .subscribe(
            webhook => {
              this.updateWebhookContextOutputs(webhook);

              if (webhook) {
                this.webhookSubscription = this.automationService
                  .subscribeAutomationTriggerWebhook(
                    this.currentProjectStore.instance,
                    this.currentEnvironmentStore.instance,
                    webhook
                  )
                  .pipe(untilDestroyed(this))
                  .subscribe(result => {
                    this.updateWebhookContextOutputs(result);
                    this.webhookUpdated$.next(result);
                  });
              }
            },
            () => {
              this.updateWebhookContextOutputs(undefined);
            }
          );
      } else {
        this.contextElement.setOutputs([]);
      }
    } else {
      this.contextElement.setOutputs(
        this.parameters.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          const icon = fieldDescription ? fieldDescription.icon : undefined;

          return {
            uniqueName: item.name,
            name: item.verboseName,
            icon: icon,
            // iconOrange: primaryKey,
            fieldType: item.field,
            fieldParams: item.params,
            external: true
          };
        })
      );
    }
  }

  updateWebhookContextOutputs(webhook: AutomationTriggerWebhook) {
    const outputs = webhook && isSet(webhook.dataStructure) ? webhook.dataStructure : [];

    this.contextElement.setOutputs(
      outputs.map(item => {
        const fieldDescription = getFieldDescriptionByType(item.field);
        const icon = fieldDescription ? fieldDescription.icon : undefined;

        return {
          uniqueName: item.name,
          name: item.verboseName,
          icon: icon,
          // iconOrange: primaryKey,
          fieldType: item.field,
          fieldParams: item.params,
          external: true
        };
      })
    );
  }

  updateTriggerDescription() {
    forceObservable(this.getTriggerDescription())
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.triggerDescription = value;
        this.cd.markForCheck();
      });
  }

  getTriggerDescription(): string | Observable<string> {
    if (this.triggerOutputs) {
      return this.triggerOutputs.length == 1 ? '1 parameter' : `${this.triggerOutputs.length} parameters`;
    } else if (this.trigger instanceof WebhookAutomationTrigger) {
      const hookPath = this.trigger.getRunPath();

      if (!this.trigger) {
        return;
      }

      return this.apiService.workflowsMethodURL(hookPath);
    } else if (this.trigger instanceof IntervalAutomationTrigger) {
      return `Every ${
        this.trigger.interval > 1 ? `${this.trigger.interval} minutes` : `${this.trigger.interval} minute`
      }`;
    } else if (this.trigger instanceof TimetableAutomationTrigger) {
      if (this.trigger.timetableType == TimetableType.EveryDay) {
        if (!isSet(this.trigger.timetableTime)) {
          return 'Every day';
        }

        return `Every day at ${this.trigger.timetableTime.format('HH:mm')} UTC`;
      } else if (this.trigger.timetableType == TimetableType.EveryWeek) {
        if (
          !this.trigger.timetableDaysOfWeek ||
          !this.trigger.timetableDaysOfWeek.length ||
          !isSet(this.trigger.timetableTime)
        ) {
          return 'Every week';
        }

        const daysOfWeek = this.trigger.timetableDaysOfWeek.map(item =>
          moment().startOf('isoWeek').add(item, 'day').format('ddd')
        );
        return `Every ${daysOfWeek.join(', ')} at ${this.trigger.timetableTime.format('HH:mm')} UTC`;
      } else if (this.trigger.timetableType == TimetableType.EveryMonth) {
        if (
          !this.trigger.timetableMonths ||
          !this.trigger.timetableDays.length ||
          !this.trigger.timetableMonths.length ||
          !isSet(this.trigger.timetableTime)
        ) {
          return 'Every month';
        }

        const months = this.trigger.timetableMonths.map(item =>
          moment().startOf('year').add(item, 'month').format('MMM')
        );
        return `Every ${months.join(', ')} on ${this.trigger.timetableDays.join(
          ', '
        )} at ${this.trigger.timetableTime.format('HH:mm')} UTC`;
      } else if (this.trigger.timetableType == TimetableType.Once) {
        if (!this.trigger.timetableDate) {
          return 'Once';
        }

        return `At ${this.trigger.timetableDate.format('ll')}`;
      } else if (this.trigger.timetableType == TimetableType.Custom) {
        return `Custom`;
      }
    } else if (this.trigger instanceof ModelAutomationTrigger) {
      if (!isSet(this.trigger.resource) || !isSet(this.trigger.model) || !isSet(this.trigger.action)) {
        return;
      }

      const modelId = [this.trigger.resource, this.trigger.model].join('.');
      const action = this.trigger.action;

      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          if (!modelDescription) {
            return;
          }

          return [modelDescription.verboseNamePlural, action].join(' ');
        })
      );
    } else if (this.parametersEnabled) {
      return this.parameters.length == 1 ? '1 parameter' : `${this.parameters.length} parameters`;
    }
  }

  customize() {
    if (this.customizing$.value) {
      return;
    }

    const dynamicComponent: DynamicComponentArguments<CustomizeWorkflowTriggerComponent> = {
      component: CustomizeWorkflowTriggerComponent,
      inputs: {
        automation: this.automation,
        triggerEditable: this.triggerEditable,
        trigger: this.trigger,
        triggerOutputs: this.triggerOutputs,
        triggerData: this.triggerData,
        testParameters: this.workflow ? this.workflow.testParameters : undefined,
        workflowEditable: this.workflowEditable,
        parametersEnabled: this.parametersEnabled,
        parameters: this.parameters,
        webhookUpdated$: this.webhookUpdated$,
        contextElement: this.contextElement
      },
      outputs: {
        workflowChange: [
          result => {
            this.trigger = result.trigger;
            this.workflow.testParameters = result.testParameters;
            this.parameters.splice(0, this.parameters.length, ...result.parameters);
            this.cd.markForCheck();
            this.triggerChanged.emit(this.trigger);
            this.updateContextOutputs();
            this.workflowEditContext.markChanged();
          }
        ]
      }
    };

    this.customizeBarContext.setSettingsComponent(dynamicComponent);
    this.customizeComponentData$.next(dynamicComponent);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { CustomViewTemplate } from '../../data/custom-view-template';

export enum CustomViewTemplateMetricType {
  View = 'view',
  Usage = 'usage'
}

@Injectable({
  providedIn: 'root'
})
export class CustomViewTemplateService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(params = {}): Observable<CustomViewTemplateService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('custom_view_templates/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => new CustomViewTemplateService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(id: string): Observable<CustomViewTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/${id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers });
      }),
      map(result => new CustomViewTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(instance: CustomViewTemplate, fields?: string[]): Observable<CustomViewTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new CustomViewTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(instance: CustomViewTemplate, fields?: string[]): Observable<CustomViewTemplate> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/${instance.id}/`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new CustomViewTemplate().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(instance: CustomViewTemplate): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/${instance.id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  rate(id: string, rating: number): Observable<{ newRating: number }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/${id}/rate/`);
        const data = { rating: rating };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ success: boolean; new_rating: number }>(url, data, { headers: headers });
      }),
      map(result => {
        return {
          newRating: result.new_rating
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  metric(id: string, type: CustomViewTemplateMetricType): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`custom_view_templates/${id}/metric/`);
        const data = { type: type };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ success: boolean }>(url, data, { headers: headers });
      }),
      map(result => result.success),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace CustomViewTemplateService {
  export class GetResponse {
    public results: CustomViewTemplate[];
    public hasMore: boolean;
    public next: string;
    public previous: string;
    public count: number;
    public perPage = 20;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new CustomViewTemplate().deserialize(item));
      this.hasMore = !!data['next'];
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }

    get numPages() {
      return Math.ceil(this.count / this.perPage);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var ActionEditPopupForm = /** @class */ (function (_super) {
    __extends(ActionEditPopupForm, _super);
    function ActionEditPopupForm(actionDescriptionService, currentProjectStore, currentEnvironmentStore, actionStore, formUtils) {
        var _this = _super.call(this, {
            verbose_name: new FormControl('', Validators.required),
            description: new FormControl('')
        }, null, function (control) { return _this.checkUniqueName(control); }) || this;
        _this.actionDescriptionService = actionDescriptionService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.actionStore = actionStore;
        _this.formUtils = formUtils;
        return _this;
    }
    ActionEditPopupForm.prototype.checkUniqueName = function (control) {
        var _this = this;
        return this.actionStore.getFirst().pipe(map(function (actions) {
            if (!actions) {
                return null;
            }
            if (actions.find(function (item) {
                return item.id != _this.action.id &&
                    item.resource == _this.action.resource &&
                    item.verboseName == control.value['verbose_name'];
            })) {
                return {
                    local: ['Action with such Name already exists']
                };
            }
            return null;
        }));
    };
    ActionEditPopupForm.prototype.setInstance = function (action) {
        this.action = action;
        this.patchValue({
            verbose_name: action.verboseName,
            description: action.description
        });
    };
    ActionEditPopupForm.prototype.getInstance = function () {
        var value = this.value;
        var instance = new ActionDescription().deserialize(this.action.serialize());
        instance.verboseName = value['verbose_name'];
        instance.description = value['description'];
        return instance;
    };
    ActionEditPopupForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var instance = this.getInstance();
        return this.actionDescriptionService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(delayWhen(function () { return _this.actionStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ActionEditPopupForm;
}(FormGroup));
export { ActionEditPopupForm };

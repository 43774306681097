import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ElementType } from '@modules/customize';
import { Environment, Project } from '@modules/projects';
import { Deserializable } from '@shared';

import { AutomationTriggerType } from '../../data/automation-trigger-type';
import { Workflow } from '../../data/workflow';
import { WorkflowRunStatus } from '../../data/workflow-backend-run';
import { WorkflowBackendRunWithRelations } from '../../data/workflow-backend-run-with-relations';
import { WorkflowRun } from '../../data/workflow-run';

@Injectable({
  providedIn: 'root'
})
export class WorkflowBackendRunService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  get(
    project: Project,
    environment: Environment,
    options: {
      automationUid?: string;
      workflowUid?: string;
      page?: number;
    } = {}
  ): Observable<WorkflowBackendRunService.GetResponse<WorkflowBackendRunWithRelations>> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'workflow_runs'
        );
        let headers = new HttpHeaders();
        const params = {};

        if (options.automationUid) {
          params['automationUid'] = options.automationUid;
        }

        if (options.workflowUid) {
          params['workflowUid'] = options.workflowUid;
        }

        if (options.page) {
          params['page'] = options.page.toString();
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers, params: params });
      }),
      map(result =>
        new WorkflowBackendRunService.GetResponse<WorkflowBackendRunWithRelations>(
          WorkflowBackendRunWithRelations
        ).deserialize(result)
      ),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(project: Project, environment: Environment, id: string): Observable<WorkflowBackendRunWithRelations> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `workflow_runs/${id}`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers });
      }),
      map(result => new WorkflowBackendRunWithRelations().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createForWorkflow(
    projectName: string,
    environmentName: string,
    workflow: Workflow,
    success: boolean,
    workflowRun: WorkflowRun,
    dateRun: moment.Moment,
    dateFinished: moment.Moment,
    options: {
      pageUid?: string;
      elementUid?: string;
      elementType?: ElementType;
      elementLabel?: string;
      userUid?: string;
    } = {}
  ): Observable<WorkflowBackendRunWithRelations> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.workflowsEnvironmentMethodURL(projectName, environmentName, `workflow_runs`);
        let headers = new HttpHeaders();
        const data = {
          workflow_uid: workflow.uid,
          workflow: workflow.serialize(),
          status: success ? WorkflowRunStatus.Succeeded : WorkflowRunStatus.Failed,
          trigger_type: AutomationTriggerType.PageAction,
          trigger_params: {},
          success: success,
          workflow_run: workflowRun.serialize(),
          date_run: dateRun.toISOString(),
          date_finished: dateFinished.toISOString(),
          params: {
            page_uid: options.pageUid,
            element_uid: options.elementUid,
            element_type: options.elementType,
            element_label: options.elementLabel,
            user_uid: options.userUid
          }
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new WorkflowBackendRunWithRelations().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace WorkflowBackendRunService {
  export class GetResponse<T extends Deserializable<T>> {
    public results: T[];
    public count: number;
    public perPage: number;
    public numPages: number;

    constructor(private cls: Type<T>) {}

    deserialize(data: Object) {
      this.results = data['results'].map(item => new this.cls().deserialize(item));
      this.count = data['count'];
      this.perPage = data['per_page'];
      this.numPages = data['num_pages'];

      return this;
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { delayWhen, switchMap, tap } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettingsStore } from '@modules/customize';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, EnvironmentService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { TemplateService } from '@modules/template';
import { TemplateApplyController, TemplateItemType } from '@modules/template-components';

@Component({
  selector: 'app-apply-template',
  templateUrl: './apply-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyTemplateComponent implements OnInit, OnDestroy {
  loading = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private activatedRoute: ActivatedRoute,
    private templateService: TemplateService,
    private templateApplyController: TemplateApplyController,
    private environmentService: EnvironmentService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuService: MenuService,
    private menuSettingsStore: MenuSettingsStore,
    private injector: Injector,
    private routing: RoutingService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.None;
    this.initApplyTemplate();
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  initApplyTemplate() {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.loading = true;
          this.cd.markForCheck();

          return this.templateService.getDetail(params['id']);
        }),
        switchMap(template => {
          return this.templateApplyController
            .applyTemplateProcess(
              this.currentProjectStore.instance,
              this.currentEnvironmentStore.instance,
              this.injector,
              {
                type: TemplateItemType.Template,
                template: template,
                resourceNameEditing: false,
                useDemoResources: true,
                analyticsSource: 'my_projects'
              }
            )
            .pipe(
              tap(() => {
                this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Applied, {
                  TemplateID: template.name
                });
              })
            );
        }),
        delayWhen(() => {
          return this.environmentService.publishDraft(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName
          );
        }),
        delayWhen(() => {
          return combineLatest(
            this.viewSettingsStore.getFirst(true),
            this.modelDescriptionStore.getFirst(true),
            this.actionStore.getFirst(true),
            this.menuSettingsStore.getFirst(true),
            this.currentProjectStore.getFirst(true)
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          if (result.processed) {
            this.routing.navigateApp(result.link, { replaceUrl: true });
          } else {
            this.loading = false;
            this.cd.markForCheck();
          }
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }
}

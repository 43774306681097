<!--<app-admin-header-->
<!--  *ngIf="!(customizeService.enabled$ | async)"-->
<!--  [userActivitiesParams]="userActivitiesParams"-->
<!--  [collaborationParams]="userActivitiesParams"-->
<!--&gt;</app-admin-header>-->

<app-error [title]="'Deprecated page type'" [fill]="true">
  This page type is not supported in the current version.<br />
  Please downgrade to a version <strong>< 2.10.30</strong> or build a new page.
</app-error>

<!--<app-list-->
<!--  [userActivitiesParams]="userActivitiesParams"-->
<!--  [modelId]="modelId"-->
<!--  [params]="params"-->
<!--  (paramsChanged)="onParamsChanged($event)"-->
<!--&gt;</app-list>-->

import template from 'lodash/template';
import toPairs from 'lodash/toPairs';

import { isSet } from '../common/common';

export function interpolate(str: string, ctx: Object, disableInterpolate = false): string {
  if (!str) {
    return str;
  }

  const compiled = template(str, {
    interpolate: !disableInterpolate ? /{{([\s\S]+?)}}/g : null,
    evaluate: /{%([\s\S]+?)%}/g
  });

  return compiled(ctx);
}

export function interpolateSimple(value: string, tokens: Object, placeholder = '{{%s}}') {
  if (value === undefined || value === null) {
    return value;
  }

  if (typeof value != 'string') {
    return value;
  }

  // const resourceName = this.resourceForm.value['unique_name'];
  //
  // tokens = assign({}, { project: this.project.uniqueName, resource: resourceName }, tokens);

  return toPairs(tokens).reduce((prev, current) => {
    const token = current[0] as string;
    const replace = current[1] as string;
    const pattern = placeholder.replace('%s', token);
    return prev.replace(new RegExp(pattern, 'g'), replace);
  }, value);
}

export function format(str: string, args?: any[]) {
  if (!isSet(str)) {
    return;
  }

  let index = 0;

  return String(str).replace(/{((?:\d+)?)}/g, (_, accessor) => {
    if (accessor == '') {
      accessor = index;
    }

    const arg = args ? args[accessor] : undefined;
    const result = isSet(arg) ? arg : '';

    ++index;

    return result;
  });
}

export function interpolateSqlContext(str: string, ctx: Object): { str: string; params: string[] } {
  if (!str) {
    return;
  }

  const matches = str.match(/\{\{([^\}]+)\}\}/g);
  const query = str.replace(/\{\{([^\}]+)\}\}/g, '%s');
  const params = matches
    ? matches.map(item => {
        const value = item
          .slice(2)
          .slice(0, -2)
          .split('.')
          .reduce((prev, current) => {
            if (prev instanceof Object) {
              return prev[current];
            } else {
              return undefined;
            }
          }, ctx);

        return value != undefined ? value.toString() : '';
      })
    : [];

  return {
    str: query,
    params: params
  };
}

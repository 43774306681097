import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet, objectsListSort } from '@shared';

import { fieldTypesOrder } from '../data/field-type';
import { FieldType } from '../data/field-type';
import { FieldDescription, fieldDescriptions, getFieldDescriptionByType } from '../data/fields';
import { ParameterField } from '../data/parameter-field';
import { ParameterNameControl } from './parameter-name.control';

export class ParameterControl extends FormGroup {
  instance: ParameterField;
  controls: {
    name: ParameterNameControl;
    verboseName: FormControl;
    description: FormControl;
    field: FormControl;
    required: FormControl;
    editable: FormControl;
    defaultType: FormControl;
    defaultValue: FormControl;
    placeholder: FormControl;
    validatorType: FormControl;
    validatorParams: FormControl;
    params: FormControl;
    protected: FormControl;
  };

  fieldOptions = fieldDescriptions
    .filter(item => item.public)
    .map(item => {
      return {
        value: item.name,
        name: item.label,
        icon: item.icon
      };
    })
    .sort(objectsListSort(fieldTypesOrder, item => item.value));

  constructor() {
    super({
      name: new ParameterNameControl(''),
      verboseName: new FormControl(''),
      description: new FormControl(''),
      field: new FormControl(FieldType.Text),
      required: new FormControl(true),
      editable: new FormControl(true),
      defaultType: new FormControl(''),
      defaultValue: new FormControl(''),
      placeholder: new FormControl(''),
      validatorType: new FormControl(null),
      validatorParams: new FormControl(null),
      params: new FormControl({}),
      protected: new FormControl(false)
    });
  }

  deserialize(item: ParameterField) {
    this.instance = item;
    this.patchValue({
      name: item.name,
      verboseName: item.verboseName,
      description: item.description,
      field: item.field,
      required: item.required,
      // editable: item.editable,
      defaultType: item.defaultType,
      defaultValue: item.defaultValue,
      placeholder: item.placeholder,
      params: item.params,
      protected: item.protected
    });
    this.markAsPristine();
  }

  serialize(): ParameterField {
    const value = this.value;
    const result = new ParameterField();

    if (this.instance) {
      result.deserialize(this.instance.serialize());
    }

    result.name = value.name;
    result.verboseName = value.verboseName;
    result.description = value.description;
    result.field = value.field;
    result.required = value.required;
    // result.editable = value.editable;
    result.defaultType = value.defaultType;
    result.defaultValue = value.defaultValue;
    result.placeholder = value.placeholder;
    result.params = value.params;
    result.protected = value.protected;
    result.updateFieldDescription();

    return result;
  }

  hasName() {
    return isSet(this.controls.name.value);
  }

  fieldDescription$(): Observable<FieldDescription> {
    return controlValue<FieldType>(this.controls.field).pipe(map(value => getFieldDescriptionByType(value)));
  }

  fieldIcon$(): Observable<string> {
    return this.fieldDescription$().pipe(map(value => value.icon));
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { FormulaSectionItem } from '../../data/formula-section';
import { formulaTokensEqual } from '../../utils/view-context-tokens';

@Pipe({
  name: 'appFormulaTokenEqual'
})
export class FormulaTokenEqualPipe implements PipeTransform {
  transform(lhs: FormulaSectionItem, rhs: FormulaSectionItem): any {
    return formulaTokensEqual(lhs, rhs);
  }
}

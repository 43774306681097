<div
  *ngIf="parentElement"
  class="customize-selector-parent"
  [class.customize-selector-parent_hover]="hoverParent$ | async"
  [class.customize-selector-parent_active]="active"
  (click)="customizeParent.emit($event)"
>
  <div
    class="customize-selector-parent-icon icon-arrow_up"
    (mousemove)="hoverParent$.next(true)"
    (mouseleave)="hoverParent$.next(false)"
  ></div>
  <div class="customize-selector-parent-label">
    {{ parentElement.displayName }}
    <span class="customize-selector-parent-label-tip">parent</span>
  </div>
</div>

<div
  *ngIf="!parentElement && parentPopup"
  class="customize-selector-parent"
  [class.customize-selector-parent_hover]="hoverParent$ | async"
  [class.customize-selector-parent_active]="active"
  (click)="customizePopup.emit($event)"
>
  <div
    class="customize-selector-parent-icon icon-arrow_up"
    (mousemove)="hoverParent$.next(true)"
    (mouseleave)="hoverParent$.next(false)"
  ></div>
  <div class="customize-selector-parent-label">
    {{ parentPopup.name || 'modal' }}
    <span class="customize-selector-parent-label-tip">parent</span>
  </div>
</div>

<div class="customize-toolbar-item">
  <div class="customize-selector" [class.customize-selector_active]="active">
    <div
      class="customize-selector__handle"
      [class.customize-selector__handle_parent-padding]="parentElement || parentPopup"
      appDragHandle
    >
      <div *ngIf="!parentElement && !parentPopup" class="customize-selector__label-drag icon-dots_small"></div>
      <span class="customize-selector__label">{{ displayName }}</span>
    </div>

    <div class="customize-selector__separator"></div>
    <div class="customize-selector__controls">
      <a
        *ngIf="customizeEnabled"
        href="javascript:void(0)"
        class="customize-selector-control"
        tabindex="-1"
        [appTip]="'Customize'"
        [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
        (click)="customize.emit($event)"
      >
        <span class="customize-selector-control__icon icon-gear"></span>
      </a>

      <div *ngIf="isStaff" class="customize-selector__dropdown-wrapper staff-block">
        <span class="customize-selector-control" tabindex="-1">
          <span class="customize-selector-control__icon icon-power"></span>
        </span>

        <div class="customize-selector__dropdown" [class.customize-selector__dropdown_bottom]="bottom">
          <div class="customize-selector" [class.customize-selector_active]="active">
            <a
              href="javascript:void(0)"
              class="customize-selector-control customize-selector-control_label"
              tabindex="-1"
              (click)="createTemplate.emit()"
            >
              <div class="customize-selector-control__label">
                Save Template
              </div>
            </a>
            <div class="customize-selector__separator"></div>
            <a
              href="javascript:void(0)"
              class="customize-selector-control customize-selector-control_label"
              tabindex="-1"
              (click)="setDefaultElement.emit()"
            >
              <div class="customize-selector-control__label">
                Set Default
              </div>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="alignmentHorizontalEnabled" class="customize-selector__dropdown-wrapper">
        <span class="customize-selector-control" tabindex="-1">
          <span
            class="customize-selector-control__icon"
            [class.icon-align_justify]="!alignmentHorizontal || alignmentHorizontal == alignsHorizontal.Justify"
            [class.icon-align_left]="alignmentHorizontal == alignsHorizontal.Left"
            [class.icon-align_center]="alignmentHorizontal == alignsHorizontal.Center"
            [class.icon-align_right]="alignmentHorizontal == alignsHorizontal.Right"
          ></span>
        </span>

        <div class="customize-selector__dropdown" [class.customize-selector__dropdown_bottom]="bottom">
          <div class="customize-selector" [class.customize-selector_active]="active">
            <div class="customize-selector__controls">
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Justify'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignHorizontal.emit(alignsHorizontal.Justify)"
              >
                <span class="customize-selector-control__icon icon-align_justify"></span>
              </a>
              <div class="customize-selector__separator"></div>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Left'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignHorizontal.emit(alignsHorizontal.Left)"
              >
                <span class="customize-selector-control__icon icon-align_left"></span>
              </a>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Center'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignHorizontal.emit(alignsHorizontal.Center)"
              >
                <span class="customize-selector-control__icon icon-align_center"></span>
              </a>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Right'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignHorizontal.emit(alignsHorizontal.Right)"
              >
                <span class="customize-selector-control__icon icon-align_right"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="alignmentVerticalEnabled" class="customize-selector__dropdown-wrapper">
        <span class="customize-selector-control" tabindex="-1">
          <span
            class="customize-selector-control__icon"
            [class.icon-resize_vertical]="!alignmentVertical || alignmentVertical == alignsVertical.Justify"
            [class.icon-align_vertical_top]="alignmentVertical == alignsVertical.Top"
            [class.icon-align_vertical_center]="alignmentVertical == alignsVertical.Center"
            [class.icon-align_vertical_bottom]="alignmentVertical == alignsVertical.Bottom"
          ></span>
        </span>

        <div class="customize-selector__dropdown" [class.customize-selector__dropdown_bottom]="bottom">
          <div class="customize-selector" [class.customize-selector_active]="active">
            <div class="customize-selector__controls">
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Fill'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignVertical.emit(undefined)"
              >
                <span class="customize-selector-control__icon icon-resize_vertical"></span>
              </a>
              <div class="customize-selector__separator"></div>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Top'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignVertical.emit(alignsVertical.Top)"
              >
                <span class="customize-selector-control__icon icon-align_vertical_top"></span>
              </a>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Center'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignVertical.emit(alignsVertical.Center)"
              >
                <span class="customize-selector-control__icon icon-align_vertical_center"></span>
              </a>
              <a
                href="javascript:void(0)"
                class="customize-selector-control"
                tabindex="-1"
                [appTip]="'Align Bottom'"
                [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
                (click)="alignVertical.emit(alignsVertical.Bottom)"
              >
                <span class="customize-selector-control__icon icon-align_vertical_bottom"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <a
        *ngIf="duplicateEnabled"
        href="javascript:void(0)"
        class="customize-selector-control"
        tabindex="-1"
        [appTip]="'Duplicate'"
        [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
        (click)="$event.stopPropagation(); duplicate.emit()"
      >
        <span class="customize-selector-control__icon icon-duplicate_2"></span>
      </a>

      <ng-container *ngIf="moveEnabled">
        <ng-template
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          [cdkConnectedOverlayOpen]="movePagesOpened"
          [cdkConnectedOverlayOrigin]="move_pages_trigger"
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'theme_dark']"
          [cdkConnectedOverlayPositions]="movePagesPositions"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          (backdropClick)="setMovePagesOpened(false)"
          (detach)="setMovePagesOpened(false)"
          cdkConnectedOverlay
        >
          <app-choose-page-dropdown
            class="customize-selector-control__dropdown overlay-content overlay-content_animated"
            [currentPage]="(customizeService.handlerInfo$ | async)?.page"
            [currentPageAllowed]="false"
            (chosen)="moveTo.emit($event); setMovePagesOpened(false)"
          ></app-choose-page-dropdown>
        </ng-template>

        <a
          href="javascript:void(0)"
          class="customize-selector-control"
          tabindex="-1"
          [appTip]="'Move to another Page'"
          [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
          (click)="setMovePagesOpened(true)"
          cdkOverlayOrigin
          #move_pages_trigger="cdkOverlayOrigin"
        >
          <span class="customize-selector-control__icon icon-redo"></span>
        </a>
      </ng-container>

      <a
        *ngIf="deleteEnabled"
        href="javascript:void(0)"
        class="customize-selector-control"
        tabindex="-1"
        [appTip]="'Delete'"
        [appTipOptions]="{ side: bottom ? 'bottom' : 'top', showDelay: tipShowDelay }"
        (click)="$event.stopPropagation(); delete.emit()"
      >
        <span class="customize-selector-control__icon icon-bin"></span>
      </a>
    </div>
  </div>
</div>

<div
  *ngIf="elementComponent?.actions.length"
  class="customize-selector customize-toolbar-item"
  [class.customize-selector_active]="active"
>
  <ng-container *ngFor="let item of elementComponent.actions; let last = last">
    <a
      href="javascript:void(0)"
      class="customize-selector-control customize-selector-control_label"
      tabindex="-1"
      (click)="action.next(item.name)"
    >
      <div class="customize-selector-control__label">
        {{ item.label }}
      </div>
    </a>
    <div *ngIf="!last" class="customize-selector__separator"></div>
  </ng-container>
</div>

<div
  *ngIf="elementComponent?.toolbar"
  class="customize-selector customize-toolbar-item"
  [class.customize-selector_active]="false"
  [class.customize-selector_hidden]="!active"
  #toolbar
></div>

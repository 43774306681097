/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/deploy-css-url/deploy-css-url.pipe";
import * as i3 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i4 from "./page-templates-choose-item-model.component";
import * as i5 from "../../../projects/stores/current-environment.store";
import * as i6 from "../../../model-queries/stores/model-description.store";
import * as i7 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_PageTemplatesChooseItemModelComponent = [];
var RenderType_PageTemplatesChooseItemModelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatesChooseItemModelComponent, data: {} });
export { RenderType_PageTemplatesChooseItemModelComponent as RenderType_PageTemplatesChooseItemModelComponent };
function View_PageTemplatesChooseItemModelComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "choose-template-list-item choose-template-list-item_border choose-template-list-item_interactive"]], [[2, "choose-template-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedResource(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "choose-template-list-item__icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "choose-template-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵpod(9, { "=1": 0, other: 1 }), i0.ɵppd(10, 2), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "choose-template-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "choose-template-list-item__icon icon-arrow_forward_2"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedResource === _v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), (("/assets/images/resources/icons/" + _v.parent.context.$implicit.resource.typeItem.icon) + ".svg"))); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.resource.name; _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent.parent.parent, 1), _v.parent.context.$implicit.models.length, _ck(_v, 9, 0, "# collection", "# collections"))); _ck(_v, 8, 0, currVal_3); }); }
function View_PageTemplatesChooseItemModelComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-template-list2-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.error; _ck(_v, 1, 0, currVal_0); }); }
function View_PageTemplatesChooseItemModelComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "choose-template-list-item choose-template-list2-item_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "choose-template-list-item__icon icon-warning_filled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.error; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.resource.name; _ck(_v, 5, 0, currVal_0); }); }
function View_PageTemplatesChooseItemModelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "choose-template-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.disabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.disabled; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PageTemplatesChooseItemModelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "choose-template__content-navigation-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "choose-template-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_3)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resources; _ck(_v, 4, 0, currVal_0); }, null); }
function View_PageTemplatesChooseItemModelComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "choose-template-list-item choose-template-list-item_contrast"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "choose-template-list-item__icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "choose-template-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵpod(9, { "=1": 0, other: 1 }), i0.ɵppd(10, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 0), (("/assets/images/resources/icons/" + _co.selectedResource.resource.typeItem.icon) + ".svg"))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedResource.resource.name; _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent.parent, 1), _co.selectedResource.models.length, _ck(_v, 9, 0, "# collection", "# collections"))); _ck(_v, 8, 0, currVal_2); }); }
function View_PageTemplatesChooseItemModelComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "choose-template-list-item choose-template-list-item_contrast choose-template-list-item_interactive"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedResource(undefined) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "choose-template-list-item__icon icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "choose-template-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵpod(8, { "=1": 0, other: 1 }), i0.ɵppd(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "choose-template-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "choose-template-list-item__icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵppd(12, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedResource.resource.name; _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent.parent, 1), _co.selectedResource.models.length, _ck(_v, 8, 0, "# collection", "# collections"))); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v.parent.parent.parent, 0), (("/assets/images/resources/icons/" + _co.selectedResource.resource.typeItem.icon) + ".svg"))); _ck(_v, 11, 0, currVal_2); }); }
function View_PageTemplatesChooseItemModelComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "choose-template-list-item choose-template-list-item_interactive"]], [[2, "choose-template-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setSelectedModel(_v.parent.context.$implicit);
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "choose-template-list-item__icon icon-documents"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 1), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "choose-template-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "choose-template-list-item__icon icon-arrow_forward_2"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedModel === _v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent.parent.parent, 2), _v.parent.context.$implicit.modelDescription.verboseNamePlural)); _ck(_v, 5, 0, currVal_1); }); }
function View_PageTemplatesChooseItemModelComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-template-list2-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.error; _ck(_v, 1, 0, currVal_0); }); }
function View_PageTemplatesChooseItemModelComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "choose-template-list-item choose-template-list2-item_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "choose-template-list-item__icon icon-warning_filled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_13)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.error; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent.parent.parent, 2), _v.parent.context.$implicit.modelDescription.verboseNamePlural)); _ck(_v, 5, 0, currVal_0); }); }
function View_PageTemplatesChooseItemModelComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "choose-template-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_11)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_12)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.disabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.disabled; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PageTemplatesChooseItemModelComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "choose-template__content-navigation-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "choose-template__content-navigation-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "choose-template-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_10)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.resource; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.selectedResource.models; _ck(_v, 9, 0, currVal_2); }, null); }
function View_PageTemplatesChooseItemModelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedResource; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedResource; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PageTemplatesChooseItemModelComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "choose-template-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "choose-template-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 5, 0, currVal_1); }); }
function View_PageTemplatesChooseItemModelComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "choose-template__content-navigation-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "choose-template-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PageTemplatesChooseItemModelComponent_15)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(5, 6)], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, "long item", "longasdads item", "longasdads item", "medium", "long item", "item"); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_PageTemplatesChooseItemModelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.DeployCssUrlPipe, []), i0.ɵpid(0, i1.I18nPluralPipe, [i1.NgLocalization]), i0.ɵpid(0, i3.CapitalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemModelComponent_14)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PageTemplatesChooseItemModelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-templates-choose-item-model", [], null, null, null, View_PageTemplatesChooseItemModelComponent_0, RenderType_PageTemplatesChooseItemModelComponent)), i0.ɵdid(1, 245760, null, 0, i4.PageTemplatesChooseItemModelComponent, [i5.CurrentEnvironmentStore, i6.ModelDescriptionStore, i7.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatesChooseItemModelComponentNgFactory = i0.ɵccf("app-page-templates-choose-item-model", i4.PageTemplatesChooseItemModelComponent, View_PageTemplatesChooseItemModelComponent_Host_0, { template: "template", templateVariant: "templateVariant", resource: "resource", analyticsSource: "analyticsSource" }, { selectModel: "selectModel", backClick: "backClick", cancelClick: "cancelClick" }, []);
export { PageTemplatesChooseItemModelComponentNgFactory as PageTemplatesChooseItemModelComponentNgFactory };

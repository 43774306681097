<div
  class="sidebar-section"
  [class.sidebar-section_border]="border"
  [class.sidebar-section_disabled]="disabled"
  [class.sidebar-section_interactive]="interactive"
>
  <div *ngIf="title || description" class="sidebar-section__header">
    <div class="sidebar-section__header-main">
      <div *ngIf="title" class="sidebar-section__title">
        {{ title }}

        <app-help-icon
          *ngIf="documentation"
          [documentation]="documentation"
          [label]="documentationLabel"
          [left]="documentationLeft"
          [source]="documentationSource"
          class="sidebar-section__title-action"
        ></app-help-icon>

        <ng-content select="ng-container[header-left]"></ng-content>
      </div>
      <div *ngIf="description" class="sidebar-section__description" [innerHTML]="description"></div>
      <ng-content select="ng-container[header]"></ng-content>
    </div>
    <div class="sidebar-section__header-right">
      <ng-content select="ng-container[header-right]"></ng-content>
    </div>
  </div>
  <ng-content></ng-content>
</div>

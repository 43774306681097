/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-columns.component";
var styles_PageColumnsComponent = [];
var RenderType_PageColumnsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageColumnsComponent, data: {} });
export { RenderType_PageColumnsComponent as RenderType_PageColumnsComponent };
export function View_PageColumnsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageColumnsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-columns", [], [[2, "page-columns", null], [2, "page-columns_border", null], [2, "page-columns_disabled", null], [2, "page-columns_inner-padding-size_none", null], [2, "page-columns_inner-padding-size_xs", null], [2, "page-columns_inner-padding-size_s", null], [2, "page-columns_inner-padding-size_m", null]], null, null, View_PageColumnsComponent_0, RenderType_PageColumnsComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageColumnsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).border; var currVal_2 = i0.ɵnov(_v, 1).disabled; var currVal_3 = i0.ɵnov(_v, 1).paddingSizeNone; var currVal_4 = i0.ɵnov(_v, 1).paddingSizeXs; var currVal_5 = i0.ɵnov(_v, 1).paddingSizeS; var currVal_6 = i0.ɵnov(_v, 1).paddingSizeM; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
var PageColumnsComponentNgFactory = i0.ɵccf("app-page-columns", i1.PageColumnsComponent, View_PageColumnsComponent_Host_0, { innerPaddingSize: "innerPaddingSize", disabled: "disabled" }, {}, ["*"]);
export { PageColumnsComponentNgFactory as PageColumnsComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';
import { DashboardService } from '../services/dashboard/dashboard.service';
var DashboardStore = /** @class */ (function (_super) {
    __extends(DashboardStore, _super);
    function DashboardStore(dashboardService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    DashboardStore.prototype.fetch = function () {
        if (!this.currentProjectStore.instance) {
            return of([]);
        }
        return this.dashboardService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(map(function (result) {
            if (!result) {
                return [];
            }
            return result;
        }), catchError(function () {
            return of([]);
        }));
    };
    DashboardStore.prototype.getDetail = function (id, forceUpdate) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.get(forceUpdate).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.id == id; });
        }));
    };
    DashboardStore.prototype.getDetailFirst = function (id, forceUpdate) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.getFirst(forceUpdate).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.id == id; });
        }));
    };
    return DashboardStore;
}(SingletonStore));
export { DashboardStore };

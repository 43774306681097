<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="close.emit()"
  (detach)="close.emit()"
>
  <div class="overlay-content overlay-content_animated">
    <div class="dropdown-selector dropdown-selector_small-border-radius">
      <div class="dropdown-selector__section dropdown-selector__section_margin_xs">
        <div class="dropdown-selector__section-title">
          Choose camera
        </div>
        <div class="dropdown-selector__section-content">
          <div *ngFor="let item of cameras" class="dropdown-selector__item" (click)="selectCamera.emit(item)">
            <div class="dropdown-selector__item-main">
              <div class="dropdown-selector__item-title">
                {{ item.label }}
              </div>
            </div>
            <div class="dropdown-selector__item-right">
              <span *ngIf="selectedCamera == item.id" class="dropdown-selector__item-optional">current</span>
              <span *ngIf="selectedCamera != item.id" class="dropdown-selector__item-check icon-arrow_forward_2"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

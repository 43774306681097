export enum ElementType {
  Section = 'section_layout', // deprecated
  Columns = 'columns_layout',
  Card = 'card_layout',
  Stack = 'stack_layout', // deprecated
  Tabs = 'tabs_layout',
  Collapse = 'collapse',
  Accordion = 'accordion',
  Title = 'title', // deprecated
  Text = 'text',
  Field = 'field',
  Action = 'action',
  ActionGroup = 'action_group',
  ActionDropdown = 'action_dropdown',
  Widget = 'widget',
  Image = 'image',
  List = 'list',
  Filter = 'filter',
  Model = 'model',
  Form = 'form',
  FormSubmit = 'form_submit',
  Back = 'back',
  IFrame = 'iframe',
  QrCode = 'qr_code',
  BarCode = 'bar_code',
  Alert = 'alert',
  Scanner = 'scanner',
  Spacing = 'spacing',
  Separator = 'separator',
  Steps = 'steps',
  FileViewer = 'file_viewer',
  RangeSlider = 'range_slider',
  DateRange = 'date_range',
  Custom = 'custom'
}

export const elementItemDataCategories = {
  [ElementType.Section]: 'Layout',
  [ElementType.Columns]: 'Layout',
  [ElementType.Card]: 'Layout',
  [ElementType.Stack]: 'Layout',
  [ElementType.Tabs]: 'Layout',
  [ElementType.Collapse]: 'Layout',
  [ElementType.Accordion]: 'Layout',
  [ElementType.Title]: 'Layout',
  [ElementType.Text]: 'Layout',
  [ElementType.Field]: 'Fields',
  [ElementType.List]: 'Collections',
  [ElementType.Model]: 'Collections',
  [ElementType.Custom]: 'Advanced',
  [ElementType.Form]: 'Actions',
  [ElementType.Action]: 'Actions',
  [ElementType.ActionGroup]: 'Actions',
  [ElementType.ActionDropdown]: 'Actions',
  [ElementType.Widget]: 'Charts'
};

export const elementItemCategories = {
  [ElementType.Section]: 'Layout',
  [ElementType.Columns]: 'Layout',
  [ElementType.Card]: 'Layout',
  [ElementType.Stack]: 'Layout',
  [ElementType.Tabs]: 'Layout',
  [ElementType.Collapse]: 'Layout',
  [ElementType.Accordion]: 'Layout',
  [ElementType.Title]: 'Layout',
  [ElementType.Text]: 'Layout',
  [ElementType.List]: 'Lists',
  [ElementType.Field]: 'Fields',
  [ElementType.Model]: 'Details',
  [ElementType.Scanner]: 'Advanced',
  [ElementType.Custom]: 'Advanced',
  [ElementType.Form]: 'Actions',
  [ElementType.Action]: 'Actions',
  [ElementType.ActionGroup]: 'Actions',
  [ElementType.ActionDropdown]: 'Actions',
  [ElementType.Widget]: 'Charts'
};

export const elementItemImages = {
  [ElementType.Section]: 'section_layout',
  [ElementType.Columns]: 'columns_layout',
  [ElementType.Card]: 'card_layout',
  [ElementType.Tabs]: 'tabs_layout',
  [ElementType.Collapse]: 'collapse',
  [ElementType.Accordion]: 'accordion',
  [ElementType.Title]: 'heading',
  [ElementType.Text]: 'text',
  [ElementType.Field]: 'text_field',
  [ElementType.Action]: 'button',
  [ElementType.Widget]: 'line_chart',
  [ElementType.List]: 'table_list',
  [ElementType.Model]: 'detail',
  [ElementType.Custom]: 'form'
};

export enum FieldType {
  Boolean = 'boolean',
  Translatable = 'translatable',
  Select = 'select',
  MultipleSelect = 'multiple_select',
  RadioButton = 'radio_button',
  RelatedModel = 'related_model',
  DateTime = 'datetime',
  // Timestamp = 'timestamp',
  // Date = 'date',
  Time = 'time',
  JSON = 'json',
  File = 'file',
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  Sql = 'sql',
  Code = 'code',
  Icon = 'icon',
  Color = 'color',
  ModelDescription = 'model_description',
  FieldType = 'field_type',
  Resource = 'resource',
  CustomView = 'custom_view',
  CustomAction = 'Custom_action',
  Text = 'text',
  RichText = 'rich_text',
  Password = 'password',
  Number = 'number',
  Rating = 'rating',
  Slider = 'slider',
  Signature = 'signature',
  URL = 'url',
  Filters = 'filters',
  Location = 'location'
}

export const defaultFieldType = FieldType.Text;

export const fieldTypesOrder = [
  FieldType.Text,
  FieldType.RichText,
  FieldType.Boolean,
  FieldType.File,
  FieldType.Image,
  FieldType.RelatedModel,
  FieldType.Select,
  FieldType.MultipleSelect,
  FieldType.RadioButton,
  FieldType.DateTime,
  FieldType.Time,
  FieldType.URL,
  FieldType.Password,
  FieldType.Number,
  FieldType.Rating,
  FieldType.Slider,
  FieldType.Location,
  FieldType.Video,
  FieldType.Audio,
  FieldType.Signature,
  FieldType.Color,
  FieldType.JSON
];

export const fileFieldTypes = [FieldType.File, FieldType.Image, FieldType.Video, FieldType.Audio];

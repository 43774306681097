import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MenuContext } from '@modules/menu-components';

import { CustomizeBarPagesEditShareControl } from '../customize-bar-pages-edit/share-control';

@Component({
  selector: 'app-share-menu-item-popover',
  templateUrl: './share-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareMenuItemPopoverComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditShareControl;
  @Output() close = new EventEmitter<void>();

  analyticsSource = 'separator_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

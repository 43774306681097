/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/popup2/popup2.component.ngfactory";
import * as i2 from "../../../../ui/components/popup2/popup2.component";
import * as i3 from "../../../../ui/components/popup2-header/popup2-header.component.ngfactory";
import * as i4 from "../../../../ui/components/popup2-header/popup2-header.component";
import * as i5 from "../../../../ui/components/popup2-title/popup2-title.component.ngfactory";
import * as i6 from "../../../../ui/components/popup2-title/popup2-title.component";
import * as i7 from "../../../../ui/components/popup2-description/popup2-description.component.ngfactory";
import * as i8 from "../../../../ui/components/popup2-description/popup2-description.component";
import * as i9 from "@angular/common";
import * as i10 from "../../../features/components/feature-overview/feature-overview.component.ngfactory";
import * as i11 from "../../../features/components/feature-overview/feature-overview.component";
import * as i12 from "../../../projects/stores/current-project.store";
import * as i13 from "./project-public-access.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../projects/services/project-public-access/project-public-access.service";
import * as i16 from "../../../api/services/api/api.service";
import * as i17 from "../../../users/stores/current-user.store";
import * as i18 from "../../../projects/stores/projects.store";
import * as i19 from "../../../customize/services/view-settings/view-settings.service";
import * as i20 from "../../../routing/services/routing/routing.service";
import * as i21 from "../../../../core/services/session-storage/session.storage";
import * as i22 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_ProjectPublicAccessComponent = [];
var RenderType_ProjectPublicAccessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectPublicAccessComponent, data: {} });
export { RenderType_ProjectPublicAccessComponent as RenderType_ProjectPublicAccessComponent };
function View_ProjectPublicAccessComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "loader loader_screen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "loader__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-double-pulse"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "spinner-double-pulse__pulse1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "spinner-double-pulse__pulse2"]], null, null, null, null, null))], null, null); }
function View_ProjectPublicAccessComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "popups popups_visible"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "popup-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "popup-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "popup popup_shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 9, "app-popup2", [], null, null, null, i1.View_Popup2Component_0, i1.RenderType_Popup2Component)), i0.ɵdid(5, 114688, null, 0, i2.Popup2Component, [], { size: [0, "size"] }, null), (_l()(), i0.ɵeld(6, 0, null, 0, 7, "app-popup2-header", [], null, null, null, i3.View_Popup2HeaderComponent_0, i3.RenderType_Popup2HeaderComponent)), i0.ɵdid(7, 114688, null, 0, i4.Popup2HeaderComponent, [], null, null), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "app-popup2-title", [["align", "center"]], null, null, null, i5.View_Popup2TitleComponent_0, i5.RenderType_Popup2TitleComponent)), i0.ɵdid(9, 114688, null, 0, i6.Popup2TitleComponent, [], { align: [0, "align"] }, null), (_l()(), i0.ɵted(-1, 0, ["Application not found"])), (_l()(), i0.ɵeld(11, 0, null, 1, 2, "app-popup2-description", [["align", "center"]], null, null, null, i7.View_Popup2DescriptionComponent_0, i7.RenderType_Popup2DescriptionComponent)), i0.ɵdid(12, 114688, null, 0, i8.Popup2DescriptionComponent, [], { align: [0, "align"] }, null), (_l()(), i0.ɵted(-1, 0, [" Looks like this public application was disabled or you have followed the wrong link. "]))], function (_ck, _v) { var currVal_0 = "m"; _ck(_v, 5, 0, currVal_0); _ck(_v, 7, 0); var currVal_1 = "center"; _ck(_v, 9, 0, currVal_1); var currVal_2 = "center"; _ck(_v, 12, 0, currVal_2); }, null); }
function View_ProjectPublicAccessComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectPublicAccessComponent_2)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectPublicAccessComponent_3)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.notFound; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProjectPublicAccessComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "app-feature-overview", [], null, null, null, i10.View_FeatureOverviewComponent_0, i10.RenderType_FeatureOverviewComponent)), i0.ɵdid(1, 245760, null, 0, i11.FeatureOverviewComponent, [[2, i12.CurrentProjectStore]], { feature: [0, "feature"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "div", [["data-subtitle", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Paid Feature"])), (_l()(), i0.ɵeld(4, 0, null, 1, 3, "div", [["data-title", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Public access"])), (_l()(), i0.ɵted(-1, null, [" feature is not enabled in the current App plan"])), (_l()(), i0.ɵeld(8, 0, null, 2, 3, "div", [["data-description", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ask App administrator to upgrade plan"]))], function (_ck, _v) { var currVal_0 = "permissions"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProjectPublicAccessComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "status-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "status-page__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "status-page__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [["class", "status-page__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This page is disabled"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "status-page__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Looks like this page is disabled or maybe you have followed the wrong link. Please contact App administrator get correct link. "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "status-page__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "status-page__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "status-page__image-inner status-page__image-inner_type_not-found"]], null, null, null, null, null))], null, null); }
export function View_ProjectPublicAccessComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectPublicAccessComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectPublicAccessComponent_4)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectPublicAccessComponent_5)), i0.ɵdid(5, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.publicAccessNotEnabled && !_co.publicAccessNotEnabledInFree); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.publicAccessNotEnabled; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.publicAccessNotEnabledInFree; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ProjectPublicAccessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-public-access", [], null, null, null, View_ProjectPublicAccessComponent_0, RenderType_ProjectPublicAccessComponent)), i0.ɵdid(1, 245760, null, 0, i13.ProjectPublicAccessComponent, [i14.ActivatedRoute, i15.ProjectPublicAccessService, i16.ApiService, i17.CurrentUserStore, i18.ProjectsStore, i19.ViewSettingsService, i20.RoutingService, i21.SessionStorage, i22.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectPublicAccessComponentNgFactory = i0.ɵccf("app-project-public-access", i13.ProjectPublicAccessComponent, View_ProjectPublicAccessComponent_Host_0, {}, {}, []);
export { ProjectPublicAccessComponentNgFactory as ProjectPublicAccessComponentNgFactory };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';

import { AddFilterPopupComponent } from './components/add-filter-popup/add-filter-popup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    MatMenuModule,
    TipsModule,
    LocalizeModule,
    FieldsModule,
    SharedModule
  ],
  declarations: [AddFilterPopupComponent],
  exports: [AddFilterPopupComponent],
  entryComponents: [AddFilterPopupComponent]
})
export class FiltersEditModule {}

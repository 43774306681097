import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { MenuItemType } from './menu-item-type';
import { deserializeMenuItem } from './menu-items';

export abstract class MenuItem {
  public id: string;
  public type: MenuItemType;
  public params = {};
  public children: MenuItem[] = [];
  public visibleInput: Input;

  deserialize(data: Object): MenuItem {
    this.id = data['id'];
    this.type = data['type'];

    this.params = data['params'];

    if (!this.id) {
      this.generateId();
    }

    if (this.params) {
      if (this.params['children']) {
        this.children = this.params['children']
          .map(item => deserializeMenuItem(item))
          .filter(item => item != undefined);
      }

      if (this.params['visible_input']) {
        this.visibleInput = new Input().deserialize(this.params['visible_input']);
      }
    }

    return this;
  }

  serialize(): Object {
    return {
      id: this.id,
      type: this.type,
      params: {
        children: this.children.map(item => item.serialize()),
        visible_input: this.visibleInput ? this.visibleInput.serialize() : null
      }
    };
  }

  generateId() {
    this.id = generateAlphanumeric(8, { letterFirst: true });
  }

  copyFrom(item: MenuItem) {
    this.id = item.id;
    this.type = item.type;
    this.params = cloneDeep(item.params);
    this.children = item.children.map(child => child.clone());
    this.visibleInput = item.visibleInput ? new Input().deserialize(item.visibleInput.serialize()) : undefined;
  }

  abstract clone(): MenuItem;
}

<div class="code-editor__structure-section">
  <div
    *ngIf="section.label"
    class="code-editor__structure-section-header code-editor__structure-section-header_clickable"
    [class.code-editor__structure-section-header_active]="opened"
    (click)="toggleOpened()"
  >
    <div *ngIf="section.icon" class="code-editor__structure-section-header-left">
      <div
        class="code-editor__structure-section-header-icon"
        [style.background-image]="'url(' + section.icon + ')'"
      ></div>
    </div>
    <div class="code-editor__structure-section-header-main">
      <div class="code-editor__structure-section-header-title">
        {{ section.label }}
        <span *ngIf="section.subtitle" class="code-editor__structure-section-header-title-additional">{{
          section.subtitle
        }}</span>
      </div>
    </div>
    <div class="code-editor__structure-section-header-right">
      <span
        class="code-editor__structure-section-header-icon"
        [class.icon-arrow_down_2]="opened"
        [class.icon-arrow_up_2]="!opened"
      ></span>
    </div>
  </div>

  <div *ngIf="opened" class="code-editor__structure-section-content">
    <app-token-structure-item
      *ngFor="let item of section.children; let last = last"
      [item]="item"
      [itemLast]="last"
      (inserted)="inserted.emit($event)"
      (valueChanged)="valueChanged.emit($event)"
    >
    </app-token-structure-item>
  </div>
</div>

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';

import { LocalizeModule } from '@common/localize';
import { CollaborationComponentsModule } from '@modules/collaboration-components';
import { FeaturesModule } from '@modules/features';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';

import { CollaborationMessagesItemComponent } from './components/collaboration-messages-item/collaboration-messages-item.component';
import { CollaborationMessagesComponent } from './components/collaboration-messages/collaboration-messages.component';
import { CollaborationTasksItemComponent } from './components/collaboration-tasks-item/collaboration-tasks-item.component';
import { CollaborationTasksStubComponent } from './components/collaboration-tasks-stub/collaboration-tasks-stub.component';
import { CollaborationTasksComponent } from './components/collaboration-tasks/collaboration-tasks.component';
import { CollaborationComponent } from './components/collaboration/collaboration.component';
import { ConversationsComponent } from './components/conversations/conversations.component';

@NgModule({
  imports: [
    CommonModule,
    FeaturesModule,
    SharedModule,
    RouterModule,
    NgGxScrollableModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxDatepickerModule,
    NgGxSelectModule,
    LocalizeModule,
    RoutingModule,
    CollaborationComponentsModule,
    TutorialModule,
    OverlayModule
  ],
  declarations: [
    ConversationsComponent,
    CollaborationMessagesComponent,
    CollaborationComponent,
    CollaborationTasksComponent,
    CollaborationMessagesItemComponent,
    CollaborationTasksItemComponent,
    CollaborationTasksStubComponent
  ],
  exports: [
    ConversationsComponent,
    CollaborationMessagesComponent,
    CollaborationComponent,
    CollaborationTasksComponent,
    CollaborationMessagesItemComponent,
    CollaborationTasksItemComponent,
    CollaborationTasksStubComponent
  ]
})
export class CollaborationRoutesModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CustomizeService, CustomizeType, ViewSettings } from '@modules/customize';
import { Domain, getActualDomain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import {
  Project,
  ProjectDeployment,
  ProjectGeneratedName,
  ProjectService,
  ProjectType,
  ResourceTypeItem
} from '@modules/projects';
import { TemplateItem, TemplateItemType } from '@modules/template-components';
import { CurrentUserStore, Employment } from '@modules/users';
import { isSet } from '@shared';

import { ProductTypeValue, ProjectCreateProjectForm } from './project-create-project.form';
import { ProjectCreateUserForm } from './project-create-user.form';

export const PROJECT_CREATE_TEMPLATE_ITEM = 'project_create_template_item';

export interface TemplateItemSerialized {
  type: TemplateItemType;
  resource?: ResourceTypeItem;
  template?: number;
}

@Component({
  selector: 'app-project-create-popup',
  templateUrl: './project-create-popup.component.html',
  providers: [ProjectCreateProjectForm, ProjectCreateUserForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCreatePopupComponent implements OnInit, OnDestroy {
  @Input() applyTemplateItem: TemplateItem;
  @Input() domain: Domain;

  @Output() created = new EventEmitter<Project>();

  appBaseUrl: string;
  generatedName: ProjectGeneratedName;
  generatedNameLoading = false;
  createdProject: Project;
  createdPage: ViewSettings;
  createLoading = false;
  userInfoLoading = false;
  createField = createFormFieldFactory();
  projectTypes = ProjectType;
  employments = Employment;
  userInfoStep = false;
  projectDeployments = ProjectDeployment;
  productTypeValues = ProductTypeValue;

  constructor(
    public currentUserStore: CurrentUserStore,
    public projectForm: ProjectCreateProjectForm,
    public userForm: ProjectCreateUserForm,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private customizeService: CustomizeService,
    private popupComponent: BasePopupComponent,
    public appConfigService: AppConfigService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectForm.controls.name.valueChanges
      .pipe(
        debounceTime(200),
        switchMap(value => {
          if (!isSet(value)) {
            return of(undefined);
          }

          this.generatedName = undefined;
          this.generatedNameLoading = true;
          this.appBaseUrl = undefined;
          this.cd.markForCheck();

          return this.projectService.generateUniqueName(value).pipe(catchError(() => of(undefined)));
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.generatedName = result;
        this.generatedNameLoading = false;
        this.appBaseUrl = result ? this.getAppBaseUrl(result) : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getAppBaseUrl(name: ProjectGeneratedName): string {
    const webBaseUrl = getActualDomain(name.domainName, false);
    return `https://${webBaseUrl}`;
  }

  createProject() {
    if (this.createLoading || this.projectForm.invalid) {
      return;
    }

    this.createLoading = true;
    this.cd.markForCheck();

    this.projectForm
      .create()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.createdProject = result.project;
          this.createdPage = result.page;

          if (this.currentUserStore.instance.params.userInfo) {
            this.createLoading = false;
            this.userInfoStep = true;
            this.finishCreateProject();
          } else {
            this.createLoading = false;
            this.userInfoStep = true;
            this.cd.markForCheck();
          }
        },
        error => {
          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            this.notificationService.error('Error', `Creating failed: ${error.nonFieldErrors[0]}`);
          } else {
            this.notificationService.error('Error', `Creating failed: ${error}`);
          }

          this.createLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  sendUserInfo() {
    this.userInfoLoading = true;
    this.cd.markForCheck();

    this.userForm
      .send()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.finishCreateProject();
        },
        error => {
          this.notificationService.error('Error', `Send failed: ${error}`);
          this.userInfoLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  skipUserInfo() {
    this.finishCreateProject();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UserInfoEntered, {
      Type: 'Skip'
    });
  }

  finishCreateProject() {
    if (!this.customizeService.layoutEnabled) {
      this.customizeService.toggleEnabled(CustomizeType.Layout);
    }

    this.created.emit(this.createdProject);
    this.close();
  }

  close() {
    this.popupComponent.close();
  }
}

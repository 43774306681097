import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { ResizableModule } from '@common/resizable';
import { TipsModule } from '@common/tips';
import { CustomizeUiModule } from '@modules/customize-ui';
import { SharedModule } from '@shared';

import { AutoElementPlaceholderComponent } from './components/auto-element-placeholder/auto-element-placeholder.component';
import { AutoElementComponent } from './components/auto-element/auto-element.component';

@NgModule({
  imports: [
    CommonModule,
    DragDrop2Module,
    ResizableModule,
    SharedModule,
    DynamicComponentModule,
    TipsModule,
    CustomizeUiModule
  ],
  declarations: [AutoElementComponent, AutoElementPlaceholderComponent],
  exports: [AutoElementComponent],
  entryComponents: [AutoElementComponent]
})
export class CustomizeElementsModule {}

import { Injectable, Injector } from '@angular/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, DownloadAction, QueryAction } from '@modules/actions';
import { ParameterArray } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ActionQuery, HttpQuery, ObjectQueryOperation, QueryType } from '@modules/queries';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';

@Injectable()
export class ActionQueryEditController {
  constructor(
    private actionStore: ActionStore,
    private actionDescriptionService: ActionDescriptionService,
    private queryBuilderService: QueryBuilderService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector
  ) {}

  edit(options: {
    resource: Resource;
    actionType: ActionType;
    action: ActionDescription;
    queryType: QueryType;
    queryContext: QueryBuilderContext;
    parametersControl: ParameterArray;
    baseQuery?: HttpQuery;
    analyticsSource?: string;
  }) {
    let query: ActionQuery;

    if (options.actionType == ActionType.Download && options.action.downloadAction) {
      query = options.action.downloadAction.query;
    } else if (options.action.queryAction) {
      query = options.action.queryAction.query;
    }

    return this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: ActionQuery,
        queryTypes: [options.queryType],
        resource: options.resource,
        resourceType: options.resource.type,
        context: options.queryContext,
        query: query,
        parametersControl: options.parametersControl,
        httpOptions: {
          baseQuery: options.baseQuery
        },
        source: options.analyticsSource
      })
      .pipe(
        filter(e => !!e.saved),
        map(e => {
          const action = new ActionDescription().deserialize(options.action.serialize());

          if (options.actionType == ActionType.Download) {
            if (!action.downloadAction) {
              action.downloadAction = new DownloadAction();
            }

            action.downloadAction.query = e.saved.query as ActionQuery;
          } else {
            if (!action.queryAction) {
              action.queryAction = new QueryAction();
            }

            action.queryAction.query = e.saved.query as ActionQuery;
          }

          action.actionParams = options.parametersControl.value;

          return action;
        }),
        switchMap(action => {
          return this.actionDescriptionService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            action
          );
        }),
        tap(result => this.actionStore.updateItem(result))
      );
  }
}

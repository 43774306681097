var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CustomizeService, ElementType, QrCodeElementItem, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { isSet } from '@shared';
var QrCodeElementComponent = /** @class */ (function (_super) {
    __extends(QrCodeElementComponent, _super);
    function QrCodeElementComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.value = '';
        return _this;
    }
    QrCodeElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContextObserver();
    };
    QrCodeElementComponent.prototype.ngOnDestroy = function () { };
    QrCodeElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.initContextObserver();
        }
    };
    QrCodeElementComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        this.updateInputs();
        this.contextSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () { return _this.updateInputs(); });
    };
    QrCodeElementComponent.prototype.updateInputs = function () {
        try {
            var value = applyParamInput(this.element.value, {
                context: this.context,
                defaultValue: ''
            });
            this.value = isSet(value) ? String(value) : undefined;
        }
        catch (e) {
            this.value = '';
        }
        this.cd.markForCheck();
    };
    return QrCodeElementComponent;
}(BaseElementComponent));
export { QrCodeElementComponent };
registerElementComponent({
    type: ElementType.QrCode,
    component: QrCodeElementComponent,
    label: 'QR code',
    actions: []
});

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../bind-component/bind-component.component.ngfactory";
import * as i2 from "../bind-component/bind-component.component";
import * as i3 from "../../../model-queries/stores/model-description.store";
import * as i4 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/pipes/control-value/control-value.pipe";
import * as i7 from "./data-source-bind-component.component";
var styles_DataSourceBindComponentComponent = [];
var RenderType_DataSourceBindComponentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataSourceBindComponentComponent, data: {} });
export { RenderType_DataSourceBindComponentComponent as RenderType_DataSourceBindComponentComponent };
function View_DataSourceBindComponentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-bind-component", [], null, [[null, "updateInputs"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateInputs" === en)) {
        var pd_0 = (_co.control.controls.query_inputs.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_BindComponentComponent_0, i1.RenderType_BindComponentComponent)), i0.ɵdid(1, 770048, null, 0, i2.BindComponentComponent, [i3.ModelDescriptionStore, i4.UniversalAnalyticsService, i0.ChangeDetectorRef], { inputs: [0, "inputs"], selfModelDescription: [1, "selfModelDescription"], selfColumns: [2, "selfColumns"], context: [3, "context"], element: [4, "element"], targetBindPrimaryKey: [5, "targetBindPrimaryKey"], selfBindPrimaryKey: [6, "selfBindPrimaryKey"], inline: [7, "inline"], analyticsSource: [8, "analyticsSource"] }, { updateInputs: "updateInputs" }), i0.ɵppd(2, 1), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 1), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.control.controls.query_inputs)))); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 4).transform(_co.modelDescription$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 6).transform(i0.ɵunv(_v, 1, 2, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.control.controls.columns)))); var currVal_3 = _co.context; var currVal_4 = _co.element; var currVal_5 = _co.targetBindPrimaryKey; var currVal_6 = _co.selfBindPrimaryKey; var currVal_7 = _co.inline; var currVal_8 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DataSourceBindComponentComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.ControlValuePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataSourceBindComponentComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.control.controls.type.value == _co.dataSourceTypes.Query); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DataSourceBindComponentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-data-source-bind-component", [], null, null, null, View_DataSourceBindComponentComponent_0, RenderType_DataSourceBindComponentComponent)), i0.ɵdid(1, 638976, null, 0, i7.DataSourceBindComponentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataSourceBindComponentComponentNgFactory = i0.ɵccf("app-data-source-bind-component", i7.DataSourceBindComponentComponent, View_DataSourceBindComponentComponent_Host_0, { control: "control", context: "context", element: "element", targetBindPrimaryKey: "targetBindPrimaryKey", selfBindPrimaryKey: "selfBindPrimaryKey", inline: "inline", analyticsSource: "analyticsSource" }, {}, []);
export { DataSourceBindComponentComponentNgFactory as DataSourceBindComponentComponentNgFactory };

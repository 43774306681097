var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ImageFieldDataParamsForm } from './image-field-data-params.form';
var ImageFieldDataParamsComponent = /** @class */ (function (_super) {
    __extends(ImageFieldDataParamsComponent, _super);
    function ImageFieldDataParamsComponent(form) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.createField = createFormFieldFactory();
        return _this;
    }
    ImageFieldDataParamsComponent.prototype.ngOnInit = function () {
        this.form.init(this.control);
    };
    ImageFieldDataParamsComponent.prototype.ngOnDestroy = function () { };
    return ImageFieldDataParamsComponent;
}(FieldParamsComponent));
export { ImageFieldDataParamsComponent };
registerFieldDataParamsComponent(FieldType.Image, ImageFieldDataParamsComponent);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { ViewContext } from '@modules/customize';
var ChangeCustomizeBarTemplatesGroupSectionComponent = /** @class */ (function () {
    function ChangeCustomizeBarTemplatesGroupSectionComponent(cd) {
        this.cd = cd;
        this.dropListGroups = [];
        this.focus = false;
        this.search = '';
        this.searchUpdated = new Subject();
        this.groupsFiltered = [];
    }
    ChangeCustomizeBarTemplatesGroupSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFiltered(); });
    };
    ChangeCustomizeBarTemplatesGroupSectionComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarTemplatesGroupSectionComponent.prototype.ngOnChanges = function (changes) {
        if (changes['section']) {
            this.updateFiltered();
        }
    };
    ChangeCustomizeBarTemplatesGroupSectionComponent.prototype.updateFiltered = function () {
        var _this = this;
        this.groupsFiltered = this.section.groups
            .map(function (group) {
            return __assign({}, group, { items: group.items.filter(function (item) {
                    if (!_this.search) {
                        return true;
                    }
                    return item.title.toLowerCase().includes(_this.search.toLowerCase());
                }) });
        })
            .filter(function (group) { return group.items.length; });
        if (!this.groupsFiltered.length) {
            this.emptyLabel = this.search.length ? 'Nothing found' : 'Nothing here';
        }
        else {
            this.emptyLabel = undefined;
        }
        this.cd.markForCheck();
    };
    ChangeCustomizeBarTemplatesGroupSectionComponent.prototype.clearSearch = function () {
        this.search = '';
        this.searchUpdated.next();
        this.cd.markForCheck();
    };
    return ChangeCustomizeBarTemplatesGroupSectionComponent;
}());
export { ChangeCustomizeBarTemplatesGroupSectionComponent };

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-container-app-stub',
  templateUrl: './container-app-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerAppStubComponent implements OnInit {
  @Input() fill = true;
  @Input() templates = true;
  @Output() customizeClick = new EventEmitter<void>();

  @HostBinding('class.container-stub') cls = true;
  @HostBinding('class.container-stub_fill') get marginSizeS() {
    return this.fill;
  }

  constructor() {}

  ngOnInit() {}
}

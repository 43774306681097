/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/drag-drop";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./page-templates-item-settings-fields.component";
var styles_PageTemplatesItemSettingsFieldsComponent = [];
var RenderType_PageTemplatesItemSettingsFieldsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatesItemSettingsFieldsComponent, data: {} });
export { RenderType_PageTemplatesItemSettingsFieldsComponent as RenderType_PageTemplatesItemSettingsFieldsComponent };
function View_PageTemplatesItemSettingsFieldsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 15, "div", [["cdkDrag", ""], ["class", "choose-template-list2__item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i1.ɵb, null, [i1.CdkDrag]), i0.ɵdid(2, 4866048, [[1, 4]], 3, i1.CdkDrag, [i0.ElementRef, [3, i1.CDK_DROP_LIST], i2.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i3.ViewportRuler, i1.DragDropRegistry, i1.CDK_DRAG_CONFIG, [2, i4.Directionality], i1.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 2, { _handles: 1 }), i0.ɵqud(335544320, 3, { _previewTemplate: 0 }), i0.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 9, "div", [["class", "choose-template-list2-item choose-template-list2-item_interactive"]], [[2, "choose-template-list2-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.toggleEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "choose-template-list2-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "big-square-checkbox big-square-checkbox_orange"]], [[2, "big-square-checkbox_checked", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "choose-template-list2-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "choose-template-list2-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "choose-template-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, [" ", " "])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "choose-template-list2-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "span", [["class", "choose-template-list2-item__icon icon-dots_small"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.controls.enabled.value; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.controls.enabled.value; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit.instance.verboseName || _v.context.$implicit.instance.name); _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.fieldDescription.label; _ck(_v, 13, 0, currVal_5); }); }
export function View_PageTemplatesItemSettingsFieldsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["cdkDropList", ""], ["class", "choose-template-list2 cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dragDropOption($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.CDK_DROP_LIST, null, [i1.CdkDropList]), i0.ɵdid(2, 1196032, null, 1, i1.CdkDropList, [i0.ElementRef, i1.DragDropRegistry, i0.ChangeDetectorRef, [2, i4.Directionality], [3, i1.CdkDropListGroup], [2, i2.DOCUMENT], i1.DragDrop], { lockAxis: [0, "lockAxis"] }, { dropped: "cdkDropListDropped" }), i0.ɵqud(603979776, 1, { _draggables: 1 }), i0.ɵprd(256, null, i1.CdkDropListGroup, undefined, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesItemSettingsFieldsComponent_1)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "y"; _ck(_v, 2, 0, currVal_4); var currVal_5 = _co.control.controls; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = i0.ɵnov(_v, 2).disabled; var currVal_2 = i0.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i0.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_PageTemplatesItemSettingsFieldsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-templates-item-settings-fields", [], null, null, null, View_PageTemplatesItemSettingsFieldsComponent_0, RenderType_PageTemplatesItemSettingsFieldsComponent)), i0.ɵdid(1, 114688, null, 0, i5.PageTemplatesItemSettingsFieldsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatesItemSettingsFieldsComponentNgFactory = i0.ɵccf("app-page-templates-item-settings-fields", i5.PageTemplatesItemSettingsFieldsComponent, View_PageTemplatesItemSettingsFieldsComponent_Host_0, { control: "control" }, {}, []);
export { PageTemplatesItemSettingsFieldsComponentNgFactory as PageTemplatesItemSettingsFieldsComponentNgFactory };

<div class="sidebar__list">
  <div class="sidebar-list__separator">
    <app-sidebar-list-separator [title]="'When action succeeded'"></app-sidebar-list-separator>
  </div>

  <div class="sidebar-list" [style.margin-bottom.px]="4">
    <div class="sidebar-list__item">
      <div
        class="sidebar-list-item sidebar-list-item_clickable"
        (click)="form.controls.on_success_notification.patchValue(!form.controls.on_success_notification.value)"
      >
        <div class="sidebar-list-item__left">
          <app-toggle
            [orange]="true"
            [selected]="form.controls.on_success_notification | appControlValue | async"
          ></app-toggle>
        </div>

        <div class="sidebar-list-item__main">
          <div class="sidebar-list-item__title sidebar-list-item__title_secondary sidebar-list-item__title_small">
            Show success notification
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-actions-edit
    [control]="form.controls.on_success_actions"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [emptyAction]="'Success action'"
    [emptyActionValue]="'Not specified'"
    [emptyIcon]="'check_circle'"
    [actionLabel]="'Action succeeded'"
    [object]="object"
    [firstInit]="firstInit"
  >
  </app-actions-edit>

  <div class="sidebar-list__separator">
    <app-sidebar-list-separator [title]="'When action failed'"></app-sidebar-list-separator>
  </div>

  <div class="sidebar-list" [style.margin-bottom.px]="4">
    <div class="sidebar-list__item">
      <div
        class="sidebar-list-item sidebar-list-item_clickable"
        (click)="form.controls.on_error_notification.patchValue(!form.controls.on_error_notification.value)"
      >
        <div class="sidebar-list-item__left">
          <app-toggle
            [orange]="true"
            [selected]="form.controls.on_error_notification | appControlValue | async"
          ></app-toggle>
        </div>

        <div class="sidebar-list-item__main">
          <div class="sidebar-list-item__title sidebar-list-item__title_secondary sidebar-list-item__title_small">
            Show error notification
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-actions-edit
    [control]="form.controls.on_error_actions"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [emptyAction]="'Error action'"
    [emptyActionValue]="'Not specified'"
    [emptyIcon]="'close'"
    [actionLabel]="'Action failed'"
    [object]="object"
    [firstInit]="firstInit"
  >
  </app-actions-edit>
</div>

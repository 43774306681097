import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';

import { AppDropListGroup } from '@common/drag-drop2';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  JET_APP_RESOURCE,
  Resource,
  ResourceType,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { TemplateService } from '@modules/template';

import { TemplatesGroup } from '../../../data/templates-group';
import { TemplateProvider } from '../../../services/template-provider/template.provider';
import { CustomizeBarTab } from '../change-customize-bar.component';

@Component({
  selector: 'app-change-customize-bar-templates',
  templateUrl: './change-customize-bar-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesComponent implements OnInit, OnDestroy {
  @Input() context: ViewContext;
  @Input() dropListGroups: AppDropListGroup[] = [];
  @Input() initialResource: Resource;
  @Input() initialResourceType: ResourceTypeItem;
  @Input() firstVisible = false;
  @Output() selectTab = new EventEmitter<CustomizeBarTab>();
  @Output() scrollToTop = new EventEmitter<boolean>();

  loading = false;
  installedItems: TemplatesGroup[] = [];
  installedItemsFiltered: TemplatesGroup[] = [];
  otherItems: TemplatesGroup[] = [];
  otherItemsFiltered: TemplatesGroup[] = [];
  search = '';
  searchUpdated = new Subject<string>();
  emptyLabel: string;
  selectedItem: TemplatesGroup;
  selectResourceGroup: TemplatesGroup;
  selectResourceType: ResourceType;
  customizeBarTabs = CustomizeBarTab;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private templateService: TemplateService,
    private resourceEditController: ResourceEditController,
    private templateProvider: TemplateProvider,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();
    this.updateGroups();
    this.searchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateFiltered());

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.IntegrationsViewed);
  }

  ngOnDestroy(): void {}

  updateGroups() {
    const resources = this.currentEnvironmentStore.resources.filter(
      item => !item.demo && item.uniqueName != JET_APP_RESOURCE
    );

    combineLatest(this.templateProvider.getInstalledGroups(resources), this.templateProvider.getOtherGroups())
      .pipe(untilDestroyed(this))
      .subscribe(([installedItems, otherItems]) => {
        this.installedItems = installedItems;
        this.otherItems = otherItems;

        if (this.initialResource) {
          const installedItem = this.installedItems.find(
            item => item.resource.uniqueName == this.initialResource.uniqueName
          );

          if (installedItem) {
            this.setSelectedItem(installedItem);
          }
        } else if (this.initialResourceType) {
          const installedItem = this.installedItems.find(
            item => item.resource.typeItem.name === this.initialResourceType.name
          );
          const otherItem = this.otherItems.find(
            item => item.resourceTypeItem && item.resourceTypeItem.name === this.initialResourceType.name
          );

          if (installedItem) {
            this.setSelectedItem(installedItem);
          } else if (installedItem) {
            this.setSelectedItem(otherItem);
          }
        } else if (this.firstVisible && this.context && this.context.modelDescription) {
          const resource = resources.find(item => item.uniqueName == this.context.modelDescription.resource);

          if (resource) {
            this.setSelectedItem(this.installedItems.find(item => item.resource === resource));
          }
        }

        if (this.selectedItem && this.selectedItem.resource) {
          this.selectedItem = this.installedItems.find(
            item => item.resource && item.resource.uniqueName == this.selectedItem.resource.uniqueName
          );
        }

        this.loading = false;
        this.cd.markForCheck();

        this.updateFiltered();
      });
  }

  setSelectedItem(item: TemplatesGroup) {
    this.selectedItem = item;
    this.selectResourceType = undefined;
    this.selectResourceGroup = undefined;
    this.search = '';
    this.updateFiltered();
    this.cd.detectChanges();
    this.scrollToTop.emit(true);

    if (this.selectedItem) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.IntegrationsResourceViewed, {
        ResourceConnected: !!this.selectedItem.resource,
        ResourceType: this.selectedItem.resourceTypeItem ? this.selectedItem.resourceTypeItem.name : undefined
      });
    }
  }

  setSelectResourceType(group: TemplatesGroup, type: ResourceType) {
    this.selectResourceType = type;
    this.selectResourceGroup = group;
    this.selectedItem = undefined;
    this.cd.detectChanges();
    this.scrollToTop.emit(true);
  }

  filterItems(items: TemplatesGroup[]) {
    return items.filter(item => {
      if (!this.search) {
        return true;
      }

      if (item.resource && item.resource.name.toLowerCase().includes(this.search.toLowerCase())) {
        return true;
      }

      return item.title.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  updateFiltered() {
    this.installedItemsFiltered = this.filterItems(this.installedItems);
    this.otherItemsFiltered = this.filterItems(this.otherItems);

    if ([this.installedItemsFiltered, this.otherItemsFiltered].every(item => !item.length)) {
      this.emptyLabel = this.search.length ? 'Nothing found' : 'There are no templates';
    } else {
      this.emptyLabel = undefined;
    }

    this.cd.markForCheck();
  }

  addResource(typeItem: ResourceTypeItem) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
      Source: 'builder_component_templates',
      ResourceID: typeItem.name
    });

    this.resourceEditController
      .createResource(typeItem, {
        resourceNameEditing: true,
        analyticsSource: 'builder_component_templates'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.resource) {
          return;
        }

        this.updateGroups();
        const selectedItem = this.installedItems.find(
          item => item.resource && item.resource.uniqueName === result.resource.uniqueName
        );

        if (selectedItem) {
          this.setSelectedItem(selectedItem);
        }
      });
  }

  onItemClick(group: TemplatesGroup) {
    if (!group.resource && group.requireResource) {
      if (group.resourceTypeItem) {
        this.addResource(group.resourceTypeItem);
      } else if (group.resourceType) {
        this.setSelectResourceType(group, group.resourceType);
        this.selectResourceGroup = group;
        this.cd.markForCheck();
      }
    } else {
      this.setSelectedItem(group);
    }
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceName } from '@modules/projects';
import { ascComparator, isSet, KeyboardEventKeyCode, splitmax, stripTags } from '@shared';
var TableTokensComponent = /** @class */ (function () {
    function TableTokensComponent(currentProjectStore, currentEnvironmentStore, modelDescriptionStore, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.cd = cd;
        this.inserted = new EventEmitter();
        this.searchControl = new FormControl('');
    }
    TableTokensComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateTables();
        this.searchControl.valueChanges
            .pipe(debounceTime(200))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateResourceStructuresFiltered(); });
    };
    TableTokensComponent.prototype.ngOnDestroy = function () { };
    TableTokensComponent.prototype.mapModelDescriptionTables = function (resource, modelDescriptions, insertFull) {
        if (insertFull === void 0) { insertFull = false; }
        var quote = function (str) {
            if ([ResourceName.Oracle].includes(resource.typeItem.name)) {
                return "\"" + str + "\"";
            }
            else if (str.match(/^[a-z][a-z\d_]*$/)) {
                return str;
            }
            else if ([ResourceName.JetDatabase, ResourceName.PostgreSQL].includes(resource.typeItem.name) ||
                resource.isSynced() ||
                resource.hasCollectionSync()) {
                return "\"" + str + "\"";
            }
            else {
                return str;
            }
        };
        if (resource.typeItem.name == ResourceName.BigQuery) {
            var datasets = modelDescriptions
                .filter(function (item) { return isSet(item.dbTable) && item.dbTable.includes('.'); })
                .sort(function (lhs, rhs) { return ascComparator(lhs.dbTable, rhs.dbTable); })
                .reduce(function (acc, item) {
                var _a = splitmax(item.dbTable, '.', 2), dataset = _a[0], name = _a[1];
                if (!acc[dataset]) {
                    acc[dataset] = [];
                }
                acc[dataset].push({
                    item: item,
                    name: name
                });
                return acc;
            }, {});
            return toPairs(datasets)
                .map(function (_a) {
                var dataset = _a[0], items = _a[1];
                return {
                    name: dataset,
                    label: dataset,
                    insert: quote(dataset),
                    children: items
                        .map(function (item) {
                        return {
                            name: item.item.dbTable,
                            label: item.item.verboseNamePlural,
                            insert: insertFull
                                ? [quote(item.item.resource), quote(item.item.dbTable)].join('.')
                                : quote(item.item.dbTable),
                            children: item.item.dbFields
                                .filter(function (field) { return isSet(field.dbColumn); })
                                .map(function (field) {
                                return {
                                    name: field.dbColumn,
                                    label: field.verboseName,
                                    insert: quote(field.dbColumn),
                                    children: []
                                };
                            })
                        };
                    })
                        .sort(function (lhs, rhs) { return ascComparator(lhs.label, rhs.label); })
                };
            })
                .filter(function (item) { return item.children.length; });
        }
        else {
            return modelDescriptions
                .filter(function (item) { return isSet(item.dbTable); })
                .sort(function (lhs, rhs) { return ascComparator(lhs.dbTable, rhs.dbTable); })
                .map(function (item) {
                return {
                    name: item.dbTable,
                    label: item.verboseNamePlural,
                    insert: insertFull ? [quote(item.resource), quote(item.dbTable)].join('.') : quote(item.dbTable),
                    children: item.dbFields
                        .filter(function (field) { return isSet(field.dbColumn); })
                        .map(function (field) {
                        return {
                            name: field.dbColumn,
                            label: field.verboseName,
                            insert: quote(field.dbColumn),
                            children: []
                        };
                    })
                };
            })
                .filter(function (item) { return item.children.length; });
        }
    };
    TableTokensComponent.prototype.updateTables = function () {
        var _this = this;
        var currentResource = this.currentProjectStore.instance
            .getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName)
            .find(function (item) { return item.uniqueName == _this.resourceUniqueName; });
        var otherProjectDatabasesResources = currentResource && currentResource.isUsingProjectDatabase()
            ? this.currentProjectStore.instance
                .getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName)
                .filter(function (item) { return !item.demo && item.uniqueName != _this.resourceUniqueName && item.isUsingProjectDatabase(); })
                .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); })
            : [];
        this.modelDescriptionStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var otherResourceTables = otherProjectDatabasesResources
                .map(function (resource) {
                return {
                    name: resource.uniqueName,
                    label: resource.name,
                    icon: resource.icon,
                    children: _this.mapModelDescriptionTables(resource, result.filter(function (model) { return model.resource == resource.uniqueName; }), true)
                };
            })
                .filter(function (item) { return item.children.length; });
            _this.resourceStructures = [
                __assign({ name: currentResource.uniqueName }, (otherResourceTables.length
                    ? {
                        label: currentResource.name,
                        subtitle: 'current resource',
                        icon: currentResource.icon
                    }
                    : {}), { children: _this.mapModelDescriptionTables(currentResource, result.filter(function (model) { return model.resource == currentResource.uniqueName; })) })
            ].concat(otherResourceTables);
            _this.cd.markForCheck();
            _this.updateResourceStructuresFiltered();
        });
    };
    TableTokensComponent.prototype.searchStr = function (str, query) {
        if (!isSet(str)) {
            return { match: false };
        }
        var strSafe = stripTags(str);
        var index = strSafe.toLocaleLowerCase().indexOf(query);
        if (index === -1) {
            return { match: false };
        }
        return {
            match: true,
            marked: [
                strSafe.substring(0, index),
                '<mark>',
                strSafe.substring(index, index + query.length),
                '</mark>',
                strSafe.substring(index + query.length)
            ].join('')
        };
    };
    TableTokensComponent.prototype.updateResourceStructuresFiltered = function () {
        var _this = this;
        if (!isSet(this.searchControl.value)) {
            this.resourceStructuresFiltered = this.resourceStructures;
            this.cd.markForCheck();
            return;
        }
        var query = this.searchControl.value.toLocaleLowerCase();
        this.resourceStructuresFiltered = this.resourceStructures.reduce(function (resourceStructureAcc, resourceStructure) {
            var tablesFiltered = resourceStructure.children.reduce(function (tablesAcc, table) {
                var tableNameMatch = _this.searchStr(table.name, query);
                var tableLabelMatch = _this.searchStr(table.label, query);
                if (tableNameMatch.match || tableLabelMatch.match) {
                    tablesAcc.push(__assign({}, table, (tableNameMatch.match ? { name: tableNameMatch.marked } : {}), (tableLabelMatch.match ? { label: tableLabelMatch.marked } : {})));
                }
                else {
                    var tableColumnsFiltered = table.children.reduce(function (tableColumnsAcc, tableColumn) {
                        var childNameMatch = _this.searchStr(tableColumn.name, query);
                        var childLabelMatch = _this.searchStr(tableColumn.label, query);
                        if (childNameMatch.match || childLabelMatch.match) {
                            tableColumnsAcc.push(__assign({}, tableColumn, (childNameMatch.match ? { name: childNameMatch.marked } : {}), (childLabelMatch.match ? { label: childLabelMatch.marked } : {})));
                        }
                        return tableColumnsAcc;
                    }, []);
                    if (tableColumnsFiltered.length) {
                        tablesAcc.push(__assign({}, table, { children: tableColumnsFiltered, openedInitial: true }));
                    }
                }
                return tablesAcc;
            }, []);
            if (tablesFiltered.length) {
                resourceStructureAcc.push(__assign({}, resourceStructure, { children: tablesFiltered, openedInitial: true }));
            }
            return resourceStructureAcc;
        }, []);
        this.cd.markForCheck();
    };
    TableTokensComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
        this.updateResourceStructuresFiltered();
    };
    TableTokensComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    return TableTokensComponent;
}());
export { TableTokensComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./dashboard-item/dashboard-item.component.ngfactory";
import * as i3 from "./dashboard-item/dashboard-item.component";
import * as i4 from "../../../dashboard/stores/dashboard.store";
import * as i5 from "../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i6 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "./dashboard-customize-bar/dashboard-customize-bar.component.ngfactory";
import * as i9 from "./dashboard-customize-bar/dashboard-customize-bar.component";
import * as i10 from "../../../projects/stores/current-project.store";
import * as i11 from "../../../dashboard/services/widget/widget.service";
import * as i12 from "../../../customize/data/view-context";
import * as i13 from "../../../projects/stores/current-environment.store";
import * as i14 from "./dashboard.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../dashboard/services/dashboard/dashboard.service";
import * as i17 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i18 from "../../../meta/services/meta.service";
import * as i19 from "../../../activities/services/user-activity/user-activity.service";
import * as i20 from "../../../../core/services/document/document.service";
import * as i21 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i22 from "../../../routing/services/routing/routing.service";
var styles_DashboardComponent = [];
var RenderType_DashboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[3, 0], ["dashboard_item_element", 1]], null, 4, "div", [["class", "dashboard__item dashboard__item_absolute"]], [[2, "dashboard__item_resizing", null], [1, "data-id", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-dashboard-item", [], null, [[null, "sizeChanged"], [null, "sizeChangeConfirmed"], [null, "resizingChanged"], [null, "updated"], [null, "copyRequested"], [null, "moveRequested"], [null, "deleteRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sizeChanged" === en)) {
        var pd_0 = (_co.onWidgetSizeChanged() !== false);
        ad = (pd_0 && ad);
    } if (("sizeChangeConfirmed" === en)) {
        var pd_1 = (_co.onWidgetSizeChangeConfirmed() !== false);
        ad = (pd_1 && ad);
    } if (("resizingChanged" === en)) {
        var pd_2 = (_co.onWidgetResizingChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("updated" === en)) {
        var pd_3 = (_co.updateItem(_v.context.$implicit, $event) !== false);
        ad = (pd_3 && ad);
    } if (("copyRequested" === en)) {
        var pd_4 = (_co.copyWidget(_v.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } if (("moveRequested" === en)) {
        var pd_5 = (_co.moveToDashboard(_v.context.$implicit, $event) !== false);
        ad = (pd_5 && ad);
    } if (("deleteRequested" === en)) {
        var pd_6 = (_co.deleteWidget(_v.context.$implicit) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i2.View_DashboardItemComponent_0, i2.RenderType_DashboardItemComponent)), i0.ɵdid(4, 4964352, [[4, 4]], 0, i3.DashboardItemComponent, [i4.DashboardStore, i5.CustomizeBarService, i6.CustomizeBarContext, i0.ChangeDetectorRef, i7.CustomizeService], { dashboard: [0, "dashboard"], widget: [1, "widget"], columnWidth: [2, "columnWidth"], rowHeight: [3, "rowHeight"], context: [4, "context"] }, { sizeChanged: "sizeChanged", sizeChangeConfirmed: "sizeChangeConfirmed", resizingChanged: "resizingChanged", updated: "updated", copyRequested: "copyRequested", moveRequested: "moveRequested", deleteRequested: "deleteRequested" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "dashboard__item dashboard__item_absolute"; var currVal_3 = _ck(_v, 2, 0, ("dashboard__item_width_" + _v.context.$implicit.width), ("dashboard__item_height_" + _v.context.$implicit.height)); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.widgetsDashboard; var currVal_5 = _v.context.$implicit; var currVal_6 = (_co.columnWidth - 10); var currVal_7 = (_co.rowHeight - 10); var currVal_8 = _co.context; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.resizingWidget == null) ? null : _co.resizingWidget.id) == _v.context.$implicit.id); var currVal_1 = _v.context.$implicit.id; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DashboardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[2, 0], ["dashboard_element", 1]], null, 3, "div", [["class", "dashboard__inner"]], [[2, "customizable", null], [4, "width", "px"]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.widgets; var currVal_3 = _co.trackByFn; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.customizeService.layoutEnabled$)); var currVal_1 = (_co.columnWidth * _co.columns); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DashboardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard-customize-bar", [], null, [[null, "widgetAdded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("widgetAdded" === en)) {
        var pd_0 = (_co.onWidgetAdded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DashboardCustomizeBarComponent_0, i8.RenderType_DashboardCustomizeBarComponent)), i0.ɵdid(1, 245760, null, 0, i9.DashboardCustomizeBarComponent, [i7.CustomizeService, i10.CurrentProjectStore, i11.WidgetService, i0.ChangeDetectorRef, i6.CustomizeBarContext], { dashboard: [0, "dashboard"], widgetPosition: [1, "widgetPosition"] }, { widgetAdded: "widgetAdded" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dashboard; var currVal_1 = _co.widgetPosition; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DashboardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "admin__sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgets; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DashboardComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { dashboardElementWrapper: 0 }), i0.ɵqud(671088640, 2, { dashboardElement: 0 }), i0.ɵqud(671088640, 3, { dashboardItemElements: 1 }), i0.ɵqud(671088640, 4, { dashboardItems: 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "dashboard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, [[1, 0], ["dashboard_element_wrapper", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DashboardComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgets; _ck(_v, 7, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.customizeService.enabled$)); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i0.ɵprd(131584, null, i12.ViewContext, i12.ViewContext, [i13.CurrentEnvironmentStore]), i0.ɵprd(131584, null, i6.CustomizeBarContext, i6.CustomizeBarContext, [i7.CustomizeService, i10.CurrentProjectStore, i13.CurrentEnvironmentStore]), i0.ɵdid(3, 4964352, null, 0, i14.DashboardComponent, [i15.ActivatedRoute, i11.WidgetService, i10.CurrentProjectStore, i13.CurrentEnvironmentStore, i12.ViewContext, i7.CustomizeService, i16.DashboardService, i17.DialogService, i18.MetaService, i19.UserActivityService, i20.DocumentService, i21.UniversalAnalyticsService, i0.ChangeDetectorRef, i22.RoutingService, i6.CustomizeBarContext], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DashboardComponentNgFactory = i0.ɵccf("app-dashboard", i14.DashboardComponent, View_DashboardComponent_Host_0, { dashboard: "dashboard" }, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };

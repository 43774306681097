import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ProjectPermissionType } from '@modules/projects';
import { ascComparator } from '@shared';

import { ProjectObjectPermissionsArray } from './project-object-permissions.array';
import { ProjectPermissionControl } from './project-permission.control';

export interface ProjectModelPermissionControlData {
  modelDescription: ModelDescription;
}

@Injectable()
export class ProjectModelPermissionsArray extends ProjectObjectPermissionsArray<ProjectModelPermissionControlData>
  implements OnDestroy {
  constructor(private modelDescriptionStore: ModelDescriptionStore) {
    super();
    this.initControls();
  }

  ngOnDestroy(): void {}

  initControls() {
    this.modelDescriptionStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(modelDescriptions => {
        const existingControls = [];

        modelDescriptions.forEach(modelDescription => {
          const existingControl = this.controls.find(item => modelDescription.isSame(item.value.object));

          if (existingControl) {
            existingControls.push(existingControl);
          } else {
            const control = new ProjectPermissionControl<ProjectModelPermissionControlData>(
              {
                type: ProjectPermissionType.Model,
                object: modelDescription.modelId
              },
              { modelDescription: modelDescription }
            );
            const permission = this.permissions.find(
              item => item.permissionType == control.value.type && item.permissionObject == control.value.object
            );

            control.deserialize(permission);
            this.push(control);
            existingControls.push(control);
          }
        });

        const deleteControls = this.controls.filter(item => !existingControls.includes(item));
        deleteControls.forEach(item => this.removeAt(this.controls.indexOf(item)));

        this.controls = this.controls.sort((lhs, rhs) => {
          return ascComparator(
            (lhs.data.modelDescription.verboseNamePlural || '').toLowerCase(),
            (rhs.data.modelDescription.verboseNamePlural || '').toLowerCase()
          );
        });

        this._initialized$.next(true);
      });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

import { AdminMode } from '@modules/admin-mode';
import { serializeTokenOptions, TokenOptions } from '@modules/api';
import { TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { Environment, Project } from '@modules/projects';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-projects-item',
  templateUrl: './projects-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsItemComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Input() externalToken: TokenOptions;
  @Input() openBuilderLink: any[];

  environment: Environment;
  adminModes = AdminMode;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ProjectsItemComponent>): void {
    if (changes.project) {
      const defaultEnvironmentUniqueName = this.project.defaultEnvironment
        ? this.project.defaultEnvironment.uniqueName
        : undefined;

      this.environment = [
        ...(this.project.defaultEnvironment ? [this.project.defaultEnvironment] : []),
        ...this.project.environments.filter(item => item.uniqueName != defaultEnvironmentUniqueName)
      ].find(environment => this.project.hasEnvironmentPermissions(environment));
    }
  }

  getExternalHref(href: string): string {
    if (this.externalToken) {
      const tokenStr = btoa(JSON.stringify(serializeTokenOptions(this.externalToken)));
      return `${href}#${TOKEN_FRAGMENT_KEY}=${tokenStr}`;
    } else {
      return href;
    }
  }
}

import { isSet } from '@shared';
// TODO: Refactor import
import { FieldType } from '../../fields/data/field-type';
import { getFieldDescriptionByType } from '../../fields/data/fields';
var FilterParameter = /** @class */ (function () {
    function FilterParameter() {
        this.field = FieldType.Text;
        this.params = {};
    }
    FilterParameter.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.label = data['label'];
        this.field = data['field'];
        this.params = data['params'];
        this.updateFieldDescription();
        return this;
    };
    Object.defineProperty(FilterParameter.prototype, "labelOrName", {
        get: function () {
            return isSet(this.label) ? this.label : this.name;
        },
        enumerable: true,
        configurable: true
    });
    FilterParameter.prototype.updateFieldDescription = function () {
        this.fieldDescription = getFieldDescriptionByType(this.field);
    };
    return FilterParameter;
}());
export { FilterParameter };

export const amplitudeResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'user_activities',
    verbose_name: 'User Activities',
    verbose_name_plural: 'User Activities',
    fields: [
      {
        name: 'device_type',
        verbose_name: 'device_type',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_device_id',
        verbose_name: 'last_device_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merge_times',
        verbose_name: 'merge_times',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'version',
        verbose_name: 'version',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_sessions',
        verbose_name: 'num_sessions',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'dma',
        verbose_name: 'dma',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLat',
        verbose_name: 'lastLocationLat',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usageTime',
        verbose_name: 'usageTime',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ip_address',
        verbose_name: 'ip_address',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'canonical_amplitude_id',
        verbose_name: 'canonical_amplitude_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deviceIds',
        verbose_name: 'deviceIds',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'city',
        verbose_name: 'city',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usage_time',
        verbose_name: 'usage_time',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'purchases',
        verbose_name: 'purchases',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'carrier',
        verbose_name: 'carrier',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device_ids',
        verbose_name: 'device_ids',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merged_amplitude_ids',
        verbose_name: 'merged_amplitude_ids',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_location',
        verbose_name: 'last_location',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastSeen',
        verbose_name: 'lastSeen',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliased_user_id',
        verbose_name: 'aliased_user_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'start_version',
        verbose_name: 'start_version',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'library',
        verbose_name: 'library',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_profiles',
        verbose_name: 'aliasing_profiles',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paying',
        verbose_name: 'paying',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'revenue',
        verbose_name: 'revenue',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'properties',
        verbose_name: 'properties',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'os',
        verbose_name: 'os',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLong',
        verbose_name: 'lastLocationLong',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device',
        verbose_name: 'device',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'region',
        verbose_name: 'region',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_events',
        verbose_name: 'num_events',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'language',
        verbose_name: 'language',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'firstSeen',
        verbose_name: 'firstSeen',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_used',
        verbose_name: 'first_used',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_used',
        verbose_name: 'last_used',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_user_ids',
        verbose_name: 'aliasing_user_ids',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'device_brand',
    relations: [],
    description:
      'Get a user summary and their most recent 1000 events plus all of the events from their most recent session.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://amplitude.com/api/2/useractivity',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'user',
            value: '{{params.amplitude_id}}'
          },
          {
            name: 'offset',
            value: '{{paging.offset}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['userData'];",
        response_path: '',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      sorting: false
    },
    get_parameters: [
      {
        name: 'amplitude_id',
        verbose_name: 'amplitude_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    create_parameters: [
      {
        name: 'device_type',
        verbose_name: 'device_type',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_device_id',
        verbose_name: 'last_device_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merge_times',
        verbose_name: 'merge_times',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'version',
        verbose_name: 'version',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_sessions',
        verbose_name: 'num_sessions',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'dma',
        verbose_name: 'dma',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLat',
        verbose_name: 'lastLocationLat',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usageTime',
        verbose_name: 'usageTime',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ip_address',
        verbose_name: 'ip_address',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'canonical_amplitude_id',
        verbose_name: 'canonical_amplitude_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deviceIds',
        verbose_name: 'deviceIds',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'city',
        verbose_name: 'city',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usage_time',
        verbose_name: 'usage_time',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'purchases',
        verbose_name: 'purchases',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'carrier',
        verbose_name: 'carrier',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device_ids',
        verbose_name: 'device_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merged_amplitude_ids',
        verbose_name: 'merged_amplitude_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_location',
        verbose_name: 'last_location',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastSeen',
        verbose_name: 'lastSeen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliased_user_id',
        verbose_name: 'aliased_user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'start_version',
        verbose_name: 'start_version',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'library',
        verbose_name: 'library',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_profiles',
        verbose_name: 'aliasing_profiles',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paying',
        verbose_name: 'paying',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'revenue',
        verbose_name: 'revenue',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'properties',
        verbose_name: 'properties',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'os',
        verbose_name: 'os',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLong',
        verbose_name: 'lastLocationLong',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device',
        verbose_name: 'device',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'region',
        verbose_name: 'region',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_events',
        verbose_name: 'num_events',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'language',
        verbose_name: 'language',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'firstSeen',
        verbose_name: 'firstSeen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_used',
        verbose_name: 'first_used',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_used',
        verbose_name: 'last_used',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_user_ids',
        verbose_name: 'aliasing_user_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters: [
      {
        name: 'device_type',
        verbose_name: 'device_type',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_device_id',
        verbose_name: 'last_device_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merge_times',
        verbose_name: 'merge_times',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'version',
        verbose_name: 'version',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_sessions',
        verbose_name: 'num_sessions',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'dma',
        verbose_name: 'dma',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLat',
        verbose_name: 'lastLocationLat',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usageTime',
        verbose_name: 'usageTime',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ip_address',
        verbose_name: 'ip_address',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'canonical_amplitude_id',
        verbose_name: 'canonical_amplitude_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deviceIds',
        verbose_name: 'deviceIds',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'city',
        verbose_name: 'city',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'usage_time',
        verbose_name: 'usage_time',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'purchases',
        verbose_name: 'purchases',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'carrier',
        verbose_name: 'carrier',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device_ids',
        verbose_name: 'device_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'merged_amplitude_ids',
        verbose_name: 'merged_amplitude_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_location',
        verbose_name: 'last_location',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastSeen',
        verbose_name: 'lastSeen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliased_user_id',
        verbose_name: 'aliased_user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'start_version',
        verbose_name: 'start_version',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'library',
        verbose_name: 'library',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_profiles',
        verbose_name: 'aliasing_profiles',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paying',
        verbose_name: 'paying',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'revenue',
        verbose_name: 'revenue',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'properties',
        verbose_name: 'properties',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'os',
        verbose_name: 'os',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lastLocationLong',
        verbose_name: 'lastLocationLong',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'device',
        verbose_name: 'device',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'region',
        verbose_name: 'region',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'num_events',
        verbose_name: 'num_events',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'language',
        verbose_name: 'language',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'firstSeen',
        verbose_name: 'firstSeen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_used',
        verbose_name: 'first_used',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_used',
        verbose_name: 'last_used',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'aliasing_user_ids',
        verbose_name: 'aliasing_user_ids',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters: [],
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'user_search',
    verbose_name: 'User Search',
    verbose_name_plural: 'User Searches',
    fields: [
      {
        name: 'last_seen',
        verbose_name: 'last_seen',
        field: 'date',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amplitude_id',
        verbose_name: 'amplitude_id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'platform',
    relations: [],
    description: 'Search for a user with a specified Amplitude ID, Device ID, User ID, or User ID prefix.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://amplitude.com/api/2/usersearch',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'user',
            value: '{{params.user_id}}'
          },
          {
            name: 'user',
            value: '{{params.amplitude_id}}'
          },
          {
            name: 'user',
            value: '{{params.device_id}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['matches'];",
        response_path: '',
        request_tokens: {}
      },
      pagination: null,
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      sorting: false
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'amplitude_id',
        verbose_name: 'amplitude_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'device_id',
        verbose_name: 'device_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    create_parameters: [
      {
        name: 'last_seen',
        verbose_name: 'last_seen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amplitude_id',
        verbose_name: 'amplitude_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters: [
      {
        name: 'last_seen',
        verbose_name: 'last_seen',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'platform',
        verbose_name: 'platform',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amplitude_id',
        verbose_name: 'amplitude_id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country',
        verbose_name: 'country',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters: [],
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'realtime',
    verbose_name: 'Realtime',
    verbose_name_plural: 'Realtimes',
    fields: [
      {
        name: 'X',
        verbose_name: 'X',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'Y',
        verbose_name: 'Y',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'X',
    relations: [],
    description: 'Get active user numbers with minute granularity for the last two days.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://amplitude.com/api/2/realtime',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'i',
            value: '{{params.i}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "timeX = data['data']['xValues'];\nseriesLabels0  = data['data']['seriesLabels'][0];\nseriesLabels1 = data['data']['seriesLabels'][1];\n\nseries1 = data['data']['series'][0];\nseries2 = data['data']['series'][1];\n\ntoday = timeX.map(obj => seriesLabels0 + \"_\" + obj);\nyesterday = timeX.map(obj => seriesLabels1 + \"_\" + obj);\nX = yesterday.concat(today)\nY = series2.concat(series1);\nresult = []\nfor (let i = 0; i < X.length; i++) { // выведет 0, затем 1, затем 2\n    result.push({\"X\": X[i], \"Y\": Y[i]});\n}\nreturn result",
        response_path: '',
        request_tokens: {}
      },
      pagination: null,
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      sorting: false
    },
    get_parameters: [
      {
        name: 'i',
        verbose_name: 'i',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    create_parameters: [
      {
        name: 'X',
        verbose_name: 'X',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'Y',
        verbose_name: 'Y',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters: [
      {
        name: 'X',
        verbose_name: 'X',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'Y',
        verbose_name: 'Y',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters: [],
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'events',
    verbose_name: 'Event Segmentation',
    verbose_name_plural: 'Event Segmentation',
    fields: [
      {
        name: 'value',
        verbose_name: 'value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'non_active',
        verbose_name: 'non active',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'timeline_hidden',
        verbose_name: 'timeline hidden',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'autohidden',
        verbose_name: 'autohidden',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'display',
        verbose_name: 'display',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'hidden',
        verbose_name: 'hidden',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deleted',
        verbose_name: 'deleted',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'flow_hidden',
        verbose_name: 'flow hidden',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'totals',
        verbose_name: 'totals',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'in_waitroom',
        verbose_name: 'in waitroom',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'waitroom_approved',
        verbose_name: 'waitroom approved',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'totals_delta',
        verbose_name: 'totals delta',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Get metrics for an event with segmentation.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://amplitude.com/api/2/events/list',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Basic {-basic_auth-}'
          }
        ],
        query_params: [
          {
            name: 'start',
            value: '{{params.start}}'
          },
          {
            name: 'end',
            value: '{{params.end}}'
          },
          {
            name: 'i',
            value: '{{params.i}}'
          },
          {
            name: 's',
            value: '{{params.s}}'
          },
          {
            name: 'g',
            value: '{{params.g}}'
          },
          {
            name: 'm',
            value: '{{params.m}}'
          },
          {
            name: 'e',
            value: '{{params.e}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        response_path: '',
        request_tokens: {}
      },
      pagination: null,
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      sorting: false
    },
    get_parameters: [
      {
        name: 'start',
        verbose_name: 'start',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'end',
        verbose_name: 'end',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'i',
        verbose_name: 'i',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 's',
        verbose_name: 'segment',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'g',
        verbose_name: 'group',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'm',
        verbose_name: 'metrics',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'e',
        verbose_name: 'event',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    create_parameters: [],
    update_parameters: [],
    delete_parameters: [],
    params: {}
  }
];

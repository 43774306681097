// import { TypesProject } from './project';

export class Company {
  public name: string;
  public industry: string;
  public role: string;
  public size: string;
  // public type: TypesProject;
  public website: string;
  public projectAbout: string;

  deserialize(data: Object): Company {
    this.name = data['name'];
    this.industry = data['industry'];
    this.role = data['role'];
    this.size = data['size'];
    // this.type = data['type'];
    this.website = data['website'];
    this.website = data['project_about'];

    return this;
  }

  serialize(): Object {
    return {
      name: this.name || '',
      industry: this.industry || '',
      role: this.role || '',
      size: this.size || '',
      // type: this.type || '',
      website: this.website || '',
      project_about: this.projectAbout || ''
    };
  }
}

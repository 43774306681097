<app-model-description-data-source-edit
  [control]="control"
  [label]="label"
  [typeOrange]="typeOrange"
  [queryOptionEmptyLabel]="queryOptionEmptyLabel"
  [search]="search"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [resourceClasses]="resourceClasses"
  [queryOptionClasses]="queryOptionClasses"
  [analyticsSource]="analyticsSource"
></app-model-description-data-source-edit>

<div *ngIf="control.controls.query.valid" class="sidebar__element">
  <app-sidebar-field [label]="'show'" [required]="true">
    <mat-menu #query_column_result="matMenu">
      <ng-template matMenuContent>
        <button
          *ngFor="let item of columnOptions$ | async"
          mat-menu-item
          [disableRipple]="true"
          (click)="control.controls.func.patchValue(undefined); control.controls.column.patchValue(item.value)"
        >
          {{ item.name }}
        </button>
        <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
          No result fields
        </div>
      </ng-template>
    </mat-menu>

    <mat-menu #query_column_func="matMenu">
      <ng-template matMenuContent let-query_func="query_func">
        <button
          *ngFor="let item of columnOptions$ | async"
          mat-menu-item
          [disableRipple]="true"
          (click)="control.controls.func.patchValue(query_func); control.controls.column.patchValue(item.value)"
        >
          {{ item.name }}
        </button>
        <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
          No result fields
        </div>
      </ng-template>
    </mat-menu>

    <mat-menu #query_func_menu="matMenu">
      <ng-template matMenuContent>
        <button
          *ngIf="
            (control.controls.type | appControlValue | async) == dataSourceTypes.Input ||
            (control.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
            ((control.controls.type | appControlValue | async) == dataSourceTypes.Query && control.controls.query.valid)
          "
          mat-menu-item
          [disableRipple]="true"
          [matMenuTriggerFor]="query_column_result"
        >
          Pre-aggregated Field
        </button>
        <ng-container *ngFor="let item of control.yColumnFuncOptions">
          <button
            *ngIf="item.hasField"
            mat-menu-item
            [disableRipple]="true"
            [matMenuTriggerFor]="query_column_func"
            [matMenuTriggerData]="{ query_func: item.value }"
          >
            {{ item.name }}
          </button>
          <button
            *ngIf="!item.hasField"
            mat-menu-item
            [disableRipple]="true"
            (click)="control.controls.func.patchValue(item.value); control.controls.column.patchValue(undefined)"
          >
            {{ item.name }}
          </button>
        </ng-container>
      </ng-template>
    </mat-menu>

    <div class="select select_theme_jet select_fill" [matMenuTriggerFor]="query_func_menu" #trigger="matMenuTrigger">
      <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
        <span class="select__input-label">
          <ng-container *ngIf="funcLabel$ | async as label; else label_placeholder">
            {{ label }}
          </ng-container>

          <ng-template #label_placeholder>
            Choose
          </ng-template>
        </span>
        <span class="select__input-arrow"></span>
      </a>
    </div>
    <app-field-errors [form]="control" [fieldName]="'func'"></app-field-errors>
  </app-sidebar-field>
</div>

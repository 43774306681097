export type TypedFunction<T = any> = (...args: unknown[]) => T;

export function cacheCall<T = any>(func: TypedFunction<T>): TypedFunction<T> {
  let cachedValue: any;

  return (...args) => {
    if (cachedValue === undefined) {
      cachedValue = func(...args);
    }

    return cachedValue;
  };
}

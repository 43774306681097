import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { isSet } from '@shared';

import { DocsDocument } from '../../data/document';

export interface DocsDocumentSelector {
  path?: string;
  filePath?: string;
  relativePath?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocsService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getDocument(selector: DocsDocumentSelector): Observable<DocsDocument> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('docs/documents');
        let headers = new HttpHeaders();
        const params = {};
        headers = this.apiService.setHeadersToken(headers);

        if (isSet(selector.path)) {
          params['path'] = selector.path;
        }

        if (isSet(selector.filePath)) {
          params['filePath'] = selector.filePath;
        }

        if (isSet(selector.relativePath)) {
          params['relativePath'] = selector.relativePath;
        }

        return this.http.get<DocsDocument>(url, { headers: headers, params: params });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { all, create, factory } from 'mathjs';
import './math-custom-functions';
import { mathCustomFunctions } from './math-custom-functions.const';
function EQUAL(a, b) {
    return a == b;
}
function UNEQUAL(a, b) {
    return a != b;
}
export var mathjs = create(__assign({}, all, { createEqual: factory('equal', [], function () { return EQUAL; }), createUnequal: factory('unequal', [], function () { return UNEQUAL; }) }), {
    matrix: 'Array'
});
mathjs.import(mathCustomFunctions, { override: true });

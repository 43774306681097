/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./refresh-data.component";
import * as i4 from "../../../../core/services/local-storage/local.storage";
var styles_RefreshDataComponent = [];
var RenderType_RefreshDataComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RefreshDataComponent, data: {} });
export { RenderType_RefreshDataComponent as RenderType_RefreshDataComponent };
function View_RefreshDataComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "dropdown dropdown_clip dropdown_position_top-left dropdown_icons dropdown refresh__dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "dropdown__inner refresh__dropdown-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "dropdown__item dropdown__item_no-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleRepeatUpdateData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "span", [["class", "dropdown__item-icon dropdown__item-icon_flexible"], ["style", "margin-top: -2px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "toggle"]], [[2, "toggle_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "toggle__track"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "toggle__knob"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [["class", "dropdown__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.runAutomatically; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), "Refresh automatically")); _ck(_v, 8, 0, currVal_1); }); }
export function View_RefreshDataComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.LocalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "refresh dropdown-anchor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "button button_icon button_transparent button_small refresh__button"], ["type", "button"]], [[2, "button_primary-transparent", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateData.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "button__icon icon-repeat refresh__button-icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RefreshDataComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.viewId; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.runAutomatically; _ck(_v, 2, 0, currVal_0); }); }
export function View_RefreshDataComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-refresh-data", [], null, null, null, View_RefreshDataComponent_0, RenderType_RefreshDataComponent)), i0.ɵdid(1, 4440064, null, 0, i3.RefreshDataComponent, [i4.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RefreshDataComponentNgFactory = i0.ɵccf("app-refresh-data", i3.RefreshDataComponent, View_RefreshDataComponent_Host_0, { viewId: "viewId" }, { updateData: "updateData" }, []);
export { RefreshDataComponentNgFactory as RefreshDataComponentNgFactory };

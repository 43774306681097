var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import keys from 'lodash/keys';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { applyParamInput, applyParamInput$, ComputedDisplayField, DisplayField, DisplayFieldType, FieldType, getFieldDescriptionByType, Input, transformFieldForeignKey } from '@modules/fields';
import { MessageService } from '@modules/messages';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { getDefaultValue, Model, ModelDescription, NO_PAGINATION_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { EMPTY, isSet } from '@shared';
import { ITEM_OUTPUT } from '../data/outputs';
var ColumnsModelListStore = /** @class */ (function (_super) {
    __extends(ColumnsModelListStore, _super);
    function ColumnsModelListStore(modelUtilsService, reducedModelService, queryService, messageService, injector, service, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, modelDescriptionDataSourceService, resourceControllerService) {
        var _this = _super.call(this, service, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, modelDescriptionDataSourceService, resourceControllerService) || this;
        _this.modelUtilsService = modelUtilsService;
        _this.reducedModelService = reducedModelService;
        _this.queryService = queryService;
        _this.messageService = messageService;
        _this.injector = injector;
        _this.ignoreErrors = true;
        return _this;
    }
    ColumnsModelListStore.prototype.deserializeModelAttributes = function () {
        var _this = this;
        if (!this.items) {
            return;
        }
        this.items = this.items.map(function (item) {
            item.model.deserializeAttributes(_this.dataSource.columns.filter(function (column) { return column.type != DisplayFieldType.Computed; }));
            _this.dataSource.columns
                .filter(function (column) { return column instanceof ComputedDisplayField; })
                .forEach(function (column) {
                var value = _this.resolveFlexItemValue(column, item.model);
                item.model.setAttribute(column.name, value);
            });
            return item;
        });
    };
    ColumnsModelListStore.prototype.fetchPage = function (page, next) {
        var _this = this;
        var cache = {};
        return this.fetchModelPage(page, next).pipe(switchMap(function (response) {
            if (!response || !response.items.length) {
                return of(response);
            }
            return combineLatest.apply(void 0, response.items.map(function (item) {
                return _this.resolveItemColumns(item, cache).pipe(map(function (columns) {
                    return {
                        columns: columns,
                        model: item
                    };
                }));
            })).pipe(map(function (items) {
                var newResponse = response;
                newResponse.items = items;
                return newResponse;
            }));
        }));
    };
    ColumnsModelListStore.prototype.resolveItemColumns = function (model, cache) {
        var _this = this;
        if (cache === void 0) { cache = {}; }
        var columns = this.dataSource.columns;
        if (!columns || !columns.length) {
            columns = keys(model.getAttributes()).map(function (item) {
                var result = new DisplayField({
                    name: item,
                    visible: true,
                    params: {}
                });
                result.updateFieldDescription();
                return result;
            });
        }
        if (!columns.length) {
            return of([]);
        }
        return combineLatest.apply(void 0, columns.map(function (item) {
            // if (!item.visible) {
            //   return of({
            //     column: item.name,
            //     value: undefined
            //   });
            // }
            var result = _this.resolveItemColumn(model, item, cache);
            if (result instanceof Observable) {
                if (_this.ignoreErrors) {
                    return result.pipe(catchError(function (e) {
                        console.log(e);
                        return of({
                            column: item.name,
                            value: undefined
                        });
                    }));
                }
                else {
                    return result;
                }
            }
            else {
                return of(result);
            }
        }));
    };
    // cacheColumnParam$(cache: Object, column: RawListViewSettingsColumn, name: string, obs: Observable<any>): Observable<any> {
    //   const key = [column.name, name].join('_');
    //
    //   if (!cache[key]) {
    //     cache[key] = obs.pipe(share());
    //   }
    //
    //   return cache[key];
    // }
    ColumnsModelListStore.prototype.resolveItemColumn = function (model, column, cache) {
        if (column instanceof ComputedDisplayField) {
            var value = this.resolveFlexItemValue(column, model);
            model.setAttribute(column.name, value);
            return this.getItemColumn(model, value, column, cache);
        }
        else {
            return this.getItemColumn(model, model.getAttribute(column.name), column, cache);
        }
    };
    ColumnsModelListStore.prototype.resolveFlexItemValue = function (column, model) {
        var _a;
        if (column.valueInput) {
            try {
                var value = applyParamInput(column.valueInput, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = model.getAttributes(),
                        _a)
                    // field: { field: column.field, params: column.params }
                });
                if (value !== EMPTY) {
                    return value;
                }
            }
            catch (e) { }
        }
        return getDefaultValue(column);
    };
    ColumnsModelListStore.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    ColumnsModelListStore.prototype.getItemColumn = function (model, value, column, cache) {
        var _this = this;
        //   const field = this.modelDescription.field(column.name);
        //
        //   if (field.type == ModelFieldType.Db) {
        var fieldDescription = getFieldDescriptionByType(column.field);
        var str;
        var fieldRelatedModel = column.params && column.params['related_model']
            ? new ModelDescription().deserialize(column.params['related_model'])
            : undefined;
        if (column.field == FieldType.RelatedModel && fieldRelatedModel && isSet(value)) {
            var resource_1 = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.dataSource.queryResource; });
            var controller = resource_1 ? this.resourceControllerService.get(resource_1.type) : undefined;
            var setUp$ = controller && controller.checkApiInfo ? controller.checkApiInfo(resource_1) : of(undefined);
            return setUp$.pipe(switchMap(function () { return _this.modelDescriptionStore.getDetailFirst(fieldRelatedModel.model); }), switchMap(function (relatedModelDescription) {
                var _a, _b;
                if (!relatedModelDescription) {
                    return of({
                        column: column.name
                    });
                }
                var idField = column.params['custom_primary_key'] || relatedModelDescription.primaryKeyField;
                var nameField = column.params['custom_display_field'] || relatedModelDescription.displayField;
                if (!isSet(nameField)) {
                    return of({
                        column: column.name
                    });
                }
                var obs$;
                var relatedValue = model.getRelationValue({ path: [column.name], field: nameField });
                if (relatedValue !== EMPTY) {
                    if (isSet(relatedValue)) {
                        var relatedModel = _this.createModel();
                        relatedModel.deserialize(relatedModelDescription.model, (_a = {}, _a[idField] = value, _a[nameField] = relatedValue, _a));
                        relatedModel.setUp(relatedModelDescription);
                        relatedModel.deserializeAttributes(relatedModelDescription.dbFields);
                        obs$ = of(relatedModel);
                    }
                    else {
                        obs$ = of(undefined);
                    }
                }
                else {
                    var commonParams = void 0;
                    var apiInfo = resource_1 ? resource_1.apiInfo : undefined;
                    var idValue = transformFieldForeignKey(column.params, value);
                    if (apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '0.5.9', jetDjango: '0.8.4' })) {
                        commonParams = (_b = {}, _b[NO_PAGINATION_PARAM] = 1, _b);
                    }
                    obs$ = _this.reducedModelService.getDetail(fieldRelatedModel.model, idField, idValue, {}, commonParams);
                }
                return obs$.pipe(map(function (relatedModel) {
                    if (!fieldDescription.valueToStrTableIgnore && relatedModel) {
                        var nameInput = column.params['custom_display_field_input']
                            ? new Input().deserialize(column.params['custom_display_field_input'])
                            : undefined;
                        if (nameInput) {
                            str = applyParamInput$(nameInput, {
                                localContext: {
                                    item: relatedModel.getAttributes()
                                }
                            });
                        }
                        else if (isSet(nameField)) {
                            str = of(relatedModel.hasAttribute(nameField)
                                ? relatedModel.getAttribute(nameField)
                                : relatedModel.getRawAttribute(nameField));
                        }
                        else if (relatedModelDescription) {
                            str = _this.modelUtilsService.str(relatedModel);
                        }
                        else {
                            str = of(undefined);
                        }
                    }
                    return {
                        column: column.name,
                        // value: this.deserializeItemColumn(column, value),
                        // formField: field.formField,
                        // context: {
                        //   model: model,
                        //   modelDescription: model.modelDescription,
                        //   related_model: relatedModel
                        // },
                        relatedModel: relatedModel,
                        relatedModelDescription: relatedModelDescription,
                        str: str
                    };
                }));
            }));
        }
        else {
            return {
                column: column.name
                // value: this.deserializeItemColumn(column, value)
            };
        }
        //     } else if (field.item.field == 'SelectField' && field.item.params['options_type'] == OptionsType.MessagesAPI) {
        //       const params = {
        //         field: field.name,
        //         model: model.model
        //       };
        //
        //       return this.cacheColumnParam$(
        //         cache,
        //         column,
        //         'messages_api_options',
        //         this.messageService.send(undefined, MessageName.GetFieldOptions, params)
        //           .pipe(map(result => {
        //             if (!result.json || !isArray(result.json)) {
        //               return;
        //             }
        //
        //             return result.json.map(item => {
        //               return {
        //                 name: item['name'],
        //                 value: this.deserializeItemColumn(column, item['value']),
        //                 color: item['color']
        //               };
        //             });
        //           }))
        //       ).pipe(map(options => {
        //         return {
        //           link: model.getLink(),
        //           value: value: this.deserializeItemColumn(column, model.getAttribute(field.name)),
        //           formField: field.formField,
        //           context: {
        //             model: model,
        //             modelDescription: model.modelDescription,
        //             messages_api_options: options
        //           },
        //           str: str
        //         };
        //       }));
        //     } else {
        //       if (fieldDescription.valueToStr && !fieldDescription.valueToStrTableIgnore) {
        //         const value = fieldDescription.valueToStr(model.getAttribute(field.name), { field: field.formField });
        //
        //         if (value !== undefined) {
        //           str = value;
        //         }
        //       }
        //
        //       return {
        //         link: model.getLink(),
        //         value: value: this.deserializeItemColumn(column, model.getAttribute(field.name)),
        //         formField: field.formField,
        //         context: {
        //           model: model,
        //           modelDescription: model.modelDescription
        //         },
        //         str: str
        //       };
        //     }
        //   } else if (field.type == ModelFieldType.Flex) {
        //     return this.flexFieldService.resolveCurrentField(this.modelDescription, model, field).pipe(
        //       map(value => {
        //         return {
        //           link: model.getLink(),
        //           value: this.deserializeItemColumn(column, value),
        //           formField: field.item.formField,
        //           context: {
        //             model: model,
        //             modelDescription: model.modelDescription
        //           }
        //         };
        //       }),
        //     );
        //   }
    };
    return ColumnsModelListStore;
}(ModelListStore));
export { ColumnsModelListStore };

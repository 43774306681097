import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BaseElementComponent } from '@modules/customize-elements';

@Component({
  selector: 'app-default-element',
  templateUrl: './default-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultElementComponent extends BaseElementComponent implements OnInit {
  ngOnInit() {}
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';
import { TaskQueue } from '../../data/task-queue';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/custom-http-parameter-codec/custom-http-parameter-codec";
var TaskQueueService = /** @class */ (function () {
    function TaskQueueService(http, apiService, parameterCodec) {
        this.http = http;
        this.apiService = apiService;
        this.parameterCodec = parameterCodec;
    }
    TaskQueueService.prototype.get = function (projectName, environmentName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({}, (draft && { draft: '1' }), params),
                encoder: _this.parameterCodec
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new TaskQueue().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskQueueService.prototype.createBulk = function (projectName, environmentName, instances) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instances.map(function (item) { return item.serialize(); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new TaskQueue().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskQueueService.prototype.create = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.post(url, instance.serialize(), { headers: headers });
        //   }),
        //   map(result => new TaskQueue().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    TaskQueueService.prototype.update = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `task_queues/${instance.uid}/`);
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.patch(url, instance.serialize(), { headers: headers });
        //   }),
        //   map(result => new TaskQueue().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    TaskQueueService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `task_queues/${instance.uid}/`);
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers });
        //   }),
        //   map(() => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    TaskQueueService.ngInjectableDef = i0.defineInjectable({ factory: function TaskQueueService_Factory() { return new TaskQueueService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CustomHttpParameterCodec)); }, token: TaskQueueService, providedIn: "root" });
    return TaskQueueService;
}());
export { TaskQueueService };

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';

import { mixpanelResourceParamsMenuSettings } from '../../data/mixpanel/mixpanel-resource-params-menu-settings.stub';
import { mixpanelResourceParamsModelDescriptions } from '../../data/mixpanel/mixpanel-resource-params-model-descriptions.stub';
import { mixpanelResourceParamsParams } from '../../data/mixpanel/mixpanel-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface MixpanelParamsOptions {
  key: string;
}

@Injectable()
export class MixpanelGeneratorService extends ResourceGeneratorService<MixpanelParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: MixpanelParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://data.mixpanel.com/api/2.0/export/';
    query.headers = [{ name: 'Authorization', value: `Basic ${this.authTokenFromOptions(options)}` }];
    query.queryParams = [
      { name: 'from_date', value: moment().format('YYYY-MM-DD') },
      { name: 'to_date', value: moment().format('YYYY-MM-DD') },
      { name: 'limit', value: '1' }
    ];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 400) {
          error = new ServerRequestError('API secret is not valid or not enough permissions');
        } else if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse) {
          error = new ServerRequestError(error.response.error);
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<MixpanelParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_BASIC_AUTH_VALUE,
        this.mode == AdminMode.Builder
      )
      .pipe(map(result => this.authTokenToOptions(result.value)));
  }

  authTokenFromOptions(options: MixpanelParamsOptions): string {
    try {
      return btoa([options.key, ''].join(':'));
    } catch (e) {}
  }

  authTokenToOptions(token: string): MixpanelParamsOptions {
    try {
      const [key, _] = atob(token).split(':', 2);
      return {
        key: key
      };
    } catch (e) {
      return {
        key: undefined
      };
    }
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: MixpanelParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = mixpanelResourceParamsParams;
    const modelDescriptions = mixpanelResourceParamsModelDescriptions;
    const menuSettings = mixpanelResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
    token.type = SecretTokenType.Static;
    token.value = this.authTokenFromOptions(options);

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()]
    });
  }
}

<div
  class="sidebar-image-card2"
  [class.sidebar-image-card2_movable]="movable"
  [class.sidebar-image-card2_hoverable]="hoverable"
  [class.sidebar-image-card2_purple-gradient]="purpleGradient"
  [class.sidebar-image-card2_shadow]="shadow"
>
  <div *ngIf="image || icon" class="sidebar-image-card2__image-container">
    <div *ngIf="image" class="sidebar-image-card2__image" [style.background-image]="imagePath"></div>
    <div *ngIf="icon && !image" class="sidebar-image-card2__image-icon" [ngClass]="['icon-' + icon]"></div>
    <img *ngIf="indicatorImage" class="sidebar-image-card2__image-indicator" [src]="indicatorImage" />
  </div>

  <div *ngIf="title | appIsSet" class="sidebar-image-card2__title">
    <span class="sidebar-image-card2__title-right sidebar-image-card2__icon icon-dots_small"></span>
    <div class="sidebar-image-card2__line">{{ title }}</div>
  </div>

  <div *ngIf="beta" class="sidebar-image-card2__tag background-color_blue_1">
    <span class="sidebar-image-card2__tag-raw">β</span>eta
  </div>
</div>

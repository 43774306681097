import { ListModelDescriptionDataSource, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionQuery } from '@modules/queries';

import { migrateModelDescriptionDataSource } from '../utils/migration';

export enum ModelDataType {
  Detail = 'detail',
  List = 'list'
}

export class ModelData {
  public type: ModelDataType;
  public uid: string;
  public name: string;
  public detailDataSource: ModelDescriptionDataSource;
  public listDataSource: ListModelDescriptionDataSource;

  deserialize(data: Object): ModelData {
    this.type = data['type'] || ModelDataType.Detail;
    this.uid = data['uid'];
    this.name = data['name'];

    if (data['detail_data_source']) {
      this.detailDataSource = new ModelDescriptionDataSource().deserialize(data['detail_data_source']);
    } else if (data['resource']) {
      // Backward compatibility
      this.type = ModelDataType.Detail;
      this.detailDataSource = migrateModelDescriptionDataSource(ModelDescriptionDataSource, ModelDescriptionQuery, {
        resource: data['resource'],
        query: data['query'],
        parameters: data['parameters'],
        inputs: data['inputs'],
        columns: data['columns']
      });
    }

    if (data['list_data_source']) {
      this.listDataSource = new ListModelDescriptionDataSource().deserialize(data['list_data_source']);
    }

    return this;
  }

  serialize() {
    return {
      type: this.type,
      uid: this.uid,
      name: this.name,
      detail_data_source: this.detailDataSource ? this.detailDataSource.serialize() : undefined,
      list_data_source: this.listDataSource ? this.listDataSource.serialize() : undefined
    };
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import toPairs from 'lodash/toPairs';
import { of } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { AppConfigService, LocalStorage, SessionStorage } from '@core';
import { ApiService } from '@modules/api';
import { getFilenameWithExtension, isSet } from '@shared';
import { Plan } from '../../data/plan';
import { Project } from '../../data/project';
import { PublicSettings } from '../../data/public-settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../core/services/local-storage/local.storage";
import * as i3 from "../../../../core/services/session-storage/session.storage";
import * as i4 from "../../../api/services/api/api.service";
import * as i5 from "../../../../core/services/app-config/app-config.service";
var NEW_PROJECT_NAME_KEY = 'new_project_name';
var ProjectService = /** @class */ (function () {
    function ProjectService(http, storageService, sessionStorage, apiService, appConfigService) {
        this.http = http;
        this.storageService = storageService;
        this.sessionStorage = sessionStorage;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
    }
    ProjectService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            httpParams = httpParams.set('v', _this.appConfigService.version);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.getAll = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            httpParams = httpParams.set('all', '1');
            httpParams = httpParams.set('v', _this.appConfigService.version);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Project().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.getDetail = function (uniqueName, environmentUniqueName, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + uniqueName + "/");
            var headers = new HttpHeaders();
            var params = __assign({}, (isSet(environmentUniqueName) && { env_name: environmentUniqueName }), (draft && { draft: '1' }), { v: _this.appConfigService.version });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.getDemo = function () {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/demo/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return result.map(function (item) { return new Project().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.popNewProjectName = function () {
        var result = this.sessionStorage.get(NEW_PROJECT_NAME_KEY);
        if (!isSet(result)) {
            return;
        }
        this.sessionStorage.remove(NEW_PROJECT_NAME_KEY);
        return result;
    };
    ProjectService.prototype.create = function (project, token, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/');
            var headers = new HttpHeaders();
            var params = {
                v: _this.appConfigService.version
            };
            var data = project.serialize(fields);
            data['token'] = token;
            headers = _this.apiService.setHeadersToken(headers);
            if (fields && fields.includes('logo') && project.logoFile) {
                var formData_1 = new FormData();
                toPairs(data).forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    if (v === undefined) {
                        return;
                    }
                    else if (['params', 'project_settings', 'menu_settings'].includes(k) && isSet(v)) {
                        v = JSON.stringify(v);
                    }
                    formData_1.append(k, v);
                });
                data = formData_1;
            }
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.createDemo = function (projectUniqueName, token) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/demo_create/');
            var headers = new HttpHeaders();
            var data = {
                demo_project_name: projectUniqueName,
                token: token
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.createFromTemplate = function (project, template) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('projects/');
            var headers = new HttpHeaders();
            var data = __assign({}, project.serialize(['name']), { template_project: template });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.generateUniqueName = function (name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/generate_unique_name/');
            var headers = new HttpHeaders();
            var data = {
                name: name
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) {
            return {
                uniqueName: result.unique_name,
                domainName: result.domain_name
            };
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.isUsedUniqueName = function (name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/is_used_unique_name/');
            var headers = new HttpHeaders();
            var data = {
                unique_name: name
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.result; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.isUsedDomainName = function (name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('projects/is_used_domain_name/');
            var headers = new HttpHeaders();
            var data = {
                domain_name: name
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.result; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.delete = function (project, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/");
            var data = __assign({}, (options.hardDelete && {
                hard_delete: true
            }));
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.request('DELETE', url, { body: data, headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectService.prototype.update = function (uniqueName, project, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + uniqueName + "/");
            var headers = new HttpHeaders();
            var data = project.serialize(fields);
            headers = _this.apiService.setHeadersToken(headers);
            if (fields && fields.includes('logo') && project.logoFile) {
                var formData_2 = new FormData();
                toPairs(data).forEach(function (_a) {
                    var k = _a[0], v = _a[1];
                    if (v === undefined) {
                        return;
                    }
                    else if (k == 'params' && isSet(v)) {
                        v = JSON.stringify(v);
                    }
                    formData_2.append(k, v);
                });
                data = formData_2;
            }
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectService.prototype.updateLogo = function (project, file) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/");
            var headers = new HttpHeaders();
            var data;
            if (file) {
                data = new FormData();
                data.append('logo', file);
            }
            else {
                data = {
                    logo: null
                };
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new Project().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectService.prototype.createProjectToken = function () {
        var url = this.apiService.methodURL('project_tokens/');
        return this.http.post(url, {}).pipe(map(function (result) { return result['token']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.copySettings = function (project, sourceProject, interfaceSettings) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/copy_settings/");
            var headers = new HttpHeaders();
            var data = {
                source_project: sourceProject.uniqueName
            };
            if (interfaceSettings) {
                data['interface_settings'] = interfaceSettings;
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectService.prototype.resetSettings = function (project, interfaceSettings) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/reset_settings/");
            var headers = new HttpHeaders();
            var data = {};
            if (interfaceSettings) {
                data['interface_settings'] = interfaceSettings;
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectService.prototype.getPlans = function (project, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(project.uniqueName, 'plans/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Plan().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.planOrder = function (project, plan, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(project.uniqueName, "plans/" + plan.uniqueName + "/order/");
            var headers = new HttpHeaders();
            var data = __assign({}, (isSet(options.annual) && { annual: options.annual }), (isSet(options.rewardful) && { rewardful: options.rewardful }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result['url']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.planSwitch = function (project, plan, annual) {
        var _this = this;
        if (annual === void 0) { annual = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(project.uniqueName, "plans/" + plan.uniqueName + "/switch/");
            var headers = new HttpHeaders();
            var data = {
                annual: annual
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.getPublicSettings = function (projectName, environmentName) {
        var url = isSet(environmentName)
            ? this.apiService.environmentMethodURL(projectName, environmentName, 'public_settings/')
            : this.apiService.projectMethodURL(projectName, 'public_settings/');
        return this.http.get(url).pipe(map(function (result) { return new PublicSettings().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectService.prototype.getProjectsLastUsed = function () {
        try {
            return JSON.parse(this.storageService.get('projects_last_used')) || {};
        }
        catch (e) {
            return {};
        }
    };
    ProjectService.prototype.updateProjectLastUsed = function (project) {
        if (project.demo && project.parent) {
            return;
        }
        var lastUsed = this.getProjectsLastUsed();
        lastUsed[project.uniqueName] = new Date().toISOString();
        this.storageService.set('projects_last_used', JSON.stringify(lastUsed));
    };
    ProjectService.prototype.sortProjectsLastUsed = function (projects) {
        var lastUsed = this.getProjectsLastUsed();
        return projects.sort(function (lhs, rhs) {
            if (lastUsed[lhs.uniqueName] == undefined && lastUsed[rhs.uniqueName] == undefined) {
                return 0;
            }
            else if (lastUsed[lhs.uniqueName] == undefined) {
                return 1;
            }
            else if (lastUsed[rhs.uniqueName] == undefined) {
                return -1;
            }
            else {
                return new Date(lastUsed[rhs.uniqueName]).getTime() - new Date(lastUsed[lhs.uniqueName]).getTime();
            }
        });
    };
    ProjectService.prototype.getLogoFile = function (logo) {
        return this.http.get(logo, { responseType: 'blob' }).pipe(map(function (blob) {
            var filename = getFilenameWithExtension(logo);
            return new File([blob], filename);
        }), catchError(function () { return of(undefined); }));
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.HttpClient), i0.inject(i2.LocalStorage), i0.inject(i3.SessionStorage), i0.inject(i4.ApiService), i0.inject(i5.AppConfigService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
export { ProjectService };
(function (ProjectService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new Project().deserialize(item); });
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        return GetResponse;
    }());
    ProjectService.GetResponse = GetResponse;
})(ProjectService || (ProjectService = {}));

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { IntercomService } from '@modules/analytics';
import { SignUpLayout } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, ImageFit } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
var CustomizeBarSigninEditComponent = /** @class */ (function () {
    function CustomizeBarSigninEditComponent(currentProjectStore, appConfigService, notificationService, featureService, projectDomainController, intercomService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.appConfigService = appConfigService;
        this.notificationService = notificationService;
        this.featureService = featureService;
        this.projectDomainController = projectDomainController;
        this.intercomService = intercomService;
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.booleanFieldStyle = BooleanFieldStyle;
        this.hasSSO = false;
        this.layoutOptions = [
            {
                layout: SignUpLayout.PosterRight,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#d9eaf4'
            },
            {
                layout: SignUpLayout.PosterLeft,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#d9eaf4'
            },
            {
                layout: SignUpLayout.Form,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#dce0e6'
            },
            {
                layout: SignUpLayout.Background,
                defaultBackgroundColor: '#fff'
            }
        ];
        this.imageFits = ImageFit;
        this.signUpBackgroundColors = ['blue', 'teal', 'maroon', 'navy', 'purple', 'orange'];
    }
    CustomizeBarSigninEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (project) {
            _this.signUpLink = _this.getSignUpLink();
            _this.cd.markForCheck();
        });
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.hasSSO = _this.domain && _this.domain.ssoSettings.length != 0;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarSigninEditComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CustomizeBarSigninEditComponent.prototype, "currentLayoutOption", {
        get: function () {
            var _this = this;
            return this.layoutOptions.find(function (item) { return item.layout == _this.projectDomainUpdateForm.controls.sign_up_layout.value; });
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarSigninEditComponent.prototype.showSignUpFeatureOverview = function () {
        if (this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
            return;
        }
        this.featureService.showFeatureOverview({
            subtitle: 'Customer Portal Feature',
            title: 'App sign up with <strong>Customer Access</strong>',
            description: "\n        <ul>\n          <li>Customers can get access to app on their own without invitation</li>\n          <li>Assign particular Team for newly registered customers</li>\n          <li>Filter data for customers</li>\n        </ul>\n      "
        });
    };
    CustomizeBarSigninEditComponent.prototype.getSignUpLink = function () {
        var project = this.currentProjectStore.instance;
        var webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : this.appConfigService.webBaseUrl;
        return webBaseUrl + "/register/" + this.currentProjectStore.instance.uniqueName;
    };
    CustomizeBarSigninEditComponent.prototype.copySignUpCode = function () {
        var _this = this;
        var text = this.getSignUpLink();
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            _this.notificationService.info('Copied', 'Link was copied to clipboard');
        });
    };
    CustomizeBarSigninEditComponent.prototype.editDomain = function () {
        this.projectDomainController
            .edit({ analyticsSource: 'layout_builder_sign_in' })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomizeBarSigninEditComponent.prototype.onDomainRequiredClick = function () {
        if (!this.domain) {
            this.editDomain();
        }
    };
    CustomizeBarSigninEditComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    return CustomizeBarSigninEditComponent;
}());
export { CustomizeBarSigninEditComponent };

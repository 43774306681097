import { ActionItem } from '@modules/actions';

export enum LayerInteractionType {
  HoverOutput = 'hover_output',
  PressedOutput = 'pressed_output',
  Click = 'click',
  DisableTextSelection = 'disable_text_selection',
  Cursor = 'cursor'
}

export class LayerInteraction {
  type: LayerInteractionType;

  constructor(options: Partial<LayerInteraction> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.type = data['type'];

    return this;
  }

  serialize(): Object {
    return {
      type: this.type
    };
  }
}

export class ActionLayerInteraction extends LayerInteraction {
  action: ActionItem;

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['action']) {
      this.action = new ActionItem().deserialize(data['action']);
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      action: this.action ? this.action.serialize() : undefined
    };
  }
}

export class CursorLayerInteraction extends LayerInteraction {
  cursor: string;

  deserialize(data: Object): this {
    super.deserialize(data);
    this.cursor = data['cursor'];

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      cursor: this.cursor
    };
  }
}

export function createLayerInteraction(type: LayerInteractionType): LayerInteraction {
  if ([LayerInteractionType.Click].includes(type)) {
    return new ActionLayerInteraction({ type: type });
  } else if ([LayerInteractionType.Cursor].includes(type)) {
    return new CursorLayerInteraction({ type: type });
  } else {
    return new LayerInteraction({ type: type });
  }
}

export function deserializeLayerInteraction(data: Object): LayerInteraction {
  const result = createLayerInteraction(data['type']);
  return result.deserialize(data);
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, timer } from 'rxjs';

import { ApiService } from '@modules/api';
import { FieldType, ParameterField } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryPagination } from '@modules/queries';
import { flattenTokens, Token } from '@modules/tokens';
import { CurrentUserStore } from '@modules/users';

import { QueryBuilderContext } from '../../data/query-builder-context';

export interface TokenSearchResult {
  query: string;
  exact: boolean;
}

export const CONTEXT_TOKEN = new InjectionToken<QueryBuilderContext>('QueryBuilderContext');
export const SEARCH_TOKEN = new InjectionToken<Observable<TokenSearchResult>>('Observable<TokenSearchResult>');

export interface PopupToken extends Token {
  reference?: string;
}

export interface InputTokensEvent<T = Object> {
  type: InputTokensEventType;
  data?: T;
}

export enum InputTokensEventType {
  SetSorting,
  SetPagination,
  AddParameter,
  Submit
}

export interface InputTokensEventSetSortingData {
  value: boolean;
}

export interface InputTokensEventSetPaginationData {
  pagination: QueryPagination;
}

@Component({
  selector: 'app-input-tokens',
  templateUrl: './input-tokens.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTokensComponent implements OnInit, OnDestroy {
  @Output() inserted = new EventEmitter<Token>();
  @Output() event = new EventEmitter<InputTokensEvent>();

  search: string;
  searchSuggestAdd = false;
  selectedToken: PopupToken;
  createdToken: PopupToken;
  selectedTokenValue = new FormControl('');
  choosePagination = false;
  specifyAddParameter = false;
  addParameterName = '';
  paginationOptions = [
    { value: QueryPagination.Page, name: 'Page pagination', icon: 'documents' },
    { value: QueryPagination.Offset, name: 'Offset pagination', icon: 'starts_with' },
    { value: QueryPagination.Cursor, name: 'Cursor pagination', icon: 'cursor' }
  ];
  queryPaginations = QueryPagination;
  mainTokens: Token[] = [];
  mainTokensFiltered: Token[] = [];
  otherTokens: Token[] = [];
  otherTokensFiltered: Token[] = [];
  paginationTokens: PopupToken[] = [];
  paginationTokensFiltered: PopupToken[] = [];
  sortingTokens: PopupToken[] = [];
  sortingTokensFiltered: PopupToken[] = [];
  tokenValues = {};

  constructor(
    @Inject(CONTEXT_TOKEN) public context: QueryBuilderContext,
    @Inject(SEARCH_TOKEN) public search$: Observable<TokenSearchResult>,
    private currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private apiService: ApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.search$.pipe(untilDestroyed(this)).subscribe(search => {
      if (search && search.query) {
        this.search = search.query;
        this.searchSuggestAdd =
          search.query && (!search.query.includes('.') || search.query.startsWith('params.')) && !search.exact;
        this.updatedTokensFiltered(search.query, search.exact);
      } else {
        this.search = undefined;
        this.searchSuggestAdd = false;
        this.updatedTokensFiltered();
      }

      this.cd.markForCheck();
    });

    combineLatest(this.context.tokens$, this.context.systemTokens$)
      .pipe(untilDestroyed(this))
      .subscribe(([tokens, systemTokens]) => {
        this.mainTokens = tokens.filter(item => {
          return item.children && (item.children.length || (item.data && item.data['parameter_tokens']));
        });

        const otherTokens: Token[] = [];
        const paginationTokens: Token[] = [];
        const sortingTokens: Token[] = [];

        systemTokens
          .filter(item => item.children && item.children.length)
          .forEach(item => {
            if (item.label == 'Pagination') {
              paginationTokens.push(...item.children);
            } else if (item.label == 'Sorting') {
              sortingTokens.push(...item.children);
            } else {
              otherTokens.push(item);
            }
          });

        this.otherTokens = otherTokens;
        this.paginationTokens = paginationTokens;
        this.sortingTokens = sortingTokens;

        this.cd.markForCheck();
        this.updatedTokensFiltered();
        this.tokenValues = this.context.getTokenValues();
        this.cd.markForCheck();
      });

    this.selectedTokenValue.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.setTokenValue(value));
  }

  ngOnDestroy(): void {}

  filterTokens(tokens: Token[], search: string): Token[] {
    const filterItems = (items: Token[]): Token[] => {
      return items
        .map(item => {
          if (item.name && item.name.startsWith(search)) {
            return item;
          } else if (item.children) {
            const result = {
              ...item,
              children: filterItems(item.children)
            };

            if (result.children.length) {
              return result;
            }
          }
        })
        .filter(item => item != undefined);
    };

    return filterItems(tokens);
  }

  updatedTokensFiltered(search?: string, exact?: boolean) {
    if (!search) {
      this.mainTokensFiltered = this.mainTokens;
      this.otherTokensFiltered = this.otherTokens;
      this.paginationTokensFiltered = this.paginationTokens;
      this.sortingTokensFiltered = this.sortingTokens;
      this.clearSelectedToken();
      this.cd.markForCheck();
      return;
    }

    this.mainTokensFiltered = this.filterTokens(this.mainTokens, search);
    this.otherTokensFiltered = this.filterTokens(this.otherTokens, search);
    this.paginationTokensFiltered = this.filterTokens(this.paginationTokens, search);
    this.sortingTokensFiltered = this.filterTokens(this.sortingTokens, search);

    const lists: Token[] = [...this.mainTokensFiltered, ...this.otherTokensFiltered];

    if (this.paginationTokensFiltered) {
      lists.push(...this.paginationTokensFiltered);
    }

    if (this.context.form.controls.sorting.value) {
      lists.push(...this.sortingTokensFiltered);
    }

    this.selectedToken = search ? flattenTokens(lists).find(item => item.name == search) : undefined;
    this.selectedTokenValue.patchValue(this.selectedToken ? this.context.getTokenValue(this.selectedToken) : '', {
      emitEvent: false
    });

    if (this.selectedToken && this.selectedToken.fixedValue) {
      this.selectedTokenValue.disable();
    } else {
      this.selectedTokenValue.enable();
    }

    if (exact && !this.selectedToken) {
      this.mainTokensFiltered = this.mainTokens;
      this.otherTokensFiltered = this.otherTokens;
      this.paginationTokensFiltered = this.paginationTokens;
      this.sortingTokensFiltered = this.sortingTokens;
    }

    this.selectedTokenValue.markAsPristine();
    this.cd.markForCheck();
  }

  setSorting(sorting: boolean) {
    this.context.form.controls.sorting.patchValue(sorting);
    this.updatedTokensFiltered(this.search);

    this.event.emit({
      type: InputTokensEventType.SetSorting,
      data: {
        value: sorting
      } as InputTokensEventSetSortingData
    });
  }

  setChoosePagination(value: boolean) {
    this.choosePagination = value;
    this.cd.markForCheck();
  }

  setPagination(pagination: QueryPagination) {
    this.context.form.setPagination(pagination);
    this.setChoosePagination(false);

    this.event.emit({
      type: InputTokensEventType.SetPagination,
      data: {
        pagination: pagination
      } as InputTokensEventSetPaginationData
    });
  }

  setSpecifyAddParameter(value: boolean) {
    this.specifyAddParameter = value;
    this.cd.markForCheck();
  }

  clearSelectedToken() {
    this.selectedToken = undefined;
    this.selectedTokenValue.patchValue('', { emitEvent: false });
    this.cd.markForCheck();
  }

  insert(item: Token) {
    this.inserted.next(item);
  }

  addParameter(name: string, value?: any) {
    if (!this.context.parametersControl) {
      return;
    }

    const result = new ParameterField();

    result.name = name;
    result.verboseName = name;
    result.field = FieldType.Text;
    result.required = true;
    result.updateFieldDescription();

    const parameters = [...(this.context.parametersControl.value || []), result];
    this.context.parametersControl.patchValue(parameters);

    timer(0)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const tokenName = ['params', name].join('.');
        const token = flattenTokens(this.context.tokens).find(item => item.name == tokenName);
        this.setSpecifyAddParameter(false);
        this.addParameterName = '';

        if (token) {
          this.insert(token);
        }

        this.selectedToken = token;
        this.createdToken = token;
        this.setTokenValue(value);
        this.cd.markForCheck();

        this.event.emit({
          type: InputTokensEventType.AddParameter
        });
      });
  }

  addParameterFromInput() {
    if (!this.addParameterName) {
      return;
    }

    this.addParameter(this.addParameterName, this.selectedTokenValue.value);
  }

  setAddParameterName(nameValue: string) {
    this.addParameterName = nameValue.replace(/[^\w]/g, '_').replace(/^\d/, '_');
  }

  submitTokenValue() {
    this.event.emit({
      type: InputTokensEventType.Submit
    });
  }

  setTokenValue(value: any) {
    if (!this.selectedToken) {
      return;
    }

    this.context.setTokenValue(this.selectedToken, value);
    this.selectedTokenValue.markAsPristine();
  }

  get currentPaginationOption() {
    return this.paginationOptions.find(item => item.value == this.context.form.controls.pagination.value);
  }
}

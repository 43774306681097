/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "@angular/common";
import * as i3 from "./customize-toolbar-bottom-container.component";
import * as i4 from "../../services/customize-toolbar-bottom/customize-toolbar-bottom.service";
var styles_CustomizeToolbarBottomContainerComponent = [];
var RenderType_CustomizeToolbarBottomContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeToolbarBottomContainerComponent, data: {} });
export { RenderType_CustomizeToolbarBottomContainerComponent as RenderType_CustomizeToolbarBottomContainerComponent };
function View_CustomizeToolbarBottomContainerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CustomizeToolbarBottomContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeToolbarBottomContainerComponent_2)), i0.ɵdid(1, 212992, null, 0, i1.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomizeToolbarBottomContainerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeToolbarBottomContainerComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templatePortals; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomizeToolbarBottomContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-customize-toolbar-bottom-container", [], null, null, null, View_CustomizeToolbarBottomContainerComponent_0, RenderType_CustomizeToolbarBottomContainerComponent)), i0.ɵdid(1, 245760, null, 0, i3.CustomizeToolbarBottomContainerComponent, [i4.CustomizeToolbarBottomService, i0.ViewContainerRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeToolbarBottomContainerComponentNgFactory = i0.ɵccf("app-customize-toolbar-bottom-container", i3.CustomizeToolbarBottomContainerComponent, View_CustomizeToolbarBottomContainerComponent_Host_0, {}, {}, []);
export { CustomizeToolbarBottomContainerComponentNgFactory as CustomizeToolbarBottomContainerComponentNgFactory };

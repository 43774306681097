import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { RawListViewSettingsColumn, ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import {
  applyParamInput,
  defaultFieldType,
  FieldOutput,
  Input,
  InputValueType,
  isRequiredInputsSet,
  ParameterArray,
  ParameterField
} from '@modules/fields';
import { ModelDescription } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProvider,
  InputFieldProviderItem,
  parametersToProviderItemsFlat
} from '@modules/parameters';
import { QueryService } from '@modules/queries';
import { WorkflowResult } from '@modules/workflow';
import { controlValue } from '@shared';

export const validateInputs: ValidatorFn = control => {
  const parent = control.parent as CustomizeWorkflowResultForm;
  if (!parent) {
    return;
  }

  const fields = parent.inputFieldProvider.fields;
  const inputs: Input[] = control.value;

  if (!isRequiredInputsSet(fields, inputs)) {
    return { required: true };
  }
};

export enum WorkflowResultType {
  Auto = 'auto',
  Manual = 'manual'
}

@Injectable()
export class CustomizeWorkflowResultForm extends FormGroup implements OnDestroy {
  result: WorkflowResult;
  resultOutputs: FieldOutput[];
  inputFieldProvider = new InputFieldProvider();

  controls: {
    type: FormControl;
    parameters: ParameterArray;
    inputs: FormControl;
    array: FormControl;
    array_input: FieldInputControl;
  };

  typeOptions: Option<string>[] = [
    {
      value: WorkflowResultType.Auto,
      name: 'Use last step',
      icon: 'magic_wand'
    },
    {
      value: WorkflowResultType.Manual,
      name: 'Specify outputs',
      icon: 'edit'
    }
  ];

  constructor(private queryService: QueryService) {
    super({
      type: new FormControl(WorkflowResultType.Auto),
      parameters: new ParameterArray([]),
      inputs: new FormControl([], validateInputs),
      array: new FormControl(false),
      array_input: new FieldInputControl({ name: 'value' })
    });

    this.inputFieldProvider.fields$.subscribe(() => {
      this.controls.inputs.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(result: WorkflowResult, resultOutputs?: FieldOutput[]) {
    this.result = result;
    this.resultOutputs = resultOutputs;

    if (result) {
      if (resultOutputs && resultOutputs.length && result) {
        this.controls.inputs.patchValue(result.inputs);
      } else if (result) {
        this.controls.type.patchValue(WorkflowResultType.Manual);
        this.controls.parameters.patchValue(result.parameters);
        this.controls.inputs.patchValue(result.inputs);
        this.controls.array.patchValue(result.array);
        this.controls.array_input.patchValue(result.arrayInput ? result.arrayInput.serialize() : {});
      } else {
        this.controls.type.patchValue(WorkflowResultType.Auto);
      }

      this.markAsPristine();
    }

    if (resultOutputs && resultOutputs.length) {
      this.updateInputFieldProvider().subscribe();
    } else {
      controlValue(this.controls.parameters).subscribe(() => this.updateInputFieldProvider().subscribe());
    }

    this.controls.array.valueChanges.subscribe(() => {
      this.controls.parameters.patchValue([]);
      this.controls.inputs.patchValue([]);
    });
  }

  updateInputFieldProvider() {
    if (this.resultOutputs && this.resultOutputs.length) {
      const items: InputFieldProviderItem[] = this.resultOutputs.map(item => {
        return {
          label: item.verboseName || item.name,
          field: {
            ...item
          }
        };
      });

      this.inputFieldProvider.setProvider(items);
      return of(items);
    } else {
      return controlValue<ParameterField[]>(this.controls.parameters).pipe(
        first(),
        map(parameters => {
          return parametersToProviderItemsFlat((parameters || []).filter(item => item.name));
        }),
        tap(items => {
          this.inputFieldProvider.setProvider(items);
        })
      );
    }
  }

  getArrayInputDefaultParameters(
    isDataArray: boolean,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<ParameterField[]> {
    const getContextColumns = (value: Input): Observable<RawListViewSettingsColumn[]> => {
      return options.context.getToken$(value.contextValue, options.contextElement).pipe(
        map(result => {
          if (!result || !result.children || !result.children.length) {
            return;
          }

          const columns =
            isDataArray && result.children[0].children && result.children[0].children.length
              ? result.children[0].children
              : result.children;

          return columns.map(item => {
            return {
              name: item.uniqueName,
              verboseName: item.name,
              field: item.fieldType || defaultFieldType,
              params: item.fieldParams || {}
            };
          });
        })
      );
    };

    return controlValue(this.controls.array_input).pipe(
      switchMap(value => {
        const input = value ? new Input().deserialize(value) : undefined;

        if (!input) {
          return of(undefined);
        }

        const contextColumns$ =
          options.context && input.valueType == InputValueType.Context ? getContextColumns(input) : of(undefined);

        return contextColumns$.pipe(
          map(contextColumns => {
            if (contextColumns) {
              return contextColumns;
            }

            try {
              const data = applyParamInput(input, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: {}
              });

              return isDataArray
                ? this.queryService.autoDetectGetFields(data)
                : this.queryService.autoDetectGetDetailFields(data);
            } catch (e) {}
          })
        );
      }),
      map(result => {
        if (!result) {
          return;
        }

        return result.map(item => {
          const parameter = new ParameterField();

          parameter.name = item.name;
          parameter.verboseName = item.verboseName;
          parameter.field = item.field;
          parameter.params = item.params;

          return parameter;
        });
      })
    );
  }

  isParametersSame$(lhs: ParameterField[], rhs: ParameterField[]): boolean {
    lhs = lhs.filter(item => !item['flex']);
    rhs = rhs.filter(item => !item['flex']);

    return lhs.length == rhs.length && lhs.every(lhsItem => !!rhs.find(rhsItem => rhsItem.name == lhsItem.name));
  }

  getAutoDetectedParameters$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<ParameterField[]> {
    return this.getArrayInputDefaultParameters(true, options);
  }

  setColumns(columns: ParameterField[], options: { markAsDirty?: boolean; modelDescription?: ModelDescription } = {}) {
    this.controls.parameters.setValue(columns);

    if (options.markAsDirty) {
      this.controls.parameters.markAsDirty();
    }
  }

  resetArrayInputParameters(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      markAsDirty?: boolean;
    } = {}
  ) {
    return this.getAutoDetectedParameters$({
      context: options.context,
      contextElement: options.contextElement,
      localContext: options.localContext
    })
      .pipe(first(), untilDestroyed(this))
      .subscribe(columns => {
        if (columns) {
          this.setColumns(columns, { markAsDirty: options.markAsDirty });
        }
      });
  }

  submit(): WorkflowResult {
    if (this.resultOutputs && this.resultOutputs.length) {
      const result = cloneDeep(this.result) || new WorkflowResult();

      result.array = false;
      result.inputs = this.controls.inputs.value;

      return result;
    } else if (this.controls.type.value == WorkflowResultType.Auto) {
      return undefined;
    } else {
      const result = cloneDeep(this.result) || new WorkflowResult();

      result.parameters = this.controls.parameters.value;
      result.inputs = this.controls.inputs.value;
      result.array = this.controls.array.value;

      if (this.controls.array_input.value) {
        result.arrayInput = new Input().deserialize(this.controls.array_input.value);
      } else {
        result.arrayInput = undefined;
      }

      return result;
    }
  }
}

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { gteFieldLookup, ltFieldLookup } from '../base-lookups';
import { FieldLookup } from './base';

export class IsPastFieldLookup extends FieldLookup {
  public lookup = 'is_past';
  public param = 'isPast';
  public verboseName = localize('is past');
  public icon = 'calendar';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not in the past', [field]);
      } else {
        return localize('is not in the past');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is in the past', [field]);
      } else {
        return localize('in the past');
      }
    }
  }

  compute(
    field: string[],
    value: any,
    exclude: boolean
  ): { field: string[]; lookup: FieldLookup; value: any; exclude?: boolean }[] {
    return exclude
      ? [
          {
            field: field,
            lookup: gteFieldLookup,
            value: moment().toISOString()
          }
        ]
      : [
          {
            field: field,
            lookup: ltFieldLookup,
            value: moment().toISOString()
          }
        ];
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';

import { isSet } from '@shared';

// TODO: Refactor import
import { getColorHex, isLightColor } from '../../../modules/colors/utils/colors';

export type ProjectLogoSize = 'xxs' | 'xs' | 's' | 'sm' | 'm';
export type ProjectLogoBorderRadius = 's' | 'm';

@Component({
  selector: 'app-project-logo',
  templateUrl: './project-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectLogoComponent implements OnInit, OnChanges {
  @Input() color: string;
  @Input() colorDefault = '';
  @Input() logo: string;
  @Input() isButton = false;
  @Input() initials: string;
  @Input() icon: string;
  @Input() size: ProjectLogoSize = 'm';
  @Input() borderRadius: ProjectLogoBorderRadius = 'm';
  @Input() borderRadiusPercent: number;
  @Input() border = false;
  @Input() fill = false;
  @Input() badgeColor: string;

  @HostBinding('style') style: SafeStyle;

  light = false;
  colorDisplay: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const color = isSet(this.color) ? this.color : this.colorDefault;

    this.colorDisplay = this.cleanColor(color);
    this.light = isLightColor(this.colorDisplay);
    this.style = this.getStyleVars(this.colorDisplay);
  }

  cleanColor(color: string) {
    return color == 'blue' ? 'bright-blue' : color;
  }

  getDarkColor(color: string): string {
    if (color == 'transparent') {
      color = 'white';
    }

    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.6).string();
    } catch (e) {}
  }

  getStyleVars(color: string): SafeStyle {
    const darkColor = this.getDarkColor(color);

    const vars = {};

    if (darkColor) {
      vars['dark-color'] = darkColor;
    }

    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}

<div
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="true"
  [class.sidebar-collapse_block]="true"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="false"
>
  <div class="sidebar-collapse__header" [class.sidebar-collapse__header_error]="false" (click)="toggleOpened()">
    <div class="sidebar-collapse__header-left">
      <span
        class="sidebar-collapse__header-icon"
        [ngClass]="(item.icon | appIsSet) ? 'icon-' + item.icon : 'icon-' + item.fieldDescription.icon"
        style="margin: 0 0 0 8px;"
      ></span>
    </div>
    <div class="sidebar-collapse__header-main">
      <div class="sidebar-collapse__header-title">
        {{ item.verboseName || item.name }}
      </div>

      <div
        *ngIf="(valueControl.value | appIsSet) && !opened"
        class="sidebar-collapse__header-description"
        [class.sidebar-collapse__header-description_wrap]="true"
      >
        {{ valueControl.value }}
      </div>

      <div
        *ngIf="(item.description | appIsSet) && (opened || !(valueControl.value | appIsSet))"
        class="sidebar-collapse__header-description"
      >
        {{ item.description }}
      </div>
    </div>
    <div class="sidebar-collapse__header-right">
      <div
        class="sidebar-list-item__action"
        [class.icon-arrow_forward_2]="false"
        [class.icon-arrow_down_2]="true"
      ></div>
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content" [class.sidebar-collapse__content_padding]="true">
    <app-auto-field
      *ngIf="item.field == fieldTypes.Boolean"
      [form]="valueControlForm"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          field: fieldTypes.Select,
          placeholder: placeholder,
          params: {
            valueEquals: booleanValueEquals,
            options: [
              { value: false, name: 'False' },
              { value: true, name: 'True' }
            ],
            classes: ['select_fill']
          },
          reset_enabled: true
        })
      "
      [autofocus]="autofocus"
    ></app-auto-field>

    <app-auto-field
      *ngIf="item.field != fieldTypes.Boolean"
      [form]="valueControlForm"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          placeholder: placeholder,
          field: item.field,
          params: itemParams,
          reset_enabled: true
        })
      "
      [autofocus]="autofocus"
    >
    </app-auto-field>
  </div>
</div>

import pickBy from 'lodash/pickBy';

export enum SecretTokenType {
  Static = 'static',
  Firebase = 'firebase',
  OAuth = 'oauth'
}

export class SecretToken {
  public resource: string;
  public name: string;
  public type: SecretTokenType = SecretTokenType.Static;
  public value: string;
  public params: Object;
  public draft = false;
  public deleted = false;

  deserialize(data: Object): SecretToken {
    this.resource = data['resource'];
    this.name = data['name'];
    this.value = data['value'];
    this.params = data['params'];

    if (data['type']) {
      this.type = data['type'];
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      resource: this.resource,
      name: this.name,
      type: this.type,
      value: this.value,
      params: this.params,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

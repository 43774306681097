import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { IntercomService } from '@modules/analytics';
import { Font, fonts } from '@modules/theme';
import { ascComparator, isSet } from '@shared';

@Component({
  selector: 'app-font-menu',
  templateUrl: './font-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() value: string;
  @Output() fontSelect = new EventEmitter<string>();

  @ViewChild('viewport') viewport: ElementRef;
  @ViewChildren('font_item_element') fontItemElements = new QueryList<ElementRef>();

  searchControl = new FormControl('');
  defaultOption = fonts.find(item => item.default);
  options = fonts.sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
  filteredOptions: Font[] = [];
  firstVisibleIndex: number;
  firstInvisibleIndex: number;

  trackFont = (() => {
    return (i, item: Font) => {
      return item.name;
    };
  })();

  constructor(private intercomService: IntercomService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateFilteredOptions();

    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredOptions());
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent(this.viewport.nativeElement, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateVisible());

    this.updateVisible();
  }

  getFilteredOptions(): Font[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.options;
    }

    return this.options.filter(item => {
      return item.name.toLowerCase().includes(search);
    });
  }

  updateFilteredOptions() {
    this.filteredOptions = this.getFilteredOptions();
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredOptions();
  }

  updateVisible() {
    const viewportBounds = this.viewport.nativeElement.getBoundingClientRect();
    const fontItemElements = this.fontItemElements.toArray();
    const firstVisibleIndex = fontItemElements.findIndex(item => {
      const bounds = item.nativeElement.getBoundingClientRect();
      return bounds.top <= viewportBounds.bottom && bounds.bottom >= viewportBounds.top;
    });
    const firstInvisibleIndex =
      firstVisibleIndex !== -1
        ? fontItemElements.findIndex((item, i) => {
            if (i <= firstVisibleIndex) {
              return false;
            }

            const bounds = item.nativeElement.getBoundingClientRect();
            return bounds.top > viewportBounds.bottom;
          })
        : -1;

    this.firstVisibleIndex = firstVisibleIndex !== -1 ? firstVisibleIndex : undefined;
    this.firstInvisibleIndex = firstInvisibleIndex !== -1 ? firstInvisibleIndex : undefined;
    this.cd.markForCheck();
  }

  isOptionVisible(index: number): boolean {
    return (
      isSet(this.firstVisibleIndex) &&
      index >= this.firstVisibleIndex &&
      (!isSet(this.firstInvisibleIndex) || index < this.firstInvisibleIndex)
    );
  }

  openChat() {
    this.intercomService.openChat();
  }
}

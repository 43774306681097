import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CHART_COLORS, getDatasetsEffectiveColors } from '@modules/charts';
import { ViewContext } from '@modules/customize';
import { ChartType, Widget } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid, controlValue } from '@shared';
import { CustomizeBarContext } from '../../../../services/customize-bar-context/customize-bar.context';
import { ChartWidgetDataSourceEditComponent } from '../../../chart-widget-data-source-edit/chart-widget-data-source-edit.component';
import { DataSourceInputsEditComponent } from '../../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ChartWidgetEditFormDatasetControl } from '../chart-widget-edit-dataset.control';
import { ChartWidgetGroupArray } from '../chart-widget-group.array';
var CustomizeBarChartWidgetEditDatasetComponent = /** @class */ (function () {
    function CustomizeBarChartWidgetEditDatasetComponent(customizeBarContext, analyticsService, cd) {
        this.customizeBarContext = customizeBarContext;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.datasetColumnEnabled = false;
        this.datasetColumnAllowed = false;
        this.deleteEnabled = false;
        this.singleColorDataset = false;
        this.labelEnabled = true;
        this.firstInit = false;
        this.setupOnCreate = false;
        this.addInput = false;
        this.delete = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.dataSourceTypes = DataSourceType;
        this.colors = CHART_COLORS;
        this.collapseContext = new SidebarCollapseContext();
        this.groupsColors = [];
    }
    CustomizeBarChartWidgetEditDatasetComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.groupsControl) {
            controlValue(this.groupsControl)
                .pipe(untilDestroyed(this))
                .subscribe(function (datasets) {
                var datasetColors = datasets.map(function (item) { return item.color; });
                _this.groupsColors = getDatasetsEffectiveColors(datasetColors, CHART_COLORS);
                _this.cd.markForCheck();
            });
        }
        this.form.controls.data_source
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.widget.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.form.controls.data_source.getResource$().pipe(skip(1)), this.form.controls.data_source.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.widget.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        controlValid(this.form.controls.data_source)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
                ComponentTypeID: _this.widget.analyticsName
            });
        });
        controlValid(this.form.controls.data_source.controls.query_resource)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
                ComponentTypeID: _this.widget.analyticsName
            });
        });
        controlValid(this.form.controls.data_source.controls.query_inputs)
            .pipe(filter(function (configured, i) { return configured && i > 0; }), untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
                ComponentTypeID: _this.widget.analyticsName
            });
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
            ComponentTypeID: this.widget.analyticsName
        });
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.addInput && _this.inputsEditComponent) {
                _this.inputsEditComponent.openAddInput();
            }
        }, 0);
    };
    Object.defineProperty(CustomizeBarChartWidgetEditDatasetComponent.prototype, "subtitle", {
        get: function () {
            return this.form.controls.name.value || 'New';
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarChartWidgetEditDatasetComponent.prototype.back = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.cancel = function () {
        this.back();
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.submit = function () {
        this.back();
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.addQueryInput();
        }
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.getGroupDefaultColor = function (index) {
        var datasetColors = this.groupsControl.controls.map(function (item) { return item.controls.color.value; });
        return getDatasetsEffectiveColors(datasetColors, CHART_COLORS, index)[index];
    };
    CustomizeBarChartWidgetEditDatasetComponent.prototype.dragDropGroup = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.groupsControl.controls, event.previousIndex, event.currentIndex);
            this.groupsControl.updateValueAndValidity();
        }
    };
    return CustomizeBarChartWidgetEditDatasetComponent;
}());
export { CustomizeBarChartWidgetEditDatasetComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../parameters-components/components/view-context-token-popover-overlay/view-context-token-popover-overlay.component.ngfactory";
import * as i5 from "../../../../../parameters-components/components/view-context-token-popover-overlay/view-context-token-popover-overlay.component";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i9 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i10 from "./customize-bar-action-edit-type-run-javascript.component";
var styles_CustomizeBarActionEditTypeRunJavaScriptComponent = [];
var RenderType_CustomizeBarActionEditTypeRunJavaScriptComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeRunJavaScriptComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeRunJavaScriptComponent as RenderType_CustomizeBarActionEditTypeRunJavaScriptComponent };
function View_CustomizeBarActionEditTypeRunJavaScriptComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(2, 770048, [[1, 4], ["js_field", 4]], 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(3, 1), i0.ɵpod(4, { language: 0, autogrow: 1, flexible: 2, field: 3, field_contrast: 4, wrap: 5, dark: 6, classes: 7 }), i0.ɵpod(5, { name: 0, field: 1, placeholder: 2, params: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 5, 0, "run_javascript", "CodeField", _co.jsPlaceholder, _ck(_v, 4, 0, "javascript", !_co.fillVertical, _co.fillVertical, true, true, true, _co.dark, _ck(_v, 3, 0, (_co.fill ? "input_fill" : "")).concat((_co.classes || i0.ɵEMPTY_ARRAY))))); var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomizeBarActionEditTypeRunJavaScriptComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "input input_disabled"]], [[2, "input_fill", null], [2, "input_fill-vertical", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fill; var currVal_1 = _co.fillVertical; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CustomizeBarActionEditTypeRunJavaScriptComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "input-error input-error_overlay input-error_rounded-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error__inner"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jsError; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.jsError; _ck(_v, 2, 0, currVal_1); }); }
function View_CustomizeBarActionEditTypeRunJavaScriptComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeRunJavaScriptComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "app-view-context-token-popover-overlay", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        _co.onJsTokenSelected($event);
        var pd_0 = (_co.closeTokenPopover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ViewContextTokenPopoverOverlayComponent_0, i4.RenderType_ViewContextTokenPopoverOverlayComponent)), i0.ɵdid(4, 770048, [[2, 4]], 0, i5.ViewContextTokenPopoverOverlayComponent, [i6.FocusMonitor, i0.ChangeDetectorRef], { context: [0, "context"], contextElement: [1, "contextElement"], contextElementPath: [2, "contextElementPath"], contextElementPaths: [3, "contextElementPaths"], origin: [4, "origin"], control: [5, "control"], hideSections: [6, "hideSections"] }, { selected: "selected" }), i0.ɵpad(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "button-tags"], ["style", "padding-top: 4px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["cdkOverlayOrigin", ""], ["class", "button-tag button-tags__item button-tags__item_fill"]], [[2, "button-tag_darker", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTokenPopover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, [["insert_token_origin", 4]], 0, i7.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "button-tag__icon button-tag__icon_text"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", "..", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Insert Input"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jsError; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.contextElementPath; var currVal_4 = _co.contextElementPaths; var currVal_5 = i0.ɵnov(_v, 8); var currVal_6 = _co.form.controls.run_javascript; var currVal_7 = _ck(_v, 5, 0, "functions"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.darker; _ck(_v, 7, 0, currVal_8); var currVal_9 = "{"; var currVal_10 = "}"; _ck(_v, 10, 0, currVal_9, currVal_10); }); }
export function View_CustomizeBarActionEditTypeRunJavaScriptComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { jsField: 0 }), i0.ɵqud(671088640, 2, { tokenPopoverOverlayComponent: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i8.View_SidebarFieldComponent_0, i8.RenderType_SidebarFieldComponent)), i0.ɵdid(4, 114688, null, 0, i9.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(5, 0, null, 3, 4, "div", [["class", "field field_content-fill-vertical input-icon-wrapper"]], [[2, "input-icon-wrapper_fill-vertical", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeRunJavaScriptComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeRunJavaScriptComponent_2)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_CustomizeBarActionEditTypeRunJavaScriptComponent_3)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "JavaScript code"; _ck(_v, 4, 0, currVal_0); var currVal_2 = !_co.isInputOverlayOpened(); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.isInputOverlayOpened(); _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.isInputOverlayOpened(); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fillVertical; _ck(_v, 5, 0, currVal_1); }); }
export function View_CustomizeBarActionEditTypeRunJavaScriptComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-run-javascript", [], null, null, null, View_CustomizeBarActionEditTypeRunJavaScriptComponent_0, RenderType_CustomizeBarActionEditTypeRunJavaScriptComponent)), i0.ɵdid(1, 245760, null, 0, i10.CustomizeBarActionEditTypeRunJavaScriptComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeRunJavaScriptComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-run-javascript", i10.CustomizeBarActionEditTypeRunJavaScriptComponent, View_CustomizeBarActionEditTypeRunJavaScriptComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeRunJavaScriptComponentNgFactory as CustomizeBarActionEditTypeRunJavaScriptComponentNgFactory };

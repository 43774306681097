import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconDirective } from './directives/icon/icon.directive';
import { IconTypePipe } from './pipes/icon-type/icon-type.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IconDirective, IconTypePipe],
  exports: [IconDirective, IconTypePipe]
})
export class IconsModule {}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DateFormat, defaultDateFormat, defaultTimeFormat, momentDateFormats, momentTimeFormats, TimeFormat } from '@modules/fields';
import { isSet } from '@shared';
var DateTimeFieldViewParamsForm = /** @class */ (function () {
    function DateTimeFieldViewParamsForm(fb) {
        this.fb = fb;
        this.dateFormatOptions = [
            { value: DateFormat.Local, name: "Local (" + moment().format(momentDateFormats[DateFormat.Local]) + ")" },
            { value: DateFormat.Friendly, name: "Friendly (" + moment().format(momentDateFormats[DateFormat.Friendly]) + ")" },
            { value: DateFormat.US, name: "US (" + moment().format(momentDateFormats[DateFormat.US]) + ")" },
            { value: DateFormat.European, name: "European (" + moment().format(momentDateFormats[DateFormat.European]) + ")" },
            { value: DateFormat.ISO, name: "ISO (" + moment().format(momentDateFormats[DateFormat.ISO]) + ")" },
            { value: DateFormat.Custom, name: 'Custom' }
        ];
        this.dateFormatEditOptions = this.dateFormatOptions.filter(function (item) { return item.value !== DateFormat.Friendly; });
        this.timeFormatOptions = [
            {
                value: TimeFormat.HourMinute24,
                name: "24 hour (" + moment().format(momentTimeFormats[TimeFormat.HourMinute24]) + ")"
            },
            {
                value: TimeFormat.HourMinuteSecond24,
                name: "24 hour with seconds (" + moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond24]) + ")"
            },
            {
                value: TimeFormat.HourMinute12,
                name: "12 hour (" + moment().format(momentTimeFormats[TimeFormat.HourMinute12]) + ")"
            },
            {
                value: TimeFormat.HourMinuteSecond12,
                name: "12 hour with seconds (" + moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond12]) + ")"
            },
            {
                value: TimeFormat.Custom,
                name: 'Custom'
            }
        ];
        this.form = this.fb.group({
            time: new FormControl(true),
            relative: new FormControl(false),
            date_format: new FormControl(defaultDateFormat),
            date_format_custom: new FormControl(momentDateFormats[DateFormat.US]),
            time_format: new FormControl(defaultTimeFormat),
            time_format_custom: new FormControl(momentTimeFormats[TimeFormat.HourMinuteSecond24])
        });
    }
    DateTimeFieldViewParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            var value = {};
            if (isSet(control.value['time'])) {
                value['time'] = control.value['time'];
            }
            if (isSet(control.value['relative'])) {
                value['relative'] = control.value['relative'];
            }
            if (isSet(control.value['date_format']) && momentDateFormats[control.value['date_format']]) {
                value['date_format'] = control.value['date_format'];
            }
            else if (isSet(control.value['date_format'])) {
                value['date_format'] = DateFormat.Custom;
                value['date_format_custom'] = control.value['date_format'];
            }
            if (isSet(control.value['time_format']) && momentTimeFormats[control.value['time_format']]) {
                value['time_format'] = control.value['time_format'];
            }
            else if (isSet(control.value['time_format'])) {
                value['time_format'] = TimeFormat.Custom;
                value['time_format_custom'] = control.value['time_format'];
            }
            this.form.patchValue(value, { emitEvent: false });
        }
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    DateTimeFieldViewParamsForm.prototype.submit = function () {
        var data = {
            time: this.form.value['time'],
            relative: this.form.value['relative'],
            date_format: this.form.value['date_format'] == DateFormat.Custom && isSet(this.form.value['date_format_custom'])
                ? this.form.value['date_format_custom']
                : this.form.value['date_format'],
            time_format: this.form.value['time_format'] == TimeFormat.Custom && isSet(this.form.value['time_format_custom'])
                ? this.form.value['time_format_custom']
                : this.form.value['time_format']
        };
        this.control.patchValue(__assign({}, this.control.value, data));
    };
    return DateTimeFieldViewParamsForm;
}());
export { DateTimeFieldViewParamsForm };

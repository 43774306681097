import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, delayWhen, filter } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { ModelDataType, processElementItemResources, ViewContext, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { generateUUID } from '@shared';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarPageEditQueryComponent } from './customize-bar-page-edit-query/customize-bar-page-edit-query.component';
import { CustomizeBarPageEditForm } from './customize-bar-page-edit.form';
var CustomizeBarPageEditComponent = /** @class */ (function () {
    function CustomizeBarPageEditComponent(form, modelDescriptionStore, dialogService, currentUserStore, currentProjectStore, currentEnvironmentStore, viewSettingsService, viewSettingsStore, notificationService, customizeBarContext, cd) {
        this.form = form;
        this.modelDescriptionStore = modelDescriptionStore;
        this.dialogService = dialogService;
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.notificationService = notificationService;
        this.customizeBarContext = customizeBarContext;
        this.cd = cd;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.analyticsObject = 'page';
        this.modelDataTypes = ModelDataType;
    }
    CustomizeBarPageEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.viewSettings);
        this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            var result = _this.form.submit();
            _this.emitUpdate(result);
        });
        var modelId = [this.viewSettings.resource, this.viewSettings.model].join('.');
        this.modelDescriptionStore
            .getDetailFirst(modelId)
            .pipe(untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.modelDescription = modelDescription;
            _this.cd.markForCheck();
        });
        this.sourceTemplateId =
            this.viewSettings && this.viewSettings.sourceTemplate != undefined
                ? String(this.viewSettings.sourceTemplate)
                : '';
    };
    CustomizeBarPageEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarPageEditComponent.prototype.close = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarPageEditComponent.prototype.submit = function () {
        var result = this.form.submit();
        this.emitUpdate(result, true);
        this.close();
    };
    CustomizeBarPageEditComponent.prototype.emitUpdate = function (result, submit) {
        if (submit === void 0) { submit = false; }
        if (this.viewSettings) {
            this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
        }
        else {
            this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
        }
    };
    CustomizeBarPageEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarPageEditComponent.prototype.delete = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete page " + this.viewSettings.name + "?",
            style: 'orange'
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.event.emit({ type: CustomizeBarEditEventType.Deleted });
            _this.close();
        });
    };
    Object.defineProperty(CustomizeBarPageEditComponent.prototype, "title", {
        get: function () {
            if (this.form.controls.name.value) {
                return this.form.controls.name.value;
            }
            else if (this.viewSettings) {
                return 'Page';
            }
            else if (!this.viewSettings) {
                return 'New Page';
            }
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarPageEditComponent.prototype.editQuery = function (control, index) {
        var _this = this;
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarPageEditQueryComponent,
            inputs: {
                control: control,
                parentForm: this.form,
                context: this.context,
                object: this.analyticsObject
            },
            outputs: {
                delete: [
                    function () {
                        _this.removeQuery(control);
                    }
                ]
            }
        });
    };
    CustomizeBarPageEditComponent.prototype.removeQuery = function (control) {
        this.form.controls.queries.removeControl(control);
    };
    CustomizeBarPageEditComponent.prototype.addQuery = function (type) {
        var control = this.form.controls.queries.appendControl({
            value: {
                type: type,
                name: "Query " + (this.form.controls.queries.length + 1)
            }
        });
        this.editQuery(control, this.form.controls.queries.length - 1);
    };
    CustomizeBarPageEditComponent.prototype.getQueryTitle = function (control, index) {
        if (control.controls.name.value) {
            return control.controls.name.value;
        }
        else {
            return "Query " + (index + 1);
        }
    };
    CustomizeBarPageEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.queries.controls, event.previousIndex, event.currentIndex);
            this.form.controls.queries.updateValueAndValidity();
        }
    };
    CustomizeBarPageEditComponent.prototype.updateTemplateInfo = function () {
        var _this = this;
        var page = cloneDeep(this.viewSettings);
        var pageResources = {};
        var processResource = function (resourceName) {
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == resourceName; });
            if (resource) {
                pageResources[resourceName] = resource;
            }
            return resourceName;
        };
        processElementItemResources(page, processResource);
        page.sourceTemplate = this.sourceTemplateId ? parseInt(this.sourceTemplateId, 10) : undefined;
        page.templateInstanceId = generateUUID();
        page.usedResources = values(pageResources).map(function (item) {
            return {
                type: item.type,
                typeItem: item.typeItem,
                name: item.uniqueName
            };
        });
        this.viewSettingsService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, page)
            .pipe(delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), untilDestroyed(this))
            .subscribe(function () {
            _this.notificationService.success('Updated', 'Template info updated successfully');
        });
    };
    return CustomizeBarPageEditComponent;
}());
export { CustomizeBarPageEditComponent };

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { combineLatest, concat, Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap, toArray } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import { FieldType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import {
  Environment,
  Project,
  Resource,
  ResourceType,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import {
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ModelDescriptionSimpleQuery,
  QueryType
} from '@modules/queries';
import {
  AIRTABLE_CREATED_TIME,
  AIRTABLE_FIELD_ID_PARAM,
  AIRTABLE_PRIMARY_KEY,
  AIRTABLE_TABLE_UID_PARAM,
  AIRTABLE_TABLE_VIEW_ID_PARAM,
  AirtableBasePermissionLevel,
  AirtableBaseResponse,
  AirtableFieldType,
  airtableReadOnlyFieldTypes,
  AirtableResourceController,
  AirtableTableResponse,
  ResourceControllerService,
  ResourceParamsResult,
  SOURCE_FIELD_TYPE
} from '@modules/resources';
import { forceObservable, isSet } from '@shared';

import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import {
  airtableFieldMapping,
  AirtableFieldMappingFieldWithParams,
  defaultAirtableFieldMapping
} from './airtable-field-mapping';

const readOnlyFields = [AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME];

export interface AirtableParamsOptionsTable {
  id: string;
  view: string;
}

export enum AirtableAuthType {
  OAuth = 'oauth',
  PersonalAccessToken = 'personal_access_token',
  ApiKey = 'api_key'
}

export interface AirtableParamsOptions {
  auth_type: AirtableAuthType;
  access_token?: string;
  token_params?: Object;
  personal_access_token?: string;
  base: string;
  tables?: AirtableParamsOptionsTable[];
  // Backward compatibility
  key?: string;
}

@Injectable()
export class AirtableGeneratorService extends ResourceGeneratorService<AirtableParamsOptions> {
  tokenNameOAuth = 'oauth_access_token';
  tokenNamePersonalAccessToken = 'personal_access_token';
  tokenNameLegacy = 'api_key';
  controller: AirtableResourceController;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private resourceControllerService: ResourceControllerService,
    private secretTokenService: SecretTokenService,
    private apiService: ApiService
  ) {
    super();
    this.controller = this.resourceControllerService.get<AirtableResourceController>(ResourceType.Airtable);
  }

  getAccessToken(options: AirtableParamsOptions): string {
    const authType = options.auth_type || AirtableAuthType.OAuth;

    if (authType == AirtableAuthType.PersonalAccessToken) {
      return options.personal_access_token;
    } else {
      return options.access_token;
    }
  }

  isOptionsValid(options: AirtableParamsOptions): Observable<IsOptionsValidResult> {
    const accessToken = this.getAccessToken(options);
    return this.controller.getBases({ accessToken: accessToken, key: options.key }).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('API Key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<AirtableParamsOptions> {
    const authType = resource.params['auth_type'] || AirtableAuthType.OAuth;

    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        authType == AirtableAuthType.PersonalAccessToken ? this.tokenNamePersonalAccessToken : this.tokenNameOAuth,
        this.mode == AdminMode.Builder
      )
      .pipe(
        catchError(() => {
          return this.secretTokenService.getDetail(
            project.uniqueName,
            environment.uniqueName,
            resource.uniqueName,
            this.tokenNameLegacy,
            this.mode == AdminMode.Builder
          );
        }),
        catchError(() => of(undefined)),
        map((secretToken: SecretToken) => {
          return {
            ...(secretToken &&
              secretToken.name == this.tokenNameOAuth && {
                auth_type: AirtableAuthType.OAuth,
                access_token: secretToken.value,
                token_params: secretToken.params
              }),
            ...(secretToken &&
              secretToken.name == this.tokenNamePersonalAccessToken && {
                auth_type: AirtableAuthType.PersonalAccessToken,
                personal_access_token: secretToken.value
              }),
            ...(secretToken &&
              secretToken.name == this.tokenNameLegacy && {
                auth_type: AirtableAuthType.ApiKey,
                key: secretToken.value
              }),
            base: resource.params['base'],
            tables: resource.params['tables']
          };
        })
      );
  }

  getUniqueName(name: string) {
    return slugify(name, { trim: true, separator: '_' }).replace(/_+/g, '_');
  }

  createModelDescriptionMeta(
    options: AirtableParamsOptions,
    table: AirtableTableResponse,
    tables: AirtableTableResponse[]
  ): Observable<{
    fields: ModelField[];
    displayField: string;
    view: string;
  }> {
    const accessToken = this.getAccessToken(options);
    const defaultViewId = table.views.length ? table.views[0].id : undefined;
    const optionsTable = options.tables ? options.tables.find(item => item.id == table.id) : undefined;
    const optionsTableViewId = optionsTable ? optionsTable.view : undefined;
    const viewId = isSet(optionsTableViewId) ? optionsTableViewId : defaultViewId;

    return this.controller
      .getBaseTableRecords({
        base: options.base,
        table: table.id,
        accessToken: accessToken,
        key: options.key,
        viewId: viewId
      })
      .pipe(
        switchMap(modelResponse => {
          const fieldsObs = [
            {
              name: AIRTABLE_PRIMARY_KEY,
              fieldWithParams: {
                field: FieldType.Text,
                params: {}
              },
              source_type: AirtableFieldType.SingleLineText,
              editable: false
            },
            ...uniqBy(table.fields, item => item.name)
              .filter(item => ![AirtableFieldType.Button].includes(item.type))
              .map(item => {
                const mapping = airtableFieldMapping.find(i => i.type == item.type);
                const resultMapping = mapping ? mapping.mapping : defaultAirtableFieldMapping;
                const extraParams = { [AIRTABLE_FIELD_ID_PARAM]: item.id };
                let fieldWithParams: Observable<AirtableFieldMappingFieldWithParams>;

                if (resultMapping.getField) {
                  fieldWithParams = forceObservable(
                    resultMapping.getField({
                      name: item.name,
                      records: modelResponse.records,
                      options: options,
                      table: table,
                      field: item,
                      tables: tables,
                      controller: this.controller
                    })
                  ).pipe(
                    map(field => {
                      return {
                        ...field,
                        params: {
                          ...field.params,
                          ...extraParams
                        }
                      };
                    })
                  );
                } else if (resultMapping.getParams) {
                  fieldWithParams = forceObservable(
                    resultMapping.getParams({
                      name: item.name,
                      records: modelResponse.records,
                      options: options,
                      table: table,
                      field: item,
                      tables: tables,
                      controller: this.controller
                    })
                  ).pipe(
                    map(params => {
                      return {
                        field: resultMapping.field,
                        params: {
                          ...params,
                          ...extraParams
                        }
                      };
                    })
                  );
                } else {
                  fieldWithParams = of({
                    field: resultMapping.field,
                    params: { ...resultMapping.params, ...extraParams }
                  });
                }

                return {
                  name: item.name,
                  fieldWithParams: fieldWithParams,
                  source_type: item.type,
                  editable:
                    !airtableReadOnlyFieldTypes.includes(item.type) &&
                    ![
                      AirtableFieldType.SingleCollaborator,
                      AirtableFieldType.MultipleCollaborators,
                      AirtableFieldType.Barcode
                    ].includes(item.type)
                };
              }),
            {
              name: AIRTABLE_CREATED_TIME,
              fieldWithParams: {
                field: FieldType.DateTime,
                params: {}
              },
              source_type: AirtableFieldType.DateTime,
              editable: false
            }
          ].map(item => {
            return forceObservable(item.fieldWithParams).pipe(
              map(fieldWithParams => {
                const field = new ModelField();
                const dbField = new ModelDbField();

                dbField.name = item.name;

                if (dbField.name == AIRTABLE_PRIMARY_KEY) {
                  dbField.verboseName = 'ID';
                  dbField.required = true;
                } else {
                  dbField.required = false;
                }

                dbField.field = fieldWithParams.field;
                dbField.editable = item.editable;
                dbField.filterable = !readOnlyFields.includes(item.name);
                dbField.sortable = !readOnlyFields.includes(item.name);
                dbField.params = {
                  ...fieldWithParams.params,
                  [SOURCE_FIELD_TYPE]: item.source_type
                };
                dbField.updateFieldDescription();

                field.name = item.name;
                field.type = ModelFieldType.Db;
                field.item = dbField;

                return field;
              })
            );
          });

          return concat(...fieldsObs).pipe(
            toArray(),
            map(fields => {
              const displayField = table.fields.find(item => item.id == table.primaryFieldId);

              return {
                fields: fields,
                displayField: displayField ? displayField.name : undefined,
                view: viewId
              };
            })
          );
        })
      );
  }

  createModelDescription(
    options: AirtableParamsOptions,
    base: AirtableBaseResponse,
    table: AirtableTableResponse,
    tables: AirtableTableResponse[]
  ): Observable<ModelDescription> {
    if (!base || !table) {
      return of(undefined);
    }

    const modelDescription = new ModelDescription();

    modelDescription.project = '{{project}}';
    modelDescription.resource = '{{resource}}';
    modelDescription.model = [base.id, table.id].join('_');
    modelDescription.verboseName = table.name;
    modelDescription.verboseNamePlural = table.name;
    modelDescription.primaryKeyField = AIRTABLE_PRIMARY_KEY;

    return this.createModelDescriptionMeta(options, table, tables).pipe(
      map(meta => {
        modelDescription.fields = meta.fields;
        modelDescription.defaultFields = cloneDeep(meta.fields);

        modelDescription.displayField = meta.displayField;
        modelDescription.params = {
          [AIRTABLE_TABLE_UID_PARAM]: table.id,
          [AIRTABLE_TABLE_VIEW_ID_PARAM]: meta.view
        };

        const getSimpleQuery = new ModelDescriptionSimpleQuery();
        getSimpleQuery.model = modelDescription.model;

        modelDescription.getQuery = new ListModelDescriptionQuery();
        modelDescription.getQuery.queryType = QueryType.Simple;
        modelDescription.getQuery.simpleQuery = getSimpleQuery;
        modelDescription.getQuery.sortingFields = meta.fields.map(item => {
          return {
            name: item.name,
            sortable: ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(item.name)
          };
        });

        // const getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
        // getDetailSimpleQuery.model = modelDescription.model;
        //
        // modelDescription.getDetailQuery = new ModelDescriptionQuery();
        // modelDescription.getDetailQuery.queryType = QueryType.Simple;
        // modelDescription.getDetailQuery.simpleQuery = getDetailSimpleQuery;
        // modelDescription.getDetailParametersUseDefaults = true;

        if (base && base.permissionLevel == AirtableBasePermissionLevel.Create) {
          const createSimpleQuery = new ModelDescriptionSimpleQuery();
          createSimpleQuery.model = modelDescription.model;

          modelDescription.createQuery = new ModelDescriptionQuery();
          modelDescription.createQuery.queryType = QueryType.Simple;
          modelDescription.createQuery.simpleQuery = createSimpleQuery;
          modelDescription.createParametersUseDefaults = true;
        }

        if (
          base &&
          [AirtableBasePermissionLevel.Create, AirtableBasePermissionLevel.Edit].includes(base.permissionLevel)
        ) {
          const updateSimpleQuery = new ModelDescriptionSimpleQuery();
          updateSimpleQuery.model = modelDescription.model;

          modelDescription.updateQuery = new ModelDescriptionQuery();
          modelDescription.updateQuery.queryType = QueryType.Simple;
          modelDescription.updateQuery.simpleQuery = updateSimpleQuery;
          modelDescription.updateParametersUseDefaults = true;
        }

        if (
          base &&
          [AirtableBasePermissionLevel.Create, AirtableBasePermissionLevel.Edit].includes(base.permissionLevel)
        ) {
          const deleteSimpleQuery = new ModelDescriptionSimpleQuery();
          deleteSimpleQuery.model = modelDescription.model;

          modelDescription.deleteQuery = new ModelDescriptionQuery();
          modelDescription.deleteQuery.queryType = QueryType.Simple;
          modelDescription.deleteQuery.simpleQuery = deleteSimpleQuery;
          modelDescription.deleteParametersUseDefaults = true;
        }

        return modelDescription;
      }),
      this.apiService.catchApiError<ModelDescription, ModelDescription>()
    );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: AirtableParamsOptions
  ): Observable<ResourceParamsResult> {
    const accessToken = this.getAccessToken(options);

    return combineLatest(
      this.controller.getBases({ accessToken: accessToken, key: options.key }),
      this.controller.getBaseTables({ base: options.base, accessToken: accessToken, key: options.key })
    ).pipe(
      switchMap(([basesResponse, tablesResponse]) => {
        const base = basesResponse.bases.find(i => i.id == options.base);
        const activeTables = tablesResponse.tables.filter(table => {
          if (options.tables) {
            return options.tables.some(item => item.id == table.id);
          } else {
            return true;
          }
        });

        return concat(
          ...activeTables.map(item => {
            return this.createModelDescription(options, base, item, tablesResponse.tables);
          })
        ).pipe(toArray());
      }),
      map((modelDescriptions: ModelDescription[]) => modelDescriptions.filter(item => item != undefined)),
      map((modelDescriptions: ModelDescription[]) => {
        const token = new SecretToken();

        token.resource = '{{resource}}';

        if (options.auth_type == AirtableAuthType.PersonalAccessToken) {
          token.name = this.tokenNamePersonalAccessToken;
          token.type = SecretTokenType.Static;
          token.value = options.personal_access_token;
        } else {
          token.name = this.tokenNameOAuth;
          token.type = SecretTokenType.OAuth;
          token.value = options.access_token;

          try {
            token.params = options.token_params;
          } catch (e) {
            token.params = {};
          }
        }

        const resourceParams = {
          auth_type: options.auth_type,
          base: options.base,
          tables: options.tables
        };

        return {
          resourceParams: resourceParams,
          modelDescriptions: modelDescriptions.map(item => item.serialize()),
          secretTokens: [token.serialize()]
        };
      })
    );
  }
}

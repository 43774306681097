/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-description.component";
var styles_PageDescriptionComponent = [];
var RenderType_PageDescriptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageDescriptionComponent, data: {} });
export { RenderType_PageDescriptionComponent as RenderType_PageDescriptionComponent };
export function View_PageDescriptionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "page-description"]], [[2, "page-description_align_center", null], [2, "page-description_align_right", null]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.align == "center"); var currVal_1 = (_co.align == "right"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PageDescriptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-description", [], null, null, null, View_PageDescriptionComponent_0, RenderType_PageDescriptionComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageDescriptionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageDescriptionComponentNgFactory = i0.ɵccf("app-page-description", i1.PageDescriptionComponent, View_PageDescriptionComponent_Host_0, { align: "align" }, {}, ["*"]);
export { PageDescriptionComponentNgFactory as PageDescriptionComponentNgFactory };

import { DomPortalHost } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';
import Quill from 'quill';
import { Subscription } from 'rxjs';

import { InlineInputEditComponent } from '@modules/parameters-components';
import { controlValue } from '@shared';

const Embed = Quill.import('blots/embed');

export const inlineInputInitialProperty = '_initial_property';

export class InlineInputDynamicComponent {
  subscriptions: Subscription[] = [];

  constructor(private componentRef: ComponentRef<InlineInputEditComponent>, private portalHost: DomPortalHost) {}

  getComponent(): InlineInputEditComponent {
    return this.componentRef.instance;
  }

  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  destroy() {
    this.portalHost.detach();
    this.subscriptions.forEach(item => item.unsubscribe());
  }
}

class ContextFormulaBlot extends Embed {
  static blotName = 'context-formula';
  static tagName = 'app-quill-formula';

  dynamicComponent: InlineInputDynamicComponent;

  static create(value) {
    const node = super.create(value);
    node['_value'] = value;
    node.setAttribute('contenteditable', false);
    return node;
  }

  static value(node) {
    return node['_value'];
  }

  getQuill() {
    let node = this.domNode;

    while (node) {
      const quill = Quill.find(node);
      if (quill instanceof Quill) {
        return quill;
      }

      node = node.parentElement;
    }
  }

  getOptions() {
    const quill = this.getQuill();
    if (quill) {
      const contextFormula = quill.getModule('contextFormula');
      if (contextFormula) {
        return contextFormula.options;
      }
    }
  }

  attach(): void {
    super.attach();
    this.initComponent();
  }

  detach(): void {
    super.detach();

    const options = this.getOptions();
    if (options && this.dynamicComponent) {
      options.disposeComponentFn(this.dynamicComponent);
    }
  }

  initComponent() {
    if (this.dynamicComponent) {
      return;
    }

    const options = this.getOptions();
    if (!options) {
      return;
    }

    this.dynamicComponent = options.createComponentFn(this.domNode, this.domNode['_value']);

    const valueSubscription = controlValue(this.dynamicComponent.getComponent().itemForm).subscribe(itemForm => {
      this.domNode['_value'] = itemForm;
    });
    this.dynamicComponent.addSubscription(valueSubscription);
  }
}

Quill.register('formats/context-formula', ContextFormulaBlot);

const QuillModule = Quill.import('core/module');

class ContextFormulaModule extends QuillModule {
  constructor(quill, options) {
    super(quill, options);
    const toolbar = quill.getModule('toolbar');

    if (toolbar) {
      toolbar.addHandler('context-formula', () => {
        const range = quill.getSelection();
        const index = range ? range.index : 0;

        quill.insertEmbed(index, 'context-formula', {
          value_type: 'formula',
          formula_value: '',
          [inlineInputInitialProperty]: true
        });
      });
    }
  }
}

Quill.register('modules/contextFormula', ContextFormulaModule);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName, ResourceType } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { XanoResourceSettingsForm } from './xano-resource-settings.form';
var XanoResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(XanoResourceSettingsComponent, _super);
    function XanoResourceSettingsComponent(form, chooseSyncController, resourceControllerService, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.form = form;
        _this.chooseSyncController = chooseSyncController;
        _this.resourceControllerService = resourceControllerService;
        _this.loadingDomains = false;
        _this.domainOptions = [];
        _this.loadingWorkspaces = false;
        _this.workspaceOptions = [];
        _this.controller = _this.resourceControllerService.get(ResourceType.Xano);
        return _this;
    }
    XanoResourceSettingsComponent.prototype.onFormInit = function () {
        var _this = this;
        _super.prototype.onFormInit.call(this);
        controlValue(this.form.form.controls['access_token'])
            .pipe(switchMap(function (accessToken) {
            if (!isSet(accessToken)) {
                return of([]);
            }
            _this.loadingDomains = true;
            _this.cd.markForCheck();
            return _this.controller.getInstances(accessToken).pipe(catchError(function () { return of([]); }));
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.domainOptions = result.map(function (item) {
                return {
                    value: item.xano_domain,
                    name: item.xano_domain,
                    subtitle: item.display
                };
            });
            _this.loadingDomains = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loadingDomains = false;
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.form.form.controls['access_token']), controlValue(this.form.form.controls['domain']))
            .pipe(switchMap(function (_a) {
            var accessToken = _a[0], domain = _a[1];
            if (!isSet(accessToken) && !isSet(domain)) {
                return of([]);
            }
            _this.loadingWorkspaces = true;
            _this.cd.markForCheck();
            return _this.controller.getWorkspaces(accessToken, domain).pipe(catchError(function () { return of([]); }));
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.workspaceOptions = result.map(function (item) {
                return {
                    value: item.id,
                    name: item.name,
                    subtitle: "ID: " + item.id
                };
            });
            _this.loadingWorkspaces = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loadingWorkspaces = false;
            _this.cd.markForCheck();
        });
        this.form.form.controls['access_token'].valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
            _this.form.form.controls['domain'].patchValue(undefined);
            _this.form.form.controls['workspace_id'].patchValue(undefined);
        });
    };
    XanoResourceSettingsComponent.prototype.chooseSync = function () {
        var _this = this;
        this.chooseSyncController
            .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.submit(); });
    };
    return XanoResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { XanoResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.Xano, XanoResourceSettingsComponent);

<app-element-wrapper
  [wrap]="wrappable"
  [attr.element-groupable]="groupable ? true : null"
  (mouseenter)="hover$.next(true)"
  (mouseleave)="hover$.next(false)"
>
  <div class="element-placeholder-stub">
    <div class="element-placeholder-stub-wrapper">
      <!--      <div-->
      <!--        *ngIf="isCreateColumnsAllowed() && !(siblingRightEntered$ | async)"-->
      <!--        class="element-placeholder-stub__sibling element-placeholder-stub__sibling_left"-->
      <!--        [class.element-placeholder-stub__sibling_active]="siblingLeftEntered$ | async"-->
      <!--        (mouseenter)="siblingLeftEntered$.next(true)"-->
      <!--        (mouseleave)="siblingLeftEntered$.next(false)"-->
      <!--      >-->
      <!--        <div *ngIf="siblingLeftEntered$ | async" class="element-placeholder-stub">-->
      <!--          <div class="element-placeholder-stub__inner"></div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div
        class="element-placeholder-stub__inner"
        [class.element-placeholder-stub__inner_secondary]="
          (siblingLeftEntered$ | async) || (siblingRightEntered$ | async)
        "
      ></div>

      <!--      <div-->
      <!--        *ngIf="isCreateColumnsAllowed() && !(siblingLeftEntered$ | async)"-->
      <!--        class="element-placeholder-stub__sibling element-placeholder-stub__sibling_right"-->
      <!--        [class.element-placeholder-stub__sibling_active]="siblingRightEntered$ | async"-->
      <!--        (mouseenter)="siblingRightEntered$.next(true)"-->
      <!--        (mouseleave)="siblingRightEntered$.next(false)"-->
      <!--      >-->
      <!--        <div *ngIf="siblingRightEntered$ | async" class="element-placeholder-stub">-->
      <!--          <div class="element-placeholder-stub__inner"></div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</app-element-wrapper>

import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';

export class IntervalAutomationTrigger extends AutomationTrigger {
  type = AutomationTriggerType.Interval;
  interval: number;

  deserialize(data: Object): this {
    this.interval = data['interval'];

    return this;
  }

  serialize() {
    return {
      interval: this.interval
    };
  }
}

registerAutomationTriggerForType(AutomationTriggerType.Interval, IntervalAutomationTrigger);

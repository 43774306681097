import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-compact-checkbox',
  templateUrl: './compact-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompactCheckboxComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactCheckboxComponent implements OnInit {
  @Input() formControl: FormControl;
  @Input() label: string;
  @Output() change = new EventEmitter<void>();

  value = false;

  onChange = (value: boolean) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnInit() {}

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = !!value;
  }

  updateValue(value: boolean) {
    this.value = value;
    this.onChange(this.value);
    this.change.emit();
  }
}

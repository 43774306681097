import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { Environment, Project, ProjectToken, ProjectTokenService, Resource } from '@modules/projects';
import { BaseResourceSettingsForm } from './base-resource-settings.form';
var BaseResourceSettingsComponent = /** @class */ (function () {
    function BaseResourceSettingsComponent(form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        this.form = form;
        this.popupComponent = popupComponent;
        this.projectTokenService = projectTokenService;
        this.intercomService = intercomService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.resourceNameEditing = false;
        this.resourceDelete = false;
        this.params = {};
        this.saved = new EventEmitter();
        this.canceled = new EventEmitter();
        this.deleteRequested = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.loadingSubmit = false;
        this.slowLoading = false;
        this.slowLoadingDuration = 3000;
        this.optionsHidden = false;
    }
    BaseResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.optionsHidden = this.resource && this.resource.isStub();
        var existingToken = this.project.tokens.find(function (item) { return !item.activated; });
        var token = existingToken ? existingToken.clone() : new ProjectToken();
        token.resourceType = this.typeItem.resourceType;
        token.resourceTypeItem = this.typeItem.name;
        this.loading = true;
        this.cd.markForCheck();
        this.form
            .init(this.project, this.environment, this.resource, this.typeItem, this.resourceNameEditing, this.optionsHidden, this.params)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
            _this.onFormInit();
        });
    };
    BaseResourceSettingsComponent.prototype.ngOnDestroy = function () { };
    BaseResourceSettingsComponent.prototype.onFormInit = function () { };
    Object.defineProperty(BaseResourceSettingsComponent.prototype, "defaultPlaceholder", {
        get: function () {
            return this.optionsHidden ? '••••••••••••••••' : '';
        },
        enumerable: true,
        configurable: true
    });
    BaseResourceSettingsComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    BaseResourceSettingsComponent.prototype.submit = function () {
        var _this = this;
        if (this.slowLoadingSubscription) {
            this.slowLoadingSubscription.unsubscribe();
        }
        this.loadingSubmit = true;
        this.cd.markForCheck();
        this.slowLoadingSubscription = timer(this.slowLoadingDuration)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.slowLoading = true;
            _this.cd.markForCheck();
        });
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.close();
            _this.saved.emit(result);
            if (_this.slowLoadingSubscription) {
                _this.slowLoadingSubscription.unsubscribe();
            }
        }, function (error) {
            console.error(error);
            _this.loadingSubmit = false;
            _this.slowLoading = false;
            _this.cd.markForCheck();
            if (_this.slowLoadingSubscription) {
                _this.slowLoadingSubscription.unsubscribe();
            }
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
            if (_this.resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceUpdateFailed, {
                    Source: _this.analyticsSource,
                    ResourceID: _this.typeItem.name,
                    Error: errorMessage
                });
            }
            else {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailed, {
                    Source: _this.analyticsSource,
                    ResourceID: _this.typeItem.name,
                    Error: errorMessage
                });
            }
        });
    };
    BaseResourceSettingsComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    BaseResourceSettingsComponent.prototype.cancel = function () {
        this.canceled.emit();
        this.close();
    };
    BaseResourceSettingsComponent.prototype.requestDelete = function () {
        this.deleteRequested.emit();
        this.close();
    };
    return BaseResourceSettingsComponent;
}());
export { BaseResourceSettingsComponent };

import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopoverComponent, PopoverService } from '@common/popover';
import { FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { fromLegacyModel, ModelDbField, ModelDescription, ModelFieldType, ModelFlexField } from '@modules/models';

@Component({
  selector: 'app-list-related-field',
  templateUrl: './list-related-field.component.html'
})
export class ListRelatedFieldComponent implements OnInit, OnDestroy {
  @Input() path: ModelDbField[] = [];
  @Output() selected = new EventEmitter<ModelFlexField>();

  modelDescription: ModelDescription;
  relatedFields: ModelDbField[] = [];

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private popoverService: PopoverService,
    public popoverComponent: PopoverComponent,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.setRelatedField(last(this.path));
  }

  ngOnDestroy(): void {}

  trackByFn(i, item) {
    return item.name;
  }

  setRelatedField(field: ModelDbField) {
    const model = field.params['related_model'] ? fromLegacyModel(field.params['related_model']['model']) : undefined;

    this.relatedFields = [];
    this.modelDescriptionStore
      .getDetail(model)
      .pipe(untilDestroyed(this))
      .subscribe(relModelDescription => {
        this.modelDescription = relModelDescription;
        this.relatedFields = this.modelDescription.dbFields.filter(
          item => item.field == FieldType.RelatedModel && item.params['related_model']
        );
      });
  }

  addFlexField(field) {
    const flex = new ModelFlexField();
    const fullPath = this.path.concat([field]);

    field.type = ModelFieldType.Flex;
    flex.name = `flex_${fullPath.map(item => item.name).join('_')}`;
    flex.verboseName = fullPath.map(item => item.verboseName).join(' ');
    flex.field = field.field;
    flex.query = fullPath.map(item => item.name).join('.');
    this.selected.emit(flex);
    this.popoverComponent.close();
  }

  showRelation(field: ModelDbField, sourceElement: Element) {
    this.popoverService.create(
      {
        component: ListRelatedFieldComponent,
        inputs: {
          path: this.path.concat([field])
        },
        outputs: {
          selected: [
            (item: ModelFlexField) => {
              this.selected.emit(item);
              this.popoverComponent.close();
            }
          ]
        },
        injector: this.injector
      },
      sourceElement
    );
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { PageTemplatesItemFieldArray } from '../page-templates-item-settings/page-templates-item-field.array';
import { PageTemplatesItemFieldControl } from '../page-templates-item-settings/page-templates-item-field.control';

@Component({
  selector: 'app-page-templates-item-settings-fields',
  templateUrl: './page-templates-item-settings-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesItemSettingsFieldsComponent implements OnInit {
  @Input() control: PageTemplatesItemFieldArray;

  constructor() {}

  ngOnInit() {}

  dragDropOption(event: CdkDragDrop<PageTemplatesItemFieldControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}

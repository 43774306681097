<app-view-context-token-popover-overlay
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [origin]="popoverOrigin || textarea_origin"
  [control]="control"
  [openOnFocus]="input_element"
  [extraSections]="extraSections"
  [search]="search$ | async"
  [selectFunction]="(hoverFunction$ | async) || (activeFunction$ | async)"
  (selected)="onTokenSelected($event)"
  (opened)="popoverOpened$.next(true); popoverOpened.emit()"
  (closed)="popoverOpened$.next(false); checkIfContextValue()"
></app-view-context-token-popover-overlay>

<div
  class="input input_textarea input_formula input_fill"
  [class.input_fill]="fill"
  [class.input_fill-vertical]="fillVertical"
  [class.input_inline]="inline"
  [class.input_small]="small"
  [class.input_segment-top]="formulaEffectiveError | appIsSet"
  contenteditable="true"
  role="textbox"
  spellcheck="false"
  [attr.autocorrect]="'off'"
  autocapitalize="off"
  [attr.placeholder]="placeholder"
  (keydown)="onKeydown($event)"
  (focus)="focus$.next(true); onFocus()"
  (blur)="focus$.next(false)"
  cdkOverlayOrigin
  #textarea_origin="cdkOverlayOrigin"
  #input_element
></div>

<div
  *ngIf="formulaEffectiveError | appIsSet"
  class="input-error"
  [class.input-error_overlay]="!inline"
  [class.input-error_rounded-bottom]="!inline"
>
  <div class="input-error__inner" [title]="formulaEffectiveError">{{ formulaEffectiveError }}</div>
</div>

import { ActivationStart, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MenuService } from '@modules/menu';
import { MetaService } from '@modules/meta';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../meta/services/meta.service";
import * as i3 from "../../../menu/services/menu/menu.service";
var NavigationService = /** @class */ (function () {
    function NavigationService(router, metaService, menuService) {
        var _this = this;
        this.router = router;
        this.metaService = metaService;
        this.menuService = menuService;
        this._items = new BehaviorSubject([]);
        this._routeSnapshot = new BehaviorSubject(null);
        this.router.events
            .pipe(filter(function (item) { return item instanceof NavigationStart; }))
            .subscribe(function (e) { return _this.pushItem(e.url); });
        this.metaService.titlePrimary$.subscribe(function (title) {
            if (!_this.currentItem) {
                return;
            }
            _this.currentItem.title = title;
            _this.items = _this.items;
        });
        this.menuService.section$.subscribe(function (section) {
            if (!_this.currentItem) {
                return;
            }
            _this.currentItem.section = section;
            _this.items = _this.items;
        });
        this.router.events
            .pipe(filter(function (item) { return item instanceof ActivationStart; }))
            .subscribe(function (event) { return _this._routeSnapshot.next(event.snapshot); });
    }
    Object.defineProperty(NavigationService.prototype, "items", {
        get: function () {
            return this._items.value;
        },
        set: function (value) {
            this._items.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationService.prototype, "items$", {
        get: function () {
            return this._items.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationService.prototype, "routeSnapshot", {
        get: function () {
            return this._routeSnapshot.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationService.prototype, "routeSnapshot$", {
        get: function () {
            return this._routeSnapshot.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationService.prototype, "currentItem", {
        get: function () {
            if (!this.items.length) {
                return;
            }
            return this.items[this.items.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    NavigationService.prototype.pushItem = function (url) {
        var urlTree = this.router.parseUrl(url);
        var group = urlTree.root.children['primary'] || urlTree.root;
        var link = group.segments.map(function (it) { return it.path; });
        link[0] = "/" + link[0];
        this.items = this.items.concat([
            {
                link: link,
                queryParams: urlTree.queryParams,
                section: this.menuService.section
            }
        ]);
    };
    NavigationService.prototype.previousNavigationItem$ = function (menuSection) {
        return this.items$.pipe(map(function (items) {
            return items
                .slice(0, -1)
                .reverse()
                .find(function (item) { return item.title && (menuSection == undefined || item.section == menuSection); });
        }));
    };
    NavigationService.ngInjectableDef = i0.defineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.inject(i1.Router), i0.inject(i2.MetaService), i0.inject(i3.MenuService)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };

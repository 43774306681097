var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { SendGridGeneratorService } from '@modules/resource-generators';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var SendGridResourceSettingsForm = /** @class */ (function (_super) {
    __extends(SendGridResourceSettingsForm, _super);
    function SendGridResourceSettingsForm(sendGridGeneratorService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.sendGridGeneratorService = sendGridGeneratorService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({
            key: new FormControl('', Validators.required)
        });
        return _this;
    }
    SendGridResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.sendGridGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                key: result.key
            });
        }));
    };
    SendGridResourceSettingsForm.prototype.getOptions = function () {
        return {
            key: this.form.value['key']
        };
    };
    SendGridResourceSettingsForm.prototype.isOptionsValid = function () {
        return this.sendGridGeneratorService.isOptionsValid(this.getOptions());
    };
    SendGridResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        return this.sendGridGeneratorService
            .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
            .pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    return SendGridResourceSettingsForm;
}(BaseResourceSettingsForm));
export { SendGridResourceSettingsForm };

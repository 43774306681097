import { FieldOutput } from '@modules/fields';
import { isSet } from '@shared';

export class AutomationTriggerWebhook {
  public id: string;
  public token: string;
  public structureInitialized = false;
  public params: Object = {};
  public dataStructure: FieldOutput[];
  public dataStructureArray: boolean;
  public testData: Object;

  constructor(options: Partial<AutomationTriggerWebhook> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object) {
    this.id = data['id'];
    this.token = data['token'];
    this.structureInitialized = data['structure_initialized'];
    this.params = data['params'];

    if (this.params['data_structure']) {
      this.dataStructure = this.params['data_structure'];
    }

    if (isSet(this.params['data_structure_array'])) {
      this.dataStructureArray = this.params['data_structure_array'];
    }

    if (isSet(this.params['test_data'])) {
      this.testData = this.params['test_data'];
    }

    return this;
  }

  getRunPath() {
    return isSet(this.token) ? `hooks/${this.token}` : undefined;
  }
}

import { Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { FieldElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { FieldsEditConfigurable } from '@modules/field-components';
import { BaseField } from '@modules/fields';

export abstract class FieldParamsComponent {
  @Input() field: BaseField;
  @Input() field$: Observable<BaseField>;
  @Input() configurable: FieldsEditConfigurable = {};
  @Input() control: AbstractControl;
  @Input() element: FieldElementItem;
  @Input() resourceField = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { NotificationService } from '@common/notifications';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';
import { ChooseTemplateComponent, CLOSE_BY_BUTTON_CLICK } from '../../components/choose-template/choose-template.component';
import { TemplateApplyController } from '../template-apply-controller/template-apply.controller';
var ChooseTemplateService = /** @class */ (function () {
    function ChooseTemplateService(notificationService, popupService, routing, templateApplyController, analyticsService) {
        this.notificationService = notificationService;
        this.popupService = popupService;
        this.routing = routing;
        this.templateApplyController = templateApplyController;
        this.analyticsService = analyticsService;
    }
    ChooseTemplateService.prototype.chooseTemplate = function (injector, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs = new ReplaySubject();
        this.popupService.push({
            component: ChooseTemplateComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentDisableClose: !options.cancel,
            popupComponentOrange: true,
            popupComponentCloseWithoutConfirm: true,
            popupClosed: function (data) {
                if (data == CLOSE_BY_BUTTON_CLICK || data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateDismissed);
                }
            },
            inputs: __assign({}, (options.title && { title: options.title }), (isSet(options.chooseSection) && { chooseSection: options.chooseSection }), { initialSection: options.initialSection }, (options.header !== undefined && { header: options.header }), { wide: options.wide, orange: true, currentPage: options.currentPage, resource: options.resource, cancel: options.cancel, newPage: options.newPage, type: options.type, analyticsSource: options.analyticsInnerSource }),
            outputs: {
                blankAppCreated: [
                    function () {
                        obs.next({
                            blankApp: true
                        });
                    }
                ],
                templateApplied: [
                    function (template) {
                        obs.next({
                            template: template
                        });
                    }
                ]
            },
            injector: injector
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateOpened, {
            Source: options.analyticsSource
        });
        return obs.pipe(tap(function (result) {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateCompleted, {
                BlankApp: !!result.blankApp,
                TemplateID: result.template ? result.template.name : undefined,
                ResourceID: result.resource ? result.resource.typeItem.name : undefined
            });
        }));
    };
    return ChooseTemplateService;
}());
export { ChooseTemplateService };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ViewSettings } from '@modules/customize';
import { MenuContext } from '@modules/menu-components';

import { CustomizeBarPagesEditSimpleControl } from '../customize-bar-pages-edit/simple-control';
import { SystemOption } from '../edit-menu-item-action/edit-menu-item-action.component';

@Component({
  selector: 'app-simple-menu-item-popover',
  templateUrl: './simple-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleMenuItemPopoverComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditSimpleControl;
  @Output() close = new EventEmitter<void>();

  analyticsSource = 'simple_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  onPageSelected(page: ViewSettings, finished: boolean) {
    this.control.controls.title.patchValue(page.name);

    if (finished) {
      this.close.emit();
    }
  }

  onSystemSelected(option: SystemOption) {
    this.control.controls.title.patchValue(option.label);
    this.control.controls.icon.patchValue(option.icon);
    this.close.emit();
  }
}

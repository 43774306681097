import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService, ServerRequestError } from '@modules/api';
import { getExtension, isSet } from '@shared';

import { Environment } from '../../data/environment';

export enum DraftItemsType {
  ViewSettings = 'view_settings',
  ModelDescriptions = 'model_descriptions',
  ActionDescriptions = 'action_descriptions',
  MenuSettings = 'menu_settings',
  Resources = 'resources',
  SecretTokens = 'secret_tokens',
  Storages = 'storages',
  CustomViews = 'custom_views',
  TaskQueues = 'task_queues',
  ProjectProperties = 'project_properties',
  ProjectSettings = 'project_settings'
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}): Observable<Environment[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'environments/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Environment().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, uid: string, params = {}): Observable<Environment> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `environments/${uid}/`);
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new Environment().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environment: Environment): Observable<Environment> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'environments/');
        let headers = new HttpHeaders();
        const data = environment.serialize();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Environment().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, uniqueName: string, instance: Environment, fields?: string[]): Observable<Environment> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `environments/${uniqueName}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, instance.serialize(fields), { headers: headers });
      }),
      map(result => new Environment().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName: string, instance: Environment): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `environments/${instance.uniqueName}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  dump(projectName: string, instance: Environment, secretKey?: string): Observable<HttpResponse<Blob>> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, instance.uniqueName, 'dump');
        let headers = new HttpHeaders();
        const data = {
          ...(isSet(secretKey) && { secret_key: secretKey })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers, observe: 'response', responseType: 'blob' });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  restore(projectName: string, instance: Environment, dump: File, secretKey?: string): Observable<boolean> {
    let extension = getExtension(dump.name);

    if (!extension) {
      return throwError(new ServerRequestError(`Backup has no extension`));
    }

    extension = extension.toLowerCase();

    if (extension == 'zip') {
      return this.apiService.refreshToken().pipe(
        switchMap(() => {
          const url = this.apiService.nodeEnvironmentMethodURL(projectName, instance.uniqueName, 'restore');
          let headers = new HttpHeaders();
          const formData = new FormData();

          formData.append('file', dump);

          if (isSet(secretKey)) {
            formData.append('secret_key', secretKey);
          }

          headers = this.apiService.setHeadersToken(headers);

          return this.http.post(url, formData, { headers: headers });
        }),
        map(() => true),
        this.apiService.catchApiError(),
        publishLast(),
        refCount()
      );
    } else if (extension == 'json') {
      return this.apiService.refreshToken().pipe(
        switchMap(() => {
          const url = this.apiService.projectMethodURL(projectName, `environments/${instance.uniqueName}/restore/`);
          let headers = new HttpHeaders();
          const formData = new FormData();

          formData.append('dump', dump);

          headers = this.apiService.setHeadersToken(headers);

          return this.http.post(url, formData, { headers: headers });
        }),
        map(result => result['result'] || false),
        this.apiService.catchApiError(),
        publishLast(),
        refCount()
      );
    } else {
      return throwError(new ServerRequestError(`Unsupported backup extension: ${extension}`));
    }
  }

  merge(projectName: string, from: Environment, to: Environment): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeProjectMethodURL(projectName, 'environments/merge');
        let headers = new HttpHeaders();
        const data = {
          environment_from: from.uniqueName,
          environment_to: to.uniqueName
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  publishDraft(
    projectName: string,
    environmentName: string,
    options: EnvironmentService.EntitiesOptions = {}
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `environments/${environmentName}/publish_draft/`);
        let headers = new HttpHeaders();
        const data = this.serializeEntities(options);

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteDraft(
    projectName: string,
    environmentName: string,
    options: EnvironmentService.EntitiesOptions = {}
  ): Observable<Object> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `environments/${environmentName}/delete_draft/`);
        let headers = new HttpHeaders();
        const data = this.serializeEntities(options);

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  serializeEntities(options: EnvironmentService.EntitiesOptions = {}): Record<string, unknown> {
    const data = {};

    if (options.itemsType) {
      data['items_type'] = options.itemsType;
    }

    if (options.viewSettings) {
      data['view_settings'] = options.viewSettings;
    }

    if (options.modelDescriptions) {
      data['model_descriptions'] = options.modelDescriptions;
    }

    if (options.actionDescriptions) {
      data['action_descriptions'] = options.actionDescriptions;
    }

    if (options.resources) {
      data['resources'] = options.resources;
    }

    if (options.secretTokens) {
      data['secret_tokens'] = options.secretTokens;
    }

    if (options.storages) {
      data['storages'] = options.storages;
    }

    if (options.customViews) {
      data['custom_views'] = options.customViews;
    }

    if (options.taskQueues) {
      data['task_queues'] = options.taskQueues;
    }

    if (options.projectProperties) {
      data['project_properties'] = options.projectProperties;
    }

    if (options.projectSettings) {
      data['project_settings'] = options.projectSettings;
    }

    return data;
  }
}

export namespace EnvironmentService {
  export interface EntitiesOptions {
    itemsType?: DraftItemsType;
    viewSettings?: string[];
    modelDescriptions?: string[];
    actionDescriptions?: string[];
    resources?: string[];
    secretTokens?: string[];
    storages?: string[];
    customViews?: string[];
    taskQueues?: string[];
    projectProperties?: string[];
    projectSettings?: string[];
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "./list-layouts.component";
import * as i4 from "../../../layouts/services/layout/layout.service";
import * as i5 from "../../../customize/services/customize/customize.service";
var styles_ListLayoutsComponent = [];
var RenderType_ListLayoutsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListLayoutsComponent, data: {} });
export { RenderType_ListLayoutsComponent as RenderType_ListLayoutsComponent };
function View_ListLayoutsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "button", [["class", "button button_small button_icon"]], [[2, "button_orange", null], [2, "button_primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentLayoutIndex(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "icon button__icon"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(7, 1)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; var currVal_3 = _ck(_v, 2, 0, "bottom"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "icon button__icon"; var currVal_5 = _ck(_v, 7, 0, ("icon-" + _v.context.$implicit.icon)); _ck(_v, 6, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 3).transform(_co.customizeService.enabled$)); var currVal_1 = (i0.ɵunv(_v, 0, 1, i0.ɵnov(_v, 4).transform(_co.layoutService.currentLayoutIndex$)) == _v.context.index); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ListLayoutsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "button button_orange button_small button_icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addLayout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon-plus button__icon"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "Add layout"; var currVal_1 = _ck(_v, 2, 0, "bottom"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ListLayoutsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "list__layouts"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListLayoutsComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListLayoutsComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.layoutService.layouts$)); var currVal_1 = _co.trackLayoutByFn; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.customizeService.enabled$)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ListLayoutsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListLayoutsComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.layoutService.layouts$))) == null) ? null : tmp_0_0.length) > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ListLayoutsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-layouts", [], null, null, null, View_ListLayoutsComponent_0, RenderType_ListLayoutsComponent)), i0.ɵdid(1, 245760, null, 0, i3.ListLayoutsComponent, [i4.LayoutService, i5.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListLayoutsComponentNgFactory = i0.ɵccf("app-list-layouts", i3.ListLayoutsComponent, View_ListLayoutsComponent_Host_0, {}, { layoutAdd: "layoutAdd", layoutCustomize: "layoutCustomize" }, []);
export { ListLayoutsComponentNgFactory as ListLayoutsComponentNgFactory };

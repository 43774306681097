<app-video-player *ngIf="previewFile | appIsSet" [src]="previewFile"></app-video-player>

<iframe
  *ngIf="previewEmbed | appIsSet"
  [src]="previewEmbed"
  allowfullscreen
  allowtransparency
  allow="autoplay"
  style="border: 0;"
></iframe>

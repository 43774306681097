import isEqual from 'lodash/isEqual';

import { isSet } from '@shared';

import { FormulaSection, FormulaSectionItem } from '../data/formula-section';
import { FormulaToken } from '../data/formula-token';
import { FormulaTokenPosition } from '../data/formula-token-position';

export function getSectionTokens(sections: FormulaSection[]): FormulaToken[] {
  return sections.reduce((acc, section) => {
    section.items.forEach(item => {
      if (item.item) {
        acc.push(item.item);
      } else if (item.section) {
        acc.push(...getSectionTokens([item.section]));
      }
    });

    return acc;
  }, []);
}

export function findSectionToken(
  sections: FormulaSection[],
  options: {
    nested: boolean;
    predicate?: (token: FormulaSectionItem) => boolean;
  }
): FormulaSectionItem {
  for (const section of sections) {
    for (const item of section.items) {
      if (!options.predicate || options.predicate(item)) {
        return item;
      } else if (item.section && options.nested) {
        const matchItem = findSectionToken([item.section], options);
        if (matchItem) {
          return matchItem;
        }
      }
    }
  }
}

export function getFormulaTokens(formula: string): FormulaTokenPosition[] {
  const regex = /(?:(?:\[[^\]]+\])|[\w.])+/g;
  const positions = [];
  let m;

  while ((m = regex.exec(formula))) {
    if (m[0]) {
      positions.push({
        value: m[0],
        index: m.index,
        length: m[0].length
      });
    }
  }

  return positions;
}

export function detectFormulaToken(formula: string, index: number): FormulaTokenPosition {
  const positions = getFormulaTokens(formula);
  return positions.find(item => {
    let searchIndex = index;
    const searchChar = formula.substring(searchIndex, searchIndex + 1);

    if (searchChar == '(') {
      --searchIndex;
    }

    return searchIndex >= item.index && searchIndex < item.index + item.length;
  });
}

export function searchFormulaSections(sections: FormulaSection[], search = ''): FormulaSection[] {
  if (!isSet(search)) {
    return sections;
  }

  const processQuery = q => {
    return String(q).toLowerCase().replace(/[_-]/g, ' ').replace(/\s\s+/g, ' ').trim();
  };
  const query = processQuery(search);
  const matchQuery = (str: string, q: string): { marked: string } => {
    if (!isSet(str)) {
      return;
    }

    const index = processQuery(str).indexOf(q);

    if (index == -1) {
      return;
    }

    return {
      marked: [
        str.substring(0, index),
        '<em>',
        str.substring(index, index + q.length),
        '</em>',
        str.substring(index + q.length)
      ].join('')
    };
  };

  const searchSection = (rootItems: FormulaSectionItem[]): FormulaSectionItem[] => {
    const searchItems = (items: FormulaSectionItem[], path: string[]): FormulaSectionItem[] => {
      return items.reduce<FormulaSectionItem[]>((acc, item) => {
        if (item.section) {
          const itemPath = [...path, item.section.label];
          const match = matchQuery(item.section.label, query);

          if (match) {
            acc.push({
              path: item.path,
              section: {
                ...item.section,
                name: match.marked
              },
              subtitle: item.subtitle
            });
          }

          acc.push(...searchItems(item.section.items, itemPath));
        } else if (item.item) {
          const labelSecondary = path.join(' · ');
          const matchLabel = matchQuery(item.item.label, query);
          const matchLabelSecondary = matchQuery(labelSecondary, query);
          const matchInsert = matchQuery(item.item.insert ? item.item.insert.join('.') : undefined, query);

          if (matchLabel) {
            acc.push({
              path: item.path,
              item: {
                ...item.item,
                label: matchLabel.marked,
                labelPlain: item.item.label,
                labelSecondary: labelSecondary
              },
              subtitle: item.subtitle
            });
          } else if (matchLabelSecondary) {
            acc.push({
              path: item.path,
              item: {
                ...item.item,
                labelSecondary: matchLabelSecondary.marked
              },
              subtitle: item.subtitle
            });
          } else if (matchInsert) {
            acc.push({
              path: item.path,
              item: {
                ...item.item,
                labelSecondary: labelSecondary
              },
              subtitle: item.subtitle
            });
          }
        }

        return acc;
      }, []);
    };

    return searchItems(rootItems, []);
  };

  return sections
    .map(section => {
      return {
        label: section.label,
        icon: section.icon,
        items: searchSection(section.items).filter(item => item.item || item.section.items.length)
      };
    })
    .filter(section => section.items.length);
}

export function formulaTokensEqual(lhs: FormulaSectionItem, rhs: FormulaSectionItem): boolean {
  return isEqual(lhs.path, rhs.path);
}

export function findFormulaTokenSibling(
  sections: FormulaSection[],
  token: FormulaSectionItem,
  next: boolean
): FormulaSectionItem | null {
  let prevToken: FormulaSectionItem = null;

  for (const section of sections) {
    for (const item of section.items) {
      if (next && prevToken && formulaTokensEqual(prevToken, token)) {
        return item;
      } else if (!next && formulaTokensEqual(item, token)) {
        return prevToken;
      }

      prevToken = item;
    }
  }
}

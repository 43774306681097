export class LogHttpRequest {
  public method: string;
  public url: string;
  public params: { name: string; value: string }[] = [];
  public headers: { name: string; value: string }[] = [];
  public body: any;
  public responseCode: number;
  public responseBody: any;

  deserialize(data: Object): LogHttpRequest {
    this.method = data['method'];
    this.url = data['url'];
    this.responseCode = data['response_code'];

    if (data['params']) {
      this.headers = data['headers'];
    }

    if (data['headers']) {
      this.headers = data['headers'];
    }

    if (data['body']) {
      this.body = data['body'];
    }

    if (data['response_body']) {
      this.responseBody = data['response_body'];
    }

    return this;
  }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AppConfigService } from '@core';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, Project, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html'
})
export class MenuProfileComponent implements OnInit, OnDestroy {
  @Input() theme: string;
  @Input() position: string;
  @Input() themeChange = true;
  @Input() analyticsSource: string;

  currentProject: Project;
  analyticsEvents = AnalyticsEvent;

  constructor(
    public projectsStore: ProjectsStore,
    @Optional() public currentEnvironmentStore: CurrentEnvironmentStore,
    public currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef,
    private intercomService: IntercomService,
    private routing: RoutingService,
    @Optional() public themeService: ThemeService,
    public customizeService: CustomizeService,
    public appConfigService: AppConfigService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.projectsStore.lastUsed$.pipe(untilDestroyed(this)).subscribe(currentProject => {
      this.currentProject = currentProject;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  openChat() {
    this.intercomService.openChat();
  }

  logout() {
    this.routing.navigate(['/logout']);
  }

  toggleTheme() {
    if (!this.themeService) {
      return;
    }

    this.themeService.toggleTheme();
  }

  toggleStaffEnabled() {
    if (!this.themeService) {
      return;
    }

    this.themeService.toggleStaffEnabled();
  }

  onItemClicked(name: string, params = {}) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.UserMenu.ItemClicked, {
      Name: name,
      ...params,
      Source: this.analyticsSource
    });
  }
}

import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, filter } from 'rxjs/operators';

import { IconType } from '@modules/icons';
import { MenuBlock, MenuGeneratorService, MenuItem } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { TypedChanges } from '@shared';

export const dropdownPositionsHorizontal: ConnectedPosition[] = [
  {
    panelClass: ['overlay_position_right-top'],
    originX: 'end',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'top',
    offsetY: -8
  },
  {
    panelClass: ['overlay_position_right-bottom'],
    originX: 'end',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'bottom',
    offsetY: 8
  },
  {
    panelClass: ['overlay_position_right-center'],
    originX: 'end',
    overlayX: 'start',
    originY: 'center',
    overlayY: 'center'
  },
  {
    panelClass: ['overlay_position_left-top'],
    originX: 'start',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'top',
    offsetY: -8
  },
  {
    panelClass: ['overlay_position_left-bottom'],
    originX: 'start',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'bottom',
    offsetY: 8
  },
  {
    panelClass: ['overlay_position_left-center'],
    originX: 'start',
    overlayX: 'end',
    originY: 'center',
    overlayY: 'center'
  },
  {
    panelClass: ['overlay_position_bottom-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_bottom-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: 8
  },
  {
    panelClass: ['overlay_position_top-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_top-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: 8
  }
];

export const dropdownPositionsVertical: ConnectedPosition[] = [
  {
    panelClass: ['overlay_position_bottom-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_bottom-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'bottom',
    overlayY: 'top',
    offsetX: 8
  },
  {
    panelClass: ['overlay_position_top-left'],
    originX: 'start',
    overlayX: 'start',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: -8
  },
  {
    panelClass: ['overlay_position_top-right'],
    originX: 'end',
    overlayX: 'end',
    originY: 'top',
    overlayY: 'bottom',
    offsetX: 8
  },
  {
    panelClass: ['overlay_position_left-center'],
    originX: 'start',
    overlayX: 'end',
    originY: 'center',
    overlayY: 'center'
  },
  {
    panelClass: ['overlay_position_right-center'],
    originX: 'end',
    overlayX: 'start',
    originY: 'center',
    overlayY: 'center'
  }
];

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() link: any[];
  @Input() queryParams?: Object;
  @Input() href: string;
  @Input() exactActive = false;
  @Input() handler: () => Observable<any>;
  @Input() menuBlock: MenuBlock;
  @Input() children: MenuItem[] = [];
  @Input() childrenVertical = false;
  @Input() primary = false;
  @Input() dropdown = false;
  @Input() forceActive = false;
  @Input() forceOpened = true;
  @Output() dropdownOpen = new EventEmitter<void>();
  @Output() dropdownClose = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  displayChildren: MenuItem[] = [];
  menuItemHovered$ = new BehaviorSubject<CdkOverlayOrigin>(undefined);
  menuDropdownHovered$ = new BehaviorSubject<boolean>(false);
  menuChildDropdownOpened$ = new BehaviorSubject<boolean>(false);
  opened: boolean;
  alwaysOpened: boolean;
  dropdownOpened = false;
  origin: CdkOverlayOrigin;
  popoverPositions: ConnectedPosition[] = [];
  popoverPositionsSubscription: Subscription;
  menuDropdownSubscription: Subscription[] = [];
  iconTypes = IconType;

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private menuGeneratorService: MenuGeneratorService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
    this.cd.markForCheck();

    if (this.forceOpened) {
      this.opened = true;
      this.cd.markForCheck();
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<MenuItemComponent>): void {
    if (changes.children) {
      this.updateChildren();
      this.initMenuDropdown();
    }

    if (this.opened == undefined) {
      this.opened = this.alwaysOpened = localStorage[this.alwaysOpenedUniqueKey] == '1';
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  updateChildren() {
    this.displayChildren = this.menuGeneratorService.cleanMenuItemsAppMode(this.children);
    this.cd.markForCheck();
  }

  toggleOpened() {
    this.opened = this.alwaysOpened = !this.opened;
    this.cd.markForCheck();

    localStorage[this.alwaysOpenedUniqueKey] = this.alwaysOpened ? '1' : '0';
  }

  get alwaysOpenedUniqueKey() {
    return JSON.stringify({
      project: this.currentProjectStore.instance.uniqueName,
      link: this.link,
      externalLink: this.href
    });
  }

  onActivate() {
    this.opened = true;
    this.cd.markForCheck();
  }

  onDeactivate() {
    this.opened = this.alwaysOpened;
    this.cd.markForCheck();
  }

  initMenuDropdown() {
    this.menuDropdownSubscription.forEach(item => item.unsubscribe());
    this.menuDropdownSubscription = [];

    if (!this.children.length) {
      return;
    }

    this.menuDropdownSubscription.push(
      combineLatest(
        this.menuItemHovered$.pipe(debounce(origin => timer(origin ? 0 : 100))),
        this.menuDropdownHovered$,
        this.menuChildDropdownOpened$
      )
        .pipe(debounceTime(10), untilDestroyed(this))
        .subscribe(([itemOrigin, dropdownHovered, childDropdownOpened]) => {
          if (itemOrigin && !this.dropdownOpened) {
            this.openDropdown(itemOrigin);
          } else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && this.dropdownOpened) {
            this.closeDropdown();
          }
        })
    );

    this.menuDropdownSubscription.push(
      merge(this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
        .pipe(untilDestroyed(this))
        .subscribe(() => this.closeDropdown())
    );
  }

  openDropdown(origin: CdkOverlayOrigin) {
    this.origin = origin;
    this.dropdownOpened = true;
    this.cd.markForCheck();
    this.dropdownOpen.emit();
  }

  closeDropdown() {
    this.dropdownOpened = false;
    this.cd.markForCheck();
    this.dropdownClose.emit();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  onClick() {
    if (this.handler) {
      this.handler().pipe(untilDestroyed(this)).subscribe();
    }
  }
}

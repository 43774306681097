import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';

import { ActionStore } from '@modules/action-queries';
import { sortActionDescriptions } from '@modules/actions';
import { ViewSettingsService } from '@modules/customize';
import { MenuSettingsService, ModelLinkMenuItem, SectionMenuItem } from '@modules/menu';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { sortModelDescriptions, sortModelFields } from '@modules/models';
import { Environment, Project } from '@modules/projects';
import { HttpQueryAuthType } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import { isSet, mapValuesDeep, objectsSortPredicate } from '@shared';

@Injectable()
export class DumpResourceService {
  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelDescriptionService: ModelDescriptionService,
    private actionStore: ActionStore,
    private viewSettingsService: ViewSettingsService,
    private menuSettingsService: MenuSettingsService
  ) {}

  saveAsFile(content: string, fileName: string) {
    const blob = new Blob([content], { type: 'text/plain; charset=utf-8' });
    saveAs(blob, fileName);
  }

  dumpObject(obj: Object) {
    return JSON.stringify(obj, null, '  ');
  }

  dumpConst(name: string, type: string, content: string) {
    return `export const ${name}: ${type} = ${content};\n`;
  }

  dump(project: Project, environment: Environment, resourceName: string) {
    const resource = project
      .getEnvironmentResources(environment.uniqueName)
      .find(item => item.uniqueName == resourceName);
    const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
    const baseUrl =
      resourceParams.baseHttpQuery && isSet(resourceParams.baseHttpQuery.url) ? resourceParams.baseHttpQuery.url : '';
    const templateResourceName = resource.name;
    const templateResourceNameCamel = camelCase(templateResourceName);
    const templateResourceNameDash = templateResourceName.replace(/_/g, '-').toLowerCase();

    // console.log('params dump');
    // console.log(paramsDump);
    const paramsSerialized = resourceParams.serialize();

    delete paramsSerialized['sync'];
    delete paramsSerialized['sync_collection'];
    delete paramsSerialized['url'];
    delete paramsSerialized['bridge_settings'];
    delete paramsSerialized['deploy'];
    delete paramsSerialized['region'];
    delete paramsSerialized['schema'];
    delete paramsSerialized['custom_proxy'];
    delete paramsSerialized['featured'];

    if (
      paramsSerialized['base_http_query'] &&
      paramsSerialized['base_http_query']['auth_type'] == HttpQueryAuthType.OAuth2
    ) {
      paramsSerialized['base_http_query']['auth_params'] = {};
    }

    const paramsDump = this.dumpConst(
      `${templateResourceNameCamel}ResourceParamsParams`,
      'Object',
      this.dumpObject(paramsSerialized)
    );
    this.saveAsFile(paramsDump, `${templateResourceNameDash}-resource-params-params.stub.ts`);

    this.modelDescriptionStore.getFirst().subscribe(storeItems => {
      this.modelDescriptionService.getOverride(project, environment, true).subscribe(result => {
        const models = sortModelDescriptions(
          result
            .filter(item => !item.deleted)
            .filter(item => item.resource == resourceName)
            .filter(item => !item.hidden)
            .filter(item => storeItems.find(storeItem => storeItem.modelId == item.modelId) != undefined)
        );

        let items = cloneDeep(models)
          .map((item, i) => {
            item.project = '{{project}}';
            item.resource = '{{resource}}';

            if (item.getQuery && item.getQuery.httpQuery) {
              item.getQuery.httpQuery.requestResponse = undefined;
              item.getQuery.httpQuery.requestTokens = {};

              if (isSet(item.getQuery.httpQuery.urlPath)) {
                item.getQuery.httpQuery.url = baseUrl + item.getQuery.httpQuery.urlPath;
                item.getQuery.httpQuery.urlPath = undefined;
              }
            } else {
              item.getQuery = undefined;
            }

            if (item.getDetailQuery && item.getDetailQuery.httpQuery) {
              item.getDetailQuery.httpQuery.requestResponse = undefined;
              item.getDetailQuery.httpQuery.requestTokens = {};

              if (isSet(item.getDetailQuery.httpQuery.urlPath)) {
                item.getDetailQuery.httpQuery.url = baseUrl + item.getDetailQuery.httpQuery.urlPath;
                item.getDetailQuery.httpQuery.urlPath = undefined;
              }
            } else {
              item.getDetailQuery = undefined;
            }

            if (item.createQuery && item.createQuery.httpQuery) {
              item.createQuery.httpQuery.requestResponse = undefined;
              item.createQuery.httpQuery.requestTokens = {};

              if (isSet(item.createQuery.httpQuery.urlPath)) {
                item.createQuery.httpQuery.url = baseUrl + item.createQuery.httpQuery.urlPath;
                item.createQuery.httpQuery.urlPath = undefined;
              }
            } else {
              item.createQuery = undefined;
            }

            if (item.updateQuery && item.updateQuery.httpQuery) {
              item.updateQuery.httpQuery.requestResponse = undefined;
              item.updateQuery.httpQuery.requestTokens = {};

              if (isSet(item.updateQuery.httpQuery.urlPath)) {
                item.updateQuery.httpQuery.url = baseUrl + item.updateQuery.httpQuery.urlPath;
                item.updateQuery.httpQuery.urlPath = undefined;
              }
            } else {
              item.updateQuery = undefined;
            }

            if (item.deleteQuery && item.deleteQuery.httpQuery) {
              item.deleteQuery.httpQuery.requestResponse = undefined;
              item.deleteQuery.httpQuery.requestTokens = {};

              if (isSet(item.deleteQuery.httpQuery.urlPath)) {
                item.deleteQuery.httpQuery.url = baseUrl + item.deleteQuery.httpQuery.urlPath;
                item.deleteQuery.httpQuery.urlPath = undefined;
              }
            } else {
              item.deleteQuery = undefined;
            }

            item.searchQuery = undefined;
            item.aggregateQuery = undefined;
            item.groupQuery = undefined;

            const prevItem = models[i - 1];
            item.orderAfter = prevItem ? prevItem.model : undefined;

            const fields = sortModelFields(item.fields);
            fields.forEach((field, f) => {
              const prevField = fields[f - 1];
              field.orderAfter = prevField ? prevField.name : undefined;
            });

            return item;
          })
          .sort(objectsSortPredicate('model'))
          .map(item => {
            const serialized = item.serialize();

            delete serialized['db_table'];
            delete serialized['sync'];
            delete serialized['sync_finished'];
            delete serialized['featured'];
            delete serialized['demo'];
            delete serialized['draft'];
            delete serialized['deleted'];
            delete serialized['virtual'];

            return serialized;
          });

        items = mapValuesDeep(items, item => {
          if (typeof item != 'string') {
            return item;
          }
          return item
            .replace(new RegExp(`"${resourceName}"`, 'g'), '"{{resource}}"')
            .replace(new RegExp(`${resourceName}\\.`, 'g'), '{{resource}}.');
        });

        // console.log('modelDescriptions dump');
        // console.log(JSON.stringify(value, null, ' '));

        if (items.length) {
          const modelDescriptionsDump = this.dumpConst(
            `${templateResourceNameCamel}ResourceParamsModelDescriptions`,
            'Object[]',
            this.dumpObject(items)
          );
          this.saveAsFile(
            modelDescriptionsDump,
            `${templateResourceNameDash}-resource-params-model-descriptions.stub.ts`
          );
        }
      });

      this.actionStore.getFirst().subscribe(result => {
        const actions = sortActionDescriptions(
          result
            .filter(item => item.resource == resourceName)
            .filter(item => !item.protected)
            // Filter auto generating actions
            .filter(item => !item.modelAction)
        ).filter(item => item.queryAction && item.queryAction.query && item.queryAction.query.httpQuery);
        let value = cloneDeep(actions)
          .map((item, i) => {
            item.project = '{{project}}';
            item.resource = '{{resource}}';

            item.queryAction.query.httpQuery.requestResponse = undefined;
            item.queryAction.query.httpQuery.requestTokens = {};

            if (isSet(item.queryAction.query.httpQuery.urlPath)) {
              item.queryAction.query.httpQuery.url = baseUrl + item.queryAction.query.httpQuery.urlPath;
              item.queryAction.query.httpQuery.urlPath = undefined;
            }

            const prevItem = actions[i - 1];
            item.orderAfter = prevItem ? prevItem.model : undefined;

            return item;
          })
          .sort(objectsSortPredicate('name'))
          .map(item => {
            const serialized = item.serialize();

            delete serialized['featured'];
            delete serialized['draft'];
            delete serialized['deleted'];
            delete serialized['params']['featured'];
            delete serialized['params']['virtual'];

            return serialized;
          });

        value = mapValuesDeep(value, item => {
          if (typeof item != 'string') {
            return item;
          }
          return item
            .replace(new RegExp(`"${resourceName}"`, 'g'), '"{{resource}}"')
            .replace(new RegExp(`${resourceName}\\.`, 'g'), '{{resource}}.');
        });

        // console.log('actionDescriptions dump');
        // console.log(JSON.stringify(value, null, ' '));

        if (value.length) {
          const actionDescriptionsDump = this.dumpConst(
            `${templateResourceNameCamel}ResourceParamsActionDescriptions`,
            'Object[]',
            this.dumpObject(value)
          );
          this.saveAsFile(
            actionDescriptionsDump,
            `${templateResourceNameDash}-resource-params-action-descriptions.stub.ts`
          );
        }
      });

      this.viewSettingsService.get(project.uniqueName, environment.uniqueName).subscribe(result => {
        let value = cloneDeep(result)
          .filter(item => item.resource == resourceName)
          .filter(
            item =>
              storeItems.find(storeItem => storeItem.modelId == [item.resource, item.model].join('.')) != undefined
          )
          .map(item => {
            item.resource = '{{resource}}';
            delete item.uid;
            return item;
          })
          .sort(objectsSortPredicate('uniqueName'));

        value = mapValuesDeep(value, item => {
          if (typeof item != 'string') {
            return item;
          }
          return item
            .replace(new RegExp(`"${resourceName}"`, 'g'), '"{{resource}}"')
            .replace(new RegExp(`${resourceName}\\.`, 'g'), '{{resource}}.');
        });

        // console.log('viewSettings dump');
        // console.log(JSON.stringify(value, null, ' '));

        if (value.length) {
          const viewSettingsDump = this.dumpConst(
            `${templateResourceNameCamel}ResourceParamsViewSettings`,
            'Object[]',
            this.dumpObject(value.map(item => item.serialize()))
          );
          this.saveAsFile(viewSettingsDump, `${templateResourceNameDash}-resource-params-view-settings.stub.ts`);
        }
      });

      this.menuSettingsService.get(project.uniqueName, environment.uniqueName).subscribe(result => {
        if (!result) {
          return;
        }

        const filterItem = item => {
          if (item instanceof ModelLinkMenuItem) {
            return item.model.startsWith(`${resourceName}.`);
          } else if (item instanceof SectionMenuItem) {
            item.children = item.children.filter(filterItem);

            if (item.children.length) {
              item.title = '{{resource_name}}';
            }

            return item.children.length;
          } else {
            return false;
          }
        };

        result = cloneDeep(result);
        result.project = '{{project}}';
        result.blocks = result.blocks
          .filter(block => block.enabled)
          .map(block => {
            const newBlock = block.clone();
            newBlock.startItems = newBlock.startItems.filter(filterItem);
            newBlock.centerItems = newBlock.centerItems.filter(filterItem);
            newBlock.endItems = newBlock.endItems.filter(filterItem);
            return newBlock;
          })
          .filter(item => item.getAllItems().length);

        let value = result.serialize();

        value = mapValuesDeep(value, item => {
          if (typeof item != 'string') {
            return item;
          }
          return item
            .replace(new RegExp(`"${resourceName}"`, 'g'), '"{{resource}}"')
            .replace(new RegExp(`${resourceName}\\.`, 'g'), '{{resource}}.');
        });

        // console.log('menuSettings dump');
        // console.log(JSON.stringify(value, null, ' '));

        if (result.getAllItems().length) {
          const menuSettingsDump = this.dumpConst(
            `${templateResourceNameCamel}ResourceParamsMenuSettings`,
            'Object',
            this.dumpObject(value)
          );
          this.saveAsFile(menuSettingsDump, `${templateResourceNameDash}-resource-params-menu-settings.stub.ts`);
        }
      });
    });
  }
}

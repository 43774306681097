import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxSelectModule } from 'ng-gxselect';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { CodeModule } from '@common/code';
import { DynamicComponentModule } from '@common/dynamic-component';
import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { CustomizeUiModule } from '@modules/customize-ui';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ImportModule } from '@modules/import';
import { ModelComponentsModule } from '@modules/model-components';
import { QueriesComponentsModule } from '@modules/queries-components';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ChooseDeployTypeComponent } from './components/choose-deploy-type/choose-deploy-type.component';
import { ChooseResourceTypeComponent } from './components/choose-resource-type/choose-resource-type.component';
import { ChooseSyncModeComponent } from './components/choose-sync-mode/choose-sync-mode.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { ConfirmSyncModeComponent } from './components/confirm-sync-mode/confirm-sync-mode.component';
import { CreateResourceComponent } from './components/create-resource/create-resource.component';
import { DirectModeImageComponent } from './components/direct-mode-image/direct-mode-image.component';
import { InstallInstructionsDjangoComponent } from './components/install-instructions/install-instructions-django/install-instructions-django.component';
import { InstallInstructionsMongoComponent } from './components/install-instructions/install-instructions-mongo/install-instructions-mongo.component';
import { InstallInstructionsOtherComponent } from './components/install-instructions/install-instructions-other/install-instructions-other.component';
import { InstallInstructionsSqlDockerComponent } from './components/install-instructions/install-instructions-sql-docker/install-instructions-sql-docker.component';
import { InstallInstructionsSqlGeneralComponent } from './components/install-instructions/install-instructions-sql-general/install-instructions-sql-general.component';
import { InstallInstructionsSqlHerokuComponent } from './components/install-instructions/install-instructions-sql-heroku/install-instructions-sql-heroku.component';
import { InstallInstructionsComponent } from './components/install-instructions/install-instructions.component';
import { OAuth2ParamsComponent } from './components/oauth2-params/oauth2-params.component';
import { ParameterFieldsEditItemComponent } from './components/parameter-fields-edit/parameter-fields-edit-item/parameter-fields-edit-item.component';
import { ParameterFieldsEditComponent } from './components/parameter-fields-edit/parameter-fields-edit.component';
import { ProjectResourceDataTemplatesTableItemComponent } from './components/project-resource-data-templates-table-item/project-resource-data-templates-table-item.component';
import { ProjectResourceDataTemplatesTableComponent } from './components/project-resource-data-templates-table/project-resource-data-templates-table.component';
import { ProjectResourceDataTemplatesComponent } from './components/project-resource-data-templates/project-resource-data-templates.component';
import { GoogleSheetsImportFileComponent } from './components/resource-add-model/google-sheets-import-file/google-sheets-import-file.component';
import { JetDatabaseCreateTableComponent } from './components/resource-add-model/jet-database-create-table/jet-database-create-table.component';
import { JetDatabaseImportFileComponent } from './components/resource-add-model/jet-database-import-file/jet-database-import-file.component';
import { ResourceChoosePagesComponent } from './components/resource-choose-pages/resource-choose-pages.component';
import { ResourceFieldComponent } from './components/resource-field/resource-field.component';
import { ResourceSettingsStubComponent } from './components/resource-settings-stub/resource-settings-stub.component';
import { ResourceSettingsSubmitErrorComponent } from './components/resource-settings-submit-error/resource-settings-submit-error.component';
import { AirtableResourceSettingsComponent } from './components/resource-settings/airtable-resource-settings/airtable-resource-settings.component';
import { AmazonS3ResourceSettingsComponent } from './components/resource-settings/amazon-s3-resource-settings/amazon-s3-resource-settings.component';
import { AmplitudeResourceSettingsComponent } from './components/resource-settings/amplitude-resource-settings/amplitude-resource-settings.component';
import { AzureBlobResourceSettingsComponent } from './components/resource-settings/azure-blob-resource-settings/azure-blob-resource-settings.component';
import { DatabasesResourceSettingsComponent } from './components/resource-settings/databases-resource-settings/databases-resource-settings.component';
import { DigitalOceanSpacesResourceSettingsComponent } from './components/resource-settings/digital-ocean-spaces-resource-settings/digital-ocean-spaces-resource-settings.component';
import { DjangoResourceSettingsComponent } from './components/resource-settings/django-resource-settings/django-resource-settings.component';
import { FirebaseResourceSettingsComponent } from './components/resource-settings/firebase-resource-settings/firebase-resource-settings.component';
import { GoogleCloudStorageResourceSettingsComponent } from './components/resource-settings/google-cloud-storage-resource-settings/google-cloud-storage-resource-settings.component';
import { GoogleSheetsResourceSettingsFileComponent } from './components/resource-settings/google-sheets-resource-settings/google-sheets-resource-settings-file/google-sheets-resource-settings-file.component';
import { GoogleSheetsResourceSettingsFilesComponent } from './components/resource-settings/google-sheets-resource-settings/google-sheets-resource-settings-files/google-sheets-resource-settings-files.component';
import { GoogleSheetsResourceSettingsComponent } from './components/resource-settings/google-sheets-resource-settings/google-sheets-resource-settings.component';
import { HubspotResourceSettingsComponent } from './components/resource-settings/hubspot-resource-settings/hubspot-resource-settings.component';
import { IntercomResourceSettingsComponent } from './components/resource-settings/intercom-resource-settings/intercom-resource-settings.component';
import { JetDatabaseResourceSettingsComponent } from './components/resource-settings/jet-database-resource-settings/jet-database-resource-settings.component';
import { MailchimpResourceSettingsComponent } from './components/resource-settings/mailchimp-resource-settings/mailchimp-resource-settings.component';
import { MailgunResourceSettingsComponent } from './components/resource-settings/mailgun-resource-settings/mailgun-resource-settings.component';
import { MixpanelResourceSettingsComponent } from './components/resource-settings/mixpanel-resource-settings/mixpanel-resource-settings.component';
import { OpenAIResourceSettingsComponent } from './components/resource-settings/openai-resource-settings/openai-resource-settings.component';
import { PipedriveResourceSettingsComponent } from './components/resource-settings/pipedrive-resource-settings/pipedrive-resource-settings.component';
import { RestApiResourceSettingsComponent } from './components/resource-settings/rest-api-resource-settings/rest-api-resource-settings.component';
import { S3CompatibleResourceSettingsComponent } from './components/resource-settings/s3-compatible-resource-settings/s3-compatible-resource-settings.component';
import { SalesforceResourceSettingsComponent } from './components/resource-settings/salesforce-resource-settings/salesforce-resource-settings.component';
import { SendGridResourceSettingsComponent } from './components/resource-settings/send-grid-resource-settings/send-grid-resource-settings.component';
import { SlackResourceSettingsComponent } from './components/resource-settings/slack-resource-settings/slack-resource-settings.component';
import { SmartSuiteResourceSettingsComponent } from './components/resource-settings/smart-suite-resource-settings/smart-suite-resource-settings.component';
import { StripeResourceSettingsComponent } from './components/resource-settings/stripe-resource-settings/stripe-resource-settings.component';
import { TwilioResourceSettingsComponent } from './components/resource-settings/twilio-resource-settings/twilio-resource-settings.component';
import { XanoResourceSettingsComponent } from './components/resource-settings/xano-resource-settings/xano-resource-settings.component';
import { ZapierResourceSettingsComponent } from './components/resource-settings/zapier-resource-settings/zapier-resource-settings.component';
import { ZendeskResourceSettingsComponent } from './components/resource-settings/zendesk-resource-settings/zendesk-resource-settings.component';
import { ResourceSummaryComponent } from './components/resource-summary/resource-summary.component';
import { StepFormItemComponent } from './components/step-form-item/step-form-item.component';
import { SupabaseStorageResourceSettingsComponent } from './components/supabase-storage-resource-settings/supabase-storage-resource-settings.component';
import { SyncModeImageComponent } from './components/sync-mode-image/sync-mode-image.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    MatMenuModule,
    FieldsModule,
    FieldComponentsModule,
    DynamicComponentModule,
    NgxJsonViewerModule,
    CodeModule,
    QueriesComponentsModule,
    TutorialModule,
    RoutingModule,
    UniqueIdModule,
    SharedModule,
    LocalizeModule,
    UiModule,
    TipsModule,
    SelectModule,
    ImportModule,
    ModelComponentsModule,
    FormUtilsModule,
    CustomizeUiModule,
    DragDropModule,
    MatAutocompleteModule
  ],
  declarations: [
    CompanyInfoComponent,
    ChooseDeployTypeComponent,
    InstallInstructionsComponent,
    InstallInstructionsSqlGeneralComponent,
    InstallInstructionsSqlDockerComponent,
    InstallInstructionsSqlHerokuComponent,
    InstallInstructionsDjangoComponent,
    InstallInstructionsMongoComponent,
    InstallInstructionsOtherComponent,
    StepFormItemComponent,
    ParameterFieldsEditComponent,
    ParameterFieldsEditItemComponent,
    StripeResourceSettingsComponent,
    HubspotResourceSettingsComponent,
    PipedriveResourceSettingsComponent,
    SendGridResourceSettingsComponent,
    SlackResourceSettingsComponent,
    TwilioResourceSettingsComponent,
    OpenAIResourceSettingsComponent,
    ZendeskResourceSettingsComponent,
    DatabasesResourceSettingsComponent,
    DjangoResourceSettingsComponent,
    RestApiResourceSettingsComponent,
    FirebaseResourceSettingsComponent,
    ResourceFieldComponent,
    CreateResourceComponent,
    ChooseResourceTypeComponent,
    GoogleSheetsResourceSettingsComponent,
    GoogleSheetsResourceSettingsFileComponent,
    GoogleSheetsResourceSettingsFilesComponent,
    OAuth2ParamsComponent,
    MixpanelResourceSettingsComponent,
    AmplitudeResourceSettingsComponent,
    IntercomResourceSettingsComponent,
    MailchimpResourceSettingsComponent,
    MailgunResourceSettingsComponent,
    AzureBlobResourceSettingsComponent,
    AmazonS3ResourceSettingsComponent,
    DigitalOceanSpacesResourceSettingsComponent,
    S3CompatibleResourceSettingsComponent,
    GoogleCloudStorageResourceSettingsComponent,
    SupabaseStorageResourceSettingsComponent,
    ResourceChoosePagesComponent,
    ResourceSummaryComponent,
    GoogleSheetsImportFileComponent,
    AirtableResourceSettingsComponent,
    SmartSuiteResourceSettingsComponent,
    ZapierResourceSettingsComponent,
    JetDatabaseResourceSettingsComponent,
    JetDatabaseImportFileComponent,
    JetDatabaseCreateTableComponent,
    ChooseSyncModeComponent,
    ConfirmSyncModeComponent,
    SyncModeImageComponent,
    DirectModeImageComponent,
    ResourceSettingsSubmitErrorComponent,
    SalesforceResourceSettingsComponent,
    XanoResourceSettingsComponent,
    ProjectResourceDataTemplatesComponent,
    ProjectResourceDataTemplatesTableComponent,
    ProjectResourceDataTemplatesTableItemComponent,
    ResourceSettingsStubComponent
  ],
  exports: [
    CompanyInfoComponent,
    ChooseDeployTypeComponent,
    InstallInstructionsComponent,
    InstallInstructionsSqlGeneralComponent,
    InstallInstructionsSqlDockerComponent,
    InstallInstructionsSqlHerokuComponent,
    InstallInstructionsDjangoComponent,
    InstallInstructionsMongoComponent,
    InstallInstructionsOtherComponent,
    StepFormItemComponent,
    ResourceFieldComponent,
    CreateResourceComponent,
    ChooseResourceTypeComponent,
    OAuth2ParamsComponent,
    ResourceSummaryComponent,
    ResourceChoosePagesComponent,
    ProjectResourceDataTemplatesComponent
  ],
  entryComponents: [
    CompanyInfoComponent,
    ParameterFieldsEditComponent,
    ParameterFieldsEditItemComponent,
    StripeResourceSettingsComponent,
    HubspotResourceSettingsComponent,
    PipedriveResourceSettingsComponent,
    SendGridResourceSettingsComponent,
    SlackResourceSettingsComponent,
    TwilioResourceSettingsComponent,
    OpenAIResourceSettingsComponent,
    ZendeskResourceSettingsComponent,
    DatabasesResourceSettingsComponent,
    DjangoResourceSettingsComponent,
    RestApiResourceSettingsComponent,
    FirebaseResourceSettingsComponent,
    GoogleSheetsResourceSettingsComponent,
    MixpanelResourceSettingsComponent,
    AmplitudeResourceSettingsComponent,
    IntercomResourceSettingsComponent,
    MailchimpResourceSettingsComponent,
    MailgunResourceSettingsComponent,
    AzureBlobResourceSettingsComponent,
    AmazonS3ResourceSettingsComponent,
    DigitalOceanSpacesResourceSettingsComponent,
    S3CompatibleResourceSettingsComponent,
    GoogleCloudStorageResourceSettingsComponent,
    SupabaseStorageResourceSettingsComponent,
    ResourceSummaryComponent,
    ResourceChoosePagesComponent,
    GoogleSheetsImportFileComponent,
    AirtableResourceSettingsComponent,
    SmartSuiteResourceSettingsComponent,
    ZapierResourceSettingsComponent,
    JetDatabaseResourceSettingsComponent,
    JetDatabaseImportFileComponent,
    JetDatabaseCreateTableComponent,
    XanoResourceSettingsComponent,
    ChooseSyncModeComponent,
    ConfirmSyncModeComponent,
    SalesforceResourceSettingsComponent,
    ProjectResourceDataTemplatesComponent
  ]
})
export class ProjectsComponentsModule {}

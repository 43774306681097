import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { getDefaultValue, ParameterField } from '@modules/fields';

@Injectable()
export class ActionParamsForm {
  form: FormGroup;

  initialize(actionParams: ParameterField[]) {
    const group = {};

    actionParams.forEach(item => {
      const defaultValue = getDefaultValue(item);

      group[item.name] = new FormControl(defaultValue, item.required ? [Validators.required] : []);
    });

    this.form = new FormGroup(group);
  }

  submit(): Object {
    const value = this.form.value;

    return value;
  }
}

import { Inject, Injectable } from '@angular/core';
import keys from 'lodash/keys';
import OpenAPIV3 from 'openapi-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { FieldType, OptionsType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import {
  Environment,
  Project,
  Resource,
  ResourceType,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import {
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ModelDescriptionSimpleQuery,
  QueryType,
  StorageQuery
} from '@modules/queries';
import {
  ResourceControllerService,
  ResourceParamsResult,
  XANO_COLLECTION_MODEL_CREATE_PARAM_NAME,
  XANO_COLLECTION_MODEL_DELETE_PARAM_NAME,
  XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME,
  XANO_COLLECTION_MODEL_GET_PARAM_NAME,
  XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME,
  XANO_PRIMARY_KEY_FIELD_NAME,
  XanoLegacyResourceController,
  XanoResourceController,
  XanoStorageParams
} from '@modules/resources';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import {
  XANO_CREATE_REGEX,
  XANO_DELETE_REGEX,
  XANO_GET_DETAIL_REGEX,
  XANO_GET_REGEX,
  XANO_RELATION_2_REGEX,
  XANO_RELATION_REGEX,
  XANO_STORAGE_NAME,
  XANO_UPDATE_REGEX
} from './xano-constants';
import { XanoParamsOptions } from './xano-types';

@Injectable()
export class XanoLegacyGeneratorService extends ResourceGeneratorService<XanoParamsOptions> {
  controller: XanoLegacyResourceController;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private resourceControllerService: ResourceControllerService,
    private secretTokenService: SecretTokenService
  ) {
    super();
    this.controller = this.resourceControllerService.get<XanoResourceController>(ResourceType.Xano).legacyController;
  }

  isOptionsValid(options: XanoParamsOptions): Observable<IsOptionsValidResult> {
    return this.controller.getSchema(options.apiBaseUrl).pipe(
      map(swaggerApi => {
        if (!keys(swaggerApi.paths).length) {
          throw new ServerRequestError('Not API endpoints found');
        }

        return {
          accountName: options.apiBaseUrl
        };
      })
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<XanoParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            apiBaseUrl: resource.params['api_base_url'],
            authHeader: result.value
          };
        })
      );
  }

  fetchApi(
    options: XanoParamsOptions
  ): Observable<{
    modelDescriptions: ModelDescription[];
    storage?: Storage;
  }> {
    return this.controller.getSchema(options.apiBaseUrl).pipe(
      map(swaggerApi => {
        const parsedModelDescriptions = new Map<string, ModelDescription>();

        for (const path of keys(swaggerApi.paths)) {
          const schema = swaggerApi.paths[path];
          const splitPath = path.split('/');
          let modelDescription = parsedModelDescriptions.get(splitPath[1]);

          if (modelDescription === undefined) {
            modelDescription = new ModelDescription();
            modelDescription.project = '{{project}}';
            modelDescription.resource = '{{resource}}';
            modelDescription.model = splitPath[1];
            modelDescription.verboseName = modelDescription.model;
            modelDescription.verboseNamePlural = modelDescription.model;
            modelDescription.primaryKeyField = XANO_PRIMARY_KEY_FIELD_NAME;

            parsedModelDescriptions.set(splitPath[1], modelDescription);
          }

          this.parseModelDescriptionSchema(modelDescription, schema as OpenAPIV3.OpenAPIV3.PathItemObject);
        }

        const modelDescriptions = Array.from(parsedModelDescriptions.values()).filter(
          item => item.getQuery && item.fields.length !== 0
        );

        modelDescriptions.forEach(modelDescription => {
          modelDescription.fields
            .filter(field => field.item.field === FieldType.RelatedModel)
            .forEach(field => {
              const schema = field.item.params['schema'];
              delete field.item.params['schema'];

              const { fieldType: actualFieldType, params: actualParams } = this.detectCollectionSchemaField(
                field.name,
                schema,
                false
              );

              const relatedModel = modelDescriptions.find(
                item => item.model == field.item.params['related_model']['model']
              );
              const relatedModelPk =
                relatedModel && isSet(relatedModel.primaryKeyField)
                  ? relatedModel.fields.find(item => item.name == relatedModel.primaryKeyField)
                  : undefined;

              if (!relatedModel || !relatedModelPk || relatedModelPk.item.field != actualFieldType) {
                field.item.field = actualFieldType;
                field.item.params = actualParams;
              }
            });
        });

        const storage = this.createStorageFromSchema(swaggerApi.paths['/upload/attachment']);

        return {
          modelDescriptions: modelDescriptions,
          storage: storage
        };
      })
    );
  }

  private parseModelDescriptionSchema(
    modelDescription: ModelDescription,
    schema: OpenAPIV3.OpenAPIV3.PathItemObject
  ): void {
    for (const method in schema) {
      if (schema[method].summary.match(XANO_GET_REGEX)) {
        modelDescription.getQuery = this.generateModelDescriptionGet(modelDescription);
        modelDescription.params[XANO_COLLECTION_MODEL_GET_PARAM_NAME] = method;

        if (schema[method].responses['200'] !== undefined) {
          modelDescription.fields = this.parseModelDescriptionSchemaFields(
            schema[method].responses['200'] as OpenAPIV3.OpenAPIV3.ResponseObject
          );
        }
      } else if (schema[method].summary.match(XANO_GET_DETAIL_REGEX)) {
        modelDescription.getDetailQuery = this.generateModelDescriptionGetDetail(modelDescription);
        modelDescription.getDetailParametersUseDefaults = true;
        modelDescription.params[XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME] = method;
      } else if (schema[method].summary.match(XANO_CREATE_REGEX)) {
        modelDescription.createQuery = this.generateModelDescriptionCreate(modelDescription);
        modelDescription.createParametersUseDefaults = true;
        modelDescription.params[XANO_COLLECTION_MODEL_CREATE_PARAM_NAME] = method;
      } else if (schema[method].summary.match(XANO_UPDATE_REGEX)) {
        modelDescription.updateQuery = this.generateModelDescriptionUpdate(modelDescription);
        modelDescription.updateParametersUseDefaults = true;
        modelDescription.params[XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME] = method;
      } else if (schema[method].summary.match(XANO_DELETE_REGEX)) {
        modelDescription.deleteQuery = this.generateModelDescriptionDelete(modelDescription);
        modelDescription.deleteParametersUseDefaults = true;
        modelDescription.params[XANO_COLLECTION_MODEL_DELETE_PARAM_NAME] = method;
      }
    }
  }

  private generateModelDescriptionGet(modelDescription: ModelDescription): ListModelDescriptionQuery {
    const getSimpleQuery = new ModelDescriptionSimpleQuery();
    getSimpleQuery.model = modelDescription.model;

    const getQuery = new ListModelDescriptionQuery();

    getQuery.queryType = QueryType.Simple;
    getQuery.simpleQuery = getSimpleQuery;

    return getQuery;
  }

  private generateModelDescriptionGetDetail(modelDescription: ModelDescription): ModelDescriptionQuery {
    const getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
    getDetailSimpleQuery.model = modelDescription.model;

    const getDetailQuery = new ModelDescriptionQuery();

    getDetailQuery.queryType = QueryType.Simple;
    getDetailQuery.simpleQuery = getDetailSimpleQuery;

    return getDetailQuery;
  }

  private generateModelDescriptionCreate(modelDescription: ModelDescription): ModelDescriptionQuery {
    const createSimpleQuery = new ModelDescriptionSimpleQuery();
    createSimpleQuery.model = modelDescription.model;

    const createQuery = new ModelDescriptionQuery();

    createQuery.queryType = QueryType.Simple;
    createQuery.simpleQuery = createSimpleQuery;

    return createQuery;
  }

  private generateModelDescriptionUpdate(modelDescription: ModelDescription): ModelDescriptionQuery {
    const updateSimpleQuery = new ModelDescriptionSimpleQuery();
    updateSimpleQuery.model = modelDescription.model;

    const updateQuery = new ModelDescriptionQuery();

    updateQuery.queryType = QueryType.Simple;
    updateQuery.simpleQuery = updateSimpleQuery;

    return updateQuery;
  }

  private generateModelDescriptionDelete(modelDescription: ModelDescription): ModelDescriptionQuery {
    const deleteSimpleQuery = new ModelDescriptionSimpleQuery();
    deleteSimpleQuery.model = modelDescription.model;

    const deleteQuery = new ModelDescriptionQuery();

    deleteQuery.queryType = QueryType.Simple;
    deleteQuery.simpleQuery = deleteSimpleQuery;

    return deleteQuery;
  }

  private parseModelDescriptionSchemaFields(responseSchema: OpenAPIV3.OpenAPIV3.ResponseObject): ModelField[] {
    const result: ModelField[] = [];
    let schema: OpenAPIV3.OpenAPIV3.SchemaObject | undefined;

    if (responseSchema.content['application/json'].schema) {
      schema = responseSchema.content['application/json'].schema as OpenAPIV3.OpenAPIV3.SchemaObject;
    }

    if (schema === undefined) {
      return [];
    }

    let items: OpenAPIV3.OpenAPIV3.SchemaObject = {};

    if (schema.type === 'object' && schema.properties && schema.properties['items'] !== undefined) {
      items = schema.properties['items']['items'] as OpenAPIV3.OpenAPIV3.SchemaObject;
    }

    if (schema.type === 'array') {
      items = schema.items as OpenAPIV3.OpenAPIV3.SchemaObject;
    }

    if (items.type !== 'object') {
      return [];
    }

    for (const propertyKey of keys(items.properties)) {
      const property = items.properties[propertyKey] as OpenAPIV3.OpenAPIV3.SchemaObject;

      if (property.type === undefined) {
        continue;
      }

      result.push(this.parseModelDescriptionSchemaField(propertyKey, property));
    }

    return result;
  }

  private parseModelDescriptionSchemaField(propertyKey: string, schema: OpenAPIV3.OpenAPIV3.SchemaObject): ModelField {
    const { fieldType, params } = this.detectCollectionSchemaField(propertyKey, schema);
    const field = new ModelField();
    const dbField = new ModelDbField();

    dbField.name = propertyKey;
    dbField.verboseName = propertyKey;
    dbField.required = schema.nullable === undefined ? true : !schema.nullable;
    dbField.editable = propertyKey != XANO_PRIMARY_KEY_FIELD_NAME;
    dbField.field = fieldType;
    dbField.params = params;
    dbField.updateFieldDescription();

    field.name = dbField.name;
    field.type = ModelFieldType.Db;
    field.item = dbField;

    return field;
  }

  private detectCollectionSchemaRelationField(propertyKey: string): string | null {
    for (const regex of [XANO_RELATION_REGEX, XANO_RELATION_2_REGEX]) {
      const match = propertyKey.match(regex);
      if (match) {
        return match[1];
      }
    }

    return null;
  }

  private detectCollectionSchemaField(
    propertyKey: string,
    schema: OpenAPIV3.OpenAPIV3.SchemaObject,
    allowRelation = true
  ): {
    fieldType: FieldType;
    params: Record<string, unknown> | undefined;
  } {
    let params: Record<string, unknown> | undefined;
    let fieldType = this.detectPrimitiveFieldType(schema.type);

    const relationField = allowRelation ? this.detectCollectionSchemaRelationField(propertyKey) : null;

    if (schema.format === 'timestamptz') {
      params = {
        date: true,
        time: true
      };
      fieldType = FieldType.DateTime;
    } else if (schema.format === 'date') {
      params = {
        date: true,
        time: false
      };
      fieldType = FieldType.DateTime;
    } else if (schema.enum !== undefined) {
      params = {
        options_type: OptionsType.Static,
        options: schema.enum.map(item => {
          return {
            value: item,
            name: item
          };
        })
      };
      fieldType = FieldType.Select;
    } else if (allowRelation && relationField !== null && schema.type !== 'array') {
      params = {
        value_format: {
          number_fraction: 0
        },
        related_model: {
          model: relationField
        },
        schema: schema
      };
      fieldType = FieldType.RelatedModel;
    } else if (schema.type === 'object') {
      if (
        schema.properties !== undefined &&
        schema.properties['path'] !== undefined &&
        schema.properties['url'] !== undefined &&
        schema.properties['mime'] !== undefined
      ) {
        fieldType = FieldType.File;
        params = {
          storage_resource: '{{resource}}',
          storage_name: XANO_STORAGE_NAME
        };
      }
    } else if (schema.type === 'integer') {
      params = {
        value_format: {
          number_fraction: 0
        }
      };
    }

    return { fieldType, params };
  }

  private detectPrimitiveFieldType(type: OpenAPIV3.OpenAPIV3.NonArraySchemaObjectType | 'array'): FieldType {
    switch (type) {
      case 'integer': {
        return FieldType.Number;
      }
      case 'boolean': {
        return FieldType.Boolean;
      }
      case 'number': {
        return FieldType.Number;
      }
      case 'object': {
        return FieldType.JSON;
      }
      case 'string': {
        return FieldType.Text;
      }
      case 'array': {
        return FieldType.JSON;
      }
      default: {
        return FieldType.Text;
      }
    }
  }

  createStorageFromSchema(schema: OpenAPIV3.OpenAPIV3.PathItemObject): Storage {
    if (!schema) {
      return;
    }

    const storage = new Storage();
    const method = keys(schema)[0];
    const file_parameter = keys(schema[method].requestBody)[0];

    storage.uniqueName = XANO_STORAGE_NAME;
    storage.name = 'Attachments';

    storage.uploadQuery = new StorageQuery();
    storage.uploadQuery.queryType = QueryType.Simple;
    storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

    storage.extraParams = {
      upload: {
        method: method,
        file_parameter: file_parameter
      }
    } as XanoStorageParams;

    return storage;
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: XanoParamsOptions
  ): Observable<ResourceParamsResult> {
    return this.fetchApi(options).pipe(
      map(result => {
        if (!result.modelDescriptions.length) {
          throw new ServerRequestError('No CRUD API endpoints found');
        }

        const resourceParams = {
          api_base_url: options.apiBaseUrl
        };
        const token = new SecretToken();

        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.authHeader;

        return {
          resourceParams: resourceParams,
          modelDescriptions: result.modelDescriptions.map(item => item.serialize()),
          secretTokens: [token.serialize()],
          storages: result.storage ? [result.storage.serialize()] : []
        };
      })
    );
  }
}

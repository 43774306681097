/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./container-app-stub.component";
var styles_ContainerAppStubComponent = [];
var RenderType_ContainerAppStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContainerAppStubComponent, data: {} });
export { RenderType_ContainerAppStubComponent as RenderType_ContainerAppStubComponent };
export function View_ContainerAppStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "container-stub__item container-stub__item_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "container-stub-placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "container-stub-placeholder__image container-stub-placeholder__image_type_drag-n-drop"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "container-stub-placeholder__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Start creating your Page"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "container-stub-placeholder__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Drag-and-drop different components and connect resources to build an App "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "container-stub-placeholder__actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "button button_primary button_orange-alternative"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customizeClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Customize Page "]))], null, null); }
export function View_ContainerAppStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-container-app-stub", [], [[2, "container-stub", null], [2, "container-stub_fill", null]], null, null, View_ContainerAppStubComponent_0, RenderType_ContainerAppStubComponent)), i0.ɵdid(1, 114688, null, 0, i1.ContainerAppStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).marginSizeS; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ContainerAppStubComponentNgFactory = i0.ɵccf("app-container-app-stub", i1.ContainerAppStubComponent, View_ContainerAppStubComponent_Host_0, { fill: "fill", templates: "templates" }, { customizeClick: "customizeClick" }, []);
export { ContainerAppStubComponentNgFactory as ContainerAppStubComponentNgFactory };

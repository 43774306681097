/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../icon-selector-stub/icon-selector-stub.component.ngfactory";
import * as i4 from "../icon-selector-stub/icon-selector-stub.component";
import * as i5 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i6 from "./icon-selector-icons.component";
var styles_IconSelectorIconsComponent = [];
var RenderType_IconSelectorIconsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSelectorIconsComponent, data: {} });
export { RenderType_IconSelectorIconsComponent as RenderType_IconSelectorIconsComponent };
function View_IconSelectorIconsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "select-icon__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [["class", "select-icon-item select-icon-item_interactive"], ["href", "javascript:void(0)"]], [[2, "select-icon-item_current", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected.emit(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { showDelay: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "select-icon-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(6, 1)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; var currVal_2 = _ck(_v, 3, 0, 300); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "select-icon-item__icon"; var currVal_4 = _ck(_v, 6, 0, ("icon-" + _v.context.$implicit.name)); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.current == _v.context.$implicit.name); _ck(_v, 1, 0, currVal_0); }); }
function View_IconSelectorIconsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "select-icon__items-empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No icons found "]))], null, null); }
function View_IconSelectorIconsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorIconsComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_IconSelectorIconsComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredItems; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.search)) && !_co.filteredItems.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_IconSelectorIconsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-stub", [], null, null, null, i3.View_IconSelectorStubComponent_0, i3.RenderType_IconSelectorStubComponent)), i0.ɵdid(1, 638976, null, 0, i4.IconSelectorStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_IconSelectorIconsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorIconsComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorIconsComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IconSelectorIconsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-icons", [], null, null, null, View_IconSelectorIconsComponent_0, RenderType_IconSelectorIconsComponent)), i0.ɵdid(1, 638976, null, 0, i6.IconSelectorIconsComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSelectorIconsComponentNgFactory = i0.ɵccf("app-icon-selector-icons", i6.IconSelectorIconsComponent, View_IconSelectorIconsComponent_Host_0, { current: "current", search: "search", loading: "loading" }, { selected: "selected" }, []);
export { IconSelectorIconsComponentNgFactory as IconSelectorIconsComponentNgFactory };

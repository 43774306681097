import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopupsModule } from '@common/popups';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { GuideFocusComponent } from './components/guide-focus/guide-focus.component';
import { GuidePopupDocumentationComponent } from './components/guide-popup/guide-popup-documentation/guide-popup-documentation.component';
import { GuidePopupTaskComponent } from './components/guide-popup/guide-popup-task/guide-popup-task.component';
import { GuidePopupComponent } from './components/guide-popup/guide-popup.component';
import { GuideWidgetComponent } from './components/guide-widget/guide-widget.component';
import { OnboardingPopupComponent } from './components/onboarding-popup/onboarding-popup.component';
import { VideoPopupComponent } from './components/video-popup/video-popup.component';
import { DocumentationDirective } from './directives/documentation/documentation.directive';
import { ShowVideoDirective } from './directives/show-video/show-video.directive';
import { TutorialTipDirective } from './directives/tutorial-tip/tutorial-tip.directive';
import { TutorialTipService } from './services/tutorial-tip/tutorial-tip.service';

@NgModule({
  imports: [CommonModule, SharedModule, PopupsModule, RoutingModule, TipsModule, AnalyticsModule],
  declarations: [
    VideoPopupComponent,
    OnboardingPopupComponent,
    TutorialTipDirective,
    GuidePopupComponent,
    GuideWidgetComponent,
    GuidePopupDocumentationComponent,
    GuidePopupTaskComponent,
    GuideFocusComponent,
    DocumentationDirective,
    ShowVideoDirective
  ],
  exports: [
    TutorialTipDirective,
    GuideWidgetComponent,
    GuideFocusComponent,
    DocumentationDirective,
    ShowVideoDirective
  ],
  entryComponents: [VideoPopupComponent, OnboardingPopupComponent, GuidePopupComponent]
})
export class TutorialModule {}

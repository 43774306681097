import { ParameterField } from '@modules/fields';

import { FieldsEditItem } from '../components/fields-edit/fields-edit.form';

export function fieldsEditItemToParameterField(value: FieldsEditItem): ParameterField {
  const result = new ParameterField();

  result.name = value.name;
  result.verboseName = value.verboseName;
  result.description = value.description;
  result.field = value.field;
  result.required = value.required;
  result.defaultType = value.defaultType;
  result.defaultValue = value.defaultValue;
  result.placeholder = value.placeholder;
  result.validatorType = value.validatorType;
  result.validatorParams = value.validatorParams;
  result.params = value.params;
  result.protected = value.protected;
  result.updateFieldDescription();

  return result;
}

export function fieldsEditItemFromParameterField(value: ParameterField): FieldsEditItem {
  return {
    name: value.name,
    verboseName: value.verboseName,
    description: value.description,
    field: value.field,
    required: value.required,
    defaultType: value.defaultType,
    defaultValue: value.defaultValue,
    placeholder: value.placeholder,
    validatorType: value.validatorType,
    validatorParams: value.validatorParams,
    params: value.params,
    protected: value.protected
  };
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AudioOutputFormat, FieldType, registerFieldComponent } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { UploadFieldComponent } from '../file-field/upload-field.component';
var AudioFieldComponent = /** @class */ (function (_super) {
    __extends(AudioFieldComponent, _super);
    function AudioFieldComponent(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) {
        var _this = _super.call(this, modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) || this;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.storageService = storageService;
        _this.resourceControllerService = resourceControllerService;
        _this.cd = cd;
        _this.outputFormats = AudioOutputFormat;
        return _this;
    }
    AudioFieldComponent.prototype.ngOnInit = function () {
        this.updateStorage();
        this.updateUploaded();
        this.initOutputs();
    };
    AudioFieldComponent.prototype.ngOnDestroy = function () { };
    AudioFieldComponent.prototype.ngOnChanges = function (changes) {
        this.updateStorage();
        this.updateUploaded();
    };
    return AudioFieldComponent;
}(UploadFieldComponent));
export { AudioFieldComponent };
registerFieldComponent(FieldType.Audio, AudioFieldComponent);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { imageFieldCrops, ImageFieldType, imageFieldTypes } from '@modules/field-components';
import { imageFieldFits, ImageOutputFormat, Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
var ImageFieldViewParamsForm = /** @class */ (function () {
    function ImageFieldViewParamsForm(fb, currentProjectStore, currentEnvironmentStore) {
        this.fb = fb;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.outputFormatOptions = [
            { value: ImageOutputFormat.URL, name: 'Specify URL' },
            { value: ImageOutputFormat.Storage, name: 'Save to Storage' }
        ];
        this.typeOptions = imageFieldTypes;
        this.typeElementOptions = imageFieldTypes.filter(function (item) {
            return [ImageFieldType.Rectangular, ImageFieldType.Circular].includes(item.value);
        });
        this.fitOptions = imageFieldFits;
        this.imageFieldCrops = imageFieldCrops;
        this.form = this.fb.group({
            output_format: new FormControl(ImageOutputFormat.Storage),
            storage: new FormControl(undefined),
            path: new FieldInputControl({ name: 'value' }),
            type: new FormControl(imageFieldTypes[0].value),
            fit: new FormControl(imageFieldFits[0].value),
            crop_format: new FormControl(imageFieldCrops[0].value),
            resize: new FormControl(false),
            width: new FormControl(''),
            height: new FormControl(''),
            show_preview: new FormControl(false),
            lightbox: new FormControl(false),
            editor: new FormControl(true),
            crop_format_custom: new FormControl('', [this.validateCropFormat()])
        });
    }
    ImageFieldViewParamsForm.prototype.validateCropFormat = function () {
        return function (control) {
            if (!control.value) {
                return;
            }
            var match1 = String(control.value).match(/^(\d+\:\d+)$/);
            if (control.value && !match1) {
                return { local: ['Crop format: x:x'] };
            }
        };
    };
    ImageFieldViewParamsForm.prototype.init = function (control, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.control = control;
        var params = control.value || {};
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, params['storage_resource'], params['storage_name'], { defaultFirst: true, contextResource: options.context ? options.context.resource : undefined });
        var value = {
            storage: storage ? { resource: storage.resource.uniqueName, name: storage.storage.uniqueName } : undefined
        };
        if (control.value) {
            // Backward compatibility
            if (params['external']) {
                value['output_format'] = ImageOutputFormat.URL;
            }
            else {
                value['output_format'] = params['output_format'] || ImageOutputFormat.Storage;
            }
            // Backward compatibility
            if (typeof params['path'] == 'string') {
                value['path'] = new Input().deserializeFromStatic('value', params['path']).serialize();
            }
            else if (params['path']) {
                value['path'] = new Input().deserialize(params['path']).serialize();
            }
            value['type'] = params['type'];
            value['fit'] = params['fit'];
            value['resize'] = params['resize'];
            value['width'] = params['width'];
            value['height'] = params['height'];
            value['show_preview'] = params['show_preview'];
            value['lightbox'] = params['lightbox'];
            value['editor'] = isSet(params['editor']) ? params['editor'] : true;
            value['crop_format'] = params['crop_format'];
            value['crop_format_custom'] = params['crop_format_custom'];
        }
        this.form.patchValue(value, { emitEvent: false });
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    ImageFieldViewParamsForm.prototype.submit = function () {
        var value = this.form.value;
        var params = {
            output_format: value['output_format'],
            path: value['path'] ? new FieldInput().deserialize(value['path']).serialize() : undefined,
            type: value['type'],
            fit: value['fit'],
            resize: value['resize'],
            width: value['width'],
            height: value['height'],
            show_preview: value['show_preview'],
            lightbox: value['lightbox'],
            editor: value['editor'],
            crop_format: value['crop_format'],
            crop_format_custom: value['crop_format_custom']
        };
        if (value['storage']) {
            params['storage_resource'] = value['storage']['resource'];
            params['storage_name'] = value['storage']['name'];
        }
        this.control.patchValue(__assign({}, this.control.value, params));
    };
    return ImageFieldViewParamsForm;
}());
export { ImageFieldViewParamsForm };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { isSet } from '@shared';

import { User } from '../../data/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(search: string): Observable<User[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('users/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: { search: search } });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new User().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getCurrent(forceOriginal = false): Observable<User> {
    return this.apiService.refreshToken(forceOriginal).pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('current_user/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers, undefined, forceOriginal);

        return this.http.get<User>(url, { headers: headers });
      }),
      map(result => new User().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    user: User,
    options: {
      projectName?: string;
      projectToken?: string;
      transferDemoProjects?: string[];
      language?: string;
      customFields?: Object;
    } = {}
  ): Observable<{ user: User; selfSignOn: boolean }> {
    const url = this.apiService.methodURL('users/');
    const data = user.serialize();

    if (options.projectName) {
      data['project'] = options.projectName;
    }

    if (options.projectToken) {
      data['project_token'] = options.projectToken;
    }

    if (options.transferDemoProjects) {
      data['transfer_projects'] = options.transferDemoProjects.join(',');
    }

    if (options.language) {
      data['language'] = options.language;
    }

    if (options.customFields) {
      data['custom_fields'] = options.customFields;
    }

    return this.http.post(url, data).pipe(
      map(result => {
        return {
          user: new User().deserialize(result),
          selfSignOn: result['self_sign_on']
        };
      }),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  update(user: User, fields: string[]): Observable<User> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('current_user/edit/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, user.serialize(fields), { headers: headers });
      }),
      map(result => new User().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  updatePhoto(file: File): Observable<User> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('current_user/edit/');
        let headers = new HttpHeaders();
        let data: any;

        if (file) {
          data = new FormData();
          data.append('photo', file);
        } else {
          data = {
            photo: null
          };
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new User().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  changeEmail(newEmail: string, currentPassword?: string): Observable<UserService.ChangeEmailResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('users/change_email');
        let headers = new HttpHeaders();
        const data = {
          email: newEmail,
          ...(isSet(currentPassword) && {
            password: currentPassword
          })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ sent_confirmation_to: string }>(url, data, { headers: headers });
      }),
      map(result => new UserService.ChangeEmailResponse().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  changeEmailConfirm(code: string): Observable<UserService.ChangeEmailConfirmResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('users/change_email_confirm');
        let headers = new HttpHeaders();
        const data = {
          code: code
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new UserService.ChangeEmailConfirmResponse().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  restore(email: string): Observable<boolean> {
    const url = this.apiService.methodURL('users/restore/');
    const data = {
      email: email
    };

    return this.http.post(url, data).pipe(
      map(result => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  restoreComplete(code: string, password: string): Observable<UserService.RestoreCompleteResponse> {
    const url = this.apiService.methodURL('users/restore_complete/');
    const data = {
      validation_code: code,
      password: password
    };

    return this.http.post(url, data).pipe(
      map(result => new UserService.RestoreCompleteResponse().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  resendVerification() {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('resend_verification_email/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, null, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  verify(email: string, code: string) {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('verify_email/');
        const data = {
          email: email,
          code: code
        };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace UserService {
  export class RestoreCompleteResponse {
    public validationCode: string;
    public token: string;

    deserialize(data: Object) {
      this.validationCode = data['validation_code'];
      this.token = data['token'];

      return this;
    }
  }

  export class ChangeEmailResponse {
    public sentConfirmationTo: string;

    deserialize(data: Object) {
      this.sentConfirmationTo = data['sent_confirmation_to'];

      return this;
    }
  }

  export class ChangeEmailConfirmResponse {
    public oldEmail: string;
    public newEmail: string;

    deserialize(data: Object) {
      this.oldEmail = data['old_email'];
      this.newEmail = data['new_email'];

      return this;
    }
  }
}

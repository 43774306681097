export { DefaultDialogOptions, DialogComponent } from './components/dialog/dialog.component';
export {
  DialogButton,
  DialogButtonType,
  DialogButtonPosition,
  DialogInput,
  DialogOptions,
  DialogButtonHotkey
} from './data/options';
export { DialogInstance, DialogService } from './services/dialog/dialog.service';
export { DialogsModule } from './dialogs.module';

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { CustomizeBarContext } from '@modules/customize-bar';
import { FlexLayout, FrameLayer, LayerType } from '@modules/views';
import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../../services/view-editor-context/view-editor.context';
import { serializeFlexLayoutOrientationOption } from '../../controls/flex-layout';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { GroupCustomizeLayerForm } from './group-customize-layer.form';
var GroupCustomizeLayerComponent = /** @class */ (function (_super) {
    __extends(GroupCustomizeLayerComponent, _super);
    function GroupCustomizeLayerComponent(form, editorContext, customizeBarContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.editorContext = editorContext;
        _this.customizeBarContext = customizeBarContext;
        _this.cd = cd;
        _this.settingsComponents = [];
        return _this;
    }
    GroupCustomizeLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var layerContainer = this.editorContext.getLayerContainer(this.layer);
        this.form.init(this.layer, { firstInit: true, container: layerContainer });
        this.trackFormUpdates(this.form, function () { return _this.form.submit(); });
        combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
            .pipe(auditTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var layer = _a[0], container = _a[1];
            _this.pauseFormUpdates();
            _this.form.init(layer, {
                firstInit: false,
                container: container
            });
            _this.resumeFormUpdates();
        });
        this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(function (layer) {
            _this.containerLayer = layer;
            _this.cd.markForCheck();
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.markForCheck();
        });
    };
    GroupCustomizeLayerComponent.prototype.ngOnDestroy = function () { };
    GroupCustomizeLayerComponent.prototype.setFlexLayout = function (option) {
        var _this = this;
        var container = this.containerLayer ? this.containerLayer.layers : this.editorContext.view$.value.layers;
        var index = container.findIndex(function (item) { return item.isSame(_this.layer); });
        if (index === -1) {
            return;
        }
        var frameLayer = new FrameLayer().deserialize(this.layer.serialize());
        var _a = serializeFlexLayoutOrientationOption(option.value), orientation = _a.orientation, wrap = _a.wrap;
        frameLayer.name = this.editorContext.generateLayerName(frameLayer, { ignoreLayers: [this.layer] });
        frameLayer.widthFluid = true;
        frameLayer.heightFluid = true;
        frameLayer.flexLayout = new FlexLayout();
        frameLayer.flexLayout.orientation = orientation;
        frameLayer.flexLayout.wrap = wrap;
        container.splice(index, 1, frameLayer);
        this.editorContext.markLayerContainerChanged(container, ViewEditorCustomizeSource.CustomizeLayer);
        this.editorContext.setCustomizingLayer(frameLayer);
    };
    return GroupCustomizeLayerComponent;
}(CustomizeLayerComponent));
export { GroupCustomizeLayerComponent };
registerCustomizeLayerComponent(LayerType.Group, GroupCustomizeLayerComponent);

import { Injectable, Injector, Type } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionStore } from '@modules/action-queries';
import { FieldType, ParameterArray } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import {
  HttpQuery,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ObjectQueryOperation,
  Query,
  QueryService
} from '@modules/queries';
import {
  HttpResultsSection,
  QueryBuilderContext,
  QueryBuilderHttpOptions,
  QueryBuilderService,
  QueryBuilderSqlOptions
} from '@modules/queries-components';

@Injectable()
export class ModelQueryEditController {
  constructor(
    private injector: Injector,
    private queryBuilderService: QueryBuilderService,
    private queryService: QueryService,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private notificationService: NotificationService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  editGetQuery(options: {
    resource: Resource;
    modelDescription: ModelDescription;
    query: ModelDescriptionQuery;
    queryClass: Type<Query>;
    queryContext: QueryBuilderContext;
    parametersControl: ParameterArray;
    httpOptions?: QueryBuilderHttpOptions;
    sqlOptions?: QueryBuilderSqlOptions;
    analyticsSource?: string;
  }): Observable<ModelDescription> {
    return this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: options.queryClass,
        queryTypes: [options.query.queryType],
        resource: options.resource,
        resourceType: options.resource.type,
        context: options.queryContext,
        requireResponse: true,
        arrayResponse: true,
        query: options.query,
        parametersControl: options.parametersControl,
        httpOptions: options.httpOptions,
        sqlOptions: options.sqlOptions,
        objectOptions: {
          forceOperation: ObjectQueryOperation.Get
        },
        source: options.analyticsSource
      })
      .pipe(
        filter(e => !!e.saved),
        map(e => {
          const model = cloneDeep(options.modelDescription) as ModelDescription;

          if (options.queryClass == ListModelDescriptionQuery) {
            model.getQuery = e.saved.query as ListModelDescriptionQuery;
            model.getParameters = options.parametersControl.value;
          } else if (options.queryClass == ModelDescriptionQuery) {
            model.getDetailQuery = e.saved.query as ModelDescriptionQuery;
            model.getDetailParameters = options.parametersControl.value;
          }

          return model;
        }),
        tap(model => {
          const responseColumns = this.queryService.getAutoDetectColumns(
            model.getQuery,
            options.queryClass == ListModelDescriptionQuery
          );

          if (!responseColumns) {
            this.notificationService.error('Failed to detect fields', 'Response is incorrect');
            return;
          }

          model.fields = responseColumns.map(item => {
            const existingColumn = model.fields ? model.fields.find(i => i.name == item.name) : undefined;
            if (existingColumn) {
              return existingColumn;
            }
            const field = new ModelField();
            field.name = item.name;
            field.type = ModelFieldType.Db;
            field.item = new ModelDbField();
            field.item.name = item.name;
            field.item.verboseName = item.name;
            field.item.field = FieldType.Text;
            field.item.updateFieldDescription();
            return field;
          });
        }),
        delayWhen(model => {
          return this.modelDescriptionService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            model
          );
        }),
        tap(model => this.modelDescriptionStore.updateItem(model))
      );
  }

  editAutoAction(options: {
    resource: Resource;
    modelDescription: ModelDescription;
    name: string;
    query: ModelDescriptionQuery;
    queryClass: Type<Query>;
    queryContext: QueryBuilderContext;
    parametersControl: ParameterArray;
    httpOptions?: QueryBuilderHttpOptions;
    sqlOptions?: QueryBuilderSqlOptions;
    analyticsSource?: string;
  }): Observable<ModelDescription> {
    let forceOperation: ObjectQueryOperation;

    if (options.name == 'create') {
      forceOperation = ObjectQueryOperation.Create;
    } else if (options.name == 'update') {
      forceOperation = ObjectQueryOperation.Update;
    } else if (options.name == 'delete') {
      forceOperation = ObjectQueryOperation.Delete;
    }

    return this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: options.queryClass,
        queryTypes: [options.query.queryType],
        resource: options.resource,
        resourceType: options.resource.type,
        context: options.queryContext,
        requireResponse: false,
        arrayResponse: false,
        query: options.query,
        parametersControl: options.parametersControl,
        httpOptions: options.httpOptions,
        sqlOptions: options.sqlOptions,
        objectOptions: {
          forceOperation: forceOperation
        },
        source: options.analyticsSource
      })
      .pipe(
        filter(e => !!e.saved),
        map(e => {
          const model = cloneDeep(options.modelDescription) as ModelDescription;
          model.setQuerySettings(options.name, e.saved.query as ModelDescriptionQuery, options.parametersControl.value);
          return model;
        }),
        switchMap(model => {
          return this.modelDescriptionService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            model
          );
        }),
        tap(result => this.modelDescriptionStore.updateItem(result)),
        delayWhen(() => this.actionStore.getFirst(true))
      );
  }
}

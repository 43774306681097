<app-customize-bar-header [title]="'Sign Up'" [subtitle]="'Sign In & Sign Up'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize sign up page you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <div
    *ngIf="currentEnvironmentStore.instance.uniqueName != currentProjectStore.instance.defaultEnvironment?.uniqueName"
    class="sidebar__element"
  >
    <app-alert
      [title]="'Please open ' + currentProjectStore.instance.defaultEnvironment?.name + ' environment'"
      [warning]="true"
    >
      <div>Sign Up configuration is App specific so you can't configure it for current environment only</div>
      <div style="margin-top: 4px;">
        <a
          [appLink]="currentProjectStore.instance.settingsSignUpLink('auth')"
          [appLinkEnvironment]="currentProjectStore.instance.defaultEnvironment?.uniqueName"
        >
          Go to {{ currentProjectStore.instance.defaultEnvironment?.name }}
        </a>
      </div>
    </app-alert>
  </div>

  <ng-container
    *ngIf="currentEnvironmentStore.instance.uniqueName == currentProjectStore.instance.defaultEnvironment?.uniqueName"
  >
    <app-sidebar-section [interactive]="!domain" (click)="onDomainRequiredClick()">
      <div class="sidebar__element">
        <app-sidebar-field
          [label]="'members sign up'"
          [tag]="'customer portal'"
          [subtitle]="'New members will be able to sign up to current App on their own without invitation'"
          [tagColor]="'blue'"
        >
          <div
            *ngIf="!domain"
            class="sidebar-collapse"
            [class.sidebar-collapse_openable]="false"
            [class.sidebar-collapse_block]="true"
            [class.sidebar-collapse_opened]="false"
            [class.sidebar-collapse_disabled]="true"
          >
            <div class="sidebar-collapse__header">
              <div class="sidebar-collapse__header-left">
                <app-toggle [selected]="false" [orange]="true" style="margin-left: 10px;"></app-toggle>
              </div>
              <div class="sidebar-collapse__header-main">
                <div class="sidebar-collapse__header-title">Enable Sign Up page</div>
              </div>
              <div class="sidebar-collapse__header-right">
                <div
                  class="sidebar-list-item__action"
                  [class.icon-arrow_forward_2]="true"
                  [class.icon-arrow_down_2]="false"
                ></div>
              </div>
            </div>
          </div>

          <div
            *ngIf="domain"
            class="sidebar-collapse"
            [class.sidebar-collapse_openable]="true"
            [class.sidebar-collapse_block]="true"
            [class.sidebar-collapse_opened]="signUpOpened"
            [class.sidebar-collapse_disabled]="false"
          >
            <app-project-group-dropdown
              [control]="projectSignUpForm.controls.default_group"
              [origin]="dropdownTrigger"
              [headerTitle]="'Choose Team for new members'"
              [headerIcon]="'users_teams'"
              [environmentName]="currentProjectStore.instance.defaultEnvironment.uniqueName"
              (change)="onSignUpEnableGroupChange($event)"
              #dropdown
            ></app-project-group-dropdown>

            <div
              class="sidebar-collapse__header"
              cdkOverlayOrigin
              #dropdownTrigger="cdkOverlayOrigin"
              (click)="onSignUpClick()"
            >
              <div class="sidebar-collapse__header-left">
                <app-toggle
                  [selected]="isSignUpEnabled()"
                  [orange]="true"
                  (click)="onSignUpToggleClick($event)"
                  style="margin-left: 10px;"
                ></app-toggle>
              </div>
              <div class="sidebar-collapse__header-main">
                <ng-container *ngIf="isSignUpEnabled()">
                  <div class="sidebar-collapse__header-title">
                    Sign Up page
                  </div>
                  <div
                    *ngIf="!signUpOpened"
                    class="sidebar-collapse__header-description"
                    [class.sidebar-collapse__header-description_wrap]="true"
                  >
                    {{ signUpLink }}
                  </div>
                </ng-container>

                <div *ngIf="!isSignUpEnabled()" class="sidebar-collapse__header-title">
                  Enable Sign Up page
                </div>
              </div>
              <div class="sidebar-collapse__header-right">
                <div
                  class="sidebar-list-item__action"
                  [class.icon-arrow_forward_2]="!signUpOpened"
                  [class.icon-arrow_down_2]="signUpOpened"
                ></div>
              </div>
            </div>

            <div *ngIf="signUpOpened" class="sidebar-collapse__content">
              <div class="sidebar__element">
                <div class="sidebar-field">
                  <div class="sidebar-field-layout-default">
                    <div class="sidebar-field-layout-default__label sidebar-field__label">
                      <div class="sidebar-field__label-main">
                        <span class="sidebar-field__label-title">
                          Sign Up URL
                          <a
                            href="javascript:void(0)"
                            class="field__label-link"
                            style="margin-left: 4px;"
                            (click)="copySignUpCode()"
                          >
                            <span class="field__label-link-icon icon-duplicate"></span>
                            Copy
                          </a>
                        </span>
                      </div>
                    </div>

                    <div class="sidebar-field-layout-default__content">
                      <div class="sidebar-field-layout-default__content-main">
                        <input
                          *ngIf="isSignUpEnabled() && signUpLink"
                          class="input input_fill"
                          [value]="signUpLink"
                          (click)="$event.target.select()"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sidebar__element">
                <app-sidebar-field [label]="'New members team'">
                  <app-project-group-control
                    [control]="projectSignUpForm.controls.default_group"
                    [fill]="true"
                    [environmentName]="currentProjectStore.instance.defaultEnvironment.uniqueName"
                  ></app-project-group-control>

                  <ng-container description>
                    This team will be assigned to all newly registered members
                  </ng-container>
                </app-sidebar-field>
              </div>

              <div class="sidebar__element">
                <app-sidebar-field [label]="'domain default app'" [layoutLeft]="true">
                  <app-auto-field
                    [form]="projectDomainUpdateForm"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'default_project',
                        field: 'BooleanField',
                        params: { style: booleanFieldStyle.Toggle, orange: true }
                      })
                    "
                  ></app-auto-field>

                  <ng-container description>
                    Redirect to current App when opening home page URL
                  </ng-container>
                </app-sidebar-field>
              </div>
            </div>
          </div>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>

    <app-sidebar-section [interactive]="!domain" (click)="onDomainRequiredClick()">
      <div class="sidebar__element">
        <app-sidebar-field [label]="'title'" [additional]="'(optional)'">
          <app-auto-field
            [form]="projectDomainUpdateForm"
            [label]="false"
            [field]="
              createField({
                name: 'sign_up_title',
                field: 'CharField',
                placeholder: 'Form title',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element">
        <app-sidebar-field [label]="'description'" [additional]="'(optional)'">
          <app-auto-field
            [form]="projectDomainUpdateForm"
            [label]="false"
            [field]="
              createField({
                name: 'sign_up_description',
                field: 'CharField',
                placeholder: 'Form description',
                params: { classes: ['input_fill'], multiline: true, rows: 4 }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>

    <app-sidebar-section
      [title]="'Form fields'"
      [interactive]="!domain"
      [disabled]="!domain"
      (click)="onDomainRequiredClick()"
    >
      <div *ngIf="builtInSignUpFields.length" class="sidebar__list">
        <div class="sidebar-list">
          <div *ngFor="let item of builtInSignUpFields" class="sidebar-list__item">
            <div
              class="sidebar-list-item"
              [class.sidebar-list-item_draggable]="false"
              [class.sidebar-list-item_disabled]="false"
            >
              <div class="sidebar-list-item__left">
                <div class="sidebar-list-item__icon" [ngClass]="'icon-' + item.icon"></div>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">
                  {{ item.label }}
                  <span *ngIf="item.optional" class="sidebar-list-item__title-description">&nbsp;(optional)</span>
                </div>
                <div class="sidebar-list-item__description">Built-in field</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar__list">
        <div class="sidebar-list__separator">
          <app-sidebar-list-separator [title]="'Custom fields'"></app-sidebar-list-separator>
        </div>

        <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
          <div *ngFor="let item of signUpFields" cdkDrag class="sidebar-list__item">
            <app-sign-up-field-control
              [control]="item.control"
              [property]="item.property"
              [analyticsSource]="analyticsSource"
              (edit)="editProperty($event.control, $event.property)"
              (remove)="settingsForm.controls.sign_up_fields.removeControl(item.control)"
            ></app-sign-up-field-control>
          </div>

          <div *ngIf="!signUpFields.length" cdkDrag class="sidebar-list__item">
            <app-project-property-menu-overlay
              [type]="projectPropertyTypes.User"
              [origin]="add_item_origin"
              [filterOptions]="filterPropertyOption"
              (propertySelect)="settingsForm.controls.sign_up_fields.appendControl($event.property)"
              (createPropertyClick)="createProperty()"
            ></app-project-property-menu-overlay>

            <div
              class="sidebar-list-item"
              [class.sidebar-list-item_clickable]="true"
              [class.sidebar-list-item_disabled]="true"
              cdkOverlayOrigin
              #add_item_origin="cdkOverlayOrigin"
            >
              <div class="sidebar-list-item__drag"></div>
              <div class="sidebar-list-item__left">
                <span class="sidebar-list-item__icon icon-earth_planet"></span>
              </div>
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">New Sign Up field</div>
                <div class="sidebar-list-item__description">Not specified</div>
              </div>
            </div>
          </div>

          <div class="sidebar-list__buttons">
            <app-project-property-menu-overlay
              [type]="projectPropertyTypes.User"
              [origin]="add_button_origin"
              [filterOptions]="filterPropertyOption"
              (propertySelect)="settingsForm.controls.sign_up_fields.appendControl($event.property)"
              (createPropertyClick)="createProperty()"
            ></app-project-property-menu-overlay>

            <a
              href="javascript:void(0)"
              class="button button_orange-transparent button_small sidebar-list__buttons-item"
              cdkOverlayOrigin
              #add_button_origin="cdkOverlayOrigin"
            >
              <span class="icon icon-plus button__icon button__icon_left"></span>
              <span class="button__label">Add Sign Up field</span>
            </a>
          </div>
        </div>
      </div>
    </app-sidebar-section>

    <app-sidebar-section>
      <div class="sidebar__element">
        <app-sidebar-field [label]="'page after sign up'">
          <app-customize-home
            [menuItems]="menuItems"
            [disabled]="!domain"
            [dropdownLeft]="true"
            [analyticsSource]="analyticsSource"
          ></app-customize-home>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element">
        <app-sidebar-field [label]="'terms of service URL'">
          <app-auto-field
            [form]="projectDomainUpdateForm"
            [label]="false"
            [field]="
              createField({
                name: 'terms_of_service',
                field: 'CharField',
                placeholder: 'https://www.jetadmin.io/terms-of-service',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element">
        <app-sidebar-field [label]="'privacy policy URL'">
          <app-auto-field
            [form]="projectDomainUpdateForm"
            [label]="false"
            [field]="
              createField({
                name: 'privacy_policy',
                field: 'CharField',
                placeholder: 'https://www.jetadmin.io/privacy-policy',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </ng-container>
</div>

<div class="list-item">
  {{ item.name }} <span *ngIf="handle" appDragHandle>(drag me)</span>
  <div
    *ngIf="item.children"
    appDropList
    [appDropListData]="item.children"
    class="container"
    (appDropListDropped)="dropped.emit($event)"
  >
    <app-playground-item
      *ngFor="let subitem of item.children"
      appDrag
      [appDragData]="subitem"
      [item]="subitem"
      [handle]="handle"
      [children]="children"
      (dropped)="dropped.emit($event)"
    ></app-playground-item>
  </div>
</div>

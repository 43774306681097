import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ViewContext } from '@modules/customize';
import { Frame, Layer, View } from '@modules/views';
import { controlValue, isSet } from '@shared';
import { FillControl } from '../../controls/fill.control';
var FillColorButtonComponent = /** @class */ (function () {
    function FillColorButtonComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.openedChange = new EventEmitter();
        this.colorOpened = false;
        this.colorPreviewSize = new Frame({ width: 32 - 2, height: 26 - 2 });
        this.colorPositions = [
            { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
        ];
    }
    FillColorButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.control)
            .pipe(switchMap(function () {
            var instance = _this.control.getInstance();
            var css$ = instance.css$({ frame: _this.colorPreviewSize, context: _this.viewContext });
            var iconFill$ = instance.iconFill
                ? instance.iconFill.display$({ context: _this.viewContext }).pipe(map(function (value) {
                    return {
                        icon: value.icon,
                        color: value.color
                    };
                }))
                : of(undefined);
            return combineLatest(css$, iconFill$);
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var css = _a[0], icon = _a[1];
            _this.colorBackground = isSet(css.background)
                ? _this.sanitizer.bypassSecurityTrustStyle(css.background)
                : undefined;
            _this.colorWidth = css.width;
            _this.colorHeight = css.height;
            _this.colorTransform = isSet(css.transform) ? _this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined;
            _this.colorIcon = icon;
            _this.cd.detectChanges();
        });
    };
    FillColorButtonComponent.prototype.ngOnDestroy = function () { };
    FillColorButtonComponent.prototype.setOpened = function (value) {
        this.colorOpened = value;
        this.cd.markForCheck();
        this.openedChange.emit();
    };
    FillColorButtonComponent.prototype.open = function () {
        this.setOpened(true);
    };
    FillColorButtonComponent.prototype.close = function () {
        this.setOpened(false);
    };
    FillColorButtonComponent.prototype.isOpened = function () {
        return this.colorOpened;
    };
    return FillColorButtonComponent;
}());
export { FillColorButtonComponent };

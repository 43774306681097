import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { AutomationTriggerType } from './automation-trigger-type';
import { getAutomationTriggerByType } from './automation-trigger-types';
import { AutomationTrigger } from './automation-triggers';
import { Workflow } from './workflow';
import { WorkflowBackendRun } from './workflow-backend-run';

export class Automation {
  public uid: string;
  public name: string;
  public trigger: AutomationTrigger;
  public workflow: Workflow;
  public lastRun: WorkflowBackendRun;
  public draft = false;
  public deleted = false;
  public active = false;
  public dateAdd: any;

  constructor(options: Partial<Automation> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.name = data['name'];
    this.draft = data['draft'];
    this.deleted = data['deleted'];
    this.active = data['active'];
    this.dateAdd = moment(data['date_add']);

    const triggerTypeCls = getAutomationTriggerByType(data['trigger_type']);
    if (triggerTypeCls) {
      this.trigger = new triggerTypeCls().deserialize(data['trigger_params']);
    } else {
      this.trigger = undefined;
    }

    if (data['workflow']) {
      if (typeof data['workflow'] === 'string') {
        this.workflow = new Workflow({ uid: data['workflow'] });
      } else {
        this.workflow = new Workflow().deserialize({
          ...data['workflow']['params'],
          uid: data['workflow']['uid']
        });
      }
    } else {
      this.workflow = undefined;
    }

    if (data['last_run']) {
      this.lastRun = new WorkflowBackendRun().deserialize(data['last_run']);
    } else {
      this.lastRun = undefined;
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      name: this.name,
      trigger_type: this.trigger ? this.trigger.type : undefined,
      trigger_params: this.trigger ? this.trigger.serialize() : undefined,
      workflow: this.workflow
        ? {
            params: this.workflow.serialize()
          }
        : undefined,
      draft: this.draft,
      deleted: this.deleted,
      active: this.trigger && this.trigger.type == AutomationTriggerType.Manual ? true : this.active
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  getStatusLabel(): string {
    const lastRunStatus = this.lastRun ? this.lastRun.getStatusLabel() : undefined;

    if (lastRunStatus) {
      return lastRunStatus;
    } else {
      return 'not run';
    }
  }
}

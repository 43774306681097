/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./query-builder-object-structure-node.component";
var styles_QueryBuilderObjectStructureNodeComponent = [];
var RenderType_QueryBuilderObjectStructureNodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderObjectStructureNodeComponent, data: {} });
export { RenderType_QueryBuilderObjectStructureNodeComponent as RenderType_QueryBuilderObjectStructureNodeComponent };
function View_QueryBuilderObjectStructureNodeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ROOT"]))], null, null); }
function View_QueryBuilderObjectStructureNodeComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nodeName || "No name"); _ck(_v, 1, 0, currVal_0); }); }
function View_QueryBuilderObjectStructureNodeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "structure-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "structure-item__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"]], [[2, "sidebar-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "button button_icon button_orange button_tiny"], ["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["{ }"])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 7, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_4)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" \u00A0"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "sidebar-list-item__title-description"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["OBJECT (", " items)"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.deep == 0); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.deep > 0); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodeSelected; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.nodeObjectItems.length; _ck(_v, 15, 0, currVal_3); }); }
function View_QueryBuilderObjectStructureNodeComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "a", [["class", "structure-item__dash-toggle icon-plus"], ["href", "javascript:void(0)"]], [[2, "structure-item__dash-toggle_active", null], [2, "icon-plus", null], [2, "icon-minus", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.childCollapsed[_v.parent.context.index]; var currVal_1 = _co.childCollapsed[_v.parent.context.index]; var currVal_2 = !_co.childCollapsed[_v.parent.context.index]; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_QueryBuilderObjectStructureNodeComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "structure-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "structure-item__dash"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "structure-item__dash-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "structure-item__dash-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_7)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "structure-item__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-query-builder-object-structure-node", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.childSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QueryBuilderObjectStructureNodeComponent_0, RenderType_QueryBuilderObjectStructureNodeComponent)), i0.ɵdid(9, 770048, null, 0, i2.QueryBuilderObjectStructureNodeComponent, [], { node: [0, "node"], nodeName: [1, "nodeName"], path: [2, "path"], pathSelected: [3, "pathSelected"], skipContent: [4, "skipContent"], deep: [5, "deep"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.type == _co.nodeTypes.Object) || (_v.context.$implicit.type == _co.nodeTypes.Array)); _ck(_v, 6, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; var currVal_2 = _v.context.$implicit.key; var currVal_3 = _co.nodePath; var currVal_4 = _co.pathSelected; var currVal_5 = _co.childCollapsed[_v.context.index]; var currVal_6 = (_co.deep + 1); _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_6)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodeObjectItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.skipSelf; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.skipContent; _ck(_v, 4, 0, currVal_1); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ROOT"]))], null, null); }
function View_QueryBuilderObjectStructureNodeComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nodeName || "No name"); _ck(_v, 1, 0, currVal_0); }); }
function View_QueryBuilderObjectStructureNodeComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "structure-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "structure-item__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"]], [[2, "sidebar-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "button button_icon button_orange button_tiny"], ["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["[ ]"])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 7, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_10)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_11)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" \u00A0"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "sidebar-list-item__title-description"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["ARRAY (", " items)"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.deep == 0); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.deep > 0); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodeSelected; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.nodeArrayItems.length; _ck(_v, 15, 0, currVal_3); }); }
function View_QueryBuilderObjectStructureNodeComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-query-builder-object-structure-node", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.childSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QueryBuilderObjectStructureNodeComponent_0, RenderType_QueryBuilderObjectStructureNodeComponent)), i0.ɵdid(2, 770048, null, 0, i2.QueryBuilderObjectStructureNodeComponent, [], { node: [0, "node"], nodeName: [1, "nodeName"], path: [2, "path"], pathSelected: [3, "pathSelected"], skipSelf: [4, "skipSelf"], deep: [5, "deep"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _v.parent.context.index.toString(); var currVal_2 = _co.nodePath; var currVal_3 = _co.pathSelected; var currVal_4 = true; var currVal_5 = (_co.deep + 1); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "structure-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "structure-item__dash structure-item__dash_last"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "structure-item__dash-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "structure-item__dash-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "structure-item__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-query-builder-object-structure-node", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.childSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QueryBuilderObjectStructureNodeComponent_0, RenderType_QueryBuilderObjectStructureNodeComponent)), i0.ɵdid(6, 770048, null, 0, i2.QueryBuilderObjectStructureNodeComponent, [], { node: [0, "node"], nodeName: [1, "nodeName"], path: [2, "path"], pathSelected: [3, "pathSelected"], deep: [4, "deep"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _v.parent.context.index.toString(); var currVal_2 = _co.nodePath; var currVal_3 = _co.pathSelected; var currVal_4 = (_co.deep + 1); _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_14)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_15)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit.type == _co.nodeTypes.Object) || (_v.context.$implicit.type == _co.nodeTypes.Array)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == _co.nodeTypes.Field); _ck(_v, 4, 0, currVal_1); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_13)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodeArrayItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_9)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_12)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.skipSelf; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.skipContent; _ck(_v, 4, 0, currVal_1); }, null); }
function View_QueryBuilderObjectStructureNodeComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ROOT"]))], null, null); }
function View_QueryBuilderObjectStructureNodeComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nodeName || "No name"); _ck(_v, 1, 0, currVal_0); }); }
function View_QueryBuilderObjectStructureNodeComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"]], [[2, "sidebar-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button button_icon button_orange button_tiny"], ["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "icon button__icon icon-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_17)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_18)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" \u00A0"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "sidebar-list-item__title-description"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.deep == 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.deep > 0); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nodeSelected; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.node; _ck(_v, 13, 0, currVal_3); }); }
export function View_QueryBuilderObjectStructureNodeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureNodeComponent_16)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.nodeType == _co.nodeTypes.Object); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.nodeType == _co.nodeTypes.Array); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.nodeType == _co.nodeTypes.Field); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_QueryBuilderObjectStructureNodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-object-structure-node", [], null, null, null, View_QueryBuilderObjectStructureNodeComponent_0, RenderType_QueryBuilderObjectStructureNodeComponent)), i0.ɵdid(1, 770048, null, 0, i2.QueryBuilderObjectStructureNodeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderObjectStructureNodeComponentNgFactory = i0.ɵccf("app-query-builder-object-structure-node", i2.QueryBuilderObjectStructureNodeComponent, View_QueryBuilderObjectStructureNodeComponent_Host_0, { node: "node", nodeName: "nodeName", path: "path", pathSelected: "pathSelected", skipSelf: "skipSelf", skipContent: "skipContent", deep: "deep" }, { selected: "selected" }, []);
export { QueryBuilderObjectStructureNodeComponentNgFactory as QueryBuilderObjectStructureNodeComponentNgFactory };

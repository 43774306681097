var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent } from '@modules/fields';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { NumberFieldViewParamsForm } from './number-field-view-params.form';
var NumberFieldViewParamsComponent = /** @class */ (function (_super) {
    __extends(NumberFieldViewParamsComponent, _super);
    function NumberFieldViewParamsComponent(form, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.cd = cd;
        _this.createField = createFormFieldFactory();
        _this.editable = false;
        _this.fieldTypes = FieldType;
        return _this;
    }
    NumberFieldViewParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control);
        if (this.field$) {
            this.field$.pipe(untilDestroyed(this)).subscribe(function (field) {
                _this.editable = (!_this.configurable || _this.configurable.editable) && field && field['editable'];
                _this.cd.markForCheck();
            });
        }
    };
    NumberFieldViewParamsComponent.prototype.ngOnDestroy = function () { };
    return NumberFieldViewParamsComponent;
}(FieldParamsComponent));
export { NumberFieldViewParamsComponent };
registerFieldViewParamsComponent(FieldType.Number, NumberFieldViewParamsComponent);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../compact-wide-button/compact-wide-button.component.ngfactory";
import * as i2 from "../compact-wide-button/compact-wide-button.component";
import * as i3 from "./interactions-section-pressed-output.component";
var styles_InteractionsSectionPressedOutputComponent = [];
var RenderType_InteractionsSectionPressedOutputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InteractionsSectionPressedOutputComponent, data: {} });
export { RenderType_InteractionsSectionPressedOutputComponent as RenderType_InteractionsSectionPressedOutputComponent };
export function View_InteractionsSectionPressedOutputComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compact-wide-button", [], null, [[null, "rightClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rightClick" === en)) {
        var pd_0 = (_co.arrayControl.removeControl(_co.control) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CompactWideButtonComponent_0, i1.RenderType_CompactWideButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.CompactWideButtonComponent, [], { label: [0, "label"], leftIcon: [1, "leftIcon"], leftBackground: [2, "leftBackground"], rightIcon: [3, "rightIcon"], rightInteractive: [4, "rightInteractive"], rightTooltip: [5, "rightTooltip"] }, { rightClick: "rightClick" })], function (_ck, _v) { var currVal_0 = "Track pressed state"; var currVal_1 = "select_all"; var currVal_2 = true; var currVal_3 = "close"; var currVal_4 = true; var currVal_5 = "Stop tracking"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_InteractionsSectionPressedOutputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-interactions-section-pressed-output", [], null, null, null, View_InteractionsSectionPressedOutputComponent_0, RenderType_InteractionsSectionPressedOutputComponent)), i0.ɵdid(1, 114688, null, 0, i3.InteractionsSectionPressedOutputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractionsSectionPressedOutputComponentNgFactory = i0.ɵccf("app-interactions-section-pressed-output", i3.InteractionsSectionPressedOutputComponent, View_InteractionsSectionPressedOutputComponent_Host_0, { control: "control", arrayControl: "arrayControl" }, {}, []);
export { InteractionsSectionPressedOutputComponentNgFactory as InteractionsSectionPressedOutputComponentNgFactory };

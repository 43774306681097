import pickBy from 'lodash/pickBy';

import { ListLayout, listLayouts, ListLayoutType } from '@modules/layouts';

import { ListLayoutSettings } from '../../list-layout-settings';
import { TableSettings } from '../../table-settings';
import { getListLayoutSettingsClass } from '../../view-settings/list';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class ListElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.List;
  public height: number;
  public layouts: ListLayoutSettings[] = [];

  deserialize(data: Object): ListElementItem {
    super.deserialize(data);
    this.height = this.params['height'] || 600;

    if (this.params['title']) {
      this.name = this.params['title'];
    }

    if (this.params['layouts'] || this.params['layout']) {
      const layoutsData = this.params['layouts'] || [this.params['layout']];
      this.layouts = layoutsData
        .map(item => {
          if (typeof item == 'string') {
            item = { type: item };
          }

          const cls = getListLayoutSettingsClass(item['type']);

          if (!cls) {
            return;
          }

          if (item['title'] === undefined && this.name) {
            item['title'] = this.name;
          }

          return new cls().deserialize(item);
        })
        .filter(item => item != undefined);
    } else {
      this.layouts = [new TableSettings()];
    }

    if (this.params['default_layout']) {
      this.layouts = this.layouts.sort((lhs, rhs) => {
        const defaultType = this.params['default_layout'];
        if (lhs.type == defaultType && rhs.type != defaultType) {
          return -1;
        } else if (lhs.type != defaultType && rhs.type == defaultType) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    if (this.params['table_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Table);
      if (settings) {
        settings.deserialize(this.params['table_settings']);
      }
    }

    if (this.params['map_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Map);
      if (settings) {
        settings.deserialize(this.params['map_settings']);
      }
    }

    if (this.params['kanban_board_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.KanbanBoard);
      if (settings) {
        settings.deserialize(this.params['kanban_board_settings']);
      }
    }

    if (this.params['calendar_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Calendar);
      if (settings) {
        settings.deserialize(this.params['calendar_settings']);
      }
    }

    if (this.params['grid_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Grid);
      if (settings) {
        settings.deserialize(this.params['grid_settings']);
      }
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['height'] = this.height;
    this.params['layouts'] = this.layouts.map(item => item.serialize());

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  getOrCreateLayout<T extends ListLayoutSettings = ListLayoutSettings>(layout: ListLayoutType): T {
    const settings = this.layouts.find(item => item.type == layout);

    if (settings) {
      return settings as T;
    } else {
      const cls = getListLayoutSettingsClass(layout);
      const instance = new cls();
      this.layouts = [...this.layouts, instance];
      return instance as T;
    }
  }

  get listLayout(): ListLayout {
    if (!this.layouts[0]) {
      return;
    }

    return listLayouts.find(item => item.type == this.layouts[0].type);
  }

  get typeStr(): string {
    const layout = this.listLayout;
    return layout ? layout.description : 'list';
  }

  get analyticsName(): string {
    const layout = this.layouts[0];

    if (!layout) {
      return;
    }

    return layout.analyticsName;
  }

  defaultName() {
    if (!this.layouts[0]) {
      return 'list';
    }

    const type = this.layouts[0].type;

    if (type == ListLayoutType.Table) {
      return 'Table';
    } else if (type == ListLayoutType.Map) {
      return 'Map';
    } else if (type == ListLayoutType.KanbanBoard) {
      return 'Kanban Board';
    } else if (type == ListLayoutType.Calendar) {
      return 'Calendar';
    } else if (type == ListLayoutType.Grid) {
      return 'Gallery';
    } else if (type == ListLayoutType.Carousel) {
      return 'Carousel';
    } else if (type == ListLayoutType.Timeline) {
      return 'Timeline';
    }
  }
}

registerElementForType(ElementType.List, ListElementItem);

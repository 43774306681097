/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./section-column.component";
var styles_SectionColumnComponent = [];
var RenderType_SectionColumnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionColumnComponent, data: {} });
export { RenderType_SectionColumnComponent as RenderType_SectionColumnComponent };
export function View_SectionColumnComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_SectionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section-column", [], [[2, "section__column", null], [2, "section__column_size_s", null]], null, null, View_SectionColumnComponent_0, RenderType_SectionColumnComponent)), i0.ɵdid(1, 114688, null, 0, i1.SectionColumnComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).sizeS; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var SectionColumnComponentNgFactory = i0.ɵccf("app-section-column", i1.SectionColumnComponent, View_SectionColumnComponent_Host_0, { size: "size" }, {}, ["*"]);
export { SectionColumnComponentNgFactory as SectionColumnComponentNgFactory };

import { Directive, Input } from '@angular/core';

import { Domain } from '@modules/domain';

import { ThemeOptions } from '../../data/theme-options';
import { ThemeOptionsDirective } from '../theme-options/theme-options.directive';

@Directive({
  selector: '[appDomainThemeOptions]'
})
export class DomainThemeOptionsDirective extends ThemeOptionsDirective {
  @Input() domain: Domain;

  getThemeOptions(): ThemeOptions {
    if (this.domain) {
      return {
        accentColor: this.domain.signUpAccentColor,
        fontRegular: this.domain.signUpFontRegular,
        fontHeading: this.domain.signUpFontHeading
      };
    } else {
      return {
        accentColor: this.accentColor,
        fontRegular: this.fontRegular,
        fontHeading: this.fontHeading
      };
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';

import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { Project } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

import { CompanyInfoForm } from './company-info.form';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  providers: [CompanyInfoForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Output() closed = new EventEmitter<void>();

  loading = false;

  constructor(
    public form: CompanyInfoForm,
    private popupComponent: BasePopupComponent,
    private currentUserStore: CurrentUserStore,
    private analyticsService: UniversalAnalyticsService,
    private intercomService: IntercomService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.project);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.OnboardingCompanyInfo);
  }

  ngOnDestroy(): void {}

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        company => {
          this.close();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.OnboardingCompanyInfoSuccess, {
            CompanyName: company.name,
            CompanyIndustry: company.industry,
            CompanyRole: company.role,
            CompanySize: company.size
          });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  skip() {
    this.currentUserStore.instance.params.addProjectCompanyInfoShowed(this.project.uniqueName);
    this.currentUserStore.update(['params']).subscribe();
    this.close();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.OnboardingCompanyInfoSkip);
  }

  openChat() {
    this.intercomService.openChat();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.OnboardingCompanyInfoContact);
  }

  close() {
    this.popupComponent.close();
    this.closed.emit();
  }
}

<form [formGroup]="form.form" (ngSubmit)="submit()">
  <app-popup2 [size]="'m'">
    <app-popup2-header>
      <app-popup2-title>Create a Team</app-popup2-title>
      <app-popup2-description>
        Group users into Teams to grant permissions and assign properties.
      </app-popup2-description>
    </app-popup2-header>

    <app-page-block>
      <app-auto-field
        [form]="form.form"
        [autofocus]="true"
        [field]="
          createField({
            name: 'name',
            label: 'Team Name',
            field: 'CharField',
            required: true,
            params: { classes: ['input_fill'] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-page-block>
      <app-form-errors [form]="form.form" [manualMargin]="true"></app-form-errors>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <a
            href="javascript:void(0)"
            class="button popup2__footer-item"
            [class.button_disabled]="loading"
            (click)="close()"
          >
            Cancel
          </a>

          <button
            type="submit"
            class="button button_primary popup2__footer-item"
            [class.button_disabled]="form.form.invalid || form.form.pending || loading"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Create</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>

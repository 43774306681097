<form [formGroup]="form.form" (ngSubmit)="submit()" style="width: 100%; max-width: 720px;">
  <div class="title">Reset Interface Settings</div>
  <div class="description">Select interface settings you want to reset</div>

  <!--  <app-select-user-interface-->
  <!--    [project]="currentProjectStore.instance"-->
  <!--    formControlName="interface_settings"-->
  <!--  ></app-select-user-interface>-->

  <div class="description">
    <strong>Warning!</strong> The selected interface settings will be deleted in app project and can't be restored
  </div>

  <div class="change__submit">
    <button
      type="submit"
      class="button button_primary button_danger"
      [disabled]="form.form.invalid || form.form.disabled"
      style="margin-right: 10px;"
    >
      <span class="button__label">Submit</span>
    </button>
    <a href="javascript:void(0)" class="button" (click)="cancel()">
      <span class="button__label">Cancel</span>
    </a>
  </div>
</form>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var CryptService = /** @class */ (function () {
    function CryptService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    CryptService.prototype.encrypt = function (project, environment, token, message) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/" + environment.uniqueName + "/encrypt/");
            var headers = new HttpHeaders();
            var data = {
                message: message
            };
            if (token) {
                data['project_token'] = token.token;
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result['result']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CryptService.prototype.decrypt = function (project, environment, token, message) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/" + environment.uniqueName + "/decrypt/");
            var headers = new HttpHeaders();
            var data = {
                message: message
            };
            if (token) {
                data['project_token'] = token.token;
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result['result']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CryptService.ngInjectableDef = i0.defineInjectable({ factory: function CryptService_Factory() { return new CryptService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: CryptService, providedIn: "root" });
    return CryptService;
}());
export { CryptService };

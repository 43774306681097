/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../shared/pipes/deploy-url/deploy-url.pipe";
import * as i3 from "../../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i4 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i5 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i6 from "./change-customize-bar-templates-select-resource.component";
var styles_ChangeCustomizeBarTemplatesSelectResourceComponent = [];
var RenderType_ChangeCustomizeBarTemplatesSelectResourceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeCustomizeBarTemplatesSelectResourceComponent, data: {} });
export { RenderType_ChangeCustomizeBarTemplatesSelectResourceComponent as RenderType_ChangeCustomizeBarTemplatesSelectResourceComponent };
function View_ChangeCustomizeBarTemplatesSelectResourceComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [["class", "sidebar-list-item__icon"]], [[8, "src", 4]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 0), (("/assets/images/resources/icons/" + _v.parent.context.$implicit.image) + ".svg"))); _ck(_v, 0, 0, currVal_0); }); }
function View_ChangeCustomizeBarTemplatesSelectResourceComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "sidebar-list-item__icon icon-bank"]], null, null, null, null, null))], null, null); }
function View_ChangeCustomizeBarTemplatesSelectResourceComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), (_v.parent.context.$implicit.featuresDescription || _v.parent.context.$implicit.shortDescription))); _ck(_v, 1, 0, currVal_0); }); }
function View_ChangeCustomizeBarTemplatesSelectResourceComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "sidebar-list-item sidebar-list-item_clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "span", [["class", "sidebar-list-item__action icon-arrow_forward_2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_v.context.$implicit.image; _ck(_v, 6, 0, currVal_1); var currVal_3 = (_v.context.$implicit.featuresDescription || _v.context.$implicit.shortDescription); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 9, 0, currVal_2); }); }
function View_ChangeCustomizeBarTemplatesSelectResourceComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar__message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Nothing here\n"]))], null, null); }
export function View_ChangeCustomizeBarTemplatesSelectResourceComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.DeployUrlPipe, []), i0.ɵpid(0, i3.CapitalizePipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 5, "app-sidebar-section", [], null, null, null, i4.View_SidebarSectionComponent_0, i4.RenderType_SidebarSectionComponent)), i0.ɵdid(3, 114688, null, 0, i5.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(4, 0, null, 3, 3, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "sidebar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_1)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.items; _ck(_v, 7, 0, currVal_0); var currVal_1 = !((_co.items == null) ? null : _co.items.length); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ChangeCustomizeBarTemplatesSelectResourceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-change-customize-bar-templates-select-resource", [], null, null, null, View_ChangeCustomizeBarTemplatesSelectResourceComponent_0, RenderType_ChangeCustomizeBarTemplatesSelectResourceComponent)), i0.ɵdid(1, 770048, null, 0, i6.ChangeCustomizeBarTemplatesSelectResourceComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeCustomizeBarTemplatesSelectResourceComponentNgFactory = i0.ɵccf("app-change-customize-bar-templates-select-resource", i6.ChangeCustomizeBarTemplatesSelectResourceComponent, View_ChangeCustomizeBarTemplatesSelectResourceComponent_Host_0, { group: "group", resourceType: "resourceType" }, { selected: "selected" }, []);
export { ChangeCustomizeBarTemplatesSelectResourceComponentNgFactory as ChangeCustomizeBarTemplatesSelectResourceComponentNgFactory };

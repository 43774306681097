var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { GuideUtilsService } from '../../services/guide-utils/guide-utils.service';
import { Task } from '../task';
import { TaskScenario } from '../task-scenario';
import * as i0 from "@angular/core";
import * as i1 from "../../services/guide-utils/guide-utils.service";
var CreateSegmentSqlTask = /** @class */ (function (_super) {
    __extends(CreateSegmentSqlTask, _super);
    function CreateSegmentSqlTask(guideUtilsService) {
        var _this = _super.call(this) || this;
        _this.guideUtilsService = guideUtilsService;
        return _this;
    }
    CreateSegmentSqlTask.prototype.init = function () {
        return this.guideUtilsService.getAnyModelDescription().pipe(map(function (modelDescription) {
            if (!modelDescription) {
                return;
            }
            return new TaskScenario([
                {
                    link: ['resources', modelDescription.resource, 'models', modelDescription.model, 'segments'],
                    items: []
                }
            ]);
        }));
    };
    CreateSegmentSqlTask.ngInjectableDef = i0.defineInjectable({ factory: function CreateSegmentSqlTask_Factory() { return new CreateSegmentSqlTask(i0.inject(i1.GuideUtilsService)); }, token: CreateSegmentSqlTask, providedIn: "root" });
    return CreateSegmentSqlTask;
}(Task));
export { CreateSegmentSqlTask };

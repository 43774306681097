var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { LocalStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { fieldsEditItemFromParameterField, fieldsEditItemToParameterField } from '@modules/field-components';
// TODO: Fix import
import { BooleanFieldStyle } from '@modules/field-components/components/boolean-field/boolean-field.component';
import { createFormFieldFactory, ParameterArray, ParameterField } from '@modules/fields';
import { Resource, ResourceName } from '@modules/projects';
import { HttpContentType, HttpQueryService, HttpResponseType, QueryService } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import { controlValue, filterObject, isSet, JsFunctionError, limitObjectLength } from '@shared';
import { QueryBuilderContext } from '../../data/query-builder-context';
import { InputTokensEventType } from '../input-tokens/input-tokens.component';
import { QueryBuilderForm } from '../query-builder/query-builder.form';
import { QueryBuilderHttpForm } from './query-builder-http.form';
export var HttpResultsSection;
(function (HttpResultsSection) {
    HttpResultsSection["Parameters"] = "parameters";
    HttpResultsSection["Http"] = "http";
    HttpResultsSection["Result"] = "result";
})(HttpResultsSection || (HttpResultsSection = {}));
var SideBarSection;
(function (SideBarSection) {
    SideBarSection["Sorting"] = "sorting";
    SideBarSection["Pagination"] = "pagination";
    SideBarSection["ErrorTransformer"] = "error_transformer";
})(SideBarSection || (SideBarSection = {}));
var queryBuilderHttpOptionsDefaults = {
    enabledBodyTypes: [
        HttpContentType.JSON,
        HttpContentType.FormUrlEncoded,
        HttpContentType.FormData,
        HttpContentType.Raw
    ]
};
var QueryBuilderSection;
(function (QueryBuilderSection) {
    QueryBuilderSection[QueryBuilderSection["QueryParameters"] = 0] = "QueryParameters";
    QueryBuilderSection[QueryBuilderSection["Result"] = 1] = "Result";
    QueryBuilderSection[QueryBuilderSection["SideBar"] = 2] = "SideBar";
})(QueryBuilderSection || (QueryBuilderSection = {}));
export var HTTP_BUILDER_REQUEST_BUFFER_KEY = 'http_builder_request_buffer';
var QueryBuilderHttpComponent = /** @class */ (function () {
    function QueryBuilderHttpComponent(form, queryService, httpQueryService, localStorage, notificationService, dialogService, analyticsService, cd) {
        var _this = this;
        this.form = form;
        this.queryService = queryService;
        this.httpQueryService = httpQueryService;
        this.localStorage = localStorage;
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.requireResponse = false;
        this.arrayResponse = false;
        this.customSections = [];
        this.executed = new EventEmitter();
        this.saved = new EventEmitter();
        this.canceled = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.requestCreate = false;
        this.responseActual = false;
        this.resultsSection = HttpResultsSection.Parameters;
        this.resultsSections = HttpResultsSection;
        this.sideBarSections = SideBarSection;
        this.customSectionComponentsTop = [];
        this.customSectionComponentsBottom = [];
        this.customSectionComponentsPreview = [];
        this.booleanFieldStyle = BooleanFieldStyle;
        this.fieldsEditItemToParameterField = fieldsEditItemToParameterField;
        this.fieldsEditItemFromParameterField = fieldsEditItemFromParameterField;
        this.httpResponseTypes = HttpResponseType;
        this.resourceNames = ResourceName;
        this.queryBuilderSections = QueryBuilderSection;
        this.saveHovered = new BehaviorSubject(false);
        this.requireResponseMessageHovered = new BehaviorSubject(false);
        this.requireResponseMessageVisible$ = combineLatest(this.saveHovered.pipe(filter(function (value) { return (value && _this.isResponseMissing()) || !value; }), debounce(function (value) { return timer(value ? 0 : 200); })), this.requireResponseMessageHovered).pipe(map(function (_a) {
            var saveHovered = _a[0], requireResponseMessageHovered = _a[1];
            return saveHovered || requireResponseMessageHovered;
        }));
    }
    QueryBuilderHttpComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = this.control.value;
        this.form.init(this.resource, query, this.currentOptions, this.parametersControl, this.context.useFileObjects);
        this.context.httpForm = this.form;
        this.context.paginationTokens = this.queryForm.listQuery;
        this.context.sortingTokens = this.queryForm.listQuery;
        if (query) {
            this.requestCreate = false;
            this.context.tokenValues = query.requestTokens;
            if (query.requestResponse !== undefined) {
                this.responseBody = query.requestResponse;
                this.updateResponseBody(query, query.requestTokens);
                this.responseActual = true;
                this.cd.markForCheck();
            }
        }
        else {
            this.requestCreate = true;
            this.context.tokenValues = {};
        }
        this.context.tokenValues$.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateRequestParameters(); });
        if (isSet(this.currentOptions.initialResultsSection)) {
            this.resultsSection = this.currentOptions.initialResultsSection;
        }
        else {
            this.resultsSection = this.responseBodyProcessed ? HttpResultsSection.Result : HttpResultsSection.Parameters;
        }
        this.cd.markForCheck();
        merge.apply(void 0, values(this.form.controls)
            .filter(function (control) {
            return [_this.form.controls.request_response, _this.form.controls.request_tokens].every(function (item) { return item !== control; });
        })
            .map(function (control) { return control.valueChanges; }).concat((this.parametersControl ? [this.parametersControl.valueChanges] : []))).pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function () {
            _this.updateRequestParameters();
            _this.responseActual = false;
            _this.cd.markForCheck();
        });
        controlValue(this.form)
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function () {
            _this.query = _this.form.getInstance();
            _this.cd.markForCheck();
        });
        this.form.controls.response_path.valueChanges.pipe(debounceTime(60), untilDestroyed(this)).subscribe(function () {
            var currentQuery = _this.form.getInstance();
            _this.updateResponseBodyProcessed(currentQuery);
        });
        this.updateRequestParameters();
        this.updateCustomSections();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.StartToSetUp, {
            ResourceType: this.resource.typeItem.name,
            HTTPQueryType: this.httpQueryType,
            Source: this.source
        });
    };
    QueryBuilderHttpComponent.prototype.ngOnDestroy = function () { };
    QueryBuilderHttpComponent.prototype.ngOnChanges = function (changes) { };
    Object.defineProperty(QueryBuilderHttpComponent.prototype, "currentOptions", {
        get: function () {
            return __assign({}, queryBuilderHttpOptionsDefaults, this.options);
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderHttpComponent.prototype.setPerPage = function (length) {
        if (isSet(this.queryForm.controls.pagination_per_page.value) || length === 0) {
            return;
        }
        this.queryForm.controls.pagination_per_page.patchValue(length);
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.updateRequestParameters = function () {
        var query = this.form.getInstance();
        try {
            this.requestParameters = this.httpQueryService.prepareParameters(query, {
                baseQuery: this.currentOptions.baseQuery,
                tokens: this.context.serializeTokenValues()
            });
            this.cd.markForCheck();
        }
        catch (e) {
            this.requestParameters = undefined;
            this.cd.markForCheck();
        }
    };
    QueryBuilderHttpComponent.prototype.setResultsSection = function (section) {
        this.resultsSection = section;
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.updateCustomSections = function () {
        this.customSectionComponentsTop = this.customSections
            .filter(function (item) { return item.position == 'top'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsBottom = this.customSections
            .filter(function (item) { return item.position == 'bottom'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsPreview = this.customSections
            .filter(function (item) { return item.position == 'preview'; })
            .map(function (item) { return item.component; });
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.applyTransformer = function (query, tokens, body) {
        if (query.responseTransformer) {
            try {
                return this.queryService.applyTransformer(body, query.responseTransformer, query.url, true, tokens);
            }
            catch (e) {
                var error = new JsFunctionError(e);
                this.notificationService.error('Transformation Failed', "\n                    Response transformer failed:<br>\n                    [" + (error.line - 2) + ":" + error.column + "] " + error.message + "\n                ");
            }
        }
        else {
            return body;
        }
    };
    QueryBuilderHttpComponent.prototype.updateResponseBodyTransformed = function (query, tokens) {
        this.responseBodyTransformed = this.applyTransformer(query, tokens, this.responseBody);
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.updateResponseBodyProcessed = function (query) {
        this.responseBodyProcessed = this.queryService.getPath(this.responseBodyTransformed, query.responsePath);
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.updateResponseBody = function (query, tokens) {
        this.updateResponseBodyTransformed(query, tokens);
        this.updateResponseBodyProcessed(query);
    };
    QueryBuilderHttpComponent.prototype.execute = function () {
        var _this = this;
        var query = this.form.getInstance();
        var requestTokens = this.context.serializeTokenValues();
        var saveTokens = this.context.serializeTokenValues(true);
        var resourceParams = this.resource
            ? this.resource.parseParams(RestAPIResourceParams)
            : undefined;
        this.loading = true;
        this.response = undefined;
        this.responseBody = undefined;
        this.responseBodyTransformed = undefined;
        this.responseBodyProcessed = undefined;
        this.resultsSection = HttpResultsSection.Result;
        this.cd.markForCheck();
        try {
            this.httpQueryService
                .request(query, {
                resource: this.resource ? this.resource.uniqueName : undefined,
                baseQuery: this.currentOptions.baseQuery,
                tokens: requestTokens,
                raiseErrors: true,
                customProxy: resourceParams ? resourceParams.customProxy : undefined
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (response) {
                _this.response = response;
                _this.responseActual = true;
                _this.loading = false;
                _this.cd.markForCheck();
                _this.responseBody = response.body;
                if (isArray(_this.responseBodyProcessed)) {
                    _this.setPerPage(_this.responseBodyProcessed.length);
                }
                _this.cd.markForCheck();
                _this.updateResponseBody(query, requestTokens);
                _this.context.lastExecutedResponse = _this.responseBodyProcessed;
                _this.form.controls.request_response.patchValue(limitObjectLength(_this.responseBody, 20));
                _this.form.controls.request_tokens.patchValue(filterObject(saveTokens, function (item) { return !(item instanceof Blob); }));
                _this.executed.emit(query);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.SuccessfullyPerformed, {
                    ResourceType: _this.resource.typeItem.name,
                    HTTPQueryType: _this.httpQueryType
                });
            }, function (e) {
                if (e.response && e.response instanceof HttpErrorResponse) {
                    _this.response = e.response;
                    _this.responseBody = e.response.error;
                    _this.responseBodyTransformed = e.response.error;
                    _this.responseBodyProcessed = e.response.error;
                }
                else if (e.response && e.response instanceof HttpResponse) {
                    _this.response = e.response;
                    _this.responseBody = e.response.body;
                    _this.responseBodyTransformed = e.response.body;
                    _this.responseBodyProcessed = e.response.body;
                }
                else {
                    _this.resultsSection = HttpResultsSection.Http;
                    var serverError = new ServerRequestError(e);
                    if (serverError.nonFieldErrors.length) {
                        _this.notificationService.error('Request failed', serverError.nonFieldErrors[0]);
                    }
                    else {
                        _this.notificationService.error('Request failed', "Unknown error");
                    }
                }
                _this.responseActual = true;
                _this.loading = false;
                _this.cd.markForCheck();
                _this.form.controls.request_response.patchValue(limitObjectLength(_this.responseBodyProcessed, 20));
                _this.form.controls.request_tokens.patchValue(filterObject(saveTokens, function (item) { return !(item instanceof Blob); }));
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.UnsuccessfullyPerformed, {
                    ResourceType: _this.resource.typeItem.name,
                    HTTPQueryType: _this.httpQueryType
                });
            });
        }
        catch (e) {
            if (e instanceof JsFunctionError) {
                this.notificationService.error('Transformation Failed', "\n          Body transformer failed:<br>\n          [" + (e.line - 2) + ":" + e.column + "] " + e.message + "\n        ");
            }
            else if (e instanceof ReferenceError) {
                this.notificationService.error('Execute Failed', 'Token parsing failed:<br>' + e.message);
            }
            else {
                this.notificationService.error('Execute Failed', e);
            }
            this.loading = false;
            this.cd.markForCheck();
        }
    };
    QueryBuilderHttpComponent.prototype.onHasMorePathSelected = function (e) {
        this.queryForm.controls.pagination_has_more_path.patchValue(e.pathName);
        if (e.pathName === 'pagination_has_more_pages_path') {
            this.queryForm.controls.pagination_has_more_pages_path.patchValue(e.value);
        }
        if (e.pathName === 'pagination_has_more_total_pages_path') {
            this.queryForm.controls.pagination_has_more_total_pages_path.patchValue(e.value);
        }
        if (e.pathName === 'pagination_has_more_total_records_path') {
            this.queryForm.controls.pagination_has_more_total_records_path.patchValue(e.value);
        }
    };
    QueryBuilderHttpComponent.prototype.onHasMorePathJsSelected = function () {
        this.queryForm.controls.pagination_has_more_path.patchValue(undefined);
    };
    QueryBuilderHttpComponent.prototype.onTotalTransformPathSelected = function (e) {
        this.queryForm.controls.pagination_total_transformer_enabled.patchValue(false);
        this.queryForm.controls.pagination_total_path.patchValue(e.value);
    };
    QueryBuilderHttpComponent.prototype.onTotalTransformJsSelected = function () {
        this.queryForm.controls.pagination_total_transformer_enabled.patchValue(true);
    };
    QueryBuilderHttpComponent.prototype.onCursorPrevPathSelected = function (e) {
        this.queryForm.controls.pagination_cursor_prev_transformer_enabled.patchValue(false);
        this.queryForm.controls.pagination_cursor_prev_path.patchValue(e.value);
    };
    QueryBuilderHttpComponent.prototype.onCursorPrevTransformJsSelected = function () {
        this.queryForm.controls.pagination_cursor_prev_transformer_enabled.patchValue(true);
    };
    QueryBuilderHttpComponent.prototype.onCursorNextPathSelected = function (e) {
        this.queryForm.controls.pagination_cursor_next_transformer_enabled.patchValue(false);
        this.queryForm.controls.pagination_cursor_next_path.patchValue(e.value);
    };
    QueryBuilderHttpComponent.prototype.onCursorNextTransformJsSelected = function () {
        this.queryForm.controls.pagination_cursor_next_transformer_enabled.patchValue(true);
    };
    QueryBuilderHttpComponent.prototype.onResponseTransformPathSelected = function (e) {
        this.form.controls.response_transformer_enabled.patchValue(false);
        this.form.controls.response_path.patchValue(e.value);
        this.execute();
    };
    QueryBuilderHttpComponent.prototype.onResponseTransformJsSelected = function () {
        this.form.controls.response_transformer_enabled.patchValue(true);
        this.execute();
    };
    QueryBuilderHttpComponent.prototype.setSideBarSection = function (value) {
        this.sideBarSection = value;
        this.cd.markForCheck();
    };
    QueryBuilderHttpComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    QueryBuilderHttpComponent.prototype.saveProcess = function () {
        var query = this.form.getInstance();
        this.control.patchValue(query);
        this.saved.emit();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.HTTPBuilder.SuccessfullySetUp, {
            ResourceType: this.resource.typeItem.name,
            HTTPQueryType: this.httpQueryType
        });
    };
    QueryBuilderHttpComponent.prototype.isResponseMissing = function () {
        return this.requireResponse && !this.responseActual;
    };
    QueryBuilderHttpComponent.prototype.save = function () {
        if (this.isResponseMissing()) {
            return;
        }
        this.saveProcess();
    };
    QueryBuilderHttpComponent.prototype.submit = function () {
        this.save();
    };
    Object.defineProperty(QueryBuilderHttpComponent.prototype, "httpQueryType", {
        get: function () {
            if (this.resource.typeItem.name == ResourceName.GraphQL) {
                return ResourceName.GraphQL;
            }
            else {
                return ResourceName.RestApi;
            }
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderHttpComponent.prototype.onInputTokensEvent = function (event, source) {
        if (event.type == InputTokensEventType.SetSorting) {
            var setSortingEvent = event;
            if (setSortingEvent.data.value) {
                this.setSideBarSection(SideBarSection.Sorting);
            }
            else if (!setSortingEvent.data.value && this.sideBarSection == SideBarSection.Sorting) {
                this.setSideBarSection(undefined);
            }
        }
        else if (event.type == InputTokensEventType.SetPagination) {
            var setPaginationEvent = event;
            if (setPaginationEvent.data.pagination) {
                this.setSideBarSection(SideBarSection.Pagination);
            }
            else if (!setPaginationEvent.data.pagination && this.sideBarSection == SideBarSection.Pagination) {
                this.setSideBarSection(undefined);
            }
        }
        else if (event.type == InputTokensEventType.AddParameter) {
            if (this.parametersControl && source == QueryBuilderSection.QueryParameters) {
                this.setResultsSection(HttpResultsSection.Parameters);
            }
        }
    };
    QueryBuilderHttpComponent.prototype.copyRequest = function () {
        var formValue = this.form.value;
        if (this.form.baseUrlAllowed) {
            formValue['url'] = this.form.urlPathToUrl();
        }
        var data = {
            formValue: formValue,
            parametersValue: this.parametersControl
                ? this.parametersControl.serialize().map(function (item) { return item.serialize(); })
                : undefined
        };
        this.localStorage.set(HTTP_BUILDER_REQUEST_BUFFER_KEY, JSON.stringify(data));
    };
    QueryBuilderHttpComponent.prototype.isPasteRequestAvailable = function () {
        return !!this.localStorage.get(HTTP_BUILDER_REQUEST_BUFFER_KEY);
    };
    QueryBuilderHttpComponent.prototype.pasteRequest = function () {
        var dataStr = this.localStorage.get(HTTP_BUILDER_REQUEST_BUFFER_KEY);
        if (!dataStr) {
            return;
        }
        try {
            var data = JSON.parse(dataStr);
            if (this.parametersControl && data.parametersValue) {
                this.parametersControl.deserialize(data.parametersValue.map(function (item) { return new ParameterField().deserialize(item); }));
            }
            this.form.patchValue(data.formValue);
            this.notificationService.info('Pasted', 'Request was pasted from clipboard');
        }
        catch (e) {
            this.notificationService.info('Paste failed', "Failed to load request from clipboard: " + e);
        }
    };
    return QueryBuilderHttpComponent;
}());
export { QueryBuilderHttpComponent };

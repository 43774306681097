import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { CustomizeService, CustomViewSettings, PopupSettings } from '@modules/customize';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet, TypedChanges } from '@shared';

export interface PreviewFocus {
  page?: CustomViewSettings;
  popup?: PopupSettings;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
}

@Component({
  selector: 'app-page-template-preview, [app-page-template-preview]',
  templateUrl: './page-template-preview.component.html',
  providers: [CustomizeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewComponent implements OnInit, OnChanges {
  @Input() startPage: CustomViewSettings;
  @Input() otherPages: CustomViewSettings[] = [];
  @Input() modelDescription: ModelDescription;
  @Input() previewFocus: PreviewFocus;

  @HostBinding('class.choose-template__detail-viewport') viewportCls = true;
  @HostBinding('style') style: SafeStyle;

  startPagePopup: PopupSettings;

  startPageFocus = false;
  popupFocus = false;
  otherPagesVisible = false;
  otherPagesLabels = false;

  trackPage = (() => {
    return (i, item: CustomViewSettings) => {
      return isSet(item.uniqueName) ? item.uniqueName : item;
    };
  })();

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updatePreviewFocus();
  }

  ngOnChanges(changes: TypedChanges<PageTemplatePreviewComponent>): void {
    if (changes.startPage) {
      this.startPagePopup = this.startPage ? this.startPage.popups[0] : undefined;
    }

    if (changes.previewFocus) {
      this.updatePreviewFocus();
    }
  }

  updatePreviewFocus() {
    let style: string;
    let startPageFocus = false;
    let popupFocus = true;
    let otherPagesVisible = true;
    let otherPagesLabels = true;

    if (!this.previewFocus) {
    } else if (this.previewFocus.popup && this.previewFocus.page && this.previewFocus.page === this.startPage) {
      style = `transform: scale(1.5) translate(0%, 15%)`;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage && this.previewFocus.left) {
      style = `transform: scale(1.5) translate(15%, -2%)`;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage && this.previewFocus.right) {
      style = `transform: scale(1.5) translate(-15%, -2%)`;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage && this.previewFocus.bottom) {
      startPageFocus = true;
      style = `transform: translate(0%, -50%)`;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage && this.startPage.popups.length) {
      startPageFocus = true;
      popupFocus = false;
    } else if (this.previewFocus.page && this.previewFocus.page === this.otherPages[0] && this.otherPages.length == 2) {
      style = `transform: scale(2) translate(25%, -25%)`;
      otherPagesLabels = false;
    } else if (this.previewFocus.page && this.previewFocus.page === this.otherPages[1] && this.otherPages.length == 2) {
      style = `transform: scale(2) translate(-25%, -25%)`;
      otherPagesLabels = false;
    } else if (this.previewFocus.page && this.previewFocus.page === this.otherPages[0] && this.otherPages.length == 1) {
      style = `transform: scale(2) translate(-25%, -25%)`;
      otherPagesLabels = false;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage && this.otherPages.length) {
      startPageFocus = true;
      otherPagesVisible = false;
    } else if (this.previewFocus.page && this.previewFocus.page === this.startPage) {
      startPageFocus = true;
    }

    this.style = style ? this.sanitizer.bypassSecurityTrustStyle(style) : undefined;
    this.startPageFocus = startPageFocus;
    this.popupFocus = popupFocus;
    this.otherPagesVisible = otherPagesVisible;
    this.otherPagesLabels = otherPagesLabels;

    this.cd.markForCheck();
  }
}

import { Injectable, NgZone } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { ParameterArray, ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  parametersToProviderItems
} from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { DataSourceControls } from './data-source.control';
import { ModelDescriptionDataSourceControl } from './model-description-data-source';

export const validateQueryYFunc = (queryColumnControl: string): ValidatorFn => {
  return control => {
    if (!control.parent) {
      return;
    }

    const column = control.parent.controls[queryColumnControl].value as string;

    if (isSet(control.value) && control.value != AggregateFunc.Count && !isSet(column)) {
      return { required: true };
    } else if (!isSet(control.value) && !isSet(column)) {
      return { required: true };
    }
  };
};

@Injectable()
export class ValueWidgetDataSourceControl extends ModelDescriptionDataSourceControl<ValueWidgetDataSource> {
  public static instanceCls = ValueWidgetDataSource;
  resourceFieldParams: Object = { charts_resources: true };

  controls: DataSourceControls & {
    func: FormControl;
    column: FormControl;
  };

  groupCommonOptions = [
    { value: DatasetGroupLookup.Auto, name: 'Auto', valueDisplay: field => `${field} - Auto` },
    { value: DatasetGroupLookup.Plain, name: 'Plain value', valueDisplay: field => `${field} - Plain` }
  ];

  groupDateOptions = [
    { value: DatasetGroupLookup.DateDay, name: 'By Day', valueDisplay: field => `${field} - Day` },
    { value: DatasetGroupLookup.DateWeek, name: 'By Week', valueDisplay: field => `${field} - Week` },
    { value: DatasetGroupLookup.DateMonth, name: 'By Month', valueDisplay: field => `${field} - Month` },
    { value: DatasetGroupLookup.DateQuarter, name: 'By Quarter', valueDisplay: field => `${field} - Quarter` },
    { value: DatasetGroupLookup.DateYear, name: 'By Year', valueDisplay: field => `${field} - Year` }
  ];

  groupTimeOptions = [
    { value: DatasetGroupLookup.DateHour, name: 'By Hour', valueDisplay: field => `${field} - Hour` },
    { value: DatasetGroupLookup.DateMinute, name: 'By Minute', valueDisplay: field => `${field} - Minute` },
    { value: DatasetGroupLookup.DateSecond, name: 'By Second', valueDisplay: field => `${field} - Second` }
  ];

  xColumnLookupAllOptions = [...this.groupCommonOptions, ...this.groupDateOptions, ...this.groupTimeOptions];
  xColumnLookupDefaultOptions = [...this.groupCommonOptions];
  xColumnLookupDateOptions = [...this.groupCommonOptions, ...this.groupDateOptions, ...this.groupTimeOptions];
  xColumnLookupTimeOptions = [...this.groupCommonOptions, ...this.groupTimeOptions];

  yColumnFuncOptions: {
    value: AggregateFunc;
    name: string;
    hasField?: boolean;
    valueDisplay: (field: string) => string;
  }[] = [
    { value: AggregateFunc.Count, name: 'Number of records', valueDisplay: () => `Number of records` },
    { value: AggregateFunc.Sum, name: 'Sum of field', hasField: true, valueDisplay: field => `Sum of ${field}` },
    {
      value: AggregateFunc.Min,
      name: 'Minimum of field',
      hasField: true,
      valueDisplay: field => `Minimum of ${field}`
    },
    {
      value: AggregateFunc.Max,
      name: 'Maximum of field',
      hasField: true,
      valueDisplay: field => `Maximum of ${field}`
    },
    { value: AggregateFunc.Avg, name: 'Average of field', hasField: true, valueDisplay: field => `Average of ${field}` }
  ];

  constructor(
    modelDescriptionStore: ModelDescriptionStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    projectSettingsService: ProjectSettingsService,
    resourceControllerService: ResourceControllerService,
    resourceGeneratorResolver: ResourceGeneratorResolver,
    modelService: ModelService,
    actionService: ActionService,
    queryService: QueryService,
    notificationService: NotificationService,
    zone: NgZone
  ) {
    super(
      modelDescriptionStore,
      currentEnvironmentStore,
      projectSettingsService,
      resourceControllerService,
      resourceGeneratorResolver,
      modelService,
      actionService,
      queryService,
      notificationService,
      zone,
      {
        func: new FormControl('', validateQueryYFunc('column')),
        column: new FormControl('')
      }
    );

    this.controls.column.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.func.updateValueAndValidity();
    });

    this.inputFieldProvider.fields$.subscribe(() => {
      this.controls.query_inputs.updateValueAndValidity();
    });
  }

  clearExtraControls() {
    this.controls.func.patchValue('');
    this.controls.column.patchValue('');
  }

  deserializeExtraControls(instance: ValueWidgetDataSource) {
    if (instance) {
      this.controls.func.patchValue(instance.func);
      this.controls.column.patchValue(instance.column);
    } else {
      this.clearExtraControls();
    }
  }

  onModelDescriptionChange(modelDescription: ModelDescription) {
    super.onModelDescriptionChange(modelDescription);

    this.clearExtraControls();
  }

  serialize(): ValueWidgetDataSource {
    const result = super.serialize();

    if (!result) {
      return;
    }

    result.func = this.controls.func.value;
    result.column = this.controls.column.value;

    return result;
  }

  getInputFieldProvider$(): Observable<InputFieldProviderItem[]> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      this.getResource$(),
      this.getModelDescription$(),
      controlValue<ParameterField[]>(this.controls.query_parameters),
      controlValue<ListModelDescriptionQuery>(this.controls.query),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([type, resource, modelDescription, parameters, getQuery, columns]): InputFieldProviderItem[] => {
        return [
          ...parametersToProviderItems(parameters),
          ...inputFieldProviderItemsFromModelGet(resource, modelDescription, getQuery, columns, type)
        ];
      })
    );
  }

  isListQuery() {
    return true;
  }
}

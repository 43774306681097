/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../previews/file-preview/file-preview.component.ngfactory";
import * as i2 from "../../previews/file-preview/file-preview.component";
import * as i3 from "../upload-template/upload-template.component.ngfactory";
import * as i4 from "../upload-template/upload-template.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i7 from "./file-upload.component";
import * as i8 from "../../../../projects/stores/current-project.store";
import * as i9 from "../../../../projects/stores/current-environment.store";
import * as i10 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i11 from "../../../../storages-queries/services/storage/storage.service";
var styles_FileUploadComponent = [];
var RenderType_FileUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "upload__background upload__background_padding"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-file-preview", [], null, null, null, i1.View_FilePreviewComponent_0, i1.RenderType_FilePreviewComponent)), i0.ɵdid(3, 114688, null, 0, i2.FilePreviewComponent, [], { previewFilename: [0, "previewFilename"], previewExtension: [1, "previewExtension"], previewSize: [2, "previewSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.filename); var currVal_1 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.extension); var currVal_2 = _co.uploadFileSize; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_FileUploadComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-upload-template", [], null, [[null, "clearValue"], [null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearValue" === en)) {
        var pd_0 = (_co.clearCurrentValue() !== false);
        ad = (pd_0 && ad);
    } if (("fileSelected" === en)) {
        var pd_1 = (_co.onFileSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_UploadTemplateComponent_0, i3.RenderType_UploadTemplateComponent)), i0.ɵdid(1, 245760, null, 0, i4.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], name: [1, "name"], control: [2, "control"], autofocus: [3, "autofocus"], disabled: [4, "disabled"], background: [5, "background"], fit: [6, "fit"], cropFormat: [7, "cropFormat"], cropFormatCustom: [8, "cropFormatCustom"], preview: [9, "preview"], uploadProgress: [10, "uploadProgress"], uploadError: [11, "uploadError"], accentColor: [12, "accentColor"] }, { clearValue: "clearValue", fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_FileUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 1, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "a", [["class", "upload__button icon-cloud_download"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 737280, null, 0, i6.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(7, { side: 0, margin: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadable(); var currVal_1 = _co.name; var currVal_2 = _co.control; var currVal_3 = _co.autofocus; var currVal_4 = !_co.storage$.value; var currVal_5 = _co.background; var currVal_6 = _co.fit; var currVal_7 = _co.cropFormat; var currVal_8 = _co.cropFormatCustom; var currVal_9 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); var currVal_10 = _co.uploadProgress; var currVal_11 = _co.uploadError; var currVal_12 = _co.accentColor; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); var currVal_13 = _co.uploadedFile; _ck(_v, 3, 0, currVal_13); var currVal_14 = "Download"; var currVal_15 = _ck(_v, 7, 0, "top", (0 - 4)); _ck(_v, 6, 0, currVal_14, currVal_15); }, null); }
export function View_FileUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i0.ɵdid(1, 770048, null, 0, i7.FileUploadComponent, [i8.CurrentProjectStore, i9.CurrentEnvironmentStore, i10.ResourceControllerService, i11.StorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadComponentNgFactory = i0.ɵccf("app-file-upload", i7.FileUploadComponent, View_FileUploadComponent_Host_0, { name: "name", control: "control", outputFormat: "outputFormat", storageResourceName: "storageResourceName", storageName: "storageName", path: "path", autofocus: "autofocus", disabled: "disabled", background: "background", fit: "fit", resize: "resize", editor: "editor", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor" }, { uploadedUpdated: "uploadedUpdated" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { trimAll, unwrapElement } from '@shared';
import { localize } from '../../utils/localize';
var LocalizeDirective = /** @class */ (function () {
    function LocalizeDirective(templateRef, vcr) {
        this.templateRef = templateRef;
        this.vcr = vcr;
        this.context = {};
    }
    LocalizeDirective.prototype.ngOnInit = function () {
        var context = fromPairs(toPairs(this.context).map(function (_a, i) {
            var k = _a[0], v = _a[1];
            return [k, "{" + i + "}"];
        }));
        var view = this.vcr.createEmbeddedView(this.templateRef, __assign({}, context, { $implicit: context }));
        view.detectChanges();
        var renderedHtml = trimAll(view.rootNodes
            .map(function (item) {
            if (item.nodeType == 3) {
                return item.textContent;
            }
            else {
                return item.outerHTML;
            }
        })
            .join(''));
        this.vcr.clear();
        var content = localize(renderedHtml, values(this.context));
        var htmlContainer = document.createElement('div');
        var vcrEl = this.vcr.element.nativeElement;
        htmlContainer.innerHTML = content;
        vcrEl.parentNode.insertBefore(htmlContainer, vcrEl);
        unwrapElement(htmlContainer);
    };
    return LocalizeDirective;
}());
export { LocalizeDirective };

import { AggregateFunc } from '@modules/charts';
import { isSet } from '@shared';

import { BaseField, FieldWithAction, FilterableField, FlexField, SortableField, ToggleField } from './base-field';
import { FieldType } from './field-type';
import { FieldDescription, getFieldDescriptionByType, parseFieldType } from './fields';
import { FormField } from './form-field';
import { Input } from './input';

export enum DisplayFieldType {
  Base = 'base',
  Computed = 'computed',
  CustomView = 'custom_view',
  Lookup = 'lookup',
  Aggregate = 'aggregate'
}

export class DisplayField implements BaseField, FilterableField, SortableField, ToggleField, FieldWithAction {
  public type: DisplayFieldType = DisplayFieldType.Base;
  public name: string;
  public verboseName: string;
  public description: string;
  public field: FieldType;
  public params = {};
  public visible = false;
  public fieldDescription: FieldDescription;
  private _formField: FormField;

  constructor(options: Partial<DisplayField> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): DisplayField {
    this.type = data['type'] || DisplayFieldType.Base;
    this.name = data['name'];
    this.verboseName = data['verboseName'];
    this.description = data['description'];
    this.field = parseFieldType(data['field']);
    this.visible = data['visible'];

    if (data['params']) {
      this.params = data['params'];
    }

    this.updateFieldDescription();

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      name: this.name,
      verboseName: this.verboseName,
      description: this.description,
      field: this.field,
      visible: this.visible,
      params: this.params
    };
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }

  get formField(): FormField {
    if (!this._formField) {
      this._formField = new FormField().deserialize({
        name: this.name,
        label: this.verboseName,
        field: this.field,
        editable: false,
        required: false,
        params: this.params,
        description: this.description
      });
    }

    return this._formField;
  }

  isCustom() {
    return this.type != DisplayFieldType.Base;
  }

  get icon() {
    return this.fieldDescription ? this.fieldDescription.icon : undefined;
  }
}

export class ComputedDisplayField extends DisplayField implements FlexField {
  public type: DisplayFieldType = DisplayFieldType.Computed;
  public flex = true;
  public valueInput: Input;

  constructor(options: Partial<ComputedDisplayField> = {}) {
    super(options);
  }

  deserialize(data: Object): ComputedDisplayField {
    super.deserialize(data);

    if (data['valueInput']) {
      this.valueInput = new Input().deserialize(data['valueInput']);
    } else {
      this.valueInput = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      flex: this.flex,
      valueInput: this.valueInput ? this.valueInput.serialize() : undefined
    };
  }

  get icon() {
    return 'function';
  }
}

// TODO: Refactor import
export class ViewMapping {
  public sourceParameter: string;
  public sourceParameterInput: Input;
  public targetParameter: string;

  constructor(options: Partial<ViewMapping> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (isSet(data['source_parameter'])) {
      this.sourceParameter = data['source_parameter'];
    } else if (data['sourceParameter']) {
      // Backward compatibility
      this.sourceParameter = data['sourceParameter'];
    }

    if (isSet(data['target_parameter'])) {
      this.targetParameter = data['target_parameter'];
    } else if (data['targetParameter']) {
      // Backward compatibility
      this.targetParameter = data['targetParameter'];
    }

    if (data['source_parameter_input']) {
      this.sourceParameterInput = new Input().deserialize(data['source_parameter_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      source_parameter: this.sourceParameter,
      source_parameter_input: this.sourceParameterInput ? this.sourceParameterInput.serialize() : undefined,
      target_parameter: this.targetParameter
    };
  }
}

export class CustomViewDisplayField extends DisplayField {
  public type: DisplayFieldType = DisplayFieldType.CustomView;
  public customView: string;
  public customViewMappings: ViewMapping[] = [];

  constructor(options: Partial<CustomViewDisplayField> = {}) {
    super(options);
  }

  deserialize(data: Object): CustomViewDisplayField {
    super.deserialize(data);
    this.customView = data['custom_view'];

    if (data['custom_view_mappings']) {
      this.customViewMappings = data['custom_view_mappings'].map(item => new ViewMapping().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      custom_view: this.customView,
      custom_view_mappings: this.customViewMappings.map(item => item.serialize())
    };
  }

  get icon() {
    return 'canvas';
  }
}

export class LookupDisplayField extends DisplayField {
  public type: DisplayFieldType = DisplayFieldType.Lookup;
  public path: string[];

  constructor(options: Partial<LookupDisplayField> = {}) {
    super(options);
  }

  deserialize(data: Object): LookupDisplayField {
    super.deserialize(data);

    this.path = data['path'];

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      path: this.path
    };
  }

  get icon() {
    return 'lookup';
  }
}

export class AggregateDisplayField extends DisplayField {
  public type: DisplayFieldType = DisplayFieldType.Aggregate;
  public path: string[];
  public func: AggregateFunc;
  public column: string;

  constructor(options: Partial<AggregateDisplayField> = {}) {
    super(options);
  }

  deserialize(data: Object): AggregateDisplayField {
    super.deserialize(data);

    this.path = data['path'];
    this.func = data['func'];
    this.column = data['column'];

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      path: this.path,
      func: this.func,
      column: this.column
    };
  }

  get icon() {
    return 'spiral';
  }
}

export function deserializeDisplayField(item: Object): DisplayField {
  // Backward compatibility
  if (item['flex']) {
    return new ComputedDisplayField().deserialize({
      ...item,
      type: DisplayFieldType.Computed
    });
  }

  if (item['type'] == DisplayFieldType.Computed) {
    return new ComputedDisplayField().deserialize(item);
  } else if (item['type'] == DisplayFieldType.CustomView) {
    return new CustomViewDisplayField().deserialize(item);
  } else if (item['type'] == DisplayFieldType.Lookup) {
    return new LookupDisplayField().deserialize(item);
  } else if (item['type'] == DisplayFieldType.Aggregate) {
    return new AggregateDisplayField().deserialize(item);
  } else {
    return new DisplayField().deserialize(item);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutStore {
  layoutKey(key: string) {
    return ['layout', key].join('_');
  }

  getCurrentLayout(key: string): number {
    return localStorage[this.layoutKey(key)];
  }

  setCurrentLayout(key: string, layout: number) {
    const layoutKey = this.layoutKey(key);
    localStorage[layoutKey] = layout;
  }

  resetCurrentLayout(key: string) {
    const layoutKey = this.layoutKey(key);
    if (localStorage[layoutKey] != undefined) {
      delete localStorage[layoutKey];
    }
  }
}

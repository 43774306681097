import { StorageQuery } from '@modules/queries';
import { ascComparator, generateAlphanumeric, sortUsingAfterItem } from '@shared';

import { FieldType } from '../../fields/data/field-type';
import { ParameterField } from '../../fields/data/parameter-field';

export function sortStorages(items: Storage[]): Storage[] {
  if (items.length <= 1) {
    return items;
  }

  return sortUsingAfterItem<Storage>({
    items: items,
    getAfterItem: item => item.orderAfter,
    getItemId: item => item.uniqueName,
    defaultSort: (lhs, rhs) => {
      return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase());
    }
  });
}

export class Storage {
  public uniqueName: string;
  public name: string;
  public params = {};
  public getQuery: StorageQuery;
  // public getParameters: ParameterField[] = [];
  public uploadQuery: StorageQuery;
  public removeQuery: StorageQuery;
  public createDirectoryQuery: StorageQuery;
  public getObjectUrlQuery: StorageQuery;
  public extraParams = {};
  public orderAfter: string = undefined;
  public draft = false;
  public deleted = false;

  static generateUniqueName(): string {
    return generateAlphanumeric(8);
  }

  deserialize(data: Object): Storage {
    this.uniqueName = data['unique_name'];
    this.name = data['name'];

    if (data['params']) {
      this.params = data['params'];

      if (this.params['get_query']) {
        this.getQuery = new StorageQuery().deserialize(this.params['get_query']);
      }

      if (this.params['upload_query']) {
        this.uploadQuery = new StorageQuery().deserialize(this.params['upload_query']);
      }

      if (this.params['remove_query']) {
        this.removeQuery = new StorageQuery().deserialize(this.params['remove_query']);
      }

      if (this.params['create_directory_query']) {
        this.createDirectoryQuery = new StorageQuery().deserialize(this.params['create_directory_query']);
      }

      if (this.params['get_object_url_query']) {
        this.getObjectUrlQuery = new StorageQuery().deserialize(this.params['get_object_url_query']);
      }

      if (this.params['order_after'] !== undefined) {
        this.orderAfter = this.params['order_after'];
      }

      if (this.params['extra_params'] !== undefined) {
        this.extraParams = this.params['extra_params'];
      }
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(): Object {
    return {
      unique_name: this.uniqueName,
      name: this.name,
      params: {
        get_query: this.getQuery ? this.getQuery.serialize() : undefined,
        upload_query: this.uploadQuery ? this.uploadQuery.serialize() : undefined,
        remove_query: this.removeQuery ? this.removeQuery.serialize() : undefined,
        create_directory_query: this.createDirectoryQuery ? this.createDirectoryQuery.serialize() : undefined,
        get_object_url_query: this.getObjectUrlQuery ? this.getObjectUrlQuery.serialize() : undefined,
        order_after: this.orderAfter,
        extra_params: this.extraParams
      },
      draft: this.draft,
      deleted: this.deleted
    };
  }

  editLink(resourceUniqueName: string) {
    return ['resources', resourceUniqueName, 'storages', this.uniqueName];
  }

  isConfigured() {
    return this.uploadQuery && this.uploadQuery.isConfigured();
  }

  isSame(storage: string | Storage): boolean {
    if (storage instanceof Storage) {
      return this.uniqueName == storage.uniqueName;
    } else if (typeof storage == 'string') {
      return this.uniqueName == storage;
    } else {
      return false;
    }
  }

  autoActions() {
    return [
      {
        name: 'get_object_url',
        label: 'get object URL',
        path: true,
        query: 'getObjectUrlQuery',
        uniqueName: this.autoActionUniqueName('get_object_url')
      },
      {
        name: 'upload',
        label: 'upload',
        query: 'uploadQuery',
        uniqueName: this.autoActionUniqueName('upload'),
        modify: true
      },
      {
        name: 'get',
        label: 'get objects',
        query: 'getQuery',
        uniqueName: this.autoActionUniqueName('get')
      },
      {
        name: 'create_directory',
        label: 'create directory',
        path: true,
        query: 'createDirectoryQuery',
        uniqueName: this.autoActionUniqueName('create_directory'),
        modify: true
      },
      {
        name: 'remove',
        label: 'delete file',
        path: true,
        query: 'removeQuery',
        uniqueName: this.autoActionUniqueName('remove'),
        modify: true
      }
    ];
  }

  autoActionUniqueName(name: string) {
    return ['', 'storage', this.uniqueName, name].join('__');
  }

  getObjectUrlParameters(): ParameterField[] {
    const pathParameter = new ParameterField();

    pathParameter.name = 'path';
    pathParameter.verboseName = 'file path';
    pathParameter.required = true;
    pathParameter.field = FieldType.Text;

    const expiresParameter = new ParameterField();

    expiresParameter.name = 'expires';
    expiresParameter.verboseName = 'expires in (sec.)';
    expiresParameter.placeholder = 'default is 15 min.';
    expiresParameter.required = false;
    expiresParameter.field = FieldType.Number;

    return [pathParameter, expiresParameter];
  }

  uploadParameters(): ParameterField[] {
    const fileParameter = new ParameterField();

    fileParameter.name = 'file';
    fileParameter.verboseName = 'file';
    fileParameter.required = true;
    fileParameter.field = FieldType.File;

    const pathParameter = new ParameterField();

    pathParameter.name = 'path';
    pathParameter.verboseName = 'file path';
    pathParameter.required = false;
    pathParameter.field = FieldType.Text;

    return [fileParameter, pathParameter];
  }

  getParameters(): ParameterField[] {
    const pathParameter = new ParameterField();

    pathParameter.name = 'path';
    pathParameter.verboseName = 'path';
    pathParameter.required = false;
    pathParameter.field = FieldType.Text;

    return [pathParameter];
  }

  createDirectoryParameters(): ParameterField[] {
    const pathParameter = new ParameterField();

    pathParameter.name = 'path';
    pathParameter.verboseName = 'path';
    pathParameter.required = true;
    pathParameter.field = FieldType.Text;

    return [pathParameter];
  }

  removeParameters(): ParameterField[] {
    const pathParameter = new ParameterField();

    pathParameter.name = 'path';
    pathParameter.verboseName = 'path';
    pathParameter.required = true;
    pathParameter.field = FieldType.Text;

    return [pathParameter];
  }
}

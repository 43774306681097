import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { TintStyle } from '@modules/actions';
import {
  ActionDropdownElementItem,
  AlignHorizontal,
  CustomizeService,
  ElementType,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-action-dropdown-element',
  templateUrl: './action-dropdown-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionDropdownElementComponent extends BaseElementComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: ActionDropdownElementItem;
  @ViewChild('menuTrigger', { read: MatMenuTrigger }) menuTrigger: MatMenuTrigger;

  customizeEnabled$: Observable<boolean>;
  buttonHover = new BehaviorSubject<boolean>(false);
  menuHover = new BehaviorSubject<boolean>(false);
  style: TintStyle = TintStyle.Primary;
  tintStyles = TintStyle;
  alignHorizontals = AlignHorizontal;
  verboseName: string;
  verboseNameSubscription: Subscription;
  disabled = false;
  disabledSubscription: Subscription;

  constructor(
    public viewContextElement: ViewContextElement,
    private cd: ChangeDetectorRef,
    private customizeService: CustomizeService,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });

    this.initVerboseName();
    this.initStyle();
    this.initDisabled();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ActionDropdownElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
    }

    if (changes.element) {
      this.initVerboseName();
      this.initStyle();
      this.initDisabled();
    }
  }

  ngAfterViewInit(): void {
    combineLatest(this.buttonHover, this.menuHover)
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(([buttonHover, menuHover]) => {
        if (!this.menuTrigger) {
          return;
        }

        if (buttonHover || menuHover) {
          this.menuTrigger.openMenu();
        } else {
          this.menuTrigger.closeMenu();
        }
      });
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.element.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.element.verboseNameInput, {
      context: this.context,
      contextElement: this.viewContextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  initStyle() {
    this.style = this.element.style ? this.element.style : TintStyle.Primary;
    this.cd.markForCheck();
  }

  getElementDisabled$() {
    return this.element.disabledInput && this.element.disabledInput.isSet()
      ? applyBooleanInput$(this.element.disabledInput, {
          context: this.context,
          contextElement: this.viewContextElement
        })
      : of(false);
  }

  initDisabled() {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    if (!this.element) {
      this.disabled = false;
      this.cd.markForCheck();
      return;
    }

    this.getElementDisabled$()
      .pipe(untilDestroyed(this))
      .subscribe(inputDisabled => {
        this.disabled = inputDisabled;
        this.cd.markForCheck();
      });
  }
}

registerElementComponent({
  type: ElementType.ActionDropdown,
  component: ActionDropdownElementComponent,
  label: 'Button dropdown',
  actions: []
});

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore, ProjectGroup, ProjectGroupService } from '@modules/projects';

@Injectable()
export class ProjectGroupCreatePopupForm {
  form = new FormGroup({
    name: new FormControl('', [Validators.required], [this.duplicateNameValidator()])
  });

  constructor(
    private projectGroupService: ProjectGroupService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {}

  duplicateNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (control.value === null) {
        return of(null);
      }

      return timer(200).pipe(
        switchMap(() => {
          return this.projectGroupService.get(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName
          );
        }),
        map((groups: ProjectGroup[]) => {
          const groupMatch = groups.find(item => item.name.toLowerCase().trim() === control.value.toLowerCase().trim());
          if (groupMatch) {
            return { local: ['Team with such name already exists'] };
          }
          return null;
        })
      );
    };
  }
}

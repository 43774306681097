var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { applyValueFormat, applyValueFormat$ } from '@modules/customize';
import { DomainStore } from '@modules/domain';
import { FieldType, NumberFieldType, NumberValueFormat, registerFieldComponent, ValueFormat } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var NumberFieldComponent = /** @class */ (function (_super) {
    __extends(NumberFieldComponent, _super);
    function NumberFieldComponent(domainStore, currentProjectStore, cd) {
        var _this = _super.call(this) || this;
        _this.domainStore = domainStore;
        _this.currentProjectStore = currentProjectStore;
        _this.cd = cd;
        _this.numberFieldTypes = NumberFieldType;
        return _this;
    }
    NumberFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.valueFormat = this.getValueFormat();
        this.displayValue$ = this.getDisplayValue$(this.valueFormat);
        this.getDomain$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.defaultNumberFractionSeparator = domain ? domain.numberFractionSeparator : undefined;
            _this.defaultNumberThousandsSeparator = domain ? domain.numberThousandsSeparator : undefined;
            _this.valueFormat = _this.getValueFormat();
            _this.displayValue$ = _this.getDisplayValue$(_this.valueFormat);
            _this.cd.markForCheck();
        });
    };
    NumberFieldComponent.prototype.ngOnDestroy = function () { };
    NumberFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes.field) {
            this.valueFormat = this.getValueFormat();
        }
        if (changes.value || changes.form) {
            this.displayValue$ = this.getDisplayValue$(this.valueFormat);
        }
    };
    NumberFieldComponent.prototype.getDomain$ = function () {
        if (this.currentProjectStore) {
            return this.currentProjectStore.get().pipe(map(function (project) { return (project ? project.domain : undefined); }));
        }
        else if (this.domainStore) {
            return this.domainStore.get();
        }
        else {
            return of(undefined);
        }
    };
    NumberFieldComponent.prototype.getValueFormat = function () {
        var result = this.field.params['value_format']
            ? new ValueFormat().deserialize(this.field.params['value_format'])
            : new ValueFormat({ numberFormat: NumberValueFormat.Default });
        if (isSet(this.field.params['thousands_separator'], true)) {
            result.numberThousandsSeparator = this.field.params['thousands_separator'];
        }
        this.applyValueFormatDefaults(result);
        return result;
    };
    NumberFieldComponent.prototype.applyValueFormatDefaults = function (valueFormat) {
        if (!isSet(valueFormat.numberFractionSeparator) && isSet(this.defaultNumberFractionSeparator)) {
            valueFormat.numberFractionSeparator = this.defaultNumberFractionSeparator;
        }
        if (!isSet(valueFormat.numberThousandsSeparator, true) && isSet(this.defaultNumberThousandsSeparator, true)) {
            valueFormat.numberThousandsSeparator = this.defaultNumberThousandsSeparator;
        }
    };
    NumberFieldComponent.prototype.getDisplayValue = function (valueFormat) {
        var value = this.currentValue;
        if (value === null || value === undefined) {
            return value;
        }
        value = String(value);
        return applyValueFormat(value, valueFormat, { context: this.context, contextElement: this.contextElement });
    };
    NumberFieldComponent.prototype.getDisplayValue$ = function (valueFormat) {
        var _this = this;
        return this.currentValue$.pipe(switchMap(function (value) {
            if (value === null || value === undefined) {
                return of(value);
            }
            value = String(value);
            return applyValueFormat$(value, valueFormat, { context: _this.context, contextElement: _this.contextElement });
        }), startWith(this.getDisplayValue(valueFormat)), shareReplay(1));
    };
    Object.defineProperty(NumberFieldComponent.prototype, "inputSmall", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
        },
        enumerable: true,
        configurable: true
    });
    return NumberFieldComponent;
}(FieldComponent));
export { NumberFieldComponent };
registerFieldComponent(FieldType.Number, NumberFieldComponent);

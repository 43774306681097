export const schemeAmplitude = [
  '#2a76df',
  '#93bd31',
  '#7e21ad',
  '#66d1d4',
  '#fc775d',
  '#a071ff',
  '#f8a0ef',
  '#11659b',
  '#ce6c18',
  '#249a82',
  '#fbc300',
  '#d5084b'
];

export const schemeCategory10 = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf'
];

export const CHART_COLORS = [...schemeAmplitude];

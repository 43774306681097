<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<form *ngIf="enabled" [formGroup]="form" (ngSubmit)="submit.emit()">
  <app-customize-bar-header
    [title]="form.value['title'] || (options.titleEditable ? 'action' : 'Action')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="options.titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="backLabel"
    [backPopSettingsComponent]="backPopSettingsComponent"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (titleChanged)="titleChange.emit($event)"
    (deleteClick)="delete.emit()"
    (backClick)="back.emit()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <app-sidebar-submit
    *ngIf="hasBack"
    [saveEnabled]="false"
    [cancelEnabled]="false"
    [backEnabled]="true"
    [errors]="form | appFormErrors"
    (cancelClick)="cancel.emit()"
  >
  </app-sidebar-submit>
</form>

<ng-container *ngIf="!enabled">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

var PricingPlan = /** @class */ (function () {
    function PricingPlan() {
    }
    PricingPlan.prototype.deserialize = function (data) {
        if (data['Name']) {
            this.name = data['Name'];
        }
        if (data['Price Monthly'] !== undefined) {
            this.priceMonthly = data['Price Monthly'];
        }
        if (data['Price Annual'] !== undefined) {
            this.priceAnnual = data['Price Annual'];
        }
        if (data['Unique Name']) {
            this.uniqueName = data['Unique Name'];
        }
        if (data['Description']) {
            this.description = data['Description'];
        }
        if (data['Button Link']) {
            this.buttonLink = data['Button Link'];
        }
        if (data['Button Name']) {
            this.buttonName = data['Button Name'];
        }
        if (data['Features Mobile']) {
            this.featuresMobile = data['Features Mobile'];
        }
        if (data['Price Additional']) {
            this.priceAdditional = data['Price Additional'];
        }
        return this;
    };
    Object.defineProperty(PricingPlan.prototype, "changePlanLink", {
        get: function () {
            return ['project', 'change_plan', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    PricingPlan.prototype.isFree = function () {
        return this.priceAnnual === 0 && this.priceAnnual === 0;
    };
    return PricingPlan;
}());
export { PricingPlan };

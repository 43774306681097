import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionGroupElementItem, ActionGroupOrientation } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';

import { MarginControl } from '../margin-control/margin-control.component';

export function validateActions(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const parent = control.parent as CustomizeBarActionGroupEditForm;

    if (!parent) {
      return of(null);
    }

    if (!control.value || !control.value.length) {
      return of(null);
    }

    return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
      map(result => {
        if (result.some(configured => !configured)) {
          return { required: true };
        }
      })
    );
  };
}

@Injectable()
export class CustomizeBarActionGroupEditForm extends FormGroup {
  element: ActionGroupElementItem;

  controls: {
    name: FormControl;
    actions: FormControl;
    orientation: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  constructor(public elementConfigurationService: ElementConfigurationService) {
    super({
      name: new FormControl(''),
      actions: new FormControl([], undefined, validateActions()),
      orientation: new FormControl(ActionGroupOrientation.Horizontal),
      visible_input: new FieldInputControl({ name: 'value' }),
      margin: new MarginControl()
    });
  }

  init(element: ActionGroupElementItem, firstInit = false) {
    this.element = element;

    const value = {
      actions: element.actions,
      orientation: element.orientation,
      visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
      margin: element.margin
    };

    this.patchValue(value, { emitEvent: false });

    if (!firstInit) {
      this.markAsDirty();
    }
  }

  isConfigured(instance: ActionGroupElementItem): Observable<boolean> {
    return this.elementConfigurationService.isActionGroupConfigured(instance);
  }

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    if (!controls.length) {
      return of(true);
    }

    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item))
      // debounceTime(60) TODO: Too long wait with debounceTime
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$([this.controls.actions]);
  }

  submit(): ActionGroupElementItem {
    const instance = cloneDeep(this.element) as ActionGroupElementItem;

    instance.actions = this.controls.actions.value;
    instance.orientation = this.controls.orientation.value;
    instance.margin = this.controls.margin.value;

    if (this.controls.visible_input.value) {
      instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
    } else {
      instance.visibleInput = undefined;
    }

    return instance;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { localize } from '../../utils/localize';

@Pipe({
  name: 'localize'
})
export class LocalizePipe implements PipeTransform {
  transform(label: string, args?: any): any {
    return localize(label, args);
  }
}

<ng-template #popup_content>
  <div class="popup-content resource-popup" [class.resource-popup_wide]="wide">
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>

    <div class="resource-popup__title">
      <span [class.loading-animation]="true">
        <span class="stub-text">Allow access</span>
      </span>
      <br />
      <strong [style.color]="typeItem.color">
        <span [class.loading-animation]="true">
          <span class="stub-text">Resource</span>
        </span>
      </strong>
    </div>

    <div class="resource-popup__description">
      <span [class.loading-animation]="true">
        <span class="stub-text">
          Sign in to Resource
        </span>
      </span>
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngFor="let item of [1, 2]">
        <div class="field">
          <label class="field__label">
            <span [class.loading-animation]="true">
              <span class="stub-text">Field</span>
            </span>
          </label>

          <div class="field__content">
            <input
              class="input input_stub"
              [class.input_fill]="true"
              [class.loading-animation]="true"
              type="text"
              value="Value"
              [disabled]="true"
            />
          </div>

          <div class="field__description">
            <span [class.loading-animation]="true">
              <span class="stub-text">
                Field description
              </span>
            </span>
          </div>
        </div>
      </app-page-block>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!sideInfo">
  <ng-container *ngTemplateOutlet="popup_content"></ng-container>
</ng-container>

<app-popup2-side *ngIf="sideInfo">
  <ng-container *ngTemplateOutlet="popup_content"></ng-container>

  <ng-container data-side>
    <div class="resource-popup__banner-description">
      <span [class.loading-animation]="true">
        <span class="stub-text">
          Manual 3rd party credentials description with screenshot
        </span>
      </span>
    </div>
    <div class="resource-popup__banner-image">
      <div
        class="resource-popup__banner-image-inner resource-popup__banner-image-inner_stub"
        [class.loading-animation]="true"
      ></div>
    </div>
  </ng-container>
</app-popup2-side>

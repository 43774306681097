import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, concat, defer, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { PopupDynamicComponentArguments, PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEventType, WorkflowExecuteWorkflowFinishedEvent } from '@modules/action-queries';
import { HomeType, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { CustomizeService, ViewSettingsStore } from '@modules/customize';
import { GlobalContext } from '@modules/customize-utils';
import {
  ButtonMenuItem,
  findMenuItem,
  MenuItemActionType,
  MenuPagesService,
  MenuSettingsStore,
  ModelLinkMenuItem,
  SimpleMenuItem
} from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { NavigationService } from '@modules/navigation';
import { CurrentEnvironmentStore, CurrentProjectStore, HomeTriggerOutput, ProjectDeployment } from '@modules/projects';
import { PROJECT_CREATE_TEMPLATE_ITEM, TemplateItemSerialized } from '@modules/projects-routes';
import { RoutingService } from '@modules/routing';
import { Template, TemplateService } from '@modules/template';
import {
  ChooseTemplateOptions,
  ChooseTemplateSection,
  ChooseTemplateService,
  TemplateApplyController,
  TemplateItemType
} from '@modules/template-components';
import { Workflow } from '@modules/workflow';
import { ascComparator, isSet } from '@shared';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  providers: [GlobalContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = false;
  openedOnboarding = false;
  onboardingPopup: PopupDynamicComponentArguments;

  constructor(
    private context: GlobalContext,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsStore: ProjectSettingsStore,
    private actionService: ActionService,
    public menuSettingsStore: MenuSettingsStore,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private navigationService: NavigationService,
    public customizeService: CustomizeService,
    private routing: RoutingService,
    private templateService: TemplateService,
    private chooseTemplateService: ChooseTemplateService,
    private templateApplyController: TemplateApplyController,
    private menuPagesService: MenuPagesService,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector
  ) {}

  ngOnInit() {
    const templateItem = this.popProjectCreateTemplateItem();

    if (templateItem) {
      const templateObs = templateItem.template
        ? this.templateService.getDetail(String(templateItem.template))
        : of(undefined);

      templateObs.pipe(untilDestroyed(this)).subscribe(template => this.applyTemplate(templateItem, template));
    } else {
      this.redirectIfNeeded()
        .pipe(untilDestroyed(this))
        .subscribe(redirected => {
          if (!redirected && this.customizeService.enabled && !this.openedOnboarding) {
            this.openedOnboarding = true;
            this.openOnboarding();
          }
        });
    }

    // this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
    //   const apiBaseUrl = this.detectApiBaseUrl(params['install_referrer']);
    //
    //   if (apiBaseUrl) {
    //     this.dialogService
    //       .dialog({
    //         title: 'Finish installation',
    //         description: `
    //         Use the following API URL to finish Django installation:<br>
    //         <strong>${apiBaseUrl}</strong>
    //     `,
    //         buttons: [
    //           {
    //             name: 'copy',
    //             label: 'Copy API URL',
    //             type: DialogButtonType.Default
    //           },
    //           {
    //             name: 'ok',
    //             label: 'OK',
    //             type: DialogButtonType.Primary
    //           }
    //         ]
    //       })
    //       .subscribe(result => {
    //         if (result.button == 'copy') {
    //           copyTextToClipboard(apiBaseUrl);
    //         }
    //       });
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.closeOnboardingPopup();
  }

  popProjectCreateTemplateItem(): TemplateItemSerialized {
    const route = this.activatedRoute.snapshot;

    if (!route.queryParams.hasOwnProperty(PROJECT_CREATE_TEMPLATE_ITEM)) {
      return;
    }

    const templateItemData = route.queryParams[PROJECT_CREATE_TEMPLATE_ITEM];

    this.routing.navigateRoute(route, {
      queryParams: {
        ...route.queryParams,
        [PROJECT_CREATE_TEMPLATE_ITEM]: undefined
      },
      replaceUrl: true
    });

    if (templateItemData) {
      try {
        return JSON.parse(templateItemData);
      } catch (e) {}
    }
  }

  applyTemplate(templateItem: TemplateItemSerialized, template: Template) {
    if (templateItem.type == TemplateItemType.AdminPanel && !templateItem.resource) {
      if (this.customizeService.enabled && !this.openedOnboarding) {
        this.openedOnboarding = true;
        this.openOnboarding({ type: TemplateItemType.AdminPanel });
      }
    } else {
      this.templateApplyController
        .applyTemplateProcess(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.injector, {
          type: templateItem.type,
          resourceType: templateItem.resource,
          template: template,
          resourceNameEditing: false,
          useDemoResources: true,
          analyticsSource: 'onboarding'
        })
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          if (result.link) {
            this.routing.navigateApp(result.link, { replaceUrl: true });
          } else if (result.cancelled) {
            this.openOnboarding();
          }
        });
    }
  }

  redirectIfNeeded(): Observable<boolean> {
    if (this.customizeService.enabled) {
      return this.builderRedirectIfNeeded();
    } else if (!this.customizeService.enabled) {
      return this.appRedirectIfNeeded();
    } else {
      return of(false);
    }
  }

  pageRedirect(pageUid: string): Observable<boolean> {
    if (!isSet(pageUid)) {
      return of(false);
    }

    return this.viewSettingsStore.getDetailByUidFirst(pageUid).pipe(
      map(viewSettings => {
        if (!viewSettings) {
          return false;
        }

        this.routing.navigateApp(viewSettings.link, { replaceUrl: true });
        return true;
      })
    );
  }

  workflowRedirect(workflow: Workflow): Observable<boolean> {
    if (!isSet(workflow)) {
      return of(false);
    }

    const source = this.activatedRoute.snapshot.queryParams['src'];
    const sourceAcceptInvite = source == HomeTriggerOutput.AcceptInvite;
    const sourceRegister = source == HomeTriggerOutput.Register;
    const params = {
      [HomeTriggerOutput.AcceptInvite]: sourceAcceptInvite,
      [HomeTriggerOutput.Register]: sourceRegister,
      [HomeTriggerOutput.Login]: !sourceAcceptInvite && !sourceRegister
    };

    return this.actionService
      .executeWorkflow(workflow, params, { context: this.context, injector: this.injector })
      .pipe(
        filter(event => event.type == WorkflowExecuteEventType.WorkflowFinished),
        map((event: WorkflowExecuteWorkflowFinishedEvent) => {
          if (event.error) {
            throw new ServerRequestError(event.error);
          }

          return true;
        })
      );
  }

  firstCustomizablePageRedirect(): Observable<boolean> {
    return this.menuPagesService.getGroups().pipe(
      first(),
      map(groups => {
        const nonEmptyGroup = groups.find(item => item.items.length > 0);
        if (nonEmptyGroup) {
          const link = nonEmptyGroup.items[0].link;
          this.routing.navigateApp(link, { replaceUrl: true });
          return true;
        }

        const project = this.currentProjectStore.instance;
        const firstResource = this.currentEnvironmentStore.resources
          .filter(item => !item.typeItem.protected)
          .filter(item => !item.demo || (item.demo && project.uniqueName == DEMO_RESOURCES_PROJECT))
          .sort((lhs, rhs) => ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()))[0];
        if (firstResource) {
          this.routing.navigateApp(firstResource.link, { replaceUrl: true });
          return true;
        }

        return false;
      })
    );
  }

  firstMenuItemRedirect(): Observable<boolean> {
    return combineLatest(
      this.menuSettingsStore.getFirst(),
      this.modelDescriptionStore.getFirst(),
      this.viewSettingsStore.getFirst()
    ).pipe(
      map(([settings, modelDescriptions, viewSettings]) => {
        let link: any[];

        findMenuItem(settings.getAllItems(), item => {
          if (item instanceof ModelLinkMenuItem) {
            const modelDescription = modelDescriptions ? modelDescriptions.find(i => i.isSame(item.model)) : undefined;
            if (modelDescription) {
              link = modelDescription.link;
              return true;
            }
          } else if (
            (item instanceof SimpleMenuItem || item instanceof ButtonMenuItem) &&
            item.action &&
            item.action.type == MenuItemActionType.Page
          ) {
            const page = viewSettings ? viewSettings.find(i => i.isSame(item.action.pageUid)) : undefined;
            if (page) {
              link = page.link;
              return true;
            }
          }

          return false;
        });

        if (!link) {
          return false;
        }

        this.routing.navigateApp(link, { replaceUrl: true });
        return true;
      })
    );
  }

  newPageRedirect(): Observable<boolean> {
    this.routing.navigateApp(this.currentProjectStore.instance.newPageLink, { replaceUrl: true });
    return of(true);
  }

  appRedirectIfNeeded(): Observable<boolean> {
    return this.projectSettingsStore.getAllSettingsFirst$().pipe(
      switchMap(projectSettings => {
        const methods: Observable<boolean>[] = [];

        if (projectSettings.homeType == HomeType.Page) {
          methods.push(defer(() => this.pageRedirect(projectSettings.homePageUid)));
        } else if (projectSettings.homeType == HomeType.Workflow) {
          methods.push(defer(() => this.workflowRedirect(projectSettings.homeWorkflow)));
        }

        methods.push(defer(() => this.firstMenuItemRedirect()));
        methods.push(defer(() => this.newPageRedirect()));

        return concat(...methods.map(item => item.pipe(catchError(() => of(true))))).pipe(
          first(redirected => redirected)
        );
      })
    );
  }

  builderRedirectIfNeeded(): Observable<boolean> {
    const project = this.currentProjectStore.instance;

    if (project && project.deployment == ProjectDeployment.OnPremise) {
      this.routing.navigateApp(project.onPremiseLink, { replaceUrl: true });
      return of(true);
    } else {
      return this.firstCustomizablePageRedirect();
    }
  }

  closeOnboardingPopup() {
    if (this.onboardingPopup) {
      this.popupService.remove(this.onboardingPopup);
      this.popupService = undefined;
    }
  }

  openOnboarding(options: ChooseTemplateOptions = {}) {
    this.closeOnboardingPopup();

    this.chooseTemplateService
      .chooseTemplate(this.injector, {
        chooseSection: false,
        title: 'Start creating your first App',
        initialSection: ChooseTemplateSection.Resources,
        newPage: true,
        analyticsSource: 'onboarding',
        analyticsInnerSource: 'onboarding',
        ...options
      })
      .subscribe(templateResult => {
        this.onboardingPopup = undefined;

        this.customizeService.requestPublish();
        this.redirectIfNeeded().subscribe();

        if (templateResult.resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.ResourceCreated, {
            ResourceID: templateResult.resource.typeItem.name,
            ResourceType: templateResult.resource.typeItem.resourceType,
            ResourceHasAutoCollections: templateResult.resource.typeItem.hasAutoCollections
          });
        } else if (templateResult.template && templateResult.template.type == TemplateItemType.AdminPanel) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.AdminPanelTemplateApplied, {
            TemplateID: templateResult.template.name,
            ResourceType: templateResult.template.resource ? templateResult.template.resource.name : undefined
          });
        } else if (templateResult.template) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.TemplateApplied, {
            TemplateID: templateResult.template.name
          });
        }

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ProjectCreate.StartBuildingApp, {
          ...(templateResult.template ? { TemplateID: templateResult.template.name } : {}),
          ...(templateResult.resource
            ? {
                ResourceID: templateResult.resource.typeItem.name,
                ResourceType: templateResult.resource.typeItem.resourceType,
                ResourceHasAutoCollections: templateResult.resource.typeItem.hasAutoCollections
              }
            : {}),
          ...(templateResult.blankApp ? { BlankApp: true } : {})
        });
      });

    this.onboardingPopup = this.popupService.last();
  }
}

import { FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import truncate from 'lodash/truncate';

import { ViewContext, ViewContextToken } from '@modules/customize';
import { InputValueType, isInputSet } from '@modules/fields';
import { contextToFormulaValue, getFormulaUsages } from '@modules/parameters';
import { isSet } from '@shared';

export function getFormulaStateAnalyticsParams(form: FormGroup, context?: ViewContext, initialValue?: Object): Object {
  const params = {
    ValueType: form.value['value_type'],
    IsSet: isInputSet(form.value),
    Valid: true,
    Dirty: !form.pristine
  };

  if (initialValue !== undefined) {
    params['Changed'] = !isEqual(initialValue, form.value);
  }

  let formula: string;

  if (form.value['value_type'] == InputValueType.Formula) {
    formula = form.value['formula_value'];
    params['FormulaValue'] = form.value['formula_value'];
  } else if (form.value['value_type'] == InputValueType.TextInputs) {
    formula = form.value['text_inputs_value'];
    params['TextInputsValue'] = form.value['text_inputs_value'];
  } else if (form.value['value_type'] == InputValueType.Js) {
    formula = form.value['js_value'];
    params['JsValue'] = isSet(form.value['js_value']) ? truncate(form.value['js_value'], { length: 60 }) : '';
  } else if (form.value['value_type'] == InputValueType.Context) {
    formula = contextToFormulaValue(form.value['context_value']);
    params['ContextValue'] = form.value['context_value'];
  } else if (form.value['value_type'] == InputValueType.StaticValue) {
    params['StaticValue'] = form.value['static_value'];
  }

  if (isSet(formula)) {
    try {
      const usages = getFormulaUsages(formula, context);

      params['UsedElements'] = usages.elements;
      params['UsedElementProperties'] = usages.elementProperties;
      params['UsedUser'] = usages.user;
      params['UsedUserProperties'] = usages.userProperties;
      params['UsedTeamProperties'] = usages.teamProperties;
      params['UsedPageParameters'] = usages.pageParameters;
      params['UsedFunctions'] = usages.functions;
    } catch (e) {
      params['Valid'] = false;
      params['Error'] = String(e);
    }
  }

  return params;
}

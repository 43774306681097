import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { VideoFieldDataParamsForm } from './video-field-data-params.form';

@Component({
  selector: 'app-file-field-data-params',
  templateUrl: './video-field-data-params.component.html',
  providers: [VideoFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();

  constructor(public form: VideoFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.Video, VideoFieldDataParamsComponent);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsTodayFieldLookup = /** @class */ (function (_super) {
    __extends(IsTodayFieldLookup, _super);
    function IsTodayFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_today';
        _this.param = 'isToday';
        _this.verboseName = localize('is Today');
        _this.icon = 'calendar';
        return _this;
    }
    IsTodayFieldLookup.prototype.from = function (value) {
        return moment().startOf('day').toISOString();
    };
    IsTodayFieldLookup.prototype.to = function (value) {
        return moment().endOf('day').toISOString();
    };
    IsTodayFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not today', [field]);
            }
            else {
                return localize('is not today');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is today', [field]);
            }
            else {
                return localize('today');
            }
        }
    };
    return IsTodayFieldLookup;
}(BetweenFieldLookup));
export { IsTodayFieldLookup };

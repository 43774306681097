import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';

import { TaskQueue } from '../../data/task-queue';

@Injectable({
  providedIn: 'root'
})
export class TaskQueueService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private parameterCodec: CustomHttpParameterCodec
  ) {}

  get(projectName: string, environmentName: string, params = {}, draft = false): Observable<TaskQueue[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({
          fromObject: {
            ...(draft && { draft: '1' }),
            ...params
          },
          encoder: this.parameterCodec
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new TaskQueue().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(projectName: string, environmentName: string, instances: TaskQueue[]): Observable<TaskQueue[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new TaskQueue().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: TaskQueue): Observable<TaskQueue> {
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'task_queues/');
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, instance.serialize(), { headers: headers });
    //   }),
    //   map(result => new TaskQueue().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  update(projectName: string, environmentName: string, instance: TaskQueue): Observable<TaskQueue> {
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `task_queues/${instance.uid}/`);
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, instance.serialize(), { headers: headers });
    //   }),
    //   map(result => new TaskQueue().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, instance: TaskQueue): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `task_queues/${instance.uid}/`);
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers });
    //   }),
    //   map(() => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}

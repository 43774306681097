import { localize } from '@common/localize';
import { isSet } from '@shared';

import { FieldLookup } from './base';

export class EndsWithFieldLookup extends FieldLookup {
  public lookup = 'iendswith';
  public param = 'endsWithI';
  public verboseName = localize('ends with');
  public icon = 'ends_with';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} does not end with {1}', [field, value]);
      } else {
        return localize('does not end with');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} ends with {1}', [field, value]);
      } else {
        return localize('ends with');
      }
    }
  }
}

<div class="pagination">
  <a
    href="javascript:void(0)"
    class="pagination__control icon-arrow_backward_2"
    [class.pagination__control_disabled]="!prevPage"
    (click)="openPage(prevPage)"
  >
  </a>

  <div class="pagination__pages">
    <ng-container *ngFor="let item of items; trackBy: trackByFn">
      <a
        *ngIf="item.type == 'page'"
        href="javascript:void(0)"
        class="pagination__pages-item"
        [class.pagination__pages-item_selected]="current == item.page"
        (click)="openPage(item.page)"
      >
        {{ item.page }}
      </a>
      <span *ngIf="item.type == 'separator'" class="pagination__pages-item pagination__pages-item_disabled">
        ...
      </span>
    </ng-container>
  </div>

  <a
    href="javascript:void(0)"
    class="pagination__control icon-arrow_forward_2"
    [class.pagination__control_disabled]="!nextPage"
    (click)="openPage(nextPage)"
  >
  </a>
</div>

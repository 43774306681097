var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { AppError, isSet } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
export var AiDatabaseEngine;
(function (AiDatabaseEngine) {
    AiDatabaseEngine["PostgresSQL"] = "PostgresSQL";
    AiDatabaseEngine["MySQL"] = "MySQL";
    AiDatabaseEngine["MicrosoftSQL"] = "MicrosoftSQL";
    AiDatabaseEngine["Oracle"] = "Oracle";
    AiDatabaseEngine["BigQuery"] = "BigQuery";
    AiDatabaseEngine["Snowflake"] = "Snowflake";
    AiDatabaseEngine["SQLite"] = "SQLite";
})(AiDatabaseEngine || (AiDatabaseEngine = {}));
var AiService = /** @class */ (function () {
    function AiService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    AiService.prototype.getSqlByNaturalText = function (query, tables, engine) {
        var _this = this;
        if (!isSet(query)) {
            return of(undefined);
        }
        if (!tables.length) {
            return throwError(new AppError('No tables found in resource'));
        }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('ai/convert_text_to_sql');
            var headers = new HttpHeaders();
            var data = __assign({ query: query, tables: tables }, (isSet(engine) && { database_engine: engine }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.sql_query; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AiService.ngInjectableDef = i0.defineInjectable({ factory: function AiService_Factory() { return new AiService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: AiService, providedIn: "root" });
    return AiService;
}());
export { AiService };

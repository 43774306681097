<div class="card-row collaboration-tasks__row">
  <div class="card-row__inner">
    <div class="card-row__col">
      <div class="card-row__col-inner">
        <div [style.background-image]="'none'" class="collaboration-tasks__list-avatar"></div>
        <div class="collaboration-tasks__list-assigned">
          <span class="collaboration-tasks__list-assigned-name">
            <span [class.loading-animation]="true">
              <span class="stub-text">Some Name</span>
            </span>
          </span>
          <span class="collaboration-tasks__list-date">
            <span [class.loading-animation]="true">
              <span class="stub-text">Some Data</span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <span class="collaboration-tasks__list-name">
          <span [class.loading-animation]="true">
            <span class="stub-text">Some Name</span>
          </span>
        </span>
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <p class="collaboration-tasks__list-page">
          <span [class.loading-animation]="true">
            <span class="stub-text">Some description...</span>
          </span>
        </p>
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <span [class.loading-animation]="true">
          <span class="stub-text">Counter</span>
        </span>
      </div>
    </div>
    <div class="card-row__col" style="overflow: visible;">
      <div class="card-row__col-inner">
        <span [class.loading-animation]="true">
          <span class="stub-text">Button Button</span>
        </span>
      </div>
    </div>
  </div>
</div>

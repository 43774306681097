import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UniqueIdToken } from '@common/unique-id';
import { LocalStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { FeatureService } from '@modules/features';
import { FormField } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItem3rdParty, isResourceTypeItemCustom, ResourceName, resourceTypeItems } from '@modules/projects';
import { controlValue, deployUrl, objectsSortPredicate } from '@shared';
import { getResourceSettingsComponent } from '../../data/resource-settings-components';
import { ResourceEditController } from '../../services/resource-edit-controller/resource-edit.controller';
import { ResourceSummaryService } from '../../services/resource-summary/resource-summary.service';
var ResourceFieldComponent = /** @class */ (function () {
    function ResourceFieldComponent(currentProjectStore, currentEnvironmentStore, resourceEditController, resourceSummaryService, featureService, analyticsService, cd, localStorage) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceEditController = resourceEditController;
        this.resourceSummaryService = resourceSummaryService;
        this.featureService = featureService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.localStorage = localStorage;
        this.readonly = false;
        this.label = true;
        this.errors = true;
        this.card = false;
        this.autofocus = false;
        this.context = {};
        this.manualMargin = false;
        this.idToken = new UniqueIdToken();
        this.valueResourceSettings = false;
        this.resourceItems = [];
    }
    ResourceFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentEnvironmentStore.resources$
            .pipe(untilDestroyed(this))
            .subscribe(function (resourcesAll) { return _this.updateItems(resourcesAll); });
        controlValue(this.control)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.currentValue = value;
            _this.updateValue();
        });
    };
    ResourceFieldComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(ResourceFieldComponent.prototype, "control", {
        get: function () {
            if (!this.form || !this.field) {
                return;
            }
            return this.form.controls[this.field.name];
        },
        enumerable: true,
        configurable: true
    });
    ResourceFieldComponent.prototype.isResourceEnabled = function (typeItem, resource) {
        if (!typeItem) {
            return false;
        }
        if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
            typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; });
        }
        var modelResources = this.field.params['model_resources'];
        var actionResources = this.field.params['action_resources'];
        var storageResources = this.field.params['storage_resources'];
        var chartsResources = this.field.params['charts_resources'];
        if ([modelResources, actionResources, storageResources, chartsResources].every(function (item) { return !item; })) {
            return true;
        }
        if (modelResources && typeItem.modelsEnabled) {
            return true;
        }
        else if (actionResources && typeItem.actionsEnabled) {
            return true;
        }
        else if (storageResources && typeItem.storagesEnabled) {
            return true;
        }
        else if (chartsResources && typeItem.chartsEnabled) {
            return true;
        }
        else {
            return false;
        }
    };
    ResourceFieldComponent.prototype.updateValue = function () {
        var _this = this;
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.currentValue; });
        this.valueResource = resource;
        this.valueResourceSettings = resource ? !!getResourceSettingsComponent(resource.typeItem.name) : false;
        this.valueStr = resource ? resource.name : undefined;
        this.valueIcon = resource ? resource.icon : undefined;
        this.cd.markForCheck();
    };
    ResourceFieldComponent.prototype.updateItems = function (resourcesAll) {
        var _this = this;
        var resourceTypes = resourceTypeItems.filter(function (item) { return !item.hidden && _this.isResourceEnabled(item); });
        var userResourcesKey = "last_used_resources_" + this.currentProjectStore.uniqueName;
        var localStorageResources = this.localStorage.get(userResourcesKey);
        var lastUsedResources = localStorageResources ? JSON.parse(localStorageResources) : {};
        var defaultPredicate = objectsSortPredicate('demo', 'name');
        var resources = resourcesAll
            ? resourcesAll
                .filter(function (item) { return _this.isResourceEnabled(item.typeItem, item); })
                .sort(function (lhs, rhs) {
                var lhsLastUsed = lastUsedResources[lhs.uniqueName]
                    ? moment(lastUsedResources[lhs.uniqueName])
                    : undefined;
                var rhsLastUsed = lastUsedResources[rhs.uniqueName]
                    ? moment(lastUsedResources[rhs.uniqueName])
                    : undefined;
                if (!lhsLastUsed && !rhsLastUsed) {
                    return defaultPredicate(lhs, rhs);
                }
                else if (lhsLastUsed && !rhsLastUsed) {
                    return -1;
                }
                else if (!lhsLastUsed && rhsLastUsed) {
                    return 1;
                }
                else if (lhsLastUsed && rhsLastUsed) {
                    return (lhsLastUsed.unix() - rhsLastUsed.unix()) * -1;
                }
            })
            : [];
        var userResources = resources.filter(function (item) { return !item.demo; });
        var demoResources = resources.filter(function (item) { return item.demo && (item.featured || _this.control.value == item.uniqueName); });
        this.resourceItems = userResources.length
            ? [
                {
                    button: {
                        icon: 'plus',
                        label: 'Connect Resource'
                    },
                    children: resourceTypes
                        .filter(function (item) { return !item.protected; })
                        .map(function (item) {
                        return {
                            button: {
                                name: 'add_resource',
                                label: item.label,
                                image: deployUrl("/assets/images/resources/icons/" + item.icon + ".svg"),
                                data: { resource: item }
                            }
                        };
                    }),
                    stickyTop: true,
                    orange: true
                }
            ].concat(userResources.map(function (item) {
                return {
                    option: {
                        value: item.uniqueName,
                        name: item.name,
                        image: item.icon
                    }
                };
            }), [
                {
                    button: {
                        icon: 'play_2',
                        label: 'Demo'
                    },
                    children: demoResources.map(function (item) {
                        return {
                            option: {
                                value: item.uniqueName,
                                name: item.name,
                                image: item.icon
                            },
                            valueTag: 'DEMO'
                        };
                    }),
                    stickyBottom: true,
                    large: true
                }
            ]) : resourceTypes.map(function (item) {
            return {
                button: {
                    name: 'add_resource',
                    label: item.label,
                    image: deployUrl("/assets/images/resources/icons/" + item.icon + ".svg"),
                    data: { resource: item }
                },
                subtitle: 'Connect Resource'
            };
        }).concat([
            {
                button: {
                    icon: 'play_2',
                    label: 'Demo'
                },
                children: demoResources.map(function (item) {
                    return {
                        option: {
                            value: item.uniqueName,
                            name: item.name,
                            image: item.icon
                        },
                        valueTag: 'DEMO'
                    };
                }),
                stickyBottom: true,
                large: true
            }
        ]);
        this.cd.markForCheck();
    };
    ResourceFieldComponent.prototype.openResourceSettings = function (typeItem, resource) {
        var _this = this;
        if (isResourceTypeItem3rdParty(typeItem) &&
            !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Business Apps</strong>',
                description: "\n          Connect data from various business apps. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        else if (isResourceTypeItemCustom(typeItem) &&
            !this.currentProjectStore.instance.features.isCustomResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Custom Queries</strong>',
                description: "\n          Connect data from custom queries. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        if (!resource) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
                Source: 'component',
                ResourceID: typeItem.name
            });
        }
        this.resourceEditController
            .openEditPopup(typeItem, resource, {
            resourceNameEditing: true,
            analyticsSource: 'component'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result.resource) {
                return;
            }
            _this.selectExistingResource(result.resource);
            _this.updateValue();
            // if (!resource) {
            //   this.resourceSummaryService.open(result.resource).subscribe();
            // }
        });
    };
    ResourceFieldComponent.prototype.selectExistingResource = function (resource) {
        var value = this.form.value[this.field.name];
        var resourceName = resource ? resource.uniqueName : undefined;
        if (resourceName) {
            this.updateLastUsed(resourceName);
        }
        if (value != resourceName) {
            if (this.control) {
                this.control.patchValue(resourceName);
                this.control.markAsDirty();
            }
        }
    };
    ResourceFieldComponent.prototype.updateLastUsed = function (resourceName) {
        var userResourcesKey = "last_used_resources_" + this.currentProjectStore.uniqueName;
        var resources = this.localStorage.get(userResourcesKey);
        var lastUsedResources = resources ? JSON.parse(resources) : {};
        lastUsedResources[resourceName] = moment().toISOString();
        this.localStorage.set(userResourcesKey, JSON.stringify(lastUsedResources));
        this.updateItems(this.currentEnvironmentStore.resources);
    };
    ResourceFieldComponent.prototype.onOptionClick = function (option) {
        if (option.value) {
            this.updateLastUsed(option.value);
        }
    };
    ResourceFieldComponent.prototype.onButtonClick = function (button) {
        if (button.name == 'add_resource') {
            this.openResourceSettings(button.data['resource']);
        }
    };
    return ResourceFieldComponent;
}());
export { ResourceFieldComponent };

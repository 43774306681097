/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./toast.component";
import * as i3 from "../../../core/services/local-storage/local.storage";
var styles_ToastComponent = [];
var RenderType_ToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
export function View_ToastComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "toast2"]], [[2, "toast2_info", null], [2, "toast2_warning", null], [2, "toast2_visible", null], [2, "toast2_expands", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "toast2__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "toast2__description"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "toast2__buttons"]], [[2, "toast2__buttons_center", null]], null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "toast2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "toast2__icon"]], null, null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "toast2__main toast2__title"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "toast2__close icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setMinimized(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "toast2-badge"]], [[2, "toast2-badge_visible", null], [2, "toast2-badge_info", null], [2, "toast2-badge_warning", null], [2, "toast2-badge_shift", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setMinimized(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "toast2__icon"; var currVal_6 = (_co.icon ? ("icon-" + _co.icon) : "icon-warning_filled"); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_11 = "toast2-badge"; var currVal_12 = (_co.icon ? ("icon-" + _co.icon) : "icon-warning_filled"); _ck(_v, 13, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; var currVal_1 = _co.warning; var currVal_2 = !_co.minimized; var currVal_3 = _co.expands; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.buttonsCenter; _ck(_v, 4, 0, currVal_4); var currVal_7 = _co.minimized; var currVal_8 = _co.info; var currVal_9 = _co.warning; var currVal_10 = _co.shift; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_ToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i0.ɵdid(1, 638976, null, 0, i2.ToastComponent, [i3.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i0.ɵccf("app-toast", i2.ToastComponent, View_ToastComponent_Host_0, { warning: "warning", info: "info", icon: "icon", uniqueName: "uniqueName", expands: "expands", buttonsCenter: "buttonsCenter", shift: "shift" }, {}, ["[data-description]", "[data-buttons]", "[data-title]"]);
export { ToastComponentNgFactory as ToastComponentNgFactory };

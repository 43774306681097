import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { JsonStructureNode } from '@modules/fields';

@Component({
  selector: 'app-json-field-structure',
  templateUrl: './json-field-structure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldStructureComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(e => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  get value(): JsonStructureNode {
    return this.control.value;
  }

  onChanged(value: JsonStructureNode) {
    this.control.patchValue(value);
  }

  onWheel(event: WheelEvent) {
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      event.stopPropagation();
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval, merge, Subscription } from 'rxjs';
import { bufferCount, filter, map, startWith, tap } from 'rxjs/operators';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { BasePopupComponent, PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import { Frame, FrameTranslate, Layer, SideConstraint, SizeConstraint, Translate } from '@modules/views';
import {
  getOffset,
  isSet,
  KeyboardEventKeyCode,
  MouseButton,
  noKeyModifiers,
  parseNumber,
  pointsDistance,
  TypedChanges
} from '@shared';

import { getLayerComponent } from '../../data/layer-components';
import {
  CreatedLayer,
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../services/view-editor-context/view-editor.context';
import { ViewEditorMultipleLayers } from '../../services/view-editor-multiple-layers/view-editor-multiple-layers';
import { measureFrames, snapFrame } from '../../utils/guides';
import { isViewBoundsMouseDown } from '../layers/base/layer-resizable.directive';
import { LayerComponent } from '../layers/base/layer.component';

const markViewLayerClickProperty = '_markViewLayerClickProperty';

export function markViewLayerClick(clickEvent: MouseEvent) {
  clickEvent[markViewLayerClickProperty] = true;
}

export function isViewLayerClick(clickEvent: MouseEvent) {
  return !!clickEvent[markViewLayerClickProperty];
}

@Component({
  selector: 'app-auto-layer',
  templateUrl: './auto-layer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoLayerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() layer: Layer;
  @Input() container: Layer[];
  @Input() containerElement: HTMLElement;
  @Input() frame: Frame;
  @Input() layout = false;
  @Input() translate: Translate = { x: 0, y: 0 };
  @Input() viewContext: ViewContext;
  @Input() analyticsSource: string;
  @Output() layerDelete = new EventEmitter<void>();

  @ViewChild('root_element') rootElement: ElementRef;
  @ViewChild(DynamicComponent) dynamicComponent: DynamicComponent<LayerComponent>;

  @HostBinding('attr.data-layer-id') get layerId() {
    return this.layer.id;
  }

  @HostBinding('attr.data-layer-name') get layerName() {
    return this.layer.name;
  }

  componentData: DynamicComponentArguments<LayerComponent>;
  createdLayer: CreatedLayer;
  layerUpdated$ = this.editorContext
    ? this.editorContext.layerChanged$().pipe(
        filter(event => event.layer.isSame(this.layer) && event.source != ViewEditorCustomizeSource.Layer),
        map(event => event.layer)
      )
    : undefined;
  loadingExecute = false;
  moveThreshold = 5;
  framesMeasuring = false;
  framesMeasuringSubscriptions: Subscription[] = [];

  constructor(
    @Optional() private editorContext: ViewEditorContext,
    @Optional() private multipleLayers: ViewEditorMultipleLayers,
    private popupService: PopupService,
    @Optional() private popupComponent: BasePopupComponent,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.initComponent();

    if (this.editorContext) {
      this.editorContext
        .trackKeydown()
        .pipe(
          filter(() => this.editorContext.isCustomizingLayer(this.layer)),
          untilDestroyed(this)
        )
        .subscribe(e => {
          let moveLayer: { updateFrame: Partial<Frame>; addFrame: Partial<Frame> };

          if (e.keyCode == KeyboardEventKeyCode.ArrowUp) {
            const delta = e.shiftKey ? 10 : 1;
            moveLayer = { updateFrame: { y: this.layer.frame.y - delta }, addFrame: { y: -delta } };
          } else if (e.keyCode == KeyboardEventKeyCode.ArrowRight) {
            const delta = e.shiftKey ? 10 : 1;
            moveLayer = { updateFrame: { x: this.layer.frame.x + delta }, addFrame: { x: delta } };
          } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown) {
            const delta = e.shiftKey ? 10 : 1;
            moveLayer = { updateFrame: { y: this.layer.frame.y + delta }, addFrame: { y: delta } };
          } else if (e.keyCode == KeyboardEventKeyCode.ArrowLeft) {
            const delta = e.shiftKey ? 10 : 1;
            moveLayer = { updateFrame: { x: this.layer.frame.x - delta }, addFrame: { x: -delta } };
          }

          if (moveLayer) {
            e.stopPropagation();

            if (this.editorContext.isCustomizingMultipleLayers()) {
              this.multipleLayers.patchFrame(moveLayer.addFrame, { add: true });
            } else {
              this.patchLayerFrame(moveLayer.updateFrame);
              this.showSnapGuides();
            }
          } else if (e.keyCode == KeyboardEventKeyCode.Alt && this.editorContext.isCustomizingLayer(this.layer)) {
            e.stopPropagation();
            this.startFramesMeasure();
          }
        });

      this.editorContext
        .trackKeydown()
        .pipe(
          filter(() => this.editorContext.isCustomizingLayer(this.layer)),
          filter(e => {
            return (
              e.keyCode >= KeyboardEventKeyCode.Number0 &&
              e.keyCode <= KeyboardEventKeyCode.Number9 &&
              noKeyModifiers(e)
            );
          }),
          tap(e => e.stopPropagation()),
          map(e => ({ number: e.keyCode - KeyboardEventKeyCode.Number0, date: new Date() })),
          startWith(undefined),
          bufferCount(2, 1),
          map(value => value.filter(item => item && new Date().getTime() - item.date.getTime() <= 500)),
          untilDestroyed(this)
        )
        .subscribe(value => {
          if (value.length == 1 && value[0].number === 0) {
            this.layer.opacity = 1;
          } else {
            this.layer.opacity = value.reduce((acc, item, i) => acc + item.number * Math.pow(10, -(i + 1)), 0);
          }

          this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.AutoLayer);
        });

      merge(
        this.editorContext
          .layerChanged$()
          .pipe(filter(event => event.layer.isSame(this.layer) && event.source != ViewEditorCustomizeSource.AutoLayer)),
        this.editorContext
          .globalLayersChange$()
          .pipe(filter(event => event.source != ViewEditorCustomizeSource.AutoLayer))
      )
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.updateLayerFrameTransform();
          this.updateFramesMeasure();
        });

      interval(1000)
        .pipe(
          filter(() => !this.editorContext.movingLayer$.value && !this.editorContext.resizingLayer$.value),
          untilDestroyed(this)
        )
        .subscribe(() => this.updateLayoutFrame());
    }
  }

  ngOnDestroy(): void {
    if (this.editorContext) {
      this.editorContext.removeHoverLayer(this.layer, this);
      this.editorContext.unregisterLayerContainer(this.layer);
    }
  }

  ngOnChanges(changes: TypedChanges<AutoLayerComponent>): void {
    if ([changes.layer, changes.container, changes.translate].some(item => item && !item.firstChange)) {
      if (
        changes.layer &&
        (changes.layer.previousValue ? changes.layer.previousValue.type : undefined) !==
          (changes.layer.currentValue ? changes.layer.currentValue.type : undefined)
      ) {
        this.initComponent();
      } else {
        this.updateComponent();
      }
    }

    if (changes.layer) {
      if (this.editorContext) {
        this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.HistoryMove);
      } else {
        this.updateLayerFrameTransform();
        this.updateFramesMeasure();
      }
    }

    if (changes.layout) {
      this.updateLayerFrameTransform();
      this.updateFramesMeasure();
    }

    if (changes.container && this.editorContext) {
      this.editorContext.registerLayerContainer(this.layer, this.container);
    }
  }

  ngAfterViewInit(): void {
    this.updateLayerFrameTransform();

    if (this.editorContext) {
      this.zone.runOutsideAngular(() => {
        fromEvent<MouseEvent>(this.rootElement.nativeElement, 'mousedown')
          .pipe(
            filter(() => !this.layout),
            filter(e => e.button == MouseButton.Main),
            filter(e => {
              return (
                !this.editorContext.isCreateTool() &&
                !isViewBoundsMouseDown(e) &&
                !this.editorContext.customizingGradient$.value &&
                !this.editorContext.navigateMode$.value
              );
            }),
            filter(() => this.editorContext.isTopHoverLayer(this.layer)),
            filter(() => {
              if (this.editorContext.isCustomizingMultipleLayers()) {
                return this.editorContext.customizingLayers$.value.some(item => item.isSame(this.layer));
              } else {
                return true;
              }
            }),
            untilDestroyed(this)
          )
          .subscribe(downEvent => {
            const subscriptions = [];
            const originalX = this.layer.frame.x;
            const originalY = this.layer.frame.y;
            let thresholdPassed = false;
            const originalMultipleLayers = cloneDeep(this.editorContext.customizingLayers$.value);
            const originalMultipleLayersFrame = this.multipleLayers.frame$.value;
            const exceptLayers = this.editorContext.isCustomizingMultipleLayers()
              ? this.editorContext.customizingLayers$.value
              : [this.layer];
            const otherFrames = this.editorContext.getFrames({
              onlyContainers: [this.container],
              exceptLayers: exceptLayers
            });

            downEvent.stopPropagation();

            subscriptions.push(
              fromEvent<MouseEvent>(document, 'mousemove')
                .pipe(untilDestroyed(this))
                .subscribe(moveEvent => {
                  moveEvent.preventDefault();

                  if (!thresholdPassed) {
                    const originDistance = pointsDistance(
                      downEvent.clientX,
                      downEvent.clientY,
                      moveEvent.clientX,
                      moveEvent.clientY
                    );
                    if (originDistance >= this.moveThreshold) {
                      thresholdPassed = true;
                      this.editorContext.movingLayer$.next(this.layer);
                      this.editorContext.removeHorizontalGuides();
                      this.editorContext.removeVerticalGuides();
                    }
                  }

                  if (thresholdPassed) {
                    const scale = this.editorContext.viewportScale$.value;
                    const deltaX = (moveEvent.clientX - downEvent.clientX) / scale;
                    const deltaY = (moveEvent.clientY - downEvent.clientY) / scale;

                    if (this.editorContext.isCustomizingMultipleLayers()) {
                      const newFrame = new Frame(originalMultipleLayersFrame).patch({
                        x: Math.round(originalMultipleLayersFrame.x + deltaX),
                        y: Math.round(originalMultipleLayersFrame.y + deltaY)
                      });
                      const snap = snapFrame({
                        base: new FrameTranslate({
                          frame: newFrame
                        }),
                        others: otherFrames,
                        horizontalSnapX: true,
                        verticalSnapY: true
                      });

                      newFrame.patch(snap.updateFrame);

                      this.multipleLayers.patchFrame(
                        {
                          ...(newFrame.x != originalMultipleLayersFrame.x && { x: newFrame.x }),
                          ...(newFrame.y != originalMultipleLayersFrame.y && { y: newFrame.y })
                        },
                        {
                          originalFrame: originalMultipleLayersFrame,
                          originalLayers: originalMultipleLayers
                        }
                      );
                    } else {
                      const container = this.editorContext.getContainer(this.container);
                      const newFrame = new Frame(this.layer.frame).patch({
                        x: Math.round(originalX + deltaX),
                        y: Math.round(originalY + deltaY)
                      });
                      const snap = snapFrame({
                        base: new FrameTranslate({
                          frame: newFrame,
                          translate: container ? container.options.translate : undefined
                        }),
                        others: otherFrames,
                        horizontalSnapX: true,
                        verticalSnapY: true
                      });

                      newFrame.patch(snap.updateFrame);

                      this.editorContext.setHorizontalGuides(snap.horizontalGuides);
                      this.editorContext.setVerticalGuides(snap.verticalGuides);

                      const updateFrame = newFrame.diff(this.layer.frame);
                      this.patchLayerFrame(updateFrame);

                      if (!this.editorContext.isCustomizingLayer(this.layer)) {
                        this.customize();
                      }
                    }
                  }
                })
            );

            subscriptions.push(
              fromEvent<MouseEvent>(document, 'mouseup')
                .pipe(
                  filter(e => e.button == MouseButton.Main),
                  untilDestroyed(this)
                )
                .subscribe(upEvent => {
                  upEvent.preventDefault();
                  subscriptions.forEach(item => item.unsubscribe());

                  this.editorContext.movingLayer$.next(undefined);
                  this.editorContext.removeHorizontalGuides();
                  this.editorContext.removeVerticalGuides();

                  this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.AutoLayer);
                })
            );
          });
      });
    }
  }

  startFramesMeasure() {
    const subscriptions = [];

    this.framesMeasuring = true;
    this.framesMeasuringSubscriptions = subscriptions;

    this.editorContext.removeHorizontalGuides();
    this.editorContext.removeVerticalGuides();

    subscriptions.push(
      this.editorContext
        .getTopHoverLayer$()
        .pipe(untilDestroyed(this))
        .subscribe(() => this.updateFramesMeasure())
    );

    subscriptions.push(
      merge(
        fromEvent<KeyboardEvent>(document, 'keyup').pipe(
          filter(e => e.keyCode == KeyboardEventKeyCode.Alt),
          tap(e => e.preventDefault())
        ),
        this.editorContext.resizingLayer$.pipe(filter(layer => !!layer))
      )
        .pipe(untilDestroyed(this))
        .subscribe(() => this.stopFramesMeasure())
    );
  }

  updateFramesMeasure() {
    if (!this.framesMeasuring) {
      return;
    }

    const hoverLayer = this.editorContext.getTopHoverLayer();
    let hoverFrame: FrameTranslate;

    if (hoverLayer && !hoverLayer.isSame(this.layer)) {
      const container = this.editorContext.getLayerContainer(hoverLayer);

      hoverFrame = new FrameTranslate({
        frame: hoverLayer.frame,
        translate: container ? container.options.translate : undefined
      });
    } else if (!hoverLayer) {
      hoverFrame = new FrameTranslate({
        frame: this.editorContext.view$.value.frame
      });
    }

    if (hoverFrame) {
      const container = this.editorContext.getContainer(this.container);
      const frame = new FrameTranslate({
        frame: this.layer.frame,
        translate: container ? container.options.translate : undefined
      });
      const measures = measureFrames(frame, hoverFrame);

      this.editorContext.setHorizontalGuides(measures.horizontalGuides);
      this.editorContext.setVerticalGuides(measures.verticalGuides);
    } else {
      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
    }
  }

  stopFramesMeasure() {
    this.framesMeasuringSubscriptions.forEach(item => item.unsubscribe());
    this.framesMeasuringSubscriptions = [];

    this.editorContext.removeHorizontalGuides();
    this.editorContext.removeVerticalGuides();

    this.framesMeasuring = false;
  }

  getComponentInputs(): Partial<LayerComponent> {
    return {
      layer: this.layer,
      layerUpdated$: this.layerUpdated$,
      container: this.container,
      translate: this.translate,
      createdLayer: this.createdLayer,
      customizeEnabled: !!this.editorContext,
      viewContext: this.viewContext,
      analyticsSource: this.analyticsSource
    };
  }

  initComponent() {
    const component = getLayerComponent(this.layer.type);

    if (!component) {
      this.componentData = undefined;
      this.cd.markForCheck();
      console.error(`No such layer type registered: ${this.layer.type}`);
      return;
    }

    if (this.editorContext) {
      this.createdLayer = this.editorContext.initCreatedLayer(this.layer);
    }

    this.componentData = {
      component: component,
      inputs: this.getComponentInputs(),
      outputs: {
        layerCustomizeMouseEnter: [
          () => {
            this.updateLayoutFrame();
            this.editorContext.addHoverLayer(this.layer, this);
          }
        ],
        layerCustomizeMouseLeave: [
          () => {
            this.editorContext.removeHoverLayer(this.layer, this);
          }
        ],
        layerCustomize: [
          () => {
            this.customize();
          }
        ],
        layerAddCustomizing: [
          () => {
            this.addCustomizing();
          }
        ],
        layerRemoveCustomizing: [
          () => {
            this.removeCustomizing();
          }
        ],
        layerDelete: [
          () => {
            this.delete();
          }
        ],
        updateFrame: [
          frame => {
            this.patchLayerFrame(frame, ViewEditorCustomizeSource.Layer);
          }
        ]
      }
    };
    this.cd.markForCheck();
  }

  updateComponent(options: { forcePropUpdate?: keyof LayerComponent } = {}) {
    if (
      !this.dynamicComponent ||
      !this.dynamicComponent.currentComponent ||
      !this.dynamicComponent.currentComponent.instance
    ) {
      return;
    }

    const ref = this.dynamicComponent.currentComponent;
    const inputs = this.getComponentInputs();
    const changes: TypedChanges<LayerComponent> = toPairs(inputs).reduce((acc, [prop, currentValue]) => {
      const prevValue = ref.instance[prop];

      if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
        acc[prop] = new SimpleChange(prevValue, currentValue, false);
        ref.instance[prop] = currentValue;
      }

      return acc;
    }, {});

    this.componentData.inputs = inputs;

    if (values(changes).length) {
      if (ref.instance['ngOnChanges']) {
        ref.instance['ngOnChanges'](changes);
      }

      ref.changeDetectorRef.markForCheck();
    }
  }

  updateLayerFrameTransform() {
    if (!this.rootElement) {
      return;
    }

    const rotation = parseNumber(this.layer.frame.rotation, 0);
    const attrs: { [k: string]: any } = {
      'mix-blend-mode': this.layer.cssMixBlendMode()
    };
    let hasTransform = false;

    if (rotation) {
      attrs['rotation'] = rotation;
      hasTransform = true;
    } else {
      attrs['rotation'] = 0;
    }

    if (this.layer.reflectHorizontal) {
      attrs['scaleX'] = -1;
      hasTransform = true;
    } else {
      attrs['scaleX'] = 1;
    }

    if (this.layer.reflectVertical) {
      attrs['scaleY'] = -1;
      hasTransform = true;
    } else {
      attrs['scaleY'] = 1;
    }

    if (this.layout) {
      if (hasTransform) {
        attrs['x'] = 0;
        attrs['y'] = 0;
      }

      attrs['width'] = this.layer.frame.width;
      attrs['height'] = this.layer.frame.height;
    } else if (this.editorContext) {
      attrs['top'] = 0;
      attrs['left'] = 0;
      attrs['x'] = this.layer.frame.x;
      attrs['y'] = this.layer.frame.y;
      attrs['width'] = this.layer.frame.width;
      attrs['height'] = this.layer.frame.height;

      hasTransform = true;
    } else {
      const constraints = this.layer.getConstraintsOrDefault();

      if (constraints.isHorizontalSides(SideConstraint.Fixed)) {
        attrs['left'] = this.layer.frame.x;
        attrs['right'] = this.frame.width - (this.layer.frame.x + this.layer.frame.width);
      } else if (constraints.width == SizeConstraint.Fluid) {
        const width = (this.layer.frame.width / this.frame.width) * 100;
        attrs['width'] = `${width}%`;
      } else {
        attrs['width'] = this.layer.frame.width;
      }

      if (constraints.isVerticalSides(SideConstraint.Fixed)) {
        attrs['top'] = this.layer.frame.y;
        attrs['bottom'] = this.frame.height - (this.layer.frame.y + this.layer.frame.height);
      } else if (constraints.height == SizeConstraint.Fluid) {
        const height = (this.layer.frame.height / this.frame.height) * 100;
        attrs['height'] = `${height}%`;
      } else {
        attrs['height'] = this.layer.frame.height;
      }

      if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fluid) {
        const xCenter = this.layer.frame.x + this.layer.frame.width * 0.5;
        const left = (xCenter / this.frame.width) * 100;
        attrs['left'] = `${left}%`;
        attrs['xPercent'] = -50;

        hasTransform = true;
      } else if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fixed) {
        attrs['right'] = this.frame.width - (this.layer.frame.x + this.layer.frame.width);
      } else {
        attrs['left'] = this.layer.frame.x;
      }

      if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fluid) {
        const yCenter = this.layer.frame.y + this.layer.frame.height * 0.5;
        const top = (yCenter / this.frame.height) * 100;
        attrs['top'] = `${top}%`;
        attrs['yPercent'] = -50;

        hasTransform = true;
      } else if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fixed) {
        attrs['bottom'] = this.frame.height - (this.layer.frame.y + this.layer.frame.height);
      } else {
        attrs['top'] = this.layer.frame.y;
      }
    }

    const clearProps: string[] = [];
    const clearableProps = ['top', 'right', 'bottom', 'left', 'width', 'height'];

    clearableProps.forEach(prop => {
      if (!attrs.hasOwnProperty(prop)) {
        clearProps.push(prop);
      }
    });

    if (this.layer.widthFluid) {
      clearProps.push('width');
    }

    if (this.layer.heightFluid) {
      clearProps.push('height');
    }

    if (!hasTransform) {
      clearProps.push('transform');
    }

    if (clearProps.length) {
      attrs['clearProps'] = clearProps.join(',');
    }

    TweenMax.set(this.rootElement.nativeElement, attrs);
  }

  updateLayoutFrame() {
    if (!this.layout) {
      return;
    }

    const offset = getOffset(this.rootElement.nativeElement, this.containerElement);
    const frame: Partial<Frame> = {};

    if (this.layer.frame.x != offset.left) {
      frame.x = offset.left;
    }

    if (this.layer.frame.y != offset.top) {
      frame.y = offset.top;
    }

    if (keys(frame).length) {
      this.patchLayerFrame(frame, ViewEditorCustomizeSource.AutoLayerBounds);
    }
  }

  patchLayerFrame(frame: Partial<Frame>, source: ViewEditorCustomizeSource = ViewEditorCustomizeSource.AutoLayer) {
    this.layer.frame.patch(frame);

    if (isSet(frame.width) && this.layer.widthFluid) {
      this.layer.widthFluid = false;
    }

    if (isSet(frame.height) && this.layer.heightFluid) {
      this.layer.heightFluid = false;
    }

    this.editorContext.markLayersChanged([this.layer], source);
    this.updateLayerFrameTransform();
    this.updateFramesMeasure();
  }

  showSnapGuides() {
    const container = this.editorContext.getContainer(this.container);
    const otherFrames = this.editorContext.getFrames({
      onlyContainers: [this.container],
      exceptLayers: [this.layer]
    });

    const snap = snapFrame({
      base: new FrameTranslate({
        frame: this.layer.frame,
        translate: container ? container.options.translate : undefined
      }),
      others: otherFrames,
      horizontalSnapX: true,
      verticalSnapY: true,
      snapDistance: 0
    });

    this.editorContext.setHorizontalGuides(snap.horizontalGuides, { durationMs: 600 });
    this.editorContext.setVerticalGuides(snap.verticalGuides, { durationMs: 600 });
  }

  delete() {
    this.removeCustomizing();
    this.layerDelete.emit();
  }

  customize() {
    this.editorContext.setCustomizingLayer(this.layer);
  }

  addCustomizing() {
    this.editorContext.addCustomizingLayer(this.layer);
  }

  removeCustomizing() {
    this.editorContext.removeCustomizingLayer(this.layer);
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    markViewLayerClick(e);
  }
}

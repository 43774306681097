<app-interactions-section-cursor-dropdown
  [value]="control.controls.cursor.value"
  [origin]="trigger"
  [forceOpened]="dropdownOpened"
  (selected)="control.controls.cursor.patchValue($event)"
  (close)="setDropdownOpened(false)"
></app-interactions-section-cursor-dropdown>

<app-compact-wide-button
  [label]="'Cursor'"
  [additional]="control.controls.cursor.value | appCapitalize"
  [interactive]="true"
  [leftIcon]="'cursor'"
  [leftBackground]="true"
  [rightIcon]="'close'"
  [rightInteractive]="true"
  [rightTooltip]="'Switch to default'"
  (click)="setDropdownOpened(true)"
  (rightClick)="arrayControl.removeControl(control)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
></app-compact-wide-button>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
var RatingFieldDataParamsForm = /** @class */ (function () {
    function RatingFieldDataParamsForm(fb) {
        this.fb = fb;
        this.form = this.fb.group({
            max_value: new FormControl(5),
            allow_half: new FormControl(false)
        });
    }
    RatingFieldDataParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            this.form.patchValue(control.value, { emitEvent: false });
        }
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    RatingFieldDataParamsForm.prototype.submit = function () {
        var value = this.form.value;
        var result = {
            max_value: value['max_value'],
            allow_half: value['allow_half']
        };
        this.control.patchValue(__assign({}, this.control.value, result));
    };
    return RatingFieldDataParamsForm;
}());
export { RatingFieldDataParamsForm };

<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || (titleEditable ? 'collapse' : 'Collapse')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <div class="sidebar__element">
          <app-customize-bar-input-edit
            [label]="'title'"
            [itemForm]="form.controls.title"
            [context]="context"
            [contextElement]="contextElement"
            [fill]="true"
            [analyticsSource]="element.analyticsGenericName + '_title'"
          ></app-customize-bar-input-edit>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'icon'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'icon',
                  label: 'Icon',
                  field: 'IconField',
                  params: { classes: ['select_fill'], allow_empty: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Is opened'">
            <app-input-edit
              [itemForm]="form.controls.opened_input"
              [context]="context"
              [contextElement]="contextElement"
              [staticValueField]="fieldTypes.Select"
              [staticValueParams]="{ options: openedOptions }"
              [placeholder]="'Closed by default'"
              [resetEnabled]="false"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_opened'"
            ></app-input-edit>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'tooltip'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'tooltip',
                  field: 'CharField',
                  placeholder: 'Show information for users',
                  reset_enabled: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'opened/closed indicator'">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                *ngFor="let item of form.collapseIndicatorPositionOptions"
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="form.controls.collapse_indicator_position.value == item.value"
                (click)="form.controls.collapse_indicator_position.setValue(item.value)"
              >
                <div class="sidebar-icon-button__label">{{ item.name }}</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.form.invalid || form.form.disabled"-->
  <!--    [errors]="form.form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--    (deleteClick)="delete()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first, switchMap } from 'rxjs/operators';
import { ViewContext, ViewSettingsStore } from '@modules/customize';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { createFormFieldFactory } from '@modules/fields';
import { getMenuItemSystemActionTypeInfo, MenuItemActionService, MenuItemActionType, menuItemSystemActionTypes } from '@modules/menu';
import { ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ascComparator, controlValue, isSet } from '@shared';
import { MenuItemActionControl } from '../customize-bar-pages-edit/menu-item-action.control';
var EditMenuItemActionComponent = /** @class */ (function () {
    function EditMenuItemActionComponent(menuItemActionService, viewSettingsStore, projectPropertyStore, projectPropertyEditController, cd) {
        this.menuItemActionService = menuItemActionService;
        this.viewSettingsStore = viewSettingsStore;
        this.projectPropertyStore = projectPropertyStore;
        this.projectPropertyEditController = projectPropertyEditController;
        this.cd = cd;
        this.selectPage = new EventEmitter();
        this.selectSystem = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.systemOptions = [];
        this.pages = [];
        this.propertyGroups = [];
        this.actionTypes = MenuItemActionType;
    }
    EditMenuItemActionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.systemOptions = menuItemSystemActionTypes
            .map(function (systemType) {
            var info = getMenuItemSystemActionTypeInfo(systemType);
            if (!info) {
                return;
            }
            return {
                type: systemType,
                label: info.label,
                actionLabel: info.actionLabel,
                icon: info.icon
            };
        })
            .filter(function (item) { return item !== undefined; });
        this.cd.markForCheck();
        this.viewSettingsStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (viewSettings) {
            _this.pages = viewSettings
                .filter(function (item) { return item.uniqueName; })
                .sort(function (lhs, rhs) {
                var lhsName = isSet(lhs.name) ? String(lhs.name).toLowerCase() : '';
                var rhsName = isSet(rhs.name) ? String(rhs.name).toLowerCase() : '';
                return ascComparator(lhsName, rhsName);
            });
            _this.cd.markForCheck();
        });
        this.menuItemActionService
            .getActionValueDisplay$(this.control, { excludeUrl: true })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.actionValueDisplay = value ? { label: value.label, icon: value.icon } : undefined;
            _this.cd.markForCheck();
        });
        this.control
            .getPageUid$()
            .pipe(switchMap(function (pageUid) { return _this.viewSettingsStore.getDetailByUid(pageUid); }), untilDestroyed(this))
            .subscribe(function (viewSettings) {
            _this.selectedPage = viewSettings;
            _this.cd.markForCheck();
        });
        this.control.updateInputFieldProvider().pipe(untilDestroyed(this)).subscribe();
        this.projectPropertyStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (properties) {
            var groups = [
                {
                    type: ProjectPropertyType.Global,
                    label: 'Globals',
                    addLabel: 'Add Global variable'
                },
                {
                    type: ProjectPropertyType.User,
                    label: 'Current user',
                    addLabel: 'Add User property'
                },
                {
                    type: ProjectPropertyType.Group,
                    label: 'Current team',
                    addLabel: 'Add Team property'
                }
            ];
            _this.propertyGroups = groups.map(function (group) {
                return __assign({}, group, { properties: properties.filter(function (item) { return item.type == group.type; }) });
            });
            _this.cd.markForCheck();
        });
        controlValue(this.control.controls.set_property_uid)
            .pipe(switchMap(function (uid) { return _this.projectPropertyStore.getDetail(uid); }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.currentProperty = value;
            _this.cd.markForCheck();
        });
    };
    EditMenuItemActionComponent.prototype.ngOnDestroy = function () {
        this.control.inputFieldProvider.clearProvider();
    };
    EditMenuItemActionComponent.prototype.setPageValue = function (page) {
        var _this = this;
        this.control.setPageValue(page);
        this.control.inputFieldProvider.fields$.pipe(first(), untilDestroyed(this)).subscribe(function (items) {
            _this.selectPage.emit({ page: page, finished: !items.length });
        });
    };
    EditMenuItemActionComponent.prototype.setPropertyValue = function (property) {
        this.control.setPropertyValue(property);
    };
    EditMenuItemActionComponent.prototype.addProperty = function (type) {
        var _this = this;
        this.projectPropertyEditController
            .create({
            type: type,
            defaultName: 'variable',
            defaultValueEnabled: true,
            context: this.context,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.control.setPropertyValue(result.property);
        });
    };
    return EditMenuItemActionComponent;
}());
export { EditMenuItemActionComponent };

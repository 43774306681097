import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { applyParamInput$, FieldType, Input, registerFieldComponent } from '@modules/fields';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-slider-field',
  templateUrl: 'slider-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {
  contextSubscription: Subscription;
  defaultMinValue = 1;
  defaultMaxValue = 10;
  defaultStepSize = 1;
  minValue = this.defaultMinValue;
  minValueLabel: string;
  maxValue = this.defaultMaxValue;
  maxValueLabel: string;
  normalValue: number;
  normalValueLabel: string;
  stepSize = this.defaultStepSize;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.updateParams();
    this.initContextObserver();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateParams();
    this.initContextObserver();
  }

  updateParams() {
    this.minValueLabel = this.field.params['min_value_label'];
    this.maxValueLabel = this.field.params['max_value_label'];
    this.normalValueLabel = this.field.params['normal_label'];
  }

  getParams(): { minValueInput?: Input; maxValueInput?: Input; stepSizeInput?: Input } {
    const result: { minValueInput?: Input; maxValueInput?: Input; stepSizeInput?: Input } = {};
    const params = this.field.params;

    if (params['min_value_input']) {
      result.minValueInput = new Input().deserialize(params['min_value_input']);
    } else if (isSet(params['min_value'])) {
      // Backward compatibility
      result.minValueInput = new Input().deserializeFromStatic('value', params['min_value']);
    }

    if (params['max_value_input']) {
      result.maxValueInput = new Input().deserialize(params['max_value_input']);
    } else if (isSet(params['max_value'])) {
      // Backward compatibility
      result.maxValueInput = new Input().deserializeFromStatic('value', params['max_value']);
    }

    if (params['step_size_input']) {
      result.stepSizeInput = new Input().deserialize(params['step_size_input']);
    } else if (isSet(params['step_size'])) {
      // Backward compatibility
      result.stepSizeInput = new Input().deserializeFromStatic('value', params['step_size']);
    }

    return result;
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    const params = this.getParams();
    const minValue$ = params.minValueInput
      ? applyParamInput$(params.minValueInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const maxValue$ = params.maxValueInput
      ? applyParamInput$(params.maxValueInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const stepSize$ = params.stepSizeInput
      ? applyParamInput$(params.stepSizeInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);

    this.contextSubscription = combineLatest(
      minValue$.pipe(
        tap(value => {
          this.minValue = isSet(value) ? value : this.defaultMinValue;
          this.cd.markForCheck();
        })
      ),
      maxValue$.pipe(
        tap(value => {
          this.maxValue = isSet(value) ? value : this.defaultMaxValue;
          this.cd.markForCheck();
        })
      ),
      stepSize$.pipe(
        tap(value => {
          this.stepSize = isSet(value) ? value : this.defaultStepSize;
          this.cd.markForCheck();
        })
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}

registerFieldComponent(FieldType.Slider, SliderFieldComponent);

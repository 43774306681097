import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Domain } from '@modules/domain';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  hasEnvironmentPermission,
  Project,
  ProjectPermissions
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';

import { CustomizeBarAppearanceEditComponent } from '../customize-bar-appearance-edit/customize-bar-appearance-edit.component';
import { CustomizeBarCustomCodeEditComponent } from '../customize-bar-custom-code-edit/customize-bar-custom-code-edit.component';
import { CustomizeBarDomainEditComponent } from '../customize-bar-domain-edit/customize-bar-domain-edit.component';
import { CustomizeBarEmailsEditComponent } from '../customize-bar-emails-edit/customize-bar-emails-edit.component';
import { CustomizeBarFeaturesEditComponent } from '../customize-bar-features-edit/customize-bar-features-edit.component';
import { CustomizeBarIntegrationsEditComponent } from '../customize-bar-integrations-edit/customize-bar-integrations-edit.component';
import { CustomizeBarLanguageRegionEditComponent } from '../customize-bar-language-region-edit/customize-bar-language-region-edit.component';
import { CustomizeBarMenuEditComponent } from '../customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarSharingEditComponent } from '../customize-bar-sharing-edit/customize-bar-sharing-edit.component';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
import { MenuUpdateForm } from './menu-update.form';
import { ProjectSettingsUpdateForm } from './project-settings-update.form';
import { ProjectUpdateForm } from './project-update.form';

enum MenuItemType {
  Appearance = 'appearance',
  LanguageRegion = 'language_region',
  Menu = 'menu',
  SignUp = 'sign_up',
  Domain = 'domain',
  Emails = 'emails',
  Features = 'features',
  Sharing = 'Sharing',
  Integrations = 'Integrations',
  CustomCode = 'custom_code'
}

enum DisplayComponent {}

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CustomizeBarContext,
    ProjectUpdateForm,
    MenuUpdateForm,
    ProjectSettingsUpdateForm,
    ProjectSignUpForm,
    ProjectDomainUpdateForm
  ]
})
export class ProjectSettingsComponent implements OnInit, OnDestroy, CustomizeHandler {
  @ViewChild('root') root: ElementRef;

  menuItems = [
    {
      type: MenuItemType.Appearance,
      title: 'Appearance',
      subtitle: 'App name, layout and colors',
      icon: 'palette',
      component: CustomizeBarAppearanceEditComponent,
      inputs: {
        projectForm: this.projectForm,
        settingsForm: this.settingsForm,
        menuForm: this.menuForm
      },
      displayComponent: undefined
    },
    // {
    //   type: MenuItemType.Menu,
    //   title: 'Menu',
    //   subtitle: 'Pages navigation',
    //   icon: 'fileds',
    //   component: CustomizeBarMenuEditComponent,
    //   inputs: {
    //     menuForm: this.menuForm,
    //     settingsForm: this.settingsForm
    //   },
    //   displayComponent: undefined
    // },
    {
      type: MenuItemType.Domain,
      title: 'Custom Domain',
      subtitle: 'Host app under your domain',
      icon: 'external_link',
      component: CustomizeBarDomainEditComponent,
      inputs: {
        projectForm: this.projectForm
      },
      displayComponent: undefined
    },
    {
      type: MenuItemType.LanguageRegion,
      title: 'Language & Region',
      subtitle: 'Localization, timezone and formats',
      icon: 'earth_planet',
      component: CustomizeBarLanguageRegionEditComponent,
      inputs: {
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: undefined
    },
    {
      type: MenuItemType.Emails,
      title: 'Emails',
      subtitle: 'Settings, templates',
      icon: 'email',
      component: CustomizeBarEmailsEditComponent,
      inputs: {
        projectSignUpForm: this.projectSignUpForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: undefined
    },
    // {
    //   type: MenuItemType.Features,
    //   title: 'Features',
    //   subtitle: 'Advanced functionality',
    //   icon: 'switch',
    //   component: CustomizeBarFeaturesEditComponent,
    //   inputs: {
    //     settingsForm: this.settingsForm
    //   },
    //   displayComponent: undefined
    // },
    {
      type: MenuItemType.Sharing,
      title: 'Sharing',
      subtitle: 'Meta info for URL sharing',
      icon: 'redo',
      component: CustomizeBarSharingEditComponent,
      inputs: {
        projectForm: this.projectForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: undefined
    },
    {
      type: MenuItemType.Integrations,
      title: 'Integrations',
      subtitle: '3rd party services',
      icon: 'chip',
      component: CustomizeBarIntegrationsEditComponent,
      inputs: {
        settingsForm: this.settingsForm
      },
      displayComponent: undefined
    },
    {
      type: MenuItemType.CustomCode,
      title: 'Scripts & Styles',
      subtitle: 'Set global JS and CSS',
      icon: 'console',
      component: CustomizeBarCustomCodeEditComponent,
      inputs: {
        settingsForm: this.settingsForm
      },
      displayComponent: undefined
    }
  ];
  selectedMenuItemType: MenuItemType;
  displayComponent: DisplayComponent;
  displayComponents = DisplayComponent;
  settingsComponents: DynamicComponentArguments[] = [];
  project: Project;
  projectHome: string;
  domain: Domain;
  loading = false;
  domainFormSubscription: Subscription;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private activatedRoute: ActivatedRoute,
    public projectForm: ProjectUpdateForm,
    public menuForm: MenuUpdateForm,
    public settingsForm: ProjectSettingsUpdateForm,
    private projectSignUpForm: ProjectSignUpForm,
    public projectDomainUpdateForm: ProjectDomainUpdateForm,
    private menuService: MenuService,
    private customizeBarContext: CustomizeBarContext,
    private currentProjectStore: CurrentProjectStore,
    private projectSettingsStore: ProjectSettingsStore,
    private customizeService: CustomizeService,
    private menuSettingsStore: MenuSettingsStore,
    private routing: RoutingService,
    private appConfigService: AppConfigService,
    public metaService: MetaService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
      this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
      return;
    }

    this.menuService.section = MenuSection.None;
    this.loading = true;
    this.cd.markForCheck();

    this.activatedRoute.params
      .pipe(
        map(params => params['tab']),
        untilDestroyed(this)
      )
      .subscribe(tab => {
        if (isSet(tab)) {
          this.setSelectedMenuItemType(tab);
        } else {
          const link = this.currentProjectStore.instance.settingsLayoutLink(this.menuItems[0].type);
          this.routing.navigateApp(link, { replaceUrl: true });
        }
      });

    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      inAppDisabled: true
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.detectChanges();
      this.scrollToTop();
    });

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.cd.markForCheck();
      });

    combineLatest(
      this.currentProjectStore.getFirst(),
      this.projectSettingsStore.getAllSettingsFirst$(),
      this.menuSettingsStore.getFirst()
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([project, projectSettings, menuSettings]) => {
          const webBaseUrl =
            project && project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;

          this.project = project;
          this.domain = project ? project.domain : undefined;
          this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
          this.loading = false;
          this.cd.markForCheck();

          this.projectForm.init(project);
          this.menuForm.init(menuSettings);
          this.settingsForm.init(projectSettings);
          this.projectSignUpForm.init(project);

          this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectForm.submit().subscribe(() => this.onSave());
          });

          this.menuForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.menuForm.submit().subscribe(() => this.onSave());
          });

          this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.settingsForm.submit().subscribe(() => this.onSave());
          });

          this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectSignUpForm.submit().subscribe(() => this.onSave());
          });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const webBaseUrl =
          result && result.domain ? `https://${result.domain.actualDomain}` : this.appConfigService.webBaseUrl;

        this.project = result;
        this.domain = result ? result.domain : undefined;
        this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
        this.cd.markForCheck();

        this.projectForm.project = this.project;
        this.projectSignUpForm.project = this.project;
        this.initDomainForm();
      });
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
    this.customizeService.unsetHandler(this);
  }

  initDomainForm() {
    if (this.domainFormSubscription) {
      this.domainFormSubscription.unsubscribe();
      this.domainFormSubscription = undefined;
    }

    this.projectDomainUpdateForm.init(this.domain);

    this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(() => {
        this.projectDomainUpdateForm.submit().subscribe(() => this.onSave());
      });
  }

  get selectedMenuItem() {
    return this.menuItems.find(item => item.type == this.selectedMenuItemType);
  }

  setSelectedMenuItemType(type: MenuItemType) {
    if (this.selectedMenuItemType === type) {
      return;
    }

    this.selectedMenuItemType = type;
    this.cd.markForCheck();

    const selectedMenuItem = this.selectedMenuItem;

    if (!selectedMenuItem) {
      return;
    }

    this.displayComponent = selectedMenuItem.displayComponent;
    this.cd.markForCheck();

    if (selectedMenuItem.component) {
      this.customizeBarContext.setSettingsComponent({
        component: selectedMenuItem.component,
        inputs: selectedMenuItem.inputs || {}
      });
    }
  }

  scrollToTop(animated = true) {
    if (!this.root) {
      return;
    }

    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  onMenuItemClicked(name: string) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.AppSettings.MenuItemClicked, {
      Name: name
    });
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'app_settings'
    });
  }

  // TODO: Fix empty handler
  closePopup(uid?: string): void {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../poster-layout/poster-layout.component.ngfactory";
import * as i2 from "../poster-layout/poster-layout.component";
import * as i3 from "@angular/common";
import * as i4 from "../form-layout/form-layout.component.ngfactory";
import * as i5 from "../form-layout/form-layout.component";
import * as i6 from "../background-layout/background-layout.component.ngfactory";
import * as i7 from "../background-layout/background-layout.component";
import * as i8 from "./layout.component";
var styles_LayoutComponent = [];
var RenderType_LayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_LayoutComponent_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 1), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_LayoutComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-poster-layout", [], null, null, null, i1.View_PosterLayoutComponent_0, i1.RenderType_PosterLayoutComponent)), i0.ɵdid(1, 638976, null, 0, i2.PosterLayoutComponent, [], { left: [0, "left"], backgroundColor: [1, "backgroundColor"], backgroundImage: [2, "backgroundImage"], fillScreen: [3, "fillScreen"], preview: [4, "preview"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LayoutComponent_4)), i0.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_LayoutComponent_5)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.layout == "poster_left"); var currVal_1 = _co.backgroundColor; var currVal_2 = _co.backgroundImage; var currVal_3 = _co.fillScreen; var currVal_4 = _co.preview; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_5); var currVal_6 = i0.ɵnov(_v.parent, 1); _ck(_v, 5, 0, currVal_6); }, null); }
function View_LayoutComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-form-layout", [], null, null, null, i4.View_FormLayoutComponent_0, i4.RenderType_FormLayoutComponent)), i0.ɵdid(1, 638976, null, 0, i5.FormLayoutComponent, [], { backgroundColor: [0, "backgroundColor"], backgroundImage: [1, "backgroundImage"], fillScreen: [2, "fillScreen"], preview: [3, "preview"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LayoutComponent_7)), i0.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_LayoutComponent_8)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundColor; var currVal_1 = _co.backgroundImage; var currVal_2 = _co.fillScreen; var currVal_3 = _co.preview; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵnov(_v.parent, 1); _ck(_v, 5, 0, currVal_5); }, null); }
function View_LayoutComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LayoutComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-background-layout", [], null, null, null, i6.View_BackgroundLayoutComponent_0, i6.RenderType_BackgroundLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i7.BackgroundLayoutComponent, [], { backgroundColor: [0, "backgroundColor"], fillScreen: [1, "fillScreen"], preview: [2, "preview"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_LayoutComponent_10)), i0.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_LayoutComponent_11)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundColor; var currVal_1 = _co.fillScreen; var currVal_2 = _co.preview; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_3); var currVal_4 = i0.ɵnov(_v.parent, 1); _ck(_v, 5, 0, currVal_4); }, null); }
export function View_LayoutComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_LayoutComponent_1)), (_l()(), i0.ɵand(0, [["footer_content", 2]], null, 0, null, View_LayoutComponent_2)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LayoutComponent_3)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LayoutComponent_6)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LayoutComponent_9)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.layout == "poster_left") || (_co.layout == "poster_right")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.layout == "form"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.layout == "background"); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i0.ɵdid(1, 114688, null, 0, i8.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i0.ɵccf("app-layout", i8.LayoutComponent, View_LayoutComponent_Host_0, { layout: "layout", backgroundColor: "backgroundColor", backgroundImage: "backgroundImage", fillScreen: "fillScreen", preview: "preview" }, {}, ["*", "[data-layout-footer]"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };

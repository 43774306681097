import pull from 'lodash/pull';

export class MarkdownNode {
  open: string;
  close: string;
  text: string;
  block = false;
  children: MarkdownNode[] = [];
  parent: MarkdownNode;

  constructor(options: Partial<MarkdownNode> = {}) {
    Object.assign(this, options);
  }

  appendNode(node: MarkdownNode) {
    if (node.parent) {
      pull(node.parent.children, node);
    }

    node.parent = this;

    this.children.push(node);
  }

  appendText(text: string) {
    const node = new MarkdownNode({ text: text });
    this.appendNode(node);
  }

  render(): string {
    let text = '';

    if (this.open) {
      text += this.open;
    }

    if (this.text) {
      text += this.text;
    }

    for (const child of this.children) {
      text += child.render();
    }

    if (this.close) {
      text += this.close;
    }

    return text;
  }
}

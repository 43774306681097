<div
  class="compact-select compact-select_big compact-select_transparent"
  [class.compact-select_focus]="dropdownOpened"
  [style.min-width.px]="66"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="setDropdownOpened(true)"
>
  <div class="compact-select__main">
    <div class="compact-select__value">
      {{ editorContext.viewportScale$ | async | appFormatNumber: '0%' }}
    </div>
  </div>

  <div class="compact-select__right">
    <div class="compact-select__arrow icon-arrow_down_2"></div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="compact-select-options">
    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="(editorContext.viewportScale$ | async) >= editorContext.viewportScaleMax"
      (click)="editorContext.zoomIn(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div class="compact-select-option__checked compact-select-option__item"></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom In</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol compact-select-option__hotkey-symbol_enlarge">+</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_disabled]="(editorContext.viewportScale$ | async) <= editorContext.viewportScaleMin"
      (click)="editorContext.zoomOut(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div class="compact-select-option__checked compact-select-option__item"></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom Out</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol compact-select-option__hotkey-symbol_enlarge">-</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="editorContext.fitToContent(viewport); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div class="compact-select-option__checked compact-select-option__item"></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom to Fit</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="(editorContext.viewportScale$ | async) == 0.5"
      (click)="editorContext.viewportScale$.next(0.5); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="(editorContext.viewportScale$ | async) == 0.5"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom to 50%</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="(editorContext.viewportScale$ | async) == 1"
      (click)="editorContext.viewportScale$.next(1); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="(editorContext.viewportScale$ | async) == 1"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom to 100%</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__hotkey">
          <span class="compact-select-option__hotkey-modifier">⌘</span>
          <span class="compact-select-option__hotkey-symbol">0</span>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="(editorContext.viewportScale$ | async) == 2"
      (click)="editorContext.viewportScale$.next(2); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="(editorContext.viewportScale$ | async) == 2"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Zoom to 200%</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ActionDescription, ActionItem, ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, createFormFieldFactory, FieldType, InputValueType, ParameterField } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var BaseActionExecuteComponent = /** @class */ (function () {
    function BaseActionExecuteComponent(currentProjectStore, currentEnvironmentStore, actionService, actionControllerService, form, notificationService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.actionService = actionService;
        this.actionControllerService = actionControllerService;
        this.form = form;
        this.notificationService = notificationService;
        this.injector = injector;
        this.cd = cd;
        this.params = {};
        this.options = {};
        this.submitLabel = 'Execute';
        this.fill = false;
        this.primaryKeyAutoLookup = true;
        this.finished = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.paramValues = {};
        this.actionParams = [];
        this.executeStarted = false;
        this.executeProcessedCount = 0;
        this.executeSucceededCount = 0;
        this.executeTotalCount = 0;
        this.createField = createFormFieldFactory();
    }
    BaseActionExecuteComponent.prototype.ngOnInit = function () {
        this.init();
        this.initVerboseName();
    };
    BaseActionExecuteComponent.prototype.ngOnDestroy = function () { };
    BaseActionExecuteComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.action && !changes.action.firstChange) ||
            (changes.actionDescription && !changes.actionDescription.firstChange)) {
            this.init();
            this.initVerboseName();
        }
    };
    Object.defineProperty(BaseActionExecuteComponent.prototype, "currentOptions", {
        get: function () {
            return defaults(this.options, { showSuccess: true, showError: true });
        },
        enumerable: true,
        configurable: true
    });
    BaseActionExecuteComponent.prototype.init = function () {
        var _this = this;
        if (!this.action || !this.actionDescription) {
            this.form.deinit();
            return;
        }
        var paramValues = this.getParamValues(this.actionDescription, this.params);
        this.paramValues = paramValues;
        this.actionParams = this.actionDescription.actionParams
            .filter(function (parameter) {
            if (parameter.required && !paramValues.hasOwnProperty(parameter.name)) {
                return true;
            }
            return _this.action.inputs.find(function (item) { return item.name == parameter.name && item.valueType == InputValueType.Prompt; });
        })
            .map(function (item) {
            var primaryKeyLookup = _this.modelDescription &&
                _this.modelDescription.primaryKeyField &&
                item.name == _this.modelDescription.primaryKeyField &&
                _this.modelDescription.getQuery &&
                _this.modelDescription.field(item.name);
            if (!_this.fill && !primaryKeyLookup) {
                return item;
            }
            var field = new ParameterField().deserialize(item.serialize());
            if (primaryKeyLookup && _this.primaryKeyAutoLookup) {
                field.field = FieldType.RelatedModel;
                field.params = __assign({}, field.params, { related_model: { model: _this.modelDescription.modelId }, open_button: false, create_button: false });
            }
            if (_this.fill) {
                field.params = __assign({}, field.params, { classes: ['select_fill', 'input_fill'] });
            }
            field.updateFieldDescription();
            return field;
        });
        // TODO: Add bulk actions
        this.executeTotalCount = values(this.paramValues).length
            ? Math.max.apply(Math, values(this.paramValues).map(function (item) { return (isArray(item) ? item.length : 1); })) : 1;
        this.form.init(this.actionParams, { context: this.options.context, contextElement: this.options.contextElement });
    };
    BaseActionExecuteComponent.prototype.initVerboseName = function () {
        var _this = this;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.action || !this.action.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.action.verboseNameInput, {
            context: this.options.context,
            contextElement: this.options.contextElement,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    BaseActionExecuteComponent.prototype.getParamValues = function (action, params) {
        return action.actionParams.reduce(function (acc, item) {
            if (params.hasOwnProperty(item.name)) {
                acc[item.name] = params[item.name];
            }
            return acc;
        }, {});
    };
    BaseActionExecuteComponent.prototype.execute = function () {
        var _this = this;
        if (this.form.form.invalid || this.executeStarted || this.executeProcessedCount == this.executeTotalCount) {
            return;
        }
        var params = this.form.submit();
        if (this.action.approve) {
            var paramCall = __assign({}, this.paramValues, params);
            this.actionService
                .requestApproval(this.action, paramCall)
                .pipe(untilDestroyed(this))
                .subscribe(function (task) {
                if (!task) {
                    return;
                }
                _this.onSubmitCompleted(true, undefined);
            }, function (error) {
                if (error instanceof ServerRequestError && error.errors.length) {
                    _this.notificationService.error('Error', error.errors[0]);
                }
                else {
                    _this.notificationService.error('Error', error);
                }
            });
        }
        else {
            var paramCalls = void 0;
            if (this.actionDescription.type == ActionType.Export) {
                var resource = this.actionDescription.exportAction &&
                    this.actionDescription.exportAction.dataSource &&
                    this.actionDescription.exportAction.dataSource.type == DataSourceType.Query &&
                    this.actionDescription.exportAction.dataSource.queryResource
                    ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == _this.actionDescription.exportAction.dataSource.queryResource; })
                    : undefined;
                paramCalls = [
                    __assign({}, toPairs(this.paramValues).reduce(function (acc, _a) {
                        var k = _a[0], v = _a[1];
                        if (isArray(v)) {
                            acc[k + "__in"] = v;
                        }
                        else {
                            acc[k] = v;
                        }
                        return acc;
                    }, {}), params)
                ];
            }
            else {
                paramCalls = range(this.executeTotalCount).map(function (i) {
                    return __assign({}, toPairs(_this.paramValues).reduce(function (acc, _a) {
                        var k = _a[0], v = _a[1];
                        // TODO: Add bulk actions
                        if (isArray(v)) {
                            var indexClean = Math.min(i, v.length - 1);
                            acc[k] = v[indexClean];
                        }
                        else {
                            acc[k] = v;
                        }
                        return acc;
                    }, {}), params);
                });
            }
            if (!paramCalls.length) {
                this.notificationService.error('Nothing to do', 'No items found for execution');
                return;
            }
            this.executeStarted = true;
            this.executeProcessedCount = 0;
            this.executeSucceededCount = 0;
            this.cd.markForCheck();
            this.processParamCalls(paramCalls)
                .pipe(untilDestroyed(this))
                .subscribe(function (response) {
                var successResult = _this.executeSucceededCount == _this.executeProcessedCount;
                if (_this.currentOptions.showSuccess && successResult) {
                    _this.notificationService.success('Action Executed', 'Action executed successfully');
                }
                _this.onSubmitCompleted(successResult, response);
            });
        }
    };
    BaseActionExecuteComponent.prototype.onSubmitCompleted = function (successResult, result) {
        this.finished.emit({
            processedCount: this.executeProcessedCount,
            successCount: this.executeSucceededCount,
            failedCount: this.executeProcessedCount - this.executeSucceededCount,
            totalCount: this.executeTotalCount,
            result: result
        });
    };
    BaseActionExecuteComponent.prototype.processParamCalls = function (paramCalls, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        var params = paramCalls[index];
        return this.actionControllerService
            .executeAction(this.action, params, {
            context: this.currentOptions.context,
            contextElement: this.currentOptions.contextElement,
            localContext: this.currentOptions.localContext,
            saveResultTo: this.currentOptions.saveResultTo,
            injector: this.injector,
            analyticsSource: this.analyticsSource
        })
            .pipe(tap(function (response) {
            _this.executeProcessedCount += 1;
            _this.executeSucceededCount += 1;
            _this.cd.markForCheck();
            _this.actionService.processResponse(response);
        }), catchError(function (error) {
            console.error(error);
            _this.executeProcessedCount += 1;
            _this.cd.markForCheck();
            if (_this.currentOptions.showError) {
                if (error instanceof ServerRequestError && error.errors.length) {
                    _this.notificationService.error('Action Failed', error.errors[0]);
                }
                else {
                    _this.notificationService.error('Action Failed', error);
                }
            }
            return of(undefined);
        }), switchMap(function (response) {
            if (index + 1 < paramCalls.length) {
                return _this.processParamCalls(paramCalls, index + 1);
            }
            else {
                return of(response);
            }
        }));
    };
    Object.defineProperty(BaseActionExecuteComponent.prototype, "fieldContext", {
        get: function () {
            return {
                action: this.actionDescription ? this.actionDescription.id : undefined
            };
        },
        enumerable: true,
        configurable: true
    });
    return BaseActionExecuteComponent;
}());
export { BaseActionExecuteComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SharedCustomSSOType, SSOType } from '@modules/sso';
export var facebookBackendName = 'facebook';
export var googleOAuth2BackendName = 'google-oauth2';
export var cognitoBackendName = 'cognito';
export var customOAuth2BackendName = 'custom_oauth_2';
export var azureADTenantBackendName = 'azuread-tenant-oauth2';
export var azureADV2TenantBackendName = 'azuread-v2-tenant-oauth2';
export var auth0BackendName = 'auth0';
export var keycloakBackendName = 'keycloak';
export var salesforceBackendName = 'salesforce-oauth2';
export var sharedCustomSSO = [
    {
        type: SharedCustomSSOType.Firebase,
        label: 'Firebase Auth',
        image: 'firebase'
    },
    {
        type: SharedCustomSSOType.Supabase,
        label: 'Supabase Auth',
        image: 'supabase'
    }
];
export var socialBackends = [
    {
        name: customOAuth2BackendName,
        label: 'Custom OAuth 2.0',
        path: 'core.backends.CustomOAuth2'
    },
    {
        name: 'aol',
        label: 'AOL OpenId',
        path: 'social_core.backends.aol.AOLOpenId'
    },
    {
        name: 'actionid-openid',
        label: 'ActionIDOpenID',
        path: 'social_core.backends.ngpvan.ActionIDOpenID'
    },
    {
        name: 'amazon',
        label: 'Amazon OAuth 2.0',
        path: 'social_core.backends.amazon.AmazonOAuth2'
    },
    {
        name: 'angel',
        label: 'Angel OAuth 2.0',
        path: 'social_core.backends.angel.AngelOAuth2'
    },
    {
        name: 'appsfuel',
        label: 'Appsfuel OAuth 2.0',
        path: 'social_core.backends.appsfuel.AppsfuelOAuth2'
    },
    {
        name: 'appsfuel-sandbox',
        label: 'Appsfuel OAuth 2.0 Sandbox',
        path: 'social_core.backends.appsfuel.AppsfuelOAuth2Sandbox'
    },
    {
        name: 'arcgis',
        label: 'ArcGIS OAuth 2.0',
        path: 'social_core.backends.arcgis.ArcGISOAuth2'
    },
    {
        name: 'asana',
        label: 'Asana OAuth 2.0',
        path: 'social_core.backends.asana.AsanaOAuth2'
    },
    {
        name: 'atlassian',
        label: 'Atlassian OAuth 2.0',
        path: 'social_core.backends.atlassian.AtlassianOAuth2'
    },
    // {
    //   name: 'azuread-b2c-oauth2',
    //   label: 'AzureADB2C OAuth 2.0',
    //   path: 'social_core.backends.azuread_b2c.AzureADB2COAuth2'
    // },
    // {
    //   name: 'azuread-oauth2',
    //   label: 'AzureAD OAuth 2.0',
    //   path: 'social_core.backends.azuread_b2c.AzureADOAuth2'
    // },
    // {
    //   name: 'azuread-oauth2',
    //   label: 'AzureAD OAuth 2.0',
    //   path: 'social_core.backends.azuread_tenant.AzureADOAuth2'
    // },
    // {
    //   name: 'azuread-oauth2',
    //   label: 'AzureAD OAuth 2.0',
    //   path: 'social_core.backends.azuread.AzureADOAuth2'
    // },
    {
        name: azureADTenantBackendName,
        label: 'AzureADTenant OAuth 2.0',
        path: 'social_core.backends.azuread_tenant.AzureADTenantOAuth2'
    },
    {
        name: azureADV2TenantBackendName,
        label: 'AzureADV2Tenant OAuth 2.0',
        path: 'social_core.backends.azuread_tenant.AzureADV2TenantOAuth2',
        image: 'azure'
    },
    {
        name: auth0BackendName,
        label: 'Auth0 OAuth 2.0',
        path: 'social_core.backends.auth0.Auth0OAuth2',
        image: 'auth0'
    },
    {
        name: 'battlenet-oauth2',
        label: 'BattleNet OAuth 2.0',
        path: 'social_core.backends.battlenet.BattleNetOAuth2'
    },
    {
        name: 'beats',
        label: 'Beats OAuth 2.0',
        path: 'social_core.backends.beats.BeatsOAuth2'
    },
    {
        name: 'behance',
        label: 'Behance OAuth 2.0',
        path: 'social_core.backends.behance.BehanceOAuth2'
    },
    {
        name: 'belgiumeid',
        label: 'BelgiumEID OpenId',
        path: 'social_core.backends.belgiumeid.BelgiumEIDOpenId'
    },
    {
        name: 'bitbucket',
        label: 'Bitbucket OAuth 1.0',
        path: 'social_core.backends.bitbucket.BitbucketOAuth'
    },
    {
        name: 'bitbucket-oauth2',
        label: 'Bitbucket OAuth 2.0',
        path: 'social_core.backends.bitbucket.BitbucketOAuth2'
    },
    {
        name: 'box',
        label: 'Box OAuth 2.0',
        path: 'social_core.backends.box.BoxOAuth2'
    },
    {
        name: 'bungie',
        label: 'Bungie OAuth 2.0',
        path: 'social_core.backends.bungie.BungieOAuth2'
    },
    {
        name: 'changetip',
        label: 'ChangeTip OAuth 2.0',
        path: 'social_core.backends.changetip.ChangeTipOAuth2'
    },
    {
        name: 'chatwork',
        label: 'Chatwork OAuth 2.0',
        path: 'social_core.backends.chatwork.ChatworkOAuth2'
    },
    {
        name: 'classlink',
        label: 'Classlink OAuth',
        path: 'social_core.backends.classlink.ClasslinkOAuth'
    },
    {
        name: 'clef',
        label: 'Clef OAuth 2.0',
        path: 'social_core.backends.clef.ClefOAuth2'
    },
    {
        name: 'coding',
        label: 'Coding OAuth 2.0',
        path: 'social_core.backends.coding.CodingOAuth2'
    },
    {
        name: cognitoBackendName,
        label: 'Cognito OAuth 2.0',
        path: 'social_core.backends.cognito.CognitoOAuth2',
        image: 'aws-cognito'
    },
    {
        name: 'coinbase',
        label: 'Coinbase OAuth 2.0',
        path: 'social_core.backends.coinbase.CoinbaseOAuth2'
    },
    {
        name: 'coursera',
        label: 'Coursera OAuth 2.0',
        path: 'social_core.backends.coursera.CourseraOAuth2'
    },
    {
        name: 'dailymotion',
        label: 'Dailymotion OAuth 2.0',
        path: 'social_core.backends.dailymotion.DailymotionOAuth2'
    },
    {
        name: 'datagouv',
        label: 'Datagouvfr OAuth 2.0',
        path: 'social_core.backends.udata.DatagouvfrOAuth2'
    },
    {
        name: 'deezer',
        label: 'Deezer OAuth 2.0',
        path: 'social_core.backends.deezer.DeezerOAuth2'
    },
    {
        name: 'digitalocean',
        label: 'DigitalOcean OAuth',
        path: 'social_core.backends.digitalocean.DigitalOceanOAuth'
    },
    {
        name: 'discord',
        label: 'Discord OAuth 2.0',
        path: 'social_core.backends.discord.DiscordOAuth2'
    },
    {
        name: 'disqus',
        label: 'Disqus OAuth 2.0',
        path: 'social_core.backends.disqus.DisqusOAuth2'
    },
    {
        name: 'docker',
        label: 'Docker OAuth 2.0',
        path: 'social_core.backends.docker.DockerOAuth2'
    },
    {
        name: 'douban',
        label: 'Douban OAuth 1.0',
        path: 'social_core.backends.douban.DoubanOAuth'
    },
    {
        name: 'douban-oauth2',
        label: 'Douban OAuth 2.0',
        path: 'social_core.backends.douban.DoubanOAuth2'
    },
    {
        name: 'dribbble',
        label: 'Dribbble OAuth 2.0',
        path: 'social_core.backends.dribbble.DribbbleOAuth2'
    },
    {
        name: 'drip',
        label: 'Drip OAuth',
        path: 'social_core.backends.drip.DripOAuth'
    },
    {
        name: 'dropbox',
        label: 'Dropbox OAuth 1.0',
        path: 'social_core.backends.dropbox.DropboxOAuth'
    },
    {
        name: 'dropbox-oauth2',
        label: 'Dropbox OAuth 2.0',
        path: 'social_core.backends.dropbox.DropboxOAuth2'
    },
    {
        name: 'dropbox-oauth2',
        label: 'DropboxOAuth2V2',
        path: 'social_core.backends.dropbox.DropboxOAuth2V2'
    },
    {
        name: 'eveonline',
        label: 'EVEOnline OAuth 2.0',
        path: 'social_core.backends.eveonline.EVEOnlineOAuth2'
    },
    {
        name: 'echosign',
        label: 'Echosign OAuth 2.0',
        path: 'social_core.backends.echosign.EchosignOAuth2'
    },
    {
        name: 'edmodo',
        label: 'Edmodo OAuth 2.0',
        path: 'social_core.backends.edmodo.EdmodoOAuth2'
    },
    {
        name: 'email',
        label: 'EmailAuth',
        path: 'social_core.backends.email.EmailAuth'
    },
    {
        name: 'eventbrite',
        label: 'Eventbrite OAuth 2.0',
        path: 'social_core.backends.eventbrite.EventbriteOAuth2'
    },
    {
        name: 'evernote',
        label: 'Evernote OAuth',
        path: 'social_core.backends.evernote.EvernoteOAuth'
    },
    {
        name: 'evernote-sandbox',
        label: 'Evernote OAuth Sandbox',
        path: 'social_core.backends.evernote.EvernoteSandboxOAuth'
    },
    {
        name: 'exacttarget',
        label: 'ExactTarget OAuth 2.0',
        path: 'social_core.backends.exacttarget.ExactTargetOAuth2'
    },
    {
        name: 'facebook-app',
        label: 'FacebookApp OAuth 2.0',
        path: 'social_core.backends.facebook.FacebookAppOAuth2'
    },
    {
        name: facebookBackendName,
        label: 'Facebook OAuth 2.0',
        path: 'social_core.backends.facebook.FacebookOAuth2',
        image: 'facebook'
    },
    {
        name: 'fedora',
        label: 'Fedora OpenId',
        path: 'social_core.backends.fedora.FedoraOpenId'
    },
    {
        name: 'fitbit',
        label: 'Fitbit OAuth 1.0',
        path: 'social_core.backends.fitbit.FitbitOAuth1'
    },
    {
        name: 'fitbit',
        label: 'Fitbit OAuth 2.0',
        path: 'social_core.backends.fitbit.FitbitOAuth2'
    },
    {
        name: '500px',
        label: 'FiveHundredPx OAuth',
        path: 'social_core.backends.five_hundred_px.FiveHundredPxOAuth'
    },
    {
        name: 'flat',
        label: 'Flat OAuth 2.0',
        path: 'social_core.backends.flat.FlatOAuth2'
    },
    {
        name: 'flickr',
        label: 'Flickr OAuth',
        path: 'social_core.backends.flickr.FlickrOAuth'
    },
    {
        name: 'foursquare',
        label: 'Foursquare OAuth 2.0',
        path: 'social_core.backends.foursquare.FoursquareOAuth2'
    },
    {
        name: 'gitlab',
        label: 'GitLab OAuth 2.0',
        path: 'social_core.backends.gitlab.GitLabOAuth2'
    },
    {
        name: 'github-enterprise',
        label: 'GithubEnterprise OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubEnterpriseOAuth2'
    },
    {
        name: 'github-enterprise-org',
        label: 'GithubEnterpriseOrganization OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubEnterpriseOrganizationOAuth2'
    },
    {
        name: 'github-enterprise-team',
        label: 'GithubEnterpriseTeam OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubEnterpriseTeamOAuth2'
    },
    {
        name: 'github',
        label: 'GithubMember OAuth 2.0',
        path: 'social_core.backends.github.GithubMemberOAuth2'
    },
    {
        name: 'github',
        label: 'Github OAuth 2.0',
        path: 'social_core.backends.github.GithubOAuth2'
    },
    {
        name: 'github',
        label: 'Github OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubOAuth2'
    },
    {
        name: 'github-org',
        label: 'GithubOrganization OAuth 2.0',
        path: 'social_core.backends.github.GithubOrganizationOAuth2'
    },
    {
        name: 'github-org',
        label: 'GithubOrganization OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubOrganizationOAuth2'
    },
    {
        name: 'github-team',
        label: 'GithubTeam OAuth 2.0',
        path: 'social_core.backends.github.GithubTeamOAuth2'
    },
    {
        name: 'github-team',
        label: 'GithubTeam OAuth 2.0',
        path: 'social_core.backends.github_enterprise.GithubTeamOAuth2'
    },
    {
        name: 'goclioeu',
        label: 'GoClioEu OAuth 2.0',
        path: 'social_core.backends.goclioeu.GoClioEuOAuth2'
    },
    {
        name: 'goclio',
        label: 'GoClio OAuth 2.0',
        path: 'social_core.backends.goclioeu.GoClioOAuth2'
    },
    {
        name: 'goclio',
        label: 'GoClio OAuth 2.0',
        path: 'social_core.backends.goclio.GoClioOAuth2'
    },
    {
        name: 'google-oauth',
        label: 'Google OAuth 1.0',
        path: 'social_core.backends.google.GoogleOAuth'
    },
    {
        name: googleOAuth2BackendName,
        label: 'Google OAuth 2.0',
        path: 'social_core.backends.google.GoogleOAuth2',
        image: 'google'
    },
    {
        name: 'google',
        label: 'Google OpenId',
        path: 'social_core.backends.google.GoogleOpenId'
    },
    {
        name: 'google-plus',
        label: 'GooglePlus Auth',
        path: 'social_core.backends.google.GooglePlusAuth'
    },
    {
        name: 'instagram',
        label: 'Instagram OAuth 2.0',
        path: 'social_core.backends.instagram.InstagramOAuth2'
    },
    {
        name: 'itembase',
        label: 'Itembase OAuth 2.0',
        path: 'social_core.backends.itembase.ItembaseOAuth2'
    },
    {
        name: 'itembase-sandbox',
        label: 'Itembase OAuth 2.0 Sandbox',
        path: 'social_core.backends.itembase.ItembaseOAuth2Sandbox'
    },
    {
        name: 'jawbone',
        label: 'Jawbone OAuth 2.0',
        path: 'social_core.backends.jawbone.JawboneOAuth2'
    },
    {
        name: 'justgiving',
        label: 'JustGiving OAuth 2.0',
        path: 'social_core.backends.justgiving.JustGivingOAuth2'
    },
    {
        name: 'kakao',
        label: 'Kakao OAuth 2.0',
        path: 'social_core.backends.kakao.KakaoOAuth2'
    },
    {
        name: keycloakBackendName,
        label: 'Keycloak OAuth 2.0',
        path: 'social_core.backends.keycloak.KeycloakOAuth2',
        image: 'keycloak2'
    },
    {
        name: 'khanacademy-oauth1',
        label: 'KhanAcademy',
        path: 'social_core.backends.khanacademy.KhanAcademyOAuth1'
    },
    {
        name: 'lastfm',
        label: 'LastFmAuth',
        path: 'social_core.backends.lastfm.LastFmAuth'
    },
    {
        name: 'launchpad',
        label: 'Launchpad OpenId',
        path: 'social_core.backends.launchpad.LaunchpadOpenId'
    },
    {
        name: 'line',
        label: 'Line OAuth 2.0',
        path: 'social_core.backends.line.LineOAuth2'
    },
    {
        name: 'linkedin-mobile-oauth2',
        label: 'LinkedinMobile OAuth 2.0',
        path: 'social_core.backends.linkedin.LinkedinMobileOAuth2'
    },
    {
        name: 'linkedin-oauth2',
        label: 'Linkedin OAuth 2.0',
        path: 'social_core.backends.linkedin.LinkedinOAuth2'
    },
    {
        name: 'livejournal',
        label: 'LiveJournal OpenId',
        path: 'social_core.backends.livejournal.LiveJournalOpenId'
    },
    {
        name: 'live',
        label: 'Live OAuth 2.0',
        path: 'social_core.backends.live.LiveOAuth2'
    },
    {
        name: 'loginradius',
        label: 'LoginRadiusAuth',
        path: 'social_core.backends.loginradius.LoginRadiusAuth'
    },
    {
        name: 'lyft',
        label: 'Lyft OAuth 2.0',
        path: 'social_core.backends.lyft.LyftOAuth2'
    },
    {
        name: 'mailchimp',
        label: 'MailChimp OAuth 2.0',
        path: 'social_core.backends.mailchimp.MailChimpOAuth2'
    },
    {
        name: 'mailru-oauth2',
        label: 'Mailru OAuth 2.0',
        path: 'social_core.backends.mailru.MailruOAuth2'
    },
    {
        name: 'mapmyfitness',
        label: 'MapMyFitness OAuth 2.0',
        path: 'social_core.backends.mapmyfitness.MapMyFitnessOAuth2'
    },
    {
        name: 'mediawiki',
        label: 'MediaWiki',
        path: 'social_core.backends.mediawiki.MediaWiki'
    },
    {
        name: 'meetup',
        label: 'Meetup OAuth 2.0',
        path: 'social_core.backends.meetup.MeetupOAuth2'
    },
    {
        name: 'mendeley',
        label: 'Mendeley OAuth 1.0',
        path: 'social_core.backends.mendeley.MendeleyOAuth'
    },
    {
        name: 'mendeley-oauth2',
        label: 'Mendeley OAuth 2.0',
        path: 'social_core.backends.mendeley.MendeleyOAuth2'
    },
    {
        name: 'microsoft-graph',
        label: 'Microsoft OAuth 2.0',
        path: 'social_core.backends.microsoft.MicrosoftOAuth2'
    },
    {
        name: 'mineid',
        label: 'MineID OAuth 2.0',
        path: 'social_core.backends.mineid.MineIDOAuth2'
    },
    {
        name: 'mixcloud',
        label: 'Mixcloud OAuth 2.0',
        path: 'social_core.backends.mixcloud.MixcloudOAuth2'
    },
    {
        name: 'monzo',
        label: 'Monzo OAuth 2.0',
        path: 'social_core.backends.monzo.MonzoOAuth2'
    },
    {
        name: 'moves',
        label: 'Moves OAuth 2.0',
        path: 'social_core.backends.moves.MovesOAuth2'
    },
    {
        name: 'nationbuilder',
        label: 'NationBuilder OAuth 2.0',
        path: 'social_core.backends.nationbuilder.NationBuilderOAuth2'
    },
    {
        name: 'naver',
        label: 'Naver OAuth 2.0',
        path: 'social_core.backends.naver.NaverOAuth2'
    },
    {
        name: 'orcid',
        label: 'ORCIDMember OAuth 2.0',
        path: 'social_core.backends.orcid.ORCIDMemberOAuth2'
    },
    {
        name: 'orcid-sandbox',
        label: 'ORCIDMember OAuth 2.0 Sandbox',
        path: 'social_core.backends.orcid.ORCIDMemberOAuth2Sandbox'
    },
    {
        name: 'orcid',
        label: 'ORCID OAuth 2.0',
        path: 'social_core.backends.orcid.ORCIDOAuth2'
    },
    {
        name: 'orcid-sandbox',
        label: 'ORCID OAuth 2.0 Sandbox',
        path: 'social_core.backends.orcid.ORCIDOAuth2Sandbox'
    },
    {
        name: 'odnoklassniki-app',
        label: 'OdnoklassnikiApp',
        path: 'social_core.backends.odnoklassniki.OdnoklassnikiApp'
    },
    {
        name: 'odnoklassniki-oauth2',
        label: 'Odnoklassniki OAuth 2.0',
        path: 'social_core.backends.odnoklassniki.OdnoklassnikiOAuth2'
    },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.suse.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.launchpad.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.ngpvan.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.openstackdev.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.yahoo.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.aol.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.openstack.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.yandex.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.google.OpenIdAuth'
    // },
    {
        name: 'openid',
        label: 'OpenIdAuth',
        path: 'social_core.backends.open_id.OpenIdAuth'
    },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.belgiumeid.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.fedora.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.steam.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.ubuntu.OpenIdAuth'
    // },
    // {
    //   name: 'openid',
    //   label: 'OpenIdAuth',
    //   path: 'social_core.backends.livejournal.OpenIdAuth'
    // },
    {
        name: 'opensuse',
        label: 'OpenSUSE OpenId',
        path: 'social_core.backends.suse.OpenSUSEOpenId'
    },
    {
        name: 'openstackdev',
        label: 'OpenStackDev OpenId',
        path: 'social_core.backends.openstackdev.OpenStackDevOpenId'
    },
    {
        name: 'openstack',
        label: 'OpenStack OpenId',
        path: 'social_core.backends.openstack.OpenStackOpenId'
    },
    {
        name: 'openstreetmap',
        label: 'OpenStreetMap OAuth',
        path: 'social_core.backends.openstreetmap.OpenStreetMapOAuth'
    },
    {
        name: 'openshift',
        label: 'Openshift OAuth 2.0',
        path: 'social_core.backends.openshift.OpenshiftOAuth2'
    },
    {
        name: 'orbi',
        label: 'Orbi OAuth 2.0',
        path: 'social_core.backends.orbi.OrbiOAuth2'
    },
    {
        name: 'patreon',
        label: 'Patreon OAuth 2.0',
        path: 'social_core.backends.patreon.PatreonOAuth2'
    },
    {
        name: 'persona',
        label: 'PersonaAuth',
        path: 'social_core.backends.persona.PersonaAuth'
    },
    {
        name: 'phabricator',
        label: 'Phabricator OAuth 2.0',
        path: 'social_core.backends.phabricator.PhabricatorOAuth2'
    },
    {
        name: 'pinterest',
        label: 'Pinterest OAuth 2.0',
        path: 'social_core.backends.pinterest.PinterestOAuth2'
    },
    {
        name: 'pocket',
        label: 'PocketAuth',
        path: 'social_core.backends.pocket.PocketAuth'
    },
    {
        name: 'podio',
        label: 'Podio OAuth 2.0',
        path: 'social_core.backends.podio.PodioOAuth2'
    },
    {
        name: 'professionali',
        label: 'Professionali OAuth 2.0',
        path: 'social_core.backends.professionali.ProfessionaliOAuth2'
    },
    {
        name: 'pushbullet',
        label: 'Pushbullet OAuth 2.0',
        path: 'social_core.backends.pushbullet.PushbulletOAuth2'
    },
    {
        name: 'qq',
        label: 'QQ OAuth 2.0',
        path: 'social_core.backends.qq.QQOAuth2'
    },
    {
        name: 'qiita',
        label: 'Qiita OAuth 2.0',
        path: 'social_core.backends.qiita.QiitaOAuth2'
    },
    {
        name: 'quizlet',
        label: 'Quizlet OAuth 2.0',
        path: 'social_core.backends.quizlet.QuizletOAuth2'
    },
    {
        name: 'rdio-oauth1',
        label: 'Rdio OAuth 1.0',
        path: 'social_core.backends.rdio.RdioOAuth1'
    },
    {
        name: 'rdio-oauth2',
        label: 'Rdio OAuth 2.0',
        path: 'social_core.backends.rdio.RdioOAuth2'
    },
    {
        name: 'readability',
        label: 'Readability OAuth',
        path: 'social_core.backends.readability.ReadabilityOAuth'
    },
    {
        name: 'reddit',
        label: 'Reddit OAuth 2.0',
        path: 'social_core.backends.reddit.RedditOAuth2'
    },
    {
        name: 'runkeeper',
        label: 'RunKeeper OAuth 2.0',
        path: 'social_core.backends.runkeeper.RunKeeperOAuth2'
    },
    {
        name: salesforceBackendName,
        label: 'Salesforce OAuth 2.0',
        path: 'social_core.backends.salesforce.SalesforceOAuth2'
    },
    {
        name: 'salesforce-oauth2-sandbox',
        label: 'Salesforce OAuth 2.0 Sandbox',
        path: 'social_core.backends.salesforce.SalesforceOAuth2Sandbox'
    },
    {
        name: 'scistarter',
        label: 'SciStarter OAuth 2.0',
        path: 'social_core.backends.scistarter.SciStarterOAuth2'
    },
    {
        name: 'shimmering',
        label: 'Shimmering OAuth 2.0',
        path: 'social_core.backends.shimmering.ShimmeringOAuth2'
    },
    {
        name: 'shopify',
        label: 'Shopify OAuth 2.0',
        path: 'social_core.backends.shopify.ShopifyOAuth2'
    },
    {
        name: 'sketchfab',
        label: 'Sketchfab OAuth 2.0',
        path: 'social_core.backends.sketchfab.SketchfabOAuth2'
    },
    {
        name: 'skyrock',
        label: 'Skyrock OAuth',
        path: 'social_core.backends.skyrock.SkyrockOAuth'
    },
    {
        name: 'slack',
        label: 'Slack OAuth 2.0',
        path: 'social_core.backends.slack.SlackOAuth2'
    },
    {
        name: 'soundcloud',
        label: 'Soundcloud OAuth 2.0',
        path: 'social_core.backends.soundcloud.SoundcloudOAuth2'
    },
    {
        name: 'spotify',
        label: 'Spotify OAuth 2.0',
        path: 'social_core.backends.spotify.SpotifyOAuth2'
    },
    {
        name: 'stackoverflow',
        label: 'Stackoverflow OAuth 2.0',
        path: 'social_core.backends.stackoverflow.StackoverflowOAuth2'
    },
    {
        name: 'steam',
        label: 'Steam OpenId',
        path: 'social_core.backends.steam.SteamOpenId'
    },
    {
        name: 'stocktwits',
        label: 'Stocktwits OAuth 2.0',
        path: 'social_core.backends.stocktwits.StocktwitsOAuth2'
    },
    {
        name: 'strava',
        label: 'Strava OAuth',
        path: 'social_core.backends.strava.StravaOAuth'
    },
    {
        name: 'stripe',
        label: 'Stripe OAuth 2.0',
        path: 'social_core.backends.stripe.StripeOAuth2'
    },
    {
        name: 'taobao',
        label: 'TAOBA OAuth',
        path: 'social_core.backends.taobao.TAOBAOAuth'
    },
    {
        name: 'telegram',
        label: 'TelegramAuth',
        path: 'social_core.backends.telegram.TelegramAuth'
    },
    {
        name: 'thisismyjam',
        label: 'ThisIsMyJam',
        path: 'social_core.backends.thisismyjam.ThisIsMyJamOAuth1'
    },
    {
        name: 'trello',
        label: 'Trello OAuth',
        path: 'social_core.backends.trello.TrelloOAuth'
    },
    {
        name: 'tripit',
        label: 'TripIt OAuth',
        path: 'social_core.backends.tripit.TripItOAuth'
    },
    {
        name: 'tumblr',
        label: 'Tumblr OAuth',
        path: 'social_core.backends.tumblr.TumblrOAuth'
    },
    {
        name: 'twilio',
        label: 'TwilioAuth',
        path: 'social_core.backends.twilio.TwilioAuth'
    },
    {
        name: 'twitch',
        label: 'Twitch OAuth 2.0',
        path: 'social_core.backends.twitch.TwitchOAuth2'
    },
    {
        name: 'twitter',
        label: 'Twitter OAuth',
        path: 'social_core.backends.twitter.TwitterOAuth'
    },
    {
        name: 'uber',
        label: 'Uber OAuth 2.0',
        path: 'social_core.backends.uber.UberOAuth2'
    },
    {
        name: 'ubuntu',
        label: 'Ubuntu OpenId',
        path: 'social_core.backends.ubuntu.UbuntuOpenId'
    },
    {
        name: 'universe',
        label: 'Universe OAuth 2.0',
        path: 'social_core.backends.universe.UniverseOAuth2'
    },
    {
        name: 'untappd',
        label: 'Untappd OAuth 2.0',
        path: 'social_core.backends.untappd.UntappdOAuth2'
    },
    {
        name: 'upwork',
        label: 'Upwork OAuth',
        path: 'social_core.backends.upwork.UpworkOAuth'
    },
    {
        name: 'username',
        label: 'UsernameAuth',
        path: 'social_core.backends.username.UsernameAuth'
    },
    {
        name: 'vk-app',
        label: 'VKApp OAuth 2.0',
        path: 'social_core.backends.vk.VKAppOAuth2'
    },
    {
        name: 'vk-oauth2',
        label: 'VK OAuth 2.0',
        path: 'social_core.backends.vk.VKOAuth2'
    },
    {
        name: 'vk-openapi',
        label: 'VKontakteOpenAPI',
        path: 'social_core.backends.vk.VKontakteOpenAPI'
    },
    {
        name: 'vend',
        label: 'Vend OAuth 2.0',
        path: 'social_core.backends.vend.VendOAuth2'
    },
    {
        name: 'vimeo',
        label: 'Vimeo OAuth 1.0',
        path: 'social_core.backends.vimeo.VimeoOAuth1'
    },
    {
        name: 'vimeo-oauth2',
        label: 'Vimeo OAuth 2.0',
        path: 'social_core.backends.vimeo.VimeoOAuth2'
    },
    {
        name: 'weibo',
        label: 'Weibo OAuth 2.0',
        path: 'social_core.backends.weibo.WeiboOAuth2'
    },
    {
        name: 'weixin',
        label: 'Weixin OAuth 2.0',
        path: 'social_core.backends.weixin.WeixinOAuth2'
    },
    {
        name: 'weixinapp',
        label: 'WeixinOAuth2APP',
        path: 'social_core.backends.weixin.WeixinOAuth2APP'
    },
    {
        name: 'withings',
        label: 'Withings OAuth',
        path: 'social_core.backends.withings.WithingsOAuth'
    },
    {
        name: 'wunderlist',
        label: 'Wunderlist OAuth 2.0',
        path: 'social_core.backends.wunderlist.WunderlistOAuth2'
    },
    {
        name: 'xing',
        label: 'Xing OAuth',
        path: 'social_core.backends.xing.XingOAuth'
    },
    {
        name: 'yahoo-oauth',
        label: 'Yahoo OAuth 1.0',
        path: 'social_core.backends.yahoo.YahooOAuth'
    },
    {
        name: 'yahoo-oauth2',
        label: 'Yahoo OAuth 2.0',
        path: 'social_core.backends.yahoo.YahooOAuth2'
    },
    {
        name: 'yahoo',
        label: 'Yahoo OpenId',
        path: 'social_core.backends.yahoo.YahooOpenId'
    },
    {
        name: 'yammer',
        label: 'Yammer OAuth 2.0',
        path: 'social_core.backends.yammer.YammerOAuth2'
    },
    {
        name: 'yammer-staging',
        label: 'YammerStaging OAuth 2.0',
        path: 'social_core.backends.yammer.YammerStagingOAuth2'
    },
    {
        name: 'yandex-oauth2',
        label: 'Yandex OAuth 2.0',
        path: 'social_core.backends.yandex.YandexOAuth2'
    },
    {
        name: 'yandex-openid',
        label: 'Yandex OpenId',
        path: 'social_core.backends.yandex.YandexOpenId'
    },
    {
        name: 'yaru',
        label: 'Yaru OAuth 2.0',
        path: 'social_core.backends.yandex.YaruOAuth2'
    },
    {
        name: 'zotero',
        label: 'Zotero OAuth',
        path: 'social_core.backends.zotero.ZoteroOAuth'
    }
];
export var popularSSOProviders = [
    {
        type: SSOType.OAuth2,
        oauth2Backend: googleOAuth2BackendName
    },
    {
        type: SSOType.Custom,
        sharedCustomSSO: SharedCustomSSOType.Firebase
    },
    {
        type: SSOType.OAuth2,
        oauth2Backend: auth0BackendName
    },
    {
        type: SSOType.Custom,
        sharedCustomSSO: SharedCustomSSOType.Supabase
    },
    {
        type: SSOType.OAuth2,
        oauth2Backend: azureADV2TenantBackendName
    },
    {
        type: SSOType.OAuth2,
        oauth2Backend: cognitoBackendName
    },
    {
        type: SSOType.OAuth2,
        oauth2Backend: keycloakBackendName
    }
].map(function (provider) {
    if (provider.type == SSOType.OAuth2) {
        var socialBackend = socialBackends.find(function (item) { return item.name == provider.oauth2Backend; });
        return __assign({}, provider, { image: socialBackend ? socialBackend.image : undefined });
    }
    else if (provider.type == SSOType.Custom) {
        var customSSO = sharedCustomSSO.find(function (item) { return item.type == provider.sharedCustomSSO; });
        return __assign({}, provider, { image: customSSO ? customSSO.image : undefined });
    }
    else {
        return provider;
    }
});

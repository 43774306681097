<div class="side-popup" #sidePopupWrap>
  <div class="side-popup__inner" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }" #sidePopup>
    <div class="side-popup__header">
      <div class="side-popup__header-inner">
        <div class="side-popup__header-top">
          <h2 class="side-popup__title">{{ 'Activity Log' | localize }}</h2>
          <span (click)="close()" class="side-popup__close icon-close" #closeButton></span>
        </div>
        <div class="side-popup__header-bottom">
          <div [formGroup]="filters" class="side-popup__header-columns">
            <div class="side-popup__header-columns-item">
              <app-custom-select
                [control]="filters.controls.activity_type_in"
                [items]="activityTypeOptions"
                [classes]="['select_small', 'select_fill']"
                [panelClasses]="['mat-menu-panel_sidebar']"
                [allowSearch]="false"
              >
              </app-custom-select>
            </div>
            <div class="side-popup__header-columns-item">
              <app-custom-select
                [control]="filters.controls.user"
                [items]="activityUserOptions"
                [classes]="['select_small', 'select_fill']"
                [panelClasses]="['mat-menu-panel_sidebar']"
              >
              </app-custom-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-popup__content">
      <div *ngIf="(userActivityListStore.items$ | async) !== undefined" class="user-activities-popup__table">
        <table class="table table_compact">
          <tbody>
            <tr
              *ngFor="let item of userActivityListStore.items$ | async"
              [item]="item"
              app-user-activities-popup-item
              class="table__row"
            ></tr>

            <ng-container *ngIf="userActivityListStore.loading$ | async">
              <tr *ngFor="let item of [1, 2, 3]" class="table__row">
                <td class="table__column table__column_left-padding"></td>
                <td class="table__column">
                  <div class="table__column-inner">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">aa</span>
                    </span>
                  </div>
                </td>
                <td class="table__column">
                  <div class="table__column-inner">
                    <div class="user-activities-popup__type-description">
                      <span class="user-activities-popup__type">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">aaaa</span>
                        </span>
                      </span>
                      <span class="user-activities-popup__description">
                        <span [class.loading-animation]="true">
                          <span class="stub-text">aaaaaa</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="(userActivityListStore.items$ | async) === undefined" class="user-activities-popup__table-stub">
        <table class="table table_compact">
          <tbody>
            <tr *ngFor="let item of [1, 2, 3, 4, 5, 6]" class="table__row">
              <td class="table__column table__column_left-padding"></td>
              <td class="table__column">
                <div class="table__column-inner">
                  <span [class.loading-animation]="loading">
                    <span class="stub-text">aa</span>
                  </span>
                </div>
              </td>
              <td class="table__column">
                <div class="table__column-inner">
                  <div class="user-activities-popup__type-description">
                    <span class="user-activities-popup__type">
                      <span [class.loading-animation]="loading">
                        <span class="stub-text">aaaa</span>
                      </span>
                    </span>
                    <span class="user-activities-popup__description">
                      <span [class.loading-animation]="loading">
                        <span class="stub-text">aaaaaa</span>
                      </span>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="(userActivityListStore.items$ | async)?.length === 0" class="side-popup__error">
    <app-error [title]="'No activities found' | localize" [compact]="true">
      {{ 'Start to working with data to log activities.' | localize }}

      <button *ngIf="filtersIsSet" (click)="filters.reset()" class="button button_primary" style="margin-top: 20px;">
        Reset Filters
      </button>
    </app-error>
  </div>
</div>

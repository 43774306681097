/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i3 from "../../../../fields/components/auto-field/auto-field.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../field-components/components/field-errors/field-errors.component.ngfactory";
import * as i6 from "../../../../field-components/components/field-errors/field-errors.component";
import * as i7 from "../../../../field-components/components/auto-field-stub/auto-field-stub.component.ngfactory";
import * as i8 from "../../../../field-components/components/auto-field-stub/auto-field-stub.component";
import * as i9 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i10 from "../../../../customize/data/view-context-element";
import * as i11 from "../../../../customize/data/view-context";
import * as i12 from "./filter-element-item.component";
import * as i13 from "../../../../customize/services/customize/customize.service";
import * as i14 from "../../../../model-queries/stores/model-description.store";
import * as i15 from "../../../../projects/stores/current-project.store";
import * as i16 from "../../custom-page-popup/custom-page-popup.component";
var styles_FilterElementItemComponent = [];
var RenderType_FilterElementItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterElementItemComponent, data: {} });
export { RenderType_FilterElementItemComponent as RenderType_FilterElementItemComponent };
function View_FilterElementItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "field__label-help help-icon icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FilterElementItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "app-auto-field", [], null, null, null, i2.View_AutoFieldComponent_0, i2.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i3.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], tooltip: [3, "tooltip"], accentColor: [4, "accentColor"] }, null), i0.ɵpod(2, { value: 0, name: 1 }), i0.ɵpod(3, { value: 0, name: 1 }), i0.ɵpod(4, { value: 0, name: 1 }), i0.ɵpad(5, 3), i0.ɵpad(6, 1), i0.ɵpod(7, { valueEquals: 0, options: 1, classes: 2 }), i0.ɵpod(8, { name: 0, field: 1, params: 2, reset_enabled: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 8, 0, _co.state.formField.name, _co.fieldTypes.Select, _ck(_v, 7, 0, _co.booleanValueEquals, _ck(_v, 5, 0, _ck(_v, 2, 0, null, "Not specified"), _ck(_v, 3, 0, false, "No"), _ck(_v, 4, 0, true, "Yes")), _ck(_v, 6, 0, "select_fill")), true)); var currVal_2 = false; var currVal_3 = _co.tooltip; var currVal_4 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_FilterElementItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], null, null, null, i2.View_AutoFieldComponent_0, i2.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i3.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], label: [3, "label"], context: [4, "context"], contextElement: [5, "contextElement"], manualMargin: [6, "manualMargin"], tooltip: [7, "tooltip"], accentColor: [8, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.state.formField; var currVal_2 = false; var currVal_3 = false; var currVal_4 = _co.context; var currVal_5 = _co.viewContextElement; var currVal_6 = true; var currVal_7 = _co.tooltip; var currVal_8 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_FilterElementItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "label", [["class", "field__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "field__label-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FilterElementItemComponent_2)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "field__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_3)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_4)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-field-errors", [], null, null, null, i5.View_FieldErrorsComponent_0, i5.RenderType_FieldErrorsComponent)), i0.ɵdid(13, 114688, null, 0, i6.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.tooltip)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.state.formField.field == _co.fieldTypes.Boolean); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.state.formField.field != _co.fieldTypes.Boolean); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.form; var currVal_5 = _co.field.name; _ck(_v, 13, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.formField.label; _ck(_v, 3, 0, currVal_0); }); }
function View_FilterElementItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterElementItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 1, 0, currVal_0); }); }
function View_FilterElementItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "label", [["class", "field__label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_6)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_7)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-auto-field-stub", [], null, null, null, i7.View_AutoFieldStubComponent_0, i7.RenderType_AutoFieldStubComponent)), i0.ɵdid(7, 114688, null, 0, i8.AutoFieldStubComponent, [], { field: [0, "field"], readonly: [1, "readonly"], label: [2, "label"], manualMargin: [3, "manualMargin"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 3, 0, currVal_0); var currVal_1 = !((_co.state.formField == null) ? null : _co.state.formField.label); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.state.formField; var currVal_3 = false; var currVal_4 = false; var currVal_5 = true; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_FilterElementItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterElementItemComponent_5)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.loadingValue && _co.state.formField); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loadingValue; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FilterElementItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-filter-element-item", [], null, null, null, View_FilterElementItemComponent_0, RenderType_FilterElementItemComponent)), i0.ɵprd(131584, null, i10.ViewContextElement, i10.ViewContextElement, [i11.ViewContext]), i0.ɵdid(2, 770048, null, 0, i12.FilterElementItemComponent, [i13.CustomizeService, i14.ModelDescriptionStore, i15.CurrentProjectStore, i10.ViewContextElement, i0.ChangeDetectorRef, [2, i16.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FilterElementItemComponentNgFactory = i0.ɵccf("app-filter-element-item", i12.FilterElementItemComponent, View_FilterElementItemComponent_Host_0, { name: "name", field: "field", tooltip: "tooltip", context: "context", parent: "parent", accentColor: "accentColor" }, {}, []);
export { FilterElementItemComponentNgFactory as FilterElementItemComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectsStore } from '@modules/projects';
import { capitalize, isSet } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "../../projects/stores/projects.store";
var MetaService = /** @class */ (function () {
    function MetaService(titleService, projectsStore, meta) {
        var _this = this;
        this.titleService = titleService;
        this.projectsStore = projectsStore;
        this.meta = meta;
        this.jetMetaData = {
            titleShort: 'Jet Admin',
            shareTitle: 'Jet | Back office totally ready to run your service',
            description: 'Save time and money on the design, development and support of your own back office.',
            image: 'assets/favicon/apple-touch-icon.png',
            icon: {
                w180: 'assets/favicon/apple-touch-icon.png',
                w32: 'assets/favicon/favicon-32x32.png',
                w16: 'assets/favicon/favicon-16x16.png',
                default: 'assets/favicon/apple-touch-icon.png'
            },
            manifest: 'assets/favicon/site.webmanifest',
            color: '#2b50ed',
            maskIcon: 'assets/favicon/safari-pinned-tab.svg',
            msApplicationConfig: 'assets/favicon/browserconfig.xml'
        };
        this._title = new BehaviorSubject(undefined);
        this._defaultMetaData = new BehaviorSubject({});
        this.title$.subscribe(function (value) {
            var title = _this.titleFormat(value);
            _this.tagTitle = title.join(' · ');
        });
    }
    MetaService.prototype.resetMeta = function () {
        this.set({});
    };
    MetaService.prototype.set = function (meta) {
        meta = __assign({}, this.defaultMetaData, meta);
        var title;
        if (meta.title instanceof Array) {
            title = meta.title;
        }
        else {
            title = [meta.title];
        }
        this.title = title.filter(function (item) { return isSet(item); });
        this.tagDescription = meta.description;
        this.tagOgTitle = meta.shareTitle;
        this.tagOgDescription = meta.description;
        this.tagOgImage = meta.image;
        this.tagIcon = meta.icon;
        this.manifest = meta.manifest;
        this.maskIcon = meta.maskIcon;
        this.color = meta.color;
        this.msApplicationConfig = meta.msApplicationConfig;
    };
    MetaService.prototype.titleFormat = function (value) {
        var title = value || [];
        var trailing;
        if (this.projectsStore.current) {
            trailing = this.projectsStore.current.name;
        }
        else if (this.defaultMetaData) {
            trailing = this.defaultMetaData.titleShort;
        }
        title = title.map(function (item) { return capitalize(item); });
        if (trailing) {
            title.push(trailing);
        }
        return title;
    };
    Object.defineProperty(MetaService.prototype, "title", {
        get: function () {
            return this._title.value;
        },
        set: function (value) {
            this._title.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "title$", {
        get: function () {
            return this._title.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "titlePrimary$", {
        get: function () {
            var _this = this;
            return this.title$.pipe(map(function (value) {
                var title = _this.titleFormat(value);
                return title[0];
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "titlePrimary", {
        get: function () {
            var title = this.titleFormat(this.title);
            return title[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "defaultMetaData", {
        get: function () {
            return this._defaultMetaData.value;
        },
        set: function (value) {
            this._defaultMetaData.next(__assign({}, value, { titleShort: isSet(value.titleShort) ? value.titleShort : this.jetMetaData.titleShort, icon: isSet(value.icon) ? value.icon : this.jetMetaData.icon }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "defaultMetaData$", {
        get: function () {
            return this._defaultMetaData.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MetaService.prototype.setJetDefaultMetaData = function () {
        this._defaultMetaData.next(this.jetMetaData);
    };
    Object.defineProperty(MetaService.prototype, "tagTitle", {
        set: function (value) {
            this.titleService.setTitle(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "tagDescription", {
        set: function (value) {
            this.updateMetaTagContent('description', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "tagOgTitle", {
        set: function (value) {
            this.updateMetaTagContent('og:title', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "tagOgDescription", {
        set: function (value) {
            this.updateMetaTagContent('og:description', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "tagOgImage", {
        set: function (value) {
            this.updateMetaTagContent('og:image', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "tagIcon", {
        set: function (value) {
            var _this = this;
            var tags = [
                { query: 'link[sizes="180x180"]', size: 'w180' },
                { query: 'link[sizes="32x32"]', size: 'w32' },
                { query: 'link[sizes="16x16"]', size: 'w16' },
                { query: 'link[rel~="shortcut"]', size: 'w16' }
            ];
            tags.forEach(function (tag) {
                var image = '';
                if (value && value[tag.size]) {
                    image = value[tag.size];
                }
                else if (value) {
                    image = value.default;
                }
                _this.updateElementAttribute(tag.query, 'href', image);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "manifest", {
        set: function (value) {
            this.updateElementAttribute('link[rel="manifest"]', 'href', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "maskIcon", {
        set: function (value) {
            this.updateElementAttribute('link[rel="mask-icon"]', 'href', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "color", {
        set: function (value) {
            this.updateElementAttribute('link[rel="mask-icon"]', 'color', value);
            this.updateElementAttribute('meta[name="msapplication-TileColor"]', 'content', value);
            this.updateElementAttribute('meta[name="theme-color"]', 'content', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MetaService.prototype, "msApplicationConfig", {
        set: function (value) {
            this.updateElementAttribute('meta[name="msapplication-config"]', 'content', value);
        },
        enumerable: true,
        configurable: true
    });
    MetaService.prototype.updateMetaTagContent = function (property, value) {
        this.meta.updateTag({ property: property, content: isSet(value) ? value : '' });
    };
    MetaService.prototype.updateElementAttribute = function (selector, name, value) {
        var element = document.querySelector(selector);
        if (!element) {
            return;
        }
        element.setAttribute(name, isSet(value) ? value : '');
    };
    MetaService.ngInjectableDef = i0.defineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.inject(i1.Title), i0.inject(i2.ProjectsStore), i0.inject(i1.Meta)); }, token: MetaService, providedIn: "root" });
    return MetaService;
}());
export { MetaService };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, Input as FieldInput } from '@modules/fields';
import { getInputRepresentation } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, forceObservable, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { ConditionCustomizeWorkflowStepItemControl } from '../condition-customize-workflow-step/condition-customize-workflow-step-item.control';

@Component({
  selector: 'app-condition-customize-workflow-step-switch-item',
  templateUrl: './condition-customize-workflow-step-switch-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionCustomizeWorkflowStepSwitchItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: ConditionCustomizeWorkflowStepItemControl;
  @Input() title: string;
  @Input() defaultTitle: string;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() openedInitial = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Output() remove = new EventEmitter<void>();

  createField = createFormFieldFactory();
  opened = false;
  rename = false;
  renameControl = new FormControl('');
  hovered$ = new BehaviorSubject<boolean>(false);
  description: string;
  descriptionSubscription: Subscription;
  initCollapseContextSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ConditionCustomizeWorkflowStepSwitchItemComponent>): void {
    if (changes.control) {
      this.updateDescription();
    }

    if (changes.collapseContext) {
      this.initCollapseContext();
    }
  }

  initCollapseContext() {
    if (this.initCollapseContextSubscription) {
      this.initCollapseContextSubscription.unsubscribe();
      this.initCollapseContextSubscription = undefined;
    }

    if (this.collapseContext) {
      this.initCollapseContextSubscription = this.collapseContext.opened$
        .pipe(untilDestroyed(this))
        .subscribe(opened => {
          this.opened = opened === this;
          this.cd.markForCheck();
        });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  setOpened(opened: boolean) {
    if (this.collapseContext) {
      if (opened && this.collapseContext.opened !== this) {
        this.collapseContext.opened = this;
      } else if (!opened && this.collapseContext.opened === this) {
        this.collapseContext.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  setRename(rename: boolean, save = true) {
    this.rename = rename;
    this.cd.markForCheck();

    if (this.rename) {
      this.renameControl.patchValue(this.title);
    } else if (!rename && save) {
      this.control.controls.label.patchValue(this.renameControl.value);
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.setRename(false);
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.setRename(false, false);
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  updateDescription() {
    if (this.descriptionSubscription) {
      this.descriptionSubscription.unsubscribe();
    }

    if (!this.control) {
      return;
    }

    this.descriptionSubscription = controlValue(this.control.controls.condition)
      .pipe(
        switchMap(value => {
          if (!value) {
            return of(undefined);
          }

          const input = new FieldInput().deserialize(value);

          const result = getInputRepresentation(input, {
            context: this.context,
            contextElement: this.contextElement
          });

          return forceObservable<string>(result);
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(description => {
        this.description = description;
        this.cd.markForCheck();
      });
  }
}

import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PageLoadingService } from '@common/page-loading';
import { DocumentService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { serializeTokenOptions } from '@modules/api';
import { AuthService, TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { DomainStore } from '@modules/domain';
import { ProjectService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import * as i0 from "@angular/core";
import * as i1 from "../../projects/stores/projects.store";
import * as i2 from "../../domain/stores/domain.store";
import * as i3 from "../../projects/services/project/project.service";
import * as i4 from "../../auth/services/auth/auth.service";
import * as i5 from "../../../core/services/document/document.service";
import * as i6 from "../../routing/services/routing/routing.service";
import * as i7 from "../../../common/page-loading/services/page-loading/page-loading.service";
export var ROUTE_LOADED_PROJECTS_STORE = 'ROUTE_LOADED_PROJECTS_STORE';
var DomainProjectGuard = /** @class */ (function () {
    function DomainProjectGuard(projectsStore, domainStore, projectService, authService, documentService, routing, pageLoadingService) {
        this.projectsStore = projectsStore;
        this.domainStore = domainStore;
        this.projectService = projectService;
        this.authService = authService;
        this.documentService = documentService;
        this.routing = routing;
        this.pageLoadingService = pageLoadingService;
    }
    DomainProjectGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var loader = !this.projectsStore.instance || !this.domainStore.instance ? {} : undefined;
        if (loader) {
            this.pageLoadingService.addLoader(loader);
        }
        var initialProjects = this.projectsStore.instance;
        return combineLatest(this.projectsStore.getFirst(), this.domainStore.getFirst()).pipe(switchMap(function (_a) {
            var projects = _a[0], domain = _a[1];
            if (!initialProjects) {
                _this.routing.setRouterAttr(ROUTE_LOADED_PROJECTS_STORE, true);
            }
            if (loader) {
                _this.pageLoadingService.removeLoader(loader);
            }
            if (domain && domain.whiteLabel && domain.domain) {
                var domainProjects = projects.filter(function (item) { return item.isDomain(domain); });
                var singleDomainProject_1 = domainProjects.length === 1 ? domainProjects[0] : undefined;
                if (singleDomainProject_1) {
                    var defaultEnvironmentUniqueName_1 = singleDomainProject_1.defaultEnvironment
                        ? singleDomainProject_1.defaultEnvironment.uniqueName
                        : undefined;
                    var environment = (singleDomainProject_1.defaultEnvironment ? [singleDomainProject_1.defaultEnvironment] : []).concat(singleDomainProject_1.environments.filter(function (item) { return item.uniqueName != defaultEnvironmentUniqueName_1; })).find(function (item) { return singleDomainProject_1.hasEnvironmentPermissions(item); });
                    var mode = singleDomainProject_1.hasEnvironmentCustomizationPermission(environment) && !_this.documentService.isMobile()
                        ? AdminMode.Builder
                        : AdminMode.App;
                    var link_1 = singleDomainProject_1.getHomeLinkWithProtocol({
                        mode: mode,
                        environmentName: environment ? environment.uniqueName : undefined
                    });
                    if (link_1.href) {
                        return _this.authService.generateToken().pipe(map(function (externalToken) {
                            link_1.href = _this.getExternalHref(externalToken, link_1.href);
                            _this.routing.navigateLink(link_1);
                            return false;
                        }));
                    }
                    else {
                        _this.routing.navigateLink(link_1);
                        return of(false);
                    }
                }
            }
            return of(true);
        }), catchError(function () {
            if (loader) {
                _this.pageLoadingService.removeLoader(loader);
            }
            return of(true);
        }));
    };
    DomainProjectGuard.prototype.getExternalHref = function (externalToken, href) {
        if (externalToken) {
            var tokenStr = btoa(JSON.stringify(serializeTokenOptions(externalToken)));
            return href + "#" + TOKEN_FRAGMENT_KEY + "=" + tokenStr;
        }
        else {
            return href;
        }
    };
    DomainProjectGuard.ngInjectableDef = i0.defineInjectable({ factory: function DomainProjectGuard_Factory() { return new DomainProjectGuard(i0.inject(i1.ProjectsStore), i0.inject(i2.DomainStore), i0.inject(i3.ProjectService), i0.inject(i4.AuthService), i0.inject(i5.DocumentService), i0.inject(i6.RoutingService), i0.inject(i7.PageLoadingService)); }, token: DomainProjectGuard, providedIn: "root" });
    return DomainProjectGuard;
}());
export { DomainProjectGuard };

import { GuideTaskProgress } from './guide-task-progress';

export class GuideTask {
  public uniqueName: string;
  public title: string;
  public description: string;
  public duration: string;
  public documentation: string;
  public video: string;
  public autoComplete: number;
  public ordering: number;
  public progress: GuideTaskProgress;

  deserialize(data: Object): GuideTask {
    this.uniqueName = data['unique_name'];
    this.title = data['title'];
    this.description = data['description'];
    this.duration = data['duration'];
    this.documentation = data['documentation'];
    this.video = data['video'];
    this.autoComplete = data['auto_complete'];
    this.ordering = data['ordering'];

    if (data['progress']) {
      this.progress = new GuideTaskProgress().deserialize(data['progress']);
    }

    return this;
  }

  get completed() {
    return this.autoComplete || this.progress != undefined;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { ActionItem } from '@modules/actions';
import { TypedControl } from '@shared';
var ActionItemArray = /** @class */ (function (_super) {
    __extends(ActionItemArray, _super);
    function ActionItemArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActionItemArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.patchValue(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    ActionItemArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.value; });
    };
    ActionItemArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    ActionItemArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    ActionItemArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    ActionItemArray.prototype.createControl = function (item) {
        return new TypedControl(item || new ActionItem());
    };
    ActionItemArray.prototype.appendControl = function (item) {
        var control = this.createControl(item);
        this.push(control);
        return control;
    };
    ActionItemArray.prototype.getControlNameDefault = function (index) {
        return "Action " + (index + 1);
    };
    ActionItemArray.prototype.generateActionName = function (defaultName, names) {
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (names.hasOwnProperty(newName.toLowerCase()));
        return newName;
    };
    return ActionItemArray;
}(FormArray));
export { ActionItemArray };

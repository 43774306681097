<div class="resource-popup__wrap">
  <div class="resource-popup__left">
    <ng-content></ng-content>
  </div>

  <div class="resource-popup__right" [class.resource-popup__right_visible]="content.children.length > 0">
    <div class="resource-popup__banner">
      <div class="resource-popup__banner-background">
        <div class="resource-popup__banner-circle resource-popup__banner-circle_one"></div>
        <div class="resource-popup__banner-circle resource-popup__banner-circle_two"></div>
      </div>

      <div class="resource-popup__banner-content" #content>
        <ng-content select="[data-side]"></ng-content>
      </div>
    </div>
  </div>
</div>

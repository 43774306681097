/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i2 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i3 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i4 from "../../../../fields/components/auto-field/auto-field.component";
import * as i5 from "./date-time-field-data-params.form";
import * as i6 from "@angular/forms";
import * as i7 from "./date-time-field-data-params.component";
var styles_DateTimeFieldDataParamsComponent = [];
var RenderType_DateTimeFieldDataParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateTimeFieldDataParamsComponent, data: {} });
export { RenderType_DateTimeFieldDataParamsComponent as RenderType_DateTimeFieldDataParamsComponent };
export function View_DateTimeFieldDataParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i3.View_AutoFieldComponent_0, i3.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i4.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { options: 0, classes: 1 }), i0.ɵpod(7, { name: 0, field: 1, params: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "output format"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form.form; var currVal_2 = _co.createField(_ck(_v, 7, 0, "output_format", "SelectField", _ck(_v, 6, 0, _co.form.outputFormat, _ck(_v, 5, 0, "select_fill")))); var currVal_3 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DateTimeFieldDataParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-date-time-field-data-params", [], null, null, null, View_DateTimeFieldDataParamsComponent_0, RenderType_DateTimeFieldDataParamsComponent)), i0.ɵprd(512, null, i5.DateTimeFieldDataParamsForm, i5.DateTimeFieldDataParamsForm, [i6.FormBuilder]), i0.ɵdid(2, 114688, null, 0, i7.DateTimeFieldDataParamsComponent, [i5.DateTimeFieldDataParamsForm], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DateTimeFieldDataParamsComponentNgFactory = i0.ɵccf("app-date-time-field-data-params", i7.DateTimeFieldDataParamsComponent, View_DateTimeFieldDataParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { DateTimeFieldDataParamsComponentNgFactory as DateTimeFieldDataParamsComponentNgFactory };

export enum TaskType {
  InviteUser = 'invite_user',
  CreateGroup = 'create_group',
  CustomizeMenu = 'customize_menu',
  CustomizeModel = 'customize_model',
  CustomizeModelDbField = 'customize_model_field',
  CustomizeListView = 'customize_list_view',
  CustomizeChangeView = 'customize_change_view',
  AddRelatedData = 'add_related_data',
  FindRecord = 'find_record',
  CreateSegment = 'create_segment',
  CreateSegmentSql = 'create_segment_sql',
  DuplicateRecord = 'duplicate_record',
  ExportRecords = 'export_records',
  AddChartWidget = 'add_chart_widget',
  AddChartWidgetSql = 'add_chart_widget_sql',
  ChangeChartType = 'change_chart_type',
  AddChartForChangeView = 'add_chart_for_change_view',
  CustomizeProject = 'customize_project',
  CreateFlexView = 'create_flex_view',
  CreateFlexField = 'create_flex_field',
  AddNewProject = 'add_new_project',
  SwitchToDarkTheme = 'switch_to_dark_theme',
  CheckOutSources = 'check_out_sources'
}

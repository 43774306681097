import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-query-builder-simple',
  templateUrl: './query-builder-simple.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderSimpleComponent implements OnInit {
  @Output() saved = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  cancel() {
    this.canceled.emit();
  }

  submit() {
    this.saved.emit();
  }
}

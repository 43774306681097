import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServerRequestError } from '@modules/api';
import {
  DataSyncJob,
  DataSyncJobRecordUpdateTaskParams,
  DataSyncJobTask,
  DataSyncJobTaskStatus,
  DataSyncJobTaskType,
  DataSyncService
} from '@modules/data-sync';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { isSet } from '@shared';

@Component({
  selector: 'app-data-sync-job-tasks-item, [app-data-sync-job-tasks-item]',
  templateUrl: './data-sync-job-tasks-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSyncJobTasksItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: Resource;
  @Input() job: DataSyncJob;
  @Input() item: DataSyncJobTask;

  @ViewChild('secondRow') template: TemplateRef<any>;

  types = DataSyncJobTaskType;
  statuses = DataSyncJobTaskStatus;
  duration: string;
  recordUpdateTaskParams: DataSyncJobRecordUpdateTaskParams;
  recordUpdateModelDescription$: Observable<ModelDescription>;
  recordUpdateInfo$: Observable<string>;
  expanded = false;
  logs: string;
  logsLoading = false;
  logsError: string;
  logsSubscription: Subscription;
  hover = false;

  @HostListener('click') onClick() {
    this.toggleExpanded();
  }

  @HostListener('mouseenter') onEnter() {
    this.hover = true;
    this.cd.markForCheck();
  }

  @HostListener('mouseleave') onLeave() {
    this.hover = false;
    this.cd.markForCheck();
  }

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private dataSyncService: DataSyncService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private vcRef: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.vcRef.createEmbeddedView(this.template);

    if (this.item.isActive()) {
      const subscription = this.dataSyncService
        .subscribeJobTask(this.item)
        .pipe(untilDestroyed(this))
        .subscribe(task => {
          this.item = task;
          this.cd.markForCheck();
          this.updateDuration();

          if (!task.isActive()) {
            subscription.unsubscribe();
          }
        });
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateParams();
    this.updateDuration();
  }

  updateParams() {
    if (this.item.type == DataSyncJobTaskType.SyncRecord) {
      this.recordUpdateTaskParams = this.item.params as DataSyncJobRecordUpdateTaskParams;

      const modelId = this.resource
        ? [this.resource.uniqueName, this.recordUpdateTaskParams.tableName].join('.')
        : undefined;
      this.recordUpdateModelDescription$ = this.modelDescriptionStore.getDetailFirst(modelId);
      this.recordUpdateInfo$ = this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        map(modelDescription => {
          return [
            this.recordUpdateTaskParams.type,
            modelDescription ? modelDescription.verboseNamePlural : undefined,
            JSON.stringify(this.recordUpdateTaskParams.collectionRecordInPrimitiveStructure)
          ]
            .filter(item => isSet(item))
            .join(' ');
        })
      );
    }
  }

  updateDuration() {
    if (this.item.dateRun && this.item.dateFinished) {
      const seconds = moment(this.item.dateFinished).diff(moment(this.item.dateRun), 'seconds');

      if (seconds == 0) {
        this.duration = undefined;
      } else if (seconds >= 60) {
        this.duration = `${Math.round(seconds / 60)} min.`;
      } else {
        this.duration = `${seconds} sec.`;
      }
    } else {
      this.duration = undefined;
    }
  }

  getLogs() {
    this.cancelLogsRequest();

    this.logs = undefined;
    this.logsError = undefined;
    this.logsLoading = true;
    this.cd.markForCheck();

    this.logsSubscription = this.dataSyncService
      .getJobTaskLogs(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.logs = result;
          this.logsLoading = false;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.status == 404) {
            this.logsError = 'File not found';
          } else if (error instanceof ServerRequestError && error.errors.length) {
            this.logsError = error.errors[0];
          } else {
            this.logsError = error;
          }

          this.logsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  cancelLogsRequest() {
    if (this.logsSubscription) {
      this.logsSubscription.unsubscribe();
      this.logsSubscription = undefined;
    }
  }

  expand() {
    this.expanded = true;
    this.cd.markForCheck();

    this.getLogs();
  }

  collapse() {
    this.expanded = false;
    this.cd.markForCheck();
    this.cancelLogsRequest();
  }

  toggleExpanded() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Constraints, SideConstraint, SizeConstraint } from '@modules/views';
import { isSet } from '@shared';
var ConstraintsControl = /** @class */ (function (_super) {
    __extends(ConstraintsControl, _super);
    function ConstraintsControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            top: new FormControl(isSet(state.top) ? state.top : SideConstraint.Fluid),
            right: new FormControl(isSet(state.right) ? state.right : SideConstraint.Fluid),
            bottom: new FormControl(isSet(state.bottom) ? state.bottom : SideConstraint.Fluid),
            left: new FormControl(isSet(state.left) ? state.left : SideConstraint.Fluid),
            width: new FormControl(isSet(state.width) ? state.width : SizeConstraint.Fluid),
            height: new FormControl(isSet(state.height) ? state.height : SizeConstraint.Fluid)
        }) || this;
    }
    ConstraintsControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.top.patchValue(value.top, { emitEvent: options.emitEvent });
        this.controls.right.patchValue(value.right, { emitEvent: options.emitEvent });
        this.controls.bottom.patchValue(value.bottom, { emitEvent: options.emitEvent });
        this.controls.left.patchValue(value.left, { emitEvent: options.emitEvent });
        this.controls.width.patchValue(value.width, { emitEvent: options.emitEvent });
        this.controls.height.patchValue(value.height, { emitEvent: options.emitEvent });
    };
    ConstraintsControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Constraints();
        }
        instance.top = this.controls.top.value;
        instance.right = this.controls.right.value;
        instance.bottom = this.controls.bottom.value;
        instance.left = this.controls.left.value;
        instance.width = this.controls.width.value;
        instance.height = this.controls.height.value;
        return instance;
    };
    ConstraintsControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    ConstraintsControl.prototype.toggleTop = function () {
        var value = this.controls.top.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.top.patchValue(value);
    };
    ConstraintsControl.prototype.toggleRight = function () {
        var value = this.controls.right.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.right.patchValue(value);
    };
    ConstraintsControl.prototype.toggleBottom = function () {
        var value = this.controls.bottom.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.bottom.patchValue(value);
    };
    ConstraintsControl.prototype.toggleLeft = function () {
        var value = this.controls.left.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.left.patchValue(value);
    };
    ConstraintsControl.prototype.toggleWidth = function () {
        var value = this.controls.width.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.width.patchValue(value);
    };
    ConstraintsControl.prototype.toggleHeight = function () {
        var value = this.controls.height.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
        this.controls.height.patchValue(value);
    };
    ConstraintsControl.prototype.isTopDisabled = function () {
        return this.controls.bottom.value == SideConstraint.Fixed && this.controls.height.value == SideConstraint.Fixed;
    };
    ConstraintsControl.prototype.isRightDisabled = function () {
        return this.controls.left.value == SideConstraint.Fixed && this.controls.width.value == SideConstraint.Fixed;
    };
    ConstraintsControl.prototype.isBottomDisabled = function () {
        return this.controls.top.value == SideConstraint.Fixed && this.controls.height.value == SideConstraint.Fixed;
    };
    ConstraintsControl.prototype.isLeftDisabled = function () {
        return this.controls.right.value == SideConstraint.Fixed && this.controls.width.value == SideConstraint.Fixed;
    };
    ConstraintsControl.prototype.isWidthDisabled = function () {
        return this.controls.left.value == SideConstraint.Fixed && this.controls.right.value == SideConstraint.Fixed;
    };
    ConstraintsControl.prototype.isHeightDisabled = function () {
        return this.controls.top.value == SideConstraint.Fixed && this.controls.bottom.value == SideConstraint.Fixed;
    };
    return ConstraintsControl;
}(FormGroup));
export { ConstraintsControl };

export enum FlexLayoutOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum FlexLayoutAlign {
  Start = 'start',
  Center = 'center',
  End = 'end',
  Justify = 'justify'
}

export class FlexLayout {
  public orientation: FlexLayoutOrientation = FlexLayoutOrientation.Horizontal;
  public wrap = false;
  public alignHorizontal: FlexLayoutAlign = FlexLayoutAlign.Start;
  public alignVertical: FlexLayoutAlign = FlexLayoutAlign.Start;
  public gapHorizontal = 0;
  public gapVertical = 0;
  public paddingTop = 0;
  public paddingRight = 0;
  public paddingBottom = 0;
  public paddingLeft = 0;

  constructor(options: Partial<FlexLayout> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.orientation = data['orientation'];
    this.wrap = data['wrap'];
    this.alignHorizontal = data['align_horizontal'];
    this.alignVertical = data['align_vertical'];
    this.gapHorizontal = data['gap_horizontal'];
    this.gapVertical = data['gap_vertical'];
    this.paddingTop = data['padding_top'];
    this.paddingRight = data['padding_right'];
    this.paddingBottom = data['padding_bottom'];
    this.paddingLeft = data['padding_left'];

    return this;
  }

  serialize(): Object {
    return {
      orientation: this.orientation,
      wrap: this.wrap,
      align_horizontal: this.alignHorizontal,
      align_vertical: this.alignVertical,
      gap_horizontal: this.gapHorizontal,
      gap_vertical: this.gapVertical,
      padding_top: this.paddingTop,
      padding_right: this.paddingRight,
      padding_bottom: this.paddingBottom,
      padding_left: this.paddingLeft
    };
  }
}

<div class="element-drag-preview">
  <div *ngIf="image" class="element-drag-preview__left">
    <div class="element-drag-preview__image-container">
      <div class="element-drag-preview__image" [style.background-image]="imagePath"></div>
    </div>
  </div>

  <div class="element-drag-preview__main">
    <div class="element-drag-preview__content">
      <div class="element-drag-preview__title">{{ name }}</div>
      <div class="element-drag-preview__subtitle">dragging component</div>
    </div>
  </div>
</div>

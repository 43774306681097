import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IntercomService } from '@modules/analytics';

@Component({
  selector: 'app-resource-settings-submit-error',
  templateUrl: './resource-settings-submit-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSettingsSubmitErrorComponent implements OnInit {
  @Input() errors: string[] = [];
  @Output() contact = new EventEmitter();

  constructor(private intercomService: IntercomService) {}

  ngOnInit() {}

  openChat() {
    this.intercomService.openChat();
  }
}

export const salesforceResourceParamsParams: Object = {
  sync: false,
  featured: false,
  custom_proxy: '',
  base_http_query: {
    method: 'GET',
    auth_type: 'oauth_2',
    auth_params: {},
    body_type: 'JSON',
    body: '',
    headers: [
      {
        name: 'Authorization',
        value: 'Bearer {-oauth_access_token-}'
      }
    ],
    query_params: [],
    response_type: 'json',
    request_tokens: {}
  }
};

import { Type } from '@angular/core';

import { LayerType } from '@modules/views';

import { CustomizeLayerComponent } from '../components/customize-layers/base/base-customize-layer.component';

const customizeLayerComponentsByType: { type: LayerType; cls: Type<CustomizeLayerComponent> }[] = [];

export function getCustomizeLayerComponent(type: LayerType): Type<CustomizeLayerComponent> {
  const instance = customizeLayerComponentsByType.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerCustomizeLayerComponent(type: LayerType, cls: Type<CustomizeLayerComponent>) {
  customizeLayerComponentsByType.push({ type: type, cls: cls });
}

import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxScrollableModule } from 'ng-gxscrollable';
import { NgGxSelectModule } from 'ng-gxselect';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BannersModule } from '@common/banners';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { ActivitiesRoutesModule } from '@modules/activities-routes';
import { AnalyticsModule } from '@modules/analytics';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { DemoModule } from '@modules/demo';
import { DomainComponentsModule } from '@modules/domain-components';
import { FeaturesModule } from '@modules/features';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { MenuComponentsModule } from '@modules/menu-components';
import { MenuProfileModule } from '@modules/menu-profile';
import { ModelQueriesModule } from '@modules/model-queries';
import { ProjectSettingsComponentsModule } from '@modules/project-settings-components';
import { RoutingModule } from '@modules/routing';
import { ShareModule } from '@modules/share';
import { SlackComponentsModule } from '@modules/slack-components';
import { SnapshotsComponentsModule } from '@modules/snapshots-components';
import { TutorialModule } from '@modules/tutorial';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { CustomizeHelpComponent } from './components/customize-help/customize-help.component';
import { CustomizeMenuBannerComponent } from './components/customize-menu-banner/customize-menu-banner.component';
import { CustomizeMenuPagesGroupComponent } from './components/customize-menu-pages-group/customize-menu-pages-group.component';
import { CustomizeMenuPagesComponent } from './components/customize-menu-pages/customize-menu-pages.component';
import { CustomizeMenuQueuesComponent } from './components/customize-menu-queues/customize-menu-queues.component';
import { CustomizeMenuResourcesComponent } from './components/customize-menu-resources/customize-menu-resources.component';
import { CustomizeMenuComponent } from './components/customize-menu/customize-menu.component';
import { CustomizePreviewUserDropdownComponent } from './components/customize-preview-user-dropdown/customize-preview-user-dropdown.component';
import { CustomizePreviewComponent } from './components/customize-preview/customize-preview.component';
import { CustomizeTitleComponent } from './components/customize-title/customize-title.component';
import { CustomizeToolbarBottomContainerComponent } from './components/customize-toolbar-bottom-container/customize-toolbar-bottom-container.component';
import { CustomizeToolbarItemPopupComponent } from './components/customize-toolbar-item-popup/customize-toolbar-item-popup.component';
import { CustomizeToolbarItemComponent } from './components/customize-toolbar-item/customize-toolbar-item.component';
import { CustomizeToolbarComponent } from './components/customize-toolbar/customize-toolbar.component';
import { DraftChangesItemComponent } from './components/draft-changes-item/draft-changes-item.component';
import { DraftChangesComponent } from './components/draft-changes/draft-changes.component';
import { PublishMenuComponent } from './components/publish-menu/publish-menu.component';
import { SaveErrorMenuComponent } from './components/save-error-menu/save-error-menu.component';
import { CustomizeHandlerHeaderCenterDirective } from './directives/customize-handler-header-center/customize-handler-header-center.directive';
import { CustomizeHandlerHeaderLeftDirective } from './directives/customize-handler-header-left/customize-handler-header-left.directive';
import { CustomizeToolbarBottomDirective } from './directives/customize-toolbar-bottom/customize-toolbar-bottom.directive';

@NgModule({
  imports: [
    CommonModule,
    NgGxSelectModule,
    ModelQueriesModule,
    FieldComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FieldsModule,
    SharedModule,
    ActivitiesRoutesModule,
    RoutingModule,
    TutorialModule,
    DemoModule,
    AnalyticsModule,
    LocalizeModule,
    MenuProfileModule,
    MenuComponentsModule,
    TipsModule,
    OverlayModule,
    ObserversModule,
    ProjectSettingsComponentsModule,
    DomainComponentsModule,
    UiModule,
    FeaturesModule,
    ShareModule,
    PortalModule,
    CustomizeComponentsModule,
    MatMenuModule,
    NgGxScrollableModule,
    BannersModule,
    CustomizeBarModule,
    SnapshotsComponentsModule,
    InfiniteScrollModule,
    SlackComponentsModule,
    IconsModule
  ],
  declarations: [
    CustomizeToolbarComponent,
    CustomizeTitleComponent,
    CustomizeToolbarBottomDirective,
    CustomizeToolbarBottomContainerComponent,
    CustomizeToolbarItemComponent,
    CustomizeToolbarItemPopupComponent,
    CustomizeMenuComponent,
    CustomizeHelpComponent,
    DraftChangesComponent,
    DraftChangesItemComponent,
    CustomizePreviewComponent,
    PublishMenuComponent,
    CustomizeMenuPagesComponent,
    CustomizeMenuQueuesComponent,
    CustomizeMenuResourcesComponent,
    CustomizeMenuBannerComponent,
    CustomizeHandlerHeaderLeftDirective,
    CustomizeHandlerHeaderCenterDirective,
    CustomizePreviewUserDropdownComponent,
    CustomizeMenuPagesGroupComponent,
    SaveErrorMenuComponent
  ],
  exports: [
    CustomizeToolbarComponent,
    CustomizeToolbarBottomDirective,
    CustomizeToolbarItemComponent,
    CustomizeToolbarItemPopupComponent,
    CustomizeHandlerHeaderLeftDirective,
    CustomizeHandlerHeaderCenterDirective,
    CustomizeTitleComponent,
    CustomizeMenuComponent
  ],
  entryComponents: [CustomizeToolbarComponent, DraftChangesComponent]
})
export class CustomizeToolbarModule {}

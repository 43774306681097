<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top', offsetX: 0, offsetY: -20 },
    { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="iconPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openIconPopover(false)"
  (detach)="openIconPopover(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <app-icon-selector
        [current]="control.controls.icon.value"
        [emptyEnabled]="true"
        (selected)="control.controls.icon.setValue($event); openIconPopover(false)"
      ></app-icon-selector>
    </div>
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-section-menu-item-popover [control]="control" (close)="openEditPopover(false)"></app-section-menu-item-popover>
</ng-template>

<app-add-menu-item-menu
  [layout]="layout"
  (selectMenuItem)="addMenuItem($event)"
  #add_menu_item
></app-add-menu-item-menu>

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openEditPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize item
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    [matMenuTriggerFor]="add_menu_item.menu"
    [matMenuTriggerData]="{ prepend: true }"
  >
    <span class="choose-items-item__dropdown-icon icon-fileds"></span>
    Add item to section
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete section
  </button>
</mat-menu>

<div class="sidebar-container__element-group">
  <div
    class="sidebar-list-item2-group sidebar-list-item2-group_draggable"
    [class.sidebar-list-item2-group_disabled]="iconPopoverOpened || editPopoverOpened"
    #root_trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
  >
    <div class="sidebar-list-item2-group__header">
      <div class="sidebar-list-item2-group__header-left">
        <div class="sidebar-list-item2-group__drag icon-dots_small"></div>
        <div
          class="sidebar-list-item2-group__icon sidebar-list-item2-group__icon_clickable"
          [class.sidebar-list-item2__icon_active]="iconPopoverOpened"
          [appIcon]="control.controls.icon.value ? control.controls.icon.value : 'circle'"
          cdkOverlayOrigin
          (click)="openIconPopover(true)"
          (mousedown)="$event.stopPropagation()"
        ></div>
      </div>
      <div class="sidebar-list-item2-group__header-main">
        <div
          class="sidebar-list-item2-group__title"
          [class.sidebar-list-item2-group__title_editing]="editable_content.editing"
        >
          <app-editable-content
            [value]="control.controls.title.value"
            [emptyValue]="'No title'"
            [outline]="false"
            (valueChange)="onTitleChanged($event)"
            (mousedown)="$event.stopPropagation()"
            #editable_content
          >
          </app-editable-content>
        </div>
      </div>
      <div class="sidebar-list-item2-group__header-right">
        <div
          class="sidebar-list-item2-group__action sidebar-list-item2-group__action_hover icon-more"
          [class.sidebar-list-item2-group__action_active]="item_dropdown_trigger.menuOpen"
          [matMenuTriggerFor]="item_dropdown"
          #item_dropdown_trigger="matMenuTrigger"
          (mousedown)="$event.stopPropagation()"
        ></div>
      </div>
    </div>
    <div class="sidebar-list-item2-group__children">
      <div
        appDropList
        [appDropListData]="control.controls.children"
        [appDropListLockAxis]="dragAxis.Y"
        [appDropListEnterPredicate]="isDroppable"
        [appDropListAreaMarginForward]="[-45, 0, 0, 0]"
        [appDropListAreaMarginBackward]="[0, 0, -15, 0]"
        (appDropListDropped)="dragDrop($event)"
      >
        <div
          *ngFor="let item of control.controls.children.controls; let i = index; trackBy: trackMenuItemFn"
          appDrag
          [appDragData]="item"
        >
          <app-auto-menu-item-edit
            [control]="item"
            [layout]="layout"
            [createdOptions]="item === createdMenuItemControl ? createdMenuItemControlOptions : undefined"
            (deleteRequested)="control.controls.children.removeAt(i)"
          ></app-auto-menu-item-edit>
        </div>
      </div>
    </div>
  </div>
</div>

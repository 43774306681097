import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RoutingService } from '@modules/routing';

@Injectable({
  providedIn: 'root'
})
export class LicenseCheckInterceptor implements HttpInterceptor {
  constructor(private routing: RoutingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status == 400 &&
          error.error &&
          error.error['license_invalid']
        ) {
          const queryParams = {
            error: error.error['license_invalid']['error'],
            error_description: error.error['license_invalid']['error_description']
          };
          this.routing.navigate(['/license-not-valid'], { queryParams: queryParams, skipLocationChange: true });
        }

        return throwError(error);
      })
    );
  }
}

import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ascComparator } from '@shared';

import { CustomizeToolbarBottomService } from '../../services/customize-toolbar-bottom/customize-toolbar-bottom.service';

@Component({
  selector: 'app-customize-toolbar-bottom-container',
  templateUrl: './customize-toolbar-bottom-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeToolbarBottomContainerComponent implements OnInit, OnDestroy {
  templatePortals: TemplatePortal<unknown>[] = [];

  constructor(
    private customizeToolbarBottomService: CustomizeToolbarBottomService,
    private vcr: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeToolbarBottomService.templates$.pipe(untilDestroyed(this)).subscribe(value => {
      this.templatePortals = value
        .sort((lhs, rhs) => ascComparator(lhs.options.order, rhs.options.order))
        .map(item => new TemplatePortal(item.template, this.vcr));
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}

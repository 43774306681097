import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, delayWhen, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { getLanguages } from '@common/localize';
import { timezones } from '@core';
import { Domain, DomainService, DomainStore, EmailConfig, SignUpLayout } from '@modules/domain';
import { Option } from '@modules/field-components';
import { defaultNumberFractionSeparator, defaultNumberThousandsSeparator } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { defaultFontName } from '@modules/theme';
import { controlValue, isColorHex, isSet, setControlDisabled, setControlEnabled, stripStart } from '@shared';

@Injectable()
export class ProjectDomainUpdateForm extends FormGroup implements OnDestroy {
  domain$ = new BehaviorSubject<Domain>(undefined);
  uploaderResourceName = 'demo_rest_api';
  uploaderResourceStorageName = 'temporary';

  languageOptions: Option[] = [];
  languageDefaultOption: Option = {
    value: '',
    name: 'User local language'
  };
  timezoneOptions: Option[] = [];
  timezoneDefaultOption: Option = {
    value: '',
    name: 'User local timezone'
  };

  controls: {
    disable_email_sign_in: FormControl;
    disable_email_sign_up: FormControl;
    disable_google_sign_in: FormControl;
    disable_facebook_sign_in: FormControl;
    sign_up_layout: FormControl;
    sign_up_background_color: FormControl;
    sign_up_background_color_custom_enabled: FormControl;
    sign_up_background_color_custom: FormControl;
    sign_up_accent_color: FormControl;
    sign_up_accent_color_custom_enabled: FormControl;
    sign_up_accent_color_custom: FormControl;
    sign_up_font_regular: FormControl;
    sign_up_font_heading: FormControl;
    sign_up_background_image: FormControl;
    sign_up_title: FormControl;
    sign_up_description: FormControl;
    sign_in_title: FormControl;
    sign_in_description: FormControl;
    default_project: FormControl;
    whiteLabel: FormControl;
    name: FormControl;
    title: FormControl;
    description: FormControl;
    logo: FormControl;
    logo_color: FormControl;
    logo_color_custom_enabled: FormControl;
    logo_color_custom: FormControl;
    icon: FormControl;
    image: FormControl;
    privacy_policy: FormControl;
    terms_of_service: FormControl;
    language: FormControl;
    timezone: FormControl;
    number_fraction_separator: FormControl;
    number_thousands_separator: FormControl;
    append_scripts: FormControl;
    append_styles: FormControl;
    // email_config_enabled: FormControl;
    // email_config_host: FormControl;
    // email_config_port: FormControl;
    // email_config_username: FormControl;
    // email_config_password: FormControl;
    // email_config_from: FormControl;
  };

  numberFractionSeparatorOptions = [
    {
      value: '.',
      name: 'Period (100.15)'
    },
    {
      value: ',',
      name: 'Comma (100,15)'
    }
  ];

  numberThousandsSeparatorOptions = [
    {
      value: '',
      name: 'None (100000000)'
    },
    {
      value: ',',
      name: 'Comma (100,000,000)'
    },
    {
      value: '.',
      name: 'Period (100.000.000)'
    },
    {
      value: ' ',
      name: 'Space (100 000 000)'
    },
    {
      value: "'",
      name: "Apostrophe (100'000'000)"
    }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private domainStore: DomainStore,
    private formUtils: FormUtils,
    private domainService: DomainService
  ) {
    super({
      disable_email_sign_in: new FormControl(false),
      disable_email_sign_up: new FormControl(false),
      disable_google_sign_in: new FormControl(false),
      disable_facebook_sign_in: new FormControl(false),
      sign_up_layout: new FormControl(SignUpLayout.Form),
      sign_up_background_color: new FormControl(''),
      sign_up_background_color_custom_enabled: new FormControl(false),
      sign_up_background_color_custom: new FormControl('#2B50ED'),
      sign_up_accent_color: new FormControl(''),
      sign_up_accent_color_custom_enabled: new FormControl(false),
      sign_up_accent_color_custom: new FormControl('#2B50ED'),
      sign_up_font_regular: new FormControl(defaultFontName),
      sign_up_font_heading: new FormControl(defaultFontName),
      sign_up_background_image: new FormControl(''),
      sign_up_title: new FormControl(''),
      sign_up_description: new FormControl(''),
      sign_in_title: new FormControl(''),
      sign_in_description: new FormControl(''),
      default_project: new FormControl(false),
      whiteLabel: new FormControl(false),
      name: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      logo: new FormControl(''),
      logo_color: new FormControl(''),
      logo_color_custom_enabled: new FormControl(false),
      logo_color_custom: new FormControl('#2B50ED'),
      icon: new FormControl(''),
      image: new FormControl(''),
      privacy_policy: new FormControl(''),
      terms_of_service: new FormControl(''),
      language: new FormControl(''),
      timezone: new FormControl(''),
      number_fraction_separator: new FormControl(defaultNumberFractionSeparator),
      number_thousands_separator: new FormControl(defaultNumberThousandsSeparator),
      append_scripts: new FormControl(''),
      append_styles: new FormControl('')
      // email_config_enabled: new FormControl(false),
      // email_config_host: new FormControl(''),
      // email_config_port: new FormControl(''),
      // email_config_username: new FormControl(''),
      // email_config_password: new FormControl(''),
      // email_config_from: new FormControl('')
    });

    this.getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(instance => {
        setControlEnabled(this, !!instance);

        setControlEnabled(this.controls.name, instance && instance.whiteLabel);
        setControlEnabled(this.controls.logo, instance && instance.whiteLabel);
        setControlEnabled(this.controls.logo_color, instance && instance.whiteLabel);
        setControlEnabled(this.controls.icon, instance && instance.whiteLabel);
      });

    merge(
      this.controls.number_fraction_separator.valueChanges.pipe(map(() => this.controls.number_fraction_separator)),
      this.controls.number_thousands_separator.valueChanges.pipe(map(() => this.controls.number_thousands_separator))
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(control => {
        const anotherControl = [this.controls.number_fraction_separator, this.controls.number_thousands_separator].find(
          item => item !== control
        );

        if (control.value == ',' && anotherControl.value == control.value) {
          anotherControl.patchValue('.');
        } else if (control.value == '.' && anotherControl.value == control.value) {
          anotherControl.patchValue(',');
        }
      });
  }

  ngOnDestroy() {}

  initDomain(domain: Domain) {
    this.domain$.next(domain);
  }

  init(domain: Domain) {
    this.languageOptions = [this.languageDefaultOption, ...getLanguages()];
    this.timezoneOptions = [
      this.timezoneDefaultOption,
      ...timezones.map(item => {
        return {
          value: item.name,
          name: `(GMT${item.gmt}) ${item.name}`,
          data: {
            name: item.name,
            tz: item.tz
          }
        };
      })
    ];

    const domainToggled = (!this.domain$.value && domain) || (this.domain$.value && !domain);

    this.initDomain(domain);

    if (domainToggled && domain) {
      this.controls.disable_email_sign_in.patchValue(domain.disableEmailSignIn);
      this.controls.disable_email_sign_up.patchValue(domain.disableEmailSignUp);
      this.controls.disable_google_sign_in.patchValue(domain.disableGoogleSignIn);
      this.controls.disable_facebook_sign_in.patchValue(domain.disableFacebookSignIn);

      this.controls.sign_up_layout.patchValue(domain.signUpLayout);

      if (isColorHex(domain.signUpBackgroundColor)) {
        this.controls.sign_up_background_color.patchValue('');
        this.controls.sign_up_background_color_custom_enabled.patchValue(true);
        this.controls.sign_up_background_color_custom.patchValue(domain.signUpBackgroundColor);
      } else {
        this.controls.sign_up_background_color.patchValue(domain.signUpBackgroundColor);
        this.controls.sign_up_background_color_custom_enabled.patchValue(false);
        this.controls.sign_up_background_color_custom.patchValue('#2B50ED');
      }

      if (isColorHex(domain.signUpAccentColor)) {
        this.controls.sign_up_accent_color.patchValue('');
        this.controls.sign_up_accent_color_custom_enabled.patchValue(true);
        this.controls.sign_up_accent_color_custom.patchValue(domain.signUpAccentColor);
      } else {
        this.controls.sign_up_accent_color.patchValue(domain.signUpAccentColor);
        this.controls.sign_up_accent_color_custom_enabled.patchValue(false);
        this.controls.sign_up_accent_color_custom.patchValue('#2B50ED');
      }

      if (isSet(domain.signUpFontRegular)) {
        this.controls.sign_up_font_regular.patchValue(domain.signUpFontRegular);
      }

      if (isSet(domain.signUpFontHeading)) {
        this.controls.sign_up_font_heading.patchValue(domain.signUpFontHeading);
      }

      this.controls.sign_up_background_image.patchValue(domain.signUpBackgroundImage);
      this.controls.sign_up_title.patchValue(domain.signUpTitle);
      this.controls.sign_up_description.patchValue(domain.signUpDescription);
      this.controls.sign_in_title.patchValue(domain.signInTitle);
      this.controls.sign_in_description.patchValue(domain.signInDescription);
      this.controls.default_project.patchValue(domain.defaultProject == this.currentProjectStore.instance.uniqueName);
      this.controls.whiteLabel.patchValue(domain.whiteLabel);
      this.controls.name.patchValue(domain.name);
      this.controls.title.patchValue(domain.title);
      this.controls.description.patchValue(domain.description);
      this.controls.logo.patchValue(domain.logo);

      if (isColorHex(domain.logoColor)) {
        this.controls.logo_color.patchValue('');
        this.controls.logo_color_custom_enabled.patchValue(true);
        this.controls.logo_color_custom.patchValue(domain.logoColor);
      } else {
        this.controls.logo_color.patchValue(domain.logoColor);
        this.controls.logo_color_custom_enabled.patchValue(false);
        this.controls.logo_color_custom.patchValue('#2B50ED');
      }

      this.controls.icon.patchValue(domain.icon);
      this.controls.image.patchValue(domain.image);
      this.controls.privacy_policy.patchValue(domain.privacyPolicy);
      this.controls.terms_of_service.patchValue(domain.termsOfService);
      this.controls.language.patchValue(domain.language);
      this.controls.timezone.patchValue(domain.timezone);
      this.controls.number_fraction_separator.setValue(
        isSet(domain.numberFractionSeparator) ? domain.numberFractionSeparator : defaultNumberFractionSeparator
      );
      this.controls.number_thousands_separator.setValue(
        isSet(domain.numberThousandsSeparator, true) ? domain.numberThousandsSeparator : defaultNumberThousandsSeparator
      );
      this.controls.append_scripts.patchValue(domain.appendScripts);
      this.controls.append_styles.patchValue(domain.appendStyles);

      // if (domain.emailConfig) {
      //   this.controls.email_config_enabled.patchValue(true);
      //   this.controls.email_config_host.patchValue(domain.emailConfig.host);
      //   this.controls.email_config_port.patchValue(domain.emailConfig.port);
      //   this.controls.email_config_username.patchValue(domain.emailConfig.username);
      //   this.controls.email_config_password.patchValue(domain.emailConfig.password);
      //   this.controls.email_config_from.patchValue(domain.emailConfig.from);
      // } else {
      //   this.controls.email_config_enabled.patchValue(false);
      // }
    }
  }

  getUploaderResource() {
    return this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.uploaderResourceName);
  }

  getUploaderResourceStorage() {
    const result = this.getUploaderResource();
    if (!result) {
      return;
    }
    return result.storages.find(item => item.uniqueName == this.uploaderResourceStorageName);
  }

  serializeMediaUrl(url: string) {
    if (!isSet(url)) {
      return url;
    }

    const urlInfo = new URL(url);
    const mediaPath = '/media/';

    if (urlInfo.pathname.startsWith(mediaPath)) {
      return stripStart(stripStart(url, urlInfo.origin), mediaPath);
    } else {
      return url;
    }
  }

  getLogoColor(): string {
    if (this.controls.logo_color_custom_enabled.value) {
      return this.controls.logo_color_custom.value;
    } else {
      return this.controls.logo_color.value;
    }
  }

  getSignUpBackgroundColor(): string {
    if (this.controls.sign_up_background_color_custom_enabled.value) {
      return this.controls.sign_up_background_color_custom.value;
    } else {
      return this.controls.sign_up_background_color.value;
    }
  }

  getSignUpAccentColor(): string {
    if (this.controls.sign_up_accent_color_custom_enabled.value) {
      return this.controls.sign_up_accent_color_custom.value;
    } else {
      return this.controls.sign_up_accent_color.value;
    }
  }

  getInstance(): Domain {
    if (!this.domain$.value) {
      return;
    }

    const instance = cloneDeep(this.domain$.value) as Domain;

    if (this.controls.default_project.value) {
      instance.makeDefaultProject = this.controls.default_project.value;
    }

    instance.disableEmailSignIn = this.controls.disable_email_sign_in.value;
    instance.disableEmailSignUp = this.controls.disable_email_sign_up.value;
    instance.disableGoogleSignIn = this.controls.disable_google_sign_in.value;
    instance.disableFacebookSignIn = this.controls.disable_facebook_sign_in.value;

    instance.signUpLayout = this.controls.sign_up_layout.value;
    instance.signUpBackgroundColor = this.getSignUpBackgroundColor();
    instance.signUpAccentColor = this.getSignUpAccentColor();

    if (this.controls.sign_up_font_regular.value != defaultFontName) {
      instance.signUpFontRegular = this.controls.sign_up_font_regular.value;
    } else {
      instance.signUpFontRegular = undefined;
    }

    if (this.controls.sign_up_font_heading.value != defaultFontName) {
      instance.signUpFontHeading = this.controls.sign_up_font_heading.value;
    } else {
      instance.signUpFontHeading = undefined;
    }

    instance.signUpBackgroundImage = this.controls.sign_up_background_image.value;
    instance.signUpTitle = this.controls.sign_up_title.value;
    instance.signUpDescription = this.controls.sign_up_description.value;
    instance.signInTitle = this.controls.sign_in_title.value;
    instance.signInDescription = this.controls.sign_in_description.value;

    instance.whiteLabel = this.controls.whiteLabel.value;
    instance.name = this.controls.name.value;
    instance.title = this.controls.title.value;
    instance.description = this.controls.description.value;
    instance.logo = this.controls.logo.value;
    instance.logoColor = this.getLogoColor();
    instance.icon = this.controls.icon.value;
    instance.image = this.controls.image.value;
    instance.privacyPolicy = this.controls.privacy_policy.value;
    instance.termsOfService = this.controls.terms_of_service.value;
    instance.language = this.controls.language.value;
    instance.timezone = this.controls.timezone.value;
    instance.numberFractionSeparator = this.controls.number_fraction_separator.value;
    instance.numberThousandsSeparator = this.controls.number_thousands_separator.value;
    instance.appendScripts = isSet(this.controls.append_scripts.value) ? this.controls.append_scripts.value : undefined;
    instance.appendStyles = isSet(this.controls.append_styles.value) ? this.controls.append_styles.value : undefined;

    // if (this.controls.email_config_enabled.value) {
    //   instance.emailConfig = new EmailConfig();
    //   instance.emailConfig.host = this.controls.email_config_host.value;
    //   instance.emailConfig.port = this.controls.email_config_port.value;
    //   instance.emailConfig.username = this.controls.email_config_username.value;
    //   instance.emailConfig.password = this.controls.email_config_password.value;
    //   instance.emailConfig.from = this.controls.email_config_from.value;
    // } else {
    //   instance.emailConfig = null;
    // }

    return instance;
  }

  getInstance$(): Observable<Domain> {
    return combineLatest(this.domain$, controlValue(this)).pipe(map(() => this.getInstance()));
  }

  submit(): Observable<Domain> {
    if (this.disabled) {
      return of(this.domain$.value);
    }

    const instance = this.getInstance();

    if (!instance) {
      return of(undefined);
    }

    instance.logo = this.serializeMediaUrl(instance.logo);
    instance.icon = this.serializeMediaUrl(instance.icon);
    instance.image = this.serializeMediaUrl(instance.image);

    return this.domainService
      .update(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, instance, [
        'make_default_project',
        'white_label',
        'name',
        'title',
        'description',
        'logo',
        'color',
        'icon',
        'image',
        'privacy_policy',
        'terms_of_service',
        'language',
        'timezone',
        'params'
      ])
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        delayWhen(() => {
          this.domainStore.reset();
          return this.domainStore.getFirst(true);
        }),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource, ResourceName, resourceTypeItems } from '@modules/projects';
import { PageTemplate, PageTemplateType, PageVariantType } from '@modules/template-generators';
import { objectsSortPredicate } from '@shared';

interface ModelItem {
  modelDescription: ModelDescription;
  disabled?: boolean;
  error?: string;
}

interface ResourceItem {
  resource: Resource;
  models: ModelItem[];
  disabled?: boolean;
  error?: string;
}

@Component({
  selector: 'app-page-templates-choose-item-model',
  templateUrl: './page-templates-choose-item-model.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesChooseItemModelComponent implements OnInit, OnDestroy {
  @Input() template: PageTemplate;
  @Input() templateVariant: PageVariantType;
  @Input() resource: Resource;
  @Input() analyticsSource: string;
  @Output() selectModel = new EventEmitter<ModelDescription>();
  @Output() backClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  loading = false;
  modelDescriptions: ModelDescription[] = [];
  resources: ResourceItem[] = [];
  selectedResource: ResourceItem;
  selectedModel: ModelItem;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initResources();
  }

  ngOnDestroy(): void {}

  initResources() {
    this.loading = true;
    this.cd.markForCheck();

    combineLatest(this.currentEnvironmentStore.resources$, this.modelDescriptionStore.get())
      .pipe(untilDestroyed(this))
      .subscribe(
        ([resources, modelDescriptions]) => {
          this.resources = resources
            .filter(item => !item.typeItem.hidden && !item.demo)
            .map(resource => {
              const models = this.getResourceModels(modelDescriptions, resource);
              let disabled = false;
              let error: string;

              if (!models.length) {
                disabled = true;
                error = 'No collections';
              } else if (this.template.type == PageTemplateType.Dashboard && !this.isResourceChartsEnabled(resource)) {
                disabled = true;
                error = 'Charts not supported';
              }

              return {
                resource: resource,
                models: models,
                disabled: disabled,
                error: error
              };
            })
            .sort(objectsSortPredicate('disabled', 'resource.name'));

          this.modelDescriptions = modelDescriptions;
          this.loading = false;
          this.cd.markForCheck();

          if (this.resource) {
            this.setSelectedResource({
              resource: this.resource,
              models: this.getResourceModels(modelDescriptions, this.resource)
            });
          }
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  isResourceChartsEnabled(resource: Resource): boolean {
    let typeItem = resource.typeItem;

    if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
      typeItem = resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL);
    }

    return typeItem.chartsEnabled;
  }

  isResourceModelChartsEnabled(resource: Resource, modelDescription: ModelDescription): boolean {
    let typeItem = resource.typeItem;

    if (resource.isSynced(modelDescription.model) || modelDescription.isSynced()) {
      typeItem = resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL);
    }

    return typeItem.chartsEnabled;
  }

  getResourceModels(modelDescriptions: ModelDescription[], resource: Resource) {
    return modelDescriptions
      .filter(item => item.resource == resource.uniqueName && !item.hidden)
      .map(item => {
        let disabled = false;
        let error: string;

        if (!item.getQuery) {
          disabled = true;
          error = 'Get query not configured';
        } else if (!item.dbFields.length) {
          disabled = true;
          error = 'Not fields';
        } else if (this.template.type == PageTemplateType.AdminPanelEdit && !item.createQuery && !item.updateQuery) {
          disabled = true;
          error = 'Edit queries not configured';
        } else if (
          this.template.type == PageTemplateType.Dashboard &&
          !this.isResourceModelChartsEnabled(resource, item)
        ) {
          disabled = true;
          error = 'Charts not supported';
        }

        return {
          modelDescription: item,
          disabled: disabled,
          error: error
        };
      })
      .sort(objectsSortPredicate('disabled', 'modelDescription.verboseNamePlural'));
  }

  setSelectedResource(resource: ResourceItem) {
    this.selectedResource = resource;
    this.cd.markForCheck();

    if (this.selectedResource) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.ResourceSelected, {
        Template: this.template ? this.template.type : undefined,
        TemplateVariant: this.templateVariant,
        ResourceType: this.selectedResource.resource.typeItem.name,
        Resource: this.selectedResource.resource.uniqueName,
        Source: this.analyticsSource
      });

      if (this.selectedResource.models.length == 1 && !this.selectedResource.models[0].disabled) {
        this.setSelectedModel(this.selectedResource.models[0]);
        this.submit();
      }
    }
  }

  setSelectedModel(item: ModelItem) {
    this.selectedModel = item;
    this.cd.markForCheck();
  }

  submit() {
    this.selectModel.emit(this.selectedModel.modelDescription);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import { applyParamInput$, createFormFieldFactory, FieldType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, TypedChanges } from '@shared';

import { AccordionItemControl } from '../accordion-item.control';

@Component({
  selector: 'app-customize-bar-accordion-edit-item',
  templateUrl: './customize-bar-accordion-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAccordionEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: AccordionItemControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() openedInitial = false;
  @Input() removeEnabled = false;
  @Input() analyticsSource: string;
  @Output() remove = new EventEmitter<void>();

  createField = createFormFieldFactory();
  title: string;
  titleSubscription: Subscription;
  fieldTypes = FieldType;
  openedOptions: Option[] = [
    {
      value: false,
      name: 'Closed by default'
    },
    {
      value: true,
      name: 'Opened by default'
    }
  ];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomizeBarAccordionEditItemComponent>): void {
    if (changes.control || changes.context) {
      this.initTitle();
    }
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    this.titleSubscription = controlValue(this.control.controls.title)
      .pipe(
        map(() => this.control.controls.title.serialize()),
        switchMap(titleInput => {
          if (!titleInput) {
            return of(undefined);
          }

          return applyParamInput$<string>(titleInput, {
            context: this.context,
            contextElement: this.contextElement,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { MenuSettings } from '../../data/menu-settings';

@Injectable()
export class MenuSettingsService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, draft = false): Observable<MenuSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'menu_settings/');
        let headers = new HttpHeaders();
        const params = {
          ...(draft && { draft: '1' })
        };

        params['project'] = projectName;

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: params });
      }),
      map(result => {
        if (!result.length) {
          return;
        }

        const instance = new MenuSettings().deserialize(result[0]);
        instance.project = projectName;
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: MenuSettings): Observable<MenuSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'menu_settings/');
        let headers = new HttpHeaders();
        const params = {
          project: projectName,
          draft: '1'
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, instance.serialize(), { headers: headers, params: params });
      }),
      map(result => {
        const newInstance = new MenuSettings().deserialize(result);
        newInstance.project = projectName;
        return newInstance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponentArguments } from '@common/dynamic-component';

import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { ChartType, getWidgetByType, Widget, WidgetService, WidgetType } from '@modules/dashboard';
import { CurrentProjectStore } from '@modules/projects';
import { scrollTo } from '@shared';

enum CustomizeBarItemType {
  Heading,
  Item,
  Button,
  Tile
}

@Component({
  selector: 'app-dashboard-customize-bar',
  templateUrl: './dashboard-customize-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardCustomizeBarComponent implements OnInit, OnDestroy {
  @Input() dashboard;
  @Input() widgetPosition: { x: number; y: number };
  @Output() widgetAdded = new EventEmitter<Widget>();
  @ViewChild('root') root: ElementRef;

  settingsComponents: DynamicComponentArguments[] = [];
  itemTypes = CustomizeBarItemType;
  items = [
    {
      context: {
        heading: 'Line Chart',
        icon: 'line-chart'
      },
      data: {
        widget_type: WidgetType.Chart,
        name: 'Line Chart',
        width: 4,
        height: 2,
        params: JSON.stringify({
          chart_type: ChartType.Line
        })
      }
    },
    {
      context: {
        heading: 'Bar Chart',
        icon: 'bar-chart'
      },
      data: {
        widget_type: WidgetType.Chart,
        name: 'Bar Chart',
        width: 4,
        height: 2,
        params: JSON.stringify({
          chart_type: ChartType.Bar
        })
      }
    },
    {
      context: {
        heading: 'Pie Chart',
        icon: 'pie-chart'
      },
      data: {
        widget_type: WidgetType.Chart,
        name: 'Pie Chart',
        width: 2,
        height: 2,
        params: JSON.stringify({
          chart_type: ChartType.Pie
        })
      }
    },
    {
      context: {
        heading: 'Doughnut Chart',
        icon: 'doughnut-chart'
      },
      data: {
        widget_type: WidgetType.Chart,
        name: 'Doughnut Chart',
        width: 2,
        height: 2,
        params: JSON.stringify({
          chart_type: ChartType.Doughnut
        })
      }
    },
    {
      context: {
        heading: 'Counter',
        icon: 'counter'
      },
      data: {
        widget_type: WidgetType.Value,
        name: 'Counter',
        width: 2,
        height: 2,
        params: JSON.stringify({})
      }
    }
  ];

  constructor(
    public customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    private widgetService: WidgetService,
    private cd: ChangeDetectorRef,
    private customizeBarContext: CustomizeBarContext
  ) {}

  ngOnInit() {
    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.detectChanges();
      this.scrollToTop();
    });
  }

  ngOnDestroy(): void {}

  scrollToTop() {
    scrollTo(this.root.nativeElement, 0, this.root.nativeElement.scrollTop > 0 ? 0.4 : 0);
  }

  add(item) {
    const data = {
      ...item.data,
      x: this.widgetPosition.x,
      y: this.widgetPosition.y,
      dashboard: this.dashboard.id
    };

    let instance: Widget;
    const element = getWidgetByType(data['widget_type']);

    if (element) {
      instance = new element().deserialize(data);
    } else {
      instance = new Widget().deserialize(data);
    }

    this.widgetAdded.emit(instance);
  }
}

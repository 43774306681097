<div class="dashboard-item">
  <div class="dashboard-item__resize-placeholder" [class.dashboard-item__resize-placeholder_visible]="resizing"></div>
  <div class="dashboard-item__content" [class.dashboard-item__content_disabled]="resizing" #root>
    <div class="element" [class.element_active]="active" (mouseenter)="onHover(true)" (mouseleave)="onHover(false)">
      <span class="element__squares element__squares_top"></span>
      <span class="element__squares element__squares_bottom"></span>
      <div class="element__toolbar">
        <div class="customize-selector dashboard-item__draggable">
          <div class="customize-selector__inner">
            <span class="customize-selector__label handle">
              <span class="customize-selector__drag icon-dots_small"></span>
              {{ widget.typeStr }}
            </span>
            <div class="customize-selector__controls">
              <a href="javascript:void(0)" class="customize-selector__control" tabindex="-1" (click)="editWidget()">
                <span class="customize-selector__icon icon-gear"></span>
              </a>
              <a
                href="javascript:void(0)"
                class="customize-selector__control"
                tabindex="-1"
                (click)="copyRequested.emit()"
              >
                <span class="customize-selector__icon icon-copy"></span>
              </a>

              <div
                *ngIf="(dashboardStore.instance$ | async)?.length > 1"
                href="javascript:void(0)"
                class="customize-selector__control dropdown-anchor dropdown-anchor_fill"
              >
                <span class="customize-selector__icon icon-redo"></span>

                <div class="dropdown dropdown_position_bottom-left">
                  <div class="dropdown__inner">
                    <ng-container *ngFor="let item of dashboardStore.instance$ | async">
                      <a
                        *ngIf="item.id != dashboard.id"
                        href="javascript:void(0)"
                        class="dropdown__item"
                        (click)="moveRequested.emit(item)"
                      >
                        <span class="dropdown__item-label">{{ item.name }}</span>
                      </a>
                      <span *ngIf="item.id == dashboard.id" class="dropdown__item dropdown__item_disabled">
                        <span class="dropdown__item-label">{{ item.name }}</span>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <span
                *ngIf="(dashboardStore.instance$ | async)?.length <= 1"
                class="customize-selector__control customize-selector__control_disabled"
              >
                <span class="customize-selector__icon icon-redo"></span>
              </span>

              <a
                href="javascript:void(0)"
                class="customize-selector__control"
                tabindex="-1"
                (click)="deleteRequested.emit()"
              >
                <span class="customize-selector__icon icon-bin"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <app-auto-widget [dashboard]="dashboard" [widget]="widget" [context]="context"> </app-auto-widget>
      <span class="dashboard-item__resize-handle" #handle></span>
    </div>
  </div>
</div>

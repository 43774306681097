<div class="container-stub__item container-stub__item_card">
  <div class="container-stub-placeholder">
    <div class="container-stub-placeholder__image container-stub-placeholder__image_type_drag-n-drop"></div>
    <div class="container-stub-placeholder__title">Start creating your Page</div>
    <div class="container-stub-placeholder__description">
      Drag-and-drop different components and connect resources to build an App
    </div>
    <div class="container-stub-placeholder__actions">
      <button type="button" class="button button_primary button_orange-alternative" (click)="customizeClick.emit()">
        Customize Page
      </button>
    </div>
  </div>
</div>

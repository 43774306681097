import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { TaskQueue, TaskQueueService, TaskQueueStore, TaskStatus, TaskStatusCategory } from '@modules/collaboration';
import { Option } from '@modules/field-components';
import { ParameterArray } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class TaskQueueEditPopupForm {
  queue: TaskQueue;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    statuses: new FormArray([], Validators.required),
    parameters: new ParameterArray([])
  });
  categoryOptions: Option<TaskStatusCategory>[] = [
    {
      name: 'New',
      value: TaskStatusCategory.New
    },
    {
      name: 'In Progress',
      value: TaskStatusCategory.InProgress
    },
    {
      name: 'Completed',
      value: TaskStatusCategory.Completed
    }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private taskQueueService: TaskQueueService,
    private taskQueueStore: TaskQueueStore,
    private formUtils: FormUtils
  ) {}

  init(queue?: TaskQueue) {
    this.queue = queue;

    if (queue) {
      this.form.patchValue({
        name: queue.name,
        parameters: queue.parameters
      });
      this.arraySet(
        'statuses',
        queue.statuses.map(item => this.createStatus(item))
      );
    } else {
      this.createBaseStatuses();
    }
  }

  createBaseStatuses(): void {
    const baseStatuses = this.categoryOptions.map(status => {
      return new TaskStatus().deserialize({
        name: status.name,
        category: status.value
      });
    });

    this.arraySet(
      'statuses',
      baseStatuses.map(item => this.createStatus(item))
    );
  }

  createStatus(value?: TaskStatus): FormGroup {
    const form = new FormGroup({
      uid: new FormControl(null),
      name: new FormControl('', Validators.required),
      category: new FormControl(TaskStatusCategory.InProgress, Validators.required)
      // color: new FormControl(''),
    });

    if (value) {
      form.patchValue({
        uid: value.uid,
        name: value.name,
        category: value.category
      });
    }

    return form;
  }

  appendStatus(value?: TaskStatus) {
    this.arrayAppend('statuses', this.createStatus());
  }

  removeStatus(item: FormGroup) {
    this.arrayRemove('statuses', item);
  }

  array(name: string): FormArray {
    return this.form.controls[name] as FormArray;
  }

  arraySet(name: string, controls: FormGroup[]) {
    const array = this.array(name);
    range(array.controls.length).forEach(() => array.removeAt(0));
    controls.forEach(item => array.push(item));
    array.updateValueAndValidity();
  }

  arrayAppend(name: string, control: FormGroup) {
    const array = this.array(name);
    array.push(control);
    array.updateValueAndValidity();
  }

  arrayRemove(name: string, control: FormGroup) {
    const array = this.array(name);
    const index = array.controls.findIndex(item => item === control);
    array.removeAt(index);
    array.updateValueAndValidity();
  }

  submit(): Observable<TaskQueue> {
    const instance = this.queue ? cloneDeep(this.queue) : new TaskQueue();
    const value = this.form.value;
    let obs: Observable<TaskQueue>;

    instance.name = value['name'];
    instance.parameters = value['parameters'];

    instance.statuses = value['statuses'].map(item => {
      const status = new TaskStatus();
      status.uid = item['uid'];
      status.name = item['name'];
      status.category = item['category'];
      return status;
    });

    if (this.queue) {
      obs = this.taskQueueService.update(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    } else {
      obs = this.taskQueueService.create(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        instance
      );
    }

    return obs.pipe(
      delayWhen(() => this.taskQueueStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this.form, error);
        return throwError(error);
      })
    );
  }
}

import { LatLngBoundsLiteral } from '@agm/core';
import { LatLngLiteral } from '@agm/core/services/google-maps-types';

export function getBoundsZoomLevel(bounds: LatLngBoundsLiteral, width: number, height: number): number {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const latFraction = (latRad(bounds.north) - latRad(bounds.south)) / Math.PI;

  const lngDiff = bounds.east - bounds.west;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export function coordinatesCenter(coords: LatLngLiteral[]) {
  const sum = coords.reduce((prev, item) => [item.lat + prev[0], item.lng + prev[1]], [0, 0]);
  return {
    latitude: sum[0] / coords.length,
    longitude: sum[1] / coords.length
  };
}

export function coordinatesBounds(coords: LatLngLiteral[]): LatLngBoundsLiteral {
  let minLatitude: number;
  let minLongitude: number;
  let maxLatitude: number;
  let maxLongitude: number;

  coords.forEach(item => {
    if (minLatitude == undefined || item.lat < minLatitude) {
      minLatitude = item.lat;
    }

    if (minLongitude == undefined || item.lng < minLongitude) {
      minLongitude = item.lng;
    }

    if (maxLatitude == undefined || item.lat > maxLatitude) {
      maxLatitude = item.lat;
    }

    if (maxLongitude == undefined || item.lng > maxLongitude) {
      maxLongitude = item.lng;
    }
  });

  if (minLatitude == undefined) {
    return;
  }

  return {
    east: maxLongitude,
    north: maxLatitude,
    south: minLatitude,
    west: minLongitude
  };
}

export function containsBounds(outerBounds: LatLngBoundsLiteral, innerBounds: LatLngBoundsLiteral): boolean {
  if (!outerBounds) {
    return false;
  }

  return (
    outerBounds.east >= innerBounds.east &&
    outerBounds.north >= innerBounds.north &&
    outerBounds.south <= innerBounds.south &&
    outerBounds.west <= innerBounds.west
  );
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BooleanFieldStyle } from '@modules/field-components';
import {
  AudioOutputFormat,
  createFormFieldFactory,
  FieldType,
  registerFieldViewParamsComponent
} from '@modules/fields';
import { StorageSelectSource } from '@modules/storages-queries';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { AudioFieldViewParamsForm } from './audio-field-view-params.form';

@Component({
  selector: 'app-audio-field-view-params',
  templateUrl: './audio-field-view-params.component.html',
  providers: [AudioFieldViewParamsForm, StorageSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioFieldViewParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  outputFormats = AudioOutputFormat;

  constructor(public form: AudioFieldViewParamsForm, public storageSelectSource: StorageSelectSource) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, { context: this.context });
  }
}

registerFieldViewParamsComponent(FieldType.Audio, AudioFieldViewParamsComponent);

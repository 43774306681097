import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as Color from 'color';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { colors, parseColor } from '@modules/colors';
import { MapSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, DisplayField } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { ThemeService } from '@modules/theme';
import { EMPTY, isColorHex, numberToHex } from '@shared';

import { MapMarker } from '../map/map.component';

@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapMarkerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() settings: MapSettings;
  @Input() item: MapMarker;
  @Input() modelDescription: ModelDescription;
  @Input() windowOpen = false;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Output() modelUpdated = new EventEmitter<Model>();
  @Output() openWindow = new EventEmitter<void>();
  @Output() closeWindow = new EventEmitter<void>();

  icon: any;

  constructor(
    private themeService: ThemeService,
    private appConfigService: AppConfigService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.themeService.theme$.pipe(untilDestroyed(this)).subscribe(() => this.updateStyles());

    this.context.outputValues$.pipe(debounceTime(10), untilDestroyed(this)).subscribe(() => this.updateStyles());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.updateStyles();
    }
  }

  updateStyles() {
    this.icon = this.getIcon();
    this.cd.markForCheck();
  }

  getColor(): string {
    if (this.settings.markerColorInput) {
      try {
        const value = applyParamInput(this.settings.markerColorInput, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.item.item.model.getAttributes()
          }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    } else {
      return this.settings.markerColor;
    }
  }

  getSize(): number {
    if (this.settings.markerSizeInput) {
      try {
        const value = applyParamInput(this.settings.markerSizeInput, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.item.item.model.getAttributes()
          }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    } else {
      return this.settings.markerSize;
    }
  }

  getIcon(): any {
    const markerColor = this.getColor() || this.accentColor;
    const markerSize = this.getSize();
    const theme = this.themeService.theme;
    const colorHex = isColorHex(markerColor)
      ? markerColor.substring(1)
      : colors.filter(item => item.name == markerColor).map(item => numberToHex(item.hex))[0];
    const clr = colorHex ? parseColor('#' + colorHex) : undefined;
    const isDark = !clr || clr.contrast(Color('white')) >= 2;
    const contrastColor = !clr || isDark ? 'FFF' : clr.darken(0.8).hex().toUpperCase().substring(1);
    const size = markerSize || 26;
    const url = new URL(`${this.appConfigService.serverBaseUrl}/assets/marker.svg`);

    if (colorHex) {
      url.searchParams.append('color', colorHex);
    }

    if (contrastColor) {
      url.searchParams.append('contrast_color', contrastColor);
    }

    if (theme == 'dark') {
      url.searchParams.append('dark', '1');
    }

    if (size) {
      url.searchParams.append('size', size.toString());
    }

    return {
      url: url.toString(),
      anchor: {
        x: size * 0.5,
        y: size * 0.5
      }
    };
  }
}

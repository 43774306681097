var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
var ProjectObjectPermissionsArray = /** @class */ (function (_super) {
    __extends(ProjectObjectPermissionsArray, _super);
    function ProjectObjectPermissionsArray() {
        var _this = _super.call(this, []) || this;
        _this.permissions = [];
        _this._initialized$ = new BehaviorSubject(false);
        return _this;
    }
    Object.defineProperty(ProjectObjectPermissionsArray.prototype, "initialized$", {
        get: function () {
            return this._initialized$.pipe(filter(function (item) { return item; }));
        },
        enumerable: true,
        configurable: true
    });
    ProjectObjectPermissionsArray.prototype.push = function (control) {
        _super.prototype.push.call(this, control);
    };
    ProjectObjectPermissionsArray.prototype.deserialize = function (permissions) {
        this.permissions = permissions;
        this.controls.forEach(function (control) {
            var permission = permissions.find(function (item) { return item.permissionType == control.value.type && item.permissionObject == control.value.object; });
            control.deserialize(permission);
        });
    };
    ProjectObjectPermissionsArray.prototype.serialize = function () {
        return this.controls
            .filter(function (control) { return control.controls.actions.isSelectedAny(); })
            .map(function (control) { return control.serialize(); });
    };
    ProjectObjectPermissionsArray.prototype.isEveryControlAllActionsSelected = function () {
        return this.controls.every(function (control) { return control.controls.actions.isSelectedAll(); });
    };
    ProjectObjectPermissionsArray.prototype.setEveryControlAllActions = function (value) {
        this.controls.forEach(function (control) { return control.controls.actions.setAll(value); });
    };
    ProjectObjectPermissionsArray.prototype.toggleEveryControlAllActions = function () {
        var selectAll = !this.isEveryControlAllActionsSelected();
        this.setEveryControlAllActions(selectAll);
    };
    ProjectObjectPermissionsArray.prototype.isEveryControlActionSelected = function (name) {
        return this.controls.every(function (control) { return control.controls.actions.controls[name].value; });
    };
    ProjectObjectPermissionsArray.prototype.setEveryControlAction = function (name, value) {
        this.controls.forEach(function (control) { return control.controls.actions.controls[name].setValue(value); });
    };
    ProjectObjectPermissionsArray.prototype.toggleEveryControlAction = function (name) {
        var select = !this.isEveryControlActionSelected(name);
        this.setEveryControlAction(name, select);
    };
    return ProjectObjectPermissionsArray;
}(FormArray));
export { ProjectObjectPermissionsArray };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../../fields/components/auto-field/auto-field.component";
import * as i3 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i4 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i5 from "@angular/common";
import * as i6 from "./time-field-view-params.form";
import * as i7 from "@angular/forms";
import * as i8 from "./time-field-view-params.component";
var styles_TimeViewFieldParamsComponent = [];
var RenderType_TimeViewFieldParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeViewFieldParamsComponent, data: {} });
export { RenderType_TimeViewFieldParamsComponent as RenderType_TimeViewFieldParamsComponent };
function View_TimeViewFieldParamsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(2, 2), i0.ɵpod(3, { classes: 0 }), i0.ɵpod(4, { name: 0, field: 1, params: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.form; var currVal_1 = _co.createField(_ck(_v, 4, 0, "time_format_custom", "CharField", _ck(_v, 3, 0, _ck(_v, 2, 0, "input_fill", "input_segment-bottom")))); var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TimeViewFieldParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i3.View_SidebarFieldComponent_0, i3.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i4.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 2), i0.ɵpod(6, { options: 0, classes: 1 }), i0.ɵpod(7, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_TimeViewFieldParamsComponent_1)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "time format"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form.form; var currVal_2 = _co.createField(_ck(_v, 7, 0, "time_format", "SelectField", _ck(_v, 6, 0, _co.form.timeFormatOptions, _ck(_v, 5, 0, "select_fill", ((_co.form.form.value["time_format"] == _co.timeFormats.Custom) ? "select_segment-top" : ""))))); var currVal_3 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.form.form.value["time_format"] == _co.timeFormats.Custom); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_TimeViewFieldParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-time-field-view-params", [], null, null, null, View_TimeViewFieldParamsComponent_0, RenderType_TimeViewFieldParamsComponent)), i0.ɵprd(512, null, i6.TimeFieldViewParamsForm, i6.TimeFieldViewParamsForm, [i7.FormBuilder]), i0.ɵdid(2, 114688, null, 0, i8.TimeViewFieldParamsComponent, [i6.TimeFieldViewParamsForm], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TimeViewFieldParamsComponentNgFactory = i0.ɵccf("app-time-field-view-params", i8.TimeViewFieldParamsComponent, View_TimeViewFieldParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { TimeViewFieldParamsComponentNgFactory as TimeViewFieldParamsComponentNgFactory };

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { isSet } from '@shared';

import { ParameterField } from '../data/parameter-field';
import { ParameterControl } from './parameter.control';

export class ParameterArray extends FormArray {
  controls: ParameterControl[];

  constructor(controls: ParameterControl[] = []) {
    super(controls);
  }

  patchValue(value: ParameterField[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  get value(): ParameterField[] {
    return this.serialize();
  }

  set value(value: ParameterField[]) {}

  deserialize(value: ParameterField[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): ParameterField[] {
    return this.controls.map(control => control.serialize()).filter(item => isSet(item.name));
  }

  setControls(controls: ParameterControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: ParameterControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: ParameterField, value?: Object): ParameterControl {
    const control = new ParameterControl();

    if (item) {
      control.deserialize(item);
    }

    if (value) {
      control.patchValue(value);
      control.markAsPristine();
    }

    return control;
  }

  prependControl(item?: ParameterField, value?: Object): ParameterControl {
    const control = this.createControl(item, value);
    this.insert(0, control);
    return control;
  }

  appendControl(item?: ParameterField, value?: Object): ParameterControl {
    const control = this.createControl(item, value);
    this.push(control);
    return control;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, Injector, OnDestroy, OnInit, QueryList, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subject } from 'rxjs';
import { PopupService } from '@common/popups';
import { messageType } from '@modules/activities';
import { TimelinePopupComponent } from '@modules/activities-components';
import { FeedItemListStore, FeedItemService } from '@modules/collaboration';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getWindowScrollTop } from '@shared';
import { CollaborationMessagesItemComponent } from '../collaboration-messages-item/collaboration-messages-item.component';
var CollaborationMessagesComponent = /** @class */ (function () {
    function CollaborationMessagesComponent(renderer, feedItemService, popupService, activatedRoute, router, feedItemListStore, currentProjectStore, currentEnvironmentStore, injector, cd) {
        this.renderer = renderer;
        this.feedItemService = feedItemService;
        this.popupService = popupService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.feedItemListStore = feedItemListStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.injector = injector;
        this.cd = cd;
        this.selectedItemIndex = undefined;
        this.closeTimelinePopup = new Subject();
        this.feedItem = new QueryList();
        this.form = new FormGroup({
            date_add_gte: new FormControl(),
            date_add_lte: new FormControl(),
            read: new FormControl(null),
            mention: new FormControl(null)
        });
        this.formatDate = 'DD.MM.YYYY';
        this.readOptions = [
            { name: 'All Messages', value: null },
            { name: 'Unread Messages', value: 0 }
        ];
    }
    CollaborationMessagesComponent.prototype.trackByFn = function (index, item) {
        return item.dateAdd;
    };
    CollaborationMessagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.baseParams = {
            order_by: '-date_add',
            user_activity_type_in: "" + messageType.slice().join(',')
        };
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(function (params) {
            var formValue = _this.form.value;
            var patchValue = {};
            ['date_add_gte', 'date_add_lte', 'read', 'mention'].forEach(function (name) {
                if (params[name]) {
                    patchValue[name] = params[name];
                }
                else {
                    patchValue[name] = null;
                }
            });
            if (!isEqual(formValue, patchValue)) {
                _this.form.patchValue(__assign({}, patchValue));
            }
            _this.feedItemListStore.projectName = _this.currentProjectStore.instance.uniqueName;
            _this.feedItemListStore.environmentName = _this.currentEnvironmentStore.instance.uniqueName;
            _this.feedItemListStore.params = __assign({}, _this.baseParams, params);
            _this.feedItemListStore.reset();
            _this.feedItemListStore.getNext();
            _this.cd.markForCheck();
        });
        this.feedItems$ = this.feedItemListStore.items$;
        this.feedItemsLoading$ = this.feedItemListStore.loading$;
    };
    CollaborationMessagesComponent.prototype.ngOnDestroy = function () { };
    CollaborationMessagesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            var params = {};
            ['date_add_gte', 'date_add_lte', 'read', 'mention'].forEach(function (name) {
                if (value[name] === null) {
                    return;
                }
                if (name === 'date_add_gte') {
                    params[name] = moment(value[name]).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
                else if (name === 'date_add_lte') {
                    params[name] = moment(value[name]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
                else if (name === 'read' && value[name] === 0) {
                    params[name] = value[name];
                }
                else {
                    params[name] = value[name];
                }
            });
            _this.router.navigate([], {
                relativeTo: _this.activatedRoute,
                queryParams: params
            });
            _this.selectedItemIndex = undefined;
            _this.timelineBaseParams = undefined;
            _this.closeTimelinePopup.next();
            _this.cd.markForCheck();
        });
        fromEvent(window, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var scrollTop = getWindowScrollTop();
            var viewportHeight = window.innerHeight;
            var viewportBottom = scrollTop + viewportHeight;
            var contentHeight = document.body.offsetHeight;
            if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
                _this.onScrollFinished();
            }
        });
    };
    CollaborationMessagesComponent.prototype.onScrollFinished = function () {
        this.feedItemListStore.getNext();
    };
    CollaborationMessagesComponent.prototype.activeToggle = function (index, item) {
        if (index === this.selectedItemIndex) {
            this.selectedItemIndex = undefined;
            this.timelineBaseParams = undefined;
            this.closeTimelinePopup.next();
            this.cd.markForCheck();
        }
        else {
            this.openTimelinePopup(item);
            this.selectedItemIndex = index;
            this.cd.markForCheck();
        }
    };
    CollaborationMessagesComponent.prototype.openTimelinePopup = function (feedItem) {
        var timelineBaseParams = {};
        if (feedItem.userActivity.objectType) {
            timelineBaseParams['object_type'] = feedItem.userActivity.objectType;
        }
        if (feedItem.userActivity.objectId) {
            timelineBaseParams['object_id'] = feedItem.userActivity.objectId;
        }
        if (!this.timelineBaseParams) {
            this.timelineBaseParams = timelineBaseParams;
            this.createPopup(feedItem, this.timelineBaseParams);
            this.setMarkRead(feedItem, this.timelineBaseParams);
        }
        else if (!isEqual(timelineBaseParams, this.timelineBaseParams)) {
            this.closeTimelinePopup.next();
            this.timelineBaseParams = timelineBaseParams;
            this.createPopup(feedItem, this.timelineBaseParams);
            this.setMarkRead(feedItem, this.timelineBaseParams);
        }
    };
    CollaborationMessagesComponent.prototype.setMarkRead = function (feedItem, selectedItemParams) {
        var _this = this;
        this.feedItemService
            .setMarkRead(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
            user_activity_object_type: feedItem.userActivity.objectType,
            user_activity_object_id: feedItem.userActivity.objectId
        })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.feedItemListStore.setMarkRead(selectedItemParams);
            _this.cd.detectChanges();
        });
    };
    CollaborationMessagesComponent.prototype.readAll = function () {
        this.feedItemListStore.setMarkRead();
        this.cd.detectChanges();
    };
    CollaborationMessagesComponent.prototype.createPopup = function (feedItem, params) {
        var _this = this;
        this.timelinePopup = this.popupService.push({
            component: TimelinePopupComponent,
            disablePointerEvents: true,
            enableWindowScroll: true,
            inputs: {
                baseParams: params,
                closeObs: this.closeTimelinePopup
            },
            outputs: {
                closeObsEvent: [
                    function () {
                        _this.selectedItemIndex = undefined;
                        _this.timelineBaseParams = undefined;
                        _this.cd.markForCheck();
                    }
                ]
            },
            injector: this.injector
        });
    };
    return CollaborationMessagesComponent;
}());
export { CollaborationMessagesComponent };

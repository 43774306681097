var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge } from 'rxjs';
import { BasePopupComponent } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { controlValue } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { SmartSuiteResourceSettingsForm } from './smart-suite-resource-settings.form';
import { SmartSuiteSolutionSource } from './smart-suite-solution-source';
var SmartSuiteResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(SmartSuiteResourceSettingsComponent, _super);
    function SmartSuiteResourceSettingsComponent(mode, solutionSource, chooseSyncController, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.mode = mode;
        _this.solutionSource = solutionSource;
        _this.chooseSyncController = chooseSyncController;
        _this.form = form;
        return _this;
    }
    SmartSuiteResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        combineLatest(controlValue(this.form.form.controls['account_id']), controlValue(this.form.form.controls['key']))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var accountId = _a[0], key = _a[1];
            _this.solutionSource.accountId = accountId;
            _this.solutionSource.key = key;
            _this.solutionSource.reset();
        });
        merge(this.form.form.controls['account_id'].valueChanges, this.form.form.controls['key'].valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.form.form.controls['solution_id'].patchValue(null);
        });
    };
    SmartSuiteResourceSettingsComponent.prototype.ngOnDestroy = function () { };
    SmartSuiteResourceSettingsComponent.prototype.chooseSync = function () {
        var _this = this;
        this.chooseSyncController
            .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.submit(); });
    };
    return SmartSuiteResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { SmartSuiteResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.SmartSuite, SmartSuiteResourceSettingsComponent);

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export type LogoAlign = 'left' | 'center' | 'right';

@Component({
  selector: 'app-page-logo',
  templateUrl: './page-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLogoComponent implements OnInit {
  @HostBinding('class.page-logo') cls = true;
  @Input() align: LogoAlign;

  @HostBinding('class.page-logo_align_left') get alignLeft() {
    return this.align == 'left';
  }

  @HostBinding('class.page-logo_align_center') get alignCenter() {
    return this.align == 'center';
  }

  @HostBinding('class.page-logo_align_right') get alignRight() {
    return this.align == 'right';
  }

  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM } from '@modules/models';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { FeedItem } from '../data/feed-item';
import { FeedItemService } from '../services/feed-item/feed-item.service';

@Injectable()
export class FeedItemListStore extends ListStore<FeedItem> {
  projectName: string;
  environmentName: string;
  params = {};

  constructor(private service: FeedItemService) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<FeedItem>> {
    const params = this.paramsForPage(page);

    return this.service.get(this.projectName, this.environmentName, params).pipe(
      map(response => {
        return {
          items: response.results,
          hasMore: response.hasMore,
          totalPages: response.numPages,
          perPage: response.perPage,
          count: response.count
        };
      })
    );
  }

  setMarkRead(params?: Object): void {
    this.items = this.items.map(item => {
      const currentParams = {};
      if (item.userActivity.objectType) {
        currentParams['object_type'] = item.userActivity.objectType;
      }
      if (item.userActivity.objectId) {
        currentParams['object_id'] = item.userActivity.objectId;
      }

      if (isEqual(currentParams, params)) {
        item = cloneDeep(item);
        item.read = true;
        return item;
      } else {
        return item;
      }
    });
  }
}

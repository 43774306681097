<mat-menu #item_dropdown="matMenu">
  <button
    *ngIf="item.renameHandler"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="startRename()"
  >
    <span class="choose-items-item__dropdown-icon icon-edit"></span>
    {{ item.renameLabel }}
  </button>

  <button *ngIf="item.editHandler" mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="editItem()">
    <span class="choose-items-item__dropdown-icon icon-configure"></span>
    {{ item.editLabel }}
  </button>

  <button
    *ngFor="let action of item.actions || []"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="handleItemAction(action)"
  >
    <span *ngIf="action.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + action.icon"></span>
    {{ action.label }}
  </button>

  <button
    *ngIf="item.deleteHandler"
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteItem()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    {{ item.deleteLabel }}
  </button>
</mat-menu>

<div class="menu3-item menu3-item_interactive" [class.menu3-item_active]="rename">
  <div class="menu3-item__row menu3-item__row_interactive">
    <div class="menu3-item__left">
      <div
        class="menu3-item__icon menu3-item__icon_button"
        [class.menu3-item__icon_state_non-drag]="false"
        [ngClass]="item.icon ? 'icon-' + item.icon : 'icon-text'"
      ></div>
    </div>

    <div class="menu3-item__main">
      <ng-container *ngIf="!rename && !renameLoading && !actionLoading && !deleteLoading">
        <div class="menu3-item__title">
          {{ item.label }}

          <span *ngIf="item.labelIcon" class="menu3-item__title-icon" [ngClass]="'icon-' + item.labelIcon"></span>
        </div>

        <!--        <span *ngIf="item.tag | appIsSet" class="menu3-item__label-tag">-->
        <!--          {{ item.tag }}-->
        <!--        </span>-->
      </ng-container>

      <input
        *ngIf="rename && !renameLoading"
        class="menu3-item__input"
        type="text"
        [formControl]="renameControl"
        [appAutofocus]="true"
        (blur)="finishRename()"
        (keyup)="onRenameKeyUp($event)"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      />

      <div *ngIf="renameLoading || actionLoading || deleteLoading" class="menu3-item__title">
        <span [class.loading-animation]="true"><span class="stub-text">Loading</span></span>
      </div>
    </div>

    <div class="menu3-item__right">
      <div *ngIf="valueDisplay | appIsSet" class="menu3-item__additional menu3-item__right-item">
        {{ valueDisplay }}
      </div>

      <div
        *ngIf="!rename && !renameLoading && !actionLoading && !deleteLoading"
        class="menu3-item__action menu3-item__action_orange menu3-item__right-item icon-more"
        [class.menu3-item__action_active]="item_dropdown_trigger.menuOpen"
        [matMenuTriggerFor]="item_dropdown"
        #item_dropdown_trigger="matMenuTrigger"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      ></div>
    </div>
  </div>
</div>

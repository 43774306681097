import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ImageElementItem, ViewContext } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, FieldType, ImageFit, ImageOutputFormat } from '@modules/fields';
import { StorageSelectSource } from '@modules/storages-queries';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarImageEditForm } from './customize-bar-image-edit.form';

@Component({
  selector: 'app-customize-bar-image-edit',
  templateUrl: './customize-bar-image-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomizeBarImageEditForm, StorageSelectSource]
})
export class CustomizeBarImageEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() titleEnabled = true;
  @Input() element: ImageElementItem;
  @Input() elementUpdated: Observable<ImageElementItem>;
  @Input() context: ViewContext;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  result: ImageElementItem;
  fieldTypes = FieldType;
  booleanFieldStyle = BooleanFieldStyle;
  outputFormats = ImageOutputFormat;
  imageFits = ImageFit;
  configurationStarted = false;
  actionsValid$: Observable<boolean>;

  constructor(
    private cd: ChangeDetectorRef,
    private customizeBarContext: CustomizeBarContext,
    public storageSelectSource: StorageSelectSource,
    public form: CustomizeBarImageEditForm,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.element, this.firstInit);

    if (this.elementUpdated) {
      this.elementUpdated.pipe(untilDestroyed(this)).subscribe(element => {
        this.form.init(element);
        this.form.updateValueAndValidity();
      });
    }

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(value => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        switchMap(result => this.form.isConfigured(result)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    this.actionsValid$ = this.form.actionsValid$();
  }

  ngOnDestroy(): void {}

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.element.analyticsName
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  emitUpdate(submit = false) {
    const args = { result: this.result, submit: submit };

    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpHeaders } from '@angular/common/http';
import toPairs from 'lodash/toPairs';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpContentType, HttpMethod, HttpQuery, HttpQueryService, QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
import { AppError } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var GoogleCloudStorageGeneratorService = /** @class */ (function (_super) {
    __extends(GoogleCloudStorageGeneratorService, _super);
    function GoogleCloudStorageGeneratorService(mode, secretTokenService, apiService, http, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.apiService = apiService;
        _this.http = http;
        _this.httpQueryService = httpQueryService;
        _this.tokenName = 'access_token';
        return _this;
    }
    GoogleCloudStorageGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        var _this = this;
        return this.getServiceToken(project.uniqueName, environment.uniqueName, resource.uniqueName).pipe(switchMap(function (serviceToken) {
            return _this.readServiceToken(serviceToken).pipe(map(function (result) { return [serviceToken, result ? result.projectId : undefined, result ? result.accessToken : undefined]; }));
        }), map(function (_a) {
            var serviceToken = _a[0], projectId = _a[1], accessToken = _a[2];
            return {
                serviceToken: serviceToken,
                projectId: projectId,
                accessToken: accessToken
            };
        }));
    };
    GoogleCloudStorageGeneratorService.prototype.getServiceToken = function (projectName, environmentName, resourceName) {
        return this.secretTokenService
            .getDetail(projectName, environmentName, resourceName, this.tokenName, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            if (result.params['service_token']) {
                return JSON.stringify(result.params['service_token']);
            }
            return '';
        }));
    };
    GoogleCloudStorageGeneratorService.prototype.readServiceToken = function (value) {
        // const value = this.form.value['service_token'].trim();
        try {
            var serviceToken = JSON.parse(value.trim());
            var url = this.apiService.methodURL('resource_auth/');
            var headers = new HttpHeaders();
            var data = {
                name: 'firebase',
                service_token: serviceToken
            };
            headers = this.apiService.setHeadersToken(headers);
            return this.http.post(url, data, { headers: headers }).pipe(map(function (result) {
                // this.form.patchValue({
                //   project_id: result['project_id'],
                //   access_token: result['token']
                // });
                return {
                    projectId: result['project_id'],
                    accessToken: result['token']
                };
            }
            // () => {
            //   this.form.patchValue({
            //     project_id: '',
            //     access_token: ''
            //   });
            // }
            ));
        }
        catch (e) {
            // this.form.patchValue({
            //   project_id: '',
            //   access_token: ''
            // });
            return of(undefined);
        }
    };
    GoogleCloudStorageGeneratorService.prototype.createStorages = function (projectId, setUpHeaders) {
        var _this = this;
        var query = new HttpQuery();
        query.url = "https://storage.googleapis.com/storage/v1/b?project=" + projectId;
        query.headers = toPairs(setUpHeaders).map(function (_a) {
            var k = _a[0], v = _a[1];
            return { name: k, value: v };
        });
        return this.httpQueryService.requestBody(query).pipe(map(function (response) {
            if (!response['items'] || !response['items'].length) {
                throw new AppError('This account has no storages');
            }
            return response['items'].map(function (item, i) {
                var storage = new Storage();
                var bucketId = item['id'];
                var bucketName = item['name'];
                storage.uniqueName = bucketId;
                storage.name = bucketName;
                storage.uploadQuery = new StorageQuery();
                storage.uploadQuery.queryType = QueryType.Http;
                storage.uploadQuery.httpQuery = new HttpQuery();
                storage.uploadQuery.httpQuery.method = HttpMethod.POST;
                storage.uploadQuery.httpQuery.url = "https://storage.googleapis.com/upload/storage/v1/b/" + bucketId + "/o?uploadType=multipart";
                storage.uploadQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                storage.uploadQuery.httpQuery.bodyType = HttpContentType.FormData;
                storage.uploadQuery.httpQuery.body = [
                    {
                        name: 'metadata',
                        value: "{\n                  \"name\": \"{{path}}{{file_name}}\",\n                  \"predefinedAcl\": \"publicRead\",\n                  \"mimeType\": \"{{file_mime_type}}\",\n                  \"acl\": [{\"entity\": \"allUsers\", \"role\": \"READER\"}]\n                }",
                        contentType: 'application/json'
                    },
                    {
                        name: 'file',
                        value: '{{file}}'
                    }
                ];
                storage.uploadQuery.httpQuery.responseTransformer = "return 'https://storage.googleapis.com/" + bucketId + "/' + encodeURIComponent(data.name)";
                storage.removeQuery = new StorageQuery();
                storage.removeQuery.queryType = QueryType.Http;
                storage.removeQuery.httpQuery = new HttpQuery();
                storage.removeQuery.httpQuery.method = HttpMethod.DELETE;
                storage.removeQuery.httpQuery.url = "https://storage.googleapis.com/storage/v1/b/" + bucketId + "/o/{{encodeURIComponent(path)}}";
                storage.removeQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                storage.removeQuery.httpQuery.bodyType = HttpContentType.Raw;
                storage.createDirectoryQuery = new StorageQuery();
                storage.createDirectoryQuery.queryType = QueryType.Http;
                storage.createDirectoryQuery.httpQuery = new HttpQuery();
                storage.createDirectoryQuery.httpQuery.method = HttpMethod.POST;
                storage.createDirectoryQuery.httpQuery.url = "https://storage.googleapis.com/upload/storage/v1/b/" + bucketId + "/o";
                storage.createDirectoryQuery.httpQuery.headers = [
                    { name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }
                ];
                storage.createDirectoryQuery.httpQuery.queryParams = [
                    {
                        name: 'uploadType',
                        value: 'media'
                    },
                    {
                        name: 'name',
                        value: '{{path}}/'
                    }
                ];
                storage.getQuery = new StorageQuery();
                storage.getQuery.queryType = QueryType.Http;
                storage.getQuery.httpQuery = new HttpQuery();
                storage.getQuery.httpQuery.method = HttpMethod.GET;
                storage.getQuery.httpQuery.url = "https://storage.googleapis.com/storage/v1/b/" + bucketId + "/o";
                storage.getQuery.httpQuery.headers = [{ name: 'Authorization', value: "Bearer {-" + _this.tokenName + "-}" }];
                storage.getQuery.httpQuery.queryParams = [
                    {
                        name: 'delimiter',
                        value: '/'
                    },
                    {
                        name: 'prefix',
                        value: '{{path}}'
                    }
                ];
                storage.getQuery.httpQuery.responseTransformer = "\nconst folders = (data.prefixes || []).map(item => {\n    return {\n      type: 'folder',\n      path: item\n    };\n});\n\nconst files = (data.items || []).filter(item => !item['name'].endsWith('/')).map(item => {\n    return {\n      type: item['name'].substr(-1) == '/' ? 'folder' : 'file',\n      path: item['name'],\n      url: 'https://storage.googleapis.com/" + bucketId + "/' + encodeURIComponent(item.name),\n      size: item.size,\n      created: item.timeCreated,\n      updated: item.updated\n    };\n});\n\n\nreturn [...folders, ...files];";
                return storage;
            });
        }), this.apiService.catchApiError());
    };
    GoogleCloudStorageGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        var setUpHeaders = {
            Authorization: "Bearer " + options.accessToken
        };
        return combineLatest(this.createStorages(options.projectId, setUpHeaders)).pipe(map(function (_a) {
            var storages = _a[0];
            var token = new SecretToken();
            token.name = _this.tokenName;
            token.type = SecretTokenType.Firebase;
            token.value = '';
            try {
                token.params = {
                    service_token: JSON.parse(options.serviceToken)
                };
            }
            catch (e) {
                token.params = {};
            }
            return {
                resourceParams: {},
                secretTokens: [token.serialize()],
                storages: storages.map(function (item) { return item.serialize(); })
            };
        }));
    };
    return GoogleCloudStorageGeneratorService;
}(ResourceGeneratorService));
export { GoogleCloudStorageGeneratorService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/directives/app-link/app-link.directive";
import * as i2 from "../../../routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../routing/directives/app-link-active/app-link-active.directive";
import * as i6 from "../customize-menu-banner/customize-menu-banner.component.ngfactory";
import * as i7 from "../customize-menu-banner/customize-menu-banner.component";
import * as i8 from "../../../tutorial/directives/documentation/documentation.directive";
import * as i9 from "../../../tutorial/services/guide-popup/guide-popup.service";
import * as i10 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i11 from "ng-gxscrollable";
import * as i12 from "../../../../common/banners/pipes/banner-visible/banner-visible.pipe";
import * as i13 from "../../../../core/services/local-storage/local.storage";
import * as i14 from "./customize-menu-queues.component";
import * as i15 from "../../../collaboration-components/services/task-queue-edit-controller/task-queue-edit.controller";
import * as i16 from "../../../projects/stores/current-project.store";
import * as i17 from "../../../menu/services/menu/menu.service";
import * as i18 from "../../../collaboration/stores/task-queue.store";
var styles_CustomizeMenuQueuesComponent = [];
var RenderType_CustomizeMenuQueuesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeMenuQueuesComponent, data: {} });
export { RenderType_CustomizeMenuQueuesComponent as RenderType_CustomizeMenuQueuesComponent };
function View_CustomizeMenuQueuesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["class", "menu-pages__item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeMenu.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, [[2, 4]], 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { queryParams: [0, "queryParams"], appLink: [1, "appLink"] }, null), i0.ɵpod(2, { queue: 0 }), i0.ɵdid(3, 1720320, null, 2, i5.AppLinkActiveDirective, [i3.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { appLinkActive: [0, "appLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "menu-pages-item menu-pages-item_interactive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "menu-pages-item__icon icon-versions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "menu-pages-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, _v.context.$implicit.uid); var currVal_3 = ((_co.currentProject == null) ? null : _co.currentProject.settingsCollaborationTasksLink); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "menu-pages__item_active"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_5); }); }
function View_CustomizeMenuQueuesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "menu-pages__banner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-customize-menu-banner", [], null, null, null, i6.View_CustomizeMenuBannerComponent_0, i6.RenderType_CustomizeMenuBannerComponent)), i0.ɵdid(2, 114688, null, 0, i7.CustomizeMenuBannerComponent, [], { uid: [0, "uid"], title: [1, "title"], showVideoOptions: [2, "showVideoOptions"] }, null), i0.ɵpod(3, { youtubeId: 0 }), (_l()(), i0.ɵted(-1, 0, [" Read more in our "])), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "a", [["target", "_blank"]], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i8.DocumentationDirective, [i0.ElementRef, i9.GuidePopupService, i10.UniversalAnalyticsService], { documentation: [0, "documentation"] }, null), (_l()(), i0.ɵted(-1, null, ["Documentation"]))], function (_ck, _v) { var currVal_0 = "menu_collaboration_banner"; var currVal_1 = "Discover the power of the <br>Jet Admin Collaboration"; var currVal_2 = _ck(_v, 3, 0, "-DMkubFxhDs"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "approval-workflow"; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_CustomizeMenuQueuesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "menu-pages"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "menu-pages__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "menu-pages-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "menu-pages-header__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Tasks"])), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["class", "menu-pages__content"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(6, 12664832, null, 0, i11.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(7, { stopPropagationAlways: 0 }), (_l()(), i0.ɵeld(8, 0, null, null, 9, "div", [["class", "menu-pages__group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 8, "div", [["class", "menu-pages-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "a", [["class", "menu-pages__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createQueue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "menu-pages-item menu-pages-item_interactive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "menu-pages-item__icon icon-plus_circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "span", [["class", "menu-pages-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add task queue"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomizeMenuQueuesComponent_1)), i0.ɵdid(16, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomizeMenuQueuesComponent_2)), i0.ɵdid(19, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(0, i12.BannerVisiblePipe, [i13.LocalStorage])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 7, 0, true); _ck(_v, 6, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform(_co.queues$)); _ck(_v, 16, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 19, 0, i0.ɵnov(_v, 20).transform("menu_collaboration_banner")); _ck(_v, 19, 0, currVal_2); }, null); }
export function View_CustomizeMenuQueuesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-menu-queues", [], null, null, null, View_CustomizeMenuQueuesComponent_0, RenderType_CustomizeMenuQueuesComponent)), i0.ɵdid(1, 245760, null, 0, i14.CustomizeMenuQueuesComponent, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i15.TaskQueueEditController, i16.CurrentProjectStore, i17.MenuService, i18.TaskQueueStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeMenuQueuesComponentNgFactory = i0.ɵccf("app-customize-menu-queues", i14.CustomizeMenuQueuesComponent, View_CustomizeMenuQueuesComponent_Host_0, {}, { closeMenu: "closeMenu" }, []);
export { CustomizeMenuQueuesComponentNgFactory as CustomizeMenuQueuesComponentNgFactory };

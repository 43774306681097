import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { JsonOutputFormat } from '@modules/fields';

@Injectable()
export class JsonFieldDataParamsForm {
  form: FormGroup;
  control: AbstractControl;

  outputFormatOptions = [
    { value: JsonOutputFormat.Object, name: 'JSON Object' },
    { value: JsonOutputFormat.String, name: 'String' },
    { value: JsonOutputFormat.Firestore, name: 'Firebase format' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output_format: new FormControl(JsonOutputFormat.Object)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      let outputFormat: JsonOutputFormat;

      if (control.value.hasOwnProperty('output_format')) {
        outputFormat = control.value['output_format'];
      } else if (control.value.hasOwnProperty('store_object')) {
        outputFormat = control.value['store_object'] ? JsonOutputFormat.Object : JsonOutputFormat.String;
      }

      if (outputFormat) {
        this.form.controls['output_format'].patchValue(outputFormat, { emitEvent: false });
      }
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const result = {
      output_format: value['output_format']
    };

    this.control.patchValue({
      ...this.control.value,
      ...result
    });
  }
}

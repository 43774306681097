<div class="sidebar__element">
  <app-sidebar-field [label]="'HEX'">
    <div style="position: relative;">
      <span
        class="field__button-color"
        [class.field__button-color_empty]="!hexControl.value"
        [style.background-color]="hexControl.value"
        style="position: absolute; top: 12px; left: 12px;"
      ></span>

      <input
        type="text"
        class="input input_fill"
        style="padding-left: 35px;"
        [formControl]="hexControl"
        [appAutofocus]="true"
        placeholder="#FF0000"
        maxlength="7"
      />
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'hue'">
    <app-slider
      [baseControl]="hueControl"
      [min]="0"
      [max]="360"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="hueBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setHue($event)"
    ></app-slider>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'saturation'">
    <app-slider
      [baseControl]="saturationControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="saturationBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setSaturation($event)"
    ></app-slider>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'luminosity'">
    <app-slider
      [baseControl]="luminosityControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="luminosityBackground"
      [fillBackground]="'transparent'"
      [thumbBorderColor]="hueThumbBorder"
      [thumbInside]="true"
      (baseChange)="setLuminosity($event)"
    ></app-slider>
  </app-sidebar-field>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicComponentModule } from '@common/dynamic-component';
import { SharedModule } from '@shared';

import { BasePopupComponent } from './components/base-popup/base-popup.component';
import { PopupsComponent } from './components/popups/popups.component';

@NgModule({
  imports: [CommonModule, SharedModule, DynamicComponentModule],
  declarations: [PopupsComponent, BasePopupComponent],
  exports: [PopupsComponent, BasePopupComponent],
  entryComponents: [BasePopupComponent]
})
export class PopupsModule {}

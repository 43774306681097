import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';

import { EditableContentModule } from '@common/editable-content';
import { TipsModule } from '@common/tips';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { SidebarCardComponent } from './components/sidebar-card/sidebar-card.component';
import { SidebarCollapseComponent } from './components/sidebar-collapse/sidebar-collapse.component';
import { SidebarFieldComponent } from './components/sidebar-field/sidebar-field.component';
import { SidebarHeaderComponent } from './components/sidebar-header/sidebar-header.component';
import { SidebarImageCardComponent } from './components/sidebar-image-card/sidebar-image-card.component';
import { SidebarListSeparatorComponent } from './components/sidebar-list-separator/sidebar-list-separator.component';
import { SidebarSectionComponent } from './components/sidebar-section/sidebar-section.component';
import { SidebarSubmitComponent } from './components/sidebar-submit/sidebar-submit.component';
import { SidebarTabComponent } from './components/sidebar-tab/sidebar-tab.component';
import { SidebarTabsComponent } from './components/sidebar-tabs/sidebar-tabs.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    OverlayModule,
    EditableContentModule,
    FieldsModule,
    TipsModule,
    UiModule,
    MatMenuModule
  ],
  declarations: [
    SidebarCollapseComponent,
    SidebarFieldComponent,
    SidebarHeaderComponent,
    SidebarListSeparatorComponent,
    SidebarSectionComponent,
    SidebarSubmitComponent,
    SidebarImageCardComponent,
    SidebarCardComponent,
    SidebarTabsComponent,
    SidebarTabComponent
  ],
  exports: [
    SidebarCollapseComponent,
    SidebarFieldComponent,
    SidebarHeaderComponent,
    SidebarListSeparatorComponent,
    SidebarSectionComponent,
    SidebarSubmitComponent,
    SidebarImageCardComponent,
    SidebarCardComponent,
    SidebarTabsComponent,
    SidebarTabComponent
  ]
})
export class SidebarModule {}

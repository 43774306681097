<div class="browser-window-header">
  <div class="browser-window-header__left">
    <div class="browser-window-header__circle browser-window-header__circle_color_red"></div>
    <div class="browser-window-header__circle browser-window-header__circle_color_yellow"></div>
    <div class="browser-window-header__circle browser-window-header__circle_color_green"></div>
  </div>
  <div class="browser-window-header__main">
    <div class="browser-window-header__address">
      <div *ngIf="addressIcon || addressIconHasDefault" class="browser-window-header__address-left">
        <div
          class="browser-window-header__address-icon"
          [style.background-image]="
            addressIcon ? 'url(' + addressIcon + ')' : ('/assets/favicon/favicon-32x32.png' | appDeployCssUrl)
          "
        ></div>
      </div>
      <div class="browser-window-header__address-input">
        <span *ngIf="title | appIsSet" class="browser-window-header__address-input-label">{{ title }}</span>
        <span *ngIf="(title | appIsSet) || (address | appIsSet)" class="browser-window-header__address-input-separator"
          >–</span
        >
        <span *ngIf="address | appIsSet" class="browser-window-header__address-input-url">{{ address }}</span>
      </div>

      <div *ngIf="addressSearchIcon" class="browser-window-header__address-right">
        <div class="browser-window-header__address-icon icon-search"></div>
      </div>
    </div>
  </div>
</div>

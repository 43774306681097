var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppError } from '../errors/errors';
var JsFunctionError = /** @class */ (function (_super) {
    __extends(JsFunctionError, _super);
    function JsFunctionError(e) {
        var _this = _super.call(this, e) || this;
        _this.e = e;
        var excMatch = e.stack.match(/^([^\n]+)\n/);
        var paramsMatch = e.stack.match(/at\s.+\s\(.+,\s<anonymous>:(\d+):(\d+)\)/);
        _this.error = excMatch ? excMatch[1] : undefined;
        _this.line = paramsMatch ? paramsMatch[1] : undefined;
        _this.column = paramsMatch ? paramsMatch[2] : undefined;
        Object.setPrototypeOf(_this, JsFunctionError.prototype);
        return _this;
    }
    return JsFunctionError;
}(AppError));
export { JsFunctionError };
export function getFunctionBody(func) {
    if (!func) {
        return func;
    }
    return func.toString().match(/function[^{]+\{([\s\S]*)\}$/)[1];
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectPermission } from '@modules/projects';
import { ProjectPermissionActionsControl } from './project-permission-actions.control';
var ProjectPermissionControl = /** @class */ (function (_super) {
    __extends(ProjectPermissionControl, _super);
    function ProjectPermissionControl(formState, data) {
        var _this = _super.call(this, {
            id: new FormControl(formState.id),
            type: new FormControl(formState.type),
            object: new FormControl(formState.object),
            actions: new ProjectPermissionActionsControl(formState.actions),
            enabled: new FormControl(!!formState.enabled)
        }) || this;
        _this.data = data;
        return _this;
    }
    ProjectPermissionControl.prototype.setValue = function (value, options) {
        _super.prototype.setValue.call(this, value, options);
    };
    ProjectPermissionControl.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
    };
    ProjectPermissionControl.prototype.deserialize = function (permission) {
        this.patchValue({
            id: permission ? permission.id : undefined,
            enabled: !!permission
        });
        this.controls.actions.deserialize(permission ? permission.permissionActions : '');
    };
    ProjectPermissionControl.prototype.serialize = function () {
        var permission = new ProjectPermission();
        permission.id = this.value.id;
        permission.permissionType = this.value.type;
        permission.permissionObject = this.value.object;
        permission.permissionActions = this.controls.actions.serialize();
        return permission;
    };
    return ProjectPermissionControl;
}(FormGroup));
export { ProjectPermissionControl };

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent implements OnInit {
  @Input() paddingLeft = false;
  @Input() paddingHorizontal = false;

  @HostBinding('class.page') cls = true;
  @HostBinding('class.page_padding-left') get paddingLeftCls() {
    return this.paddingLeft;
  }
  @HostBinding('class.page_padding-horizontal') get paddingHorizontalCls() {
    return this.paddingHorizontal;
  }

  constructor() {}

  ngOnInit() {}
}

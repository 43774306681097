/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../field-type-menu/field-type-menu.component.ngfactory";
import * as i2 from "../field-type-menu/field-type-menu.component";
import * as i3 from "./field-type-dropdown.component";
var styles_FieldTypeDropdownComponent = [];
var RenderType_FieldTypeDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FieldTypeDropdownComponent, data: {} });
export { RenderType_FieldTypeDropdownComponent as RenderType_FieldTypeDropdownComponent };
export function View_FieldTypeDropdownComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "popover2__content"], ["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-field-type-menu", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.select.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FieldTypeMenuComponent_0, i1.RenderType_FieldTypeMenuComponent)), i0.ɵdid(5, 770048, null, 0, i2.FieldTypeMenuComponent, [i0.ChangeDetectorRef], { overrideSections: [0, "overrideSections"], currentValue: [1, "currentValue"] }, { select: "select" }), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "popover2__arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overrideSections; var currVal_1 = _co.currentValue; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_FieldTypeDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-type-dropdown", [], null, null, null, View_FieldTypeDropdownComponent_0, RenderType_FieldTypeDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i3.FieldTypeDropdownComponent, [], null, null)], null, null); }
var FieldTypeDropdownComponentNgFactory = i0.ɵccf("app-field-type-dropdown", i3.FieldTypeDropdownComponent, View_FieldTypeDropdownComponent_Host_0, { overrideSections: "sections", currentValue: "currentValue" }, { select: "select" }, []);
export { FieldTypeDropdownComponentNgFactory as FieldTypeDropdownComponentNgFactory };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-field',
  templateUrl: './sidebar-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarFieldComponent implements OnInit {
  @Input() label: string;
  @Input() required = false;
  @Input() additional: string;
  @Input() tag: string;
  @Input() tagColor = 'blue';
  @Input() subtitle: string;
  @Input() layoutRight = false;
  @Input() layoutLeft = false;
  @Input() fullWidth = false;
  @Input() documentation: string;
  @Input() documentationLabel: string;
  @Input() documentationLeft = true;
  @Input() documentationSource: string;
  @Input() classes: string | string[];

  constructor() {}

  ngOnInit() {}
}

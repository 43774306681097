import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { IntercomService } from '@modules/analytics';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { Domain, SignUpLayout } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, ImageFit } from '@modules/fields';
import { MenuGroupItem, MenuPagesService } from '@modules/menu';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectPropertyType
} from '@modules/projects';
import { controlValue, setControlEnabled } from '@shared';

import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { SignUpFieldControl } from '../project-settings/sign-up-field.control';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';

@Component({
  selector: 'app-customize-bar-signup-edit',
  templateUrl: './customize-bar-signup-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarSignupEditComponent implements OnInit, OnDestroy {
  @Input() projectSignUpForm: ProjectSignUpForm;
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;
  @Input() settingsForm: ProjectSettingsUpdateForm;

  @ViewChild('dropdown') signUpEnableGroupDropdown: ProjectGroupDropdownComponent;

  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  signUpOpened = false;
  signUpLink: string;
  domain: Domain;
  hasSSO = false;
  layoutOptions = [
    {
      layout: SignUpLayout.PosterRight,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#d9eaf4'
    },
    {
      layout: SignUpLayout.PosterLeft,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#d9eaf4'
    },
    {
      layout: SignUpLayout.Form,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#dce0e6'
    },
    {
      layout: SignUpLayout.Background,
      defaultBackgroundColor: '#fff'
    }
  ];
  imageFits = ImageFit;
  signUpFields: { control: SignUpFieldControl; property: ProjectProperty }[] = [];
  signUpBackgroundColors = ['blue', 'teal', 'maroon', 'navy', 'purple', 'orange'];
  builtInSignUpFields = [
    {
      icon: 'user',
      label: 'First Name'
    },
    {
      icon: 'user',
      label: 'Last Name',
      optional: true
    },
    {
      icon: 'email',
      label: 'Email'
    },
    {
      icon: 'lock_close',
      label: 'Password'
    }
  ];
  menuLoading = true;
  menuItems: MenuGroupItem[] = [];
  projectPropertyTypes = ProjectPropertyType;
  analyticsSource = 'layout_builder_sign_up';

  filterPropertyOption = (() => {
    return (property: ProjectProperty) => {
      return !this.settingsForm.controls.sign_up_fields.controls.find(control => {
        return control.controls.project_property.value == property.uid;
      });
    };
  })();

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private featureService: FeatureService,
    private projectDomainController: ProjectDomainController,
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private menuPagesService: MenuPagesService,
    private intercomService: IntercomService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.signUpLink = this.getSignUpLink();
        this.cd.markForCheck();
      });

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.hasSSO = this.domain && this.domain.ssoSettings.length != 0;
        this.cd.markForCheck();
      });

    this.menuPagesService
      .getGroups()
      .pipe(untilDestroyed(this))
      .subscribe(
        groups => {
          this.menuItems = groups.reduce((acc, group) => {
            acc.push(...group.items);
            return acc;
          }, []);
          this.menuLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.menuLoading = false;
          this.cd.markForCheck();
        }
      );

    combineLatest(
      controlValue(this.settingsForm.controls.sign_up_fields),
      this.projectPropertyStore
        .get()
        .pipe(map(properties => properties.filter(item => item.type == ProjectPropertyType.User)))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([_, properties]) => {
        this.signUpFields = this.settingsForm.controls.sign_up_fields.controls
          .map(control => {
            const property = properties
              ? properties.find(item => item.uid === control.controls.project_property.value)
              : undefined;
            return {
              control: control,
              property: property
            };
          })
          .filter(item => item.property);
        this.cd.markForCheck();
      });

    controlValue(this.projectSignUpForm.controls.enabled)
      .pipe(untilDestroyed(this))
      .subscribe(signUpEnabled => {
        setControlEnabled(this.projectDomainUpdateForm.controls.sign_up_title, signUpEnabled);
        setControlEnabled(this.projectDomainUpdateForm.controls.sign_up_description, signUpEnabled);
        setControlEnabled(this.projectDomainUpdateForm.controls.privacy_policy, signUpEnabled);
        setControlEnabled(this.projectDomainUpdateForm.controls.terms_of_service, signUpEnabled);
      });
  }

  ngOnDestroy(): void {}

  get currentLayoutOption() {
    return this.layoutOptions.find(item => item.layout == this.projectDomainUpdateForm.controls.sign_up_layout.value);
  }

  showSignUpFeatureOverview() {
    if (this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
      return;
    }

    this.featureService.showFeatureOverview({
      subtitle: 'Customer Portal Feature',
      title: 'App sign up with <strong>Customer Access</strong>',
      description: `
        <ul>
          <li>Customers can get access to app on their own without invitation</li>
          <li>Assign particular Team for newly registered customers</li>
          <li>Filter data for customers</li>
        </ul>
      `
    });
  }

  getSignUpLink(): string {
    const project = this.currentProjectStore.instance;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
    return `${webBaseUrl}/register/${this.currentProjectStore.instance.uniqueName}`;
  }

  copySignUpCode() {
    const text = this.getSignUpLink();

    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info('Copied', 'Link was copied to clipboard');
      });
  }

  editDomain() {
    this.projectDomainController.edit({ analyticsSource: this.analyticsSource }).pipe(untilDestroyed(this)).subscribe();
  }

  onDomainRequiredClick() {
    if (!this.domain) {
      this.editDomain();
    }
  }

  isSignUpEnabled(): boolean {
    return (
      !this.projectDomainUpdateForm.controls.disable_email_sign_up.value ||
      this.projectSignUpForm.controls.enabled.value
    );
  }

  onSignUpClick() {
    if (!this.isSignUpEnabled()) {
      if (!this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
        this.showSignUpFeatureOverview();
      } else {
        this.signUpEnableGroupDropdown.open();
      }

      if (!this.projectDomainUpdateForm.controls.default_project.value) {
        this.projectDomainUpdateForm.controls.default_project.patchValue(true);
      }
    } else {
      this.signUpOpened = !this.signUpOpened;
      this.cd.markForCheck();
    }
  }

  onSignUpToggleClick(event: MouseEvent) {
    if (this.isSignUpEnabled()) {
      event.stopPropagation();

      this.projectDomainUpdateForm.controls.disable_email_sign_up.patchValue(true);
      this.projectSignUpForm.controls.enabled.patchValue(false);
      this.signUpOpened = false;
      this.cd.markForCheck();
    }
  }

  onSignUpEnableGroupChange(value: ProjectGroup) {
    if (value) {
      this.projectDomainUpdateForm.controls.disable_email_sign_up.patchValue(false);
      this.projectSignUpForm.controls.enabled.patchValue(true);
      this.signUpOpened = true;
      this.cd.markForCheck();
    }
  }

  openChat() {
    this.intercomService.openChat();
  }

  createProperty() {
    this.projectPropertyEditController
      .create({
        type: ProjectPropertyType.User,
        requiredEnabled: true,
        placeholderEnabled: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const control = this.settingsForm.controls.sign_up_fields.appendControl(result.property);

        control.controls.required.patchValue(result.required);
        control.controls.placeholder.patchValue(result.placeholder);
      });
  }

  editProperty(control: SignUpFieldControl, property: ProjectProperty) {
    this.projectPropertyEditController
      .edit({
        property: property,
        requiredEnabled: true,
        required: control.controls.required.value,
        placeholderEnabled: true,
        placeholder: control.controls.placeholder.value,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        control.controls.required.patchValue(result.required);
        control.controls.placeholder.patchValue(result.placeholder);
      });
  }

  dragDrop(event: CdkDragDrop<SignUpFieldControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.settingsForm.controls.sign_up_fields.controls, event.previousIndex, event.currentIndex);
      this.settingsForm.controls.sign_up_fields.updateValueAndValidity();
    }
  }
}

import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { Resource } from '@modules/projects';
import { HttpQueryService, QueryType, StorageQuery } from '@modules/queries';
import { Storage, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { GetObjectUrlResponse, ResourceController, StorageBucketsResponse } from '../../data/resource-controller';

@Injectable()
export class AmazonS3ResourceController extends ResourceController {
  private http: HttpClient;
  private httpQueryService: HttpQueryService;
  private apiService: ApiService;
  private projectApiService: ProjectApiService;

  init() {
    this.http = this.initService<HttpClient>(HttpClient);
    this.httpQueryService = this.initService<HttpQueryService>(HttpQueryService);
    this.apiService = this.initService<ApiService>(ApiService);
    this.projectApiService = this.initService<ProjectApiService>(ProjectApiService);
  }

  getBuckets(accessKeyId: string, secretAccessKey: string, endpoint?: string): Observable<StorageBucketsResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(project, environment, 's3/get_buckets');
        let headers = new HttpHeaders();
        const data = {
          access_key_id: accessKeyId,
          secret_access_key: secretAccessKey,
          endpoint: endpoint
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new StorageBucketsResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  supportedQueryTypes(queryClass: any): QueryType[] {
    return [QueryType.Simple, QueryType.Http];
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project,
          environment,
          `${resource.uniqueName}/storages/${storage.uniqueName}/upload_file`,
          true
        );
        const params = {
          ...(window['mode'] == AdminMode.Builder && {
            draft: '1'
          })
        };
        let headers = new HttpHeaders();
        const data = new FormData();

        if (isSet(fileName)) {
          file = new File([file], fileName, { type: file.type });
        }

        if (isSet(path)) {
          data.set('path', path);
        }

        data.set('file', file);

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http
          .post<ModelResponse.UploadFileResponse>(url, data, {
            params: params,
            headers: headers,
            observe: 'events',
            reportProgress: true
          })
          .pipe(
            map(event => {
              if (event.type == HttpEventType.Response) {
                return {
                  ...event.body,
                  result: {
                    ...event.body.result,
                    response: event
                  }
                };
              } else if (event.type == HttpEventType.UploadProgress) {
                return {
                  state: {
                    uploadProgress: event.loaded / event.total,
                    downloadProgress: 0,
                    uploadLoaded: event.loaded,
                    uploadTotal: event.total
                  }
                };
              } else if (event.type == HttpEventType.DownloadProgress) {
                return {
                  state: {
                    uploadProgress: 1,
                    downloadProgress: event.loaded / event.total,
                    downloadLoaded: event.loaded,
                    downloadTotal: event.total
                  }
                };
              }
            }),
            filter(item => item !== undefined)
          );
      }),
      this.apiService.catchApiError() as any
    );
  }

  getStorageObjects(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<StorageObjectsResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project,
          environment,
          `${resource.uniqueName}/storages/${storage.uniqueName}/list_objects`
        );
        const params = {
          ...(window['mode'] == AdminMode.Builder && {
            draft: '1'
          })
        };
        let headers = new HttpHeaders();
        const data = {
          path: path
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post(url, data, { params: params, headers: headers });
      }),
      map(result => new StorageObjectsResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteStorageObject(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<{ result: boolean }> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project,
          environment,
          `${resource.uniqueName}/storages/${storage.uniqueName}/delete_object`
        );
        const params = {
          ...(window['mode'] == AdminMode.Builder && {
            draft: '1'
          })
        };
        let headers = new HttpHeaders();
        const data = {
          path: path
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.request<{ result: boolean }>('DELETE', url, { params: params, headers: headers, body: data });
      }),
      map(result => result.result),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createStorageFolder(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<{ result: boolean }> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project,
          environment,
          `${resource.uniqueName}/storages/${storage.uniqueName}/create_folder`
        );
        const params = {
          ...(window['mode'] == AdminMode.Builder && {
            draft: '1'
          })
        };
        let headers = new HttpHeaders();
        const data = {
          path: path
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post<{ result: boolean }>(url, data, { params: params, headers: headers });
      }),
      map(result => result.result),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getObjectUrl(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string,
    expiresInSec?: number
  ): Observable<GetObjectUrlResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const project = window['project'];
        const environment = window['project_environment'];
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project,
          environment,
          `${resource.uniqueName}/storages/${storage.uniqueName}/get_object_url`
        );
        const params = {
          ...(window['mode'] == AdminMode.Builder && {
            draft: '1'
          })
        };
        let headers = new HttpHeaders();
        const data = {
          path: path,
          expires: expiresInSec
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post(url, data, { params: params, headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

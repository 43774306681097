var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { ImportModelsFieldControl } from './import-models-field.control';
var ImportModelsFieldArray = /** @class */ (function (_super) {
    __extends(ImportModelsFieldArray, _super);
    function ImportModelsFieldArray(injector) {
        var _this = _super.call(this, []) || this;
        _this.injector = injector;
        return _this;
    }
    ImportModelsFieldArray.prototype.init = function (modelDescription) {
        this.modelDescription = modelDescription;
    };
    ImportModelsFieldArray.prototype.createControl = function () {
        var control = Injector.create({
            providers: [{ provide: ImportModelsFieldControl, deps: [] }],
            parent: this.injector
        }).get(ImportModelsFieldControl);
        control.init(this.modelDescription);
        return control;
    };
    ImportModelsFieldArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    ImportModelsFieldArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    return ImportModelsFieldArray;
}(FormArray));
export { ImportModelsFieldArray };

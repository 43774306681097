<ng-container *ngIf="currentProjectStore.instance.features.isPublicAccessEnabled()">
  <app-page-block *ngIf="hasDraftChanges">
    <app-alert [purple]="true" [title]="'You have unpublished changes'" [icon]="'warning_filled'">
      Please note that public users will only see the published App
    </app-alert>
  </app-page-block>

  <app-page-block>
    <app-alert [warning]="true">
      <ng-container *ngIf="currentPageUrl">
        {{ 'You are setting sharing for the current page' | localize }}:<br />
        <strong style="word-break: break-word;">{{ currentPageUrl }}</strong>
      </ng-container>
      <ng-container *ngIf="!currentPageUrl">
        {{ 'You are setting sharing for all pages of your App.' | localize }}
      </ng-container>
    </app-alert>
  </app-page-block>

  <app-page-block>
    <div class="field field_manual-margin">
      <ng-container *ngIf="!loading">
        <label class="field__label">
          <app-toggle [orange]="false" [selected]="!!publicAccess" (click)="togglePublicAccess()"> </app-toggle>

          <label class="checkbox-label" (click)="togglePublicAccess()">
            {{ 'Public access link' | localize }}
            <a
              *ngIf="publicAccessLink && !submitLoading"
              href="javascript:void(0)"
              class="field__label-link"
              style="margin-left: 4px;"
              (click)="$event.stopPropagation(); copy(publicAccessLink, localize('link'))"
            >
              <span class="field__label-link-icon icon-duplicate"></span>
              {{ 'Copy' | localize }}
            </a>
          </label>
        </label>

        <div style="display: flex; align-items: center;">
          <div style="flex-grow: 1;">
            <ng-container *ngIf="publicAccessLink && !submitLoading">
              <div class="input-icon-wrapper">
                <span class="input-icon input-icon_left icon-link"></span>
                <input
                  class="input input_icon-left input_disabled input_fill"
                  [value]="publicAccessLink"
                  (click)="$event.target.select()"
                  readonly
                />
              </div>
            </ng-container>

            <ng-container *ngIf="!publicAccessLink && !submitLoading">
              <input
                class="input input_disabled input_stub input_fill"
                value="https://app.jetadmin.io/"
                style="pointer-events: none;"
                readonly
              />
            </ng-container>

            <ng-container *ngIf="submitLoading">
              <input
                class="input input_disabled input_fill"
                [value]="'Updating public access...' | localize"
                style="pointer-events: none;"
                readonly
              />
            </ng-container>
          </div>

          <div style="margin-left: 8px;">
            <app-project-group-control [control]="selectedGroup"></app-project-group-control>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="loading">
        <label class="field__label">
          <span [class.loading-animation]="true"><span class="stub-text">Public access</span></span>
        </label>

        <input
          class="input input_disabled input_stub input_fill"
          [class.loading-animation]="true"
          value="https://app.jetadmin.io/"
          style="pointer-events: none;"
          readonly
        />
      </ng-container>

      <div class="field__description">
        {{
          'Anyone with this link will be able to view current page. This way you can also embed current page into another website.'
            | localize
        }}
      </div>
    </div>
  </app-page-block>

  <app-popup-collapse *ngIf="publicAccessIFrame" [title]="'Embed code (IFRAME)' | localize" [border]="true">
    <app-page-block>
      <div class="field">
        <label class="field__label">
          <span class="field__label-text">HTML</span>
          <a
            href="javascript:void(0)"
            class="field__label-link"
            style="margin-left: 4px;"
            (click)="copy(publicAccessIFrame, 'iFrame')"
          >
            <span class="field__label-link-icon icon-duplicate"></span>
            {{ 'Copy' | localize }}
          </a>
        </label>

        <div class="field__content">
          <textarea
            class="input input_textarea input_disabled input_fill"
            [value]="publicAccessIFrame"
            (click)="$event.target.select()"
            style="word-break: break-all;"
            rows="3"
            readonly
          ></textarea>
        </div>
        <div class="field__description">
          {{
            'Paste this code into the HTML code of your website where you would like current page to appear.' | localize
          }}
        </div>
      </div>
    </app-page-block>
  </app-popup-collapse>
</ng-container>

<app-feature-overview
  *ngIf="!currentProjectStore.instance.features.isPublicAccessEnabled()"
  [feature]="'permissions'"
  (buttonClicked)="close.emit()"
>
  <div data-subtitle>Paid Feature</div>
  <div data-title><strong>Public access</strong> feature is not enabled in the current App plan</div>
  <div data-description>
    <ul>
      <li>Ask App administrator to upgrade plan</li>
    </ul>
  </div>
</app-feature-overview>

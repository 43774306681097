import { Injectable, Injector } from '@angular/core';
import { filter, take } from 'rxjs/operators';

import { PopupService } from '@common/popups';

import { GuidePopupComponent, GuidePopupOptions } from '../../components/guide-popup/guide-popup.component';
import { GuideTask } from '../../data/guide-task';
import { GuidePopupStoreService } from '../guide-popup-store/guide-popup-store.service';

@Injectable({
  providedIn: 'root'
})
export class GuidePopupService {
  constructor(
    private popupService: PopupService,
    private guidePopupStoreService: GuidePopupStoreService,
    private injector: Injector
  ) {}

  getComponent() {
    return this.guidePopupStoreService.component$.pipe(
      filter(item => item != undefined),
      take(1)
    );
  }

  createGuidePopupIfNeeded() {
    if (this.guidePopupStoreService.component) {
      return;
    }

    this.popupService.push({
      component: GuidePopupComponent,
      providers: [
        {
          provide: GuidePopupService,
          useValue: this
        }
      ],
      injector: this.injector
    });
  }

  openTask(task: GuideTask) {
    this.createGuidePopupIfNeeded();
    this.getComponent().subscribe(component => {
      component.openTask(task);
    });
  }

  openDocumentation(path: string, options: { left?: boolean } = {}) {
    const componentOptions: GuidePopupOptions = {
      left: options.left
    };

    this.createGuidePopupIfNeeded();
    this.getComponent().subscribe(component => {
      component.setOptions(componentOptions);
      component.openDocumentation(path);
    });
  }

  close() {
    if (!this.guidePopupStoreService.component) {
      return;
    }

    this.guidePopupStoreService.component.close();
  }
}

import * as moment from 'moment';

import { ElementType } from '@modules/customize';
import { isSet } from '@shared';

import { WorkflowRunLog } from './workflow-run-log';

export enum WorkflowRunStatus {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Failed = 'Failed'
}

export class WorkflowBackendRun {
  public id: string;
  public status: WorkflowRunStatus;
  public dateRun: moment.Moment;
  public dateFinished: moment.Moment;
  public dateAdd: moment.Moment;
  public params = {};
  public pageUid: string;
  public elementUid: string;
  public elementType: ElementType;
  public elementLabel: string;
  public userUid: string;
  public log: WorkflowRunLog;

  constructor(options: Partial<WorkflowBackendRun> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object) {
    this.id = data['id'];
    this.status = data['status'];
    this.dateRun = data['date_run'] ? moment(data['date_run']) : undefined;
    this.dateFinished = data['date_finished'] ? moment(data['date_finished']) : undefined;
    this.dateAdd = data['date_add'] ? moment(data['date_add']) : undefined;

    if (data['params']) {
      this.params = data['params'];
    }

    if (isSet(this.params['page_uid'])) {
      this.pageUid = this.params['page_uid'];
    }

    if (isSet(this.params['element_uid'])) {
      this.elementUid = this.params['element_uid'];
    }

    if (isSet(this.params['element_type'])) {
      this.elementType = this.params['element_type'];
    }

    if (isSet(this.params['element_label'])) {
      this.elementLabel = this.params['element_label'];
    }

    if (isSet(this.params['user_uid'])) {
      this.userUid = this.params['user_uid'];
    }

    if (data['log']) {
      this.log = new WorkflowRunLog().deserialize(data['log']);
    }

    return this;
  }

  isActive() {
    return [WorkflowRunStatus.Pending, WorkflowRunStatus.Running].includes(this.status);
  }

  getStatusLabel(): string {
    if (this.status == WorkflowRunStatus.Succeeded) {
      return 'succeeded';
    } else if (this.status == WorkflowRunStatus.Pending) {
      return 'pending';
    } else if (this.status == WorkflowRunStatus.Running) {
      return 'running';
    } else if (this.status == WorkflowRunStatus.Failed) {
      return 'failed';
    }
  }

  getLastDate() {
    return this.dateFinished || this.dateRun || this.dateAdd;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
var CustomizeBarActionEditElementActionComponent = /** @class */ (function () {
    function CustomizeBarActionEditElementActionComponent(cd) {
        this.cd = cd;
        this.componentActionPopoverOpened = false;
        this.breadcrumbsToken = [];
    }
    CustomizeBarActionEditElementActionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.context
            .getActionTokens$()
            .pipe(untilDestroyed(this))
            .subscribe(function (tokens) {
            var groups = tokens;
            var getComponentAction = function (token) {
                // if (token.allowSkip && token.children.length === 1) {
                //   return {
                //     ...getComponentAction(token.children[0]),
                //     name: token.name
                //   };
                // } else if (token.children != undefined) {
                if (token.children != undefined) {
                    return __assign({}, token, { children: token.children.map(function (item) { return getComponentAction(item); }) });
                }
                else {
                    return token;
                }
            };
            var hasTokens = function (token) {
                if (token.children != undefined) {
                    return token.children.some(function (item) { return hasTokens(item); });
                }
                else {
                    return token.token != undefined;
                }
            };
            _this.tokenGroup = groups.map(function (item) { return getComponentAction(item); }).filter(function (item) { return hasTokens(item); });
            _this.currentTokenGroup = _this.tokenGroup;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarActionEditElementActionComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditElementActionComponent.prototype.setCurrentTokenGroup = function (tokenGroup) {
        this.currentTokenGroup = [tokenGroup];
        this.breadcrumbsToken.push(tokenGroup);
        this.cd.markForCheck();
    };
    CustomizeBarActionEditElementActionComponent.prototype.goBack = function () {
        this.breadcrumbsToken.pop();
        if (this.breadcrumbsToken.length) {
            this.currentTokenGroup = [this.breadcrumbsToken[this.breadcrumbsToken.length - 1]];
        }
        else {
            this.currentTokenGroup = this.tokenGroup;
        }
    };
    CustomizeBarActionEditElementActionComponent.prototype.setComponentActionOpen = function (state, input) {
        if (input === void 0) { input = false; }
        if (input) {
            this.componentActionPopoverOpened = !this.componentActionPopoverOpened;
        }
        else {
            this.componentActionPopoverOpened = state;
        }
        this.cd.markForCheck();
    };
    CustomizeBarActionEditElementActionComponent.prototype.setComponentAction = function (componentAction) {
        this.selectedToken = componentAction;
        this.currentTokenGroup = this.tokenGroup;
        this.breadcrumbsToken = [];
        this.control.patchValue(componentAction ? componentAction.token : null);
        this.control.markAsDirty();
        this.setComponentActionOpen(false);
        this.cd.markForCheck();
    };
    CustomizeBarActionEditElementActionComponent.prototype.elementActionLabel = function (token) {
        return this.context.elementActionPath$(token).pipe(map(function (result) {
            return result.slice().reverse()
                .map(function (item) { return item.name; })
                .join(' · ');
        }));
    };
    return CustomizeBarActionEditElementActionComponent;
}());
export { CustomizeBarActionEditElementActionComponent };

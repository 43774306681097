export enum InputValueType {
  Prompt = 'prompt',
  StaticValue = 'static_value',
  EmptyString = 'empty_string',
  Null = 'null',
  Filter = 'filter',
  Context = 'context',
  Formula = 'formula',
  TextInputs = 'text_inputs',
  Js = 'js'
}

export const contextInputValueTypes = [
  InputValueType.Context,
  InputValueType.Formula,
  InputValueType.TextInputs,
  InputValueType.Js
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';
var ModelApiReloadQueryComponent = /** @class */ (function (_super) {
    __extends(ModelApiReloadQueryComponent, _super);
    function ModelApiReloadQueryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.modelApi = false;
        _this.parameters = [];
        return _this;
    }
    ModelApiReloadQueryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchModel();
        this.updateParameters();
    };
    ModelApiReloadQueryComponent.prototype.updateParameters = function () {
        this.parameters = this.getParameters();
        this.cd.markForCheck();
    };
    ModelApiReloadQueryComponent.prototype.updateCurl = function () {
        this.curl = this.getCurlRequest({
            url: this.getAbsoluteApiUrl(),
            method: 'POST'
        });
    };
    ModelApiReloadQueryComponent.prototype.getQueryUrl = function () {
        return '/reload';
    };
    ModelApiReloadQueryComponent.prototype.getResponseData = function () {
        return JSON.stringify({ success: true }, null, 2);
    };
    return ModelApiReloadQueryComponent;
}(ModelApiBaseQueryComponent));
export { ModelApiReloadQueryComponent };

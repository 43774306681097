<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-separator-menu-item-popover
    [control]="control"
    (close)="openEditPopover(false)"
  ></app-separator-menu-item-popover>
</ng-template>

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openEditPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize item
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete item
  </button>
</mat-menu>

<div class="sidebar-container__element">
  <div
    class="sidebar-list-separator2 sidebar-list-separator2_draggable"
    [class.sidebar-list-separator2_active]="item_dropdown_trigger.menuOpen || editPopoverOpened"
    [class.sidebar-list-separator2_disabled]="editPopoverOpened"
    #root_trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
  >
    <div class="sidebar-list-separator2__inner"></div>
    <div
      class="sidebar-list-separator2__action icon-more"
      [class.sidebar-list-separator2__action_active]="item_dropdown_trigger.menuOpen"
      [matMenuTriggerFor]="item_dropdown"
      #item_dropdown_trigger="matMenuTrigger"
    ></div>
  </div>
</div>

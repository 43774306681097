import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, filter } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { PopupSettings, ViewContext } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarPopupEditForm } from './customize-bar-popup-edit.form';
var CustomizeBarPopupEditComponent = /** @class */ (function () {
    function CustomizeBarPopupEditComponent(form, dialogService, customizeBarContext, cd) {
        this.form = form;
        this.dialogService = dialogService;
        this.customizeBarContext = customizeBarContext;
        this.cd = cd;
        this.firstInit = false;
        this.setupOnCreate = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.booleanFieldStyle = BooleanFieldStyle;
        this.analyticsObject = 'popup';
    }
    CustomizeBarPopupEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.popup, this.firstInit);
        this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            var result = _this.form.submit();
            _this.emitUpdate(result);
        });
        this.actionsValid$ = this.form.actionsValid$();
    };
    CustomizeBarPopupEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarPopupEditComponent.prototype.close = function () {
        this.customizeBarContext.popSettingsComponent();
    };
    CustomizeBarPopupEditComponent.prototype.submit = function () {
        var result = this.form.submit();
        this.emitUpdate(result, true);
        this.close();
    };
    CustomizeBarPopupEditComponent.prototype.emitUpdate = function (result, submit) {
        if (submit === void 0) { submit = false; }
        this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    };
    CustomizeBarPopupEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarPopupEditComponent.prototype.delete = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete overlay " + this.popup.name + "?",
            style: 'orange'
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () {
            _this.event.emit({ type: CustomizeBarEditEventType.Deleted });
            _this.close();
        });
    };
    Object.defineProperty(CustomizeBarPopupEditComponent.prototype, "title", {
        get: function () {
            if (this.form.controls.name.value) {
                return this.form.controls.name.value;
            }
            else {
                return 'Modal';
            }
        },
        enumerable: true,
        configurable: true
    });
    return CustomizeBarPopupEditComponent;
}());
export { CustomizeBarPopupEditComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./guide-popup-task/guide-popup-task.component.ngfactory";
import * as i2 from "./guide-popup-task/guide-popup-task.component";
import * as i3 from "../../services/guide-popup/guide-popup.service";
import * as i4 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i5 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i6 from "../../services/guide/guide.service";
import * as i7 from "./guide-popup-documentation/guide-popup-documentation.component.ngfactory";
import * as i8 from "./guide-popup-documentation/guide-popup-documentation.component";
import * as i9 from "../../services/task/task.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../../common/popups/services/popup/popup.service";
import * as i12 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i13 from "@angular/common";
import * as i14 from "./guide-popup.component";
import * as i15 from "../../services/guide-popup-store/guide-popup-store.service";
import * as i16 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i17 from "../../../meta/services/meta.service";
var styles_GuidePopupComponent = [];
var RenderType_GuidePopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GuidePopupComponent, data: {} });
export { RenderType_GuidePopupComponent as RenderType_GuidePopupComponent };
function View_GuidePopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "guide-popup__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "guide-popup__header-back"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-arrow_backward_2 guide-popup__header-back-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" Back to ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "a", [["class", "guide-popup__header-close icon icon-close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-guide-popup-task", [], null, null, null, i1.View_GuidePopupTaskComponent_0, i1.RenderType_GuidePopupTaskComponent)), i0.ɵdid(7, 245760, null, 0, i2.GuidePopupTaskComponent, [i3.GuidePopupService, i4.DialogService, i5.UniversalAnalyticsService, i6.GuideService], { task: [0, "task"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openedTask; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; _ck(_v, 4, 0, currVal_0); }); }
function View_GuidePopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-guide-popup-documentation", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_GuidePopupDocumentationComponent_0, i7.RenderType_GuidePopupDocumentationComponent)), i0.ɵdid(1, 1753088, null, 0, i8.GuidePopupDocumentationComponent, [i9.TaskService, i10.DomSanitizer, i11.PopupService, i0.Injector, i0.ChangeDetectorRef], { path: [0, "path"], pageTitle: [1, "pageTitle"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openedDocumentationPath; var currVal_1 = _co.pageTitle; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GuidePopupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i12.IsSetPipe, []), i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "guide-popup-background"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["root", 1]], null, 5, "div", [["class", "guide-popup"]], [[2, "guide-popup_left", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GuidePopupComponent_1)), i0.ɵdid(5, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GuidePopupComponent_2)), i0.ɵdid(7, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openedTask; _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), _co.openedDocumentationPath)); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.left; _ck(_v, 3, 0, currVal_0); }); }
export function View_GuidePopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-guide-popup", [], null, null, null, View_GuidePopupComponent_0, RenderType_GuidePopupComponent)), i0.ɵdid(1, 4440064, null, 0, i14.GuidePopupComponent, [i15.GuidePopupStoreService, i16.BasePopupComponent, i17.MetaService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuidePopupComponentNgFactory = i0.ɵccf("app-guide-popup", i14.GuidePopupComponent, View_GuidePopupComponent_Host_0, { options: "options" }, {}, []);
export { GuidePopupComponentNgFactory as GuidePopupComponentNgFactory };

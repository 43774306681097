<div *ngIf="false" class="screen-form">
  <div class="screen-form__form">
    <form class="screen-form__form-inner" [formGroup]="form.form" (ngSubmit)="submit()">
      <div class="screen-form__heading">
        <!--<div class="screen-form__heading-subtitle">-->
        <!--Join Jet community-->
        <!--</div>-->
        <div class="screen-form__heading-title">
          <span class="screen-form__heading-title-left">
            New Project
          </span>
          <span class="screen-form__heading-title-right">
            Integration
          </span>
        </div>
      </div>
      <div class="screen-form__section">
        <div
          class="screen-form__row"
          [class.screen-form__row_error]="(form.form | appFormFieldErrors: 'name')?.length"
          [class.screen-form__row_focus]="name | appInputFocused"
        >
          <input
            class="screen-form__input"
            type="text"
            formControlName="name"
            placeholder="Project Name"
            [appAutofocus]="true"
            appInputFocusable
            #name
          />
          <span class="screen-form__input-icon">
            <span class="icon icon-chip"></span>
          </span>

          <div class="screen-form__errors">
            <div *ngFor="let error of form.form | appFormFieldErrors: 'name'" class="screen-form__error">
              {{ error }}
            </div>
          </div>
        </div>
        <div
          class="screen-form__row"
          [class.screen-form__row_error]="(form.form | appFormFieldErrors: 'api_base_url')?.length"
          [class.screen-form__row_focus]="api_base_url | appInputFocused"
        >
          <input
            class="screen-form__input"
            type="text"
            formControlName="api_base_url"
            placeholder="API URL"
            appInputFocusable
            #api_base_url
          />
          <div class="screen-form__help">
            https://<em>YOUR_PROJECT_URL</em>/jet_api/
          </div>

          <span class="screen-form__input-icon">
            <span class="icon icon-model_link"></span>
          </span>

          <div class="screen-form__errors">
            <div *ngFor="let error of form.form | appFormFieldErrors: 'api_base_url'" class="screen-form__error">
              {{ error }}
            </div>
          </div>
        </div>
        <div
          class="screen-form__row"
          [class.screen-form__row_error]="(form.form | appFormFieldErrors: 'token')?.length"
        >
          <div class="screen-form__label">Project Token</div>
          <div class="screen-form__text">{{ form.form.value['token'] }}</div>

          <div class="screen-form__errors">
            <div *ngFor="let error of form.form | appFormFieldErrors: 'token'" class="screen-form__error">
              {{ error }}
            </div>
          </div>
        </div>
      </div>

      <div class="screen-form__submit">
        <button
          type="submit"
          class="screen-form__button"
          [class.screen-form__button_disabled]="form.form.invalid || form.form.disabled"
        >
          Create App
        </button>
        <span class="screen-form__submit-loader" [class.screen-form__submit-loader_visible]="loading"></span>

        <div class="screen-form__errors" [class.screen-form__errors_visible]="(form.form | appFormErrors)?.length">
          <div *ngFor="let error of form.form | appFormErrors" class="screen-form__error">
            {{ error }}
          </div>
        </div>
      </div>
      <div>
        <a [routerLink]="['/projects']" class="screen-form__tip">Show all Projects</a>
      </div>
    </form>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { LayoutGroup, LayoutOption } from '../menu-block-layout-overlay/menu-block-layout-overlay.component';

@Component({
  selector: 'app-menu-block-add',
  templateUrl: './menu-block-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBlockAddComponent implements OnInit, OnDestroy {
  @Input() layoutGroups: LayoutGroup[] = [];
  @Output() add = new EventEmitter<LayoutOption>();

  layoutOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  setLayoutOpened(value: boolean) {
    this.layoutOpened = value;
    this.cd.markForCheck();
  }
}

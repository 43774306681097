import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';

import { localize } from '@common/localize';
import { ServerRequestError } from '@modules/api';

import { AppFormGroup } from '../data/app.form-group';
import { ErrorMessage } from '../data/error-message';

@Injectable({
  providedIn: 'root'
})
export class FormUtils {
  showFormErrors(form: FormGroup, error: ServerRequestError) {
    if (!(error instanceof ServerRequestError)) {
      return;
    }

    if (error.nonFieldErrors && error.nonFieldErrors.length) {
      if (form instanceof AppFormGroup) {
        form.setServerErrors(error.nonFieldErrors);
      } else {
        form.setErrors({
          server: error.nonFieldErrors
        });
      }
    }

    for (const field in error.fieldErrors) {
      if (form.controls[field] && form.controls[field] instanceof AbstractControl) {
        form.controls[field].setErrors({
          server: error.fieldErrors[field]
        });
      }
    }
  }

  getFirstError(form: FormGroup): ServerRequestError {
    let hasError = false;
    const nonFieldErrors = this.parseErrors(form.errors);
    const fieldErrors = {};

    if (nonFieldErrors.length) {
      hasError = true;
    }

    for (const name of keys(form.controls)) {
      fieldErrors[name] = this.parseErrors(form.controls[name].errors);

      if (fieldErrors[name].length) {
        hasError = true;
      }
    }

    if (!hasError) {
      return;
    }

    const error = new ServerRequestError();
    error.nonFieldErrors = nonFieldErrors.map(item => item.message);
    error.fieldErrors = fromPairs(
      toPairs(fieldErrors).map(([name, value]) => [name, (value as ErrorMessage[]).map(item => item.message)])
    );
    return error;
  }

  parseErrors(errors: ValidationErrors | null): ErrorMessage[] {
    if (!errors) {
      return [];
    }

    const result: ErrorMessage[] = [];

    toPairs(errors).forEach(([name, value]) => {
      if (name == 'required') {
        result.push({ name: name, message: localize('required') });
      } else if (name == 'email') {
        result.push({ name: name, message: localize('incorrect Email'), important: true });
      } else if (name == 'server') {
        result.push({ name: name, message: value, important: true });
      } else if (name == 'local') {
        result.push({ name: name, message: value, important: true });
      } else if (name == 'local_dirty') {
        result.push({ name: name, message: value });
      } else if (name == 'minlength') {
        result.push({ name: name, message: localize('Minimum password length {0}', [value.requiredLength]) });
      }
    });

    return result;
  }

  getControlErrors(control: AbstractControl, options: { touchOnly?: boolean } = {}): string[] {
    if (!control || !control.errors) {
      return [];
    }

    const form = control.parent;
    const submit = form ? form['submit'] : undefined;

    if (options.touchOnly && submit !== true && !control.touched) {
      return [];
    }

    const fieldErrors = control.errors;
    const errors = this.parseErrors(fieldErrors);
    const importantErrors = errors.filter(item => item.important);

    if (submit === false && control.pristine) {
      return importantErrors.map(item => item.message);
    } else if (submit !== false && form.pristine && control.pristine) {
      return importantErrors.map(item => item.message);
    } else {
      return errors.map(item => item.message);
    }
  }

  getFieldErrors(form: FormGroup | FormArray, field: string | number, options: { touchOnly?: boolean } = {}): string[] {
    if (!form || !form.controls[field]) {
      return [];
    }

    return this.getControlErrors(form.controls[field], options);
  }

  getFormErrors(form: FormGroup): string[] {
    if (!form) {
      return [];
    }
    if (!form.errors && !(form instanceof AppFormGroup && form.serverErrors.length)) {
      return [];
    }

    const errors = this.parseErrors(form.errors);

    if (form instanceof AppFormGroup) {
      errors.push(
        ...form.serverErrors.map(item => {
          return { name: 'server', message: item, important: true };
        })
      );
    }

    if (form.pristine) {
      return errors.filter(item => item.important).map(item => item.message);
    }

    if (form['submit'] === false && form.pristine) {
      return errors.filter(item => item.important).map(item => item.message);
    } else if (form['submit'] !== false && form.pristine) {
      return errors.filter(item => item.important).map(item => item.message);
    }

    return errors.map(item => item.message);
  }
}

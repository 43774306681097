import pickBy from 'lodash/pickBy';

import { generateAlphanumeric } from '@shared';

import { traverseWorkflowSteps } from '../utils/traverse';
import { WorkflowStep } from './steps/base';
import { WorkflowResult } from './workflow-result';
import { WorkflowRun } from './workflow-run';
import { deserializerWorkflowStep } from './workflow-step-types';

export class Workflow {
  public uid: string;
  public name: string;
  public steps: WorkflowStep[] = [];
  public result: WorkflowResult;
  public testRun: WorkflowRun;
  public testParameters = {};

  constructor(options: Partial<Workflow> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.name = data['name'];
    this.testParameters = data['test_parameters'] || {};

    if (!this.uid) {
      this.generateUid();
    }

    if (data['steps']) {
      this.steps = data['steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    if (data['result']) {
      this.result = new WorkflowResult().deserialize(data['result']);
    } else {
      this.result = undefined;
    }

    if (data['test_run']) {
      this.testRun = new WorkflowRun().deserialize(data['test_run']);
    } else {
      this.testRun = undefined;
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      name: this.name,
      steps: this.steps.map(item => item.serialize()),
      result: this.result ? this.result.serialize() : undefined,
      test_run: this.testRun ? this.testRun.serialize() : undefined,
      test_parameters: this.testParameters
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }

  flattenSteps(): WorkflowStep[] {
    const result = [];

    traverseWorkflowSteps(this.steps, item => {
      if (item instanceof WorkflowStep) {
        result.push(item);
      }
    });

    return result;
  }

  getStepsCount() {
    return this.flattenSteps().length;
  }
}

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, delay, startWith } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType, InputValueType, ParameterArray } from '@modules/fields';
import { InputFieldProvider } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet, objectsSortPredicate } from '@shared';
import { InputsEditForm } from '../inputs-edit/inputs-edit.form';
var ParametersEditWithInputsComponent = /** @class */ (function () {
    function ParametersEditWithInputsComponent(inputsForm, analyticsService, cd) {
        this.inputsForm = inputsForm;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.staticValueDisabled = false;
        this.userInput = false;
        this.displayValueTypes = [InputValueType.Formula];
        this.placeholder = 'Text';
        this.formulaPlaceholder = 'Formula';
        this.jsPlaceholder = 'return 2 * 3;';
        this.configurable = {};
        this.addLabel = 'Add Parameter';
        this.addBaseName = 'param';
        this.emptyLabel = 'Parameter';
        this.collapseContext = new SidebarCollapseContext();
        this.sendParametersAnalytics = true;
    }
    ParametersEditWithInputsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
            _this.cd.markForCheck();
        });
        this.initInputs();
        if (this.sendParametersAnalytics) {
            this.control.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(function (value) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
                    Object: _this.analyticsSource
                });
            });
        }
    };
    ParametersEditWithInputsComponent.prototype.ngOnDestroy = function () { };
    ParametersEditWithInputsComponent.prototype.getDistinctName = function (baseName, template, startIndex) {
        if (template === void 0) { template = function (n, i) { return n + "_" + i; }; }
        if (startIndex === void 0) { startIndex = 1; }
        var names = this.control.controls.map(function (item) {
            var value = item.controls.name.value;
            return isSet(value) ? value : '';
        });
        var name;
        var index = startIndex;
        do {
            name = template(baseName, index);
            ++index;
        } while (names.find(function (item) { return item.toLowerCase() == name.toLowerCase(); }));
        return name;
    };
    ParametersEditWithInputsComponent.prototype.addItem = function () {
        var name = this.getDistinctName(this.addBaseName);
        this.lastAddedForm = this.control.appendControl(undefined, { name: name, field: FieldType.Text });
        var form = this.inputsForm.createItem({ name: name, field: FieldType.Text, value_type: InputValueType.Formula });
        this.inputsForm.arrayAppend(form);
        if (this.sendParametersAnalytics) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
                Object: this.analyticsSource
            });
        }
    };
    ParametersEditWithInputsComponent.prototype.removeItem = function (control) {
        this.control.removeControl(control);
        if (this.sendParametersAnalytics) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
                Object: this.analyticsSource
            });
        }
    };
    ParametersEditWithInputsComponent.prototype.getItemForm = function (name) {
        return this.inputsForm.form.controls.find(function (item) { return item.value['name'] == name; });
    };
    ParametersEditWithInputsComponent.prototype.initInputs = function () {
        var _this = this;
        if (!this.inputsControl) {
            return;
        }
        this.inputsForm.init(this.inputsControl, this.parameterProvider, this.fieldsControl, this.context, this.contextElement);
        combineLatest(this.parameterProvider.value$, this.inputsForm.updatedFromControl.pipe(startWith(undefined)))
            .pipe(delay(0), untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0];
            _this.updateInputItems(value);
        });
    };
    ParametersEditWithInputsComponent.prototype.updateInputItems = function (providerItems) {
        var _this = this;
        var _a, _b;
        var displayAllParameters = providerItems.every(function (item) { return !item.children || !item.children.length; });
        var parameters = this.parameterProvider.itemsToFields(providerItems);
        var items = this.inputsForm.form.controls;
        var addItems = [];
        var removeItems = [];
        if (displayAllParameters) {
            // Add inputs for all parameters if they don't exist
            parameters.forEach(function (parameter, i) {
                var defaultValueType = providerItems[i].defaultValueType;
                var existingValue = items.find(function (item) { return item.value['name'] == parameter.name; });
                var valueType;
                if (parameter['required']) {
                    valueType = _this.userInput ? InputValueType.Prompt : defaultValueType;
                }
                if (existingValue) {
                    if (!existingValue.value['value_type'] && valueType) {
                        existingValue.patchValue({ value_type: valueType });
                    }
                    if (existingValue.value['required'] != parameter.required) {
                        existingValue.patchValue({ required: parameter.required });
                    }
                    return;
                }
                addItems.push(_this.inputsForm.createItem({
                    name: parameter.name,
                    value_type: valueType,
                    required: parameter.required
                }));
            });
            // Remove inputs
            items.forEach(function (item) {
                var existingParameter = parameters.find(function (parameter) { return parameter.name == item.value['name']; });
                if (!existingParameter) {
                    // Remove inputs for non existing parameters
                    removeItems.push(item);
                }
                else if (!isSet(item.value['name'])) {
                    // Remove no name inputs
                    removeItems.push(item);
                }
            });
        }
        else {
            // Add inputs for required parameters if they don't exist
            parameters
                .filter(function (item) { return item['required']; })
                .forEach(function (parameter) {
                var providerItem = providerItems.find(function (item) { return item.field && item.field.name == parameter.name; });
                var defaultValueType = providerItem ? providerItem.defaultValueType : undefined;
                var existingValue = items.find(function (item) { return item.value['name'] == parameter.name; });
                var valueType;
                if (parameter['required']) {
                    valueType = _this.userInput ? InputValueType.Prompt : defaultValueType;
                }
                if (existingValue) {
                    if (!existingValue.value['value_type'] && valueType) {
                        existingValue.patchValue({ value_type: valueType });
                    }
                    if (existingValue.value['required'] != parameter.required) {
                        existingValue.patchValue({ required: parameter.required });
                    }
                    return;
                }
                addItems.push(_this.inputsForm.createItem({
                    name: parameter.name,
                    value_type: valueType || '',
                    required: parameter.required
                }));
            });
            // Remove inputs
            items.forEach(function (item) {
                var existingParameter = parameters.find(function (parameter) { return parameter.name == item.value['name']; });
                if (!existingParameter) {
                    // Remove inputs for non existing parameters
                    removeItems.push(item);
                }
                else if (!isSet(item.value['name'])) {
                    // Remove no name inputs
                    removeItems.push(item);
                }
            });
        }
        if (addItems.length) {
            (_a = this.inputsForm).arrayAppend.apply(_a, addItems.sort(objectsSortPredicate('-value.name')));
        }
        if (removeItems.length) {
            (_b = this.inputsForm).arrayRemove.apply(_b, removeItems);
        }
    };
    ParametersEditWithInputsComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
            this.control.updateValueAndValidity();
        }
    };
    return ParametersEditWithInputsComponent;
}());
export { ParametersEditWithInputsComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { MapLocationStorage } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldInputControl } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, generateAlphanumeric, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { MarginControl } from '../../margin-control/margin-control.component';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import { CustomizeBarListLayoutSettingsForm, validateAction, validateActions } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
export var validateMapLocationField = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var storage = parent.controls.map_location_storage.value;
    if ([MapLocationStorage.PostgreSQL, MapLocationStorage.Object].includes(storage)) {
        if (!isSet(control.value)) {
            return { required: true };
        }
    }
};
export var validateMapTwoFieldsStorage = function (control) {
    var parent = control.parent;
    if (!parent) {
        return;
    }
    var storage = parent.controls.map_location_storage.value;
    if ([MapLocationStorage.TwoFields].includes(storage)) {
        if (!isSet(control.value)) {
            return { required: true };
        }
    }
};
var CustomizeBarMapSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarMapSettingsForm, _super);
    function CustomizeBarMapSettingsForm(modelOptionsSource, customViewService, customViewsStore, notificationService, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, dataSourceControl) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, {
            header: new FormControl(true),
            title: new FieldInputControl({ name: 'value' }),
            map_location_storage: new FormControl('', Validators.required),
            map_location_field: new FormControl('', validateMapLocationField),
            map_location_latitude_field: new FormControl('', validateMapTwoFieldsStorage),
            map_location_longitude_field: new FormControl('', validateMapTwoFieldsStorage),
            marker_color: new FormControl(''),
            marker_color_input_enabled: new FormControl(false),
            marker_color_input: new FieldInputControl({ name: 'value' }),
            marker_size: new FormControl(26),
            marker_size_input_enabled: new FormControl(false),
            marker_size_input: new FieldInputControl({ name: 'value' }),
            data_source: dataSourceControl,
            card_view_unique_name: new FormControl(null),
            card_view: new FormControl(null),
            card_view_mappings: new FormControl([]),
            search_enabled: new FormControl(true),
            search_live: new FormControl(true),
            card_click_action: new FormControl(undefined, undefined, validateAction),
            actions: new FormControl([], undefined, validateActions),
            model_actions: new FormControl([], undefined, validateActions),
            columns_actions: new FieldActionsArray([]),
            custom_actions: new ActionOutputFormGroup(elementConfigurationService),
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true),
            display_filters: new FormControl(true),
            filter_fields: new OptionEnabledArray([]),
            visible_input: new FieldInputControl({ name: 'value' }),
            name: new FormControl(''),
            tooltip: new FormControl(''),
            margin: new MarginControl()
        }) || this;
        _this.modelOptionsSource = modelOptionsSource;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.notificationService = notificationService;
        _this.ignoreSubmitControls = [_this.controls.card_view];
        _this.submitLoading$ = new BehaviorSubject(false);
        _this.locationStorageOptions = [
            {
                name: 'Latitude/Longitude fields',
                value: MapLocationStorage.TwoFields
            },
            {
                name: 'Latitude & Longitude object',
                value: MapLocationStorage.Object
            },
            {
                name: 'PostgreSQL format',
                value: MapLocationStorage.PostgreSQL
            }
        ];
        dataSourceControl.setRequired(true);
        _this.controls.map_location_storage.valueChanges.pipe(delay(0)).subscribe(function () {
            _this.controls.map_location_field.updateValueAndValidity();
            _this.controls.map_location_latitude_field.updateValueAndValidity();
            _this.controls.map_location_longitude_field.updateValueAndValidity();
        });
        return _this;
    }
    CustomizeBarMapSettingsForm.prototype.init = function (options) {
        var _this = this;
        this.settings = options.settings;
        this.pageUid = options.pageUid;
        this.elementUid = options.elementUid;
        var customView$ = isSet(options.settings.cardCustomView)
            ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
            : of(undefined);
        return customView$.pipe(tap(function (customView) {
            var value = {
                header: options.settings.header,
                title: options.settings.titleInput ? options.settings.titleInput.serialize() : {},
                card_view_unique_name: options.settings.cardCustomView,
                card_view: customView ? customView.view : null,
                card_view_mappings: options.settings.cardCustomViewMappings,
                map_location_storage: options.settings.locationStorage,
                map_location_field: options.settings.locationField,
                map_location_latitude_field: options.settings.locationLatitudeField,
                map_location_longitude_field: options.settings.locationLongitudeField,
                marker_color: options.settings.markerColor,
                marker_color_input_enabled: !!options.settings.markerColorInput,
                marker_color_input: options.settings.markerColorInput ? options.settings.markerColorInput.serialize() : {},
                marker_size: options.settings.markerSize || 26,
                marker_size_input_enabled: !!options.settings.markerSizeInput,
                marker_size_input: options.settings.markerSizeInput ? options.settings.markerSizeInput.serialize() : {},
                search_enabled: options.settings.searchEnabled,
                search_live: options.settings.searchLive,
                card_click_action: options.settings.cardClickAction,
                actions: options.settings.actions,
                model_actions: options.settings.modelActions,
                columns_actions: options.settings.columnActions,
                sorting_field: options.settings.sortingField || null,
                sorting_asc: options.settings.sortingAsc,
                display_filters: options.settings.displayFilters,
                filter_fields: options.settings.filterFields.map(function (item) {
                    return {
                        name: item.name,
                        enabled: true
                    };
                }),
                visible_input: options.visibleInput ? options.visibleInput.serialize() : {},
                tooltip: options.settings.tooltip,
                margin: options.margin
            };
            if (options.nameEditable) {
                value['name'] = options.name;
            }
            _this.patchValue(value, { emitEvent: false });
            _this.controls.data_source.deserialize(options.settings.dataSource);
            _this.controls.custom_actions.deserialize(customView && customView.view ? customView.view.actions : [], options.settings.customActions);
            _this.trackChanges(options);
            if (!options.firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarMapSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        _super.prototype.trackChanges.call(this, options);
        combineLatest(controlValue(this.controls.data_source.controls.columns), this.controls.data_source.getModelDescription$(), this.controls.data_source.getModelDescription$().pipe(switchMap(function (modelDescription) {
            if (!modelDescription) {
                return of([]);
            }
            return _this.modelOptionsSource.getOptions$(modelDescription, {
                relationsEnabled: true
            });
        })))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var columns = _a[0], modelDescription = _a[1], modelOptions = _a[2];
            var columnNames = modelDescription ? modelOptions.map(function (item) { return item.name; }) : columns.map(function (item) { return item.name; });
            var modelId = modelDescription ? modelDescription.modelId : null;
            var filterFieldsSourceChanged = _this.controls.filter_fields.isSourceSet()
                ? !_this.controls.filter_fields.isSource(modelId)
                : false;
            _this.controls.filter_fields.syncControls(columnNames, { source: modelId });
            if (_this.controls.display_filters.value && _this.controls.filter_fields.isAllDisabled()) {
                _this.controls.filter_fields.enableDefault();
            }
            else if (_this.controls.display_filters.value && filterFieldsSourceChanged) {
                _this.controls.filter_fields.enableDefault();
            }
        });
        this.controls.card_view.valueChanges
            .pipe(debounceTime(60), switchMap(function (value) {
            _this.submitLoading$.next(true);
            return _this.submitCardView(_this.controls.card_view_unique_name.value, value);
        }), tap(function (uniqueName) {
            _this.controls.card_view_unique_name.patchValue(uniqueName);
            _this.submitLoading$.next(false);
        }), catchError(function (error) {
            _this.submitLoading$.next(false);
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
            return of(undefined);
        }), untilDestroyed(this))
            .subscribe();
    };
    CustomizeBarMapSettingsForm.prototype.getCardView = function () {
        return this.controls.card_view.value;
    };
    CustomizeBarMapSettingsForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isListMapConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarMapSettingsForm.prototype.submitCardView = function (uniqueName, view) {
        var _this = this;
        if (!view) {
            return of(undefined);
        }
        var customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);
        return customView$.pipe(switchMap(function (customView) {
            if (customView) {
                var instance = cloneDeep(customView);
                var fields = ['unique_name', 'view_type', 'view', 'params'];
                instance.view = view;
                instance.pageUid = _this.pageUid;
                instance.elementUid = _this.elementUid;
                return _this.customViewService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
            else {
                var instance = new CustomView();
                var fields = ['unique_name', 'view_type', 'view', 'params'];
                instance.uniqueName =
                    isSet(_this.pageUid) && isSet(_this.elementUid)
                        ? [CustomViewType.ListItem, _this.pageUid, _this.elementUid].join('.')
                        : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
                instance.viewType = CustomViewType.ListItem;
                instance.view = view;
                instance.pageUid = _this.pageUid;
                instance.elementUid = _this.elementUid;
                return _this.customViewService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
        }), delayWhen(function () { return _this.customViewsStore.getFirst(true); }), map(function (result) { return result.uniqueName; }));
    };
    CustomizeBarMapSettingsForm.prototype.submit = function () {
        var instance = cloneDeep(this.settings);
        instance.locationStorage = this.controls.map_location_storage.value;
        instance.locationField = this.controls.map_location_field.value;
        instance.locationLatitudeField = this.controls.map_location_latitude_field.value;
        instance.locationLongitudeField = this.controls.map_location_longitude_field.value;
        if (this.controls.marker_color_input_enabled.value) {
            instance.markerColor = undefined;
            instance.markerColorInput = this.controls.marker_color_input.value
                ? new Input().deserialize(this.controls.marker_color_input.value)
                : undefined;
        }
        else {
            instance.markerColor = this.controls.marker_color.value;
            instance.markerColorInput = undefined;
        }
        if (this.controls.marker_size_input_enabled.value) {
            instance.markerSize = undefined;
            instance.markerSizeInput = this.controls.marker_size_input.value
                ? new Input().deserialize(this.controls.marker_size_input.value)
                : undefined;
        }
        else {
            instance.markerSize = this.controls.marker_size.value;
            instance.markerSizeInput = undefined;
        }
        instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        instance.cardCustomView = this.controls.card_view_unique_name.value;
        instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
        instance.searchEnabled = this.controls.search_enabled.value;
        instance.searchLive = this.controls.search_live.value;
        if (this.controls.card_click_action.value) {
            instance.cardClickAction = this.controls.card_click_action.value;
        }
        else {
            instance.cardClickAction = undefined;
        }
        instance.actions = this.controls.actions.value;
        instance.modelActions = this.controls.model_actions.value;
        instance.columnActions = this.controls.columns_actions.value;
        instance.customActions = this.controls.custom_actions.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        instance.filterFields = this.controls.filter_fields.value
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            return {
                name: item.name
            };
        });
        instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
        instance.header =
            (instance.titleInput && instance.titleInput.isSet()) ||
                !!instance.searchEnabled ||
                !!instance.displayFilters ||
                (instance.actions && instance.actions.length > 0);
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        return {
            settings: instance,
            visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined,
            name: this.controls.name.value,
            margin: this.controls.margin.value
        };
    };
    return CustomizeBarMapSettingsForm;
}(CustomizeBarListLayoutSettingsForm));
export { CustomizeBarMapSettingsForm };

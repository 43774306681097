<ng-container *ngIf="!updateLoading">
  <td class="card-table__column">
    <div class="card-table__column-inner">
      <div class="timeline2__dot" [class.timeline2__dot_active]="item.latest"></div>

      <span
        class="user-photo user-photo_tiny card-table__column-item"
        [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
        [appTip]="item.user ? item.user.strFull : 'Unknown user'"
        [appTipOptions]="{ side: 'top' }"
      >
      </span>

      <div *ngIf="item.createdAt" class="card-table__small card-table__column-item" style="min-width: 120px;">
        {{ item.createdAt | appFormatDate: 'lll' }}
      </div>

      <div
        *ngIf="!item.isProcessing() && !item.isFailed()"
        class="card-table__tag card-table__tag_interactive card-table__column-item card-table__column-item_l"
        [class.card-table__tag_orange]="(item.name | appIsSet) || editable_content.editing"
        [class.card-table__tag_active]="editable_content.editing"
        [appTip]="!(item.name | appIsSet) && !editable_content.editing ? 'Add version name' : null"
        [appTipOptions]="{ side: 'top' }"
        (click)="editable_content.startEditing()"
      >
        <span
          class="card-table__tag-label"
          [class.card-table__tag-label_disabled]="!(item.anyName | appIsSet) && !editable_content.editing"
        >
          <app-editable-content
            [value]="(item.anyName | appIsSet) ? item.anyName : ''"
            [emptyValue]="item.shortHash"
            [placeholder]="'v1.0.0'"
            [inline]="true"
            [multiline]="false"
            [activeTextColor]="false"
            [outline]="false"
            [cursorPointer]="true"
            (valueChange)="renameSnapshot($event)"
            (click)="$event.stopPropagation()"
            #editable_content
          >
          </app-editable-content>
        </span>
        <span
          class="card-table__tag-icon card-table__tag-icon_right icon-tag"
          [class.card-table__tag-icon_disabled]="!(item.name | appIsSet)"
          [class.card-table__tag-icon_hover]="!(item.name | appIsSet)"
        ></span>
      </div>

      <ng-container *ngIf="item.isProcessing() || item.isFailed()">
        <div *ngIf="item.anyName | appIsSet" class="card-table__tag card-table__column-item card-table__column-item_l">
          <span class="card-table__tag-label">
            {{ item.anyName }}
          </span>
        </div>

        <div
          *ngIf="item.isProcessing()"
          class="card-table__tag card-table__tag_yellow card-table__column-item"
          [class.card-table__column-item_l]="!(item.anyName | appIsSet)"
          [class.loading-animation]="true"
        >
          <span class="card-table__tag-label">Processing</span>
        </div>

        <div
          *ngIf="item.isFailed()"
          class="card-table__tag card-table__tag_red card-table__column-item"
          [class.card-table__column-item_l]="!(item.anyName | appIsSet)"
        >
          <span class="card-table__tag-label">Failed</span>
        </div>
      </ng-container>
    </div>
  </td>

  <td class="card-table__column">
    <div class="card-table__column-inner card-table__column-inner_right">
      <button *ngIf="item.latest" type="button" class="button button_extra-small button_orange button_disabled">
        <span class="button__icon button__icon_left icon-check_2"></span>
        <span class="button__label">Current version</span>
      </button>

      <button
        *ngIf="!item.latest && item.isSucceeded()"
        type="button"
        class="button button_extra-small button_orange-transparent"
        [disabled]="applyLoading"
        (click)="applySnapshot()"
      >
        <app-loader-small *ngIf="applyLoading" class="button__icon button__icon_left"></app-loader-small>
        <span *ngIf="!applyLoading" class="button__icon button__icon_left icon-redo"></span>
        <span class="button__label">Restore</span>
      </button>
    </div>
  </td>
</ng-container>

<ng-container *ngIf="updateLoading">
  <td class="card-table__column">
    <div class="card-table__column-inner">
      <div class="timeline2__dot"></div>

      <div class="card-table__small card-table__column-item">
        <span class="stub-text">
          <span [class.loading-animation]="true">current date</span>
        </span>
      </div>

      <div class="card-table__tag card-table__column-item">
        <span class="card-table__tag-label">
          <span class="stub-text">
            <span [class.loading-animation]="true">xxxxx</span>
          </span>
        </span>
      </div>
    </div>
  </td>

  <td class="card-table__column">
    <div class="card-table__column-inner"></div>
  </td>
</ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import { Option } from '@modules/field-components';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-compact-segmented-control',
  templateUrl: './compact-segmented-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompactSegmentedControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactSegmentedControlComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() options: Option[] = [];
  @Input() fill = false;
  @Output() change = new EventEmitter<void>();

  value$ = new Subject<any>();
  options$ = new BehaviorSubject<Option[]>([]);
  currentOption: Option;

  onChange = (value: any) => undefined;
  onTouched = () => undefined;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.value$, this.options$)
      .pipe(untilDestroyed(this))
      .subscribe(([value, options]) => {
        this.currentOption = options.find(item => item.value == value);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CompactSegmentedControlComponent>): void {
    if (changes.options) {
      this.options$.next(this.options);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value$.next(value);
  }

  updateValue(value: any) {
    this.value$.next(value);
    this.onChange(value);
    this.change.emit();
  }
}

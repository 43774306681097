import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, skip } from 'rxjs/operators';

import { ChartWidgetDataset } from '@modules/dashboard';
import { ModelDescription } from '@modules/models';

import { ChartWidgetDataSourceControl } from '../../model-description-data-source-edit/chart-widget-data-source';

export class ChartWidgetEditFormDatasetControl extends FormGroup implements OnDestroy {
  instance: ChartWidgetDataset;

  controls: {
    data_source: ChartWidgetDataSourceControl;
    color: FormControl;
    name: FormControl;
    format: FormControl;
  };

  constructor(dataSourceControl: ChartWidgetDataSourceControl) {
    super({
      data_source: dataSourceControl,
      color: new FormControl(''),
      name: new FormControl(''),
      format: new FormControl('')
    });
  }

  ngOnDestroy(): void {}

  deserialize(instance: ChartWidgetDataset) {
    if (instance) {
      this.controls.color.patchValue(instance.color);
      this.controls.name.patchValue(instance.name);
      this.controls.format.patchValue(instance.format);
    } else {
      this.controls.color.patchValue('');
      this.controls.name.patchValue('');
      this.controls.format.patchValue('');
    }

    this.controls.data_source.deserialize(instance ? instance.dataSource : undefined);

    this.trackChanges();

    this.markAsPristine();
  }

  trackChanges() {
    this.controls.data_source
      .getModelDescription$()
      .pipe(
        distinctUntilChanged((lhs, rhs) => {
          const modelId = (item: ModelDescription) => (item ? item.modelId : undefined);
          return modelId(lhs) === modelId(rhs);
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(modelDescription => this.onModelChange(modelDescription));
  }

  onModelChange(modelDescription: ModelDescription) {
    if (modelDescription && modelDescription.verboseNamePlural) {
      this.controls.name.patchValue(modelDescription.verboseNamePlural);
    } else {
      this.controls.name.patchValue('');
    }
  }

  serialize() {
    const result = cloneDeep(this.instance) || new ChartWidgetDataset();

    result.dataSource = this.controls.data_source.serialize();
    result.color = this.controls.color.value;
    result.name = this.controls.name.value;
    result.format = this.controls.format.value;

    return result;
  }
}

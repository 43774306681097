import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { DNSCNameRecord } from '../../data/dns';

@Injectable({
  providedIn: 'root'
})
export class EmailAddressService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  initiateCustomEmail(projectName: string, environmentName: string, domain: string): Observable<DNSCNameRecord[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `domains/${domain}/initiate_custom_email`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ cnames: DNSCNameRecord[] }>(url, {}, { headers: headers });
      }),
      map(result => result.cnames),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  setupCustomEmail(
    projectName: string,
    environmentName: string,
    domain: string,
    emailFrom: string
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `domains/${domain}/setup_custom_email`
        );
        let headers = new HttpHeaders();
        const data = {
          email_from: emailFrom
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteCustomEmail(projectName: string, environmentName: string, domain: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `domains/${domain}/delete_custom_email`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

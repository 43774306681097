import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { Domain } from '@modules/domain';
import { CurrentProjectStore } from '@modules/projects';

import { ProjectDomainComponent } from '../../components/project-domain/project-domain.component';

export interface ProjectDomainEditResult {
  created?: Domain;
  deleted?: Domain;
}

@Injectable()
export class ProjectDomainController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  edit(options: { analyticsSource?: string } = {}): Observable<ProjectDomainEditResult> {
    const obs$ = new ReplaySubject<ProjectDomainEditResult>();

    const create = !this.currentProjectStore.instance.domain;

    if (create) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomDomain.AddClicked, {
        Source: options.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomDomain.EditClicked, {
        Source: options.analyticsSource
      });
    }

    this.popupService.push({
      component: ProjectDomainComponent,
      popupComponent: ThinDialogPopupComponent,
      outputs: {
        created: [
          result => {
            obs$.next(result);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomDomain.DomainCreated, {
              Source: options.analyticsSource
            });
          }
        ],
        deleted: [
          result => {
            obs$.next(result);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomDomain.DomainDeleted, {
              Source: options.analyticsSource
            });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$.asObservable();
  }
}

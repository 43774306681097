<div class="code-editor__search input-icon-wrapper">
  <span class="input-icon input-icon_small input-icon_left icon-search"></span>
  <input
    class="input input_fill input_small input_icon-left input_icon-right"
    type="text"
    placeholder="Search tables..."
    [formControl]="searchControl"
    (keyup)="onSearchKey($event)"
    (change)="updateResourceStructuresFiltered()"
  />
  <a
    *ngIf="searchControl.value | appIsSet"
    href="javascript:void(0)"
    class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
    (click)="clearSearch()"
  ></a>
</div>

<app-token-structure-section
  *ngFor="let item of resourceStructuresFiltered"
  [section]="item"
  [openedInitial]="item.name == resourceUniqueName || (searchControl.value | appIsSet)"
  (inserted)="inserted.emit($event.insert)"
></app-token-structure-section>

<span class="code-editor__message" *ngIf="!resourceStructuresFiltered || resourceStructuresFiltered.length === 0"
  >No tables found</span
>

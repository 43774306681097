import { OnInit } from '@angular/core';
var PageHeadingComponent = /** @class */ (function () {
    function PageHeadingComponent() {
        this.size = 'm';
        this.cls = true;
    }
    Object.defineProperty(PageHeadingComponent.prototype, "sizeS", {
        get: function () {
            return this.size == 's';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageHeadingComponent.prototype, "sizeM", {
        get: function () {
            return this.size == 'm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageHeadingComponent.prototype, "sizeL", {
        get: function () {
            return this.size == 'l';
        },
        enumerable: true,
        configurable: true
    });
    PageHeadingComponent.prototype.ngOnInit = function () { };
    return PageHeadingComponent;
}());
export { PageHeadingComponent };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FixedContainerComponent } from './components/fixed-container/fixed-container.component';
import { FixedDirective } from './directives/fixed/fixed.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FixedDirective, FixedContainerComponent],
  exports: [FixedDirective, FixedContainerComponent]
})
export class FixedComponentModule {}

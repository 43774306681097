var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { combineLatest, concat, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap, toArray } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { AdminMode } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import { FieldType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ResourceType, SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, ModelDescriptionSimpleQuery, QueryType } from '@modules/queries';
import { AIRTABLE_CREATED_TIME, AIRTABLE_FIELD_ID_PARAM, AIRTABLE_PRIMARY_KEY, AIRTABLE_TABLE_UID_PARAM, AIRTABLE_TABLE_VIEW_ID_PARAM, AirtableBasePermissionLevel, AirtableFieldType, airtableReadOnlyFieldTypes, ResourceControllerService, SOURCE_FIELD_TYPE } from '@modules/resources';
import { forceObservable, isSet } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { airtableFieldMapping, defaultAirtableFieldMapping } from './airtable-field-mapping';
var readOnlyFields = [AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME];
export var AirtableAuthType;
(function (AirtableAuthType) {
    AirtableAuthType["OAuth"] = "oauth";
    AirtableAuthType["PersonalAccessToken"] = "personal_access_token";
    AirtableAuthType["ApiKey"] = "api_key";
})(AirtableAuthType || (AirtableAuthType = {}));
var AirtableGeneratorService = /** @class */ (function (_super) {
    __extends(AirtableGeneratorService, _super);
    function AirtableGeneratorService(mode, resourceControllerService, secretTokenService, apiService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.resourceControllerService = resourceControllerService;
        _this.secretTokenService = secretTokenService;
        _this.apiService = apiService;
        _this.tokenNameOAuth = 'oauth_access_token';
        _this.tokenNamePersonalAccessToken = 'personal_access_token';
        _this.tokenNameLegacy = 'api_key';
        _this.controller = _this.resourceControllerService.get(ResourceType.Airtable);
        return _this;
    }
    AirtableGeneratorService.prototype.getAccessToken = function (options) {
        var authType = options.auth_type || AirtableAuthType.OAuth;
        if (authType == AirtableAuthType.PersonalAccessToken) {
            return options.personal_access_token;
        }
        else {
            return options.access_token;
        }
    };
    AirtableGeneratorService.prototype.isOptionsValid = function (options) {
        var accessToken = this.getAccessToken(options);
        return this.controller.getBases({ accessToken: accessToken, key: options.key }).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
                error = new ServerRequestError('API Key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    AirtableGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        var _this = this;
        var authType = resource.params['auth_type'] || AirtableAuthType.OAuth;
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, authType == AirtableAuthType.PersonalAccessToken ? this.tokenNamePersonalAccessToken : this.tokenNameOAuth, this.mode == AdminMode.Builder)
            .pipe(catchError(function () {
            return _this.secretTokenService.getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, _this.tokenNameLegacy, _this.mode == AdminMode.Builder);
        }), catchError(function () { return of(undefined); }), map(function (secretToken) {
            return __assign({}, (secretToken &&
                secretToken.name == _this.tokenNameOAuth && {
                auth_type: AirtableAuthType.OAuth,
                access_token: secretToken.value,
                token_params: secretToken.params
            }), (secretToken &&
                secretToken.name == _this.tokenNamePersonalAccessToken && {
                auth_type: AirtableAuthType.PersonalAccessToken,
                personal_access_token: secretToken.value
            }), (secretToken &&
                secretToken.name == _this.tokenNameLegacy && {
                auth_type: AirtableAuthType.ApiKey,
                key: secretToken.value
            }), { base: resource.params['base'], tables: resource.params['tables'] });
        }));
    };
    AirtableGeneratorService.prototype.getUniqueName = function (name) {
        return slugify(name, { trim: true, separator: '_' }).replace(/_+/g, '_');
    };
    AirtableGeneratorService.prototype.createModelDescriptionMeta = function (options, table, tables) {
        var _this = this;
        var accessToken = this.getAccessToken(options);
        var defaultViewId = table.views.length ? table.views[0].id : undefined;
        var optionsTable = options.tables ? options.tables.find(function (item) { return item.id == table.id; }) : undefined;
        var optionsTableViewId = optionsTable ? optionsTable.view : undefined;
        var viewId = isSet(optionsTableViewId) ? optionsTableViewId : defaultViewId;
        return this.controller
            .getBaseTableRecords({
            base: options.base,
            table: table.id,
            accessToken: accessToken,
            key: options.key,
            viewId: viewId
        })
            .pipe(switchMap(function (modelResponse) {
            var fieldsObs = [
                {
                    name: AIRTABLE_PRIMARY_KEY,
                    fieldWithParams: {
                        field: FieldType.Text,
                        params: {}
                    },
                    source_type: AirtableFieldType.SingleLineText,
                    editable: false
                }
            ].concat(uniqBy(table.fields, function (item) { return item.name; })
                .filter(function (item) { return ![AirtableFieldType.Button].includes(item.type); })
                .map(function (item) {
                var _a;
                var mapping = airtableFieldMapping.find(function (i) { return i.type == item.type; });
                var resultMapping = mapping ? mapping.mapping : defaultAirtableFieldMapping;
                var extraParams = (_a = {}, _a[AIRTABLE_FIELD_ID_PARAM] = item.id, _a);
                var fieldWithParams;
                if (resultMapping.getField) {
                    fieldWithParams = forceObservable(resultMapping.getField({
                        name: item.name,
                        records: modelResponse.records,
                        options: options,
                        table: table,
                        field: item,
                        tables: tables,
                        controller: _this.controller
                    })).pipe(map(function (field) {
                        return __assign({}, field, { params: __assign({}, field.params, extraParams) });
                    }));
                }
                else if (resultMapping.getParams) {
                    fieldWithParams = forceObservable(resultMapping.getParams({
                        name: item.name,
                        records: modelResponse.records,
                        options: options,
                        table: table,
                        field: item,
                        tables: tables,
                        controller: _this.controller
                    })).pipe(map(function (params) {
                        return {
                            field: resultMapping.field,
                            params: __assign({}, params, extraParams)
                        };
                    }));
                }
                else {
                    fieldWithParams = of({
                        field: resultMapping.field,
                        params: __assign({}, resultMapping.params, extraParams)
                    });
                }
                return {
                    name: item.name,
                    fieldWithParams: fieldWithParams,
                    source_type: item.type,
                    editable: !airtableReadOnlyFieldTypes.includes(item.type) &&
                        ![
                            AirtableFieldType.SingleCollaborator,
                            AirtableFieldType.MultipleCollaborators,
                            AirtableFieldType.Barcode
                        ].includes(item.type)
                };
            }), [
                {
                    name: AIRTABLE_CREATED_TIME,
                    fieldWithParams: {
                        field: FieldType.DateTime,
                        params: {}
                    },
                    source_type: AirtableFieldType.DateTime,
                    editable: false
                }
            ]).map(function (item) {
                return forceObservable(item.fieldWithParams).pipe(map(function (fieldWithParams) {
                    var _a;
                    var field = new ModelField();
                    var dbField = new ModelDbField();
                    dbField.name = item.name;
                    if (dbField.name == AIRTABLE_PRIMARY_KEY) {
                        dbField.verboseName = 'ID';
                        dbField.required = true;
                    }
                    else {
                        dbField.required = false;
                    }
                    dbField.field = fieldWithParams.field;
                    dbField.editable = item.editable;
                    dbField.filterable = !readOnlyFields.includes(item.name);
                    dbField.sortable = !readOnlyFields.includes(item.name);
                    dbField.params = __assign({}, fieldWithParams.params, (_a = {}, _a[SOURCE_FIELD_TYPE] = item.source_type, _a));
                    dbField.updateFieldDescription();
                    field.name = item.name;
                    field.type = ModelFieldType.Db;
                    field.item = dbField;
                    return field;
                }));
            });
            return concat.apply(void 0, fieldsObs).pipe(toArray(), map(function (fields) {
                var displayField = table.fields.find(function (item) { return item.id == table.primaryFieldId; });
                return {
                    fields: fields,
                    displayField: displayField ? displayField.name : undefined,
                    view: viewId
                };
            }));
        }));
    };
    AirtableGeneratorService.prototype.createModelDescription = function (options, base, table, tables) {
        if (!base || !table) {
            return of(undefined);
        }
        var modelDescription = new ModelDescription();
        modelDescription.project = '{{project}}';
        modelDescription.resource = '{{resource}}';
        modelDescription.model = [base.id, table.id].join('_');
        modelDescription.verboseName = table.name;
        modelDescription.verboseNamePlural = table.name;
        modelDescription.primaryKeyField = AIRTABLE_PRIMARY_KEY;
        return this.createModelDescriptionMeta(options, table, tables).pipe(map(function (meta) {
            var _a;
            modelDescription.fields = meta.fields;
            modelDescription.defaultFields = cloneDeep(meta.fields);
            modelDescription.displayField = meta.displayField;
            modelDescription.params = (_a = {},
                _a[AIRTABLE_TABLE_UID_PARAM] = table.id,
                _a[AIRTABLE_TABLE_VIEW_ID_PARAM] = meta.view,
                _a);
            var getSimpleQuery = new ModelDescriptionSimpleQuery();
            getSimpleQuery.model = modelDescription.model;
            modelDescription.getQuery = new ListModelDescriptionQuery();
            modelDescription.getQuery.queryType = QueryType.Simple;
            modelDescription.getQuery.simpleQuery = getSimpleQuery;
            modelDescription.getQuery.sortingFields = meta.fields.map(function (item) {
                return {
                    name: item.name,
                    sortable: ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(item.name)
                };
            });
            // const getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
            // getDetailSimpleQuery.model = modelDescription.model;
            //
            // modelDescription.getDetailQuery = new ModelDescriptionQuery();
            // modelDescription.getDetailQuery.queryType = QueryType.Simple;
            // modelDescription.getDetailQuery.simpleQuery = getDetailSimpleQuery;
            // modelDescription.getDetailParametersUseDefaults = true;
            if (base && base.permissionLevel == AirtableBasePermissionLevel.Create) {
                var createSimpleQuery = new ModelDescriptionSimpleQuery();
                createSimpleQuery.model = modelDescription.model;
                modelDescription.createQuery = new ModelDescriptionQuery();
                modelDescription.createQuery.queryType = QueryType.Simple;
                modelDescription.createQuery.simpleQuery = createSimpleQuery;
                modelDescription.createParametersUseDefaults = true;
            }
            if (base &&
                [AirtableBasePermissionLevel.Create, AirtableBasePermissionLevel.Edit].includes(base.permissionLevel)) {
                var updateSimpleQuery = new ModelDescriptionSimpleQuery();
                updateSimpleQuery.model = modelDescription.model;
                modelDescription.updateQuery = new ModelDescriptionQuery();
                modelDescription.updateQuery.queryType = QueryType.Simple;
                modelDescription.updateQuery.simpleQuery = updateSimpleQuery;
                modelDescription.updateParametersUseDefaults = true;
            }
            if (base &&
                [AirtableBasePermissionLevel.Create, AirtableBasePermissionLevel.Edit].includes(base.permissionLevel)) {
                var deleteSimpleQuery = new ModelDescriptionSimpleQuery();
                deleteSimpleQuery.model = modelDescription.model;
                modelDescription.deleteQuery = new ModelDescriptionQuery();
                modelDescription.deleteQuery.queryType = QueryType.Simple;
                modelDescription.deleteQuery.simpleQuery = deleteSimpleQuery;
                modelDescription.deleteParametersUseDefaults = true;
            }
            return modelDescription;
        }), this.apiService.catchApiError());
    };
    AirtableGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        var accessToken = this.getAccessToken(options);
        return combineLatest(this.controller.getBases({ accessToken: accessToken, key: options.key }), this.controller.getBaseTables({ base: options.base, accessToken: accessToken, key: options.key })).pipe(switchMap(function (_a) {
            var basesResponse = _a[0], tablesResponse = _a[1];
            var base = basesResponse.bases.find(function (i) { return i.id == options.base; });
            var activeTables = tablesResponse.tables.filter(function (table) {
                if (options.tables) {
                    return options.tables.some(function (item) { return item.id == table.id; });
                }
                else {
                    return true;
                }
            });
            return concat.apply(void 0, activeTables.map(function (item) {
                return _this.createModelDescription(options, base, item, tablesResponse.tables);
            })).pipe(toArray());
        }), map(function (modelDescriptions) { return modelDescriptions.filter(function (item) { return item != undefined; }); }), map(function (modelDescriptions) {
            var token = new SecretToken();
            token.resource = '{{resource}}';
            if (options.auth_type == AirtableAuthType.PersonalAccessToken) {
                token.name = _this.tokenNamePersonalAccessToken;
                token.type = SecretTokenType.Static;
                token.value = options.personal_access_token;
            }
            else {
                token.name = _this.tokenNameOAuth;
                token.type = SecretTokenType.OAuth;
                token.value = options.access_token;
                try {
                    token.params = options.token_params;
                }
                catch (e) {
                    token.params = {};
                }
            }
            var resourceParams = {
                auth_type: options.auth_type,
                base: options.base,
                tables: options.tables
            };
            return {
                resourceParams: resourceParams,
                modelDescriptions: modelDescriptions.map(function (item) { return item.serialize(); }),
                secretTokens: [token.serialize()]
            };
        }));
    };
    return AirtableGeneratorService;
}(ResourceGeneratorService));
export { AirtableGeneratorService };

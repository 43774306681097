var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { getFieldDescriptionByType } from '@modules/fields';
import { isSet } from '@shared';
var PageTemplatesItemFieldControl = /** @class */ (function (_super) {
    __extends(PageTemplatesItemFieldControl, _super);
    function PageTemplatesItemFieldControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            enabled: new FormControl(isSet(state.enabled) ? state.enabled : '')
        }) || this;
    }
    PageTemplatesItemFieldControl.prototype.deserialize = function (instance) {
        this.instance = instance;
        this.fieldDescription = getFieldDescriptionByType(instance ? instance.field : undefined);
        if (instance) {
            this.controls.enabled.patchValue(instance.visible);
        }
        else {
            this.controls.enabled.patchValue(true);
        }
        this.markAsPristine();
        return this;
    };
    PageTemplatesItemFieldControl.prototype.serialize = function () {
        var result = cloneDeep(this.instance);
        result.visible = this.controls.enabled.value;
        return result;
    };
    PageTemplatesItemFieldControl.prototype.toggleEnabled = function () {
        this.controls.enabled.patchValue(!this.controls.enabled.value);
    };
    return PageTemplatesItemFieldControl;
}(FormGroup));
export { PageTemplatesItemFieldControl };

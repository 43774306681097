<td class="card-table__column">
  <div class="card-table__column-inner">
    {{ item.createdAt | appFormatDate: 'lll' }}
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__strong">
    <ng-container *ngIf="item.type == types.SyncStructure">Full sync</ng-container>
    <ng-container *ngIf="item.type == types.SyncData">Sync data</ng-container>
    <ng-container *ngIf="item.type == types.SyncRecord">
      Sync record

      <a
        *ngIf="recordUpdateInfo$ | async as info"
        class="help-icon help-icon_clickable icon-help"
        [appTip]="info"
        [appTipOptions]="{ side: 'top' }"
      >
      </a>
    </ng-container>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <div style="margin: -4px 0;">
      <span *ngIf="item.status == statuses.Pending" class="status">
        Pending
      </span>
      <span *ngIf="item.status == statuses.Running" class="status color_yellow_1 background-color_yellow_2">
        Running
      </span>
      <span *ngIf="item.status == statuses.Succeeded" class="status color_green_1 background-color_green_2">
        Succeeded
      </span>
      <span *ngIf="item.status == statuses.Failed" class="status color_red_1 background-color_red_2">
        Failed
      </span>
    </div>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__grey">
    <ng-container *ngIf="item.dateRun">
      <span class="icon-time"></span>
      {{ item.dateRun | appFormatDate: 'HH:mm:ss'
      }}<ng-container *ngIf="item.dateFinished"> - {{ item.dateFinished | appFormatDate: 'HH:mm:ss' }}</ng-container>
      <ng-container *ngIf="duration"> ({{ duration }})</ng-container>
    </ng-container>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner card-table__column-inner_right">
    <div
      class="button-icon2 button-icon2_size_s button-icon2_circle"
      [class.button-icon2_hover]="hover"
      [class.button-icon2_active]="expanded"
      [class.icon-arrow_forward_2]="!expanded"
      [class.icon-arrow_down_2]="expanded"
    ></div>
  </div>
</td>

<ng-template #secondRow>
  <tr *ngIf="expanded">
    <td colspan="5">
      <div class="terminal">
        <ng-container *ngIf="logsLoading">
          <span [class.loading-animation]="true">
            <span class="stub-text">
              [LOG] Opening database connection...<br />
              [LOG] Database connection is opened<br />
              [LOG] Started adding records to database...<br />
              [LOG] Fetching collections...
            </span>
          </span>
        </ng-container>

        <div *ngIf="logs" [innerHTML]="logs | appFormatText"></div>

        <ng-container *ngIf="logsError">
          Loading log failed:<br />
          {{ logsError }}
        </ng-container>
      </div>
    </td>
  </tr>
</ng-template>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import Delta from 'quill-delta';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import { MarkupType } from '@modules/fields';
import { ThemeService } from '@modules/theme';
import { isSet } from '@shared';

@Component({
  selector: 'app-input-edit-text-inputs-value',
  templateUrl: './input-edit-text-inputs-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditTextInputsValueComponent implements OnInit {
  @Input() typeControl: FormControl;
  @Input() valueControl: FormControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() displayValueTypesEnabled = true;
  @Input() fill = false;
  @Input() fillVertical = false;
  @Input() header = false;
  @Input() darker = false;
  @Input() borderRadius = true;
  @Input() analyticsSource: string;
  @Output() switchToDefault = new EventEmitter<string>();
  @Output() switchToFormula = new EventEmitter<string>();

  @ViewChild('toolbar_element') toolbarElement: ElementRef;

  opened = false;
  typeOptions: Option<MarkupType>[] = [
    {
      value: MarkupType.Text,
      name: 'Plain Text'
    },
    {
      value: MarkupType.HTML,
      name: 'HTML'
    },
    {
      value: MarkupType.Markdown,
      name: 'Markdown'
    }
  ];
  richTextTypes: MarkupType[] = [MarkupType.HTML, MarkupType.Markdown];

  constructor(public themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  get markupOption(): Option<MarkupType> {
    return isSet(this.typeControl.value)
      ? this.typeOptions.find(item => item.value == this.typeControl.value)
      : undefined;
  }

  setOpened(value: boolean) {
    this.opened = value;
    this.cd.markForCheck();
  }

  save(value: Delta) {
    this.valueControl.patchValue(value);
  }
}

<!--<div class="step-form__item-layout">-->
<!--  <div class="step-form__item-layout__side">-->
<!--    <div class="step-form__item-title">For Django</div>-->
<!--    <div class="step-form__item-description">-->
<!--      Its the most simple way to try and use Jet Admin for Django based projects.-->
<!--      Installed the same way as most Django packages. It will work even with your local application on localhost.-->
<!--    </div>-->
<div class="step-form__item-label">Requirements:</div>
<ul class="step-form__item-requirements">
  <li class="step-form__item-requirements-item"><strong>Python</strong> 3.4+
  <li class="step-form__item-requirements-item"><strong>Django</strong> 1.11+
  <li class="step-form__item-requirements-item"><strong>localhost</strong> or <strong>web server</strong> with an external IP</li>
</ul>
<!--  </div>-->
<!--  <div class="step-form__item-layout__main">-->
<div class="step-form__item-title">Installation</div>
<div class="step-form__item-instruction">
  <p>
    If you have any questions, please refer to our <br>
    <a [appDocumentation]="'install/django'" target="_blank">Documentation</a>
  </p>
  <p>
    1. Download and install latest version of <strong>Jet Bridge</strong> for <strong>Django</strong>:
  </p>

<app-code [language]="'bash'">pip install jet-django -U</app-code>

  <p>
    2. Add <mark>jet_django</mark> application to the <mark>INSTALLED_APPS</mark> setting
    inside <strong>settings.py</strong> file:
  </p>

<app-code [language]="'python'">INSTALLED_APPS = (
  ...
  'jet_django',
  ...
)</app-code>

  <p>
    3. Set <mark>JET_PROJECT</mark> and <mark>JET_TOKEN</mark> inside <strong>settings.py</strong> file:
  </p>

<app-code *ngIf="project && token" [language]="'python'">JET_PROJECT = '{{project.uniqueName}}'
JET_TOKEN = '{{token}}'<ng-container *ngIf="environmentName">
JET_ENVIRONMENT = '{{environmentName}}'</ng-container></app-code>

  <p>4. Add URL-pattern to the <strong>urls.py</strong> file:</p>

<app-code [language]="'python'">from django.urls import path, include

urlpatterns = [
  ...
  path('jet_api/', include('jet_django.urls')),
  ...
]</app-code>

  <p>If you use <strong>Django < 2.0</strong>:</p>

<app-code [language]="'python'">from django.conf.urls import url, include

urlpatterns = [
  ...
  url(r'^jet_api/', include('jet_django.urls')),
  ...
]</app-code>

  <p>
    5. Restart your project
  </p>

  <p>
    6. Finish installation by opening in your browser (usually it opens automatically):<br>
    <strong>localhost</strong> is your <strong>Django</strong> HOST and <strong>8000</strong> is its PORT.
  </p>

<app-code [language]="'bash'">http://localhost:8000/jet_api/register/</app-code>

</div>
<!--  </div>-->
<!--</div>-->

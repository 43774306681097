var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import defaults from 'lodash/defaults';
import { map } from 'rxjs/operators';
import { FieldType } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { RelationDirection } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { isSet } from '@shared';
var ModelOptionsSource = /** @class */ (function () {
    function ModelOptionsSource(currentEnvironmentStore, modelDescriptionStore, modelService) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelService = modelService;
    }
    ModelOptionsSource.prototype.getOptions$ = function (modelDescription, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        options = defaults(options, {
            path: [],
            maxRelationsDepth: 4
        });
        return this.modelDescriptionStore.get().pipe(map(function (modelDescriptions) {
            var result = [];
            var depth = options.path.length + 1;
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
            result.push.apply(result, modelDescription.dbFields
                .filter(function (field) {
                if (!options.onlyNestedFields) {
                    return true;
                }
                if (depth == 1) {
                    return field.field == FieldType.RelatedModel;
                }
                else {
                    return true;
                }
            })
                .map(function (field) {
                var relatedModelDescription;
                if (field.field == FieldType.RelatedModel &&
                    options.relationsEnabled &&
                    depth < options.maxRelationsDepth) {
                    var relatedModelId_1 = field.params ? field.params['related_model']['model'] : undefined;
                    var relatedModel_1 = relatedModelId_1
                        ? modelDescriptions.find(function (item) { return item.isSame(relatedModelId_1); })
                        : undefined;
                    var relatedResource = relatedModel_1
                        ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == relatedModel_1.resource; })
                        : undefined;
                    if (_this.modelService.isGetAdvSupported(resource, modelDescription) &&
                        resource.isSameDatabase(modelDescription, relatedResource, relatedModel_1)) {
                        relatedModelDescription = relatedModel_1;
                    }
                }
                return {
                    name: field.name,
                    verboseName: field.verboseName || field.name,
                    icon: field.fieldDescription.icon,
                    field: field,
                    modelDescription: modelDescription,
                    relatedModelDescription: relatedModelDescription
                };
            }));
            if (options.relationsEnabled) {
                result.push.apply(result, modelDescription
                    .allRelations()
                    .filter(function (relation) { return relation.direction == RelationDirection.OneToMany; })
                    .filter(function () { return !isSet(options.maxRelationsDepth) || depth < options.maxRelationsDepth; })
                    .map(function (relation) {
                    var relatedModel = modelDescriptions.find(function (item) { return item.isSame(relation.relatedModel); });
                    var relatedResource = relatedModel
                        ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == relatedModel.resource; })
                        : undefined;
                    if (!relatedResource ||
                        !relatedModel ||
                        !_this.modelService.isGetAdvSupported(resource, modelDescription) ||
                        !resource.isSameDatabase(modelDescription, relatedResource, relatedModel)) {
                        return;
                    }
                    var localField = modelDescription.dbField(relation.localField);
                    var relatedField = relatedModel.dbField(relation.relatedField);
                    if (!localField || !relatedField) {
                        return;
                    }
                    var isPrimaryKeyRelation = modelDescription.primaryKeyField == relation.localField;
                    return __assign({ name: relation.name, verboseName: relatedModel.verboseNamePlural }, (isPrimaryKeyRelation
                        ? { additional: relatedField.verboseName || relatedField.name }
                        : {
                            description: [
                                relatedField.verboseName || relatedField.name,
                                localField.verboseName || localField.name
                            ].join(' ↔ ')
                        }), { icon: 'lookup', relation: relation, modelDescription: modelDescription, relatedModelDescription: relatedModel });
                })
                    .filter(function (item) { return item; }));
            }
            return result;
        }));
    };
    return ModelOptionsSource;
}());
export { ModelOptionsSource };

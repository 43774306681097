import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { RatingFieldDataParamsForm } from './rating-field-data-params.form';

@Component({
  selector: 'app-rating-field-data-params',
  templateUrl: './rating-field-data-params.component.html',
  providers: [RatingFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;

  constructor(public form: RatingFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.Rating, RatingFieldDataParamsComponent);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/localize/pipes/localize/localize.pipe";
import * as i2 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i3 from "../../../fields/components/auto-field/auto-field.component";
import * as i4 from "./filter-edit-value.component";
var styles_FilterEditValueComponent = [];
var RenderType_FilterEditValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterEditValueComponent, data: {} });
export { RenderType_FilterEditValueComponent as RenderType_FilterEditValueComponent };
export function View_FilterEditValueComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.LocalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-auto-field", [], null, null, null, i2.View_AutoFieldComponent_0, i2.RenderType_AutoFieldComponent)), i0.ɵdid(2, 770048, null, 0, i3.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], errors: [3, "errors"], autofocus: [4, "autofocus"] }, null), i0.ɵppd(3, 1), i0.ɵpod(4, { name: 0, field: 1, placeholder: 2, params: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 4, 0, "control", _co.valueField.field, i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v, 0), "Enter value...")), _co.valueField.params)); var currVal_2 = false; var currVal_3 = false; var currVal_4 = _co.autoFocus; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FilterEditValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-filter-edit-value", [], null, null, null, View_FilterEditValueComponent_0, RenderType_FilterEditValueComponent)), i0.ɵdid(1, 638976, null, 0, i4.FilterEditValueComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterEditValueComponentNgFactory = i0.ɵccf("app-filter-edit-value", i4.FilterEditValueComponent, View_FilterEditValueComponent_Host_0, { control: "control", valueField: "valueField", autoFocus: "autoFocus" }, {}, []);
export { FilterEditValueComponentNgFactory as FilterEditValueComponentNgFactory };

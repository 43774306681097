/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../login/login.component.ngfactory";
import * as i2 from "../login/login.form";
import * as i3 from "../../../../common/form-utils/services/form-utils";
import * as i4 from "@angular/forms";
import * as i5 from "../../../auth/services/auth/auth.service";
import * as i6 from "../../../api/services/api/api.service";
import * as i7 from "../login/login.component";
import * as i8 from "../../../routing/services/routing/routing.service";
import * as i9 from "../../../users/stores/current-user.store";
import * as i10 from "@angular/router";
import * as i11 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i12 from "../../../analytics/services/google-analytics/google-analytics.service";
import * as i13 from "../../../../core/services/app-config/app-config.service";
import * as i14 from "../../../../common/notifications/services/notification/notification.service";
import * as i15 from "../../../meta/services/meta.service";
import * as i16 from "../../../projects/stores/projects.store";
import * as i17 from "../../../projects/services/project-invite/project-invite.service";
import * as i18 from "../../../projects/services/project-public-invite/project-public-invite.service";
import * as i19 from "./login-route.component";
var styles_LoginRouteComponent = [];
var RenderType_LoginRouteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginRouteComponent, data: {} });
export { RenderType_LoginRouteComponent as RenderType_LoginRouteComponent };
export function View_LoginRouteComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-login", [], null, null, null, i1.View_LoginComponent_0, i1.RenderType_LoginComponent)), i0.ɵprd(512, null, i2.LoginForm, i2.LoginForm, [i3.FormUtils, i4.FormBuilder, i5.AuthService, i6.ApiService]), i0.ɵdid(2, 245760, null, 0, i7.LoginComponent, [i2.LoginForm, i8.RoutingService, i9.CurrentUserStore, i10.ActivatedRoute, i11.UniversalAnalyticsService, i12.GoogleAnalyticsService, i13.AppConfigService, i5.AuthService, i14.NotificationService, i15.MetaService, i16.ProjectsStore, i6.ApiService, i17.ProjectInviteService, i18.ProjectPublicInviteService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LoginRouteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login-route", [], null, null, null, View_LoginRouteComponent_0, RenderType_LoginRouteComponent)), i0.ɵdid(1, 245760, null, 0, i19.LoginRouteComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginRouteComponentNgFactory = i0.ɵccf("app-login-route", i19.LoginRouteComponent, View_LoginRouteComponent_Host_0, {}, {}, []);
export { LoginRouteComponentNgFactory as LoginRouteComponentNgFactory };

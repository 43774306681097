import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';

import {
  ConditionWorkflowStep,
  ConditionWorkflowStepItem,
  ConditionWorkflowStepType,
  WorkflowStepType
} from '@modules/workflow';
import { TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

@Component({
  selector: 'app-condition-workflow-step',
  templateUrl: './condition-workflow-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConditionWorkflowStepComponent extends WorkflowStepComponent<ConditionWorkflowStep>
  implements OnInit, OnChanges {
  conditionTypeLabel: string;
  conditionTypes = ConditionWorkflowStepType;
  icon: string;

  trackStepItem = (() => {
    return (i, item: ConditionWorkflowStepItem) => {
      return item ? item.uid : i;
    };
  })();

  constructor(workflowEditContext: WorkflowEditContext, cd: ChangeDetectorRef) {
    super(undefined, workflowEditContext, cd);
  }

  ngOnInit() {
    this.updateIcon();
    this.updateConditionTypeLabel();
    this.trackExecuteStatus({ successOnStart: true });

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<ConditionWorkflowStepComponent>): void {
    if (changes.step) {
      this.updateIcon();
      this.updateConditionTypeLabel();
    }
  }

  updateIcon() {
    this.icon = this.step.getIcon();
  }

  updateConditionTypeLabel() {
    if (this.step.conditionType == ConditionWorkflowStepType.Boolean) {
      this.conditionTypeLabel = 'Yes/No';
    } else if (this.step.conditionType == ConditionWorkflowStepType.Switch) {
      this.conditionTypeLabel = 'Switch';
    } else {
      this.conditionTypeLabel = undefined;
    }

    this.cd.markForCheck();
  }
}

registerWorkflowStepComponent(WorkflowStepType.Condition, ConditionWorkflowStepComponent);

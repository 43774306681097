import { AggregateFunc } from '@modules/charts';
import { ValueWidgetQuery } from '@modules/queries';
import { isSet } from '@shared';

import { DataSource } from './data-source';

export class ValueWidgetDataSource extends DataSource<ValueWidgetQuery> {
  public static queryCls = ValueWidgetQuery;
  public column: string;
  public func: AggregateFunc;

  constructor(options: Partial<ValueWidgetDataSource> = {}) {
    super(options);

    if (options.hasOwnProperty('column')) {
      this.column = options.column;
    }

    if (options.hasOwnProperty('func')) {
      this.func = options.func;
    }
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    this.column = data['column'];
    this.func = data['func'];

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      column: this.column,
      func: this.func
    };
  }

  isConfigured(options: { columnsOptional?: boolean } = {}): boolean {
    const isCount = this.func == AggregateFunc.Count;

    return (
      super.isConfigured() &&
      (isCount || (isSet(this.func) && !isCount && isSet(this.column)) || (!isSet(this.func) && isSet(this.column)))
    );
  }
}

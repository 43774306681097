import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BlobClient, BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { from, merge, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { ResourceController } from '../../data/resource-controller';
var AzureBlobResourceController = /** @class */ (function (_super) {
    __extends(AzureBlobResourceController, _super);
    function AzureBlobResourceController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AzureBlobResourceController.prototype.getBlobUrl = function (account, container, blob) {
        return "https://" + account + ".blob.core.windows.net/" + container + "/" + blob;
    };
    AzureBlobResourceController.prototype.getBlobServiceClient = function (account, sas) {
        return new BlobServiceClient("https://" + account + ".blob.core.windows.net" + sas);
    };
    AzureBlobResourceController.prototype.getContainerClient = function (account, sas, container) {
        return new ContainerClient("https://" + account + ".blob.core.windows.net/" + container + sas);
    };
    AzureBlobResourceController.prototype.getBlobClient = function (account, sas, container, blob) {
        return new BlobClient("https://" + account + ".blob.core.windows.net/" + container + "/" + blob + sas);
    };
    AzureBlobResourceController.prototype.listContainers = function (account, sas) {
        var blobServiceClient = this.getBlobServiceClient(account, sas);
        return from(blobServiceClient.listContainers().byPage().next()).pipe(map(function (result) {
            return result.value.containerItems.map(function (item) { return item.name; });
        }));
    };
    AzureBlobResourceController.prototype.supportedQueryTypes = function (queryClass) {
        return [QueryType.Simple, QueryType.Http];
    };
    AzureBlobResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var _this = this;
        var account = resource.params['account'];
        var sas = resource.params['sas'];
        var container = storage.name;
        var blobFileName = isSet(fileName) ? fileName : String(file.name);
        var blobName = [path, blobFileName].join('');
        var client = this.getContainerClient(account, sas, container);
        var progressObs = new Subject();
        var uploadObs = from(client.uploadBlockBlob(blobName, file, file.size, {
            onProgress: function (progress) {
                progressObs.next({
                    state: {
                        uploadProgress: progress.loadedBytes / file.size,
                        downloadProgress: 0,
                        uploadLoaded: progress.loadedBytes,
                        uploadTotal: file.size
                    }
                });
            }
        })).pipe(map(function () {
            var url = _this.getBlobUrl(account, container, blobName);
            return {
                result: {
                    uploadedPath: url,
                    uploadedUrl: url
                },
                state: {
                    downloadProgress: 1,
                    uploadProgress: 1
                }
            };
        }));
        return merge(progressObs, uploadObs);
    };
    return AzureBlobResourceController;
}(ResourceController));
export { AzureBlobResourceController };

<div *ngIf="!editing" class="title" (click)="onClick()">
  <div [class.title__editable]="isEditable">
    <ng-container *ngIf="element.title">{{ element.title }}</ng-container>
    <ng-container *ngIf="!element.title">Empty title</ng-container>
  </div>
</div>
<div *ngIf="editing" class="title title_editing">
  <div class="inline-actions-container">
    <input
      class="title-input"
      (mousedown)="$event.stopPropagation()"
      [(ngModel)]="editingValue"
      [appAutofocus]="true"
      (keyup)="onKeyUp($event)"
      (blur)="saveEditing()"
    />
    <span class="inline-actions">
      <a href="javascript:void(0)" class="inline-actions__item icon icon-check_2" (click)="saveEditing()"></a>
      <a href="javascript:void(0)" class="inline-actions__item icon icon-close" (click)="cancelEditing()"></a>
    </span>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SingletonStore } from '@shared';

import { ProjectGroup } from '../data/project-user';
import { ProjectGroupService } from '../services/project-group/project-group.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class ProjectGroupStore extends SingletonStore<ProjectGroup[]> {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectGroupService: ProjectGroupService
  ) {
    super();
  }

  protected fetch(): Observable<ProjectGroup[]> {
    return this.projectGroupService.get(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName
    );
  }
}

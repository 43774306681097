import { OnDestroy } from '@angular/core';
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { HttpParameter } from '@modules/queries';
import { isSet } from '@shared';

import { HttpParameterControl, HttpParameterControlState } from './http-parameter.control';

export class HttpParameterArray extends FormArray implements OnDestroy {
  controls: HttpParameterControl[];
  typeEnabled = false;

  constructor(controls: HttpParameterControl[]) {
    super(controls);

    this.addParameterIfNeeded();

    this.valueChanges.pipe(debounceTime(60), untilDestroyed(this)).subscribe(() => {
      this.addParameterIfNeeded();
      this.removeParameterIfNeeded();
    });
  }

  ngOnDestroy(): void {}

  serialize(): HttpParameter[] {
    return this.controls
      .filter(item => isSet(item.controls.name.value))
      .map(item => {
        return {
          name: item.controls.name.value,
          value: item.controls.value.value,
          ...(this.typeEnabled && { type: item.controls.type.value })
        };
      });
  }

  setValue(value: HttpParameter[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    const controls = value.map(item => this.addParameter(item));
    this.setControls(controls);
  }

  patchValue(value: HttpParameter[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    const controls = value.map(item => this.addParameter(item));
    this.setControls(controls);
  }

  setControls(controls: HttpParameterControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
    this.addParameterIfNeeded();
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  addParameter(value: HttpParameterControlState = {}): HttpParameterControl {
    const control = new HttpParameterControl(value);
    this.push(control);
    return control;
  }

  addParameterIfNeeded(): HttpParameterControl {
    const lastControl = this.controls.length ? this.controls[this.controls.length - 1] : undefined;

    if (!lastControl || lastControl.isSet()) {
      return this.addParameter();
    }
  }

  removeParameterIfNeeded() {
    const preLastControl = this.controls[this.controls.length - 2];
    const lastControl = this.controls[this.controls.length - 1];

    if (preLastControl && !preLastControl.isSet() && lastControl && !lastControl.isSet()) {
      this.removeAt(this.controls.length - 1);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import range from 'lodash/range';
var ChartWidgetEditFormDatasetArray = /** @class */ (function (_super) {
    __extends(ChartWidgetEditFormDatasetArray, _super);
    function ChartWidgetEditFormDatasetArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartWidgetEditFormDatasetArray.prototype.set = function (groups) {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
        groups.forEach(function (item) { return _this.push(item); });
        this.updateValueAndValidity();
    };
    ChartWidgetEditFormDatasetArray.prototype.append = function (group) {
        this.push(group);
        this.updateValueAndValidity();
    };
    ChartWidgetEditFormDatasetArray.prototype.remove = function (group) {
        var index = this.controls.findIndex(function (item) { return item === group; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    ChartWidgetEditFormDatasetArray.prototype.copy = function (group) {
        this.push(cloneDeep(group));
        this.updateValueAndValidity();
    };
    return ChartWidgetEditFormDatasetArray;
}(FormArray));
export { ChartWidgetEditFormDatasetArray };

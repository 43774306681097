import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

import { isSet, TypedChanges } from '@shared';

import { defaultTipOptions, TipComponent, TipOptions } from '../../components/tip/tip.component';

@Directive({
  selector: '[appTip]'
})
export class TipDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appTip') content: string | TemplateRef<any>;
  @Input('appTipOptions') set optionsRaw(options: TipOptions) {
    this.options = defaults(options, defaultTipOptions);
  }
  @Input('appTipOrigin') origin: Element;

  options: TipOptions = defaultTipOptions;
  componentRef: ComponentRef<TipComponent>;
  subscriptions: Subscription[] = [];
  tipHover$ = new BehaviorSubject<boolean>(false);
  observer: MutationObserver;
  setOriginHover = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    const originHover$ = merge(
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseenter').pipe(map(() => true)),
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseleave').pipe(map(() => false)),
      this.setOriginHover
    ).pipe(
      debounce(hover => {
        const defaultShowDelay = 0;
        const defaultHideDelay = this.options.hoverable ? 100 : 0;
        const showDelay = this.options.showDelay || defaultShowDelay;
        const hideDelay = this.options.hideDelay || defaultHideDelay;
        return timer(hover ? showDelay : hideDelay);
      })
    );

    combineLatest(originHover$, this.tipHover$)
      .pipe(
        map(([hover, tipHover]) => hover || tipHover),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(hover => {
        if (hover) {
          this.show();
        } else {
          this.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnChanges(changes: TypedChanges<TipDirective>): void {
    if (changes.content && !changes.content.firstChange) {
      if (isSet(this.content)) {
        if (this.componentRef) {
          this.componentRef.instance.setContent(this.content);
        }
      } else {
        this.hide();
      }
    }
  }

  show() {
    this.destroy();

    if (!isSet(this.content)) {
      return;
    }

    const portalHost = new DomPortalHost(document.body, this.resolver, this.appRef, this.injector);
    const portal = new ComponentPortal<TipComponent>(TipComponent);
    const componentRef = portalHost.attach(portal);
    const subscriptions: Subscription[] = [];

    componentRef.instance.content = this.content;
    componentRef.instance.options = this.options;
    componentRef.instance.origin = this.origin || this.el.nativeElement;

    if (this.options.hoverable) {
      subscriptions.push(
        merge(
          fromEvent<MouseEvent>(componentRef.instance.root.nativeElement, 'mouseenter').pipe(map(() => true)),
          fromEvent<MouseEvent>(componentRef.instance.root.nativeElement, 'mouseleave').pipe(map(() => false))
        )
          .pipe(
            debounce(hover => timer(hover ? 0 : 100)),
            untilDestroyed(this)
          )
          .subscribe(hover => {
            this.tipHover$.next(hover);
          })
      );
    } else {
      this.tipHover$.next(false);
    }

    subscriptions.push(
      componentRef.instance.closed.pipe(untilDestroyed(this)).subscribe(() => {
        componentRef.destroy();
        subscriptions.forEach(item => item.unsubscribe());

        if (this.componentRef === componentRef) {
          this.componentRef = undefined;
          this.subscriptions = undefined;
        }
      })
    );

    this.componentRef = componentRef;
    this.subscriptions = subscriptions;

    if (this.options.observeRemoveFromDom) {
      this.initObserver();
    }
  }

  hide() {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.instance.close();
  }

  destroy() {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.destroy();
    this.destroyObserver();
    this.subscriptions.forEach(item => item.unsubscribe());

    this.componentRef = undefined;
    this.subscriptions = undefined;
  }

  initObserver() {
    this.destroyObserver();

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const nodes = Array.from(mutation.removedNodes);

        if (nodes.includes(this.el.nativeElement) || nodes.some(parent => parent.contains(this.el.nativeElement))) {
          this.setOriginHover.next(false);
          this.tipHover$.next(false);
          this.hide();
        }
      });
    });
    this.observer.observe(document.body, { subtree: true, childList: true });
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, Validators } from '@angular/forms';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { AdminMode } from '@modules/admin-mode';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore, SecretTokenService } from '@modules/projects';
import { googleDriveFileUidParam, GoogleSheetsGeneratorService } from '@modules/resource-generators';
import { GoogleSheetsFileControl } from '../../resource-settings/google-sheets-resource-settings/google-sheets-resource-settings.form';
export var validateExisting = function (control) {
    if (!control.parent) {
        return;
    }
    var fileControl = control.parent;
    if (!fileControl.parent) {
        return;
    }
    var parent = fileControl.parent;
    var uniqueName = parent.googleSheetsGeneratorService.getUniqueName(control.value);
    var files = parent.controls.files.value;
    if (uniqueName &&
        files.find(function (item) { return parent.googleSheetsGeneratorService.getUniqueName(item.verbose_name) == uniqueName; })) {
        return { local: ['File with such name already added'] };
    }
};
var GoogleSheetsImportFileForm = /** @class */ (function (_super) {
    __extends(GoogleSheetsImportFileForm, _super);
    function GoogleSheetsImportFileForm(mode, currentProjectStore, currentEnvironmentStore, googleSheetsGeneratorService, secretTokenService, formUtils, projectSettingsService, modelDescriptionStore) {
        var _this = _super.call(this, {
            access_token: new FormControl(null, Validators.required),
            params: new FormControl(null, Validators.required),
            files: new FormControl(null, Validators.required),
            file: new GoogleSheetsFileControl({ verboseNameValidator: validateExisting })
        }) || this;
        _this.mode = mode;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.googleSheetsGeneratorService = googleSheetsGeneratorService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.modelDescriptionStore = modelDescriptionStore;
        return _this;
    }
    GoogleSheetsImportFileForm.prototype.init = function (resource) {
        this.resource = resource;
        return this.initValue(this.resource);
    };
    GoogleSheetsImportFileForm.prototype.initValue = function (resource) {
        var _this = this;
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        return combineLatest(this.googleSheetsGeneratorService.getParamsOptions(project, environment, resource), this.secretTokenService.getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, this.googleSheetsGeneratorService.tokenName, this.mode == AdminMode.Builder)).pipe(tap(function (_a) {
            var options = _a[0], secretToken = _a[1];
            _this.controls.access_token.patchValue(options.access_token);
            _this.controls.params.patchValue(options.token_params);
            _this.controls.files.patchValue(options.files);
        }), map(function () { return true; }));
    };
    GoogleSheetsImportFileForm.prototype.getParams = function () {
        return this.googleSheetsGeneratorService.generateParams(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.resource.typeItem, {
            access_token: this.controls.access_token.value,
            token_params: this.controls.params.value,
            files: this.controls.files.value.concat([this.controls.file.value])
        });
    };
    GoogleSheetsImportFileForm.prototype.submit = function () {
        var _this = this;
        this.clearServerErrors();
        var params = this.getParams();
        var obs = params instanceof Observable ? params : of(params);
        return obs.pipe(switchMap(function (result) {
            var saveParams = {
                resourceParams: result.resourceParams,
                modelDescriptions: result.modelDescriptions,
                resourceModelDescriptions: result.resourceModelDescriptions,
                actionDescriptions: result.actionDescriptions,
                secretTokens: result.secretTokens,
                storages: result.storages,
                extraTokens: result.extraTokens
            };
            return _this.projectSettingsService.saveResource(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.resource, !_this.resource, __assign({}, saveParams, { mergeExisting: true }));
        }), switchMap(function () {
            return _this.modelDescriptionStore.getFirst();
        }), map(function (modelDescriptions) {
            var file = _this.controls.file.value;
            return modelDescriptions.find(function (item) { return item.resource == _this.resource.uniqueName && item.params[googleDriveFileUidParam] == file.uid; });
        }), catchError(function (error) {
            console.error(error);
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return GoogleSheetsImportFileForm;
}(AppFormGroup));
export { GoogleSheetsImportFileForm };

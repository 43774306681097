import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { InputValueType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-parameters-query',
  templateUrl: './customize-bar-action-edit-parameters-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditParametersQueryComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() analyticsSource: string;
  @Output() addQueryInput = new EventEmitter<void>();

  inputsCollapseContext = new SidebarCollapseContext();
  inputValueTypes = InputValueType;

  constructor() {}

  ngOnInit() {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { SideConstraint, SizeConstraint } from '@modules/views';

import { ConstraintsControl } from '../../controls/constraints.control';

enum Control {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  Width = 'width',
  Height = 'height'
}

@Component({
  selector: 'app-constraints-control',
  templateUrl: './constraints-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstraintsControlComponent implements OnInit {
  @Input() control: ConstraintsControl;

  controlsState: { [k: string]: { hover: boolean; changedAfterHover: boolean } } = {
    [Control.Top]: { hover: false, changedAfterHover: false },
    [Control.Right]: { hover: false, changedAfterHover: false },
    [Control.Bottom]: { hover: false, changedAfterHover: false },
    [Control.Left]: { hover: false, changedAfterHover: false },
    [Control.Width]: { hover: false, changedAfterHover: false },
    [Control.Height]: { hover: false, changedAfterHover: false }
  };
  sideConstraints = SideConstraint;
  sizeConstraints = SizeConstraint;
  controls = Control;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  onControlHover(control: Control) {
    this.controlsState[control].hover = true;
    this.controlsState[control].changedAfterHover = false;
    this.cd.markForCheck();
  }

  onControlOut(control: Control) {
    this.controlsState[control].hover = false;
    this.controlsState[control].changedAfterHover = false;
    this.cd.markForCheck();
  }

  onControlChange(control: Control) {
    this.controlsState[control].changedAfterHover = true;
    this.cd.markForCheck();
  }

  isControlHoverDisplay(control: Control): boolean {
    return this.controlsState[control].hover && !this.controlsState[control].changedAfterHover;
  }

  isSidesHover(): boolean {
    return [Control.Top, Control.Right, Control.Bottom, Control.Left].some(item => this.controlsState[item].hover);
  }
}

export const hubspotResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'companies',
    verbose_name: 'Companies',
    verbose_name_plural: 'Companies',
    hidden: false,
    fields: [
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'name',
        params: {},
        order_after: 'hs_object_id',
        visible: true
      },
      {
        name: 'domain',
        verbose_name: 'domain',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'domain',
        params: {},
        order_after: 'name',
        visible: true
      },
      {
        name: 'createdate',
        verbose_name: 'createdate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'createdate',
        params: {
          date: true,
          time: true
        },
        order_after: 'domain',
        visible: true
      },
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: false,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'hs_object_id',
        params: {},
        visible: true
      },
      {
        name: 'hs_lastmodifieddate',
        verbose_name: 'hs_lastmodifieddate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'hs_lastmodifieddate',
        params: {
          date: true,
          time: true
        },
        order_after: 'createdate',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'hs_object_id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/objects/companies',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "// return Body as Object\nconst datos = data['results']\nconst propiedades = datos.map((i) => {return i.properties})\nreturn propiedades;",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: ['paging', 'next', 'after'],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'domain',
          sortable: true
        },
        {
          name: 'createdate',
          sortable: true
        },
        {
          name: 'hs_object_id',
          sortable: true
        },
        {
          name: 'hs_lastmodifieddate',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/objects/companies/{{params.hs_object_id}}/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.hubapi.com/crm/v3/objects/companies',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "city": params.city,\n    "domain": params.domain,\n    "industry": params.industry,\n    "name": params.name,\n    "phone": params.phone,\n    "state": params.state\n  }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'domain',
        verbose_name: 'domain',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'city',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'industry',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'state',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PATCH',
        url: 'https://api.hubapi.com/crm/v3/objects/companies/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "city": params.city,\n    "domain": params.domain,\n    "industry": params.industry,\n    "name": params.name,\n    "phone": params.phone,\n    "state": params.state\n  }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'domain',
        verbose_name: 'domain',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'createdate',
        verbose_name: 'createdate',
        field: 'datetime',
        required: false,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'hs_lastmodifieddate',
        verbose_name: 'hs_lastmodifieddate',
        field: 'datetime',
        required: false,
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'city',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'industry',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'state',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.hubapi.com/crm/v3/objects/companies/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'contacts',
    verbose_name: 'Contacts',
    verbose_name_plural: 'Contacts',
    hidden: false,
    fields: [
      {
        name: 'createdate',
        verbose_name: 'createdate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'createdate',
        params: {
          date: true,
          time: true
        },
        order_after: 'lastname',
        visible: true
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'email',
        params: {},
        order_after: 'hs_object_id',
        visible: true
      },
      {
        name: 'firstname',
        verbose_name: 'firstname',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'firstname',
        params: {},
        order_after: 'email',
        visible: true
      },
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: false,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'hs_object_id',
        params: {},
        visible: true
      },
      {
        name: 'lastmodifieddate',
        verbose_name: 'lastmodifieddate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'lastmodifieddate',
        params: {
          date: true,
          time: true
        },
        order_after: 'createdate',
        visible: true
      },
      {
        name: 'lastname',
        verbose_name: 'lastname',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'lastname',
        params: {},
        order_after: 'firstname',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'hs_object_id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/objects/contacts/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "// return Body as Object\nconst datos = data['results']\nconst propiedades = datos.map((i) => {return i.properties})\nreturn propiedades;",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: ['paging', 'next', 'after'],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'createdate',
          sortable: true
        },
        {
          name: 'email',
          sortable: true
        },
        {
          name: 'firstname',
          sortable: true
        },
        {
          name: 'hs_object_id',
          sortable: true
        },
        {
          name: 'lastmodifieddate',
          sortable: true
        },
        {
          name: 'lastname',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.hubapi.com/crm/v3/objects/contacts',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "company": params.company,\n    "email": params.email,\n    "firstname": params.firstname,\n    "lastname": params.lastname,\n    "phone": params.phone,\n    "website": params.website\n  }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'Content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'firstname',
        verbose_name: 'firstname',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'lastname',
        verbose_name: 'lastname',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'phone',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'website',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'company',
        verbose_name: '',
        description: '',
        field: 'text',
        required: true,
        default_value: '',
        placeholder: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PATCH',
        url: 'https://api.hubapi.com/crm/v3/objects/contacts/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "email": params.email,\n    "firstname": params.firstname,\n    "lastname": params.lastname,\n    "company": params.phone,\n    "phone": params.phone,\n    "website": params.website\n    }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'firstname',
        verbose_name: 'firstname',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'lastname',
        verbose_name: 'lastname',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'company',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      },
      {
        name: 'website',
        verbose_name: '',
        description: '',
        field: 'text',
        required: false,
        default_value: '',
        placeholder: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.hubapi.com/crm/v3/objects/contacts/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'companies',
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'deals',
    verbose_name: 'Deals',
    verbose_name_plural: 'Deals',
    hidden: false,
    fields: [
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'amount',
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {},
        order_after: 'dealstage',
        visible: true
      },
      {
        name: 'dealname',
        verbose_name: 'dealname',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'dealname',
        params: {},
        order_after: 'hs_object_id',
        visible: true
      },
      {
        name: 'pipeline',
        verbose_name: 'pipeline',
        field: 'related_model',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'pipeline',
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipelines'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        },
        order_after: 'dealname',
        visible: true
      },
      {
        name: 'dealstage',
        verbose_name: 'dealstage',
        field: 'related_model',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'dealstage',
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipeline_stages'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        },
        order_after: 'pipeline',
        visible: true
      },
      {
        name: 'createdate',
        verbose_name: 'createdate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'createdate',
        params: {
          date: true,
          time: true
        },
        order_after: 'amount',
        visible: true
      },
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: false,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'hs_object_id',
        params: {},
        visible: true
      },
      {
        name: 'hs_lastmodifieddate',
        verbose_name: 'hs_lastmodifieddate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'hs_lastmodifieddate',
        params: {
          date: true,
          time: true
        },
        order_after: 'createdate',
        visible: false
      },
      {
        name: 'closedate',
        verbose_name: 'closedate',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'closedate',
        params: {
          date: true,
          time: true
        },
        order_after: 'hs_lastmodifieddate',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'hs_object_id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/objects/deals',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'properties',
            value: 'dealname,dealstage,pipeline,amount,hs_object_id,createdate'
          }
        ],
        response_type: 'json',
        response_transformer:
          "// return Body as Object\nconst datos = data['results']\nconst propiedades = datos.map((i) => {return i.properties})\nreturn propiedades;",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: ['paging', 'next', 'after'],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'amount',
          sortable: true
        },
        {
          name: 'dealname',
          sortable: true
        },
        {
          name: 'pipeline',
          sortable: true
        },
        {
          name: 'dealstage',
          sortable: true
        },
        {
          name: 'createdate',
          sortable: true
        },
        {
          name: 'hs_object_id',
          sortable: true
        },
        {
          name: 'hs_lastmodifieddate',
          sortable: true
        }
      ],
      frontend_filtering: false
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/objects/deals/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.hubapi.com/crm/v3/objects/deals',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "closedate": params.closedate,\n    "dealname": params.dealname,\n    "dealstage": params.dealstage,\n    "pipeline": params.pipeline\n  }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'dealname',
        verbose_name: 'dealname',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'pipeline',
        verbose_name: 'pipeline',
        field: 'related_model',
        required: true,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipelines'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        }
      },
      {
        name: 'dealstage',
        verbose_name: 'dealstage',
        field: 'text',
        required: true,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipeline_stages'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        }
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {}
      },
      {
        name: 'closedate',
        verbose_name: 'closedate',
        field: 'datetime',
        required: false,
        params: {
          date: true,
          time: true
        }
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PATCH',
        url: 'https://api.hubapi.com/crm/v3/objects/deals/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\n  "properties": {\n    "dealname": params.dealname,\n    "pipeline": params.pipeline,\n    "dealstage": params.dealstage,\n    "amount": params.amount,\n    "closedate": params.closedate\n      \n  }\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          },
          {
            name: 'content-type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'properties',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      },
      {
        name: 'dealname',
        verbose_name: 'dealname',
        field: 'text',
        required: false,
        params: {}
      },
      {
        name: 'pipeline',
        verbose_name: 'pipeline',
        field: 'related_model',
        required: false,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipelines'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        }
      },
      {
        name: 'dealstage',
        verbose_name: 'dealstage',
        field: 'text',
        required: false,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {
          create_button: true,
          related_model: {
            model: '{{resource}}.deals_pipeline_stages'
          },
          custom_primary_key: 'id',
          custom_display_field: 'label'
        }
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: false,
        validator_type: null,
        validator_params: {
          error: null,
          input: {
            name: 'value',
            field: null,
            js_value: '',
            required: false,
            value_type: null,
            filter_field: '',
            static_value: '',
            context_value: null,
            filter_lookup: '',
            formula_value: ''
          },
          regex: null,
          validator_length: null,
          validator_max_value: null,
          validator_min_value: null,
          validator_max_length: null,
          validator_min_length: null
        },
        params: {}
      },
      {
        name: 'closedate',
        verbose_name: 'closedate',
        field: 'datetime',
        required: false,
        params: {
          date: true,
          time: true
        }
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.hubapi.com/crm/v3/objects/deals/{{params.hs_object_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'hs_object_id',
        verbose_name: 'hs_object_id',
        field: 'text',
        required: true,
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'contacts',
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'deals_pipeline_stages',
    verbose_name: 'Deals Pipeline Stages',
    verbose_name_plural: 'Deals Pipeline Stages',
    fields: [
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'id',
        visible: true
      },
      {
        name: 'displayOrder',
        verbose_name: 'displayOrder',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'label',
        visible: true
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'isClosed',
                  type: 'field',
                  label: 'isClosed',
                  params: {
                    name: 'isClosed',
                    field: 'boolean',
                    label: 'isClosed',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'probability',
                  type: 'field',
                  label: 'probability',
                  params: {
                    name: 'probability',
                    field: 'number',
                    label: 'probability',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        },
        order_after: 'displayOrder',
        visible: true
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        visible: true
      },
      {
        name: 'createdAt',
        verbose_name: 'createdAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'metadata',
        visible: true
      },
      {
        name: 'updatedAt',
        verbose_name: 'updatedAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'createdAt',
        visible: true
      },
      {
        name: 'archived',
        verbose_name: 'archived',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'updatedAt',
        visible: true
      },
      {
        name: 'writePermissions',
        verbose_name: 'writePermissions',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'archived',
        visible: true
      },
      {
        name: 'pipeline_id',
        verbose_name: 'pipeline_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'writePermissions',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/pipelines/deals/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer:
          "// return Body as Object\nfunction getStageList(pipeline_id, stageList) {\n    return stageList.map((j) => {\n        j['pipeline_id'] = pipeline_id;\n        return j;\n    })\n}\n\nconst datos = data['results']\nconst propiedades = datos.map((i) => {return getStageList(i.id, i.stages)})\n\nvar result_array = [];\nfor (var i=0; i < propiedades.length; i++) {\n    result_array = result_array.concat(propiedades[i]);\n}\n\nreturn result_array;",
        request_tokens: {}
      },
      pagination: null,
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'label',
          sortable: true
        },
        {
          name: 'archived',
          sortable: true
        },
        {
          name: 'metadata',
          sortable: true
        },
        {
          name: 'createdAt',
          sortable: true
        },
        {
          name: 'updatedAt',
          sortable: true
        },
        {
          name: 'pipeline_id',
          sortable: true
        },
        {
          name: 'displayOrder',
          sortable: true
        },
        {
          name: 'writePermissions',
          sortable: true
        }
      ],
      frontend_filtering: true
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'deals',
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'deals_pipelines',
    verbose_name: 'Deals Pipelines',
    verbose_name_plural: 'Deals Pipelines',
    fields: [
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'id',
        visible: true
      },
      {
        name: 'displayOrder',
        verbose_name: 'displayOrder',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'label',
        visible: true
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        visible: true
      },
      {
        name: 'stages',
        verbose_name: 'stages',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                name: null,
                type: 'object',
                label: null,
                params: {
                  items: [
                    {
                      name: 'label',
                      type: 'field',
                      label: 'label',
                      params: {
                        name: 'label',
                        field: 'text',
                        label: 'label',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'displayOrder',
                      type: 'field',
                      label: 'displayOrder',
                      params: {
                        name: 'displayOrder',
                        field: 'number',
                        label: 'displayOrder',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'metadata',
                      type: 'object',
                      label: 'metadata',
                      params: {
                        items: [
                          {
                            name: 'isClosed',
                            type: 'field',
                            label: 'isClosed',
                            params: {
                              name: 'isClosed',
                              field: 'boolean',
                              label: 'isClosed',
                              params: {},
                              required: false
                            }
                          },
                          {
                            name: 'probability',
                            type: 'field',
                            label: 'probability',
                            params: {
                              name: 'probability',
                              field: 'number',
                              label: 'probability',
                              params: {},
                              required: false
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: 'id',
                      type: 'field',
                      label: 'id',
                      params: {
                        name: 'id',
                        field: 'text',
                        label: 'id',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'createdAt',
                      type: 'field',
                      label: 'createdAt',
                      params: {
                        name: 'createdAt',
                        field: 'datetime',
                        label: 'createdAt',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'updatedAt',
                      type: 'field',
                      label: 'updatedAt',
                      params: {
                        name: 'updatedAt',
                        field: 'datetime',
                        label: 'updatedAt',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'archived',
                      type: 'field',
                      label: 'archived',
                      params: {
                        name: 'archived',
                        field: 'boolean',
                        label: 'archived',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'writePermissions',
                      type: 'field',
                      label: 'writePermissions',
                      params: {
                        name: 'writePermissions',
                        field: 'text',
                        label: 'writePermissions',
                        params: {},
                        required: false
                      }
                    }
                  ]
                }
              }
            }
          },
          display_fields: true
        },
        order_after: 'displayOrder',
        visible: true
      },
      {
        name: 'createdAt',
        verbose_name: 'createdAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'stages',
        visible: true
      },
      {
        name: 'updatedAt',
        verbose_name: 'updatedAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'createdAt',
        visible: true
      },
      {
        name: 'archived',
        verbose_name: 'archived',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'updatedAt',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/pipelines/deals/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'results',
        request_tokens: {}
      },
      pagination: null,
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'label',
          sortable: true
        },
        {
          name: 'displayOrder',
          sortable: true
        },
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'stages',
          sortable: true
        },
        {
          name: 'createdAt',
          sortable: true
        },
        {
          name: 'updatedAt',
          sortable: true
        },
        {
          name: 'archived',
          sortable: true
        }
      ],
      frontend_filtering: true
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'deals_pipeline_stages',
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'ticket_pipelines',
    verbose_name: 'Ticket Pipelines',
    verbose_name_plural: 'Ticket Pipelines',
    fields: [
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        visible: true
      },
      {
        name: 'displayOrder',
        verbose_name: 'displayOrder',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'label',
        visible: true
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'displayOrder',
        visible: true
      },
      {
        name: 'stages',
        verbose_name: 'stages',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'array',
            label: null,
            params: {
              item: {
                name: null,
                type: 'object',
                label: null,
                params: {
                  items: [
                    {
                      name: 'label',
                      type: 'field',
                      label: 'label',
                      params: {
                        name: 'label',
                        field: 'text',
                        label: 'label',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'displayOrder',
                      type: 'field',
                      label: 'displayOrder',
                      params: {
                        name: 'displayOrder',
                        field: 'number',
                        label: 'displayOrder',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'metadata',
                      type: 'object',
                      label: 'metadata',
                      params: {
                        items: [
                          {
                            name: 'ticketState',
                            type: 'field',
                            label: 'ticketState',
                            params: {
                              name: 'ticketState',
                              field: 'text',
                              label: 'ticketState',
                              params: {},
                              required: false
                            }
                          },
                          {
                            name: 'isClosed',
                            type: 'field',
                            label: 'isClosed',
                            params: {
                              name: 'isClosed',
                              field: 'boolean',
                              label: 'isClosed',
                              params: {},
                              required: false
                            }
                          }
                        ]
                      }
                    },
                    {
                      name: 'id',
                      type: 'field',
                      label: 'id',
                      params: {
                        name: 'id',
                        field: 'text',
                        label: 'id',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'createdAt',
                      type: 'field',
                      label: 'createdAt',
                      params: {
                        name: 'createdAt',
                        field: 'datetime',
                        label: 'createdAt',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'updatedAt',
                      type: 'field',
                      label: 'updatedAt',
                      params: {
                        name: 'updatedAt',
                        field: 'datetime',
                        label: 'updatedAt',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'archived',
                      type: 'field',
                      label: 'archived',
                      params: {
                        name: 'archived',
                        field: 'boolean',
                        label: 'archived',
                        params: {},
                        required: false
                      }
                    },
                    {
                      name: 'writePermissions',
                      type: 'field',
                      label: 'writePermissions',
                      params: {
                        name: 'writePermissions',
                        field: 'text',
                        label: 'writePermissions',
                        params: {},
                        required: false
                      }
                    }
                  ]
                }
              }
            }
          },
          display_fields: true
        },
        order_after: 'id',
        visible: true
      },
      {
        name: 'createdAt',
        verbose_name: 'createdAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'stages',
        visible: true
      },
      {
        name: 'updatedAt',
        verbose_name: 'updatedAt',
        field: 'datetime',
        required: true,
        null: false,
        editable: true,
        params: {
          date: true,
          time: true
        },
        order_after: 'createdAt',
        visible: true
      },
      {
        name: 'archived',
        verbose_name: 'archived',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {},
        order_after: 'updatedAt',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/pipelines/tickets/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: 'results',
        request_tokens: {}
      },
      pagination: null,
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [],
      frontend_filtering: true
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'deals_pipelines',
    params: {}
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'tickets_pipeline_stages',
    verbose_name: 'Tickets Pipeline Stages',
    verbose_name_plural: 'Tickets Pipeline Stages',
    hidden: false,
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: false,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'id',
        params: {},
        visible: true
      },
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'label',
        params: {},
        order_after: 'id',
        visible: true
      },
      {
        name: 'archived',
        verbose_name: 'archived',
        field: 'boolean',
        db_field: 'BooleanField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'archived',
        params: {},
        order_after: 'label',
        visible: true
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'json',
        db_field: 'JSONField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'metadata',
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'ticketState',
                  type: 'field',
                  label: 'ticketState',
                  params: {
                    name: 'ticketState',
                    field: 'text',
                    label: 'ticketState',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'isClosed',
                  type: 'field',
                  label: 'isClosed',
                  params: {
                    name: 'isClosed',
                    field: 'boolean',
                    label: 'isClosed',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        },
        order_after: 'archived',
        visible: true
      },
      {
        name: 'createdAt',
        verbose_name: 'createdAt',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'createdAt',
        params: {
          date: true,
          time: true
        },
        order_after: 'metadata',
        visible: true
      },
      {
        name: 'updatedAt',
        verbose_name: 'updatedAt',
        field: 'datetime',
        db_field: 'TimestampField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'updatedAt',
        params: {
          date: true,
          time: true
        },
        order_after: 'createdAt',
        visible: true
      },
      {
        name: 'pipeline_id',
        verbose_name: 'pipeline_id',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'pipeline_id',
        params: {},
        order_after: 'updatedAt',
        visible: true
      },
      {
        name: 'displayOrder',
        verbose_name: 'displayOrder',
        field: 'number',
        db_field: 'DoublePrecisionField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'displayOrder',
        params: {},
        order_after: 'pipeline_id',
        visible: true
      },
      {
        name: 'writePermissions',
        verbose_name: 'writePermissions',
        field: 'text',
        db_field: 'CharField',
        required: true,
        null: true,
        editable: true,
        filterable: true,
        sortable: true,
        db_column: 'writePermissions',
        params: {},
        order_after: 'displayOrder',
        visible: true
      }
    ],
    flex_fields: [],
    custom_fields: [],
    segments: [],
    primary_key_field: 'id',
    query_type: 'http',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.hubapi.com/crm/v3/pipelines/tickets/',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-oauth_access_token-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer:
          "// return Body as Object\nfunction getStageList(pipeline_id, stageList) {\n    return stageList.map((j) => {\n        j['pipeline_id'] = pipeline_id;\n        return j;\n    })\n}\n\nconst datos = data['results']\nconst propiedades = datos.map((i) => {return getStageList(i.id, i.stages)})\n\nvar result_array = [];\nfor (var i=0; i < propiedades.length; i++) {\n    result_array = result_array.concat(propiedades[i]);\n}\n\nreturn result_array;",
        request_tokens: {}
      },
      pagination: null,
      pagination_total_path: [],
      pagination_cursor_prev_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'label',
          sortable: true
        },
        {
          name: 'displayOrder',
          sortable: true
        },
        {
          name: 'metadata',
          sortable: true
        },
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'createdAt',
          sortable: true
        },
        {
          name: 'updatedAt',
          sortable: true
        },
        {
          name: 'archived',
          sortable: true
        },
        {
          name: 'writePermissions',
          sortable: true
        },
        {
          name: null,
          sortable: null
        }
      ],
      frontend_filtering: true
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    order_after: 'ticket_pipelines',
    params: {}
  }
];

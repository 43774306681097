import { Injectable } from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';

import { RawListViewSettingsColumn } from '@modules/customize';
import { ColumnsDescription } from '@modules/resources';
import { aggregateObjects, interpolate, isSet } from '@shared';

// TODO: Refactor import
import { BaseField } from '../../../fields/data/base-field';
import { defaultFieldType } from '../../../fields/data/field-type';
import { detectFieldByValue } from '../../../fields/utils/detect-field-by-value';

import { HttpQuery } from '../../data/http-query';
import { Query } from '../../data/query';
import { QueryType } from '../../data/query-type';

export const NO_KEY_ATTRIBUTE = 'result';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  constructor() {}

  applyTokens(template: string, tokens = {}, raiseErrors = false, disableInterpolate = false): string {
    if (typeof template == 'string') {
      const ctx = tokens ? tokens : {};
      try {
        return interpolate(template, ctx);
      } catch (e) {
        console.error(e);
        if (raiseErrors) {
          throw e;
        } else {
          return template;
        }
      }
    } else {
      return template;
    }
  }

  applyTransformer(value, transformer: string, url: string, raiseErrors = false, tokens?: Object) {
    tokens = tokens || {};

    if (transformer) {
      try {
        const x = new Function('data', 'url', ...keys(tokens), transformer);
        return x(value, url, ...values(tokens));
      } catch (e) {
        console.error(e, { transformer, value, tokens });
        if (raiseErrors) {
          throw e;
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  }

  getPath(value: any, pathStr: string): any {
    if (!isSet(value) || !isSet(pathStr)) {
      return value;
    }

    let result = value;
    const path = pathStr.split('.').filter(item => isSet(item));

    for (const item of path) {
      if (!isSet(result) || !result.hasOwnProperty(item)) {
        return;
      }

      result = result[item];
    }

    return result;
  }

  getResponseColumns(query: Query): ColumnsDescription {
    if (query.queryType == QueryType.SQL && query.sqlQuery) {
      return query.sqlQuery.requestResponseColumns;
    }
  }

  columnDescriptionToFields(columnsDescription: ColumnsDescription): BaseField[] {
    if (!columnsDescription) {
      return;
    }

    return toPairs(columnsDescription).map(([name, description]) => {
      return {
        name: name,
        verboseName: name,
        field: description.field || defaultFieldType,
        params: {}
      };
    });
  }

  getResponseProcessed(query: Query): any {
    if (query.queryType == QueryType.Http && query.httpQuery) {
      return this.getHttpResponseProcessed(query.httpQuery);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      return query.sqlQuery.requestResponse;
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      return query.objectQuery.requestResponse;
    }
  }

  getHttpResponseProcessed(query: HttpQuery): any {
    let response = query.requestResponse;

    response = this.applyTransformer(response, query.responseTransformer, query.url, false, query.requestTokens);
    response = this.getPath(response, query.responsePath);

    return response;
  }

  public autoDetectObjectFields(obj: Object): BaseField[] {
    if (!obj) {
      return;
    }

    return toPairs(obj).map(([k, v]) => {
      const valueField = detectFieldByValue(v);
      return {
        name: k,
        verboseName: k,
        field: valueField.field,
        params: valueField.params || {}
      };
    });
  }

  public autoDetectGetFields(data: Object[]): BaseField[] {
    if (data && isArray(data)) {
      if (!data.length) {
        return [];
      }

      if (!data.every(item => isPlainObject(item))) {
        const firstData = data.find(item => !isPlainObject(item));
        const valueField = detectFieldByValue(firstData);
        return [
          {
            name: NO_KEY_ATTRIBUTE,
            verboseName: 'data',
            field: valueField.field,
            params: valueField.params || {}
          }
        ];
      }

      const aggregatedData = aggregateObjects(data);

      return this.autoDetectObjectFields(aggregatedData);
    } else if (data && isPlainObject(data)) {
      return this.autoDetectObjectFields(data);
    } else if (data && !isPlainObject(data)) {
      const valueField = detectFieldByValue(data);
      return [
        {
          name: NO_KEY_ATTRIBUTE,
          verboseName: 'data',
          field: valueField.field,
          params: valueField.params || {}
        }
      ];
    }
  }

  public autoDetectGetDetailFields(data: any): BaseField[] {
    if (data && isArray(data)) {
      if (!data.length) {
        return [];
      }

      if (!data.every(item => isPlainObject(item))) {
        const firstData = data.find(item => !isPlainObject(item));
        const valueField = detectFieldByValue(firstData);
        return [
          {
            name: NO_KEY_ATTRIBUTE,
            verboseName: 'data',
            field: valueField.field,
            params: valueField.params || {}
          }
        ];
      }

      const aggregatedData = aggregateObjects(data);

      return this.autoDetectGetDetailFields(aggregatedData);
    } else if (data && isPlainObject(data)) {
      return this.autoDetectObjectFields(data);
    } else if (data && !isPlainObject(data)) {
      const valueField = detectFieldByValue(data);
      return [
        {
          name: NO_KEY_ATTRIBUTE,
          verboseName: 'data',
          field: valueField.field,
          params: valueField.params || {}
        }
      ];
    }
  }

  autoDetectColumns(response: any, isDataArray: boolean): RawListViewSettingsColumn[] {
    const result = isDataArray ? this.autoDetectGetFields(response) : this.autoDetectGetDetailFields(response);

    if (!result) {
      return;
    }

    return result;
  }

  getAutoDetectColumns(query: Query, isDataArray: boolean): RawListViewSettingsColumn[] {
    const columnDescription = this.getResponseColumns(query);

    if (columnDescription) {
      return this.columnDescriptionToFields(columnDescription);
    }

    const response = this.getResponseProcessed(query);
    return this.autoDetectColumns(response, isDataArray);
  }

  isQueryArrayResponse(query: Query): boolean {
    if (query.queryType == QueryType.Http && query.httpQuery) {
      return isArray(this.getHttpResponseProcessed(query.httpQuery));
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      return true;
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      return isArray(query.objectQuery.requestResponse);
    }
  }
}

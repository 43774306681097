import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DynamicComponentModule } from '@common/dynamic-component';
import { FixedComponentModule } from '@common/fixed-component';
import { TipsModule } from '@common/tips';
import { AdminHeaderModule } from '@modules/admin-header';
import { AnalyticsModule } from '@modules/analytics';
import { CalendarModule } from '@modules/calendar';
import { CustomizeBarModule } from '@modules/customize-bar';
import { DemoModule } from '@modules/demo';
import { GridModule } from '@modules/grid';
import { KanbanBoardModule } from '@modules/kanban-board';
import { MapModule } from '@modules/map';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { TimelineModule } from '@modules/timeline';
import { SharedModule } from '@shared';

import { ListCustomizeBarComponent } from './components/list-customize-bar/list-customize-bar.component';
import { ListRouteComponent } from './components/list-route/list-route.component';
import { ListComponent } from './components/list/list.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    NgGxScrollableModule,
    TipsModule,
    TableModule,
    CalendarModule,
    GridModule,
    KanbanBoardModule,
    MapModule,
    TimelineModule,
    DemoModule,
    DynamicComponentModule,
    CustomizeBarModule,
    SharedModule,
    FixedComponentModule,
    AnalyticsModule,
    AdminHeaderModule
  ],
  declarations: [ListComponent, ListCustomizeBarComponent, ListRouteComponent],
  exports: [ListComponent, ListRouteComponent]
})
export class ListRoutesModule {}

var _a, _b;
export var DateFormat;
(function (DateFormat) {
    DateFormat["Local"] = "local";
    DateFormat["Friendly"] = "friendly";
    DateFormat["US"] = "us";
    DateFormat["European"] = "european";
    DateFormat["ISO"] = "iso";
    DateFormat["Custom"] = "custom";
})(DateFormat || (DateFormat = {}));
export var defaultDateFormat = DateFormat.Local;
export var momentDateFormats = (_a = {},
    _a[DateFormat.Local] = 'L',
    _a[DateFormat.Friendly] = 'LL',
    _a[DateFormat.US] = 'MM/DD/YYYY',
    _a[DateFormat.European] = 'DD/MM/YYYY',
    _a[DateFormat.ISO] = 'YYYY-MM-DD',
    _a);
export var momentDateEditFormats = (_b = {},
    _b[DateFormat.Local] = 'L',
    _b[DateFormat.Friendly] = 'L',
    _b[DateFormat.US] = 'MM/DD/YYYY',
    _b[DateFormat.European] = 'DD/MM/YYYY',
    _b[DateFormat.ISO] = 'YYYY-MM-DD',
    _b);

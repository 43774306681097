var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OverlayRef } from '@angular/cdk/overlay';
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { ACTION_SERVICE_ACTION_MENU_COMPONENT, ActionServiceActionMenuComponent } from '@modules/action-queries';
export var ACTION_SERVICE_ACTION_MENU_COMPONENT_PROVIDER = {
    provide: ACTION_SERVICE_ACTION_MENU_COMPONENT,
    useFactory: actionServiceActionMenuComponentFactory
};
export function actionServiceActionMenuComponentFactory() {
    return ActionMenuComponent;
}
var ActionMenuComponent = /** @class */ (function (_super) {
    __extends(ActionMenuComponent, _super);
    function ActionMenuComponent(overlayRef) {
        var _this = _super.call(this) || this;
        _this.overlayRef = overlayRef;
        return _this;
    }
    ActionMenuComponent.prototype.ngOnInit = function () { };
    ActionMenuComponent.prototype.ngOnDestroy = function () { };
    ActionMenuComponent.prototype.close = function () {
        this.overlayRef.dispose();
    };
    return ActionMenuComponent;
}(ActionServiceActionMenuComponent));
export { ActionMenuComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sidebar-card.component";
var styles_SidebarCardComponent = [];
var RenderType_SidebarCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarCardComponent, data: {} });
export { RenderType_SidebarCardComponent as RenderType_SidebarCardComponent };
export function View_SidebarCardComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-card"]], [[2, "sidebar-card_interactive", null], [2, "sidebar-card_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-card__inner"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.interactive; var currVal_1 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SidebarCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-card", [], null, null, null, View_SidebarCardComponent_0, RenderType_SidebarCardComponent)), i0.ɵdid(1, 114688, null, 0, i1.SidebarCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarCardComponentNgFactory = i0.ɵccf("app-sidebar-card", i1.SidebarCardComponent, View_SidebarCardComponent_Host_0, { interactive: "interactive", disabled: "disabled" }, {}, ["*"]);
export { SidebarCardComponentNgFactory as SidebarCardComponentNgFactory };

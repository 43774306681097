var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Domain } from '../../domain';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var DomainService = /** @class */ (function () {
    function DomainService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    DomainService.prototype.get = function (domain, custom) {
        if (custom === void 0) { custom = true; }
        var url = this.apiService.methodURL('domains/');
        var httpParams = new HttpParams({
            fromObject: __assign({ domain: domain }, (!custom && {
                custom: '0'
            }))
        });
        return this.http.get(url, { params: httpParams }).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return new Domain().deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DomainService.prototype.createCustom = function (project, environment, domain) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'domains');
            var headers = new HttpHeaders();
            var data = domain.serialize(['domain']);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Domain().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DomainService.prototype.update = function (project, environment, domain, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "domains/" + domain.domain);
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({}, (!domain.custom && {
                    custom: '0'
                }))
            });
            var data = domain.serialize(fields);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers, params: httpParams });
        }), map(function (result) { return new Domain().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DomainService.prototype.deleteCustom = function (project, environment, domain) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "domains/" + domain.domain);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DomainService.ngInjectableDef = i0.defineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };

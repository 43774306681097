import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay, filter } from 'rxjs/operators';
import { IntercomService } from '@modules/analytics';
import { CurrentProjectStore } from '@modules/projects';
import { GuidePopupService } from '../../services/guide-popup/guide-popup.service';
import { GuideService } from '../../services/guide/guide.service';
import { GuideSectionStore } from '../../stores/guide-section.store';
var GuideWidgetComponent = /** @class */ (function () {
    function GuideWidgetComponent(guideService, guidePopupService, guideSectionStore, intercomService, currentProjectStore, cd) {
        this.guideService = guideService;
        this.guidePopupService = guidePopupService;
        this.guideSectionStore = guideSectionStore;
        this.intercomService = intercomService;
        this.currentProjectStore = currentProjectStore;
        this.cd = cd;
        this.visible = false;
        this.completedTaskVisible = false;
        this.firstTaskCompleted = false;
    }
    GuideWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.guideService.state$.pipe(untilDestroyed(this)).subscribe(function (state) {
            _this.visible = state != undefined;
            _this.currentTask = state ? state.task : undefined;
            _this.cd.markForCheck();
        });
        this.guideService.completedTask$
            .pipe(filter(function (state) { return !state.scenario.isEmpty; }), delay(600), untilDestroyed(this))
            .subscribe(function (state) {
            _this.completedTask = state.task;
            _this.completedTaskVisible = true;
            _this.cd.markForCheck();
            _this.guideSectionStore
                .getTasks(true)
                .pipe(untilDestroyed(_this))
                .subscribe(function (tasks) {
                var completed = tasks.filter(function (item) { return !item.autoComplete && item.completed; });
                _this.firstTaskCompleted = completed.length == 1;
                _this.cd.markForCheck();
            });
        });
    };
    GuideWidgetComponent.prototype.ngOnDestroy = function () { };
    GuideWidgetComponent.prototype.openTask = function () {
        this.guidePopupService.openTask(this.guideService.state.task);
    };
    GuideWidgetComponent.prototype.closeCompleted = function () {
        this.completedTaskVisible = false;
        this.cd.markForCheck();
    };
    Object.defineProperty(GuideWidgetComponent.prototype, "setupGuideLink", {
        get: function () {
            return ['guide'];
        },
        enumerable: true,
        configurable: true
    });
    return GuideWidgetComponent;
}());
export { GuideWidgetComponent };

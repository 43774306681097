import { Type } from '@angular/core';

import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { deserializeDisplayField, Input, ParameterField } from '@modules/fields';

export function migrateModelDescriptionDataSource<T extends ModelDescriptionDataSource>(
  cls: Type<T>,
  queryCls: Type<any>,
  options: {
    resource: string;
    query: Object;
    parameters: Object[];
    inputs: Object[];
    columns: Object[];
  }
): T {
  const dataSource = new cls();

  dataSource.type = DataSourceType.Query;
  dataSource.queryResource = options.resource;

  if (options.query) {
    dataSource.query = new queryCls().deserialize(options.query);
  }

  if (options.parameters) {
    dataSource.queryParameters = options.parameters.map(item => new ParameterField().deserialize(item));
  }

  if (options.inputs) {
    dataSource.queryInputs = options.inputs.map(item => new Input().deserialize(item));
  }

  if (options.columns) {
    dataSource.columns = options.columns.map(item => {
      if (item.hasOwnProperty('name')) {
        return deserializeDisplayField(item);
      } else {
        return deserializeDisplayField({
          name: item['field'] || item['flex_field'],
          visible: item['visible']
        });
      }
    });
  }

  return dataSource;
}

import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { Option } from '@modules/field-components';
import { ConditionWorkflowStep, ConditionWorkflowStepType } from '@modules/workflow';

import { ConditionCustomizeWorkflowStepItemArray } from './condition-customize-workflow-step-item.array';

function createItemsControl(injector: Injector): ConditionCustomizeWorkflowStepItemArray {
  return Injector.create({
    providers: [{ provide: ConditionCustomizeWorkflowStepItemArray, deps: [Injector] }],
    parent: injector
  }).get<ConditionCustomizeWorkflowStepItemArray>(ConditionCustomizeWorkflowStepItemArray);
}

@Injectable()
export class ConditionCustomizeWorkflowStepForm extends FormGroup {
  instance: ConditionWorkflowStep;

  controls: {
    name: FormControl;
    condition_type: FormControl;
    items: ConditionCustomizeWorkflowStepItemArray;
    else_label: FormControl;
  };

  typeOptions: Option<ConditionWorkflowStepType>[] = [
    {
      value: ConditionWorkflowStepType.Boolean,
      name: 'Yes/No',
      icon: 'switch'
    },
    {
      value: ConditionWorkflowStepType.Switch,
      name: 'Switch',
      icon: 'versions'
    }
  ];

  constructor(private injector: Injector) {
    super({
      name: new FormControl(''),
      condition_type: new FormControl(ConditionWorkflowStepType.Boolean),
      items: createItemsControl(injector),
      else_label: new FormControl('')
    });
  }

  init(instance: ConditionWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.condition_type.patchValue(instance.conditionType);
      this.controls.items.init(instance.items);
      this.controls.else_label.patchValue(instance.elseLabel);
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  setConditionType(value: ConditionWorkflowStepType) {
    this.controls.condition_type.patchValue(value);

    if (value == ConditionWorkflowStepType.Boolean) {
      while (this.controls.items.controls.length < 2) {
        const control = this.controls.items.createControl();
        this.controls.items.push(control);
      }
    }
  }

  submit(): ConditionWorkflowStep {
    const instance = cloneDeep(this.instance) as ConditionWorkflowStep;

    instance.name = this.controls.name.value;
    instance.conditionType = this.controls.condition_type.value;
    instance.items = this.controls.items.submit();
    instance.elseLabel = this.controls.else_label.value;

    return instance;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as inflection from 'inflection';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { combineLatest, of } from 'rxjs';
import { catchError, delayWhen, map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import { FieldType } from '@modules/fields';
import { ModelDescription, ModelField, ModelFieldType, ModelRelation, processLegacyModelDescriptions, RelationDirection } from '@modules/models';
import { ResourceName } from '@modules/projects';
import { JetBridgeResourceController, ResourceControllerService } from '@modules/resources';
import { ascComparator, isSet } from '@shared';
function createManyToOneRelation(localResource, localModel, localField, relatedResource, relatedModel) {
    // TODO: Move externalItemPrimaryKey out of generators
    var defaultRelatedField = relatedResource.typeItem.name == ResourceName.Firebase ? '__jet_item_pk__' : relatedModel.primaryKeyField;
    var relatedField = localField.params['custom_primary_key'] || defaultRelatedField;
    var relatedModelId = relatedModel.resource == localModel.resource ? relatedModel.model : relatedModel.modelId;
    var name = ModelRelation.generateManyToOneName({
        localModel: localModel,
        localField: localField.name,
        relatedModel: relatedModelId,
        relatedField: relatedField
    });
    return new ModelRelation({
        name: name,
        direction: RelationDirection.ManyToOne,
        localField: localField.name,
        relatedModel: relatedModelId,
        relatedField: relatedField
    });
}
function createOneToManyRelation(localResource, localModel, relatedResource, relatedModel, relatedField) {
    // TODO: Move externalItemPrimaryKey out of generators
    var defaultLocalField = localResource.typeItem.name == ResourceName.Firebase ? '__jet_item_pk__' : localModel.primaryKeyField;
    var localField = relatedField.params['custom_primary_key'] || defaultLocalField;
    var relatedModelId = relatedModel.resource == localModel.resource ? relatedModel.model : relatedModel.modelId;
    var name = ModelRelation.generateOneToManyName({
        localModel: localModel,
        localField: localField,
        relatedModel: relatedModelId,
        relatedField: relatedField.name
    });
    return new ModelRelation({
        name: name,
        direction: RelationDirection.OneToMany,
        localField: localField,
        relatedModel: relatedModelId,
        relatedField: relatedField.name
    });
}
var ModelDescriptionService = /** @class */ (function () {
    function ModelDescriptionService(http, resourceControllerService, apiService, appConfigService) {
        this.http = http;
        this.resourceControllerService = resourceControllerService;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
    }
    ModelDescriptionService.prototype.getFromResource = function (resource, draft) {
        if (draft === void 0) { draft = false; }
        var controller = this.resourceControllerService.getForResource(resource, true);
        if (!controller || !controller.modelDescriptionGet) {
            return of(undefined);
        }
        return controller.modelDescriptionGet(resource, draft);
    };
    ModelDescriptionService.prototype.getFromResources = function (project, environment, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        var resources = project.getEnvironmentResources(environment.uniqueName);
        if (!resources.length) {
            return of([]);
        }
        return combineLatest.apply(void 0, resources
            .map(function (resource) { return _this.getFromResource(resource, draft); })
            .map(function (item) { return item.pipe(catchError(function () { return of([]); })); })).pipe(map(function (result) {
            var projectResult = flatten(result.filter(function (item) { return item != undefined; }));
            projectResult.forEach(function (projectModel) {
                projectModel.project = project.uniqueName;
                projectModel.fromResource = true;
            });
            return projectResult;
        }));
    };
    ModelDescriptionService.prototype.getOverride = function (project, environment, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'model_descriptions/');
            var headers = new HttpHeaders();
            var params = __assign({}, (draft && { draft: '1' }), { v: _this.appConfigService.version });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) {
            return result.map(function (item) {
                var params = typeof item['params'] === 'string' ? JSON.parse(item['params']) : item['params'];
                return __assign({ resource: item['resource'], model: item['model'], date_add: item['date_add'] }, params, { draft: item['draft'], deleted: item['deleted'] });
            });
        }), map(function (result) {
            return processLegacyModelDescriptions(result);
        }), map(function (result) {
            return result.map(function (item) { return new ModelDescription().deserialize(item); });
        }), map(function (result) {
            return result.map(function (instance) {
                instance.project = project.uniqueName;
                instance.fields
                    .filter(function (field) {
                    return (field.item.field == FieldType.RelatedModel &&
                        field.item.params['related_model'] &&
                        isSet(field.item.params['related_model']['model']) &&
                        !field.item.params['related_model']['model'].includes('.'));
                })
                    .forEach(function (field) {
                    field.item.params['related_model']['model'] = [
                        instance.resource,
                        field.item.params['related_model']['model']
                    ].join('.');
                });
                return instance;
            });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ModelDescriptionService.prototype.get = function (project, environment, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return combineLatest(this.getFromResources(project, environment, draft), this.getOverride(project, environment, draft)).pipe(map(function (_a) {
            var originalResult = _a[0], overrideResult = _a[1];
            originalResult = originalResult.filter(function (item) { return item.model; });
            overrideResult = overrideResult.filter(function (item) { return item.model; });
            // Filter getFromResources with synced-only ModelDescriptions
            originalResult = originalResult.filter(function (originalModel) {
                var resource = project
                    .getEnvironmentResources(environment.uniqueName)
                    .find(function (item) { return item.uniqueName == originalModel.resource; });
                if (resource.isCollectionSyncSupported(originalModel)) {
                    var overrideModel = overrideResult.find(function (overrideItem) { return overrideItem.isSame(originalModel); });
                    return resource.isSynced(originalModel.model) || (overrideModel && overrideModel.isSynced());
                }
                else {
                    return true;
                }
            });
            // Remove dbTable from overrides
            overrideResult
                .filter(function (item) { return isSet(item.dbTable); })
                .forEach(function (item) {
                item.dbTable = undefined;
            });
            // TODO: refactor
            var overrideResultMissing = originalResult.filter(function (item) { return overrideResult.find(function (overrideItem) { return overrideItem.isSame(item); }) == undefined; });
            if (overrideResultMissing.length != 0) {
                var prefixedCount = originalResult.filter(function (originalModel) {
                    return originalModel.dbTable && originalModel.dbTable.split('_', 2).length > 1;
                }).length;
                var prefixed_1 = (originalResult.length < 10 && prefixedCount == originalResult.length) ||
                    (originalResult.length >= 10 + 3 + 1 && prefixedCount >= originalResult.length - 3);
                overrideResult = overrideResult.concat(overrideResultMissing
                    .filter(function (originalModel) { return prefixed_1 && originalModel.autoVerboseName && originalModel.dbTable; })
                    .map(function (originalModel) {
                    var modelDescription = new ModelDescription();
                    var parts = originalModel.dbTable.split('_');
                    modelDescription.resource = originalModel.resource;
                    modelDescription.model = originalModel.model;
                    modelDescription.verboseName = parts.length > 1 ? parts.slice(1).join(' ') : parts[0];
                    modelDescription.verboseNamePlural = inflection.pluralize(modelDescription.verboseName);
                    delete modelDescription['dbTable'];
                    delete modelDescription['hidden'];
                    delete modelDescription['orderingField'];
                    delete modelDescription['defaultOrderBy'];
                    delete modelDescription['displayField'];
                    delete modelDescription['primaryKeyField'];
                    return modelDescription;
                }));
            }
            overrideResult.forEach(function (overrideModel) {
                var originalModel = originalResult.find(function (item) { return item.isSame(overrideModel); });
                if (!originalModel) {
                    originalResult.push(overrideModel);
                    return;
                }
                var resource = project.getEnvironmentResource(environment.uniqueName, originalModel.resource);
                var controller = resource ? _this.resourceControllerService.getForResource(resource, true) : undefined;
                var originalManagedStructure = controller instanceof JetBridgeResourceController;
                [
                    // 'dbTable',
                    'verboseName',
                    'verboseNamePlural',
                    'hidden',
                    'orderingField',
                    'defaultOrderBy',
                    'displayField'
                ].concat((originalManagedStructure ? [] : ['primaryKeyField']), [
                    'description',
                    'queryType',
                    'getQuery',
                    'getParameters',
                    'getDetailQuery',
                    'getDetailParameters',
                    'getDetailParametersUseDefaults',
                    'createQuery',
                    'updateQuery',
                    'deleteQuery',
                    'featured',
                    'demo',
                    'sync',
                    'syncFinished',
                    'orderAfter',
                    'params',
                    'draft'
                ]).filter(function (item) { return originalModel.hasOwnProperty(item) && overrideModel[item] !== undefined; })
                    .forEach(function (item) { return (originalModel[item] = overrideModel[item]); });
                var mergeDbFields = function (overrideFields, originalFields) {
                    overrideFields.forEach(function (overrideField) {
                        var originalField = originalFields.find(function (item) { return item.name == overrideField.name; });
                        if (!originalField) {
                            return;
                        }
                        [
                            'verboseName',
                            'description',
                            'field',
                            'dbField',
                            'required',
                            'editable',
                            'filterable',
                            'sortable',
                            'defaultType',
                            'defaultValue',
                            'placeholder',
                            'validatorType',
                            'validatorParams'
                        ]
                            .filter(function (item) { return originalField.hasOwnProperty(item) && overrideField[item] !== undefined; })
                            .forEach(function (item) { return (originalField[item] = overrideField[item]); });
                        if (originalField.hasOwnProperty('params') && overrideField['params'] !== undefined) {
                            originalField['params'] = __assign({}, originalField['params'], overrideField['params']);
                        }
                        originalField.updateFieldDescription();
                    });
                };
                mergeDbFields(overrideModel.dbFields, originalModel.dbFields);
                if (originalModel.defaultFields) {
                    mergeDbFields(overrideModel.dbDefaultFields, originalModel.dbDefaultFields);
                }
                else {
                    originalModel.defaultFields = cloneDeep(overrideModel.defaultFields);
                }
                originalModel.getDetailParameters = cloneDeep(overrideModel.getDetailParameters);
                originalModel.getDetailParametersUseDefaults = overrideModel.getDetailParametersUseDefaults;
                originalModel.createParameters = cloneDeep(overrideModel.createParameters);
                originalModel.createParametersUseDefaults = overrideModel.createParametersUseDefaults;
                originalModel.updateParameters = cloneDeep(overrideModel.updateParameters);
                originalModel.updateParametersUseDefaults = overrideModel.updateParametersUseDefaults;
                originalModel.deleteParameters = cloneDeep(overrideModel.deleteParameters);
                originalModel.deleteParametersUseDefaults = overrideModel.deleteParametersUseDefaults;
                overrideModel.flexFields.forEach(function (overrideField) {
                    var originalField = originalModel.flexFields.find(function (item) { return item.name == overrideField.name; });
                    if (originalField) {
                        ['verboseName', 'field', 'query', 'code', 'params']
                            .filter(function (item) { return originalField.hasOwnProperty(item) && overrideField[item] !== undefined; })
                            .forEach(function (item) { return (originalField[item] = overrideField[item]); });
                        originalField.updateFieldDescription();
                    }
                    else {
                        var field = new ModelField();
                        field.type = ModelFieldType.Flex;
                        field.item = overrideField;
                        field.name = field.item.name;
                        originalModel.fields.push(field);
                    }
                });
                overrideModel.customFields.forEach(function (overrideField) {
                    var originalField = originalModel.customFields.find(function (item) { return item.name == overrideField.name; });
                    if (originalField) {
                        ['type', 'verboseName', 'description', 'field', 'params', 'visible', 'flex', 'valueInput', 'path']
                            .filter(function (item) { return originalField.hasOwnProperty(item) && overrideField[item] !== undefined; })
                            .forEach(function (item) { return (originalField[item] = overrideField[item]); });
                        originalField.updateFieldDescription();
                    }
                    else {
                        var field = new ModelField();
                        field.type = ModelFieldType.Custom;
                        field.item = overrideField;
                        field.name = field.item.name;
                        originalModel.fields.push(field);
                    }
                });
                overrideModel.fields.forEach(function (overrideField) {
                    var originalField = originalModel.fields.find(function (item) { return item.name == overrideField.name; });
                    if (originalField) {
                        ['orderAfter', 'visible']
                            .filter(function (item) { return originalField.hasOwnProperty(item) && overrideField[item] !== undefined; })
                            .forEach(function (item) { return (originalField[item] = overrideField[item]); });
                    }
                });
                overrideModel.segments.forEach(function (overrideSegment) {
                    var originalSegment = originalModel.segments.find(function (item) { return item.label == overrideSegment.label; });
                    if (originalSegment) {
                        ['label', 'filterItems', 'visible']
                            .filter(function (item) { return originalSegment.hasOwnProperty(item) && overrideSegment[item] !== undefined; })
                            .forEach(function (item) { return (originalSegment[item] = overrideSegment[item]); });
                    }
                    else {
                        originalModel.segments.push(overrideSegment);
                    }
                });
                // overrideModel.relations.forEach(overrideRelation => {
                //   const originalRelation = originalModel.relations.find(item => item.name == overrideRelation.name);
                //
                //   if (!originalRelation) {
                //     return;
                //   }
                //
                //   ['verboseName', 'field']
                //     .filter(item => originalRelation.hasOwnProperty(item) && overrideRelation.hasOwnProperty(item))
                //     .forEach(item => originalRelation[item] = overrideRelation[item]);
                // });
                // originalModel.relations = overrideModel.relations;
            });
            // TODO: Workaround for hardcoded firebase get parameters
            originalResult.forEach(function (originalModel) {
                var resource = project.getEnvironmentResource(environment.uniqueName, originalModel.resource);
                if (resource && resource.typeItem.name == ResourceName.Firebase) {
                    var parentParameter = originalModel.getParameters.find(function (item) { return item.name == '__parent__'; });
                    if (parentParameter) {
                        var field = originalModel.dbField('__parent__');
                        parentParameter.field = field.field;
                        parentParameter.params = field.params;
                    }
                }
            });
            return originalResult;
        }), delayWhen(function (modelDescriptions) {
            var resources = project
                .getEnvironmentResources(environment.uniqueName)
                .filter(function (item) {
                var controller = _this.resourceControllerService.getForResource(item, true);
                return controller instanceof JetBridgeResourceController && controller.setModelDescriptionRelationOverrides;
            })
                .filter(function (resource) {
                return (resource &&
                    resource.apiInfo &&
                    resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.2.9', jetDjango: '1.4.4' }));
            });
            var relationsByModel = {};
            resources.forEach(function (resource) {
                var resourceModelDescriptions = modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) { return item.fromResource; });
                resourceModelDescriptions.forEach(function (modelDescription) {
                    modelDescription.dbFields
                        .filter(function (item) { return item.field == FieldType.RelatedModel; })
                        .forEach(function (field) {
                        if (field.params['related_model'] && isSet(field.params['related_model']['model'])) {
                            var relatedModelId_1 = field.params['related_model']['model'].includes('.')
                                ? field.params['related_model']['model']
                                : [modelDescription.resource, field.params['related_model']['model']].join('.');
                            var relatedModel_1 = modelDescriptions.find(function (item) { return item.isSame(relatedModelId_1); });
                            var relatedResource = relatedModel_1
                                ? resources.find(function (item) { return item.uniqueName == relatedModel_1.resource; })
                                : undefined;
                            if (relatedResource &&
                                relatedModel_1 &&
                                resource.isSameDatabase(modelDescription, relatedResource, relatedModel_1)) {
                                var relation = createManyToOneRelation(resource, modelDescription, field, relatedResource, relatedModel_1);
                                if (!relationsByModel[modelDescription.modelId]) {
                                    relationsByModel[modelDescription.modelId] = {
                                        modelDescription: modelDescription,
                                        relations: []
                                    };
                                }
                                relationsByModel[modelDescription.modelId].relations.push(relation);
                                var backRelation = createOneToManyRelation(relatedResource, relatedModel_1, resource, modelDescription, field);
                                if (!relationsByModel[relatedModel_1.modelId]) {
                                    relationsByModel[relatedModel_1.modelId] = {
                                        modelDescription: relatedModel_1,
                                        relations: []
                                    };
                                }
                                relationsByModel[relatedModel_1.modelId].relations.push(backRelation);
                            }
                        }
                    });
                });
            });
            var setModelRelations$ = resources
                .map(function (resource) {
                var resourceModelDescriptions = modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) { return item.fromResource; });
                var setModelRelations = [];
                resourceModelDescriptions.forEach(function (modelDescription) {
                    var existingRelations = modelDescription.relations.sort(function (lhs, rhs) {
                        return ascComparator(lhs.name, rhs.name);
                    });
                    var actualRelations = relationsByModel[modelDescription.modelId]
                        ? relationsByModel[modelDescription.modelId].relations.sort(function (lhs, rhs) {
                            return ascComparator(lhs.name, rhs.name);
                        })
                        : [];
                    var relationOverrides = actualRelations.filter(function (relation) { return !existingRelations.find(function (item) { return item.name == relation.name; }); });
                    if (!isEqual(relationOverrides, modelDescription.relationOverrides)) {
                        setModelRelations.push({
                            modelDescription: modelDescription,
                            relations: relationOverrides
                        });
                    }
                });
                if (setModelRelations.length) {
                    var controller = _this.resourceControllerService.getForResource(resource, true);
                    return controller.setModelDescriptionRelationOverrides(resource, setModelRelations, draft).pipe(tap(function () {
                        setModelRelations.forEach(function (item) {
                            item.modelDescription.relationOverrides = item.relations;
                        });
                    }), catchError(function () { return of([]); }));
                }
            })
                .filter(function (item) { return item; });
            if (!setModelRelations$.length) {
                return of([]);
            }
            return combineLatest.apply(void 0, setModelRelations$);
        }), 
        // map((modelDescriptions: ModelDescription[]) => {
        //   modelDescriptions.forEach(modelDescription => {
        //     // const resource = this.currentProjectStore.instance.resources.find(
        //     //   item => item.uniqueName == modelDescription.resource
        //     // );
        //     const resource = project
        //       .getEnvironmentResources(environment)
        //       .find(item => item.uniqueName == modelDescription.resource);
        //     const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
        //     const others = modelDescriptions.filter(item => item !== modelDescription);
        //
        //     modelDescription.relations = [];
        //
        //     const relations = flatten(
        //       others.map(other => {
        //         return other.dbFields
        //           .filter(field => {
        //             return (
        //               field.field == FieldType.RelatedModel &&
        //               field.params['related_model'] &&
        //               modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
        //             );
        //           })
        //           .map(field => {
        //             const instance = new ModelRelation();
        //
        //             instance.name = `auto_${other.dbTable}`;
        //             instance.verboseName = `${other.verboseNamePlural} - ${field.name}`;
        //             instance.relatedModel = other.getMinimal();
        //             instance.path = [
        //               {
        //                 prevModel: modelDescription.modelId,
        //                 prevModelField: field.params['custom_primary_key'] || modelDescription.primaryKeyField,
        //                 model: other.modelId,
        //                 modelField: field.name
        //               }
        //             ];
        //             instance.auto = true;
        //
        //             return instance;
        //           });
        //       })
        //     );
        //
        //     modelDescription.relations = modelDescription.relations.concat(relations);
        //
        //     if (controller && controller.relationFilter) {
        //       const m2mRelations = flatten(
        //         others.map(other => {
        //           return flatten(
        //             other.dbFields
        //               .filter(field => {
        //                 return (
        //                   field.field == FieldType.RelatedModel &&
        //                   field.params['related_model'] &&
        //                   modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
        //                 );
        //               })
        //               .map(() => {
        //                 return others
        //                   .filter(otherNested => {
        //                     if (otherNested.isSame(other)) {
        //                       return false;
        //                     }
        //
        //                     return (
        //                       other.dbFields.find(fieldM2M => {
        //                         return (
        //                           fieldM2M.field == FieldType.RelatedModel &&
        //                           fieldM2M.params['related_model'] &&
        //                           otherNested.isSame(fromLegacyModel(fieldM2M.params['related_model']['model']))
        //                         );
        //                       }) != undefined
        //                     );
        //                   })
        //                   .map(otherNested => {
        //                     const instance = new ModelRelation();
        //                     const otherToPrimaryField = other.dbFields.find(field => {
        //                       return (
        //                         field.field == FieldType.RelatedModel &&
        //                         field.params['related_model'] &&
        //                         modelDescription.isSame(fromLegacyModel(field.params['related_model']['model']))
        //                       );
        //                     });
        //                     const otherToNestedField = other.dbFields.find(field => {
        //                       return (
        //                         field.field == FieldType.RelatedModel &&
        //                         field.params['related_model'] &&
        //                         otherNested.isSame(fromLegacyModel(field.params['related_model']['model']))
        //                       );
        //                     });
        //
        //                     instance.name = `auto_m2m_${otherNested.dbTable}_${other.dbTable}`;
        //                     instance.verboseName = `${otherNested.verboseNamePlural} M2M via ${other.verboseNamePlural}`;
        //                     instance.relatedModel = otherNested.getMinimal();
        //                     instance.path = [
        //                       {
        //                         prevModel: modelDescription.modelId,
        //                         prevModelField:
        //                           otherToPrimaryField.params['custom_primary_key'] || modelDescription.primaryKeyField,
        //                         model: other.modelId,
        //                         modelField: otherToPrimaryField.name
        //                       },
        //                       {
        //                         prevModel: other.modelId,
        //                         prevModelField: otherToNestedField.name,
        //                         model: otherNested.modelId,
        //                         modelField: otherToNestedField.params['custom_primary_key'] || otherNested.primaryKeyField
        //                       }
        //                     ];
        //                     instance.auto = true;
        //
        //                     return instance;
        //                   });
        //               })
        //           );
        //         })
        //       );
        //
        //       modelDescription.relations = modelDescription.relations.concat(m2mRelations);
        //     }
        //   });
        //
        //   return modelDescriptions;
        // }),
        this.apiService.catchApiError(), publishLast(), refCount());
    };
    ModelDescriptionService.prototype.createBulk = function (projectName, environmentName, modelDescriptions) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'model_descriptions/');
            var headers = new HttpHeaders();
            var httpParams = {
                draft: '1',
                v: _this.appConfigService.version
            };
            var data = modelDescriptions.map(function (item) {
                var params = item.serialize();
                delete params['project'];
                delete params['model'];
                return {
                    project: item.project,
                    resource: item.resource,
                    model: item.model,
                    params: params,
                    draft: item.draft,
                    deleted: item.deleted
                };
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: httpParams });
        }), map(function (result) {
            return result.map(function (item) {
                var params = typeof item['params'] === 'string' ? JSON.parse(item['params']) : item['params'];
                return __assign({ resource: item['resource'], model: item['model'], date_add: item['date_add'] }, params, { draft: item['draft'], deleted: item['deleted'] });
            });
        }), map(function (result) { return result.map(function (item) { return new ModelDescription().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ModelDescriptionService.prototype.create = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
    };
    ModelDescriptionService.prototype.update = function (projectName, environmentName, instance) {
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'model_descriptions/');
        //     const httpParams = { v: this.appConfigService.version };
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     const params = modelDescription.serialize();
        //
        //     delete params['project'];
        //     delete params['model'];
        //
        //     const data = {
        //       project: modelDescription.project,
        //       resource: modelDescription.resource,
        //       model: modelDescription.model,
        //       params: params
        //     };
        //
        //     return this.http.post(url, data, { headers: headers, params: httpParams });
        //   }),
        //   map(result => {
        //     const params = typeof result['params'] === 'string' ? JSON.parse(result['params']) : result['params'];
        //     return {
        //       resource: result['resource'],
        //       model: result['model'],
        //       date_add: result['date_add'],
        //       ...params
        //     };
        //   }),
        //   map(result => new ModelDescription().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ModelDescriptionService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `model_descriptions/${modelDescription.resource}/${modelDescription.model}/`
        //     );
        //     const httpParams = { v: this.appConfigService.version };
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers, params: httpParams });
        //   }),
        //   map(() => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    return ModelDescriptionService;
}());
export { ModelDescriptionService };

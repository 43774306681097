import { Optional, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Domain, DomainStore } from '@modules/domain';
import { defaultNumberFractionSeparator, defaultNumberThousandsSeparator } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { FormatNumberPipe, isSet } from '@shared';

@Pipe({
  name: 'appDomainFormatNumber'
})
export class DomainFormatNumberPipe extends FormatNumberPipe implements PipeTransform {
  constructor(
    @Optional() private domainStore: DomainStore,
    @Optional() private currentProjectStore: CurrentProjectStore
  ) {
    super();
  }

  getDomain$(): Observable<Domain> {
    if (this.currentProjectStore) {
      return this.currentProjectStore.get().pipe(map(project => (project ? project.domain : undefined)));
    } else if (this.domainStore) {
      return this.domainStore.get();
    } else {
      return of(undefined);
    }
  }

  transform(value: any, format: string): Observable<any> {
    const result = super.transform(value, format);

    if (!isSet(result)) {
      return result;
    }

    return this.getDomain$().pipe(
      map(domain => {
        const numberFractionSeparator = domain ? domain.numberFractionSeparator : undefined;
        const numberThousandsSeparator = domain ? domain.numberThousandsSeparator : undefined;

        return String(result).replace(/[,.]/g, symbol => {
          if (symbol == ',') {
            return isSet(numberThousandsSeparator, true) ? numberThousandsSeparator : defaultNumberThousandsSeparator;
          } else if (symbol == '.') {
            return isSet(numberFractionSeparator) ? numberFractionSeparator : defaultNumberFractionSeparator;
          } else {
            return symbol;
          }
        });
      })
    );
  }
}

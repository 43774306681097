import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { Deserializable, isSet } from '@shared';

import { Snapshot } from '../../data/snapshot';

@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  constructor(private http: HttpClient, private apiService: ApiService, private centrifugoService: CentrifugoService) {}

  get(projectName: string, environmentName: string, params = {}): Observable<SnapshotService.GetResponse<Snapshot>> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'snapshots');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => new SnapshotService.GetResponse<Snapshot>(Snapshot).deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, name?: string): Observable<Snapshot> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'snapshots');
        const data = {
          ...(isSet(name) && { name: name })
        };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Snapshot().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, environmentName: string, instance: Snapshot, fields?: string[]): Observable<Snapshot> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, `snapshots/${instance.id}`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new Snapshot().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  apply(projectName: string, environmentName: string, instance: Snapshot): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `snapshots/${instance.id}/apply`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeSnapshot(projectName: string, environmentName: string, instance: Snapshot): Observable<Snapshot> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$environment_snapshot:environment_snapshot', instance.id].join('/');
        return this.centrifugoService.subscribeState(channel).pipe(map(msg => new Snapshot().deserialize(msg.data)));
      })
    );
  }
}

export namespace SnapshotService {
  export class GetResponse<T extends Deserializable<T>> {
    public createAllowed = false;
    public results: T[];
    public count: number;
    public perPage: number;
    public numPages: number;

    constructor(private cls: Type<T>) {}

    deserialize(data: Object) {
      this.createAllowed = data['create_allowed'];
      this.results = data['results'].map(item => new this.cls().deserialize(item));
      this.count = data['count'];
      this.perPage = data['per_page'];
      this.numPages = data['num_pages'];

      return this;
    }
  }
}

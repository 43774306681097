import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType } from '@modules/fields';
import { registerFieldDataParamsComponent } from '@modules/fields/data/components';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { DateTimeFieldDataParamsForm } from './date-time-field-data-params.form';

@Component({
  selector: 'app-date-time-field-data-params',
  templateUrl: './date-time-field-data-params.component.html',
  providers: [DateTimeFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFieldDataParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();

  constructor(public form: DateTimeFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }
}

registerFieldDataParamsComponent(FieldType.DateTime, DateTimeFieldDataParamsComponent);

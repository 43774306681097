/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../../common/dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "@angular/common";
import * as i5 from "./auto-layer.component";
import * as i6 from "../../services/view-editor-context/view-editor.context";
import * as i7 from "../../services/view-editor-multiple-layers/view-editor-multiple-layers";
import * as i8 from "../../../../common/popups/services/popup/popup.service";
import * as i9 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i10 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_AutoLayerComponent = [];
var RenderType_AutoLayerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoLayerComponent, data: {} });
export { RenderType_AutoLayerComponent as RenderType_AutoLayerComponent };
function View_AutoLayerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, [[2, 4]], 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AutoLayerComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { rootElement: 0 }), i0.ɵqud(671088640, 2, { dynamicComponent: 0 }), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["root_element", 1]], null, 2, "div", [["class", "layer"]], [[2, "layer_layout", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoLayerComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.componentData; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layout; _ck(_v, 2, 0, currVal_0); }); }
export function View_AutoLayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-layer", [], [[1, "data-layer-id", 0], [1, "data-layer-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AutoLayerComponent_0, RenderType_AutoLayerComponent)), i0.ɵdid(1, 4964352, null, 0, i5.AutoLayerComponent, [[2, i6.ViewEditorContext], [2, i7.ViewEditorMultipleLayers], i8.PopupService, [2, i9.BasePopupComponent], i0.NgZone, i0.ChangeDetectorRef, i10.UniversalAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).layerId; var currVal_1 = i0.ɵnov(_v, 1).layerName; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AutoLayerComponentNgFactory = i0.ɵccf("app-auto-layer", i5.AutoLayerComponent, View_AutoLayerComponent_Host_0, { layer: "layer", container: "container", containerElement: "containerElement", frame: "frame", layout: "layout", translate: "translate", viewContext: "viewContext", analyticsSource: "analyticsSource" }, { layerDelete: "layerDelete" }, []);
export { AutoLayerComponentNgFactory as AutoLayerComponentNgFactory };

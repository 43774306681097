import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Draggabilly from 'draggabilly';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as Packery from 'packery';
import { combineLatest, merge, of } from 'rxjs';
import { catchError, debounceTime, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { DocumentService } from '@core';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomizeType, ViewContext, ViewContextElementType } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Dashboard, DashboardService, WidgetService } from '@modules/dashboard';
import { MetaService } from '@modules/meta';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { generateUUID, scrollWindowTo } from '@shared';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(activatedRoute, widgetService, currentProjectStore, currentEnvironmentStore, context, customizeService, dashboardService, dialogService, metaService, userActivityService, documentService, analyticsService, cd, routingService, customizeBarContext) {
        this.activatedRoute = activatedRoute;
        this.widgetService = widgetService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.context = context;
        this.customizeService = customizeService;
        this.dashboardService = dashboardService;
        this.dialogService = dialogService;
        this.metaService = metaService;
        this.userActivityService = userActivityService;
        this.documentService = documentService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.routingService = routingService;
        this.customizeBarContext = customizeBarContext;
        this.dashboardItemElements = new QueryList();
        this.dashboardItems = new QueryList();
        this.loading = false;
        this.columns = 10;
        this.rowHeight = 100;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(this.activatedRoute.params, this.activatedRoute.queryParams)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var snapshot = _this.activatedRoute.snapshot;
            _this.context.objectType = 'dashboard';
            _this.context.objectId = snapshot.params['id'];
            _this.cd.markForCheck();
        });
        this.metaService.set({ title: 'Dashboard' });
        this.customizeService.layoutCustomization = {
            subtitle: '',
            title: 'Edit Dashboard',
            image: 'dashboard',
            clickEvent: [AnalyticsEvent.Deprecated.CustomizeInterfaceChooseDashboard, { DashboardID: this.dashboard.id }],
            description: [
                { icon: 'dashboard', label: 'Create multiple dashboards' },
                { icon: 'chart', label: 'Add and edit Chart Components: \nLine, Bar, Pie, List and others' },
                { icon: 'magic_wand', label: 'Use the SQL Builder for more complex queries' }
            ]
        };
        this.customizeService.layoutEnabled$.pipe(untilDestroyed(this)).subscribe(function (enabled) {
            if (enabled) {
                _this.customizeService.setHandler(_this);
                _this.updateCustomizeHandleInfo();
            }
            else {
                _this.customizeService.unsetHandler(_this);
            }
        });
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.customizeService.layoutCustomization = undefined;
        this.customizeService.unsetHandler(this);
        this.closeCustomize();
    };
    DashboardComponent.prototype.onBeforeDestroy = function () {
        this.context.clear();
    };
    DashboardComponent.prototype.ngOnChanges = function (changes) {
        this.getWidgets();
        this.updateCustomizeHandleInfo();
    };
    DashboardComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.documentService.viewportSize$
            .pipe(debounceTime(100), map(function () {
            return {
                width: window.innerWidth,
                height: window.innerHeight
            };
        }), pairwise(), untilDestroyed(this))
            .subscribe(function (_a) {
            var prevSize = _a[0], newSize = _a[1];
            _this.updateColumnWidth();
            _this.cd.markForCheck();
            _this.updateWidgets();
            if (newSize.width != prevSize.width) {
                var widgets = _this.widgets;
                _this.widgets = undefined;
                _this.cd.detectChanges();
                _this.widgets = widgets;
                _this.cd.detectChanges();
            }
            _this.initGrid();
        });
        this.updateColumnWidth();
        this.cd.markForCheck();
    };
    DashboardComponent.prototype.getCollaborationParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: 'dashboard',
            object_id: snapshot.params['id']
        };
    };
    DashboardComponent.prototype.getUserActivitiesParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: 'dashboard',
            object_id: snapshot.params['id']
        };
    };
    DashboardComponent.prototype.getWidgets = function () {
        var _this = this;
        var dashboard = this.dashboard;
        this.loading = true;
        this.cd.markForCheck();
        var obs = this.widgetService.get(this.currentProjectStore.instance.uniqueName, { dashboard: dashboard.id }).pipe(untilDestroyed(this), tap(function (result) {
            _this.widgets = undefined;
            _this.context.clear(ViewContextElementType.Element);
            _this.cd.detectChanges();
            _this.widgets = result;
            _this.widgetsDashboard = dashboard;
            _this.initialWidgets = cloneDeep(_this.widgets);
            _this.cd.detectChanges();
            _this.initGrid();
            _this.customizeService.startTrackChanges();
            _this.userActivityService
                .currentProjectCreateDashboardInstance(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, UserActivityType.DashboardList, dashboard.id, {
                id: dashboard.id
            })
                .subscribe(function () { });
        }));
        obs.pipe(untilDestroyed(this)).subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (e) {
            console.log(e);
            _this.loading = false;
            _this.cd.markForCheck();
        });
        return obs;
    };
    DashboardComponent.prototype.initGrid = function () {
        var _this = this;
        if (!this.dashboardElement) {
            return;
        }
        this.grid = new Packery(this.dashboardElement.nativeElement, {
            itemSelector: '.dashboard__item',
            columnWidth: this.columnWidth,
            rowHeight: this.rowHeight,
            percentPosition: true,
            initLayout: false,
            resize: false
        });
        this.dashboardItemElements.forEach(function (item) { return _this.initGridItem(item.nativeElement); });
        this.initWidgetPositions();
        this.grid.on('dragItemPositioned', function () {
            // this.grid.shiftLayout();
        });
    };
    DashboardComponent.prototype.initGridItem = function (el) {
        var draggie = new Draggabilly(el, {
            handle: '.dashboard-item__draggable'
        });
        this.grid.bindDraggabillyEvents(draggie);
    };
    DashboardComponent.prototype.updateColumnWidth = function () {
        this.columnWidth = Math.floor(this.dashboardElementWrapper.nativeElement.offsetWidth / this.columns);
    };
    DashboardComponent.prototype.initWidgetPositions = function () {
        var _this = this;
        if (!this.grid) {
            return;
        }
        this.grid._resetLayout();
        this.grid.items = this.widgets.slice().sort(function (lhs, rhs) {
            if (lhs.y == rhs.y) {
                return lhs.x - rhs.x;
            }
            else {
                return lhs.y - rhs.y;
            }
        })
            .map(function (widget) {
            var item = _this.grid.items.find(function (i) { return i.element.dataset['id'] == widget.id; });
            item.rect.x = _this.grid.columnWidth * widget.x;
            item.rect.y = _this.grid.rowHeight * widget.y;
            return item;
        });
        this.grid.shiftLayout();
    };
    Object.defineProperty(DashboardComponent.prototype, "widgetPosition", {
        get: function () {
            var yBottom = this.widgets.map(function (item) { return item.y + item.height; });
            return {
                x: 0,
                y: yBottom.length ? Math.max.apply(Math, yBottom) : 0
            };
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.onWidgetSizeChanged = function () {
        this.cd.detectChanges();
        this.grid.shiftLayout();
    };
    DashboardComponent.prototype.onWidgetSizeChangeConfirmed = function () { };
    DashboardComponent.prototype.deleteWidget = function (widget) {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Are you sure?',
            description: 'Widget will be deleted completely'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (confirm) {
            if (confirm) {
                _this.onWidgetDeleteRequested(widget);
            }
        });
    };
    DashboardComponent.prototype.copyWidget = function (copyWidget) {
        var widget = cloneDeep(copyWidget);
        widget.id = undefined;
        widget.x = this.widgetPosition.x;
        widget.y = this.widgetPosition.y;
        widget.dashboard = this.dashboard.id;
        this.onWidgetAdded(widget);
    };
    DashboardComponent.prototype.onWidgetAdded = function (widget) {
        this.widgets.push(widget);
        this.cd.detectChanges();
        var el = this.dashboardItemElements.last.nativeElement;
        this.grid.appended(el);
        this.initGridItem(el);
        var widgetElements = this.dashboardItemElements.toArray();
        var widgetElement = widgetElements[widgetElements.length - 1];
        scrollWindowTo(widgetElement.nativeElement, 0.6);
        var component = this.dashboardItems.find(function (item) { return item.widget === widget; });
        component.editWidget();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Created);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidget, {
            WidgetTypeID: widget['chartType'] || widget.type,
            WidgetID: widget.id
        });
    };
    DashboardComponent.prototype.onWidgetDeleteRequested = function (widget) {
        this.widgets = this.widgets.filter(function (item) { return item.id != widget.id; });
        this.cd.markForCheck();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Deleted);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidget, {
            WidgetTypeID: widget['chartType'] || widget.type,
            WidgetID: widget.id
        });
    };
    DashboardComponent.prototype.onWidgetResizingChanged = function (widget) {
        this.resizingWidget = widget;
        this.cd.markForCheck();
    };
    DashboardComponent.prototype.toggleCustomize = function () {
        this.customizeService.toggleEnabled(CustomizeType.Layout);
    };
    DashboardComponent.prototype.updateWidgets = function () {
        var _this = this;
        if (!this.grid) {
            return;
        }
        this.widgets = this.grid.items
            .map(function (item) {
            var widget = _this.widgets.find(function (w) { return w.id == item.element.dataset['id']; });
            if (!widget) {
                return;
            }
            widget.x = Math.round(item.rect.x / _this.grid.columnWidth);
            widget.y = Math.round(item.rect.y / _this.grid.rowHeight);
            widget.width = Math.round(item.rect.width / _this.grid.columnWidth);
            widget.height = Math.round(item.rect.height / _this.grid.rowHeight);
            return widget;
        })
            .filter(function (item) { return item != undefined; });
        this.cd.markForCheck();
    };
    DashboardComponent.prototype.updateCustomizeHandleInfo = function () {
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: this.dashboard ? ['Customizing Dashboard', this.dashboard.name] : undefined
        });
    };
    DashboardComponent.prototype.getChangesState = function () {
        var _this = this;
        var settingsChanges = ['name', 'params'];
        var positionChanges = ['id', 'x', 'y', 'width', 'height'];
        var createWidgets = this.widgets.filter(function (item) { return !_this.initialWidgets.find(function (i) { return i.id == item.id; }); });
        var updateWidgets = this.widgets.filter(function (item) {
            var initialWidget = _this.initialWidgets.find(function (i) { return i.id == item.id; });
            if (!initialWidget) {
                return false;
            }
            return !isEqual(item.serialize(settingsChanges), initialWidget.serialize(settingsChanges));
        });
        var deleteWidgets = this.initialWidgets.filter(function (item) { return !_this.widgets.find(function (i) { return i.id == item.id; }); });
        var widgetPositions = !isEqual(this.widgets.map(function (item) { return item.serialize(positionChanges); }), this.initialWidgets.map(function (item) { return item.serialize(positionChanges); }))
            ? this.widgets
            : undefined;
        return {
            createWidgets: createWidgets,
            updateWidgets: updateWidgets,
            deleteWidgets: deleteWidgets,
            widgetPositions: widgetPositions
        };
    };
    DashboardComponent.prototype.setChangesState = function (state) { };
    DashboardComponent.prototype.isChangesStateEqual = function (lhs, rhs) {
        return (isEqual(lhs.createWidgets.map(function (item) { return item.serialize(); }), rhs.createWidgets.map(function (item) { return item.serialize(); })) &&
            isEqual(lhs.updateWidgets.map(function (item) { return item.serialize(); }), rhs.updateWidgets.map(function (item) { return item.serialize(); })) &&
            isEqual(lhs.deleteWidgets.map(function (item) { return item.serialize(); }), rhs.deleteWidgets.map(function (item) { return item.serialize(); })) &&
            isEqual(lhs.widgetPositions.map(function (item) { return item.serialize(); }), rhs.widgetPositions.map(function (item) { return item.serialize(); })));
    };
    DashboardComponent.prototype.saveChangesState = function (state) {
        var _this = this;
        this.updateWidgets();
        var requests = state.createWidgets.map(function (item) {
            item.dashboard = _this.dashboard.id;
            return _this.widgetService.create(_this.currentProjectStore.instance.uniqueName, item).pipe(tap(function (widget) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidgetSuccess, {
                    WidgetTypeID: widget.type,
                    WidgetID: widget.id
                });
            }), catchError(function (error) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceCreateWidgetError, {
                    WidgetTypeID: item.type,
                    WidgetID: item.id,
                    Error: error.toString()
                });
                return of(undefined);
            }), map(function (instance) {
                return { widget: item, instance: instance };
            }));
        }).concat(state.updateWidgets.map(function (item) {
            return _this.widgetService.update(_this.currentProjectStore.instance.uniqueName, item).pipe(tap(function (widget) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidgetSuccess, {
                    WidgetTypeID: widget.type,
                    WidgetID: widget.id
                });
            }), catchError(function (error) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidgetError, {
                    WidgetTypeID: item.type,
                    WidgetID: item.id,
                    Error: error.toString()
                });
                return of(undefined);
            }), map(function (instance) {
                return { widget: item, instance: instance };
            }));
        }), state.deleteWidgets.map(function (item) {
            return _this.widgetService.delete(_this.currentProjectStore.instance.uniqueName, item).pipe(tap(function () {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidgetSuccess, {
                    WidgetTypeID: item.type,
                    WidgetID: item.id
                });
            }), catchError(function (error) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceDeleteWidgetError, {
                    WidgetTypeID: item.type,
                    WidgetID: item.id,
                    Error: error.toString()
                });
                return of(undefined);
            }), map(function (instance) {
                return { widget: item, instance: undefined };
            }));
        }));
        if (!requests.length) {
            return of(state);
        }
        return combineLatest.apply(void 0, requests).pipe(tap(function (updates) {
            _this.widgets = _this.widgets
                .map(function (item) {
                var update = updates.find(function (i) { return i.widget === item; });
                if (update) {
                    update.instance['_uid'] = item['_uid'];
                    return update.instance;
                }
                else {
                    return item;
                }
            })
                .filter(function (item) { return item != undefined; });
            _this.initialWidgets = cloneDeep(_this.widgets);
            _this.cd.markForCheck();
        }), switchMap(function () { return _this.savePositionsCustomize(state); }), map(function () {
            return state;
        }));
    };
    DashboardComponent.prototype.savePositionsCustomize = function (state) {
        if (!state.widgetPositions) {
            return of(state);
        }
        return this.dashboardService
            .setWidgets(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.dashboard, state.widgetPositions)
            .pipe(map(function () { return state; }));
    };
    DashboardComponent.prototype.reload = function () {
        return this.getWidgets();
    };
    DashboardComponent.prototype.moveToDashboard = function (widget, dashboard) {
        var _this = this;
        var instance = cloneDeep(widget);
        instance.dashboard = dashboard.id;
        this.widgetService
            .update(this.currentProjectStore.instance.uniqueName, instance, ['dashboard'])
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routingService.navigateApp(dashboard.link); });
    };
    DashboardComponent.prototype.updateItem = function (widget, instance) {
        this.widgets = this.widgets.map(function (item) {
            if (item === widget) {
                return instance;
            }
            else {
                return item;
            }
        });
        this.cd.markForCheck();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.DashboardWidget, AnalyticsEventAction.Updated);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Deprecated.DashboardCustomizeInterfaceEditWidget, {
            WidgetTypeID: widget.type,
            WidgetID: widget.id
        });
        this.customizeService.markChanged();
    };
    DashboardComponent.prototype.trackByFn = function (i, item) {
        if (item['_uid'] || !item.id) {
            if (!item['_uid']) {
                item['_uid'] = generateUUID();
            }
            return "uid_" + item['_uid'];
        }
        else {
            return "id_" + item.id;
        }
    };
    DashboardComponent.prototype.closeCustomize = function () {
        if (this.customizeComponentData) {
            this.customizeBarContext.closeSettingsComponent(this.customizeComponentData);
            this.customizeComponentData = undefined;
        }
    };
    return DashboardComponent;
}());
export { DashboardComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as QRCode from 'qrcode';
import { getColorHexStr } from '@modules/colors';
import { elementResize$, isSet } from '@shared';
var QrCodeComponent = /** @class */ (function () {
    function QrCodeComponent(el, cd) {
        this.el = el;
        this.cd = cd;
        this.value = '';
        this.cls = true;
    }
    Object.defineProperty(QrCodeComponent.prototype, "minWidth", {
        get: function () {
            return isSet(this.error) ? 120 : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QrCodeComponent.prototype, "backgroundImage", {
        get: function () {
            return "url(" + this.imageUrl + ")";
        },
        enumerable: true,
        configurable: true
    });
    QrCodeComponent.prototype.ngOnInit = function () {
        var _this = this;
        elementResize$(this.el.nativeElement)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.render(); });
    };
    QrCodeComponent.prototype.ngOnDestroy = function () { };
    QrCodeComponent.prototype.ngOnChanges = function (changes) {
        if ([changes.value, changes.fillColor, changes.backgroundColor].some(function (item) { return item && !item.firstChange; })) {
            this.render();
        }
    };
    QrCodeComponent.prototype.render = function () {
        var _this = this;
        var boundsWidth = this.el.nativeElement.offsetWidth;
        var boundsHeight = this.el.nativeElement.offsetHeight;
        var size = Math.min(boundsWidth, boundsHeight) * window.devicePixelRatio;
        var fillColor = isSet(this.fillColor) ? getColorHexStr(this.fillColor) : undefined;
        var backgroundColor = isSet(this.backgroundColor) ? getColorHexStr(this.backgroundColor) : undefined;
        QRCode.toDataURL(this.value, {
            width: size,
            margin: 0,
            color: __assign({}, (fillColor && { dark: fillColor }), { light: backgroundColor ? backgroundColor : '#ffffff00' })
        }, function (err, url) {
            if (isSet(err)) {
                console.error(err);
                _this.imageUrl = undefined;
                _this.error = 'Invalid value';
                _this.cd.markForCheck();
            }
            else {
                _this.imageUrl = url;
                _this.error = undefined;
                _this.cd.markForCheck();
            }
        });
    };
    return QrCodeComponent;
}());
export { QrCodeComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../field-components/components/custom-select/custom-select-dropdown/custom-select-dropdown.component.ngfactory";
import * as i2 from "../../../../field-components/components/custom-select/custom-select-dropdown/custom-select-dropdown.component";
import * as i3 from "./add-step-dropdown.component";
import * as i4 from "../../../../projects/stores/current-project.store";
import * as i5 from "../../../../projects/stores/current-environment.store";
import * as i6 from "../../../../../core/services/local-storage/local.storage";
var styles_AddStepDropdownComponent = [];
var RenderType_AddStepDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddStepDropdownComponent, data: {} });
export { RenderType_AddStepDropdownComponent as RenderType_AddStepDropdownComponent };
export function View_AddStepDropdownComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-select-dropdown", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.onButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CustomSelectDropdownComponent_0, i1.RenderType_CustomSelectDropdownComponent)), i0.ɵdid(1, 638976, null, 0, i2.CustomSelectDropdownComponent, [i0.ChangeDetectorRef], { items: [0, "items"], searchEnabled: [1, "searchEnabled"], emptyLabel: [2, "emptyLabel"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = true; var currVal_2 = "Nothing here"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AddStepDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-step-dropdown", [], null, null, null, View_AddStepDropdownComponent_0, RenderType_AddStepDropdownComponent)), i0.ɵdid(1, 245760, null, 0, i3.AddStepDropdownComponent, [i4.CurrentProjectStore, i5.CurrentEnvironmentStore, i6.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddStepDropdownComponentNgFactory = i0.ɵccf("app-add-step-dropdown", i3.AddStepDropdownComponent, View_AddStepDropdownComponent_Host_0, { actionTypesEnabled: "actionTypesEnabled" }, { buttonClick: "buttonClick" }, []);
export { AddStepDropdownComponentNgFactory as AddStepDropdownComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./render.component";
var styles_RenderComponent = [];
var RenderType_RenderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenderComponent, data: {} });
export { RenderType_RenderComponent as RenderType_RenderComponent };
function View_RenderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 1, 0, currVal_0); }); }
function View_RenderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_RenderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderComponent_3)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _co.template; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_RenderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.template; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.template; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RenderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-render", [], null, null, null, View_RenderComponent_0, RenderType_RenderComponent)), i0.ɵdid(1, 638976, null, 0, i2.RenderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RenderComponentNgFactory = i0.ɵccf("app-render", i2.RenderComponent, View_RenderComponent_Host_0, { content: "content", context: "context" }, {}, []);
export { RenderComponentNgFactory as RenderComponentNgFactory };

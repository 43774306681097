<a
  *ngIf="link"
  [appLink]="link"
  class="background-tab"
  [class.background-tab_active]="active"
  (click)="tabClick.emit($event)"
>
  {{ label }}
</a>

<a
  *ngIf="!link"
  href="javascript:void(0)"
  class="background-tab"
  [class.background-tab_active]="active"
  (click)="tabClick.emit($event)"
>
  {{ label }}
</a>

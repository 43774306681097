import { modelFieldToDisplayField, WidgetElementItem } from '@modules/customize';
import { ValueWidget } from '@modules/dashboard';
import { DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { Input, InputValueType } from '@modules/fields';
import { ModelFieldType } from '@modules/models';
import { QueryType, ValueWidgetQuery } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { isSet } from '@shared';
var ValueWidgetGenerator = /** @class */ (function () {
    function ValueWidgetGenerator() {
    }
    ValueWidgetGenerator.prototype.getElement = function (resource, modelDescription, options) {
        var _a, _b;
        var widget = new ValueWidget();
        var query = new ValueWidgetQuery();
        query.queryType = QueryType.Simple;
        query.simpleQuery = new query.simpleQueryClass();
        query.simpleQuery.model = modelDescription.model;
        if (isSet(options.label)) {
            widget.nameInput = new Input().deserializeFromStatic('value', options.label);
        }
        widget.dataSource = new ValueWidgetDataSource();
        widget.dataSource.type = DataSourceType.Query;
        widget.dataSource.queryResource = resource.uniqueName;
        widget.dataSource.query = query;
        widget.dataSource.columns = modelDescription.fields
            .filter(function (item) { return item.type == ModelFieldType.Db; })
            .map(function (item) {
            var result = modelFieldToDisplayField(item);
            if (result.name == modelDescription.primaryKeyField) {
                result.visible = false;
            }
            return result;
        })
            .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); });
        widget.dataSource.func = options.func;
        widget.dataSource.column = options.column;
        if (options.inputs) {
            (_a = widget.dataSource.queryInputs).push.apply(_a, options.inputs);
        }
        if (options.filters) {
            (_b = widget.dataSource.queryInputs).push.apply(_b, options.filters.map(function (item) {
                var input = new Input();
                input.name = item.name;
                input.valueType = InputValueType.StaticValue;
                input.staticValue = item.value;
                return input;
            }));
        }
        var element = new WidgetElementItem();
        if (options.uid) {
            element.uid = options.uid;
        }
        else {
            element.generateUid();
        }
        element.widget = widget;
        return element;
    };
    return ValueWidgetGenerator;
}());
export { ValueWidgetGenerator };

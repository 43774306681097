import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { createFormFieldFactory, ParameterValueArray, ParameterValueControl } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet } from '@shared';

@Component({
  selector: 'app-parameter-values',
  templateUrl: './parameter-values.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterValuesComponent implements OnInit {
  @Input() control: ParameterValueArray;
  @Input() customizable = false;

  createField = createFormFieldFactory();
  collapseContext = new SidebarCollapseContext();
  lastAddedParameterControl: ParameterValueControl;

  constructor() {}

  ngOnInit() {}

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.control.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addItem() {
    const name = this.getDistinctName('param');
    this.lastAddedParameterControl = this.control.appendControl(undefined, {
      name: name
    });
  }

  removeItem(control: ParameterValueControl) {
    this.control.removeControl(control);
  }

  dragDrop(event: CdkDragDrop<ParameterValueControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }

  getItemParams(params: object): Object {
    return {
      ...params,
      classes: ['select_fill', 'input_fill']
    };
  }
}

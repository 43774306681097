import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, QueryAction } from '@modules/actions';
import { ApiService } from '@modules/api';
import { ViewContextElement } from '@modules/customize';
import { getFieldDescriptionByType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ActionQuery, QueryType } from '@modules/queries';
import { Automation, AutomationService, AutomationTrigger, IntervalAutomationTrigger, ModelAutomationTrigger, TimetableAutomationTrigger, TimetableType, WebhookAutomationTrigger, Workflow } from '@modules/workflow';
import { forceObservable, isSet } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowTriggerComponent } from '../customize-workflow-trigger/customize-workflow-trigger.component';
var WorkflowTriggerComponent = /** @class */ (function () {
    function WorkflowTriggerComponent(customizeBarContext, currentProjectStore, currentEnvironmentStore, automationService, modelDescriptionStore, workflowEditContext, actionService, contextElement, apiService, cd) {
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.automationService = automationService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.workflowEditContext = workflowEditContext;
        this.actionService = actionService;
        this.contextElement = contextElement;
        this.apiService = apiService;
        this.cd = cd;
        this.triggerEditable = false;
        this.workflowEditable = false;
        this.parametersEnabled = false;
        this.customizeInitial = false;
        this.triggerChanged = new EventEmitter();
        this.customizeComponentData$ = new BehaviorSubject(undefined);
        this.customizing$ = new BehaviorSubject(false);
        this.webhookUpdated$ = new Subject();
    }
    WorkflowTriggerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextElement.initGlobal({
            uniqueName: 'workflow',
            name: this.parametersEnabled ? 'Workflow parameters' : 'Trigger parameters'
        });
        this.updateContextOutputs();
        if (this.workflow && this.workflow.testParameters) {
            this.contextElement.setOutputValues(this.workflow.testParameters);
        }
        combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
            .pipe(debounceTime(10), map(function (_a) {
            var customizeComponentData = _a[0], components = _a[1];
            return customizeComponentData && components[0] === customizeComponentData;
        }), startWith(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (value) { return _this.customizing$.next(value); });
        if (this.customizeInitial) {
            this.customize();
        }
    };
    WorkflowTriggerComponent.prototype.ngOnDestroy = function () { };
    WorkflowTriggerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.triggerOutputs || changes.trigger || changes.parametersEnabled || changes.parameters) {
            this.updateTriggerDescription();
        }
    };
    WorkflowTriggerComponent.prototype.updateContextOutputs = function () {
        var _this = this;
        if (this.webhookSubscription) {
            this.webhookSubscription.unsubscribe();
            this.webhookSubscription = undefined;
        }
        if (this.triggerOutputs) {
            this.contextElement.setOutputs(this.triggerOutputs.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                var icon = isSet(item.icon) ? item.icon : fieldDescription.icon;
                return {
                    uniqueName: item.name,
                    name: item.verboseName,
                    icon: icon,
                    // iconOrange: primaryKey,
                    fieldType: item.field,
                    fieldParams: item.params,
                    external: true
                };
            }));
        }
        else if (this.triggerEditable) {
            if (this.trigger instanceof ModelAutomationTrigger) {
                var actionDescription = new ActionDescription();
                actionDescription.resource = this.trigger.resource;
                actionDescription.type = ActionType.Query;
                actionDescription.model = this.trigger.model;
                actionDescription.modelAction = this.trigger.action;
                actionDescription.queryAction = new QueryAction();
                actionDescription.queryAction.query = new ActionQuery();
                actionDescription.queryAction.query.queryType = QueryType.Simple;
                actionDescription.queryAction.query.simpleQuery = new actionDescription.queryAction.query.simpleQueryClass();
                actionDescription.queryAction.query.simpleQuery.name = "__" + this.trigger.model + "__" + this.trigger.action;
                var action = new ActionItem();
                action.actionDescription = actionDescription;
                this.actionService
                    .getActionOutputs(action)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (result) {
                    _this.contextElement.setOutputs(result.outputs.map(function (item) {
                        var fieldDescription = getFieldDescriptionByType(item.field);
                        var icon = fieldDescription ? fieldDescription.icon : undefined;
                        return {
                            uniqueName: item.name,
                            name: item.verboseName,
                            icon: icon,
                            // iconOrange: primaryKey,
                            fieldType: item.field,
                            fieldParams: item.params,
                            external: true
                        };
                    }));
                });
            }
            else if (this.trigger instanceof WebhookAutomationTrigger) {
                this.automationService
                    .getAutomationTriggerWebhook(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.automation)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (webhook) {
                    _this.updateWebhookContextOutputs(webhook);
                    if (webhook) {
                        _this.webhookSubscription = _this.automationService
                            .subscribeAutomationTriggerWebhook(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, webhook)
                            .pipe(untilDestroyed(_this))
                            .subscribe(function (result) {
                            _this.updateWebhookContextOutputs(result);
                            _this.webhookUpdated$.next(result);
                        });
                    }
                }, function () {
                    _this.updateWebhookContextOutputs(undefined);
                });
            }
            else {
                this.contextElement.setOutputs([]);
            }
        }
        else {
            this.contextElement.setOutputs(this.parameters.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                var icon = fieldDescription ? fieldDescription.icon : undefined;
                return {
                    uniqueName: item.name,
                    name: item.verboseName,
                    icon: icon,
                    // iconOrange: primaryKey,
                    fieldType: item.field,
                    fieldParams: item.params,
                    external: true
                };
            }));
        }
    };
    WorkflowTriggerComponent.prototype.updateWebhookContextOutputs = function (webhook) {
        var outputs = webhook && isSet(webhook.dataStructure) ? webhook.dataStructure : [];
        this.contextElement.setOutputs(outputs.map(function (item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            var icon = fieldDescription ? fieldDescription.icon : undefined;
            return {
                uniqueName: item.name,
                name: item.verboseName,
                icon: icon,
                // iconOrange: primaryKey,
                fieldType: item.field,
                fieldParams: item.params,
                external: true
            };
        }));
    };
    WorkflowTriggerComponent.prototype.updateTriggerDescription = function () {
        var _this = this;
        forceObservable(this.getTriggerDescription())
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.triggerDescription = value;
            _this.cd.markForCheck();
        });
    };
    WorkflowTriggerComponent.prototype.getTriggerDescription = function () {
        if (this.triggerOutputs) {
            return this.triggerOutputs.length == 1 ? '1 parameter' : this.triggerOutputs.length + " parameters";
        }
        else if (this.trigger instanceof WebhookAutomationTrigger) {
            var hookPath = this.trigger.getRunPath();
            if (!this.trigger) {
                return;
            }
            return this.apiService.workflowsMethodURL(hookPath);
        }
        else if (this.trigger instanceof IntervalAutomationTrigger) {
            return "Every " + (this.trigger.interval > 1 ? this.trigger.interval + " minutes" : this.trigger.interval + " minute");
        }
        else if (this.trigger instanceof TimetableAutomationTrigger) {
            if (this.trigger.timetableType == TimetableType.EveryDay) {
                if (!isSet(this.trigger.timetableTime)) {
                    return 'Every day';
                }
                return "Every day at " + this.trigger.timetableTime.format('HH:mm') + " UTC";
            }
            else if (this.trigger.timetableType == TimetableType.EveryWeek) {
                if (!this.trigger.timetableDaysOfWeek ||
                    !this.trigger.timetableDaysOfWeek.length ||
                    !isSet(this.trigger.timetableTime)) {
                    return 'Every week';
                }
                var daysOfWeek = this.trigger.timetableDaysOfWeek.map(function (item) {
                    return moment().startOf('isoWeek').add(item, 'day').format('ddd');
                });
                return "Every " + daysOfWeek.join(', ') + " at " + this.trigger.timetableTime.format('HH:mm') + " UTC";
            }
            else if (this.trigger.timetableType == TimetableType.EveryMonth) {
                if (!this.trigger.timetableMonths ||
                    !this.trigger.timetableDays.length ||
                    !this.trigger.timetableMonths.length ||
                    !isSet(this.trigger.timetableTime)) {
                    return 'Every month';
                }
                var months = this.trigger.timetableMonths.map(function (item) {
                    return moment().startOf('year').add(item, 'month').format('MMM');
                });
                return "Every " + months.join(', ') + " on " + this.trigger.timetableDays.join(', ') + " at " + this.trigger.timetableTime.format('HH:mm') + " UTC";
            }
            else if (this.trigger.timetableType == TimetableType.Once) {
                if (!this.trigger.timetableDate) {
                    return 'Once';
                }
                return "At " + this.trigger.timetableDate.format('ll');
            }
            else if (this.trigger.timetableType == TimetableType.Custom) {
                return "Custom";
            }
        }
        else if (this.trigger instanceof ModelAutomationTrigger) {
            if (!isSet(this.trigger.resource) || !isSet(this.trigger.model) || !isSet(this.trigger.action)) {
                return;
            }
            var modelId = [this.trigger.resource, this.trigger.model].join('.');
            var action_1 = this.trigger.action;
            return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                if (!modelDescription) {
                    return;
                }
                return [modelDescription.verboseNamePlural, action_1].join(' ');
            }));
        }
        else if (this.parametersEnabled) {
            return this.parameters.length == 1 ? '1 parameter' : this.parameters.length + " parameters";
        }
    };
    WorkflowTriggerComponent.prototype.customize = function () {
        var _this = this;
        if (this.customizing$.value) {
            return;
        }
        var dynamicComponent = {
            component: CustomizeWorkflowTriggerComponent,
            inputs: {
                automation: this.automation,
                triggerEditable: this.triggerEditable,
                trigger: this.trigger,
                triggerOutputs: this.triggerOutputs,
                triggerData: this.triggerData,
                testParameters: this.workflow ? this.workflow.testParameters : undefined,
                workflowEditable: this.workflowEditable,
                parametersEnabled: this.parametersEnabled,
                parameters: this.parameters,
                webhookUpdated$: this.webhookUpdated$,
                contextElement: this.contextElement
            },
            outputs: {
                workflowChange: [
                    function (result) {
                        var _a;
                        _this.trigger = result.trigger;
                        _this.workflow.testParameters = result.testParameters;
                        (_a = _this.parameters).splice.apply(_a, [0, _this.parameters.length].concat(result.parameters));
                        _this.cd.markForCheck();
                        _this.triggerChanged.emit(_this.trigger);
                        _this.updateContextOutputs();
                        _this.workflowEditContext.markChanged();
                    }
                ]
            }
        };
        this.customizeBarContext.setSettingsComponent(dynamicComponent);
        this.customizeComponentData$.next(dynamicComponent);
    };
    return WorkflowTriggerComponent;
}());
export { WorkflowTriggerComponent };

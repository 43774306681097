var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService, LocalStorage } from '@core';
import { isSet, SingletonStore, stripEnd } from '@shared';
import { DomainService } from '../services/domain/domain.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/domain/domain.service";
import * as i2 from "../../../core/services/app-config/app-config.service";
import * as i3 from "../../../core/services/local-storage/local.storage";
var DomainStore = /** @class */ (function (_super) {
    __extends(DomainStore, _super);
    function DomainStore(domainService, appConfigService, localStorage) {
        var _this = _super.call(this) || this;
        _this.domainService = domainService;
        _this.appConfigService = appConfigService;
        _this.localStorage = localStorage;
        _this.lastFetch = new BehaviorSubject(undefined);
        _this.getFirst().subscribe(function (domain) {
            if (!domain) {
                return;
            }
            if (isSet(domain.language) && !isSet(_this.localStorage.getLanguage())) {
                _this.localStorage.setLanguage(domain.language);
            }
            if (isSet(domain.timezone) && !isSet(_this.localStorage.getTimezone())) {
                _this.localStorage.setTimezone(domain.timezone);
            }
        });
        return _this;
    }
    DomainStore.prototype.getCurrentDomain = function () {
        var subdomainSuffix = "." + this.appConfigService.subdomainsBaseDomain;
        if (window.location.hostname.toLowerCase().endsWith(subdomainSuffix.toLowerCase())) {
            return {
                domain: stripEnd(window.location.hostname, subdomainSuffix),
                custom: false
            };
        }
        return {
            domain: window.location.hostname,
            custom: true
        };
    };
    DomainStore.prototype.executeFetch = function () {
        // if (!isProductionLocation()) {
        //   return of(undefined);
        // }
        var domain = this.getCurrentDomain();
        // let webHostName: string;
        //
        // try {
        //   webHostName = new URL(this.appConfigService.webBaseUrl).hostname;
        // } catch (e) {}
        //
        // if (domain == webHostName) {
        //   return of(undefined);
        // }
        return this.domainService.get(domain.domain, domain.custom).pipe(catchError(function () { return of(undefined); }));
    };
    DomainStore.prototype.fetch = function () {
        var _this = this;
        if (this.lastFetch.value !== undefined) {
            return of(this.lastFetch.value);
        }
        return this.executeFetch().pipe(map(function (domain) {
            var result = isSet(domain) ? domain : null;
            _this.lastFetch.next(result);
            return result;
        }));
    };
    DomainStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.lastFetch.next(undefined);
    };
    DomainStore.ngInjectableDef = i0.defineInjectable({ factory: function DomainStore_Factory() { return new DomainStore(i0.inject(i1.DomainService), i0.inject(i2.AppConfigService), i0.inject(i3.LocalStorage)); }, token: DomainStore, providedIn: "root" });
    return DomainStore;
}(SingletonStore));
export { DomainStore };

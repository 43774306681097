import { ChangeDetectorRef, Injectable, Injector } from '@angular/core';
import { AsyncValidatorFn, FormControl, Validators } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, ResourceType, SecretTokenService } from '@modules/projects';
import { IsOptionsValidResult, XanoGeneratorService, XanoParamsOptions } from '@modules/resource-generators';
import { ResourceControllerService, ResourceParamsResult, XanoResourceController } from '@modules/resources';
import { isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class XanoResourceSettingsForm extends BaseResourceSettingsForm<XanoParamsOptions> {
  form = new AppFormGroup({
    access_token: new FormControl('', Validators.required, this.validateAccessToken()),
    domain: new FormControl('', Validators.required),
    workspace_id: new FormControl('', Validators.required),
    sync: new FormControl(true)
  });

  controller: XanoResourceController;
  legacyOptions = false;

  constructor(
    private xanoGeneratorService: XanoGeneratorService,
    private resourceControllerService: ResourceControllerService,
    private cd: ChangeDetectorRef,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
    this.controller = this.resourceControllerService.get<XanoResourceController>(ResourceType.Xano);
  }

  validateAccessToken(): AsyncValidatorFn {
    return control => {
      return timer(200).pipe(
        switchMap(() => {
          if (!isSet(control.value)) {
            return of([]);
          }

          return this.controller.getInstances(control.value);
        }),
        map(() => undefined),
        finalize(() => this.cd.markForCheck()),
        catchError(error => {
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
          return of({ server: errorMessage ? [errorMessage] : true });
        })
      );
    };
  }

  initResourceValue(): Observable<void> {
    return this.xanoGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.legacyOptions = isSet(result.apiBaseUrl) && !isSet(result.accessToken);

        this.form.patchValue({
          access_token: result.accessToken,
          domain: result.domain,
          workspace_id: result.workspaceId,
          sync: !!this.resource.isSynced()
        });
      })
    );
  }

  getOptions(): XanoParamsOptions {
    return {
      accessToken: this.form.value['access_token'],
      domain: this.form.value['domain'],
      workspaceId: this.form.value['workspace_id']
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.xanoGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.xanoGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name'],
            sync: this.form.value['sync'],
            syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
          };
        })
      );
  }
}

<mat-menu #project_dropdown="matMenu">
  <ng-template matMenuContent>
    <app-projects-item-dropdown
      [project]="project"
      [environment]="environment"
      [externalToken]="externalToken"
    ></app-projects-item-dropdown>
  </ng-template>
</mat-menu>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.Builder,
      environmentName: environment ? environment.uniqueName : undefined,
      builderLink: openBuilderLink
    }).link as link
  "
  [routerLink]="link"
  class="projects-list-item projects-list-item_interactive desktop-only"
  [class.projects-list-item_active]="trigger.menuOpen"
>
  <div class="projects-list-item__logo">
    <app-project-logo
      class="projects-list-item__logo-inner projects-list-item__logo-inner_fade"
      [color]="project.logoColor"
      [logo]="project.logo"
      [initials]="project.initials"
      [fill]="project.logoFill"
    ></app-project-logo>

    <div
      *ngIf="environment && project.hasEnvironmentCustomizationPermission(environment)"
      class="projects-list-item__logo-label"
    >
      <span class="icon-design projects-list-item__logo-label-icon"></span>
      <ng-container *ngIf="openBuilderLink">{{ 'Open' | localize }}</ng-container>
      <ng-container *ngIf="!openBuilderLink">{{ 'Builder' | localize }}</ng-container>
    </div>

    <div
      *ngIf="!environment || !project.hasEnvironmentCustomizationPermission(environment)"
      class="projects-list-item__logo-label"
    >
      <span class="icon-earth_planet projects-list-item__logo-label-icon"></span>
      {{ 'App' | localize }}
    </div>

    <span *ngIf="!project.isCreated" class="projects-list-item__tag">{{ 'continue install' | localize }}</span>
    <div
      class="projects-list-item__logo-action icon-more"
      [matMenuTriggerFor]="project_dropdown"
      #trigger="matMenuTrigger"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    ></div>
  </div>
  <div class="projects-list-item__title">{{ project.name }}</div>
</a>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.Builder,
      environmentName: environment ? environment.uniqueName : undefined,
      builderLink: openBuilderLink
    }).href as href
  "
  [href]="getExternalHref(href)"
  class="projects-list-item projects-list-item_interactive desktop-only"
  [class.projects-list-item_active]="trigger.menuOpen"
>
  <div class="projects-list-item__logo">
    <app-project-logo
      class="projects-list-item__logo-inner projects-list-item__logo-inner_fade"
      [color]="project.logoColor"
      [logo]="project.logo"
      [initials]="project.initials"
      [fill]="project.logoFill"
    ></app-project-logo>

    <div
      *ngIf="environment && project.hasEnvironmentCustomizationPermission(environment)"
      class="projects-list-item__logo-label"
    >
      <span class="icon-design projects-list-item__logo-label-icon"></span>
      <ng-container *ngIf="openBuilderLink">{{ 'Open' | localize }}</ng-container>
      <ng-container *ngIf="!openBuilderLink">{{ 'Builder' | localize }}</ng-container>
    </div>

    <div
      *ngIf="!environment || !project.hasEnvironmentCustomizationPermission(environment)"
      class="projects-list-item__logo-label"
    >
      <span class="icon-earth_planet projects-list-item__logo-label-icon"></span>
      {{ 'App' | localize }}
    </div>

    <span *ngIf="!project.isCreated" class="projects-list-item__tag">{{ 'continue install' | localize }}</span>
    <div
      class="projects-list-item__logo-action icon-more"
      [matMenuTriggerFor]="project_dropdown"
      #trigger="matMenuTrigger"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    ></div>
  </div>
  <div class="projects-list-item__title">{{ project.name }}</div>
</a>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.App,
      environmentName: environment ? environment.uniqueName : undefined
    }).link as link
  "
  [routerLink]="link"
  class="projects-list-item projects-list-item_interactive mobile-only"
  [class.projects-list-item_active]="trigger.menuOpen"
>
  <div class="projects-list-item__logo">
    <app-project-logo
      class="projects-list-item__logo-inner projects-list-item__logo-inner_fade"
      [color]="project.logoColor"
      [logo]="project.logo"
      [initials]="project.initials"
      [fill]="project.logoFill"
    ></app-project-logo>
    <div class="projects-list-item__logo-label">
      <span class="icon-earth_planet projects-list-item__logo-label-icon"></span>
      {{ 'App' | localize }}
    </div>
    <span *ngIf="!project.isCreated" class="projects-list-item__tag">{{ 'continue install' | localize }}</span>
    <div
      class="projects-list-item__logo-action icon-more"
      [matMenuTriggerFor]="project_dropdown"
      #trigger="matMenuTrigger"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    ></div>
  </div>
  <div class="projects-list-item__title">{{ project.name }}</div>
</a>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.App,
      environmentName: environment ? environment.uniqueName : undefined
    }).href as href
  "
  [href]="getExternalHref(href)"
  class="projects-list-item projects-list-item_interactive mobile-only"
  [class.projects-list-item_active]="trigger.menuOpen"
>
  <div class="projects-list-item__logo">
    <app-project-logo
      class="projects-list-item__logo-inner projects-list-item__logo-inner_fade"
      [color]="project.logoColor"
      [logo]="project.logo"
      [initials]="project.initials"
      [fill]="project.logoFill"
    ></app-project-logo>
    <div class="projects-list-item__logo-label">
      <span class="icon-earth_planet projects-list-item__logo-label-icon"></span>
      {{ 'App' | localize }}
    </div>
    <span *ngIf="!project.isCreated" class="projects-list-item__tag">{{ 'continue install' | localize }}</span>
    <div
      class="projects-list-item__logo-action icon-more"
      [matMenuTriggerFor]="project_dropdown"
      #trigger="matMenuTrigger"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    ></div>
  </div>
  <div class="projects-list-item__title">{{ project.name }}</div>
</a>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var StepsItem = /** @class */ (function () {
    function StepsItem() {
    }
    StepsItem.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.value = data['value'];
        this.name = data['name'];
        this.description = data['description'];
        this.color = data['color'];
        if (!this.uid) {
            this.generateUid();
        }
        if (data['visible_input']) {
            this.visibleInput = new Input().deserialize(data['visible_input']);
        }
        else {
            this.visibleInput = undefined;
        }
        return this;
    };
    StepsItem.prototype.serialize = function (fields) {
        var data = {
            value: this.value,
            name: this.name,
            description: this.description,
            color: this.color,
            visible_input: this.visibleInput ? this.visibleInput.serialize() : null
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    StepsItem.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(4, { letterFirst: true });
    };
    return StepsItem;
}());
export { StepsItem };
var StepsElementItem = /** @class */ (function (_super) {
    __extends(StepsElementItem, _super);
    function StepsElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Steps;
        _this.items = [];
        return _this;
    }
    StepsElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['items']) {
            this.items = this.params['items'].map(function (item) { return new StepsItem().deserialize(item); });
        }
        if (this.params['current_item']) {
            this.currentItem = new Input().deserialize(this.params['current_item']);
        }
        return this;
    };
    StepsElementItem.prototype.serialize = function () {
        this.params = {
            items: this.items.map(function (item) { return item.serialize(); }),
            current_item: this.currentItem ? this.currentItem.serialize() : null
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(StepsElementItem.prototype, "analyticsName", {
        get: function () {
            return 'steps';
        },
        enumerable: true,
        configurable: true
    });
    StepsElementItem.prototype.defaultName = function () {
        return 'Steps';
    };
    return StepsElementItem;
}(ElementItem));
export { StepsElementItem };
registerElementForType(ElementType.Steps, StepsElementItem);

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { CurrentProjectStore } from '@modules/projects';

@Component({
  selector: 'app-feature-overview-line',
  templateUrl: './feature-overview-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureOverviewLineComponent implements OnInit, OnDestroy {
  constructor(public currentProjectStore: CurrentProjectStore) {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { controlValue } from '@shared';
export function flattenItems(items) {
    return items.reduce(function (acc, item) {
        acc.push(item);
        if (item.children) {
            acc.push.apply(acc, flattenItems(item.children));
        }
        return acc;
    }, []);
}
export function flattenItemOptions(items) {
    return flattenItems(items)
        .filter(function (item) { return item.option; })
        .map(function (item) { return item.option; });
}
var CustomSelectComponent = /** @class */ (function () {
    function CustomSelectComponent(cd) {
        this.cd = cd;
        this.items = [];
        this.compareWith = this.defaultCompare;
        this.emptyLabel = 'No items';
        this.chooseLabel = 'Choose';
        this.resetEnabled = false;
        this.resetValue = undefined;
        this.classes = [];
        this.panelClasses = [];
        this.capitalize = true;
        this.allowSearch = true;
        this.optionClick = new EventEmitter();
        this.buttonClick = new EventEmitter();
        this.optionItemsFlatten = [];
    }
    CustomSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.control)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.updateCurrentOption(value);
        });
    };
    CustomSelectComponent.prototype.ngOnDestroy = function () { };
    CustomSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes['items']) {
            this.optionItemsFlatten = this.items ? flattenItems(this.items).filter(function (item) { return item.option; }) : [];
        }
        if (changes['items'] || changes['options'] || changes['compareWith']) {
            this.updateCurrentOption(this.control.value);
        }
    };
    Object.defineProperty(CustomSelectComponent.prototype, "panelClassesStr", {
        get: function () {
            var classes = this.panelClasses.slice();
            // if (this.searchEnabled) {
            classes.push('mat-menu-panel_no-top-padding');
            // }
            // if (this.filteredOptions.find(item => item.data && item.data['stickyBottom'])) {
            classes.push('mat-menu-panel_no-bottom-padding');
            // }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    CustomSelectComponent.prototype.defaultCompare = function (o1, o2) {
        return o1 == o2;
    };
    CustomSelectComponent.prototype.updateCurrentOption = function (value) {
        var _this = this;
        this.currentOptionItem = this.optionItemsFlatten.find(function (item) { return _this.compareWith(item.option.value, value); });
        this.cd.markForCheck();
    };
    CustomSelectComponent.prototype.resetOption = function () {
        this.control.patchValue(this.resetValue);
        this.control.markAsDirty();
    };
    CustomSelectComponent.prototype.onOptionClick = function (option) {
        this.control.patchValue(option.value);
        this.control.markAsDirty();
        this.optionClick.next(option);
    };
    CustomSelectComponent.prototype.onButtonClick = function (button) {
        this.buttonClick.next(button);
    };
    CustomSelectComponent.prototype.openDropdown = function () {
        if (this.trigger) {
            this.trigger.openMenu();
        }
    };
    CustomSelectComponent.prototype.closeDropdown = function () {
        if (this.trigger) {
            this.trigger.closeMenu();
        }
    };
    return CustomSelectComponent;
}());
export { CustomSelectComponent };

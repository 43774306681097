import toPairs from 'lodash/toPairs';
import * as mimeTypes from '../../data/mime-types.json';
export function getMimeExtension(mime) {
    if (typeof mime !== 'string') {
        return;
    }
    mime = mime.toLowerCase();
    if (mime == 'application/octet-stream') {
        return;
    }
    var mimeType = mimeTypes[mime];
    return mimeType && mimeType['extensions'] ? mimeType['extensions'][0] : undefined;
}
export function getExtensionMime(extension) {
    if (typeof extension !== 'string') {
        return;
    }
    extension = extension.toLowerCase();
    var mimeType = toPairs(mimeTypes).find(function (_a) {
        var k = _a[0], v = _a[1];
        return v['extensions'] && v['extensions'].includes(extension);
    });
    return mimeType ? mimeType[0] : undefined;
}
export function isImageMime(mime) {
    if (typeof mime !== 'string') {
        return;
    }
    return mime.startsWith('image/');
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { environment } from '@env/environment';
import { ServerRequestError } from '@modules/api';
import { Domain, getActualDomain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Project, ProjectService } from '@modules/projects';
import { controlValue, isSet, openUrl } from '@shared';

import { ProjectUniqueNameForm } from './project-unique-name.form';

@Component({
  selector: 'app-project-unique-name',
  templateUrl: './project-unique-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectUniqueNameForm]
})
export class ProjectUniqueNameComponent implements OnInit, OnDestroy {
  @Output() updated = new EventEmitter<Project>();

  @ViewChild('subdomain_input') subdomainInputElement: ElementRef;
  @ViewChild('subdomain_bounds') subdomainBoundsElement: ElementRef;

  createField = createFormFieldFactory();
  loading = false;
  appBaseUrl: string;
  domain: Domain;
  subdomainsBaseDomain = environment.subdomainsBaseDomain;
  uniqueNameIsUsed = false;
  uniqueNameIsUsedLoading = false;
  subdomainIsUsed = false;
  subdomainIsUsedLoading = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public form: ProjectUniqueNameForm,
    private appConfigService: AppConfigService,
    private projectDomainController: ProjectDomainController,
    private projectService: ProjectService,
    private notificationService: NotificationService,
    private popupComponent: BasePopupComponent,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.domain = project.domain;
        this.form.init(project);
        this.cd.markForCheck();
      });

    combineLatest(this.currentProjectStore.get(), controlValue(this.form.controls.subdomain))
      .pipe(untilDestroyed(this))
      .subscribe(([project, subdomain]) => {
        this.appBaseUrl = this.getAppBaseUrl(project, subdomain);
        this.cd.markForCheck();
      });

    this.form.controls.unique_name.valueChanges
      .pipe(
        debounceTime(200),
        switchMap(value => {
          if (!isSet(value)) {
            return of(false);
          } else if (this.form.instance && value == this.form.instance.uniqueName) {
            return of(false);
          }

          this.uniqueNameIsUsed = false;
          this.uniqueNameIsUsedLoading = true;
          this.cd.markForCheck();

          return this.projectService.isUsedUniqueName(value).pipe(catchError(() => of(false)));
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.uniqueNameIsUsed = result;
        this.uniqueNameIsUsedLoading = false;
        this.cd.markForCheck();
      });

    this.form.controls.subdomain.valueChanges
      .pipe(
        debounceTime(200),
        switchMap(value => {
          if (!isSet(value)) {
            return of(false);
          } else if (
            this.form.instance &&
            this.form.instance.domain &&
            !this.form.instance.domain.custom &&
            value == this.form.instance.domain.domain
          ) {
            return of(false);
          }

          this.subdomainIsUsed = false;
          this.subdomainIsUsedLoading = true;
          this.cd.markForCheck();

          return this.projectService.isUsedDomainName(value).pipe(catchError(() => of(false)));
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.subdomainIsUsed = result;
        this.subdomainIsUsedLoading = false;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setSubdomainValue(value: string) {
    const element = this.subdomainInputElement.nativeElement;
    const selection = window.getSelection();
    const currentRange = selection.getRangeAt(0);
    const startOffset = currentRange.startOffset;
    const endOffset = currentRange.endOffset;
    const cleanValue = this.form.cleanValue(value);

    this.form.controls.subdomain.patchValue(cleanValue);
    this.cd.detectChanges();

    element.textContent = cleanValue;

    const node = element.childNodes[0] || element;
    const nodeValue = element.textContent;
    const range = document.createRange();

    range.setStart(node, Math.min(startOffset, nodeValue.length));
    range.setEnd(node, Math.min(endOffset, nodeValue.length));

    selection.removeAllRanges();
    selection.addRange(range);
  }

  onSubdomainInputClick() {
    const element = this.subdomainInputElement.nativeElement;
    const selection = window.getSelection();
    const range = document.createRange();
    const node = element.childNodes[0] || element;
    const nodeValue = element.textContent;

    range.setStart(node, nodeValue.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  }

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.updated.emit(result);

          this.notificationService.success(
            'App URL changed',
            `<strong>${this.currentProjectStore.instance.name}</strong> App URL was successfully changed`
          );

          const urlSegments = window.location.pathname.split('/');

          if (this.currentEnvironmentStore.instance.version) {
            urlSegments[3] = result.uniqueName;
          } else {
            urlSegments[2] = result.uniqueName;
          }

          const project = this.currentProjectStore.instance;
          const webBaseUrl = project.domain
            ? `${project.protocol}://${project.domain.actualDomain}`
            : this.appConfigService.webBaseUrl;
          const newUrl = webBaseUrl + urlSegments.join('/');

          openUrl(newUrl);
        },
        error => {
          console.error(error);
          this.loading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('App URL change failed', error.errors[0]);
          } else {
            this.notificationService.error('App URL change failed', error);
          }
        }
      );
  }

  getAppBaseUrl(project: Project, subdomain: string): string {
    if (project.domain && !project.domain.custom) {
      const webBaseUrl = getActualDomain(subdomain, false);
      return `${webBaseUrl}/app/`;
    } else {
      let webBaseHost = '';

      try {
        webBaseHost = new URL(this.appConfigService.webBaseUrl).host;
      } catch (e) {}

      const webBaseUrl = project.domain ? project.domain.actualDomain : webBaseHost;
      return `${webBaseUrl}/app/`;
    }
  }

  editCustomDomain() {
    this.projectDomainController.edit({ analyticsSource: 'customize_toolbar' }).pipe(untilDestroyed(this)).subscribe();
  }

  close() {
    this.popupComponent.close();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, share, shareReplay, switchMap } from 'rxjs/operators';
import Centrifuge from '@common/centrifuge';
import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/app-config/app-config.service";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "@angular/common/http";
var CentrifugoService = /** @class */ (function () {
    function CentrifugoService(appConfigService, apiService, http) {
        this.appConfigService = appConfigService;
        this.apiService = apiService;
        this.http = http;
        this.channelObservables = {};
    }
    CentrifugoService.prototype.getCentrifuge = function () {
        if (this.centrifuge) {
            return this.centrifuge;
        }
        this.centrifuge = new Centrifuge(this.appConfigService.centrifugoUrl, {
            debug: !this.appConfigService.production,
            subscribeEndpoint: this.apiService.methodURL('centrifugo/auth/'),
            subscribeHeaders: this.getHeaders()
        });
        this.connect();
        return this.centrifuge;
    };
    CentrifugoService.prototype.getHeaders = function () {
        var result = {};
        var headers = new HttpHeaders();
        headers = this.apiService.setHeadersToken(headers);
        headers.keys().forEach(function (key) {
            result[key] = headers.get(key);
        });
        return result;
    };
    CentrifugoService.prototype.refreshHeaders = function () {
        if (this.centrifuge) {
            this.centrifuge.setSubscribeHeaders(this.getHeaders());
        }
    };
    CentrifugoService.prototype.connect = function () {
        var _this = this;
        this.getUserToken().subscribe(function (token) {
            _this.centrifuge.setToken(token);
            _this.centrifuge.connect();
        });
    };
    CentrifugoService.prototype.disconnect = function () {
        if (this.centrifuge) {
            this.centrifuge.disconnect();
        }
    };
    CentrifugoService.prototype.subscribe = function (channel) {
        var _this = this;
        this.refreshHeaders();
        if (!this.channelObservables[channel]) {
            var subscribeObs = Observable.create(function (observer) {
                var subscription = _this.getCentrifuge().subscribe(channel, function (message) { return observer.next(message); });
                return function () {
                    subscription.unsubscribe();
                    delete _this.channelObservables[channel];
                };
            });
            this.channelObservables[channel] = subscribeObs.pipe(share());
        }
        return this.channelObservables[channel];
    };
    CentrifugoService.prototype.subscribeState = function (channel) {
        var _this = this;
        this.refreshHeaders();
        if (!this.channelObservables[channel]) {
            var subscribeObs = Observable.create(function (observer) {
                var subscription = _this.getCentrifuge().subscribe(channel, function (message) {
                    observer.next(message);
                });
                subscription.history({ limit: 1 }).then(function (ctx) {
                    var lastPublication = ctx.publications[0];
                    if (lastPublication) {
                        observer.next(lastPublication);
                    }
                });
                return function () {
                    subscription.unsubscribe();
                    delete _this.channelObservables[channel];
                };
            });
            this.channelObservables[channel] = subscribeObs.pipe(shareReplay(1));
        }
        return this.channelObservables[channel];
    };
    CentrifugoService.prototype.getUserToken = function () {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('centrifugo/token/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return result['token']; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CentrifugoService.ngInjectableDef = i0.defineInjectable({ factory: function CentrifugoService_Factory() { return new CentrifugoService(i0.inject(i1.AppConfigService), i0.inject(i2.ApiService), i0.inject(i3.HttpClient)); }, token: CentrifugoService, providedIn: "root" });
    return CentrifugoService;
}());
export { CentrifugoService };

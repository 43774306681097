import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CurrentUserStore } from '@modules/users';
import {
  CustomViewTemplate,
  CustomViewTemplateMetricType,
  CustomViewTemplateService,
  CustomViewTemplateType,
  View
} from '@modules/views';

import { ViewEditorController } from '../../services/view-editor-controller/view-editor.controller';

@Component({
  selector: 'app-custom-view-templates-detail',
  templateUrl: './custom-view-templates-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesDetailComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() nameEditingEnabled = true;
  @Input() viewCustomizeEnabled = true;
  @Input() stateSelectedEnabled = false;
  @Input() componentLabel = 'component';
  @Input() analyticsSource: string;
  @Output() selectView = new EventEmitter<{ view: View; template: CustomViewTemplate }>();
  @Output() showTemplateType = new EventEmitter<CustomViewTemplateType>();
  @Output() customizeTemplate = new EventEmitter<CustomViewTemplate>();
  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  template: CustomViewTemplate;
  previewScale: number;
  loading = false;
  error: string;

  constructor(
    public currentUserStore: CurrentUserStore,
    private customViewTemplateService: CustomViewTemplateService,
    private viewEditorController: ViewEditorController,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fetch();
  }

  ngOnDestroy(): void {}

  fetch() {
    this.template = undefined;
    this.loading = true;
    this.error = undefined;
    this.cd.markForCheck();

    this.customViewTemplateService
      .getDetail(this.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        value => {
          this.template = value;
          this.loading = false;
          this.cd.markForCheck();

          const frame = this.template.view ? this.template.view.frame : undefined;
          const maxWidth = 516;

          this.previewScale = frame && frame.width > maxWidth ? maxWidth / frame.width : undefined;
          this.cd.markForCheck();

          this.customViewTemplateService.metric(this.id, CustomViewTemplateMetricType.View).subscribe();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  createTemplateView(): View {
    const view = cloneDeep<View>(this.template.view);

    view.generateId();
    view.name = this.template.name;

    return view;
  }

  useView() {
    const view = this.createTemplateView();
    this.onViewSelected(view);
  }

  customizeView() {
    const view = this.createTemplateView();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.ViewCustomize, {
      Name: this.template.name,
      Source: this.analyticsSource
    });

    this.viewEditorController
      .open({
        create: true,
        view: view,
        componentLabel: this.componentLabel,
        submitLabel: 'Save changes',
        nameEditingEnabled: this.nameEditingEnabled,
        stateSelectedEnabled: this.stateSelectedEnabled,
        templatesEnabled: false,
        analyticsSource: 'view_templates_detail'
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.onViewSelected(result.view);
      });
  }

  onViewSelected(view: View) {
    this.selectView.emit({ view: view, template: this.template });
    this.customViewTemplateService.metric(this.id, CustomViewTemplateMetricType.Usage).subscribe();
  }
}

export class EnvironmentObjectMerge {
  public environmentFrom: string;
  public environmentTo: string;
  public objectType: string;
  public idRoot: string;
  public idTo: string;

  deserialize(data: Object): EnvironmentObjectMerge {
    this.environmentFrom = data['environment_from'];
    this.environmentTo = data['environment_to'];
    this.objectType = data['object_type'];
    this.idRoot = data['id_root'];
    this.idTo = data['id_to'];

    return this;
  }
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Image'"
    [titleEnabled]="titleEnabled"
    [backLabel]="'All components'"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-customize-bar-input-edit
              [label]="'Image'"
              [documentation]="'inputs'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_value'"
              [itemForm]="form.controls.value_input"
              [context]="context"
              [bindComponents]="true"
              [element]="element"
              [staticValueField]="fieldTypes.Image"
              [staticValueParams]="{
                output_format: form.controls.output_format.value,
                path: form.controls.path.value,
                fit: imageFits.Fit,
                storage_resource: form.controls.storage.value ? form.controls.storage.value['resource'] : undefined,
                storage_name: form.controls.storage.value ? form.controls.storage.value['name'] : undefined
              }"
              [staticValueVerticalHeight]="160"
              [placeholder]="'Choose image'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_value'"
            ></app-customize-bar-input-edit>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'upload type'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'output_format',
                    field: 'SelectField',
                    params: { options: form.outputFormatOptions, classes: ['select_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div *ngIf="form.controls.output_format.value == outputFormats.Storage" class="sidebar__element">
            <app-sidebar-card>
              <div class="sidebar__element">
                <app-sidebar-field>
                  <gxs-select
                    [placeholder]="'choose' | localize | appCapitalize"
                    [formControl]="form.controls.storage"
                    [source]="storageSelectSource"
                    [options]="{
                      theme: 'jet',
                      search: true,
                      searchMinimumLength: 1,
                      searchDebounce: 0,
                      classes: ['select_fill'],
                      labels: {} | localizeSelect
                    }"
                  >
                  </gxs-select>
                </app-sidebar-field>
              </div>

              <div class="sidebar__element">
                <app-sidebar-field
                  [label]="'Path inside storage'"
                  [additional]="'(optional)'"
                  [documentation]="'components/fields/media'"
                  [documentationLabel]="'See how it works'"
                  [documentationSource]="element.analyticsGenericName + '_path'"
                >
                  <app-input-edit
                    [itemForm]="form.controls.path"
                    [context]="context"
                    [placeholder]="'Path'"
                    [fill]="true"
                    [analyticsSource]="element.analyticsGenericName + '_path'"
                  ></app-input-edit>

                  <ng-container description>
                    Example: product/photo/
                  </ng-container>
                </app-sidebar-field>
              </div>
            </app-sidebar-card>
          </div>

          <div class="sidebar__row sidebar__element">
            <div class="sidebar__row-item">
              <div class="sidebar__element">
                <app-sidebar-field [label]="'display'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'display_type',
                        field: 'SelectField',
                        params: { options: form.displayTypeOptions, classes: ['select_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>
            </div>
            <div class="sidebar__row-item">
              <div class="sidebar__element">
                <app-sidebar-field [label]="'fit'">
                  <app-auto-field
                    [form]="form"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'fit',
                        field: 'SelectField',
                        params: { options: form.fitOptions, classes: ['select_fill'] }
                      })
                    "
                  ></app-auto-field>
                </app-sidebar-field>
              </div>
            </div>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Show preview on hover'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'show_preview',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Show fullscreen on click'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'lightbox',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When image clicked'"></app-sidebar-list-separator>
            </div>
            <div class="sidebar-list">
              <div class="sidebar-list__item">
                <app-action-single-edit
                  [options]="{ originEnabled: true }"
                  [title]="'Image click'"
                  [control]="form.controls.click_action"
                  [context]="context"
                  [defaultSubtitle]="'Not specified'"
                  [remove]="form.controls.click_action.value"
                  [icon]="'cursor'"
                  [object]="element.analyticsGenericName"
                  [firstInit]="firstInit"
                >
                </app-action-single-edit>
              </div>
            </div>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { ViewSettingsStore } from '@modules/customize';
import { AdminPanelSeparatePagesGenerator } from '@modules/customize-generators';
import { ModelDescriptionStore } from '@modules/model-queries';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { objectsSortPredicate } from '@shared';
import { getResourceAddModelComponents } from '../../data/resource-add-model-components';
import { ResourceModelEditController } from '../../services/resource-model-edit-controller/resource-model-edit.controller';
var ResourceChoosePagesComponent = /** @class */ (function () {
    function ResourceChoosePagesComponent(popupComponent, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, adminPanelSeparatePagesGenerator, resourceModelEditController, viewSettingsStore, cd) {
        this.popupComponent = popupComponent;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.adminPanelSeparatePagesGenerator = adminPanelSeparatePagesGenerator;
        this.resourceModelEditController = resourceModelEditController;
        this.viewSettingsStore = viewSettingsStore;
        this.cd = cd;
        this.pages = [];
        this.modelPages = false;
        this.optional = false;
        this.selected = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.items = [];
        this.selectedAll = false;
        this.addModelComponents = [];
    }
    ResourceChoosePagesComponent.prototype.ngOnInit = function () {
        this.updateItems();
        this.updateAddModel();
    };
    ResourceChoosePagesComponent.prototype.updateItems = function () {
        var _this = this;
        this.modelDescriptionStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (allModelDescriptions) {
            var defaultSelected = !_this.optional;
            var items = _this.pages.map(function (item) {
                var existingItem = _this.items.find(function (i) { return i.page.uniqueName == item.uniqueName; });
                return {
                    page: item,
                    selected: existingItem ? existingItem.selected : defaultSelected,
                    disabled: false
                };
            });
            if (_this.modelPages) {
                var modelDescriptions = allModelDescriptions.filter(function (item) { return item.resource == _this.resource.uniqueName; });
                var modelPages = modelDescriptions
                    .filter(function (item) {
                    if (!item.getQuery) {
                        return false;
                    }
                    else if (item.getParameters.some(function (parameter) { return parameter.required; })) {
                        return false;
                    }
                    else if (item.virtual) {
                        return true;
                    }
                    else if (!item.getDetailQuery &&
                        !item.getQuery.frontendFiltering &&
                        !modelDescriptionHasAutoParameters(_this.resource, item)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                })
                    .map(function (item) {
                    return {
                        uniqueName: "model_" + item.model,
                        name: item.verboseNamePlural,
                        data: {
                            modelDescription: item
                        }
                    };
                });
                items.push.apply(items, modelPages.map(function (item) {
                    var existingItem = _this.items.find(function (i) { return i.page.uniqueName == item.uniqueName; });
                    var modelDescription = item.data.modelDescription;
                    var error;
                    if (!modelDescription.getQuery) {
                        error = 'No Get Record List query';
                    }
                    else if (!modelDescription.getDetailQuery &&
                        !modelDescription.getQuery.frontendFiltering &&
                        !modelDescriptionHasAutoParameters(_this.resource, modelDescription)) {
                        error = 'No Get One Record query';
                    }
                    else if (!modelDescription.primaryKeyField) {
                        error = 'No Primary Key specified';
                    }
                    return {
                        page: item,
                        selected: existingItem ? existingItem.selected : defaultSelected,
                        disabled: !!error,
                        error: error
                    };
                }));
            }
            _this.items = items.sort(objectsSortPredicate('disabled', 'page.name'));
            _this.updateSelectedAll();
        });
    };
    ResourceChoosePagesComponent.prototype.ngOnDestroy = function () { };
    ResourceChoosePagesComponent.prototype.toggleSelected = function (toggleItem) {
        this.items = this.items.map(function (item) {
            return __assign({}, item, (item === toggleItem && { selected: !item.selected }));
        });
        this.updateSelectedAll();
    };
    ResourceChoosePagesComponent.prototype.setSelectedAll = function (selected) {
        this.items = this.items.map(function (item) {
            return __assign({}, item, { selected: selected && !item.disabled });
        });
        this.updateSelectedAll();
    };
    ResourceChoosePagesComponent.prototype.updateSelectedAll = function () {
        this.selectedAll = this.items.filter(function (item) { return !item.disabled; }).every(function (item) { return item.selected; });
        this.cd.markForCheck();
    };
    ResourceChoosePagesComponent.prototype.updateAddModel = function () {
        if (this.resource && !this.resource.demo && this.modelPages) {
            this.addModelComponents = getResourceAddModelComponents(this.resource.typeItem.name).filter(function (item) { return !item.multistep; });
        }
        else {
            this.addModelComponents = [];
        }
        this.cd.markForCheck();
    };
    ResourceChoosePagesComponent.prototype.close = function () {
        this.popupComponent.close();
    };
    ResourceChoosePagesComponent.prototype.submit = function () {
        var selectedPages = this.items
            .filter(function (item) { return item.selected; })
            .filter(function (item) { return !item.disabled; })
            .map(function (item) { return item.page; });
        this.close();
        this.selected.emit(selectedPages);
    };
    ResourceChoosePagesComponent.prototype.cancel = function () {
        this.close();
        this.cancelled.emit();
    };
    ResourceChoosePagesComponent.prototype.addModel = function (addModelComponent) {
        var _this = this;
        this.resourceModelEditController
            .addModel(this.resource, addModelComponent, { source: 'choose_pages' })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.items = _this.items.map(function (item) {
                var data = item.page.data;
                if (data.modelDescription && data.modelDescription.modelId == result.modelDescription.modelId) {
                    return __assign({}, item, { selected: true });
                }
                else {
                    return item;
                }
            });
            _this.cd.markForCheck();
        });
    };
    return ResourceChoosePagesComponent;
}());
export { ResourceChoosePagesComponent };

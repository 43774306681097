import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, skip, switchMap, tap } from 'rxjs/operators';

import { AppDrag, AppDragDrop, AppDropList } from '@common/drag-drop2';
import { CustomizeBarItem } from '@modules/change-components';
import {
  CollapseIndicatorPosition,
  CustomizeService,
  ElementItem,
  ElementType,
  traverseElementItems,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { AutoElementComponent, ElementContainerService } from '@modules/customize-elements';
import { applyParamInput$, Input as FieldInput } from '@modules/fields';
import { RoutingService } from '@modules/routing';
import { addClass, removeClass, toggleClass, TypedChanges } from '@shared';

import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-collapse-section',
  templateUrl: './collapse-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() titleInput: FieldInput;
  @Input() icon: string;
  @Input() children: ElementItem[] = [];
  @Input() tooltip: string;
  @Input() collapseIndicatorPosition: CollapseIndicatorPosition = CollapseIndicatorPosition.Right;
  @Input() element: ElementItem;
  @Input() opened = false;
  @Input() visible = true;
  @Input() first = false;
  @Input() last = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Output() toggleOpened = new EventEmitter<void>();

  @ViewChild(ElementGroupsContainerDirective) elementGroupsContainer: ElementGroupsContainerDirective;
  @ViewChildren(AutoElementComponent) elementComponents = new QueryList<AutoElementComponent>();
  @ViewChild('content_element') contentElement: ElementRef<HTMLElement>;

  titleInput$ = new BehaviorSubject<FieldInput>(this.titleInput);
  opened$ = new BehaviorSubject<boolean>(undefined);
  title: string;
  collapseIndicatorPositions = CollapseIndicatorPosition;

  canEnter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
        let isInsideCurrentForm = false;

        if (this.formElementComponent) {
          traverseElementItems(this.formElementComponent.element, item => {
            if (item === drag.data) {
              isInsideCurrentForm = true;
            }
          });
        }

        return isInsideCurrentForm;
      } else {
        return true;
      }
    };
  })();

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(
    public customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private routing: RoutingService,
    @Optional() private formElementComponent: FormElementComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.opened$.next(this.opened);

    this.initTitle();
    this.initOpenAnimation();
  }

  ngOnDestroy() {}

  ngOnChanges(changes: TypedChanges<CollapseSectionComponent>): void {
    if (changes.titleInput) {
      this.titleInput$.next(this.titleInput);
    }

    if (changes.opened && !changes.opened.firstChange) {
      this.opened$.next(this.opened);
    }
  }

  initTitle() {
    this.titleInput$
      .pipe(
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        switchMap(titleInput => {
          if (!titleInput) {
            return of(undefined);
          }

          return applyParamInput$<string>(titleInput, {
            context: this.context,
            contextElement: this.contextElement,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }

  initOpenAnimation() {
    this.opened$
      .pipe(
        skip(1),
        tap(open => {
          const element = this.contentElement.nativeElement;
          const currentHeight = element.offsetHeight;

          removeClass(element, 'collapse-section__content_hidden');
          const newHeight = open ? element.scrollHeight : 0;

          addClass(element, 'collapse-section__content_animating');

          TweenMax.fromTo(
            element,
            0.4,
            {
              height: currentHeight
            },
            {
              height: newHeight,
              ease: Power2.easeOut,
              clearProps: 'height',
              onComplete: () => {
                removeClass(element, 'collapse-section__content_animating');
                toggleClass(element, 'collapse-section__content_hidden', !this.opened);
              }
            }
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  dragDrop(event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    const item = event.previousContainer.data[event.previousIndex];
    const barItem = item instanceof ElementItem ? undefined : (item as CustomizeBarItem);

    if (barItem && barItem.popup) {
      if (this.customizeService.handler && this.customizeService.handler.createPopup) {
        this.customizeService.handler.createPopup(true, {
          ...(barItem.defaultParams && {
            width: barItem.defaultParams['width'],
            style: barItem.defaultParams['style'],
            position: barItem.defaultParams['position']
          }),
          analyticsSource: 'components_library'
        });
        this.elementContainerService.sendAddPopupAnalytics();
      }

      return;
    }

    const siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
    const siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
    const siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
    const siblingAnchor: ElementItem = event.data ? event.data['siblingAnchor'] : undefined;
    const siblingAnchorContainer: AppDropList = event.data ? event.data['siblingAnchorContainer'] : undefined;

    if (siblingLeftEntered || siblingRightEntered) {
      const anchorContainer: ElementItem[] = siblingSelf ? event.container.data : siblingAnchorContainer.data;
      const anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);

      this.elementContainerService.dragDropIntoSiblingColumn({
        sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
        sourceIndex: event.previousIndex,
        sourceCloneItem: event.previousContainer.cloneItems,
        anchorContainer: anchorContainer,
        anchorIndex: anchorIndex,
        anchorSelf: siblingSelf,
        left: siblingLeftEntered,
        context: this.context,
        parent: this.element
      });

      // TODO: Implement onAdd
      // this.cd.detectChanges();
      // const component = this.elementComponents.find(i => i.element === elementItem);
      // this.onAdd(elementItem, item, component);
    } else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data as ElementItem[], event.previousIndex, event.currentIndex);
      } else if (event.previousContainer.cloneItems) {
        const elementItem = this.elementContainerService.copyElementItem(
          event.previousContainer.data as CustomizeBarItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex,
          this.context
        );
        this.customizeService.registerCreatedElement(elementItem, barItem);
      } else {
        transferArrayItem(
          event.previousContainer.data as ElementItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  duplicateItem(index: number) {
    const elementItem = this.elementContainerService.duplicateElementItem(this.children, this.context, index);
    this.cd.detectChanges();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();

    const component = this.elementComponents.find(i => i.element === elementItem);

    if (component) {
      component.customize();
    }
  }

  replaceItem(index: number, elements: ElementItem[]) {
    this.elementContainerService.replaceElementItem(this.children, index, elements);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  deleteItem(element: ElementItem) {
    const index = this.children.findIndex(item => item === element);

    if (index == -1) {
      return;
    }

    this.elementContainerService.deleteElementItem(this.children, index);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  moveItemTo(element: ElementItem, link: any[]) {
    this.deleteItem(element);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(link));
  }

  updateElementStatesOnStable() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStatesOnStable();
    }
  }

  updateElementStates() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStates();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Quill from 'quill';
import { controlValue } from '@shared';
var Embed = Quill.import('blots/embed');
export var inlineInputInitialProperty = '_initial_property';
var InlineInputDynamicComponent = /** @class */ (function () {
    function InlineInputDynamicComponent(componentRef, portalHost) {
        this.componentRef = componentRef;
        this.portalHost = portalHost;
        this.subscriptions = [];
    }
    InlineInputDynamicComponent.prototype.getComponent = function () {
        return this.componentRef.instance;
    };
    InlineInputDynamicComponent.prototype.addSubscription = function (subscription) {
        this.subscriptions.push(subscription);
    };
    InlineInputDynamicComponent.prototype.destroy = function () {
        this.portalHost.detach();
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
    };
    return InlineInputDynamicComponent;
}());
export { InlineInputDynamicComponent };
var ContextFormulaBlot = /** @class */ (function (_super) {
    __extends(ContextFormulaBlot, _super);
    function ContextFormulaBlot() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContextFormulaBlot.create = function (value) {
        var node = _super.create.call(this, value);
        node['_value'] = value;
        node.setAttribute('contenteditable', false);
        return node;
    };
    ContextFormulaBlot.value = function (node) {
        return node['_value'];
    };
    ContextFormulaBlot.prototype.getQuill = function () {
        var node = this.domNode;
        while (node) {
            var quill = Quill.find(node);
            if (quill instanceof Quill) {
                return quill;
            }
            node = node.parentElement;
        }
    };
    ContextFormulaBlot.prototype.getOptions = function () {
        var quill = this.getQuill();
        if (quill) {
            var contextFormula = quill.getModule('contextFormula');
            if (contextFormula) {
                return contextFormula.options;
            }
        }
    };
    ContextFormulaBlot.prototype.attach = function () {
        _super.prototype.attach.call(this);
        this.initComponent();
    };
    ContextFormulaBlot.prototype.detach = function () {
        _super.prototype.detach.call(this);
        var options = this.getOptions();
        if (options && this.dynamicComponent) {
            options.disposeComponentFn(this.dynamicComponent);
        }
    };
    ContextFormulaBlot.prototype.initComponent = function () {
        var _this = this;
        if (this.dynamicComponent) {
            return;
        }
        var options = this.getOptions();
        if (!options) {
            return;
        }
        this.dynamicComponent = options.createComponentFn(this.domNode, this.domNode['_value']);
        var valueSubscription = controlValue(this.dynamicComponent.getComponent().itemForm).subscribe(function (itemForm) {
            _this.domNode['_value'] = itemForm;
        });
        this.dynamicComponent.addSubscription(valueSubscription);
    };
    ContextFormulaBlot.blotName = 'context-formula';
    ContextFormulaBlot.tagName = 'app-quill-formula';
    return ContextFormulaBlot;
}(Embed));
Quill.register('formats/context-formula', ContextFormulaBlot);
var QuillModule = Quill.import('core/module');
var ContextFormulaModule = /** @class */ (function (_super) {
    __extends(ContextFormulaModule, _super);
    function ContextFormulaModule(quill, options) {
        var _this = _super.call(this, quill, options) || this;
        var toolbar = quill.getModule('toolbar');
        if (toolbar) {
            toolbar.addHandler('context-formula', function () {
                var _a;
                var range = quill.getSelection();
                var index = range ? range.index : 0;
                quill.insertEmbed(index, 'context-formula', (_a = {
                        value_type: 'formula',
                        formula_value: ''
                    },
                    _a[inlineInputInitialProperty] = true,
                    _a));
            });
        }
        return _this;
    }
    return ContextFormulaModule;
}(QuillModule));
Quill.register('modules/contextFormula', ContextFormulaModule);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { AnalyticsEvent } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { MenuService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore, Project, Resource } from '@modules/projects';
import { ResourceCreateController } from '@modules/template-components';
import { ascComparator } from '@shared';

@Component({
  selector: 'app-customize-menu-resources',
  templateUrl: './customize-menu-resources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuResourcesComponent implements OnInit, OnDestroy {
  @Output() closeMenu = new EventEmitter<void>();

  project: Project;
  items: Resource[] = [];
  analyticsEvents = AnalyticsEvent;

  constructor(
    private menuService: MenuService,
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceCreateController: ResourceCreateController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.currentProjectStore.instance$, this.currentEnvironmentStore.resources$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([project, resources]) => {
          this.project = project;
          this.items = resources
            ? resources
                .filter(item => !item.typeItem.protected)
                .filter(item => !item.demo || (item.demo && project.uniqueName == DEMO_RESOURCES_PROJECT))
                .sort((lhs, rhs) => ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()))
            : [];
          this.cd.markForCheck();
        },
        () => {
          this.project = undefined;
          this.items = [];
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  createResource() {
    this.resourceCreateController
      .create({ analyticsSource: 'menu_data' })
      // .pipe(untilDestroyed(this))
      .subscribe();
  }
}

import { capitalize, getNavigatorLanguages } from '@shared';

import { DEFAULT_LANGUAGE, locales } from '../data/locales';
import { getCurrentLanguage } from './localize';

export function getLanguages(): { name: string; value: string }[] {
  const navigatorLanguage = getNavigatorLanguages();
  const language = getCurrentLanguage();

  return [
    { value: DEFAULT_LANGUAGE, name: 'English' },
    ...locales
      .filter(item => {
        if (item.hidden) {
          return navigatorLanguage.includes(item.language) || language == item.language;
        } else {
          return true;
        }
      })
      .map(item => {
        return { value: item.language, name: capitalize(item.name) };
      })
  ];
}

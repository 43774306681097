var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { emojiCategories, getEmojiConverter, getEmojiData, parseEmojiColon } from '@modules/icons';
import { isSet } from '@shared';
var convertor = getEmojiConverter({ sprite: true });
var IconSelectorEmojisComponent = /** @class */ (function () {
    function IconSelectorEmojisComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.search = '';
        this.loading = false;
        this.selected = new EventEmitter();
        this.categories = [];
        this.filteredCategories = [];
    }
    IconSelectorEmojisComponent.prototype.ngOnInit = function () {
        this.updateCategories();
        this.updateFilteredCategories();
    };
    IconSelectorEmojisComponent.prototype.ngOnChanges = function (changes) {
        if (changes.search) {
            this.search = this.search.trim().toLowerCase();
        }
        if (changes.search && !changes.search.firstChange) {
            this.updateFilteredCategories();
        }
    };
    IconSelectorEmojisComponent.prototype.updateCategories = function () {
        var _this = this;
        this.categories = toPairs(emojiCategories).map(function (_a) {
            var category = _a[0], groups = _a[1];
            var items = toPairs(groups).reduce(function (acc, _a) {
                var group = _a[0], shortNames = _a[1];
                shortNames.forEach(function (shortName) {
                    var emoji = getEmojiData(shortName);
                    if (emoji) {
                        var value = ":" + emoji.short_name + ":";
                        var html = convertor.replace_colons(value);
                        var htmlColon = parseEmojiColon(html);
                        if (isSet(htmlColon)) {
                            return;
                        }
                        acc.push({
                            value: value,
                            name: emoji.name.toLowerCase(),
                            html: _this.sanitizer.bypassSecurityTrustHtml(html)
                        });
                    }
                });
                return acc;
            }, []);
            return {
                name: category,
                items: items
            };
        });
        this.cd.markForCheck();
    };
    IconSelectorEmojisComponent.prototype.updateFilteredCategories = function () {
        var _this = this;
        if (!isSet(this.search)) {
            this.filteredCategories = this.categories;
        }
        else {
            this.filteredCategories = this.categories
                .map(function (category) {
                return __assign({}, category, { items: category.items.filter(function (item) {
                        return [item.name, item.value].some(function (str) { return str.toLowerCase().includes(_this.search); });
                    }) });
            })
                .filter(function (category) { return category.items.length; });
        }
        this.cd.markForCheck();
    };
    return IconSelectorEmojisComponent;
}());
export { IconSelectorEmojisComponent };

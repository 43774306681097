import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import {
  FieldType,
  FormFieldSerialized,
  getFieldDescriptionByType,
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams,
  ParameterControl
} from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

export const nodeTypeDefaultNames = {
  [JsonStructureNodeType.Field]: 'field',
  [JsonStructureNodeType.Object]: 'object',
  [JsonStructureNodeType.Array]: 'array'
};

@Component({
  selector: 'app-json-field-structure-node',
  templateUrl: './json-field-structure-node.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldStructureNodeComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() node: JsonStructureNode;
  @Input() parentNode: JsonStructureNode;
  @Input() skipSelf = false;
  @Input() skipContent = false;
  @Input() created = false;
  @Input() dragItem = false;
  @Input() deep = 0;
  @Output() changed = new EventEmitter<JsonStructureNode>();
  @Output() deleted = new EventEmitter<void>();

  trackNode = (() => {
    return (i, item: JsonStructureNode) => {
      return isSet(item) ? item.name : i;
    };
  })();

  types = JsonStructureNodeType;
  childCollapsed: boolean[] = [];
  renameOpened = false;
  renameName: string;
  renameName$: BehaviorSubject<string>;
  renameNameSubscription: Subscription;
  renameLabel: string;
  editOpened = false;
  popoverPositionsSubscriptions: Subscription[] = [];
  fieldControl = new ParameterControl();
  fieldControlSubscription: Subscription;
  createdNode: JsonStructureNode;
  dropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: 0,
      offsetY: 0,
      weight: 2
    },
    {
      panelClass: ['overlay_position_left-top'],
      originX: 'start',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'top',
      offsetX: 0,
      offsetY: -8,
      weight: 2
    },
    {
      panelClass: ['overlay_position_left-bottom'],
      originX: 'start',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 8,
      weight: 2
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 0,
      offsetY: 0,
      weight: 1
    },
    {
      panelClass: ['overlay_position_right-top'],
      originX: 'end',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'top',
      offsetX: 0,
      offsetY: -8,
      weight: 1
    },
    {
      panelClass: ['overlay_position_right-bottom'],
      originX: 'end',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: 8,
      weight: 1
    }
  ];

  @ViewChildren(CdkConnectedOverlay) cdkConnectedOverlays = new QueryList<CdkConnectedOverlay>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<JsonFieldStructureNodeComponent>): void {
    if (changes.node) {
      const defaultCollapsed = this.deep > 3;
      if (this.node && this.node.type == JsonStructureNodeType.Object) {
        this.childCollapsed = this.objectParams.items.map(() => defaultCollapsed);
      } else if (this.node && this.node.type == JsonStructureNodeType.Array) {
        this.childCollapsed = [defaultCollapsed];
      } else if (this.node && this.node.type == JsonStructureNodeType.Field) {
        this.initFieldControl();
      }
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  get objectParams() {
    return this.node.params as JsonStructureObjectParams;
  }

  get arrayParams() {
    return this.node.params as JsonStructureArrayParams;
  }

  get fieldParams() {
    return this.node.params as FormFieldSerialized;
  }

  fieldIcon(field: string): string {
    return getFieldDescriptionByType(field as FieldType).icon;
  }

  toggleCollapse(i: number) {
    this.childCollapsed[i] = !this.childCollapsed[i];
  }

  getItemName(defaultName: string, names: string[]) {
    const namesObj = fromPairs(names.map(item => [item, true]));
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join('') : defaultName;
      ++i;
    } while (namesObj.hasOwnProperty(newName));

    return newName;
  }

  getItemParams(options: {
    type: JsonStructureNodeType;
    name: string;
    label?: string;
    arrayType?: JsonStructureNodeType;
  }) {
    if (options.type == JsonStructureNodeType.Field) {
      return {
        name: options.name,
        label: options.label,
        field: FieldType.Text
      } as FormFieldSerialized;
    } else if (options.type == JsonStructureNodeType.Object) {
      const itemType = JsonStructureNodeType.Field;
      const itemName = nodeTypeDefaultNames[itemType];

      return {
        items: [
          {
            type: itemType,
            name: itemName,
            label: itemName,
            params: this.getItemParams({ type: itemType, name: itemName, label: itemName })
          }
        ]
      } as JsonStructureObjectParams;
    } else if (options.type == JsonStructureNodeType.Array) {
      const itemName = nodeTypeDefaultNames[options.arrayType];

      return {
        item: {
          type: options.arrayType,
          name: name,
          label: name,
          params: this.getItemParams({ type: options.arrayType, name: itemName })
        }
      } as JsonStructureArrayParams;
    }
  }

  createItem(
    type: JsonStructureNodeType,
    names: string[],
    options: { arrayType?: JsonStructureNodeType; self?: boolean } = {}
  ): JsonStructureNode {
    const defaultName = nodeTypeDefaultNames[type];

    if (!defaultName) {
      return;
    }

    let name: string;

    if (options.self && !this.parentNode) {
      name = null;
    } else {
      name = this.getItemName(defaultName, names);
    }

    const params = this.getItemParams({ type: type, name: name, label: name, arrayType: options.arrayType });

    if (!params) {
      return;
    }

    return { type: type, name: name, label: null, params: params };
  }

  addItem(type: JsonStructureNodeType, options: { arrayType?: JsonStructureNodeType; self?: boolean } = {}) {
    if (options.self) {
      const newItem = this.createItem(type, [], options);

      this.changed.emit(newItem);
    } else {
      const instance = cloneDeep(this.node);
      const instanceParams = instance.params as JsonStructureObjectParams;
      const names = instanceParams.items.map(item => item.name);
      const newItem = this.createItem(type, names, options);

      this.createdNode = newItem;
      instanceParams.items.push(newItem);

      this.changed.emit(instance);
    }
  }

  changeItem(type: JsonStructureNodeType, options: { arrayType?: JsonStructureNodeType } = {}) {
    const params = this.getItemParams({ type: type, name: this.node.name, arrayType: options.arrayType });

    if (!params) {
      return;
    }

    const newInstance = { type: type, name: this.node.name, label: this.node.name, params: params };

    this.changed.emit(newInstance);
  }

  onObjectChildChanged(index: number, child: JsonStructureNode) {
    const instance = cloneDeep(this.node);
    const params = instance.params as JsonStructureObjectParams;

    params.items[index] = child;

    this.changed.emit(instance);
  }

  onObjectChildDeleted(index: number) {
    const instance = cloneDeep(this.node);
    const params = instance.params as JsonStructureObjectParams;

    params.items = [...params.items.slice(0, index), ...params.items.slice(index + 1)];

    this.changed.emit(instance);
  }

  onArrayChildChanged(child: JsonStructureNode) {
    const instance = cloneDeep(this.node);
    const params = instance.params as JsonStructureArrayParams;

    params.item = child;

    this.changed.emit(instance);
  }

  onNameChanged(name: string) {
    const instance = cloneDeep(this.node);

    instance.name = name;
    instance.label = name;

    if (instance.type == JsonStructureNodeType.Field) {
      const params = instance.params as FormFieldSerialized;
      params.name = name;
    }

    this.changed.emit(instance);
  }

  initFieldControl() {
    if (this.fieldControlSubscription) {
      this.fieldControlSubscription.unsubscribe();
      this.fieldControlSubscription = undefined;
    }

    const fieldParams = this.fieldParams;

    this.fieldControl.controls.field.patchValue(fieldParams.field);
    this.fieldControl.controls.required.patchValue(fieldParams.required);
    this.fieldControl.controls.editable.patchValue(fieldParams.editable);
    this.fieldControl.controls.params.patchValue(fieldParams.params);

    this.fieldControlSubscription = this.fieldControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const instance: JsonStructureNode = cloneDeep(this.node);

      instance.params = {
        ...instance.params,
        field: this.fieldControl.controls.field.value,
        required: this.fieldControl.controls.required.value,
        editable: this.fieldControl.controls.editable.value,
        params: this.fieldControl.controls.params.value
      };

      this.changed.emit(instance);
    });
  }

  startRename() {
    if (!this.parentNode) {
      return;
    }

    if (this.renameOpened) {
      return;
    }

    this.renameOpened = true;
    this.renameName = this.node.name;
    this.renameName$ = new BehaviorSubject<string>(this.renameName);
    this.renameLabel = this.node.type == JsonStructureNodeType.Field ? this.fieldParams.label : this.node.label;
    this.cd.markForCheck();

    this.renameNameSubscription = this.renameName$
      .pipe(pairwise(), untilDestroyed(this))
      .subscribe(([prevName, currentName]) => {
        if (this.renameLabel == prevName) {
          this.renameLabel = currentName;
          this.cd.markForCheck();
        }
      });
  }

  finishRename() {
    if (!this.renameOpened) {
      return;
    }

    if (this.renameNameSubscription) {
      this.renameNameSubscription.unsubscribe();
      this.renameNameSubscription = undefined;
    }

    const prevName = this.node.name;
    const prevLabel = this.node.type == JsonStructureNodeType.Field ? this.fieldParams.label : this.node.label;

    if (prevName != this.renameName || prevLabel != this.renameLabel) {
      const instance: JsonStructureNode = cloneDeep(this.node);

      instance.name = this.renameName;
      instance.label = this.renameLabel;

      if (this.node.type == JsonStructureNodeType.Field) {
        instance.params = {
          ...instance.params,
          name: this.renameName,
          label: this.renameLabel
        };
      }

      this.changed.emit(instance);
    }

    this.renameOpened = false;
    this.renameName = undefined;
    this.renameLabel = undefined;
    this.cd.markForCheck();
  }

  setEditNodeOpened(opened: boolean) {
    this.editOpened = opened;
    this.cd.markForCheck();
  }

  onPopoverContentChanged(overlay: CdkConnectedOverlay) {
    overlay.overlayRef.updatePosition();
  }

  setPositionObserver() {
    this.popoverPositionsSubscriptions.forEach(item => item.unsubscribe());
    this.popoverPositionsSubscriptions = [];

    this.popoverPositionsSubscriptions = this.cdkConnectedOverlays.map(overlay => {
      return overlay.positionChange.pipe(untilDestroyed(this)).subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.dropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.dropdownPositions.filter(item => item !== position);

        if (position) {
          overlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => overlay.overlayRef.removePanelClass(item.panelClass));
      });
    });
  }

  objectItemsDragDrop(event: CdkDragDrop<JsonStructureNode[]>) {
    if (event.previousIndex !== event.currentIndex) {
      const instance: JsonStructureNode = cloneDeep(this.node);
      const params = instance.params as JsonStructureObjectParams;

      moveItemInArray(params.items, event.previousIndex, event.currentIndex);

      this.changed.emit(instance);
    }
  }
}

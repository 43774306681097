import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { MailchimpResourceSettingsForm } from './mailchimp-resource-settings.form';

@Component({
  selector: 'app-mailchimp-resource-settings',
  templateUrl: './mailchimp-resource-settings.component.html',
  providers: [MailchimpResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailchimpResourceSettingsComponent extends BaseResourceSettingsComponent {
  constructor(
    public form: MailchimpResourceSettingsForm,
    private chooseSyncController: ChooseSyncController,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.Mailchimp, MailchimpResourceSettingsComponent);

import isArray from 'lodash/isArray';
import { environment } from '@env/environment';
export function isSet(value, strict) {
    if (strict === void 0) { strict = false; }
    if (strict) {
        return !(value === null || value === undefined);
    }
    else {
        return !(value === null || value === undefined || value === '');
    }
}
export function firstSet() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return values.find(function (item) { return isSet(item); });
}
export function isEmptyArray(value) {
    return isArray(value) && !value.length;
}
export function arrayMove(array, index, newIndex) {
    if (newIndex > index) {
        return array.slice(0, index).concat(array.slice(index + 1, newIndex), [array[index]], array.slice(newIndex));
    }
    else if (newIndex == index) {
        return array.slice(0, newIndex).concat([array[index]], array.slice(newIndex, index), array.slice(index + 1));
    }
    else {
        return array;
    }
}
export function arraySet(array, items) {
    array.splice.apply(array, [0, array.length].concat(items));
    return array;
}
export function arrayReverse(array, reverse) {
    if (reverse === void 0) { reverse = true; }
    if (reverse) {
        return array.slice().reverse();
    }
    else {
        return array.slice();
    }
}
export function isValidNumber(value) {
    if (typeof value == 'string') {
        value = value.replace(',', '.');
    }
    return !isNaN(parseFloat(value));
}
export function splitmax(str, separator, limit) {
    var result = str.split(separator);
    if (limit !== undefined && result.length > limit) {
        return result.slice(0, limit - 1).concat([result.slice(limit - 1).join(separator)]);
    }
    else {
        return result;
    }
}
export function coerceArray(obj) {
    return isArray(obj) ? obj : [obj];
}
export function instanceOf(obj, classes) {
    if (isArray(classes)) {
        return classes.some(function (cls) { return obj instanceof cls; });
    }
    else {
        return obj instanceof classes;
    }
}
export function forceString(value) {
    if (value === null || value === undefined) {
        return '';
    }
    return String(value);
}
export function getCircleIndex(array, index) {
    return array[index % array.length];
}
export function arrayStartsWith(array, startsWithArray) {
    return startsWithArray.every(function (item, i) { return array[i] == item; });
}
export function deployUrl(url) {
    if (!environment['deployUrl'] || typeof url !== 'string') {
        return url;
    }
    if (url.startsWith('/')) {
        return "" + environment['deployUrl'] + url.substring(1);
    }
    else {
        return url;
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TemplatesGroup } from '../../../../data/templates-group';

@Component({
  selector: 'app-change-customize-bar-templates-item',
  templateUrl: './change-customize-bar-templates-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesItemComponent implements OnInit {
  @Input() item: TemplatesGroup;
  @Output() open = new EventEmitter<TemplatesGroup>();

  constructor() {}

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, QueryList } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { AppLinkDirective } from '../app-link/app-link.directive';
var AppLinkActiveDirective = /** @class */ (function (_super) {
    __extends(AppLinkActiveDirective, _super);
    function AppLinkActiveDirective() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.appLinkActiveOptions = { exact: true };
        _this.linksWithHrefs = new QueryList();
        return _this;
    }
    AppLinkActiveDirective.prototype.ngOnChanges = function (changes) {
        if (changes['appLinkActive']) {
            this.updateLinkActive();
        }
        if (changes['appLinkActiveOptions']) {
            this.updateLinkActiveOptions();
        }
        _super.prototype.ngOnChanges.call(this, changes);
    };
    AppLinkActiveDirective.prototype.updateLinkActive = function () {
        this.routerLinkActive = this.appLinkActive;
    };
    AppLinkActiveDirective.prototype.updateLinkActiveOptions = function () {
        this.routerLinkActiveOptions = this.appLinkActiveOptions;
    };
    return AppLinkActiveDirective;
}(RouterLinkActive));
export { AppLinkActiveDirective };

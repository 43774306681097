import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { localize } from '@common/localize';
import { BasePopupComponent } from '@common/popups';
import { UserActivityListStore, UserActivityType } from '@modules/activities';
import { CustomSelectItem } from '@modules/field-components';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { isSet } from '@shared';

class Filters extends FormGroup {
  controls: {
    activity_type_in: FormControl;
    user: FormControl;
  };

  constructor() {
    super({
      activity_type_in: new FormControl(null),
      user: new FormControl(null)
    });
  }

  isSet(): boolean {
    return values(this.controls).some(item => isSet(item.value));
  }
}

@Component({
  selector: 'app-user-activities-popup',
  templateUrl: './user-activities-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserActivityListStore]
})
export class UserActivitiesPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() baseParams = {};
  @Input() closeObs: Observable<void>;
  @Output() closeObsEvent = new EventEmitter<void>();

  @ViewChild('sidePopupWrap') sidePopupWrap: ElementRef<HTMLElement>;
  @ViewChild('sidePopup') sidePopup: ElementRef<HTMLElement>;
  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;

  defaultParams = {};

  loading = false;
  error: string;

  filters = new Filters();
  filtersIsSet = false;
  activityTypeOptions: CustomSelectItem<UserActivityType>[] = [
    {
      name: localize('All Activity'),
      value: null
    },
    {
      name: localize('Create Record'),
      value: UserActivityType.ModelCreate
    },
    {
      name: localize('Update Record'),
      value: UserActivityType.ModelUpdate
    },
    {
      name: localize('Delete Record'),
      value: UserActivityType.ModelDelete
    }
    // {
    //   name: 'Delete Multiple Records',
    //   value: UserActivityType.ModelMassDelete
    // },
    // {
    //   name: 'View Record',
    //   value: UserActivityType.ModelDetail
    // },
    // {
    //   name: 'View Collection',
    //   value: UserActivityType.ModelList
    // },
    // {
    //   name: 'View Dashboard',
    //   value: UserActivityType.DashboardList
    // }
  ].map(item => {
    return {
      option: {
        value: item.value,
        name: item.name
      }
    };
  });
  activityUserOptions: CustomSelectItem<string>[] = [];

  tl = new TimelineMax();

  constructor(
    private router: Router,
    private popupComponent: BasePopupComponent,
    private projectUserService: ProjectUserService,
    public userActivityListStore: UserActivityListStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.defaultParams = {
      ...this.baseParams,
      order_by: '-date_add',
      activity_type_in: this.activityTypeOptions
        .filter(item => item.option.value)
        .map(item => item.option.value)
        .join()
    };

    this.projectUserService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(users => {
        this.activityUserOptions = [
          {
            name: localize('All Members'),
            value: null
          },
          ...users
            .filter(user => user.user)
            .map(user => {
              return {
                name: user.user.firstName + ' ' + user.user.lastName,
                value: user.user.uid
              };
            })
        ].map(item => {
          return {
            option: {
              value: item.value,
              name: item.name
            }
          };
        });
        this.cd.markForCheck();
      });

    if (this.closeObs) {
      this.closeObs.pipe(untilDestroyed(this)).subscribe(() => {
        this.close();
      });
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.close();
      });

    fromEvent<WheelEvent>(this.sidePopup.nativeElement, 'wheel')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const sidePopupHeight = this.sidePopup.nativeElement.offsetHeight;
        const sidePopupScrollHeight = this.sidePopup.nativeElement.scrollHeight;
        const scrollTop = this.sidePopup.nativeElement.scrollTop;
        const scrollMax = sidePopupScrollHeight - sidePopupHeight;

        if (scrollTop >= scrollMax) {
          this.onScrollFinished();
        }
      });

    this.show();
  }

  init() {
    this.fetch();
    this.initFilters();
  }

  initFilters() {
    this.filters.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const params = {
        ...this.defaultParams,
        ...(isSet(this.filters.controls.activity_type_in.value)
          ? {
              activity_type_in: this.filters.controls.activity_type_in.value
            }
          : undefined),
        ...(isSet(this.filters.controls.user.value)
          ? {
              user: this.filters.controls.user.value
            }
          : undefined)
      };

      this.fetch(params);

      this.filtersIsSet = this.filters.isSet();
      this.cd.markForCheck();
    });
  }

  fetch(params?: Object) {
    const newParams = params || this.defaultParams;

    if (isEqual(newParams, this.userActivityListStore.params)) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
    this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
    this.userActivityListStore.params = newParams;
    this.userActivityListStore.reset();
    this.userActivityListStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          this.loading = false;
          this.error = error;
          this.cd.markForCheck();
        }
      );
  }

  onScrollFinished() {
    this.userActivityListStore.getNext();
  }

  show(): void {
    this.tl
      .fromTo(
        this.sidePopupWrap.nativeElement,
        0.6,
        {
          xPercent: 100
        },
        {
          xPercent: 0,
          ease: Power2.easeOut
        }
      )
      .add(() => {
        this.init();
      });
  }

  close(): void {
    this.closeObsEvent.emit();
    this.tl
      .clear()
      .set(
        this.closeButton.nativeElement,
        {
          pointerEvents: 'none'
        },
        0
      )
      .to(
        this.sidePopupWrap.nativeElement,
        0.4,
        {
          xPercent: 100,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.popupComponent.close();
      });
  }
}

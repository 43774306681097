/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layer-interaction/layer-interaction.component.ngfactory";
import * as i2 from "../../layer-interaction/layer-interaction.component";
import * as i3 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i4 from "../line-layer-bounds/line-layer-bounds.component.ngfactory";
import * as i5 from "../line-layer-bounds/line-layer-bounds.component";
import * as i6 from "../../../services/view-editor-context/view-editor.context";
import * as i7 from "@angular/common";
import * as i8 from "../../../directives/view-editor-viewport-overlay/view-editor-viewport-overlay.directive";
import * as i9 from "../../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service";
import * as i10 from "../../../../customize/data/view-context-element";
import * as i11 from "../../../../customize/data/view-context";
import * as i12 from "./line-layer.component";
import * as i13 from "@angular/platform-browser";
var styles_LineLayerComponent = [];
var RenderType_LineLayerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LineLayerComponent, data: {} });
export { RenderType_LineLayerComponent as RenderType_LineLayerComponent };
function View_LineLayerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layer-interaction", [], null, null, null, i1.View_LayerInteractionComponent_0, i1.RenderType_LayerInteractionComponent)), i0.ɵdid(1, 770048, null, 0, i2.LayerInteractionComponent, [i3.ActionControllerService, i0.Injector], { interaction: [0, "interaction"], element: [1, "element"], context: [2, "context"], contextElement: [3, "contextElement"], analyticsSource: [4, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = i0.ɵnov(_v.parent, 1); var currVal_2 = _co.viewContext; var currVal_3 = _co.contextElement; var currVal_4 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_LineLayerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "line-layer__fill"]], [[4, "margin-top", "px"], [4, "height", "px"]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (0 - 5); var currVal_1 = 10; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LineLayerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "line-layer__fill"]], [[4, "border-bottom", null], [4, "margin-top", "px"], [4, "height", "px"], [4, "box-shadow", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.border; var currVal_1 = ((0 - _v.context.$implicit.thickness) * 0.5); var currVal_2 = _v.context.$implicit.thickness; var currVal_3 = _co.boxShadow; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_LineLayerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-line-layer-bounds", [], null, [[null, "resizeStarted"], [null, "resizeFinished"], [null, "updateLayer"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resizeStarted" === en)) {
        var pd_0 = (_co.editorContext.resizingLayer$.next(_co.layer) !== false);
        ad = (pd_0 && ad);
    } if (("resizeFinished" === en)) {
        var pd_1 = (_co.editorContext.resizingLayer$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } if (("updateLayer" === en)) {
        var pd_2 = (_co.onLayerUpdate($event.frame, $event.points) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_LineLayerBoundsComponent_0, i4.RenderType_LineLayerBoundsComponent)), i0.ɵdid(2, 245760, null, 0, i5.LineLayerBoundsComponent, [i6.ViewEditorContext], { frame: [0, "frame"], translate: [1, "translate"], points: [2, "points"], fromX: [3, "fromX"], fromY: [4, "fromY"], length: [5, "length"], transform: [6, "transform"], snapFrame: [7, "snapFrame"], snapFrameContainer: [8, "snapFrameContainer"], snapFrameExceptLayers: [9, "snapFrameExceptLayers"], active: [10, "active"], hover: [11, "hover"] }, { resizeStarted: "resizeStarted", resizeFinished: "resizeFinished", updateLayer: "updateLayer" }), i0.ɵpad(3, 1), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layer.frame; var currVal_1 = _co.translate; var currVal_2 = _co.layer.points; var currVal_3 = _co.fromX; var currVal_4 = _co.fromY; var currVal_5 = _co.length; var currVal_6 = _co.transform; var currVal_7 = true; var currVal_8 = _co.container; var currVal_9 = _ck(_v, 3, 0, _co.layer); var currVal_10 = i0.ɵunv(_v, 2, 10, i0.ɵnov(_v, 4).transform(_co.customizing$)); var currVal_11 = i0.ɵunv(_v, 2, 11, i0.ɵnov(_v, 5).transform(_co.hover$)); _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_LineLayerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(0, null, null, 2, null, View_LineLayerComponent_5)), i0.ɵdid(2, 9125888, null, 0, i8.ViewEditorViewportOverlayDirective, [i0.TemplateRef, i9.ViewEditorViewportOverlayService], { options: [0, "options"] }, null), i0.ɵpod(3, { frame: 0, translate: 1, transform: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.layer.frame, _co.translate, _co.transformOverlay); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LineLayerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "layer-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["layer_element", 1]], null, 6, "div", [["class", "line-layer"]], [[4, "top", "%"], [4, "left", "%"], [4, "width", "px"], [4, "transform", null], [4, "opacity", null], [4, "cursor", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "mouseup"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (_co.onMouseUp() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.onMouseClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineLayerComponent_1)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineLayerComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineLayerComponent_3)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LineLayerComponent_4)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.layer.interactions; _ck(_v, 3, 0, currVal_6); var currVal_7 = _co.editorContext; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.borders; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.editorContext; _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fromY * 100); var currVal_1 = (_co.fromX * 100); var currVal_2 = _co.length; var currVal_3 = _co.transform; var currVal_4 = _co.layer.opacity; var currVal_5 = (_co.layer.getCursor() || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_LineLayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-line-layer", [], null, null, null, View_LineLayerComponent_0, RenderType_LineLayerComponent)), i0.ɵprd(131584, null, i10.ViewContextElement, i10.ViewContextElement, [i11.ViewContext]), i0.ɵdid(2, 770048, null, 0, i12.LineLayerComponent, [[2, i6.ViewEditorContext], i10.ViewContextElement, i13.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LineLayerComponentNgFactory = i0.ɵccf("app-line-layer", i12.LineLayerComponent, View_LineLayerComponent_Host_0, { layer: "layer", layerUpdated$: "layerUpdated$", container: "container", translate: "translate", createdLayer: "createdLayer", customizeEnabled: "customizeEnabled", viewContext: "viewContext", analyticsSource: "analyticsSource" }, { layerCustomizeMouseEnter: "layerCustomizeMouseEnter", layerCustomizeMouseLeave: "layerCustomizeMouseLeave", layerCustomize: "layerCustomize", layerAddCustomizing: "layerAddCustomizing", layerRemoveCustomizing: "layerRemoveCustomizing", layerDelete: "layerDelete", updateFrame: "updateFrame" }, []);
export { LineLayerComponentNgFactory as LineLayerComponentNgFactory };

<app-list-layouts *ngIf="layouts" (layoutAdd)="layoutAdd.emit($event)" (layoutCustomize)="layoutCustomize.emit($event)">
</app-list-layouts>

<app-list-search
  *ngIf="searchEnabled"
  [focus]="focus"
  [listTitle]="listTitle"
  [search]="search"
  [live]="searchLive"
  [accentColor]="accentColor"
  (searchUpdated)="searchChanged.emit($event)"
>
</app-list-search>

<ng-container *ngIf="filtersEnabled">
  <app-filter-edit-overlay
    [dataSource]="dataSource"
    [filters]="filters"
    [optionsFilter]="optionsFilter"
    [origin]="addFilterOrigin"
    (filtersUpdated)="filtersUpdated.emit($event)"
  ></app-filter-edit-overlay>

  <a
    href="javascript:void(0)"
    class="button button_icon list__actions-item"
    [appButtonTintColor]="accentColor"
    [appButtonTintColorStyle]="tintStyles.Default"
    [appTip]="'Filter' | localize"
    [appTipOptions]="{ side: 'top' }"
    cdkOverlayOrigin
    #addFilterOrigin="cdkOverlayOrigin"
  >
    <span class="button__icon icon-filter_2"></span>
  </a>
</ng-container>

<ng-container *ngIf="otherActions.length">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
    [cdkConnectedOverlayPositions]="otherActionsDropdownPositions"
    [cdkConnectedOverlayOrigin]="other_actions_origin"
    [cdkConnectedOverlayOpen]="otherActionsDropdownOpened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="closeOtherActionsDropdown()"
    (detach)="closeOtherActionsDropdown()"
  >
    <div
      class="popover2 overlay-content overlay-content_animated"
      (mouseenter)="otherActionsDropdownHover$.next(true)"
      (mouseleave)="otherActionsDropdownHover$.next(false)"
    >
      <div class="popover2__inner">
        <div class="popover2__viewport popover2__viewport_scrollable" style="min-width: 220px;">
          <div class="popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset">
            <app-list-actions-item
              *ngFor="let item of otherActions"
              [item]="item"
              [pinned]="false"
              [context]="context"
              [contextElement]="contextElement"
              [accentColor]="accentColor"
              (selected)="closeOtherActionsDropdown()"
            ></app-list-actions-item>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <span
    class="button button_icon list__actions-item"
    [appButtonTintColor]="accentColor"
    [appButtonTintColorStyle]="tintStyles.Default"
    (mouseenter)="otherActionsHover$.next(true)"
    (mouseleave)="otherActionsHover$.next(false)"
    cdkOverlayOrigin
    #other_actions_origin="cdkOverlayOrigin"
  >
    <span class="button__icon icon-more"></span>
  </span>
</ng-container>

<app-list-actions-item
  *ngFor="let item of pinnedActions"
  [item]="item"
  [pinned]="true"
  [context]="context"
  [contextElement]="contextElement"
  [compact]="compactActions"
  [accentColor]="accentColor"
></app-list-actions-item>

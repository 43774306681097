import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import {
  CustomizeService,
  CustomizeType,
  ElementType,
  registerElementComponent,
  TitleElementItem
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { KeyboardEventKeyCode } from '@shared';

@Component({
  selector: 'app-title-element',
  templateUrl: './title-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleElementComponent extends BaseElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: TitleElementItem;

  editing = false;
  editingValue: string;

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(() => {
      this.setEditing(false);
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  get isEditable() {
    return this.customizeService.enabled == CustomizeType.Layout;
  }

  setEditing(editing) {
    this.editing = editing;

    if (editing) {
      this.editingValue = this.element.title;
    }

    this.cd.markForCheck();
  }

  onClick() {
    if (!this.isEditable) {
      return;
    }

    this.setEditing(true);
  }

  onKeyUp(e) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.saveEditing();
    }
  }

  saveEditing() {
    this.setEditing(false);
    this.element.title = this.editingValue;
  }

  cancelEditing() {
    this.setEditing(false);
    this.editingValue = this.element.title;
    this.cd.markForCheck();
  }
}

registerElementComponent({
  type: ElementType.Title,
  component: TitleElementComponent,
  label: 'Title',
  actions: []
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var SectionLayoutElementItem = /** @class */ (function (_super) {
    __extends(SectionLayoutElementItem, _super);
    function SectionLayoutElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Section;
        _this.children = [];
        return _this;
    }
    SectionLayoutElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['children']) {
            this.children = this.params['children']
                .map(function (item) {
                var element = getElementByType(item['type']);
                if (!element) {
                    console.error("Unsupported element type: " + item['type']);
                    return;
                }
                return new element().deserialize(item);
            })
                .filter(function (item) { return item != undefined; });
        }
        return this;
    };
    SectionLayoutElementItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['children'] = this.children.map(function (item) { return item.serialize(); });
        return data;
    };
    Object.defineProperty(SectionLayoutElementItem.prototype, "analyticsName", {
        get: function () {
            return 'action';
        },
        enumerable: true,
        configurable: true
    });
    SectionLayoutElementItem.prototype.defaultName = function () {
        return 'Section';
    };
    SectionLayoutElementItem.prototype.childrenCount = function () {
        return this.children.length;
    };
    return SectionLayoutElementItem;
}(ElementItem));
export { SectionLayoutElementItem };
registerElementForType(ElementType.Section, SectionLayoutElementItem);

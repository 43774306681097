var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
var OpaqueThinDialogPopupComponent = /** @class */ (function (_super) {
    __extends(OpaqueThinDialogPopupComponent, _super);
    function OpaqueThinDialogPopupComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.opaque = true;
        _this.wide = false;
        return _this;
    }
    return OpaqueThinDialogPopupComponent;
}(DialogPopupComponent));
export { OpaqueThinDialogPopupComponent };

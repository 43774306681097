var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, map, skip, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { rawListViewSettingsColumnsToDisplayField, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import { applyParamInputs, createFormFieldFactory, deserializeDisplayField, FieldType, getFieldDescriptionByType, Input, MultipleSelectStyle, OptionsType, ParameterField, registerFieldViewParamsComponent } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { PER_PAGE_PARAM } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { ResourceModelEditController } from '@modules/projects-components';
import { editableQueryTypes, ListModelDescriptionQuery, ObjectQueryOperation, QueryService, QueryType } from '@modules/queries';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';
import { RoutingService } from '@modules/routing';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue } from '@shared';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { SelectFieldViewParamsForm } from './select-field-view-params.form';
export var optionsElementName = '__jet_options_element__';
var SelectFieldViewParamsComponent = /** @class */ (function (_super) {
    __extends(SelectFieldViewParamsComponent, _super);
    function SelectFieldViewParamsComponent(form, columnContextElement, queryContext, queryBuilderService, notificationService, dialogService, queryService, resourceModelEditController, modelSelectSource, routing, injector, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.columnContextElement = columnContextElement;
        _this.queryContext = queryContext;
        _this.queryBuilderService = queryBuilderService;
        _this.notificationService = notificationService;
        _this.dialogService = dialogService;
        _this.queryService = queryService;
        _this.resourceModelEditController = resourceModelEditController;
        _this.modelSelectSource = modelSelectSource;
        _this.routing = routing;
        _this.injector = injector;
        _this.cd = cd;
        _this.createField = createFormFieldFactory();
        _this.displayOptions = [];
        _this.addingMissing = false;
        _this.optionsTypes = OptionsType;
        _this.collapseContext = new SidebarCollapseContext();
        _this.multiple = false;
        _this.editable = false;
        _this.toStaticOptionsLoading = false;
        _this.optionsMaxDisplayInitial = 8;
        _this.optionsCollapsed = true;
        _this.queryTypes = QueryType;
        _this.editableQueryTypes = editableQueryTypes;
        _this.fieldTypes = FieldType;
        _this.columnContextElementPath = [ITEM_OUTPUT];
        _this.booleanFieldStyle = BooleanFieldStyle;
        _this.multipleSelectStyles = MultipleSelectStyle;
        _this.object = 'component_field_select';
        return _this;
    }
    SelectFieldViewParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control, this.context);
        this.queryContext.parametersControl = this.form.controls.parameters;
        this.updateTokens();
        this.form.controls.parameters.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(function () {
            _this.updateTokens();
        });
        this.columnContextElement.initElement({
            uniqueName: optionsElementName,
            name: 'Fields'
        });
        if (this.field$) {
            this.field$.pipe(untilDestroyed(this)).subscribe(function (field) {
                _this.type = field ? field.field : undefined;
                _this.multiple = _this.type == FieldType.MultipleSelect;
                _this.editable = (!_this.configurable || _this.configurable.editable) && field && field['editable'];
                _this.cd.markForCheck();
            });
        }
        controlValue(this.form.controls.options)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateDisplayItems(); });
        combineLatest(this.form.resource$(this.form.controls.resource), this.form.resourceModelItems$(this.form.controls.resource), this.form.modelOption$(this.form.controls.model), this.form.modelDescription$(this.form.controls.resource, this.form.controls.model), this.form.resourceBaseHttpQuery$(this.form.controls.resource), this.form.columnOptions$(this.form.controls.columns), this.form.sortableColumnOptions$())
            .pipe(auditTime(1000 / 60), untilDestroyed(this))
            .subscribe(function (result) {
            _this.resource = result[0];
            _this.resourceModelItems = result[1];
            _this.modelOption = result[2];
            _this.modelDescription = result[3];
            _this.resourceBaseHttpQuery = result[4];
            _this.columnOptions = result[5];
            _this.sortableColumnOptions = result[6];
            _this.cd.markForCheck();
            _this.columnContextElement.setOutputs([
                {
                    uniqueName: ITEM_OUTPUT,
                    name: 'Fields',
                    internal: true,
                    children: _this.columnOptions.map(function (item) {
                        return {
                            uniqueName: item.option.value,
                            name: item.option.name,
                            icon: item.option.icon
                        };
                    })
                }
            ]);
        });
        controlValue(this.form.controls.query)
            .pipe(delay(0), map(function (query) { return (query ? query.queryType : undefined); }), distinctUntilChanged(), skip(1), untilDestroyed(this))
            .subscribe(function (queryType) {
            if (editableQueryTypes.includes(queryType)) {
                _this.editQuery();
            }
        });
    };
    SelectFieldViewParamsComponent.prototype.ngOnDestroy = function () { };
    SelectFieldViewParamsComponent.prototype.updateDisplayItems = function () {
        if (this.optionsCollapsed) {
            this.displayOptions = this.form.controls.options.controls.slice(0, this.optionsMaxDisplayInitial);
        }
        else {
            this.displayOptions = this.form.controls.options.controls;
        }
        this.cd.markForCheck();
    };
    SelectFieldViewParamsComponent.prototype.removeOption = function (group, i) {
        // this.dialogService
        //   .warning({
        //     title: 'Delete Option',
        //     description: `Are you sure want to delete <strong>Option #${i + 1}</strong>?`,
        //     style: 'orange'
        //   })
        //   .pipe(untilDestroyed(this))
        //   .subscribe(result => {
        //     if (result) {
        this.form.formArrayRemove('options', group);
        //   }
        // });
    };
    SelectFieldViewParamsComponent.prototype.addOption = function () {
        var item = this.form.createOption();
        this.form.formArrayAppend('options', item);
        this.createdOption = item;
        this.cd.markForCheck();
    };
    SelectFieldViewParamsComponent.prototype.dragDropOption = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.formArrayControls('options'), event.previousIndex, event.currentIndex);
            this.form.controls.options.updateValueAndValidity();
        }
    };
    SelectFieldViewParamsComponent.prototype.addMissing = function () {
        var _this = this;
        this.addingMissing = true;
        this.cd.markForCheck();
        this.form
            .getMissing()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.length == 0) {
                _this.notificationService.warning('No missing values', 'All found values are already added');
            }
            else {
                var maxValues = 10;
                var newValues = result;
                if (result.length > maxValues) {
                    newValues = newValues.slice(0, maxValues);
                    _this.notificationService.success('Missing values added', "\n                Found <strong>" + result.length + "</strong> missing values,\n                added first <strong>" + newValues.length + "</strong> of them\n              ");
                }
                else {
                    _this.notificationService.success('Missing values added', "\n                <strong>" + newValues.length + "</strong> values were added\n              ");
                }
                _this.form.addMissing(newValues);
            }
            _this.addingMissing = false;
            _this.cd.markForCheck();
        }, function () {
            _this.addingMissing = false;
            _this.cd.markForCheck();
        });
    };
    SelectFieldViewParamsComponent.prototype.updateTokens = function () {
        var tokens = [
            {
                name: undefined,
                label: 'general',
                children: [
                    {
                        name: 'search',
                        field: FieldType.Text,
                        label: 'Search query'
                    }
                ]
            },
            {
                name: 'filters._value',
                label: 'Selected Value'
            }
        ];
        if (this.form.controls.parameters.value) {
            tokens.push({
                name: undefined,
                label: 'Inputs',
                data: {
                    parameter_tokens: true
                },
                children: this.form.controls.parameters.value
                    .filter(function (item) { return item.name; })
                    .map(function (item) {
                    var fieldDescription = getFieldDescriptionByType(item.field);
                    return {
                        name: ['params', item.name].join('.'),
                        field: item.field,
                        params: item.params,
                        label: item.verboseName || item.name,
                        icon: fieldDescription.icon
                    };
                })
            });
        }
        this.queryContext.tokens = tokens;
    };
    SelectFieldViewParamsComponent.prototype.editQuery = function () {
        var _this = this;
        if (!this.resource) {
            return;
        }
        var query = this.form.controls.query.value;
        this.queryBuilderService
            .edit({
            injector: this.injector,
            queryClass: this.form.queryClass,
            queryTypes: [query.queryType],
            resource: this.resource,
            resourceType: this.resource.type,
            context: this.queryContext,
            requireResponse: true,
            arrayResponse: true,
            query: query,
            parametersControl: this.form.controls.parameters,
            httpOptions: {
                baseQuery: this.resourceBaseHttpQuery
            },
            objectOptions: {
                forceOperation: ObjectQueryOperation.Get
            },
            source: 'component_select'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.saved) {
                _this.onQuerySaved(e.saved);
            }
        });
    };
    SelectFieldViewParamsComponent.prototype.onQuerySaved = function (event) {
        var responseColumns = this.queryService.getAutoDetectColumns(event.query, true);
        if (!responseColumns) {
            this.notificationService.error('Failed to detect fields', 'Response is incorrect');
            return;
        }
        var columns = responseColumns.map(function (item) {
            var result = rawListViewSettingsColumnsToDisplayField(item);
            result.visible = true;
            return result;
        });
        var prevColumns = this.form.controls.columns.value;
        this.form.patchValue({
            query: event.query,
            columns: columns.map(function (item) {
                var existingColumn = prevColumns ? prevColumns.find(function (i) { return i.name == item.name; }) : undefined;
                return existingColumn || item;
            })
        });
    };
    SelectFieldViewParamsComponent.prototype.onModelButtonClick = function (button) {
        if (button.name == 'add_model') {
            this.addModel(button.data['addModelComponent']);
        }
    };
    SelectFieldViewParamsComponent.prototype.addModel = function (addModelComponent) {
        var _this = this;
        this.resourceModelEditController
            .addModel(this.resource, addModelComponent, { source: this.object })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.link) {
                _this.routing.navigateApp(result.link);
            }
            else {
                _this.form.controls.model.patchValue({ queryType: QueryType.Simple, model: result.modelDescription.model });
                _this.form.markAsDirty();
            }
        });
    };
    SelectFieldViewParamsComponent.prototype.toStaticOptions = function (merge) {
        var _this = this;
        if (merge === void 0) { merge = false; }
        var _a;
        if (this.toStaticOptionsSubscription) {
            this.toStaticOptionsSubscription.unsubscribe();
            this.toStaticOptionsSubscription = undefined;
        }
        var fieldParams = this.form.serialize();
        var parameters = fieldParams['parameters']
            ? fieldParams['parameters'].map(function (item) { return new ParameterField().deserialize(item); })
            : [];
        var inputs = fieldParams['inputs'] ? fieldParams['inputs'].map(function (item) { return new Input().deserialize(item); }) : [];
        var params = applyParamInputs((_a = {},
            _a[PER_PAGE_PARAM] = 100,
            _a), inputs, { context: this.context, parameters: parameters });
        this.modelSelectSource.init({
            resource: fieldParams['resource'],
            query: fieldParams['query'] ? new ListModelDescriptionQuery().deserialize(fieldParams['query']) : undefined,
            queryParameters: parameters,
            columns: fieldParams['columns'] ? fieldParams['columns'].map(function (item) { return deserializeDisplayField(item); }) : undefined,
            valueField: fieldParams['value_field'],
            nameField: fieldParams['label_field'],
            nameInput: fieldParams['label_field_input']
                ? new Input().deserialize(fieldParams['label_field_input'])
                : undefined,
            subtitleField: fieldParams['subtitle_field'],
            subtitleInput: fieldParams['subtitle_input'] ? new Input().deserialize(fieldParams['subtitle_input']) : undefined,
            iconField: fieldParams['icon_field'],
            iconInput: fieldParams['icon_input'] ? new Input().deserialize(fieldParams['icon_input']) : undefined,
            colorField: fieldParams['color_field'],
            colorInput: fieldParams['color_input'] ? new Input().deserialize(fieldParams['color_input']) : undefined,
            params: params,
            multiple: this.multiple,
            context: this.context,
            contextElement: this.contextElement
        });
        this.modelSelectSource.reset();
        this.toStaticOptionsLoading = true;
        this.cd.markForCheck();
        this.toStaticOptionsSubscription = this.processStaticOptionsPage({})
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var options = values(result);
            if (!options.length) {
                _this.notificationService.error('No options found', 'Specified query contains no options');
                return;
            }
            var existingOptions = merge ? _this.form.controls.options.value : [];
            var existingColors = existingOptions.map(function (item) { return item['color']; });
            var newOptions = existingOptions.concat(options
                .filter(function (option) {
                if (merge) {
                    return _this.form.controls.options.value.find(function (item) { return item.value === option.value; }) === undefined;
                }
                else {
                    return true;
                }
            })
                .map(function (item, i) {
                var autoColor = _this.form.getAutoColor(existingColors, i);
                if (!existingColors.includes(autoColor)) {
                    existingColors.push(autoColor);
                }
                return {
                    color: autoColor,
                    name: item.name,
                    value: item.value,
                    subtitle: item.data['subtitle'],
                    icon: item.image
                };
            }));
            _this.form.formArraySet('options', newOptions.map(function () { return _this.form.createOption(); }));
            _this.form.controls.options_type.patchValue(OptionsType.Static);
            _this.form.controls.options.patchValue(newOptions);
            _this.toStaticOptionsLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.toStaticOptionsLoading = false;
            _this.cd.markForCheck();
        });
    };
    SelectFieldViewParamsComponent.prototype.processStaticOptionsPage = function (optionsAcc) {
        var _this = this;
        if (!this.modelSelectSource.isFetchAvailable()) {
            return of(optionsAcc);
        }
        return this.modelSelectSource.fetch('').pipe(switchMap(function (result) {
            optionsAcc = result.reduce(function (acc, item) {
                var key = JSON.stringify(item.value);
                if (!acc.hasOwnProperty(key)) {
                    acc[key] = item;
                }
                return acc;
            }, optionsAcc);
            return _this.processStaticOptionsPage(optionsAcc);
        }));
    };
    SelectFieldViewParamsComponent.prototype.setOptionsCollapsed = function (value) {
        this.optionsCollapsed = value;
        this.cd.markForCheck();
        this.updateDisplayItems();
    };
    return SelectFieldViewParamsComponent;
}(FieldParamsComponent));
export { SelectFieldViewParamsComponent };
registerFieldViewParamsComponent(FieldType.Select, SelectFieldViewParamsComponent);
registerFieldViewParamsComponent(FieldType.RadioButton, SelectFieldViewParamsComponent);
registerFieldViewParamsComponent(FieldType.MultipleSelect, SelectFieldViewParamsComponent);

import pickBy from 'lodash/pickBy';

import { WorkflowStepRun } from './workflow-step-run';

export class WorkflowRun {
  public stepRuns: WorkflowStepRun[] = [];
  public resultStepRun: WorkflowStepRun;

  constructor(options: Partial<WorkflowRun> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object) {
    if (data['step_runs']) {
      this.stepRuns = data['step_runs'].map(item => new WorkflowStepRun().deserialize(item));
    }

    if (data['result_step_run']) {
      this.resultStepRun = new WorkflowStepRun().deserialize(data['result_step_run']);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      step_runs: this.stepRuns.map(item => item.serialize()),
      result_step_run: this.resultStepRun ? this.resultStepRun.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

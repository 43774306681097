import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export type StackAlign = 'left' | 'center' | 'right' | 'space-between';

@Component({
  selector: 'app-stack',
  templateUrl: './stack.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackComponent implements OnInit {
  @HostBinding('class.stack') cls = true;
  @Input() align: StackAlign;

  @HostBinding('class.stack_align_left') get alignLeft() {
    return this.align == 'left';
  }

  @HostBinding('class.stack_align_center') get alignCenter() {
    return this.align == 'center';
  }

  @HostBinding('class.stack_align_right') get alignRight() {
    return this.align == 'right';
  }

  @HostBinding('class.stack_align_space-between') get alignSpaceBetween() {
    return this.align == 'space-between';
  }

  constructor() {}

  ngOnInit() {}
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-password-field',
  templateUrl: 'password-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFieldComponent extends FieldComponent {
  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }
}

registerFieldComponent(FieldType.Password, PasswordFieldComponent);

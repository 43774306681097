var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyParamInput$, Input } from '@modules/fields';
export function outputDataToHtml(value, options) {
    if (options === void 0) { options = {}; }
    if (!value) {
        return of(undefined);
    }
    return combineLatest(value.blocks.map(function (block) {
        switch (block.type) {
            case 'header':
                return of("<h" + block.data['level'] + ">" + block.data['text'] + "</h" + block.data['level'] + ">");
            case 'paragraph':
                return of("<p>" + block.data['text'] + "</p>");
            case 'formula':
                var input = new Input();
                if (block.data['value']) {
                    input.deserialize(block.data['value']);
                }
                return applyParamInput$(input, __assign({}, options, { defaultValue: '' })).pipe(map(function (content) { return "<p>" + content + "</p>"; }));
            default:
                console.log('Unknown block type', block.type);
                console.log(block);
                return of('');
        }
    })).pipe(map(function (blockContents) { return blockContents.join(''); }));
}

import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { CustomizeService } from '../../services/customize/customize.service';

@Injectable({
  providedIn: 'root'
})
export class WaitUnsavedChangesGuard implements CanDeactivate<Component> {
  constructor(private customizeService: CustomizeService) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.customizeService.handler == undefined) {
      return true;
    }

    return this.customizeService.changesSaving$.pipe(
      first(value => !value),
      map(() => true)
    );
  }
}

export enum TimeFormat {
  HourMinute24 = 'hour_minute_24',
  HourMinute12 = 'hour_minute_12',
  HourMinuteSecond24 = 'hour_minute_second_24',
  HourMinuteSecond12 = 'hour_minute_second_12',
  Relative = 'relative',
  Custom = 'custom'
}

export const defaultTimeFormat = TimeFormat.HourMinute24;

export const momentTimeFormats = {
  [TimeFormat.HourMinute24]: 'HH:mm',
  [TimeFormat.HourMinute12]: 'hh:mm A',
  [TimeFormat.HourMinuteSecond24]: 'HH:mm:ss',
  [TimeFormat.HourMinuteSecond12]: 'hh:mm:ss A'
};

export function isClock12TimeFormat(format: TimeFormat): boolean {
  return [TimeFormat.HourMinute12, TimeFormat.HourMinuteSecond12].includes(format);
}

export const momentTimeEditFormats = {
  [TimeFormat.HourMinute24]: 'HH:mm',
  [TimeFormat.HourMinute12]: 'hh:mm A',
  [TimeFormat.HourMinuteSecond24]: 'HH:mm:ss',
  [TimeFormat.HourMinuteSecond12]: 'hh:mm:ss A',
  [TimeFormat.Relative]: 'HH:mm:ss'
};

export function isClock12TimeEditFormat(format: TimeFormat) {
  return [TimeFormat.HourMinute12, TimeFormat.HourMinuteSecond12].includes(format);
}

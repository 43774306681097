import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { JsonToHtmlPipe } from './pipes/json-to-html/json-to-html.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TextEditorComponent, JsonToHtmlPipe],
  exports: [TextEditorComponent, JsonToHtmlPipe],
  entryComponents: [TextEditorComponent]
})
export class TextEditorModule {}

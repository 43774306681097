import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  create(subject: string, name: string, text: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`tickets/`);
        let headers = new HttpHeaders();
        const data = new HttpParams({
          fromObject: {
            subject: subject,
            name: name,
            text: text
          }
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }
}

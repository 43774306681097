export enum ApiType {
  JetDjango = 'jet_django',
  JetBridge = 'jet_bridge'
}

export function versionCompare(v1, v2, options = {}) {
  const lexicographical = options && options['lexicographical'];
  const zeroExtend = options && options['zeroExtend'];
  let v1parts = (v1 || '').toString().split('.').map(Number);
  let v2parts = (v2 || '').toString().split('.').map(Number);

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) {
      v1parts.push(0);
    }
    while (v2parts.length < v1parts.length) {
      v2parts.push(0);
    }
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1;
    }

    if (v1parts[i] == v2parts[i]) {
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}

export function versionLessThan(v1, v2) {
  return versionCompare(v1, v2) == -1;
}

export function versionGreaterThanOrEquals(v1, v2) {
  return versionCompare(v1, v2) != -1;
}

export class ApiInfo {
  public version: string;
  public type: string;
  public storeAvailable = false;
  public mediaUrlTemplate: string;

  deserialize(data: Object): ApiInfo {
    this.version = data['version'];
    this.type = data['type'] || ApiType.JetDjango;
    this.storeAvailable = !!data['store_available'];
    this.mediaUrlTemplate = data['media_url_template'];

    return this;
  }

  get customType() {
    return this.type && this.type != ApiType.JetBridge && this.type != ApiType.JetDjango;
  }

  mediaUrl(path: string) {
    return this.mediaUrlTemplate.replace('{}', path);
  }

  versionCompare(v2, options = {}) {
    return versionCompare(this.version, v2);
  }

  versionLessThan(v2) {
    return versionLessThan(this.version, v2);
  }

  versionGreaterThanOrEquals(v2) {
    return versionGreaterThanOrEquals(this.version, v2);
  }

  isCompatibleJetBridge(version: { jetBridge: string; jetDjango: string }) {
    return (
      (this.type == ApiType.JetBridge && this.versionGreaterThanOrEquals(version.jetBridge)) ||
      (this.type == ApiType.JetDjango && this.versionGreaterThanOrEquals(version.jetDjango))
    );
  }
}

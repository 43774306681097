import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ViewSettings, ViewSettingsStore } from '@modules/customize';
import {
  getMenuItemSystemActionTypeInfo,
  MenuBlockLayout,
  MenuGeneratorService,
  MenuItem,
  MenuItemSystemActionType,
  menuItemSystemActionTypes,
  MenuItemType
} from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

interface SystemOption {
  type: MenuItemSystemActionType;
  label: string;
  actionLabel: string;
  icon: string;
}

export interface AddMenuItemOptions {
  item: MenuItem;
  prepend: boolean;
  opened?: boolean;
  customize?: boolean;
}

@Component({
  selector: 'app-add-menu-item-menu',
  templateUrl: './add-menu-item-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMenuItemMenuComponent implements OnInit, OnDestroy {
  @Input() layout: MenuBlockLayout;
  @Output() selectMenuItem = new EventEmitter<AddMenuItemOptions>();

  @ViewChild('add_menu_item_dropdown') menu: MatMenu;

  pages: { label: string; type: MenuItemType; page: ViewSettings }[] = [];
  systemOptions: SystemOption[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentUserStore: CurrentUserStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuGeneratorService: MenuGeneratorService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updatePages();

    this.systemOptions = menuItemSystemActionTypes
      .map(systemType => {
        const info = getMenuItemSystemActionTypeInfo(systemType);

        if (!info) {
          return;
        }

        return {
          type: systemType,
          label: info.label,
          actionLabel: info.actionLabel,
          icon: info.icon
        };
      })
      .filter(item => item !== undefined);
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  updatePages() {
    this.viewSettingsStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.pages = viewSettings
          .filter(item => item.uniqueName)
          .map(item => {
            return {
              label: item.name,
              type: MenuItemType.Simple,
              page: item
            };
          })
          .sort((lhs, rhs) => {
            if (lhs.label.toLowerCase() < rhs.label.toLowerCase()) {
              return -1;
            } else if (lhs.label.toLowerCase() > rhs.label.toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          });
        this.cd.markForCheck();
      });
  }

  addPageMenuItem(page: ViewSettings, prepend = false) {
    const item = this.menuGeneratorService.createPageMenuItem(page);

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend
    });
  }

  addUrlMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createUrlMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      customize: true
    });
  }

  addSystemMenuItem(option: SystemOption, prepend = false) {
    const item = this.menuGeneratorService.createSystemMenuItem(option.type);

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend
    });
  }

  addSectionMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createSectionMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend
    });
  }

  addProjectMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createProjectMenuItem(this.layout, {
      imageFill: this.currentProjectStore.instance.logoFill,
      imageColor: this.currentProjectStore.instance.logoColor
    });

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      customize: true
    });
  }

  addUserMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createUserMenuItem(this.layout);

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      opened: true
    });
  }

  addImageMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createImageMenuItem(this.layout);

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      opened: true
    });
  }

  addButtonMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createButtonMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      customize: true
    });
  }

  addDropdownMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createDropdownMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      opened: true
    });
  }

  addSeparatorMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createSeparatorMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend
    });
  }

  addShareMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createShareMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend
    });
  }

  addCustomMenuItem(prepend = false) {
    const item = this.menuGeneratorService.createCustomMenuItem();

    this.selectMenuItem.emit({
      item: item,
      prepend: prepend,
      customize: true
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { PER_PAGE_PARAM } from '@modules/models';
import { InstanceStore } from '@shared';
import { CustomViewTemplateService } from '../services/custom-view-template/custom-view-template.service';
var CustomViewTemplateCounterStore = /** @class */ (function (_super) {
    __extends(CustomViewTemplateCounterStore, _super);
    function CustomViewTemplateCounterStore(customViewTemplateService) {
        var _this = _super.call(this) || this;
        _this.customViewTemplateService = customViewTemplateService;
        return _this;
    }
    CustomViewTemplateCounterStore.prototype.ngOnDestroy = function () { };
    CustomViewTemplateCounterStore.prototype.fetchData = function () {
        var _a;
        return this.customViewTemplateService.get((_a = {}, _a[PER_PAGE_PARAM] = 1, _a)).pipe(map(function (response) { return response.count; }));
    };
    CustomViewTemplateCounterStore.prototype.getApprox$ = function () {
        return this.get$().pipe(map(function (value) {
            return value >= 10 ? Math.floor(value / 10) * 10 : undefined;
        }));
    };
    CustomViewTemplateCounterStore.prototype.getApproxFirst$ = function () {
        return this.getApprox$().pipe(first());
    };
    return CustomViewTemplateCounterStore;
}(InstanceStore));
export { CustomViewTemplateCounterStore };

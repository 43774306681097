<div class="request">
  <div *ngIf="requestParameters" class="request__item">
    <div class="request__title"><span class="icon icon-redo request__title-icon"></span> HTTP Request</div>
    <div class="request__content">
      <div class="request__option">
        <span class="request__option-name">Request Method:</span>
        <span class="request__option-value">{{ requestParameters.method }}</span>
      </div>
      <div class="request__option">
        <span class="request__option-name">Request URL:</span>
        <span class="request__option-value">{{ requestParameters.url }}</span>
      </div>
      <div *ngIf="requestParameters.queryParams.length" class="request__option">
        <span class="request__option-name">Query Parameters:</span>
        <span class="request__option-value">
          <ng-container *ngFor="let item of requestParameters.queryParams; let last = last"
            >{{ item.name }}={{ item.value | appEncodeURIComponent
            }}<ng-container *ngIf="!last">&</ng-container></ng-container
          >
        </span>
      </div>
      <ng-container *ngIf="requestParameters.headers.length">
        <div *ngFor="let item of requestParameters.headers" class="request__option">
          <span class="request__option-name">{{ item.name }}:</span>
          <span class="request__option-value">{{ item.value }}</span>
        </div>
      </ng-container>
      <div *ngIf="requestParameters.body | appIsSet" class="request__body">
        {{ requestParameters.body }}
      </div>
    </div>
  </div>
  <div class="request__item">
    <div class="request__title">
      <span class="icon icon-undo request__title-icon"></span>{{ responseTitle }}
      <small *ngIf="response && !responseActual"> (execute to update)</small>
    </div>
    <div *ngIf="response" class="request__content">
      <ng-container *ngIf="responseShowHeaders">
        <div class="request__option">
          <span class="request__option-name">Status Code:</span>
          <span class="request__option-value">
            {{ response.status }}
            <ng-container *ngIf="responseStatus"> – {{ responseStatus }}</ng-container>
          </span>
        </div>
        <div *ngFor="let key of response.headers.keys()" class="request__option">
          <span class="request__option-name">{{ key }}:</span>
          <span class="request__option-value">{{ response.headers.get(key) }}</span>
        </div>
      </ng-container>
      <div class="request__body">
        <ng-container *ngIf="isArray(responseBodyDisplay)">
          &#91;
          <ngx-json-viewer [json]="responseBodyDisplay"></ngx-json-viewer>
          &#93;
        </ng-container>
        <ng-container *ngIf="isObject(responseBodyDisplay)">
          &#123;
          <ngx-json-viewer [json]="responseBodyDisplay"></ngx-json-viewer>
          &#125;
        </ng-container>
        <ng-container *ngIf="!isArray(responseBodyDisplay) && !isObject(responseBodyDisplay)">
          {{ responseBodyDisplay }}
        </ng-container>
        <ng-container *ngIf="responseBodyDisplay === undefined">undefined</ng-container>
        <ng-container *ngIf="responseBodyDisplay === null">null</ng-container>
      </div>
    </div>
    <div *ngIf="!response" class="request__content" [class.loading-animation]="false">
      <div class="request__option">
        <span class="request__option-name"><span class="stub-text">Status Code:</span></span>
        <span class="request__option-value"><span class="stub-text">200 - OK</span></span>
      </div>
      <div class="request__option">
        <span class="request__option-name"><span class="stub-text">content-type:</span></span>
        <span class="request__option-value"><span class="stub-text">application/json</span></span>
      </div>
      <div class="request__body">
        <span class="stub-text">
          it a very long<br />
          long long long long long long<br />
          response
        </span>
      </div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./polar-area-chart.component";
import * as i2 from "../../../theme/services/theme/theme.service";
var styles_PolarAreaChartComponent = [];
var RenderType_PolarAreaChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PolarAreaChartComponent, data: {} });
export { RenderType_PolarAreaChartComponent as RenderType_PolarAreaChartComponent };
export function View_PolarAreaChartComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { chartElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["chart_element", 1]], null, 0, "canvas", [], null, null, null, null, null))], null, null); }
export function View_PolarAreaChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-polar-area-chart", [], null, null, null, View_PolarAreaChartComponent_0, RenderType_PolarAreaChartComponent)), i0.ɵdid(1, 4898816, null, 0, i1.PolarAreaChartComponent, [i2.ThemeService], null, null)], null, null); }
var PolarAreaChartComponentNgFactory = i0.ɵccf("app-polar-area-chart", i1.PolarAreaChartComponent, View_PolarAreaChartComponent_Host_0, { datasets: "datasets", animate: "animate", legend: "legend" }, {}, []);
export { PolarAreaChartComponentNgFactory as PolarAreaChartComponentNgFactory };

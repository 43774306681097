import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injector, OnDestroy, Type } from '@angular/core';
import defaults from 'lodash/defaults';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ScrollService } from '@common/scroll';
import { PopupRef } from '../../data/popup-ref';
import { markPopupMouseEvent } from '../../utils/events';
import * as i0 from "@angular/core";
import * as i1 from "../../../scroll/services/scroll/scroll.service";
import * as i2 from "@angular/cdk/overlay";
export function untilPopupClosed(popupRef, handler) {
    return function (source) {
        return source.pipe(takeUntil(popupRef.closed$.pipe(tap(function () {
            if (handler) {
                handler();
            }
        }))));
    };
}
var PopupService = /** @class */ (function () {
    function PopupService(scrollService, overlay) {
        var _this = this;
        this.scrollService = scrollService;
        this.overlay = overlay;
        this._items = new BehaviorSubject([]);
        this._overlays = [];
        this._items.subscribe(function (items) {
            _this.scrollService.setWindowBackScrollEnabled(!items.length);
            if (items.length) {
                if (last(items).enableWindowScroll) {
                    _this.scrollService.enableWindowScroll();
                }
                else {
                    _this.scrollService.disableWindowScroll();
                }
            }
            else {
                _this.scrollService.enableWindowScroll();
            }
        });
    }
    PopupService.prototype.ngOnDestroy = function () { };
    PopupService.prototype.push = function (data) {
        data.popupRequestClose = new Subject();
        var value = this.items.concat([data]);
        this._items.next(value);
        return data;
    };
    PopupService.prototype.last = function () {
        return this.items[this.items.length - 1];
    };
    PopupService.prototype.pop = function () {
        var value = this.items.slice(0, this.items.length - 1);
        this._items.next(value);
    };
    PopupService.prototype.remove = function (data) {
        var value = this.items.filter(function (item) { return item !== data; });
        this._items.next(value);
    };
    PopupService.prototype.clear = function () {
        this._items.next([]);
        this._overlays.forEach(function (item) { return item.dispose(); });
        this._overlays = [];
    };
    Object.defineProperty(PopupService.prototype, "items", {
        get: function () {
            return this._items.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupService.prototype, "items$", {
        get: function () {
            return this._items.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    PopupService.prototype.showComponent = function (options) {
        var _this = this;
        options = defaults(options, { animate: true, backdrop: true, centerHorizontally: true, centerVertically: true });
        var subscriptions = [];
        var position = this.overlay.position().global();
        if (options.centerHorizontally) {
            position.centerHorizontally();
        }
        if (options.centerVertically) {
            position.centerVertically();
        }
        var overlayRef = this.overlay.create({
            positionStrategy: position,
            scrollStrategy: options.allowBodyScroll
                ? this.overlay.scrollStrategies.reposition()
                : this.overlay.scrollStrategies.block(),
            hasBackdrop: options.backdrop,
            backdropClass: 'popup-background',
            panelClass: options.scrollable ? 'cdk-overlay-pane_scrollable' : undefined
        });
        this._overlays.push(overlayRef);
        var popupRef = new PopupRef({
            overlayRef: overlayRef,
            animate: options.animate
        });
        var injector = Injector.create([{ provide: PopupRef, useValue: popupRef }], options.injector);
        var portal = new ComponentPortal(options.component, null, injector);
        var componentRef = overlayRef.attach(portal);
        if (options.inputs) {
            Object.assign(componentRef.instance, options.inputs);
        }
        if (options.init) {
            options.init(componentRef.instance);
        }
        popupRef.popup = componentRef.location.nativeElement;
        popupRef.backdrop = overlayRef.backdropElement;
        popupRef.instance = componentRef.instance;
        subscriptions.push(fromEvent(componentRef.location.nativeElement, 'mousedown').subscribe(function (e) {
            markPopupMouseEvent(e);
        }));
        if (overlayRef.backdropElement) {
            subscriptions.push(fromEvent(overlayRef.backdropElement, 'mousedown').subscribe(function (e) {
                markPopupMouseEvent(e);
            }));
        }
        overlayRef
            .detachments()
            .pipe(first(), untilDestroyed(this))
            .subscribe(function () {
            _this._overlays = _this._overlays.filter(function (item) { return item !== overlayRef; });
            subscriptions.forEach(function (item) { return item.unsubscribe(); });
        });
        popupRef.show();
        return popupRef;
    };
    PopupService.ngInjectableDef = i0.defineInjectable({ factory: function PopupService_Factory() { return new PopupService(i0.inject(i1.ScrollService), i0.inject(i2.Overlay)); }, token: PopupService, providedIn: "root" });
    return PopupService;
}());
export { PopupService };

<div
  *ngIf="visible || (customizeService.enabled$ | async)"
  style="width: 100%; height: 100%;"
  appElement
  [appElementDetectVisible]="true"
  (appElementFirstVisible)="onFirstVisible()"
>
  <ng-container
    *ngIf="
      customViewSources.includes(source) && !(currentProjectStore.instance$ | async).features.isCustomViewsEnabled();
      then not_enabled;
      else content
    "
  ></ng-container>

  <ng-template #content>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="html">
        <div [innerHTML]="html | appSafeHtml"></div>
      </ng-container>

      <ng-container *ngIf="customView?.dist">
        <div *ngIf="customElementUploadPending">Save this component to initialize</div>
        <div
          *ngIf="!customElementUploadPending"
          appCustomElement
          [tagName]="customView.tagName"
          [inputs]="params"
          [outputs]="element.outputs"
          (outputEmitted)="onOutputEmitted($event.name, $event.event)"
        ></div>
      </ng-container>

      <app-custom-element-view
        *ngIf="view"
        [view]="view"
        [parameters]="element.parameters"
        [actions]="element.actions"
        [params]="params"
        [parentContext]="context"
      ></app-custom-element-view>

      <ng-container *ngIf="!html && !customView?.dist && !view">
        This component is not configured yet
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #not_enabled>
    <app-feature-overview-line>
      <div data-subtitle>Paid Feature</div>
      <div data-title>Create any component with <strong>Custom Components</strong></div>
      <div data-description>
        Write HTML or upload JS-based component built with React/Vue/Angular or JS Vanilla
      </div>
    </app-feature-overview-line>
  </ng-template>
</div>

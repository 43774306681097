<section class="collaboration">
  <ng-container
    *ngIf="(currentProjectStore.instance$ | async).features.isTasksEnabled(); then content; else not_enabled"
  ></ng-container>

  <ng-template #content>
    <div class="collaboration__content">
      <!--      <div class="collaboration__left" *ngIf="!(customizeEnabled$ | async)">-->
      <!--        <div class="menu2-main">-->
      <!--          <div [xsScrollableOptions]="{ stopPropagationAlways: true }" class="menu2-main__viewport" xsScrollable>-->
      <!--            <div class="menu2-main__content menu2-list">-->
      <!--              <div class="menu2-list__item menu2-list__item_collapse menu2-list__item_wrap">-->
      <!--                <div class="menu2-collapse menu2-collapse_opened">-->
      <!--                  <div class="menu2-collapse__header">-->
      <!--                    <div class="menu2-collapse__header-inner">-->
      <!--                      <div class="menu2-list__item">-->
      <!--                        <a class="menu2-item" [appLink]="currentProject?.settingsCollaborationMessagesLink">-->
      <!--                          <div class="menu2-item__icon icon-chat menu2-item__icon_collaboration"></div>-->
      <!--                          <div class="menu2-item__label">Comments</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div class="menu2-collapse__content" *ngIf="currentPage === 'messages'">-->
      <!--                    <div class="menu2-collapse__content-inner">-->
      <!--                      <div class="menu2-list__item">-->
      <!--                        <a [appLink]="currentProject?.settingsCollaborationMessagesLink" class="menu2-item">-->
      <!--                          <div class="menu2-item__icon icon-dot"></div>-->
      <!--                          <div class="menu2-item__label">All</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </div>-->

      <!--                      <div class="menu2-list__item">-->
      <!--                        <a-->
      <!--                          class="menu2-item"-->
      <!--                          [queryParams]="{ read: 0 }"-->
      <!--                          [appLink]="currentProject?.settingsCollaborationMessagesLink"-->
      <!--                        >-->
      <!--                          <div class="menu2-item__icon icon-dot"></div>-->
      <!--                          <div class="menu2-item__label">Unread</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </div>-->

      <!--                      <div class="menu2-list__item">-->
      <!--                        <a-->
      <!--                          class="menu2-item"-->
      <!--                          [queryParams]="{ mention: 1 }"-->
      <!--                          [appLink]="currentProject?.settingsCollaborationMessagesLink"-->
      <!--                        >-->
      <!--                          <div class="menu2-item__icon icon-dot"></div>-->
      <!--                          <div class="menu2-item__label">Mentioned</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              <div-->
      <!--                class="menu2-list__item menu2-list__item_collapse menu2-list__item_wrap"-->
      <!--                *ngFor="let queue of queues$ | async"-->
      <!--              >-->
      <!--                <div class="menu2-collapse menu2-collapse_opened">-->
      <!--                  <div class="menu2-collapse__header">-->
      <!--                    <div class="menu2-collapse__header-inner">-->
      <!--                      <div class="menu2-list__item">-->
      <!--                        <a-->
      <!--                          class="menu2-item"-->
      <!--                          [queryParams]="{ queue: queue.uid }"-->
      <!--                          [appLink]="currentProject?.settingsCollaborationTasksLink"-->
      <!--                        >-->
      <!--                          <div class="menu2-item__icon icon-check_3 menu2-item__icon_collaboration"></div>-->
      <!--                          <div class="menu2-item__label">{{ queue.name }}</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div class="menu2-collapse__content" *ngIf="currentPage === 'tasks'">-->
      <!--                    <div class="menu2-collapse__content-inner">-->
      <!--                      <a class="menu2-list__item" *ngFor="let status of queue.statuses">-->
      <!--                        <a-->
      <!--                          class="menu2-item"-->
      <!--                          [queryParams]="{ queue: queue.uid, status: status.uid }"-->
      <!--                          [appLink]="currentProject?.settingsCollaborationTasksLink"-->
      <!--                        >-->
      <!--                          <div class="menu2-item__icon icon-dot"></div>-->
      <!--                          <div class="menu2-item__label">{{ status.name }}</div>-->
      <!--                          <div class="menu2-item__right"></div>-->
      <!--                        </a>-->
      <!--                      </a>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="collaboration__right">
        <div class="collaboration__right-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #not_enabled>
    <app-feature-overview>
      <div data-subtitle>Paid Feature</div>
      <div data-title>Collaborate on a project with <strong>Collaboration</strong></div>
      <div data-description>
        Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly
        through Jet and make sure everyone is up-to-date.
      </div>
    </app-feature-overview>
  </ng-template>
</section>

<div class="token-popup-search">
  <input
    type="text"
    class="token-popup-search__input"
    [formControl]="searchControl"
    [appAutofocus]="true"
    placeholder="Search..."
  />
  <span
    *ngIf="searchControl.value | appIsSet"
    class="token-popup-search__close icon-close"
    (click)="clearSearch()"
    [appTip]="'Clear'"
    [appTipOptions]="{ side: 'top' }"
  ></span>
</div>

<div *ngIf="!(searchControl.value | appIsSet)" class="token-popup__item">
  <div class="token-popup-section">
    <div class="token-popup-section__title">
      <div class="token-popup-section__title-main">Popular integrations</div>
    </div>

    <div class="token-popup-section__content">
      <a
        *ngFor="let item of popularOptions"
        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
        [class.token-popup-list-item_orange]="item.orange"
        [class.token-popup-list-item_active]="false"
        href="javascript:void(0)"
        (click)="selectOption(item)"
      >
        <div class="token-popup-list-item__inner">
          <div class="token-popup-list-item__line">
            <span *ngIf="item.icon" class="token-popup-list-item__left">
              <span
                class="token-popup-list-item__icon"
                [class.token-popup-list-item__icon_orange]="item.orange"
                [ngClass]="'icon-' + item.icon"
              >
              </span>
            </span>

            <span *ngIf="item.image" class="token-popup-list-item__left">
              <span
                class="token-popup-list-item__image token-popup-list-item__image_size_l"
                [style.background-image]="'/assets/images/sso/' + item.image + '.svg' | appDeployCssUrl"
              >
              </span>
            </span>

            <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
              {{ item.label | appCapitalize }}
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="token-popup__item">
  <div class="token-popup-section">
    <div *ngIf="!(searchControl.value | appIsSet)" class="token-popup-section__title">
      <div class="token-popup-section__title-main">Other</div>
    </div>

    <div class="token-popup-section__content">
      <a
        *ngFor="let item of filteredOptions"
        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
        [class.token-popup-list-item_orange]="item.orange"
        [class.token-popup-list-item_active]="false"
        href="javascript:void(0)"
        (click)="selectOption(item)"
      >
        <div class="token-popup-list-item__inner">
          <div class="token-popup-list-item__line">
            <span *ngIf="item.icon" class="token-popup-list-item__left">
              <span
                class="token-popup-list-item__icon"
                [class.token-popup-list-item__icon_orange]="item.orange"
                [ngClass]="'icon-' + item.icon"
              >
              </span>
            </span>

            <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
              {{ item.label | appCapitalize }}
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<div *ngIf="!filteredOptions.length" class="token-popup-list-item">
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__tip">Nothing found</div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Environment, Project, Resource, ResourceType, ResourceTypeItem } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { AzureBlobResourceController, ResourceControllerService, ResourceParamsResult } from '@modules/resources';
import { Storage } from '@modules/storages';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface AzureBlobParamsOptions {
  account: string;
  sas: string;
}

@Injectable()
export class AzureBlobGeneratorService extends ResourceGeneratorService<AzureBlobParamsOptions> {
  constructor(private resourceControllerService: ResourceControllerService, private apiService: ApiService) {
    super();
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<AzureBlobParamsOptions> {
    return of({
      account: resource.params['account'],
      sas: resource.params['sas']
    });
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: AzureBlobParamsOptions
  ): Observable<ResourceParamsResult> {
    const controller = this.resourceControllerService.get(ResourceType.AzureBlob) as AzureBlobResourceController;

    return controller.listContainers(options.account, options.sas).pipe(
      map(containers => {
        const storages = containers.map(item => {
          const storage = new Storage();

          storage.uniqueName = item;
          storage.name = item;

          storage.uploadQuery = new StorageQuery();
          storage.uploadQuery.queryType = QueryType.Simple;
          storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

          return storage;
        });

        return {
          resourceParams: {
            account: options.account,
            sas: options.sas
          },
          storages: storages.map(item => item.serialize())
        };
      }),
      this.apiService.catchApiError()
    );
  }
}

<div class="compact-inputs-row compact-inputs-row_vertical-align_top">
  <div *ngIf="typeControl.value == types.All" class="compact-inputs-row__item">
    <app-compact-input
      [formControl]="allControl"
      [postfix]="'px'"
      [placeholder]="'0'"
      [widthPx]="60"
      [number]="true"
      [numberOptions]="{ min: 0 }"
      (change)="updateLocalValue(allControl, allControl.value)"
    ></app-compact-input>
  </div>

  <ng-container *ngIf="typeControl.value == types.Custom">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="topControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(topControl, topControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-align_vertical_top"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="rightControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(rightControl, rightControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-align_horizontal_right"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="bottomControl"
        [placeholder]="'0'"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(bottomControl, bottomControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-align_vertical_bottom"></span>
      </div>
    </div>

    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-compact-input
        [formControl]="leftControl"
        [number]="true"
        [numberOptions]="{ min: 0 }"
        (change)="updateLocalValue(leftControl, leftControl.value)"
      ></app-compact-input>

      <div class="compact-inputs-row__subtitle">
        <span class="icon-align_horizontal_left"></span>
      </div>
    </div>
  </ng-container>

  <div class="compact-inputs-row__item">
    <app-compact-segmented-control
      [formControl]="typeControl"
      [options]="typeOptions"
      (change)="updateValue()"
    ></app-compact-segmented-control>
  </div>
</div>

import * as moment from 'moment';

import { User } from '@modules/users';

import { Environment } from './environment';
import { Project } from './project';
import { ProjectProperty } from './project-property';
import { ProjectGroup, ProjectUser } from './project-user';
import { ValidationCode } from './validation-code';

export class ProjectInvite {
  public uid: string;
  public project: Project;
  public projectUsers: ProjectUser[] = [];
  public environment: Environment;
  public user: User;
  public userEmail: string;
  public inviter: User;
  public group: ProjectGroup;
  public properties = {};
  public code: ValidationCode;
  public dateAdd: any;

  deserialize(data: Object): ProjectInvite {
    this.uid = data['uid'];
    this.userEmail = data['user_email'];
    this.properties = data['properties'];
    this.dateAdd = moment(data['date_add']);

    if (data['project']) {
      this.project = new Project().deserialize(data['project']);
    }

    if (data['project_users']) {
      this.projectUsers = data['project_users'].map(item => new ProjectUser().deserialize(item));
    }

    if (data['environment']) {
      this.environment = new Environment().deserialize(data['environment']);
    }

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    if (data['inviter']) {
      this.inviter = new User().deserialize(data['inviter']);
    }

    if (data['group']) {
      this.group = new ProjectGroup().deserialize(data['group']);
    } else {
      this.group = new ProjectGroup();
    }

    if (data['code']) {
      this.code = new ValidationCode().deserialize(data['code']);
    }

    return this;
  }

  serialize() {
    return {
      user: this.user ? this.user.id : undefined,
      user_email: this.userEmail,
      group: this.group ? this.group.uid : undefined,
      properties: this.properties
    };
  }

  getEmail() {
    return this.user ? this.user.email : this.userEmail;
  }

  getFirstName() {
    return this.user ? this.user.firstName : undefined;
  }

  getLastName() {
    return this.user ? this.user.lastName : undefined;
  }

  link(webBaseUrl: string) {
    const code = this.code ? this.code.code : undefined;
    let result = `${webBaseUrl}/projects/invite/${code}`;

    if (this.user) {
      result += '?registered=1';
    }

    return result;
  }

  getPropertyValue(property: ProjectProperty): any {
    let value = this.properties[property.uid];

    if (property.fieldDescription && property.fieldDescription.deserializeValue) {
      value = property.fieldDescription.deserializeValue(value, property.field);
    }

    return value;
  }

  setPropertyValue(property: ProjectProperty, value: any) {
    if (property.fieldDescription && property.fieldDescription.serializeValue) {
      value = property.fieldDescription.serializeValue(value, property.field);
    }

    this.properties[property.uid] = value;
  }
}

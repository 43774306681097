import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Power4, TimelineMax } from 'gsap';
import clamp from 'lodash/clamp';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CustomViewsStore } from '@modules/custom-views';
import {
  CarouselControlsPosition,
  CarouselSettings,
  CarouselStep,
  CustomizeService,
  getModelAttributesByColumns,
  getModelBulkAttributesByColumns,
  ListDefaultSelection,
  rawListViewSettingsColumnsToViewContextOutputs,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import {
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  LOADING_VALUE,
  NOT_SET_VALUE
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  CHECKED_ITEMS_OUTPUT,
  ColumnsModelListStore,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent, ListState, serializeDataSourceColumns } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Model, NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { View } from '@modules/views';
import { toggleClass } from '@shared';
import { isSet, MouseButton } from '@shared';

export interface CarouselState extends ListState<CarouselSettings> {
  page?: number;
  loadedPage?: boolean;
  perPage?: number;
  sortingField?: string;
  sortingAsc?: boolean;
  multipleSelection?: boolean;
  defaultSelection?: ListDefaultSelection;
}

function getListStateFetch(state: CarouselState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    dataSourceStaticData: state.dataSourceStaticData,
    dataSourceParams: state.dataSourceParams,
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    perPage: state.perPage,
    sortingField: state.sortingField,
    sortingAsc: state.sortingAsc
  };
}

function getListStateFetchNewParams(state: CarouselState): Object {
  return {
    params: pickBy(state.dataSourceParams, (v, k) => PAGE_PARAM != k),
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort
  };
}

function getListStateColumns(state: CarouselState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

function getListStateSelection(state: CarouselState): Object {
  return {
    multipleSelection: state.multipleSelection
  };
}

function getListStateDefaultSelection(state: CarouselState): Object {
  return {
    defaultSelection: state.defaultSelection
  };
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  providers: [ColumnsModelListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent extends ListLayoutComponent<CarouselSettings, CarouselState>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChild('viewport_element') viewportElement: ElementRef;
  @ViewChild('carousel_element') carouselElement: ElementRef;
  @ViewChild('carousel_viewport_element') carouselViewportElement: ElementRef;
  @ViewChildren('grid_item_element', { read: ElementRef }) gridItemElements = new QueryList<ElementRef<HTMLElement>>();

  layout = ListLayoutType.Carousel;
  cardView$ = new BehaviorSubject<View>(undefined);
  viewportWidth$ = new BehaviorSubject<number>(undefined);
  visibleColumns: DisplayField[] = [];
  displayPage = 1;
  loading = true;
  loadingItems = 4;
  error: string;
  configured = true;
  items: ListItem[];
  selectedItem: ListItem;
  checkedItems: { [k: string]: Model } = {};
  scrolled = false;
  fetchSubscription: Subscription;
  rowCards = 1;
  rowCardsVisible = 1;
  cardDefaultHeight = 245;
  dragging$ = new BehaviorSubject<boolean>(false);
  dragSubscriptions: Subscription[] = [];
  scrollTl = new TimelineMax();
  firstIndex = -1;
  lastIndex = -1;
  scrollPosition$ = new BehaviorSubject<number>(0);
  scrollingToPosition$ = new BehaviorSubject<number>(undefined);
  heightBeforeLoading: number;
  tintStyles = TintStyle;
  controlsPositions = CarouselControlsPosition;

  constructor(
    public listStore: ColumnsModelListStore,
    public customizeService: CustomizeService,
    public customViewsStore: CustomViewsStore,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    injector: Injector,
    cd: ChangeDetectorRef,
    customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initDataUpdates();
    this.initContext();

    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.cardView$.next(customView ? customView.view : undefined);
      });

    combineLatest(this.settings$, this.cardView$, this.viewportWidth$)
      .pipe(untilDestroyed(this))
      .subscribe(([settings, cardView, viewportWidth]) => {
        this.rowCards = this.getRowCards(settings, cardView, viewportWidth);
        this.rowCardsVisible = this.getRowCards(settings, cardView, viewportWidth - 80);
        this.cardDefaultHeight = cardView ? cardView.frame.height : undefined;
        this.cd.markForCheck();
      });

    this.initCarouselScroll();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getListState(
    settings: CarouselSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<CarouselState> {
    const page = parseInt(params[PAGE_PARAM], 10) || 1;

    params = cloneDeep(params);

    delete params[NEXT_PAGE_SCROLL_PARAM];

    if (!sort.length && isSet(settings.sortingField)) {
      sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
    }

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(
      map(([staticData, inputParams, modelDescription]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings.dataSource,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          page: page,
          loadedPage: this.listStore.fromPage <= page && page <= this.listStore.currentPage,
          perPage: settings ? settings.perPage : undefined,
          sortingField: settings ? settings.sortingField : undefined,
          sortingAsc: settings ? settings.sortingAsc : undefined,
          multipleSelection: settings.multipleSelection,
          defaultSelection: settings ? settings.defaultSelection : undefined
        };
      })
    );
  }

  onStateUpdated(state: CarouselState) {
    if (
      !isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
      !isEqual(getListStateSelection(state), getListStateSelection(this.listState))
    ) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
      let paramsNeedUpdate = false;

      if (newParams) {
        this.setSelectedItem(undefined, false);
        this.setChecked([], false);
        this.updateSelectedContext();

        if (this.setPage(1)) {
          paramsNeedUpdate = true;
        }
      }

      if (!paramsNeedUpdate) {
        this.fetch(state);
      }
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
        if (this.listStore.dataSource) {
          this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
          this.listStore.deserializeModelAttributes();
        }
      }

      if (
        !isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
        state.defaultSelection == ListDefaultSelection.First
      ) {
        const firstItem = this.items ? this.items[0] : undefined;

        this.setSelectedItem(firstItem, false);
        this.setChecked(firstItem ? [firstItem] : [], false);
        this.updateSelectedContext();
      }
    }
  }

  getStateQueryOptions(state: CarouselState): GetQueryOptions {
    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);

    queryOptions.filters = [...queryOptions.filters, ...state.filters];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    return queryOptions;
  }

  fetch(state: CarouselState) {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    this.configured = state.dataSource && state.dataSource.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.cd.markForCheck();

    this.contextElement.patchOutputValueMeta(EMPTY_OUTPUT, { loading: true });

    if (!this.configured) {
      this.listStore.dataSource = undefined;
      this.listStore.params = {};
      this.listStore.queryOptions = undefined;
      this.loading = false;
      this.cd.markForCheck();
      this.listStore.reset();
      return;
    }

    if (state.inputsNotSet) {
      this.items = undefined;
      this.loading = false;
      this.error = undefined;
      this.cd.markForCheck();
      this.listStore.reset();
      return;
    }

    this.items = undefined;
    this.loading = true;
    this.error = undefined;
    this.heightBeforeLoading = this.carouselElement
      ? this.carouselElement.nativeElement.getBoundingClientRect().height
      : undefined;
    this.cd.markForCheck();

    if (this.settings.defaultSelection && !this.selectedItem) {
      this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
    }

    if (state.inputsLoading) {
      this.listStore.reset();
      return;
    }

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = this.getStateQueryOptions(state);
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;
    this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;

    this.listStore.reset(state.page);
    this.cd.detectChanges();

    this.fetchSubscription = this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.items = result;
          this.firstIndex = 0;
          this.scrolled = false;
          this.cd.markForCheck();

          this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });

            if (this.settings.defaultSelection == ListDefaultSelection.First && state.page == 1) {
              const firstItem = this.items ? this.items[0] : undefined;

              this.setSelectedItem(firstItem, false);
              this.setChecked(firstItem ? [firstItem] : [], false);
              this.updateSelectedContext();
            }
          } else if (this.selectedItem) {
            this.checkSelectedModelIsActual();
          }
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else if (isPlainObject(error)) {
            this.error = JSON.stringify(error);
          } else if (error.hasOwnProperty('message')) {
            console.error(error);
            this.error = error.message;
          } else {
            console.error(error);
            this.error = error;
          }

          this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
          }

          this.cd.markForCheck();
        }
      );
  }

  initDataUpdates() {
    this.listStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
      this.items = items;
      this.cd.markForCheck();
    });

    this.listStore.loading$
      .pipe(skip(1))
      .pipe(untilDestroyed(this))
      .subscribe(loading => {
        this.loading = loading;
        this.cd.markForCheck();
      });
  }

  initCarouselScroll() {
    combineLatest(this.scrollPosition$, this.scrollingToPosition$)
      .pipe(untilDestroyed(this))
      .subscribe(([scrollPosition]) => {
        const firstIndex = this.getVisibleIndexFirst();
        const lastIndex = this.getVisibleIndexLast();

        if (this.firstIndex !== firstIndex) {
          this.firstIndex = firstIndex;
          this.cd.markForCheck();
        }

        if (this.lastIndex !== lastIndex) {
          this.lastIndex = lastIndex;
          this.cd.markForCheck();
        }

        if (this.carouselViewportElement) {
          const viewport = this.carouselViewportElement.nativeElement;
          const scrollRight = scrollPosition + viewport.offsetWidth;
          const endDistance = viewport.scrollWidth - scrollRight;

          if (endDistance <= Math.max(viewport.offsetWidth * 0.5, 400)) {
            this.onScrollFinished();
          }
        }
      });

    combineLatest(this.scrollingToPosition$, this.dragging$)
      .pipe(
        filter(() => !!this.carouselViewportElement),
        map(([scrollingToPosition, dragging]) => isSet(scrollingToPosition) || dragging),
        switchMap(manualScroll => {
          const result = of(manualScroll);
          return manualScroll ? result : result.pipe(delay(10));
        }),
        untilDestroyed(this)
      )
      .subscribe(manualScroll => {
        toggleClass(this.carouselViewportElement.nativeElement, 'carousel__viewport_manual-scroll', manualScroll);
      });
  }

  getRowCardMinWidth(cardView: View): number {
    if (cardView && cardView.widthResize.enabled && isSet(cardView.widthResize.min)) {
      return cardView.widthResize.min;
    } else if (cardView && isSet(cardView.frame.width)) {
      return cardView.frame.width;
    } else {
      return 240;
    }
  }

  getRowCards(settings: CarouselSettings, cardView: View, viewportWidth: number) {
    const cardMinWidth = this.getRowCardMinWidth(cardView);
    const rowMaxCards = isSet(viewportWidth) ? Math.max(Math.floor(viewportWidth / cardMinWidth), 1) : 1;
    return settings && isSet(settings.cardsPerRow) && settings.cardsPerRow <= rowMaxCards
      ? settings.cardsPerRow
      : rowMaxCards;
  }

  isPrevCarouselCardsAvailable(): boolean {
    return this.items && this.firstIndex > 0;
  }

  isNextCarouselCardsAvailable(): boolean {
    return this.items && this.lastIndex < this.items.length - 1;
  }

  getVisibleIndexFirst(): number {
    if (!this.carouselViewportElement) {
      return -1;
    }

    const scrollLeftActual = this.carouselViewportElement.nativeElement.scrollLeft;
    const scrollLeft = isSet(this.scrollingToPosition$.value) ? this.scrollingToPosition$.value : scrollLeftActual;
    const viewportBounds = this.carouselViewportElement.nativeElement.getBoundingClientRect();
    const gridItemElements = this.gridItemElements.toArray();

    const index = gridItemElements.findIndex((item, i) => {
      const itemBounds = item.nativeElement.getBoundingClientRect();
      return (
        Math.floor(itemBounds.left + itemBounds.width * 0.5) - Math.floor(viewportBounds.left) + scrollLeftActual >
        scrollLeft
      );
    });

    if (index === -1) {
      return gridItemElements.length - 1;
    }

    return index;
  }

  getVisibleIndexLast(): number {
    if (!this.carouselViewportElement) {
      return -1;
    }

    const scrollLeftActual = this.carouselViewportElement.nativeElement.scrollLeft;
    const scrollLeft = isSet(this.scrollingToPosition$.value) ? this.scrollingToPosition$.value : scrollLeftActual;
    const viewportBounds = this.carouselViewportElement.nativeElement.getBoundingClientRect();
    const gridItemElements = this.gridItemElements.toArray();

    const index = [...gridItemElements].reverse().findIndex((item, i) => {
      const itemBounds = item.nativeElement.getBoundingClientRect();
      return (
        Math.floor(itemBounds.left + itemBounds.width * 0.5) - Math.floor(viewportBounds.left) + scrollLeftActual <
        scrollLeft + viewportBounds.width
      );
    });

    if (index === -1) {
      return gridItemElements.length - 1;
    }

    return gridItemElements.length - 1 - index;
  }

  scrollToIndexWithDelta(delta: number) {
    if (delta === 0) {
      return;
    }

    const currentIndex = this.getVisibleIndexFirst();
    if (currentIndex === -1) {
      return;
    }

    const gridItemElements = this.gridItemElements.toArray();
    const newIndex = clamp(currentIndex + delta, 0, gridItemElements.length - 1);

    this.scrollToIndex(newIndex);
  }

  scrollToIndex(newIndex: number, force = false) {
    const currentIndex = this.getVisibleIndexFirst();
    if (currentIndex === newIndex && !force) {
      return;
    }

    const gridItemElements = this.gridItemElements.toArray();
    const item = gridItemElements[newIndex];
    if (!item) {
      return;
    }

    const viewportBounds = this.carouselViewportElement.nativeElement.getBoundingClientRect();
    const scrollLeft = this.carouselViewportElement.nativeElement.scrollLeft;
    const itemBounds = item.nativeElement.getBoundingClientRect();
    const x = itemBounds.left - viewportBounds.left + scrollLeft + (newIndex > 0 ? -80 : 0);

    this.scrollToPosition(x);
  }

  scrollToPosition(x: number) {
    this.scrollingToPosition$.next(x);

    const finish = (complete: boolean) => {
      this.scrollingToPosition$.next(undefined);
    };

    this.scrollTl.clear().to(this.carouselViewportElement.nativeElement, 0.6, {
      scrollTo: {
        x: x,
        onAutoKill: () => finish(false)
      },
      ease: Power4.easeOut,
      onOverwrite: () => finish(false),
      onComplete: () => finish(true)
    });
  }

  prevCarouselCards() {
    this.scrollToIndexWithDelta(-this.stepSize);
  }

  nextCarouselCards() {
    this.scrollToIndexWithDelta(this.stepSize);
  }

  onScroll() {
    const position = this.carouselViewportElement.nativeElement.scrollLeft;
    this.scrollPosition$.next(position);
  }

  onMouseWheel(e: WheelEvent) {
    const viewport = this.carouselViewportElement.nativeElement;
    const horizontalScroll = Math.abs(e.deltaX) > Math.abs(e.deltaY);
    if (!horizontalScroll) {
      return;
    }

    if (viewport.scrollLeft == 0 && e.deltaX < 0) {
      e.preventDefault();
    } else if (viewport.scrollLeft + viewport.offsetWidth >= viewport.scrollWidth && e.deltaX > 0) {
      e.preventDefault();
    }
  }

  onMouseDown(downEvent: MouseEvent) {
    if (!this.settings.dragNavigation) {
      return;
    }

    if (downEvent.button != MouseButton.Main) {
      return;
    }

    const downScrollLeft = this.carouselViewportElement.nativeElement.scrollLeft;
    const subscriptions = [];

    this.dragging$.next(false);

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();

          this.onMouseMove(downScrollLeft, downEvent, moveEvent);
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(() => this.onMouseUp())
    );

    this.dragSubscriptions = subscriptions;
  }

  onMouseMove(downScrollLeft: number, downEvent: MouseEvent, moveEvent: MouseEvent) {
    const delta = downEvent.clientX - moveEvent.clientX;

    if (!this.dragging$.value) {
      if (Math.abs(delta) < 5) {
        return;
      }

      this.dragging$.next(true);
      this.scrollTl.clear();
    }

    this.carouselViewportElement.nativeElement.scrollLeft = downScrollLeft + delta;
  }

  onMouseUp() {
    this.dragging$.next(false);

    this.dragSubscriptions.forEach(item => item.unsubscribe());
    this.dragSubscriptions = [];

    if (this.settings.snapOnNavigate) {
      const index = this.getVisibleIndexFirst();
      this.scrollToIndex(index, true);
    }
  }

  onScrollFinished() {
    this.listStore.getNext().pipe(untilDestroyed(this)).subscribe();
  }

  trackByFn(i, item: ListItem) {
    return item.model.primaryKey || i;
  }

  updateVisibleColumns(state: CarouselState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Card',
        icon: 'deselect',
        parameters: [],
        handler: () => {
          this.setSelectedItem(undefined);
          this.setChecked([]);
        }
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetFilters()
      }
    ]);
  }

  updateContextOutputs(state: CarouselState) {
    const columns = state.dataSource ? state.dataSource.columns : [];

    this.contextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Card',
        icon: 'duplicate_2',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: rawListViewSettingsColumnsToViewContextOutputs(
          columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Card',
        icon: 'hand',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Card selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Card selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      ...(state.multipleSelection
        ? [
            {
              uniqueName: CHECKED_ITEMS_OUTPUT,
              name: 'Checked Cards',
              icon: 'check',
              children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            }
          ]
        : [])
    ]);

    this.updateSelectedContext();
  }

  updateSelectedContext() {
    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    if (this.selectedItem) {
      this.contextElement.setOutputValue(
        SELECTED_ITEM_OUTPUT,
        getModelAttributesByColumns(this.selectedItem.model, columns)
      );
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }

    if (this.settings.multipleSelection) {
      const models: Model[] = values(this.checkedItems);
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
    } else {
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
    }
  }

  isItemSelected(item: ListItem, index: number) {
    if (this.settings.multipleSelection) {
      return this.isItemChecked(item, index);
    } else {
      return this.itemEquals(this.selectedItem, item);
    }
  }

  isItemChecked(item: ListItem, index: number) {
    const pk = item.model.primaryKey || `${index}`;
    return this.checkedItems[pk];
  }

  setSelectedItem(item: ListItem, updateContext = true) {
    this.selectedItem = item;
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  setChecked(value: ListItem[], updateContext = true) {
    this.checkedItems = value.reduce((acc, item) => {
      const pk = item.model.primaryKey;
      acc[pk] = item.model;
      return acc;
    }, {});
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  toggleSelectedItem(item: ListItem, index: number, element: HTMLElement, click = false) {
    if (this.selectedItem === item) {
      this.setSelectedItem(undefined, false);
    } else {
      this.setSelectedItem(item, false);
    }

    if (this.settings.multipleSelection) {
      const pk = item.model.primaryKey || index;
      const checked = this.isItemChecked(item, index);

      if (!checked) {
        const checkedItems = clone(this.checkedItems);
        checkedItems[pk] = item.model;
        this.checkedItems = checkedItems;
      } else {
        const checkedItems = clone(this.checkedItems);
        delete checkedItems[pk];
        this.checkedItems = checkedItems;
      }
    }

    this.updateSelectedContext();

    if (click && this.settings.cardClickAction && this.selectedItem) {
      this.actionControllerService
        .execute(this.settings.cardClickAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.selectedItem.model.getAttributes()
          },
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }

  onModelUpdated(model: Model) {
    if (this.selectedItem && this.selectedItem.model.isSame(model)) {
      this.updateSelectedContext();
    }

    const checkedModels: Model[] = values(this.checkedItems);

    if (checkedModels.some(item => item.isSame(model))) {
      this.updateSelectedContext();
    }
  }

  onResize() {
    const width = this.viewportElement ? this.viewportElement.nativeElement.offsetWidth : undefined;
    this.viewportWidth$.next(width);
  }

  get stepSize(): number {
    if (this.settings.step == CarouselStep.Row) {
      return this.rowCardsVisible;
    } else if (this.settings.step == CarouselStep.Custom) {
      return isSet(this.settings.stepCustom) ? Math.max(this.settings.stepCustom, 1) : 1;
    } else {
      return 1;
    }
  }

  public getAnyModel(): Model {
    if (!this.listStore.items || !this.listStore.items.length) {
      return;
    }

    return this.listStore.items[0].model;
  }

  checkSelectedModelIsActual() {
    const actualSelectedItem = this.items.find(item => item.model.isSame(this.selectedItem.model));

    if (
      actualSelectedItem &&
      !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())
    ) {
      this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
      this.cd.markForCheck();
      this.onModelUpdated(this.selectedItem.model);
    }
  }
}

import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var GuidePopupStoreService = /** @class */ (function () {
    function GuidePopupStoreService() {
        this._component = new BehaviorSubject(undefined);
    }
    Object.defineProperty(GuidePopupStoreService.prototype, "component", {
        get: function () {
            return this._component.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuidePopupStoreService.prototype, "component$", {
        get: function () {
            return this._component.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GuidePopupStoreService.prototype.registerGuidePopup = function (component) {
        this._component.next(component);
    };
    GuidePopupStoreService.prototype.unregisterGuidePopup = function (component) {
        if (this._component.value === component) {
            this._component.next(undefined);
        }
    };
    GuidePopupStoreService.ngInjectableDef = i0.defineInjectable({ factory: function GuidePopupStoreService_Factory() { return new GuidePopupStoreService(); }, token: GuidePopupStoreService, providedIn: "root" });
    return GuidePopupStoreService;
}());
export { GuidePopupStoreService };

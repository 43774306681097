import { Injectable } from '@angular/core';

import { addClass, removeClass } from '@shared';

export const DISABLE_WINDOW_BACK_SCROLL_CLASS = 'body_disable-window-back-scroll';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor() {}

  disableScroll(scrollable, container) {
    if (container.style.overflow == 'hidden') {
      return;
    }

    const scrollTop = scrollable.scrollTop;
    container.style.overflow = 'hidden';
    scrollable.scrollTop = scrollTop;
  }

  enableScroll(scrollable, container) {
    if (container.style.overflow != 'hidden') {
      return;
    }

    const scrollTop = scrollable.scrollTop;
    container.style.overflow = null;
    scrollable.scrollTop = scrollTop;
  }

  disableWindowScroll() {
    this.disableScroll(document.documentElement, document.body);
  }

  enableWindowScroll() {
    this.enableScroll(document.documentElement, document.body);
  }

  disableWindowBackScroll() {
    this.disableScroll(document.documentElement, document.body);
  }

  setWindowBackScrollEnabled(enabled: boolean) {
    if (enabled) {
      removeClass(document.body, DISABLE_WINDOW_BACK_SCROLL_CLASS);
    } else {
      addClass(document.body, DISABLE_WINDOW_BACK_SCROLL_CLASS);
    }
  }
}

import { FieldType, ParameterField } from '@modules/fields';
import { JET_APP_RESOURCE } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { JET_APP_GROUPS } from './jet-app-group.model-description';
import { ModelDbField } from './model-db-field';
import { ModelDescription, ModelField, ModelFieldType } from './model-description';
export var JET_APP_USERS = 'users';
export function getJetAppUserModelDescription(properties) {
    var result = new ModelDescription();
    result.resource = JET_APP_RESOURCE;
    result.model = JET_APP_USERS;
    result.verboseName = 'user';
    result.verboseNamePlural = 'users';
    result.displayField = 'email';
    result.primaryKeyField = 'uid';
    var groupsModelId = [JET_APP_RESOURCE, JET_APP_GROUPS].join('.');
    var fields = [
        {
            name: 'uid',
            verboseName: 'ID',
            field: FieldType.Text
        },
        {
            name: 'email',
            verboseName: 'email',
            field: FieldType.Text
        },
        {
            name: 'first_name',
            verboseName: 'first name',
            field: FieldType.Text
        },
        {
            name: 'last_name',
            verboseName: 'last name',
            field: FieldType.Text
        },
        {
            name: 'group',
            verboseName: 'team',
            field: FieldType.RelatedModel,
            params: {
                related_model: { model: groupsModelId }
            }
        },
        {
            name: 'date_add',
            verboseName: 'date added',
            field: FieldType.DateTime
        }
    ].concat(properties.map(function (item) {
        return {
            name: item.uid,
            verboseName: item.name,
            field: item.field ? item.field.field : FieldType.Text,
            params: item.field ? item.field.params : {}
        };
    }));
    result.fields = fields.map(function (item) {
        var field = new ModelField();
        field.name = item.name;
        field.type = ModelFieldType.Db;
        field.item = new ModelDbField();
        field.item.name = item.name;
        field.item.verboseName = item.verboseName;
        field.item.field = item.field;
        field.item.params = item.params || {};
        field.item.updateFieldDescription();
        return field;
    });
    result.getQuery = new ListModelDescriptionQuery();
    result.getQuery.queryType = QueryType.Simple;
    result.getQuery.simpleQuery = new result.getQuery.simpleQueryClass();
    result.getQuery.simpleQuery.model = result.model;
    // jetAppUsers.getParameters = this.getModelDescriptionGetParameters(jetAppUsers);
    result.getDetailQuery = new ModelDescriptionQuery();
    result.getDetailQuery.queryType = QueryType.Simple;
    result.getDetailQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.getDetailQuery.simpleQuery.model = result.model;
    // jetAppUsers.getDetailParameters = this.getModelDescriptionGetParameters(jetAppUsers);
    result.getDetailParametersUseDefaults = true;
    result.createQuery = new ModelDescriptionQuery();
    result.createQuery.queryType = QueryType.Simple;
    result.createQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.createQuery.simpleQuery.model = result.model;
    var propertiesNames = properties.map(function (item) { return item.uid; });
    result.createParameters = fields
        .filter(function (item) { return ['email', 'group'].concat(propertiesNames).includes(item.name); })
        .map(function (item) {
        var parameter = new ParameterField();
        parameter.name = item.name;
        parameter.verboseName = item.verboseName;
        parameter.field = item.field;
        parameter.params = item.params || {};
        parameter.required = !propertiesNames.includes(item.name);
        parameter.updateFieldDescription();
        return parameter;
    });
    result.updateQuery = new ModelDescriptionQuery();
    result.updateQuery.queryType = QueryType.Simple;
    result.updateQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.updateQuery.simpleQuery.model = result.model;
    result.updateParameters = fields
        .filter(function (item) { return ['uid', 'group'].concat(propertiesNames).includes(item.name); })
        .map(function (item) {
        var parameter = new ParameterField();
        parameter.name = item.name;
        parameter.verboseName = item.verboseName;
        parameter.field = item.field;
        parameter.params = item.params || {};
        parameter.required = item.name == 'uid';
        parameter.updateFieldDescription();
        return parameter;
    });
    result.deleteQuery = new ModelDescriptionQuery();
    result.deleteQuery.queryType = QueryType.Simple;
    result.deleteQuery.simpleQuery = new result.getDetailQuery.simpleQueryClass();
    result.deleteQuery.simpleQuery.model = result.model;
    result.deleteParameters = fields
        .filter(function (item) { return ['uid'].includes(item.name); })
        .map(function (item) {
        var parameter = new ParameterField();
        parameter.name = item.name;
        parameter.verboseName = item.verboseName;
        parameter.field = item.field;
        parameter.params = item.params || {};
        parameter.required = true;
        parameter.updateFieldDescription();
        return parameter;
    });
    return result;
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import 'brace/mode/sql';
import 'brace/snippets/sql';
import 'brace/snippets/text';
import flatten from 'lodash/flatten';
import { AceEditorDirective } from 'ng2-ace-editor';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelDescriptionStore, ModelService, ReducedModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ModelResponse } from '@modules/resources';
import { ThemeService } from '@modules/theme';
import { TokenStructureItem } from '@modules/tokens';

import { FieldComponent } from '../field/field.component';
import './theme-dark';
import './theme-default';
import './theme-default-contrast';

@Component({
  selector: 'app-sql-field',
  templateUrl: 'sql-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SqlFieldComponent extends FieldComponent implements OnDestroy, OnChanges, AfterViewInit, OnInit {
  @ViewChild(AceEditorDirective) ace: AceEditorDirective;

  resource: string;
  tables: TokenStructureItem[];
  sqlResult: ModelResponse.SqlResponse;
  sqlError: string;

  constructor(
    protected modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    private reducedModelService: ReducedModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef,
    private themeService: ThemeService
  ) {
    super();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'] || changes['value']) {
      // this.executeSql();
    }
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.resource = this.field.params['resource'];

    if (this.field.editable) {
      this.updateTables();
      this.addCompleter();
      // this.executeSql();
    }
  }

  get theme() {
    return this.themeService.theme == 'dark' ? 'dracula' : 'xcode';
  }

  addCompleter() {
    const completer = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        this.modelDescriptionStore
          .getFirst()
          .pipe(untilDestroyed(this))
          .subscribe(result => {
            if (this.resource) {
              result = result.filter(model => model.resource == this.resource);
            }

            const modelDbFields = result
              .filter(item => item.dbTable)
              .map(model => [
                {
                  value: model.dbTable,
                  score: 2,
                  meta: 'table',
                  docHTML: [
                    `<b>${model.verboseNamePlural}</b>`,
                    '<hr>',
                    ...model.dbFields.slice(0, 10).map(field => `${field.dbColumn}: <i>${field.field}</i><br>`),
                    model.fields.length > 10 ? '...' : ''
                  ].join('')
                },
                ...model.dbFields.map(field => {
                  return {
                    caption: `${field.dbColumn} (${model.dbTable})`,
                    value: field.dbColumn,
                    score: 2,
                    meta: 'column',
                    docHTML: [
                      `<b>${field.verboseName}</b>`,
                      '<hr>',
                      `Type: <i>${field.field}</i><br>`,
                      `Table: ${model.dbTable} (${model.verboseNamePlural})`
                    ].join('')
                  };
                }),
                ...model.dbFields.map(field => {
                  return {
                    caption: `${model.dbTable}.${field.dbColumn}`,
                    value: `${model.dbTable}.${field.dbColumn}`,
                    score: 1,
                    meta: 'column',
                    docHTML: [
                      `<b>${field.verboseName}</b>`,
                      '<hr>',
                      `Type: <i>${field.field}</i><br>`,
                      `Table: ${model.dbTable} (${model.verboseNamePlural})`
                    ].join('')
                  };
                })
              ]);

            callback(null, flatten(modelDbFields));
          });
      }
    };

    this.ace.editor.completers = [...(this.ace.editor.completers || []), completer];
  }

  updateTables() {
    this.modelDescriptionStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (this.resource) {
          result = result.filter(model => model.resource == this.resource);
        }

        this.tables = result
          .filter(item => item.dbTable)
          .sort((lhs, rhs) => {
            return lhs.dbTable < rhs.dbTable ? -1 : lhs.dbTable < rhs.dbTable ? 0 : 1;
          })
          .map(item => {
            return {
              name: item.dbTable,
              label: item.verboseNamePlural,
              insert: item.dbTable,
              children: item.dbFields.map(field => {
                return {
                  name: field.dbColumn,
                  label: field.verboseName,
                  insert: field.dbColumn,
                  children: []
                };
              })
            };
          });
        this.cd.markForCheck();
      });
  }

  editorInsert(text) {
    this.ace.editor.session.replace(this.ace.editor.selection.getRange(), text);
  }

  executeSql() {
    const sql = this.currentValue;

    this.sqlResult = undefined;
    this.sqlError = undefined;
    this.cd.markForCheck();

    if (!sql) {
      return;
    }

    // TODO: get resource from context
    const resource = this.currentEnvironmentStore.resources.find(item => item.type == ResourceType.JetBridge);
    this.modelService
      .sql(resource, sql)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.sqlResult = result;
          this.cd.markForCheck();
        },
        error => {
          if (error.fieldErrors['error']) {
            this.sqlError = error.fieldErrors['error'];
          } else {
            this.sqlError = 'Unknown error';
          }

          this.cd.markForCheck();
        }
      );
  }
}

registerFieldComponent(FieldType.Sql, SqlFieldComponent);

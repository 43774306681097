var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { PopupRef } from '@common/popups';
import { cropFormats } from '@modules/field-components/components/image-field/image-field.crop';
import { isSet } from '@shared';
var CornerPosition;
(function (CornerPosition) {
    CornerPosition[CornerPosition["TopLeft"] = 0] = "TopLeft";
    CornerPosition[CornerPosition["TopRight"] = 1] = "TopRight";
    CornerPosition[CornerPosition["BottomLeft"] = 2] = "BottomLeft";
    CornerPosition[CornerPosition["BottomRight"] = 3] = "BottomRight";
})(CornerPosition || (CornerPosition = {}));
var ImageEditorComponent = /** @class */ (function () {
    function ImageEditorComponent(popupRef, cd) {
        this.popupRef = popupRef;
        this.cd = cd;
        this.changed = new EventEmitter();
        this.corners = new QueryList();
        this.loading = true;
        this.position = {
            x: 0,
            y: 0,
            scale: 0.25,
            rotate: 0
        };
        this.boundsPosition = {
            x: 0,
            y: 0,
            width: 300,
            height: 400,
            mainSide: 'width'
        };
        this.commonScales = [0.01, 0.05, 0.1, 0.25, 0.5, 1.0, 2.0];
        this.dragging = false;
        this.boundsDragging = false;
    }
    ImageEditorComponent.prototype.ngOnInit = function () { };
    ImageEditorComponent.prototype.ngOnDestroy = function () { };
    ImageEditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.img = new Image();
        this.img.onload = function () {
            _this.width = _this.img.width;
            _this.height = _this.img.height;
            _this.loading = false;
            _this.cd.detectChanges();
            _this.scaleFit(false);
            _this.initPositionBounds();
            var ctx = _this.canvas.nativeElement.getContext('2d');
            ctx.drawImage(_this.img, 0, 0, _this.width, _this.height);
            _this.initViewportDragging();
            _this.initBoundsDragging();
            _this.initBoundsCornersDragging();
        };
        this.img.src = URL.createObjectURL(this.file);
    };
    ImageEditorComponent.prototype.initPositionBounds = function () {
        var width = this.imageWidth;
        var height = this.imageHeight;
        if (this.onCropFormatter()) {
            var proportion = this.proportion;
            var newWidth = height * proportion;
            if (newWidth <= width) {
                width = newWidth;
            }
            else {
                height = width / proportion;
            }
        }
        this.setBoundsPosition({
            x: this.position.x,
            y: this.position.y,
            width: width,
            height: height
        });
    };
    ImageEditorComponent.prototype.getImageTransform = function () {
        var offsetXMultiplier = (this.boundsPosition.x - this.boundsPosition.width / 2 - (this.position.x - this.imageWidth / 2)) /
            this.imageWidth;
        var offsetYMultiplier = (this.boundsPosition.y - this.boundsPosition.height / 2 - (this.position.y - this.imageHeight / 2)) /
            this.imageHeight;
        var widthMultiplier = this.boundsPosition.width / this.imageWidth;
        var heightMultiplier = this.boundsPosition.height / this.imageHeight;
        var offsetX, offsetY, width, height;
        if ((Math.abs(this.position.rotate) / 90) % 4 == 0) {
            offsetX = this.width * offsetXMultiplier;
            offsetY = this.height * offsetYMultiplier;
            width = this.width * widthMultiplier;
            height = this.height * heightMultiplier;
        }
        else if ((Math.abs(this.position.rotate) / 90) % 4 == 1) {
            offsetX = this.width * offsetYMultiplier;
            offsetY = this.height * (1 - (offsetXMultiplier + widthMultiplier));
            width = this.width * heightMultiplier;
            height = this.height * widthMultiplier;
        }
        else if ((Math.abs(this.position.rotate) / 90) % 4 == 2) {
            offsetX = this.width * (1 - (offsetXMultiplier + widthMultiplier));
            offsetY = this.height * (1 - (offsetYMultiplier + heightMultiplier));
            width = this.width * widthMultiplier;
            height = this.height * heightMultiplier;
        }
        else if ((Math.abs(this.position.rotate) / 90) % 4 == 3) {
            offsetX = this.width * (1 - (offsetYMultiplier + heightMultiplier));
            offsetY = this.height * offsetXMultiplier;
            width = this.width * heightMultiplier;
            height = this.height * widthMultiplier;
        }
        return { offsetX: offsetX, offsetY: offsetY, width: width, height: height };
    };
    ImageEditorComponent.prototype.updatePreview = function () {
        var _a = this.getImageTransform(), offsetX = _a.offsetX, offsetY = _a.offsetY, width = _a.width, height = _a.height;
        var canvasWidth, canvasHeight;
        if (this.landscape) {
            canvasWidth = height;
            canvasHeight = width;
        }
        else {
            canvasWidth = width;
            canvasHeight = height;
        }
        var widthHeightRation = canvasWidth / canvasHeight;
        var previewWidth, previewHeight;
        if (widthHeightRation >= 1) {
            previewWidth = 200;
            previewHeight = 200 * (1 / widthHeightRation);
        }
        else {
            previewWidth = 200 * widthHeightRation;
            previewHeight = 200;
        }
        TweenMax.set(this.previewCanvas.nativeElement, {
            attr: {
                width: canvasWidth,
                height: canvasHeight
            },
            scale: previewWidth / canvasWidth
        });
        TweenMax.set(this.previewInner.nativeElement, {
            width: previewWidth,
            height: previewHeight
        });
        var canvas = this.previewCanvas.nativeElement;
        var ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(((Math.PI / 2) * this.position.rotate) / 90);
        ctx.drawImage(this.img, offsetX, offsetY, width, height, this.landscape ? 0 - canvas.height / 2 : 0 - canvas.width / 2, this.landscape ? 0 - canvas.width / 2 : 0 - canvas.height / 2, width, height);
    };
    ImageEditorComponent.prototype.initViewportDragging = function () {
        var _this = this;
        fromEvent([this.viewport.nativeElement, this.preview.nativeElement], 'mousedown')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.dragging = true;
            _this.dragPosition = {
                x: e.clientX,
                y: e.clientY
            };
        });
        fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (!_this.dragging) {
                return;
            }
            var deltaX = e.clientX - _this.dragPosition.x;
            var deltaY = e.clientY - _this.dragPosition.y;
            _this.setPosition({
                x: _this.position.x + deltaX,
                y: _this.position.y + deltaY
            });
            _this.setBoundsPosition({
                x: _this.boundsPosition.x + deltaX,
                y: _this.boundsPosition.y + deltaY
            });
            _this.dragPosition = {
                x: e.clientX,
                y: e.clientY
            };
        });
        fromEvent(window.document, 'mouseup')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (!_this.dragging) {
                return;
            }
            _this.dragging = false;
        });
    };
    ImageEditorComponent.prototype.initBoundsDragging = function () {
        var _this = this;
        fromEvent(this.bounds.nativeElement, 'mousedown')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            e.preventDefault();
            e.stopPropagation();
            _this.boundsDragging = true;
            _this.boundsDragPosition = {
                x: e.clientX,
                y: e.clientY
            };
        });
        fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (!_this.boundsDragging) {
                return;
            }
            var deltaX = e.clientX - _this.boundsDragPosition.x;
            var deltaY = e.clientY - _this.boundsDragPosition.y;
            _this.setBoundsPosition({
                x: _this.boundsPosition.x + deltaX,
                y: _this.boundsPosition.y + deltaY
            });
            _this.boundsDragPosition = {
                x: e.clientX,
                y: e.clientY
            };
        });
        fromEvent(window.document, 'mouseup')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (!_this.boundsDragging) {
                return;
            }
            _this.boundsDragging = false;
        });
    };
    ImageEditorComponent.prototype.initBoundsCornersDragging = function () {
        var _this = this;
        this.corners.forEach(function (item, i) {
            return fromEvent(item.nativeElement, 'mousedown')
                .pipe(untilDestroyed(_this))
                .subscribe(function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.cornerDragging = i;
                _this.cornerDragPosition = {
                    x: e.clientX,
                    y: e.clientY
                };
            });
        });
        fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (_this.cornerDragging == undefined) {
                return;
            }
            var deltaX = e.clientX - _this.cornerDragPosition.x;
            var deltaY = e.clientY - _this.cornerDragPosition.y;
            if (!_this.onCropFormatter()) {
                _this.updatePositionBoundsDefault(deltaX, deltaY);
            }
            else {
                _this.updatePositionBoundsCrop(deltaX, deltaY);
            }
            _this.cornerDragPosition = {
                x: e.clientX,
                y: e.clientY
            };
        });
        fromEvent(window.document, 'mouseup')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (_this.cornerDragging == undefined) {
                return;
            }
            _this.cornerDragging = undefined;
        });
    };
    ImageEditorComponent.prototype.onCropFormatter = function () {
        if (this.cropFormat === cropFormats.Custom) {
            return isSet(this.cropFormatCustom);
        }
        else {
            return isSet(this.cropFormat);
        }
    };
    ImageEditorComponent.prototype.scale = function (position, animate) {
        if (animate === void 0) { animate = true; }
        var deltaX = (this.boundsPosition.x - this.position.x) / this.imageWidth;
        var deltaY = (this.boundsPosition.y - this.position.y) / this.imageHeight;
        var deltaWidth = this.boundsPosition.width / this.imageWidth;
        var deltaHeight = this.boundsPosition.height / this.imageHeight;
        this.setPosition(position, animate);
        var x = this.position.x + this.imageWidth * deltaX;
        var y = this.position.y + this.imageHeight * deltaY;
        var width = this.imageWidth * deltaWidth;
        var height = this.imageHeight * deltaHeight;
        this.setBoundsPosition({
            x: x,
            y: y,
            width: width,
            height: height
        }, animate);
    };
    ImageEditorComponent.prototype.scaleUp = function () {
        var _this = this;
        var newScale = this.position.scale * 2;
        var commonScale = this.commonScales.find(function (item) { return _this.position.scale < item && newScale > item; });
        if (commonScale != undefined) {
            newScale = commonScale;
        }
        this.scale({ scale: newScale });
    };
    ImageEditorComponent.prototype.scaleDown = function () {
        var _this = this;
        var newScale = this.position.scale / 2;
        var commonScale = this.commonScales.find(function (item) { return _this.position.scale > item && newScale < item; });
        if (commonScale != undefined) {
            newScale = commonScale;
        }
        this.scale({ scale: newScale });
    };
    ImageEditorComponent.prototype.scaleFit = function (animate) {
        if (animate === void 0) { animate = true; }
        var padding = 80;
        var scaleX = (this.viewport.nativeElement.offsetWidth / 2 - padding) / (this.width / 2);
        var scaleY = (this.viewport.nativeElement.offsetHeight / 2 - padding) / (this.height / 2);
        this.scale({
            x: this.viewport.nativeElement.offsetWidth / 2,
            y: this.viewport.nativeElement.offsetHeight / 2,
            scale: Math.min(scaleX, scaleY)
        }, animate);
    };
    ImageEditorComponent.prototype.rotate = function (clockwise) {
        var clockwiseMultiplier = clockwise ? 1 : -1;
        var rotate = this.position.rotate + 90 * clockwiseMultiplier;
        this.setPosition({ rotate: rotate }, true);
        this.setBoundsPosition({
            x: (this.boundsPosition.y - this.position.y) * -1 * clockwiseMultiplier + this.position.x,
            y: (this.boundsPosition.x - this.position.x) * clockwiseMultiplier + this.position.y,
            width: this.boundsPosition.height,
            height: this.boundsPosition.width
        }, true);
    };
    ImageEditorComponent.prototype.setPosition = function (position, animate) {
        if (animate === void 0) { animate = false; }
        this.position = __assign({}, this.position, position);
        TweenMax.to(this.image.nativeElement, animate ? 0.4 : 0, {
            x: this.position.x,
            y: this.position.y,
            xPercent: -50,
            yPercent: -50,
            rotation: this.position.rotate,
            scale: this.position.scale,
            ease: animate ? Power2.easeInOut : undefined
        });
        this.updatePreview();
    };
    ImageEditorComponent.prototype.setBoundsPosition = function (position, animate) {
        if (animate === void 0) { animate = false; }
        this.boundsPosition = __assign({}, this.boundsPosition, position);
        this.validateBoundsPosition();
        TweenMax.to(this.bounds.nativeElement, animate ? 0.4 : 0, {
            x: this.boundsPosition.x,
            y: this.boundsPosition.y,
            width: this.boundsPosition.width,
            height: this.boundsPosition.height,
            xPercent: -50,
            yPercent: -50,
            ease: animate ? Power2.easeInOut : undefined
        });
        this.updatePreview();
    };
    Object.defineProperty(ImageEditorComponent.prototype, "landscape", {
        get: function () {
            return (Math.abs(this.position.rotate) / 90) % 2 == 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageEditorComponent.prototype, "imageWidth", {
        get: function () {
            if (this.landscape) {
                return this.height * this.position.scale;
            }
            else {
                return this.width * this.position.scale;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageEditorComponent.prototype, "imageHeight", {
        get: function () {
            if (this.landscape) {
                return this.width * this.position.scale;
            }
            else {
                return this.height * this.position.scale;
            }
        },
        enumerable: true,
        configurable: true
    });
    ImageEditorComponent.prototype.validateBoundsPosition = function () {
        // if (this.boundsPosition.width > this.imageWidth) {
        //   this.boundsPosition.width = this.imageWidth;
        // }
        // if (this.boundsPosition.height > this.imageHeight) {
        //   this.boundsPosition.height = this.imageHeight;
        // }
        var boundsRightX = this.boundsPosition.x + this.boundsPosition.width / 2;
        var rightX = this.position.x + this.imageWidth / 2;
        if (boundsRightX > rightX) {
            this.boundsPosition.x = rightX - this.boundsPosition.width / 2;
        }
        var boundsLeftX = this.boundsPosition.x - this.boundsPosition.width / 2;
        var leftX = this.position.x - this.imageWidth / 2;
        if (boundsLeftX < leftX) {
            this.boundsPosition.x = leftX + this.boundsPosition.width / 2;
        }
        var boundsBottomY = this.boundsPosition.y + this.boundsPosition.height / 2;
        var bottomY = this.position.y + this.imageHeight / 2;
        if (boundsBottomY > bottomY) {
            this.boundsPosition.y = bottomY - this.boundsPosition.height / 2;
        }
        var boundsTopY = this.boundsPosition.y - this.boundsPosition.height / 2;
        var topY = this.position.y - this.imageHeight / 2;
        if (boundsTopY < topY) {
            this.boundsPosition.y = topY + this.boundsPosition.height / 2;
        }
    };
    ImageEditorComponent.prototype.validateRightX = function (position) {
        var boundsRightX = position.x + position.width / 2;
        var rightX = this.position.x + this.imageWidth / 2;
        return boundsRightX <= rightX;
    };
    ImageEditorComponent.prototype.validateLeftX = function (position) {
        var boundsLeftX = position.x - position.width / 2;
        var leftX = this.position.x - this.imageWidth / 2;
        return boundsLeftX >= leftX;
    };
    ImageEditorComponent.prototype.validateBottomY = function (position) {
        var boundsBottomY = position.y + position.height / 2;
        var bottomY = this.position.y + this.imageHeight / 2;
        return boundsBottomY <= bottomY;
    };
    ImageEditorComponent.prototype.validateTopY = function (position) {
        var boundsTopY = position.y - position.height / 2;
        var topY = this.position.y - this.imageHeight / 2;
        return boundsTopY >= topY;
    };
    ImageEditorComponent.prototype.close = function () {
        if (this.popupRef) {
            this.popupRef.close();
        }
    };
    ImageEditorComponent.prototype.cancel = function () {
        this.close();
    };
    ImageEditorComponent.prototype.isImageTransformOriginal = function () {
        var transform = this.getImageTransform();
        return (transform.offsetX == 0 &&
            transform.offsetY == 0 &&
            transform.width == this.width &&
            transform.height == this.height);
    };
    ImageEditorComponent.prototype.save = function () {
        this.close();
        if (this.isImageTransformOriginal()) {
            this.changed.emit(this.file);
        }
        else {
            this.changed.emit(this.toFile());
        }
    };
    ImageEditorComponent.prototype.toFile = function () {
        var dataURL = this.previewCanvas.nativeElement.toDataURL(this.file.type);
        var blobBin = atob(dataURL.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        return new File([new Uint8Array(array)], this.file.name, { type: this.file.type });
    };
    ImageEditorComponent.prototype.multiply = function (size) {
        if (!size) {
            return 0;
        }
        return size * this.proportion;
    };
    ImageEditorComponent.prototype.divid = function (size) {
        if (!size) {
            return 0;
        }
        return size / this.proportion;
    };
    Object.defineProperty(ImageEditorComponent.prototype, "proportion", {
        get: function () {
            if (this.cropFormat === cropFormats.Square) {
                return 1;
            }
            else if (this.cropFormat === cropFormats.Original) {
                return this.width / this.height;
            }
            else if (this.cropFormat === cropFormats.Format_16x9) {
                return 16 / 9;
            }
            else if (this.cropFormat === cropFormats.Format_3x2) {
                return 3 / 2;
            }
            else if (this.cropFormat === cropFormats.Format_4x3) {
                return 4 / 2;
            }
            else if (this.cropFormat === cropFormats.Format_5x4) {
                return 5 / 4;
            }
            else if (this.cropFormat === cropFormats.Format_7x5) {
                return 7 / 5;
            }
            else if (this.cropFormat === cropFormats.Custom) {
                var _a = this.cropFormatCustom.split(':'), a = _a[0], b = _a[1];
                return Number(a) / Number(b);
            }
        },
        enumerable: true,
        configurable: true
    });
    ImageEditorComponent.prototype.updatePositionBoundsDefault = function (deltaX, deltaY) {
        var position;
        if (this.cornerDragging == CornerPosition.TopLeft) {
            position = {
                x: this.boundsPosition.x + deltaX / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.boundsPosition.width - deltaX,
                height: this.boundsPosition.height - deltaY
            };
            if (this.validateTopY(position) && this.validateLeftX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.TopRight) {
            position = {
                x: this.boundsPosition.x + deltaX / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.boundsPosition.width + deltaX,
                height: this.boundsPosition.height - deltaY
            };
            if (this.validateTopY(position) && this.validateRightX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.BottomLeft) {
            position = {
                x: this.boundsPosition.x + deltaX / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.boundsPosition.width - deltaX,
                height: this.boundsPosition.height + deltaY
            };
            if (this.validateBottomY(position) && this.validateLeftX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.BottomRight) {
            position = {
                x: this.boundsPosition.x + deltaX / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.boundsPosition.width + deltaX,
                height: this.boundsPosition.height + deltaY
            };
            if (this.validateRightX(position) && this.validateBottomY(position)) {
                this.setBoundsPosition(position);
            }
        }
    };
    ImageEditorComponent.prototype.updatePositionBoundsCrop = function (deltaX, deltaY) {
        var position;
        if (this.cornerDragging == CornerPosition.TopLeft) {
            position = {
                x: this.boundsPosition.x + this.multiply(deltaY) / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.multiply(this.boundsPosition.height - deltaY),
                height: this.boundsPosition.height - deltaY
            };
            if (this.validateTopY(position) && this.validateLeftX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.TopRight) {
            position = {
                x: this.boundsPosition.x + -this.multiply(deltaY) / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.multiply(this.boundsPosition.height - deltaY),
                height: this.boundsPosition.height - deltaY
            };
            if (this.validateTopY(position) && this.validateRightX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.BottomLeft) {
            position = {
                x: this.boundsPosition.x + -this.multiply(deltaY) / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.multiply(this.boundsPosition.height + deltaY),
                height: this.boundsPosition.height + deltaY
            };
            if (this.validateBottomY(position) && this.validateLeftX(position)) {
                this.setBoundsPosition(position);
            }
        }
        else if (this.cornerDragging == CornerPosition.BottomRight) {
            position = {
                x: this.boundsPosition.x + this.multiply(deltaY) / 2,
                y: this.boundsPosition.y + deltaY / 2,
                width: this.multiply(this.boundsPosition.height + deltaY),
                height: this.boundsPosition.height + deltaY
            };
            if (this.validateRightX(position) && this.validateBottomY(position)) {
                this.setBoundsPosition(position);
            }
        }
    };
    return ImageEditorComponent;
}());
export { ImageEditorComponent };

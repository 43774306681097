import { FormControl, FormGroup } from '@angular/forms';

import { isSet } from '@shared';

export interface OptionEnabledControlValue {
  name?: string;
  enabled?: boolean;
}

export class OptionEnabledControl extends FormGroup {
  controls: {
    name: FormControl;
    enabled: FormControl;
  };

  readonly value: OptionEnabledControlValue;

  constructor(value: OptionEnabledControlValue = {}) {
    super({
      name: new FormControl(isSet(value.name) ? value.name : undefined),
      enabled: new FormControl(isSet(value.enabled) ? value.enabled : false)
    });
  }

  patchValue(value: OptionEnabledControlValue, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }

  setValue(value: OptionEnabledControlValue, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { EMPTY, isSet, parseNumber } from '@shared';

import { Input } from '../data/input';
import { contextInputValueTypes } from '../data/input-value-type';
import { ValidatorType } from '../data/validator-type';
import { isInputSet } from './common';
import { applyParamInput } from './inputs';

function getRegexValidator(regex: string, errorMessage: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!isSet(control.value)) {
      return null;
    }

    try {
      if (!new RegExp(regex).test(control.value)) {
        return { local: [errorMessage] };
      }
    } catch (e) {
      return { local: ['Incorrect regular expression'] };
    }

    return null;
  };
}

function getInputValidator(
  input: Input,
  errorMessage: string,
  options: { context?: ViewContext; contextElement?: ViewContextElement } = {}
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!isSet(control.value)) {
      return null;
    }

    if (!input || !input.isSet()) {
      return null;
    }

    try {
      const result = applyParamInput(input, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: {
          value: control.value
        }
      });

      if (result === EMPTY) {
        return null;
      }

      if (result === '0' || result === 'false') {
        return null;
      }

      if (!result) {
        return { local: [errorMessage] };
      }

      return null;
    } catch (e) {
      return null;
    }
  };
}

function getNumberValidator(isValid: (num: number) => boolean, errorMessage: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!isSet(control.value)) {
      return null;
    }

    const num = parseNumber(control.value);

    if (!isValid(num)) {
      return { local: [errorMessage] };
    }

    return null;
  };
}

export function getEmailValidator(): ValidatorFn {
  return getRegexValidator(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
    'Incorrect email address'
  );
}

export function getPhoneValidator(): ValidatorFn {
  return getRegexValidator('^[\\s()+-]*([0-9][\\s()+-]*){6,20}$', 'Incorrect phone number');
}

export function getExactLengthValidator(length: number): ValidatorFn {
  return getRegexValidator(`^[\\s\\S]{${length},${length}}$`, `Length is not equal ${length}`);
}

export function getMinLengthValidator(length: number): ValidatorFn {
  return getRegexValidator(`^[\\s\\S]{${length},}$`, `Length is less than ${length}`);
}

export function getMaxLengthValidator(length: number): ValidatorFn {
  return getRegexValidator(`^[\\s\\S]{0,${length}}$`, `Length is greater than ${length}`);
}

export function getValidatorByType(
  type: ValidatorType,
  params: Object,
  options: { context?: ViewContext; contextElement?: ViewContextElement } = {}
): { func?: ValidatorFn; contextDependent?: boolean } {
  if (!type) {
    return {};
  }

  params = params || {};

  if (type === ValidatorType.Custom) {
    const regex = params['regex'];
    const error = params['error'] || 'Is not valid';

    if (isSet(regex)) {
      return { func: getRegexValidator(regex, error) };
    }
  } else if (type === ValidatorType.CustomInput) {
    const input = params['input'] ? new Input().deserialize(params['input']) : undefined;
    const error = params['error'] || 'Is not valid';

    if (isSet(input)) {
      return {
        func: getInputValidator(input, error, {
          context: options.context,
          contextElement: options.contextElement
        }),
        contextDependent: contextInputValueTypes.includes(input.valueType)
      };
    }
  } else if (type === ValidatorType.Email) {
    return { func: getEmailValidator() };
  } else if (type === ValidatorType.Phone) {
    return { func: getPhoneValidator() };
  } else if (type === ValidatorType.Length) {
    const length = parseNumber(params['validator_length']);

    if (length !== null) {
      return { func: getExactLengthValidator(length) };
    }
  } else if (type === ValidatorType.MaxLength) {
    const maxLength = parseNumber(params['validator_max_length']);

    if (maxLength !== null) {
      return { func: getMaxLengthValidator(maxLength) };
    }
  } else if (type === ValidatorType.MinLength) {
    const minLength = parseNumber(params['validator_min_length']);

    if (minLength !== null) {
      return { func: getMinLengthValidator(minLength) };
    }
  } else if (type === ValidatorType.LengthRange) {
    const minLength = parseNumber(params['validator_min_length']);
    const maxLength = parseNumber(params['validator_max_length']);

    if (minLength !== null && maxLength !== null) {
      return {
        func: getRegexValidator(
          `^[\\s\\S]{${minLength},${maxLength}}$`,
          `Length should be between ${minLength} and ${maxLength}`
        )
      };
    } else if (minLength !== null) {
      return { func: getMinLengthValidator(minLength) };
    } else if (maxLength !== null) {
      return { func: getMaxLengthValidator(maxLength) };
    }
  } else if (type === ValidatorType.ValueRange) {
    const minValue = parseNumber(params['validator_min_value']);
    const maxValue = parseNumber(params['validator_max_value']);

    if (minValue !== null && maxValue !== null) {
      return {
        func: getNumberValidator(
          value => value >= minValue && value <= maxValue,
          `Value should be between ${minValue} and ${maxValue}`
        )
      };
    } else if (minValue !== null) {
      return { func: getNumberValidator(value => value >= minValue, `Value is less than ${minValue}`) };
    } else if (maxValue !== null) {
      return { func: getNumberValidator(value => value <= maxValue, `Value is greater than ${maxValue}`) };
    }
  }

  return {};
}

export function validateValidatorParams(validatorType: ValidatorType, params: Object): boolean {
  params = params || {};

  if (validatorType === ValidatorType.Custom) {
    return isSet(params['regex']);
  } else if (validatorType === ValidatorType.CustomInput) {
    return isSet(params['input']) && isInputSet(params['input']);
  } else if (validatorType === ValidatorType.Length) {
    return isSet(params['validator_length']);
  } else if (validatorType === ValidatorType.MaxLength) {
    return isSet(params['validator_max_length']);
  } else if (validatorType === ValidatorType.MinLength) {
    return isSet(params['validator_min_length']);
  } else if (validatorType === ValidatorType.LengthRange) {
    return isSet(params['validator_min_length']) || isSet(params['validator_max_length']);
  } else if (validatorType === ValidatorType.ValueRange) {
    return isSet(params['validator_min_value']) || isSet(params['validator_max_value']);
  } else {
    return true;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { ProjectProperty } from '../../data/project-property';

@Injectable({
  providedIn: 'root'
})
export class ProjectPropertyService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}, draft = false): Observable<ProjectProperty[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({
          fromObject: {
            ...(draft && { draft: '1' }),
            ...params
          }
          // encoder: this.parameterCodec
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new ProjectProperty().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    instances: ProjectProperty[]
  ): Observable<ProjectProperty[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new ProjectProperty().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: ProjectProperty): Observable<ProjectProperty> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instance.serialize();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers, params: params });
      }),
      map(result => new ProjectProperty().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
    // return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
  }

  update(
    projectName: string,
    environmentName: string,
    instance: ProjectProperty,
    fields?: string[]
  ): Observable<ProjectProperty> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `properties/${instance.uid}/`);
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instance.serialize(fields);

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers, params: params });
      }),
      map(result => new ProjectProperty().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
    // return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
  }

  delete(projectName: string, environmentName: string, instance: ProjectProperty): Observable<boolean> {
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `properties/${instance.uid}`);
    //     let headers = new HttpHeaders();
    //     const params = {
    //       draft: '1'
    //     };
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers, params: params });
    //   }),
    //   map(() => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
  }
}

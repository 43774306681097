export class ProjectJob {
  public uniqueName: string;
  public name: string;
  public icon: string;
  public color: string;
  public url: string;
  public ordering: number;

  deserialize(data: Object): ProjectJob {
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.icon = data['icon'];
    this.color = data['color'];
    this.url = data['url'];
    this.ordering = data['ordering'];

    return this;
  }
}

import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../users/stores/current-user.store";
var RewardfulService = /** @class */ (function () {
    function RewardfulService(currentUserStore) {
        var _this = this;
        this.currentUserStore = currentUserStore;
        this._loaded = new BehaviorSubject(false);
        var interval = setInterval(function () {
            if (!_this.rewardful) {
                return;
            }
            _this._loaded.next(true);
            clearInterval(interval);
        }, 1000);
        this.loaded$
            .pipe(switchMap(function () { return _this.currentUserStore.get(); }), filter(function (user) { return !!user; }), switchMap(function (user) {
            var referral = _this.getReferral();
            if (isSet(referral) && user.params.rewardful != referral) {
                user.params.rewardful = referral;
                return _this.currentUserStore.update(['params']);
            }
            else {
                return of(undefined);
            }
        }), catchError(function () { return of(undefined); }))
            .subscribe();
    }
    Object.defineProperty(RewardfulService.prototype, "rewardful", {
        get: function () {
            return window['Rewardful'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RewardfulService.prototype, "loaded$", {
        get: function () {
            return this._loaded.pipe(filter(function (item) { return item == true; }));
        },
        enumerable: true,
        configurable: true
    });
    RewardfulService.prototype.getReferral = function () {
        if (!this.rewardful) {
            return;
        }
        return this.rewardful.referral;
    };
    RewardfulService.ngInjectableDef = i0.defineInjectable({ factory: function RewardfulService_Factory() { return new RewardfulService(i0.inject(i1.CurrentUserStore)); }, token: RewardfulService, providedIn: "root" });
    return RewardfulService;
}());
export { RewardfulService };

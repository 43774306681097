import { ViewContainerRef } from '@angular/core';
import * as i0 from "@angular/core";
var FixedService = /** @class */ (function () {
    function FixedService() {
    }
    FixedService.prototype.registerContainer = function (vcr) {
        this.vcr = vcr;
    };
    Object.defineProperty(FixedService.prototype, "container", {
        get: function () {
            return this.vcr;
        },
        enumerable: true,
        configurable: true
    });
    FixedService.ngInjectableDef = i0.defineInjectable({ factory: function FixedService_Factory() { return new FixedService(); }, token: FixedService, providedIn: "root" });
    return FixedService;
}());
export { FixedService };

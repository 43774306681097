<app-field-wrapper
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
>
  <input
    class="input input_stub"
    [class.input_fill]="true"
    [class.loading-animation]="true"
    type="text"
    value="Value"
    [disabled]="true"
  />
</app-field-wrapper>

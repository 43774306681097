<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-section [disabled]="!workflowEditable">
    <div class="sidebar__list">
      <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDropItem($event)">
        <div
          *ngFor="let stepItemControl of form.controls.items.controls; let i = index"
          cdkDrag
          class="sidebar-list__item"
        >
          <div class="sidebar-list-item sidebar-list-item_draggable">
            <div class="sidebar-list-item__drag"></div>
            <!--                <div *ngIf="hasIcon" class="sidebar-list-item__left">-->
            <!--                  <span class="button button_icon button_orange button_extra-small" style="margin: 0;">-->
            <!--                    <span *ngIf="displayIcon" class="icon button__icon" [ngClass]="['icon-' + displayIcon]"></span>-->
            <!--                  </span>-->
            <!--                </div>-->

            <!--                <div *ngIf="loading" class="sidebar-list-item__main">-->
            <!--                  <div class="sidebar-list-item__title">-->
            <!--      <span [class.loading-animation]="true">-->
            <!--        <span class="stub-text">Action</span>-->
            <!--      </span>-->
            <!--                  </div>-->
            <!--                  <div class="sidebar-list-item__description">-->
            <!--      <span [class.loading-animation]="true">-->
            <!--        <span class="stub-text">Action Type</span>-->
            <!--      </span>-->
            <!--                  </div>-->
            <!--                </div>-->

            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">Branch {{ i + 1 }}</div>
              <div class="sidebar-list-item__description">
                {{ stepItemControl.instance?.steps.length || 0 | i18nPlural: { '=1': '# step', other: '# steps' } }}
              </div>
            </div>

            <div class="sidebar-list-item__right">
              <a
                href="javascript:void(0)"
                class="sidebar-list-item__action icon-bin"
                (click)="$event.stopPropagation(); removeItem(stepItemControl, i)"
              >
              </a>
            </div>
          </div>

          <!--              <app-sidebar-collapse-->
          <!--                [title]="'Branch #' + (i + 1)"-->
          <!--                [error]="stepItemControl.invalid ? 'Option is not configured' : undefined"-->
          <!--                [description]="'test'"-->
          <!--                [block]="true"-->
          <!--                [context]="collapseContext"-->
          <!--                [openedInitial]="stepItemControl === createdItem"-->
          <!--                [descriptionWhenOpened]="false"-->
          <!--                [descriptionWrap]="true"-->
          <!--                [arrow]="true"-->
          <!--                [drag]="true"-->
          <!--                [padding]="false"-->
          <!--              >-->
          <!--                <ng-container right>-->
          <!--                  <a-->
          <!--                    href="javascript:void(0)"-->
          <!--                    class="sidebar-collapse__action icon-bin"-->
          <!--                    (click)="$event.stopPropagation(); removeItem(stepItemControl, i)"-->
          <!--                  >-->
          <!--                  </a>-->
          <!--                </ng-container>-->

          <!--                <div style="padding: 10px 0;">-->
          <!--                  <div class="sidebar__element sidebar__element_margin_xxs">-->
          <!--                    <app-sidebar-field>-->
          <!--                      <app-input-edit-->
          <!--                        [itemForm]="form.controls.items.controls[0].controls.condition"-->
          <!--                        [context]="context"-->
          <!--                        [staticValueDisabled]="true"-->
          <!--                        [focusedInitial]="true"-->
          <!--                        [placeholder]="'Value'"-->
          <!--                        [fill]="true"-->
          <!--                      ></app-input-edit>-->

          <!--                      <ng-container description>-->
          <!--                        Example: EQ(steps.query1.type, 'new')-->
          <!--                      </ng-container>-->
          <!--                    </app-sidebar-field>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </app-sidebar-collapse>-->
        </div>

        <div class="sidebar-list__buttons">
          <a
            href="javascript:void(0)"
            class="button button_orange-transparent button_small sidebar-list__buttons-item"
            (click)="addItem()"
          >
            <span class="icon icon-plus button__icon button__icon_left"></span>
            <span class="button__label">Add Branch</span>
          </a>

          <app-field-errors
            *ngIf="!form.controls.items.controls.length"
            [form]="form"
            [fieldName]="'items'"
          ></app-field-errors>
        </div>
      </div>
    </div>
  </app-sidebar-section>
</div>

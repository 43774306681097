import { FormControl, FormGroup } from '@angular/forms';

export interface ProjectPermissionActionsValue {
  read?: boolean;
  write?: boolean;
  delete?: boolean;
}

export class ProjectPermissionActionsControl extends FormGroup {
  controls: {
    read: FormControl;
    write: FormControl;
    delete: FormControl;
  };
  value: ProjectPermissionActionsValue;

  constructor(formState?: ProjectPermissionActionsValue) {
    super({
      read: new FormControl(formState ? !!formState.read : false),
      write: new FormControl(formState ? !!formState.write : false),
      delete: new FormControl(formState ? !!formState.delete : false)
    });
  }

  deserialize(value: string) {
    this.controls.read.setValue(value.includes('r'));
    this.controls.write.setValue(value.includes('w'));
    this.controls.delete.setValue(value.includes('d'));
  }

  serialize(): string {
    return [this.value.read ? 'r' : '', this.value.write ? 'w' : '', this.value.delete ? 'd' : ''].join('');
  }

  setAll(value: boolean) {
    this.patchValue({
      read: value,
      write: value,
      delete: value
    });
  }

  toggleAll() {
    if (this.isSelectedAll()) {
      this.setAll(false);
    } else {
      this.setAll(true);
    }
  }

  isSelectedAll() {
    return [this.controls.read.value, this.controls.write.value, this.controls.delete.value].every(item => item);
  }

  isSelectedAny() {
    return [this.controls.read.value, this.controls.write.value, this.controls.delete.value].some(item => item);
  }
}

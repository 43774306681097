import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { ParameterArray, ParameterControl } from '@modules/fields';
import { Token } from '@modules/tokens';

import { QueryBuilderContext } from '../../data/query-builder-context';

@Component({
  selector: 'app-query-builder-parameters-edit',
  templateUrl: './query-builder-parameters-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderParametersEditComponent implements OnInit, OnDestroy {
  @Input() control: ParameterArray;
  @Input() queryContext: QueryBuilderContext;
  @Input() object: string;

  createField = createFormFieldFactory();
  parameterValues = {};
  protectedControls: ParameterControl[] = [];
  commonControls: ParameterControl[] = [];
  sendParametersAnalytics = true;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.control.controls.length) {
      this.control.appendControl();
    }

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateControls();
    });

    this.updateControls();

    this.queryContext.tokenValues$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateTokenValues();
    });

    this.updateTokenValues();

    if (this.sendParametersAnalytics) {
      this.control.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(value => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
          Object: this.object
        });
      });
    }
  }

  ngOnDestroy(): void {}

  updateControls() {
    this.protectedControls = this.control.controls.filter(item => item.controls.protected.value);
    this.commonControls = this.control.controls.filter(item => !item.controls.protected.value);
    this.cd.markForCheck();
  }

  updateTokenValues() {
    this.parameterValues = fromPairs(
      toPairs(this.queryContext.getTokenValues()['params'] || {}).map(([key, value]) => {
        return [key, value];
      })
    );
    this.cd.markForCheck();
  }

  setTokenValue(control: ParameterControl, value: any) {
    const token: Token = {
      name: ['params', control.controls.name.value].join('.'),
      label: control.controls.name.value,
      field: control.controls.field.value
    };

    this.queryContext.setTokenValue(token, value);
  }

  addItem() {
    this.control.appendControl();

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
        Object: this.object
      });
    }
  }

  removeItem(control: ParameterControl) {
    this.control.removeControl(control);

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
        Object: this.object
      });
    }
  }

  controlPlaceholder(name: string) {
    return `Test value for {{params.${name || 'name'}}}`;
  }
}

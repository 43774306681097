var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ChartWidgetSimpleQuery } from './chart-widget-simple-query';
import { Query } from './query';
var ChartWidgetQuery = /** @class */ (function (_super) {
    __extends(ChartWidgetQuery, _super);
    function ChartWidgetQuery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.simpleQueryClass = ChartWidgetSimpleQuery;
        return _this;
    }
    ChartWidgetQuery.prototype.serialize = function (fields) {
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ChartWidgetQuery.prototype.isSuperConfigured = function () {
        return _super.prototype.isConfigured.call(this);
    };
    return ChartWidgetQuery;
}(Query));
export { ChartWidgetQuery };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../../../common/dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "@angular/common";
import * as i5 from "./auto-workflow-step.component";
import * as i6 from "../../../services/customize-bar-context/customize-bar.context";
import * as i7 from "../../../services/workflow-edit-context/workflow-edit.context";
import * as i8 from "../../../../action-queries/services/action/action.service";
import * as i9 from "../../../../../common/popups/services/popup/popup.service";
import * as i10 from "../../../../../common/popups/components/base-popup/base-popup.component";
import * as i11 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_AutoWorkflowStepComponent = [];
var RenderType_AutoWorkflowStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoWorkflowStepComponent, data: {} });
export { RenderType_AutoWorkflowStepComponent as RenderType_AutoWorkflowStepComponent };
function View_AutoWorkflowStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, [[1, 4]], 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AutoWorkflowStepComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { dynamicComponent: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoWorkflowStepComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AutoWorkflowStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-workflow-step", [], [[1, "data-step-id", 0], [1, "data-step-name", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AutoWorkflowStepComponent_0, RenderType_AutoWorkflowStepComponent)), i0.ɵdid(1, 4964352, null, 0, i5.AutoWorkflowStepComponent, [i6.CustomizeBarContext, i7.WorkflowEditContext, i8.ActionService, i9.PopupService, [2, i10.BasePopupComponent], i0.Injector, i0.ChangeDetectorRef, i11.UniversalAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).stepId; var currVal_1 = i0.ɵnov(_v, 1).stepName; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AutoWorkflowStepComponentNgFactory = i0.ɵccf("app-auto-workflow-step", i5.AutoWorkflowStepComponent, View_AutoWorkflowStepComponent_Host_0, { step: "step", prevStep: "prevStep", workflowEditable: "workflowEditable", index: "index", context: "context", actionTypesEnabled: "actionTypesEnabled", analyticsSource: "analyticsSource" }, { stepAddBefore: "stepAddBefore", stepDuplicate: "stepDuplicate", stepDelete: "stepDelete" }, []);
export { AutoWorkflowStepComponentNgFactory as AutoWorkflowStepComponentNgFactory };

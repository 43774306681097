import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectService } from '../services/project/project.service';

@Injectable()
export class ProjectSelectSource extends SelectSource {
  model: string;

  private page = 1;
  private totalPages = 1;

  constructor(private projectService: ProjectService) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    const params = { page: this.page };

    if (searchQuery != undefined) {
      params['_search'] = searchQuery;
    }

    return this.projectService.get(params).pipe(
      map(result => {
        this.totalPages = Math.ceil(result.count / 20);
        this.page += 1;

        return result.results
          .filter(
            item =>
              !searchQuery ||
              item.uniqueName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map(item => {
            return {
              value: item,
              name: item.name
            };
          });
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value) {
      return of(undefined);
    }
    return this.projectService.get().pipe(
      map(result => {
        const project = result.results.find(item => item.uniqueName == value.uniqueName);

        if (!project) {
          return;
        }

        return {
          value: project,
          name: project.name
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../draft-changes-item/draft-changes-item.component.ngfactory";
import * as i3 from "../draft-changes-item/draft-changes-item.component";
import * as i4 from "./draft-changes.component";
import * as i5 from "../../../projects/stores/current-project.store";
import * as i6 from "../../../projects/stores/current-environment.store";
import * as i7 from "../../../model-queries/stores/model-description.store";
import * as i8 from "../../../action-queries/stores/action.store";
import * as i9 from "../../../customize/stores/view-settings.store";
import * as i10 from "../../../menu/stores/menu-settings/menu-settings.store";
import * as i11 from "../../../custom-views/stores/custom-views.store";
import * as i12 from "../../../collaboration/stores/task-queue.store";
import * as i13 from "../../../projects/stores/project-property.store";
import * as i14 from "../../../all-project-settings/stores/project-settings.store";
import * as i15 from "../../../projects/services/environment/environment.service";
import * as i16 from "../../../customize-utils/services/publish/publish.service";
import * as i17 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i18 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i19 from "../../../../common/popups/components/base-popup/base-popup.component";
var styles_DraftChangesComponent = [];
var RenderType_DraftChangesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DraftChangesComponent, data: {} });
export { RenderType_DraftChangesComponent as RenderType_DraftChangesComponent };
function View_DraftChangesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "tr", [["class", "card-table__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Collections"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Page1, PageName2"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "div", [["class", "card-table__column-inner"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); var currVal_1 = true; _ck(_v, 8, 0, currVal_1); }); }
function View_DraftChangesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_DraftChangesComponent_2)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(2, 8), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, 1, 2, 3, 4, 5, 6, 7, 8).slice(0, (_v.context.size || 8)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DraftChangesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DraftChangesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "table", [["class", "card-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesComponent_4)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 6); _ck(_v, 3, 0, currVal_0); }, null); }
function View_DraftChangesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "tr", [["app-draft-changes-item", ""], ["class", "card-table__row"]], [[2, "card-table__row_clickable", null], [2, "card-table__row_expanded", null]], [[null, "publish"], [null, "discard"], [null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onLeave() !== false);
        ad = (pd_2 && ad);
    } if (("publish" === en)) {
        var pd_3 = (_co.publishDraft(_v.context.$implicit, $event) !== false);
        ad = (pd_3 && ad);
    } if (("discard" === en)) {
        var pd_4 = (_co.deleteDraft(_v.context.$implicit, $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_DraftChangesItemComponent_0, i2.RenderType_DraftChangesItemComponent)), i0.ɵdid(1, 245760, null, 0, i3.DraftChangesItemComponent, [i0.ViewContainerRef, i0.ChangeDetectorRef], { item: [0, "item"], disabled: [1, "disabled"] }, { publish: "publish", discard: "discard" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = (_co.publishDraftLoading || _co.deleteDraftLoading); _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.singleItem; var currVal_1 = i0.ɵnov(_v, 1).expandedClass; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DraftChangesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "table", [["class", "card-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesComponent_6)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changes; _ck(_v, 4, 0, currVal_0); }, null); }
function View_DraftChangesComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DraftChangesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesComponent_8)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 7); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DraftChangesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "custom-page-popup__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "custom-page-popup__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "custom-page-popup__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Draft changes"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "custom-page-popup__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "a", [["class", "custom-page-popup__close icon-close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(0, [["table_rows_stub_template", 2]], null, 0, null, View_DraftChangesComponent_1)), (_l()(), i0.ɵand(0, [["table_stub_template", 2]], null, 0, null, View_DraftChangesComponent_3)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["style", "padding: 20px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DraftChangesComponent_7)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_DraftChangesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-draft-changes", [], null, null, null, View_DraftChangesComponent_0, RenderType_DraftChangesComponent)), i0.ɵdid(1, 245760, null, 0, i4.DraftChangesComponent, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.ModelDescriptionStore, i8.ActionStore, i9.ViewSettingsStore, i10.MenuSettingsStore, i11.CustomViewsStore, i12.TaskQueueStore, i13.ProjectPropertyStore, i14.ProjectSettingsStore, i15.EnvironmentService, i16.PublishService, i17.UniversalAnalyticsService, i18.DialogService, i19.BasePopupComponent, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DraftChangesComponentNgFactory = i0.ɵccf("app-draft-changes", i4.DraftChangesComponent, View_DraftChangesComponent_Host_0, {}, {}, []);
export { DraftChangesComponentNgFactory as DraftChangesComponentNgFactory };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Plan } from '../../data/plan';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(params = {}): Observable<Plan[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('plans/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Plan().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(uniqueName: string): Observable<Plan> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`plans/${uniqueName}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers });
      }),
      map(result => new Plan().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

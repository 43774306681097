import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionType } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { WorkflowEditController } from '@modules/customize-bar';
import { MenuSection, MenuService } from '@modules/menu';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  hasEnvironmentPermission,
  ProjectPermissions
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import {
  WorkflowBackendRunListStore,
  WorkflowBackendRunService,
  WorkflowBackendRunWithRelations
} from '@modules/workflow';
import { AppError, isSet } from '@shared';

@Component({
  selector: 'app-automation-runs',
  templateUrl: './automation-runs.component.html',
  providers: [WorkflowBackendRunListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationRunsComponent implements OnInit, OnDestroy {
  items$: Observable<WorkflowBackendRunWithRelations[]>;
  loading$: Observable<boolean>;
  hasMore = false;
  error: string;
  analyticsSource = 'automation_runs';

  trackItemFn(i, item: WorkflowBackendRunWithRelations) {
    return item.id;
  }

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private workflowBackendRunService: WorkflowBackendRunService,
    private workflowEditController: WorkflowEditController,
    private store: WorkflowBackendRunListStore,
    private menuService: MenuService,
    private routing: RoutingService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
      this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
      return;
    }

    this.menuService.section = MenuSection.None;

    this.loading$ = this.store.loading$;
    this.items$ = this.store.items$;

    this.store
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.hasMore = result && result.length == this.store.perPage;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  getNext() {
    this.store
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.hasMore = result && result.length == this.store.perPage;
        this.cd.markForCheck();
      });
  }

  openItem(item: WorkflowBackendRunWithRelations) {
    this.workflowBackendRunService
      .getDetail(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, item.id)
      .pipe(
        switchMap(result => {
          if (!result || !result.log) {
            throw new AppError('No information about this run');
          }

          return this.workflowEditController.open({
            name: result.getName(),
            workflow: result.log.workflow,
            workflowRun: result.log.workflowRun,
            parameters: [],
            trigger: result.log.trigger,
            triggerEditable: !!item.automation,
            triggerLabel: isSet(item.pageUid) ? 'Page action' : undefined,
            triggerData: result.log.data,
            actionTypesEnabled: [ActionType.Query],
            resultEnabled: true,
            analyticsSource: this.analyticsSource
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {},
        error => {
          this.notificationService.error('Failed to open', String(error));
        }
      );
  }
}

export const pipedriveResourceParamsParams: Object = {
  base_http_query: {
    method: 'GET',
    auth_type: 'key',
    auth_params: {
      name: 'api_token',
      send_as: 'query_param'
    },
    body_type: 'JSON',
    body: '',
    headers: [],
    query_params: [
      {
        name: 'api_token',
        value: '{-api_key-}'
      }
    ],
    response_type: 'json',
    request_tokens: {}
  }
};

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';

import { BaseUploadComponent } from '../base-upload.component';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent extends BaseUploadComponent {
  constructor(
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    storageService: StorageService,
    cd: ChangeDetectorRef
  ) {
    super(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-element-action',
  templateUrl: './customize-bar-action-edit-type-element-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeElementActionComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  constructor() {}

  ngOnInit() {}
}

<ng-template #custom_resource>
  <div class="select-item-grid__item">
    <a
      href="javascript:void(0)"
      class="select-item-grid-item select-item-grid-item_contrast"
      (click)="chooseResource(customResource)"
    >
      <span class="select-item-grid-item__image">
        <span class="select-item-grid-item__image-icon icon-plus"></span>
      </span>
      <span class="select-item-grid-item__title">
        New Data
        <span
          *ngIf="customResource.icon"
          class="select-item-grid-item__subtitle-logo"
          [style.background-image]="'/assets/images/resources/icons/' + customResource.icon + '.svg' | appDeployCssUrl"
        >
        </span>
      </span>
      <span class="select-item-grid-item__subtitle"> using {{ customResource.label }} </span>
    </a>
  </div>
</ng-template>

<div class="select-item">
  <!--      <div class="select-item__header">-->
  <!--        <div class="select-item__title">Choose Resource</div>-->
  <!--        <div class="select-item__description">Integrate with Database or 3rd party APIs</div>-->
  <!--      </div>-->
  <div class="select-item__main">
    <div class="select-item__main-navigation">
      <div class="select-item-input select-item__main-navigation-input">
        <input
          type="text"
          class="select-item-input__control"
          placeholder="Search..."
          [(ngModel)]="resourceSearch"
          [appAutofocus]="true"
          (keyup)="resourceSearchUpdated.next(); onSearchKey($event)"
        />
        <span class="select-item-input__icon icon-search"></span>
        <span
          *ngIf="resourceSearch | appIsSet"
          class="select-item-input__close icon-close"
          (click)="clearSearch()"
        ></span>
      </div>
      <div class="select-item__main-navigation-list">
        <div class="select-item-list">
          <ng-container *ngFor="let item of resourceFilters; let i = index">
            <div *ngIf="resourceFilterCounts[i]" class="select-item-list__item">
              <a
                href="javascript:void(0)"
                class="select-item-list-item"
                [class.select-item-list-item_active]="resourceFilterIndex == i"
                (click)="setResourceFilterIndex(i)"
              >
                <span class="select-item-list-item__main">{{ item.label }}</span>
                <span class="select-item-list-item__right">{{ resourceFilterCounts[i] }}</span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="select-item__main-content">
      <div class="select-item-grid">
        <ng-container *ngIf="resourcesFiltered?.length">
          <ng-container *ngTemplateOutlet="custom_resource"></ng-container>
        </ng-container>

        <div *ngFor="let item of resourcesFiltered" class="select-item-grid__item">
          <a href="javascript:void(0)" class="select-item-grid-item" (click)="chooseResource(item)">
            <span class="select-item-grid-item__image">
              <span
                class="select-item-grid-item__image-inner"
                [style.background-image]="'/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl"
              >
              </span>
            </span>
            <span class="select-item-grid-item__title">{{ item.label }}</span>
            <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
            <span *ngIf="item.beta" class="select-item-grid-item__tag background-color_orange_1">
              <span class="select-item-grid-item__tag-raw">β</span>eta
            </span>
          </a>
        </div>
        <ng-container *ngIf="resourceSearch && !resourcesFiltered?.length">
          <div class="select-item-grid__message">
            <div>
              Can't find resource you're looking for?
            </div>
            <div>
              You can integrate any <strong>Rest API</strong>, <strong>GraphQL</strong> or
              <strong>create new data</strong> using the options below:
            </div>
          </div>

          <ng-container *ngTemplateOutlet="custom_resource"></ng-container>

          <div *ngFor="let item of resourcesNotFound" class="select-item-grid__item">
            <a href="javascript:void(0)" class="select-item-grid-item" (click)="chooseResource(item)">
              <span class="select-item-grid-item__image">
                <span
                  class="select-item-grid-item__image-inner"
                  [style.background-image]="'/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl"
                >
                </span>
              </span>
              <span class="select-item-grid-item__title">{{ item.label }}</span>
              <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

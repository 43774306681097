import { generateAlphanumeric } from '@shared';

import { getElementByType } from '../element-items';
import { ElementItem } from './base';

export class ColumnsLayoutColumnElementItem {
  public uid: string;
  public weight: number;
  public width: number;
  public fit = false;
  public children: ElementItem[] = [];

  deserialize(data: Object): ColumnsLayoutColumnElementItem {
    this.uid = data['uid'];
    this.weight = data['weight'];
    this.width = data['width'];
    this.fit = data['fit'];

    if (!this.uid) {
      this.generateUid();
    }

    if (data['children']) {
      this.children = data['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    return this;
  }

  serialize(): Object {
    return {
      uid: this.uid,
      weight: this.weight,
      width: this.width,
      fit: this.fit,
      children: this.children.map(item => item.serialize())
    };
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

import { ChangeDetectorRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { WorkflowExecuteEventType } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { WorkflowStep, WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';

import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';

export enum WorkflowStepStatus {
  NotExecuted = 'not_executed',
  Executing = 'executing',
  Success = 'success',
  Failed = 'failed'
}

export interface WorkflowRunParams {
  success: boolean;
  params?: Object;
  result?: any;
  error?: any;
}

export abstract class WorkflowStepComponent<T extends WorkflowStep = WorkflowStep, S = any> implements OnDestroy {
  @Input() step: T;
  @Input() prevStep: WorkflowStep;
  @Input() workflowEditable = false;
  @Input() index: number;
  @Input() context: ViewContext;
  @Input() customizing$: Observable<boolean>;
  @Input() actionTypesEnabled: ActionType[];
  @Input() analyticsSource: string;
  @Output() stepAddBefore = new EventEmitter<WorkflowStep>();
  @Output() stepCustomize = new EventEmitter<{ contextElement?: ViewContextElement }>();
  @Output() stepExecute = new EventEmitter<void>();
  @Output() stepDuplicate = new EventEmitter<void>();
  @Output() stepDelete = new EventEmitter<void>();

  step$ = new BehaviorSubject<T>(undefined);
  state: S = {} as S;
  status = WorkflowStepStatus.NotExecuted;
  statusIcon: string;
  statusColor: string;
  statuses = WorkflowStepStatus;

  constructor(
    public contextElement: ViewContextElement,
    protected workflowEditContext: WorkflowEditContext,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  trackChanges() {
    this.step$
      .pipe(
        switchMap(value => this.getStepState(value)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  stepOnChange(value: T) {
    this.step$.next(value);
  }

  getStepState(step: T): Observable<S> {
    return;
  }

  onStateUpdated(state: S) {}

  trackRun() {
    this.workflowEditContext.run$
      .pipe(
        map(run => {
          if (!run) {
            return;
          }

          return run.stepRuns.find(item => item.uid == this.step.uid);
        }),
        untilDestroyed(this)
      )
      .subscribe(stepRun => {
        if (stepRun) {
          this.onRunUpdated({
            success: !stepRun.error,
            params: stepRun.params,
            result: stepRun.result,
            error: stepRun.error
          });

          if (isSet(stepRun.error)) {
            this.setStatus(WorkflowStepStatus.Failed);
          } else {
            this.setStatus(WorkflowStepStatus.Success);
          }
        }
      });
  }

  trackExecuteStatus(options: { successOnStart?: boolean } = {}) {
    this.workflowEditContext.testExecuteEvents$.pipe(untilDestroyed(this)).subscribe(event => {
      if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
        this.setStatus(WorkflowStepStatus.NotExecuted);
      } else if (event.type == WorkflowExecuteEventType.StepStarted && event.step.uid == this.step.uid) {
        this.setStatus(options.successOnStart ? WorkflowStepStatus.Success : WorkflowStepStatus.Executing);
      } else if (event.type == WorkflowExecuteEventType.StepFinished && event.step.uid == this.step.uid) {
        this.onRunUpdated({
          success: event.success,
          params: event.params,
          result: event.result,
          error: event.error
        });

        if (!options.successOnStart) {
          if (event.success) {
            this.setStatus(WorkflowStepStatus.Success);
          } else {
            this.setStatus(WorkflowStepStatus.Failed);
          }
        }
      }
    });
  }

  onRunUpdated(event: WorkflowRunParams) {}

  setStatus(status: WorkflowStepStatus) {
    this.status = status;

    if (this.status == WorkflowStepStatus.Success) {
      this.statusIcon = 'check_2';
      this.statusColor = 'green';
    } else if (this.status == WorkflowStepStatus.Failed) {
      this.statusIcon = 'close';
      this.statusColor = 'red';
    } else {
      this.statusIcon = undefined;
      this.statusColor = undefined;
    }

    this.cd.markForCheck();
  }

  stepHasPreviousConnection(): boolean {
    return !this.prevStep || this.prevStep.type != WorkflowStepType.Exit;
  }
}

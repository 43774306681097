import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Color from 'color';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { colors, parseColor } from '@modules/colors';
import { isColorHex, isColorRgb, isSet, numberToHex } from '@shared';
var ColorSelectorComponent = /** @class */ (function () {
    function ColorSelectorComponent(cd) {
        this.cd = cd;
        this.customColors = false;
        this.allowEmpty = false;
        this.rowSize = 6;
        this.selected = new EventEmitter();
        this.finish = new EventEmitter();
        this.customColorControl = new FormControl('#2B50ED');
    }
    ColorSelectorComponent.prototype.ngOnInit = function () {
        this.updateItems();
        this.setInitialCustomColor();
        this.initCustomColor();
    };
    ColorSelectorComponent.prototype.ngOnDestroy = function () { };
    ColorSelectorComponent.prototype.ngOnChanges = function (changes) {
        if (changes.colors) {
            this.updateItems();
        }
    };
    ColorSelectorComponent.prototype.updateItems = function () {
        var items = [];
        if (this.allowEmpty) {
            items.push({
                value: undefined,
                color: isSet(this.emptyColor) ? this.emptyColor : null
            });
        }
        if (this.colors) {
            items.push.apply(items, this.colors
                .map(function (value) {
                if (isColorHex(value) || isColorRgb(value)) {
                    var clr = Color(value);
                    return {
                        value: clr.hex(),
                        color: clr.hex(),
                        predefined: false
                    };
                }
                else {
                    var predefined = colors.find(function (item) { return item.name == value; });
                    if (predefined) {
                        var clr = Color(predefined.hex);
                        return {
                            value: predefined.name,
                            color: clr.hex(),
                            predefined: true
                        };
                    }
                    else {
                        return {
                            value: value,
                            color: value
                        };
                    }
                }
            })
                .filter(function (item) { return isSet(item); }));
        }
        else {
            items.push.apply(items, colors.map(function (item) {
                var clr = Color(item.hex);
                return {
                    value: item.name,
                    color: clr.hex(),
                    predefined: true
                };
            }));
        }
        this.items = items;
        this.cd.markForCheck();
    };
    Object.defineProperty(ColorSelectorComponent.prototype, "width", {
        get: function () {
            return 12 + this.rowSize * (8 + 26 + 8) + 12;
        },
        enumerable: true,
        configurable: true
    });
    ColorSelectorComponent.prototype.select = function (item) {
        if (this.customColors) {
            this.customColorControl.patchValue(item.color ? item.color : '');
        }
        this.selected.emit(item.value);
        this.finish.emit();
    };
    ColorSelectorComponent.prototype.initCustomColor = function () {
        var _this = this;
        this.customColorControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (_this.customColors) {
                _this.selected.emit(value);
            }
        });
    };
    ColorSelectorComponent.prototype.setInitialCustomColor = function () {
        var _this = this;
        if (!isSet(this.current)) {
            return;
        }
        if (isColorHex(this.current)) {
            this.customColorControl.patchValue(this.current);
            return;
        }
        var color = colors.find(function (item) { return item.name == _this.current; });
        if (color) {
            var hex = '#' + numberToHex(color.hex);
            this.customColorControl.patchValue(hex);
        }
    };
    ColorSelectorComponent.prototype.getContrastColor = function (color) {
        var colorHex = isColorHex(color)
            ? color.substring(1)
            : colors.filter(function (item) { return item.name == color; }).map(function (item) { return numberToHex(item.hex); })[0];
        var clr = colorHex ? parseColor('#' + colorHex) : undefined;
        if (!clr) {
            return null;
        }
        var colorContrast = clr.contrast(Color('white'));
        var isDark = colorContrast >= 2;
        return isDark ? '#fff' : clr.darken(0.8).string();
    };
    return ColorSelectorComponent;
}());
export { ColorSelectorComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, of, Subject } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc } from '@modules/charts';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { AggregateDisplayField, ComputedDisplayField, CustomViewDisplayField, DisplayFieldType, FieldType, LookupDisplayField } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { CustomViewTemplateType, Frame, View } from '@modules/views';
import { AutofocusDirective, controlValue, generateAlphanumeric, isSet } from '@shared';
// TODO: Refactor import
import { CustomViewMapParametersController } from '../../../views-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { DisplayFieldArray } from './display-field.array';
import { FieldActionsArray } from './field-actions.array';
var DisplayFieldsEditComponent = /** @class */ (function () {
    function DisplayFieldsEditComponent(currentProjectStore, currentEnvironmentStore, customizeBarService, customizeBarContext, modelService, viewEditorController, customViewService, customViewsStore, customViewTemplatesController, customViewMapParametersController, contextTokenProvider, notificationService, analyticsService, popoverService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.modelService = modelService;
        this.viewEditorController = viewEditorController;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.customViewTemplatesController = customViewTemplatesController;
        this.customViewMapParametersController = customViewMapParametersController;
        this.contextTokenProvider = contextTokenProvider;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.popoverService = popoverService;
        this.injector = injector;
        this.cd = cd;
        this.itemName = 'field';
        this.componentName = 'component';
        this.collapsible = true;
        this.searchEnabled = false;
        this.searchFocus = false;
        this.visibleEditable = true;
        this.customViewEnabled = false;
        this.customViewStateSelectedEnabled = false;
        this.firstInit = false;
        this.searchCleared = new EventEmitter();
        this.isLookupsSupported = false;
        this.displayItems = [];
        this.maxDisplayInitial = 8;
        this.collapsed = true;
        this.search = '';
        this.searchUpdated = new Subject();
        this.submitLoading = false;
        this.displayFieldTypes = DisplayFieldType;
    }
    DisplayFieldsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(controlValue(this.form), this.searchUpdated)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateDisplayItems(); });
    };
    DisplayFieldsEditComponent.prototype.ngOnDestroy = function () { };
    DisplayFieldsEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.resource || changes.modelDescription) {
            this.isLookupsSupported =
                this.modelService.isGetAdvSupported(this.resource, this.modelDescription) &&
                    this.modelDescription &&
                    this.modelDescription.queryType != QueryType.SQL;
        }
        if (changes.searchEnabled && !this.searchEnabled) {
            this.clearSearch();
        }
    };
    DisplayFieldsEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls, event.previousIndex, event.currentIndex);
            this.form.updateValueAndValidity();
        }
    };
    DisplayFieldsEditComponent.prototype.getDistinctName = function (baseName, template, startIndex) {
        if (template === void 0) { template = function (n, i) { return n + "_" + i; }; }
        if (startIndex === void 0) { startIndex = 1; }
        var names = this.form.controls.map(function (item) {
            var value = item.controls.name.value;
            return isSet(value) ? value : '';
        });
        var name;
        var index = startIndex;
        do {
            name = template(baseName, index);
            ++index;
        } while (names.find(function (item) { return item.toLowerCase() == name.toLowerCase(); }));
        return name;
    };
    DisplayFieldsEditComponent.prototype.customize = function (control) {
        var _this = this;
        if (!control.controls.visible.value) {
            control.controls.visible.patchValue(true);
        }
        var column = control.serialize();
        var initialElement = cloneDeep(control.serialize());
        var valueEditable = control.instance && control.instance.type == DisplayFieldType.Computed;
        var lookupEditable = control.instance && control.instance.type == DisplayFieldType.Lookup;
        var aggregateEditable = control.instance && control.instance.type == DisplayFieldType.Aggregate;
        var actionsValue = this.fieldActionsControl
            ? this.fieldActionsControl.getColumnActions(control.controls.name.value)
            : [];
        this.customizeBarService
            .customizeColumn({
            context: this.customizeBarContext,
            column: column,
            modelDescription: this.modelDescription,
            actions: actionsValue,
            configurable: {
                verboseName: true,
                value: valueEditable,
                lookup: lookupEditable,
                aggregate: aggregateEditable,
                action: !!this.fieldActionsControl
            },
            viewContext: this.context,
            viewContextElement: this.contextElement,
            viewContextElementPath: this.contextElementPath,
            viewContextElementPaths: this.contextElementPaths,
            actionsLabels: this.actionsLabels,
            append: true,
            firstInit: this.firstInit
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Updated) {
                control.deserialize(e.args['result']);
                control.markAsDirty();
                if (_this.fieldActionsControl) {
                    var actionsControl = _this.fieldActionsControl.getColumnControl(control.controls.name.value);
                    var actions = e.args['actions'] || [];
                    if (actionsControl) {
                        actionsControl.controls.actions.setValue(actions);
                    }
                    else {
                        _this.fieldActionsControl.appendControl({
                            name: control.controls.name.value,
                            actions: actions
                        });
                    }
                }
            }
            else if (e.type == CustomizeBarEditEventType.Canceled) {
                control.deserialize(initialElement);
                control.markAsDirty();
            }
        });
        this.clearSearch();
    };
    DisplayFieldsEditComponent.prototype.getControlUniqueName = function () {
        return "_jet_" + generateAlphanumeric(4);
    };
    DisplayFieldsEditComponent.prototype.getControlUniqueField = function (fieldValueGetter, prefix, separator, skipFirst) {
        if (separator === void 0) { separator = '_'; }
        if (skipFirst === void 0) { skipFirst = false; }
        var nextNumber = 1;
        var result;
        do {
            result = skipFirst && nextNumber == 1 ? prefix : "" + prefix + separator + nextNumber;
            ++nextNumber;
        } while (this.form.controls.find(function (group) { return fieldValueGetter(group) == result; }));
        return result;
    };
    DisplayFieldsEditComponent.prototype.addComputedItem = function (type) {
        if (type === void 0) { type = FieldType.Text; }
        var uniqueName = this.getControlUniqueName();
        var defaultVerboseName = 'Computed Field';
        var verboseName = this.getControlUniqueField(function (item) { return item.controls.verboseName.value; }, defaultVerboseName, ' ', true);
        var instance = new ComputedDisplayField({
            name: uniqueName,
            verboseName: verboseName,
            field: type,
            visible: true
        });
        var control = this.form.appendControl(instance);
        this.customize(control);
        this.clearSearch();
    };
    DisplayFieldsEditComponent.prototype.createView = function () {
        var _this = this;
        var result = new View();
        result.generateId();
        result.name = 'New View';
        result.frame = new Frame({ width: 110, height: 40 });
        var sourceParameters = this.form.getParameters();
        if (sourceParameters) {
            result.parameters = sourceParameters;
            if (this.contextElement) {
                var testValues = sourceParameters
                    .map(function (parameter) {
                    var testValue = _this.contextElement.getFieldValue(parameter.name);
                    return [parameter.name, testValue];
                })
                    .filter(function (item) { return isSet(item); });
                result.testParameters = fromPairs(testValues);
            }
        }
        return result;
    };
    DisplayFieldsEditComponent.prototype.submitCardView = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!options.view) {
            return of(undefined);
        }
        var customView$ = isSet(options.uniqueName)
            ? this.customViewsStore.getDetailFirst(options.uniqueName)
            : of(undefined);
        return customView$.pipe(switchMap(function (customView) {
            var pageUid = _this.context && _this.context.viewSettings ? _this.context.viewSettings.uid : undefined;
            var elementUid = _this.element ? _this.element.uid : undefined;
            var fields = ['unique_name', 'view_type', 'view', 'params'];
            if (customView) {
                var instance = cloneDeep(customView);
                instance.view = options.view;
                instance.pageUid = pageUid;
                instance.elementUid = elementUid;
                instance.columnUniqueName = options.columnUniqueName;
                return _this.customViewService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
            else {
                var instance = new CustomView();
                instance.uniqueName =
                    isSet(pageUid) && isSet(elementUid) && isSet(options.columnUniqueName)
                        ? [CustomViewType.ItemColumn, pageUid, elementUid, options.columnUniqueName].join('.')
                        : [CustomViewType.ItemColumn, generateAlphanumeric(8, { letterFirst: true })].join('.');
                instance.viewType = CustomViewType.ItemColumn;
                instance.view = options.view;
                instance.pageUid = pageUid;
                instance.elementUid = elementUid;
                instance.columnUniqueName = options.columnUniqueName;
                return _this.customViewService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: true, fields: fields });
            }
        }), delayWhen(function () { return _this.customViewsStore.getFirst(true); }), map(function (result) { return result.uniqueName; }));
    };
    DisplayFieldsEditComponent.prototype.addCustomViewField = function (view, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var uniqueName = this.getControlUniqueName();
        var defaultVerboseName = view.name;
        var verboseName = this.getControlUniqueField(function (item) { return item.controls.verboseName.value; }, defaultVerboseName, ' ', true);
        this.submitLoading = true;
        this.cd.markForCheck();
        this.submitCardView({ view: view, columnUniqueName: uniqueName })
            .pipe(untilDestroyed(this))
            .subscribe(function (customView) {
            _this.submitLoading = false;
            _this.cd.markForCheck();
            if (!customView) {
                return;
            }
            var instance = new CustomViewDisplayField({
                name: uniqueName,
                verboseName: verboseName,
                visible: true
            });
            instance.customView = customView;
            if (options.mapping) {
                instance.customViewMappings = options.mapping;
            }
            _this.form.appendControl(instance);
            _this.clearSearch();
        }, function (error) {
            _this.submitLoading = false;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
        });
    };
    DisplayFieldsEditComponent.prototype.updateCustomViewField = function (control, view, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var uniqueName = control.controls.customViewUniqueName.value;
        var columnUniqueName = control.instance ? control.instance.name : uniqueName;
        this.submitControlLoading = control;
        this.cd.markForCheck();
        this.submitCardView({ uniqueName: uniqueName, view: view, columnUniqueName: columnUniqueName })
            .pipe(untilDestroyed(this))
            .subscribe(function (customView) {
            _this.submitControlLoading = undefined;
            _this.cd.markForCheck();
            if (!customView) {
                return;
            }
            control.controls.customViewUniqueName.patchValue(customView);
            if (options.mapping) {
                control.controls.customViewMappings.patchValue(options.mapping);
            }
            if (options.updateName) {
                control.controls.verboseName.patchValue(view.name);
            }
        }, function (error) {
            _this.submitControlLoading = undefined;
            _this.cd.markForCheck();
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
        });
    };
    DisplayFieldsEditComponent.prototype.openViewEditor = function (control, view) {
        var _this = this;
        var create = !control;
        if (!view) {
            view = this.createView();
        }
        return this.viewEditorController
            .open({
            create: create,
            view: view,
            componentLabel: this.componentName,
            submitLabel: create ? 'Create cell' : 'Save changes',
            nameEditingEnabled: create,
            stateSelectedEnabled: this.customViewStateSelectedEnabled,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            if (create) {
                _this.addCustomViewField(result.view);
            }
            else {
                _this.updateCustomViewField(control, result.view);
            }
        });
    };
    DisplayFieldsEditComponent.prototype.openCustomViewTemplates = function (control) {
        var _this = this;
        var create = !control;
        this.customViewTemplatesController
            .chooseTemplate({
            initialFilter: { type: CustomViewTemplateType.ItemColumn },
            nameEditingEnabled: create,
            viewCreateEnabled: true,
            stateSelectedEnabled: this.customViewStateSelectedEnabled,
            componentLabel: this.componentName,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (viewResult) {
            var sourceParameters = _this.form.getParameters();
            _this.customViewMapParametersController
                .open({
                sourceParameters: sourceParameters,
                view: viewResult.view,
                context: _this.context,
                contextElement: _this.contextElement,
                contextElementPath: _this.contextElementPath,
                contextElementPaths: _this.contextElementPaths,
                contextTokenProvider: _this.contextTokenProvider,
                analyticsSource: _this.analyticsSource
            })
                .pipe(filter(function (mappingResult) { return !mappingResult.cancelled; }), untilDestroyed(_this))
                .subscribe(function (mappingResult) {
                if (create) {
                    _this.addCustomViewField(viewResult.view, { mapping: mappingResult.mappings });
                }
                else {
                    _this.updateCustomViewField(control, viewResult.view, {
                        mapping: mappingResult.mappings,
                        updateName: true
                    });
                }
            });
        });
    };
    DisplayFieldsEditComponent.prototype.changeMapping = function (control, view) {
        var mappings = control.controls.customViewMappings.value;
        var sourceParameters = this.form.getParameters();
        return this.customViewMapParametersController
            .open({
            sourceParameters: sourceParameters,
            view: view,
            mappings: mappings,
            context: this.context,
            contextElement: this.contextElement,
            contextElementPath: this.contextElementPath,
            contextElementPaths: this.contextElementPaths,
            contextTokenProvider: this.contextTokenProvider,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (mappingResult) { return !mappingResult.cancelled; }), untilDestroyed(this))
            .subscribe(function (mappingResult) {
            control.controls.customViewMappings.patchValue(mappingResult.mappings);
        });
    };
    DisplayFieldsEditComponent.prototype.updateCustomViewTemplate = function (control, view) {
        this.customViewTemplatesController
            .setTemplateView(view, {
            stateSelectedEnabled: this.customViewStateSelectedEnabled,
            componentLabel: this.componentName
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe();
    };
    DisplayFieldsEditComponent.prototype.renameCustomView = function (control, name) {
        control.controls.verboseName.patchValue(name);
    };
    DisplayFieldsEditComponent.prototype.addLookupItem = function (e) {
        if (!e.field) {
            return;
        }
        var uniqueName = this.getControlUniqueName();
        var defaultVerboseName = e.path.map(function (item) { return item.verboseName; }).join(' ');
        var verboseName = this.getControlUniqueField(function (item) { return item.controls.verboseName.value; }, defaultVerboseName, ' ', true);
        var instance = new LookupDisplayField(__assign({ name: uniqueName, verboseName: verboseName }, (e.field && {
            field: e.field.field,
            params: e.field.params
        }), { visible: true, path: e.path.map(function (item) { return item.name; }) }));
        var control = this.form.appendControl(instance);
        this.customize(control);
        this.clearSearch();
    };
    DisplayFieldsEditComponent.prototype.addAggregateItem = function (e) {
        if (!e.relation) {
            return;
        }
        var uniqueName = this.getControlUniqueName();
        var defaultVerboseName = e.path.map(function (item) { return item.verboseName; }).join(' ') + " " + (e.aggregation ? e.aggregation.func.toLowerCase() : 'Count');
        var verboseName = this.getControlUniqueField(function (item) { return item.controls.verboseName.value; }, defaultVerboseName, ' ', true);
        var instance = new AggregateDisplayField(__assign({ name: uniqueName, verboseName: verboseName }, (e.field && {
            field: e.field.field,
            params: e.field.params
        }), (e.relation && {
            field: FieldType.Number
        }), { path: e.path.map(function (item) { return item.name; }) }, (e.aggregation
            ? {
                func: e.aggregation.func,
                column: e.aggregation.field
            }
            : { func: AggregateFunc.Count, column: undefined }), { visible: true }));
        var control = this.form.appendControl(instance);
        this.customize(control);
        this.clearSearch();
    };
    DisplayFieldsEditComponent.prototype.updateDisplayItems = function () {
        var processSearch = function (str) { return (str || '').trim().toLowerCase(); };
        var search = processSearch(this.search);
        if (isSet(search)) {
            this.displayItems = this.form.controls.filter(function (item) {
                return (processSearch(item.controls.verboseName.value).indexOf(search) !== -1 ||
                    processSearch(item.controls.name.value).indexOf(search) !== -1);
            });
        }
        else if (this.collapsible && this.collapsed) {
            this.displayItems = this.form.controls.slice(0, this.maxDisplayInitial);
        }
        else {
            this.displayItems = this.form.controls;
        }
        this.cd.markForCheck();
    };
    DisplayFieldsEditComponent.prototype.setCollapsed = function (value) {
        this.collapsed = value;
        this.cd.markForCheck();
        this.updateDisplayItems();
    };
    DisplayFieldsEditComponent.prototype.isToggledAll = function () {
        return this.form.isToggledAll();
    };
    DisplayFieldsEditComponent.prototype.toggleAll = function () {
        this.form.toggleAll();
    };
    DisplayFieldsEditComponent.prototype.isEmpty = function () {
        return this.displayItems.length == 0;
    };
    DisplayFieldsEditComponent.prototype.hasMultipleItems = function () {
        return this.displayItems.length > 1;
    };
    DisplayFieldsEditComponent.prototype.clearSearch = function () {
        if (!isSet(this.search)) {
            return;
        }
        this.search = '';
        this.searchUpdated.next();
        this.cd.markForCheck();
        this.searchCleared.emit();
    };
    DisplayFieldsEditComponent.prototype.onSearchBlur = function () {
        if (!isSet(this.search)) {
            this.searchCleared.emit();
        }
    };
    DisplayFieldsEditComponent.prototype.focusSearch = function () {
        if (this.searchAutoFocus) {
            this.searchAutoFocus.focus();
        }
    };
    return DisplayFieldsEditComponent;
}());
export { DisplayFieldsEditComponent };

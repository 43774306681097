import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DropListService } from '@common/drag-drop2';
import { Layer } from '@modules/views';

import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';

@Component({
  selector: 'app-view-editor-navigator-drag-preview',
  templateUrl: './view-editor-navigator-drag-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorNavigatorDragPreviewComponent implements OnInit {
  layer: Layer;
  multipleLayers: Layer[];

  constructor(private dropListService: DropListService, private editorContext: ViewEditorContext) {}

  ngOnInit() {
    const dragStart = this.dropListService.draggingDragStart$.value;
    const layer = dragStart.source.data as Layer;
    const customizingLayers = this.editorContext.customizingLayers$.value;
    const multipleLayers =
      customizingLayers.length > 1 && customizingLayers.find(item => item.isSame(layer))
        ? customizingLayers
        : undefined;

    this.layer = multipleLayers ? undefined : layer;
    this.multipleLayers = multipleLayers;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Option } from '@modules/field-components';
import { ContainerLayer, FlexLayout, FrameLayer, GroupLayer, LayerType } from '@modules/views';

import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import {
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';
import { FlexLayoutOrientationOption, serializeFlexLayoutOrientationOption } from '../../controls/flex-layout';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { GroupCustomizeLayerForm } from './group-customize-layer.form';

@Component({
  selector: 'app-group-customize-layer',
  templateUrl: './group-customize-layer.component.html',
  providers: [GroupCustomizeLayerForm, CustomizeBarContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCustomizeLayerComponent extends CustomizeLayerComponent<GroupLayer> implements OnInit, OnDestroy {
  containerLayer: ContainerLayer;
  settingsComponents: DynamicComponentArguments[] = [];

  constructor(
    public form: GroupCustomizeLayerForm,
    private editorContext: ViewEditorContext,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const layerContainer = this.editorContext.getLayerContainer(this.layer);

    this.form.init(this.layer, { firstInit: true, container: layerContainer });
    this.trackFormUpdates(this.form, () => this.form.submit());

    combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
      .pipe(auditTime(10), untilDestroyed(this))
      .subscribe(([layer, container]) => {
        this.pauseFormUpdates();
        this.form.init(layer, {
          firstInit: false,
          container: container
        });
        this.resumeFormUpdates();
      });

    this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(layer => {
      this.containerLayer = layer;
      this.cd.markForCheck();
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  setFlexLayout(option: Option<FlexLayoutOrientationOption>) {
    const container = this.containerLayer ? this.containerLayer.layers : this.editorContext.view$.value.layers;
    const index = container.findIndex(item => item.isSame(this.layer));

    if (index === -1) {
      return;
    }

    const frameLayer = new FrameLayer().deserialize(this.layer.serialize());
    const { orientation, wrap } = serializeFlexLayoutOrientationOption(option.value);

    frameLayer.name = this.editorContext.generateLayerName(frameLayer, { ignoreLayers: [this.layer] });
    frameLayer.widthFluid = true;
    frameLayer.heightFluid = true;
    frameLayer.flexLayout = new FlexLayout();
    frameLayer.flexLayout.orientation = orientation;
    frameLayer.flexLayout.wrap = wrap;

    container.splice(index, 1, frameLayer);
    this.editorContext.markLayerContainerChanged(container, ViewEditorCustomizeSource.CustomizeLayer);

    this.editorContext.setCustomizingLayer(frameLayer);
  }
}

registerCustomizeLayerComponent(LayerType.Group, GroupCustomizeLayerComponent);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
var StorageSelectSource = /** @class */ (function (_super) {
    __extends(StorageSelectSource, _super);
    function StorageSelectSource(currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.allowEmpty = false;
        _this.emptyName = '---';
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    Object.defineProperty(StorageSelectSource.prototype, "emptyOption", {
        get: function () {
            return {
                value: null,
                name: this.emptyName
            };
        },
        enumerable: true,
        configurable: true
    });
    StorageSelectSource.prototype.image = function (uniqueName) {
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == uniqueName; });
        if (!resource) {
            return;
        }
        return resource.icon;
    };
    StorageSelectSource.prototype.getStorages = function () {
        var _this = this;
        return this.currentProjectStore.getFirst().pipe(map(function (result) {
            if (!result) {
                return [];
            }
            return result
                .getStorages(_this.currentEnvironmentStore.instance.uniqueName)
                .filter(function (item) { return item.storage.isConfigured(); });
        }));
    };
    StorageSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.getStorages().pipe(map(function (result) { return result.filter(function (item) { return !item.resource.demo; }); }), map(function (result) {
            _this.page += 1;
            var results = result
                .filter(function (item) {
                return isSet(searchQuery)
                    ? [item.resource.name, item.resource.uniqueName].some(function (str) {
                        return str.toLowerCase().includes(searchQuery.toLowerCase());
                    })
                    : true;
            })
                .map(function (item) {
                return {
                    value: { resource: item.resource.uniqueName, name: item.storage.uniqueName },
                    name: [item.resource.name, item.storage.name].join(' - '),
                    image: _this.image(item.resource.uniqueName)
                };
            });
            if (!_this.allowEmpty) {
                return results;
            }
            return [_this.emptyOption].concat(results);
        }));
    };
    StorageSelectSource.prototype.fetchByValue = function (value) {
        var _this = this;
        if (this.allowEmpty && value === null) {
            return of(this.emptyOption);
        }
        if (!value) {
            return of(undefined);
        }
        return this.getStorages().pipe(map(function (result) {
            var item = result.find(function (i) { return i.resource.uniqueName == value['resource'] && i.storage.uniqueName == value['name']; });
            if (!item) {
                return;
            }
            return {
                value: { resource: item.resource.uniqueName, name: item.storage.uniqueName },
                name: [item.resource.name, item.storage.name].join(' - '),
                image: _this.image(item.resource.uniqueName)
            };
        }));
    };
    StorageSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    StorageSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    StorageSelectSource.prototype.setStaticOptions = function (options) { };
    return StorageSelectSource;
}(SelectSource));
export { StorageSelectSource };

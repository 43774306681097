import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, ProjectGroupStore } from '@modules/projects';
import { View, ViewMapping } from '@modules/views';

import { CustomViewMapParametersComponent } from '../../components/custom-view-map-parameters/custom-view-map-parameters.component';

export interface CustomViewMapParametersEvent {
  mappings?: ViewMapping[];
  cancelled?: boolean;
}

@Injectable()
export class CustomViewMapParametersController {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  open(options: {
    sourceParameters: ParameterField[];
    view: View;
    mappings?: ViewMapping[];
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextElementPath?: (string | number)[];
    contextElementPaths?: (string | number)[][];
    contextTokenProvider?: ViewContextTokenProvider;
    analyticsSource?: string;
  }): Observable<CustomViewMapParametersEvent> {
    const obs$ = new ReplaySubject<CustomViewMapParametersEvent>();
    const injector = Injector.create(
      [
        {
          provide: ViewContext,
          useFactory: (currentEnvironmentStore: CurrentEnvironmentStore) => {
            if (options.context) {
              return options.context;
            } else {
              return new ViewContext(currentEnvironmentStore);
            }
          },
          deps: [CurrentEnvironmentStore]
        },
        {
          provide: ViewContextTokenProvider,
          useFactory: (viewContext: ViewContext, projectGroupStore: ProjectGroupStore) => {
            if (options.contextTokenProvider) {
              return options.contextTokenProvider;
            } else {
              return new ViewContextTokenProvider(viewContext, projectGroupStore);
            }
          },
          deps: [ViewContext, ProjectGroupStore]
        }
      ],
      this.injector
    );

    this.popupService.push({
      component: CustomViewMapParametersComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        sourceParameters: options.sourceParameters,
        view: options.view,
        mappings: options.mappings,
        context: options.context,
        contextElement: options.contextElement,
        contextElementPath: options.contextElementPath,
        contextElementPaths: options.contextElementPaths,
        analyticsSource: options.analyticsSource
      },
      outputs: {
        selectMapping: [
          result => {
            obs$.next({
              mappings: result
            });
          }
        ],
        cancelled: [
          () => {
            obs$.next({ cancelled: true });
          }
        ]
      },
      resolver: this.resolver,
      injector: injector
    });

    return obs$;
  }
}

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { DialogInstance, DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  ElementItem,
  processElementItemPageSegues,
  processElementItemResources,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import {
  MenuBlock,
  MenuBlockLayout,
  MenuGeneratorService,
  MenuItem,
  MenuItemAction,
  MenuItemActionType,
  MenuSettings,
  MenuSettingsService,
  MenuSettingsStore,
  SectionMenuItem,
  SimpleMenuItem
} from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  isResourceTypeItemCompatible,
  Project,
  Resource,
  ResourceTypeItem
} from '@modules/projects';
import {
  ResourceChoosePagesController,
  ResourceChoosePagesItem,
  ResourceChoosePagesOptions,
  ResourceChoosePagesResult
} from '@modules/projects-components';
import { Template } from '@modules/template';
import { PageTemplatesController, PageTemplatesResult } from '@modules/template-queries';
import { AppError, generateUUID, interpolateSimple, mapValuesDeep } from '@shared';

import { SelectResourcesController } from '../select-resources-controller/select-resources.controller';

export interface ApplyAdminPanelTemplateResult {
  viewSettings?: ViewSettings[];
  startPage?: ViewSettings;
  newPage?: boolean;
  resource?: Resource;
  cancelled?: boolean;
}

export interface ApplyPageTemplateResult {
  viewSettings: ViewSettings[];
  createdResources: Resource[];
}

@Injectable()
export class TemplateApplyService {
  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private menuGeneratorService: MenuGeneratorService,
    private menuSettingsService: MenuSettingsService,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private dialogService: DialogService,
    private selectResourcesController: SelectResourcesController,
    private resourceChoosePagesController: ResourceChoosePagesController,
    private pageTemplatesController: PageTemplatesController,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private analyticsService: UniversalAnalyticsService
  ) {}

  applyPage(
    project: Project,
    environment: Environment,
    template: Template,
    options: { useExistingUsedResource?: Resource; useDemoResources?: boolean; analyticsSource?: string } = {}
  ): Observable<ApplyPageTemplateResult> {
    const templateInstanceId = generateUUID();
    const usedPages = cloneDeep(template.usedPages);
    const menuItems = cloneDeep(template.menuItems);
    let firstMenuItem: SimpleMenuItem;
    const pages = template.pages.map(templatePage => {
      const page = cloneDeep(templatePage) as ViewSettings;

      if (page.uniqueName) {
        const match = page.uniqueName.match(/(.+)_(\d+)$/);
        const originalName = page.uniqueName;
        let uniqueName = originalName;
        let prefix = originalName;
        let counter = 1;

        if (match) {
          prefix = match[1];
          counter = parseInt(match[2], 10);
        }

        const viewSettings = this.viewSettingsStore.instance || [];

        while (viewSettings.find(item => item['unique_name'] == uniqueName)) {
          ++counter;
          uniqueName = [prefix, counter].join('_');
        }

        page.uniqueName = uniqueName;

        usedPages.forEach(item => {
          if (item.uniqueName == originalName) {
            item.uniqueName = page.uniqueName;
          }
        });

        const processMenuItems = (items: MenuItem[]) => {
          items.forEach(item => {
            if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
              if (item.action.pageUniqueName == originalName) {
                item.action.pageUniqueName = page.uniqueName;
              }

              if (!firstMenuItem) {
                firstMenuItem = item;
              }
            } else if (item instanceof SectionMenuItem) {
              processMenuItems(item.children);
            }
          });
        };

        processMenuItems(menuItems);
      }

      return page;
    });

    const processPageSegue = (pageName: string): string => {
      const usedPage = usedPages.find(item => item.token == pageName);

      if (!usedPage) {
        return pageName;
      }

      return usedPage.uniqueName;
    };

    processElementItemPageSegues(pages, processPageSegue);

    let loadingDialog: DialogInstance;
    const result: ApplyPageTemplateResult = {
      viewSettings: undefined,
      createdResources: undefined
    };

    return this.selectResourcesController
      .selectTemplateResourcesIfNeeded(project, environment, template, {
        useDemoResources: options.useDemoResources,
        analyticsSource: options.analyticsSource
      })
      .pipe(
        tap(selectResult => {
          if (selectResult.cancelled) {
            throw new AppError('Resource select canceled');
          }

          loadingDialog = this.dialogService.loading({
            title: 'Applying template',
            description: 'We are preparing template for you, please, wait...'
          });
        }),
        switchMap(selectResult => {
          const resourcesWithOptions = template.usedResources.filter(item => item.options);

          if (!resourcesWithOptions.length) {
            return of(selectResult.resources);
          }

          return this.selectResourcesController
            .createTemplateResources(project, environment, template, options.useExistingUsedResource)
            .pipe(
              map(usedResources => {
                result.createdResources = [
                  ...selectResult.resources.filter(item => item.created).map(item => item.resource),
                  ...usedResources.map(item => item.resource)
                ];
                return [...selectResult.resources, ...usedResources];
              })
            );
        }),
        tap(usedResources => {
          let pageResources: { [key: string]: any } = {};
          const processResource = (resourceName: string): string => {
            const replace = usedResources.find(item => item.token == resourceName);

            if (!replace) {
              return resourceName;
            }
            pageResources[replace.resource.uniqueName] = replace;
            return replace.resource.uniqueName;
          };

          pages.map(page => {
            pageResources = [];
            processElementItemResources(page, processResource);
            page.sourceTemplate = template.id;
            page.templateInstanceId = templateInstanceId;
            page.usedResources = values(pageResources).map(item => {
              return {
                type: item.type,
                typeItem: item.typeItem,
                name: item.resource.uniqueName
              };
            });
          });
        }),
        switchMap(() => {
          return this.viewSettingsService.createBulk(project.uniqueName, environment.uniqueName, pages);
        }),
        tap(viewSettings => {
          result.viewSettings = viewSettings;
        }),
        // map(result => {
        //   return result.find(item => firstMenuItem && item.uniqueName == firstMenuItem.uniqueName);
        // }),
        delayWhen(() => {
          // this.viewSettingsStore.projectName = project.uniqueName;
          return this.viewSettingsStore.getFirst(true);
        }),
        delayWhen(() => {
          // if (!value['add_to_menu']) {
          //   return of(undefined);
          // }

          const processMenuItems = (items: MenuItem[]) => {
            items.forEach(item => {
              if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
                const viewSettings = result.viewSettings.find(i => i.uniqueName == item.action.pageUniqueName);

                if (viewSettings) {
                  item.action.pageUid = viewSettings.uid;
                }
              } else if (item instanceof SectionMenuItem) {
                processMenuItems(item.children);
              }
            });
          };

          processMenuItems(menuItems);

          return this.menuSettingsStore.addItems(menuItems);
        }),
        map(() => {
          return result;
        }),
        tap(() => {
          if (loadingDialog) {
            loadingDialog.close();
          }
        }),
        catchError(error => {
          if (loadingDialog) {
            loadingDialog.close();
          }

          return throwError(error);
        })
      ) as any;
  }

  getAdminPanelPagesAuto(
    project: Project,
    resource: Resource,
    options: ResourceChoosePagesOptions = {}
  ): Observable<{ pages?: ViewSettings[]; startPage?: ViewSettings; newPage?: boolean; cancelled?: boolean }> {
    return this.modelDescriptionStore.getFirst().pipe(
      switchMap(allModelDescriptions => {
        const modelDescriptions = allModelDescriptions
          .filter(item => item.resource == resource.uniqueName)
          .filter(item => item.getQuery);

        if (!modelDescriptions.length) {
          return of<PageTemplatesResult>({ startPage: undefined });
        }

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseAdminPanelTemplateOpened, {
          Source: options.analyticsSource,
          ResourceTypeID: resource.typeItem.name,
          ResourceID: resource.uniqueName
        });

        return this.pageTemplatesController.open({
          resource: resource,
          cancelEnabled: options.optional,
          newPage: true,
          analyticsSource: options.analyticsSource
        });
      }),
      map(result => {
        return {
          pages: result.startPage ? [result.startPage] : undefined,
          startPage: result.startPage,
          newPage: result.newPage,
          cancelled: result.cancelled
        };
      })
    );
  }

  getAdminPanelCustomPages(
    project: Project,
    resource: Resource,
    pages: ViewSettings[],
    options: ResourceChoosePagesOptions = {}
  ): Observable<{ pages?: ViewSettings[]; cancelled?: boolean }> {
    return this.modelDescriptionStore.getFirst().pipe(
      switchMap(allModelDescriptions => {
        const modelDescriptions = allModelDescriptions.filter(item => item.resource == resource.uniqueName);
        const choosePages: ResourceChoosePagesItem<{ page: ViewSettings }>[] = pages.map(item => {
          const modelDescription = modelDescriptions.find(
            i => i.resource == resource.uniqueName && i.model == item.model
          );
          const name = [modelDescription ? modelDescription.verboseNamePlural : item.model, item.view].join(' - ');

          return {
            uniqueName: `page_${item.uniqueName}`,
            name: name,
            data: {
              page: item
            }
          };
        });

        return this.resourceChoosePagesController.choosePages<{ page: ViewSettings }>(resource, choosePages, options);
      }),
      map<ResourceChoosePagesResult<{ page: ViewSettings }>, { pages?: ViewSettings[]; cancelled?: boolean }>(
        result => {
          if (result.cancelled) {
            return { cancelled: true };
          }

          const tokens = {
            project: project.uniqueName,
            resource: resource.uniqueName,
            resource_name: resource.name
          };
          const resultPages = result.pages
            .map(item => item.data.page)
            .map(item => item.serialize())
            .map(item => mapValuesDeep(item, value => interpolateSimple(value, tokens)))
            .map(item => new ViewSettings().deserialize(item));

          return { pages: resultPages };
        }
      )
    );
  }

  applyAdminPanelTemplate(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: ResourceChoosePagesOptions = {}
  ): Observable<ApplyAdminPanelTemplateResult> {
    const cancelled = new AppError();

    // const resourcePagesTemplates = resourceTemplates.find(
    //   item => item.name == resource.typeItem.name && item.viewSettings != undefined
    // );
    // const resourcePages = resourcePagesTemplates
    //   ? resourcePagesTemplates.viewSettings.map(item => new ViewSettings().deserialize(item))
    //   : [];
    //
    // let obs: Observable<{ pages?: ViewSettings[]; cancelled?: boolean }>;
    //
    // if (resourcePages.length) {
    //   obs = this.getAdminPanelCustomPages(project, resource, resourcePages, options);
    // } else {
    //   obs = this.getAdminPanelPagesAuto(project, resource, options);
    // }

    let usedResource: Resource;
    let resourceObs: Observable<Resource>;

    if (options.resource) {
      resourceObs = of(options.resource);
    } else {
      resourceObs = this.selectResourcesController
        .selectResourcesIfNeeded(
          project,
          environment,
          [{ type: typeItem.resourceType, typeItem: typeItem, token: 'resource' }],
          { analyticsSource: options.analyticsSource }
        )
        .pipe(
          map(result => {
            if (result.cancelled) {
              throw cancelled;
            }

            return result.resources[0].resource;
          })
        );
    }

    return resourceObs.pipe(
      switchMap(resource => {
        usedResource = resource;

        if (usedResource.isSynced() && !usedResource.isSyncedFinished()) {
          return of<ApplyAdminPanelTemplateResult>({ resource: usedResource });
        } else if (usedResource.typeItem.installConfigurationNeeded) {
          return of<ApplyAdminPanelTemplateResult>({ resource: usedResource });
        } else {
          return this.getAdminPanelPagesAuto(project, resource, { ...options, modelPages: true }).pipe(
            map(result => {
              if (result.cancelled) {
                throw cancelled;
              }

              return {
                viewSettings: result.pages,
                startPage: result.startPage,
                newPage: result.newPage,
                resource: usedResource
              };
            })
          );
        }
      }),
      catchError(error => {
        if (error === cancelled) {
          return of({ cancelled: true });
        } else {
          return throwError(error);
        }
      })
    );
  }

  createMenuSettings(project: Project, resource: Resource, viewSettings: ViewSettings[]) {
    const menuItems = [
      ...viewSettings
        .filter(item => item.uniqueName)
        .map(item => {
          const menuItem = new SimpleMenuItem();

          menuItem.generateId();
          menuItem.title = item.name;

          menuItem.action = new MenuItemAction();
          menuItem.action.type = MenuItemActionType.Page;
          menuItem.action.pageUid = item.uid;
          menuItem.action.pageUniqueName = item.uniqueName;

          return menuItem;
        })
    ];

    if (!menuItems.length) {
      return;
    }

    const menuSettings = new MenuSettings();
    const section = new SectionMenuItem();

    section.generateId();
    section.title = resource.name;
    section.children = menuItems;

    const block = new MenuBlock();

    block.generateUid();
    block.layout = MenuBlockLayout.LeftWide;
    block.startItems = [section];

    menuSettings.project = project.uniqueName;
    menuSettings.blocks = [block];

    return menuSettings;
  }

  applyWidget(
    project: Project,
    environment: Environment,
    template: Template,
    resource?: Resource,
    options: { useDemoResources?: boolean } = {}
  ): Observable<ElementItem> {
    const element = cloneDeep(template.element) as ElementItem;
    const templateSingleResource = template.usedResources.length == 1 ? template.usedResources[0] : undefined;
    const singleDemoResource = templateSingleResource
      ? this.currentEnvironmentStore.resources.find(item =>
          isResourceTypeItemCompatible(item.typeItem, templateSingleResource.typeItem)
        )
      : undefined;
    let obs: Observable<{ token: string; resource: Resource }[]>;

    if (templateSingleResource && resource) {
      obs = of([{ token: templateSingleResource.token, resource: resource }]);
    } else if (templateSingleResource && singleDemoResource) {
      obs = of([{ token: templateSingleResource.token, resource: singleDemoResource }]);
    } else {
      obs = this.selectResourcesController
        .selectTemplateResourcesIfNeeded(project, environment, template, {
          useDemoResources: options.useDemoResources,
          analyticsSource: 'builder_component_templates'
        })
        .pipe(
          filter(result => !result.cancelled),
          map(result => result.resources)
        );
    }

    return obs.pipe(
      map(selected => {
        const processResource = (resourceName: string): string => {
          const replace = selected.find(item => item.token == resourceName);

          if (!replace) {
            return resourceName;
          }

          return replace.resource.uniqueName;
        };

        processElementItemResources(element, processResource);

        return element;
      })
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { FigmaExternalImport, FigmaExternalImportListStore, FigmaService } from '@modules/figma-integration';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';
import { capitalize, isSet } from '@shared';

@Component({
  selector: 'app-import-figma-node',
  templateUrl: './import-figma-node.component.html',
  providers: [FigmaExternalImportListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportFigmaNodeComponent implements OnInit, OnDestroy {
  @Output() importView = new EventEmitter<View>();
  @Output() cancelled = new EventEmitter<void>();

  importCode: string;
  importCodeLoading = false;

  items: FigmaExternalImport[];
  loading = false;
  hasMore = true;

  constructor(
    @Optional() protected popupComponent: BasePopupComponent,
    public store: FigmaExternalImportListStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private figmaService: FigmaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadImportCode();

    this.store.items$.pipe(untilDestroyed(this)).subscribe(value => {
      this.items = value;
      this.cd.markForCheck();
    });

    this.store.loading$.pipe(untilDestroyed(this)).subscribe(value => {
      this.loading = value;
      this.cd.markForCheck();
    });

    this.store.hasMore$.pipe(untilDestroyed(this)).subscribe(value => {
      this.hasMore = value;
      this.cd.markForCheck();
    });

    this.loadImports();
  }

  ngOnDestroy(): void {}

  loadImportCode() {
    this.importCodeLoading = true;
    this.cd.markForCheck();

    this.figmaService
      .getImportCode(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.importCode = result;
          this.importCodeLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.importCodeLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  loadImports() {
    this.store.reset(1);
    this.store.getNext().subscribe();
  }

  loadMore() {
    this.store.getNext().subscribe();
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel)
          ? localize('{0} was copied to clipboard', [capitalize(contentLabel)])
          : undefined;
        this.notificationService.info(localize('Copied'), description);
      });
  }
}

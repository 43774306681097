import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ActionOutputArray, ActionOutputControl } from '@modules/fields';
import { isSet } from '@shared';

@Component({
  selector: 'app-action-outputs-edit',
  templateUrl: './action-outputs-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionOutputsEditComponent implements OnInit {
  @Input() control: ActionOutputArray;
  @Input() addLabel = 'Add Action';
  @Input() addDefaults = {};
  @Input() addBaseName = 'action';
  @Input() analyticsSource: string;

  constructor() {}

  ngOnInit() {}

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.control.controls.map(item => {
      const value = item.controls.name.value;
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addItem() {
    const name = this.getDistinctName(this.addBaseName);
    this.control.appendControl(undefined, {
      name: name
    });
  }

  deleteItem(control: ActionOutputControl) {
    this.control.removeControl(control);
  }

  dragDrop(event: CdkDragDrop<ActionOutputControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}

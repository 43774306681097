var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { isSet } from '@shared';
import { CustomViewTemplatesComponent } from '../../components/custom-view-templates/custom-view-templates.component';
var CustomViewTemplatesController = /** @class */ (function () {
    function CustomViewTemplatesController(popupService, injector, resolver, analyticsService) {
        this.popupService = popupService;
        this.injector = injector;
        this.resolver = resolver;
        this.analyticsService = analyticsService;
    }
    CustomViewTemplatesController.prototype.chooseTemplate = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var obs$ = new ReplaySubject();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.Opened, {
            Source: options.analyticsSource
        });
        this.popupService.push({
            component: CustomViewTemplatesComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentOrange: true,
            inputs: __assign({}, (options.initialFilter && { initialFilter: options.initialFilter }), (isSet(options.nameEditingEnabled) && { nameEditingEnabled: options.nameEditingEnabled }), (isSet(options.viewCustomizeEnabled) && { viewCustomizeEnabled: options.viewCustomizeEnabled }), (isSet(options.viewCreateEnabled) && { viewCreateEnabled: options.viewCreateEnabled }), { stateSelectedEnabled: options.stateSelectedEnabled }, (isSet(options.componentLabel) && { componentLabel: options.componentLabel }), { analyticsSource: options.analyticsSource }),
            outputs: {
                selectView: [
                    function (result) {
                        obs$.next({ view: result.view, template: result.template });
                        _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.ViewApplied, {
                            Name: result.template ? result.template.name : undefined,
                            New: !result.template,
                            Source: options.analyticsSource
                        });
                    }
                ],
                cancelled: [
                    function () {
                        obs$.next({ cancelled: true });
                        _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.Cancelled, {
                            Source: options.analyticsSource
                        });
                    }
                ]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return obs$;
    };
    CustomViewTemplatesController.prototype.setTemplateView = function (view, options) {
        if (options === void 0) { options = {}; }
        var obs$ = new ReplaySubject();
        this.popupService.push({
            component: CustomViewTemplatesComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentOrange: true,
            inputs: __assign({ updateOnSelect: true, setTemplateView: view, stateSelectedEnabled: options.stateSelectedEnabled }, (isSet(options.componentLabel) && { componentLabel: options.componentLabel })),
            outputs: {
                templateUpdated: [
                    function (result) {
                        obs$.next({ template: result });
                    }
                ],
                cancelled: [
                    function () {
                        obs$.next({ cancelled: true });
                    }
                ]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return obs$;
    };
    return CustomViewTemplatesController;
}());
export { CustomViewTemplatesController };

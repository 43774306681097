var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import { WorkflowStepType } from '@modules/workflow';
import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';
var ForkWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(ForkWorkflowStepComponent, _super);
    function ForkWorkflowStepComponent(workflowEditContext, cd) {
        var _this = _super.call(this, undefined, workflowEditContext, cd) || this;
        _this.trackStepItem = (function () {
            return function (i, item) {
                return item ? item.uid : i;
            };
        })();
        return _this;
    }
    ForkWorkflowStepComponent.prototype.ngOnInit = function () {
        this.updateIcon();
        this.trackExecuteStatus({ successOnStart: true });
        this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
    };
    ForkWorkflowStepComponent.prototype.ngOnChanges = function (changes) {
        if (changes.step) {
            this.updateIcon();
        }
    };
    ForkWorkflowStepComponent.prototype.updateIcon = function () {
        this.icon = this.step.getIcon();
    };
    return ForkWorkflowStepComponent;
}(WorkflowStepComponent));
export { ForkWorkflowStepComponent };
registerWorkflowStepComponent(WorkflowStepType.Fork, ForkWorkflowStepComponent);

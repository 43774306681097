import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { ColumnsModelListStore } from '@modules/list';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { paramsToGetQueryOptions } from '@modules/resources';

import { CalendarType } from '../../data/calendar-type';
import { CalendarBaseComponent } from '../calendar-base/calendar-base.component';
import { DayOfWeek } from '../calendar-month/calendar-month.component';
import { CalendarState } from '../calendar/calendar-state';

export interface HourDayOfWeekItem {
  date: moment.Moment;
  weekend: boolean;
  key: string;
}

export interface HourDayOfWeek {
  date: moment.Moment;
  items: HourDayOfWeekItem[];
}

@Component({
  selector: 'app-calendar-week',
  templateUrl: './calendar-week.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnsModelListStore]
})
export class CalendarWeekComponent extends CalendarBaseComponent implements OnInit, OnDestroy {
  weekDays: DayOfWeek[] = [];
  hours: HourDayOfWeek[] = [];
  weekDayItems = {};
  tintStyles = TintStyle;

  constructor(
    injector: Injector,
    actionControllerService: ActionControllerService,
    listStore: ColumnsModelListStore,
    notificationService: NotificationService,
    cd: ChangeDetectorRef
  ) {
    super(injector, actionControllerService, listStore, notificationService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.listStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
      this.weekDayItems = items
        ? groupBy(items, item => moment(item.model.getAttribute(this.listState.settings.dateField)).format('d'))
        : {};
      this.cd.markForCheck();
    });
  }

  fetch(state: CalendarState) {
    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);

    queryOptions.filters = [...queryOptions.filters, ...state.filters, ...this.dateFilterFilters(state)];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = queryOptions;
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;

    this.getItems();
    this.updateVisibleColumns(state);
    this.updateHours(state);

    this.prevDate = state.date;
  }

  get now() {
    return moment();
  }

  updateHours(state: CalendarState) {
    if (!state.date) {
      this.weekDays = [];
      this.hours = [];
      this.cd.markForCheck();
      return;
    }

    this.weekDays = range(0, 6 + 1).map(day => {
      const date = state.date.clone().startOf('isoWeek').add(day, 'days');

      return {
        date: date,
        weekend: [6, 0].includes(date.day()),
        today: date.isSame(this.now, 'day') && date.isSame(this.now, 'month') && date.isSame(this.now, 'year')
      };
    });

    this.hours = range(0, 24).map(hour => {
      return {
        date: state.date.clone().startOf('isoWeek').add(hour, 'hours'),
        items: range(0, 7).map(day => {
          const date = state.date.clone().startOf('isoWeek').add(hour, 'hours').add(day, 'days');

          return {
            date: date,
            weekend: [6, 0].includes(date.day()),
            key: date.format(this.itemsGroupBy())
          };
        })
      };
    });

    this.cd.markForCheck();
  }

  dayParams(date) {
    const params = {};
    params[TYPE_PARAM] = CalendarType.Day;
    params[DATE_PARAM] = date.toISOString();
    return this.applyParams(params);
  }

  dateFilterFilters(state: CalendarState): FilterItem2[] {
    const firstDay = state.date.clone().startOf('isoWeek');
    const lastDay = state.date.clone().endOf('isoWeek');

    return [
      new FilterItem2({
        field: [state.dateField],
        lookup: gteFieldLookup,
        value: firstDay.toISOString()
      }),
      new FilterItem2({
        field: [state.dateField],
        lookup: lteFieldLookup,
        value: lastDay.toISOString()
      })
    ];
  }

  itemsGroupBy(): string {
    return 'DD.MM.YYYY HH';
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TipsModule } from '@common/tips';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ColorOptionsComponent } from './components/color-options/color-options.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';

@NgModule({
  imports: [CommonModule, OverlayModule, SharedModule, UiModule, SidebarModule, ReactiveFormsModule, TipsModule],
  declarations: [ColorSelectorComponent, ColorPickerComponent, ColorOptionsComponent],
  exports: [ColorSelectorComponent, ColorPickerComponent, ColorOptionsComponent]
})
export class ColorsComponentsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GlobalsService } from './services/globals/globals.service';

@NgModule({
  imports: [CommonModule],
  declarations: []
})
export class GlobalsModule {
  constructor(private globalsService: GlobalsService) {}
}

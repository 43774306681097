<div
  *ngIf="visible || (customizeEnabled$ | async)"
  class="widget-element widget-element_manual-margin"
  [class.widget-element_flexible-height]="true"
>
  <app-auto-widget
    [widget]="element.widget"
    [context]="context"
    [contextElement]="viewContextElement"
    [fill]="true"
    [accentColor]="accentColor"
  ></app-auto-widget>
</div>

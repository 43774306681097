import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, map, share } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { AllProjectSettings, CrispIntegration, ProjectSettingsStore } from '@modules/all-project-settings';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectSettings,
  ProjectSettingsName,
  ProjectSettingsService
} from '@modules/projects';
import { isSet } from '@shared';

@Injectable()
export class CrispIntegrationEditPopupForm extends FormGroup implements OnDestroy {
  value: CrispIntegration;

  controls: {
    id: FormControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectSettingsService: ProjectSettingsService,
    private projectSettingsStore: ProjectSettingsStore,
    private formUtils: FormUtils
  ) {
    super({
      id: new FormControl('')
    });
  }

  ngOnDestroy(): void {}

  init(value: CrispIntegration = {}) {
    this.value = value;
    this.controls.id.patchValue(value ? value.id : undefined);
    this.markAsPristine();
  }

  submit(): Observable<CrispIntegration> {
    this.markAsDirty();

    let submitSettings: ProjectSettings;
    const instance = new AllProjectSettings();

    if (isSet(this.controls.id.value)) {
      instance.crisp = { id: this.controls.id.value };

      submitSettings = instance.serialize([ProjectSettingsName.Crisp])[0];
    } else {
      instance.crisp = this.value;

      submitSettings = instance.serialize([ProjectSettingsName.Crisp])[0];
      submitSettings.deleted = true;
    }

    return this.projectSettingsService
      .createBulk(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, [
        submitSettings
      ])
      .pipe(
        delayWhen(() => this.projectSettingsStore.getFirst(true)),
        map(result => new AllProjectSettings().deserialize(result)),
        map(result => result.crisp),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        }),
        share()
      );
  }
}

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { RoutingService } from '@modules/routing';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/notifications/services/notification/notification.service";
import * as i2 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i3 from "../../../routing/services/routing/routing.service";
var ResponseService = /** @class */ (function () {
    function ResponseService(notificationService, dialogService, routing) {
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.routing = routing;
        this.activitiesMaxExceededWarning = false;
        // TODO: fix
        // this.currentProjectStore.instance$.subscribe(() => this.activitiesMaxExceededWarning = false);
    }
    ResponseService.prototype.handleWarning = function (warning) {
        var _this = this;
        if (warning == 'activities_max_exceeded') {
            if (!this.activitiesMaxExceededWarning) {
                setTimeout(function () {
                    _this.dialogService
                        .dialog({
                        title: 'Activities Limit Exceeded',
                        description: 'Your monthly limit exceeded. Please upgrade your plan to continue using Jet.',
                        buttons: [
                            {
                                name: 'ok',
                                label: 'OK',
                                type: DialogButtonType.Default,
                                hotkey: DialogButtonHotkey.Cancel
                            },
                            {
                                name: 'upgrade',
                                label: 'Upgrade',
                                type: DialogButtonType.Primary,
                                hotkey: DialogButtonHotkey.Submit
                            }
                        ]
                    })
                        .subscribe(function (result) {
                        if (result.button == 'upgrade') {
                            _this.routing.navigateApp(['project', 'billing']);
                        }
                    });
                }, 1000);
                this.activitiesMaxExceededWarning = true;
            }
        }
        else if (warning == 'no_permission') {
            this.notificationService.warning('No Access', "Sorry, you don't have enough permissions");
        }
        else {
            this.notificationService.warning(warning);
        }
    };
    ResponseService.ngInjectableDef = i0.defineInjectable({ factory: function ResponseService_Factory() { return new ResponseService(i0.inject(i1.NotificationService), i0.inject(i2.DialogService), i0.inject(i3.RoutingService)); }, token: ResponseService, providedIn: "root" });
    return ResponseService;
}());
export { ResponseService };

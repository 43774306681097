export const mailgunResourceParamsParams: Object = {
  base_http_query: {
    method: 'GET',
    auth_type: 'basic_auth',
    auth_params: {},
    body_type: 'JSON',
    body: '',
    headers: [
      {
        name: 'Authorization',
        value: 'Basic {-basic_auth-}'
      }
    ],
    query_params: [],
    response_type: 'json',
    request_tokens: {}
  }
};

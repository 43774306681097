<div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
  <div *ngFor="let item of control.controls; let i = index" cdkDrag class="sidebar-list__item">
    <app-action-outputs-edit-item [control]="item" (delete)="deleteItem(item)"></app-action-outputs-edit-item>
  </div>

  <div *ngIf="!control.controls.length" class="sidebar-list__item">
    <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="button__icon icon-power"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">Action</div>
        <div class="sidebar-list-item__description">Not specified</div>
      </div>
    </div>
  </div>

  <div class="sidebar-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon-plus button__icon button__icon_left"></span>
      <span class="button__label">{{ addLabel }}</span>
    </a>
  </div>
</div>

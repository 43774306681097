var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var FilterElementInput = /** @class */ (function () {
    function FilterElementInput() {
    }
    FilterElementInput.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.tooltip = data['tooltip'];
        this.weight = data['weight'];
        if (data['settings']) {
            this.settings = data['settings'];
            this.settings.valueInput = data['settings']['valueInput']
                ? new Input().deserialize(data['settings']['valueInput'])
                : undefined;
        }
        else {
            this.settings = undefined;
        }
        return this;
    };
    FilterElementInput.prototype.serialize = function () {
        return {
            name: this.name,
            settings: this.settings
                ? __assign({}, this.settings, (this.settings.valueInput && { valueInput: this.settings.valueInput.serialize() })) : undefined,
            tooltip: this.tooltip,
            weight: this.weight
        };
    };
    return FilterElementInput;
}());
export { FilterElementInput };
export var FilterStyle;
(function (FilterStyle) {
    FilterStyle["Wrap"] = "wrap";
    FilterStyle["Background"] = "background";
})(FilterStyle || (FilterStyle = {}));
var FilterElementItem = /** @class */ (function (_super) {
    __extends(FilterElementItem, _super);
    function FilterElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Filter;
        _this.elements = [];
        _this.elementInputs = [];
        _this.style = FilterStyle.Background;
        return _this;
    }
    FilterElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['elements']) {
            this.elements = this.params['elements'];
        }
        if (this.params['element_inputs']) {
            this.elementInputs = this.params['element_inputs'].map(function (item) { return new FilterElementInput().deserialize(item); });
        }
        if (this.params['style']) {
            this.style = this.params['style'];
        }
        return this;
    };
    FilterElementItem.prototype.serialize = function () {
        this.params = {
            elements: this.elements,
            element_inputs: this.elementInputs.map(function (item) { return item.serialize(); }),
            style: this.style
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(FilterElementItem.prototype, "analyticsName", {
        get: function () {
            return 'filter';
        },
        enumerable: true,
        configurable: true
    });
    FilterElementItem.prototype.defaultName = function () {
        return 'Filter';
    };
    return FilterElementItem;
}(ElementItem));
export { FilterElementItem };
registerElementForType(ElementType.Filter, FilterElementItem);

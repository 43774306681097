import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
var DropListService = /** @class */ (function () {
    function DropListService() {
        this.dropLists = [];
        this.dragging$ = new BehaviorSubject(false);
        this.draggingDropList$ = new BehaviorSubject(undefined);
        this.draggingDragStart$ = new BehaviorSubject(undefined);
        this.dropped$ = new Subject();
    }
    DropListService.prototype.registerDropList = function (dropList) {
        var index = this.dropLists.indexOf(dropList);
        if (index == -1) {
            this.dropLists.push(dropList);
        }
    };
    DropListService.prototype.unregisterDropList = function (dropList) {
        var index = this.dropLists.indexOf(dropList);
        if (index != -1) {
            this.dropLists.splice(index, 1);
        }
    };
    DropListService.ngInjectableDef = i0.defineInjectable({ factory: function DropListService_Factory() { return new DropListService(); }, token: DropListService, providedIn: "root" });
    return DropListService;
}());
export { DropListService };

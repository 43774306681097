import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import startCase from 'lodash/startCase';
import { Matrix } from 'mathjs';
import * as moment from 'moment';
import { slugify } from 'transliteration';

import { ViewSettings } from '@modules/customize';
import {
  Environment,
  hasEnvironmentModelPermission,
  hasEnvironmentPagePermission,
  hasEnvironmentPermission,
  ProjectPermissions
} from '@modules/projects';
import { EMPTY, format as formatFn, generateHash, generateUUID, isSet, parseTime } from '@shared';

import { NumberValueFormat } from '../data/value-format.interface';
import { registerMathCustomFunction } from './math-custom-functions.const';
import { applyStaticValueFormat } from './value-format';

function CONCAT(...args): string {
  return args.reduce((acc, item) => {
    if (item === null || item === undefined) {
      item = '';
    }

    return `${acc}${String(item)}`;
  }, '');
}
CONCAT.toString = () => 'CONCAT';

function FORMAT(template: string, ...args: any): string {
  return formatFn(template, args || []);
}
FORMAT.toString = () => 'FORMAT';

function POW(base: number, exponent: number): number {
  return Math.pow(base, exponent);
}
POW.toString = () => 'POW';

function ROUND(value: number, places = 0): number {
  return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
}
ROUND.toString = () => 'ROUND';

function CEIL(base: number, factor = 1): number {
  return Math.ceil(base / factor) * factor;
}
CEIL.toString = () => 'CEIL';

function FLOOR(base: number, factor = 1): number {
  return Math.floor(base / factor) * factor;
}
FLOOR.toString = () => 'FLOOR';

export function LOG(value: number, base = 10): number {
  return Math.log(value) / Math.log(base);
}
LOG.toString = () => 'LOG';

function EXP(exponent: number): number {
  return Math.exp(exponent);
}
EXP.toString = () => 'EXP';

function ABS(value: number): number {
  return Math.abs(value);
}
ABS.toString = () => 'ABS';

function SQRT(value: number): number {
  return Math.sqrt(value);
}
SQRT.toString = () => 'SQRT';

function FIX(number: number, places = 0): string {
  return ROUND(number, places).toFixed(places);
}
FIX.toString = () => 'FIX';

function IF(logical_expression: boolean, value_if_true: any, value_if_false: any): any {
  return logical_expression ? value_if_true : value_if_false;
}
IF.toString = () => 'IF';

function EQ(value1: any, value2: any): boolean {
  return value1 == value2;
}
EQ.toString = () => 'EQ';

function AND(logical_expression1: boolean, logical_expression2: boolean): boolean {
  return !!logical_expression1 && !!logical_expression2;
}
AND.toString = () => 'AND';

function NOT(logical_expression: boolean): boolean {
  return !logical_expression;
}
NOT.toString = () => 'NOT';

function OR(logical_expression1: boolean, logical_expression2: boolean): boolean {
  return !!logical_expression1 || !!logical_expression2;
}
OR.toString = () => 'OR';

function XOR(logical_expression1: boolean, logical_expression2: boolean): boolean {
  return (!!logical_expression1 && !logical_expression2) || (!logical_expression1 && !!logical_expression2);
}
XOR.toString = () => 'XOR';

function SIZE(value: Matrix): number {
  if (isArray(value)) {
    return value.length;
  } else if (typeof value === 'string') {
    return value.length;
  } else {
    return value.toArray().length;
  }
}
SIZE.toString = () => 'SIZE';

function EMPTY_FUNC(value: Matrix): boolean {
  if (isArray(value)) {
    return value.length === 0;
  } else if (typeof value === 'string') {
    return value.length === 0;
  } else {
    return value.toArray().length === 0;
  }
}
EMPTY_FUNC.toString = () => 'EMPTY';

function IS_NULL(value: any): boolean {
  return value === null;
}
IS_NULL.toString = () => 'IS_NULL';

function SORT(value: Matrix): any[] {
  return value.toArray().sort();
}
SORT.toString = () => 'SORT';

function MAP(value: Matrix | any[], callback: (item: any) => any): any[] {
  const valueArray = isArray(value) ? value : value.toArray();
  if (callback['signatures']) {
    callback = callback['signatures']['any'];
  }
  return valueArray.map(callback);
}
MAP.toString = () => 'MAP';

function FILTER(value: Matrix, callback: (item: any) => any): any[] {
  const valueArray = isArray(value) ? value : value.toArray();
  if (callback['signatures']) {
    callback = callback['signatures']['any'];
  }
  return valueArray.filter(callback);
}
FILTER.toString = () => 'FILTER';

export function UUID() {
  return generateUUID();
}
UUID.toString = () => 'UUID';

export function HASH(length = 32) {
  return generateHash(length);
}
HASH.toString = () => 'HASH';

export function RANDOM(low: number, high: number): number {
  return Math.floor(Math.random() * (high - low + 1)) + low;
}
RANDOM.toString = () => 'RANDOM';

export function MIN(...values: number[]): number {
  return Math.min(...values);
}
MIN.toString = () => 'MIN';

export function MAX(...values: number[]): number {
  return Math.max(...values);
}
MAX.toString = () => 'MAX';

export function AVERAGE(...values: number[]): number {
  return SUM(...values) / SIZE(values);
}
AVERAGE.toString = () => 'AVERAGE';

export function SUM(...values: number[]): number {
  return values.reduce((a, b) => {
    a = NUMBER(a);
    b = NUMBER(b);

    if (isNaN(a)) {
      a = 0;
    }

    if (isNaN(b)) {
      b = 0;
    }

    return a + b;
  }, 0);
}
SUM.toString = () => 'SUM';

function STRING(value: any): string {
  return String(value);
}
STRING.toString = () => 'STRING';

function NUMBER(value: any): number {
  return parseFloat(value);
}
NUMBER.toString = () => 'NUMBER';

function NUMBER_FORMAT(value: number, numberFormat?: NumberValueFormat, numberFraction?: number): string {
  if (!isSet(value)) {
    return (value as unknown) as string;
  }

  if (typeof numberFormat === 'string') {
    numberFormat = numberFormat.toLowerCase() as NumberValueFormat;
  }

  return applyStaticValueFormat(value, {
    numberFormat: numberFormat || NumberValueFormat.Default,
    numberFraction: numberFraction
  });
}
NUMBER_FORMAT.toString = () => 'NUMBER_FORMAT';

function parseJSON(value: string): Object {
  try {
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
}
parseJSON.toString = () => 'JSON';

function UPPER(value: any): string {
  if (!isSet(value)) {
    return (value as unknown) as string;
  }

  return String(value).toUpperCase();
}
UPPER.toString = () => 'UPPER';

function LOWER(value: any): string {
  if (!isSet(value)) {
    return (value as unknown) as string;
  }

  return String(value).toLowerCase();
}
LOWER.toString = () => 'LOWER';

function TITLE_CASE(value: any): string {
  if (!isSet(value)) {
    return (value as unknown) as string;
  }

  return startCase(value);
}
TITLE_CASE.toString = () => 'TITLE_CASE';

function SLUGIFY(value: any, separator = '_'): string {
  let result = slugify(String(value), { trim: true, separator: separator });

  if (['-', '_'].includes(separator)) {
    const regex = new RegExp(`${separator}+`, 'g');
    result = result.replace(regex, separator);
  }

  return result;
}
SLUGIFY.toString = () => 'SLUGIFY';

function GET(object: any, property: any, defaultValue?: any): string {
  return isSet(object) ? object[property] || defaultValue : defaultValue;
}
GET.toString = () => 'GET';

function CONTAINS(object: any, value: any, caseInsensitive = false): boolean {
  if (!object) {
    return false;
  }

  if (isArray(object)) {
    return (
      object.find(item => {
        if (typeof item === 'string' && caseInsensitive) {
          return item.toLowerCase() == String(value).toLowerCase();
        } else {
          return item == value;
        }
      }) !== undefined
    );
  } else if (typeof object === 'string') {
    value = String(value);

    if (caseInsensitive) {
      object = object.toLowerCase();
      value = value.toLowerCase();
    }

    return object.indexOf(value) !== -1;
  } else {
    if (caseInsensitive) {
      return (
        keys(object).find(item => {
          if (typeof item === 'string') {
            return item.toLowerCase() == String(value).toLowerCase();
          } else {
            return item == value;
          }
        }) !== undefined
      );
    } else {
      return object[value] !== undefined;
    }
  }
}
CONTAINS.toString = () => 'CONTAINS';

export function ANY(...values: any[]): any {
  return values.find(item => isSet(item));
}
ANY.toString = () => 'ANY';

function DATE(value: string | moment.Moment, format?: string): moment.Moment {
  if (!value) {
    return;
  }

  if (moment.isMoment(value)) {
    return value;
  }

  const date = moment(value, format);

  if (!date.isValid()) {
    return;
  }

  return date;
}
DATE.toString = () => 'DATE';

function wrapTime(value: moment.Moment): moment.Moment {
  value.toString = () => {
    if (value.isValid()) {
      return value.format('HH:mm:ss');
    } else {
      return 'Invalid time';
    }
  };
  return value;
}

function TIME(value: string | moment.Moment, format?: string): moment.Moment {
  if (!value) {
    return;
  }

  const dt = parseTime(value, format);
  return wrapTime(dt);
}
TIME.toString = () => 'TIME';

export function NOW(): moment.Moment {
  return moment();
}
NOW.toString = () => 'NOW';

function DAY(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.date();
}
DAY.toString = () => 'DAY';

function WEEKDAY(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.isoWeekday();
}
WEEKDAY.toString = () => 'WEEKDAY';

function MONTH(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.month() + 1;
}
MONTH.toString = () => 'MONTH';

function QUARTER(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.quarter();
}
QUARTER.toString = () => 'QUARTER';

function YEAR(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.year();
}
YEAR.toString = () => 'YEAR';

function HOUR(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.hour();
}
HOUR.toString = () => 'HOUR';

function MINUTE(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.minute();
}
MINUTE.toString = () => 'MINUTE';

function SECOND(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.second();
}
SECOND.toString = () => 'SECOND';

function MILLISECOND(value: moment.Moment): number {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.millisecond();
}
MILLISECOND.toString = () => 'MILLISECOND';

function IS_DATE_AFTER(value1: moment.Moment, value2: moment.Moment, granularity?: moment.unitOfTime.StartOf): boolean {
  value1 = DATE(value1);
  value2 = DATE(value2);

  if (!value1) {
    return;
  } else if (!value2) {
    return;
  }

  return value1.isAfter(value2, granularity);
}
IS_DATE_AFTER.toString = () => 'IS_DATE_AFTER';

function IS_DATE_BEFORE(
  value1: moment.Moment,
  value2: moment.Moment,
  granularity?: moment.unitOfTime.StartOf
): boolean {
  value1 = DATE(value1);
  value2 = DATE(value2);

  if (!value1) {
    return;
  } else if (!value2) {
    return;
  }

  return value1.isBefore(value2, granularity);
}
IS_DATE_BEFORE.toString = () => 'IS_DATE_BEFORE';

function IS_DATE_SAME(value1: moment.Moment, value2: moment.Moment, granularity?: moment.unitOfTime.StartOf): boolean {
  value1 = DATE(value1);
  value2 = DATE(value2);

  if (!value1) {
    return;
  } else if (!value2) {
    return;
  }

  return value1.isSame(value2, granularity);
}
IS_DATE_SAME.toString = () => 'IS_DATE_SAME';

function DATE_FORMAT(value: moment.Moment, format: string): string {
  value = DATE(value);

  if (!value) {
    return;
  }

  return value.format(format);
}
DATE_FORMAT.toString = () => 'DATE_FORMAT';

function DATE_ADD(value: moment.Moment, amount: number, unit: moment.unitOfTime.DurationConstructor): moment.Moment {
  value = DATE(value);

  if (!value || !isSet(amount) || !isSet(unit)) {
    return;
  }

  return value.clone().add(amount, unit);
}
DATE_ADD.toString = () => 'DATE_ADD';

function DATE_SUBTRACT(
  value: moment.Moment,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor
): moment.Moment {
  value = DATE(value);

  if (!value || !isSet(amount) || !isSet(unit)) {
    return;
  }

  return value.clone().subtract(amount, unit);
}
DATE_SUBTRACT.toString = () => 'DATE_SUBTRACT';

function DATE_DIFF(
  value1: moment.Moment,
  value2: moment.Moment,
  unit: moment.unitOfTime.DurationConstructor,
  decimal = false
): number {
  value1 = DATE(value1);
  value2 = DATE(value2);

  if (!value1 || !value2) {
    return;
  }

  return value2.diff(value1, unit, decimal);
}
DATE_DIFF.toString = () => 'DATE_DIFF';

function DATE_START_OF(value: moment.Moment, unit: moment.unitOfTime.StartOf): moment.Moment {
  value = DATE(value);

  if (!value || !isSet(unit)) {
    return;
  }

  return value.clone().startOf(unit);
}
DATE_START_OF.toString = () => 'DATE_START_OF';

function DATE_END_OF(value: moment.Moment, unit: moment.unitOfTime.StartOf): moment.Moment {
  value = DATE(value);

  if (!value || !isSet(unit)) {
    return;
  }

  return value.clone().endOf(unit);
}
DATE_END_OF.toString = () => 'DATE_END_OF';

function IS_TIME_AFTER(value1: moment.Moment, value2: moment.Moment, granularity?: moment.unitOfTime.StartOf): boolean {
  value1 = TIME(value1);
  value2 = TIME(value2);
  return IS_DATE_AFTER(value1, value2, granularity);
}
IS_TIME_AFTER.toString = () => 'IS_TIME_AFTER';

function IS_TIME_BEFORE(
  value1: moment.Moment,
  value2: moment.Moment,
  granularity?: moment.unitOfTime.StartOf
): boolean {
  value1 = TIME(value1);
  value2 = TIME(value2);
  return IS_DATE_BEFORE(value1, value2, granularity);
}
IS_TIME_BEFORE.toString = () => 'IS_TIME_BEFORE';

function IS_TIME_SAME(value1: moment.Moment, value2: moment.Moment, granularity?: moment.unitOfTime.StartOf): boolean {
  value1 = TIME(value1);
  value2 = TIME(value2);
  return IS_DATE_SAME(value1, value2, granularity);
}
IS_TIME_SAME.toString = () => 'IS_TIME_SAME';

function TIME_FORMAT(value: moment.Moment, format: string): string {
  value = TIME(value);
  return DATE_FORMAT(value, format);
}
TIME_FORMAT.toString = () => 'TIME_FORMAT';

function TIME_ADD(value: moment.Moment, amount: number, unit: moment.unitOfTime.DurationConstructor): moment.Moment {
  value = TIME(value);
  return DATE_ADD(value, amount, unit);
}
TIME_ADD.toString = () => 'TIME_ADD';

function TIME_SUBTRACT(
  value: moment.Moment,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor
): moment.Moment {
  value = TIME(value);
  return DATE_SUBTRACT(value, amount, unit);
}
TIME_SUBTRACT.toString = () => 'TIME_SUBTRACT';

function TIME_DIFF(
  value1: moment.Moment,
  value2: moment.Moment,
  unit: moment.unitOfTime.DurationConstructor,
  decimal = false
): number {
  value1 = TIME(value1);
  value2 = TIME(value2);
  return DATE_DIFF(value1, value2, unit, decimal);
}
TIME_DIFF.toString = () => 'TIME_DIFF';

function TIME_START_OF(value: moment.Moment, unit: moment.unitOfTime.StartOf): moment.Moment {
  value = TIME(value);
  return DATE_START_OF(value, unit);
}
TIME_START_OF.toString = () => 'TIME_START_OF';

function TIME_END_OF(value: moment.Moment, unit: moment.unitOfTime.StartOf): moment.Moment {
  value = TIME(value);
  return DATE_END_OF(value, unit);
}
TIME_END_OF.toString = () => 'TIME_END_OF';

export interface JetContext {
  env?: Environment;
  page?: ViewSettings;
}

export function HAS_PERMISSION(jet: JetContext, permission: ProjectPermissions): boolean {
  if (!jet.env) {
    return false;
  }

  return hasEnvironmentPermission(jet.env, permission);
}
HAS_PERMISSION.toString = () => 'HAS_PERMISSION';

export function HAS_COLLECTION_PERMISSION(jet: JetContext, modelId: string, action: string): boolean {
  if (!jet.env) {
    return false;
  }

  return hasEnvironmentModelPermission(jet.env, modelId, action);
}
HAS_COLLECTION_PERMISSION.toString = () => 'HAS_COLLECTION_PERMISSION';

export function HAS_PAGE_PERMISSION(jet: JetContext, action: string, pageUid?: string): boolean {
  if (!jet.env) {
    return false;
  }

  const defaultPageUid = isSet(jet.page) ? jet.page.uid : undefined;
  const usePageUid = isSet(pageUid) ? pageUid : defaultPageUid;

  return hasEnvironmentPagePermission(jet.env, usePageUid, action);
}
HAS_PAGE_PERMISSION.toString = () => 'HAS_PAGE_PERMISSION';

registerMathCustomFunction('DATE', DATE);
registerMathCustomFunction('TIME', TIME);
registerMathCustomFunction('NOW', NOW);

registerMathCustomFunction('DAY', DAY);
registerMathCustomFunction('WEEKDAY', WEEKDAY);
registerMathCustomFunction('MONTH', MONTH);
registerMathCustomFunction('YEAR', YEAR);
registerMathCustomFunction('HOUR', HOUR);
registerMathCustomFunction('MINUTE', MINUTE);
registerMathCustomFunction('SECOND', SECOND);
registerMathCustomFunction('MILLISECOND', MILLISECOND);

registerMathCustomFunction('IS_DATE_AFTER', IS_DATE_AFTER);
registerMathCustomFunction('IS_DATE_BEFORE', IS_DATE_BEFORE);
registerMathCustomFunction('IS_DATE_SAME', IS_DATE_SAME);

registerMathCustomFunction('DATE_FORMAT', DATE_FORMAT);
registerMathCustomFunction('DATE_ADD', DATE_ADD);
registerMathCustomFunction('DATE_SUBTRACT', DATE_SUBTRACT);
registerMathCustomFunction('DATE_DIFF', DATE_DIFF);
registerMathCustomFunction('DATE_START_OF', DATE_START_OF);
registerMathCustomFunction('DATE_END_OF', DATE_END_OF);

registerMathCustomFunction('IS_TIME_AFTER', IS_TIME_AFTER);
registerMathCustomFunction('IS_TIME_BEFORE', IS_TIME_BEFORE);
registerMathCustomFunction('IS_TIME_SAME', IS_TIME_SAME);

registerMathCustomFunction('TIME_FORMAT', TIME_FORMAT);
registerMathCustomFunction('TIME_ADD', TIME_ADD);
registerMathCustomFunction('TIME_SUBTRACT', TIME_SUBTRACT);
registerMathCustomFunction('TIME_DIFF', TIME_DIFF);
registerMathCustomFunction('TIME_START_OF', TIME_START_OF);
registerMathCustomFunction('TIME_END_OF', TIME_END_OF);

registerMathCustomFunction('CONCAT', CONCAT);
registerMathCustomFunction('FORMAT', FORMAT);
registerMathCustomFunction('POW', POW);
registerMathCustomFunction('ROUND', ROUND);
registerMathCustomFunction('CEIL', CEIL);
registerMathCustomFunction('FLOOR', FLOOR);
registerMathCustomFunction('LOG', LOG);
registerMathCustomFunction('EXP', EXP);
registerMathCustomFunction('ABS', ABS);
registerMathCustomFunction('SQRT', SQRT);
registerMathCustomFunction('FIX', FIX);
registerMathCustomFunction('IF', IF);
registerMathCustomFunction('EQ', EQ);
registerMathCustomFunction('AND', AND);
registerMathCustomFunction('NOT', NOT);
registerMathCustomFunction('OR', OR);
registerMathCustomFunction('XOR', XOR);
registerMathCustomFunction('SIZE', SIZE);
registerMathCustomFunction('EMPTY', EMPTY_FUNC);
registerMathCustomFunction('IS_NULL', IS_NULL);
registerMathCustomFunction('SORT', SORT);
registerMathCustomFunction('MAP', MAP);
registerMathCustomFunction('FILTER', FILTER);
registerMathCustomFunction('UUID', UUID);
registerMathCustomFunction('HASH', HASH);
registerMathCustomFunction('RANDOM', RANDOM);
registerMathCustomFunction('MIN', MIN);
registerMathCustomFunction('MAX', MAX);
registerMathCustomFunction('AVERAGE', AVERAGE);
registerMathCustomFunction('SUM', SUM);
registerMathCustomFunction('STRING', STRING);
registerMathCustomFunction('NUMBER', NUMBER);
registerMathCustomFunction('NUMBER_FORMAT', NUMBER_FORMAT);
registerMathCustomFunction('JSON', parseJSON);
registerMathCustomFunction('UPPER', UPPER);
registerMathCustomFunction('LOWER', LOWER);
registerMathCustomFunction('TITLE_CASE', TITLE_CASE);
registerMathCustomFunction('SLUGIFY', SLUGIFY);
registerMathCustomFunction('GET', GET);
registerMathCustomFunction('CONTAINS', CONTAINS);
registerMathCustomFunction('ANY', ANY);

registerMathCustomFunction('HAS_PERMISSION', HAS_PERMISSION);
registerMathCustomFunction('HAS_COLLECTION_PERMISSION', HAS_COLLECTION_PERMISSION);
registerMathCustomFunction('HAS_PAGE_PERMISSION', HAS_PAGE_PERMISSION);

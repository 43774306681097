<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-image-menu-item-popover
    [control]="control"
    [options]="editPopoverOptions"
    [imageOptions]="imageOptions"
    [layout]="layout"
  ></app-image-menu-item-popover>
</ng-template>

<app-add-menu-item-menu
  [layout]="layout"
  (selectMenuItem)="addMenuItem($event)"
  #add_menu_item
></app-add-menu-item-menu>

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openEditPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize item
  </button>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" [matMenuTriggerFor]="add_menu_item.menu">
    <span class="choose-items-item__dropdown-icon icon-fileds"></span>
    <ng-container *ngIf="control.controls.children.controls.length">
      Add item to dropdown
    </ng-container>
    <ng-container *ngIf="!control.controls.children.controls.length">
      Add dropdown item
    </ng-container>
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete item
  </button>
</mat-menu>

<div class="sidebar-container__element">
  <div class="sidebar-list-item2 sidebar-list-item2_draggable" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <div
      class="sidebar-list-item2__row sidebar-list-item2__row_padding_l"
      [class.sidebar-list-item2__row_opened]="control.controls.children.controls.length && childrenExpanded"
      [class.sidebar-list-item2__row_disabled]="iconPopoverOpened || editPopoverOpened"
    >
      <div class="sidebar-list-item2__row-left sidebar-list-item2__row-left_margin_l" [style]="imageStyle">
        <div class="sidebar-list-item2__drag sidebar-list-item2__drag_margin icon-dots_small"></div>
        <div
          class="sidebar-list-item2__image sidebar-list-item2__image_clickable"
          [class.sidebar-list-item2__image_unset]="!(imageColor | appIsSet)"
          [class.sidebar-list-item2__image_fill]="control.controls.image_fill.value && imageUrl"
          [class.sidebar-list-item2__image_light]="imageColorLight"
          [style.background-color]="(imageColor | appIsColorHex) ? imageColor : null"
          [style.border-radius.%]="
            (control.controls.image_border_radius.value | appIsSet)
              ? control.controls.image_border_radius.value * 0.5
              : null
          "
          [ngClass]="!(imageColor | appIsColorHex) ? 'background-color_' + imageColor + '_1' : ''"
          cdkOverlayOrigin
          (click)="openEditPopover(true)"
          (mousedown)="$event.stopPropagation()"
        >
          <div
            *ngIf="imageUrl"
            class="sidebar-list-item2__image-inner"
            [style.background-image]="imageUrl ? 'url(' + imageUrl + ')' : ''"
          ></div>

          <div *ngIf="!imageUrl" class="sidebar-list-item2__image-inner" [appIcon]="control.controls.image_icon.value">
            <ng-container *ngIf="!control.controls.image_icon.value && (imageInitials | appIsSet)">{{
              imageInitials
            }}</ng-container>
          </div>
        </div>
      </div>
      <div class="sidebar-list-item2__row-main sidebar-list-item2__row-main_multiline">
        <div
          class="sidebar-list-item2__title sidebar-list-item2__title_editable"
          (click)="openEditPopover(true, { focusTitle: true })"
          (mousedown)="$event.stopPropagation()"
        >
          <ng-container *ngIf="title | appIsSet">
            {{ title }}
          </ng-container>
          <ng-container *ngIf="!(title | appIsSet)">
            No title
          </ng-container>
        </div>
        <div
          class="sidebar-list-item2__subtitle sidebar-list-item2__subtitle_editable"
          (click)="openEditPopover(true, { focusSubtitle: true })"
          (mousedown)="$event.stopPropagation()"
        >
          <ng-container *ngIf="subtitle | appIsSet">
            {{ subtitle }}
          </ng-container>
          <ng-container *ngIf="!(subtitle | appIsSet)">
            No subtitle
          </ng-container>
        </div>
      </div>
      <div class="sidebar-list-item2__row-right">
        <div
          class="sidebar-list-item2__action sidebar-list-item2__action_hover sidebar-list-item2__item icon-more"
          [class.sidebar-list-item2__action_active]="item_dropdown_trigger.menuOpen"
          [matMenuTriggerFor]="item_dropdown"
          [matMenuTriggerData]="{ prepend: true }"
          #item_dropdown_trigger="matMenuTrigger"
          (mousedown)="$event.stopPropagation()"
        ></div>

        <div
          *ngIf="control.controls.children.controls.length"
          class="sidebar-list-item2__action sidebar-list-item2__item"
          [class.icon-arrow_up_2]="childrenExpanded"
          [class.icon-arrow_down_2]="!childrenExpanded"
          (click)="toggleChildrenExpanded()"
        ></div>
      </div>
    </div>

    <div *ngIf="control.controls.children.controls.length && childrenExpanded" class="sidebar-list-item2__children">
      <div
        appDropList
        [appDropListData]="control.controls.children"
        [appDropListLockAxis]="dragAxis.Y"
        [appDropListEnterPredicate]="isDroppable"
        (appDropListDropped)="dragDrop($event)"
      >
        <div
          *ngFor="let item of control.controls.children.controls; let i = index; trackBy: trackMenuItemFn"
          appDrag
          [appDragData]="item"
        >
          <app-auto-menu-item-edit
            [control]="item"
            [layout]="layout"
            [createdOptions]="item === createdMenuItemControl ? createdMenuItemControlOptions : undefined"
            (deleteRequested)="control.controls.children.removeAt(i)"
          ></app-auto-menu-item-edit>
        </div>
      </div>
    </div>
  </div>
</div>

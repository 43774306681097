import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Company } from '../../data/company';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var CompanyService = /** @class */ (function () {
    function CompanyService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    CompanyService.prototype.create = function (project, company) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + project.uniqueName + "/companies/");
            var headers = new HttpHeaders();
            var data = company.serialize();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Company().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };

import { Injectable, Injector } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import toPairs from 'lodash/toPairs';
import { Observable, of } from 'rxjs';
import { delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import {
  KeygenService,
  ProjectProperty,
  ProjectTokenService,
  Resource,
  ResourceDeploy,
  ResourceName,
  ResourceType,
  SecretTokenService
} from '@modules/projects';
import { Region, RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators';

// // TODO: Refactor import
import { DatabaseParamsOptions } from '@modules/resource-generators/services/database-generator/database-generator.service';
import {
  JetBridgeResourceController,
  JetBridgeTablesResponse,
  ResourceControllerService,
  ResourceParamsResult
} from '@modules/resources';
import { AppError, ascComparator, controlValue, forceObservable, generateUUID, isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

export interface DatabaseResourceParams {
  defaultShowInstructions?: boolean;
  defaultToken?: string;
  defaultApiBaseUrl?: string;
  defaultDeploy?: string;
}

export class DatabaseTableControl extends FormGroup {
  controls: {
    name: FormControl;
    active: FormControl;
  };

  constructor(
    state: {
      name?: string;
      active?: boolean;
    } = {}
  ) {
    super({
      name: new FormControl(isSet(state.name) ? state.name : ''),
      active: new FormControl(isSet(state.active) ? state.active : false)
    });
  }

  setActive(value: boolean) {
    this.controls.active.patchValue(value);
    this.controls.active.markAsTouched();
  }

  toggleActive() {
    this.setActive(!this.controls.active.value);
  }
}

export function validateTableArray(): ValidatorFn {
  return (control: DatabaseTableArray): { [key: string]: any } | null => {
    const selected = control.controls.filter(item => item.controls.active.value).length;

    if (!selected) {
      return { local: ['No tables selected'] };
    }

    if (isSet(control.maxTables) && selected > control.maxTables) {
      return { local: [`You can select only up to ${control.maxTables} tables`] };
    }
  };
}

export class DatabaseTableArray extends FormArray {
  controls: DatabaseTableControl[];
  maxTables: number;

  initialValue?: string[];

  constructor(controls: DatabaseTableControl[]) {
    super(controls, validateTableArray());
  }

  init(tables: string[], maxTables?: number) {
    this.maxTables = maxTables;

    this.removeControls();

    tables
      .sort((lhs, rhs) => ascComparator(String(lhs).toUpperCase(), String(rhs).toUpperCase()))
      .forEach(item => {
        const control = this.appendControl(item);

        if (this.initialValue && this.initialValue.includes(item)) {
          control.controls.active.patchValue(true);
        }
      });

    this.updateValueAndValidity();
    this.markAsPristine();
  }

  setControls(controls: DatabaseTableControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: DatabaseTableControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(name: string): DatabaseTableControl {
    const control = new DatabaseTableControl({
      name: name
    });
    control.markAsPristine();
    return control;
  }

  appendControl(name: string): DatabaseTableControl {
    const control = this.createControl(name);
    this.push(control);
    return control;
  }

  getActiveControls(): DatabaseTableControl[] {
    return this.controls.filter(item => item.controls.active.value);
  }

  isAllActive(): boolean {
    return this.controls.every(item => item.controls.active.value);
  }

  isAnyActive(): boolean {
    return this.controls.some(item => item.controls.active.value);
  }

  setAllActive(value: boolean) {
    this.controls.forEach(item => item.setActive(value));
  }

  toggleAllActive() {
    if (this.isAllActive()) {
      this.setAllActive(false);
    } else {
      this.setAllActive(true);
    }
  }
}

@Injectable()
export class DatabasesResourceSettingsForm extends BaseResourceSettingsForm<
  DatabaseParamsOptions,
  DatabaseResourceParams
> {
  form = new AppFormGroup({
    deploy: new FormControl(ResourceDeploy.Direct, Validators.required),
    choose_tables: new FormControl(false),
    show_instructions: new FormControl(true)
  });

  deployDirectForm = new AppFormGroup({
    region: new FormControl('', this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
    database_host: new FormControl('', [Validators.required, this.validateHost(), this.validatePublicHost()]),
    database_port: new FormControl('', [Validators.required, this.validateOnlyNumbers()]),
    database_user: new FormControl('', Validators.required),
    database_password: new FormControl(''),
    database_name: new FormControl('', Validators.required),
    database_schema: new FormControl(''),
    database_extra: new FormControl(''),
    database_ssl_enabled: new FormControl(false),
    database_ssl_ca: new FormControl(''),
    database_ssl_cert: new FormControl(''),
    database_ssl_key: new FormControl(''),
    database_ssh_enabled: new FormControl(false),
    database_ssh_host: new FormControl('', this.validateSSHRequired()),
    database_ssh_port: new FormControl('', this.validateSSHRequired()),
    database_ssh_user: new FormControl('', this.validateSSHRequired()),
    database_ssh_public_key: new FormControl(''),
    database_ssh_private_key: new FormControl(''),
    database_tables: new DatabaseTableArray([])
  });

  deployDirectBigQueryForm = new AppFormGroup({
    region: new FormControl('', this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
    service_token: new FormControl(null, [Validators.required, this.validateServiceToken()])
  });

  deployDirectSnowflakeForm = new AppFormGroup({
    region: new FormControl('', this.appConfigService.jetBridgeRegions ? Validators.required : undefined),
    database_account: new FormControl('', Validators.required),
    database_user: new FormControl('', Validators.required),
    database_password: new FormControl('', Validators.required),
    database_name: new FormControl('', Validators.required),
    database_schema: new FormControl('', Validators.required),
    database_warehouse: new FormControl('', Validators.required)
  });

  jetBridgeForm = new AppFormGroup({
    jetbridge_manual: new FormControl(false),
    url: new FormControl('', Validators.required),
    token: new FormControl('', [Validators.required, this.validateUUID()])
  });

  constructor(
    private databaseGeneratorService: DatabaseGeneratorService,
    private resourceControllerService: ResourceControllerService,
    private regionService: RegionService,
    private keygenService: KeygenService,
    private appConfigService: AppConfigService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );

    controlValue(this.deployDirectForm.controls['database_ssh_enabled'])
      .pipe(delay(0))
      .subscribe(() => {
        this.deployDirectForm.controls['database_host'].updateValueAndValidity();
        this.deployDirectForm.controls['database_ssh_host'].updateValueAndValidity();
        this.deployDirectForm.controls['database_ssh_port'].updateValueAndValidity();
        this.deployDirectForm.controls['database_ssh_user'].updateValueAndValidity();
      });
  }

  validateHost(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!isSet(control.value)) {
        return;
      }

      const value = (control.value || '').toLowerCase();

      if (
        ['http://', 'https://', 'ftp://', '//'].some(item => value.startsWith(item)) ||
        value.indexOf('/') !== -1 ||
        value.match(/:\d+$/)
      ) {
        return { local: ['Only HOST (ex. db.example.com, 136.244.82.XXX) should be specified'] };
      }
    };
  }

  validatePublicHost(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!isSet(control.value)) {
        return;
      }

      const parent = control.parent;

      if (!parent) {
        return;
      }

      const sshEnabled = parent.controls['database_ssh_enabled'].value;

      if (sshEnabled) {
        return;
      }

      const privateHosts = [/^localhost$/, /^127\.0\.0\.1$/, /^192\.\d{1,3}\.\d{1,3}\.\d{1,3}$/];

      if (privateHosts.some(regex => control.value && control.value.trim().match(regex))) {
        return { local: ['Only public HOST is allowed here, use Docker or Python installation for private networks'] };
      }
    };
  }

  validateOnlyNumbers(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!isSet(control.value)) {
        return;
      }

      const isNumber = /^[0-9]*$/.test(control.value);
      if (!isNumber) {
        return { local: ['Only numbers are allowed'] };
      }
      return null;
    };
  }

  validateSSHRequired(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const parent = control.parent;

      if (!parent || !parent.controls['database_ssh_enabled'].value) {
        return;
      }

      if (!isSet(control.value)) {
        return { required: true };
      }
    };
  }

  validateServiceToken(): ValidatorFn {
    return (control: FormControl) => {
      if (!isSet(control.value)) {
        return;
      }

      try {
        const value = JSON.parse(control.value);

        if (!value['type'] || value['type'] != 'service_account' || !value['project_id'] || !value['private_key']) {
          return { local: ['Service key is not valid or not enough permissions'] };
        }
      } catch (e) {
        return { local: ['Service key has incorrect format'] };
      }
    };
  }

  validateUUID(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!isSet(control.value)) {
        return;
      }

      const cleanValue = String(control.value).toLowerCase().replace(/-/g, '');
      const isUUID = /^[a-f0-9]{32}$/.test(cleanValue);
      if (!isUUID) {
        return { local: ['Not a valid UUID, should be string of 32 hexadecimal digits'] };
      }
      return null;
    };
  }

  initResourceValue(): Observable<void> {
    return this.databaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          deploy: result.deploy,
          show_instructions: false
        });

        this.jetBridgeForm.patchValue({
          url: result.url,
          token: this.resource.token || ''
        });

        if (this.typeItem.name == ResourceName.BigQuery) {
          try {
            const serviceTokenStr = atob(result.database_password);

            this.deployDirectBigQueryForm.patchValue({
              region: result.region,
              service_token: serviceTokenStr
            });
          } catch (e) {}

          this.databaseTables.initialValue = result.database_only;
        } else if (this.typeItem.name == ResourceName.Snowflake) {
          const databaseExtra = new URLSearchParams(result.database_extra);

          this.deployDirectSnowflakeForm.patchValue({
            region: result.region,
            database_account: result.database_host,
            database_port: result.database_port,
            database_name: result.database_name,
            database_user: result.database_user,
            database_password: result.database_password,
            database_schema: result.database_schema,
            database_warehouse: databaseExtra['warehouse']
          });

          this.databaseTables.initialValue = result.database_only;
        } else {
          this.deployDirectForm.patchValue({
            region: result.region,
            database_host: result.database_host,
            database_port: result.database_port,
            database_name: result.database_name,
            database_user: result.database_user,
            database_password: result.database_password,
            database_schema: result.database_schema,
            database_extra: result.database_extra,
            database_only: result.database_only,
            database_ssl_enabled: [
              result.database_ssl_ca,
              result.database_ssl_cert,
              result.database_ssl_key
            ].some(item => isSet(item)),
            database_ssl_ca: result.database_ssl_ca,
            database_ssl_cert: result.database_ssl_cert,
            database_ssl_key: result.database_ssl_key,
            database_ssh_enabled: [
              result.database_ssh_host,
              result.database_ssh_port,
              result.database_ssh_user,
              result.database_ssh_public_key,
              result.database_ssh_private_key
            ].some(item => isSet(item)),
            database_ssh_host: result.database_ssh_host,
            database_ssh_port: result.database_ssh_port,
            database_ssh_user: result.database_ssh_user,
            database_ssh_public_key: result.database_ssh_public_key,
            database_ssh_private_key: result.database_ssh_private_key
          });

          this.databaseTables.initialValue = result.database_only;

          if (!isSet(result.database_ssh_public_key) || !isSet(result.database_ssh_private_key)) {
            this.initSSHKeys();
          }
        }
      })
    );
  }

  initDefaultValue(): Observable<void> {
    const { defaultShowInstructions, defaultToken, defaultApiBaseUrl, defaultDeploy } = this.params;

    if (this.typeItem.name == ResourceName.SQLite) {
      this.form.patchValue({ deploy: ResourceDeploy.Docker }, { emitEvent: false });
    }

    if (isSet(defaultDeploy)) {
      this.form.patchValue({ deploy: defaultDeploy });
    }

    if (isSet(defaultShowInstructions)) {
      this.form.patchValue({ show_instructions: defaultShowInstructions });
    }

    if (isSet(defaultApiBaseUrl)) {
      this.jetBridgeForm.patchValue({ url: defaultApiBaseUrl });
    }

    if (isSet(defaultToken)) {
      this.jetBridgeForm.patchValue({ token: defaultToken });
    }

    if (![ResourceName.BigQuery, ResourceName.Snowflake].includes(this.typeItem.name)) {
      this.initSSHKeys();
    }

    return of(undefined);
  }

  initSSHKeys() {
    this.keygenService.generate(this.project, this.environment).subscribe(result => {
      this.deployDirectForm.patchValue({
        database_ssh_public_key: result.public_key,
        database_ssh_private_key: result.private_key
      });
    });
  }

  isDeployDirectFormConnectionValid() {
    if (this.typeItem.name == ResourceName.BigQuery) {
      return this.deployDirectBigQueryForm.valid;
    } else if (this.typeItem.name == ResourceName.Snowflake) {
      return this.deployDirectSnowflakeForm.valid;
    } else {
      return toPairs(this.deployDirectForm.controls)
        .filter(([name]) => name != 'database_tables')
        .every(([name, control]) => control.valid);
    }
  }

  isValid() {
    if (!super.isValid()) {
      return false;
    }

    if (this.form.value['deploy'] == ResourceDeploy.Direct) {
      if (this.typeItem.name == ResourceName.BigQuery) {
        return this.deployDirectBigQueryForm.valid && this.deployDirectForm.controls['database_tables'].valid;
      } else if (this.typeItem.name == ResourceName.Snowflake) {
        return this.deployDirectSnowflakeForm.valid && this.deployDirectForm.controls['database_tables'].valid;
      } else {
        return this.deployDirectForm.valid;
      }
    } else if (this.form.value['deploy'] == ResourceDeploy.Docker) {
      return this.jetBridgeForm.valid;
    } else if (this.form.value['deploy'] == ResourceDeploy.Python) {
      return this.jetBridgeForm.valid;
    } else {
      return true;
    }
  }

  get regionControl(): FormControl {
    if (this.typeItem.name == ResourceName.BigQuery) {
      return this.deployDirectBigQueryForm.controls['region'] as FormControl;
    } else if (this.typeItem.name == ResourceName.Snowflake) {
      return this.deployDirectSnowflakeForm.controls['region'] as FormControl;
    } else {
      return this.deployDirectForm.controls['region'] as FormControl;
    }
  }

  setDefaultRegion(region: Region) {
    if (!this.resource && region) {
      if (this.typeItem.name == ResourceName.BigQuery) {
        if (!this.deployDirectBigQueryForm.value['region']) {
          this.deployDirectBigQueryForm.patchValue({ region: region.uid });
        }
      } else if (this.typeItem.name == ResourceName.Snowflake) {
        if (!this.deployDirectSnowflakeForm.value['region']) {
          this.deployDirectSnowflakeForm.patchValue({ region: region.uid });
        }
      } else {
        if (!this.deployDirectForm.value['region']) {
          this.deployDirectForm.patchValue({ region: region.uid });
        }
      }
    }
  }

  get databaseTables(): DatabaseTableArray {
    return this.deployDirectForm.controls['database_tables'] as DatabaseTableArray;
  }

  getOptions(): Observable<DatabaseParamsOptions> {
    const options: DatabaseParamsOptions = {
      deploy: this.form.value['deploy']
    };

    if (this.form.value['deploy'] == ResourceDeploy.Direct) {
      if (this.typeItem.name == ResourceName.BigQuery) {
        const value = this.deployDirectBigQueryForm.value;

        options.region = value['region'];

        try {
          const serviceToken = JSON.parse(value['service_token']);

          options.database_name = serviceToken['project_id'];
          options.database_password = btoa(JSON.stringify(serviceToken));
        } catch (e) {
          console.error(e);
        }
      } else if (this.typeItem.name == ResourceName.Snowflake) {
        const value = this.deployDirectSnowflakeForm.value;

        options.region = value['region'];
        options.database_host = value['database_account'];
        options.database_name = value['database_name'];
        options.database_user = value['database_user'];
        options.database_password = value['database_password'];
        options.database_schema = value['database_schema'];
        options.database_extra = `warehouse=${value['database_warehouse']}`;
      } else {
        const value = this.deployDirectForm.value;

        options.region = value['region'];
        options.database_host = value['database_host'];
        options.database_port = value['database_port'];
        options.database_name = value['database_name'];
        options.database_user = value['database_user'];
        options.database_password = value['database_password'];
        options.database_schema = value['database_schema'];
        options.database_extra = value['database_extra'];

        if (value['database_ssl_enabled']) {
          options.database_ssl_ca = value['database_ssl_ca'];
          options.database_ssl_cert = value['database_ssl_cert'];
          options.database_ssl_key = value['database_ssl_key'];
        }

        if (value['database_ssh_enabled']) {
          options.database_ssh_host = value['database_ssh_host'];
          options.database_ssh_port = value['database_ssh_port'];
          options.database_ssh_user = value['database_ssh_user'];
          options.database_ssh_public_key = value['database_ssh_public_key'];
          options.database_ssh_private_key = value['database_ssh_private_key'];
        }
      }

      options.database_only = this.databaseTables.getActiveControls().map(item => item.controls.name.value);
      options.token = this.jetBridgeForm.value['token'] || generateUUID();

      if (this.appConfigService.jetBridgeRegions) {
        return this.regionService.getDetail(options.region).pipe(
          map(region => {
            options.url = region.jetBridgeCloudUrl;
            return options;
          })
        );
      } else {
        options.url = this.appConfigService.jetBridgeCloudBaseUrl;
        return of(options);
      }
    } else {
      options.url = this.jetBridgeForm.value['url'];
      options.token = this.jetBridgeForm.value['token'];

      return of(options);
    }
  }

  getGeneralParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.getOptions().pipe(
      switchMap(options =>
        this.databaseGeneratorService.generateGeneralParams(this.project, this.environment, this.typeItem, options)
      ),
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name']
        };
      })
    );
  }

  discoverConnection(): Observable<boolean> {
    return forceObservable<ResourceParamsResult>(this.getGeneralParams()).pipe(
      switchMap(result => {
        const instance = this.resource ? cloneDeep(this.resource) : new Resource();

        instance.type = this.typeItem.resourceType;
        instance.typeItem = this.typeItem;
        instance.token = result.resourceToken;
        instance.params = result.resourceParams;

        const controller = this.resourceControllerService.get<JetBridgeResourceController>(ResourceType.JetBridge);
        return controller.discoverConnection(instance);
      })
    );
  }

  discoverTables(): Observable<JetBridgeTablesResponse> {
    return forceObservable<ResourceParamsResult>(this.getGeneralParams()).pipe(
      switchMap(result => {
        const instance = this.resource ? cloneDeep(this.resource) : new Resource();

        instance.type = this.typeItem.resourceType;
        instance.typeItem = this.typeItem;
        instance.token = result.resourceToken;
        instance.params = result.resourceParams;

        const controller = this.resourceControllerService.get<JetBridgeResourceController>(ResourceType.JetBridge);
        return controller.discoverTables(instance);
      }),
      tap(result => {
        if (!result.tables.length) {
          throw new ServerRequestError('Database does not have any valid Tables');
        }
      })
    );
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.getOptions().pipe(
      switchMap(options =>
        this.databaseGeneratorService.generateParams(this.project, this.environment, this.typeItem, options)
      ),
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name']
        };
      })
    );
  }

  submit(): Observable<Resource> {
    const createResource = !this.resource;

    this.deploy = this.form.value['deploy'];

    return super.submit().pipe(
      delayWhen(() => {
        if (createResource) {
          return this.keygenService.clear(this.project, this.environment);
        } else {
          return of(undefined);
        }
      })
    );
  }
}

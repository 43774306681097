import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-route',
  templateUrl: './register-route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterRouteComponent implements OnInit, OnDestroy {
  ngOnInit() {}

  ngOnDestroy(): void {}
}

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { SignUpField } from '@modules/all-project-settings';
import { ProjectProperty } from '@modules/projects';

import { SignUpFieldControl } from './sign-up-field.control';

export class SignUpFieldArray extends FormArray {
  controls: SignUpFieldControl[];

  deserialize(value: SignUpField[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): SignUpField[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: SignUpFieldControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: SignUpFieldControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: ProjectProperty): SignUpFieldControl {
    const control = new SignUpFieldControl({
      ...(item && {
        project_property: item.uid
      })
    });
    control.markAsPristine();
    return control;
  }

  appendControl(item?: ProjectProperty): SignUpFieldControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }
}

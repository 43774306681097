/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../routing/directives/app-link/app-link.directive";
import * as i3 from "../../../routing/services/routing/routing.service";
import * as i4 from "@angular/router";
import * as i5 from "../../../../shared/components/toast/toast.component.ngfactory";
import * as i6 from "../../../../shared/components/toast/toast.component";
import * as i7 from "../../../../core/services/local-storage/local.storage";
import * as i8 from "../../../../shared/pipes/format-date/format-date.pipe";
import * as i9 from "./subscription-past-due-toast.component";
import * as i10 from "../../../analytics/services/intercom/intercom.service";
var styles_SubscriptionPastDueToastComponent = [];
var RenderType_SubscriptionPastDueToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubscriptionPastDueToastComponent, data: {} });
export { RenderType_SubscriptionPastDueToastComponent as RenderType_SubscriptionPastDueToastComponent };
function View_SubscriptionPastDueToastComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you don't subscribe to a new plan your App will be suspended on "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 2), (_l()(), i0.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.context.ngIf, "ll")); _ck(_v, 3, 0, currVal_0); }); }
function View_SubscriptionPastDueToastComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Your subscription was cancelled on ", "."])), i0.ɵppd(3, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.project.subscription.dateEndActual; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), _co.project.subscription.dateCancelled, "lll")); _ck(_v, 2, 0, currVal_0); }); }
function View_SubscriptionPastDueToastComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If the payment does not go through your App will be suspended on "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 2), (_l()(), i0.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.context.ngIf, "ll")); _ck(_v, 3, 0, currVal_0); }); }
function View_SubscriptionPastDueToastComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" There was an error with your latest subscription payment. This is usually due to insufficient funds or an expired card. "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project.subscription.dateEndActual; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SubscriptionPastDueToastComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "toast2__button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i2.AppLinkDirective, [i3.RoutingService, i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { appLink: [0, "appLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-payments"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose plan"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.project.settingsBillingPlansLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SubscriptionPastDueToastComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "toast2__button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i2.AppLinkDirective, [i3.RoutingService, i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { appLink: [0, "appLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-payments"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Check payment method"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.project.settingsBillingLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SubscriptionPastDueToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "app-toast", [], null, null, null, i5.View_ToastComponent_0, i5.RenderType_ToastComponent)), i0.ɵdid(1, 638976, null, 0, i6.ToastComponent, [i7.LocalStorage, i0.ChangeDetectorRef], { warning: [0, "warning"], uniqueName: [1, "uniqueName"], shift: [2, "shift"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Payment for your subscription is past due "])), (_l()(), i0.ɵeld(4, 0, null, 0, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, 1, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_6)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_7)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "toast2__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-chat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Contact Us"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = ("past_due_" + _co.project); var currVal_2 = _co.shift; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.project.subscription.cancelled; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_co.project.subscription.cancelled; _ck(_v, 8, 0, currVal_4); var currVal_5 = (_co.project.hasProjectBillingPermission() && _co.project.subscription.cancelled); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.project.hasProjectBillingPermission() && !_co.project.subscription.cancelled); _ck(_v, 13, 0, currVal_6); }, null); }
export function View_SubscriptionPastDueToastComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.FormatDatePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubscriptionPastDueToastComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SubscriptionPastDueToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-subscription-past-due-toast", [], null, null, null, View_SubscriptionPastDueToastComponent_0, RenderType_SubscriptionPastDueToastComponent)), i0.ɵdid(1, 114688, null, 0, i9.SubscriptionPastDueToastComponent, [i10.IntercomService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscriptionPastDueToastComponentNgFactory = i0.ɵccf("app-subscription-past-due-toast", i9.SubscriptionPastDueToastComponent, View_SubscriptionPastDueToastComponent_Host_0, { project: "project", shift: "shift" }, {}, []);
export { SubscriptionPastDueToastComponentNgFactory as SubscriptionPastDueToastComponentNgFactory };

import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function tapOnce<T>(project: (value: T) => void, defer?: boolean, thisArg?: any): OperatorFunction<T, T> {
  return function tapOnceOperation(source: Observable<T>): Observable<T> {
    return source.pipe(
      map<T, T>((item, i) => {
        if (i == 0) {
          if (defer) {
            setTimeout(() => project(item), 0);
          } else {
            project(item);
          }
        }

        return item;
      })
    );
  };
}

export function mapWithError<T, R, E>(project: (value?: T, error?: E) => R): OperatorFunction<T, R> {
  return function mapWithErrorOperation(source: Observable<T>): Observable<R> {
    return source.pipe(
      map(result => {
        return project(result, undefined);
      }),
      catchError(error => {
        return of(project(undefined, error));
      })
    );
  };
}

<div class="input-toolbar ql-mini" [class.input-toolbar_header]="header" #toolbar_element></div>

<div
  class="input input_textarea input_wysiwyg"
  [class.input_fill]="fill"
  [class.input_fill-vertical]="fillVertical"
  [class.input_header]="header"
  [class.input_footer]="displayValueTypesEnabled"
>
  <app-text-editor
    [value]="valueControl.value"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [toolbarElement]="toolbarElement"
    [toolbarHeadersEnabled]="richTextTypes.includes(typeControl.value)"
    [toolbarTextStyleEnabled]="richTextTypes.includes(typeControl.value)"
    [toolbarInputLabel]="!richTextTypes.includes(typeControl.value)"
    [toolbarListsEnabled]="richTextTypes.includes(typeControl.value)"
    [toolbarAlignEnabled]="false"
    [toolbarCleanEnabled]="richTextTypes.includes(typeControl.value)"
    [schema]="(themeService.isDarkTheme$ | async) ? 'dark' : 'grey'"
    [paddingSize]="'m'"
    [analyticsSource]="analyticsSource"
    (changed)="save($event)"
  >
    <ng-container data-toolbar-left>
      <span class="ql-formats">
        <span class="ql-type ql-picker" [class.ql-expanded]="opened">
          <span class="ql-picker-label" [class.ql-active]="true" role="button" (click)="setOpened(!opened)">
            <span *ngIf="!(typeControl.value | appIsSet)">Type</span>
            <span *ngIf="markupOption as option">{{ option.name }}</span>

            <svg viewBox="0 0 18 18">
              <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon>
              <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon>
            </svg>
          </span>

          <span class="ql-picker-options" tabindex="-1">
            <span
              *ngFor="let item of typeOptions"
              class="ql-picker-item"
              [class.ql-selected]="item.value == typeControl.value"
              tabindex="0"
              role="button"
              (click)="typeControl.patchValue(item.value); setOpened(false)"
            >
              {{ item.name }}
            </span>
          </span>
        </span>
      </span>
    </ng-container>
  </app-text-editor>
</div>

<div
  *ngIf="displayValueTypesEnabled"
  class="button-tags"
  [class.button-tags_border]="true"
  [class.button-tags_footer]="true"
>
  <div
    *ngIf="staticValueDisabled"
    class="button-tag button-tags__item"
    [class.button-tags__item_fill]="false"
    [class.button-tag_darker]="darker"
    (click)="switchToFormula.emit()"
  >
    <span class="button-tag__icon icon-variable"></span>
    <span class="button-tag__label">Disable Text with Inputs</span>
  </div>

  <div
    *ngIf="!staticValueDisabled"
    class="button-tag button-tags__item"
    [class.button-tags__item_fill]="false"
    [class.button-tag_darker]="darker"
    (click)="switchToDefault.emit()"
  >
    <span class="button-tag__icon icon-variable"></span>
    <span class="button-tag__label">Disable Text with Inputs</span>
  </div>
</div>

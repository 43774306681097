var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { TintStyle } from '@modules/actions';
import { AlignHorizontal, ElementType, FormSubmitElementItem, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { FormElementComponent } from '../form-element/form-element.component';
var FormSubmitElementComponent = /** @class */ (function (_super) {
    __extends(FormSubmitElementComponent, _super);
    function FormSubmitElementComponent(formElement, cd) {
        var _this = _super.call(this) || this;
        _this.formElement = formElement;
        _this.cd = cd;
        _this.disabled = false;
        _this.style = TintStyle.Primary;
        _this.tintStyles = TintStyle;
        _this.alignHorizontals = AlignHorizontal;
        return _this;
    }
    FormSubmitElementComponent.prototype.ngOnInit = function () {
        this.isFormSubmitDisabled$ = this.formElement ? this.formElement.isSubmitDisabled$() : of(true);
        this.initVerboseName();
        this.initStyle();
        this.initDisabled();
    };
    FormSubmitElementComponent.prototype.ngOnDestroy = function () { };
    FormSubmitElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes['element']) {
            this.initVerboseName();
            this.initStyle();
            this.initDisabled();
        }
    };
    FormSubmitElementComponent.prototype.initVerboseName = function () {
        var _this = this;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.element.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.element.verboseNameInput, {
            context: this.context,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    FormSubmitElementComponent.prototype.initStyle = function () {
        this.style = this.element.style ? this.element.style : TintStyle.Primary;
        this.cd.markForCheck();
    };
    FormSubmitElementComponent.prototype.getElementDisabled$ = function () {
        return this.element.disabledInput && this.element.disabledInput.isSet()
            ? applyBooleanInput$(this.element.disabledInput, {
                context: this.context
            })
            : of(false);
    };
    FormSubmitElementComponent.prototype.initDisabled = function () {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        if (!this.element) {
            this.disabled = false;
            this.cd.markForCheck();
            return;
        }
        this.getElementDisabled$()
            .pipe(untilDestroyed(this))
            .subscribe(function (inputDisabled) {
            _this.disabled = inputDisabled;
            _this.cd.markForCheck();
        });
    };
    return FormSubmitElementComponent;
}(BaseElementComponent));
export { FormSubmitElementComponent };
registerElementComponent({
    type: ElementType.FormSubmit,
    component: FormSubmitElementComponent,
    alwaysActive: false,
    label: 'Form submit',
    actions: []
});

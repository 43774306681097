var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import { FieldType } from '@modules/fields';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ResourceType, SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, ModelDescriptionSimpleQuery, QueryType } from '@modules/queries';
import { ResourceControllerService, SMART_SUITE_ACCOUNT_ID_PARAM, SMART_SUITE_APPLICATION_ID_PARAM, SMART_SUITE_ID_FIELD_NAME, SMART_SUITE_SOLUTION_ID_PARAM, SmartSuiteApplicationFieldType, SOURCE_FIELD_TYPE } from '@modules/resources';
import { forceObservable } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
import { defaultSmartSuiteFieldMapping, smartSuiteFieldMapping } from './smart-suite-field-mapping';
var SmartSuiteGeneratorService = /** @class */ (function (_super) {
    __extends(SmartSuiteGeneratorService, _super);
    function SmartSuiteGeneratorService(mode, resourceControllerService, secretTokenService, apiService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.resourceControllerService = resourceControllerService;
        _this.secretTokenService = secretTokenService;
        _this.apiService = apiService;
        _this.tokenName = 'api_key';
        _this.controller = _this.resourceControllerService.get(ResourceType.SmartSuite);
        return _this;
    }
    SmartSuiteGeneratorService.prototype.isOptionsValid = function (options) {
        return this.controller.getApplications(options).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
                error = new ServerRequestError('API Key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    SmartSuiteGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, this.tokenName, this.mode == AdminMode.Builder)
            .pipe(catchError(function () { return of(undefined); }), map(function (secretToken) {
            return {
                key: secretToken.value,
                accountId: resource.params[SMART_SUITE_ACCOUNT_ID_PARAM],
                solutionId: resource.params[SMART_SUITE_SOLUTION_ID_PARAM]
            };
        }));
    };
    SmartSuiteGeneratorService.prototype.getModelDescriptions = function (accountId, key, solutionId) {
        var _this = this;
        return this.controller.getApplications({ accountId: accountId, key: key, solutionId: solutionId }).pipe(switchMap(function (applications) {
            if (!applications.length) {
                return of([]);
            }
            return combineLatest(applications.map(function (application) { return _this.getModelDescription(application, applications); }));
        }));
    };
    SmartSuiteGeneratorService.prototype.getModelDescription = function (application, applications) {
        return this.getModelDescriptionFields(application, applications).pipe(map(function (fields) {
            var _a;
            var modelDescription = new ModelDescription();
            modelDescription.project = '{{project}}';
            modelDescription.resource = '{{resource}}';
            modelDescription.model = application.slug;
            modelDescription.verboseName = application.name;
            modelDescription.verboseNamePlural = application.name;
            modelDescription.primaryKeyField = SMART_SUITE_ID_FIELD_NAME;
            modelDescription.displayField = application.primary_field;
            modelDescription.params = (_a = {},
                _a[SMART_SUITE_APPLICATION_ID_PARAM] = application.id,
                _a);
            modelDescription.fields = fields;
            var getSimpleQuery = new ModelDescriptionSimpleQuery();
            getSimpleQuery.model = modelDescription.model;
            modelDescription.getQuery = new ListModelDescriptionQuery();
            modelDescription.getQuery.queryType = QueryType.Simple;
            modelDescription.getQuery.simpleQuery = getSimpleQuery;
            modelDescription.getQuery.sortingFields = modelDescription.dbFields.map(function (item) {
                return {
                    name: item.name,
                    sortable: ![SMART_SUITE_ID_FIELD_NAME].includes(item.name)
                };
            });
            var getDetailSimpleQuery = new ModelDescriptionSimpleQuery();
            getDetailSimpleQuery.model = modelDescription.model;
            modelDescription.getDetailQuery = new ModelDescriptionQuery();
            modelDescription.getDetailQuery.queryType = QueryType.Simple;
            modelDescription.getDetailQuery.simpleQuery = getDetailSimpleQuery;
            modelDescription.getDetailParametersUseDefaults = true;
            var createSimpleQuery = new ModelDescriptionSimpleQuery();
            createSimpleQuery.model = modelDescription.model;
            modelDescription.createQuery = new ModelDescriptionQuery();
            modelDescription.createQuery.queryType = QueryType.Simple;
            modelDescription.createQuery.simpleQuery = createSimpleQuery;
            modelDescription.createParametersUseDefaults = true;
            var updateSimpleQuery = new ModelDescriptionSimpleQuery();
            updateSimpleQuery.model = modelDescription.model;
            modelDescription.updateQuery = new ModelDescriptionQuery();
            modelDescription.updateQuery.queryType = QueryType.Simple;
            modelDescription.updateQuery.simpleQuery = updateSimpleQuery;
            modelDescription.updateParametersUseDefaults = true;
            var deleteSimpleQuery = new ModelDescriptionSimpleQuery();
            deleteSimpleQuery.model = modelDescription.model;
            modelDescription.deleteQuery = new ModelDescriptionQuery();
            modelDescription.deleteQuery.queryType = QueryType.Simple;
            modelDescription.deleteQuery.simpleQuery = deleteSimpleQuery;
            modelDescription.deleteParametersUseDefaults = true;
            return modelDescription;
        }));
    };
    SmartSuiteGeneratorService.prototype.getModelDescriptionFields = function (application, applications) {
        var _this = this;
        if (!application.structure.length) {
            return of([]);
        }
        return combineLatest(application.structure.map(function (field) { return _this.getModelDescriptionField(application, field, applications); })).pipe(map(function (fields) {
            var _a;
            var primaryKey = new ModelField();
            var dbField = new ModelDbField();
            dbField.name = SMART_SUITE_ID_FIELD_NAME;
            dbField.verboseName = 'id';
            dbField.field = FieldType.Text;
            dbField.editable = false;
            dbField.params = (_a = {},
                _a[SOURCE_FIELD_TYPE] = SmartSuiteApplicationFieldType.TextField,
                _a);
            dbField.updateFieldDescription();
            primaryKey.name = dbField.name;
            primaryKey.type = ModelFieldType.Db;
            primaryKey.item = dbField;
            return [primaryKey].concat(fields);
        }));
    };
    SmartSuiteGeneratorService.prototype.getModelDescriptionField = function (application, item, applications) {
        var result = new ModelField();
        var dbField = new ModelDbField();
        dbField.name = item.slug;
        dbField.verboseName = item.label;
        dbField.field = FieldType.Text;
        dbField.required = item.params.required;
        dbField.editable = ![
            SmartSuiteApplicationFieldType.AutoNumberField,
            SmartSuiteApplicationFieldType.CommentsCountField,
            SmartSuiteApplicationFieldType.FirstCreatedField,
            SmartSuiteApplicationFieldType.LastUpdatedField
        ].includes(item.field_type);
        dbField.updateFieldDescription();
        result.name = item.slug;
        result.type = ModelFieldType.Db;
        result.item = dbField;
        var mapping = smartSuiteFieldMapping.find(function (i) { return i.type == item.field_type; });
        var resultMapping = mapping ? mapping.mapping : defaultSmartSuiteFieldMapping;
        var fieldWithParams;
        if (resultMapping.getField) {
            fieldWithParams = forceObservable(resultMapping.getField({
                name: item.slug,
                field: item,
                applications: applications
            }));
        }
        else if (resultMapping.getParams) {
            fieldWithParams = forceObservable(resultMapping.getParams({
                name: item.slug,
                field: item,
                applications: applications
            })).pipe(map(function (params) {
                return {
                    field: resultMapping.field,
                    params: params
                };
            }));
        }
        else {
            fieldWithParams = of({
                field: resultMapping.field,
                params: resultMapping.params
            });
        }
        return fieldWithParams.pipe(map(function (_a) {
            var field = _a.field, params = _a.params;
            var _b;
            result.item.field = field;
            result.item.params = __assign({}, params, (_b = {}, _b[SOURCE_FIELD_TYPE] = item.field_type, _b));
            return result;
        }));
    };
    SmartSuiteGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        return this.getModelDescriptions(options.accountId, options.key, options.solutionId).pipe(map(function (modelDescriptions) {
            var _a;
            var token = new SecretToken();
            token.resource = '{{resource}}';
            token.name = _this.tokenName;
            token.type = SecretTokenType.Static;
            token.value = options.key;
            var resourceParams = (_a = {},
                _a[SMART_SUITE_ACCOUNT_ID_PARAM] = options.accountId,
                _a[SMART_SUITE_SOLUTION_ID_PARAM] = options.solutionId,
                _a);
            return {
                resourceParams: resourceParams,
                modelDescriptions: modelDescriptions.map(function (item) { return item.serialize(); }),
                secretTokens: [token.serialize()]
            };
        }));
    };
    return SmartSuiteGeneratorService;
}(ResourceGeneratorService));
export { SmartSuiteGeneratorService };

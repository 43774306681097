import { OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
var AppDropListGroup = /** @class */ (function () {
    function AppDropListGroup() {
        this.disabled = false;
        this.dropLists = [];
        this.dropListsUpdated = new Subject();
    }
    AppDropListGroup.prototype.ngOnChanges = function (changes) {
        this.dropListsUpdated.next();
    };
    AppDropListGroup.prototype.registerDropList = function (dropList) {
        var index = this.dropLists.indexOf(dropList);
        if (index == -1) {
            this.dropLists.push(dropList);
            this.dropListsUpdated.next();
        }
    };
    AppDropListGroup.prototype.unregisterDropList = function (dropList) {
        var index = this.dropLists.indexOf(dropList);
        if (index != -1) {
            this.dropLists.splice(index, 1);
            this.dropListsUpdated.next();
        }
    };
    Object.defineProperty(AppDropListGroup.prototype, "dropListsUpdated$", {
        get: function () {
            return this.dropListsUpdated.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return AppDropListGroup;
}());
export { AppDropListGroup };

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isSet } from '@shared';

import { TaskQueueStore } from './task-queue.store';

@Injectable()
export class TaskStatusSelectSource extends SelectSource {
  allowEmpty = false;
  emptyName = '---';
  queue: string;

  private page = 1;
  private totalPages = 1;

  constructor(private taskQueueStore: TaskQueueStore) {
    super();
  }

  get emptyOption() {
    return {
      value: null,
      name: this.emptyName
    };
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.taskQueueStore.getDetailFirst(this.queue).pipe(
      map(result => {
        this.page += 1;

        const results = result.statuses
          .filter(item =>
            isSet(searchQuery) ? [item.name].some(str => str.toLowerCase().includes(searchQuery.toLowerCase())) : true
          )
          .map(item => {
            return {
              value: item.uid,
              name: item.name
            };
          });

        if (!this.allowEmpty) {
          return results;
        }

        return [this.emptyOption].concat(results);
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    return this.taskQueueStore.getDetailFirst(this.queue).pipe(
      map(result => {
        if (result) {
          const resource = result.statuses.find(i => i.uid == value);

          if (!resource) {
            return;
          }

          return {
            value: resource.uid,
            name: resource.name
          };
        }
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

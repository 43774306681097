export enum FirebaseDatabaseInstanceType {
  Unspecified = 'DATABASE_INSTANCE_TYPE_UNSPECIFIED',
  Default = 'DEFAULT_DATABASE',
  User = 'USER_DATABASE'
}

export enum FirebaseDatabaseState {
  Unspecified = 'LIFECYCLE_STATE_UNSPECIFIED',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Deleted = 'DELETED'
}

export interface FirebaseDatabase {
  name: string;
  project: string;
  databaseUrl: string;
  type: FirebaseDatabaseInstanceType;
  state: FirebaseDatabaseState;
}

export interface FirebaseDatabasesResponse {
  instances?: FirebaseDatabase[];
}

export interface FirebaseFirestoreCollectionIdsResponse {
  collectionIds: string[];
}

export enum FirebaseDatabaseType {
  Firestore = 'firestore',
  Realtime = 'realtime'
}

export interface FirebaseDatabaseOption {
  type: FirebaseDatabaseType;
  id?: string;
}

import pickBy from 'lodash/pickBy';

import { SimpleQuery } from './simple-query';

export class StorageSimpleQuery extends SimpleQuery {
  name: string;

  deserialize(data: Object): StorageSimpleQuery {
    this.name = data['name'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      name: this.name
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured(): boolean {
    return true;
  }
}

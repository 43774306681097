import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var KeygenService = /** @class */ (function () {
    function KeygenService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    KeygenService.prototype.generate = function (project, environment) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'keygen/');
            var headers = new HttpHeaders();
            var data = {};
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    KeygenService.prototype.clear = function (project, environment) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'keygen/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    KeygenService.ngInjectableDef = i0.defineInjectable({ factory: function KeygenService_Factory() { return new KeygenService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: KeygenService, providedIn: "root" });
    return KeygenService;
}());
export { KeygenService };

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { AggregateFunc } from '@modules/charts';
import { ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class MoveForm {
  form: FormGroup;
  modelDescription: ModelDescription;

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    this.form = this.fb.group({
      move: new FormControl('after', Validators.required),
      model: new FormControl(null)
    });
  }

  init(modelDescription: ModelDescription) {
    this.modelDescription = modelDescription;
  }

  get modelNeeded() {
    const value = this.form.value;

    return value['move'] == 'after' || value['move'] == 'before';
  }

  isForwardProcessing() {
    const value = this.form.value;

    if (value['move'] == 'after') {
      return false;
    } else if (value['move'] == 'before') {
      return true;
    } else if (value['move'] == 'first') {
      return false;
    } else if (value['move'] == 'last') {
      return true;
    } else {
      return true;
    }
  }

  submit(id: any) {
    this.form.disable();

    const value = this.form.value;

    const requests: Observable<any>[] = [
      this.modelService.getDetail(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        this.modelDescription.modelId,
        this.modelDescription.primaryKeyField,
        id
      )
    ];

    if (this.modelNeeded) {
      requests.push(
        this.modelService.getDetail(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          this.modelDescription.modelId,
          this.modelDescription.primaryKeyField,
          value['model']
        )
      );
      requests.push(of(undefined));
    } else {
      requests.push(of(undefined));
      this.modelService.aggregate(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        this.modelDescription.modelId,
        AggregateFunc.Count,
        undefined
      );
    }

    return combineLatest(...requests).pipe(
      switchMap(result => {
        const moveModel = result[0] as Model;
        const toModel = result[1] as Model;
        const count = result[2] as number;
        const orderingField = this.modelDescription.orderingField;
        const moveOrder = moveModel.getAttribute(orderingField);
        const toOrder = toModel ? toModel.getAttribute(orderingField) : undefined;
        let forward: boolean;
        let segmentFrom: number;
        let segmentTo: number;

        if (value['move'] == 'after') {
          forward = toOrder >= moveOrder;

          if (forward) {
            forward = toOrder >= moveOrder;
            segmentFrom = moveOrder + 1;
            segmentTo = toOrder;
          } else {
            segmentFrom = toOrder + 1;
            segmentTo = moveOrder - 1;
          }
        } else if (value['move'] == 'before') {
          forward = toOrder >= moveOrder;

          if (forward) {
            segmentFrom = moveOrder + 1;
            segmentTo = toOrder - 1;
          } else {
            segmentFrom = toOrder;
            segmentTo = moveOrder - 1;
          }
        } else if (value['move'] == 'first') {
          forward = false;
          segmentFrom = 1;
          segmentTo = moveOrder - 1;
        } else if (value['move'] == 'last') {
          forward = true;
          segmentFrom = moveOrder + 1;
          segmentTo = count;
        }

        return this.modelService.reorder(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          this.modelDescription.modelId,
          forward,
          segmentFrom,
          segmentTo,
          moveModel.primaryKey,
          true
        );
      }),
      tap(() => {
        this.form.enable();
        this.form.markAsPristine();
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this.form, error);
        this.form.enable();
        this.form.markAsDirty();
        return throwError(error);
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from '../../utils/text/text';

@Pipe({
  name: 'appCapitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any, args?: boolean): any {
    if (args !== false) {
      return capitalize(value);
    } else {
      return value;
    }
  }
}

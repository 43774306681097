import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-url-field',
  templateUrl: 'url-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class URLFieldComponent extends FieldComponent implements OnInit {
  ngOnInit(): void {}

  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }
}

registerFieldComponent(FieldType.URL, URLFieldComponent);

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ViewContextElement, ViewContextOutput } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { Frame, LayerInteractionType, LayerType, LineLayer, Point } from '@modules/views';
import { isSet, radToDeg, square } from '@shared';

import { registerLayerComponent } from '../../../data/layer-components';
import {
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';

interface BorderItem {
  border: SafeStyle;
  thickness: number;
  enabled: boolean;
}

export function getLineOrientation(from: Partial<Point>, to: Partial<Point>): { xInverse: boolean; yInverse: boolean } {
  return {
    xInverse: from.x > to.x,
    yInverse: from.y > to.y
  };
}

@Component({
  selector: 'app-line-layer',
  templateUrl: './line-layer.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineLayerComponent extends LayerComponent<LineLayer> implements OnInit, OnDestroy {
  hover$: Observable<boolean>;
  customizing$: Observable<boolean>;
  customizingMultiple$: Observable<boolean>;

  fromX = 0;
  fromY = 0;
  length = 0;
  angle = 0;
  xInverse = false;
  yInverse = false;
  transform: SafeStyle;
  transformOverlay: string;
  borders: BorderItem[] = [];
  bordersSubscription: Subscription;
  boxShadow: SafeStyle;
  boxShadowSubscription: Subscription;

  constructor(
    @Optional() editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super(editorContext);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.editorContext) {
      this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
      this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
      this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
      this.updating$ = this.getLayerUpdating$(() => !this.editorContext.isCreateTool());

      this.editorContext
        .globalLayersChange$()
        .pipe(
          filter(event => event.source != ViewEditorCustomizeSource.Layer),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.updateLine();
        });
    }

    this.getLayer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateLine();
        this.updateBorders();
        this.updateBoxShadows();
        this.updateLayerContext();
      });
  }

  ngOnDestroy(): void {}

  updateLine() {
    const frame = this.layer.frame;
    const fromX = this.layer.points[0] ? this.layer.points[0].x : 0;
    const fromY = this.layer.points[0] ? this.layer.points[0].y : 0;
    const toX = this.layer.points[1] ? this.layer.points[1].x : 0;
    const toY = this.layer.points[1] ? this.layer.points[1].y : 0;
    const deltaX = frame ? (fromX - toX) * frame.width : 0;
    const deltaY = frame ? (toY - fromY) * frame.height : 0;
    const { xInverse, yInverse } = getLineOrientation(this.layer.points[0], this.layer.points[1]);
    const angle = this.getLineAngle(deltaX, deltaY);

    this.fromX = fromX;
    this.fromY = fromY;
    this.length = frame ? Math.sqrt(square(deltaX) + square(deltaY)) : 0;
    this.transform = angle ? this.sanitizer.bypassSecurityTrustStyle(`rotate(${angle}deg)`) : undefined;
    this.transformOverlay = this.layer.frame.rotation ? `rotate(${this.layer.frame.rotation}deg)` : undefined;
    this.xInverse = xInverse;
    this.yInverse = yInverse;
    this.cd.markForCheck();
  }

  getLineAngle(deltaX: number, deltaY: number) {
    if (deltaY != 0) {
      const angle = radToDeg(Math.atan(deltaX / deltaY));

      if (deltaX >= 0 && deltaY >= 0) {
        return 90 + angle;
      } else if (deltaX >= 0 && deltaY < 0) {
        return 270 + angle;
      } else if (deltaX < 0 && deltaY < 0) {
        return 270 + angle;
      } else if (deltaX < 0 && deltaY >= 0) {
        return 90 + angle;
      }
    } else {
      return deltaX >= 0 ? 180 : 0;
    }
  }

  updateBorders() {
    if (this.bordersSubscription) {
      this.bordersSubscription.unsubscribe();
      this.bordersSubscription = undefined;
    }

    const borders$ = [...this.layer.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const border$ = item.cssBorder$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(border$, enabled$).pipe(
          map(([border, enabled]) => {
            return {
              border: isSet(border) ? this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
              thickness: item.thickness,
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.borders = [];
      this.cd.markForCheck();
      return;
    }

    this.bordersSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.borders = borders.filter(item => item.enabled && isSet(item.border));
        this.cd.markForCheck();
      });
  }

  updateBoxShadows() {
    if (this.boxShadowSubscription) {
      this.boxShadowSubscription.unsubscribe();
      this.boxShadowSubscription = undefined;
    }

    const shadows$ = this.layer.shadows
      .filter(item => item.enabled)
      .map(item => {
        const boxShadow$ = item.cssBoxShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(boxShadow$, enabled$).pipe(
          map(([boxShadow, enabled]) => {
            return {
              boxShadow: boxShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.boxShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.boxShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.boxShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.boxShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateLayerContext() {
    const hoverOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: this.layer.id,
        name: this.layer.name,
        icon: this.layer.icon
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: this.layer.name,
          icon: this.layer.icon
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  onFrameUpdate(frame: Partial<Frame>) {
    super.onFrameUpdate(frame);
    this.updateLine();
  }

  onLayerUpdate(frame: Partial<Frame>, points?: { from: Partial<Point>; to: Partial<Point> }) {
    this.layer.frame.patch(frame);

    if (points) {
      this.layer.applyPoints(points.from, points.to);
    }

    this.updateLine();
    this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Layer);
  }
}

registerLayerComponent(LayerType.Line, LineLayerComponent);

import { TemplateRef } from '@angular/core';

import { FormField, FormFieldSerialized } from '../data/form-field';

function stringify(obj) {
  const value = new FormField().deserialize(obj).serialize();
  if (value.description instanceof TemplateRef) {
    value.description = value.description.toString();
  }
  return JSON.stringify(value);
}

export function createFormFieldFactory(): (options: FormFieldSerialized) => FormField {
  const cache = {};

  return (options: FormFieldSerialized) => {
    const cacheKey = stringify(options);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const field = new FormField().deserialize(options);
    cache[cacheKey] = field;
    return field;
  };
}

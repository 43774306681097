var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CustomizeService, ElementType, FileViewerElementItem, FileViewerType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { isSet } from '@shared';
var FileViewerElementComponent = /** @class */ (function (_super) {
    __extends(FileViewerElementComponent, _super);
    function FileViewerElementComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.embedUrl = '';
        _this.fileViewerTypes = FileViewerType;
        _this.isSafari = false;
        return _this;
    }
    FileViewerElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        this.initContextObserver();
    };
    FileViewerElementComponent.prototype.ngOnDestroy = function () { };
    FileViewerElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.initContextObserver();
        }
    };
    FileViewerElementComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        this.updateInputs();
        this.contextSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () { return _this.updateInputs(); });
    };
    FileViewerElementComponent.prototype.updateInputs = function () {
        var embedUrl = this.getEmbedUrl();
        if (embedUrl == this.embedUrl) {
            return;
        }
        if (isSet(this.embedUrl)) {
            this.embedUrl = '';
            this.cd.detectChanges();
        }
        this.embedUrl = embedUrl;
        this.cd.markForCheck();
    };
    FileViewerElementComponent.prototype.getEmbedUrl = function () {
        try {
            var url = applyParamInput(this.element.url, {
                context: this.context,
                defaultValue: ''
            });
            var cleanUrl = this.getCleanUrl(url);
            return this.getFileViewerUrl(cleanUrl);
        }
        catch (e) {
            return '';
        }
    };
    FileViewerElementComponent.prototype.getCleanUrl = function (url) {
        if (!isSet(url)) {
            return url;
        }
        url = String(url);
        if (!['https://', 'http://', 'file://', 'ftp://', '//'].some(function (item) { return url.toLowerCase().startsWith(item); })) {
            return "//" + url;
        }
        else {
            return url;
        }
    };
    FileViewerElementComponent.prototype.getFileViewerUrl = function (fileUrl) {
        if (!isSet(fileUrl)) {
            return fileUrl;
        }
        if (this.element.viewerType == FileViewerType.Google) {
            var encodedUrl = encodeURIComponent(fileUrl);
            return "https://docs.google.com/gview?url=" + encodedUrl + "&pid=explorer&efh=false&a=v&chrome=false&embedded=true";
        }
        else {
            if (this.element.toolbar) {
                return fileUrl;
            }
            var url = new URL(fileUrl);
            var hashParams = new URLSearchParams(url.hash);
            hashParams.set('toolbar', '0');
            hashParams.set('navpanes', '0');
            url.hash = hashParams.toString();
            return url.toString();
        }
    };
    return FileViewerElementComponent;
}(BaseElementComponent));
export { FileViewerElementComponent };
registerElementComponent({
    type: ElementType.FileViewer,
    component: FileViewerElementComponent,
    label: 'File Viewer',
    actions: []
});

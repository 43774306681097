/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../previews/audio-preview/audio-preview.component.ngfactory";
import * as i2 from "../../previews/audio-preview/audio-preview.component";
import * as i3 from "../../previews/file-preview/file-preview.component.ngfactory";
import * as i4 from "../../previews/file-preview/file-preview.component";
import * as i5 from "@angular/common";
import * as i6 from "../upload-template/upload-template.component.ngfactory";
import * as i7 from "../upload-template/upload-template.component";
import * as i8 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i9 from "./audio-upload.component";
import * as i10 from "../../../../projects/stores/current-project.store";
import * as i11 from "../../../../projects/stores/current-environment.store";
import * as i12 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i13 from "../../../../storages-queries/services/storage/storage.service";
var styles_AudioUploadComponent = [];
var RenderType_AudioUploadComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AudioUploadComponent, data: {} });
export { RenderType_AudioUploadComponent as RenderType_AudioUploadComponent };
function View_AudioUploadComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-audio-preview", [], null, null, null, i1.View_AudioPreviewComponent_0, i1.RenderType_AudioPreviewComponent)), i0.ɵdid(1, 114688, null, 0, i2.AudioPreviewComponent, [], { preview: [0, "preview"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AudioUploadComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-preview", [], null, null, null, i3.View_FilePreviewComponent_0, i3.RenderType_FilePreviewComponent)), i0.ɵdid(1, 114688, null, 0, i4.FilePreviewComponent, [], { previewFilename: [0, "previewFilename"], previewExtension: [1, "previewExtension"], previewSize: [2, "previewSize"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.filename); var currVal_1 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.extension); var currVal_2 = _co.uploadFileSize; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AudioUploadComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "upload__background upload__background_audio upload__background_interactive"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AudioUploadComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AudioUploadComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.compact; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.compact; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AudioUploadComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-upload-template", [], null, [[null, "clearValue"], [null, "fileSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clearValue" === en)) {
        var pd_0 = (_co.clearCurrentValue() !== false);
        ad = (pd_0 && ad);
    } if (("fileSelected" === en)) {
        var pd_1 = (_co.onFileSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_UploadTemplateComponent_0, i6.RenderType_UploadTemplateComponent)), i0.ɵdid(1, 245760, null, 0, i7.UploadTemplateComponent, [i0.ChangeDetectorRef], { upload: [0, "upload"], accept: [1, "accept"], name: [2, "name"], control: [3, "control"], autofocus: [4, "autofocus"], disabled: [5, "disabled"], background: [6, "background"], thin: [7, "thin"], fit: [8, "fit"], cropFormat: [9, "cropFormat"], cropFormatCustom: [10, "cropFormatCustom"], preview: [11, "preview"], previewInteractive: [12, "previewInteractive"], uploadProgress: [13, "uploadProgress"], uploadError: [14, "uploadError"], accentColor: [15, "accentColor"] }, { clearValue: "clearValue", fileSelected: "fileSelected" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AudioUploadComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 1, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "a", [["class", "upload__button icon-cloud_download"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 737280, null, 0, i8.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(7, { side: 0, margin: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUploadable(); var currVal_1 = "audio/*"; var currVal_2 = _co.name; var currVal_3 = _co.control; var currVal_4 = _co.autofocus; var currVal_5 = !_co.storage$.value; var currVal_6 = _co.background; var currVal_7 = true; var currVal_8 = _co.fit; var currVal_9 = _co.cropFormat; var currVal_10 = _co.cropFormatCustom; var currVal_11 = ((_co.uploadedFile == null) ? null : _co.uploadedFile.url); var currVal_12 = true; var currVal_13 = _co.uploadProgress; var currVal_14 = _co.uploadError; var currVal_15 = _co.accentColor; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); var currVal_16 = _co.uploadedFile; _ck(_v, 3, 0, currVal_16); var currVal_17 = "Download"; var currVal_18 = _ck(_v, 7, 0, "top", (0 - 4)); _ck(_v, 6, 0, currVal_17, currVal_18); }, null); }
export function View_AudioUploadComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-audio-upload", [], null, null, null, View_AudioUploadComponent_0, RenderType_AudioUploadComponent)), i0.ɵdid(1, 770048, null, 0, i9.AudioUploadComponent, [i10.CurrentProjectStore, i11.CurrentEnvironmentStore, i12.ResourceControllerService, i13.StorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioUploadComponentNgFactory = i0.ɵccf("app-audio-upload", i9.AudioUploadComponent, View_AudioUploadComponent_Host_0, { name: "name", control: "control", outputFormat: "outputFormat", storageResourceName: "storageResourceName", storageName: "storageName", path: "path", autofocus: "autofocus", disabled: "disabled", background: "background", fit: "fit", resize: "resize", editor: "editor", cropFormat: "cropFormat", cropFormatCustom: "cropFormatCustom", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor" }, { uploadedUpdated: "uploadedUpdated" }, []);
export { AudioUploadComponentNgFactory as AudioUploadComponentNgFactory };

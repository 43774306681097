import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
  CollapseElementItem,
  CustomizeService,
  ElementType,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$ } from '@modules/fields';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: CollapseElementItem;

  customizeEnabled$: Observable<boolean>;
  element$ = new BehaviorSubject<CollapseElementItem>(this.element);
  opened = false;

  constructor(
    private customizeService: CustomizeService,
    private viewContextElement: ViewContextElement,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.element$.next(this.element);

    this.initItemsOpened();

    this.initContext();
    this.updateContextActions();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CollapseComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.element$.next(this.element);

      this.updateContextInfo();
    }
  }

  initItemsOpened() {
    this.element$
      .pipe(
        switchMap(element => {
          if (!element.openedInput || !element.openedInput.isSet()) {
            return of(false);
          }

          return applyParamInput$(element.openedInput, {
            context: this.context
          }).pipe(distinctUntilChanged());
        }),
        untilDestroyed(this)
      )
      .subscribe(opened => {
        if (opened && !this.opened) {
          this.setOpened(true);
        } else if (!opened && this.opened) {
          this.setOpened(false);
        }
      });
  }

  setOpened(value: boolean) {
    this.opened = value;
    this.cd.markForCheck();
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'align_vertical_fill',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextInfo() {
    this.viewContextElement.initInfo(
      {
        name: this.element.name,
        element: this.element
      },
      true
    );
  }

  updateContextActions() {
    this.viewContextElement.setActions([
      {
        uniqueName: 'show',
        name: 'Show content',
        icon: 'align_vertical_fill',
        parameters: [],
        handler: () => {
          this.setOpened(true);
        }
      },
      {
        uniqueName: 'hide',
        name: 'Hide content',
        icon: 'align_vertical_center',
        parameters: [],
        handler: () => {
          this.setOpened(false);
        }
      },
      {
        uniqueName: 'toggle',
        name: 'Toggle content visibility',
        icon: 'sort',
        parameters: [],
        handler: () => {
          this.toggleOpened();
        }
      }
    ]);
  }
}

registerElementComponent({
  type: ElementType.Collapse,
  component: CollapseComponent,
  label: 'Collapse',
  alwaysActive: false,
  actions: []
});

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ViewContextElement, ViewContextOutput } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import {
  Frame,
  GroupLayer,
  includesAnyLayers,
  Layer,
  LayerInteractionType,
  LayerType,
  resizeLayers
} from '@modules/views';
import { TypedChanges } from '@shared';

import { registerLayerComponent } from '../../../data/layer-components';
import {
  ViewEditorContainer,
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';

@Component({
  selector: 'app-group-layer',
  templateUrl: './group-layer.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupLayerComponent extends LayerComponent<GroupLayer> implements OnInit, OnDestroy, OnChanges {
  nestedContainer: ViewEditorContainer;
  hover$: Observable<boolean>;
  customizing$: Observable<boolean>;
  customizingMultiple$: Observable<boolean>;
  layersInteraction = true;
  displayItems: Layer[] = [];
  displayItemsSubscription: Subscription;

  trackLayerFn(i, item: Layer) {
    return item.id;
  }

  constructor(
    @Optional() editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super(editorContext);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.registerNestedContainer();

    if (this.editorContext) {
      this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
      this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
      this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
      this.updating$ = this.getLayerUpdating$(() => !this.editorContext.isCreateTool());

      merge(
        this.editorContext
          .layerChanged$()
          .pipe(filter(event => !!this.layer.layers.find(item => item.isSame(event.layer)))),
        this.editorContext.layerContainerChanged$().pipe(filter(event => this.layer.layers === event.container))
      )
        .pipe(untilDestroyed(this))
        .subscribe(() => this.updateDisplayItems());

      combineLatest(this.editorContext.ctrlKeydown$, this.editorContext.customizingLayers$)
        .pipe(untilDestroyed(this))
        .subscribe(([ctrlKeydown, customizingLayers]) => {
          this.layersInteraction = ctrlKeydown || includesAnyLayers(this.layer.layers, customizingLayers);
          this.cd.markForCheck();
        });

      this.initFitContainer();
    }

    this.getLayer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateNestedContainer();
        this.updateDisplayItems();
        this.updateLayerContext();
      });
  }

  ngOnDestroy(): void {
    this.unregisterNestedContainer();
  }

  ngOnChanges(changes: TypedChanges<GroupLayerComponent>) {
    super.ngOnChanges(changes);

    if (this.editorContext) {
      const layerChanged = changes.layer && !changes.layer.firstChange;
      const translateChanged = changes.translate && !changes.translate.firstChange;

      if (layerChanged) {
        this.unregisterNestedContainer();
        this.registerNestedContainer();
      } else if (translateChanged) {
        this.updateNestedContainer();
      }
    }
  }

  registerNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    this.nestedContainer = this.editorContext.registerContainer(this.layer.layers, {
      layer: this.layer,
      translate: {
        x: this.translate.x + this.layer.frame.x,
        y: this.translate.y + this.layer.frame.y
      }
    });
  }

  unregisterNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    if (!this.nestedContainer) {
      return;
    }

    this.editorContext.unregisterContainer(this.nestedContainer.container);
    this.nestedContainer = undefined;
  }

  updateNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    if (!this.nestedContainer) {
      return;
    }

    this.nestedContainer.patchOptions({
      translate: {
        x: this.translate.x + this.layer.frame.x,
        y: this.translate.y + this.layer.frame.y
      }
    });
  }

  updateDisplayItems() {
    if (this.displayItemsSubscription) {
      this.displayItemsSubscription.unsubscribe();
      this.displayItemsSubscription = undefined;
    }

    const items$ = this.layer.layers
      .filter(item => item.visible)
      .map(item => {
        const visible$ = item.visibleInput ? item.visible$({ context: this.viewContext }) : of(true);

        return combineLatest(visible$).pipe(
          map(([visible]) => {
            return {
              item: item,
              visible: visible
            };
          })
        );
      });

    if (!items$.length) {
      this.displayItems = [];
      this.cd.markForCheck();
      return;
    }

    this.displayItemsSubscription = combineLatest(items$)
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.displayItems = items.filter(item => item.visible).map(item => item.item);
        this.cd.markForCheck();
      });
  }

  updateLayerContext() {
    const hoverOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: this.layer.id,
        name: this.layer.name,
        icon: this.layer.icon
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: this.layer.name,
          icon: this.layer.icon
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  deleteLayer(index: number) {
    this.layer.layers.splice(index, 1);
    this.cd.markForCheck();
    this.editorContext.markLayerContainerChanged(this.layer.layers, ViewEditorCustomizeSource.View);
  }

  initFitContainer() {
    this.trackLayerChildrenFrames()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.editorContext.fitContainer(this.layer, ViewEditorCustomizeSource.Layer);
      });
  }

  onFrameUpdate(frame: Partial<Frame>) {
    if (!this.layer.flexLayout) {
      resizeLayers({
        layers: this.layer.layers,
        originalFrame: this.layer.frame,
        newFrame: new Frame(this.layer.frame).patch(frame)
      });
      this.editorContext.markLayersChanged(this.layer.layers, ViewEditorCustomizeSource.ParentLayer);
    }

    super.onFrameUpdate(frame);
    this.updateNestedContainer();
  }
}

registerLayerComponent(LayerType.Group, GroupLayerComponent);

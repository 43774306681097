var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { isSet } from '@shared';
import { KanbanStageControl } from './kanban-stage.control';
export var validateStages = function (control) {
    if (!control.value.length || control.value.some(function (item) { return !isSet(item.name); })) {
        return { required: true };
    }
};
var KanbanStageArray = /** @class */ (function (_super) {
    __extends(KanbanStageArray, _super);
    function KanbanStageArray(controls) {
        return _super.call(this, controls, validateStages) || this;
    }
    KanbanStageArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.deserialize(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    KanbanStageArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    KanbanStageArray.prototype.setControls = function (groups) {
        var _this = this;
        this.removeControls();
        groups.forEach(function (item) { return _this.push(item); });
    };
    KanbanStageArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    KanbanStageArray.prototype.removeControl = function (group) {
        var index = this.controls.findIndex(function (item) { return item === group; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    KanbanStageArray.prototype.createControl = function (stage) {
        var control = new KanbanStageControl();
        if (stage) {
            control.deserialize(stage);
        }
        control.markAsPristine();
        return control;
    };
    KanbanStageArray.prototype.appendControl = function (item) {
        var control = this.createControl(item);
        this.push(control);
        return control;
    };
    KanbanStageArray.prototype.removeEmpty = function () {
        while (true) {
            var group = this.controls.find(function (item) { return !isSet(item.controls.value.value) && !isSet(item.controls.name.value); });
            if (!group) {
                break;
            }
            this.removeControl(group);
        }
    };
    KanbanStageArray.prototype.addMissingByValues = function (newValues) {
        var _this = this;
        this.removeEmpty();
        newValues
            .filter(function (value) { return value !== undefined; })
            .forEach(function (value) {
            var stage = _this.createControl();
            var name = value;
            if (name === null) {
                name = 'NULL';
            }
            else if (name === '') {
                name = 'Empty';
            }
            stage.controls.value.patchValue(value);
            stage.controls.name.patchValue(name);
            _this.push(stage);
        });
    };
    return KanbanStageArray;
}(FormArray));
export { KanbanStageArray };

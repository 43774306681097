import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Company } from '../../data/company';
import { Project } from '../../data/project';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  create(project: Project, company: Company): Observable<Company> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/companies/`);
        let headers = new HttpHeaders();
        const data = company.serialize();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Company().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { isSet } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
var DocsService = /** @class */ (function () {
    function DocsService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    DocsService.prototype.getDocument = function (selector) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeMethodURL('docs/documents');
            var headers = new HttpHeaders();
            var params = {};
            headers = _this.apiService.setHeadersToken(headers);
            if (isSet(selector.path)) {
                params['path'] = selector.path;
            }
            if (isSet(selector.filePath)) {
                params['filePath'] = selector.filePath;
            }
            if (isSet(selector.relativePath)) {
                params['relativePath'] = selector.relativePath;
            }
            return _this.http.get(url, { headers: headers, params: params });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DocsService.ngInjectableDef = i0.defineInjectable({ factory: function DocsService_Factory() { return new DocsService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: DocsService, providedIn: "root" });
    return DocsService;
}());
export { DocsService };

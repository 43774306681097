var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, fileFieldTypes, FormField } from '@modules/fields';
var InputTokenValueComponent = /** @class */ (function () {
    function InputTokenValueComponent(cd) {
        this.cd = cd;
        this.useFileObjects = false;
        this.autofocus = false;
        this.back = new EventEmitter();
        this.submit = new EventEmitter();
        this.controlName = 'field';
        this.fileInput = false;
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    InputTokenValueComponent.prototype.ngOnInit = function () {
        var _a;
        this.form = new FormGroup((_a = {},
            _a[this.controlName] = this.selectedTokenValue,
            _a));
    };
    InputTokenValueComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedToken) {
            if (this.selectedToken.field == FieldType.Boolean) {
                this.formField = new FormField();
                this.formField.name = this.controlName;
                this.formField.field = FieldType.Select;
                this.formField.params = {
                    valueEquals: this.booleanValueEquals,
                    options: [
                        { value: null, name: 'Not specified' },
                        { value: false, name: 'No' },
                        { value: true, name: 'Yes' }
                    ],
                    classes: ['select_fill']
                };
            }
            else {
                var classes = this.selectedToken.params && this.selectedToken.params['classes'] ? this.selectedToken.params['classes'] : [];
                this.formField = new FormField();
                this.formField.name = this.controlName;
                this.formField.field = this.selectedToken.field;
                this.formField.params = __assign({}, this.selectedToken.params, { classes: ['select_fill', 'input_fill'].concat(classes), background: true, thumb_inside: true });
            }
            this.formField.resetEnabled = !this.selectedToken.fixedValue;
            this.formField.placeholder = this.controlPlaceholder;
            this.fileInput = this.useFileObjects && fileFieldTypes.includes(this.selectedToken.field);
            this.cd.markForCheck();
        }
    };
    InputTokenValueComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.selectedTokenValue.patchValue(file);
    };
    Object.defineProperty(InputTokenValueComponent.prototype, "controlPlaceholder", {
        get: function () {
            return "Test value for {{" + (this.selectedToken.name || 'name') + "}}";
        },
        enumerable: true,
        configurable: true
    });
    return InputTokenValueComponent;
}());
export { InputTokenValueComponent };

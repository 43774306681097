import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { debounceTime, first, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewSource } from '@modules/custom-views';
import { cleanElementName, CustomElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldOutput, Input as FieldInput, InputValueType } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';
import { ResourceControllerService } from '@modules/resources';
import { CustomViewTemplateType } from '@modules/views';
import { controlValue, isSet } from '@shared';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { CustomizeBarCustomEditForm } from './customize-bar-custom-edit.form';
var CustomizeBarCustomEditComponent = /** @class */ (function () {
    function CustomizeBarCustomEditComponent(form, queryContext, customizeBarContext, resourceControllerService, queryBuilderService, currentProjectStore, cd, notificationService, analyticsService) {
        var _this = this;
        this.form = form;
        this.queryContext = queryContext;
        this.customizeBarContext = customizeBarContext;
        this.resourceControllerService = resourceControllerService;
        this.queryBuilderService = queryBuilderService;
        this.currentProjectStore = currentProjectStore;
        this.cd = cd;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.titleEnabled = true;
        this.deleteEnabled = false;
        this.titleEditable = false;
        this.firstInit = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.configurationStarted = false;
        this.loading = false;
        this.customViewSources = CustomViewSource;
        this.customViewTemplateTypes = CustomViewTemplateType;
        this.titleCleanValue = (function () {
            return function (value) {
                return cleanElementName(value, _this.element, _this.context.getElementItems());
            };
        })();
    }
    CustomizeBarCustomEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form
            .init(this.element, this.context, this.firstInit)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var resultObs = _this.form.submitChanges.pipe(debounceTime(200), switchMap(function () { return _this.submit(); }));
            resultObs.pipe(untilDestroyed(_this)).subscribe(function (result) {
                _this.result = result;
                _this.emitUpdate();
            });
            combineLatest(controlValue(_this.form.controls.dist_file), controlValue(_this.form.controls.dist))
                .pipe(untilDestroyed(_this))
                .subscribe(function () { return _this.updateDistPreview(); });
            _this.form.controls.view.valueChanges.pipe(untilDestroyed(_this)).subscribe(function () { return _this.cd.markForCheck(); });
            resultObs
                .pipe(switchMap(function (result) { return _this.form.isConfigured(result); }), trackConfigured(), first(function (configuration) { return configuration == ElementConfiguration.Started; }), untilDestroyed(_this))
                .subscribe(function () {
                _this.configurationStarted = true;
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
                    ComponentTypeID: _this.element.analyticsName
                });
            });
            if (_this.firstInit && !isSet(_this.element.customView) && _this.element.customViewTemporary) {
                _this.save(false);
            }
        });
    };
    CustomizeBarCustomEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarCustomEditComponent.prototype.close = function () {
        var _this = this;
        (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (configured) {
            if (configured) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
                    ComponentTypeID: _this.element.analyticsName
                });
            }
            _this.customizeBarContext.popSettingsComponent();
        });
    };
    CustomizeBarCustomEditComponent.prototype.submit = function () {
        return this.form.submit({ submitCustomView: this.form.controls.source.value == CustomViewSource.CustomElement });
    };
    CustomizeBarCustomEditComponent.prototype.save = function (close) {
        var _this = this;
        if (close === void 0) { close = true; }
        this.submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.result = result;
            _this.emitUpdate(true);
            if (close) {
                _this.close();
            }
        });
    };
    CustomizeBarCustomEditComponent.prototype.onTitleChanged = function (title) {
        var _this = this;
        this.title = title;
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.result = result;
            _this.emitUpdate();
        });
    };
    CustomizeBarCustomEditComponent.prototype.emitUpdate = function (submit) {
        if (submit === void 0) { submit = false; }
        if (this.element) {
            this.event.emit({
                type: CustomizeBarEditEventType.Updated,
                args: { result: this.result, title: this.title, submit: submit }
            });
        }
        else {
            this.event.emit({
                type: CustomizeBarEditEventType.Created,
                args: { result: this.result, title: this.title, submit: submit }
            });
        }
    };
    CustomizeBarCustomEditComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
        this.close();
    };
    CustomizeBarCustomEditComponent.prototype.delete = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
    };
    Object.defineProperty(CustomizeBarCustomEditComponent.prototype, "distFilename", {
        get: function () {
            if (!this.distPreview) {
                return;
            }
            return this.distPreview.split('/').pop();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarCustomEditComponent.prototype.updateDistPreview = function () {
        if (this.form.controls.dist_file.value) {
            this.distPreview = this.form.controls.dist_file.value.name;
        }
        else if (this.form.controls.dist.value instanceof File) {
            this.distPreview = this.form.controls.dist.value.name;
        }
        else {
            this.distPreview = this.form.controls.dist.value;
        }
        this.cd.markForCheck();
    };
    CustomizeBarCustomEditComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.form.controls.dist_file.patchValue(file);
        this.form.controls.files.patchValue({ js: [], css: [] });
        this.form.markAsDirty();
    };
    CustomizeBarCustomEditComponent.prototype.serializeOutput = function (value) {
        var result = new FieldOutput();
        result.name = value.name;
        result.verboseName = value.verboseName;
        result.description = value.description;
        result.field = value.field;
        result.defaultType = value.defaultType;
        result.defaultValue = value.defaultValue;
        result.params = value.params;
        result.updateFieldDescription();
        return result;
    };
    CustomizeBarCustomEditComponent.prototype.deserializeOutput = function (value) {
        return {
            name: value.name,
            verboseName: value.verboseName,
            description: value.description,
            field: value.field,
            defaultType: value.defaultType,
            defaultValue: value.defaultValue,
            params: value.params
        };
    };
    CustomizeBarCustomEditComponent.prototype.onCustomViewChange = function (view) {
        this.form.controls.parameters.patchValue(view.parameters);
    };
    CustomizeBarCustomEditComponent.prototype.onCustomViewTemplateSelected = function (template) {
        var inputs = toPairs(template.view.testParameters).map(function (_a) {
            var name = _a[0], value = _a[1];
            var result = new FieldInput();
            result.name = name;
            result.valueType = InputValueType.StaticValue;
            result.staticValue = value;
            return result;
        });
        this.form.controls.inputs.patchValue(inputs);
    };
    return CustomizeBarCustomEditComponent;
}());
export { CustomizeBarCustomEditComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/browser-header/browser-header.component.ngfactory";
import * as i2 from "../../../../ui/components/browser-header/browser-header.component";
import * as i3 from "../../../customize-elements/components/auto-element/auto-element.component.ngfactory";
import * as i4 from "../../../customize-elements/components/auto-element/auto-element.component";
import * as i5 from "../../../admin-mode/providers/route-admin-mode";
import * as i6 from "../../../customize/services/customize/customize.service";
import * as i7 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i8 from "../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i9 from "../../../../common/popups/services/popup/popup.service";
import * as i10 from "../../../../common/popover/services/popover/popover.service";
import * as i11 from "../../../../core/services/session-storage/session.storage";
import * as i12 from "../../../routing/services/routing/routing.service";
import * as i13 from "../../../template/services/template/template.service";
import * as i14 from "../../../model-queries/stores/model-description.store";
import * as i15 from "../../../../common/notifications/services/notification/notification.service";
import * as i16 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
import * as i17 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i18 from "../../../customize-elements/services/element-components/element-components.service";
import * as i19 from "../../../customize-elements/services/element-container/element-container.service";
import * as i20 from "../../../customize-configuration/services/element-configuration/element-configuration.service";
import * as i21 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i22 from "../../../customize-components/components/custom-page-popup/custom-page-popup.component";
import * as i23 from "../../../../common/drag-drop2/directives/drop-list/drop-list.directive";
import * as i24 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i25 from "@angular/common";
import * as i26 from "../../../customize-components/directives/element-groups-container/element-groups-container.directive";
import * as i27 from "../../../customize/data/view-context-element";
import * as i28 from "../../../customize/data/view-context";
import * as i29 from "./page-template-preview-page.component";
var styles_PageTemplatePreviewPageComponent = [];
var RenderType_PageTemplatePreviewPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewPageComponent, data: {} });
export { RenderType_PageTemplatePreviewPageComponent as RenderType_PageTemplatePreviewPageComponent };
function View_PageTemplatePreviewPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "browser-window__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-browser-header", [], null, null, null, i1.View_BrowserHeaderComponent_0, i1.RenderType_BrowserHeaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.BrowserHeaderComponent, [], { address: [0, "address"], addressIconHasDefault: [1, "addressIconHasDefault"], addressSearchIcon: [2, "addressSearchIcon"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.page.name)); var currVal_1 = false; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PageTemplatePreviewPageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-element", [], [[2, "app-auto-element_inline", null], [1, "data-component-id", 0], [1, "data-component-name", 0]], [[null, "visibleUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleUpdated" === en)) {
        var pd_0 = (_co.updateElementStates() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AutoElementComponent_0, i3.RenderType_AutoElementComponent)), i0.ɵdid(1, 4964352, null, 0, i4.AutoElementComponent, [i0.Injector, i5.ROUTE_ADMIN_MODE, i6.CustomizeService, [2, i7.CustomizeBarContext], i8.CustomizeBarService, i9.PopupService, i10.PopoverService, i0.ComponentFactoryResolver, i0.ChangeDetectorRef, i11.SessionStorage, i12.RoutingService, i13.TemplateService, i14.ModelDescriptionStore, i15.NotificationService, i16.DropListService, i17.DialogService, i0.ElementRef, i0.Renderer2, i18.ElementComponentsService, i19.ElementContainerService, i20.ElementConfigurationService, i0.NgZone, i21.UniversalAnalyticsService, [3, i4.AutoElementComponent], [3, i22.CustomPagePopupComponent], [2, i23.AppDropList]], { element: [0, "element"], context: [1, "context"] }, { visibleUpdated: "visibleUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.context; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).inlineClass; var currVal_1 = i0.ɵnov(_v, 1).elementId; var currVal_2 = i0.ɵnov(_v, 1).elementName; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PageTemplatePreviewPageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i24.CapitalizePipe, []), i0.ɵqud(402653184, 1, { elementGroupsContainer: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "browser-window"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewPageComponent_1)), i0.ɵdid(4, 16384, null, 0, i25.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "browser-window__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "browser-window__content-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["appElementGroupsContainer", ""], ["class", "browser-window__elements"]], null, null, null, null, null)), i0.ɵdid(8, 147456, [[1, 4]], 0, i26.ElementGroupsContainerDirective, [i0.NgZone, i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewPageComponent_2)), i0.ɵdid(10, 278528, null, 0, i25.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.page.elements; var currVal_2 = _co.trackElement; _ck(_v, 10, 0, currVal_1, currVal_2); }, null); }
export function View_PageTemplatePreviewPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-page", [], null, null, null, View_PageTemplatePreviewPageComponent_0, RenderType_PageTemplatePreviewPageComponent)), i0.ɵprd(131584, null, i27.ViewContextElement, i27.ViewContextElement, [i28.ViewContext]), i0.ɵdid(2, 4833280, null, 0, i29.PageTemplatePreviewPageComponent, [i28.ViewContext, i27.ViewContextElement], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PageTemplatePreviewPageComponentNgFactory = i0.ɵccf("app-page-template-preview-page", i29.PageTemplatePreviewPageComponent, View_PageTemplatePreviewPageComponent_Host_0, { page: "page", context: "context", header: "header" }, {}, []);
export { PageTemplatePreviewPageComponentNgFactory as PageTemplatePreviewPageComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { TabsLayoutItemControl } from './tabs-layout-item.control';
var TabsLayoutItemArray = /** @class */ (function (_super) {
    __extends(TabsLayoutItemArray, _super);
    function TabsLayoutItemArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TabsLayoutItemArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.deserialize(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    TabsLayoutItemArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    TabsLayoutItemArray.prototype.patchValue = function (value, options) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.setValue(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    TabsLayoutItemArray.prototype.setValue = function (value, options) {
        _super.prototype.setValue.call(this, value, options);
    };
    TabsLayoutItemArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    TabsLayoutItemArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    TabsLayoutItemArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    TabsLayoutItemArray.prototype.createControl = function (item) {
        var control = new TabsLayoutItemControl();
        if (item) {
            control.deserialize(item);
        }
        control.markAsPristine();
        return control;
    };
    TabsLayoutItemArray.prototype.appendControl = function (item) {
        var control = this.createControl(item);
        this.push(control);
        return control;
    };
    return TabsLayoutItemArray;
}(FormArray));
export { TabsLayoutItemArray };

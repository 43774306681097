import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { PopoverComponent } from '@common/popover';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CurrentEnvironmentStore, ProjectGroupStore } from '@modules/projects';
import { Singleton } from '@shared';

import {
  createFormFieldFactory,
  DefaultType,
  getFieldComponentsByName,
  getFieldDescriptionByType,
  ParameterControl
} from '@modules/fields';

// TODO: Refactor import
import { ViewContextTokenProvider } from '../../../parameters-components/services/view-context-token-provider/view-context-token-provider';

import { BooleanFieldStyle } from '../boolean-field/boolean-field.component';
import { FieldsEditConfigurable } from '../fields-edit/fields-edit.form';

@Component({
  selector: 'app-fields-edit-item',
  templateUrl: './fields-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldsEditItemComponent implements OnInit, OnDestroy {
  @Input() control: ParameterControl;
  @Input() configurable: FieldsEditConfigurable;
  @Output() saveRequested = new EventEmitter<void>();
  @Output() deleteRequested = new EventEmitter<void>();

  createField = createFormFieldFactory();
  viewParamsComponentData: DynamicComponentArguments;
  dataParamsComponentData: DynamicComponentArguments;
  defaultTypes = DefaultType;
  defaultTypeOptions = [
    { value: DefaultType.Value, name: 'Fixed value' },
    { value: DefaultType.DatetimeNow, name: 'Current time' },
    { value: DefaultType.UUID, name: 'UUID' }
  ];
  contextNew = new Singleton(() => new ViewContext(this.currentEnvironmentStore));
  booleanFieldStyle = BooleanFieldStyle;

  constructor(
    @Optional() private context: ViewContext,
    @Optional() private contextElement: ViewContextElement,
    @Optional() private contextTokenProvider: ViewContextTokenProvider,
    @Optional() private popoverComponent: PopoverComponent,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control.controls.field.valueChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.updateParamsComponentData());

    this.updateParamsComponentData();
  }

  ngOnDestroy(): void {}

  getContextProviders() {
    return [
      {
        provide: ViewContext,
        useFactory: () => {
          if (this.context) {
            return this.context;
          } else {
            return this.contextNew.get();
          }
        },
        deps: []
      },
      {
        provide: ViewContextTokenProvider,
        useFactory: (viewContext: ViewContext, projectGroupStore: ProjectGroupStore) => {
          if (this.contextTokenProvider) {
            return this.contextTokenProvider;
          } else {
            return new ViewContextTokenProvider(viewContext, projectGroupStore);
          }
        },
        deps: [ViewContext, ProjectGroupStore]
      }
    ];
  }

  updateParamsComponentData() {
    const item = getFieldDescriptionByType(this.control.value['field']);
    const components = getFieldComponentsByName(item.name);

    if (!components || !components.viewParamsComponent) {
      this.viewParamsComponentData = undefined;
    } else {
      this.viewParamsComponentData = {
        component: components.viewParamsComponent,
        inputs: {
          control: this.control.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement
        },
        providers: this.getContextProviders()
      };
    }

    if (!components || !components.dataParamsComponent) {
      this.dataParamsComponentData = undefined;
    } else {
      this.dataParamsComponentData = {
        component: components.dataParamsComponent,
        inputs: {
          control: this.control.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement
        },
        providers: this.getContextProviders()
      };
    }

    this.cd.markForCheck();
  }

  save() {
    if (this.popoverComponent) {
      this.popoverComponent.close();
    }

    this.saveRequested.emit();
  }

  requestDelete() {
    if (this.popoverComponent) {
      this.popoverComponent.close();
    }

    this.deleteRequested.emit();
  }
}

import { Injectable, Injector, OnDestroy } from '@angular/core';

import { PopupRef, PopupService } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

import { ModelApiPopupComponent } from '../../components/model-api-popup/model-api-popup.component';

@Injectable()
export class ModelApiController implements OnDestroy {
  constructor(private injector: Injector, private popupService: PopupService) {}

  ngOnDestroy(): void {}

  open(options: { resource: Resource; modelDescription: ModelDescription }): PopupRef<ModelApiPopupComponent> {
    return this.popupService.showComponent<ModelApiPopupComponent>({
      component: ModelApiPopupComponent,
      injector: this.injector,
      inputs: {
        resource: options.resource,
        modelDescription: options.modelDescription
      },
      scrollable: true
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore, Environment } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { ProjectEnvironmentCreatePopupForm } from './project-environment-create-popup.form';

@Component({
  selector: 'app-project-environment-create-popup',
  templateUrl: './project-environment-create-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectEnvironmentCreatePopupForm]
})
export class ProjectEnvironmentCreatePopupComponent implements OnInit, OnDestroy {
  @Input() source: string;
  @Output() created = new EventEmitter<Environment>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    public form: ProjectEnvironmentCreatePopupForm,
    private currentProjectStore: CurrentProjectStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.form.init();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.StartedToCreate, {
      Source: this.source
    });
  }

  ngOnDestroy(): void {}

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        environment => {
          this.created.emit(environment);
          this.close();

          this.notificationService.success('Created', 'Environment was successfully added to current App');

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Environment.Created, {
            EnvironmentName: environment.name,
            Source: this.source
          });
        },
        e => {
          this.notificationService.error('Error', 'Saving environment failed');
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }
}

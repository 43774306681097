/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../workflow-step-card/workflow-step-card.component.ngfactory";
import * as i3 from "../workflow-step-card/workflow-step-card.component";
import * as i4 from "./workflow-result.component";
import * as i5 from "../../../services/customize-bar-context/customize-bar.context";
import * as i6 from "../../../services/workflow-edit-context/workflow-edit.context";
import * as i7 from "../../../../action-queries/services/action/action.service";
var styles_WorkflowResultComponent = [];
var RenderType_WorkflowResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WorkflowResultComponent, data: {} });
export { RenderType_WorkflowResultComponent as RenderType_WorkflowResultComponent };
export function View_WorkflowResultComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPluralPipe, [i1.NgLocalization]), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-workflow-step-card", [], [[2, "workflow-step", null], [2, "workflow-step_active", null], [2, "workflow-step_contrast", null], [2, "workflow-step_contrast2", null], [2, "workflow-step_disabled", null], [2, "workflow-step_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_WorkflowStepCardComponent_0, i2.RenderType_WorkflowStepCardComponent)), i0.ɵdid(2, 114688, null, 0, i3.WorkflowStepCardComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], icon: [2, "icon"], indicatorColor: [3, "indicatorColor"], indicatorIcon: [4, "indicatorIcon"], active: [5, "active"], contrast: [6, "contrast"], interactive: [7, "interactive"], connectorTop: [8, "connectorTop"], connectorBottom: [9, "connectorBottom"] }, null), i0.ɵpod(3, { "=1": 0, other: 1 }), i0.ɵppd(4, 2), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = "Workflow result"; var currVal_7 = i0.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.outputs.length, _ck(_v, 3, 0, "# output", "# outputs"))); var currVal_8 = "workflow"; var currVal_9 = _co.statusColor; var currVal_10 = _co.statusIcon; var currVal_11 = i0.ɵunv(_v, 2, 5, i0.ɵnov(_v, 5).transform(_co.customizing$)); var currVal_12 = true; var currVal_13 = true; var currVal_14 = _co.connectorTop; var currVal_15 = false; _ck(_v, 2, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).cls; var currVal_1 = i0.ɵnov(_v, 2).activeClass; var currVal_2 = i0.ɵnov(_v, 2).contrastClass; var currVal_3 = i0.ɵnov(_v, 2).contrast2Class; var currVal_4 = i0.ɵnov(_v, 2).disabledClass; var currVal_5 = i0.ɵnov(_v, 2).interactiveClass; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_WorkflowResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-workflow-result", [], null, null, null, View_WorkflowResultComponent_0, RenderType_WorkflowResultComponent)), i0.ɵdid(1, 245760, null, 0, i4.WorkflowResultComponent, [i5.CustomizeBarContext, i6.WorkflowEditContext, i7.ActionService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkflowResultComponentNgFactory = i0.ɵccf("app-workflow-result", i4.WorkflowResultComponent, View_WorkflowResultComponent_Host_0, { workflow: "workflow", workflowEditable: "workflowEditable", resultOutputs: "resultOutputs", statusIcon: "statusIcon", statusColor: "statusColor", connectorTop: "connectorTop", context: "context", contextElement: "contextElement" }, {}, []);
export { WorkflowResultComponentNgFactory as WorkflowResultComponentNgFactory };

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AdminMode } from '@modules/admin-mode';
import { ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, openUrl } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class DefaultEnvironmentRedirectGuard implements CanActivateChild {
  constructor(private projectsStore: ProjectsStore, private routingService: RoutingService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.projectsStore.getFirst().pipe(
      map(projects => {
        const modeSegment = childRoute.data['routeWithVersion'] ? 1 : 0;
        const modeName = childRoute.parent.url[modeSegment].path as AdminMode;
        const projectNameSegment = childRoute.data['routeWithVersion'] ? 2 : 1;
        const projectName = childRoute.parent.url[projectNameSegment].path;
        const project = projects ? projects.find(item => item.uniqueName == projectName) : undefined;
        const appLink = childRoute.url.map(item => item.path);

        if (!project) {
          // Ignore error to show error inside RedirectComponent
          return true;
        }

        if (project.defaultEnvironment) {
          const link = project.linkWithProtocol(appLink, {
            environmentName: project.defaultEnvironment.uniqueName,
            mode: modeName
          });

          if (link.link) {
            this.routingService.navigate(link.link, { fragment: childRoute.fragment });
          } else if (link.href) {
            if (isSet(childRoute.fragment)) {
              link.href = `${link.href}#${childRoute.fragment}`;
            }

            openUrl(link.href);
          }
        }

        return false;
      }),
      catchError(() => {
        this.routingService.navigate(['/']);
        return of(false);
      })
    );
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import concat from 'lodash/concat';
import values from 'lodash/values';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { TintStyle } from '@modules/actions';
import {
  MessageUserActivity,
  UserActivity,
  UserActivityListStore,
  UserActivityService,
  UserActivityType
} from '@modules/activities';
import { CustomizeService } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { SendEvent, TimelinePopupFooterComponent } from '../timeline-popup-footer/timeline-popup-footer.component';

@Component({
  selector: 'app-timeline-popup',
  templateUrl: './timeline-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserActivityListStore, MessageUserActivity]
})
export class TimelinePopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() baseParams = {};
  @Input() closeObs: Observable<void>;
  @Output() closeObsEvent = new EventEmitter<void>();

  @ViewChild('header') header: ElementRef<HTMLElement>;
  @ViewChildren('separator') separator = new QueryList<ElementRef<HTMLElement>>();
  @ViewChild('sidePopupWrap') sidePopupWrap: ElementRef<HTMLElement>;
  @ViewChild('sidePopup') sidePopup: ElementRef<HTMLElement>;
  @ViewChild('closeButton') closeButton: ElementRef<HTMLElement>;
  @ViewChild('footer') footerElement: ElementRef<HTMLElement>;
  @ViewChild(TimelinePopupFooterComponent) footer: TimelinePopupFooterComponent;

  params = {};
  scrollHeight: number;
  initItems = false;
  sendMessage = false;
  newMessages = false;
  loadingItems = true;
  error: string = undefined;
  items: { item: UserActivity | MessageUserActivity; firstDayActivity?: boolean }[] = [];
  showAnimation = true;

  filter = {
    messages: [UserActivityType.Message],
    activity: [
      UserActivityType.DashboardList,
      UserActivityType.ModelList,
      UserActivityType.ModelDetail,
      UserActivityType.ModelCreate,
      UserActivityType.ModelUpdate,
      UserActivityType.ModelDelete,
      UserActivityType.ModelMassDelete,
      UserActivityType.ModelMassEdit
    ]
  };

  showPopover = false;

  formHeight: number;

  formFilter: FormGroup = new FormGroup({
    messages: new FormControl(true),
    activity: new FormControl(true)
  });

  tlVisible = new TimelineMax();

  tintStyles = TintStyle;

  constructor(
    private customizeService: CustomizeService,
    private renderer2: Renderer2,
    private userActivityService: UserActivityService,
    private popupComponent: BasePopupComponent,
    private router: Router,
    public userActivityListStore: UserActivityListStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  trackByFn(index, item: { item: MessageUserActivity | UserActivity; firstDayActivity?: boolean }) {
    return item.item.dateAdd;
  }

  resetFilter(): void {
    this.showPopover = false;
    this.formFilter.reset({
      messages: true,
      activity: true
    });
  }

  hasFilters() {
    return values(this.formFilter.value).some(item => item === false);
  }

  ngOnInit() {
    if (this.closeObs) {
      this.closeObs.pipe(untilDestroyed(this)).subscribe(() => {
        this.close();
      });
    }

    // this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(value => {
    //   if (value) {
    //     this.close();
    //   }
    // });

    this.params = {
      ...this.baseParams,
      order_by: '-date_add',
      activity_type_in: concat(this.filter.messages, this.filter.activity).join()
    };

    this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
    this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
    this.userActivityListStore.params = this.params;
    this.userActivityListStore.reset();
    this.userActivityListStore.getNext();

    this.userActivityListStore.items$
      .pipe(
        map(value => {
          if (value) {
            let date: string;
            return [...value].reverse().map(item => {
              if (date === undefined) {
                date = moment(item.dateAdd).format('LL');
                return {
                  item: item,
                  firstDayActivity: true
                };
              } else {
                if (moment(item.dateAdd).format('LL') !== date) {
                  date = moment(item.dateAdd).format('LL');
                  return {
                    item: item,
                    firstDayActivity: true
                  };
                } else {
                  return {
                    item: item,
                    firstDayActivity: false
                  };
                }
              }
            });
          } else {
            return undefined;
          }
        })
      )
      .subscribe(value => {
        this.items = value;
        if (this.items === undefined) {
          return;
        }
        this.loadingItems = false;
        this.cd.detectChanges();
        this.setFormBottomPadding();

        if (!this.initItems) {
          this.initItems = true;
          setTimeout(() => {
            this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
            this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
            this.setSeparators();
          }, 500);
        } else if (this.sendMessage) {
          this.sendMessage = false;
          this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
          this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
          this.setSeparators();
        } else if (this.newMessages) {
          this.newMessages = false;
          if (
            this.scrollHeight ===
            this.sidePopup.nativeElement.scrollTop + this.sidePopup.nativeElement.offsetHeight
          ) {
            this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
            this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
          }
          this.setSeparators();
        } else {
          this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight - this.scrollHeight);
          this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
          this.setSeparators();
        }
      });

    this.userActivityService
      .subscribeUserActivities(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.params['object_type'],
        this.params['object_id']
      )
      .pipe(untilDestroyed(this))
      .subscribe((value: UserActivity) => {
        this.newMessages = true;
        this.userActivityListStore.prependItems([value]);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.formFilter.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          const activity_type_in = [];

          if (value.messages) {
            activity_type_in.push(this.filter.messages);
          }
          if (value.activity) {
            activity_type_in.push(this.filter.activity);
          }

          const params = {
            ...this.params,
            activity_type_in: activity_type_in.length === 0 ? 'void' : activity_type_in.join()
          };

          this.loadingItems = true;
          this.error = undefined;
          this.cd.markForCheck();
          this.userActivityListStore.params = params;
          this.userActivityListStore.reset();
          return this.userActivityListStore.getNext();
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.loadingItems = false;
          setTimeout(() => {
            this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
            this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
            this.setSeparators();
          }, 500);
          this.cd.markForCheck();
        },
        error => {
          this.loadingItems = false;
          this.error = error;
          this.cd.markForCheck();
        }
      );
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.close();
      });

    this.show();
  }

  setFormBottomPadding(): void {
    if (this.formHeight === undefined) {
      this.formHeight = this.footerElement.nativeElement.offsetHeight;
      this.cd.detectChanges();
    } else if (this.footerElement.nativeElement.offsetHeight !== this.formHeight) {
      if (
        this.sidePopup.nativeElement.scrollHeight ===
        this.sidePopup.nativeElement.scrollTop + this.sidePopup.nativeElement.offsetHeight
      ) {
        this.formHeight = this.footerElement.nativeElement.offsetHeight;
        this.cd.detectChanges();
        this.sidePopup.nativeElement.scrollTo(0, this.sidePopup.nativeElement.scrollHeight);
      } else {
        this.formHeight = this.footerElement.nativeElement.offsetHeight;
        this.cd.detectChanges();
      }
    }
  }

  popoverToggle(): void {
    this.showPopover = !this.showPopover;
  }

  submit(event: SendEvent): void {
    this.sendMessage = true;
    this.cd.markForCheck();

    this.userActivityService
      .createInstance(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        event.activity,
        event.mentions
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.footer.reset();
          this.setSeparators();
          this.sendMessage = false;
          this.footer.focus();
          this.cd.markForCheck();
        },
        () => {
          this.sendMessage = false;
          this.footer.focus();
          this.cd.markForCheck();
        }
      );
  }

  show(): void {
    this.tlVisible
      .fromTo(
        this.sidePopupWrap.nativeElement,
        0.6,
        {
          xPercent: 100
        },
        {
          xPercent: 0,
          ease: Power2.easeOut
        }
      )
      .add(() => {
        this.showAnimation = false;
        this.footer.focus();
        this.cd.markForCheck();

        fromEvent<WheelEvent>(this.sidePopup.nativeElement, 'wheel')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (this.sidePopup.nativeElement.scrollHeight !== this.scrollHeight) {
              this.scrollHeight = this.sidePopup.nativeElement.scrollHeight;
            }
            this.setSeparators();
            const scrollTop = this.sidePopup.nativeElement.scrollTop;
            if (scrollTop <= 0 && !this.loadingItems) {
              this.loadingItems = true;
              this.userActivityListStore.getNext();
            }
          });
      });
  }

  setSeparators(): void {
    const topSeparators = this.separator.filter(
      item =>
        item.nativeElement.getBoundingClientRect().top <=
        this.header.nativeElement.getBoundingClientRect().height + item.nativeElement.getBoundingClientRect().height / 2
    );

    const bottomSeparators = this.separator.filter(
      item => item.nativeElement.getBoundingClientRect().top > this.header.nativeElement.getBoundingClientRect().height
    );

    topSeparators.forEach((item, index) => {
      if (index === topSeparators.length - 1) {
        this.renderer2.setStyle(item.nativeElement, 'opacity', 1);
      } else {
        this.renderer2.setStyle(item.nativeElement, 'opacity', 0);
      }
    });

    bottomSeparators.forEach(item => {
      this.renderer2.setStyle(item.nativeElement, 'opacity', 1);
    });
  }

  close(): void {
    this.closeObsEvent.emit();
    this.tlVisible
      .clear()
      .set(
        this.closeButton.nativeElement,
        {
          pointerEvents: 'none'
        },
        0
      )
      .to(
        this.sidePopupWrap.nativeElement,
        0.4,
        {
          xPercent: 100,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.popupComponent.close();
      });
  }
}

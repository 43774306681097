var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { TimeOutputFormat } from '@modules/fields';
var TimeFieldDataParamsForm = /** @class */ (function () {
    function TimeFieldDataParamsForm(fb) {
        this.fb = fb;
        this.outputFormat = [
            { value: TimeOutputFormat.ISO, name: 'hh:mm:ss' },
            { value: TimeOutputFormat.Number, name: 'Google Sheets' }
        ];
        this.form = this.fb.group({
            output_format: new FormControl(TimeOutputFormat.ISO)
        });
    }
    TimeFieldDataParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            this.form.patchValue(control.value, { emitEvent: false });
        }
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    TimeFieldDataParamsForm.prototype.submit = function () {
        var data = this.form.value;
        this.control.patchValue(__assign({}, this.control.value, data));
    };
    return TimeFieldDataParamsForm;
}());
export { TimeFieldDataParamsForm };

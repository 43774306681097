import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, publishLast, refCount, retry, switchMap, timeout } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';

import { Region } from '../../data/region';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(private http: HttpClient, private apiService: ApiService, private appConfigService: AppConfigService) {}

  get(params = {}): Observable<Region[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('regions/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Region().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDefault(): Observable<Region> {
    return this.get().pipe(map(result => result.find(item => item.default)));
  }

  getDefaultJetBridge(): Observable<{
    region?: Region;
    url: string;
  }> {
    if (this.appConfigService.jetBridgeRegions) {
      return this.getDefault().pipe(
        map(result => {
          if (!result) {
            return;
          }
          return {
            region: result,
            url: result.jetBridgeCloudUrl
          };
        })
      );
    } else {
      return of({
        url: this.appConfigService.jetBridgeCloudBaseUrl
      });
    }
  }

  getDetail(uid: string): Observable<Region> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`regions/${uid}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new Region().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getLatency(region: Region, timeoutMs = 10000, retries = 1): Observable<number> {
    const url = region.jetBridgeCloudUrl;
    const startFrom = new Date().getTime();

    return this.http.get(url).pipe(
      map(() => {
        return new Date().getTime() - startFrom;
      }),
      timeout(timeoutMs),
      retry(retries),
      catchError(() => of(undefined)),
      publishLast(),
      refCount()
    );
  }
}

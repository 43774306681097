import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CurrentUserStore } from '@modules/users';
import {
  CustomViewTemplate,
  CustomViewTemplateListStore,
  CustomViewTemplateType,
  customViewTemplateTypeItems,
  Frame,
  View
} from '@modules/views';
import { isSet } from '@shared';

import { ViewEditorController } from '../../services/view-editor-controller/view-editor.controller';

interface FilterItem {
  type?: CustomViewTemplateType;
  active?: boolean;
  label: string;
  icon?: string;
  staff?: boolean;
}

export interface CustomViewTemplateFilter {
  type?: CustomViewTemplateType;
}

export const FEATURED_TOKEN = new InjectionToken<CustomViewTemplateListStore>('FEATURED_TOKEN');
export const LIST_TOKEN = new InjectionToken<CustomViewTemplateListStore>('LIST_TOKEN');

@Component({
  selector: 'app-custom-view-templates-list',
  templateUrl: './custom-view-templates-list.component.html',
  providers: [
    { provide: FEATURED_TOKEN, useClass: CustomViewTemplateListStore },
    { provide: LIST_TOKEN, useClass: CustomViewTemplateListStore }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesListComponent implements OnInit, OnDestroy {
  @Input() title = 'Components marketplace';
  @Input() initialFilter: CustomViewTemplateFilter = {};
  @Input() nameEditingEnabled = true;
  @Input() viewCreateEnabled = true;
  @Input() stateSelectedEnabled = false;
  @Input() componentLabel = 'component';
  @Output() selectTemplate = new EventEmitter<CustomViewTemplate>();
  @Output() createdView = new EventEmitter<View>();
  @Output() close = new EventEmitter<void>();

  loading = false;
  moreLoading = false;
  error = false;
  featuredItems$: Observable<CustomViewTemplate[]>;
  featuredLoading$: Observable<boolean>;
  listItems$: Observable<CustomViewTemplate[]>;
  listLoading$: Observable<boolean>;
  filters: FilterItem[] = [
    {
      label: 'All'
    },
    ...customViewTemplateTypeItems.map(item => {
      return {
        type: item.type,
        label: item.label,
        icon: item.icon
      };
    }),
    {
      active: false,
      label: 'Disabled',
      icon: 'close',
      staff: true
    }
  ];
  currentFilterItem: FilterItem;
  perRow = 4;

  constructor(
    @Inject(FEATURED_TOKEN) private featuredStore: CustomViewTemplateListStore,
    @Inject(LIST_TOKEN) public listStore: CustomViewTemplateListStore,
    public currentUserStore: CurrentUserStore,
    private viewEditorController: ViewEditorController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.initialFilter.type) {
      this.filterByTemplateType(this.initialFilter.type);
    }

    if (!this.currentFilterItem) {
      this.currentFilterItem = this.filters[0];
    }

    this.featuredStore.featured = true;
    this.featuredStore.perPage = 16;
    this.featuredItems$ = this.featuredStore.items$;
    this.featuredLoading$ = this.featuredStore.loading$;

    this.listStore.featured = false;
    this.listStore.perPage = 16;
    this.listItems$ = this.listStore.items$;
    this.listLoading$ = this.listStore.loading$;

    this.fetch();

    const scrollContainer = this.getScrollContainer();
    if (scrollContainer) {
      fromEvent(scrollContainer, 'scroll')
        .pipe(
          filter(() => !this.loading && !this.moreLoading),
          untilDestroyed(this)
        )
        .subscribe(() => {
          const scrollTop = scrollContainer.scrollTop;
          const viewportHeight = window.innerHeight;
          const viewportBottom = scrollTop + viewportHeight;
          const contentHeight = scrollContainer.scrollHeight;

          if (contentHeight - viewportBottom <= 100) {
            this.loadMore();
          }
        });
    }
  }

  ngOnDestroy(): void {}

  getScrollContainer(): HTMLElement {
    return document.querySelector<HTMLElement>('.popup-container');
  }

  setCurrentFilter(filterItem: FilterItem, force = false) {
    if (this.currentFilterItem === filterItem && !force) {
      return;
    }

    this.currentFilterItem = filterItem;
    this.cd.markForCheck();
    this.fetch();
  }

  filterByTemplateType(type: CustomViewTemplateType) {
    const filterItem = this.filters.find(item => item.type == type);
    if (filterItem) {
      this.setCurrentFilter(filterItem, true);
    }
  }

  fetch() {
    this.featuredStore.type = this.currentFilterItem ? this.currentFilterItem.type : undefined;

    if (this.currentFilterItem && isSet(this.currentFilterItem.active)) {
      this.featuredStore.all = true;
      this.featuredStore.active = this.currentFilterItem.active;
    } else {
      this.featuredStore.all = false;
      this.featuredStore.active = undefined;
    }

    this.listStore.type = this.currentFilterItem ? this.currentFilterItem.type : undefined;

    if (this.currentFilterItem && isSet(this.currentFilterItem.active)) {
      this.listStore.all = true;
      this.listStore.active = this.currentFilterItem.active;
    } else {
      this.listStore.all = false;
      this.listStore.active = undefined;
    }

    this.featuredStore.reset();
    this.listStore.reset();

    this.loading = true;
    this.moreLoading = false;
    this.error = false;
    this.cd.markForCheck();

    combineLatest(this.featuredStore.getNext(), this.listStore.getNext())
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.error = true;
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  loadMore() {
    if (!this.listStore.hasMore) {
      return;
    }

    this.moreLoading = true;
    this.cd.markForCheck();

    this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.moreLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.moreLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  createView() {
    const view = new View();

    view.generateId();
    view.name = 'New View';
    view.frame = new Frame({ width: 300, height: 240 });

    this.viewEditorController
      .open({
        create: true,
        view: view,
        componentLabel: this.componentLabel,
        nameEditingEnabled: this.nameEditingEnabled,
        stateSelectedEnabled: this.stateSelectedEnabled,
        submitLabel: 'Create ' + this.componentLabel,
        templatesEnabled: false,
        analyticsSource: 'view_templates_list'
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.createdView.emit(result.view);
      });
  }
}

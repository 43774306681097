import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AdminComponentService {
  public contentWithSidebar$ = new BehaviorSubject<boolean>(false);
  public contentClick = new Subject<MouseEvent>();
  public contentMousedown = new Subject<MouseEvent>();

  constructor() {}
}

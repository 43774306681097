var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay } from 'rxjs/operators';
import { PopoverService } from '@common/popover';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { AddFilterPopupComponent } from '@modules/filters-edit';
import { ModelDescriptionStore } from '@modules/model-queries';
import { FieldComponent } from '../field/field.component';
var FiltersFieldComponent = /** @class */ (function (_super) {
    __extends(FiltersFieldComponent, _super);
    function FiltersFieldComponent(injector, cd, modelDescriptionStore, popoverService) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.cd = cd;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.popoverService = popoverService;
        return _this;
    }
    FiltersFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.form) {
            this.form.controls[this.field.name].valueChanges
                .pipe(delay(1), // TODO: refactor delay(1)
            untilDestroyed(this))
                .subscribe(function (value) { return _this.updateFilterItems(); });
        }
        this.updateFilterItems();
        this.updateModelDescription();
    };
    FiltersFieldComponent.prototype.ngOnDestroy = function () { };
    FiltersFieldComponent.prototype.ngOnChanges = function (changes) {
        if (!this.form && changes['value']) {
            this.updateFilterItems();
        }
        if (changes['field']) {
            this.updateModelDescription();
        }
    };
    FiltersFieldComponent.prototype.updateFilterItems = function () {
        if (this.form) {
            this.filterItems = this.form.value[this.field.name] || [];
        }
        else {
            this.filterItems = this.value || [];
        }
        this.cd.markForCheck();
    };
    FiltersFieldComponent.prototype.updateModelDescription = function () {
        var _this = this;
        var model = this.field.params['modelDescription'];
        if (!model) {
            this.modelDescription = undefined;
            this.cd.markForCheck();
            return;
        }
        this.modelDescriptionStore
            .getDetailFirst(model['model'])
            .pipe(untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.modelDescription = modelDescription;
            _this.cd.markForCheck();
        });
    };
    FiltersFieldComponent.prototype.addFilter = function (sourceElement) {
        var _this = this;
        if (!this.filterItems || !this.modelDescription) {
            return;
        }
        this.popoverService.create({
            component: AddFilterPopupComponent,
            inputs: {
                modelDescription: this.modelDescription
            },
            outputs: {
                added: [
                    function (item) {
                        _this.setValue(_this.filterItems.concat([item]));
                    }
                ]
            },
            injector: this.injector
        }, sourceElement);
    };
    FiltersFieldComponent.prototype.removeFilter = function (filterItem) {
        this.setValue(this.filterItems.filter(function (item) { return item != filterItem; }));
    };
    FiltersFieldComponent.prototype.changeFilterItems = function (filterItems) {
        this.setValue(filterItems);
    };
    FiltersFieldComponent.prototype.setValue = function (value) {
        this.filterItems = value;
        this.cd.markForCheck();
        if (this.form) {
            var params = {};
            params[this.field.name] = this.filterItems;
            this.form.patchValue(params);
        }
    };
    return FiltersFieldComponent;
}(FieldComponent));
export { FiltersFieldComponent };
registerFieldComponent(FieldType.Filters, FiltersFieldComponent);

export const ORDER_BY_PARAM = '_order_by';
export const PAGE_PARAM = 'page';
export const PER_PAGE_PARAM = '_per_page';
export const CURSOR_PREV_PARAM = '_cursor_prev';
export const CURSOR_NEXT_PARAM = '_cursor_next';
export const NO_PAGINATION_PARAM = '_no_pagination';
export const SEARCH_PARAM = '_search';
export const SEGMENT_PARAM = '_segment';
export const TYPE_PARAM = '_type';
export const DATE_PARAM = '_date';
export const ACTION_BULK_INPUTS_PARAM = '_action_bulk_inputs';
export const NEXT_PAGE_SCROLL_PARAM = '_scroll';

export const SYSTEM_PARAMS = [
  ORDER_BY_PARAM,
  PAGE_PARAM,
  PER_PAGE_PARAM,
  CURSOR_PREV_PARAM,
  CURSOR_NEXT_PARAM,
  NO_PAGINATION_PARAM,
  TYPE_PARAM,
  DATE_PARAM,
  ACTION_BULK_INPUTS_PARAM,
  NEXT_PAGE_SCROLL_PARAM
];

export const BUILT_IN_PARAMS = [...SYSTEM_PARAMS, SEARCH_PARAM, SEGMENT_PARAM];

import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { RoutingService } from '@modules/routing';
import { View } from '@modules/views';
import { CustomViewTemplatesListComponent } from '../custom-view-templates-list/custom-view-templates-list.component';
var RouteType;
(function (RouteType) {
    RouteType["List"] = "list";
    RouteType["Detail"] = "detail";
    RouteType["Update"] = "update";
})(RouteType || (RouteType = {}));
var CustomViewTemplatesComponent = /** @class */ (function () {
    function CustomViewTemplatesComponent(popupComponent, routing, analyticsService, cd) {
        this.popupComponent = popupComponent;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.initialFilter = {};
        this.updateOnSelect = false;
        this.nameEditingEnabled = true;
        this.viewCustomizeEnabled = true;
        this.viewCreateEnabled = false;
        this.stateSelectedEnabled = false;
        this.componentLabel = 'component';
        this.selectView = new EventEmitter();
        this.templateUpdated = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.defaultRoute = {
            type: RouteType.List
        };
        this.route = this.defaultRoute;
        this.routeTypes = RouteType;
    }
    Object.defineProperty(CustomViewTemplatesComponent.prototype, "detailRoute", {
        get: function () {
            return this.route;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomViewTemplatesComponent.prototype, "updateRoute", {
        get: function () {
            return this.route;
        },
        enumerable: true,
        configurable: true
    });
    CustomViewTemplatesComponent.prototype.ngOnInit = function () { };
    CustomViewTemplatesComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    CustomViewTemplatesComponent.prototype.openList = function (options) {
        if (options === void 0) { options = {}; }
        this.route = { type: RouteType.List };
        this.cd.markForCheck();
        if (options.type) {
            this.listComponent.filterByTemplateType(options.type);
        }
    };
    CustomViewTemplatesComponent.prototype.openDetail = function (id) {
        this.route = { type: RouteType.Detail, id: id };
        this.cd.markForCheck();
    };
    CustomViewTemplatesComponent.prototype.openUpdate = function (id) {
        this.route = { type: RouteType.Update, id: id };
        this.cd.markForCheck();
    };
    CustomViewTemplatesComponent.prototype.onTemplateSelected = function (template) {
        if (this.updateOnSelect) {
            this.openUpdate(template.id);
        }
        else {
            this.openDetail(template.id);
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.ViewOpened, {
                Name: template.name,
                Source: this.analyticsSource
            });
        }
    };
    CustomViewTemplatesComponent.prototype.onViewSelected = function (view, template) {
        this.selectView.emit({ view: view, template: template });
        this.close();
    };
    CustomViewTemplatesComponent.prototype.onTemplateSaved = function (template) {
        if (this.updateOnSelect) {
            this.templateUpdated.emit(template);
            this.close();
        }
        else {
            this.openDetail(template.id);
            this.listComponent.fetch();
        }
    };
    CustomViewTemplatesComponent.prototype.cancel = function () {
        this.cancelled.emit();
        this.close();
    };
    return CustomViewTemplatesComponent;
}());
export { CustomViewTemplatesComponent };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-copy-to-clipboard',
  templateUrl: './customize-bar-action-edit-type-copy-to-clipboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeCopyToClipboardComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();

  constructor() {}

  ngOnInit() {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, throttleTime } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import {
  CustomizeService,
  ElementType,
  FieldElementItem,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import {
  applyParamInput$,
  BaseField,
  EditableFlexField,
  FormField,
  getFieldDescriptionByType,
  getValidatorByType,
  Input as FieldInput,
  LOADING_VALUE,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { getDefaultValue } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

export const VALUE_OUTPUT = 'value';
const STANDALONE_FORM_CONTROL = 'field';

interface ElementState {
  element?: FieldElementItem;
  settings?: EditableFlexField;
  readonly?: boolean;
  valueInput?: FieldInput;
  disableInput?: FieldInput;
  name?: string;
  field?: FormField;
  tooltip?: string;
}

function getElementStateField(state: ElementState): Object {
  return {
    field: state.field ? state.field.serialize() : undefined
  };
}

function getElementStateInfo(state: ElementState): Object {
  return {
    name: state.name,
    field: state.field ? state.field.field : undefined
  };
}

function getElementStateOutputs(state: ElementState): Object {
  return {
    field: state.field ? state.field.field : undefined,
    params: state.field ? state.field.params : undefined
  };
}

function getElementStateValue(state: ElementState): Object {
  return {
    field: state.field ? state.field.field : undefined,
    valueInput: state.valueInput ? state.valueInput.serialize() : undefined
  };
}

function getElementStateDisabled(state: ElementState): Object {
  return {
    disableInput: state.disableInput ? state.disableInput.serialize() : undefined
  };
}

function getElementStateValidators(state: ElementState): Object {
  return state.field
    ? {
        required: state.field.required,
        validatorType: state.field.validatorType,
        validatorParams: state.field.validatorParams
      }
    : {};
}

class FieldFormControl extends FormControl {
  private field?: BaseField;

  init(options: { field?: BaseField } = {}) {
    this.field = options.field;
  }

  cleanValue(value: any): any {
    if (!this.field) {
      return value;
    }

    const fieldDescription = getFieldDescriptionByType(this.field.field);

    if (fieldDescription.cleanValue) {
      value = fieldDescription.cleanValue(value, {
        ...this.field,
        field: this.field.field,
        params: this.field.params,
        description: undefined
      });
    }

    return value;
  }

  patchValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    value = this.cleanValue(value);
    super.patchValue(value, options);
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    value = this.cleanValue(value);
    super.setValue(value, options);
  }
}

@Component({
  selector: 'app-auto-field-element',
  templateUrl: './auto-field-element.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoFieldElementComponent extends BaseElementComponent<FieldElementItem>
  implements OnInit, OnDestroy, OnChanges {
  @Input() element: FieldElementItem;

  state: ElementState = {};

  control = new FieldFormControl();
  form = new FormGroup({ [STANDALONE_FORM_CONTROL]: this.control });
  defaultValueSubscription: Subscription;
  disabledSubscription: Subscription;
  updateOutputSubscriptions: Subscription[] = [];
  updateValidatorSubscription: Subscription;
  baseValidator: ValidatorFn[] = [];
  customizeEnabled$: Observable<boolean>;
  loadingValue = false;

  constructor(
    private customizeService: CustomizeService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    private actionControllerService: ActionControllerService,
    public viewContextElement: ViewContextElement,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoFieldElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
    }

    if (changes.element) {
      this.elementOnChange(this.element);
    }
  }

  trackChanges() {
    this.element$
      .pipe(
        map(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(element: FieldElementItem): ElementState {
    const field = cloneDeep(element.formField);

    field.name = STANDALONE_FORM_CONTROL;
    field.params = { ...field.params, classes: ['input_fill', 'select_fill'] };

    return {
      element: element,
      settings: cloneDeep(element.settings),
      readonly: element.settings ? !element.settings.editable : false,
      valueInput: element.settings && element.settings.valueInput,
      disableInput: element.disableInput,
      name: element.name,
      field: field,
      tooltip: element.tooltip
    };
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateField(state), getElementStateField(this.state))) {
      this.updateField(state);
    }

    if (!isEqual(getElementStateInfo(state), getElementStateInfo(this.state))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.state))) {
      this.updateContextOutputs(state);
      this.updateContextActions(state);
    }

    if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
      this.updateDefaultValue(state);
      this.updateValueOutput();
    }

    if (!isEqual(getElementStateDisabled(state), getElementStateDisabled(this.state))) {
      this.updateDisabled(state);
    }

    if (!isEqual(getElementStateValidators(state), getElementStateValidators(this.state))) {
      this.updateValidators(state);
    }
  }

  updateField(state: ElementState) {
    const field: BaseField = state.field
      ? {
          field: state.field.field,
          params: state.field.params
        }
      : undefined;

    this.control.init({ field: field });
  }

  updateDefaultValue(state: ElementState) {
    if (this.defaultValueSubscription) {
      this.defaultValueSubscription.unsubscribe();
      this.defaultValueSubscription = undefined;
    }

    let value$: Observable<any>;
    const fieldTypeDefault = getDefaultValue(state.settings);

    if (state.valueInput) {
      value$ = applyParamInput$<any>(state.valueInput, {
        context: this.context,
        defaultValue: fieldTypeDefault,
        handleLoading: true
      }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)));
    } else {
      value$ = of(fieldTypeDefault);
    }

    this.defaultValueSubscription = value$.pipe(untilDestroyed(this)).subscribe(value => {
      this.setControlDefault(value);
    });
  }

  setControlDefault(value?: any) {
    if (value === LOADING_VALUE) {
      this.loadingValue = true;
      this.cd.markForCheck();
    } else {
      this.loadingValue = false;
      this.cd.markForCheck();

      this.control.patchValue(value);
      this.control.markAsPristine();
    }
  }

  updateDisabled(state: ElementState) {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    let value$: Observable<boolean>;

    if (state.disableInput) {
      value$ = applyParamInput$<boolean>(state.disableInput, {
        context: this.context,
        defaultValue: false,
        handleLoading: true
      }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)));
    } else {
      value$ = of(false);
    }

    this.disabledSubscription = value$.pipe(untilDestroyed(this)).subscribe(disabled => {
      this.setControlDisabled(disabled);
    });
  }

  setControlDisabled(disabled: boolean) {
    if (disabled === LOADING_VALUE) {
      disabled = true;
    }

    if (disabled && !this.control.disabled) {
      this.control.disable();
    } else if (!disabled && this.control.disabled) {
      this.control.enable();
    }

    this.cd.markForCheck();
  }

  updateValidators(state: ElementState): void {
    if (this.updateValidatorSubscription) {
      this.updateValidatorSubscription.unsubscribe();
      this.updateValidatorSubscription = undefined;
    }

    const validators: ValidatorFn[] = [...this.baseValidator];

    if (state.field) {
      if (state.field.required) {
        validators.push(Validators.required);
      }

      const validator = getValidatorByType(state.field.validatorType, state.field.validatorParams, {
        context: this.context,
        contextElement: this.viewContextElement
      });

      if (validator.func) {
        validators.push(validator.func);
      }

      if (validator.contextDependent && this.context) {
        const control = this.control;
        this.updateValidatorSubscription = this.context.outputValues$
          .pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
          .pipe(untilDestroyed(this))
          .subscribe(() => control.updateValueAndValidity());
      }
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  updateValueOutput() {
    this.updateOutputSubscriptions.forEach(item => item.unsubscribe());
    this.updateOutputSubscriptions = [];

    const control = this.control;

    this.updateOutputSubscriptions.push(
      controlValue(control)
        .pipe(
          // TODO: distinctUntilChanged breaks forms
          // distinctUntilChanged(),
          untilDestroyed(this)
        )
        .subscribe(value => {
          const error = !!control.errors;
          this.viewContextElement.setOutputValue(VALUE_OUTPUT, value, { error: error });
        })
    );

    this.updateOutputSubscriptions.push(
      controlValue(control)
        .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
        .subscribe(value => {
          this.element.onChangeActions.forEach(action => {
            this.actionControllerService
              .execute(action, {
                context: this.context,
                contextElement: this.viewContextElement,
                localContext: {
                  [VALUE_OUTPUT]: value
                },
                injector: this.injector
              })
              .subscribe();
          });
        })
    );
  }

  initContext() {
    const fieldDescription = getFieldDescriptionByType(this.element.formField.field);

    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: fieldDescription.icon,
      allowSkip: true,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
  }

  updateContextInfo(state: ElementState) {
    const fieldDescription = getFieldDescriptionByType(state.field.field);
    this.viewContextElement.initInfo({
      name: state.name,
      icon: fieldDescription.icon,
      getFieldValue: (field, outputs) => {
        return outputs[VALUE_OUTPUT];
      }
    });
  }

  updateContextOutputs(state: ElementState) {
    const fieldDescription = getFieldDescriptionByType(state.field.field);
    this.viewContextElement.setOutputs([
      {
        uniqueName: VALUE_OUTPUT,
        name: 'Value',
        icon: fieldDescription.icon,
        fieldType: state.field.field,
        fieldParams: state.field.params,
        external: true
      }
    ]);
  }

  updateContextActions(state: ElementState) {
    const valueParameter = new ParameterField();

    valueParameter.name = 'value';
    valueParameter.field = state.field.field;
    valueParameter.params = { ...state.field.params };

    this.viewContextElement.setActions([
      {
        uniqueName: 'set_value',
        name: 'Set Value',
        icon: 'edit',
        parameters: [valueParameter],
        handler: params => {
          this.control.setValue(params['value']);
        }
      },
      {
        uniqueName: 'clear_value',
        name: 'Clear Value',
        icon: 'delete',
        parameters: [],
        handler: (options: { pristine?: boolean } = {}) => {
          const fieldDescription = getFieldDescriptionByType(state.field.field);

          this.control.setValue(fieldDescription.defaultValue);

          if (options.pristine) {
            this.control.markAsPristine();
          }
        }
      }
    ]);
  }
}

registerElementComponent({
  type: ElementType.Field,
  component: AutoFieldElementComponent,
  label: 'Field',
  actions: []
});

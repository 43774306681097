/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../field-components/components/field-type-dropdown/field-type-dropdown.component.ngfactory";
import * as i2 from "../../../field-components/components/field-type-dropdown/field-type-dropdown.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../shared/components/autofocus/autofocus.directive";
import * as i5 from "../../../../core/services/app-config/app-config.service";
import * as i6 from "../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i7 from "../../../../common/tips/directives/tip/tip.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i10 from "../../../fields/components/auto-field/auto-field.component";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "./parameter-values-item.component";
var styles_ParameterValuesItemComponent = [];
var RenderType_ParameterValuesItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ParameterValuesItemComponent, data: {} });
export { RenderType_ParameterValuesItemComponent as RenderType_ParameterValuesItemComponent };
function View_ParameterValuesItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-type-dropdown", [["class", "overlay-content overlay-content_animated"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        _co.setFieldTypeDropdownOpened(false);
        var pd_0 = (_co.control.controls.field.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FieldTypeDropdownComponent_0, i1.RenderType_FieldTypeDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i2.FieldTypeDropdownComponent, [], { currentValue: [0, "currentValue"] }, { select: "select" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.field.value; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ParameterValuesItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "input", [["class", "editable-content-input"]], [[2, "editable-content-input_inline", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "keypress"], [null, "blur"], [null, "click"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.onKeyUp($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (_co.onKeyPress($event) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (_co.setRename(false) !== false);
        ad = (pd_6 && ad);
    } if (("click" === en)) {
        var pd_7 = ($event.stopPropagation() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(3, 540672, null, 0, i3.FormControlDirective, [[8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(6, 540672, null, 0, i4.AutofocusDirective, [i0.ElementRef, i5.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null), i0.ɵdid(7, 81920, null, 0, i6.FormControlSelectorDirective, [i0.ElementRef, [2, i3.FormControlDirective], [2, i3.FormControlName]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.renameControl; _ck(_v, 3, 0, currVal_8); var currVal_9 = true; _ck(_v, 6, 0, currVal_9); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = false; var currVal_1 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 5).ngClassValid; var currVal_6 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ParameterValuesItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "editable-content-button"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-pen"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "Rename parameter"; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ParameterValuesItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "editable-content-label"]], [[2, "editable-content-label_inline", null], [2, "editable-content-label_with-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.setRename(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ParameterValuesItemComponent_5)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.hovered$)); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.control.controls.name.value; _ck(_v, 1, 0, currVal_2); }); }
function View_ParameterValuesItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar-collapse__header-title sidebar-collapse__header-title_editable"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterValuesItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterValuesItemComponent_4)), i0.ɵdid(4, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rename; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.rename; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ParameterValuesItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-collapse__header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.name.value; _ck(_v, 1, 0, currVal_0); }); }
function View_ParameterValuesItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-collapse__content"]], [[2, "sidebar-collapse__content_padding", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-auto-field", [], null, null, null, i9.View_AutoFieldComponent_0, i9.RenderType_AutoFieldComponent)), i0.ɵdid(2, 770048, null, 0, i10.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"], autofocus: [3, "autofocus"] }, null), i0.ɵpod(3, { name: 0, field: 1, params: 2, placeholder: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.control; var currVal_2 = _co.createField(_ck(_v, 3, 0, "value", _co.control.controls.field.value, _co.getParams(), "Enter variable value")); var currVal_3 = false; var currVal_4 = true; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_ParameterValuesItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), i0.ɵqud(402653184, 2, { fieldTypeOverlay: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 25, "div", [["class", "sidebar-collapse"]], [[2, "sidebar-collapse_openable", null], [2, "sidebar-collapse_block", null], [2, "sidebar-collapse_opened", null], [2, "sidebar-collapse_disabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.hovered$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hovered$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 22, "div", [["class", "sidebar-collapse__header"]], [[2, "sidebar-collapse__header_error", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 10, "div", [["class", "sidebar-collapse__header-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "sidebar-collapse__header-drag"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, [["field_type_overlay", 2]], null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setFieldTypeDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setFieldTypeDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ParameterValuesItemComponent_1)), i0.ɵdid(8, 671744, [[1, 4], [2, 4]], 0, i11.CdkConnectedOverlay, [i11.Overlay, i0.TemplateRef, i0.ViewContainerRef, i11.ɵc, [2, i12.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 5, "a", [["cdkOverlayOrigin", ""], ["class", "button button_icon button_orange button_extra-small"], ["href", "javascript:void(0)"], ["style", "margin: 0 0 0 8px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.setFieldTypeDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, [["dropdownTrigger", 4]], 0, i11.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 3, "span", [["class", "icon button__icon"]], null, null, null, null, null)), i0.ɵdid(13, 278528, null, 0, i8.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpad(15, 1), (_l()(), i0.ɵeld(16, 0, null, null, 4, "div", [["class", "sidebar-collapse__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterValuesItemComponent_2)), i0.ɵdid(18, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterValuesItemComponent_6)), i0.ɵdid(20, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 4, "div", [["class", "sidebar-collapse__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 2, "a", [["class", "sidebar-list-item__action icon-check_4"], ["href", "javascript:void(0)"]], [[2, "sidebar-list-item__action_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.control.controls.required.patchValue(!_co.control.controls.required.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(23, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(24, { side: 0 }), (_l()(), i0.ɵeld(25, 0, null, null, 0, "a", [["class", "sidebar-list-item__action icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.deleteRequested.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterValuesItemComponent_7)), i0.ɵdid(27, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = undefined; _ck(_v, 4, 0, currVal_5); var currVal_6 = i0.ɵnov(_v, 11); var currVal_7 = _co.fieldTypeDropdownPositions; var currVal_8 = "popover2-backdrop"; var currVal_9 = _ck(_v, 9, 0, "overlay", "overlay_horizontal_center"); var currVal_10 = _co.fieldTypeDropdownOpened; var currVal_11 = true; var currVal_12 = true; var currVal_13 = true; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = "icon button__icon"; var currVal_15 = _ck(_v, 15, 0, ("icon-" + i0.ɵunv(_v, 13, 1, i0.ɵnov(_v, 14).transform(_co.fieldIcon$)))); _ck(_v, 13, 0, currVal_14, currVal_15); var currVal_16 = _co.opened; _ck(_v, 18, 0, currVal_16); var currVal_17 = !_co.opened; _ck(_v, 20, 0, currVal_17); var currVal_19 = (_co.control.controls.required.value ? "Make Optional" : "Make Required"); var currVal_20 = _ck(_v, 24, 0, "top"); _ck(_v, 23, 0, currVal_19, currVal_20); var currVal_21 = _co.opened; _ck(_v, 27, 0, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.opened; var currVal_3 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = false; _ck(_v, 3, 0, currVal_4); var currVal_18 = _co.control.controls.required.value; _ck(_v, 22, 0, currVal_18); }); }
export function View_ParameterValuesItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-parameter-values-item", [], null, null, null, View_ParameterValuesItemComponent_0, RenderType_ParameterValuesItemComponent)), i0.ɵdid(1, 4964352, null, 0, i13.ParameterValuesItemComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParameterValuesItemComponentNgFactory = i0.ɵccf("app-parameter-values-item", i13.ParameterValuesItemComponent, View_ParameterValuesItemComponent_Host_0, { control: "control", openedInitial: "openedInitial", collapseContext: "collapseContext" }, { deleteRequested: "deleteRequested" }, []);
export { ParameterValuesItemComponentNgFactory as ParameterValuesItemComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import cloneDeep from 'lodash/cloneDeep';
import { Input } from '@modules/fields';
import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
var CustomMenuItem = /** @class */ (function (_super) {
    __extends(CustomMenuItem, _super);
    function CustomMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.Custom;
        _this.inputs = [];
        return _this;
    }
    CustomMenuItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.title = this.params['title'];
        this.customView = this.params['custom_view'];
        if (this.params['inputs']) {
            this.inputs = this.params['inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        return this;
    };
    CustomMenuItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['title'] = this.title;
        data['params']['custom_view'] = this.customView;
        data['params']['inputs'] = this.inputs.map(function (item) { return item.serialize(); });
        return data;
    };
    CustomMenuItem.prototype.copyFrom = function (item) {
        _super.prototype.copyFrom.call(this, item);
        this.title = item.title;
        this.customView = item.customView;
        this.inputs = cloneDeep(item.inputs);
    };
    CustomMenuItem.prototype.clone = function () {
        var instance = new CustomMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return CustomMenuItem;
}(MenuItem));
export { CustomMenuItem };
registerMenuItemForType(MenuItemType.Custom, CustomMenuItem);

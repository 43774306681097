import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { CustomizeBarContext } from '@modules/customize-bar';
import { ContainerLayer, LayerType, LineLayer } from '@modules/views';

import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { BorderControl } from '../../controls/border.control';
import { ShadowControl } from '../../controls/shadow.control';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { LineCustomizeLayerForm } from './line-customize-layer.form';

@Component({
  selector: 'app-line-customize-layer',
  templateUrl: './line-customize-layer.component.html',
  providers: [LineCustomizeLayerForm, CustomizeBarContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineCustomizeLayerComponent extends CustomizeLayerComponent<LineLayer> implements OnInit, OnDestroy {
  containerLayer: ContainerLayer;
  settingsComponents: DynamicComponentArguments[] = [];

  constructor(
    public form: LineCustomizeLayerForm,
    private editorContext: ViewEditorContext,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const layerContainer = this.editorContext.getLayerContainer(this.layer);

    this.form.init(this.layer, { firstInit: true, container: layerContainer });
    this.trackFormUpdates(this.form, () => this.form.submit());

    combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
      .pipe(auditTime(10), untilDestroyed(this))
      .subscribe(([layer, container]) => {
        this.pauseFormUpdates();
        this.form.init(layer, {
          firstInit: false,
          container: container
        });
        this.resumeFormUpdates();
      });

    this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(layer => {
      this.containerLayer = layer;
      this.cd.markForCheck();
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  bordersDragDrop(event: CdkDragDrop<BorderControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.borders.controls, event.previousIndex, event.currentIndex);
      this.form.controls.borders.updateValueAndValidity();
    }
  }

  shadowsDragDrop(event: CdkDragDrop<ShadowControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.shadows.controls, event.previousIndex, event.currentIndex);
      this.form.controls.shadows.updateValueAndValidity();
    }
  }
}

registerCustomizeLayerComponent(LayerType.Line, LineCustomizeLayerComponent);

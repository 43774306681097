<app-custom-view-templates-list
  [initialFilter]="initialFilter"
  [nameEditingEnabled]="nameEditingEnabled"
  [viewCreateEnabled]="viewCreateEnabled"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [componentLabel]="componentLabel"
  [hidden]="route.type != routeTypes.List"
  (selectTemplate)="onTemplateSelected($event)"
  (createdView)="onViewSelected($event)"
  (close)="cancel()"
></app-custom-view-templates-list>

<app-custom-view-templates-detail
  *ngIf="route.type == routeTypes.Detail"
  [id]="detailRoute.id"
  [nameEditingEnabled]="nameEditingEnabled"
  [viewCustomizeEnabled]="viewCustomizeEnabled"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [componentLabel]="componentLabel"
  [analyticsSource]="analyticsSource"
  (selectView)="onViewSelected($event.view, $event.template)"
  (showTemplateType)="openList({ type: $event })"
  (customizeTemplate)="openUpdate($event.id)"
  (back)="openList()"
  (close)="cancel()"
></app-custom-view-templates-detail>

<app-custom-view-templates-change
  *ngIf="route.type == routeTypes.Update"
  [id]="updateRoute.id"
  [setView]="setTemplateView"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [componentLabel]="componentLabel"
  (openList)="openList()"
  (openDetail)="openDetail($event)"
  (saved)="onTemplateSaved($event)"
  (back)="openDetail(updateRoute.id)"
  (close)="cancel()"
></app-custom-view-templates-change>

<td class="table__column table__column_left-padding"></td>
<td class="table__column">
  <div class="table__column-inner">
    <div class="table-user table-user_photo">
      <span
        class="table-user__photo user-photo"
        [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
      >
      </span>
      <ng-container *ngIf="item.user">
        <div class="table-user__title">
          {{ item.user.firstName }} {{ item.user.lastName }}
          <span *ngIf="(currentUserStore.instance$ | async)?.uid == item.user.uid" class="table-user__title-comment">
            ({{ 'You' | localize }})
          </span>
        </div>
        <div class="table-user__subtitle">{{ item.user.email }}</div>
      </ng-container>
      <ng-container *ngIf="!item.user">
        <div class="table-user__title">
          {{ item.userEmail }} <span class="table-user__title-comment">({{ 'invited' | localize }})</span>
        </div>
      </ng-container>
    </div>
  </div>
</td>
<td *ngIf="group" class="table__column">
  <div class="table__column-inner">{{ item.group.name }}</div>
</td>
<td class="table__column">
  <div class="table__column-inner">{{ item.dateAdd.format('LLL') }}</div>
</td>
<td class="table__column">
  <div class="table__column-inner">
    <div *ngIf="inviteLink" style="position: relative; max-width: 360px;">
      <input
        class="input input_disabled input_fill share-form__input"
        [value]="inviteLink"
        (click)="$event.target.select()"
        style="padding-right: 85px;"
        readonly
      />

      <a
        href="javascript:void(0)"
        class="button button_extra-small"
        style="position: absolute; right: 3px; top: 3px; margin: 0;"
        (click)="copyInviteCode(inviteLink)"
      >
        <span class="button__icon button__icon_left icon-duplicate"></span>
        <span class="button__label">{{ 'Copy' | localize }}</span>
      </a>
    </div>
  </div>
</td>
<td class="table__column table__column_right">
  <button
    (click)="delete(item)"
    [disabled]="deleteLoading"
    [appTip]="'Delete' | localize"
    [appTipOptions]="{ side: 'top' }"
    class="button button_icon button_bg-transparent"
  >
    <app-loader-small *ngIf="deleteLoading" class="button__icon"> </app-loader-small>
    <span *ngIf="!deleteLoading" class="icon icon-bin button__icon"></span>
  </button>
</td>
<td class="table__column"></td>

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FieldType } from '@modules/fields';
import { forceObservable, isSet } from '@shared';
import { ViewContextTokenProvider } from '../../services/view-context-token-provider/view-context-token-provider';
var ViewContextTokenPopoverSectionItemComponent = /** @class */ (function () {
    function ViewContextTokenPopoverSectionItemComponent(tokenProvider, cd) {
        this.tokenProvider = tokenProvider;
        this.cd = cd;
        this.descriptionAlwaysVisible = false;
        this.hover = new EventEmitter();
        this.out = new EventEmitter();
        this.opened = new EventEmitter();
        this.selected = new EventEmitter();
        this.valueLoading = false;
    }
    ViewContextTokenPopoverSectionItemComponent.prototype.ngOnInit = function () { };
    ViewContextTokenPopoverSectionItemComponent.prototype.ngOnDestroy = function () { };
    ViewContextTokenPopoverSectionItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.item) {
            this.updateValue(this.item.item);
        }
    };
    Object.defineProperty(ViewContextTokenPopoverSectionItemComponent.prototype, "effectiveItem", {
        get: function () {
            return this.overrideItem || this.item;
        },
        enumerable: true,
        configurable: true
    });
    ViewContextTokenPopoverSectionItemComponent.prototype.updateValue = function (token) {
        var _this = this;
        if (!token || !isSet(token.value)) {
            this.valueDisplay = undefined;
            this.valueLoading = false;
            this.cd.markForCheck();
            return;
        }
        var tokenValue$ = forceObservable(token.value);
        this.valueDisplay = undefined;
        this.valueLoading = true;
        this.cd.markForCheck();
        tokenValue$.pipe(untilDestroyed(this)).subscribe(function (tokenValue) {
            _this.valueDisplay = _this.tokenProvider.getValueDisplay(tokenValue, {
                name: token.label,
                type: token.fieldType,
                params: token.fieldParams
            });
            _this.overrideItem = _this.getOverrideItem(token, tokenValue);
            _this.valueLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.valueLoading = false;
            _this.cd.markForCheck();
        });
    };
    ViewContextTokenPopoverSectionItemComponent.prototype.getOverrideItem = function (token, tokenValue) {
        if (token.fieldType == FieldType.JSON && isSet(tokenValue)) {
            var structure = token.fieldParams && token.fieldParams['display_fields'] && token.fieldParams['structure']
                ? token.fieldParams['structure']
                : undefined;
            var jsonItem = this.tokenProvider.mapJsonToken(token.token, this.item.path, token.label, tokenValue, 0, {
                structure: structure
            });
            return jsonItem && jsonItem.section && jsonItem.section.items.length ? jsonItem : undefined;
        }
    };
    return ViewContextTokenPopoverSectionItemComponent;
}());
export { ViewContextTokenPopoverSectionItemComponent };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Option } from '@modules/field-components';
import { ObjectQuery, ObjectQueryOperation } from '@modules/queries';

export interface QueryBuilderObjectOptions {
  forceOperation?: ObjectQueryOperation;
  defaultOperation?: ObjectQueryOperation;
}

@Injectable()
export class QueryBuilderObjectForm extends FormGroup {
  controls: {
    operation: FormControl;
    id: FormControl;
    query: FormControl;
    object_to_array: FormControl;
    request_response: FormControl;
    request_tokens: FormControl;
  };

  options: QueryBuilderObjectOptions = {};
  operationOptions: Option<ObjectQueryOperation>[] = [
    { value: ObjectQueryOperation.Get, name: 'Get Data' },
    { value: ObjectQueryOperation.Create, name: 'Create Record in Object' },
    { value: ObjectQueryOperation.Update, name: 'Update Record in Object' },
    { value: ObjectQueryOperation.Delete, name: 'Delete Record in Object' }
  ];

  constructor() {
    super({
      operation: new FormControl(undefined),
      id: new FormControl(''),
      query: new FormControl(''),
      object_to_array: new FormControl(true),
      request_response: new FormControl(undefined),
      request_tokens: new FormControl({})
    });
  }

  init(query?: ObjectQuery, arrayResponse?: boolean, options: QueryBuilderObjectOptions = {}) {
    if (query) {
      this.controls.operation.patchValue(query.operation);
      this.controls.id.patchValue(query.id);
      this.controls.query.patchValue(query.query);
      this.controls.object_to_array.patchValue(query.queryOptions.objectToArray);
      this.controls.request_response.patchValue(query.requestResponse);
      this.controls.request_tokens.patchValue(query.requestTokens);
    } else {
      this.controls.operation.patchValue(options.forceOperation || options.defaultOperation);
      this.controls.object_to_array.patchValue(!!arrayResponse);
    }
  }

  getInstance(): ObjectQuery {
    const instance = new ObjectQuery();

    instance.operation = this.controls.operation.value;
    instance.id = this.controls.id.value;
    instance.query = this.controls.query.value;
    instance.queryOptions = { objectToArray: this.controls.object_to_array.value };
    instance.requestResponse = this.controls.request_response.value;
    instance.requestTokens = this.controls.request_tokens.value;

    return instance;
  }
}

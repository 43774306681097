import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectPublicInvite } from '../../data/project-public-invite';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectPublicInviteService = /** @class */ (function () {
    function ProjectPublicInviteService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectPublicInviteService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'public_invites/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectPublicInvite().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_invites/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectPublicInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'public_invites/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectPublicInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.update = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_invites/" + instance.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectPublicInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.delete = function (projectName, environmentName, invite) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_invites/" + invite.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.getByCode = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_public_invites/" + code + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectPublicInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.prototype.accept = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_public_invites/" + code + "/accept/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return (result ? result['result'] : false); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicInviteService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectPublicInviteService_Factory() { return new ProjectPublicInviteService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectPublicInviteService, providedIn: "root" });
    return ProjectPublicInviteService;
}());
export { ProjectPublicInviteService };

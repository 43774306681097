import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { SSOSettings, SSOType } from '../../data/sso-settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var SSOSettingsService = /** @class */ (function () {
    function SSOSettingsService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    SSOSettingsService.prototype.get = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'sso_settings/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new SSOSettings().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SSOSettingsService.prototype.getDetail = function (projectName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "sso_settings/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new SSOSettings().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SSOSettingsService.prototype.create = function (projectName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'sso_settings/');
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            if (instance.type == SSOType.SAML2) {
                var formData = toPairs(data).reduce(function (acc, _a) {
                    var key = _a[0], value = _a[1];
                    if (value === null) {
                        value = '';
                    }
                    else if (isPlainObject(value)) {
                        value = JSON.stringify(value);
                    }
                    acc.append(key, value);
                    return acc;
                }, new FormData());
                return _this.http.post(url, formData, { headers: headers });
            }
            else {
                return _this.http.post(url, data, { headers: headers });
            }
        }), map(function (result) { return new SSOSettings().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SSOSettingsService.prototype.update = function (projectName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "sso_settings/" + instance.uid + "/");
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            if (instance.type == SSOType.SAML2) {
                var formData = toPairs(data).reduce(function (acc, _a) {
                    var key = _a[0], value = _a[1];
                    if (value === null) {
                        value = '';
                    }
                    else if (isPlainObject(value)) {
                        value = JSON.stringify(value);
                    }
                    acc.append(key, value);
                    return acc;
                }, new FormData());
                return _this.http.patch(url, formData, { headers: headers });
            }
            else {
                return _this.http.patch(url, data, { headers: headers });
            }
        }), map(function (result) { return new SSOSettings().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SSOSettingsService.prototype.delete = function (projectName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, "sso_settings/" + instance.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SSOSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SSOSettingsService_Factory() { return new SSOSettingsService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: SSOSettingsService, providedIn: "root" });
    return SSOSettingsService;
}());
export { SSOSettingsService };

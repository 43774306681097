var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { PAGE_PARAM } from '@modules/models';
import { ListStore } from '@shared';
import { UserActivityService } from '../services/user-activity/user-activity.service';
var UserActivityListStore = /** @class */ (function (_super) {
    __extends(UserActivityListStore, _super);
    function UserActivityListStore(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.params = {};
        return _this;
    }
    UserActivityListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        return params;
    };
    UserActivityListStore.prototype.fetchPage = function (page, next) {
        var params = this.paramsForPage(page);
        return this.service.get(this.projectName, this.environmentName, params).pipe(map(function (response) {
            return {
                items: response.results,
                hasMore: response.hasMore,
                totalPages: response.numPages,
                perPage: response.perPage,
                count: response.count
            };
        }));
    };
    return UserActivityListStore;
}(ListStore));
export { UserActivityListStore };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "../flex-layout-control/flex-layout-control.component.ngfactory";
import * as i5 from "../flex-layout-control/flex-layout-control.component";
import * as i6 from "./flex-layout-section.component";
var styles_FlexLayoutSectionComponent = [];
var RenderType_FlexLayoutSectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FlexLayoutSectionComponent, data: {} });
export { RenderType_FlexLayoutSectionComponent as RenderType_FlexLayoutSectionComponent };
function View_FlexLayoutSectionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compact-select-option__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "compact-select-option__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "compact-select-option__icon"; var currVal_1 = ("icon-" + _v.parent.context.$implicit.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FlexLayoutSectionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectOption(_v.context.$implicit);
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FlexLayoutSectionComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.subtitle; _ck(_v, 6, 0, currVal_1); }); }
function View_FlexLayoutSectionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compact-select-options"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FlexLayoutSectionComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.orientationOptions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FlexLayoutSectionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["cdkOverlayOrigin", ""], ["class", "compact-button compact-button_primary icon-plus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDropdownOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, [["trigger", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FlexLayoutSectionComponent_2)), i0.ɵdid(4, 671744, null, 0, i2.CdkConnectedOverlay, [i2.Overlay, i0.TemplateRef, i0.ViewContainerRef, i2.ɵc, [2, i3.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(5, 2), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2); var currVal_1 = _co.dropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 5, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_FlexLayoutSectionComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "compact-button icon-minus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.enabledControl.patchValue(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FlexLayoutSectionComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-flex-layout-control", [], null, null, null, i4.View_FlexLayoutControlComponent_0, i4.RenderType_FlexLayoutControlComponent)), i0.ɵdid(1, 245760, null, 0, i5.FlexLayoutControlComponent, [i0.ChangeDetectorRef], { control: [0, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FlexLayoutSectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "compact-inputs-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "compact-inputs-section__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "compact-inputs-section__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "compact-inputs-section__header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Auto layout"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "compact-inputs-section__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FlexLayoutSectionComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FlexLayoutSectionComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FlexLayoutSectionComponent_6)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.enabledControl.value; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.enabledControl.value; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.enabledControl.value; _ck(_v, 11, 0, currVal_2); }, null); }
export function View_FlexLayoutSectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-flex-layout-section", [], null, null, null, View_FlexLayoutSectionComponent_0, RenderType_FlexLayoutSectionComponent)), i0.ɵdid(1, 114688, null, 0, i6.FlexLayoutSectionComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlexLayoutSectionComponentNgFactory = i0.ɵccf("app-flex-layout-section", i6.FlexLayoutSectionComponent, View_FlexLayoutSectionComponent_Host_0, { enabledControl: "enabledControl", control: "control", updateControlsValue: "updateControlsValue" }, { selectedOption: "selectedOption" }, []);
export { FlexLayoutSectionComponentNgFactory as FlexLayoutSectionComponentNgFactory };

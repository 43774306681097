<app-popup2 [size]="'m'">
  <app-popup2-close (click)="close()"></app-popup2-close>

  <div class="tabs2">
    <div class="tabs2-navigation tabs2-navigation_border" style="padding: 0 15px;">
      <a
        href="javascript:void(0)"
        class="tabs2-navigation__item"
        [class.tabs2-navigation__item_active]="currentTab == tabs.Share"
        (click)="setCurrentTab(tabs.Share)"
      >
        {{ 'Invite members' | localize }}
      </a>
      <a
        href="javascript:void(0)"
        class="tabs2-navigation__item"
        [class.tabs2-navigation__item_active]="currentTab == tabs.Public"
        (click)="setCurrentTab(tabs.Public)"
      >
        {{ 'Public share' | localize }}
      </a>
      <a href="javascript:void(0)" class="tabs2-navigation__item" (click)="openSignUp()">
        {{ 'Sign Up' | localize }}
        <span class="tabs2-navigation__item-icon tabs2-navigation__item-icon_right icon-external_link"></span>
      </a>
      <a
        href="javascript:void(0)"
        class="tabs2-navigation__item"
        [class.tabs2-navigation__item_active]="currentTab == tabs.Members"
        (click)="setCurrentTab(tabs.Members)"
      >
        {{ 'Members' | localize }}
        <span *ngIf="invitesCount || usersCount" class="tabs2-navigation__item-counter">
          {{ (invitesCount || 0) + (usersCount || 0) }}
        </span>
      </a>
    </div>
  </div>

  <app-page-block *ngIf="currentTab == tabs.Share">
    <app-page-block *ngIf="hasDraftChanges" [paddingSize]="'none'">
      <app-alert [purple]="true" [title]="'You have unpublished changes'" [icon]="'warning_filled'">
        Please note that non-builder users will only see the published App
      </app-alert>
    </app-page-block>

    <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
      <app-page-heading [size]="'s'">
        <span class="page-heading-title-icon icon-email" style="margin-right: 4px;"></span>
        {{ 'Invite with Email' | localize }}
      </app-page-heading>
    </app-page-block>

    <form *ngIf="form.form && !groupsLoading" class="share-form__form" [formGroup]="form.form" (ngSubmit)="submit()">
      <div class="share-form__header">
        <div class="share-form__field">
          <input
            class="input share-form__input"
            type="text"
            formControlName="user_email"
            [placeholder]="'Invite someone...' | localize"
            [appTutorialTip]="{
              tip: 'Enter user Email',
              task: 'invite_user',
              page: 1,
              pageItem: 'choose_user',
              action: 'input',
              formGroup: form.form
            }"
            [appAutofocus]="true"
          />

          <app-project-group-control
            [control]="form.form.controls['group']"
            class="share-form__select"
          ></app-project-group-control>
        </div>
        <button
          type="submit"
          class="button button_bigger button_primary button_segment-left share-form__button"
          [class.button_orange]="orange"
          [disabled]="form.form.invalid || form.form.disabled || createInviteLoading"
          [appTutorialTip]="{
            tip: 'Send Invite',
            task: 'invite_user',
            page: 1,
            pageItem: 'send_invite',
            action: 'none'
          }"
        >
          <app-loader-small *ngIf="createInviteLoading" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">{{ 'Send Invite' | localize }}</span>
        </button>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay']"
          [cdkConnectedOverlayPositions]="[
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
              offsetX: 8,
              offsetY: 0
            }
          ]"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="inviteSettingsOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          (backdropClick)="setInviteSettingsOpened(false)"
          (detach)="setInviteSettingsOpened(false)"
        >
          <div (cdkObserveContent)="onPopoverContentChanged()">
            <div class="popover2">
              <div class="popover2__inner popover2__inner_no-margin-collapse">
                <div class="popover2__viewport popover2__viewport_scrollable">
                  <app-project-property-change
                    [type]="projectPropertyTypes.User"
                    [control]="form.form.controls['properties']"
                    [description]="'Limit access to your data by user properties' | localize"
                    [analyticsSource]="'invite'"
                  >
                  </app-project-property-change>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <button
          type="button"
          class="button button_bigger button_icon button_primary button_border button_segment-right share-form__button"
          [class.button_orange]="orange"
          [disabled]="form.form.invalid || form.form.disabled || createInviteLoading"
          (click)="setInviteSettingsOpened(true)"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
        >
          <span class="button__icon"><span class="icon-arrow_down_2"></span></span>
        </button>
      </div>
    </form>

    <div *ngIf="!form.form || groupsLoading" class="share-form__form">
      <div class="share-form__header">
        <div class="share-form__field">
          <input
            class="input input_stub share-form__input"
            [class.loading-animation]="true"
            type="text"
            [value]="'Invite someone'"
          />
        </div>
        <button
          type="button"
          class="button button_bigger button_primary share-form__button button_disabled"
          [class.button_orange]="orange"
        >
          <span class="button__label">
            <span [class.loading-animation]="true"><span class="stub-text">Send</span></span>
          </span>
        </button>
      </div>
    </div>

    <app-page-block [paddingSize]="'none'" [marginSize]="'s'">
      <div class="share-form__description">
        {{ 'Enter the email of the user you’d like to invite to collaborate.' | localize }}
      </div>
    </app-page-block>

    <ng-container *ngIf="createInviteResult">
      <app-alert [success]="true">
        Invitation Email was sent to <strong>{{ createInviteResult.getEmail() }}</strong
        >.<br />
        You can also share the invitation link yourself:<br />
      </app-alert>

      <app-page-block [paddingSize]="'none'" [marginSize]="'s'">
        <div style="position: relative;">
          <input
            class="input input_disabled input_fill share-form__input"
            [value]="getInviteLink()"
            (click)="$event.target.select()"
            style="padding-right: 85px;"
            readonly
          />

          <a
            href="javascript:void(0)"
            class="button button_small"
            style="position: absolute; right: 5px; top: 5px; margin: 0;"
            (click)="copyInviteCode()"
          >
            <span class="button__icon button__icon_left icon-duplicate"></span>
            <span class="button__label">{{ 'Copy' | localize }}</span>
          </a>
        </div>
      </app-page-block>
    </ng-container>

    <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
      <hr />
    </app-page-block>

    <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
      <app-page-heading [size]="'s'">
        <span class="page-heading-title-icon icon-link" style="margin-right: 4px;"></span>
        {{ 'Invite with Link' | localize }}
      </app-page-heading>
    </app-page-block>

    <ng-container *ngIf="form.form && !groupsLoading">
      <div *ngIf="!publicInvitesLoading && publicInvites?.length" style="margin-top: 8px;">
        <table class="share-form__table share-table">
          <tbody>
            <tr
              *ngFor="let item of publicInvites; let i = index"
              app-share-public-invite-item
              [item]="item"
              class="share-table__row"
              (deleted)="onPublicInviteDeleted(item)"
            ></tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="publicInvitesLoading && publicInvites !== undefined" style="margin-top: 8px;">
        <table class="share-form__table share-table">
          <tbody>
            <tr *ngFor="let item of [1]" class="share-table__row">
              <td class="share-table__column" style="padding-right: 10px;">
                <input
                  class="input input_disabled input_fill input_stub"
                  [class.loading-animation]="true"
                  type="text"
                  [value]="'Page URL'"
                />
              </td>
              <td class="share-table__column">
                <span [class.loading-animation]="true"><span class="stub-text">Team name</span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="display: flex; align-items: center; margin-top: 8px;">
        <div>
          <app-project-group-control [control]="createPublicInviteGroupControl"></app-project-group-control>
        </div>

        <div>
          <button
            type="button"
            class="button button_alternative share-form__button"
            [class.button_orange]="orange"
            [class.button_disabled]="!createPublicInviteGroupControl.value || createPublicInviteLoading"
            (click)="createPublicInvite()"
            style="margin-left: 10px; box-shadow: none;"
          >
            <app-loader-small
              *ngIf="createPublicInviteLoading"
              class="button__icon button__icon_left"
            ></app-loader-small>
            <span *ngIf="!createPublicInviteLoading" class="button__icon button__icon_left icon-plus"></span>
            <span class="button__label">{{ 'Add Invite Link' | localize }}</span>
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!form.form || groupsLoading" style="display: flex; align-items: center; margin-top: 8px;">
      <div>
        <input class="input input_stub" [class.loading-animation]="true" type="text" [value]="'Invite someone'" />
      </div>

      <div>
        <button
          type="button"
          class="button button_bigger button_alternative share-form__button button_disabled"
          [class.button_orange]="orange"
        >
          <span class="button__label">
            <span class="button__label">
              <span [class.loading-animation]="true"><span class="stub-text">Add Public</span></span>
            </span>
          </span>
        </button>
      </div>
    </div>

    <p class="share-form__description">
      {{ 'Anyone on the internet with this link will be able to join the selected team.' | localize }}
    </p>
  </app-page-block>

  <app-share-public
    *ngIf="currentTab == tabs.Public"
    [hasDraftChanges]="hasDraftChanges"
    (close)="close()"
  ></app-share-public>

  <ng-container *ngIf="currentTab == tabs.Members">
    <ng-container *ngIf="!membersLoading">
      <ng-container *ngIf="invitesCount">
        <app-page-block>
          <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
            <app-page-heading [size]="'s'">
              {{ 'Pending Invites' | localize }} <span class="page-heading__dim">({{ invitesCount }})</span>
            </app-page-heading>
          </app-page-block>

          <table class="share-form__table share-table">
            <tbody>
              <tr
                *ngFor="let item of invites; let i = index"
                app-share-invite-item
                [item]="item"
                class="share-table__row"
                (deleted)="onInviteDeleted(item)"
              ></tr>
            </tbody>
          </table>
        </app-page-block>
      </ng-container>

      <app-page-block>
        <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
          <app-page-heading [size]="'s'">
            {{ 'Active Members' | localize }} <span *ngIf="usersCount" class="list__sign">({{ usersCount }})</span>
          </app-page-heading>
        </app-page-block>

        <table class="share-form__table share-table">
          <tbody>
            <tr
              *ngFor="let item of users; let i = index"
              app-share-user-item
              [item]="item"
              class="share-table__row"
              (deleted)="onUserDeleted(item)"
            ></tr>
          </tbody>
        </table>
      </app-page-block>
    </ng-container>

    <div *ngIf="membersLoading">
      <app-page-block>
        <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
          <app-page-heading [size]="'s'">
            <span [class.loading-animation]="true"><span class="stub-text">Active Members</span></span>
          </app-page-heading>
        </app-page-block>

        <table class="share-form__table share-table">
          <tbody>
            <tr *ngFor="let item of [1, 2]" class="share-table__row">
              <td class="share-table__column">
                <div class="table-user table-user_photo">
                  <span class="table-user__photo stub-image stub-image_contrast"></span>
                  <div class="table-user__user">
                    <span [class.loading-animation]="true"><span class="stub-text">User name</span></span>
                  </div>
                  <div class="table-user__email">
                    <span [class.loading-animation]="true"><span class="stub-text">Email</span></span>
                  </div>
                </div>
              </td>
              <td class="share-table__column">
                <span [class.loading-animation]="true"><span class="stub-text">Team name</span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </app-page-block>
    </div>
  </ng-container>
</app-popup2>

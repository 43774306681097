var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { AdminMode } from '@modules/admin-mode';
import { versionLessThan } from '@modules/api';
import { containsTree, isSet, openUrl } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var ROUTER_ATTRS_KEY = '_attrs';
var RoutingService = /** @class */ (function () {
    function RoutingService(router, location) {
        this.router = router;
        this.location = location;
    }
    RoutingService.prototype.ensureRouterAttrs = function () {
        this.router[ROUTER_ATTRS_KEY] = this.router[ROUTER_ATTRS_KEY] || {};
    };
    RoutingService.prototype.setRouterAttr = function (name, value) {
        this.ensureRouterAttrs();
        this.router[ROUTER_ATTRS_KEY][name] = value;
    };
    RoutingService.prototype.popRouterAttr = function (name) {
        this.ensureRouterAttrs();
        if (this.router[ROUTER_ATTRS_KEY].hasOwnProperty(name)) {
            var value = this.router[ROUTER_ATTRS_KEY][name];
            delete this.router[ROUTER_ATTRS_KEY][name];
            return value;
        }
    };
    Object.defineProperty(RoutingService.prototype, "routerState", {
        get: function () {
            return this.router.routerState;
        },
        enumerable: true,
        configurable: true
    });
    RoutingService.prototype.appLink = function (commands, options) {
        if (options === void 0) { options = {}; }
        if (!commands) {
            return;
        }
        var version = window['version'];
        var mode = options.modeName || window['mode'];
        if (version && versionLessThan(version, '2.1.0')) {
            mode = AdminMode.App;
        }
        var project = options.projectName || window['project'];
        var environment = options.environmentName || window['project_environment'];
        var prefix = version ? ["/v" + version, mode] : ["/" + mode];
        return prefix.concat([project, environment], commands);
    };
    RoutingService.prototype.buildURL = function (href, options) {
        if (options === void 0) { options = {}; }
        var result = [href];
        var queryParamPairs = options.queryParams ? toPairs(options.queryParams) : undefined;
        if (queryParamPairs && queryParamPairs.length) {
            var queryParamsStr = queryParamPairs.map(function (_a) {
                var k = _a[0], v = _a[1];
                return [k, encodeURIComponent(v)].join('=');
            }).join('&');
            result.push("?" + queryParamsStr);
        }
        if (isSet(options.fragment)) {
            result.push("#" + options.fragment);
        }
        return result.join('');
    };
    RoutingService.prototype.getRouteWithNested = function (route) {
        var _this = this;
        return [
            route
        ].concat(route.children.reduce(function (acc, item) {
            acc.push.apply(acc, _this.getRouteWithNested(item));
            return acc;
        }, []));
    };
    RoutingService.prototype.getRouteUrl = function (route) {
        return this.getRouteWithNested(route).reduce(function (acc, item) {
            acc.push.apply(acc, item.url.map(function (segment) { return segment.path; }));
            return acc;
        }, []);
    };
    RoutingService.prototype.navigate = function (commands, extras) {
        return this.router.navigate(commands, extras);
    };
    RoutingService.prototype.navigateApp = function (commands, extras, options) {
        if (options === void 0) { options = {}; }
        return this.router.navigate(this.appLink(commands, options), extras);
    };
    RoutingService.prototype.navigateCurrent = function (extras) {
        return this.router.navigate([], extras);
    };
    RoutingService.prototype.navigateRoute = function (route, extras) {
        var commands = this.getRouteUrl(route);
        return this.navigate(commands, extras);
    };
    RoutingService.prototype.navigateLink = function (link, extras) {
        if (link.link) {
            this.navigate(link.link, extras);
        }
        else {
            var url = this.buildURL(link.href, __assign({}, (extras && {
                queryParams: extras.queryParams,
                fragment: extras.fragment
            })));
            openUrl(url);
        }
    };
    RoutingService.prototype.navigateLinkApp = function (link, extras, options) {
        if (options === void 0) { options = {}; }
        if (link.link) {
            this.navigateApp(link.link, extras, options);
        }
        else {
            var url = this.buildURL(link.href, __assign({}, (extras && {
                queryParams: extras.queryParams,
                fragment: extras.fragment
            })));
            openUrl(url);
        }
    };
    RoutingService.prototype.createUrlTree = function (commands, navigationExtras) {
        return this.router.createUrlTree(commands, navigationExtras);
    };
    RoutingService.prototype.createUrlTreeApp = function (commands, navigationExtras, options) {
        if (options === void 0) { options = {}; }
        return this.router.createUrlTree(this.appLink(commands, options), navigationExtras);
    };
    RoutingService.prototype.serializeUrl = function (url) {
        return this.router.serializeUrl(url);
    };
    RoutingService.prototype.navigateByUrl = function (url, extras) {
        return this.router.navigateByUrl(url, extras);
    };
    RoutingService.prototype.replaceUrl = function (commands, navigationExtras) {
        var url = this.serializeUrl(this.createUrlTree(commands, navigationExtras));
        this.location.replaceState(url);
    };
    RoutingService.prototype.replaceUrlApp = function (commands, navigationExtras) {
        var url = this.serializeUrl(this.createUrlTreeApp(commands, navigationExtras));
        this.location.replaceState(url);
    };
    RoutingService.prototype.isRouterLinkActive = function (route, options) {
        if (options === void 0) { options = {}; }
        var currentUrlTree = this.router.parseUrl(this.router.url);
        var routeUrlTree = this.router.createUrlTree(isArray ? route : [route], { queryParams: options.queryParams });
        return containsTree(currentUrlTree, routeUrlTree, options.exact);
    };
    RoutingService.prototype.isAppLinkActive = function (route, options) {
        if (options === void 0) { options = {}; }
        var currentUrlTree = this.router.parseUrl(this.router.url);
        var routeLink = this.appLink(isArray ? route : [route]);
        var routeUrlTree = this.router.createUrlTree(routeLink, { queryParams: options.queryParams });
        return containsTree(currentUrlTree, routeUrlTree, options.exact);
    };
    RoutingService.prototype.navigateUnauthenticated = function (route) {
        var queryParams = {};
        var url = this.getRouteUrl(route);
        var redirect = encodeURIComponent(JSON.stringify({
            url: url,
            params: route.queryParams
        }));
        var referrer = route.queryParams['referrer'];
        var registered = route.queryParams['registered'];
        if (redirect) {
            queryParams['redirect'] = redirect;
        }
        if (referrer) {
            queryParams['referrer'] = referrer;
        }
        if (route.data['registerWithoutAuthenticated'] && !registered) {
            this.navigate(['/register'], { queryParams: queryParams });
        }
        else {
            this.navigate(['/login'], { queryParams: queryParams });
        }
    };
    RoutingService.ngInjectableDef = i0.defineInjectable({ factory: function RoutingService_Factory() { return new RoutingService(i0.inject(i1.Router), i0.inject(i2.Location)); }, token: RoutingService, providedIn: "root" });
    return RoutingService;
}());
export { RoutingService };

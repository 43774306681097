import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescription } from '@modules/models';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretToken, SecretTokenService, socialBackends } from '@modules/projects';
import { HttpQuery, HttpQueryAuthType, RateLimit } from '@modules/queries';
import { ResourceParamsResult, RestAPIResourceParams } from '@modules/resources';
import { TemplateService } from '@modules/template';
import { parseNumber } from '@shared';

// TODO: Refactor import
import {
  HTTP_QUERY_BASIC_AUTH_VALUE,
  HTTP_QUERY_KEY_AUTH_NAME,
  HttpQueryKeyAuthSendAs
} from '../../../data/http-query-auth';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class RestApiResourceSettingsForm extends BaseResourceSettingsForm implements OnDestroy {
  form = new AppFormGroup({
    url: new FormControl(''),
    auth_type: new FormControl(null),
    auth_params: new FormControl({}),
    access_token_name: new FormControl(''),
    rate_limit_actions: new FormControl(undefined),
    rate_limit_per_seconds: new FormControl(60),
    custom_proxy: new FormControl('')
  });

  keyForm = new FormGroup({
    send_as: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    value: new FormControl('', Validators.required)
  });

  basicForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  keyAuthSendAsOptions = [
    { value: HttpQueryKeyAuthSendAs.Header, name: 'Header' },
    { value: HttpQueryKeyAuthSendAs.QueryParam, name: 'Query Param' }
  ];

  authTypeOptions = [
    { value: null, name: 'None' },
    { value: HttpQueryAuthType.Key, name: 'API Key' },
    { value: HttpQueryAuthType.BasicAuth, name: 'Basic Auth' },
    { value: HttpQueryAuthType.OAuth2, name: 'OAuth 2.0' }
  ];

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private templateService: TemplateService,
    secretTokenService: SecretTokenService,
    formUtils: FormUtils,
    projectSettingsService: ProjectSettingsService,
    projectTokenService: ProjectTokenService,
    popupService: PopupService,
    menuGeneratorService: MenuGeneratorService,
    injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    const resourceParams = this.resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);

    if (!resourceParams.baseHttpQuery) {
      return of(undefined);
    }

    const query = resourceParams.baseHttpQuery;

    this.form.patchValue(
      {
        url: query.url,
        auth_type: query.authType || null,
        custom_proxy: resourceParams.customProxy,
        ...(resourceParams.rateLimit &&
          resourceParams.rateLimit.isSet() && {
            rate_limit_actions: resourceParams.rateLimit.actions,
            rate_limit_per_seconds: resourceParams.rateLimit.perMs / 1000
          })
      },
      { emitEvent: false }
    );

    if (query.authType == HttpQueryAuthType.Key) {
      if (query.authParams) {
        this.keyForm.patchValue(
          {
            send_as: query.authParams['send_as'] || HttpQueryKeyAuthSendAs.Header,
            name: query.authParams['name'] || 'Authorization'
          },
          { emitEvent: false }
        );
      }

      return this.secretTokenService
        .getDetail(
          this.project.uniqueName,
          this.environment.uniqueName,
          this.resource.uniqueName,
          HTTP_QUERY_KEY_AUTH_NAME,
          this.mode == AdminMode.Builder
        )
        .pipe(
          map(result => {
            this.keyForm.patchValue(
              {
                value: result.value
              },
              { emitEvent: false }
            );
          })
        );
    } else if (query.authType == HttpQueryAuthType.BasicAuth) {
      return this.secretTokenService
        .getDetail(
          this.project.uniqueName,
          this.environment.uniqueName,
          this.resource.uniqueName,
          HTTP_QUERY_BASIC_AUTH_VALUE,
          this.mode == AdminMode.Builder
        )
        .pipe(
          map(result => {
            try {
              const credentials = atob(result.value).split(':');
              this.basicForm.patchValue(
                {
                  username: credentials[0],
                  password: credentials[1]
                },
                { emitEvent: false }
              );
            } catch (e) {}
          })
        );
    } else if (query.authType == HttpQueryAuthType.OAuth2) {
      if (query.headers) {
        const accessToken = query.headers.find(item => item.name == 'Authorization');
        const prefix = 'Bearer ';

        if (accessToken && accessToken['value'].startsWith(prefix)) {
          this.form.patchValue(
            {
              access_token_name: accessToken['value'].substring(prefix.length)
            },
            { emitEvent: false }
          );
        }
      }

      const params = cloneDeep(query.authParams);

      if (params['backend']) {
        const backend = socialBackends.find(item => item.path == params['backend']);
        params['backend'] = backend ? backend.name : undefined;
      }

      this.form.patchValue({ auth_params: params });

      return of(undefined);
    } else {
      return of(undefined);
    }
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const params = new RestAPIResourceParams();
    const baseHttpQuery = new HttpQuery();
    const tokens: SecretToken[] = [];
    const value = this.form.value;
    const keyFormValue = this.keyForm.value;
    const basicFormValue = this.basicForm.value;
    let hasValues = false;

    if (value['url']) {
      hasValues = true;
      baseHttpQuery.url = value['url'];
    }

    if (value['auth_type'] == HttpQueryAuthType.Key) {
      const keyValue = this.keyForm.value;

      hasValues = true;
      baseHttpQuery.authType = value['auth_type'];
      baseHttpQuery.authParams = {
        send_as: keyValue['send_as'],
        name: keyValue['name']
      };

      if (keyValue['send_as'] == HttpQueryKeyAuthSendAs.Header) {
        baseHttpQuery.headers = [{ name: keyValue['name'], value: `{-${HTTP_QUERY_KEY_AUTH_NAME}-}` }];
      } else if (keyValue['send_as'] == HttpQueryKeyAuthSendAs.QueryParam) {
        baseHttpQuery.queryParams = [{ name: keyValue['name'], value: `{-${HTTP_QUERY_KEY_AUTH_NAME}-}` }];
      }
    } else if (value['auth_type'] == HttpQueryAuthType.BasicAuth) {
      hasValues = true;
      baseHttpQuery.authType = value['auth_type'];
      baseHttpQuery.headers = [{ name: 'Authorization', value: `Basic {-${HTTP_QUERY_BASIC_AUTH_VALUE}-}` }];
    } else if (value['auth_type'] == HttpQueryAuthType.OAuth2) {
      hasValues = true;
      baseHttpQuery.authType = value['auth_type'];
      baseHttpQuery.headers = [{ name: 'Authorization', value: `Bearer ${value['access_token_name']}` }];

      const newParams = cloneDeep(value['auth_params']);

      if (newParams['backend']) {
        const backend = socialBackends.find(item => item.name == newParams['backend']);
        newParams['backend'] = backend ? backend.path : undefined;
      }

      baseHttpQuery.authParams = newParams;
    }

    if (keyFormValue['value']) {
      const token = new SecretToken();

      token.name = HTTP_QUERY_KEY_AUTH_NAME;
      token.value = keyFormValue['value'];

      tokens.push(token);
    }

    if (basicFormValue['username'] && basicFormValue['password']) {
      try {
        const token = new SecretToken();

        token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
        token.value = btoa([basicFormValue['username'], basicFormValue['password']].join(':'));

        tokens.push(token);
      } catch (e) {}
    }

    if (hasValues) {
      params.baseHttpQuery = baseHttpQuery;
    }

    const rateLimitActions = parseNumber(value['rate_limit_actions']);
    const rateLimitPerSeconds = parseNumber(value['rate_limit_per_seconds']);

    if (rateLimitActions && rateLimitPerSeconds) {
      params.rateLimit = new RateLimit({
        actions: rateLimitActions,
        perMs: rateLimitPerSeconds * 1000
      });
    } else {
      params.rateLimit = undefined;
    }

    params.customProxy = value['custom_proxy'];

    let modelDescriptions$: Observable<Object[]>;

    if (!this.resource) {
      modelDescriptions$ = this.templateService.getResourceInitialModelDescriptions(this.typeItem.name).pipe(
        map(template => {
          if (!template) {
            return;
          }

          return template.modelDescriptions.map(item => {
            item.model = ModelDescription.generateModel();
            item.resource = '{{resource}}';
            item.demo = true;
            return item.serialize();
          });
        })
      );
    } else {
      modelDescriptions$ = of(undefined);
    }

    return modelDescriptions$.pipe(
      map(modelDescriptions => {
        return {
          resourceName: this.resourceForm.value['name'],
          resourceParams: params.serialize(),
          modelDescriptions: modelDescriptions,
          secretTokens: tokens.map(item => item.serialize())
        };
      })
    );
  }
}

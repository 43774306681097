import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { BooleanFieldStyle } from '@modules/field-components';
import {
  createFormFieldFactory,
  FieldType,
  registerFieldViewParamsComponent,
  VideoOutputFormat
} from '@modules/fields';
import { StorageSelectSource } from '@modules/storages-queries';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { VideoFieldViewParamsForm } from './video-field-view-params.form';

@Component({
  selector: 'app-video-field-view-params',
  templateUrl: './video-field-view-params.component.html',
  providers: [VideoFieldViewParamsForm, StorageSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoFieldViewParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  outputFormats = VideoOutputFormat;

  constructor(public form: VideoFieldViewParamsForm, public storageSelectSource: StorageSelectSource) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, { context: this.context });
  }
}

registerFieldViewParamsComponent(FieldType.Video, VideoFieldViewParamsComponent);

import { FormControl, FormGroup } from '@angular/forms';

import { SignUpField } from '@modules/all-project-settings';
import { isSet } from '@shared';

export class SignUpFieldControl extends FormGroup {
  controls: {
    project_property: FormControl;
    required: FormControl;
    placeholder: FormControl;
  };

  constructor(
    state: {
      project_property?: string;
      required?: boolean;
      placeholder?: boolean;
    } = {}
  ) {
    super({
      project_property: new FormControl(isSet(state.project_property) ? state.project_property : undefined),
      required: new FormControl(isSet(state.required) ? state.required : false),
      placeholder: new FormControl(isSet(state.placeholder) ? state.placeholder : undefined)
    });
  }

  deserialize(instance: SignUpField) {
    this.controls.project_property.patchValue(instance.property);
    this.controls.required.patchValue(instance.required);
    this.controls.placeholder.patchValue(instance.placeholder);

    this.markAsPristine();
  }

  serialize(): SignUpField {
    const result = new SignUpField();

    result.property = this.controls.project_property.value;
    result.required = this.controls.required.value;
    result.placeholder = this.controls.placeholder.value;

    return result;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ResourceTypeItem } from '@modules/projects';

@Component({
  selector: 'app-sync-mode-image',
  templateUrl: './sync-mode-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncModeImageComponent implements OnInit {
  @Input() typeItem: ResourceTypeItem;

  constructor() {}

  ngOnInit() {}
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { ActionControllerService } from '@modules/action-queries';
import { ActionItem } from '@modules/actions';
import { CustomViewSettings, ModelData, ModelDataType, ViewContext } from '@modules/customize';
import { CustomizeBarEditEvent, CustomizeBarEditEventType } from '@modules/customize-bar';
import { QueryBuilderContext } from '@modules/queries-components';
import { isBodyHasChild, isElementHasChild, nodeListToArray, TypedChanges, TypedControl } from '@shared';

import { CustomPageQueriesForm } from './custom-page-queries.form';
import { PageQueryArray } from './page-query-array.service';
import { PageQueryControl } from './page-query-control.service';

const customPageQueriesMenuClickEventProperty = '_customPageQueriesMenuClickEvent';

export function markCustomPageQueriesClickEvent(clickEvent: MouseEvent) {
  clickEvent[customPageQueriesMenuClickEventProperty] = true;
}

export function isCustomPageQueriesClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[customPageQueriesMenuClickEventProperty];
}

@Component({
  selector: 'app-custom-page-queries',
  templateUrl: './custom-page-queries.component.html',
  providers: [PageQueryArray, CustomPageQueriesForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPageQueriesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() viewSettings: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() opened = false;
  @Output() queriesUpdated = new EventEmitter<ModelData[]>();
  @Output() actionsUpdated = new EventEmitter<ActionItem[]>();
  @Output() closed = new EventEmitter<void>();

  editQueryControl: PageQueryControl;
  editActionControl: TypedControl<ActionItem>;
  editActionControlIndex: number;
  formSubscriptions: Subscription[] = [];
  blurSubscription: Subscription;
  modelDataTypes = ModelDataType;
  analyticsSource = 'page_queries';

  actionNameCleanValue = (() => {
    return (value: string): string => {
      const names = this.form.controls.open_actions
        .serialize()
        .filter(item => (this.editActionControl ? item !== this.editActionControl.value : true))
        .reduce((acc, item) => {
          acc[item.name.toLowerCase()] = item;
          return acc;
        }, {});

      return this.form.controls.open_actions.generateActionName(value, names);
    };
  })();

  constructor(
    public form: CustomPageQueriesForm,
    private actionControllerService: ActionControllerService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomPageQueriesComponent>): void {
    if (changes.viewSettings) {
      this.initForm();
    }

    if (changes.viewSettings && !changes.viewSettings.firstChange) {
      this.close();
    }

    if (changes.opened && this.opened) {
      this.onOpened();
    } else if (changes.opened && !changes.opened.firstChange && !this.opened) {
      this.onClosed();
    }
  }

  initForm() {
    this.formSubscriptions.forEach(item => item.unsubscribe());
    this.formSubscriptions = [];

    this.form.init(this.viewSettings);

    this.formSubscriptions.push(
      this.form.controls.queries.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
        this.queriesUpdated.emit(this.form.controls.queries.serialize());
      })
    );

    this.formSubscriptions.push(
      this.form.controls.open_actions.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
        this.actionsUpdated.emit(this.form.controls.open_actions.serialize());
      })
    );
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.cd.markForCheck();

    this.onClosed();
  }

  onOpened() {
    setTimeout(() => {
      this.blurSubscription = fromEvent<MouseEvent>(window.document, 'click')
        .pipe(
          filter(e => {
            if (isCustomPageQueriesClickEvent(e)) {
              return false;
            }

            const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popups'));
            if (
              overlays.some(overlay => isElementHasChild(overlay, e.target as HTMLElement)) ||
              !isBodyHasChild(e.target as HTMLElement)
            ) {
              return false;
            }

            return true;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.close());
    }, 0);
  }

  onClosed() {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
      this.blurSubscription = undefined;
    }

    this.closeQueryEdit();
    this.closeActionEdit();

    this.closed.emit();
  }

  markClickEvent(e: MouseEvent) {
    markCustomPageQueriesClickEvent(e);
  }

  editQuery(control: PageQueryControl) {
    this.editQueryControl = control;
    this.cd.markForCheck();
  }

  closeQueryEdit() {
    this.editQueryControl = undefined;
    this.cd.markForCheck();
  }

  removeQuery(control: PageQueryControl) {
    this.form.controls.queries.removeControl(control);
  }

  addQuery(type: ModelDataType) {
    const control = this.form.controls.queries.appendControl({
      value: {
        type: type,
        name: `Query ${this.form.controls.queries.length + 1}`
      }
    });
    this.editQuery(control);
  }

  getQueryTitle(control: PageQueryControl, index: number) {
    if (control.controls.name.value) {
      return control.controls.name.value;
    } else {
      return `Query ${index + 1}`;
    }
  }

  queryDragDrop(event: CdkDragDrop<PageQueryControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.queries.controls, event.previousIndex, event.currentIndex);
      this.form.controls.queries.updateValueAndValidity();
    }
  }

  addAction() {
    const names = this.form.controls.open_actions.serialize().reduce((acc, item) => {
      acc[item.name.toLowerCase()] = item;
      return acc;
    }, {});
    const action = new ActionItem();
    const defaultName = this.form.controls.open_actions.getControlNameDefault(
      this.form.controls.open_actions.controls.length
    );

    action.generateUid();
    action.name = this.form.controls.open_actions.generateActionName(defaultName, names);

    const control = this.form.controls.open_actions.appendControl(action);
    const index = this.form.controls.open_actions.controls.length - 1;

    this.editAction(control, index);
  }

  editAction(action: TypedControl<ActionItem>, index: number) {
    this.editActionControl = action;
    this.editActionControlIndex = index;
    this.cd.markForCheck();
  }

  closeActionEdit() {
    this.editActionControl = undefined;
    this.editActionControlIndex = undefined;
    this.cd.markForCheck();
  }

  onActionEditEvent(e: CustomizeBarEditEvent) {
    if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
      const actionItem = e.args['result'] as ActionItem;
      actionItem.name = e.args['title'] as string;
      this.editActionControl.patchValue(actionItem);
    }
  }

  actionDragDrop(event: CdkDragDrop<TypedControl<ActionItem>[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.open_actions.controls, event.previousIndex, event.currentIndex);
      this.form.controls.open_actions.updateValueAndValidity();
    }
  }

  executeAction(action: ActionItem) {
    this.actionControllerService
      .execute(action, {
        context: this.context,
        injector: this.injector
      })
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}

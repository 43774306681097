<ng-container *ngIf="loading">
  <div class="workflow-step__main">
    <div class="workflow-step__title">
      <span class="stub-text">Action</span>
    </div>
    <div class="workflow-step__subtitle">
      <span class="stub-text">Action Type</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="workflow-step__main">
    <div *ngIf="title" class="workflow-step__title">{{ title }}</div>
    <div *ngIf="subtitle" class="workflow-step__subtitle">{{ subtitle }}</div>
  </div>

  <div *ngIf="icon || image" class="workflow-step__right">
    <div class="workflow-step__icon">
      <div
        *ngIf="icon"
        class="workflow-step__icon-inner workflow-step__icon-inner_background"
        [ngClass]="'icon-' + icon"
      ></div>
      <div *ngIf="image" class="workflow-step__icon-inner" [style.background-image]="'url(' + image + ')'"></div>

      <app-loader-small
        *ngIf="indicatorLoader"
        class="workflow-step__icon-indicator workflow-step__icon-indicator_yellow"
        [ngClass]="'workflow-step__icon-indicator_' + indicatorColor"
      ></app-loader-small>
      <span
        *ngIf="indicatorIcon"
        class="workflow-step__icon-indicator"
        [ngClass]="['workflow-step__icon-indicator_' + indicatorColor, 'icon-' + indicatorIcon]"
      ></span>
    </div>
  </div>
</ng-container>

<div class="workflow-step__border"></div>

<div class="workflow-step__actions">
  <button
    *ngIf="executeEnabled"
    type="button"
    class="workflow-step__actions-item icon-play"
    [appTip]="'Test Step'"
    [appTipOptions]="{ side: 'top' }"
    (click)="$event.stopPropagation(); stepExecute.emit()"
  ></button>

  <button
    *ngIf="duplicateEnabled"
    type="button"
    class="workflow-step__actions-item icon-duplicate_2"
    [appTip]="'Duplicate'"
    [appTipOptions]="{ side: 'top' }"
    (click)="$event.stopPropagation(); stepDuplicate.emit()"
  ></button>

  <button
    *ngIf="deleteEnabled"
    type="button"
    class="workflow-step__actions-item icon-bin"
    [appTip]="'Delete'"
    [appTipOptions]="{ side: 'top' }"
    (click)="$event.stopPropagation(); stepDelete.emit()"
  ></button>
</div>

<div *ngIf="connectorTop" class="workflow-step__connector workflow-step__connector_position_top"></div>
<div *ngIf="connectorBottom" class="workflow-step__connector workflow-step__connector_position_bottom"></div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { Point } from '@common/drag-drop2';
import { addClass, coerceFloat, controlValue, isSet, MouseButton, removeClass, TypedChanges } from '@shared';

const SLIDER_DRAGGING_CLASS = 'app-slider-dragging';

export interface DraggingState {
  startPosition: Point;
  startDelta: Point;
  baseThumb: boolean;
  subscriptions: Subscription[];
}

const DEFAULT_MIN = 1;
const DEFAULT_MAX = 10;
const DEFAULT_STEP = 1;

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() range = false;
  @Input() baseControl: AbstractControl;
  @Input() baseValue: number;
  @Input() extentControl: AbstractControl;
  @Input() extentValue: number;
  @Input() disabled = false;
  @Input() min = DEFAULT_MIN;
  @Input() minLabel = 'Min';
  @Input() max = DEFAULT_MAX;
  @Input() maxLabel = 'Max';
  @Input() normal: number;
  @Input() normalLabel = 'Normal';
  @Input() step = DEFAULT_STEP;
  @Input() stickToSteps = false;
  @Input() debounce = 60;
  @Input() valueLabel = true;
  @Input() labels = true;
  @Input() trackBackground: string;
  @Input() fillBackground: string;
  @Input() thumbBackgroundColor: string;
  @Input() thumbBorderColor: string;
  @Input() orange = false;
  @Input() dark = false;
  @Input() compact = false;
  @Input() thumbInside = false;
  @Output() baseChange = new EventEmitter<number>();
  @Output() extentChange = new EventEmitter<number>();

  @ViewChild('track') trackElement: ElementRef;
  @ViewChild('base_thumb') baseThumbElement: ElementRef;
  @ViewChild('extent_thumb') extentThumbElement: ElementRef;

  baseDisplayValue: number;
  baseDraggingValue: number;

  extentDisplayValue: number;
  extentDraggingValue: number;

  draggingState: DraggingState;
  setBaseValue = new Subject<number>();
  setExtentValue = new Subject<number>();
  trackBackgroundSafe: SafeStyle;
  fillBackgroundSafe: SafeStyle;
  thumbBackgroundColorSafe: SafeStyle;
  thumbBorderColorSafe: SafeStyle;

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.setBaseValue.pipe(debounceTime(this.debounce), untilDestroyed(this)).subscribe(value => {
      if (this.baseControl) {
        this.baseControl.setValue(value);
      }

      this.baseChange.emit(value);
    });

    this.setExtentValue.pipe(debounceTime(this.debounce), untilDestroyed(this)).subscribe(value => {
      if (this.extentControl) {
        this.extentControl.setValue(value);
      }

      this.extentChange.emit(value);
    });

    const baseValue$ = this.baseControl ? controlValue(this.baseControl) : of(this.baseValue);
    const extentValue$ = this.extentControl ? controlValue(this.extentControl) : of(this.extentValue);

    combineLatest(baseValue$, extentValue$)
      .pipe(untilDestroyed(this))
      .subscribe(([baseValue, extentValue]) => {
        this.baseDisplayValue = baseValue;
        this.extentDisplayValue = isSet(extentValue) ? Math.max(baseValue, extentValue) : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    if (this.draggingState) {
      removeClass(document.body, SLIDER_DRAGGING_CLASS);
    }
  }

  ngOnChanges(changes: TypedChanges<SliderComponent>): void {
    if (changes.min) {
      this.min = coerceFloat(this.min, DEFAULT_MIN);
    }

    if (changes.max) {
      this.max = coerceFloat(this.max, DEFAULT_MAX);
    }

    if (changes.step) {
      this.step = coerceFloat(this.step, DEFAULT_STEP);
    }

    if (changes.trackBackground) {
      this.trackBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(this.trackBackground);
    }

    if (changes.fillBackground) {
      this.fillBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(this.fillBackground);
    }

    if (changes.thumbBackgroundColor) {
      this.thumbBackgroundColorSafe = this.sanitizer.bypassSecurityTrustStyle(this.thumbBackgroundColor);
    }

    if (changes.thumbBorderColor) {
      this.thumbBorderColorSafe = this.sanitizer.bypassSecurityTrustStyle(this.thumbBorderColor);
    }
  }

  cleanValue(value: number): number {
    if (typeof value != 'number') {
      value = parseFloat(value);
    }

    if (isNaN(value)) {
      value = this.min;
    }

    value = clamp(value, this.min, this.max);

    const diff = (value - this.min) % this.step;

    if (diff != 0) {
      if (diff >= this.step / 2) {
        value += this.step - diff;
      } else {
        value -= diff;
      }
    }

    return value;
  }

  get leftPercentage(): number {
    if (this.range) {
      const value = this.isDraggingThumb(true) ? this.baseDraggingValue : this.cleanValue(this.baseDisplayValue);
      const total = this.max - this.min;
      return total != 0 ? clamp((value - this.min) / total, 0, 1) : 0;
    } else {
      return 0;
    }
  }

  get widthPercentage(): number {
    if (this.range) {
      const leftPercentage = this.leftPercentage;
      const value = this.isDraggingThumb(false) ? this.extentDraggingValue : this.cleanValue(this.extentDisplayValue);
      const total = this.max - this.min;
      return total != 0 ? clamp((value - this.min) / total - leftPercentage, 0, 1 - leftPercentage) : 0;
    } else {
      const value = this.isDraggingThumb(true) ? this.baseDraggingValue : this.cleanValue(this.baseDisplayValue);
      const total = this.max - this.min;
      return total != 0 ? clamp((value - this.min) / total, 0, 1) : 0;
    }
  }

  get baseValueClean(): number {
    return this.cleanValue(this.isDraggingThumb(true) ? this.baseDraggingValue : this.baseDisplayValue);
  }

  get extentValueClean(): number {
    return this.cleanValue(this.isDraggingThumb(false) ? this.extentDraggingValue : this.extentDisplayValue);
  }

  isDraggingThumb(base: boolean): boolean {
    if (!this.draggingState) {
      return false;
    }

    if (base) {
      return this.draggingState.baseThumb;
    } else {
      return !this.draggingState.baseThumb;
    }
  }

  isBaseThumbPosition(position: Point): boolean {
    if (this.range) {
      const rawValue = this.getRawValueForPosition(position);

      if (rawValue < this.baseDisplayValue) {
        return true;
      } else if (rawValue >= this.extentDisplayValue) {
        return false;
      } else {
        const baseDistance = Math.abs(rawValue - this.baseDisplayValue);
        const extentDistance = Math.abs(rawValue - this.extentDisplayValue);
        return baseDistance < extentDistance;
      }
    } else {
      return true;
    }
  }

  dragStart(mouseDownEvent: MouseEvent) {
    if (mouseDownEvent.button != MouseButton.Main) {
      return;
    }

    mouseDownEvent.preventDefault();

    const position = { x: mouseDownEvent.clientX, y: mouseDownEvent.clientY };
    const baseThumb = this.isBaseThumbPosition(position);
    const thumbElement = baseThumb ? this.baseThumbElement.nativeElement : this.extentThumbElement.nativeElement;
    const dragBounds = thumbElement.getBoundingClientRect();
    const subscriptions = [];

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          e.preventDefault();
          this.dragMove(e);
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(e => this.dragFinish(e))
    );

    addClass(document.body, SLIDER_DRAGGING_CLASS);

    this.draggingState = {
      startPosition: position,
      startDelta: {
        x: mouseDownEvent.clientX - (dragBounds.left + dragBounds.width * 0.5),
        y: mouseDownEvent.clientY - (dragBounds.top + dragBounds.height * 0.5)
      },
      baseThumb: baseThumb,
      subscriptions: subscriptions
    };

    if (baseThumb) {
      this.baseDraggingValue = this.cleanValue(this.baseDisplayValue);
    } else {
      this.extentDraggingValue = this.cleanValue(this.extentDisplayValue);
    }

    this.setValueForPosition(position);
  }

  dragMove(e: MouseEvent) {
    const position = { x: e.clientX, y: e.clientY };
    this.setValueForPosition(position);
  }

  dragFinish(e: MouseEvent) {
    const position = { x: e.clientX, y: e.clientY };
    this.setValueForPosition(position);

    removeClass(document.body, SLIDER_DRAGGING_CLASS);

    if (this.draggingState) {
      this.draggingState.subscriptions.forEach(item => item.unsubscribe());
    }

    this.baseDraggingValue = undefined;
    this.extentDraggingValue = undefined;
    this.draggingState = undefined;
    this.cd.markForCheck();
  }

  getRawValueForPosition(position: Point) {
    const padding = 5;
    const bounds = this.trackElement.nativeElement.getBoundingClientRect();
    const percentage = clamp((position.x - (bounds.left + padding)) / (bounds.width - padding * 2), 0, 1);
    return this.min + (this.max - this.min) * percentage;
  }

  setValueForPosition(position: Point) {
    let value = this.getRawValueForPosition(position);

    if (this.range) {
      if (this.draggingState.baseThumb) {
        value = Math.min(value, this.extentDisplayValue);
      } else {
        value = Math.max(value, this.baseDisplayValue);
      }
    }

    const cleanValue = this.cleanValue(value);

    if (this.range && !this.draggingState.baseThumb) {
      this.extentDisplayValue = this.extentDraggingValue;
      this.extentDraggingValue = this.stickToSteps ? cleanValue : value;
      this.cd.markForCheck();

      this.setExtentValue.next(cleanValue);
    } else {
      this.baseDisplayValue = this.baseDraggingValue;
      this.baseDraggingValue = this.stickToSteps ? cleanValue : value;
      this.cd.markForCheck();

      this.setBaseValue.next(cleanValue);
    }
  }
}

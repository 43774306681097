import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType } from '@modules/fields';
import { registerFieldDataParamsComponent } from '@modules/fields/data/components';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { TimeFieldDataParamsForm } from './time-field-data-params.form';

@Component({
  selector: 'app-time-field-data-params',
  templateUrl: './time-field-data-params.component.html',
  providers: [TimeFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeFieldDataParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();

  constructor(public form: TimeFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }
}

registerFieldDataParamsComponent(FieldType.Time, TimeFieldDataParamsComponent);

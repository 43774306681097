import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GuideUtilsService } from '../../services/guide-utils/guide-utils.service';
import { Task } from '../task';
import { TaskScenario } from '../task-scenario';

@Injectable({
  providedIn: 'root'
})
export class AddRelatedDataTask extends Task {
  constructor(private guideUtilsService: GuideUtilsService) {
    super();
  }

  init(): Observable<TaskScenario> | TaskScenario {
    return this.guideUtilsService.getAnyModel().pipe(
      map(model => {
        if (!model) {
          return;
        }

        return new TaskScenario([
          {
            link: model.getLink(),
            items: []
          }
        ]);
      })
    );
  }
}

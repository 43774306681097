import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-icon',
  templateUrl: './help-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpIconComponent implements OnInit {
  @Input() documentation: string;
  @Input() left = true;
  @Input() source: string;
  @Input() label = 'See how it works';

  constructor() {}

  ngOnInit() {}
}

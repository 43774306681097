import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionStore } from '@modules/action-queries';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { ViewSettingsStore } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { JET_APP_GROUPS, JET_APP_USERS } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, DraftItemsType, JET_APP_RESOURCE, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { isSet } from '@shared';
var DraftChangesService = /** @class */ (function () {
    function DraftChangesService(currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionStore, viewSettingsStore, menuSettingsStore, customViewsStore, taskQueueStore, projectPropertyStore, projectSettingsStore) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.customViewsStore = customViewsStore;
        this.taskQueueStore = taskQueueStore;
        this.projectPropertyStore = projectPropertyStore;
        this.projectSettingsStore = projectSettingsStore;
    }
    DraftChangesService.prototype.getDraftChanges$ = function (options) {
        if (options === void 0) { options = {}; }
        return combineLatest(this.viewSettingsStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), this.modelDescriptionStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), this.actionStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), this.menuSettingsStore.get(false, { includeDeleted: true }), this.customViewsStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), combineLatest(this.currentProjectStore.get(), this.currentEnvironmentStore.instance$).pipe(map(function (_a) {
            var project = _a[0], environment = _a[1];
            if (!project || !environment) {
                return [];
            }
            return project.getEnvironmentResources(environment.uniqueName, { includeDeleted: true });
        })), this.taskQueueStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), this.projectPropertyStore.get(false, { includeDeleted: true }).pipe(map(function (value) { return value || []; })), this.projectSettingsStore.get(false, { includeDeleted: true })).pipe(map(function (_a) {
            var viewSettings = _a[0], modelDescriptions = _a[1], actionDescriptions = _a[2], menuSettings = _a[3], customViews = _a[4], resources = _a[5], taskQueues = _a[6], projectProperties = _a[7], projectSettings = _a[8];
            var draftChanges = [];
            var secretTokens = resources.reduce(function (acc, item) {
                acc.push.apply(acc, item.secretTokensWithDeleted);
                return acc;
            }, []);
            var storages = resources.reduce(function (acc, item) {
                acc.push.apply(acc, item.storagesWithDeleted);
                return acc;
            }, []);
            if (isSet(options.resource)) {
                resources = resources.filter(function (item) {
                    return item.uniqueName == options.resource;
                });
                draftChanges.push(DraftItemsType.Resources);
                secretTokens = secretTokens.filter(function (item) {
                    return item.resource == options.resource;
                });
                draftChanges.push(DraftItemsType.SecretTokens);
            }
            if (isSet(options.modelId)) {
                modelDescriptions = modelDescriptions.filter(function (item) {
                    return item.isSame(options.modelId);
                });
                draftChanges.push(DraftItemsType.ModelDescriptions);
                actionDescriptions = actionDescriptions.filter(function (item) {
                    return [item.resource, item.model].join('.') == options.modelId;
                });
                draftChanges.push(DraftItemsType.ActionDescriptions);
                projectProperties = projectProperties.filter(function (item) {
                    if (options.modelId == [JET_APP_RESOURCE, JET_APP_USERS].join('.')) {
                        return item.type == ProjectPropertyType.User;
                    }
                    else if (options.modelId == [JET_APP_RESOURCE, JET_APP_GROUPS].join('.')) {
                        return item.type == ProjectPropertyType.Group;
                    }
                    else {
                        return false;
                    }
                });
                draftChanges.push(DraftItemsType.ProjectProperties);
            }
            if (isSet(options.action)) {
                actionDescriptions = actionDescriptions.filter(function (item) {
                    return item.isSame(options.action);
                });
                draftChanges.push(DraftItemsType.ActionDescriptions);
            }
            return [
                {
                    type: DraftItemsType.ViewSettings,
                    count: viewSettings.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ModelDescriptions,
                    count: modelDescriptions.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ActionDescriptions,
                    count: actionDescriptions.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.MenuSettings,
                    count: menuSettings && menuSettings.draft ? 1 : 0
                },
                {
                    type: DraftItemsType.CustomViews,
                    count: customViews.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.Resources,
                    count: resources.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.SecretTokens,
                    count: secretTokens.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.Storages,
                    count: storages.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.TaskQueues,
                    count: taskQueues.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ProjectProperties,
                    count: projectProperties.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ProjectSettings,
                    count: projectSettings.filter(function (item) { return item.draft; }).length
                }
            ].filter(function (item) { return !draftChanges.length || draftChanges.includes(item.type); });
        }));
    };
    return DraftChangesService;
}());
export { DraftChangesService };

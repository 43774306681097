var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
var ImportSketchFileForm = /** @class */ (function (_super) {
    __extends(ImportSketchFileForm, _super);
    function ImportSketchFileForm() {
        var _this = _super.call(this, {
            file: new FormControl(null, Validators.required),
            page: new FormControl(null, Validators.required),
            artboard: new FormControl(null, Validators.required)
        }) || this;
        _this.controls.file.valueChanges.pipe(untilDestroyed(_this)).subscribe(function () {
            _this.controls.page.patchValue(null);
            _this.controls.artboard.patchValue(null);
        });
        _this.controls.page.valueChanges.pipe(untilDestroyed(_this)).subscribe(function () {
            _this.controls.artboard.patchValue(null);
        });
        return _this;
    }
    ImportSketchFileForm.prototype.ngOnDestroy = function () { };
    return ImportSketchFileForm;
}(FormGroup));
export { ImportSketchFileForm };

<div class="message-user-activity">
  <div class="message-user-activity__inner">
    <div class="message-user-activity__left">
      <div
        class="message-user-activity__avatar"
        [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
      ></div>
    </div>
    <div class="message-user-activity__right">
      <span class="message-user-activity__author">{{ item.user.strFull }}</span>
      <p class="message-user-activity__message" [innerHTML]="message | appFormatText"></p>
      <span class="message-user-activity__date">{{ item.dateAdd | appTimeAgo: true }}</span>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomViewSettings } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';

import { ActionItemArray } from './action-item.array';
import { PageQueryArray } from './page-query-array.service';

export const validateActions: AsyncValidatorFn = control => {
  const parent = control.parent as CustomPageQueriesForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value || !control.value.length) {
    return of(null);
  }

  return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
    map(result => {
      if (result.some(configured => !configured)) {
        return { required: true };
      }
    })
  );
};

@Injectable()
export class CustomPageQueriesForm extends FormGroup {
  viewSettings: CustomViewSettings;

  controls: {
    queries: PageQueryArray;
    open_actions: ActionItemArray;
  };

  constructor(
    public elementConfigurationService: ElementConfigurationService,
    customizeBarModelDataArray: PageQueryArray
  ) {
    super({
      queries: customizeBarModelDataArray,
      open_actions: new ActionItemArray([], undefined, validateActions)
    });
  }

  init(viewSettings: CustomViewSettings) {
    this.viewSettings = viewSettings;

    if (viewSettings) {
      this.controls.queries.deserialize(viewSettings.queries);
      this.controls.open_actions.deserialize(viewSettings.openActions);
    }
  }
}

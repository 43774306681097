import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type LayoutOptions = 'poster_left' | 'poster_right' | 'form' | 'background';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {
  @Input() layout: LayoutOptions;
  @Input() backgroundColor: string;
  @Input() backgroundImage: string;
  @Input() fillScreen = false;
  @Input() preview = false;

  constructor() {}

  ngOnInit() {}
}

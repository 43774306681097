import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material';

import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { BarCodeComponent } from './components/bar-code/bar-code.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { ScannerCamerasDropdownComponent } from './components/scanner-cameras-dropdown/scanner-cameras-dropdown.component';
import { ScannerPopupComponent } from './components/scanner-popup/scanner-popup.component';
import { ScannerComponent } from './components/scanner/scanner.component';

@NgModule({
  imports: [CommonModule, SharedModule, UniqueIdModule, MatMenuModule, OverlayModule, TipsModule, UiModule],
  declarations: [
    QrCodeComponent,
    BarCodeComponent,
    ScannerComponent,
    ScannerCamerasDropdownComponent,
    ScannerPopupComponent
  ],
  exports: [QrCodeComponent, BarCodeComponent, ScannerComponent],
  entryComponents: [ScannerPopupComponent]
})
export class ImageCodesModule {}

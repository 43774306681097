import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { GlobalsService } from '@modules/globals';

import { ViewContext } from '../../data/view-context';

@Injectable()
export class ViewContextGlobalsService implements OnDestroy {
  constructor(private globalsService: GlobalsService, private context: ViewContext) {
    this.context.outputValues$.pipe(untilDestroyed(this)).subscribe(value => {
      this.globalsService.setContext(value);
    });
  }

  ngOnDestroy(): void {
    this.globalsService.clearContext();
  }
}

<div
  class="scanner"
  [class.scanner_disabled]="scanningLoading || scanningInitialLoading"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <div
    class="scanner__background"
    [class.scanner__background_upload-empty]="!scanning && !scanningInitialLoading && !scanningImage"
    [class.scanner__background_upload-active]="draggingOver"
  >
    <div *ngIf="scanning || scanningInitialLoading" class="scanner__background-icon icon-camera"></div>
  </div>

  <div class="scanner__viewport" [id]="'scanner' | appUniqueId: idToken" #scanner></div>

  <div *ngIf="!scanning && !scanningInitialLoading && !scanningImage" class="scanner__placeholder">
    <app-scanner-cameras-dropdown
      [origin]="camera_control"
      [opened]="cameraDropdownOpened"
      [cameras]="cameras"
      [selectedCamera]="scanningCamera"
      (selectCamera)="setCameraDropdownOpened(false); selectCamera($event.id)"
      (close)="setCameraDropdownOpened(false)"
    ></app-scanner-cameras-dropdown>

    <div
      class="scanner__placeholder-button scanner__button"
      [class.scanner__button_disabled]="camerasLoading || cameraDropdownOpened"
      cdkOverlayOrigin
      #camera_control="cdkOverlayOrigin"
      (click)="openCameraDropdown()"
    >
      <div class="scanner__button-icon icon-camera"></div>
      <div class="scanner__button-label">Scan with Camera</div>
    </div>

    <div class="scanner__placeholder-label">or</div>

    <label class="scanner__placeholder-button scanner__button" [for]="'scanner_file' | appUniqueId: idToken">
      <span class="scanner__button-icon icon-image"></span>
      <span class="scanner__button-label">Scan Image file</span>
    </label>
  </div>

  <div
    *ngIf="scanningImage && scanningImageError | appIsSet"
    class="scanner__overlay"
    [style.background-image]="'url(' + scanConfirmImage + ')'"
  >
    <div class="scanner__overlay-footer">
      <div class="scanner__overlay-message">
        {{ scanningImageError }}
      </div>
    </div>
  </div>

  <div *ngIf="scanning || scanningInitialLoading || scanningImage" class="scanner__controls">
    <app-scanner-cameras-dropdown
      [origin]="camera_control"
      [opened]="cameraDropdownOpened"
      [cameras]="cameras"
      [selectedCamera]="scanningCamera"
      (selectCamera)="setCameraDropdownOpened(false); selectCamera($event.id)"
      (close)="setCameraDropdownOpened(false)"
    ></app-scanner-cameras-dropdown>

    <div
      *ngIf="scanning"
      class="scanner__control icon-close"
      (click)="stopScanning()"
      [appTip]="'Stop scanning'"
      [appTipOptions]="{ side: 'left' }"
    ></div>

    <div
      class="scanner__control icon-camera"
      [class.scanner__control_disabled]="camerasLoading || cameraDropdownOpened"
      cdkOverlayOrigin
      #camera_control="cdkOverlayOrigin"
      (click)="openCameraDropdown()"
      [appTip]="'Choose camera'"
      [appTipOptions]="{ side: 'left' }"
    ></div>

    <label
      class="scanner__control icon-image"
      [for]="'scanner_file' | appUniqueId: idToken"
      [appTip]="'Scan Image file'"
      [appTipOptions]="{ side: 'left' }"
    ></label>
  </div>

  <div
    *ngIf="scanConfirmImage"
    class="scanner__overlay scanner__overlay_bottom scanner__overlay_background"
    [style.background-image]="'url(' + scanConfirmImage + ')'"
  >
    <div class="scanner__overlay-footer">
      <div class="scanner__overlay-message scanner__overlay-message_fill scanner__overlay-message_wrap">
        {{ scanConfirmValue }}
      </div>

      <div class="scanner__overlay-buttons">
        <div class="button button_fill scanner__overlay-button" (click)="scanConfirmCancel()">Scan again</div>
        <div class="button button_primary button_fill scanner__overlay-button" (click)="scanConfirmSubmit()">
          Use this scan
        </div>
      </div>
    </div>
  </div>
</div>

<input
  class="input input_file"
  type="file"
  (change)="onFileChange($event.target)"
  [id]="'scanner_file' | appUniqueId: idToken"
/>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PathSelectedEvent } from '../query-builder-object-structure-node/query-builder-object-structure-node.component';

@Component({
  selector: 'app-query-builder-object-structure',
  templateUrl: './query-builder-object-structure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderObjectStructureComponent implements OnInit, OnDestroy {
  @Input() object: any;
  @Input() pathControl: AbstractControl;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.pathControl.valueChanges.pipe(untilDestroyed(this)).subscribe(e => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  onSelected(event: PathSelectedEvent) {
    this.pathControl.patchValue(event.path);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionItem } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { CustomizeActionOptions } from '../customize-bar-action-edit/customize-bar-action-edit.form';

@Component({
  selector: 'app-action-single-edit',
  templateUrl: './action-single-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSingleEditComponent implements OnInit, OnDestroy {
  @Input() options: CustomizeActionOptions = {};
  @Input() label: string;
  @Input() title: string;
  @Input() control: AbstractControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() defaultTitle: string;
  @Input() defaultSubtitle = 'Not specified';
  @Input() defaultDisabled = true;
  @Input() draggable = false;
  @Input() remove = false;
  @Input() pin = false;
  @Input() pinned = false;
  @Input() icon: string;
  @Input() object: string;
  @Input() firstInit = false;

  constructor(
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

    if (this.control.parent) {
      this.control.parent.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
  }

  ngOnDestroy(): void {}

  editActionItem(control: AbstractControl, title?: string) {
    this.customizeBarService
      .customizeActionItem({
        options: {
          actionItem: control.value,
          actionLabel: this.title,
          ...this.options
        },
        context: this.customizeBarContext,
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        label: this.label,
        object: this.object,
        append: true,
        firstInit: this.firstInit
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ActionItem;
          control.patchValue(instance);
          control.markAsDirty();
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          control.patchValue(undefined);
          control.markAsDirty();
        }
      });
  }

  removeActionItem(control: AbstractControl) {
    control.patchValue(undefined);
    control.markAsDirty();
  }
}

<app-popup2 [size]="userInfoStep ? 'sm' : 'm'">
  <form *ngIf="!userInfoStep" [formGroup]="projectForm" (ngSubmit)="createProject()">
    <app-popup2-close (click)="close()"></app-popup2-close>

    <div class="auth-form__header" style="margin-top: 60px;">
      <ng-container *ngIf="applyTemplateItem">
        <div class="auth-form__title">
          Get Started with
          <div class="auth-form__accent">
            {{ applyTemplateItem.name }}
            <ng-container *ngIf="applyTemplateItem.resource"> for {{ applyTemplateItem.resource.label }} </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!applyTemplateItem">
        <div class="auth-form__title">
          Create a new app <span class="emoji-wave"></span>
          <!--            Welcome to-->
          <!--            <span class="auth-form__accent">-->
          <!--              <ng-container *ngIf="domain?.name">{{ domain.name }}</ng-container>-->
          <!--              <ng-container *ngIf="!domain?.name">Jet Admin <span class="emoji-wave"></span></ng-container>-->
          <!--            </span>-->
        </div>
        <!--          <div *ngIf="!domain" class="auth-form__text auth-form__text_bigger" style="margin-top: 18px;">-->
        <!--            You're almost there.<br />-->
        <!--            Create a project to start building your apps-->
        <!--          </div>-->
      </ng-container>
    </div>

    <div *ngIf="(projectForm | appFormErrors)?.length" class="auth-form__error error-notification">
      <div class="error-notification__background"></div>
      <div class="error-notification__content">
        <div class="error-notification__title">Unable to Create App</div>
        <div *ngFor="let error of projectForm | appFormErrors" class="error-notification__description">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="auth-form__section">
      <app-page-block>
        <app-custom-char-field
          class="auth-form__control"
          [form]="projectForm"
          [autofocus]="true"
          [field]="
            createField({
              name: 'name',
              field: 'CharField',
              placeholder: 'Name your app'
            })
          "
        >
        </app-custom-char-field>
      </app-page-block>

      <!--      <app-page-block *ngIf="appConfigService.deploymentTypeEnabled">-->
      <!--        <div class="auth-form__control switch-control">-->
      <!--          <div class="switch-control__label">Deployment</div>-->
      <!--          <div class="switch-control__container">-->
      <!--            <label-->
      <!--              class="switch-control__item switch-control__item_center"-->
      <!--              [ngClass]="[-->
      <!--                projectForm.controls.deployment.value == projectDeployments.Cloud ? 'switch-control__item_active' : ''-->
      <!--              ]"-->
      <!--            >-->
      <!--              <span class="switch-control__icon icon-cloud"></span>-->
      <!--              <span class="switch-control__text">-->
      <!--                <span class="switch-control__title">Cloud</span>-->
      <!--                <span class="switch-control__tag">Recommended</span>-->
      <!--                <span class="switch-control__description">-->
      <!--                  Quick start, can integrate with external resources, best for evaluation-->
      <!--                </span>-->
      <!--              </span>-->
      <!--              <input-->
      <!--                class="switch-control__input"-->
      <!--                type="radio"-->
      <!--                formControlName="deployment"-->
      <!--                [value]="projectDeployments.Cloud"-->
      <!--              />-->
      <!--            </label>-->

      <!--            <label-->
      <!--              class="switch-control__item switch-control__item_center"-->
      <!--              [ngClass]="[-->
      <!--                projectForm.controls.deployment.value == projectDeployments.OnPremise-->
      <!--                  ? 'switch-control__item_active'-->
      <!--                  : ''-->
      <!--              ]"-->
      <!--            >-->
      <!--              <span class="switch-control__icon icon-server"></span>-->
      <!--              <span class="switch-control__text">-->
      <!--                <span class="switch-control__title">On-premise</span>-->
      <!--                <span class="switch-control__description">-->
      <!--                  Deployment requires technical skills, can be used to meet security requirements-->
      <!--                </span>-->
      <!--              </span>-->
      <!--              <input-->
      <!--                class="switch-control__input"-->
      <!--                type="radio"-->
      <!--                formControlName="deployment"-->
      <!--                [value]="projectDeployments.OnPremise"-->
      <!--              />-->
      <!--            </label>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </app-page-block>-->

      <app-page-block *ngIf="projectForm.controls.deployment.value == projectDeployments.Cloud">
        <div
          class="auth-form__control"
          [class.auth-form__control_disabled]="!(appBaseUrl | appIsSet) && !generatedNameLoading"
        >
          <div class="button-selector2">
            <div class="button-selector2__label">
              App will have the following URL
              <span class="button-selector2__label-additional">(you can change it later)</span>
            </div>

            <div class="custom-char">
              <div
                class="custom-char__control custom-char__control_icon custom-char__control_compact custom-char__control_disabled"
              >
                <ng-container *ngIf="appBaseUrl | appIsSet">
                  {{ appBaseUrl }}
                </ng-container>

                <span
                  *ngIf="!(appBaseUrl | appIsSet)"
                  class="custom-char__control_placeholder"
                  [class.loading-animation]="generatedNameLoading"
                >
                  <span class="stub-text">
                    https://app.jetadmin.io
                  </span>
                </span>
              </div>

              <span class="custom-char__input-icon custom-char__input-icon_compact">
                <span class="icon-earth_planet"></span>
              </span>
            </div>
          </div>
        </div>
      </app-page-block>

      <app-page-block>
        <div class="auth-form__control">
          <div class="button-selector2">
            <div class="button-selector2__label">What are you looking to build?</div>
            <div *ngFor="let item of projectForm.productTypeOptions" class="button-selector2__item">
              <div
                class="button-selector2-item"
                [class.button-selector2-item_active]="projectForm.controls.product_type.value == item.value"
                (click)="projectForm.controls.product_type.setValue(item.value)"
              >
                <div class="button-selector2-item__left">
                  <div class="button-selector2-item__icon" [ngClass]="['icon-' + item.icon]"></div>
                </div>
                <div class="button-selector2-item__main">
                  <div class="button-selector2-item__title">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>

          <app-custom-char-field
            *ngIf="projectForm.controls.product_type.value == productTypeValues.Other"
            [form]="projectForm"
            [autofocus]="true"
            [field]="
              createField({
                name: 'product_type_custom',
                field: 'CharField',
                placeholder: 'Please describe what tasks you want to solve',
                params: { multiline: true }
              })
            "
          >
          </app-custom-char-field>
        </div>
      </app-page-block>
    </div>

    <div class="auth-form__controls">
      <app-page-block>
        <button
          class="auth-form__submit button_disabled button button_primary button_xl button_fill"
          [class.button_disabled]="projectForm.invalid || generatedNameLoading || createLoading || createdProject"
          type="submit"
        >
          <app-loader-small *ngIf="createLoading" class="button__icon"></app-loader-small>
          <span *ngIf="!createLoading" class="button__label">Create App</span>
        </button>
      </app-page-block>
    </div>
  </form>

  <form *ngIf="userInfoStep" [formGroup]="userForm" (ngSubmit)="sendUserInfo()">
    <app-popup2-close (click)="skipUserInfo()"></app-popup2-close>

    <div class="auth-form__header" style="margin-top: 60px;">
      <div class="auth-form__title">Your app is ready <span class="emoji-tada"></span></div>
      <div class="auth-form__text auth-form__text_bigger" style="margin-top: 18px;">
        Tell us how are you planning to use Jet so we can personalize your experience.
      </div>
    </div>

    <div class="auth-form__section">
      <app-page-block>
        <div class="auth-form__control switch-control">
          <div class="switch-control__label">I’m using Jet Admin for…</div>
          <div class="switch-control__container">
            <label
              class="switch-control__item switch-control__item_center"
              [ngClass]="[
                userForm.controls.employment.value == employments.Company ? 'switch-control__item_active' : ''
              ]"
            >
              <span class="switch-control__icon icon-teams"></span>
              <span class="switch-control__text">My company</span>
              <input
                class="switch-control__input"
                type="radio"
                formControlName="employment"
                [value]="employments.Company"
              />
            </label>
            <label
              class="switch-control__item switch-control__item_center"
              [ngClass]="[
                userForm.controls.employment.value == employments.Personal ? 'switch-control__item_active' : ''
              ]"
            >
              <span class="switch-control__icon icon-user"></span>
              <span class="switch-control__text">Personal Use</span>
              <input
                class="switch-control__input"
                type="radio"
                formControlName="employment"
                [value]="employments.Personal"
              />
            </label>
          </div>
        </div>
      </app-page-block>

      <app-page-block *ngIf="userForm.controls.employment.value == employments.Company">
        <app-custom-char-field
          class="auth-form__control"
          [form]="userForm"
          [field]="
            createField({
              name: 'company_website',
              field: 'CharField',
              placeholder: 'Company website',
              params: { icon: 'user' }
            })
          "
        >
        </app-custom-char-field>
      </app-page-block>

      <app-page-block>
        <app-custom-char-field
          class="auth-form__control"
          [form]="userForm"
          [field]="
            createField({
              name: 'phone',
              field: 'CharField',
              placeholder: 'Phone number',
              params: { icon: 'phone' }
            })
          "
        >
        </app-custom-char-field>
      </app-page-block>

      <!--              <app-custom-select-field-->
      <!--                class="auth-form__control"-->
      <!--                [form]="userForm"-->
      <!--                [label]="false"-->
      <!--                [field]="-->
      <!--                  createField({-->
      <!--                    name: 'user_level',-->
      <!--                    placeholder: 'Which best describes you?',-->
      <!--                    params: {-->
      <!--                      options: userForm.levelOptions,-->
      <!--                      classes: ['select_fill']-->
      <!--                    }-->
      <!--                  })-->
      <!--                "-->
      <!--              >-->
      <!--              </app-custom-select-field>-->
    </div>

    <div class="auth-form__controls">
      <app-page-block>
        <div class="auth-form__controls-item">
          <button
            class="auth-form__submit button button_transparent button_xl button_fill"
            type="button"
            [class.button_disabled]="userInfoLoading"
            (click)="skipUserInfo()"
          >
            <span class="button__label">Skip this</span>
          </button>
        </div>
        <div class="auth-form__controls-item">
          <button
            class="auth-form__submit button_disabled button button_primary button_xl button_fill"
            [class.button_disabled]="userForm.invalid || userInfoLoading"
            type="submit"
          >
            <app-loader-small *ngIf="userInfoLoading" class="button__icon"></app-loader-small>
            <span *ngIf="!userInfoLoading" class="button__label">Start building app</span>
          </button>
        </div>
      </app-page-block>
    </div>
  </form>
</app-popup2>

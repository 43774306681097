import { ChangeDetectorRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import clone from 'lodash/clone';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import {
  CalendarSettings,
  getModelAttributesByColumns,
  getModelBulkAttributesByColumns,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { DisplayField } from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import {
  CHECKED_ITEMS_OUTPUT,
  ColumnsModelListStore,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  listItemEquals,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { Model, ModelDescription } from '@modules/models';

import { CalendarState } from '../calendar/calendar-state';

export abstract class CalendarBaseComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() date: moment.Moment;
  @Input() params: Object;
  @Input() context: ViewContext;
  @Input() listState: CalendarState;
  @Input() modelDescription: ModelDescription;
  @Input() settings: CalendarSettings;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Output() queryParamsChanged = new EventEmitter<Object>();

  loading = false;
  items: { [k: string]: ListItem[] } = {};
  prevDate: moment.Moment;
  selectedItem: ListItem;
  checkedItems: { [k: string]: Model } = {};
  visibleColumns: DisplayField[] = [];
  fetchSubscription: Subscription;

  constructor(
    public injector: Injector,
    public actionControllerService: ActionControllerService,
    public listStore: ColumnsModelListStore,
    private notificationService: NotificationService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.listStore.items$.pipe(untilDestroyed(this)).subscribe(items => {
      this.items = items
        ? groupBy(items, item =>
            moment(item.model.getAttribute(this.listState.settings.dateField)).format(this.itemsGroupBy())
          )
        : {};
      this.cd.markForCheck();
    });

    this.fetch(this.listState);
    this.onStateUpdated(this.listState, {});
  }

  ngOnDestroy() {}

  updateVisibleColumns(state: CalendarState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  updateStoreColumns(state: CalendarState) {
    if (this.listStore.dataSource) {
      this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
      this.listStore.deserializeModelAttributes();
    }
  }

  onStateUpdated(state: CalendarState, prevState: CalendarState) {}

  abstract fetch(state: CalendarState);

  groupItems(items) {
    this.items = items
      ? groupBy(items, item => moment(item.getAttribute(this.listState.settings.dateField)).format(this.itemsGroupBy()))
      : {};
  }

  resetItems() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    this.items = {};
    this.loading = false;
    this.cd.markForCheck();

    this.listStore.reset();
  }

  getItems() {
    this.resetItems();

    // if (!this.selectedItem) {
    //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
    // }

    this.loading = true;
    this.cd.markForCheck();

    this.fetchSubscription = this.listStore.getAll().subscribe(
      result => {
        this.loading = false;
        this.cd.markForCheck();

        this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });

        // if (!this.selectedItem) {
        //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
        // }
      },
      () => {
        this.loading = false;
        this.cd.markForCheck();

        this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });

        // if (!this.selectedItem) {
        //   this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
        // }
      }
    );
  }

  applyParams(params: Object = {}) {
    //   return {
    //     ...applyParamInputs(this.params, this.settings.inputs, {
    //       context: this.contextElement.context,
    //       contextElement: this.contextElement,
    //       parameters: this.settings.parameters
    //     }),
    //     ...params
    //   };
    return params;
  }

  setParams(params: Object) {
    // this.queryParamsChanged.emit(this.applyParams(params));
    this.queryParamsChanged.emit({
      ...this.params,
      ...params
    });
  }

  updateSelectedContext() {
    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    if (this.selectedItem) {
      this.contextElement.setOutputValue(
        SELECTED_ITEM_OUTPUT,
        getModelAttributesByColumns(this.selectedItem.model, columns)
      );
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }

    if (this.settings.multipleSelection) {
      const models: Model[] = values(this.checkedItems);
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
    } else {
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
    }
  }

  isItemSelected(item: ListItem, index: number) {
    if (this.settings.multipleSelection) {
      return this.isItemChecked(item, index);
    } else {
      return this.itemEquals(this.selectedItem, item);
    }
  }

  isItemChecked(item: ListItem, index: number) {
    const pk = item.model.primaryKey || `${index}`;
    return this.checkedItems[pk];
  }

  setSelectedItem(item: ListItem, updateContext = true) {
    this.selectedItem = item;
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  setChecked(value: ListItem[], updateContext = true) {
    this.checkedItems = value.reduce((acc, item) => {
      const pk = item.model.primaryKey;
      acc[pk] = item.model;
      return acc;
    }, {});
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  toggleSelectedItem(item: ListItem, index: number, element: HTMLElement, click = false) {
    if (this.selectedItem === item) {
      this.setSelectedItem(undefined, false);
    } else {
      this.setSelectedItem(item, false);
    }

    if (this.settings.multipleSelection) {
      const pk = item.model.primaryKey || index;
      const checked = this.isItemChecked(item, index);

      if (!checked) {
        const checkedItems = clone(this.checkedItems);
        checkedItems[pk] = item.model;
        this.checkedItems = checkedItems;
      } else {
        const checkedItems = clone(this.checkedItems);
        delete checkedItems[pk];
        this.checkedItems = checkedItems;
      }
    }

    this.updateSelectedContext();

    if (click && this.settings.cardClickAction && this.selectedItem) {
      this.actionControllerService
        .execute(this.settings.cardClickAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.selectedItem.model.getAttributes()
          },
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }

  onModelUpdated(model: Model) {
    if (this.selectedItem && this.selectedItem.model.isSame(model)) {
      this.updateSelectedContext();
    }

    const checkedModels: Model[] = values(this.checkedItems);

    if (checkedModels.some(item => item.isSame(model))) {
      this.updateSelectedContext();
    }
  }

  itemEquals(lhs: ListItem, rhs: ListItem) {
    return listItemEquals(lhs, rhs);
  }

  abstract dateFilterFilters(state: CalendarState): FilterItem2[];

  abstract itemsGroupBy(): string;

  public getAnyModel(): Model {
    if (!this.listStore.items || !this.listStore.items.length) {
      return;
    }

    return this.listStore.items[0].model;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/help-icon/help-icon.component.ngfactory";
import * as i2 from "../../../../ui/components/help-icon/help-icon.component";
import * as i3 from "@angular/common";
import * as i4 from "./sidebar-section.component";
var styles_SidebarSectionComponent = [];
var RenderType_SidebarSectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarSectionComponent, data: {} });
export { RenderType_SidebarSectionComponent as RenderType_SidebarSectionComponent };
function View_SidebarSectionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-help-icon", [["class", "sidebar-section__title-action"]], null, null, null, i1.View_HelpIconComponent_0, i1.RenderType_HelpIconComponent)), i0.ɵdid(1, 114688, null, 0, i2.HelpIconComponent, [], { documentation: [0, "documentation"], left: [1, "left"], source: [2, "source"], label: [3, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentation; var currVal_1 = _co.documentationLeft; var currVal_2 = _co.documentationSource; var currVal_3 = _co.documentationLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SidebarSectionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSectionComponent_3)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.documentation; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarSectionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar-section__description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarSectionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "sidebar-section__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "sidebar-section__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSectionComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSectionComponent_4)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "sidebar-section__header-right"]], null, null, null, null, null)), i0.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SidebarSectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-section"]], [[2, "sidebar-section_border", null], [2, "sidebar-section_disabled", null], [2, "sidebar-section_interactive", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSectionComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.title || _co.description); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.border; var currVal_1 = _co.disabled; var currVal_2 = _co.interactive; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SidebarSectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-section", [], null, null, null, View_SidebarSectionComponent_0, RenderType_SidebarSectionComponent)), i0.ɵdid(1, 114688, null, 0, i4.SidebarSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarSectionComponentNgFactory = i0.ɵccf("app-sidebar-section", i4.SidebarSectionComponent, View_SidebarSectionComponent_Host_0, { title: "title", documentation: "documentation", documentationLabel: "documentationLabel", documentationLeft: "documentationLeft", documentationSource: "documentationSource", description: "description", border: "border", interactive: "interactive", disabled: "disabled" }, {}, ["ng-container[header-left]", "ng-container[header]", "ng-container[header-right]", "*"]);
export { SidebarSectionComponentNgFactory as SidebarSectionComponentNgFactory };

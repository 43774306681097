import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldTypeSection, FieldTypeSectionItem } from '@modules/field-components';
import { FieldDescriptionCategory, fieldDescriptions, fieldTypesOrder } from '@modules/fields';
import { isSet, objectsListSort, TypedChanges } from '@shared';

export interface FieldTypeSectionItem {
  value: string;
  name: string;
  icon?: string;
  description?: string;
}

export interface FieldTypeSection {
  label?: string;
  icon?: string;
  children: FieldTypeSectionItem[];
}

export const fieldTypeCategoryLabels = {
  [FieldDescriptionCategory.Text]: 'Text',
  [FieldDescriptionCategory.Numeric]: 'Numeric',
  [FieldDescriptionCategory.Choices]: 'Choices',
  [FieldDescriptionCategory.DateTime]: 'Date & Time',
  [FieldDescriptionCategory.Relationships]: 'Relationships',
  [FieldDescriptionCategory.Files]: 'Files',
  [FieldDescriptionCategory.Others]: 'Others'
};

@Component({
  selector: 'app-field-type-menu',
  templateUrl: './field-type-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldTypeMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input('sections') overrideSections: FieldTypeSection[];
  @Input() currentValue: string;
  @Output() select = new EventEmitter<string>();

  searchControl = new FormControl('');
  defaultSections: FieldTypeSection[] = toPairs<FieldTypeSectionItem[]>(
    fieldDescriptions
      .filter(item => item.public)
      .map(item => {
        return {
          value: item.name,
          name: item.label,
          icon: item.icon,
          description: item.description,
          group: item.category
        };
      })
      .sort(objectsListSort(fieldTypesOrder, item => item.value))
      .reduce((acc, item) => {
        if (!acc[item.group]) {
          acc[item.group] = [];
        }
        acc[item.group].push(item);
        return acc;
      }, {})
  )
    .sort(
      objectsListSort(
        [
          FieldDescriptionCategory.Text,
          FieldDescriptionCategory.Numeric,
          FieldDescriptionCategory.Choices,
          FieldDescriptionCategory.DateTime,
          FieldDescriptionCategory.Relationships,
          FieldDescriptionCategory.Files,
          FieldDescriptionCategory.Others
        ],
        ([group, items]) => group
      )
    )
    .map(([group, items]) => {
      const category = group as FieldDescriptionCategory;
      return {
        label: fieldTypeCategoryLabels[category],
        children: items
      };
    });
  sections: FieldTypeSection[] = this.defaultSections;
  sectionsFiltered: FieldTypeSection[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateFilteredSections();

    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredSections());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FieldTypeMenuComponent>): void {
    if (changes.overrideSections) {
      this.sections = this.overrideSections || this.defaultSections;
    }
  }

  getFilteredSections(): FieldTypeSection[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.sections;
    }

    return this.sections
      .map(section => {
        return {
          ...section,
          children: section.children.filter(sectionItem => {
            return [sectionItem.name, sectionItem.description, section.label].some(item => {
              return isSet(item) && item.toLowerCase().includes(search);
            });
          })
        };
      })
      .filter(item => item.children.length);
  }

  updateFilteredSections() {
    this.sectionsFiltered = this.getFilteredSections();
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredSections();
  }
}

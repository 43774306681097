import { ElementType } from './element-type';

export interface ElementComponentAction {
  name: string;
  label: string;
}

export interface ElementComponent {
  type: ElementType;
  component: any;
  label: string;
  alwaysActive?: boolean;
  actions: ElementComponentAction[];
  toolbar?: boolean;
}

const elementComponents: ElementComponent[] = [];

export function getElementComponentByType(type: ElementType): ElementComponent {
  return elementComponents.find(item => item.type == type);
}

export function registerElementComponent(component: ElementComponent) {
  const instance = getElementComponentByType(component.type);

  if (instance) {
    return;
  }

  elementComponents.push(component);
}

import { MatSelect } from '@angular/material/select';
import { take } from 'rxjs/operators';

MatSelect.prototype.open = function () {
  // if (this.disabled || !this.options || !this.options.length || this._panelOpen) {
  if (this.disabled || this._panelOpen) {
    return;
  }

  this._triggerRect = this.trigger.nativeElement.getBoundingClientRect();
  // Note: The computed font-size will be a string pixel value (e.g. "16px").
  // `parseInt` ignores the trailing 'px' and converts this to a number.
  this._triggerFontSize = parseInt(getComputedStyle(this.trigger.nativeElement).fontSize || '0', 10);

  this._panelOpen = true;
  this._keyManager.withHorizontalOrientation(null);
  this._calculateOverlayPosition();
  this._highlightCorrectOption();
  this._changeDetectorRef.markForCheck();

  // Set the font size on the panel element once it exists.
  this._ngZone.onStable
    .asObservable()
    .pipe(take(1))
    .subscribe(() => {
      if (this._triggerFontSize && this.overlayDir.overlayRef && this.overlayDir.overlayRef.overlayElement) {
        this.overlayDir.overlayRef.overlayElement.style.fontSize = `${this._triggerFontSize}px`;
      }
    });
};

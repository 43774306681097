/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dashboard-components/components/widgets/auto-widget/auto-widget.component.ngfactory";
import * as i2 from "../../../dashboard-components/components/widgets/auto-widget/auto-widget.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../customize/data/view-context-element";
import * as i5 from "../../../customize/data/view-context";
import * as i6 from "./auto-widget-element.component";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../custom-page-popup/custom-page-popup.component";
var styles_AutoWidgetElementComponent = [];
var RenderType_AutoWidgetElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoWidgetElementComponent, data: {} });
export { RenderType_AutoWidgetElementComponent as RenderType_AutoWidgetElementComponent };
function View_AutoWidgetElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "widget-element widget-element_manual-margin"]], [[2, "widget-element_flexible-height", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-auto-widget", [], null, null, null, i1.View_AutoWidgetComponent_0, i1.RenderType_AutoWidgetComponent)), i0.ɵdid(2, 704512, [[1, 4]], 0, i2.AutoWidgetComponent, [], { widget: [0, "widget"], context: [1, "context"], contextElement: [2, "contextElement"], fill: [3, "fill"], accentColor: [4, "accentColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.element.widget; var currVal_2 = _co.context; var currVal_3 = _co.viewContextElement; var currVal_4 = true; var currVal_5 = _co.accentColor; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_AutoWidgetElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { autoWidgetComponent: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AutoWidgetElementComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeEnabled$))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AutoWidgetElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-auto-widget-element", [], null, null, null, View_AutoWidgetElementComponent_0, RenderType_AutoWidgetElementComponent)), i0.ɵprd(131584, null, i4.ViewContextElement, i4.ViewContextElement, [i5.ViewContext]), i0.ɵdid(2, 770048, null, 0, i6.AutoWidgetElementComponent, [i7.CustomizeService, i4.ViewContextElement, [2, i8.CustomPagePopupComponent], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AutoWidgetElementComponentNgFactory = i0.ɵccf("app-auto-widget-element", i6.AutoWidgetElementComponent, View_AutoWidgetElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { AutoWidgetElementComponentNgFactory as AutoWidgetElementComponentNgFactory };

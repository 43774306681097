var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CustomActionSelectSource } from '@modules/action-queries';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var CustomActionFieldComponent = /** @class */ (function (_super) {
    __extends(CustomActionFieldComponent, _super);
    function CustomActionFieldComponent(source, cd) {
        var _this = _super.call(this) || this;
        _this.source = source;
        _this.cd = cd;
        return _this;
    }
    CustomActionFieldComponent.prototype.ngOnInit = function () {
        this.initValueStr();
    };
    CustomActionFieldComponent.prototype.ngOnDestroy = function () { };
    CustomActionFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['value']) {
            this.initValueStr();
        }
    };
    CustomActionFieldComponent.prototype.initValueStr = function () {
        var _this = this;
        if (!this.readonly) {
            return;
        }
        if (this.currentValue) {
            this.valueStr = undefined;
            this.source
                .fetchByValue(this.currentValue)
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.valueStr = result.name;
                _this.cd.markForCheck();
            });
        }
        else {
            this.valueStr = '---';
            this.cd.markForCheck();
        }
    };
    return CustomActionFieldComponent;
}(FieldComponent));
export { CustomActionFieldComponent };
registerFieldComponent(FieldType.CustomAction, CustomActionFieldComponent);

import { FieldLookup } from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';
import { booleanLookupMatchers } from './boolean';
import { dateTimeLookupMatchers } from './date-time';
import { jsonLookupMatchers } from './json';
import { multipleSelectLookupMatchers } from './multiple-select';
import { numberLookupMatchers } from './number';
import { passwordLookupMatchers } from './password';
import { radioButtonLookupMatchers } from './radio-button';
import { ratingLookupMatchers } from './rating';
import { relatedModelLookupMatchers } from './related-model';
import { richTextLookupMatchers } from './rich-text';
import { selectLookupMatchers } from './select';
import { sliderLookupMatchers } from './slider';
import { textLookupMatchers } from './text';
import { urlLookupMatchers } from './url';

export interface LookupMatcher {
  field: FieldType;
  lookup: FieldLookup;
  match: (fieldValue: any, lookupValue: any, field?: BaseField) => boolean;
}

export const lookupMatchers: LookupMatcher[] = [];

lookupMatchers.push(...booleanLookupMatchers);
lookupMatchers.push(...selectLookupMatchers);
lookupMatchers.push(...multipleSelectLookupMatchers);
lookupMatchers.push(...radioButtonLookupMatchers);
lookupMatchers.push(...relatedModelLookupMatchers);
lookupMatchers.push(...dateTimeLookupMatchers);
lookupMatchers.push(...textLookupMatchers);
lookupMatchers.push(...richTextLookupMatchers);
lookupMatchers.push(...passwordLookupMatchers);
lookupMatchers.push(...numberLookupMatchers);
lookupMatchers.push(...ratingLookupMatchers);
lookupMatchers.push(...sliderLookupMatchers);
lookupMatchers.push(...urlLookupMatchers);
lookupMatchers.push(...jsonLookupMatchers);

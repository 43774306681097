var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CustomizeService, CustomizeType, ElementType, registerElementComponent, TitleElementItem } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { KeyboardEventKeyCode } from '@shared';
var TitleElementComponent = /** @class */ (function (_super) {
    __extends(TitleElementComponent, _super);
    function TitleElementComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.editing = false;
        return _this;
    }
    TitleElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(function () {
            _this.setEditing(false);
        });
    };
    TitleElementComponent.prototype.ngOnDestroy = function () { };
    TitleElementComponent.prototype.ngAfterViewInit = function () { };
    Object.defineProperty(TitleElementComponent.prototype, "isEditable", {
        get: function () {
            return this.customizeService.enabled == CustomizeType.Layout;
        },
        enumerable: true,
        configurable: true
    });
    TitleElementComponent.prototype.setEditing = function (editing) {
        this.editing = editing;
        if (editing) {
            this.editingValue = this.element.title;
        }
        this.cd.markForCheck();
    };
    TitleElementComponent.prototype.onClick = function () {
        if (!this.isEditable) {
            return;
        }
        this.setEditing(true);
    };
    TitleElementComponent.prototype.onKeyUp = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            this.saveEditing();
        }
    };
    TitleElementComponent.prototype.saveEditing = function () {
        this.setEditing(false);
        this.element.title = this.editingValue;
    };
    TitleElementComponent.prototype.cancelEditing = function () {
        this.setEditing(false);
        this.editingValue = this.element.title;
        this.cd.markForCheck();
    };
    return TitleElementComponent;
}(BaseElementComponent));
export { TitleElementComponent };
registerElementComponent({
    type: ElementType.Title,
    component: TitleElementComponent,
    label: 'Title',
    actions: []
});

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { MenuContext } from '@modules/menu-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isColorHex, isSet } from '@shared';
import { MenuBlockControl } from '../project-settings/menu-block.control';
var MenuBlockEditComponent = /** @class */ (function () {
    function MenuBlockEditComponent(currentUserStore, context, dialogService, cd) {
        this.currentUserStore = currentUserStore;
        this.context = context;
        this.dialogService = dialogService;
        this.cd = cd;
        this.title = 'Menu';
        this.layoutGroups = [];
        this.active = false;
        this.disabled = false;
        this.edit = new EventEmitter();
        this.remove = new EventEmitter();
        this.enabledInputToggled = false;
        this.totalItems = 0;
        this.colorSet = false;
        this.colors = ['blue', 'teal', 'red', 'maroon', 'black', 'purple'];
        this.colorOpened = false;
        this.colorPositions = [
            { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 },
            { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 },
            { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 },
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
            { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
        ];
        this.itemsPluralMap = { '=1': '# item', '=0': 'no items', other: '# items' };
        this.layoutOpened = false;
    }
    MenuBlockEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(controlValue(this.control.controls.layout), this.control.getColor$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var layout = _a[0], color = _a[1];
            _this.layoutSelected = _this.layoutGroups
                .reduce(function (acc, item) {
                acc.push.apply(acc, item.options);
                return acc;
            }, [])
                .find(function (item) { return item.layout == layout && (item.color === undefined || isSet(item.color) == isSet(color)); });
            _this.cd.markForCheck();
        });
        this.control
            .getTotalItems$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.totalItems = _this.control.getTotalItems();
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.control.controls.layout), controlValue(this.control.controls.color_custom_enabled), controlValue(this.control.controls.color_custom), controlValue(this.control.controls.color))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.colorSet = _this.isColorSet();
            _this.displayColor = _this.getDisplayColor();
            _this.displayColorClass = _this.getDisplayColorClass();
            _this.cd.markForCheck();
        });
    };
    MenuBlockEditComponent.prototype.ngOnDestroy = function () { };
    MenuBlockEditComponent.prototype.selectLayoutOption = function (option) {
        var newMenu = !this.control.controls.layout.value;
        this.control.setLayout(option.layout);
        if (option.color !== undefined) {
            if (!isSet(this.control.getColor()) && isSet(option.color)) {
                if (isColorHex(option.color)) {
                    this.control.controls.color_custom_enabled.patchValue(true);
                    this.control.controls.color_custom.patchValue(option.color);
                }
                else {
                    this.control.controls.color.patchValue(option.color);
                    this.control.controls.color_custom_enabled.patchValue(false);
                }
            }
            else if (isSet(this.control.getColor()) && !isSet(option.color)) {
                this.control.controls.color.patchValue('');
                this.control.controls.color_custom_enabled.patchValue(false);
            }
        }
        if (newMenu) {
            this.control.controls.enabled.patchValue(true);
            this.control.applyDefaultState();
        }
    };
    MenuBlockEditComponent.prototype.isColorSet = function () {
        if (this.control.controls.color_custom_enabled.value) {
            return isSet(this.control.controls.color_custom.value);
        }
        else {
            return isSet(this.control.controls.color.value);
        }
    };
    MenuBlockEditComponent.prototype.getDisplayColor = function () {
        if (!this.isColorSet()) {
            return;
        }
        if (this.control.controls.color_custom_enabled.value) {
            return this.control.controls.color_custom.value;
        }
        return null;
    };
    MenuBlockEditComponent.prototype.getDisplayColorClass = function () {
        if (!this.control.controls.color_custom_enabled.value) {
            return "background-color_" + this.control.controls.color.value + "_1";
        }
        return null;
    };
    MenuBlockEditComponent.prototype.setColor = function (value) {
        if (isColorHex(value)) {
            this.control.controls.color_custom_enabled.patchValue(true);
            this.control.controls.color_custom.patchValue(value);
        }
        else {
            this.control.controls.color_custom_enabled.patchValue(false);
            this.control.controls.color.patchValue(value);
        }
    };
    MenuBlockEditComponent.prototype.setColorOpened = function (value) {
        this.colorOpened = value;
        this.cd.markForCheck();
    };
    MenuBlockEditComponent.prototype.setLayoutOpened = function (value) {
        this.layoutOpened = value;
        this.cd.markForCheck();
    };
    MenuBlockEditComponent.prototype.enable = function () {
        if (this.control.controls.layout.value) {
            this.control.controls.enabled.patchValue(true);
        }
        else {
            this.setLayoutOpened(true);
        }
    };
    MenuBlockEditComponent.prototype.disable = function () {
        this.control.controls.enabled.patchValue(false);
    };
    MenuBlockEditComponent.prototype.toggleEnabledInput = function () {
        if (this.control.controls.enabled_input_enabled.value) {
            this.control.controls.enabled_input_enabled.patchValue(false);
            this.enabledInputToggled = false;
        }
        else {
            this.control.controls.enabled_input_enabled.patchValue(true);
            this.enabledInputToggled = true;
        }
    };
    MenuBlockEditComponent.prototype.toggleEnabled = function () {
        if (this.control.controls.enabled.value) {
            this.disable();
        }
        else {
            this.enable();
        }
    };
    MenuBlockEditComponent.prototype.onClick = function (event) {
        if (this.control.controls.enabled.value) {
            return;
        }
        this.enable();
        event.stopPropagation();
    };
    MenuBlockEditComponent.prototype.confirmRemove = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete " + this.title + " menu?"
        })
            .pipe(filter(function (result) { return result == true; }), untilDestroyed(this))
            .subscribe(function () { return _this.remove.emit(); });
    };
    return MenuBlockEditComponent;
}());
export { MenuBlockEditComponent };

import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MenuGeneratorService, MenuItemType, MenuSettings, ModelLinkMenuItem, SectionMenuItem } from '@modules/menu';
import { ModelDescriptionService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { Environment, Project, Resource, ResourceTypeItem } from '@modules/projects';
import { ResourceParamsResult } from '@modules/resources';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface DjangoParamsOptions {
  url: string;
  token?: string;
}

@Injectable()
export class DjangoGeneratorService extends ResourceGeneratorService<DjangoParamsOptions> {
  constructor(
    private modelDescriptionService: ModelDescriptionService,
    private menuGeneratorService: MenuGeneratorService,
    private injector: Injector
  ) {
    super();
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<DjangoParamsOptions> {
    return of({
      url: resource.params['url']
    });
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: DjangoParamsOptions
  ): Observable<ResourceParamsResult> {
    const result = {
      resourceToken: options.token,
      resourceParams: {
        url: options.url
      }
    };

    const instance = new Resource();

    instance.type = typeItem.resourceType;
    instance.typeItem = typeItem;
    instance.token = result.resourceToken;
    instance.params = result.resourceParams || {};

    return this.modelDescriptionService.getFromResource(instance).pipe(
      catchError(() => of([])),
      map(modelDescriptions => {
        if (!modelDescriptions || !modelDescriptions.length) {
          return result;
        }

        return {
          ...result
        };
      })
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { AppConfigService } from '@core';

import { IconType } from '../../data/icon-type';
import { getIconData } from '../../utils/icons';

@Pipe({
  name: 'appIconType'
})
export class IconTypePipe implements PipeTransform {
  constructor(private appConfigService: AppConfigService) {}

  transform(icon: string): IconType {
    const data = getIconData(icon, {
      mediaBaseUrl: this.appConfigService.getMediaBaseUrl()
    });
    return data ? data.type : undefined;
  }
}

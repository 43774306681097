import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { NotificationService } from '@common/notifications';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettings } from '@modules/customize';
import { Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';

import {
  ChooseTemplateComponent,
  ChooseTemplateSection,
  CLOSE_BY_BUTTON_CLICK
} from '../../components/choose-template/choose-template.component';
import { TemplateItem } from '../../data/template-item';
import { TemplateItemType } from '../../data/template-item-type';
import { TemplateApplyController } from '../template-apply-controller/template-apply.controller';

export interface ChooseTemplateOptions {
  currentPage?: ViewSettings;
  resource?: Resource;
  cancel?: boolean;
  chooseSection?: boolean;
  title?: string;
  initialSection?: ChooseTemplateSection;
  header?: boolean;
  wide?: boolean;
  newPage?: boolean;
  type?: TemplateItemType;
  analyticsSource?: string;
  analyticsInnerSource?: string;
}

export interface ChooseTemplateResult {
  template?: TemplateItem;
  resource?: Resource;
  blankApp?: boolean;
}

@Injectable()
export class ChooseTemplateService {
  constructor(
    private notificationService: NotificationService,
    private popupService: PopupService,
    private routing: RoutingService,
    private templateApplyController: TemplateApplyController,
    private analyticsService: UniversalAnalyticsService
  ) {}

  chooseTemplate(injector: Injector, options: ChooseTemplateOptions = {}): Observable<ChooseTemplateResult> {
    const obs = new ReplaySubject<ChooseTemplateResult>();

    this.popupService.push({
      component: ChooseTemplateComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentDisableClose: !options.cancel,
      popupComponentOrange: true,
      popupComponentCloseWithoutConfirm: true,
      popupClosed: data => {
        if (data == CLOSE_BY_BUTTON_CLICK || data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateDismissed);
        }
      },
      inputs: {
        ...(options.title && { title: options.title }),
        ...(isSet(options.chooseSection) && { chooseSection: options.chooseSection }),
        initialSection: options.initialSection,
        ...(options.header !== undefined && { header: options.header }),
        wide: options.wide,
        orange: true,
        currentPage: options.currentPage,
        resource: options.resource,
        cancel: options.cancel,
        newPage: options.newPage,
        type: options.type,
        analyticsSource: options.analyticsInnerSource
      },
      outputs: {
        blankAppCreated: [
          () => {
            obs.next({
              blankApp: true
            });
          }
        ],
        templateApplied: [
          template => {
            obs.next({
              template: template
            });
          }
        ]
      },
      injector: injector
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateOpened, {
      Source: options.analyticsSource
    });

    return obs.pipe(
      tap(result => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseTemplateCompleted, {
          BlankApp: !!result.blankApp,
          TemplateID: result.template ? result.template.name : undefined,
          ResourceID: result.resource ? result.resource.typeItem.name : undefined
        });
      })
    );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';

import { BasePopupComponent } from '@common/popups';

import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { ZapierResourceSettingsForm } from './zapier-resource-settings.form';

@Component({
  selector: 'app-zapier-resource-settings',
  templateUrl: './zapier-resource-settings.component.html',
  providers: [ZapierResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZapierResourceSettingsComponent extends BaseResourceSettingsComponent {
  constructor(
    public form: ZapierResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }
}

registerResourceSettingsComponent(ResourceName.Zapier, ZapierResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.Integromat, ZapierResourceSettingsComponent);

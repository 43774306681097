import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { ProjectUser, ProjectUserListStore } from '@modules/projects';
import { CurrentUserStore, User } from '@modules/users';
import { isSet, KeyboardEventKeyCode } from '@shared';

@Component({
  selector: 'app-customize-preview-user-dropdown',
  templateUrl: './customize-preview-user-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectUserListStore]
})
export class CustomizePreviewUserDropdownComponent implements OnInit, OnDestroy {
  @Output() selectUser = new EventEmitter<ProjectUser>();
  @Output() resetUser = new EventEmitter<void>();

  originalUser: User;
  loading = true;
  searchControl = new FormControl('');
  update = new Subject<string>();

  constructor(
    public currentUserStore: CurrentUserStore,
    public projectUserListStore: ProjectUserListStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUserStore.original$.pipe(untilDestroyed(this)).subscribe(originalUser => {
      this.originalUser = originalUser;
      this.cd.markForCheck();
    });

    merge(of(this.searchControl.value), this.searchControl.valueChanges.pipe(debounceTime(200)), this.update)
      .pipe(
        switchMap(search => {
          this.projectUserListStore.params = {
            not_user_uid: this.currentUserStore.original.uid,
            ...(isSet(search) && { search: search })
          };
          this.projectUserListStore.reset();

          this.loading = true;
          this.cd.markForCheck();

          return this.projectUserListStore.getNext();
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  resetSearch() {
    const value = '';
    this.searchControl.patchValue(value, { emitEvent: false });
    this.update.next(value);
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.resetSearch();
    }
  }
}

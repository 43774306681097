<div
  class="view-editor-viewport"
  [class.view-editor-viewport_dark]="dark"
  [class.view-editor-viewport_navigate]="navigate && !navigating"
  [class.view-editor-viewport_navigating]="navigating"
  [class.view-editor-viewport_hud]="!(pickingColor$ | async)"
  [class.view-editor-viewport_selection]="
    !(editorContext.isCreateTool$ | async) &&
    !(editorContext.movingLayer$ | async) &&
    !(editorContext.resizingLayer$ | async) &&
    !(editorContext.customizingGradient$ | async) &&
    !(editorContext.resizingView$ | async) &&
    !(editorContext.navigateMode$ | async)
  "
  (mousedown)="onMouseDown($event)"
  (click)="onClick($event)"
  #viewport_element
>
  <div class="view-editor-viewport__content">
    <div class="view-editor-viewport__scalable" #canvas_scale_element>
      <div class="view-editor-viewport__scrollable view-editor-canvas" #canvas_element>
        <app-view-editor-view
          [view]="view"
          [viewContext]="viewContext"
          [componentLabel]="componentLabel"
          [stateSelectedEnabled]="stateSelectedEnabled"
          [templatesEnabled]="templatesEnabled"
          [dark]="dark"
          (importFigmaNode)="importFigmaNode.emit()"
          (importSketchFile)="importSketchFile.emit()"
        ></app-view-editor-view>
        <div
          *ngIf="!(pickingColor$ | async)"
          class="view-editor-canvas__grid"
          [class.view-editor-canvas__grid_active]="(editorContext.viewportScale$ | async) >= 4"
        ></div>
      </div>
    </div>
  </div>

  <div class="view-editor-viewport__overlay">
    <div class="view-editor-viewport__overlay-canvas view-editor-canvas" #overlay_canvas_element>
      <div class="view-editor-view" [style.width.em]="view.frame.width / 10" [style.height.em]="view.frame.height / 10">
        <div
          *ngFor="let item of editorContext.horizontalGuides$ | async"
          class="view-editor-viewport__guide view-editor-viewport__guide_horizontal"
          [class.view-editor-viewport__guide_dashed]="item.dashed"
          [ngClass]="'view-editor-viewport__guide_color_' + item.color"
          [style.top.em]="item.y / 10"
          [style.left.em]="item.x / 10"
          [style.width.em]="item.length / 10"
        >
          <div *ngIf="item.label | appIsSet" class="view-editor-viewport__guide-label">{{ item.label }}</div>
          <div
            *ngIf="item.startSymbol == guideSymbols.Line"
            class="view-editor-viewport__guide-line view-editor-viewport__guide-line_start"
          ></div>
          <div
            *ngIf="item.endSymbol == guideSymbols.Line"
            class="view-editor-viewport__guide-line view-editor-viewport__guide-line_end"
          ></div>
        </div>

        <div
          *ngFor="let item of editorContext.verticalGuides$ | async"
          class="view-editor-viewport__guide view-editor-viewport__guide_vertical"
          [class.view-editor-viewport__guide_dashed]="item.dashed"
          [ngClass]="'view-editor-viewport__guide_color_' + item.color"
          [style.top.em]="item.y / 10"
          [style.left.em]="item.x / 10"
          [style.height.em]="item.length / 10"
        >
          <div *ngIf="item.label | appIsSet" class="view-editor-viewport__guide-label">{{ item.label }}</div>
          <div
            *ngIf="item.startSymbol == guideSymbols.Line"
            class="view-editor-viewport__guide-line view-editor-viewport__guide-line_start"
          ></div>
          <div
            *ngIf="item.endSymbol == guideSymbols.Line"
            class="view-editor-viewport__guide-line view-editor-viewport__guide-line_end"
          ></div>
        </div>

        <div
          *ngIf="customizeGradient"
          class="view-editor-viewport__overlay-item view-editor-viewport__overlay-interactive"
          [style.left.em]="customizeGradient.frame.x / 10"
          [style.top.em]="customizeGradient.frame.y / 10"
          [style.width.em]="customizeGradient.frame.width / 10"
          [style.height.em]="customizeGradient.frame.height / 10"
          #bounds_element
        >
          <app-gradient-position
            [control]="customizeGradient.control"
            [viewContext]="viewContext"
            [activeStop]="customizeGradient.activeStop"
            [frame]="customizeGradient.frame.frame"
            [translate]="customizeGradient.frame.translate"
            [boundsElement]="bounds_element"
          ></app-gradient-position>
        </div>

        <app-view-editor-viewport-overlays></app-view-editor-viewport-overlays>

        <div
          *ngIf="multipleLayersFrame && !(editorContext.movingLayer$ | async)"
          class="view-editor-viewport__overlay-item"
          [style.left.em]="multipleLayersFrame.x / 10"
          [style.top.em]="multipleLayersFrame.y / 10"
          [style.width.em]="multipleLayersFrame.width / 10"
          [style.height.em]="multipleLayersFrame.height / 10"
        >
          <app-view-editor-bounds
            class="rectangle-layer__bounds"
            [class.rectangle-layer__bounds_hover]="false"
            [class.rectangle-layer__bounds_active]="true"
            [frame]="multipleLayersFrame"
            [snapFrame]="true"
            [snapFrameExceptLayers]="editorContext.customizingLayers$.value"
            [constrainProportions]="false"
            [active]="true"
            [resizeHorizontal]="true"
            [resizeVertical]="true"
            (resizeStarted)="onMultipleLayersResizeStarted()"
            (resizeFinished)="onMultipleLayersResizeFinished()"
            (updateFrame)="onMultipleLayersResize($event)"
          ></app-view-editor-bounds>
        </div>
      </div>
    </div>
  </div>
</div>

export enum QueryName {
  Get = 'get',
  GetDetail = 'get_detail',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Aggregate = 'aggregate',
  Group = 'group',
  Reload = 'reload'
}

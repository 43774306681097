import { CookieService } from 'ngx-cookie';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie/src/cookie.service";
var YandexMetrikaService = /** @class */ (function () {
    function YandexMetrikaService(cookieService) {
        this.cookieService = cookieService;
    }
    Object.defineProperty(YandexMetrikaService.prototype, "clientId", {
        get: function () {
            return this.cookieService.get('_ym_uid');
        },
        enumerable: true,
        configurable: true
    });
    YandexMetrikaService.ngInjectableDef = i0.defineInjectable({ factory: function YandexMetrikaService_Factory() { return new YandexMetrikaService(i0.inject(i1.CookieService)); }, token: YandexMetrikaService, providedIn: "root" });
    return YandexMetrikaService;
}());
export { YandexMetrikaService };

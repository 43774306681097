/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./project-reset-interface.form";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../projects/services/project/project.service";
import * as i5 from "./project-reset-interface.component";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "../../../dashboard/stores/dashboard.store";
import * as i8 from "../../../model-queries/stores/model-description.store";
import * as i9 from "../../../customize/stores/view-settings.store";
import * as i10 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i11 from "../../../../common/dialogs/services/dialog/dialog.service";
var styles_ProjectResetInterfaceComponent = [];
var RenderType_ProjectResetInterfaceComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectResetInterfaceComponent, data: {} });
export { RenderType_ProjectResetInterfaceComponent as RenderType_ProjectResetInterfaceComponent };
export function View_ProjectResetInterfaceComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "form", [["novalidate", ""], ["style", "width: 100%; max-width: 720px;"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reset Interface Settings"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Select interface settings you want to reset"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning!"])), (_l()(), i0.ɵted(-1, null, [" The selected interface settings will be deleted in app project and can't be restored "])), (_l()(), i0.ɵeld(13, 0, null, null, 6, "div", [["class", "change__submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "button", [["class", "button button_primary button_danger"], ["style", "margin-right: 10px;"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "a", [["class", "button"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form.form; _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.form.form.invalid || _co.form.form.disabled); _ck(_v, 14, 0, currVal_8); }); }
export function View_ProjectResetInterfaceComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-project-reset-interface", [], null, null, null, View_ProjectResetInterfaceComponent_0, RenderType_ProjectResetInterfaceComponent)), i0.ɵprd(512, null, i2.ProjectResetInterfaceForm, i2.ProjectResetInterfaceForm, [i1.FormBuilder, i3.CurrentProjectStore, i4.ProjectService]), i0.ɵdid(2, 245760, null, 0, i5.ProjectResetInterfaceComponent, [i2.ProjectResetInterfaceForm, i3.CurrentProjectStore, i6.NotificationService, i7.DashboardStore, i8.ModelDescriptionStore, i9.ViewSettingsStore, i10.BasePopupComponent, i11.DialogService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ProjectResetInterfaceComponentNgFactory = i0.ɵccf("app-project-reset-interface", i5.ProjectResetInterfaceComponent, View_ProjectResetInterfaceComponent_Host_0, {}, {}, []);
export { ProjectResetInterfaceComponentNgFactory as ProjectResetInterfaceComponentNgFactory };

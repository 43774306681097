var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { combineLatest, throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var ModelEditPopupForm = /** @class */ (function (_super) {
    __extends(ModelEditPopupForm, _super);
    function ModelEditPopupForm(formUtils, currentProjectStore, currentEnvironmentStore, modelDescriptionService, modelDescriptionStore) {
        var _this = _super.call(this, {
            verbose_name_plural: new FormControl(''),
            description: new FormControl(''),
            display_field: new FormControl(''),
            default_order_by: new FormControl('')
        }) || this;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionService = modelDescriptionService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.fieldOptions = [];
        _this.dbFieldOptions = [];
        _this.defaultOrderByOptions = [];
        return _this;
    }
    ModelEditPopupForm.prototype.setInstance = function (modelDescription) {
        this.modelDescription = modelDescription;
        this.patchValue({
            verbose_name_plural: modelDescription.verboseNamePlural,
            description: modelDescription.description,
            display_field: modelDescription.displayField,
            default_order_by: modelDescription.defaultOrderBy
        });
        this.fieldOptions = [
            {
                name: '---',
                value: undefined
            }
        ].concat(this.modelDescription.fields.map(function (item) {
            return {
                name: item.verboseName,
                value: item.name
            };
        }));
        this.dbFieldOptions = [
            {
                name: '---',
                value: undefined
            }
        ].concat(this.modelDescription.dbFields.map(function (item) {
            return {
                name: item.verboseName,
                value: item.name
            };
        }));
        this.defaultOrderByOptions = flatten(this.modelDescription.dbFields.map(function (item) { return [
            {
                name: item.verboseName + " (ascending)",
                value: item.name
            },
            {
                name: item.verboseName + " (descending)",
                value: "-" + item.name
            }
        ]; }));
    };
    ModelEditPopupForm.prototype.getInstance = function () {
        var value = this.value;
        var instance = cloneDeep(this.modelDescription);
        instance.verboseNamePlural = value.verbose_name_plural;
        instance.description = value.description;
        instance.displayField = value.display_field;
        instance.defaultOrderBy = value.default_order_by;
        return instance;
    };
    ModelEditPopupForm.prototype.submit = function () {
        var _this = this;
        var instance = this.getInstance();
        return this.modelDescriptionService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(delayWhen(function () {
            return combineLatest(_this.modelDescriptionStore.getFirst(true));
        }), tap(function (result) {
            _this.modelDescription = result;
        }), catchError(function (error) {
            _this.markAsDirty();
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ModelEditPopupForm;
}(FormGroup));
export { ModelEditPopupForm };

<div class="sidebar__element">
  <app-sidebar-field>
    <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
      <div
        class="sidebar-icon-button"
        [class.sidebar-icon-button_active]="!form.controls.multiple.value"
        (click)="form.controls.multiple.setValue(false)"
      >
        <div class="sidebar-icon-button__label">single file</div>
      </div>
      <div
        class="sidebar-icon-button"
        [class.sidebar-icon-button_active]="form.controls.multiple.value"
        (click)="form.controls.multiple.setValue(true)"
      >
        <div class="sidebar-icon-button__label">multiple files</div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div *ngIf="form.controls.multiple.value" class="sidebar__element">
  <app-sidebar-field [label]="'Data format'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'store_format',
          field: 'SelectField',
          params: { options: form.outputFormatOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

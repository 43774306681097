var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { openaiResourceParamsActionDescriptions } from '../../data/openai/openai-resource-params-action-descriptions.stub';
import { openaiResourceParamsParams } from '../../data/openai/openai-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var OpenAIGeneratorService = /** @class */ (function (_super) {
    __extends(OpenAIGeneratorService, _super);
    function OpenAIGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        return _this;
    }
    OpenAIGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = 'https://api.openai.com/v1/models';
        query.headers = [{ name: 'Authorization', value: "Bearer " + options.api_key }];
        return this.httpQueryService.request(query).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
                error = new ServerRequestError('API key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    OpenAIGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            return {
                api_key: result.value
            };
        }));
    };
    OpenAIGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = openaiResourceParamsParams;
        var actionDescriptions = openaiResourceParamsActionDescriptions;
        var token = new SecretToken();
        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.api_key;
        return of({
            resourceParams: resourceParams,
            actionDescriptions: actionDescriptions,
            secretTokens: [token.serialize()]
        });
    };
    return OpenAIGeneratorService;
}(ResourceGeneratorService));
export { OpenAIGeneratorService };

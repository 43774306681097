import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import values from 'lodash/values';
import { AceEditorDirective } from 'ng2-ace-editor';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { format } from 'sql-formatter';

import { DialogService } from '@common/dialogs';
import { DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { AiDatabaseEngine, AiService, TableDescription } from '@modules/ai';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import {
  CodeFieldComponent,
  fieldsEditItemFromParameterField,
  fieldsEditItemToParameterField
} from '@modules/field-components';
import { createFormFieldFactory, ParameterArray } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Resource, ResourceName } from '@modules/projects';
import { QueryService, SQL_QUERY_VERSION, SqlQuery } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { Benchmark, controlValue, filterObject, isSet, limitObjectLength } from '@shared';

// Refactor imports
import { SqlFieldComponent } from '@modules/field-components/components/sql-field/sql-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';

import { QueryBuilderContext } from '../../data/query-builder-context';
import { InputTokensEvent, InputTokensEventType } from '../input-tokens/input-tokens.component';
import { QueryBuilderCustomComponent } from '../query-builder/query-builder.component';
import { TextToSqlOverlayComponent } from '../text-to-sql-overlay/text-to-sql-overlay.component';
import { QueryBuilderSqlForm } from './query-builder-sql.form';

export enum SqlResultsSection {
  Parameters,
  Result
}

export interface QueryBuilderSqlOptions {
  initialResultsSection?: SqlResultsSection;
}

export enum SQLFormatterDialect {
  SQL = 'sql',
  PostgresSQL = 'postgresql',
  MySQL = 'mysql',
  MicrosoftSQL = 'tsql',
  Oracle = 'plsql'
}

@Component({
  selector: 'app-query-builder-sql',
  templateUrl: './query-builder-sql.component.html',
  providers: [QueryBuilderSqlForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderSqlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() resource: Resource;
  @Input() context: QueryBuilderContext;
  @Input() control: AbstractControl;
  @Input() requireResponse = false;
  @Input() arrayResponse = false;
  @Input() options: QueryBuilderSqlOptions;
  @Input() customSections: QueryBuilderCustomComponent[] = [];
  @Input() parametersControl: ParameterArray;
  @Input() source: string;
  @Output() executed = new EventEmitter<SqlQuery>();
  @Output() saved = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<boolean>();

  @ViewChild(AutoFieldComponent) queryFieldComponent: AutoFieldComponent;
  @ViewChild(TextToSqlOverlayComponent) textToSqlOverlayComponent: TextToSqlOverlayComponent;

  createField = createFormFieldFactory();
  loading = false;

  requestCreate = false;
  response: any;
  responseActual = false;
  error: string;
  incorrectLike = false;
  incorrectLikeClosed = false;
  upgradeQuery = false;
  upgradeQueryClosed = false;
  sqlPlaceholder = `SELECT\n  *\nFROM\n  users\nWHERE\n  city = {{params.city}} AND name ILIKE {{'%'+params.name+'%'}}`;
  textToSqlLoading = false;

  resultsSection = SqlResultsSection.Result;
  resultsSections = SqlResultsSection;
  customSectionComponentsTop: DynamicComponentArguments[] = [];
  customSectionComponentsBottom: DynamicComponentArguments[] = [];
  customSectionComponentsPreview: DynamicComponentArguments[] = [];
  fieldsEditItemToParameterField = fieldsEditItemToParameterField;
  fieldsEditItemFromParameterField = fieldsEditItemFromParameterField;

  saveHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageHovered = new BehaviorSubject<boolean>(false);
  requireResponseMessageVisible$ = combineLatest(
    this.saveHovered.pipe(
      filter(value => (value && this.isResponseMissing()) || !value),
      debounce(value => timer(value ? 0 : 200))
    ),
    this.requireResponseMessageHovered
  ).pipe(
    map(([saveHovered, requireResponseMessageHovered]) => {
      return saveHovered || requireResponseMessageHovered;
    })
  );

  constructor(
    public form: QueryBuilderSqlForm,
    private queryService: QueryService,
    private resourceControllerService: ResourceControllerService,
    private aiService: AiService,
    private modelDescriptionStore: ModelDescriptionStore,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const query = this.control.value as SqlQuery;

    this.form.init(query);
    this.context.sqlForm = this.form;
    this.context.paginationTokens = false;
    this.context.sortingTokens = false;

    if (query) {
      this.requestCreate = false;
      this.context.tokenValues = query.requestTokens;

      if (query.requestResponse !== undefined) {
        this.response = query.requestResponse;
        this.responseActual = true;
        this.cd.markForCheck();
      }
    } else {
      this.requestCreate = true;
      this.context.tokenValues = {};
    }

    if (isSet(this.currentOptions.initialResultsSection)) {
      this.resultsSection = this.currentOptions.initialResultsSection;
    } else {
      this.resultsSection = this.response ? SqlResultsSection.Result : SqlResultsSection.Result;
    }

    this.cd.markForCheck();

    merge(
      ...values<AbstractControl>(this.form.controls)
        .filter(control => {
          return [
            this.form.controls.request_response,
            this.form.controls.request_response_columns,
            this.form.controls.request_tokens
          ].every(item => item !== control);
        })
        .map(control => control.valueChanges)
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(() => {
        this.responseActual = false;
        this.cd.markForCheck();
      });

    this.updateCustomSections();

    if (SQL_QUERY_VERSION >= 2) {
      if (!query || query.version != 1) {
        controlValue(this.form.controls.query)
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.incorrectLike = !!value.match(/['"`]%{{[\w.]+}}%['"`]/g);
            this.cd.markForCheck();
          });
      }

      const controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;

      if (controller && controller.checkApiInfo) {
        controller
          .checkApiInfo(this.resource)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            if (
              query &&
              query.version == 1 &&
              this.resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' })
            ) {
              this.upgradeQuery = true;
              this.cd.markForCheck();
            }
          });
      }
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.StartToSetUp, {
      ResourceType: this.resource.typeItem.name,
      Source: this.source
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  get currentOptions(): QueryBuilderSqlOptions {
    return {
      ...this.options
    };
  }

  setResultsSection(resultsSection) {
    this.resultsSection = resultsSection;
    this.cd.markForCheck();
  }

  updateCustomSections() {
    this.customSectionComponentsTop = this.customSections
      .filter(item => item.position == 'top')
      .map(item => item.component);
    this.customSectionComponentsBottom = this.customSections
      .filter(item => item.position == 'bottom')
      .map(item => item.component);
    this.customSectionComponentsPreview = this.customSections
      .filter(item => item.position == 'preview')
      .map(item => item.component);
    this.cd.markForCheck();
  }

  execute() {
    const query = this.form.getInstance();
    const requestTokens = this.context.serializeTokenValues();
    const saveTokens = this.context.serializeTokenValues(true);

    this.loading = true;
    this.response = undefined;
    this.error = undefined;
    this.resultsSection = SqlResultsSection.Result;
    this.cd.markForCheck();

    const controller = this.resource ? this.resourceControllerService.getForResource(this.resource, true) : undefined;

    controller
      .sql(this.resource, query.query, { tokens: requestTokens, version: query.version })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.response = result ? result.toObject() : undefined;
          this.loading = false;
          this.context.lastExecutedResponse = this.response;
          this.form.controls.request_response.patchValue(limitObjectLength(this.response, 20));
          this.form.controls.request_response_columns.patchValue(result.columnDescriptions);
          this.form.controls.request_tokens.patchValue(filterObject(saveTokens, item => !(item instanceof Blob)));
          this.responseActual = true;
          this.executed.emit(query);
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.SuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            if (error.fieldErrors['error']) {
              this.error = String(error.fieldErrors['error']).trim();
            } else if (error.nonFieldErrors.length) {
              this.error = String(error.nonFieldErrors[0]).trim();
            } else {
              this.error = 'Unknown error';
            }

            this.notificationService.error('Request failed', this.error);
          } else {
            this.notificationService.error('Request failed', `Unknown error`);
          }

          this.responseActual = true;
          this.loading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.UnsuccessfullyPerformed, {
            ResourceType: this.resource.typeItem.name
          });
        }
      );
  }

  insertSqlByNaturalText(query: string) {
    this.textToSqlLoading = true;
    this.cd.markForCheck();

    const benchmark = new Benchmark(true);

    this.modelDescriptionStore
      .get()
      .pipe(
        switchMap(modelDescriptions => {
          const tables: TableDescription[] = modelDescriptions
            .filter(item => item.resource == this.resource.uniqueName)
            .filter(item => isSet(item.dbTable))
            .map(item => {
              return {
                name: item.dbTable,
                columns: item.dbFields
                  .filter(field => isSet(field.dbColumn))
                  .map(field => {
                    return {
                      name: field.dbColumn
                    };
                  })
              };
            })
            .filter(item => item.columns.length);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.StartedTranslation, {
            Type: 'TextToSQL',
            Query: query
          });

          let engine: AiDatabaseEngine;

          if (this.resource.isSynced() || this.resource.hasCollectionSync()) {
            engine = AiDatabaseEngine.PostgresSQL;
          } else if (
            [
              ResourceName.PostgreSQL,
              ResourceName.Redshift,
              ResourceName.AlloyDB,
              ResourceName.Supabase,
              ResourceName.JetDatabase
            ].includes(this.resource.typeItem.name)
          ) {
            engine = AiDatabaseEngine.PostgresSQL;
          } else if ([ResourceName.MySQL, ResourceName.MariaDB].includes(this.resource.typeItem.name)) {
            engine = AiDatabaseEngine.MySQL;
          } else if (this.resource.typeItem.name == ResourceName.MicrosoftSQL) {
            engine = AiDatabaseEngine.MicrosoftSQL;
          } else if (this.resource.typeItem.name == ResourceName.Oracle) {
            engine = AiDatabaseEngine.Oracle;
          } else if (this.resource.typeItem.name == ResourceName.BigQuery) {
            engine = AiDatabaseEngine.BigQuery;
          } else if (this.resource.typeItem.name == ResourceName.Snowflake) {
            engine = AiDatabaseEngine.Snowflake;
          } else if (this.resource.typeItem.name == ResourceName.SQLite) {
            engine = AiDatabaseEngine.SQLite;
          }

          benchmark.reset();

          return this.aiService.getSqlByNaturalText(query, tables, engine);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          if (isSet(result)) {
            this.textToSqlOverlayComponent.reset();
            this.setFormattedText(result);
          }

          this.textToSqlLoading = false;
          this.cd.markForCheck();

          this.notificationService.success('SQL inserted', 'Your text query has been translated to SQL');

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.Translated, {
            Type: 'TextToSQL',
            Query: query,
            Result: result,
            Time: benchmark.secondElapsed(1)
          });
        },
        error => {
          this.textToSqlLoading = false;
          this.cd.markForCheck();

          let errorMessage: string;

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            errorMessage = error.nonFieldErrors[0];
          } else {
            errorMessage = 'Unknown error';
          }

          this.notificationService.error('Translate Failed', errorMessage);

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.TranslationFailed, {
            Type: 'TextToSQL',
            Query: query,
            Error: errorMessage,
            Time: benchmark.secondElapsed(1)
          });
        }
      );
  }

  onTokenChanged() {}

  getAce(): AceEditorDirective {
    if (
      this.queryFieldComponent &&
      this.queryFieldComponent.dynamicComponent.currentComponent &&
      (this.queryFieldComponent.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent ||
        this.queryFieldComponent.dynamicComponent.currentComponent.instance instanceof SqlFieldComponent)
    ) {
      return this.queryFieldComponent.dynamicComponent.currentComponent.instance.ace;
    }
  }

  setText(text: string) {
    const ace = this.getAce();

    if (!ace) {
      return;
    }

    ace.editor.setValue(text, 1);
  }

  setFormattedText(value: string) {
    let dialect: SQLFormatterDialect;

    if (this.resource.isSynced() || this.resource.hasCollectionSync()) {
      dialect = SQLFormatterDialect.PostgresSQL;
    } else if (
      [
        ResourceName.PostgreSQL,
        ResourceName.Redshift,
        ResourceName.AlloyDB,
        ResourceName.Supabase,
        ResourceName.JetDatabase
      ].includes(this.resource.typeItem.name)
    ) {
      dialect = SQLFormatterDialect.PostgresSQL;
    } else if ([ResourceName.MySQL, ResourceName.MariaDB].includes(this.resource.typeItem.name)) {
      dialect = SQLFormatterDialect.MySQL;
    } else if (this.resource.typeItem.name == ResourceName.MicrosoftSQL) {
      dialect = SQLFormatterDialect.MicrosoftSQL;
    } else if (this.resource.typeItem.name == ResourceName.Oracle) {
      dialect = SQLFormatterDialect.Oracle;
    } else if (this.resource.typeItem.name == ResourceName.BigQuery) {
      dialect = SQLFormatterDialect.SQL;
    } else if (this.resource.typeItem.name == ResourceName.Snowflake) {
      dialect = SQLFormatterDialect.SQL;
    } else if (this.resource.typeItem.name == ResourceName.SQLite) {
      dialect = SQLFormatterDialect.SQL;
    } else {
      dialect = SQLFormatterDialect.SQL;
    }

    const placeholders = [];

    value = value.replace(/{{([^}]+)}}/g, (str, group) => {
      const index = placeholders.length;
      placeholders.push(group);
      return `__JET_VAR__(${index})`;
    });

    const result = format(value, {
      language: dialect
    }).replace(/__JET_VAR__\((\d+)\)/g, (str, group) => {
      const index = parseInt(group, 10);
      if (isNaN(index)) {
        return '';
      }

      return `{{${placeholders[index]}}}`;
    });

    this.setText(result);
  }

  insertText(text: string) {
    const ace = this.getAce();

    if (!ace) {
      return;
    }

    const selectionRange = ace.editor.selection.getRange();
    ace.editor.session.replace(selectionRange, text);
  }

  formatCurrentValue() {
    this.setFormattedText(this.form.controls.query.value || '');
  }

  cancel() {
    this.canceled.emit();
  }

  saveProcess() {
    const query = this.form.getInstance();
    this.control.patchValue(query);
    this.saved.emit();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.SuccessfullySetUp, {
      ResourceType: this.resource.typeItem.name
    });
  }

  isResponseMissing() {
    return this.requireResponse && !this.responseActual;
  }

  save() {
    if (this.isResponseMissing()) {
      return;
    }

    this.saveProcess();
  }

  submit() {
    this.save();
  }

  onInputTokensEvent(event: InputTokensEvent) {
    if (event.type == InputTokensEventType.AddParameter) {
      if (this.parametersControl) {
        this.setResultsSection(SqlResultsSection.Parameters);
      }
    }
  }

  closeIncorrectLike() {
    this.incorrectLikeClosed = true;
    this.cd.markForCheck();
  }

  closeUpgradeQuery() {
    this.upgradeQueryClosed = true;
    this.cd.markForCheck();
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { Observable, of } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { AppConfigService, LocalStorage, SessionStorage } from '@core';
import { ApiService } from '@modules/api';
import { getFilenameWithExtension, isSet } from '@shared';

import { Plan } from '../../data/plan';
import { Project } from '../../data/project';
import { PublicSettings } from '../../data/public-settings';

const NEW_PROJECT_NAME_KEY = 'new_project_name';

export interface ProjectGeneratedName {
  uniqueName: string;
  domainName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(
    private http: HttpClient,
    private storageService: LocalStorage,
    private sessionStorage: SessionStorage,
    private apiService: ApiService,
    private appConfigService: AppConfigService
  ) {}

  get(params = {}): Observable<ProjectService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/');
        let headers = new HttpHeaders();
        let httpParams = new HttpParams({ fromObject: params });

        httpParams = httpParams.set('v', this.appConfigService.version);
        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => new ProjectService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getAll(params = {}): Observable<Project[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/');
        let headers = new HttpHeaders();
        let httpParams = new HttpParams({ fromObject: params });

        httpParams = httpParams.set('all', '1');
        httpParams = httpParams.set('v', this.appConfigService.version);
        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Project().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(uniqueName: string, environmentUniqueName?: string, draft = false): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${uniqueName}/`);
        let headers = new HttpHeaders();
        const params = {
          ...(isSet(environmentUniqueName) && { env_name: environmentUniqueName }),
          ...(draft && { draft: '1' }),
          v: this.appConfigService.version
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: params });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDemo(): Observable<Project[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/demo/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => result.map(item => new Project().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  popNewProjectName(): string {
    const result = this.sessionStorage.get(NEW_PROJECT_NAME_KEY);
    if (!isSet(result)) {
      return;
    }

    this.sessionStorage.remove(NEW_PROJECT_NAME_KEY);
    return result;
  }

  create(project: Project, token: string, fields?: string[]): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/');
        let headers = new HttpHeaders();
        const params = {
          v: this.appConfigService.version
        };
        let data = project.serialize(fields);

        data['token'] = token;

        headers = this.apiService.setHeadersToken(headers);

        if (fields && fields.includes('logo') && project.logoFile) {
          const formData = new FormData();

          toPairs(data).forEach(([k, v]) => {
            if (v === undefined) {
              return;
            } else if (['params', 'project_settings', 'menu_settings'].includes(k) && isSet(v)) {
              v = JSON.stringify(v);
            }

            formData.append(k, v);
          });

          data = formData;
        }

        return this.http.post(url, data, { headers: headers, params: params });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createDemo(projectUniqueName: string, token: string): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/demo_create/');
        let headers = new HttpHeaders();
        const data = {
          demo_project_name: projectUniqueName,
          token: token
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createFromTemplate(project: Project, template: string): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('projects/');
        let headers = new HttpHeaders();
        const data = {
          ...project.serialize(['name']),
          template_project: template
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  generateUniqueName(name: string): Observable<ProjectGeneratedName> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/generate_unique_name/');
        let headers = new HttpHeaders();
        const data = {
          name: name
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ unique_name: string; domain_name: string }>(url, data, { headers: headers });
      }),
      map(result => {
        return {
          uniqueName: result.unique_name,
          domainName: result.domain_name
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  isUsedUniqueName(name: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/is_used_unique_name/');
        let headers = new HttpHeaders();
        const data = {
          unique_name: name
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ result: boolean }>(url, data, { headers: headers });
      }),
      map(result => result.result),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  isUsedDomainName(name: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('projects/is_used_domain_name/');
        let headers = new HttpHeaders();
        const data = {
          domain_name: name
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ result: boolean }>(url, data, { headers: headers });
      }),
      map(result => result.result),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(project: Project, options: { hardDelete?: boolean } = {}): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/`);
        const data = {
          ...(options.hardDelete && {
            hard_delete: true
          })
        };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.request('DELETE', url, { body: data, headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  update(uniqueName: string, project: Project, fields?: string[]): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${uniqueName}/`);
        let headers = new HttpHeaders();
        let data = project.serialize(fields);

        headers = this.apiService.setHeadersToken(headers);

        if (fields && fields.includes('logo') && project.logoFile) {
          const formData = new FormData();

          toPairs(data).forEach(([k, v]) => {
            if (v === undefined) {
              return;
            } else if (k == 'params' && isSet(v)) {
              v = JSON.stringify(v);
            }

            formData.append(k, v);
          });

          data = formData;
        }

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  updateLogo(project: Project, file: File): Observable<Project> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/`);
        let headers = new HttpHeaders();
        let data: any;

        if (file) {
          data = new FormData();
          data.append('logo', file);
        } else {
          data = {
            logo: null
          };
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new Project().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  createProjectToken(): Observable<string> {
    const url = this.apiService.methodURL('project_tokens/');

    return this.http.post(url, {}).pipe(
      map(result => result['token']),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  copySettings(project: Project, sourceProject: Project, interfaceSettings?: Object): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/copy_settings/`);
        let headers = new HttpHeaders();
        const data = {
          source_project: sourceProject.uniqueName
        };

        if (interfaceSettings) {
          data['interface_settings'] = interfaceSettings;
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  resetSettings(project: Project, interfaceSettings?: Object): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/reset_settings/`);
        let headers = new HttpHeaders();
        const data = {};

        if (interfaceSettings) {
          data['interface_settings'] = interfaceSettings;
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  getPlans(project: Project, params = {}): Observable<Plan[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(project.uniqueName, 'plans/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Plan().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  planOrder(
    project: Project,
    plan: Plan,
    options: {
      annual?: boolean;
      rewardful?: string;
    } = {}
  ): Observable<string> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(project.uniqueName, `plans/${plan.uniqueName}/order/`);
        let headers = new HttpHeaders();
        const data = {
          ...(isSet(options.annual) && { annual: options.annual }),
          ...(isSet(options.rewardful) && { rewardful: options.rewardful })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => result['url']),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  planSwitch(project: Project, plan: Plan, annual = false): Observable<{ result: boolean; description: string }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(project.uniqueName, `plans/${plan.uniqueName}/switch/`);
        let headers = new HttpHeaders();
        const data = {
          annual: annual
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getPublicSettings(projectName: string, environmentName?: string): Observable<PublicSettings> {
    const url = isSet(environmentName)
      ? this.apiService.environmentMethodURL(projectName, environmentName, 'public_settings/')
      : this.apiService.projectMethodURL(projectName, 'public_settings/');

    return this.http.get(url).pipe(
      map(result => new PublicSettings().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getProjectsLastUsed() {
    try {
      return JSON.parse(this.storageService.get('projects_last_used')) || {};
    } catch (e) {
      return {};
    }
  }

  updateProjectLastUsed(project: Project) {
    if (project.demo && project.parent) {
      return;
    }

    const lastUsed = this.getProjectsLastUsed();
    lastUsed[project.uniqueName] = new Date().toISOString();
    this.storageService.set('projects_last_used', JSON.stringify(lastUsed));
  }

  sortProjectsLastUsed(projects: Project[]) {
    const lastUsed = this.getProjectsLastUsed();

    return projects.sort((lhs, rhs) => {
      if (lastUsed[lhs.uniqueName] == undefined && lastUsed[rhs.uniqueName] == undefined) {
        return 0;
      } else if (lastUsed[lhs.uniqueName] == undefined) {
        return 1;
      } else if (lastUsed[rhs.uniqueName] == undefined) {
        return -1;
      } else {
        return new Date(lastUsed[rhs.uniqueName]).getTime() - new Date(lastUsed[lhs.uniqueName]).getTime();
      }
    });
  }

  getLogoFile(logo: string): Observable<File> {
    return this.http.get(logo, { responseType: 'blob' }).pipe(
      map(blob => {
        const filename = getFilenameWithExtension(logo);
        return new File([blob], filename);
      }),
      catchError(() => of(undefined))
    );
  }
}

export namespace ProjectService {
  export class GetResponse {
    public results: Project[];
    public next: string;
    public previous: string;
    public count: number;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new Project().deserialize(item));
      this.next = data['next'];
      this.previous = data['previous'];
      this.count = data['count'];

      return this;
    }
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { createFormFieldFactory } from '@modules/fields';
import { Field } from '@modules/projects';
var RegisterTemplateFieldComponent = /** @class */ (function () {
    function RegisterTemplateFieldComponent(cd) {
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.fieldParams = {};
    }
    RegisterTemplateFieldComponent.prototype.ngOnInit = function () { };
    RegisterTemplateFieldComponent.prototype.ngOnChanges = function (changes) {
        var _a;
        if (changes.form || changes.field) {
            if (!this.form) {
                this.form = this.field ? new FormGroup((_a = {}, _a[this.field.name] = new FormControl(), _a)) : undefined;
            }
        }
        if (changes.field) {
            this.updateFieldParams();
        }
    };
    RegisterTemplateFieldComponent.prototype.updateFieldParams = function () {
        var params = this.field.params;
        var classes = params && params['classes'] ? params['classes'] : [];
        this.fieldParams = __assign({}, params, { classes: ['input_fill', 'select_fill'].concat(classes) });
        this.cd.markForCheck();
    };
    return RegisterTemplateFieldComponent;
}());
export { RegisterTemplateFieldComponent };

<div class="workflow-editor">
  <app-popup2-close *ngIf="workflowEditable" [outside]="true" (click)="submit()"></app-popup2-close>
  <app-popup2-close *ngIf="!workflowEditable" [outside]="true" (click)="close()"></app-popup2-close>

  <div class="workflow-editor__header workflow-editor-header" [class.workflow-editor-header_blue]="!workflowEditable">
    <div class="workflow-editor-header__left"></div>
    <div class="workflow-editor-header__main">
      <div
        *ngIf="nameEditable"
        class="workflow-editor-header-input"
        [class.workflow-editor-header-input_active]="nameEditing"
        (click)="setNameEditing(true)"
      >
        <input
          *ngIf="nameEditing"
          class="workflow-editor-header-input__control"
          [(ngModel)]="name"
          [appAutofocus]="true"
          appKeyEvent
          (enterUp)="input.blur()"
          (blur)="cleanName(); setNameEditing(false)"
          #input
        />
        <div *ngIf="!nameEditing" class="workflow-editor-header-input__control">{{ name }}</div>
        <div *ngIf="!nameEditing" class="workflow-editor-header-input__icon icon-pen"></div>
      </div>

      <div *ngIf="!nameEditable" class="workflow-editor-header-title">
        {{ name }}
      </div>
    </div>
    <div class="workflow-editor-header__right">
      <div *ngIf="workflowEditable" class="workflow-editor-header__item">
        <ng-template #undo_tip> Undo <app-tip-hotkey [metaKey]="true" [key]="'Z'"></app-tip-hotkey> </ng-template>

        <button
          class="button button_icon button_grey button_segment-left"
          [class.button_disabled]="!(isUndoAvailable$ | async)"
          [appTip]="undo_tip"
          [appTipOptions]="{ side: 'bottom' }"
          (click)="workflowEditContext.undo()"
        >
          <span class="button__icon icon-undo"></span>
        </button>

        <ng-template #redo_tip>
          Redo <app-tip-hotkey [metaKey]="true" [shiftKey]="true" [key]="'Z'"></app-tip-hotkey>
        </ng-template>

        <button
          class="button button_icon button_grey button_segment-right"
          [class.button_disabled]="!(isRedoAvailable$ | async)"
          [appTip]="redo_tip"
          [appTipOptions]="{ side: 'bottom' }"
          (click)="workflowEditContext.redo()"
        >
          <span class="button__icon icon-redo"></span>
        </button>
      </div>

      <a
        *ngIf="historyEnabled"
        href="javascript:void(0)"
        class="button button_grey workflow-editor-header__item"
        [class.button_disabled]="triggerEditable && !trigger?.type"
        (click)="openRuns()"
      >
        <span class="button__icon button__icon_left icon-history"></span>
        <span class="button__label">Run history</span>
      </a>

      <a
        *ngIf="workflowEditable"
        href="javascript:void(0)"
        class="button button_grey workflow-editor-header__item"
        [class.button_disabled]="loadingExecute || !workflow.steps.length"
        (click)="executeWorkflow()"
      >
        <app-loader-small *ngIf="loadingExecute" class="button__icon button__icon_left"></app-loader-small>
        <span *ngIf="!loadingExecute" class="button__icon button__icon_left icon-play"></span>
        <span class="button__label">
          <ng-container *ngIf="automation">Test Automation</ng-container>
          <ng-container *ngIf="!automation">Test Workflow</ng-container>
        </span>
      </a>

      <a
        *ngIf="workflowEditable"
        href="javascript:void(0)"
        class="button button_primary button_orange workflow-editor-header__item"
        [class.button_disabled]="triggerEditable && !trigger?.type"
        (click)="submit({ automationActive: true })"
      >
        <span *ngIf="automation?.active === false" class="button__label">Publish</span>
        <span *ngIf="automation?.active !== false" class="button__label">Save</span>
      </a>
    </div>
  </div>

  <div class="workflow-editor__main">
    <div class="workflow-editor__canvas">
      <div class="workflow-canvas" (click)="closeCustomize($event)" #canvas_element>
        <div class="workflow-root">
          <app-workflow-trigger
            class="workflow-root__item"
            [automation]="automation"
            [triggerEditable]="triggerEditable"
            [trigger]="trigger"
            [triggerOutputs]="triggerOutputs"
            [triggerData]="triggerData"
            [workflow]="workflow"
            [workflowEditable]="workflowEditable"
            [parametersEnabled]="parametersEnabled"
            [parameters]="parameters"
            [label]="triggerLabel"
            [icon]="triggerIcon"
            [customizeInitial]="customizeTrigger"
            (triggerChanged)="onTriggerChange($event)"
            (click)="onWorkflowElementClick($event)"
          ></app-workflow-trigger>

          <div
            class="workflow-root__item workflow-container"
            appDropList
            appDropListGroup
            [appDropListData]="workflow.steps"
            [appDropListDisabled]="!workflowEditable"
            [appDropListSwapDistance]="20"
            [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: 20 }"
            (appDropListDropped)="dragDrop($event)"
          >
            <app-auto-workflow-step
              *ngFor="let item of workflow.steps; let i = index; let first = first; trackBy: trackStep"
              class="workflow-container__item"
              appDrag
              [appDragData]="item"
              [step]="item"
              [prevStep]="workflow.steps[i - 1]"
              [workflowEditable]="workflowEditable"
              [index]="i"
              [context]="context"
              [actionTypesEnabled]="actionTypesEnabled"
              [analyticsSource]="analyticsSource"
              (stepAddBefore)="addStep($event, { addBeforeIndex: i })"
              (stepDuplicate)="duplicateStep(i)"
              (stepDelete)="deleteStep(i)"
            >
              <app-workflow-step-card
                *appDragPreview
                [title]="item.name"
                [subtitle]="item.type"
                [active]="true"
                [connectorTop]="false"
                [connectorBottom]="false"
              ></app-workflow-step-card>
            </app-auto-workflow-step>
          </div>

          <div class="workflow-root__item workflow-connection" (click)="onWorkflowElementClick($event)">
            <div *ngIf="stepsHasNextConnection()" class="workflow-connection__bounds">
              <div class="workflow-connection__dash workflow-connection__dash_size_l">
                <ng-container *ngIf="workflowEditable && !(triggerEditable && !trigger)">
                  <app-add-step-menu
                    [actionTypesEnabled]="actionTypesEnabled"
                    (add)="addStep($event)"
                    #add_step_menu
                  ></app-add-step-menu>

                  <div
                    class="workflow-connection__button icon-plus"
                    [class.workflow-connection__button_position_center]="resultEnabled && workflow.steps.length"
                    [class.workflow-connection__button_position_bottom]="!resultEnabled || !workflow.steps.length"
                    [class.workflow-connection__button_active]="add_trigger.menuOpen"
                    [matMenuTriggerFor]="add_step_menu.menu"
                    [matMenuTriggerData]="{ trigger: add_trigger }"
                    #add_trigger="matMenuTrigger"
                  ></div>
                </ng-container>
              </div>
            </div>

            <div *ngIf="!stepsHasNextConnection()" class="workflow-connection__space"></div>
          </div>

          <div *ngIf="workflowEditable && triggerEditable && !trigger" class="workflow-root__item workflow-container">
            <app-workflow-step-card
              class="workflow-container__item"
              [title]="'Step'"
              [subtitle]="'Not specified'"
              [disabled]="true"
              [connectorBottom]="false"
            ></app-workflow-step-card>
          </div>

          <app-workflow-result
            *ngIf="resultEnabled && workflow.steps.length"
            class="workflow-root__item"
            [workflow]="workflow"
            [workflowEditable]="workflowEditable"
            [resultOutputs]="resultOutputs"
            [statusIcon]="statusIcon"
            [statusColor]="statusColor"
            [connectorTop]="stepsHasNextConnection()"
            [context]="context"
            [contextElement]="contextElement"
            (click)="onWorkflowElementClick($event)"
          ></app-workflow-result>
        </div>
      </div>
    </div>

    <div *ngIf="(customizeBarContext.settingsComponents$ | async)?.length" class="workflow-editor__sidebar">
      <app-workflow-sidebar></app-workflow-sidebar>
    </div>
  </div>
</div>

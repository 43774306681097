import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import toPairs from 'lodash/toPairs';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { getCurrentLanguage } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import {
  AnalyticsEvent,
  AnalyticsEventAction,
  GoogleAnalyticsService,
  UniversalAnalyticsService
} from '@modules/analytics';
import { ApiService, TokenOptions } from '@modules/api';
import { AuthService } from '@modules/auth';
import { Domain } from '@modules/domain';
import { MetaService } from '@modules/meta';
import {
  HomeTriggerOutput,
  Project,
  ProjectInvite,
  ProjectInviteService,
  ProjectPublicInvite,
  ProjectPublicInviteService,
  ProjectsStore
} from '@modules/projects';
import { ROUTE_LOADED_PROJECTS_STORE } from '@modules/projects-components';
import { RoutingService } from '@modules/routing';
import { JetBridgeSSOSettings, SSOSettings, SSOType } from '@modules/sso';
import { CurrentUserStore } from '@modules/users';
import { isAbsoluteUrl, isProductionLocation, isSet, openUrl } from '@shared';

import { LoginForm } from './login.form';

export abstract class LoginBaseComponent implements OnInit, OnDestroy {
  submitLoading = false;
  screenLoader = false;
  _domain = new BehaviorSubject<Domain>(undefined);
  currentSSO: SSOSettings;
  invite: ProjectInvite;
  inviteLoading = false;
  publicInvite: ProjectPublicInvite;
  publicInviteLoading = false;
  analyticsEvents = AnalyticsEvent;

  constructor(
    public loginForm: LoginForm,
    protected routing: RoutingService,
    protected currentUserStore: CurrentUserStore,
    protected activatedRoute: ActivatedRoute,
    protected analyticsService: UniversalAnalyticsService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    public appConfigService: AppConfigService,
    protected authService: AuthService,
    protected notificationService: NotificationService,
    protected metaService: MetaService,
    protected projectsStore: ProjectsStore,
    protected apiService: ApiService,
    protected projectInviteService: ProjectInviteService,
    protected projectPublicInviteService: ProjectPublicInviteService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activatedRoute.fragment.pipe(untilDestroyed(this)).subscribe(fragment => {
      const params: { key: string; value: string }[] = (fragment || '').split('&').map(item => {
        const parts = item.split('=', 2);
        return {
          key: parts[0],
          value: parts[1]
        };
      });
      const token = params.find(item => item.key == 'token');
      const accessToken = params.find(item => item.key == 'access_token');
      const accessTokenExpires = params.find(item => item.key == 'access_token_expires');
      const refreshToken = params.find(item => item.key == 'refresh_token');
      const refreshTokenExpires = params.find(item => item.key == 'refresh_token_expires');
      const serverTime = params.find(item => item.key == 'server_time');
      const sso = params.find(item => item.key == 'sso');

      const message = params.find(item => item.key == 'message');
      const created = params.find(item => item.key == 'created');
      const socialBackend = params.find(item => item.key == 'social_backend');

      if (token || message) {
        this.googleAnalyticsService.setLocationReferrer();
      }

      if (token) {
        const tokenOptions: TokenOptions = {
          token: token.value
        };

        if (accessToken) {
          tokenOptions.accessToken = accessToken.value;
        }

        if (accessTokenExpires) {
          tokenOptions.accessTokenExpires = moment(accessTokenExpires.value);
        }

        if (refreshToken) {
          tokenOptions.refreshToken = refreshToken.value;
        }

        if (refreshTokenExpires) {
          tokenOptions.refreshTokenExpires = moment(refreshTokenExpires.value);
        }

        if (serverTime) {
          tokenOptions.serverTime = moment(serverTime.value);
        }

        if (sso) {
          tokenOptions.sso = sso.value;
        }

        if (socialBackend) {
          tokenOptions.social = socialBackend.value;
        }

        this.tokenLogin(
          tokenOptions,
          created && created.value == 'True',
          socialBackend && socialBackend.value ? socialBackend.value : undefined
        );
      }

      if (message) {
        this.notificationService.error('Error', message.value);
      }
    });

    combineLatest(
      this.activatedRoute.data.pipe(map(data => data.domain as Domain)),
      this.activatedRoute.queryParams.pipe(map(params => params['sso']))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([domain, ssoUid]) => {
        this.domain = domain;
        this.currentSSO = domain && isSet(ssoUid) ? domain.ssoSettings.find(item => item.uid == ssoUid) : undefined;
        this.cd.markForCheck();
      });

    this.activatedRoute.queryParams
      .pipe(
        map(queryParams => {
          const redirectParams = queryParams['redirect']
            ? JSON.parse(decodeURIComponent(queryParams['redirect']))
            : undefined;

          if (
            !redirectParams ||
            !redirectParams['url'] ||
            !redirectParams['url'].join('/').startsWith('projects/invite')
          ) {
            return;
          }

          return redirectParams['url'][2];
        }),
        switchMap(code => {
          if (!code) {
            return of(undefined);
          }

          this.inviteLoading = true;
          this.cd.markForCheck();

          return this.projectInviteService.getByCode(code);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        invite => {
          this.invite = invite;
          this.inviteLoading = false;
          this.cd.markForCheck();
          this.onInviteLoaded();
        },
        () => {
          this.inviteLoading = false;
          this.cd.markForCheck();
        }
      );

    this.activatedRoute.queryParams
      .pipe(
        map(queryParams => {
          const redirectParams = queryParams['redirect']
            ? JSON.parse(decodeURIComponent(queryParams['redirect']))
            : undefined;

          if (
            !redirectParams ||
            !redirectParams['url'] ||
            !redirectParams['url'].join('/').startsWith('projects/join')
          ) {
            return;
          }

          return redirectParams['url'][2];
        }),
        switchMap(code => {
          if (!code) {
            return of(undefined);
          }

          this.publicInviteLoading = true;
          this.cd.markForCheck();

          return this.projectPublicInviteService.getByCode(code);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        invite => {
          this.publicInvite = invite;
          this.publicInviteLoading = false;
          this.cd.markForCheck();
          this.onInviteLoaded();
        },
        () => {
          this.publicInviteLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  get domain(): Domain {
    return this._domain.value;
  }

  get domain$(): Observable<Domain> {
    return this._domain.asObservable();
  }

  set domain(value: Domain) {
    this._domain.next(value);
  }

  socialLogin(social: string) {
    this.submitLoading = true;
    this.cd.markForCheck();

    openUrl(this.socialLoginUrl(social));
  }

  ssoLogin(sso: SSOSettings): { externalRedirect?: boolean } {
    if (sso.type == SSOType.Custom) {
      this.routing.navigate([], { queryParams: { ...(sso && { sso: sso.uid }) }, queryParamsHandling: 'merge' });
      return {};
    } else {
      this.submitLoading = true;
      this.cd.markForCheck();

      const url = this.ssoLoginOpenUrl(sso);

      if (isSet(url)) {
        openUrl(url);
        return { externalRedirect: true };
      }
    }

    return {};
  }

  ssoReset() {
    this.routing.navigate([], { queryParams: { sso: undefined }, queryParamsHandling: 'merge' });
  }

  tokenLogin(tokenOptions: TokenOptions, userCreated = false, socialBackend?: string) {
    this.submitLoading = true;
    this.screenLoader = true;
    this.cd.markForCheck();

    this.authService
      .tokenLogin(tokenOptions)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.onLogin({ userCreated: userCreated, socialBackend: socialBackend });
        },
        () => {
          this.submitLoading = false;
          this.screenLoader = false;
          this.cd.markForCheck();
        }
      );
  }

  get webBaseUrl() {
    return this.domain && isProductionLocation()
      ? `https://${this.domain.actualDomain}`
      : this.appConfigService.webBaseUrl;
  }

  serializeQueryParams(params: Object) {
    return toPairs(params)
      .map(item => `${item[0]}=${encodeURIComponent(item[1])}`)
      .join('&');
  }

  loginRedirectParams() {
    const currentQueryParams = this.serializeQueryParams(this.activatedRoute.snapshot.queryParams);
    const projectName = this.activatedRoute.snapshot.params['project'];
    const transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
    const projectToken = this.apiService.getProjectToken();
    let redirect = projectName
      ? [this.webBaseUrl, 'login', projectName].join('/')
      : [this.webBaseUrl, 'login'].join('/');

    if (currentQueryParams) {
      redirect = `${redirect}?${currentQueryParams}`;
    }

    const params = {
      redirect_uri: redirect,
      language: getCurrentLanguage()
    };

    if (projectName) {
      params['project'] = projectName;
    }

    if (projectToken) {
      params['project_token'] = projectToken;
    }

    if (transferProjects) {
      params['transfer_projects'] = transferProjects;
    }

    return params;
  }

  socialLoginUrl(social: string) {
    const params = this.loginRedirectParams();
    const queryParams = this.serializeQueryParams(params);
    return `${this.appConfigService.serverBaseUrl}/api/social_login/${social}/?${queryParams}`;
  }

  ssoLoginOpenUrl(sso: SSOSettings) {
    const params = this.loginRedirectParams();
    params['sso'] = sso.uid;

    if (sso.type == SSOType.JetBridge) {
      params['auth_uri'] = `${this.appConfigService.serverBaseUrl}/api/jet_bridge_login/`;
    }

    const queryParams = this.serializeQueryParams(params);

    if (sso.type == SSOType.SAML2) {
      return `${this.appConfigService.serverBaseUrl}/api/saml2_login/${sso.uid}/?${queryParams}`;
    } else if (sso.type == SSOType.OAuth2) {
      return `${this.appConfigService.serverBaseUrl}/api/social_login/${sso.publicParams['backend']}/?${queryParams}`;
    } else if (sso.type == SSOType.JetBridge && sso instanceof JetBridgeSSOSettings) {
      return `${sso.url}external_auth/login/${sso.uid}/?${queryParams}`;
    }
  }

  onInviteLoaded() {}

  onLogin(
    options: {
      userCreated?: boolean;
      socialBackend?: string;
      selfSignOn?: boolean;
    } = {}
  ) {
    this.projectsStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(projects => {
        const projectsCreated = projects ? projects.filter(item => item.isCreated).length : 0;
        const redirect = this.activatedRoute.snapshot.queryParams['redirect'];
        const redirectParams = redirect ? JSON.parse(decodeURIComponent(redirect)) : undefined;
        const invited = !!(
          redirectParams &&
          redirectParams['url'] &&
          redirectParams['url'].join('/').startsWith('projects/invite')
        );
        const redirectQueryParams = redirectParams && redirectParams['params'] ? redirectParams['params'] : {};
        const onboardingEventParams = {
          ...(redirectQueryParams['apply_template_admin_panel'] && {
            OnboardingTemplateAdminPanel: true
          }),
          ...(redirectQueryParams['apply_template_admin_panel_resource'] && {
            OnboardingTemplateAdminPanelResource: redirectQueryParams['apply_template_admin_panel_resource']
          })
        };

        if (options.socialBackend == 'google-oauth2') {
          options.socialBackend = 'google';
        }

        if (options.userCreated) {
          const user = this.currentUserStore.instance;

          this.analyticsService.sendEvent(AnalyticsEvent.GA.UserRegistration, AnalyticsEventAction.Success, '', {
            userEmail: user ? user.email : undefined,
            userFirstName: user ? user.firstName : undefined,
            userLastName: user ? user.lastName : undefined,
            socialSignIn: options.socialBackend,
            projectsCreated: projectsCreated
          });

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountCreated, {
            Email: user ? user.email : undefined,
            FirstName: user ? user.firstName : undefined,
            LastName: user ? user.lastName : undefined,
            Provider: options.socialBackend,
            Invited: invited,
            ReferralUrl: document.referrer,
            ...onboardingEventParams
          });

          if (options.socialBackend) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountVerified, {
              Email: user ? user.email : undefined
            });
          }
        } else {
          this.analyticsService.sendEvent(AnalyticsEvent.GA.UserLogin, AnalyticsEventAction.Success, '', {
            projectsCreated: projectsCreated
          });
        }

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignIn.UserSignedIn, {
          Invited: invited,
          UserCreated: options.userCreated,
          ReferralUrl: document.referrer,
          ...onboardingEventParams
        });

        this.redirect({ projects: projects || [], userCreated: options.userCreated, selfSignOn: options.selfSignOn });
      });
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  redirect(options: { projects: Project[]; userCreated?: boolean; selfSignOn?: boolean }) {
    const redirect = this.activatedRoute.snapshot.queryParams['redirect'];
    const redirectParams = redirect ? JSON.parse(decodeURIComponent(redirect)) : undefined;
    const projectSignUp = this.activatedRoute.snapshot.params['project'];
    const transferProjects = this.activatedRoute.snapshot.queryParams['transfer_projects'];
    const selfSignOn = options.selfSignOn || this.activatedRoute.snapshot.queryParams['self_sign_on'];

    const project =
      projectSignUp || transferProjects
        ? options.projects.find(item => item.uniqueName == projectSignUp || transferProjects)
        : undefined;

    if (redirectParams && redirectParams['url']) {
      const queryParams = redirectParams['params'] || {};
      const referrer = this.activatedRoute.snapshot.queryParams['referrer'];

      if (referrer) {
        queryParams['referrer'] = referrer;
      }

      this.routing.navigate(redirectParams['url'], { queryParams: queryParams });
    } else if (project) {
      const mode = transferProjects ? AdminMode.Builder : AdminMode.App;
      if (project.params['sign_up_link'] && options.userCreated) {
        const link = isAbsoluteUrl(project.params['sign_up_link'])
          ? { href: project.params['sign_up_link'] }
          : project.linkWithProtocol(
              project.params['sign_up_link'].split('/').filter(item => isSet(item)),
              { mode: mode }
            );

        this.routing.navigateLink(link);
      } else {
        this.routing.navigateLink(project.getHomeLinkWithProtocol({ mode: mode }), {
          queryParams: {
            ...(selfSignOn && {
              src: HomeTriggerOutput.Register
            })
          }
        });
      }
    } else {
      this.routing.setRouterAttr(ROUTE_LOADED_PROJECTS_STORE, true);
      this.routing.navigate(['/projects']);
    }
  }
}

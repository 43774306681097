import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { OnBeforeDestroy } from '@common/lifecycle';
import { IgnoreScrollTop } from '@common/route-scroll';
import { ChangeComponent } from '@modules/change-routes';
import {
  ChangeViewSettings,
  CustomizeService,
  CustomViewSettings,
  ListViewSettings,
  ViewSettings,
  ViewSettingsStore,
  ViewSettingsType,
  WithBuilderMode
} from '@modules/customize';
import { ListComponent } from '@modules/list-routes';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';

import { CustomPageComponent } from '../custom-page/custom-page.component';

@Component({
  templateUrl: './page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@WithBuilderMode
@IgnoreScrollTop({
  predicate: (newSnapshot, prevSnapshot) => {
    const page = newSnapshot.params['page'];
    const prevPage = prevSnapshot ? prevSnapshot.params['page'] : undefined;

    return page == prevPage;
  }
})
export class PageComponent implements OnInit, OnDestroy, OnBeforeDestroy {
  @ViewChild(ListComponent) listComponent: ListComponent;
  @ViewChild(ChangeComponent) changeComponent: ChangeComponent;
  @ViewChild(CustomPageComponent) customPageComponent: CustomPageComponent;

  types = ViewSettingsType;
  deprecatedTypes: ViewSettingsType[] = [ViewSettingsType.List, ViewSettingsType.Change];
  type: ViewSettingsType;
  uniqueName: string;
  params: Object;
  modelId: string;
  id: string;
  notFound = false;
  notAllowed = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customizeService: CustomizeService,
    private viewSettingsStore: ViewSettingsStore,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(
      this.activatedRoute.params.pipe(
        switchMap<Params, ViewSettings>(params => {
          if (isSet(params['page'])) {
            return this.viewSettingsStore.getDetailFirst(params['page']);
          } else {
            return of(new CustomViewSettings());
          }
        })
      ),
      this.activatedRoute.queryParams,
      this.currentEnvironmentStore.instance$
    )
      .pipe(untilDestroyed(this))
      .subscribe(([viewSettings, queryParams, environment]) => {
        if (viewSettings) {
          let hasPermission = true;

          if (!this.customizeService.layoutEnabled) {
            if (viewSettings instanceof CustomViewSettings) {
              hasPermission = this.currentProjectStore.instance.hasEnvironmentPagePermission(
                environment,
                viewSettings.uid,
                'r'
              );
            } else if (viewSettings instanceof ChangeViewSettings) {
              hasPermission = this.currentProjectStore.instance.hasEnvironmentModelPermission(
                environment,
                viewSettings.uid,
                'w'
              );
            } else if (viewSettings instanceof ListViewSettings) {
              hasPermission = this.currentProjectStore.instance.hasEnvironmentModelPermission(
                environment,
                viewSettings.uid,
                'r'
              );
            }
          }

          if (hasPermission) {
            this.type = viewSettings.view;
            this.uniqueName = viewSettings.uniqueName;
            this.notFound = false;
            this.notAllowed = false;
          } else {
            this.type = undefined;
            this.uniqueName = undefined;
            this.notFound = false;
            this.notAllowed = true;
          }
        } else {
          this.type = undefined;
          this.uniqueName = undefined;
          this.notFound = true;
          this.notAllowed = false;
        }

        if (viewSettings instanceof ChangeViewSettings) {
          this.modelId =
            viewSettings.getQuery && viewSettings.getQuery.simpleQuery
              ? [viewSettings.getResource, viewSettings.getQuery.simpleQuery.model].join('.')
              : undefined;
          this.id = queryParams['id'];
        } else {
          this.modelId = undefined;
          this.id = undefined;
        }

        this.params = queryParams;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onBeforeDestroy(): void {
    if (this.listComponent) {
      this.listComponent.onBeforeDestroy();
    }

    if (this.changeComponent) {
      this.changeComponent.onBeforeDestroy();
    }

    if (this.customPageComponent) {
      this.customPageComponent.onBeforeDestroy();
    }
  }

  onParamsChanged(params: Object) {
    this.routing.navigateApp(['page', this.uniqueName], {
      queryParams: params
    });
  }
}

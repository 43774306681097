import pickBy from 'lodash/pickBy';

import { RawListViewSettingsColumn } from '@modules/customize';

import { ModelDescriptionQuery } from './model-description-query';
import { QueryType } from './query-type';

export enum QueryPagination {
  Page = 'page',
  Offset = 'offset',
  Cursor = 'cursor'
}

export const DefaultQueryPaginationHasMoreFunction = `// ex. data['hasMore']
return `;

export const DefaultQueryPaginationTotalFunction = `// ex. data['count']
return `;

export const DefaultQueryPaginationCursorPrevFunction = `// ex. data['prev_cursor']
return `;

export const DefaultQueryPaginationCursorNextFunction = `// ex. data['next_cursor']
return `;

export interface SortingField {
  name?: string;
  sortable?: boolean;
}

export class ListModelDescriptionQuery extends ModelDescriptionQuery {
  pagination: QueryPagination;
  paginationHasMoreFunction: string;
  paginationHasMorePagesPath: string[] = [];
  paginationHasMoreTotalPagesPath: string[] = [];
  paginationHasMoreTotalRecordsPath: string[] = [];
  paginationPerPage: number;
  paginationPerPageMin: number;
  paginationPerPageMax: number;
  paginationTotalFunction: string;
  paginationTotalPath: string[] = [];
  paginationCursorPrevFunction: string;
  paginationCursorPrevPath: string[] = [];
  paginationCursorNextFunction: string;
  paginationCursorNextPath: string[] = [];
  sorting = false;
  sortingFields: SortingField[];
  frontendFiltering = true;

  deserialize(data: Object) {
    super.deserialize(data);

    this.pagination = data['pagination'];
    this.paginationHasMoreFunction = data['pagination_has_more_function'];

    if (data['pagination_has_more_pages_path']) {
      this.paginationHasMorePagesPath = data['pagination_has_more_pages_path'];
    }
    if (data['pagination_has_more_total_pages_path']) {
      this.paginationHasMoreTotalPagesPath = data['pagination_has_more_total_pages_path'];
    }
    if (data['pagination_has_more_total_records_path']) {
      this.paginationHasMoreTotalRecordsPath = data['pagination_has_more_total_records_path'];
    }
    this.paginationPerPage = data['pagination_per_page'];
    this.paginationPerPageMin = data['pagination_per_page_min'];
    this.paginationPerPageMax = data['pagination_per_page_max'];
    this.paginationTotalPath = data['pagination_total_path'];
    this.paginationCursorPrevPath = data['pagination_cursor_prev_path'];
    this.paginationCursorNextPath = data['pagination_cursor_next_path'];
    this.paginationTotalFunction = data['pagination_total_function'];
    this.paginationCursorPrevFunction = data['pagination_cursor_prev_function'];
    this.paginationCursorNextFunction = data['pagination_cursor_next_function'];
    this.sortingFields = data['sorting_fields'];
    this.frontendFiltering = data['frontend_filtering'];

    if (data['sorting'] != undefined) {
      this.sorting = data['sorting'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      ...super.serialize(),
      pagination: this.pagination,
      pagination_has_more_function: this.paginationHasMoreFunction,
      pagination_total_function: this.paginationTotalFunction,
      pagination_cursor_prev_function: this.paginationCursorPrevFunction,
      pagination_cursor_next_function: this.paginationCursorNextFunction,
      pagination_total_path: this.paginationTotalPath,
      pagination_per_page: this.paginationPerPage,
      pagination_per_page_min: this.paginationPerPageMin,
      pagination_per_page_max: this.paginationPerPageMax,
      pagination_cursor_prev_path: this.paginationCursorPrevPath,
      pagination_cursor_next_path: this.paginationCursorNextPath,
      pagination_has_more_pages_path: this.paginationHasMorePagesPath,
      pagination_has_more_total_pages_path: this.paginationHasMoreTotalPagesPath,
      pagination_has_more_total_records_path: this.paginationHasMoreTotalRecordsPath,
      sorting: this.sorting,
      sorting_fields: this.sortingFields,
      frontend_filtering: this.frontendFiltering
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isFieldSortable(field: RawListViewSettingsColumn) {
    if ([QueryType.Simple, QueryType.SQL].includes(this.queryType)) {
      return !field.flex;
    } else if (this.frontendFiltering) {
      return !field.flex;
    } else {
      return (
        this.sorting &&
        this.sortingFields &&
        this.sortingFields.find(item => item.name == field.name && item.sortable) != undefined
      );
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';

import { CustomizeService, ViewContext } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { Dashboard, DashboardStore, Widget, WidgetType } from '@modules/dashboard';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardItemComponent implements OnDestroy, OnChanges, AfterViewInit, OnInit {
  @Input() dashboard: Widget;
  @Input() widget: Widget;
  @Input() columnWidth: number;
  @Input() rowHeight: number;
  @Input() context: ViewContext;
  @Output() sizeChanged = new EventEmitter<Widget>();
  @Output() sizeChangeConfirmed = new EventEmitter<Widget>();
  @Output() resizingChanged = new EventEmitter<Widget>();
  @Output() updated = new EventEmitter<Widget>();
  @Output() copyRequested = new EventEmitter<void>();
  @Output() moveRequested = new EventEmitter<Dashboard>();
  @Output() deleteRequested = new EventEmitter<void>();
  @ViewChild('root') root: ElementRef;
  @ViewChild('handle') handle: ElementRef;

  widgetType = WidgetType;

  resizing = false;
  resizeStartPosition;
  resizeStartSize;
  resizeSizeChanged = false;
  size;
  active = false;
  customizeComponent: DynamicComponentArguments;

  constructor(
    public dashboardStore: DashboardStore,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef,
    private customizeService: CustomizeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.size = { width: this.widget.width, height: this.widget.height };
  }

  ngOnDestroy(): void {
    this.closeCustomize();
  }

  ngOnInit() {
    this.customizeService.lastHovered$
      .pipe(
        debounceTime(1), // TODO: Workaround for slow ngOnDestroy
        untilDestroyed(this)
      )
      .subscribe(lastHovered => {
        this.active = lastHovered === this;
        this.cd.markForCheck();
      });
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.handle.nativeElement, 'mousedown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        e.preventDefault();

        this.resizing = true;
        this.resizeSizeChanged = false;
        this.resizeStartPosition = { x: e.clientX, y: e.clientY };
        this.resizeStartSize = {
          width: this.root.nativeElement.offsetWidth,
          height: this.root.nativeElement.offsetHeight
        };
        this.cd.detectChanges();
        this.resizingChanged.emit(this.widget);
      });

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (!this.resizing) {
          return;
        }

        const result = this.calculateSizeForPosition(e.clientX, e.clientY);

        if (result.sizeNormalized.width != this.size.width || result.sizeNormalized.height != this.size.height) {
          this.size = result.sizeNormalized;
          this.resizeSizeChanged = true;
          this.widget.width = this.size.width;
          this.widget.height = this.size.height;
          this.sizeChanged.emit(this.widget);
        }

        this.root.nativeElement.style.width = `${result.size.width}px`;
        this.root.nativeElement.style.height = `${result.size.height}px`;
      });

    fromEvent<MouseEvent>(window.document, 'mouseup')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.resizing) {
          return;
        }

        this.root.nativeElement.style.width = '';
        this.root.nativeElement.style.height = '';

        this.resizing = false;
        this.resizeStartPosition = undefined;
        this.resizeStartSize = undefined;
        this.cd.detectChanges();
        this.resizingChanged.emit(undefined);

        if (this.resizeSizeChanged) {
          this.resizeSizeChanged = false;
          this.sizeChangeConfirmed.emit(this.widget);
        }
      });
  }

  calculateSizeForPosition(x, y) {
    const delta = {
      x: x - this.resizeStartPosition.x,
      y: y - this.resizeStartPosition.y
    };

    const size = {
      width: Math.max(this.columnWidth, this.resizeStartSize.width + delta.x),
      height: Math.max(this.rowHeight, this.resizeStartSize.height + delta.y)
    };

    const sizeNormalized = {
      width: Math.round(size.width / this.columnWidth),
      height: Math.round(size.height / this.rowHeight)
    };

    this.root.nativeElement.style.width = `${size.width}px`;
    this.root.nativeElement.style.height = `${size.height}px`;

    return {
      size: size,
      sizeNormalized: sizeNormalized
    };
  }

  onHover(hover) {
    if (hover) {
      this.customizeService.addHover(this);
    } else {
      this.customizeService.removeHover(this);
    }
  }

  editWidget() {
    const initialWidget = cloneDeep(this.widget);

    this.customizeBarService
      .customizeWidget({ context: this.customizeBarContext, item: this.widget, viewContext: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as Widget;
          this.updated.next(instance);
        } else {
          this.updated.next(initialWidget);
        }
      });
    this.customizeComponent = this.customizeBarContext.settingsComponent;
  }

  closeCustomize() {
    if (this.customizeComponent) {
      this.customizeBarContext.closeSettingsComponent(this.customizeComponent);
      this.customizeComponent = undefined;
    }
  }
}

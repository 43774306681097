import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ValueWidget } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';

import { CustomizeBarEditEvent } from '../../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../../data/customize-bar-edit-event-type';
import { DataSourceInputsEditComponent } from '../../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ValueWidgetDataSourceControl } from '../../../model-description-data-source-edit/value-widget-data-source';
import { ValueWidgetDataSourceEditComponent } from '../../../value-widget-data-source-edit/value-widget-data-source-edit.component';
import { CustomizeBarValueWidgetEditForm } from '../customize-bar-value-widget-edit.form';

@Component({
  selector: 'app-customize-bar-value-widget-edit-data',
  templateUrl: './customize-bar-value-widget-edit-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarValueWidgetEditDataComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() widget: ValueWidget;
  @Input() control: ValueWidgetDataSourceControl;
  @Input() chartForm: CustomizeBarValueWidgetEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() addInput = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild(ValueWidgetDataSourceEditComponent) dataSourceEditComponent: ValueWidgetDataSourceEditComponent;
  @ViewChild(DataSourceInputsEditComponent) inputsEditComponent: DataSourceInputsEditComponent;

  createField = createFormFieldFactory();
  dataSourceTypes = DataSourceType;
  booleanFieldStyle = BooleanFieldStyle;

  constructor(private analyticsService: UniversalAnalyticsService) {}

  ngOnInit() {
    this.control
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.widget.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(this.control.getResource$().pipe(skip(1)), this.control.getModelDescription$().pipe(skip(1)))
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.widget.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
      ComponentTypeID: this.widget.analyticsName
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.addInput && this.inputsEditComponent) {
        this.inputsEditComponent.openAddInput();
      }
    }, 0);
  }

  submit() {
    this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.chartForm.submit() } });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.addQueryInput();
    }
  }
}

import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CustomViewSettings, PopupSettings, ViewSettings } from '@modules/customize';

@Component({
  selector: 'app-custom-page-popups-menu-section-item',
  templateUrl: './custom-page-popups-menu-section-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePopupsMenuSectionItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() item: PopupSettings;
  @Input() active = false;
  @Input() viewSettings: CustomViewSettings;
  @Output() selected = new EventEmitter<void>();
  @Output() customize = new EventEmitter<void>();
  @Output() moveToPage = new EventEmitter<ViewSettings>();
  @Output() duplicate = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) movePagesOverlay: CdkConnectedOverlay;

  movePagesOpened = false;
  movePagesPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetY: 0
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: 0
    }
  ];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setMovePagesOpened(value) {
    this.movePagesOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (!this.movePagesOverlay) {
      return;
    }

    this.movePagesOverlay.positionChange.pipe(untilDestroyed(this)).subscribe((e: ConnectedOverlayPositionChange) => {
      const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
      const position = this.movePagesPositions.find(item =>
        propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
      );
      const otherPosition = this.movePagesPositions.filter(item => item !== position);

      if (position) {
        this.movePagesOverlay.overlayRef.addPanelClass(position.panelClass);
      }

      otherPosition.forEach(item => this.movePagesOverlay.overlayRef.removePanelClass(item.panelClass));
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { ChartWidgetDataset } from '@modules/dashboard';
var ChartWidgetEditFormDatasetControl = /** @class */ (function (_super) {
    __extends(ChartWidgetEditFormDatasetControl, _super);
    function ChartWidgetEditFormDatasetControl(dataSourceControl) {
        return _super.call(this, {
            data_source: dataSourceControl,
            color: new FormControl(''),
            name: new FormControl(''),
            format: new FormControl('')
        }) || this;
    }
    ChartWidgetEditFormDatasetControl.prototype.ngOnDestroy = function () { };
    ChartWidgetEditFormDatasetControl.prototype.deserialize = function (instance) {
        if (instance) {
            this.controls.color.patchValue(instance.color);
            this.controls.name.patchValue(instance.name);
            this.controls.format.patchValue(instance.format);
        }
        else {
            this.controls.color.patchValue('');
            this.controls.name.patchValue('');
            this.controls.format.patchValue('');
        }
        this.controls.data_source.deserialize(instance ? instance.dataSource : undefined);
        this.trackChanges();
        this.markAsPristine();
    };
    ChartWidgetEditFormDatasetControl.prototype.trackChanges = function () {
        var _this = this;
        this.controls.data_source
            .getModelDescription$()
            .pipe(distinctUntilChanged(function (lhs, rhs) {
            var modelId = function (item) { return (item ? item.modelId : undefined); };
            return modelId(lhs) === modelId(rhs);
        }), skip(1), untilDestroyed(this))
            .subscribe(function (modelDescription) { return _this.onModelChange(modelDescription); });
    };
    ChartWidgetEditFormDatasetControl.prototype.onModelChange = function (modelDescription) {
        if (modelDescription && modelDescription.verboseNamePlural) {
            this.controls.name.patchValue(modelDescription.verboseNamePlural);
        }
        else {
            this.controls.name.patchValue('');
        }
    };
    ChartWidgetEditFormDatasetControl.prototype.serialize = function () {
        var result = cloneDeep(this.instance) || new ChartWidgetDataset();
        result.dataSource = this.controls.data_source.serialize();
        result.color = this.controls.color.value;
        result.name = this.controls.name.value;
        result.format = this.controls.format.value;
        return result;
    };
    return ChartWidgetEditFormDatasetControl;
}(FormGroup));
export { ChartWidgetEditFormDatasetControl };

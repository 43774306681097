var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
var ShareMenuItem = /** @class */ (function (_super) {
    __extends(ShareMenuItem, _super);
    function ShareMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.Share;
        return _this;
    }
    ShareMenuItem.prototype.clone = function () {
        var instance = new ShareMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return ShareMenuItem;
}(MenuItem));
export { ShareMenuItem };
registerMenuItemForType(MenuItemType.Share, ShareMenuItem);

export enum HttpParameterType {
  Text = 'text',
  File = 'file'
}

export interface HttpParameter {
  name: string;
  value: string;
  type?: HttpParameterType;
  contentType?: string;
}

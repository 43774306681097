import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';

import { ViewSettingsStore } from '@modules/customize';
import { AdminPanelSeparatePagesGenerator } from '@modules/customize-generators';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { objectsSortPredicate } from '@shared';

import { getResourceAddModelComponents, ResourceAddModelComponentItem } from '../../data/resource-add-model-components';
import { ResourceModelEditController } from '../../services/resource-model-edit-controller/resource-model-edit.controller';

export interface ResourceChoosePagesItem<T = Object> {
  uniqueName: string;
  name: string;
  data?: T;
}

export interface ResourceChoosePagesAutoItemData {
  modelDescription: ModelDescription;
}

@Component({
  selector: 'app-resource-choose-pages',
  templateUrl: './resource-choose-pages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceChoosePagesComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() pages: ResourceChoosePagesItem[] = [];
  @Input() modelPages = false;
  @Input() optional = false;
  @Output() selected = new EventEmitter<ResourceChoosePagesItem[]>();
  @Output() cancelled = new EventEmitter<void>();

  items: {
    page: ResourceChoosePagesItem;
    selected: boolean;
    disabled: boolean;
    error?: string;
  }[] = [];
  selectedAll = false;
  addModelComponents: ResourceAddModelComponentItem[] = [];

  constructor(
    private popupComponent: BasePopupComponent,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private adminPanelSeparatePagesGenerator: AdminPanelSeparatePagesGenerator,
    private resourceModelEditController: ResourceModelEditController,
    private viewSettingsStore: ViewSettingsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateItems();
    this.updateAddModel();
  }

  updateItems() {
    this.modelDescriptionStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(allModelDescriptions => {
        const defaultSelected = !this.optional;
        const items = this.pages.map(item => {
          const existingItem = this.items.find(i => i.page.uniqueName == item.uniqueName);

          return {
            page: item,
            selected: existingItem ? existingItem.selected : defaultSelected,
            disabled: false
          };
        });

        if (this.modelPages) {
          const modelDescriptions = allModelDescriptions.filter(item => item.resource == this.resource.uniqueName);
          const modelPages: ResourceChoosePagesItem<ResourceChoosePagesAutoItemData>[] = modelDescriptions
            .filter(item => {
              if (!item.getQuery) {
                return false;
              } else if (item.getParameters.some(parameter => parameter.required)) {
                return false;
              } else if (item.virtual) {
                return true;
              } else if (
                !item.getDetailQuery &&
                !item.getQuery.frontendFiltering &&
                !modelDescriptionHasAutoParameters(this.resource, item)
              ) {
                return false;
              } else {
                return true;
              }
            })
            .map(item => {
              return {
                uniqueName: `model_${item.model}`,
                name: item.verboseNamePlural,
                data: {
                  modelDescription: item
                }
              };
            });

          items.push(
            ...modelPages.map(item => {
              const existingItem = this.items.find(i => i.page.uniqueName == item.uniqueName);
              const modelDescription = item.data.modelDescription;
              let error: string;

              if (!modelDescription.getQuery) {
                error = 'No Get Record List query';
              } else if (
                !modelDescription.getDetailQuery &&
                !modelDescription.getQuery.frontendFiltering &&
                !modelDescriptionHasAutoParameters(this.resource, modelDescription)
              ) {
                error = 'No Get One Record query';
              } else if (!modelDescription.primaryKeyField) {
                error = 'No Primary Key specified';
              }

              return {
                page: item,
                selected: existingItem ? existingItem.selected : defaultSelected,
                disabled: !!error,
                error: error
              };
            })
          );
        }

        this.items = items.sort(objectsSortPredicate('disabled', 'page.name'));
        this.updateSelectedAll();
      });
  }

  ngOnDestroy(): void {}

  toggleSelected(toggleItem) {
    this.items = this.items.map(item => {
      return {
        ...item,
        ...(item === toggleItem && { selected: !item.selected })
      };
    });
    this.updateSelectedAll();
  }

  setSelectedAll(selected: boolean) {
    this.items = this.items.map(item => {
      return {
        ...item,
        selected: selected && !item.disabled
      };
    });
    this.updateSelectedAll();
  }

  updateSelectedAll() {
    this.selectedAll = this.items.filter(item => !item.disabled).every(item => item.selected);
    this.cd.markForCheck();
  }

  updateAddModel() {
    if (this.resource && !this.resource.demo && this.modelPages) {
      this.addModelComponents = getResourceAddModelComponents(this.resource.typeItem.name).filter(
        item => !item.multistep
      );
    } else {
      this.addModelComponents = [];
    }

    this.cd.markForCheck();
  }

  close() {
    this.popupComponent.close();
  }

  submit() {
    const selectedPages = this.items
      .filter(item => item.selected)
      .filter(item => !item.disabled)
      .map(item => item.page);

    this.close();
    this.selected.emit(selectedPages);
  }

  cancel() {
    this.close();
    this.cancelled.emit();
  }

  addModel(addModelComponent: ResourceAddModelComponentItem) {
    this.resourceModelEditController
      .addModel(this.resource, addModelComponent, { source: 'choose_pages' })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.items = this.items.map(item => {
          const data = item.page.data as ResourceChoosePagesAutoItemData;
          if (data.modelDescription && data.modelDescription.modelId == result.modelDescription.modelId) {
            return {
              ...item,
              selected: true
            };
          } else {
            return item;
          }
        });
        this.cd.markForCheck();
      });
  }
}

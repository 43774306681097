import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { controlValue, TypedChanges } from '@shared';

@Component({
  selector: 'app-input-edit-context-value',
  templateUrl: './input-edit-context-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditContextValueComponent implements OnInit, OnDestroy, OnChanges {
  @Input() valueControl: FormControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Output() edit = new EventEmitter<void>();
  @Output() reset = new EventEmitter<void>();

  valuePath: string[];
  valuePathSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<InputEditContextValueComponent>): void {
    if (
      changes.valueControl ||
      changes.context ||
      changes.contextElement ||
      changes.contextElementPath ||
      changes.contextElementPaths
    ) {
      this.updateValuePath();
    }
  }

  updateValuePath() {
    if (this.valuePathSubscription) {
      this.valuePathSubscription.unsubscribe();
      this.valuePathSubscription = undefined;
    }

    this.valuePathSubscription = this.getValuePath$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.valuePath = value;
        this.cd.markForCheck();
      });
  }

  getValuePath$(): Observable<string[]> {
    return controlValue<string[]>(this.valueControl).pipe(
      switchMap(value => {
        if (!this.context) {
          return of(undefined);
        }

        return this.context.tokenPath$(
          value,
          this.contextElement,
          this.contextElementPath,
          this.contextElementPaths,
          true
        );
      }),
      map(result => {
        if (!result) {
          return ['Incorrect value'];
        }

        return result.reverse();
      })
    );
  }
}

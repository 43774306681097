var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InjectionToken, NgZone, OnDestroy, Type } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, Subject } from 'rxjs';
import { delayWhen, distinctUntilChanged, first, map, skip, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { applyDisplayFieldsDefaultVisible, rawListViewSettingsColumnsToDisplayField } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput, defaultFieldType, DisplayField, DisplayFieldType, Input, InputValueType, isInputSet, isRequiredInputsSet, ParameterArray } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { FieldInputControl, InputFieldProvider } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { editableQueryTypes, ModelDescriptionQuery, QueryService, QueryType } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService, RestAPIResourceParams } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { DisplayFieldArray } from '../display-fields-edit/display-field.array';
export var validateType = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateResource = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    var type = parent.controls.type.value;
    if (![DataSourceType.Query].includes(type)) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
export var validateQuery = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    var type = parent.controls.type.value;
    if (![DataSourceType.Query].includes(type)) {
        return;
    }
    var query = control.value;
    if (!query || !query.isConfigured()) {
        return { required: true };
    }
};
export var validateInput = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    var type = parent.controls.type.value;
    if (![DataSourceType.Input].includes(type)) {
        return;
    }
    if (!isInputSet(control.value)) {
        return { required: true };
    }
};
export var validateInputs = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    var type = parent.controls.type.value;
    if (![DataSourceType.Query].includes(type)) {
        return;
    }
    var fields = parent.inputFieldProvider.fields;
    var inputs = control.value;
    if (!isRequiredInputsSet(fields, inputs)) {
        return { required: true };
    }
};
export var validateColumns = function (control) {
    var parent = control.parent;
    if (!parent || parent.validator !== Validators.required) {
        return;
    }
    if (!control.controls.length) {
        return { required: true };
    }
};
export var DATA_SOURCE_EXTRA_CONTROLS = new InjectionToken('DATA_SOURCE_EXTRA_CONTROLS');
var DataSourceControl = /** @class */ (function (_super) {
    __extends(DataSourceControl, _super);
    function DataSourceControl(currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone, extraControls) {
        if (extraControls === void 0) { extraControls = {}; }
        var _this = _super.call(this, __assign({ type: new FormControl(DataSourceType.Query, validateType), query_resource: new FormControl('', validateResource), query: new FormControl(null, validateQuery), query_parameters: new ParameterArray([]), query_inputs: new FormControl([], validateInputs), input: new FieldInputControl({ name: 'value' }, validateInput), workflow: new FormControl(null), columns: new DisplayFieldArray([], validateColumns) }, extraControls)) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectSettingsService = projectSettingsService;
        _this.resourceControllerService = resourceControllerService;
        _this.resourceGeneratorResolver = resourceGeneratorResolver;
        _this.modelService = modelService;
        _this.actionService = actionService;
        _this.queryService = queryService;
        _this.notificationService = notificationService;
        _this.zone = zone;
        _this.resourceFieldParams = {};
        _this.inputFieldProvider = new InputFieldProvider();
        _this.queryDefaultSet = new Subject();
        _this.defaultJsonVisibility = false;
        _this.typeOptions = [
            { value: DataSourceType.Query, name: 'Load Data', icon: 'cloud_download' },
            { value: DataSourceType.Workflow, name: 'Load using Workflow', icon: 'workflow' },
            { value: DataSourceType.Input, name: 'Specify Data', icon: 'link' }
        ];
        _this.inputFieldProvider.setProvider(_this.getInputFieldProvider$());
        _this.inputFieldProvider.fields$.pipe(untilDestroyed(_this)).subscribe(function () {
            _this.controls.query_inputs.updateValueAndValidity();
        });
        return _this;
    }
    DataSourceControl.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    DataSourceControl.prototype.setRequired = function (required) {
        if (required) {
            this.setValidators(Validators.required);
            values(this.controls).forEach(function (item) { return item.updateValueAndValidity(); });
        }
        else {
            this.clearValidators();
            values(this.controls).forEach(function (item) { return item.updateValueAndValidity(); });
        }
    };
    DataSourceControl.prototype.deserializeExtraControls = function (instance, initial) {
        if (initial === void 0) { initial = true; }
    };
    DataSourceControl.prototype.clear = function () {
        this.controls.type.setValue(DataSourceType.Query);
        this.controls.query_resource.setValue('');
        this.controls.query.setValue(null);
        this.controls.query_parameters.setValue([]);
        this.controls.query_inputs.setValue([]);
        this.controls.input.clearValue({ name: 'value' });
        this.controls.workflow.setValue(null);
        this.controls.columns.setValue([]);
    };
    DataSourceControl.prototype.deserialize = function (instance, initial) {
        var _this = this;
        if (initial === void 0) { initial = true; }
        this.instance = instance;
        if (instance) {
            this.controls.type.setValue(instance.type);
            this.controls.query_resource.setValue(instance.queryResource);
            this.controls.query.setValue(instance.query);
            this.controls.query_parameters.setValue(instance.queryParameters);
            this.controls.query_inputs.setValue(instance.queryInputs);
            this.controls.workflow.setValue(instance.workflow);
            this.controls.columns.setValue(instance.columns);
            if (instance.input) {
                this.controls.input.patchValue(instance.input.serialize());
            }
            else {
                this.controls.input.clearValue({ name: 'value' });
            }
        }
        else {
            this.clear();
        }
        if (this.deserializeExtraControls) {
            this.deserializeExtraControls(instance, initial);
        }
        if (initial) {
            this.markAsPristine();
            controlValue(this.controls.type)
                .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
                .subscribe(function (value) { return _this.onTypeChange(value); });
            this.controls.query_resource.valueChanges
                .pipe(distinctUntilChanged(), switchMap(function () { return _this.getQueryOptions$().pipe(first()); }), untilDestroyed(this))
                .subscribe(function (items) {
                var options = items.filter(function (item) { return item.option; }).map(function (item) { return item.option; });
                if (options[0]) {
                    _this.zone.run(function () {
                        _this.controls.query.setValue(options[0].value);
                        _this.queryDefaultSet.next(options[0].value);
                    });
                }
            });
            controlValue(this.controls.workflow)
                .pipe(skip(1), untilDestroyed(this))
                .subscribe(function (value) { return _this.onWorkflowChange(value); });
        }
    };
    DataSourceControl.prototype.serialize = function () {
        if (!this.controls.type.value) {
            return;
        }
        var instanceCls = this.getInstanceCls();
        var result = new instanceCls();
        result.type = this.controls.type.value;
        if (result.type == DataSourceType.Query) {
            result.queryResource = this.controls.query_resource.value;
            result.query = this.controls.query.value;
            result.queryParameters = this.controls.query_parameters.value;
            result.queryInputs = this.controls.query_inputs.value;
        }
        else if (result.type == DataSourceType.Input) {
            result.input = this.controls.input.value ? new Input().deserialize(this.controls.input.value) : undefined;
            result.queryInputs = this.controls.query_inputs.value;
        }
        else if (result.type == DataSourceType.Workflow) {
            result.workflow = this.controls.workflow.value;
            result.queryParameters = this.controls.query_parameters.value;
            result.queryInputs = this.controls.query_inputs.value;
        }
        result.columns = this.controls.columns.value;
        return result;
    };
    DataSourceControl.prototype.getInstanceCls = function () {
        return this.constructor.instanceCls;
    };
    DataSourceControl.prototype.getResource$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.query_resource)).pipe(switchMap(function (_a) {
            var type = _a[0], resource = _a[1];
            if (type != DataSourceType.Query) {
                return of(undefined);
            }
            return _this.currentEnvironmentStore.resources$.pipe(map(function (resources) {
                return resources.find(function (item) { return item.uniqueName == resource; });
            }));
        }));
    };
    DataSourceControl.prototype.getResourceBaseHttpQuery$ = function () {
        return this.getResource$().pipe(map(function (resource) {
            if (!resource) {
                return;
            }
            var resourceParams = resource.parseParams(RestAPIResourceParams);
            return resourceParams.baseHttpQuery;
        }));
    };
    DataSourceControl.prototype.getResourceReloadAllowed$ = function () {
        var _this = this;
        return this.getResource$().pipe(map(function (resource) {
            if (!resource) {
                return false;
            }
            if (resource) {
                var generator = _this.resourceGeneratorResolver.get(resource.typeItem.name);
                return _this.projectSettingsService.syncAllowed(resource, generator) && !resource.demo;
            }
            else {
                return false;
            }
        }));
    };
    DataSourceControl.prototype.getCustomQueryType$ = function () {
        var _this = this;
        return this.getResource$().pipe(map(function (resource) {
            if (!resource) {
                return;
            }
            var controller = _this.resourceControllerService.get(resource.type);
            var queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
            return editableQueryTypes.find(function (item) { return queryTypes.includes(item); });
        }));
    };
    DataSourceControl.prototype.getQueryEditable$ = function () {
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.query)).pipe(map(function (_a) {
            var type = _a[0], query = _a[1];
            if (type == DataSourceType.Query) {
                return query && editableQueryTypes.includes(query.queryType);
            }
            else if (type == DataSourceType.Workflow) {
                return true;
            }
            else {
                return false;
            }
        }));
    };
    DataSourceControl.prototype.getDataConfigured$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return controlValue(this).pipe(map(function () {
            if (_this.controls.type.value == DataSourceType.Query) {
                return (isSet(_this.controls.query_resource.value) &&
                    isSet(_this.controls.query.value) &&
                    _this.controls.query.value.isConfigured() &&
                    (options.columnsOptional || _this.controls.columns.value.length > 0));
            }
            else if (_this.controls.type.value == DataSourceType.Input) {
                return isInputSet(_this.controls.input.value) && _this.controls.columns.controls.length > 0;
            }
            else if (_this.controls.type.value == DataSourceType.Workflow) {
                return _this.controls.workflow.value && _this.controls.columns.controls.length > 0;
            }
            else {
                return false;
            }
        }));
    };
    DataSourceControl.prototype.getQueryConfigured$ = function () {
        var _this = this;
        return controlValue(this).pipe(map(function () {
            if (_this.controls.type.value == DataSourceType.Query) {
                return isSet(_this.controls.query.value) && _this.controls.query.value.isConfigured();
            }
            else if (_this.controls.type.value == DataSourceType.Input) {
                return isInputSet(_this.controls.input.value);
            }
            else if (_this.controls.type.value == DataSourceType.Workflow) {
                return _this.controls.workflow.value;
            }
            else {
                return false;
            }
        }));
    };
    DataSourceControl.prototype.getInput$ = function () {
        return controlValue(this.controls.input).pipe(map(function (value) { return (value ? new Input().deserialize(value) : undefined); }));
    };
    DataSourceControl.prototype.getColumnOptions$ = function () {
        return controlValue(this.controls.columns).pipe(map(function (columnsValue) {
            if (!columnsValue) {
                return [];
            }
            return columnsValue
                .filter(function (item) { return item.type == DisplayFieldType.Base; })
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.verboseName || item.name,
                    icon: item.icon
                };
            });
        }));
    };
    DataSourceControl.prototype.setQueryUpdated = function (options) {
        if (options === void 0) { options = {}; }
        var query = cloneDeep(this.controls.query.value);
        query.updated = moment();
        this.controls.query.setValue(query);
        if (options.markAsDirty) {
            this.controls.query.markAsDirty();
        }
    };
    DataSourceControl.prototype.reloadResource = function () {
        var _this = this;
        return this.getResource$().pipe(first(), switchMap(function (resource) {
            if (!resource) {
                return;
            }
            var generator = _this.resourceGeneratorResolver.get(resource.typeItem.name);
            if (!generator) {
                return of(false);
            }
            return _this.projectSettingsService
                .syncResource(resource, generator, { mergeExisting: true })
                .pipe(map(function () { return true; }));
        }), delayWhen(function () { return _this.onResourceReloaded(); }));
    };
    DataSourceControl.prototype.onTypeChange = function (type) {
        if (type == DataSourceType.Input) {
            this.setColumns([]);
        }
    };
    DataSourceControl.prototype.onResourceReloaded = function () {
        return of(undefined);
    };
    DataSourceControl.prototype.getWorkflowColumns = function (workflow) {
        return this.actionService.getWorkflowOutputs(workflow).pipe(map(function (result) {
            return result.outputs.map(function (item) {
                var field = new DisplayField({
                    name: item.name,
                    verboseName: item.verboseName,
                    field: item.field,
                    params: item.params
                });
                field.updateFieldDescription();
                return field;
            });
        }));
    };
    DataSourceControl.prototype.onWorkflowChange = function (workflow) {
        var _this = this;
        this.getWorkflowColumns(workflow)
            .pipe(untilDestroyed(this))
            .subscribe(function (columns) {
            _this.setColumns(columns);
            _this.controls.query_parameters.setValue([]);
        });
    };
    DataSourceControl.prototype.setColumns = function (columns, options) {
        if (options === void 0) { options = {}; }
        columns = applyDisplayFieldsDefaultVisible(columns, {
            maxVisible: 6,
            modelDescription: options.modelDescription,
            defaultJsonVisibility: this.defaultJsonVisibility
        });
        this.controls.columns.setValue(columns);
        if (options.markAsDirty) {
            this.controls.columns.markAsDirty();
        }
    };
    DataSourceControl.prototype.mergeColumns = function (newColumns, options) {
        if (options === void 0) { options = {}; }
        var prevColumns = this.controls.columns.value || [];
        var result = prevColumns.filter(function (item) { return newColumns.find(function (i) { return i.name == item.name; }) || item.type != DisplayFieldType.Base; }).concat(newColumns.filter(function (item) { return !prevColumns.find(function (i) { return i.name == item.name; }); }));
        this.setColumns(result, options);
    };
    DataSourceControl.prototype.setAutoDetectColumns = function (query, isDataArray, options) {
        if (options === void 0) { options = {}; }
        var responseColumns = this.queryService.getAutoDetectColumns(query, isDataArray);
        if (!responseColumns) {
            this.notificationService.error('Failed to detect fields', 'Response is incorrect');
            return;
        }
        var columns = responseColumns.map(function (field) { return rawListViewSettingsColumnsToDisplayField(field); });
        if (options.merge) {
            this.mergeColumns(columns, { markAsDirty: options.markAsDirty });
        }
        else {
            this.setColumns(columns, { markAsDirty: options.markAsDirty });
        }
    };
    DataSourceControl.prototype.isColumnsSame$ = function (lhs, rhs) {
        lhs = lhs.filter(function (item) { return !item['flex']; });
        rhs = rhs.filter(function (item) { return !item['flex']; });
        return lhs.length == rhs.length && lhs.every(function (lhsItem) { return !!rhs.find(function (rhsItem) { return rhsItem.name == lhsItem.name; }); });
    };
    DataSourceControl.prototype.isListQuery = function () {
        return false;
    };
    DataSourceControl.prototype.getSimpleQueryDefaultColumns = function () {
        return of([]);
    };
    DataSourceControl.prototype.getCustomQueryDefaultColumns = function (query, isDataArray) {
        var data = query.getRequestResponse();
        var columns = isDataArray
            ? this.queryService.autoDetectGetFields(data)
            : this.queryService.autoDetectGetDetailFields(data);
        return of(columns);
    };
    DataSourceControl.prototype.getInputDefaultColumns = function (isDataArray, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var getContextColumns = function (value) {
            return options.context.getToken$(value.contextValue, options.contextElement).pipe(map(function (result) {
                if (!result || !result.children || !result.children.length) {
                    return;
                }
                var columns = isDataArray && result.children[0].children && result.children[0].children.length
                    ? result.children[0].children
                    : result.children;
                return columns.map(function (item) {
                    return {
                        name: item.uniqueName,
                        verboseName: item.name,
                        field: item.fieldType || defaultFieldType,
                        params: item.fieldParams || {}
                    };
                });
            }));
        };
        return controlValue(this.controls.input).pipe(switchMap(function (value) {
            var input = value ? new Input().deserialize(value) : undefined;
            if (!input) {
                return of(undefined);
            }
            var contextColumns$ = options.context && input.valueType == InputValueType.Context ? getContextColumns(input) : of(undefined);
            return contextColumns$.pipe(map(function (contextColumns) {
                if (contextColumns) {
                    return contextColumns;
                }
                try {
                    var data = applyParamInput(input, {
                        context: options.context,
                        contextElement: options.contextElement,
                        localContext: options.localContext,
                        defaultValue: {}
                    });
                    return isDataArray
                        ? _this.queryService.autoDetectGetFields(data)
                        : _this.queryService.autoDetectGetDetailFields(data);
                }
                catch (e) { }
            }));
        }));
    };
    DataSourceControl.prototype.getAutoDetectedColumns$ = function (isDataArray, options) {
        if (options === void 0) { options = {}; }
        if (this.controls.type.value == DataSourceType.Query) {
            var query = this.controls.query.value;
            if (!query) {
                return of(undefined);
            }
            if (query.queryType == QueryType.Simple) {
                return this.getSimpleQueryDefaultColumns();
            }
            else {
                return this.getCustomQueryDefaultColumns(query, isDataArray).pipe(map(function (columns) {
                    if (!columns) {
                        return;
                    }
                    return columns.map(function (item) { return rawListViewSettingsColumnsToDisplayField(item); });
                }));
            }
        }
        else {
            return this.getInputDefaultColumns(isDataArray, options).pipe(map(function (columns) {
                if (!columns) {
                    return;
                }
                return columns.map(function (item) { return rawListViewSettingsColumnsToDisplayField(item); });
            }));
        }
    };
    DataSourceControl.prototype.resetInputColumns = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var isDataArray = this.isListQuery();
        return this.getAutoDetectedColumns$(isDataArray, {
            context: options.context,
            contextElement: options.contextElement,
            localContext: options.localContext
        })
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (columns) {
            if (columns) {
                _this.setColumns(columns, { markAsDirty: options.markAsDirty });
            }
        });
    };
    return DataSourceControl;
}(FormGroup));
export { DataSourceControl };

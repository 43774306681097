/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../compact-wide-button/compact-wide-button.component.ngfactory";
import * as i2 from "../compact-wide-button/compact-wide-button.component";
import * as i3 from "./interactions-section-click.component";
import * as i4 from "../../view-editor-sidebar/view-editor-sidebar.component";
import * as i5 from "../../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i6 from "../../../../customize-bar/services/customize-bar-context/customize-bar.context";
var styles_InteractionsSectionClickComponent = [];
var RenderType_InteractionsSectionClickComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InteractionsSectionClickComponent, data: {} });
export { RenderType_InteractionsSectionClickComponent as RenderType_InteractionsSectionClickComponent };
export function View_InteractionsSectionClickComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compact-wide-button", [], null, [[null, "click"], [null, "rightClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customizeAction() !== false);
        ad = (pd_0 && ad);
    } if (("rightClick" === en)) {
        var pd_1 = (_co.arrayControl.removeControl(_co.control) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CompactWideButtonComponent_0, i1.RenderType_CompactWideButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.CompactWideButtonComponent, [], { label: [0, "label"], interactive: [1, "interactive"], leftIcon: [2, "leftIcon"], leftBackground: [3, "leftBackground"], rightIcon: [4, "rightIcon"], rightInteractive: [5, "rightInteractive"], rightTooltip: [6, "rightTooltip"] }, { rightClick: "rightClick" })], function (_ck, _v) { var currVal_0 = "Click action"; var currVal_1 = true; var currVal_2 = "hand"; var currVal_3 = true; var currVal_4 = "close"; var currVal_5 = true; var currVal_6 = "Remove action"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_InteractionsSectionClickComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-interactions-section-click", [], null, null, null, View_InteractionsSectionClickComponent_0, RenderType_InteractionsSectionClickComponent)), i0.ɵdid(1, 245760, null, 0, i3.InteractionsSectionClickComponent, [[2, i4.ViewEditorSidebarComponent], i5.CustomizeBarService, i6.CustomizeBarContext], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractionsSectionClickComponentNgFactory = i0.ɵccf("app-interactions-section-click", i3.InteractionsSectionClickComponent, View_InteractionsSectionClickComponent_Host_0, { control: "control", arrayControl: "arrayControl", viewContext: "viewContext" }, {}, []);
export { InteractionsSectionClickComponentNgFactory as InteractionsSectionClickComponentNgFactory };

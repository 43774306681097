import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { DelayWorkflowStep } from '@modules/workflow';

@Injectable()
export class DelayCustomizeWorkflowStepForm extends FormGroup {
  instance: DelayWorkflowStep;

  controls: {
    name: FormControl;
    delay: FormControl;
  };

  constructor() {
    super({
      name: new FormControl(''),
      delay: new FormControl(3)
    });
  }

  init(instance: DelayWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.delay.patchValue(instance.delay);
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): DelayWorkflowStep {
    const instance = cloneDeep(this.instance) as DelayWorkflowStep;

    instance.name = this.controls.name.value;
    instance.delay = this.controls.delay.value;

    return instance;
  }
}

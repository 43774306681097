import { FormArray, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import range from 'lodash/range';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { controlValue, isSet } from '@shared';

import { ParameterValue } from '../data/parameter-value';
import { ParameterValueControl } from './parameter-value.control';

export class ParameterValueArray extends FormArray {
  controls: ParameterValueControl[];

  private _group = new FormGroup({});

  constructor(controls: ParameterValueControl[] = []) {
    super(controls);
  }

  patchValue(value: ParameterValue[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  get value(): ParameterValue[] {
    return this.serialize();
  }

  set value(value: ParameterValue[]) {}

  deserialize(value: ParameterValue[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): ParameterValue[] {
    return this.controls.map(control => control.serialize()).filter(item => isSet(item.name));
  }

  setControls(controls: ParameterValueControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: ParameterValueControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: ParameterValue, value?: Object): ParameterValueControl {
    const control = new ParameterValueControl();

    if (item) {
      control.deserialize(item);
    }

    if (value) {
      control.patchValue(value);
      control.markAsPristine();
    }

    return control;
  }

  prependControl(item?: ParameterValue, value?: Object): ParameterValueControl {
    const control = this.createControl(item, value);
    this.insert(0, control);
    return control;
  }

  appendControl(item?: ParameterValue, value?: Object): ParameterValueControl {
    const control = this.createControl(item, value);
    this.push(control);
    return control;
  }

  getGroup$(): Observable<FormGroup> {
    return controlValue(this).pipe(
      map(() => {
        this.controls
          .filter(control => !this._group.contains(control.controls.name.value))
          .forEach(control => this._group.addControl(control.controls.name.value, control.controls.value));
        keys(this._group.controls)
          .filter(name => !this.controls.find(control => control.controls.name.value == name))
          .forEach(name => this._group.removeControl(name));

        return this._group;
      })
    );
  }
}

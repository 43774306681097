<div class="menu-pages-banner">
  <div class="menu-pages-banner__title" [innerHTML]="title"></div>
  <div class="menu-pages-banner__video-container">
    <a href="javascript:void(0)" class="menu-pages-banner__video" [appShowVideo]="showVideoOptions">
      <div class="menu-pages-banner__video-inner" [style.background-image]="'url(' + getViewPreview() + ')'"></div>
      <div class="menu-pages-banner__video-overlay"></div>
      <div class="menu-pages-banner__video-icon icon-play_filled"></div>
    </a>
  </div>
  <div class="menu-pages-banner__border"></div>
  <div class="menu-pages-banner__description"><ng-content></ng-content></div>
  <a *ngIf="uid" href="javascript:void(0)" class="menu-pages-banner__close icon-close" [appHideBanner]="uid"></a>
</div>

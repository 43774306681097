import { all, create, factory } from 'mathjs';

import './math-custom-functions';
import { mathCustomFunctions } from './math-custom-functions.const';

function EQUAL(a, b) {
  return a == b;
}

function UNEQUAL(a, b) {
  return a != b;
}

export const mathjs = create(
  {
    ...all,
    createEqual: factory('equal', [], () => EQUAL),
    createUnequal: factory('unequal', [], () => UNEQUAL)
  },
  {
    matrix: 'Array'
  }
);

mathjs.import(mathCustomFunctions, { override: true });

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-custom-menu-item-popover [control]="control" (close)="openEditPopover(false)"></app-custom-menu-item-popover>
</ng-template>

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openViewEditor()">
    <span class="choose-items-item__dropdown-icon icon-canvas"></span>
    Customize with Designer
  </button>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openEditPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize item
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete item
  </button>
</mat-menu>

<div class="sidebar-container__element">
  <div class="sidebar-list-item2 sidebar-list-item2_draggable" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <div class="sidebar-list-item2__row" [class.sidebar-list-item2__row_disabled]="submitLoading">
      <div class="sidebar-list-item2__row-left sidebar-list-item2__row-left_margin_l">
        <div class="sidebar-list-item2__drag sidebar-list-item2__drag_margin icon-dots_small"></div>
        <div
          *ngIf="!submitLoading"
          class="sidebar-list-item2__icon icon-canvas"
          [class.sidebar-list-item2__icon_purple-gradient]="true"
          [class.sidebar-list-item2__icon_size_l]="true"
        ></div>
        <app-loader-small
          *ngIf="submitLoading"
          class="sidebar-list-item2__icon"
          [class.sidebar-list-item2__icon_size_l]="true"
        ></app-loader-small>
      </div>
      <div class="sidebar-list-item2__row-main sidebar-list-item2__row-main_multiline">
        <div class="sidebar-list-item2__title">
          <ng-container *ngIf="control.controls.title.value | appIsSet">
            {{ control.controls.title.value }}
          </ng-container>
          <ng-container *ngIf="!(control.controls.title.value | appIsSet)">
            No title
          </ng-container>
        </div>
        <div class="sidebar-list-item2__subtitle">
          Custom component
        </div>
      </div>
      <div class="sidebar-list-item2__row-right">
        <div
          class="sidebar-list-item2__action sidebar-list-item2__action_hover sidebar-list-item2__item icon-more"
          [class.sidebar-list-item2__action_active]="item_dropdown_trigger.menuOpen"
          [matMenuTriggerFor]="item_dropdown"
          [matMenuTriggerData]="{ prepend: true }"
          #item_dropdown_trigger="matMenuTrigger"
          (mousedown)="$event.stopPropagation()"
        ></div>
      </div>
    </div>
  </div>
</div>

<div
  class="sidebar-summary-block"
  [class.sidebar-summary-block_active]="active"
  [class.sidebar-summary-block_disabled]="disabled && control.controls.layout.value"
  [class.sidebar-summary-block_draggable]="control.controls.layout.value"
  [class.sidebar-summary-block_clickable]="!control.controls.enabled.value"
  (click)="onClick($event)"
>
  <div class="sidebar-summary-block__header">
    <div class="sidebar-summary-block__header-left">
      <div class="sidebar-summary-block__header-drag icon-dots_small"></div>
    </div>

    <div class="sidebar-summary-block__header-main">
      <div
        class="sidebar-summary-block__header-title"
        [class.sidebar-summary-block__header-title_disabled]="!control.controls.layout.value"
      >
        {{ title }}
      </div>
    </div>

    <div class="sidebar-summary-block__header-right">
      <app-toggle
        *ngIf="!control.controls.enabled_input_enabled.value"
        class="sidebar-summary-block__header-right-item"
        [label]="'Enabled'"
        [labelPosition]="'right'"
        [orange]="true"
        [selected]="control.controls.enabled.value"
        (click)="$event.stopPropagation(); toggleEnabled()"
      ></app-toggle>

      <span
        class="sidebar-summary-block__button icon-function sidebar-summary-block__header-right-item"
        [class.sidebar-summary-block__button_active]="control.controls.enabled_input_enabled.value"
        [appTip]="!control.controls.enabled_input_enabled.value ? 'Conditional Enabled' : 'Disable Conditional Enabled'"
        [appTipOptions]="{ side: 'left' }"
        (click)="toggleEnabledInput()"
      ></span>
    </div>
  </div>

  <div class="sidebar-summary-block__content">
    <div
      *ngIf="control.controls.enabled_input_enabled.value"
      class="sidebar-summary-block__item sidebar-summary-block__item_interactive sidebar-summary-block__item_padding_s"
      (mousedown)="$event.stopPropagation()"
    >
      <div class="sidebar-summary-block__item-main">
        <div [style.width.%]="100">
          <div class="sidebar-summary-block__item-heading">Conditional Enabled:</div>
          <app-input-edit
            [itemForm]="control.controls.enabled_input"
            [context]="context"
            [staticValueDisabled]="true"
            [focusedInitial]="enabledInputToggled"
            [formulaPlaceholder]="''"
            [fill]="true"
            [small]="true"
          ></app-input-edit>
        </div>
      </div>
    </div>

    <app-menu-block-layout-overlay
      [layoutValue]="control.controls.layout.value"
      [colorValue]="control.getColor$() | async"
      [layoutGroups]="layoutGroups"
      [opened]="layoutOpened"
      [origin]="layout_trigger"
      (selectLayoutOption)="selectLayoutOption($event)"
      (close)="setLayoutOpened(false)"
    ></app-menu-block-layout-overlay>

    <div class="sidebar-summary-block__row">
      <div class="sidebar-summary-block__row-left">
        <div
          class="sidebar-summary-block__layout"
          [class.sidebar-summary-block__layout_clickable]="true"
          [class.sidebar-summary-block__layout_disabled]="!control.controls.enabled.value"
          [style.background-image]="
            '/assets/images/project-layouts/' +
              (layoutSelected ? layoutSelected.image : 'layout-double-menu-left') +
              '.svg' | appDeployCssUrl
          "
          (click)="setLayoutOpened(true)"
        ></div>
      </div>

      <div class="sidebar-summary-block__row-main">
        <div
          class="sidebar-summary-block__item"
          [class.sidebar-summary-block__item_clickable]="true"
          [class.sidebar-summary-block__item_disabled]="!control.controls.enabled.value"
          cdkOverlayOrigin
          #layout_trigger="cdkOverlayOrigin"
          (click)="setLayoutOpened(true)"
        >
          <div class="sidebar-summary-block__item-main">
            <div class="sidebar-summary-block__item-title sidebar-summary-block__item-element">
              Size & Position
            </div>
          </div>

          <div class="sidebar-summary-block__item-right">
            <div class="sidebar-summary-block__item-action icon-gear"></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="sidebar-summary-block__item"
      [class.sidebar-summary-block__item_clickable]="true"
      [class.sidebar-summary-block__item_disabled]="!control.controls.enabled.value"
      (click)="edit.emit()"
    >
      <div class="sidebar-summary-block__item-left">
        <div class="sidebar-summary-block__item-icon icon-fileds"></div>
      </div>

      <div class="sidebar-summary-block__item-main">
        <div class="sidebar-summary-block__item-title sidebar-summary-block__item-element">
          Menu items
        </div>
        <div class="sidebar-summary-block__item-additional sidebar-summary-block__item-element">
          {{ totalItems | i18nPlural: itemsPluralMap }}
        </div>
      </div>

      <div class="sidebar-summary-block__item-right">
        <div class="sidebar-summary-block__item-arrow icon-arrow_forward_2"></div>
      </div>
    </div>

    <ng-container *ngIf="control.controls.color">
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
        [cdkConnectedOverlayPositions]="colorPositions"
        [cdkConnectedOverlayOrigin]="color_trigger"
        [cdkConnectedOverlayOpen]="colorOpened"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayGrowAfterOpen]="true"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
        (backdropClick)="setColorOpened(false)"
        (detach)="setColorOpened(false)"
      >
        <div class="popover2 overlay-content overlay-content_animated">
          <div class="popover2__inner">
            <app-color-selector
              [current]="
                control.controls.color_custom_enabled.value
                  ? control.controls.color_custom.value
                  : control.controls.color.value
              "
              [colors]="colors"
              [customColors]="true"
              [allowEmpty]="true"
              (selected)="setColor($event)"
              (finish)="setColorOpened(false)"
            ></app-color-selector>
          </div>
        </div>
      </ng-template>

      <div
        class="sidebar-summary-block__item"
        [class.sidebar-summary-block__item_clickable]="true"
        [class.sidebar-summary-block__item_disabled]="!control.controls.enabled.value"
        cdkOverlayOrigin
        #color_trigger="cdkOverlayOrigin"
        (click)="setColorOpened(true)"
      >
        <div class="sidebar-summary-block__item-left">
          <div
            class="sidebar-summary-block__item-color"
            [class.sidebar-summary-block__item-color_transparent]="!colorSet"
            [style.background-color]="displayColor"
            [ngClass]="displayColorClass"
          ></div>
        </div>

        <div class="sidebar-summary-block__item-main">
          <div class="sidebar-summary-block__item-title">Color</div>
        </div>

        <div class="sidebar-summary-block__item-right">
          <div class="sidebar-summary-block__item-action icon-gear"></div>
        </div>
      </div>

      <div
        *ngIf="(currentUserStore.instance$ | async)?.isStaff"
        class="sidebar-summary-block__item staff-block staff-block_background"
        [class.sidebar-summary-block__item_clickable]="true"
        [class.sidebar-summary-block__item_disabled]="!control.controls.enabled.value"
        (click)="confirmRemove()"
      >
        <div class="sidebar-summary-block__item-left">
          <div class="sidebar-summary-block__item-icon icon-bin"></div>
        </div>

        <div class="sidebar-summary-block__item-main">
          <div class="sidebar-summary-block__item-title">Delete menu</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

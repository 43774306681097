<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  [executeEnabled]="workflowEditable"
  [executeLoading]="status == statuses.Executing"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
  (execute)="execute()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-tabs [initialTabIndex]="workflowEditable ? 0 : 1">
    <app-sidebar-tab [label]="'Settings'" [contentDisabled]="!workflowEditable">
      <app-sidebar-section [disabled]="!workflowEditable">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'transformation'" [additional]="'use others steps result'">
            <app-input-edit
              [itemForm]="form.controls.value"
              [context]="context"
              [contextElement]="contextElement"
              [staticValueDisabled]="true"
              [focusedInitial]="true"
              [placeholder]="'Value'"
              [fill]="true"
            ></app-input-edit>

            <ng-container description>
              Example: steps.query1.result[0].name
            </ng-container>
          </app-sidebar-field>
        </div>
      </app-sidebar-section>
    </app-sidebar-tab>

    <app-sidebar-tab
      [label]="workflowEditable ? 'Test result' : 'Run result'"
      [error]="stepRun?.error ? (workflowEditable ? 'Test failed' : 'Run failed') : undefined"
    >
      <div *ngIf="status == statuses.Executing" class="sidebar__element">
        <app-alert [title]="'Step is executing'" [secondary]="true">
          Please wait until execution is finished
        </app-alert>
      </div>

      <ng-container *ngIf="status != statuses.Executing">
        <div *ngIf="stepRun?.result | appIsSet" class="sidebar__element">
          <app-sidebar-field [label]="'step result'">
            <app-json-viewer [content]="stepRun.result"></app-json-viewer>
          </app-sidebar-field>
        </div>

        <ng-container *ngIf="stepRun?.error | appIsSet">
          <div class="sidebar__element">
            <app-alert [title]="'Step failed with the following error'" [danger]="true" [border]="true">
              Please check if the step and all inputs are configured correctly
            </app-alert>
          </div>

          <div class="sidebar__element">
            <app-json-viewer [content]="stepRun.error"></app-json-viewer>
          </div>
        </ng-container>

        <div *ngIf="!(stepRun?.result | appIsSet) && !(stepRun?.error | appIsSet)" class="sidebar__element">
          <app-alert [title]="'Click Test Step to get result'" [secondary]="true">
            <div>You can test step to check whether your Workflow is working correctly</div>
            <div style="margin-top: 4px;">
              <a href="javascript:void(0)" (click)="execute()">Test Step</a>
            </div>
          </app-alert>
        </div>
      </ng-container>
    </app-sidebar-tab>
  </app-sidebar-tabs>
</div>

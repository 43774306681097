<div class="layer-container">
  <div
    class="line-layer"
    [style.top.%]="fromY * 100"
    [style.left.%]="fromX * 100"
    [style.width.px]="length"
    [style.transform]="transform"
    [style.opacity]="layer.opacity"
    [style.cursor]="layer.getCursor() || null"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (mouseup)="onMouseUp()"
    (click)="onMouseClick($event)"
    #layer_element
  >
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="layer_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div *ngIf="editorContext" class="line-layer__fill" [style.margin-top.px]="-5" [style.height.px]="10"></div>

    <div
      *ngFor="let border of borders"
      class="line-layer__fill"
      [style.border-bottom]="border.border"
      [style.margin-top.px]="-border.thickness * 0.5"
      [style.height.px]="border.thickness"
      [style.box-shadow]="boxShadow"
    ></div>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container
      *appViewEditorViewportOverlay="{
        frame: layer.frame,
        translate: translate,
        transform: transformOverlay
      }"
    >
      <app-line-layer-bounds
        [frame]="layer.frame"
        [translate]="translate"
        [points]="layer.points"
        [fromX]="fromX"
        [fromY]="fromY"
        [length]="length"
        [transform]="transform"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [active]="customizing$ | async"
        [hover]="hover$ | async"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateLayer)="onLayerUpdate($event.frame, $event.points)"
      ></app-line-layer-bounds>
    </ng-container>
  </ng-container>
</div>

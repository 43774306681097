import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomViewSettings } from '@modules/customize';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  PermissionsGroup,
  ProjectGroupStore,
  ProjectPublicAccess,
  ProjectPublicAccessService
} from '@modules/projects';
import { capitalize, isSet } from '@shared';

@Component({
  selector: 'app-share-public',
  templateUrl: './share-public.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharePublicComponent implements OnInit, OnDestroy {
  @Input() hasDraftChanges = false;
  @Output() close = new EventEmitter<void>();

  publicAccess: ProjectPublicAccess;
  publicAccessLink: string;
  publicAccessIFrame: string;
  currentPage: CustomViewSettings;
  currentPageParams: Object;
  currentPageUrl: string;
  selectedGroup = new FormControl();
  loading = false;
  submitLoading = false;
  localize = localize;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private activatedRoute: ActivatedRoute,
    private projectPublicAccessService: ProjectPublicAccessService,
    private projectGroupStore: ProjectGroupStore,
    private dialogService: DialogService,
    private customizeService: CustomizeService,
    private notificationService: NotificationService,
    private appConfigService: AppConfigService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initCurrentPage();
  }

  ngOnDestroy(): void {}

  initCurrentPage() {
    this.customizeService.handlerInfo$.pipe(untilDestroyed(this)).subscribe(handlerInfo => {
      if (handlerInfo && handlerInfo.page && handlerInfo.page instanceof CustomViewSettings) {
        const project = this.currentProjectStore.instance;
        const environment = this.currentEnvironmentStore.instance;
        const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
        const url = [
          webBaseUrl,
          AdminMode.App,
          project.uniqueName,
          environment.uniqueName,
          ...handlerInfo.page.link
        ].join('/');
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const queryParamsStr = new URLSearchParams(queryParams).toString();

        this.currentPage = handlerInfo.page;
        this.currentPageParams = queryParams;
        this.currentPageUrl = [url, ...(isSet(queryParamsStr) ? [queryParamsStr] : [])].join('?');
        this.cd.markForCheck();
      } else {
        this.currentPage = undefined;
        this.currentPageParams = undefined;
        this.currentPageUrl = undefined;
        this.cd.markForCheck();
      }

      this.loadPublicAccess();
    });
  }

  loadPublicAccess() {
    this.loading = true;
    this.cd.markForCheck();

    this.projectPublicAccessService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          const pageUid = this.currentPage ? this.currentPage.uid : undefined;
          const instance = result.find(item => {
            return item.pageUid === pageUid && isEqual(item.pageParams, this.currentPageParams);
          });

          this.init(instance);
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  init(publicAccess: ProjectPublicAccess) {
    this.setPublicAccess(publicAccess);

    this.projectGroupStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        const group = (groups || []).find(item => {
          if (publicAccess && publicAccess.group) {
            return item.uid == publicAccess.group.uid;
          } else {
            return item.permissionsGroup == PermissionsGroup.Editor;
          }
        });

        if (group) {
          this.selectedGroup.patchValue(group);
        }

        this.selectedGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (this.publicAccess) {
            this.updatePublicAccess();
          }
        });
      });
  }

  setPublicAccess(value: ProjectPublicAccess) {
    this.publicAccess = value;
    this.publicAccessLink = this.getLink();
    this.publicAccessIFrame = this.getIFrame();
    this.cd.markForCheck();
  }

  getLink() {
    const project = this.currentProjectStore.instance;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
    return this.publicAccess ? this.publicAccess.link(webBaseUrl) : undefined;
  }

  getIFrame() {
    const link = this.getLink();
    return link
      ? `<iframe src="${link}?iframe=1" width="100%" height="640" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`
      : undefined;
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel)
          ? localize('{0} was copied to clipboard', [capitalize(contentLabel)])
          : undefined;
        this.notificationService.info(localize('Copied'), description);
      });
  }

  createPublicAccess() {
    if (!this.selectedGroup.value) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    const instance = new ProjectPublicAccess();

    instance.group = this.selectedGroup.value;
    instance.pageUid = this.currentPage ? this.currentPage.uid : undefined;
    instance.pageParams = this.currentPageParams;

    this.projectPublicAccessService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.setPublicAccess(result);
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessCreated, {
            Code: result.code,
            Source: 'share_popup'
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  updatePublicAccess() {
    if (!this.selectedGroup.value || !this.publicAccess) {
      return;
    }

    this.submitLoading = true;
    this.cd.markForCheck();

    const instance = cloneDeep(this.publicAccess) as ProjectPublicAccess;

    instance.group = this.selectedGroup.value;

    this.projectPublicAccessService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.setPublicAccess(result);
          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessUpdated, {
            Code: result.code,
            Source: 'share_popup'
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  deletePublicAccess() {
    const instance = this.publicAccess;

    this.submitLoading = true;
    this.cd.markForCheck();

    this.projectPublicAccessService
      .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.setPublicAccess(undefined);

          // this.notificationService.success('Deleted', 'Public access was successfully deleted from App');

          this.submitLoading = false;
          this.cd.markForCheck();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessDeleted, {
            Code: instance.code,
            Mode: this.mode,
            Source: 'share_popup'
          });
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  togglePublicAccess() {
    if (this.submitLoading) {
      return;
    }

    if (this.publicAccess) {
      this.deletePublicAccess();
    } else {
      this.createPublicAccess();
    }
  }
}

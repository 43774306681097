<app-external-fonts [fonts]="externalFonts"></app-external-fonts>

<div *ngIf="loading" class="loader loader_screen">
  <div class="loader__inner">
    <div class="spinner-double-pulse">
      <div class="spinner-double-pulse__pulse1"></div>
      <div class="spinner-double-pulse__pulse2"></div>
    </div>
  </div>
</div>

<app-error
  *ngIf="!loading && !state.projectCurrent"
  [title]="'App not found'"
  [fill]="true"
  [button]="'Go to your Apps'"
  [buttonLink]="['/']"
>
  Unfortunately, app was not found. <br />
  Maybe you don't have access to it or followed the wrong link. <br />
</app-error>

<app-error
  *ngIf="!loading && state.projectCurrent && !state.environmentCurrent"
  [title]="'Environment not found'"
  [fill]="true"
  [button]="'Go to your Apps'"
  [buttonLink]="['/']"
>
  Unfortunately, environment was not found. <br />
  Maybe you don't have access to it or followed the wrong link. <br />
</app-error>

<app-error
  *ngIf="!loading && state.projectCurrent && state.environmentCurrent && error"
  [title]="'Loading failed' | localize"
  [fill]="true"
  [button]="'Go to your Apps' | localize"
  [buttonLink]="['/']"
>
  {{ 'Unfortunately, app failed to load.' | localize }}
</app-error>

<app-error
  *ngIf="
    !loading &&
    state.projectCurrent &&
    state.environmentCurrent &&
    !error &&
    state.mode == adminModes.Builder &&
    !hasCustomizationPermission
  "
  [title]="'You don\'t have access to Visual Builder' | localize"
  [fill]="true"
  [button]="'Open Application' | localize"
  [buttonLink]="
    state.projectCurrent.getHomeLinkWithProtocol({
      environmentName: state.environmentCurrent.uniqueName,
      mode: adminModes.App
    }).link
  "
>
  Unfortunately, You don't have customization permission. <br />
  Maybe you have followed the wrong link, try to open Application mode. <br />
</app-error>

<app-feature-overview
  *ngIf="
    !loading &&
    !error &&
    state.projectCurrent &&
    state.environmentCurrent &&
    !error &&
    !(state.mode == adminModes.Builder && !hasCustomizationPermission) &&
    !state.usersFeatureActive &&
    state.mode == adminModes.App
  "
  [feature]="'permissions'"
  [button]="hasCustomizationPermission"
>
  <div data-subtitle>Paid Feature</div>
  <div data-title><strong>Multiple Users</strong> feature is not enabled in the current App plan</div>
  <div data-description>
    <ul>
      <li>Ask App administrator to upgrade plan</li>
    </ul>
  </div>
</app-feature-overview>

<ng-container
  *ngIf="
    !loading &&
    !error &&
    state.projectCurrent &&
    state.environmentCurrent &&
    !error &&
    !(state.mode == adminModes.Builder && !hasCustomizationPermission) &&
    !(!state.usersFeatureActive && state.mode == adminModes.App)
  "
>
  <div
    class="admin"
    [class.admin_screen-record]="recording"
    [class.admin_mobile-header]="!state.customizeEnabled && !state.iframe && isMobile"
    [class.admin_selection]="state.customizeSelection"
    [class.admin_customize]="state.customizeEnabled"
    [class.admin_customize_menu]="state.customizeMenuEnabled"
    [class.admin_customize_layout]="state.customizeLayoutEnabled"
    [class.admin_customize_default]="state.menuSection == menuSections.Default"
    [class.admin_customize_project]="state.menuSection == menuSections.Project"
    [class.admin_customize_bar_opened]="state.customizeBarOpened"
    [class.admin_menu]="state.menuOpened"
    [class.admin_animating]="state.customizeAnimating"
    [class.admin_iframe]="state.iframe"
    (click)="onBackgroundClick($event)"
  >
    <div
      class="admin__content"
      [class.admin__content_sidebar]="adminComponentService.contentWithSidebar$ | async"
      [class.admin__content_copyright]="state.iframe"
      data-selection-cancel="true"
      (click)="adminComponentService.contentClick.next($event)"
      (mousedown)="adminComponentService.contentMousedown.next($event)"
    >
      <app-menu-wrapper
        [title]="state.metaTitlePrimary"
        [desktopMenu]="!state.customizeEnabled && !state.iframe && !isMobile"
        [mobileMenu]="!state.customizeEnabled && !state.iframe && isMobile"
        [mobileOpened]="state.menuOpened"
      >
        <div class="admin__outlet">
          <router-outlet (activate)="onRouteActivate($event)"></router-outlet>
        </div>

        <ng-container data-menu-top-fixed-after>
          <app-subscription-past-due-toast
            *ngIf="
              (state.subscriptionPastDue &&
                state.environmentCurrent &&
                state.projectCurrent?.hasEnvironmentCustomizationPermission(state.environmentCurrent)) ||
                false;
              else no_subscription_past_due
            "
            [project]="state.projectCurrent"
            [shift]="state.mode == adminModes.Builder"
          ></app-subscription-past-due-toast>

          <ng-template #no_subscription_past_due>
            <app-email-verification-toast
              *ngIf="state.verificationBannerVisible && state.customizeEnabled; else verified_email"
              [shift]="state.mode == adminModes.Builder"
            ></app-email-verification-toast>

            <ng-template #verified_email>
              <app-demo-project-toast
                *ngIf="state.demoBannerEnabled"
                [project]="state.projectCurrent"
                [shift]="state.mode == adminModes.Builder"
              ></app-demo-project-toast>
            </ng-template>
          </ng-template>
        </ng-container>
      </app-menu-wrapper>

      <app-fixed-container></app-fixed-container>
      <!--      <div-->
      <!--        class="admin__content-overlay admin__content-overlay_button-area"-->
      <!--        (click)="switchToLayoutCustomize()"-->
      <!--        [class.admin__content-overlay_visible]="state.customizeMenuEnabled"-->
      <!--      >-->
      <!--        <a-->
      <!--          *ngIf="state.customizeLayoutCustomization; let layoutCustomization"-->
      <!--          href="javascript:void(0)"-->
      <!--          class="admin__content-overlay-button"-->
      <!--          [class.admin__content-overlay-button_visible]="state.customizeMenuEnabled"-->
      <!--        >-->
      <!--          <span class="admin__content-overlay-button-icon"><span class="icon-gear"></span></span>-->
      <!--          <span class="admin__content-overlay-button-label">{{ layoutCustomization.title }}</span>-->
      <!--        </a>-->
      <!--      </div>-->
    </div>

    <app-customize-menu *ngIf="state.mode == adminModes.Builder"></app-customize-menu>
  </div>

  <app-guide-focus></app-guide-focus>
  <app-guide-widget></app-guide-widget>
  <app-demo-widget></app-demo-widget>

  <a
    *ngIf="state.iframe && !state.projectCurrent?.isWhiteLabel()"
    class="copyright"
    href="https://www.jetadmin.io/"
    target="_blank"
  >
    <span class="copyright__logo"></span>
  </a>
</ng-container>

<app-integrations-code *ngIf="state.projectSettings" [projectSettings]="state.projectSettings"></app-integrations-code>

<app-custom-code
  *ngIf="state.projectSettings"
  [appendScripts]="state.projectSettings.appendScripts"
  [appendStyles]="state.projectSettings.appendStyles"
></app-custom-code>

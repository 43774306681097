import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { PageLoadingService } from '@common/page-loading';
import { getColorHexStr } from '@modules/colors';
import { MetaService } from '@modules/meta';

import { Domain } from '../domain';
import { DomainStore } from '../stores/domain.store';

@Injectable({ providedIn: 'root' })
export class DomainResolver implements Resolve<Domain> {
  constructor(
    private domainStore: DomainStore,
    private metaService: MetaService,
    private pageLoadingService: PageLoadingService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Domain> {
    return this.domainStore.getFirst().pipe(
      tap(domain => {
        if (domain) {
          this.metaService.defaultMetaData = {
            titleShort: domain.name,
            shareTitle: domain.title,
            description: domain.description,
            image: domain.image,
            icon: {
              default: domain.icon
            },
            color: getColorHexStr(domain.logoColor)
          };
        } else {
          this.metaService.setJetDefaultMetaData();
        }

        this.metaService.resetMeta();
        this.pageLoadingService.removeAppLoader();
      }),
      catchError(e => {
        this.pageLoadingService.removeAppLoader();

        return throwError(e);
      })
    );
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { OnInit } from '@angular/core';
import { createFormFieldFactory, ParameterValueArray } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet } from '@shared';
var ParameterValuesComponent = /** @class */ (function () {
    function ParameterValuesComponent() {
        this.customizable = false;
        this.createField = createFormFieldFactory();
        this.collapseContext = new SidebarCollapseContext();
    }
    ParameterValuesComponent.prototype.ngOnInit = function () { };
    ParameterValuesComponent.prototype.getDistinctName = function (baseName, template, startIndex) {
        if (template === void 0) { template = function (n, i) { return n + "_" + i; }; }
        if (startIndex === void 0) { startIndex = 1; }
        var names = this.control.controls.map(function (item) {
            var value = item.controls.name.value;
            return isSet(value) ? value : '';
        });
        var name;
        var index = startIndex;
        do {
            name = template(baseName, index);
            ++index;
        } while (names.find(function (item) { return item.toLowerCase() == name.toLowerCase(); }));
        return name;
    };
    ParameterValuesComponent.prototype.addItem = function () {
        var name = this.getDistinctName('param');
        this.lastAddedParameterControl = this.control.appendControl(undefined, {
            name: name
        });
    };
    ParameterValuesComponent.prototype.removeItem = function (control) {
        this.control.removeControl(control);
    };
    ParameterValuesComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
            this.control.updateValueAndValidity();
        }
    };
    ParameterValuesComponent.prototype.getItemParams = function (params) {
        return __assign({}, params, { classes: ['select_fill', 'input_fill'] });
    };
    return ParameterValuesComponent;
}());
export { ParameterValuesComponent };

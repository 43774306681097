<app-customize-bar-header [title]="'Language & Region'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize user default you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section
    [description]="'Customize how users from different regions will see your App by default'"
    [interactive]="!domain"
    (click)="onDomainRequiredClick()"
  >
    <div class="sidebar__element">
      <app-sidebar-field [label]="'default language'">
        <app-select
          [control]="projectDomainUpdateForm.controls.language"
          [placeholder]="projectDomainUpdateForm.languageDefaultOption?.name"
          [options]="projectDomainUpdateForm.languageOptions"
          [fill]="true"
        ></app-select>

        <ng-container description>
          You can submit new localizations or edits to the existing ones on our
          <a href="https://github.com/jet-admin/jet-localization" target="_blank">GitHub</a>.
        </ng-container>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'default timezone'">
        <app-select
          [control]="projectDomainUpdateForm.controls.timezone"
          [placeholder]="projectDomainUpdateForm.timezoneDefaultOption?.name"
          [options]="projectDomainUpdateForm.timezoneOptions"
          [fill]="true"
        ></app-select>

        <ng-container *ngIf="currentTimezoneOption && currentTimezoneTime$ | async as now" description>
          Current time in {{ currentTimezoneOption.data['name'] }} is <br />
          <strong>{{ now.format('ll hh:mm A') }}</strong>
        </ng-container>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Fractional part separator'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'number_fraction_separator',
              field: 'SelectField',
              params: { options: projectDomainUpdateForm.numberFractionSeparatorOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Thousands separator'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'number_thousands_separator',
              field: 'SelectField',
              params: { options: projectDomainUpdateForm.numberThousandsSeparatorOptions, classes: ['select_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { ActionItem } from '@modules/actions';
import { FieldOutput, Input, ParameterField } from '@modules/fields';
// TODO: Refactor import
import { CustomView } from '../../../../custom-views/data/custom-view';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var CustomElementItem = /** @class */ (function (_super) {
    __extends(CustomElementItem, _super);
    function CustomElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Custom;
        _this.parameters = [];
        _this.inputs = [];
        _this.outputs = [];
        _this.actions = [];
        return _this;
    }
    CustomElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.height = this.params['height'];
        this.source = this.params['source'];
        this.customView = this.params['custom_view'];
        if (this.params['custom_view_temporary']) {
            this.customViewTemporary = new CustomView().deserialize(this.params['custom_view_temporary']);
        }
        if (this.params['parameters']) {
            this.parameters = this.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (this.params['inputs']) {
            this.inputs = this.params['inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (this.params['outputs']) {
            this.outputs = this.params['outputs'].map(function (item) { return new FieldOutput().deserialize(item); });
        }
        if (this.params['actions']) {
            this.actions = this.params['actions'].map(function (item) { return ({
                name: item['name'],
                action: new ActionItem().deserialize(item['action'])
            }); });
        }
        return this;
    };
    CustomElementItem.prototype.serialize = function (fields) {
        this.params = {
            width: this.width,
            height: this.height,
            source: this.source
        };
        if (this.customView) {
            this.params['custom_view'] = this.customView;
        }
        else if (this.customViewTemporary) {
            this.params['custom_view'] = this.customViewTemporary.uniqueName;
        }
        this.params['parameters'] = this.parameters.map(function (item) { return item.serialize(); });
        this.params['inputs'] = this.inputs.map(function (item) { return item.serialize(); });
        this.params['outputs'] = this.outputs.map(function (item) { return item.serialize(); });
        this.params['actions'] = this.actions.map(function (item) { return ({ name: item.name, action: item.action.serialize() }); });
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    CustomElementItem.prototype.copy = function (element) {
        _super.prototype.copy.call(this, element);
        this.customView = element.customView;
        this.customViewTemporary = element.customViewTemporary;
    };
    Object.defineProperty(CustomElementItem.prototype, "typeStr", {
        get: function () {
            return 'custom created component';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomElementItem.prototype, "analyticsName", {
        get: function () {
            return 'custom';
        },
        enumerable: true,
        configurable: true
    });
    CustomElementItem.prototype.defaultName = function () {
        return 'Custom';
    };
    return CustomElementItem;
}(ElementItem));
export { CustomElementItem };
registerElementForType(ElementType.Custom, CustomElementItem);

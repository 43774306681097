<div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
  <div *ngFor="let control of form.form.controls; let i = index" cdkDrag class="sidebar-list__item">
    <app-actions-edit-item
      [actionItemClass]="options.actionItemClass"
      [action]="control.value"
      [context]="context"
      [contextElement]="contextElement"
      [defaultTitle]="'Action ' + (i + 1)"
      [draggable]="true"
      [pin]="pin"
      [pinned]="control.value['pinned']"
      [remove]="true"
      [error]="(form.form | appFormFieldErrors: i).length ? 'Not configured' : undefined"
      [classes]="classes"
      (open)="editItem(control, !control.value['protected'])"
      (pinClick)="toggleItemPinned(control)"
      (removeClick)="removeItem(control)"
    >
    </app-actions-edit-item>
  </div>
  <div *ngIf="!form.form.controls.length && emptyAction" class="sidebar-list__item">
    <div
      class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"
      [ngClass]="classes"
      (click)="addItem()"
    >
      <div *ngIf="true" class="sidebar-list-item__drag"></div>
      <div *ngIf="emptyIcon" class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span *ngIf="emptyIcon" class="icon button__icon" [ngClass]="['icon-' + emptyIcon]"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">{{ emptyAction }}</div>
        <div *ngIf="emptyActionValue" class="sidebar-list-item__description">{{ emptyActionValue }}</div>
      </div>
    </div>
  </div>
  <!--  <div *ngIf="!form.form.controls.length" class="sidebar__message">-->
  <!--    There are no actions-->
  <!--  </div>-->
  <div class="sidebar-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add Action</span>
    </a>
  </div>
</div>

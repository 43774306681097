/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./customize-bar-action-edit-element-action.component";
var styles_CustomizeBarActionEditElementActionComponent = [];
var RenderType_CustomizeBarActionEditElementActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditElementActionComponent, data: {} });
export { RenderType_CustomizeBarActionEditElementActionComponent as RenderType_CustomizeBarActionEditElementActionComponent };
function View_CustomizeBarActionEditElementActionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose"]))], null, null); }
function View_CustomizeBarActionEditElementActionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "input-tag input-tag_fill"], ["href", "javascript:void(0)"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "input-tag__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "input-tag__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "input-tag__control input-tag__control_root icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.setComponentAction(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = "Choose another action"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.elementActionLabel(_co.control.value))); _ck(_v, 3, 0, currVal_1); }); }
function View_CustomizeBarActionEditElementActionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [["class", "token-popup-list-item token-popup-list-item_orange"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "token-popup-list-item__inner token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "icon-arrow_backward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Back"]))], null, null); }
function View_CustomizeBarActionEditElementActionComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + _v.parent.parent.context.$implicit.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarActionEditElementActionComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setCurrentTokenGroup(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_8)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "token-popup-list-item__right token-popup-list-item__right_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "token-popup-list-item__icon icon-arrow_forward_2"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_CustomizeBarActionEditElementActionComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + _v.parent.parent.context.$implicit.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarActionEditElementActionComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setComponentAction(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_10)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "token-popup-list-item__right token-popup-list-item__right_wrap"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_CustomizeBarActionEditElementActionComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_7)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_9)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.children == null) ? null : _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = !((_v.context.$implicit.children == null) ? null : _v.context.$implicit.children.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomizeBarActionEditElementActionComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_6)), i0.ɵdid(8, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.children; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_0); }); }
function View_CustomizeBarActionEditElementActionComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "token-popup-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "token-popup-list-item__tip"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No components with actions"]))], null, null); }
function View_CustomizeBarActionEditElementActionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "popover2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "popover2__content"], ["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_4)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_5)), i0.ɵdid(7, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_11)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "popover2__arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbsToken.length; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.currentTokenGroup; _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.currentTokenGroup.length; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_CustomizeBarActionEditElementActionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["cdkOverlayOrigin", ""], ["class", "input input_fill input_container"], ["style", "cursor: pointer;"]], [[2, "input_with-placeholder", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setComponentActionOpen(true, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, [["chooseActionTrigger", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditElementActionComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 4, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setComponentActionOpen(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setComponentActionOpen(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CustomizeBarActionEditElementActionComponent_3)), i0.ɵdid(7, 671744, null, 0, i3.CdkConnectedOverlay, [i3.Overlay, i0.TemplateRef, i0.ViewContainerRef, i3.ɵc, [2, i4.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpod(8, { originX: 0, originY: 1, overlayX: 2, overlayY: 3 }), i0.ɵpad(9, 1), i0.ɵpad(10, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.control.value; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.control.value; _ck(_v, 5, 0, currVal_2); var currVal_3 = i0.ɵnov(_v, 1); var currVal_4 = _ck(_v, 9, 0, _ck(_v, 8, 0, "start", "center", "end", "center")); var currVal_5 = "popover2-backdrop"; var currVal_6 = _ck(_v, 10, 0, "overlay", "overlay_horizontal_center"); var currVal_7 = _co.componentActionPopoverOpened; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.control.value; _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomizeBarActionEditElementActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-element-action", [], null, null, null, View_CustomizeBarActionEditElementActionComponent_0, RenderType_CustomizeBarActionEditElementActionComponent)), i0.ɵdid(1, 245760, null, 0, i5.CustomizeBarActionEditElementActionComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditElementActionComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-element-action", i5.CustomizeBarActionEditElementActionComponent, View_CustomizeBarActionEditElementActionComponent_Host_0, { control: "control", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths" }, {}, []);
export { CustomizeBarActionEditElementActionComponentNgFactory as CustomizeBarActionEditElementActionComponentNgFactory };

import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, filter, map, skip, switchMap } from 'rxjs/operators';
import { ActionEditController, ActionQueryEditController } from '@modules/actions-components';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DraftChangesService } from '@modules/customize-utils';
import { CustomSelectComponent } from '@modules/field-components';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { ModelQueryEditController } from '@modules/model-components';
import { ModelDescriptionStore } from '@modules/model-queries';
import { editableQueryTypes, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';
import { isResourceActionCustom, ResourceControllerService } from '@modules/resources';
import { controlValue, isSet, tapOnce } from '@shared';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';
var CustomizeBarActionEditTypeQueryComponent = /** @class */ (function () {
    function CustomizeBarActionEditTypeQueryComponent(queryContext, queryBuilderService, actionEditController, actionQueryEditController, modelQueryEditController, modelDescriptionStore, resourceControllerService, draftChangesService, injector, analyticsService, cd) {
        this.queryContext = queryContext;
        this.queryBuilderService = queryBuilderService;
        this.actionEditController = actionEditController;
        this.actionQueryEditController = actionQueryEditController;
        this.modelQueryEditController = modelQueryEditController;
        this.modelDescriptionStore = modelDescriptionStore;
        this.resourceControllerService = resourceControllerService;
        this.draftChangesService = draftChangesService;
        this.injector = injector;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.setupOnCreate = false;
        this.showDraftChanges = false;
        this.createField = createFormFieldFactory();
        this.editableQueryTypes = editableQueryTypes;
        this.actionDescriptionQuery = false;
        this.hasDraftChanges = false;
        this.queryTypes = QueryType;
    }
    CustomizeBarActionEditTypeQueryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.queryContext.parametersControl = this.form.controls.action_params;
        this.updateTokens();
        this.form.controls.action_params.valueChanges
            .pipe(delay(0), untilDestroyed(this))
            .subscribe(function () { return _this.updateTokens(); });
        combineLatest(this.form.resource$(this.form.controls.resource), this.form.resourceActionQueryItems$(this.form.controls.resource), this.form.resourceBaseHttpQuery$(this.form.controls.resource), this.form.actionDescription$(this.form.controls.resource, this.form.controls.action))
            .pipe(auditTime(1000 / 60), tapOnce(function () {
            if (_this.setupOnCreate &&
                _this.form.value['action'] &&
                _this.form.value['action'].queryType &&
                _this.form.value['action'].queryType != QueryType.Simple) {
                _this.editCustomQuery();
            }
        }, true), untilDestroyed(this))
            .subscribe(function (result) {
            _this.resource = result[0], _this.resourceQueryActionItems = result[1], _this.resourceBaseHttpQuery = result[2], _this.actionDescription = result[3];
            _this.actionDescriptionQuery = isResourceActionCustom(_this.resource, _this.actionDescription);
            _this.cd.markForCheck();
        });
        controlValue(this.form.controls.query)
            .pipe(delay(0), map(function (query) { return (query ? query.queryType : undefined); }), distinctUntilChanged(), skip(1), untilDestroyed(this))
            .subscribe(function (queryType) {
            if (editableQueryTypes.includes(queryType)) {
                _this.editCustomQuery();
                _this.customSelectComponent.closeDropdown();
            }
        });
        this.form
            .resource$(this.form.controls.resource, true)
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                if (_this.customSelectComponent) {
                    _this.customSelectComponent.openDropdown();
                }
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.analyticsSource,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.form.resource$(this.form.controls.resource, true).pipe(skip(1)), this.form.controls.action.valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], action = _a[1];
            if (action) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.analyticsSource,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        if (this.showDraftChanges) {
            combineLatest(this.form.resource$(this.form.controls.resource), this.form.actionDescription$(this.form.controls.resource, this.form.controls.action))
                .pipe(switchMap(function (_a) {
                var resource = _a[0], actionDescription = _a[1];
                var modelId = actionDescription && isSet(actionDescription.resource) && isSet(actionDescription.model)
                    ? [actionDescription.resource, actionDescription.model].join('.')
                    : undefined;
                var action = actionDescription && isSet(actionDescription.resource) && isSet(actionDescription.name)
                    ? [actionDescription.resource, actionDescription.name].join('.')
                    : undefined;
                return _this.draftChangesService.getDraftChanges$({
                    resource: resource ? resource.uniqueName : undefined,
                    modelId: modelId,
                    action: action
                });
            }), untilDestroyed(this))
                .subscribe(function (changes) {
                _this.hasDraftChanges = changes.filter(function (item) { return item.count; }).length > 0;
                _this.cd.markForCheck();
            });
        }
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditTypeQueryComponent.prototype.updateTokens = function () {
        var tokens = [];
        tokens = tokens.concat([
            {
                name: undefined,
                label: 'Inputs',
                data: {
                    parameter_tokens: true
                },
                children: this.form.value['action_params']
                    .filter(function (item) { return item.name; })
                    .map(function (item) {
                    var fieldDescription = getFieldDescriptionByType(item.field);
                    return {
                        name: ['params', item.name].join('.'),
                        field: item.field,
                        params: item.params,
                        label: item.verboseName || item.name,
                        icon: fieldDescription.icon
                    };
                })
            }
        ]);
        this.queryContext.tokens = tokens;
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.editCustomQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (!this.resource) {
            return;
        }
        var query = this.form.controls.query.value;
        this.queryBuilderService
            .edit({
            injector: this.injector,
            queryClass: this.form.queryClass,
            queryTypes: [query.queryType],
            resource: this.resource,
            resourceType: this.resource.type,
            context: this.queryContext,
            query: query,
            parametersControl: this.form.controls.action_params,
            httpOptions: {
                baseQuery: this.resourceBaseHttpQuery,
                initialResultsSection: options.initialHttpResultsSection
            },
            sqlOptions: {
                initialResultsSection: options.initialSqlResultsSection
            },
            source: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.saved) {
                _this.onCustomQuerySaved(e.saved);
            }
        });
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.onCustomQuerySaved = function (event) {
        this.form.setQuery(event.query);
        this.form.setAutoDetectOutputs(event.query, { markAsDirty: true });
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.editSavedQuery = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (this.actionDescription.model) {
            return this.modelDescriptionStore
                .getDetailFirst(this.actionDescription.model)
                .pipe(switchMap(function (modelDescription) {
                if (!modelDescription) {
                    return of(undefined);
                }
                var querySettings = modelDescription.getQuerySettings(_this.actionDescription.modelAction);
                if (!querySettings) {
                    return of(undefined);
                }
                return _this.modelQueryEditController.editAutoAction({
                    resource: _this.resource,
                    modelDescription: modelDescription,
                    name: _this.actionDescription.modelAction,
                    query: querySettings.query,
                    queryClass: ModelDescriptionQuery,
                    queryContext: _this.queryContext,
                    parametersControl: _this.queryContext.parametersControl,
                    httpOptions: {
                        baseQuery: _this.resourceBaseHttpQuery,
                        initialResultsSection: options.initialHttpResultsSection
                    },
                    sqlOptions: {
                        initialResultsSection: options.initialSqlResultsSection
                    },
                    analyticsSource: _this.analyticsSource
                });
            }), filter(function (value) { return value; }), untilDestroyed(this))
                .subscribe(function (model) {
                var instance = cloneDeep(_this.form.controls.query.value);
                instance.updated = moment();
                _this.form.setQuery(instance);
            }, function (e) {
                console.error(e);
            });
        }
        else {
            var query = this.actionDescription.queryAction ? this.actionDescription.queryAction.query : undefined;
            var controller = this.resourceControllerService.get(this.resource.type);
            var queryType = query
                ? query.queryType
                : controller.supportedQueryTypes(this.form.queryClass).filter(function (item) { return item != QueryType.Simple; })[0];
            return this.actionQueryEditController
                .edit({
                resource: this.resource,
                actionType: this.form.controls.type.value,
                action: this.actionDescription,
                queryType: queryType,
                queryContext: this.queryContext,
                parametersControl: this.queryContext.parametersControl,
                baseQuery: this.resourceBaseHttpQuery,
                analyticsSource: this.analyticsSource
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (model) {
                var instance = cloneDeep(_this.form.controls.query.value);
                instance.updated = moment();
                _this.form.setQuery(instance);
            });
        }
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.editQuery = function (options) {
        if (options === void 0) { options = {}; }
        var option = this.form.value['action'];
        if (option && editableQueryTypes.includes(option.queryType)) {
            this.editCustomQuery(options);
        }
        else if (option && option.queryType == QueryType.Simple && this.actionDescriptionQuery) {
            this.editSavedQuery(options);
        }
    };
    CustomizeBarActionEditTypeQueryComponent.prototype.createAction = function () {
        var _this = this;
        this.actionEditController
            .createAction(this.resource, {
            type: this.form.controls.type.value,
            query: this.form.controls.query.value,
            parameters: this.form.controls.action_params.value
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.form.controls.action.patchValue({
                queryType: QueryType.Simple,
                action: result.name
            });
        });
    };
    return CustomizeBarActionEditTypeQueryComponent;
}());
export { CustomizeBarActionEditTypeQueryComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CurrentProjectStore, ProjectGroupService, ProjectGroupStore, ProjectService } from '@modules/projects';
var ProjectSignUpForm = /** @class */ (function (_super) {
    __extends(ProjectSignUpForm, _super);
    function ProjectSignUpForm(formUtils, projectService, currentProjectStore, projectGroupService, projectGroupStore) {
        var _this = _super.call(this, {
            enabled: new FormControl(false),
            default_group: new FormControl(null)
        }) || this;
        _this.formUtils = formUtils;
        _this.projectService = projectService;
        _this.currentProjectStore = currentProjectStore;
        _this.projectGroupService = projectGroupService;
        _this.projectGroupStore = projectGroupStore;
        _this.controls.default_group.valueChanges.subscribe(function (value) { return _this.controls.enabled.setValue(!!value); });
        return _this;
    }
    ProjectSignUpForm.prototype.ngOnDestroy = function () { };
    ProjectSignUpForm.prototype.init = function (project) {
        var _this = this;
        this.project = project;
        this.projectGroupService
            .get(this.currentProjectStore.instance.uniqueName, this.currentProjectStore.instance.defaultEnvironment
            ? this.currentProjectStore.instance.defaultEnvironment.uniqueName
            : undefined)
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            var group = groups ? groups.find(function (item) { return item.uid == project.defaultGroup; }) : null;
            _this.patchValue({
                enabled: !!group,
                default_group: group
            }, { emitEvent: false });
            _this.markAsPristine();
        });
        if (!project.features.isCustomerSignUpEnabled()) {
            this.controls.default_group.disable();
        }
    };
    ProjectSignUpForm.prototype.submit = function () {
        var _this = this;
        var value = this.value;
        var project = cloneDeep(this.project);
        project.defaultGroup = value['enabled'] && value['default_group'] ? value['default_group'].uid : null;
        return this.projectService.update(project.uniqueName, project, ['default_group']).pipe(tap(function (result) {
            _this.project = result;
        }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.markAsDirty();
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectSignUpForm;
}(FormGroup));
export { ProjectSignUpForm };

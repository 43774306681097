<app-add-step-menu
  [actionTypesEnabled]="actionTypesEnabled"
  (add)="stepAddBefore.emit($event)"
  #add_step_menu
></app-add-step-menu>

<div class="workflow-connection" [class.workflow-connection_first]="index == 0">
  <div
    *ngIf="stepHasPreviousConnection()"
    class="workflow-connection__bounds workflow-connection__bounds_interactive"
    [matMenuTriggerFor]="add_step_menu.menu"
    [matMenuTriggerData]="{ trigger: trigger }"
    #trigger="matMenuTrigger"
  >
    <div class="workflow-connection__dash">
      <div
        *ngIf="workflowEditable"
        class="workflow-connection__button icon-plus"
        [class.workflow-connection__button_active]="trigger.menuOpen"
        [ngClass]="[
          'workflow-connection__button_size_s',
          'workflow-connection__button_position_center',
          'workflow-connection__button_on-hover'
        ]"
      ></div>
    </div>
  </div>

  <div *ngIf="!stepHasPreviousConnection()" class="workflow-connection__space"></div>
</div>

<app-workflow-step-card
  *ngIf="!step.resultSteps"
  appDragHandle
  [title]="titles[0] | appCapitalize"
  [subtitle]="step.name"
  [icon]="displayIcon"
  [image]="displayImage"
  [indicatorLoader]="status == statuses.Executing"
  [indicatorIcon]="statusIcon"
  [indicatorColor]="statusColor"
  [active]="customizing$ | async"
  [interactive]="true"
  [loading]="loading"
  [connectorTop]="stepHasPreviousConnection()"
  [duplicateEnabled]="workflowEditable"
  [deleteEnabled]="workflowEditable"
  [executeEnabled]="workflowEditable"
  (click)="stepCustomize.emit({ contextElement: contextElement })"
  (stepDuplicate)="stepDuplicate.emit()"
  (stepDelete)="stepDelete.emit()"
  (stepExecute)="stepExecute.emit()"
></app-workflow-step-card>

<div *ngIf="step.resultSteps" class="workflow-fork">
  <div class="workflow-fork__root">
    <app-workflow-step-card
      appDragHandle
      [title]="titles[0] | appCapitalize"
      [subtitle]="step.name"
      [icon]="displayIcon"
      [image]="displayImage"
      [indicatorLoader]="status == statuses.Executing"
      [indicatorIcon]="statusIcon"
      [indicatorColor]="statusColor"
      [active]="customizing$ | async"
      [interactive]="true"
      [loading]="loading"
      [connectorTop]="stepHasPreviousConnection()"
      [duplicateEnabled]="workflowEditable"
      [deleteEnabled]="workflowEditable"
      [executeEnabled]="workflowEditable"
      (click)="stepCustomize.emit({ contextElement: contextElement })"
      (stepDuplicate)="stepDuplicate.emit()"
      (stepDelete)="stepDelete.emit()"
      (stepExecute)="stepExecute.emit()"
    ></app-workflow-step-card>
  </div>

  <div class="workflow-fork__children" #children>
    <app-action-workflow-step-branch
      [label]="'On success'"
      [icon]="'check_2'"
      [steps]="step.successSteps"
      [workflowEditable]="workflowEditable"
      [active]="customizing$ | async"
      [containerElement]="children"
      [context]="context"
      [actionTypesEnabled]="actionTypesEnabled"
      [analyticsSource]="analyticsSource"
      (stepClick)="stepCustomize.emit()"
    ></app-action-workflow-step-branch>

    <app-action-workflow-step-branch
      [label]="'On error'"
      [icon]="'close'"
      [steps]="step.errorSteps"
      [workflowEditable]="workflowEditable"
      [active]="customizing$ | async"
      [containerElement]="children"
      [context]="context"
      [actionTypesEnabled]="actionTypesEnabled"
      [analyticsSource]="analyticsSource"
      (stepClick)="stepCustomize.emit()"
    ></app-action-workflow-step-branch>
  </div>
</div>

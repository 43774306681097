/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./project-model-permissions.array";
import * as i2 from "../../../model-queries/stores/model-description.store";
import * as i3 from "./project-page-permissions.array";
import * as i4 from "../../../customize/stores/view-settings.store";
import * as i5 from "./project-group-change.form";
import * as i6 from "../../../../common/form-utils/services/form-utils";
import * as i7 from "../../../projects/stores/current-project.store";
import * as i8 from "../../../projects/stores/project-group.store";
import * as i9 from "../../../projects/stores/current-environment.store";
import * as i10 from "../../../projects/services/project-group/project-group.service";
import * as i11 from "../../../action-queries/stores/action.store";
import * as i12 from "../../../customize/services/page-permissions/page-permissions.service";
import * as i13 from "../../forms/project-user.form";
import * as i14 from "../../../projects/services/project-user/project-user.service";
import * as i15 from "@angular/forms";
import * as i16 from "./project-group-change.component";
import * as i17 from "../../../admin-mode/providers/route-admin-mode";
import * as i18 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i19 from "../../../../common/notifications/services/notification/notification.service";
import * as i20 from "../../../routing/services/routing/routing.service";
import * as i21 from "../../../tutorial/services/guide/guide.service";
import * as i22 from "../../../projects/services/project-invite/project-invite.service";
import * as i23 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i24 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i25 from "../../../users/stores/current-user.store";
var styles_ProjectGroupChangeComponent = [];
var RenderType_ProjectGroupChangeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectGroupChangeComponent, data: {} });
export { RenderType_ProjectGroupChangeComponent as RenderType_ProjectGroupChangeComponent };
export function View_ProjectGroupChangeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { pagePermissionActions: 1 })], null, null); }
export function View_ProjectGroupChangeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-project-group-change", [], null, null, null, View_ProjectGroupChangeComponent_0, RenderType_ProjectGroupChangeComponent)), i0.ɵprd(131584, null, i1.ProjectModelPermissionsArray, i1.ProjectModelPermissionsArray, [i2.ModelDescriptionStore]), i0.ɵprd(131584, null, i3.ProjectPagePermissionsArray, i3.ProjectPagePermissionsArray, [i4.ViewSettingsStore]), i0.ɵprd(131584, null, i5.ProjectGroupChangeForm, i5.ProjectGroupChangeForm, [i1.ProjectModelPermissionsArray, i3.ProjectPagePermissionsArray, i6.FormUtils, i7.CurrentProjectStore, i8.ProjectGroupStore, i9.CurrentEnvironmentStore, i10.ProjectGroupService, i11.ActionStore, i4.ViewSettingsStore, i12.PagePermissionsService]), i0.ɵprd(512, null, i13.ProjectUserForm, i13.ProjectUserForm, [i6.FormUtils, i7.CurrentProjectStore, i9.CurrentEnvironmentStore, i14.ProjectUserService, i15.FormBuilder]), i0.ɵdid(5, 245760, null, 0, i16.ProjectGroupChangeComponent, [i17.ROUTE_ADMIN_MODE, i10.ProjectGroupService, i8.ProjectGroupStore, i7.CurrentProjectStore, i9.CurrentEnvironmentStore, [2, i18.BasePopupComponent], i19.NotificationService, i5.ProjectGroupChangeForm, i20.RoutingService, i21.GuideService, i0.ChangeDetectorRef, i14.ProjectUserService, i22.ProjectInviteService, i23.DialogService, i24.UniversalAnalyticsService, i25.CurrentUserStore, i13.ProjectUserForm], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
var ProjectGroupChangeComponentNgFactory = i0.ɵccf("app-project-group-change", i16.ProjectGroupChangeComponent, View_ProjectGroupChangeComponent_Host_0, { group: "group", members: "members", autoSave: "autoSave", propertiesEnabled: "propertiesEnabled", deleteEnabled: "deleteEnabled" }, { saved: "saved", deleted: "deleted" }, []);
export { ProjectGroupChangeComponentNgFactory as ProjectGroupChangeComponentNgFactory };

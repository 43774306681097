/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../views-components/components/custom-element-view/custom-element-view.component.ngfactory";
import * as i2 from "../../../../customize/data/view-context";
import * as i3 from "../../../../projects/stores/current-environment.store";
import * as i4 from "../../../../views-components/components/custom-element-view/custom-element-view.component";
import * as i5 from "../../../../customize/data/view-context-element";
import * as i6 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i7 from "../../../../theme/services/theme/theme.service";
import * as i8 from "../../../../../common/notifications/services/notification/notification.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common";
import * as i11 from "./custom-menu-item.component";
import * as i12 from "../../../../menu/services/menu-item-action/menu-item-action.service";
import * as i13 from "../../../../custom-views/stores/custom-views.store";
var styles_CustomMenuItemComponent = [];
var RenderType_CustomMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomMenuItemComponent, data: {} });
export { RenderType_CustomMenuItemComponent as RenderType_CustomMenuItemComponent };
function View_CustomMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], [[4, "width", "px"], [4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-custom-element-view", [], null, null, null, i1.View_CustomElementViewComponent_0, i1.RenderType_CustomElementViewComponent)), i0.ɵprd(131584, null, i2.ViewContext, i2.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵprd(512, null, i4.parametersToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.actionsToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.stateToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵprd(512, null, i4.layerToken, i5.ViewContextElement, [i2.ViewContext]), i0.ɵdid(7, 770048, null, 0, i4.CustomElementViewComponent, [i2.ViewContext, i4.parametersToken, i4.actionsToken, i4.stateToken, i4.layerToken, i6.ActionControllerService, i7.ThemeService, i8.NotificationService, i9.DomSanitizer, i0.Injector, i0.ChangeDetectorRef], { view: [0, "view"], parameters: [1, "parameters"], params: [2, "params"], parentContext: [3, "parentContext"] }, null), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.view; var currVal_3 = _co.view.parameters; var currVal_4 = i0.ɵunv(_v, 7, 2, i0.ɵnov(_v, 8).transform(_co.viewParams$)); var currVal_5 = _co.context; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.horizontal ? _co.view.frame.width : null); var currVal_1 = (_co.horizontal ? 50 : _co.view.frame.height); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CustomMenuItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomMenuItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-menu-item", [], null, null, null, View_CustomMenuItemComponent_0, RenderType_CustomMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i11.CustomMenuItemComponent, [i12.MenuItemActionService, i13.CustomViewsStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomMenuItemComponentNgFactory = i0.ɵccf("app-custom-menu-item", i11.CustomMenuItemComponent, View_CustomMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { CustomMenuItemComponentNgFactory as CustomMenuItemComponentNgFactory };

import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, timer } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ApiService } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore, UserService } from '@modules/users';
import { addClass, controlValue, getOffset, getSize, getWindowScrollTop, isSet, removeClass } from '@shared';
import { ChangeEmailForm } from './change-email.form';
import { ChangePasswordForm } from './change-password.form';
import { UserEditForm } from './user-edit.form';
var UserEditComponent = /** @class */ (function () {
    function UserEditComponent(userEditForm, changePasswordForm, changeEmailForm, userService, notificationService, currentUserStore, apiService, routing, metaService, cd, activatedRoute) {
        this.userEditForm = userEditForm;
        this.changePasswordForm = changePasswordForm;
        this.changeEmailForm = changeEmailForm;
        this.userService = userService;
        this.notificationService = notificationService;
        this.currentUserStore = currentUserStore;
        this.apiService = apiService;
        this.routing = routing;
        this.metaService = metaService;
        this.cd = cd;
        this.activatedRoute = activatedRoute;
        this.loading = false;
        this.submitting = false;
        this.changingEmail = false;
        this.changingPassword = false;
        this.createField = createFormFieldFactory();
        this.externalAuth = false;
    }
    UserEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.set({ title: 'Update Profile' });
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.isWhiteLabel = data.domain && data.domain.whiteLabel;
            _this.cd.markForCheck();
        });
        this.loading = true;
        this.cd.markForCheck();
        this.enableTheme();
        this.currentUserStore
            .getFirst(true)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result) {
                _this.routing.navigateUnauthenticated(_this.activatedRoute.snapshot);
                return;
            }
            _this.user = result;
            _this.photo = _this.user.photo;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.userEditForm.init(_this.user);
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        combineLatest(this.changePasswordForm.controls.new_password.valueChanges, this.changePasswordForm.controls.repeat_password.valueChanges)
            .pipe(untilDestroyed(this))
            .pipe(debounceTime(100))
            .subscribe(function (v) {
            _this.setErrorPassword();
        });
        controlValue(this.userEditForm.controls.timezone)
            .pipe(untilDestroyed(this))
            .subscribe(function (timezone) {
            var option = isSet(timezone)
                ? _this.userEditForm.timezoneOptions.find(function (item) { return item.value == timezone; })
                : _this.userEditForm.timezoneDefaultOption;
            _this.currentTimezoneOption = option;
            _this.currentTimezoneTime$ = option
                ? timer(0, 1000).pipe(map(function () {
                    if (!option.data || !isSet(option.data['tz'])) {
                        return;
                    }
                    return moment().tz(option.data['tz']);
                }))
                : undefined;
            _this.cd.markForCheck();
        });
        this.externalAuth = isSet(this.apiService.getSSOUid()) || isSet(this.apiService.getSocialBackend());
        this.cd.markForCheck();
    };
    UserEditComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.updatePositionFooter();
        fromEvent(window, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(function (event) {
            _this.updatePositionFooter();
        });
    };
    UserEditComponent.prototype.ngOnDestroy = function () {
        this.disableTheme();
    };
    UserEditComponent.prototype.enableTheme = function () {
        addClass(document.body, 'theme_dark');
    };
    UserEditComponent.prototype.disableTheme = function () {
        removeClass(document.body, 'theme_dark');
    };
    UserEditComponent.prototype.onFileChange = function (el) {
        var _this = this;
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        this.userService
            .updatePhoto(file)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.photo = result.photo;
            _this.cd.markForCheck();
            _this.currentUserStore.getFirst(true);
            _this.notificationService.success('Saved', "<strong>Photo</strong> was successfully updated");
        }, function (error) {
            _this.notificationService.error('Error', error.errors[0]);
        });
    };
    UserEditComponent.prototype.deletePhoto = function () {
        var _this = this;
        this.photo = undefined;
        this.cd.markForCheck();
        this.userService
            .updatePhoto(undefined)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.currentUserStore.getFirst(true);
            _this.notificationService.success('Removed', "<strong>Photo</strong> was successfully deleted");
        }, function (error) {
            _this.notificationService.error('Error', error.errors[0]);
        });
    };
    UserEditComponent.prototype.submit = function () {
        var _this = this;
        this.submitting = true;
        this.cd.markForCheck();
        this.userEditForm
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.submitting = false;
            _this.cd.markForCheck();
            _this.currentUserStore.getFirst(true);
            _this.notificationService.success('Saved', "<strong>Current user</strong> was successfully updated");
            if (result.languageChanged) {
                setTimeout(function () { return window.location.reload(); }, 1000);
            }
        }, function () {
            _this.submitting = false;
            _this.cd.markForCheck();
            _this.notificationService.error('Error', "Saving <strong>Current user</strong> failed");
        });
    };
    UserEditComponent.prototype.back = function () {
        window.history.back();
    };
    Object.defineProperty(UserEditComponent.prototype, "validPassword", {
        get: function () {
            return (this.changePasswordForm.controls.new_password.value == this.changePasswordForm.controls.repeat_password.value &&
                this.changePasswordForm.controls.new_password.value &&
                this.changePasswordForm.controls.new_password.value.length > 5);
        },
        enumerable: true,
        configurable: true
    });
    UserEditComponent.prototype.setErrorPassword = function () {
        if (this.changePasswordForm.controls.new_password.value &&
            this.changePasswordForm.controls.new_password.value.length < 6) {
            this.changePasswordForm.controls.new_password.setErrors({ server: 'Short password' });
        }
        else if (this.changePasswordForm.controls.repeat_password.value &&
            this.changePasswordForm.controls.new_password.value &&
            this.changePasswordForm.controls.new_password.value !== this.changePasswordForm.controls.repeat_password.value) {
            this.changePasswordForm.controls.new_password.setErrors(null);
            this.changePasswordForm.controls.repeat_password.setErrors({ server: "Passwords don't match." });
        }
        else {
            this.changePasswordForm.controls.new_password.setErrors(null);
            this.changePasswordForm.controls.repeat_password.setErrors(null);
        }
    };
    UserEditComponent.prototype.changeEmail = function () {
        var _this = this;
        if (this.changingEmail) {
            return;
        }
        this.changingEmail = true;
        this.cd.markForCheck();
        this.changeEmailForm
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.changingEmail = false;
            _this.cd.markForCheck();
            _this.notificationService.success('Check your Email', "Confirmation Email was sent to <strong>" + result.sentConfirmationTo + "</strong>");
        }, function () {
            _this.changingEmail = false;
            _this.cd.markForCheck();
            _this.notificationService.error('Error', "Changing Email failed");
        });
    };
    UserEditComponent.prototype.changePassword = function () {
        var _this = this;
        if (this.changingPassword) {
            return;
        }
        this.changingPassword = true;
        this.cd.markForCheck();
        this.changePasswordForm
            .changePassword()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.user = result;
            _this.changingPassword = false;
            _this.cd.markForCheck();
            _this.notificationService.success('Saved', "<strong>Current password</strong> was successfully updated");
        }, function () {
            _this.changingPassword = false;
            _this.cd.markForCheck();
            _this.notificationService.error('Error', "Saving <strong>Current password</strong> failed");
        });
    };
    UserEditComponent.prototype.updatePositionFooter = function () {
        if (!this.containerFooter || !this.footer) {
            return;
        }
        var scroll = getWindowScrollTop();
        var position = getOffset(this.containerFooter.nativeElement);
        var size = getSize(this.containerFooter.nativeElement);
        if (scroll + window.innerHeight < position.top + size.height) {
            addClass(this.footer.nativeElement, 'workspace__footer_fixed');
        }
        else {
            removeClass(this.footer.nativeElement, 'workspace__footer_fixed');
        }
    };
    return UserEditComponent;
}());
export { UserEditComponent };

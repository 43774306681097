/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../dashboard-components/components/widgets/auto-widget/auto-widget.component.ngfactory";
import * as i3 from "../../../../dashboard-components/components/widgets/auto-widget/auto-widget.component";
import * as i4 from "./dashboard-item.component";
import * as i5 from "../../../../dashboard/stores/dashboard.store";
import * as i6 from "../../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i7 from "../../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i8 from "../../../../customize/services/customize/customize.service";
var styles_DashboardItemComponent = [];
var RenderType_DashboardItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardItemComponent, data: {} });
export { RenderType_DashboardItemComponent as RenderType_DashboardItemComponent };
function View_DashboardItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "dropdown__item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveRequested.emit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "dropdown__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_DashboardItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "dropdown__item dropdown__item_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "dropdown__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_DashboardItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardItemComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id != _co.dashboard.id); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.id == _co.dashboard.id); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DashboardItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "customize-selector__control dropdown-anchor dropdown-anchor_fill"], ["href", "javascript:void(0)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "customize-selector__icon icon-redo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "dropdown dropdown_position_bottom-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "dropdown__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DashboardItemComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.dashboardStore.instance$)); _ck(_v, 5, 0, currVal_0); }, null); }
function View_DashboardItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "customize-selector__control customize-selector__control_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "customize-selector__icon icon-redo"]], null, null, null, null, null))], null, null); }
export function View_DashboardItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), i0.ɵqud(402653184, 2, { handle: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 27, "div", [["class", "dashboard-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "dashboard-item__resize-placeholder"]], [[2, "dashboard-item__resize-placeholder_visible", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["root", 1]], null, 25, "div", [["class", "dashboard-item__content"]], [[2, "dashboard-item__content_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 24, "div", [["class", "element"]], [[2, "element_active", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.onHover(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.onHover(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "element__squares element__squares_top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "element__squares element__squares_bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 18, "div", [["class", "element__toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 17, "div", [["class", "customize-selector dashboard-item__draggable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 16, "div", [["class", "customize-selector__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "customize-selector__label handle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "customize-selector__drag icon-dots_small"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, [" ", " "])), (_l()(), i0.ɵeld(14, 0, null, null, 12, "div", [["class", "customize-selector__controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "customize-selector__control"], ["href", "javascript:void(0)"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editWidget() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "customize-selector__icon icon-gear"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "a", [["class", "customize-selector__control"], ["href", "javascript:void(0)"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyRequested.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 0, "span", [["class", "customize-selector__icon icon-copy"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DashboardItemComponent_1)), i0.ɵdid(20, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_DashboardItemComponent_5)), i0.ɵdid(23, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(25, 0, null, null, 1, "a", [["class", "customize-selector__control"], ["href", "javascript:void(0)"], ["tabindex", "-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteRequested.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 0, "span", [["class", "customize-selector__icon icon-bin"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "app-auto-widget", [], null, null, null, i2.View_AutoWidgetComponent_0, i2.RenderType_AutoWidgetComponent)), i0.ɵdid(28, 704512, null, 0, i3.AutoWidgetComponent, [], { dashboard: [0, "dashboard"], widget: [1, "widget"], context: [2, "context"] }, null), (_l()(), i0.ɵeld(29, 0, [[2, 0], ["handle", 1]], null, 0, "span", [["class", "dashboard-item__resize-handle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var tmp_4_0 = null; var currVal_4 = ((((tmp_4_0 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform(_co.dashboardStore.instance$))) == null) ? null : tmp_4_0.length) > 1); _ck(_v, 20, 0, currVal_4); var tmp_5_0 = null; var currVal_5 = ((((tmp_5_0 = i0.ɵunv(_v, 23, 0, i0.ɵnov(_v, 24).transform(_co.dashboardStore.instance$))) == null) ? null : tmp_5_0.length) <= 1); _ck(_v, 23, 0, currVal_5); var currVal_6 = _co.dashboard; var currVal_7 = _co.widget; var currVal_8 = _co.context; _ck(_v, 28, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resizing; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.resizing; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.active; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.widget.typeStr; _ck(_v, 13, 0, currVal_3); }); }
export function View_DashboardItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard-item", [], null, null, null, View_DashboardItemComponent_0, RenderType_DashboardItemComponent)), i0.ɵdid(1, 4964352, null, 0, i4.DashboardItemComponent, [i5.DashboardStore, i6.CustomizeBarService, i7.CustomizeBarContext, i0.ChangeDetectorRef, i8.CustomizeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardItemComponentNgFactory = i0.ɵccf("app-dashboard-item", i4.DashboardItemComponent, View_DashboardItemComponent_Host_0, { dashboard: "dashboard", widget: "widget", columnWidth: "columnWidth", rowHeight: "rowHeight", context: "context" }, { sizeChanged: "sizeChanged", sizeChangeConfirmed: "sizeChangeConfirmed", resizingChanged: "resizingChanged", updated: "updated", copyRequested: "copyRequested", moveRequested: "moveRequested", deleteRequested: "deleteRequested" }, []);
export { DashboardItemComponentNgFactory as DashboardItemComponentNgFactory };

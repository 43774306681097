import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { FieldType, ValueFormat } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { isSet } from '@shared';

import { ValueFormatControl } from '../../value-format-edit/value-format.control';

@Injectable()
export class CharFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;
  context: Object;
  modelDbFieldOptions = [];

  constructor(private fb: FormBuilder, private valueFormatControl: ValueFormatControl) {
    this.form = this.fb.group({
      wide: new FormControl(false),
      multiline: new FormControl(false),
      rows: new FormControl(8),
      value_format: valueFormatControl
    });
  }

  init(control: AbstractControl, context) {
    this.control = control;
    this.context = context;

    if (control.value) {
      const value = {};

      if (isSet(control.value['wide'])) {
        value['wide'] = control.value['wide'];
      }

      if (isSet(control.value['multiline'])) {
        value['multiline'] = control.value['multiline'];
      }

      if (isSet(control.value['rows'])) {
        value['rows'] = control.value['rows'];
      }

      this.form.patchValue(value, { emitEvent: false });
    }

    const valueFormat =
      control.value && control.value['value_format']
        ? new ValueFormat().deserialize(control.value['value_format'])
        : undefined;
    this.valueFormatControl.deserialize(valueFormat, { field: FieldType.Text });

    this.form.valueChanges.subscribe(() => this.submit());
    this.updateModelDbFieldOptions();
  }

  submit() {
    const data = this.form.value;
    const valueFormat = this.valueFormatControl.serialize();

    data['value_format'] = valueFormat ? valueFormat.serialize() : undefined;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }

  updateModelDbFieldOptions() {
    if (!this.context || !this.context['modelDescription']) {
      this.modelDbFieldOptions = [];
      return;
    }

    this.modelDbFieldOptions = (this.context['modelDescription'] as ModelDescription).dbFields.map(item => {
      return {
        name: item.name,
        value: item.verboseName
      };
    });
  }
}

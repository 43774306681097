/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../list-components/components/dot-pagination/dot-pagination.component.ngfactory";
import * as i2 from "../../../list-components/components/dot-pagination/dot-pagination.component";
import * as i3 from "./carousel-pagination.component";
var styles_CarouselPaginationComponent = [];
var RenderType_CarouselPaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CarouselPaginationComponent, data: {} });
export { RenderType_CarouselPaginationComponent as RenderType_CarouselPaginationComponent };
export function View_CarouselPaginationComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dot-pagination", [], null, [[null, "selectPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectPage" === en)) {
        var pd_0 = (_co.onPageSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DotPaginationComponent_0, i1.RenderType_DotPaginationComponent)), i0.ɵdid(1, 638976, null, 0, i2.DotPaginationComponent, [], { page: [0, "page"], pages: [1, "pages"], accentColor: [2, "accentColor"] }, { selectPage: "selectPage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page; var currVal_1 = _co.pages; var currVal_2 = _co.accentColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CarouselPaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-carousel-pagination", [], null, null, null, View_CarouselPaginationComponent_0, RenderType_CarouselPaginationComponent)), i0.ɵdid(1, 638976, null, 0, i3.CarouselPaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarouselPaginationComponentNgFactory = i0.ɵccf("app-carousel-pagination", i3.CarouselPaginationComponent, View_CarouselPaginationComponent_Host_0, { currentIndex: "currentIndex", items: "items", stepSize: "stepSize", displaySize: "displaySize", accentColor: "accentColor" }, { selectIndex: "selectIndex" }, []);
export { CarouselPaginationComponentNgFactory as CarouselPaginationComponentNgFactory };

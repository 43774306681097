import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  CustomizeCreatePopupOptions,
  CustomizeService,
  CustomViewSettings,
  PopupPosition,
  PopupSettings,
  ViewSettings
} from '@modules/customize';
import { isBodyHasChild, isElementHasChild, nodeListToArray, TypedChanges } from '@shared';

import { PopupSection } from '../custom-page-popups-menu-section/custom-page-popups-menu-section.component';

const customPagePopupsMenuClickEventProperty = '_customPagePopupsMenuClickEvent';

export function markCustomPagePopupsMenuClickEvent(clickEvent: MouseEvent) {
  clickEvent[customPagePopupsMenuClickEventProperty] = true;
}

export function isCustomPagePopupsMenuClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[customPagePopupsMenuClickEventProperty];
}

@Component({
  selector: 'app-custom-page-popups-menu',
  templateUrl: './custom-page-popups-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePopupsMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input() viewSettings: CustomViewSettings;
  @Input() popups: PopupSettings[] = [];
  @Input() openedPopup: PopupSettings;
  @Input() opened = false;
  @Output() openPopup = new EventEmitter<string>();
  @Output() closePopup = new EventEmitter<string>();
  @Output() createPopup = new EventEmitter<CustomizeCreatePopupOptions>();
  @Output() customizePopup = new EventEmitter<string>();
  @Output() movePopupToPage = new EventEmitter<{ uid: string; page: ViewSettings }>();
  @Output() duplicatePopup = new EventEmitter<string>();
  @Output() deletePopup = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();

  sections: PopupSection[] = [];
  blurSubscription: Subscription;

  constructor(public customizeService: CustomizeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomPagePopupsMenuComponent>): void {
    if (changes.viewSettings && !changes.viewSettings.firstChange) {
      this.close();
    }

    if (changes.popups) {
      this.initSections();
    }

    if (changes.opened && this.opened) {
      this.onOpened();
    } else if (changes.opened && !changes.opened.firstChange && !this.opened) {
      this.onClosed();
    }
  }

  initSections() {
    const modalPopups = this.popups.filter(item => !item.isSide() && !item.isOrigin());
    const sidePopups = this.popups.filter(item => item.isSide());
    const originPopups = this.popups.filter(item => item.isOrigin());

    this.sections = [
      {
        name: 'modal_popups',
        label: 'Modals',
        icon: 'windows',
        descriptionTitle: 'Modals',
        descriptionText: 'Opens above page content in the center of the screen',
        button: {
          label: 'Create Modal',
          icon: 'plus',
          handler: () => {
            this.createPopup.next({});
            this.close();
          }
        },
        items: modalPopups,
        itemsTotal: modalPopups.length,
        showEmpty: true
      },
      {
        name: 'side_popups',
        label: 'Slideouts',
        icon: 'versions',
        descriptionTitle: 'Slideouts',
        descriptionText: 'Slides outs from the side of the screen over page content',
        button: {
          label: 'Create Slideout',
          icon: 'plus',
          handler: () => {
            this.createPopup.next({ width: 400, defaultName: 'Slideout', position: PopupPosition.SideRight });
            this.close();
          }
        },
        items: sidePopups,
        itemsTotal: sidePopups.length,
        showEmpty: true
      },
      {
        name: 'side_popups',
        label: 'Dropdowns',
        icon: 'fileds',
        descriptionTitle: 'Dropdowns',
        descriptionText: 'Dropdown next to click origin over page content',
        button: {
          label: 'Create Dropdown',
          icon: 'plus',
          handler: () => {
            this.createPopup.next({
              width: 400,
              defaultName: 'Dropdown',
              position: PopupPosition.Origin,
              overlay: false
            });
            this.close();
          }
        },
        items: originPopups,
        itemsTotal: originPopups.length,
        showEmpty: true
      }
    ];
    this.cd.markForCheck();
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.cd.markForCheck();

    this.onClosed();
  }

  onOpened() {
    setTimeout(() => {
      this.blurSubscription = fromEvent<MouseEvent>(window.document, 'click')
        .pipe(
          filter(e => {
            if (isCustomPagePopupsMenuClickEvent(e)) {
              return false;
            }

            const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popups'));
            if (
              overlays.some(overlay => isElementHasChild(overlay, e.target as HTMLElement)) ||
              !isBodyHasChild(e.target as HTMLElement)
            ) {
              return false;
            }

            return true;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.close());
    }, 0);
  }

  onClosed() {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
      this.blurSubscription = undefined;
    }

    this.closed.emit();
  }

  markClickEvent(e: MouseEvent) {
    markCustomPagePopupsMenuClickEvent(e);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UniversalAnalyticsService } from '@modules/analytics';
import { CalendarSettings } from '@modules/customize';
import { Option } from '@modules/field-components';
import { FieldType } from '@modules/fields';
import { ResourceGeneratorResolver } from '@modules/resource-generators';

import { CustomizeBarListLayoutSettingsDataSourceComponent } from '../../customize-bar-list-layout-settings/customize-bar-list-layout-settings-data-source.component';
import { CustomizeBarCalendarSettingsForm } from '../customize-bar-calendar-settings.form';

@Component({
  selector: 'app-customize-bar-calendar-settings-data-source',
  templateUrl: './customize-bar-calendar-settings-data-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCalendarSettingsDataSourceComponent
  extends CustomizeBarListLayoutSettingsDataSourceComponent<CalendarSettings>
  implements OnInit, OnDestroy {
  @Input() form: CustomizeBarCalendarSettingsForm;

  dateColumnOptions$: Observable<Option<string>[]>;

  constructor(
    resourceGeneratorResolver: ResourceGeneratorResolver,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(resourceGeneratorResolver, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    this.dateColumnOptions$ = this.columnOptions$.pipe(
      map(options => {
        return options.map(option => {
          const column = this.form.controls.data_source.controls.columns.controls.find(
            control => control.controls.name.value == option.value
          );
          const isDateField = column ? column.controls.field.value == FieldType.DateTime : false;
          const fieldDescription = column ? column.getFieldDescription() : undefined;

          return {
            ...option,
            nameAdditional: !isDateField && fieldDescription ? `(${fieldDescription.label})` : undefined,
            disabled: !isDateField
          };
        });
      })
    );
  }
}

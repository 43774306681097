var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var QrCodeElementItem = /** @class */ (function (_super) {
    __extends(QrCodeElementItem, _super);
    function QrCodeElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.QrCode;
        _this.backgroundColor = 'white';
        return _this;
    }
    QrCodeElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.height = this.params['height'];
        this.fillColor = this.params['fill_color'];
        this.backgroundColor = this.params['background_color'];
        if (this.params['value']) {
            this.value = new Input().deserialize(this.params['value']);
        }
        return this;
    };
    QrCodeElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['value'] = this.value ? this.value.serialize() : null;
        this.params['width'] = this.width;
        this.params['height'] = this.height;
        this.params['fill_color'] = this.fillColor;
        this.params['background_color'] = this.backgroundColor;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(QrCodeElementItem.prototype, "typeStr", {
        get: function () {
            return 'QR code';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QrCodeElementItem.prototype, "analyticsName", {
        get: function () {
            return 'qr_code';
        },
        enumerable: true,
        configurable: true
    });
    QrCodeElementItem.prototype.defaultName = function () {
        return 'QR code';
    };
    return QrCodeElementItem;
}(ElementItem));
export { QrCodeElementItem };
registerElementForType(ElementType.QrCode, QrCodeElementItem);

import { AfterViewChecked, Directive, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { Frame, FrameTranslate, Translate } from '@modules/views';
import { generateHash, TypedChanges } from '@shared';

import { ViewEditorViewportOverlayService } from '../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service';

interface Options {
  enabled?: boolean;
  frame?: Frame;
  translate?: Translate;
  transform?: string;
  interactive?: boolean;
  order?: any;
}

@Directive({
  selector: '[appViewEditorViewportOverlay]'
})
export class ViewEditorViewportOverlayDirective implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @Input('appViewEditorViewportOverlay') options: Options | null;

  id = generateHash(16);

  constructor(private tpl: TemplateRef<any>, private viewportOverlayService: ViewEditorViewportOverlayService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.viewportOverlayService.removeTemplate(this.id);
  }

  ngOnChanges(changes: TypedChanges<ViewEditorViewportOverlayDirective>): void {
    if (changes.options) {
      this.updateTemplate();
    }
  }

  ngAfterViewChecked(): void {
    this.viewportOverlayService.markTemplateChecked(this.id);
  }

  getOptions(): Options {
    return {
      enabled: true,
      ...this.options
    };
  }

  updateTemplate() {
    const options = this.getOptions();

    if (options.enabled) {
      this.viewportOverlayService.setTemplate(this.id, this.tpl, {
        frame: options.frame,
        translate: options.translate,
        transform: options.transform,
        interactive: options.interactive,
        order: options.order
      });
    } else {
      this.viewportOverlayService.removeTemplate(this.id);
    }
  }
}

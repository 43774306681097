import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, delayWhen, filter } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';

import {
  ModelDataType,
  processElementItemResources,
  ViewContext,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { QueryBuilderContext } from '@modules/queries-components';
import { CurrentUserStore } from '@modules/users';
import { generateUUID } from '@shared';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarModelDataArray } from './customize-bar-model-data.array';
import { CustomizeBarModelDataControl } from './customize-bar-model-data.control';
import { CustomizeBarPageEditQueryComponent } from './customize-bar-page-edit-query/customize-bar-page-edit-query.component';
import { CustomizeBarPageEditForm } from './customize-bar-page-edit.form';

@Component({
  selector: 'app-customize-bar-page-edit',
  templateUrl: './customize-bar-page-edit.component.html',
  providers: [CustomizeBarModelDataArray, CustomizeBarPageEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarPageEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() viewSettings: ViewSettings;
  @Input() context: ViewContext;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  loading = false;
  modelDescription: ModelDescription;
  analyticsObject = 'page';
  sourceTemplateId: string;
  modelDataTypes = ModelDataType;

  constructor(
    public form: CustomizeBarPageEditForm,
    private modelDescriptionStore: ModelDescriptionStore,
    private dialogService: DialogService,
    public currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private notificationService: NotificationService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.viewSettings);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      const result = this.form.submit();
      this.emitUpdate(result);
    });

    const modelId = [this.viewSettings.resource, this.viewSettings.model].join('.');

    this.modelDescriptionStore
      .getDetailFirst(modelId)
      .pipe(untilDestroyed(this))
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
        this.cd.markForCheck();
      });

    this.sourceTemplateId =
      this.viewSettings && this.viewSettings.sourceTemplate != undefined
        ? String(this.viewSettings.sourceTemplate)
        : '';
  }

  ngOnDestroy(): void {}

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: ViewSettings, submit = false) {
    if (this.viewSettings) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete page ${this.viewSettings.name}?`,
        style: 'orange'
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.event.emit({ type: CustomizeBarEditEventType.Deleted });
        this.close();
      });
  }

  get title() {
    if (this.form.controls.name.value) {
      return this.form.controls.name.value;
    } else if (this.viewSettings) {
      return 'Page';
    } else if (!this.viewSettings) {
      return 'New Page';
    }
  }

  editQuery(control: CustomizeBarModelDataControl, index: number) {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarPageEditQueryComponent,
      inputs: {
        control: control,
        parentForm: this.form,
        context: this.context,
        object: this.analyticsObject
      },
      outputs: {
        delete: [
          () => {
            this.removeQuery(control);
          }
        ]
      }
    });
  }

  removeQuery(control: CustomizeBarModelDataControl) {
    this.form.controls.queries.removeControl(control);
  }

  addQuery(type: ModelDataType) {
    const control = this.form.controls.queries.appendControl({
      value: {
        type: type,
        name: `Query ${this.form.controls.queries.length + 1}`
      }
    });
    this.editQuery(control, this.form.controls.queries.length - 1);
  }

  getQueryTitle(control: CustomizeBarModelDataControl, index: number) {
    if (control.controls.name.value) {
      return control.controls.name.value;
    } else {
      return `Query ${index + 1}`;
    }
  }

  dragDrop(event: CdkDragDrop<CustomizeBarModelDataControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.queries.controls, event.previousIndex, event.currentIndex);
      this.form.controls.queries.updateValueAndValidity();
    }
  }

  updateTemplateInfo() {
    const page = cloneDeep(this.viewSettings);
    const pageResources: { [key: string]: Resource } = {};
    const processResource = (resourceName: string): string => {
      const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);

      if (resource) {
        pageResources[resourceName] = resource;
      }

      return resourceName;
    };

    processElementItemResources(page, processResource);

    page.sourceTemplate = this.sourceTemplateId ? parseInt(this.sourceTemplateId, 10) : undefined;
    page.templateInstanceId = generateUUID();
    page.usedResources = values(pageResources).map(item => {
      return {
        type: item.type,
        typeItem: item.typeItem,
        name: item.uniqueName
      };
    });

    this.viewSettingsService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, page)
      .pipe(
        delayWhen(() => this.viewSettingsStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.notificationService.success('Updated', 'Template info updated successfully');
      });
  }
}

<div class="collapse-container">
  <app-collapse-section
    class="collapse-container__item"
    [titleInput]="element.titleInput"
    [icon]="element.icon"
    [children]="element.children"
    [tooltip]="element.tooltip"
    [collapseIndicatorPosition]="element.collapseIndicatorPosition"
    [element]="element"
    [opened]="opened"
    [first]="true"
    [last]="true"
    [context]="context"
    (toggleOpened)="toggleOpened()"
  ></app-collapse-section>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/star/star.component.ngfactory";
import * as i2 from "../../../../ui/components/star/star.component";
import * as i3 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./custom-view-template-rating.component";
import * as i6 from "../../../views/services/custom-view-template/custom-view-template.service";
import * as i7 from "../../../../common/notifications/services/notification/notification.service";
var styles_CustomViewTemplateRatingComponent = [];
var RenderType_CustomViewTemplateRatingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewTemplateRatingComponent, data: {} });
export { RenderType_CustomViewTemplateRatingComponent as RenderType_CustomViewTemplateRatingComponent };
function View_CustomViewTemplateRatingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "rating__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "app-star", [], null, null, null, i1.View_StarComponent_0, i1.RenderType_StarComponent)), i0.ɵdid(2, 770048, null, 0, i2.StarComponent, [], { leftActive: [0, "leftActive"], rightActive: [1, "rightActive"] }, null), i0.ɵppd(3, 1), i0.ɵppd(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "rating__item-left"]], null, [[null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.setHoverValue(_v.context.$implicit, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "rating__item-right"]], null, [[null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.setHoverValue(_v.context.$implicit, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isRatingValueGte((i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.displayValue)) ? _co.displayValue : _co.currentValue), _v.context.$implicit, true); var currVal_1 = _co.isRatingValueGte((i0.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.displayValue)) ? _co.displayValue : _co.currentValue), _v.context.$implicit, false); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CustomViewTemplateRatingComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "rating rating_interactive"]], null, [[null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.clearHoverValue() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setCurrentValue(_co.hoverValue) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomViewTemplateRatingComponent_1)), i0.ɵdid(3, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(4, 5)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, 0, 1, 2, 3, 4); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CustomViewTemplateRatingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-template-rating", [], null, null, null, View_CustomViewTemplateRatingComponent_0, RenderType_CustomViewTemplateRatingComponent)), i0.ɵdid(1, 770048, null, 0, i5.CustomViewTemplateRatingComponent, [i6.CustomViewTemplateService, i7.NotificationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewTemplateRatingComponentNgFactory = i0.ɵccf("app-custom-view-template-rating", i5.CustomViewTemplateRatingComponent, View_CustomViewTemplateRatingComponent_Host_0, { template: "template" }, {}, []);
export { CustomViewTemplateRatingComponentNgFactory as CustomViewTemplateRatingComponentNgFactory };

import { FormControl, FormGroup } from '@angular/forms';

import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Shadow, ShadowPosition } from '@modules/views';
import { isSet } from '@shared';

import { ColorControl } from './color.control';

export class ShadowControl extends FormGroup {
  instance: Shadow;

  controls: {
    color: ColorControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
    position: FormControl;
    offset_x: FormControl;
    offset_y: FormControl;
    blur_radius: FormControl;
    spread_radius: FormControl;
    enabled: FormControl;
    enabled_input: FieldInputControl;
  };

  positionOptions: Option<ShadowPosition>[] = [
    {
      value: ShadowPosition.Outside,
      name: 'Outside'
    },
    {
      value: ShadowPosition.Inside,
      name: 'Inside'
    }
  ];

  constructor(state: Partial<Shadow> = {}) {
    super({
      color: new ColorControl(isSet(state.color) ? state.color : {}),
      color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
      color_input: new FieldInputControl({ name: 'value' }),
      position: new FormControl(isSet(state.position) ? state.position : ShadowPosition.Outside),
      offset_x: new FormControl(isSet(state.offsetX) ? state.offsetX : 0),
      offset_y: new FormControl(isSet(state.offsetY) ? state.offsetY : 2),
      blur_radius: new FormControl(isSet(state.blurRadius) ? state.blurRadius : 4),
      spread_radius: new FormControl(isSet(state.spreadRadius) ? state.spreadRadius : 0),
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
      enabled_input: new FieldInputControl({ name: 'value' })
    });
  }

  deserialize(value: Shadow, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    if (value.color) {
      this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
    }

    this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
    this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });

    this.controls.position.patchValue(value.position || ShadowPosition.Outside, { emitEvent: options.emitEvent });
    this.controls.offset_x.patchValue(value.offsetX, { emitEvent: options.emitEvent });
    this.controls.offset_y.patchValue(value.offsetY, { emitEvent: options.emitEvent });
    this.controls.blur_radius.patchValue(value.blurRadius, { emitEvent: options.emitEvent });
    this.controls.spread_radius.patchValue(value.spreadRadius, { emitEvent: options.emitEvent });
    this.controls.enabled.patchValue(value.enabled, { emitEvent: options.emitEvent });
    this.controls.enabled_input.patchValue(value.enabledInput ? value.enabledInput.serialize() : {});
  }

  getInstance(instance?: Shadow): Shadow {
    if (!instance) {
      instance = new Shadow();
    }

    if (this.controls.color_input_enabled.value) {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = this.controls.color_input.value
        ? new Input().deserialize(this.controls.color_input.value)
        : undefined;
    } else {
      instance.color = this.controls.color.getInstance(instance.color);
      instance.colorInput = undefined;
    }

    instance.color = this.controls.color.getInstance(instance.color);
    instance.position = this.controls.position.value;
    instance.offsetX = this.controls.offset_x.value;
    instance.offsetY = this.controls.offset_y.value;
    instance.blurRadius = this.controls.blur_radius.value;
    instance.spreadRadius = this.controls.spread_radius.value;
    instance.enabled = this.controls.enabled.value;
    instance.enabledInput = this.controls.enabled_input.value
      ? new Input().deserialize(this.controls.enabled_input.value)
      : undefined;

    return instance;
  }

  serialize(): Shadow {
    return this.getInstance(this.instance);
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { NO_PAGINATION_PARAM, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { JET_APP_RESOURCE } from '@modules/projects';
import { indent, indentLines, isSet } from '@shared';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';
var ModelApiGetQueryComponent = /** @class */ (function (_super) {
    __extends(ModelApiGetQueryComponent, _super);
    function ModelApiGetQueryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pagination = true;
        _this.parameters = [];
        return _this;
    }
    ModelApiGetQueryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchModel();
        this.updateParameters();
    };
    ModelApiGetQueryComponent.prototype.updateParameters = function () {
        this.parameters = this.getParameters();
        this.cd.markForCheck();
    };
    ModelApiGetQueryComponent.prototype.updateCurl = function () {
        this.curl = this.getCurlRequest({
            url: this.getAbsoluteApiUrl()
        });
        this.cd.markForCheck();
    };
    ModelApiGetQueryComponent.prototype.getParametersAuto = function () {
        var _this = this;
        var result = [];
        if (modelDescriptionHasAutoParameters(this.resource, this.modelDescription) &&
            this.resource.uniqueName != JET_APP_RESOURCE) {
            result.push.apply(result, this.modelDescription.dbFields.map(function (field) {
                var mapLookup = function (lookupInfo) {
                    var lookup = lookupInfo.type;
                    return {
                        type: ApiParameterType.Query,
                        name: _this.getFieldParamName(field.name, lookup.lookup),
                        fieldTypeLabel: lookupInfo.field,
                        fieldTypeDescription: {
                            field: field.field,
                            params: field.params
                        },
                        description: "Return results where the " + field.verboseName + " field " + lookup.verboseName + " this value."
                    };
                };
                var main = field.fieldDescription.lookups
                    .filter(function (lookupInfo) { return !lookupInfo.type.lookup; })
                    .map(function (lookupInfo) { return mapLookup(lookupInfo); })[0];
                var children = field.fieldDescription.lookups
                    .filter(function (lookupInfo) { return lookupInfo.type.lookup; })
                    .map(function (lookupInfo) { return mapLookup(lookupInfo); });
                var first = main ? main : children[0];
                var others = main ? children : children.slice(1);
                return __assign({}, first, (others.length ? { children: others } : {}));
            }).concat([
                {
                    type: ApiParameterType.Query,
                    name: SEARCH_PARAM,
                    fieldTypeLabel: 'string',
                    description: "Search results TEXT fields with the specified value. Search is case insensitive."
                },
                {
                    type: ApiParameterType.Query,
                    name: ORDER_BY_PARAM,
                    fieldTypeLabel: 'string',
                    description: "Sorts returned objects by the specified field name. If you want to sort descending add \"-\" before the field name."
                }
            ]));
        }
        else {
            result.push.apply(result, this.modelDescription.getParameters.map(function (parameter) {
                return fieldToApiParameter(parameter, ApiParameterType.Query);
            }));
        }
        if (this.pagination) {
            result.push.apply(result, [
                {
                    type: ApiParameterType.Query,
                    name: PAGE_PARAM,
                    fieldTypeLabel: 'number',
                    description: "A page number for use in pagination. All results are split into pages with maximum " + PER_PAGE_PARAM + " items."
                },
                {
                    type: ApiParameterType.Query,
                    name: PER_PAGE_PARAM,
                    fieldTypeLabel: 'number',
                    description: "A limit on the number of objects to be returned. Limit can range between 1 and 10 000, and the default is 25."
                },
                {
                    type: ApiParameterType.Query,
                    name: NO_PAGINATION_PARAM,
                    fieldTypeLabel: 'boolean',
                    description: "Disables pagination and returns all objects (maximum 10 000)"
                }
            ]);
        }
        return result;
    };
    ModelApiGetQueryComponent.prototype.getQueryUrl = function () {
        return "";
    };
    ModelApiGetQueryComponent.prototype.getResponseData = function () {
        var model = this.model || this.getDefaultModel();
        var modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);
        if (this.pagination) {
            var modelCount = isSet(this.modelCount) ? this.modelCount : 5;
            var modelNext = isSet(this.modelNext) ? this.modelNext : this.getAbsoluteApiUrl() + '?page=2';
            return [
                '{',
                indent("\"count\": " + JSON.stringify(modelCount) + ",", this.indentSize),
                indent("\"next\": " + JSON.stringify(modelNext) + ",", this.indentSize),
                indent("\"previous\": null,", this.indentSize),
                indent("\"results\": [", this.indentSize),
                indentLines(JSON.stringify(modelData, null, this.indentSize), this.indentSize * 2) + ',',
                indent("{...},", this.indentSize * 2),
                indent("{...}", this.indentSize * 2),
                indent("]", this.indentSize),
                '}'
            ].join('\n');
        }
        else {
            return ['[', indentLines(JSON.stringify(modelData, null, this.indentSize), this.indentSize), ']'].join('\n');
        }
    };
    return ModelApiGetQueryComponent;
}(ModelApiBaseQueryComponent));
export { ModelApiGetQueryComponent };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { FormulaTokenFunction } from '../../data/formula-token-function';

@Component({
  selector: 'app-view-context-token-popover-function',
  templateUrl: './view-context-token-popover-function.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverFunctionComponent implements OnInit {
  @Input() function: FormulaTokenFunction;

  constructor() {}

  ngOnInit() {}
}

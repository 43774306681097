import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxSelectModule } from 'ng-gxselect';

import { LocalizeModule } from '@common/localize';
import { ActivitiesComponentsModule } from '@modules/activities-components';
import { FeaturesModule } from '@modules/features';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { UserActivitiesItemComponent } from './components/user-activities-item/user-activities-item.component';
import { UserActivitiesComponent } from './components/user-activities/user-activities.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    ModelQueriesModule,
    SharedModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    LocalizeModule,
    FormsModule,
    NgGxDatepickerModule,
    ActivitiesComponentsModule,
    FeaturesModule,
    OverlayModule
  ],
  declarations: [UserActivitiesComponent, UserActivitiesItemComponent],
  exports: [UserActivitiesComponent]
})
export class ActivitiesRoutesModule {}

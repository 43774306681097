var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var PointTranslate = /** @class */ (function () {
    function PointTranslate(options) {
        this.translate = { x: 0, y: 0 };
        this.point = options.point;
        this.translate = __assign({ x: 0, y: 0 }, options.translate);
    }
    Object.defineProperty(PointTranslate.prototype, "x", {
        get: function () {
            return this.point.x + this.translate.x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PointTranslate.prototype, "y", {
        get: function () {
            return this.point.y + this.translate.y;
        },
        enumerable: true,
        configurable: true
    });
    return PointTranslate;
}());
export { PointTranslate };

<div class="sidebar__element">
  <app-sidebar-field [label]="'enable group collapse'" [layoutLeft]="true">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'group_collapse',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'show group items count'" [layoutLeft]="true">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'group_counter',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar-section-border sidebar-section-border_margin_sm"></div>

<div class="sidebar__list">
  <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDrop($event)">
    <div
      *ngFor="let group of form.controls.groups.controls; let i = index"
      [formGroup]="group"
      cdkDrag
      class="sidebar-list__item"
    >
      <app-sidebar-collapse
        [title]="(group.controls.name.value | appIsSet) ? group.controls.name.value : 'No label'"
        [error]="group.invalid ? 'Column is not configured' : undefined"
        [description]="group.controls.value.value != group.controls.name.value ? group.controls.value.value : undefined"
        [descriptionWrap]="true"
        [block]="true"
        [context]="collapseContext"
        [openedInitial]="group === createdGroup"
        [arrow]="true"
        [drag]="true"
        [padding]="false"
      >
        <ng-container right>
          <a
            href="javascript:void(0)"
            class="sidebar-collapse__action icon-bin"
            (click)="$event.stopPropagation(); removeGroup(group, i)"
          >
          </a>
        </ng-container>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="
              form.controls.group_field.value
                ? form.controls.group_field.value + ' field value'
                : 'group by field value'
            "
          >
            <app-auto-field
              [form]="group"
              [label]="false"
              [field]="
                createField({
                  name: 'value',
                  field: 'CharField',
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'label'">
            <app-auto-field
              [form]="group"
              [label]="false"
              [autofocus]="true"
              [field]="
                createField({
                  name: 'name',
                  field: 'CharField',
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'color'">
            <app-auto-field
              [form]="group"
              [label]="false"
              [field]="
                createField({
                  name: 'color',
                  field: 'ColorField',
                  params: { classes: ['select_fill'], custom_colors: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <div *ngIf="form.controls.group_collapse.value" class="sidebar__element">
          <app-sidebar-field [label]="'default display'">
            <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="group.controls.opened_initial.value"
                (click)="group.controls.opened_initial.setValue(true)"
              >
                <div class="sidebar-icon-button__label">Opened</div>
              </div>
              <div
                class="sidebar-icon-button"
                [class.sidebar-icon-button_active]="!group.controls.opened_initial.value"
                (click)="group.controls.opened_initial.setValue(false)"
              >
                <div class="sidebar-icon-button__label">Collapsed</div>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="analyticsSource + '_group_visible'"
          >
            <app-input-edit
              [itemForm]="group.controls.visible_input"
              [context]="context"
              [contextElement]="contextElement"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="analyticsSource + '_group_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>
      </app-sidebar-collapse>
    </div>

    <ng-container *ngIf="!form.controls.groups.controls.length">
      <ng-container *ngIf="addMissingLoading || addingMissing">
        <div *ngFor="let item of [1, 2, 3]" class="sidebar-list__item">
          <div class="sidebar-collapse" [class.sidebar-collapse_block]="true" [class.sidebar-collapse_disabled]="false">
            <div class="sidebar-collapse__header">
              <div class="sidebar-collapse__header-main">
                <div class="sidebar-collapse__header-line">
                  <div class="sidebar-collapse__header-title">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">title</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!addMissingLoading && !addingMissing" class="sidebar-list__item">
        <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="createGroup()">
          <div class="sidebar-list-item__drag"></div>
          <div class="sidebar-list-item__left">
            <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
              <span class="icon button__icon icon-section"></span>
            </span>
          </div>
          <div class="sidebar-list-item__main">
            <div class="sidebar-list-item__title">Group</div>
            <div class="sidebar-list-item__description">Not specified</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="sidebar-list__buttons">
      <a
        href="javascript:void(0)"
        class="button button_orange-transparent button_small sidebar-list__buttons-item"
        [class.button_disabled]="addMissingLoading || addingMissing"
        (click)="createGroup()"
      >
        <span class="icon icon-plus button__icon button__icon_left"></span>
        <span class="button__label">Add Group</span>
      </a>
      <a
        *ngIf="form.isGetMissingEnabled()"
        href="javascript:void(0)"
        class="button button_orange-alternative button_small sidebar-list__buttons-item"
        [class.button_disabled]="addMissingLoading || addingMissing"
        (click)="addMissing()"
      >
        <span class="button__label">Detect missing values</span>
      </a>
      <app-field-errors
        *ngIf="!form.controls.groups.controls.length"
        [form]="form"
        [fieldName]="'groups'"
      ></app-field-errors>
    </div>
  </div>
</div>

import * as Sentry from '@sentry/browser';
import { CurrentUserStore } from '@modules/users';
import * as i0 from "@angular/core";
import * as i1 from "../../../users/stores/current-user.store";
var SentryService = /** @class */ (function () {
    function SentryService(currentUserStore) {
        this.currentUserStore = currentUserStore;
        this.currentUserStore.getFirst().subscribe(function (user) {
            Sentry.setUser(user ? { email: user.email } : null);
        });
    }
    SentryService.ngInjectableDef = i0.defineInjectable({ factory: function SentryService_Factory() { return new SentryService(i0.inject(i1.CurrentUserStore)); }, token: SentryService, providedIn: "root" });
    return SentryService;
}());
export { SentryService };

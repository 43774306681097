var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { isLightColor } from '@modules/colors';
import { ShareMenuItem } from '@modules/menu';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { ShareController } from '@modules/share';
import { isSet } from '@shared';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
var ShareMenuItemComponent = /** @class */ (function (_super) {
    __extends(ShareMenuItemComponent, _super);
    function ShareMenuItemComponent(currentProjectStore, currentEnvironmentStore, projectUserService, shareController, sanitizer, cd) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectUserService = projectUserService;
        _this.shareController = shareController;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.loading = false;
        _this.editAccess = false;
        _this.displayUsersCount = 4;
        _this.otherUsersCount = 0;
        _this.colorLight = true;
        _this.colorUnset = false;
        return _this;
    }
    ShareMenuItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.displayUsersCount = !this.horizontal && this.primary ? 1 : 4;
        this.cd.markForCheck();
        combineLatest(this.currentProjectStore.get(), this.currentEnvironmentStore.instance$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], environment = _a[1];
            _this.editAccess = project.hasEnvironmentAccessPermission(environment);
            _this.cd.markForCheck();
        });
        this.projectUserService
            .getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {},
            _a[PER_PAGE_PARAM] = this.displayUsersCount,
            _a))
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.displayUsers = result.results
                .filter(function (user) { return user.user; })
                .map(function (user) { return user.user; })
                .slice(0, _this.displayUsersCount + 1)
                .reverse();
            _this.otherUsersCount = result.count - _this.displayUsers.length;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.updateStyle();
    };
    ShareMenuItemComponent.prototype.ngOnDestroy = function () { };
    ShareMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuBlock && !changes.menuBlock.firstChange) {
            this.updateStyle();
        }
    };
    ShareMenuItemComponent.prototype.updateStyle = function () {
        var vars = __assign({}, (this.menuBlock && isSet(this.menuBlock.accentColor) && { 'menu-accent-color': this.menuBlock.accentColor }));
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        this.style = this.sanitizer.bypassSecurityTrustStyle(styles);
        this.colorUnset = !this.menuBlock || !isSet(this.menuBlock.accentColor);
        this.colorLight = !this.colorUnset ? isLightColor(this.menuBlock.accentColor) : undefined;
        this.cd.markForCheck();
    };
    ShareMenuItemComponent.prototype.share = function () {
        if (!this.editAccess) {
            return;
        }
        this.shareController.open().pipe(untilDestroyed(this)).subscribe();
    };
    return ShareMenuItemComponent;
}(BaseMenuItemComponent));
export { ShareMenuItemComponent };

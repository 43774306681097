var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import isObject from 'lodash/isObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, detectJSONFieldStructure, FieldType, JsonStructureNodeType, registerFieldViewParamsComponent } from '@modules/fields';
import { controlValue, EMPTY, isSet } from '@shared';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { JsonFieldViewParamsForm } from './json-field-view-params.form';
var JsonFieldViewParamsComponent = /** @class */ (function (_super) {
    __extends(JsonFieldViewParamsComponent, _super);
    function JsonFieldViewParamsComponent(form) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.createField = createFormFieldFactory();
        _this.booleanFieldStyle = BooleanFieldStyle;
        return _this;
    }
    JsonFieldViewParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control);
        controlValue(this.form.form.controls['display_fields'])
            .pipe(distinctUntilChanged(), startWith(undefined), pairwise(), untilDestroyed(this))
            .subscribe(function (_a) {
            var prev = _a[0], current = _a[1];
            if ((prev === false || !_this.form.form.value['structure']) && current) {
                _this.autoDetectStructure();
            }
        });
    };
    JsonFieldViewParamsComponent.prototype.ngOnDestroy = function () { };
    JsonFieldViewParamsComponent.prototype.parseContextValue = function () {
        var value = this.contextElement.getFieldValue(this.field.name);
        if (isSet(value) && !isObject(value)) {
            try {
                return JSON.parse(value);
            }
            catch (e) { }
        }
        return value;
    };
    JsonFieldViewParamsComponent.prototype.getDefaultStructure = function () {
        return {
            type: JsonStructureNodeType.Object,
            name: null,
            label: undefined,
            params: {
                items: []
            }
        };
    };
    JsonFieldViewParamsComponent.prototype.autoDetectStructure = function () {
        if (!this.contextElement || !this.field) {
            return;
        }
        var value = this.parseContextValue();
        var detectedStructure = value !== EMPTY ? detectJSONFieldStructure(value) : undefined;
        var structure = detectedStructure || this.getDefaultStructure();
        this.form.form.patchValue({ structure: structure });
    };
    return JsonFieldViewParamsComponent;
}(FieldParamsComponent));
export { JsonFieldViewParamsComponent };
registerFieldViewParamsComponent(FieldType.JSON, JsonFieldViewParamsComponent);

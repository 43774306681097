import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectBillingService } from '@modules/projects';

@Component({
  selector: 'app-project-billing-details-popup',
  templateUrl: './project-billing-details-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectBillingDetailsPopupComponent implements OnInit, OnDestroy {
  response: ProjectBillingService.GetSubscriptionUsersResponse;
  loading = false;
  error: string;

  constructor(
    @Optional() private popupComponent: BasePopupComponent,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectBillingService: ProjectBillingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.projectBillingService
      .getSubscriptionUsers(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        { [PER_PAGE_PARAM]: 6 }
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.response = response;
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';

import { UploadFieldComponent } from '../file-field/upload-field.component';

@Component({
  selector: 'app-image-field',
  templateUrl: 'image-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFieldComponent extends UploadFieldComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    modelService: ModelService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    storageService: StorageService,
    resourceControllerService: ResourceControllerService,
    cd: ChangeDetectorRef
  ) {
    super(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd);
  }

  ngOnInit(): void {
    this.updateStorage();
    this.updateUploaded();
    this.initOutputs();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStorage();
    this.updateUploaded();
  }
}

registerFieldComponent(FieldType.Image, ImageFieldComponent);

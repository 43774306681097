/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/error/error.component.ngfactory";
import * as i2 from "../../../../shared/components/error/error.component";
import * as i3 from "./change-route.component";
import * as i4 from "../../../customize/services/customize/customize.service";
import * as i5 from "@angular/router";
var styles_ChangeRouteComponent = [];
var RenderType_ChangeRouteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeRouteComponent, data: {} });
export { RenderType_ChangeRouteComponent as RenderType_ChangeRouteComponent };
export function View_ChangeRouteComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { changeComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(2, 114688, null, 0, i2.ErrorComponent, [], { title: [0, "title"], fill: [1, "fill"] }, null), (_l()(), i0.ɵted(-1, 0, [" This page type is not supported in the current version."])), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Please downgrade to a version "])), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["< 2.10.30"])), (_l()(), i0.ɵted(-1, 0, [" or build a new page.\n"]))], function (_ck, _v) { var currVal_0 = "Deprecated page type"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ChangeRouteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-change-route", [], null, null, null, View_ChangeRouteComponent_0, RenderType_ChangeRouteComponent)), i0.ɵdid(1, 245760, null, 0, i3.ChangeRouteComponent, [i4.CustomizeService, i5.ActivatedRoute, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeRouteComponentNgFactory = i0.ɵccf("app-change-route", i3.ChangeRouteComponent, View_ChangeRouteComponent_Host_0, {}, {}, []);
export { ChangeRouteComponentNgFactory as ChangeRouteComponentNgFactory };

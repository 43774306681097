<div
  class="grid"
  [style.margin-left.px]="(gapHorizontal | appIsSet) ? -this.gapHorizontal * 0.5 : null"
  [style.margin-right.px]="(gapHorizontal | appIsSet) ? -this.gapHorizontal * 0.5 : null"
  [style.margin-top.px]="(gapVertical | appIsSet) ? -this.gapVertical * 0.5 : null"
  [style.margin-bottom.px]="(gapVertical | appIsSet) ? -this.gapVertical * 0.5 : null"
  [style.height.px]="height"
  style="overflow: hidden;"
>
  <app-grid-stub-item
    *ngFor="let item of rowsRange; let i = index"
    [index]="i"
    [columns]="columns"
    [rowCards]="rowCards"
    [height]="cardHeight"
    [gapHorizontal]="gapHorizontal"
    [gapVertical]="gapVertical"
    [animating]="animating"
  >
  </app-grid-stub-item>
</div>

<div
  class="layer-container"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (mouseup)="onMouseUp()"
  (click)="onMouseClick($event)"
>
  <div
    class="frame-layer"
    [class.frame-layer_clip]="layer.clipContent"
    [style.border-radius]="borderRadius"
    [style.opacity]="layer.opacity"
    [style.box-shadow]="boxShadow"
    [style.cursor]="layer.getCursor() || null"
    #frame_element
  >
    <app-layer-interaction
      *ngFor="let item of layer.interactions"
      [interaction]="item"
      [element]="frame_element"
      [context]="viewContext"
      [contextElement]="contextElement"
      [analyticsSource]="analyticsSource"
    ></app-layer-interaction>

    <div
      *ngFor="let fill of fills; trackBy: trackFillFn"
      class="frame-layer__fill"
      [class.frame-layer__fill_inner]="fill.transform"
      [style.background]="fill.transform ? null : fill.background"
      [style.border-radius]="borderRadius"
      [style.opacity]="(fill.opacity | appIsSet) ? fill.opacity : null"
    >
      <span
        *ngIf="fill.transform"
        class="frame-layer__fill-inner"
        [style.background]="fill.background"
        [style.width]="fill.width"
        [style.height]="fill.height"
        [style.transform]="fill.transform"
      ></span>

      <span
        *ngIf="fill.icon"
        class="frame-layer__fill-icon"
        [style.color]="fill.icon.color"
        [style.font-size.px]="fill.icon.size"
        [appIcon]="fill.icon.icon"
      ></span>
    </div>

    <div
      class="frame-layer__layers"
      [class.frame-layer__layers_fixed]="!layer.widthFluid && !layer.heightFluid"
      [class.frame-layer__layers_flex]="layer.flexLayout"
      [class.frame-layer__layers_flex-orientation_horizontal]="
        layer.flexLayout?.orientation == flexLayoutOrientations.Horizontal
      "
      [class.frame-layer__layers_flex-orientation_vertical]="
        layer.flexLayout?.orientation == flexLayoutOrientations.Vertical
      "
      [class.frame-layer__layers_flex-wrap]="layer.flexLayout?.wrap"
      [class.frame-layer__layers_flex-align_start]="alignItems == flexLayoutAligns.Start"
      [class.frame-layer__layers_flex-align_center]="alignItems == flexLayoutAligns.Center"
      [class.frame-layer__layers_flex-align_end]="alignItems == flexLayoutAligns.End"
      [class.frame-layer__layers_flex-justify_start]="justifyContent == flexLayoutAligns.Start"
      [class.frame-layer__layers_flex-justify_center]="justifyContent == flexLayoutAligns.Center"
      [class.frame-layer__layers_flex-justify_end]="justifyContent == flexLayoutAligns.End"
      [class.frame-layer__layers_no-interaction]="!layersInteraction"
      [style]="layersStyle"
      #container_element
    >
      <app-auto-layer
        *ngFor="let item of displayItems; let i = index; trackBy: trackLayerFn"
        class="frame-layer__layers-item"
        [class.frame-layer__layers-item_static]="!item.absoluteLayout"
        [layer]="item"
        [container]="layer.layers"
        [containerElement]="container_element"
        [frame]="layer.frame"
        [layout]="!!layer.flexLayout && !item.absoluteLayout"
        [translate]="nestedContainer?.options.translate || { x: 0, y: 0 }"
        [viewContext]="viewContext"
        [analyticsSource]="analyticsSource"
        (layerDelete)="deleteLayer(i)"
      ></app-auto-layer>
    </div>

    <div
      *ngFor="let border of borders"
      class="frame-layer__fill frame-layer__fill_no-interaction"
      [style.border]="border.border"
      [style.top.px]="border.position"
      [style.right.px]="border.position"
      [style.bottom.px]="border.position"
      [style.left.px]="border.position"
      [style.border-radius]="border.borderRadius"
    ></div>
  </div>

  <ng-container *ngIf="editorContext">
    <ng-container
      *appViewEditorViewportOverlay="{
        frame: layer.frame,
        translate: translate
      }"
    >
      <app-view-editor-bounds
        *ngIf="editorContext"
        class="frame-layer__bounds"
        [class.frame-layer__bounds_hover]="hover$ | async"
        [class.frame-layer__bounds_active]="
          (customizing$ | async) &&
          !(editorContext.movingLayer$ | async) &&
          !(editorContext.customizingGradient$ | async)
        "
        [frame]="layer.frame"
        [translate]="translate"
        [snapFrame]="true"
        [snapFrameContainer]="container"
        [snapFrameExceptLayers]="[layer]"
        [constrainProportions]="!!layer.constrainProportion"
        [active]="customizing$ | async"
        [resizeHorizontal]="(customizing$ | async) && !(customizingMultiple$ | async)"
        [resizeVertical]="(customizing$ | async) && !(customizingMultiple$ | async)"
        (resizeStarted)="editorContext.resizingLayer$.next(layer)"
        (resizeFinished)="editorContext.resizingLayer$.next(undefined)"
        (updateFrame)="onFrameUpdate($event)"
      ></app-view-editor-bounds>
    </ng-container>
  </ng-container>
</div>

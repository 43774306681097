import isArray from 'lodash/isArray';

import {
  exactFieldLookup,
  gteFieldLookup,
  gtFieldLookup,
  inFieldLookup,
  isNullFieldLookup,
  lteFieldLookup,
  ltFieldLookup
} from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';
import { parseInLookupStringValue } from '../lookup-matchers/text';

export const numberAirtableFormulas = [
  {
    field: FieldType.Number,
    lookup: exactFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      return `{${field.name}} = "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Number,
    lookup: gteFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      return `{${field.name}} >= "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Number,
    lookup: gtFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      return `{${field.name}} > "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Number,
    lookup: lteFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      return `{${field.name}} <= "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Number,
    lookup: ltFieldLookup,
    formula: (lookupValue: number, field?: BaseField): string => {
      return `{${field.name}} < "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Number,
    lookup: isNullFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      // return lookupValue ? fieldValue === null : fieldValue !== null;
      return;
    }
  },
  {
    field: FieldType.Number,
    lookup: inFieldLookup,
    formula: (lookupValue: number | number[], field?: BaseField): string => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return;
      }

      if (typeof lookupValue == 'string') {
        lookupValue = (parseInLookupStringValue((lookupValue as unknown) as string) as unknown) as number[];
      } else if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const predicates = lookupValue.map(item => `{${field.name}} = "${item}"`);
      return `OR(${predicates.join(',')})`;
    }
  }
];

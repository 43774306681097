import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild
} from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { applyBooleanInput$ } from '@modules/fields';
import { MenuBlock, MenuItem, MenuItemType } from '@modules/menu';
import { isSet, TypedChanges } from '@shared';

import { MenuContext } from '../../../data/menu-context';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
import { ButtonMenuItemComponent } from '../button-menu-item/button-menu-item.component';
import { CustomMenuItemComponent } from '../custom-menu-item/custom-menu-item.component';
import { ImageMenuItemComponent } from '../image-menu-item/image-menu-item.component';
import { ModelLinkMenuItemComponent } from '../model-link-menu-item/model-link-menu-item.component';
import { SectionMenuItemComponent } from '../section-menu-item/section-menu-item.component';
import { SeparatorMenuItemComponent } from '../separator-menu-item/separator-menu-item.component';
import { ShareMenuItemComponent } from '../share-menu-item/share-menu-item.component';
import { SimpleMenuItemComponent } from '../simple-menu-item/simple-menu-item.component';

@Component({
  selector: 'app-auto-menu-item',
  templateUrl: 'auto-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: MenuItem;
  @Input() menuBlock: MenuBlock;
  @Input() primary = false;
  @Input() dropdown = false;
  @Input() horizontal = false;
  @Input() childrenVertical = false;
  @Input() forceActive = false;
  @Input() forceOpened = false;
  @Output() dropdownOpen = new EventEmitter<void>();
  @Output() dropdownClose = new EventEmitter<void>();

  @ViewChild(DynamicComponent) dynamicComponent: DynamicComponent<BaseMenuItemComponent>;

  menuItem$ = new BehaviorSubject<MenuItem>(undefined);
  mapping: { type: MenuItemType; component: any }[] = [
    { type: MenuItemType.Simple, component: SimpleMenuItemComponent },
    { type: MenuItemType.Section, component: SectionMenuItemComponent },
    { type: MenuItemType.ModelLink, component: ModelLinkMenuItemComponent },
    { type: MenuItemType.Image, component: ImageMenuItemComponent },
    { type: MenuItemType.Button, component: ButtonMenuItemComponent },
    { type: MenuItemType.Separator, component: SeparatorMenuItemComponent },
    { type: MenuItemType.Share, component: ShareMenuItemComponent },
    { type: MenuItemType.Custom, component: CustomMenuItemComponent }
  ];
  defaultMapping = SimpleMenuItemComponent;
  componentData: DynamicComponentArguments<BaseMenuItemComponent>;
  isVisible = false;

  constructor(private context: MenuContext, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.menuItem$.next(this.menuItem);

    this.menuItem$
      .pipe(
        switchMap(menuItem => {
          return applyBooleanInput$(menuItem.visibleInput, {
            context: this.context
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.isVisible = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoMenuItemComponent>): void {
    if (
      changes.menuItem &&
      (changes.menuItem.previousValue ? changes.menuItem.previousValue.type : undefined) !==
        (changes.menuItem.currentValue ? changes.menuItem.currentValue.type : undefined)
    ) {
      this.initComponent();
    } else {
      this.updateComponent();
    }

    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.menuItem$.next(this.menuItem);
    }
  }

  getComponentInputs(): Partial<BaseMenuItemComponent> {
    return {
      menuItem: this.menuItem,
      menuBlock: this.menuBlock,
      primary: this.primary,
      dropdown: this.dropdown,
      horizontal: this.horizontal,
      childrenVertical: this.childrenVertical,
      forceActive: this.forceActive,
      forceOpened: this.forceOpened,
      context: this.context
    };
  }

  initComponent() {
    const mapping = this.mapping.find(item => item.type == this.menuItem.type);
    const component = mapping ? mapping.component : this.defaultMapping;

    if (!component) {
      this.componentData = undefined;
      this.cd.markForCheck();
      console.error(`No such menu item type registered: ${this.menuItem.type}`);
      return;
    }

    this.componentData = {
      component: component,
      inputs: this.getComponentInputs(),
      outputs: {
        dropdownOpen: [() => this.dropdownOpen.emit()],
        dropdownClose: [() => this.dropdownClose.emit()]
      }
    };
    this.cd.markForCheck();
  }

  updateComponent(options: { forcePropUpdate?: keyof BaseMenuItemComponent } = {}) {
    if (
      !this.dynamicComponent ||
      !this.dynamicComponent.currentComponent ||
      !this.dynamicComponent.currentComponent.instance
    ) {
      return;
    }

    const ref = this.dynamicComponent.currentComponent;
    const inputs = this.getComponentInputs();
    const changes: TypedChanges<BaseMenuItemComponent> = toPairs(inputs).reduce((acc, [prop, currentValue]) => {
      const prevValue = ref.instance[prop];

      if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
        acc[prop] = new SimpleChange(prevValue, currentValue, false);
        ref.instance[prop] = currentValue;
      }

      return acc;
    }, {});

    this.componentData.inputs = inputs;

    if (values(changes).length) {
      if (ref.instance['ngOnChanges']) {
        ref.instance['ngOnChanges'](changes);
      }

      ref.changeDetectorRef.markForCheck();
    }
  }
}

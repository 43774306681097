<div class="dialog-background"></div>
<div class="dialog-container">
  <div
    class="dialog"
    [class.dialog_fit]="!(currentOptions.title | appIsSet) && !(currentOptions.description | appIsSet)"
    [class.dialog_no-padding]="!(currentOptions.title | appIsSet) && !(currentOptions.description | appIsSet)"
    [class.theme-dark]="currentOptions.dark"
  >
    <app-loader [title]="currentOptions.title" [compact]="true">
      {{ currentOptions.description }}
    </app-loader>
  </div>
</div>

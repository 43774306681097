import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ViewContext, ViewContextElement, ViewSettings } from '@modules/customize';
import { InputValueType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-parameters-link',
  templateUrl: './customize-bar-action-edit-parameters-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditParametersLinkComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() analyticsSource: string;

  inputsCollapseContext = new SidebarCollapseContext();
  inputValueTypes = InputValueType;
  page: ViewSettings;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form
      .linkPage$()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.page = viewSettings;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}

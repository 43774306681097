import { exactFieldLookup } from '@modules/field-lookups';

import { FieldType } from '../field-type';

export const booleanLookupMatchers = [
  {
    field: FieldType.Boolean,
    lookup: exactFieldLookup,
    match: (fieldValue: boolean, lookupValue: boolean): boolean => {
      return fieldValue == lookupValue;
    }
  }
];

import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { StorageObject, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';

@Injectable()
export class ProjectStorageService {
  constructor(private http: HttpClient, private apiService: ApiService, private projectApiService: ProjectApiService) {}

  getUploadUrl(projectName: string): string {
    return this.apiService.nodeProjectMethodURL(projectName, 'static_files', true);
  }

  getUploadHeaders(): HttpHeaders {
    return this.projectApiService.setHeadersToken(new HttpHeaders());
  }

  getUploadData(file: Blob, path?: string, fileName?: string): FormData {
    const data = new FormData();

    data.set('file', file);

    if (isSet(path)) {
      data.set('path', path);
    }

    if (isSet(fileName)) {
      data.set('file_name', fileName);
    }

    return data;
  }

  uploadFile(
    projectName: string,
    environmentName: string,
    file: Blob,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.getUploadUrl(projectName);
        const data = this.getUploadData(file, path, fileName);
        const headers = this.getUploadHeaders();

        return this.http.post(url, data, { headers: headers, observe: 'events' });
      }),
      map(event => {
        if (event.type == HttpEventType.Response) {
          return {
            result: {
              uploadedPath: event.body['path'],
              uploadedUrl: event.body['url'],
              response: event
            },
            state: {
              downloadProgress: 1,
              uploadProgress: 1
            }
          };
        } else if (event.type == HttpEventType.UploadProgress) {
          return {
            state: {
              uploadProgress: event.loaded / event.total,
              downloadProgress: 0,
              uploadLoaded: event.loaded,
              uploadTotal: event.total
            }
          };
        } else if (event.type == HttpEventType.DownloadProgress) {
          return {
            state: {
              uploadProgress: 1,
              downloadProgress: event.loaded / event.total,
              downloadLoaded: event.loaded,
              downloadTotal: event.total
            }
          };
        }
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getObjects(projectName: string, environmentName: string, path: string): Observable<StorageObjectsResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeProjectMethodURL(projectName, 'static_files');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({
          fromObject: {
            ...(isSet(path) && { path: path })
          }
        });

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => {
        const response = new StorageObjectsResponse();

        response.objects = result['items'].map(item => {
          return new StorageObject({
            path: item['path'],
            url: item['url'],
            type: item['type'],
            size: item['size'],
            created: item['created_at'],
            updated: item['updated_at']
          });
        });
        response.storageSize = result['storage_size'];
        response.storageLimit = result['storage_limit'];

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createFolder(projectName: string, environmentName: string, path: string): Observable<boolean> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeProjectMethodURL(projectName, 'static_files/create_folder');
        const data = {
          path: path
        };
        let headers = new HttpHeaders();

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteObject(projectName: string, environmentName: string, path: string): Observable<boolean> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeProjectMethodURL(projectName, 'static_files');
        const data = {
          path: path
        };
        let headers = new HttpHeaders();

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.request('DELETE', url, { body: data, headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

<div
  class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_border"
  [class.sidebar-list-item_draggable]="false"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
>
  <div class="sidebar-list-item__drag"></div>

  <div class="sidebar-list-item__left">
    <mat-menu #fieldsMenu="matMenu">
      <ng-template matMenuContent>
        <button
          *ngFor="let item of control.fieldOptions"
          mat-menu-item
          [disableRipple]="true"
          (click)="control.controls.field.patchValue(item.value)"
        >
          <span *ngIf="item.icon" class="mat-menu-item-icon" [ngClass]="['icon-' + item.icon]"></span>

          {{ item.name }}
        </button>
      </ng-template>
    </mat-menu>

    <a
      href="javascript:void(0)"
      class="button button_icon button_orange button_extra-small"
      [matMenuTriggerFor]="fieldsMenu"
      style="margin: 0;"
    >
      <span class="button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
    </a>
  </div>

  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      <app-editable-content
        [value]="control.controls.name.value"
        [emptyValue]="'Parameter name'"
        [pen]="hovered$ | async"
        [penTitle]="'Rename parameter'"
        (valueChange)="control.controls.name.patchValue($event)"
        (mousedown)="$event.stopPropagation()"
      >
      </app-editable-content>
    </div>
  </div>

  <div *ngIf="hovered$ | async" class="sidebar-list-item__right">
    <a href="javascript:void(0)" class="sidebar-list-item__action icon-bin" (click)="delete.emit()"> </a>
  </div>
</div>

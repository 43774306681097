import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';

import { isSet } from '@shared';

// TODO: Refactor import
import { getColorHex, parseColor } from '../../../modules/colors/utils/colors';

@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormLayoutComponent implements OnInit, OnChanges {
  @Input() backgroundColor: string;
  @Input() backgroundImage: string;
  @Input() fillScreen = false;
  @Input() preview = false;

  backgroundContrastColor1: string;
  backgroundContrastColor2: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundColor']) {
      this.updateBackgroundContrastColor();
    }
  }

  updateBackgroundContrastColor() {
    const hex = getColorHex(this.backgroundColor || '#e8ebf0');
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (!clr) {
      this.backgroundContrastColor1 = undefined;
      this.backgroundContrastColor2 = undefined;
      return;
    }

    const colorContrast = clr.contrast(Color('white'));
    const isDark = colorContrast >= 4;

    this.backgroundContrastColor1 = isDark ? clr.lighten(0.02 * colorContrast).string() : clr.darken(0.04).string();
    this.backgroundContrastColor2 = isDark ? clr.lighten(0.01 * colorContrast).string() : clr.darken(0.02).string();
  }
}

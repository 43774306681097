import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { MenuComponentsModule } from '@modules/menu-components';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { MessagesConverterComponent } from './components/messages-converter/messages-converter.component';
import { routes } from './messages-routes.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    UiModule,
    ReactiveFormsModule,
    FieldsModule,
    SharedModule,
    TableModule,
    MenuComponentsModule,
    FieldComponentsModule,
    RoutingModule
  ],
  declarations: [MessagesConverterComponent]
})
export class MessagesRoutesModule {}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InjectionToken } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ValueWidget } from '@modules/dashboard';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
import { MarginControl } from '../../margin-control/margin-control.component';
import { ValueWidgetDataSourceControl } from '../../model-description-data-source-edit/value-widget-data-source';
import { CustomizeBarBaseWidgetEditForm } from '../customize-bar-base-widget-edit/customize-bar-base-widget-edit.form';
import { ChartWidgetEditFormDatasetControl } from '../customize-bar-chart-widget-edit/chart-widget-edit-dataset.control';
export var VALUE_WIDGET_DATA_SOURCE = new InjectionToken('VALUE_WIDGET_DATA_SOURCE');
export var VALUE_WIDGET_COMPARE_DATA_SOURCE = new InjectionToken('VALUE_WIDGET_COMPARE_DATA_SOURCE');
export var VALUE_WIDGET_CHART_DATASET = new InjectionToken('VALUE_WIDGET_CHART_DATASET');
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.value).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
var CustomizeBarValueWidgetEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarValueWidgetEditForm, _super);
    function CustomizeBarValueWidgetEditForm(elementConfigurationService, dataSourceControl, compareDataSourceControl, chartDatasetControl) {
        var _this = _super.call(this, {
            title: new FieldInputControl({ name: 'value' }),
            data_source: dataSourceControl,
            compare_data_source_enabled: new FormControl(false),
            compare_name: new FormControl(''),
            compare_growth_negative: new FormControl(false),
            compare_data_source: compareDataSourceControl,
            chart_dataset_enabled: new FormControl(false),
            chart_dataset: chartDatasetControl,
            prefix: new FormControl(''),
            postfix: new FormControl(''),
            format: new FormControl(''),
            icon: new FormControl(''),
            show_reload: new FormControl(true),
            name: new FormControl(''),
            click_action: new FormControl(undefined, undefined, validateAction),
            visible_input: new FieldInputControl({ name: 'value' }),
            tooltip: new FormControl(''),
            margin: new MarginControl()
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    CustomizeBarValueWidgetEditForm.prototype.init = function (widget, name, visibleInput, margin, firstInit, component) {
        if (firstInit === void 0) { firstInit = false; }
        this.widget = widget;
        this.component = component;
        if (widget) {
            this.controls.title.patchValue(widget.nameInput ? widget.nameInput.serialize() : {});
            this.controls.compare_data_source_enabled.patchValue(!!widget.compareDataSource);
            this.controls.chart_dataset_enabled.patchValue(!!widget.chartDataset);
            this.controls.compare_name.patchValue(widget.compareName);
            this.controls.compare_growth_negative.patchValue(widget.compareGrowthNegative);
            this.controls.prefix.patchValue(widget.prefix);
            this.controls.postfix.patchValue(widget.postfix);
            this.controls.format.patchValue(widget.format);
            this.controls.icon.patchValue(widget.icon);
            this.controls.show_reload.patchValue(widget.showReload);
            this.controls.click_action.patchValue(widget.clickAction);
            this.controls.visible_input.patchValue(visibleInput ? visibleInput.serialize() : {});
            this.controls.tooltip.patchValue(widget.tooltip);
            this.controls.margin.patchValue(margin);
        }
        if (name) {
            this.controls.name.patchValue(name);
        }
        this.controls.data_source.deserialize(widget ? widget.dataSource : undefined);
        this.controls.compare_data_source.deserialize(widget ? widget.compareDataSource : undefined);
        this.controls.chart_dataset.deserialize(widget ? widget.chartDataset : undefined);
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarValueWidgetEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isWidgetValueConfigured(instance, { restrictDemo: true });
    };
    CustomizeBarValueWidgetEditForm.prototype.setCompareDataSourceEnabled = function (value) {
        if (this.controls.compare_data_source_enabled.value === value) {
            return;
        }
        this.controls.compare_data_source_enabled.patchValue(value);
    };
    CustomizeBarValueWidgetEditForm.prototype.setChartDatasetEnabled = function (value) {
        if (this.controls.chart_dataset_enabled.value === value) {
            return;
        }
        this.controls.chart_dataset_enabled.patchValue(value);
    };
    CustomizeBarValueWidgetEditForm.prototype.swapMainAndCompareDataSources = function () {
        var dataSourceValue = this.controls.data_source.serialize();
        var compareDataSourceValue = this.controls.compare_data_source.serialize();
        this.controls.compare_data_source.deserialize(dataSourceValue, false);
        this.controls.data_source.deserialize(compareDataSourceValue, false);
    };
    CustomizeBarValueWidgetEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.click_action]);
    };
    CustomizeBarValueWidgetEditForm.prototype.submit = function () {
        var instance = this.widget ? cloneDeep(this.widget) : new ValueWidget();
        instance.nameInput = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.dataSource = this.controls.data_source.serialize();
        if (this.controls.compare_data_source_enabled.value) {
            instance.compareName = this.controls.compare_name.value;
            instance.compareGrowthNegative = this.controls.compare_growth_negative.value;
            instance.compareDataSource = this.controls.compare_data_source.serialize();
        }
        else {
            instance.compareName = undefined;
            instance.compareGrowthNegative = undefined;
            instance.compareDataSource = undefined;
        }
        if (this.controls.chart_dataset_enabled.value) {
            instance.chartDataset = this.controls.chart_dataset.serialize();
        }
        else {
            instance.chartDataset = undefined;
        }
        instance.prefix = this.controls.prefix.value;
        instance.postfix = this.controls.postfix.value;
        instance.format = this.controls.format.value;
        instance.icon = this.controls.icon.value;
        instance.showReload = this.controls.show_reload.value;
        if (this.controls.click_action.value) {
            instance.clickAction = this.controls.click_action.value;
        }
        else {
            instance.clickAction = undefined;
        }
        instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
        return {
            widget: instance,
            name: this.controls.name.value,
            visibleInput: this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined,
            margin: this.controls.margin.value
        };
    };
    return CustomizeBarValueWidgetEditForm;
}(CustomizeBarBaseWidgetEditForm));
export { CustomizeBarValueWidgetEditForm };

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

import { salesforceResourceParamsModelDescriptions } from '../../data/salesforce/salesforce-resource-params-model-descriptions.stub';
import { salesforceResourceParamsParams } from '../../data/salesforce/salesforce-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface SalesforceParamsOptions {
  domain: string;
  key: string;
  secret: string;
  access_token: string;
  token_params: Object;
}

@Injectable()
export class SalesforceGeneratorService extends ResourceGeneratorService<SalesforceParamsOptions> {
  tokenName = 'oauth_access_token';

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: SalesforceParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = `https://${options.domain}.my.salesforce.com/services/data/v52.0/`;
    query.headers = [{ name: 'Authorization', value: `Bearer ${options.access_token}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (
          error instanceof ServerRequestError &&
          error.response instanceof HttpErrorResponse &&
          error.status >= 401 &&
          error.status < 500
        ) {
          error = new ServerRequestError('API key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<SalesforceParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        this.tokenName,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(secretToken => {
          return {
            domain: resource.params['domain'],
            access_token: secretToken.value,
            token_params: secretToken.params,
            key: secretToken.params['KEY'],
            secret: secretToken.params['SECRET']
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: SalesforceParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = salesforceResourceParamsParams;
    const modelDescriptions = salesforceResourceParamsModelDescriptions;
    const token = new SecretToken();

    token.resource = '{{resource}}';
    token.name = this.tokenName;
    token.type = SecretTokenType.OAuth;
    token.value = options.access_token;

    try {
      token.params = options.token_params;
    } catch (e) {
      token.params = {};
    }

    resourceParams['domain'] = options.domain;

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      secretTokens: [token.serialize()],
      extraTokens: {
        domain: options.domain
      }
    });
  }
}

import { EditableField } from './base-field';
import { DefaultType } from './default';
import { FieldType } from './field-type';
import { FieldDescription, getFieldDescriptionByType, parseFieldType } from './fields';
import { ValidatorType } from './validator-type';

export class ParameterField implements EditableField {
  public group: string;
  public groupIcon: string;
  public name: string;
  public verboseName: string;
  public icon: string;
  public description: string;
  public field: FieldType;
  public required = true;
  public defaultType: DefaultType = undefined;
  public defaultValue: string;
  public placeholder: string;
  public validatorType: ValidatorType;
  public validatorParams: Object;
  public params = {};
  public fieldDescription: FieldDescription;
  public protected = false;

  constructor(options: Partial<ParameterField> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): ParameterField {
    this.group = data['group'];
    this.name = data['name'];
    this.verboseName = data['verbose_name'];
    this.description = data['description'];
    this.field = parseFieldType(data['field']);
    this.defaultValue = data['default_value'];
    this.placeholder = data['placeholder'];
    this.validatorType = data['validator_type'];
    this.validatorParams = data['validator_params'];

    if (data['required'] == false) {
      this.required = false;
    }

    if (data['default_type']) {
      this.defaultType = data['default_type'];
    }

    if (data['params']) {
      this.params = data['params'];
    }

    this.updateFieldDescription();

    return this;
  }

  serialize(): Object {
    return {
      group: this.group,
      name: this.name,
      verbose_name: this.verboseName,
      description: this.description,
      field: this.field,
      required: this.required,
      default_type: this.defaultType,
      default_value: this.defaultValue,
      placeholder: this.placeholder,
      validator_type: this.validatorType,
      validator_params: this.validatorParams,
      params: this.params
    };
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }
}

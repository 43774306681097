/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "./import-data-item.component";
var styles_ImportDataItemComponent = [];
var RenderType_ImportDataItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImportDataItemComponent, data: {} });
export { RenderType_ImportDataItemComponent as RenderType_ImportDataItemComponent };
function View_ImportDataItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["auto"]))], null, null); }
function View_ImportDataItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], [[4, "pointer-events", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.createField(_v.parent.context.$implicit.fieldObj); var currVal_2 = true; var currVal_3 = _v.parent.context.$implicit.value; var currVal_4 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 0, 0, currVal_0); }); }
function View_ImportDataItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [], [[2, "table__column-inner", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportDataItemComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportDataItemComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.stubValue; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_v.context.$implicit.stubValue; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_v.context.$implicit.field.field == _co.fieldTypes.Text) && _v.context.$implicit.field.params["wide"]); _ck(_v, 1, 0, currVal_0); }); }
export function View_ImportDataItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportDataItemComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackByFn; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ImportDataItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-import-data-item", [], null, null, null, View_ImportDataItemComponent_0, RenderType_ImportDataItemComponent)), i0.ɵdid(1, 638976, null, 0, i4.ImportDataItemComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportDataItemComponentNgFactory = i0.ɵccf("app-import-data-item, [app-import-data-item]", i4.ImportDataItemComponent, View_ImportDataItemComponent_Host_0, { row: "row", index: "index", autoValueStub: "autoValueStub", fields: "fields" }, {}, []);
export { ImportDataItemComponentNgFactory as ImportDataItemComponentNgFactory };

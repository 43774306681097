import pickBy from 'lodash/pickBy';

export enum UserLevel {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Advanced = 'advanced'
}

export enum Employment {
  Company = 'company',
  Personal = 'personal'
}

export interface UserInfo {
  employment: Employment;
  company_website: string;
  phone: string;
  // user_level: UserLevel;
}

export class UserParams {
  public projectOnboardingShowed: string[] = [];
  public projectCompanyInfoShowed: string[] = [];
  public googleAnalyticsClientId: string[] = [];
  public activatedProjectFeatures: { [k: string]: string[] } = {};
  public userInfo: UserInfo;
  public rewardful: string;

  deserialize(data: Object): UserParams {
    if (data['project_onboarding_showed']) {
      this.projectOnboardingShowed = data['project_onboarding_showed'];
    }

    if (data['project_company_info_showed']) {
      this.projectCompanyInfoShowed = data['project_company_info_showed'];
    }

    if (data['google_analytics_client_id']) {
      this.googleAnalyticsClientId = data['google_analytics_client_id'];
    }

    if (data['activated_project_features']) {
      this.activatedProjectFeatures = data['activated_project_features'];
    }

    if (data['user_info']) {
      this.userInfo = data['user_info'];
    }

    if (data['rewardful']) {
      this.rewardful = data['rewardful'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      project_onboarding_showed: this.projectOnboardingShowed,
      project_company_info_showed: this.projectCompanyInfoShowed,
      google_analytics_client_id: this.googleAnalyticsClientId,
      activated_project_features: this.activatedProjectFeatures,
      user_info: this.userInfo,
      rewardful: this.rewardful
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  hasProjectOnboardingShowed(name): boolean {
    return this.projectOnboardingShowed.find(item => item === name) != undefined;
  }

  addProjectOnboardingShowed(name): boolean {
    if (this.hasProjectOnboardingShowed(name)) {
      return false;
    }

    this.projectOnboardingShowed.push(name);
    return true;
  }

  hasProjectCompanyInfoShowed(name): boolean {
    return this.projectCompanyInfoShowed.find(item => item === name) != undefined;
  }

  addProjectCompanyInfoShowed(name): boolean {
    if (this.hasProjectCompanyInfoShowed(name)) {
      return false;
    }

    this.projectCompanyInfoShowed.push(name);
    return true;
  }

  hasGoogleAnalyticsClientId(clientId): boolean {
    return this.googleAnalyticsClientId.find(item => item === clientId) != undefined;
  }

  addGoogleAnalyticsClientId(clientId): boolean {
    if (this.hasGoogleAnalyticsClientId(clientId)) {
      return false;
    }

    this.googleAnalyticsClientId.push(clientId);
    return true;
  }

  isProjectFeatureActivated(project: string, feature: string): boolean {
    if (!this.activatedProjectFeatures[project]) {
      return false;
    }

    return this.activatedProjectFeatures[project].find(item => item === feature) != undefined;
  }

  addActivatedProjectFeature(project: string, feature: string): boolean {
    if (this.isProjectFeatureActivated(project, feature)) {
      return false;
    }

    if (!this.activatedProjectFeatures[project]) {
      this.activatedProjectFeatures[project] = [];
    }

    this.activatedProjectFeatures[project].push(feature);
    return true;
  }
}

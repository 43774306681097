var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CURSOR_NEXT_PARAM, CURSOR_PREV_PARAM, Model, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { applyQueryOptionsFilterInputs, ResourceControllerService } from '@modules/resources';
import { isSet, ListStore } from '@shared';
var ModelListStore = /** @class */ (function (_super) {
    __extends(ModelListStore, _super);
    function ModelListStore(service, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, modelDescriptionDataSourceService, resourceControllerService) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelDescriptionDataSourceService = modelDescriptionDataSourceService;
        _this.resourceControllerService = resourceControllerService;
        _this.useDataSourceColumns = false;
        _this.staticData = [];
        _this.params = {};
        return _this;
    }
    ModelListStore.prototype.deserializeModelAttributes = function () {
        var _this = this;
        if (!this.items) {
            return;
        }
        this.items = this.items.map(function (item) {
            if (item instanceof Model) {
                item.deserializeAttributes(_this.dataSource.columns);
            }
            return item;
        });
    };
    ModelListStore.prototype.fetchModelPage = function (page, next) {
        var _this = this;
        var obs$;
        if (this.queryOptions) {
            var queryOptions = {};
            queryOptions.paging = queryOptions.paging || {};
            queryOptions.paging.page = page;
            if (this.perPage) {
                queryOptions.paging.limit = this.perPage;
            }
            if (next) {
                var cursorNext = this.nextPageCursors[page - 1];
                if (isSet(cursorNext)) {
                    queryOptions.paging.cursorNext = cursorNext;
                }
            }
            else {
                var cursorPrev = this.prevPageCursors[page + 1];
                if (isSet(cursorPrev)) {
                    queryOptions.paging.cursorPrev = cursorPrev;
                }
            }
            queryOptions.filters = this.queryOptions.filters;
            queryOptions.params = this.queryOptions.params;
            queryOptions.search = this.queryOptions.search;
            queryOptions.sort = this.queryOptions.sort;
            queryOptions.columns = this.dataSource ? this.dataSource.columns : undefined;
            queryOptions = applyQueryOptionsFilterInputs(this.dataSource, queryOptions);
            obs$ = this.modelDescriptionDataSourceService.getAdv({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: this.dataSource,
                queryOptions: queryOptions,
                staticData: this.staticData,
                context: this.context,
                contextElement: this.contextElement,
                localContext: this.localContext
            });
        }
        else {
            var params = this.params ? clone(this.params) : {};
            params[PAGE_PARAM] = page;
            if (this.perPage) {
                params[PER_PAGE_PARAM] = this.perPage;
            }
            if (next) {
                var cursorNext = this.nextPageCursors[page - 1];
                if (isSet(cursorNext)) {
                    params[CURSOR_NEXT_PARAM] = cursorNext;
                }
            }
            else {
                var cursorPrev = this.prevPageCursors[page + 1];
                if (isSet(cursorPrev)) {
                    params[CURSOR_PREV_PARAM] = cursorPrev;
                }
            }
            obs$ = this.modelDescriptionDataSourceService.get({
                project: this.currentProjectStore.instance,
                environment: this.currentEnvironmentStore.instance,
                dataSource: this.dataSource,
                params: params,
                staticData: this.staticData,
                context: this.context,
                contextElement: this.contextElement,
                localContext: this.localContext
            });
        }
        return obs$.pipe(map(function (response) {
            if (!response) {
                return;
            }
            var perPage;
            var totalPages;
            if (response.perPage) {
                perPage = response.perPage;
                _this.perPage = perPage;
            }
            else if (_this.perPage) {
                perPage = _this.perPage;
            }
            else if (response.results.length) {
                perPage = response.results.length;
                _this.perPage = perPage;
            }
            if (response.numPages) {
                totalPages = response.numPages;
            }
            else if (response.count && perPage) {
                totalPages = Math.ceil(response.count / perPage);
            }
            return {
                items: response.results,
                hasMore: response.hasMore,
                totalPages: totalPages,
                fetchPerPage: perPage,
                count: response.count,
                cursorPrev: response.cursorPrev,
                cursorNext: response.cursorNext
            };
        }), map(function (result) {
            if (result && result.items) {
                result.items.forEach(function (item) {
                    if (_this.useDataSourceColumns &&
                        item instanceof Model &&
                        _this.dataSource.columns &&
                        _this.dataSource.columns.length) {
                        item.deserializeAttributes(_this.dataSource.columns);
                    }
                });
            }
            return result;
        }));
    };
    ModelListStore.prototype.fetchPage = function (page, next) {
        return this.fetchModelPage(page, next);
    };
    return ModelListStore;
}(ListStore));
export { ModelListStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectorRef, ElementRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DialogService } from '@common/dialogs';
import { ScrollService } from '@common/scroll';
import { SessionStorage } from '@core';
import { ActionControllerService } from '@modules/action-queries';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomViewSettings, generateUidAndNamesRecursive, PopupSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { BUILDER_ADD_ELEMENT } from '@modules/customize-elements';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';
import { isCustomPagePopupClickEvent } from '../custom-page-popup/custom-page-popup.component';
var CustomPagePopupsComponent = /** @class */ (function () {
    function CustomPagePopupsComponent(mode, customizeService, customizeBarContext, actionControllerService, sessionStorage, routing, scrollService, dialogService, injector, analyticsService, cd) {
        this.mode = mode;
        this.customizeService = customizeService;
        this.customizeBarContext = customizeBarContext;
        this.actionControllerService = actionControllerService;
        this.sessionStorage = sessionStorage;
        this.routing = routing;
        this.scrollService = scrollService;
        this.dialogService = dialogService;
        this.injector = injector;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.openedToolbar = false;
        this.closedToolbar = new EventEmitter();
        this.popupContainerElements = new QueryList();
        this.popupElements = new QueryList();
        this.popupOverlayElements = new QueryList();
        this.popupComponents = new QueryList();
        this.mouseDownContainer = false;
        this.dropdownPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 0,
                offsetY: 5
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 0,
                offsetY: 5
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: -5
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: -5
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center',
                offsetX: -5
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center',
                offsetX: 5
            }
        ];
        this.adminModes = AdminMode;
        this.analyticsSource = 'popups_toolbar';
        this.trackColumn = (function () {
            return function (i, item) { return (isSet(item.uid) ? item.uid : item); };
        })();
    }
    CustomPagePopupsComponent.prototype.ngOnInit = function () { };
    CustomPagePopupsComponent.prototype.ngOnDestroy = function () {
        this.openedPopupUid = undefined;
        this.openedPopupOrigin = undefined;
        this.updateWindowScroll();
    };
    CustomPagePopupsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['viewSettings'] && this.openedPopupUid && (!this.viewSettings || !this.getPopup(this.openedPopupUid))) {
            this.openedPopupUid = undefined;
            this.openedPopupOrigin = undefined;
            this.updateWindowScroll();
        }
    };
    CustomPagePopupsComponent.prototype.hasOpenedPopup = function () {
        return isSet(this.openedPopupUid);
    };
    CustomPagePopupsComponent.prototype.getPopup = function (uid) {
        return this.viewSettings.popups.find(function (item) { return item.uid == uid; });
    };
    Object.defineProperty(CustomPagePopupsComponent.prototype, "openedPopup", {
        get: function () {
            if (!this.openedPopupUid) {
                return;
            }
            return this.getPopup(this.openedPopupUid);
        },
        enumerable: true,
        configurable: true
    });
    CustomPagePopupsComponent.prototype.openPopup = function (uid, options) {
        // Allow re-opening already opened popup (ex. for changing parameters)
        // if (this.openedPopupUid === uid) {
        //   return;
        // }
        var _this = this;
        if (options === void 0) { options = {}; }
        var popup = this.getPopup(uid);
        if (!popup) {
            return;
        }
        if (this.openedPopup && this.openedPopup.uid == popup.uid && options.togglePopup) {
            this.closePopup(uid);
            return;
        }
        this.openedPopupUid = uid;
        if (popup.isOrigin() && options.origin instanceof ElementRef) {
            this.openedPopupOrigin = new CdkOverlayOrigin(options.origin);
        }
        else if (popup.isOrigin() && options.origin instanceof HTMLElement && options.origin.tagName) {
            this.openedPopupOrigin = new CdkOverlayOrigin(new ElementRef(options.origin));
        }
        else {
            this.openedPopupOrigin = undefined;
        }
        this.cd.detectChanges();
        var index = this.viewSettings.popups.findIndex(function (item) { return item.uid == popup.uid; });
        if (index === -1) {
            return;
        }
        var element = this.popupElements.toArray()[index];
        var overlay = this.popupOverlayElements.toArray()[index];
        if (!element || !overlay) {
            return;
        }
        var popupComponent = this.popupComponents.find(function (item) { return item.popup === popup; });
        if (popupComponent && options.params) {
            popupComponent.setContextOutputValues(options.params);
        }
        this.updateWindowScroll();
        this.scrollPopupToTop(index);
        popup.openActions.forEach(function (action) {
            _this.actionControllerService
                .execute(action, {
                context: _this.context,
                injector: _this.injector
            })
                .subscribe();
        });
        if (options.openComponents && this.customizeService.enabled) {
            this.customizeBarContext.resetSettingsComponent();
        }
        else if (options.customize && this.customizeService.enabled) {
            if (popupComponent) {
                popupComponent.customize();
            }
        }
        TweenMax.fromTo(overlay.nativeElement, 0.15, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        });
        if (popup.isSide()) {
            TweenMax.fromTo(element.nativeElement, 0.6, {
                xPercent: 100
            }, {
                xPercent: 0,
                ease: Power2.easeOut
            });
        }
        else {
            TweenMax.fromTo(element.nativeElement, 0.15, {
                y: -10,
                opacity: 0
            }, {
                y: 0,
                opacity: 1
            });
        }
    };
    CustomPagePopupsComponent.prototype.scrollPopupToTop = function (index) {
        var popup = this.viewSettings.popups[index];
        if (!popup) {
            return;
        }
        var scrollableElement = popup.isSide()
            ? this.popupElements.toArray()[index]
            : this.popupContainerElements.toArray()[index];
        if (!scrollableElement) {
            return;
        }
        scrollTo(scrollableElement.nativeElement, 0);
    };
    CustomPagePopupsComponent.prototype.createPopup = function (open, options) {
        if (open === void 0) { open = false; }
        if (options === void 0) { options = {}; }
        var defaultName = isSet(options.defaultName) ? options.defaultName : 'Modal';
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (this.viewSettings.popups.find(function (item) { return item.name.toLowerCase() == newName.toLowerCase(); }));
        var popup = new PopupSettings();
        popup.generateUid();
        popup.name = newName;
        if (options.width) {
            popup.width = options.width;
        }
        if (options.style) {
            popup.style = options.style;
        }
        if (options.position) {
            popup.position = options.position;
        }
        if (isSet(options.overlay)) {
            popup.overlay = options.overlay;
        }
        this.viewSettings.popups = this.viewSettings.popups.concat([popup]);
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Created, {
            Source: options.analyticsSource
        });
        if (open) {
            this.openPopup(popup.uid, { openComponents: true });
        }
        return popup;
    };
    CustomPagePopupsComponent.prototype.createPopupFromToolbar = function (open, options) {
        if (open === void 0) { open = false; }
        if (options === void 0) { options = {}; }
        return this.createPopup(open, __assign({ analyticsSource: this.analyticsSource }, options));
    };
    CustomPagePopupsComponent.prototype.duplicatePopup = function (uid, options) {
        var existingPopup = this.getPopup(uid);
        if (!existingPopup) {
            return;
        }
        var defaultName = existingPopup.name + " Copy";
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (this.viewSettings.popups.find(function (item) { return item.name.toLowerCase() == newName.toLowerCase(); }));
        var popup = new PopupSettings();
        popup.generateUid();
        popup.patch(existingPopup);
        popup.name = newName;
        popup.elements = cloneDeep(existingPopup.elements);
        generateUidAndNamesRecursive(popup.elements, this.context, { forceNewUid: true });
        this.viewSettings.popups = this.viewSettings.popups.concat([popup]);
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Duplicated, {
            Source: options.analyticsSource
        });
        if (options.open) {
            this.openPopup(popup.uid, options.customize ? { customize: true } : { openComponents: true });
        }
        return popup;
    };
    CustomPagePopupsComponent.prototype.duplicatePopupFromToolbar = function (uid, options) {
        return this.duplicatePopup(uid, __assign({ analyticsSource: this.analyticsSource }, options));
    };
    CustomPagePopupsComponent.prototype.moveToPage = function (uid, page, options) {
        var _this = this;
        var existingPopup = this.getPopup(uid);
        if (!existingPopup) {
            return;
        }
        this.sessionStorage.set(BUILDER_ADD_ELEMENT, JSON.stringify({
            popup: existingPopup.serialize()
        }));
        this.deletePopupProcess(uid);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(page.link); });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.MovedToPage, {
            Source: options.analyticsSource
        });
    };
    CustomPagePopupsComponent.prototype.moveToPageFromToolbar = function (uid, page, options) {
        return this.moveToPage(uid, page, __assign({ analyticsSource: this.analyticsSource }, options));
    };
    CustomPagePopupsComponent.prototype.updatePopups = function () {
        this.viewSettings.popups = this.viewSettings.popups.slice();
        this.customizeService.markChanged();
        this.cd.markForCheck();
    };
    CustomPagePopupsComponent.prototype.deletePopup = function (uid, force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (force) {
            this.deletePopupProcess(uid);
            return;
        }
        this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete Overlay?",
            style: 'orange'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result) {
                return;
            }
            _this.deletePopupProcess(uid);
        });
    };
    CustomPagePopupsComponent.prototype.deletePopupProcess = function (uid) {
        this.closePopup(uid, false);
        var popup = this.getPopup(uid);
        if (!popup) {
            return;
        }
        this.viewSettings.popups = this.viewSettings.popups.filter(function (item) { return item !== popup; });
        this.customizeService.markChanged();
        this.cd.markForCheck();
    };
    CustomPagePopupsComponent.prototype.closePopup = function (uid, animate) {
        var _this = this;
        if (animate === void 0) { animate = true; }
        var popup = this.openedPopup;
        if (!popup) {
            return;
        }
        if (uid && popup.uid != uid) {
            return;
        }
        var index = this.viewSettings.popups.findIndex(function (item) { return item.uid == popup.uid; });
        if (index === -1) {
            this.onPopupClosed(popup);
            return;
        }
        var element = this.popupElements.toArray()[index];
        var overlay = this.popupOverlayElements.toArray()[index];
        if ((!element || !overlay) && !popup.isOrigin()) {
            return;
        }
        popup.closeActions.forEach(function (action) {
            _this.actionControllerService
                .execute(action, {
                context: _this.context,
                injector: _this.injector
            })
                .subscribe();
        });
        if (animate && !popup.isOrigin()) {
            TweenMax.to(overlay.nativeElement, 0.2, {
                opacity: 0
            });
            if (this.customizeService.enabled && !popup.isOrigin()) {
                TweenMax.to(element.nativeElement, 0.4, {
                    x: -1 * 0.5 * window.innerWidth + 400,
                    y: -1 * 0.5 * window.innerHeight - 50,
                    scale: 0,
                    opacity: 0,
                    clearProps: 'transform,opacity',
                    ease: Power2.easeIn,
                    onComplete: function () { return _this.onPopupClosed(popup); }
                });
            }
            else {
                if (popup.isSide()) {
                    TweenMax.to(element.nativeElement, 0.4, {
                        xPercent: 100,
                        ease: Power2.easeOut,
                        onComplete: function () { return _this.onPopupClosed(popup); }
                    });
                }
                else {
                    TweenMax.to(element.nativeElement, 0.15, {
                        opacity: 0,
                        y: 10,
                        onComplete: function () { return _this.onPopupClosed(popup); }
                    });
                }
            }
        }
        else {
            this.onPopupClosed(popup);
        }
    };
    CustomPagePopupsComponent.prototype.updatePopup = function (uid, newPopup) {
        var popup = this.getPopup(uid);
        var popupComponent = popup ? this.popupComponents.find(function (item) { return item.popup === popup; }) : undefined;
        if (!popupComponent) {
            return;
        }
        popupComponent.onCustomized(newPopup);
    };
    CustomPagePopupsComponent.prototype.updateWindowScroll = function () {
        if (this.openedPopup && this.openedPopup.overlay) {
            this.scrollService.disableWindowScroll();
        }
        else {
            this.scrollService.enableWindowScroll();
        }
    };
    CustomPagePopupsComponent.prototype.onPopupClosed = function (popup) {
        if (this.openedPopupUid != popup.uid) {
            return;
        }
        this.openedPopupUid = undefined;
        this.openedPopupOrigin = undefined;
        this.cd.markForCheck();
        this.updateWindowScroll();
    };
    CustomPagePopupsComponent.prototype.onPopupContainerClicked = function (item) {
        var closeEnabled = this.customizeService.enabled ? true : item.closeOnBlur;
        if (this.mouseDownContainer && closeEnabled) {
            this.closePopup();
        }
    };
    CustomPagePopupsComponent.prototype.onPopupContainerMouseDown = function (event) {
        this.mouseDownContainer = !isCustomPagePopupClickEvent(event);
    };
    return CustomPagePopupsComponent;
}());
export { CustomPagePopupsComponent };

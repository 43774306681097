import { ActionItem } from '@modules/actions';
import { ImageFieldType } from '@modules/field-components';
import { ImageFit, ImageOutputFormat, Input } from '@modules/fields';

import { AlignHorizontal } from '../../align-horizontal';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export class ImageElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Image;
  public alignHorizontalDefault = AlignHorizontal.Left;

  public width: number;
  public height: number;
  public valueInput: Input;
  public outputFormat: ImageOutputFormat;
  public storageResource: string;
  public storageName: string;
  public path: Input;
  public displayType: ImageFieldType;
  public fit: ImageFit;
  public showPreview = false;
  public lightbox = false;
  public clickAction: ActionItem;

  deserialize(data: Object): ImageElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.storageResource = this.params['storage_resource'];
    this.storageName = this.params['storage_name'];
    this.displayType = this.params['display_type'];
    this.fit = this.params['fit'];
    this.showPreview = this.params['show_preview'];
    this.lightbox = this.params['lightbox'];

    if (this.params['output_format']) {
      this.outputFormat = this.params['output_format'];
    } else if (this.params['storage_resource']) {
      this.outputFormat = ImageOutputFormat.Storage;
    }

    if (this.params['value_input']) {
      this.valueInput = new Input().deserialize(this.params['value_input']);
    } else if (this.params['url']) {
      this.valueInput = new Input().deserializeFromStatic('value', this.params['url']);
    } else {
      this.valueInput = undefined;
    }

    if (this.params['path']) {
      this.path = new Input().deserialize(this.params['path']);
    } else {
      this.path = undefined;
    }

    if (this.params['click_action']) {
      this.clickAction = new ActionItem().deserialize(this.params['click_action']);
    } else {
      this.clickAction = undefined;
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      width: this.width,
      height: this.height,
      value_input: this.valueInput ? this.valueInput.serialize() : undefined,
      output_format: this.outputFormat,
      storage_resource: this.storageResource,
      storage_name: this.storageName,
      display_type: this.displayType,
      fit: this.fit,
      show_preview: this.showPreview,
      lightbox: this.lightbox,
      click_action: this.clickAction ? this.clickAction.serialize() : undefined
    };
    return super.serialize();
  }

  get analyticsName(): string {
    return 'image';
  }

  defaultName() {
    return 'Image';
  }
}

registerElementForType(ElementType.Image, ImageElementItem);

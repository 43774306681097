import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { PopoverService } from '@common/popover';
import { createFormFieldFactory } from '@modules/fields';

import { ParameterFieldsEditItemComponent } from './parameter-fields-edit-item/parameter-fields-edit-item.component';
import { ParameterFieldsEditForm } from './parameter-fields-edit.form';

@Component({
  selector: 'app-parameter-fields-edit',
  templateUrl: './parameter-fields-edit.component.html',
  providers: [ParameterFieldsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterFieldsEditComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() customizable = false;

  createField = createFormFieldFactory();

  constructor(
    public form: ParameterFieldsEditForm,
    private popoverService: PopoverService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.form.init(this.control);
  }

  requestDelete(form: FormGroup) {
    this.form.arrayDelete(form);
  }

  openArraySettings(form: FormGroup, sourceElement) {
    this.popoverService.create(
      {
        component: ParameterFieldsEditItemComponent,
        inputs: {
          form: form
        },
        outputs: {
          deleteRequested: [
            () => {
              this.form.arrayDelete(form);
            }
          ]
        },
        injector: this.injector
      },
      sourceElement
    );
  }
}

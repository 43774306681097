import pickBy from 'lodash/pickBy';

export class WorkflowStepRun {
  public uid: string;
  public params: Object;
  public result: any;
  public error: any;

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.params = data['params'];
    this.result = data['result'];
    this.error = data['error'];

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      params: this.params,
      result: this.result,
      error: this.error
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectsStore } from '@modules/projects';
import { capitalize, isSet } from '@shared';

export interface MetaDataIcon {
  w16?: string;
  w32?: string;
  w180?: string;
  default: string;
}

export interface MetaData {
  title?: string | string[];
  titleShort?: string;
  shareTitle?: string;
  description?: string;
  image?: string;
  icon?: MetaDataIcon;
  manifest?: string;
  color?: string;
  maskIcon?: string;
  msApplicationConfig?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  public readonly jetMetaData: MetaData = {
    titleShort: 'Jet Admin',
    shareTitle: 'Jet | Back office totally ready to run your service',
    description: 'Save time and money on the design, development and support of your own back office.',
    image: 'assets/favicon/apple-touch-icon.png',
    icon: {
      w180: 'assets/favicon/apple-touch-icon.png',
      w32: 'assets/favicon/favicon-32x32.png',
      w16: 'assets/favicon/favicon-16x16.png',
      default: 'assets/favicon/apple-touch-icon.png'
    },
    manifest: 'assets/favicon/site.webmanifest',
    color: '#2b50ed',
    maskIcon: 'assets/favicon/safari-pinned-tab.svg',
    msApplicationConfig: 'assets/favicon/browserconfig.xml'
  };

  private _title = new BehaviorSubject<string[]>(undefined);
  private _defaultMetaData = new BehaviorSubject<MetaData>({});

  constructor(private titleService: Title, private projectsStore: ProjectsStore, private meta: Meta) {
    this.title$.subscribe(value => {
      const title = this.titleFormat(value);
      this.tagTitle = title.join(' · ');
    });
  }

  resetMeta() {
    this.set({});
  }

  set(meta: MetaData) {
    meta = {
      ...this.defaultMetaData,
      ...meta
    };

    let title: string[];

    if (meta.title instanceof Array) {
      title = meta.title as string[];
    } else {
      title = [meta.title as string];
    }

    this.title = title.filter(item => isSet(item));
    this.tagDescription = meta.description;
    this.tagOgTitle = meta.shareTitle;
    this.tagOgDescription = meta.description;
    this.tagOgImage = meta.image;
    this.tagIcon = meta.icon;
    this.manifest = meta.manifest;
    this.maskIcon = meta.maskIcon;
    this.color = meta.color;
    this.msApplicationConfig = meta.msApplicationConfig;
  }

  titleFormat(value) {
    let title = value || [];
    let trailing: string;

    if (this.projectsStore.current) {
      trailing = this.projectsStore.current.name;
    } else if (this.defaultMetaData) {
      trailing = this.defaultMetaData.titleShort;
    }

    title = title.map(item => capitalize(item));

    if (trailing) {
      title.push(trailing);
    }

    return title;
  }

  get title(): string[] {
    return this._title.value;
  }

  get title$(): Observable<string[]> {
    return this._title.asObservable();
  }

  set title(value: string[]) {
    this._title.next(value);
  }

  get titlePrimary$(): Observable<string> {
    return this.title$.pipe(
      map(value => {
        const title = this.titleFormat(value);
        return title[0];
      })
    );
  }

  get titlePrimary(): string {
    const title = this.titleFormat(this.title);
    return title[0];
  }

  get defaultMetaData(): MetaData {
    return this._defaultMetaData.value;
  }

  get defaultMetaData$(): Observable<MetaData> {
    return this._defaultMetaData.asObservable();
  }

  set defaultMetaData(value: MetaData) {
    this._defaultMetaData.next({
      ...value,
      titleShort: isSet(value.titleShort) ? value.titleShort : this.jetMetaData.titleShort,
      icon: isSet(value.icon) ? value.icon : this.jetMetaData.icon
    });
  }

  setJetDefaultMetaData() {
    this._defaultMetaData.next(this.jetMetaData);
  }

  set tagTitle(value: string) {
    this.titleService.setTitle(value);
  }

  set tagDescription(value: string) {
    this.updateMetaTagContent('description', value);
  }

  set tagOgTitle(value: string) {
    this.updateMetaTagContent('og:title', value);
  }

  set tagOgDescription(value: string) {
    this.updateMetaTagContent('og:description', value);
  }

  set tagOgImage(value: string) {
    this.updateMetaTagContent('og:image', value);
  }

  set tagIcon(value: MetaDataIcon) {
    const tags = [
      { query: 'link[sizes="180x180"]', size: 'w180' },
      { query: 'link[sizes="32x32"]', size: 'w32' },
      { query: 'link[sizes="16x16"]', size: 'w16' },
      { query: 'link[rel~="shortcut"]', size: 'w16' }
    ];

    tags.forEach(tag => {
      let image = '';

      if (value && value[tag.size]) {
        image = value[tag.size];
      } else if (value) {
        image = value.default;
      }

      this.updateElementAttribute(tag.query, 'href', image);
    });
  }

  set manifest(value: string) {
    this.updateElementAttribute('link[rel="manifest"]', 'href', value);
  }

  set maskIcon(value: string) {
    this.updateElementAttribute('link[rel="mask-icon"]', 'href', value);
  }

  set color(value: string) {
    this.updateElementAttribute('link[rel="mask-icon"]', 'color', value);
    this.updateElementAttribute('meta[name="msapplication-TileColor"]', 'content', value);
    this.updateElementAttribute('meta[name="theme-color"]', 'content', value);
  }

  set msApplicationConfig(value: string) {
    this.updateElementAttribute('meta[name="msapplication-config"]', 'content', value);
  }

  updateMetaTagContent(property: string, value: string) {
    this.meta.updateTag({ property: property, content: isSet(value) ? value : '' });
  }

  updateElementAttribute(selector: string, name: string, value: string) {
    const element = document.querySelector(selector);

    if (!element) {
      return;
    }

    element.setAttribute(name, isSet(value) ? value : '');
  }
}

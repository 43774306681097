import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { getFontUrlByName } from '@modules/theme';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-external-fonts',
  templateUrl: './external-fonts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalFontsComponent implements OnInit, OnChanges {
  @Input() fonts: string[] = [];

  safeUrls: SafeResourceUrl[] = [];

  trackUrl = (() => {
    return (i, item: SafeResourceUrl) => {
      return item;
    };
  })();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ExternalFontsComponent>): void {
    this.safeUrls = this.fonts
      .filter(item => isSet(item))
      .map(item => {
        const url = getFontUrlByName(item);
        return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : undefined;
      })
      .filter(item => isSet(item));
  }
}

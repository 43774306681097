var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { WorkflowExecuteEventType } from '@modules/action-queries';
import { ActionItem, ActionType } from '@modules/actions';
import { SidebarTabsComponent } from '@modules/sidebar';
import { WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';
import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeBarActionEditTypeComponent } from '../../../customize-bar-action-edit/customize-bar-action-edit-type/customize-bar-action-edit-type.component';
import { WorkflowStepStatus } from '../../steps/base-workflow-step/base-workflow-step.component';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { ActionCustomizeWorkflowStepForm } from './action-customize-workflow-step.form';
var ActionCustomizeWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(ActionCustomizeWorkflowStepComponent, _super);
    function ActionCustomizeWorkflowStepComponent(form, workflowEditContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.workflowEditContext = workflowEditContext;
        _this.cd = cd;
        _this.submitActionClass = ActionItem;
        _this.status = WorkflowStepStatus.NotExecuted;
        _this.statuses = WorkflowStepStatus;
        _this.actionTypes = ActionType;
        return _this;
    }
    ActionCustomizeWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.step, { backgroundWorkflow: this.backgroundWorkflow, context: this.context, firstInit: false });
        this.operationTypeValid$ = this.form.controls.action.operationTypeValid$();
        this.getOperation$ = this.form.controls.action.getActionDescription$().pipe(map(function (actionDescription) {
            return actionDescription && actionDescription.modelAction == 'get';
        }));
        this.trackRun();
        this.trackExecuteStatus();
        if (this.workflowEditable) {
            this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.submit(result);
            });
        }
    };
    ActionCustomizeWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    ActionCustomizeWorkflowStepComponent.prototype.trackRun = function () {
        var _this = this;
        this.workflowEditContext.run$
            .pipe(map(function (run) {
            if (!run) {
                return;
            }
            return run.stepRuns.find(function (item) { return item.uid == _this.step.uid; });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.stepRun = value;
            if (_this.stepRun) {
                if (isSet(value.error)) {
                    _this.status = WorkflowStepStatus.Failed;
                }
                else {
                    _this.status = WorkflowStepStatus.Success;
                }
            }
            _this.cd.markForCheck();
        });
    };
    ActionCustomizeWorkflowStepComponent.prototype.trackExecuteStatus = function () {
        var _this = this;
        this.workflowEditContext.testExecuteEvents$.pipe(untilDestroyed(this)).subscribe(function (event) {
            if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
                _this.status = WorkflowStepStatus.NotExecuted;
            }
            else if (event.type == WorkflowExecuteEventType.StepStarted && event.step.uid == _this.step.uid) {
                _this.status = WorkflowStepStatus.Executing;
            }
            else if (event.type == WorkflowExecuteEventType.StepFinished && event.step.uid == _this.step.uid) {
                if (event.success) {
                    _this.status = WorkflowStepStatus.Success;
                }
                else {
                    _this.status = WorkflowStepStatus.Failed;
                }
            }
            _this.cd.markForCheck();
        });
    };
    ActionCustomizeWorkflowStepComponent.prototype.addQueryInput = function () {
        if (this.editTypeComponent) {
            this.editTypeComponent.addQueryInput();
        }
    };
    ActionCustomizeWorkflowStepComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.stepChange.emit(value);
    };
    ActionCustomizeWorkflowStepComponent.prototype.execute = function () {
        this.stepExecute.emit();
        this.tabsComponent.setVisibleTab(this.tabsComponent.tabs[1]);
    };
    return ActionCustomizeWorkflowStepComponent;
}(CustomizeWorkflowStepComponent));
export { ActionCustomizeWorkflowStepComponent };
registerCustomizeWorkflowStepComponent(WorkflowStepType.Action, ActionCustomizeWorkflowStepComponent);

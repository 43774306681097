import { Injectable } from '@angular/core';
import keys from 'lodash/keys';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class CookieStorage {
  constructor(private cookieService: CookieService) {}

  public get(key: string) {
    return this.cookieService.get(key);
  }

  public set(key: string, value: string) {
    const expires = new Date();
    expires.setDate(expires.getDate() + 30);

    this.cookieService.put(key, value, { expires: expires });
  }

  public remove(key: string) {
    this.cookieService.remove(key);
  }

  public keys(): string[] {
    return keys(this.cookieService.getAll());
  }
}

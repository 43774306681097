import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { OutputFormat } from '@modules/fields';

@Injectable()
export class DateTimeFieldDataParamsForm {
  form: FormGroup;
  control: AbstractControl;

  outputFormat = [
    { value: OutputFormat.ISO, name: 'ISO 8601' },
    { value: OutputFormat.ISODate, name: 'ISO 8601 date only' },
    { value: OutputFormat.Timestamp, name: 'Unix Timestamp' },
    { value: OutputFormat.TimestampMillisecond, name: 'Unix Millisecond Timestamp' },
    { value: OutputFormat.SerialNumber, name: 'Google Sheets' },
    { value: OutputFormat.SerialNumberDate, name: 'Google Sheets date only' },
    { value: OutputFormat.String, name: 'Formatted string' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output_format: new FormControl(OutputFormat.ISO)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = this.form.value;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

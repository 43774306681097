<div class="flex-layout-editor">
  <div class="flex-layout-editor__controls">
    <div class="compact-inputs-element">
      <app-compact-segmented-control
        [formControl]="control.controls.orientation_option"
        [options]="control.orientationOptions"
      ></app-compact-segmented-control>
    </div>

    <div *ngIf="!vertical" class="compact-inputs-element">
      <div class="compact-heading">
        Horizontal gap
      </div>

      <app-compact-input
        [formControl]="control.controls.gap_horizontal"
        [postfix]="'px'"
        [placeholder]="'0'"
        [widthPx]="62"
        [number]="true"
        [numberOptions]="{ min: 0 }"
      ></app-compact-input>
    </div>

    <div *ngIf="vertical" class="compact-inputs-element">
      <div class="compact-heading">
        Vertical gap
      </div>

      <app-compact-input
        [formControl]="control.controls.gap_vertical"
        [postfix]="'px'"
        [placeholder]="'0'"
        [widthPx]="62"
        [number]="true"
        [numberOptions]="{ min: 0 }"
      ></app-compact-input>
    </div>
  </div>

  <div class="flex-layout-editor__items">
    <div class="compact-inputs-element">
      <div class="flex-layout-editor-items">
        <div *ngFor="let row of aligns" class="flex-layout-editor-items__row">
          <div *ngFor="let cell of aligns" class="flex-layout-editor-items__cell">
            <div
              class="flex-layout-editor-item"
              [class.flex-layout-editor-item_vertical]="vertical"
              [class.flex-layout-editor-item_align_start]="
                !vertical ? row == flexAligns.Start : cell == flexAligns.Start
              "
              [class.flex-layout-editor-item_align_center]="
                !vertical ? row == flexAligns.Center : cell == flexAligns.Center
              "
              [class.flex-layout-editor-item_align_end]="!vertical ? row == flexAligns.End : cell == flexAligns.End"
              [class.flex-layout-editor-item_active]="
                control.controls.align_vertical.value == row && control.controls.align_horizontal.value == cell
              "
              (click)="
                control.controls.align_horizontal.patchValue(cell); control.controls.align_vertical.patchValue(row)
              "
            >
              <div class="flex-layout-editor-item__dot"></div>
              <div class="flex-layout-editor-item__line flex-layout-editor-item__line_size_m"></div>
              <div class="flex-layout-editor-item__line flex-layout-editor-item__line_size_l"></div>
              <div class="flex-layout-editor-item__line flex-layout-editor-item__line_size_s"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="compact-inputs-element">
  <div class="compact-heading">
    Padding
  </div>

  <app-flex-layout-padding-control [formControl]="control.controls.padding"></app-flex-layout-padding-control>
</div>

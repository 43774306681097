import { Injectable } from '@angular/core';

import { RawListViewSettingsColumn } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { Input, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Resource } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';

import { autoInputsFromModelGet } from '../../data/input-field-provider';
import { autoParametersFromModelGet } from '../../data/input-field-provider';

@Injectable()
export class InputService {
  constructor(private modelDescriptionStore: ModelDescriptionStore) {}

  parametersFromModelGet(
    resource: Resource,
    getQuery?: ListModelDescriptionQuery,
    columns: RawListViewSettingsColumn[] = [],
    type?: DataSourceType
  ): ParameterField[] {
    const queryModelId =
      resource && getQuery && getQuery.queryType == QueryType.Simple && getQuery.simpleQuery
        ? [resource.uniqueName, getQuery.simpleQuery.model].join('.')
        : undefined;
    const queryModelDescription = queryModelId
      ? (this.modelDescriptionStore.instance || []).find(item => item.isSame(queryModelId))
      : undefined;

    return [
      ...(queryModelDescription ? queryModelDescription.getParameters : []),
      ...autoParametersFromModelGet(resource, queryModelDescription, getQuery, columns, type)
    ];
  }

  inputsFromModelGet(
    resource: Resource,
    getQuery?: ListModelDescriptionQuery,
    columns: RawListViewSettingsColumn[] = [],
    type?: DataSourceType
  ): Input[] {
    const queryModelId =
      resource && getQuery && getQuery.queryType == QueryType.Simple && getQuery.simpleQuery
        ? [resource.uniqueName, getQuery.simpleQuery.model].join('.')
        : undefined;
    const queryModelDescription = queryModelId
      ? (this.modelDescriptionStore.instance || []).find(item => item.isSame(queryModelId))
      : undefined;

    return autoInputsFromModelGet(resource, queryModelDescription, getQuery, columns, type);
  }
}

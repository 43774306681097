import isArray from 'lodash/isArray';

import { isSet } from '@shared';

export function deserializeSingleFileValue(value: any): string {
  if (!isSet(value)) {
    return;
  } else if (isArray(value)) {
    return deserializeSingleFileValue(value[0]);
  } else if (typeof value === 'string') {
    return value;
  } else {
    return;
  }
}

export function deserializeMultipleFileValue(value: any): string[] {
  if (!isSet(value)) {
    return [];
  } else if (isArray(value)) {
    return value.map(item => deserializeSingleFileValue(item));
  } else if (typeof value === 'string') {
    return (value as string).split(',').map(item => item.trim());
  } else {
    return [];
  }
}

import { Injectable } from '@angular/core';

import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

import { AnalyticsEvent, AnalyticsEventAction } from '../../data/event';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { UniversalAnalyticsService } from '../universal-analytics/universal-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private currentUserStore: CurrentUserStore,
    private projectsStore: ProjectsStore,
    private googleAnalyticsService: GoogleAnalyticsService,
    private universalAnalyticsService: UniversalAnalyticsService
  ) {
    this.currentUserStore.getFirst().subscribe(() => {
      this.universalAnalyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.AppOpen);
    });

    this.currentUserStore.instance$.subscribe(user => {
      if (!user) {
        return;
      }

      this.universalAnalyticsService.sendEvent(AnalyticsEvent.GA.CurrentUser, AnalyticsEventAction.Init, user.username);

      // this.projectsStore.getFirst().subscribe(projects => {
      this.universalAnalyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.UserSet, {
        FirstName: user.firstName,
        LastName: user.lastName
        // ProjectsCreated: projects ? projects.filter(item => item.isCreated).length : 0
      });
      // });

      googleAnalyticsService.clientId$.subscribe(clientId => {
        if (!clientId) {
          return;
        }

        if (
          !this.currentUserStore.instance.isAnonymous() &&
          this.currentUserStore.instance.params.addGoogleAnalyticsClientId(googleAnalyticsService.clientId)
        ) {
          this.currentUserStore.update(['params']).subscribe();
        }
      });
    });
  }
}

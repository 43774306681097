var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { createFormFieldFactory, FieldOutput, FieldType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
var CustomizeWorkflowTriggerOutputComponent = /** @class */ (function () {
    function CustomizeWorkflowTriggerOutputComponent(cd) {
        this.cd = cd;
        this.openedInitial = false;
        this.autofocus = false;
        this.valueUpdated = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.opened = false;
        this.placeholder = 'Test value';
        this.fieldTypes = FieldType;
        this.valueControl = new FormControl('');
        this.valueControlForm = new FormGroup({ control: this.valueControl });
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    CustomizeWorkflowTriggerOutputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.valueControl.valueChanges
            .pipe(debounceTime(200), untilDestroyed(this))
            .subscribe(function (value) { return _this.valueUpdated.emit(value); });
    };
    CustomizeWorkflowTriggerOutputComponent.prototype.ngOnDestroy = function () { };
    CustomizeWorkflowTriggerOutputComponent.prototype.ngOnChanges = function (changes) {
        if (changes.collapseContext) {
            this.initCollapseContext();
        }
        if (changes.item) {
            this.itemParams = this.getItemParams();
        }
        if (changes.value) {
            this.valueControl.patchValue(this.value, { emitEvent: false });
        }
    };
    CustomizeWorkflowTriggerOutputComponent.prototype.initCollapseContext = function () {
        var _this = this;
        if (this.initCollapseContextSubscription) {
            this.initCollapseContextSubscription.unsubscribe();
            this.initCollapseContextSubscription = undefined;
        }
        if (this.collapseContext) {
            this.initCollapseContextSubscription = this.collapseContext.opened$
                .pipe(untilDestroyed(this))
                .subscribe(function (opened) {
                _this.opened = opened === _this;
                _this.cd.markForCheck();
            });
        }
        if (this.openedInitial) {
            this.setOpened(true);
        }
    };
    CustomizeWorkflowTriggerOutputComponent.prototype.setOpened = function (opened) {
        if (this.collapseContext) {
            if (opened && this.collapseContext.opened !== this) {
                this.collapseContext.opened = this;
            }
            else if (!opened && this.collapseContext.opened === this) {
                this.collapseContext.opened = undefined;
            }
        }
        else {
            this.opened = opened;
            this.cd.markForCheck();
        }
    };
    CustomizeWorkflowTriggerOutputComponent.prototype.toggleOpened = function () {
        this.setOpened(!this.opened);
    };
    CustomizeWorkflowTriggerOutputComponent.prototype.getItemParams = function () {
        return __assign({}, (this.item ? this.item.params : {}), { classes: [
                'select_fill',
                'input_fill'
            ].concat((this.item && this.item.params['classes'] ? this.item.params['classes'] : [])), background: true });
    };
    return CustomizeWorkflowTriggerOutputComponent;
}());
export { CustomizeWorkflowTriggerOutputComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var ChangeCustomViewForm = /** @class */ (function () {
    function ChangeCustomViewForm(formUtils, currentProjectStore, currentEnvironmentStore, customViewService, customViewsStore, fb) {
        this.formUtils = formUtils;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.customViewService = customViewService;
        this.customViewsStore = customViewsStore;
        this.fb = fb;
        this.viewTypeOptions = [
            { value: CustomViewType.Common, name: 'Common' },
            { value: CustomViewType.ModelDescription, name: 'Collection' },
            { value: CustomViewType.Model, name: 'Record' }
        ];
        this.init(undefined);
    }
    ChangeCustomViewForm.prototype.init = function (instance) {
        this.instance = instance;
        this.form = this.fb.group({
            unique_name: new FormControl('jet-', Validators.required),
            name: new FormControl('', Validators.required),
            view_type: new FormControl('common', Validators.required),
            model_description: new FormControl(),
            dist: new FormControl('', Validators.required),
            params: new FormControl({}, Validators.required)
        });
        if (instance) {
            this.form.patchValue({
                unique_name: instance.uniqueName,
                name: instance.name,
                view_type: instance.viewType,
                model_description: instance.params['model_description']
                    ? { model: instance.params['model_description'] }
                    : instance.params['model_description'],
                dist: instance.dist,
                params: instance.params
            });
        }
        else {
            this.form.patchValue({ model_description: undefined }); // workaround to set undefined
        }
    };
    ChangeCustomViewForm.prototype.submit = function () {
        var _this = this;
        this.form.markAsDirty();
        var value = this.form.value;
        var instance = new CustomView();
        var params = __assign({}, value['params'], { model_description: value['model_description'] ? value['model_description']['model'] : value['model_description'] });
        instance.uniqueName = value['unique_name'];
        instance.name = value['name'];
        instance.viewType = value['view_type'];
        instance.dist = value['dist'];
        instance.params = params;
        var obs;
        if (this.instance) {
            obs = this.customViewService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        else {
            obs = this.customViewService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        obs = obs.pipe(switchMap(function () { return _this.customViewsStore.getDetail(instance.uniqueName, true); }));
        obs.subscribe(function () { }, function (error) { return _this.formUtils.showFormErrors(_this.form, error); });
        return obs;
    };
    return ChangeCustomViewForm;
}());
export { ChangeCustomViewForm };

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum MenuSection {
  Default,
  Project,
  None
}

export enum MenuCustomSection {
  Pages = 'pages',
  Data = 'data',
  Layout = 'layout',
  SignUp = 'sign_up',
  Automations = 'automations',
  Collaboration = 'Collaboration'
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  _opened = new BehaviorSubject<boolean>(false);
  _section = new BehaviorSubject<MenuSection>(MenuSection.Default);
  _customizeBarOpened = new BehaviorSubject<boolean>(false);

  constructor() {}

  set opened(value: boolean) {
    this._opened.next(value);
  }

  get opened(): boolean {
    return this._opened.value;
  }

  get opened$(): Observable<boolean> {
    return this._opened.asObservable();
  }

  toggleOpened() {
    this.opened = !this.opened;
  }

  set section(value: MenuSection) {
    this._section.next(value);
  }

  get section(): MenuSection {
    return this._section.value;
  }

  get section$(): Observable<MenuSection> {
    return this._section.asObservable();
  }

  set customizeBarOpened(value: boolean) {
    this._customizeBarOpened.next(value);
  }

  get customizeBarOpened(): boolean {
    return this._customizeBarOpened.value;
  }

  get customizeBarOpened$(): Observable<boolean> {
    return this._customizeBarOpened.asObservable();
  }
}

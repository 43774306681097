import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalizeDirective } from './directives/localize/localize.directive';
import { LocalizeAdvPipe } from './pipes/localize-adv/localize-adv.pipe';
import { LocalizeSelectPipe } from './pipes/localize-select/localize-select.pipe';
import { LocalizePipe } from './pipes/localize/localize.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LocalizePipe, LocalizeSelectPipe, LocalizeDirective, LocalizeAdvPipe],
  exports: [LocalizePipe, LocalizeSelectPipe, LocalizeDirective, LocalizeAdvPipe]
})
export class LocalizeModule {}

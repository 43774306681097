import { OnInit } from '@angular/core';
import { Environment, Project } from '@modules/projects';
var InstallInstructionsDjangoComponent = /** @class */ (function () {
    function InstallInstructionsDjangoComponent() {
    }
    InstallInstructionsDjangoComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(InstallInstructionsDjangoComponent.prototype, "environmentName", {
        get: function () {
            if (!this.environment) {
                return;
            }
            if (this.project.environments.length <= 1) {
                return;
            }
            return this.environment.uniqueName;
        },
        enumerable: true,
        configurable: true
    });
    return InstallInstructionsDjangoComponent;
}());
export { InstallInstructionsDjangoComponent };

<div
  *ngIf="!valueEditable"
  class="sidebar-list-item"
  [class.sidebar-list-item_draggable]="configurable.sortable"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
>
  <div *ngIf="configurable.sortable" class="sidebar-list-item__drag"></div>

  <div *ngIf="configurable.field && !configurable.params" class="sidebar-list-item__left">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="fieldTypeDropdownPositions"
      [cdkConnectedOverlayOrigin]="dropdownTrigger"
      [cdkConnectedOverlayOpen]="fieldTypeDropdownOpened"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="setFieldTypeDropdownOpened(false)"
      (detach)="setFieldTypeDropdownOpened(false)"
      #field_type_overlay
    >
      <app-field-type-dropdown
        class="overlay-content overlay-content_animated"
        [currentValue]="control.controls.field.value"
        (select)="setFieldTypeDropdownOpened(false); control.controls.field.patchValue($event)"
      ></app-field-type-dropdown>
    </ng-template>

    <a
      href="javascript:void(0)"
      class="button button_icon button_orange button_extra-small"
      cdkOverlayOrigin
      #dropdownTrigger="cdkOverlayOrigin"
      (click)="setFieldTypeDropdownOpened(true)"
      style="margin: 0;"
    >
      <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
    </a>
  </div>

  <div *ngIf="configurable.params" class="sidebar-list-item__left">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - 8 },
        { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 + 8 },
        { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 8 + 8, offsetY: 0 }
      ]"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="popoverOpened"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="setPopoverOpened(false)"
      (detach)="setPopoverOpened(false)"
    >
      <div (cdkObserveContent)="onPopoverContentChanged()">
        <div class="popover2">
          <div class="popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse">
            <div class="popover2__viewport popover2__viewport_scrollable">
              <app-fields-edit-item
                [control]="control"
                [configurable]="{}"
                (saveRequested)="setPopoverOpened(false)"
                (deleteRequested)="deleteRequested.emit()"
              ></app-fields-edit-item>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <a
      href="javascript:void(0)"
      class="button button_icon button_orange button_extra-small"
      [class.button_disabled]="popoverOpened"
      (click)="setPopoverOpened(true)"
      style="margin: 0;"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      #button
    >
      <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
    </a>
  </div>

  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      <app-editable-content
        *ngIf="configurable.name"
        [value]="control.controls.name.value"
        [emptyValue]="'Empty name'"
        [pen]="hovered$ | async"
        [penTitle]="'Rename parameter'"
        (valueChange)="control.controls.name.patchValue($event)"
        (mousedown)="$event.stopPropagation()"
      >
      </app-editable-content>

      <ng-container *ngIf="!configurable.name">
        {{ control.controls.verboseName.value || control.controls.name.value }}
      </ng-container>
    </div>
  </div>

  <div class="sidebar-list-item__right">
    <a
      *ngIf="configurable.required"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-check_4"
      [class.sidebar-list-item__action_active]="control.controls.required.value"
      [appTip]="control.controls.required.value ? 'Make field Optional' : 'Make field Required'"
      (click)="control.controls.required.patchValue(!control.controls.required.value)"
    >
    </a>

    <a
      *ngIf="configurable.add"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-bin"
      (click)="deleteRequested.emit()"
    >
    </a>
  </div>
</div>

<div
  *ngIf="valueEditable"
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="true"
  [class.sidebar-collapse_block]="true"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="false"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
>
  <div class="sidebar-collapse__header" [class.sidebar-collapse__header_error]="false" (click)="toggleOpened()">
    <div class="sidebar-collapse__header-left">
      <div *ngIf="configurable.sortable" class="sidebar-collapse__header-drag"></div>

      <div *ngIf="configurable.params" class="sidebar-list-item__left">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay']"
          [cdkConnectedOverlayPositions]="[
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - 8 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 + 8 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 8 + 8, offsetY: 0 }
          ]"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="popoverOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          (backdropClick)="setPopoverOpened(false)"
          (detach)="setPopoverOpened(false)"
        >
          <div (cdkObserveContent)="onPopoverContentChanged()">
            <div class="popover2">
              <div class="popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse">
                <div class="popover2__viewport popover2__viewport_scrollable">
                  <app-fields-edit-item
                    [control]="control"
                    [configurable]="{}"
                    (saveRequested)="setPopoverOpened(false)"
                    (deleteRequested)="deleteRequested.emit()"
                  ></app-fields-edit-item>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <a
          href="javascript:void(0)"
          class="button button_icon button_orange button_extra-small"
          [class.button_disabled]="popoverOpened"
          (click)="setPopoverOpened(true)"
          style="margin: 0;"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          #button
        >
          <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
        </a>
      </div>

      <ng-container *ngIf="!configurable.params && configurable.field">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
          [cdkConnectedOverlayPositions]="fieldTypeDropdownPositions"
          [cdkConnectedOverlayOrigin]="dropdownTrigger"
          [cdkConnectedOverlayOpen]="fieldTypeDropdownOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          (backdropClick)="setFieldTypeDropdownOpened(false)"
          (detach)="setFieldTypeDropdownOpened(false)"
          #field_type_overlay
        >
          <app-field-type-dropdown
            class="overlay-content overlay-content_animated"
            [currentValue]="control.controls.field.value"
            (select)="setFieldTypeDropdownOpened(false); control.controls.field.patchValue($event)"
          ></app-field-type-dropdown>
        </ng-template>

        <a
          href="javascript:void(0)"
          class="button button_icon button_orange button_extra-small"
          cdkOverlayOrigin
          #dropdownTrigger="cdkOverlayOrigin"
          (click)="$event.stopPropagation(); setFieldTypeDropdownOpened(true)"
          style="margin: 0 0 0 8px;"
        >
          <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
        </a>
      </ng-container>

      <span
        *ngIf="!configurable.params && !configurable.field"
        class="sidebar-collapse__header-icon"
        [ngClass]="'icon-' + (fieldIcon$ | async)"
        style="margin: 0 0 0 8px;"
      ></span>
    </div>
    <div class="sidebar-collapse__header-main">
      <div
        *ngIf="configurable.name && opened"
        class="sidebar-collapse__header-title sidebar-collapse__header-title_editable"
      >
        <input
          *ngIf="rename"
          class="editable-content-input"
          [class.editable-content-input_inline]="false"
          [formControl]="renameControl"
          [appAutofocus]="true"
          (keyup)="onKeyUp($event)"
          (keypress)="onKeyPress($event)"
          (blur)="setRename(false)"
          (click)="$event.stopPropagation()"
        />

        <span
          *ngIf="!rename"
          class="editable-content-label"
          [class.editable-content-label_inline]="true"
          [class.editable-content-label_with-button]="true"
          (click)="$event.stopPropagation(); setRename(true)"
        >
          {{ control.controls.verboseName.value || control.controls.name.value }}

          <span
            *ngIf="hovered$ | async"
            class="editable-content-button"
            [appTip]="'Rename parameter'"
            [appTipOptions]="{ side: 'top' }"
          >
            <span class="icon icon-pen"></span>
          </span>
        </span>
      </div>

      <div *ngIf="!configurable.name || !opened" class="sidebar-collapse__header-title">
        {{ control.controls.verboseName.value || control.controls.name.value }}
      </div>

      <div
        *ngIf="(value | appIsSet) && !opened"
        class="sidebar-collapse__header-description"
        [class.sidebar-collapse__header-description_wrap]="true"
      >
        {{ value }}
      </div>
    </div>
    <div class="sidebar-collapse__header-right">
      <a
        *ngIf="configurable.required"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-check_4"
        [class.sidebar-list-item__action_active]="control.controls.required.value"
        [appTip]="control.controls.required.value ? 'Make Optional' : 'Make Required'"
        [appTipOptions]="{ side: 'top' }"
        (click)="$event.stopPropagation(); control.controls.required.patchValue(!control.controls.required.value)"
      >
      </a>

      <a
        *ngIf="configurable.add"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); deleteRequested.emit()"
      >
      </a>
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content" [class.sidebar-collapse__content_padding]="true">
    <app-auto-field
      *ngIf="(fieldType$ | async) == fieldTypes.Boolean"
      [form]="valueControlForm"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          field: fieldTypes.Select,
          placeholder: placeholder,
          params: {
            valueEquals: booleanValueEquals,
            options: [
              { value: false, name: 'False' },
              { value: true, name: 'True' }
            ],
            classes: ['select_fill']
          },
          reset_enabled: true
        })
      "
      [autofocus]="autofocus"
    ></app-auto-field>

    <app-auto-field
      *ngIf="(fieldType$ | async) != fieldTypes.Boolean"
      [form]="valueControlForm"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          placeholder: placeholder,
          field: fieldType$ | async,
          params: valueFieldParams,
          reset_enabled: true
        })
      "
      [autofocus]="autofocus"
    >
    </app-auto-field>
  </div>
</div>

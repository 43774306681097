import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { SSOSettings, SSOType } from '../../data/sso-settings';

@Injectable({
  providedIn: 'root'
})
export class SSOSettingsService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName, params = {}): Observable<SSOSettings[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'sso_settings/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new SSOSettings().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName, id: string): Observable<SSOSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `sso_settings/${id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new SSOSettings().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName, instance: SSOSettings, fields?: string[]): Observable<SSOSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'sso_settings/');
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        if (instance.type == SSOType.SAML2) {
          const formData = toPairs(data).reduce((acc, [key, value]) => {
            if (value === null) {
              value = '';
            } else if (isPlainObject(value)) {
              value = JSON.stringify(value);
            }

            acc.append(key, value);

            return acc;
          }, new FormData());

          return this.http.post(url, formData, { headers: headers });
        } else {
          return this.http.post(url, data, { headers: headers });
        }
      }),
      map(result => new SSOSettings().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName, instance: SSOSettings, fields?: string[]): Observable<SSOSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `sso_settings/${instance.uid}/`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        if (instance.type == SSOType.SAML2) {
          const formData = toPairs(data).reduce((acc, [key, value]) => {
            if (value === null) {
              value = '';
            } else if (isPlainObject(value)) {
              value = JSON.stringify(value);
            }

            acc.append(key, value);

            return acc;
          }, new FormData());

          return this.http.patch(url, formData, { headers: headers });
        } else {
          return this.http.patch(url, data, { headers: headers });
        }
      }),
      map(result => new SSOSettings().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName, instance: SSOSettings): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `sso_settings/${instance.uid}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

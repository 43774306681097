var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var SignatureFieldComponent = /** @class */ (function (_super) {
    __extends(SignatureFieldComponent, _super);
    function SignatureFieldComponent(domSanitizer) {
        var _this = _super.call(this) || this;
        _this.domSanitizer = domSanitizer;
        return _this;
    }
    SignatureFieldComponent.prototype.ngOnInit = function () { };
    SignatureFieldComponent.prototype.ngOnChanges = function (changes) { };
    Object.defineProperty(SignatureFieldComponent.prototype, "currentValueBackgroundImage", {
        get: function () {
            if (!this.currentValue) {
                return;
            }
            return this.domSanitizer.bypassSecurityTrustStyle("url('" + this.currentValue + "')");
        },
        enumerable: true,
        configurable: true
    });
    return SignatureFieldComponent;
}(FieldComponent));
export { SignatureFieldComponent };
registerFieldComponent(FieldType.Signature, SignatureFieldComponent);

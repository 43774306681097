var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, delayWhen, map, share } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectSettingsName, ProjectSettingsService } from '@modules/projects';
import { isSet } from '@shared';
var DriftIntegrationEditPopupForm = /** @class */ (function (_super) {
    __extends(DriftIntegrationEditPopupForm, _super);
    function DriftIntegrationEditPopupForm(currentProjectStore, currentEnvironmentStore, projectSettingsService, projectSettingsStore, formUtils) {
        var _this = _super.call(this, {
            id: new FormControl('')
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectSettingsService = projectSettingsService;
        _this.projectSettingsStore = projectSettingsStore;
        _this.formUtils = formUtils;
        return _this;
    }
    DriftIntegrationEditPopupForm.prototype.ngOnDestroy = function () { };
    DriftIntegrationEditPopupForm.prototype.init = function (value) {
        if (value === void 0) { value = {}; }
        this.value = value;
        this.controls.id.patchValue(value ? value.id : undefined);
        this.markAsPristine();
    };
    DriftIntegrationEditPopupForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var submitSettings;
        var instance = new AllProjectSettings();
        if (isSet(this.controls.id.value)) {
            instance.drift = { id: this.controls.id.value };
            submitSettings = instance.serialize([ProjectSettingsName.Drift])[0];
        }
        else {
            instance.drift = this.value;
            submitSettings = instance.serialize([ProjectSettingsName.Drift])[0];
            submitSettings.deleted = true;
        }
        return this.projectSettingsService
            .createBulk(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, [
            submitSettings
        ])
            .pipe(delayWhen(function () { return _this.projectSettingsStore.getFirst(true); }), map(function (result) { return new AllProjectSettings().deserialize(result); }), map(function (result) { return result.drift; }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }), share());
    };
    return DriftIntegrationEditPopupForm;
}(FormGroup));
export { DriftIntegrationEditPopupForm };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { ViewSettingsStore } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, EnvironmentService, ProjectPropertyStore } from '@modules/projects';
import { controlValue } from '@shared';
var ProjectEnvironmentMergePopupForm = /** @class */ (function (_super) {
    __extends(ProjectEnvironmentMergePopupForm, _super);
    function ProjectEnvironmentMergePopupForm(environmentService, formUtils, currentProjectStore, viewSettingsStore, customViewsStore, modelDescriptionStore, actionStore, menuSettingsStore, taskQueueStore, projectPropertyStore, projectSettingsStore, currentEnvironmentStore) {
        var _this = _super.call(this, {
            from_environment: new FormControl(undefined, [Validators.required]),
            to_environment: new FormControl(undefined, [Validators.required])
        }) || this;
        _this.environmentService = environmentService;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.viewSettingsStore = viewSettingsStore;
        _this.customViewsStore = customViewsStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionStore = actionStore;
        _this.menuSettingsStore = menuSettingsStore;
        _this.taskQueueStore = taskQueueStore;
        _this.projectPropertyStore = projectPropertyStore;
        _this.projectSettingsStore = projectSettingsStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.environmentOptions = [];
        return _this;
    }
    ProjectEnvironmentMergePopupForm.prototype.init = function (options) {
        if (options === void 0) { options = {}; }
        this.environmentOptions = this.currentProjectStore.instance.environments.map(function (item) {
            return {
                value: item,
                name: item.name
            };
        });
        if (options.environmentFrom) {
            var value = this.environmentOptions.find(function (item) { return item.value && item.value.uniqueName == options.environmentFrom.uniqueName; });
            this.controls.from_environment.patchValue(value ? value.value : undefined);
        }
        if (options.environmentTo) {
            var value = this.environmentOptions.find(function (item) { return item.value && item.value.uniqueName == options.environmentTo.uniqueName; });
            this.controls.to_environment.patchValue(value ? value.value : undefined);
        }
    };
    ProjectEnvironmentMergePopupForm.prototype.environmentOptionsExcept$ = function (control) {
        var _this = this;
        return controlValue(control).pipe(map(function (environment) {
            return _this.environmentOptions.filter(function (item) { return !environment || item.value !== environment; });
        }));
    };
    ProjectEnvironmentMergePopupForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var fromEnvironment = this.controls.from_environment.value;
        var toEnvironment = this.controls.to_environment.value;
        return this.environmentService
            .merge(this.currentProjectStore.instance.uniqueName, fromEnvironment, toEnvironment)
            .pipe(delayWhen(function () {
            if (_this.currentEnvironmentStore.instance.uniqueName != toEnvironment.uniqueName) {
                return of(undefined);
            }
            return combineLatest(_this.currentProjectStore.getFirst(true), _this.viewSettingsStore.getFirst(true), _this.customViewsStore.getFirst(true), _this.modelDescriptionStore.getFirst(true), _this.actionStore.getFirst(true), _this.menuSettingsStore.getFirst(true), _this.taskQueueStore.getFirst(true), _this.projectPropertyStore.getFirst(true), _this.projectSettingsStore.getFirst(true));
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectEnvironmentMergePopupForm;
}(FormGroup));
export { ProjectEnvironmentMergePopupForm };

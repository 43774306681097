import pickBy from 'lodash/pickBy';
import { ProjectGroup, ProjectUser } from './project-user';
var Environment = /** @class */ (function () {
    function Environment() {
        this.params = {};
    }
    Environment.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.uniqueName = data['unique_name'];
        this.color = data['color'];
        this.dateAdd = data['date_add'];
        if (data['user']) {
            this.user = new ProjectUser().deserialize(data['user']);
        }
        if (data['group']) {
            this.group = new ProjectGroup().deserialize(data['group']);
        }
        if (data['params']) {
            this.params = data['params'];
            this.version = this.params['version'];
        }
        return this;
    };
    Environment.prototype.serialize = function (fields) {
        this.params = {};
        if (this.version) {
            this.params['version'] = this.version;
        }
        var data = {
            unique_name: this.uniqueName,
            name: this.name,
            color: this.color,
            params: this.params
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Environment.prototype, "link", {
        get: function () {
            return ['project', 'environments', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    return Environment;
}());
export { Environment };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ApiParameterComponent } from './components/api-parameter/api-parameter.component';
import { HeaderParametersComponent } from './components/header-parameters/header-parameters.component';
import { ModelApiPopupComponent } from './components/model-api-popup/model-api-popup.component';
import { ModelApiAggregateQueryComponent } from './components/model-api-queries/model-api-aggregate-query/model-api-aggregate-query.component';
import { ModelApiBaseQueryComponent } from './components/model-api-queries/model-api-base-query/model-api-base-query.component';
import { ModelApiCreateQueryComponent } from './components/model-api-queries/model-api-create-query/model-api-create-query.component';
import { ModelApiDeleteQueryComponent } from './components/model-api-queries/model-api-delete-query/model-api-delete-query.component';
import { ModelApiGetDetailQueryComponent } from './components/model-api-queries/model-api-get-detail-query/model-api-get-detail-query.component';
import { ModelApiGetQueryComponent } from './components/model-api-queries/model-api-get-query/model-api-get-query.component';
import { ModelApiGroupQueryComponent } from './components/model-api-queries/model-api-group-query/model-api-group-query.component';
import { ModelApiReloadQueryComponent } from './components/model-api-queries/model-api-reload-query/model-api-reload-query.component';
import { ModelApiUpdateQueryComponent } from './components/model-api-queries/model-api-update-query/model-api-update-query.component';
import { ModelApiComponent } from './components/model-api/model-api.component';

@NgModule({
  imports: [CommonModule, SharedModule, UiModule, RoutingModule],
  declarations: [
    ApiParameterComponent,
    ModelApiComponent,
    ModelApiBaseQueryComponent,
    ModelApiGetQueryComponent,
    ModelApiCreateQueryComponent,
    ModelApiUpdateQueryComponent,
    ModelApiDeleteQueryComponent,
    ModelApiGroupQueryComponent,
    ModelApiAggregateQueryComponent,
    ModelApiGetDetailQueryComponent,
    ModelApiReloadQueryComponent,
    HeaderParametersComponent,
    ModelApiPopupComponent
  ],
  exports: [ApiParameterComponent, ModelApiComponent],
  entryComponents: [ModelApiPopupComponent]
})
export class ApiDocsModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ImageEditorComponent } from '@common/image-editor';
import { PopupService } from '@common/popups';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';

import { ImagePreviewComponent } from '../../previews/image-preview/image-preview.component';
import { BaseUploadComponent } from '../base-upload.component';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent extends BaseUploadComponent {
  @ViewChild(ImagePreviewComponent) imagePreviewComponent: ImagePreviewComponent;

  constructor(
    private popupService: PopupService,
    private injector: Injector,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    storageService: StorageService,
    cd: ChangeDetectorRef
  ) {
    super(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd);
  }

  onFileSelected(file: File) {
    const path = this.getUploadPath(file);

    if (!this.editor || file.type == 'image/svg+xml') {
      this.uploadFile(file, path);
      return;
    }

    const popup = this.popupService.showComponent<ImageEditorComponent>({
      component: ImageEditorComponent,
      injector: this.injector,
      inputs: {
        file: file,
        cropFormat: this.cropFormat,
        cropFormatCustom: this.cropFormatCustom
      }
    });

    popup.instance.changed.pipe(untilDestroyed(this)).subscribe(result => {
      this.uploadFile(result, path);
    });
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { CustomSelectItem, CustomSelectItemButton } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectProperty, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ascComparator, controlValue, isSet } from '@shared';

import { ProjectPropertyEditController } from '../../../../services/project-property-edit-controller/project-property-edit.controller';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-set-property',
  templateUrl: './customize-bar-action-edit-type-set-property.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeSetPropertyComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  propertyOptions: CustomSelectItem<string>[] = [];
  currentProperty: ProjectProperty;

  constructor(
    private projectPropertyStore: ProjectPropertyStore,
    private projectPropertyEditController: ProjectPropertyEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectPropertyStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        const sections = [
          {
            label: 'Globals',
            properties: properties.filter(item => item.type == ProjectPropertyType.Global),
            buttonName: 'add_global_property',
            buttonLabel: 'Add Global variable'
          }
        ];

        if (this.context && this.context.viewSettings && this.context.viewSettings.uid) {
          sections.splice(0, 0, {
            label: 'Current page',
            properties: properties.filter(item => {
              return (
                item.type == ProjectPropertyType.Page &&
                isSet(item.pageUid) &&
                this.context.viewSettings &&
                this.context.viewSettings.uid == item.pageUid
              );
            }),
            buttonName: 'add_page_property',
            buttonLabel: 'Add Page variable'
          });
        }

        this.propertyOptions = sections.reduce((acc, item) => {
          acc.push(
            ...item.properties
              .sort((lhs, rhs) => ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()))
              .map(property => {
                return {
                  option: {
                    value: property.uid,
                    name: property.name,
                    icon: property.fieldDescription.icon
                  },
                  subtitle: item.label
                };
              })
          );
          acc.push({
            button: {
              name: item.buttonName,
              label: item.buttonLabel,
              icon: 'plus'
            },
            orange: true
          });

          return acc;
        }, []);
        this.cd.markForCheck();
      });

    controlValue(this.form.controls.set_property_action_property)
      .pipe(
        switchMap(uid => this.projectPropertyStore.getDetail(uid)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.currentProperty = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onButtonItemClick(button: CustomSelectItemButton) {
    let type: ProjectPropertyType;
    let pageUid: string;

    if (button.name == 'add_global_property') {
      type = ProjectPropertyType.Global;
    } else if (button.name == 'add_page_property') {
      type = ProjectPropertyType.Page;
      pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
    }

    if (type) {
      this.projectPropertyEditController
        .create({
          type: type,
          defaultName: 'variable',
          defaultValueEnabled: true,
          pageUid: pageUid,
          context: this.context,
          analyticsSource: this.analyticsSource
        })
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.form.controls.set_property_action_property.patchValue(result.property.uid);
        });
    }
  }
}

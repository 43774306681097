<div
  class="compact-list-item compact-list-item_interactive"
  [class.compact-list-item_active]="customizing$ | async"
  [class.compact-list-item_yellow]="layer.visibleInput?.isSet()"
  [class.compact-list-item_disabled]="!layer.visible"
  [class.compact-list-item_hidden]="transferring"
  (dblclick)="startEditing()"
  (click)="onClick($event)"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="compact-list-item__left">
    <div *ngFor="let item of indentRange" class="compact-list-item__indent"></div>

    <div *ngIf="!containerLayer" class="compact-list-item__indent"></div>
    <div
      *ngIf="containerLayer"
      class="compact-list-item__arrow"
      [class.icon-arrow_forward_2]="!layersOpened"
      [class.icon-arrow_down_2]="layersOpened"
      (click)="toggleOpened(); $event.stopPropagation()"
      (dblclick)="$event.stopPropagation()"
    ></div>

    <div
      class="compact-list-item__icon"
      [class.compact-list-item__icon_orange]="flexLayout && layer.absoluteLayout"
      [ngClass]="'icon-' + layer.icon"
    ></div>
  </div>

  <div class="compact-list-item__main">
    <div *ngIf="!nameEditing" class="compact-list-item__title">
      {{ layer.name }}
    </div>

    <input
      *ngIf="nameEditing"
      class="compact-list-item__input"
      [(ngModel)]="nameValue"
      (blur)="finishEditing()"
      (keyup)="onKeyUp($event)"
      #input
    />
  </div>

  <div *ngIf="!nameEditing" class="compact-list-item__right">
    <div
      class="compact-list-item__action compact-list-item__action_toggle icon-eye_disabled_2"
      [class.compact-list-item__action_active]="!layer.visible"
      [appTip]="layer.visible ? 'Hide layer' : 'Show layer'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation(); toggleVisible()"
      (dblclick)="$event.stopPropagation()"
    ></div>
  </div>
</div>

<app-view-editor-navigator-items
  *ngIf="containerLayer && layersOpened"
  [items]="containerLayer.layers"
  [flexLayout]="!!containerLayer?.flexLayout"
  [indent]="indent + 1"
  [search]="search"
></app-view-editor-navigator-items>

<app-page [paddingLeft]="true">
  <app-section [top]="true">
    <app-page-header>
      <app-page-title>Automations</app-page-title>
      <app-page-description>
        Automations allow you to automate repetitive tasks and user flows without leaving Jet.
      </app-page-description>
    </app-page-header>

    <app-section-tabs>
      <app-section-tab
        [label]="'All Automations'"
        [active]="false"
        [link]="currentProjectStore.instance.automationsLink"
      ></app-section-tab>

      <app-section-tab
        [label]="'Run history'"
        [active]="true"
        [link]="currentProjectStore.instance.automationRunsLink"
      ></app-section-tab>
    </app-section-tabs>
  </app-section>
</app-page>

<ng-template let-size="size" #table_rows_stub_template>
  <tr *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, size || 4)" class="card-table__row">
    <td class="card-table__column"></td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Automation</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Success</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Created</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner">
        <span [class.loading-animation]="true"><span class="stub-text">Finished</span></span>
      </div>
    </td>
    <td class="card-table__column">
      <div class="card-table__column-inner"></div>
    </td>
  </tr>
</ng-template>

<ng-template #table_stub_template>
  <table class="card-table">
    <thead>
      <tr class="card-table__head-row">
        <th class="card-table__head-column"></th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Automation</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Status</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text">Created</span></span>
        </th>
        <th class="card-table__head-column">
          <span [class.loading-animation]="true"><span class="stub-text"></span></span>
        </th>
        <th class="card-table__head-column"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngTemplateOutlet="table_rows_stub_template"></ng-container>
    </tbody>
  </table>
</ng-template>

<app-page [paddingHorizontal]="true">
  <app-page-block [paddingSize]="'none'">
    <ng-container *ngIf="(!(loading$ | async) || (items$ | async)?.length) && !error">
      <table class="card-table">
        <thead>
          <tr class="card-table__head-row">
            <th class="card-table__head-column"></th>
            <th class="card-table__head-column"></th>
            <th class="card-table__head-column">Status</th>
            <th class="card-table__head-column">Created</th>
            <th class="card-table__head-column"></th>
            <th class="card-table__head-column"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of items$ | async; trackBy: trackItemFn"
            class="card-table__row card-table__row_clickable"
            app-automation-runs-item
            [item]="item"
            (click)="openItem(item)"
          ></tr>

          <ng-container *ngIf="loading$ | async">
            <ng-container *ngTemplateOutlet="table_rows_stub_template; context: { size: 3 }"></ng-container>
          </ng-container>
        </tbody>
      </table>

      <div *ngIf="hasMore && !(loading$ | async)" style="margin-top: 20px; text-align: center;">
        <a href="javascript:void(0)" class="button button_background button_small" (click)="getNext()">
          <span class="button__label">Load more</span>
        </a>
      </div>
    </ng-container>

    <div *ngIf="!(loading$ | async) && !(items$ | async)?.length && !error" class="status-block">
      <div class="status-block__main">
        <div class="status-block__content">
          <h1 class="status-block__title">No runs yet</h1>
          <div class="status-block__description">
            Start building automations to automate repetitive tasks and user flows without leaving Jet.
          </div>
        </div>
      </div>
      <div class="status-block__right">
        <div class="status-block__image">
          <div class="status-block__image-inner status-block__image-inner_type_not-found"></div>
        </div>
      </div>
    </div>

    <div *ngIf="error" class="table__empty">
      <app-error [title]="'Loading failed' | localize">
        {{ error }}
      </app-error>
    </div>

    <ng-container *ngIf="(loading$ | async) && !(items$ | async)?.length">
      <ng-container *ngTemplateOutlet="table_stub_template"></ng-container>
    </ng-container>
  </app-page-block>
</app-page>

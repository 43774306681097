import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { ResizeType } from '@common/resizable';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';

import {
  createFormFieldFactory,
  FieldType,
  getJavaScriptSyntaxError,
  InputFilterField,
  InputFilterFieldLookup,
  InputValueType
} from '@modules/fields';
import {
  contextToFormulaValue,
  singleTokenFormulaToContextValue,
  transformFormulaElementAccessors
} from '@modules/parameters';
import { TokenPopupItem } from '@modules/tokens-components';
import { controlValue, isSet } from '@shared';

// Refactor imports
import { CodeFieldComponent } from '@modules/field-components/components/code-field/code-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { FormulaInsert, FormulaToken } from '../../data/formula-token';
import { InputOverlayDirective } from '../../directives/input-overlay/input-overlay.directive';
import { getFormulaStateAnalyticsParams } from '../../utils/analytics';
import { InputEditFormulaValueComponent } from '../input-edit-formula-value/input-edit-formula-value.component';
import { ViewContextTokenPopoverOverlayComponent } from '../view-context-token-popover-overlay/view-context-token-popover-overlay.component';
import { FieldFilterInputTokenData, InputTokenData, InputTokenType } from './input-token';

@Component({
  selector: 'app-input-edit',
  templateUrl: './input-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemForm: FormGroup;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueField: FieldType;
  @Input() staticValueParams: Object;
  @Input() staticValueDisabled = false;
  @Input() filterFields: InputFilterField[] = [];
  @Input() userInput = false;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() resetEnabled = true;
  @Input() displayValueTypesEnabled = true;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() overlay = false;
  @Input() builtInItemsEnabled = true;
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Input() fillVertical = false;
  @Input() small = false;
  @Input() dark = false;
  @Input() darker = false;
  @Input() analyticsSource: string;

  @ViewChild('js_field') jsField: AutoFieldComponent;
  @ViewChild(InputEditFormulaValueComponent) formulaValueComponent: InputEditFormulaValueComponent;
  @ViewChild(ViewContextTokenPopoverOverlayComponent)
  tokenPopoverOverlayComponent: ViewContextTokenPopoverOverlayComponent;
  @ViewChild(InputOverlayDirective) inputOverlayDirective: InputOverlayDirective;

  createField = createFormFieldFactory();
  control: FormControl;
  form: FormGroup;
  initialValue: Object;
  jsError: string;
  filterField: InputFilterField;
  filterFieldLookup: InputFilterFieldLookup;
  staticValueParamsDisplay: Object;
  staticValueDisabledClean = false;
  extraSections: FormulaSection[] = [];
  inputValueTypes = InputValueType;
  fieldTypes = FieldType;
  resizeTypes = ResizeType;
  popoverPositionsSubscription: Subscription;
  startedFormulaEditing = false;
  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(
    private focusMonitor: FocusMonitor,
    private analyticsService: UniversalAnalyticsService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control = new FormControl(undefined, { updateOn: this.itemForm ? this.itemForm.updateOn : undefined });
    // TODO: Temporary solution
    this.form = new FormGroup({ control: this.control });

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.itemForm.value['value_type'] == InputValueType.StaticValue && value === '') {
        if (isSet(this.itemForm.controls['value_type'].value)) {
          this.itemForm.controls['value_type'].patchValue('');
          this.itemForm.controls['static_value'].patchValue('');
        }
      } else if (
        (!this.itemForm.value['value_type'] || this.itemForm.value['value_type'] == InputValueType.StaticValue) &&
        !this.staticValueDisabledClean
      ) {
        if (this.itemForm.controls['value_type'].value != InputValueType.StaticValue) {
          this.itemForm.controls['value_type'].patchValue(InputValueType.StaticValue);
        }

        this.itemForm.controls['static_value'].patchValue(value);
      } else if (this.itemForm.value['value_type'] == InputValueType.Formula || this.staticValueDisabledClean) {
        if (this.itemForm.controls['value_type'].value != InputValueType.Formula) {
          this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
        }

        const toExternalValue = transformFormulaElementAccessors(value, this.context, false);
        this.itemForm.controls['formula_value'].patchValue(toExternalValue);
      }

      this.itemForm.markAsDirty();
    });

    controlValue<Object>(this.itemForm)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (value['value_type'] == InputValueType.Filter) {
          this.updateFilterField();
        }
      });

    this.itemForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(value => {
      this.updateInputErrors();
      this.onInteracting();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.Updated, {
        ValueType: this.itemForm.controls['value_type'].value,
        Source: this.analyticsSource
      });
    });

    this.setInitialValue();
    this.updateExtraSections();
  }

  ngOnDestroy(): void {
    if (this.startedFormulaEditing) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.FinishedToSetUp, {
        ...getFormulaStateAnalyticsParams(this.itemForm, this.context, this.initialValue),
        Source: this.analyticsSource
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['staticValueParams'] || changes['fill'] || changes['classes']) {
      this.staticValueParamsDisplay = this.getStaticValueParams();
    }

    if (changes['staticValueField'] || changes['staticValueDisabled']) {
      if ([FieldType.JSON, FieldType.Location].includes(this.staticValueField)) {
        this.staticValueDisabledClean = true;
      } else {
        this.staticValueDisabledClean = this.staticValueDisabled;
      }
    }

    if (
      [changes.builtInItemsEnabled, changes.userInput, changes.contextElement, changes.filterFields].some(
        item => item && !item.firstChange
      )
    ) {
      this.updateExtraSections();
    }
  }

  getStaticValueParams(): Object {
    return {
      ...this.staticValueParams,
      classes: [...(this.fill ? ['select_fill', 'input_fill'] : []), ...this.classes],
      output_format: undefined,
      background: true
    };
  }

  clearInputErrors() {
    this.jsError = undefined;
    this.cd.markForCheck();
  }

  updateInputErrors() {
    const value = this.itemForm.value;

    this.clearInputErrors();

    if (value['value_type'] == InputValueType.Js) {
      if (isSet(value['js_value'])) {
        this.jsError = getJavaScriptSyntaxError(value['js_value']);
        this.cd.markForCheck();
      }
    }
  }

  updateFilterField() {
    this.filterField = this.filterFields.find(item => item.name == this.itemForm.value['filter_field']);
    this.filterFieldLookup = this.filterField
      ? this.filterField.lookups.find(item => item.name === this.itemForm.value['filter_lookup'])
      : undefined;
    this.cd.markForCheck();
  }

  getFilterTokenItems(): TokenPopupItem<InputTokenData> {
    if (!this.contextElement || !this.contextElement.fieldFilters) {
      return;
    }

    return {
      name: 'Current Component Filters',
      data: {
        type: InputTokenType.Filter
      },
      items: this.filterFields.map(field => {
        return {
          name: field.label,
          items: field.lookups.map(lookup => {
            return {
              name: lookup.label,
              data: {
                field: field.name,
                lookup: lookup.name
              } as FieldFilterInputTokenData
            };
          })
        };
      })
    };
  }

  setInitialValue() {
    const formValue = this.itemForm.value;

    if (!formValue['value_type'] || formValue['value_type'] == InputValueType.StaticValue) {
      this.control.patchValue(formValue['static_value'], { emitEvent: false });
    } else if (formValue['value_type'] == InputValueType.Formula) {
      const fromExternalValue = transformFormulaElementAccessors(formValue['formula_value'], this.context, true);
      this.control.patchValue(fromExternalValue, { emitEvent: false });
    } else if (formValue['value_type'] == InputValueType.Filter) {
      this.updateFilterField();
    }

    this.initialValue = formValue;
    this.itemForm.markAsPristine();
    this.updateInputErrors();
  }

  updateExtraSections() {
    const extraSections: FormulaSection[] = [];

    if (this.builtInItemsEnabled) {
      const builtInItems: FormulaSectionItem[] = [
        {
          path: ['built_in', InputTokenType.TextInputs],
          item: {
            token: [InputTokenType.TextInputs],
            label: 'Text with Inputs',
            icon: 'variable',
            tip: 'Apply format and Insert inputs in Text, HTML or Markdown',
            iconOrange: true,
            orange: true
          }
        },
        {
          path: ['built_in', InputTokenType.Js],
          item: {
            token: [InputTokenType.Js],
            label: 'JavaScript',
            icon: 'console',
            tip: 'Use JavaScript',
            iconOrange: true,
            orange: true
          }
        },
        ...(this.userInput
          ? [
              {
                path: ['built_in', InputTokenType.Prompt],
                item: {
                  token: [InputTokenType.Prompt],
                  label: 'Ask user',
                  icon: 'edit',
                  tip: 'Ask user to enter value in popup window'
                }
              }
            ]
          : []),
        {
          path: ['built_in', InputTokenType.EmptyString],
          item: {
            token: [InputTokenType.EmptyString],
            label: 'Empty String',
            icon: 'delete',
            tip: 'Send empty string'
          }
        },
        {
          path: ['built_in', InputTokenType.Null],
          item: {
            token: [InputTokenType.Null],
            label: 'Null',
            icon: 'deselect',
            tip: 'Send null value'
          }
        }
      ];

      extraSections.push({
        name: 'built_in',
        pinned: true,
        horizontal: true,
        items: builtInItems
      });
    }

    const filterItems = this.getFilterTokenItems();

    if (filterItems) {
      extraSections.push({
        label: filterItems.name,
        icon: 'filter',
        items: filterItems.items.reduce<FormulaSectionItem[]>((acc, fieldItem) => {
          fieldItem.items.forEach(lookupItem => {
            acc.push({
              path: ['filter', fieldItem.name, lookupItem.name],
              item: {
                token: [InputTokenType.Filter],
                label: [fieldItem.name, lookupItem.name].join(' '),
                data: lookupItem.data
              }
            });
          }, []);
          return acc;
        }, [])
      });
    }

    this.extraSections = extraSections;
    this.cd.markForCheck();
  }

  onEditorOpened() {
    this.onInteracting();
  }

  onInteracting() {
    if (!this.startedFormulaEditing) {
      this.startedFormulaEditing = true;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Formula.StartedToSetUp, {
        ValueType: this.itemForm.controls['value_type'].value,
        Source: this.analyticsSource
      });
    }
  }

  switchToFormula(empty = false) {
    const currentValueType = this.itemForm.controls['value_type'].value;
    const contextValue = this.itemForm.controls['context_value'].value;
    const staticValue = this.itemForm.controls['static_value'].value;

    this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);

    if (empty) {
      this.control.patchValue('');
    } else if (currentValueType == InputValueType.Context && isSet(contextValue)) {
      let formulaValue = contextToFormulaValue(contextValue);
      formulaValue = transformFormulaElementAccessors(formulaValue, this.context, true);
      this.control.patchValue(formulaValue);
    } else if (currentValueType == InputValueType.StaticValue && isSet(staticValue)) {
      this.control.patchValue(JSON.stringify(staticValue));
    }

    this.focusValueOnStable();
  }

  focusValue() {
    if (this.formulaValueComponent) {
      this.formulaValueComponent.focus();
    }

    if (this.jsField) {
      this.jsField.focus();
    }
  }

  focusValueOnStable() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.focusValue());
  }

  switchToJs() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Js);
    this.itemForm.markAsDirty();
  }

  switchToTextInputs() {
    this.itemForm.controls.value_type.patchValue(InputValueType.TextInputs);
    this.itemForm.markAsDirty();
  }

  switchToEmptyString() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.EmptyString);
    this.itemForm.markAsDirty();
  }

  switchToNull() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Null);
    this.itemForm.markAsDirty();
  }

  switchToPrompt() {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Prompt);
    this.itemForm.markAsDirty();
  }

  switchToContext(token: string) {
    const contextValue = singleTokenFormulaToContextValue(token);
    this.itemForm.controls['value_type'].patchValue(InputValueType.Context);
    this.itemForm.controls['context_value'].patchValue(contextValue);
    this.itemForm.markAsDirty();
  }

  switchToFieldFilter(field: string, lookup: string) {
    this.itemForm.controls['value_type'].patchValue(InputValueType.Filter);
    this.itemForm.controls['filter_field'].patchValue(field);
    this.itemForm.controls['filter_lookup'].patchValue(lookup);
    this.itemForm.markAsDirty();
  }

  switchToDefault() {
    if (this.staticValueDisabledClean) {
      this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);
      this.itemForm.markAsDirty();

      this.control.patchValue('');

      this.focusValueOnStable();
    } else {
      this.itemForm.controls['value_type'].patchValue('');
      this.itemForm.markAsDirty();

      this.control.patchValue(this.itemForm.controls['static_value'].value);
    }
  }

  insertJsToken(item: FormulaInsert | FormulaToken) {
    if (!isSet(item.insert) && !isSet(item.formula)) {
      return;
    }

    if (
      this.jsField &&
      this.jsField.dynamicComponent.currentComponent &&
      this.jsField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent
    ) {
      const ace = this.jsField.dynamicComponent.currentComponent.instance.ace;
      const selectionRange = ace.editor.selection.getRange();
      let insert: string;

      if (isSet(item.formula)) {
        insert = item.formula;
      } else {
        const formulaValue = contextToFormulaValue(item.insert);
        insert = transformFormulaElementAccessors(formulaValue, this.context, false);
      }

      const insertRow = selectionRange.start.row;
      const insertLast = isSet(item['label']) ? (item as FormulaToken).label : item.insert[item.insert.length - 1];
      const insertRowValue = ace.editor.session.getLine(insertRow);
      const varName = typeof insertLast == 'number' ? `item${insertLast}` : camelCase(String(insertLast));
      const prefixMatch = insertRowValue.match(/^([\s\t]+)/);
      const prefix = prefixMatch ? prefixMatch[1] : '';

      ace.editor.session.insert({ row: insertRow, column: 0 }, `${prefix}let ${varName} = ${insert};\n`);
    }
  }

  onJsTokenSelected(item: FormulaInsert | FormulaToken) {
    if (isSet(item.insert) || isSet(item.formula)) {
      this.insertJsToken(item);
    }
  }

  openTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.open();
    }
  }

  closeTokenPopover() {
    if (this.tokenPopoverOverlayComponent) {
      this.tokenPopoverOverlayComponent.close();
    }
  }

  isInputOverlayOpened(): boolean {
    if (this.overlay || !this.inputOverlayDirective) {
      return false;
    }

    return this.inputOverlayDirective.isOpened();
  }

  onInputOverlayFinished() {
    this.setInitialValue();
  }
}

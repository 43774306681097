<mat-menu [overlapTrigger]="false" #menu>
  <ng-template matMenuContent>
    <ng-container *ngFor="let path of paths">
      <app-query-builder-response-keys
        [items]="pathItems"
        (selected)="selectedPath.emit({ pathName: path.name, value: $event })"
        #children
      >
      </app-query-builder-response-keys>

      <button
        mat-menu-item
        [disableRipple]="true"
        [matMenuTriggerFor]="children.menu"
        (click)="selectedPath.emit({ pathName: path.name, value: [] })"
      >
        {{ path.chooseLabel }}
      </button>
    </ng-container>

    <button mat-menu-item [disableRipple]="true" (click)="selectedJS.emit()">
      JavaScript transform
    </button>
  </ng-template>
</mat-menu>

<a
  href="javascript:void(0)"
  class="input input_fill"
  style="margin-left: 10px;"
  [matMenuTriggerFor]="menu"
  #response_trigger="matMenuTrigger"
>
  <ng-container *ngFor="let item of currentValueDisplay; let last = last">
    {{ item }}
    <span *ngIf="!last" class="icon-arrow_forward_2"></span>
  </ng-container>

  <span *ngIf="currentValueIcon" [ngClass]="['icon-' + currentValueIcon]"></span>
</a>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApiModule } from '@modules/api';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import './data/widgets/items/chart';
import './data/widgets/items/value';

@NgModule({
  imports: [CommonModule, RoutingModule, ApiModule, SharedModule]
})
export class DashboardModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { TableSettings } from '@modules/customize';
import { Option } from '@modules/field-components';
import { CHECKED_ITEMS_OUTPUT, ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isSet } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarTableSettingsDataSourceComponent } from './customize-bar-table-settings-data-source/customize-bar-table-settings-data-source.component';
import { CustomizeBarTableSettingsForm } from './customize-bar-table-settings.form';

@Component({
  selector: 'app-customize-bar-table-settings',
  templateUrl: './customize-bar-table-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListModelDescriptionDataSourceControl, CustomizeBarTableSettingsForm]
})
export class CustomizeBarTableSettingsComponent extends CustomizeBarListLayoutSettingsComponent<TableSettings>
  implements OnInit, OnDestroy {
  itemContextElementPath = [ITEM_OUTPUT];
  selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
  checkedItemsContextElementPath = [CHECKED_ITEMS_OUTPUT];
  dataSourceEditComponent = CustomizeBarTableSettingsDataSourceComponent;
  columnOptions$: Observable<Option<string>[]>;
  dataConfigured$: Observable<boolean>;
  collapseContext = new SidebarCollapseContext();
  actionsRequiredFields = ['row_click_action', 'actions', 'row_actions', 'model_actions'];
  groupsEditOverlayOpenedInitial = false;
  groupsAddMissingLoading = false;

  constructor(
    public form: CustomizeBarTableSettingsForm,
    customizeBarContext: CustomizeBarContext,
    dialogService: DialogService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, customizeBarContext, dialogService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.context = this.context;
    this.form.contextElement = this.contextElement;

    this.columnOptions$ = this.form.controls.data_source.getColumnOptions$();
    this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();

    combineLatest(this.form.controls.data_source.getModelDescription$(), this.form.controls.group_field.valueChanges)
      .pipe(
        filter(([modelDescription, stage]) => isSet(modelDescription) && isSet(stage)),
        debounceTime(10),
        switchMap(() => {
          this.groupsAddMissingLoading = true;
          this.cd.markForCheck();

          return this.form.getMissing();
        })
      )
      .subscribe(
        result => {
          if (result && result.length) {
            this.form.controls.groups.addMissingByValues(result);
          }

          this.groupsAddMissingLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.groupsAddMissingLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  isDataEditable(): boolean {
    if (!this.modelDescription) {
      return false;
    }

    const updateParameters = this.modelDescription.updateParametersOrDefaults;

    return (
      this.modelDescription.updateQuery &&
      this.modelDescription.updateQuery.isConfigured() &&
      updateParameters.length > 0
    );
  }

  onGroupFieldChange(value: string) {
    this.groupsEditOverlayOpenedInitial = !isSet(this.result.settings.groupField) && isSet(value);
    this.cd.markForCheck();
  }
}

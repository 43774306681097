<mat-menu #dropdown="matMenu" [class]="panelClassesStr">
  <ng-template matMenuContent>
    <app-custom-select-dropdown
      [items]="items || []"
      [currentOption]="currentOptionItem?.option"
      [searchEnabled]="allowSearch"
      [emptyLabel]="emptyLabel"
      [panelClasses]="panelClasses"
      [capitalize]="capitalize"
      (optionClick)="closeDropdown(); onOptionClick($event)"
      (buttonClick)="closeDropdown(); onButtonClick($event)"
    ></app-custom-select-dropdown>
  </ng-template>
</mat-menu>

<div class="select select_theme_jet" [ngClass]="classes" [matMenuTriggerFor]="dropdown" #trigger="matMenuTrigger">
  <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
    <span
      *ngIf="
        currentOptionItem?.valueIcon !== undefined
          ? currentOptionItem?.valueIcon
          : currentOptionItem?.option?.icon as icon
      "
      class="select__input-icon"
      [ngClass]="['icon-' + icon]"
    ></span>
    <span
      *ngIf="
        currentOptionItem?.valueImage !== undefined
          ? currentOptionItem?.valueImage
          : currentOptionItem?.option?.image as image
      "
      class="select__input-icon select__input-icon_image"
      [style.background-image]="'url(' + image + ')'"
    ></span>
    <span *ngIf="currentOptionItem?.valueTag | appIsSet" class="select__input-tag">
      {{ currentOptionItem.valueTag }}
    </span>

    <span class="select__input-label">
      <ng-container *ngIf="currentOptionItem">
        {{ currentOptionItem.valueLabel || currentOptionItem.option.name | appCapitalize: capitalize }}
      </ng-container>
      <ng-container *ngIf="!currentOptionItem">
        {{ items?.length ? chooseLabel : emptyLabel }}
      </ng-container>
    </span>

    <span
      *ngIf="resetEnabled && currentOptionItem"
      class="select__input-reset icon-close"
      (click)="$event.stopPropagation(); resetOption()"
    ></span>
    <span *ngIf="!resetEnabled || !currentOptionItem" class="select__input-arrow"></span>
  </a>
</div>

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isSet } from '../utils/common/common';
import { trimAll } from '../utils/text/text';

export function NonEmptyValidator(message: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (isSet(control.value) && !isSet(trimAll(control.value))) {
      return { local: [message] };
    } else {
      return null;
    }
  };
}

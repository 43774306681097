import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsLastMonthFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_last_month';
  public param = 'isLastMonth';
  public verboseName = localize('is Last Month');
  public icon = 'calendar';

  from(value: string): string {
    return moment().subtract(1, 'months').toISOString();
  }

  to(value: string): string {
    return moment().toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not last month', [field]);
      } else {
        return localize('is not last month');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is last month', [field]);
      } else {
        return localize('last month');
      }
    }
  }
}

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import toPairs from 'lodash/toPairs';

import { TypedChanges } from '../../utils/angular/angular';

@Directive({
  selector: '[appAttr]'
})
export class AttrDirective implements OnChanges {
  @Input('appAttr') attrs: Record<string, string>;

  constructor(private el: ElementRef<Element>) {}

  ngOnChanges(changes: TypedChanges<AttrDirective>): void {
    if (changes.attrs) {
      if (changes.attrs.previousValue) {
        toPairs(changes.attrs.previousValue)
          .filter(([k]) => !this.attrs || !this.attrs.hasOwnProperty(k))
          .forEach(([k]) => this.el.nativeElement.removeAttribute(k));
      }

      if (this.attrs) {
        toPairs(this.attrs).forEach(([k, v]) => this.el.nativeElement.setAttribute(k, v));
      }
    }
  }
}

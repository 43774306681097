var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { sendGridResourceParamsActionDescriptions } from '../../data/send-grid/send-grid-resource-params-action-descriptions.stub';
import { sendGridResourceParamsParams } from '../../data/send-grid/send-grid-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var SendGridGeneratorService = /** @class */ (function (_super) {
    __extends(SendGridGeneratorService, _super);
    function SendGridGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        return _this;
    }
    SendGridGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = 'https://api.sendgrid.com/v3/user/profile';
        query.headers = [{ name: 'Authorization', value: "Bearer " + options.key }];
        return this.httpQueryService.request(query).pipe(map(function (response) {
            var company = response.body['company'];
            var firstName = response.body['first_name'];
            var lastName = response.body['last_name'];
            if (company) {
                return { accountName: company };
            }
            else if (firstName || lastName) {
                return { accountName: [firstName, lastName].filter(function (item) { return item; }).join(' ') };
            }
            else {
                return {};
            }
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 403) {
                error = new ServerRequestError('API key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    SendGridGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
            .pipe(map(function (result) {
            return {
                key: result.value
            };
        }));
    };
    SendGridGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = sendGridResourceParamsParams;
        var actionDescriptions = sendGridResourceParamsActionDescriptions;
        var token = new SecretToken();
        token.name = HTTP_QUERY_KEY_AUTH_NAME;
        token.type = SecretTokenType.Static;
        token.value = options.key;
        return of({
            resourceParams: resourceParams,
            actionDescriptions: actionDescriptions,
            secretTokens: [token.serialize()]
        });
    };
    return SendGridGeneratorService;
}(ResourceGeneratorService));
export { SendGridGeneratorService };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { isSet } from '@shared';
import { Automation } from '../../data/automation';
import { AutomationTriggerWebhook } from '../../data/automation-trigger-hook';
import { WorkflowBackendRunWithRelations } from '../../data/workflow-backend-run-with-relations';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../centrifugo/services/centrifugo/centrifugo.service";
var AutomationService = /** @class */ (function () {
    function AutomationService(apiService, http, centrifugoService) {
        this.apiService = apiService;
        this.http = http;
        this.centrifugoService = centrifugoService;
    }
    AutomationService.prototype.get = function (project, environment, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'automations/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new Automation().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.getDetail = function (project, environment, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, "automations/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new Automation().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.create = function (project, environment, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, 'automations/');
            var headers = new HttpHeaders();
            var data = instance.serialize();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Automation().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.update = function (project, environment, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, "automations/" + instance.uid + "/");
            var headers = new HttpHeaders();
            var data = instance.serialize(fields);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new Automation().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.delete = function (project, environment, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(project.uniqueName, environment.uniqueName, "automations/" + instance.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.run = function (project, environment, id, draft) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "automations/" + id + "/run");
            var headers = new HttpHeaders();
            var data = {
                draft: draft
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.getAutomationTriggerWebhook = function (project, environment, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'hooks');
            var headers = new HttpHeaders();
            var data = {
                automationUid: instance.uid
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new AutomationTriggerWebhook().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.automationTriggerWebhookReinitializeStructure = function (project, environment, webhook) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "hooks/" + webhook.token + "/reinitialize_structure");
            var headers = new HttpHeaders();
            var data = {};
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new AutomationTriggerWebhook().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.subscribeAutomationTriggerWebhook = function (project, environment, webhook) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = [
                '$automation_webhook:automation_webhook',
                project.uniqueName,
                environment.uniqueName,
                webhook.token
            ].join('/');
            return _this.centrifugoService
                .subscribeState(channel)
                .pipe(map(function (msg) { return new AutomationTriggerWebhook().deserialize(msg.data); }));
        }));
    };
    AutomationService.prototype.trackModelChange = function (project, environment, resource, model, action, uid, modelData) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsMethodURL('model_change');
            var headers = new HttpHeaders();
            var data = {
                project: project.uniqueName,
                environment: environment.uniqueName,
                resource_unique_name: resource.uniqueName,
                model: model,
                action: action,
                data: modelData
            };
            if (isSet(uid)) {
                data['id'] = uid;
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AutomationService.prototype.subscribeAutomation = function (project, environment, automation) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$automation:automation', project.uniqueName, environment.uniqueName, automation.uid].join('/');
            return _this.centrifugoService.subscribeState(channel).pipe(map(function (msg) { return new Automation().deserialize(msg.data); }));
        }));
    };
    AutomationService.prototype.subscribeWorkflowRun = function (project, environment, run) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$workflow_run:workflow_run', run.id].join('/');
            return _this.centrifugoService
                .subscribeState(channel)
                .pipe(map(function (msg) { return new WorkflowBackendRunWithRelations().deserialize(msg.data); }));
        }));
    };
    AutomationService.ngInjectableDef = i0.defineInjectable({ factory: function AutomationService_Factory() { return new AutomationService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient), i0.inject(i3.CentrifugoService)); }, token: AutomationService, providedIn: "root" });
    return AutomationService;
}());
export { AutomationService };

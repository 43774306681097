/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./toggle-content.component";
var styles_ToggleContentComponent = [];
var RenderType_ToggleContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToggleContentComponent, data: {} });
export { RenderType_ToggleContentComponent as RenderType_ToggleContentComponent };
function View_ToggleContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "toggle-content__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleContentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "toggle-content__button button button_small button_no-margin"], ["type", "button"]], [[2, "button_orange", null], [2, "button_primary", null], [2, "button_background", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTabEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabEnabled; var currVal_1 = _co.tabEnabled; var currVal_2 = !_co.tabEnabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.tabLabel; _ck(_v, 1, 0, currVal_3); }); }
function View_ToggleContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "toggle-content__header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleContentComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleContentComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tabLabel; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ToggleContentComponent_4(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ToggleContentComponent_5(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 1), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ToggleContentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "toggle-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "toggle-content__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleContentComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "toggle-content__body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleContentComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleContentComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tabLabel || _co.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.tabEnabled; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.tabEnabled; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ToggleContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toggle-content", [], null, null, null, View_ToggleContentComponent_0, RenderType_ToggleContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.ToggleContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToggleContentComponentNgFactory = i0.ɵccf("app-toggle-content", i2.ToggleContentComponent, View_ToggleContentComponent_Host_0, { title: "title", tabLabel: "tabLabel", tabEnabled: "tabEnabled", description: "description" }, { tabEnabledToggle: "tabEnabledToggle" }, ["ng-container[default]", "ng-container[tab]"]);
export { ToggleContentComponentNgFactory as ToggleContentComponentNgFactory };

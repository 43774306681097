import { FormControl, FormGroup } from '@angular/forms';

import { Option } from '@modules/field-components';
import { Font, FontStyle } from '@modules/views';
import { isSet } from '@shared';

const weightOptions: Option<number>[] = [
  {
    value: 100,
    name: 'Thin'
  },
  {
    value: 200,
    name: 'Extra Light'
  },
  {
    value: 300,
    name: 'Light'
  },
  {
    value: 400,
    name: 'Regular'
  },
  {
    value: 500,
    name: 'Medium'
  },
  {
    value: 600,
    name: 'Semi Bold'
  },
  {
    value: 700,
    name: 'Bold'
  },
  {
    value: 800,
    name: 'Extra Bold'
  },
  {
    value: 900,
    name: 'Black'
  }
];

export interface FontType {
  weight: number;
  style: FontStyle;
}

export class FontControl extends FormGroup {
  instance: Font;

  controls: {
    family: FormControl;
    type: FormControl;
    size: FormControl;
  };

  typeOptions: Option<FontType>[] = [FontStyle.Normal, FontStyle.Italic].reduce((acc, item) => {
    acc.push(
      ...weightOptions.map(weight => {
        let name = weight.name;
        const styles = [`font-weight: ${weight.value}`];

        if (item == FontStyle.Italic) {
          name = `${weight.name} Italic`;
          styles.push('font-style: italic');
        }

        return {
          value: { weight: weight.value, style: item },
          name: name,
          style: styles.join(';')
        };
      })
    );

    return acc;
  }, []);

  constructor(state: Partial<Font> = {}) {
    super({
      family: new FormControl(isSet(state.family) ? state.family : 'Fira Sans'),
      type: new FormControl({
        weight: 400,
        style: FontStyle.Normal,
        ...(isSet(state.weight) && { weight: state.weight }),
        ...(isSet(state.style) && { style: state.style })
      }),
      size: new FormControl(isSet(state.size) ? state.size : 14)
    });
  }

  deserialize(value: Font, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    if (isSet(value.family)) {
      this.controls.family.patchValue(value.family, { emitEvent: options.emitEvent });
    }

    const type = this.typeOptions.find(item => item.value.weight == value.weight && item.value.style == value.style);
    if (type) {
      this.controls.type.patchValue(type.value, { emitEvent: options.emitEvent });
    }

    this.controls.size.patchValue(value.size, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Font): Font {
    if (!instance) {
      instance = new Font();
    }

    instance.family = this.controls.family.value;

    if (this.controls.type.value) {
      instance.weight = this.controls.type.value.weight;
      instance.style = this.controls.type.value.style;
    }

    instance.size = this.controls.size.value;

    return instance;
  }

  serialize(): Font {
    return this.getInstance(this.instance);
  }
}

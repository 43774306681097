import uniqBy from 'lodash/uniqBy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { MenuItemActionType } from '../../data/menu-item-action';
import { MenuItemType } from '../../data/menu-item-type';
import { ModelLinkMenuItem } from '../../data/model-link-menu-item';
import { SimpleMenuItem } from '../../data/simple-menu-item';
import { MenuSettingsStore } from '../../stores/menu-settings/menu-settings.store';
import { forEachMenuItems } from '../../utils/menu';
var MenuPagesService = /** @class */ (function () {
    function MenuPagesService(dashboardStore, menuSettingsStore, modelDescriptionStore, viewSettingsStore, currentProjectStore, currentEnvironmentStore) {
        this.dashboardStore = dashboardStore;
        this.menuSettingsStore = menuSettingsStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
    }
    MenuPagesService.prototype.getGroups = function () {
        var _this = this;
        return combineLatest(
        // this.dashboardStore.getFirst(),
        this.menuSettingsStore.get().pipe(map(function (menuSettings) {
            var menuItems = [];
            if (menuSettings) {
                forEachMenuItems(menuSettings.getAllItems(), function (item) {
                    if (item.type != MenuItemType.Section) {
                        menuItems.push(item);
                    }
                });
            }
            return menuItems;
        })), this.modelDescriptionStore.get(), this.viewSettingsStore.get()).pipe(map(function (_a) {
            var menuItems = _a[0], modelDescriptions = _a[1], viewSettings = _a[2];
            var menuLinks = menuItems
                .map(function (item) {
                if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
                    var page = viewSettings.find(function (i) { return i.uid == item.action.pageUid; });
                    if (!page) {
                        return;
                    }
                    return {
                        title: item.title,
                        icon: item.icon || 'circle',
                        link: page ? page.link : undefined,
                        page: page,
                        pageUid: item.action.pageUid
                    };
                }
                else if (item instanceof ModelLinkMenuItem) {
                    var model = new ModelDescription();
                    var modelId = item.model.split('.', 2);
                    if (modelId.length == 2) {
                        model.resource = modelId[0], model.model = modelId[1];
                    }
                    else {
                        model.model = modelId[0];
                    }
                    return {
                        title: item.title,
                        icon: item.icon || 'circle',
                        link: model.link
                    };
                }
            })
                .filter(function (item) { return item; });
            var pageLink = viewSettings
                .filter(function (item) { return item.uniqueName; })
                .map(function (item) {
                return {
                    title: item.name || item.uniqueName,
                    icon: 'document',
                    link: item.link,
                    page: item,
                    pageUid: item.uid
                };
            });
            var result = [
                {
                    title: 'Menu pages',
                    items: uniqBy(menuLinks, function (item) {
                        return JSON.stringify({ page: item.pageUid, model: item.model });
                    }),
                    actions: [
                        {
                            label: 'Customize menu',
                            icon: 'gear',
                            link: _this.currentProjectStore.instance.settingsMenuLink()
                        }
                    ],
                    border: true,
                    collapsible: true,
                    showEmpty: true,
                    searchIgnore: true,
                    duplicate: true,
                    sorted: true
                },
                {
                    title: 'All Pages',
                    items: pageLink
                },
                // {
                //   title: 'Dashboards',
                //   items: dashboards.map(item => {
                //     return {
                //       title: item.name,
                //       icon: 'document',
                //       link: item.link
                //     };
                //   })
                // },
                {
                    title: 'Collections',
                    items: modelDescriptions
                        .map(function (item) {
                        var resource = _this.currentEnvironmentStore.resources.find(function (i) { return i.uniqueName == item.resource; });
                        // TODO: Legacy views
                        var hasCustomizations = viewSettings.find(function (viewSetting) {
                            return [ViewSettingsType.List, ViewSettingsType.Change].includes(viewSetting.view) &&
                                viewSetting.resource == item.resource &&
                                viewSetting.model == item.model;
                        });
                        if (!resource || (resource && resource.demo) || !hasCustomizations) {
                            return;
                        }
                        return {
                            title: resource
                                ? (item.verboseNamePlural || item.model) + " (" + (resource.name || resource.uniqueName) + ")"
                                : item.verboseNamePlural || item.model,
                            icon: 'document',
                            link: item.link
                        };
                    })
                        .filter(function (item) { return item != undefined; })
                }
            ];
            return result.map(function (group) {
                if (!group.sorted) {
                    group.items = group.items.sort(function (lhs, rhs) {
                        return lhs.title.toLowerCase() < rhs.title.toLowerCase()
                            ? -1
                            : lhs.title.toLowerCase() == rhs.title.toLowerCase()
                                ? 0
                                : 1;
                    });
                }
                return group;
            });
        }));
    };
    return MenuPagesService;
}());
export { MenuPagesService };

import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { AnalyticsEvent, AnalyticsEventAction } from '../../data/event';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { UniversalAnalyticsService } from '../universal-analytics/universal-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../users/stores/current-user.store";
import * as i2 from "../../../projects/stores/projects.store";
import * as i3 from "../google-analytics/google-analytics.service";
import * as i4 from "../universal-analytics/universal-analytics.service";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(currentUserStore, projectsStore, googleAnalyticsService, universalAnalyticsService) {
        var _this = this;
        this.currentUserStore = currentUserStore;
        this.projectsStore = projectsStore;
        this.googleAnalyticsService = googleAnalyticsService;
        this.universalAnalyticsService = universalAnalyticsService;
        this.currentUserStore.getFirst().subscribe(function () {
            _this.universalAnalyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.AppOpen);
        });
        this.currentUserStore.instance$.subscribe(function (user) {
            if (!user) {
                return;
            }
            _this.universalAnalyticsService.sendEvent(AnalyticsEvent.GA.CurrentUser, AnalyticsEventAction.Init, user.username);
            // this.projectsStore.getFirst().subscribe(projects => {
            _this.universalAnalyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.UserSet, {
                FirstName: user.firstName,
                LastName: user.lastName
                // ProjectsCreated: projects ? projects.filter(item => item.isCreated).length : 0
            });
            // });
            googleAnalyticsService.clientId$.subscribe(function (clientId) {
                if (!clientId) {
                    return;
                }
                if (!_this.currentUserStore.instance.isAnonymous() &&
                    _this.currentUserStore.instance.params.addGoogleAnalyticsClientId(googleAnalyticsService.clientId)) {
                    _this.currentUserStore.update(['params']).subscribe();
                }
            });
        });
    }
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.CurrentUserStore), i0.inject(i2.ProjectsStore), i0.inject(i3.GoogleAnalyticsService), i0.inject(i4.UniversalAnalyticsService)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };

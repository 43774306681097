import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { CurrentUserStore, User, UserService } from '@modules/users';
import { isSet } from '@shared';

export function validateCurrentPassword(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return;
    }

    const parent = control.parent as ChangePasswordForm;

    if (!parent) {
      return;
    }

    if (parent.user && !parent.user.hasPassword) {
      return;
    }

    if (!isSet(control.value)) {
      return { required: true };
    }
  };
}

@Injectable()
export class ChangePasswordForm extends FormGroup {
  controls: {
    current_password: FormControl;
    new_password: FormControl;
    repeat_password: FormControl;
  };

  user: User;

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private userService: UserService,
    private currentUserStore: CurrentUserStore
  ) {
    super({
      current_password: new FormControl('', validateCurrentPassword()),
      new_password: new FormControl('', Validators.required),
      repeat_password: new FormControl('', Validators.required)
    });
  }

  init(user: User) {
    this.user = user;
    this.markAsPristine();
  }

  changePassword(): Observable<User> {
    this.markAsDirty();

    const instance = new User();

    instance.currentPassword = this.controls.current_password.value;
    instance.password = this.controls.new_password.value;

    return this.userService.update(instance, ['password', 'current_password']).pipe(
      switchMap(() => this.currentUserStore.getFirst(true)),
      tap(result => {
        this.user = result;

        this.reset();
        this.markAsPristine();
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { Observable, of } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';

import {
  ActionDescription,
  ActionResponse,
  getJetAppSendEmailActionDescription,
  JET_APP_SEND_EMAIL
} from '@modules/actions';
import { ParameterField } from '@modules/fields';
import {
  getJetAppGroupModelDescription,
  getJetAppUserModelDescription,
  JET_APP_GROUPS,
  JET_APP_USERS,
  Model,
  ModelDescription,
  ModelFieldType
} from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  EmailService,
  ProjectGroup,
  ProjectGroupService,
  ProjectInvite,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectUser,
  ProjectUserService,
  Resource
} from '@modules/projects';
import { ActionQuery, QueryType, StorageQuery } from '@modules/queries';
import { Storage, StorageObject, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';

// TODO: Refactor import
import { modelFieldToRawListViewSettingsColumn } from '../../../customize/utils/common';

import { ModelResponse } from '../../data/model-response';
import { ActionExecuteParams, ResourceController } from '../../data/resource-controller';
import { applyFrontendFiltering, applyFrontendPagination } from '../../utils/filters';
import { ProjectStorageService } from '../project-storage/project-storage.service';

@Injectable()
export class JetAppResourceController extends ResourceController {
  filtersExcludable = true;
  filtersLookups = true;
  relationFilter = true;

  private currentProjectStore: CurrentProjectStore;
  private currentEnvironmentStore: CurrentEnvironmentStore;
  private projectUserService: ProjectUserService;
  private projectGroupService: ProjectGroupService;
  private projectPropertyStore: ProjectPropertyStore;
  private emailService: EmailService;
  private projectStorageService: ProjectStorageService;

  init() {
    this.currentProjectStore = this.initService<CurrentProjectStore>(CurrentProjectStore);
    this.currentEnvironmentStore = this.initService<CurrentEnvironmentStore>(CurrentEnvironmentStore);
    this.projectUserService = this.initService<ProjectUserService>(ProjectUserService);
    this.projectGroupService = this.initService<ProjectGroupService>(ProjectGroupService);
    this.projectPropertyStore = this.initService<ProjectPropertyStore>(ProjectPropertyStore);
    this.emailService = this.initService<EmailService>(EmailService);
    this.projectStorageService = this.initService<ProjectStorageService>(ProjectStorageService);
  }

  supportedQueryTypes(queryClass: any): QueryType[] {
    return [QueryType.Simple];
  }

  modelDescriptionGet(resource: Resource, draft = false): Observable<ModelDescription[]> {
    return this.projectPropertyStore.getFirst().pipe(
      map(properties => {
        const userProperties = properties.filter(item => item.type == ProjectPropertyType.User);
        const users = getJetAppUserModelDescription(userProperties);
        const groupProperties = properties.filter(item => item.type == ProjectPropertyType.Group);
        const groups = getJetAppGroupModelDescription(groupProperties);
        return [users, groups];
      })
    );
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    params = params || {};

    if (modelDescription.model == JET_APP_USERS) {
      return this.usersGet(resource, modelDescription, params);
    } else if (modelDescription.model == JET_APP_GROUPS) {
      return this.groupsGet(resource, modelDescription, params);
    }
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    if (isSet(id)) {
      params = { ...params, [idField]: id };
    }

    return this.modelGet(resource, modelDescription, params).pipe(map(result => result.results[0]));
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    if (modelDescription.model == JET_APP_USERS) {
      return this.usersCreate(resource, modelDescription, modelInstance);
    }
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    if (modelDescription.model == JET_APP_USERS) {
      return this.usersUpdate(resource, modelDescription, modelInstance);
    } else if (modelDescription.model == JET_APP_GROUPS) {
      return this.groupsUpdate(resource, modelDescription, modelInstance);
    }
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    if (modelDescription.model == JET_APP_USERS) {
      return this.usersDelete(resource, modelDescription, modelInstance);
    }
  }

  serializeUser(item: ProjectUser | ProjectInvite): Object {
    return {
      uid: item.uid,
      email: item.getEmail(),
      first_name: item.getFirstName(),
      last_name: item.getLastName(),
      group: item.group ? item.group.uid : undefined,
      date_add: item.dateAdd ? item.dateAdd.toISOString() : undefined,
      ...item.properties
    };
  }

  usersGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params: Object
  ): Observable<ModelResponse.GetResponse> {
    return this.projectUserService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        map(models => {
          let result: Object[] = models.map(item => {
            return this.serializeUser(item);
          });

          const columns = modelDescription.fields
            .filter(item => item.type == ModelFieldType.Db)
            .map(item => modelFieldToRawListViewSettingsColumn(item));

          result = applyFrontendFiltering(result, params, columns);

          const data = {
            results: result,
            count: result.length
          };
          const response = this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);

          applyFrontendPagination(response, params, true);

          return response;
        }),
        map(response => {
          response.results.forEach(item => {
            item.deserializeAttributes(modelDescription.dbFields);
          });

          return response;
        })
      );
  }

  getUserInstance(modelInstance: Model): ProjectUser {
    const instanceFields = ['uid', 'email', 'first_name', 'last_name', 'group'];
    const instance = new ProjectUser();
    const group = new ProjectGroup();

    group.uid = modelInstance.getAttribute('group');

    instance.uid = modelInstance.getAttribute('uid');
    instance.userEmail = modelInstance.getAttribute('email');
    instance.group = group;
    instance.properties = fromPairs(
      toPairs(modelInstance.getAttributes()).filter(([name, value]) => !instanceFields.includes(name))
    );

    return instance;
  }

  usersCreate(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Model> {
    const instance = this.getUserInstance(modelInstance);
    const group = new ProjectGroup();

    group.uid = modelInstance.getAttribute('group');

    return this.projectUserService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(
        map(result => {
          const data = this.serializeUser(result);
          const model = this.createModel().deserialize(modelDescription.model, data);
          model.setUp(modelDescription);
          model.deserializeAttributes(modelDescription.dbFields);
          return model;
        })
      );
  }

  usersUpdate(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Model> {
    return this.projectUserService
      .getDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        modelInstance.getAttribute('uid')
      )
      .pipe(
        switchMap(instance => {
          const newInstance = this.getUserInstance(modelInstance);

          instance.uid = newInstance.uid;
          instance.userEmail = newInstance.userEmail;
          instance.group = newInstance.group;
          instance.properties = {
            ...instance.properties,
            ...newInstance.properties
          };

          return this.projectUserService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance
          );
        }),
        tap(result => {
          if (result.uid == this.currentEnvironmentStore.instance.user.uid) {
            this.currentEnvironmentStore.updateUser(result);
          }
        }),
        map(result => {
          const data = this.serializeUser(result);
          const model = this.createModel().deserialize(modelDescription.model, data);
          model.setUp(modelDescription);
          model.deserializeAttributes(modelDescription.dbFields);
          return model;
        })
      );
  }

  usersDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<boolean> {
    const user = new ProjectUser();

    user.uid = modelInstance.getAttribute('uid');

    return this.projectUserService
      .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, user)
      .pipe(map(() => true));
  }

  serializeGroup(item: ProjectGroup): Object {
    return {
      uid: item.uid,
      name: item.name,
      description: item.description,
      permissions_group: item.permissionsGroup,
      ...item.properties
    };
  }

  getGroupInstance(modelInstance: Model): ProjectGroup {
    const instanceFields = ['uid', 'name'];
    const instance = new ProjectGroup();

    instance.uid = modelInstance.getAttribute('uid');
    instance.name = modelInstance.getAttribute('name');
    instance.properties = fromPairs(
      toPairs(modelInstance.getAttributes()).filter(([name, value]) => !instanceFields.includes(name))
    );

    return instance;
  }

  groupsGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params: Object
  ): Observable<ModelResponse.GetResponse> {
    return this.projectGroupService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        map(models => {
          let result: Object[] = models.map(item => this.serializeGroup(item));

          const columns = modelDescription.fields
            .filter(item => item.type == ModelFieldType.Db)
            .map(item => modelFieldToRawListViewSettingsColumn(item));

          result = applyFrontendFiltering(result, params, columns);

          const data = {
            results: result,
            count: result.length
          };
          const response = this.createGetResponse().deserialize(data, modelDescription.model, modelDescription);

          applyFrontendPagination(response, params, true);

          return response;
        }),
        map(response => {
          response.results.forEach(item => {
            item.deserializeAttributes(modelDescription.dbFields);
          });

          return response;
        })
      );
  }

  groupsUpdate(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Model> {
    return this.projectGroupService
      .getDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        modelInstance.getAttribute('uid')
      )
      .pipe(
        switchMap(instance => {
          const newInstance = this.getUserInstance(modelInstance);

          instance.uid = newInstance.uid;
          instance.name = newInstance.getLastName();
          instance.properties = {
            ...instance.properties,
            ...newInstance.properties
          };

          return this.projectGroupService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance
          );
        }),
        tap(result => {
          if (result.uid == this.currentEnvironmentStore.instance.group.uid) {
            this.currentEnvironmentStore.updateGroup(result);
          }
        }),
        map(result => {
          const data = this.serializeGroup(result);
          const model = this.createModel().deserialize(modelDescription.model, data);
          model.setUp(modelDescription);
          model.deserializeAttributes(modelDescription.dbFields);
          return model;
        })
      );
  }

  actionDescriptionGet(resource: Resource): Observable<ActionDescription[]> {
    const sendEmail = getJetAppSendEmailActionDescription();
    return of([sendEmail]);
  }

  actionExecute(
    resource: Resource,
    query: ActionQuery,
    parameters: ParameterField[] = [],
    params?: ActionExecuteParams,
    rawErrors?: boolean
  ): Observable<ActionResponse> {
    if (query.simpleQuery && query.simpleQuery.name == JET_APP_SEND_EMAIL) {
      return this.emailService
        .send(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          params.actionParams['to'],
          params.actionParams['subject'],
          params.actionParams['text'],
          params.actionParams['text_html']
        )
        .pipe(
          map(result => {
            return {
              json: result
            };
          })
        );
    }
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    return this.projectStorageService.uploadFile(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      file,
      path,
      fileName
    );
  }

  getStorageObjects(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<StorageObjectsResponse> {
    return this.projectStorageService.getObjects(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      path
    );
  }

  deleteStorageObject(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    return this.projectStorageService.deleteObject(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      path
    );
  }

  createStorageFolder(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    return this.projectStorageService.createFolder(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      path
    );
  }
}

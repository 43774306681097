import { Directive, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';

import { AppConfigService } from '@core';
import { addClass, isSet, removeClass, TypedChanges } from '@shared';

import { IconData } from '../../data/icon-data';
import { getEmojiConverter, getIconData } from '../../utils/icons';

const convertor = getEmojiConverter();

@Directive({
  selector: '[appIcon]'
})
export class IconDirective implements OnChanges {
  @Input('appIcon') icon: string;

  @HostBinding('style.background-image') get backgroundImage() {
    return isSet(this.image) ? `url(${this.image})` : null;
  }

  currentIcon: IconData;
  image: string;

  constructor(private appConfigService: AppConfigService, private el: ElementRef) {}

  ngOnChanges(changes: TypedChanges<IconDirective>) {
    if (changes.icon) {
      const prevIcon = this.currentIcon;
      const icon = getIconData(changes.icon.currentValue, {
        mediaBaseUrl: this.appConfigService.getMediaBaseUrl()
      });

      this.currentIcon = icon;

      if (prevIcon && prevIcon.icon) {
        removeClass(this.el.nativeElement, `icon-${prevIcon.icon}`);
      }

      if (icon && icon.icon) {
        addClass(this.el.nativeElement, `icon-${icon.icon}`);
      }

      if (icon && icon.emoji) {
        this.el.nativeElement.innerHTML = convertor.replace_colons(`:${icon.emoji}:`);
      } else if (prevIcon && prevIcon.emoji) {
        this.el.nativeElement.innerHTML = '';
      }

      this.image = icon && icon.image ? icon.image : undefined;
    }
  }
}

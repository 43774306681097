import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html'
})
export class FormErrorsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() manualMargin = false;

  constructor() {}

  ngOnInit() {}
}

import { FormControl, FormGroup } from '@angular/forms';

import { ValueResize } from '@modules/views';
import { isSet } from '@shared';

export interface ValueResizeControlValue {
  top_left: number;
  top_right: number;
  bottom_right: number;
  bottom_left: number;
}

export class ValueResizeControl extends FormGroup {
  instance: ValueResize;

  controls: {
    enabled: FormControl;
    min: FormControl;
    max: FormControl;
  };

  _applyFormState: any;

  constructor(state: Partial<ValueResize> = {}) {
    super({
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
      min: new FormControl(isSet(state.min) ? state.min : undefined),
      max: new FormControl(isSet(state.max) ? state.max : undefined)
    });
  }

  deserialize(value: ValueResize, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.enabled.patchValue(value.enabled, options);
    this.controls.min.patchValue(value.min, options);
    this.controls.max.patchValue(value.max, options);
  }

  getInstance(instance?: ValueResize): ValueResize {
    if (!instance) {
      instance = new ValueResize();
    }

    instance.enabled = this.controls.enabled.value;
    instance.min = this.controls.min.value;
    instance.max = this.controls.max.value;

    return instance;
  }

  serialize(): ValueResize {
    return this.getInstance(this.instance);
  }

  onDisabledChange = (value: boolean) => undefined;

  registerOnChange(fn: Function): void {
    this.valueChanges.subscribe(value => fn(value));
  }

  registerOnDisabledChange(fn: (disabled: boolean) => void): void {
    this.onDisabledChange = fn;
  }

  enable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.enable(opts);
    this.onDisabledChange(false);
  }

  disable(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.disable(opts);
    this.onDisabledChange(true);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../query-builder-object-structure-node/query-builder-object-structure-node.component.ngfactory";
import * as i2 from "../query-builder-object-structure-node/query-builder-object-structure-node.component";
import * as i3 from "@angular/common";
import * as i4 from "./query-builder-object-structure.component";
var styles_QueryBuilderObjectStructureComponent = [];
var RenderType_QueryBuilderObjectStructureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderObjectStructureComponent, data: {} });
export { RenderType_QueryBuilderObjectStructureComponent as RenderType_QueryBuilderObjectStructureComponent };
function View_QueryBuilderObjectStructureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-object-structure-node", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_QueryBuilderObjectStructureNodeComponent_0, i1.RenderType_QueryBuilderObjectStructureNodeComponent)), i0.ɵdid(1, 770048, null, 0, i2.QueryBuilderObjectStructureNodeComponent, [], { node: [0, "node"], pathSelected: [1, "pathSelected"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.object; var currVal_1 = _co.pathControl.value; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_QueryBuilderObjectStructureComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderObjectStructureComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.object !== undefined) && (_co.object !== null)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QueryBuilderObjectStructureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-object-structure", [], null, null, null, View_QueryBuilderObjectStructureComponent_0, RenderType_QueryBuilderObjectStructureComponent)), i0.ɵdid(1, 245760, null, 0, i4.QueryBuilderObjectStructureComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderObjectStructureComponentNgFactory = i0.ɵccf("app-query-builder-object-structure", i4.QueryBuilderObjectStructureComponent, View_QueryBuilderObjectStructureComponent_Host_0, { object: "object", pathControl: "pathControl" }, {}, []);
export { QueryBuilderObjectStructureComponentNgFactory as QueryBuilderObjectStructureComponentNgFactory };

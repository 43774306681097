import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { ViewSettingsAction } from '@modules/actions';
import { FieldActions } from '@modules/customize';

import { FieldActionsControl } from './field-actions.control';

export class FieldActionsArray extends FormArray {
  controls: FieldActionsControl[];

  patchValue(value: FieldActions[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.setValue(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  setValue(value: FieldActions[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }

  setControls(controls: FieldActionsControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: FieldActionsControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: FieldActions): FieldActionsControl {
    const control = new FieldActionsControl(item);
    control.markAsPristine();
    return control;
  }

  appendControl(item?: FieldActions): FieldActionsControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  getColumnControl(name: string): FieldActionsControl {
    return this.controls.find(item => item.controls.name.value == name);
  }

  getColumnActions(name: string): ViewSettingsAction[] {
    const control = this.getColumnControl(name);
    return control ? control.controls.actions.value : [];
  }
}

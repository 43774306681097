import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { RawListViewSettingsColumn } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ModelCreateOptions } from '@modules/model-components';
import { ModelDescription } from '@modules/models';
import { Resource, ResourceName } from '@modules/projects';

import {
  registerResourceAddModelComponent,
  ResourceAddModelAddedEvent,
  ResourceAddModelComponent
} from '../../../data/resource-add-model-components';
import { JetDatabaseImportFileComponent } from '../jet-database-import-file/jet-database-import-file.component';

@Component({
  selector: 'app-jet-database-create-table',
  templateUrl: './jet-database-create-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JetDatabaseCreateTableComponent implements OnInit, ResourceAddModelComponent {
  @Input() resource: Resource;
  @Input() analyticsSource: string;
  @Output() cancelled = new EventEmitter<void>();
  @Output() added = new EventEmitter<ResourceAddModelAddedEvent>();

  options: ModelCreateOptions;

  constructor(
    @Optional() protected popupComponent: BasePopupComponent,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit(): void {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.AddCollectionClicked);

    const primaryKey: RawListViewSettingsColumn = {
      name: 'id',
      field: FieldType.Number
    };

    this.options = { columns: [primaryKey], primaryKey: primaryKey.name, resourceCreate: true };
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  finish(result: ModelDescription) {
    this.close();
    this.added.emit({ modelDescription: result, link: result.systemSettingsLink, reloadData: true });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'data'
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionCreated, {
      ResourceID: this.resource.typeItem.name
    });
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }
}

// registerResourceAddModelComponent(ResourceName.JetDatabase, JetDatabaseImportFileComponent, 'Import from File', {
//   uniqueName: 'import',
//   icon: 'upload'
// });

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

@Component({
  selector: 'app-feature-overview-popup',
  templateUrl: './feature-overview-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureOverviewPopupComponent implements OnInit, OnDestroy {
  @Input() subtitle: string;
  @Input() title: string;
  @Input() description: string;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private routingService: RoutingService,
    private popupComponent: BasePopupComponent
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  cancel() {
    this.popupComponent.close();
  }

  upgradePlan() {
    this.popupComponent.close();
    this.routingService.navigateApp(this.currentProjectStore.instance.settingsBillingLink);
  }
}

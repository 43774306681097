import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';

import { FigmaExternalImport } from '../../data/figma-external-import';

@Injectable()
export class FigmaService {
  constructor(private http: HttpClient, private apiService: ApiService, private projectApiService: ProjectApiService) {}

  getImportCode(projectName: string, environmentName: string): Observable<string> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'figma/import_code');
        let headers = new HttpHeaders();

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.get<{ code: string }>(url, { headers: headers });
      }),
      map(result => result.code),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getImports(
    projectName: string,
    environmentName: string,
    params = {}
  ): Observable<FigmaService.GetExternalImportsResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'figma/imports');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: httpParams });
      }),
      map(result => new FigmaService.GetExternalImportsResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getImportDetail(projectName: string, environmentName: string, id: string): Observable<FigmaExternalImport> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, `figma/imports/${id}`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers });
      }),
      map(result => new FigmaExternalImport().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace FigmaService {
  export class GetExternalImportsResponse {
    public results: FigmaExternalImport[];
    public count: number;
    public numPages: number;
    public perPage: number;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new FigmaExternalImport().deserialize(item));
      this.count = data['count'];
      this.numPages = data['num_pages'];
      this.perPage = data['per_page'];

      return this;
    }
  }
}

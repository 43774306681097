var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName, ResourceType } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, isSet } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { AmazonS3ResourceSettingsForm } from './amazon-s3-resource-settings.form';
var AmazonS3ResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(AmazonS3ResourceSettingsComponent, _super);
    function AmazonS3ResourceSettingsComponent(form, resourceControllerService, dialogService, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.form = form;
        _this.resourceControllerService = resourceControllerService;
        _this.dialogService = dialogService;
        _this.buckets = [];
        return _this;
    }
    AmazonS3ResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        combineLatest(controlValue(this.form.form.controls.access_key_id), controlValue(this.form.form.controls.secret_access_key))
            .pipe(debounceTime(200), switchMap(function (_a) {
            var accessKeyId = _a[0], secretAccessKey = _a[1];
            var controller = _this.resourceControllerService.get(ResourceType.AmazonS3);
            var controlsValid = [
                _this.form.form.controls.access_key_id,
                _this.form.form.controls.secret_access_key
            ].every(function (item) { return item.valid; });
            var buckets$ = controlsValid
                ? controller.getBuckets(accessKeyId, secretAccessKey).pipe(map(function (response) { return response.buckets; }), catchError(function () { return of([]); }))
                : of([]);
            return combineLatest(buckets$, controlValue(_this.form.form.controls.buckets));
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var result = _a[0], usedBuckets = _a[1];
            _this.buckets = result.filter(function (bucket) { return usedBuckets.every(function (item) { return item != bucket.name; }); });
            _this.cd.markForCheck();
        });
    };
    AmazonS3ResourceSettingsComponent.prototype.createBucket = function () {
        this.createdControl = this.form.form.controls.buckets.appendControl();
        this.cd.markForCheck();
    };
    AmazonS3ResourceSettingsComponent.prototype.removeBucket = function (control, i) {
        var _this = this;
        if (!isSet(control.value)) {
            this.processRemoveBucket(control);
            return;
        }
        this.dialogService
            .warning({
            title: 'Remove bucket',
            description: "Are you sure want to remove <strong>Bucket " + (i + 1) + "</strong>?",
            style: 'orange'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result) {
                _this.processRemoveBucket(control);
            }
        });
    };
    AmazonS3ResourceSettingsComponent.prototype.processRemoveBucket = function (control) {
        this.form.form.controls.buckets.removeControl(control);
        this.cd.markForCheck();
    };
    AmazonS3ResourceSettingsComponent.prototype.bucketsDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.form.controls.buckets.controls, event.previousIndex, event.currentIndex);
            this.form.form.updateValueAndValidity();
        }
    };
    return AmazonS3ResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { AmazonS3ResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.AmazonS3, AmazonS3ResourceSettingsComponent);

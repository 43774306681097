var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FieldType, FormField, getFieldDescriptionByType, parseFieldType } from '@modules/fields';
import { isSet } from '@shared';
var ModelDbField = /** @class */ (function () {
    function ModelDbField() {
        this.required = true;
        this.null = false;
        this.defaultType = undefined;
        this.params = {};
    }
    ModelDbField.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.description = data['description'];
        this.field = parseFieldType(data['field']);
        this.dbField = data['db_field'];
        this.editable = data['editable'];
        this.isRelation = data['is_relation'];
        this.filterable = data['filterable'];
        this.dbColumn = data['db_column'];
        this.defaultValue = data['default_value'];
        this.placeholder = data['placeholder'];
        this.validatorType = data['validator_type'];
        this.validatorParams = data['validator_params'];
        if (data['data_source_name']) {
            this.verboseName = data['data_source_name'];
        }
        else {
            this.verboseName = data['verbose_name'];
        }
        this.autoFillVerboseName();
        if (data['data_source_field']) {
            this.field = parseFieldType(data['data_source_field']);
        }
        else {
            this.field = parseFieldType(data['field']);
        }
        if (data['null'] != undefined) {
            this.null = data['null'];
        }
        if (data['default_type']) {
            this.defaultType = data['default_type'];
        }
        if (data['required'] != undefined) {
            this.required = data['required'];
        }
        else if (data['required'] == undefined && this.null == true) {
            this.required = false;
        }
        if (data['sortable'] != undefined) {
            this.sortable = data['sortable'];
        }
        else {
            this.sortable = this.filterable;
        }
        var item = getFieldDescriptionByType(this.field);
        this.params = __assign({}, item.defaultParams, data['params'], data['data_source_params'], item.forceParams);
        this.updateFieldDescription();
        return this;
    };
    ModelDbField.prototype.serialize = function () {
        return {
            name: this.name,
            verbose_name: this.verboseName,
            field: this.field,
            db_field: this.dbField,
            required: this.required,
            null: this.null,
            editable: this.editable,
            is_relation: this.isRelation,
            filterable: this.filterable,
            sortable: this.sortable,
            db_column: this.dbColumn,
            default_type: this.defaultType,
            default_value: this.defaultValue,
            placeholder: this.placeholder,
            validator_type: this.validatorType,
            validator_params: this.validatorParams,
            params: this.params,
            description: this.description
        };
    };
    Object.defineProperty(ModelDbField.prototype, "formField", {
        get: function () {
            if (!this._formField) {
                this._formField = new FormField().deserialize({
                    name: this.name,
                    label: this.verboseName,
                    field: this.field,
                    required: this.required,
                    editable: this.editable,
                    params: this.params,
                    description: this.description
                });
            }
            return this._formField;
        },
        enumerable: true,
        configurable: true
    });
    ModelDbField.prototype.autoFillVerboseName = function () {
        if (!isSet(this.verboseName) && isSet(this.name) && typeof this.name === 'string') {
            this.verboseName = this.name;
            if (this.field == FieldType.RelatedModel && this.verboseName.toLowerCase().substr(-3) == '_id') {
                this.verboseName = this.verboseName.substr(0, this.verboseName.length - 3);
            }
            this.verboseName = this.verboseName.replace(/_/g, ' ');
        }
    };
    ModelDbField.prototype.resetFormField = function () {
        this._formField = undefined;
    };
    ModelDbField.prototype.updateFieldDescription = function () {
        this.fieldDescription = getFieldDescriptionByType(this.field);
    };
    Object.defineProperty(ModelDbField.prototype, "lookups", {
        get: function () {
            return this.fieldDescription.lookups;
        },
        enumerable: true,
        configurable: true
    });
    ModelDbField.prototype.applyOverrides = function (overrideField) {
        this.verboseName = overrideField.verboseName;
        this.description = overrideField.description;
        this.required = overrideField.required;
        this.editable = overrideField.editable;
        this.placeholder = overrideField.placeholder;
        this.validatorType = overrideField.validatorType;
        this.validatorParams = overrideField.validatorParams;
        this.params = __assign({}, overrideField.params, this.params);
    };
    return ModelDbField;
}());
export { ModelDbField };

import { EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ElementItem, ViewContext } from '@modules/customize';

export abstract class BaseElementComponent<T extends ElementItem = ElementItem> {
  @Input() element: T;
  @Input() elementActive = false;
  @Input() context: ViewContext;
  @Input() visible = true;
  @Input() actionClicked: Observable<string>;
  @Input() accentColor: string;
  @Output() updated = new EventEmitter<T>();
  @Output() replaceRequested = new EventEmitter<T[]>();
  @Output() deleteRequested = new EventEmitter<void>();

  element$ = new BehaviorSubject<T>(undefined);

  elementOnChange(value: T) {
    this.element$.next(value);
  }
}

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { Shadow } from '@modules/views';

import { ShadowControl } from './shadow.control';

export class ShadowArray extends FormArray {
  controls: ShadowControl[];

  deserialize(value: Shadow[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): Shadow[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: ShadowControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: ShadowControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: Shadow): ShadowControl {
    const control = new ShadowControl();

    if (!item) {
      item = new Shadow();
    }

    control.deserialize(item);
    control.markAsPristine();

    return control;
  }

  appendControl(item?: Shadow): ShadowControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  prependControl(item?: Shadow): ShadowControl {
    const control = this.createControl(item);
    this.insert(0, control);
    return control;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ApiKey } from '../../data/api-key';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ApiKeyService = /** @class */ (function () {
    function ApiKeyService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ApiKeyService.prototype.get = function (project, environment) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'api_keys');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return result.map(function (item) { return new ApiKey().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ApiKeyService.prototype.create = function (project, environment, name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'api_keys');
            var data = {
                name: name
            };
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new ApiKey().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ApiKeyService.prototype.update = function (project, environment, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "api_keys/" + instance.token);
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new ApiKey().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ApiKeyService.prototype.revoke = function (project, environment, key) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "api_keys/" + key + "/revoke");
            var data = {};
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ApiKeyService.ngInjectableDef = i0.defineInjectable({ factory: function ApiKeyService_Factory() { return new ApiKeyService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ApiKeyService, providedIn: "root" });
    return ApiKeyService;
}());
export { ApiKeyService };

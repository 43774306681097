/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../actions-edit/actions-edit-item/actions-edit-item.component.ngfactory";
import * as i2 from "../actions-edit/actions-edit-item/actions-edit-item.component";
import * as i3 from "../../../action-queries/services/action/action.service";
import * as i4 from "../../../projects/stores/current-environment.store";
import * as i5 from "./action-single-edit.component";
import * as i6 from "../../services/customize-bar/customize-bar.service";
import * as i7 from "../../services/customize-bar-context/customize-bar.context";
var styles_ActionSingleEditComponent = [];
var RenderType_ActionSingleEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionSingleEditComponent, data: {} });
export { RenderType_ActionSingleEditComponent as RenderType_ActionSingleEditComponent };
export function View_ActionSingleEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-actions-edit-item", [], null, [[null, "open"], [null, "removeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.editActionItem(_co.control, _co.title) !== false);
        ad = (pd_0 && ad);
    } if (("removeClick" === en)) {
        var pd_1 = (_co.removeActionItem(_co.control) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ActionsEditItemComponent_0, i1.RenderType_ActionsEditItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.ActionsEditItemComponent, [i3.ActionService, i4.CurrentEnvironmentStore, i0.ChangeDetectorRef], { actionItemClass: [0, "actionItemClass"], title: [1, "title"], action: [2, "action"], context: [3, "context"], contextElement: [4, "contextElement"], defaultSubtitle: [5, "defaultSubtitle"], disabled: [6, "disabled"], remove: [7, "remove"], icon: [8, "icon"], error: [9, "error"] }, { open: "open", removeClick: "removeClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.actionItemClass; var currVal_1 = _co.title; var currVal_2 = _co.control.value; var currVal_3 = _co.context; var currVal_4 = _co.contextElement; var currVal_5 = _co.defaultSubtitle; var currVal_6 = (_co.defaultDisabled && !_co.control.value); var currVal_7 = (_co.remove && _co.control.value); var currVal_8 = _co.icon; var currVal_9 = ((_co.control.invalid && ((_co.control.parent == null) ? null : _co.control.parent.dirty)) ? "Not configured" : undefined); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ActionSingleEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-single-edit", [], null, null, null, View_ActionSingleEditComponent_0, RenderType_ActionSingleEditComponent)), i0.ɵdid(1, 245760, null, 0, i5.ActionSingleEditComponent, [i6.CustomizeBarService, i7.CustomizeBarContext, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionSingleEditComponentNgFactory = i0.ɵccf("app-action-single-edit", i5.ActionSingleEditComponent, View_ActionSingleEditComponent_Host_0, { options: "options", label: "label", title: "title", control: "control", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", defaultTitle: "defaultTitle", defaultSubtitle: "defaultSubtitle", defaultDisabled: "defaultDisabled", draggable: "draggable", remove: "remove", pin: "pin", pinned: "pinned", icon: "icon", object: "object", firstInit: "firstInit" }, {}, []);
export { ActionSingleEditComponentNgFactory as ActionSingleEditComponentNgFactory };

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { User, UserService } from '@modules/users';
import { isSet } from '@shared';

export function validateCurrentPassword(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return;
    }

    const parent = control.parent as ChangeEmailForm;

    if (!parent) {
      return;
    }

    if (parent.user && !parent.user.hasPassword) {
      return;
    }

    if (!isSet(control.value)) {
      return { required: true };
    }
  };
}

@Injectable()
export class ChangeEmailForm extends FormGroup {
  controls: {
    password: FormControl;
    email: FormControl;
  };

  user: User;

  constructor(private formUtils: FormUtils, private fb: FormBuilder, private userService: UserService) {
    super({
      password: new FormControl('', validateCurrentPassword()),
      email: new FormControl('', Validators.required)
    });
  }

  init(user: User) {
    this.user = user;
    this.markAsPristine();
  }

  submit(): Observable<UserService.ChangeEmailResponse> {
    this.markAsDirty();

    const newEmail = this.controls.email.value;
    const currentPassword = this.user && !this.user.hasPassword ? undefined : this.controls.password.value;

    return this.userService.changeEmail(newEmail, currentPassword).pipe(
      tap(() => {
        this.controls.email.patchValue('');
        this.controls.password.patchValue('');
        this.markAsPristine();
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

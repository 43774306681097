import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ActionCreatePopupComponent } from './action-create-popup/action-create-popup.component';
import { ActionEditPopupComponent } from './action-edit-popup/action-edit-popup.component';
import { ActionExecutePopupComponent } from './action-execute-popup/action-execute-popup.component';
import { ActionExecuteComponent } from './action-execute/action-execute.component';

@NgModule({
  imports: [
    CommonModule,
    FieldsModule,
    SharedModule,
    FieldComponentsModule,
    ReactiveFormsModule,
    UiModule,
    ThemeComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    ActionExecutePopupComponent,
    ActionEditPopupComponent,
    ActionExecuteComponent,
    ActionCreatePopupComponent
  ],
  exports: [ActionExecutePopupComponent, ActionEditPopupComponent, ActionExecuteComponent],
  entryComponents: [ActionExecutePopupComponent, ActionEditPopupComponent, ActionCreatePopupComponent]
})
export class ActionsComponentsModule {}

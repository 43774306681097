var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl } from '@angular/forms';
var TypedControl = /** @class */ (function (_super) {
    __extends(TypedControl, _super);
    function TypedControl(formState, validatorOrOpts, asyncValidator) {
        return _super.call(this, formState, validatorOrOpts, asyncValidator) || this;
    }
    TypedControl.prototype.setValue = function (value, options) {
        _super.prototype.setValue.call(this, value, options);
    };
    TypedControl.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
    };
    TypedControl.prototype.reset = function (formState, options) {
        _super.prototype.reset.call(this, formState, options);
    };
    return TypedControl;
}(FormControl));
export { TypedControl };

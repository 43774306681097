var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, throwError } from 'rxjs';
import { catchError, delay, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { DefaultType, FieldType, getFieldDescriptionByType } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelField, ModelFieldType, sortModelFields } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getJetDatabaseType, ResourceControllerService } from '@modules/resources';
import { ascComparator, controlValue, isSet } from '@shared';
export var validateRelatedModel = function (control) {
    if (!control.parent) {
        return;
    }
    var type = control.parent.controls['field'].value;
    if (type != FieldType.RelatedModel) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
var CustomizeBarResourceFieldEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarResourceFieldEditForm, _super);
    function CustomizeBarResourceFieldEditForm(resourceControllerService, modelDescriptionService, modelDescriptionStore, actionStore, formUtils, currentProjectStore, currentEnvironmentStore) {
        var _a;
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            name_enabled: new FormControl(false),
            field: new FormControl(FieldType.Text, Validators.required),
            db_field_enabled: new FormControl(false),
            db_field: new FormControl('CharField'),
            null: new FormControl(true),
            default_type: new FormControl(DefaultType.NULL),
            default_value: new FormControl(null),
            length: new FormControl(null),
            related_model: new FormControl(null, validateRelatedModel),
            custom_primary_key: new FormControl(null)
        }) || this;
        _this.resourceControllerService = resourceControllerService;
        _this.modelDescriptionService = modelDescriptionService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionStore = actionStore;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.dbFieldSections = [
            {
                label: 'Text',
                icon: 'text',
                children: [
                    {
                        value: 'CharField',
                        name: 'Varchar'
                    },
                    {
                        value: 'FixedCharField',
                        name: 'Char'
                    },
                    {
                        value: 'TextField',
                        name: 'Text'
                    }
                ]
            },
            {
                label: 'Numeric',
                icon: 'number',
                children: [
                    {
                        value: 'IntegerField',
                        name: 'Integer'
                    },
                    {
                        value: 'BigIntegerField',
                        name: 'Big Integer'
                    },
                    {
                        value: 'SmallIntegerField',
                        name: 'Small Integer'
                    },
                    {
                        value: 'FloatField',
                        name: 'Float'
                    },
                    {
                        value: 'NumberField',
                        name: 'Numeric'
                    },
                    {
                        value: 'DecimalField',
                        name: 'Decimal'
                    },
                    {
                        value: 'DoublePrecisionField',
                        name: 'Double Precision'
                    }
                ]
            },
            {
                label: 'Choices',
                icon: 'select',
                children: [
                    {
                        value: 'BooleanField',
                        name: 'Boolean'
                    }
                ]
            },
            {
                label: 'Date & Time',
                icon: 'calendar',
                children: [
                    {
                        value: 'DateField',
                        name: 'Date'
                    },
                    {
                        value: 'DateTimeField',
                        name: 'DateTime'
                    },
                    {
                        value: 'TimestampField',
                        name: 'Timestamp'
                    }
                ]
            },
            {
                label: 'Other',
                icon: 'components',
                children: [
                    {
                        value: 'JSONField',
                        name: 'JSON'
                    },
                    {
                        value: 'GeometryField',
                        name: 'Geometry'
                    },
                    {
                        value: 'GeographyField',
                        name: 'Geography'
                    }
                ]
            }
        ];
        _this.allDefaultTypeOptions = [
            { value: null, name: 'None' },
            { value: DefaultType.NULL, name: 'NULL' },
            { value: DefaultType.Value, name: 'Fixed value' },
            { value: DefaultType.DatetimeNow, name: 'Current time' },
            { value: DefaultType.Sequence, name: 'Sequence (1, 2, 3, ...)' }
            // { value: DefaultType.AutoIncrement, name: 'Auto Increment (1, 2, 3, ...)' },
            // { value: DefaultType.UUID, name: 'Random UUID' }
        ];
        _this.defaultTypesValueInfo = (_a = {},
            _a[DefaultType.Value] = {
                fieldType: true
            },
            _a[DefaultType.Sequence] = {
                field: FieldType.Text
            },
            _a);
        _this.controls.field.valueChanges.pipe(delay(0), untilDestroyed(_this)).subscribe(function () {
            _this.controls.related_model.updateValueAndValidity();
        });
        controlValue(_this.controls.null)
            .pipe(delay(0), untilDestroyed(_this))
            .subscribe(function (allowNull) {
            if (!allowNull && _this.controls.default_type.value == DefaultType.NULL) {
                _this.controls.default_type.patchValue(null);
            }
            else if (allowNull && _this.controls.default_type.value === null) {
                _this.controls.default_type.patchValue(DefaultType.NULL);
            }
        });
        return _this;
    }
    CustomizeBarResourceFieldEditForm.prototype.ngOnDestroy = function () { };
    CustomizeBarResourceFieldEditForm.prototype.init = function (options) {
        var _this = this;
        this.resource = options.resource;
        this.modelDescription = options.modelDescription;
        this.field = options.field;
        if (options.field) {
            var params = options.field.params;
            var defaultType = !isSet(options.field.defaultType) && options.field.null ? DefaultType.NULL : options.field.defaultType;
            this.controls.name.patchValue(options.field.name);
            this.controls.field.patchValue(options.field.field);
            this.controls.db_field.patchValue(options.field.dbField);
            this.controls.null.patchValue(options.field.null || null);
            this.controls.default_type.patchValue(defaultType);
            this.controls.default_value.patchValue(options.field.defaultValue);
            if (params) {
                this.controls.length.patchValue(params['length']);
                this.controls.related_model.patchValue(params['related_model'] ? params['related_model']['model'] : undefined);
                this.controls.custom_primary_key.patchValue(params['custom_primary_key']);
            }
        }
        this.controls.field.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (!_this.controls.db_field_enabled.value) {
                var jetDatabaseType = getJetDatabaseType({ field: value });
                _this.controls.db_field.patchValue(jetDatabaseType ? jetDatabaseType.dbColumn : 'CharField');
            }
        });
        if (options.defaults && isSet(options.defaults.field)) {
            this.controls.field.patchValue(options.defaults.field);
        }
    };
    CustomizeBarResourceFieldEditForm.prototype.resourceModelItems$ = function () {
        var _this = this;
        return this.modelDescriptionStore.get().pipe(map(function (modelDescriptions) {
            var options = [];
            if (modelDescriptions) {
                options.push.apply(options, modelDescriptions
                    .filter(function (item) { return item.resource == _this.resource.uniqueName && !item.virtual; })
                    .filter(function (item) {
                    return !_this.resource.demo ||
                        item.featured ||
                        (_this.controls.related_model.value && _this.controls.related_model.value['model'] == item.model);
                })
                    .sort(function (lhs, rhs) {
                    return ascComparator(String(lhs.verboseNamePlural).toLowerCase(), String(rhs.verboseNamePlural).toLowerCase());
                })
                    .map(function (item) {
                    return {
                        option: {
                            value: item.model,
                            name: item.verboseNamePlural,
                            icon: 'document'
                        }
                    };
                }));
            }
            return options;
        }));
    };
    CustomizeBarResourceFieldEditForm.prototype.getDefaultTypeOptions$ = function () {
        var _this = this;
        return controlValue(this.controls.null).pipe(map(function (allowNull) { return _this.allDefaultTypeOptions.filter(function (item) { return allowNull || item.value !== DefaultType.NULL; }); }));
    };
    CustomizeBarResourceFieldEditForm.prototype.serialize = function () {
        var instance = cloneDeep(this.field) || new ModelDbField();
        instance.name = isSet(this.controls.name.value)
            ? slugify(this.controls.name.value, { trim: true, separator: '_' }).replace(/_+/g, '_')
            : undefined;
        instance.field = this.controls.field.value;
        instance.dbField = this.controls.db_field.value;
        instance.null = this.controls.null.value;
        instance.updateFieldDescription();
        if (isSet(this.controls.default_type.value)) {
            instance.defaultType = this.controls.default_type.value;
        }
        else {
            instance.defaultType = undefined;
        }
        var valueInfo = this.defaultTypesValueInfo[instance.defaultType];
        if (valueInfo && valueInfo.fieldType) {
            var fieldDescription = getFieldDescriptionByType(instance.field);
            instance.defaultValue = isSet(this.controls.default_value.value)
                ? this.controls.default_value.value
                : fieldDescription.defaultValue;
        }
        else if (valueInfo && valueInfo.field) {
            var fieldDescription = getFieldDescriptionByType(valueInfo.field);
            instance.defaultValue = isSet(this.controls.default_value.value)
                ? this.controls.default_value.value
                : fieldDescription.defaultValue;
        }
        else {
            instance.defaultValue = undefined;
        }
        instance.params = __assign({}, (isSet(this.controls.length.value) ? { length: this.controls.length.value } : {}), (isSet(this.controls.related_model.value)
            ? { related_model: { model: this.controls.related_model.value } }
            : {}), (isSet(this.controls.custom_primary_key.value) ? { length: this.controls.custom_primary_key.value } : {}));
        return instance;
    };
    CustomizeBarResourceFieldEditForm.prototype.createField = function (field, overrideField) {
        var _this = this;
        var controller = this.resourceControllerService.get(this.resource.type);
        return controller.modelDescriptionFieldCreate(this.resource, this.modelDescription, field).pipe(switchMap(function () {
            if (!overrideField) {
                return of(_this.modelDescription);
            }
            var overrideModelDescription = cloneDeep(_this.modelDescriptionStore.instance.find(function (item) { return item.modelId == _this.modelDescription.modelId; }));
            if (overrideModelDescription) {
                var newField = new ModelField();
                var sortedFields = sortModelFields(_this.modelDescription.fields);
                var lastField = sortedFields[sortedFields.length - 1];
                newField.name = field.name;
                newField.type = ModelFieldType.Db;
                newField.item = cloneDeep(field);
                newField.orderAfter = lastField ? lastField.name : undefined;
                if (newField.item instanceof ModelDbField) {
                    newField.item.applyOverrides(overrideField);
                    newField.item.autoFillVerboseName();
                }
                overrideModelDescription.fields.push(newField);
            }
            return _this.modelDescriptionService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, overrideModelDescription);
        }), tap(function (result) { return _this.modelDescriptionStore.updateItem(result); }), delayWhen(function (result) { return _this.actionStore.syncAutoActions(result); }), map(function (result) {
            return {
                field: result.dbField(field.name),
                modelDescription: result
            };
        }));
    };
    CustomizeBarResourceFieldEditForm.prototype.updateField = function (name, field, overrideField) {
        var _this = this;
        var controller = this.resourceControllerService.get(this.resource.type);
        return controller.modelDescriptionFieldUpdate(this.resource, this.modelDescription, name, field).pipe(switchMap(function () {
            if (!overrideField) {
                return of(_this.modelDescription);
            }
            var overrideModelDescription = cloneDeep(_this.modelDescription);
            if (overrideModelDescription) {
                overrideModelDescription.fields = overrideModelDescription.fields.map(function (item) {
                    if (item.name == name && item.type == ModelFieldType.Db && overrideField) {
                        var newField = new ModelField();
                        var existingField = _this.modelDescription.field(name);
                        var sortedFields = sortModelFields(_this.modelDescription.fields);
                        var lastField = sortedFields[sortedFields.length - 1];
                        newField.name = field.name;
                        newField.type = ModelFieldType.Db;
                        newField.item = cloneDeep(field);
                        if (existingField) {
                            newField.orderAfter = existingField.orderAfter;
                        }
                        else if (lastField) {
                            newField.orderAfter = lastField.name;
                        }
                        else {
                            newField.orderAfter = undefined;
                        }
                        if (newField.item instanceof ModelDbField) {
                            newField.item.applyOverrides(overrideField);
                        }
                        return newField;
                    }
                    else {
                        return item;
                    }
                });
            }
            return _this.modelDescriptionService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, overrideModelDescription);
        }), tap(function (result) { return _this.modelDescriptionStore.updateItem(result); }), delayWhen(function (result) { return _this.actionStore.syncAutoActions(result); }), map(function (result) {
            return {
                field: result.dbField(field.name),
                modelDescription: result
            };
        }));
    };
    CustomizeBarResourceFieldEditForm.prototype.submit = function (overrideField) {
        var _this = this;
        var instance = this.serialize();
        var result$ = this.field
            ? this.updateField(this.field.name, instance, overrideField)
            : this.createField(instance, overrideField);
        return result$.pipe(catchError(function (error) {
            console.error(error);
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return CustomizeBarResourceFieldEditForm;
}(FormGroup));
export { CustomizeBarResourceFieldEditForm };

<a
  *ngIf="effectiveItem.section"
  href="javascript:void(0)"
  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
  [class.token-popup-list-item_active]="activeToken | appFormulaTokenEqual: effectiveItem"
  [attr.data-formula-token-path]="effectiveItem.path"
  (mouseenter)="hover.emit()"
  (mouseleave)="out.emit()"
  (click)="opened.emit(effectiveItem.section)"
>
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__line">
      <span *ngIf="effectiveItem.section.icon" class="token-popup-list-item__left">
        <span class="token-popup-list-item__icon" [ngClass]="'icon-' + effectiveItem.section.icon"></span>
      </span>
      <span
        class="token-popup-list-item__main token-popup-list-item__main_wrap"
        [title]="effectiveItem.section.labelPlain || effectiveItem.section.label"
        [innerHTML]="effectiveItem.section.label"
      ></span>
      <span
        class="token-popup-list-item__right"
        [class.token-popup-list-item__right_compact]="effectiveItem.section.insert"
      >
        <span
          *ngIf="effectiveItem.section.insert"
          class="token-popup-list-item__action"
          [appTip]="'Insert «' + (effectiveItem.section.labelPlain || effectiveItem.section.label) + '»'"
          [appTipOptions]="{ side: 'top' }"
          (click)="$event.stopPropagation(); selected.emit(effectiveItem)"
        >
          apply
        </span>
        <span
          *ngIf="effectiveItem.section.labelSecondary | appIsSet"
          class="token-popup-list-item__right-wrap"
          [title]="effectiveItem.section.labelSecondary | appStripTags"
        >
          {{ effectiveItem.section.labelSecondary | appStripTags }}
        </span>
        <span
          *ngIf="!effectiveItem.section.items.length && !(effectiveItem.section.labelSecondary | appIsSet)"
          class="token-popup-list-item__tip"
          >no outputs</span
        >
        <span
          *ngIf="effectiveItem.section.items.length"
          class="token-popup-list-item__icon icon-arrow_forward_2"
        ></span>
      </span>
    </div>
  </div>
</a>

<a
  *ngIf="effectiveItem.item"
  href="javascript:void(0)"
  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
  [class.token-popup-list-item_orange]="effectiveItem.item.orange"
  [class.token-popup-list-item_active]="activeToken | appFormulaTokenEqual: effectiveItem"
  [attr.data-formula-token-path]="effectiveItem.path"
  (mouseenter)="hover.emit()"
  (mouseleave)="out.emit()"
  (click)="selected.emit(effectiveItem)"
  [appTip]="effectiveItem.item.tip"
  [appTipOptions]="{ side: 'bottom' }"
>
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__line">
      <span *ngIf="effectiveItem.item.icon" class="token-popup-list-item__left">
        <span
          class="token-popup-list-item__icon"
          [class.token-popup-list-item__icon_orange]="effectiveItem.item.iconOrange"
          [ngClass]="'icon-' + effectiveItem.item.icon"
        ></span>
      </span>
      <span
        class="token-popup-list-item__main token-popup-list-item__main_wrap"
        [class.token-popup-list-item__main_orange]="effectiveItem.item.orange"
        [class.token-popup-list-item__main_function]="effectiveItem.item.function"
        [title]="effectiveItem.item.labelPlain || effectiveItem.item.label"
      >
        <span [innerHTML]="effectiveItem.item.label"></span>
        <span *ngIf="effectiveItem.item.labelAdditional" class="token-popup-list-item__main-additional">
          {{ effectiveItem.item.labelAdditional }}
        </span>
      </span>
      <span class="token-popup-list-item__right token-popup-list-item__right_wrap">
        <span *ngIf="effectiveItem.item.labelSecondary | appIsSet">
          {{ valueDisplay | appStripTags }}
        </span>

        <span *ngIf="!(effectiveItem.item.labelSecondary | appIsSet) && !valueLoading && (valueDisplay | appIsSet)">
          {{ valueDisplay | appStripTags }}
        </span>
      </span>
    </div>
    <div
      *ngIf="effectiveItem.item.description"
      class="token-popup-list-item__line"
      [class.token-popup-list-item__line_active]="!descriptionAlwaysVisible"
    >
      <div class="token-popup-list-item__description">
        {{ effectiveItem.item.description }}
      </div>
    </div>
  </div>
</a>

/* tslint:disable:no-bitwise */
import range from 'lodash/range';

export function generateUUID() {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; ++i) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  return s.join('');
}

export function generateRandomWord(length: number, alphabet: string) {
  const result = [];
  const alphabetSize = alphabet.length;

  for (let i = 0; i < length; ++i) {
    result[i] = alphabet.substr(Math.floor(Math.random() * alphabetSize), 1);
  }

  return result.join('');
}

export function getAlphabet(from: string, to: string): string {
  const fromCode = from.charCodeAt(0);
  const toCode = to.charCodeAt(0);
  return String.fromCharCode(...range(fromCode, toCode + 1));
}

export function generateAlphanumeric(length: number, options: { letterFirst?: boolean } = {}) {
  const numbers = getAlphabet('0', '9');
  const letters = getAlphabet('a', 'z');
  const alphanumeric = numbers + letters;

  if (options.letterFirst) {
    return generateRandomWord(1, letters) + generateRandomWord(length - 1, alphanumeric);
  } else {
    return generateRandomWord(length, alphanumeric);
  }
}

export function generateHash(length: number) {
  const alphabet = getAlphabet('0', '9') + getAlphabet('a', 'f');
  return generateRandomWord(length, alphabet);
}

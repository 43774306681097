import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import defaults from 'lodash/defaults';

import { DialogOptions } from '../../data/options';
import { DefaultDialogOptions } from '../dialog/dialog.component';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingDialogComponent implements OnInit {
  @Input() options: DialogOptions;

  ngOnInit() {}

  get currentOptions(): DialogOptions {
    return defaults(this.options, DefaultDialogOptions);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewSettings } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import {
  PageTemplate,
  PageTemplatesGeneratorService,
  PageVariantType,
  TemplateSettings
} from '@modules/template-generators';

@Component({
  selector: 'app-page-templates-choose-item',
  templateUrl: './page-templates-choose-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesChooseItemComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() cancelEnabled = false;
  @Input() newPage = false;
  @Input() template: PageTemplate;
  @Input() templateVariant: PageVariantType;
  @Input() resource: Resource;
  @Input() analyticsSource: string;
  @Output() selected = new EventEmitter<{
    template: PageTemplate;
    templateVariant: PageVariantType;
    modelDescription?: ModelDescription;
  }>();
  @Output() cancelClick = new EventEmitter<void>();

  selectedTemplate: PageTemplate;
  selectedTemplateVariant: PageVariantType;
  chooseModel = false;
  selectedModel: ModelDescription;

  previewLoading = true;
  startPage: CustomViewSettings;
  otherPages: CustomViewSettings[] = [];

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private pageTemplatesGeneratorService: PageTemplatesGeneratorService,
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setSelectedTemplate(this.template);
    this.setSelectedTemplateVariant(this.templateVariant);
    this.updatePreview();

    if (this.template && this.templateVariant) {
      this.setChooseModel(true);
    }
  }

  ngOnDestroy(): void {}

  setSelectedTemplate(template: PageTemplate) {
    this.selectedTemplate = template;
    this.cd.markForCheck();
  }

  setSelectedTemplateVariant(variant: PageVariantType) {
    this.selectedTemplateVariant = variant;
    this.cd.markForCheck();
  }

  onSelectedTemplate(value: { template: PageTemplate; templateVariant: PageVariantType }) {
    this.setSelectedTemplate(value.template);
    this.setSelectedTemplateVariant(value.templateVariant);
    this.updatePreview();
  }

  setChooseModel(value: boolean) {
    this.chooseModel = value;
    this.cd.markForCheck();
  }

  setSelectedModel(item: ModelDescription) {
    this.selectedModel = item;
    this.cd.markForCheck();
  }

  getTemplateSettings(): Observable<TemplateSettings> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => {
        const defaultResourceName = 'demo_postgresql';
        const defaultModelName = 'companies';
        const defaultResource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == defaultResourceName
        );
        const defaultModelDescription = defaultResource
          ? modelDescriptions.find(
              item => item.resource == defaultResource.uniqueName && item.model == defaultModelName
            )
          : undefined;

        const resource =
          defaultResource || this.currentEnvironmentStore.resources.find(item => item.demo && item.featured);

        if (!resource) {
          return;
        }

        const modelDescription =
          defaultModelDescription ||
          modelDescriptions.filter(item => item.resource == resource.uniqueName).find(item => item.featured);

        if (!modelDescription) {
          return;
        }

        return {
          modelDescription: modelDescription,
          templateVariant: this.selectedTemplateVariant
        };
      })
    );
  }

  updatePreview() {
    if (!this.selectedTemplate) {
      this.startPage = undefined;
      this.otherPages = undefined;
      this.previewLoading = false;
      this.cd.markForCheck();
      return;
    }

    this.getTemplateSettings()
      .pipe(
        switchMap(settings => {
          this.cd.markForCheck();

          if (!settings) {
            return of(undefined);
          }

          return this.pageTemplatesGeneratorService.createTemplatePages(this.selectedTemplate, settings);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.startPage = result ? result.startPage : undefined;
          this.otherPages = result ? result.pages.filter(item => item !== result.startPage) : undefined;
          this.previewLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.previewLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  submitTemplate() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.LayoutSelected, {
      Template: this.selectedTemplate ? this.selectedTemplate.type : undefined,
      TemplateVariant: this.selectedTemplateVariant,
      Source: this.analyticsSource
    });

    if (this.selectedTemplate.type) {
      this.setChooseModel(true);
    } else {
      this.selected.emit({
        template: this.selectedTemplate,
        templateVariant: this.selectedTemplateVariant
      });
    }
  }

  submitModel() {
    const resource = this.selectedModel
      ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.selectedModel.resource)
      : undefined;

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.CollectionSelected, {
      Template: this.selectedTemplate ? this.selectedTemplate.type : undefined,
      TemplateVariant: this.selectedTemplateVariant,
      ResourceType: resource ? resource.typeItem.name : undefined,
      Resource: resource ? resource.uniqueName : undefined,
      Collection: this.selectedModel ? this.selectedModel.model : undefined,
      Source: this.analyticsSource
    });

    this.selected.emit({
      template: this.selectedTemplate,
      templateVariant: this.selectedTemplateVariant,
      modelDescription: this.selectedModel
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isSet } from '@shared';
import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';
var WebhookAutomationTrigger = /** @class */ (function (_super) {
    __extends(WebhookAutomationTrigger, _super);
    function WebhookAutomationTrigger(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = AutomationTriggerType.Webhook;
        return _this;
    }
    WebhookAutomationTrigger.prototype.deserialize = function (data) {
        this.webhookToken = data['webhook_token'];
        return this;
    };
    WebhookAutomationTrigger.prototype.serialize = function () {
        return {
            webhook_token: this.webhookToken
        };
    };
    WebhookAutomationTrigger.prototype.getRunPath = function () {
        return isSet(this.webhookToken) ? "hooks/" + this.webhookToken : undefined;
    };
    return WebhookAutomationTrigger;
}(AutomationTrigger));
export { WebhookAutomationTrigger };
registerAutomationTriggerForType(AutomationTriggerType.Webhook, WebhookAutomationTrigger);

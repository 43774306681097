/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-header-right.component";
var styles_PageHeaderRightComponent = [];
var RenderType_PageHeaderRightComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeaderRightComponent, data: {} });
export { RenderType_PageHeaderRightComponent as RenderType_PageHeaderRightComponent };
export function View_PageHeaderRightComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageHeaderRightComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-header-right", [], null, null, null, View_PageHeaderRightComponent_0, RenderType_PageHeaderRightComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageHeaderRightComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageHeaderRightComponentNgFactory = i0.ɵccf("app-page-header-right", i1.PageHeaderRightComponent, View_PageHeaderRightComponent_Host_0, {}, {}, ["*"]);
export { PageHeaderRightComponentNgFactory as PageHeaderRightComponentNgFactory };

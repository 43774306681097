import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminMode } from '@modules/admin-mode';

export const ROUTE_ADMIN_MODE$ = new InjectionToken<Observable<AdminMode>>('ROUTE_ADMIN_MODE$');

export function routeAdminMode$Factory(activatedRoute: ActivatedRoute): Observable<AdminMode> {
  return activatedRoute.data.pipe(map(data => data['mode']));
}

export const ROUTE_ADMIN_MODE$_PROVIDER: Provider = {
  provide: ROUTE_ADMIN_MODE$,
  deps: [ActivatedRoute],
  useFactory: routeAdminMode$Factory
};

export const ROUTE_ADMIN_MODE = new InjectionToken<AdminMode>('ROUTE_ADMIN_MODE');

export function routeAdminModeFactory(activatedRoute: ActivatedRoute): AdminMode {
  return activatedRoute.snapshot.data['mode'];
}

export const ROUTE_ADMIN_MODE_PROVIDER: Provider = {
  provide: ROUTE_ADMIN_MODE,
  deps: [ActivatedRoute],
  useFactory: routeAdminModeFactory
};

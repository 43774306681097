import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';
export var ElementConfiguration;
(function (ElementConfiguration) {
    ElementConfiguration["Started"] = "started";
    ElementConfiguration["Configured"] = "configured";
})(ElementConfiguration || (ElementConfiguration = {}));
export function trackConfigured() {
    return function (source) {
        return source.pipe(map(function (item) { return !!item; }), startWith(undefined), distinctUntilChanged(), pairwise(), map(function (_a) {
            var wasConfigured = _a[0], isConfigured = _a[1];
            if ((wasConfigured === undefined || wasConfigured === true) && isConfigured === false) {
                return ElementConfiguration.Started;
            }
            else if (wasConfigured === false && isConfigured === true) {
                return ElementConfiguration.Configured;
            }
        }));
    };
}

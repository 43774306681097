import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ServerRequestError } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { CurrentProjectStore, Resource } from '@modules/projects';

import { MessageName } from '../../data/message-name';

export interface MessageResponse {
  response: HttpResponse<any>;
  blob?: Blob;
  json?: Object | Object[];
  text?: string;
}

@Injectable()
export class MessageService {
  constructor(private http: HttpClient, private apiService: ProjectApiService) {}

  messagesUrl(resource: Resource) {
    if (!resource || !resource.params['url']) {
      return;
    }
    return `${resource.params['url']}`;
  }

  // TODO: Move parseErrors to operator
  send(resource: Resource, name: MessageName, params?: Object, parseErrors = true): Observable<MessageResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.messagesUrl(resource);

        let headers = new HttpHeaders();
        const data = {
          name: name,
          params: params
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, {
          headers: headers,
          observe: 'response',
          responseType: 'blob'
        });
      }),
      switchMap(response => {
        let textObs: Observable<string>;

        if (response.body.type == 'application/json') {
          textObs = new Observable<string>(observer => {
            const reader = new FileReader();
            reader.onload = () => {
              observer.next(reader.result as string);
              observer.complete();
            };
            reader.onerror = e => {
              observer.error(e);
            };
            reader.readAsText(response.body, 'utf-8');
          });
        } else {
          textObs = of(undefined);
        }

        return textObs.pipe(
          map(text => {
            let json: string;

            if (response.body.type == 'application/json') {
              try {
                json = JSON.parse(text);
              } catch (e) {}
            }

            return {
              response: response,
              blob: response.body,
              json: json,
              text: text
            };
          })
        );
      }),
      switchMap((response: MessageResponse) => {
        if (!parseErrors) {
          return of(response);
        }

        if (response.json && response.json['errors']) {
          return throwError(response.json['errors']);
        }

        return of(response);
      }),
      catchError(error => {
        if (!parseErrors) {
          return throwError(error);
        }

        const serverError = new ServerRequestError(error);

        return throwError(serverError);
      }),
      publishLast(),
      refCount()
    );
  }
}

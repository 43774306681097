<div class="multiple-files-uploader" [class.multiple-files-uploader_compact]="compact">
  <app-upload-template
    *ngFor="let uploadedFile of compact ? uploadedFiles.slice(0, 3) : uploadedFiles; let i = index"
    class="multiple-files-uploader__item"
    [upload]="isUploadable()"
    [name]="name"
    [control]="control"
    [autofocus]="autofocus"
    [disabled]="!storage$.value"
    [background]="background"
    [fit]="fit"
    [cropFormat]="cropFormat"
    [cropFormatCustom]="cropFormatCustom"
    [preview]="uploadedFile?.url"
    [uploadProgress]="uploadedFile?.uploadProgress"
    [uploadError]="uploadedFile?.uploadError"
    [accentColor]="accentColor"
    (clearValue)="clearCurrentValue(i)"
    (fileSelected)="onFileSelected($event, i)"
  >
    <ng-container *ngIf="uploadedFile" upload-preview>
      <div class="upload__background upload__background_padding">
        <app-file-preview
          [previewFilename]="uploadedFile?.filename"
          [previewExtension]="uploadedFile?.extension"
          [previewSize]="uploadedFile?.uploadFileSize"
        ></app-file-preview>
      </div>
    </ng-container>

    <ng-container upload-actions>
      <a
        href="javascript:void(0)"
        class="upload__button icon-cloud_download"
        (click)="$event.stopPropagation(); download(i)"
        [appTip]="'Download'"
        [appTipOptions]="{ side: 'top', margin: -4 }"
      >
      </a>
    </ng-container>
  </app-upload-template>

  <div *ngIf="compact && uploadedFiles.length > 3" class="multiple-files-uploader__item">
    <div class="multiple-files-uploader__spoiler">+{{ uploadedFiles.length - 3 }}</div>
  </div>

  <app-upload-template
    class="multiple-files-uploader__item"
    [upload]="isUploadable()"
    [multiple]="true"
    [name]="name"
    [control]="control"
    [autofocus]="autofocus"
    [disabled]="!storage$.value"
    [background]="background"
    [fit]="fit"
    [cropFormat]="cropFormat"
    [cropFormatCustom]="cropFormatCustom"
    [uploadProgress]="upload?.uploadProgress"
    [uploadError]="upload?.uploadError"
    [accentColor]="accentColor"
    (filesSelected)="onFilesSelected($event)"
  >
  </app-upload-template>
</div>

<div class="view-editor__data-menu" [class.view-editor__data-menu_visible]="opened" (click)="markClickEvent($event)">
  <div class="view-editor__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <app-sidebar-section>
      <div class="sidebar__list">
        <app-sidebar-field
          [label]="'Component Actions'"
          [subtitle]="'Component actions allow you to trigger actions outside of this component'"
        >
          <app-action-outputs-edit
            [control]="form.controls.actions"
            [analyticsSource]="analyticsSource"
          ></app-action-outputs-edit>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </div>
</div>

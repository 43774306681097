import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';

import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import {
  BaseField,
  FieldDescriptionLookup,
  FieldType,
  getFieldDescriptionByType,
  InputValueType
} from '@modules/fields';
import { FilterItem2 } from '@modules/filters';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, traverseModelPath } from '@modules/models';
import { queryHasFrontendAutoParameters, queryHasResourceAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { coerceArray, controlValue, isSet } from '@shared';

export interface FieldValue {
  path: string[];
  field: BaseField;
}

export class ValueArray extends FormArray {
  controls: FormControl[];

  pushNew(): FormControl {
    const control = new FormControl(undefined);
    this.push(control);
    return control;
  }

  removeLast() {
    this.removeAt(this.controls.length - 1);
  }

  prepareControls(value: any[]) {
    const addControls = value.length > this.controls.length ? value.length - this.controls.length : 0;
    const removeControls = value.length < this.controls.length ? this.controls.length - value.length : 0;
    range(addControls).forEach(() => this.pushNew());
    range(removeControls).forEach(() => this.removeLast());
  }

  patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.prepareControls(value);
    super.patchValue(value, options);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.prepareControls(value);
    super.setValue(value, options);
  }

  get value(): any[] {
    return this.controls.map(item => item.value);
  }

  set value(value: any[]) {}
}

@Injectable()
export class FilterEditForm extends FormGroup implements OnDestroy {
  dataSource: ListModelDescriptionDataSource;
  filter: FilterItem2;
  field: string[];

  controls: {
    field: FormControl;
    lookup: FormControl;
    value: FormControl;
    value_array: ValueArray;
    exclude: FormControl;
  };

  modelDescription$ = new BehaviorSubject<ModelDescription>(undefined);

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore
  ) {
    super({
      field: new FormControl(),
      lookup: new FormControl(),
      value: new FormControl(),
      value_array: new ValueArray([]),
      exclude: new FormControl(false)
    });
  }

  ngOnDestroy(): void {}

  init(options: {
    dataSource?: ListModelDescriptionDataSource;
    filter?: FilterItem2;
    field?: string[];
  }): Observable<boolean> {
    this.dataSource = options.dataSource;
    this.filter = options.filter;
    this.field = options.field;

    return this.initFieldOptions();
  }

  getDataSourceParams(): {
    type: DataSourceType;
    query: ListModelDescriptionQuery;
    resource: Resource;
    modelId: string;
  } {
    const type = this.dataSource ? this.dataSource.type : undefined;
    const query = type == DataSourceType.Query ? (this.dataSource.query as ListModelDescriptionQuery) : undefined;
    const resource =
      type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.dataSource.queryResource)
        : undefined;
    const modelId =
      resource && query && query.queryType == QueryType.Simple && query.simpleQuery
        ? [resource.uniqueName, query.simpleQuery.model].join('.')
        : undefined;

    return {
      type: type,
      query: query,
      resource: resource,
      modelId: modelId
    };
  }

  initFieldOptions(): Observable<boolean> {
    const { modelId } = this.getDataSourceParams();

    return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
      tap(modelDescription => {
        this.modelDescription$.next(modelDescription);
      }),
      switchMap(() => {
        if (this.filter) {
          return this.getFieldValue$(this.filter.field);
        } else if (this.field) {
          return this.getFieldValue$(this.field);
        } else {
          return of(undefined);
        }
      }),
      map(field => {
        const fieldDescription = field ? getFieldDescriptionByType(field.field.field) : undefined;

        if (this.filter) {
          const lookup =
            fieldDescription && this.filter.lookup
              ? fieldDescription.lookups.find(item => item.type && item.type.lookup == this.filter.lookup.lookup)
              : undefined;

          this.controls.lookup.patchValue(lookup);
          this.controls.exclude.patchValue(this.filter.exclude);

          if (lookup && lookup.array) {
            this.controls.value_array.patchValue(coerceArray(this.filter.value));
          } else {
            this.controls.value.patchValue(this.filter.value);
          }
        } else if (this.field) {
          this.getFieldLookups$(field)
            .pipe(first(), untilDestroyed(this))
            .subscribe(lookups => {
              const firstLookup = lookups.lookups[0];
              this.controls.field.patchValue(field);
              this.controls.lookup.patchValue(firstLookup);
            });
        }

        this.controls.field.valueChanges
          .pipe(
            switchMap(value => this.getFieldLookups$(value).pipe(first())),
            untilDestroyed(this)
          )
          .subscribe(lookups => {
            const firstLookup = lookups.lookups[0];
            this.controls.lookup.patchValue(firstLookup);
            this.controls.exclude.patchValue(false);
          });

        combineLatest(this.getField$(), controlValue<FieldDescriptionLookup>(this.controls.lookup))
          .pipe(
            map(([fieldValue, lookup]) => ({
              fieldValue: fieldValue,
              lookup: lookup,
              valueField: this.getValueField(fieldValue, lookup)
            })),
            distinctUntilChanged((lhs, rhs) => {
              const lhsLookup = lhs.lookup && lhs.lookup.type ? lhs.lookup.type.lookup : undefined;
              const lhsPath = lhs.fieldValue ? lhs.fieldValue.path : undefined;
              const rhsLookup = rhs.lookup && rhs.lookup.type ? rhs.lookup.type.lookup : undefined;
              const rhsPath = rhs.fieldValue ? rhs.fieldValue.path : undefined;
              return isEqual(lhsPath, rhsPath) && lhsLookup == rhsLookup;
            }),
            startWith(undefined),
            pairwise(),
            filter(([prev, current]) => {
              if (prev) {
                const prevValueField = prev.valueField ? prev.valueField.field : undefined;
                const prevLookupArray = prev.lookup ? prev.lookup.array : false;
                const currentValueField = current.valueField ? current.valueField.field : undefined;
                const currentLookupArray = current.lookup ? current.lookup.array : false;
                return prevValueField != currentValueField || prevLookupArray != currentLookupArray;
              } else {
                if (this.filter) {
                  const currentLookupArray = current.lookup ? current.lookup.array : false;
                  return currentLookupArray && isEqual(this.filter.value, []);
                } else {
                  return true;
                }
              }
            }),
            untilDestroyed(this)
          )
          .subscribe(([prev, current]) => {
            const valueFieldDescription = getFieldDescriptionByType(
              current.valueField ? current.valueField.field : FieldType.Text
            );

            if (current.lookup && current.lookup.array) {
              this.controls.value_array.patchValue([valueFieldDescription.defaultValue]);
            } else {
              this.controls.value.patchValue(valueFieldDescription.defaultValue);
            }
          });

        return true;
      })
    );
  }

  traverseDataSourcePath$(
    path: string[]
  ): Observable<{
    path: {
      name: string;
      verboseName: string;
    }[];
    field: BaseField;
  }> {
    const { modelId } = this.getDataSourceParams();

    if (isSet(modelId)) {
      return this.modelDescriptionStore.getFirst().pipe(
        map(modelDescriptions => {
          const modelDescription = isSet(modelId) ? modelDescriptions.find(item => item.isSame(modelId)) : undefined;
          const traversePath = traverseModelPath(modelDescription, path, modelDescriptions);

          if (!traversePath || !traversePath.length) {
            return;
          }

          return {
            path: traversePath.map(item => {
              return {
                name: item.name,
                verboseName: item.verboseName
              };
            }),
            field: traversePath[traversePath.length - 1].field
          };
        })
      );
    } else if (this.dataSource) {
      if (path.length != 1) {
        return of(undefined);
      }

      const field = this.dataSource.columns.find(item => item.name == path[0]);

      return of({
        path: [{ name: field.name, verboseName: field.verboseName }],
        field: field
      });
    } else {
      return of(undefined);
    }
  }

  getFieldValue$(path: string[]): Observable<FieldValue> {
    return this.traverseDataSourcePath$(path).pipe(
      map(value => {
        if (!value) {
          return;
        }

        return {
          path: value.path.map(item => item.name),
          field: value.field
        };
      })
    );
  }

  getField$(): Observable<FieldValue> {
    if (this.filter && this.filter.field) {
      const path = this.filter.field;
      return this.getFieldValue$(path);
    } else if (this.field) {
      const path = this.field;
      return this.getFieldValue$(path);
    } else {
      return controlValue<FieldValue>(this.controls.field);
    }
  }

  getFieldLookups$(
    fieldValue: FieldValue
  ): Observable<{ lookups: FieldDescriptionLookup[]; excludeSupported?: boolean }> {
    if (!fieldValue) {
      return of({ lookups: [] });
    }

    const fieldDescription = getFieldDescriptionByType(fieldValue.field.field);
    const { type, query, resource, modelId } = this.getDataSourceParams();

    if (isSet(modelId)) {
      return this.modelDescriptionStore.getDetail(modelId).pipe(
        map(modelDescription => {
          const getQuery = modelDescription ? modelDescription.getQuery : undefined;

          if (
            getQuery &&
            (queryHasResourceAutoParameters(resource, getQuery, modelDescription) ||
              queryHasFrontendAutoParameters(resource, getQuery, modelDescription))
          ) {
            return { lookups: fieldDescription.lookups, excludeSupported: true };
          } else {
            const lookups = fieldDescription.lookups.filter(lookup => {
              return this.dataSource.queryInputs.find(
                item =>
                  item.valueType == InputValueType.Filter &&
                  item.filterField == fieldValue.field.name &&
                  item.filterLookup == lookup.type.lookup
              );
            });
            return {
              lookups: lookups
            };
          }
        })
      );
    } else if (type == DataSourceType.Input || type == DataSourceType.Workflow) {
      return of({ lookups: fieldDescription.lookups, excludeSupported: true });
    } else if (this.dataSource) {
      if (
        query &&
        (queryHasResourceAutoParameters(resource, query, undefined) ||
          queryHasFrontendAutoParameters(resource, query, undefined))
      ) {
        return of({
          lookups: fieldDescription.lookups,
          excludeSupported: true
        });
      } else {
        const lookups = fieldDescription.lookups.filter(lookup => {
          return this.dataSource.queryInputs.find(
            item =>
              item.valueType == InputValueType.Filter &&
              item.filterField == fieldValue.field.name &&
              item.filterLookup == lookup.type.lookup
          );
        });
        return of({ lookups: lookups });
      }
    } else {
      return of({ lookups: [] });
    }
  }

  getLookups$(): Observable<{
    lookups: FieldDescriptionLookup[];
    excludeSupported?: boolean;
  }> {
    return this.getField$().pipe(switchMap(fieldValue => this.getFieldLookups$(fieldValue)));
  }

  getValueField(field: FieldValue, lookup: FieldDescriptionLookup): BaseField {
    if (!field || !lookup || !lookup.field) {
      return;
    }

    if (lookup.field == FieldType.Boolean) {
      return {
        field: FieldType.Select,
        params: {
          valueEquals: (lhs, rhs) => lhs === rhs,
          options: [
            { value: false, name: 'No' },
            { value: true, name: 'Yes' }
          ],
          classes: ['select_fill']
        }
      };
    } else {
      const fieldParams = lookup.fieldParams
        ? lookup.fieldParams.reduce((acc, item) => {
            acc[item] = field.field.params[item];
            return acc;
          }, {})
        : {};
      const params = {
        ...fieldParams,
        ...lookup.extraParams
      };

      return {
        field: lookup.field,
        params: params
      };
    }
  }

  getValueField$(): Observable<BaseField> {
    return combineLatest(this.getField$(), controlValue<FieldDescriptionLookup>(this.controls.lookup)).pipe(
      map(([field, lookup]) => this.getValueField(field, lookup))
    );
  }

  submit(): Observable<FilterItem2> {
    return this.getField$().pipe(
      first(),
      map(field => {
        const fieldValue = this.controls.field.value as FieldValue;
        let filterField: string[];
        const filterLookup = this.controls.lookup.value as FieldDescriptionLookup;

        if (this.filter) {
          filterField = this.filter.field;
        } else if (fieldValue) {
          filterField = fieldValue.path;
        }

        if (!isSet(filterField)) {
          return;
        }

        const valueField = this.getValueField(field, filterLookup);
        let filterValue: any;
        const filterExclude = this.controls.exclude.value;

        if (valueField) {
          if (filterLookup && filterLookup.array) {
            filterValue = this.controls.value_array.value.filter(item => isSet(item));
          } else {
            filterValue = this.controls.value.value;
          }
        } else {
          filterValue = true;
        }

        return new FilterItem2({
          field: filterField,
          lookup: filterLookup ? filterLookup.type : undefined,
          value: filterValue,
          exclude: filterExclude
        });
      })
    );
  }
}

<div *ngIf="!(section.disabledTitle | appIsSet)" class="menu3-section">
  <div class="menu3-section__header">
    <div class="menu3-section__header-main">
      <div class="menu3-section__header-title">
        {{ section.label }}

        <span
          *ngIf="section.documentation | appIsSet"
          class="menu3-section__header-title-action icon-help"
          [appDocumentation]="section.documentation"
          [appDocumentationLeft]="false"
        ></span>
      </div>
    </div>

    <div *ngIf="section.itemsTotal || !(section.descriptionTitle | appIsSet)" class="menu3-section__header-right">
      <div
        *ngIf="section.buttons.length == 1 && section.buttons[0] as button"
        class="menu3-section__header-action menu3-section__header-action_orange icon-plus"
        (click)="button.handler()"
        [appTip]="button.label"
        [appTipOptions]="{ side: 'top' }"
      ></div>

      <ng-container *ngIf="section.buttons.length > 1">
        <app-custom-page-properties-add-menu
          [buttons]="section.buttons"
          [origin]="add_item_origin"
          (buttonClick)="$event.handler()"
          #add_item_menu="appCustomPagePropertiesAddMenu"
        ></app-custom-page-properties-add-menu>

        <div
          class="menu3-section__header-action menu3-section__header-action_orange icon-plus"
          [class.menu3-section__header-action_active]="add_item_menu.isOpened()"
          cdkOverlayOrigin
          #add_item_origin="cdkOverlayOrigin"
        ></div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!section.itemsTotal">
    <div *ngIf="!bannerHidden && (section.descriptionTitle | appIsSet)" class="menu3-section__banner">
      <div class="menu3-section-banner menu3-section-banner_orange">
        <!--        <div class="menu3-section-banner__close icon-close" (click)="setBannerHidden(true)"></div>-->
        <div
          *ngIf="section.icon"
          class="menu3-section-banner__icon"
          [ngClass]="section.icon ? 'icon-' + section.icon : 'icon-cloud'"
        ></div>
        <div class="menu3-section-banner__title">{{ section.descriptionTitle }}</div>
        <div class="menu3-section-banner__description">{{ section.descriptionText }}</div>

        <div *ngIf="section.buttons.length == 1 && section.buttons[0] as button" class="menu3-section-banner__buttons">
          <div class="menu3-section-banner__button" (click)="button.handler()">
            <div
              class="menu3-section-banner__button-icon"
              [ngClass]="button.icon ? 'icon-' + button.icon : 'icon-plus'"
            ></div>
            <div class="menu3-section-banner__button-label">{{ button.label }}</div>
          </div>
        </div>

        <div *ngIf="section.buttons.length > 1" class="menu3-section-banner__buttons">
          <app-custom-page-properties-add-menu
            [buttons]="section.buttons"
            [origin]="add_item_origin"
            (buttonClick)="$event.handler()"
            #add_item_menu="appCustomPagePropertiesAddMenu"
          ></app-custom-page-properties-add-menu>

          <div class="menu3-section-banner__button" cdkOverlayOrigin #add_item_origin="cdkOverlayOrigin">
            <div
              class="menu3-section-banner__button-icon"
              [ngClass]="section.buttonsIcon ? 'icon-' + section.buttonsIcon : 'icon-plus'"
            ></div>
            <div class="menu3-section-banner__button-label">{{ section.buttonsLabel }}</div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="bannerHidden || !(section.descriptionTitle | appIsSet)">
      <div *ngIf="section.buttons.length == 1 && section.buttons[0] as button" class="menu3-section__items">
        <div class="menu3-action-item menu3-action-item_orange menu3-section__item" (click)="button.handler()">
          <div class="menu3-action-item__left">
            <div class="menu3-action-item__icon" [ngClass]="button.icon ? 'icon-' + button.icon : 'icon-plus'"></div>
          </div>
          <div class="menu3-action-item__main">
            <div class="menu3-action-item__title">{{ button.label }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="section.buttons.length > 1" class="menu3-section__items">
        <app-custom-page-properties-add-menu
          [buttons]="section.buttons"
          [origin]="add_item_origin"
          (buttonClick)="$event.handler()"
          #add_item_menu="appCustomPagePropertiesAddMenu"
        ></app-custom-page-properties-add-menu>

        <div
          class="menu3-action-item menu3-action-item_orange menu3-section__item"
          cdkOverlayOrigin
          #add_item_origin="cdkOverlayOrigin"
        >
          <div class="menu3-action-item__left">
            <div
              class="menu3-action-item__icon"
              [ngClass]="section.buttonsIcon ? 'icon-' + section.buttonsIcon : 'icon-plus'"
            ></div>
          </div>
          <div class="menu3-action-item__main">
            <div class="menu3-action-item__title">{{ section.buttonsLabel }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="section.items.length" class="menu3-section__items">
    <div *ngFor="let item of section.items" class="menu3-section__item">
      <app-custom-page-properties-section-item
        [item]="item"
        [value]="values[item.id]"
      ></app-custom-page-properties-section-item>
    </div>
  </div>
</div>

<div *ngIf="section.disabledTitle | appIsSet" class="menu3-section">
  <div class="menu3-section__header">
    <div class="menu3-section__header-main">
      <div class="menu3-section__header-title">
        {{ section.label }}
      </div>
    </div>
  </div>

  <div>
    <div class="menu3-section-banner menu3-section-banner_orange">
      <div class="menu3-section-banner__title">{{ section.disabledTitle }}</div>
      <div class="menu3-section-banner__description">{{ section.disabledText }}</div>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { FormField } from '@modules/fields';
import { ProjectProperty } from '@modules/projects';
import { KeyboardEventKeyCode } from '@shared';

@Component({
  selector: 'app-project-property-change-item, [app-project-property-change-item]',
  templateUrl: './project-property-change-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPropertyChangeItemComponent implements OnInit, OnChanges {
  @Input() property: ProjectProperty;
  @Input() propertiesControl: AbstractControl;
  @Input() analyticsSource: string;

  editing = false;
  formField: FormField;
  control: FormControl;
  form: FormGroup;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['property']) {
      this.formField = new FormField();
      this.formField.name = this.property.name;
      this.formField.field = this.property.field.field;
      // this.formField.resetEnabled = true;

      const classes =
        this.property.field && this.property.field.params && this.property.field.params['classes']
          ? this.property.field.params['classes']
          : [];

      this.formField.params = {
        ...this.property.field.params,
        classes: ['select_fill', 'select_small', 'input_fill', 'input_small', ...classes]
      };
    }
  }

  startEditing() {
    const value = this.getPropertyValue();

    this.control = new FormControl(value);
    this.form = new FormGroup({
      [this.formField.name]: this.control
    });

    this.editing = true;
    this.cd.markForCheck();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateStarted, {
      Source: this.analyticsSource
    });
  }

  finishEditing(save = false) {
    const value = this.control.value;

    this.editing = false;
    this.control = undefined;
    this.form = undefined;
    this.cd.markForCheck();

    if (save) {
      this.setPropertyValue(value);

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Saved, {
        Source: this.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Cancelled, {
        Source: this.analyticsSource
      });
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishEditing(true);
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.finishEditing(false);
    }
  }

  getPropertyValue(): any {
    let value = this.propertiesControl.value[this.property.uid];

    if (this.property.fieldDescription && this.property.fieldDescription.deserializeValue) {
      value = this.property.fieldDescription.deserializeValue(value, this.property.field);
    }

    return value;
  }

  setPropertyValue(value: any) {
    if (this.property.fieldDescription && this.property.fieldDescription.serializeValue) {
      value = this.property.fieldDescription.serializeValue(value, this.property.field);
    }

    this.propertiesControl.patchValue({
      ...this.propertiesControl.value,
      [this.property.uid]: value
    });
  }

  clearPropertyValue() {
    this.setPropertyValue(undefined);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Deleted, {
      Source: this.analyticsSource
    });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';

import { PopupRef } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

@Component({
  selector: 'app-model-api-popup',
  templateUrl: './model-api-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiPopupComponent implements OnInit {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;

  constructor(@Optional() private popupRef: PopupRef) {}

  ngOnInit() {}

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }
}

import pickBy from 'lodash/pickBy';

import { getWidgetByType, Widget, WidgetType } from '@modules/dashboard';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export interface ResizableElementItem extends ElementItem {
  height?: number;
  width?: number;
}

export class WidgetElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Widget;
  public widget: Widget;
  public height: number;

  deserialize(data: Object): WidgetElementItem {
    super.deserialize(data);
    this.height = this.params['height'];

    if (this.params['widget']) {
      const element = getWidgetByType(this.params['widget']['widget_type']);
      this.widget = new element().deserialize(this.params['widget']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['height'] = this.height;
    this.params['widget'] = this.widget ? this.widget.serialize() : undefined;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return `visualize data on the ${this.widget.typeStr}`;
  }

  get analyticsName(): string {
    if (!this.widget) {
      return;
    }
    return this.widget.analyticsName;
  }

  defaultName() {
    if (this.widget.type == WidgetType.Chart) {
      return 'Chart';
    } else if (this.widget.type == WidgetType.Value) {
      return 'Single Value';
    } else {
      return 'Widget';
    }
  }
}

registerElementForType(ElementType.Widget, WidgetElementItem);

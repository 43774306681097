import { Injectable } from '@angular/core';

import { DraggableDirective } from '../../directives/draggable/draggable.directive';

@Injectable({
  providedIn: 'root'
})
export class DraggableService {
  items: DraggableDirective[] = [];

  add(item: DraggableDirective) {
    this.items.push(item);
  }

  remove(item: DraggableDirective) {
    this.items = this.items.filter(i => i !== item);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  SkipSelf,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AppDrag, AppDropList, DropListService } from '@common/drag-drop2';
import { DynamicComponent, DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ResizeFinishedEvent, ResizeType } from '@common/resizable';
import { SessionStorage } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components';
import {
  AlignHorizontal,
  AlignVertical,
  CustomizeService,
  ElementComponent,
  ElementItem,
  ElementPadding,
  ElementPaddingType,
  ElementType,
  elementTypePadding,
  elementTypeResize,
  elementTypeResizeMax,
  elementTypeResizeMin,
  elementTypeResizeStep,
  getElementComponentByType,
  ignoreElementCustomize,
  isElementCustomizeIgnored,
  isElementTypeAddable,
  isElementTypeAlignHorizontal,
  isElementTypeAlignVertical,
  isElementTypeContainer,
  isElementTypeCustomizable,
  isElementTypeCustomizableAlways,
  isElementTypeCustomizeOnAdd,
  isElementTypeDeletable,
  isElementTypeGroupable,
  isElementTypeWrappable,
  markElementClickEvent,
  ResizableElementItem,
  ViewContext,
  ViewSettings
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementToolbarComponent } from '@modules/customize-ui';
import { applyBooleanInput } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { RoutingService } from '@modules/routing';
import { defaultComponentTemplateName, Template, TemplateService, TemplateType } from '@modules/template';
import {
  addClass,
  forceObservable,
  isControlElement,
  isSet,
  KeyboardEventKeyCode,
  removeClass,
  TypedChanges
} from '@shared';

// TODO: Refactor import
import { CustomPagePopupComponent } from '../../../customize-components/components/custom-page-popup/custom-page-popup.component';

import { ElementComponentsService } from '../../services/element-components/element-components.service';
import { ElementContainerService } from '../../services/element-container/element-container.service';
import { BUILDER_ELEMENT_BUFFER } from '../../services/element-container/element-container.service';
import { BUILDER_ADD_ELEMENT } from '../../services/element-container/element-container.service';
import { BaseElementComponent } from '../base-element/base-element.component';

export const DRAG_DROP_SIBLING_ACTIVE_CLASS = 'app-drag-drop-sibling-active';

@Component({
  selector: 'app-auto-element',
  templateUrl: './auto-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoElementComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: ElementItem;
  @Input() context: ViewContext;
  @Input() customizing = false;
  @Input() accentColor: string;
  @Input() wrapperEnabled = true;
  @Input() paddingEnabled = true;
  @Output() updated = new EventEmitter<ElementItem>();
  @Output() duplicatedRequested = new EventEmitter<void>();
  @Output() replaceRequested = new EventEmitter<ElementItem[]>();
  @Output() deleteRequested = new EventEmitter<void>();
  @Output() moveToRequested = new EventEmitter<any[]>();
  @Output() visibleUpdated = new EventEmitter<void>();

  @ViewChild('element_wrapper', { read: ElementRef }) elementWrapperElement: ElementRef;
  @ViewChild(DynamicComponent) dynamicComponent: DynamicComponent;
  @ViewChild(AutoElementToolbarComponent) autoElementToolbarComponent: AutoElementToolbarComponent;

  @HostBinding('class.app-auto-element_inline') get inlineClass() {
    return this.alignHorizontalSupported ? this.element.isInline() : false;
  }

  @HostBinding('attr.data-component-id') get elementId() {
    return this.element.uid;
  }

  @HostBinding('attr.data-component-name') get elementName() {
    return this.element.name;
  }

  componentData: DynamicComponentArguments;
  customizeComponentData = new BehaviorSubject<DynamicComponentArguments>(undefined);
  elementComponent: ElementComponent;
  hover = false;
  hoverAnother = false;
  draggingOver = false;
  actionClicked = new Subject<string>();
  isContainer = false;
  containerHorizontal = false;
  customizingElement = false;
  customizingElementFirstInit = false;
  resizingElement = false;
  customizeElementEnabled = false;
  addElementEnabled = false;
  deleteElementEnabled = false;
  customizeElementAlways = false;
  interactionsDisabled = false;
  resizeSupported: { width: boolean; height: boolean } = { width: false, height: false };
  resizeEnabled: { width: boolean; height: boolean } = { width: false, height: false };
  resizeStep: { width?: number; height?: number } = {};
  resizeMinSize: { width?: number; height?: number } = {};
  resizeMaxSize: { width?: number; height?: number } = {};
  alignHorizontalSupported = false;
  alignVerticalSupported = false;
  capabilitiesSubscriptions: Subscription[] = [];
  elementSelfCustomized = new Subject<ElementItem>();
  configured = true;
  visible = false;
  visibleSubscription: Subscription;
  wrapper = false;
  padding: ElementPadding = {};
  paddingTypes = ElementPaddingType;
  width: number;
  height: number;
  toolbarBottom = false;
  siblingLeftEntered$ = new BehaviorSubject<boolean>(false);
  siblingRightEntered$ = new BehaviorSubject<boolean>(false);
  adminModes = AdminMode;
  alignsHorizontal = AlignHorizontal;

  elementCanEnterSibling = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      return drag.data instanceof ElementItem || (isPlainObject(drag.data) && !(drag.data as CustomizeBarItem).popup);
    };
  })();

  containerCanMove = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      return true;
    };
  })();

  constructor(
    private injector: Injector,
    @Inject(ROUTE_ADMIN_MODE) public mode: AdminMode,
    public customizeService: CustomizeService,
    @Optional() private customizeBarContext: CustomizeBarContext,
    private customizeBarService: CustomizeBarService,
    private popupService: PopupService,
    private popoverService: PopoverService,
    private resolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private sessionStorage: SessionStorage,
    private routing: RoutingService,
    private templateService: TemplateService,
    private modelDescriptionStore: ModelDescriptionStore,
    private notificationService: NotificationService,
    private dropListService: DropListService,
    private dialogService: DialogService,
    private el: ElementRef,
    private renderer: Renderer2,
    private elementComponentsService: ElementComponentsService,
    private elementContainerService: ElementContainerService,
    private elementConfigurationService: ElementConfigurationService,
    private zone: NgZone,
    private analyticsService: UniversalAnalyticsService,
    @SkipSelf() @Optional() public parentElement: AutoElementComponent,
    @SkipSelf() @Optional() public parentPopup: CustomPagePopupComponent,
    @Optional() public dropList: AppDropList
  ) {}

  ngOnInit(): void {
    this.elementComponentsService.register(this.element, this);

    this.initElement();
    this.initElementSize();
    this.updateCapabilities();

    if (this.mode == AdminMode.Builder) {
      combineLatest(this.customizeService.lastHovered$, this.dropListService.dragging$)
        .pipe(untilDestroyed(this))
        .subscribe(([lastHovered, dragging]) => {
          this.hover = lastHovered === this && !dragging;
          this.hoverAnother = !!lastHovered && lastHovered !== this;
          this.draggingOver = lastHovered === this && dragging;
          this.cd.markForCheck();

          if (!dragging) {
            this.siblingLeftEntered$.next(false);
            this.siblingRightEntered$.next(false);
          }
        });

      if (this.customizeBarContext) {
        combineLatest(this.customizeComponentData, this.customizeBarContext.settingsComponents$)
          .pipe(untilDestroyed(this))
          .subscribe(([customizeComponentData, components]) => {
            const customizingElement = components[0] && components[0] === customizeComponentData;
            if (this.customizingElement == customizingElement) {
              return;
            }

            this.customizingElement = customizingElement;

            if (!this.customizingElement) {
              this.customizingElementFirstInit = false;
            }

            this.cd.markForCheck();
            this.updateElement();
          });
      }

      combineLatest(this.siblingLeftEntered$, this.siblingRightEntered$)
        .pipe(untilDestroyed(this))
        .subscribe(([siblingLeftEntered, siblingRightEntered]) => {
          const dropList = this.dropListService.draggingDropList$.value;

          if (dropList) {
            dropList.mergeDraggingStateData({
              siblingLeftEntered: siblingLeftEntered,
              siblingRightEntered: siblingRightEntered,
              siblingSelf: false,
              siblingAnchor: this.element,
              siblingAnchorContainer: this.dropList
            });
          }

          if (siblingLeftEntered || siblingRightEntered) {
            addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
          } else {
            removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
          }
        });

      fromEvent<KeyboardEvent>(document, 'keydown')
        .pipe(
          filter(() => {
            return (
              this.customizing &&
              this.customizingElement &&
              !this.popupService.items.length &&
              !isControlElement(document.activeElement)
            );
          }),
          untilDestroyed(this)
        )
        .subscribe(e => {
          if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.closeCustomize();
          } else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
            this.deleteElement();
          } else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.X) {
            this.copyElement(true);
          } else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.C) {
            this.copyElement();
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.elementComponentsService.unregister(this.element);

    this.customizeService.removeHover(this);
    this.closeCustomize();

    if (this.siblingLeftEntered$.value || this.siblingRightEntered$.value) {
      removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
    }
  }

  ngOnChanges(changes: TypedChanges<AutoElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.elementComponentsService.unregister(changes.element.previousValue);
      this.elementComponentsService.register(this.element, this);
    }

    if (changes.element) {
      const prevType = changes.element.previousValue ? changes.element.previousValue.type : undefined;
      const currentType = changes.element.currentValue ? changes.element.currentValue.type : undefined;

      this.initElementSize();
      this.updateConfigured();
      this.updateVisible();
      this.updateGroupable();
      this.updateHidden();

      if (prevType != currentType) {
        this.initElement();
      } else {
        this.updateElement({ updateCustomizeComponent: true });
      }
    } else if (changes.accentColor) {
      this.updateElement();
    }

    if (changes.element || changes.customizing) {
      this.updateCapabilities();
    }
  }

  ngAfterViewInit(): void {
    if (this.mode == AdminMode.Builder) {
      fromEvent(this.elementWrapperElement.nativeElement, 'mouseenter')
        .pipe(
          filter(() => this.customizing),
          untilDestroyed(this)
        )
        .subscribe(() => this.onHover(true));

      fromEvent(this.elementWrapperElement.nativeElement, 'mouseleave')
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onHover(false));
    }
  }

  get displayName() {
    if (!this.element) {
      return;
    }

    return this.element.name || this.elementComponent.label;
  }

  updateToolbarBottom() {
    const toolbarBottom = this.el.nativeElement.getBoundingClientRect().top < 110;

    if (this.toolbarBottom == toolbarBottom) {
      return;
    }

    this.toolbarBottom = toolbarBottom;
    this.cd.markForCheck();
  }

  initElement() {
    const elementComponent = this.element ? getElementComponentByType(this.element.type) : undefined;

    if (!elementComponent) {
      this.componentData = undefined;
      this.cd.markForCheck();
      console.error(`No such component type registered: ${this.element.type}`);
      return;
    }

    this.componentData = {
      component: elementComponent.component,
      inputs: {
        element: this.element,
        elementActive: this.customizingElement,
        context: this.context,
        visible: this.visible,
        accentColor: this.accentColor,
        // TODO: Refactor
        actionClicked: this.actionClicked.asObservable()
      },
      outputs: {
        updated: [
          element => {
            this.onCustomized(element);
            this.elementSelfCustomized.next(element);
          }
        ],
        replaceRequested: [elements => this.replaceRequested.next(elements)],
        deleteRequested: [() => this.deleteRequested.next()]
      },
      resolver: this.resolver
    };
    this.elementComponent = elementComponent;
    this.wrapper = this.wrapperEnabled && isElementTypeWrappable(this.element.type);
    this.padding = elementTypePadding(this.element.type);
    this.cd.markForCheck();

    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const createdElement = this.customizeService.applyCreatedElementComponent(this.element);

        if (createdElement) {
          this.onAdd(createdElement.barItem);
        }
      });
  }

  updateElement(options: { forceUpdate?: boolean; updateCustomizeComponent?: boolean } = {}) {
    if (
      !this.dynamicComponent ||
      !this.dynamicComponent.currentComponent ||
      !this.dynamicComponent.currentComponent.instance
    ) {
      return;
    }

    const ref = this.dynamicComponent.currentComponent as ComponentRef<BaseElementComponent>;
    const changes: SimpleChanges = {};
    const inputs = {
      element: this.element,
      elementActive: this.customizingElement,
      context: this.context,
      visible: this.visible,
      accentColor: this.accentColor
    };

    const addChange = (name, value) => {
      changes[name] = new SimpleChange(ref.instance[name], value, false);
      ref.instance[name] = value;
    };

    toPairs(inputs).forEach(([name, value]) => {
      if (name == 'element' && options.forceUpdate) {
        addChange(name, value);
      } else if (name == 'element' && !options.forceUpdate) {
        const currentValue = value as ElementItem;
        const currentValueSerialized = currentValue ? currentValue.serialize() : undefined;
        const previousValue = ref.instance.element as ElementItem;
        const previousValueSerialized = previousValue ? previousValue.serialize() : undefined;

        if (JSON.stringify(previousValueSerialized) != JSON.stringify(currentValueSerialized)) {
          addChange(name, value);
        }
      } else {
        if (ref.instance[name] !== value) {
          addChange(name, value);
        }
      }

      this.componentData.inputs[name] = value;
    });

    if (values(changes).length) {
      ref.changeDetectorRef.markForCheck();

      if (ref.instance['ngOnChanges']) {
        ref.instance['ngOnChanges'](changes);
      }

      if (options.updateCustomizeComponent && this.customizingElement) {
        this.closeCustomize();
      }
    }
  }

  updateConfigured() {
    this.elementConfigurationService
      .isConfigured(this.element)
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        this.configured = configured;
        this.cd.markForCheck();
      });
  }

  updateVisible() {
    if (this.visibleSubscription) {
      this.visibleSubscription.unsubscribe();
    }

    if (!this.element.visibleInput) {
      this.setVisible(true);
      return;
    }

    const visible = applyBooleanInput(this.element.visibleInput, this.context);
    this.setVisible(visible);

    this.visibleSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        const value = applyBooleanInput(this.element.visibleInput, this.context);
        this.setVisible(value, true);
      });
  }

  updateGroupable() {
    if (isElementTypeGroupable(this.element.type)) {
      this.renderer.setAttribute(this.el.nativeElement, 'element-groupable', 'true');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'element-groupable');
    }
  }

  updateHidden() {
    if (!this.visibleDisplay) {
      this.renderer.setAttribute(this.el.nativeElement, 'element-hidden', 'true');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'element-hidden');
    }
  }

  setVisible(visible: boolean, updateElement = false) {
    if (this.visible == visible) {
      return;
    }

    this.visible = visible;
    this.cd.markForCheck();

    this.updateHidden();

    if (updateElement) {
      this.updateElement();
    }

    this.visibleUpdated.emit();
  }

  updateCapabilities() {
    this.capabilitiesSubscriptions.forEach(item => item.unsubscribe());
    this.capabilitiesSubscriptions = [];

    this.isContainer = isElementTypeContainer(this.element.type);
    this.containerHorizontal = this.parentElement && this.parentElement.element.type == ElementType.Stack;
    this.customizeElementEnabled = this.customizing && isElementTypeCustomizable(this.element.type);
    this.addElementEnabled = isElementTypeAddable(this.element.type);
    this.deleteElementEnabled = this.customizing && isElementTypeDeletable(this.element.type);
    this.customizeElementAlways = this.customizing && isElementTypeCustomizableAlways(this.element.type);
    this.resizeStep = elementTypeResizeStep(this.element.type);
    this.alignHorizontalSupported = !this.containerHorizontal && isElementTypeAlignHorizontal(this.element.type);
    this.alignVerticalSupported = this.containerHorizontal && isElementTypeAlignVertical(this.element.type);
    this.cd.markForCheck();

    this.capabilitiesSubscriptions.push(
      combineLatest(
        forceObservable(elementTypeResize(this.element.type, { injector: this.injector, element: this.element })),
        forceObservable(elementTypeResizeMin(this.element.type, { injector: this.injector, element: this.element })),
        forceObservable(elementTypeResizeMax(this.element.type, { injector: this.injector, element: this.element }))
      )
        .pipe(untilDestroyed(this))
        .subscribe(([resizeSupported, resizeMinSize, resizeMaxSize]) => {
          const margin = this.getMarginSize();

          this.resizeSupported = resizeSupported;
          this.resizeEnabled = this.customizing
            ? this.resizeSupported
            : {
                width: false,
                height: false
              };
          this.resizeMinSize = {
            width: isSet(resizeMinSize.width) ? resizeMinSize.width + margin.horizontal : undefined,
            height: isSet(resizeMinSize.height) ? resizeMinSize.height + margin.vertical : undefined
          };
          this.resizeMaxSize = {
            width: isSet(resizeMaxSize.width) ? resizeMaxSize.width + margin.horizontal : undefined,
            height: isSet(resizeMaxSize.height) ? resizeMaxSize.height + margin.vertical : undefined
          };
          this.initElementSize();
          this.cd.markForCheck();
        })
    );
  }

  getMarginSize(): { horizontal: number; vertical: number } {
    let horizontal = 0;
    let vertical = 0;

    if (isSet(this.element.margin.left)) {
      horizontal += this.element.margin.left;
    } else if (
      this.padding.horizontal == ElementPaddingType.Normal ||
      this.padding.horizontal == ElementPaddingType.DoubleAfter
    ) {
      horizontal += 20;
    } else if (this.padding.horizontal == ElementPaddingType.DoubleBefore) {
      horizontal += 40;
    }

    if (isSet(this.element.margin.right)) {
      horizontal += this.element.margin.right;
    } else if (
      this.padding.horizontal == ElementPaddingType.Normal ||
      this.padding.horizontal == ElementPaddingType.DoubleBefore
    ) {
      horizontal += 20;
    } else if (this.padding.horizontal == ElementPaddingType.DoubleAfter) {
      horizontal += 40;
    }

    if (isSet(this.element.margin.top)) {
      vertical += this.element.margin.top;
    } else if (
      this.padding.vertical == ElementPaddingType.Normal ||
      this.padding.vertical == ElementPaddingType.DoubleAfter
    ) {
      vertical += 15;
    } else if (this.padding.vertical == ElementPaddingType.DoubleBefore) {
      vertical += 30;
    }

    if (isSet(this.element.margin.bottom)) {
      vertical += this.element.margin.bottom;
    } else if (
      this.padding.vertical == ElementPaddingType.Normal ||
      this.padding.vertical == ElementPaddingType.DoubleBefore
    ) {
      vertical += 15;
    } else if (this.padding.vertical == ElementPaddingType.DoubleAfter) {
      vertical += 30;
    }

    return {
      horizontal: horizontal,
      vertical: vertical
    };
  }

  get visibleDisplay(): boolean {
    return this.visible || !!this.customizeService.enabled;
  }

  onHover(hover: boolean) {
    if (hover) {
      this.customizeService.addHover(this);
      this.updateToolbarBottom();
    } else {
      this.customizeService.removeHover(this);
    }
  }

  getForm(): AutoElementComponent {
    if (!this.parentElement) {
      return;
    } else if (this.parentElement.element.type == ElementType.Form) {
      return this.parentElement;
    } else {
      return this.parentElement.getForm();
    }
  }

  customize(
    options: {
      event?: MouseEvent;
      append?: boolean;
      backLabel?: string;
      firstInit?: boolean;
      highlight?: boolean;
      forceHover?: boolean;
      setupOnCreate?: boolean;
    } = {}
  ) {
    options = defaults(options, { firstInit: false });

    if (!this.customizing) {
      return;
    }

    if (!this.customizeBarContext) {
      return;
    }

    if (options.event) {
      if (isElementCustomizeIgnored(options.event)) {
        return;
      } else {
        ignoreElementCustomize(options.event);
      }
    }

    if (this.customizingElement) {
      if (options.highlight) {
        this.customizeBarContext.highlight();
      }

      return;
    }

    if (!this.customizeElementEnabled) {
      return;
    }

    if (options.firstInit && !isElementTypeCustomizeOnAdd(this.element.type)) {
      return;
    }

    const component =
      this.dynamicComponent && this.dynamicComponent.currentComponent && this.dynamicComponent.currentComponent.instance
        ? this.dynamicComponent.currentComponent.instance
        : undefined;

    this.customizingElementFirstInit = options.firstInit;
    this.cd.markForCheck();

    this.customizeBarService
      .customize(this.customizeBarContext, this.element, this.context, component, this.elementSelfCustomized, {
        append: options.append,
        backLabel: options.backLabel,
        firstInit: options.firstInit,
        setupOnCreate: options.setupOnCreate,
        parentElement: this.parentElement,
        parentPopup: this.parentPopup,
        parentForm: this.getForm()
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.event && result.event === CustomizeBarEditEventType.Deleted) {
          this.deleteElement();
        } else {
          this.setElementSize(result.el as ResizableElementItem);
          this.onCustomized(result.el);
        }
      });

    if (options.forceHover) {
      this.customizeService.forceHover(this);
    }

    this.customizeComponentData.next(this.customizeBarContext.settingsComponent);
    this.updateToolbarBottom();
  }

  closeCustomize() {
    if (this.customizeComponentData.value) {
      this.customizeBarContext.closeSettingsComponent(this.customizeComponentData.value);
      this.customizeComponentData.next(undefined);
    }
  }

  duplicateElement() {
    this.duplicatedRequested.emit();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Duplicated, {
      ComponentTypeID: this.element.analyticsName
    });
  }

  moveElementToPage(page: ViewSettings) {
    if (!page || !page.link) {
      return;
    }

    this.sessionStorage.set(
      BUILDER_ADD_ELEMENT,
      JSON.stringify({
        element: this.element.serialize()
      })
    );

    this.moveToRequested.emit(page.link);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.MovedToPage, {
      ComponentTypeID: this.element.analyticsName
    });
  }

  copyElement(cut = false) {
    this.sessionStorage.set(
      BUILDER_ELEMENT_BUFFER,
      JSON.stringify({
        element: this.element.serialize(),
        persistent: !cut
      })
    );

    if (cut) {
      this.deleteElementProcess();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Cut, {
        ComponentTypeID: this.element.analyticsName
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Copy, {
        ComponentTypeID: this.element.analyticsName
      });
    }
  }

  deleteElement() {
    const components = this.element.childrenCount();

    if (!components) {
      this.deleteElementProcess();
      return;
    }

    this.dialogService
      .warning({
        title: `Delete container components (${components})`,
        description: `
          This container contains other components.
          Are you sure want to delete this container with the contained components?
        `,
        style: 'orange'
      })
      .pipe(
        filter(result => result),
        untilDestroyed(this)
      )
      .subscribe(() => this.deleteElementProcess());
  }

  deleteElementProcess() {
    this.closeCustomize();
    this.deleteRequested.emit();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Deleted, {
      ComponentTypeID: this.element.analyticsName
    });
  }

  onAdd(item?: CustomizeBarItem) {
    this.elementContainerService
      .setUpElementComponent(this.element, item)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (result.customized) {
            this.onCustomized(result.element);
          }

          this.onCreated(item);
        },
        () => {
          this.onSetupCancel();
        }
      );

    if (item) {
      this.elementContainerService.sendAddElementItemAnalytics(this.element, item);
    }
  }

  onCreated(item?: CustomizeBarItem) {
    this.customize({ firstInit: true, setupOnCreate: item ? item.setupOnCreate : false });
    this.customizeService.markChanged();
  }

  onSetupCancel() {
    this.deleteElement();
  }

  onCustomized(element: ElementItem) {
    this.element.copy(element);
    this.updated.next(element);
    this.customizeService.markChanged();
    this.updateConfigured();
    this.updateVisible();
    this.updateCapabilities();
    this.updateGroupable();
    this.updateHidden();
    this.updateElement({ forceUpdate: true });
  }

  createTemplate() {
    this.sessionStorage.set('template_widget', JSON.stringify(this.element.serialize()));
    this.routing.navigateApp(['create_template']);
  }

  setDefaultElement() {
    this.dialogService
      .confirm({
        title: 'Default Component',
        description: 'Do you want to set this component as default for this component type?'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result) {
          return;
        }

        const instance = new Template();

        instance.type = TemplateType.DefaultComponent;

        instance.uniqueName = defaultComponentTemplateName(this.element);
        instance.name = this.element.type;

        instance.element = cloneDeep(this.element);
        instance.element.uid = undefined;
        instance.active = true;

        this.templateService
          .create(instance)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.notificationService.success('Updated', 'Default component settings updated');
          });
      });
  }

  initElementSize() {
    if (this.resizeSupported.width) {
      this.width = (this.element as ResizableElementItem).width;
    }

    if (this.resizeSupported.height) {
      this.height = (this.element as ResizableElementItem).height;
    }
  }

  setElementSize(element: ResizableElementItem) {
    if (this.resizeEnabled.width) {
      element.width = this.width;

      if (
        isSet(element.width) &&
        this.alignHorizontalSupported &&
        element.alignHorizontalOrDefault == AlignHorizontal.Justify
      ) {
        element.alignHorizontal = AlignHorizontal.Left;
      }
    }

    if (this.resizeEnabled.height) {
      element.height = this.height;

      if (
        isSet(element.height) &&
        this.alignVerticalSupported &&
        (!element.alignVertical || element.alignVertical == AlignVertical.Justify)
      ) {
        element.alignVertical = AlignVertical.Top;
      }
    }
  }

  markElementEvent(e: MouseEvent) {
    markElementClickEvent(e);
  }

  onResizeStarted() {
    this.interactionsDisabled = true;
    this.resizingElement = true;
    this.cd.markForCheck();
  }

  onResize(event: ResizeFinishedEvent) {
    if (event.types.includes(ResizeType.Horizontal) && event.widthChanged) {
      this.width = event.width;
    }

    if (event.types.includes(ResizeType.Vertical) && event.heightChanged) {
      this.height = event.height;
    }

    this.setElementSize(this.element as ResizableElementItem);
    this.interactionsDisabled = false;
    this.resizingElement = false;
    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  alignHorizontal(alignment: AlignHorizontal) {
    this.element.alignHorizontal = alignment;

    if ((!alignment || alignment == AlignHorizontal.Justify) && this.resizeEnabled.width) {
      this.width = undefined;
      (this.element as ResizableElementItem).width = undefined;
    }

    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  alignVertical(alignment: AlignVertical) {
    this.element.alignVertical = alignment;

    if ((!alignment || alignment == AlignVertical.Justify) && this.resizeEnabled.height) {
      this.height = undefined;
      (this.element as ResizableElementItem).height = undefined;
    }

    this.cd.markForCheck();
    this.customizeService.markChanged();
  }

  isCreateColumnsAllowed() {
    const isRestricted = [
      ElementType.Columns,
      ElementType.Stack,
      ElementType.Tabs,
      ElementType.Collapse,
      ElementType.Accordion,
      ElementType.Form,
      ElementType.Card
    ].includes(this.element.type);
    const isStackParent = this.parentElement && this.parentElement.element.type == ElementType.Stack;
    const widthBigEnough = this.el.nativeElement.offsetWidth >= 200;
    return !isRestricted && !isStackParent && widthBigEnough;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { SegueType } from '@modules/actions';
import { BackElementItem, CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { EMPTY } from '@shared';

@Component({
  selector: 'app-back-element',
  templateUrl: './back-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: BackElementItem;

  title: string;
  previousPageTitle: string;
  backEnabled = true;
  contextSubscription: Subscription;

  constructor(
    public customizeService: CustomizeService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['element'] || changes['context']) {
      this.init();
    }
  }

  init() {
    this.initContextObserver();

    if (this.element.previousPageAction) {
      if (
        this.element.previousPageAction.actionDescription &&
        this.element.previousPageAction.actionDescription.linkAction &&
        this.element.previousPageAction.actionDescription.linkAction.type == SegueType.PreviousPage
      ) {
        this.backEnabled = history.length > 2;
      } else {
        this.backEnabled = true;
      }
    } else {
      this.backEnabled = false;
    }

    this.cd.markForCheck();
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    this.updateInputs();

    this.contextSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.updateInputs());
  }

  updateInputs() {
    let title: string;
    let previousPageTitle: string;

    if (this.element.titleInput) {
      try {
        const value = applyParamInput(this.element.titleInput, { context: this.context });

        if (value !== EMPTY) {
          title = value;
        }
      } catch (e) {}
    }

    if (this.element.previousPageTitleInput) {
      try {
        const value = applyParamInput(this.element.previousPageTitleInput, { context: this.context });

        if (value !== EMPTY) {
          previousPageTitle = value;
        }
      } catch (e) {}
    }

    this.title = title || 'No title';
    this.previousPageTitle = previousPageTitle || 'Back';
    this.cd.markForCheck();
  }

  back(element: HTMLElement) {
    this.actionControllerService
      .execute(this.element.previousPageAction, { context: this.context, injector: this.injector, origin: element })
      .subscribe();
  }
}

registerElementComponent({
  type: ElementType.Back,
  component: BackElementComponent,
  label: 'Back',
  actions: []
});

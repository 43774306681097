var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { of, throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CurrentProjectStore, ProjectService } from '@modules/projects';
import { isColorHex } from '@shared';
var ProjectUpdateForm = /** @class */ (function (_super) {
    __extends(ProjectUpdateForm, _super);
    function ProjectUpdateForm(projectService, currentProjectStore, formUtils) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            logo_color: new FormControl(''),
            logo_color_custom_enabled: new FormControl(false),
            logo_color_custom: new FormControl('#2B50ED'),
            logo: new FormControl(null),
            logo_file: new FormControl(null),
            logo_fill: new FormControl(false)
        }) || this;
        _this.projectService = projectService;
        _this.currentProjectStore = currentProjectStore;
        _this.formUtils = formUtils;
        return _this;
    }
    ProjectUpdateForm.prototype.init = function (project) {
        this.project = project;
        this.controls.name.patchValue(project.name);
        if (isColorHex(project.logoColor)) {
            this.controls.logo_color.patchValue('');
            this.controls.logo_color_custom_enabled.patchValue(true);
            this.controls.logo_color_custom.patchValue(project.logoColor);
        }
        else {
            this.controls.logo_color.patchValue(project.logoColor);
            this.controls.logo_color_custom_enabled.patchValue(false);
            this.controls.logo_color_custom.patchValue('#2B50ED');
        }
        this.controls.logo.patchValue(project.logo);
        this.controls.logo_file.patchValue(null);
        this.controls.logo_fill.patchValue(project.logoFill);
        this.markAsPristine();
    };
    ProjectUpdateForm.prototype.getLogoColor = function () {
        if (this.controls.logo_color_custom_enabled.value) {
            return this.controls.logo_color_custom.value;
        }
        else {
            return this.controls.logo_color.value;
        }
    };
    ProjectUpdateForm.prototype.getInstance = function () {
        var project = cloneDeep(this.project);
        project.name = this.controls.name.value;
        project.logoColor = this.getLogoColor();
        if (this.controls.logo_file.value) {
            project.logoFile = this.controls.logo_file.value;
        }
        else {
            project.logo = this.controls.logo.value;
        }
        project.logoFill =
            this.controls.logo.value || this.controls.logo_file.value ? this.controls.logo_fill.value : false;
        return project;
    };
    ProjectUpdateForm.prototype.submit = function () {
        var _this = this;
        var project = this.getInstance();
        if (!project) {
            return of(undefined);
        }
        var obs = this.projectService.update(project.uniqueName, project, [
            'name',
            'color',
            // 'url',
            // 'default_group',
            'logo',
            'default_theme',
            'params'
        ]);
        return obs.pipe(tap(function (result) { return (_this.project = result); }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectUpdateForm;
}(FormGroup));
export { ProjectUpdateForm };

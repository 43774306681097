<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header [title]="title | appCapitalize" [subtitle]="'Data'"> </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-section>
      <app-model-description-data-source-edit
        [control]="form.controls.data_source"
        [label]="'choose collection'"
        [typeOrange]="true"
        [queryOptionEmptyLabel]="'No collections'"
        [search]="form.controls.search_enabled | appControlValue | async"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [resourceClasses]="'component-data-resource'"
        [queryOptionClasses]="'component-data-collection'"
        [analyticsSource]="object"
      ></app-model-description-data-source-edit>

      <ng-container *ngIf="dataConfigured$ | async">
        <div class="sidebar__element">
          <app-sidebar-field [label]="'date field'" [required]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'calendar_date_field',
                  field: 'SelectField',
                  params: { options: dateColumnOptions$ | async, classes: ['select_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <ng-container *ngIf="sortableColumnOptions$ | async as sortableColumnOptions">
          <div *ngIf="sortableColumnOptions.length" class="sidebar__element">
            <app-sidebar-field [label]="'Default sorting'" [classes]="'component-data-sorting'">
              <app-select-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'sorting_field',
                    field: 'SelectField',
                    params: {
                      options: sortableColumnOptions,
                      allow_empty: true,
                      empty_name: 'Not specified',
                      classes: ['select_fill']
                    }
                  })
                "
                #sorting_field
              >
              </app-select-field>
              <app-field-errors [form]="form" [fieldName]="'sorting_field'"></app-field-errors>
              <app-field-errors [form]="form" [fieldName]="'sorting_asc'"></app-field-errors>

              <ng-container right>
                <a
                  href="javascript:void(0)"
                  class="button button_bigger button_icon button_orange-alternative button_primary sidebar-field__button"
                  [class.button_disabled]="!form.controls.sorting_field.value"
                  [appTip]="'Change sorting order'"
                  (click)="form.toggleDefaultSorting()"
                >
                  <span
                    class="button__icon"
                    [class.icon-filter_down]="form.controls.sorting_asc.value"
                    [class.icon-filter_up]="!form.controls.sorting_asc.value"
                  ></span>
                </a>
              </ng-container>
            </app-sidebar-field>
          </div>
        </ng-container>
      </ng-container>
    </app-sidebar-section>

    <ng-container *ngIf="dataConfigured$ | async">
      <div class="sidebar__element">
        <app-data-source-bind-component
          [control]="form.controls.data_source"
          [context]="context"
          [element]="element"
          [selfBindPrimaryKey]="false"
          [analyticsSource]="object"
        ></app-data-source-bind-component>
      </div>

      <app-sidebar-section
        *ngIf="
          (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Input ||
          (form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
          ((form.controls.data_source.controls.type | appControlValue | async) == dataSourceTypes.Query &&
            form.controls.data_source.controls.query.valid)
        "
        [title]="(form.controls.data_source.getQueryEditable$() | async) ? 'Specify Inputs' : 'Apply Filters'"
        [description]="
          (form.controls.data_source.getQueryEditable$() | async)
            ? 'Manually or use value from other components'
            : 'Static or use value from other components'
        "
      >
        <app-data-source-inputs-edit
          [control]="form.controls.data_source"
          [fieldsControl]="form.controls.data_source.controls.columns"
          [editable]="form.controls.data_source.getQueryEditable$() | async"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [classes]="'component-data-inputs'"
          [itemClasses]="'component-data-inputs-item'"
          [addClasses]="'component-data-inputs-add'"
          [analyticsSource]="object"
          (addInput)="addQueryInput()"
        ></app-data-source-inputs-edit>
      </app-sidebar-section>
    </ng-container>

    <app-sidebar-submit
      [saveEnabled]="false"
      [cancelEnabled]="false"
      [backEnabled]="true"
      [errors]="form | appFormErrors"
      (cancelClick)="cancel()"
    >
    </app-sidebar-submit>
  </div>
</form>

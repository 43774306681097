import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsPreviousYearFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_previous_year';
  public param = 'isPreviousYear';
  public verboseName = localize('is Previous Year');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('year').subtract(1, 'years').toISOString();
  }

  to(value: string): string {
    return moment().startOf('year').subtract(1, 'years').endOf('year').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not previous year', [field]);
      } else {
        return localize('is not previous year');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is previous year', [field]);
      } else {
        return localize('previous year');
      }
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { ListStore } from '@shared';
// TODO: Refactor import
import { PAGE_PARAM, PER_PAGE_PARAM } from '../../models/data/params';
import { LogService } from '../services/log/log.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var LogListStore = /** @class */ (function (_super) {
    __extends(LogListStore, _super);
    function LogListStore(currentProjectStore, currentEnvironmentStore, logService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.logService = logService;
        _this.params = {};
        return _this;
    }
    LogListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        params[PER_PAGE_PARAM] = this.perPage;
        return params;
    };
    LogListStore.prototype.fetchPage = function (page, next) {
        var _this = this;
        var params = this.paramsForPage(page);
        return this.logService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, params)
            .pipe(map(function (response) {
            var perPage = _this.perPage || 20;
            var totalPages = Math.ceil(response.count / perPage);
            return {
                items: response.results,
                hasMore: !!response.next,
                totalPages: totalPages,
                perPage: perPage,
                count: response.count
            };
        }));
    };
    return LogListStore;
}(ListStore));
export { LogListStore };

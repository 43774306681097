import { ListLayoutType } from './list-layout-type';

export interface ListLayout {
  type: ListLayoutType;
  label: string;
  icon: string;
  description?: string;
  disabled?: boolean;
  filters?: boolean;
}

export const listLayouts: ListLayout[] = [
  {
    type: ListLayoutType.Table,
    label: 'Table',
    icon: 'table',
    // description: 'Easily sort and filter your records with rows and columns layout'
    description: 'display tabular data with filtering and pagination',
    filters: true
  },
  {
    type: ListLayoutType.Map,
    label: 'Map',
    icon: 'sending',
    // description: 'View and work with your records inside any geographical area'
    description: 'display markers inside any geographical area',
    filters: true
  },
  {
    type: ListLayoutType.KanbanBoard,
    label: 'Kanban',
    icon: 'versions',
    // description: 'Build your workflow with Kanban Board for convenient status change of records'
    description: 'display grouped data in boards by status'
  },
  {
    type: ListLayoutType.Calendar,
    label: 'Calendar',
    icon: 'calendar',
    // description: 'Work with your timeline in calendar with day, week, month or year view'
    description: 'display events in calendar with day, week, month or year view',
    filters: true
  },
  {
    type: ListLayoutType.Grid,
    label: 'Gallery',
    icon: 'windows',
    // description: 'Display your records as cards when focusing on each item is important'
    description: 'display records as cards',
    filters: true
  },
  {
    type: ListLayoutType.Carousel,
    label: 'Carousel',
    icon: 'windows',
    description: 'display records in carousel',
    filters: true
  },
  {
    type: ListLayoutType.Timeline,
    label: 'Timeline',
    icon: 'time',
    description: 'display a series of events in time',
    filters: true
  }
  // {
  //   type: ListLayoutType.SideList,
  //   label: 'Side List',
  //   disabled: true
  // }
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { isSet, ListStore } from '@shared';
import { CustomViewTemplateService } from '../services/custom-view-template/custom-view-template.service';
var CustomViewTemplateListStore = /** @class */ (function (_super) {
    __extends(CustomViewTemplateListStore, _super);
    function CustomViewTemplateListStore(customViewTemplateService) {
        var _this = _super.call(this) || this;
        _this.customViewTemplateService = customViewTemplateService;
        _this.all = false;
        _this.params = {};
        return _this;
    }
    CustomViewTemplateListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        params[PER_PAGE_PARAM] = this.perPage;
        if (isSet(this.featured)) {
            params['featured'] = this.featured;
        }
        if (this.all) {
            params['all'] = '1';
        }
        if (isSet(this.active)) {
            params['active'] = this.active;
        }
        if (isSet(this.type)) {
            params['types'] = this.type;
        }
        return params;
    };
    CustomViewTemplateListStore.prototype.fetchPage = function (page, next) {
        var _this = this;
        var params = this.paramsForPage(page);
        return this.customViewTemplateService.get(params).pipe(map(function (response) {
            var perPage = _this.perPage || response.perPage;
            return {
                items: response.results,
                hasMore: !!response.next,
                totalPages: response.numPages,
                perPage: perPage,
                count: response.count
            };
        }));
    };
    return CustomViewTemplateListStore;
}(ListStore));
export { CustomViewTemplateListStore };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { createFormFieldFactory, FieldType, getFieldDescriptionByType, ParameterControl } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, KeyboardEventKeyCode } from '@shared';
var ParametersEditItemComponent = /** @class */ (function () {
    function ParametersEditItemComponent(cd) {
        this.cd = cd;
        this.configurable = {};
        this.valueEditable = false;
        this.openedInitial = false;
        this.autofocus = false;
        this.deleteRequested = new EventEmitter();
        this.valueUpdated = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.opened = false;
        this.rename = false;
        this.renameControl = new FormControl('');
        this.popoverOpened = false;
        this.hovered$ = new BehaviorSubject(false);
        this.fieldTypeDropdownOpened = false;
        this.fieldTypeDropdownPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center'
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center'
            }
        ];
        this.valueControl = new FormControl('');
        this.valueControlForm = new FormGroup({ control: this.valueControl });
        this.fieldTypes = FieldType;
        this.booleanValueEquals = function (lhs, rhs) { return lhs === rhs; };
    }
    ParametersEditItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fieldType$ = controlValue(this.control.controls.field);
        this.fieldIcon$ = this.fieldType$.pipe(map(function (value) { return getFieldDescriptionByType(value).icon; }));
        controlValue(this.control.controls.params)
            .pipe(filter(function () { return _this.valueEditable; }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.valueFieldParams = _this.getValueFieldParams(value);
            _this.cd.markForCheck();
        });
        this.valueControl.valueChanges
            .pipe(filter(function () { return _this.valueEditable; }), debounceTime(200), untilDestroyed(this))
            .subscribe(function (value) { return _this.valueUpdated.emit(value); });
    };
    ParametersEditItemComponent.prototype.ngOnDestroy = function () { };
    ParametersEditItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.collapseContext) {
            this.initCollapseContext();
        }
        if (this.valueEditable && (changes.valueEditable || changes.value)) {
            this.valueControl.patchValue(this.value, { emitEvent: false });
        }
    };
    ParametersEditItemComponent.prototype.ngAfterViewInit = function () {
        this.setFieldTypeDropdownPositionObserver();
    };
    ParametersEditItemComponent.prototype.initCollapseContext = function () {
        var _this = this;
        if (this.initCollapseContextSubscription) {
            this.initCollapseContextSubscription.unsubscribe();
            this.initCollapseContextSubscription = undefined;
        }
        if (this.collapseContext) {
            this.initCollapseContextSubscription = this.collapseContext.opened$
                .pipe(untilDestroyed(this))
                .subscribe(function (opened) {
                _this.opened = opened === _this;
                _this.cd.markForCheck();
            });
        }
        if (this.openedInitial) {
            this.setOpened(true);
        }
    };
    ParametersEditItemComponent.prototype.setOpened = function (opened) {
        if (this.collapseContext) {
            if (opened && this.collapseContext.opened !== this) {
                this.collapseContext.opened = this;
            }
            else if (!opened && this.collapseContext.opened === this) {
                this.collapseContext.opened = undefined;
            }
        }
        else {
            this.opened = opened;
            this.cd.markForCheck();
        }
    };
    ParametersEditItemComponent.prototype.toggleOpened = function () {
        this.setOpened(!this.opened);
    };
    ParametersEditItemComponent.prototype.setRename = function (rename, save) {
        if (save === void 0) { save = true; }
        this.rename = rename;
        this.cd.markForCheck();
        if (this.rename) {
            this.renameControl.patchValue(this.control.controls.name.value);
        }
        else if (!rename && save) {
            this.control.controls.name.patchValue(this.renameControl.value);
        }
    };
    ParametersEditItemComponent.prototype.onKeyUp = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            this.setRename(false);
        }
        else if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.setRename(false, false);
        }
    };
    ParametersEditItemComponent.prototype.onKeyPress = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            e.preventDefault();
        }
    };
    ParametersEditItemComponent.prototype.setPopoverOpened = function (opened) {
        this.popoverOpened = opened;
        this.cd.markForCheck();
    };
    ParametersEditItemComponent.prototype.onPopoverContentChanged = function () {
        this.cdkConnectedOverlay.overlayRef.updatePosition();
    };
    ParametersEditItemComponent.prototype.setFieldTypeDropdownOpened = function (value) {
        this.fieldTypeDropdownOpened = value;
        this.cd.markForCheck();
    };
    ParametersEditItemComponent.prototype.setFieldTypeDropdownPositionObserver = function () {
        var _this = this;
        if (this.fieldTypeDropdownPositionsSubscription) {
            this.fieldTypeDropdownPositionsSubscription.unsubscribe();
        }
        if (!this.fieldTypeOverlay) {
            return;
        }
        this.fieldTypeDropdownPositionsSubscription = this.fieldTypeOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.fieldTypeDropdownPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.fieldTypeDropdownPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.fieldTypeOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.fieldTypeOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    ParametersEditItemComponent.prototype.getValueFieldParams = function (params) {
        return __assign({}, (params ? params : {}), { classes: ['select_fill', 'input_fill'].concat((params && params['classes'] ? params['classes'] : [])), background: true });
    };
    return ParametersEditItemComponent;
}());
export { ParametersEditItemComponent };

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { FileFieldDataParamsForm } from './file-field-data-params.form';

@Component({
  selector: 'app-file-field-data-params',
  templateUrl: './file-field-data-params.component.html',
  providers: [FileFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();

  constructor(public form: FileFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.File, FileFieldDataParamsComponent);

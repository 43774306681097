import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';

import { FeatureService } from '@modules/features';
import {
  CurrentProjectStore,
  isResourceTypeItem3rdParty,
  isResourceTypeItemCustom,
  Project,
  ResourceName,
  ResourceTypeItem,
  ResourceTypeItemCategory,
  resourceTypeItems
} from '@modules/projects';
import { KeyboardEventKeyCode } from '@shared';

import { ResourceFilter } from '../../data/resource-filter';

@Component({
  selector: 'app-create-resource',
  templateUrl: './create-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateResourceComponent implements OnInit, OnDestroy {
  @Input() animatedShow = false;
  @Input() passed = false;
  @Input() future = false;
  @Input() project: Project;
  @Output() chosen = new EventEmitter<ResourceTypeItem>();

  customResource = resourceTypeItems.find(item => item.name == ResourceName.JetDatabase);
  resources: ResourceTypeItem[];
  resourcesNotFound: ResourceTypeItem[];
  resourcesFiltered: ResourceTypeItem[];
  resourceFilters: { label: string; filter?: ResourceFilter }[] = [
    {
      label: 'All Integrations'
    },
    {
      label: 'Databases',
      filter: { category: ResourceTypeItemCategory.Databases }
    },
    {
      label: 'APIs',
      filter: { category: ResourceTypeItemCategory.APIs }
    },
    {
      label: 'Frameworks',
      filter: { category: ResourceTypeItemCategory.Frameworks }
    },
    {
      label: 'Storages',
      filter: { category: ResourceTypeItemCategory.Storages }
    }
  ];
  resourceFilterIndex = 0;
  resourceFilterCounts: number[] = [];
  resourceSearch: string;
  resourceSearchUpdated = new Subject<void>();

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private featureService: FeatureService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.resources = resourceTypeItems.filter(
      item => !item.hidden && !item.protected && item.name != ResourceName.JetDatabase
    );
    this.resourcesNotFound = resourceTypeItems.filter(item => {
      return [ResourceName.RestApi, ResourceName.GraphQL].includes(item.name);
    });

    this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateResourcesFiltered());

    this.updateResourcesFiltered();
  }

  ngOnDestroy(): void {}

  setResourceFilterIndex(i: number) {
    this.resourceFilterIndex = i;
    this.cd.markForCheck();
    this.updateResourcesFiltered();
  }

  updateResourcesFiltered() {
    const filterResources = (resources: ResourceTypeItem[], filter?: ResourceFilter) => {
      return resources.filter(item => {
        if (filter) {
          if (filter.category != undefined) {
            if (!item.categories.includes(filter.category)) {
              return false;
            }
          }
        }

        if (this.resourceSearch) {
          if (item.label.toLowerCase().includes(this.resourceSearch.toLowerCase()) == false) {
            return false;
          }
        }

        return true;
      });
    };

    this.resourceFilterCounts = this.resourceFilters.map(item => filterResources(this.resources, item.filter).length);

    if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
      this.resourceFilterIndex = 0;
    }

    const currentFilter = this.currentResourceFilter;

    this.resourcesFiltered = filterResources(this.resources, currentFilter);
    this.cd.markForCheck();
  }

  clearSearch() {
    this.resourceSearch = '';
    this.resourceSearchUpdated.next();
    this.cd.markForCheck();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }

  get currentResourceFilter(): ResourceFilter {
    if (!this.resourceFilters[this.resourceFilterIndex]) {
      return;
    }
    return this.resourceFilters[this.resourceFilterIndex].filter;
  }

  chooseResource(typeItem: ResourceTypeItem) {
    if (
      isResourceTypeItem3rdParty(typeItem) &&
      !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Business Apps</strong>',
        description: `
          Connect data from various business apps. You can see all your data and take action in one place.
        `
      });
      return;
    } else if (
      isResourceTypeItemCustom(typeItem) &&
      !this.currentProjectStore.instance.features.isCustomResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Custom Queries</strong>',
        description: `
          Connect data from custom queries. You can see all your data and take action in one place.
        `
      });
      return;
    }

    this.chosen.emit(typeItem);
  }
}

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { ActionItem } from '@modules/actions';
import { TypedControl } from '@shared';

export class ActionItemArray extends FormArray {
  controls: TypedControl<ActionItem>[];

  deserialize(value: ActionItem[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.patchValue(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): ActionItem[] {
    return this.controls.map(control => control.value);
  }

  setControls(controls: TypedControl<ActionItem>[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: TypedControl<ActionItem>) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item: ActionItem): TypedControl<ActionItem> {
    return new TypedControl<ActionItem>(item || new ActionItem());
  }

  appendControl(item: ActionItem): TypedControl<ActionItem> {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  getControlNameDefault(index: number): string {
    return `Action ${index + 1}`;
  }

  generateActionName(defaultName: string, names: { [name: string]: any }): string {
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (names.hasOwnProperty(newName.toLowerCase()));

    return newName;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';

import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';

@Component({
  selector: 'app-customize-bar-features-edit',
  templateUrl: './customize-bar-features-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
// TODO: Remove unused
export class CustomizeBarFeaturesEditComponent {
  @Input() settingsForm: ProjectSettingsUpdateForm;

  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
}

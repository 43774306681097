var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore } from '@shared';
import { DataSyncService } from '../services/data-sync/data-sync.service';
var DataSyncJobTaskStore = /** @class */ (function (_super) {
    __extends(DataSyncJobTaskStore, _super);
    function DataSyncJobTaskStore(currentProjectStore, currentEnvironmentStore, dataSyncService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.dataSyncService = dataSyncService;
        _this.params = {};
        return _this;
    }
    DataSyncJobTaskStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        return params;
    };
    DataSyncJobTaskStore.prototype.fetchPage = function (page, next) {
        var params = this.paramsForPage(page);
        return this.dataSyncService
            .getJobTasks(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.jobId, params)
            .pipe(map(function (response) {
            return {
                items: response.results,
                hasMore: page < response.numPages,
                totalPages: response.numPages,
                perPage: response.perPage,
                count: response.count
            };
        }));
    };
    return DataSyncJobTaskStore;
}(ListStore));
export { DataSyncJobTaskStore };

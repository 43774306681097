import { NgZone, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { AppConfigService } from '../app-config/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "../app-config/app-config.service";
export var ViewportType;
(function (ViewportType) {
    ViewportType[ViewportType["Desktop"] = 0] = "Desktop";
    ViewportType[ViewportType["Tablet"] = 1] = "Tablet";
    ViewportType[ViewportType["Phone"] = 2] = "Phone";
})(ViewportType || (ViewportType = {}));
var DocumentService = /** @class */ (function () {
    function DocumentService(zone, appConfigService) {
        var _this = this;
        this.zone = zone;
        this.appConfigService = appConfigService;
        this.tabletMaxWidth = 992;
        this.phoneMaxWidth = 480;
        this._viewportWidth = new BehaviorSubject(undefined);
        this._viewportHeight = new BehaviorSubject(undefined);
        this._viewportType = new BehaviorSubject(undefined);
        if (this.appConfigService.isPlatformServer()) {
            return;
        }
        merge(of({}), fromEvent(window, 'resize'))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this._viewportWidth.next(window.innerWidth);
            _this._viewportHeight.next(window.innerHeight);
        });
        this._viewportWidth.pipe(untilDestroyed(this)).subscribe(function (width) {
            _this.updateViewportType(width);
        });
    }
    DocumentService.prototype.ngOnDestroy = function () { };
    Object.defineProperty(DocumentService.prototype, "viewportWidth", {
        get: function () {
            return this._viewportWidth.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportWidth$", {
        get: function () {
            return this._viewportWidth.pipe(filter(function (item) { return item !== undefined; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportWidthChanges$", {
        get: function () {
            return this.viewportWidth$.pipe(skip(1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportHeight", {
        get: function () {
            return this._viewportHeight.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportHeight$", {
        get: function () {
            return this._viewportHeight.pipe(filter(function (item) { return item !== undefined; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportHeightChanges$", {
        get: function () {
            return this.viewportHeight$.pipe(skip(1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportSize$", {
        get: function () {
            return combineLatest(this.viewportWidth$, this.viewportHeight$).pipe(map(function (_a) {
                var width = _a[0], height = _a[1];
                return { width: width, height: height };
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportSizeChanges$", {
        get: function () {
            return this.viewportSize$.pipe(skip(1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportType", {
        get: function () {
            return this._viewportType.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentService.prototype, "viewportType$", {
        get: function () {
            return this._viewportType.pipe(filter(function (item) { return item !== undefined; }));
        },
        enumerable: true,
        configurable: true
    });
    DocumentService.prototype.isDesktopViewportType = function (value) {
        return value == ViewportType.Desktop;
    };
    DocumentService.prototype.isTabletViewportType = function (value) {
        return value == ViewportType.Tablet;
    };
    DocumentService.prototype.isPhoneViewportType = function (value) {
        return value == ViewportType.Phone;
    };
    DocumentService.prototype.isMobileViewportType = function (value) {
        return [ViewportType.Tablet, ViewportType.Phone].includes(value);
    };
    DocumentService.prototype.isTablet = function () {
        return this.isTabletViewportType(this.viewportType);
    };
    DocumentService.prototype.isTablet$ = function () {
        var _this = this;
        return this.viewportType$.pipe(map(function (value) { return _this.isTabletViewportType(value); }));
    };
    DocumentService.prototype.isPhone = function () {
        return this.isPhoneViewportType(this.viewportType);
    };
    DocumentService.prototype.isPhone$ = function () {
        var _this = this;
        return this.viewportType$.pipe(map(function (value) { return _this.isPhoneViewportType(value); }));
    };
    DocumentService.prototype.isMobile = function () {
        return this.isMobileViewportType(this.viewportType);
    };
    DocumentService.prototype.isMobile$ = function () {
        var _this = this;
        return this.viewportType$.pipe(map(function (value) { return _this.isMobileViewportType(value); }));
    };
    DocumentService.prototype.getViewportType = function (viewportWidth) {
        if (viewportWidth <= this.phoneMaxWidth) {
            return ViewportType.Phone;
        }
        else if (viewportWidth <= this.tabletMaxWidth) {
            return ViewportType.Tablet;
        }
        else {
            return ViewportType.Desktop;
        }
    };
    DocumentService.prototype.updateViewportType = function (viewportWidth) {
        var viewportType = this.getViewportType(viewportWidth);
        if (this._viewportType.value != viewportType) {
            this._viewportType.next(viewportType);
        }
    };
    DocumentService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.inject(i0.NgZone), i0.inject(i1.AppConfigService)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };

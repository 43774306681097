import { transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import clamp from 'lodash/clamp';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components/data/customize-bar-item';
import {
  AlignHorizontal,
  ChangeViewSettings,
  ColumnsLayoutColumnElementItem,
  ColumnsLayoutElementItem,
  CustomizeService,
  CustomViewSettings,
  ElementItem,
  generateElementNameInContext,
  generateUidAndNamesRecursive,
  getElementByType,
  getElementNames,
  isElementTypeAlignHorizontal,
  PopupSettings,
  ViewContext,
  ViewSettings
} from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { TemplateApplyService } from '@modules/template-components';
import { isSet } from '@shared';

export const BUILDER_ADD_ELEMENT = 'builder_add_element';
export const BUILDER_ELEMENT_BUFFER = 'builder_element_buffer';

@Injectable()
export class ElementContainerService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customizeService: CustomizeService,
    private sessionStorage: SessionStorage,
    private templateApplyService: TemplateApplyService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  generateElementItemName(element: ElementItem, context: ViewContext, options: { forceNewUid?: boolean } = {}) {
    generateUidAndNamesRecursive(element, context, options);
  }

  generatePopupName(element: PopupSettings, context: ViewContext, options: { forceNewUid?: boolean } = {}) {
    generateUidAndNamesRecursive(element, context, options);
  }

  createElementItem<T extends ElementItem = ElementItem>(item: CustomizeBarItem, context: ViewContext): T {
    const element = getElementByType(item.type);

    if (!element) {
      return;
    }

    const instance = new element().deserialize({
      type: item.type,
      align_horizontal: item.alignHorizontal,
      align_vertical: item.alignVertical,
      params: cloneDeep(item.defaultParams)
    }) as T;

    generateElementNameInContext(instance, context, { name: item.defaultName });

    if (item.postCreate) {
      return item.postCreate(instance) as T;
    } else {
      return instance;
    }
  }

  insertElementItem<T extends ElementItem = ElementItem>(
    item: CustomizeBarItem,
    list: ElementItem[],
    context: ViewContext,
    index: number
  ): T {
    const to = clamp(index, list.length);
    const elementItem = this.createElementItem<T>(item, context);

    list.splice(to, 0, elementItem);

    return elementItem;
  }

  copyElementItem(
    currentArray: CustomizeBarItem[],
    targetArray: ElementItem[],
    currentIndex: number,
    targetIndex: number,
    context: ViewContext
  ): ElementItem {
    const to = clamp(targetIndex, targetArray.length);

    if (currentArray.length) {
      const item = currentArray[currentIndex];
      return this.insertElementItem(item, targetArray, context, to);
    }
  }

  duplicateElementItem(list: ElementItem[], context: ViewContext, index: number): ElementItem {
    const element = cloneDeep(list[index]);
    this.generateElementItemName(element, context, { forceNewUid: true });
    list.splice(index + 1, 0, element);
    return element;
  }

  replaceElementItem(list: ElementItem[], index: number, elements: ElementItem[]): void {
    list.splice(index, 1, ...elements);
  }

  deleteElementItem(list: ElementItem[], index: number): void {
    list.splice(index, 1);
  }

  prependElementItem(
    page: CustomViewSettings | ChangeViewSettings,
    pagePopup: PopupSettings,
    element: ElementItem,
    context: ViewContext
  ) {
    element.uid = undefined;

    this.generateElementItemName(element, context);

    if (pagePopup) {
      pagePopup.elements.splice(0, 0, element);
    } else {
      page.elements.splice(0, 0, element);
    }
  }

  prependPopup(page: CustomViewSettings, pagePopup: PopupSettings, popup: PopupSettings, context: ViewContext) {
    popup.uid = undefined;

    this.generatePopupName(popup, context);
    page.popups.push(popup);
  }

  addSavedElement(
    page: CustomViewSettings | ChangeViewSettings,
    pagePopup: PopupSettings,
    sessionKey: string,
    context: ViewContext
  ): { element?: ElementItem; popup?: PopupSettings } {
    const addElementData = this.sessionStorage.get(sessionKey);

    if (!addElementData) {
      return {};
    }

    try {
      const data = JSON.parse(addElementData);

      if (!data['persistent']) {
        this.sessionStorage.remove(sessionKey);
      }

      if (data['element'] && data['element']['type']) {
        const elementCls = getElementByType(data['element']['type']);

        if (!elementCls) {
          return;
        }

        const element = new elementCls().deserialize(data['element']);
        this.prependElementItem(page, pagePopup, element, context);
        return { element };
      } else if (data['popup'] && page instanceof CustomViewSettings) {
        const popup = new PopupSettings().deserialize(data['popup']);
        this.prependPopup(page, pagePopup, popup, context);
        return { popup };
      }
    } catch (e) {
      console.error(e);
    }

    return {};
  }

  setUpElementComponent(
    element: ElementItem,
    item?: CustomizeBarItem
  ): Observable<{ element: ElementItem; customized?: boolean }> {
    if (!item || !item.template) {
      return of({ element: element });
    }

    return this.templateApplyService
      .applyWidget(
        this.currentProjectStore.instance,
        this.currentEnvironmentStore.instance,
        item.template,
        item.resource,
        { useDemoResources: true }
      )
      .pipe(
        map(result => {
          result.uid = element.uid;
          result.name = element.name;

          return { element: result, customized: true };
        })
      );
  }

  dragDropIntoSiblingColumn(options: {
    sourceContainer: (ElementItem | CustomizeBarItem)[];
    sourceIndex: number;
    sourceCloneItem: boolean;
    anchorContainer: ElementItem[];
    anchorIndex: number;
    anchorSelf?: boolean;
    left: boolean;
    context: ViewContext;
    parent?: ElementItem;
  }) {
    const anchor = options.anchorContainer[options.anchorIndex];
    const anchorColumnWeight = 2;
    const newColumnWeight = 1;
    const anchorContainerUseExistingForLength = options.anchorSelf ? 0 : 1;
    const newColumn = new ColumnsLayoutColumnElementItem();

    if (options.sourceCloneItem) {
      const sourceContainer = options.sourceContainer as CustomizeBarItem[];
      const source = sourceContainer[options.sourceIndex];

      const elementItem = this.copyElementItem(
        sourceContainer,
        newColumn.children,
        options.sourceIndex,
        0,
        options.context
      );
      this.customizeService.registerCreatedElement(elementItem, source);
    } else {
      transferArrayItem(options.sourceContainer as ElementItem[], newColumn.children, options.sourceIndex, 0);
    }

    // if (
    //   options.parent instanceof ColumnsLayoutElementItem &&
    //   options.anchorContainer.length == anchorContainerUseExistingForLength
    // ) {
    //   // If columns already exists and has 1 child
    //   const anchorColumnIndex = options.parent.columns.findIndex(item => item.children === options.anchorContainer);
    //   const anchorColumn = options.parent.columns[anchorColumnIndex];
    //
    //   options.parent.columns = options.left
    //     ? [
    //         ...options.parent.columns.slice(0, anchorColumnIndex),
    //         newColumn,
    //         ...options.parent.columns.slice(anchorColumnIndex)
    //       ]
    //     : [
    //         ...options.parent.columns.slice(0, anchorColumnIndex + 1),
    //         newColumn,
    //         ...options.parent.columns.slice(anchorColumnIndex + 1)
    //       ];
    //
    //   if (isSet(anchorColumn.width)) {
    //     const totalWeight = anchorColumnWeight + newColumnWeight;
    //
    //     anchorColumn.weight = anchorColumn.width * (anchorColumnWeight / totalWeight);
    //     anchorColumn.width = undefined;
    //     newColumn.weight = anchorColumn.width * (newColumnWeight / totalWeight);
    //   } else if (isSet(anchorColumn.weight)) {
    //     newColumn.weight = newColumnWeight * anchorColumn.weight;
    //     anchorColumn.weight = anchorColumnWeight * anchorColumn.weight;
    //   }
    //
    //   if (
    //     !options.anchorSelf &&
    //     isElementTypeAlignHorizontal(anchor.type) &&
    //     anchor.alignHorizontalOrDefault == AlignHorizontal.Left
    //   ) {
    //     anchorColumn.weight = undefined;
    //     anchorColumn.width = undefined;
    //     anchorColumn.fit = true;
    //   }
    // } else {
    // If columns should be created
    const columns = new ColumnsLayoutElementItem();
    const anchorColumn = new ColumnsLayoutColumnElementItem();

    columns.generateUid();
    generateElementNameInContext(columns, options.context);

    columns.columns = options.left ? [newColumn, anchorColumn] : [anchorColumn, newColumn];

    anchorColumn.weight = anchorColumnWeight;
    newColumn.weight = newColumnWeight;

    if (
      !options.anchorSelf &&
      isElementTypeAlignHorizontal(anchor.type) &&
      anchor.alignHorizontalOrDefault == AlignHorizontal.Left
    ) {
      if (options.left) {
        newColumn.fit = true;
      } else {
        anchorColumn.fit = true;
      }
    }

    if (options.anchorContainer === options.sourceContainer && options.sourceIndex < options.anchorIndex) {
      options.anchorIndex -= 1;
    }

    if (!options.anchorSelf) {
      transferArrayItem(options.anchorContainer, anchorColumn.children, options.anchorIndex, 0);
    }

    options.anchorContainer.splice(options.anchorIndex, 0, columns);
    // }
  }

  dragDropIntoNewColumn(options: {
    columnsElement: ColumnsLayoutElementItem;
    columnIndex: number;
    sourceContainer: (ElementItem | CustomizeBarItem)[];
    sourceIndex: number;
    sourceCloneItem: boolean;
    context: ViewContext;
  }) {
    const newColumn = new ColumnsLayoutColumnElementItem();

    if (options.sourceCloneItem) {
      const sourceContainer = options.sourceContainer as CustomizeBarItem[];
      const source = sourceContainer[options.sourceIndex];

      const elementItem = this.copyElementItem(
        sourceContainer,
        newColumn.children,
        options.sourceIndex,
        0,
        options.context
      );
      this.customizeService.registerCreatedElement(elementItem, source);
    } else {
      transferArrayItem(options.sourceContainer as ElementItem[], newColumn.children, options.sourceIndex, 0);
    }

    const fluidColumns = options.columnsElement.columns.filter(item => isSet(item.weight));
    const weight = fluidColumns.length
      ? fluidColumns.map(item => item.weight).reduce((a, b) => a + b, 0) / fluidColumns.length
      : 1;

    newColumn.weight = weight;

    options.columnsElement.columns = [
      ...options.columnsElement.columns.slice(0, options.columnIndex),
      newColumn,
      ...options.columnsElement.columns.slice(options.columnIndex)
    ];
  }

  sendAddElementItemAnalytics(element: ElementItem, item: CustomizeBarItem) {
    let templateResourceTypeID;
    let templateResourceID;
    let templateName;

    if (item.template) {
      templateResourceTypeID = item.template.forResources.length
        ? item.template.forResources[0].typeItem.name
        : undefined;
      templateName = item.title;
    } else if (item.resource) {
      templateResourceTypeID = item.resource.typeItem.name;
      templateName = item.title;
    }

    if (item.resource && item.resource.demo) {
      templateResourceID = item.resource.uniqueName;
    } else if (item.resource) {
      templateResourceID = item.resource.typeItem.name;
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.DraggedToPage, {
      ComponentTypeID: element.analyticsName,
      TemplateResourceTypeID: templateResourceTypeID,
      TemplateResourceID: templateResourceID,
      TemplateName: templateName
      // ComponentsCount: elements.length
    });
  }

  sendAddPopupAnalytics() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.DraggedToPage, {
      ComponentTypeID: 'popup'
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isSet } from '@shared';
import { getFieldDescriptionByType, parseFieldType } from './fields';
import { FormField } from './form-field';
import { Input } from './input';
export var DisplayFieldType;
(function (DisplayFieldType) {
    DisplayFieldType["Base"] = "base";
    DisplayFieldType["Computed"] = "computed";
    DisplayFieldType["CustomView"] = "custom_view";
    DisplayFieldType["Lookup"] = "lookup";
    DisplayFieldType["Aggregate"] = "aggregate";
})(DisplayFieldType || (DisplayFieldType = {}));
var DisplayField = /** @class */ (function () {
    function DisplayField(options) {
        if (options === void 0) { options = {}; }
        this.type = DisplayFieldType.Base;
        this.params = {};
        this.visible = false;
        Object.assign(this, options);
    }
    DisplayField.prototype.deserialize = function (data) {
        this.type = data['type'] || DisplayFieldType.Base;
        this.name = data['name'];
        this.verboseName = data['verboseName'];
        this.description = data['description'];
        this.field = parseFieldType(data['field']);
        this.visible = data['visible'];
        if (data['params']) {
            this.params = data['params'];
        }
        this.updateFieldDescription();
        return this;
    };
    DisplayField.prototype.serialize = function () {
        return {
            type: this.type,
            name: this.name,
            verboseName: this.verboseName,
            description: this.description,
            field: this.field,
            visible: this.visible,
            params: this.params
        };
    };
    DisplayField.prototype.updateFieldDescription = function () {
        this.fieldDescription = getFieldDescriptionByType(this.field);
    };
    Object.defineProperty(DisplayField.prototype, "formField", {
        get: function () {
            if (!this._formField) {
                this._formField = new FormField().deserialize({
                    name: this.name,
                    label: this.verboseName,
                    field: this.field,
                    editable: false,
                    required: false,
                    params: this.params,
                    description: this.description
                });
            }
            return this._formField;
        },
        enumerable: true,
        configurable: true
    });
    DisplayField.prototype.isCustom = function () {
        return this.type != DisplayFieldType.Base;
    };
    Object.defineProperty(DisplayField.prototype, "icon", {
        get: function () {
            return this.fieldDescription ? this.fieldDescription.icon : undefined;
        },
        enumerable: true,
        configurable: true
    });
    return DisplayField;
}());
export { DisplayField };
var ComputedDisplayField = /** @class */ (function (_super) {
    __extends(ComputedDisplayField, _super);
    function ComputedDisplayField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = DisplayFieldType.Computed;
        _this.flex = true;
        return _this;
    }
    ComputedDisplayField.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['valueInput']) {
            this.valueInput = new Input().deserialize(data['valueInput']);
        }
        else {
            this.valueInput = undefined;
        }
        return this;
    };
    ComputedDisplayField.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { flex: this.flex, valueInput: this.valueInput ? this.valueInput.serialize() : undefined });
    };
    Object.defineProperty(ComputedDisplayField.prototype, "icon", {
        get: function () {
            return 'function';
        },
        enumerable: true,
        configurable: true
    });
    return ComputedDisplayField;
}(DisplayField));
export { ComputedDisplayField };
// TODO: Refactor import
var ViewMapping = /** @class */ (function () {
    function ViewMapping(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    ViewMapping.prototype.deserialize = function (data) {
        if (isSet(data['source_parameter'])) {
            this.sourceParameter = data['source_parameter'];
        }
        else if (data['sourceParameter']) {
            // Backward compatibility
            this.sourceParameter = data['sourceParameter'];
        }
        if (isSet(data['target_parameter'])) {
            this.targetParameter = data['target_parameter'];
        }
        else if (data['targetParameter']) {
            // Backward compatibility
            this.targetParameter = data['targetParameter'];
        }
        if (data['source_parameter_input']) {
            this.sourceParameterInput = new Input().deserialize(data['source_parameter_input']);
        }
        return this;
    };
    ViewMapping.prototype.serialize = function () {
        return {
            source_parameter: this.sourceParameter,
            source_parameter_input: this.sourceParameterInput ? this.sourceParameterInput.serialize() : undefined,
            target_parameter: this.targetParameter
        };
    };
    return ViewMapping;
}());
export { ViewMapping };
var CustomViewDisplayField = /** @class */ (function (_super) {
    __extends(CustomViewDisplayField, _super);
    function CustomViewDisplayField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = DisplayFieldType.CustomView;
        _this.customViewMappings = [];
        return _this;
    }
    CustomViewDisplayField.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.customView = data['custom_view'];
        if (data['custom_view_mappings']) {
            this.customViewMappings = data['custom_view_mappings'].map(function (item) { return new ViewMapping().deserialize(item); });
        }
        return this;
    };
    CustomViewDisplayField.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { custom_view: this.customView, custom_view_mappings: this.customViewMappings.map(function (item) { return item.serialize(); }) });
    };
    Object.defineProperty(CustomViewDisplayField.prototype, "icon", {
        get: function () {
            return 'canvas';
        },
        enumerable: true,
        configurable: true
    });
    return CustomViewDisplayField;
}(DisplayField));
export { CustomViewDisplayField };
var LookupDisplayField = /** @class */ (function (_super) {
    __extends(LookupDisplayField, _super);
    function LookupDisplayField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = DisplayFieldType.Lookup;
        return _this;
    }
    LookupDisplayField.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.path = data['path'];
        return this;
    };
    LookupDisplayField.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { path: this.path });
    };
    Object.defineProperty(LookupDisplayField.prototype, "icon", {
        get: function () {
            return 'lookup';
        },
        enumerable: true,
        configurable: true
    });
    return LookupDisplayField;
}(DisplayField));
export { LookupDisplayField };
var AggregateDisplayField = /** @class */ (function (_super) {
    __extends(AggregateDisplayField, _super);
    function AggregateDisplayField(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = DisplayFieldType.Aggregate;
        return _this;
    }
    AggregateDisplayField.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.path = data['path'];
        this.func = data['func'];
        this.column = data['column'];
        return this;
    };
    AggregateDisplayField.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { path: this.path, func: this.func, column: this.column });
    };
    Object.defineProperty(AggregateDisplayField.prototype, "icon", {
        get: function () {
            return 'spiral';
        },
        enumerable: true,
        configurable: true
    });
    return AggregateDisplayField;
}(DisplayField));
export { AggregateDisplayField };
export function deserializeDisplayField(item) {
    // Backward compatibility
    if (item['flex']) {
        return new ComputedDisplayField().deserialize(__assign({}, item, { type: DisplayFieldType.Computed }));
    }
    if (item['type'] == DisplayFieldType.Computed) {
        return new ComputedDisplayField().deserialize(item);
    }
    else if (item['type'] == DisplayFieldType.CustomView) {
        return new CustomViewDisplayField().deserialize(item);
    }
    else if (item['type'] == DisplayFieldType.Lookup) {
        return new LookupDisplayField().deserialize(item);
    }
    else if (item['type'] == DisplayFieldType.Aggregate) {
        return new AggregateDisplayField().deserialize(item);
    }
    else {
        return new DisplayField().deserialize(item);
    }
}

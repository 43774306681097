import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { IconsModule } from '@modules/icons';
import { SharedModule } from '@shared';

import { SelectComponent } from './components/select/select.component';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TipsModule,
    LocalizeModule,
    IconsModule
  ],
  declarations: [SelectComponent],
  exports: [SelectComponent]
})
export class SelectModule {}

import { Injectable } from '@angular/core';

import { DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isHttps, isHttpsUrl, isProductionLocation } from '@shared';

@Injectable()
export class ProjectProtocolService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService
  ) {
    this.currentEnvironmentStore.resources$.subscribe(resources => {
      if (!resources) {
        return;
      }

      const apiUrls = resources.map(item => item.params['url']).filter(item => item);
      this.redirectProtocolIfNeeded(apiUrls);
    });
  }

  redirectProtocolIfNeeded(apiUrls: string[]) {
    if (!isProductionLocation()) {
      return;
    }

    const projectHttps = apiUrls.every(item => isHttpsUrl(item));
    const browserHttps = isHttps();
    let setProtocol: string;
    let why: string;

    if (projectHttps && !browserHttps) {
      setProtocol = 'https:';
      why = "security reasons it's not recommended";
    } else if (!projectHttps && browserHttps) {
      setProtocol = 'http:';
      why = "browser security policies it's not possible";
    } else {
      return;
    }

    const newUrl = `${setProtocol}${window.location.href.substring(window.location.protocol.length)}`;
    const newUrlShort = newUrl.split('?', 2)[0];
    const oldProtocol = window.location.protocol.substring(0, location.protocol.length - 1).toUpperCase();
    const newProtocol = setProtocol.substring(0, setProtocol.length - 1).toUpperCase();
    const analyticsData = {
      ProjectUrl: apiUrls.join(','),
      BrowserUrl: window.location.href,
      SetProtocol: setProtocol
    };

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.ProtocolRedirectShow, analyticsData);

    this.dialogService
      .confirm({
        title: `${newProtocol} Redirect`,
        description: `
        Due to ${why} to work with <strong>${newProtocol}</strong>&nbsp;resources
        from <strong>${oldProtocol}</strong>&nbsp;URL. We will redirect you to:<br>
        <a href="${newUrl}" target="_blank">${newUrlShort}</a>
      `
      })
      .subscribe(confirm => {
        if (confirm) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.ProtocolRedirectConfirm, analyticsData);
          window.location.protocol = setProtocol;
        } else {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.ProtocolRedirectCancel, analyticsData);
        }
      });
  }
}

<span
  *ngIf="label && labelPosition == 'left'"
  class="toggle__label toggle__label_position_left"
  [class.toggle__label_disabled]="disabled"
>
  <ng-container *ngIf="!loading">{{ label }}</ng-container>
  <span *ngIf="loading" [class.loading-animation]="true"><span class="stub-text">ON</span></span>
</span>

<span class="toggle" [class.toggle_orange]="orange" [class.toggle_disabled]="disabled" [class.toggle_active]="selected">
  <span class="toggle__track">
    <span *ngIf="trackLabel" class="toggle__track-label">{{ trackLabel }}</span>
  </span>
  <span class="toggle__knob"></span>
</span>

<span
  *ngIf="label && labelPosition == 'right'"
  class="toggle__label toggle__label_position_right"
  [class.toggle__label_disabled]="disabled"
>
  <ng-container *ngIf="!loading">{{ label }}</ng-container>
  <span *ngIf="loading" [class.loading-animation]="true"><span class="stub-text">ON</span></span>
</span>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { View } from '@modules/views';
import { KeyboardEventKeyCode } from '@shared';

import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';
import { ViewEditorNavigatorService } from '../../services/view-editor-navigator/view-editor-navigator.service';

@Component({
  selector: 'app-view-editor-navigator',
  templateUrl: './view-editor-navigator.component.html',
  providers: [ViewEditorNavigatorService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorNavigatorComponent implements OnInit {
  @Input() view: View;

  searchControl = new FormControl('');

  constructor(public editorContext: ViewEditorContext) {}

  ngOnInit() {}

  clearSearch() {
    this.searchControl.patchValue('');
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { JsonFieldDataParamsForm } from './json-field-data-params.form';

@Component({
  selector: 'app-json-field-data-params',
  templateUrl: './json-field-data-params.component.html',
  providers: [JsonFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;

  constructor(public form: JsonFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.JSON, JsonFieldDataParamsComponent);

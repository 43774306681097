<div
  class="input input_fill input_container"
  [class.input_with-placeholder]="!control.value"
  style="cursor: pointer;"
  cdkOverlayOrigin
  #chooseActionTrigger="cdkOverlayOrigin"
  (click)="setComponentActionOpen(true, true)"
>
  <span *ngIf="!control.value" class="select__input-label">Choose</span>
  <a
    *ngIf="control.value"
    [appTip]="'Choose another action'"
    class="input-tag input-tag_fill"
    href="javascript:void(0)"
  >
    <span class="input-tag__main">{{ elementActionLabel(control.value) | async }}</span>
    <span class="input-tag__right">
      <span
        class="input-tag__control input-tag__control_root icon-close"
        (click)="$event.stopPropagation(); setComponentAction(null)"
      ></span>
    </span>
  </a>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }]"
  [cdkConnectedOverlayOrigin]="chooseActionTrigger"
  [cdkConnectedOverlayOpen]="componentActionPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setComponentActionOpen(false)"
  (detach)="setComponentActionOpen(false)"
>
  <div class="popover2">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content" style="min-width: 300px;">
          <div *ngIf="breadcrumbsToken.length" class="token-popup__item">
            <a href="javascript:void(0)" (click)="goBack()" class="token-popup-list-item token-popup-list-item_orange">
              <div class="token-popup-list-item__inner token-popup-list-item__line">
                <span class="token-popup-list-item__left"><span class="icon-arrow_backward_2"></span></span>
                <span class="token-popup-list-item__main">Back</span>
              </div>
            </a>
          </div>
          <ng-container *ngFor="let token of currentTokenGroup">
            <div class="token-popup__item">
              <div class="token-popup-section">
                <div class="token-popup-section__title">
                  <div class="token-popup-section__title-main">{{ token.name }}</div>
                </div>
                <div class="token-popup-section__content">
                  <ng-container *ngFor="let component of token.children">
                    <a
                      *ngIf="component.children?.length"
                      (click)="setCurrentTokenGroup(component)"
                      class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                      href="javascript:void(0)"
                    >
                      <div class="token-popup-list-item__inner">
                        <div class="token-popup-list-item__line">
                          <span *ngIf="component.icon" class="token-popup-list-item__left">
                            <span class="token-popup-list-item__icon" [ngClass]="'icon-' + component.icon"></span>
                          </span>
                          <span
                            class="token-popup-list-item__main token-popup-list-item__main_wrap"
                            [title]="component.name"
                          >
                            {{ component.name }}
                          </span>
                          <span class="token-popup-list-item__right token-popup-list-item__right_wrap">
                            <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                          </span>
                        </div>
                      </div>
                    </a>

                    <a
                      *ngIf="!component.children?.length"
                      (click)="setComponentAction(component)"
                      class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                      href="javascript:void(0)"
                    >
                      <div class="token-popup-list-item__inner">
                        <div class="token-popup-list-item__line">
                          <span *ngIf="component.icon" class="token-popup-list-item__left">
                            <span class="token-popup-list-item__icon" [ngClass]="'icon-' + component.icon"></span>
                          </span>
                          <span
                            class="token-popup-list-item__main token-popup-list-item__main_wrap"
                            [title]="component.name"
                          >
                            {{ component.name }}
                          </span>
                          <span class="token-popup-list-item__right token-popup-list-item__right_wrap"> </span>
                        </div>
                      </div>
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="token-popup__item">
            <div *ngIf="!currentTokenGroup.length" class="token-popup-list-item">
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__tip">No components with actions</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popover2__arrow"></div>
    </div>
  </div>
</ng-template>

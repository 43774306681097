import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { isElementHasChild, isSet, KeyboardEventKeyCode } from '@shared';

@Component({
  selector: 'app-customize-title',
  templateUrl: './customize-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeTitleComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Output() updateTitle = new EventEmitter<string>();

  @ViewChild('root') root: ElementRef;

  editing = false;
  editingValue: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.editing && !isElementHasChild(this.root.nativeElement, e.target as HTMLElement, true)) {
          this.cancelEditing();
        }
      });
  }

  ngOnDestroy(): void {}

  public isEditing() {
    return this.editing;
  }

  startEditing() {
    if (this.editing) {
      return;
    }

    this.editing = true;
    this.editingValue = this.title;
    this.cd.markForCheck();
  }

  saveEditing() {
    if (!this.editing) {
      return;
    }

    const value = this.cleanTitle(this.editingValue);

    if (!isSet(value)) {
      this.cancelEditing();
      return;
    }

    this.editing = false;
    this.cd.markForCheck();

    this.updateTitle.emit(value);
  }

  cancelEditing() {
    if (!this.editing) {
      return;
    }

    this.editing = false;
    this.editingValue = undefined;
    this.cd.markForCheck();
  }

  onClick(e) {
    e.stopPropagation();

    this.startEditing();
  }

  onKeyUp(e) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.saveEditing();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.cancelEditing();
    }
  }

  cleanTitle(title: string) {
    if (typeof title === 'string') {
      return title.trim();
    } else {
      return '';
    }
  }
}

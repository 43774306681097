<div class="popup-content">
  <div *ngIf="selectedResources.length == 1" class="popup-content__header">
    <div class="popup-content__title">Select {{ selectedResources[0].typeItem.label }}</div>
    <div class="popup-content__description">
      This template requires access to {{ selectedResources[0].typeItem.label }} resource, please choose which to use or
      create a new one.
    </div>
  </div>
  <div *ngIf="selectedResources.length > 1" class="popup-content__header">
    <div class="popup-content__title">Select Resources</div>
    <div class="popup-content__description">
      This template requires access to external Resources, please choose which to use or create new ones.
    </div>
  </div>
  <div class="popup-content__content">
    <div class="choose-items">
      <div class="choose-items__inner">
        <ng-container *ngIf="selectedResources.length == 1">
          <div
            *ngFor="let item of resourcesForItem(selectedResources[0].typeItem); let i = index"
            class="choose-items__item"
          >
            <a
              href="javascript:void(0)"
              class="choose-items-item choose-items-item_clickable"
              [class.choose-items-item_active]="selectedResources[0].resource == item"
              (click)="selectExistingResource(0, item); submit()"
            >
              <div class="choose-items-item__image">
                <div
                  class="choose-items-item__image-inner"
                  [style.background-image]="
                    '/assets/images/resources/' + item.typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></div>
              </div>
              <div class="choose-items-item__title">{{ item.name }}</div>
              <div class="choose-items-item__description">{{ item.typeItem.shortDescription }}</div>
              <div class="choose-items-item__action">
                <ng-container *ngIf="selectedResources[0].resource == item">
                  <span
                    class="choose-items-item__action-button choose-items-item__action-button_active choose-items-item__action-button_fill"
                  >
                    <span
                      class="icon icon-check_2 choose-items-item__action-button-icon choose-items-item__action-button-icon_left"
                    ></span>
                    <span class="button__label">Selected</span>
                  </span>
                  <a
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_clickable choose-items-item__action-button_icon choose-items-item__action-button_top"
                    (click)="$event.stopPropagation(); openResourceSettings(0, item)"
                  >
                    <span class="icon icon-gear choose-items-item__action-button-icon"></span>
                  </a>
                </ng-container>

                <ng-container *ngIf="selectedResources[0].resource != item">
                  <span
                    class="choose-items-item__action-button choose-items-item__action-button_focus choose-items-item__action-button_fill"
                  >
                    <span class="button__label">Select Resource</span>
                  </span>
                  <a
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_clickable choose-items-item__action-button_icon choose-items-item__action-button_top"
                    (click)="$event.stopPropagation(); openResourceSettings(0, item)"
                  >
                    <span class="icon icon-gear choose-items-item__action-button-icon"></span>
                  </a>
                </ng-container>
              </div>
            </a>
          </div>
          <div class="choose-items__item">
            <a
              href="javascript:void(0)"
              class="choose-items-item choose-items-item_clickable"
              (click)="openResourceSettings(0)"
            >
              <div class="choose-items-item__image">
                <div class="choose-items-item__image-inner"></div>
              </div>
              <div
                *ngIf="resourcesForItem(selectedResources[0].typeItem).length"
                class="choose-items-item__icon choose-items-item__icon_center"
              >
                <div class="choose-items-item__icon-inner">
                  <span class="icon-plus"></span>
                </div>
              </div>
              <div *ngIf="!resourcesForItem(selectedResources[0].typeItem).length" class="choose-items-item__image">
                <div
                  class="choose-items-item__image-inner"
                  [style.background-image]="
                    '/assets/images/resources/' + selectedResources[0].typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></div>
              </div>
              <div class="choose-items-item__title">&nbsp;</div>
              <div class="choose-items-item__description">&nbsp;</div>
              <div class="choose-items-item__action">
                <span class="choose-items-item__action-button choose-items-item__action-button_fill">
                  <span class="button__label">Create New</span>
                </span>
              </div>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedResources.length > 1">
          <div *ngFor="let item of selectedResources; let i = index" class="choose-items__item">
            <div class="choose-items-item">
              <div class="choose-items-item__image">
                <div
                  class="choose-items-item__image-inner"
                  [style.background-image]="
                    '/assets/images/resources/' + item.typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></div>
              </div>
              <div class="choose-items-item__title">{{ item.typeItem.label }}</div>
              <div class="choose-items-item__description">{{ item.typeItem.shortDescription }}</div>
              <div class="choose-items-item__action">
                <mat-menu #dropdown="matMenu">
                  <ng-template matMenuContent>
                    <button
                      *ngFor="let resource of resourcesForItem(item.typeItem)"
                      mat-menu-item
                      [disableRipple]="true"
                      (click)="selectExistingResource(i, resource)"
                    >
                      <img *ngIf="resource.icon" class="choose-items-item__dropdown-icon" [src]="resource.icon" />
                      <span *ngIf="!resource.icon" class="choose-items-item__dropdown-icon icon-bank"></span>
                      {{ resource.name }}
                    </button>
                    <div *ngIf="!resourcesForItem(item.typeItem).length" class="mat-menu-item-message">
                      No connected resources
                    </div>
                    <button mat-menu-item [disableRipple]="true" (click)="openResourceSettings(i)">
                      <span class="choose-items-item__dropdown-icon icon-plus"></span>
                      Connect Resource
                    </button>
                  </ng-template>
                </mat-menu>

                <ng-container *ngIf="item.resource">
                  <a
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_fill"
                    [matMenuTriggerFor]="dropdown"
                  >
                    <span class="button__label">{{ item.resource.name }}</span>
                    <span
                      class="icon icon-arrow_down_2 choose-items-item__action-button-icon choose-items-item__action-button-icon_right"
                    ></span>
                  </a>

                  <a
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_clickable choose-items-item__action-button_icon choose-items-item__action-button_top"
                    (click)="$event.stopPropagation(); openResourceSettings(i, item.resource)"
                  >
                    <span class="icon icon-gear choose-items-item__action-button-icon"></span>
                  </a>
                </ng-container>
                <ng-container *ngIf="!item.resource">
                  <a
                    *ngIf="resourcesForItem(item.typeItem).length"
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_clickable choose-items-item__action-button_fill"
                    [matMenuTriggerFor]="dropdown"
                  >
                    <span class="button__label">Select Resource</span>
                    <span
                      class="icon icon-arrow_down_2 choose-items-item__action-button-icon choose-items-item__action-button-icon_right"
                    ></span>
                  </a>

                  <a
                    *ngIf="!resourcesForItem(item.typeItem).length"
                    href="javascript:void(0)"
                    class="choose-items-item__action-button choose-items-item__action-button_clickable choose-items-item__action-button_fill"
                    (click)="openResourceSettings(i)"
                  >
                    <span
                      class="icon icon-plus choose-items-item__action-button-icon choose-items-item__action-button-icon_left"
                    ></span>
                    <span class="button__label">Add Resource</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="demoAvailable">
      <div class="choose-items-separator">
        <div class="choose-items-separator__border"></div>
        <div class="choose-items-separator__label">or</div>
        <div class="choose-items-separator__border"></div>
      </div>
      <div class="choose-items-block">
        <div class="choose-items-block__text">
          Would you like to play around with the Template with our Demo Data Sources?
        </div>
        <div class="choose-items-block__actions">
          <button type="button" class="button" (click)="useDemo()">
            Use Demo Data
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="popup-content__submit">
    <button type="button" class="button popup-content__submit-item" (click)="cancel()">
      Cancel
    </button>
    <button
      type="button"
      class="button button_primary popup-content__submit-item"
      [class.button_disabled]="!isValid()"
      (click)="submit()"
    >
      Continue
    </button>
  </div>
</div>

import { ContentChildren, Directive, Input, OnChanges, QueryList } from '@angular/core';
import { RouterLinkActive } from '@angular/router';

import { AppLinkDirective } from '../app-link/app-link.directive';

interface RouterLinkActiveOptions {
  exact: boolean;
}

@Directive({
  selector: '[appLinkActive]',
  exportAs: 'appLinkActive'
})
export class AppLinkActiveDirective extends RouterLinkActive implements OnChanges {
  @Input() appLinkActive: string[] | string;
  @Input() appLinkActiveOptions: RouterLinkActiveOptions = { exact: true };
  @Input() appLinkActiveData: Object;

  @ContentChildren(AppLinkDirective, { descendants: true }) linksWithHrefs = new QueryList<AppLinkDirective>();

  ngOnChanges(changes: {}): any {
    if (changes['appLinkActive']) {
      this.updateLinkActive();
    }

    if (changes['appLinkActiveOptions']) {
      this.updateLinkActiveOptions();
    }

    super.ngOnChanges(changes);
  }

  updateLinkActive() {
    this.routerLinkActive = this.appLinkActive;
  }

  updateLinkActiveOptions() {
    this.routerLinkActiveOptions = this.appLinkActiveOptions;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-heading.component";
var styles_PageHeadingComponent = [];
var RenderType_PageHeadingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeadingComponent, data: {} });
export { RenderType_PageHeadingComponent as RenderType_PageHeadingComponent };
export function View_PageHeadingComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageHeadingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-heading", [], [[2, "page-heading", null], [2, "page-heading_size_s", null], [2, "page-heading_size_m", null], [2, "page-heading_size_l", null]], null, null, View_PageHeadingComponent_0, RenderType_PageHeadingComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageHeadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).sizeS; var currVal_2 = i0.ɵnov(_v, 1).sizeM; var currVal_3 = i0.ɵnov(_v, 1).sizeL; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var PageHeadingComponentNgFactory = i0.ɵccf("app-page-heading", i1.PageHeadingComponent, View_PageHeadingComponent_Host_0, { size: "size" }, {}, ["*"]);
export { PageHeadingComponentNgFactory as PageHeadingComponentNgFactory };

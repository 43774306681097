import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FormUtilsModule } from '@common/form-utils';
import { LocalizeModule } from '@common/localize';
import { NotificationsModule } from '@common/notifications';
import { SelectModule } from '@common/select';
import { AnalyticsModule } from '@modules/analytics';
import { ApiModule } from '@modules/api';
import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { HeaderModule } from '@modules/header';
import { RoutingModule } from '@modules/routing';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ChangeEmailCompleteComponent } from './components/change-email-complete/change-email-complete.component';
import { LoginRouteComponent } from './components/login-route/login-route.component';
import { LoginTemplateComponent } from './components/login-template/login-template.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegisterRouteComponent } from './components/register-route/register-route.component';
import { RegisterTemplateFieldComponent } from './components/register-template-field/register-template-field.component';
import { RegisterTemplateComponent } from './components/register-template/register-template.component';
import { RegisterComponent } from './components/register/register.component';
import { RestoreCompleteComponent } from './components/restore-complete/restore-complete.component';
import { RestoreComponent } from './components/restore/restore.component';
import { SetTokenComponent } from './components/set-token/set-token.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { routes } from './users-routes.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ApiModule,
    AnalyticsModule,
    FieldsModule,
    FieldComponentsModule,
    NotificationsModule,
    RouterModule.forChild(routes),
    RoutingModule,
    HeaderModule,
    UiModule,
    LocalizeModule,
    FormUtilsModule,
    SelectModule,
    ThemeComponentsModule,
    ColorsSharedModule
  ],
  declarations: [
    SetTokenComponent,
    UserEditComponent,
    LoginRouteComponent,
    RegisterRouteComponent,
    LogoutComponent,
    VerifyEmailComponent,
    LoginComponent,
    RegisterComponent,
    RestoreComponent,
    RestoreCompleteComponent,
    LoginTemplateComponent,
    RegisterTemplateComponent,
    RegisterTemplateFieldComponent,
    ChangeEmailCompleteComponent
  ],
  exports: [LoginTemplateComponent, RegisterTemplateComponent]
})
export class UsersRoutesModule {}

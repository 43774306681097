import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ApiService, TokenOptions } from '@modules/api';
import { AuthService } from '@modules/auth';
import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore, UserService } from '@modules/users';

@Injectable()
export class RestoreCompleteForm {
  form: FormGroup;
  code: string;
  password = new FormControl('', Validators.required);
  password_repeat = new FormControl('', Validators.required);

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private projectsStore: ProjectsStore,
    private currentUserStore: CurrentUserStore,
    private apiService: ApiService
  ) {
    this.form = this.fb.group(
      {
        password: this.password,
        password_repeat: this.password_repeat
      },
      {
        validator: this.checkPasswords
      }
    );
  }

  checkPasswords(group: FormGroup) {
    if (group.controls['password'].value != group.controls['password_repeat'].value) {
      return {
        local: ['Passwords do not match']
      };
    }

    return null;
  }

  submit(): Observable<UserService.RestoreCompleteResponse> {
    this.form.markAsDirty();

    return this.userService
      .restoreComplete(this.code, this.form.value['password'])
      .pipe(
        switchMap(result => {
          const tokenOptions: TokenOptions = {
            token: result['token']
          };

          if (result['access_token']) {
            tokenOptions.accessToken = result['access_token'];
          }

          if (result['access_token_expires']) {
            tokenOptions.accessTokenExpires = moment(result['access_token_expires']);
          }

          if (result['refresh_token']) {
            tokenOptions.refreshToken = result['refresh_token'];
          }

          if (result['refresh_token_expires']) {
            tokenOptions.refreshTokenExpires = moment(result['refresh_token_expires']);
          }

          if (result['server_time']) {
            tokenOptions.serverTime = moment(result['server_time']);
          }

          this.apiService.saveToken(tokenOptions);
          return combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()]);
        })
      )
      .pipe(
        catchError(error => {
          this.formUtils.showFormErrors(this.form, error);
          return throwError(error);
        })
      );
  }
}

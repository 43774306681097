import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { AudioFieldDataParamsForm } from './audio-field-data-params.form';

@Component({
  selector: 'app-audio-field-data-params',
  templateUrl: './audio-field-data-params.component.html',
  providers: [AudioFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();

  constructor(public form: AudioFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.Audio, AudioFieldDataParamsComponent);

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="close.emit()"
  (detach)="close.emit()"
>
  <div
    class="overlay-content overlay-content_animated"
    [class.overlay-content_disable-interactions]="disableInteractions"
  >
    <div class="popover2 overlay-content overlay-content_animated">
      <div class="popover2__inner">
        <div class="popover2__viewport popover2__viewport_scrollable" [style.max-height.vh]="80">
          <div class="popover2__content" style="min-width: 300px;">
            <div class="token-popup-search" [style.margin-bottom.px]="10">
              <input
                type="text"
                class="token-popup-search__input"
                [formControl]="searchControl"
                [appAutofocus]="true"
                placeholder="Search actions..."
              />
              <span
                *ngIf="searchControl.value | appIsSet"
                class="token-popup-search__close icon-close"
                (click)="clearSearch()"
                [appTip]="'Clear'"
                [appTipOptions]="{ side: 'top' }"
              ></span>
            </div>

            <ng-container *ngIf="filteredSections">
              <div *ngFor="let section of filteredSections; trackBy: trackSectionFn" class="token-popup__item">
                <div class="token-popup-section">
                  <div class="token-popup-section__title">
                    <div class="token-popup-section__title-main">{{ section.title }}</div>
                  </div>
                  <div class="token-popup-section__content">
                    <a
                      *ngFor="let item of section.items; trackBy: trackSectionItemFn"
                      class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                      [class.token-popup-list-item_active]="false"
                      href="javascript:void(0)"
                      (click)="select.emit(item.value)"
                    >
                      <div class="token-popup-list-item__inner">
                        <div class="token-popup-list-item__line">
                          <span *ngIf="item.icon" class="token-popup-list-item__left">
                            <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                          </span>

                          <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                            {{ item.name }}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="!filteredSections.length" class="token-popup-list-item">
                <div class="token-popup-list-item__inner">
                  <div class="token-popup-list-item__tip">Nothing found</div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!filteredSections">
              <app-action-type-dropdown-nested
                *ngIf="hoverSection"
                [value]="value"
                [section]="hoverSection.section"
                [origin]="hoverSection.origin"
                (select)="resetHoverSection(); select.emit($event)"
                (hover)="hoverSection.hover$.next($event)"
              ></app-action-type-dropdown-nested>

              <ng-container *ngFor="let section of sections; trackBy: trackSectionFn">
                <div *ngIf="section.highlight" class="token-popup-list-border"></div>

                <div
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  [class.token-popup-list-item_active]="section.id == activeSection?.id"
                  [class.token-popup-list-item_orange]="section.highlight"
                  cdkOverlayOrigin
                  #dropdownTrigger="cdkOverlayOrigin"
                  (mouseenter)="onSectionHover(section, dropdownTrigger)"
                  (click)="onSectionClick(section)"
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span *ngIf="section.icon" class="token-popup-list-item__left">
                        <span
                          class="token-popup-list-item__icon"
                          [class.token-popup-list-item__icon_orange]="section.highlight"
                          [ngClass]="'icon-' + section.icon"
                        ></span>
                      </span>

                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                        {{ section.title }}
                      </span>

                      <span
                        *ngIf="!section.disableNested"
                        class="token-popup-list-item__right token-popup-list-item__right_wrap"
                      >
                        <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                      </span>
                    </div>
                    <div
                      *ngIf="section.subtitle"
                      class="token-popup-list-item__line"
                      [class.token-popup-list-item__line_active]="false"
                    >
                      <div class="token-popup-list-item__description">
                        {{ section.subtitle | appCapitalize }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

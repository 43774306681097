import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { FieldLabelButton, FormField } from '@modules/fields';

@Component({
  selector: 'app-auto-field-stub',
  templateUrl: './auto-field-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoFieldStubComponent implements OnInit {
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() requiredAsterisk = false;
  @Input() label = true;
  @Input() errors = true;
  @Input() autofocus = false;
  @Input() truncate = false;
  @Input() manualMargin = false;
  @Input() labelButtons: FieldLabelButton[] = [];

  constructor() {}

  ngOnInit() {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotAllowedComponent implements OnInit, OnDestroy {
  error: string;
  errorDescription: string;

  constructor(private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.error = params['error'];
      this.errorDescription = params['error_description'];
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}

<ng-container *ngIf="!loading">
  <ng-container *ngIf="!selectedResource">
    <!--              <div class="choose-template__content-navigation-header">-->
    <!--                <div class="choose-template__content-navigation-title">Choose Collection</div>-->
    <!--              </div>-->

    <div class="choose-template__content-navigation-list">
      <div class="choose-template-list">
        <div *ngFor="let item of resources; let i = index" class="choose-template-list__item">
          <div
            *ngIf="!item.disabled"
            class="choose-template-list-item choose-template-list-item_border choose-template-list-item_interactive"
            [class.choose-template-list-item_active]="selectedResource === item"
            (click)="setSelectedResource(item)"
          >
            <div class="choose-template-list-item__left">
              <span
                class="choose-template-list-item__icon"
                [style.background-image]="
                  '/assets/images/resources/icons/' + item.resource.typeItem.icon + '.svg' | appDeployCssUrl
                "
              ></span>
            </div>

            <div class="choose-template-list-item__main">
              <div class="choose-template-list-item__title">{{ item.resource.name }}</div>
              <div class="choose-template-list-item__subtitle">
                {{ item.models.length | i18nPlural: { '=1': '# collection', other: '# collections' } }}
              </div>
            </div>

            <div class="choose-template-list-item__right">
              <span class="choose-template-list-item__icon icon-arrow_forward_2"></span>
            </div>
          </div>

          <div *ngIf="item.disabled" class="choose-template-list-item choose-template-list2-item_disabled">
            <div class="choose-template-list-item__left">
              <div class="choose-template-list-item__icon icon-warning_filled"></div>
            </div>

            <div class="choose-template-list-item__main">
              <div class="choose-template-list-item__title">{{ item.resource.name }}</div>
              <div *ngIf="item.error" class="choose-template-list2-item__subtitle">{{ item.error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedResource">
    <div class="choose-template__content-navigation-header">
      <!--                <div class="choose-template__content-navigation-title">Choose Collection</div>-->

      <div *ngIf="resource" class="choose-template-list-item choose-template-list-item_contrast">
        <div class="choose-template-list-item__left">
          <span
            class="choose-template-list-item__icon"
            [style.background-image]="
              '/assets/images/resources/icons/' + selectedResource.resource.typeItem.icon + '.svg' | appDeployCssUrl
            "
          ></span>
        </div>

        <div class="choose-template-list-item__main">
          <div class="choose-template-list-item__title">{{ selectedResource.resource.name }}</div>
          <div class="choose-template-list-item__subtitle">
            {{ selectedResource.models.length | i18nPlural: { '=1': '# collection', other: '# collections' } }}
          </div>
        </div>
      </div>

      <div
        *ngIf="!resource"
        class="choose-template-list-item choose-template-list-item_contrast choose-template-list-item_interactive"
        (click)="setSelectedResource(undefined)"
      >
        <div class="choose-template-list-item__left">
          <span class="choose-template-list-item__icon icon-arrow_backward_2"></span>
        </div>

        <div class="choose-template-list-item__main">
          <div class="choose-template-list-item__title">{{ selectedResource.resource.name }}</div>
          <div class="choose-template-list-item__subtitle">
            {{ selectedResource.models.length | i18nPlural: { '=1': '# collection', other: '# collections' } }}
          </div>
        </div>

        <div class="choose-template-list-item__right">
          <span
            class="choose-template-list-item__icon"
            [style.background-image]="
              '/assets/images/resources/icons/' + selectedResource.resource.typeItem.icon + '.svg' | appDeployCssUrl
            "
          ></span>
        </div>
      </div>
    </div>

    <div class="choose-template__content-navigation-list">
      <div class="choose-template-list">
        <div *ngFor="let item of selectedResource.models; let i = index" class="choose-template-list__item">
          <div
            *ngIf="!item.disabled"
            class="choose-template-list-item choose-template-list-item_interactive"
            [class.choose-template-list-item_active]="selectedModel === item"
            (click)="setSelectedModel(item); submit()"
          >
            <div class="choose-template-list-item__left">
              <span class="choose-template-list-item__icon icon-documents"></span>
            </div>

            <div class="choose-template-list-item__main">
              <div class="choose-template-list-item__title">
                {{ item.modelDescription.verboseNamePlural | appCapitalize }}
              </div>
            </div>

            <div class="choose-template-list-item__right">
              <span class="choose-template-list-item__icon icon-arrow_forward_2"></span>
            </div>
          </div>

          <div *ngIf="item.disabled" class="choose-template-list-item choose-template-list2-item_disabled">
            <div class="choose-template-list-item__left">
              <div class="choose-template-list-item__icon icon-warning_filled"></div>
            </div>

            <div class="choose-template-list-item__main">
              <div class="choose-template-list-item__title">
                {{ item.modelDescription.verboseNamePlural | appCapitalize }}
              </div>
              <div *ngIf="item.error" class="choose-template-list2-item__subtitle">{{ item.error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <div class="choose-template__content-navigation-list">
    <div class="choose-template-list">
      <div
        *ngFor="let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']"
        class="choose-template-list__item"
      >
        <div class="choose-template-list-item">
          <div class="choose-template-list-item__main">
            <span [class.loading-animation]="true">
              <span class="stub-text">{{ item }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

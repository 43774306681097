export enum ValidatorType {
  Email = 'email',
  Phone = 'phone',
  Length = 'length',
  MaxLength = 'max_length',
  MinLength = 'min_length',
  LengthRange = 'length_range',
  ValueRange = 'value_range',
  Custom = 'custom',
  CustomInput = 'custom_input'
}

import { FieldLookup, serializeFieldParamName } from '@modules/field-lookups';

export class FilterItem2 {
  field: string[];
  lookup: FieldLookup;
  value: any;
  exclude = false;

  constructor(options: Partial<FilterItem2> = {}) {
    Object.assign(this, options);
  }

  getName(): string {
    return serializeFieldParamName(this.field.join('__'), this.lookup ? this.lookup.lookup : undefined, false);
  }

  serialize() {
    return {
      field: this.field,
      lookup: this.lookup,
      value: this.value,
      exclude: this.exclude
    };
  }
}

import isPlainObject from 'lodash/isPlainObject';
import Delta from 'quill-delta';

import { isSet } from '@shared';

// TODO: Refactor imports
import { Input } from '../../../modules/fields/data/input';

export function quillDeltaToText(
  delta: Delta,
  options: {
    applyContextFormula: (input: Input) => any;
  }
): string {
  if (!delta) {
    return;
  }

  let result = '';

  for (const op of delta.ops) {
    if (isPlainObject(op.insert) && op.insert['context-formula']) {
      const input = new Input().deserialize(op.insert['context-formula']);

      let value: any;

      try {
        value = options.applyContextFormula(input);
      } catch (e) {}

      result += isSet(value) ? value : '';
    } else {
      result += op.insert;
    }
  }

  return result;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { FormArray } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { getFieldDescriptionByType, Input, InputValueType, isInputSet } from '@modules/fields';
import { FieldInputControl, FieldInputRequiredValidator } from '@modules/parameters';
import { controlValue } from '@shared';
var InputsEditForm = /** @class */ (function () {
    function InputsEditForm() {
        this.form = new FormArray([]);
        this.updatedFromControl = new Subject();
    }
    InputsEditForm.prototype.init = function (control, parameterProvider, fieldsControl, context, contextElement) {
        var _this = this;
        this.control = control;
        this.parameterProvider = parameterProvider;
        this.fieldsControl = fieldsControl;
        this.context = context;
        this.contextElement = contextElement;
        this.control.valueChanges.pipe(debounceTime(10), untilDestroyed(this)).subscribe(function () {
            if (_this.updateValueFromControl()) {
                _this.updatedFromControl.next();
            }
        });
        this.updateValueFromControl();
        this.form.valueChanges.pipe(debounceTime(60)).subscribe(function (value) { return _this.updateValueToControl(value); });
    };
    InputsEditForm.prototype.ngOnDestroy = function () { };
    InputsEditForm.prototype.serializeValueItem = function (item) {
        var input = new Input();
        var field = this.parameterProvider.fields.find(function (i) { return i.name == item['name']; });
        if (!field) {
            return;
        }
        input.name = item['name'];
        if (!field.field) {
            input.valueType = InputValueType.StaticValue;
            input.staticValue = true;
        }
        else {
            input.valueType = item['value_type'];
            input.staticValue = item['static_value'];
            if (input.valueType == InputValueType.StaticValue && field) {
                var fieldDescription = getFieldDescriptionByType(field.field);
                if (fieldDescription.serializeValue) {
                    input.staticValue = fieldDescription.serializeValue(input.staticValue, __assign({}, field, { params: __assign({}, field.params, { output_format: undefined }) }));
                }
            }
            input.contextValue = item['context_value'];
            input.filterField = item['filter_field'];
            input.filterLookup = item['filter_lookup'];
            input.formulaValue = item['formula_value'];
            input.textInputsType = item['text_inputs_type'];
            input.textInputsValue = item['text_inputs_value'];
            input.jsValue = item['js_value'];
            input.required = item['required'];
        }
        return input;
    };
    InputsEditForm.prototype.serializeValue = function (value) {
        var _this = this;
        return value
            .filter(function (item) { return item['name']; })
            .map(function (item) { return _this.serializeValueItem(item); })
            .filter(function (item) { return item; });
    };
    InputsEditForm.prototype.deserializeValue = function (value) {
        return value.map(function (item) {
            return {
                name: item.name,
                value_type: item.valueType,
                static_value: item.staticValue,
                context_value: item.contextValue,
                filter_field: item.filterField,
                filter_lookup: item.filterLookup,
                formula_value: item.formulaValue,
                text_inputs_type: item.textInputsType,
                text_inputs_value: item.textInputsValue,
                js_value: item.jsValue,
                required: item.required
            };
        });
    };
    InputsEditForm.prototype.updateValueFromControl = function () {
        var _this = this;
        var value = this.control.value;
        // TODO: Add value equals check
        var createItems = this.parameterProvider.fields
            .filter(function (item) { return item['required'] === true; })
            .filter(function (field) { return value.find(function (item) { return item.name == field.name; }) == undefined; })
            .map(function (field) {
            return {
                name: field.name,
                field: field.field
            };
        }).concat(this.deserializeValue(value).map(function (item) {
            var field = _this.parameterProvider.fields.find(function (i) { return i.name == item['name']; });
            return __assign({ field: field ? field.field : null }, item);
        }));
        if (isEqual(createItems, this.form.value)) {
            return false;
        }
        this.arrayUpdate(createItems);
        return true;
    };
    InputsEditForm.prototype.updateValueToControl = function (value) {
        var currentValue = this.control.value;
        var controlValueObj = this.deserializeValue(currentValue);
        if (isEqual(value, controlValueObj)) {
            return;
        }
        this.control.patchValue(this.serializeValue(value));
    };
    InputsEditForm.prototype.parameter$ = function (form) {
        return combineLatest(controlValue(form.controls['name']), this.parameterProvider.fields$).pipe(map(function (_a) {
            var name = _a[0], fields = _a[1];
            if (!name) {
                return;
            }
            return fields.find(function (item) { return item.name == name; });
        }));
    };
    InputsEditForm.prototype.providerItem$ = function (form) {
        return combineLatest(controlValue(form.controls['name']), this.parameterProvider.value$).pipe(map(function (_a) {
            var name = _a[0], values = _a[1];
            if (!name) {
                return;
            }
            return values.find(function (item) { return item.field && item.field.name == name; });
        }));
    };
    InputsEditForm.prototype.value$ = function (form) {
        return controlValue(form);
    };
    InputsEditForm.prototype.isSet$ = function (form) {
        return this.value$(form).pipe(map(function (value) { return isInputSet(value); }));
    };
    InputsEditForm.prototype.filterFields$ = function () {
        if (!this.fieldsControl) {
            return of([]);
        }
        return controlValue(this.fieldsControl).pipe(map(function (fields) {
            return fields.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    name: item.name,
                    label: item.verboseName || item.name,
                    lookups: fieldDescription
                        ? fieldDescription.lookups.map(function (lookup) {
                            return {
                                name: lookup.type.lookup,
                                label: lookup.type.verboseName || lookup.type.name
                            };
                        })
                        : []
                };
            });
        }));
    };
    InputsEditForm.prototype.createItem = function (value) {
        return new FieldInputControl(value, FieldInputRequiredValidator);
    };
    InputsEditForm.prototype.addItem = function (item) {
        var form = this.createItem({ name: item.field.name, field: item.field.field });
        this.arrayAppend(form);
        return form;
    };
    InputsEditForm.prototype.isItemPristine = function (item) {
        return item.value['value_type'] == InputValueType.StaticValue && item.value['static_value'] === '';
    };
    InputsEditForm.prototype.groupTrackBy = function (item) {
        return item['name'];
    };
    InputsEditForm.prototype.arraySet = function (groups) {
        var _this = this;
        range(this.form.controls.length).forEach(function () { return _this.form.removeAt(0); });
        groups.forEach(function (item) { return _this.form.push(item); });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayUpdate = function (value) {
        var _this = this;
        var existingControls = fromPairs(this.form.controls.map(function (item) { return [_this.groupTrackBy(item.value), item]; }));
        var preserveControls = [];
        value
            .sort(function (lhs, rhs) {
            var isInputSetLhs = isInputSet(lhs) ? 1 : 0;
            var isInputSetRhs = isInputSet(rhs) ? 1 : 0;
            var parameterIndexLhs = _this.parameterProvider.fields.findIndex(function (item) { return item.name == lhs['name']; });
            var parameterIndexRhs = _this.parameterProvider.fields.findIndex(function (item) { return item.name == rhs['name']; });
            var parameterLhs = parameterIndexLhs != -1 ? _this.parameterProvider.fields[parameterIndexLhs] : undefined;
            var parameterRhs = parameterIndexRhs != -1 ? _this.parameterProvider.fields[parameterIndexRhs] : undefined;
            var requiredLhs = parameterLhs && parameterLhs.required ? 1 : 0;
            var requiredRhs = parameterRhs && parameterRhs.required ? 1 : 0;
            return ((requiredLhs - requiredRhs) * 10 * -1 +
                (isInputSetLhs - isInputSetRhs) * -1 +
                (parameterIndexLhs - parameterIndexRhs) / 10);
        })
            .forEach(function (item) {
            var id = _this.groupTrackBy(item);
            var control = existingControls[id];
            if (control) {
                control.patchValue(item);
            }
            else {
                control = _this.createItem(item);
                _this.form.push(control);
            }
            preserveControls.push(control);
        });
        this.form.controls
            .map(function (item, i) { return [item, i]; })
            .filter(function (_a) {
            var item = _a[0], index = _a[1];
            return !preserveControls.includes(item);
        })
            .reverse()
            .forEach(function (_a) {
            var item = _a[0], index = _a[1];
            _this.form.removeAt(index);
        });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayAppend = function () {
        var _this = this;
        var groups = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            groups[_i] = arguments[_i];
        }
        groups.forEach(function (item) { return _this.form.push(item); });
        this.form.updateValueAndValidity();
    };
    InputsEditForm.prototype.arrayRemove = function () {
        var _this = this;
        var groups = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            groups[_i] = arguments[_i];
        }
        groups.forEach(function (item) {
            var index = _this.form.controls.findIndex(function (i) { return i === item; });
            if (index == -1) {
                return;
            }
            _this.form.removeAt(index);
        });
        this.form.updateValueAndValidity();
    };
    return InputsEditForm;
}());
export { InputsEditForm };

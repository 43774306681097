import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { JET_APP_RESOURCE } from '@modules/projects';

import { ApiParameter } from '../../../data/api-parameter';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

@Component({
  selector: 'app-model-api-delete-query',
  templateUrl: './model-api-delete-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiDeleteQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  parameters: ApiParameter[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl(),
      method: 'DELETE'
    });
  }

  getQueryUrl() {
    return `/:${this.getPrimaryKey()}`;
  }
}

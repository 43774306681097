import pickBy from 'lodash/pickBy';

import { ModelDescription } from '@modules/models';
import { HttpQuery, SqlQuery } from '@modules/queries';

export class RestAPIModelDescription {
  modelDescription: ModelDescription;
  getQuery: HttpQuery;
  getSqlQuery: SqlQuery;
  getDetailQuery: HttpQuery;
  getDetailSqlQuery: SqlQuery;
  createQuery: HttpQuery;
  updateQuery: HttpQuery;
  deleteQuery: HttpQuery;
  siblingsQuery: HttpQuery;
  params = {};

  deserialize(data: Object): RestAPIModelDescription {
    if (data['model_description']) {
      this.modelDescription = new ModelDescription().deserialize(data['model_description']);
    }

    if (data['get_query']) {
      this.getQuery = new HttpQuery().deserialize(data['get_query']);
    }

    if (data['get_sql_query']) {
      this.getSqlQuery = new SqlQuery().deserialize(data['get_sql_query']);
    }

    if (data['get_detail_query']) {
      this.getDetailQuery = new HttpQuery().deserialize(data['get_detail_query']);
    }

    if (data['get_detail_sql_query']) {
      this.getDetailSqlQuery = new SqlQuery().deserialize(data['get_detail_sql_query']);
    }

    if (data['create_query']) {
      this.createQuery = new HttpQuery().deserialize(data['create_query']);
    }

    if (data['update_query']) {
      this.updateQuery = new HttpQuery().deserialize(data['update_query']);
    }

    if (data['delete_query']) {
      this.deleteQuery = new HttpQuery().deserialize(data['delete_query']);
    }

    if (data['siblings_query']) {
      this.siblingsQuery = new HttpQuery().deserialize(data['siblings_query']);
    }

    if (data['params']) {
      this.params = data['params'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      model_description: this.modelDescription ? this.modelDescription.serialize() : undefined,
      get_query: this.getQuery ? this.getQuery.serialize() : undefined,
      get_sql_query: this.getSqlQuery ? this.getSqlQuery.serialize() : undefined,
      get_detail_query: this.getDetailQuery ? this.getDetailQuery.serialize() : undefined,
      get_detail_sql_query: this.getDetailSqlQuery ? this.getDetailSqlQuery.serialize() : undefined,
      create_query: this.createQuery ? this.createQuery.serialize() : undefined,
      update_query: this.updateQuery ? this.updateQuery.serialize() : undefined,
      delete_query: this.deleteQuery ? this.deleteQuery.serialize() : undefined,
      siblings_query: this.siblingsQuery ? this.siblingsQuery.serialize() : undefined,
      params: this.params
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';

import { PopupService } from '@common/popups';

import { SlackJoinPopupComponent } from '../../components/slack-join-popup/slack-join-popup.component';

export interface SlackJoinPopupResult {
  cancelled?: boolean;
}

@Injectable()
export class SlackJoinPopupController implements OnDestroy {
  constructor(private injector: Injector, private popupService: PopupService) {}

  ngOnDestroy(): void {}

  open(): Observable<SlackJoinPopupResult> {
    const result = new Subject<SlackJoinPopupResult>();
    const popup = this.popupService.showComponent<SlackJoinPopupComponent>({
      component: SlackJoinPopupComponent,
      injector: this.injector,
      inputs: {},
      scrollable: true
    });

    popup.instance.cancelled.pipe(untilDestroyed(this)).subscribe(() => {
      result.next({ cancelled: true });
    });

    return result.asObservable();
  }
}

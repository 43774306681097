import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';

import { ShareComponent, ShareTab } from '../../components/share/share.component';

export interface ShareOpenResult {
  userDeleted?: boolean;
  inviteAdded?: boolean;
  inviteDeleted?: boolean;
  publicInviteAdded?: boolean;
  publicInviteDeleted?: boolean;
}

@Injectable()
export class ShareController {
  constructor(
    private injector: Injector,
    private popupService: PopupService,
    private resolver: ComponentFactoryResolver
  ) {}

  open(options: { orange?: boolean; initialTab?: ShareTab } = {}): Observable<ShareOpenResult> {
    const result = new Subject<ShareOpenResult>();

    this.popupService.push({
      component: ShareComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentCloseWithoutConfirm: true,
      inputs: {
        orange: options.orange,
        initialTab: options.initialTab
      },
      outputs: {
        userDeleted: [
          () => {
            result.next({
              userDeleted: true
            });
          }
        ],
        inviteAdded: [
          () => {
            result.next({
              inviteAdded: true
            });
          }
        ],
        inviteDeleted: [
          () => {
            result.next({
              inviteDeleted: true
            });
          }
        ],
        publicInviteAdded: [
          () => {
            result.next({
              publicInviteAdded: true
            });
          }
        ],
        publicInviteDeleted: [
          () => {
            result.next({
              publicInviteDeleted: true
            });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appRatio'
})
export class RatioPipe implements PipeTransform {
  transform(value: number, ratioTo: number): any {
    return value / ratioTo - 1;
  }
}

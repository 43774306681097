import { OnInit } from '@angular/core';
var StackComponent = /** @class */ (function () {
    function StackComponent() {
        this.cls = true;
    }
    Object.defineProperty(StackComponent.prototype, "alignLeft", {
        get: function () {
            return this.align == 'left';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackComponent.prototype, "alignCenter", {
        get: function () {
            return this.align == 'center';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackComponent.prototype, "alignRight", {
        get: function () {
            return this.align == 'right';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackComponent.prototype, "alignSpaceBetween", {
        get: function () {
            return this.align == 'space-between';
        },
        enumerable: true,
        configurable: true
    });
    StackComponent.prototype.ngOnInit = function () { };
    return StackComponent;
}());
export { StackComponent };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { AppError, isSet } from '@shared';

export interface TableColumnDescription {
  name: string;
}

export interface TableDescription {
  name: string;
  columns: TableColumnDescription[];
}

export enum AiDatabaseEngine {
  PostgresSQL = 'PostgresSQL',
  MySQL = 'MySQL',
  MicrosoftSQL = 'MicrosoftSQL',
  Oracle = 'Oracle',
  BigQuery = 'BigQuery',
  Snowflake = 'Snowflake',
  SQLite = 'SQLite'
}

@Injectable({
  providedIn: 'root'
})
export class AiService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  getSqlByNaturalText(query: string, tables: TableDescription[], engine?: AiDatabaseEngine): Observable<string> {
    if (!isSet(query)) {
      return of(undefined);
    }

    if (!tables.length) {
      return throwError(new AppError('No tables found in resource'));
    }

    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeMethodURL('ai/convert_text_to_sql');
        let headers = new HttpHeaders();
        const data = {
          query: query,
          tables: tables,
          ...(isSet(engine) && { database_engine: engine })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<{ sql_query: string }>(url, data, { headers: headers });
      }),
      map(result => result.sql_query),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

<td *ngFor="let item of items; trackBy: trackByFn; let i = index" class="table__column">
  <div [class.table__column-inner]="!(item.field.field == fieldTypes.Text && item.field.params['wide'])">
    <ng-container *ngIf="item.stubValue">
      <span class="stub-text">auto</span>
    </ng-container>

    <app-auto-field
      *ngIf="!item.stubValue"
      [field]="createField(item.fieldObj)"
      [label]="false"
      [readonly]="true"
      [style.pointer-events]="'none'"
      [value]="item.value"
      (click)="$event.stopPropagation()"
    >
    </app-auto-field>
  </div>
</td>

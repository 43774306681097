var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var SpacingElementItem = /** @class */ (function (_super) {
    __extends(SpacingElementItem, _super);
    function SpacingElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Spacing;
        return _this;
    }
    SpacingElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.height = this.params['height'];
        return this;
    };
    SpacingElementItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['height'] = this.height;
        return data;
    };
    Object.defineProperty(SpacingElementItem.prototype, "typeStr", {
        get: function () {
            return 'space between containers';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SpacingElementItem.prototype, "analyticsName", {
        get: function () {
            return 'spacing';
        },
        enumerable: true,
        configurable: true
    });
    SpacingElementItem.prototype.defaultName = function () {
        return 'Spacing';
    };
    return SpacingElementItem;
}(ElementItem));
export { SpacingElementItem };
registerElementForType(ElementType.Spacing, SpacingElementItem);

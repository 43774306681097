<ng-container *ngIf="itemForm.value['name']">
  <ng-container
    *ngIf="
      (!itemForm.value['value_type'] || itemForm.value['value_type'] == inputValueTypes.StaticValue) &&
      !staticValueDisabledClean
    "
  >
    <app-auto-field
      *ngIf="staticValueField == fieldTypes.Boolean"
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          field: fieldTypes.Select,
          placeholder: placeholder,
          params: {
            valueEquals: booleanValueEquals,
            options: [
              { value: false, name: 'False' },
              { value: true, name: 'True' }
            ],
            classes: fill ? ['select_fill'] : []
          },
          reset_enabled: resetEnabled
        })
      "
      [autofocus]="focusedInitial"
    ></app-auto-field>

    <app-auto-field
      *ngIf="staticValueField != fieldTypes.Boolean"
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'control',
          placeholder: placeholder,
          field: staticValueField,
          params: staticValueParamsDisplay,
          reset_enabled: resetEnabled
        })
      "
      [context]="context"
      [contextElement]="contextElement"
      [autofocus]="focusedInitial"
    >
    </app-auto-field>

    <div *ngIf="displayValueTypesEnabled" class="button-tags" style="padding-top: 4px;">
      <div class="button-tags__label">or insert</div>
      <ng-container *ngFor="let item of displayValueTypes">
        <div
          *ngIf="item == inputValueTypes.Formula"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToFormula()"
        >
          <span class="button-tag__icon icon-function"></span>
          <span class="button-tag__label">Formula</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.Prompt"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToPrompt()"
        >
          <span class="button-tag__icon icon-edit"></span>
          <span class="button-tag__label">Ask user</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.Null"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToNull()"
        >
          <span class="button-tag__icon icon-deselect"></span>
          <span class="button-tag__label">Null</span>
        </div>

        <div
          *ngIf="item == inputValueTypes.EmptyString"
          class="button-tag button-tags__item"
          [class.button-tag_darker]="darker"
          (click)="switchToEmptyString()"
        >
          <span class="button-tag__icon icon-delete"></span>
          <span class="button-tag__label">Empty</span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      itemForm.value['value_type'] == inputValueTypes.Formula ||
      ((!itemForm.value['value_type'] || itemForm.value['value_type'] == inputValueTypes.StaticValue) &&
        staticValueDisabledClean)
    "
  >
    <div class="input-icon-wrapper" [class.input-icon-wrapper_fill-vertical]="fillVertical">
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-input-edit-formula-value
          [control]="control"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [focusedInitial]="focusedInitial"
          [placeholder]="formulaPlaceholder"
          [extraSections]="extraSections"
          [fill]="fill"
          [fillVertical]="fillVertical"
          [small]="small"
          (popoverOpened)="onEditorOpened()"
          (switchToContext)="switchToContext($event)"
          (switchToTextInputs)="switchToTextInputs()"
          (switchToJs)="switchToJs()"
          (switchToEmptyString)="switchToEmptyString()"
          (switchToNull)="switchToNull()"
          (switchToPrompt)="switchToPrompt()"
          (switchToFieldFilter)="switchToFieldFilter($event.field, $event.lookup)"
        ></app-input-edit-formula-value>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_bottom-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>

    <ng-container *ngIf="!isInputOverlayOpened()">
      <div *ngIf="!staticValueDisabledClean && displayValueTypesEnabled" class="button-tags" style="padding-top: 4px;">
        <div
          class="button-tag button-tags__item button-tags__item_fill"
          [class.button-tag_darker]="darker"
          (click)="switchToDefault()"
        >
          <span class="button-tag__icon icon-function"></span>
          <span class="button-tag__label">Disable Formula</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.TextInputs">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <app-input-edit-text-inputs-value
        *ngIf="!isInputOverlayOpened()"
        [typeControl]="itemForm.controls['text_inputs_type']"
        [valueControl]="itemForm.controls['text_inputs_value']"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [staticValueDisabled]="staticValueDisabledClean"
        [displayValueTypesEnabled]="displayValueTypesEnabled"
        [fill]="fill"
        [fillVertical]="fillVertical"
        [header]="false"
        [darker]="darker"
        [analyticsSource]="analyticsSource"
        (switchToDefault)="switchToDefault()"
        (switchToFormula)="switchToFormula()"
      ></app-input-edit-text-inputs-value>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_after-toolbar-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.controls.value_type.value == inputValueTypes.Js">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-auto-field
          [form]="itemForm"
          [label]="false"
          [field]="
            createField({
              name: 'js_value',
              field: 'CodeField',
              placeholder: jsPlaceholder,
              params: {
                language: 'javascript',
                autogrow: !fillVertical,
                flexible: fillVertical,
                field: true,
                field_contrast: true,
                wrap: true,
                dark: dark,
                classes: [fill ? 'input_fill' : ''].concat(classes || [])
              }
            })
          "
          #js_field
        ></app-auto-field>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <div
        *ngIf="!overlay"
        class="input-expand input-expand_position_top-right input-icon-hover_flex"
        [class.input-expand_active]="isInputOverlayOpened()"
        [class.input-icon-hover]="!isInputOverlayOpened()"
        appInputOverlay
        [appInputOverlayInput]="this"
        (appInputOverlayFinished)="onInputOverlayFinished()"
      >
        <span
          class="input-expand__icon"
          [class.icon-copy]="isInputOverlayOpened()"
          [class.icon-enlarge_expand]="!isInputOverlayOpened()"
        ></span>
        <span class="input-expand__label">Editing in popup</span>
        <span class="input-expand__close icon-close"></span>
      </div>
    </div>

    <ng-container *ngIf="!isInputOverlayOpened()">
      <div *ngIf="jsError" class="input-error input-error_overlay input-error_rounded-bottom">
        <div class="input-error__inner" [title]="jsError">{{ jsError }}</div>
      </div>

      <app-view-context-token-popover-overlay
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [origin]="insert_token_origin"
        [control]="control"
        [extraSections]="extraSections"
        [hideSections]="['functions']"
        (selected)="onJsTokenSelected($event); closeTokenPopover()"
      ></app-view-context-token-popover-overlay>

      <div class="button-tags" style="padding-top: 4px;">
        <div
          class="button-tag button-tags__item button-tags__item_fill"
          [class.button-tag_darker]="darker"
          cdkOverlayOrigin
          #insert_token_origin="cdkOverlayOrigin"
          (click)="openTokenPopover()"
        >
          <span class="button-tag__icon button-tag__icon_text">{{ '{' }}..{{ '}' }}</span>
          <span class="button-tag__label">Insert Input</span>
        </div>

        <ng-container *ngIf="displayValueTypesEnabled">
          <div
            *ngIf="staticValueDisabledClean"
            class="button-tag button-tags__item button-tags__item_fill"
            [class.button-tag_darker]="darker"
            (click)="switchToFormula()"
          >
            <span class="button-tag__icon icon-console"></span>
            <span class="button-tag__label">Disable JavaScript</span>
          </div>

          <div
            *ngIf="!staticValueDisabledClean"
            class="button-tag button-tags__item button-tags__item_fill"
            [class.button-tag_darker]="darker"
            (click)="switchToDefault()"
          >
            <span class="button-tag__icon icon-console"></span>
            <span class="button-tag__label">Disable JavaScript</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.EmptyString">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-delete"></span>
        </span>
        <span class="input-tag__main">Empty String</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.Null">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-deselect"></span>
        </span>
        <span class="input-tag__main">Null</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.Prompt">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToDefault()"
      >
        <span class="input-tag__left">
          <span class="input-tag__icon icon-edit"></span>
        </span>
        <span class="input-tag__main">Ask value from user</span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.Context">
    <app-input-edit-context-value
      [valueControl]="itemForm.controls['context_value']"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [classes]="classes"
      [fill]="fill"
      (edit)="switchToFormula()"
      (reset)="switchToFormula(true)"
    ></app-input-edit-context-value>
  </ng-container>

  <ng-container *ngIf="itemForm.value['value_type'] == inputValueTypes.Filter">
    <div class="input input_container" [class.input_fill]="fill" [ngClass]="classes">
      <a
        href="javascript:void(0)"
        class="input-tag input-tag_fill"
        [appTip]="'Choose another data'"
        (click)="switchToFormula()"
      >
        <span class="input-tag__main">
          <ng-container *ngIf="filterField">
            Filters <span class="icon-arrow_forward_2"></span> {{ filterField.label }}

            <ng-container *ngIf="filterFieldLookup">
              <span class="icon-arrow_forward_2"></span> {{ filterFieldLookup.label }}
            </ng-container>
          </ng-container>
        </span>
        <span class="input-tag__right">
          <span class="input-tag__control input-tag__control_root icon-close"></span>
        </span>
      </a>
    </div>
  </ng-container>
</ng-container>

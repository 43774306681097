<div class="choose-template choose-template_popup">
  <div class="choose-template__header">
    <div class="project-resource-setting__logo" [style.background-image]="'url(' + resource.icon + ')'"></div>
    Choose pages to import
  </div>
  <div class="choose-template__content">
    <div class="choose-template__content-navigation">
      <div class="choose-template__content-navigation-inner">
        <div class="choose-template__content-navigation-list">
          <a
            *ngIf="!selectedAll"
            href="javascript:void(0)"
            class="choose-template-check-button choose-template__select-all"
            (click)="setSelectedAll(true)"
          >
            <span class="choose-template-check-button__icon icon-uncheck"></span>
            <span class="choose-template-check-button__label">Select All</span>
          </a>

          <a
            *ngIf="selectedAll"
            href="javascript:void(0)"
            class="choose-template-check-button choose-template__select-all"
            (click)="setSelectedAll(false)"
          >
            <span class="choose-template-check-button__icon icon-check"></span>
            <span class="choose-template-check-button__label">Deselect All</span>
          </a>

          <div class="choose-template-list2">
            <div *ngFor="let item of items" class="choose-template-list2__item">
              <a
                *ngIf="!item.disabled"
                href="javascript:void(0)"
                class="choose-template-list2-item"
                [class.choose-template-list2-item_active]="item.selected"
                (click)="toggleSelected(item)"
              >
                <div class="choose-template-list2-item__left">
                  <div
                    class="big-square-checkbox big-square-checkbox_orange"
                    [class.big-square-checkbox_checked]="item.selected"
                  ></div>
                </div>
                <div class="choose-template-list2-item__main">
                  <div class="choose-template-list2-item__title">{{ item.page.name | appCapitalize }}</div>
                </div>
              </a>
              <div
                *ngIf="item.disabled"
                class="choose-template-list2-item"
                [class.choose-template-list2-item_disabled]="item.disabled"
                [appTip]="'Go to Data to configure collection queries'"
                [appTipOptions]="{ side: 'top' }"
              >
                <div class="choose-template-list2-item__left">
                  <div class="choose-template-list2-item__icon icon-warning_filled"></div>
                </div>
                <div class="choose-template-list2-item__main">
                  <div class="choose-template-list2-item__title">{{ item.page.name | appCapitalize }}</div>
                  <div *ngIf="item.error" class="choose-template-list2-item__subtitle">{{ item.error }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let item of addModelComponents" class="choose-template__content-navigation-button">
          <button type="button" class="button button_fill button_orange-transparent" (click)="addModel(item)">
            <span class="icon button__icon_left icon-plus_circle button__icon"></span>
            <span class="button__label">{{ item.label }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="choose-template__content-main">
      <div class="choose-template__detail">
        <div class="choose-template__detail-header">
          <div class="choose-template__detail-header-main">
            <!--            <div class="choose-template__detail-title">Pages</div>-->
          </div>
          <div class="choose-template__detail-header-right">
            <div class="choose-template__detail-description">
              Build a custom admin panel based on any databases, apps and APIs like Stripe, Twilio and GraphQL.
            </div>
          </div>
        </div>

        <div
          class="choose-template__detail-image choose-template__detail-image_light choose-template__detail-image_navigation choose-template-video-bounds"
        >
          <div class="choose-template-video">
            <video
              class="choose-template-video__inner"
              [src]="'/assets/video/admin-panel-import-pages.mp4' | appDeployUrl"
              autoplay
              loop
              muted
            ></video>
          </div>
        </div>

        <div class="choose-template__detail-submit choose-template__detail-submit_margin">
          <div class="choose-template__detail-submit-item">
            <a href="javascript:void(0)" class="button button_orange" (click)="cancel()">
              Cancel
            </a>
          </div>

          <div class="choose-template__detail-submit-item">
            <a href="javascript:void(0)" class="button button_primary button_orange" (click)="submit()">
              Continue
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

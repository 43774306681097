import { AfterViewInit, ElementRef, OnDestroy, ViewRef } from '@angular/core';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval, merge, of } from 'rxjs';
import { addClass, getWindowScrollingElement, MouseButton, nodeListToArray, removeClass } from '@shared';
import { DraggableService } from '../../services/draggable/draggable.service';
import { DraggableDirective } from '../draggable/draggable.directive';
var ignoreDragDropProperty = '_ignoreDragDrop';
export function ignoreDragDrop(mouseDownEvent) {
    mouseDownEvent[ignoreDragDropProperty] = true;
}
export function isDragDropIgnored(mouseDownEvent) {
    return !!mouseDownEvent[ignoreDragDropProperty];
}
var DraggableItemDirective = /** @class */ (function () {
    function DraggableItemDirective(el, draggableService, parent) {
        this.el = el;
        this.draggableService = draggableService;
        this.parent = parent;
        this.options = {};
        this.draggingRequested = false;
        this.dragging = false;
        this.elementSubscriptions = [];
        this.copied = false;
    }
    DraggableItemDirective.prototype.ngOnDestroy = function () {
        // this.parent.removeItem(this);
        this.stopScroll();
    };
    DraggableItemDirective.prototype.ngAfterViewInit = function () {
        this.init();
    };
    DraggableItemDirective.prototype.handleMatches = function (el, selector, selectorDisable, end) {
        if (!(el instanceof HTMLElement)) {
            return false;
        }
        if (el['matches'](selector)) {
            return true;
        }
        if (selectorDisable && el['matches'](selectorDisable)) {
            return false;
        }
        if (!el.parentNode || el.parentNode == end) {
            return false;
        }
        return this.handleMatches(el.parentNode, selector, selectorDisable, end);
    };
    DraggableItemDirective.prototype.deinit = function () {
        this.elementSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.elementSubscriptions = [];
    };
    DraggableItemDirective.prototype.init = function () {
        var _this = this;
        this.deinit();
        if (this.dragging) {
            this.el.nativeElement.classList.add('dragging');
        }
        this.elementSubscriptions.push(fromEvent(this.el.nativeElement, 'mousedown')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (isDragDropIgnored(e)) {
                return;
            }
            if (e.button != MouseButton.Main) {
                return;
            }
            if (_this.parent.options['isDraggable'] && !_this.parent.options['isDraggable'](_this, _this.parent)) {
                return;
            }
            if (_this.parent.options['handle'] &&
                !_this.handleMatches(e.target, _this.parent.options['handle'], _this.parent.options['handleDisable'], _this.el.nativeElement)) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            var bounds = _this.el.nativeElement.getBoundingClientRect();
            _this.draggingRequested = true;
            _this.draggingRequestedStartPosition = { x: e.clientX, y: e.clientY };
            _this.draggingStartDelta = { x: e.clientX - bounds.left, y: e.clientY - bounds.top };
        }));
        this.elementSubscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (!_this.draggingRequested) {
                return;
            }
            if (!_this.dragging &&
                (Math.abs(_this.draggingRequestedStartPosition.x - e.clientX) >= 5 ||
                    Math.abs(_this.draggingRequestedStartPosition.y - e.clientY) >= 5)) {
                _this.dragging = true;
                _this.draggingStartPosition = { x: e.clientX, y: e.clientY };
                _this.draggingFrom = _this.parent;
                _this.parent.dragChanged.emit(true);
                _this.parent.dragStarted.emit(_this);
            }
            if (!_this.dragging) {
                return;
            }
            if (!_this.mirror) {
                var mirror = _this.el.nativeElement.cloneNode(true);
                mirror.style.position = 'absolute';
                mirror.style.width = _this.el.nativeElement.offsetWidth + "px";
                mirror.style.height = _this.el.nativeElement.offsetHeight + "px";
                mirror.classList.add('mirror', "draggable-parent_" + _this.parent.name);
                addClass(document.body, 'movable');
                if (_this.options['mirrorClass']) {
                    mirror.classList.add(_this.options['mirrorClass']);
                }
                document.body.appendChild(mirror);
                _this.mirror = mirror;
                _this.el.nativeElement.classList.add('dragging');
                if (mirror.tagName == 'TR') {
                    var originalChildren_1 = nodeListToArray(_this.el.nativeElement.querySelectorAll(':scope > TD'));
                    var mirrorChildren = nodeListToArray(mirror.querySelectorAll(':scope > TD'));
                    mirrorChildren.forEach(function (item, i) {
                        item.style.width = originalChildren_1[i].offsetWidth + "px";
                    });
                }
            }
            _this.mirror.style.top = e.clientY - _this.draggingStartDelta.y + getWindowScrollingElement().scrollTop + "px";
            _this.mirror.style.left = e.clientX - _this.draggingStartDelta.x + getWindowScrollingElement().scrollLeft + "px";
            var droppable = _this.draggableService.items
                .filter(function (item) {
                return item === _this.parent || (_this.parent.options['droppable'] || []).find(function (name) { return name == item.name; });
            })
                .filter(function (item) {
                if (!item.options['isDroppable']) {
                    return true;
                }
                return item.options['isDroppable'](_this, _this.parent);
            })
                .filter(function (item) {
                return !_this.el.nativeElement.contains(item.el.nativeElement);
            })
                .sort(function (lhs, rhs) { return rhs.depth - lhs.depth; })
                .find(function (item) { return item.isPointInside(e.clientX, e.clientY); });
            if (droppable) {
                if (_this.parent !== droppable) {
                    droppable.addExternalItem(_this);
                }
                droppable.updatePositionFor(_this, { mousePosition: { x: e.clientX, y: e.clientY } });
            }
            else {
            }
            if (_this.parent.scrollableParent) {
                var bounds = _this.parent.scrollableParent.getBoundingClientRect();
                var scrollDistance = 80;
                var scrollMultiplierUp = _this.parent.scrollableParent == document.body
                    ? clamp((scrollDistance - e.clientY) / scrollDistance, 0, 1)
                    : clamp((bounds.top + scrollDistance - e.clientY) / scrollDistance, 0, 1);
                var scrollMultiplierDown = _this.parent.scrollableParent == document.body
                    ? clamp(((window.innerHeight - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1)
                    : clamp(((bounds.bottom - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1);
                if (scrollMultiplierUp > 0) {
                    _this.startScroll(false, scrollMultiplierUp);
                }
                else if (scrollMultiplierDown > 0) {
                    _this.startScroll(true, scrollMultiplierDown);
                }
                else {
                    _this.stopScroll();
                }
            }
        }));
        this.elementSubscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.button != MouseButton.Main) {
                return;
            }
            _this.onDragEnd();
        }));
        this.parent.addItem(this, true);
    };
    DraggableItemDirective.prototype.onDragEnd = function () {
        this.draggingRequested = false;
        if (!this.dragging) {
            return;
        }
        this.dragging = false;
        if (this.mirror) {
            this.mirror.parentNode.removeChild(this.mirror);
            this.mirror = undefined;
        }
        removeClass(document.body, 'movable');
        this.el.nativeElement.classList.remove('dragging');
        this.draggingFrom.dragChanged.emit(false);
        this.draggingFrom.dragFinished.emit(this);
        this.draggingFrom = undefined;
        this.copied = false;
        this.stopScroll();
    };
    Object.defineProperty(DraggableItemDirective.prototype, "bounds", {
        get: function () {
            return this.mirror ? this.mirror.getBoundingClientRect() : this.el.nativeElement.getBoundingClientRect();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DraggableItemDirective.prototype, "elementIndex", {
        get: function () {
            if (!this.el.nativeElement.parentNode) {
                return -1;
            }
            return Array.prototype.indexOf.call(this.el.nativeElement.parentNode.children, this.el.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    DraggableItemDirective.prototype.createDirective = function (el, parent) {
        return new DraggableItemDirective(el, this.draggableService, parent);
    };
    DraggableItemDirective.prototype.startScroll = function (down, multiplier) {
        var _this = this;
        this.stopScroll();
        var maxSpeed = 20;
        var speed = down ? multiplier * maxSpeed : multiplier * maxSpeed * -1;
        this.scrollInterval = merge(of({}), interval(1000 / 60))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (!_this.parent.scrollableParent) {
                return;
            }
            if (_this.parent.scrollableParent == document.body) {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed;
            }
            else {
                _this.parent.scrollableParent.scrollTop = _this.parent.scrollableParent.scrollTop + speed;
            }
        });
    };
    DraggableItemDirective.prototype.stopScroll = function () {
        if (this.scrollInterval) {
            this.scrollInterval.unsubscribe();
            this.scrollInterval = undefined;
        }
    };
    return DraggableItemDirective;
}());
export { DraggableItemDirective };

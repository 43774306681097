import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectInvite,
  ProjectInviteService,
  ProjectUserService
} from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-share-invite-item, [app-share-invite-item]',
  templateUrl: './share-invite-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareInviteItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ProjectInvite;
  @Output() deleted = new EventEmitter<void>();

  inviteLink: string;
  deleteLoading = false;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public currentUserStore: CurrentUserStore,
    private projectInviteService: ProjectInviteService,
    private dialogService: DialogService,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private projectUserService: ProjectUserService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.inviteLink = this.getInviteLink();
  }

  getInviteLink(): string {
    const project = this.currentProjectStore.instance;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
    return this.item.link(webBaseUrl);
  }

  copyInviteCode(text: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
      });
  }

  delete(instance: ProjectInvite) {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete invitation for "${instance.getEmail()}"?`
      })
      .pipe(
        filter(result => result == true),
        switchMap(() => {
          this.deleteLoading = true;
          this.cd.markForCheck();
          return this.projectInviteService.delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        users => {
          this.notificationService.success('Deleted', 'Invitation was successfully deleted');
          this.deleteLoading = false;
          this.cd.markForCheck();
          this.deleted.next();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.MemberInviteDeleted, {
            Email: instance.getEmail(),
            Mode: this.mode,
            Source: 'share_popup'
          });

          this.projectUserService.getProject(this.currentProjectStore.instance.uniqueName).subscribe(projectUsers => {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UsersChanged, {
              ProjectID: this.currentProjectStore.instance.uniqueName,
              UsersCount: uniq(projectUsers.map(item => item.getEmail())).length + 1
            });
          });
        },
        () => {
          this.deleteLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  resendInvite(instance: ProjectInvite) {
    return this.projectInviteService
      .resend(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.notificationService.success('Invite was resent', 'Normally it should arrive within 1 minute');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Resend failed', error.errors[0]);
          } else {
            this.notificationService.error('Resend failed', error);
          }
        }
      );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { TabsStyle } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { MarginControl } from '../margin-control/margin-control.component';
import { TabsLayoutItemArray } from './tabs-layout-item.array';
var CustomizeBarTabsEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarTabsEditForm, _super);
    function CustomizeBarTabsEditForm() {
        var _this = _super.call(this, {
            title: new FormControl(''),
            items: new TabsLayoutItemArray([]),
            style: new FormControl(TabsStyle.Wrap),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl(),
            load_invisible: new FormControl(false),
            load_on_show: new FormControl(false)
        }) || this;
        _this.styleOptions = [
            {
                value: TabsStyle.Wrap,
                name: 'Wrap',
                image: 'tabs-style-wrap'
            },
            {
                value: TabsStyle.Background,
                name: 'Background',
                image: 'tabs-style-background'
            }
        ];
        return _this;
    }
    CustomizeBarTabsEditForm.prototype.init = function (element) {
        this.element = element;
        this.controls.title.patchValue(element.name ? element.name : 'Tabs');
        this.controls.style.patchValue(element.style || TabsStyle.Wrap);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serialize() : {});
        this.controls.margin.patchValue(element.margin);
        this.controls.load_invisible.patchValue(element.loadInvisible);
        this.controls.load_on_show.patchValue(element.loadOnShow);
        this.controls.items.deserialize(element.items);
        this.markAsPristine();
    };
    CustomizeBarTabsEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.name = this.controls.title.value;
        instance.items = this.controls.items.serialize();
        instance.style = this.controls.style.value;
        instance.loadOnShow = this.controls.load_on_show.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        instance.loadInvisible = this.controls.load_invisible.value;
        instance.margin = this.controls.margin.value;
        return instance;
    };
    return CustomizeBarTabsEditForm;
}(FormGroup));
export { CustomizeBarTabsEditForm };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./playground-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/drag-handle/drag-handle.token";
import * as i3 from "../../directives/drag-handle/drag-handle.directive";
import * as i4 from "../../directives/drag/drag.token";
import * as i5 from "../../directives/drag/drag.directive";
import * as i6 from "./playground-item.component";
import * as i7 from "../../directives/drop-list/drop-list.directive";
import * as i8 from "../../directives/drop-list-group/drop-list-group.directive";
import * as i9 from "../../services/drop-list/drop-list.service";
import * as i10 from "../../services/scroll/scroll.service";
import * as i11 from "@angular/common";
var styles_PlaygroundItemComponent = [i0.styles];
var RenderType_PlaygroundItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlaygroundItemComponent, data: {} });
export { RenderType_PlaygroundItemComponent as RenderType_PlaygroundItemComponent };
function View_PlaygroundItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["appDragHandle", ""]], null, null, null, null, null)), i1.ɵprd(6144, null, i2.APP_DRAG_HANDLE, null, [i3.AppDragHandle]), i1.ɵdid(2, 4341760, null, 0, i3.AppDragHandle, [i1.ElementRef, [3, i4.APP_DRAG]], null, null), (_l()(), i1.ɵted(-1, null, ["(drag me)"]))], null, null); }
function View_PlaygroundItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "app-playground-item", [["appDrag", ""]], null, [[null, "dropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropped" === en)) {
        var pd_0 = (_co.dropped.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PlaygroundItemComponent_0, RenderType_PlaygroundItemComponent)), i1.ɵprd(6144, null, i4.APP_DRAG, null, [i5.AppDrag]), i1.ɵdid(2, 212992, [[1, 4]], 2, i5.AppDrag, [i1.ElementRef, i1.ViewContainerRef], { data: [0, "data"] }, null), i1.ɵqud(335544320, 2, { placeholderTemplate: 0 }), i1.ɵqud(335544320, 3, { previewTemplate: 0 }), i1.ɵdid(5, 114688, null, 0, i6.PlaygroundItemComponent, [], { item: [0, "item"], handle: [1, "handle"], children: [2, "children"] }, { dropped: "dropped" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.handle; var currVal_3 = _co.children; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PlaygroundItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["appDropList", ""], ["class", "container"]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dropped.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4931584, null, 1, i7.AppDropList, [i1.ElementRef, [2, i8.AppDropListGroup], i1.ChangeDetectorRef, i9.DropListService, i10.ScrollService], { data: [0, "data"] }, { dropped: "appDropListDropped" }), i1.ɵqud(603979776, 1, { draggables: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundItemComponent_3)), i1.ɵdid(4, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.children; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.children; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PlaygroundItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundItemComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlaygroundItemComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.handle; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.item.children; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_PlaygroundItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-playground-item", [], null, null, null, View_PlaygroundItemComponent_0, RenderType_PlaygroundItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.PlaygroundItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlaygroundItemComponentNgFactory = i1.ɵccf("app-playground-item", i6.PlaygroundItemComponent, View_PlaygroundItemComponent_Host_0, { item: "item", handle: "handle", children: "children" }, { dropped: "dropped" }, []);
export { PlaygroundItemComponentNgFactory as PlaygroundItemComponentNgFactory };

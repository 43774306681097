<app-toast [icon]="'eye_2'" [uniqueName]="'demo_project'" [buttonsCenter]="true" [shift]="shift">
  <ng-container data-title>
    This is demo application
  </ng-container>

  <ng-container data-description>
    <p>
      Build your own applications in 5 minutes: Admin Panel, Dashboard, Customer portal
    </p>
  </ng-container>

  <ng-container data-buttons>
    <a *ngIf="!demoUser" [routerLink]="['/projects', 'create']" class="toast2__button">
      <div class="toast2__button-icon icon-hand"></div>
      <div class="toast2__button-label">Get Started</div>
    </a>

    <a
      *ngIf="demoUser"
      [routerLink]="['/register']"
      [queryParams]="{ transfer_projects: project?.uniqueName }"
      class="toast2__button"
    >
      <div class="toast2__button-icon icon-magic_wand"></div>
      <div class="toast2__button-label">Apply Template</div>
    </a>
  </ng-container>
</app-toast>

<table
  class="table"
  [class.table_grid]="grid"
  [class.table_compact]="compact"
  [style.height.px]="height"
  style="overflow: hidden;"
>
  <thead
    *ngIf="header"
    app-table-stub-content-header
    [columns]="columns"
    [animating]="animating"
    [scrollable]="scrollable"
  ></thead>
  <tbody
    app-table-stub-content-body
    [columns]="columns"
    [rows]="rows"
    [rowActions]="rowActions"
    [rowActionsButtons]="rowActionsButtons"
    [selection]="selection"
    [animating]="animating"
    [scrollable]="scrollable"
  ></tbody>
</table>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle, CustomSelectItem } from '@modules/field-components';
import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription } from '@modules/models';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ForeignKeyFieldViewParamsForm } from './foreign-key-field-view-params.form';

export const optionsElementName = '__jet_options_element__';

@Component({
  selector: 'app-foreign-key-field-view-params',
  templateUrl: './foreign-key-field-view-params.component.html',
  providers: [ForeignKeyFieldViewParamsForm, ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForeignKeyFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  primaryKeyTransformerVisible = false;
  booleanFieldStyle = BooleanFieldStyle;
  columnContextElementPath = [ITEM_OUTPUT];
  editable = false;

  resourceModelItems: CustomSelectItem<string>[];
  modelDescription: ModelDescription;
  columnOptions: CustomSelectItem<string>[];

  constructor(
    public form: ForeignKeyFieldViewParamsForm,
    public columnContextElement: ViewContextElement,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, this.context);

    this.columnContextElement.initElement({
      uniqueName: optionsElementName,
      name: 'Fields'
    });

    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.editable = (!this.configurable || this.configurable.editable) && field && field['editable'];
        this.cd.markForCheck();
      });
    }

    combineLatest(this.form.resourceModelItems$(), this.form.modelDescription$(), this.form.columnOptions$())
      .pipe(auditTime(1000 / 60), untilDestroyed(this))
      .subscribe(result => {
        [this.resourceModelItems, this.modelDescription, this.columnOptions] = result;
        this.cd.markForCheck();

        this.columnContextElement.setOutputs([
          {
            uniqueName: ITEM_OUTPUT,
            name: 'Fields',
            internal: true,
            children: this.columnOptions.map(item => {
              return {
                uniqueName: item.option.value,
                name: item.option.name,
                icon: item.option.icon
              };
            })
          }
        ]);
      });
  }

  ngOnDestroy(): void {}

  togglePrimaryKeyTransformerVisible() {
    this.primaryKeyTransformerVisible = !this.primaryKeyTransformerVisible;
    this.cd.markForCheck();
  }
}

registerFieldViewParamsComponent(FieldType.RelatedModel, ForeignKeyFieldViewParamsComponent);

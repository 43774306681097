import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Frame } from '@modules/views';
import { controlValue } from '@shared';

import { ColorControl } from '../../controls/color.control';

@Component({
  selector: 'app-color-button',
  templateUrl: './color-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorButtonComponent implements OnInit, OnDestroy {
  @Input() colorControl: ColorControl;
  @Input() colorInputEnabledControl: FormControl;
  @Input() colorInputControl: FieldInputControl;
  @Input() viewContext: ViewContext;

  colorBackground: string;
  colorOpened = false;
  colorPreviewSize = new Frame({ width: 32 - 2, height: 26 - 2 });
  colorPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ];

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(
      controlValue(this.colorControl).pipe(map(() => this.colorControl.serialize())),
      this.colorInputEnabledControl ? controlValue<boolean>(this.colorInputEnabledControl) : of(false),
      this.colorInputControl
        ? controlValue(this.colorInputControl).pipe(map(() => this.colorInputControl.serialize()))
        : of(undefined)
    )
      .pipe(
        switchMap(([color, colorInputEnabled, colorInput]) => {
          if (colorInputEnabled && colorInput) {
            return applyParamInput$(colorInput, {
              context: this.viewContext,
              defaultValue: ''
            });
          } else if (!colorInputEnabled && color) {
            return of(color.css());
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.colorBackground = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setOpened(value: boolean) {
    this.colorOpened = value;
    this.cd.markForCheck();
  }

  open() {
    this.setOpened(true);
  }

  close() {
    this.setOpened(false);
  }
}

import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ForkWorkflowStep } from '@modules/workflow';

import { ForkCustomizeWorkflowStepItemArray } from './fork-customize-workflow-step-item.array';

function createItemsControl(injector: Injector): ForkCustomizeWorkflowStepItemArray {
  return Injector.create({
    providers: [{ provide: ForkCustomizeWorkflowStepItemArray, deps: [Injector] }],
    parent: injector
  }).get<ForkCustomizeWorkflowStepItemArray>(ForkCustomizeWorkflowStepItemArray);
}

@Injectable()
export class ForkCustomizeWorkflowStepForm extends FormGroup {
  instance: ForkWorkflowStep;

  controls: {
    name: FormControl;
    items: ForkCustomizeWorkflowStepItemArray;
  };

  constructor(private injector: Injector) {
    super({
      name: new FormControl(''),
      items: createItemsControl(injector)
    });
  }

  init(instance: ForkWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.items.init(instance.items);
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): ForkWorkflowStep {
    const instance = cloneDeep(this.instance) as ForkWorkflowStep;

    instance.name = this.controls.name.value;
    instance.items = this.controls.items.submit();

    return instance;
  }
}

<div class="sidebar__element">
  <app-sidebar-field [label]="'actions'" [required]="true">
    <app-actions-edit
      [options]="{
        actionItemClass: viewSettingsActionClass,
        nameEditable: true,
        iconEditable: true,
        approveEnabled: true,
        confirmationEnabled: true,
        completionEditable: true,
        disabledEditable: true,
        visibleEditable: true
      }"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [control]="form.controls.open_action_menu_actions"
      [emptyActionValue]="'Not specified'"
      [emptyAction]="'Dropdown action'"
      [emptyIcon]="'fileds'"
      [actionLabel]="'Dropdown action click'"
      [object]="analyticsSource"
      [classes]="'component-action-button'"
      [firstInit]="firstInit"
    >
    </app-actions-edit>
  </app-sidebar-field>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { CustomViewSettings, ViewContext } from '@modules/customize';
import { isBodyHasChild, isElementHasChild, nodeListToArray, TypedChanges } from '@shared';

import { CustomPageParametersForm } from './custom-page-parameters.form';

const customPageParametersMenuClickEventProperty = '_customPageParametersMenuClickEvent';

export function markCustomPageParametersClickEvent(clickEvent: MouseEvent) {
  clickEvent[customPageParametersMenuClickEventProperty] = true;
}

export function isCustomPageParametersClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[customPageParametersMenuClickEventProperty];
}

@Component({
  selector: 'app-custom-page-parameters',
  templateUrl: './custom-page-parameters.component.html',
  providers: [CustomPageParametersForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPageParametersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() viewSettings: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() opened = false;
  @Output() viewSettingsUpdated = new EventEmitter<CustomViewSettings>();
  @Output() closed = new EventEmitter<void>();

  formSubscription: Subscription;
  blurSubscription: Subscription;
  analyticsSource = 'page_parameters';

  constructor(public form: CustomPageParametersForm, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomPageParametersComponent>): void {
    if (changes.viewSettings) {
      this.initForm();
    }

    if (changes.viewSettings && !changes.viewSettings.firstChange) {
      this.close();
    }

    if (changes.opened && this.opened) {
      this.onOpened();
    } else if (changes.opened && !changes.opened.firstChange && !this.opened) {
      this.onClosed();
    }
  }

  initForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
      this.formSubscription = undefined;
    }

    this.form.init(this.viewSettings);

    this.formSubscription = this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      const result = this.form.submit();
      this.viewSettingsUpdated.emit(result);
    });
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.cd.markForCheck();

    this.onClosed();
  }

  onOpened() {
    setTimeout(() => {
      this.blurSubscription = fromEvent<MouseEvent>(window.document, 'click')
        .pipe(
          filter(e => {
            if (isCustomPageParametersClickEvent(e)) {
              return false;
            }

            const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popups'));
            if (
              overlays.some(overlay => isElementHasChild(overlay, e.target as HTMLElement)) ||
              !isBodyHasChild(e.target as HTMLElement)
            ) {
              return false;
            }

            return true;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.close());
    }, 0);
  }

  onClosed() {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
      this.blurSubscription = undefined;
    }

    this.closed.emit();
  }

  markClickEvent(e: MouseEvent) {
    markCustomPageParametersClickEvent(e);
  }
}

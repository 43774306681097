/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./view-context-token-popover-stub.component";
var styles_ViewContextTokenPopoverStubComponent = [];
var RenderType_ViewContextTokenPopoverStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewContextTokenPopoverStubComponent, data: {} });
export { RenderType_ViewContextTokenPopoverStubComponent as RenderType_ViewContextTokenPopoverStubComponent };
function View_ViewContextTokenPopoverStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "token-popup-list-item"]], [[2, "token-popup-list-item_orange", null], [2, "token-popup-list-item_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], [[2, "token-popup-list-item__main_orange", null], [2, "token-popup-list-item__main_function", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["token item"]))], null, function (_ck, _v) { var currVal_0 = false; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = false; var currVal_3 = false; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = true; _ck(_v, 5, 0, currVal_4); }); }
export function View_ViewContextTokenPopoverStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "token-popup-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "token-popup-search__input"], ["disabled", ""], ["placeholder", "Search..."], ["type", "text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ViewContextTokenPopoverStubComponent_1)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(7, 4)], function (_ck, _v) { var currVal_0 = _ck(_v, 7, 0, 1, 2, 3, 4); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ViewContextTokenPopoverStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-context-token-popover-stub", [], null, null, null, View_ViewContextTokenPopoverStubComponent_0, RenderType_ViewContextTokenPopoverStubComponent)), i0.ɵdid(1, 114688, null, 0, i2.ViewContextTokenPopoverStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewContextTokenPopoverStubComponentNgFactory = i0.ɵccf("app-view-context-token-popover-stub", i2.ViewContextTokenPopoverStubComponent, View_ViewContextTokenPopoverStubComponent_Host_0, {}, {}, []);
export { ViewContextTokenPopoverStubComponentNgFactory as ViewContextTokenPopoverStubComponentNgFactory };

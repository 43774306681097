<div
  class="custom-char"
  [class.custom-char_error]="(form | appFormFieldErrors: field.name:true)?.length"
  [class.custom-char_focus]="field.name | appInputFocused"
>
  <input
    *ngIf="!field.params['multiline']"
    class="custom-char__control"
    [class.custom-char__control_icon]="field.params['icon']"
    [formControl]="control"
    [placeholder]="field.placeholder || ''"
    autocomplete="off"
    [appAutofocus]="autofocus"
    appInputFocusable
    [type]="field.params['type'] || 'text'"
    #input_element
  />

  <textarea
    *ngIf="field.params['multiline']"
    class="custom-char__control custom-char__control_textarea"
    [class.custom-char__control_icon]="field.params['icon']"
    [formControl]="control"
    [placeholder]="field.placeholder || ''"
    autocomplete="off"
    [appAutofocus]="autofocus"
    appInputFocusable
    #input_element
  >
  </textarea>

  <span *ngIf="field.params['icon']" class="custom-char__input-icon">
    <span class="icon" [ngClass]="'icon-' + field.params['icon']"></span>
  </span>

  <span
    *ngIf="(form | appFormFieldErrors: field.name:true)?.length"
    class="custom-char__input-icon custom-char__input-icon_close custom-char__input-icon_right"
    (click)="reset()"
  >
    <span class="icon icon-close"></span>
  </span>

  <div
    class="custom-char__errors"
    [class.custom-char__errors_visible]="(form | appFormFieldErrors: field.name:true)?.length"
    *ngIf="(form | appFormFieldErrors: field.name:true)?.length"
  >
    <div *ngFor="let error of form | appFormFieldErrors: field.name:true" class="custom-char__error">
      {{ error }}
    </div>
  </div>
</div>

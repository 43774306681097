import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';
import { isSet } from '../common/common';
import { objectGet } from '../object/object';
export function defaultComparator(lhs, rhs) {
    if (lhs < rhs) {
        return -1;
    }
    else if (lhs > rhs) {
        return 1;
    }
    else {
        return 0;
    }
}
export function ascComparator(lhs, rhs) {
    if (lhs < rhs) {
        return -1;
    }
    else if (lhs > rhs) {
        return 1;
    }
    else {
        return 0;
    }
}
var typesOrder = ['number', 'bigint', 'string', 'symbol', 'object', 'boolean', 'function', 'undefined'];
export function objectsSortPredicate() {
    var sortBy = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        sortBy[_i] = arguments[_i];
    }
    return function (lhs, rhs) {
        for (var _i = 0, sortBy_1 = sortBy; _i < sortBy_1.length; _i++) {
            var sorting = sortBy_1[_i];
            if (typeof sorting == 'string') {
                var _a = sorting.startsWith('-') ? [sorting.slice(1), false] : [sorting, true], path = _a[0], asc = _a[1];
                var lhsValue = objectGet(lhs, path, null);
                var rhsValue = objectGet(rhs, path, null);
                if (lhsValue === undefined) {
                    lhsValue = null;
                }
                if (rhsValue === undefined) {
                    rhsValue = null;
                }
                if (lhsValue !== null && rhsValue === null) {
                    return asc ? -1 : 1;
                }
                else if (lhsValue === null && rhsValue !== null) {
                    return asc ? 1 : -1;
                }
                else if (lhsValue === null && rhsValue === null) {
                    continue;
                }
                var typesIndexDiff = typesOrder.indexOf(typeof lhsValue) - typesOrder.indexOf(typeof rhsValue);
                var typesSort = asc ? typesIndexDiff : typesIndexDiff * -1;
                if (typesSort != 0) {
                    return typesSort;
                }
                if (lhsValue < rhsValue) {
                    return asc ? -1 : 1;
                }
                else if (lhsValue > rhsValue) {
                    return asc ? 1 : -1;
                }
            }
            else if (isFunction(sorting)) {
                var result = sorting(lhs, rhs);
                if (result != 0) {
                    return result;
                }
            }
        }
        return 0;
    };
}
export function objectsListSort(list, property) {
    return function (lhs, rhs) {
        var lhsIndex = list.indexOf(property(lhs));
        var rhsIndex = list.indexOf(property(rhs));
        return lhsIndex < rhsIndex ? -1 : lhsIndex == rhsIndex ? 0 : 1;
    };
}
export function sortUsingAfterItem(options) {
    if (options.defaultSort) {
        options.items = options.items.sort(options.defaultSort);
    }
    var itemsByAfterItem = options.items.reduce(function (acc, item) {
        var afterItem = options.getAfterItem(item);
        var key = isSet(afterItem) ? afterItem : '';
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
    var result = [];
    var appendItemsAfter = function (afterItem) {
        if (!itemsByAfterItem[afterItem]) {
            return;
        }
        result.push.apply(result, itemsByAfterItem[afterItem]);
        var itemsNested = itemsByAfterItem[afterItem];
        delete itemsByAfterItem[afterItem];
        itemsNested.forEach(function (item) {
            var itemId = options.getItemId(item);
            appendItemsAfter(itemId);
        });
    };
    var findMostBeforeItem = function (afterItemId, acc) {
        if (acc === void 0) { acc = {}; }
        // Found cyclic afterItemId
        if (acc.hasOwnProperty(afterItemId)) {
            return afterItemId;
        }
        var beforeItem = options.items.find(function (item) { return options.getItemId(item) == afterItemId; });
        var beforeItemAfterItem = beforeItem ? options.getAfterItem(beforeItem) : undefined;
        acc[afterItemId] = true;
        if (isSet(beforeItemAfterItem)) {
            return findMostBeforeItem(beforeItemAfterItem, acc);
        }
        else {
            return afterItemId;
        }
    };
    while (keys(itemsByAfterItem).length) {
        if (itemsByAfterItem['']) {
            appendItemsAfter('');
        }
        else {
            var firstKey = keys(itemsByAfterItem).find(function (key) { return !!options.items.find(function (item) { return options.getItemId(item) == key; }); }) ||
                keys(itemsByAfterItem)[0];
            var parentKey = findMostBeforeItem(firstKey);
            appendItemsAfter(parentKey);
        }
    }
    return result;
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var BarCodeFormat;
(function (BarCodeFormat) {
    BarCodeFormat["CODE128"] = "CODE128";
    BarCodeFormat["CODE128A"] = "CODE128A";
    BarCodeFormat["CODE128B"] = "CODE128B";
    BarCodeFormat["CODE128C"] = "CODE128C";
    BarCodeFormat["EAN13"] = "EAN13";
    BarCodeFormat["EAN8"] = "EAN8";
    BarCodeFormat["EAN5"] = "EAN5";
    BarCodeFormat["EAN2"] = "EAN2";
    BarCodeFormat["UPC"] = "UPC";
    BarCodeFormat["CODE39"] = "CODE39";
    BarCodeFormat["ITF14"] = "ITF14";
    BarCodeFormat["ITF"] = "ITF";
    BarCodeFormat["MSI"] = "MSI";
    BarCodeFormat["MSI10"] = "MSI10";
    BarCodeFormat["MSI11"] = "MSI11";
    BarCodeFormat["MSI1010"] = "MSI1010";
    BarCodeFormat["MSI1110"] = "MSI1110";
    BarCodeFormat["Pharmacode"] = "pharmacode";
    BarCodeFormat["Codabar"] = "codabar";
})(BarCodeFormat || (BarCodeFormat = {}));
var BarCodeElementItem = /** @class */ (function (_super) {
    __extends(BarCodeElementItem, _super);
    function BarCodeElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.BarCode;
        _this.format = BarCodeFormat.CODE128;
        _this.backgroundColor = 'white';
        _this.displayText = true;
        return _this;
    }
    BarCodeElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.format = this.params['format'];
        this.height = this.params['height'];
        this.fillColor = this.params['fill_color'];
        this.backgroundColor = this.params['background_color'];
        if (this.params['value']) {
            this.value = new Input().deserialize(this.params['value']);
        }
        if (this.params.hasOwnProperty('display_text')) {
            this.displayText = this.params['display_text'];
        }
        return this;
    };
    BarCodeElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['format'] = this.format;
        this.params['value'] = this.value ? this.value.serialize() : null;
        this.params['height'] = this.height;
        this.params['fill_color'] = this.fillColor;
        this.params['background_color'] = this.backgroundColor;
        this.params['display_text'] = this.displayText;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(BarCodeElementItem.prototype, "typeStr", {
        get: function () {
            return 'bar code';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BarCodeElementItem.prototype, "analyticsName", {
        get: function () {
            return 'bar_code';
        },
        enumerable: true,
        configurable: true
    });
    BarCodeElementItem.prototype.defaultName = function () {
        return 'Bar code';
    };
    return BarCodeElementItem;
}(ElementItem));
export { BarCodeElementItem };
registerElementForType(ElementType.BarCode, BarCodeElementItem);

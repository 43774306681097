/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../model-components/components/model-create/model-create.component.ngfactory";
import * as i2 from "../../../../model-components/components/model-create/model-create.form";
import * as i3 from "../../../../model-queries/stores/model-description.store";
import * as i4 from "../../../../action-queries/stores/action.store";
import * as i5 from "../../../../model-queries/services/model-description/model-description.service";
import * as i6 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i7 from "../../../../../common/form-utils/services/form-utils";
import * as i8 from "../../../../template/services/template/template.service";
import * as i9 from "../../../../projects/stores/current-project.store";
import * as i10 from "../../../../projects/stores/current-environment.store";
import * as i11 from "../../../../model-components/components/model-create/model-create.component";
import * as i12 from "../../../../../common/popups/components/base-popup/base-popup.component";
import * as i13 from "./jet-database-create-table.component";
import * as i14 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_JetDatabaseCreateTableComponent = [];
var RenderType_JetDatabaseCreateTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JetDatabaseCreateTableComponent, data: {} });
export { RenderType_JetDatabaseCreateTableComponent as RenderType_JetDatabaseCreateTableComponent };
export function View_JetDatabaseCreateTableComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-model-create", [], null, [[null, "cancelled"], [null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelled" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("created" === en)) {
        var pd_1 = (_co.finish($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ModelCreateComponent_0, i1.RenderType_ModelCreateComponent)), i0.ɵprd(512, null, i2.ModelCreateForm, i2.ModelCreateForm, [i3.ModelDescriptionStore, i4.ActionStore, i5.ModelDescriptionService, i6.ResourceControllerService, i7.FormUtils, i8.TemplateService, i9.CurrentProjectStore, i10.CurrentEnvironmentStore]), i0.ɵdid(2, 4440064, null, 0, i11.ModelCreateComponent, [i2.ModelCreateForm, [2, i12.BasePopupComponent], i0.ChangeDetectorRef], { resource: [0, "resource"], options: [1, "options"] }, { cancelled: "cancelled", created: "created" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.options; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_JetDatabaseCreateTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-jet-database-create-table", [], null, null, null, View_JetDatabaseCreateTableComponent_0, RenderType_JetDatabaseCreateTableComponent)), i0.ɵdid(1, 114688, null, 0, i13.JetDatabaseCreateTableComponent, [[2, i12.BasePopupComponent], i14.UniversalAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JetDatabaseCreateTableComponentNgFactory = i0.ɵccf("app-jet-database-create-table", i13.JetDatabaseCreateTableComponent, View_JetDatabaseCreateTableComponent_Host_0, { resource: "resource", analyticsSource: "analyticsSource" }, { cancelled: "cancelled", added: "added" }, []);
export { JetDatabaseCreateTableComponentNgFactory as JetDatabaseCreateTableComponentNgFactory };

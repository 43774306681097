import { Pipe, PipeTransform } from '@angular/core';
import last from 'lodash/last';

@Pipe({
  name: 'appFileNameFromPath'
})
export class FileNameFromPathPipe implements PipeTransform {
  transform(value: string): string {
    return last(value.split('/').filter(item => item));
  }
}

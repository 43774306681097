import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MenuModule } from '@modules/menu';
import { MetaModule } from '@modules/meta';

import { NavigationService } from './services/navigation/navigation.service';

@NgModule({
  imports: [CommonModule, MenuModule, MetaModule],
  declarations: []
})
export class NavigationModule {
  constructor(private navigationService: NavigationService) {}
}

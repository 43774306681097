/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i3 from "../../../../shared/components/loader-small/loader-small.component";
import * as i4 from "./sidebar-submit.component";
var styles_SidebarSubmitComponent = [];
var RenderType_SidebarSubmitComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarSubmitComponent, data: {} });
export { RenderType_SidebarSubmitComponent as RenderType_SidebarSubmitComponent };
function View_SidebarSubmitComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar__submit-stub"]], null, null, null, null, null))], null, null); }
function View_SidebarSubmitComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "field__error"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarSubmitComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "field__errors sidebar-submit__errors"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarSubmitComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "button button_orange-alternative button_fill sidebar-submit__buttons-item"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveLoading; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.cancelLabel; _ck(_v, 2, 0, currVal_1); }); }
function View_SidebarSubmitComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "button button_primary button_orange button_fill sidebar-submit__buttons-item"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveLoading; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.backLabel; _ck(_v, 2, 0, currVal_1); }); }
function View_SidebarSubmitComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "button button_danger button_fill sidebar-submit__buttons-item"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveLoading; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarSubmitComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "button__icon button__icon_left"]], null, null, null, i2.View_LoaderSmallComponent_0, i2.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SidebarSubmitComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "button button_primary button_orange button_fill sidebar-submit__buttons-item"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_9)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.saveLoading; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.saveDisabled || _co.saveLoading); _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.saveLabel; _ck(_v, 4, 0, currVal_2); }); }
function View_SidebarSubmitComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "sidebar__submit sidebar-submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "sidebar-submit__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_7)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_8)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.errors == null) ? null : _co.errors.length); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.cancelEnabled; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.backEnabled; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.deleteEnabled; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.saveEnabled; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_SidebarSubmitComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarSubmitComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.visible; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SidebarSubmitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-submit", [], null, null, null, View_SidebarSubmitComponent_0, RenderType_SidebarSubmitComponent)), i0.ɵdid(1, 770048, null, 0, i4.SidebarSubmitComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarSubmitComponentNgFactory = i0.ɵccf("app-sidebar-submit", i4.SidebarSubmitComponent, View_SidebarSubmitComponent_Host_0, { saveEnabled: "saveEnabled", saveDisabled: "saveDisabled", saveLoading: "saveLoading", saveLabel: "saveLabel", backEnabled: "backEnabled", backLabel: "backLabel", deleteEnabled: "deleteEnabled", cancelEnabled: "cancelEnabled", errors: "errors", cancelLabel: "cancelLabel" }, { cancelClick: "cancelClick", deleteClick: "deleteClick" }, []);
export { SidebarSubmitComponentNgFactory as SidebarSubmitComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { ChartWidgetGroupControl } from './chart-widget-group.control';
var ChartWidgetGroupArray = /** @class */ (function (_super) {
    __extends(ChartWidgetGroupArray, _super);
    function ChartWidgetGroupArray() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartWidgetGroupArray.prototype.deserialize = function (instances) {
        var controls = instances.map(function (item) { return new ChartWidgetGroupControl().deserialize(item); });
        this.set(controls);
    };
    ChartWidgetGroupArray.prototype.serialize = function () {
        return this.controls.map(function (item) { return item.serialize(); });
    };
    ChartWidgetGroupArray.prototype.set = function (controls) {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
        controls.forEach(function (item) { return _this.push(item); });
        this.updateValueAndValidity();
    };
    ChartWidgetGroupArray.prototype.append = function (control) {
        this.push(control);
        this.updateValueAndValidity();
    };
    ChartWidgetGroupArray.prototype.remove = function (control) {
        var index = this.controls.findIndex(function (item) { return item === control; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    return ChartWidgetGroupArray;
}(FormArray));
export { ChartWidgetGroupArray };

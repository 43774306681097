import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { CHART_COLORS } from '@modules/charts';
import { createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, TypedChanges } from '@shared';

import { ChartWidgetGroupControl } from '../chart-widget-group.control';

@Component({
  selector: 'app-chart-widget-edit-dataset',
  templateUrl: './chart-widget-edit-dataset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartWidgetEditDatasetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: ChartWidgetGroupControl;
  @Input() openedInitial = false;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() effectiveColor: string;
  @Input() defaultColor: string;
  @Output() remove = new EventEmitter<void>();

  createField = createFormFieldFactory();
  opened = false;
  initCollapseContextSubscription: Subscription;
  colors = CHART_COLORS;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.group.controls.removable)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ChartWidgetEditDatasetComponent>): void {
    if (changes.collapseContext) {
      this.initCollapseContext();
    }
  }

  initCollapseContext() {
    if (this.initCollapseContextSubscription) {
      this.initCollapseContextSubscription.unsubscribe();
      this.initCollapseContextSubscription = undefined;
    }

    if (this.collapseContext) {
      this.initCollapseContextSubscription = this.collapseContext.opened$
        .pipe(untilDestroyed(this))
        .subscribe(opened => {
          this.opened = opened === this;
          this.cd.markForCheck();
        });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  setOpened(opened: boolean) {
    if (this.collapseContext) {
      if (opened && this.collapseContext.opened !== this) {
        this.collapseContext.opened = this;
      } else if (!opened && this.collapseContext.opened === this) {
        this.collapseContext.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }
}

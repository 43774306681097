<div
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="true"
  [class.sidebar-collapse_block]="true"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="false"
  [ngClass]="classes"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
>
  <div
    class="sidebar-collapse__header"
    [class.sidebar-collapse__header_error]="false"
    [appTip]="undefined"
    (click)="toggleOpened()"
  >
    <div class="sidebar-collapse__header-left">
      <div class="sidebar-collapse__header-drag"></div>

      <ng-container *ngIf="configurable.field && !configurable.params">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
          [cdkConnectedOverlayPositions]="fieldTypeDropdownPositions"
          [cdkConnectedOverlayOrigin]="dropdownTrigger"
          [cdkConnectedOverlayOpen]="fieldTypeDropdownOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          (backdropClick)="setFieldTypeDropdownOpened(false)"
          (detach)="setFieldTypeDropdownOpened(false)"
          #field_type_overlay
        >
          <app-field-type-dropdown
            class="overlay-content overlay-content_animated"
            [currentValue]="control.controls.field.value"
            (select)="setFieldTypeDropdownOpened(false); control.controls.field.patchValue($event)"
          ></app-field-type-dropdown>
        </ng-template>

        <a
          href="javascript:void(0)"
          class="button button_icon button_orange button_extra-small"
          cdkOverlayOrigin
          #dropdownTrigger="cdkOverlayOrigin"
          (click)="$event.stopPropagation(); setFieldTypeDropdownOpened(true)"
          style="margin: 0 0 0 8px;"
        >
          <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
        </a>
      </ng-container>
    </div>
    <div class="sidebar-collapse__header-main">
      <div
        *ngIf="configurable.name && opened"
        class="sidebar-collapse__header-title sidebar-collapse__header-title_editable"
      >
        <input
          *ngIf="rename"
          class="editable-content-input"
          [class.editable-content-input_inline]="false"
          [formControl]="renameControl"
          [appAutofocus]="true"
          (keyup)="onKeyUp($event)"
          (keypress)="onKeyPress($event)"
          (blur)="setRename(false)"
          (click)="$event.stopPropagation()"
        />

        <span
          *ngIf="!rename"
          class="editable-content-label"
          [class.editable-content-label_inline]="true"
          [class.editable-content-label_with-button]="true"
          (click)="$event.stopPropagation(); setRename(true)"
        >
          {{ control.controls.verboseName.value || control.controls.name.value }}

          <span
            *ngIf="hovered$ | async"
            class="editable-content-button"
            [appTip]="'Rename parameter'"
            [appTipOptions]="{ side: 'top' }"
          >
            <span class="icon icon-pen"></span>
          </span>
        </span>
      </div>

      <div *ngIf="!configurable.name || !opened" class="sidebar-collapse__header-title">
        {{ control.controls.verboseName.value || control.controls.name.value }}
      </div>

      <div
        *ngIf="description && !opened"
        class="sidebar-collapse__header-description"
        [class.sidebar-collapse__header-description_wrap]="true"
      >
        {{ description }}
      </div>
    </div>
    <div class="sidebar-collapse__header-right">
      <a
        *ngIf="configurable.required"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-check_4"
        [class.sidebar-list-item__action_active]="control.controls.required.value"
        [appTip]="control.controls.required.value ? 'Make Optional' : 'Make Required'"
        [appTipOptions]="{ side: 'top' }"
        (click)="$event.stopPropagation(); control.controls.required.patchValue(!control.controls.required.value)"
      >
      </a>

      <a
        *ngIf="configurable.add"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); deleteRequested.emit()"
      >
      </a>

      <!--      <span-->
      <!--        *ngIf="false"-->
      <!--        class="sidebar-collapse__header-icon sidebar-collapse__header-icon_error icon-warning_filled"-->
      <!--      ></span>-->
      <!--      <span-->
      <!--        *ngIf="true"-->
      <!--        class="sidebar-collapse__header-icon sidebar-collapse__header-icon_arrow icon-arrow_forward_2"-->
      <!--      ></span>-->
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content" [class.sidebar-collapse__content_padding]="true">
    <app-input-edit
      *ngIf="itemForm && itemForm.value['field']"
      [itemForm]="itemForm"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueField]="control.controls.field.value"
      [staticValueParams]="control.controls.params.value"
      [staticValueDisabled]="staticValueDisabled"
      [userInput]="userInput"
      [focusedInitial]="focusedInitial"
      [placeholder]="placeholder"
      [formulaPlaceholder]="formulaPlaceholder"
      [jsPlaceholder]="jsPlaceholder"
      [displayValueTypes]="displayValueTypes"
      [fill]="true"
      [analyticsSource]="analyticsSource"
    ></app-input-edit>

    <app-alert *ngIf="warning" [warning]="true" style="margin-top: 10px;">
      <span [innerHTML]="warning"></span>
    </app-alert>
  </div>
</div>

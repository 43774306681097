/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/browser-header/browser-header.component.ngfactory";
import * as i2 from "../../../../ui/components/browser-header/browser-header.component";
import * as i3 from "../../../customize-components/components/container-builder-stub/container-builder-stub.component.ngfactory";
import * as i4 from "../../../customize-components/components/container-builder-stub/container-builder-stub.component";
import * as i5 from "./page-template-preview-custom.component";
var styles_PageTemplatePreviewCustomComponent = [];
var RenderType_PageTemplatePreviewCustomComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewCustomComponent, data: {} });
export { RenderType_PageTemplatePreviewCustomComponent as RenderType_PageTemplatePreviewCustomComponent };
export function View_PageTemplatePreviewCustomComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "choose-template__detail-browser"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "browser-window"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "browser-window__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-browser-header", [], null, null, null, i1.View_BrowserHeaderComponent_0, i1.RenderType_BrowserHeaderComponent)), i0.ɵdid(4, 114688, null, 0, i2.BrowserHeaderComponent, [], { address: [0, "address"], addressIconHasDefault: [1, "addressIconHasDefault"], addressSearchIcon: [2, "addressSearchIcon"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "browser-window__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "browser-window__content-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "browser-window__center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-container-builder-stub", [], [[2, "container-stub", null], [2, "container-stub_fill", null]], null, null, i3.View_ContainerBuilderStubComponent_0, i3.RenderType_ContainerBuilderStubComponent)), i0.ɵdid(9, 114688, null, 0, i4.ContainerBuilderStubComponent, [], null, null)], function (_ck, _v) { var currVal_0 = "Blank"; var currVal_1 = false; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 9).cls; var currVal_4 = i0.ɵnov(_v, 9).marginSizeS; _ck(_v, 8, 0, currVal_3, currVal_4); }); }
export function View_PageTemplatePreviewCustomComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-template-preview-custom", [], null, null, null, View_PageTemplatePreviewCustomComponent_0, RenderType_PageTemplatePreviewCustomComponent)), i0.ɵdid(1, 114688, null, 0, i5.PageTemplatePreviewCustomComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatePreviewCustomComponentNgFactory = i0.ɵccf("app-page-template-preview-custom", i5.PageTemplatePreviewCustomComponent, View_PageTemplatePreviewCustomComponent_Host_0, {}, {}, []);
export { PageTemplatePreviewCustomComponentNgFactory as PageTemplatePreviewCustomComponentNgFactory };

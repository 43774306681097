export const intercomResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'contacts',
    verbose_name: 'Contacts',
    verbose_name_plural: 'Contacts',
    fields: [
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'workspace_id',
        verbose_name: 'workspace id',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external id',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role',
        verbose_name: 'role',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'avatar',
        verbose_name: 'avatar',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner id',
        field: 'number',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'social_profiles',
        verbose_name: 'social profiles',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'has_hard_bounced',
        verbose_name: 'has hard bounced',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'marked_email_as_spam',
        verbose_name: 'marked email as spam',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'unsubscribed_from_emails',
        verbose_name: 'unsubscribed from emails',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'signed_up_at',
        verbose_name: 'signed up at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'last_seen_at',
        verbose_name: 'last seen at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'last_replied_at',
        verbose_name: 'last replied at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'last_contacted_at',
        verbose_name: 'last contacted at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'last_email_opened_at',
        verbose_name: 'last email opened at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'last_email_clicked_at',
        verbose_name: 'last email clicked at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'language_override',
        verbose_name: 'language override',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser',
        verbose_name: 'browser',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser_version',
        verbose_name: 'browser version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser_language',
        verbose_name: 'browser language',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'os',
        verbose_name: 'os',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_app_name',
        verbose_name: 'android app name',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_app_version',
        verbose_name: 'android app version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_device',
        verbose_name: 'android device',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_os_version',
        verbose_name: 'android os version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_sdk_version',
        verbose_name: 'android sdk version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_last_seen_at',
        verbose_name: 'android last seen at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'ios_app_name',
        verbose_name: 'ios app name',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_app_version',
        verbose_name: 'ios app version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_device',
        verbose_name: 'ios device',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_os_version',
        verbose_name: 'ios os version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_sdk_version',
        verbose_name: 'ios sdk version',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_last_seen_at',
        verbose_name: 'ios last seen at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'custom_attributes',
        verbose_name: 'custom attributes',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes',
        verbose_name: 'notes',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'companies',
        verbose_name: 'companies',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'sms_consent',
        verbose_name: 'sms_consent',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'unsubscribed_from_sms',
        verbose_name: 'unsubscribed_from_sms',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'List of all contacts.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.intercom.io/contacts',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'per_page',
            value: '{{paging.limit}}'
          },
          {
            name: 'Intercom-Version',
            value: '2.5'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['pages']['page'] > data['pages']['total_pages']",
      pagination_total_function: "// ex. data['count']\nreturn data['total_count']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['pages']['next'] ? data['pages']['next']['starting_after'] : undefined",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'os',
          sortable: true
        },
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'role',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'type',
          sortable: true
        },
        {
          name: 'email',
          sortable: true
        },
        {
          name: 'notes',
          sortable: true
        },
        {
          name: 'phone',
          sortable: true
        },
        {
          name: 'avatar',
          sortable: true
        },
        {
          name: 'browser',
          sortable: true
        },
        {
          name: 'location',
          sortable: true
        },
        {
          name: 'owner_id',
          sortable: true
        },
        {
          name: 'companies',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'ios_device',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'external_id',
          sortable: true
        },
        {
          name: 'sms_consent',
          sortable: true
        },
        {
          name: 'ios_app_name',
          sortable: true
        },
        {
          name: 'last_seen_at',
          sortable: true
        },
        {
          name: 'signed_up_at',
          sortable: true
        },
        {
          name: 'workspace_id',
          sortable: true
        },
        {
          name: 'android_device',
          sortable: true
        },
        {
          name: 'ios_os_version',
          sortable: true
        },
        {
          name: 'browser_version',
          sortable: true
        },
        {
          name: 'ios_app_version',
          sortable: true
        },
        {
          name: 'ios_sdk_version',
          sortable: true
        },
        {
          name: 'last_replied_at',
          sortable: true
        },
        {
          name: 'social_profiles',
          sortable: true
        },
        {
          name: 'android_app_name',
          sortable: true
        },
        {
          name: 'browser_language',
          sortable: true
        },
        {
          name: 'has_hard_bounced',
          sortable: true
        },
        {
          name: 'ios_last_seen_at',
          sortable: true
        },
        {
          name: 'custom_attributes',
          sortable: true
        },
        {
          name: 'language_override',
          sortable: true
        },
        {
          name: 'last_contacted_at',
          sortable: true
        },
        {
          name: 'android_os_version',
          sortable: true
        },
        {
          name: 'android_app_version',
          sortable: true
        },
        {
          name: 'android_sdk_version',
          sortable: true
        },
        {
          name: 'android_last_seen_at',
          sortable: true
        },
        {
          name: 'last_email_opened_at',
          sortable: true
        },
        {
          name: 'marked_email_as_spam',
          sortable: true
        },
        {
          name: 'last_email_clicked_at',
          sortable: true
        },
        {
          name: 'unsubscribed_from_sms',
          sortable: true
        },
        {
          name: 'unsubscribed_from_emails',
          sortable: true
        }
      ]
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.intercom.io/contacts/{{params.contact_id}}',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'contact_id',
        verbose_name: 'contact_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.intercom.io/contacts ',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n\t"role": "{{params.role}}",\r\n\t"external_id": "{{params.external_id}}",\r\n\t"email": "{{params.email}}",\r\n\t"phone": "{{params.phone}}",\r\n\t"name": "{{params.name}}",\r\n\t"avatar": "{{params.avatar}}",\r\n\t"last_seen_at": "{{params.last_seen_at}}",\r\n\t"signed_up_at": "{{params.signed_up_at}}",\r\n\t"owner_id": "{{params.owner_id}}",\r\n\t"unsubscribed_from_emails": "{{params.unsubscribed_from_emails}}"\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'role',
        verbose_name: 'role',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'avatar',
        verbose_name: 'avatar',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'last_seen_at',
        verbose_name: 'last_seen_at',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'signed_up_at',
        verbose_name: 'signed_up_at',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'unsubscribed_from_emails',
        verbose_name: 'unsubscribed_from_emails',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.intercom.io/contacts/{{params.contact_id}}',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n\t"role": "{{params.role}}",\r\n\t"external_id": "{{params.external_id}}",\r\n\t"email": "{{params.email}}",\r\n\t"phone": "{{params.phone}}",\r\n\t"name": "{{params.name}}",\r\n\t"avatar": "{{params.avatar}}",\r\n\t"last_seen_at": "{{params.last_seen_at}}",\r\n\t"signed_up_at": "{{params.signed_up_at}}",\r\n\t"owner_id": "{{params.owner_id}}",\r\n\t"unsubscribed_from_emails": "{{params.unsubscribed_from_emails}}"\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'contact_id',
        verbose_name: 'contact_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'role',
        verbose_name: 'role',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'avatar',
        verbose_name: 'avatar',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'last_seen_at',
        verbose_name: 'last_seen_at',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'signed_up_at',
        verbose_name: 'signed_up_at',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'unsubscribed_from_emails',
        verbose_name: 'unsubscribed_from_emails',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.intercom.io/contacts/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'contact_id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'conversations',
    verbose_name: 'Conversations',
    verbose_name_plural: 'Conversations',
    fields: [
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'waiting_since',
        verbose_name: 'waiting since',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'snoozed_until',
        verbose_name: 'snoozed until',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'contacts',
        verbose_name: 'contacts',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_contact_reply',
        verbose_name: 'first contact reply',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'admin_assignee_id',
        verbose_name: 'admin_assignee_id',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'team_assignee_id',
        verbose_name: 'team_assignee_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'open',
        verbose_name: 'open',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'state',
        verbose_name: 'state',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'read',
        verbose_name: 'read',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'sla_applied',
        verbose_name: 'sla applied',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statistics',
        verbose_name: 'statistics',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'conversation_rating',
        verbose_name: 'conversation rating',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'teammates',
        verbose_name: 'teammates',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'title',
        verbose_name: 'title',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Lists all conversations on your Intercom.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.intercom.io/conversations',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'order',
            value: '{{params.order}}'
          },
          {
            name: 'sort',
            value: '{{params.sort}}'
          },
          {
            name: 'page',
            value: '{{paging.page}}'
          },
          {
            name: 'Intercom-Version',
            value: '2.5'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['conversations'];",
        request_tokens: {}
      },
      pagination: 'page',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['pages']['page'] < data['pages']['total_pages']",
      pagination_total_function: "// ex. data['count']\nreturn data['total_count']",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn data['pages']['next']",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'open',
          sortable: true
        },
        {
          name: 'read',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'type',
          sortable: true
        },
        {
          name: 'state',
          sortable: true
        },
        {
          name: 'title',
          sortable: true
        },
        {
          name: 'source',
          sortable: true
        },
        {
          name: 'contacts',
          sortable: true
        },
        {
          name: 'priority',
          sortable: true
        },
        {
          name: 'teammates',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'statistics',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'sla_applied',
          sortable: true
        },
        {
          name: 'snoozed_until',
          sortable: true
        },
        {
          name: 'waiting_since',
          sortable: true
        },
        {
          name: 'team_assignee_id',
          sortable: true
        },
        {
          name: 'admin_assignee_id',
          sortable: true
        },
        {
          name: 'conversation_rating',
          sortable: true
        },
        {
          name: 'first_contact_reply',
          sortable: true
        }
      ]
    },
    get_parameters: [
      {
        name: 'order',
        verbose_name: 'order',
        description: '',
        field: 'select',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'Ascending',
              color: 'blue',
              value: 'asc'
            },
            {
              name: 'Descending',
              color: 'green',
              value: 'desc'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'sort',
        verbose_name: 'sort',
        description: '',
        field: 'select',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'Created',
              color: 'blue',
              value: 'created_at'
            },
            {
              name: 'Updated',
              color: 'green',
              value: 'updated_at'
            },
            {
              name: 'Waiting since',
              color: 'red',
              value: 'waiting_since'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.intercom.io/conversations/{{params.conversation_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer  {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'conversation_id',
        verbose_name: 'conversation_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.intercom.io/conversations',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n  "from": {\r\n    "type": "{{params.type}}",\r\n    "id": "{{params.id}}"\r\n  },\r\n  "body": "{{params.body}}"\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'body',
        verbose_name: 'body',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.intercom.io/conversations/{{params.conversation_id}}',
        auth_params: {},
        body_type: 'Raw',
        body: '{\r\n  "read": "{{params.read}}"\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'conversation_id',
        verbose_name: 'conversation_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'read',
        verbose_name: 'read',
        description: '',
        field: 'boolean',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.intercom.io/conversations/{{params.conversation_id}}/parts',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{ \r\n  "message_type": "close",\r\n  "type": "admin",\r\n  "admin_id": "{{params.admin_id}}",\r\n  "body": "{{params.body}}"\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'conversation_id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'admin_id',
        verbose_name: 'admin_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'body',
        verbose_name: 'body',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'search_for_contacts',
    verbose_name: 'Search for Contacts',
    verbose_name_plural: 'Search for Contacts',
    fields: [
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'workspace_id',
        verbose_name: 'workspace id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'external_id',
        verbose_name: 'external id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role',
        verbose_name: 'role',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'avatar',
        verbose_name: 'avatar',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'social_profiles',
        verbose_name: 'social profiles',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'has_hard_bounced',
        verbose_name: 'has hard bounced',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'marked_email_as_spam',
        verbose_name: 'marked email as spam',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'unsubscribed_from_emails',
        verbose_name: 'unsubscribed from emails',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'signed_up_at',
        verbose_name: 'signed up at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_seen_at',
        verbose_name: 'last seen at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_replied_at',
        verbose_name: 'last replied at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_contacted_at',
        verbose_name: 'last contacted at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_email_opened_at',
        verbose_name: 'last email opened at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_email_clicked_at',
        verbose_name: 'last email clicked at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'language_override',
        verbose_name: 'language override',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser',
        verbose_name: 'browser',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser_version',
        verbose_name: 'browser version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'browser_language',
        verbose_name: 'browser language',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'os',
        verbose_name: 'os',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_app_name',
        verbose_name: 'android app name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_app_version',
        verbose_name: 'android app version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_device',
        verbose_name: 'android device',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_os_version',
        verbose_name: 'android os version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_sdk_version',
        verbose_name: 'android sdk version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'android_last_seen_at',
        verbose_name: 'android last seen at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_app_name',
        verbose_name: 'ios app name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_app_version',
        verbose_name: 'ios app version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_device',
        verbose_name: 'ios device',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_os_version',
        verbose_name: 'ios os version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_sdk_version',
        verbose_name: 'ios sdk version',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ios_last_seen_at',
        verbose_name: 'ios last seen at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'custom_attributes',
        verbose_name: 'custom attributes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes',
        verbose_name: 'notes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'companies',
        verbose_name: 'companies',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'opted_out_subscription_types',
        verbose_name: 'opted_out_subscription_types',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'type',
                  type: 'field',
                  label: 'type',
                  params: {
                    name: 'type',
                    field: 'text',
                    label: 'type',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'data',
                  type: 'array',
                  label: 'data',
                  params: {
                    item: {
                      type: 'object',
                      params: {
                        items: []
                      }
                    }
                  }
                },
                {
                  name: 'url',
                  type: 'field',
                  label: 'url',
                  params: {
                    name: 'url',
                    field: 'text',
                    label: 'url',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'total_count',
                  type: 'field',
                  label: 'total_count',
                  params: {
                    name: 'total_count',
                    field: 'number',
                    label: 'total_count',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'has_more',
                  type: 'field',
                  label: 'has_more',
                  params: {
                    name: 'has_more',
                    field: 'boolean',
                    label: 'has_more',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'utm_campaign',
        verbose_name: 'utm_campaign',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'utm_content',
        verbose_name: 'utm_content',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'utm_medium',
        verbose_name: 'utm_medium',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'utm_source',
        verbose_name: 'utm_source',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'utm_term',
        verbose_name: 'utm_term',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'referrer',
        verbose_name: 'referrer',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'sms_consent',
        verbose_name: 'sms_consent',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'unsubscribed_from_sms',
        verbose_name: 'unsubscribed_from_sms',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns contact by search inputs.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.intercom.io/contacts/search',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\r\n "query":  {\r\n    "field": params.field,\r\n    "operator": params.operator,\r\n    "value": params.value\r\n  },\r\n  "pagination": {\r\n    "per_page": paging.limit || 20,\r\n    "starting_after": paging.cursor_next\r\n  }\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Intercom-Version',
            value: '2.5'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_total_function: "// ex. data['count']\nreturn data['total_count']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['pages']['next'] ? data['pages']['next']['starting_after'] : undefined",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: [
        {
          name: 'id',
          sortable: true
        },
        {
          name: 'os',
          sortable: true
        },
        {
          name: 'name',
          sortable: true
        },
        {
          name: 'role',
          sortable: true
        },
        {
          name: 'tags',
          sortable: true
        },
        {
          name: 'type',
          sortable: true
        },
        {
          name: 'email',
          sortable: true
        },
        {
          name: 'notes',
          sortable: true
        },
        {
          name: 'phone',
          sortable: true
        },
        {
          name: 'avatar',
          sortable: true
        },
        {
          name: 'browser',
          sortable: true
        },
        {
          name: 'location',
          sortable: true
        },
        {
          name: 'owner_id',
          sortable: true
        },
        {
          name: 'referrer',
          sortable: true
        },
        {
          name: 'utm_term',
          sortable: true
        },
        {
          name: 'companies',
          sortable: true
        },
        {
          name: 'created_at',
          sortable: true
        },
        {
          name: 'ios_device',
          sortable: true
        },
        {
          name: 'updated_at',
          sortable: true
        },
        {
          name: 'utm_medium',
          sortable: true
        },
        {
          name: 'utm_source',
          sortable: true
        },
        {
          name: 'external_id',
          sortable: true
        },
        {
          name: 'sms_consent',
          sortable: true
        },
        {
          name: 'utm_content',
          sortable: true
        },
        {
          name: 'ios_app_name',
          sortable: true
        },
        {
          name: 'last_seen_at',
          sortable: true
        },
        {
          name: 'signed_up_at',
          sortable: true
        },
        {
          name: 'utm_campaign',
          sortable: true
        },
        {
          name: 'workspace_id',
          sortable: true
        },
        {
          name: 'android_device',
          sortable: true
        },
        {
          name: 'ios_os_version',
          sortable: true
        },
        {
          name: 'browser_version',
          sortable: true
        },
        {
          name: 'ios_app_version',
          sortable: true
        },
        {
          name: 'ios_sdk_version',
          sortable: true
        },
        {
          name: 'last_replied_at',
          sortable: true
        },
        {
          name: 'social_profiles',
          sortable: true
        },
        {
          name: 'android_app_name',
          sortable: true
        },
        {
          name: 'browser_language',
          sortable: true
        },
        {
          name: 'has_hard_bounced',
          sortable: true
        },
        {
          name: 'ios_last_seen_at',
          sortable: true
        },
        {
          name: 'custom_attributes',
          sortable: true
        },
        {
          name: 'language_override',
          sortable: true
        },
        {
          name: 'last_contacted_at',
          sortable: true
        },
        {
          name: 'android_os_version',
          sortable: true
        },
        {
          name: 'android_app_version',
          sortable: true
        },
        {
          name: 'android_sdk_version',
          sortable: true
        },
        {
          name: 'android_last_seen_at',
          sortable: true
        },
        {
          name: 'last_email_opened_at',
          sortable: true
        },
        {
          name: 'marked_email_as_spam',
          sortable: true
        },
        {
          name: 'last_email_clicked_at',
          sortable: true
        },
        {
          name: 'unsubscribed_from_sms',
          sortable: true
        },
        {
          name: 'unsubscribed_from_emails',
          sortable: true
        },
        {
          name: 'opted_out_subscription_types',
          sortable: true
        }
      ]
    },
    get_parameters: [
      {
        name: 'field',
        verbose_name: 'field',
        description: '',
        field: 'select',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'email',
              color: 'blue',
              value: 'email'
            },
            {
              name: 'name',
              color: 'green',
              value: 'name'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'operator',
        verbose_name: 'operator',
        description: '',
        field: 'select',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'Equals',
              color: 'blue',
              value: '='
            },
            {
              name: 'Contains',
              color: 'green',
              value: '~'
            },
            {
              name: 'Starts With',
              color: 'red',
              value: '^'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'value',
        verbose_name: 'value',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'search_for_conversations',
    verbose_name: 'Search for Conversations',
    verbose_name_plural: 'Search for Conversations',
    fields: [
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_at',
        verbose_name: 'created at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'updated_at',
        verbose_name: 'updated at',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'waiting_since',
        verbose_name: 'waiting since',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'snoozed_until',
        verbose_name: 'snoozed until',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'contacts',
        verbose_name: 'contacts',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_contact_reply',
        verbose_name: 'first contact reply',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'admin_assignee_id',
        verbose_name: 'admin_assignee_id',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'team_assignee_id',
        verbose_name: 'team_assignee_id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'open',
        verbose_name: 'open',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'state',
        verbose_name: 'state',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'read',
        verbose_name: 'read',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tags',
        verbose_name: 'tags',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'priority',
        verbose_name: 'priority',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'sla_applied',
        verbose_name: 'sla applied',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statistics',
        verbose_name: 'statistics',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'conversation_rating',
        verbose_name: 'conversation rating',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'teammates',
        verbose_name: 'teammates',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'title',
        verbose_name: 'title',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'custom_attributes',
        verbose_name: 'custom_attributes',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: []
            }
          },
          display_fields: true
        }
      },
      {
        name: 'topics',
        verbose_name: 'topics',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: []
            }
          },
          display_fields: true
        }
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Lists conversations on your Intercom by contact id.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.intercom.io/conversations/search',
        auth_params: {},
        body_type: 'JSON',
        body:
          '{\r\n "query":  {\r\n    "field": params.field,\r\n    "operator": params.operator,\r\n    "value": params.value\r\n  },\r\n  "pagination": {\r\n    "per_page": paging.limit || 20,\r\n    "starting_after": paging.cursor_next\r\n  }\r\n}',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          },
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Intercom-Version',
            value: '2.5'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['conversations']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_total_function: "// ex. data['count']\nreturn data['total_count']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['pages']['next'] ? data['pages']['next']['starting_after'] : undefined",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'field',
        verbose_name: 'field',
        description: '',
        field: 'select',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'Author Email',
              color: 'blue',
              value: 'source.author.email'
            },
            {
              name: 'Author Name',
              color: 'green',
              value: 'source.author.name'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'operator',
        verbose_name: 'operator',
        description: '',
        field: 'select',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          inputs: [],
          columns: [],
          options: [
            {
              name: 'Equals',
              color: 'blue',
              value: '='
            },
            {
              name: 'Contains',
              color: 'green',
              value: '~'
            },
            {
              name: 'Starts With',
              color: 'red',
              value: '^'
            }
          ],
          parameters: [],
          options_type: 'static'
        }
      },
      {
        name: 'value',
        verbose_name: 'value',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_parameters: [],
    get_detail_parameters_use_defaults: false,
    create_parameters: [],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: true,
    deleted: false
  }
];

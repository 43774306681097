import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';

import { amplitudeResourceParamsMenuSettings } from '../../data/amplitude/amplitude-resource-params-menu-settings.stub';
import { amplitudeResourceParamsModelDescriptions } from '../../data/amplitude/amplitude-resource-params-model-descriptions.stub';
import { amplitudeResourceParamsParams } from '../../data/amplitude/amplitude-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface AmplitudeParamsOptions {
  key: string;
  secret: string;
}

@Injectable()
export class AmplitudeGeneratorService extends ResourceGeneratorService<AmplitudeParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: AmplitudeParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://amplitude.com/api/2/realtime';
    query.headers = [{ name: 'Authorization', value: `Basic ${this.authTokenFromOptions(options)}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 403) {
          error = new ServerRequestError('API Key or Secret Key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<AmplitudeParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_BASIC_AUTH_VALUE,
        this.mode == AdminMode.Builder
      )
      .pipe(map(result => this.authTokenToOptions(result.value)));
  }

  authTokenFromOptions(options: AmplitudeParamsOptions): string {
    try {
      return btoa([options.key, options.secret].join(':'));
    } catch (e) {}
  }

  authTokenToOptions(token: string): AmplitudeParamsOptions {
    try {
      const [key, secret] = atob(token).split(':', 2);
      return {
        key: key,
        secret: secret
      };
    } catch (e) {
      return {
        key: undefined,
        secret: undefined
      };
    }
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: AmplitudeParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = amplitudeResourceParamsParams;
    const modelDescriptions = amplitudeResourceParamsModelDescriptions;
    const menuSettings = amplitudeResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
    token.type = SecretTokenType.Static;
    token.value = this.authTokenFromOptions(options);

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()]
    });
  }
}

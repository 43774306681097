/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./onboarding-popup.component";
import * as i2 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i3 from "../../stores/guide-section.store";
import * as i4 from "../../services/guide/guide.service";
var styles_OnboardingPopupComponent = [];
var RenderType_OnboardingPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OnboardingPopupComponent, data: {} });
export { RenderType_OnboardingPopupComponent as RenderType_OnboardingPopupComponent };
export function View_OnboardingPopupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), i0.ɵqud(402653184, 2, { background: 0 }), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["background", 1]], null, 0, "div", [["class", "onboarding-popup-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "onboarding-popup-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, [[1, 0], ["root", 1]], null, 13, "div", [["class", "onboarding-popup"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "onboarding-popup__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "onboarding-popup__image-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "onboarding-popup__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Congratulations!"])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Glad to see you at Jet Admin "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "onboarding-popup__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Start discovering operations you can do to tune Jet Admin for your needs by completing your first task. "])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "onboarding-popup__button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "a", [["class", "button button_primary button_fill"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getStarted() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Start"]))], null, null); }
export function View_OnboardingPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-onboarding-popup", [], null, null, null, View_OnboardingPopupComponent_0, RenderType_OnboardingPopupComponent)), i0.ɵdid(1, 4374528, null, 0, i1.OnboardingPopupComponent, [i2.BasePopupComponent, i3.GuideSectionStore, i4.GuideService], null, null)], null, null); }
var OnboardingPopupComponentNgFactory = i0.ɵccf("app-onboarding-popup", i1.OnboardingPopupComponent, View_OnboardingPopupComponent_Host_0, {}, { closed: "closed" }, []);
export { OnboardingPopupComponentNgFactory as OnboardingPopupComponentNgFactory };

import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import isArray from 'lodash/isArray';

import { FormUtils } from '../../services/form-utils';

@Pipe({
  name: 'appFormFieldErrors',
  pure: false
})
export class FormFieldErrorsPipe implements PipeTransform {
  constructor(private formUtils: FormUtils) {}

  transform(form: FormGroup | FormArray, field: string | number | string[] | number[], touchOnly = false): string[] {
    if (isArray(field)) {
      return (field as string[]).reduce<string[]>((acc, item) => {
        const errors: string[] = this.formUtils.getFieldErrors(form, item, { touchOnly: touchOnly });
        acc.push(...errors);
        return acc;
      }, []);
    } else {
      return this.formUtils.getFieldErrors(form, field, { touchOnly: touchOnly });
    }
  }
}

import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore, ReducedModelService } from '@modules/model-queries';
import { UserActivityType } from '../../data/user-activity-type';
var UserActivityDisplayService = /** @class */ (function () {
    function UserActivityDisplayService(dashboardStore, modelDescriptionStore, reducedModelService) {
        this.dashboardStore = dashboardStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.reducedModelService = reducedModelService;
    }
    UserActivityDisplayService.prototype.getActionRelations = function (userActivity) {
        var _this = this;
        var obs = [];
        if (userActivity.activityType == UserActivityType.DashboardList) {
            obs.push(this.dashboardStore.getDetailFirst(userActivity.params['id']).pipe(map(function (result) { return [['dashboard', result]]; })));
        }
        else if ([UserActivityType.ModelList, UserActivityType.ModelDelete].includes(userActivity.activityType)) {
            obs.push(this.modelDescriptionStore
                .getDetailFirst(userActivity.params['model'])
                .pipe(map(function (result) { return [['model_description', result]]; })));
        }
        else if ([UserActivityType.ModelDetail, UserActivityType.ModelCreate, UserActivityType.ModelUpdate].includes(userActivity.activityType)) {
            obs.push(this.modelDescriptionStore.getDetailFirst(userActivity.params['model']).pipe(switchMap(function (modelDescription) {
                var subObs = [];
                subObs.push(_this.reducedModelService.getDetail(modelDescription.modelId, modelDescription.primaryKeyField, userActivity.params['id']));
                if (userActivity.activityType == UserActivityType.ModelCreate && userActivity.params['duplicate']) {
                    subObs.push(_this.reducedModelService.getDetail(modelDescription.modelId, modelDescription.primaryKeyField, userActivity.params['duplicate']));
                }
                return combineLatest.apply(void 0, subObs).pipe(map(function (_a) {
                    var model = _a[0], duplicate = _a[1];
                    var result = [
                        ['model_description', modelDescription],
                        ['model', model]
                    ];
                    if (duplicate) {
                        result.push(['duplicate', duplicate]);
                    }
                    return result;
                }));
            })));
        }
        if (!obs.length) {
            return of({});
        }
        return combineLatest.apply(void 0, obs).pipe(map(function (result) {
            result = flatten(result);
            return fromPairs(result);
        }));
    };
    return UserActivityDisplayService;
}());
export { UserActivityDisplayService };

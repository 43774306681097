/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alert-element-action/alert-element-action.component.ngfactory";
import * as i2 from "./alert-element-action/alert-element-action.component";
import * as i3 from "../../../action-queries/services/action/action.service";
import * as i4 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i5 from "../../../customize/services/customize/customize.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i9 from "../../../theme-components/data/theme-context";
import * as i10 from "../../../../ui/components/alert/alert.component.ngfactory";
import * as i11 from "../../../../ui/components/alert/alert.component";
import * as i12 from "./alert-element.component";
var styles_AlertElementComponent = [];
var RenderType_AlertElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertElementComponent, data: {} });
export { RenderType_AlertElementComponent as RenderType_AlertElementComponent };
function View_AlertElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-alert-element-action", [], null, null, null, i1.View_AlertElementActionComponent_0, i1.RenderType_AlertElementActionComponent)), i0.ɵdid(1, 770048, null, 0, i2.AlertElementActionComponent, [i3.ActionService, i4.ActionControllerService, i5.CustomizeService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], context: [1, "context"], accentColor: [2, "accentColor"], analyticsSource: [3, "analyticsSource"] }, null), i0.ɵppd(2, 1), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.context; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 2, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "accentColor")))); var currVal_3 = _co.element.analyticsGenericName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AlertElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.ThemeColorHexOptionPipe, [[2, i9.ThemeContext]]), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-alert", [], null, null, null, i10.View_AlertComponent_0, i10.RenderType_AlertComponent)), i0.ɵdid(2, 114688, null, 0, i11.AlertComponent, [], { title: [0, "title"], icon: [1, "icon"], secondary: [2, "secondary"], info: [3, "info"], danger: [4, "danger"], warning: [5, "warning"], success: [6, "success"], purple: [7, "purple"], color: [8, "color"], border: [9, "border"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertElementComponent_1)), i0.ɵdid(7, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.element.icon; var currVal_2 = (!_co.element.color && (_co.element.tint == _co.tints.Default)); var currVal_3 = (!_co.element.color && (_co.element.tint == _co.tints.Info)); var currVal_4 = (!_co.element.color && (_co.element.tint == _co.tints.Danger)); var currVal_5 = (!_co.element.color && (_co.element.tint == _co.tints.Warning)); var currVal_6 = (!_co.element.color && (_co.element.tint == _co.tints.Success)); var currVal_7 = (!_co.element.color && (_co.element.tint == _co.tints.Special)); var currVal_8 = _co.element.color; var currVal_9 = (_co.element.style == _co.styles.Border); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = _co.element.actions; _ck(_v, 7, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.message; _ck(_v, 4, 0, currVal_10); }); }
export function View_AlertElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert-element", [], null, null, null, View_AlertElementComponent_0, RenderType_AlertElementComponent)), i0.ɵdid(1, 770048, null, 0, i12.AlertElementComponent, [i5.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertElementComponentNgFactory = i0.ɵccf("app-alert-element", i12.AlertElementComponent, View_AlertElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { AlertElementComponentNgFactory as AlertElementComponentNgFactory };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, publishLast, refCount, skip } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { BasePopupComponent, PopupService } from '@common/popups';

export const CLOSE_BY_BACKGROUND_CLICK = 'CLOSE_BY_BACKGROUND_CLICK';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogPopupComponent extends BasePopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;

  tl = new TimelineMax();
  _animating = new BehaviorSubject<boolean>(false);
  opaque = false;
  wide = true;
  contrast = false;
  transparent = false;
  showRequested = false;
  closeRequested = false;
  containerClickSourceTarget: EventTarget;

  constructor(private dialogService: DialogService, el: ElementRef, popupService: PopupService) {
    super(el, popupService);
  }

  initProviders() {
    this.data.providers = (this.data.providers || []).concat([
      { provide: BasePopupComponent, useValue: this },
      { provide: DialogPopupComponent, useValue: this }
    ]);
  }

  ngAfterViewInit(): void {
    this.show();
  }

  get animating() {
    return this._animating.value;
  }

  get animating$() {
    return this._animating.asObservable();
  }

  set animating(value) {
    this._animating.next(value);
  }

  get loaded$(): Observable<void> {
    return this.animating$.pipe(
      skip(1),
      filter(item => item == false),
      map(() => {}),
      first(),
      publishLast(),
      refCount()
    );
  }

  show() {
    if (this.closeRequested) {
      return;
    }

    this.showRequested = true;
    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0.15,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        },
        0
      )
      .fromTo(
        this.root.nativeElement,
        0.15,
        {
          y: -10,
          opacity: 0
        },
        {
          y: 0,
          opacity: 1
        },
        0
      )
      .add(() => {
        this.animating = false;
      });
  }

  close(data?: any) {
    if (this.closeRequested) {
      return;
    }

    if (!this.showRequested) {
      this.closed.emit(data);
      this.popupService.remove(this.data);
      return;
    }

    this.closeRequested = true;
    this.data.closeRequested = true;
    this.animating = true;
    this.closed.emit(data);
    this.tl
      .clear()
      .to(
        this.root.nativeElement,
        0.15,
        {
          opacity: 0,
          y: 10
        },
        0
      )
      .to(
        this.background.nativeElement,
        0.2,
        {
          opacity: 0
        },
        0
      )
      .add(() => {
        this.animating = false;
        this.popupService.remove(this.data);
      });
  }

  onContainerMouseDown(e: MouseEvent) {
    this.containerClickSourceTarget = e.target;
  }

  onContainerMouseClick(e: MouseEvent, container: Element) {
    if (e.target !== container || this.containerClickSourceTarget !== container) {
      return;
    }

    if (this.animating || this.disableClose) {
      return;
    }

    this.confirmClose(CLOSE_BY_BACKGROUND_CLICK);
  }

  confirmClose(data?: any) {
    if (this.closeWithoutConfirm) {
      this.close(data);
    } else {
      this.dialogService
        .confirm({
          title: this.closeTitle,
          description: this.closeDescription,
          style: this.orange ? 'orange' : undefined,
          dark: this.dark
        })
        .pipe(untilDestroyed(this))
        .subscribe(confirmed => {
          if (!confirmed) {
            return;
          }

          this.close(data);
        });
    }
  }
}

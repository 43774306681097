<div
  class="poster-layout"
  [class.poster-layout_left]="left"
  [class.poster-layout_fill-screen]="fillScreen"
  [class.poster-layout_preview]="preview"
>
  <div
    class="poster-layout__poster"
    [style.background-image]="backgroundImage ? 'url(' + backgroundImage + ')' : ''"
    [style.background-color]="(backgroundColor | appIsColorHex) ? backgroundColor : null"
    [ngClass]="
      backgroundColor && !(backgroundColor | appIsColorHex) ? 'background-color_' + backgroundColor + '_1' : ''
    "
  >
    <svg
      *ngIf="!backgroundImage"
      style="width: 100%; height: 100%;"
      preserveAspectRatio="xMidYMin slice"
      width="480px"
      height="705px"
      viewBox="0 0 480 705"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Dashboard---Share-Your-Template" transform="translate(-225.000000, -98.000000)">
          <g id="Image" transform="translate(225.000000, 98.000000)">
            <g id="background">
              <circle
                *ngIf="backgroundContrastColor"
                [style.fill]="backgroundContrastColor"
                id="Oval"
                fill="#C8E2F2"
                cx="400"
                cy="89"
                r="307"
              ></circle>
              <circle
                *ngIf="backgroundContrastColor"
                [style.fill]="backgroundContrastColor"
                id="Oval"
                fill="#C8E2F2"
                cx="-9.5"
                cy="685.5"
                r="185.5"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="poster-layout__content-container">
    <div class="poster-layout__content">
      <ng-content></ng-content>
      <ng-content select="[data-layout-footer]"></ng-content>
    </div>
  </div>
</div>

<ng-template #tabs_template>
  <div *ngIf="tabs?.length > 1" class="query-builder__tabs">
    <a
      *ngFor="let item of tabs"
      href="javascript:void(0)"
      class="query-builder__tabs-item"
      [class.query-builder__tabs-item_active]="form.controls.query_type.value == item.value"
      (click)="form.controls.query_type.patchValue(item.value)"
    >
      <span class="query-builder__tabs-item-inner">{{ item.name }}</span>
    </a>
  </div>
</ng-template>

<app-query-builder-simple
  *ngIf="form.controls.query_type.value == queryType.Simple"
  (saved)="submit()"
  (canceled)="cancel()"
>
  <ng-container *ngTemplateOutlet="tabs_template" tabs></ng-container>
</app-query-builder-simple>

<app-query-builder-http
  *ngIf="form.controls.query_type.value == queryType.Http"
  [resource]="resource"
  [context]="context"
  [control]="form.controls.http_query"
  [requireResponse]="requireResponse"
  [arrayResponse]="arrayResponse"
  [customSections]="customSections"
  [parametersControl]="parametersControl"
  [options]="httpOptions"
  [queryForm]="form"
  [source]="source"
  (executed)="onHttpExecuted($event)"
  (saved)="submit()"
  (canceled)="cancel()"
>
  <ng-container *ngTemplateOutlet="tabs_template" tabs></ng-container>
  <!--  <ng-container sections-bottom>-->
  <!--    <app-query-builder-list-query-parameters *ngIf="form.listQuery" [form]="form.form">-->
  <!--    </app-query-builder-list-query-parameters>-->
  <!--  </ng-container>-->
</app-query-builder-http>

<app-query-builder-sql
  *ngIf="form.controls.query_type.value == queryType.SQL"
  [resource]="resource"
  [context]="context"
  [control]="form.controls.sql_query"
  [requireResponse]="requireResponse"
  [arrayResponse]="arrayResponse"
  [customSections]="customSections"
  [parametersControl]="parametersControl"
  [options]="sqlOptions"
  [source]="source"
  (executed)="onSqlExecuted($event)"
  (saved)="submit()"
  (canceled)="cancel()"
>
  <ng-container *ngTemplateOutlet="tabs_template" tabs></ng-container>
  <!--  <ng-container sections-bottom>-->
  <!--    <app-query-builder-list-query-parameters *ngIf="form.listQuery"-->
  <!--                                             [form]="form.form">-->
  <!--    </app-query-builder-list-query-parameters>-->
  <!--  </ng-container>-->
</app-query-builder-sql>

<app-query-builder-object
  *ngIf="form.controls.query_type.value == queryType.Object"
  [resource]="resource"
  [context]="context"
  [control]="form.controls.object_query"
  [requireResponse]="requireResponse"
  [arrayResponse]="arrayResponse"
  [options]="objectOptions"
  [customSections]="customSections"
  [parametersControl]="parametersControl"
  [source]="source"
  (executed)="onObjectExecuted($event)"
  (saved)="submit()"
  (canceled)="cancel()"
>
  <ng-container *ngTemplateOutlet="tabs_template" tabs></ng-container>
  <!--  <ng-container sections-bottom>-->
  <!--    <app-query-builder-list-query-parameters *ngIf="form.listQuery"-->
  <!--                                             [form]="form.form">-->
  <!--    </app-query-builder-list-query-parameters>-->
  <!--  </ng-container>-->
</app-query-builder-object>

import { OutputData } from '@editorjs/editorjs';
import isEqual from 'lodash/isEqual';
import Delta from 'quill-delta';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export function editorJsTextToQuillOp(text: string, extraAttrs?: Object): Object[] {
  text += '\n';

  const tags = [
    { name: 'bold', regex: /(<b(?:\s([^>]+))?>)(?:(?!<\/b).)*(<\/b>)/g },
    { name: 'italic', regex: /(<i(?:\s([^>]+))?>)(?:(?!<\/i).)*(<\/i>)/g },
    {
      name: 'link',
      regex: /(<a(?:\s([^>]+))?>)(?:(?!<\/a).)*(<\/a>)/g,
      getValue: attrs => {
        if (!attrs) {
          return;
        }
        const match = /href="([^"]+)"/.exec(attrs);
        if (match) {
          return match[1];
        }
      }
    }
  ];
  const textTags = {};

  for (const tag of tags) {
    let match;

    while ((match = tag.regex.exec(text))) {
      const value = tag.getValue ? tag.getValue(match[2]) : true;

      for (let i = match.index; i < match.index + match[1].length; ++i) {
        textTags[i] = { ignore: true };
      }
      for (let i = match.index + match[1].length; i < match.index + match[0].length - match[3].length; ++i) {
        if (!textTags[i]) {
          textTags[i] = {};
        }

        textTags[i][tag.name] = value;
      }
      for (let i = match.index + match[0].length - match[3].length; i < match.index + match[0].length; ++i) {
        textTags[i] = { ignore: true };
      }
    }
  }

  return text.split('').reduce((acc, char, i) => {
    const prevOp = acc[acc.length - 1];
    let attributes = textTags[i];

    if (extraAttrs) {
      attributes = {
        ...attributes,
        ...extraAttrs
      };
    }

    if (!attributes || !attributes['ignore']) {
      let currentOp;

      if (!prevOp || !isEqual(prevOp.attributes, attributes)) {
        currentOp = {
          insert: char,
          ...(attributes ? { attributes: attributes } : {})
        };
        acc.push(currentOp);
      } else {
        currentOp = prevOp;
        currentOp.insert += char;
      }
    }

    return acc;
  }, []);
}

export function editorJsOutputDataToQuillDelta(markup: OutputData): Delta {
  const result = markup.blocks.reduce((acc, item) => {
    if (item.type == 'formula') {
      acc.push({
        insert: {
          'context-formula': item.data['value']
        }
      });
    } else if (item.type == 'header') {
      const ops = editorJsTextToQuillOp(item.data['text'], {
        header: item.data['level'] || 2
      });
      acc.push(...ops);
    } else if (item.type == 'paragraph') {
      const ops = editorJsTextToQuillOp(item.data['text']);
      acc.push(...ops);
    }

    return acc;
  }, []);

  let added = 0;

  result.forEach((item, i) => {
    if (item.insert['context-formula']) {
      const nextIndex = i + 1 + added;
      if (result[nextIndex] && typeof result[nextIndex]['insert'] == 'string') {
        result[nextIndex]['insert'] = `\n${result[nextIndex]['insert']}`;
      } else {
        result.splice(nextIndex, 0, { insert: '\n' });
        ++added;
      }
    }
  });

  return {
    ops: result
  };
}

export class TextElementItem extends ElementItem {
  public type = ElementType.Text;
  // public text: string;
  public markup: OutputData;
  public quillDelta: Delta;

  deserialize(data: Object): TextElementItem {
    super.deserialize(data);
    // this.text = this.params['text'];

    // if (this.params['markup']) {
    //   this.markup = this.params['markup'];
    // }

    if (this.params['quill_delta']) {
      this.quillDelta = this.params['quill_delta'];
    } else if (this.params['markup']) {
      // Backward compatibility
      this.quillDelta = editorJsOutputDataToQuillDelta(this.params['markup']);
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    // data['params']['text'] = this.text;
    // data['params']['markup'] = this.markup;
    data['params']['quill_delta'] = this.quillDelta;
    return data;
  }

  get analyticsName(): string {
    return 'text';
  }

  defaultName() {
    return 'Text';
  }
}

registerElementForType(ElementType.Text, TextElementItem);

<app-add-step-menu
  [actionTypesEnabled]="actionTypesEnabled"
  (add)="stepAddBefore.emit($event)"
  #add_step_menu
></app-add-step-menu>

<div class="workflow-connection" [class.workflow-connection_first]="index == 0">
  <div
    *ngIf="stepHasPreviousConnection()"
    class="workflow-connection__bounds workflow-connection__bounds_interactive"
    [matMenuTriggerFor]="add_step_menu.menu"
    [matMenuTriggerData]="{ trigger: trigger }"
    #trigger="matMenuTrigger"
  >
    <div class="workflow-connection__dash">
      <div
        *ngIf="workflowEditable"
        class="workflow-connection__button icon-plus"
        [class.workflow-connection__button_active]="trigger.menuOpen"
        [ngClass]="[
          'workflow-connection__button_size_s',
          'workflow-connection__button_position_center',
          'workflow-connection__button_on-hover'
        ]"
      ></div>
    </div>
  </div>

  <div *ngIf="!stepHasPreviousConnection()" class="workflow-connection__space"></div>
</div>

<div class="workflow-fork">
  <div class="workflow-fork__root">
    <app-workflow-step-card
      appDragHandle
      [title]="conditionTypeLabel"
      [subtitle]="step.name"
      [icon]="icon"
      [indicatorLoader]="status == statuses.Executing"
      [indicatorIcon]="statusIcon"
      [indicatorColor]="statusColor"
      [active]="customizing$ | async"
      [interactive]="true"
      [connectorTop]="stepHasPreviousConnection()"
      [duplicateEnabled]="workflowEditable"
      [deleteEnabled]="workflowEditable"
      (click)="stepCustomize.emit()"
      (stepDuplicate)="stepDuplicate.emit()"
      (stepDelete)="stepDelete.emit()"
    ></app-workflow-step-card>
  </div>

  <div class="workflow-fork__children" #children>
    <ng-container *ngIf="step.conditionType == conditionTypes.Boolean">
      <app-condition-workflow-step-item
        *ngFor="let item of step.items.slice(0, 2); let i = index; trackBy: trackStepItem"
        [label]="i == 0 ? 'Yes' : 'No'"
        [steps]="item.steps"
        [workflowEditable]="workflowEditable"
        [active]="customizing$ | async"
        [containerElement]="children"
        [context]="context"
        [actionTypesEnabled]="actionTypesEnabled"
        [analyticsSource]="analyticsSource"
        (stepClick)="stepCustomize.emit()"
      ></app-condition-workflow-step-item>
    </ng-container>

    <ng-container *ngIf="step.conditionType != conditionTypes.Boolean">
      <app-condition-workflow-step-item
        *ngFor="let item of step.items; let i = index; trackBy: trackStepItem"
        [label]="(item.label | appIsSet) ? item.label : 'Condition ' + (i + 1)"
        [steps]="item.steps"
        [workflowEditable]="workflowEditable"
        [active]="customizing$ | async"
        [containerElement]="children"
        [context]="context"
        [actionTypesEnabled]="actionTypesEnabled"
        [analyticsSource]="analyticsSource"
        (stepClick)="stepCustomize.emit()"
      ></app-condition-workflow-step-item>

      <app-condition-workflow-step-item
        [label]="(step.elseLabel | appIsSet) ? step.elseLabel : 'Else'"
        [steps]="step.elseSteps"
        [workflowEditable]="workflowEditable"
        [active]="customizing$ | async"
        [containerElement]="children"
        [context]="context"
        [actionTypesEnabled]="actionTypesEnabled"
        [analyticsSource]="analyticsSource"
        (stepClick)="stepCustomize.emit()"
      ></app-condition-workflow-step-item>
    </ng-container>
  </div>
</div>

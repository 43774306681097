/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../errors/errors.component.ngfactory";
import * as i2 from "../errors/errors.component";
import * as i3 from "../../../../common/form-utils/pipes/form-errors/form-errors.pipe";
import * as i4 from "../../../../common/form-utils/services/form-utils";
import * as i5 from "./form-errors.component";
var styles_FormErrorsComponent = [];
var RenderType_FormErrorsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormErrorsComponent, data: {} });
export { RenderType_FormErrorsComponent as RenderType_FormErrorsComponent };
export function View_FormErrorsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "field"]], [[2, "field_manual-margin", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-errors", [], null, null, null, i1.View_ErrorsComponent_0, i1.RenderType_ErrorsComponent)), i0.ɵdid(2, 114688, null, 0, i2.ErrorsComponent, [], { errors: [0, "errors"] }, null), i0.ɵpid(0, i3.FormErrorsPipe, [i4.FormUtils])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.form)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manualMargin; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormErrorsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-errors", [], null, null, null, View_FormErrorsComponent_0, RenderType_FormErrorsComponent)), i0.ɵdid(1, 114688, null, 0, i5.FormErrorsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormErrorsComponentNgFactory = i0.ɵccf("app-form-errors", i5.FormErrorsComponent, View_FormErrorsComponent_Host_0, { form: "form", manualMargin: "manualMargin" }, {}, []);
export { FormErrorsComponentNgFactory as FormErrorsComponentNgFactory };

import { EventEmitter, Input, Output } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { WorkflowStep } from '@modules/workflow';

export abstract class CustomizeWorkflowStepComponent<T extends WorkflowStep = WorkflowStep> {
  @Input() step: T;
  @Input() workflowEditable = false;
  @Input() backgroundWorkflow = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Output() stepChange = new EventEmitter<T>();
  @Output() stepDuplicate = new EventEmitter<void>();
  @Output() stepDelete = new EventEmitter<void>();
  @Output() stepExecute = new EventEmitter<void>();
  @Output() closeCustomize = new EventEmitter<void>();

  constructor() {}
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SessionStorage } from '@core';
import { ActionDescriptionParamsExecuteSessionKey } from '@modules/action-queries';
import { UserActivityService } from '@modules/activities';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
var DeleteComponent = /** @class */ (function () {
    function DeleteComponent(injector, modelService, modelListStore, modelDescriptionStore, userActivityService, activatedRoute, currentProjectStore, currentEnvironmentStore, sessionStorage, metaService, cd) {
        this.injector = injector;
        this.modelService = modelService;
        this.modelListStore = modelListStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.userActivityService = userActivityService;
        this.activatedRoute = activatedRoute;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.sessionStorage = sessionStorage;
        this.metaService = metaService;
        this.cd = cd;
        this.loading = false;
        this.inverseIds = false;
        this.getItemList = false;
        this.deleteStarted = false;
        this.deleteProcessed = 0;
        this.deleteDeleted = 0;
    }
    DeleteComponent.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    DeleteComponent.prototype.ngOnInit = function () { };
    DeleteComponent.prototype.ngOnDestroy = function () { };
    DeleteComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        combineLatest(this.activatedRoute.params, this.activatedRoute.queryParams)
            .pipe(switchMap(function () {
            var snapshot = _this.activatedRoute.snapshot;
            try {
                _this.params = JSON.parse(_this.sessionStorage.get(ActionDescriptionParamsExecuteSessionKey));
            }
            catch (e) {
                _this.params = {};
            }
            _this.loading = true;
            _this.ids = _this.params['ids'] || [];
            _this.inverseIds = _this.params['inverseIds'];
            _this.modelDescription = undefined;
            _this.items = undefined;
            _this.count = undefined;
            _this.deleteStarted = false;
            _this.deleteProcessed = 0;
            _this.deleteDeleted = 0;
            _this.cd.markForCheck();
            return _this.modelDescriptionStore.getDetailFirst(snapshot.params['model']).pipe(map(function (item) { return [snapshot, item]; }));
        }), switchMap(function (_a) {
            var snapshot = _a[0], modelDescription = _a[1];
            var resource = _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
            _this.modelDescription = modelDescription;
            _this.getItemList = resource && resource.type == ResourceType.JetBridge;
            if (!_this.getItemList) {
                _this.count = _this.ids.length;
                _this.cd.markForCheck();
                return of(undefined);
            }
            var params = {};
            if (_this.inverseIds) {
                params["exclude__" + _this.modelDescription.primaryKeyField + "__in"] = _this.ids.join(',');
            }
            else {
                params[_this.modelDescription.primaryKeyField + "__in"] = _this.ids.join(',');
            }
            _this.modelListStore.dataSource = new ListModelDescriptionDataSource({
                type: DataSourceType.Query,
                queryResource: _this.modelDescription.resource,
                query: _this.modelDescription.getQuery
            });
            _this.modelListStore.params = __assign({}, _this.params, params);
            if (_this.modelListStore.params[PAGE_PARAM] != undefined) {
                delete _this.modelListStore.params[PAGE_PARAM];
            }
            if (_this.modelListStore.params['ids'] != undefined) {
                delete _this.modelListStore.params['ids'];
            }
            if (_this.modelListStore.params['inverseIds'] != undefined) {
                delete _this.modelListStore.params['inverseIds'];
            }
            var applyResult = applySegments(_this.modelListStore.params, {}, _this.modelDescription);
            applyResult.params = applyParamsComputedLookups(applyResult.params);
            _this.cd.markForCheck();
            return _this.modelService.get(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, snapshot.params['model'], applyResult.params, keys(applyResult.body).length ? applyResult.body : undefined);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.loading = false;
            if (result) {
                _this.items = result.results;
                _this.count = result.count;
            }
            _this.metaService.set({
                title: [_this.count ? "Delete " + _this.count : 'Delete', _this.modelDescription.verboseNamePlural]
            });
            _this.cd.markForCheck();
        }, function (e) {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    DeleteComponent.prototype.processPage = function () {
        var _this = this;
        this.modelListStore.reset();
        if (!this.getItemList) {
            var result_1 = new ReplaySubject();
            var model = this.createModel();
            model.setUp(this.modelDescription);
            model.setPrimaryKey(this.ids[this.deleteProcessed]);
            this.modelService
                .delete(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.modelDescription.modelId, model)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.deleteProcessed += 1;
                _this.deleteDeleted += 1;
                _this.cd.markForCheck();
                result_1.next(true);
            }, function () {
                _this.deleteProcessed += 1;
                _this.cd.markForCheck();
                result_1.next(false);
            });
            return result_1.pipe(filter(function () { return _this.deleteProcessed < _this.count; }), switchMap(function () { return _this.processPage(); }));
        }
        else {
            return this.modelListStore.getNext().pipe(switchMap(function (results) {
                var obs = results
                    .map(function (item) { return item.initialPrimaryKey; })
                    .map(function (pk) {
                    return switchMap(function () {
                        var req = new ReplaySubject();
                        var model = _this.createModel().deserialize(_this.modelDescription.model, {});
                        model.setUp(_this.modelDescription);
                        model.setPrimaryKey(pk);
                        _this.modelService
                            .delete(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelDescription.modelId, model)
                            .pipe(untilDestroyed(_this))
                            .subscribe(function () {
                            _this.deleteProcessed += 1;
                            _this.deleteDeleted += 1;
                            _this.cd.markForCheck();
                            req.next(true);
                        }, function () {
                            _this.deleteProcessed += 1;
                            _this.cd.markForCheck();
                            req.next(false);
                        });
                        return req;
                    });
                });
                var result = of({});
                obs.forEach(function (item) { return (result = result.pipe(item)); });
                return result;
            }), filter(function () { return _this.modelListStore.hasMore; }), switchMap(function () { return _this.processPage(); }));
        }
    };
    DeleteComponent.prototype.confirm = function () {
        this.deleteStarted = true;
        this.cd.markForCheck();
        this.processPage().pipe(untilDestroyed(this)).subscribe();
    };
    Object.defineProperty(DeleteComponent.prototype, "listParams", {
        get: function () {
            var params = __assign({}, this.params);
            if (params[PAGE_PARAM] != undefined) {
                delete params[PAGE_PARAM];
            }
            if (params['ids'] != undefined) {
                delete params['ids'];
            }
            if (params['inverseIds'] != undefined) {
                delete params['inverseIds'];
            }
            return params;
        },
        enumerable: true,
        configurable: true
    });
    return DeleteComponent;
}());
export { DeleteComponent };

import { FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ProjectPermission } from '@modules/projects';

import { ProjectPermissionControl } from './project-permission.control';

export abstract class ProjectObjectPermissionsArray<T> extends FormArray {
  public permissions: ProjectPermission[] = [];
  public controls: ProjectPermissionControl<T>[];

  protected _initialized$ = new BehaviorSubject<boolean>(false);

  constructor() {
    super([]);
  }

  get initialized$() {
    return this._initialized$.pipe(filter(item => item));
  }

  push(control: ProjectPermissionControl) {
    super.push(control);
  }

  deserialize(permissions: ProjectPermission[]) {
    this.permissions = permissions;

    this.controls.forEach(control => {
      const permission = permissions.find(
        item => item.permissionType == control.value.type && item.permissionObject == control.value.object
      );

      control.deserialize(permission);
    });
  }

  serialize(): ProjectPermission[] {
    return this.controls
      .filter(control => control.controls.actions.isSelectedAny())
      .map(control => control.serialize());
  }

  isEveryControlAllActionsSelected() {
    return this.controls.every(control => control.controls.actions.isSelectedAll());
  }

  setEveryControlAllActions(value: boolean) {
    this.controls.forEach(control => control.controls.actions.setAll(value));
  }

  toggleEveryControlAllActions() {
    const selectAll = !this.isEveryControlAllActionsSelected();
    this.setEveryControlAllActions(selectAll);
  }

  isEveryControlActionSelected(name: string) {
    return this.controls.every(control => control.controls.actions.controls[name].value);
  }

  setEveryControlAction(name: string, value: boolean) {
    this.controls.forEach(control => control.controls.actions.controls[name].setValue(value));
  }

  toggleEveryControlAction(name: string) {
    const select = !this.isEveryControlActionSelected(name);
    this.setEveryControlAction(name, select);
  }
}

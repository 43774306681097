import pickBy from 'lodash/pickBy';

import { BaseField, FieldDescription, FieldType } from '@modules/fields';

// TODO: Refactor import
import { getFieldDescriptionByType } from '../../fields/data/fields';

export enum ProjectPropertyType {
  User = 'user',
  Group = 'group',
  Global = 'global',
  Page = 'page'
}

export function getProjectPropertyTypeLabel(type: ProjectPropertyType): string {
  if ([ProjectPropertyType.Global, ProjectPropertyType.Page].includes(type)) {
    return 'variable';
  } else {
    return 'property';
  }
}

export class ProjectPropertyField implements BaseField {
  field: FieldType;
  params = {};

  deserialize(data: Object): ProjectPropertyField {
    this.field = data['field'];

    if (data['params'] !== undefined) {
      this.params = data['params'];
    }

    return this;
  }

  serialize(): Object {
    return {
      field: this.field,
      params: this.params
    };
  }
}

export class ProjectProperty {
  public uid: string;
  public type: ProjectPropertyType;
  public name: string;
  public params = {};
  public ordering: number;
  public draft = false;
  public deleted = false;
  public dateAdd: string;
  public field: ProjectPropertyField;
  public fieldDescription: FieldDescription;
  public defaultValue: any;
  public pageUid?: string;
  public saveLocal = false;

  deserialize(data: Object): ProjectProperty {
    this.uid = data['uid'];
    this.type = data['type'];
    this.name = data['name'];
    this.params = data['params'];
    this.dateAdd = data['date_add'];
    this.draft = data['draft'];
    this.deleted = data['deleted'];

    if (this.params['field']) {
      this.field = new ProjectPropertyField().deserialize(this.params['field']);
    }

    if (this.params.hasOwnProperty('default_value')) {
      this.defaultValue = this.params['default_value'];
    }

    if (this.params.hasOwnProperty('page_uid')) {
      this.pageUid = this.params['page_uid'];
    }

    if (this.params.hasOwnProperty('save_local')) {
      this.saveLocal = this.params['save_local'];
    }

    this.updateFieldDescription();

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      field: this.field ? this.field.serialize() : undefined,
      default_value: this.defaultValue,
      page_uid: this.pageUid,
      save_local: this.saveLocal
    };

    let data: Object = {
      uid: this.uid,
      type: this.type,
      name: this.name,
      params: this.params,
      ordering: this.ordering,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  updateFieldDescription() {
    const field = this.field ? this.field.field : undefined;
    this.fieldDescription = getFieldDescriptionByType(field);
  }

  getTypeLabel(): string {
    if (this.type == ProjectPropertyType.User) {
      return 'User property';
    } else if (this.type == ProjectPropertyType.Group) {
      return 'Team property';
    } else if (this.type == ProjectPropertyType.Global) {
      return 'Global variable';
    } else if (this.type == ProjectPropertyType.Page) {
      return 'Page variable';
    }
  }
}

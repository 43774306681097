import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, defer, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, share, switchMap, tap } from 'rxjs/operators';

import { LocalStorage, SessionStorage } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { ApiService, isTokenExpired, ServerRequestError, TokenOptions } from '@modules/api';
import { CurrentProjectStore, Resource } from '@modules/projects';
import { User } from '@modules/users';

import { ResponseService } from '../response/response.service';

@Injectable()
export class ProjectApiService {
  onError = new Subject<ServerRequestError>();
  refreshObs: Observable<boolean>;

  private _overrideToken$ = new BehaviorSubject<TokenOptions>(undefined);

  constructor(
    private apiService: ApiService,
    private responseService: ResponseService,
    private currentProjectStore: CurrentProjectStore,
    private sessionStorage: SessionStorage,
    private localStorage: LocalStorage,
    private http: HttpClient
  ) {}

  public apiBaseUrlForProjectResource(resource: Resource) {
    if (!resource || !resource.params['url']) {
      return;
    }
    return `${resource.params['url']}`;
  }

  public getAccessToken() {
    const projectName = this.currentProjectStore.instance.uniqueName;

    if (this.overrideToken) {
      return this.overrideToken.accessToken;
    } else if (this.isSessionScope()) {
      return this.sessionStorage.get(`jet_project_${projectName}_access_token`);
    } else {
      return this.localStorage.get(`jet_project_${projectName}_access_token`);
    }
  }

  public methodURLForProjectResource(method: string, resource: Resource) {
    return `${this.apiBaseUrlForProjectResource(resource)}${method}`;
  }

  public modelUrlForProjectResource(model: string, resource: Resource) {
    return `${this.apiBaseUrlForProjectResource(resource)}models/${model}/`;
  }

  public detailModelUrlForProjectResource(model: string, id: any, resource: Resource) {
    return `${this.apiBaseUrlForProjectResource(resource)}models/${model}/${encodeURIComponent(id)}/`;
  }

  public actionUrlForProjectResource(model: string, action: string, resource: Resource) {
    return `${this.apiBaseUrlForProjectResource(resource)}models/${model}/${action}/`;
  }

  public detailActionUrlForProjectResource(model: string, id: any, action: string, resource: Resource) {
    return `${this.apiBaseUrlForProjectResource(resource)}models/${model}/${encodeURIComponent(id)}/${action}/`;
  }

  get overrideToken(): TokenOptions {
    return this._overrideToken$.value;
  }

  get overrideToken$(): Observable<TokenOptions> {
    return this._overrideToken$.asObservable();
  }

  setOverrideToken(value: TokenOptions) {
    this._overrideToken$.next(value);
  }

  clearOverrideToken() {
    this.setOverrideToken(undefined);
  }

  getToken(
    projectName: string,
    environmentName: string,
    options: {
      user?: User;
      forceOriginal?: boolean;
      mode?: AdminMode;
    } = {}
  ): Observable<TokenOptions> {
    return this.apiService.refreshToken(options.forceOriginal).pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${projectName}/${environmentName}/token/`);
        let headers = new HttpHeaders();
        const data = {};

        if (options.user) {
          data['user'] = options.user.uid;
        }

        if (options.mode) {
          data['mode'] = options.mode;
        }

        headers = this.apiService.setHeadersToken(headers, undefined, options.forceOriginal);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => {
        return {
          accessToken: result['project_access_token'],
          accessTokenExpires: moment(result['project_access_token_expires']),
          refreshToken: result['project_refresh_token'],
          refreshTokenExpires: moment(result['project_refresh_token_expires']),
          serverTime: moment(result['server_time'])
        };
      }),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  public setHeadersToken(headers: HttpHeaders, childProjectName?: string) {
    // return this.apiService.setHeadersToken(headers, childProjectName);
    const token = this.apiService.getToken();
    const projectToken = this.apiService.getProjectToken();
    const projectName = this.currentProjectStore.instance.uniqueName;

    let tokenValue: string;
    const authorization: string[] = [];

    if (token) {
      tokenValue = `Token ${token}`;
    } else if (projectToken) {
      tokenValue = `ProjectToken ${projectToken}`;
    }

    if (childProjectName && tokenValue) {
      tokenValue = `${tokenValue};project_child=${childProjectName}`;
    }

    let accessToken: string;

    if (this.overrideToken) {
      accessToken = this.overrideToken.accessToken;
    } else if (this.isSessionScope()) {
      accessToken = this.sessionStorage.get(`jet_project_${projectName}_access_token`);
    } else {
      accessToken = this.localStorage.get(`jet_project_${projectName}_access_token`);
    }

    if (accessToken && !window['legacy_tokens']) {
      authorization.push(`JWT ${accessToken}`);
    } else if (tokenValue) {
      authorization.push(tokenValue);
    }

    if (authorization.length) {
      headers = headers.set('Authorization', authorization);
    }

    return headers;
  }

  refreshToken(): Observable<boolean> {
    return defer(() => {
      if (this.refreshObs) {
        return this.refreshObs;
      }

      const refreshObs = this.executeRefreshToken().pipe(share());
      this.refreshObs = refreshObs;
      refreshObs.subscribe(
        () => {
          this.refreshObs = undefined;
        },
        () => {
          this.refreshObs = undefined;
        }
      );
      return refreshObs;
    });
  }

  executeRefreshToken(): Observable<boolean> {
    const projectName = this.currentProjectStore.instance.uniqueName;
    let accessToken: string;
    let accessTokenExpires: moment.Moment;

    if (this.overrideToken) {
      accessToken = this.overrideToken.accessToken;
      accessTokenExpires = this.overrideToken.accessTokenExpires;
    } else if (this.isSessionScope()) {
      const expiresStr = this.sessionStorage.get(`jet_project_${projectName}_access_token_expires`);
      accessToken = this.sessionStorage.get(`jet_project_${projectName}_access_token`);
      accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    } else {
      const expiresStr = this.localStorage.get(`jet_project_${projectName}_access_token_expires`);
      accessToken = this.localStorage.get(`jet_project_${projectName}_access_token`);
      accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    }

    if (accessToken && !isTokenExpired(accessTokenExpires)) {
      return of(true);
    }

    let refreshToken: string;
    let refreshTokenExpires: moment.Moment;

    if (this.overrideToken) {
      refreshToken = this.overrideToken.refreshToken;
      refreshTokenExpires = this.overrideToken.refreshTokenExpires;
    } else if (this.isSessionScope()) {
      const expiresStr = this.sessionStorage.get(`jet_project_${projectName}_refresh_token_expires`);
      refreshToken = this.sessionStorage.get(`jet_project_${projectName}_refresh_token`);
      refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    } else {
      const expiresStr = this.localStorage.get(`jet_project_${projectName}_refresh_token_expires`);
      refreshToken = this.localStorage.get(`jet_project_${projectName}_refresh_token`);
      refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    }

    if (!refreshToken || !refreshTokenExpires || refreshTokenExpires.diff(moment(), 'minutes') < 1) {
      return of(false);
    }

    const url = this.apiService.methodURL('token/refresh/');
    const data = { refresh: refreshToken };

    return this.http.post(url, data).pipe(
      tap(result => {
        const options: TokenOptions = {
          accessToken: result['access_token'],
          accessTokenExpires: moment(result['access_token_expires']),
          serverTime: moment(result['server_time'])
        };

        if (result['refresh_token']) {
          options.refreshToken = result['refresh_token'];
        }

        if (result['refresh_token_expires']) {
          options.refreshTokenExpires = moment(result['refresh_token_expires']);
        }

        this.saveToken(options);
      }),
      map(() => true)
    );
  }

  public saveToken(options: TokenOptions) {
    const getLocalTime = (date: moment.Moment) => {
      return moment().add(date.diff(options.serverTime), 'milliseconds').toISOString();
    };
    const projectName = this.currentProjectStore.instance.uniqueName;

    if (this.overrideToken) {
      const newToken = { ...this.overrideToken };

      if (options.accessToken) {
        newToken.accessToken = options.accessToken;
        newToken.accessTokenExpires = moment(getLocalTime(options.accessTokenExpires));
      }

      if (options.refreshToken) {
        newToken.refreshToken = options.refreshToken;
        newToken.refreshTokenExpires = moment(getLocalTime(options.refreshTokenExpires));
      }

      this.setOverrideToken(newToken);
    } else if (this.isSessionScope()) {
      if (options.accessToken) {
        this.sessionStorage.set(`jet_project_${projectName}_access_token`, options.accessToken);
        this.sessionStorage.set(
          `jet_project_${projectName}_access_token_expires`,
          getLocalTime(options.accessTokenExpires)
        );
      }

      if (options.refreshToken) {
        this.sessionStorage.set(`jet_project_${projectName}_refresh_token`, options.refreshToken);
        this.sessionStorage.set(
          `jet_project_${projectName}_refresh_token_expires`,
          getLocalTime(options.refreshTokenExpires)
        );
      }
    } else {
      if (options.accessToken) {
        this.localStorage.set(`jet_project_${projectName}_access_token`, options.accessToken);
        this.localStorage.set(
          `jet_project_${projectName}_access_token_expires`,
          getLocalTime(options.accessTokenExpires)
        );
      }

      if (options.refreshToken) {
        this.localStorage.set(`jet_project_${projectName}_refresh_token`, options.refreshToken);
        this.localStorage.set(
          `jet_project_${projectName}_refresh_token_expires`,
          getLocalTime(options.refreshTokenExpires)
        );
      }
    }
  }

  public deleteToken() {
    const projectName = this.currentProjectStore.instance.uniqueName;

    if (this.overrideToken) {
      this.setOverrideToken({});
    } else if (this.isSessionScope()) {
      this.sessionStorage.remove(`jet_project_${projectName}_access_token`);
      this.sessionStorage.remove(`jet_project_${projectName}_access_token_expires`);
      this.sessionStorage.remove(`jet_project_${projectName}_refresh_token`);
      this.sessionStorage.remove(`jet_project_${projectName}_refresh_token_expires`);
    } else {
      this.localStorage.remove(`jet_project_${projectName}_access_token`);
      this.localStorage.remove(`jet_project_${projectName}_access_token_expires`);
      this.localStorage.remove(`jet_project_${projectName}_refresh_token`);
      this.localStorage.remove(`jet_project_${projectName}_refresh_token_expires`);
    }
  }

  public setHeadersBridgeSettings(headers: HttpHeaders, resource: Resource) {
    if (resource.params['bridge_settings']) {
      return headers.set('X-Bridge-Settings', resource.params['bridge_settings']);
    }

    return headers;
  }

  public catchApiError(processAuthExpire = true) {
    return catchError(error => {
      if (processAuthExpire && error instanceof HttpErrorResponse && error.status == 401) {
        console.error(error);
      }

      if (error instanceof HttpErrorResponse) {
        const warning = error.headers.get('X-Application-Warning');

        if (warning) {
          this.responseService.handleWarning(warning);
        }
      }

      const serverError = new ServerRequestError(error);

      serverError.exception = error;

      this.onError.next(serverError);

      return throwError(serverError);
    });
  }

  processApiResponse<T = Object>() {
    return map(
      (response: HttpResponse<T>): T => {
        const warning = response.headers.get('X-Application-Warning');

        if (warning) {
          this.responseService.handleWarning(warning);
        }

        return response.body;
      }
    );
  }

  public isSessionScope() {
    return this.apiService.isSessionScope();
  }
}

import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { isContainerLayer } from '@modules/views';
import { KeyboardEventKeyCode } from '@shared';

import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';

@Component({
  selector: 'app-view-editor-menu-edit',
  templateUrl: './view-editor-menu-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorMenuEditComponent implements OnInit, OnDestroy {
  @Input() trigger: CdkOverlayOrigin;
  @Output() close = new EventEmitter<void>();

  dropdownHover$ = new BehaviorSubject<boolean>(false);
  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'end', overlayX: 'start', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'start', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
    { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 }
  ];

  constructor(public editorContext: ViewEditorContext, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(
      merge(
        fromEvent<MouseEvent>(this.trigger.elementRef.nativeElement, 'mouseenter').pipe(map(() => true)),
        fromEvent<MouseEvent>(this.trigger.elementRef.nativeElement, 'mouseleave').pipe(map(() => false))
      ),
      this.dropdownHover$
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(([triggerHover, dropdownHover]) => {
        this.setDropdownOpened(triggerHover || dropdownHover);
      });
  }

  ngOnDestroy(): void {}

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }

  undo() {
    if (this.editorContext.isUndoAvailable()) {
      this.editorContext.undo();
    }
  }

  redo() {
    if (this.editorContext.isRedoAvailable()) {
      this.editorContext.redo();
    }
  }

  isBufferLayersSet(): boolean {
    return this.editorContext.getBufferLayers().length > 0;
  }

  cutLayers() {
    const initDict: KeyboardEventInit = { key: 'x', metaKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.X;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  copyLayers() {
    const initDict: KeyboardEventInit = { key: 'c', metaKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.C;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  pasteBufferLayers() {
    const initDict: KeyboardEventInit = { key: 'v', metaKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.V;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  isLayerSelected(): boolean {
    return this.editorContext.customizingLayers$.value.length > 0;
  }

  isUngroupAvailable(): boolean {
    return this.editorContext.customizingLayers$.value.some(item => isContainerLayer(item));
  }

  isSelectAllAvailable(): boolean {
    return (
      this.editorContext.view$.value.layers.length &&
      !this.editorContext.view$.value.layers.every(layer => {
        return !!this.editorContext.customizingLayers$.value.find(item => item.isSame(layer));
      })
    );
  }

  isSelectInverseAvailable(): boolean {
    return this.editorContext.view$.value.layers.length > 0;
  }

  groupSelected() {
    const initDict: KeyboardEventInit = { key: 'g', metaKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.G;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  frameSelected() {
    const initDict: KeyboardEventInit = { key: 'g', metaKey: true, altKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.G;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  ungroupSelected() {
    const initDict: KeyboardEventInit = { key: 'backspace', metaKey: true };
    initDict['keyCode'] = KeyboardEventKeyCode.Backspace;
    const event = new KeyboardEvent('keydown', initDict);
    this.editorContext.emitKeydownEvent(event);
  }

  selectAll() {
    const layers = this.editorContext.view$.value.layers;
    this.editorContext.setCustomizingLayer(...layers);
  }

  selectNone() {
    this.editorContext.resetCustomizingLayers();
  }

  selectInverse() {
    const layers = this.editorContext.view$.value.layers.filter(layer => {
      return !this.editorContext.customizingLayers$.value.find(item => item.isSame(layer));
    });

    this.editorContext.setCustomizingLayer(...layers);
  }

  onItemSelected() {
    this.setDropdownOpened(false);
    this.close.emit();
  }
}

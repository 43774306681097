import isObject from 'lodash/isObject';
import repeat from 'lodash/repeat';
import trimEnd from 'lodash/trimEnd';
import Delta from 'quill-delta';

// TODO: Refactor import
import { Input } from '../../../../modules/fields/data/input';

import { defaultConverters, getQuillConverters } from './converters';
import { MarkdownNode } from './markdown-node';

export function quillDeltaToMarkdown(
  delta: Delta,
  options: {
    applyContextFormula: (input: Input) => any;
    addContextFormulaClass?: boolean;
  }
): string {
  if (!delta) {
    return;
  }

  const converters = getQuillConverters(options);
  return new QuillToMarkdownConverter({ converters: converters }).convert(delta.ops);
}

export interface BlockGroup {
  name: string;
  value: any;
  nodes: MarkdownNode[];
}

export class QuillToMarkdownConverter {
  rootNode: MarkdownNode;
  lineNode: MarkdownNode;
  blockGroup: BlockGroup;
  converters = defaultConverters;

  constructor(options: Partial<QuillToMarkdownConverter> = {}) {
    Object.assign(this, options);
  }

  newLine() {
    const node = new MarkdownNode({ open: '', close: '\n' });
    this.lineNode = node;
    this.rootNode.appendNode(node);
  }

  isBlock(op): boolean {
    if (!op.attributes) {
      return false;
    }

    for (const attr of Object.keys(op.attributes)) {
      if (this.converters.block[attr]) {
        return true;
      }
    }

    return false;
  }

  isSameBlockGroup(op): boolean {
    if (!this.blockGroup || !op.attributes) {
      return false;
    }

    for (const attr of Object.keys(op.attributes)) {
      if (this.blockGroup.name == attr && this.blockGroup.value == op.attributes[attr]) {
        return true;
      }
    }

    return false;
  }

  convert(ops): string {
    this.rootNode = new MarkdownNode();

    this.newLine();

    for (let i = 0; i < ops.length; ++i) {
      const op = ops[i];

      if (isObject(op.insert)) {
        if (op.insert) {
          for (const embed of Object.keys(op.insert)) {
            if (this.converters.embed[embed]) {
              const value = op.insert[embed];
              const appendNode = this.converters.embed[embed](value, op.attributes, this.lineNode);

              if (!appendNode) {
                continue;
              }

              this.lineNode.appendNode(appendNode);
            }
          }
        }
      } else if (this.isBlock(op)) {
        for (const attr of Object.keys(op.attributes)) {
          if (this.converters.block[attr]) {
            const value = op.attributes[attr];

            if (!this.blockGroup || !this.isSameBlockGroup(op)) {
              this.blockGroup = { name: attr, value: value, nodes: [this.lineNode] };
            } else {
              this.blockGroup.nodes.push(this.lineNode);
            }

            const wrap = this.converters.block[attr](value, this.blockGroup);

            if (!wrap) {
              continue;
            }

            this.lineNode.children.forEach(child => wrap.appendNode(child));
            this.lineNode.appendNode(wrap);
          }
        }

        const lines = op.insert.split('\n');
        lines.slice(1).forEach(() => this.newLine());
      } else if (typeof op.insert === 'string') {
        const lines = op.insert.split('\n');

        for (let l = 0; l < lines.length; ++l) {
          let line = lines[l];

          if (l > 0) {
            this.newLine();
          }

          if (line === '') {
            continue;
          }

          const indentMatch = !this.lineNode.children.length ? line.match(/^(\s+)/) : undefined;
          if (indentMatch) {
            line = repeat('&nbsp;', indentMatch[1].length) + line.substring(indentMatch[1].length);
          }

          let appendNode = new MarkdownNode({ text: line });

          if (op.attributes) {
            for (const attr of Object.keys(op.attributes)) {
              if (this.converters.inline[attr]) {
                const value = op.attributes[attr];
                const [open, close] = this.converters.inline[attr](value);
                const wrap = new MarkdownNode({ open: open, close: close });
                wrap.appendNode(appendNode);
                appendNode = wrap;
              }
            }
          }

          this.lineNode.appendNode(appendNode);
        }
      }
    }

    for (let i = 0; i < this.rootNode.children.length; ++i) {
      const prev = this.rootNode.children[i - 1];
      const current = this.rootNode.children[i];

      if (
        prev &&
        prev.children.length &&
        !prev.children.some(item => item.block) &&
        prev.close == '\n' &&
        current.children.length &&
        !current.children.some(item => item.block)
      ) {
        prev.close = `<br>${prev.close}`;
      }
    }

    const result = this.rootNode.render();
    return trimEnd(result) + '\n';
  }
}

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { delayWhen, map } from 'rxjs/operators';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { ViewSettingsStore } from '@modules/customize';
import { PublishService } from '@modules/customize-utils';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, DraftItemsType, EnvironmentService, ProjectPropertyStore } from '@modules/projects';
import { isSet } from '@shared';
import { DraftChangeItemType } from '../draft-changes-item/draft-changes-item.component';
var DraftChangesComponent = /** @class */ (function () {
    function DraftChangesComponent(currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionStore, viewSettingsStore, menuSettingsStore, customViewsStore, taskQueueStore, projectPropertyStore, projectSettingsStore, environmentService, publishService, analyticsService, dialogService, popupComponent, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.customViewsStore = customViewsStore;
        this.taskQueueStore = taskQueueStore;
        this.projectPropertyStore = projectPropertyStore;
        this.projectSettingsStore = projectSettingsStore;
        this.environmentService = environmentService;
        this.publishService = publishService;
        this.analyticsService = analyticsService;
        this.dialogService = dialogService;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.loading = true;
        this.publishDraftLoading = false;
        this.deleteDraftLoading = false;
        this.changes = [];
    }
    DraftChangesComponent.prototype.ngOnInit = function () {
        this.initChanges();
    };
    DraftChangesComponent.prototype.ngOnDestroy = function () { };
    DraftChangesComponent.prototype.initChanges = function () {
        var _this = this;
        combineLatest(this.viewSettingsStore.get(false, { includeDeleted: true }), this.modelDescriptionStore.get(false, { includeDeleted: true }), this.actionStore.get(false, { includeDeleted: true }), this.menuSettingsStore.get(false, { includeDeleted: true }), this.customViewsStore.get(false, { includeDeleted: true }), combineLatest(this.currentProjectStore.get(false), this.currentEnvironmentStore.instance$).pipe(map(function (_a) {
            var project = _a[0], environment = _a[1];
            return project.getEnvironmentResources(environment.uniqueName, { includeDeleted: true });
        })), this.taskQueueStore.get(false, { includeDeleted: true }), this.projectPropertyStore.get(false, { includeDeleted: true }), this.projectSettingsStore.get(false, { includeDeleted: true }))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var viewSettings = _a[0], modelDescriptions = _a[1], actionDescriptions = _a[2], menuSettings = _a[3], customViews = _a[4], resources = _a[5], taskQueues = _a[6], projectProperties = _a[7], projectSettings = _a[8];
            var secretTokens = resources.reduce(function (acc, resource) {
                acc.push.apply(acc, resource.secretTokensWithDeleted.map(function (item) { return ({
                    item: item,
                    resource: resource
                }); }));
                return acc;
            }, []);
            var storages = resources.reduce(function (acc, resource) {
                acc.push.apply(acc, resource.storagesWithDeleted.map(function (item) { return ({
                    item: item,
                    resource: resource
                }); }));
                return acc;
            }, []);
            var detectChangeType = function (item, previousItem) {
                if (item.deleted) {
                    return DraftChangeItemType.Deleted;
                }
                else if (previousItem) {
                    return DraftChangeItemType.Updated;
                }
                else {
                    return DraftChangeItemType.Created;
                }
            };
            _this.changes = [
                {
                    type: DraftItemsType.ViewSettings,
                    label: 'Pages',
                    items: viewSettings
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var resource = resources.find(function (i) { return i.uniqueName == item.resource; });
                        var name = resource ? [resource.name, item.name].join(' - ') : item.name;
                        var previousItem = viewSettings.find(function (i) { return !i.draft && i.uniqueName == item.uniqueName; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.uid
                        };
                    }),
                    count: viewSettings.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.MenuSettings,
                    label: 'Menu settings',
                    items: [],
                    singleItem: true,
                    count: menuSettings && menuSettings.draft ? 1 : 0
                },
                {
                    type: DraftItemsType.Resources,
                    label: 'Resources',
                    items: resources
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var name = item.name;
                        var previousItem = resources.find(function (i) { return !i.draft && i.uniqueName == item.uniqueName; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.uniqueName
                        };
                    }),
                    count: resources.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ModelDescriptions,
                    label: 'Collections',
                    items: modelDescriptions
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var resource = resources.find(function (i) { return i.uniqueName == item.resource; });
                        var name = resource ? [resource.name, item.verboseNamePlural].join(' - ') : item.verboseNamePlural;
                        var previousItem = modelDescriptions.find(function (i) { return !i.draft && i.isSame(item); });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.modelId
                        };
                    }),
                    count: modelDescriptions.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ActionDescriptions,
                    label: 'Actions',
                    items: actionDescriptions
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var resource = resources.find(function (i) { return i.uniqueName == item.resource; });
                        var name = resource ? [resource.name, item.verboseName].join(' - ') : item.verboseName;
                        var previousItem = actionDescriptions.find(function (i) { return !i.draft && i.isSame(item); });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.id
                        };
                    }),
                    count: actionDescriptions.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.SecretTokens,
                    label: 'Credentials',
                    items: secretTokens
                        .filter(function (item) { return item.item.draft; })
                        .map(function (item) {
                        var name = [item.resource.name, item.item.name].join(' - ');
                        var previousItem = secretTokens.find(function (i) { return !i.item.draft && i.item.resource == item.item.resource && i.item.name == item.item.name; });
                        return {
                            type: detectChangeType(item.item, previousItem),
                            name: name,
                            id: [item.resource.uniqueName, item.item.name].join('.')
                        };
                    }),
                    count: secretTokens.filter(function (item) { return item.item.draft; }).length
                },
                {
                    type: DraftItemsType.Storages,
                    label: 'Storages',
                    items: storages
                        .filter(function (item) { return item.item.draft; })
                        .map(function (item) {
                        var name = [item.resource.name, item.item.name].join(' - ');
                        var previousItem = storages.find(function (i) {
                            return !i.item.draft &&
                                i.resource.uniqueName == item.resource.uniqueName &&
                                i.item.uniqueName == item.item.uniqueName;
                        });
                        return {
                            type: detectChangeType(item.item, previousItem),
                            name: name,
                            id: [item.resource.uniqueName, item.item.uniqueName].join('.')
                        };
                    }),
                    count: storages.filter(function (item) { return item.item.draft; }).length
                },
                {
                    type: DraftItemsType.CustomViews,
                    label: 'Custom views',
                    items: customViews
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var page = isSet(item.pageUid) ? viewSettings.find(function (i) { return i.uid == item.pageUid; }) : undefined;
                        var name = item.getViewTypeStr({ page: page ? page.name : undefined, element: item.elementUid });
                        var previousItem = customViews.find(function (i) { return !i.draft && i.uniqueName == item.uniqueName; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.uniqueName
                        };
                    }),
                    count: customViews.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.TaskQueues,
                    label: 'Task queues',
                    items: taskQueues
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var name = item.name;
                        var previousItem = taskQueues.find(function (i) { return !i.draft && i.uid == item.uid; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.uid
                        };
                    }),
                    count: taskQueues.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ProjectProperties,
                    label: 'Properties',
                    items: projectProperties
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var name = item.name;
                        var previousItem = projectProperties.find(function (i) { return !i.draft && i.type == item.type && i.uid == item.uid; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: [item.type, item.uid].join('.')
                        };
                    }),
                    count: projectProperties.filter(function (item) { return item.draft; }).length
                },
                {
                    type: DraftItemsType.ProjectSettings,
                    label: 'App settings',
                    items: projectSettings
                        .filter(function (item) { return item.draft; })
                        .map(function (item) {
                        var name = item.getLabel() || item.name;
                        var previousItem = projectSettings.find(function (i) { return !i.draft && i.name == item.name; });
                        return {
                            type: detectChangeType(item, previousItem),
                            name: name,
                            id: item.name
                        };
                    }),
                    count: projectSettings.filter(function (item) { return item.draft; }).length
                }
            ].filter(function (item) { return item.count; });
            _this.loading = false;
            _this.cd.markForCheck();
            if (!_this.changes.length) {
                _this.close();
            }
        });
    };
    DraftChangesComponent.prototype.close = function () {
        this.popupComponent.close();
    };
    DraftChangesComponent.prototype.countEntity = function (item, options) {
        if (options === void 0) { options = {}; }
        var all = false;
        var count;
        if (item.singleItem) {
            all = true;
        }
        else {
            if (isSet(options.viewSettings)) {
                count = options.viewSettings.length;
            }
            else if (isSet(options.modelDescriptions)) {
                count = options.modelDescriptions.length;
            }
            else if (isSet(options.actionDescriptions)) {
                count = options.actionDescriptions.length;
            }
            else if (isSet(options.resources)) {
                count = options.resources.length;
            }
            else if (isSet(options.secretTokens)) {
                count = options.secretTokens.length;
            }
            else if (isSet(options.storages)) {
                count = options.storages.length;
            }
            else if (isSet(options.customViews)) {
                count = options.customViews.length;
            }
            else if (isSet(options.taskQueues)) {
                count = options.taskQueues.length;
            }
            else {
                all = true;
                count = item.count;
            }
        }
        return {
            all: all,
            count: count
        };
    };
    DraftChangesComponent.prototype.publishDraft = function (item, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPublish, {
            ItemsType: options.itemsType,
            ItemsOptions: options
        });
        this.publishDraftLoading = true;
        this.cd.markForCheck();
        return this.environmentService
            .publishDraft(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, options)
            .pipe(delayWhen(function () { return _this.publishService.reloadChanges([{ type: item.type }]); }), delayWhen(function () { return _this.publishService.updatePagePermissions(); }), untilDestroyed(this))
            .subscribe(function () {
            _this.publishDraftLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Published, {
                ItemsType: options.itemsType,
                ItemsOptions: options
            });
        }, function () {
            _this.publishDraftLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.PublishFailed, {
                ItemsType: options.itemsType,
                ItemsOptions: options
            });
        });
    };
    DraftChangesComponent.prototype.deleteDraft = function (item, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickDiscard, {
            ItemsType: options.itemsType,
            ItemsOptions: options
        });
        this.deleteDraftLoading = true;
        this.cd.markForCheck();
        var _a = this.countEntity(item, options), all = _a.all, count = _a.count;
        this.dialogService
            .dialog({
            title: all
                ? "Are you sure want to discard all " + item.label + " draft changes" + (count !== undefined ? " (" + count + ")" : '') + "?"
                : "Are you sure want to discard selected " + item.label + " draft changes" + (count !== undefined ? " (" + count + ")" : '') + "?",
            description: all
                ? "\n          All " + item.label + " will be restored to published state.<br>\n          Other environments will not be affected.\n        "
                : "\n          Only selected " + item.label + " will be restored to published state.<br>\n          Other environments will not be affected.\n        ",
            style: 'orange',
            buttons: [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'ok',
                    label: 'Discard changes',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit,
                    executor: function () {
                        return _this.environmentService.deleteDraft(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, options);
                    }
                }
            ]
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.button == 'ok') {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Discarded, {
                    ItemsType: options.itemsType,
                    ItemsOptions: options
                });
                window.location.reload();
            }
            else {
                _this.deleteDraftLoading = false;
                _this.cd.markForCheck();
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardCancelled, {
                    ItemsType: options.itemsType,
                    ItemsOptions: options
                });
            }
        }, function () {
            _this.deleteDraftLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardFailed, {
                ItemsType: options.itemsType,
                ItemsOptions: options
            });
        });
    };
    return DraftChangesComponent;
}());
export { DraftChangesComponent };

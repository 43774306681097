var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from 'moment';
import { DefaultType, FieldType, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import { generateUUID, isSet } from '@shared';
import { ModelDbField } from '../../data/model-db-field';
import { ModelFlexField } from '../../data/model-flex-field';
// TODO: make common for actions and dbFields
export function getDefaultValue(field) {
    var fieldDescription = getFieldDescriptionByType(field.field);
    var result = fieldDescription.defaultValue;
    if (field.defaultType == DefaultType.Value) {
        result = field.defaultValue;
    }
    else if (field.defaultType == DefaultType.DatetimeNow) {
        result = moment().toISOString();
    }
    else if (field.defaultType == DefaultType.UUID) {
        result = generateUUID();
    }
    // if (field.null === false && result === null) {
    //   result = undefined;
    // }
    return result;
}
export function detectPrimaryKey(fields) {
    var idField = fields.find(function (item) { return item.toLowerCase() == 'id'; });
    if (idField) {
        return idField;
    }
    return fields[0];
}
export function modelDbFieldToParameterField(item) {
    var parameter = new ParameterField();
    parameter.name = item.name;
    parameter.verboseName = item.verboseName;
    parameter.description = item.description;
    parameter.field = item.field;
    parameter.required = item.required;
    parameter.defaultType = item.defaultType;
    parameter.defaultValue = item.defaultValue;
    parameter.placeholder = item.placeholder;
    parameter.validatorType = item.validatorType;
    parameter.validatorParams = item.validatorParams;
    parameter.params = item.params;
    parameter.updateFieldDescription();
    return parameter;
}
export function modelFieldToField(modelField) {
    return __assign({ name: modelField.item.name, verboseName: modelField.item.verboseName, description: modelField.item.description, field: modelField.item.field, params: modelField.item.params }, (modelField.item instanceof ModelDbField
        ? {
            required: modelField.item.required,
            editable: modelField.item.editable,
            null: modelField.item.null,
            defaultType: modelField.item.defaultType,
            defaultValue: modelField.item.defaultValue
        }
        : {}), (modelField.item instanceof ModelFlexField
        ? {
            flex: true,
            query: modelField.item.query,
            code: modelField.item.code
        }
        : {}));
}
export function forceModelId(modelId, resource) {
    if (!isSet(modelId)) {
        return modelId;
    }
    if (modelId.startsWith(resource + ".")) {
        return modelId;
    }
    else if (modelId.indexOf('.') != -1) {
        return modelId;
    }
    else {
        return [resource, modelId].join('.');
    }
}
export function traverseModelPath(modelDescription, path, modelDescriptions, acc) {
    if (acc === void 0) { acc = []; }
    if (!modelDescription || !path.length) {
        return;
    }
    var name = path[0];
    var field = modelDescription.dbField(name);
    var relation = modelDescription.relation(name);
    if (field) {
        var pathResult = {
            modelDescription: modelDescription,
            name: field.name,
            verboseName: field.verboseName || field.name,
            field: field
        };
        if (path.length == 1) {
            return acc.concat([pathResult]);
        }
        else if (field.field == FieldType.RelatedModel) {
            var modelId_1 = field.params ? field.params['related_model']['model'] : undefined;
            var relatedModel = modelDescriptions.find(function (item) { return item.isSame(modelId_1); });
            pathResult.relatedModel = relatedModel;
            return traverseModelPath(relatedModel, path.slice(1), modelDescriptions, acc.concat([pathResult]));
        }
    }
    else if (relation) {
        var relatedModel = modelDescriptions.find(function (item) { return item.isSame(relation.relatedModel); });
        var pathResult = {
            modelDescription: modelDescription,
            name: relation.name,
            verboseName: relatedModel.verboseNamePlural,
            relation: relation,
            relatedModel: relatedModel
        };
        if (path.length == 1) {
            return acc.concat([pathResult]);
        }
        else {
            return traverseModelPath(relatedModel, path.slice(1), modelDescriptions, acc.concat([pathResult]));
        }
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UniqueIdToken } from '@common/unique-id';
import { LocalStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { FeatureService } from '@modules/features';
import { CustomSelectItem, CustomSelectItemButton, Option } from '@modules/field-components';
import { FormField } from '@modules/fields';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  isResourceTypeItem3rdParty,
  isResourceTypeItemCustom,
  Resource,
  ResourceName,
  ResourceTypeItem,
  resourceTypeItems
} from '@modules/projects';
import { controlValue, deployUrl, objectsSortPredicate } from '@shared';

import { getResourceSettingsComponent } from '../../data/resource-settings-components';
import { ResourceEditController } from '../../services/resource-edit-controller/resource-edit.controller';
import { ResourceSummaryService } from '../../services/resource-summary/resource-summary.service';

@Component({
  selector: 'app-resource-field2',
  templateUrl: './resource-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceFieldComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() value: any;
  @Input() label = true;
  @Input() errors = true;
  @Input() card = false;
  @Input() autofocus = false;
  @Input() context = {};
  @Input() manualMargin = false;

  idToken = new UniqueIdToken();
  currentValue: string;
  valueResource: Resource;
  valueResourceSettings = false;
  valueStr: string;
  valueIcon: string;
  resourceItems: CustomSelectItem<string>[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceEditController: ResourceEditController,
    private resourceSummaryService: ResourceSummaryService,
    private featureService: FeatureService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef,
    private localStorage: LocalStorage
  ) {}

  ngOnInit() {
    this.currentEnvironmentStore.resources$
      .pipe(untilDestroyed(this))
      .subscribe(resourcesAll => this.updateItems(resourcesAll));

    controlValue<string>(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.currentValue = value;
        this.updateValue();
      });
  }

  ngOnDestroy(): void {}

  get control(): FormControl {
    if (!this.form || !this.field) {
      return;
    }

    return this.form.controls[this.field.name] as FormControl;
  }

  isResourceEnabled(typeItem: ResourceTypeItem, resource?: Resource): boolean {
    if (!typeItem) {
      return false;
    }

    if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
      typeItem = resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL);
    }

    const modelResources = this.field.params['model_resources'] as boolean;
    const actionResources = this.field.params['action_resources'] as boolean;
    const storageResources = this.field.params['storage_resources'] as boolean;
    const chartsResources = this.field.params['charts_resources'] as boolean;

    if ([modelResources, actionResources, storageResources, chartsResources].every(item => !item)) {
      return true;
    }

    if (modelResources && typeItem.modelsEnabled) {
      return true;
    } else if (actionResources && typeItem.actionsEnabled) {
      return true;
    } else if (storageResources && typeItem.storagesEnabled) {
      return true;
    } else if (chartsResources && typeItem.chartsEnabled) {
      return true;
    } else {
      return false;
    }
  }

  updateValue() {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.currentValue);

    this.valueResource = resource;
    this.valueResourceSettings = resource ? !!getResourceSettingsComponent(resource.typeItem.name) : false;
    this.valueStr = resource ? resource.name : undefined;
    this.valueIcon = resource ? resource.icon : undefined;
    this.cd.markForCheck();
  }

  updateItems(resourcesAll: Resource[]) {
    const resourceTypes = resourceTypeItems.filter(item => !item.hidden && this.isResourceEnabled(item));
    const userResourcesKey = `last_used_resources_${this.currentProjectStore.uniqueName}`;
    const localStorageResources = this.localStorage.get(userResourcesKey);
    const lastUsedResources = localStorageResources ? JSON.parse(localStorageResources) : {};
    const defaultPredicate = objectsSortPredicate('demo', 'name');
    const resources = resourcesAll
      ? resourcesAll
          .filter(item => this.isResourceEnabled(item.typeItem, item))
          .sort((lhs, rhs) => {
            const lhsLastUsed = lastUsedResources[lhs.uniqueName]
              ? moment(lastUsedResources[lhs.uniqueName])
              : undefined;
            const rhsLastUsed = lastUsedResources[rhs.uniqueName]
              ? moment(lastUsedResources[rhs.uniqueName])
              : undefined;

            if (!lhsLastUsed && !rhsLastUsed) {
              return defaultPredicate(lhs, rhs);
            } else if (lhsLastUsed && !rhsLastUsed) {
              return -1;
            } else if (!lhsLastUsed && rhsLastUsed) {
              return 1;
            } else if (lhsLastUsed && rhsLastUsed) {
              return (lhsLastUsed.unix() - rhsLastUsed.unix()) * -1;
            }
          })
      : [];
    const userResources = resources.filter(item => !item.demo);
    const demoResources = resources.filter(
      item => item.demo && (item.featured || this.control.value == item.uniqueName)
    );

    this.resourceItems = userResources.length
      ? [
          {
            button: {
              icon: 'plus',
              label: 'Connect Resource'
            },
            children: resourceTypes
              .filter(item => !item.protected)
              .map(item => {
                return {
                  button: {
                    name: 'add_resource',
                    label: item.label,
                    image: deployUrl(`/assets/images/resources/icons/${item.icon}.svg`),
                    data: { resource: item }
                  }
                };
              }),
            stickyTop: true,
            orange: true
          },
          ...userResources.map(item => {
            return {
              option: {
                value: item.uniqueName,
                name: item.name,
                image: item.icon
              }
            };
          }),
          {
            button: {
              icon: 'play_2',
              label: 'Demo'
            },
            children: demoResources.map(item => {
              return {
                option: {
                  value: item.uniqueName,
                  name: item.name,
                  image: item.icon
                },
                valueTag: 'DEMO'
              };
            }),
            stickyBottom: true,
            large: true
          }
        ]
      : [
          ...resourceTypes.map(item => {
            return {
              button: {
                name: 'add_resource',
                label: item.label,
                image: deployUrl(`/assets/images/resources/icons/${item.icon}.svg`),
                data: { resource: item }
              },
              subtitle: 'Connect Resource'
            };
          }),
          {
            button: {
              icon: 'play_2',
              label: 'Demo'
            },
            children: demoResources.map(item => {
              return {
                option: {
                  value: item.uniqueName,
                  name: item.name,
                  image: item.icon
                },
                valueTag: 'DEMO'
              };
            }),
            stickyBottom: true,
            large: true
          }
        ];

    this.cd.markForCheck();
  }

  openResourceSettings(typeItem: ResourceTypeItem, resource?: Resource) {
    if (
      isResourceTypeItem3rdParty(typeItem) &&
      !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Business Apps</strong>',
        description: `
          Connect data from various business apps. You can see all your data and take action in one place.
        `
      });
      return;
    } else if (
      isResourceTypeItemCustom(typeItem) &&
      !this.currentProjectStore.instance.features.isCustomResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Custom Queries</strong>',
        description: `
          Connect data from custom queries. You can see all your data and take action in one place.
        `
      });
      return;
    }

    if (!resource) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
        Source: 'component',
        ResourceID: typeItem.name
      });
    }

    this.resourceEditController
      .openEditPopup(typeItem, resource, {
        resourceNameEditing: true,
        analyticsSource: 'component'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.resource) {
          return;
        }

        this.selectExistingResource(result.resource);
        this.updateValue();

        // if (!resource) {
        //   this.resourceSummaryService.open(result.resource).subscribe();
        // }
      });
  }

  selectExistingResource(resource: Resource) {
    const value = this.form.value[this.field.name];
    const resourceName = resource ? resource.uniqueName : undefined;

    if (resourceName) {
      this.updateLastUsed(resourceName);
    }

    if (value != resourceName) {
      if (this.control) {
        this.control.patchValue(resourceName);
        this.control.markAsDirty();
      }
    }
  }

  updateLastUsed(resourceName: string) {
    const userResourcesKey = `last_used_resources_${this.currentProjectStore.uniqueName}`;
    const resources = this.localStorage.get(userResourcesKey);

    const lastUsedResources = resources ? JSON.parse(resources) : {};
    lastUsedResources[resourceName] = moment().toISOString();
    this.localStorage.set(userResourcesKey, JSON.stringify(lastUsedResources));

    this.updateItems(this.currentEnvironmentStore.resources);
  }

  onOptionClick(option: Option<string>) {
    if (option.value) {
      this.updateLastUsed(option.value);
    }
  }

  onButtonClick(button: CustomSelectItemButton) {
    if (button.name == 'add_resource') {
      this.openResourceSettings(button.data['resource']);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { FieldLookup } from './base';
var InFieldLookup = /** @class */ (function (_super) {
    __extends(InFieldLookup, _super);
    function InFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'in';
        _this.param = 'in';
        _this.verboseName = localize('one of');
        _this.icon = 'one_of';
        return _this;
    }
    InFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not one of {1}', [field, value]);
            }
            else {
                return localize('is not one of');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is one of {1}', [field, value]);
            }
            else {
                return localize('is one of');
            }
        }
    };
    return InFieldLookup;
}(FieldLookup));
export { InFieldLookup };

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  EditableField,
  getDefaultValue,
  getFieldDescriptionByType,
  getValidatorByType,
  ParameterField
} from '@modules/fields';

@Injectable()
export class BaseActionExecuteForm implements OnDestroy {
  form: FormGroup;
  updateValidatorSubscriptions: Subscription[] = [];

  ngOnDestroy(): void {}

  init(actionParams: ParameterField[], options: { context?: ViewContext; contextElement?: ViewContextElement } = {}) {
    this.updateValidatorSubscriptions.forEach(item => item.unsubscribe());
    this.updateValidatorSubscriptions = [];

    const group = {};

    actionParams.forEach(item => {
      const validators = [];
      const fieldDescription = getFieldDescriptionByType(item.field);
      const defaultValue = getDefaultValue(item);

      if (item.required) {
        validators.push(Validators.required);
      }

      if (fieldDescription.validators) {
        validators.push(...fieldDescription.validators);
      }

      const editableField = item as EditableField;
      const validator = getValidatorByType(editableField.validatorType, editableField.validatorParams, {
        context: options.context,
        contextElement: options.contextElement
      });

      if (validator.func) {
        validators.push(validator.func);
      }

      const control = new FormControl(defaultValue, validators);

      group[item.name] = control;

      if (validator.contextDependent && options.context) {
        this.updateValidatorSubscriptions.push(
          options.context.outputValues$
            .pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
            .pipe(untilDestroyed(this))
            .subscribe(() => control.updateValueAndValidity())
        );
      }
    });

    this.form = new FormGroup(group);
  }

  deinit() {
    this.form = undefined;
  }

  submit(): Object {
    const value = this.form.value;

    return value;
  }
}

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="backdrop"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="overlay-content overlay-content_animated">
    <app-dropdown-options
      [source]="source"
      [options]="options"
      [selectedValues]="selectedValues"
      [displaySelected]="displaySelected"
      [compareWith]="compareWith"
      [emptyPlaceholder]="emptyPlaceholder"
      [searchEnabled]="searchEnabled"
      [searchAutofocus]="searchAutofocus"
      [searchExternalControl]="searchExternalControl"
      [searchDebounce]="searchDebounce"
      [addValueEnabled]="addValueEnabled"
      (valueSelected)="onSelected($event)"
      (addValue)="addValue.emit($event)"
      (rootClick)="markDropdownMouseEvent($event)"
      (cdkObserveContent)="onContentChanged()"
    >
      <ng-container dropdown-top>
        <ng-content select="ng-container[dropdown-top]"></ng-content>
      </ng-container>

      <ng-container dropdown-bottom>
        <ng-content select="ng-container[dropdown-bottom]"></ng-content>
      </ng-container>
    </app-dropdown-options>
  </div>
</ng-template>

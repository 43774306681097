import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { addClass, removeClass } from '@shared';

export const BODY_CONTENTFUL_RIGHT_BAR_CLASS = 'body_contentful-right-bar';

@Component({
  selector: 'app-sidebar-submit',
  templateUrl: './sidebar-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSubmitComponent implements OnInit, OnDestroy, OnChanges {
  @Input() saveEnabled = true;
  @Input() saveDisabled = false;
  @Input() saveLoading = false;
  @Input() saveLabel = 'Save';
  @Input() backEnabled = false;
  @Input() backLabel = 'Back';
  @Input() deleteEnabled = false;
  @Input() cancelEnabled = true;
  @Input() errors: string[];
  @Input() cancelLabel = 'Cancel';
  @Output() cancelClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();

  visible: boolean;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.setVisibleClass(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.visible = this.saveEnabled || this.cancelEnabled || this.backEnabled || this.deleteEnabled;
    this.setVisibleClass(this.visible);
  }

  setVisibleClass(visible: boolean) {
    if (visible) {
      addClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
    } else {
      removeClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
    }
  }
}

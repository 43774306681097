import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { controlValue } from '@shared';

import { Option } from '../../data/option';

export interface CustomSelectItemButton {
  name?: string;
  label: string;
  icon?: string;
  image?: string;
  data?: Object;
}

export interface CustomSelectItem<T = any> {
  option?: Option<T>;
  button?: CustomSelectItemButton;
  valueTag?: string;
  valueIcon?: string;
  valueImage?: string;
  valueLabel?: string;
  stickyBottom?: boolean;
  stickyTop?: boolean;
  orange?: boolean;
  large?: boolean;
  children?: CustomSelectItem<T>[];
  subtitle?: string;
  data?: Object;
}

export function flattenItems(items: CustomSelectItem[]): CustomSelectItem[] {
  return items.reduce((acc, item) => {
    acc.push(item);

    if (item.children) {
      acc.push(...flattenItems(item.children));
    }

    return acc;
  }, []);
}

export function flattenItemOptions(items: CustomSelectItem[]): Option[] {
  return flattenItems(items)
    .filter(item => item.option)
    .map(item => item.option);
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: AbstractControl;
  @Input() items: CustomSelectItem[] = [];
  @Input() compareWith: (o1: any, o2: any) => boolean = this.defaultCompare;
  @Input() emptyLabel = 'No items';
  @Input() chooseLabel = 'Choose';
  @Input() resetEnabled = false;
  @Input() resetValue = undefined;
  @Input() classes: string[] = [];
  @Input() panelClasses: string[] = [];
  @Input() capitalize = true;
  @Input() allowSearch = true;
  @Output() optionClick = new EventEmitter<Option>();
  @Output() buttonClick = new EventEmitter<CustomSelectItemButton>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  optionItemsFlatten: CustomSelectItem[] = [];
  currentOptionItem: CustomSelectItem;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.updateCurrentOption(value);
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.optionItemsFlatten = this.items ? flattenItems(this.items).filter(item => item.option) : [];
    }

    if (changes['items'] || changes['options'] || changes['compareWith']) {
      this.updateCurrentOption(this.control.value);
    }
  }

  get panelClassesStr() {
    const classes = [...this.panelClasses];

    // if (this.searchEnabled) {
    classes.push('mat-menu-panel_no-top-padding');
    // }

    // if (this.filteredOptions.find(item => item.data && item.data['stickyBottom'])) {
    classes.push('mat-menu-panel_no-bottom-padding');
    // }

    return classes.join(' ');
  }

  defaultCompare(o1, o2) {
    return o1 == o2;
  }

  updateCurrentOption(value: any) {
    this.currentOptionItem = this.optionItemsFlatten.find(item => this.compareWith(item.option.value, value));
    this.cd.markForCheck();
  }

  resetOption() {
    this.control.patchValue(this.resetValue);
    this.control.markAsDirty();
  }

  onOptionClick(option: Option) {
    this.control.patchValue(option.value);
    this.control.markAsDirty();
    this.optionClick.next(option);
  }

  onButtonClick(button: CustomSelectItemButton) {
    this.buttonClick.next(button);
  }

  openDropdown() {
    if (this.trigger) {
      this.trigger.openMenu();
    }
  }

  closeDropdown() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }
}

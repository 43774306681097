import { fromEvent } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';

export enum KeyboardEventKeyCode {
  Enter = 13,
  Escape = 27,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
  At = 50,
  Backspace = 8,
  Tab = 9,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  Space = 32,
  Meta = 91,
  Minus = 189,
  Equals = 187,
  Dot = 190,

  H = 72,
  J = 74,
  T = 84,
  Number0 = 48,
  Number1 = 49,
  Number2 = 50,
  Number3 = 51,
  Number4 = 52,
  Number5 = 53,
  Number6 = 54,
  Number7 = 55,
  Number8 = 56,
  Number9 = 57,
  S = 83,
  C = 67,
  R = 82,
  E = 69,
  N = 78,
  O = 79,
  D = 68,
  Z = 90,

  X = 88,
  V = 86,
  L = 76,
  F = 70,
  G = 71
}

export const keyboardChars = [
  { char: 'J', code: KeyboardEventKeyCode.J },
  { char: 'T', code: KeyboardEventKeyCode.T },
  { char: '!', code: KeyboardEventKeyCode.Number1, shift: true },
  { char: 'S', code: KeyboardEventKeyCode.S },
  { char: 'C', code: KeyboardEventKeyCode.C },
  { char: 'R', code: KeyboardEventKeyCode.R },
  { char: 'E', code: KeyboardEventKeyCode.E },
  { char: 'N', code: KeyboardEventKeyCode.N },
  { char: 'O', code: KeyboardEventKeyCode.O },
  { char: 'D', code: KeyboardEventKeyCode.D },
  { char: 'R', code: KeyboardEventKeyCode.R },
  { char: 'O', code: KeyboardEventKeyCode.O },
  { char: 'V', code: KeyboardEventKeyCode.V },
  { char: 'H', code: KeyboardEventKeyCode.H },
  { char: 'L', code: KeyboardEventKeyCode.L },
  { char: 'F', code: KeyboardEventKeyCode.F },
  { char: 'G', code: KeyboardEventKeyCode.G }
];

export enum MouseButton {
  Main = 0, // usually the left button or the un-initialized state
  Auxiliary = 1, // usually the wheel button or the middle button (if present)
  Secondary = 2, // usually the right button
  Fourth = 3, // typically the Browser Back button
  Fifth = 4 // typically the Browser Forward button
}

export function noKeyModifiers(event: KeyboardEvent) {
  return !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey;
}

export function trackKeyboardCode(code: KeyboardEventKeyCode[]) {
  return fromEvent<KeyboardEvent>(document, 'keyup').pipe(
    scan<KeyboardEvent, number[]>((acc, e) => {
      if (e.altKey) {
        return [...acc, e.keyCode].slice(code.length * -1);
      } else {
        return [];
      }
    }, []),
    filter(value => value.length == code.length),
    map(value => value.length == code.length && code.every((item, i) => value[i] == item))
  );
}

import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';

import { ColumnsDescription } from '@modules/resources';

export const SQL_QUERY_VERSION = 2;

export class SqlQuery {
  query: string;
  requestResponse: any;
  requestResponseColumns: ColumnsDescription;
  requestTokens = {};
  version = SQL_QUERY_VERSION;

  deserialize(data: Object): SqlQuery {
    this.query = data['query'];
    this.version = data['v'] || 1;

    if (data['request_response']) {
      this.requestResponse = data['request_response'];
    }

    if (data['request_response_columns']) {
      this.requestResponseColumns = data['request_response_columns'];
    }

    if (data['request_tokens']) {
      this.requestTokens = data['request_tokens'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      query: this.query,
      request_response: this.requestResponse,
      request_response_columns: this.requestResponseColumns,
      request_tokens: this.requestTokens,
      v: this.version
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured(): boolean {
    return !!this.query;
  }

  equals(another: this): boolean {
    return isEqual(this.serialize(), another.serialize());
  }
}

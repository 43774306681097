<app-action-type-dropdown
  [opened]="dropdownOpened"
  [origin]="origin"
  [value]="control.value"
  [typesOnly]="typesOnly"
  [actionOriginEnabled]="actionOriginEnabled"
  (select)="setDropdownOpened(false); control.patchValue($event)"
  (close)="setDropdownOpened(false)"
></app-action-type-dropdown>

<div
  class="select"
  [class.select_theme_jet]="!orange"
  [class.select_theme_jet_orange]="orange"
  [class.select_fill]="fill"
  [ngClass]="classes"
>
  <a
    href="javascript:void(0)"
    class="select__input"
    [class.select__input_opened]="dropdownOpened"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    (click)="setDropdownOpened(true)"
  >
    <span *ngIf="currentValueIcon" class="select__input-icon" [ngClass]="'icon-' + currentValueIcon"></span>

    <span class="select__input-label">
      <ng-container *ngIf="currentValueLabel | appIsSet">{{ currentValueLabel }}</ng-container>
      <ng-container *ngIf="!(currentValueLabel | appIsSet)">{{ placeholder }}</ng-container>
    </span>
    <span class="select__input-arrow"></span>
  </a>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import {
  customOAuth2BackendName,
  ProjectTokenService,
  ResourceName,
  SecretToken,
  socialBackends
} from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, submitForm } from '@modules/queries';
import { controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { HubspotResourceSettingsForm } from './hubspot-resource-settings.form';

@Component({
  selector: 'app-hubspot-resource-settings',
  templateUrl: './hubspot-resource-settings.component.html',
  providers: [HubspotResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubspotResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  account: string;
  accountSubscription: Subscription;
  accountLoading = true;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private httpQueryService: HttpQueryService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    public form: HubspotResourceSettingsForm,
    private chooseSyncController: ChooseSyncController,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit(): void {
    super.ngOnInit();

    fromEvent<MessageEvent>(window, 'message')
      .pipe(untilDestroyed(this))
      .subscribe(message => {
        if (message.data && message.data['type'] == 'oauth_response') {
          const data = message.data['params'];
          const secretToken = new SecretToken().deserialize(data);

          this.form.form.patchValue({
            access_token: secretToken.value,
            params: secretToken.params
          });
        }
      });
  }

  ngOnDestroy(): void {}

  onFormInit() {
    super.onFormInit();

    controlValue(this.form.form.controls['access_token'])
      .pipe(untilDestroyed(this))
      .subscribe(accessToken => this.updateAccount(accessToken));
  }

  updateAccount(accessToken: string) {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }

    if (!isSet(accessToken)) {
      this.account = undefined;
      this.accountLoading = false;
      this.cd.markForCheck();
      return;
    }

    this.accountLoading = true;
    this.cd.markForCheck();

    const query = new HttpQuery();

    query.url = 'https://api.hubapi.com/account-info/v3/details';
    query.headers = [{ name: 'Authorization', value: `Bearer ${accessToken}` }];

    return this.httpQueryService
      .requestBody<{ portalId: string }>(query)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.account = `Hub ID ${result.portalId}`;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.account = undefined;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getOAuthToken() {
    this.apiService
      .refreshToken()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params = { USE_BACKEND_SHARED_PARAMS: 'hubspot' };
        const redirect = this.redirectUrl();
        const backend = socialBackends.find(item => item.name == customOAuth2BackendName);
        const data = [
          {
            name: 'name',
            value: this.form.tokenName
          },
          {
            name: 'backend',
            value: backend ? backend.path : ''
          },
          {
            name: 'params',
            value: JSON.stringify(params)
          },
          {
            name: 'access_token',
            value: this.apiService.getAccessToken()
          },
          {
            name: 'redirect_uri',
            value: redirect
          }
        ];

        if (this.mode == AdminMode.Builder) {
          data.push({
            name: 'draft',
            value: '1'
          });
        }

        const w = open('', '', 'height=800,width=600');
        if (!w) {
          this.notificationService.error(
            'Failed to open popup',
            'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups'
          );
          return;
        }

        submitForm(HttpMethod.POST, this.apiService.createOAuthTokenUrl, data, w.document);
      });
  }

  redirectUrl() {
    return `${window.location.origin}/oauth_response`;
  }

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.HubSpot, HubspotResourceSettingsComponent);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';
import { createFormFieldFactory } from '@modules/fields';
import { WorkflowStepType } from '@modules/workflow';
import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { DelayCustomizeWorkflowStepForm } from './delay-customize-workflow-step.form';
var DelayCustomizeWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(DelayCustomizeWorkflowStepComponent, _super);
    function DelayCustomizeWorkflowStepComponent(form) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.createField = createFormFieldFactory();
        return _this;
    }
    DelayCustomizeWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.step, { firstInit: false });
        if (this.workflowEditable) {
            this.form.valueChanges
                .pipe(debounceTime(200), map(function () { return _this.form.submit(); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.submit(result);
            });
        }
    };
    DelayCustomizeWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    DelayCustomizeWorkflowStepComponent.prototype.submit = function (value) {
        if (!value) {
            value = this.form.submit();
        }
        this.stepChange.emit(value);
    };
    return DelayCustomizeWorkflowStepComponent;
}(CustomizeWorkflowStepComponent));
export { DelayCustomizeWorkflowStepComponent };
registerCustomizeWorkflowStepComponent(WorkflowStepType.Delay, DelayCustomizeWorkflowStepComponent);

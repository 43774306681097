<app-section [screen]="true">
  <app-table-stub [animating]="false" [scrollable]="true"></app-table-stub>
</app-section>

<div class="admin__sidebar">
  <app-sidebar-empty [animating]="false"></app-sidebar-empty>
</div>

<div class="popup-background popup-container_inside" style="z-index: 2;"></div>
<div class="popup-container popup-container_inside" style="z-index: 2;">
  <div class="popup popup_shadow">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <app-popup2 [size]="'m'">
        <app-popup2-header>
          <app-popup2-title>Sync Messages API</app-popup2-title>
        </app-popup2-header>

        <app-page-block>
          <app-auto-field
            [form]="form"
            [field]="
              createField({
                name: 'api_url',
                label: 'API url',
                field: 'CharField',
                required: true,
                params: {
                  classes: ['input_fill']
                },
                placeholder: 'https://example.com/jet_api/'
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
        </app-page-block>

        <app-popup2-footer>
          <app-page-block>
            <app-stack [align]="'right'">
              <a [appLink]="(currentProjectStore.instance$ | async)?.homeLink" class="button popup2__footer-item"
                >Back</a
              >
              <button type="submit" class="button button_primary" [disabled]="form.invalid || submitLoading">
                <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
                <span class="button__label">Sync</span>
              </button>
            </app-stack>
          </app-page-block>
        </app-popup2-footer>
      </app-popup2>
    </form>
  </div>
</div>

<div class="collapse-section" [class.collapse-section_opened]="opened">
  <div
    class="collapse-section__header"
    [class.collapse-section__header_top]="first"
    [class.collapse-section__header_bottom]="last && !opened"
    (click)="toggleOpened.emit()"
  >
    <div
      *ngIf="collapseIndicatorPosition == collapseIndicatorPositions.Left || icon"
      class="collapse-section__header-left"
    >
      <div
        *ngIf="collapseIndicatorPosition == collapseIndicatorPositions.Left"
        class="collapse-section__header-arrow"
        [class.icon-arrow_down_2]="opened"
        [class.icon-arrow_forward_2]="!opened"
      ></div>

      <div *ngIf="icon" class="collapse-section__header-icon" [appIcon]="icon"></div>
    </div>

    <div class="collapse-section__header-main">
      <div class="collapse-section__header-title">{{ title }}</div>

      <span
        *ngIf="tooltip | appIsSet"
        class="collapse-section__header-help help-icon icon-help_filled"
        [appTip]="tooltip"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </div>

    <div *ngIf="collapseIndicatorPosition == collapseIndicatorPositions.Right" class="collapse-section__header-right">
      <div
        class="collapse-section__header-arrow"
        [class.icon-arrow_down_2]="opened"
        [class.icon-arrow_up_2]="!opened"
      ></div>
    </div>

    <span
      *ngIf="!visible && (customizeService.enabled$ | async)"
      class="collapse-section__header-indicator icon-eye"
      [appTip]="'Section is hidden'"
    ></span>
  </div>

  <div class="collapse-section__content" [class.collapse-section__content_hidden]="!opened" #content_element>
    <div class="collapse-section__content-separator"></div>

    <div
      class="placeholder"
      [class.placeholder_active]="customizeService.layoutEnabled$ | async"
      appDropList
      [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
      [appDropListEnterPredicate]="canEnter"
      [appDropListData]="children"
      [appDropListSwapDistance]="20"
      [appDropListAreaMarginForward]="[-10, 0, 0, 0]"
      [appDropListAreaMarginBackward]="[0, 0, -10, 0]"
      [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
      [appDropListOutsideIndicator]="true"
      (appDropListDragStarted)="updateElementStates()"
      (appDropListEntered)="updateElementStates()"
      (appDropListExited)="updateElementStates()"
      (appDropListSorted)="updateElementStates()"
      (appDropListDragged)="updateElementStatesOnStable()"
      (appDropListDropped)="dragDrop($event)"
      appElementGroupsContainer
    >
      <app-auto-element
        *ngFor="let item of children; let i = index; trackBy: trackElement"
        appDrag
        [appDragData]="item"
        [element]="item"
        [context]="context"
        [customizing]="customizeService.layoutEnabled$ | async"
        [accentColor]="'accentColor' | appThemeColorHexOption | async"
        (duplicatedRequested)="duplicateItem(i)"
        (replaceRequested)="replaceItem(i, $event)"
        (deleteRequested)="deleteItem(item)"
        (moveToRequested)="moveItemTo(item, $event)"
        (visibleUpdated)="updateElementStates()"
      >
        <app-element-placeholder-stub
          *appDragPlaceholder
          [elementType]="item.type"
          class="element-placeholder"
        ></app-element-placeholder-stub>

        <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
      </app-auto-element>
      <div class="placeholder__border"></div>
    </div>
  </div>
</div>

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

import { Option } from '@modules/field-components';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-compact-select',
  templateUrl: './compact-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompactSelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactSelectComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() options: Option[] = [];
  @Input() placeholder = 'Choose';
  @Input() widthPx: number = null;
  @Input() compareWith: (o1: any, o2: any) => boolean = this.defaultCompare;
  @Output() change = new EventEmitter<void>();

  value$ = new Subject<any>();
  options$ = new BehaviorSubject<Option[]>([]);
  currentOption: Option;
  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];

  onChange = (value: any) => undefined;
  onTouched = () => undefined;
  defaultCompare(o1: any, o2: any) {
    return o1 == o2;
  }

  constructor(public sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.value$, this.options$)
      .pipe(untilDestroyed(this))
      .subscribe(([value, options]) => {
        this.currentOption = options.find(item => this.compareWith(item.value, value));
        this.cd.markForCheck();
      });

    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.value$.next(value));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CompactSelectComponent>): void {
    if (changes.options) {
      this.options$.next(this.options);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value$.next(value);
  }

  updateValue(value: any) {
    this.value$.next(value);
    this.onChange(value);
    this.change.emit();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }
}

<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="form.controls.name.value || 'Button group'"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [deleteEnabled]="deleteEnabled"
    [backLabel]="'All components'"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (deleteClick)="delete()"
    (titleChanged)="onTitleChanged($event)"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'orientation'">
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.controls.orientation.value == orientations.Horizontal"
                  (click)="form.controls.orientation.setValue(orientations.Horizontal)"
                >
                  <div class="sidebar-icon-button__label">Horizontal</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.controls.orientation.value == orientations.Vertical"
                  (click)="form.controls.orientation.setValue(orientations.Vertical)"
                >
                  <div class="sidebar-icon-button__label">Vertical</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__list">
            <app-actions-edit
              [options]="{
                actionItemClass: viewSettingsActionClass,
                nameEditable: true,
                iconEditable: true,
                styleEditable: true,
                colorsEditable: true,
                approveEnabled: true,
                confirmationEnabled: true,
                completionEditable: true,
                disabledEditable: true,
                visibleEditable: true,
                originEnabled: true
              }"
              [control]="form.controls.actions"
              [context]="context"
              [emptyAction]="'Button action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'cursor'"
              [actionLabel]="'Button action click'"
              [object]="element.analyticsGenericName + '_actions'"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Conditional Visibility'"
            [documentation]="'conditional-visibility'"
            [documentationLabel]="'See how it works'"
            [documentationSource]="element.analyticsGenericName + '_visible'"
          >
            <app-input-edit
              [itemForm]="form.controls.visible_input"
              [context]="context"
              [staticValueDisabled]="true"
              [placeholder]="'Value'"
              [fill]="true"
              [analyticsSource]="element.analyticsGenericName + '_visible'"
            ></app-input-edit>

            <ng-container description>
              Example: elements.field.value >= 10
            </ng-container>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'Spacing'">
            <app-margin-control [control]="form.controls.margin"></app-margin-control>
          </app-sidebar-field>
        </div>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>
</form>

<app-popup2 [size]="wide ? 'xxl' : 'l'">
  <div class="choose-template" [class.choose-template_popup]="false">
    <ng-container *ngIf="section == sections.Onboarding">
      <!--    <div class="choose-template__header">-->
      <!--      {{ title }}-->
      <!--      <a-->
      <!--        *ngIf="cancel"-->
      <!--        href="javascript:void(0)"-->
      <!--        class="choose-template__header-close icon-close"-->
      <!--        (click)="onCloseClick()"-->
      <!--      ></a>-->
      <!--    </div>-->

      <div class="choose-template__content">
        <div class="choose-template__columns">
          <div class="choose-template__columns-item">
            <div class="choose-template__section">
              <div class="choose-template__section-header">
                <div class="choose-template__section-title">
                  <!--                Instantly generate App-->
                  Start with my own data
                  <span class="choose-template__section-title-tag">Admin Panel</span>
                </div>
                <div class="choose-template__section-description">
                  <!--                Just connect a resource and Jet will automatically generate app: CRUD operations, filters based on the scheme-->
                  Connect your own data source and Jet will immediately generate a ready-to-use admin panel
                </div>
              </div>
              <div class="choose-template__section-content choose-template__section-content_center">
                <div class="choose-template__section-list">
                  <a
                    *ngFor="let item of resources.slice(0, 6)"
                    href="javascript:void(0)"
                    class="choose-template__section-list-item"
                    (click)="applyAdminPanelTemplate(item)"
                  >
                    <span
                      class="choose-template__section-list-item-image"
                      [style.background-image]="
                        '/assets/images/resources/icons/' + item.icon + '.svg' | appDeployCssUrl
                      "
                    ></span>
                    <span class="choose-template__section-list-item-title">{{ item.label }}</span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="choose-template__section-list-link"
                    (click)="chooseAdminPanelTemplate('onboarding')"
                  >
                    See more 30+ resources
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="newPage" class="choose-template__columns-item">
            <div class="choose-template__section">
              <div class="choose-template__section-header">
                <div class="choose-template__section-title">Start from scratch</div>
                <div class="choose-template__section-description">
                  Start off by drag-and-dropping the UI components. Replace our demo data with your own at any point
                  <!--                Create your app from scratch by drag-and-dropping our pre-built components-->
                </div>
              </div>
              <div class="choose-template__section-content choose-template__section-content_center">
                <a
                  href="javascript:void(0)"
                  class="choose-template__section-button"
                  [class.choose-template__section-button_active]="loadingCreateBlankApp"
                  [class.choose-template__section-button_disabled]="loadingCreateBlankApp"
                  (click)="createBlankApp()"
                >
                  <div *ngIf="!loadingCreateBlankApp" class="choose-template__section-button-icon icon-plus"></div>
                  <app-loader-small
                    *ngIf="loadingCreateBlankApp"
                    class="choose-template__section-button-loader"
                  ></app-loader-small>
                  <div class="choose-template__section-button-label">Start with a Blank App</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--          <div class="choose-template__footer">-->
      <!--            <div class="choose-template__section choose-template__section_horizontal">-->
      <!--              <div class="choose-template__section-header">-->
      <!--                <div class="choose-template__section-title choose-template__section-title_small">Explore example apps</div>-->
      <!--                <div class="choose-template__section-description choose-template__section-description_small">-->
      <!--                  Check what can be built-->
      <!--                  &lt;!&ndash;            Not sure what to build?<br />&ndash;&gt;-->
      <!--                  &lt;!&ndash;            Browse some examples&ndash;&gt;-->
      <!--                </div>-->
      <!--                &lt;!&ndash;          <a href="javascript:void(0)" class="choose-template__section-link" (click)="chooseTemplate()">&ndash;&gt;-->
      <!--                &lt;!&ndash;            Explore more templates&ndash;&gt;-->
      <!--                &lt;!&ndash;          </a>&ndash;&gt;-->
      <!--              </div>-->
      <!--              <div class="choose-template__section-content">-->
      <!--                <div class="choose-template__section-tiles">-->
      <!--                  <a-->
      <!--                    *ngFor="let item of templates.slice(0, 3)"-->
      <!--                    href="javascript:void(0)"-->
      <!--                    class="choose-template__section-tiles-item choose-template__section-tiles-item_fill"-->
      <!--                    (click)="chooseTemplate(item)"-->
      <!--                  >-->
      <!--                    <div class="choose-template__section-tiles-item-icon icon-magic_wand"></div>-->
      <!--                    <div class="choose-template__section-tiles-item-main">-->
      <!--                      <div class="choose-template__section-tiles-item-title">{{ item.name }}</div>-->
      <!--                      <div *ngIf="item.tags.length" class="choose-template__section-tiles-item-description">-->
      <!--                        <ng-container *ngFor="let tag of item.tags; let last = last">-->
      <!--                          {{ tag.name }}<ng-container *ngIf="!last">, </ng-container>-->
      <!--                        </ng-container>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </a>-->
      <!--                  <a-->
      <!--                    href="javascript:void(0)"-->
      <!--                    class="choose-template__section-tiles-item"-->
      <!--                    (click)="chooseTemplate()"-->
      <!--                    [appTip]="'See more Templates'"-->
      <!--                    [appTipOptions]="{ side: 'top' }"-->
      <!--                  >-->
      <!--                    <span class="choose-template__section-tiles-button icon-more"></span>-->
      <!--                  </a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
    </ng-container>

    <ng-container *ngIf="section == sections.Templates">
      <app-popup2-close *ngIf="!header && cancel" [outside]="true" (click)="close()"></app-popup2-close>

      <div *ngIf="header" class="choose-template__header">
        <ng-container *ngIf="previousSection">
          <ng-container *ngIf="!type">
            <a href="javascript:void(0)" (click)="navigateBack()" style="margin-right: 6px;">
              <span class="icon icon-arrow_backward_3"></span> Back
            </a>
            –
          </ng-container>
          Choose Template
        </ng-container>

        <ng-container *ngIf="!previousSection">
          <ng-container *ngIf="chooseSection">
            <!--      <mat-menu [overlapTrigger]="false" #chooseMenu="matMenu">-->
            <!--        <ng-template matMenuContent>-->
            <!--          <div mat-menu-item [disableRipple]="true">-->
            <!--            <span class="choose-items-item__dropdown-icon icon-check_2"></span> Templates-->
            <!--          </div>-->
            <!--          <button mat-menu-item [disableRipple]="true" (click)="startChooseResource()">-->
            <!--            <span class="choose-items-item__dropdown-icon"></span> Data sources-->
            <!--          </button>-->
            <!--        </ng-template>-->
            <!--      </mat-menu>-->

            {{ title }} with &nbsp;

            <!--      <a href="javascript:void(0)" class="choose-template__header-link" [matMenuTriggerFor]="chooseMenu"-->
            <!--        >Templates<span class="icon-arrow_down_3"></span-->
            <!--      ></a>-->

            <div class="choose-template__radio" [class.choose-template__radio_orange]="orange">
              <a href="javascript:void(0)" class="choose-template__radio-item choose-template__radio-item_active">
                Templates
              </a>
              <a
                href="javascript:void(0)"
                class="choose-template__radio-item"
                (click)="chooseAdminPanelTemplate('data_sources')"
              >
                Data Sources
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="!chooseSection">
            Choose Template
          </ng-container>
        </ng-container>

        <a
          *ngIf="cancel"
          href="javascript:void(0)"
          class="choose-template__header-close icon-close"
          (click)="onCloseClick()"
        ></a>
      </div>

      <div class="choose-template__content">
        <div class="choose-template__content-navigation">
          <div class="choose-template__content-navigation-inner">
            <div class="choose-template__content-navigation-header">
              <!--            <ng-container *ngIf="newPage">-->
              <!--              <div class="choose-template__content-navigation-button">-->
              <!--                <button-->
              <!--                  type="button"-->
              <!--                  class="button button_fill"-->
              <!--                  [class.button_orange]="orange"-->
              <!--                  [class.button_disabled]="loadingCreateBlankApp"-->
              <!--                  (click)="createBlankApp()"-->
              <!--                >-->
              <!--                  <span *ngIf="!loadingCreateBlankApp" class="icon button__icon_left icon-brush button__icon"></span>-->
              <!--                  <app-loader-small-->
              <!--                    *ngIf="loadingCreateBlankApp"-->
              <!--                    class="button__icon button__icon_left"-->
              <!--                  ></app-loader-small>-->
              <!--                  <span class="button__label">Start with a Blank App</span>-->
              <!--                  <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>-->
              <!--                </button>-->
              <!--              </div>-->
              <!--              <div class="choose-template__content-navigation-separator">-->
              <!--                <div class="choose-template__content-navigation-separator-border"></div>-->
              <!--                <div class="choose-template__content-navigation-separator-label">or</div>-->
              <!--                <div class="choose-template__content-navigation-separator-border"></div>-->
              <!--              </div>-->
              <!--            </ng-container>-->

              <div *ngIf="chooseSection" class="choose-template__content-navigation-title">Choose a Template</div>
              <input
                type="text"
                class="choose-template-input"
                placeholder="Search templates..."
                [(ngModel)]="templateSearch"
                (keyup)="templateSearchUpdated.next()"
                [appAutofocus]="true"
              />
            </div>

            <div class="choose-template__content-navigation-list">
              <div class="choose-template-list">
                <ng-container *ngIf="!loading">
                  <div *ngFor="let item of templatesFiltered" class="choose-template-list__item">
                    <div
                      class="choose-template-list-item choose-template-list-item_interactive"
                      [class.choose-template-list-item_orange]="orange"
                      [class.choose-template-list-item_active]="selectedTemplate === item"
                      (click)="setSelectedTemplate(item)"
                    >
                      <div class="choose-template-list-item__main">{{ item.name }}</div>

                      <ng-container *ngIf="!resource">
                        <div *ngIf="item.type == templateItemTypes.AdminPanel" class="choose-template-list-item__right">
                          <!--                      any resource-->
                          <span class="choose-template-list-item__tag">any</span>
                        </div>
                        <div *ngIf="item.type == templateItemTypes.Template" class="choose-template-list-item__right">
                          <span
                            *ngFor="let resource of item.template.forResources.slice(0, 3)"
                            class="choose-template-list-item__icon"
                            [style.background-image]="
                              '/assets/images/resources/icons/' + resource.typeItem.icon + '.svg' | appDeployCssUrl
                            "
                          >
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="!templatesFiltered?.length" class="choose-template-list__message">
                    Nothing found
                  </div>

                  <!--                <button type="button" class="button button_fill button_orange-transparent" id="request_template">-->
                  <!--                  <span class="icon button__icon_left icon-magic_wand button__icon"></span>-->
                  <!--                  <span class="button__label">Suggest a Template</span>-->
                  <!--                  <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>-->
                  <!--                </button>-->
                </ng-container>
                <ng-container *ngIf="loading">
                  <div
                    *ngFor="
                      let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']
                    "
                    class="choose-template-list__item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="choose-template-list-item"
                      [class.choose-template-list-item_orange]="orange"
                    >
                      <div class="choose-template-list-item__main">
                        <span [class.loading-animation]="true"
                          ><span class="stub-text">{{ item }}</span></span
                        >
                      </div>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!--        <div class="choose-template__content-navigation-footer">-->
          <!--          <button type="button" class="button button_fill button_orange-transparent" id="request_template">-->
          <!--            <span class="icon button__icon_left icon-magic_wand button__icon"></span>-->
          <!--            <span class="button__label">Suggest a Template</span>-->
          <!--            <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>-->
          <!--          </button>-->
          <!--        </div>-->
        </div>

        <div class="choose-template__content-main">
          <div *ngIf="selectedTemplate" class="choose-template__detail">
            <div class="choose-template__detail-header">
              <div class="choose-template__detail-header-top">
                <div class="choose-template__detail-header-left">
                  <app-project-logo
                    [color]="selectedTemplate.color"
                    [logo]="selectedTemplate.logo"
                    [icon]="selectedTemplate.icon ? 'icon-' + selectedTemplate.icon : undefined"
                    [initials]="selectedTemplate.name | appInitials"
                    [fill]="selectedTemplate.logoFill"
                    [size]="selectedTemplate.tags.length || true ? 's' : 'xs'"
                    [borderRadius]="'s'"
                  ></app-project-logo>
                </div>

                <div class="choose-template__detail-header-main">
                  <div class="choose-template__detail-title">
                    {{ selectedTemplate.name }}
                  </div>

                  <div *ngIf="selectedTemplate.subtitle" class="choose-template__detail-subtitle">
                    {{ selectedTemplate.subtitle }}
                  </div>

                  <div *ngIf="false && selectedTemplate.tags.length" class="choose-template__detail-tags">
                    <span
                      *ngFor="let item of selectedTemplate.tags.slice(0, tagsMaximum)"
                      class="choose-template-tag choose-template__detail-tags-item"
                      [ngClass]="[
                        !(item.color | appIsColorHex) ? 'color_' + item.color + '_1' : '',
                        !(item.color | appIsColorHex) ? 'border-color_' + item.color + '_1' : ''
                      ]"
                      [style.color]="(item.color | appIsColorHex) ? item.color : null"
                      [style.border-color]="(item.color | appIsColorHex) ? item.color : null"
                    >
                      {{ item.name }}
                    </span>
                    <span
                      *ngIf="selectedTemplate.tags.length > tagsMaximum"
                      class="choose-template-tag choose-template__detail-tags-item"
                    >
                      + {{ selectedTemplate.tags.length - tagsMaximum }} more
                    </span>
                  </div>
                </div>
                <div class="choose-template__detail-header-right">
                  <div
                    *ngIf="selectedTemplate.type == templateItemTypes.Template"
                    class="choose-template__detail-submit-item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="button button_primary"
                      [class.button_orange]="orange"
                      [class.button_disabled]="!selectedTemplate"
                      (click)="applyTemplate(selectedTemplate)"
                    >
                      <span class="button__icon button__icon_left icon-magic_wand"></span>
                      <span class="button__label">Use this template</span>
                    </a>
                  </div>

                  <div
                    *ngIf="selectedTemplate.type == templateItemTypes.AdminPanel"
                    class="choose-template__detail-submit-item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="button button_primary"
                      [class.button_orange]="orange"
                      [class.button_disabled]="!selectedTemplate"
                      (click)="chooseAdminPanelTemplate('templates_list')"
                    >
                      <span class="button__icon button__icon_left icon-magic_wand"></span>
                      <span class="button__label">Use this template</span>
                    </a>
                  </div>
                </div>
              </div>
              <div *ngIf="false && selectedTemplate.description" class="choose-template__detail-header-bottom">
                <div class="choose-template__detail-description">{{ selectedTemplate.description }}</div>
              </div>
            </div>

            <div
              class="choose-template__detail-image choose-template__detail-image_light choose-template__detail-image_navigation choose-template-video-bounds"
            >
              <ng-container *ngIf="selectedTemplate.images[0] as image" [ngSwitch]="isVideoUrl(image.image)">
                <div *ngSwitchCase="true" class="choose-template-video choose-template-video_padding">
                  <video class="choose-template-video__inner" [src]="image.image" autoplay loop muted></video>
                </div>
                <div
                  *ngSwitchCase="false"
                  class="choose-template-image"
                  [style.background-image]="'url(\'' + (image.image | appEncodeURI) + '\')' | appSafeStyle"
                ></div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="section == sections.Resources">
      <app-popup2-close *ngIf="!header && cancel" [outside]="true" (click)="close()"></app-popup2-close>

      <div *ngIf="header" class="choose-template__header">
        <ng-container *ngIf="previousSection">
          <ng-container *ngIf="!type">
            <a href="javascript:void(0)" (click)="navigateBack()" style="margin-right: 6px;">
              <span class="icon icon-arrow_backward_3"></span> Back
            </a>
            –
          </ng-container>
          Choose Resource
          <!--        <ng-container *ngIf="selectedTemplate">Choose resource for {{ selectedTemplate.name }}</ng-container>-->
          <!--        <ng-container *ngIf="!selectedTemplate">Choose resource</ng-container>-->
        </ng-container>

        <ng-container *ngIf="!previousSection && newPage">
          <a href="javascript:void(0)" (click)="navigateToProjects()" style="margin-right: 6px;">
            <span class="icon icon-arrow_backward_3"></span> Back
          </a>
          – Choose Resource
        </ng-container>

        <ng-container *ngIf="!previousSection && !newPage">
          <ng-container *ngIf="chooseSection">
            <!--        <mat-menu [overlapTrigger]="false" #chooseMenu="matMenu">-->
            <!--          <ng-template matMenuContent>-->
            <!--            <button mat-menu-item [disableRipple]="true" (click)="cancelChooseResource()">-->
            <!--              <span class="choose-items-item__dropdown-icon"></span> Templates-->
            <!--            </button>-->
            <!--            <div mat-menu-item [disableRipple]="true">-->
            <!--              <span class="choose-items-item__dropdown-icon icon-check_2"></span> Data sources-->
            <!--            </div>-->
            <!--          </ng-template>-->
            <!--        </mat-menu>-->

            {{ title }} with &nbsp;

            <!--        <a href="javascript:void(0)" class="choose-template__header-link" [matMenuTriggerFor]="chooseMenu"-->
            <!--          >Data sources<span class="icon-arrow_down_3"></span-->
            <!--        ></a>-->

            <div class="choose-template__radio" [class.choose-template__radio_orange]="orange">
              <a href="javascript:void(0)" class="choose-template__radio-item" (click)="chooseTemplate()">
                Templates
              </a>
              <a href="javascript:void(0)" class="choose-template__radio-item choose-template__radio-item_active">
                Data Sources
              </a>
            </div>
          </ng-container>

          <ng-container *ngIf="!chooseSection">
            Choose Resource
          </ng-container>
        </ng-container>

        <a
          *ngIf="cancel"
          href="javascript:void(0)"
          class="choose-template__header-close icon-close"
          (click)="onCloseClick()"
        ></a>
      </div>

      <div class="choose-template__content">
        <div class="choose-template__select-item">
          <ng-template #custom_resource>
            <div class="select-item-grid__item">
              <a
                href="javascript:void(0)"
                class="select-item-grid-item select-item-grid-item_contrast"
                (click)="setSelectedResource(customResource); applyAdminPanelTemplate(customResource)"
              >
                <span class="select-item-grid-item__image">
                  <span class="select-item-grid-item__image-icon icon-plus"></span>
                </span>
                <span class="select-item-grid-item__title">
                  New Data
                  <span
                    *ngIf="customResource.icon"
                    class="select-item-grid-item__subtitle-logo"
                    [style.background-image]="
                      '/assets/images/resources/icons/' + customResource.icon + '.svg' | appDeployCssUrl
                    "
                  >
                  </span>
                </span>
                <span class="select-item-grid-item__subtitle"> using {{ customResource.label }} </span>
              </a>
            </div>
          </ng-template>

          <div class="select-item">
            <!--          <div class="select-item__header">-->
            <!--            <div class="select-item__title">Choose Resource</div>-->
            <!--            <div class="select-item__description">Integrate with Database or 3rd party APIs</div>-->
            <!--          </div>-->
            <div class="select-item__main">
              <div class="select-item__main-navigation">
                <div *ngIf="chooseSection" class="select-item__main-navigation-title">Choose Resource</div>
                <div class="select-item-input select-item__main-navigation-input">
                  <input
                    type="text"
                    class="select-item-input__control"
                    placeholder="Search..."
                    [(ngModel)]="resourceSearch"
                    [appAutofocus]="true"
                    (keyup)="resourceSearchUpdated.next(); onSearchKey($event)"
                  />
                  <span class="select-item-input__icon icon-search"></span>
                  <span
                    *ngIf="resourceSearch | appIsSet"
                    class="select-item-input__close icon-close"
                    (click)="clearSearch()"
                  ></span>
                </div>

                <div class="select-item__main-navigation-list">
                  <div class="select-item-list">
                    <ng-container *ngFor="let item of resourceFilters; let i = index">
                      <div *ngIf="resourceFilterCounts[i]" class="select-item-list__item">
                        <a
                          href="javascript:void(0)"
                          class="select-item-list-item"
                          [class.select-item-list-item_orange]="orange"
                          [class.select-item-list-item_active]="resourceFilterIndex == i"
                          (click)="setResourceFilterIndex(i)"
                        >
                          <span class="select-item-list-item__main">{{ item.label }}</span>
                          <span class="select-item-list-item__right">{{ resourceFilterCounts[i] }}</span>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <!--              <div class="select-item__main-navigation-footer">-->
                <!--                <a-->
                <!--                  *ngIf="newPage"-->
                <!--                  href="javascript:void(0)"-->
                <!--                  class="select-item-list-item"-->
                <!--                  [class.select-item-list-item_contrast]="true"-->
                <!--                  [class.select-item-list-item_orange]="orange"-->
                <!--                  [class.select-item-list-item_disabled]="loadingCreateBlankApp"-->
                <!--                  (click)="createBlankApp()"-->
                <!--                >-->
                <!--                  <span class="select-item-list-item__left">-->
                <!--                    <span *ngIf="!loadingCreateBlankApp" class="select-item-list-item__icon icon-design"></span>-->
                <!--                    <app-loader-small-->
                <!--                      *ngIf="loadingCreateBlankApp"-->
                <!--                      class="select-item-list-item__icon"-->
                <!--                    ></app-loader-small>-->
                <!--                  </span>-->
                <!--                  <span class="select-item-list-item__main">Start with a Blank App</span>-->
                <!--                </a>-->
                <!--              </div>-->
              </div>
              <div class="select-item__main-content">
                <div class="select-item-grid">
                  <ng-container *ngIf="resourcesFiltered?.length">
                    <ng-container *ngTemplateOutlet="custom_resource"></ng-container>
                  </ng-container>

                  <div *ngFor="let item of resourcesFiltered" class="select-item-grid__item">
                    <a
                      href="javascript:void(0)"
                      class="select-item-grid-item"
                      [class.select-item-grid-item_orange]="orange"
                      [class.select-item-grid-item_active]="selectedResource === item"
                      (click)="setSelectedResource(item); applyAdminPanelTemplate(item)"
                    >
                      <span class="select-item-grid-item__image">
                        <span
                          class="select-item-grid-item__image-inner"
                          [style.background-image]="
                            '/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl
                          "
                        >
                        </span>
                      </span>
                      <span class="select-item-grid-item__title">{{ item.label }}</span>
                      <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
                      <span *ngIf="item.beta" class="select-item-grid-item__tag background-color_orange_1">
                        <span class="select-item-grid-item__tag-raw">β</span>eta
                      </span>
                    </a>
                  </div>
                  <ng-container *ngIf="resourceSearch && !resourcesFiltered?.length">
                    <div class="select-item-grid__message">
                      <div>
                        Can't find resource you're looking for?
                      </div>
                      <div>
                        You can integrate any <strong>Rest API</strong>, <strong>GraphQL</strong> or
                        <strong>create new data</strong> using the options below:
                      </div>
                    </div>

                    <ng-container *ngTemplateOutlet="custom_resource"></ng-container>

                    <div *ngFor="let item of resourcesNotFound" class="select-item-grid__item">
                      <a
                        href="javascript:void(0)"
                        class="select-item-grid-item"
                        [class.select-item-grid-item_orange]="orange"
                        [class.select-item-grid-item_active]="selectedResource === item"
                        (click)="setSelectedResource(item); applyAdminPanelTemplate(item)"
                      >
                        <span class="select-item-grid-item__image">
                          <span
                            class="select-item-grid-item__image-inner"
                            [style.background-image]="
                              '/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl
                            "
                          >
                          </span>
                        </span>
                        <span class="select-item-grid-item__title">{{ item.label }}</span>
                        <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--    <div class="choose-template__detail-submit">-->
      <!--      <ng-container *ngIf="selectedTemplate?.selectResources">-->
      <!--        <a-->
      <!--          href="javascript:void(0)"-->
      <!--          class="button button_primary choose-template__detail-submit-item"-->
      <!--          [class.button_orange]="orange"-->
      <!--          [class.button_disabled]="!selectedResource"-->
      <!--          (click)="applyAdminPanelTemplate()"-->
      <!--        >-->
      <!--          Create {{ selectedTemplate.name-->
      <!--          }}<ng-container *ngIf="selectedResource"> for {{ selectedResource.label }}</ng-container>-->
      <!--        </a>-->
      <!--      </ng-container>-->

      <!--      <ng-container *ngIf="!selectedTemplate?.selectResources">-->
      <!--        <app-loader-small *ngIf="loadingCreateBlankApp"></app-loader-small>-->

      <!--        <a-->
      <!--          href="javascript:void(0)"-->
      <!--          class="button choose-template__detail-submit-item"-->
      <!--          [class.button_orange]="orange"-->
      <!--          [class.button_disabled]="loadingCreateBlankApp"-->
      <!--          (click)="useDemoResources()"-->
      <!--        >-->
      <!--          <span class="button__label">Use Demo resources</span>-->
      <!--        </a>-->
      <!--        <a-->
      <!--          href="javascript:void(0)"-->
      <!--          class="button button_primary choose-template__detail-submit-item"-->
      <!--          [class.button_orange]="orange"-->
      <!--          [class.button_disabled]="!selectedResource || loadingCreateBlankApp"-->
      <!--          (click)="chooseResource()"-->
      <!--        >-->
      <!--          Connect<ng-container *ngIf="selectedResource"> {{ selectedResource.label }}</ng-container>-->
      <!--        </a>-->
      <!--      </ng-container>-->
      <!--    </div>-->
    </ng-container>
  </div>
</app-popup2>

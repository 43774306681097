<div class="sidebar__element">
  <app-sidebar-field [label]="'Output format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'output_format',
          field: 'SelectField',
          params: { options: form.outputFormatOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-compact-border-button',
  templateUrl: './compact-border-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactBorderButtonComponent implements OnInit {
  @Input() label: string;
  @Input() interactive = false;
  @Input() leftIcon: string;
  @Input() leftBackground = false;
  @Input() leftInteractive = false;
  @Input() rightIcon: string;
  @Input() rightBackground = false;
  @Input() rightInteractive = false;
  @Output() leftClick = new EventEmitter<MouseEvent>();
  @Output() rightClick = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}
}

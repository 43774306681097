<app-customize-bar-header
  [title]="'Features'"
  [subtitle]="'App settings'"
  [backEnabled]="false"
></app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section [title]="'Additional features'">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'show collaboration component'" [layoutLeft]="true">
        <app-auto-field
          [form]="settingsForm"
          [label]="false"
          [field]="
            createField({
              name: 'collaboration_enabled',
              field: 'BooleanField',
              params: { style: booleanFieldStyle.Toggle, orange: true }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'show activity component'" [layoutLeft]="true">
        <app-auto-field
          [form]="settingsForm"
          [label]="false"
          [field]="
            createField({
              name: 'activity_log_enabled',
              field: 'BooleanField',
              params: { style: booleanFieldStyle.Toggle, orange: true }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { TypedChanges } from '@shared';

export const cursors = [
  'default',
  'pointer',
  'progress',
  'wait',
  'none',
  'context-menu',
  'help',
  'cell',
  'crosshair',
  'text',
  'vertical-text',
  'alias',
  'copy',
  'move',
  'no-drop',
  'not-allowed',
  'grab',
  'grabbing',
  'all-scroll',
  'col-resize',
  'row-resize',
  'n-resize',
  'e-resize',
  's-resize',
  'w-resize',
  'ne-resize',
  'nw-resize',
  'se-resize',
  'sw-resize',
  'ew-resize',
  'ns-resize',
  'nesw-resize',
  'nwse-resize',
  'zoom-in',
  'zoom-out'
];

@Component({
  selector: 'app-interactions-section-cursor-dropdown',
  templateUrl: './interactions-section-cursor-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionsSectionCursorDropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value: string;
  @Input() origin: CdkOverlayOrigin;
  @Input() openOnFocus: HTMLElement;
  @Input() forceOpened: boolean;
  @Output() selected = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  dropdownHover$ = new BehaviorSubject<boolean>(false);
  dropdownOpened = false;
  cursors = cursors;
  verticalDropdownPositions: ConnectedPosition[] = [
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 }
  ];
  horizontalDropdownPositions: ConnectedPosition[] = [
    { originX: 'end', overlayX: 'start', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'start', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
    { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'bottom', overlayY: 'bottom', offsetX: 0, offsetY: 8 }
  ];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<InteractionsSectionCursorDropdownComponent>): void {
    if (changes.openOnFocus) {
      this.initOpenOnFocus();
    } else if (changes.forceOpened && this.forceOpened === true) {
      this.setDropdownOpened(true);
    } else if (changes.forceOpened && this.forceOpened === false) {
      this.setDropdownOpened(false);
    }
  }

  initOpenOnFocus() {
    combineLatest(
      merge(
        fromEvent<MouseEvent>(this.origin.elementRef.nativeElement, 'mouseenter').pipe(map(() => true)),
        fromEvent<MouseEvent>(this.origin.elementRef.nativeElement, 'mouseleave').pipe(map(() => false))
      ),
      this.dropdownHover$
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(([triggerHover, dropdownHover]) => {
        this.setDropdownOpened(triggerHover || dropdownHover);
      });
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }
}

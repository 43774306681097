import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { WorkflowBackendRunWithRelations } from '../data/workflow-backend-run-with-relations';
import { WorkflowBackendRunService } from '../services/workflow-backend-run/workflow-backend-run.service';

@Injectable()
export class WorkflowBackendRunListStore extends ListStore<WorkflowBackendRunWithRelations> {
  automationUid: string;
  workflowUid: string;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private workflowRunService: WorkflowBackendRunService
  ) {
    super();
  }

  get hasMore(): boolean {
    return true;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<WorkflowBackendRunWithRelations>> {
    return this.workflowRunService
      .get(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, {
        automationUid: this.automationUid,
        workflowUid: this.workflowUid,
        page: page
      })
      .pipe(
        map(response => {
          return {
            items: response.results,
            hasMore: page < response.numPages,
            totalPages: response.numPages,
            perPage: response.perPage,
            count: response.count
          };
        })
      );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../directives/copy-text/copy-text.directive";
import * as i2 from "../../../tips/directives/tip/tip.directive";
import * as i3 from "../../directives/code/code.directive";
import * as i4 from "@angular/common";
import * as i5 from "./code.component";
import * as i6 from "../../../notifications/services/notification/notification.service";
var styles_CodeComponent = [];
var RenderType_CodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CodeComponent, data: {} });
export { RenderType_CodeComponent as RenderType_CodeComponent };
function View_CodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "pre", [["appCopyText", ""], ["class", "code__content"]], null, null, null, null, null)), i0.ɵdid(1, 81920, [[1, 4]], 0, i1.CopyTextDirective, [i0.ElementRef], null, null), i0.ɵncd(null, 0)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CodeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "icon icon-copy code__copy"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i2.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = "Copy to clipboard"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CodeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { copyText: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "code"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CodeComponent_1)), i0.ɵdid(3, 606208, null, 0, i3.CodeDirective, [i0.ViewContainerRef, i0.TemplateRef], { appCode: [0, "appCode"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CodeComponent_2)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.language; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.copyEnabled; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-code", [], null, null, null, View_CodeComponent_0, RenderType_CodeComponent)), i0.ɵdid(1, 245760, null, 0, i5.CodeComponent, [i6.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CodeComponentNgFactory = i0.ɵccf("app-code", i5.CodeComponent, View_CodeComponent_Host_0, { language: "language", copyEnabled: "copyEnabled" }, {}, ["*"]);
export { CodeComponentNgFactory as CodeComponentNgFactory };

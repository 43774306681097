<app-popup2 [size]="'s'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="custom-page-popup custom-page-popup_style_background">
      <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
        <div class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">
            <ng-container *ngIf="property">Edit {{ property.name }}</ng-container>
            <ng-container *ngIf="!property">New {{ typeLabel | appCapitalize }}</ng-container>
          </div>
        </div>
      </div>
      <div class="custom-page-popup__content">
        <app-page-block>
          <app-field-type-field
            [form]="form"
            [label]="false"
            [field]="createField({ name: 'field', params: { classes: ['select_fill'] } })"
          ></app-field-type-field>
        </app-page-block>

        <app-page-block>
          <app-sidebar-field [label]="'Name'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'name',
                  field: 'CharField',
                  params: { classes: ['input_fill'] }
                })
              "
              [autofocus]="true"
            ></app-auto-field>
          </app-sidebar-field>
        </app-page-block>

        <app-page-block *ngIf="defaultValueEnabled && form.controls.field.value">
          <app-sidebar-field [label]="'Default value'" [additional]="'(optional)'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'default_value',
                  placeholder: 'Variable default value',
                  field: form.controls.field.value,
                  params: defaultValueParams
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </app-page-block>

        <app-page-block *ngIf="requiredEnabled">
          <app-sidebar-field [label]="'required'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'required',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </app-page-block>

        <div *ngIf="viewParamsComponentData" style="padding: 0 15px;">
          <app-dynamic-component [componentData]="viewParamsComponentData"></app-dynamic-component>
        </div>

        <div *ngIf="dataParamsComponentData" style="padding: 0 15px;">
          <app-dynamic-component [componentData]="dataParamsComponentData"></app-dynamic-component>
        </div>

        <app-page-block *ngIf="placeholderEnabled">
          <app-sidebar-field [label]="'Placeholder'" [additional]="'(optional)'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'placeholder',
                  field: 'CharField',
                  placeholder: 'Field description',
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </app-page-block>

        <app-page-block>
          <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
        </app-page-block>

        <app-page-block>
          <app-stack [align]="'right'">
            <button
              type="button"
              class="button button_orange-transparent popup2__footer-item"
              (click)="cancel()"
              [disabled]="submitLoading"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="button button_primary button_orange popup2__footer-item"
              [disabled]="submitLoading || !form.valid"
            >
              <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
              <ng-container *ngIf="property">Save</ng-container>
              <ng-container *ngIf="!property">Create</ng-container>
            </button>
          </app-stack>
        </app-page-block>
      </div>
    </div>
  </form>
</app-popup2>

<div *ngIf="control.controls.description.value" class="query-builder-list-item-label">
  {{ control.controls.description.value }}
</div>

<div class="query-builder-list-item">
  <div class="query-builder-list-item__left">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 - 8 },
        { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 + 8 },
        { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 8 + 8, offsetY: 0 }
      ]"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="popoverOpened"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="setPopoverOpened(false)"
      (detach)="setPopoverOpened(false)"
    >
      <div (cdkObserveContent)="onPopoverContentChanged()">
        <div class="popover2">
          <div class="popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse">
            <div class="popover2__viewport popover2__viewport_scrollable">
              <app-fields-edit-item
                [control]="control"
                [configurable]="{
                  verboseName: true,
                  field: true,
                  required: true,
                  params: true
                }"
                (saveRequested)="setPopoverOpened(false)"
                (deleteRequested)="deleteRequested.emit()"
              ></app-fields-edit-item>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <a
      href="javascript:void(0)"
      class="button button_icon button_orange-alternative button_small"
      [class.button_primary]="popoverOpened"
      [class.button_disabled]="popoverOpened"
      style="margin: 0;"
      cdkOverlayOrigin
      (click)="setPopoverOpened(true)"
      #trigger="cdkOverlayOrigin"
      #button
    >
      <span class="icon button__icon" [ngClass]="['icon-' + (fieldIcon$ | async)]"></span>
    </a>
  </div>

  <div class="query-builder-list-item__main">
    <input
      class="input input_fill"
      [formControl]="control.controls.name"
      placeholder="Field name"
      [appAutofocus]="autofocus"
    />
  </div>

  <div *ngIf="formField" class="query-builder-list-item__main">
    <app-auto-field [form]="form" [field]="formField" [label]="false"> </app-auto-field>
  </div>

  <div class="query-builder-list-item__right">
    <a
      href="javascript:void(0)"
      style="margin-right: 8px;"
      (click)="control.controls.required.patchValue(!control.controls.required.value)"
    >
      <app-toggle
        [orange]="true"
        [selected]="control.controls.required.value"
        [label]="'Required'"
        [labelPosition]="'left'"
      >
      </app-toggle>
    </a>

    <a href="javascript:void(0)" class="query-builder-list-item__action icon-bin" (click)="deleteRequested.emit()"></a>
  </div>
</div>

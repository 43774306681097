import { EventEmitter, OnInit } from '@angular/core';
var ContainerAppStubComponent = /** @class */ (function () {
    function ContainerAppStubComponent() {
        this.fill = true;
        this.templates = true;
        this.customizeClick = new EventEmitter();
        this.cls = true;
    }
    Object.defineProperty(ContainerAppStubComponent.prototype, "marginSizeS", {
        get: function () {
            return this.fill;
        },
        enumerable: true,
        configurable: true
    });
    ContainerAppStubComponent.prototype.ngOnInit = function () { };
    return ContainerAppStubComponent;
}());
export { ContainerAppStubComponent };

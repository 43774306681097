var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewChecked, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Frame, Translate } from '@modules/views';
import { generateHash } from '@shared';
import { ViewEditorViewportOverlayService } from '../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service';
var ViewEditorViewportOverlayDirective = /** @class */ (function () {
    function ViewEditorViewportOverlayDirective(tpl, viewportOverlayService) {
        this.tpl = tpl;
        this.viewportOverlayService = viewportOverlayService;
        this.id = generateHash(16);
    }
    ViewEditorViewportOverlayDirective.prototype.ngOnInit = function () { };
    ViewEditorViewportOverlayDirective.prototype.ngOnDestroy = function () {
        this.viewportOverlayService.removeTemplate(this.id);
    };
    ViewEditorViewportOverlayDirective.prototype.ngOnChanges = function (changes) {
        if (changes.options) {
            this.updateTemplate();
        }
    };
    ViewEditorViewportOverlayDirective.prototype.ngAfterViewChecked = function () {
        this.viewportOverlayService.markTemplateChecked(this.id);
    };
    ViewEditorViewportOverlayDirective.prototype.getOptions = function () {
        return __assign({ enabled: true }, this.options);
    };
    ViewEditorViewportOverlayDirective.prototype.updateTemplate = function () {
        var options = this.getOptions();
        if (options.enabled) {
            this.viewportOverlayService.setTemplate(this.id, this.tpl, {
                frame: options.frame,
                translate: options.translate,
                transform: options.transform,
                interactive: options.interactive,
                order: options.order
            });
        }
        else {
            this.viewportOverlayService.removeTemplate(this.id);
        }
    };
    return ViewEditorViewportOverlayDirective;
}());
export { ViewEditorViewportOverlayDirective };

export function cacheCall(func) {
    var cachedValue;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (cachedValue === undefined) {
            cachedValue = func.apply(void 0, args);
        }
        return cachedValue;
    };
}

import { FormControl, FormGroup } from '@angular/forms';

import { Option } from '@modules/field-components';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { ImageFill, ImageFillType } from '@modules/views';
import { isSet } from '@shared';

export class ImageFillControl extends FormGroup {
  instance: ImageFill;

  controls: {
    type: FormControl;
    image: FormControl;
    image_input_enabled: FormControl;
    image_input: FieldInputControl;
    scale_x: FormControl;
    scale_y: FormControl;
  };

  typeOptions: Option<ImageFillType>[] = [
    {
      value: ImageFillType.Fill,
      name: 'Fill Image'
    },
    {
      value: ImageFillType.Fit,
      name: 'Fit Image'
    },
    {
      value: ImageFillType.Stretch,
      name: 'Stretch Image'
    },
    {
      value: ImageFillType.Tile,
      name: 'Tile Image'
    }
  ];

  constructor(state: Partial<ImageFill> = {}) {
    super({
      type: new FormControl(isSet(state.type) ? state.type : ImageFillType.Fill),
      image: new FormControl(isSet(state.image) ? state.image : undefined),
      image_input_enabled: new FormControl(isSet(state.imageInput) ? !!state.imageInput : false),
      image_input: new FieldInputControl({ name: 'value' }),
      scale_x: new FormControl(isSet(state.scaleX) ? state.scaleX : 1),
      scale_y: new FormControl(isSet(state.scaleY) ? state.scaleY : 1)
    });
  }

  deserialize(value: ImageFill, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.type.patchValue(value.type, { emitEvent: options.emitEvent });
    this.controls.image.patchValue(value.image, { emitEvent: options.emitEvent });
    this.controls.image_input_enabled.patchValue(!!value.imageInput, { emitEvent: options.emitEvent });
    this.controls.image_input.patchValue(value.imageInput ? value.imageInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });
    this.controls.scale_x.patchValue(value.scaleX, { emitEvent: options.emitEvent });
    this.controls.scale_y.patchValue(value.scaleY, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: ImageFill): ImageFill {
    if (!instance) {
      instance = new ImageFill();
    }

    instance.type = this.controls.type.value;

    if (this.controls.image_input_enabled.value) {
      instance.image = this.controls.image.value;
      instance.imageInput = this.controls.image_input.value
        ? new Input().deserialize(this.controls.image_input.value)
        : undefined;
    } else {
      instance.image = this.controls.image.value;
      instance.imageInput = undefined;
    }

    instance.scaleX = this.controls.scale_x.value;
    instance.scaleY = this.controls.scale_y.value;

    return instance;
  }

  serialize(): ImageFill {
    return this.getInstance(this.instance);
  }
}

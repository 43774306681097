import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ActionApproveService } from '@modules/action-queries';
import { ApproveResultState } from '@modules/actions';
import { ServerRequestError } from '@modules/api';
import { Task } from '@modules/collaboration';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-collaboration-tasks-item',
  templateUrl: './collaboration-tasks-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationTasksItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() task: Task;
  @Input() clickable = false;
  @Output() updated = new EventEmitter<Task>();

  assignedToMe = false;
  approveResultStates = ApproveResultState;

  constructor(
    private currentUserStore: CurrentUserStore,
    private notificationService: NotificationService,
    private actionApproveService: ActionApproveService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateAssigned();
  }

  updateAssigned() {
    this.assignedToMe = this.task.assigned && this.currentUserStore.instance.uid == this.task.assigned.uid;
    this.cd.markForCheck();
  }

  approve() {
    this.actionApproveService
      .approve(this.task, { injector: this.injector })
      .pipe(untilDestroyed(this))
      .subscribe(
        task => {
          this.updated.emit(task);
          this.notificationService.success('Approved', 'Action was successfully approved');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', `<strong>Approve</strong> failed: ${error.errors[0]}`);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  reject() {
    this.actionApproveService
      .reject(this.task, { injector: this.injector })
      .pipe(untilDestroyed(this))
      .subscribe(
        task => {
          this.updated.emit(task);
          this.notificationService.success('Rejected', 'Action was successfully rejected');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', `<strong>Reject</strong> failed: ${error.errors[0]}`);
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { registerFieldDataParamsComponent } from '@modules/fields/data/components';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { TimeFieldDataParamsForm } from './time-field-data-params.form';
var TimeFieldDataParamsComponent = /** @class */ (function (_super) {
    __extends(TimeFieldDataParamsComponent, _super);
    function TimeFieldDataParamsComponent(form) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.createField = createFormFieldFactory();
        return _this;
    }
    TimeFieldDataParamsComponent.prototype.ngOnInit = function () {
        this.form.init(this.control);
    };
    return TimeFieldDataParamsComponent;
}(FieldParamsComponent));
export { TimeFieldDataParamsComponent };
registerFieldDataParamsComponent(FieldType.Time, TimeFieldDataParamsComponent);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { format } from 'sql-formatter';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AiDatabaseEngine, AiService } from '@modules/ai';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { CodeFieldComponent, fieldsEditItemFromParameterField, fieldsEditItemToParameterField } from '@modules/field-components';
import { createFormFieldFactory, ParameterArray } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Resource, ResourceName } from '@modules/projects';
import { QueryService, SQL_QUERY_VERSION } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { Benchmark, controlValue, filterObject, isSet, limitObjectLength } from '@shared';
// Refactor imports
import { SqlFieldComponent } from '@modules/field-components/components/sql-field/sql-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';
import { QueryBuilderContext } from '../../data/query-builder-context';
import { InputTokensEventType } from '../input-tokens/input-tokens.component';
import { TextToSqlOverlayComponent } from '../text-to-sql-overlay/text-to-sql-overlay.component';
import { QueryBuilderSqlForm } from './query-builder-sql.form';
export var SqlResultsSection;
(function (SqlResultsSection) {
    SqlResultsSection[SqlResultsSection["Parameters"] = 0] = "Parameters";
    SqlResultsSection[SqlResultsSection["Result"] = 1] = "Result";
})(SqlResultsSection || (SqlResultsSection = {}));
export var SQLFormatterDialect;
(function (SQLFormatterDialect) {
    SQLFormatterDialect["SQL"] = "sql";
    SQLFormatterDialect["PostgresSQL"] = "postgresql";
    SQLFormatterDialect["MySQL"] = "mysql";
    SQLFormatterDialect["MicrosoftSQL"] = "tsql";
    SQLFormatterDialect["Oracle"] = "plsql";
})(SQLFormatterDialect || (SQLFormatterDialect = {}));
var QueryBuilderSqlComponent = /** @class */ (function () {
    function QueryBuilderSqlComponent(form, queryService, resourceControllerService, aiService, modelDescriptionStore, notificationService, dialogService, analyticsService, cd) {
        var _this = this;
        this.form = form;
        this.queryService = queryService;
        this.resourceControllerService = resourceControllerService;
        this.aiService = aiService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.requireResponse = false;
        this.arrayResponse = false;
        this.customSections = [];
        this.executed = new EventEmitter();
        this.saved = new EventEmitter();
        this.canceled = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = false;
        this.requestCreate = false;
        this.responseActual = false;
        this.incorrectLike = false;
        this.incorrectLikeClosed = false;
        this.upgradeQuery = false;
        this.upgradeQueryClosed = false;
        this.sqlPlaceholder = "SELECT\n  *\nFROM\n  users\nWHERE\n  city = {{params.city}} AND name ILIKE {{'%'+params.name+'%'}}";
        this.textToSqlLoading = false;
        this.resultsSection = SqlResultsSection.Result;
        this.resultsSections = SqlResultsSection;
        this.customSectionComponentsTop = [];
        this.customSectionComponentsBottom = [];
        this.customSectionComponentsPreview = [];
        this.fieldsEditItemToParameterField = fieldsEditItemToParameterField;
        this.fieldsEditItemFromParameterField = fieldsEditItemFromParameterField;
        this.saveHovered = new BehaviorSubject(false);
        this.requireResponseMessageHovered = new BehaviorSubject(false);
        this.requireResponseMessageVisible$ = combineLatest(this.saveHovered.pipe(filter(function (value) { return (value && _this.isResponseMissing()) || !value; }), debounce(function (value) { return timer(value ? 0 : 200); })), this.requireResponseMessageHovered).pipe(map(function (_a) {
            var saveHovered = _a[0], requireResponseMessageHovered = _a[1];
            return saveHovered || requireResponseMessageHovered;
        }));
    }
    QueryBuilderSqlComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = this.control.value;
        this.form.init(query);
        this.context.sqlForm = this.form;
        this.context.paginationTokens = false;
        this.context.sortingTokens = false;
        if (query) {
            this.requestCreate = false;
            this.context.tokenValues = query.requestTokens;
            if (query.requestResponse !== undefined) {
                this.response = query.requestResponse;
                this.responseActual = true;
                this.cd.markForCheck();
            }
        }
        else {
            this.requestCreate = true;
            this.context.tokenValues = {};
        }
        if (isSet(this.currentOptions.initialResultsSection)) {
            this.resultsSection = this.currentOptions.initialResultsSection;
        }
        else {
            this.resultsSection = this.response ? SqlResultsSection.Result : SqlResultsSection.Result;
        }
        this.cd.markForCheck();
        merge.apply(void 0, values(this.form.controls)
            .filter(function (control) {
            return [
                _this.form.controls.request_response,
                _this.form.controls.request_response_columns,
                _this.form.controls.request_tokens
            ].every(function (item) { return item !== control; });
        })
            .map(function (control) { return control.valueChanges; })).pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function () {
            _this.responseActual = false;
            _this.cd.markForCheck();
        });
        this.updateCustomSections();
        if (SQL_QUERY_VERSION >= 2) {
            if (!query || query.version != 1) {
                controlValue(this.form.controls.query)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (value) {
                    _this.incorrectLike = !!value.match(/['"`]%{{[\w.]+}}%['"`]/g);
                    _this.cd.markForCheck();
                });
            }
            var controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;
            if (controller && controller.checkApiInfo) {
                controller
                    .checkApiInfo(this.resource)
                    .pipe(untilDestroyed(this))
                    .subscribe(function () {
                    if (query &&
                        query.version == 1 &&
                        _this.resource.apiInfo.isCompatibleJetBridge({ jetBridge: '1.0.0', jetDjango: '1.1.5' })) {
                        _this.upgradeQuery = true;
                        _this.cd.markForCheck();
                    }
                });
            }
        }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.StartToSetUp, {
            ResourceType: this.resource.typeItem.name,
            Source: this.source
        });
    };
    QueryBuilderSqlComponent.prototype.ngOnDestroy = function () { };
    QueryBuilderSqlComponent.prototype.ngOnChanges = function (changes) { };
    Object.defineProperty(QueryBuilderSqlComponent.prototype, "currentOptions", {
        get: function () {
            return __assign({}, this.options);
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderSqlComponent.prototype.setResultsSection = function (resultsSection) {
        this.resultsSection = resultsSection;
        this.cd.markForCheck();
    };
    QueryBuilderSqlComponent.prototype.updateCustomSections = function () {
        this.customSectionComponentsTop = this.customSections
            .filter(function (item) { return item.position == 'top'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsBottom = this.customSections
            .filter(function (item) { return item.position == 'bottom'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsPreview = this.customSections
            .filter(function (item) { return item.position == 'preview'; })
            .map(function (item) { return item.component; });
        this.cd.markForCheck();
    };
    QueryBuilderSqlComponent.prototype.execute = function () {
        var _this = this;
        var query = this.form.getInstance();
        var requestTokens = this.context.serializeTokenValues();
        var saveTokens = this.context.serializeTokenValues(true);
        this.loading = true;
        this.response = undefined;
        this.error = undefined;
        this.resultsSection = SqlResultsSection.Result;
        this.cd.markForCheck();
        var controller = this.resource ? this.resourceControllerService.getForResource(this.resource, true) : undefined;
        controller
            .sql(this.resource, query.query, { tokens: requestTokens, version: query.version })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.response = result ? result.toObject() : undefined;
            _this.loading = false;
            _this.context.lastExecutedResponse = _this.response;
            _this.form.controls.request_response.patchValue(limitObjectLength(_this.response, 20));
            _this.form.controls.request_response_columns.patchValue(result.columnDescriptions);
            _this.form.controls.request_tokens.patchValue(filterObject(saveTokens, function (item) { return !(item instanceof Blob); }));
            _this.responseActual = true;
            _this.executed.emit(query);
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.SuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                if (error.fieldErrors['error']) {
                    _this.error = String(error.fieldErrors['error']).trim();
                }
                else if (error.nonFieldErrors.length) {
                    _this.error = String(error.nonFieldErrors[0]).trim();
                }
                else {
                    _this.error = 'Unknown error';
                }
                _this.notificationService.error('Request failed', _this.error);
            }
            else {
                _this.notificationService.error('Request failed', "Unknown error");
            }
            _this.responseActual = true;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.UnsuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        });
    };
    QueryBuilderSqlComponent.prototype.insertSqlByNaturalText = function (query) {
        var _this = this;
        this.textToSqlLoading = true;
        this.cd.markForCheck();
        var benchmark = new Benchmark(true);
        this.modelDescriptionStore
            .get()
            .pipe(switchMap(function (modelDescriptions) {
            var tables = modelDescriptions
                .filter(function (item) { return item.resource == _this.resource.uniqueName; })
                .filter(function (item) { return isSet(item.dbTable); })
                .map(function (item) {
                return {
                    name: item.dbTable,
                    columns: item.dbFields
                        .filter(function (field) { return isSet(field.dbColumn); })
                        .map(function (field) {
                        return {
                            name: field.dbColumn
                        };
                    })
                };
            })
                .filter(function (item) { return item.columns.length; });
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.StartedTranslation, {
                Type: 'TextToSQL',
                Query: query
            });
            var engine;
            if (_this.resource.isSynced() || _this.resource.hasCollectionSync()) {
                engine = AiDatabaseEngine.PostgresSQL;
            }
            else if ([
                ResourceName.PostgreSQL,
                ResourceName.Redshift,
                ResourceName.AlloyDB,
                ResourceName.Supabase,
                ResourceName.JetDatabase
            ].includes(_this.resource.typeItem.name)) {
                engine = AiDatabaseEngine.PostgresSQL;
            }
            else if ([ResourceName.MySQL, ResourceName.MariaDB].includes(_this.resource.typeItem.name)) {
                engine = AiDatabaseEngine.MySQL;
            }
            else if (_this.resource.typeItem.name == ResourceName.MicrosoftSQL) {
                engine = AiDatabaseEngine.MicrosoftSQL;
            }
            else if (_this.resource.typeItem.name == ResourceName.Oracle) {
                engine = AiDatabaseEngine.Oracle;
            }
            else if (_this.resource.typeItem.name == ResourceName.BigQuery) {
                engine = AiDatabaseEngine.BigQuery;
            }
            else if (_this.resource.typeItem.name == ResourceName.Snowflake) {
                engine = AiDatabaseEngine.Snowflake;
            }
            else if (_this.resource.typeItem.name == ResourceName.SQLite) {
                engine = AiDatabaseEngine.SQLite;
            }
            benchmark.reset();
            return _this.aiService.getSqlByNaturalText(query, tables, engine);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            if (isSet(result)) {
                _this.textToSqlOverlayComponent.reset();
                _this.setFormattedText(result);
            }
            _this.textToSqlLoading = false;
            _this.cd.markForCheck();
            _this.notificationService.success('SQL inserted', 'Your text query has been translated to SQL');
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.Translated, {
                Type: 'TextToSQL',
                Query: query,
                Result: result,
                Time: benchmark.secondElapsed(1)
            });
        }, function (error) {
            _this.textToSqlLoading = false;
            _this.cd.markForCheck();
            var errorMessage;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                errorMessage = error.nonFieldErrors[0];
            }
            else {
                errorMessage = 'Unknown error';
            }
            _this.notificationService.error('Translate Failed', errorMessage);
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.AI.TranslationFailed, {
                Type: 'TextToSQL',
                Query: query,
                Error: errorMessage,
                Time: benchmark.secondElapsed(1)
            });
        });
    };
    QueryBuilderSqlComponent.prototype.onTokenChanged = function () { };
    QueryBuilderSqlComponent.prototype.getAce = function () {
        if (this.queryFieldComponent &&
            this.queryFieldComponent.dynamicComponent.currentComponent &&
            (this.queryFieldComponent.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent ||
                this.queryFieldComponent.dynamicComponent.currentComponent.instance instanceof SqlFieldComponent)) {
            return this.queryFieldComponent.dynamicComponent.currentComponent.instance.ace;
        }
    };
    QueryBuilderSqlComponent.prototype.setText = function (text) {
        var ace = this.getAce();
        if (!ace) {
            return;
        }
        ace.editor.setValue(text, 1);
    };
    QueryBuilderSqlComponent.prototype.setFormattedText = function (value) {
        var dialect;
        if (this.resource.isSynced() || this.resource.hasCollectionSync()) {
            dialect = SQLFormatterDialect.PostgresSQL;
        }
        else if ([
            ResourceName.PostgreSQL,
            ResourceName.Redshift,
            ResourceName.AlloyDB,
            ResourceName.Supabase,
            ResourceName.JetDatabase
        ].includes(this.resource.typeItem.name)) {
            dialect = SQLFormatterDialect.PostgresSQL;
        }
        else if ([ResourceName.MySQL, ResourceName.MariaDB].includes(this.resource.typeItem.name)) {
            dialect = SQLFormatterDialect.MySQL;
        }
        else if (this.resource.typeItem.name == ResourceName.MicrosoftSQL) {
            dialect = SQLFormatterDialect.MicrosoftSQL;
        }
        else if (this.resource.typeItem.name == ResourceName.Oracle) {
            dialect = SQLFormatterDialect.Oracle;
        }
        else if (this.resource.typeItem.name == ResourceName.BigQuery) {
            dialect = SQLFormatterDialect.SQL;
        }
        else if (this.resource.typeItem.name == ResourceName.Snowflake) {
            dialect = SQLFormatterDialect.SQL;
        }
        else if (this.resource.typeItem.name == ResourceName.SQLite) {
            dialect = SQLFormatterDialect.SQL;
        }
        else {
            dialect = SQLFormatterDialect.SQL;
        }
        var placeholders = [];
        value = value.replace(/{{([^}]+)}}/g, function (str, group) {
            var index = placeholders.length;
            placeholders.push(group);
            return "__JET_VAR__(" + index + ")";
        });
        var result = format(value, {
            language: dialect
        }).replace(/__JET_VAR__\((\d+)\)/g, function (str, group) {
            var index = parseInt(group, 10);
            if (isNaN(index)) {
                return '';
            }
            return "{{" + placeholders[index] + "}}";
        });
        this.setText(result);
    };
    QueryBuilderSqlComponent.prototype.insertText = function (text) {
        var ace = this.getAce();
        if (!ace) {
            return;
        }
        var selectionRange = ace.editor.selection.getRange();
        ace.editor.session.replace(selectionRange, text);
    };
    QueryBuilderSqlComponent.prototype.formatCurrentValue = function () {
        this.setFormattedText(this.form.controls.query.value || '');
    };
    QueryBuilderSqlComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    QueryBuilderSqlComponent.prototype.saveProcess = function () {
        var query = this.form.getInstance();
        this.control.patchValue(query);
        this.saved.emit();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SQLBuilder.SuccessfullySetUp, {
            ResourceType: this.resource.typeItem.name
        });
    };
    QueryBuilderSqlComponent.prototype.isResponseMissing = function () {
        return this.requireResponse && !this.responseActual;
    };
    QueryBuilderSqlComponent.prototype.save = function () {
        if (this.isResponseMissing()) {
            return;
        }
        this.saveProcess();
    };
    QueryBuilderSqlComponent.prototype.submit = function () {
        this.save();
    };
    QueryBuilderSqlComponent.prototype.onInputTokensEvent = function (event) {
        if (event.type == InputTokensEventType.AddParameter) {
            if (this.parametersControl) {
                this.setResultsSection(SqlResultsSection.Parameters);
            }
        }
    };
    QueryBuilderSqlComponent.prototype.closeIncorrectLike = function () {
        this.incorrectLikeClosed = true;
        this.cd.markForCheck();
    };
    QueryBuilderSqlComponent.prototype.closeUpgradeQuery = function () {
        this.upgradeQueryClosed = true;
        this.cd.markForCheck();
    };
    return QueryBuilderSqlComponent;
}());
export { QueryBuilderSqlComponent };

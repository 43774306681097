import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import * as numeral from 'numeral';

import { isSet } from '@shared';

// TODO: Refactor import
import { getColorHexStr } from '../../../colors/utils/colors';

export interface DataTooltipDataset {
  value: number | string;
  valueFormat?: string;
  percentage?: number;
  label: string;
  color: string;
}

@Component({
  selector: 'app-data-tooltip',
  templateUrl: './data-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTooltipComponent implements OnInit {
  @Input() group: string;
  @Input() datasets: DataTooltipDataset[] = [];
  @Input() datasetActiveIndex: number;
  @Input() valueFormat: string;
  @Input() colorLine = false;
  @Input() colorCircle = false;

  @HostBinding('class.chart-tooltip-container') cls = true;
  @ViewChild('root') root: ElementRef;

  maxHeight: number;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  valueDisplay(value: number, valueFormat?: string): string {
    const format = valueFormat || this.valueFormat;

    if (!isSet(format)) {
      return String(value);
    }

    return numeral(value).format(format);
  }

  colorDisplay(value: string): string {
    return getColorHexStr(value);
  }

  getContentWidth() {
    return this.root.nativeElement.scrollWidth;
  }

  getContentHeight() {
    return this.root.nativeElement.scrollHeight;
  }

  setMaxHeight(height: number) {
    this.maxHeight = height;
    this.cd.markForCheck();
  }
}

<!--<app-loader *ngIf="loading" [title]="'Loading'" [fill]="true">-->
<!--  We are fetching dashboard data, please, wait...-->
<!--</app-loader>-->

<!--<app-dashboard-stub *ngIf="loading" [animating]="true"></app-dashboard-stub>-->

<div class="dashboard">
  <!--  <app-admin-header-->
  <!--    *ngIf="!(customizeService.enabled$ | async)"-->
  <!--    [userActivitiesParams]="userActivitiesParams"-->
  <!--    [collaborationParams]="userActivitiesParams"-->
  <!--  ></app-admin-header>-->

  <div #dashboard_element_wrapper>
    <div
      *ngIf="widgets"
      class="dashboard__inner"
      [class.customizable]="customizeService.layoutEnabled$ | async"
      [style.width.px]="columnWidth * columns"
      #dashboard_element
    >
      <div
        *ngFor="let widget of widgets; trackBy: trackByFn"
        class="dashboard__item dashboard__item_absolute"
        [class.dashboard__item_resizing]="resizingWidget?.id == widget.id"
        [ngClass]="['dashboard__item_width_' + widget.width, 'dashboard__item_height_' + widget.height]"
        [attr.data-id]="widget.id"
        #dashboard_item_element
      >
        <app-dashboard-item
          [dashboard]="widgetsDashboard"
          [widget]="widget"
          [columnWidth]="columnWidth - 10"
          [rowHeight]="rowHeight - 10"
          [context]="context"
          (sizeChanged)="onWidgetSizeChanged()"
          (sizeChangeConfirmed)="onWidgetSizeChangeConfirmed()"
          (resizingChanged)="onWidgetResizingChanged($event)"
          (updated)="updateItem(widget, $event)"
          (copyRequested)="copyWidget(widget)"
          (moveRequested)="moveToDashboard(widget, $event)"
          (deleteRequested)="deleteWidget(widget)"
        >
        </app-dashboard-item>
      </div>
    </div>
  </div>
</div>

<!--<app-feature-overview-->
<!--  *ngIf="!loading && widgets && widgets.length == 0"-->
<!--  [fill]="true"-->
<!--  [feature]="'dashboard'"-->
<!--  [action]="'Add Component'"-->
<!--  (actionClicked)="toggleCustomize()"-->
<!--&gt;-->
<!--  <div data-title>Keep Track of All Relevant KPIs with <strong>Dashboards</strong></div>-->
<!--  <div data-description>-->
<!--    <ul>-->
<!--      <li>Use multiple dashboards to track your KPIs and monitor productivity</li>-->
<!--      <li>Aggregate all your crucial metrics in one view</li>-->
<!--      <li>Use SQL for more complex queries</li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</app-feature-overview>-->

<ng-container *ngIf="customizeService.enabled$ | async">
  <div class="admin__sidebar">
    <app-dashboard-customize-bar
      *ngIf="widgets"
      [dashboard]="dashboard"
      [widgetPosition]="widgetPosition"
      (widgetAdded)="onWidgetAdded($event)"
    >
    </app-dashboard-customize-bar>
  </div>
</ng-container>

import {
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject } from 'rxjs';

import { UniqueIdToken } from '@common/unique-id';
import { ApiType, ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  applyParamInput,
  deserializeMultipleFileValue,
  ImageOutputFormat,
  Input as FieldInput,
  MultipleFileStoreFormat
} from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { controlValue, getExtension, getFilename, getFilenameWithExtension, isSet, openUrl } from '@shared';

import { UploadedFile } from '../file-field/upload-field.component';
import { cropFormats } from '../image-field/image-field.crop';

export const UPLOAD_RESULT_OUTPUT = 'upload_result';
export const FILE_NAME_OUTPUT = 'file_name';
export const FILE_EXTENSION_OUTPUT = 'file_extension';

export interface UploadState {
  uploadFileSize?: number;
  uploadProgress?: number;
  uploadError?: string;
}

export type MultipleUploadedFile = UploadedFile & UploadState;

export abstract class BaseMultipleUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() storeFormat: MultipleFileStoreFormat;
  @Input() outputFormat: ImageOutputFormat;
  @Input() storageResourceName: string;
  @Input() storageName: string;
  @Input() path: FieldInput;
  @Input() autofocus = false;
  @Input() disabled = false;
  @Input() background = false;
  @Input() fit: string;
  @Input() resize = false;
  @Input() editor = true;
  @Input() cropFormat: cropFormats;
  @Input() cropFormatCustom: string;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() compact = false;
  @Input() accentColor: string;
  @Output() uploadedUpdated = new EventEmitter<void>();

  idToken = new UniqueIdToken();
  uploadedFiles: MultipleUploadedFile[] = [];
  upload: UploadState;

  storage$ = new BehaviorSubject<Storage>(undefined);
  storageResource$ = new BehaviorSubject<Resource>(undefined);

  constructor(
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected resourceControllerService: ResourceControllerService,
    protected storageService: StorageService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.storageResource$, controlValue(this.control))
      .pipe(untilDestroyed(this))
      .subscribe(([storageResource, value]) => {
        const values = deserializeMultipleFileValue(value);

        this.uploadedFiles = values.map(item => {
          item = this.getUploadUrl(item, storageResource);
          return this.getUploadedFileFromValue(item);
        });
        this.cd.markForCheck();
        this.onUploadedUpdated();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['storageResourceName'] || changes['storageName']) {
      this.updateStorage();
    }
  }

  getUploadUrl(value: string, storageResource: Resource): string {
    if (!isSet(value)) {
      return;
    }

    // const apiInfo = this.getApiInfo();
    const apiInfo = storageResource ? storageResource.apiInfo : undefined;

    if (
      apiInfo &&
      apiInfo.type == ApiType.JetDjango &&
      apiInfo.versionLessThan('0.7.7')
      // !this.field.params['ignore_old_django_format']
    ) {
      value = value['value'];
    }

    if (this.outputFormat == ImageOutputFormat.Storage) {
      const controller = storageResource ? this.resourceControllerService.get(storageResource.type) : undefined;

      if (controller && controller.fileUrl) {
        return controller.fileUrl(storageResource, value);
      }
    }

    return value;
  }

  updateStorage() {
    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      this.storageResourceName,
      this.storageName,
      { defaultFirst: true }
    );

    this.storageResource$.next(storage ? storage.resource : undefined);
    this.storage$.next(storage ? storage.storage : undefined);
  }

  getUploadedFileFromValue(value: string) {
    if (!value) {
      return;
    }

    return {
      url: value,
      filename: getFilenameWithExtension(value),
      extension: getExtension(value)
    };

    // if (this.contextElement) {
    //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.preview));
    //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
    // }
    //   },
    //   () => {
    //     this.preview = undefined;
    //     this.previewFilename = undefined;
    //     this.previewExtension = undefined;
    //     this.cd.markForCheck();
    //     this.onPreviewUpdate(this.preview);
    //
    //     if (this.contextElement) {
    //       this.contextElement.setOutputValue(FILE_NAME_OUTPUT, this.previewFilename);
    //       this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
    //     }
    //   }
    // );
  }

  onUploadedUpdated() {}

  // isUploadable() {
  //   return (
  //     (!this.field.params['output_format'] || this.field.params['output_format'] == ImageOutputFormat.Storage) &&
  //     !this.field.params['external']
  //   );
  // }

  isUploadable() {
    return this.outputFormat != ImageOutputFormat.URL;
  }

  setControlValue(value: string[]) {
    let newValue: any;

    if (this.storeFormat == MultipleFileStoreFormat.String) {
      newValue = value.join(',');
    } else {
      newValue = value;
    }

    this.control.patchValue(newValue);
  }

  clearCurrentValue(index: number) {
    if (this.control) {
      const values = deserializeMultipleFileValue(this.control.value);
      values.splice(index, 1);
      this.setControlValue(values);
    }
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    el.value = null;
    this.onFileSelected(file);
  }

  getUploadPath(file: File) {
    if (this.path) {
      try {
        let result = applyParamInput(this.path, {
          context: this.context,
          contextElement: this.contextElement,
          localContext: {
            [FILE_NAME_OUTPUT]: getFilename(file.name),
            [FILE_EXTENSION_OUTPUT]: getExtension(file.name)
          },
          defaultValue: ''
        });

        if (isSet(result)) {
          result = String(result);
        }

        return result;
      } catch (e) {
        return '';
      }
      // } else if (this.context['modelDescription']) {
      //   return [this.context['modelDescription'].model, this.field.name].join('/');
      // } else if (this.context['action']) {
      //   return ['actions', this.context['action'], this.field.name].join('/');
    } else {
      return '';
    }
  }

  onFileSelected(file: File, index?: number) {
    const path = this.getUploadPath(file);
    this.uploadFile(file, path, index).pipe(untilDestroyed(this)).subscribe();
  }

  onFilesSelected(files: File[], index?: number) {
    merge(
      ...files.map(file => {
        const path = this.getUploadPath(file);
        return this.uploadFile(file, path, index);
      })
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  uploadFile(file: File, path: string, index?: number): Observable<string> {
    const obs$ = new ReplaySubject<string>();

    if (isSet(index)) {
      this.uploadedFiles[index] = {
        url: undefined,
        filename: undefined,
        extension: undefined,
        uploadFileSize: file.size,
        uploadProgress: 0,
        uploadError: undefined
      };
    } else {
      this.upload = {
        uploadFileSize: file.size,
        uploadProgress: 0,
        uploadError: undefined
      };
    }

    this.cd.markForCheck();
    this.onUploadedUpdated();

    const storageResource = this.storageResource$.value;
    const storage = this.storage$.value;

    if (!storageResource || !storage) {
      obs$.error(undefined);
      obs$.complete();

      return obs$.asObservable();
    }

    this.storageService
      .upload(storageResource, storage, storage.uploadQuery, file, path)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (!response) {
            if (isSet(index)) {
              this.uploadedFiles[index] = {
                ...this.uploadedFiles[index],
                uploadProgress: undefined
              };
            } else {
              this.upload = undefined;
            }

            this.cd.markForCheck();

            if (this.contextElement) {
              this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }

            obs$.error(undefined);
            obs$.complete();

            return;
          }

          if (response.result) {
            let value: any;

            // if (this.form) {
            // const params = {};
            // const apiInfo = this.getApiInfo();
            const apiInfo = storageResource ? storageResource.apiInfo : undefined;

            if (
              apiInfo &&
              apiInfo.type == ApiType.JetDjango &&
              apiInfo.versionLessThan('0.7.7')
              // !this.field.params['ignore_old_django_format']
            ) {
              value = {
                value: response.result.uploadedPath,
                url: response.result.uploadedUrl
              };
            } else {
              value = response.result.uploadedUrl;
            }

            if (isSet(index)) {
              this.uploadedFiles[index] = {
                ...this.uploadedFiles[index],
                uploadProgress: undefined
              };
            } else {
              this.upload = undefined;
            }
            this.cd.markForCheck();

            if (this.control) {
              const values = deserializeMultipleFileValue(this.control.value);
              let newValue: string[];

              if (isSet(index)) {
                newValue = values.map((item, i) => (i === index ? value : item));
              } else {
                newValue = [...values, value];
              }

              this.setControlValue(newValue);
            }

            this.uploadedUpdated.next();

            if (this.contextElement) {
              this.contextElement.setOutputValue(
                UPLOAD_RESULT_OUTPUT,
                response.result.response ? response.result.response.body : undefined
              );
            }

            obs$.next(value);
            obs$.complete();
          } else {
            const progress = response.state.uploadProgress * 0.95 + response.state.downloadProgress * 0.05;

            if (isSet(index)) {
              this.uploadedFiles[index] = {
                ...this.uploadedFiles[index],
                uploadProgress: progress
              };
            } else {
              this.upload = {
                ...this.upload,
                uploadProgress: progress
              };
            }

            this.cd.markForCheck();

            if (this.contextElement) {
              this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }
          }
        },
        error => {
          let uploadError: string;

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            uploadError = error.nonFieldErrors[0];
          } else {
            uploadError = 'Unknown error';
          }

          if (isSet(index)) {
            this.uploadedFiles[index] = {
              ...this.uploadedFiles[index],
              uploadProgress: undefined,
              uploadError: uploadError
            };
          } else {
            this.upload = {
              ...this.upload,
              uploadProgress: undefined,
              uploadError: uploadError
            };
          }

          this.cd.markForCheck();

          if (this.contextElement) {
            this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
          }

          obs$.error(error);
        }
      );

    return obs$.asObservable();
  }

  download(index: number) {
    const uploadedFile = this.uploadedFiles[index];

    if (uploadedFile) {
      openUrl(uploadedFile.url, true);
    }
  }
}

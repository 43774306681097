import { Injector } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { GuidePopupComponent } from '../../components/guide-popup/guide-popup.component';
import { GuidePopupStoreService } from '../guide-popup-store/guide-popup-store.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/popups/services/popup/popup.service";
import * as i2 from "../guide-popup-store/guide-popup-store.service";
var GuidePopupService = /** @class */ (function () {
    function GuidePopupService(popupService, guidePopupStoreService, injector) {
        this.popupService = popupService;
        this.guidePopupStoreService = guidePopupStoreService;
        this.injector = injector;
    }
    GuidePopupService.prototype.getComponent = function () {
        return this.guidePopupStoreService.component$.pipe(filter(function (item) { return item != undefined; }), take(1));
    };
    GuidePopupService.prototype.createGuidePopupIfNeeded = function () {
        if (this.guidePopupStoreService.component) {
            return;
        }
        this.popupService.push({
            component: GuidePopupComponent,
            providers: [
                {
                    provide: GuidePopupService,
                    useValue: this
                }
            ],
            injector: this.injector
        });
    };
    GuidePopupService.prototype.openTask = function (task) {
        this.createGuidePopupIfNeeded();
        this.getComponent().subscribe(function (component) {
            component.openTask(task);
        });
    };
    GuidePopupService.prototype.openDocumentation = function (path, options) {
        if (options === void 0) { options = {}; }
        var componentOptions = {
            left: options.left
        };
        this.createGuidePopupIfNeeded();
        this.getComponent().subscribe(function (component) {
            component.setOptions(componentOptions);
            component.openDocumentation(path);
        });
    };
    GuidePopupService.prototype.close = function () {
        if (!this.guidePopupStoreService.component) {
            return;
        }
        this.guidePopupStoreService.component.close();
    };
    GuidePopupService.ngInjectableDef = i0.defineInjectable({ factory: function GuidePopupService_Factory() { return new GuidePopupService(i0.inject(i1.PopupService), i0.inject(i2.GuidePopupStoreService), i0.inject(i0.INJECTOR)); }, token: GuidePopupService, providedIn: "root" });
    return GuidePopupService;
}());
export { GuidePopupService };

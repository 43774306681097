var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { of, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { processElementItemPageSegues, processElementItemResources, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { MenuBlock, MenuBlockLayout, MenuGeneratorService, MenuItemAction, MenuItemActionType, MenuSettings, MenuSettingsService, MenuSettingsStore, SectionMenuItem, SimpleMenuItem } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItemCompatible } from '@modules/projects';
import { ResourceChoosePagesController } from '@modules/projects-components';
import { PageTemplatesController } from '@modules/template-queries';
import { AppError, generateUUID, interpolateSimple, mapValuesDeep } from '@shared';
import { SelectResourcesController } from '../select-resources-controller/select-resources.controller';
var TemplateApplyService = /** @class */ (function () {
    function TemplateApplyService(modelDescriptionStore, menuGeneratorService, menuSettingsService, viewSettingsService, viewSettingsStore, menuSettingsStore, dialogService, selectResourcesController, resourceChoosePagesController, pageTemplatesController, currentProjectStore, currentEnvironmentStore, analyticsService) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.menuGeneratorService = menuGeneratorService;
        this.menuSettingsService = menuSettingsService;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.dialogService = dialogService;
        this.selectResourcesController = selectResourcesController;
        this.resourceChoosePagesController = resourceChoosePagesController;
        this.pageTemplatesController = pageTemplatesController;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.analyticsService = analyticsService;
    }
    TemplateApplyService.prototype.applyPage = function (project, environment, template, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var templateInstanceId = generateUUID();
        var usedPages = cloneDeep(template.usedPages);
        var menuItems = cloneDeep(template.menuItems);
        var firstMenuItem;
        var pages = template.pages.map(function (templatePage) {
            var page = cloneDeep(templatePage);
            if (page.uniqueName) {
                var match = page.uniqueName.match(/(.+)_(\d+)$/);
                var originalName_1 = page.uniqueName;
                var uniqueName_1 = originalName_1;
                var prefix = originalName_1;
                var counter = 1;
                if (match) {
                    prefix = match[1];
                    counter = parseInt(match[2], 10);
                }
                var viewSettings = _this.viewSettingsStore.instance || [];
                while (viewSettings.find(function (item) { return item['unique_name'] == uniqueName_1; })) {
                    ++counter;
                    uniqueName_1 = [prefix, counter].join('_');
                }
                page.uniqueName = uniqueName_1;
                usedPages.forEach(function (item) {
                    if (item.uniqueName == originalName_1) {
                        item.uniqueName = page.uniqueName;
                    }
                });
                var processMenuItems_1 = function (items) {
                    items.forEach(function (item) {
                        if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
                            if (item.action.pageUniqueName == originalName_1) {
                                item.action.pageUniqueName = page.uniqueName;
                            }
                            if (!firstMenuItem) {
                                firstMenuItem = item;
                            }
                        }
                        else if (item instanceof SectionMenuItem) {
                            processMenuItems_1(item.children);
                        }
                    });
                };
                processMenuItems_1(menuItems);
            }
            return page;
        });
        var processPageSegue = function (pageName) {
            var usedPage = usedPages.find(function (item) { return item.token == pageName; });
            if (!usedPage) {
                return pageName;
            }
            return usedPage.uniqueName;
        };
        processElementItemPageSegues(pages, processPageSegue);
        var loadingDialog;
        var result = {
            viewSettings: undefined,
            createdResources: undefined
        };
        return this.selectResourcesController
            .selectTemplateResourcesIfNeeded(project, environment, template, {
            useDemoResources: options.useDemoResources,
            analyticsSource: options.analyticsSource
        })
            .pipe(tap(function (selectResult) {
            if (selectResult.cancelled) {
                throw new AppError('Resource select canceled');
            }
            loadingDialog = _this.dialogService.loading({
                title: 'Applying template',
                description: 'We are preparing template for you, please, wait...'
            });
        }), switchMap(function (selectResult) {
            var resourcesWithOptions = template.usedResources.filter(function (item) { return item.options; });
            if (!resourcesWithOptions.length) {
                return of(selectResult.resources);
            }
            return _this.selectResourcesController
                .createTemplateResources(project, environment, template, options.useExistingUsedResource)
                .pipe(map(function (usedResources) {
                result.createdResources = selectResult.resources.filter(function (item) { return item.created; }).map(function (item) { return item.resource; }).concat(usedResources.map(function (item) { return item.resource; }));
                return selectResult.resources.concat(usedResources);
            }));
        }), tap(function (usedResources) {
            var pageResources = {};
            var processResource = function (resourceName) {
                var replace = usedResources.find(function (item) { return item.token == resourceName; });
                if (!replace) {
                    return resourceName;
                }
                pageResources[replace.resource.uniqueName] = replace;
                return replace.resource.uniqueName;
            };
            pages.map(function (page) {
                pageResources = [];
                processElementItemResources(page, processResource);
                page.sourceTemplate = template.id;
                page.templateInstanceId = templateInstanceId;
                page.usedResources = values(pageResources).map(function (item) {
                    return {
                        type: item.type,
                        typeItem: item.typeItem,
                        name: item.resource.uniqueName
                    };
                });
            });
        }), switchMap(function () {
            return _this.viewSettingsService.createBulk(project.uniqueName, environment.uniqueName, pages);
        }), tap(function (viewSettings) {
            result.viewSettings = viewSettings;
        }), 
        // map(result => {
        //   return result.find(item => firstMenuItem && item.uniqueName == firstMenuItem.uniqueName);
        // }),
        delayWhen(function () {
            // this.viewSettingsStore.projectName = project.uniqueName;
            return _this.viewSettingsStore.getFirst(true);
        }), delayWhen(function () {
            // if (!value['add_to_menu']) {
            //   return of(undefined);
            // }
            var processMenuItems = function (items) {
                items.forEach(function (item) {
                    if (item instanceof SimpleMenuItem && item.action && item.action.type == MenuItemActionType.Page) {
                        var viewSettings = result.viewSettings.find(function (i) { return i.uniqueName == item.action.pageUniqueName; });
                        if (viewSettings) {
                            item.action.pageUid = viewSettings.uid;
                        }
                    }
                    else if (item instanceof SectionMenuItem) {
                        processMenuItems(item.children);
                    }
                });
            };
            processMenuItems(menuItems);
            return _this.menuSettingsStore.addItems(menuItems);
        }), map(function () {
            return result;
        }), tap(function () {
            if (loadingDialog) {
                loadingDialog.close();
            }
        }), catchError(function (error) {
            if (loadingDialog) {
                loadingDialog.close();
            }
            return throwError(error);
        }));
    };
    TemplateApplyService.prototype.getAdminPanelPagesAuto = function (project, resource, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.modelDescriptionStore.getFirst().pipe(switchMap(function (allModelDescriptions) {
            var modelDescriptions = allModelDescriptions
                .filter(function (item) { return item.resource == resource.uniqueName; })
                .filter(function (item) { return item.getQuery; });
            if (!modelDescriptions.length) {
                return of({ startPage: undefined });
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ChooseAdminPanelTemplateOpened, {
                Source: options.analyticsSource,
                ResourceTypeID: resource.typeItem.name,
                ResourceID: resource.uniqueName
            });
            return _this.pageTemplatesController.open({
                resource: resource,
                cancelEnabled: options.optional,
                newPage: true,
                analyticsSource: options.analyticsSource
            });
        }), map(function (result) {
            return {
                pages: result.startPage ? [result.startPage] : undefined,
                startPage: result.startPage,
                newPage: result.newPage,
                cancelled: result.cancelled
            };
        }));
    };
    TemplateApplyService.prototype.getAdminPanelCustomPages = function (project, resource, pages, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.modelDescriptionStore.getFirst().pipe(switchMap(function (allModelDescriptions) {
            var modelDescriptions = allModelDescriptions.filter(function (item) { return item.resource == resource.uniqueName; });
            var choosePages = pages.map(function (item) {
                var modelDescription = modelDescriptions.find(function (i) { return i.resource == resource.uniqueName && i.model == item.model; });
                var name = [modelDescription ? modelDescription.verboseNamePlural : item.model, item.view].join(' - ');
                return {
                    uniqueName: "page_" + item.uniqueName,
                    name: name,
                    data: {
                        page: item
                    }
                };
            });
            return _this.resourceChoosePagesController.choosePages(resource, choosePages, options);
        }), map(function (result) {
            if (result.cancelled) {
                return { cancelled: true };
            }
            var tokens = {
                project: project.uniqueName,
                resource: resource.uniqueName,
                resource_name: resource.name
            };
            var resultPages = result.pages
                .map(function (item) { return item.data.page; })
                .map(function (item) { return item.serialize(); })
                .map(function (item) { return mapValuesDeep(item, function (value) { return interpolateSimple(value, tokens); }); })
                .map(function (item) { return new ViewSettings().deserialize(item); });
            return { pages: resultPages };
        }));
    };
    TemplateApplyService.prototype.applyAdminPanelTemplate = function (project, environment, typeItem, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var cancelled = new AppError();
        // const resourcePagesTemplates = resourceTemplates.find(
        //   item => item.name == resource.typeItem.name && item.viewSettings != undefined
        // );
        // const resourcePages = resourcePagesTemplates
        //   ? resourcePagesTemplates.viewSettings.map(item => new ViewSettings().deserialize(item))
        //   : [];
        //
        // let obs: Observable<{ pages?: ViewSettings[]; cancelled?: boolean }>;
        //
        // if (resourcePages.length) {
        //   obs = this.getAdminPanelCustomPages(project, resource, resourcePages, options);
        // } else {
        //   obs = this.getAdminPanelPagesAuto(project, resource, options);
        // }
        var usedResource;
        var resourceObs;
        if (options.resource) {
            resourceObs = of(options.resource);
        }
        else {
            resourceObs = this.selectResourcesController
                .selectResourcesIfNeeded(project, environment, [{ type: typeItem.resourceType, typeItem: typeItem, token: 'resource' }], { analyticsSource: options.analyticsSource })
                .pipe(map(function (result) {
                if (result.cancelled) {
                    throw cancelled;
                }
                return result.resources[0].resource;
            }));
        }
        return resourceObs.pipe(switchMap(function (resource) {
            usedResource = resource;
            if (usedResource.isSynced() && !usedResource.isSyncedFinished()) {
                return of({ resource: usedResource });
            }
            else if (usedResource.typeItem.installConfigurationNeeded) {
                return of({ resource: usedResource });
            }
            else {
                return _this.getAdminPanelPagesAuto(project, resource, __assign({}, options, { modelPages: true })).pipe(map(function (result) {
                    if (result.cancelled) {
                        throw cancelled;
                    }
                    return {
                        viewSettings: result.pages,
                        startPage: result.startPage,
                        newPage: result.newPage,
                        resource: usedResource
                    };
                }));
            }
        }), catchError(function (error) {
            if (error === cancelled) {
                return of({ cancelled: true });
            }
            else {
                return throwError(error);
            }
        }));
    };
    TemplateApplyService.prototype.createMenuSettings = function (project, resource, viewSettings) {
        var menuItems = viewSettings
            .filter(function (item) { return item.uniqueName; })
            .map(function (item) {
            var menuItem = new SimpleMenuItem();
            menuItem.generateId();
            menuItem.title = item.name;
            menuItem.action = new MenuItemAction();
            menuItem.action.type = MenuItemActionType.Page;
            menuItem.action.pageUid = item.uid;
            menuItem.action.pageUniqueName = item.uniqueName;
            return menuItem;
        }).slice();
        if (!menuItems.length) {
            return;
        }
        var menuSettings = new MenuSettings();
        var section = new SectionMenuItem();
        section.generateId();
        section.title = resource.name;
        section.children = menuItems;
        var block = new MenuBlock();
        block.generateUid();
        block.layout = MenuBlockLayout.LeftWide;
        block.startItems = [section];
        menuSettings.project = project.uniqueName;
        menuSettings.blocks = [block];
        return menuSettings;
    };
    TemplateApplyService.prototype.applyWidget = function (project, environment, template, resource, options) {
        if (options === void 0) { options = {}; }
        var element = cloneDeep(template.element);
        var templateSingleResource = template.usedResources.length == 1 ? template.usedResources[0] : undefined;
        var singleDemoResource = templateSingleResource
            ? this.currentEnvironmentStore.resources.find(function (item) {
                return isResourceTypeItemCompatible(item.typeItem, templateSingleResource.typeItem);
            })
            : undefined;
        var obs;
        if (templateSingleResource && resource) {
            obs = of([{ token: templateSingleResource.token, resource: resource }]);
        }
        else if (templateSingleResource && singleDemoResource) {
            obs = of([{ token: templateSingleResource.token, resource: singleDemoResource }]);
        }
        else {
            obs = this.selectResourcesController
                .selectTemplateResourcesIfNeeded(project, environment, template, {
                useDemoResources: options.useDemoResources,
                analyticsSource: 'builder_component_templates'
            })
                .pipe(filter(function (result) { return !result.cancelled; }), map(function (result) { return result.resources; }));
        }
        return obs.pipe(map(function (selected) {
            var processResource = function (resourceName) {
                var replace = selected.find(function (item) { return item.token == resourceName; });
                if (!replace) {
                    return resourceName;
                }
                return replace.resource.uniqueName;
            };
            processElementItemResources(element, processResource);
            return element;
        }));
    };
    return TemplateApplyService;
}());
export { TemplateApplyService };

import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import isArray from 'lodash/isArray';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { AnalyticsEventType } from '../../data/event';
import { UniversalAnalyticsService } from '../../services/universal-analytics/universal-analytics.service';

@Directive({
  selector: '[appClickEvent]'
})
export class ClickEventDirective implements OnInit, OnDestroy {
  @Input() appClickEvent: AnalyticsEventType | [AnalyticsEventType, Object];

  constructor(private el: ElementRef, private analyticsService: UniversalAnalyticsService) {}

  ngOnInit(): void {
    if (!this.appClickEvent) {
      return;
    }

    fromEvent(this.el.nativeElement, 'click', { passive: true })
      // .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params = isArray(this.appClickEvent) ? this.appClickEvent : [this.appClickEvent];
        this.analyticsService.sendSimpleEvent(params[0], params[1]);
      });
  }

  ngOnDestroy(): void {}
}

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="previewVisible$ | async"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
>
  <app-preview-tip [url]="value" [width]="imageWidth" [height]="imageHeight"></app-preview-tip>
</ng-template>

<span
  class="field__preview field__preview_image field__preview_fill"
  [class.field__preview_cover]="previewBackgroundImage && fit != imageFits.Fit"
  [class.field__preview_contain]="previewBackgroundImage && fit == imageFits.Fit"
  [class.field__preview_zoom]="url && lightBox"
  [class.field__preview_no-background]="noBackground"
  [class.field__preview_background]="background"
  [class.field__preview_compact]="compact"
  [ngClass]="['field__preview_type_' + type]"
  [style.background-image]="previewBackgroundImage"
  [style.opacity]="(previewVisible$ | async) ? 0.25 : 1"
  (mouseenter)="showPreview()"
  (mouseleave)="hidePreview()"
  (click)="openLightbox($event)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  #preview_img
>
  <!--  <a-->
  <!--    *ngIf="lightBox && !preview"-->
  <!--    href="javascript:void(0)"-->
  <!--    class="field__zoom"-->
  <!--    (click)="$event.stopPropagation(); openLightbox()"-->
  <!--  ></a>-->
</span>

<ng-container *ngIf="environment && project.hasEnvironmentCustomizationPermission(environment)">
  <a
    *ngIf="
      project.getHomeLinkWithProtocol({
        mode: adminModes.Builder,
        environmentName: environment ? environment.uniqueName : undefined
      }).link as link
    "
    [routerLink]="link"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
  >
    <span class="choose-items-item__dropdown-icon icon-design"></span>
    {{ 'Open builder' | localize }}
  </a>

  <a
    *ngIf="
      project.getHomeLinkWithProtocol({
        mode: adminModes.Builder,
        environmentName: environment ? environment.uniqueName : undefined
      }).href as href
    "
    [href]="getExternalHref(href)"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
  >
    <span class="choose-items-item__dropdown-icon icon-design"></span>
    {{ 'Open builder' | localize }}
  </a>
</ng-container>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.App,
      environmentName: environment ? environment.uniqueName : undefined
    }).link as link
  "
  [routerLink]="link"
  mat-menu-item
  class="mat-menu-item-odd"
  [disableRipple]="true"
>
  <span class="choose-items-item__dropdown-icon icon-earth_planet"></span>
  {{ 'View published' | localize }}
</a>

<a
  *ngIf="
    project.getHomeLinkWithProtocol({
      mode: adminModes.App,
      environmentName: environment ? environment.uniqueName : undefined
    }).href as href
  "
  [href]="getExternalHref(href)"
  mat-menu-item
  class="mat-menu-item-odd"
  [disableRipple]="true"
>
  <span class="choose-items-item__dropdown-icon icon-earth_planet"></span>
  {{ 'View published' | localize }}
</a>

<button
  *ngIf="project.defaultEnvironment && project.hasEnvironmentSettingsPermission(project.defaultEnvironment)"
  mat-menu-item
  class="mat-menu-item-odd mat-menu-item-red"
  [disableRipple]="true"
  (click)="deleteProject()"
>
  <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
  {{ 'Delete App' | localize }}
</button>

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { delayWhen, switchMap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';

import { ActionCreatePopupComponent } from '../../action-create-popup/action-create-popup.component';
import { ActionCreateOptions } from '../../action-create-popup/action-create-popup.form';
import { ActionEditPopupComponent } from '../../action-edit-popup/action-edit-popup.component';

@Injectable()
export class ActionEditController {
  constructor(
    private popupService: PopupService,
    private dialogService: DialogService,
    private actionStore: ActionStore,
    private actionDescriptionService: ActionDescriptionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  createAction(resource: Resource, options: ActionCreateOptions = {}): Observable<ActionDescription> {
    const result = new Subject<ActionDescription>();

    this.popupService.push({
      component: ActionCreatePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        resource: resource,
        options: options
      },
      outputs: {
        created: [
          (action: ActionDescription) => {
            result.next(action);
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }

  editAction(action: ActionDescription) {
    this.popupService.push({
      component: ActionEditPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        action: action
      },
      resolver: this.resolver,
      injector: this.injector
    });
  }

  deleteAction(action: ActionDescription): Observable<boolean> {
    const name = action.verboseName;

    return this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete Action <strong>${name}</strong>?`
      })
      .pipe(
        switchMap(result => {
          if (!result) {
            return of(false);
          }

          const projectName = this.currentProjectStore.instance.uniqueName;
          const environmentName = this.currentEnvironmentStore.instance.uniqueName;

          return this.actionDescriptionService
            .delete(projectName, environmentName, action)
            .pipe(delayWhen(() => this.actionStore.getFirst(true)));
        })
      );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import shuffle from 'lodash/shuffle';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subject } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettings, ViewSettingsType } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { FeatureService } from '@modules/features';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  isResourceTypeItem3rdParty,
  isResourceTypeItemCustom,
  Resource,
  ResourceName,
  resourcesCustom,
  ResourceTypeItem,
  ResourceTypeItemCategory,
  resourceTypeItems
} from '@modules/projects';
import { ResourceEditController, ResourceFilter, ResourceSummaryService } from '@modules/projects-components';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { RoutingService } from '@modules/routing';
import { TemplateService, TemplateType } from '@modules/template';
import { CurrentUserStore } from '@modules/users';
import { KeyboardEventKeyCode } from '@shared';

import { adminPanelTemplateItem, TemplateItem, toTemplateItem } from '../../data/template-item';
import { TemplateItemType } from '../../data/template-item-type';
import { TemplateApplyController } from '../../services/template-apply-controller/template-apply.controller';

export enum ChooseTemplateSection {
  Onboarding = 'onboarding',
  Templates = 'templates',
  Resources = 'resources'
}

export const CLOSE_BY_BUTTON_CLICK = 'CLOSE_BY_BUTTON_CLICK';

@Component({
  selector: 'app-choose-template',
  templateUrl: './choose-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseTemplateComponent implements OnInit, OnDestroy {
  @Input() title = 'Create an App';
  @Input() initialSection: ChooseTemplateSection;
  @Input() orange = false;
  @Input() currentPage: ViewSettings;
  @Input() resource: Resource;
  @Input() cancel = false;
  @Input() newPage = false;
  @Input() initialSelected: number;
  @Input() header = true;
  @Input() wide = false;
  @Input() type: TemplateItemType;
  @Input() chooseSection = true;
  @Input() analyticsSource: string;
  @Output() chosenTemplate = new EventEmitter<TemplateItem>();
  @Output() templateApplied = new EventEmitter<TemplateItem>();
  @Output() resourceCreated = new EventEmitter<Resource>();
  @Output() blankAppCreated = new EventEmitter<void>();
  @Output() chosenDemoResources = new EventEmitter<void>();

  loading = false;
  loadingCreateBlankApp = false;

  templateItemTypes = TemplateItemType;
  templates: TemplateItem[] = [];
  adminPanelTemplate: TemplateItem;
  templatesFiltered: TemplateItem[] = [];
  selectedTemplate: TemplateItem;
  templateSearch = '';
  templateSearchUpdated = new Subject<string>();
  tagsMaximum = 4;

  section: ChooseTemplateSection = ChooseTemplateSection.Templates;
  sections = ChooseTemplateSection;
  breadcrumbs: ChooseTemplateSection[] = [];
  customResource = resourceTypeItems.find(item => item.name == ResourceName.JetDatabase);
  resources: ResourceTypeItem[];
  resourcesNotFound: ResourceTypeItem[];
  resourcesFiltered: ResourceTypeItem[];
  selectedResource: ResourceTypeItem;
  resourceFilters: { label: string; filter?: ResourceFilter }[] = [
    {
      label: 'All Integrations'
    },
    {
      label: 'Databases',
      filter: { category: ResourceTypeItemCategory.Databases }
    },
    {
      label: 'APIs',
      filter: { category: ResourceTypeItemCategory.APIs }
    },
    {
      label: 'Frameworks',
      filter: { category: ResourceTypeItemCategory.Frameworks }
    },
    {
      label: 'Storages',
      filter: { category: ResourceTypeItemCategory.Storages }
    }
  ];
  resourceFilterIndex = 0;
  resourceFilterCounts: number[] = [];
  resourceSearch: string;
  resourceSearchUpdated = new Subject<string>();

  analyticsEvents = AnalyticsEvent;

  constructor(
    @Optional() public popupComponent: BasePopupComponent,
    private resourceGeneratorResolver: ResourceGeneratorResolver,
    private injector: Injector,
    public currentUserStore: CurrentUserStore,
    private resourceEditController: ResourceEditController,
    private resourceSummaryService: ResourceSummaryService,
    private cd: ChangeDetectorRef,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private featureService: FeatureService,
    private templateService: TemplateService,
    private templateApplyController: TemplateApplyController,
    private notificationService: NotificationService,
    private routing: RoutingService,
    private viewSettingsQueries: ViewSettingsQueries,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.resources =
      this.selectedTemplate && this.selectedTemplate.selectResources
        ? this.selectedTemplate.selectResources
        : resourceTypeItems.filter(item => !item.hidden && !item.protected && item.name != ResourceName.JetDatabase);
    this.resourcesNotFound = resourceTypeItems.filter(item => resourcesCustom.includes(item.name));
    this.updateResourcesFiltered();

    if (this.initialSection) {
      this.section = this.initialSection;
    }

    this.adminPanelTemplate = {
      ...adminPanelTemplateItem,
      selectResources: resourceTypeItems
        .filter(item => !item.hidden && !item.protected)
        .filter(item => this.resourceGeneratorResolver.get(item.name) || isResourceTypeItemCustom(item))
    };

    this.loading = true;
    this.cd.markForCheck();

    this.templateService
      .get({ type: TemplateType.Page })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          const items = result
            .filter(item => {
              if (!this.resource) {
                return true;
              }

              return item.forResources.find(resource => resource.typeItem.name == this.resource.typeItem.name);
            })
            .map(item => toTemplateItem(item));
          const featuredTemplates = shuffle([adminPanelTemplateItem, ...items.filter(item => item.template.featured)]);
          const otherTemplates = shuffle(items.filter(item => !item.template.featured));

          this.templates = [...featuredTemplates, ...otherTemplates];

          if (this.type == TemplateItemType.AdminPanel) {
            this.selectedTemplate = this.adminPanelTemplate;
            this.chooseAdminPanelTemplate();
          } else if (this.initialSelected != undefined) {
            this.selectedTemplate = this.templates.find(
              item => item.template && item.template.id == this.initialSelected
            );
          } else {
            this.selectedTemplate = this.templates[0];
          }

          this.loading = false;
          this.cd.markForCheck();

          this.updateTemplatesFiltered();
          this.updateResourcesFiltered();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.templateSearchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateTemplatesFiltered());

    this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateResourcesFiltered());
  }

  ngOnDestroy(): void {}

  updateTemplatesFiltered() {
    this.templatesFiltered = this.templates
      ? this.templates.filter(item => {
          if (!this.templateSearch) {
            return true;
          }

          return item.name.toLowerCase().includes(this.templateSearch.toLowerCase());
        })
      : undefined;
    this.cd.markForCheck();
  }

  updateResourcesFiltered() {
    const filterResources = (resources: ResourceTypeItem[], filter?: ResourceFilter) => {
      return resources.filter(item => {
        if (filter) {
          if (filter.category != undefined) {
            if (!item.categories.includes(filter.category)) {
              return false;
            }
          }
        }

        if (this.resourceSearch) {
          if (item.label.toLowerCase().includes(this.resourceSearch.toLowerCase()) == false) {
            return false;
          }
        }

        return true;
      });
    };

    this.resourceFilterCounts = this.resourceFilters.map(item => filterResources(this.resources, item.filter).length);

    if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
      this.resourceFilterIndex = 0;
    }

    const currentFilter = this.currentResourceFilter;

    this.resourcesFiltered = filterResources(this.resources, currentFilter);
    this.cd.markForCheck();
  }

  get currentResourceFilter(): ResourceFilter {
    if (!this.resourceFilters[this.resourceFilterIndex]) {
      return;
    }
    return this.resourceFilters[this.resourceFilterIndex].filter;
  }

  clearSearch() {
    this.resourceSearch = '';
    this.resourceSearchUpdated.next();
    this.cd.markForCheck();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }

  setSelectedTemplate(template: TemplateItem) {
    this.selectedTemplate = template;
    this.cd.markForCheck();

    if (template.type != TemplateItemType.AdminPanel) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
        TemplateID: template.name
      });
    }
  }

  setSelectedResource(resource: ResourceTypeItem) {
    if (
      isResourceTypeItem3rdParty(resource) &&
      !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Business Apps</strong>',
        description: `
          Connect data from various business apps. You can see all your data and take action in one place.
        `
      });
      return;
    } else if (
      isResourceTypeItemCustom(resource) &&
      !this.currentProjectStore.instance.features.isCustomResourcesEnabled()
    ) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Custom Queries</strong>',
        description: `
          Connect data from custom queries. You can see all your data and take action in one place.
        `
      });
      return;
    }

    this.selectedResource = resource;
    this.cd.markForCheck();
  }

  setResourceFilterIndex(i: number) {
    this.resourceFilterIndex = i;
    this.cd.markForCheck();
    this.updateResourcesFiltered();
  }

  chooseTemplate(template?: TemplateItem) {
    this.selectedTemplate = template ? template : this.templatesFiltered[0];
    this.navigate(ChooseTemplateSection.Templates);
    this.cd.markForCheck();

    if (this.selectedTemplate) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
        TemplateID: this.selectedTemplate.name
      });
    }
  }

  navigate(section: ChooseTemplateSection) {
    this.breadcrumbs = [...this.breadcrumbs, this.section];
    this.section = section;
    this.cd.markForCheck();
  }

  navigateToProjects() {
    this.routing.navigate(['/projects']);
  }

  navigateBack() {
    this.section = this.previousSection;
    this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
    this.cd.markForCheck();
  }

  get previousSection() {
    return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  isVideoUrl(url: string) {
    return ['mp4', 'webm', 'ogg'].some(item => url.toLowerCase().endsWith(`.${item}`));
  }

  close(data?: any) {
    if (this.popupComponent) {
      this.popupComponent.close(data);
    }
  }

  onCloseClick() {
    this.close(CLOSE_BY_BUTTON_CLICK);
  }

  createNewPage(): Observable<ViewSettings> {
    if (!this.newPage) {
      return of(undefined);
    }

    return this.viewSettingsQueries.createPage({
      project: this.currentProjectStore.instance,
      environment: this.currentEnvironmentStore.instance,
      view: ViewSettingsType.Custom,
      // uniqueName: 'new-page',
      name: 'New Page',
      addToMenu: true
    });
  }

  chooseAdminPanelTemplate(source?: string) {
    // this.selectedTemplate = this.adminPanelTemplate;

    if (this.resource) {
      this.applyTemplate({
        ...this.adminPanelTemplate,
        resource: this.resource.typeItem
      });
    } else {
      this.navigate(ChooseTemplateSection.Resources);
      this.setResourceFilterIndex(undefined);
      this.updateResourcesFiltered();
      // this.selectedResource = this.resourcesFiltered[0];
      this.selectedResource = undefined;
      this.cd.markForCheck();
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
      TemplateID: this.selectedTemplate.name,
      Source: source
    });
  }

  createBlankApp() {
    this.loadingCreateBlankApp = true;
    this.cd.markForCheck();

    this.createNewPage()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.close();
          this.blankAppCreated.emit();
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.BlankApp);
        },
        () => {
          this.loadingCreateBlankApp = false;
          this.cd.markForCheck();
        }
      );
  }

  applyTemplate(template: TemplateItem) {
    this.templateApplyController
      .applyTemplateFromPage(template, this.injector, this.currentPage, {
        resource: this.resource,
        useDemoResources: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(link => {
        this.close();

        this.templateApplied.emit(template);

        this.routing.navigateApp(link);

        // this.notificationService.success(
        //   'Template applied',
        //   `Template <strong>${template.name}</strong> was successfully applied`
        // );

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Applied, {
          TemplateID: template.name,
          ResourceType: template.resource ? template.resource.name : undefined
        });
      });
  }

  applyAdminPanelTemplate(resource: ResourceTypeItem) {
    if (!resource) {
      return;
    }

    this.applyTemplate({
      ...this.adminPanelTemplate,
      resource: resource
    });
  }

  // useDemoResources() {
  //   this.loadingCreateBlankApp = true;
  //   this.cd.markForCheck();
  //
  //   this.createNewPage()
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       () => {
  //         this.close();
  //         this.chosenDemoResources.emit();
  //         this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.DemoResources);
  //       },
  //       () => {
  //         this.loadingCreateBlankApp = false;
  //         this.cd.markForCheck();
  //       }
  //     );
  // }

  // chooseResource() {
  //   if (!this.selectedResource) {
  //     return;
  //   }
  //
  //   this.loadingCreateBlankApp = true;
  //   this.cd.markForCheck();
  //
  //   this.createNewPage()
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       () => {
  //         // this.close();
  //         this.onResourceChosen(this.selectedResource);
  //
  //         this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ResourceCreate, {
  //           ResourceID: this.selectedResource.name
  //         });
  //       },
  //       () => {
  //         this.loadingCreateBlankApp = false;
  //         this.cd.markForCheck();
  //       }
  //     );
  // }

  // onResourceChosen(resource: ResourceTypeItem) {
  //   this.resourceEditController
  //     .createResource(resource, {
  //       resourceNameEditing: true,
  //       importPages: false,
  //       createAnalyticsSource: 'data'
  //     })
  //     .pipe(untilDestroyed(this))
  //     .subscribe(result => {
  //       if (result.resource) {
  //         this.close();
  //         this.resourceSummaryService.open(result.resource).subscribe(() => {
  //           this.resourceCreated.emit(result.resource);
  //         });
  //       } else if (result.cancelled) {
  //         this.loadingCreateBlankApp = false;
  //         this.cd.markForCheck();
  //       }
  //     });
  // }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { createFormFieldFactory } from '@modules/fields';
import { setControlEnabled } from '@shared';

import { CustomizeBarCustomCodeEditForm } from '../customize-bar-custom-code-edit/customize-bar-custom-code-edit.form';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';

@Component({
  selector: 'app-customize-bar-sign-up-custom-code-edit',
  templateUrl: './customize-bar-sign-up-custom-code-edit.component.html',
  providers: [CustomizeBarCustomCodeEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarSignUpCustomCodeEditComponent implements OnInit, OnDestroy {
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;

  createField = createFormFieldFactory();
  loading = false;
  domain: Domain;

  constructor(
    public customCodeForm: CustomizeBarCustomCodeEditForm,
    private customizeBarContext: CustomizeBarContext,
    private projectDomainController: ProjectDomainController,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {}

  init() {
    this.customCodeForm.init(
      this.projectDomainUpdateForm.controls.append_scripts.value,
      this.projectDomainUpdateForm.controls.append_styles.value
    );

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.cd.markForCheck();

        setControlEnabled(this.customCodeForm.controls.append_scripts, !!domain);
        setControlEnabled(this.customCodeForm.controls.append_styles, !!domain);
      });
  }

  submit() {
    this.projectDomainUpdateForm.controls.append_scripts.patchValue(this.customCodeForm.controls.append_scripts.value, {
      emitEvent: false
    });
    this.projectDomainUpdateForm.controls.append_styles.patchValue(this.customCodeForm.controls.append_styles.value, {
      emitEvent: false
    });

    this.loading = true;
    this.cd.markForCheck();

    this.projectDomainUpdateForm
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();

          this.init();
          this.onSave();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_sign_in' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onDomainRequiredClick() {
    if (!this.domain) {
      this.editDomain();
    }
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'signup_settings'
    });
  }
}

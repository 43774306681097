import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ChangeViewSettings, ViewSettings } from '@modules/customize';
import { ParameterArray } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { splitmax } from '@shared';

import { CustomizeBarModelDataArray } from './customize-bar-model-data.array';

@Injectable()
export class CustomizeBarPageEditForm extends FormGroup {
  viewSettings: ViewSettings;
  changeViewSettings = false;

  controls: {
    name: FormControl;
    model: FormControl;
    parameters: ParameterArray;
    queries: CustomizeBarModelDataArray;
  };

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService,
    customizeBarModelDataArray: CustomizeBarModelDataArray
  ) {
    super({
      name: new FormControl(''),
      model: new FormControl(''),
      parameters: new ParameterArray([]),
      queries: customizeBarModelDataArray
    });
  }

  init(viewSettings: ViewSettings) {
    this.viewSettings = viewSettings;
    this.changeViewSettings = viewSettings instanceof ChangeViewSettings;

    if (viewSettings) {
      const value = {
        name: viewSettings.name,
        model: undefined,
        parameters: viewSettings.parameters
      };

      if (viewSettings instanceof ChangeViewSettings) {
        if (viewSettings.getResource && viewSettings.getQuery && viewSettings.getQuery.simpleQuery) {
          value['model'] = { model: [viewSettings.getResource, viewSettings.getQuery.simpleQuery.model].join('.') };
        }
      }

      this.patchValue(value, { emitEvent: false });
      this.controls.queries.deserialize(viewSettings.queries);
    }
  }

  submit(): ViewSettings {
    const value = this.value;
    const instance: ViewSettings = this.viewSettings ? cloneDeep(this.viewSettings) : new ViewSettings();

    // instance.name = value['name'];
    instance.parameters = value['parameters'];
    instance.queries = this.controls.queries.serialize();

    if (instance instanceof ChangeViewSettings) {
      if (value['model']) {
        const [resource, model] = splitmax(value['model']['model'], '.', 2);

        instance.getResource = resource;

        if (!instance.getQuery) {
          instance.getQuery = new ModelDescriptionQuery();
        }

        if (!instance.getQuery.simpleQuery) {
          instance.getQuery.simpleQuery = new instance.getQuery.simpleQueryClass();
        }

        instance.getQuery.simpleQuery.model = model;
      }
    }

    return instance;
  }
}

<div class="token-popup-search">
  <input
    type="text"
    class="token-popup-search__input"
    [formControl]="searchControl"
    [appAutofocus]="true"
    placeholder="Search..."
  />
  <span
    *ngIf="searchControl.value | appIsSet"
    class="token-popup-search__close icon-close"
    (click)="clearSearch()"
    [appTip]="'Clear'"
    [appTipOptions]="{ side: 'top' }"
  ></span>
</div>

<div *ngFor="let section of sectionsFiltered" class="token-popup__item">
  <div class="token-popup-section">
    <div *ngIf="section.label | appIsSet" class="token-popup-section__title">
      <span
        *ngIf="section.icon"
        class="token-popup-section__title-icon token-popup-section__title-icon_left icon-help"
        [ngClass]="'icon-' + section.icon"
      ></span>

      <div class="token-popup-section__title-main">{{ section.label }}</div>
    </div>
    <div class="token-popup-section__content">
      <a
        *ngFor="let item of section.children"
        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
        [class.token-popup-list-item_active]="currentValue === item.value"
        href="javascript:void(0)"
        (click)="select.emit(item.value)"
      >
        <div class="token-popup-list-item__inner">
          <div class="token-popup-list-item__line">
            <span *ngIf="item.icon" class="token-popup-list-item__left">
              <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
            </span>

            <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
              {{ item.name | appCapitalize }}
            </span>
          </div>
          <div
            *ngIf="item.description"
            class="token-popup-list-item__line"
            [class.token-popup-list-item__line_active]="false"
          >
            <div class="token-popup-list-item__description">
              {{ item.description | appCapitalize }}
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>

<div *ngIf="!sectionsFiltered.length" class="token-popup-list-item">
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__tip">Nothing found</div>
  </div>
</div>

<div
  class="sidebar-list-item sidebar-list-item_clickable"
  [class.sidebar-list-item_disabled]="disabled"
  [class.sidebar-list-item_draggable]="draggable"
  [class.sidebar-list-item_error]="error"
  [ngClass]="classes"
  [appTip]="error"
  (click)="open.emit(action)"
>
  <div *ngIf="draggable" class="sidebar-list-item__drag"></div>
  <div *ngIf="hasIcon" class="sidebar-list-item__left">
    <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
      <span *ngIf="displayIcon" class="icon button__icon" [appIcon]="displayIcon"></span>
    </span>
  </div>
  <div *ngIf="loading" class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      <span [class.loading-animation]="true">
        <span class="stub-text">Action</span>
      </span>
    </div>
    <div class="sidebar-list-item__description">
      <span [class.loading-animation]="true">
        <span class="stub-text">Action Type</span>
      </span>
    </div>
  </div>
  <div *ngIf="!loading" class="sidebar-list-item__main">
    <div *ngIf="titles[0]" class="sidebar-list-item__title">
      <span *ngIf="actionDescriptionResource?.demo" class="sidebar-list-item__tag">DEMO</span>
      {{ titles[0] | appCapitalize }}
    </div>
    <div *ngIf="titles[1] && titles[1] != titles[0]" class="sidebar-list-item__description">
      {{ titles[1] | appCapitalize }}
    </div>
  </div>
  <div class="sidebar-list-item__right">
    <a
      *ngIf="execute"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-play"
      [appTip]="'Run action'"
      (click)="$event.stopPropagation(); executeClick.emit()"
    >
    </a>
    <a
      *ngIf="pin"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-pin_2"
      [class.sidebar-list-item__action_active]="pinned"
      [appTip]="'Pinned'"
      (click)="$event.stopPropagation(); pinClick.emit()"
    >
    </a>
    <a
      *ngIf="remove"
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-bin"
      (click)="$event.stopPropagation(); removeClick.emit()"
    >
    </a>
    <ng-container *ngIf="!pin && !remove">
      <span *ngIf="error" class="sidebar-list-item__action sidebar-list-item__action_error icon-warning_filled"></span>
      <span *ngIf="!error" class="sidebar-list-item__action icon-arrow_forward_2"></span>
    </ng-container>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { Option } from '@modules/field-components';
import { ProjectTokenService, ResourceName, ResourceType } from '@modules/projects';
import { IXanoInstance, IXanoWorkspace, ResourceControllerService, XanoResourceController } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { XanoResourceSettingsForm } from './xano-resource-settings.form';

@Component({
  selector: 'app-xano-resource-settings',
  templateUrl: './xano-resource-settings.component.html',
  providers: [XanoResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XanoResourceSettingsComponent extends BaseResourceSettingsComponent {
  controller: XanoResourceController;
  loadingDomains = false;
  domainOptions: Option[] = [];
  loadingWorkspaces = false;
  workspaceOptions: Option[] = [];

  constructor(
    public form: XanoResourceSettingsForm,
    private chooseSyncController: ChooseSyncController,
    private resourceControllerService: ResourceControllerService,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
    this.controller = this.resourceControllerService.get<XanoResourceController>(ResourceType.Xano);
  }

  onFormInit() {
    super.onFormInit();

    controlValue<string>(this.form.form.controls['access_token'])
      .pipe(
        switchMap(accessToken => {
          if (!isSet(accessToken)) {
            return of<IXanoInstance[]>([]);
          }

          this.loadingDomains = true;
          this.cd.markForCheck();

          return this.controller.getInstances(accessToken).pipe(catchError(() => of<IXanoInstance[]>([])));
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.domainOptions = result.map(item => {
            return {
              value: item.xano_domain,
              name: item.xano_domain,
              subtitle: item.display
            };
          });
          this.loadingDomains = false;
          this.cd.markForCheck();
        },
        () => {
          this.loadingDomains = false;
          this.cd.markForCheck();
        }
      );

    combineLatest(
      controlValue<string>(this.form.form.controls['access_token']),
      controlValue<string>(this.form.form.controls['domain'])
    )
      .pipe(
        switchMap(([accessToken, domain]) => {
          if (!isSet(accessToken) && !isSet(domain)) {
            return of<IXanoWorkspace[]>([]);
          }

          this.loadingWorkspaces = true;
          this.cd.markForCheck();

          return this.controller.getWorkspaces(accessToken, domain).pipe(catchError(() => of<IXanoWorkspace[]>([])));
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.workspaceOptions = result.map(item => {
            return {
              value: item.id,
              name: item.name,
              subtitle: `ID: ${item.id}`
            };
          });
          this.loadingWorkspaces = false;
          this.cd.markForCheck();
        },
        () => {
          this.loadingWorkspaces = false;
          this.cd.markForCheck();
        }
      );

    this.form.form.controls['access_token'].valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.form.form.controls['domain'].patchValue(undefined);
      this.form.form.controls['workspace_id'].patchValue(undefined);
    });
  }

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.Xano, XanoResourceSettingsComponent);

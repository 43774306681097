var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { DomainService } from '@modules/domain';
import { EmailAddressService } from '@modules/emails';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';
var EmailAddressEditForm = /** @class */ (function (_super) {
    __extends(EmailAddressEditForm, _super);
    function EmailAddressEditForm(currentProjectStore, currentEnvironmentStore, formUtils, domainService, emailAddressService) {
        var _this = _super.call(this, {
            email_name: new FormControl(''),
            email_from: new FormControl('', [Validators.required, Validators.email])
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.formUtils = formUtils;
        _this.domainService = domainService;
        _this.emailAddressService = emailAddressService;
        controlValue(_this.controls.email_name).subscribe(function (value) {
            var email = isSet(_this.domain) ? value + "@" + _this.domain.domain : '';
            _this.controls.email_from.patchValue(email);
        });
        return _this;
    }
    EmailAddressEditForm.prototype.init = function (domain) {
        this.domain = domain;
    };
    EmailAddressEditForm.prototype.initiateCustomEmail = function () {
        return this.emailAddressService.initiateCustomEmail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.domain.domain);
    };
    EmailAddressEditForm.prototype.setupCustomEmail = function () {
        var _this = this;
        return this.emailAddressService
            .setupCustomEmail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.domain.domain, this.controls.email_name.value)
            .pipe(map(function () { return true; }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    EmailAddressEditForm.prototype.deleteCustomEmail = function () {
        var _this = this;
        return this.emailAddressService
            .deleteCustomEmail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.domain.domain)
            .pipe(map(function () { return true; }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return EmailAddressEditForm;
}(FormGroup));
export { EmailAddressEditForm };

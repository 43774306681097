import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Environment, Project, Resource, ResourceTypeItem } from '@modules/projects';
import { ResourceParamsResult } from '@modules/resources';

import { slackResourceParamsActionDescriptions } from '../../data/slack/slack-resource-params-action-descriptions.stub';
import { slackResourceParamsParams } from '../../data/slack/slack-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface SlackParamsOptions {
  webhook_url: string;
}

@Injectable()
export class SlackGeneratorService extends ResourceGeneratorService<SlackParamsOptions> {
  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<SlackParamsOptions> {
    return of({
      webhook_url: resource.params['webhook_url']
    });
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: SlackParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = slackResourceParamsParams;
    const actionDescriptions = slackResourceParamsActionDescriptions;
    const extraTokens = {
      webhook_url: options.webhook_url
    };

    resourceParams['webhook_url'] = options.webhook_url;

    return of({
      resourceParams: resourceParams,
      actionDescriptions: actionDescriptions,
      extraTokens: extraTokens
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { TableTokensComponent } from './components/table-tokens/table-tokens.component';
import { TokenPopupListItemComponent } from './components/token-popup-list-item/token-popup-list-item.component';
import { TokenPopupListComponent } from './components/token-popup-list/token-popup-list.component';
import { TokenPopupComponent } from './components/token-popup/token-popup.component';
import { TokenStructureItemComponent } from './components/token-structure-item/token-structure-item.component';
import { TokenStructureSectionComponent } from './components/token-structure-section/token-structure-section.component';

@NgModule({
  imports: [CommonModule, FormsModule, TipsModule, SharedModule, ReactiveFormsModule],
  declarations: [
    TokenPopupComponent,
    TokenPopupListComponent,
    TokenPopupListItemComponent,
    TokenStructureItemComponent,
    TableTokensComponent,
    TokenStructureSectionComponent
  ],
  exports: [TokenPopupComponent, TableTokensComponent, TokenStructureItemComponent, TokenStructureSectionComponent]
})
export class TokensComponentsModule {}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActionItem } from '@modules/actions';
import { ImageOutputFormat, Input } from '@modules/fields';
import { AlignHorizontal } from '../../align-horizontal';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ImageElementItem = /** @class */ (function (_super) {
    __extends(ImageElementItem, _super);
    function ImageElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Image;
        _this.alignHorizontalDefault = AlignHorizontal.Left;
        _this.showPreview = false;
        _this.lightbox = false;
        return _this;
    }
    ImageElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.width = this.params['width'];
        this.height = this.params['height'];
        this.storageResource = this.params['storage_resource'];
        this.storageName = this.params['storage_name'];
        this.displayType = this.params['display_type'];
        this.fit = this.params['fit'];
        this.showPreview = this.params['show_preview'];
        this.lightbox = this.params['lightbox'];
        if (this.params['output_format']) {
            this.outputFormat = this.params['output_format'];
        }
        else if (this.params['storage_resource']) {
            this.outputFormat = ImageOutputFormat.Storage;
        }
        if (this.params['value_input']) {
            this.valueInput = new Input().deserialize(this.params['value_input']);
        }
        else if (this.params['url']) {
            this.valueInput = new Input().deserializeFromStatic('value', this.params['url']);
        }
        else {
            this.valueInput = undefined;
        }
        if (this.params['path']) {
            this.path = new Input().deserialize(this.params['path']);
        }
        else {
            this.path = undefined;
        }
        if (this.params['click_action']) {
            this.clickAction = new ActionItem().deserialize(this.params['click_action']);
        }
        else {
            this.clickAction = undefined;
        }
        return this;
    };
    ImageElementItem.prototype.serialize = function () {
        this.params = {
            width: this.width,
            height: this.height,
            value_input: this.valueInput ? this.valueInput.serialize() : undefined,
            output_format: this.outputFormat,
            storage_resource: this.storageResource,
            storage_name: this.storageName,
            display_type: this.displayType,
            fit: this.fit,
            show_preview: this.showPreview,
            lightbox: this.lightbox,
            click_action: this.clickAction ? this.clickAction.serialize() : undefined
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(ImageElementItem.prototype, "analyticsName", {
        get: function () {
            return 'image';
        },
        enumerable: true,
        configurable: true
    });
    ImageElementItem.prototype.defaultName = function () {
        return 'Image';
    };
    return ImageElementItem;
}(ElementItem));
export { ImageElementItem };
registerElementForType(ElementType.Image, ImageElementItem);

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { ProjectSettings } from '../../data/project-settings';

@Injectable({
  providedIn: 'root'
})
export class ProjectSettingsService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, draft = false): Observable<ProjectSettings[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
        let headers = new HttpHeaders();
        const params = {
          ...(draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: params });
      }),
      map(result => result.map(item => new ProjectSettings().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    instances: ProjectSettings[]
  ): Observable<ProjectSettings[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new ProjectSettings().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: ProjectSettings): Observable<ProjectSettings> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'project_settings/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, instance.serialize(), { headers: headers, params: params });
      }),
      map(result => new ProjectSettings().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { Injectable, Injector } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { LocalStorage } from '@core';
import { Project } from '@modules/projects';
import { OnboardingService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';

import { CompanyInfoComponent } from '../../components/company-info/company-info.component';

@Injectable()
export class CompanyInfoService {
  _visible = new BehaviorSubject<boolean>(false);

  constructor(
    private popupService: PopupService,
    private onboardingService: OnboardingService,
    private localStorage: LocalStorage,
    private currentUserStore: CurrentUserStore,
    private injector: Injector
  ) {}

  isProjectCreateTimeAgo(project: Project) {
    const value = this.localStorage.get(project.dateCreatedKey);

    if (!value) {
      return;
    }

    const minutesPassed = moment().diff(moment(value), 'minutes');

    return minutesPassed >= 10;
  }

  isShown(project: Project): boolean {
    return this.currentUserStore.instance.params.hasProjectCompanyInfoShowed(project.uniqueName);
  }

  set visible(value: boolean) {
    this._visible.next(value);
  }

  get visible(): boolean {
    return this._visible.value;
  }

  get visible$(): Observable<boolean> {
    return this._visible.asObservable();
  }

  showCompanyInfoPopup(project: Project) {
    this.visible = true;
    this.popupService.push({
      component: CompanyInfoComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        project: project
      },
      outputs: {
        closed: [
          () => {
            this.visible = false;
            this.onboardingService.showOnboardingPopupIfNeeded(project);
          }
        ]
      },
      injector: this.injector
    });
  }

  showCompanyInfoPopupIfNeeded(project: Project) {
    return false;

    if (
      this.isProjectCreateTimeAgo(project) !== false &&
      !this.isShown(project) &&
      !this.visible &&
      !project.company &&
      !project.demo
    ) {
      // this.setShown(project);
      this.showCompanyInfoPopup(project);
      return true;
    } else {
      return false;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FieldType, registerFieldComponent, VideoOutputFormat } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { isSet } from '@shared';

import { UploadFieldComponent } from '../file-field/upload-field.component';

@Component({
  selector: 'app-video-field',
  templateUrl: 'video-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoFieldComponent extends UploadFieldComponent implements OnInit, OnDestroy, OnChanges {
  outputFormats = VideoOutputFormat;
  previewFile: string;
  previewEmbed: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    protected modelService: ModelService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected storageService: StorageService,
    protected resourceControllerService: ResourceControllerService,
    protected cd: ChangeDetectorRef
  ) {
    super(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd);
  }

  ngOnInit(): void {
    this.updateStorage();
    this.updateUploaded();
    this.initOutputs();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStorage();
    this.updateUploaded();
  }

  getUploadUrl(value: string): string {
    if (!isSet(value)) {
      return value;
    }

    if (this.field.params['output_format'] == VideoOutputFormat.YoutubeID) {
      return `https://www.youtube.com/watch?v=${value}`;
    } else if (this.field.params['output_format'] == VideoOutputFormat.VimeoID) {
      return `https://vimeo.com/${value}`;
    } else {
      return value;
    }
  }

  onUploadedUpdated() {
    if (!this.uploadedFile) {
      this.previewFile = undefined;
      this.previewEmbed = undefined;
      this.cd.markForCheck();
      return;
    }

    const youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(
      this.uploadedFile.url
    );
    const vimeoMatch = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(
      this.uploadedFile.url
    );
    let previewFile: string;
    let previewEmbed: string;

    if (youtubeMatch) {
      previewEmbed = `https://www.youtube.com/embed/${youtubeMatch[5]}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
    } else if (vimeoMatch) {
      previewEmbed = `https://player.vimeo.com/video/${vimeoMatch[4]}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    } else {
      previewFile = this.uploadedFile.url;
    }

    this.previewFile = previewFile;
    this.previewEmbed = previewEmbed ? this.sanitizer.bypassSecurityTrustResourceUrl(previewEmbed) : undefined;
    this.cd.markForCheck();
  }
}

registerFieldComponent(FieldType.Video, VideoFieldComponent);

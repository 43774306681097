import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { LayerInteractionControl } from '../../controls/layer-interaction.control';

@Component({
  selector: 'app-interactions-section-hover-output',
  templateUrl: './interactions-section-hover-output.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionsSectionHoverOutputComponent implements OnInit {
  @Input() control: LayerInteractionControl;
  @Input() arrayControl: LayerInteractionArray;

  constructor() {}

  ngOnInit() {}
}

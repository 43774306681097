import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';

import { ObjectQueryOptions } from '@modules/resources';

export enum ObjectQueryOperation {
  Get = 'get',
  Create = 'create',
  Update = 'update',
  Delete = 'delete'
}

export class ObjectQuery {
  operation: ObjectQueryOperation = ObjectQueryOperation.Get;
  id: string;
  query: PropertyKey[];
  queryOptions: ObjectQueryOptions = {};
  requestResponse: any;
  requestTokens = {};

  deserialize(data: Object): ObjectQuery {
    this.query = data['query'];
    this.id = data['id'];

    if (data['operation']) {
      this.operation = data['operation'];
    }

    if (data['query_options']) {
      this.queryOptions = data['query_options'];
    }

    if (data['request_response']) {
      this.requestResponse = data['request_response'];
    }

    if (data['request_tokens']) {
      this.requestTokens = data['request_tokens'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      operation: this.operation,
      id: this.id,
      query: this.query,
      query_options: this.queryOptions,
      request_response: this.requestResponse,
      request_tokens: this.requestTokens
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured(): boolean {
    return !!this.query;
  }

  equals(another: this): boolean {
    return isEqual(this.serialize(), another.serialize());
  }
}

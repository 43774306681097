import pickBy from 'lodash/pickBy';
import { generateAlphanumeric } from '@shared';
var WorkflowStep = /** @class */ (function () {
    function WorkflowStep() {
    }
    WorkflowStep.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.type = data['type'];
        if (!this.uid) {
            this.generateUid();
        }
        return this;
    };
    WorkflowStep.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            name: this.name,
            type: this.type
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    WorkflowStep.prototype.generateUid = function () {
        this.uid = generateAlphanumeric(8, { letterFirst: true });
    };
    WorkflowStep.prototype.defaultName = function () {
        return 'Step';
    };
    Object.defineProperty(WorkflowStep.prototype, "analyticsName", {
        get: function () {
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    WorkflowStep.prototype.getIcon = function () {
        return 'power';
    };
    return WorkflowStep;
}());
export { WorkflowStep };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { AppConfigService, CustomHttpUrlEncodingCodec } from '@core';
import { ApiService } from '@modules/api';
import { ViewSettings, ViewSettingsType } from '../../data/view-settings/base';
import { ChangeViewSettings } from '../../data/view-settings/change';
import { CustomViewSettings } from '../../data/view-settings/custom';
import { ListViewSettings } from '../../data/view-settings/list';
import { validateElementNames } from '../../utils/traverse';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../core/services/custom-http-url-encoding-codec/custom-http-url-encoding-codec";
import * as i3 from "../../../api/services/api/api.service";
import * as i4 from "../../../../core/services/app-config/app-config.service";
var ViewSettingsService = /** @class */ (function () {
    function ViewSettingsService(http, urlEncodingCodec, apiService, appConfigService) {
        this.http = http;
        this.urlEncodingCodec = urlEncodingCodec;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
    }
    ViewSettingsService.prototype.mapItem = function (item) {
        if (item['view'] == ViewSettingsType.Custom) {
            var result = new CustomViewSettings().deserialize(item);
            validateElementNames(result.elements);
            return result;
        }
        else if (item['view'] == ViewSettingsType.List) {
            return new ListViewSettings().deserialize(item);
        }
        else if (item['view'] == ViewSettingsType.Change) {
            var result = new ChangeViewSettings().deserialize(item);
            validateElementNames(result.elements);
            return result;
        }
        else {
            return new ViewSettings().deserialize(item);
        }
    };
    ViewSettingsService.prototype.get = function (projectName, environmentName, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
            var headers = new HttpHeaders();
            var params = __assign({ project: projectName }, (draft && { draft: '1' }), { v: _this.appConfigService.version });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (viewSettings) {
            // Backward compatibility
            if (!window['legacyModels']) {
                var remove_1 = [];
                viewSettings.forEach(function (item) {
                    var parts = item['model'].split(';', 2);
                    if (parts.length != 2) {
                        return;
                    }
                    if (!viewSettings.find(function (newItem) { return newItem['model'] == parts[0] + "_" + parts[1]; })) {
                        return;
                    }
                    remove_1.push(item['model']);
                });
                viewSettings = viewSettings.filter(function (item) { return !remove_1.includes(item['model']); });
            }
            return viewSettings;
        }), map(function (result) {
            return result.map(function (item) { return _this.mapItem(item); });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ViewSettingsService.prototype.createBulk = function (projectName, environmentName, instances, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1',
                v: _this.appConfigService.version
            };
            var data = instances.map(function (item) { return item.serialize(fields); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) {
            return result.map(function (item) { return _this.mapItem(item); });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ViewSettingsService.prototype.create = function (projectName, environmentName, instance, fields) {
        return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'view_settings/');
        //     let headers = new HttpHeaders();
        //     const params = {
        //       view: instance.view
        //     };
        //
        //     if (instance.uniqueName) {
        //       params['unique_name'] = instance.uniqueName;
        //     }
        //
        //     if (instance.resource) {
        //       params['resource'] = instance.resource;
        //     }
        //
        //     if (instance.model) {
        //       params['model'] = instance.model;
        //     }
        //
        //     const httpParams = new HttpParams({
        //       fromObject: params,
        //       encoder: this.encoder,
        //       v: this.appConfigService.version
        //     });
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.post(url, instance.serialize(fields), { headers: headers, params: httpParams });
        //   }),
        //   map(result => new ViewSettings().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ViewSettingsService.prototype.update = function (projectName, environmentName, instance, fields) {
        return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.projectMethodURL(projectName, `view_settings/${instance.uid}/`);
        //     const httpParams = { v: this.appConfigService.version };
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.patch(url, instance.serialize(fields), { headers: headers, params: httpParams });
        //   }),
        //   map(result => new ViewSettings().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ViewSettingsService.prototype.delete = function (projectName, environmentName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.projectMethodURL(projectName, `view_settings/${instance.uid}/`);
        //     const httpParams = { v: this.appConfigService.version };
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers, params: httpParams });
        //   }),
        //   map(() => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    ViewSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function ViewSettingsService_Factory() { return new ViewSettingsService(i0.inject(i1.HttpClient), i0.inject(i2.CustomHttpUrlEncodingCodec), i0.inject(i3.ApiService), i0.inject(i4.AppConfigService)); }, token: ViewSettingsService, providedIn: "root" });
    return ViewSettingsService;
}());
export { ViewSettingsService };

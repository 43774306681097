import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NotificationType } from '@common/notifications';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-show-notification',
  templateUrl: './customize-bar-action-edit-type-show-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeShowNotificationComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  colors = [
    {
      value: NotificationType.Info,
      color: '#2b50ed'
    },
    {
      value: NotificationType.Success,
      color: '#35cc62'
    },
    {
      value: NotificationType.Warning,
      color: '#e3871f'
    },
    {
      value: NotificationType.Error,
      color: '#ed2b4f'
    }
  ];

  constructor() {}

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject, throwError } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { AppError, SingletonStore } from '@shared';
import { ProjectService } from '../../projects/services/project/project.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/project/project.service";
import * as i2 from "../../api/services/api/api.service";
var ProjectsStore = /** @class */ (function (_super) {
    __extends(ProjectsStore, _super);
    function ProjectsStore(projectService, apiService) {
        var _this = _super.call(this) || this;
        _this.projectService = projectService;
        _this.apiService = apiService;
        _this._current = new BehaviorSubject(undefined);
        _this.lastUsed$ = _this._current.pipe(filter(function (item) { return item != undefined; }), shareReplay(1));
        return _this;
    }
    Object.defineProperty(ProjectsStore.prototype, "current", {
        get: function () {
            return this._current.value;
        },
        set: function (value) {
            this._current.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectsStore.prototype, "current$", {
        get: function () {
            return this._current.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ProjectsStore.prototype.fetch = function () {
        if (!this.apiService.getAuthorization()) {
            return throwError(new AppError('Not authorized'));
        }
        return this.projectService.getAll({ minimal: true }).pipe(map(function (response) { return response; }));
    };
    ProjectsStore.prototype.addItem = function (addInstance) {
        this.instance = this.instance.concat([addInstance]);
    };
    ProjectsStore.prototype.updateItem = function (updateInstance) {
        var index = this.instance.findIndex(function (item) { return item.uniqueName == updateInstance.uniqueName; });
        if (index !== -1) {
            this.instance = this.instance.slice(0, index).concat([updateInstance], this.instance.slice(index + 1));
        }
    };
    ProjectsStore.ngInjectableDef = i0.defineInjectable({ factory: function ProjectsStore_Factory() { return new ProjectsStore(i0.inject(i1.ProjectService), i0.inject(i2.ApiService)); }, token: ProjectsStore, providedIn: "root" });
    return ProjectsStore;
}(SingletonStore));
export { ProjectsStore };

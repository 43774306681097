import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';

import { ActionControllerService } from '@modules/action-queries';
import { ViewSettingsAction } from '@modules/actions';
import { FieldActions, ITEM_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import {
  applyParamInput,
  ComputedDisplayField,
  createFormFieldFactory,
  DisplayField,
  FieldLabelButton
} from '@modules/fields';
import { getDefaultValue, Model } from '@modules/models';
import { EMPTY, TypedChanges } from '@shared';

@Component({
  selector: 'app-model-element-item',
  templateUrl: './model-element-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelElementItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: DisplayField;
  @Input() model: Model;
  @Input() columnActions: FieldActions[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  createField = createFormFieldFactory();
  labelButtons: FieldLabelButton[] = [];

  constructor(private actionControllerService: ActionControllerService, private injector: Injector) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelElementItemComponent>): void {
    if (changes.item || changes.columnActions) {
      const columnActions = this.columnActions.find(item => item.name == this.item.name);
      const actions = columnActions ? columnActions.actions : [];

      this.labelButtons = actions.map(item => {
        return {
          click: this.onExternalLinkClick(item),
          tip: this.resolveActionVerboseName(item),
          icon: item.icon || 'power'
        };
      });
    }
  }

  resolveFlexItemValue(column: ComputedDisplayField) {
    if (column.valueInput) {
      try {
        const value = applyParamInput(column.valueInput, {
          context: this.context,
          contextElement: this.contextElement
          // field: { field: column.field, params: column.params }
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    }

    return getDefaultValue(column);
  }

  resolveItemColumn(column: DisplayField) {
    if (column instanceof ComputedDisplayField) {
      return this.resolveFlexItemValue(column);
    } else {
      return this.model.getAttribute(column.name);
    }
  }

  resolveActionVerboseName(action: ViewSettingsAction) {
    if (action.verboseNameInput) {
      try {
        const value = applyParamInput(action.verboseNameInput, {
          context: this.context,
          contextElement: this.contextElement,
          defaultValue: ''
        });

        if (value !== EMPTY) {
          return value;
        }
      } catch (e) {}
    }
  }

  onExternalLinkClick(action: ViewSettingsAction) {
    return (e: MouseEvent) => {
      this.context.clickEvent = e;

      if (action) {
        this.actionControllerService
          .execute(action, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: this.model.getAttributes()
            },
            injector: this.injector,
            origin: new ElementRef(e.srcElement)
          })
          .subscribe();
      }
    };
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CustomViewSettings, ViewContext } from '@modules/customize';

@Component({
  selector: 'app-page-template-preview-other-page',
  templateUrl: './page-template-preview-other-page.component.html',
  providers: [ViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewOtherPageComponent implements OnInit {
  @Input() page: CustomViewSettings;
  @Input() labelsVisible = true;

  constructor(public context: ViewContext) {}

  ngOnInit() {}
}

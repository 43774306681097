import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { FilterParameter } from '../../data/filter-parameter';
import { Log } from '../../data/log';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}): Observable<LogService.GetResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'logs/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: params });
      }),
      map(result => new LogService.GetResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getFilters(projectName: string, environmentName: string): Observable<FilterParameter[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'logs/filters/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers });
      }),
      map(result => result.map(item => new FilterParameter().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace LogService {
  export class GetResponse {
    public results: Log[];
    public count: number;
    public next: string;
    public previous: string;

    deserialize(data: Object) {
      this.results = data['results'].map(item => new Log().deserialize(item));
      this.count = data['count'];
      this.next = data['next'];
      this.previous = data['previous'];

      return this;
    }
  }
}

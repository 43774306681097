/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup2-footer.component";
var styles_Popup2FooterComponent = [];
var RenderType_Popup2FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2FooterComponent, data: {} });
export { RenderType_Popup2FooterComponent as RenderType_Popup2FooterComponent };
export function View_Popup2FooterComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_Popup2FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-footer", [], null, null, null, View_Popup2FooterComponent_0, RenderType_Popup2FooterComponent)), i0.ɵdid(1, 114688, null, 0, i1.Popup2FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2FooterComponentNgFactory = i0.ɵccf("app-popup2-footer", i1.Popup2FooterComponent, View_Popup2FooterComponent_Host_0, {}, {}, ["*"]);
export { Popup2FooterComponentNgFactory as Popup2FooterComponentNgFactory };

import { splitmax } from '@shared';

import { Environment } from '../data/environment';
import { ProjectPermissions, ProjectPermissionType } from '../data/project-permission';
import { PermissionsGroup, ProjectGroup } from '../data/project-user';

export function hasGroupPermission(group: ProjectGroup, permission: ProjectPermissions) {
  if (group && group.permissionsGroup == PermissionsGroup.FullAccess) {
    return true;
  } else if (group) {
    return (
      group.permissions.find(item => {
        return item.permissionType == ProjectPermissionType.Project && item.permissionObject == permission.toString();
      }) != undefined
    );
  } else {
    return false;
  }
}

export function hasEnvironmentPermission(environment: Environment, permission: ProjectPermissions) {
  return hasGroupPermission(environment.group, permission);
}

export function hasEnvironmentModelPermission(environment: Environment, modelId: string, action: string) {
  if (
    environment.group &&
    [PermissionsGroup.FullAccess, PermissionsGroup.Editor].includes(environment.group.permissionsGroup)
  ) {
    return true;
  } else if (
    environment.group &&
    action == 'r' &&
    [PermissionsGroup.ReadOnly].includes(environment.group.permissionsGroup)
  ) {
    return true;
  } else if (environment.group) {
    const model = modelId.includes('.') ? splitmax(modelId, '.', 2)[1] : modelId;
    return (
      environment.group.permissions.find(item => {
        return (
          item.permissionType == ProjectPermissionType.Model &&
          (item.permissionObject == modelId || item.permissionObject == model) &&
          item.permissionActions.toLowerCase().includes(action.toLowerCase())
        );
      }) != undefined
    );
  } else {
    return false;
  }
}

export function hasEnvironmentPagePermission(environment: Environment, pageUid: string, action: string) {
  if (environment.group && environment.group.permissionsGroup) {
    return true;
  } else if (environment.group) {
    if (environment.group.permissionsVersion == 1) {
      return true;
    } else {
      return (
        environment.group.permissions.find(item => {
          return (
            item.permissionType == ProjectPermissionType.Page &&
            item.permissionObject == pageUid &&
            item.permissionActions.toLowerCase().includes(action.toLowerCase())
          );
        }) != undefined
      );
    }
  } else {
    return false;
  }
}

<div *ngIf="layer" class="compact-list-item compact-list-item_interactive" [class.compact-list-item_active]="true">
  <div class="compact-list-item__left">
    <div class="compact-list-item__icon" [ngClass]="'icon-' + layer.icon"></div>
  </div>

  <div class="compact-list-item__main">
    <div class="compact-list-item__title">
      {{ layer.name }}
    </div>
  </div>
</div>

<div
  *ngIf="multipleLayers"
  class="compact-list-item compact-list-item_interactive"
  [class.compact-list-item_active]="true"
>
  <div class="compact-list-item__left">
    <div class="compact-list-item__icon icon-layers"></div>
  </div>

  <div class="compact-list-item__main">
    <div class="compact-list-item__title">Layers ({{ multipleLayers.length }})</div>
  </div>
</div>

import pickBy from 'lodash/pickBy';

import { ViewSettingsAction } from '@modules/actions';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export enum ActionGroupOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export class ActionGroupElementItem extends ElementItem {
  public type = ElementType.ActionGroup;
  public actions: ViewSettingsAction[] = [];
  public orientation: ActionGroupOrientation = ActionGroupOrientation.Horizontal;

  deserialize(data: Object): ActionGroupElementItem {
    super.deserialize(data);

    if (this.params['actions']) {
      this.actions = this.params['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (this.params['orientation']) {
      this.orientation = this.params['orientation'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['actions'] = this.actions.map(item => item.serialize());
    this.params['orientation'] = this.orientation;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'trigger operations or transitions';
  }

  get analyticsName(): string {
    return 'action_group';
  }

  defaultName() {
    return 'Button group';
  }
}

registerElementForType(ElementType.ActionGroup, ActionGroupElementItem);

export function transformFieldForeignKey(params: Object, foreignKey: any) {
  const transformer = params['foreign_key_transformer'];

  if (!transformer) {
    return foreignKey;
  }

  try {
    const x = new Function('value', transformer);
    return x(foreignKey);
  } catch (e) {
    console.error(e);
  }
}

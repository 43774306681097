var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, InjectionToken, NgZone, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CLOSE_BY_BACKGROUND_CLICK } from '@common/dialog-popup';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DARK_THEME_OUTPUT, ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { SELECTED_OUTPUT } from '@modules/list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';
import { capitalize, isSet, KeyboardEventKeyCode } from '@shared';
import { ImportFigmaNodeController } from '../../services/import-figma-node-controller/import-figma-node.controller';
import { ImportSketchFileController } from '../../services/import-sketch-file-controller/import-sketch-file.controller';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
var ViewMenu;
(function (ViewMenu) {
    ViewMenu["Parameters"] = "parameters";
    ViewMenu["Actions"] = "actions";
})(ViewMenu || (ViewMenu = {}));
export var parametersToken = new InjectionToken('parametersToken');
export var actionsToken = new InjectionToken('actionsToken');
export var stateToken = new InjectionToken('stateToken');
var ViewEditorComponent = /** @class */ (function () {
    function ViewEditorComponent(currentProjectStore, currentEnvironmentStore, editorContext, viewContext, parametersContextElement, actionsContextElement, stateContextElement, importFigmaNodeController, importSketchFileController, zone, popupService, notificationService, popupComponent, cd, analyticsService) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.editorContext = editorContext;
        this.viewContext = viewContext;
        this.parametersContextElement = parametersContextElement;
        this.actionsContextElement = actionsContextElement;
        this.stateContextElement = stateContextElement;
        this.importFigmaNodeController = importFigmaNodeController;
        this.importSketchFileController = importSketchFileController;
        this.zone = zone;
        this.popupService = popupService;
        this.notificationService = notificationService;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.componentLabel = 'component';
        this.submitLabel = 'Save';
        this.nameEditingEnabled = true;
        this.stateSelectedEnabled = false;
        this.templatesEnabled = true;
        this.result = new EventEmitter();
        this.cancel = new EventEmitter();
        this.nameEditing = false;
        this.viewMenuItems = [
            {
                type: ViewMenu.Parameters,
                label: 'Parameters',
                icon: 'input',
                iconSize: 17
            },
            {
                type: ViewMenu.Actions,
                label: 'Actions',
                icon: 'power',
                iconSize: 15
            }
        ];
        this.viewMenus = ViewMenu;
        this.DARK_THEME_OUTPUT = DARK_THEME_OUTPUT;
    }
    ViewEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editorContext.init(this.view);
        this.isUndoAvailable$ = this.editorContext.isUndoAvailable$();
        this.isRedoAvailable$ = this.editorContext.isRedoAvailable$();
        this.editorContext.view$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.currentView = value;
            _this.cd.markForCheck();
        });
        this.updateComponentContextOutputs();
        this.updateComponentContextActions();
        this.updateStateContextOutputs();
        this.initHotkeys();
    };
    ViewEditorComponent.prototype.ngOnDestroy = function () { };
    ViewEditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.editorContext.isViewInsideViewport(_this.viewportElement.nativeElement)) {
                _this.editorContext.fitToContent(_this.viewportElement.nativeElement);
            }
        }, 0);
    };
    ViewEditorComponent.prototype.updateComponentContextOutputs = function () {
        var _this = this;
        if (this.testParametersSubscription) {
            this.testParametersSubscription.unsubscribe();
            this.testParametersSubscription = undefined;
        }
        this.parametersContextElement.initGlobal({
            uniqueName: 'component',
            name: 'Component parameters'
        });
        this.parametersContextElement.setOutputs(this.currentView.parameters.map(function (item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            var icon = fieldDescription ? fieldDescription.icon : undefined;
            return {
                uniqueName: item.name,
                name: item.verboseName,
                icon: icon,
                fieldType: item.field,
                fieldParams: item.params,
                external: true
            };
        }));
        this.parametersContextElement.setOutputValues(this.currentView.testParameters);
        this.testParametersSubscription = this.editorContext
            .viewChanged$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.parametersContextElement.setOutputValues(_this.currentView.testParameters);
        });
    };
    ViewEditorComponent.prototype.updateComponentContextActions = function () {
        var _this = this;
        this.actionsContextElement.initGlobal({
            uniqueName: 'component_actions',
            name: 'Component actions'
        });
        this.actionsContextElement.setActions(this.currentView.actions.map(function (item) {
            return {
                uniqueName: item.name,
                name: item.verboseName,
                parameters: item.parameters,
                handler: function (params) { return _this.onContextAction(item, params); },
                icon: item.icon
            };
        }));
    };
    ViewEditorComponent.prototype.onContextAction = function (action, params) {
        var description;
        if (action.parameters.length) {
            description = [
                "Action called with the following parameters:"
            ].concat(action.parameters.map(function (item) { return (item.verboseName || item.name) + ": " + params[item.name]; })).join('<br>');
        }
        else {
            description = "Action called without parameters";
        }
        this.notificationService.success("Action \"" + action.name + "\"", description);
    };
    ViewEditorComponent.prototype.updateStateContextOutputs = function () {
        var _this = this;
        var _a, _b;
        this.stateContextElement.initGlobal({
            uniqueName: 'state',
            name: 'Component state'
        });
        this.stateContextElement.setOutputs((this.stateSelectedEnabled
            ? [
                {
                    uniqueName: SELECTED_OUTPUT,
                    name: capitalize(this.componentLabel) + " is selected",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    external: true
                }
            ]
            : []).concat([
            {
                uniqueName: DARK_THEME_OUTPUT,
                name: 'Dark theme',
                icon: 'toggle_theme',
                fieldType: FieldType.Boolean,
                external: true
            }
        ]));
        var defaultState = __assign({}, (this.stateSelectedEnabled && (_a = {},
            _a[SELECTED_OUTPUT] = true,
            _a)), (_b = {}, _b[DARK_THEME_OUTPUT] = false, _b));
        this.stateContextElement.setOutputValues(__assign({}, defaultState, this.currentView.testState));
        this.editorContext
            .viewChanged$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.stateContextElement.setOutputValues(__assign({}, defaultState, _this.currentView.testState));
        });
    };
    ViewEditorComponent.prototype.openViewMenu = function (value) {
        this.currentViewMenu = value;
        this.cd.markForCheck();
    };
    ViewEditorComponent.prototype.closeCurrentViewMenu = function () {
        this.currentViewMenu = undefined;
        this.cd.markForCheck();
    };
    ViewEditorComponent.prototype.closeViewMenu = function (value) {
        if (this.currentViewMenu == value) {
            this.closeCurrentViewMenu();
        }
    };
    ViewEditorComponent.prototype.onViewMenuClick = function (value) {
        if (this.currentViewMenu == value) {
            this.closeCurrentViewMenu();
        }
        else {
            this.openViewMenu(value);
        }
    };
    ViewEditorComponent.prototype.initHotkeys = function () {
        var _this = this;
        this.editorContext
            .trackKeydown()
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Minus && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                _this.editorContext.zoomOut();
            }
            else if (e.keyCode == KeyboardEventKeyCode.Equals && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                _this.editorContext.zoomIn();
            }
            else if (e.keyCode == KeyboardEventKeyCode.Number0 && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                _this.editorContext.viewportScale$.next(1);
            }
            else if (e.keyCode == KeyboardEventKeyCode.Number1 && e.shiftKey) {
                e.preventDefault();
                _this.editorContext.fitToContent(_this.viewportElement.nativeElement);
            }
            else if (e.keyCode == KeyboardEventKeyCode.Dot && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                _this.editorContext.showInterface$.next(!_this.editorContext.showInterface$.value);
            }
            else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
                if (_this.editorContext.isUndoAvailable()) {
                    _this.editorContext.undo();
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
                if (_this.editorContext.isRedoAvailable()) {
                    _this.editorContext.redo();
                }
            }
        });
    };
    ViewEditorComponent.prototype.updateParameters = function (result) {
        this.currentView.parameters = result;
        this.cd.markForCheck();
        this.updateComponentContextOutputs();
    };
    ViewEditorComponent.prototype.updateActions = function (result) {
        this.currentView.actions = result;
        this.cd.markForCheck();
        this.updateComponentContextActions();
    };
    ViewEditorComponent.prototype.updateTestParameters = function (result) {
        this.currentView.testParameters = result;
        this.editorContext.markViewChanged(this.currentView, ViewEditorCustomizeSource.ViewParameters);
    };
    ViewEditorComponent.prototype.updateTestState = function (result) {
        this.currentView.testState = result;
        this.editorContext.markViewChanged(this.currentView, ViewEditorCustomizeSource.ViewParameters);
    };
    ViewEditorComponent.prototype.submit = function () {
        this.result.emit({ view: this.currentView });
        this.close();
    };
    ViewEditorComponent.prototype.onCloseClick = function () {
        if (this.popupComponent) {
            this.popupComponent.confirmClose(CLOSE_BY_BACKGROUND_CLICK);
        }
    };
    ViewEditorComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    ViewEditorComponent.prototype.setNameEditing = function (value) {
        this.nameEditing = value;
        this.cd.markForCheck();
    };
    ViewEditorComponent.prototype.cleanName = function () {
        var currentView = this.editorContext.view$.value;
        var name = isSet(currentView.name) ? currentView.name.trim() : '';
        if (!isSet(name)) {
            name = 'Custom View';
        }
        currentView.name = name;
        this.cd.markForCheck();
    };
    ViewEditorComponent.prototype.importFigmaNode = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
            Source: this.analyticsSource
        });
        this.importFigmaNodeController
            .importNode()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                result.view.name = _this.editorContext.view$.value.name;
                _this.editorContext.updateView(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    ViewEditorComponent.prototype.importSketchFile = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
            Source: this.analyticsSource
        });
        this.importSketchFileController
            .importFile()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                result.view.name = _this.editorContext.view$.value.name;
                _this.editorContext.updateView(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    return ViewEditorComponent;
}());
export { ViewEditorComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { ImageFill, ImageFillType } from '@modules/views';
import { isSet } from '@shared';
var ImageFillControl = /** @class */ (function (_super) {
    __extends(ImageFillControl, _super);
    function ImageFillControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            type: new FormControl(isSet(state.type) ? state.type : ImageFillType.Fill),
            image: new FormControl(isSet(state.image) ? state.image : undefined),
            image_input_enabled: new FormControl(isSet(state.imageInput) ? !!state.imageInput : false),
            image_input: new FieldInputControl({ name: 'value' }),
            scale_x: new FormControl(isSet(state.scaleX) ? state.scaleX : 1),
            scale_y: new FormControl(isSet(state.scaleY) ? state.scaleY : 1)
        }) || this;
        _this.typeOptions = [
            {
                value: ImageFillType.Fill,
                name: 'Fill Image'
            },
            {
                value: ImageFillType.Fit,
                name: 'Fit Image'
            },
            {
                value: ImageFillType.Stretch,
                name: 'Stretch Image'
            },
            {
                value: ImageFillType.Tile,
                name: 'Tile Image'
            }
        ];
        return _this;
    }
    ImageFillControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.type.patchValue(value.type, { emitEvent: options.emitEvent });
        this.controls.image.patchValue(value.image, { emitEvent: options.emitEvent });
        this.controls.image_input_enabled.patchValue(!!value.imageInput, { emitEvent: options.emitEvent });
        this.controls.image_input.patchValue(value.imageInput ? value.imageInput.serialize() : {}, {
            emitEvent: options.emitEvent
        });
        this.controls.scale_x.patchValue(value.scaleX, { emitEvent: options.emitEvent });
        this.controls.scale_y.patchValue(value.scaleY, { emitEvent: options.emitEvent });
    };
    ImageFillControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new ImageFill();
        }
        instance.type = this.controls.type.value;
        if (this.controls.image_input_enabled.value) {
            instance.image = this.controls.image.value;
            instance.imageInput = this.controls.image_input.value
                ? new Input().deserialize(this.controls.image_input.value)
                : undefined;
        }
        else {
            instance.image = this.controls.image.value;
            instance.imageInput = undefined;
        }
        instance.scaleX = this.controls.scale_x.value;
        instance.scaleY = this.controls.scale_y.value;
        return instance;
    };
    ImageFillControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    return ImageFillControl;
}(FormGroup));
export { ImageFillControl };

<div
  class="list"
  [class.list_scrollable]="scrollable"
  appElement
  [appElementDetectVisible]="{ intervalCheck: 600 }"
  (appElementFirstVisible)="firstVisible$.next(true)"
>
  <div class="list__header" [class.list__header_active]="settings.header">
    <app-list-header
      *ngIf="settings.header"
      [noBottomMargin]="true"
      [params]="params"
      [filtersEnabled]="settings.displayFilters"
      [filters]="listState.filters"
      [filterFields]="settings.filterFields"
      [hideParams]="hideParams"
      [pageSettings]="pageSettings"
      [settings]="settings"
      [dataSource]="listState.dataSource"
      [inputs]="inputs"
      [parameters]="parameters"
      [moreLink]="moreLink"
      [focus]="focus"
      [searchEnabled]="settings.searchEnabled || settings.searchQuery != undefined"
      [search]="listState.search"
      [searchLive]="settings.searchLive"
      [createSegmentAllowed]="createSegmentAllowed"
      [viewId]="viewId"
      [context]="context"
      [contextElement]="contextElement"
      [accentColor]="accentColor"
      (paramsChanged)="paramsChanged.emit($event)"
      (filtersUpdated)="onFiltersUpdated($event)"
      (searchUpdated)="onSearchUpdated($event)"
      (layoutAdd)="layoutAdd.emit($event)"
      (layoutCustomize)="layoutCustomize.emit($event)"
      (segmentCreated)="segmentCreated.emit($event)"
      (updateData)="reloadData()"
      (customizeModelDescription)="customizeModelDescription()"
      (customizeViewSettings)="customizeViewSettings()"
    >
      <ng-container *ngIf="settings.controlsPosition == controlsPositions.Header" actions-right>
        <div class="list__actions-item">
          <button
            type="button"
            class="button button_icon button_segment-left button_no-margin"
            [disabled]="!isPrevCarouselCardsAvailable()"
            [appButtonTintColor]="accentColor"
            [appButtonTintColorStyle]="tintStyles.Default"
            (click)="prevCarouselCards()"
          >
            <span class="button__icon icon-arrow_backward_2"></span>
          </button>

          <button
            type="button"
            class="button button_icon button_segment-right button_no-margin"
            [disabled]="!isNextCarouselCardsAvailable()"
            [appButtonTintColor]="accentColor"
            [appButtonTintColorStyle]="tintStyles.Default"
            (click)="nextCarouselCards()"
          >
            <span class="button__icon icon-arrow_forward_2"></span>
          </button>
        </div>
      </ng-container>
    </app-list-header>
  </div>

  <div class="list__content">
    <div class="list__viewport list__viewport_bounds" appElementResize (elementResize)="onResize()" #viewport_element>
      <app-error *ngIf="!configured" [title]="'Not configured' | localize">
        Unfortunately, this component is not configured yet
      </app-error>

      <div *ngIf="configured && error" class="carousel-message">
        <app-error [title]="'Loading failed' | localize">
          {{ error }}
        </app-error>
      </div>

      <app-carousel-stub
        *ngIf="configured && !error && items == undefined"
        [animating]="loading"
        [columns]="visibleColumns.length ? visibleColumns.length : scrollable ? 8 : 5"
        [rows]="scrollable ? 8 : loadingItems"
        [rowCards]="rowCards"
        [cardHeight]="cardDefaultHeight"
        [gapHorizontal]="settings.gapHorizontal"
        [scrollable]="scrollable"
        [height]="heightBeforeLoading"
      ></app-carousel-stub>

      <ng-container *ngIf="configured && !error && items != undefined">
        <div
          class="carousel"
          [style.margin-left.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
          [style.margin-right.px]="(settings.gapHorizontal | appIsSet) ? -settings.gapHorizontal * 0.5 : null"
          #carousel_element
        >
          <ng-container *ngIf="items">
            <div class="carousel__viewport-wrapper">
              <div
                class="carousel__viewport"
                [class.carousel__viewport_scrollable]="settings.scrollNavigation"
                (scroll)="onScroll()"
                (mousewheel)="onMouseWheel($event)"
                (mousedown)="onMouseDown($event)"
                #carousel_viewport_element
              >
                <div class="carousel__layout">
                  <app-grid-item
                    *ngFor="let item of items; let i = index; trackBy: trackByFn"
                    [item]="item"
                    [modelDescription]="listState.modelDescription"
                    [settings]="settings"
                    [visibleColumns]="visibleColumns"
                    [rowCards]="rowCards"
                    [view]="cardView$ | async"
                    [context]="context"
                    [contextElement]="contextElement"
                    [selected]="isItemSelected(item, i)"
                    [containerWidth]="viewportWidth$ | async"
                    [accentColor]="accentColor"
                    [snap]="settings.snapOnNavigate && i % stepSize === 0"
                    [snapMargin]="settings.snapOnNavigate && i > 0"
                    (select)="toggleSelectedItem(item, i, $event.element, true)"
                    (modelUpdated)="onModelUpdated($event)"
                    #grid_item_element
                  >
                  </app-grid-item>

                  <ng-container *ngIf="loading">
                    <app-grid-stub-item
                      *ngFor="let item of rowCards | appRange; let i = index"
                      [index]="i"
                      [columns]="visibleColumns.length ? visibleColumns.length : scrollable ? 8 : 5"
                      [rowCards]="rowCards"
                      [height]="cardDefaultHeight"
                      [animating]="true"
                      [snap]="settings.snapOnNavigate && i == 0"
                      [snapMargin]="true"
                    >
                    </app-grid-stub-item>
                  </ng-container>
                </div>
              </div>

              <div
                class="carousel__overflow carousel__overflow_position_left"
                [class.carousel__overflow_visible]="isPrevCarouselCardsAvailable()"
              ></div>

              <div
                class="carousel__overflow carousel__overflow_position_right"
                [class.carousel__overflow_visible]="isNextCarouselCardsAvailable()"
              ></div>

              <ng-container *ngIf="settings.controlsPosition == controlsPositions.SideOverlay">
                <div class="carousel__control carousel__control_position_left">
                  <div
                    class="carousel-control"
                    [class.carousel-control_visible]="isPrevCarouselCardsAvailable()"
                    [appTintColor]="accentColor"
                    [appTintColorStyle]="tintStyles.Primary"
                    (click)="prevCarouselCards()"
                  >
                    <div class="carousel-control__icon icon-arrow_backward_2"></div>
                  </div>
                </div>

                <div class="carousel__control carousel__control_position_right">
                  <div
                    class="carousel-control"
                    [class.carousel-control_visible]="isNextCarouselCardsAvailable()"
                    [appTintColor]="accentColor"
                    [appTintColorStyle]="tintStyles.Primary"
                    (click)="nextCarouselCards()"
                  >
                    <div class="carousel-control__icon icon-arrow_forward_2"></div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="settings.displayFooter" class="carousel__pagination">
              <app-carousel-pagination
                [currentIndex]="firstIndex"
                [items]="items.length"
                [stepSize]="stepSize"
                [displaySize]="rowCards"
                [accentColor]="accentColor"
                (selectIndex)="scrollToIndex($event)"
              ></app-carousel-pagination>
            </div>
          </ng-container>
        </div>

        <div *ngIf="items.length == 0" class="carousel-message">
          <app-error [title]="'Nothing found' | localize" [fill]="scrollable">
            {{
              'Unfortunately, no {0} matching your query found'
                | localize: [title ? (title | appCapitalize) : ('records' | localize)]
            }}
          </app-error>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import isArray from 'lodash/isArray';

import { airtableFormulas, BaseField, getFieldDescriptionByType } from '@modules/fields';
import { FilterItem } from '@modules/filters';

export function airtableFilterItemFormula(field: BaseField, filterItem: FilterItem): string {
  const fieldDescription = getFieldDescriptionByType(field.field);
  const lookup = filterItem.lookup
    ? fieldDescription.lookups.find(item => item.type.lookup == filterItem.lookup.lookup)
    : undefined;
  const lookupArray = lookup ? lookup.array : undefined;
  const lookupFieldDescription = getFieldDescriptionByType(lookup ? lookup.field : undefined);
  let filterValue = filterItem.value;

  const formula = airtableFormulas.find(i => i.field == field.field && i.lookup === filterItem.lookup);

  if (!formula) {
    return;
  }

  if (lookupFieldDescription.deserializeValue) {
    if (lookupArray && isArray(filterValue)) {
      filterValue = filterValue.map(item => lookupFieldDescription.deserializeValue(item));
    } else {
      filterValue = lookupFieldDescription.deserializeValue(filterValue);
    }
  }

  if (filterValue === undefined) {
    return;
  }

  if (filterValue === null) {
    filterValue = '';
  } else if (isArray(filterValue)) {
    filterValue = filterValue.filter(item => item !== undefined).map(item => (item === null ? '' : item));
  }

  const result = formula.formula(filterValue, field);

  return filterItem.exclude ? `NOT(${result})` : result;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { first } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectProperty, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';

@Component({
  selector: 'app-project-property-change',
  templateUrl: './project-property-change.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPropertyChangeComponent implements OnInit, OnDestroy {
  @Input() type = ProjectPropertyType.Group;
  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() description: string;
  @Input() disabled = false;
  @Input() scrollable = false;
  @Input() analyticsSource: string;

  properties: ProjectProperty[] = [];

  constructor(
    private projectPropertyStore: ProjectPropertyStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.projectPropertyStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        this.properties = properties.filter(item => item.type == this.type);
        this.cd.markForCheck();
      });

    this.control.valueChanges.pipe(first(), untilDestroyed(this)).subscribe(() => {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.StartedToSetUp, {
        Type: this.analyticsSource
      });
    });
  }

  // addProperty() {
  //   this.form.arrayAppend(this.form.createItem());
  //   this.configurable[this.form.form.controls.length - 1] = true;
  //   this.creatingProperty = true;
  //   this.cd.markForCheck();
  //
  //   this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.AddStarted, {
  //     Type: this.analyticsType
  //   });
  // }
}

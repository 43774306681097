import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

import {
  AlignHorizontal,
  AlignVertical,
  CustomizeService,
  ElementComponent,
  PopupSettings,
  ViewSettings
} from '@modules/customize';
import { CurrentUserStore } from '@modules/users';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-auto-element-toolbar',
  templateUrl: './auto-element-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoElementToolbarComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() displayName: string;
  @Input() alignmentHorizontal: AlignHorizontal;
  @Input() alignmentVertical: AlignVertical;
  @Input() parentElement: any;
  @Input() parentPopup: PopupSettings;
  @Input() elementComponent: ElementComponent;
  @Input() alignmentHorizontalEnabled = false;
  @Input() alignmentVerticalEnabled = false;
  @Input() customizeEnabled = false;
  @Input() duplicateEnabled = false;
  @Input() moveEnabled = false;
  @Input() deleteEnabled = false;
  @Input() configured = true;
  @Input() bottom = false;
  @Input() active = false;
  @Input() tipAddShowDelay = false;
  @Output() customize = new EventEmitter<MouseEvent>();
  @Output() customizeParent = new EventEmitter<MouseEvent>();
  @Output() customizePopup = new EventEmitter<MouseEvent>();
  @Output() alignHorizontal = new EventEmitter<AlignHorizontal>();
  @Output() alignVertical = new EventEmitter<AlignVertical>();
  @Output() action = new EventEmitter<string>();
  @Output() moveTo = new EventEmitter<ViewSettings>();
  @Output() duplicate = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() setDefaultElement = new EventEmitter<void>();
  @Output() createTemplate = new EventEmitter<void>();
  @Output() toolbarElementInit = new EventEmitter<ElementRef<HTMLElement>>();

  @ViewChild('toolbar', { read: ViewContainerRef }) toolbarVcr2: ViewContainerRef;
  @ViewChild('toolbar', { read: ElementRef }) toolbarVcr: ElementRef;
  @ViewChild(CdkConnectedOverlay) movePagesOverlay: CdkConnectedOverlay;

  alignsHorizontal = AlignHorizontal;
  alignsVertical = AlignVertical;
  isStaff = false;
  hoverParent$ = new BehaviorSubject<boolean>(false);
  tipAddShowDelay$ = new BehaviorSubject<boolean>(false);
  tipShowDelay = 0;
  tipShowDelayOnStart = 300;
  movePagesOpened = false;
  movePagesPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetY: 4
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetY: -4
    }
  ];

  constructor(
    public customizeService: CustomizeService,
    private currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUserStore.instance$.pipe(untilDestroyed(this)).subscribe(instance => {
      this.isStaff = instance && instance.isStaff;
      this.cd.markForCheck();
    });

    this.tipAddShowDelay$
      .pipe(
        debounce(tipDelay => {
          if (tipDelay) {
            this.tipShowDelay = this.tipShowDelayOnStart;
            this.cd.markForCheck();

            return timer(this.tipShowDelayOnStart);
          } else {
            return timer(0);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.tipShowDelay = 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutoElementToolbarComponent>): void {
    if (this.tipAddShowDelay) {
      this.tipAddShowDelay$.next(this.tipAddShowDelay);
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
    this.toolbarElementInit.emit(this.toolbarVcr);
  }

  setMovePagesOpened(value) {
    this.movePagesOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (!this.movePagesOverlay) {
      return;
    }

    this.movePagesOverlay.positionChange.pipe(untilDestroyed(this)).subscribe((e: ConnectedOverlayPositionChange) => {
      const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
      const position = this.movePagesPositions.find(item =>
        propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
      );
      const otherPosition = this.movePagesPositions.filter(item => item !== position);

      if (position) {
        this.movePagesOverlay.overlayRef.addPanelClass(position.panelClass);
      }

      otherPosition.forEach(item => this.movePagesOverlay.overlayRef.removePanelClass(item.panelClass));
    });
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ViewSettings, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { ProjectPermissionType } from '@modules/projects';
import { ascComparator } from '@shared';

import { ProjectObjectPermissionsArray } from './project-object-permissions.array';
import { ProjectPermissionControl } from './project-permission.control';

export interface ProjectPagePermissionControlData {
  page: ViewSettings;
}

@Injectable()
export class ProjectPagePermissionsArray extends ProjectObjectPermissionsArray<ProjectPagePermissionControlData>
  implements OnDestroy {
  constructor(private viewSettingsStore: ViewSettingsStore) {
    super();
    this.initControls();
  }

  ngOnDestroy(): void {}

  initControls() {
    this.viewSettingsStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        const existingControls = [];

        viewSettings
          .filter(item => item.view == ViewSettingsType.Custom)
          .forEach(page => {
            const existingControl = this.controls.find(item => item.value.object == page.uid);

            if (existingControl) {
              existingControls.push(existingControl);
            } else {
              const control = new ProjectPermissionControl<ProjectPagePermissionControlData>(
                {
                  type: ProjectPermissionType.Page,
                  object: page.uid
                },
                { page: page }
              );
              const permission = this.permissions.find(
                item => item.permissionType == control.value.type && item.permissionObject == control.value.object
              );

              control.deserialize(permission);
              this.push(control);
              existingControls.push(control);
            }
          });

        const deleteControls = this.controls.filter(item => !existingControls.includes(item));
        deleteControls.forEach(item => this.removeAt(this.controls.indexOf(item)));

        this.controls = this.controls.sort((lhs, rhs) => {
          return ascComparator((lhs.data.page.name || '').toLowerCase(), (rhs.data.page.name || '').toLowerCase());
        });

        this._initialized$.next(true);
      });
  }
}

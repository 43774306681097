<ng-container *ngIf="!custom && currentOption">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
    [cdkConnectedOverlayPositions]="popoverPositions"
    [cdkConnectedOverlayOrigin]="dropdownTrigger"
    [cdkConnectedOverlayOpen]="dropdownOpened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="setDropdownOpened(false)"
    (detach)="setDropdownOpened(false)"
  >
    <div class="popover2 overlay-content overlay-content_animated">
      <div class="popover2__inner">
        <div class="popover2__viewport popover2__viewport_scrollable">
          <div class="popover2__content" style="min-width: 300px;">
            <a
              *ngFor="let item of options"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              [class.token-popup-list-item_active]="item.type == currentOption?.type"
              href="javascript:void(0)"
              (click)="setValueType(item.type); setDropdownOpened(false)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span *ngIf="item.icon" class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="['icon-' + item.icon]"></span>
                  </span>
                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                    {{ item.name }}
                  </span>
                  <span class="token-popup-list-item__right token-popup-list-item__right_wrap"> </span>
                </div>
                <div
                  *ngIf="item.description"
                  class="token-popup-list-item__line"
                  [class.token-popup-list-item__line_active]="false"
                >
                  <div class="token-popup-list-item__description">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="popover2__arrow"></div>
      </div>
    </div>
  </ng-template>

  <div class="select select_theme_jet" [class.select_small]="small">
    <div class="select__input" cdkOverlayOrigin #dropdownTrigger="cdkOverlayOrigin" (click)="setDropdownOpened(true)">
      <span *ngIf="currentOption?.icon" class="select__input-icon" [ngClass]="['icon-' + currentOption.icon]"> </span>
      <span class="select__input-label">{{ currentOption.name }}</span>
      <span class="select__input-arrow"></span>
    </div>
  </div>
</ng-container>

<div *ngIf="custom" class="checkboxes-control">
  <label class="checkboxes-control__item">
    <input type="checkbox" class="checkbox checkbox_round" [formControl]="control.controls.read" />

    <span class="checkboxes-control__item-label">{{ 'Read' | localize }}</span>
  </label>

  <label class="checkboxes-control__item">
    <input type="checkbox" class="checkbox checkbox_round" [formControl]="control.controls.write" />

    <span class="checkboxes-control__item-label">{{ 'Update' | localize }}</span>
  </label>

  <label class="checkboxes-control__item">
    <input type="checkbox" class="checkbox checkbox_round" [formControl]="control.controls.delete" />

    <span class="checkboxes-control__item-label">{{ 'Delete' | localizeAdv: { context: 'permissions' } }}</span>
  </label>
</div>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { Dashboard } from '../data/dashboard';
import { DashboardService } from '../services/dashboard/dashboard.service';

@Injectable()
export class DashboardStore extends SingletonStore<Dashboard[]> {
  constructor(
    private dashboardService: DashboardService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<Dashboard[]> {
    if (!this.currentProjectStore.instance) {
      return of([]);
    }

    return this.dashboardService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        map(result => {
          if (!result) {
            return [];
          }

          return result;
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  getDetail(id: number, forceUpdate = false) {
    return this.get(forceUpdate).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.id == id);
      })
    );
  }

  getDetailFirst(id: number, forceUpdate = false) {
    return this.getFirst(forceUpdate).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.id == id);
      })
    );
  }
}

import { Injectable } from '@angular/core';

import { FilterElementInput, FilterElementItem, FilterStyle } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ModelDescription } from '@modules/models';

@Injectable()
export class FilterGenerator {
  constructor() {}

  getElement(
    modelDescription: ModelDescription,
    elementUid: string,
    options: {
      style?: FilterStyle;
      uid?: string;
    }
  ): FilterElementItem {
    const filterElement = new FilterElementItem();

    if (options.uid) {
      filterElement.uid = options.uid;
    } else {
      filterElement.generateUid();
    }

    const fields = modelDescription.dbFields.filter(
      item => item.name != modelDescription.primaryKeyField && item.editable
    );
    const textFields = fields.filter(item => item.field == FieldType.Text);
    const selectFields = fields.filter(item => item.field == FieldType.Select);

    filterElement.elements = [elementUid];
    filterElement.elementInputs = [textFields[0], selectFields[0], textFields[1], selectFields[1], textFields[2]]
      .filter(item => !!item)
      .map(item => {
        const input = new FilterElementInput();

        if (item.field == FieldType.Text) {
          input.name = `${item.name}__icontains`;
        } else {
          input.name = item.name;
        }

        input.settings = { verboseName: item.verboseName || item.name };

        return input;
      });

    if (options.style) {
      filterElement.style = options.style;
    }

    return filterElement;
  }
}

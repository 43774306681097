import { ChangeDetectorRef, ElementRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, ResourceName } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { TemplateService } from '@modules/template';
import { PageTemplatesController } from '@modules/template-queries';
import { isSet } from '@shared';
import { registerResourceAddModelComponent } from '../../data/resource-add-model-components';
import { ResourceModelEditController } from '../../services/resource-model-edit-controller/resource-model-edit.controller';
import { JetDatabaseImportFileComponent } from '../resource-add-model/jet-database-import-file/jet-database-import-file.component';
var ProjectResourceDataTemplatesComponent = /** @class */ (function () {
    function ProjectResourceDataTemplatesComponent(popupComponent, templateService, currentProjectStore, currentEnvironmentStore, modelDescriptionService, modelDescriptionStore, actionStore, resourceControllerService, resourceModelEditController, pageTemplatesController, popupService, injector, routing, cd) {
        this.popupComponent = popupComponent;
        this.templateService = templateService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionService = modelDescriptionService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.resourceControllerService = resourceControllerService;
        this.resourceModelEditController = resourceModelEditController;
        this.pageTemplatesController = pageTemplatesController;
        this.popupService = popupService;
        this.injector = injector;
        this.routing = routing;
        this.cd = cd;
        this.closeEnabled = true;
        this.importEnabled = true;
        this.showPageTemplates = false;
        this.cancelled = new EventEmitter();
        this.added = new EventEmitter();
        this.loading = false;
        this.submitLoading = false;
        this.name = '';
        this.nameEditing = false;
        this.modelDescriptionTemplates = [];
    }
    ProjectResourceDataTemplatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        combineLatest(this.templateService.getResourceDefaultModelDescription(this.resource.typeItem.name), this.templateService.getResourceModelDescriptionsTemplates(this.resource.typeItem.name))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var defaultModelDescriptionTemplate = _a[0], modelDescriptionTemplates = _a[1];
            _this.defaultModelDescriptionTemplate = defaultModelDescriptionTemplate;
            _this.modelDescriptionTemplates = modelDescriptionTemplates;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.setDefaultName();
    };
    ProjectResourceDataTemplatesComponent.prototype.ngOnDestroy = function () { };
    ProjectResourceDataTemplatesComponent.prototype.setSelectedTemplate = function (template) {
        this.selectedTemplate = template;
        this.cd.markForCheck();
        this.viewportElement.nativeElement.scrollTo(0, 0);
        this.setDefaultName();
    };
    ProjectResourceDataTemplatesComponent.prototype.setNameEditing = function (value) {
        this.nameEditing = value;
        this.cd.markForCheck();
    };
    ProjectResourceDataTemplatesComponent.prototype.cleanName = function () {
        var _this = this;
        this.modelDescriptionStore
            .getFirst()
            .pipe(map(function (modelDescriptions) {
            var names = modelDescriptions
                .filter(function (item) { return item.resource == _this.resource.uniqueName; })
                .reduce(function (acc, item) {
                acc.push(item.verboseName, item.verboseNamePlural);
                return acc;
            }, []);
            return _this.getDistinctModelDescriptionName(names, _this.name);
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.name = value;
            _this.cd.markForCheck();
        });
    };
    ProjectResourceDataTemplatesComponent.prototype.setDefaultName = function () {
        this.name = this.selectedTemplate ? this.selectedTemplate.name : 'New Table';
        this.cd.markForCheck();
        this.cleanName();
    };
    ProjectResourceDataTemplatesComponent.prototype.finishNameEditing = function () {
        this.setNameEditing(false);
        this.name = isSet(this.name) ? this.name.trim() : '';
        this.cd.markForCheck();
        if (isSet(this.name)) {
            this.cleanName();
        }
        else {
            this.setDefaultName();
        }
    };
    ProjectResourceDataTemplatesComponent.prototype.getDistinctModelDescriptionName = function (names, baseName) {
        var name;
        var index = 1;
        var match = baseName.match(/\s\d+$/);
        if (match) {
            baseName = baseName.slice(0, match.index);
            index = parseInt(match[0], 10);
        }
        do {
            name = index > 1 ? [baseName, index].join(' ') : baseName;
            ++index;
        } while (names.find(function (item) { return item.toLowerCase() == name.toLowerCase(); }));
        return name;
    };
    ProjectResourceDataTemplatesComponent.prototype.importModels = function () {
        var _this = this;
        this.popupService.push({
            component: JetDatabaseImportFileComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
                resource: this.resource,
                analyticsSource: this.analyticsSource
            },
            outputs: {
                added: [
                    function (event) {
                        if (!_this.closeEnabled) {
                            _this.routing.navigateApp(event.modelDescription.systemSettingsLink, { queryParams: { reload: '1' } });
                        }
                        _this.close();
                        _this.onModelDescriptionCreated(event.modelDescription);
                    }
                ]
            },
            injector: this.injector
        });
    };
    ProjectResourceDataTemplatesComponent.prototype.createModelDescription = function (templateModelDescription, templateModels) {
        var _this = this;
        this.submitLoading = true;
        this.cd.markForCheck();
        var controller = this.resourceControllerService.get(this.resource.type);
        var instance = new ModelDescription();
        instance.resource = this.resource.uniqueName;
        instance.model = ModelDescription.generateModel();
        instance.dbTable = instance.model;
        instance.verboseName = this.name;
        instance.verboseNamePlural = this.name;
        instance.queryType = templateModelDescription.queryType;
        instance.fields = templateModelDescription.fields;
        instance.primaryKeyField = templateModelDescription.primaryKeyField || 'id';
        return controller
            .modelDescriptionCreate(this.resource, instance)
            .pipe(switchMap(function () { return _this.modelDescriptionService.getFromResource(_this.resource); }), map(function (resourceModelDescriptions) { return resourceModelDescriptions.find(function (item) { return item.model == instance.model; }); }), tap(function (result) { return _this.modelDescriptionStore.addItem(result); }), delayWhen(function (result) { return _this.actionStore.syncAutoActions(result); }), delayWhen(function (modelDescription) {
            if (!templateModels.length) {
                return of([]);
            }
            var models = templateModels.map(function (data) {
                var model = controller.createModel().deserialize(modelDescription.modelId, data);
                model.setUp(modelDescription);
                model.deserializeAttributes(modelDescription.dbFields);
                return model;
            });
            return controller.modelCreateBulk(_this.resource, modelDescription, models);
        }), untilDestroyed(this))
            .subscribe(function (modelDescription) {
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.close();
            _this.onModelDescriptionCreated(modelDescription);
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectResourceDataTemplatesComponent.prototype.isCreatingModelDescription = function () {
        return this.submitLoading;
    };
    ProjectResourceDataTemplatesComponent.prototype.onModelDescriptionCreated = function (modelDescription) {
        var _this = this;
        this.added.emit({
            modelDescription: modelDescription
        });
        if (this.showPageTemplates) {
            this.pageTemplatesController
                .open({
                cancelEnabled: true,
                resource: this.resource,
                newPage: true,
                analyticsSource: this.analyticsSource
            })
                // .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                if (result.startPage) {
                    _this.routing.navigateApp(result.startPage.link);
                }
                else if (result.newPage) {
                    _this.routing.navigateApp(_this.currentProjectStore.instance.newPageLink);
                }
            });
        }
    };
    ProjectResourceDataTemplatesComponent.prototype.cancel = function () {
        this.close();
        this.cancelled.emit();
    };
    ProjectResourceDataTemplatesComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    return ProjectResourceDataTemplatesComponent;
}());
export { ProjectResourceDataTemplatesComponent };
registerResourceAddModelComponent(ResourceName.JetDatabase, ProjectResourceDataTemplatesComponent, 'Add or Import', {
    icon: 'new_document',
    multistep: true
});

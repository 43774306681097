import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

export interface Item {
  label: string;
  response?: any;
  path: string[];
  children: Item[];
}

@Component({
  selector: 'app-query-builder-response-keys',
  templateUrl: './query-builder-response-keys.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderResponseKeysComponent {
  @Input() items: Item[];
  @Output() selected = new EventEmitter<string[]>();

  @ViewChild(MatMenu) menu: MatMenu;
}

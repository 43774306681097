var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { isSet } from '@shared';
import { FieldType } from '../data/field-type';
import { ParameterValue } from '../data/parameter-value';
import { ParameterNameControl } from './parameter-name.control';
var ParameterValueControl = /** @class */ (function (_super) {
    __extends(ParameterValueControl, _super);
    function ParameterValueControl() {
        return _super.call(this, {
            name: new ParameterNameControl(''),
            value: new FormControl(''),
            field: new FormControl(FieldType.Text),
            params: new FormControl({}),
            required: new FormControl(true)
        }) || this;
    }
    ParameterValueControl.prototype.deserialize = function (item) {
        this.instance = item;
        this.controls.name.patchValue(item.name);
        this.controls.value.patchValue(item.value);
        this.controls.field.patchValue(item.field);
        this.controls.params.patchValue(item.params);
        this.controls.required.patchValue(item.required);
        this.markAsPristine();
    };
    ParameterValueControl.prototype.serialize = function () {
        var result = new ParameterValue();
        if (this.instance) {
            result.deserialize(this.instance.serialize());
        }
        result.name = this.controls.name.value;
        result.value = this.controls.value.value;
        result.field = this.controls.field.value;
        result.params = this.controls.params.value;
        result.required = this.controls.required.value;
        return result;
    };
    ParameterValueControl.prototype.hasName = function () {
        return isSet(this.controls.name.value);
    };
    return ParameterValueControl;
}(FormGroup));
export { ParameterValueControl };

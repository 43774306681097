import { Injectable, OnDestroy } from '@angular/core';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { auditTime, filter, map, switchMap } from 'rxjs/operators';

import { findLayer, Frame, FrameTranslate, getAllLayers, Layer, rectFromFrames } from '@modules/views';
import { isSet } from '@shared';

import { snapFrame } from '../../utils/guides';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../view-editor-context/view-editor.context';

@Injectable()
export class ViewEditorMultipleLayers implements OnDestroy {
  public frame$ = new BehaviorSubject<Frame>(undefined);

  constructor(private editorContext: ViewEditorContext) {
    this.getFrame$()
      .pipe(untilDestroyed(this))
      .subscribe(frame => this.frame$.next(frame));
  }

  ngOnDestroy(): void {}

  getFrame$(): Observable<Frame> {
    return this.editorContext.customizingLayers$.pipe(
      switchMap(layers => {
        if (layers.length <= 1) {
          return of(undefined);
        }

        return combineLatest(
          layers.map(layer => {
            return merge(
              of(layer),
              this.editorContext.layerChanged$().pipe(
                filter(item => item.layer.isSame(layer)),
                map(item => item.layer)
              ),
              this.editorContext.globalLayersChange$().pipe(
                map(() => {
                  const allLayers = getAllLayers(this.editorContext.view$.value.layers);
                  return findLayer(allLayers, item => item.isSame(layer));
                })
              )
            );
          })
        ).pipe(
          auditTime(0),
          map(updatedLayers => {
            const frameItems = updatedLayers.map(item => {
              const container = this.editorContext.getLayerContainer(item);
              return new FrameTranslate({
                frame: item.frame,
                translate: container ? container.options.translate : undefined
              });
            });

            const layersRect = rectFromFrames(frameItems);
            if (!layersRect) {
              return;
            }

            return new Frame({
              x: layersRect.left,
              y: layersRect.top,
              width: layersRect.right - layersRect.left,
              height: layersRect.bottom - layersRect.top
            });
          })
        );
      })
    );
  }

  patchFrame(frame: Partial<Frame>, options: { add?: boolean; originalFrame?: Frame; originalLayers?: Layer[] } = {}) {
    const originalFrame = options.originalFrame || this.frame$.value;

    if (!originalFrame) {
      return;
    }

    const newFrame = new Frame(originalFrame);

    if (options.add) {
      if (isSet(frame.x)) {
        newFrame.x = newFrame.x + frame.x;
      }

      if (isSet(frame.y)) {
        newFrame.y = newFrame.y + frame.y;
      }

      if (isSet(frame.width)) {
        newFrame.width = newFrame.width + frame.width;
      }

      if (isSet(frame.height)) {
        newFrame.height = newFrame.height + frame.height;
      }
    } else {
      newFrame.patch(frame);
    }

    const layers = this.editorContext.customizingLayers$.value;
    const changedLayers: Layer[] = [];

    layers.forEach(layer => {
      const layerOriginal = options.originalLayers ? options.originalLayers.find(item => item.isSame(layer)) : layer;
      const currentLayerFrame = layer.frame.serialize();

      if (originalFrame.width != 0) {
        const widthMultiplier = newFrame.width / originalFrame.width;
        const originalPositionX = (layerOriginal.frame.x - originalFrame.x) / originalFrame.width;

        layer.frame.width = round(layerOriginal.frame.width * widthMultiplier, 2);
        layer.frame.x = round(newFrame.x + originalPositionX * newFrame.width, 2);
      }

      if (originalFrame.height != 0) {
        const heightMultiplier = newFrame.height / originalFrame.height;
        const originalPositionY = (layerOriginal.frame.y - originalFrame.y) / originalFrame.height;

        layer.frame.height = round(layerOriginal.frame.height * heightMultiplier, 2);
        layer.frame.y = round(newFrame.y + originalPositionY * newFrame.height, 2);
      }

      if (!isEqual(layer.frame, currentLayerFrame)) {
        changedLayers.push(layer);
      }
    });

    if (changedLayers.length) {
      this.editorContext.markLayersChanged(changedLayers, ViewEditorCustomizeSource.CustomizeMultipleLayers);
    }

    this.showSnapGuides();
  }

  showSnapGuides() {
    const frame = this.frame$.value;

    if (!frame) {
      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
      return;
    }

    const customizingLayers = this.editorContext.customizingLayers$.value;
    const otherFrames = this.editorContext.getFrames({
      exceptLayers: customizingLayers
    });

    const snap = snapFrame({
      base: new FrameTranslate({
        frame: frame
      }),
      others: otherFrames,
      horizontalSnapX: true,
      verticalSnapY: true,
      snapDistance: 0
    });

    this.editorContext.setHorizontalGuides(snap.horizontalGuides, { durationMs: 600 });
    this.editorContext.setVerticalGuides(snap.verticalGuides, { durationMs: 600 });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { DEFAULT_LANGUAGE, getLanguages } from '@common/localize';
import { LocalStorage, timezones } from '@core';
import { CurrentUserStore } from '@modules/users';
import { NonEmptyValidator, trimAll } from '@shared';
var UserEditForm = /** @class */ (function (_super) {
    __extends(UserEditForm, _super);
    function UserEditForm(formUtils, fb, currentUserStore, localStorage) {
        var _this = _super.call(this, {
            first_name: new FormControl('', [Validators.required, NonEmptyValidator("This field can't be empty")]),
            last_name: new FormControl(''),
            email_subscription: new FormControl(false),
            new_password: new FormControl(''),
            repeat_password: new FormControl(''),
            language: new FormControl(''),
            timezone: new FormControl('')
        }) || this;
        _this.formUtils = formUtils;
        _this.fb = fb;
        _this.currentUserStore = currentUserStore;
        _this.localStorage = localStorage;
        _this.languageOptions = [];
        _this.timezoneOptions = [];
        return _this;
    }
    UserEditForm.prototype.init = function (user) {
        this.languageOptions = getLanguages();
        this.languageDefaultOption = this.languageOptions.find(function (item) { return item.value == DEFAULT_LANGUAGE; });
        this.timezoneOptions = timezones.map(function (item) {
            return {
                value: item.name,
                name: "(GMT" + item.gmt + ") " + item.name,
                data: {
                    name: item.name,
                    tz: item.tz
                }
            };
        });
        this.timezoneDefaultOption = this.timezoneOptions.find(function (item) { return item.data['tz'] == moment.tz.guess(); });
        this.user = user;
        this.languageInitial = user.language;
        this.timezoneInitial = user.timezone;
        this.controls.first_name.patchValue(user.firstName);
        this.controls.last_name.patchValue(user.lastName);
        this.controls.email_subscription.patchValue(user.emailSubscription);
        this.controls.language.patchValue(user.language);
        this.controls.timezone.patchValue(user.timezone);
        this.markAsPristine();
    };
    UserEditForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var languageChanged = this.controls.language.value != this.languageInitial;
        var timezoneChanged = this.controls.timezone.value != this.timezoneInitial;
        this.currentUserStore.instance.firstName = trimAll(this.controls.first_name.value);
        this.currentUserStore.instance.lastName = trimAll(this.controls.last_name.value);
        this.currentUserStore.instance.emailSubscription = this.controls.email_subscription.value;
        this.currentUserStore.instance.language = this.controls.language.value;
        this.currentUserStore.instance.timezone = this.controls.timezone.value;
        return this.currentUserStore.update(['first_name', 'last_name', 'email_subscription', 'language', 'timezone']).pipe(map(function (user) {
            _this.init(user);
            _this.markAsPristine();
            if (languageChanged) {
                _this.localStorage.setLanguage(_this.controls.language.value);
            }
            if (timezoneChanged) {
                _this.localStorage.setTimezone(_this.controls.timezone.value);
            }
            return {
                languageChanged: languageChanged,
                timezoneChanged: timezoneChanged
            };
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    UserEditForm.prototype.toggleEmailSubscription = function () {
        this.controls.email_subscription.setValue(!this.controls.email_subscription.value);
        this.markAsDirty();
    };
    return UserEditForm;
}(FormGroup));
export { UserEditForm };

import { ChangeDetectionStrategy, Component, OnInit, ViewContainerRef } from '@angular/core';

import { FixedService } from '../../services/fixed/fixed.service';

@Component({
  selector: 'app-fixed-container',
  templateUrl: './fixed-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixedContainerComponent implements OnInit {
  constructor(private fixedService: FixedService, private vcr: ViewContainerRef) {}

  ngOnInit() {
    this.fixedService.registerContainer(this.vcr);
  }
}

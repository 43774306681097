var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
var AppFormGroup = /** @class */ (function (_super) {
    __extends(AppFormGroup, _super);
    function AppFormGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._serverErrors = new BehaviorSubject([]);
        return _this;
    }
    Object.defineProperty(AppFormGroup.prototype, "serverErrors", {
        get: function () {
            return this._serverErrors.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppFormGroup.prototype, "serverErrors$", {
        get: function () {
            return this._serverErrors.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AppFormGroup.prototype.setServerErrors = function (errors) {
        this._serverErrors.next(errors.slice());
    };
    AppFormGroup.prototype.clearServerErrors = function () {
        this._serverErrors.next([]);
    };
    return AppFormGroup;
}(FormGroup));
export { AppFormGroup };

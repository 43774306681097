import pickBy from 'lodash/pickBy';

import { WorkflowStepType } from '../workflow-step-type';
import { registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class DelayWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Delay;
  public delay = 3;

  deserialize(data: Object) {
    super.deserialize(data);
    this.delay = data['delay'];

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      delay: this.delay
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Delay';
  }

  get analyticsName(): string {
    return 'delay';
  }

  getIcon() {
    return 'time';
  }
}

registerWorkflowStepType(WorkflowStepType.Delay, DelayWorkflowStep);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { Dataset } from '@modules/charts';
import { RawListViewSettingsColumn, VALUE_OUTPUT } from '@modules/customize';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ValueWidget } from '@modules/dashboard';
import { WidgetDataSourceService } from '@modules/dashboard-queries';
import { ChartWidgetDataSource, DataSource, DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator } from '@shared';

import { WidgetComponent } from '../widget/widget.component';

interface ElementState {
  widget?: ValueWidget;
  data?: {
    dataSource?: ValueWidgetDataSource;
    params?: Object;
    staticData?: Object;
  };
  compare?: {
    dataSource?: ValueWidgetDataSource;
    params?: Object;
    staticData?: Object;
  };
  chart?: {
    name?: string;
    color?: string;
    format?: string;
    dataSource?: ChartWidgetDataSource;
    params?: Object;
    staticData?: Object;
  };
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
}

export function serializeDataSourceColumns(columns: RawListViewSettingsColumn[]): Object[] {
  return columns
    .filter(item => !item.flex)
    .map(item => {
      return {
        name: item.name
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

export function serializeData(data: {
  dataSource?: ValueWidgetDataSource;
  params?: Object;
  staticData?: Object;
}): Object {
  return {
    dataSource: data.dataSource
      ? {
          ...data.dataSource.serialize(),
          columns: serializeDataSourceColumns(data.dataSource.columns)
        }
      : undefined,
    staticData: data.staticData,
    params: data.params
  };
}

function getElementStateFetch(state: ElementState): Object {
  return {
    data: state.data ? serializeData(state.data) : undefined,
    compare: state.data ? serializeData(state.compare) : undefined,
    chart: state.chart
      ? {
          dataSource: state.chart.dataSource
            ? {
                ...state.chart.dataSource.serialize(),
                columns: serializeDataSourceColumns(state.chart.dataSource.columns)
              }
            : undefined,
          staticData: state.chart.staticData,
          params: state.chart.params
        }
      : undefined,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet
  };
}

function getElementStateChartDisplay(state: ElementState): Object {
  return {
    chart: state.chart
      ? {
          name: state.chart.name,
          color: state.chart.color,
          format: state.chart.format
        }
      : undefined
  };
}

@Component({
  selector: 'app-value-widget',
  templateUrl: './value-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueWidgetComponent extends WidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() widget: ValueWidget;

  widget$ = new BehaviorSubject<ValueWidget>(undefined);
  firstVisible$ = new BehaviorSubject<boolean>(false);
  elementState: ElementState = {};
  chartDisplayState$: BehaviorSubject<ElementState>;

  loadingSubscription: Subscription;
  value: any;
  compareValue: any;
  chartValue: Dataset;
  loading = true;
  error: string;
  configured = true;
  hoverItem$ = new BehaviorSubject<{ group: string; value: number }>(undefined);

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private widgetDataSourceService: WidgetDataSourceService,
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.updateContextOutputs();
    this.widgetOnChange(this.widget);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['widget']) {
      this.widgetOnChange(this.widget);
    }
  }

  widgetOnChange(value: ValueWidget) {
    this.widget$.next(value);
  }

  trackChanges() {
    this.firstVisible$
      .pipe(
        first(value => value),
        switchMap(() => this.widget$),
        switchMap(element => {
          return combineLatest(
            this.dataSourceGeneratorService.applyDataSourceDefaults<ValueWidgetDataSource>(
              ValueWidgetDataSource,
              element.dataSource
            ),
            this.dataSourceGeneratorService.applyDataSourceDefaults<ValueWidgetDataSource>(
              ValueWidgetDataSource,
              element.compareDataSource
            ),
            this.dataSourceGeneratorService.applyDataSourceDefaults<ChartWidgetDataSource>(
              ChartWidgetDataSource,
              element.chartDataset ? element.chartDataset.dataSource : undefined
            )
          ).pipe(
            map(([dataSource, compareDataSource, chartDataset]) => {
              element.dataSource = dataSource;
              element.compareDataSource = compareDataSource;

              if (chartDataset) {
                element.chartDataset.dataSource = chartDataset;
              }

              return element;
            })
          );
        }),
        switchMap(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.elementState = state;
      });
  }

  getElementStateDataParams<T extends DataSource>(
    dataSource: T
  ): Observable<{
    dataSource?: T;
    params?: Object;
    staticData?: Object;
  }> {
    const staticData$ =
      dataSource && dataSource.type == DataSourceType.Input && dataSource.input
        ? applyParamInput$<Object>(dataSource.input, {
            context: this.context,
            defaultValue: {},
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});
    const params$ = dataSource
      ? applyParamInputs$({}, dataSource.queryInputs, {
          context: this.context,
          parameters: dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, params$).pipe(
      map(([staticData, params]) => {
        return {
          dataSource: dataSource,
          staticData: staticData,
          params: params
        };
      })
    );
  }

  getElementState(widget: ValueWidget): Observable<ElementState> {
    return combineLatest(
      this.getElementStateDataParams<ValueWidgetDataSource>(widget.dataSource),
      this.getElementStateDataParams<ValueWidgetDataSource>(widget.compareDataSource),
      this.getElementStateDataParams<ChartWidgetDataSource>(
        widget.chartDataset ? widget.chartDataset.dataSource : undefined
      )
    ).pipe(
      map(([data, compare, chart]) => {
        return {
          widget: widget,
          data: data,
          compare: compare,
          chart: widget.chartDataset
            ? {
                name: widget.chartDataset.name,
                color: widget.chartDataset.color,
                format: widget.chartDataset.format,
                ...chart
              }
            : undefined,
          inputsLoading: [data, compare, chart].some(dataParams => {
            return [dataParams.params, dataParams.staticData].some(obj => {
              return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
            });
          }),
          inputsNotSet: [data, compare, chart].some(dataParams => {
            return [dataParams.params, dataParams.staticData].some(obj => {
              return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
            });
          })
        };
      })
    );
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
      const chartDisplayState$ = new BehaviorSubject<ElementState>(state);
      this.fetch(state, chartDisplayState$);
      this.chartDisplayState$ = chartDisplayState$;
    } else if (
      this.chartDisplayState$ &&
      !isEqual(getElementStateChartDisplay(state), getElementStateChartDisplay(this.elementState))
    ) {
      this.chartDisplayState$.next(state);
    }
  }

  fetch(state: ElementState, chartDisplayState$: BehaviorSubject<ElementState>) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = undefined;
    }

    this.loading = false;
    this.configured = state.data && state.data.dataSource && state.data.dataSource.isConfigured();
    this.error = undefined;
    this.cd.markForCheck();

    this.contextElement.patchOutputValueMeta(VALUE_OUTPUT, { loading: true });

    if (!this.configured) {
      this.value = undefined;
      this.compareValue = undefined;
      this.chartValue = undefined;
      this.cd.detectChanges();
      return;
    }

    if (state.inputsNotSet) {
      this.value = undefined;
      this.compareValue = undefined;
      this.chartValue = undefined;
      this.loading = false;
      this.cd.markForCheck();
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    if (state.inputsLoading) {
      return;
    }

    const dataAggregate$ = this.widgetDataSourceService.aggregate({
      project: this.currentProjectStore.instance,
      environment: this.currentEnvironmentStore.instance,
      dataSource: state.data.dataSource,
      params: state.data.params,
      staticData: state.data.staticData,
      context: this.context
    });
    const compareAggregate$ =
      state.compare && state.compare.dataSource && state.compare.dataSource.isConfigured()
        ? this.widgetDataSourceService.aggregate({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            dataSource: state.compare.dataSource,
            params: state.compare.params,
            staticData: state.compare.staticData,
            context: this.context
          })
        : of(undefined);
    const chartGroup$ =
      state.chart && state.chart.dataSource && state.chart.dataSource.isConfigured()
        ? this.widgetDataSourceService.group({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            dataSource: state.chart.dataSource,
            params: state.chart.params,
            staticData: state.chart.staticData,
            context: this.context
          })
        : of(undefined);

    this.loadingSubscription = combineLatest(dataAggregate$, compareAggregate$, chartGroup$, chartDisplayState$)
      .pipe(untilDestroyed(this))
      .subscribe(
        ([value, compareValue, chartValue, chartDisplayState]) => {
          // const obj = { value: value };
          //
          // TweenMax.fromTo(obj, 0.6, { value: 0 }, { value: value, ease: Power4.easeIn,
          //   onUpdate: () => {
          //     if (this['_isComponentDestroyed']) {
          //       return;
          //     }
          //     this.value = this.widget.format ? obj.value : Math.round(obj.value);
          //     this.cd.markForCheck();
          //   },
          //   onComplete: () => {
          //     if (this['_isComponentDestroyed']) {
          //       return;
          //     }
          //     this.cd.markForCheck();
          //   }
          // });

          this.value = value;
          this.compareValue = compareValue;
          this.chartValue = chartValue
            ? {
                name: chartDisplayState.chart.name,
                color: chartDisplayState.chart.color,
                format: chartDisplayState.chart.format,
                groupLookup: state.chart.dataSource.xLookup,
                dataset: chartValue.slice(-30)
              }
            : undefined;
          this.loading = false;
          this.cd.markForCheck();

          this.contextElement.setOutputValue(VALUE_OUTPUT, this.value, { loading: false });
        },
        error => {
          console.error(error);
          this.value = undefined;
          this.compareValue = undefined;
          this.chartValue = undefined;
          this.loading = false;

          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else if (isPlainObject(error)) {
            this.error = JSON.stringify(error);
          } else if (error.hasOwnProperty('message')) {
            this.error = error.message;
          } else {
            this.error = error;
          }

          this.cd.markForCheck();

          this.contextElement.setOutputValue(VALUE_OUTPUT, undefined, { loading: false, error: true });
        }
      );
  }

  updateContextOutputs() {
    this.contextElement.setOutputs([
      {
        uniqueName: VALUE_OUTPUT,
        name: 'Value',
        icon: 'spiral',
        external: true
      }
    ]);
  }

  reloadData() {
    this.fetch(this.elementState, this.chartDisplayState$);
  }

  any(value) {
    return value as any;
  }

  onClick(element: HTMLElement) {
    if (this.widget.clickAction) {
      this.actionControllerService
        .execute(this.widget.clickAction, {
          context: this.context,
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tip.component";
var styles_TipComponent = [];
var RenderType_TipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TipComponent, data: {} });
export { RenderType_TipComponent as RenderType_TipComponent };
function View_TipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "tip__inner"]], [[8, "innerHTML", 1], [4, "max-width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; var currVal_1 = (_co.options.maxWidth || null); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TipComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TipComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "tip__inner"]], [[4, "max-width", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipComponent_3)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.template; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.options.maxWidth || null); _ck(_v, 0, 0, currVal_0); }); }
export function View_TipComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["root", 1]], null, 7, "div", [["class", "tip-position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "tip"]], [[2, "tip_animate_show", null], [2, "tip_animate_hide", null], [2, "tip_hoverable", null], [2, "tip_flexible", null], [2, "tip_opaque", null]], null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TipComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "tip"; var currVal_6 = _ck(_v, 4, 0, ("tip_style_" + _co.options.style), ("tip_side_" + _co.side)); _ck(_v, 3, 0, currVal_5, currVal_6); var currVal_7 = !_co.template; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.template; _ck(_v, 8, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; var currVal_1 = !_co.visible; var currVal_2 = _co.options.hoverable; var currVal_3 = _co.options.flexible; var currVal_4 = _co.options.opaque; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_TipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tip", [], null, null, null, View_TipComponent_0, RenderType_TipComponent)), i0.ɵdid(1, 4440064, null, 0, i2.TipComponent, [i0.NgZone, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TipComponentNgFactory = i0.ɵccf("app-tip", i2.TipComponent, View_TipComponent_Host_0, { content: "content", options: "options", origin: "origin", closed: "closed" }, { moved: "moved" }, []);
export { TipComponentNgFactory as TipComponentNgFactory };

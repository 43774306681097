import * as i0 from "@angular/core";
var DraggableService = /** @class */ (function () {
    function DraggableService() {
        this.items = [];
    }
    DraggableService.prototype.add = function (item) {
        this.items.push(item);
    };
    DraggableService.prototype.remove = function (item) {
        this.items = this.items.filter(function (i) { return i !== item; });
    };
    DraggableService.ngInjectableDef = i0.defineInjectable({ factory: function DraggableService_Factory() { return new DraggableService(); }, token: DraggableService, providedIn: "root" });
    return DraggableService;
}());
export { DraggableService };

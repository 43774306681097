import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { getActualDomain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectUniqueNameController } from '@modules/layout-components';
import { MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore, Project } from '@modules/projects';
import { projectThemes, ThemeService } from '@modules/theme';
import { controlValue } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';

@Component({
  selector: 'app-customize-bar-appearance-edit',
  templateUrl: './customize-bar-appearance-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAppearanceEditComponent implements OnInit, OnDestroy {
  @Input() projectForm: ProjectUpdateForm;
  @Input() settingsForm: ProjectSettingsUpdateForm;
  @Input() menuForm: MenuUpdateForm;

  appBaseUrl: string;
  appProjectUrl: string;
  appUrlHover = new BehaviorSubject<boolean>(false);
  menuBlocks: { label: string; controls: MenuBlockControl[] }[] = [];
  accentColors = ['blue', 'teal', 'green', 'red', 'maroon', 'black', 'navy', 'purple', 'orange', 'yellow'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  menuColors = ['blue', 'teal', 'red', 'maroon', 'black', 'purple'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  projectThemes = projectThemes;
  createField = createFormFieldFactory();

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public themeService: ThemeService,
    private appConfigService: AppConfigService,
    private projectUniqueNameController: ProjectUniqueNameController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.appBaseUrl = this.getAppBaseUrl(project, project.uniqueName);
        this.appProjectUrl = project.uniqueName;
        this.cd.markForCheck();
      });

    controlValue(this.menuForm.controls.blocks)
      .pipe(auditTime(60), untilDestroyed(this))
      .subscribe(() => {
        const menuBlocks = this.menuForm.controls.blocks.controls.filter(item => item.controls.enabled.value);
        this.menuBlocks = [
          {
            label: 'left',
            controls: menuBlocks.filter(item => MenuBlockLayouts.isLeft(item.controls.layout.value))
          },
          {
            label: 'top',
            controls: menuBlocks.filter(item => MenuBlockLayouts.isTop(item.controls.layout.value))
          }
        ].filter(item => item.controls.length);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getAppBaseUrl(project: Project, uniqueName: string): string {
    if (project.domain && !project.domain.custom) {
      const webBaseUrl = getActualDomain(uniqueName, false);
      return `${webBaseUrl}/app/`;
    } else {
      let webBaseHost = '';

      try {
        webBaseHost = new URL(this.appConfigService.webBaseUrl).host;
      } catch (e) {}

      const webBaseUrl = project.domain ? project.domain.actualDomain : webBaseHost;
      return `${webBaseUrl}/app/`;
    }
  }

  changeTheme(theme: string): void {
    this.settingsForm.controls.default_theme.patchValue(theme);
    this.themeService.theme = theme;
    this.cd.markForCheck();
  }

  editUniqueName() {
    this.projectUniqueNameController.edit().pipe(untilDestroyed(this)).subscribe();
  }
}

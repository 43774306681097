import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { combineLatest, Observable, of } from 'rxjs';
import { map, publishLast, refCount, share, switchMap } from 'rxjs/operators';

import { ActionDescription, ActionType, QueryAction, Segue, SegueType } from '@modules/actions';
import { ApiService } from '@modules/api';
import { modelFieldToRawListViewSettingsColumn, ViewContext } from '@modules/customize';
import { EditableField, FieldType, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelFieldType, PER_PAGE_PARAM } from '@modules/models';
import {
  fieldsToProviderItems,
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  parametersToProviderItems,
  parametersToProviderItemsFlat
} from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, Environment, Project, Resource } from '@modules/projects';
import { ActionQuery, QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

@Injectable()
export class ActionDescriptionService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private resourceControllerService: ResourceControllerService
  ) {}

  getFromResources(project: Project, environment: Environment): Observable<ActionDescription[]> {
    const controllers = project
      .getEnvironmentResources(environment.uniqueName)
      .map(resource => {
        const controller = this.resourceControllerService.get(resource.type);
        return controller && controller.actionDescriptionGet ? controller.actionDescriptionGet(resource) : undefined;
      })
      .filter(item => item != undefined);

    if (!controllers.length) {
      return of([]);
    }

    return combineLatest(...controllers).pipe(
      map(result => flatten(result.filter(item => item != undefined))),
      map(result => {
        result.forEach(projectModel => {
          projectModel.project = project.uniqueName;
        });

        return result;
      }),
      share()
    );
  }

  getModelAutoActions(modelDescription: ModelDescription): ActionDescription[] {
    return modelDescription
      .autoActions()
      .filter(action => {
        return modelDescription[action.query];
      })
      .map(action => {
        const instance = new ActionDescription();
        const queryAction = new QueryAction();
        const query = new ActionQuery();

        query.queryType = QueryType.Simple;
        query.simpleQuery = new query.simpleQueryClass();
        query.simpleQuery.name = action.uniqueName;

        queryAction.query = query;

        instance.project = modelDescription.project;
        instance.resource = modelDescription.resource;
        instance.model = modelDescription.model;
        instance.modelAction = action.name;
        instance.name = action.uniqueName;
        instance.type = ActionType.Query;
        instance.protected = true;
        instance.queryAction = queryAction;

        if (action.name == 'get') {
          instance.verboseName = `Get ${modelDescription.verboseName}`;
          instance.actionParams = modelDescription.getParameters;
        } else if (action.name == 'get_detail') {
          instance.verboseName = `Get Detail ${modelDescription.verboseName}`;
          instance.actionParams = modelDescription.getDetailParametersOrDefaults;
        } else if (action.name == 'create') {
          instance.verboseName = `Create ${modelDescription.verboseName}`;
          instance.actionParams = modelDescription.createParametersOrDefaults;
        } else if (action.name == 'update') {
          instance.verboseName = `Update ${modelDescription.verboseName}`;
          instance.actionParams = modelDescription.updateParametersOrDefaults;
        } else if (action.name == 'delete') {
          instance.verboseName = `Delete ${modelDescription.verboseName}`;
          instance.actionParams = modelDescription.deleteParametersOrDefaults;
        }

        return instance;
      });
  }

  getStorageAutoActions(project: Project, resource: Resource, storage: Storage): ActionDescription[] {
    return storage
      .autoActions()
      .filter(action => {
        return storage[action.query];
      })
      .map(action => {
        const instance = new ActionDescription();
        const queryAction = new QueryAction();
        const query = new StorageQuery();

        query.queryType = QueryType.Simple;
        query.simpleQuery = new query.simpleQueryClass();
        query.simpleQuery.name = action.uniqueName;

        queryAction.query = query;

        instance.project = project.uniqueName;
        instance.resource = resource.uniqueName;
        instance.storage = storage.uniqueName;
        instance.storageAction = action.name;
        instance.name = action.uniqueName;
        instance.type = ActionType.Query;
        instance.protected = true;
        instance.queryAction = queryAction;

        if (action.name == 'get_object_url') {
          instance.verboseName = `Get ${storage.name} object URL`;
          instance.description = 'get public accessible file URL with expiration date';
          instance.actionParams = storage.getObjectUrlParameters();
        } else if (action.name == 'upload') {
          instance.verboseName = `Upload file to ${storage.name}`;
          instance.actionParams = storage.uploadParameters();
        } else if (action.name == 'get') {
          instance.verboseName = `Get ${storage.name} objects`;
          instance.actionParams = storage.getParameters();
        } else if (action.name == 'create_directory') {
          instance.verboseName = `Create ${storage.name} directory`;
          instance.actionParams = storage.createDirectoryParameters();
        } else if (action.name == 'remove') {
          instance.verboseName = `Delete ${storage.name} file`;
          instance.actionParams = storage.removeParameters();
        }

        return instance;
      });
  }

  getAutoActions(
    options: {
      modelDescription?: ModelDescription;
      modelDescriptions?: ModelDescription[];
    } = {}
  ): Observable<ActionDescription[]> {
    return combineLatest(this.currentProjectStore.getFirst(), this.modelDescriptionStore.getFirst()).pipe(
      map(([project, modelDescriptions]) => {
        const modelActions = modelDescriptions
          .filter(modelDescription => {
            if (options.modelDescription) {
              return modelDescription.isSame(options.modelDescription);
            } else if (options.modelDescriptions) {
              return options.modelDescriptions.some(item => modelDescription.isSame(item));
            } else {
              return true;
            }
          })
          .sort((lhs, rhs) => {
            const lhsName = String(lhs.verboseNamePlural || lhs.model).toLowerCase();
            const rhsName = String(rhs.verboseNamePlural || rhs.model).toLowerCase();

            if (lhsName < rhsName) {
              return -1;
            } else if (lhsName > rhsName) {
              return 1;
            } else {
              return 0;
            }
          })
          .map(modelDescription => this.getModelAutoActions(modelDescription));
        const storageActions = project
          .getStorages(this.currentEnvironmentStore.instance.uniqueName)
          .filter(item => item.storage.isConfigured())
          .filter(() => {
            if (options.modelDescription || options.modelDescriptions) {
              return false;
            } else {
              return true;
            }
          })
          .sort((lhs, rhs) => {
            const lhsName = String(lhs.storage.name || lhs.storage.uniqueName).toLowerCase();
            const rhsName = String(rhs.storage.name || rhs.storage.uniqueName).toLowerCase();

            if (lhsName < rhsName) {
              return -1;
            } else if (lhsName > rhsName) {
              return 1;
            } else {
              return 0;
            }
          })
          .map(storage => this.getStorageAutoActions(project, storage.resource, storage.storage));

        return flatten([...modelActions, ...storageActions]);
      })
    );
  }

  getOverrides(
    project: Project,
    environment: Environment,
    options: { draft?: boolean } = {}
  ): Observable<ActionDescription[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'action_descriptions/'
        );
        let headers = new HttpHeaders();
        const params = {
          ...(options.draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: params });
      }),
      map(result =>
        result.map(item => {
          const itemResult = new ActionDescription().deserialize(item);
          itemResult.project = project.uniqueName;
          return itemResult;
        })
      ),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  applyOverrides(
    originalActions: ActionDescription[],
    overrideActions: ActionDescription[],
    options: { onlyExistent?: boolean } = {}
  ): ActionDescription[] {
    overrideActions.forEach(overrideAction => {
      const originalAction = originalActions.find(
        item => item.resource == overrideAction.resource && item.name == overrideAction.name
      );

      if (!originalAction) {
        if (!options.onlyExistent) {
          originalActions.push(overrideAction);
        }

        return;
      }

      ['type', 'verboseName', 'icon', 'link', 'dynamicStatus', 'modelIds', 'bulk', 'query', 'orderAfter']
        .filter(item => overrideAction[item] !== undefined)
        .forEach(item => (originalAction[item] = overrideAction[item]));

      overrideAction.actionParams.forEach(overrideActionParam => {
        const originalActionParam = originalAction.actionParams.find(item => item.name == overrideAction.name);

        if (!originalActionParam) {
          return;
        }

        ['verboseName', 'field', 'required', 'defaultType', 'defaultValue']
          .filter(item => originalActionParam.hasOwnProperty(item) && overrideActionParam[item] !== undefined)
          .forEach(item => (originalActionParam[item] = overrideActionParam[item]));
      });
    });

    return originalActions;
  }

  getLinkActionParameters(link: Segue, pageParameters?: ParameterField[]): InputFieldProviderItem[] {
    if (!link) {
      return [];
    }

    if (link.type == SegueType.Page) {
      return parametersToProviderItemsFlat((pageParameters || []).filter(item => item.name));
    } else if ([SegueType.ModelMassEdit, SegueType.ModelDelete, SegueType.ModelDelete].includes(link.type)) {
      return fieldsToProviderItems([
        {
          name: 'ids',
          verboseName: 'Records',
          field: FieldType.Text,
          required: true,
          params: {}
        }
      ]);
    } else if ([SegueType.ModelChange].includes(link.type)) {
      return fieldsToProviderItems([
        {
          name: 'id',
          verboseName: 'Record',
          field: FieldType.Text,
          required: true,
          params: {}
        }
      ]);
    } else if ([SegueType.ModelCreate].includes(link.type)) {
      return fieldsToProviderItems([
        {
          name: '_duplicate',
          verboseName: 'Duplicate Record',
          field: FieldType.Text,
          required: false,
          params: {}
        }
      ]);
    } else {
      return [];
    }
  }

  getExternalLinkActionParameters(): EditableField[] {
    return [
      {
        name: 'href',
        verboseName: 'URL',
        field: FieldType.Text,
        required: true,
        params: {}
      },
      {
        name: 'new_tab',
        verboseName: 'Open In New Tab',
        field: FieldType.Text,
        required: false,
        params: {}
      }
    ];
  }

  getExportActionParameters(
    resource: Resource,
    modelDescription: ModelDescription,
    parameters: ParameterField[] = []
  ): InputFieldProviderItem[] {
    if (!modelDescription) {
      return [];
    }

    const columns = modelDescription.fields
      .filter(item => item.type == ModelFieldType.Db)
      .map(item => modelFieldToRawListViewSettingsColumn(item));
    const result: InputFieldProviderItem[] = [
      ...parametersToProviderItems(parameters),
      ...inputFieldProviderItemsFromModelGet(resource, modelDescription, modelDescription.getQuery, columns)
    ];

    result.push({
      label: 'Records',
      field: {
        name: 'ids',
        verboseName: 'Records',
        field: FieldType.Text,
        required: false,
        params: {}
      }
    });

    if (modelDescription.getQuery) {
      result.push({
        label: 'Records per request',
        warning: `
          When using Records per request make sure export <strong>results are ordered</strong> because batch requests
          from unordered list can lead to incorrect total result
        `,
        field: {
          name: PER_PAGE_PARAM,
          verboseName: 'Records per request',
          field: FieldType.Number,
          required: false,
          params: {}
        }
      });
    }

    return result;
  }

  getOpenPopupActionParameters(popupParameters?: ParameterField[]): InputFieldProviderItem[] {
    return parametersToProviderItemsFlat((popupParameters || []).filter(item => item.name));
  }

  getElementActionParameters(path: (string | number)[], context?: ViewContext): InputFieldProviderItem[] {
    if (!context || !isSet(path)) {
      return [];
    }

    const action = context.getElementAction(path);

    if (!action) {
      return [];
    }

    return parametersToProviderItemsFlat(action.parameters);
  }

  createBulk(
    projectName: string,
    environmentName: string,
    actionDescriptions: ActionDescription[]
  ): Observable<ActionDescription[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'action_descriptions/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = actionDescriptions.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new ActionDescription().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: ActionDescription): Observable<ActionDescription> {
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'action_descriptions/');
    //     let headers = new HttpHeaders();
    //     const data = {
    //       project: actionDescription.project,
    //       ...actionDescription.serialize()
    //     };
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, data, { headers: headers });
    //   }),
    //   map(result => new ActionDescription().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  update(projectName: string, environmentName: string, instance: ActionDescription): Observable<ActionDescription> {
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `action_descriptions/${id}/`);
    //     let headers = new HttpHeaders();
    //     const data = {
    //       project: actionDescriptions.project,
    //       ...actionDescriptions.serialize()
    //     };
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, data, { headers: headers, params: { resource: resource } });
    //   }),
    //   map(result => new ActionDescription().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, instance: ActionDescription): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `action_descriptions/${id}/`);
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers, params: { resource: resource } });
    //   }),
    //   map(result => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}

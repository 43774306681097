<app-popup2 [size]="'xl'">
  <div class="marketplace">
    <ng-container *ngIf="loading">
      <div class="marketplace__header">
        <div class="marketplace__title">
          <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
        </div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <div class="marketplace-detail">
          <div class="marketplace-detail__main">
            <div class="marketplace-detail__gallery">
              <div class="marketplace-detail-gallery">
                <div class="marketplace-detail-gallery__image marketplace-detail-gallery__image_background"></div>
              </div>
            </div>
          </div>

          <div class="marketplace-detail__right">
            <div class="marketplace-detail__block marketplace-detail__block_first">
              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_first">
                <div class="marketplace-detail-header">
                  <div class="marketplace-detail-header__info">
                    <div class="marketplace-detail-header__title">
                      <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
                    </div>
                    <div class="marketplace-detail-header__subtitle">
                      <span [class.loading-animation]="true"><span class="stub-text">subtitle</span></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="marketplace-detail__block marketplace-detail__block_margin_s marketplace-detail__block_last">
                <div class="marketplace-detail-buttons">
                  <div
                    class="marketplace-button marketplace-button_primary marketplace-button_disabled marketplace-detail-buttons__item"
                  >
                    <span [class.loading-animation]="true"><span class="stub-text">button</span></span>
                  </div>

                  <div class="marketplace-button marketplace-detail-buttons__item marketplace-button_disabled">
                    <span [class.loading-animation]="true"><span class="stub-text">button</span></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="marketplace-detail__block marketplace-detail__block_last">
              <div class="marketplace-detail-info">
                <div class="marketplace-detail-info__title">
                  <span [class.loading-animation]="true"><span class="stub-text">label</span></span>
                </div>
                <div class="marketplace-detail-info__content">
                  <span [class.loading-animation]="true"><span class="stub-text">description</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && error">
      <div class="marketplace__header">
        <div class="marketplace__title">
          <div class="marketplace__title-item marketplace__title-item_link" (click)="back.emit()">
            Components marketplace
          </div>

          <div class="marketplace__title-arrow icon-arrow_forward_2"></div>

          <div *ngIf="id | appIsSet" class="marketplace__title-item">Update Component</div>
          <div *ngIf="!(id | appIsSet)" class="marketplace__title-item">Create Component</div>
        </div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <div class="table__empty">
          <app-error [title]="'Loading failed'" [compact]="true">
            <ng-container *ngIf="notFound">Component was not found</ng-container>
            <ng-container *ngIf="!notFound">{{ error }}</ng-container>
          </app-error>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!loading && !error">
      <div class="marketplace__header">
        <div class="marketplace__title">
          <div class="marketplace__title-item marketplace__title-item_link" (click)="openList.emit()">
            Components marketplace
          </div>

          <div class="marketplace__title-arrow icon-arrow_forward_2"></div>

          <ng-container *ngIf="id | appIsSet">
            <div class="marketplace__title-item marketplace__title-item_link" (click)="openDetail.emit(id)">
              {{ template.name }}
            </div>

            <div class="marketplace__title-arrow icon-arrow_forward_2"></div>

            <div class="marketplace__title-item">Update</div>
          </ng-container>

          <ng-container *ngIf="!(id | appIsSet)">
            <div class="marketplace__title-item">Create Component</div>
          </ng-container>
        </div>
        <div class="marketplace__close icon-close" (click)="close.emit()"></div>
      </div>

      <div class="marketplace__content">
        <form *ngIf="!loading && !notFound && !error" [formGroup]="form" (ngSubmit)="submit()">
          <div class="marketplace-change">
            <div class="marketplace-change__main">
              <app-page-block [size]="'m'" [paddingSize]="'none'">
                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="
                      createField({
                        name: 'name',
                        label: 'name',
                        field: 'CharField',
                        required: true,
                        params: { wide: true }
                      })
                    "
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="
                      createField({
                        name: 'short_description',
                        label: 'short description',
                        field: 'CharField',
                        required: true,
                        params: { wide: true }
                      })
                    "
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <div class="field">
                    <label class="field__label">
                      <span class="field__label-text">thumbnail</span>
                    </label>

                    <div class="field__value">
                      <app-auto-field
                        [form]="form"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'thumbnail',
                            label: 'thumbnail',
                            field: 'FileField',
                            params: {
                              path: 'custom_views/templates/thumbnails/',
                              storage_resource: form.getUploaderResource()?.uniqueName,
                              storage_name: form.getUploaderResourceStorage()?.uniqueName,
                              ignore_old_django_format: true,
                              editor: false,
                              fit: imageFits.Fit,
                              background: true
                            }
                          })
                        "
                        [class.field_content-fill-vertical]="true"
                      ></app-auto-field>
                    </div>
                    <div class="field__description">
                      Recommended size is 225x140, .png
                    </div>
                  </div>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="createField({ name: 'icon', label: 'icon', field: 'IconField', required: true })"
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="
                      createField({
                        name: 'icon_color',
                        label: 'icon color',
                        field: 'ColorField',
                        required: true,
                        params: { custom_colors: true }
                      })
                    "
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="
                      createField({
                        name: 'description',
                        label: 'description',
                        field: 'CharField',
                        params: { wide: true, multiline: true, rows: 4 }
                      })
                    "
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <div class="field">
                    <label class="field__label">
                      <span class="field__label-text">types</span>
                    </label>

                    <div class="field__value">
                      <app-select
                        [control]="form.controls.types"
                        [multiple]="true"
                        [options]="form.typeOptions"
                      ></app-select>
                    </div>
                  </div>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="createField({ name: 'ordering', label: 'ordering', required: true, field: 'NumberField' })"
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="createField({ name: 'featured', label: 'featured', field: 'BooleanField' })"
                  ></app-auto-field>
                </app-page-block>

                <app-page-block [marginSize]="'s'">
                  <app-auto-field
                    [form]="form"
                    [field]="createField({ name: 'active', label: 'active', required: true, field: 'BooleanField' })"
                  ></app-auto-field>
                </app-page-block>

                <app-page-block>
                  <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
                </app-page-block>
              </app-page-block>

              <app-page-block>
                <button type="submit" class="button button_primary" [disabled]="form.invalid || submitLoading">
                  <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
                  <ng-container *ngIf="template">Update component</ng-container>
                  <ng-container *ngIf="!template">Create component</ng-container>
                </button>
              </app-page-block>
            </div>
            <div class="marketplace-change__right">
              <div class="marketplace-change__gallery">
                <div class="marketplace-detail-gallery">
                  <div
                    *ngIf="templatePreview?.view"
                    class="marketplace-detail-gallery__image marketplace-detail-gallery__image_fixed"
                    [style.width.px]="templatePreview.view.frame.width"
                    [style.height.px]="templatePreview.view.frame.height"
                    [style.transform]="templatePreviewScale ? 'scale(' + templatePreviewScale + ')' : null"
                  >
                    <app-custom-element-view
                      class="marketplace-detail-gallery__image-inner"
                      [view]="templatePreview.view"
                      [params]="templatePreview.view.testParameters"
                    ></app-custom-element-view>
                  </div>

                  <div class="marketplace-detail-gallery__buttons">
                    <div class="marketplace-button marketplace-detail-gallery__buttons-item" (click)="customizeView()">
                      Customize with Designer
                    </div>
                  </div>
                </div>
              </div>

              <div class="marketplace-change__card">
                <div class="marketplace-change__card-inner">
                  <app-custom-view-templates-card
                    *ngIf="templatePreview"
                    [item]="templatePreview"
                  ></app-custom-view-templates-card>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
</app-popup2>

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { PopupService } from '@common/popups';
import { FeatureService } from '@modules/features';
import { CurrentEnvironmentStore, CurrentProjectStore, Environment, EnvironmentService } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { ProjectEnvironmentCreatePopupComponent } from '../../components/project-environment-create-popup/project-environment-create-popup.component';
import { ProjectEnvironmentMergePopupComponent } from '../../components/project-environment-merge-popup/project-environment-merge-popup.component';

@Injectable()
export class EnvironmentController implements OnDestroy {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private routing: RoutingService,
    private dialogService: DialogService,
    private environmentService: EnvironmentService,
    private featureService: FeatureService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {}

  createEnvironment(options: { source?: string } = {}): Observable<Environment> {
    if (!this.currentProjectStore.instance.features.isEnvironmentsEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build an application with <strong>Environments</strong>',
        description: `
          Test your app against a staging resource (database or API) and seamlessly switch it
          to production resources when in user mode.
        `
      });
      return;
    }

    const result = new ReplaySubject<Environment>();
    const currentEnvironment = this.currentEnvironmentStore.instance;

    this.popupService.push({
      component: ProjectEnvironmentCreatePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        source: options.source
      },
      outputs: {
        created: [
          environment => {
            this.routing.navigateApp(environment.link, undefined, { environmentName: environment.uniqueName });

            result.next(environment);

            this.mergeEnvironment({
              environmentFrom: currentEnvironment,
              environmentTo: environment,
              source: 'environment_create'
            })
              .pipe(untilDestroyed(this))
              .subscribe();
          }
        ]
      },
      injector: this.injector
    });

    return result;
  }

  mergeEnvironment(
    options: { environmentFrom?: Environment; environmentTo?: Environment; source?: string } = {}
  ): Observable<boolean> {
    const result = new ReplaySubject<boolean>();

    this.popupService.push({
      component: ProjectEnvironmentMergePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        environmentFrom: options.environmentFrom,
        environmentTo: options.environmentTo,
        source: options.source
      },
      outputs: {
        merged: [
          () => {
            result.next(true);
          }
        ]
      },
      injector: this.injector
    });

    return result;
  }

  deleteEnvironment(environment: Environment): Observable<boolean> {
    return this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this Environment from App?'
      })
      .pipe(
        filter(result => result == true),
        switchMap(() => this.environmentService.delete(this.currentProjectStore.instance.uniqueName, environment)),
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        map(() => {
          this.notificationService.success('Deleted', 'Environment was successfully deleted from App');

          const defaultEnvironment = this.currentProjectStore.instance.defaultEnvironment;

          if (defaultEnvironment) {
            this.routing.navigateApp(defaultEnvironment.link, undefined, {
              environmentName: defaultEnvironment.uniqueName
            });
          }

          return true;
        }),
        untilDestroyed(this)
      );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SingletonStore } from '@shared';
import { ProjectGroupService } from '../services/project-group/project-group.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var ProjectGroupStore = /** @class */ (function (_super) {
    __extends(ProjectGroupStore, _super);
    function ProjectGroupStore(currentProjectStore, currentEnvironmentStore, projectGroupService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectGroupService = projectGroupService;
        return _this;
    }
    ProjectGroupStore.prototype.fetch = function () {
        return this.projectGroupService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName);
    };
    return ProjectGroupStore;
}(SingletonStore));
export { ProjectGroupStore };

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectBillingService } from '@modules/projects';
var ProjectBillingDetailsPopupComponent = /** @class */ (function () {
    function ProjectBillingDetailsPopupComponent(popupComponent, currentProjectStore, currentEnvironmentStore, projectBillingService, cd) {
        this.popupComponent = popupComponent;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectBillingService = projectBillingService;
        this.cd = cd;
        this.loading = false;
    }
    ProjectBillingDetailsPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.cd.markForCheck();
        this.projectBillingService
            .getSubscriptionUsers(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {}, _a[PER_PAGE_PARAM] = 6, _a))
            .pipe(untilDestroyed(this))
            .subscribe(function (response) {
            _this.response = response;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.error = error.errors[0];
            }
            else {
                _this.error = error;
            }
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectBillingDetailsPopupComponent.prototype.ngOnDestroy = function () { };
    ProjectBillingDetailsPopupComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    return ProjectBillingDetailsPopupComponent;
}());
export { ProjectBillingDetailsPopupComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ActionItem, ActionType } from '@modules/actions';
import { CustomizeBarActionEditForm } from '../../../customize-bar-action-edit/customize-bar-action-edit.form';
// export interface QueryOption {
//   queryType: QueryType;
//   name?: string;
// }
//
// export const validateQuery: ValidatorFn = control => {
//   if (!control.parent) {
//     return;
//   }
//
//   if (!control.parent.controls['get_enabled'].value) {
//     return;
//   }
//
//   if (!control.parent.controls['resource'].value) {
//     return;
//   }
//
//   const query = control.value as ModelDescriptionQuery;
//
//   if (!query || !query.isConfigured()) {
//     return { required: true };
//   }
// };
//
// export const validateInputs: ValidatorFn = control => {
//   const parent = control.parent as ActionCustomizeWorkflowStepForm;
//
//   if (!parent) {
//     return;
//   }
//
//   if (!parent.value['get_enabled']) {
//     return;
//   }
//
//   const fields = parent.inputFieldProvider.fields;
//   const inputs: Input[] = control.value;
//
//   if (!isRequiredInputsSet(fields, inputs)) {
//     return { required: true };
//   }
// };
//
// export const validateColumns: ValidatorFn = control => {
//   if (!control.value || !control.value.length) {
//     return { required: true };
//   }
// };
//
// export const validateAction: AsyncValidatorFn = (control: CustomizeBarActionEditForm) => {
//   const parent = control.parent as ActionCustomizeWorkflowStepForm;
//
//   if (!parent) {
//     return of(null);
//   }
//
//   return parent.elementConfigurationService.isActionConfigured(control.submit().action).pipe(
//     map(configured => {
//       if (!configured) {
//         return { required: true };
//       }
//     })
//   );
// };
var ActionCustomizeWorkflowStepForm = /** @class */ (function (_super) {
    __extends(ActionCustomizeWorkflowStepForm, _super);
    function ActionCustomizeWorkflowStepForm(
    // private currentProjectStore: CurrentProjectStore,
    // private currentEnvironmentStore: CurrentEnvironmentStore,
    // protected resourceControllerService: ResourceControllerService,
    // private modelDescriptionStore: ModelDescriptionStore,
    // private actionService: ActionService,
    // private actionStore: ActionStore,
    // public elementConfigurationService: ElementConfigurationService,
    submitActionForm) {
        var _this = _super.call(this, {
            // generated: new FormControl(false),
            // children: new FormControl(undefined),
            name: new FormControl(''),
            // title: new FormControl(''),
            // resource: new FormControl(''),
            // model: new FormControl(''),
            // get_enabled: new FormControl(false),
            // get_query: new FormControl('', validateQuery),
            // get_columns: new FormControl([]),
            // columns: new FormControl([]),
            // parameters: new ParameterArray([]),
            // inputs: new FormControl([], validateInputs),
            action: submitActionForm,
            result_steps: new FormControl(false)
            // style: new FormControl(FormStyle.Wrap),
            // visible_input: new FieldInputControl({ name: 'value' }),
            // margin: new MarginControl()
        }) || this;
        _this.resultStepsActionTypes = [
            ActionType.Query,
            ActionType.Download,
            ActionType.RunJavaScript,
            ActionType.Export,
            ActionType.Import
        ];
        return _this;
        // this.controls.resource.valueChanges.pipe(delay(0)).subscribe(() => {
        //   this.controls.get_query.updateValueAndValidity();
        // });
        // this.controls.get_enabled.valueChanges.pipe(delay(0)).subscribe(() => {
        //   this.controls.inputs.updateValueAndValidity();
        // });
        // this.inputFieldProvider.fields$.subscribe(() => {
        //   this.controls.inputs.updateValueAndValidity();
        // });
    }
    // ngOnDestroy(): void {
    //   this.inputFieldProvider.clearProvider();
    // }
    // initObservers() {
    //   this.initModelObserver(this.controls.resource, this.controls.model, {
    //     queryControl: this.controls.get_query,
    //     parametersControl: this.controls.parameters,
    //     inputsControl: this.controls.inputs,
    //     getColumnsControl: this.controls.get_columns
    //   });
    //
    //   combineLatest(
    //     this.modelDescription$(this.controls.resource, this.controls.model),
    //     this.actionDescription$(this.controls.submit_enabled, this.controls.submit_action)
    //   )
    //     .pipe(skip(1), debounceTime(10))
    //     .subscribe(([modelDescription, actionDescription]) => {
    //       if (modelDescription) {
    //         const columns = modelDescription.fields
    //           .filter(item => item.type == ModelFieldType.Db)
    //           .filter(item => (item.item as ModelDbField).editable)
    //           .map(item => {
    //             const result = modelFieldToRawListViewSettingsColumn(item);
    //             return {
    //               ...result,
    //               required: !!item.item['required'],
    //               editable: true,
    //               flex: true,
    //               visible: true
    //             };
    //           });
    //
    //         this.controls.columns.patchValue(columns);
    //       } else if (actionDescription) {
    //         const columns = actionDescription.actionParams.map(item => {
    //           return {
    //             name: item.name,
    //             verboseName: item.verboseName,
    //             description: item.description,
    //             field: item.field,
    //             required: item.required,
    //             defaultType: item.defaultType,
    //             defaultValue: item.defaultValue,
    //             params: item.params,
    //             editable: true,
    //             flex: true,
    //             visible: true
    //           };
    //         });
    //
    //         this.controls.columns.patchValue(columns);
    //       } else {
    //         this.controls.columns.patchValue([]);
    //       }
    //
    //       if (actionDescription && !modelDescription) {
    //         const inputs = actionDescription.actionParams.map(item => {
    //           const input = new Input();
    //           input.name = item.name;
    //           input.valueType = InputValueType.Context;
    //           input.contextValue = [VALUE_OUTPUT, item.name].join('.');
    //           return input;
    //         });
    //
    //         this.controls.submit_action.controls.inputs.patchValue(inputs);
    //       } else if (
    //         actionDescription &&
    //         modelDescription &&
    //         actionDescription.resource == modelDescription.resource &&
    //         actionDescription.model == modelDescription.model
    //       ) {
    //         const inputs = actionDescription.actionParams
    //           .filter(item => this.controls.columns.value.find(i => i.name == item.name))
    //           .map(item => {
    //             const input = new Input();
    //             input.name = item.name;
    //             input.valueType = InputValueType.Context;
    //             input.contextValue = [VALUE_OUTPUT, item.name].join('.');
    //             return input;
    //           });
    //         this.controls.submit_action.controls.inputs.patchValue(inputs);
    //       } else {
    //         this.controls.submit_action.controls.inputs.patchValue([]);
    //       }
    //     });
    // }
    // initModelObserver(
    //   resourceControl: AbstractControl,
    //   queryControl: AbstractControl,
    //   options: {
    //     queryControl?: AbstractControl;
    //     parametersControl?: AbstractControl;
    //     inputsControl?: AbstractControl;
    //     getColumnsControl?: AbstractControl;
    //     modelControl?: AbstractControl;
    //   }
    // ) {
    //   resourceControl.valueChanges.subscribe(value =>
    //     this.onResourceChange(value, { modelControl: queryControl, inputsControl: options.inputsControl })
    //   );
    //
    //   combineLatest(controlValue<string>(resourceControl), queryControl.valueChanges)
    //     .pipe(debounceTime(0))
    //     .subscribe(([resourceName, model]) => {
    //       this.onModelChange(resourceName, model, {
    //         queryControl: options.queryControl,
    //         parametersControl: options.parametersControl,
    //         getColumnsControl: options.getColumnsControl
    //       });
    //     });
    //
    //   if (options.parametersControl) {
    //     options.parametersControl.valueChanges.subscribe(value => this.onParametersChange());
    //   }
    //
    //   if (options.queryControl) {
    //     options.queryControl.valueChanges.subscribe(value => this.onQueryChange());
    //   }
    //
    //   if (options.getColumnsControl) {
    //     options.getColumnsControl.valueChanges.subscribe(value => this.onColumnsChange());
    //   }
    // }
    ActionCustomizeWorkflowStepForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        // this.context = options.context;
        // const value = {
        //   generated: element.generated,
        //   name: element.name ? element.name : 'Form',
        //   title: element.title,
        //   resource: element.resource,
        //   model: element.getQuery
        //     ? {
        //         queryType: element.getQuery.queryType,
        //         name: element.getQuery.queryType == QueryType.Simple ? element.getQuery.simpleQuery.model : undefined
        //       }
        //     : undefined,
        //   get_enabled: element.getEnabled,
        //   get_query: element.getQuery,
        //   get_columns: element.getColumns,
        //   columns: element.columns,
        //   parameters: element.parameters,
        //   inputs: element.inputs,
        //   style: element.style || FormStyle.Wrap,
        //   visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
        //   margin: element.margin
        // };
        //
        // this.patchValue(value, { emitEvent: false });
        if (instance) {
            this.controls.name.patchValue(instance.name);
            this.controls.result_steps.patchValue(instance.resultSteps);
        }
        this.controls.action.init({
            actionItem: instance.action,
            actionItemClass: ActionItem,
            // modelDescriptionInContext: modelDescriptionInContext,
            approveEnabled: true,
            completionEditable: true,
            context: options.context
        }, options.firstInit);
        // this.initObservers();
        // this.updateInputFieldProvider().subscribe();
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    // enableGetQuery() {
    //   this.controls.get_enabled.patchValue(true);
    //
    //   if (this.controls.resource.value) {
    //     return;
    //   }
    //
    //   const actionResourceName = this.controls.submit_action.controls.resource.value;
    //   const actionOption = this.controls.submit_action.controls.action.value as ActionOption;
    //   const actionModelDescription = this.modelDescriptionStore.instance
    //     .filter(item => item.resource == actionResourceName)
    //     .find(item => actionOption && item.autoActions().some(action => action.uniqueName == actionOption.action));
    //
    //   if (actionResourceName) {
    //     this.controls.resource.patchValue(actionResourceName);
    //   }
    //
    //   if (actionModelDescription) {
    //     this.controls.model.patchValue({
    //       queryType: QueryType.Simple,
    //       name: actionModelDescription.model
    //     } as QueryOption);
    //   }
    // }
    // getFormParameters(
    //   actionResourceName: string,
    //   actionQueryType: QueryType,
    //   actionQueryName: string,
    //   actionParameters: ParameterField[],
    //   getResourceName: string,
    //   getQueryType: QueryType,
    //   getQueryName: string,
    //   getInputs: Input[]
    // ): { parameter: ParameterField; enabled: boolean; getQueryInput: boolean }[] {
    //   const actionUpdateModelDescription = this.modelDescriptionStore.instance
    //     .filter(item => item.resource == actionResourceName)
    //     .find(item => item.autoActionUniqueName('update') == actionQueryName);
    //
    //   const updateModelAction =
    //     getResourceName == actionResourceName &&
    //     actionQueryType == QueryType.Simple &&
    //     getQueryType == QueryType.Simple &&
    //     actionUpdateModelDescription &&
    //     actionUpdateModelDescription.model == getQueryName;
    //
    //   return actionParameters.map(parameter => {
    //     const getQueryInput = updateModelAction && getInputs.find(item => item.name == parameter.name && item.isSet());
    //
    //     return {
    //       parameter: parameter,
    //       enabled: !getQueryInput,
    //       getQueryInput: !!getQueryInput
    //     };
    //   });
    // }
    // generateForm() {
    //   const names = getContextElementNames(this.context);
    //   const children = [];
    //   const titleElement = new TextElementItem();
    //
    //   titleElement.generateUid();
    //   titleElement.name = generateElementName(titleElement, names);
    //   titleElement.quillDelta = editorJsOutputDataToQuillDelta({
    //     blocks: [
    //       {
    //         type: 'header',
    //         data: {
    //           text: 'Form',
    //           level: 1
    //         }
    //       }
    //     ]
    //   });
    //
    //   children.push(titleElement);
    //
    //   const actionOption = this.controls.submit_action.controls.action.value as ActionOption;
    //   const getOption = this.controls.model.value as QueryOption;
    //   const getQuery = this.controls.get_query.value as ModelDescriptionQuery;
    //
    //   const formParameters = this.getFormParameters(
    //     this.controls.submit_action.controls.resource.value,
    //     actionOption ? actionOption.queryType : undefined,
    //     actionOption ? actionOption.action : undefined,
    //     this.controls.submit_action.controls.action_params.value,
    //     this.controls.resource.value,
    //     getOption ? getOption.queryType : undefined,
    //     getOption ? getOption.name : undefined,
    //     this.controls.inputs.value
    //   );
    //
    //   const fieldElements = formParameters
    //     .filter(item => item.enabled)
    //     .map(item => {
    //       const element = new FieldElementItem();
    //
    //       element.generateUid();
    //       element.settings = {
    //         ...fieldsEditItemFromParameterField(item.parameter),
    //         verboseName: item.parameter.verboseName || item.parameter.name,
    //         editable: true
    //       };
    //       element.updateFormField();
    //       element.name = generateElementName(element, names);
    //
    //       if (getQuery && this.controls.get_columns.value.find(i => i.name == item.parameter.name)) {
    //         const input = new Input();
    //
    //         input.name = 'value';
    //         input.valueType = InputValueType.Context;
    //         input.contextValue = ['elements', this.instance.uid, ITEM_OUTPUT, item.parameter.name];
    //
    //         element.settings.valueInput = input.serialize();
    //       }
    //
    //       return element;
    //     });
    //
    //   children.push(...fieldElements);
    //
    //   const submitElement = new FormSubmitElementItem();
    //
    //   submitElement.generateUid();
    //   submitElement.verboseNameInput = this.controls.submit_action.controls.verbose_name.value
    //     ? new FieldInput().deserialize(this.controls.submit_action.controls.verbose_name.value)
    //     : undefined;
    //
    //   children.push(submitElement);
    //
    //   const submitInputs: Input[] = [];
    //
    //   submitInputs.push(
    //     ...formParameters
    //       .filter(item => item.getQueryInput)
    //       .map(item => {
    //         const input = new Input();
    //
    //         input.name = item.parameter.name;
    //         input.valueType = InputValueType.Context;
    //         input.contextValue = [ITEM_OUTPUT, item.parameter.name];
    //
    //         return input;
    //       })
    //   );
    //
    //   submitInputs.push(
    //     ...fieldElements.map(element => {
    //       const input = new Input();
    //
    //       input.name = element.settings.name;
    //       input.valueType = InputValueType.Context;
    //       input.contextValue = ['elements', element.uid, VALUE_OUTPUT];
    //
    //       return input;
    //     })
    //   );
    //
    //   this.controls.generated.patchValue(true);
    //   this.controls.submit_action.controls.inputs.patchValue(submitInputs);
    //   this.controls.children.patchValue(children);
    // }
    // setModelQuery(model: QueryOption, queryControl: AbstractControl) {
    //   const query = new ModelDescriptionQuery();
    //
    //   query.queryType = model ? model.queryType : undefined;
    //
    //   if (query.queryType == QueryType.Simple) {
    //     if (!query.simpleQuery) {
    //       query.simpleQuery = new query.simpleQueryClass();
    //     }
    //
    //     query.simpleQuery.model = model.name;
    //   }
    //
    //   queryControl.patchValue(query);
    // }
    //
    // setModelParams(
    //   resourceName: string,
    //   model: QueryOption,
    //   parametersControl: AbstractControl,
    //   columnsControl: AbstractControl
    // ) {
    //   const modelId = model ? [resourceName, model.name].join('.') : undefined;
    //
    //   this.modelDescriptionStore.getDetailFirst(modelId).subscribe(modelDescription => {
    //     const parameters = modelDescription ? modelDescription.getParameters : [];
    //     const columns = modelDescription
    //       ? modelDescription.fields
    //           .filter(item => item.type == ModelFieldType.Db)
    //           .map(item => {
    //             const result = modelFieldToRawListViewSettingsColumn(item);
    //             return {
    //               ...result,
    //               visible: true
    //             };
    //           })
    //       : [];
    //
    //     parametersControl.patchValue(parameters);
    //     columnsControl.patchValue(columns);
    //   });
    // }
    // updateInputFieldProvider() {
    //   return combineLatest(
    //     this.resource$(this.controls.resource),
    //     controlValue<QueryOption>(this.controls.model),
    //     this.modelDescription$(this.controls.resource, this.controls.model),
    //     controlValue<ParameterField[]>(this.controls.parameters),
    //     controlValue<ListModelDescriptionQuery>(this.controls.get_query),
    //     controlValue<RawListViewSettingsColumn[]>(this.controls.get_columns)
    //   ).pipe(
    //     first(),
    //     map(([resource, model, modelDescription, parameters, query, columns]): InputFieldProviderItem[] => {
    //       if (
    //         model &&
    //         model.queryType == QueryType.Simple &&
    //         modelDescription &&
    //         modelDescription.getDetailQuery &&
    //         !(modelDescriptionHasAutoParameters(resource, modelDescription) && !modelDescription.virtual)
    //       ) {
    //         return inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters);
    //       } else {
    //         return [
    //           ...parametersToProviderItems(parameters),
    //           ...inputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns)
    //         ];
    //       }
    //     }),
    //     tap(items => {
    //       this.inputFieldProvider.setProvider(items);
    //     })
    //   );
    // }
    // controlsValid$(form: FormGroup, controls: string[]): Observable<boolean> {
    //   return combineLatest(controls.map(item => controlValid(form.controls[item]))).pipe(
    //     map(result => result.every(item => item)),
    //     debounceTime(60)
    //   );
    // }
    //
    // submitActionOperationValid$(): Observable<boolean> {
    //   return this.controlsValid$(
    //     this.controls.submit_action,
    //     keys(this.controls.submit_action.controls).filter(key => key != 'inputs')
    //   );
    // }
    //
    // submitActionValid$(): Observable<boolean> {
    //   return this.controlsValid$(this.controls.submit_action, keys(this.controls.submit_action.controls));
    // }
    //
    // dataValid$(): Observable<boolean> {
    //   return this.controlsValid$(this, ['resource', 'get_query', 'inputs']);
    // }
    // resource$(control: AbstractControl): Observable<Resource> {
    //   return controlValue<string>(control).pipe(
    //     map(value => this.currentEnvironmentStore.resources.find(item => item.uniqueName == value))
    //   );
    // }
    //
    // modelDescription$(resourceControl: AbstractControl, modelControl: AbstractControl): Observable<ModelDescription> {
    //   return combineLatest(controlValue<string>(resourceControl), controlValue<{ name: string }>(modelControl)).pipe(
    //     switchMap(([resourceValue, modelValue]) => {
    //       if (!resourceValue || !modelValue || !modelValue.name) {
    //         return of(undefined);
    //       }
    //
    //       const modelId = [resourceValue, modelValue.name].join('.');
    //       return this.modelDescriptionStore.getDetail(modelId);
    //     })
    //   );
    // }
    //
    // resourceBaseHttpQuery$(control: AbstractControl): Observable<HttpQuery> {
    //   return this.resource$(control).pipe(
    //     map(resource => {
    //       if (!resource) {
    //         return undefined;
    //       }
    //
    //       const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
    //       return resourceParams.baseHttpQuery;
    //     })
    //   );
    // }
    //
    // getCustomQueryOption(name: string, queryType: QueryType): Option<QueryOption> {
    //   const option = new ModelDescriptionQuery();
    //
    //   option.queryType = queryType;
    //
    //   return {
    //     value: option,
    //     name: name,
    //     icon: 'plus'
    //   };
    // }
    //
    // getCustomQueryOptions(resource: Resource): Option<QueryOption>[] {
    //   const options: Option<QueryOption>[] = [];
    //
    //   if (isResourceCustom(resource)) {
    //     const controller = this.resourceControllerService.get(resource.type);
    //     const queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
    //     const queryType = editableQueryTypes.find(item => queryTypes.includes(item));
    //
    //     if (queryType) {
    //       const option = this.getCustomQueryOption(
    //         `Make ${getResourceTypeItemRequestName(resource.typeItem)}`,
    //         queryType
    //       );
    //       options.push(option);
    //     }
    //   }
    //
    //   if (resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync()) {
    //     const controller = this.resourceControllerService.get(ResourceType.JetBridge);
    //     const typeItem =
    //       resource.isSynced() || resource.hasCollectionSync()
    //         ? resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL)
    //         : resource.typeItem;
    //     const queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
    //     const queryType = editableQueryTypes.find(item => queryTypes.includes(item));
    //
    //     if (queryType) {
    //       const option = this.getCustomQueryOption(`Make ${getResourceTypeItemRequestName(typeItem)}`, queryType);
    //       options.push(option);
    //     }
    //   }
    //
    //   return options;
    // }
    //
    // resourceModelItems$(control: AbstractControl): Observable<CustomSelectItem<QueryOption>[]> {
    //   return combineLatest(this.resource$(control), this.modelDescriptionStore.get()).pipe(
    //     map(([resource, modelDescriptions]) => {
    //       if (!resource) {
    //         return [];
    //       }
    //
    //       const options: CustomSelectItem<QueryOption>[] = [];
    //
    //       if (modelDescriptions) {
    //         options.push(
    //           ...modelDescriptions
    //             .filter(item => item.resource == resource.uniqueName)
    //             .filter(
    //               item =>
    //                 !resource.demo ||
    //                 item.featured ||
    //                 (this.controls.model.value && this.controls.model.value['name'] == item.model)
    //             )
    //             .sort((lhs, rhs) => {
    //               return ascComparator(
    //                 String(lhs.verboseNamePlural).toLowerCase(),
    //                 String(rhs.verboseNamePlural).toLowerCase()
    //               );
    //             })
    //             .map(item => {
    //               return {
    //                 option: {
    //                   value: { queryType: QueryType.Simple, name: item.model },
    //                   name: item.verboseNamePlural,
    //                   icon: 'document'
    //                 }
    //               };
    //             })
    //         );
    //       }
    //
    //       const addModelComponents = !resource.demo ? getResourceAddModelComponents(resource.typeItem.name) : [];
    //
    //       options.push(
    //         ...addModelComponents.map(item => {
    //           return {
    //             button: {
    //               name: 'add_model',
    //               label: item.label,
    //               icon: item.icon,
    //               data: {
    //                 addModelComponent: item
    //               }
    //             },
    //             stickyBottom: true,
    //             orange: true,
    //             large: true
    //           };
    //         })
    //       );
    //
    //       options.push(
    //         ...this.getCustomQueryOptions(resource).map(item => {
    //           return {
    //             option: item,
    //             valueIcon: null,
    //             stickyBottom: true,
    //             orange: true,
    //             large: true
    //           };
    //         })
    //       );
    //
    //       return options;
    //     })
    //   );
    // }
    //
    // modelOption$(modelControl: AbstractControl): Observable<QueryOption> {
    //   return controlValue(modelControl);
    // }
    //
    // resourceActionOptions$(control: AbstractControl): Observable<Option<QueryOption>[]> {
    //   return combineLatest(this.resource$(control), this.actionStore.get()).pipe(
    //     map(([resource, actionDescriptions]) => {
    //       if (!resource) {
    //         return [];
    //       }
    //
    //       const options: Option<QueryOption>[] = [...this.getCustomQueryOptions(resource)];
    //
    //       if (actionDescriptions) {
    //         options.push(
    //           ...actionDescriptions
    //             .filter(item => item.resource == resource.uniqueName)
    //             .filter(item => item.type == ActionType.Query)
    //             .map(item => {
    //               return {
    //                 value: { queryType: QueryType.Simple, name: item.name },
    //                 name: item.verboseName
    //               };
    //             })
    //         );
    //       }
    //
    //       return options;
    //     })
    //   );
    // }
    //
    // actionDescription$(
    //   enabledControl: AbstractControl,
    //   actionControl: CustomizeBarActionEditForm
    // ): Observable<ActionDescription> {
    //   return combineLatest(
    //     controlValue<boolean>(enabledControl),
    //     controlValue(actionControl.controls.type),
    //     controlValue(actionControl.controls.resource),
    //     controlValue(actionControl.controls.query),
    //     controlValue(actionControl.controls.action_params),
    //     controlValue(actionControl.controls.export_data_source),
    //     controlValue(actionControl.controls.element_action)
    //   ).pipe(
    //     debounceTime(10),
    //     map(([enabled]) => {
    //       if (!enabled) {
    //         return;
    //       }
    //
    //       return this.controls.submit_action.submit().action;
    //     }),
    //     switchMap(action => {
    //       if (!action) {
    //         return of(undefined);
    //       }
    //
    //       return this.actionService.getActionDescription(action);
    //     })
    //   );
    // }
    //
    // queryOptionEquals(lhs: QueryOption, rhs: QueryOption) {
    //   const lhsQueryType = lhs ? lhs.queryType : undefined;
    //   const lhsName = lhs && lhs.name ? lhs.name : undefined;
    //   const rhsQueryType = rhs ? rhs.queryType : undefined;
    //   const rhsName = rhs && rhs.name ? rhs.name : undefined;
    //
    //   return lhsQueryType == rhsQueryType && lhsName == rhsName;
    // }
    //
    // setQuery(queryName: string, query: Query) {
    //   this.patchValue({
    //     [queryName]: query
    //   });
    //   this.markAsDirty();
    // }
    //
    // resetGetQuery() {
    //   this.controls.get_enabled.patchValue(false);
    //   this.controls.resource.patchValue(undefined);
    //   this.controls.model.patchValue(undefined);
    //   this.controls.get_query.patchValue(undefined);
    //   this.controls.inputs.patchValue([]);
    //   this.markAsDirty();
    // }
    //
    // setGetQuery(query: Query, columns: RawListViewSettingsColumn[]) {
    //   this.setQuery('get_query', query);
    //
    //   const prevColumns = this.value['get_columns'] as RawListViewSettingsColumn[];
    //
    //   this.patchValue({
    //     get_columns: columns.map(item => {
    //       const existingColumn = prevColumns ? prevColumns.find(i => i.name == item.name) : undefined;
    //       return existingColumn || item;
    //     })
    //   });
    //   this.markAsDirty();
    // }
    //
    // onResourceChange(resourceName, options: { modelControl?: AbstractControl; inputsControl?: AbstractControl }) {
    //   const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    //
    //   if (options.modelControl) {
    //     let modelOption: QueryOption;
    //     const firstModel = this.modelDescriptionStore.instance.find(model => {
    //       if (model.resource != resourceName) {
    //         return false;
    //       } else if (model.getParameters.filter(item => item.required).length) {
    //         return false;
    //       }
    //
    //       if (resource.demo) {
    //         return model.featured;
    //       }
    //
    //       return true;
    //     });
    //
    //     if (firstModel) {
    //       modelOption = {
    //         queryType: QueryType.Simple,
    //         name: firstModel.model
    //       };
    //     }
    //
    //     if (!modelOption && resource) {
    //       const customOption = this.getCustomQueryOptions(resource)[0];
    //
    //       if (customOption) {
    //         modelOption = customOption.value;
    //       }
    //     }
    //
    //     options.modelControl.patchValue(modelOption);
    //   }
    // }
    //
    // onModelChange(
    //   resourceName: string,
    //   option: QueryOption,
    //   options: {
    //     queryControl?: AbstractControl;
    //     parametersControl?: AbstractControl;
    //     getColumnsControl?: AbstractControl;
    //   }
    // ) {
    //   if (options.queryControl) {
    //     this.setModelQuery(option, options.queryControl);
    //   }
    //
    //   if (options.getColumnsControl) {
    //     this.setModelParams(resourceName, option, options.parametersControl, options.getColumnsControl);
    //   }
    //
    //   this.onModelParamsChange();
    // }
    //
    // onParametersChange() {
    //   this.onModelParamsChange();
    // }
    //
    // onQueryChange() {
    //   this.onModelParamsChange();
    // }
    //
    // onColumnsChange() {
    //   this.onModelParamsChange();
    // }
    //
    // onModelParamsChange() {
    //   setTimeout(() => {
    //     this.updateInputFieldProvider().subscribe();
    //   }, 0);
    // }
    //
    // isConfigured(instance: FormElementItem): Observable<boolean> {
    //   return this.elementConfigurationService.isFormConfigured(instance, { restrictDemo: true });
    // }
    ActionCustomizeWorkflowStepForm.prototype.submit = function () {
        // const value = this.value;
        var instance = cloneDeep(this.instance);
        // instance.generated = value['generated'];
        // instance.name = value['name'];
        // instance.title = value['title'];
        // instance.resource = value['resource'];
        // instance.getEnabled = value['get_enabled'] && value['resource'];
        // instance.getQuery = value['get_query'];
        // instance.getColumns = value['get_columns'];
        // instance.columns = value['columns'];
        // instance.parameters = value['parameters'];
        // instance.inputs = value['inputs'];
        // instance.style = value['style'];
        instance.name = this.controls.name.value;
        var action = this.controls.action.submit().action;
        if (action) {
            instance.action = action;
        }
        else {
            instance.action = undefined;
        }
        instance.resultSteps = this.controls.result_steps.value;
        // if (value['visible_input']) {
        //   instance.visibleInput = new Input().deserialize(value['visible_input']);
        // } else {
        //   instance.visibleInput = undefined;
        // }
        //
        // instance.margin = value['margin'];
        //
        // if (value['children']) {
        //   instance.children = value['children'];
        //   this.controls.children.patchValue(null, { emitEvent: false });
        // }
        return instance;
    };
    return ActionCustomizeWorkflowStepForm;
}(FormGroup));
export { ActionCustomizeWorkflowStepForm };

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { delayWhen, filter, tap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettingsStore } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { isResourceCustom } from '@modules/resources';
import { RoutingService } from '@modules/routing';

import { CreateResourcePopupComponent } from '../../components/create-resource-popup/create-resource-popup.component';
import { TemplateApplyService } from '../template-apply/template-apply.service';

export interface ResourceCreateControllerCreateResult {
  resource?: Resource;
  cancelled?: boolean;
}

@Injectable()
export class ResourceCreateController {
  constructor(
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private viewSettingsStore: ViewSettingsStore,
    private viewSettingsQueries: ViewSettingsQueries,
    private templateApplyService: TemplateApplyService,
    private dialogService: DialogService,
    private routing: RoutingService,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  create(options: { analyticsSource?: string } = {}): Observable<ResourceCreateControllerCreateResult> {
    const obs$ = new ReplaySubject<ResourceCreateControllerCreateResult>();

    this.popupService.push({
      component: CreateResourcePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        analyticsSource: options.analyticsSource
      },
      outputs: {
        created: [
          result => {
            obs$.next({ resource: result });
          }
        ],
        cancelled: [
          () => {
            obs$.next({ cancelled: true });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$.pipe(
      filter(result => !!result.resource),
      tap(result => {
        // this.notificationService.success('Created', 'Resource was successfully added to current App');
        this.routing.navigateApp(result.resource.link);
      }),
      delayWhen(createResult => {
        if (isResourceCustom(createResult.resource) || createResult.resource.isSynced()) {
          return of(undefined);
        }

        const project = this.currentProjectStore.instance;
        const environment = this.currentEnvironmentStore.instance;

        return this.templateApplyService
          .applyAdminPanelTemplate(project, environment, createResult.resource.typeItem, {
            resource: createResult.resource,
            optional: true
          })
          .pipe(
            tap(result => {
              if (result.startPage) {
                this.routing.navigateApp(result.startPage.link);
              } else if (result.newPage) {
                this.routing.navigateApp(this.currentProjectStore.instance.newPageLink);
              }
            })
          );
      })
    );
  }
}

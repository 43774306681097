import { ChangeDetectorRef, EventEmitter, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplateListStore, customViewTemplateTypeItems, Frame, View } from '@modules/views';
import { isSet } from '@shared';
import { ViewEditorController } from '../../services/view-editor-controller/view-editor.controller';
export var FEATURED_TOKEN = new InjectionToken('FEATURED_TOKEN');
export var LIST_TOKEN = new InjectionToken('LIST_TOKEN');
var CustomViewTemplatesListComponent = /** @class */ (function () {
    function CustomViewTemplatesListComponent(featuredStore, listStore, currentUserStore, viewEditorController, cd) {
        this.featuredStore = featuredStore;
        this.listStore = listStore;
        this.currentUserStore = currentUserStore;
        this.viewEditorController = viewEditorController;
        this.cd = cd;
        this.title = 'Components marketplace';
        this.initialFilter = {};
        this.nameEditingEnabled = true;
        this.viewCreateEnabled = true;
        this.stateSelectedEnabled = false;
        this.componentLabel = 'component';
        this.selectTemplate = new EventEmitter();
        this.createdView = new EventEmitter();
        this.close = new EventEmitter();
        this.loading = false;
        this.moreLoading = false;
        this.error = false;
        this.filters = [
            {
                label: 'All'
            }
        ].concat(customViewTemplateTypeItems.map(function (item) {
            return {
                type: item.type,
                label: item.label,
                icon: item.icon
            };
        }), [
            {
                active: false,
                label: 'Disabled',
                icon: 'close',
                staff: true
            }
        ]);
        this.perRow = 4;
    }
    CustomViewTemplatesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.initialFilter.type) {
            this.filterByTemplateType(this.initialFilter.type);
        }
        if (!this.currentFilterItem) {
            this.currentFilterItem = this.filters[0];
        }
        this.featuredStore.featured = true;
        this.featuredStore.perPage = 16;
        this.featuredItems$ = this.featuredStore.items$;
        this.featuredLoading$ = this.featuredStore.loading$;
        this.listStore.featured = false;
        this.listStore.perPage = 16;
        this.listItems$ = this.listStore.items$;
        this.listLoading$ = this.listStore.loading$;
        this.fetch();
        var scrollContainer = this.getScrollContainer();
        if (scrollContainer) {
            fromEvent(scrollContainer, 'scroll')
                .pipe(filter(function () { return !_this.loading && !_this.moreLoading; }), untilDestroyed(this))
                .subscribe(function () {
                var scrollTop = scrollContainer.scrollTop;
                var viewportHeight = window.innerHeight;
                var viewportBottom = scrollTop + viewportHeight;
                var contentHeight = scrollContainer.scrollHeight;
                if (contentHeight - viewportBottom <= 100) {
                    _this.loadMore();
                }
            });
        }
    };
    CustomViewTemplatesListComponent.prototype.ngOnDestroy = function () { };
    CustomViewTemplatesListComponent.prototype.getScrollContainer = function () {
        return document.querySelector('.popup-container');
    };
    CustomViewTemplatesListComponent.prototype.setCurrentFilter = function (filterItem, force) {
        if (force === void 0) { force = false; }
        if (this.currentFilterItem === filterItem && !force) {
            return;
        }
        this.currentFilterItem = filterItem;
        this.cd.markForCheck();
        this.fetch();
    };
    CustomViewTemplatesListComponent.prototype.filterByTemplateType = function (type) {
        var filterItem = this.filters.find(function (item) { return item.type == type; });
        if (filterItem) {
            this.setCurrentFilter(filterItem, true);
        }
    };
    CustomViewTemplatesListComponent.prototype.fetch = function () {
        var _this = this;
        this.featuredStore.type = this.currentFilterItem ? this.currentFilterItem.type : undefined;
        if (this.currentFilterItem && isSet(this.currentFilterItem.active)) {
            this.featuredStore.all = true;
            this.featuredStore.active = this.currentFilterItem.active;
        }
        else {
            this.featuredStore.all = false;
            this.featuredStore.active = undefined;
        }
        this.listStore.type = this.currentFilterItem ? this.currentFilterItem.type : undefined;
        if (this.currentFilterItem && isSet(this.currentFilterItem.active)) {
            this.listStore.all = true;
            this.listStore.active = this.currentFilterItem.active;
        }
        else {
            this.listStore.all = false;
            this.listStore.active = undefined;
        }
        this.featuredStore.reset();
        this.listStore.reset();
        this.loading = true;
        this.moreLoading = false;
        this.error = false;
        this.cd.markForCheck();
        combineLatest(this.featuredStore.getNext(), this.listStore.getNext())
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.error = true;
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewTemplatesListComponent.prototype.loadMore = function () {
        var _this = this;
        if (!this.listStore.hasMore) {
            return;
        }
        this.moreLoading = true;
        this.cd.markForCheck();
        this.listStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.moreLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.moreLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomViewTemplatesListComponent.prototype.createView = function () {
        var _this = this;
        var view = new View();
        view.generateId();
        view.name = 'New View';
        view.frame = new Frame({ width: 300, height: 240 });
        this.viewEditorController
            .open({
            create: true,
            view: view,
            componentLabel: this.componentLabel,
            nameEditingEnabled: this.nameEditingEnabled,
            stateSelectedEnabled: this.stateSelectedEnabled,
            submitLabel: 'Create ' + this.componentLabel,
            templatesEnabled: false,
            analyticsSource: 'view_templates_list'
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.createdView.emit(result.view);
        });
    };
    return CustomViewTemplatesListComponent;
}());
export { CustomViewTemplatesListComponent };

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { WidgetComponent } from '../widget/widget.component';

@Component({
  selector: 'app-unknown-widget',
  templateUrl: './unknown-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnknownWidgetComponent extends WidgetComponent implements OnInit {
  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { FirebaseGeneratorService } from '@modules/resource-generators';
import { FirebaseDatabaseType } from '@modules/resources';
import { isSet } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var FirebaseResourceSettingsForm = /** @class */ (function (_super) {
    __extends(FirebaseResourceSettingsForm, _super);
    function FirebaseResourceSettingsForm(firebaseGeneratorService, http, analyticsService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.firebaseGeneratorService = firebaseGeneratorService;
        _this.http = http;
        _this.analyticsService = analyticsService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({
            service_token: new FormControl(null, [Validators.required, _this.validateServiceToken()]),
            project_id: new FormControl(null, Validators.required),
            access_token: new FormControl(null, Validators.required),
            database_option: new FormControl(null, Validators.required),
            sync: new FormControl(true)
        });
        _this.form.controls['service_token'].valueChanges
            .pipe(switchMap(function (value) {
            return _this.firebaseGeneratorService.readServiceToken(value).pipe(catchError(function () { return of(undefined); }));
        }))
            .subscribe(function (result) {
            _this.form.patchValue(result
                ? {
                    project_id: result.projectId,
                    access_token: result.accessToken
                }
                : {
                    project_id: '',
                    access_token: ''
                });
        });
        return _this;
    }
    FirebaseResourceSettingsForm.prototype.validateServiceToken = function () {
        return function (control) {
            if (!isSet(control.value)) {
                return;
            }
            var group = control.parent;
            if (!group) {
                return;
            }
            if (group.value['service_token'] && (!group.value['project_id'] || !group.value['access_token'])) {
                return { local: ['Service key is not valid or not enough permissions'] };
            }
        };
    };
    FirebaseResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.firebaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.form.patchValue({
                service_token: result.serviceToken,
                project_id: result.projectId,
                access_token: result.accessToken,
                database_option: result.databaseOption || {
                    type: FirebaseDatabaseType.Firestore
                },
                sync: !!_this.resource.isSynced()
            });
        }));
    };
    FirebaseResourceSettingsForm.prototype.getOptions = function () {
        return {
            serviceToken: this.form.value['service_token'].trim(),
            projectId: this.form.value['project_id'],
            accessToken: this.form.value['access_token'],
            databaseOption: this.form.value['database_option']
        };
    };
    FirebaseResourceSettingsForm.prototype.databaseOptionEquals = function (lhs, rhs) {
        if (lhs && rhs) {
            return lhs.type == lhs.type && rhs.id == rhs.id;
        }
        else if (!lhs && !rhs) {
            return true;
        }
        else {
            return false;
        }
    };
    FirebaseResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        var options = this.getOptions();
        return this.firebaseGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'], sync: _this.form.value['sync'], syncModelDescriptions: _this.getParamsSyncModelDescriptions(result) });
        }), catchError(function (error) {
            if (!_this.resource) {
                var url = "https://firebasedatabase.googleapis.com/v1beta/projects/" + options.projectId + "/locations/-/instances";
                var headers_1 = { Authorization: "Bearer " + options.accessToken };
                var errorMessage_1 = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
                _this.http
                    .get(url, { headers: headers_1 })
                    .pipe(switchMap(function (result) {
                    if (!result['instances'].length) {
                        return of([]);
                    }
                    return combineLatest(result['instances'].map(function (item) {
                        var databaseUrl = item['databaseUrl'] + "/.json";
                        return _this.http.get(databaseUrl, { headers: headers_1 });
                    }));
                }))
                    .subscribe(function (databases) {
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
                        ResourceID: _this.typeItem.name,
                        Error: errorMessage_1,
                        Options: options,
                        ResourceContents: databases
                    });
                }, function (databaseError) {
                    var databaseErrorMessage = databaseError instanceof ServerRequestError && databaseError.errors.length
                        ? databaseError.errors[0]
                        : databaseError;
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
                        ResourceID: _this.typeItem.name,
                        Error: errorMessage_1,
                        Options: options,
                        ResourceContents: databaseErrorMessage
                    });
                });
            }
            return throwError(error);
        }));
    };
    return FirebaseResourceSettingsForm;
}(BaseResourceSettingsForm));
export { FirebaseResourceSettingsForm };

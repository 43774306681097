<div class="list-header" [class.list__header-inner_no-bottom-margin]="noBottomMargin">
  <div class="list-header__row">
    <div class="list-header__main" appElementResize (elementResize)="updateCompact()" #main_element>
      <h1 class="list__title">
        <span class="list__title-text">{{ title | appCapitalize }}</span>

        <span
          *ngIf="settings.tooltip | appIsSet"
          class="list__title-help help-icon icon-help"
          [appTip]="settings.tooltip"
          [appTipOptions]="{ side: 'top' }"
        ></span>

        <!--        <ng-container *ngIf="settings.title && moreLink">-->
        <!--          <a [appLink]="moreLink.link" [queryParams]="moreLink.queryParams" [appTip]="moreLink.tip">-->
        <!--            <span *ngIf="moreLink.icon" class="icon" [ngClass]="['icon-' + moreLink.icon]"></span>-->
        <!--            <ng-container *ngIf="moreLink.label">{{ moreLink.label }}</ng-container>-->
        <!--          </a>-->
        <!--        </ng-container>-->

        <!--        <div *ngIf="settings.title && sublinks?.length" class="dropdown-anchor">-->
        <!--          <span class="list__title-icon icon icon-arrow_down_2"></span>-->
        <!--          <div class="dropdown dropdown_position_bottom-right">-->
        <!--            <div class="dropdown__inner">-->
        <!--              <a href="javascript:void(0)" class="dropdown__item" (click)="resetParams()">-->
        <!--                <span class="dropdown__item-label">{{ 'All' | localize }}</span>-->
        <!--              </a>-->
        <!--              <a-->
        <!--                *ngFor="let item of sublinks"-->
        <!--                class="dropdown__item"-->
        <!--                [appLink]="item.link"-->
        <!--                [queryParams]="item.queryParams"-->
        <!--                [routerLinkActive]="['dropdown__item_selected']"-->
        <!--                [routerLinkActiveOptions]="{ exact: true }"-->
        <!--              >-->
        <!--                <span class="dropdown__item-label">{{ item.title | appCapitalize }}</span>-->
        <!--              </a>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </h1>
    </div>
    <div class="list-header__right list__actions">
      <app-list-actions
        [listTitle]="title"
        [dataSource]="dataSource"
        [queryParams]="params"
        [filtersEnabled]="filtersEnabled && hasFilterableColumns"
        [filters]="filters"
        [filterFields]="filterFields"
        [layouts]="true"
        [searchEnabled]="searchEnabled"
        [search]="search"
        [searchLive]="searchLive"
        [inputs]="inputs"
        [parameters]="parameters"
        [focus]="focus"
        [columns]="settings.dataSource?.columns"
        [actions]="settings.actions"
        [compactActions]="compactActions"
        [context]="context"
        [contextElement]="contextElement"
        [accentColor]="accentColor"
        (layoutAdd)="layoutAdd.emit($event)"
        (layoutCustomize)="layoutCustomize.emit($event)"
        (paramsChanged)="paramsChanged.emit($event)"
        (filtersUpdated)="filtersUpdated.emit($event)"
        (searchChanged)="searchUpdated.emit($event)"
      >
      </app-list-actions>

      <ng-content select="ng-container[actions-right]"></ng-content>
    </div>
  </div>

  <div *ngIf="filtersEnabled && dataSource && filters.length" class="list-header__row list-header__row_second">
    <app-filters-list
      [filters]="filters"
      [dataSource]="dataSource"
      [bright]="true"
      [context]="context"
      [contextElement]="contextElement"
      (filtersUpdated)="filtersUpdated.emit($event)"
    ></app-filters-list>
  </div>
</div>

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UniqueIdToken } from '@common/unique-id';
import { ApiType, ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, deserializeSingleFileValue, ImageOutputFormat, Input as FieldInput } from '@modules/fields';
import { controlValue, getExtension, getFilename, getFilenameWithExtension, isSet, openUrl } from '@shared';
import { cropFormats } from '../image-field/image-field.crop';
export var VALUE_OUTPUT = 'value';
export var UPLOAD_RESULT_OUTPUT = 'upload_result';
export var FILE_NAME_OUTPUT = 'file_name';
export var FILE_EXTENSION_OUTPUT = 'file_extension';
var BaseUploadComponent = /** @class */ (function () {
    function BaseUploadComponent(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceControllerService = resourceControllerService;
        this.storageService = storageService;
        this.cd = cd;
        this.autofocus = false;
        this.disabled = false;
        this.background = false;
        this.resize = false;
        this.editor = true;
        this.compact = false;
        this.uploadedUpdated = new EventEmitter();
        this.idToken = new UniqueIdToken();
        this.storage$ = new BehaviorSubject(undefined);
        this.storageResource$ = new BehaviorSubject(undefined);
    }
    BaseUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.storageResource$, controlValue(this.control))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var storageResource = _a[0], value = _a[1];
            value = deserializeSingleFileValue(value);
            value = _this.getUploadUrl(value, storageResource);
            _this.uploadedFile = _this.getUploadedFileFromValue(value);
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
        });
    };
    BaseUploadComponent.prototype.ngOnDestroy = function () { };
    BaseUploadComponent.prototype.ngOnChanges = function (changes) {
        if (changes['storageResourceName'] || changes['storageName']) {
            this.updateStorage();
        }
    };
    BaseUploadComponent.prototype.getUploadUrl = function (value, storageResource) {
        if (!isSet(value)) {
            return;
        }
        // const apiInfo = this.getApiInfo();
        var apiInfo = storageResource ? storageResource.apiInfo : undefined;
        if (apiInfo &&
            apiInfo.type == ApiType.JetDjango &&
            apiInfo.versionLessThan('0.7.7')
        // !this.field.params['ignore_old_django_format']
        ) {
            value = value['value'];
        }
        if (this.outputFormat == ImageOutputFormat.Storage) {
            var controller = storageResource ? this.resourceControllerService.get(storageResource.type) : undefined;
            if (controller && controller.fileUrl) {
                return controller.fileUrl(storageResource, value);
            }
        }
        return value;
    };
    BaseUploadComponent.prototype.updateStorage = function () {
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, this.storageResourceName, this.storageName, { defaultFirst: true });
        this.storageResource$.next(storage ? storage.resource : undefined);
        this.storage$.next(storage ? storage.storage : undefined);
    };
    BaseUploadComponent.prototype.getUploadedFileFromValue = function (value) {
        if (!value) {
            return;
        }
        return {
            url: value,
            filename: getFilenameWithExtension(value),
            extension: getExtension(value)
        };
        // if (this.contextElement) {
        //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.preview));
        //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
        // }
        //   },
        //   () => {
        //     this.preview = undefined;
        //     this.previewFilename = undefined;
        //     this.previewExtension = undefined;
        //     this.cd.markForCheck();
        //     this.onPreviewUpdate(this.preview);
        //
        //     if (this.contextElement) {
        //       this.contextElement.setOutputValue(FILE_NAME_OUTPUT, this.previewFilename);
        //       this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
        //     }
        //   }
        // );
    };
    BaseUploadComponent.prototype.onUploadedUpdated = function () { };
    // isUploadable() {
    //   return (
    //     (!this.field.params['output_format'] || this.field.params['output_format'] == ImageOutputFormat.Storage) &&
    //     !this.field.params['external']
    //   );
    // }
    BaseUploadComponent.prototype.isUploadable = function () {
        return this.outputFormat != ImageOutputFormat.URL;
    };
    BaseUploadComponent.prototype.clearCurrentValue = function () {
        if (this.control) {
            this.control.patchValue(null);
        }
    };
    BaseUploadComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.onFileSelected(file);
    };
    BaseUploadComponent.prototype.getUploadPath = function (file) {
        var _a;
        if (this.path) {
            try {
                var result = applyParamInput(this.path, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[FILE_NAME_OUTPUT] = getFilename(file.name),
                        _a[FILE_EXTENSION_OUTPUT] = getExtension(file.name),
                        _a),
                    defaultValue: ''
                });
                if (isSet(result)) {
                    result = String(result);
                }
                return result;
            }
            catch (e) {
                return '';
            }
            // } else if (this.context['modelDescription']) {
            //   return [this.context['modelDescription'].model, this.field.name].join('/');
            // } else if (this.context['action']) {
            //   return ['actions', this.context['action'], this.field.name].join('/');
        }
        else {
            return '';
        }
    };
    BaseUploadComponent.prototype.onFileSelected = function (file) {
        var path = this.getUploadPath(file);
        this.uploadFile(file, path);
    };
    BaseUploadComponent.prototype.uploadFile = function (file, path) {
        var _this = this;
        this.uploadedFile = undefined;
        this.uploadFileSize = file.size;
        this.uploadProgress = 0;
        this.uploadError = undefined;
        this.cd.markForCheck();
        this.onUploadedUpdated();
        var storageResource = this.storageResource$.value;
        var storage = this.storage$.value;
        if (!storageResource || !storage) {
            return;
        }
        this.storageService
            .upload(storageResource, storage, storage.uploadQuery, file, path)
            .pipe(untilDestroyed(this))
            .subscribe(function (response) {
            if (!response) {
                _this.uploadProgress = undefined;
                _this.cd.markForCheck();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
                }
                return;
            }
            if (response.result) {
                var value = void 0;
                // if (this.form) {
                // const params = {};
                // const apiInfo = this.getApiInfo();
                var apiInfo = storageResource ? storageResource.apiInfo : undefined;
                if (apiInfo &&
                    apiInfo.type == ApiType.JetDjango &&
                    apiInfo.versionLessThan('0.7.7')
                // !this.field.params['ignore_old_django_format']
                ) {
                    value = {
                        value: response.result.uploadedPath,
                        url: response.result.uploadedUrl
                    };
                }
                else {
                    value = response.result.uploadedUrl;
                }
                if (_this.control) {
                    _this.control.patchValue(value);
                }
                // }
                _this.uploadProgress = undefined;
                _this.cd.markForCheck();
                _this.uploadedFile = _this.getUploadedFileFromValue(value);
                _this.cd.markForCheck();
                _this.onUploadedUpdated();
                _this.uploadedUpdated.next();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, response.result.response ? response.result.response.body : undefined);
                }
            }
            else {
                _this.uploadProgress = response.state.uploadProgress * 0.95 + response.state.downloadProgress * 0.05;
                _this.cd.markForCheck();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
                }
            }
        }, function (error) {
            _this.uploadProgress = undefined;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                _this.uploadError = error.nonFieldErrors[0];
            }
            else {
                _this.uploadError = 'Unknown error';
            }
            _this.cd.markForCheck();
            if (_this.contextElement) {
                _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }
        });
    };
    BaseUploadComponent.prototype.download = function () {
        openUrl(this.uploadedFile.url, true);
    };
    return BaseUploadComponent;
}());
export { BaseUploadComponent };

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';

import { isSet, KeyboardEventKeyCode, trimAll } from '@shared';

@Component({
  selector: 'app-editable-content',
  templateUrl: './editable-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableContentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value: string;
  @Input() emptyValue: string;
  @Input() defaultValue: string;
  @Input() placeholder = '';
  @Input() pen = false;
  @Input() penHover = false;
  @Input() penTitle = 'Edit';
  @Input() bin = false;
  @Input() binTip: string;
  @Input() inline = false;
  @Input() multiline = true;
  @Input() activeTextColor = true;
  @Input() outline = true;
  @Input() autogrow = true;
  @Input() cursorPointer = false;
  @Input() cleanValue: (value: string) => string;
  @Input() immediateUpdate = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();

  @ViewChild(CdkTextareaAutosize) autosize: CdkTextareaAutosize;

  currentValue: string;
  editing = false;

  constructor(private cd: ChangeDetectorRef, private zone: NgZone) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  startEditing() {
    this.currentValue = this.value;
    this.editing = true;
    this.cd.markForCheck();
    this.onEditingBegin();
  }

  finishEditing(save = true) {
    this.editing = false;
    this.cd.markForCheck();

    if (save) {
      this.onEditingFinished();
    } else {
      this.currentValue = this.value;
    }
  }

  onEditingBegin() {
    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.autosize) {
          this.autosize.resizeToFitContent(true);
        }
      });
  }

  clean(value: string): string {
    value = trimAll(value);

    if (this.cleanValue) {
      value = this.cleanValue(value);
    }

    return value;
  }

  onEditingFinished() {
    this.currentValue = this.clean(this.currentValue);

    if (!isSet(this.currentValue) && this.defaultValue !== undefined) {
      this.currentValue = this.defaultValue || this.value;
    }

    this.cd.markForCheck();

    if (this.value != this.currentValue) {
      this.valueChange.emit(this.currentValue);
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishEditing();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.finishEditing(false);
    } else if (this.immediateUpdate) {
      if (this.value != this.currentValue) {
        this.valueChange.emit(this.currentValue);
      }
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  onDelete(event: MouseEvent): void {
    event.stopPropagation();
    this.delete.emit();
  }
}

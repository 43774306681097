import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { defaultSignUpLayout } from '@modules/domain';
import { CurrentUserStore, UserService } from '@modules/users';
import { isSet } from '@shared';
var VerifyEmailComponent = /** @class */ (function () {
    function VerifyEmailComponent(currentUserStore, router, activatedRoute, userService, notificationService, analyticsService, cd) {
        this.currentUserStore = currentUserStore;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.userService = userService;
        this.notificationService = notificationService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.loading = false;
        this.authorized = false;
        this.emailVerified = false;
        this.correctRequest = true;
        this.resent = false;
        this.domainLoading = false;
        this.defaultLayout = defaultSignUpLayout;
        this.externalFonts = [];
    }
    VerifyEmailComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([this.currentUserStore.get().pipe(catchError(function () { return of(undefined); })), this.activatedRoute.queryParams])
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var user = _a[0], params = _a[1];
            _this.authorized = !!user;
            _this.emailVerified = user && user.emailVerified;
            _this.correctRequest = _this.hasVerifyParams(params);
            _this.cd.markForCheck();
            if (_this.hasVerifyParams(params)) {
                _this.verifyEmail(params['email'], params['code']);
            }
        });
        this.domainLoading = true;
        this.cd.markForCheck();
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.externalFonts = _this.getExternalFonts(_this.domain);
            _this.domainLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.domainLoading = false;
            _this.cd.markForCheck();
        });
    };
    VerifyEmailComponent.prototype.ngOnDestroy = function () { };
    VerifyEmailComponent.prototype.getExternalFonts = function (domain) {
        if (!domain) {
            return [];
        }
        return [domain.signUpFontRegular, domain.signUpFontHeading].filter(function (item) { return isSet(item); });
    };
    Object.defineProperty(VerifyEmailComponent.prototype, "layout", {
        get: function () {
            return this.domain && isSet(this.domain.signUpLayout) ? this.domain.signUpLayout : this.defaultLayout;
        },
        enumerable: true,
        configurable: true
    });
    VerifyEmailComponent.prototype.hasVerifyParams = function (params) {
        return params['code'] && params['email'];
    };
    VerifyEmailComponent.prototype.verifyEmail = function (email, code) {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.userService
            .verify(email, code)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.emailVerified = true;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUp.AccountVerified, {
                Email: email
            });
        }, function (error) {
            _this.verifyError =
                error instanceof ServerRequestError && error.nonFieldErrors && error.nonFieldErrors[0]
                    ? error.nonFieldErrors[0]
                    : 'Unknown Error';
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    VerifyEmailComponent.prototype.resend = function () {
        var _this = this;
        this.userService
            .resendVerification()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.resent = true;
            _this.cd.markForCheck();
            _this.notificationService.success('Verification email sent', 'Please check your email and follow instructions');
        }, function (error) {
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                _this.notificationService.error('Failed to send', error.nonFieldErrors[0]);
            }
            else {
                _this.notificationService.error('Failed to send', "Unknown error");
            }
        });
    };
    Object.defineProperty(VerifyEmailComponent.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    return VerifyEmailComponent;
}());
export { VerifyEmailComponent };

<app-model-description-data-source-edit
  [control]="control"
  [label]="label"
  [typeOrange]="typeOrange"
  [queryOptionEmptyLabel]="queryOptionEmptyLabel"
  [search]="search"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [resourceClasses]="resourceClasses"
  [queryOptionClasses]="queryOptionClasses"
  [analyticsSource]="analyticsSource"
></app-model-description-data-source-edit>

<ng-container *ngIf="control.controls.query.valid">
  <div class="sidebar__element">
    <app-sidebar-field [label]="controlLabels.yColumn.label" [additional]="controlLabels.yColumn.additional">
      <mat-menu #query_y_column_result="matMenu">
        <ng-template matMenuContent>
          <button
            *ngFor="let item of columnOptions$ | async"
            mat-menu-item
            [disableRipple]="true"
            (click)="control.controls.y_func.patchValue(undefined); control.controls.y_column.patchValue(item.value)"
          >
            {{ item.name }}
          </button>
          <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
            No result fields
          </div>
        </ng-template>
      </mat-menu>

      <mat-menu #query_y_column_func="matMenu">
        <ng-template matMenuContent let-query_y_func="query_y_func">
          <button
            *ngFor="let item of columnOptions$ | async"
            mat-menu-item
            [disableRipple]="true"
            (click)="control.controls.y_func.patchValue(query_y_func); control.controls.y_column.patchValue(item.value)"
          >
            {{ item.name }}
          </button>
          <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
            No result fields
          </div>
        </ng-template>
      </mat-menu>

      <mat-menu #query_y_func_menu="matMenu">
        <ng-template matMenuContent>
          <button
            *ngIf="
              (control.controls.type | appControlValue | async) == dataSourceTypes.Input ||
              (control.controls.type | appControlValue | async) == dataSourceTypes.Workflow ||
              ((control.controls.type | appControlValue | async) == dataSourceTypes.Query &&
                control.controls.query.valid)
            "
            mat-menu-item
            [disableRipple]="true"
            [matMenuTriggerFor]="query_y_column_result"
          >
            Pre-aggregated Field
          </button>
          <ng-container *ngFor="let item of control.yColumnFuncOptions">
            <button
              *ngIf="item.hasField"
              mat-menu-item
              [disableRipple]="true"
              [matMenuTriggerFor]="query_y_column_func"
              [matMenuTriggerData]="{ query_y_func: item.value }"
            >
              {{ item.name }}
            </button>
            <button
              *ngIf="!item.hasField"
              mat-menu-item
              [disableRipple]="true"
              (click)="control.controls.y_func.patchValue(item.value); control.controls.y_column.patchValue(undefined)"
            >
              {{ item.name }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>

      <div
        class="select select_theme_jet select_fill"
        [matMenuTriggerFor]="query_y_func_menu"
        #y_func_trigger="matMenuTrigger"
      >
        <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="y_func_trigger.menuOpen">
          <span class="select__input-label">
            <ng-container *ngIf="yFuncLabel$ | async as label; else label_placeholder">
              {{ label }}
            </ng-container>

            <ng-template #label_placeholder>
              Choose
            </ng-template>
          </span>
          <span class="select__input-arrow"></span>
        </a>
      </div>
      <app-field-errors [form]="control" [fieldName]="'y_func'"></app-field-errors>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="controlLabels.xColumn.label" [additional]="controlLabels.xColumn.additional">
      <mat-menu #query_x_column_lookup_menu="matMenu">
        <ng-template matMenuContent let-query_x_column="query_x_column" let-options="options">
          <button
            *ngFor="let item of options"
            mat-menu-item
            [disableRipple]="true"
            (click)="
              control.controls.x_column.patchValue(query_x_column);
              control.controls.x_column_lookup.patchValue(item.value)
            "
          >
            {{ item.name }}
          </button>
        </ng-template>
      </mat-menu>

      <mat-menu #query_x_column_menu="matMenu">
        <ng-template matMenuContent>
          <ng-container *ngFor="let item of columnOptions$ | async">
            <button
              *ngIf="item.date"
              mat-menu-item
              [disableRipple]="true"
              [matMenuTriggerFor]="query_x_column_lookup_menu"
              [matMenuTriggerData]="{
                query_x_column: item.value,
                options: control.xColumnLookupDateOptions
              }"
            >
              {{ item.name }}
            </button>

            <button
              *ngIf="!item.date && item.time"
              mat-menu-item
              [disableRipple]="true"
              [matMenuTriggerFor]="query_x_column_lookup_menu"
              [matMenuTriggerData]="{
                query_x_column: item.value,
                options: control.xColumnLookupTimeOptions
              }"
            >
              {{ item.name }}
            </button>

            <button
              *ngIf="!item.date && !item.time"
              mat-menu-item
              [disableRipple]="true"
              (click)="
                control.controls.x_column.patchValue(item.value);
                control.controls.x_column_lookup.patchValue(datasetGroupLookup.Plain)
              "
            >
              {{ item.name }}
            </button>
          </ng-container>

          <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
            No result fields
          </div>
        </ng-template>
      </mat-menu>

      <div
        class="select select_theme_jet select_fill"
        [matMenuTriggerFor]="query_x_column_menu"
        #x_column_trigger="matMenuTrigger"
      >
        <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="x_column_trigger.menuOpen">
          <span class="select__input-label">
            <ng-container *ngIf="xColumnLabel$ | async as label; else label_placeholder">
              {{ label }}
            </ng-container>

            <ng-template #label_placeholder>
              Choose
            </ng-template>
          </span>
          <span class="select__input-arrow"></span>
        </a>
      </div>
      <app-field-errors [form]="control" [fieldName]="'x_column'"></app-field-errors>
    </app-sidebar-field>
  </div>

  <div *ngIf="control.xColumn2ChartTypes.includes(chartType)" class="sidebar__element">
    <app-sidebar-field [label]="controlLabels.xColumn2.label" [additional]="controlLabels.xColumn2.additional">
      <mat-menu #query_x_column_2_lookup_menu="matMenu">
        <ng-template matMenuContent let-query_x_column="query_x_column" let-options="options">
          <button
            *ngFor="let item of options"
            mat-menu-item
            [disableRipple]="true"
            (click)="
              control.controls.x_column_2.patchValue(query_x_column);
              control.controls.x_column_2_lookup.patchValue(item.value)
            "
          >
            {{ item.name }}
          </button>
        </ng-template>
      </mat-menu>

      <mat-menu #query_x_column_2_menu="matMenu">
        <ng-template matMenuContent>
          <ng-container *ngFor="let item of columnOptions$ | async">
            <button
              *ngIf="item.date"
              mat-menu-item
              [disableRipple]="true"
              [matMenuTriggerFor]="query_x_column_2_lookup_menu"
              [matMenuTriggerData]="{
                query_x_column: item.value,
                options: control.xColumnLookupDateOptions
              }"
            >
              {{ item.name }}
            </button>

            <button
              *ngIf="!item.date && item.time"
              mat-menu-item
              [disableRipple]="true"
              [matMenuTriggerFor]="query_x_column_2_lookup_menu"
              [matMenuTriggerData]="{
                query_x_column: item.value,
                options: control.xColumnLookupTimeOptions
              }"
            >
              {{ item.name }}
            </button>

            <button
              *ngIf="!item.date && !item.time"
              mat-menu-item
              [disableRipple]="true"
              (click)="
                control.controls.x_column_2.patchValue(item.value);
                control.controls.x_column_2_lookup.patchValue(datasetGroupLookup.Plain)
              "
            >
              {{ item.name }}
            </button>
          </ng-container>

          <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
            No result fields
          </div>
        </ng-template>
      </mat-menu>

      <div
        class="select select_theme_jet select_fill"
        [matMenuTriggerFor]="query_x_column_2_menu"
        #x_column_trigger="matMenuTrigger"
      >
        <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="x_column_trigger.menuOpen">
          <span class="select__input-label">
            <ng-container *ngIf="x2ColumnLabel$ | async as label; else label_placeholder">
              {{ label }}
            </ng-container>

            <ng-template #label_placeholder>
              Choose
            </ng-template>
          </span>
          <span class="select__input-arrow"></span>
        </a>
      </div>
      <app-field-errors [form]="control" [fieldName]="'x_column_2'"></app-field-errors>
    </app-sidebar-field>
  </div>

  <div *ngIf="datasetColumnEnabled" class="sidebar__element">
    <app-sidebar-field [label]="'dataset breakdown'" [additional]="'(optional)'">
      <app-sidebar-card
        [disabled]="!datasetColumnAllowed"
        [appTip]="datasetColumnAllowed ? undefined : 'Chart must have only 1 dataset for using dataset breakdown'"
        [appTipOptions]="{ side: 'top' }"
        style="cursor: not-allowed;"
      >
        <div class="sidebar__element">
          <app-sidebar-field>
            <mat-menu #query_dataset_column_menu="matMenu">
              <ng-template matMenuContent>
                <button
                  *ngFor="let item of columnOptions$ | async"
                  mat-menu-item
                  [disableRipple]="true"
                  (click)="datasetColumnControl.patchValue(item.value)"
                >
                  {{ item.name }}
                </button>

                <div *ngIf="!(columnOptions$ | async).length" class="mat-menu-item-message">
                  No result fields
                </div>
              </ng-template>
            </mat-menu>

            <div
              class="select select_theme_jet select_fill"
              [matMenuTriggerFor]="query_dataset_column_menu"
              #x_column_trigger="matMenuTrigger"
            >
              <a
                href="javascript:void(0)"
                class="select__input"
                [class.select__input_opened]="x_column_trigger.menuOpen"
              >
                <span class="select__input-label">
                  <ng-container *ngIf="datasetColumnLabel$ | async as label; else label_placeholder">
                    {{ label }}
                  </ng-container>

                  <ng-template #label_placeholder>
                    Choose
                  </ng-template>
                </span>
                <span
                  *ngIf="datasetColumnControl.value | appIsSet"
                  class="select__input-reset icon-close"
                  (click)="$event.stopPropagation(); datasetColumnControl.patchValue(undefined)"
                ></span>
                <span *ngIf="!(datasetColumnControl.value | appIsSet)" class="select__input-arrow"></span>
              </a>
            </div>
            <app-field-errors [form]="datasetColumnControl.parent" [fieldName]="'dataset_column'"></app-field-errors>

            <ng-container description>
              Segment data by selected field
            </ng-container>
          </app-sidebar-field>
        </div>
      </app-sidebar-card>
    </app-sidebar-field>
  </div>
</ng-container>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DynamicComponentService } from '@common/dynamic-component';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { getElementByType } from '@modules/customize';
import { CustomizeBarService } from '@modules/customize-bar';
import { CurrentEnvironmentStore, CurrentProjectStore, JET_APP_RESOURCE, ResourceName, resourceTypeItems } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { defaultComponentTemplateName, TemplateService, TemplateType } from '@modules/template';
import { deployUrl, isSet } from '@shared';
import { TemplateProvider } from '../../../services/template-provider/template.provider';
import { ChangeCustomizeBarItemsBase } from '../change-customize-bar-items-base/change-customize-bar-items-base.component';
import { changeCustomizeBarComponentsAdvanced } from './change-customize-bar-components-advanced';
import { changeCustomizeBarComponentsBasic } from './change-customize-bar-components-basic';
import { changeCustomizeBarComponentsCharts } from './change-customize-bar-components-charts';
import { changeCustomizeBarComponentsForms } from './change-customize-bar-components-forms';
import { changeCustomizeBarComponentsLayouts } from './change-customize-bar-components-layouts';
import { changeCustomizeBarComponentsLists } from './change-customize-bar-components-lists';
var ChangeCustomizeBarComponentsComponent = /** @class */ (function (_super) {
    __extends(ChangeCustomizeBarComponentsComponent, _super);
    function ChangeCustomizeBarComponentsComponent(cd, templateService, templateProvider, currentProjectStore, currentEnvironmentStore, resourceEditController, intercomService, injector, dynamicComponentService, customizeBarService, analyticsService) {
        var _this = _super.call(this, injector, dynamicComponentService, customizeBarService, analyticsService) || this;
        _this.cd = cd;
        _this.templateService = templateService;
        _this.templateProvider = templateProvider;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceEditController = resourceEditController;
        _this.intercomService = intercomService;
        _this.injector = injector;
        _this.dynamicComponentService = dynamicComponentService;
        _this.customizeBarService = customizeBarService;
        _this.analyticsService = analyticsService;
        _this.loading = false;
        _this.search = '';
        _this.searchUpdated = new Subject();
        _this.collapseContext = new SidebarCollapseContext();
        _this.layouts = changeCustomizeBarComponentsLayouts;
        _this.layoutsFiltered = [];
        _this.basic = changeCustomizeBarComponentsBasic;
        _this.basicFiltered = [];
        _this.lists = changeCustomizeBarComponentsLists;
        _this.listsFiltered = [];
        _this.charts = changeCustomizeBarComponentsCharts;
        _this.chartsFiltered = [];
        _this.forms = changeCustomizeBarComponentsForms;
        _this.formsFiltered = [];
        _this.advanced = changeCustomizeBarComponentsAdvanced;
        _this.advancedFiltered = [];
        _this.mostUsed = ['Table'].map(function (item) { return _this.lists.find(function (i) { return i.title == item; }); }).concat(['Form'].map(function (item) { return _this.forms.find(function (i) { return i.title == item; }); }), ['Button'].map(function (item) { return _this.basic.find(function (i) { return i.title == item; }); }), ['Text Field'].map(function (item) { return _this.forms.find(function (i) { return i.title == item; }); }), ['Text'].map(function (item) { return _this.basic.find(function (i) { return i.title == item; }); }), ['Line'].map(function (item) { return _this.charts.find(function (i) { return i.title == item; }); }));
        _this.mostUsedFiltered = [];
        _this.integrations = [];
        _this.integrationsFiltered = [];
        _this.integrationResources = [];
        _this.integrationResourcesFiltered = [];
        _this.analyticsSource = 'builder_components';
        return _this;
    }
    ChangeCustomizeBarComponentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.templateService
            .get({ type: TemplateType.DefaultComponent })
            .pipe(untilDestroyed(this))
            .subscribe(function (templates) {
            [_this.layouts, _this.basic, _this.lists, _this.charts, _this.forms, _this.advanced, _this.mostUsed].forEach(function (items) {
                items.forEach(function (item) {
                    var elementCls = getElementByType(item.type);
                    if (!elementCls) {
                        return;
                    }
                    var element = new elementCls().deserialize({
                        type: item.type,
                        params: item.defaultParams
                    });
                    var template = templates.find(function (i) { return i.uniqueName == defaultComponentTemplateName(element); });
                    if (!template) {
                        return;
                    }
                    item.alignHorizontal = template.element.alignHorizontal;
                    item.alignVertical = template.element.alignVertical;
                    item.defaultParams = template.element.serialize()['params'];
                });
            });
            _this.loading = false;
            _this.cd.markForCheck();
            _this.updateFiltered();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.updateIntegrations();
        this.searchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFiltered(); });
    };
    ChangeCustomizeBarComponentsComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarComponentsComponent.prototype.updateIntegrations = function () {
        var _this = this;
        var resourcesInstalled = this.currentEnvironmentStore.resources.filter(function (resource) { return !resource.demo && resource.uniqueName != JET_APP_RESOURCE; });
        combineLatest(this.templateProvider.getInstalledGroups(resourcesInstalled), this.templateProvider.getOtherGroups())
            .pipe(switchMap(function (_a) {
            var installedGroups = _a[0], otherGroups = _a[1];
            return combineLatest(installedGroups.concat(otherGroups).map(function (group) {
                return _this.templateProvider.getResourceItems(group.resource, _this.context).pipe(map(function (resourceItems) {
                    return _this.templateProvider.getTemplatesItems(group).concat(resourceItems);
                }));
            })).pipe(map(function (result) {
                return result.reduce(function (acc, item) {
                    acc.push.apply(acc, item);
                    return acc;
                }, []);
            }));
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.integrations = result;
            var resourcesItems = resourceTypeItems.filter(function (item) {
                return !item.hidden && !item.protected && (item.modelsEnabled || item.actionsEnabled);
            });
            var resourcesItemsInstalled = resourcesItems.filter(function (item) {
                return resourcesInstalled.some(function (i) { return i.typeItem && i.typeItem.name == item.name; });
            });
            _this.integrationResources = resourcesItemsInstalled.map(function (item) {
                return {
                    typeItem: item,
                    installed: true
                };
            }).concat(resourcesItems
                .filter(function (item) { return item.name != ResourceName.JetDatabase; })
                .filter(function (item) { return !resourcesItemsInstalled.find(function (i) { return i.name == item.name; }); })
                .map(function (item) {
                return {
                    typeItem: item,
                    installed: false
                };
            }));
            _this.updateFiltered();
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarComponentsComponent.prototype.onIntegrationClick = function (typeItem, installed) {
        if (!installed) {
            this.addResource(typeItem);
        }
        else {
            this.selectTemplatesTabResource.emit(typeItem);
        }
    };
    ChangeCustomizeBarComponentsComponent.prototype.addResource = function (typeItem) {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
            Source: 'builder_component_templates_banner',
            ResourceID: typeItem.name
        });
        this.resourceEditController
            .createResource(typeItem, {
            resourceNameEditing: true,
            analyticsSource: 'builder_component_templates'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result.resource) {
                return;
            }
            _this.selectTemplatesTabResource.emit(typeItem);
        });
    };
    ChangeCustomizeBarComponentsComponent.prototype.getFeaturedIntegrations = function () {
        var _this = this;
        var featured = [
            {
                resource: ResourceName.Stripe,
                title: 'Payments'
            },
            {
                resource: ResourceName.HubSpot,
                title: 'Tickets'
            },
            {
                resource: ResourceName.Intercom,
                title: 'Contacts'
            },
            {
                resource: ResourceName.Zendesk,
                title: 'Tickets'
            },
            {
                resource: ResourceName.Mixpanel,
                title: 'User Events'
            },
            {
                resource: ResourceName.Twilio,
                title: 'Send SMS'
            },
            {
                resource: ResourceName.SendGrid,
                title: 'Send email'
            },
            {
                resource: ResourceName.Mailchimp,
                title: 'Send email'
            },
            {
                resource: ResourceName.Slack,
                title: 'Send message'
            }
        ];
        return featured
            .map(function (featuredItem) {
            return _this.integrations.find(function (item) {
                var resource;
                if (item.resource) {
                    resource = item.resource.typeItem.name;
                }
                else if (item.template && item.template.forResources.length) {
                    resource = item.template.forResources[0].typeItem.name;
                }
                return featuredItem.resource == resource && featuredItem.title == item.title;
            });
        })
            .filter(function (item) { return item != undefined; })
            .slice(0, 6);
    };
    ChangeCustomizeBarComponentsComponent.prototype.filterItems = function (items) {
        var _this = this;
        return items.filter(function (item) {
            if (!_this.search) {
                return true;
            }
            var resource = '';
            if (item.resource) {
                resource = item.resource.typeItem.label;
            }
            else if (item.template && item.template.forResources.length) {
                resource = item.template.forResources[0].typeItem.label;
            }
            return (item.title.toLowerCase().includes(_this.search.toLowerCase()) ||
                resource.toLowerCase().includes(_this.search.toLowerCase()));
        });
    };
    ChangeCustomizeBarComponentsComponent.prototype.filterResourceItems = function (typeItem, items) {
        var _this = this;
        return items.filter(function (integration) {
            if (!_this.search) {
                return true;
            }
            if (integration.resource) {
                if (integration.resource.typeItem.name != typeItem.name) {
                    return false;
                }
            }
            else if (integration.template && integration.template.forResources.length) {
                if (!integration.template.forResources.some(function (i) { return i.typeItem.name == typeItem.name; })) {
                    return false;
                }
            }
            return integration.title.toLowerCase().includes(_this.search.toLowerCase());
        });
    };
    ChangeCustomizeBarComponentsComponent.prototype.updateFiltered = function () {
        var _this = this;
        this.layoutsFiltered = this.filterItems(this.layouts);
        this.basicFiltered = this.filterItems(this.basic);
        this.listsFiltered = this.filterItems(this.lists);
        this.chartsFiltered = this.filterItems(this.charts);
        this.formsFiltered = this.filterItems(this.forms);
        this.advancedFiltered = this.filterItems(this.advanced);
        this.mostUsedFiltered = this.filterItems(this.mostUsed);
        if (this.search) {
            this.integrationsFiltered = this.filterItems(this.integrations).slice(0, 15);
        }
        else {
            this.integrationsFiltered = this.getFeaturedIntegrations();
        }
        if (this.search) {
            this.integrationResourcesFiltered = this.integrationResources
                .filter(function (item) {
                return (item.typeItem.label.toLowerCase().includes(_this.search.toLowerCase()) ||
                    _this.filterResourceItems(item.typeItem, _this.integrations).length);
            })
                .slice(0, 10);
        }
        else {
            this.integrationResourcesFiltered = this.integrationResources.slice(0, 10);
        }
        if ([
            this.layoutsFiltered,
            this.basicFiltered,
            this.listsFiltered,
            this.chartsFiltered,
            this.formsFiltered,
            this.advancedFiltered,
            this.integrationsFiltered
        ].every(function (item) { return !item.length; })) {
            this.emptyLabel = this.search.length ? 'Nothing found' : 'Nothing here';
        }
        else {
            this.emptyLabel = undefined;
        }
        this.cd.markForCheck();
    };
    Object.defineProperty(ChangeCustomizeBarComponentsComponent.prototype, "isSearching", {
        get: function () {
            return isSet(this.search);
        },
        enumerable: true,
        configurable: true
    });
    ChangeCustomizeBarComponentsComponent.prototype.getCollapseContext = function () {
        return this.isSearching ? undefined : this.collapseContext;
    };
    ChangeCustomizeBarComponentsComponent.prototype.getItemIcon = function (item) {
        if (item.resource) {
            return item.resource.icon;
        }
        else if (item.template && item.template.forResources.length && item.template.forResources[0].typeItem.icon) {
            return deployUrl("/assets/images/resources/icons/" + item.template.forResources[0].typeItem.icon + ".svg");
        }
    };
    ChangeCustomizeBarComponentsComponent.prototype.isDroppable = function (item) {
        return false;
    };
    ChangeCustomizeBarComponentsComponent.prototype.clearSearch = function () {
        this.search = '';
        this.searchUpdated.next();
        this.cd.markForCheck();
    };
    ChangeCustomizeBarComponentsComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    return ChangeCustomizeBarComponentsComponent;
}(ChangeCustomizeBarItemsBase));
export { ChangeCustomizeBarComponentsComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup2-header.component";
var styles_Popup2HeaderComponent = [];
var RenderType_Popup2HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2HeaderComponent, data: {} });
export { RenderType_Popup2HeaderComponent as RenderType_Popup2HeaderComponent };
export function View_Popup2HeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popup2-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "popup2-header__title"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "popup2-header__description"]], null, null, null, null, null)), i0.ɵncd(null, 1), i0.ɵncd(null, 2)], null, null); }
export function View_Popup2HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-header", [], null, null, null, View_Popup2HeaderComponent_0, RenderType_Popup2HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i1.Popup2HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2HeaderComponentNgFactory = i0.ɵccf("app-popup2-header", i1.Popup2HeaderComponent, View_Popup2HeaderComponent_Host_0, {}, {}, ["app-popup2-title", "app-popup2-description", "*"]);
export { Popup2HeaderComponentNgFactory as Popup2HeaderComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "@angular/common";
import * as i5 from "./popups.component";
import * as i6 from "../../services/popup/popup.service";
var styles_PopupsComponent = [];
var RenderType_PopupsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopupsComponent, data: {} });
export { RenderType_PopupsComponent as RenderType_PopupsComponent };
function View_PopupsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.popupData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "popups"]], [[2, "popups_visible", null], [2, "popups_disable-events", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PopupsComponent_1)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.items; var currVal_3 = _co.trackByFn; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items.length; var currVal_1 = _co.disablePointerEvents; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PopupsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popups", [], null, null, null, View_PopupsComponent_0, RenderType_PopupsComponent)), i0.ɵdid(1, 245760, null, 0, i5.PopupsComponent, [i6.PopupService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupsComponentNgFactory = i0.ɵccf("app-popups", i5.PopupsComponent, View_PopupsComponent_Host_0, {}, {}, []);
export { PopupsComponentNgFactory as PopupsComponentNgFactory };

<div class="menu2-list__item menu2-section">
  <div class="menu2-section__header">
    <span *ngIf="menuItem.icon | appIsSet" class="menu2-section__icon" [ngClass]="'icon-' + menuItem.icon"></span>
    <span class="menu2-section__title">
      <ng-container *ngIf="menuItem.title">{{ menuItem.title }}</ng-container>
      <ng-container *ngIf="!menuItem.title">No title</ng-container>
    </span>
  </div>
  <div class="menu2-section__content">
    <app-auto-menu-item
      *ngFor="let item of children; trackBy: trackMenuItemFn"
      [menuItem]="item"
      [menuBlock]="menuBlock"
      [primary]="primary"
      [horizontal]="horizontal"
      [forceActive]="forceActive"
      [forceOpened]="forceOpened"
    >
    </app-auto-menu-item>
  </div>
</div>

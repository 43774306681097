import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Environment } from '@modules/projects';

import { Project } from '../../data/project';
import { ProjectToken } from '../../data/project-token';

@Injectable({
  providedIn: 'root'
})
export class CryptService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  encrypt(project: Project, environment: Environment, token: ProjectToken, message: string): Observable<string> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/${environment.uniqueName}/encrypt/`);
        let headers = new HttpHeaders();
        const data = {
          message: message
        };

        if (token) {
          data['project_token'] = token.token;
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => result['result']),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  decrypt(project: Project, environment: Environment, token: ProjectToken, message: string): Observable<string> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/${environment.uniqueName}/decrypt/`);
        let headers = new HttpHeaders();
        const data = {
          message: message
        };

        if (token) {
          data['project_token'] = token.token;
        }

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => result['result']),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export enum SideConstraint {
  Fixed = 'fixed',
  Fluid = 'fluid'
}

export enum SizeConstraint {
  Fixed = 'fixed',
  Fluid = 'fluid'
}

export class Constraints {
  top: SideConstraint = SideConstraint.Fluid;
  right: SideConstraint = SideConstraint.Fluid;
  bottom: SideConstraint = SideConstraint.Fluid;
  left: SideConstraint = SideConstraint.Fluid;
  width: SizeConstraint = SizeConstraint.Fluid;
  height: SizeConstraint = SizeConstraint.Fluid;

  constructor(options: Partial<Constraints> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.top = data['top'];
    this.right = data['right'];
    this.bottom = data['bottom'];
    this.left = data['left'];
    this.width = data['width'];
    this.height = data['height'];

    return this;
  }

  serialize(): Object {
    return {
      top: this.top,
      right: this.right,
      bottom: this.bottom,
      left: this.left,
      width: this.width,
      height: this.height
    };
  }

  isHorizontalSides(value: SideConstraint): boolean {
    return this.left == value && this.right == value;
  }

  isVerticalSides(value: SideConstraint): boolean {
    return this.top == value && this.bottom == value;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppError } from '@shared';
var CancelledError = /** @class */ (function (_super) {
    __extends(CancelledError, _super);
    function CancelledError() {
        var _this = _super.call(this, 'Cancelled') || this;
        Object.setPrototypeOf(_this, CancelledError.prototype);
        return _this;
    }
    return CancelledError;
}(AppError));
export { CancelledError };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { FilterParameter } from '../../data/filter-parameter';
import { Log } from '../../data/log';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var LogService = /** @class */ (function () {
    function LogService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    LogService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'logs/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return new LogService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    LogService.prototype.getFilters = function (projectName, environmentName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'logs/filters/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return result.map(function (item) { return new FilterParameter().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    LogService.ngInjectableDef = i0.defineInjectable({ factory: function LogService_Factory() { return new LogService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: LogService, providedIn: "root" });
    return LogService;
}());
export { LogService };
(function (LogService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new Log().deserialize(item); });
            this.count = data['count'];
            this.next = data['next'];
            this.previous = data['previous'];
            return this;
        };
        return GetResponse;
    }());
    LogService.GetResponse = GetResponse;
})(LogService || (LogService = {}));

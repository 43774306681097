import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { generateAlphanumeric, isSet } from '@shared';

import { Notification } from '../../data/notification';
import { NotificationService } from '../../services/notification/notification.service';

interface Item {
  uid: string;
  instance: Notification;
  position: number;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public notificationService: NotificationService, private cd: ChangeDetectorRef) {}

  @ViewChildren('notification_element', { read: ElementRef }) notificationElements = new QueryList<ElementRef>();

  items: Item[] = [];

  trackItemFn(i, item: Item) {
    return item.uid;
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.notificationService.notifications$.pipe(untilDestroyed(this)).subscribe(items => {
      this.items = items.map(item => {
        const existingItem = this.items.find(i => i.instance === item);

        if (existingItem) {
          return existingItem;
        } else {
          const uid = generateAlphanumeric(8, { letterFirst: true });
          return { uid: uid, instance: item, position: undefined };
        }
      });
      this.cd.detectChanges();

      this.items = this.items.map((item, i) => {
        if (isSet(item.position)) {
          return item;
        }

        let position = 0;

        if (i > 0) {
          const previousItem = this.items[i - 1];
          const previousElement = this.notificationElements.toArray()[i - 1].nativeElement;

          position = previousItem.position + previousElement.getBoundingClientRect().height;
        }

        return {
          uid: item.uid,
          instance: item.instance,
          position: position
        };
      });
      this.cd.detectChanges();
    });
  }
}

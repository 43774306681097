<div
  class="menu2-main menu2-block"
  [class.menu2-main_light]="menuIsLight === true"
  [class.menu2-main_dark]="menuIsLight === false"
  [class.menu2-main_unset]="!(block?.accentColor | appIsSet)"
  [class.menu2-block_light]="menuIsLight === true"
  [class.menu2-block_dark]="menuIsLight === false"
  [class.menu2-block_unset]="!(block?.accentColor | appIsSet)"
  [style.background-color]="(block?.accentColor | appIsColorHex) ? block?.accentColor : null"
  [ngClass]="!(block?.accentColor | appIsColorHex) ? 'background-color_' + block?.accentColor + '_1' : null"
>
  <div
    *ngIf="!horizontal"
    class="menu2-main__viewport"
    xsScrollable
    [xsScrollableOptions]="{ stopPropagationAlways: true }"
  >
    <div class="menu2-main__content menu2-main__containers">
      <div class="menu2-main__start">
        <app-auto-menu-item
          *ngFor="let item of startItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>

      <div class="menu2-main__main">
        <app-auto-menu-item
          *ngFor="let item of centerItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>

      <div class="menu2-main__end">
        <app-auto-menu-item
          *ngFor="let item of endItems; trackBy: trackMenuItemFn"
          [menuItem]="item"
          [menuBlock]="block"
          [primary]="false"
          [horizontal]="horizontal"
          [childrenVertical]="horizontal"
        >
        </app-auto-menu-item>
      </div>
    </div>
  </div>

  <div *ngIf="horizontal" class="menu2-main__content menu2-main__containers">
    <div class="menu2-main__start">
      <app-auto-menu-item
        *ngFor="let item of startItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>

    <div class="menu2-main__main">
      <app-auto-menu-item
        *ngFor="let item of centerItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>

    <div class="menu2-main__end">
      <app-auto-menu-item
        *ngFor="let item of endItems; trackBy: trackMenuItemFn"
        [menuItem]="item"
        [menuBlock]="block"
        [primary]="false"
        [horizontal]="horizontal"
        [childrenVertical]="horizontal"
      >
      </app-auto-menu-item>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export type PageHeadingSize = 's' | 'm' | 'l';

@Component({
  selector: 'app-page-heading',
  templateUrl: './page-heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeadingComponent implements OnInit {
  @Input() size: PageHeadingSize = 'm';

  @HostBinding('class.page-heading') cls = true;
  @HostBinding('class.page-heading_size_s') get sizeS() {
    return this.size == 's';
  }
  @HostBinding('class.page-heading_size_m') get sizeM() {
    return this.size == 'm';
  }
  @HostBinding('class.page-heading_size_l') get sizeL() {
    return this.size == 'l';
  }

  constructor() {}

  ngOnInit() {}
}

<ng-template
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayOpen]="previewAsOpened"
  [cdkConnectedOverlayOrigin]="preview_as_trigger"
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_position_top-left']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 }
  ]"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="setPreviewAsOpened(false)"
  (detach)="setPreviewAsOpened(false)"
  cdkConnectedOverlay
>
  <div class="customize-preview__dropdown-selector theme_dark overlay-content overlay-content_animated">
    <app-customize-preview-user-dropdown
      (selectUser)="previewAsUser($event); setPreviewAsOpened(false)"
      (resetUser)="disablePreviewAsUser(); setPreviewAsOpened(false)"
    ></app-customize-preview-user-dropdown>
  </div>
</ng-template>

<div class="customize-preview" [class.customize-preview_active]="active">
  <div class="customize-preview__section" cdkOverlayOrigin #preview_as_trigger="cdkOverlayOrigin">
    <div
      class="customize-preview__main customize-preview__info-hover"
      [class.customize-preview__info-hover_active]="previewAsOpened"
    >
      <div
        class="customize-preview__profile"
        [style.background-image]="
          (currentUserStore.instance$ | async)?.photo ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')' : ''
        "
      ></div>

      <div class="customize-preview__info" (click)="setPreviewAsOpened(true)">
        <div class="customize-preview__info-top customize-preview__title">
          Previewing as <span class="icon-arrow_down_2"></span>
        </div>
        <div class="customize-preview__info-bottom customize-preview__subtitle">
          {{ (currentUserStore.instance$ | async)?.strFull }}
        </div>
      </div>
    </div>

    <div class="customize-preview__right">
      <a
        href="javascript:void(0)"
        class="customize-preview__button"
        [class.customize-preview__button_contrast]="true"
        [class.customize-preview__button_orange]="false"
        [class.customize-preview__button_disabled]="publishLoading"
        (click)="disablePreviewAsUser(); exitPreview.emit()"
      >
        Exit Preview
      </a>
    </div>
  </div>

  <div
    *ngIf="draftChanges.length"
    class="customize-preview__section customize-preview__section_contrast customize-preview__buttons"
  >
    <div class="customize-preview__main">
      <div class="customize-preview__indicator"></div>
      <a class="customize-preview__link" (click)="showChanges.emit()">
        {{ draftChangesTotal | i18nPlural: { '=1': '# change', other: '# changes' } }}
        <span class="icon-arrow_forward_2"></span>
      </a>
    </div>
    <div class="customize-preview__right customize-preview__buttons">
      <!--      <a-->
      <!--        href="javascript:void(0)"-->
      <!--        class="customize-preview__button customize-preview__buttons-item"-->
      <!--        [class.customize-preview__button_disabled]="publishLoading || savingChanges"-->
      <!--        (click)="deleteDraft.emit()"-->
      <!--      >-->
      <!--        Discard-->
      <!--      </a>-->
      <a
        href="javascript:void(0)"
        class="customize-preview__button customize-preview__buttons-item customize-preview__button_purple"
        [class.customize-preview__button_disabled]="publishLoading || savingChanges"
        (click)="disablePreviewAsUser(); exitPreview.emit(); publishDraft.emit()"
      >
        <app-loader-small *ngIf="publishLoading" class="customize-preview__button-icon"></app-loader-small>
        Publish
      </a>
    </div>
  </div>
</div>

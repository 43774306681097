/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../query-builder-parameters-edit-item/query-builder-parameters-edit-item.component.ngfactory";
import * as i4 from "../query-builder-parameters-edit-item/query-builder-parameters-edit-item.component";
import * as i5 from "./query-builder-parameters-edit.component";
import * as i6 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_QueryBuilderParametersEditComponent = [];
var RenderType_QueryBuilderParametersEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderParametersEditComponent, data: {} });
export { RenderType_QueryBuilderParametersEditComponent as RenderType_QueryBuilderParametersEditComponent };
function View_QueryBuilderParametersEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "query-builder-list-item-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.controls.verboseName.value || _v.parent.context.$implicit.controls.name.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.controls.description.value; _ck(_v, 3, 0, currVal_1); }); }
function View_QueryBuilderParametersEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "query-builder-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderParametersEditComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "query-builder-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "query-builder-list-item__left"], ["style", "padding-left: 20px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "query-builder-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "input", [["class", "input input_fill"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.parameterValues[_v.context.$implicit.value["name"]] = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.setTokenValue(_v.context.$implicit, _co.parameterValues[_v.context.$implicit.value["name"]]) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(9, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i0.ɵpod(10, { standalone: 0 }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.controls.description.value; _ck(_v, 2, 0, currVal_0); var currVal_9 = _co.parameterValues[_v.context.$implicit.value["name"]]; var currVal_10 = _ck(_v, 10, 0, true); _ck(_v, 9, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.value["placeholder"] ? (((_co.controlPlaceholder(_v.context.$implicit.value["name"]) + " (") + _v.context.$implicit.value["placeholder"]) + ")") : _co.controlPlaceholder(_v.context.$implicit.value["name"])); var currVal_2 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 12).ngClassValid; var currVal_7 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_QueryBuilderParametersEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "query-builder-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-query-builder-parameters-edit-item", [], null, [[null, "valueChange"], [null, "deleteRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.setTokenValue(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteRequested" === en)) {
        var pd_1 = (_co.removeItem(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_QueryBuilderParametersEditItemComponent_0, i3.RenderType_QueryBuilderParametersEditItemComponent)), i0.ɵdid(2, 770048, null, 0, i4.QueryBuilderParametersEditItemComponent, [i0.ChangeDetectorRef], { control: [0, "control"], value: [1, "value"], autofocus: [2, "autofocus"] }, { deleteRequested: "deleteRequested", valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.parameterValues[_v.context.$implicit.controls.name.value]; var currVal_2 = ((_co.commonControls.length == 1) && !_v.context.$implicit.hasName()); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_QueryBuilderParametersEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "query-builder-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "query-builder-list__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderParametersEditComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "query-builder-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "query-builder-list__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderParametersEditComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "query-builder-list__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "a", [["class", "button button_orange-transparent button_small query-builder-list__buttons-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "span", [["class", "icon icon-plus button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Input"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.protectedControls; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.commonControls; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_QueryBuilderParametersEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-parameters-edit", [], null, null, null, View_QueryBuilderParametersEditComponent_0, RenderType_QueryBuilderParametersEditComponent)), i0.ɵdid(1, 245760, null, 0, i5.QueryBuilderParametersEditComponent, [i6.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderParametersEditComponentNgFactory = i0.ɵccf("app-query-builder-parameters-edit", i5.QueryBuilderParametersEditComponent, View_QueryBuilderParametersEditComponent_Host_0, { control: "control", queryContext: "queryContext", object: "object" }, {}, []);
export { QueryBuilderParametersEditComponentNgFactory as QueryBuilderParametersEditComponentNgFactory };

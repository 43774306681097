import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationsModule } from '@common/notifications';
import { TipsModule } from '@common/tips';

import { CodeComponent } from './components/code/code.component';
import { CodeDirective } from './directives/code/code.directive';
import { CopyTextDirective } from './directives/copy-text/copy-text.directive';

@NgModule({
  imports: [CommonModule, NotificationsModule, TipsModule],
  declarations: [CodeComponent, CodeDirective, CopyTextDirective],
  exports: [CodeComponent, CodeDirective]
})
export class CodeModule {}

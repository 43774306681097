import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class SectionMenuItem extends MenuItem {
  public type = MenuItemType.Section;
  public title: string;
  public icon: string;

  deserialize(data: Object): SectionMenuItem {
    super.deserialize(data);
    this.title = this.params['title'];
    this.icon = this.params['icon'];
    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    data['params']['icon'] = this.icon;
    return data;
  }

  copyFrom(item: SectionMenuItem) {
    super.copyFrom(item);
    this.title = item.title;
    this.icon = item.icon;
  }

  clone(): SectionMenuItem {
    const instance = new SectionMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Section, SectionMenuItem);

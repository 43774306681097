export const mailgunResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'send_an_email',
    params: {
      type: 'query',
      verbose_name: 'Send an email',
      dynamic_status: false,
      action_params: [
        {
          name: 'domain',
          verbose_name: 'domain',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'from',
          verbose_name: 'from',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'to',
          verbose_name: 'to',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'subject',
          verbose_name: 'subject',
          description: '',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'text',
          verbose_name: 'text',
          description: '',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        }
      ],
      description: 'Sends an email.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.mailgun.net/v3/{{params.domain}}/messages',
            auth_params: {},
            body_type: 'FormData',
            body: [
              {
                name: 'from',
                value: '{{params.from}}'
              },
              {
                name: 'to',
                value: '{{params.to}}'
              },
              {
                name: 'subject',
                value: '{{params.subject}}'
              },
              {
                name: 'text',
                value: '{{params.text}}'
              }
            ],
            headers: [
              {
                name: 'Authorization',
                value: 'Basic {-basic_auth-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: '',
            request_tokens: {}
          }
        }
      }
    }
  }
];

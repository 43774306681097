import { Injectable, Injector } from '@angular/core';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { PopupService } from '@common/popups';

import { PopoverComponent } from '../../components/popover/popover.component';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  constructor(private popupService: PopupService) {}

  create(data: DynamicComponentArguments, anchor: Element) {
    this.popupService.push({
      component: PopoverComponent,
      inputs: {
        data: data,
        anchor: anchor
      },
      injector: data.injector
    });
  }
}

import { AirtableBasePermissionLevel } from './airtable-base-permission-level';
import { AirtableFieldType } from './airtable-field-type';

export interface AirtableRecordResponse {
  id?: string;
  fields: Object;
  createdTime?: string;
}

export interface AirtableGetResponse {
  records: AirtableRecordResponse[];
  offset?: string;
}

export interface AirtableCreateResponse {
  records: AirtableRecordResponse[];
}

export interface AirtableUpdateResponse {
  records: AirtableRecordResponse[];
}

export interface AirtableDeleteResponse {
  records: {
    id: string;
    deleted: boolean;
  }[];
}

export interface AirtableBaseResponse {
  id: string;
  name: string;
  permissionLevel: AirtableBasePermissionLevel;
}

export interface AirtableBasesResponse {
  bases: AirtableBaseResponse[];
}

export interface AirtableSelectFieldOptions {
  choices: {
    id: string;
    name: string;
    color?: string;
  }[];
}

export interface AirtableRatingFieldOptions {
  icon: string;
  max: number;
  color: string;
}

export interface AirtableFormulaFieldOptions {
  isValid: boolean;
  formula: string;
  referencedFieldIds: string[];
  result?: AirtableTableField;
}

export interface AirtableLookupValueFieldOptions {
  fieldIdInLinkedTable: string;
  isValid: boolean;
  recordLinkFieldId: string;
  result?: AirtableTableField;
}

export interface AirtableRecordLinkFieldOptions {
  linkedTableId: string;
  prefersSingleRecordLink: boolean;
}

export interface AirtableNumberFieldOptions {
  precision: number;
}

export interface AirtableTableField<O = Record<string, unknown>> {
  type: AirtableFieldType;
  id: string;
  name: string;
  options: O;
}

export enum AirtableTableViewType {
  Grid = 'grid',
  Form = 'form',
  Calendar = 'calendar',
  Gallery = 'gallery',
  Kanban = 'kanban',
  Timeline = 'timeline',
  Gantt = 'block'
}

export interface AirtableTableResponse {
  id: string;
  name: string;
  primaryFieldId: string;
  fields: AirtableTableField[];
  views: {
    id: string;
    name: string;
    type: AirtableTableViewType;
  }[];
}

export interface AirtableTablesResponse {
  tables: AirtableTableResponse[];
}

import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
  name: 'appFormatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (!format) {
      return value;
    }
    return numeral(value).format(format);
  }
}

import { Inject, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, TransparentDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldOutput, ParameterField } from '@modules/fields';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentEnvironmentStore, ProjectGroupStore } from '@modules/projects';
import { Automation, AutomationTrigger, Workflow, WorkflowRun } from '@modules/workflow';

import { WorkflowSaveEvent } from '../../components/workflow/workflow/workflow-save-event';

export const WORKFLOW_CONTROLLER_COMPONENT = new InjectionToken<any>('WORKFLOW_CONTROLLER_COMPONENT');

@Injectable()
export class WorkflowEditController implements OnDestroy {
  constructor(
    @Inject(WORKFLOW_CONTROLLER_COMPONENT) private component: any,
    private popupService: PopupService,
    private injector: Injector,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  open(options: {
    create?: boolean;
    nameEditable?: boolean;
    name?: string;
    workflow: Workflow;
    workflowEditable?: boolean;
    workflowRun?: WorkflowRun;
    parametersEnabled?: boolean;
    parameters: ParameterField[];
    automation?: Automation;
    triggerEditable?: boolean;
    trigger?: AutomationTrigger;
    triggerOutputs?: FieldOutput[];
    triggerLabel?: string;
    triggerIcon?: string;
    triggerData?: any;
    customizeTrigger?: boolean;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    contextTokenProvider?: ViewContextTokenProvider;
    actionTypesEnabled?: ActionType[];
    historyEnabled?: boolean;
    historyOpenedInitial?: boolean;
    resultEnabled?: boolean;
    resultOutputs?: FieldOutput[];
    analyticsSource?: string;
  }): Observable<WorkflowSaveEvent> {
    const result = new ReplaySubject<WorkflowSaveEvent>();
    const injector = Injector.create(
      [
        {
          provide: ViewContext,
          useFactory: (currentEnvironmentStore: CurrentEnvironmentStore) => {
            if (options.context) {
              return options.context;
            } else {
              return new ViewContext(currentEnvironmentStore);
            }
          },
          deps: [CurrentEnvironmentStore]
        },
        {
          provide: ViewContextTokenProvider,
          useFactory: (viewContext: ViewContext, projectGroupStore: ProjectGroupStore) => {
            if (options.contextTokenProvider) {
              return options.contextTokenProvider;
            } else {
              return new ViewContextTokenProvider(viewContext, projectGroupStore);
            }
          },
          deps: [ViewContext, ProjectGroupStore]
        }
      ],
      this.injector
    );

    if (options.workflowEditable) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.StartedToSetUp, {
        Create: options.create,
        Source: options.analyticsSource
      });
    }

    const context = injector.get<ViewContext>(ViewContext);

    this.popupService.push({
      component: this.component,
      popupComponent: TransparentDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        nameEditable: options.nameEditable,
        name: options.name,
        workflow: options.workflow,
        workflowEditable: options.workflowEditable,
        workflowRun: options.workflowRun,
        parametersEnabled: options.parametersEnabled,
        parameters: options.parameters,
        automation: options.automation,
        triggerEditable: options.triggerEditable,
        trigger: options.trigger,
        triggerOutputs: options.triggerOutputs,
        triggerLabel: options.triggerLabel,
        triggerIcon: options.triggerIcon,
        triggerData: options.triggerData,
        customizeTrigger: options.customizeTrigger,
        context: context,
        contextElement: options.contextElement,
        actionTypesEnabled: options.actionTypesEnabled,
        historyEnabled: options.historyEnabled,
        historyOpenedInitial: options.historyOpenedInitial,
        resultEnabled: options.resultEnabled,
        resultOutputs: options.resultOutputs,
        analyticsSource: options.analyticsSource
      },
      outputs: {
        result: [
          event => {
            result.next(event);

            if (options.workflowEditable) {
              this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.Saved, {
                Create: options.create,
                Steps: event.workflow.getStepsCount(),
                AutomationPublish: event.automationActive === true,
                Source: options.analyticsSource
              });
            }
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          result.next({ cancelled: true });

          if (options.workflowEditable) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.SetUpCancelled, {
              Create: options.create,
              Source: options.analyticsSource
            });
          }
        }
      },
      injector: injector
    });

    return result.asObservable();
  }
}

import { NotSet } from 'ng-gxselect';
import { BehaviorSubject } from 'rxjs';
import { publishLast, refCount, tap } from 'rxjs/operators';
var SelectSource = /** @class */ (function () {
    function SelectSource() {
        this.config = {};
        this._valueOption = new BehaviorSubject(undefined);
        this._options = new BehaviorSubject([]);
        this._loading = new BehaviorSubject(false);
        this.loadRequested = false;
        this.loadingValue = NotSet;
        this._loaded = false;
    }
    Object.defineProperty(SelectSource.prototype, "valueOption", {
        get: function () {
            return this._valueOption.value;
        },
        set: function (value) {
            this._valueOption.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "valueOption$", {
        get: function () {
            return this._valueOption.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "options", {
        get: function () {
            return this._options.value;
        },
        set: function (value) {
            this._options.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "options$", {
        get: function () {
            return this._options.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "loading", {
        get: function () {
            return this._loading.value;
        },
        set: function (value) {
            this._loading.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "loading$", {
        get: function () {
            return this._loading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectSource.prototype, "loaded", {
        get: function () {
            return this._loaded;
        },
        enumerable: true,
        configurable: true
    });
    SelectSource.prototype.reset = function () {
        this.options = [];
        this.loading = false;
        this._loaded = false;
        this.loadRequested = false;
        this.resetPagination();
        if (this.fetchRequest) {
            this.fetchRequest.unsubscribe();
            this.fetchRequest = undefined;
        }
    };
    SelectSource.prototype.search = function (search) {
        this.setSearch(search);
        if (this.searchQuery == search && (this.loaded || this.loading)) {
            return;
        }
        if (this.config.searchMinimumLength != undefined &&
            search.length &&
            search.length < this.config.searchMinimumLength) {
            return;
        }
        this.loadMore();
    };
    SelectSource.prototype.setSearch = function (search) {
        if (this.searchQuery == search) {
            return;
        }
        this.reset();
        this.searchQuery = search;
    };
    SelectSource.prototype.loadMore = function () {
        var _this = this;
        if (!this.isFetchAvailable()) {
            return;
        }
        if (this.loading) {
            this.loadRequested = true;
            return;
        }
        this.loading = true;
        if (this.fetchRequest) {
            this.fetchRequest.unsubscribe();
            this.fetchRequest = undefined;
        }
        var sub = this.fetch(this.searchQuery).subscribe(function (options) {
            _this.options = _this.options.concat(options);
            _this.loading = false;
            _this._loaded = true;
            if (_this.fetchRequest === sub) {
                _this.fetchRequest = undefined;
            }
            if (_this.loadRequested) {
                _this.loadRequested = false;
                _this.loadMore();
            }
        }, function () {
            _this.loading = false;
            _this._loaded = true;
            if (_this.fetchRequest === sub) {
                _this.fetchRequest = undefined;
            }
            if (_this.loadRequested) {
                _this.loadRequested = false;
                _this.loadMore();
            }
        });
        this.fetchRequest = sub;
    };
    SelectSource.prototype.loadValue = function (value) {
        var _this = this;
        if (this.loadingValueObs && this.loadingValue === value) {
            // TODO: change to config compare
            return this.loadingValueObs;
        }
        if (this.fetchValueRequest) {
            this.fetchValueRequest.unsubscribe();
            this.fetchValueRequest = undefined;
        }
        this.loadingValue = NotSet;
        this.loadingValueObs = undefined;
        var obs = this.fetchByValue(value).pipe(tap(function (option) {
            _this.valueOption = option;
            if (_this.fetchValueRequest === sub) {
                _this.fetchValueRequest = undefined;
            }
            if (_this.loadingValueObs === obs) {
                _this.loadingValue = NotSet;
                _this.loadingValueObs = undefined;
            }
        }), publishLast(), refCount());
        this.loadingValue = value;
        this.loadingValueObs = obs;
        var sub = obs.subscribe(function () { }, function () {
            if (_this.fetchValueRequest === sub) {
                _this.fetchValueRequest = undefined;
            }
            if (_this.loadingValueObs === obs) {
                _this.loadingValue = NotSet;
                _this.loadingValueObs = undefined;
            }
        });
        this.fetchValueRequest = sub;
        return obs;
    };
    return SelectSource;
}());
export { SelectSource };

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit } from '@angular/core';

import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-grid-stub-item',
  templateUrl: './grid-stub-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridStubItemComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() columns = 5;
  @Input() rowCards = 1;
  @Input() height: number;
  @Input() gapHorizontal: number;
  @Input() gapVertical: number;
  @Input() animating = false;
  @Input() snap = false;
  @Input() snapMargin = false;

  effectiveHeight = 245;

  @HostBinding('class.grid__item') cls = true;
  @HostBinding('class.grid__item_snap') get snapCls() {
    return this.snap;
  }
  @HostBinding('class.grid__item_snap-margin') get snapMarginCls() {
    return this.snapMargin;
  }
  @HostBinding('attr.data-card-width') get dataGridCards() {
    return isSet(this.rowCards) ? true : null;
  }
  @HostBinding('style.padding-left.px') get paddingLeftPx() {
    return isSet(this.gapHorizontal) ? this.gapHorizontal * 0.5 : null;
  }
  @HostBinding('style.padding-right.px') get paddingRightPx() {
    return isSet(this.gapHorizontal) ? this.gapHorizontal * 0.5 : null;
  }
  @HostBinding('style.padding-top.px') get paddingTopPx() {
    return isSet(this.gapVertical) ? this.gapVertical * 0.5 : null;
  }
  @HostBinding('style.padding-bottom.px') get paddingBottomPx() {
    return isSet(this.gapVertical) ? this.gapVertical * 0.5 : null;
  }

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<GridStubItemComponent>): void {
    if (changes.columns || changes.height) {
      if (isSet(this.height)) {
        this.effectiveHeight = this.height;
      } else if (this.columns) {
        this.effectiveHeight = this.columns * 41.5 + (this.columns + 1) * 15;
      } else {
        this.effectiveHeight = 245;
      }
    }

    if (changes.rowCards) {
      if (this.rowCards) {
        this.el.nativeElement.style.setProperty('--card-width', `${100 / this.rowCards}%`);
      } else {
        this.el.nativeElement.style.removeProperty('--card-width');
      }
    }
  }
}

import clone from 'lodash/clone';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';

import { deserializeFieldParamName, lookups, serializeFieldParamName } from '@modules/field-lookups';
import { FilterItem, FilterItem2 } from '@modules/filters';
import { ModelDescription, ORDER_BY_PARAM, PAGE_PARAM, SEARCH_PARAM, SEGMENT_PARAM } from '@modules/models';

export function addFilterItemToQueryParams(queryParams: Object, item: FilterItem): Object {
  const params = {
    ...queryParams,
    [item.getName()]: item.value
  };

  delete params[SEARCH_PARAM];
  delete params[PAGE_PARAM];

  return params;
}

export function removeFilterItemFromQueryParams(queryParams: Object, item: FilterItem): Object {
  const params = { ...queryParams };

  delete params[item.getName()];
  delete params[PAGE_PARAM];

  return params;
}

export function filterItemsToQueryParams(items: FilterItem[]): Object {
  if (!items) {
    return {};
  }
  return items.reduce((obj, item) => {
    obj[item.getName()] = item.value;
    return obj;
  }, {});
}

export function filterItemsFromQueryParams(queryParams: Object): FilterItem[] {
  return toPairs(queryParams)
    .map(queryParam => {
      const result = new FilterItem();
      const value = queryParam[1];
      const { name, lookup, exclude } = deserializeFieldParamName(queryParam[0]);

      if ([PAGE_PARAM, ORDER_BY_PARAM].includes(name)) {
        return;
      }

      if (name == SEARCH_PARAM) {
        result.field = SEARCH_PARAM;
        result.value = value;
        result.exclude = exclude;

        return result;
      }

      result.field = name;
      result.value = value;
      result.lookup = lookups.find(item => item.lookup == lookup);
      result.exclude = exclude;

      return result;
    })
    .filter(item => item);
}

export function applyParamsComputedLookups(params: Object): Object {
  return flow([
    toPairs,
    items =>
      map(items, ([key, value]) => {
        const { name, lookup: lookupName, exclude } = deserializeFieldParamName(key);
        const lookup = lookups.find(item => item.lookup == lookupName);

        if (!lookup || !lookup.compute) {
          return {
            key: key,
            value: value
          };
        }

        return lookup.compute(name.split('__'), value, exclude).map(item => {
          const itemFieldName = item.field.join('__');
          const itemLookup = item.lookup ? item.lookup.lookup : undefined;
          const itemName = serializeFieldParamName(itemFieldName, itemLookup, false);
          return { key: itemName, value: item.value, exclude: item.exclude };
        });
      }),
    flatten,
    items =>
      map(items, item => {
        if (item.exclude) {
          return [`exclude__${item.key}`, item.value];
        } else {
          return [item.key, item.value];
        }
      }),
    fromPairs
  ])(params);
}

export function applyFiltersComputedLookups(filters: FilterItem2[]): FilterItem2[] {
  return filters.reduce((acc, filterItem) => {
    const lookup = lookups.find(item => item === filterItem.lookup);

    if (lookup && lookup.compute) {
      const computedFilterItems = lookup.compute(filterItem.field, filterItem.value, filterItem.exclude).map(item => {
        return new FilterItem2({
          field: item.field,
          lookup: item.lookup,
          value: item.value,
          exclude: item.exclude
        });
      });

      acc.push(...computedFilterItems);
    } else {
      acc.push(filterItem);
    }

    return acc;
  }, []);
}

export function applySegments(params: Object, body: Object, modelDescription: ModelDescription) {
  const innerSegments = [];
  const segmentQueries = [];

  params = flow([
    toPairs,
    items =>
      map(items, ([key, value]) => {
        if (key == SEGMENT_PARAM) {
          const segment = modelDescription.segments.find(i => i.label == value);

          if (!segment) {
            return [];
          }

          const result = [];

          if (segment.sqlQuery) {
            segmentQueries.push({
              name: segment.label,
              query: segment.sqlQuery
            });

            result.push({ key: key, value: value });
          }

          segment.filterItems.forEach(item => {
            if (item.field == SEGMENT_PARAM) {
              innerSegments.push(segment);
            }

            result.push({
              key: item.field,
              value: item.value
            });
          });

          return result;
        } else {
          return { key: key, value: value };
        }
      }),
    flatMap,
    items => map(items, item => [item.key, item.value]),
    fromPairs
  ])(params);

  if (segmentQueries.length) {
    body = clone(body);
    if (body['segments'] == undefined) {
      body['segments'] = [];
    }
    body['segments'] = body['segments'].concat(segmentQueries);
  }

  if (innerSegments.length) {
    const inner = applySegments(params, body, modelDescription);

    params = inner.params;
    body = inner.body;
  }

  return {
    params: params,
    body: body
  };
}

<div class="sidebar-list-item sidebar-list-item_clickable">
  <div class="sidebar-list-item__left">
    <span
      class="button button_icon button_small"
      [ngClass]="{
        'color_blue_1 background-color_blue_2': item.status == statuses.Pending,
        'color_yellow_1 background-color_yellow_2': item.status == statuses.Running,
        'color_green_1 background-color_green_2': item.status == statuses.Succeeded,
        'color_red_1 background-color_red_2': item.status == statuses.Failed
      }"
    >
      <span
        class="button__icon"
        [class.icon-time]="item.status == statuses.Pending"
        [class.icon-gear_2]="item.status == statuses.Running"
        [class.icon-check_circle]="item.status == statuses.Succeeded"
        [class.icon-close]="item.status == statuses.Failed"
      ></span>
    </span>
  </div>
  <div class="sidebar-list-item__main">
    <div *ngIf="item.getLastDate()" class="sidebar-list-item__title">
      {{ item.getLastDate() | appFormatDate: 'lll' }}
    </div>
    <div class="sidebar-list-item__description">
      {{ item.getStatusLabel() | appCapitalize }}<ng-container *ngIf="duration"> ({{ duration }})</ng-container>
    </div>
  </div>
  <div class="sidebar-list-item__right">
    <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
  </div>
</div>

<div class="card-table-title">
  <div class="card-table-title__left">
    <div
      class="card-table-title__color"
      [ngClass]="'background-color_' + environmentData.environment.color + '_1'"
    ></div>
  </div>
  <div class="card-table-title__main">
    <div class="card-table-title__title">{{ environmentData.environment.name }}</div>
    <div class="card-table-title__title-additional">
      ({{ environmentData.count | i18nPlural: { '=1': '# member', other: '# members' } }})
    </div>
  </div>
</div>

<table *ngIf="items" class="card-table card-table_compact">
  <tbody>
    <tr *ngFor="let item of items" class="card-table__row">
      <td class="card-table__column" style="width: 50px;">
        <div class="card-table__column-inner">
          <span
            class="user-photo user-photo_small"
            [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
          >
          </span>
        </div>
      </td>

      <td class="card-table__column">
        <div class="card-table__column-inner" style="padding-left: 0;">
          <div class="table-user">
            <div class="table-user__title">
              {{ item.getFullName() }}
              <span
                *ngIf="(currentUserStore.instance$ | async)?.uid == item.user?.uid"
                class="table-user__title-comment"
              >
                ({{ 'You' | localize }})
              </span>
            </div>
            <div class="table-user__subtitle">{{ item.getEmail() }}</div>
          </div>
        </div>
      </td>
    </tr>

    <tr *ngIf="loading" class="card-table__row">
      <td class="card-table__column" style="width: 50px;">
        <div class="card-table__column-inner"></div>
      </td>

      <td class="card-table__column">
        <div class="card-table__column-inner" style="padding-left: 0;">
          <span [class.loading-animation]="true"><span class="stub-text">First name</span></span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="hasMore && !loading" style="margin-top: 10px; text-align: center;">
  <a
    href="javascript:void(0)"
    class="button button_background button_small button_fill"
    (click)="userListStore.getNext()"
  >
    <span class="button__label">Load more</span>
  </a>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../project-resource-data-templates-table-item/project-resource-data-templates-table-item.component.ngfactory";
import * as i3 from "../project-resource-data-templates-table-item/project-resource-data-templates-table-item.component";
import * as i4 from "./project-resource-data-templates-table.component";
var styles_ProjectResourceDataTemplatesTableComponent = [];
var RenderType_ProjectResourceDataTemplatesTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectResourceDataTemplatesTableComponent, data: {} });
export { RenderType_ProjectResourceDataTemplatesTableComponent as RenderType_ProjectResourceDataTemplatesTableComponent };
function View_ProjectResourceDataTemplatesTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "th", [["class", "table__heading-column"]], [[2, "table__heading-column_disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "table__heading-column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "table__heading-column-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "table__heading-icon"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_1 = "table__heading-icon"; var currVal_2 = ("icon-" + _v.context.$implicit.item.fieldDescription.icon); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = false; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); }); }
function View_ProjectResourceDataTemplatesTableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tr", [["app-project-resource-data-templates-table-item", ""], ["class", "table__row"]], null, null, null, i2.View_ProjectResourceDataTemplatesTableItemComponent_0, i2.RenderType_ProjectResourceDataTemplatesTableItemComponent)), i0.ɵdid(1, 638976, null, 0, i3.ProjectResourceDataTemplatesTableItemComponent, [], { row: [0, "row"], columns: [1, "columns"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.columns; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectResourceDataTemplatesTableComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "table", [["class", "table table_grid table_compact"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "tr", [["class", "table__heading-row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modelDescription.fields; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.rows; var currVal_2 = _co.rowTrackByFn; _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_ProjectResourceDataTemplatesTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-resource-data-templates-table", [], null, null, null, View_ProjectResourceDataTemplatesTableComponent_0, RenderType_ProjectResourceDataTemplatesTableComponent)), i0.ɵdid(1, 638976, null, 0, i4.ProjectResourceDataTemplatesTableComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectResourceDataTemplatesTableComponentNgFactory = i0.ɵccf("app-project-resource-data-templates-table", i4.ProjectResourceDataTemplatesTableComponent, View_ProjectResourceDataTemplatesTableComponent_Host_0, { modelDescription: "modelDescription", data: "data" }, {}, []);
export { ProjectResourceDataTemplatesTableComponentNgFactory as ProjectResourceDataTemplatesTableComponentNgFactory };

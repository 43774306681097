import { OnInit } from '@angular/core';
var PageColumnsComponent = /** @class */ (function () {
    function PageColumnsComponent() {
        this.cls = true;
        this.border = false;
        this.disabled = false;
    }
    Object.defineProperty(PageColumnsComponent.prototype, "paddingSizeNone", {
        get: function () {
            return this.innerPaddingSize == 'none';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageColumnsComponent.prototype, "paddingSizeXs", {
        get: function () {
            return this.innerPaddingSize == 'xs';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageColumnsComponent.prototype, "paddingSizeS", {
        get: function () {
            return this.innerPaddingSize == 's';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageColumnsComponent.prototype, "paddingSizeM", {
        get: function () {
            return this.innerPaddingSize == 'm';
        },
        enumerable: true,
        configurable: true
    });
    PageColumnsComponent.prototype.ngOnInit = function () { };
    return PageColumnsComponent;
}());
export { PageColumnsComponent };

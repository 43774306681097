<div *ngIf="!loadingValue && state.formField" class="field">
  <label class="field__label">
    <span class="field__label-text">{{ state.formField.label }}</span>
    <span
      *ngIf="tooltip | appIsSet"
      class="field__label-help help-icon icon-help"
      [appTip]="tooltip"
      [appTipOptions]="{ side: 'top' }"
    ></span>
  </label>

  <div class="field__content">
    <app-auto-field
      *ngIf="state.formField.field == fieldTypes.Boolean"
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: state.formField.name,
          field: fieldTypes.Select,
          params: {
            valueEquals: booleanValueEquals,
            options: [
              { value: null, name: 'Not specified' },
              { value: false, name: 'No' },
              { value: true, name: 'Yes' }
            ],
            classes: ['select_fill']
          },
          reset_enabled: true
        })
      "
      [tooltip]="tooltip"
      [accentColor]="accentColor"
    ></app-auto-field>

    <app-auto-field
      *ngIf="state.formField.field != fieldTypes.Boolean"
      [form]="form"
      [field]="state.formField"
      [label]="false"
      [readonly]="false"
      [manualMargin]="true"
      [context]="context"
      [contextElement]="viewContextElement"
      [tooltip]="tooltip"
      [accentColor]="accentColor"
    >
    </app-auto-field>
  </div>
  <app-field-errors [form]="form" [fieldName]="field.name"></app-field-errors>
</div>

<div *ngIf="loadingValue" class="field">
  <label class="field__label">
    <span *ngIf="state.formField?.label" class="stub-text">
      {{ state.formField?.label }}
    </span>

    <ng-container *ngIf="!state.formField?.label">
      {{ state.formField?.label }}
    </ng-container>
  </label>
  <app-auto-field-stub
    [field]="state.formField"
    [label]="false"
    [readonly]="false"
    [manualMargin]="true"
  ></app-auto-field-stub>
</div>

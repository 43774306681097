import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { Border } from '@modules/views';

import { BorderControl } from './border.control';

export class BorderArray extends FormArray {
  controls: BorderControl[];

  deserialize(value: Border[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): Border[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: BorderControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: BorderControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: Border): BorderControl {
    const control = new BorderControl();

    if (!item) {
      item = new Border();
    }

    control.deserialize(item);
    control.markAsPristine();

    return control;
  }

  appendControl(item?: Border): BorderControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  prependControl(item?: Border): BorderControl {
    const control = this.createControl(item);
    this.insert(0, control);
    return control;
  }
}

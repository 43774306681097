<div class="sidebar__element">
  <app-sidebar-field [label]="'multiple lines'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'multiline',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>

    <ng-container description>
      Long text field that can span multiple lines
    </ng-container>
  </app-sidebar-field>
</div>

<div *ngIf="form.form.value['multiline']" class="sidebar__element">
  <app-sidebar-field
    [label]="editable ? 'number of lines' : 'limit number of lines'"
    [additional]="editable ? undefined : '(optional)'"
  >
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'rows',
          field: 'IntegerField',
          placeholder: editable ? 'Default is 8' : 'Leave blank to disable limit',
          params: { classes: ['input_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<app-value-format-edit
  *ngIf="!editable"
  [control]="form.form.controls['value_format']"
  [field]="fieldTypes.Text"
  [formatInputEnabled]="true"
  [prefixEnabled]="true"
  [postfixEnabled]="true"
  [numberFormatOptionsEnabled]="true"
  [context]="context"
  [analyticsSource]="analyticsSource"
></app-value-format-edit>

<div
  class="view-editor-header-main-button icon-canvas"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="menu_root.setDropdownOpened(true)"
>
  <span class="view-editor-header-main-button__arrow icon-arrow_down_2"></span>
</div>

<app-view-editor-menu-root
  [trigger]="trigger"
  [componentLabel]="componentLabel"
  [submitLabel]="submitLabel"
  [stateSelectedEnabled]="stateSelectedEnabled"
  [templatesEnabled]="templatesEnabled"
  [analyticsSource]="analyticsSource"
  (saveEditor)="saveEditor.emit()"
  (closeEditor)="closeEditor.emit()"
  (rename)="rename.emit()"
  (importFigmaNode)="importFigmaNode.emit()"
  (importSketchFile)="importSketchFile.emit()"
  #menu_root
></app-view-editor-menu-root>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var TranslatableFieldComponent = /** @class */ (function (_super) {
    __extends(TranslatableFieldComponent, _super);
    function TranslatableFieldComponent(cd) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.languages = [
            { code: 'en', name: 'English' },
            { code: 'ru', name: 'Russian' }
        ];
        _this.values = {};
        return _this;
    }
    TranslatableFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentLanguageCode = this.languages[0].code;
        if (this.form) {
            this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFormValue(); });
        }
        this.updateFormValue();
    };
    TranslatableFieldComponent.prototype.ngOnDestroy = function () { };
    TranslatableFieldComponent.prototype.selectLanguage = function (code) {
        this.currentLanguageCode = code;
        this.cd.markForCheck();
    };
    TranslatableFieldComponent.prototype.updateFormValue = function () {
        var _this = this;
        this.values = fromPairs(this.languages.map(function (item) {
            var value;
            if (_this.form) {
                value = _this.form.value[_this.field.name] ? _this.form.value[_this.field.name][item.code] : undefined;
            }
            else {
                value = _this.value;
            }
            return [item.code, value || ''];
        }));
        this.cd.markForCheck();
    };
    TranslatableFieldComponent.prototype.updateValue = function (code, value) {
        this.values[code] = value;
        var data = {};
        data[this.field.name] = this.values;
        this.form.patchValue(data);
    };
    Object.defineProperty(TranslatableFieldComponent.prototype, "str", {
        get: function () {
            if (this.values[this.currentLanguageCode][this.currentLanguageCode]) {
                return this.values[this.currentLanguageCode][this.currentLanguageCode];
            }
            var nonEmpty = flow([toPairs, function (items) { return filter(items, function (item) { return item[1]; }); }])(this.values[this.currentLanguageCode]);
            return nonEmpty.length ? [0][1] : '';
        },
        enumerable: true,
        configurable: true
    });
    return TranslatableFieldComponent;
}(FieldComponent));
export { TranslatableFieldComponent };
registerFieldComponent(FieldType.Translatable, TranslatableFieldComponent);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./custom-view-templates-action-card.component";
var styles_CustomViewTemplatesActionCardComponent = [];
var RenderType_CustomViewTemplatesActionCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewTemplatesActionCardComponent, data: {} });
export { RenderType_CustomViewTemplatesActionCardComponent as RenderType_CustomViewTemplatesActionCardComponent };
export function View_CustomViewTemplatesActionCardComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "marketplace-action-card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "marketplace-action-card__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "marketplace-action-card__content-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "marketplace-action-card__icon icon-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "marketplace-action-card__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["New component"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "marketplace-action-card__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["from scratch"]))], null, null); }
export function View_CustomViewTemplatesActionCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view-templates-action-card", [], null, null, null, View_CustomViewTemplatesActionCardComponent_0, RenderType_CustomViewTemplatesActionCardComponent)), i0.ɵdid(1, 114688, null, 0, i1.CustomViewTemplatesActionCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomViewTemplatesActionCardComponentNgFactory = i0.ɵccf("app-custom-view-templates-action-card", i1.CustomViewTemplatesActionCardComponent, View_CustomViewTemplatesActionCardComponent_Host_0, {}, {}, []);
export { CustomViewTemplatesActionCardComponentNgFactory as CustomViewTemplatesActionCardComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "../../../field-components/components/field-wrapper/field-wrapper.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../../field-components/components/field-wrapper/field-wrapper.component";
import * as i5 from "../../../field-components/components/custom-select/custom-select.component.ngfactory";
import * as i6 from "../../../field-components/components/custom-select/custom-select.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../shared/pipes/deploy-url/deploy-url.pipe";
import * as i9 from "./resource-field.component";
import * as i10 from "../../../projects/stores/current-project.store";
import * as i11 from "../../../projects/stores/current-environment.store";
import * as i12 from "../../services/resource-edit-controller/resource-edit.controller";
import * as i13 from "../../services/resource-summary/resource-summary.service";
import * as i14 from "../../../features/services/feature/feature.service";
import * as i15 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i16 from "../../../../core/services/local-storage/local.storage";
var styles_ResourceFieldComponent = [];
var RenderType_ResourceFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceFieldComponent, data: {} });
export { RenderType_ResourceFieldComponent as RenderType_ResourceFieldComponent };
function View_ResourceFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "control__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "button button_bigger button_icon control__button"], ["href", "javascript:void(0)"]], [[2, "button_orange", null], [2, "button_orange-alternative", null], [2, "button_primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openResourceSettings(_co.valueResource.typeItem, _co.valueResource) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon-gear button__icon"]], null, null, null, null, null))], function (_ck, _v) { var currVal_3 = "Edit resource"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card; var currVal_1 = !_co.card; var currVal_2 = !_co.card; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ResourceFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "app-field-wrapper", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FieldWrapperComponent_0, i2.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i0.ɵdid(3, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), i0.ɵdid(4, 245760, null, 0, i4.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], label: [3, "label"], errors: [4, "errors"], manualMargin: [5, "manualMargin"], idToken: [6, "idToken"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 7, "div", [["class", "control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "control__input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "app-custom-select", [], null, [[null, "optionClick"], [null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("optionClick" === en)) {
        var pd_0 = (_co.onOptionClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("buttonClick" === en)) {
        var pd_1 = (_co.onButtonClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_CustomSelectComponent_0, i5.RenderType_CustomSelectComponent)), i0.ɵdid(8, 770048, null, 0, i6.CustomSelectComponent, [i0.ChangeDetectorRef], { control: [0, "control"], items: [1, "items"], emptyLabel: [2, "emptyLabel"], chooseLabel: [3, "chooseLabel"], classes: [4, "classes"], panelClasses: [5, "panelClasses"] }, { optionClick: "optionClick", buttonClick: "buttonClick" }), i0.ɵpad(9, 1), i0.ɵpad(10, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceFieldComponent_2)), i0.ɵdid(12, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.field; var currVal_10 = _co.readonly; var currVal_11 = _co.label; var currVal_12 = _co.errors; var currVal_13 = _co.manualMargin; var currVal_14 = _co.idToken; _ck(_v, 4, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.control; var currVal_16 = _co.resourceItems; var currVal_17 = "No resources"; var currVal_18 = "Choose resource"; var currVal_19 = _ck(_v, 9, 0, "select_fill"); var currVal_20 = _ck(_v, 10, 0, "mat-menu-panel_sidebar"); _ck(_v, 8, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = ((_co.valueResource && _co.valueResourceSettings) && !_co.valueResource.params["demo"]); _ck(_v, 12, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 3).ngClassValid; var currVal_5 = i0.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ResourceFieldComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [["class", "choose-items-item__dropdown-icon"]], [[8, "src", 4]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 0), (("/assets/images/resources/icons/" + _co.valueIcon) + ".svg"))); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceFieldComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valueStr; _ck(_v, 1, 0, currVal_0); }); }
function View_ResourceFieldComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "loading-animation"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Record Name"]))], null, null); }
function View_ResourceFieldComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-field-wrapper", [], null, null, null, i2.View_FieldWrapperComponent_0, i2.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i4.FieldWrapperComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], readonly: [2, "readonly"], label: [3, "label"], errors: [4, "errors"], manualMargin: [5, "manualMargin"], idToken: [6, "idToken"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResourceFieldComponent_4)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResourceFieldComponent_5)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResourceFieldComponent_6)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.field; var currVal_2 = _co.readonly; var currVal_3 = _co.label; var currVal_4 = _co.errors; var currVal_5 = _co.manualMargin; var currVal_6 = _co.idToken; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.valueIcon; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.valueStr; _ck(_v, 5, 0, currVal_8); var currVal_9 = !_co.valueStr; _ck(_v, 7, 0, currVal_9); }, null); }
export function View_ResourceFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.DeployUrlPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceFieldComponent_1)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceFieldComponent_3)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readonly; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.readonly; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ResourceFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-resource-field2", [], null, null, null, View_ResourceFieldComponent_0, RenderType_ResourceFieldComponent)), i0.ɵdid(1, 245760, null, 0, i9.ResourceFieldComponent, [i10.CurrentProjectStore, i11.CurrentEnvironmentStore, i12.ResourceEditController, i13.ResourceSummaryService, i14.FeatureService, i15.UniversalAnalyticsService, i0.ChangeDetectorRef, i16.LocalStorage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceFieldComponentNgFactory = i0.ɵccf("app-resource-field2", i9.ResourceFieldComponent, View_ResourceFieldComponent_Host_0, { form: "form", field: "field", readonly: "readonly", value: "value", label: "label", errors: "errors", card: "card", autofocus: "autofocus", context: "context", manualMargin: "manualMargin" }, {}, []);
export { ResourceFieldComponentNgFactory as ResourceFieldComponentNgFactory };

import { OnInit } from '@angular/core';
var PageHeaderComponent = /** @class */ (function () {
    function PageHeaderComponent() {
        this.blockCls = true;
        this.cls = true;
        this.first = false;
        this.last = false;
    }
    Object.defineProperty(PageHeaderComponent.prototype, "marginSizeS", {
        get: function () {
            return this.marginSize == 's';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageHeaderComponent.prototype, "marginSizeM", {
        get: function () {
            return this.marginSize == 'm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageHeaderComponent.prototype, "marginSizeL", {
        get: function () {
            return this.marginSize == 'l';
        },
        enumerable: true,
        configurable: true
    });
    PageHeaderComponent.prototype.ngOnInit = function () { };
    return PageHeaderComponent;
}());
export { PageHeaderComponent };

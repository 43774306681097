import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { getCurrentLanguage } from '@common/localize';
import { ApiService } from '@modules/api';
import { Environment, Project, ProjectService } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

interface Globals {
  auth: {
    getUserToken(): string;
    getProjectToken(): string;
  };
  user?: {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  project: {
    instance?: {
      uniqueName: string;
    };
    hardDelete(): Promise<boolean>;
  };
  projectUser?: {
    uid: string;
  };
  projectGroup?: {
    uid: string;
  };
  ctx: Object;
  settings: {
    language?: string;
  };
}

function defaultGetProjectToken(): string {
  return undefined;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalsService implements OnDestroy {
  globals: Globals = {
    auth: {
      getUserToken: () => this.apiService.getAccessToken(),
      getProjectToken: defaultGetProjectToken
    },
    user: undefined,
    project: {
      instance: undefined,
      hardDelete: () => {
        if (!this.project) {
          throw new Error('No current project');
        }

        return this.projectService.delete(this.project, { hardDelete: true }).toPromise();
      }
    },
    projectUser: undefined,
    projectGroup: undefined,
    ctx: {},
    settings: {}
  };
  project: Project;

  constructor(
    private currentUserStore: CurrentUserStore,
    private apiService: ApiService,
    private projectService: ProjectService
  ) {
    this.currentUserStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.globals.user = user
          ? {
              uid: user.uid,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName
            }
          : undefined;

        this.globals.settings = {
          language: getCurrentLanguage()
        };
      });

    window['jet'] = this.globals;
  }

  ngOnDestroy(): void {}

  setContext(ctx: Object) {
    this.globals.ctx = ctx;
  }

  clearContext() {
    this.globals.ctx = {};
  }

  setProject(project: Project) {
    this.project = project;
    this.globals.project.instance = project
      ? {
          uniqueName: project.uniqueName
        }
      : undefined;
  }

  clearProject() {
    this.project = undefined;
    this.globals.project.instance = undefined;
  }

  setEnvironment(environment: Environment) {
    this.globals.projectUser =
      environment && environment.user
        ? {
            uid: environment.user.uid
          }
        : undefined;
    this.globals.projectGroup =
      environment && environment.group
        ? {
            uid: environment.group.uid
          }
        : undefined;
  }

  clearEnvironment() {
    this.globals.projectUser = undefined;
    this.globals.projectGroup = undefined;
  }

  setGetProjectToken(getProjectToken: () => string) {
    this.globals.auth.getProjectToken = getProjectToken;
  }

  clearGetProjectToken() {
    this.globals.auth.getProjectToken = defaultGetProjectToken;
  }
}

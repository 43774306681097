import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { AnalyticsEventAction, AnalyticsEventType } from '../../data/event';

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {
  sendEvent(event: string, category: AnalyticsEventType, action: AnalyticsEventAction, label?: string, params = {}) {
    const data = {
      event: event,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      ...params
    };

    if (environment.production) {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(data);
    } else {
      console.debug('sendEvent', data);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import { map } from 'rxjs/operators';
import { ListStore } from '@shared';
// TODO: Refactor import
import { PAGE_PARAM, PER_PAGE_PARAM } from '../../models/data/params';
import { ProjectBillingService } from '../services/project-billing/project-billing.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var SubscriptionEnvironmentUserListStore = /** @class */ (function (_super) {
    __extends(SubscriptionEnvironmentUserListStore, _super);
    function SubscriptionEnvironmentUserListStore(currentProjectStore, currentEnvironmentStore, projectBillingService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectBillingService = projectBillingService;
        _this.params = {};
        return _this;
    }
    SubscriptionEnvironmentUserListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params['environment'] = this.environment;
        params[PAGE_PARAM] = page;
        params[PER_PAGE_PARAM] = this.perPage;
        return params;
    };
    SubscriptionEnvironmentUserListStore.prototype.fetchPage = function (page, next) {
        var _this = this;
        var params = this.paramsForPage(page);
        return this.projectBillingService
            .getSubscriptionUsers(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, params)
            .pipe(map(function (response) {
            var users = response.environments.find(function (item) { return item.environment.uniqueName == _this.environment; });
            if (!users) {
                return {
                    items: [],
                    hasMore: false,
                    count: 0
                };
            }
            var totalPages = Math.ceil(users.count / users.pageSize);
            return {
                items: users.results,
                hasMore: users.next,
                totalPages: totalPages,
                perPage: users.pageSize,
                count: users.count
            };
        }));
    };
    return SubscriptionEnvironmentUserListStore;
}(ListStore));
export { SubscriptionEnvironmentUserListStore };

import isArray from 'lodash/isArray';

import { environment } from '@env/environment';

export function isSet(value: any, strict = false) {
  if (strict) {
    return !(value === null || value === undefined);
  } else {
    return !(value === null || value === undefined || value === '');
  }
}

export function firstSet(...values: any) {
  return values.find(item => isSet(item));
}

export function isEmptyArray(value) {
  return isArray(value) && !value.length;
}

export function arrayMove<T = any>(array: T[], index: number, newIndex: number): T[] {
  if (newIndex > index) {
    return [...array.slice(0, index), ...array.slice(index + 1, newIndex), array[index], ...array.slice(newIndex)];
  } else if (newIndex == index) {
    return [...array.slice(0, newIndex), array[index], ...array.slice(newIndex, index), ...array.slice(index + 1)];
  } else {
    return array;
  }
}

export function arraySet<T = any>(array: T[], items: T[]): T[] {
  array.splice(0, array.length, ...items);
  return array;
}

export function arrayReverse<T = any>(array: T[], reverse = true): T[] {
  if (reverse) {
    return [...array].reverse();
  } else {
    return [...array];
  }
}

export function isValidNumber(value: any) {
  if (typeof value == 'string') {
    value = value.replace(',', '.');
  }

  return !isNaN(parseFloat(value));
}

export function splitmax(str: string, separator: string, limit?: number): string[] {
  const result = str.split(separator);

  if (limit !== undefined && result.length > limit) {
    return [...result.slice(0, limit - 1), result.slice(limit - 1).join(separator)];
  } else {
    return result;
  }
}

export function coerceArray<T = any>(obj: T | T[]): T[] {
  return isArray(obj) ? obj : [obj];
}

export function instanceOf(obj: any, classes: any | any[]) {
  if (isArray(classes)) {
    return classes.some(cls => obj instanceof cls);
  } else {
    return obj instanceof classes;
  }
}

export function forceString(value: any) {
  if (value === null || value === undefined) {
    return '';
  }

  return String(value);
}

export function getCircleIndex<T = any>(array: T[], index: number): T {
  return array[index % array.length];
}

export function arrayStartsWith(array: unknown[], startsWithArray: unknown[]): boolean {
  return startsWithArray.every((item, i) => array[i] == item);
}

export function deployUrl(url: string): string {
  if (!environment['deployUrl'] || typeof url !== 'string') {
    return url;
  }

  if (url.startsWith('/')) {
    return `${environment['deployUrl']}${url.substring(1)}`;
  } else {
    return url;
  }
}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { ViewSettings, ViewSettingsType } from '../data/view-settings/base';
import { ChangeViewSettings } from '../data/view-settings/change';
import { ListViewSettings } from '../data/view-settings/list';
import { ViewSettingsService } from '../services/view-settings/view-settings.service';

@Injectable()
export class ViewSettingsStore extends SingletonStore<ViewSettings[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private viewSettingsService: ViewSettingsService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<ViewSettings[]> {
    return this.mode$.pipe(
      switchMap(mode =>
        this.viewSettingsService.get(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          mode == AdminMode.Builder
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ViewSettings[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ViewSettings[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getDetailFirst<T extends ViewSettings = ViewSettings>(
    uniqueName: string,
    view?: ViewSettingsType,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<T> {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.uniqueName == uniqueName && (!view || item.view == view)) as T;
      })
    );
  }

  getDetailByUid<T extends ViewSettings = ViewSettings>(
    uid: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<T> {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.uid == uid) as T;
      })
    );
  }

  getDetailByUidFirst<T extends ViewSettings = ViewSettings>(
    uid: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<T> {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.uid == uid) as T;
      })
    );
  }

  getChangeViewSettingsFirst(
    modelId: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<ChangeViewSettings> {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.view == ViewSettingsType.Change && item.isSame(modelId)) as ChangeViewSettings;
      })
    );
  }

  getListViewSettingsFirst(
    modelId: string,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<ListViewSettings> {
    return this.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return result.find(item => item.view == ViewSettingsType.List && item.isSame(modelId)) as ListViewSettings;
      })
    );
  }

  getDistinctUniqueName(
    baseUniqueName: string,
    instance?: ViewSettings,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<string> {
    return this.getFirst(forceUpdate, options).pipe(
      map(viewSettings => {
        const otherViewSettings = viewSettings.filter(item => !instance || item.uid != instance.uid);
        let uniqueName: string;
        let index = 1;
        const match = baseUniqueName.match(/\d+$/);

        if (match) {
          baseUniqueName = baseUniqueName.slice(0, match.index);
          index = parseInt(match[0], 10);
        }

        do {
          uniqueName = index > 1 ? [baseUniqueName, index].join('') : baseUniqueName;
          ++index;
        } while (otherViewSettings.find(item => item.uniqueName == uniqueName));

        return uniqueName;
      })
    );
  }

  getDistinctUniqueNameForName(
    name: string,
    instance?: ViewSettings,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<string> {
    const baseUniqueName = slugify(name, { trim: true }).replace(/-+/g, '-');
    return this.getDistinctUniqueName(baseUniqueName, instance, forceUpdate, options);
  }

  getDistinctName(
    baseName: string,
    instance?: ViewSettings,
    forceUpdate = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<string> {
    return this.getFirst(forceUpdate, options).pipe(
      map(viewSettings => {
        const otherViewSettings = viewSettings.filter(item => !instance || item.uid != instance.uid);
        let name: string;
        let index = 1;

        do {
          name = index > 1 ? `${baseName} ${index}` : baseName;
          ++index;
        } while (otherViewSettings.find(item => item.name == name));

        return name;
      })
    );
  }

  public addItem(viewSettings: ViewSettings) {
    this.instance = [...this.instance, viewSettings];
  }

  public updateItem(viewSettings: ViewSettings) {
    const index = this.instance.findIndex(item => item.uid == viewSettings.uid);

    if (index !== -1) {
      this.instance = [...this.instance.slice(0, index), viewSettings, ...this.instance.slice(index + 1)];
    }
  }
}

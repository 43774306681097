<app-customize-bar-header
  [title]="'Custom Domain'"
  [subtitle]="'App settings'"
  [backEnabled]="false"
></app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section [title]="'Custom Domain'" [description]="'App will be available under this domain'">
    <div class="sidebar__element">
      <app-sidebar-field>
        <div
          class="sidebar-list-item sidebar-list-item_clickable"
          [class.sidebar-list-item_error]="false"
          (click)="editDomain()"
        >
          <ng-container *ngIf="domain">
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>

            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">{{ domain.domain }}</div>
              <div class="sidebar-list-item__description">Domain is configured</div>
            </div>

            <div class="sidebar-list-item__right">
              <a href="javascript:void(0)" class="sidebar-list-item__action icon-edit"> </a>
            </div>
          </ng-container>

          <ng-container *ngIf="!domain">
            <div class="sidebar-list-item__left">
              <span class="sidebar-list-item__icon icon-earth_planet"></span>
            </div>
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title sidebar-list-item__title_secondary">Add custom domain</div>
            </div>

            <div class="sidebar-list-item__right">
              <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
            </div>
          </ng-container>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

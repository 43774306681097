/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/banners/directives/hide-banner/hide-banner.directive";
import * as i2 from "../../../../core/services/local-storage/local.storage";
import * as i3 from "../../../tutorial/directives/show-video/show-video.directive";
import * as i4 from "../../../../common/popups/services/popup/popup.service";
import * as i5 from "@angular/common";
import * as i6 from "./customize-menu-banner.component";
var styles_CustomizeMenuBannerComponent = [];
var RenderType_CustomizeMenuBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeMenuBannerComponent, data: {} });
export { RenderType_CustomizeMenuBannerComponent as RenderType_CustomizeMenuBannerComponent };
function View_CustomizeMenuBannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "menu-pages-banner__close icon-close"], ["href", "javascript:void(0)"]], null, null, null, null, null)), i0.ɵdid(1, 4341760, null, 0, i1.HideBannerDirective, [i0.ElementRef, i2.LocalStorage, i0.ChangeDetectorRef], { appHideBanner: [0, "appHideBanner"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uid; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomizeMenuBannerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "menu-pages-banner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "menu-pages-banner__title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "menu-pages-banner__video-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["class", "menu-pages-banner__video"], ["href", "javascript:void(0)"]], null, null, null, null, null)), i0.ɵdid(4, 4341760, null, 0, i3.ShowVideoDirective, [i0.ElementRef, i4.PopupService, i0.Injector], { appShowVideo: [0, "appShowVideo"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "menu-pages-banner__video-inner"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "menu-pages-banner__video-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "menu-pages-banner__video-icon icon-play_filled"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "menu-pages-banner__border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "menu-pages-banner__description"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeMenuBannerComponent_1)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showVideoOptions; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.uid; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_2 = (("url(" + _co.getViewPreview()) + ")"); _ck(_v, 5, 0, currVal_2); }); }
export function View_CustomizeMenuBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-menu-banner", [], null, null, null, View_CustomizeMenuBannerComponent_0, RenderType_CustomizeMenuBannerComponent)), i0.ɵdid(1, 114688, null, 0, i6.CustomizeMenuBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeMenuBannerComponentNgFactory = i0.ɵccf("app-customize-menu-banner", i6.CustomizeMenuBannerComponent, View_CustomizeMenuBannerComponent_Host_0, { uid: "uid", title: "title", videoPreview: "videoPreview", showVideoOptions: "showVideoOptions" }, {}, ["*"]);
export { CustomizeMenuBannerComponentNgFactory as CustomizeMenuBannerComponentNgFactory };

<app-page-template-preview-page
  class="choose-template__detail-browsers-item-inner"
  [page]="page"
  [context]="context"
  [header]="false"
></app-page-template-preview-page>

<div
  class="choose-template__detail-browsers-item-label"
  [class.choose-template__detail-browsers-item-label_visible]="labelsVisible"
>
  {{ page.name | appCapitalize }}
</div>

import { isSet } from '@shared';

export class RateLimit {
  actions: number;
  perMs: number;

  constructor(options: Partial<RateLimit> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): RateLimit {
    this.actions = data['actions'];
    this.perMs = data['per_ms'];

    return this;
  }

  serialize(): Object {
    return {
      actions: this.actions,
      per_ms: this.perMs
    };
  }

  isSet() {
    return isSet(this.actions) && isSet(this.perMs);
  }
}

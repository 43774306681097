<form [formGroup]="form.form" (ngSubmit)="submit()">
  <app-popup2 [size]="'s'">
    <app-popup2-header>
      <app-popup2-title>Move Records</app-popup2-title>
    </app-popup2-header>

    <ng-container *ngIf="!executeActionStarted">
      <app-loader *ngIf="loading"></app-loader>

      <ng-container *ngIf="!loading">
        <app-page-block>
          <div class="field">
            <label class="field__label field__label_bright">
              Move
            </label>
            <gxs-select [formControlName]="'move'" [options]="{ theme: 'jet', labels: {} | localizeSelect }">
              <gxs-option [name]="'After'" [value]="'after'"> </gxs-option>
              <gxs-option [name]="'Before'" [value]="'before'"> </gxs-option>
              <gxs-option [name]="'To the beginning'" [value]="'first'"> </gxs-option>
              <gxs-option [name]="'To the end'" [value]="'last'"> </gxs-option>
            </gxs-select>
            <app-field-errors [form]="form.form" [fieldName]="'move'"></app-field-errors>
          </div>
        </app-page-block>

        <app-page-block *ngIf="form.modelNeeded">
          <div class="field">
            <label class="field__label field__label_bright"> {{ form.form.value['move'] }} Record </label>
            <app-auto-field
              [form]="form.form"
              [label]="false"
              [field]="
                createField({
                  name: 'model',
                  label: 'Record',
                  field: 'ForeignKey',
                  params: { related_model: modelDescription }
                })
              "
            ></app-auto-field>
          </div>
        </app-page-block>

        <app-page-block *ngIf="firstItems">
          <div class="popup-form__description">
            You are going to Move <strong>{{ modelDescription.verboseNamePlural }}</strong> items: {{ count }}
          </div>
          <div *ngIf="false" class="card">
            <div class="card__inner">
              <ul>
                <li>The following items will be exported:</li>
                <li>---</li>
                <li *ngFor="let item of items">
                  <a
                    [appLink]="item.getLink()"
                    [innerHTML]="item | appModelStr | appStub | async"
                    (click)="close()"
                  ></a>
                </li>
                <li *ngIf="items.length < count">...{{ count - items.length }} more</li>
              </ul>
            </div>
          </div>
        </app-page-block>
      </ng-container>
    </ng-container>

    <app-page-block *ngIf="executeActionStarted">
      <app-circle-progress
        [progress]="executeActionProgress"
        [label]="executeActionSucceeded ? 'Move Completed' : 'Moving...'"
      >
      </app-circle-progress>
    </app-page-block>

    <app-popup2-footer *ngIf="!loading">
      <app-page-block>
        <app-stack [align]="'right'">
          <a href="javascript:void(0)" class="button popup2__footer-item" (click)="close()">Cancel</a>

          <button
            *ngIf="!executeActionSucceeded"
            type="submit"
            class="button button_primary popup2__footer-item"
            [class.button_disabled]="form.form.invalid || executeActionStarted"
          >
            Move
          </button>

          <button
            *ngIf="executeActionSucceeded"
            type="button"
            class="button button_primary popup2__footer-item"
            (click)="close()"
          >
            Continue
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ContrastDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { DataSyncJob } from '@modules/data-sync';
import { Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { DataSyncJobTasksComponent } from '../../components/data-sync-job-tasks/data-sync-job-tasks.component';

@Injectable()
export class DataSyncJobTasksController {
  constructor(
    private injector: Injector,
    private popupService: PopupService,
    private routing: RoutingService,
    private resolver: ComponentFactoryResolver
  ) {}

  openTasks(resource: Resource, job: DataSyncJob): Observable<void> {
    const result = new Subject<void>();

    this.popupService.push({
      component: DataSyncJobTasksComponent,
      popupComponent: ContrastDialogPopupComponent,
      inputs: {
        resource: resource,
        job: job
      },
      outputs: {
        closed: [
          () => {
            result.next();
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result.asObservable();
  }
}

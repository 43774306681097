var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { ViewSettings } from '@modules/customize';
import { MenuPagesService } from '@modules/menu';
import { controlValue, isSet, KeyboardEventKeyCode, objectsSortPredicate } from '@shared';
var ChoosePageDropdownComponent = /** @class */ (function () {
    function ChoosePageDropdownComponent(menuPagesService, cd) {
        this.menuPagesService = menuPagesService;
        this.cd = cd;
        this.currentPageAllowed = true;
        this.brightShadow = false;
        this.chosen = new EventEmitter();
        this.cls = true;
        this.smallBorderRadius = true;
        this.loading = false;
        this.searchControl = new FormControl('');
        this.groups$ = new BehaviorSubject([]);
        this.filteredGroups = [];
    }
    ChoosePageDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getGroups();
        combineLatest(this.groups$, controlValue(this.searchControl).pipe(debounceTime(10)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var groups = _a[0], search = _a[1];
            return _this.updateFiltered(groups, search);
        });
    };
    ChoosePageDropdownComponent.prototype.ngOnDestroy = function () { };
    ChoosePageDropdownComponent.prototype.getGroups = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.menuPagesService
            .getGroups()
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (groups) {
            _this.groups$.next(groups);
            _this.updateFiltered(groups);
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ChoosePageDropdownComponent.prototype.updateFiltered = function (groups, search) {
        if (search === void 0) { search = ''; }
        var compareObj = function (links) {
            return links.map(function (item) { return ({ title: item.title, link: item.link }); }).sort(objectsSortPredicate('title'));
        };
        var result = this.groups$.value;
        var searchClean = search.toLowerCase().trim();
        var duplicateGroup = groups.find(function (item) { return item.duplicate; });
        var otherItems = groups
            .filter(function (item) { return !item.duplicate; })
            .reduce(function (acc, item) {
            acc.push.apply(acc, item.items);
            return acc;
        }, []);
        if (isSet(searchClean)) {
            result = result
                .filter(function (group) { return !group.searchIgnore; })
                .map(function (group) {
                return __assign({}, group, { items: group.items.filter(function (item) {
                        return item.title.toLowerCase().includes(searchClean);
                    }) });
            });
        }
        else if (duplicateGroup && isEqual(compareObj(duplicateGroup.items), compareObj(otherItems))) {
            result = [duplicateGroup];
        }
        this.filteredGroups = result.filter(function (group) { return group.items.length; });
        this.cd.markForCheck();
    };
    ChoosePageDropdownComponent.prototype.refreshFiltered = function () {
        this.updateFiltered(this.groups$.value, this.searchControl.value);
    };
    ChoosePageDropdownComponent.prototype.resetSearch = function () {
        this.searchControl.patchValue('');
        this.refreshFiltered();
    };
    ChoosePageDropdownComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.resetSearch();
        }
    };
    return ChoosePageDropdownComponent;
}());
export { ChoosePageDropdownComponent };

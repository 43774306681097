<ng-container *ngIf="!responseType || responseType == responseTypes.JSON || responseType == responseTypes.XML">
  <div *ngIf="rows" class="data-table">
    <table class="data-table__table">
      <thead *ngIf="!columns">
        <tr class="data-table__header-row">
          <th class="data-table__header-column data-table__header-column_primary"></th>
        </tr>
      </thead>
      <thead *ngIf="columns">
        <tr class="data-table__header-row">
          <th
            *ngFor="let column of columns; let first = first"
            class="data-table__header-column"
            [class.data-table__header-column_primary]="first"
          >
            <div>{{ column }}</div>
            <div class="data-table__header-column-subtitle">column</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows" class="data-table__row">
          <td
            *ngFor="let column of row; let first = first"
            class="data-table__column"
            [class.data-table__column_primary]="first"
            [title]="column"
          >
            <ng-container *ngIf="column !== undefined && column !== null">{{ column }}</ng-container>
            <ng-container *ngIf="column === null">null</ng-container>
            <ng-container *ngIf="column === undefined">undefined</ng-container>
          </td>
        </tr>
        <tr *ngIf="!rows.length" class="data-table__row">
          <td class="data-table__column" [attr.colspan]="columns?.length || 1">
            No data
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!rows" class="data-table">
    <table class="data-table__table">
      <thead>
        <tr class="data-table__header-row">
          <th
            *ngFor="let column of ['column', 'column', 'column']; let first = first"
            class="data-table__header-column"
            [class.data-table__header-column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of [1, 2, 3, 4]" class="data-table__row">
          <td
            *ngFor="let column of ['contents', 'contents', 'contents']; let first = first"
            class="data-table__column"
            [class.data-table__column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="responseType == responseTypes.Blob">
  <div *ngIf="responseBody" class="data-table">
    <table class="data-table__table">
      <thead>
        <tr class="data-table__header-row">
          <th class="data-table__header-column" [class.data-table__header-column_primary]="true">
            <div>Blob</div>
            <div class="data-table__header-column-subtitle">data</div>
          </th>
          <th class="data-table__header-column">
            <div>Type</div>
            <div class="data-table__header-column-subtitle">mime-type</div>
          </th>
          <th class="data-table__header-column">
            <div>Size</div>
            <div class="data-table__header-column-subtitle">bytes</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="data-table__row">
          <td class="data-table__column" [class.data-table__column_primary]="true">
            <ng-container *ngIf="responseBody !== undefined && responseBody !== null">{{ responseBody }}</ng-container>
            <ng-container *ngIf="responseBody === null">null</ng-container>
            <ng-container *ngIf="responseBody === undefined">undefined</ng-container>
          </td>
          <td class="data-table__column">
            {{ responseBody?.type }}
          </td>
          <td class="data-table__column">
            {{ responseBody?.size }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!responseBody" class="data-table">
    <table class="data-table__table">
      <thead>
        <tr class="data-table__header-row">
          <th
            *ngFor="let column of ['column', 'column', 'column']; let first = first"
            class="data-table__header-column"
            [class.data-table__header-column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of [1, 2, 3, 4]" class="data-table__row">
          <td
            *ngFor="let column of ['contents', 'contents', 'contents']; let first = first"
            class="data-table__column"
            [class.data-table__column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="responseType == responseTypes.Text">
  <div *ngIf="responseBody" class="data-table">
    <table class="data-table__table">
      <thead>
        <tr class="data-table__header-row">
          <th class="data-table__header-column" [class.data-table__header-column_primary]="true">
            <div>Result</div>
            <div class="data-table__header-column-subtitle">data</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="data-table__row">
          <td class="data-table__column" [class.data-table__column_primary]="true">
            <ng-container *ngIf="responseBody !== undefined && responseBody !== null">{{ responseBody }}</ng-container>
            <ng-container *ngIf="responseBody === null">null</ng-container>
            <ng-container *ngIf="responseBody === undefined">undefined</ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!responseBody" class="data-table">
    <table class="data-table__table">
      <thead>
        <tr class="data-table__header-row">
          <th
            *ngFor="let column of ['column', 'column', 'column']; let first = first"
            class="data-table__header-column"
            [class.data-table__header-column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of [1, 2, 3, 4]" class="data-table__row">
          <td
            *ngFor="let column of ['contents', 'contents', 'contents']; let first = first"
            class="data-table__column"
            [class.data-table__column_primary]="first"
          >
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ column }}</span></span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { SSOSettings } from '@modules/sso';

import { SSOSettingsEditPopupComponent } from '../../components/sso-settings-edit-popup/sso-settings-edit-popup.component';

@Injectable()
export class SSOSettingsEditController implements OnDestroy {
  constructor(private injector: Injector, private popupService: PopupService) {}

  ngOnDestroy(): void {}

  create(options: { initialValue: Partial<SSOSettings>; analyticsSource?: string }): Observable<SSOSettings> {
    const result = new ReplaySubject<SSOSettings>();

    this.popupService.push({
      component: SSOSettingsEditPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        ...(options.initialValue && { initialValue: options.initialValue }),
        analyticsSource: options.analyticsSource
      },
      outputs: {
        created: [instance => result.next(instance)]
      },
      injector: this.injector
    });

    return result.asObservable();
  }

  edit(options: {
    sso: SSOSettings;
    analyticsSource?: string;
  }): Observable<{ updated?: SSOSettings; deleted?: SSOSettings }> {
    const result = new ReplaySubject<{ updated?: SSOSettings; deleted?: SSOSettings }>();

    this.popupService.push({
      component: SSOSettingsEditPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        sso: options.sso,
        analyticsSource: options.analyticsSource
      },
      outputs: {
        updated: [instance => result.next({ updated: instance })],
        deleted: [instance => result.next({ deleted: instance })]
      },
      injector: this.injector
    });

    return result.asObservable();
  }
}

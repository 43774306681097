var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { readFileText } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { GoogleCloudStorageResourceSettingsForm } from './google-cloud-storage-resource-settings.form';
var GoogleCloudStorageResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(GoogleCloudStorageResourceSettingsComponent, _super);
    function GoogleCloudStorageResourceSettingsComponent(form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.form = form;
        return _this;
    }
    GoogleCloudStorageResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.form.form.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
    };
    GoogleCloudStorageResourceSettingsComponent.prototype.onFileChange = function (field, el) {
        var _this = this;
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        readFileText(file)
            .pipe(untilDestroyed(this))
            .subscribe(function (content) {
            var _a;
            _this.form.form.patchValue((_a = {}, _a[field] = content, _a));
            _this.form.form.markAsDirty();
        });
    };
    return GoogleCloudStorageResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { GoogleCloudStorageResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.GoogleCloudStorage, GoogleCloudStorageResourceSettingsComponent);

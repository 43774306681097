import { Pipe, PipeTransform } from '@angular/core';

import { deployUrl } from '../../utils/common/common';

@Pipe({
  name: 'appDeployCssUrl'
})
export class DeployCssUrlPipe implements PipeTransform {
  transform(value: string): string {
    return `url(${deployUrl(value)})`;
  }
}

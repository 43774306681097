import { EventEmitter, Type } from '@angular/core';

import { ModelDescription } from '@modules/models';
import { Resource, ResourceName } from '@modules/projects';

export interface ResourceAddModelAddedEvent {
  modelDescription: ModelDescription;
  link?: any[];
  reloadData?: boolean;
}

export interface ResourceAddModelComponent {
  resource?: Resource;
  analyticsSource?: string;
  cancelled: EventEmitter<void>;
  added: EventEmitter<ResourceAddModelAddedEvent>;
}

export interface ResourceAddModelComponentItem {
  name: ResourceName;
  component: Type<ResourceAddModelComponent>;
  uniqueName?: string;
  label: string;
  icon?: string;
  multistep?: boolean;
}

const resourceAddModelComponents: ResourceAddModelComponentItem[] = [];

export function getResourceAddModelComponents(name: ResourceName): ResourceAddModelComponentItem[] {
  return resourceAddModelComponents.filter(item => item.name == name);
}

export function registerResourceAddModelComponent(
  name: ResourceName,
  component: Type<ResourceAddModelComponent>,
  label: string,
  options: { uniqueName?: string; icon?: string; multistep?: boolean } = {}
) {
  resourceAddModelComponents.push({
    name: name,
    component: component,
    uniqueName: options.uniqueName,
    label: label,
    icon: options.icon,
    multistep: options.multistep
  });
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { ViewSettings } from '@modules/customize';
import { MenuGroup, MenuGroupItem, MenuPagesService } from '@modules/menu';
import { controlValue, isSet, KeyboardEventKeyCode, objectsSortPredicate } from '@shared';

@Component({
  selector: 'app-choose-page-dropdown',
  templateUrl: './choose-page-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoosePageDropdownComponent implements OnInit, OnDestroy {
  @Input() currentPage: ViewSettings;
  @Input() currentPageAllowed = true;
  @Input() @HostBinding('class.dropdown-selector_bright-shadow') brightShadow = false;
  @Output() chosen = new EventEmitter<ViewSettings>();

  @HostBinding('class.dropdown-selector') cls = true;
  @HostBinding('class.dropdown-selector_small-border-radius') smallBorderRadius = true;

  loading = false;
  searchControl = new FormControl('');
  groups$ = new BehaviorSubject<MenuGroup[]>([]);
  filteredGroups: MenuGroup[] = [];

  constructor(private menuPagesService: MenuPagesService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.getGroups();

    combineLatest(this.groups$, controlValue<string>(this.searchControl).pipe(debounceTime(10)))
      .pipe(untilDestroyed(this))
      .subscribe(([groups, search]) => this.updateFiltered(groups, search));
  }

  ngOnDestroy(): void {}

  getGroups() {
    this.loading = true;
    this.cd.markForCheck();

    this.menuPagesService
      .getGroups()
      .pipe(first(), untilDestroyed(this))
      .subscribe(
        groups => {
          this.groups$.next(groups);
          this.updateFiltered(groups);
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  updateFiltered(groups: MenuGroup[], search = '') {
    const compareObj = (links: MenuGroupItem[]): Object => {
      return links.map(item => ({ title: item.title, link: item.link })).sort(objectsSortPredicate('title'));
    };

    let result = this.groups$.value;
    const searchClean = search.toLowerCase().trim();
    const duplicateGroup = groups.find(item => item.duplicate);
    const otherItems = groups
      .filter(item => !item.duplicate)
      .reduce((acc, item) => {
        acc.push(...item.items);
        return acc;
      }, []);

    if (isSet(searchClean)) {
      result = result
        .filter(group => !group.searchIgnore)
        .map(group => {
          return {
            ...group,
            items: group.items.filter(item => {
              return item.title.toLowerCase().includes(searchClean);
            })
          };
        });
    } else if (duplicateGroup && isEqual(compareObj(duplicateGroup.items), compareObj(otherItems))) {
      result = [duplicateGroup];
    }

    this.filteredGroups = result.filter(group => group.items.length);
    this.cd.markForCheck();
  }

  refreshFiltered() {
    this.updateFiltered(this.groups$.value, this.searchControl.value);
  }

  resetSearch() {
    this.searchControl.patchValue('');
    this.refreshFiltered();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.resetSearch();
    }
  }
}

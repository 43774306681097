import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ViewContext } from '@modules/customize';
import { FieldInputControl } from '@modules/parameters';

import { ColorControl } from '../../controls/color.control';

@Component({
  selector: 'app-color-with-input-selector',
  templateUrl: './color-with-input-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorWithInputSelectorComponent implements OnInit {
  @Input() colorControl: ColorControl;
  @Input() colorInputEnabledControl: FormControl;
  @Input() colorInputControl: FieldInputControl;
  @Input() viewContext: ViewContext;

  formulaPlaceholder = '"#ff0000"';
  colorInputFocused = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  toggleColorInputEnabled() {
    const value = !this.colorInputEnabledControl.value;

    this.colorInputEnabledControl.patchValue(value);

    if (value) {
      this.colorInputFocused = true;
      this.cd.markForCheck();
    }
  }
}

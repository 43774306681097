import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, map, skip } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { BasePopupComponent } from '@common/popups';
import { InsertTokenService } from '@common/token';
import { ApiService } from '@modules/api';
import { ParameterArray } from '@modules/fields';
import { CurrentProjectStore, Resource } from '@modules/projects';
import { HttpQuery, ObjectQuery, Query, QueryService, QueryType, SortingField, SqlQuery } from '@modules/queries';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';

import { QueryBuilderContext } from '../../data/query-builder-context';
import { QueryBuilderHttpOptions } from '../query-builder-http/query-builder-http.component';
import { QueryBuilderObjectOptions } from '../query-builder-object/query-builder-object.form';
import { QueryBuilderSqlOptions } from '../query-builder-sql/query-builder-sql.component';
import { QueryBuilderForm } from './query-builder.form';

export interface QueryBuilderSaveEvent {
  query: Query;
  context: QueryBuilderContext;
  cancelled?: boolean;
}

export interface QueryBuilderResultEvent {
  saved?: QueryBuilderSaveEvent;
  cancelled?: boolean;
}

export interface QueryBuilderCustomComponent {
  component: DynamicComponentArguments;
  position: 'top' | 'bottom' | 'preview';
}

interface QueryBuilderComponentTab {
  value: QueryType;
  name: string;
}

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  providers: [QueryBuilderForm, InsertTokenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderComponent implements OnInit, OnDestroy {
  @Input() queryClass: Type<Query>;
  @Input() queryTypes: QueryType[] = [];
  @Input() resource: Resource;
  @Input() context: QueryBuilderContext;
  @Input() requireResponse = false;
  @Input() arrayResponse = false;
  @Input() query: Query;
  @Input() currentUserTokens = true;
  @Input() customSections: QueryBuilderCustomComponent[] = [];
  @Input() parametersControl: ParameterArray;
  @Input() httpOptions: QueryBuilderHttpOptions;
  @Input() sqlOptions: QueryBuilderSqlOptions;
  @Input() objectOptions: QueryBuilderObjectOptions = {};
  @Input() source: string;
  @Output() result = new EventEmitter<QueryBuilderResultEvent>();

  queryType = QueryType;
  allTabs: QueryBuilderComponentTab[] = [
    {
      value: QueryType.Simple,
      name: 'Simple'
    },
    {
      value: QueryType.Http,
      name: 'HTTP'
    },
    {
      value: QueryType.SQL,
      name: 'SQL'
    }
  ];
  tabs: QueryBuilderComponentTab[] = [];

  constructor(
    public form: QueryBuilderForm,
    private popupComponent: BasePopupComponent,
    private currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private apiService: ApiService,
    private queryService: QueryService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.queryClass, this.queryTypes, this.query);
    this.context.init(this.form, this.currentUserTokens);

    if (this.form.controls.pagination.value) {
      this.form.controls.pagination_enabled.setValue(true, { emitEvent: false });
    }
    this.tabs = this.allTabs.filter(item => this.queryTypes.includes(item.value));
    this.cd.markForCheck();

    if (this.context) {
      this.context.lastExecutedQuery = undefined;
      this.context.lastExecutedResponse = undefined;

      this.context.lastExecutedQuery$
        .pipe(
          skip(1),
          filter(query => isSet(query)),
          map(query => this.queryService.getAutoDetectColumns(query, true)),
          untilDestroyed(this)
        )
        .subscribe(fields => {
          if (!fields) {
            this.form.controls.sorting_fields.setItems([]);
            this.cd.markForCheck();
            return;
          }

          const currentValue: SortingField[] = this.form.controls.sorting_fields.value || [];
          const value: SortingField[] = fields.map(field => {
            const existingField = currentValue.find(item => item.name == field.name);
            return {
              name: field.name,
              sortable: existingField ? existingField.sortable : true
            };
          });

          this.form.controls.sorting_fields.setItems(value);
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {}

  submit() {
    const query = this.form.getInstance();
    this.result.emit({ saved: { query: query, context: this.context } });
    this.close();
  }

  cancel() {
    this.result.emit({ cancelled: true });
    this.close();
  }

  close() {
    this.popupComponent.close();
  }

  onHttpExecuted(httpQuery: HttpQuery) {
    if (this.context) {
      const query = this.form.getInstance();
      query.httpQuery = httpQuery;
      this.context.lastExecutedQuery = query;
    }
  }

  onSqlExecuted(sqlQuery: SqlQuery) {
    if (this.context) {
      const query = this.form.getInstance();
      query.sqlQuery = sqlQuery;
      this.context.lastExecutedQuery = query;
    }
  }

  onObjectExecuted(objectQuery: ObjectQuery) {
    if (this.context) {
      const query = this.form.getInstance();
      query.objectQuery = objectQuery;
      this.context.lastExecutedQuery = query;
    }
  }
}

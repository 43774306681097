import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-translatable-field',
  templateUrl: 'translatable-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslatableFieldComponent extends FieldComponent implements OnInit, OnDestroy {
  languages = [
    { code: 'en', name: 'English' },
    { code: 'ru', name: 'Russian' }
  ];
  values = {};
  currentLanguageCode: string;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.currentLanguageCode = this.languages[0].code;

    if (this.form) {
      this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFormValue());
    }

    this.updateFormValue();
  }

  ngOnDestroy(): void {}

  selectLanguage(code) {
    this.currentLanguageCode = code;
    this.cd.markForCheck();
  }

  updateFormValue() {
    this.values = fromPairs(
      this.languages.map(item => {
        let value;

        if (this.form) {
          value = this.form.value[this.field.name] ? this.form.value[this.field.name][item.code] : undefined;
        } else {
          value = this.value;
        }

        return [item.code, value || ''];
      })
    );
    this.cd.markForCheck();
  }

  updateValue(code, value) {
    this.values[code] = value;

    const data = {};
    data[this.field.name] = this.values;
    this.form.patchValue(data);
  }

  get str() {
    if (this.values[this.currentLanguageCode][this.currentLanguageCode]) {
      return this.values[this.currentLanguageCode][this.currentLanguageCode];
    }

    const nonEmpty = flow([toPairs, items => filter(items, item => item[1])])(this.values[this.currentLanguageCode]);
    return nonEmpty.length ? [0][1] : '';
  }
}

registerFieldComponent(FieldType.Translatable, TranslatableFieldComponent);

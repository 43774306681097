import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PageLoadingService } from '@common/page-loading';
import { ProjectService } from '../services/project/project.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/project/project.service";
import * as i2 from "../../../common/page-loading/services/page-loading/page-loading.service";
var PublicSettingsResolver = /** @class */ (function () {
    function PublicSettingsResolver(projectService, pageLoadingService) {
        this.projectService = projectService;
        this.pageLoadingService = pageLoadingService;
    }
    PublicSettingsResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var projectName = route.params['project'];
        if (!projectName) {
            return of(undefined);
        }
        var loader = {};
        this.pageLoadingService.addLoader(loader);
        return this.projectService.getPublicSettings(projectName).pipe(catchError(function () { return of(undefined); }), tap(function () { return _this.pageLoadingService.removeLoader(loader); }));
    };
    PublicSettingsResolver.ngInjectableDef = i0.defineInjectable({ factory: function PublicSettingsResolver_Factory() { return new PublicSettingsResolver(i0.inject(i1.ProjectService), i0.inject(i2.PageLoadingService)); }, token: PublicSettingsResolver, providedIn: "root" });
    return PublicSettingsResolver;
}());
export { PublicSettingsResolver };

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { FieldLookup } from './base';

export class LtFieldLookup extends FieldLookup {
  public lookup = 'lt';
  public param = 'lt';
  public verboseName = localize('less than');
  public icon = 'less_than';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not less than {1}', [field, value]);
      } else {
        return localize('is not less than');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is less than {1}', [field, value]);
      } else {
        return localize('less than');
      }
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MenuContext } from '@modules/menu-components';

import { CustomizeBarPagesEditModelControl } from '../customize-bar-pages-edit/model-link-control';

@Component({
  selector: 'app-model-menu-item-popover',
  templateUrl: './model-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelMenuItemPopoverComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditModelControl;
  @Output() close = new EventEmitter<void>();

  analyticsSource = 'model_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

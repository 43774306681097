import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BooleanFieldStyle } from '@modules/field-components';
import {
  createFormFieldFactory,
  DateFormat,
  FieldType,
  registerFieldViewParamsComponent,
  TimeFormat
} from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { DateTimeFieldViewParamsForm } from './date-time-field-view-params.form';

@Component({
  selector: 'app-date-time-field-view-params',
  templateUrl: './date-time-field-view-params.component.html',
  providers: [DateTimeFieldViewParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeViewFieldParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  editable = false;
  booleanFieldStyle = BooleanFieldStyle;
  dateFormats = DateFormat;
  timeFormats = TimeFormat;

  constructor(public form: DateTimeFieldViewParamsForm, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);

    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.editable = (!this.configurable || this.configurable.editable) && field && field['editable'];
        this.cd.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {}
}

registerFieldViewParamsComponent(FieldType.DateTime, DateTimeViewFieldParamsComponent);

import { OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { getCurrentLanguage } from '@common/localize';
import { ApiService } from '@modules/api';
import { ProjectService } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import * as i0 from "@angular/core";
import * as i1 from "../../../users/stores/current-user.store";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../projects/services/project/project.service";
function defaultGetProjectToken() {
    return undefined;
}
var GlobalsService = /** @class */ (function () {
    function GlobalsService(currentUserStore, apiService, projectService) {
        var _this = this;
        this.currentUserStore = currentUserStore;
        this.apiService = apiService;
        this.projectService = projectService;
        this.globals = {
            auth: {
                getUserToken: function () { return _this.apiService.getAccessToken(); },
                getProjectToken: defaultGetProjectToken
            },
            user: undefined,
            project: {
                instance: undefined,
                hardDelete: function () {
                    if (!_this.project) {
                        throw new Error('No current project');
                    }
                    return _this.projectService.delete(_this.project, { hardDelete: true }).toPromise();
                }
            },
            projectUser: undefined,
            projectGroup: undefined,
            ctx: {},
            settings: {}
        };
        this.currentUserStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (user) {
            _this.globals.user = user
                ? {
                    uid: user.uid,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName
                }
                : undefined;
            _this.globals.settings = {
                language: getCurrentLanguage()
            };
        });
        window['jet'] = this.globals;
    }
    GlobalsService.prototype.ngOnDestroy = function () { };
    GlobalsService.prototype.setContext = function (ctx) {
        this.globals.ctx = ctx;
    };
    GlobalsService.prototype.clearContext = function () {
        this.globals.ctx = {};
    };
    GlobalsService.prototype.setProject = function (project) {
        this.project = project;
        this.globals.project.instance = project
            ? {
                uniqueName: project.uniqueName
            }
            : undefined;
    };
    GlobalsService.prototype.clearProject = function () {
        this.project = undefined;
        this.globals.project.instance = undefined;
    };
    GlobalsService.prototype.setEnvironment = function (environment) {
        this.globals.projectUser =
            environment && environment.user
                ? {
                    uid: environment.user.uid
                }
                : undefined;
        this.globals.projectGroup =
            environment && environment.group
                ? {
                    uid: environment.group.uid
                }
                : undefined;
    };
    GlobalsService.prototype.clearEnvironment = function () {
        this.globals.projectUser = undefined;
        this.globals.projectGroup = undefined;
    };
    GlobalsService.prototype.setGetProjectToken = function (getProjectToken) {
        this.globals.auth.getProjectToken = getProjectToken;
    };
    GlobalsService.prototype.clearGetProjectToken = function () {
        this.globals.auth.getProjectToken = defaultGetProjectToken;
    };
    GlobalsService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalsService_Factory() { return new GlobalsService(i0.inject(i1.CurrentUserStore), i0.inject(i2.ApiService), i0.inject(i3.ProjectService)); }, token: GlobalsService, providedIn: "root" });
    return GlobalsService;
}());
export { GlobalsService };

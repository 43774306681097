import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { AdminMode } from '@modules/admin-mode';
import { versionLessThan } from '@modules/api';
import { ViewSettingsType } from '@modules/customize';
import { MenuBlock } from '@modules/menu';
import { Storage } from '@modules/storages';
import { User } from '@modules/users';
import {
  ascComparator,
  initials,
  isHttps,
  isHttpsUrl,
  isProductionLocation,
  isSet,
  Link,
  objectsSortPredicate,
  stripEnd
} from '@shared';

import { Domain } from '../../domain/domain';
import {
  hasEnvironmentModelPermission,
  hasEnvironmentPagePermission,
  hasEnvironmentPermission,
  hasGroupPermission
} from '../utils/permissions';
import { Environment } from './environment';
import { JET_APP_RESOURCE, jetAppResource } from './jet-app.resource';
import { ProjectFeatures } from './project-features';
import { ProjectJob } from './project-job';
import { ProjectPermissions } from './project-permission';
import { ProjectSettings } from './project-settings';
import { ProjectToken } from './project-token';
import { ProjectGroup } from './project-user';
import { Resource } from './resource';
import { ResourceDeploy } from './resource-deploy';
import { ResourceName } from './resource-name';
import { Subscription } from './subscription';
import { PaymentProvider } from './transaction';

// export enum TypesProject {
//   company = 'company',
//   personal = 'personal'
// }

export enum ProjectType {
  InternalTool = 'internal',
  CustomerPortal = 'portal'
}

export enum ProjectDeployment {
  Cloud = 'cloud',
  OnPremise = 'on_premise'
}

export class Project {
  public uniqueName: string;
  public name: string;
  public apiBaseUrl: string;
  public messagesUrl: string;
  public logoColor: string;
  public logo: string;
  public logoFile: File;
  public logoFill = false;
  public url: string;
  public domain: Domain;
  public subdomain: string;
  public defaultGroup: string;
  public owner: User;
  public dateAdd: moment.Moment;
  public parent: number;
  public demo: boolean;
  public isOwner = false;
  public jobs: ProjectJob[] = [];
  public tokens: ProjectToken[] = [];
  public company = false;
  public defaultEnvironment: Environment;
  public environments: Environment[] = [];
  public resources: Resource[] = [];
  public initials: string;
  public trialEnded = false;
  public signUpLink: string;
  public type: ProjectType;
  public deployment: ProjectDeployment;
  public about: string;
  public subscription: Subscription;
  public features = new ProjectFeatures();
  public projectSettings: ProjectSettings[];
  public menuSettings: { blocks: MenuBlock[] };
  public params = {};
  // public website: string;
  // public role: string;

  deserialize(data: Object): Project {
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.apiBaseUrl = data['api_base_url'];
    this.messagesUrl = data['messages_url'] || this.defaultMessagesUrl;
    this.logoColor = data['color'];
    this.logo = data['logo'];
    this.url = data['url'];
    this.defaultGroup = data['default_group'];
    this.dateAdd = data['date_add'] ? moment(data['date_add']) : undefined;
    this.parent = data['parent'];
    this.demo = data['demo'];
    this.isOwner = data['is_owner'];
    this.company = data['company'];
    // this.type = data['type'];
    // this.website = data['website'];
    // this.role = data['role'];

    this.initials = initials(this.name);

    if (data['owner']) {
      this.owner = new User().deserialize(data['owner']);
    }

    if (data['jobs']) {
      this.jobs = data['jobs']
        .map(item => new ProjectJob().deserialize(item))
        .sort((lhs, rhs) => lhs.ordering - rhs.ordering);
    }

    if (data['tokens']) {
      this.tokens = data['tokens'].map(item => new ProjectToken().deserialize(item));
    }

    if (data['default_environment']) {
      this.defaultEnvironment = new Environment().deserialize(data['default_environment']);
    }

    if (data['environments']) {
      this.environments = data['environments'].map(item => new Environment().deserialize(item));
    }

    if (data['resources']) {
      this.resources = data['resources'].map(item => new Resource().deserialize(item)).filter(item => item.typeItem);
    }

    if (data['domain']) {
      if (typeof data['domain'] == 'string') {
        this.domain = new Domain().deserialize({ domain: data['domain'] });
      } else if (isPlainObject(data['domain'])) {
        this.domain = new Domain().deserialize(data['domain']);
      }
    }

    if (data['features']) {
      this.features = new ProjectFeatures().deserialize(data['features']);
    }

    if (data['project_settings']) {
      this.projectSettings = data['project_settings'].map(item => new ProjectSettings().deserialize(item));
    }

    if (data['subscription']) {
      this.subscription = new Subscription().deserialize(data['subscription']);
    }

    if (data['params']) {
      this.params = data['params'];
      this.signUpLink = this.params['sign_up_link'];
      this.type = this.params['type'];
      this.deployment = this.params['deployment'];
      this.about = this.params['about'];
      this.logoFill = this.params['logo_fill'];

      if (this.params['trial_ended']) {
        this.trialEnded = true;
      }

      if (this.params['logo_fill'] != undefined) {
        this.logoFill = this.params['logo_fill'];
      }
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};

    if (this.trialEnded) {
      this.params['trial_ended'] = this.trialEnded;
    }

    if (this.signUpLink) {
      this.params['sign_up_link'] = this.signUpLink;
    }

    if (this.type) {
      this.params['type'] = this.type;
    }

    if (this.deployment) {
      this.params['deployment'] = this.deployment;
    }

    if (this.about) {
      this.params['about'] = this.about;
    }

    if (this.defaultEnvironment) {
      this.params['default_environment'] = this.defaultEnvironment.uniqueName;
    }

    if (this.logoFill) {
      this.params['logo_fill'] = this.logoFill;
    }

    let data: Object = {
      name: this.name,
      unique_name: this.uniqueName,
      api_base_url: this.apiBaseUrl,
      messages_url: this.messagesUrl,
      color: this.logoColor,
      url: this.url,
      subdomain: this.subdomain,
      default_group: this.defaultGroup,
      ...(this.projectSettings && {
        project_settings: this.projectSettings.map(item => item.serialize(['name', 'value']))
      }),
      ...(this.menuSettings && {
        menu_settings: {
          blocks: this.menuSettings.blocks.map(item => item.serialize())
        }
      }),
      params: this.params
      // role: this.role,
      // website: this.website,
      // type: this.type
    };

    if (this.logoFile) {
      data['logo'] = this.logoFile;
    } else if (!this.logo) {
      data['logo'] = null;
    }

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get isHttps() {
    const apiUrls = this.resources.map(item => item.params['url']).filter(item => item);
    return apiUrls.every(item => isHttpsUrl(item));
  }

  get protocol() {
    return this.isHttps && isProductionLocation() ? 'https' : 'http';
  }

  linkEnvironment(fixedEnvironmentName?: string): Environment {
    const defaultEnvironmentName = this.defaultEnvironment ? this.defaultEnvironment.uniqueName : undefined;
    const environmentName = fixedEnvironmentName || window['project_environment'] || defaultEnvironmentName;

    return this.environments.find(item => item.uniqueName == environmentName);
  }

  link(appLink: any[] = [], options: { environmentName?: string; mode?: AdminMode } = {}): any[] {
    const environment = this.linkEnvironment(options.environmentName);
    const version = environment ? environment.version : undefined;

    if (version && versionLessThan(version, '2.1.0')) {
      options.mode = AdminMode.App;
    }

    const defaultMode = AdminMode.App;
    const mode = options.mode || window['mode'] || defaultMode;
    const prefix = version ? [`/v${version}`, mode] : [`/${mode}`];

    if (version && versionLessThan(version, '2.2.6')) {
      return [...prefix, this.uniqueName, ...this.homeLink, ...appLink];
    } else {
      return [
        ...prefix,
        this.uniqueName,
        ...(environment ? [environment.uniqueName] : []),
        ...this.homeLink,
        ...appLink
      ];
    }
  }

  linkWithProtocol(
    appLink: any[] = [],
    options: { environmentName?: string; mode?: AdminMode; allowProtocolChange?: boolean } = {}
  ): Link {
    const link = this.link(appLink, options);
    const protocol = options.allowProtocolChange ? this.protocol : stripEnd(window.location.protocol, ':');

    if (isProductionLocation()) {
      if (this.domain && !this.isCurrentDomain) {
        return {
          href: `${protocol}://${this.domain.actualDomain}${link.join('/')}`
        };
      } else if (options.allowProtocolChange && this.isHttps && !isHttps()) {
        return {
          href: `https://${window.location.host}${link.join('/')}`
        };
      } else if (options.allowProtocolChange && !this.isHttps && isHttps()) {
        return {
          href: `http://${window.location.host}${link.join('/')}`
        };
      }
    }

    const environment = this.linkEnvironment(options.environmentName);
    const version = environment ? environment.version : undefined;

    if (window['version'] != version) {
      return {
        href: `${protocol}://${window.location.host}${link.join('/')}`
      };
    }

    return {
      link: link
    };
  }

  getHomeLink(options: { environmentName?: string; mode?: AdminMode } = {}): any[] {
    if (!this.isCreated) {
      return this.createLink;
    }

    const environment = this.linkEnvironment(options.environmentName);

    if (!environment || !this.hasEnvironmentCustomizationPermission(environment)) {
      options.mode = AdminMode.App;
    }

    return this.link([], options);
  }

  getHomeLinkWithProtocol(options: { environmentName?: string; mode?: AdminMode; builderLink?: any[] } = {}): Link {
    if (!this.isCreated) {
      return { link: this.createLink };
    }

    const environment = this.linkEnvironment(options.environmentName);
    const builderAllowed = environment && this.hasEnvironmentCustomizationPermission(environment);

    if (!builderAllowed) {
      options.mode = AdminMode.App;
    }

    const link = options.builderLink && builderAllowed ? options.builderLink : [];
    return this.linkWithProtocol(link, options);
  }

  get homeLink() {
    return [];
  }

  get createLink() {
    return ['/projects', 'create', this.uniqueName];
  }

  get newPageLink() {
    return ['new-page'];
  }

  get settingsBillingLink() {
    return ['project', 'billing'];
  }

  get settingsBillingInfoLink() {
    return ['project', 'billing', 'info'];
  }

  get settingsBillingPlansLink() {
    return ['project', 'billing', 'plans'];
  }

  get subscriptionExpiredLink() {
    return ['project', 'subscription', 'expired'];
  }

  get settingsBillingActivitiesLink() {
    return ['project', 'billing', 'activities'];
  }

  get settingsBillingDetailsLink() {
    return ['project', 'billing', 'details'];
  }

  get settingsBillingHistoryLink() {
    return ['project', 'billing', 'history'];
  }

  getSettingsBillingTransactionLink(provider: PaymentProvider, providerId: string) {
    return ['project', 'billing', 'transactions', provider, providerId];
  }

  get settingsUsersLink() {
    return ['project', 'users'];
  }

  get settingsSSOLink() {
    return ['project', 'sso'];
  }

  get settingsSSOCreateLink() {
    return ['project', 'sso', 'create'];
  }

  get settingsTasksLink() {
    return ['project', 'tasks'];
  }

  get settingsTasksCreateLink() {
    return ['project', 'tasks', 'create'];
  }

  get settingsGroupsLink() {
    return ['project', 'teams'];
  }

  settingsApiLink(tab?: string) {
    const result = ['project', 'api'];

    if (tab) {
      result.push(tab);
    }

    return result;
  }

  get settingsLink() {
    return ['project', 'settings'];
  }

  get onPremiseLink() {
    return ['project', 'on-premise'];
  }

  get settingsCollectionsLink() {
    return ['project', 'models'];
  }

  get settingsCustomViewsLink() {
    return ['project', 'custom_views'];
  }

  get settingsActionsLink() {
    return ['project', 'actions'];
  }

  get settingsUserActivitiesLink() {
    return ['user_activities'];
  }

  settingsCollaborationLink(tab?: string) {
    const result = ['collaboration'];

    if (tab) {
      result.push(tab);
    }

    return result;
  }

  settingsLayoutLink(tab?: string) {
    const result = ['layout'];

    if (tab) {
      result.push(tab);
    }

    return result;
  }

  settingsSignUpLink(tab?: string) {
    const result = ['signup'];

    if (tab) {
      result.push(tab);
    }

    return result;
  }

  settingsMenuLink(uid?: string) {
    const result = ['menu'];

    if (isSet(uid)) {
      result.push(uid);
    }

    return result;
  }

  get jetAppStorageLink() {
    return ['resources', 'storage'];
  }

  get logsLink() {
    return ['resources', 'logs'];
  }

  get automationsLink() {
    return ['automations'];
  }

  get automationRunsLink() {
    return ['automations', 'runs'];
  }

  get settingsCollaborationMessagesLink() {
    return ['collaboration', 'messages'];
  }

  get settingsCollaborationTasksLink() {
    return ['collaboration', 'tasks'];
  }

  get settingsResourcesLink() {
    return ['resources'];
  }

  get templatesLink() {
    return ['templates'];
  }

  get createTemplateLink() {
    return ['create_template'];
  }

  get setupGuideLink() {
    return ['guide'];
  }

  get createPageLink() {
    return ['new_page'];
  }

  createPageTypeLink(type: ViewSettingsType) {
    return ['new_page', type];
  }

  hasEnvironmentModelPermission(environment: Environment, modelId: string, action: string) {
    return hasEnvironmentModelPermission(environment, modelId, action);
  }

  hasEnvironmentPagePermission(environment: Environment, pageUid: string, action: string) {
    return hasEnvironmentPagePermission(environment, pageUid, action);
  }

  hasProjectSettingsPermission() {
    return this.environments.some(item => hasEnvironmentPermission(item, ProjectPermissions.ProjectSettings));
  }

  hasProjectBillingPermission() {
    return this.environments.some(item => hasEnvironmentPermission(item, ProjectPermissions.ProjectBilling));
  }

  hasGroupCustomizationPermission(group: ProjectGroup) {
    return hasGroupPermission(group, ProjectPermissions.ProjectCustomization);
  }

  hasEnvironmentPermissions(environment: Environment) {
    return !!environment.group;
  }

  hasEnvironmentSettingsPermission(environment: Environment) {
    return hasEnvironmentPermission(environment, ProjectPermissions.ProjectAccess);
  }

  hasEnvironmentAccessPermission(environment: Environment) {
    return hasEnvironmentPermission(environment, ProjectPermissions.ProjectAccess);
  }

  hasEnvironmentCustomizationPermission(environment: Environment) {
    return hasEnvironmentPermission(environment, ProjectPermissions.ProjectCustomization);
  }

  hasProjectPermissions() {
    return (
      this.hasProjectSettingsPermission() ||
      this.hasProjectBillingPermission() ||
      this.environments.some(item => hasEnvironmentPermission(item, ProjectPermissions.ProjectAccess))
    );
  }

  get defaultMessagesUrl() {
    return `${this.apiBaseUrl}messages/`;
  }

  get isCreated() {
    // return this.tokens.find(item => item.activated) != undefined;
    return true;
  }

  get isCurrentDomain() {
    return this.domain && this.domain.actualDomain == window.location.hostname;
  }

  findToken(token: string): ProjectToken {
    return this.tokens.find(item => item.token.replace('-', '').toLowerCase() == token.replace('-', '').toLowerCase());
  }

  get dateCreatedKey() {
    return ['project_created', this.uniqueName].join('_');
  }

  getStorage(
    environmentName: string,
    resourceName: string,
    storageName: string,
    options: { defaultFirst?: boolean; contextResource?: Resource } = {}
  ): { resource: Resource; storage: Storage } {
    const storages = this.getStorages(environmentName);
    const storage = storages.find(
      item => item.resource.uniqueName == resourceName && item.storage.uniqueName == storageName
    );

    if (storage) {
      return storage;
    }

    if (options.defaultFirst) {
      if (options.contextResource && options.contextResource.typeItem.name == ResourceName.Supabase) {
        const supabaseStorage = this.getStorages(environmentName).find(item => {
          return item.resource.typeItem.name == ResourceName.SupabaseStorage;
        });
        if (supabaseStorage) {
          return supabaseStorage;
        }
      }

      return storages[0];
    }
  }

  getStorages(environmentName: string): { resource: Resource; storage: Storage }[] {
    return this.getEnvironmentResources(environmentName)
      .filter(
        item =>
          !(
            item.typeItem &&
            item.typeItem.name == ResourceName.PostgreSQL &&
            item.params['deploy'] == ResourceDeploy.Direct
          )
      )
      .reduce((acc, resource) => {
        acc.push(
          ...resource.storages.map(storage => {
            return {
              resource: resource,
              storage: storage
            };
          })
        );
        return acc;
      }, [])
      .sort((lhs, rhs) => {
        const isJetApp = item => {
          return item.resource.uniqueName == JET_APP_RESOURCE;
        };
        const isJetAppComparator = ascComparator(isJetApp(lhs), isJetApp(rhs));

        if (isJetAppComparator !== 0) {
          return isJetAppComparator * -1;
        }

        const getName = item => {
          return [String(item.resource.name).toLowerCase(), String(item.storage.name).toLowerCase()].join(' - ');
        };

        return ascComparator(getName(lhs), getName(rhs));
      });
  }

  getEnvironmentResources(environmentName: string, options: { includeDeleted?: boolean } = {}) {
    if (!isSet(environmentName)) {
      return [];
    }

    return [
      ...this.resources
        .filter(item => item.environment == environmentName || item.demo)
        .filter(item => options.includeDeleted || !item.deleted),
      jetAppResource
    ];
  }

  getEnvironmentResource(
    environmentName: string,
    resourceName: string,
    options: { includeDeleted?: boolean } = {}
  ): Resource {
    return this.getEnvironmentResources(environmentName, options).find(item => item.uniqueName == resourceName);
  }

  getEnvironmentsSorted(): Environment[] {
    return this.environments.sort(
      objectsSortPredicate((lhs: Environment, rhs: Environment) => {
        const defaultEnvironmentName = this.defaultEnvironment ? this.defaultEnvironment.uniqueName : undefined;

        if (lhs.uniqueName == defaultEnvironmentName && rhs.uniqueName != defaultEnvironmentName) {
          return -1;
        } else if (lhs.uniqueName != defaultEnvironmentName && rhs.uniqueName == defaultEnvironmentName) {
          return 1;
        } else {
          return 0;
        }
      }, 'name')
    );
  }

  isSubscriptionEnded() {
    return this.trialEnded || !this.subscription || !this.subscription.isActive;
  }

  isSubscriptionPastDue() {
    return (
      this.subscription &&
      this.subscription.isActive &&
      this.subscription.dateEnd &&
      this.subscription.dateEnd.clone().add(5, 'minutes').isBefore(moment())
    );
  }

  isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  isDomain(domain: Domain): boolean {
    return this.domain ? this.domain.actualDomain == domain.actualDomain : false;
  }
}

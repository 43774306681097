import { Inject, Injectable, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { ApiInfo } from '@modules/api';
import { Storage } from '@modules/storages';
import { isSet, SingletonStore } from '@shared';

import { Project } from '../../projects/data/project';
import { ProjectService } from '../../projects/services/project/project.service';
import { ProjectGroup, ProjectUser } from '../data/project-user';
import { Resource } from '../data/resource';
import { ProjectsStore } from './projects.store';

@Injectable()
export class CurrentProjectStore extends SingletonStore<Project> implements OnDestroy {
  public uniqueName: string;
  public environmentUniqueName: string;

  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private projectService: ProjectService,
    private projectsStore: ProjectsStore
  ) {
    super();
    this.instance$.pipe(untilDestroyed(this)).subscribe(project => {
      this.projectsStore.current = project;
    });
  }

  protected fetch(): Observable<Project> {
    const getApiInfoKey = (project: Project, resource: Resource) => {
      return [project.uniqueName, resource.environment, resource.uniqueName].join('.');
    };
    const apiInfos = this.instance
      ? this.instance.resources.reduce<{ [k: string]: ApiInfo }>((acc, resource) => {
          const key = getApiInfoKey(this.instance, resource);
          if (resource.apiInfo) {
            acc[key] = resource.apiInfo;
          }
          return acc;
        }, {})
      : {};

    return this.mode$.pipe(
      switchMap(mode =>
        this.projectService.getDetail(this.uniqueName, this.environmentUniqueName, mode == AdminMode.Builder)
      ),
      tap(project => {
        if (!project) {
          return;
        }

        project.resources.forEach(resource => {
          const key = getApiInfoKey(project, resource);
          if (!resource.apiInfo && apiInfos[key]) {
            resource.apiInfo = apiInfos[key];
          }
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.projectsStore.current = undefined;
  }

  public updateEnvironmentUser(environmentName: string, user: ProjectUser) {
    let updated = false;
    const project = cloneDeep(this.instance);

    project.environments = project.environments.map(environment => {
      if (environment.uniqueName == environmentName) {
        environment.user = user;
        updated = true;
        return environment;
      } else {
        return environment;
      }
    });

    if (updated) {
      this.instance = project;
    }
  }

  public updateEnvironmentGroup(environmentName: string, group: ProjectGroup) {
    let updated = false;
    const project = cloneDeep(this.instance);

    project.environments = project.environments.map(environment => {
      if (environment.uniqueName == environmentName) {
        environment.group = group;
        updated = true;
        return environment;
      } else {
        return environment;
      }
    });

    if (updated) {
      this.instance = project;
    }
  }

  public addStorage(resource: Resource, storage: Storage) {
    const newInstance = cloneDeep(this.instance) as Project;
    newInstance.resources = newInstance.resources.map(newInstanceResource => {
      if (newInstanceResource.uniqueName == resource.uniqueName) {
        newInstanceResource.storages = [...newInstanceResource.storages, storage];
      }

      return newInstanceResource;
    });

    this.instance = newInstance;
  }

  public updateStorage(resource: Resource, storage: Storage) {
    const newInstance = cloneDeep(this.instance) as Project;
    let replacesFound = false;

    newInstance.resources = newInstance.resources.map(newInstanceResource => {
      if (newInstanceResource.uniqueName == resource.uniqueName) {
        const index = newInstanceResource.storages.findIndex(item => item.uniqueName == storage.uniqueName);

        if (index !== -1) {
          if (!replacesFound) {
            replacesFound = true;
          }

          newInstanceResource.storages = newInstanceResource.storages.map((item, i) => {
            if (i === index) {
              return storage;
            } else {
              return item;
            }
          });
        }
      }

      return newInstanceResource;
    });

    if (replacesFound) {
      this.instance = newInstance;
    }
  }

  public updateStorages(resource: Resource, storages: Storage[]) {
    const newInstance = cloneDeep(this.instance) as Project;
    let replacesFound = false;

    newInstance.resources = newInstance.resources.map(newInstanceResource => {
      if (newInstanceResource.uniqueName == resource.uniqueName) {
        const newStorages = newInstanceResource.storages.map((instanceItem, i) => {
          const replaceItem = storages.find(item => item.uniqueName == instanceItem.uniqueName);

          if (!replacesFound && replaceItem) {
            replacesFound = true;
          }

          return replaceItem || instanceItem;
        });

        if (replacesFound) {
          newInstanceResource.storages = newStorages;
        }
      }

      return newInstanceResource;
    });

    if (replacesFound) {
      this.instance = newInstance;
    }
  }

  public deleteStorage(resource: Resource, storage: Storage) {
    const newInstance = cloneDeep(this.instance) as Project;
    let replacesFound = false;

    newInstance.resources = newInstance.resources.map(newInstanceResource => {
      if (newInstanceResource.uniqueName == resource.uniqueName) {
        const index = newInstanceResource.storages.findIndex(item => item.uniqueName == storage.uniqueName);

        if (index !== -1) {
          if (!replacesFound) {
            replacesFound = true;
          }

          newInstanceResource.storages = newInstanceResource.storages.filter((item, i) => i !== index);
        }
      }

      return newInstanceResource;
    });

    if (replacesFound) {
      this.instance = newInstance;
    }
  }
}

import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DynamicComponent } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';
import { CustomizeBarMenuEditComponent } from '../customize-bar-menu-edit/customize-bar-menu-edit.component';
import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';
var MenuSettingsComponent = /** @class */ (function () {
    function MenuSettingsComponent(projectForm, menuForm, settingsForm, menuService, customizeBarContext, currentProjectStore, projectSettingsStore, customizeService, menuSettingsStore, appConfigService, metaService, analyticsService, routing, cd) {
        this.projectForm = projectForm;
        this.menuForm = menuForm;
        this.settingsForm = settingsForm;
        this.menuService = menuService;
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.projectSettingsStore = projectSettingsStore;
        this.customizeService = customizeService;
        this.menuSettingsStore = menuSettingsStore;
        this.appConfigService = appConfigService;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.routing = routing;
        this.cd = cd;
        this.dynamicComponents = new QueryList();
        this.settingsComponents = [];
        this.loading = false;
        this.blockControlHover$ = new BehaviorSubject(undefined);
        this.blockControlPreviewHover$ = new BehaviorSubject(undefined);
        this.blockControlActive$ = new BehaviorSubject(undefined);
    }
    MenuSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuService.section = MenuSection.None;
        this.loading = true;
        this.cd.markForCheck();
        this.customizeBarContext.setSettingsComponent({
            component: CustomizeBarMenuEditComponent,
            inputs: {
                menuForm: this.menuForm,
                settingsForm: this.settingsForm,
                blockControlPreviewHover$: this.blockControlPreviewHover$
            },
            outputs: {
                blockControlHover: [function (value) { return _this.blockControlHover$.next(value); }]
            }
        });
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: [],
            inAppDisabled: true
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.settingsComponents = value;
            _this.cd.detectChanges();
            _this.scrollToTop();
            var pagesEditComponent = _this.dynamicComponents
                .map(function (item) { return item.currentComponent.instance; })
                .find(function (component) { return component instanceof CustomizeBarPagesEditComponent; });
            var blockControlActive = pagesEditComponent ? pagesEditComponent.blockControl : undefined;
            _this.blockControlActive$.next(blockControlActive);
            _this.cd.markForCheck();
        });
        combineLatest(this.currentProjectStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$(), this.menuSettingsStore.getFirst())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], projectSettings = _a[1], menuSettings = _a[2];
            var webBaseUrl = project && project.domain ? "https://" + project.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = project;
            _this.domain = project ? project.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
            _this.loading = false;
            _this.cd.markForCheck();
            _this.projectForm.init(project);
            _this.menuForm.init(menuSettings);
            _this.settingsForm.init(projectSettings);
            _this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.menuForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.menuForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.settingsForm.submit().subscribe(function () { return _this.onSave(); });
            });
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var webBaseUrl = result && result.domain ? "https://" + result.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = result;
            _this.domain = result ? result.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
            _this.cd.markForCheck();
            _this.projectForm.project = _this.project;
        });
    };
    MenuSettingsComponent.prototype.ngOnDestroy = function () {
        this.menuService.section = MenuSection.Default;
        this.customizeService.unsetHandler(this);
    };
    MenuSettingsComponent.prototype.scrollToTop = function (animated) {
        if (animated === void 0) { animated = true; }
        if (!this.root) {
            return;
        }
        var duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
        scrollTo(this.root.nativeElement, 0, duration);
    };
    MenuSettingsComponent.prototype.onBlockControlPreviewClick = function (block) {
        var uid = block.instance ? block.instance.uid : undefined;
        if (isSet(uid)) {
            var link = this.currentProjectStore.instance.settingsMenuLink(uid);
            this.routing.navigateApp(link);
        }
    };
    MenuSettingsComponent.prototype.onPreviewBackgroundClick = function () {
        var link = this.currentProjectStore.instance.settingsMenuLink();
        this.routing.navigateApp(link);
    };
    MenuSettingsComponent.prototype.onSave = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'menu_settings'
        });
    };
    // TODO: Fix empty handler
    MenuSettingsComponent.prototype.closePopup = function (uid) { };
    return MenuSettingsComponent;
}());
export { MenuSettingsComponent };

import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { delayWhen, map, switchMap } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { CustomViewSettings, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import {
  AdminPanelPopupsGenerator,
  AdminPanelSeparatePagesGenerator,
  AdminPanelSinglePageGenerator,
  CrudPagesGenerator,
  DashboardGenerator,
  PersistentIdGenerator
} from '@modules/customize-generators';
import { MenuService, MenuSettings, MenuSettingsService, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, EnvironmentService, Resource } from '@modules/projects';
import { TemplateApplyService } from '@modules/template-components';

import { PageTemplate } from '../../data/page-template';
import { PageTemplateType } from '../../data/page-template-type';
import { PageVariantType } from '../../data/page-variant-type';
import { TemplateSettings } from '../../data/template-settings';

export interface PageTemplatesCreateResult {
  pages: CustomViewSettings[];
  startPage?: CustomViewSettings;
}

@Injectable()
export class PageTemplatesGeneratorService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private adminPanelSeparatePagesGenerator: AdminPanelSeparatePagesGenerator,
    private adminPanelSinglePageGenerator: AdminPanelSinglePageGenerator,
    private adminPanelPopupsGenerator: AdminPanelPopupsGenerator,
    private dashboardGenerator: DashboardGenerator,
    private crudPagesGenerator: CrudPagesGenerator,
    private environmentService: EnvironmentService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private menuService: MenuService,
    private menuSettingsService: MenuSettingsService,
    private menuSettingsStore: MenuSettingsStore,
    private templateApplyService: TemplateApplyService
  ) {}

  createPages(pages: ViewSettings[]): Observable<ViewSettings[]> {
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;

    return this.viewSettingsService
      .createBulk(project.uniqueName, environment.uniqueName, pages)
      .pipe(delayWhen(() => this.viewSettingsStore.getFirst(true)));
  }

  // createMenu(page: ViewSettings, resource?: Resource): Observable<MenuSettings> {
  //   const project = this.currentProjectStore.instance;
  //   const environment = this.currentEnvironmentStore.instance;
  //   const menuSettings = this.templateApplyService.createMenuSettings(project, resource, [page]);
  //
  //   if (!menuSettings) {
  //     return of(undefined);
  //   }
  //
  //   return this.menuSettingsStore.getFirst().pipe(
  //     switchMap(original => {
  //       // const override = new MenuSettings(this.injector).deserialize(params.menuSettings);
  //
  //       original.primaryCenterItems = [...original.primaryCenterItems, ...menuSettings.primaryCenterItems];
  //       original.secondaryStartItems = [...original.secondaryStartItems, ...menuSettings.secondaryStartItems];
  //
  //       return this.menuSettingsService
  //         .create(project.uniqueName, environment.uniqueName, original)
  //         .pipe(delayWhen(() => this.menuSettingsStore.getFirst(true)));
  //     })
  //   );
  // }

  createTemplatePages(
    template: PageTemplate,
    settings: TemplateSettings,
    options: {
      idGenerator?: PersistentIdGenerator;
    } = {}
  ): Observable<PageTemplatesCreateResult> {
    const project = this.currentProjectStore.instance;
    const resource = this.currentEnvironmentStore.resources.find(
      item => item.uniqueName == settings.modelDescription.resource
    );

    if ([PageTemplateType.AdminPanelEdit, PageTemplateType.AdminPanelView].includes(template.type)) {
      const updateEnabled = template.type == PageTemplateType.AdminPanelEdit;

      if (settings.templateVariant == PageVariantType.SeparatePages) {
        return this.adminPanelSeparatePagesGenerator
          .getPages(project, resource, settings.modelDescription, updateEnabled, {
            listFields: settings.listFields,
            createFields: settings.createFields,
            updateFields: settings.updateFields,
            detailFields: settings.detailFields,
            idGenerator: options.idGenerator
          })
          .pipe(
            map(result => {
              if (!result.length) {
                return;
              }

              return {
                pages: result.map(item => item.page),
                startPage: result.filter(item => item.initial).map(item => item.page)[0]
              };
            })
          );
      } else if (settings.templateVariant == PageVariantType.SinglePage) {
        return this.adminPanelSinglePageGenerator
          .getPage(project, resource, settings.modelDescription, updateEnabled, {
            listFields: settings.listFields,
            createFields: settings.createFields,
            updateFields: settings.updateFields,
            detailFields: settings.detailFields,
            idGenerator: options.idGenerator
          })
          .pipe(
            map(page => {
              if (!page) {
                return;
              }

              return {
                pages: [page],
                startPage: page
              };
            })
          );
      } else if (settings.templateVariant == PageVariantType.Popups) {
        return this.adminPanelPopupsGenerator
          .getPage(project, resource, settings.modelDescription, updateEnabled, {
            listFields: settings.listFields,
            createFields: settings.createFields,
            updateFields: settings.updateFields,
            detailFields: settings.detailFields,
            idGenerator: options.idGenerator
          })
          .pipe(
            map(page => {
              if (!page) {
                return;
              }

              return {
                pages: [page],
                startPage: page
              };
            })
          );
      }
    } else if (template.type == PageTemplateType.CreateForm) {
      return this.crudPagesGenerator
        .prepareCreatePage(project, resource, settings.modelDescription, {
          createFields: settings.createFields,
          idGenerator: options.idGenerator
        })
        .pipe(
          map(page => {
            if (!page) {
              return;
            }

            return {
              pages: [page],
              startPage: page
            };
          })
        );
    } else if (template.type == PageTemplateType.Dashboard) {
      return this.dashboardGenerator
        .getPage(project, resource, settings.modelDescription, {
          listFields: settings.listFields,
          // createFields: settings.createFields,
          // updateFields: settings.updateFields,
          // detailFields: settings.detailFields,
          idGenerator: options.idGenerator
        })
        .pipe(
          map(page => {
            if (!page) {
              return;
            }

            return {
              pages: [page],
              startPage: page
            };
          })
        );
    }

    return of(undefined);
  }

  applyTemplate(
    template: PageTemplate,
    settings: TemplateSettings,
    options: { publish?: boolean } = {}
  ): Observable<ViewSettings> {
    const resource = this.currentEnvironmentStore.resources.find(
      item => item.uniqueName == settings.modelDescription.resource
    );

    return this.createTemplatePages(template, settings).pipe(
      switchMap(result => {
        if (!result) {
          return of(undefined);
        }

        return this.createPages(result.pages).pipe(
          // delayWhen(() => this.createMenu(result.startPage, resource)),
          map(() => result.startPage)
        );
      }),
      delayWhen(() => {
        if (!options.publish) {
          return of(undefined);
        }

        return this.environmentService
          .publishDraft(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
          .pipe(
            delayWhen(() => {
              return combineLatest(
                this.viewSettingsStore.getFirst(true),
                this.modelDescriptionStore.getFirst(true),
                this.actionStore.getFirst(true),
                this.menuSettingsStore.getFirst(true),
                this.currentProjectStore.getFirst(true)
              );
            })
          );
      })
    );
  }
}

import { OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
var WorkflowEditContext = /** @class */ (function () {
    function WorkflowEditContext() {
        var _this = this;
        this.testExecuteEvents$ = new Subject();
        this.run$ = new BehaviorSubject(undefined);
        this.history$ = new BehaviorSubject([]);
        this.historyIndex$ = new BehaviorSubject(0);
        this.historyRecord$ = new Subject();
        this.createdElements = [];
        this.historyRecord$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () { return _this.recordHistory(); });
    }
    WorkflowEditContext.prototype.ngOnDestroy = function () { };
    WorkflowEditContext.prototype.init = function (state) {
        this.state = state;
        this.history$.next([cloneDeep(state)]);
        this.historyIndex$.next(0);
    };
    WorkflowEditContext.prototype.markChanged = function () {
        this.historyRecord$.next();
    };
    WorkflowEditContext.prototype.state$ = function () {
        return combineLatest(this.history$, this.historyIndex$).pipe(map(function (_a) {
            var history = _a[0], historyIndex = _a[1];
            return history[historyIndex];
        }));
    };
    WorkflowEditContext.prototype.recordHistory = function () {
        var history = this.history$.value.slice(0, this.historyIndex$.value + 1).concat([cloneDeep(this.state)]);
        this.history$.next(history);
        this.historyIndex$.next(history.length - 1);
    };
    WorkflowEditContext.prototype.applyHistory = function (index) {
        var _a;
        var state = this.history$.value[index];
        if (!state) {
            return;
        }
        this.state.workflow.deserialize(state.workflow.serialize());
        (_a = this.state.parameters).splice.apply(_a, [0, this.state.parameters.length].concat(state.parameters));
        this.historyIndex$.next(index);
    };
    WorkflowEditContext.prototype.isHistoryAvailable$ = function (delta) {
        return combineLatest(this.history$, this.historyIndex$).pipe(map(function (_a) {
            var history = _a[0], historyIndex = _a[1];
            return history[historyIndex + delta] !== undefined;
        }));
    };
    WorkflowEditContext.prototype.undo = function () {
        this.applyHistory(this.historyIndex$.value - 1);
    };
    WorkflowEditContext.prototype.redo = function () {
        this.applyHistory(this.historyIndex$.value + 1);
    };
    WorkflowEditContext.prototype.isUndoAvailable$ = function () {
        return this.isHistoryAvailable$(-1);
    };
    WorkflowEditContext.prototype.isRedoAvailable$ = function () {
        return this.isHistoryAvailable$(1);
    };
    WorkflowEditContext.prototype.registerCreatedElement = function (element, options) {
        if (options === void 0) { options = {}; }
        this.createdElements.push({
            element: element,
            contextElement: options.contextElement
        });
    };
    WorkflowEditContext.prototype.updateCreatedElement = function (element, options) {
        if (options === void 0) { options = {}; }
        var createdElement = this.createdElements.find(function (item) { return item.element === element; });
        if (!createdElement) {
            return;
        }
        this.createdElements = this.createdElements.map(function (item) {
            if (item === createdElement) {
                if (options.contextElement) {
                    item.contextElement = options.contextElement;
                }
            }
            return item;
        });
        return createdElement;
    };
    WorkflowEditContext.prototype.initCreatedElement = function (element) {
        var createdElement = this.createdElements.find(function (item) { return item.element === element; });
        if (!createdElement) {
            return;
        }
        this.createdElements = this.createdElements.filter(function (item) { return item !== createdElement; });
        return createdElement;
    };
    return WorkflowEditContext;
}());
export { WorkflowEditContext };

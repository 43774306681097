import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { FeatureService } from '../../services/feature/feature.service';

@Pipe({
  name: 'appFeatureActive',
  pure: false
})
export class FeatureActivePipe implements PipeTransform {
  constructor(private featureService: FeatureService) {}

  transform(value: string): Observable<boolean> {
    return this.featureService.isFeatureActive(value);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { MenuBlockLayout } from '@modules/menu';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { User } from '@modules/users';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditShareControl } from '../customize-bar-pages-edit/share-control';

@Component({
  selector: 'app-share-menu-item-edit',
  templateUrl: './share-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareMenuItemEditComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditShareControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  loading = false;
  displayUsers: User[];
  displayUsersCount = 4;
  otherUsersCount = 0;
  editPopoverOpened = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.projectUserService
      .getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
        [PER_PAGE_PARAM]: this.displayUsersCount
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.displayUsers = result.results
            .filter(user => user.user)
            .map(user => user.user)
            .slice(0, this.displayUsersCount + 1)
            .reverse();
          this.otherUsersCount = result.count - this.displayUsers.length;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    if (this.createdOptions && this.createdOptions.customize) {
      this.openEditPopover(true);
    }
  }

  ngOnDestroy(): void {}

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }
}

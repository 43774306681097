<app-popup2>
  <div class="popup-form__cols">
    <div class="popup-form__cols-main">
      <form [formGroup]="form.form" (ngSubmit)="submit()">
        <app-popup2-header>
          <app-popup2-title>Help us improve our service</app-popup2-title>
          <app-popup2-description>
            Tell Us a bit About Yourself
          </app-popup2-description>
        </app-popup2-header>

        <app-page-block>
          <div class="field">
            <label class="field__label field__label_bright">Company</label>
            <input
              class="input input_fill"
              type="text"
              formControlName="name"
              [appAutofocus]="true"
              placeholder="Company Name"
            />
            <app-field-errors [form]="form.form" [fieldName]="'name'"></app-field-errors>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <label class="field__label field__label_bright">
              Industry
            </label>
            <div class="field__label-description">
              What kind of company is it? Select your industry from the list.
            </div>
            <gxs-select
              [formControlName]="'industry'"
              [options]="{
                theme: 'jet',
                search: true,
                searchMinimumLength: 1,
                searchDebounce: 0,
                classes: ['select_fill'],
                labels: {} | localizeSelect
              }"
            >
              <gxs-option *ngFor="let option of form.industryOptions" [name]="option" [value]="option"> </gxs-option>
            </gxs-select>
            <app-field-errors [form]="form.form" [fieldName]="'industry'"></app-field-errors>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <label class="field__label field__label_bright">
              Role
            </label>
            <div class="field__label-description">
              What is your role there?
            </div>
            <input class="input input_fill" type="text" formControlName="role" placeholder="Enter your Role" />
            <app-field-errors [form]="form.form" [fieldName]="'role'"></app-field-errors>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <label class="field__label field__label_bright">
              Company Size
            </label>
            <div class="field__label-description">
              How big is your company?
            </div>
            <gxs-select [formControlName]="'size'" [options]="{ theme: 'jet', classes: ['select_fill'] }">
              <gxs-option *ngFor="let option of form.sizeOptions" [name]="option" [value]="option"> </gxs-option>
            </gxs-select>
            <app-field-errors [form]="form.form" [fieldName]="'size'"></app-field-errors>
          </div>
        </app-page-block>

        <app-popup2-footer>
          <app-page-block>
            <app-stack [align]="'right'">
              <a href="javascript:void(0)" class="button popup2__footer-item" (click)="skip()">
                Skip
              </a>
              <button
                type="submit"
                class="button button_primary popup2__footer-item"
                [class.button_disabled]="form.form.invalid"
              >
                Continue
              </button>
            </app-stack>
          </app-page-block>
        </app-popup2-footer>
      </form>
    </div>
    <div class="popup-form__cols-side">
      <div class="popup-form__banner">
        <div class="popup-form__banner-content">
          <div class="popup-form__banner-subtitle">Your workspace includes a 14-day free trial of the</div>
          <div class="popup-form__banner-title">Pro plan</div>
          <ul class="popup-form__banner-list">
            <li class="popup-form__banner-list-item">Team-based permissions</li>
            <li class="popup-form__banner-list-item">Activity log</li>
            <li class="popup-form__banner-list-item">Automations</li>
            <li class="popup-form__banner-list-item">Custom widgets</li>
            <li class="popup-form__banner-list-item">Alert center</li>
            <li class="popup-form__banner-list-item">Tasks and comments</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</app-popup2>

import { Injectable } from '@angular/core';
import values from 'lodash/values';

import { QueryService } from '../query/query.service';

@Injectable({
  providedIn: 'root'
})
export class SqlQueryService {
  constructor(private queryService: QueryService) {}

  applyTokensSql(
    query: string,
    tokens = {},
    raiseErrors = false
  ): { query: string; params: Object; paramsList: any[] } {
    if (!query) {
      return;
    }

    const params: { [k: string]: { name: string; value: any } } = {};
    const paramsList = [];

    // Remove comments
    query = query.replace(/^(\s*)?--.*/gm, '');

    // Interpolate
    let resultQuery = query.replace(/\{\{([^\}]+)\}\}/g, match => {
      const value = this.queryService.applyTokens(match, tokens, raiseErrors);

      if (!params.hasOwnProperty(match)) {
        const name = `param${values(params).length}`;
        params[match] = { name: name, value: value };
        paramsList.push(value);
      }

      return `:${params[match].name}`;
    });

    // Evaluate logic
    resultQuery = this.queryService.applyTokens(resultQuery, tokens, raiseErrors, true);

    // Params to Object
    const resultParams = values<{ name: string; value: any }>(params).reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});

    return {
      query: resultQuery,
      params: resultParams,
      paramsList: paramsList
    };
  }
}

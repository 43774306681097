/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../modules/routing/directives/app-link/app-link.directive";
import * as i3 from "../../../modules/routing/services/routing/routing.service";
import * as i4 from "@angular/router";
import * as i5 from "./section-tab.component";
var styles_SectionTabComponent = [];
var RenderType_SectionTabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionTabComponent, data: {} });
export { RenderType_SectionTabComponent as RenderType_SectionTabComponent };
function View_SectionTabComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "section-tab__inner-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "section-tab__inner-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SectionTabComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "section-tab__inner"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.selected.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i2.AppLinkDirective, [i3.RoutingService, i4.Router, i4.ActivatedRoute, i1.LocationStrategy], { appLink: [0, "appLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "section-tab__inner-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionTabComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.link; _ck(_v, 1, 0, currVal_2); var currVal_4 = _co.icon; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); }); }
function View_SectionTabComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "section-tab__inner-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "section-tab__inner-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SectionTabComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "section-tab__inner"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "section-tab__inner-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionTabComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_SectionTabComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "section-tab"]], [[2, "section-tab_active", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionTabComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionTabComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.link; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.link; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_SectionTabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section-tab", [], null, null, null, View_SectionTabComponent_0, RenderType_SectionTabComponent)), i0.ɵdid(1, 114688, null, 0, i5.SectionTabComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionTabComponentNgFactory = i0.ɵccf("app-section-tab", i5.SectionTabComponent, View_SectionTabComponent_Host_0, { label: "label", link: "link", icon: "icon", active: "active" }, { selected: "selected" }, []);
export { SectionTabComponentNgFactory as SectionTabComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/routing/directives/app-link/app-link.directive";
import * as i2 from "../../../modules/routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./page-header.component";
var styles_PageHeaderComponent = [];
var RenderType_PageHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeaderComponent, data: {} });
export { RenderType_PageHeaderComponent as RenderType_PageHeaderComponent };
function View_PageHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "page-header__main-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "page-header__back icon-arrow_backward_3"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.backLink; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PageHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "page-header__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "page-header__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "page-header__main-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "page-header__logo"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "page-header__title"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "page-header__description"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "page-header__additional"]], null, null, null, null, null)), i0.ɵncd(null, 3), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "page-header__right"]], null, null, null, null, null)), i0.ɵncd(null, 4), i0.ɵncd(null, 5)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backLink; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PageHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-header", [], [[2, "page-block", null], [2, "page-header", null], [2, "page-block_first", null], [2, "page-block_last", null], [2, "page-block_margin-size_s", null], [2, "page-block_margin-size_m", null], [2, "page-block_margin-size_l", null]], null, null, View_PageHeaderComponent_0, RenderType_PageHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i5.PageHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).blockCls; var currVal_1 = i0.ɵnov(_v, 1).cls; var currVal_2 = i0.ɵnov(_v, 1).first; var currVal_3 = i0.ɵnov(_v, 1).last; var currVal_4 = i0.ɵnov(_v, 1).marginSizeS; var currVal_5 = i0.ɵnov(_v, 1).marginSizeM; var currVal_6 = i0.ɵnov(_v, 1).marginSizeL; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
var PageHeaderComponentNgFactory = i0.ɵccf("app-page-header", i5.PageHeaderComponent, View_PageHeaderComponent_Host_0, { marginSize: "marginSize", backLink: "backLink", first: "first", last: "last" }, {}, ["app-page-logo", "app-page-title", "app-page-description", "app-page-header-additional", "app-page-header-right", "*"]);
export { PageHeaderComponentNgFactory as PageHeaderComponentNgFactory };

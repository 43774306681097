/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "./register-template-field.component";
var styles_RegisterTemplateFieldComponent = [];
var RenderType_RegisterTemplateFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegisterTemplateFieldComponent, data: {} });
export { RenderType_RegisterTemplateFieldComponent as RenderType_RegisterTemplateFieldComponent };
function View_RegisterTemplateFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"] }, null), i0.ɵpod(2, { name: 0, label: 1, field: 2, params: 3, required: 4, placeholder: 5 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 2, 0, _co.field.name, _co.field.verboseName, _co.field.field, _co.fieldParams, _co.field.required, _co.field.placeholder)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RegisterTemplateFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegisterTemplateFieldComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RegisterTemplateFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-register-template-field", [], null, null, null, View_RegisterTemplateFieldComponent_0, RenderType_RegisterTemplateFieldComponent)), i0.ɵdid(1, 638976, null, 0, i4.RegisterTemplateFieldComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterTemplateFieldComponentNgFactory = i0.ɵccf("app-register-template-field", i4.RegisterTemplateFieldComponent, View_RegisterTemplateFieldComponent_Host_0, { form: "form", field: "field" }, {}, []);
export { RegisterTemplateFieldComponentNgFactory as RegisterTemplateFieldComponentNgFactory };

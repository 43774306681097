import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { PopupRef, PopupService } from '@common/popups';

import { InputOverlayComponent } from '../../components/input-overlay/input-overlay.component';
import { InputOverlayService } from '../../services/input-overlay/input-overlay.service';

@Directive({
  selector: '[appInputOverlay]',
  exportAs: 'appInputOverlay'
})
export class InputOverlayDirective implements OnInit, OnDestroy {
  @Input('appInputOverlayInput') input: any;
  @Output('appInputOverlayFinished') finished = new EventEmitter<void>();

  popup: PopupRef<InputOverlayComponent>;
  popupSubscriptions: Subscription[] = [];

  constructor(
    private el: ElementRef,
    private popupService: PopupService,
    private injector: Injector,
    private inputOverlayService: InputOverlayService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.popup) {
          this.open();
        } else {
          this.close();
        }
      });

    this.inputOverlayService
      .getActive$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        if (this.isOpened() && value && value !== this) {
          this.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.close();
  }

  open() {
    if (this.popup) {
      return;
    }

    this.popup = this.popupService.showComponent<InputOverlayComponent>({
      component: InputOverlayComponent,
      injector: this.injector,
      inputs: {
        itemForm: this.input.itemForm,
        context: this.input.context,
        contextElement: this.input.contextElement,
        contextElementPath: this.input.contextElementPath,
        contextElementPaths: this.input.contextElementPaths,
        staticValueField: this.input.staticValueField,
        staticValueParams: this.input.staticValueParams,
        staticValueDisabled: this.input.staticValueDisabled,
        filterFields: this.input.filterFields,
        userInput: this.input.userInput,
        focusedInitial: this.input.focusedInitial,
        placeholder: this.input.placeholder,
        formulaPlaceholder: this.input.formulaPlaceholder,
        jsPlaceholder: this.input.jsPlaceholder,
        displayValueTypesEnabled: this.input.displayValueTypesEnabled,
        displayValueTypes: this.input.displayValueTypes,
        classes: this.input.classes,
        fill: this.input.fill,
        analyticsSource: this.input.analyticsSource
      },
      animate: false,
      allowBodyScroll: true,
      backdrop: false,
      centerHorizontally: false,
      centerVertically: false
    });

    this.popupSubscriptions.push(
      this.popup.instance.finished.pipe(untilDestroyed(this)).subscribe(() => this.finished.emit())
    );

    this.popupSubscriptions.push(
      this.popup.overlayRef
        .detachments()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.popup = undefined;
          this.popupSubscriptions.forEach(item => item.unsubscribe());
          this.popupSubscriptions = [];
          this.cd.markForCheck();
        })
    );

    this.cd.markForCheck();
    this.inputOverlayService.setActive(this);
  }

  close() {
    if (!this.popup) {
      return;
    }

    this.popup.close();
    this.inputOverlayService.unsetActive(this);
  }

  isOpened(): boolean {
    return !!this.popup;
  }
}

import { WorkflowStep } from './steps/base';
import { WorkflowStepType } from './workflow-step-type';

const workflowStepsByType: { type: WorkflowStepType; cls: typeof WorkflowStep }[] = [];

export function getWorkflowStepByType(type: WorkflowStepType): typeof WorkflowStep {
  const instance = workflowStepsByType.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerWorkflowStepType(type: WorkflowStepType, cls: typeof WorkflowStep) {
  workflowStepsByType.push({ type: type, cls: cls });
}

export function deserializerWorkflowStep(data) {
  const cls = getWorkflowStepByType(data['type']);
  if (!cls) {
    console.error(`Unsupported workflow step type: ${data['type']}`);
    return;
  }
  return new cls().deserialize(data);
}

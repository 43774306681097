import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';

import { LicenseCheckInterceptor } from './services/license-check/license-check.interceptor';

export const usersInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LicenseCheckInterceptor, multi: true }
];

@NgModule({
  imports: [CommonModule, SharedModule]
})
export class UsersModule {}

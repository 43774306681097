<div class="view-editor-sidebar" [class.view-editor-sidebar_hidden]="settingsComponents.length">
  <div class="compact-inputs-section">
    <div *ngIf="containerLayer?.flexLayout" class="compact-inputs-element">
      <app-container-layer-control
        [containerLayer]="containerLayer"
        [absoluteControl]="form.controls.absolute_layout"
      ></app-container-layer-control>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-group">
        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.x"
            [postfix]="'X'"
            [number]="true"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.y"
            [postfix]="'Y'"
            [number]="true"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.rotation"
            [postfix]="'°'"
            [number]="true"
          ></app-compact-input>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-inputs-group">
        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.width"
            [postfix]="'W'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateHeightProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.height"
            [postfix]="'H'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateWidthProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item compact-inputs-group__item-row">
          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.constrain_proportion.value"
              [class.compact-button_hover]="form.controls.constrain_proportion.value"
              [class.icon-lock_open]="!form.controls.constrain_proportion.value"
              [class.icon-lock_close]="form.controls.constrain_proportion.value"
              (click)="form.toggleConstrainProportion()"
              [appTip]="'Constrain proportions'"
              [appTipOptions]="{ side: 'top' }"
            ></div>
          </div>

          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.reflect_horizontal.value"
              [class.compact-button_hover]="form.controls.reflect_horizontal.value"
              (click)="form.controls.reflect_horizontal.patchValue(!form.controls.reflect_horizontal.value)"
              [appTip]="'Flip horizontal'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-reflect"></span>
            </div>
          </div>

          <div class="compact-inputs-group__item-row-item">
            <div
              class="compact-button compact-button_fluid"
              [class.compact-button_primary]="form.controls.reflect_vertical.value"
              [class.compact-button_hover]="form.controls.reflect_vertical.value"
              (click)="form.controls.reflect_vertical.patchValue(!form.controls.reflect_vertical.value)"
              [appTip]="'Flip vertical'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-reflect" style="transform: rotate(90deg);"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-element">
      <app-opacity-control [formControl]="form.controls.opacity"></app-opacity-control>
    </div>

    <div class="compact-inputs-element">
      <app-compact-select
        [formControl]="form.controls.blending_mode"
        [options]="form.blendingModeOptions"
      ></app-compact-select>
    </div>

    <div class="compact-inputs-element">
      <app-compact-border-button
        *ngIf="!form.controls.visible_input_enabled.value"
        [label]="'Conditional Visibility'"
        [interactive]="true"
        [leftIcon]="'eye_2'"
        [leftBackground]="true"
        [rightIcon]="'plus'"
        (click)="form.controls.visible_input_enabled.patchValue(true)"
      ></app-compact-border-button>

      <ng-container *ngIf="form.controls.visible_input_enabled.value">
        <div class="compact-heading">
          Conditional Visibility
        </div>

        <app-input-edit
          [itemForm]="form.controls.visible_input"
          [context]="viewContext"
          [staticValueDisabled]="true"
          [focusedInitial]="form.visibleInputEnabledInitial === false"
          [placeholder]="'Value'"
          [fill]="true"
          [dark]="true"
          [darker]="true"
        ></app-input-edit>
      </ng-container>
    </div>
  </div>

  <app-flex-layout-section
    [enabledControl]="form.controls.flex_layout_enabled"
    [control]="form.controls.flex_layout"
    [updateControlsValue]="false"
    (selectedOption)="setFlexLayout($event)"
  ></app-flex-layout-section>

  <!--<div class="compact-inputs-section">-->
  <!--  <div class="compact-inputs-section__header">-->
  <!--    <div class="compact-inputs-section__header-main">-->
  <!--      <div class="compact-inputs-section__header-title">Resizing</div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="compact-inputs-element">-->
  <!--    <app-constraints-control [control]="form.controls.constraints"></app-constraints-control>-->
  <!--  </div>-->
  <!--</div>-->

  <app-interactions-section
    [control]="form.controls.interactions"
    [viewContext]="viewContext"
  ></app-interactions-section>
</div>

<div
  *ngFor="let item of settingsComponents; let last = last"
  class="view-editor-sidebar view-editor-sidebar_dark view-editor-sidebar_expanded"
  [class.view-editor-sidebar_hidden]="!last"
>
  <app-dynamic-component [componentData]="item"></app-dynamic-component>
</div>

import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { CustomViewsStore } from '@modules/custom-views';
import { getModelAttributesByColumns, TimelineSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput$, createFormFieldFactory, ParameterField } from '@modules/fields';
import { ColumnListItem, ITEM_OUTPUT, ListItem } from '@modules/list';
import { ModelDescription } from '@modules/models';
import { EMPTY, isSet } from '@shared';
var TimelineItemComponent = /** @class */ (function () {
    function TimelineItemComponent(el, actionService, customViewsStore, cd) {
        this.el = el;
        this.actionService = actionService;
        this.customViewsStore = customViewsStore;
        this.cd = cd;
        this.visibleColumns = [];
        this.inverse = false;
        this.selected = false;
        this.select = new EventEmitter();
        this.modelUpdated = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.settings$ = new BehaviorSubject(undefined);
        this.viewParameters = [];
        this.viewParams$ = of({});
        this.localContext = {};
    }
    TimelineItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (isModelUpdateEventMatch(e, _this.modelDescription, _this.item.model)) {
                _this.item.model = patchModel(_this.item.model, e.model);
                _this.cd.markForCheck();
                _this.modelUpdated.next();
            }
        });
        this.settings$
            .pipe(filter(function (settings) { return !!settings; }), switchMap(function (settings) {
            if (!isSet(settings.cardCustomView)) {
                return of(undefined);
            }
            return _this.customViewsStore.getDetail(settings.cardCustomView);
        }), untilDestroyed(this))
            .subscribe(function (customView) {
            _this.view = customView ? customView.view : undefined;
            _this.cd.markForCheck();
            _this.updateViewParameters();
            _this.updateViewParams();
        });
    };
    TimelineItemComponent.prototype.ngOnDestroy = function () { };
    TimelineItemComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var _a;
        if (changes.item) {
            this.localContext = (_a = {},
                _a[ITEM_OUTPUT] = this.item.model.getAttributes(),
                _a);
        }
        if (changes.item || changes.dataSource) {
            var column_1 = this.dataSource
                ? this.dataSource.columns.find(function (item) { return item.name == _this.settings.dateField; })
                : undefined;
            this.dateColumnListItem = column_1
                ? {
                    listItem: this.item.columns.find(function (i) { return i.column == column_1.name; }),
                    column: column_1
                }
                : undefined;
        }
        if (changes.settings) {
            this.settings$.next(this.settings);
            this.updateViewParameters();
        }
        if (changes.settings || changes.item) {
            this.updateViewParams();
        }
    };
    TimelineItemComponent.prototype.onClick = function (e, element) {
        this.contextElement.context.clickEvent = e;
        this.select.emit({ item: this.item, element: element });
    };
    TimelineItemComponent.prototype.updateViewParameters = function () {
        if (!this.view) {
            this.viewParameters = [];
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        this.viewParameters = columns.map(function (item) {
            var parameter = new ParameterField();
            parameter.name = item.name;
            parameter.verboseName = item.verboseName;
            parameter.field = item.field;
            parameter.params = item.params;
            return parameter;
        });
        this.cd.markForCheck();
    };
    TimelineItemComponent.prototype.updateViewParams = function () {
        var _this = this;
        if (!this.view) {
            this.viewParams$ = of({});
            this.cd.markForCheck();
            return;
        }
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        var modelParams = getModelAttributesByColumns(this.item.model, columns);
        this.viewParams$ = combineLatest(this.settings.cardCustomViewMappings.map(function (mapping) {
            var _a;
            if (mapping.sourceParameterInput) {
                return applyParamInput$(mapping.sourceParameterInput, {
                    context: _this.context,
                    contextElement: _this.contextElement,
                    localContext: (_a = {},
                        _a[ITEM_OUTPUT] = modelParams,
                        _a)
                }).pipe(map(function (value) {
                    return {
                        name: mapping.targetParameter,
                        value: value !== EMPTY ? value : undefined
                    };
                }));
            }
            else {
                var value = modelParams[mapping.sourceParameter];
                return of({
                    name: mapping.targetParameter,
                    value: value
                });
            }
        })).pipe(map(function (values) {
            return fromPairs(values.map(function (item) { return [item.name, item.value]; }));
        }));
        this.cd.markForCheck();
    };
    return TimelineItemComponent;
}());
export { TimelineItemComponent };

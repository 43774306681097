var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { defaultGeographyObjectOutputFormatLatitudeField, defaultGeographyObjectOutputFormatLongitudeField, GeographyOutputFormat } from '@modules/fields';
var GeographyFieldDataParamsForm = /** @class */ (function () {
    function GeographyFieldDataParamsForm(fb) {
        this.fb = fb;
        this.outputFormatOptions = [
            { value: GeographyOutputFormat.Object, name: 'Object' },
            { value: GeographyOutputFormat.PostgreSQL, name: 'PostgreSQL' },
            { value: GeographyOutputFormat.Array, name: 'Array' }
        ];
        this.form = this.fb.group({
            output_format: new FormControl(GeographyOutputFormat.Object),
            object_lat_field: new FormControl(defaultGeographyObjectOutputFormatLatitudeField),
            object_lng_field: new FormControl(defaultGeographyObjectOutputFormatLongitudeField)
        });
    }
    GeographyFieldDataParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            this.form.patchValue(control.value, { emitEvent: false });
        }
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    GeographyFieldDataParamsForm.prototype.submit = function () {
        var value = this.form.value;
        var result = {
            output_format: value['output_format']
        };
        if (value['output_format'] == GeographyOutputFormat.Object) {
            result['object_lat_field'] = value['object_lat_field'];
            result['object_lng_field'] = value['object_lng_field'];
        }
        else {
            result['object_lat_field'] = undefined;
            result['object_lng_field'] = undefined;
        }
        this.control.patchValue(__assign({}, this.control.value, result));
    };
    return GeographyFieldDataParamsForm;
}());
export { GeographyFieldDataParamsForm };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxSelectModule } from 'ng-gxselect';

import { DynamicComponentModule } from '@common/dynamic-component';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ModelCreateComponent } from './components/model-create/model-create.component';
import { ModelEditPopupComponent } from './components/model-edit-popup/model-edit-popup.component';
import { ModelPrimaryKeyComponent } from './components/model-primary-key/model-primary-key.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RoutingModule,
    SharedModule,
    DynamicComponentModule,
    FieldsModule,
    ReactiveFormsModule,
    FieldComponentsModule,
    TipsModule,
    NgGxSelectModule,
    LocalizeModule,
    UiModule,
    SelectModule
  ],
  declarations: [ModelCreateComponent, ModelEditPopupComponent, ModelPrimaryKeyComponent],
  exports: [ModelCreateComponent, ModelEditPopupComponent, ModelPrimaryKeyComponent],
  entryComponents: [ModelCreateComponent, ModelEditPopupComponent, ModelPrimaryKeyComponent]
})
export class ModelComponentsModule {}

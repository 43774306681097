import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Environment, Project } from '@modules/projects';

import { ApiKey } from '../../data/api-key';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(project: Project, environment: Environment): Observable<ApiKey[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'api_keys'
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => result.map(item => new ApiKey().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(project: Project, environment: Environment, name?: string): Observable<ApiKey> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'api_keys'
        );
        const data = {
          name: name
        };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new ApiKey().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(project: Project, environment: Environment, instance: ApiKey, fields?: string[]): Observable<ApiKey> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `api_keys/${instance.token}`
        );
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new ApiKey().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  revoke(project: Project, environment: Environment, key: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.dataSourcesEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `api_keys/${key}/revoke`
        );
        const data = {};
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { deployUrl } from '../../utils/common/common';

@Pipe({
  name: 'appDeployUrl'
})
export class DeployUrlPipe implements PipeTransform {
  transform(value: string): string {
    return deployUrl(value);
  }
}

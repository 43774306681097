<div class="popover2__viewport popover2__viewport_scrollable">
  <div class="popover2__content" style="min-width: 300px;">
    <div class="token-popup__item">
      <div class="token-popup-search">
        <div class="token-popup-search__input">
          <span [class.loading-animation]="true">
            <span class="stub-text">
              Search
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="token-popup__item" style="margin-top: 10px;">
      <div class="token-popup-section">
        <div class="token-popup-section__content">
          <ng-container *ngFor="let item of ['name', 'category', 'active', 'date_add']">
            <div class="token-popup-list-item">
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                    <span [class.loading-animation]="true">
                      <span class="stub-text">{{ item }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

export enum JsonStructureNodeType {
  Object = 'object',
  Array = 'array',
  Field = 'field'
}

export interface JsonStructureNode {
  type: JsonStructureNodeType;
  name: any;
  label: string;
  params: Object;
}

export interface JsonStructureObjectParams {
  items: JsonStructureNode[];
}

export interface JsonStructureArrayParams {
  max_items?: number;
  item: JsonStructureNode;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonTintColorDirective } from './directives/button-tint-color/button-tint-color.directive';
import { TintColorDirective } from './directives/tint-color/tint-color.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TintColorDirective, ButtonTintColorDirective],
  exports: [TintColorDirective, ButtonTintColorDirective]
})
export class ColorsSharedModule {}

import { deserializeTokenOptions } from '@modules/api';
import { RoutingService } from '@modules/routing';
import { AuthService } from '../services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
import * as i2 from "../../routing/services/routing/routing.service";
export var TOKEN_FRAGMENT_KEY = 'jet_token';
export function getRouteExternalToken(route) {
    var params = {};
    var paramsRegex = /^(\w+)=(.+)$/.exec(route.fragment || '');
    if (paramsRegex) {
        params[paramsRegex[1]] = decodeURIComponent(paramsRegex[2].replace(/\+/g, ' '));
    }
    if (params[TOKEN_FRAGMENT_KEY]) {
        try {
            return deserializeTokenOptions(JSON.parse(atob(params[TOKEN_FRAGMENT_KEY])));
        }
        catch (e) { }
    }
}
var SetExternalTokenGuard = /** @class */ (function () {
    function SetExternalTokenGuard(authService, routing) {
        this.authService = authService;
        this.routing = routing;
    }
    SetExternalTokenGuard.prototype.canActivate = function (route, state) {
        var externalToken = getRouteExternalToken(route);
        if (externalToken) {
            this.authService.saveToken(externalToken);
            this.routing.navigateRoute(route, { queryParams: route.queryParams, fragment: undefined, replaceUrl: true });
            return false;
        }
        return true;
    };
    SetExternalTokenGuard.ngInjectableDef = i0.defineInjectable({ factory: function SetExternalTokenGuard_Factory() { return new SetExternalTokenGuard(i0.inject(i1.AuthService), i0.inject(i2.RoutingService)); }, token: SetExternalTokenGuard, providedIn: "root" });
    return SetExternalTokenGuard;
}());
export { SetExternalTokenGuard };

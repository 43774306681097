import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable, of } from 'rxjs';

import { SidebarTabComponent } from '../sidebar-tab/sidebar-tab.component';

interface Tab {
  label$: Observable<string>;
  error$: Observable<string>;
  disabled$: Observable<boolean>;
}

@Component({
  selector: 'app-sidebar-tabs',
  templateUrl: './sidebar-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarTabsComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() showTabsAlways = false;
  @Input() initialTabIndex = 0;

  @ContentChildren(SidebarTabComponent) tabComponents = new QueryList<SidebarTabComponent>();

  tabs: Tab[] = [];
  visibleTab: Tab;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterContentInit(): void {
    merge(of(this.tabComponents.toArray()), this.tabComponents.changes as Observable<SidebarTabComponent[]>)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.tabs = value.map(item => {
          return {
            label$: item.label$,
            error$: item.error$,
            disabled$: item.disabled$
          };
        });

        if (this.visibleTab === undefined) {
          this.visibleTab = this.tabs[this.initialTabIndex];
        }

        value.forEach((item, i) => {
          item.setVisible(this.visibleTab === this.tabs[i]);
        });
        this.cd.markForCheck();
      });
  }

  setVisibleTab(tab: Tab) {
    this.visibleTab = tab;
    this.cd.markForCheck();

    this.tabComponents.forEach((item, i) => {
      item.setVisible(this.visibleTab === this.tabs[i]);
    });
  }

  isActive(item: Tab) {
    return item === this.visibleTab;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { IntercomService } from '@modules/analytics';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() isWhiteLabel: boolean;
  @Input() analyticsSource: string;

  constructor(
    public currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef,
    private intercomService: IntercomService
  ) {}

  ngOnInit() {}

  openChat() {
    this.intercomService.openChat();
  }
}

<ng-container *ngIf="visible || (customizeEnabled$ | async)">
  <app-image-preview
    [url]="preview"
    [value]="value"
    [noBackground]="true"
    [fit]="element.fit"
    [type]="element.displayType"
    [lightBox]="element.lightbox"
    [preview]="element.showPreview"
    [style.cursor]="element.clickAction ? 'pointer' : null"
    (click)="onClick(image_element)"
    #image_element
  ></app-image-preview>
</ng-container>

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TextEditorModule } from '@common/text-editor';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { CustomizeElementsModule } from '@modules/customize-elements';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { SidebarModule } from '@modules/sidebar';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AutoLayerComponent } from './components/auto-layer/auto-layer.component';
import { CustomElementViewComponent } from './components/custom-element-view/custom-element-view.component';
import { CustomViewMapParametersComponent } from './components/custom-view-map-parameters/custom-view-map-parameters.component';
import { CustomViewTemplateRatingComponent } from './components/custom-view-template-rating/custom-view-template-rating.component';
import { CustomViewTemplatesActionCardComponent } from './components/custom-view-templates-action-card/custom-view-templates-action-card.component';
import { CustomViewTemplatesCardStubComponent } from './components/custom-view-templates-card-stub/custom-view-templates-card-stub.component';
import { CustomViewTemplatesCardComponent } from './components/custom-view-templates-card/custom-view-templates-card.component';
import { CustomViewTemplatesChangeComponent } from './components/custom-view-templates-change/custom-view-templates-change.component';
import { CustomViewTemplatesDetailComponent } from './components/custom-view-templates-detail/custom-view-templates-detail.component';
import { CustomViewTemplatesListComponent } from './components/custom-view-templates-list/custom-view-templates-list.component';
import { CustomViewTemplatesComponent } from './components/custom-view-templates/custom-view-templates.component';
import { ElementCustomizeLayerComponent } from './components/customize-layers/element/element-customize-layer.component';
import { EllipseCustomizeLayerComponent } from './components/customize-layers/ellipse/ellipse-customize-layer.component';
import { FrameCustomizeLayerComponent } from './components/customize-layers/frame/frame-customize-layer.component';
import { GroupCustomizeLayerComponent } from './components/customize-layers/group/group-customize-layer.component';
import { LineCustomizeLayerComponent } from './components/customize-layers/line/line-customize-layer.component';
import { RectangleCustomizeLayerComponent } from './components/customize-layers/rectangle/rectangle-customize-layer.component';
import { TextCustomizeLayerComponent } from './components/customize-layers/text/text-customize-layer.component';
import { CustomizeViewComponent } from './components/customize-view/customize-view.component';
import { ImportFigmaNodeItemStubComponent } from './components/import-figma-node-item-stub/import-figma-node-item-stub.component';
import { ImportFigmaNodeItemComponent } from './components/import-figma-node-item/import-figma-node-item.component';
import { ImportFigmaNodeComponent } from './components/import-figma-node/import-figma-node.component';
import { ImportSketchFileComponent } from './components/import-sketch-file/import-sketch-file.component';
import { LayerInteractionComponent } from './components/layer-interaction/layer-interaction.component';
import { LayerResizableDirective } from './components/layers/base/layer-resizable.directive';
import { ElementLayerComponent } from './components/layers/element/element-layer.component';
import { EllipseLayerComponent } from './components/layers/ellipse/ellipse-layer.component';
import { FrameLayerComponent } from './components/layers/frame/frame-layer.component';
import { GroupLayerComponent } from './components/layers/group/group-layer.component';
import { LineLayerBoundsComponent } from './components/layers/line-layer-bounds/line-layer-bounds.component';
import { LineLayerComponent } from './components/layers/line/line-layer.component';
import { RectangleLayerComponent } from './components/layers/rectangle/rectangle-layer.component';
import { TextLayerComponent } from './components/layers/text/text-layer.component';
import { AlphaControlComponent } from './components/ui/alpha-control/alpha-control.component';
import { BorderControlComponent } from './components/ui/border-control/border-control.component';
import { ColorButtonComponent } from './components/ui/color-button/color-button.component';
import { ColorSelectorAdvancedComponent } from './components/ui/color-selector-advanced/color-selector-advanced.component';
import { ColorWithInputSelectorComponent } from './components/ui/color-with-input-selector/color-with-input-selector.component';
import { CompactBorderButtonComponent } from './components/ui/compact-border-button/compact-border-button.component';
import { CompactCheckboxComponent } from './components/ui/compact-checkbox/compact-checkbox.component';
import { CompactFontControlComponent } from './components/ui/compact-font-control/compact-font-control.component';
import { CompactInputComponent } from './components/ui/compact-input/compact-input.component';
import { CompactSegmentedControlComponent } from './components/ui/compact-segmented-control/compact-segmented-control.component';
import { CompactSelectComponent } from './components/ui/compact-select/compact-select.component';
import { CompactWideButtonComponent } from './components/ui/compact-wide-button/compact-wide-button.component';
import { ConstraintsControlComponent } from './components/ui/constraints-control/constraints-control.component';
import { ContainerLayerControlComponent } from './components/ui/container-layer-control/container-layer-control.component';
import { CornerRadiusControlComponent } from './components/ui/corner-radius-control/corner-radius-control.component';
import { FillColorButtonComponent } from './components/ui/fill-color-button/fill-color-button.component';
import { FillColorOverlayComponent } from './components/ui/fill-color-overlay/fill-color-overlay.component';
import { FillControlComponent } from './components/ui/fill-control/fill-control.component';
import { FlexLayoutControlComponent } from './components/ui/flex-layout-control/flex-layout-control.component';
import { FlexLayoutPaddingControlComponent } from './components/ui/flex-layout-padding-control/flex-layout-padding-control.component';
import { FlexLayoutSectionComponent } from './components/ui/flex-layout-section/flex-layout-section.component';
import { GradientPositionPointComponent } from './components/ui/gradient-position-point/gradient-position-point.component';
import { GradientPositionComponent } from './components/ui/gradient-position/gradient-position.component';
import { GradientSelectorComponent } from './components/ui/gradient-selector/gradient-selector.component';
import { GradientStopPositionDirective } from './components/ui/gradient-selector/gradient-stop-position/gradient-stop-position.directive';
import { HexControlComponent } from './components/ui/hex-control/hex-control.component';
import { IconFillSelectorComponent } from './components/ui/icon-fill-selector/icon-fill-selector.component';
import { ImageFillSelectorComponent } from './components/ui/image-fill-selector/image-fill-selector.component';
import { InteractionsSectionClickComponent } from './components/ui/interactions-section-click/interactions-section-click.component';
import { InteractionsSectionCursorDropdownComponent } from './components/ui/interactions-section-cursor-dropdown/interactions-section-cursor-dropdown.component';
import { InteractionsSectionCursorComponent } from './components/ui/interactions-section-cursor/interactions-section-cursor.component';
import { InteractionsSectionHoverOutputComponent } from './components/ui/interactions-section-hover-output/interactions-section-hover-output.component';
import { InteractionsSectionPressedOutputComponent } from './components/ui/interactions-section-pressed-output/interactions-section-pressed-output.component';
import { InteractionsSectionComponent } from './components/ui/interactions-section/interactions-section.component';
import { OpacityControlComponent } from './components/ui/opacity-control/opacity-control.component';
import { ShadowControlComponent } from './components/ui/shadow-control/shadow-control.component';
import { ZoomControlComponent } from './components/ui/zoom-control/zoom-control.component';
import { ViewEditorActionsComponent } from './components/view-editor-actions/view-editor-actions.component';
import { ViewEditorBoundsComponent } from './components/view-editor-bounds/view-editor-bounds.component';
import { ViewEditorMenuButtonComponent } from './components/view-editor-menu-button/view-editor-menu-button.component';
import { ViewEditorMenuEditComponent } from './components/view-editor-menu-edit/view-editor-menu-edit.component';
import { ViewEditorMenuRootComponent } from './components/view-editor-menu-root/view-editor-menu-root.component';
import { ViewEditorNavigatorDragPreviewComponent } from './components/view-editor-navigator-drag-preview/view-editor-navigator-drag-preview.component';
import { ViewEditorNavigatorItemComponent } from './components/view-editor-navigator-item/view-editor-navigator-item.component';
import { ViewEditorNavigatorItemsComponent } from './components/view-editor-navigator-items/view-editor-navigator-items.component';
import { ViewEditorNavigatorComponent } from './components/view-editor-navigator/view-editor-navigator.component';
import { ViewEditorParametersValueItemComponent } from './components/view-editor-parameters-value-item/view-editor-parameters-value-item.component';
import { ViewEditorParametersComponent } from './components/view-editor-parameters/view-editor-parameters.component';
import { ViewEditorSidebarComponent } from './components/view-editor-sidebar/view-editor-sidebar.component';
import { ViewEditorToolsItemComponent } from './components/view-editor-tools-item/view-editor-tools-item.component';
import { ViewEditorToolsComponent } from './components/view-editor-tools/view-editor-tools.component';
import { ViewEditorViewComponent } from './components/view-editor-view/view-editor-view.component';
import { ViewEditorViewportOverlaysComponent } from './components/view-editor-viewport-overlays/view-editor-viewport-overlays.component';
import { ViewEditorViewportComponent } from './components/view-editor-viewport/view-editor-viewport.component';
import { ViewEditorComponent } from './components/view-editor/view-editor.component';
import { ViewEditorViewportOverlayDirective } from './directives/view-editor-viewport-overlay/view-editor-viewport-overlay.directive';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    TipsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicComponentModule,
    NgGxScrollableModule,
    DragDropModule,
    DragDrop2Module,
    TextEditorModule,
    OverlayModule,
    FieldComponentsModule,
    ParametersComponentsModule,
    UniqueIdModule,
    SidebarModule,
    ThemeComponentsModule,
    PortalModule,
    FieldsModule,
    LocalizeModule,
    SelectModule,
    CustomizeElementsModule,
    IconsModule
  ],
  declarations: [
    ViewEditorComponent,
    ViewEditorSidebarComponent,
    ViewEditorViewComponent,
    ViewEditorViewportComponent,
    AutoLayerComponent,
    RectangleLayerComponent,
    RectangleCustomizeLayerComponent,
    EllipseLayerComponent,
    EllipseCustomizeLayerComponent,
    LineLayerComponent,
    LineCustomizeLayerComponent,
    TextLayerComponent,
    TextCustomizeLayerComponent,
    ElementLayerComponent,
    ElementCustomizeLayerComponent,
    GroupLayerComponent,
    GroupCustomizeLayerComponent,
    FrameLayerComponent,
    FrameCustomizeLayerComponent,
    CompactInputComponent,
    FillControlComponent,
    ColorSelectorAdvancedComponent,
    CompactSelectComponent,
    BorderControlComponent,
    ShadowControlComponent,
    ZoomControlComponent,
    CompactFontControlComponent,
    CompactSegmentedControlComponent,
    FillColorOverlayComponent,
    GradientStopPositionDirective,
    ImageFillSelectorComponent,
    GradientSelectorComponent,
    ViewEditorToolsComponent,
    ViewEditorNavigatorComponent,
    ViewEditorNavigatorItemsComponent,
    ViewEditorNavigatorItemComponent,
    LayerResizableDirective,
    ViewEditorBoundsComponent,
    OpacityControlComponent,
    ViewEditorToolsItemComponent,
    ConstraintsControlComponent,
    CustomizeViewComponent,
    CornerRadiusControlComponent,
    AlphaControlComponent,
    HexControlComponent,
    CustomElementViewComponent,
    ViewEditorParametersComponent,
    ViewEditorParametersValueItemComponent,
    ViewEditorActionsComponent,
    CompactCheckboxComponent,
    GradientPositionComponent,
    GradientPositionPointComponent,
    ViewEditorViewportOverlayDirective,
    IconFillSelectorComponent,
    ColorButtonComponent,
    FillColorButtonComponent,
    ViewEditorViewportOverlaysComponent,
    LineLayerBoundsComponent,
    CustomViewTemplatesComponent,
    CustomViewTemplatesListComponent,
    CustomViewTemplatesCardComponent,
    CustomViewTemplatesCardStubComponent,
    CustomViewTemplatesDetailComponent,
    CustomViewTemplatesChangeComponent,
    CustomViewTemplateRatingComponent,
    CustomViewMapParametersComponent,
    CustomViewTemplatesActionCardComponent,
    ViewEditorMenuButtonComponent,
    ViewEditorMenuRootComponent,
    ViewEditorMenuEditComponent,
    ImportSketchFileComponent,
    ImportFigmaNodeComponent,
    ImportFigmaNodeItemComponent,
    ImportFigmaNodeItemStubComponent,
    ViewEditorNavigatorDragPreviewComponent,
    FlexLayoutControlComponent,
    FlexLayoutPaddingControlComponent,
    ContainerLayerControlComponent,
    FlexLayoutSectionComponent,
    CompactBorderButtonComponent,
    CompactWideButtonComponent,
    InteractionsSectionComponent,
    LayerInteractionComponent,
    InteractionsSectionHoverOutputComponent,
    InteractionsSectionPressedOutputComponent,
    InteractionsSectionCursorComponent,
    InteractionsSectionCursorDropdownComponent,
    InteractionsSectionClickComponent,
    ColorWithInputSelectorComponent
  ],
  exports: [CustomElementViewComponent],
  entryComponents: [
    ViewEditorComponent,
    RectangleLayerComponent,
    RectangleCustomizeLayerComponent,
    EllipseLayerComponent,
    EllipseCustomizeLayerComponent,
    LineLayerComponent,
    LineCustomizeLayerComponent,
    TextLayerComponent,
    TextCustomizeLayerComponent,
    ElementLayerComponent,
    ElementCustomizeLayerComponent,
    GroupLayerComponent,
    GroupCustomizeLayerComponent,
    FrameLayerComponent,
    FrameCustomizeLayerComponent,
    CustomViewTemplatesComponent,
    CustomViewMapParametersComponent,
    ImportSketchFileComponent,
    ImportFigmaNodeComponent
  ]
})
export class ViewsComponentsModule {}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/tutorial/directives/documentation/documentation.directive";
import * as i2 from "../../../modules/tutorial/services/guide-popup/guide-popup.service";
import * as i3 from "../../../modules/analytics/services/universal-analytics/universal-analytics.service";
import * as i4 from "../../../common/tips/directives/tip/tip.directive";
import * as i5 from "./help-icon.component";
var styles_HelpIconComponent = [];
var RenderType_HelpIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HelpIconComponent, data: {} });
export { RenderType_HelpIconComponent as RenderType_HelpIconComponent };
export function View_HelpIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "help-icon help-icon_clickable icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.DocumentationDirective, [i0.ElementRef, i2.GuidePopupService, i3.UniversalAnalyticsService], { documentation: [0, "documentation"], documentationLeft: [1, "documentationLeft"], documentationSource: [2, "documentationSource"] }, null), i0.ɵdid(2, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentation; var currVal_1 = _co.left; var currVal_2 = _co.source; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.label; var currVal_4 = _ck(_v, 3, 0, "top"); _ck(_v, 2, 0, currVal_3, currVal_4); }, null); }
export function View_HelpIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-help-icon", [], null, null, null, View_HelpIconComponent_0, RenderType_HelpIconComponent)), i0.ɵdid(1, 114688, null, 0, i5.HelpIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpIconComponentNgFactory = i0.ɵccf("app-help-icon", i5.HelpIconComponent, View_HelpIconComponent_Host_0, { documentation: "documentation", left: "left", source: "source", label: "label" }, {}, []);
export { HelpIconComponentNgFactory as HelpIconComponentNgFactory };

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { AppDragDrop, AppDragStart } from '@common/drag-drop2';

import { AppDropList } from '../../directives/drop-list/drop-list.directive';

@Injectable({
  providedIn: 'root'
})
export class DropListService {
  public readonly dropLists: AppDropList[] = [];
  public readonly dragging$ = new BehaviorSubject<boolean>(false);
  public readonly draggingDropList$ = new BehaviorSubject<AppDropList>(undefined);
  public readonly draggingDragStart$ = new BehaviorSubject<AppDragStart>(undefined);
  public readonly dropped$ = new Subject<AppDragDrop<any>>();

  constructor() {}

  public registerDropList(dropList: AppDropList) {
    const index = this.dropLists.indexOf(dropList);

    if (index == -1) {
      this.dropLists.push(dropList);
    }
  }

  public unregisterDropList(dropList: AppDropList) {
    const index = this.dropLists.indexOf(dropList);

    if (index != -1) {
      this.dropLists.splice(index, 1);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsLastXDaysFieldLookup = /** @class */ (function (_super) {
    __extends(IsLastXDaysFieldLookup, _super);
    function IsLastXDaysFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_last_x_days';
        _this.param = 'isLastXDays';
        _this.verboseName = localize('is Last X Days');
        _this.icon = 'calendar';
        return _this;
    }
    IsLastXDaysFieldLookup.prototype.from = function (value) {
        return moment().subtract(value, 'days').toISOString();
    };
    IsLastXDaysFieldLookup.prototype.to = function (value) {
        return moment().toISOString();
    };
    IsLastXDaysFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not last {1} days', [field, value]);
            }
            else {
                return localize('is not last X days');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is last {1} days', [field, value]);
            }
            else {
                return localize('last X days');
            }
        }
    };
    return IsLastXDaysFieldLookup;
}(BetweenFieldLookup));
export { IsLastXDaysFieldLookup };

import { getAutomationTriggerByType } from './automation-trigger-types';
import { AutomationTrigger } from './automation-triggers';
import { Workflow } from './workflow';
import { WorkflowRun } from './workflow-run';

export class WorkflowRunLog {
  public trigger: AutomationTrigger;
  public workflow: Workflow;
  public workflowRun: WorkflowRun;
  public success = false;
  public data: any;

  deserialize(data: Object) {
    this.success = data['success'];
    this.data = data['data'];

    const triggerTypeCls = getAutomationTriggerByType(data['trigger_type']);
    if (triggerTypeCls) {
      this.trigger = new triggerTypeCls().deserialize(data['trigger_params']);
    } else {
      this.trigger = undefined;
    }

    if (data['workflow']) {
      this.workflow = new Workflow().deserialize(data['workflow']);
    } else {
      this.workflow = undefined;
    }

    if (data['workflow_run']) {
      this.workflowRun = new WorkflowRun().deserialize(data['workflow_run']);
    } else {
      this.workflowRun = undefined;
    }

    return this;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { IntercomService } from '@modules/analytics';
import { Domain, SignUpLayout } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, ImageFit } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';

import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';

@Component({
  selector: 'app-customize-bar-signin-edit',
  templateUrl: './customize-bar-signin-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarSigninEditComponent implements OnInit, OnDestroy {
  @Input() projectSignUpForm: ProjectSignUpForm;
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;

  createField = createFormFieldFactory();
  booleanFieldStyle = BooleanFieldStyle;
  signUpLink: string;
  domain: Domain;
  hasSSO = false;
  layoutOptions = [
    {
      layout: SignUpLayout.PosterRight,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#d9eaf4'
    },
    {
      layout: SignUpLayout.PosterLeft,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#d9eaf4'
    },
    {
      layout: SignUpLayout.Form,
      backgroundImageEnabled: true,
      defaultBackgroundColor: '#dce0e6'
    },
    {
      layout: SignUpLayout.Background,
      defaultBackgroundColor: '#fff'
    }
  ];
  imageFits = ImageFit;
  signUpBackgroundColors = ['blue', 'teal', 'maroon', 'navy', 'purple', 'orange'];

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private featureService: FeatureService,
    private projectDomainController: ProjectDomainController,
    private intercomService: IntercomService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.signUpLink = this.getSignUpLink();
        this.cd.markForCheck();
      });

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.hasSSO = this.domain && this.domain.ssoSettings.length != 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  get currentLayoutOption() {
    return this.layoutOptions.find(item => item.layout == this.projectDomainUpdateForm.controls.sign_up_layout.value);
  }

  showSignUpFeatureOverview() {
    if (this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
      return;
    }

    this.featureService.showFeatureOverview({
      subtitle: 'Customer Portal Feature',
      title: 'App sign up with <strong>Customer Access</strong>',
      description: `
        <ul>
          <li>Customers can get access to app on their own without invitation</li>
          <li>Assign particular Team for newly registered customers</li>
          <li>Filter data for customers</li>
        </ul>
      `
    });
  }

  getSignUpLink(): string {
    const project = this.currentProjectStore.instance;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
    return `${webBaseUrl}/register/${this.currentProjectStore.instance.uniqueName}`;
  }

  copySignUpCode() {
    const text = this.getSignUpLink();

    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info('Copied', 'Link was copied to clipboard');
      });
  }

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_sign_in' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onDomainRequiredClick() {
    if (!this.domain) {
      this.editDomain();
    }
  }

  openChat() {
    this.intercomService.openChat();
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, FileOutputFormat, registerFieldViewParamsComponent } from '@modules/fields';
import { StorageSelectSource } from '@modules/storages-queries';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { FileFieldViewParamsForm } from './file-field-view-params.form';

@Component({
  selector: 'app-file-field-view-params',
  templateUrl: './file-field-view-params.component.html',
  providers: [FileFieldViewParamsForm, StorageSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileFieldViewParamsComponent extends FieldParamsComponent implements OnInit {
  createField = createFormFieldFactory();
  outputFormats = FileOutputFormat;

  constructor(public form: FileFieldViewParamsForm, public storageSelectSource: StorageSelectSource) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, { context: this.context });
  }
}

registerFieldViewParamsComponent(FieldType.File, FileFieldViewParamsComponent);

import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { IsOptionsValidResult, StripeGeneratorService, StripeParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class StripeResourceSettingsForm extends BaseResourceSettingsForm<StripeParamsOptions> {
  form = new AppFormGroup({
    secret_key: new FormControl('', Validators.required),
    sync: new FormControl(true)
  });

  constructor(
    private stripeGeneratorService: StripeGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.stripeGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          secret_key: result.secretKey,
          sync: !!this.resource.isSynced()
        });
      })
    );
  }

  getOptions(): StripeParamsOptions {
    return {
      secretKey: this.form.value['secret_key']
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.stripeGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.stripeGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name'],
            sync: this.form.value['sync'],
            syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
          };
        })
      );
  }
}

<app-sidebar-section>
  <ng-container *ngIf="type == fieldTypes.MultipleSelect">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'style'">
        <app-sidebar-card>
          <div [style.padding.px]="10">
            <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
              <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                <a
                  href="javascript:void(0)"
                  class="sidebar-image-toggle__item"
                  [class.sidebar-image-toggle__item_active]="
                    item.style === form.controls.style.value && item.circular === form.controls.circular.value
                  "
                  (click)="form.controls.style.patchValue(item.style); form.controls.circular.patchValue(item.circular)"
                >
                  <div
                    class="sidebar-image-toggle__item-inner sidebar-image-toggle__item-inner_border-radius_s"
                    [style.padding-top.px]="0"
                    [style.width.px]="74"
                    [style.height.px]="27"
                    [style.background-image]="'/assets/images/selects/' + item.image + '.svg' | appDeployCssUrl"
                  ></div>
                </a>
              </div>
            </div>
          </div>
        </app-sidebar-card>
      </app-sidebar-field>
    </div>

    <div
      *ngIf="type == fieldTypes.MultipleSelect && form.controls.style.value == multipleSelectStyles.Chips"
      class="sidebar__element"
      [style.margin-top.px]="-10"
    >
      <app-sidebar-card>
        <div class="sidebar__element sidebar__element_padding_none sidebar__element_margin_xs">
          <app-sidebar-field [label]="'Allow adding options'" [layoutLeft]="true">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'create_enabled',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>
      </app-sidebar-card>
    </div>
  </ng-container>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'options'">
      <div class="sidebar-icon-buttons">
        <div
          *ngFor="let item of form.optionsTypeOptions"
          class="sidebar-icon-button"
          [class.sidebar-icon-button_active]="form.controls.options_type.value == item.value"
          (click)="form.controls.options_type.patchValue(item.value)"
        >
          <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>
          <div class="sidebar-icon-button__label">{{ item.name }}</div>
        </div>
      </div>
    </app-sidebar-field>
  </div>

  <div *ngIf="form.controls.options_type.value == optionsTypes.Static" class="sidebar__list">
    <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDropOption($event)">
      <div *ngFor="let group of displayOptions; let i = index" [formGroup]="group" cdkDrag class="sidebar-list__item">
        <app-sidebar-collapse
          [title]="(group.value['name'] | appIsSet) ? group.value['name'] : 'No label'"
          [error]="group.invalid ? 'Option is not configured' : undefined"
          [description]="group.value['value'] != group.value['name'] ? group.value['value'] : undefined"
          [descriptionWrap]="true"
          [block]="true"
          [context]="collapseContext"
          [openedInitial]="group === createdOption"
          [arrow]="true"
          [drag]="true"
          [padding]="false"
        >
          <ng-container right>
            <a
              href="javascript:void(0)"
              class="sidebar-collapse__action icon-bin"
              (click)="$event.stopPropagation(); removeOption(group, i)"
            >
            </a>
          </ng-container>

          <div style="padding: 10px 0;">
            <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
              <app-sidebar-field [label]="'label'" [layoutRight]="true">
                <app-auto-field
                  [form]="group"
                  [label]="false"
                  [autofocus]="true"
                  [field]="
                    createField({
                      name: 'name',
                      field: 'CharField',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
              <app-sidebar-field [label]="'value'" [layoutRight]="true">
                <app-auto-field
                  [form]="group"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'value',
                      field: 'CharField',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div *ngIf="editable" class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
              <app-sidebar-field *ngIf="type != fieldTypes.RadioButton" [label]="'subtitle'" [layoutRight]="true">
                <app-auto-field
                  [form]="group"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'subtitle',
                      field: 'CharField',
                      params: { classes: ['select_fill'], allow_empty: true }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div *ngIf="editable" class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
              <app-sidebar-field [label]="'icon'" [layoutRight]="true">
                <app-auto-field
                  [form]="group"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'icon',
                      field: 'IconField',
                      params: { classes: ['select_fill'], allow_empty: true }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <div *ngIf="!editable" class="sidebar__element sidebar__element_field-right sidebar__element_margin_xxs">
              <app-sidebar-field [label]="'color'" [layoutRight]="true">
                <app-auto-field
                  [form]="group"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'color',
                      field: 'ColorField',
                      params: { classes: ['select_fill'], custom_colors: true }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </div>
        </app-sidebar-collapse>
      </div>

      <div *ngIf="form.controls.options.controls.length > optionsMaxDisplayInitial" class="sidebar-list__buttons">
        <a
          *ngIf="optionsCollapsed"
          href="javascript:void(0)"
          class="button button_transparent button_small sidebar-list__buttons-item"
          (click)="setOptionsCollapsed(false)"
        >
          <span class="icon-arrow_down_2 button__icon button__icon_left"></span>
          <span class="button__label">Show all ({{ form.controls.options.controls.length }})</span>
        </a>
        <a
          *ngIf="!optionsCollapsed"
          href="javascript:void(0)"
          class="button button_transparent button_small sidebar-list__buttons-item"
          (click)="setOptionsCollapsed(true)"
        >
          <span class="icon-arrow_up_2 button__icon button__icon_left"></span>
          <span class="button__label">Collapse</span>
        </a>
      </div>

      <div class="sidebar-list__buttons">
        <a
          href="javascript:void(0)"
          class="button button_orange-transparent button_small sidebar-list__buttons-item"
          (click)="addOption()"
        >
          <span class="icon icon-plus button__icon button__icon_left"></span>
          <span class="button__label">Add an Option</span>
        </a>

        <a
          *ngIf="form.controls.query.value?.isConfigured() && form.controls.value_field.value | appIsSet"
          href="javascript:void(0)"
          class="button button_orange-alternative button_small sidebar-list__buttons-item"
          [class.button_disabled]="toStaticOptionsLoading"
          (click)="toStaticOptions(true)"
        >
          <span class="button__label">Add from resource</span>
        </a>

        <a
          *ngIf="form.getMissingAvailable() && !addingMissing"
          href="javascript:void(0)"
          class="button button_orange-transparent button_small sidebar-list__buttons-item"
          [class.button_primary]="!control.value['options']?.length"
          (click)="addMissing()"
        >
          <span class="icon icon-plus button__icon button__icon_left"></span>
          <span class="button__label">Auto-add Missing Values</span>
        </a>

        <app-field-errors
          *ngIf="!form.controls.options.controls.length"
          [form]="form"
          [fieldName]="'options'"
        ></app-field-errors>
      </div>
    </div>
  </div>

  <ng-container *ngIf="form.controls.options_type.value == optionsTypes.Query">
    <div class="sidebar__element">
      <div class="sidebar__element-segment">
        <app-sidebar-field [classes]="'component-data-resource'">
          <app-resource-field2
            [form]="form"
            [label]="false"
            [errors]="false"
            [field]="
              createField({
                name: 'resource',
                field: 'ResourceField',
                params: { model_resources: true, classes: ['select_fill'] }
              })
            "
          ></app-resource-field2>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element-segment">
        <app-sidebar-field [classes]="'component-data-collection'">
          <app-custom-select
            [control]="form.controls.model"
            [items]="resourceModelItems"
            [compareWith]="form.modelValueEquals"
            [emptyLabel]="'No collections'"
            [classes]="['select_fill']"
            [panelClasses]="['mat-menu-panel_sidebar']"
            (buttonClick)="onModelButtonClick($event)"
          >
          </app-custom-select>
          <app-field-errors [form]="form" [fieldName]="'query'"></app-field-errors>

          <ng-container right>
            <a
              *ngIf="
                resource &&
                form.controls.model.value &&
                editableQueryTypes.includes(form.controls.model.value?.queryType)
              "
              href="javascript:void(0)"
              class="button button_bigger button_icon button_orange-alternative sidebar-field__button"
              [appTip]="'Edit request'"
              (click)="editQuery()"
            >
              <span class="icon-edit button__icon"></span>
            </a>
          </ng-container>
        </app-sidebar-field>
      </div>
    </div>

    <div *ngIf="form.controls.query.valid" class="sidebar__element">
      <app-sidebar-card>
        <div class="sidebar__element">
          <app-sidebar-field [label]="'label'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.label_input_enabled.value"
                [appTip]="form.controls.label_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="form.controls.label_input_enabled.patchValue(!form.controls.label_input_enabled.value)"
              ></span>
            </ng-container>

            <app-custom-select
              *ngIf="!form.controls.label_input_enabled.value"
              [control]="form.controls.label_field"
              [items]="columnOptions"
              [emptyLabel]="'No fields found'"
              [classes]="['select_fill']"
              [panelClasses]="['mat-menu-panel_sidebar']"
            >
            </app-custom-select>

            <app-input-edit
              *ngIf="form.controls.label_input_enabled.value"
              [itemForm]="form.controls.label_input"
              [context]="context"
              [contextElement]="columnContextElement"
              [contextElementPath]="columnContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
              [jsPlaceholder]="'return `${item.name}`;'"
              [fill]="true"
              [analyticsSource]="object + 'label_field_input'"
            ></app-input-edit>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <app-sidebar-field [label]="'value'">
            <app-custom-select
              [control]="form.controls.value_field"
              [items]="columnOptions"
              [emptyLabel]="'No fields found'"
              [classes]="['select_fill']"
              [panelClasses]="['mat-menu-panel_sidebar']"
            >
            </app-custom-select>
          </app-sidebar-field>
        </div>

        <div *ngIf="editable" class="sidebar__element">
          <app-sidebar-field *ngIf="type != fieldTypes.RadioButton" [label]="'subtitle'" [additional]="'(optional)'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.subtitle_input_enabled.value"
                [appTip]="form.controls.subtitle_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="form.controls.subtitle_input_enabled.patchValue(!form.controls.subtitle_input_enabled.value)"
              ></span>
            </ng-container>

            <app-custom-select
              *ngIf="!form.controls.subtitle_input_enabled.value"
              [control]="form.controls.subtitle_field"
              [items]="columnOptions"
              [emptyLabel]="'No fields found'"
              [resetEnabled]="true"
              [classes]="['select_fill']"
              [panelClasses]="['mat-menu-panel_sidebar']"
            >
            </app-custom-select>

            <app-input-edit
              *ngIf="form.controls.subtitle_input_enabled.value"
              [itemForm]="form.controls.subtitle_input"
              [context]="context"
              [contextElement]="columnContextElement"
              [contextElementPath]="columnContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
              [jsPlaceholder]="'return `${item.name}`;'"
              [fill]="true"
              [analyticsSource]="object + 'subtitle_field_input'"
            ></app-input-edit>
          </app-sidebar-field>
        </div>

        <div *ngIf="editable" class="sidebar__element">
          <app-sidebar-field [label]="'icon'" [additional]="'(optional)'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.icon_input_enabled.value"
                [appTip]="form.controls.icon_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="form.controls.icon_input_enabled.patchValue(!form.controls.icon_input_enabled.value)"
              ></span>
            </ng-container>

            <app-auto-field
              *ngIf="!form.controls.icon_input_enabled.value"
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'icon_field',
                  label: 'Icon',
                  field: 'IconField',
                  params: { classes: ['select_fill'], allow_empty: true }
                })
              "
            ></app-auto-field>

            <app-input-edit
              *ngIf="form.controls.icon_input_enabled.value"
              [itemForm]="form.controls.icon_input"
              [context]="context"
              [contextElement]="columnContextElement"
              [contextElementPath]="columnContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
              [jsPlaceholder]="'return `${item.name}`;'"
              [fill]="true"
              [analyticsSource]="object + 'icon_field_input'"
            ></app-input-edit>
          </app-sidebar-field>
        </div>

        <div *ngIf="!editable" class="sidebar__element">
          <app-sidebar-field [label]="'color'" [additional]="'(optional)'">
            <ng-container actions>
              <span
                class="sidebar__field-action sidebar__field-action_button icon-function"
                [class.sidebar__field-action_active]="form.controls.color_input_enabled.value"
                [appTip]="form.controls.color_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
                [appTipOptions]="{ side: 'top' }"
                (click)="form.controls.color_input_enabled.patchValue(!form.controls.color_input_enabled.value)"
              ></span>
            </ng-container>

            <app-custom-select
              *ngIf="!form.controls.color_input_enabled.value"
              [control]="form.controls.color_field"
              [items]="columnOptions"
              [emptyLabel]="'No fields found'"
              [classes]="['select_fill']"
              [panelClasses]="['mat-menu-panel_sidebar']"
            >
            </app-custom-select>

            <app-input-edit
              *ngIf="form.controls.color_input_enabled.value"
              [itemForm]="form.controls.color_input"
              [context]="context"
              [contextElement]="columnContextElement"
              [contextElementPath]="columnContextElementPath"
              [staticValueDisabled]="true"
              [formulaPlaceholder]="'CONCAT(\'$\', item.name)'"
              [jsPlaceholder]="'return `${item.name}`;'"
              [fill]="true"
              [analyticsSource]="object + 'color_field_input'"
            ></app-input-edit>
          </app-sidebar-field>
        </div>

        <div class="sidebar__element">
          <button
            type="button"
            class="button button_fill button_orange"
            [class.button_disabled]="toStaticOptionsLoading"
            (click)="toStaticOptions()"
          >
            <app-loader-small *ngIf="toStaticOptionsLoading" class="button__icon button__icon_left"></app-loader-small>
            <span *ngIf="!toStaticOptionsLoading" class="button__icon button__icon_left icon-magic_wand"></span>
            <span class="button__label">Load and customize options</span>
          </button>
        </div>
      </app-sidebar-card>
    </div>
  </ng-container>

  <ng-container *ngIf="form.controls.options_type.value == optionsTypes.Query && form.controls.query.valid">
    <div *ngIf="sortableColumnOptions?.length" class="sidebar__element">
      <app-sidebar-field [label]="'Default sorting'" [classes]="'component-data-sorting'">
        <app-select-field
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'sorting_field',
              field: 'SelectField',
              params: {
                options: sortableColumnOptions,
                allow_empty: true,
                empty_name: 'Not specified',
                classes: ['select_fill']
              }
            })
          "
          #sorting_field
        >
        </app-select-field>
        <app-field-errors [form]="form" [fieldName]="'sorting_field'"></app-field-errors>
        <app-field-errors [form]="form" [fieldName]="'sorting_asc'"></app-field-errors>

        <ng-container right>
          <a
            href="javascript:void(0)"
            class="button button_bigger button_icon button_orange-alternative button_primary sidebar-field__button"
            [class.button_disabled]="!form.controls.sorting_field.value"
            [appTip]="'Change sorting order'"
            (click)="form.toggleDefaultSorting()"
          >
            <span
              class="button__icon"
              [class.icon-filter_down]="form.controls.sorting_asc.value"
              [class.icon-filter_up]="!form.controls.sorting_asc.value"
            ></span>
          </a>
        </ng-container>
      </app-sidebar-field>
    </div>
  </ng-container>
</app-sidebar-section>

<app-sidebar-section
  *ngIf="form.controls.options_type.value == optionsTypes.Query && form.controls.query.valid"
  [title]="modelOption?.queryType != queryTypes.Simple ? 'Specify Inputs' : 'Apply Filters'"
  [description]="
    modelOption?.queryType != queryTypes.Simple
      ? 'Manually or use value from other components'
      : 'Static or use value from other components'
  "
>
  <div class="sidebar__list component-data-inputs">
    <app-inputs-edit
      [control]="form.controls.inputs"
      [parameterProvider]="form.inputFieldProvider"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [classes]="'component-data-inputs-item'"
      [object]="object"
    >
    </app-inputs-edit>
  </div>
</app-sidebar-section>

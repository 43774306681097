/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./page-card.component";
var styles_PageCardComponent = [];
var RenderType_PageCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageCardComponent, data: {} });
export { RenderType_PageCardComponent as RenderType_PageCardComponent };
export function View_PageCardComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_PageCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-card", [], [[2, "page-card", null], [2, "page-card_border", null]], null, null, View_PageCardComponent_0, RenderType_PageCardComponent)), i0.ɵdid(1, 114688, null, 0, i1.PageCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).border; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var PageCardComponentNgFactory = i0.ɵccf("app-page-card", i1.PageCardComponent, View_PageCardComponent_Host_0, { border: "border" }, {}, ["*"]);
export { PageCardComponentNgFactory as PageCardComponentNgFactory };

export var ApiType;
(function (ApiType) {
    ApiType["JetDjango"] = "jet_django";
    ApiType["JetBridge"] = "jet_bridge";
})(ApiType || (ApiType = {}));
export function versionCompare(v1, v2, options) {
    if (options === void 0) { options = {}; }
    var lexicographical = options && options['lexicographical'];
    var zeroExtend = options && options['zeroExtend'];
    var v1parts = (v1 || '').toString().split('.').map(Number);
    var v2parts = (v2 || '').toString().split('.').map(Number);
    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }
    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }
    if (zeroExtend) {
        while (v1parts.length < v2parts.length) {
            v1parts.push(0);
        }
        while (v2parts.length < v1parts.length) {
            v2parts.push(0);
        }
    }
    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }
    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }
        if (v1parts[i] == v2parts[i]) {
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }
    if (v1parts.length != v2parts.length) {
        return -1;
    }
    return 0;
}
export function versionLessThan(v1, v2) {
    return versionCompare(v1, v2) == -1;
}
export function versionGreaterThanOrEquals(v1, v2) {
    return versionCompare(v1, v2) != -1;
}
var ApiInfo = /** @class */ (function () {
    function ApiInfo() {
        this.storeAvailable = false;
    }
    ApiInfo.prototype.deserialize = function (data) {
        this.version = data['version'];
        this.type = data['type'] || ApiType.JetDjango;
        this.storeAvailable = !!data['store_available'];
        this.mediaUrlTemplate = data['media_url_template'];
        return this;
    };
    Object.defineProperty(ApiInfo.prototype, "customType", {
        get: function () {
            return this.type && this.type != ApiType.JetBridge && this.type != ApiType.JetDjango;
        },
        enumerable: true,
        configurable: true
    });
    ApiInfo.prototype.mediaUrl = function (path) {
        return this.mediaUrlTemplate.replace('{}', path);
    };
    ApiInfo.prototype.versionCompare = function (v2, options) {
        if (options === void 0) { options = {}; }
        return versionCompare(this.version, v2);
    };
    ApiInfo.prototype.versionLessThan = function (v2) {
        return versionLessThan(this.version, v2);
    };
    ApiInfo.prototype.versionGreaterThanOrEquals = function (v2) {
        return versionGreaterThanOrEquals(this.version, v2);
    };
    ApiInfo.prototype.isCompatibleJetBridge = function (version) {
        return ((this.type == ApiType.JetBridge && this.versionGreaterThanOrEquals(version.jetBridge)) ||
            (this.type == ApiType.JetDjango && this.versionGreaterThanOrEquals(version.jetDjango)));
    };
    return ApiInfo;
}());
export { ApiInfo };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, Resource, ResourceDeploy, ResourceService, SecretTokenService } from '@modules/projects';
import { RegionService } from '@modules/regions';
import { DatabaseGeneratorService } from '@modules/resource-generators';
import { generateAlphanumeric, generateUUID } from '@shared';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var JetDatabaseResourceSettingsForm = /** @class */ (function (_super) {
    __extends(JetDatabaseResourceSettingsForm, _super);
    function JetDatabaseResourceSettingsForm(databaseGeneratorService, regionService, resourceService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.databaseGeneratorService = databaseGeneratorService;
        _this.regionService = regionService;
        _this.resourceService = resourceService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.popupService = popupService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.injector = injector;
        _this.form = new AppFormGroup({});
        return _this;
    }
    JetDatabaseResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        return this.databaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(map(function (result) {
            _this.options = result;
        }));
    };
    JetDatabaseResourceSettingsForm.prototype.getOptions = function () {
        var _this = this;
        return this.regionService.getDefaultJetBridge().pipe(map(function (jetBridge) {
            return __assign({ deploy: ResourceDeploy.Direct, region: jetBridge.region ? jetBridge.region.uid : undefined, url: jetBridge.url }, _this.options);
        }));
    };
    JetDatabaseResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        return this.getOptions().pipe(switchMap(function (options) {
            return _this.databaseGeneratorService.generateParams(_this.project, _this.environment, _this.typeItem, options);
        }), map(function (result) {
            return __assign({}, result, { resourceName: _this.resourceForm.value['name'] });
        }));
    };
    JetDatabaseResourceSettingsForm.prototype.submit = function () {
        var _this = this;
        this.deploy = ResourceDeploy.Direct;
        var create = !this.resource;
        if (create) {
            var instance = new Resource();
            instance.uniqueName = [this.typeItem.name, generateAlphanumeric(4)].join('_');
            instance.name = this.resourceForm.value['name'];
            instance.type = this.typeItem.resourceType;
            instance.typeItem = this.typeItem;
            return this.resourceService.create(this.project.uniqueName, this.environment.uniqueName, instance).pipe(switchMap(function (resource) {
                _this.resource = resource;
                return _this.databaseGeneratorService.createJetDatabase(_this.project, _this.environment, resource).pipe(catchError(function (e) {
                    _this.resource = undefined;
                    return _this.resourceService
                        .delete(_this.project.uniqueName, _this.environment.uniqueName, resource)
                        .pipe(switchMap(function () { return throwError(e); }));
                }));
            }), catchError(function (error) {
                console.error(error);
                _this.formUtils.showFormErrors(_this.form, error);
                return throwError(error);
            }), switchMap(function (result) {
                var address = result.url.split(':', 2);
                _this.options = {
                    deploy: ResourceDeploy.Direct,
                    database_host: address[0],
                    database_port: address[1],
                    database_name: result.databaseName,
                    database_user: result.userName,
                    database_password: result.password,
                    database_schema: _this.resource.uniqueName,
                    token: _this.resource.token || generateUUID()
                };
                return _super.prototype.submit.call(_this);
            }));
        }
        else {
            return _super.prototype.submit.call(this);
        }
    };
    return JetDatabaseResourceSettingsForm;
}(BaseResourceSettingsForm));
export { JetDatabaseResourceSettingsForm };

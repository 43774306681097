import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ViewContext } from '@modules/customize';
import { Frame, FrameTranslate, PointTranslate } from '@modules/views';
import { isSet, MouseButton, pointsDistance } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { snapPoint } from '../../../utils/guides';
import { GradientStopControl } from '../../controls/gradient-stop.control';
var GradientPositionPointComponent = /** @class */ (function () {
    function GradientPositionPointComponent(editorContext, el, parentCd) {
        this.editorContext = editorContext;
        this.el = el;
        this.parentCd = parentCd;
        this.active = false;
        this.translate = { x: 0, y: 0 };
        this.snap = false;
        this.positionChange = new EventEmitter();
        this.cls = true;
        this.moveThreshold = 5;
    }
    Object.defineProperty(GradientPositionPointComponent.prototype, "activeCls", {
        get: function () {
            return this.active;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GradientPositionPointComponent.prototype, "colorCls", {
        get: function () {
            return !!this.control;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GradientPositionPointComponent.prototype, "left", {
        get: function () {
            if (isSet(this.positionPx)) {
                return this.positionPx + "px";
            }
            else if (isSet(this.positionEm)) {
                return this.positionEm + "em";
            }
            else if (isSet(this.positionPercent)) {
                return this.positionPercent * 100 + "%";
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    GradientPositionPointComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.control) {
            this.control
                .getColor$({ context: this.viewContext })
                .pipe(untilDestroyed(this))
                .subscribe(function (background) {
                _this.backgroundColor = background;
                _this.parentCd.markForCheck();
            });
        }
    };
    GradientPositionPointComponent.prototype.ngOnDestroy = function () { };
    GradientPositionPointComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(this.el.nativeElement, 'mousedown')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (downEvent) {
            var subscriptions = [];
            var thresholdPassed = false;
            subscriptions.push(fromEvent(document, 'mousemove')
                .pipe(untilDestroyed(_this))
                .subscribe(function (moveEvent) {
                moveEvent.preventDefault();
                if (!thresholdPassed) {
                    var originDistance = pointsDistance(downEvent.clientX, downEvent.clientY, moveEvent.clientX, moveEvent.clientY);
                    if (originDistance >= _this.moveThreshold) {
                        thresholdPassed = true;
                        if (_this.snap) {
                            _this.editorContext.removeHorizontalGuides();
                            _this.editorContext.removeVerticalGuides();
                        }
                    }
                }
                if (thresholdPassed) {
                    var bounds = _this.bounds.getBoundingClientRect();
                    var positionX = round((moveEvent.clientX - bounds.left) / bounds.width, 2);
                    var positionY = round((moveEvent.clientY - bounds.top) / bounds.height, 2);
                    if (_this.snap) {
                        var scale = _this.editorContext.viewportScale$.value;
                        var deltaX = (moveEvent.clientX - bounds.left) / scale;
                        var deltaY = (moveEvent.clientY - bounds.top) / scale;
                        var x = _this.frame.x + deltaX;
                        var y = _this.frame.y + deltaY;
                        var snap = snapPoint({
                            point: new PointTranslate({
                                point: { x: x, y: y },
                                translate: _this.translate
                            }),
                            otherFrames: [
                                new FrameTranslate({
                                    frame: _this.frame,
                                    translate: _this.translate
                                })
                            ]
                        });
                        if (isSet(snap.updatePoint.x)) {
                            positionX = round((snap.updatePoint.x - _this.frame.x) / _this.frame.width, 2);
                        }
                        if (isSet(snap.updatePoint.y)) {
                            positionY = round((snap.updatePoint.y - _this.frame.y) / _this.frame.height, 2);
                        }
                        _this.editorContext.setHorizontalGuides(snap.horizontalGuides);
                        _this.editorContext.setVerticalGuides(snap.verticalGuides);
                    }
                    _this.positionChange.emit({ x: positionX, y: positionY });
                }
            }));
            subscriptions.push(fromEvent(document, 'mouseup')
                .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(_this))
                .subscribe(function (upEvent) {
                upEvent.preventDefault();
                subscriptions.forEach(function (item) { return item.unsubscribe(); });
                if (_this.snap) {
                    _this.editorContext.removeHorizontalGuides();
                    _this.editorContext.removeVerticalGuides();
                }
            }));
        });
    };
    return GradientPositionPointComponent;
}());
export { GradientPositionPointComponent };

import * as Color from 'color';

import { getColorHex, parseColor } from '@modules/colors';
import { fonts, getFontFamily } from '@modules/theme';
import { isSet } from '@shared';

import { ThemeOptions } from '../data/theme-options';

export enum ThemeVar {
  AccentColor = 'accent-color',
  AccentColor2 = 'accent-color-2',
  AccentColor3 = 'accent-color-3',
  AccentContrastColor = 'accent-contrast-color',
  FontRegular = 'regular-font',
  FontHeading = 'heading-font'
}

export const themeVars = [
  ThemeVar.AccentColor,
  ThemeVar.AccentColor2,
  ThemeVar.AccentColor3,
  ThemeVar.AccentContrastColor,
  ThemeVar.FontRegular,
  ThemeVar.FontHeading
];

export function getThemeVars(options: ThemeOptions = {}): { [k in ThemeVar]?: string } {
  const vars: { [k in ThemeVar]?: string } = {};
  const fontRegular = fonts.find(item => item.name == options.fontRegular);
  const fontHeading = fonts.find(item => item.name == options.fontHeading);

  if (isSet(options.accentColor)) {
    const hex = getColorHex(options.accentColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (clr) {
      const isDark = clr.contrast(Color('white')) >= 2;

      vars[ThemeVar.AccentColor] = clr.string();
      vars[ThemeVar.AccentColor2] = clr.lighten(0.15).string();
      vars[ThemeVar.AccentColor3] = clr.lighten(0.2).string();
      vars[ThemeVar.AccentContrastColor] = isDark ? '#fff' : clr.darken(0.6).string();
    }
  }

  if (fontRegular && !fontRegular.default) {
    vars[ThemeVar.FontRegular] = getFontFamily(fontRegular);
  }

  if (fontHeading && !fontHeading.default) {
    vars[ThemeVar.FontHeading] = getFontFamily(fontHeading);
  }

  return vars;
}

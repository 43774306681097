import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import {
  CustomizeService,
  ElementType,
  registerElementComponent,
  StepsElementItem,
  StepsItem
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

interface ElementStateItem {
  item: StepsItem;
  visible: boolean;
}

interface ElementState {
  items?: ElementStateItem[];
  visibleItems?: StepsItem[];
  itemsCount?: number;
  currentItem?: any;
  currentItemIndex?: number;
  currentItemColor?: string;
}

function getElementStateProgress(state: ElementState): Object {
  return {
    currentItemIndex: state.currentItemIndex,
    itemsCount: state.itemsCount
  };
}

@Component({
  selector: 'app-steps-element',
  templateUrl: './steps-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsElementComponent extends BaseElementComponent<StepsElementItem>
  implements OnInit, OnDestroy, OnChanges {
  state: ElementState = {};
  customizeEnabled$: Observable<boolean>;
  progress = 0;

  trackStepItem = (() => {
    return (i, item: ElementStateItem) => {
      return item.item.uid;
    };
  })();

  constructor(public customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<StepsElementComponent>): void {
    if (changes.element) {
      this.elementOnChange(this.element);
    }
  }

  trackChanges() {
    this.element$
      .pipe(
        switchMap(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.state = state;
      });
  }

  getElementState(element: StepsElementItem): Observable<ElementState> {
    const currentItem$ = element.currentItem
      ? applyParamInput$(element.currentItem, {
          context: this.context,
          handleLoading: true,
          ignoreEmpty: true
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of(undefined);
    const itemsVisible$ = element.items.length
      ? combineLatest(
          element.items.map<Observable<ElementStateItem>>(item => {
            if (!item.visibleInput) {
              return of({ item: item, visible: true });
            } else {
              return applyBooleanInput$(item.visibleInput, { context: this.context }).pipe(
                map(visible => {
                  return { item: item, visible: visible };
                })
              );
            }
          })
        )
      : of([]);

    return combineLatest(this.customizeService.enabled$, currentItem$, itemsVisible$).pipe(
      map(([customize, currentItem, itemsVisible]) => {
        const items = itemsVisible.filter(item => {
          if (customize) {
            return true;
          } else {
            return item.visible;
          }
        });
        const currentItemIndex = items.findIndex(item => item.item.value == currentItem);

        return {
          items: items,
          itemsCount: items.length,
          currentItem: currentItem,
          currentItemIndex: currentItemIndex,
          currentItemColor: currentItemIndex !== -1 ? items[currentItemIndex].item.color : undefined
        };
      })
    );
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateProgress(state), getElementStateProgress(this.state))) {
      this.updateProgress(state);
    }
  }

  updateProgress(state: ElementState) {
    if (state.currentItemIndex === -1 || !isSet(state.itemsCount) || state.itemsCount === 0) {
      this.progress = 0;
      this.cd.markForCheck();
      return;
    }

    const itemWidth = 1 / state.itemsCount;

    if (state.currentItemIndex === 0) {
      this.progress = 0;
    } else if (state.currentItemIndex === state.itemsCount - 1) {
      this.progress = 1;
    } else {
      this.progress = itemWidth * state.currentItemIndex + itemWidth * 0.5;
    }

    this.cd.markForCheck();
  }
}

registerElementComponent({
  type: ElementType.Steps,
  component: StepsElementComponent,
  label: 'Image',
  actions: []
});

export class ValidationCode {
  public code: string;
  public dateCreated: any;

  deserialize(data: Object): ValidationCode {
    this.code = data['code'];
    this.dateCreated = data['date_created'];

    return this;
  }
}

import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { localize } from '@common/localize';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { controlValue } from '@shared';

import {
  ProjectPermissionActionsControl,
  ProjectPermissionActionsValue
} from '../project-group-change/project-permission-actions.control';

enum ValueType {
  NoAccess = 'NoAccess',
  FullAccess = 'FullAccess',
  ReadOnly = 'ReadOnly',
  Custom = 'Custom'
}

interface ValueOption {
  type: ValueType;
  name: string;
  description?: string;
  icon?: string;
}

@Component({
  selector: 'app-project-permission-actions',
  templateUrl: './project-permission-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPermissionActionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: ProjectPermissionActionsControl;
  @Input() small = false;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  options: ValueOption[] = [
    {
      type: ValueType.FullAccess,
      name: localize('Full Access'),
      description: localize('Read, create, update and delete data'),
      icon: 'magic_wand'
    },
    {
      type: ValueType.ReadOnly,
      name: localize('Read Only'),
      description: localize('Can only view data without modification'),
      icon: 'eye'
    },
    {
      type: ValueType.Custom,
      name: localize('Custom Access'),
      description: localize('Choose allowed operations granularly'),
      icon: 'configure'
    },
    {
      type: ValueType.NoAccess,
      name: localize('No Access'),
      description: localize('Without any access to data'),
      icon: 'close'
    }
  ];
  currentOption: ValueOption;
  valueTypes = ValueType;
  dropdownOpened = false;
  custom = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue<ProjectPermissionActionsValue>(this.control)
      .pipe(
        map<any, [ProjectPermissionActionsValue, number]>((value, i) => [value, i]),
        untilDestroyed(this)
      )
      .subscribe(([value, i]) => {
        let valueType: ValueType;

        if (value.read && value.write && value.delete) {
          valueType = ValueType.FullAccess;
        } else if (value.read && !value.write && !value.delete) {
          valueType = ValueType.ReadOnly;
        } else if (!value.read && !value.write && !value.delete) {
          valueType = ValueType.NoAccess;
        } else {
          valueType = ValueType.Custom;

          if (i == 0) {
            this.custom = true;
          }
        }

        this.currentOption = this.options.find(item => item.type == valueType);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setValueType(type: ValueType) {
    const wasAccess = this.control.isSelectedAny();

    if (type == ValueType.FullAccess) {
      this.control.setAll(true);
    } else if (type == ValueType.ReadOnly) {
      this.control.controls.read.setValue(true);
      this.control.controls.write.setValue(false);
      this.control.controls.delete.setValue(false);
    } else if (type == ValueType.NoAccess) {
      this.control.setAll(false);
    } else if (type == ValueType.Custom) {
      this.setCustom(true);
    }

    this.cd.markForCheck();

    if (wasAccess && type == ValueType.NoAccess) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageRemoved, {
        Mode: this.mode
      });
    } else if (!wasAccess && type != ValueType.NoAccess) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
        Mode: this.mode
      });
    }
  }

  setCustom(value: boolean) {
    this.custom = value;
    this.cd.markForCheck();
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

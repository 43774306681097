/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./custom-view.component";
import * as i2 from "../../../custom-views/services/custom-view-loader/custom-view-loader.service";
var styles_CustomViewComponent = [];
var RenderType_CustomViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomViewComponent, data: {} });
export { RenderType_CustomViewComponent as RenderType_CustomViewComponent };
export function View_CustomViewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { container: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CustomViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-view", [], null, null, null, View_CustomViewComponent_0, RenderType_CustomViewComponent)), i0.ɵdid(1, 4898816, null, 0, i1.CustomViewComponent, [i2.CustomViewLoaderService], null, null)], null, null); }
var CustomViewComponentNgFactory = i0.ɵccf("app-custom-view", i1.CustomViewComponent, View_CustomViewComponent_Host_0, { customView: "customView", attrs: "attrs" }, {}, []);
export { CustomViewComponentNgFactory as CustomViewComponentNgFactory };

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CookieStorage } from '@core';

import { CurrentProjectStore } from '../../../projects/stores/current-project.store';

@Injectable()
export class DemoBannerService {
  _enabled = new BehaviorSubject<boolean>(false);
  _visible = new BehaviorSubject<boolean>(false);

  constructor(private currentProjectStore: CurrentProjectStore, private cookieStorage: CookieStorage) {
    this._visible.next(this.cookieStorage.get('banner_visibility') != 'false');

    this.currentProjectStore.instance$.subscribe(project => {
      this._enabled.next(project && project.demo);
    });
  }

  get enabled(): boolean {
    return this._enabled.value;
  }

  get enabled$(): Observable<boolean> {
    return this._enabled.asObservable();
  }

  get visible(): boolean {
    return this._visible.value;
  }

  get visible$(): Observable<boolean> {
    return this._visible.asObservable();
  }

  set visible(value: boolean) {
    this._visible.next(value);
    this.cookieStorage.set('banner_visibility', value.toString());
  }
}

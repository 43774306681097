import { isSet } from '@shared';

// TODO: Refactor import
import { FieldType } from '../../fields/data/field-type';
import { FieldDescription, getFieldDescriptionByType } from '../../fields/data/fields';

export class FilterParameter {
  name: string;
  label: string;
  field: FieldType = FieldType.Text;
  params: Object = {};
  fieldDescription: FieldDescription;

  deserialize(data: Object): FilterParameter {
    this.name = data['name'];
    this.label = data['label'];
    this.field = data['field'];
    this.params = data['params'];

    this.updateFieldDescription();

    return this;
  }

  get labelOrName(): string {
    return isSet(this.label) ? this.label : this.name;
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
var ProjectPermissionActionsControl = /** @class */ (function (_super) {
    __extends(ProjectPermissionActionsControl, _super);
    function ProjectPermissionActionsControl(formState) {
        return _super.call(this, {
            read: new FormControl(formState ? !!formState.read : false),
            write: new FormControl(formState ? !!formState.write : false),
            delete: new FormControl(formState ? !!formState.delete : false)
        }) || this;
    }
    ProjectPermissionActionsControl.prototype.deserialize = function (value) {
        this.controls.read.setValue(value.includes('r'));
        this.controls.write.setValue(value.includes('w'));
        this.controls.delete.setValue(value.includes('d'));
    };
    ProjectPermissionActionsControl.prototype.serialize = function () {
        return [this.value.read ? 'r' : '', this.value.write ? 'w' : '', this.value.delete ? 'd' : ''].join('');
    };
    ProjectPermissionActionsControl.prototype.setAll = function (value) {
        this.patchValue({
            read: value,
            write: value,
            delete: value
        });
    };
    ProjectPermissionActionsControl.prototype.toggleAll = function () {
        if (this.isSelectedAll()) {
            this.setAll(false);
        }
        else {
            this.setAll(true);
        }
    };
    ProjectPermissionActionsControl.prototype.isSelectedAll = function () {
        return [this.controls.read.value, this.controls.write.value, this.controls.delete.value].every(function (item) { return item; });
    };
    ProjectPermissionActionsControl.prototype.isSelectedAny = function () {
        return [this.controls.read.value, this.controls.write.value, this.controls.delete.value].some(function (item) { return item; });
    };
    return ProjectPermissionActionsControl;
}(FormGroup));
export { ProjectPermissionActionsControl };

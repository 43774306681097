<div
  class="project-logo"
  [class.project-logo_transparent]="colorDisplay == 'transparent'"
  [class.project-logo_border]="border && colorDisplay == 'white'"
  [class.project-logo_fill]="fill && logo"
  [class.project-logo_light]="light"
  [style.background-color]="(colorDisplay | appIsColorHex) ? colorDisplay : null"
  [style.border-radius.%]="(borderRadiusPercent | appIsSet) ? borderRadiusPercent : null"
  [ngClass]="[
    !(colorDisplay | appIsColorHex) ? 'background-color_' + colorDisplay + '_1' : '',
    isButton ? 'project-logo_button' : '',
    'project-logo_size_' + size,
    'project-logo_border-radius_' + borderRadius
  ]"
>
  <span
    class="project-logo__inner"
    [class.project-logo__inner_fill]="logo"
    [ngClass]="[icon && !logo ? 'icon ' + icon : '']"
    [style.background-image]="logo ? 'url(' + logo + ')' : ''"
  >
    <ng-container *ngIf="!logo && !icon && (initials | appIsSet)">{{ initials }}</ng-container>
  </span>
  <div *ngIf="badgeColor" class="project-logo__badge" [ngClass]="['background-color_' + badgeColor + '_1']"></div>
</div>

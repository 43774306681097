import { addClass, removeClass } from '@shared';
import * as i0 from "@angular/core";
export var DISABLE_WINDOW_BACK_SCROLL_CLASS = 'body_disable-window-back-scroll';
var ScrollService = /** @class */ (function () {
    function ScrollService() {
    }
    ScrollService.prototype.disableScroll = function (scrollable, container) {
        if (container.style.overflow == 'hidden') {
            return;
        }
        var scrollTop = scrollable.scrollTop;
        container.style.overflow = 'hidden';
        scrollable.scrollTop = scrollTop;
    };
    ScrollService.prototype.enableScroll = function (scrollable, container) {
        if (container.style.overflow != 'hidden') {
            return;
        }
        var scrollTop = scrollable.scrollTop;
        container.style.overflow = null;
        scrollable.scrollTop = scrollTop;
    };
    ScrollService.prototype.disableWindowScroll = function () {
        this.disableScroll(document.documentElement, document.body);
    };
    ScrollService.prototype.enableWindowScroll = function () {
        this.enableScroll(document.documentElement, document.body);
    };
    ScrollService.prototype.disableWindowBackScroll = function () {
        this.disableScroll(document.documentElement, document.body);
    };
    ScrollService.prototype.setWindowBackScrollEnabled = function (enabled) {
        if (enabled) {
            removeClass(document.body, DISABLE_WINDOW_BACK_SCROLL_CLASS);
        }
        else {
            addClass(document.body, DISABLE_WINDOW_BACK_SCROLL_CLASS);
        }
    };
    ScrollService.ngInjectableDef = i0.defineInjectable({ factory: function ScrollService_Factory() { return new ScrollService(); }, token: ScrollService, providedIn: "root" });
    return ScrollService;
}());
export { ScrollService };

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { RichTextOutput } from '@modules/fields';

@Injectable()
export class RichTextFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;
  context: Object;

  outputOptions = [
    { value: RichTextOutput.HTML, name: 'HTML' }
    // { value: RichTextOutput.Markdown, name: 'Markdown' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output: new FormControl(RichTextOutput.HTML),
      format_readonly: new FormControl(false)
    });
  }

  init(control: AbstractControl, context) {
    this.control = control;
    this.context = context;
    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }
    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = this.form.value;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectInvite, ProjectInviteService } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-project-invite-item, [app-project-invite-item]',
  templateUrl: './project-invite-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectInviteItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ProjectInvite;
  @Input() group = true;
  @Output() deleted = new EventEmitter<void>();

  inviteLink: string;
  deleteLoading = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public currentUserStore: CurrentUserStore,
    private projectInviteService: ProjectInviteService,
    private dialogService: DialogService,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.inviteLink = this.getInviteLink();
  }

  getInviteLink(): string {
    const project = this.currentProjectStore.instance;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
    return this.item.link(webBaseUrl);
  }

  copyInviteCode(text: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
      });
  }

  delete(instance: ProjectInvite) {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this invitation?'
      })
      .pipe(
        filter(result => result == true),
        switchMap(() => {
          this.deleteLoading = true;
          this.cd.markForCheck();
          return this.projectInviteService.delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.notificationService.success('Deleted', 'Invitation was successfully deleted');
          this.deleteLoading = false;
          this.cd.markForCheck();
          this.deleted.next();
        },
        () => {
          this.deleteLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}

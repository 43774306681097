import { Injectable } from '@angular/core';

import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ProjectApiService } from '@modules/project-api';
import { CurrentProjectStore, ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Injectable()
export class PublicApiService {
  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _projectApiService: ProjectApiService,
    private _projectsStore: ProjectsStore,
    private _currentProjectStore: CurrentProjectStore,
    private _currentUserStore: CurrentUserStore,
    private _modelDescriptionStore: ModelDescriptionStore,
    private _modelService: ModelService
  ) {
    window['jet_public_api'] = this;
  }

  public get apiService(): ApiService {
    return this._apiService;
  }

  public get authService(): AuthService {
    return this._authService;
  }

  public get projectApiService(): ProjectApiService {
    return this._projectApiService;
  }

  public get projectsStore(): ProjectsStore {
    return this._projectsStore;
  }

  public get currentProjectStore(): CurrentProjectStore {
    return this._currentProjectStore;
  }

  public get currentUserStore(): CurrentUserStore {
    return this._currentUserStore;
  }

  public get modelDescriptionStore(): ModelDescriptionStore {
    return this._modelDescriptionStore;
  }

  public get modelService(): ModelService {
    return this._modelService;
  }
}

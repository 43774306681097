import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-users-item-stub, [app-project-users-item-stub]',
  templateUrl: './project-users-item-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectUsersItemStubComponent implements OnInit {
  @Input() group = true;
  @Input() animating = true;

  constructor() {}

  ngOnInit() {}
}

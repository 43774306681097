import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';

import { localize } from '@common/localize';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  ResourceName,
  resourcesCustom,
  ResourceTypeItem,
  ResourceTypeItemCategory,
  resourceTypeItems
} from '@modules/projects';
import { ResourceFilter } from '@modules/projects-components';
import { CurrentUserStore } from '@modules/users';
import { KeyboardEventKeyCode } from '@shared';

import { TemplateItem } from '../../data/template-item';
import { TemplateItemType } from '../../data/template-item-type';

export enum TemplatePreviewSection {
  Templates = 'templates',
  Resources = 'resources'
}

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePreviewComponent implements OnInit, OnDestroy {
  @Input() templates: TemplateItem[] = [];
  @Input() selectedTemplate: TemplateItem;
  @Input() cancel = false;
  @Input() analyticsSource: string;
  @Output() templateApply = new EventEmitter<TemplateItem>();
  @Output() adminPanelTemplateApply = new EventEmitter<TemplateItem>();

  loading = false;
  section: TemplatePreviewSection = TemplatePreviewSection.Templates;
  sections = TemplatePreviewSection;
  breadcrumbs: TemplatePreviewSection[] = [];
  analyticsEvents = AnalyticsEvent;

  templatesFiltered: TemplateItem[] = [];
  templateItemTypes = TemplateItemType;
  templateSearch = '';
  templateSearchUpdated = new Subject<string>();
  tagsMaximum = 4;

  customResource = resourceTypeItems.find(item => item.name == ResourceName.JetDatabase);
  resources: ResourceTypeItem[];
  resourcesNotFound: ResourceTypeItem[];
  resourcesFiltered: ResourceTypeItem[];
  selectedResource: ResourceTypeItem;
  resourceFilters: { label: string; filter?: ResourceFilter }[] = [
    {
      label: localize('All Integrations')
    },
    {
      label: localize('Databases'),
      filter: { category: ResourceTypeItemCategory.Databases }
    },
    {
      label: localize('APIs'),
      filter: { category: ResourceTypeItemCategory.APIs }
    },
    {
      label: localize('Frameworks'),
      filter: { category: ResourceTypeItemCategory.Frameworks }
    },
    {
      label: localize('Storages'),
      filter: { category: ResourceTypeItemCategory.Storages }
    }
  ];
  resourceFilterIndex = 0;
  resourceFilterCounts: number[] = [];
  resourceSearch: string;
  resourceSearchUpdated = new Subject<string>();

  constructor(
    @Optional() public popupComponent: BasePopupComponent,
    public currentUserStore: CurrentUserStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.templateSearchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateTemplatesFiltered());
    this.updateTemplatesFiltered();

    this.resources =
      this.selectedTemplate && this.selectedTemplate.selectResources
        ? this.selectedTemplate.selectResources
        : resourceTypeItems.filter(item => !item.hidden && !item.protected && item.name != ResourceName.JetDatabase);
    this.resourcesNotFound = resourceTypeItems.filter(item => resourcesCustom.includes(item.name));

    this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateResourcesFiltered());
    this.updateResourcesFiltered();
  }

  ngOnDestroy(): void {}

  updateTemplatesFiltered() {
    this.templatesFiltered = this.templates
      ? this.templates.filter(item => {
          if (!this.templateSearch) {
            return true;
          }

          return item.name.toLowerCase().includes(this.templateSearch.toLowerCase());
        })
      : undefined;
    this.cd.markForCheck();
  }

  updateResourcesFiltered() {
    const filterResources = (resources: ResourceTypeItem[], filter?: ResourceFilter) => {
      return resources.filter(item => {
        if (filter) {
          if (filter.category != undefined) {
            if (!item.categories.includes(filter.category)) {
              return false;
            }
          }
        }

        if (this.resourceSearch) {
          if (item.label.toLowerCase().includes(this.resourceSearch.toLowerCase()) == false) {
            return false;
          }
        }

        return true;
      });
    };

    this.resourceFilterCounts = this.resourceFilters.map(item => filterResources(this.resources, item.filter).length);

    if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
      this.resourceFilterIndex = 0;
    }

    const currentFilter = this.currentResourceFilter;

    this.resourcesFiltered = filterResources(this.resources, currentFilter);
    this.cd.markForCheck();
  }

  clearSearch() {
    this.resourceSearch = '';
    this.resourceSearchUpdated.next();
    this.cd.markForCheck();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }

  get currentResourceFilter(): ResourceFilter {
    if (!this.resourceFilters[this.resourceFilterIndex]) {
      return;
    }
    return this.resourceFilters[this.resourceFilterIndex].filter;
  }

  setSelectedResource(resource: ResourceTypeItem) {
    this.selectedResource = resource;
    this.cd.markForCheck();
  }

  setResourceFilterIndex(i: number) {
    this.resourceFilterIndex = i;
    this.cd.markForCheck();
    this.updateResourcesFiltered();
  }

  navigate(section: TemplatePreviewSection) {
    this.breadcrumbs = [...this.breadcrumbs, this.section];
    this.section = section;
    this.cd.markForCheck();

    if (section == TemplatePreviewSection.Resources) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToChooseResources, {
        TemplateID: this.selectedTemplate.name,
        Source: 'home_templates_preview'
      });
    }
  }

  navigateBack() {
    this.section = this.previousSection;
    this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
    this.cd.markForCheck();
  }

  get previousSection() {
    return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  isVideoUrl(url: string) {
    return ['mp4', 'webm', 'ogg'].some(item => url.toLowerCase().endsWith(`.${item}`));
  }

  applyTemplate(template: TemplateItem) {
    this.close();
    this.templateApply.emit(template);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Apply, {
      TemplateID: template.name,
      ResourceType: template.resource ? template.resource.name : undefined,
      Source: 'home_templates_preview'
    });
  }

  applyAdminPanelTemplate(resource: ResourceTypeItem) {
    if (!resource) {
      return;
    }

    this.applyTemplate({
      ...this.selectedTemplate,
      resource: resource
    });
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

import { Option as GxOption } from 'ng-gxselect';

import { Option } from '../data/option';

export function optionFromGxOption(gxOption: GxOption): Option {
  return {
    value: gxOption.value,
    name: gxOption.name,
    icon: gxOption.image,
    ...(gxOption.data && {
      subtitle: gxOption.data.subtitle,
      color: gxOption.data.color
    })
  };
}

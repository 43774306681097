<app-section [cls]="section">
  <app-page-header>
    <app-page-title>List {{ modelDescription.verboseNamePlural }}</app-page-title>
    <app-page-description>
      Returns a list of {{ modelDescription.verboseNamePlural }}. You can filter, paginate and sort results using the
      parameters below.
    </app-page-description>
  </app-page-header>

  <app-section-column>
    <app-header-parameters [parameters]="headerParameters" [loading]="headerParametersLoading"></app-header-parameters>

    <ng-container *ngIf="parameters.length">
      <app-page-block>
        <app-page-heading>Request parameters</app-page-heading>
      </app-page-block>

      <app-page-block>
        <div class="code-parameters">
          <div *ngFor="let item of parameters" class="code-parameters__item">
            <app-api-parameter [item]="item"></app-api-parameter>
          </div>
        </div>
      </app-page-block>

      <app-page-block [marginSize]="'l'">
        <hr />
      </app-page-block>
    </ng-container>

    <app-page-block>
      <app-page-heading>Returned result</app-page-heading>
      <app-page-description *ngIf="pagination">
        A dictionary with a "results" property that contains an array of up to "_per_page"
        {{ modelDescription.verboseNamePlural }}. Each entry in the array is a separate
        {{ modelDescription.verboseName }} object. If no more {{ modelDescription.verboseNamePlural }} are available,
        the resulting array will be empty. If you provide a non-existent parameter values, this call returns an empty
        array as well.
      </app-page-description>

      <app-page-description *ngIf="!pagination">
        An array of {{ modelDescription.verboseNamePlural }}. Each entry in the array is a separate
        {{ modelDescription.verboseName }} object. If you provide a non-existent parameter values, this call returns an
        empty array as well.
      </app-page-description>
    </app-page-block>
  </app-section-column>

  <app-section-column>
    <div class="side-code side-code_sticky">
      <app-page-block>
        <div class="code-block">
          <div class="code-block-header">
            <div class="code-block-header__main">
              <span class="code-block-header__method color_blue_1">GET</span>
              <span class="code-block-header__url">{{ getUrlPath() }}</span>
            </div>
            <div class="code-block-header__right">
              <div
                class="code-block-header__action icon-duplicate"
                (click)="copy(curl || getAbsoluteApiUrl(), 'CURL')"
              ></div>
            </div>
          </div>
          <div *ngIf="curl" class="code-block-content">
            <div class="code-block__code">{{ curl }}</div>
          </div>
        </div>
      </app-page-block>

      <app-page-block class="side-code__fill">
        <div class="code-block">
          <div class="code-block-header">
            <div class="code-block-header__main">
              <span class="code-block-header__title">Response</span>
            </div>
          </div>
          <div class="code-block-content">
            <div class="code-block__code">{{ getResponseData() }}</div>
          </div>
        </div>
      </app-page-block>
    </div>
  </app-section-column>
</app-section>

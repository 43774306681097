<app-popup2 [size]="'l'" [maxWidth]="1150">
  <div class="choose-template choose-template_flexible" [class.choose-template_popup]="false">
    <div *ngIf="!chooseModel" class="choose-template__header">
      <div class="choose-template__header-main">
        <div *ngIf="title" class="choose-template__header-title">{{ title }}</div>
        <div *ngIf="!title" class="choose-template__header-title">Choose Layout</div>
        <div class="choose-template__header-subtitle">
          Start with one of our page templates or create page from scratch
        </div>
      </div>

      <a
        *ngIf="cancelEnabled"
        href="javascript:void(0)"
        class="choose-template__header-close icon-close"
        (click)="cancelClick.emit()"
      ></a>
    </div>

    <div *ngIf="chooseModel" class="choose-template__header">
      <div class="choose-template__detail-title">
        <a href="javascript:void(0)" (click)="setChooseModel(false)">Page Layout</a>
        <span class="choose-template__detail-title-separator icon-arrow_forward_2"></span>
        Choose Collection
      </div>

      <a
        *ngIf="cancelEnabled"
        href="javascript:void(0)"
        class="choose-template__header-close icon-close"
        (click)="cancelClick.emit()"
      ></a>
    </div>

    <div class="choose-template__content">
      <div class="choose-template__content-navigation choose-template__content-navigation_pages-preview">
        <div class="choose-template__content-navigation-inner">
          <app-page-templates-choose-item-type
            *ngIf="!chooseModel"
            [newPage]="newPage"
            (selectTemplate)="onSelectedTemplate($event)"
            (cancelClick)="cancelClick.emit()"
          ></app-page-templates-choose-item-type>

          <app-page-templates-choose-item-model
            *ngIf="chooseModel"
            [template]="selectedTemplate"
            [templateVariant]="selectedTemplateVariant"
            [resource]="resource"
            [analyticsSource]="analyticsSource"
            (selectModel)="setSelectedModel($event); submitModel()"
            (backClick)="setChooseModel(false)"
            (cancelClick)="cancelClick.emit()"
          ></app-page-templates-choose-item-model>
        </div>
      </div>

      <div
        class="choose-template__content-main"
        [class.choose-template__content-main_background-dark]="true"
        [class.choose-template__content-main_clip]="true"
        [class.choose-template__content-main_relative]="true"
      >
        <app-page-template-preview-stub *ngIf="previewLoading"></app-page-template-preview-stub>

        <ng-container *ngIf="!previewLoading">
          <app-page-template-preview-custom *ngIf="!startPage"></app-page-template-preview-custom>
          <app-page-template-preview
            *ngIf="startPage"
            [startPage]="startPage"
            [otherPages]="otherPages"
          ></app-page-template-preview>
        </ng-container>
      </div>
    </div>

    <div class="choose-template__footer">
      <app-page-block>
        <app-stack *ngIf="!chooseModel" [align]="'right'">
          <button
            type="button"
            class="button button_primary button_orange popup2__footer-item"
            [class.button_disabled]="!selectedTemplate"
            (click)="submitTemplate()"
          >
            <span class="button__label">Continue</span>
          </button>
        </app-stack>

        <app-stack *ngIf="chooseModel" [align]="'right'">
          <button type="button" class="button button_orange popup2__footer-item" (click)="setChooseModel(false)">
            <span class="button__label">Back</span>
          </button>

          <button
            type="button"
            class="button button_primary button_orange popup2__footer-item"
            [class.button_disabled]="true || !selectedModel"
            (click)="submitModel()"
          >
            <span class="button__label">Continue</span>
          </button>
        </app-stack>
      </app-page-block>
    </div>
  </div>
</app-popup2>

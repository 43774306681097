import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import startCase from 'lodash/startCase';
import * as moment from 'moment';
import { slugify } from 'transliteration';
import { hasEnvironmentModelPermission, hasEnvironmentPagePermission, hasEnvironmentPermission } from '@modules/projects';
import { format as formatFn, generateHash, generateUUID, isSet, parseTime } from '@shared';
import { NumberValueFormat } from '../data/value-format.interface';
import { registerMathCustomFunction } from './math-custom-functions.const';
import { applyStaticValueFormat } from './value-format';
function CONCAT() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.reduce(function (acc, item) {
        if (item === null || item === undefined) {
            item = '';
        }
        return "" + acc + String(item);
    }, '');
}
CONCAT.toString = function () { return 'CONCAT'; };
function FORMAT(template) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return formatFn(template, args || []);
}
FORMAT.toString = function () { return 'FORMAT'; };
function POW(base, exponent) {
    return Math.pow(base, exponent);
}
POW.toString = function () { return 'POW'; };
function ROUND(value, places) {
    if (places === void 0) { places = 0; }
    return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
}
ROUND.toString = function () { return 'ROUND'; };
function CEIL(base, factor) {
    if (factor === void 0) { factor = 1; }
    return Math.ceil(base / factor) * factor;
}
CEIL.toString = function () { return 'CEIL'; };
function FLOOR(base, factor) {
    if (factor === void 0) { factor = 1; }
    return Math.floor(base / factor) * factor;
}
FLOOR.toString = function () { return 'FLOOR'; };
export function LOG(value, base) {
    if (base === void 0) { base = 10; }
    return Math.log(value) / Math.log(base);
}
LOG.toString = function () { return 'LOG'; };
function EXP(exponent) {
    return Math.exp(exponent);
}
EXP.toString = function () { return 'EXP'; };
function ABS(value) {
    return Math.abs(value);
}
ABS.toString = function () { return 'ABS'; };
function SQRT(value) {
    return Math.sqrt(value);
}
SQRT.toString = function () { return 'SQRT'; };
function FIX(number, places) {
    if (places === void 0) { places = 0; }
    return ROUND(number, places).toFixed(places);
}
FIX.toString = function () { return 'FIX'; };
function IF(logical_expression, value_if_true, value_if_false) {
    return logical_expression ? value_if_true : value_if_false;
}
IF.toString = function () { return 'IF'; };
function EQ(value1, value2) {
    return value1 == value2;
}
EQ.toString = function () { return 'EQ'; };
function AND(logical_expression1, logical_expression2) {
    return !!logical_expression1 && !!logical_expression2;
}
AND.toString = function () { return 'AND'; };
function NOT(logical_expression) {
    return !logical_expression;
}
NOT.toString = function () { return 'NOT'; };
function OR(logical_expression1, logical_expression2) {
    return !!logical_expression1 || !!logical_expression2;
}
OR.toString = function () { return 'OR'; };
function XOR(logical_expression1, logical_expression2) {
    return (!!logical_expression1 && !logical_expression2) || (!logical_expression1 && !!logical_expression2);
}
XOR.toString = function () { return 'XOR'; };
function SIZE(value) {
    if (isArray(value)) {
        return value.length;
    }
    else if (typeof value === 'string') {
        return value.length;
    }
    else {
        return value.toArray().length;
    }
}
SIZE.toString = function () { return 'SIZE'; };
function EMPTY_FUNC(value) {
    if (isArray(value)) {
        return value.length === 0;
    }
    else if (typeof value === 'string') {
        return value.length === 0;
    }
    else {
        return value.toArray().length === 0;
    }
}
EMPTY_FUNC.toString = function () { return 'EMPTY'; };
function IS_NULL(value) {
    return value === null;
}
IS_NULL.toString = function () { return 'IS_NULL'; };
function SORT(value) {
    return value.toArray().sort();
}
SORT.toString = function () { return 'SORT'; };
function MAP(value, callback) {
    var valueArray = isArray(value) ? value : value.toArray();
    if (callback['signatures']) {
        callback = callback['signatures']['any'];
    }
    return valueArray.map(callback);
}
MAP.toString = function () { return 'MAP'; };
function FILTER(value, callback) {
    var valueArray = isArray(value) ? value : value.toArray();
    if (callback['signatures']) {
        callback = callback['signatures']['any'];
    }
    return valueArray.filter(callback);
}
FILTER.toString = function () { return 'FILTER'; };
export function UUID() {
    return generateUUID();
}
UUID.toString = function () { return 'UUID'; };
export function HASH(length) {
    if (length === void 0) { length = 32; }
    return generateHash(length);
}
HASH.toString = function () { return 'HASH'; };
export function RANDOM(low, high) {
    return Math.floor(Math.random() * (high - low + 1)) + low;
}
RANDOM.toString = function () { return 'RANDOM'; };
export function MIN() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return Math.min.apply(Math, values);
}
MIN.toString = function () { return 'MIN'; };
export function MAX() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return Math.max.apply(Math, values);
}
MAX.toString = function () { return 'MAX'; };
export function AVERAGE() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return SUM.apply(void 0, values) / SIZE(values);
}
AVERAGE.toString = function () { return 'AVERAGE'; };
export function SUM() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return values.reduce(function (a, b) {
        a = NUMBER(a);
        b = NUMBER(b);
        if (isNaN(a)) {
            a = 0;
        }
        if (isNaN(b)) {
            b = 0;
        }
        return a + b;
    }, 0);
}
SUM.toString = function () { return 'SUM'; };
function STRING(value) {
    return String(value);
}
STRING.toString = function () { return 'STRING'; };
function NUMBER(value) {
    return parseFloat(value);
}
NUMBER.toString = function () { return 'NUMBER'; };
function NUMBER_FORMAT(value, numberFormat, numberFraction) {
    if (!isSet(value)) {
        return value;
    }
    if (typeof numberFormat === 'string') {
        numberFormat = numberFormat.toLowerCase();
    }
    return applyStaticValueFormat(value, {
        numberFormat: numberFormat || NumberValueFormat.Default,
        numberFraction: numberFraction
    });
}
NUMBER_FORMAT.toString = function () { return 'NUMBER_FORMAT'; };
function parseJSON(value) {
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return {};
    }
}
parseJSON.toString = function () { return 'JSON'; };
function UPPER(value) {
    if (!isSet(value)) {
        return value;
    }
    return String(value).toUpperCase();
}
UPPER.toString = function () { return 'UPPER'; };
function LOWER(value) {
    if (!isSet(value)) {
        return value;
    }
    return String(value).toLowerCase();
}
LOWER.toString = function () { return 'LOWER'; };
function TITLE_CASE(value) {
    if (!isSet(value)) {
        return value;
    }
    return startCase(value);
}
TITLE_CASE.toString = function () { return 'TITLE_CASE'; };
function SLUGIFY(value, separator) {
    if (separator === void 0) { separator = '_'; }
    var result = slugify(String(value), { trim: true, separator: separator });
    if (['-', '_'].includes(separator)) {
        var regex = new RegExp(separator + "+", 'g');
        result = result.replace(regex, separator);
    }
    return result;
}
SLUGIFY.toString = function () { return 'SLUGIFY'; };
function GET(object, property, defaultValue) {
    return isSet(object) ? object[property] || defaultValue : defaultValue;
}
GET.toString = function () { return 'GET'; };
function CONTAINS(object, value, caseInsensitive) {
    if (caseInsensitive === void 0) { caseInsensitive = false; }
    if (!object) {
        return false;
    }
    if (isArray(object)) {
        return (object.find(function (item) {
            if (typeof item === 'string' && caseInsensitive) {
                return item.toLowerCase() == String(value).toLowerCase();
            }
            else {
                return item == value;
            }
        }) !== undefined);
    }
    else if (typeof object === 'string') {
        value = String(value);
        if (caseInsensitive) {
            object = object.toLowerCase();
            value = value.toLowerCase();
        }
        return object.indexOf(value) !== -1;
    }
    else {
        if (caseInsensitive) {
            return (keys(object).find(function (item) {
                if (typeof item === 'string') {
                    return item.toLowerCase() == String(value).toLowerCase();
                }
                else {
                    return item == value;
                }
            }) !== undefined);
        }
        else {
            return object[value] !== undefined;
        }
    }
}
CONTAINS.toString = function () { return 'CONTAINS'; };
export function ANY() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    return values.find(function (item) { return isSet(item); });
}
ANY.toString = function () { return 'ANY'; };
function DATE(value, format) {
    if (!value) {
        return;
    }
    if (moment.isMoment(value)) {
        return value;
    }
    var date = moment(value, format);
    if (!date.isValid()) {
        return;
    }
    return date;
}
DATE.toString = function () { return 'DATE'; };
function wrapTime(value) {
    value.toString = function () {
        if (value.isValid()) {
            return value.format('HH:mm:ss');
        }
        else {
            return 'Invalid time';
        }
    };
    return value;
}
function TIME(value, format) {
    if (!value) {
        return;
    }
    var dt = parseTime(value, format);
    return wrapTime(dt);
}
TIME.toString = function () { return 'TIME'; };
export function NOW() {
    return moment();
}
NOW.toString = function () { return 'NOW'; };
function DAY(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.date();
}
DAY.toString = function () { return 'DAY'; };
function WEEKDAY(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.isoWeekday();
}
WEEKDAY.toString = function () { return 'WEEKDAY'; };
function MONTH(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.month() + 1;
}
MONTH.toString = function () { return 'MONTH'; };
function QUARTER(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.quarter();
}
QUARTER.toString = function () { return 'QUARTER'; };
function YEAR(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.year();
}
YEAR.toString = function () { return 'YEAR'; };
function HOUR(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.hour();
}
HOUR.toString = function () { return 'HOUR'; };
function MINUTE(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.minute();
}
MINUTE.toString = function () { return 'MINUTE'; };
function SECOND(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.second();
}
SECOND.toString = function () { return 'SECOND'; };
function MILLISECOND(value) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.millisecond();
}
MILLISECOND.toString = function () { return 'MILLISECOND'; };
function IS_DATE_AFTER(value1, value2, granularity) {
    value1 = DATE(value1);
    value2 = DATE(value2);
    if (!value1) {
        return;
    }
    else if (!value2) {
        return;
    }
    return value1.isAfter(value2, granularity);
}
IS_DATE_AFTER.toString = function () { return 'IS_DATE_AFTER'; };
function IS_DATE_BEFORE(value1, value2, granularity) {
    value1 = DATE(value1);
    value2 = DATE(value2);
    if (!value1) {
        return;
    }
    else if (!value2) {
        return;
    }
    return value1.isBefore(value2, granularity);
}
IS_DATE_BEFORE.toString = function () { return 'IS_DATE_BEFORE'; };
function IS_DATE_SAME(value1, value2, granularity) {
    value1 = DATE(value1);
    value2 = DATE(value2);
    if (!value1) {
        return;
    }
    else if (!value2) {
        return;
    }
    return value1.isSame(value2, granularity);
}
IS_DATE_SAME.toString = function () { return 'IS_DATE_SAME'; };
function DATE_FORMAT(value, format) {
    value = DATE(value);
    if (!value) {
        return;
    }
    return value.format(format);
}
DATE_FORMAT.toString = function () { return 'DATE_FORMAT'; };
function DATE_ADD(value, amount, unit) {
    value = DATE(value);
    if (!value || !isSet(amount) || !isSet(unit)) {
        return;
    }
    return value.clone().add(amount, unit);
}
DATE_ADD.toString = function () { return 'DATE_ADD'; };
function DATE_SUBTRACT(value, amount, unit) {
    value = DATE(value);
    if (!value || !isSet(amount) || !isSet(unit)) {
        return;
    }
    return value.clone().subtract(amount, unit);
}
DATE_SUBTRACT.toString = function () { return 'DATE_SUBTRACT'; };
function DATE_DIFF(value1, value2, unit, decimal) {
    if (decimal === void 0) { decimal = false; }
    value1 = DATE(value1);
    value2 = DATE(value2);
    if (!value1 || !value2) {
        return;
    }
    return value2.diff(value1, unit, decimal);
}
DATE_DIFF.toString = function () { return 'DATE_DIFF'; };
function DATE_START_OF(value, unit) {
    value = DATE(value);
    if (!value || !isSet(unit)) {
        return;
    }
    return value.clone().startOf(unit);
}
DATE_START_OF.toString = function () { return 'DATE_START_OF'; };
function DATE_END_OF(value, unit) {
    value = DATE(value);
    if (!value || !isSet(unit)) {
        return;
    }
    return value.clone().endOf(unit);
}
DATE_END_OF.toString = function () { return 'DATE_END_OF'; };
function IS_TIME_AFTER(value1, value2, granularity) {
    value1 = TIME(value1);
    value2 = TIME(value2);
    return IS_DATE_AFTER(value1, value2, granularity);
}
IS_TIME_AFTER.toString = function () { return 'IS_TIME_AFTER'; };
function IS_TIME_BEFORE(value1, value2, granularity) {
    value1 = TIME(value1);
    value2 = TIME(value2);
    return IS_DATE_BEFORE(value1, value2, granularity);
}
IS_TIME_BEFORE.toString = function () { return 'IS_TIME_BEFORE'; };
function IS_TIME_SAME(value1, value2, granularity) {
    value1 = TIME(value1);
    value2 = TIME(value2);
    return IS_DATE_SAME(value1, value2, granularity);
}
IS_TIME_SAME.toString = function () { return 'IS_TIME_SAME'; };
function TIME_FORMAT(value, format) {
    value = TIME(value);
    return DATE_FORMAT(value, format);
}
TIME_FORMAT.toString = function () { return 'TIME_FORMAT'; };
function TIME_ADD(value, amount, unit) {
    value = TIME(value);
    return DATE_ADD(value, amount, unit);
}
TIME_ADD.toString = function () { return 'TIME_ADD'; };
function TIME_SUBTRACT(value, amount, unit) {
    value = TIME(value);
    return DATE_SUBTRACT(value, amount, unit);
}
TIME_SUBTRACT.toString = function () { return 'TIME_SUBTRACT'; };
function TIME_DIFF(value1, value2, unit, decimal) {
    if (decimal === void 0) { decimal = false; }
    value1 = TIME(value1);
    value2 = TIME(value2);
    return DATE_DIFF(value1, value2, unit, decimal);
}
TIME_DIFF.toString = function () { return 'TIME_DIFF'; };
function TIME_START_OF(value, unit) {
    value = TIME(value);
    return DATE_START_OF(value, unit);
}
TIME_START_OF.toString = function () { return 'TIME_START_OF'; };
function TIME_END_OF(value, unit) {
    value = TIME(value);
    return DATE_END_OF(value, unit);
}
TIME_END_OF.toString = function () { return 'TIME_END_OF'; };
export function HAS_PERMISSION(jet, permission) {
    if (!jet.env) {
        return false;
    }
    return hasEnvironmentPermission(jet.env, permission);
}
HAS_PERMISSION.toString = function () { return 'HAS_PERMISSION'; };
export function HAS_COLLECTION_PERMISSION(jet, modelId, action) {
    if (!jet.env) {
        return false;
    }
    return hasEnvironmentModelPermission(jet.env, modelId, action);
}
HAS_COLLECTION_PERMISSION.toString = function () { return 'HAS_COLLECTION_PERMISSION'; };
export function HAS_PAGE_PERMISSION(jet, action, pageUid) {
    if (!jet.env) {
        return false;
    }
    var defaultPageUid = isSet(jet.page) ? jet.page.uid : undefined;
    var usePageUid = isSet(pageUid) ? pageUid : defaultPageUid;
    return hasEnvironmentPagePermission(jet.env, usePageUid, action);
}
HAS_PAGE_PERMISSION.toString = function () { return 'HAS_PAGE_PERMISSION'; };
registerMathCustomFunction('DATE', DATE);
registerMathCustomFunction('TIME', TIME);
registerMathCustomFunction('NOW', NOW);
registerMathCustomFunction('DAY', DAY);
registerMathCustomFunction('WEEKDAY', WEEKDAY);
registerMathCustomFunction('MONTH', MONTH);
registerMathCustomFunction('YEAR', YEAR);
registerMathCustomFunction('HOUR', HOUR);
registerMathCustomFunction('MINUTE', MINUTE);
registerMathCustomFunction('SECOND', SECOND);
registerMathCustomFunction('MILLISECOND', MILLISECOND);
registerMathCustomFunction('IS_DATE_AFTER', IS_DATE_AFTER);
registerMathCustomFunction('IS_DATE_BEFORE', IS_DATE_BEFORE);
registerMathCustomFunction('IS_DATE_SAME', IS_DATE_SAME);
registerMathCustomFunction('DATE_FORMAT', DATE_FORMAT);
registerMathCustomFunction('DATE_ADD', DATE_ADD);
registerMathCustomFunction('DATE_SUBTRACT', DATE_SUBTRACT);
registerMathCustomFunction('DATE_DIFF', DATE_DIFF);
registerMathCustomFunction('DATE_START_OF', DATE_START_OF);
registerMathCustomFunction('DATE_END_OF', DATE_END_OF);
registerMathCustomFunction('IS_TIME_AFTER', IS_TIME_AFTER);
registerMathCustomFunction('IS_TIME_BEFORE', IS_TIME_BEFORE);
registerMathCustomFunction('IS_TIME_SAME', IS_TIME_SAME);
registerMathCustomFunction('TIME_FORMAT', TIME_FORMAT);
registerMathCustomFunction('TIME_ADD', TIME_ADD);
registerMathCustomFunction('TIME_SUBTRACT', TIME_SUBTRACT);
registerMathCustomFunction('TIME_DIFF', TIME_DIFF);
registerMathCustomFunction('TIME_START_OF', TIME_START_OF);
registerMathCustomFunction('TIME_END_OF', TIME_END_OF);
registerMathCustomFunction('CONCAT', CONCAT);
registerMathCustomFunction('FORMAT', FORMAT);
registerMathCustomFunction('POW', POW);
registerMathCustomFunction('ROUND', ROUND);
registerMathCustomFunction('CEIL', CEIL);
registerMathCustomFunction('FLOOR', FLOOR);
registerMathCustomFunction('LOG', LOG);
registerMathCustomFunction('EXP', EXP);
registerMathCustomFunction('ABS', ABS);
registerMathCustomFunction('SQRT', SQRT);
registerMathCustomFunction('FIX', FIX);
registerMathCustomFunction('IF', IF);
registerMathCustomFunction('EQ', EQ);
registerMathCustomFunction('AND', AND);
registerMathCustomFunction('NOT', NOT);
registerMathCustomFunction('OR', OR);
registerMathCustomFunction('XOR', XOR);
registerMathCustomFunction('SIZE', SIZE);
registerMathCustomFunction('EMPTY', EMPTY_FUNC);
registerMathCustomFunction('IS_NULL', IS_NULL);
registerMathCustomFunction('SORT', SORT);
registerMathCustomFunction('MAP', MAP);
registerMathCustomFunction('FILTER', FILTER);
registerMathCustomFunction('UUID', UUID);
registerMathCustomFunction('HASH', HASH);
registerMathCustomFunction('RANDOM', RANDOM);
registerMathCustomFunction('MIN', MIN);
registerMathCustomFunction('MAX', MAX);
registerMathCustomFunction('AVERAGE', AVERAGE);
registerMathCustomFunction('SUM', SUM);
registerMathCustomFunction('STRING', STRING);
registerMathCustomFunction('NUMBER', NUMBER);
registerMathCustomFunction('NUMBER_FORMAT', NUMBER_FORMAT);
registerMathCustomFunction('JSON', parseJSON);
registerMathCustomFunction('UPPER', UPPER);
registerMathCustomFunction('LOWER', LOWER);
registerMathCustomFunction('TITLE_CASE', TITLE_CASE);
registerMathCustomFunction('SLUGIFY', SLUGIFY);
registerMathCustomFunction('GET', GET);
registerMathCustomFunction('CONTAINS', CONTAINS);
registerMathCustomFunction('ANY', ANY);
registerMathCustomFunction('HAS_PERMISSION', HAS_PERMISSION);
registerMathCustomFunction('HAS_COLLECTION_PERMISSION', HAS_COLLECTION_PERMISSION);
registerMathCustomFunction('HAS_PAGE_PERMISSION', HAS_PAGE_PERMISSION);

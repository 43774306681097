import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { InstanceStore } from '@shared';

import { SlackService } from '../services/slack/slack.service';

@Injectable()
export class SlackChannelStore extends InstanceStore<SlackService.SlackChannelResponse> implements OnDestroy {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private slackService: SlackService
  ) {
    super();
  }

  ngOnDestroy(): void {}

  isEnabled() {
    return this.currentProjectStore.instance.features.isSlackChannelEnabled();
  }

  fetchData(): Observable<SlackService.SlackChannelResponse> {
    if (!this.isEnabled()) {
      return of(undefined);
    }

    return this.slackService.getSlackChannel(
      this.currentProjectStore.uniqueName,
      this.currentEnvironmentStore.uniqueName
    );
  }
}

<div
  class="editor-overlay"
  appResizable
  (resizeStarted)="onResizeStarted($event)"
  (resize)="onResize($event)"
  #root_element
>
  <div class="editor-overlay__header" #header_element>
    <div class="editor-overlay__header-left">
      <div class="editor-overlay__header-drag icon-dots_small"></div>
    </div>
    <div class="editor-overlay__header-main"></div>
    <div class="editor-overlay__header-right">
      <!--      <div class="editor-overlay__header-close icon-close" (click)="popupRef.close()"></div>-->
      <div class="editor-overlay__header-button" (click)="popupRef.close()">
        <span class="editor-overlay__header-button-icon icon-save"></span>
        <span class="editor-overlay__header-button-label">Done</span>
      </div>
    </div>
  </div>

  <div class="editor-overlay__content">
    <app-input-edit
      [itemForm]="itemForm"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueField]="staticValueField"
      [staticValueParams]="staticValueParams"
      [staticValueDisabled]="staticValueDisabled"
      [filterFields]="filterFields"
      [userInput]="userInput"
      [focusedInitial]="false"
      [placeholder]="placeholder"
      [formulaPlaceholder]="formulaPlaceholder"
      [jsPlaceholder]="jsPlaceholder"
      [displayValueTypesEnabled]="displayValueTypesEnabled"
      [displayValueTypes]="displayValueTypes"
      [overlay]="true"
      [builtInItemsEnabled]="false"
      [classes]="['input_segment-bottom']"
      [fill]="true"
      [fillVertical]="true"
      [analyticsSource]="analyticsSource"
    ></app-input-edit>
  </div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_top"
    [appResizableHandle]="[resizeTypes.Vertical]"
    [appResizableHandleSpeedVertical]="2"
    [appResizableHandleReverseVertical]="true"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_right"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    (mousedown)="$event.stopPropagation()"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleCheckHorizontalBounds]="false"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_bottom"
    [appResizableHandle]="[resizeTypes.Vertical]"
    [appResizableHandleSpeedVertical]="2"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_left"
    [appResizableHandle]="[resizeTypes.Horizontal]"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleReverseHorizontal]="true"
    [appResizableHandleCheckHorizontalBounds]="false"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_top-left"
    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleSpeedVertical]="2"
    [appResizableHandleReverseVertical]="true"
    [appResizableHandleReverseHorizontal]="true"
    [appResizableHandleCheckHorizontalBounds]="false"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_top-right"
    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleSpeedVertical]="2"
    [appResizableHandleReverseVertical]="true"
    [appResizableHandleCheckHorizontalBounds]="false"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_bottom-right"
    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleSpeedVertical]="2"
    [appResizableHandleCheckHorizontalBounds]="false"
    (mousedown)="$event.stopPropagation()"
  ></div>

  <div
    class="editor-overlay__bounds editor-overlay__bounds_position_bottom-left"
    [appResizableHandle]="[resizeTypes.Horizontal, resizeTypes.Vertical]"
    [appResizableHandleSpeedHorizontal]="2"
    [appResizableHandleSpeedVertical]="2"
    [appResizableHandleCheckHorizontalBounds]="false"
    [appResizableHandleReverseHorizontal]="true"
    (mousedown)="$event.stopPropagation()"
  ></div>
</div>

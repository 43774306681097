import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription, timer } from 'rxjs';
import { debounce, debounceTime, filter, switchMap } from 'rxjs/operators';

import { getColorHex, isLightColor } from '@modules/colors';
import { applyParamInput$ } from '@modules/fields';
import {
  ImageMenuItem,
  ImageMenuItemOption,
  MenuGeneratorService,
  MenuItem,
  MenuItemActionService
} from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { deployUrl, isSet, TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
import { dropdownPositionsHorizontal, dropdownPositionsVertical } from '../menu-item/menu-item.component';

@Component({
  selector: 'app-image-menu-item',
  templateUrl: './image-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageMenuItemComponent extends BaseMenuItemComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() menuItem: ImageMenuItem;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  link: any[];
  href: string;
  queryParams: Object;
  handler: () => Observable<any>;
  imageUrl: string;
  imageInitials: string;
  imageSubscription: Subscription;
  imageColorLight = false;
  imageStyle: SafeStyle;
  padding = 5;
  textSize = 22;
  title: string;
  subtitle: string;
  children: MenuItem[] = [];
  menuItem$ = new BehaviorSubject<ImageMenuItem>(undefined);
  menuItemHovered$ = new BehaviorSubject<CdkOverlayOrigin>(undefined);
  menuDropdownHovered$ = new BehaviorSubject<boolean>(false);
  menuChildDropdownOpened$ = new BehaviorSubject<boolean>(false);
  dropdownOpened = false;
  origin: CdkOverlayOrigin;
  popoverPositions: ConnectedPosition[] = [];
  popoverPositionsSubscription: Subscription;
  menuDropdownSubscription: Subscription[] = [];

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentUserStore: CurrentUserStore,
    private menuGeneratorService: MenuGeneratorService,
    private menuItemActionService: MenuItemActionService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.menuItem$.next(this.menuItem);
    this.updateChildren();
    this.updateImage();
    this.imageColorLight = isSet(this.menuItem.imageColor) ? isLightColor(this.menuItem.imageColor) : undefined;
    this.imageStyle = this.getStyleVars(this.menuItem.imageColor);
    this.updateTextSize();
    this.updatePadding();
    this.updateLink();
    this.initMenuDropdown();

    this.menuItem$
      .pipe(
        switchMap(menuItem => {
          if (!menuItem.titleInput || !menuItem.titleInput.isSet()) {
            return of(undefined);
          }

          return applyParamInput$(menuItem.titleInput, {
            context: this.context,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });

    this.menuItem$
      .pipe(
        switchMap(menuItem => {
          if (!menuItem.subtitleInput || !menuItem.subtitleInput.isSet()) {
            return of(undefined);
          }

          return applyParamInput$(menuItem.subtitleInput, {
            context: this.context,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.subtitle = value;
        this.cd.markForCheck();
      });

    this.popoverPositions = this.childrenVertical ? dropdownPositionsVertical : dropdownPositionsHorizontal;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ImageMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.menuItem$.next(this.menuItem);
      this.updateChildren();
      this.updateImage();
      this.imageColorLight = isLightColor(this.menuItem.imageColor);
      this.imageStyle = this.getStyleVars(this.menuItem.imageColor);
      this.updateTextSize();
      this.updatePadding();
      this.updateLink();
      this.initMenuDropdown();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  updateChildren() {
    this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
    this.cd.markForCheck();
  }

  updateImage() {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
      this.imageSubscription = undefined;
    }

    this.imageSubscription = combineLatest(this.currentProjectStore.get(), this.currentUserStore.get())
      .pipe(
        switchMap<any, { url?: string; initials?: string }>(([project, user]) => {
          const imageOptions = [
            {
              value: ImageMenuItemOption.UserPhoto,
              name: 'Current user photo',
              icon: 'human_being',
              data: {
                url: user && isSet(user.photo) ? user.photo : deployUrl('/assets/images/no-photo.svg')
              }
            },
            {
              value: ImageMenuItemOption.ProjectLogo,
              name: 'App logo',
              icon: 'image',
              data: {
                url: project ? project.logo : undefined,
                initials: project ? project.initials : undefined
              }
            }
          ];

          if (isSet(this.menuItem.imageCustom)) {
            return of({
              url: this.menuItem.imageCustom
            });
          } else if (isSet(this.menuItem.imageOption)) {
            const logoOption = imageOptions.find(item => item.value == this.menuItem.imageOption);

            if (logoOption) {
              return of({
                url: logoOption.data.url,
                initials: logoOption.data.initials
              });
            }
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.imageUrl = value ? value.url : undefined;
        this.imageInitials = value ? value.initials : undefined;
        this.cd.markForCheck();
      });
  }

  getDarkColor(color: string): string {
    if (!isSet(color)) {
      color = 'white';
    }

    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.6).string();
    } catch (e) {}
  }

  getStyleVars(color: string): SafeStyle {
    const darkColor = this.getDarkColor(color);

    const vars = {};

    if (darkColor) {
      vars['dark-color'] = darkColor;
    }

    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }

  updateTextSize() {
    const imageMinSize = 16;
    const imageMaxSize = 50;
    const imageDefaultSize = 32;
    const imageSize = isSet(this.menuItem.imageSize)
      ? clamp(this.menuItem.imageSize, imageMinSize, imageMaxSize)
      : imageDefaultSize;
    const minValue = 10;
    const maxValue = 28;
    const multiplier = (imageSize - imageMinSize) / (imageMaxSize - imageMinSize);

    this.textSize = minValue + (maxValue - minValue) * multiplier;
    this.cd.markForCheck();
  }

  updatePadding() {
    const imageMinSize = 16;
    const imageMaxSize = 50;
    const imageDefaultSize = 32;
    const imageSize = isSet(this.menuItem.imageSize)
      ? clamp(this.menuItem.imageSize, imageMinSize, imageMaxSize)
      : imageDefaultSize;
    const minValue = 3;
    const maxValue = 8;
    const multiplier = (imageSize - imageMinSize) / (imageMaxSize - imageMinSize);

    this.padding = Math.round(minValue + (maxValue - minValue) * multiplier);
    this.cd.markForCheck();
  }

  updateLink() {
    this.menuItemActionService
      .getActionExecution(this.menuItem.action, { context: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.link = result.link;
        this.href = result.href;
        this.queryParams = result.queryParams;
        this.handler = result.handler;
        this.cd.markForCheck();
      });
  }

  isIconImage(): boolean {
    return (this.primary && !this.horizontal) || (!isSet(this.title) && !isSet(this.subtitle));
  }

  initMenuDropdown() {
    this.menuDropdownSubscription.forEach(item => item.unsubscribe());
    this.menuDropdownSubscription = [];

    if (!this.menuItem.children.length) {
      return;
    }

    this.menuDropdownSubscription.push(
      combineLatest(
        this.menuItemHovered$.pipe(debounce(origin => timer(origin ? 0 : 100))),
        this.menuDropdownHovered$,
        this.menuChildDropdownOpened$
      )
        .pipe(debounceTime(10), untilDestroyed(this))
        .subscribe(([itemOrigin, dropdownHovered, childDropdownOpened]) => {
          if (itemOrigin && !this.dropdownOpened) {
            this.openDropdown(itemOrigin);
          } else if (!itemOrigin && !dropdownHovered && !childDropdownOpened && this.dropdownOpened) {
            this.closeDropdown();
          }
        })
    );

    this.menuDropdownSubscription.push(
      merge(this.router.events.pipe(filter(item => item instanceof ActivationEnd)))
        .pipe(untilDestroyed(this))
        .subscribe(() => this.closeDropdown())
    );
  }

  openDropdown(origin: CdkOverlayOrigin) {
    this.origin = origin;
    this.dropdownOpened = true;
    this.cd.markForCheck();
    this.dropdownOpen.emit();
  }

  closeDropdown() {
    this.dropdownOpened = false;
    this.cd.markForCheck();
    this.dropdownClose.emit();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }

  onClick() {
    if (this.handler) {
      this.handler().pipe(untilDestroyed(this)).subscribe();
    }
  }
}

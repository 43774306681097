import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, from, fromEvent, Observable, of, timer } from 'rxjs';
import { debounce, delayWhen, filter, first, map, switchMap } from 'rxjs/operators';

import { ContrastDialogPopupComponent, ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonPosition, DialogButtonType, DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { ActionStore } from '@modules/action-queries';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { CustomizeHandlerInfo, CustomizeService, CustomizeType, ViewSettingsStore } from '@modules/customize';
import { DraftChangeItem, DraftChangesService, PublishService } from '@modules/customize-utils';
import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { ModelDescriptionStore } from '@modules/model-queries';
import { PER_PAGE_PARAM } from '@modules/models';
import { EnvironmentController } from '@modules/project-settings-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  EnvironmentService,
  Project,
  ProjectUserService,
  Resource
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ShareController, ShareTab } from '@modules/share';
import { SnapshotPopupComponent } from '@modules/snapshots-components';
import { ConfigureResourcesComponent } from '@modules/template-components';
import { ThemeService } from '@modules/theme';
import { IntroService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { isControlElement, isSet, KeyboardEventKeyCode, openUrl } from '@shared';

import { DraftChangesComponent } from '../draft-changes/draft-changes.component';

@Component({
  selector: 'app-customize-toolbar',
  templateUrl: './customize-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeToolbarComponent implements OnInit, OnDestroy {
  @ViewChild('overview_element') overviewElement: ElementRef;

  menuCustomizing = false;
  stubResources: Resource[];
  info: CustomizeHandlerInfo;
  handlerRename = false;
  handlerSettings = false;
  handlerDuplicate = false;
  handlerDuplicateLoading = false;
  handlerHeaderLeftPortal: TemplatePortal<unknown>;
  handlerHeaderCenterPortal: TemplatePortal<unknown>;
  demoUser = false;
  previewHover$ = new BehaviorSubject<boolean>(false);
  savingChanges = false;
  saveError: string;
  saveErrorOpened = false;
  saveErrorHover$ = new BehaviorSubject<boolean>(false);
  project: Project;
  environment: Environment;
  environments: Environment[] = [];
  publishOpened = false;
  draftChanges: DraftChangeItem[] = [];
  draftChangesTotal = 0;
  publishLoading = false;
  published = false;
  snapshotsEnabled = false;
  shareTabs = ShareTab;
  analyticsEvents = AnalyticsEvent;
  source = 'customize_toolbar';

  constructor(
    public customizeService: CustomizeService,
    public currentUserStore: CurrentUserStore,
    public introService: IntroService,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private environmentService: EnvironmentService,
    private environmentController: EnvironmentController,
    private publishService: PublishService,
    private intercomService: IntercomService,
    public themeService: ThemeService,
    private shareController: ShareController,
    private appConfigService: AppConfigService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private routing: RoutingService,
    private popupService: PopupService,
    private featureService: FeatureService,
    private apiService: ApiService,
    private dialogService: DialogService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private projectUserService: ProjectUserService,
    private projectDomainController: ProjectDomainController,
    private draftChangesService: DraftChangesService,
    private vcr: ViewContainerRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.customizeService.handlerInfo$.pipe(untilDestroyed(this)).subscribe(info => {
      this.info = info;
      this.cd.markForCheck();
      this.updateStubResources();
    });

    this.customizeService.handler$.pipe(untilDestroyed(this)).subscribe(handler => {
      this.handlerRename = handler && !!handler.renameHandler;
      this.handlerSettings = handler && !!handler.openHandlerSettings;
      this.handlerDuplicate = handler && !!handler.duplicate;
      this.cd.markForCheck();
    });

    this.customizeService.handlerHeaderLeft$.pipe(untilDestroyed(this)).subscribe(template => {
      this.handlerHeaderLeftPortal = template ? new TemplatePortal(template, this.vcr) : undefined;
      this.cd.markForCheck();
    });

    this.customizeService.handlerHeaderCenter$.pipe(untilDestroyed(this)).subscribe(template => {
      this.handlerHeaderCenterPortal = template ? new TemplatePortal(template, this.vcr) : undefined;
      this.cd.markForCheck();
    });

    this.customizeService.menuEnabled$.pipe(untilDestroyed(this)).subscribe(result => {
      this.menuCustomizing = result;
      this.cd.markForCheck();
    });

    this.currentUserStore.instance$.pipe(untilDestroyed(this)).subscribe(result => {
      this.demoUser = !!this.apiService.getProjectToken();
      this.cd.markForCheck();
    });

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.project = result;
        this.environments = this.project
          ? this.project
              .getEnvironmentsSorted()
              .filter(
                item =>
                  this.project.hasEnvironmentSettingsPermission(item) ||
                  this.project.hasEnvironmentAccessPermission(item)
              )
          : [];
        this.cd.markForCheck();
      });

    this.currentEnvironmentStore.instance$.pipe(untilDestroyed(this)).subscribe(result => {
      this.environment = result;
      this.cd.markForCheck();
    });

    this.draftChangesService
      .getDraftChanges$()
      .pipe(untilDestroyed(this))
      .subscribe(changes => {
        this.draftChanges = changes.filter(item => item.count);
        this.draftChangesTotal = this.draftChanges.reduce((acc, item) => acc + item.count, 0);
        this.cd.markForCheck();
      });

    this.customizeService.changesSaving$.pipe(untilDestroyed(this)).subscribe(value => {
      this.savingChanges = value;
      this.cd.markForCheck();
    });

    this.customizeService.changesSaveError$.pipe(untilDestroyed(this)).subscribe(value => {
      const noSaveErrorBefore = !isSet(this.saveError);

      this.saveError = value;

      if (noSaveErrorBefore && isSet(value)) {
        this.saveErrorOpened = true;
      } else if (!isSet(value)) {
        this.saveErrorOpened = false;
      }

      this.cd.markForCheck();
    });

    combineLatest(
      this.previewHover$.pipe(debounce(value => timer(value ? 0 : 10))),
      this.saveErrorHover$.pipe(debounce(value => timer(value ? 0 : 10)))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([previewHover, saveErrorHover]) => {
        this.saveErrorOpened = previewHover || saveErrorHover;
        this.cd.markForCheck();
      });

    this.customizeService
      .publishRequested$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.publishDraft(true);
      });

    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(() => this.customizeService.handler && !!this.customizeService.handler.setChangesState),
        filter(() => !this.popupService.items.length && !isControlElement(document.activeElement)),
        map(e => {
          if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
            return -1;
          } else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
            return 1;
          }
        }),
        filter(move => isSet(move)),
        switchMap(move => {
          if (move > 0) {
            return this.customizeService.isRedoAvailable$.pipe(
              first(),
              map(allowed => [move, allowed])
            );
          } else {
            return this.customizeService.isUndoAvailable$.pipe(
              first(),
              map(allowed => [move, allowed])
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(([move, allowed]) => {
        if (!allowed) {
          return;
        }

        if (move > 0) {
          this.customizeService.redoChangesHistory();
        } else {
          this.customizeService.undoChangesHistory();
        }
      });

    this.snapshotsEnabled = this.appConfigService.snapshotsEnabled;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  // saveChanges() {
  //   this.saveLoading = true;
  //   this.cd.markForCheck();
  //
  //   this.customizeService
  //     .saveCustomize()
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       result => {
  //         this.saveLoading = false;
  //         this.cd.markForCheck();
  //       },
  //       () => {
  //         this.saveLoading = false;
  //         this.cd.markForCheck();
  //       }
  //     );
  // }

  // cancel() {
  //   this.cancelLoading = true;
  //   this.cd.markForCheck();
  //
  //   this.customizeService
  //     .cancelCustomize()
  //     .pipe(untilDestroyed(this))
  //     .subscribe(
  //       () => {
  //         this.cancelLoading = false;
  //         this.cd.markForCheck();
  //       },
  //       () => {
  //         this.cancelLoading = false;
  //         this.cd.markForCheck();
  //       }
  //     );
  // }

  updateStubResources() {
    if (!this.info || !this.info.page) {
      this.stubResources = [];
      this.cd.markForCheck();
      return;
    }

    this.stubResources = this.info.page.usedResources
      .map(item => this.currentEnvironmentStore.resources.find(resource => resource.uniqueName == item.name))
      .filter(item => item != undefined && item.isStub() && item.isSimpleIntegration());
    this.cd.markForCheck();
  }

  configureStubResources() {
    this.popupService.push({
      component: ConfigureResourcesComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        title: 'Connect your Resources',
        description: `
          The following resource credentials are taken from Jet Admin templates demo data.
          Provide your credentials to use templates with your data.
        `,
        resources: this.stubResources,
        page: this.info ? this.info.page : undefined
      },
      outputs: {
        resourceChanged: [
          () => {
            if (this.customizeService.handler && this.customizeService.handler.reload) {
              this.customizeService.handler.reload().subscribe();
            }
          }
        ]
      },
      injector: this.injector
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ConnectResourcesClicked);
  }

  openChat() {
    this.intercomService.openChat();
  }

  showOverview() {
    this.introService.showOverview(this.overviewElement.nativeElement, true);
  }

  logout() {
    this.authService.logout();
    this.routing.navigate(['/login']);
  }

  share(initialTab?: ShareTab) {
    this.shareController.open({ initialTab: initialTab }).pipe(untilDestroyed(this)).subscribe();
  }

  onUpdateTitle(title) {
    this.customizeService.renameHandler(title);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Renamed, {
      PageID: this.info ? this.info.page : undefined
    });
  }

  duplicate() {
    this.handlerDuplicateLoading = true;
    this.cd.markForCheck();

    this.customizeService
      .duplicateHandler()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.handlerDuplicateLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.handlerDuplicateLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  openSettings() {
    this.customizeService.openHandlerSettings();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.StartedSetUp, {
      PageID: this.info && this.info.page ? this.info.page.uid : undefined
    });
  }

  setPublishOpened(opened: boolean, published = false) {
    this.publishOpened = opened;
    this.published = published;
    this.cd.markForCheck();

    if (this.publishOpened) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenPublishOptions);
    }
  }

  setSaveErrorOpened(opened: boolean) {
    this.saveErrorOpened = opened;
    this.cd.markForCheck();
  }

  preview() {
    const obs =
      !this.customizeService.handlerInfo || !this.customizeService.handlerInfo.page
        ? from(this.routing.navigateApp(this.currentProjectStore.instance.homeLink))
        : of(undefined);

    obs.pipe(untilDestroyed(this)).subscribe(() => {
      this.customizeService.enabled = undefined;

      this.getConfigurationAnalytics()
        .pipe(untilDestroyed(this))
        .subscribe(configuration => {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPreview, {
            Published: this.published,
            ...configuration
          });
        });
    });
  }

  exitPreview() {
    this.customizeService.enabled = CustomizeType.Layout;

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ExitPreview, {
      Published: this.published
    });
  }

  getAppUrl(domain?: Domain) {
    const project = this.currentProjectStore.instance;
    const inAppDisabled = this.customizeService.handlerInfo && this.customizeService.handlerInfo.inAppDisabled;
    const newPage =
      this.customizeService.handlerInfo &&
      this.customizeService.handlerInfo.page &&
      !this.customizeService.handlerInfo.page.uid;

    let path: string;
    let params: string;

    if (this.customizeService.handler && !inAppDisabled && !newPage) {
      path = window.location.pathname.replace('/builder/', '/app/');
      params = window.location.search;
    } else if (project) {
      path = project.getHomeLink({ mode: AdminMode.App }).join('/');
      params = '';
    }

    if (domain) {
      return `${project.protocol}://${domain.actualDomain}${path}${params}`;
    } else {
      return `${this.appConfigService.webBaseUrl}${path}${params}`;
    }
  }

  switchToApp(domain?: Domain) {
    const url = this.getAppUrl(domain);
    openUrl(url);
  }

  openAppUrl(domain?: Domain) {
    const url = this.getAppUrl(domain);
    openUrl(url, true);

    this.getConfigurationAnalytics()
      .pipe(untilDestroyed(this))
      .subscribe(configuration => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenApp, {
          Published: this.published,
          ...configuration
        });
      });
  }

  openSignUp() {
    this.routing.navigateApp(this.currentProjectStore.instance.settingsSignUpLink('sign_up'));
  }

  getConfigurationAnalytics(): Observable<Object> {
    return combineLatest(
      this.currentEnvironmentStore.getResourcesFirst(),
      this.modelDescriptionStore.getFirst(),
      this.actionStore.getFirst(),
      this.viewSettingsStore.getFirst(),
      this.projectUserService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        { [PER_PAGE_PARAM]: 1 }
      )
    ).pipe(
      map(([resources, modelDescriptions, actionDescriptions, viewSettings, projectUsers]) => {
        return {
          Resources: resources.filter(item => !item.demo).length,
          ProjectUsers: projectUsers.count,
          ConfiguredComponents: viewSettings.reduce((acc, item) => acc + item.configuredElements, 0),
          ConfiguredCollectionComponents: viewSettings.reduce((acc, item) => acc + item.configuredModelElements, 0),
          ConfiguredActionComponents: viewSettings.reduce((acc, item) => acc + item.configuredActionElements, 0),
          ConfiguredVirtualCollections: modelDescriptions.filter(
            item => item.virtual && item.getQuery && item.getQuery.isConfigured()
          ).length,
          ConfiguredVirtualActions: actionDescriptions.filter(
            item => item.virtual && item.queryAction && item.queryAction && item.queryAction.query.isConfigured()
          ).length
        };
      })
    );
  }

  // requestPublish() {
  //   if (this.hasChange) {
  //     return this.dialogService
  //       .prompt({
  //         title: 'You have unsaved changes',
  //         description: 'Do you want to save current changes before publishing?',
  //         style: 'orange',
  //         buttons: [
  //           {
  //             name: 'cancel',
  //             label: "Don't save",
  //             type: DialogButtonType.Default
  //           },
  //           {
  //             name: 'submit',
  //             label: 'Save',
  //             type: DialogButtonType.Submit,
  //             executor: () => {
  //               return this.customizeService.saveCustomize();
  //             }
  //           }
  //         ]
  //       })
  //       .pipe(untilDestroyed(this))
  //       .subscribe(() => this.publish());
  //   } else {
  //     this.publish();
  //   }
  // }

  // publish() {
  //   const url = this.getAppUrl();
  //   openUrl(url, true);
  //
  //   combineLatest(
  //     this.currentEnvironmentStore.getResourcesFirst(),
  //     this.modelDescriptionStore.getFirst(),
  //     this.actionStore.getFirst(),
  //     this.viewSettingsStore.getViewSettingsFirst(),
  //     this.projectUserStore.getFirst()
  //   )
  //     .pipe(untilDestroyed(this))
  //     .subscribe(([resources, modelDescriptions, actionDescriptions, viewSettings, projectUsers]) => {
  //       this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenApp, {
  //         Resources: resources.filter(item => !item.demo).length,
  //         ProjectUsers: projectUsers.length + 1,
  //         ConfiguredComponents: viewSettings.reduce((acc, item) => acc + item.configuredElements, 0),
  //         ConfiguredCollectionComponents: viewSettings.reduce((acc, item) => acc + item.configuredModelElements, 0),
  //         ConfiguredActionComponents: viewSettings.reduce((acc, item) => acc + item.configuredActionElements, 0),
  //         ConfiguredVirtualCollections: modelDescriptions.filter(
  //           item => item.virtual && item.getQuery && item.getQuery.isConfigured()
  //         ).length,
  //         ConfiguredVirtualActions: actionDescriptions.filter(
  //           item => item.virtual && item.queryAction && item.queryAction && item.queryAction.query.isConfigured()
  //         ).length
  //       });
  //     });
  // }

  publishDraft(silent = false) {
    if (!this.draftChanges.length) {
      return;
    }

    this.publishLoading = true;
    this.cd.markForCheck();

    this.environmentService
      .publishDraft(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        delayWhen(() => this.publishService.reloadChanges(this.draftChanges)),
        delayWhen(() => this.publishService.updatePagePermissions()),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.publishLoading = false;
          this.cd.markForCheck();

          if (!silent) {
            this.setPublishOpened(true, true);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Published);
          }
        },
        () => {
          this.publishLoading = false;
          this.cd.markForCheck();

          if (!silent) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.PublishFailed);
          }
        }
      );

    if (!silent) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPublish);
    }
  }

  deleteDraft() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickDiscard);

    this.setPublishOpened(false);

    this.dialogService
      .dialog({
        title: `Are you sure want to discard current draft changes?`,
        description: `
          Current published project state will be restored.<br>
          Other environments will not be affected.
        `,
        style: 'orange',
        buttons: [
          {
            name: 'review',
            label: 'Review changes',
            type: DialogButtonType.Default,
            position: DialogButtonPosition.Left
          },
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'OK',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => {
              this.savingChanges = true;
              this.cd.markForCheck();

              return this.environmentService.deleteDraft(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName
              );
            }
          }
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (result.button == 'ok') {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Discarded);

            window.location.reload();
          } else if (result.button == 'cancel') {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardCancelled);
          } else if (result.button == 'review') {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardReviewChanges);

            this.showChanges();
          }
        },
        () => {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardFailed);

          this.savingChanges = false;
          this.cd.markForCheck();
        }
      );
  }

  openProjects() {
    if (this.currentEnvironmentStore.instance.version) {
      openUrl('/projects');
    } else {
      this.routing.navigate(['/projects']);
    }
  }

  createEnvironment() {
    this.environmentController.createEnvironment({ source: this.source });
  }

  setEnvironment(environment: Environment) {
    const link = this.project.getHomeLinkWithProtocol({ environmentName: environment.uniqueName });
    this.routing.navigateLink(link);
  }

  openEnvironmentSettings(environment: Environment) {
    const link = this.project.linkWithProtocol(environment.link, { environmentName: environment.uniqueName });
    this.routing.navigateLink(link);
  }

  showChanges() {
    this.popupService.push({
      component: DraftChangesComponent,
      popupComponent: ContrastDialogPopupComponent,
      popupComponentCloseWithoutConfirm: true,
      injector: this.injector
    });
  }

  saveChanges() {
    this.customizeService.saveCurrentChangesHistory().pipe(untilDestroyed(this)).subscribe();
  }

  showSnapshots() {
    this.popupService.push({
      component: SnapshotPopupComponent,
      popupComponent: ContrastDialogPopupComponent,
      popupComponentCloseWithoutConfirm: true,
      injector: this.injector
    });
  }

  editDomain() {
    this.projectDomainController.edit({ analyticsSource: 'customize_toolbar' }).pipe(untilDestroyed(this)).subscribe();
  }

  onSnapshotsClick() {
    const featureEnabled = this.currentProjectStore.instance.features.isSnapshotsEnabled();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Snapshots.ClickSnapshots, {
      FeatureEnabled: featureEnabled
    });

    if (!featureEnabled) {
      this.featureService.showFeatureOverview({
        subtitle: 'Version Control Feature',
        title: '<strong>Version Control</strong> of your App',
        description: `
        <ul>
          <li>Automatic snapshots when App being changed or published</li>
          <li>App snapshots when new version is released</li>
          <li>Version history of your App</li>
          <li>Rollback to previous versions</li>
        </ul>
      `
      });
      return;
    }

    this.showSnapshots();
  }
}

<div
  class="chips chips_fill"
  [class.chips_circular]="circular"
  [class.chips_small]="small"
  [class.chips_focus]="input_focus.focused$ | async"
  (mousedown)="markChipsMouseEvent($event)"
  (click)="onClick($event)"
  cdkOverlayOrigin
  #dropdown_origin="cdkOverlayOrigin"
>
  <app-chips-item
    *ngFor="let item of selectedValues; let i = index; trackBy: trackItemFn"
    [value]="item"
    [source]="source"
    [options]="options"
    [addedOptions]="addedOptions"
    [compareWith]="compareWith"
    (remove)="markChipsElementMouseEvent($event); removeItem(item, i)"
  ></app-chips-item>

  <div class="chips__input-wrapper">
    <input
      type="text"
      [formControl]="addControl"
      class="chips__input"
      [class.chips__input_icon-right]="resetEnabled && selectedValues.length"
      [placeholder]="placeholder"
      (keydown)="onKeydown($event)"
      (click)="markChipsElementMouseEvent($event)"
      appFocusMonitor
      #input_focus="appFocusMonitor"
      #input_element
    />

    <a
      *ngIf="resetEnabled && selectedValues.length"
      href="javascript:void(0)"
      class="chips__input-clear icon-close"
      (click)="resetValue(); addControl.patchValue('')"
    ></a>
  </div>
</div>

<app-dropdown
  [origin]="dropdown_origin"
  [openOnFocus]="input_element"
  [closeOnMouseDown]="closeOnMouseDown"
  [source]="source"
  [options]="options"
  [selectedValues]="selectedValues"
  [compareWith]="compareWith"
  [searchExternalControl]="addControl"
  [addValueEnabled]="allowNew"
  [backdrop]="false"
  (click)="markChipsElementMouseEvent($event)"
  (valueSelected)="addValues($event); focusInput()"
  (addValue)="addValueFromControl(); focusInput()"
>
  <ng-container dropdown-top>
    <ng-content select="ng-container[dropdown-top]"></ng-content>
  </ng-container>

  <ng-container dropdown-bottom>
    <ng-content select="ng-container[dropdown-bottom]"></ng-content>
  </ng-container>
</app-dropdown>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import {
  AccordionElementItem,
  AccordionItem,
  CustomizeService,
  ElementType,
  registerElementComponent
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { TypedChanges } from '@shared';

interface Item {
  item: AccordionItem;
  visible: boolean;
}

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: AccordionElementItem;

  customizeEnabled$: Observable<boolean>;
  element$ = new BehaviorSubject<AccordionElementItem>(this.element);
  items: Item[] = [];
  openedItems: string[] = [];

  trackItem = (() => {
    return (i, item: Item) => {
      const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
      return [pageUid, item.item.uid].join('_');
    };
  })();

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.element$.next(this.element);

    this.initItems();
    this.initItemsOpened();
    this.initItemsMultipleOpened();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AccordionComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.element$.next(this.element);
    }
  }

  initItems() {
    this.element$
      .pipe(
        switchMap(element => {
          const items$: Observable<{ item: AccordionItem; visible: boolean }>[] = element.items.map(accordionItem => {
            if (!accordionItem.visibleInput) {
              return of({
                item: accordionItem,
                visible: true
              });
            }

            return applyBooleanInput$(accordionItem.visibleInput, {
              context: this.context
            }).pipe(
              distinctUntilChanged(),
              map(visible => ({ item: accordionItem, visible: visible }))
            );
          });

          if (!items$.length) {
            return of([]);
          }

          return combineLatest(items$).pipe(
            switchMap(items => {
              return this.customizeService.enabled$.pipe(
                map(customizing => {
                  if (!customizing) {
                    return items.filter(item => item.visible);
                  } else {
                    return items;
                  }
                })
              );
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.items = result;
        this.cd.markForCheck();
      });
  }

  initItemsOpened() {
    this.element$
      .pipe(
        switchMap(element => {
          const items$ = element.items.map(accordionItem => {
            if (!accordionItem.openedInput || !accordionItem.openedInput.isSet()) {
              return of(false);
            }

            return applyParamInput$(accordionItem.openedInput, {
              context: this.context
            }).pipe(
              distinctUntilChanged(),
              tap(opened => {
                if (opened) {
                  this.openItem(accordionItem.uid);
                } else {
                  this.closeItem(accordionItem.uid);
                }
              })
            );
          });

          if (!items$.length) {
            return of([]);
          }

          return combineLatest(items$);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  initItemsMultipleOpened() {
    this.element$
      .pipe(
        map(element => element.multipleOpened),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(multipleOpened => {
        if (!multipleOpened && this.openedItems.length > 1) {
          this.setOpenedItems(this.openedItems.slice(0, 1));
        }
      });
  }

  setOpenedItems(items: string[]) {
    this.openedItems = items;
    this.cd.markForCheck();
  }

  openItem(uid: string) {
    if (this.openedItems.includes(uid)) {
      return;
    }

    if (this.element.multipleOpened) {
      this.setOpenedItems([...this.openedItems, uid]);
    } else {
      this.setOpenedItems([uid]);
    }
  }

  closeItem(uid: string) {
    if (!this.openedItems.includes(uid)) {
      return;
    }

    this.setOpenedItems(this.openedItems.filter(item => item != uid));
  }

  toggleOpenedItem(uid: string) {
    if (this.openedItems.includes(uid)) {
      this.closeItem(uid);
    } else {
      this.openItem(uid);
    }
  }
}

registerElementComponent({
  type: ElementType.Accordion,
  component: AccordionComponent,
  label: 'Accordion',
  alwaysActive: false,
  actions: []
});

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i2 from "../menu-item/menu-item.component.ngfactory";
import * as i3 from "../menu-item/menu-item.component";
import * as i4 from "../../../../projects/stores/current-project.store";
import * as i5 from "../../../../menu/services/menu-generator/menu-generator.service";
import * as i6 from "@angular/router";
import * as i7 from "./model-link-menu-item.component";
import * as i8 from "../../../../model-queries/stores/model-description.store";
import * as i9 from "../../../../routing/services/routing/routing.service";
var styles_ModelLinkMenuItemComponent = [];
var RenderType_ModelLinkMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelLinkMenuItemComponent, data: {} });
export { RenderType_ModelLinkMenuItemComponent as RenderType_ModelLinkMenuItemComponent };
export function View_ModelLinkMenuItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-menu-item", [], null, [[null, "dropdownOpen"], [null, "dropdownClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropdownOpen" === en)) {
        var pd_0 = (_co.dropdownOpen.emit() !== false);
        ad = (pd_0 && ad);
    } if (("dropdownClose" === en)) {
        var pd_1 = (_co.dropdownClose.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MenuItemComponent_0, i2.RenderType_MenuItemComponent)), i0.ɵdid(2, 4964352, null, 0, i3.MenuItemComponent, [i4.CurrentProjectStore, i5.MenuGeneratorService, i6.Router, i0.ChangeDetectorRef], { icon: [0, "icon"], title: [1, "title"], link: [2, "link"], menuBlock: [3, "menuBlock"], children: [4, "children"], childrenVertical: [5, "childrenVertical"], primary: [6, "primary"], dropdown: [7, "dropdown"], forceActive: [8, "forceActive"], forceOpened: [9, "forceOpened"] }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItem.icon; var currVal_1 = i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.menuItem.title)); var currVal_2 = _co.link; var currVal_3 = _co.menuBlock; var currVal_4 = _co.menuItem.children; var currVal_5 = _co.childrenVertical; var currVal_6 = _co.primary; var currVal_7 = _co.dropdown; var currVal_8 = _co.forceActive; var currVal_9 = _co.forceOpened; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ModelLinkMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-link-menu-item", [], null, null, null, View_ModelLinkMenuItemComponent_0, RenderType_ModelLinkMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i7.ModelLinkMenuItemComponent, [i8.ModelDescriptionStore, i9.RoutingService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelLinkMenuItemComponentNgFactory = i0.ɵccf("app-model-link-menu-item", i7.ModelLinkMenuItemComponent, View_ModelLinkMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { ModelLinkMenuItemComponentNgFactory as ModelLinkMenuItemComponentNgFactory };

import isArray from 'lodash/isArray';

import {
  containsFieldLookup,
  endsWithFieldLookup,
  exactFieldLookup,
  inFieldLookup,
  isEmptyFieldLookup,
  isNullFieldLookup,
  startsWithFieldLookup
} from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';
import { parseInLookupStringValue } from '../lookup-matchers/text';

// TODO: Refactor import
const AIRTABLE_PRIMARY_KEY = '__jet_pk__';

export const textAirtableFormulas = [
  {
    field: FieldType.Text,
    lookup: containsFieldLookup,
    formula: (lookupValue: string, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }

      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      return `SEARCH("${String(lookupValue).toLowerCase()}", LOWER(${fieldValue}))`;
    }
  },
  {
    field: FieldType.Text,
    lookup: exactFieldLookup,
    formula: (lookupValue: string, field?: BaseField): string => {
      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      return `${fieldValue} = "${lookupValue}"`;
    }
  },
  {
    field: FieldType.Text,
    lookup: startsWithFieldLookup,
    formula: (lookupValue: string, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }

      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      return `LOWER(LEFT(${fieldValue}, ${lookupValue.length})) = "${String(lookupValue).toLowerCase()}"`;
    }
  },
  {
    field: FieldType.Text,
    lookup: endsWithFieldLookup,
    formula: (lookupValue: string, field?: BaseField): string => {
      if (!lookupValue) {
        return;
      }

      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      return `LOWER(RIGHT(${fieldValue}, ${lookupValue.length})) = "${String(lookupValue).toLowerCase()}"`;
    }
  },
  {
    field: FieldType.Text,
    lookup: isEmptyFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      return `${fieldValue} = ""`;
    }
  },
  {
    field: FieldType.Text,
    lookup: isNullFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      // return !!lookupValue ? fieldValue === null : fieldValue !== null;
      return;
    }
  },
  {
    field: FieldType.Text,
    lookup: inFieldLookup,
    formula: (lookupValue: string | string[], field?: BaseField): string => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return;
      }

      if (typeof lookupValue == 'string') {
        lookupValue = parseInLookupStringValue(lookupValue);
      } else if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const fieldValue = field.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : `{${field.name}}`;
      const predicates = lookupValue.map(item => `${fieldValue} = "${item}"`);
      return `OR(${predicates.join(',')})`;
    }
  }
];

export enum HttpContentType {
  JSON = 'JSON',
  GraphQL = 'GraphQL',
  FormUrlEncoded = 'FormUrlEncoded',
  FormData = 'FormData',
  Raw = 'Raw'
}

export const httpContentTypes = [
  {
    type: HttpContentType.JSON,
    label: 'JSON'
  },
  {
    type: HttpContentType.FormUrlEncoded,
    label: 'Form URL Encoded'
  },
  {
    type: HttpContentType.FormData,
    label: 'Form Data'
  },
  {
    type: HttpContentType.Raw,
    label: 'Raw'
  },
  {
    type: HttpContentType.GraphQL,
    label: 'GraphQL query'
  }
];

import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  OnInit,
  Output,
  QueryList
} from '@angular/core';

import { BackgroundTabComponent } from '../background-tab/background-tab.component';

@Component({
  selector: 'app-background-tabs',
  templateUrl: './background-tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackgroundTabsComponent implements OnInit, AfterContentChecked {
  @ContentChildren(BackgroundTabComponent) tabComponents = new QueryList<BackgroundTabComponent>();
  @Output() tabClick = new EventEmitter<string>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterContentChecked(): void {
    this.cd.markForCheck();
  }
}

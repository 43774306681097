import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Environment, Project } from '@modules/projects';

import { Domain } from '../../domain';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(domain: string, custom = true): Observable<Domain> {
    const url = this.apiService.methodURL('domains/');
    const httpParams = new HttpParams({
      fromObject: {
        domain: domain,
        ...(!custom && {
          custom: '0'
        })
      }
    });

    return this.http.get(url, { params: httpParams }).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return new Domain().deserialize(result);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createCustom(project: Project, environment: Environment, domain: Domain): Observable<Domain> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'domains');
        let headers = new HttpHeaders();
        const data = domain.serialize(['domain']);
        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Domain().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(project: Project, environment: Environment, domain: Domain, fields?: string[]): Observable<Domain> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `domains/${domain.domain}`
        );
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({
          fromObject: {
            ...(!domain.custom && {
              custom: '0'
            })
          }
        });
        const data = domain.serialize(fields);
        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers, params: httpParams });
      }),
      map(result => new Domain().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteCustom(project: Project, environment: Environment, domain: Domain): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `domains/${domain.domain}`
        );
        let headers = new HttpHeaders();
        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import {
  CrispIntegrationEditController,
  DriftIntegrationEditController,
  FacebookPixelIntegrationEditController,
  GoogleAnalyticsIntegrationEditController,
  GoogleTagManagerIntegrationEditController,
  HotjarIntegrationEditController,
  HubspotIntegrationEditController,
  IntercomIntegrationEditController
} from '@modules/integrations-components';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import { CurrentProjectStore } from '@modules/projects';
import { deployUrl, isSet } from '@shared';

import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';

@Component({
  selector: 'app-customize-bar-integrations-edit',
  templateUrl: './customize-bar-integrations-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarIntegrationsEditComponent implements OnInit, OnDestroy {
  @Input() settingsForm: ProjectSettingsUpdateForm;

  @ViewChild('dropdown') signUpEnableGroupDropdown: ProjectGroupDropdownComponent;

  loading = false;
  settings: AllProjectSettings;
  itemTypes: {
    name: string;
    subtitle?: string;
    icon?: string;
    image?: string;
    getValue: (settings: AllProjectSettings) => string;
    editValue: () => void;
  }[] = [
    {
      name: 'Google Analytics',
      subtitle: 'Track traffic',
      image: deployUrl('/assets/images/integrations/google-analytics.svg'),
      getValue: settings => {
        return settings.googleAnalytics ? settings.googleAnalytics.id : undefined;
      },
      editValue: () => this.editGoogleAnalytics()
    },
    {
      name: 'Google Tag Manager',
      subtitle: 'Integrate external code',
      image: deployUrl('/assets/images/integrations/google-tag-manager.svg'),
      getValue: settings => {
        return settings.googleTagManager ? settings.googleTagManager.id : undefined;
      },
      editValue: () => this.editGoogleTagManager()
    },
    {
      name: 'Intercom',
      subtitle: 'Live chat',
      image: deployUrl('/assets/images/resources/icons/intercom.svg'),
      getValue: settings => {
        return settings.intercom ? settings.intercom.workspace_id : undefined;
      },
      editValue: () => this.editIntercom()
    },
    {
      name: 'Hubspot',
      subtitle: 'Live chat, forms, campaigns',
      image: deployUrl('/assets/images/resources/icons/hubspot.svg'),
      getValue: settings => {
        return settings.hubspot ? settings.hubspot.id : undefined;
      },
      editValue: () => this.editHubspot()
    },
    {
      name: 'Hotjar',
      subtitle: 'Record interactions',
      image: deployUrl('/assets/images/integrations/hotjar.svg'),
      getValue: settings => {
        return settings.hotjar ? settings.hotjar.site_id : undefined;
      },
      editValue: () => this.editHotjar()
    },
    {
      name: 'Facebook Pixel',
      subtitle: 'Track ad audiences',
      image: deployUrl('/assets/images/sso/facebook.svg'),
      getValue: settings => {
        return settings.facebookPixel ? settings.facebookPixel.id : undefined;
      },
      editValue: () => this.editFacebookPixel()
    },
    {
      name: 'Drift',
      subtitle: 'Live chat',
      image: deployUrl('/assets/images/integrations/drift.svg'),
      getValue: settings => {
        return settings.drift ? settings.drift.id : undefined;
      },
      editValue: () => this.editDrift()
    },
    {
      name: 'Crisp',
      subtitle: 'Live chat',
      image: deployUrl('/assets/images/integrations/crisp.svg'),
      getValue: settings => {
        return settings.crisp ? settings.crisp.id : undefined;
      },
      editValue: () => this.editCrisp()
    }
  ];
  enabledItems: {
    name: string;
    subtitle?: string;
    value: string;
    icon?: string;
    image?: string;
    editValue: () => void;
  }[];
  disabledItems: {
    name: string;
    subtitle?: string;
    value: string;
    icon?: string;
    image?: string;
    editValue: () => void;
  }[];
  analyticsSource = 'layout_builder_integrations';

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private projectSettingsStore: ProjectSettingsStore,
    private crispIntegrationEditController: CrispIntegrationEditController,
    private driftIntegrationEditController: DriftIntegrationEditController,
    private facebookPixelIntegrationEditController: FacebookPixelIntegrationEditController,
    private googleAnalyticsIntegrationEditController: GoogleAnalyticsIntegrationEditController,
    private googleTagManagerIntegrationEditController: GoogleTagManagerIntegrationEditController,
    private hotjarIntegrationEditController: HotjarIntegrationEditController,
    private hubspotIntegrationEditController: HubspotIntegrationEditController,
    private intercomIntegrationEditController: IntercomIntegrationEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.projectSettingsStore
      .getAllSettings$()
      .pipe(untilDestroyed(this))
      .subscribe(
        settings => {
          this.settings = settings;
          const items = this.itemTypes.map(item => {
            return {
              name: item.name,
              subtitle: item.subtitle,
              icon: item.icon,
              image: item.image,
              value: item.getValue(settings),
              editValue: () => item.editValue()
            };
          });
          this.enabledItems = items.filter(item => isSet(item.value));
          this.disabledItems = items.filter(item => !isSet(item.value));
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  editCrisp() {
    this.crispIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editDrift() {
    this.driftIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editFacebookPixel() {
    this.facebookPixelIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editGoogleAnalytics() {
    this.googleAnalyticsIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editGoogleTagManager() {
    this.googleTagManagerIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editHotjar() {
    this.hotjarIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editHubspot() {
    this.hubspotIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }

  editIntercom() {
    this.intercomIntegrationEditController
      .edit({
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.result) {
          window.location.reload();
        }
      });
  }
}

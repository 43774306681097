import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { TypedChanges } from '@shared';

import { ModelPrimaryKeyForm } from './model-primary-key.form';

@Component({
  selector: 'app-model-primary-key',
  templateUrl: './model-primary-key.component.html',
  providers: [ModelPrimaryKeyForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelPrimaryKeyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() modelDescription: ModelDescription;
  @Input() cancelEnabled = true;
  @Output() cancelled = new EventEmitter<void>();
  @Output() saved = new EventEmitter<ModelDescription>();

  loading = false;

  constructor(
    public form: ModelPrimaryKeyForm,
    @Optional() private popupComponent: BasePopupComponent,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.modelDescription);
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelPrimaryKeyComponent>): void {
    if (changes.modelDescription && !changes.modelDescription.firstChange) {
      this.form.init(this.modelDescription);
    }
  }

  submit() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        model => {
          this.saved.emit(model);
          this.close();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  cancel() {
    this.close();
    this.cancelled.emit();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

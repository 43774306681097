import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { WidgetType } from '../widget-type';

export class Widget {
  public id: number;
  public type: WidgetType;
  public nameInput: Input;
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public tooltip: string;
  public params: Object;
  public dateAdd: Date;
  public dashboard: number;

  public context: any;

  deserialize(data: Object): Widget {
    this.id = data['id'];
    this.type = data['widget_type'];
    this.x = data['x'];
    this.y = data['y'];
    this.width = data['width'];
    this.height = data['height'];
    this.tooltip = data['tooltip'];
    this.params = JSON.parse(data['params']);
    this.dateAdd = data['date_add'];
    this.dashboard = data['dashboard'];

    if (this.params['name_input']) {
      this.nameInput = new Input().deserialize(this.params['name_input']);
    } else if (data['name']) {
      // Backward compatibility
      this.nameInput = new Input().deserializeFromStatic('value', data['name']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      id: this.id,
      widget_type: this.type,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      tooltip: this.tooltip,
      params: JSON.stringify(this.params),
      dashboard: this.dashboard ? this.dashboard : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr() {
    if (this.type == WidgetType.Chart) {
      return 'Chart';
    } else if (this.type == WidgetType.Value) {
      return 'Single Value';
    }
  }

  get analyticsName(): string {
    if (this.type == WidgetType.Chart) {
      return 'chart';
    } else if (this.type == WidgetType.Value) {
      return 'single_value';
    }
  }

  isReloadDataSupported(): boolean {
    return [WidgetType.Value, WidgetType.Chart].includes(this.type);
  }

  get analyticsGenericName(): string {
    if (!this.analyticsName) {
      return;
    }
    return ['component', this.analyticsName].join('_');
  }
}

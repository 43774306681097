import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { debounceTime, filter, map, publishLast, refCount, shareReplay, switchMap, tap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { UserActivity } from '../../data/user-activity';
import { UserActivityType } from '../../data/user-activity-type';
import { UserActivityService } from '../user-activity/user-activity.service';

export interface UserActivitiesResult {
  result: {
    object_id: string;
    count: number;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class UserActivityCountService {
  countObjectIdQueue: { [k: string]: { objectIds: BehaviorSubject<string[]>; response: Observable<any> } } = {};
  countObjectIdQueueDebounce = 200;

  constructor(
    private userActivityService: UserActivityService,
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  countObjectIds(
    projectName: string,
    environmentName: string,
    activityType: UserActivityType,
    objectType: string,
    objectIds: string[]
  ): Observable<UserActivitiesResult> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'user_activities/count/');
        let headers = new HttpHeaders();
        const data = {
          activity_type: activityType,
          object_type: objectType,
          object_ids: objectIds
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<UserActivitiesResult>(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  countObjectId(
    projectName: string,
    environmentName: string,
    activityType: UserActivityType,
    objectType: string,
    objectId: string
  ): Observable<number> {
    const key = [projectName, environmentName, activityType, objectType].join('_');

    if (!this.countObjectIdQueue[key]) {
      const objectIds = new BehaviorSubject<string[]>([objectId]);
      const objectIdsRequest = objectIds.pipe(
        debounceTime(this.countObjectIdQueueDebounce),
        switchMap(value => {
          this.countObjectIdQueue[key] = undefined;
          return this.countObjectIds(projectName, environmentName, activityType, objectType, value);
        }),
        shareReplay()
      );

      this.countObjectIdQueue[key] = {
        objectIds: objectIds,
        response: objectIdsRequest
      };
    } else {
      const objectIds = this.countObjectIdQueue[key].objectIds;

      if (!objectIds.value.includes(objectId)) {
        objectIds.next([...objectIds.value, objectId]);
      }
    }

    return this.countObjectIdQueue[key].response.pipe(
      map(result => {
        const count = result.result.find(item => item.object_id == objectId);
        return count ? count.count : 0;
      })
    );
  }

  subscribeCountObjectId(
    projectName: string,
    environmentName: string,
    activityType: UserActivityType,
    objectType: string,
    objectId: string
  ): Observable<number> {
    return this.countObjectId(projectName, environmentName, activityType, objectType, objectId).pipe(
      switchMap(count => {
        return merge(
          of(count),
          this.userActivityService.subscribeUserActivities(projectName, environmentName, objectType, objectId).pipe(
            filter(item => item.activityType == activityType),
            map(() => ++count)
          )
        );
      })
    );
  }
}

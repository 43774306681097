import { Injectable } from '@angular/core';
import * as inflection from 'inflection';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { combineLatest, Observable, of } from 'rxjs';
import { map as rxMap } from 'rxjs/internal/operators/map';
import { delayWhen, tap } from 'rxjs/operators';

import {
  CustomizeService,
  CustomizeType,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { Dashboard, DashboardService, DashboardStore } from '@modules/dashboard';
import {
  ButtonMenuItem,
  MenuSettings,
  MenuSettingsService,
  MenuSettingsStore,
  ModelLinkMenuItem,
  SectionMenuItem,
  SimpleMenuItem
} from '@modules/menu';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

interface CustomizeChange<T = any> {
  value: T;
  hasChanges: boolean;
}

export interface MenuSettingsChanges {
  menuSettings: CustomizeChange<MenuSettings>;
  changedModels: CustomizeChange<ModelDescription[]>;
  changedDashboards: CustomizeChange<Dashboard[]>;
  deletedDashboards: CustomizeChange<Dashboard[]>;
  changedPages: CustomizeChange<ViewSettings[]>;
}

@Injectable()
export class MenuCustomizeService {
  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelDescriptionService: ModelDescriptionService,
    public menuSettingsService: MenuSettingsService,
    public menuSettingsStore: MenuSettingsStore,
    public customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private dashboardStore: DashboardStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private dashboardService: DashboardService
  ) {}

  getChanges(prevInstance: MenuSettings, currentInstance: MenuSettings): Observable<MenuSettingsChanges> {
    return combineLatest(this.modelDescriptionStore.getFirst(), this.viewSettingsStore.getFirst()).pipe(
      rxMap(([models, pages]) => {
        const settingsHasChanges = !isEqual(currentInstance.serialize(['items']), prevInstance.serialize(['items']));
        const changedModels: ModelDescription[] = currentInstance
          .getAllItems()
          .reduce((acc, item) => {
            if (item instanceof SectionMenuItem) {
              acc.push(...item.children);
            } else {
              acc.push(item);
            }
            return acc;
          }, [])
          .filter(item => item instanceof ModelLinkMenuItem)
          .filter(item => item.model)
          .reduce((acc, item) => {
            // const model = models ? models.find(i => i.isSame(item.model)) : undefined;
            //
            // if (model && model.verboseNamePlural != item.title && isSet(item.title)) {
            //   const newInstance: ModelDescription = cloneDeep(model);
            //
            //   if (/^[A-Za-z0-9]*$/.test(item.title)) {
            //     newInstance.verboseName = inflection.singularize(item.title);
            //
            //     if (newInstance.verboseName == item.title) {
            //       newInstance.verboseNamePlural = inflection.pluralize(item.title);
            //     } else {
            //       newInstance.verboseNamePlural = item.title;
            //     }
            //   } else {
            //     newInstance.verboseName = newInstance.verboseNamePlural = item.title;
            //   }
            //
            //   acc.push(newInstance);
            // }

            return acc;
          }, []);
        const changedPages = currentInstance
          .getAllItems()
          .reduce((acc, item) => {
            if (item instanceof SectionMenuItem) {
              acc.push(...item.children);
            } else {
              acc.push(item);
            }
            return acc;
          }, [])
          .filter(item => item instanceof SimpleMenuItem || item instanceof ButtonMenuItem)
          .reduce((acc, item: SimpleMenuItem | ButtonMenuItem) => {
            // const page = pages ? pages.find(i => item.isForPage(i)) : undefined;
            //
            // if (page && page.name != item.title && isSet(item.title)) {
            //   const newInstance: ViewSettings = cloneDeep(page);
            //   newInstance.name = item.title;
            //   acc.push(newInstance);
            // }

            return acc;
          }, []);

        return {
          menuSettings: { value: currentInstance, hasChanges: settingsHasChanges },
          changedModels: { value: changedModels, hasChanges: changedModels.length > 0 },
          changedDashboards: { value: [], hasChanges: false },
          deletedDashboards: { value: [], hasChanges: false },
          changedPages: { value: changedPages, hasChanges: changedPages.length > 0 }
        };
      })
    );
  }

  saveChanges(changes: MenuSettingsChanges): Observable<Object> {
    // return this.getChanges().pipe(
    //   switchMap(changes => {
    const requests = [
      ...(changes.menuSettings.hasChanges
        ? [
            this.menuSettingsService.create(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              changes.menuSettings.value
            )
          ]
        : []),
      ...changes.changedModels.value.map(item =>
        this.modelDescriptionService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          item
        )
      ),
      ...changes.changedDashboards.value.map(item =>
        this.dashboardService.update(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          item
        )
      ),
      ...changes.deletedDashboards.value.map(item =>
        this.dashboardService.delete(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          item
        )
      ),
      ...changes.changedPages.value.map(item =>
        this.viewSettingsService.create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          item
        )
      )
    ];

    if (!requests.length) {
      return of({});
    }

    return combineLatest(...requests).pipe(
      delayWhen(() => {
        const obs = [];

        if (changes.changedModels.hasChanges) {
          obs.push(this.modelDescriptionStore.getFirst(true));
        }

        if (changes.changedDashboards.hasChanges) {
          obs.push(this.dashboardStore.getFirst(true));
        }

        if (changes.changedPages.hasChanges) {
          obs.push(this.viewSettingsStore.getFirst(true));
        }

        return obs.length ? combineLatest(obs) : of({});
      }),
      delayWhen(() => this.menuSettingsStore.getFirst(true)),
      tap(() => {
        this.customizeService.enabled = CustomizeType.Layout;
      }),
      rxMap(() => {
        return {};
      })
    );
  }
}

import { HttpParams } from '@angular/common/http';
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import Plyr from 'plyr';
import { interval } from 'rxjs';
import { DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
var VideoPopupComponent = /** @class */ (function () {
    function VideoPopupComponent(popupComponent, analyticsService, dialogService) {
        this.popupComponent = popupComponent;
        this.analyticsService = analyticsService;
        this.dialogService = dialogService;
        this.autoPlay = false;
        this.links = [];
        this.closed = new EventEmitter();
        this.tl = new TimelineMax();
        this.animating = false;
        this.duration = 0;
        this.currentDuration = 0;
    }
    VideoPopupComponent.prototype.ngAfterViewInit = function () {
        this.show();
        this.initPlayer();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.Overview, AnalyticsEventAction.Open);
    };
    VideoPopupComponent.prototype.ngOnDestroy = function () {
        this.closed.emit();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.Overview, AnalyticsEventAction.Close, JSON.stringify({
            totalDuration: this.totalDuration,
            currentTime: this.player ? this.player.currentTime : undefined
        }));
        if (this.player) {
            this.player.destroy();
        }
    };
    VideoPopupComponent.prototype.initPlayer = function () {
        var _this = this;
        if (!this.videoElement) {
            return;
        }
        this.player = new Plyr(this.videoElement.nativeElement, {
            controls: ['play-large', 'progress', 'mute', 'volume', 'settings', 'fullscreen'],
            settings: ['captions', 'quality', 'speed', 'loop']
        });
        this.player.on('playing', function () {
            _this.startPlaying = _this.player.currentTime;
            _this.duration += _this.currentDuration;
            _this.currentDuration = 0;
        });
        this.player.on('timeupdate', function () {
            var newCurrentDuration = _this.player.currentTime - _this.startPlaying;
            if (newCurrentDuration > 0 && newCurrentDuration - _this.currentDuration <= 1.5) {
                _this.currentDuration = newCurrentDuration;
            }
            else {
                _this.duration += _this.currentDuration;
                _this.currentDuration = 0;
            }
        });
        this.player.on('seeked', function () {
            _this.analyticsService.sendEvent(AnalyticsEvent.GA.Overview, AnalyticsEventAction.Seek, JSON.stringify({
                totalDuration: _this.totalDuration,
                currentTime: _this.player.currentTime
            }));
        });
        interval(5000)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.analyticsService.sendEvent(AnalyticsEvent.GA.Overview, AnalyticsEventAction.Emit, JSON.stringify({
                totalDuration: _this.totalDuration,
                currentTime: _this.player.currentTime
            }));
        });
    };
    Object.defineProperty(VideoPopupComponent.prototype, "totalDuration", {
        get: function () {
            return this.duration + this.currentDuration;
        },
        enumerable: true,
        configurable: true
    });
    VideoPopupComponent.prototype.getYoutubeLink = function () {
        if (!this.youtubeId) {
            return;
        }
        var params = {};
        if (this.autoPlay) {
            params['autoplay'] = '1';
        }
        if (this.startTime) {
            params['start'] = String(this.startTime);
        }
        var httpParams = new HttpParams({ fromObject: params });
        return "https://www.youtube.com/embed/" + this.youtubeId + "?" + httpParams.toString();
    };
    VideoPopupComponent.prototype.sourceElementDelta = function () {
        var x = 0;
        var y = 0;
        if (this.sourceElement) {
            var bounds = this.sourceElement.getBoundingClientRect();
            x = bounds.left + bounds.width / 2 - window.innerWidth / 2;
            y = bounds.top + bounds.height / 2 - window.innerHeight / 2;
        }
        return [x, y];
    };
    VideoPopupComponent.prototype.show = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        // const [x, y] = this.sourceElementDelta();
        var showElements = [
            this.background.nativeElement,
            this.closeElement.nativeElement,
            this.buttonsElement.nativeElement
        ];
        if (this.titleElement) {
            showElements.push(this.titleElement.nativeElement);
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(showElements, 0.6, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        })
            .fromTo(this.root.nativeElement, 0.6, {
            opacity: 0,
            y: window.innerHeight * 0.2
        }, {
            opacity: 1,
            y: 0,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.animating = false;
            if (_this.autoPlay && _this.player) {
                _this.player.play();
            }
        });
    };
    VideoPopupComponent.prototype.close = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        var _a = this.sourceElementDelta(), x = _a[0], y = _a[1];
        var hideElements = [
            this.background.nativeElement,
            this.closeElement.nativeElement,
            this.buttonsElement.nativeElement
        ];
        if (this.titleElement) {
            hideElements.push(this.titleElement.nativeElement);
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(hideElements, 0.6, {
            opacity: 1
        }, {
            opacity: 0,
            ease: Power2.easeOut
        })
            .fromTo(this.root.nativeElement, 0.6, {
            scale: 1,
            opacity: 1,
            x: 0,
            y: 0
        }, {
            scale: 0,
            opacity: 0,
            x: x,
            y: y,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.animating = false;
            _this.popupComponent.close();
        });
    };
    VideoPopupComponent.prototype.miss = function () {
        var _this = this;
        this.dialogService
            .confirm({
            title: 'Close',
            description: 'Are you sure want to close video?'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (confirmed) {
            if (!confirmed) {
                return;
            }
            _this.close();
        });
    };
    VideoPopupComponent.prototype.onLinkClick = function (link) {
        if (link.action == 'close') {
            this.close();
        }
    };
    return VideoPopupComponent;
}());
export { VideoPopupComponent };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { FeatureService } from '@modules/features';
import { CurrentProjectStore } from '@modules/projects';
import { SlackChannelStore, SlackService } from '@modules/slack';
import { SlackJoinPopupController } from '@modules/slack-components';

@Component({
  selector: 'app-customize-help',
  templateUrl: './customize-help.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeHelpComponent implements OnInit, OnDestroy {
  @ViewChild('menu_element') menuElement: ElementRef;

  menuOpened = false;
  blurClickSubscription: Subscription;
  slackChannel: SlackService.SlackChannelResponse;
  slackChannelLoading = false;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private intercomService: IntercomService,
    public slackChannelStore: SlackChannelStore,
    private slackJoinPopupController: SlackJoinPopupController,
    private featureService: FeatureService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadSlack();
  }

  ngOnDestroy(): void {}

  setMenuOpened(menuOpened: boolean) {
    this.menuOpened = menuOpened;
    this.cd.markForCheck();

    setTimeout(() => {
      if (menuOpened) {
        this.initBlurClick();
      } else {
        this.deinitBlurClick();
      }
    }, 0);

    if (menuOpened) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Help.Opened);
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Help.Closed);
    }
  }

  toggleMenuOpened() {
    this.setMenuOpened(!this.menuOpened);
    this.cd.markForCheck();
  }

  openChat() {
    this.intercomService.openChat();
  }

  loadSlack() {
    if (!this.slackChannelStore.isEnabled()) {
      return;
    }

    this.slackChannelLoading = true;
    this.cd.markForCheck();

    timer(3000)
      .pipe(
        switchMap(() => this.slackChannelStore.get$()),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.slackChannel = result;
        this.slackChannelLoading = false;
        this.cd.markForCheck();
      });
  }

  joinSlack() {
    if (!this.slackChannelStore.isEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Personal support with <strong>Slack channel</strong>',
        description: `
          Get the best of Jet Admin support through a personal Slack channel with tailored onboarding
          and direct communication with our team.
        `
      });
      return;
    }

    this.slackJoinPopupController.open().pipe(untilDestroyed(this)).subscribe();
  }

  initBlurClick() {
    this.deinitBlurClick();

    this.blurClickSubscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.menuOpened && !this.menuElement.nativeElement.contains(e.target)) {
          this.setMenuOpened(false);
        }
      });
  }

  deinitBlurClick() {
    if (this.blurClickSubscription) {
      this.blurClickSubscription.unsubscribe();
      this.blurClickSubscription = undefined;
    }
  }

  onItemClicked(name: string) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Help.ItemClicked, {
      Name: name
    });
  }
}

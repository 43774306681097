import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';

import { ModelCreateForm, ModelCreateOptions } from './model-create.form';

@Component({
  selector: 'app-model-create',
  templateUrl: './model-create.component.html',
  providers: [ModelCreateForm]
})
export class ModelCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() resource: Resource;
  @Input() options: ModelCreateOptions = {};
  @Output() cancelled = new EventEmitter<void>();
  @Output() created = new EventEmitter<ModelDescription>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    public form: ModelCreateForm,
    @Optional() private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.form.init(this.resource, this.options);
  }

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        model => {
          this.created.emit(model);
          this.close();
        },
        error => {
          console.error(error);
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.cancelled.emit();

    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

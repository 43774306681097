<div class="token-popup-section">
  <div class="token-popup-section__title">
    <div class="token-popup-section__title-main">{{ title }}</div>
  </div>
  <div class="token-popup-section__content">
    <app-input-tokens-list-item
      *ngFor="let item of items"
      [item]="item"
      [tokenValues]="tokenValues"
      (clickItem)="clickItem.emit($event)"
    ></app-input-tokens-list-item>
  </div>
</div>

import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { colors } from '@modules/colors';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { controlValue, fileToBase64, isSet } from '@shared';
export function getLogoControlsUrl(options) {
    return combineLatest(controlValue(options.urlControl), options.fileControl ? controlValue(options.fileControl) : of(undefined), options.optionControl ? controlValue(options.optionControl) : of(undefined)).pipe(switchMap(function (_a) {
        var url = _a[0], file = _a[1], option = _a[2];
        if (file) {
            return fileToBase64(file).pipe(map(function (value) { return ({ url: value }); }));
        }
        else if (isSet(url)) {
            return of({
                url: url
            });
        }
        else if (isSet(option) && options.options) {
            var logoOption = options.options.find(function (item) { return item.value == option; });
            if (logoOption) {
                return of({
                    url: logoOption.data.url,
                    initials: logoOption.data.initials
                });
            }
        }
        return of(undefined);
    }));
}
var LogoUploaderComponent = /** @class */ (function () {
    function LogoUploaderComponent(currentProjectStore, currentEnvironmentStore, projectStorageService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectStorageService = projectStorageService;
        this.cd = cd;
        this.logoOptions = [];
        this.colors = ['teal', 'green', 'red', 'black'].map(function (item) {
            return {
                value: item,
                color: item
            };
        });
        this.iconPopoverOpened = false;
        this.uploading = false;
    }
    LogoUploaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        getLogoControlsUrl({
            urlControl: this.logoUrlControl,
            fileControl: this.logoFileControl,
            optionControl: this.logoOptionControl,
            options: this.logoOptions
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.logoUrl = value ? value.url : undefined;
            _this.logoInitials = value ? value.initials : undefined;
            _this.cd.markForCheck();
        });
        controlValue(this.colorControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            var color = value ? colors.find(function (item) { return item.name == value; }) : undefined;
            _this.colorDisplay = color ? color.label : undefined;
            _this.cd.markForCheck();
        });
        this.logoFillControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        if (this.borderRadiusControl) {
            this.borderRadiusControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        }
    };
    LogoUploaderComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(LogoUploaderComponent.prototype, "hasMultipleTypes", {
        get: function () {
            return this.logoOptions.length > 0 || !!this.logoIconControl;
        },
        enumerable: true,
        configurable: true
    });
    LogoUploaderComponent.prototype.isSet = function () {
        return [this.logoUrlControl, this.logoFileControl, this.logoIconControl, this.logoOptionControl].some(function (control) { return control && isSet(control.value); });
    };
    LogoUploaderComponent.prototype.isFillSupported = function () {
        return [this.logoUrlControl, this.logoFileControl, this.logoOptionControl].some(function (control) { return control && isSet(control.value); });
    };
    LogoUploaderComponent.prototype.setValue = function (options) {
        this.logoUrlControl.patchValue(isSet(options.url) ? options.url : null);
        if (this.logoFileControl) {
            this.logoFileControl.patchValue(isSet(options.file) ? options.file : null);
        }
        if (this.logoIconControl) {
            this.logoIconControl.patchValue(isSet(options.icon) ? options.icon : null);
        }
        if (this.logoOptionControl) {
            this.logoOptionControl.patchValue(isSet(options.option) ? options.option : null);
        }
    };
    LogoUploaderComponent.prototype.clear = function () {
        this.setValue({});
    };
    LogoUploaderComponent.prototype.onFileChange = function (el) {
        var _this = this;
        if (!el.files || !el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        if (this.logoFileControl) {
            this.setValue({ file: file });
            return;
        }
        var filePath = ['menu_items'].join('/');
        var fileName = file.name;
        this.uploading = true;
        this.cd.markForCheck();
        this.projectStorageService
            .uploadFile(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, file, filePath, fileName)
            .pipe(untilDestroyed(this))
            .subscribe(function (response) {
            if (response.result) {
                _this.uploading = false;
                _this.cd.markForCheck();
                _this.setValue({ url: response.result.uploadedUrl });
            }
        }, function () {
            _this.uploading = false;
            _this.cd.markForCheck();
        });
    };
    LogoUploaderComponent.prototype.onLogoClick = function () {
        if (this.uploading) {
            return;
        }
        if (this.hasMultipleTypes) {
            this.options_menu_trigger.openMenu();
        }
        else {
            this.logoFileElement.nativeElement.click();
        }
    };
    LogoUploaderComponent.prototype.openIconPopover = function (value) {
        this.iconPopoverOpened = value;
        this.cd.markForCheck();
    };
    return LogoUploaderComponent;
}());
export { LogoUploaderComponent };

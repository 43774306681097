import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ChooseResourcesComponent } from './components/choose-resources/choose-resources.component';
import { ChooseTemplateComponent } from './components/choose-template/choose-template.component';
import { ConfigureResourcesComponent } from './components/configure-resources/configure-resources.component';
import { CreateResourcePopupComponent } from './components/create-resource-popup/create-resource-popup.component';
import { TemplatePreviewComponent } from './components/template-preview/template-preview.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    SharedModule,
    AnalyticsModule,
    TipsModule,
    ProjectsComponentsModule,
    UiModule,
    LocalizeModule
  ],
  declarations: [
    ChooseTemplateComponent,
    ChooseResourcesComponent,
    ConfigureResourcesComponent,
    CreateResourcePopupComponent,
    TemplatePreviewComponent
  ],
  exports: [
    ChooseTemplateComponent,
    ChooseResourcesComponent,
    ConfigureResourcesComponent,
    CreateResourcePopupComponent
  ],
  entryComponents: [
    ChooseTemplateComponent,
    ChooseResourcesComponent,
    ConfigureResourcesComponent,
    CreateResourcePopupComponent,
    TemplatePreviewComponent
  ]
})
export class TemplateComponentsModule {}

import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-compact-font-control',
  templateUrl: './compact-font-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactFontControlComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() placeholder = 'Choose Font';

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() size = 18;
  @Input('activeColor') overrideActiveColor: string;
  @Input('normalColor') overrideNormalColor: string;
  @Input() leftActive = true;
  @Input() rightActive = true;

  defaultActiveColor = '#eab130';
  activeColor = this.defaultActiveColor;
  defaultNormalColor = '#e1e5e8';
  normalColor = this.defaultNormalColor;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<StarComponent>): void {
    if (changes.overrideActiveColor) {
      this.activeColor = this.overrideActiveColor || this.defaultActiveColor;
      this.normalColor = this.overrideNormalColor || this.defaultNormalColor;
    }
  }
}

<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner popover2__inner_sidebar">
    <div class="popover2__viewport popover2__viewport_fit-screen">
      <div class="popover2__content popover2__content_padding_none">
        <div class="menu-item-edit-popover">
          <app-edit-menu-item-action
            [label]="'When item clicked'"
            [control]="control.controls.action"
            [context]="context"
            [analyticsSource]="analyticsSource"
            (selectPage)="onPageSelected($event.page, $event.finished)"
            (selectSystem)="onSystemSelected($event)"
          ></app-edit-menu-item-action>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'color'" [required]="false">
              <app-auto-field
                [form]="control"
                [label]="false"
                [field]="
                  createField({
                    name: 'tint',
                    field: 'ColorField',
                    params: {
                      classes: ['select_fill'],
                      allow_empty: true,
                      empty_accent_color: true,
                      custom_colors: true
                    }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'style'">
              <div class="sidebar-image-toggle sidebar-image-toggle_cols_4">
                <div *ngFor="let item of control.styleOptions" class="sidebar-image-toggle__col">
                  <a
                    href="javascript:void(0)"
                    class="sidebar-image-toggle__item"
                    [class.sidebar-image-toggle__item_active]="item.value === control.controls.style.value"
                    (click)="control.controls.style.patchValue(item.value)"
                  >
                    <div
                      class="sidebar-image-toggle__item-inner"
                      [style.background-image]="'/assets/images/buttons/' + item.image + '.svg' | appDeployCssUrl"
                    ></div>
                  </a>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="analyticsSource + '_visible'"
            >
              <app-input-edit
                [itemForm]="control.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="analyticsSource + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: user.region == 'Europe'
              </ng-container>
            </app-sidebar-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { controlValue } from '@shared';

import { GoogleSheetsFileControl, GoogleSheetsResourceSettingsForm } from '../google-sheets-resource-settings.form';

@Component({
  selector: 'app-google-sheets-resource-settings-files',
  templateUrl: './google-sheets-resource-settings-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSheetsResourceSettingsFilesComponent implements OnInit, OnDestroy {
  @Input() form: GoogleSheetsResourceSettingsForm;
  @Output() back = new EventEmitter<void>();

  fileUpdating = false;
  selectedFile: GoogleSheetsFileControl;
  accessToken: string;

  constructor(private http: HttpClient, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue<string>(this.form.form.controls['access_token'])
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.accessToken = value;
        this.cd.markForCheck();
      });

    this.form.form.controls.files.valueChanges.pipe(untilDestroyed(this)).subscribe(value => this.cd.markForCheck());

    this.selectedFile = this.form.form.controls.files.controls[0];
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  setSelectedFile(control: GoogleSheetsFileControl) {
    // TODO: File reset workaround
    this.selectedFile = undefined;
    this.cd.detectChanges();

    this.selectedFile = control;
    this.cd.markForCheck();
  }

  addFile(value = {}) {
    const control = this.form.createItem(value);
    this.form.itemsAppend(control);
    this.setSelectedFile(control);
  }

  deleteFile(control: GoogleSheetsFileControl) {
    const index = this.form.form.controls.files.controls.findIndex(item => item === control);
    if (index === -1) {
      return;
    }

    this.form.itemsRemove(control);

    if (this.selectedFile === control) {
      const newIndex = index > 0 ? index - 1 : 0;
      this.selectedFile = this.form.form.controls.files.controls[newIndex];
      this.cd.markForCheck();
    }
  }

  setFileUpdating(value: boolean) {
    this.fileUpdating = value;
    this.cd.markForCheck();
  }
}

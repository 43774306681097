import isEqual from 'lodash/isEqual';
import { combineLatest, of } from 'rxjs';
import { map as rxMap } from 'rxjs/internal/operators/map';
import { delayWhen, tap } from 'rxjs/operators';
import { CustomizeService, CustomizeType, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { DashboardService, DashboardStore } from '@modules/dashboard';
import { ButtonMenuItem, MenuSettingsService, MenuSettingsStore, ModelLinkMenuItem, SectionMenuItem, SimpleMenuItem } from '@modules/menu';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var MenuCustomizeService = /** @class */ (function () {
    function MenuCustomizeService(modelDescriptionStore, modelDescriptionService, menuSettingsService, menuSettingsStore, customizeService, currentProjectStore, currentEnvironmentStore, dashboardStore, viewSettingsService, viewSettingsStore, dashboardService) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelDescriptionService = modelDescriptionService;
        this.menuSettingsService = menuSettingsService;
        this.menuSettingsStore = menuSettingsStore;
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.dashboardStore = dashboardStore;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.dashboardService = dashboardService;
    }
    MenuCustomizeService.prototype.getChanges = function (prevInstance, currentInstance) {
        return combineLatest(this.modelDescriptionStore.getFirst(), this.viewSettingsStore.getFirst()).pipe(rxMap(function (_a) {
            var models = _a[0], pages = _a[1];
            var settingsHasChanges = !isEqual(currentInstance.serialize(['items']), prevInstance.serialize(['items']));
            var changedModels = currentInstance
                .getAllItems()
                .reduce(function (acc, item) {
                if (item instanceof SectionMenuItem) {
                    acc.push.apply(acc, item.children);
                }
                else {
                    acc.push(item);
                }
                return acc;
            }, [])
                .filter(function (item) { return item instanceof ModelLinkMenuItem; })
                .filter(function (item) { return item.model; })
                .reduce(function (acc, item) {
                // const model = models ? models.find(i => i.isSame(item.model)) : undefined;
                //
                // if (model && model.verboseNamePlural != item.title && isSet(item.title)) {
                //   const newInstance: ModelDescription = cloneDeep(model);
                //
                //   if (/^[A-Za-z0-9]*$/.test(item.title)) {
                //     newInstance.verboseName = inflection.singularize(item.title);
                //
                //     if (newInstance.verboseName == item.title) {
                //       newInstance.verboseNamePlural = inflection.pluralize(item.title);
                //     } else {
                //       newInstance.verboseNamePlural = item.title;
                //     }
                //   } else {
                //     newInstance.verboseName = newInstance.verboseNamePlural = item.title;
                //   }
                //
                //   acc.push(newInstance);
                // }
                return acc;
            }, []);
            var changedPages = currentInstance
                .getAllItems()
                .reduce(function (acc, item) {
                if (item instanceof SectionMenuItem) {
                    acc.push.apply(acc, item.children);
                }
                else {
                    acc.push(item);
                }
                return acc;
            }, [])
                .filter(function (item) { return item instanceof SimpleMenuItem || item instanceof ButtonMenuItem; })
                .reduce(function (acc, item) {
                // const page = pages ? pages.find(i => item.isForPage(i)) : undefined;
                //
                // if (page && page.name != item.title && isSet(item.title)) {
                //   const newInstance: ViewSettings = cloneDeep(page);
                //   newInstance.name = item.title;
                //   acc.push(newInstance);
                // }
                return acc;
            }, []);
            return {
                menuSettings: { value: currentInstance, hasChanges: settingsHasChanges },
                changedModels: { value: changedModels, hasChanges: changedModels.length > 0 },
                changedDashboards: { value: [], hasChanges: false },
                deletedDashboards: { value: [], hasChanges: false },
                changedPages: { value: changedPages, hasChanges: changedPages.length > 0 }
            };
        }));
    };
    MenuCustomizeService.prototype.saveChanges = function (changes) {
        var _this = this;
        // return this.getChanges().pipe(
        //   switchMap(changes => {
        var requests = (changes.menuSettings.hasChanges
            ? [
                this.menuSettingsService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, changes.menuSettings.value)
            ]
            : []).concat(changes.changedModels.value.map(function (item) {
            return _this.modelDescriptionService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item);
        }), changes.changedDashboards.value.map(function (item) {
            return _this.dashboardService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item);
        }), changes.deletedDashboards.value.map(function (item) {
            return _this.dashboardService.delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item);
        }), changes.changedPages.value.map(function (item) {
            return _this.viewSettingsService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, item);
        }));
        if (!requests.length) {
            return of({});
        }
        return combineLatest.apply(void 0, requests).pipe(delayWhen(function () {
            var obs = [];
            if (changes.changedModels.hasChanges) {
                obs.push(_this.modelDescriptionStore.getFirst(true));
            }
            if (changes.changedDashboards.hasChanges) {
                obs.push(_this.dashboardStore.getFirst(true));
            }
            if (changes.changedPages.hasChanges) {
                obs.push(_this.viewSettingsStore.getFirst(true));
            }
            return obs.length ? combineLatest(obs) : of({});
        }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }), tap(function () {
            _this.customizeService.enabled = CustomizeType.Layout;
        }), rxMap(function () {
            return {};
        }));
    };
    return MenuCustomizeService;
}());
export { MenuCustomizeService };

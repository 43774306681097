var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import keys from 'lodash/keys';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.value).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
var ActionOutputFormGroup = /** @class */ (function (_super) {
    __extends(ActionOutputFormGroup, _super);
    function ActionOutputFormGroup(elementConfigurationService) {
        var _this = _super.call(this, {}) || this;
        _this.elementConfigurationService = elementConfigurationService;
        return _this;
    }
    ActionOutputFormGroup.prototype.deserialize = function (outputs, actions) {
        var _this = this;
        keys(this.controls)
            .filter(function (name) { return !outputs.some(function (item) { return item.name == name; }); })
            .forEach(function (name) { return _this.removeControl(name); });
        outputs.forEach(function (output) {
            var actionsItem = actions.find(function (item) { return item.name == output.name; });
            var action = actionsItem ? actionsItem.action : undefined;
            if (_this.contains(output.name)) {
                _this.controls[output.name].patchValue(action);
            }
            else {
                var control = new FormControl(action, undefined, validateAction);
                _this.setControl(output.name, control);
            }
        });
    };
    ActionOutputFormGroup.prototype.serialize = function () {
        var _this = this;
        return keys(this.controls)
            .map(function (name) {
            var control = _this.controls[name];
            return { name: name, action: control ? control.value : undefined };
        })
            .filter(function (item) { return item.action; });
    };
    return ActionOutputFormGroup;
}(FormGroup));
export { ActionOutputFormGroup };

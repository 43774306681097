<div class="sidebar">
  <div class="sidebar__scrollable" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }" #root>
    <div *ngFor="let item of settingsComponents; let last = last" [hidden]="!last">
      <app-dynamic-component [componentData]="item"></app-dynamic-component>
    </div>
    <ng-container *ngIf="!settingsComponents.length">
      <app-customize-bar-header [title]="'Add Components'" [subtitle]="'Dashboard'"> </app-customize-bar-header>

      <div class="sidebar__content">
        <div class="sidebar__list">
          <div class="sidebar-list">
            <div *ngFor="let item of items" class="sidebar-list__item sidebar-list__item_half">
              <div class="sidebar-image-card" (click)="add(item)">
                <div class="sidebar-image-card__title">
                  <div class="sidebar-image-card__line">{{ item.context.heading }}</div>
                </div>
                <div
                  class="sidebar-image-card__image"
                  [ngClass]="['sidebar-image-card__image_type_' + item.context.icon]"
                ></div>
                <div class="sidebar-image-card__bottom">
                  <span
                    class="sidebar-image-card__action sidebar-image-card__action_button sidebar-image-card__bottom-right icon-plus"
                  ></span>
                  <div class="sidebar-image-card__line">Add Chart</div>
                </div>
              </div>
            </div>
            <div class="sidebar-list__item sidebar-list__item_half">
              <a
                [appLink]="(currentProjectStore.instance$ | async)?.settingsCustomViewsLink"
                class="sidebar-image-card"
              >
                <div class="sidebar-image-card__title">
                  <div class="sidebar-image-card__line">FlexView</div>
                </div>
                <div class="sidebar-image-card__image sidebar-image-card__image_type_flex-view"></div>
                <div class="sidebar-image-card__bottom">
                  <span
                    class="sidebar-image-card__action sidebar-image-card__action_button sidebar-image-card__action_flex sidebar-image-card__bottom-right icon-plus"
                  ></span>
                  <div class="sidebar-image-card__line">Add Create</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

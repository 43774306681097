<div class="view-editor-sidebar" [class.view-editor-sidebar_hidden]="settingsComponents.length">
  <div class="compact-inputs-section">
    <!--  <div class="compact-inputs-element">-->
    <!--    <div class="compact-inputs-group">-->
    <!--      <div class="compact-inputs-group__item">-->
    <!--        <app-compact-input-->
    <!--          [control]="form.controls.frame.controls.x"-->
    <!--          [postfix]="'X'"-->
    <!--          [number]="true"-->
    <!--        ></app-compact-input>-->
    <!--      </div>-->

    <!--      <div class="compact-inputs-group__item">-->
    <!--        <app-compact-input-->
    <!--          [control]="form.controls.frame.controls.y"-->
    <!--          [postfix]="'Y'"-->
    <!--          [number]="true"-->
    <!--        ></app-compact-input>-->
    <!--      </div>-->

    <!--      <div class="compact-inputs-group__item"></div>-->
    <!--    </div>-->
    <!--  </div>-->

    <div class="compact-inputs-element">
      <div class="compact-inputs-group">
        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.width"
            [postfix]="'W'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateHeightProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <app-compact-input
            [formControl]="form.controls.frame.controls.height"
            [postfix]="'H'"
            [number]="true"
            [numberOptions]="{ min: 0 }"
            (change)="form.updateWidthProportional()"
          ></app-compact-input>
        </div>

        <div class="compact-inputs-group__item">
          <div
            class="compact-button"
            [class.compact-button_primary]="form.controls.constrain_proportion.value"
            [class.compact-button_hover]="form.controls.constrain_proportion.value"
            [class.icon-lock_open]="!form.controls.constrain_proportion.value"
            [class.icon-lock_close]="form.controls.constrain_proportion.value"
            (click)="form.toggleConstrainProportion()"
            [appTip]="'Constrain proportions'"
            [appTipOptions]="{ side: 'top' }"
          ></div>
        </div>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-heading">
        Width resizing
      </div>

      <div class="compact-inputs-row compact-inputs-row_vertical-align_top">
        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.width_resize.controls.enabled"
            [options]="resizeEnabledOptions"
          ></app-compact-segmented-control>
        </div>

        <ng-container *ngIf="form.controls.width_resize.controls.enabled.value">
          <div class="compact-inputs-row__item compact-inputs-row__item_fill">
            <app-compact-input
              [formControl]="form.controls.width_resize.controls.min"
              [prefix]="'min'"
              [number]="true"
              [numberOptions]="{
                min: 0,
                max:
                  (form.controls.width_resize.controls.max.value | appIsSet)
                    ? form.controls.width_resize.controls.max.value
                    : undefined
              }"
              [numberDefault]="form.controls.frame.controls.width.value"
            ></app-compact-input>
          </div>

          <div class="compact-inputs-row__item compact-inputs-row__item_fill">
            <app-compact-input
              [formControl]="form.controls.width_resize.controls.max"
              [prefix]="'max'"
              [number]="true"
              [numberOptions]="{
                min:
                  (form.controls.width_resize.controls.min.value | appIsSet)
                    ? form.controls.width_resize.controls.min.value
                    : 0
              }"
              [numberDefault]="form.controls.frame.controls.width.value"
            ></app-compact-input>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-heading">
        Height resizing
      </div>

      <div class="compact-inputs-row compact-inputs-row_vertical-align_top">
        <div class="compact-inputs-row__item">
          <app-compact-segmented-control
            [formControl]="form.controls.height_resize.controls.enabled"
            [options]="resizeEnabledOptions"
          ></app-compact-segmented-control>
        </div>

        <ng-container *ngIf="form.controls.height_resize.controls.enabled.value">
          <div class="compact-inputs-row__item compact-inputs-row__item_fill">
            <app-compact-input
              [formControl]="form.controls.height_resize.controls.min"
              [prefix]="'min'"
              [number]="true"
              [numberOptions]="{
                min: 0,
                max:
                  (form.controls.height_resize.controls.max.value | appIsSet)
                    ? form.controls.height_resize.controls.max.value
                    : undefined
              }"
              [numberDefault]="form.controls.frame.controls.height.value"
            ></app-compact-input>
          </div>

          <div class="compact-inputs-row__item compact-inputs-row__item_fill">
            <app-compact-input
              [formControl]="form.controls.height_resize.controls.max"
              [prefix]="'max'"
              [number]="true"
              [numberOptions]="{
                min:
                  (form.controls.height_resize.controls.min.value | appIsSet)
                    ? form.controls.height_resize.controls.min.value
                    : 0
              }"
              [numberDefault]="form.controls.frame.controls.height.value"
            ></app-compact-input>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="compact-inputs-element">
      <app-compact-checkbox
        [formControl]="form.controls.resize_content"
        [label]="'Adjust content on canvas resize'"
      ></app-compact-checkbox>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-element">
      <app-opacity-control [formControl]="form.controls.opacity"></app-opacity-control>
    </div>

    <div class="compact-inputs-element">
      <div class="compact-heading">
        Corner radius
      </div>

      <app-corner-radius-control [formControl]="form.controls.corner_radius"></app-corner-radius-control>
    </div>

    <div class="compact-inputs-element">
      <app-compact-checkbox [formControl]="form.controls.clip_content" [label]="'Clip content'"></app-compact-checkbox>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Fills</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.fills.controls.length"
          (click)="form.controls.fills.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="fillsDragDrop($event)">
      <div
        *ngFor="let control of form.controls.fills.controls"
        class="compact-inputs-element"
        cdkDrag
        [cdkDragDisabled]="!!fillControlOpened"
      >
        <app-fill-control
          [control]="control"
          [viewContext]="viewContext"
          [view]="view"
          (colorOpenedChange)="updateFillControlOpened()"
          (remove)="form.controls.fills.removeControl(control)"
        ></app-fill-control>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Borders</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.borders.controls.length"
          (click)="form.controls.borders.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="bordersDragDrop($event)">
      <div *ngFor="let control of form.controls.borders.controls" class="compact-inputs-element" cdkDrag>
        <app-border-control
          [control]="control"
          [viewContext]="viewContext"
          (remove)="form.controls.borders.removeControl(control)"
        ></app-border-control>
      </div>
    </div>
  </div>

  <div class="compact-inputs-section">
    <div class="compact-inputs-section__header">
      <div class="compact-inputs-section__header-main">
        <div class="compact-inputs-section__header-title">Shadows</div>
      </div>
      <div class="compact-inputs-section__header-right">
        <div
          class="compact-button icon-plus"
          [class.compact-button_primary]="!form.controls.shadows.controls.length"
          (click)="form.controls.shadows.prependControl()"
        ></div>
      </div>
    </div>

    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="shadowsDragDrop($event)">
      <div *ngFor="let control of form.controls.shadows.controls" class="compact-inputs-element" cdkDrag>
        <app-shadow-control
          [control]="control"
          [viewContext]="viewContext"
          (remove)="form.controls.shadows.removeControl(control)"
        ></app-shadow-control>
      </div>
    </div>
  </div>

  <app-interactions-section
    [control]="form.controls.interactions"
    [viewContext]="viewContext"
  ></app-interactions-section>
</div>

<div
  *ngFor="let item of settingsComponents; let last = last"
  class="view-editor-sidebar view-editor-sidebar_dark view-editor-sidebar_expanded"
  [class.view-editor-sidebar_hidden]="!last"
>
  <app-dynamic-component [componentData]="item"></app-dynamic-component>
</div>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, BehaviorSubject, combineLatest, of } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, map, switchMap, throttleTime } from 'rxjs/operators';
import { localize } from '@common/localize';
import { CustomViewSettings, elementItemCategories, ElementType, getTokenSubtitle, ViewContext } from '@modules/customize';
import { detectFieldByValue, FieldType, getFieldDescriptionByType, JsonStructureNodeType } from '@modules/fields';
import { contextToFormulaValue, transformFormulaElementAccessors } from '@modules/parameters';
import { ProjectGroupStore } from '@modules/projects';
import { ascComparator, EMPTY, forceObservable, isSet, objectGet } from '@shared';
import { FormulaCategory, viewContextTokenProviderFunctions } from './view-context-token-provider-functions.stub';
function sectionItemAscComparator(lhs, rhs) {
    return ascComparator(lhs.section ? String(lhs.section.label).toLowerCase() : String(lhs.item.label).toLowerCase(), rhs.section ? String(rhs.section.label).toLowerCase() : String(rhs.item.label).toLowerCase());
}
var ViewContextTokenProvider = /** @class */ (function () {
    function ViewContextTokenProvider(context, projectGroupStore) {
        this.context = context;
        this.projectGroupStore = projectGroupStore;
    }
    ViewContextTokenProvider.prototype.ngOnDestroy = function () { };
    ViewContextTokenProvider.prototype.ensureSectionsObserving = function () {
        var _this = this;
        if (!this.sections$) {
            this.sections$ = new BehaviorSubject(undefined);
            this.getCurrentSections$()
                .pipe(untilDestroyed(this))
                .subscribe(function (value) { return _this.sections$.next(value); });
        }
    };
    ViewContextTokenProvider.prototype.getSections$ = function () {
        this.ensureSectionsObserving();
        return this.sections$.pipe(filter(function (item) { return item !== undefined; }));
    };
    ViewContextTokenProvider.prototype.getCurrentSections$ = function () {
        var _this = this;
        return this.context.getOutputTokens$(true).pipe(auditTime(60), switchMap(function (contextTokens) {
            var obs$ = [
                _this.getStateSection(contextTokens),
                _this.getWorkflowSection(contextTokens),
                _this.getWorkflowStepsSection(contextTokens),
                _this.getComponentParametersSection(contextTokens),
                _this.getElementsSection(contextTokens),
                _this.getPageSection(contextTokens),
                _this.getQueriesSection(contextTokens),
                _this.getPopupSection(contextTokens),
                _this.getRecordSection(contextTokens),
                _this.getUserSection(contextTokens),
                _this.getTeamSection(contextTokens)
            ].concat(_this.getPropertiesSections(contextTokens), [
                _this.getFunctionsSection()
            ]).map(function (value) { return forceObservable(value); });
            return combineLatest(obs$);
        }), map(function (sections) {
            return sections
                .filter(function (item) { return item; })
                .filter(function (item) { return item.alwaysVisible || (!item.alwaysVisible && item.items.length); });
        }));
    };
    ViewContextTokenProvider.prototype.getContextElementSection = function (contextElement, contextElementPath, contextElementPaths) {
        var _this = this;
        if (!contextElement) {
            return of(undefined);
        }
        return this.context.getElementTokens$(contextElement, contextElementPath, contextElementPaths).pipe(map(function (contextElementTokens) {
            return {
                label: "<strong>" + contextElement.name + "</strong> - Current Component",
                icon: contextElement.icon || 'components',
                orange: true,
                items: _this.mapElementTokens(contextElementTokens, ['item'], { contextElement: contextElement })
            };
        }));
    };
    ViewContextTokenProvider.prototype.getWorkflowSection = function (contextTokens) {
        var workflowToken = contextTokens.find(function (item) { return item.uniqueName == 'workflow'; });
        if (!workflowToken) {
            return;
        }
        return {
            label: 'Workflow parameters',
            icon: 'input',
            alwaysVisible: true,
            items: this.mapElementTokens(workflowToken.children, ['workflow'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getComponentParametersSection = function (contextTokens) {
        var componentToken = contextTokens.find(function (item) { return item.uniqueName == 'component'; });
        if (!componentToken) {
            return;
        }
        return {
            label: 'Component parameters',
            icon: 'input',
            alwaysVisible: true,
            items: this.mapElementTokens(componentToken.children, ['component'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getStateSection = function (contextTokens) {
        var stateToken = contextTokens.find(function (item) { return item.uniqueName == 'state'; });
        if (!stateToken) {
            return;
        }
        return {
            label: 'State',
            icon: 'select',
            alwaysVisible: true,
            items: this.mapElementTokens(stateToken.children, ['state'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getWorkflowStepsSection = function (contextTokens) {
        var workflowStepsToken = contextTokens.find(function (item) { return item.uniqueName == 'steps'; });
        if (!workflowStepsToken) {
            return;
        }
        var items = workflowStepsToken.children.filter(function (item) { return item.children && item.children.length; });
        if (!items.length) {
            return;
        }
        return {
            label: 'Workflow steps',
            icon: 'workflow',
            alwaysVisible: true,
            items: this.mapElementTokens(items, ['steps'], { sort: true, ignoreContextElement: true })
        };
    };
    ViewContextTokenProvider.prototype.getElementsSection = function (contextTokens) {
        var elementsToken = contextTokens.find(function (item) { return item.uniqueName == 'elements'; });
        if (!elementsToken) {
            return;
        }
        var elementItemCategoriesValues = [
            elementItemCategories[ElementType.List]
        ].concat((this.context.viewSettings instanceof CustomViewSettings
            ? this.context.viewSettings.popups.map(function (item) { return item.name; })
            : []), [
            elementItemCategories[ElementType.Field],
            elementItemCategories[ElementType.Custom],
            elementItemCategories[ElementType.Form],
            elementItemCategories[ElementType.Action],
            elementItemCategories[ElementType.Model],
            elementItemCategories[ElementType.Widget]
        ]);
        return {
            label: 'Other Components',
            icon: 'components',
            alwaysVisible: true,
            items: this.mapElementTokens(elementsToken.children, ['components'], {
                sort: false,
                ignoreContextElement: true
            }).sort(function (lhs, rhs) {
                if (isSet(lhs.subtitle) && isSet(rhs.subtitle)) {
                    var lhsIndex = elementItemCategoriesValues.indexOf(lhs.subtitle);
                    var rhsIndex = elementItemCategoriesValues.indexOf(rhs.subtitle);
                    if (lhsIndex !== rhsIndex) {
                        return lhsIndex - rhsIndex;
                    }
                    else {
                        return sectionItemAscComparator(lhs, rhs);
                    }
                }
                else if (isSet(lhs.subtitle) && !isSet(rhs.subtitle)) {
                    return -1;
                }
                else if (!isSet(lhs.subtitle) && isSet(rhs.subtitle)) {
                    return 1;
                }
                else {
                    return sectionItemAscComparator(lhs, rhs);
                }
            })
        };
    };
    ViewContextTokenProvider.prototype.getPageSection = function (contextTokens) {
        var pageToken = contextTokens.find(function (item) { return item.uniqueName == 'page'; });
        if (!pageToken) {
            return;
        }
        return {
            label: 'Page Inputs',
            icon: 'input',
            alwaysVisible: true,
            items: this.mapElementTokens(pageToken.children, ['page'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getQueriesSection = function (contextTokens) {
        var queriesToken = contextTokens.find(function (item) { return item.uniqueName == 'queries'; });
        if (!queriesToken) {
            return;
        }
        return {
            label: 'Page Queries',
            icon: 'cloud_download',
            alwaysVisible: true,
            items: this.mapElementTokens(queriesToken.children, ['queries'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getPopupSection = function (contextTokens) {
        var popupsToken = contextTokens.find(function (item) { return item.uniqueName == 'modals'; });
        if (!popupsToken) {
            return;
        }
        var items = popupsToken.children.filter(function (item) { return item.children && item.children.length; });
        if (items.length) {
            return {
                label: 'Overlay inputs',
                icon: 'cloud_download',
                alwaysVisible: true,
                items: this.mapElementTokens(items, ['modals'], { sort: true })
            };
        }
    };
    ViewContextTokenProvider.prototype.getRecordSection = function (contextTokens) {
        var recordToken = contextTokens.find(function (item) { return item.uniqueName == 'record'; });
        if (!recordToken) {
            return;
        }
        return {
            label: 'Record',
            icon: 'document',
            items: this.mapElementTokens(recordToken.children, ['record'], { sort: true })
        };
    };
    ViewContextTokenProvider.prototype.getUserSection = function (contextTokens) {
        var userToken = contextTokens.find(function (item) { return item.uniqueName == 'user'; });
        var userPropertiesToken = contextTokens.find(function (item) { return item.uniqueName == 'user_properties'; });
        if (!userToken && !userPropertiesToken) {
            return;
        }
        var items = (userPropertiesToken ? userPropertiesToken.children : []).concat((userToken ? userToken.children : []));
        return {
            label: 'User Properties',
            icon: 'user',
            alwaysVisible: true,
            action: userPropertiesToken.action,
            documentation: userPropertiesToken.documentation,
            items: this.mapElementTokens(items, ['user'], { sort: false })
        };
    };
    ViewContextTokenProvider.prototype.getTeamSection = function (contextTokens) {
        var _this = this;
        return this.projectGroupStore.get().pipe(map(function (groups) {
            groups = groups || [];
            var teamToken = contextTokens.find(function (item) { return item.uniqueName == 'group'; });
            var teamPropertiesToken = contextTokens.find(function (item) { return item.uniqueName == 'team_properties'; });
            if (!teamToken && !teamPropertiesToken) {
                return;
            }
            var items = (teamPropertiesToken ? teamPropertiesToken.children : []).concat((teamToken ? teamToken.children : []));
            var projectGroups = groups.filter(function (item) { return !item.permissionsGroup; });
            var builtInGroups = groups.filter(function (item) { return item.permissionsGroup; });
            return {
                label: 'Team Properties',
                icon: 'users_teams',
                alwaysVisible: true,
                action: teamPropertiesToken.action,
                documentation: teamPropertiesToken.documentation,
                items: _this.mapElementTokens(items, ['group'], { sort: false }).concat([
                    {
                        path: ['group', 'teams'],
                        section: {
                            label: 'Is member of a team',
                            icon: 'human_being',
                            items: projectGroups.map(function (group) {
                                return {
                                    path: ['group', 'teams', group.uid],
                                    item: {
                                        token: [''],
                                        label: group.name,
                                        icon: 'users_teams',
                                        formula: "group.uid == \"" + group.uid + "\""
                                    },
                                    subtitle: localize('App Teams')
                                };
                            }).concat(builtInGroups.map(function (group) {
                                return {
                                    path: ['group', 'teams', group.uid],
                                    item: {
                                        token: [''],
                                        label: group.name,
                                        labelAdditional: group.getDescription(),
                                        icon: group.getIcon(),
                                        formula: "group.uid == \"" + group.uid + "\""
                                    },
                                    subtitle: projectGroups.length ? localize('Built-In Teams') : undefined
                                };
                            }))
                        }
                    }
                ])
            };
        }));
    };
    ViewContextTokenProvider.prototype.getPropertiesSections = function (contextTokens) {
        var result = [];
        var globalPropertiesToken = contextTokens.find(function (item) { return item.uniqueName == 'global_variables'; });
        var pagePropertiesToken = contextTokens.find(function (item) { return item.uniqueName == 'page_variables'; });
        var appPropertiesToken = contextTokens.find(function (item) { return item.uniqueName == 'app'; });
        var appDeviceToken = contextTokens.find(function (item) { return item.uniqueName == 'device'; });
        if (globalPropertiesToken) {
            result.push({
                label: 'Global Variables',
                icon: 'variable',
                alwaysVisible: true,
                hideTab: true,
                action: globalPropertiesToken.action,
                documentation: globalPropertiesToken.documentation,
                items: this.mapElementTokens(globalPropertiesToken.children, ['global_variables'], { sort: false })
            });
        }
        if (pagePropertiesToken) {
            result.push({
                label: 'Page Variables',
                icon: 'variable',
                alwaysVisible: true,
                hideTab: true,
                action: pagePropertiesToken.action,
                documentation: pagePropertiesToken.documentation,
                items: this.mapElementTokens(pagePropertiesToken.children, ['page_variables'], { sort: false })
            });
        }
        if (appPropertiesToken) {
            result.push({
                label: 'Application',
                hideTab: true,
                items: this.mapElementTokens(appPropertiesToken.children, ['app'], { sort: false })
            });
        }
        if (appDeviceToken) {
            var viewportTypeNames_1 = ['is_desktop', 'is_mobile', 'is_phone', 'is_tablet'];
            var viewportTypesItems = appDeviceToken.children.filter(function (item) { return viewportTypeNames_1.includes(item.uniqueName); });
            var otherItems = appDeviceToken.children.filter(function (item) { return !viewportTypeNames_1.includes(item.uniqueName); });
            result.push({
                label: 'Device',
                hideTab: true,
                items: [
                    {
                        path: ['device', 'viewport_types'],
                        section: {
                            label: 'Is Desktop/Mobile/Tablet/Phone',
                            icon: 'pages',
                            items: this.mapElementTokens(viewportTypesItems, ['device', 'viewport_types'], { sort: false })
                        }
                    }
                ].concat(this.mapElementTokens(otherItems, ['device'], { sort: false }))
            });
        }
        return result;
    };
    ViewContextTokenProvider.prototype.getFunctionsSection = function () {
        return {
            name: 'functions',
            label: 'Functions',
            icon: 'function',
            items: viewContextTokenProviderFunctions.map(function (item) {
                var _a;
                var formulaCategoryLabels = (_a = {},
                    _a[FormulaCategory.General] = 'General functions',
                    _a[FormulaCategory.Logic] = 'Logical functions',
                    _a[FormulaCategory.Math] = 'Mathematical functions',
                    _a[FormulaCategory.DateTime] = 'Date & Time Functions',
                    _a);
                return {
                    path: ['functions', item.function.name],
                    item: {
                        token: [item.function.name],
                        label: item.function.name + "()",
                        type: 'function',
                        insert: [item.function.name],
                        caretIndex: item.function.arguments.length ? item.function.name.length + 1 : item.function.name.length + 2,
                        insertSelection: true,
                        description: item.description,
                        function: item.function
                    },
                    subtitle: formulaCategoryLabels[item.category]
                };
            })
        };
    };
    ViewContextTokenProvider.prototype.mapElementTokens = function (tokens, path, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var depth = options.depth || 0;
        var result = tokens
            .filter(function (item) { return item.token || item.children; })
            .map(function (item) {
            var mapSection = function (i, itemPath, override) {
                if (override === void 0) { override = {}; }
                var tokenPath = itemPath.concat([i.uniqueName]);
                return __assign({ path: tokenPath, section: __assign({ token: i.token, insert: i.token, 
                        // caretIndex: token ? token.length : undefined,
                        label: i.name, icon: i.icon, allowSkip: i.allowSkip, action: i.action, documentation: i.documentation, items: _this.mapElementTokens(i.children, tokenPath, __assign({}, options, { sort: false, ignoreContextElement: undefined, depth: depth + 1 })) }, override), subtitle: getTokenSubtitle(i.element) }, (options.ignoreContextElement && {
                    data: {
                        ignoreContextElement: item.element
                    }
                }));
            };
            var mapItem = function (i, itemPath, override) {
                if (override === void 0) { override = {}; }
                var tokenPath = itemPath.concat([i.uniqueName]);
                var tokenValue$ = _this.getTokenValue(i, options.contextElement);
                return __assign({ path: tokenPath, item: __assign({ token: i.token, 
                        // label: item.token.split('.').slice(-1).join(''),
                        label: i.name, labelAdditional: i.subtitle, icon: i.icon, iconOrange: i.iconOrange, insert: i.token, fieldType: i.fieldType, fieldParams: i.fieldParams, value: tokenValue$ }, override), subtitle: getTokenSubtitle(i.element) }, (options.ignoreContextElement && {
                    data: {
                        ignoreContextElement: item.element
                    }
                }));
            };
            if (item.children) {
                var singleItem = item.children.length == 1 ? item.children[0] : undefined;
                if (item.allowSkip && singleItem) {
                    if (singleItem.children) {
                        return __assign({}, mapSection(singleItem, path.concat([item.uniqueName]), { label: item.name }), { subtitle: getTokenSubtitle(item.element) });
                    }
                    else if (singleItem.token) {
                        return __assign({}, mapItem(singleItem, path.concat([item.uniqueName]), { label: item.name }), { subtitle: getTokenSubtitle(item.element) });
                    }
                }
                else {
                    return mapSection(item, path);
                }
            }
            else if (item.token) {
                return mapItem(item, path);
            }
        })
            .filter(function (item) {
            if (item.item) {
                return item.item.token;
            }
            else if (item.section) {
                return item.section.allowSkip ? item.section.items.length : true;
            }
            else {
                return true;
            }
        });
        if (options.sort) {
            return result.sort(function (lhs, rhs) {
                var lhsOrange = lhs.section ? lhs.section.orange : lhs.item.orange || lhs.item.iconOrange;
                var rhsOrange = rhs.section ? rhs.section.orange : rhs.item.orange || rhs.item.iconOrange;
                return -10 * ((lhsOrange ? 1 : -1) - (rhsOrange ? 1 : -1)) + sectionItemAscComparator(lhs, rhs);
            });
        }
        else {
            return result;
        }
    };
    ViewContextTokenProvider.prototype.mapJsonToken = function (token, path, tokenLabel, tokenValue, depth, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var fieldType;
        var isTokenArray;
        var isTokenObject;
        if (options.structure && options.structure.type == JsonStructureNodeType.Field) {
            fieldType = options.structure.params.field;
            isTokenArray = false;
            isTokenObject = false;
        }
        else if (options.structure && options.structure.type == JsonStructureNodeType.Object) {
            fieldType = FieldType.JSON;
            isTokenArray = false;
            isTokenObject = true;
        }
        else if (options.structure && options.structure.type == JsonStructureNodeType.Array) {
            fieldType = FieldType.JSON;
            isTokenArray = true;
            isTokenObject = false;
        }
        else {
            fieldType = detectFieldByValue(tokenValue).field;
            isTokenArray = isArray(tokenValue);
            isTokenObject = isPlainObject(tokenValue);
        }
        var name = String(token[token.length - 1]);
        var labelSecondary = this.getValueDisplay(tokenValue, {
            name: name,
            type: fieldType
        });
        if (isTokenArray) {
            var childStructure_1;
            if (options.structure && options.structure.type == JsonStructureNodeType.Array) {
                childStructure_1 = options.structure.params.item;
            }
            return {
                path: path,
                section: __assign({ token: token, insert: token, 
                    // caretIndex: token ? token.length : undefined,
                    label: tokenLabel, labelSecondary: labelSecondary, icon: 'layers_2', allowSkip: false, items: (isArray(tokenValue) ? tokenValue : []).map(function (child, i) {
                        var childToken = token.concat([i]);
                        var childName = "Item #" + (i + 1);
                        return _this.mapJsonToken(childToken, path.concat([String(i)]), childName, child, depth + 1, __assign({}, options, { structure: childStructure_1, override: undefined }));
                    }) }, (options.override || {}))
            };
        }
        else if (isTokenObject) {
            var childrenKeys = void 0;
            var childrenStructure_1;
            if (options.structure && options.structure.type == JsonStructureNodeType.Object) {
                childrenStructure_1 = options.structure.params.items;
                childrenKeys = childrenStructure_1.map(function (item) { return item.name; });
            }
            else {
                childrenKeys = isPlainObject(tokenValue) ? keys(tokenValue) : [];
                childrenStructure_1 = [];
            }
            return {
                path: path,
                section: __assign({ token: token, insert: token, 
                    // caretIndex: token ? token.length : undefined,
                    label: tokenLabel, labelSecondary: labelSecondary, icon: 'components', allowSkip: false, items: childrenKeys.map(function (key) {
                        var childValue = isPlainObject(tokenValue) ? tokenValue[key] : undefined;
                        var childToken = token.concat([key]);
                        var childStructure = childrenStructure_1.find(function (item) { return item.name == key; });
                        return _this.mapJsonToken(childToken, path.concat([key]), key, childValue, depth + 1, __assign({}, options, { structure: childStructure, override: undefined }));
                    }) }, (options.override || {}))
            };
        }
        else {
            var fieldDescription = getFieldDescriptionByType(fieldType);
            return {
                path: path,
                item: __assign({ token: token, label: tokenLabel, icon: fieldDescription.icon, labelSecondary: labelSecondary, insert: token }, (options.override || {}))
            };
        }
    };
    ViewContextTokenProvider.prototype.getValueDisplay = function (value, options) {
        if (options === void 0) { options = {}; }
        var fieldDescription = getFieldDescriptionByType(options.type);
        if (value === EMPTY || !isSet(value)) {
            return fieldDescription.label;
        }
        if (fieldDescription.serializeValue) {
            value = fieldDescription.serializeValue(value, {
                name: options.name,
                field: options.type,
                params: options.params
            });
        }
        if (!isSet(value)) {
            return fieldDescription.label;
        }
        if (isObject(value)) {
            try {
                value = JSON.stringify(value);
            }
            catch (e) { }
        }
        return String(value).substring(0, 40);
    };
    ViewContextTokenProvider.prototype.getTokenValue = function (token, contextElement) {
        var _this = this;
        return this.context.outputValues$.pipe(map(function (globalCtx) {
            var formula = contextToFormulaValue(token.token);
            var internalToken = transformFormulaElementAccessors(formula, _this.context, false);
            var elementPath = _this.context.getElementPath(contextElement);
            var elementCtx = elementPath ? objectGet(globalCtx, elementPath) : EMPTY;
            var ctx = __assign({}, (elementCtx !== EMPTY ? elementCtx : {}), globalCtx);
            return objectGet(ctx, internalToken, null);
        }), throttleTime(60, asyncScheduler, { leading: true, trailing: true }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
    };
    return ViewContextTokenProvider;
}());
export { ViewContextTokenProvider };

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

@Injectable()
export class IsProjectCreatedGuard implements CanActivate {
  constructor(private currentProjectStore: CurrentProjectStore, private routing: RoutingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const canActivate = new ReplaySubject<boolean>();

    this.currentProjectStore.getFirst().subscribe(
      project => {
        if (!project) {
          canActivate.next(true);
          return;
        }

        // if ((project.isCreated && route.data['projectCreateRoute']) || (!project.isCreated && !route.data['projectCreateRoute'])) {
        if (project.isCreated && route.data['projectCreateRoute']) {
          this.routing.navigateLink(project.getHomeLinkWithProtocol());
          canActivate.next(false);
        } else {
          canActivate.next(true);
        }
      },
      () => canActivate.next(false)
    );

    return canActivate;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { BasePopupComponent } from '@common/popups';

import { Resource } from '@modules/projects';
import { getCurrentScrollingElement, getOffset, scrollToElement } from '@shared';

export interface SectionItem {
  name: string;
  description?: string;
  icon?: string;
}

export interface Section {
  name: string;
  items: SectionItem[];
}

@Component({
  selector: 'app-resource-summary',
  templateUrl: './resource-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() resource: Resource;
  @Input() tabs: Section[] = [];
  @Output() closed = new EventEmitter<void>();
  @ViewChildren('section_grid') sectionGridElements = new QueryList<ElementRef>();

  loading = false;
  container: Element;
  currentTab: Section;
  scrollAnimating = false;
  scrollSubscription: Subscription;
  scrollOffset = -44;
  stubTabs = [
    {
      name: 'Collections',
      items: [
        {
          name: 'Cars'
        },
        {
          name: 'Restaurants'
        }
      ]
    },
    {
      name: 'Actions',
      items: [
        {
          name: 'Cars - Create'
        },
        {
          name: 'Cars - Update'
        },
        {
          name: 'Cars - Delete'
        },
        {
          name: 'Restaurants - Create'
        },
        {
          name: 'Restaurants - Update'
        }
      ]
    }
  ];

  constructor(private popupComponent: BasePopupComponent, private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.container = getCurrentScrollingElement(this.el.nativeElement);

    fromEvent(this.container, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateCurrentTab());

    this.updateCurrentTab();
  }

  updateCurrentTab() {
    if (this.scrollAnimating) {
      return;
    }

    const currentOffset = this.sectionGridElements
      .map((element, i) => {
        return {
          offset: getOffset(element.nativeElement, this.container).top + this.scrollOffset,
          index: i
        };
      })
      .reverse()
      .find(item => item.offset <= this.container.scrollTop);

    if (currentOffset) {
      this.currentTab = this.tabs[currentOffset.index];
      this.cd.markForCheck();
    } else {
      this.currentTab = this.tabs[0];
      this.cd.markForCheck();
    }
  }

  openTab(index: number) {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    const element = this.sectionGridElements.toArray()[index];

    this.scrollAnimating = true;
    this.currentTab = this.tabs[index];
    this.cd.markForCheck();

    this.scrollSubscription = scrollToElement(this.container, element.nativeElement, 0.6, this.scrollOffset)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.scrollAnimating = false;
        this.scrollSubscription = undefined;
        this.updateCurrentTab();
      });
  }

  close() {
    this.popupComponent.close();
    this.closed.emit();
  }
}

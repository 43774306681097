var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';
import { mailgunResourceParamsActionDescriptions } from '../../data/mailgun/mailgun-resource-params-action-descriptions.stub';
import { mailgunResourceParamsParams } from '../../data/mailgun/mailgun-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var MailgunGeneratorService = /** @class */ (function (_super) {
    __extends(MailgunGeneratorService, _super);
    function MailgunGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        return _this;
    }
    MailgunGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = 'https://api.mailgun.net/v3/domains';
        query.headers = [{ name: 'Authorization', value: "Basic " + this.authTokenFromOptions(options) }];
        return this.httpQueryService.request(query).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
                error = new ServerRequestError('API Key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    MailgunGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        var _this = this;
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_BASIC_AUTH_VALUE, this.mode == AdminMode.Builder)
            .pipe(map(function (result) { return _this.authTokenToOptions(result.value); }));
    };
    MailgunGeneratorService.prototype.authTokenFromOptions = function (options) {
        try {
            return btoa(['api', options.key].join(':'));
        }
        catch (e) { }
    };
    MailgunGeneratorService.prototype.authTokenToOptions = function (token) {
        try {
            var _a = atob(token).split(':', 2), _1 = _a[0], key = _a[1];
            return {
                key: key
            };
        }
        catch (e) {
            return {
                key: undefined
            };
        }
    };
    MailgunGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = mailgunResourceParamsParams;
        var actionDescriptions = mailgunResourceParamsActionDescriptions;
        var token = new SecretToken();
        token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
        token.type = SecretTokenType.Static;
        token.value = this.authTokenFromOptions(options);
        return of({
            resourceParams: resourceParams,
            actionDescriptions: actionDescriptions,
            secretTokens: [token.serialize()]
        });
    };
    return MailgunGeneratorService;
}(ResourceGeneratorService));
export { MailgunGeneratorService };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { FormField } from '@modules/fields';
import { ProjectProperty } from '@modules/projects';
import { KeyboardEventKeyCode } from '@shared';
var ProjectPropertyChangeItemComponent = /** @class */ (function () {
    function ProjectPropertyChangeItemComponent(analyticsService, cd) {
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.editing = false;
    }
    ProjectPropertyChangeItemComponent.prototype.ngOnInit = function () { };
    ProjectPropertyChangeItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes['property']) {
            this.formField = new FormField();
            this.formField.name = this.property.name;
            this.formField.field = this.property.field.field;
            // this.formField.resetEnabled = true;
            var classes = this.property.field && this.property.field.params && this.property.field.params['classes']
                ? this.property.field.params['classes']
                : [];
            this.formField.params = __assign({}, this.property.field.params, { classes: ['select_fill', 'select_small', 'input_fill', 'input_small'].concat(classes) });
        }
    };
    ProjectPropertyChangeItemComponent.prototype.startEditing = function () {
        var _a;
        var value = this.getPropertyValue();
        this.control = new FormControl(value);
        this.form = new FormGroup((_a = {},
            _a[this.formField.name] = this.control,
            _a));
        this.editing = true;
        this.cd.markForCheck();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateStarted, {
            Source: this.analyticsSource
        });
    };
    ProjectPropertyChangeItemComponent.prototype.finishEditing = function (save) {
        if (save === void 0) { save = false; }
        var value = this.control.value;
        this.editing = false;
        this.control = undefined;
        this.form = undefined;
        this.cd.markForCheck();
        if (save) {
            this.setPropertyValue(value);
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Saved, {
                Source: this.analyticsSource
            });
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Cancelled, {
                Source: this.analyticsSource
            });
        }
    };
    ProjectPropertyChangeItemComponent.prototype.onKeyUp = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            this.finishEditing(true);
        }
        else if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.finishEditing(false);
        }
    };
    ProjectPropertyChangeItemComponent.prototype.getPropertyValue = function () {
        var value = this.propertiesControl.value[this.property.uid];
        if (this.property.fieldDescription && this.property.fieldDescription.deserializeValue) {
            value = this.property.fieldDescription.deserializeValue(value, this.property.field);
        }
        return value;
    };
    ProjectPropertyChangeItemComponent.prototype.setPropertyValue = function (value) {
        var _a;
        if (this.property.fieldDescription && this.property.fieldDescription.serializeValue) {
            value = this.property.fieldDescription.serializeValue(value, this.property.field);
        }
        this.propertiesControl.patchValue(__assign({}, this.propertiesControl.value, (_a = {}, _a[this.property.uid] = value, _a)));
    };
    ProjectPropertyChangeItemComponent.prototype.clearPropertyValue = function () {
        this.setPropertyValue(undefined);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Deleted, {
            Source: this.analyticsSource
        });
    };
    return ProjectPropertyChangeItemComponent;
}());
export { ProjectPropertyChangeItemComponent };

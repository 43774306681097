import { FormControl, FormGroup } from '@angular/forms';

import { Constraints, SideConstraint, SizeConstraint } from '@modules/views';
import { isSet } from '@shared';

export class ConstraintsControl extends FormGroup {
  instance: Constraints;

  controls: {
    top: FormControl;
    right: FormControl;
    bottom: FormControl;
    left: FormControl;
    width: FormControl;
    height: FormControl;
  };

  constructor(state: Partial<Constraints> = {}) {
    super({
      top: new FormControl(isSet(state.top) ? state.top : SideConstraint.Fluid),
      right: new FormControl(isSet(state.right) ? state.right : SideConstraint.Fluid),
      bottom: new FormControl(isSet(state.bottom) ? state.bottom : SideConstraint.Fluid),
      left: new FormControl(isSet(state.left) ? state.left : SideConstraint.Fluid),
      width: new FormControl(isSet(state.width) ? state.width : SizeConstraint.Fluid),
      height: new FormControl(isSet(state.height) ? state.height : SizeConstraint.Fluid)
    });
  }

  deserialize(value: Constraints, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.top.patchValue(value.top, { emitEvent: options.emitEvent });
    this.controls.right.patchValue(value.right, { emitEvent: options.emitEvent });
    this.controls.bottom.patchValue(value.bottom, { emitEvent: options.emitEvent });
    this.controls.left.patchValue(value.left, { emitEvent: options.emitEvent });
    this.controls.width.patchValue(value.width, { emitEvent: options.emitEvent });
    this.controls.height.patchValue(value.height, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Constraints): Constraints {
    if (!instance) {
      instance = new Constraints();
    }

    instance.top = this.controls.top.value;
    instance.right = this.controls.right.value;
    instance.bottom = this.controls.bottom.value;
    instance.left = this.controls.left.value;
    instance.width = this.controls.width.value;
    instance.height = this.controls.height.value;

    return instance;
  }

  serialize(): Constraints {
    return this.getInstance(this.instance);
  }

  toggleTop() {
    const value = this.controls.top.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.top.patchValue(value);
  }

  toggleRight() {
    const value = this.controls.right.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.right.patchValue(value);
  }

  toggleBottom() {
    const value = this.controls.bottom.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.bottom.patchValue(value);
  }

  toggleLeft() {
    const value = this.controls.left.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.left.patchValue(value);
  }

  toggleWidth() {
    const value = this.controls.width.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.width.patchValue(value);
  }

  toggleHeight() {
    const value = this.controls.height.value == SideConstraint.Fluid ? SideConstraint.Fixed : SideConstraint.Fluid;
    this.controls.height.patchValue(value);
  }

  isTopDisabled(): boolean {
    return this.controls.bottom.value == SideConstraint.Fixed && this.controls.height.value == SideConstraint.Fixed;
  }

  isRightDisabled(): boolean {
    return this.controls.left.value == SideConstraint.Fixed && this.controls.width.value == SideConstraint.Fixed;
  }

  isBottomDisabled(): boolean {
    return this.controls.top.value == SideConstraint.Fixed && this.controls.height.value == SideConstraint.Fixed;
  }

  isLeftDisabled(): boolean {
    return this.controls.right.value == SideConstraint.Fixed && this.controls.width.value == SideConstraint.Fixed;
  }

  isWidthDisabled(): boolean {
    return this.controls.left.value == SideConstraint.Fixed && this.controls.right.value == SideConstraint.Fixed;
  }

  isHeightDisabled(): boolean {
    return this.controls.top.value == SideConstraint.Fixed && this.controls.bottom.value == SideConstraint.Fixed;
  }
}

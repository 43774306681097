<ng-template #template>
  <ng-container *ngIf="(group && isVisible) || (customizeService.enabled$ | async) || !group">
    <tbody *ngIf="group" class="table__row-separator-wrapper">
      <tr>
        <td
          class="table__row-separator"
          [class.table__row-separator_interactive]="settings.groupCollapse"
          [attr.colspan]="colspan"
          (mouseenter)="hoverSeparator$.next(true)"
          (mouseleave)="hoverSeparator$.next(false)"
          (click)="toggleCollapsed()"
        >
          <div class="table-row-separator">
            <div
              *ngIf="settings.groupCollapse"
              class="table-row-separator__arrow"
              [class.table-row-separator__arrow_hover]="hoverSeparator$ | async"
              [class.table-row-separator__arrow_active]="collapsed$ | async"
              [class.icon-arrow_forward_2]="collapsed$ | async"
              [class.icon-arrow_down_2]="!(collapsed$ | async)"
            ></div>

            <span
              class="status"
              [ngClass]="
                !(group.color | appIsColorHex)
                  ? ['color_' + group.color + '_1', 'background-color_' + group.color + '_2']
                  : ['']
              "
              [style.color]="(group.color | appIsColorHex) ? group.color : null"
              [style.background-color]="(group.color | appIsColorHex) ? backgroundCustomColor(group.color) : null"
            >
              <ng-container *ngIf="group.name | appIsSet">{{ group.name }}</ng-container>
              <ng-container *ngIf="!(group.name | appIsSet) && (group.value | appIsSet)">{{
                group.value
              }}</ng-container>
              <ng-container *ngIf="!(group.name | appIsSet) && !(group.value | appIsSet)">---</ng-container>
            </span>

            <span
              *ngIf="!isVisible && (customizeService.enabled$ | async)"
              class="table-row-separator__indicator icon-eye"
              [appTip]="'Component is hidden'"
            ></span>

            <span *ngIf="settings.groupCounter && listStore.items !== undefined" class="table-row-separator__counter">
              {{ listStore.count$ | async }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>

    <ng-container *ngIf="(group && !settings.groupCollapse) || (group && !(collapsed$ | async)) || !group">
      <tbody
        *ngIf="loading"
        app-table-stub-content-body
        [columns]="columnsCount"
        [rows]="scrollable ? 8 : loadingRows"
        [rowActions]="settings.rowActions.length > 0"
        [selection]="false"
        [animating]="loading"
        [style.height.px]="(heightBeforeLoading | appIsSet) ? heightBeforeLoading : null"
      ></tbody>

      <tbody *ngIf="!loading && error">
        <tr>
          <td [attr.colspan]="colspan">
            <div class="table__empty">
              <app-error [title]="'Loading failed' | localize">
                {{ error }}
              </app-error>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody
        *ngIf="!loading && !error"
        appElement
        (appElementInit)="bodyElement = $event"
        (appElementDestroy)="bodyElement = undefined"
      >
        <tr
          app-table-item
          *ngFor="let item of items || []; trackBy: trackByFn; let i = index"
          class="table__row"
          [class.table__row_draggable]="false"
          [class.table__row_selected]="isItemSelected(item, i) || isItemChecked(item, i)"
          [class.inverse]="isItemSelected(item, i) || isItemChecked(item, i)"
          [row]="item"
          [modelDescription]="modelDescription"
          [visibleColumns]="visibleColumns"
          [selected]="isItemSelected(item, i)"
          [checked]="isItemChecked(item, i)"
          [checkEnabled]="settings.modelActions.length != 0 || isAnyChecked"
          [settings]="settings"
          [context]="context"
          [contextElement]="contextElement"
          [viewId]="viewId"
          [accentColor]="accentColor"
          (init)="onItemComponentInit($event)"
          (destroy)="onItemComponentDestroy($event)"
          (modelUpdated)="modelUpdated.emit($event)"
          (selectToggle)="selectToggle.emit({ item: $event, element: row.el.nativeElement })"
          (checkedToggle)="checkedToggle.emit({ component: row, index: i })"
          (changeColumnValue)="changeColumnValue.emit({ item: item, name: $event.name, value: $event.value })"
          #row
        ></tr>

        <tr *ngIf="items?.length === 0">
          <td [attr.colspan]="colspan">
            <div class="table__empty">
              <app-error [title]="'Nothing found' | localize">
                {{
                  'Unfortunately, no {0} matching your query found'
                    | localize: [title ? (title | appCapitalize) : ('records' | localize)]
                }}
              </app-error>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="!error && settings.displayFooter">
        <tr>
          <td [attr.colspan]="colspan">
            <app-list-stub-footer *ngIf="loading" [count]="true" [animating]="true" class="table__footer">
            </app-list-stub-footer>

            <app-list-store-footer
              *ngIf="!loading"
              [title]="title"
              [listStore]="listStore"
              [viewId]="viewId"
              (pageSelected)="page$.next($event)"
              (reload)="reloadData()"
              class="table__footer"
            >
            </app-list-store-footer>
          </td>
        </tr>
      </tbody>
    </ng-container>
  </ng-container>
</ng-template>

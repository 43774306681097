import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import {
  CurrentProjectStore,
  Project,
  ProjectGroupService,
  ProjectGroupStore,
  ProjectService
} from '@modules/projects';

@Injectable()
export class ProjectSignUpForm extends FormGroup implements OnDestroy {
  controls: {
    enabled: FormControl;
    default_group: FormControl;
  };

  project: Project;

  constructor(
    private formUtils: FormUtils,
    private projectService: ProjectService,
    private currentProjectStore: CurrentProjectStore,
    private projectGroupService: ProjectGroupService,
    private projectGroupStore: ProjectGroupStore
  ) {
    super({
      enabled: new FormControl(false),
      default_group: new FormControl(null)
    });

    this.controls.default_group.valueChanges.subscribe(value => this.controls.enabled.setValue(!!value));
  }

  ngOnDestroy(): void {}

  init(project: Project) {
    this.project = project;

    this.projectGroupService
      .get(
        this.currentProjectStore.instance.uniqueName,
        this.currentProjectStore.instance.defaultEnvironment
          ? this.currentProjectStore.instance.defaultEnvironment.uniqueName
          : undefined
      )
      .pipe(untilDestroyed(this))
      .subscribe(groups => {
        const group = groups ? groups.find(item => item.uid == project.defaultGroup) : null;
        this.patchValue(
          {
            enabled: !!group,
            default_group: group
          },
          { emitEvent: false }
        );
        this.markAsPristine();
      });

    if (!project.features.isCustomerSignUpEnabled()) {
      this.controls.default_group.disable();
    }
  }

  submit() {
    const value = this.value;
    const project = cloneDeep<Project>(this.project);

    project.defaultGroup = value['enabled'] && value['default_group'] ? value['default_group'].uid : null;

    return this.projectService.update(project.uniqueName, project, ['default_group']).pipe(
      tap(result => {
        this.project = result;
      }),
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      catchError(error => {
        this.markAsDirty();
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

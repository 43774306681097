var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { AllProjectSettings, ProjectSettingsStore } from '@modules/all-project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyStore, ProjectPropertyType, ProjectSettingsName, ProjectSettingsService } from '@modules/projects';
import { defaultFontName, projectThemes } from '@modules/theme';
import { controlValue, isColorHex, isSet } from '@shared';
import { SignUpFieldArray } from './sign-up-field.array';
var ProjectSettingsUpdateForm = /** @class */ (function (_super) {
    __extends(ProjectSettingsUpdateForm, _super);
    function ProjectSettingsUpdateForm(currentProjectStore, currentEnvironmentStore, projectSettingsService, projectSettingsStore, projectPropertyStore, formUtils) {
        var _this = _super.call(this, {
            accent_color: new FormControl(''),
            accent_color_custom_enabled: new FormControl(false),
            accent_color_custom: new FormControl('#2B50ED'),
            font_regular: new FormControl(defaultFontName),
            font_heading: new FormControl(defaultFontName),
            collaboration_enabled: new FormControl(false),
            activity_log_enabled: new FormControl(false),
            default_theme: new FormControl(null),
            append_scripts: new FormControl(''),
            append_styles: new FormControl(''),
            sign_up_fields: new SignUpFieldArray([])
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectSettingsService = projectSettingsService;
        _this.projectSettingsStore = projectSettingsStore;
        _this.projectPropertyStore = projectPropertyStore;
        _this.formUtils = formUtils;
        return _this;
    }
    ProjectSettingsUpdateForm.prototype.init = function (projectSettings) {
        this.projectSettings = projectSettings;
        if (projectSettings) {
            if (isColorHex(projectSettings.accentColor)) {
                this.controls.accent_color.patchValue('');
                this.controls.accent_color_custom_enabled.patchValue(true);
                this.controls.accent_color_custom.patchValue(projectSettings.accentColor);
            }
            else {
                this.controls.accent_color.patchValue(isSet(projectSettings.accentColor) ? projectSettings.accentColor : '');
                this.controls.accent_color_custom_enabled.patchValue(false);
                this.controls.accent_color_custom.patchValue('#2B50ED');
            }
            if (isSet(projectSettings.fontRegular)) {
                this.controls.font_regular.patchValue(projectSettings.fontRegular);
            }
            if (isSet(projectSettings.fontHeading)) {
                this.controls.font_heading.patchValue(projectSettings.fontHeading);
            }
            this.controls.collaboration_enabled.patchValue(projectSettings.collaborationEnabled);
            this.controls.activity_log_enabled.patchValue(projectSettings.activityLogEnabled);
            this.controls.default_theme.patchValue(projectSettings.defaultTheme ? projectSettings.defaultTheme : projectThemes[0]);
            this.controls.append_scripts.patchValue(projectSettings.appendScripts);
            this.controls.append_styles.patchValue(projectSettings.appendStyles);
            this.controls.sign_up_fields.deserialize(projectSettings.signUpFields);
        }
        this.markAsPristine();
    };
    ProjectSettingsUpdateForm.prototype.getAccentColor = function () {
        if (this.controls.accent_color_custom_enabled.value) {
            return this.controls.accent_color_custom.value;
        }
        else {
            return this.controls.accent_color.value;
        }
    };
    ProjectSettingsUpdateForm.prototype.getAccentColor$ = function () {
        return combineLatest(controlValue(this.controls.accent_color_custom_enabled), controlValue(this.controls.accent_color_custom), controlValue(this.controls.accent_color)).pipe(map(function (_a) {
            var colorCustomEnabled = _a[0], colorCustom = _a[1], color = _a[2];
            if (colorCustomEnabled) {
                return colorCustom;
            }
            else {
                return color;
            }
        }));
    };
    ProjectSettingsUpdateForm.prototype.getInstance$ = function () {
        var _this = this;
        return combineLatest(controlValue(this), this.projectPropertyStore
            .get()
            .pipe(map(function (properties) { return properties.filter(function (item) { return item.type == ProjectPropertyType.User; }); }))).pipe(map(function (_a) {
            var _ = _a[0], properties = _a[1];
            var instance = new AllProjectSettings();
            instance.accentColor = _this.getAccentColor();
            instance.collaborationEnabled = _this.controls.collaboration_enabled.value;
            instance.activityLogEnabled = _this.controls.activity_log_enabled.value;
            instance.defaultTheme = _this.controls.default_theme.value;
            instance.appendScripts = isSet(_this.controls.append_scripts.value)
                ? _this.controls.append_scripts.value
                : undefined;
            instance.appendStyles = isSet(_this.controls.append_styles.value)
                ? _this.controls.append_styles.value
                : undefined;
            if (_this.controls.font_regular.value != defaultFontName) {
                instance.fontRegular = _this.controls.font_regular.value;
            }
            else {
                instance.fontRegular = undefined;
            }
            if (_this.controls.font_heading.value != defaultFontName) {
                instance.fontHeading = _this.controls.font_heading.value;
            }
            else {
                instance.fontHeading = undefined;
            }
            instance.signUpFields = _this.controls.sign_up_fields
                .serialize()
                .filter(function (field) { return properties.some(function (item) { return item.uid == field.property; }); });
            return instance;
        }));
    };
    ProjectSettingsUpdateForm.prototype.getInstanceFirst = function () {
        return this.getInstance$().pipe(first());
    };
    ProjectSettingsUpdateForm.prototype.submit = function () {
        var _this = this;
        return this.getInstanceFirst().pipe(switchMap(function (allProjectSettings) {
            if (!allProjectSettings) {
                return of(undefined);
            }
            var submitSettings = [
                ProjectSettingsName.AccentColor,
                ProjectSettingsName.DefaultTheme,
                ProjectSettingsName.AccentColor,
                ProjectSettingsName.FontRegular,
                ProjectSettingsName.FontHeading,
                ProjectSettingsName.AppendScripts,
                ProjectSettingsName.AppendStyles,
                ProjectSettingsName.CollaborationEnabled,
                ProjectSettingsName.ActivityLogEnabled,
                ProjectSettingsName.SignUpFields
            ];
            var prevProjectSettings = _this.projectSettings.serialize(submitSettings);
            var newProjectSettings = allProjectSettings.serialize(submitSettings);
            var updateProjectSettings = newProjectSettings
                .filter(function (newItem) {
                var prevItem = prevProjectSettings.find(function (item) { return item.name == newItem.name; });
                return prevItem ? !isEqual(newItem.value, prevItem.value) : true;
            })
                .map(function (item) {
                if (!isSet(item.value)) {
                    item.deleted = true;
                }
                return item;
            });
            if (!updateProjectSettings.length) {
                return of(undefined);
            }
            return _this.projectSettingsService.createBulk(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, updateProjectSettings);
        }), filter(function (item) { return item; }), switchMap(function () { return _this.projectSettingsStore.getFirst(true); }), map(function (result) { return new AllProjectSettings().deserialize(result); }), tap(function (result) {
            _this.projectSettings = result;
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectSettingsUpdateForm;
}(FormGroup));
export { ProjectSettingsUpdateForm };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Input } from '@modules/fields';
import { MenuItem } from './menu-item';
import { MenuItemAction } from './menu-item-action';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
export var ImageMenuItemOption;
(function (ImageMenuItemOption) {
    ImageMenuItemOption["UserPhoto"] = "user_photo";
    ImageMenuItemOption["ProjectLogo"] = "project_logo";
})(ImageMenuItemOption || (ImageMenuItemOption = {}));
var ImageMenuItem = /** @class */ (function (_super) {
    __extends(ImageMenuItem, _super);
    function ImageMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.Image;
        _this.imageFill = false;
        _this.imageSize = 32;
        _this.imageBorderRadius = 50;
        return _this;
    }
    ImageMenuItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.imageOption = this.params['image_option'];
        this.imageIcon = this.params['image_icon'];
        this.imageCustom = this.params['image_custom'];
        this.imageFill = this.params['image_fill'];
        this.imageColor = this.params['image_color'];
        this.imageSize = this.params['image_size'];
        this.imageBorderRadius = this.params['image_border_radius'];
        if (this.params['title_input']) {
            this.titleInput = new Input().deserialize(this.params['title_input']);
        }
        if (this.params['subtitle_input']) {
            this.subtitleInput = new Input().deserialize(this.params['subtitle_input']);
        }
        if (this.params['action']) {
            this.action = new MenuItemAction().deserialize(this.params['action']);
        }
        return this;
    };
    ImageMenuItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['image_option'] = this.imageOption;
        data['params']['image_icon'] = this.imageIcon;
        data['params']['image_custom'] = this.imageCustom;
        data['params']['image_fill'] = this.imageFill;
        data['params']['image_color'] = this.imageColor;
        data['params']['image_size'] = this.imageSize;
        data['params']['image_border_radius'] = this.imageBorderRadius;
        data['params']['title_input'] = this.titleInput ? this.titleInput.serialize() : null;
        data['params']['subtitle_input'] = this.subtitleInput ? this.subtitleInput.serialize() : null;
        data['params']['action'] = this.action ? this.action.serialize() : undefined;
        return data;
    };
    ImageMenuItem.prototype.copyFrom = function (item) {
        _super.prototype.copyFrom.call(this, item);
        this.imageOption = item.imageOption;
        this.imageIcon = item.imageIcon;
        this.imageCustom = item.imageCustom;
        this.imageFill = item.imageFill;
        this.imageColor = item.imageColor;
        this.imageSize = item.imageSize;
        this.imageBorderRadius = item.imageBorderRadius;
        this.titleInput = item.titleInput ? new Input().deserialize(item.titleInput.serialize()) : undefined;
        this.subtitleInput = item.subtitleInput ? new Input().deserialize(item.subtitleInput.serialize()) : undefined;
    };
    ImageMenuItem.prototype.clone = function () {
        var instance = new ImageMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return ImageMenuItem;
}(MenuItem));
export { ImageMenuItem };
registerMenuItemForType(MenuItemType.Image, ImageMenuItem);

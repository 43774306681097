import { Injectable } from '@angular/core';

import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { modelFieldToDisplayField, WidgetElementItem } from '@modules/customize';
import { ChartType, ChartWidget, ChartWidgetDataset } from '@modules/dashboard';
import { ChartWidgetDataSource, DataSourceType } from '@modules/data-sources';
import { Input } from '@modules/fields';
import { ModelDescription, ModelFieldType } from '@modules/models';
import { Resource } from '@modules/projects';
import { ChartWidgetQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { isSet } from '@shared';

@Injectable()
export class ChartWidgetGenerator {
  constructor() {}

  getElement(
    resource: Resource,
    modelDescription: ModelDescription,
    options: {
      yFunc: AggregateFunc;
      yColumn?: string;
      xColumn: string;
      xLookup?: DatasetGroupLookup;
      label?: string;
      chartType?: ChartType;
      uid?: string;
    }
  ): WidgetElementItem {
    const widget = new ChartWidget();
    const query = new ChartWidgetQuery();

    query.queryType = QueryType.Simple;
    query.simpleQuery = new query.simpleQueryClass();
    query.simpleQuery.model = modelDescription.model;

    const dataset = new ChartWidgetDataset();

    dataset.dataSource = new ChartWidgetDataSource();
    dataset.dataSource.type = DataSourceType.Query;
    dataset.dataSource.queryResource = resource.uniqueName;
    dataset.dataSource.query = query;
    dataset.dataSource.columns = modelDescription.fields
      .filter(item => item.type == ModelFieldType.Db)
      .map(item => {
        const result = modelFieldToDisplayField(item);

        if (result.name == modelDescription.primaryKeyField) {
          result.visible = false;
        }

        return result;
      })
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));

    dataset.dataSource.yFunc = options.yFunc;
    dataset.dataSource.xColumn = options.xColumn;

    if (options.yColumn) {
      dataset.dataSource.yColumn = options.yColumn;
    }

    if (options.xLookup) {
      dataset.dataSource.xLookup = options.xLookup;
    }

    if (isSet(options.label)) {
      widget.nameInput = new Input().deserializeFromStatic('value', options.label);
    }

    if (options.chartType) {
      widget.chartType = options.chartType;
    }

    widget.datasets = [dataset];

    const element = new WidgetElementItem();

    if (options.uid) {
      element.uid = options.uid;
    } else {
      element.generateUid();
    }

    element.widget = widget;

    return element;
  }
}

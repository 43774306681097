import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { versionCompare } from '@modules/api';
import { Environment, Project } from '@modules/projects';
import { isSet } from '@shared';

import { MigrationService } from '../migration/migration.service';

@Injectable()
export class MigrationCheckService implements OnDestroy {
  constructor(
    private migrationService: MigrationService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  ngOnDestroy(): void {}

  checkNotAppliedMigrations(project: Project, environment: Environment) {
    // this.apiInfoStore
    //   .getFirst()
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (!result) {
    //       return;
    //     }
    //
    //     this.migrationService
    //       .get(project.uniqueName, environment.uniqueName)
    //       .pipe(untilDestroyed(this))
    //       .subscribe(migrations => {
    //         migrations = this.migrationService
    //           .filterPossible(migrations, result)
    //           .filter(item => !item.migrated)
    //           .filter(item =>
    //             item.tokens.some(
    //               token => isSet(token.bridgeVersion) && versionCompare(token.bridgeVersion, item.bridgeVersion) == -1
    //             )
    //           );
    //
    //         const migration = migrations[0];
    //
    //         if (!migration) {
    //           return;
    //         }
    //
    //         this.dialogService
    //           .warning({
    //             title: 'Migrate Project',
    //             description: `
    //         Looks like you have updated your <strong>Jet Bridge</strong> and there is a migration need to be done
    //         to continue getting full functionality of <strong>Jet Admin</strong>.<br>
    //         Migration for <strong>${migration.bridgeType}</strong> version <strong>${migration.bridgeVersion}</strong><br>
    //         Your current <strong>${result.type}</strong> version is <strong>${result.version}</strong>
    //       `
    //           })
    //           .pipe(untilDestroyed(this))
    //           .subscribe(confirm => {
    //             if (!confirm) {
    //               return;
    //             }
    //
    //             this.migrationService
    //               .apply(project, environment, migration)
    //               .pipe(untilDestroyed(this))
    //               .subscribe(
    //                 () => {
    //                   this.notificationService.success('Success', `Migration request succeeded`);
    //
    //                   setTimeout(() => {
    //                     window.location.reload();
    //                   }, 1000);
    //                 },
    //                 () => {
    //                   this.notificationService.error('Error', `Migration request failed`);
    //                 }
    //               );
    //           });
    //       });
    //   });
  }
}

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AppDragDrop } from '@common/drag-drop2';
import { CustomSelectItem } from '@modules/field-components';
import { FieldDescription, FieldType, getFieldDescriptionByType, ParameterField } from '@modules/fields';
import { controlValue, isSet, TypedChanges } from '@shared';

import { ImportModelsFieldArray } from '../import-models/import-models-field.array';
import { ImportModelsFieldControl } from '../import-models/import-models-field.control';

export interface ImportDataField {
  control: ImportModelsFieldControl;
  field: ParameterField;
  fieldDescription: FieldDescription;
  required: boolean;
  primaryKey: boolean;
}

export const JET_IMPORT_PK = '__jet_import_pk__';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportDataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fieldsControl: ImportModelsFieldArray;
  @Input() primaryKeyControl: FormControl;
  @Input() data: Object[] = [];
  @Input() sourceOptionsEnabled = false;
  @Input() sourceOptions: CustomSelectItem<string>[] = [];

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  fields: ImportDataField[] = [];
  displayData: Object[] = [];
  updateFieldsSubscription: Subscription;
  openedFieldCustomize: string;

  constructor(private injector: Injector, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ImportDataComponent>): void {
    if (changes.fieldsControl) {
      this.updateFields();
    }

    if (changes.data) {
      this.displayData = this.data ? this.data.slice(0, 8) : undefined;
    }
  }

  updateFields() {
    if (this.updateFieldsSubscription) {
      this.updateFieldsSubscription.unsubscribe();
      this.updateFieldsSubscription = undefined;
    }

    this.updateFieldsSubscription = controlValue(this.fieldsControl)
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => {
        this.fields = this.fieldsControl.controls.map(control => {
          const field = control.controls.field.serialize();
          const fieldDescription = getFieldDescriptionByType(field.field);

          return {
            control: control,
            field: field,
            fieldDescription: fieldDescription,
            required: control.controls.field.controls.required.value,
            primaryKey:
              control.controls.field.controls.name.value == JET_IMPORT_PK ||
              (control.modelDescription &&
                control.controls.field.controls.name.value == control.modelDescription.primaryKeyField)
          };
        });
        this.cd.markForCheck();
      });
  }

  setOpenedFieldCustomize(item: string) {
    this.openedFieldCustomize = item;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    if (this.cdkConnectedOverlay && this.cdkConnectedOverlay.overlayRef) {
      this.cdkConnectedOverlay.overlayRef.updatePosition();
    }
  }

  dragDropOption(event: AppDragDrop<ImportDataField[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.fieldsControl.controls, event.previousIndex, event.currentIndex);
      this.fieldsControl.updateValueAndValidity();
    }
  }

  makePrimaryKey(dataField: ImportDataField) {
    if (dataField.primaryKey) {
      const previousPrimaryKey = this.primaryKeyControl.value;

      if (isSet(previousPrimaryKey)) {
        this.fields
          .filter(item => item.field.name == previousPrimaryKey)
          .forEach(item => item.control.controls.active.patchValue(false));
      }

      this.primaryKeyControl.patchValue(undefined);
      dataField.control.controls.active.patchValue(true);
    } else {
      this.fields.filter(item => item.primaryKey).forEach(item => item.control.controls.active.patchValue(false));

      this.primaryKeyControl.patchValue(dataField.control.controls.field.controls.name.value);
      dataField.control.controls.active.patchValue(true);
    }
  }

  isPrimaryKey(item: ImportDataField): boolean {
    return (
      (item.primaryKey && !isSet(this.primaryKeyControl.value)) ||
      (!item.primaryKey && item.control.controls.field.controls.name.value == this.primaryKeyControl.value)
    );
  }

  enableField(item: ImportDataField) {
    item.control.controls.active.patchValue(true);
  }

  disableField(item: ImportDataField) {
    item.control.controls.active.patchValue(false);

    if (this.sourceOptionsEnabled) {
      item.control.controls.source.patchValue(null);
    }

    if (this.isPrimaryKey(item)) {
      const pkItem = this.fields.find(field => field.primaryKey);
      if (pkItem) {
        this.makePrimaryKey(pkItem);
      }
    }
  }

  asImportDataField(item: any): ImportDataField {
    return item as ImportDataField;
  }

  trackByFn(i, item: ImportDataField) {
    return item ? item.field.name : i;
  }
}

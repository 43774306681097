import { Injector, Type } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { MenuItem } from '@modules/menu';

export type MenuItemControlType = Type<CustomizeBarPagesEditMenuItemControl> & {
  inject: (injector: Injector, ...args: any[]) => CustomizeBarPagesEditMenuItemControl;
};

export abstract class CustomizeBarPagesEditMenuItemControl extends FormGroup {
  menuItem: MenuItem;
  controls: {
    children?: CustomizeBarPagesEditMenuItemArray;
    [key: string]: AbstractControl;
  };

  static inject(injector: Injector, ...args: any[]): CustomizeBarPagesEditMenuItemControl {
    throw new Error('inject is not implemented');
  }

  abstract deserialize(item: MenuItem);
  abstract serialize(): MenuItem;
}

export const menuItemControls: { menuItem: Type<MenuItem>; control: MenuItemControlType }[] = [];

export function getMenuItemControlChildren(
  control: CustomizeBarPagesEditMenuItemControl,
  includeSelf = false
): CustomizeBarPagesEditMenuItemControl[] {
  const result = [];

  if (includeSelf) {
    result.push(control);
  }

  if (control.controls.children) {
    control.controls.children.controls.forEach(subControl => {
      result.push(...getMenuItemControlChildren(subControl));
    });
  }

  return result;
}

export class CustomizeBarPagesEditMenuItemArray extends FormArray {
  controls: CustomizeBarPagesEditMenuItemControl[];

  constructor(private injector: Injector, controls: CustomizeBarPagesEditMenuItemControl[] = []) {
    super(controls);
  }

  static inject(
    injector: Injector,
    controls: CustomizeBarPagesEditMenuItemControl[] = []
  ): CustomizeBarPagesEditMenuItemArray {
    return Injector.create({
      providers: [
        {
          provide: CustomizeBarPagesEditMenuItemArray,
          useFactory: () => {
            return new CustomizeBarPagesEditMenuItemArray(injector, controls);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(CustomizeBarPagesEditMenuItemArray);
  }

  deserialize(value: MenuItem[]) {
    const controls = (value || [])
      .map(item => {
        const control = this.createMenuItemControl(item);

        if (!control) {
          return;
        }

        control.deserialize(item);
        return control;
      })
      .filter(item => item != undefined);

    this.setControls(controls);
  }

  setControls(controls: CustomizeBarPagesEditMenuItemControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: CustomizeBarPagesEditMenuItemControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  appendControl(item: MenuItem): CustomizeBarPagesEditMenuItemControl {
    const control = this.createMenuItemControl(item);

    if (!control) {
      return;
    }

    control.deserialize(item);
    this.push(control);

    return control;
  }

  prependControl(item: MenuItem): CustomizeBarPagesEditMenuItemControl {
    const control = this.createMenuItemControl(item);

    if (!control) {
      return;
    }

    control.deserialize(item);
    this.insert(0, control);

    return control;
  }

  addControl(item: MenuItem, prepend = false): CustomizeBarPagesEditMenuItemControl {
    if (prepend) {
      return this.prependControl(item);
    } else {
      return this.appendControl(item);
    }
  }

  createMenuItemControl(menuItem: MenuItem): CustomizeBarPagesEditMenuItemControl {
    const control = menuItemControls.find(item => menuItem instanceof item.menuItem);

    if (control) {
      return control.control.inject(this.injector);
    }
  }

  serialize(): MenuItem[] {
    return this.controls.map(control => control.serialize());
  }
}

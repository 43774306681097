var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
var GoogleSheetsFileSource = /** @class */ (function (_super) {
    __extends(GoogleSheetsFileSource, _super);
    function GoogleSheetsFileSource(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    GoogleSheetsFileSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        var url = 'https://www.googleapis.com/drive/v3/files';
        var headers = {
            Authorization: "Bearer " + this.accessToken
        };
        var params = {
            q: searchQuery
                ? "mimeType='application/vnd.google-apps.spreadsheet' and (name contains '" + searchQuery + "')"
                : "mimeType='application/vnd.google-apps.spreadsheet'",
            fields: 'files(kind,id,name,mimeType,capabilities/canDelete,capabilities/canEdit)',
            orderBy: "viewedByMeTime desc",
            pageSize: 20
        };
        if (this.pageToken) {
            params['pageToken'] = this.pageToken;
        }
        return this.http
            .get(url, { headers: headers, params: params })
            .pipe(map(function (result) {
            _this.pageToken = result.nextPageToken ? result.nextPageToken : null;
            return result.files.map(function (item) {
                return {
                    value: item,
                    name: item.name
                };
            });
        }));
    };
    GoogleSheetsFileSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        var url = "https://www.googleapis.com/drive/v3/files/" + value.id;
        var headers = {
            Authorization: "Bearer " + this.accessToken
        };
        var params = {
            fields: 'kind,id,name,mimeType,capabilities/canDelete,capabilities/canEdit'
        };
        return this.http
            .get(url, { headers: headers, params: params })
            .pipe(map(function (result) {
            if (!result) {
                return;
            }
            return {
                value: result,
                name: result.name
            };
        }));
    };
    GoogleSheetsFileSource.prototype.isFetchAvailable = function () {
        return this.pageToken !== null;
    };
    GoogleSheetsFileSource.prototype.resetPagination = function () {
        this.pageToken = undefined;
    };
    GoogleSheetsFileSource.prototype.setStaticOptions = function (options) { };
    return GoogleSheetsFileSource;
}(SelectSource));
export { GoogleSheetsFileSource };

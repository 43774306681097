var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomViewSettings, ViewContext } from '@modules/customize';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyService, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ascComparator, isBodyHasChild, isElementHasChild, isSet, KeyboardEventKeyCode, nodeListToArray } from '@shared';
var customPagePropertiesClickEventProperty = '_customPagePropertiesClickEvent';
export function markCustomPagePropertiesClickEvent(clickEvent) {
    clickEvent[customPagePropertiesClickEventProperty] = true;
}
export function isCustomPagePropertiesClickEvent(clickEvent) {
    return !!clickEvent[customPagePropertiesClickEventProperty];
}
var CustomPagePropertiesComponent = /** @class */ (function () {
    function CustomPagePropertiesComponent(currentProjectStore, currentEnvironmentStore, projectPropertyService, projectPropertyEditController, projectPropertyStore, notificationService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectPropertyService = projectPropertyService;
        this.projectPropertyEditController = projectPropertyEditController;
        this.projectPropertyStore = projectPropertyStore;
        this.notificationService = notificationService;
        this.cd = cd;
        this.opened = false;
        this.closed = new EventEmitter();
        this.searchControl = new FormControl('');
        this.propertiesCount = 0;
        this.sections = [];
        this.filteredSections = [];
        this.globalValues = {};
        this.pageValues = {};
        this.userValues = {};
        this.groupValues = {};
        this.pageSubscriptions = [];
        this.analyticsSource = 'page_properties';
    }
    CustomPagePropertiesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFilteredSections(); });
    };
    CustomPagePropertiesComponent.prototype.ngOnDestroy = function () { };
    CustomPagePropertiesComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewSettings) {
            this.initSections();
        }
        if (changes.opened && this.opened) {
            this.onOpened();
        }
        else if (changes.opened && !changes.opened.firstChange && !this.opened) {
            this.onClosed();
        }
    };
    CustomPagePropertiesComponent.prototype.initSections = function () {
        var _this = this;
        this.close();
        this.pageSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.pageSubscriptions = [];
        this.projectPropertyStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (properties) {
            var pageProperties = properties.filter(function (item) {
                return item.type == ProjectPropertyType.Page && isSet(item.pageUid) && item.pageUid == _this.viewSettings.uid;
            });
            var globalProperties = properties.filter(function (item) {
                return item.type == ProjectPropertyType.Global;
            });
            var userProperties = properties.filter(function (item) {
                return item.type == ProjectPropertyType.User;
            });
            var groupProperties = properties.filter(function (item) {
                return item.type == ProjectPropertyType.Group;
            });
            _this.propertiesCount =
                pageProperties.length + globalProperties.length + userProperties.length + groupProperties.length;
            _this.sections = [
                __assign({ name: 'page', label: 'Current page', icon: 'documents', documentation: 'variables', descriptionTitle: 'Page variables', descriptionText: 'Save page state to variables and access from components', buttons: [
                        {
                            label: 'Temporary page variable',
                            description: 'Value exists during user session, resets on refresh',
                            icon: 'time',
                            handler: function () {
                                return _this.createProjectProperty(ProjectPropertyType.Page, {
                                    defaultName: 'variable',
                                    defaultValueEnabled: true,
                                    pageUid: _this.viewSettings.uid
                                });
                            }
                        },
                        {
                            label: 'Stored page variable',
                            description: 'Value is saved in browser across sessions',
                            icon: 'download',
                            handler: function () {
                                return _this.createProjectProperty(ProjectPropertyType.Page, {
                                    defaultName: 'variable',
                                    defaultValueEnabled: true,
                                    pageUid: _this.viewSettings.uid,
                                    saveLocal: true
                                });
                            }
                        }
                    ], buttonsLabel: 'Create page variable', buttonsIcon: 'plus', items: _this.mapPropertyItems(pageProperties, { saveLocalEnabled: true }), itemsTotal: pageProperties.length, showEmpty: true, getValues: function () { return _this.pageValues; } }, ((!_this.viewSettings || !_this.viewSettings.uid) && {
                    disabledTitle: 'Page is not set up',
                    disabledText: 'Add any components to this page first'
                })),
                {
                    name: 'global',
                    label: 'Globals',
                    icon: 'home',
                    descriptionTitle: 'Global variables',
                    descriptionText: 'Global variables can be saved or accessed from any page',
                    buttons: [
                        {
                            label: 'Temporary global variable',
                            description: 'Value exists during user session, resets on refresh',
                            icon: 'time',
                            handler: function () {
                                return _this.createProjectProperty(ProjectPropertyType.Global, {
                                    defaultName: 'variable',
                                    defaultValueEnabled: true
                                });
                            }
                        },
                        {
                            label: 'Stored global variable',
                            description: 'Value is saved in browser across sessions',
                            icon: 'download',
                            handler: function () {
                                return _this.createProjectProperty(ProjectPropertyType.Global, {
                                    defaultName: 'variable',
                                    defaultValueEnabled: true,
                                    saveLocal: true
                                });
                            }
                        }
                    ],
                    buttonsLabel: 'Create global variable',
                    buttonsIcon: 'plus',
                    items: _this.mapPropertyItems(globalProperties, { saveLocalEnabled: true }),
                    itemsTotal: globalProperties.length,
                    showEmpty: true,
                    getValues: function () { return _this.globalValues; }
                },
                {
                    name: 'user',
                    label: 'Current user',
                    icon: 'user',
                    descriptionTitle: 'User properties',
                    descriptionText: 'User properties are linked to current user and accessed from any page',
                    buttons: [
                        {
                            label: 'Create user property',
                            icon: 'plus',
                            handler: function () { return _this.createProjectProperty(ProjectPropertyType.User); }
                        }
                    ],
                    items: _this.mapPropertyItems(userProperties),
                    itemsTotal: userProperties.length,
                    showEmpty: true,
                    getValues: function () { return _this.userValues; },
                    defaultBannerHidden: true
                },
                {
                    name: 'group',
                    label: 'Current team',
                    icon: 'users_teams',
                    descriptionTitle: 'Team properties',
                    descriptionText: 'Team properties are linked to current team and accessed from any page',
                    buttons: [
                        {
                            label: 'Create team property',
                            icon: 'plus',
                            handler: function () { return _this.createProjectProperty(ProjectPropertyType.Group); }
                        }
                    ],
                    items: _this.mapPropertyItems(groupProperties),
                    itemsTotal: groupProperties.length,
                    showEmpty: true,
                    getValues: function () { return _this.groupValues; },
                    defaultBannerHidden: true
                }
            ];
            _this.updateFilteredSections();
            _this.cd.markForCheck();
            _this.pageSubscriptions.push(_this.currentEnvironmentStore.globalStorage
                .getValues$()
                .pipe(untilDestroyed(_this))
                .subscribe(function (value) {
                _this.globalValues = value;
                _this.cd.markForCheck();
            }));
            if (_this.context && _this.context.pageStorage) {
                _this.pageSubscriptions.push(_this.context.pageStorage
                    .getValues$()
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (value) {
                    _this.pageValues = value;
                    _this.cd.markForCheck();
                }));
            }
            else {
                _this.pageValues = {};
                _this.cd.markForCheck();
            }
            _this.pageSubscriptions.push(_this.currentEnvironmentStore.instance$.pipe(untilDestroyed(_this)).subscribe(function (value) {
                _this.userValues = value && value.user ? value.user.properties : {};
                _this.groupValues = value && value.group ? value.group.properties : {};
                _this.cd.markForCheck();
            }));
        });
    };
    CustomPagePropertiesComponent.prototype.mapPropertyItems = function (pageProperties, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return pageProperties
            .map(function (item) {
            return __assign({ id: item.uid, label: item.name, icon: item.fieldDescription.icon, labelIcon: item.saveLocal ? 'download' : undefined }, (item.field && {
                field: item.field.field,
                params: item.field.params
            }), { renameLabel: 'Rename', renameHandler: function (value) { return _this.renameProjectProperty(item, value); }, editLabel: 'Edit', editHandler: function () { return _this.editProjectProperty(item); } }, (options.saveLocalEnabled && {
                actions: item.saveLocal
                    ? [
                        {
                            label: 'Make temporary',
                            icon: 'time',
                            handler: function () { return _this.setSaveLocalProjectProperty(item, false); }
                        }
                    ]
                    : [
                        {
                            label: 'Make stored',
                            icon: 'download',
                            handler: function () { return _this.setSaveLocalProjectProperty(item, true); }
                        }
                    ]
            }), { deleteLabel: 'Delete', deleteHandler: function () { return _this.deleteProjectProperty(item); } });
        })
            .sort(function (lhs, rhs) { return ascComparator(lhs.label.toLowerCase(), rhs.label.toLowerCase()); });
    };
    CustomPagePropertiesComponent.prototype.getFilteredSections = function () {
        var search = this.searchControl.value.toLowerCase().trim();
        if (!isSet(search)) {
            return this.sections;
        }
        return this.sections
            .map(function (section) {
            return __assign({}, section, { items: section.items.filter(function (item) {
                    return item.label.toLowerCase().includes(search);
                }) });
        })
            .filter(function (item) { return item.items.length || item.showEmpty; });
    };
    CustomPagePropertiesComponent.prototype.updateFilteredSections = function () {
        this.filteredSections = this.getFilteredSections();
        this.cd.markForCheck();
    };
    CustomPagePropertiesComponent.prototype.close = function () {
        if (!this.opened) {
            return;
        }
        this.opened = false;
        this.cd.markForCheck();
        this.onClosed();
    };
    CustomPagePropertiesComponent.prototype.onOpened = function () {
        var _this = this;
        setTimeout(function () {
            _this.blurSubscription = fromEvent(window.document, 'click')
                .pipe(filter(function (e) {
                if (isCustomPagePropertiesClickEvent(e)) {
                    return false;
                }
                var overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popups'));
                if (overlays.some(function (overlay) { return isElementHasChild(overlay, e.target); }) ||
                    !isBodyHasChild(e.target)) {
                    return false;
                }
                return true;
            }), untilDestroyed(_this))
                .subscribe(function () { return _this.close(); });
        }, 0);
    };
    CustomPagePropertiesComponent.prototype.onClosed = function () {
        if (this.blurSubscription) {
            this.blurSubscription.unsubscribe();
            this.blurSubscription = undefined;
        }
        this.closed.emit();
    };
    CustomPagePropertiesComponent.prototype.markClickEvent = function (e) {
        markCustomPagePropertiesClickEvent(e);
    };
    CustomPagePropertiesComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
    };
    CustomPagePropertiesComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    CustomPagePropertiesComponent.prototype.createProjectProperty = function (type, options) {
        if (options === void 0) { options = {}; }
        this.projectPropertyEditController
            .create({
            type: type,
            defaultName: options.defaultName,
            defaultValueEnabled: options.defaultValueEnabled,
            pageUid: options.pageUid,
            saveLocal: options.saveLocal,
            context: this.context,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomPagePropertiesComponent.prototype.renameProjectProperty = function (property, name) {
        var _this = this;
        var instance = cloneDeep(property);
        instance.name = name;
        return this.projectPropertyService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, ['name'])
            .pipe(map(function () { return true; }), delayWhen(function () { return _this.projectPropertyStore.getFirst(true); }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Rename Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Rename Failed', error);
            }
            return throwError(error);
        }));
    };
    CustomPagePropertiesComponent.prototype.setSaveLocalProjectProperty = function (property, value) {
        var _this = this;
        var instance = cloneDeep(property);
        instance.saveLocal = value;
        return this.projectPropertyService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance, ['params'])
            .pipe(map(function () {
            if (property.type == ProjectPropertyType.Global) {
                if (value) {
                    _this.currentEnvironmentStore.globalStorage.persist(property);
                }
                else {
                    _this.currentEnvironmentStore.globalStorage.makeTemporary(property);
                }
            }
            else if (property.type == ProjectPropertyType.Page && _this.context && _this.context.pageStorage) {
                if (value) {
                    _this.context.pageStorage.persist(property);
                }
                else {
                    _this.context.pageStorage.makeTemporary(property);
                }
            }
            return true;
        }), delayWhen(function () { return _this.projectPropertyStore.getFirst(true); }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Save Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Save Failed', error);
            }
            return throwError(error);
        }));
    };
    CustomPagePropertiesComponent.prototype.editProjectProperty = function (property) {
        this.projectPropertyEditController
            .edit({
            property: property,
            defaultValueEnabled: [ProjectPropertyType.Global, ProjectPropertyType.Page].includes(property.type),
            pageUid: this.viewSettings.uid,
            context: this.context,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomPagePropertiesComponent.prototype.deleteProjectProperty = function (property) {
        return this.projectPropertyEditController.delete({ property: property, analyticsSource: this.analyticsSource });
    };
    return CustomPagePropertiesComponent;
}());
export { CustomPagePropertiesComponent };

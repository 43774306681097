export const slackResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'send_message',
    params: {
      type: 'query',
      verbose_name: 'Send message',
      dynamic_status: false,
      action_params: [
        {
          name: 'messageText',
          verbose_name: 'message',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        }
      ],
      description: 'Sending messages.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: '{{webhook_url}}',
            auth_params: {},
            body_type: 'JSON',
            body: '{\n    "text": params.messageText\n}',
            headers: [
              {
                name: 'Content-type',
                value: 'application/json'
              }
            ],
            query_params: [],
            response_type: 'text',
            response_path: '',
            request_tokens: {}
          }
        }
      }
    }
  }
];

import { TintStyle } from '@modules/actions';
import { ViewSettings } from '@modules/customize';
import { isSet } from '@shared';

import { MenuItem } from './menu-item';
import { MenuItemAction, MenuItemActionType } from './menu-item-action';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class ButtonMenuItem extends MenuItem {
  public type = MenuItemType.Button;
  public title: string;
  public icon: string;
  public action: MenuItemAction;
  public style: TintStyle = TintStyle.Primary;
  public tint: string;

  deserialize(data: Object): ButtonMenuItem {
    super.deserialize(data);
    this.title = this.params['title'];
    this.icon = this.params['icon'];
    this.tint = this.params['tint'];

    if (this.params['action']) {
      this.action = new MenuItemAction().deserialize(this.params['action']);
    }

    if (this.params['style'] != undefined) {
      this.style = this.params['style'];
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    data['params']['icon'] = this.icon;
    data['params']['action'] = this.action ? this.action.serialize() : undefined;
    data['params']['style'] = this.style;
    data['params']['tint'] = this.tint;
    return data;
  }

  isForPage(page: ViewSettings) {
    if (!this.action || this.action.type != MenuItemActionType.Page) {
      return false;
    }

    if (isSet(this.action.pageUid)) {
      return page.uid == this.action.pageUid;
    } else if (isSet(this.action.pageUniqueName)) {
      return page.uniqueName == this.action.pageUniqueName;
    } else {
      return false;
    }
  }

  copyFrom(item: ButtonMenuItem) {
    super.copyFrom(item);
    this.title = item.title;
    this.icon = item.icon;

    if (item.action) {
      this.action = new MenuItemAction();
      this.action.copyFrom(item.action);
    }
  }

  clone(): ButtonMenuItem {
    const instance = new ButtonMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Button, ButtonMenuItem);

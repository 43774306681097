import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ValueWidget } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { CustomizeBarEditEventType } from '../../../../data/customize-bar-edit-event-type';
import { DataSourceInputsEditComponent } from '../../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ValueWidgetDataSourceControl } from '../../../model-description-data-source-edit/value-widget-data-source';
import { ValueWidgetDataSourceEditComponent } from '../../../value-widget-data-source-edit/value-widget-data-source-edit.component';
import { CustomizeBarValueWidgetEditForm } from '../customize-bar-value-widget-edit.form';
var CustomizeBarValueWidgetEditDataComponent = /** @class */ (function () {
    function CustomizeBarValueWidgetEditDataComponent(analyticsService) {
        this.analyticsService = analyticsService;
        this.addInput = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.dataSourceTypes = DataSourceType;
        this.booleanFieldStyle = BooleanFieldStyle;
    }
    CustomizeBarValueWidgetEditDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.widget.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.control.getResource$().pipe(skip(1)), this.control.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.widget.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
            ComponentTypeID: this.widget.analyticsName
        });
    };
    CustomizeBarValueWidgetEditDataComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarValueWidgetEditDataComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.addInput && _this.inputsEditComponent) {
                _this.inputsEditComponent.openAddInput();
            }
        }, 0);
    };
    CustomizeBarValueWidgetEditDataComponent.prototype.submit = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.chartForm.submit() } });
    };
    CustomizeBarValueWidgetEditDataComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    };
    CustomizeBarValueWidgetEditDataComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.addQueryInput();
        }
    };
    return CustomizeBarValueWidgetEditDataComponent;
}());
export { CustomizeBarValueWidgetEditDataComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ViewSettingsStore } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet, SingletonStore } from '@shared';
import { MenuBlock, MenuBlockLayout } from '../../data/menu-settings';
import { MenuGeneratorService } from '../../services/menu-generator/menu-generator.service';
import { MenuSettingsService } from '../../services/menu-settings/menu-settings.service';
var MenuSettingsStore = /** @class */ (function (_super) {
    __extends(MenuSettingsStore, _super);
    function MenuSettingsStore(mode$, menuSettingsService, menuGeneratorService, modelDescriptionStore, viewSettingsStore, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.menuSettingsService = menuSettingsService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.viewSettingsStore = viewSettingsStore;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    MenuSettingsStore.prototype.fetch = function () {
        var _this = this;
        if (!this.currentProjectStore.instance) {
            return of(undefined);
        }
        return combineLatest(this.mode$.pipe(switchMap(function (mode) {
            return _this.menuSettingsService.get(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, mode == AdminMode.Builder);
        })), this.modelDescriptionStore.getFirst().pipe(catchError(function () { return of([]); })), this.viewSettingsStore.getFirst().pipe(catchError(function () { return of([]); }))).pipe(map(function (_a) {
            var settings = _a[0], models = _a[1], viewSettings = _a[2];
            return _this.menuGeneratorService.validate(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, settings, models, viewSettings);
        }));
    };
    MenuSettingsStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return options.includeDeleted || !result.deleted ? result : undefined;
        }));
    };
    MenuSettingsStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return options.includeDeleted || !result.deleted ? result : undefined;
        }));
    };
    MenuSettingsStore.prototype.addItems = function (menuItems) {
        var _this = this;
        if (!menuItems.length) {
            return this.getFirst();
        }
        return this.getFirst().pipe(switchMap(function (settings) {
            settings = cloneDeep(settings);
            var block = settings.blocks
                .filter(function (item) { return item.enabled; })
                .find(function (item) { return item.layout == MenuBlockLayout.TopThin && !isSet(item.accentColor); });
            if (!block) {
                block = new MenuBlock();
                block.generateUid();
                block.layout = MenuBlockLayout.TopThin;
                settings.blocks.push(block);
            }
            block.startItems = menuItems.concat(block.startItems);
            return _this.menuSettingsService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, settings);
        }), delayWhen(function () {
            return _this.getFirst(true);
        }));
    };
    return MenuSettingsStore;
}(SingletonStore));
export { MenuSettingsStore };

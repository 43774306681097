import * as moment from 'moment';

import { User } from '@modules/users';

export class UserActivityGrouped {
  public month: any;
  public count: number;
  public user: User;

  deserialize(data: Object): UserActivityGrouped {
    this.month = data['month'] ? moment(data['month']) : undefined;
    this.count = data['count'];

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    return this;
  }
}

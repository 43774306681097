import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Color from 'color';

import { isSet } from '@shared';

// TODO: Refactor import
import { getColorHex, parseColor } from '../../../modules/colors/utils/colors';

@Component({
  selector: 'app-poster-layout',
  templateUrl: './poster-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PosterLayoutComponent implements OnInit, OnChanges {
  @Input() left = false;
  @Input() backgroundColor: string;
  @Input() backgroundImage: string;
  @Input() fillScreen = false;
  @Input() preview = false;

  backgroundContrastColor: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundColor']) {
      this.updateBackgroundContrastColor();
    }
  }

  updateBackgroundContrastColor() {
    const hex = getColorHex(this.backgroundColor || '#d9eaf4');
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (!clr) {
      this.backgroundContrastColor = undefined;
      return;
    }

    const colorContrast = clr.contrast(Color('white'));
    const isDark = colorContrast >= 4;

    this.backgroundContrastColor = isDark ? clr.lighten(0.02 * colorContrast).string() : clr.darken(0.04).string();
  }
}

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EmbedScripts, ScriptsService } from '@core';
import { Project } from '@modules/projects';
import { User } from '@modules/users';

export interface SurveyResult {
  finished?: boolean;
  closed?: boolean;
}

declare const Tally: any;

interface TallyOptions {
  layout?: 'default' | 'modal';
  width?: number;
  alignLeft?: boolean;
  hideTitle?: boolean;
  overlay?: boolean;
  emoji?: {
    text: string;
    animation: 'none' | 'wave' | 'tada' | 'heart-beat' | 'spin' | 'flash' | 'bounce' | 'rubber-band' | 'head-shake';
  };
  autoClose?: number;
  customFormUrl?: string;
  hiddenFields?: {
    [key: string]: any;
  };
  onOpen?: () => void;
  onClose?: () => void;
  onPageView?: (page: number) => void;
  onSubmit?: (payload: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  unsubscribeFormId = 'mJbGJm';

  constructor(private scriptsService: ScriptsService) {}

  openUnsubscribe(options: { project: Project; user: User }): Observable<SurveyResult> {
    const result$ = new ReplaySubject<SurveyResult>();

    return this.scriptsService.loadSingleton(EmbedScripts.Tally).pipe(
      switchMap(() => {
        Tally.openPopup(this.unsubscribeFormId, {
          layout: 'modal',
          width: 820,
          overlay: true,
          autoClose: 0,
          hiddenFields: {
            project_id: options.project.uniqueName,
            email: options.user.email,
            user_id: options.user.uid,
            plan_id:
              options.project.subscription && options.project.subscription.plan
                ? options.project.subscription.plan.uniqueName
                : undefined,
            subscription_price: options.project.subscription ? options.project.subscription.price : undefined
          },
          onClose: () => {
            result$.next({ closed: true });
          },
          onSubmit: () => {
            result$.next({ finished: true });
          }
        } as TallyOptions);

        return result$.asObservable();
      })
    );
  }
}

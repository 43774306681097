import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-signature-field',
  templateUrl: 'signature-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureFieldComponent extends FieldComponent implements OnInit, OnChanges {
  constructor(private domSanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  get currentValueBackgroundImage() {
    if (!this.currentValue) {
      return;
    }

    return this.domSanitizer.bypassSecurityTrustStyle(`url('${this.currentValue}')`);
  }
}

registerFieldComponent(FieldType.Signature, SignatureFieldComponent);

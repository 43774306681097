import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopupRef } from '@common/popups';
import { DraftChangesService } from '@modules/customize-utils';
import { Resource, ResourceName, ResourceType, ResourceTypeItem } from '@modules/projects';
import { isResourceCustom } from '@modules/resources';

@Component({
  selector: 'app-confirm-sync-mode',
  templateUrl: './confirm-sync-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmSyncModeComponent implements OnInit, OnDestroy {
  @Input() object: string;
  @Input() sync: boolean;
  @Input() resource: Resource;
  @Input() typeItem: ResourceTypeItem;
  @Input() closeOnConfirm = true;
  @Output() confirm = new EventEmitter<void>();

  loading = false;
  isPublishRequired = false;
  hasDraftChanges = false;
  resourceNames = ResourceName;

  constructor(
    private draftChangesService: DraftChangesService,
    @Optional() private popupRef: PopupRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isPublishRequired = this.typeItem.resourceType == ResourceType.RestAPI && isResourceCustom(this.resource);
    this.cd.markForCheck();

    this.draftChangesService
      .getDraftChanges$({
        resource: this.resource ? this.resource.uniqueName : undefined
      })
      .pipe(untilDestroyed(this))
      .subscribe(changes => {
        this.hasDraftChanges = changes.filter(item => item.count).length > 0;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setLoading(loading: boolean) {
    this.loading = loading;
    this.cd.markForCheck();
  }

  submit() {
    this.confirm.emit();

    if (this.closeOnConfirm) {
      this.close();
    }
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }
}

<mat-menu #add_page_dropdown="matMenu">
  <ng-template let-prepend="prepend" matMenuContent>
    <button
      *ngFor="let page of pages"
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addPageMenuItem(page.page, prepend)"
    >
      {{ page.label }}
    </button>

    <div *ngIf="!pages.length" class="mat-menu-item-message">
      No pages yet
    </div>
  </ng-template>
</mat-menu>

<mat-menu #add_menu_item_dropdown="matMenu">
  <ng-template let-prepend="prepend" matMenuContent>
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      [matMenuTriggerFor]="add_page_dropdown"
      [matMenuTriggerData]="{ prepend: prepend }"
    >
      <span class="mat-menu-item-icon icon-document"></span>
      Page
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addUrlMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-external_link"></span>
      Custom Link
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addSectionMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-section"></span>
      Section
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addProjectMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-image"></span>
      App logo
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addUserMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-user"></span>
      Current user
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addImageMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-image"></span>
      Icon / Image
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addButtonMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-hand"></span>
      Button
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addDropdownMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-fileds"></span>
      Dropdown
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addSeparatorMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-fixed_horizontal"></span>
      Separator
    </button>

    <!--    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true">-->
    <!--      <span class="mat-menu-item-icon icon-data"></span>-->
    <!--      Load from resource-->
    <!--    </button>-->

    <button
      *ngFor="let item of systemOptions"
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addSystemMenuItem(item, prepend)"
    >
      <span class="mat-menu-item-icon" [ngClass]="'icon-' + item.icon"></span>
      {{ item.actionLabel }}
    </button>

    <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="addShareMenuItem(prepend)">
      <span class="mat-menu-item-icon icon-redo"></span>
      App members
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd mat-menu-item-orange mat-menu-item_bottom-sticky mat-menu-item-border"
      [disableRipple]="true"
      (click)="addCustomMenuItem(prepend)"
    >
      <span class="mat-menu-item-icon icon-canvas"></span>
      Custom component
    </button>
  </ng-template>
</mat-menu>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';
import { ModelDescriptionService } from '../services/model-description/model-description.service';
var ModelDescriptionStore = /** @class */ (function (_super) {
    __extends(ModelDescriptionStore, _super);
    function ModelDescriptionStore(mode$, modelDescriptionService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.modelDescriptionService = modelDescriptionService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    ModelDescriptionStore.prototype.fetch = function () {
        var _this = this;
        return this.currentProjectStore.getFirst().pipe(switchMap(function () {
            if (!_this.currentProjectStore.instance || !_this.currentEnvironmentStore.instance) {
                return of([]);
            }
            return _this.mode$.pipe(switchMap(function (mode) {
                return _this.modelDescriptionService.get(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, mode == AdminMode.Builder);
            }));
        }), catchError(function (e) {
            console.error('[MODEL_DESCRIPTION_STORE_FETCH]', e);
            return throwError(e);
        }));
    };
    ModelDescriptionStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ModelDescriptionStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ModelDescriptionStore.prototype.getDetail = function (modelId, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (results) {
            if (!results) {
                return;
            }
            return results.find(function (item) { return item.isSame(modelId); });
        }));
    };
    ModelDescriptionStore.prototype.getDetailFirst = function (modelId, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (results) {
            if (!results) {
                return;
            }
            return results.find(function (item) { return item.isSame(modelId); });
        }));
    };
    ModelDescriptionStore.prototype.addItem = function (modelDescription) {
        this.instance = this.instance.concat([modelDescription]);
    };
    ModelDescriptionStore.prototype.updateItem = function (modelDescription) {
        var index = this.instance.findIndex(function (item) { return item.isSame(modelDescription); });
        if (index !== -1) {
            this.instance = this.instance.map(function (item, i) {
                if (i === index) {
                    return modelDescription;
                }
                else {
                    return item;
                }
            });
        }
    };
    ModelDescriptionStore.prototype.updateItems = function (modelDescription) {
        var replacesFound = false;
        var newInstance = this.instance.map(function (instanceItem, i) {
            var replaceItem = modelDescription.find(function (item) { return item.isSame(instanceItem); });
            if (!replacesFound && replaceItem) {
                replacesFound = true;
            }
            return replaceItem || instanceItem;
        });
        if (replacesFound) {
            this.instance = newInstance;
        }
    };
    ModelDescriptionStore.prototype.deleteItem = function (modelDescription) {
        var index = this.instance.findIndex(function (item) { return item.isSame(modelDescription); });
        if (index !== -1) {
            this.instance = this.instance.filter(function (item, i) { return i !== index; });
        }
    };
    return ModelDescriptionStore;
}(SingletonStore));
export { ModelDescriptionStore };

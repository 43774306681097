import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarContext } from '@modules/customize-bar';
import { createFormFieldFactory } from '@modules/fields';

import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { CustomizeBarCustomCodeEditForm } from './customize-bar-custom-code-edit.form';

@Component({
  selector: 'app-customize-bar-custom-code-edit',
  templateUrl: './customize-bar-custom-code-edit.component.html',
  providers: [CustomizeBarCustomCodeEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCustomCodeEditComponent implements OnInit, OnDestroy {
  @Input() settingsForm: ProjectSettingsUpdateForm;

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    public customCodeForm: CustomizeBarCustomCodeEditForm,
    private customizeBarContext: CustomizeBarContext,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {}

  init() {
    this.customCodeForm.init(
      this.settingsForm.controls.append_scripts.value,
      this.settingsForm.controls.append_styles.value
    );
  }

  submit() {
    this.settingsForm.controls.append_scripts.patchValue(this.customCodeForm.controls.append_scripts.value, {
      emitEvent: false
    });
    this.settingsForm.controls.append_styles.patchValue(this.customCodeForm.controls.append_styles.value, {
      emitEvent: false
    });

    this.loading = true;
    this.cd.markForCheck();

    this.settingsForm
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();

          this.init();
          this.onSave();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'app_settings'
    });
  }
}

<app-toast *ngIf="project" [warning]="true" [uniqueName]="'past_due_' + project" [shift]="shift">
  <ng-container data-title>
    Payment for your subscription is past due
  </ng-container>

  <ng-container data-description>
    <ng-container *ngIf="project.subscription.cancelled">
      <p>Your subscription was cancelled on {{ project.subscription.dateCancelled | appFormatDate: 'lll' }}.</p>

      <p *ngIf="project.subscription.dateEndActual as dateEndActual">
        If you don't subscribe to a new plan your App will be suspended on
        <strong>{{ dateEndActual | appFormatDate: 'll' }}</strong
        >.
      </p>
    </ng-container>

    <ng-container *ngIf="!project.subscription.cancelled">
      <p>
        There was an error with your latest subscription payment. This is usually due to insufficient funds or an
        expired card.
      </p>

      <p *ngIf="project.subscription.dateEndActual as dateEndActual">
        If the payment does not go through your App will be suspended on
        <strong>{{ dateEndActual | appFormatDate: 'll' }}</strong
        >.
      </p>
    </ng-container>
  </ng-container>

  <ng-container data-buttons>
    <a
      *ngIf="project.hasProjectBillingPermission() && project.subscription.cancelled"
      [appLink]="project.settingsBillingPlansLink"
      class="toast2__button"
    >
      <div class="toast2__button-icon icon-payments"></div>
      <div class="toast2__button-label">Choose plan</div>
    </a>

    <a
      *ngIf="project.hasProjectBillingPermission() && !project.subscription.cancelled"
      [appLink]="project.settingsBillingLink"
      class="toast2__button"
    >
      <div class="toast2__button-icon icon-payments"></div>
      <div class="toast2__button-label">Check payment method</div>
    </a>

    <div class="toast2__button" (click)="openChat()">
      <div class="toast2__button-icon icon-chat"></div>
      <div class="toast2__button-label">Contact Us</div>
    </div>
  </ng-container>
</app-toast>

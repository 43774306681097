import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ValueFormatControl } from '../../value-format-edit/value-format.control';
import { CharFieldViewParamsForm } from './char-field-view-params.form';

@Component({
  selector: 'app-char-field-view-params',
  templateUrl: './char-field-view-params.component.html',
  providers: [ValueFormatControl, CharFieldViewParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  editable = false;
  booleanFieldStyle = BooleanFieldStyle;
  fieldTypes = FieldType;

  constructor(public form: CharFieldViewParamsForm, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, this.context);

    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.editable = (!this.configurable || this.configurable.editable) && field && field['editable'];
        this.cd.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {}
}

registerFieldViewParamsComponent(FieldType.Text, CharFieldViewParamsComponent);

import { Injectable, Injector, StaticProvider, Type } from '@angular/core';
import 'reflect-metadata';

@Injectable({
  providedIn: 'root'
})
export class Factory {
  constructor(private injector: Injector) {}

  createReflected<T>(cls: Type<T>): T {
    const argTypes = Reflect.getMetadata('design:paramtypes', cls) || [];
    const childInjector = Injector.create({
      providers: [{ provide: cls, deps: argTypes }],
      parent: this.injector
    });

    return childInjector.get<T>(cls);
  }

  create<T>(cls: Type<T>, options: { injector?: Injector; dependencies?: any[]; providers?: StaticProvider[] } = {}) {
    const childInjector = Injector.create({
      providers: [{ provide: cls, deps: options.dependencies }, ...(options.providers || [])],
      parent: options.injector || this.injector
    });
    return childInjector.get<T>(cls);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';

import { AppDropListGroup } from '@common/drag-drop2';

import { ViewContext } from '@modules/customize';

import { TemplatesSection } from '../../../../data/templates-section';
import { TemplatesSectionGroup } from '../../../../data/templates-section-group';

@Component({
  selector: 'app-change-customize-bar-templates-group-section',
  templateUrl: './change-customize-bar-templates-group-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesGroupSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() section: TemplatesSection;
  @Input() context: ViewContext;
  @Input() dropListGroups: AppDropListGroup[] = [];
  @Input() focus = false;

  search = '';
  searchUpdated = new Subject<string>();
  groupsFiltered: TemplatesSectionGroup[] = [];
  emptyLabel: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.searchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateFiltered());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      this.updateFiltered();
    }
  }

  updateFiltered() {
    this.groupsFiltered = this.section.groups
      .map(group => {
        return {
          ...group,
          items: group.items.filter(item => {
            if (!this.search) {
              return true;
            }

            return item.title.toLowerCase().includes(this.search.toLowerCase());
          })
        };
      })
      .filter(group => group.items.length);

    if (!this.groupsFiltered.length) {
      this.emptyLabel = this.search.length ? 'Nothing found' : 'Nothing here';
    } else {
      this.emptyLabel = undefined;
    }

    this.cd.markForCheck();
  }

  clearSearch() {
    this.search = '';
    this.searchUpdated.next();
    this.cd.markForCheck();
  }
}

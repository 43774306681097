<div
  *ngFor="let item of viewSettings.popups; trackBy: trackColumn"
  class="custom-page-popup-container"
  [class.custom-page-popup-container_visible]="item.uid === openedPopupUid"
  [class.custom-page-popup-container_side]="item.isSide()"
  [class.custom-page-popup-container_interactive]="item.overlay"
  [class.custom-page-popup-container_scrollable]="!item.isSide()"
  (scroll)="$event.stopPropagation()"
  (wheel)="$event.stopPropagation()"
  (click)="onPopupContainerClicked(item)"
  (mousedown)="onPopupContainerMouseDown($event)"
  #popup_container_element
>
  <div
    class="custom-page-popup-container__overlay"
    [class.custom-page-popup-container__overlay_enabled]="item.overlay"
    #popup_overlay_element
  ></div>

  <ng-template #popup_tpl>
    <app-custom-page-popup
      [popup]="item"
      [viewSettings]="viewSettings"
      [context]="context"
      [customizing]="customizeService.layoutEnabled$ | async"
      [popupsContextElement]="popupsContextElement"
      [visible]="item.uid === openedPopupUid"
      (editPopup)="updatePopups()"
      (deletePopup)="deletePopup(item.uid, $event)"
      (closePopup)="closePopup(item.uid)"
      (duplicatePopup)="duplicatePopupFromToolbar(item.uid, { open: true, customize: true })"
      (movePopupToPage)="moveToPageFromToolbar(item.uid, $event, { open: true })"
      class="custom-page-popup-container__popup"
      [class.custom-page-popup-container__popup_visible]="item.uid === openedPopupUid"
      [class.custom-page-popup-container__popup_scrollable]="item.isSide()"
      #popup_element
    ></app-custom-page-popup>
  </ng-template>

  <ng-container *ngIf="openedPopupOrigin">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="dropdownPositions"
      [cdkConnectedOverlayOrigin]="openedPopupOrigin"
      [cdkConnectedOverlayOpen]="item.uid === openedPopupUid"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="!(customizeService.layoutEnabled$ | async)"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="closePopup(item.uid)"
      (detach)="closePopup(item.uid)"
    >
      <div
        class="overlay-content overlay-content_animated"
        [class.customizable]="customizeService.layoutEnabled$ | async"
      >
        <ng-container *ngTemplateOutlet="popup_tpl"></ng-container>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="!openedPopupOrigin">
    <ng-container *ngTemplateOutlet="popup_tpl"></ng-container>
  </ng-container>
</div>

<app-custom-page-popups-menu
  *ngIf="mode == adminModes.Builder"
  [viewSettings]="viewSettings"
  [popups]="viewSettings.popups"
  [openedPopup]="openedPopup"
  [opened]="openedToolbar"
  (createPopup)="createPopupFromToolbar(true, $event)"
  (duplicatePopup)="duplicatePopupFromToolbar($event, { open: true })"
  (movePopupToPage)="moveToPageFromToolbar($event.uid, $event.page, { open: true })"
  (deletePopup)="deletePopup($event, true)"
  (openPopup)="openPopup($event, { openComponents: true })"
  (customizePopup)="openPopup($event, { customize: true })"
  (closePopup)="closePopup($event)"
  (closed)="closedToolbar.emit()"
></app-custom-page-popups-menu>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../page-templates-choose-item/page-templates-choose-item.component.ngfactory";
import * as i2 from "../page-templates-choose-item/page-templates-choose-item.component";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../../../template-generators/services/page-templates-generator/page-templates-generator.service";
import * as i5 from "../../../model-queries/stores/model-description.store";
import * as i6 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i7 from "../page-templates-item-settings/page-templates-item-settings.component.ngfactory";
import * as i8 from "../page-templates-item-settings/page-templates-item-settings.form";
import * as i9 from "../../../customize-generators/services/data-source-generator/data-source-generator.service";
import * as i10 from "../../../customize-generators/services/view-settings-generator/view-settings-generator.service";
import * as i11 from "../../../customize-generators/services/create-form-generator/create-form-generator.service";
import * as i12 from "../../../customize-generators/services/update-form-generator/update-form-generator.service";
import * as i13 from "../../../customize/data/view-context";
import * as i14 from "../page-templates-item-settings/page-templates-item-settings.component";
import * as i15 from "@angular/common";
import * as i16 from "./page-templates.component";
import * as i17 from "../../../projects/stores/current-project.store";
import * as i18 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i19 from "../../../routing/services/routing/routing.service";
var styles_PageTemplatesComponent = [];
var RenderType_PageTemplatesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatesComponent, data: {} });
export { RenderType_PageTemplatesComponent as RenderType_PageTemplatesComponent };
function View_PageTemplatesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-templates-choose-item", [], null, [[null, "selected"], [null, "cancelClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelectedItem($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelClick" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PageTemplatesChooseItemComponent_0, i1.RenderType_PageTemplatesChooseItemComponent)), i0.ɵdid(1, 245760, null, 0, i2.PageTemplatesChooseItemComponent, [i3.CurrentEnvironmentStore, i4.PageTemplatesGeneratorService, i5.ModelDescriptionStore, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], { title: [0, "title"], cancelEnabled: [1, "cancelEnabled"], newPage: [2, "newPage"], template: [3, "template"], templateVariant: [4, "templateVariant"], resource: [5, "resource"], analyticsSource: [6, "analyticsSource"] }, { selected: "selected", cancelClick: "cancelClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.cancelEnabled; var currVal_2 = _co.newPage; var currVal_3 = _co.selectedTemplate; var currVal_4 = _co.selectedTemplateVariant; var currVal_5 = _co.resource; var currVal_6 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_PageTemplatesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-page-templates-item-settings", [], null, [[null, "createdStartPage"], [null, "backToTypeClick"], [null, "backToModelClick"], [null, "cancelClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("createdStartPage" === en)) {
        var pd_0 = (_co.onCreatedStartPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("backToTypeClick" === en)) {
        _co.setSelectedTemplate(undefined);
        var pd_1 = (_co.setSelectedModel(undefined) !== false);
        ad = (pd_1 && ad);
    } if (("backToModelClick" === en)) {
        var pd_2 = (_co.setSelectedModel(undefined) !== false);
        ad = (pd_2 && ad);
    } if (("cancelClick" === en)) {
        var pd_3 = (_co.cancel() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_PageTemplatesItemSettingsComponent_0, i7.RenderType_PageTemplatesItemSettingsComponent)), i0.ɵprd(512, null, i8.PageTemplatesItemSettingsForm, i8.PageTemplatesItemSettingsForm, [i4.PageTemplatesGeneratorService, i9.DataSourceGeneratorService, i10.ViewSettingsGeneratorService, i11.CreateFormGenerator, i12.UpdateFormGenerator]), i0.ɵprd(131584, null, i13.ViewContext, i13.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵdid(3, 245760, null, 0, i14.PageTemplatesItemSettingsComponent, [i8.PageTemplatesItemSettingsForm, i3.CurrentEnvironmentStore, i4.PageTemplatesGeneratorService, i13.ViewContext, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], { template: [0, "template"], templateVariant: [1, "templateVariant"], modelDescription: [2, "modelDescription"], cancelEnabled: [3, "cancelEnabled"], analyticsSource: [4, "analyticsSource"] }, { createdStartPage: "createdStartPage", backToTypeClick: "backToTypeClick", backToModelClick: "backToModelClick", cancelClick: "cancelClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTemplate; var currVal_1 = _co.selectedTemplateVariant; var currVal_2 = _co.selectedModel; var currVal_3 = _co.cancelEnabled; var currVal_4 = _co.analyticsSource; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PageTemplatesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesComponent_1)), i0.ɵdid(1, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesComponent_2)), i0.ɵdid(3, 16384, null, 0, i15.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.selectedTemplate && _co.selectedModel); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PageTemplatesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-templates", [], null, null, null, View_PageTemplatesComponent_0, RenderType_PageTemplatesComponent)), i0.ɵdid(1, 245760, null, 0, i16.PageTemplatesComponent, [i17.CurrentProjectStore, i3.CurrentEnvironmentStore, i18.BasePopupComponent, i19.RoutingService, i6.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatesComponentNgFactory = i0.ɵccf("app-page-templates", i16.PageTemplatesComponent, View_PageTemplatesComponent_Host_0, { title: "title", resource: "resource", cancelEnabled: "cancelEnabled", newPage: "newPage", publish: "publish", analyticsSource: "analyticsSource" }, { createdPages: "createdPages", createNewPage: "createNewPage", cancelled: "cancelled" }, []);
export { PageTemplatesComponentNgFactory as PageTemplatesComponentNgFactory };

<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [label]="label"
  [errors]="errors"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
>
  <div class="control">
    <div class="control__input">
      <app-custom-select
        [control]="control"
        [items]="resourceItems"
        [emptyLabel]="'No resources'"
        [chooseLabel]="'Choose resource'"
        [classes]="['select_fill']"
        [panelClasses]="['mat-menu-panel_sidebar']"
        (optionClick)="onOptionClick($event)"
        (buttonClick)="onButtonClick($event)"
      >
      </app-custom-select>
    </div>
    <div *ngIf="valueResource && valueResourceSettings && !valueResource.params['demo']" class="control__right">
      <a
        href="javascript:void(0)"
        class="button button_bigger button_icon control__button"
        [class.button_orange]="card"
        [class.button_orange-alternative]="!card"
        [class.button_primary]="!card"
        [appTip]="'Edit resource'"
        (click)="openResourceSettings(valueResource.typeItem, valueResource)"
      >
        <span class="icon-gear button__icon"></span>
      </a>
    </div>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [label]="label"
  [errors]="errors"
  [manualMargin]="manualMargin"
  [idToken]="idToken"
>
  <img
    *ngIf="valueIcon"
    class="choose-items-item__dropdown-icon"
    [src]="'/assets/images/resources/icons/' + valueIcon + '.svg' | appDeployUrl"
  />
  <ng-container *ngIf="valueStr">{{ valueStr }}</ng-container>
  <span *ngIf="!valueStr" class="loading-animation"><span class="stub-text">Record Name</span></span>
</app-field-wrapper>

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class JsonFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      display_fields: new FormControl(true),
      structure: new FormControl(undefined)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = this.form.value;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class ActionEditPopupForm extends FormGroup {
  action: ActionDescription;

  constructor(
    private actionDescriptionService: ActionDescriptionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionStore: ActionStore,
    private formUtils: FormUtils
  ) {
    super(
      {
        verbose_name: new FormControl('', Validators.required),
        description: new FormControl('')
      },
      null,
      (control: FormGroup) => this.checkUniqueName(control)
    );
  }

  checkUniqueName(control: FormGroup) {
    return this.actionStore.getFirst().pipe(
      map(actions => {
        if (!actions) {
          return null;
        }

        if (
          actions.find(
            item =>
              item.id != this.action.id &&
              item.resource == this.action.resource &&
              item.verboseName == control.value['verbose_name']
          )
        ) {
          return {
            local: ['Action with such Name already exists']
          };
        }

        return null;
      })
    );
  }

  setInstance(action: ActionDescription) {
    this.action = action;
    this.patchValue({
      verbose_name: action.verboseName,
      description: action.description
    });
  }

  getInstance(): ActionDescription {
    const value = this.value;
    const instance = new ActionDescription().deserialize(this.action.serialize());

    instance.verboseName = value['verbose_name'];
    instance.description = value['description'];

    return instance;
  }

  submit() {
    this.markAsDirty();
    const instance = this.getInstance();

    return this.actionDescriptionService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(
        delayWhen(() => this.actionStore.getFirst(true)),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

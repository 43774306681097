/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../../common/dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "../customize-view/customize-view.component.ngfactory";
import * as i5 from "../customize-view/customize-view.form";
import * as i6 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../../../projects/stores/current-project.store";
import * as i9 from "../../../projects/stores/current-environment.store";
import * as i10 from "../customize-view/customize-view.component";
import * as i11 from "ng-gxscrollable";
import * as i12 from "@angular/common";
import * as i13 from "./view-editor-sidebar.component";
import * as i14 from "../../services/view-editor-context/view-editor.context";
var styles_ViewEditorSidebarComponent = [];
var RenderType_ViewEditorSidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorSidebarComponent, data: {} });
export { RenderType_ViewEditorSidebarComponent as RenderType_ViewEditorSidebarComponent };
function View_ViewEditorSidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customizeLayerComponent; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ViewEditorSidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-customize-view", [], null, [[null, "viewChange"], [null, "closeCustomize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("viewChange" === en)) {
        var pd_0 = (_co.onViewChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeCustomize" === en)) {
        var pd_1 = (_co.onViewCloseCustomize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_CustomizeViewComponent_0, i4.RenderType_CustomizeViewComponent)), i0.ɵprd(512, null, i5.CustomizeViewForm, i5.CustomizeViewForm, []), i0.ɵprd(131584, null, i6.CustomizeBarContext, i6.CustomizeBarContext, [i7.CustomizeService, i8.CurrentProjectStore, i9.CurrentEnvironmentStore]), i0.ɵdid(3, 245760, null, 0, i10.CustomizeViewComponent, [i5.CustomizeViewForm, i6.CustomizeBarContext, i0.ChangeDetectorRef], { view: [0, "view"], viewUpdated$: [1, "viewUpdated$"], viewContext: [2, "viewContext"] }, { viewChange: "viewChange", closeCustomize: "closeCustomize" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customizeView; var currVal_1 = _co.viewUpdated$; var currVal_2 = _co.viewContext; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ViewEditorSidebarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["root", 1]], null, 6, "div", [["class", "sidebar__scrollable"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(3, 12664832, null, 0, i11.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(4, { stopPropagationAlways: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorSidebarComponent_1)), i0.ɵdid(6, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorSidebarComponent_2)), i0.ɵdid(8, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.customizeLayerComponent; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.customizeView; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ViewEditorSidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-sidebar", [], null, null, null, View_ViewEditorSidebarComponent_0, RenderType_ViewEditorSidebarComponent)), i0.ɵdid(1, 245760, null, 0, i13.ViewEditorSidebarComponent, [i14.ViewEditorContext, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorSidebarComponentNgFactory = i0.ɵccf("app-view-editor-sidebar", i13.ViewEditorSidebarComponent, View_ViewEditorSidebarComponent_Host_0, { viewContext: "viewContext" }, {}, []);
export { ViewEditorSidebarComponentNgFactory as ViewEditorSidebarComponentNgFactory };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponentService } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';

import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarService } from '@modules/customize-bar';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getResourceSettingsComponent, ResourceEditController } from '@modules/projects-components';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { SidebarCollapseContext } from '@modules/sidebar';

import { TemplatesGroup } from '../../../../data/templates-group';
import { TemplatesSection } from '../../../../data/templates-section';
import { TemplateProvider } from '../../../../services/template-provider/template.provider';
import { ChangeCustomizeBarItemsBase } from '../../change-customize-bar-items-base/change-customize-bar-items-base.component';

@Component({
  selector: 'app-change-customize-bar-templates-group',
  templateUrl: './change-customize-bar-templates-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesGroupComponent extends ChangeCustomizeBarItemsBase
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() item: TemplatesGroup;
  @Output() scrollToTop = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<boolean>();

  collapseContext = new SidebarCollapseContext();
  sections: TemplatesSection[] = [];
  reloadLoading = false;
  reloadAllowed = false;

  constructor(
    private cd: ChangeDetectorRef,
    private templateProvider: TemplateProvider,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceEditController: ResourceEditController,
    protected injector: Injector,
    protected dynamicComponentService: DynamicComponentService,
    protected customizeBarService: CustomizeBarService,
    protected analyticsService: UniversalAnalyticsService,
    protected projectSettingsService: ProjectSettingsService,
    protected resourceGeneratorResolver: ResourceGeneratorResolver,
    protected notificationService: NotificationService
  ) {
    super(injector, dynamicComponentService, customizeBarService, analyticsService);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.updateSections();
    }
  }

  updateSections() {
    this.templateProvider
      .getGroupSections(this.item, this.context)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.sections = result;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.scrollToTop.emit(false);
  }

  editResource() {
    const resource = this.item.resource;
    const settings = getResourceSettingsComponent(resource.typeItem.name);

    if (!settings) {
      return;
    }

    this.resourceEditController
      .editResource(resource, {
        resourceNameEditing: true,
        analyticsSource: 'component_templates'
      })
      .subscribe();
  }

  reload() {
    this.reloadLoading = true;
    this.cd.markForCheck();

    const generator = this.resourceGeneratorResolver.get(this.item.resource.typeItem.name);

    if (generator) {
      this.projectSettingsService
        .syncResource(this.item.resource, generator, { mergeExisting: true })
        .pipe(untilDestroyed(this))
        .subscribe(
          res => {
            this.update.emit();
            this.reloadLoading = false;
            this.cd.markForCheck();

            this.notificationService.success('Synchronized', 'Resource was successfully updated');
          },
          e => {
            console.error(e);
            this.reloadLoading = false;
            this.cd.markForCheck();

            this.notificationService.error('Synchronized Failed', 'Resource structure update failed');
          }
        );
    } else {
      this.notificationService.error('Synchronized not available', 'No synchronization mechanics found');
    }
  }

  updateReload() {
    if (this.item.resource) {
      const generator = this.resourceGeneratorResolver.get(this.item.resource.typeItem.name);
      this.reloadAllowed =
        this.projectSettingsService.syncAllowed(this.item.resource, generator) && !this.item.resource.demo;
    } else {
      this.reloadAllowed = false;
    }

    this.cd.markForCheck();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i2 from "@angular/material/menu";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "./query-builder-response-keys.component";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/bidi";
var styles_QueryBuilderResponseKeysComponent = [];
var RenderType_QueryBuilderResponseKeysComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderResponseKeysComponent, data: {} });
export { RenderType_QueryBuilderResponseKeysComponent as RenderType_QueryBuilderResponseKeysComponent };
function View_QueryBuilderResponseKeysComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selected.emit(_v.parent.context.$implicit.path) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[2, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_6 = true; _ck(_v, 1, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).role; var currVal_1 = i0.ɵnov(_v, 1)._highlighted; var currVal_2 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_7); }); }
function View_QueryBuilderResponseKeysComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-query-builder-response-keys", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.selected.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QueryBuilderResponseKeysComponent_0, RenderType_QueryBuilderResponseKeysComponent)), i0.ɵdid(2, 49152, [["children", 4]], 0, i5.QueryBuilderResponseKeysComponent, [], { items: [0, "items"] }, { selected: "selected" }), (_l()(), i0.ɵeld(3, 16777216, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "mouseenter"], [null, "mousedown"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5)._handleMousedown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i0.ɵnov(_v, 5)._handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5)._handleClick($event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.selected.emit(_v.parent.context.$implicit.path) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_MatMenuItem_0, i1.RenderType_MatMenuItem)), i0.ɵdid(4, 180224, [[2, 4]], 0, i2.MatMenuItem, [i0.ElementRef, i3.DOCUMENT, i4.FocusMonitor, [2, i2.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), i0.ɵdid(5, 1196032, null, 0, i2.MatMenuTrigger, [i6.Overlay, i0.ElementRef, i0.ViewContainerRef, i2.MAT_MENU_SCROLL_STRATEGY, [2, i2.MatMenu], [6, i2.MatMenuItem], [2, i7.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵted(6, 0, [" ", " "])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.children; _ck(_v, 2, 0, currVal_0); var currVal_8 = true; _ck(_v, 4, 0, currVal_8); var currVal_9 = i0.ɵnov(_v, 2).menu; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 4).role; var currVal_2 = i0.ɵnov(_v, 4)._highlighted; var currVal_3 = i0.ɵnov(_v, 4)._triggersSubmenu; var currVal_4 = i0.ɵnov(_v, 4)._getTabIndex(); var currVal_5 = i0.ɵnov(_v, 4).disabled.toString(); var currVal_6 = (i0.ɵnov(_v, 4).disabled || null); var currVal_7 = (i0.ɵnov(_v, 5).menuOpen || null); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _v.parent.context.$implicit.label; _ck(_v, 6, 0, currVal_10); }); }
function View_QueryBuilderResponseKeysComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_5)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit.children == null) ? null : _v.context.$implicit.children.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.children.length; _ck(_v, 4, 0, currVal_1); }, null); }
function View_QueryBuilderResponseKeysComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "mat-menu-item-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No items"]))], null, null); }
function View_QueryBuilderResponseKeysComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_3)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_6)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.items == null) ? null : _co.items.length) == 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_QueryBuilderResponseKeysComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "mat-menu-item-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Send request first"]))], null, null); }
function View_QueryBuilderResponseKeysComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_QueryBuilderResponseKeysComponent_7)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.items; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_QueryBuilderResponseKeysComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { menu: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "mat-menu", [], null, null, null, i1.View_MatMenu_0, i1.RenderType_MatMenu)), i0.ɵprd(6144, null, i2.ɵf24, null, [i2.MatMenu]), i0.ɵdid(3, 1294336, [[1, 4]], 2, i2.MatMenu, [i0.ElementRef, i0.NgZone, i2.MAT_MENU_DEFAULT_OPTIONS], { overlapTrigger: [0, "overlapTrigger"] }, null), i0.ɵqud(603979776, 2, { items: 1 }), i0.ɵqud(335544320, 3, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_QueryBuilderResponseKeysComponent_1)), i0.ɵdid(7, 147456, [[3, 4]], 0, i2.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i3.DOCUMENT], null, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_QueryBuilderResponseKeysComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-query-builder-response-keys", [], null, null, null, View_QueryBuilderResponseKeysComponent_0, RenderType_QueryBuilderResponseKeysComponent)), i0.ɵdid(1, 49152, null, 0, i5.QueryBuilderResponseKeysComponent, [], null, null)], null, null); }
var QueryBuilderResponseKeysComponentNgFactory = i0.ɵccf("app-query-builder-response-keys", i5.QueryBuilderResponseKeysComponent, View_QueryBuilderResponseKeysComponent_Host_0, { items: "items" }, { selected: "selected" }, []);
export { QueryBuilderResponseKeysComponentNgFactory as QueryBuilderResponseKeysComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../import/components/import-models/import-models.component.ngfactory";
import * as i2 from "../../../../import/components/import-models/import-models-field.array";
import * as i3 from "../../../../import/components/import-models/import-models.form";
import * as i4 from "../../../../model-queries/services/model-description/model-description.service";
import * as i5 from "../../../../model-queries/stores/model-description.store";
import * as i6 from "../../../../projects/stores/current-project.store";
import * as i7 from "../../../../projects/stores/current-environment.store";
import * as i8 from "../../../../queries/services/query/query.service";
import * as i9 from "../../../../../common/form-utils/services/form-utils";
import * as i10 from "../../../../action-queries/stores/action.store";
import * as i11 from "../../../../import/services/import/import.service";
import * as i12 from "../../../../resources/services/resource-controller/resource-controller.service";
import * as i13 from "../../../../list/stores/columns-model-list.store";
import * as i14 from "../../../../model-queries/services/model-utils/model-utils.service";
import * as i15 from "../../../../model-queries/services/reduced-model/reduced-model.service";
import * as i16 from "../../../../messages/services/message/message.service";
import * as i17 from "../../../../model-queries/services/model/model.service";
import * as i18 from "../../../../data-sources-queries/services/model-description-data-source/model-description-data-source.service";
import * as i19 from "../../../../export/services/export/export.service";
import * as i20 from "../../../../import/components/import-models/import-models.component";
import * as i21 from "../../../../../common/popups/components/base-popup/base-popup.component";
import * as i22 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i23 from "./jet-database-import-file.component";
var styles_JetDatabaseImportFileComponent = [];
var RenderType_JetDatabaseImportFileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JetDatabaseImportFileComponent, data: {} });
export { RenderType_JetDatabaseImportFileComponent as RenderType_JetDatabaseImportFileComponent };
export function View_JetDatabaseImportFileComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-import-models", [], null, [[null, "cancelled"], [null, "imported"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelled" === en)) {
        var pd_0 = (_co.cancel($event.modelDescription) !== false);
        ad = (pd_0 && ad);
    } if (("imported" === en)) {
        var pd_1 = (_co.finish($event.modelDescription) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ImportModelsComponent_0, i1.RenderType_ImportModelsComponent)), i0.ɵprd(512, null, i2.ImportModelsFieldArray, i2.ImportModelsFieldArray, [i0.Injector]), i0.ɵprd(131584, null, i3.ImportModelsForm, i3.ImportModelsForm, [i4.ModelDescriptionService, i5.ModelDescriptionStore, i6.CurrentProjectStore, i7.CurrentEnvironmentStore, i8.QueryService, i9.FormUtils, i10.ActionStore, i11.ImportService, i12.ResourceControllerService, i2.ImportModelsFieldArray]), i0.ɵprd(131584, null, i13.ColumnsModelListStore, i13.ColumnsModelListStore, [i14.ModelUtilsService, i15.ReducedModelService, i8.QueryService, i16.MessageService, i0.Injector, i17.ModelService, i6.CurrentProjectStore, i7.CurrentEnvironmentStore, i5.ModelDescriptionStore, i18.ModelDescriptionDataSourceService, i12.ResourceControllerService]), i0.ɵprd(512, null, i19.ExportService, i19.ExportService, [i13.ColumnsModelListStore, i6.CurrentProjectStore]), i0.ɵdid(5, 245760, null, 0, i20.ImportModelsComponent, [i3.ImportModelsForm, [2, i21.BasePopupComponent], i11.ImportService, i19.ExportService, i22.UniversalAnalyticsService, i0.ChangeDetectorRef], { resource: [0, "resource"], analyticsSource: [1, "analyticsSource"] }, { cancelled: "cancelled", imported: "imported" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.analyticsSource; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_JetDatabaseImportFileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-jet-database-import-file", [], null, null, null, View_JetDatabaseImportFileComponent_0, RenderType_JetDatabaseImportFileComponent)), i0.ɵdid(1, 49152, null, 0, i23.JetDatabaseImportFileComponent, [[2, i21.BasePopupComponent]], null, null)], null, null); }
var JetDatabaseImportFileComponentNgFactory = i0.ɵccf("app-jet-database-import-file", i23.JetDatabaseImportFileComponent, View_JetDatabaseImportFileComponent_Host_0, { resource: "resource", analyticsSource: "analyticsSource" }, { cancelled: "cancelled", added: "added" }, []);
export { JetDatabaseImportFileComponentNgFactory as JetDatabaseImportFileComponentNgFactory };

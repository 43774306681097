import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { SlackJoinPopupController } from '@modules/slack-components';

@Component({
  selector: 'app-open-slack-popup',
  templateUrl: './open-slack-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenSlackPopupComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private currentProjectStore: CurrentProjectStore,
    private slackJoinPopupController: SlackJoinPopupController,
    private routing: RoutingService
  ) {}

  ngOnInit() {
    if (this.mode == AdminMode.Builder && this.currentProjectStore.instance.features.isSlackChannelEnabled()) {
      this.slackJoinPopupController.open().pipe(untilDestroyed(this)).subscribe();
    }

    this.routing.navigateApp(this.currentProjectStore.instance.homeLink, { replaceUrl: true });
  }

  ngOnDestroy(): void {}
}

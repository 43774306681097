<app-element-wrapper
  *ngIf="visible || element.loadInvisible || (customizeEnabled$ | async)"
  [wrap]="element.style == tabsStyles.Wrap"
>
  <div
    *ngIf="element"
    class="tabs2"
    [style.display]="element.loadInvisible && !visible && !(customizeEnabled$ | async) ? 'none' : null"
  >
    <app-element-wrapper [wrap]="element.style == tabsStyles.Background">
      <div class="tabs2-navigation" [class.tabs2-navigation_border]="element.style == tabsStyles.Wrap">
        <app-tabs-layout-navigation-item
          *ngFor="let tab of element.items; let i = index"
          [tab]="tab"
          [context]="context"
          [active]="activeItemUid === tab.uid"
          [isVisible]="tabsVisibleById$.value[tab.uid]"
          (click)="setActiveItemUid(tab.uid, true)"
        ></app-tabs-layout-navigation-item>
      </div>
    </app-element-wrapper>

    <ng-container *ngFor="let tab of element.items; let t = index; trackBy: trackTab">
      <div
        *ngIf="!element.loadOnShow || (element.loadOnShow && (activeItemUid === tab.uid || itemsOpened[tab.uid]))"
        class="tabs2__item"
        [class.tabs2__item_active]="activeItemUid === tab.uid"
      >
        <div
          class="placeholder"
          [class.placeholder_active]="customizeService.layoutEnabled$ | async"
          appDropList
          [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
          [appDropListEnterPredicate]="canEnter"
          [appDropListData]="tab.children"
          [appDropListSwapDistance]="20"
          [appDropListAreaMarginForward]="element.style == tabsStyles.Background ? [-64, 0, 0, 0] : [-45, 0, 0, 0]"
          [appDropListAreaMarginBackward]="[0, 0, 0, 0]"
          [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
          [appDropListOutsideIndicator]="true"
          (appDropListDragStarted)="updateElementStates(t)"
          (appDropListEntered)="updateElementStates(t)"
          (appDropListExited)="updateElementStates(t)"
          (appDropListSorted)="updateElementStates(t)"
          (appDropListDropped)="dragDrop(t, $event)"
          (appDropListDragged)="updateElementStatesOnStable(t)"
          appElementGroupsContainer
        >
          <app-auto-element
            *ngFor="let item of tab.children; let i = index; trackBy: trackElement"
            appDrag
            [appDragData]="item"
            [element]="item"
            [context]="context"
            [customizing]="customizeService.layoutEnabled$ | async"
            [accentColor]="'accentColor' | appThemeColorHexOption | async"
            (duplicatedRequested)="duplicateItem(t, i)"
            (replaceRequested)="replaceItem(t, i, $event)"
            (deleteRequested)="deleteItem(item)"
            (moveToRequested)="moveItemTo(item, $event)"
            (visibleUpdated)="updateElementStates(t)"
          >
            <app-element-placeholder-stub
              *appDragPlaceholder
              [elementType]="item.type"
              class="element-placeholder"
            ></app-element-placeholder-stub>

            <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
          </app-auto-element>
          <div class="placeholder__border"></div>
        </div>
      </div>
    </ng-container>
  </div>
</app-element-wrapper>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { AppDrag, AppDragDrop, DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { MenuBlockLayout } from '@modules/menu';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl
} from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';

@Component({
  selector: 'app-section-menu-item-edit',
  templateUrl: './section-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionMenuItemEditComponent implements OnInit {
  @Input() control: CustomizeBarPagesEditSectionControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  iconPopoverOpened = false;
  editPopoverOpened = false;
  createdMenuItemControl: CustomizeBarPagesEditMenuItemControl;
  createdMenuItemControlOptions: AddMenuItemOptions;
  dragAxis = DragAxis;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.createdOptions && this.createdOptions.customize) {
      this.openEditPopover(true);
    }
  }

  isDroppable(item: AppDrag<CustomizeBarPagesEditMenuItemControl>): boolean {
    return !(item.data instanceof CustomizeBarPagesEditSectionControl);
  }

  onTitleChanged(value: string) {
    this.control.controls.title.patchValue(value);
    this.cd.markForCheck();
  }

  openIconPopover(value) {
    this.iconPopoverOpened = value;
    this.cd.markForCheck();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  addMenuItem(options: AddMenuItemOptions) {
    this.createdMenuItemControl = this.control.children.addControl(options.item, options.prepend);
    this.createdMenuItemControlOptions = options;

    this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
  }

  dragDrop(event: AppDragDrop<CustomizeBarPagesEditMenuItemArray>) {
    if (event.previousContainer === event.container) {
      moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferFormArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}

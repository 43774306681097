import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { ViewSettingsStore } from '@modules/customize';
import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectSelectSource } from '@modules/projects';

import { ProjectCopyInterfaceForm } from './project-copy-interface.form';

@Component({
  selector: 'app-project-copy-interface',
  templateUrl: './project-copy-interface.component.html',
  providers: [ProjectCopyInterfaceForm, ProjectSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCopyInterfaceComponent implements OnInit, OnDestroy {
  constructor(
    public form: ProjectCopyInterfaceForm,
    public source: ProjectSelectSource,
    private notificationService: NotificationService,
    private dashboardStore: DashboardStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsStore: ViewSettingsStore,
    private popupComponent: BasePopupComponent,
    private dialogService: DialogService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  submit() {
    const projectCopy = this.form.form.value['project_copy'];

    this.dialogService
      .warning({
        title: 'Copy',
        description: `Are you sure want to copy current App interface settings from <strong>${projectCopy.name}</strong>?`
      })
      .pipe(
        filter(result => result),
        switchMap(() => this.form.submit()),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          // this.dashboardStore.getFirst(true);
          // this.modelDescriptionStore.getFirst(true);
          // this.viewSettingsStore.getFirst(true);

          this.close();

          this.notificationService.success(
            'Copied',
            `<strong>Current project</strong> settings was successfully copied from <strong>${projectCopy.name}</strong>`
          );

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error(
              'Error',
              `Saving <strong>Current project</strong> failed: ${error.errors[0]}`
            );
          } else {
            this.notificationService.error('Error', error);
          }
        }
      );
  }

  cancel() {
    this.close();
  }

  close() {
    this.popupComponent.close();
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import assign from 'lodash/assign';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { ApiService } from '@modules/api';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { BUILT_IN_PARAMS, CURSOR_NEXT_PARAM, CURSOR_PREV_PARAM, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { HttpQueryService, QueryPagination } from '@modules/queries';
import { CurrentUserStore } from '@modules/users';
import { generateUUID, isSet } from '@shared';
var QueryTokensService = /** @class */ (function () {
    function QueryTokensService(currentUserStore, currentProjectStore, currentEnvironmentStore, httpQueryService, apiService, projectApiService) {
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.httpQueryService = httpQueryService;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
    }
    QueryTokensService.prototype.mergeTokens = function () {
        var tokens = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            tokens[_i] = arguments[_i];
        }
        return assign.apply(void 0, [{}].concat(tokens));
    };
    QueryTokensService.prototype.generalTokens = function () {
        var tokens = {
            jet: {
                uuid: generateUUID()
            }
        };
        var user = this.currentUserStore.instance;
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        // TODO: Remove deprecated tokens
        tokens['user_uid'] = user ? user.uid : undefined;
        tokens['user_email'] = user ? user.email : undefined;
        tokens['user_token'] = this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined;
        tokens['user_properties'] = environment && environment.user ? environment.user.properties : {};
        tokens['user_group_uid'] = environment && environment.group ? environment.group.uid : undefined;
        tokens['user_group_properties'] = environment && environment.group ? environment.group.properties : {};
        tokens['user'] = {
            uid: environment && environment.user ? environment.user.uid : undefined,
            email: user ? user.email : undefined,
            token: this.apiService.getAccessToken() ? this.apiService.getAccessToken() : undefined,
            project_token: this.projectApiService.getAccessToken() ? this.projectApiService.getAccessToken() : undefined,
            properties: environment && environment.user ? environment.user.properties : {},
            group: {
                uid: environment && environment.group ? environment.group.uid : undefined,
                properties: environment && environment.group ? environment.group.properties : {}
            }
        };
        tokens['project'] = {
            unique_name: project ? project.uniqueName : undefined
        };
        tokens['env'] = {
            unique_name: environment ? environment.uniqueName : undefined
        };
        return tokens;
    };
    QueryTokensService.prototype.modelGetTokens = function (params, parameters, userQuery) {
        var tokens = {};
        var filters = {};
        if (params) {
            toPairs(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
                    return;
                }
                filters[key] = value;
            });
        }
        tokens['params'] = this.serializeParams(parameters, filters);
        tokens['search'] = params[SEARCH_PARAM];
        // if (userQuery) {
        //   tokens['params'] = strictParamsTokens(tokens['params']);
        // }
        if (params[ORDER_BY_PARAM]) {
            var ascending = params[ORDER_BY_PARAM][0] != '-';
            var field = ascending ? params[ORDER_BY_PARAM] : params[ORDER_BY_PARAM].substring(1);
            // TODO: Remove deprecated tokens
            tokens['sorting_field'] = field;
            tokens['sorting_asc'] = ascending;
            tokens['sorting_desc'] = !ascending;
            tokens['sorting'] = {
                field: field,
                asc: ascending,
                desc: !ascending
            };
        }
        else {
            // TODO: Remove deprecated tokens
            tokens['sorting_field'] = undefined;
            tokens['sorting_asc'] = undefined;
            tokens['sorting_desc'] = undefined;
            tokens['sorting'] = {
                field: undefined,
                asc: undefined,
                desc: undefined
            };
        }
        return tokens;
    };
    QueryTokensService.prototype.modelGetDetailTokens = function (params, parameters, userQuery) {
        var tokens = {};
        var inputs = {};
        if (params) {
            toPairs(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
                    return;
                }
                inputs[key] = value;
            });
        }
        tokens['params'] = this.serializeParams(parameters, inputs);
        // if (userQuery) {
        //   tokens['params'] = strictParamsTokens(tokens['params']);
        // }
        return tokens;
    };
    QueryTokensService.prototype.parametersMeta = function (parameters) {
        if (!parameters) {
            return {};
        }
        return fromPairs(parameters.map(function (item) { return [item.name, item.serialize()]; }));
    };
    QueryTokensService.prototype.modelCreateTokens = function (data, parameters) {
        var tokens = {
            params: data,
            fields: parameters ? parameters.map(function (item) { return item.name; }) : undefined,
            fieldsMeta: this.parametersMeta(parameters)
        };
        // TODO: Remove deprecated tokens
        tokens['record'] = data;
        return tokens;
    };
    QueryTokensService.prototype.modelUpdateTokens = function (data, parameters, primaryKeyValue, fields) {
        var tokens = {
            params: data,
            fields: parameters ? parameters.map(function (item) { return item.name; }) : undefined,
            fieldsMeta: this.parametersMeta(parameters),
            onlyFields: fields
        };
        // TODO: Remove deprecated tokens
        tokens['PK'] = primaryKeyValue;
        tokens['record'] = data;
        return tokens;
    };
    QueryTokensService.prototype.modelDeleteTokens = function (data, primaryKeyValue) {
        var tokens = {
            params: data
        };
        // TODO: Remove deprecated tokens
        tokens['PK'] = primaryKeyValue;
        return tokens;
    };
    QueryTokensService.prototype.modelAggregateTokens = function (yFunc, yColumn, parameters, params, userQuery) {
        var tokens = {};
        var filters = {};
        if (params) {
            toPairs(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
                    return;
                }
                filters[key] = value;
            });
        }
        tokens['params'] = this.serializeParams(parameters, filters);
        // if (userQuery) {
        //   tokens['params'] = strictParamsTokens(tokens['params']);
        // }
        tokens['y_func'] = yFunc;
        tokens['y_column'] = yColumn;
        return tokens;
    };
    QueryTokensService.prototype.modelGroupTokens = function (xColumns, yFunc, yColumn, parameters, params, userQuery) {
        var tokens = {};
        var filters = {};
        if (params) {
            toPairs(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (BUILT_IN_PARAMS.includes(key) || value === undefined) {
                    return;
                }
                filters[key] = value;
            });
        }
        tokens['params'] = this.serializeParams(parameters, filters);
        // if (userQuery) {
        //   tokens['params'] = strictParamsTokens(tokens['params']);
        // }
        var indexName = function (name, i) {
            if (i == 0) {
                return name;
            }
            else {
                return name + "_" + (i + 1);
            }
        };
        xColumns.forEach(function (item, i) {
            tokens[indexName('x_column', i)] = item.xColumn;
            tokens[indexName('x_lookup', i)] = item.xLookup;
        });
        tokens['y_func'] = yFunc;
        tokens['y_column'] = yColumn;
        return tokens;
    };
    QueryTokensService.prototype.paginationTokens = function (pagination, params) {
        var tokens = {};
        if (pagination == QueryPagination.Page || pagination == QueryPagination.Offset) {
            var page = params[PAGE_PARAM] || 1;
            var perPage = params[PER_PAGE_PARAM] || 20;
            // TODO: Remove deprecated tokens
            tokens['page'] = page;
            tokens['offset'] = (page - 1) * perPage;
            tokens['limit'] = perPage;
            tokens['paging'] = {
                page: page,
                offset: (page - 1) * perPage,
                limit: perPage
            };
        }
        else if (pagination == QueryPagination.Cursor) {
            var perPage = params[PER_PAGE_PARAM] || 20;
            // TODO: Remove deprecated tokens
            tokens['cursor_first'] = params[CURSOR_PREV_PARAM];
            tokens['cursor_last'] = params[CURSOR_NEXT_PARAM];
            tokens['limit'] = perPage;
            tokens['paging'] = {
                cursor_prev: params[CURSOR_PREV_PARAM],
                cursor_next: params[CURSOR_NEXT_PARAM],
                limit: perPage
            };
        }
        return tokens;
    };
    QueryTokensService.prototype.uploadFileTokens = function (file, path, fileName) {
        return {
            file: file,
            file_name: isSet(fileName) ? fileName : file.name,
            file_mime_type: file.type,
            path: path
        };
    };
    QueryTokensService.prototype.storageGetObjectsTokens = function (path) {
        return {
            path: path
        };
    };
    QueryTokensService.prototype.storageDeleteObjectTokens = function (path) {
        return {
            path: path
        };
    };
    QueryTokensService.prototype.storageCreateFolderTokens = function (path) {
        return {
            path: path
        };
    };
    QueryTokensService.prototype.storageGetObjectUrlTokens = function (path, expiresInSec) {
        return {
            path: path,
            expires: expiresInSec
        };
    };
    QueryTokensService.prototype.actionExecuteTokens = function (params, parameters, userQuery) {
        var tokens = {};
        if (params) {
            tokens['model'] = params.model ? params.model : undefined;
            tokens['id'] = params.id ? params.id : undefined;
            tokens['ids'] = params.ids ? params.ids : undefined;
            tokens['inverseIds'] = params.inverseIds ? params.inverseIds : undefined;
            tokens['params'] = this.serializeParams(parameters, params.actionParams);
            // tokens['data'] = params.actionParams ? params.actionParams : {}; // Backward compatibility
            tokens['record'] = params.modelInstance ? params.modelInstance.serialize() : {};
            // if (userQuery) {
            //   tokens['params'] = strictParamsTokens(tokens['params']);
            // }
        }
        return tokens;
    };
    QueryTokensService.prototype.typeHasArrayValue = function (parameter) {
        if (parameter.field == FieldType.JSON) {
            return true;
        }
        else if (parameter.field == FieldType.MultipleSelect) {
            return true;
        }
        else if (parameter.field == FieldType.Location) {
            return true;
        }
        else {
            return false;
        }
    };
    QueryTokensService.prototype.serializeParams = function (parameters, params) {
        var _this = this;
        return __assign({}, params, parameters.reduce(function (acc, parameter) {
            var value = params ? params[parameter.name] : undefined;
            if (isSet(value, true)) {
                var fieldDescription_1 = getFieldDescriptionByType(parameter.field);
                if (fieldDescription_1.serializeValue) {
                    // TODO: Add fields isArray parameter
                    if (isArray(value) && !_this.typeHasArrayValue(parameter)) {
                        value = value.map(function (item) { return fieldDescription_1.serializeValue(item, parameter); });
                    }
                    else {
                        value = fieldDescription_1.serializeValue(value, parameter);
                    }
                }
            }
            acc[parameter.name] = value;
            return acc;
        }, {}));
    };
    return QueryTokensService;
}());
export { QueryTokensService };

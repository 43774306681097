import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { CrispIntegrationEditPopupComponent } from './components/crisp-integration-edit-popup/crisp-integration-edit-popup.component';
import { DriftIntegrationEditPopupComponent } from './components/drift-integration-edit-popup/drift-integration-edit-popup.component';
import { FacebookPixelIntegrationEditPopupComponent } from './components/facebook-pixel-integration-edit-popup/facebook-pixel-integration-edit-popup.component';
import { GoogleAnalyticsIntegrationEditPopupComponent } from './components/google-analytics-integration-edit-popup/google-analytics-integration-edit-popup.component';
import { GoogleTagManagerIntegrationEditPopupComponent } from './components/google-tag-manager-integration-edit-popup/google-tag-manager-integration-edit-popup.component';
import { HotjarIntegrationEditPopupComponent } from './components/hotjar-integration-edit-popup/hotjar-integration-edit-popup.component';
import { HubspotIntegrationEditPopupComponent } from './components/hubspot-integration-edit-popup/hubspot-integration-edit-popup.component';
import { IntegrationsCodeComponent } from './components/integrations-code/integrations-code.component';
import { IntercomIntegrationEditPopupComponent } from './components/intercom-integration-edit-popup/intercom-integration-edit-popup.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiModule, FieldsModule, SharedModule],
  declarations: [
    CrispIntegrationEditPopupComponent,
    DriftIntegrationEditPopupComponent,
    FacebookPixelIntegrationEditPopupComponent,
    GoogleAnalyticsIntegrationEditPopupComponent,
    GoogleTagManagerIntegrationEditPopupComponent,
    HotjarIntegrationEditPopupComponent,
    HubspotIntegrationEditPopupComponent,
    IntercomIntegrationEditPopupComponent,
    IntegrationsCodeComponent
  ],
  exports: [IntegrationsCodeComponent],
  entryComponents: [
    CrispIntegrationEditPopupComponent,
    DriftIntegrationEditPopupComponent,
    FacebookPixelIntegrationEditPopupComponent,
    GoogleAnalyticsIntegrationEditPopupComponent,
    GoogleTagManagerIntegrationEditPopupComponent,
    HotjarIntegrationEditPopupComponent,
    HubspotIntegrationEditPopupComponent,
    IntercomIntegrationEditPopupComponent
  ]
})
export class IntegrationsComponentsModule {}

import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export class AppFormGroup extends FormGroup {
  private _serverErrors = new BehaviorSubject<string[]>([]);

  get serverErrors(): string[] {
    return this._serverErrors.value;
  }

  get serverErrors$(): Observable<string[]> {
    return this._serverErrors.asObservable();
  }

  setServerErrors(errors: string[]) {
    this._serverErrors.next([...errors]);
  }

  clearServerErrors() {
    this._serverErrors.next([]);
  }
}

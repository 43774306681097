var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import isArray from 'lodash/isArray';
import { ParameterField } from '@modules/fields';
import { getElementByType } from '../elements/element-items';
import { PopupSettings } from '../popup';
import { ViewSettings, ViewSettingsType } from './base';
var CustomViewSettings = /** @class */ (function (_super) {
    __extends(CustomViewSettings, _super);
    function CustomViewSettings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.view = ViewSettingsType.Custom;
        _this.elements = [];
        _this.popups = [];
        return _this;
    }
    CustomViewSettings.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['parameters']) {
            this.parameters = this.params['parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        var mapElement = function (item) {
            var element = getElementByType(item['type']);
            if (!element) {
                console.error("Unsupported element type: " + item['type']);
                return;
            }
            return new element().deserialize(item);
        };
        if (this.params && this.params['elements']) {
            this.elements = this.params['elements'].map(mapElement).filter(function (item) { return item != undefined; });
        }
        else if (this.params && isArray(this.params)) {
            this.elements = this.params.map(mapElement).filter(function (item) { return item != undefined; });
        }
        if (this.params['popups']) {
            this.popups = this.params['popups'].map(function (item) { return new PopupSettings().deserialize(item); });
        }
        return this;
    };
    CustomViewSettings.prototype.serialize = function (fields) {
        this.params = {};
        this.params['parameters'] = this.parameters.map(function (item) { return item.serialize(); });
        this.params['elements'] = this.elements.map(function (item) { return item.serialize(); });
        this.params['popups'] = this.popups.map(function (item) { return item.serialize(); });
        return _super.prototype.serialize.call(this, fields);
    };
    return CustomViewSettings;
}(ViewSettings));
export { CustomViewSettings };

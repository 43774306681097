import { OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, from, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/notifications/services/notification/notification.service";
var PickColorService = /** @class */ (function () {
    function PickColorService(notificationService) {
        this.notificationService = notificationService;
        this.pickSubscriptions = new BehaviorSubject([]);
    }
    PickColorService.prototype.ngOnDestroy = function () { };
    PickColorService.prototype.pick = function () {
        var _this = this;
        if (!('EyeDropper' in window)) {
            this.notificationService.error('Eyedropper is not supported', 'Your browser does not support the EyeDropper API yet');
            return of(undefined);
        }
        var obs$ = new ReplaySubject();
        // @ts-ignore
        var eyeDropper = new window.EyeDropper();
        var subscription = from(eyeDropper.open())
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            obs$.next(result.sRGBHex);
            _this.removePickSubscription(subscription);
        }, function (error) {
            obs$.error(error);
            _this.removePickSubscription(subscription);
        });
        this.addPickSubscription(subscription);
        return obs$.asObservable();
    };
    PickColorService.prototype.isPicking$ = function () {
        return this.pickSubscriptions.pipe(map(function (value) { return value.length > 0; }));
    };
    PickColorService.prototype.addPickSubscription = function (subscription) {
        var value = this.pickSubscriptions.value.concat([subscription]);
        this.pickSubscriptions.next(value);
    };
    PickColorService.prototype.removePickSubscription = function (subscription) {
        var value = this.pickSubscriptions.value.filter(function (item) { return item !== subscription; });
        this.pickSubscriptions.next(value);
    };
    PickColorService.ngInjectableDef = i0.defineInjectable({ factory: function PickColorService_Factory() { return new PickColorService(i0.inject(i1.NotificationService)); }, token: PickColorService, providedIn: "root" });
    return PickColorService;
}());
export { PickColorService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl, Validators } from '@angular/forms';
import { isSet } from '@shared';
var StorageBucketArray = /** @class */ (function (_super) {
    __extends(StorageBucketArray, _super);
    function StorageBucketArray(value, validatorOrOpts, asyncValidator) {
        if (value === void 0) { value = []; }
        var _this = _super.call(this, [], validatorOrOpts, asyncValidator) || this;
        _this.deserialize(value);
        _this.markAsPristine();
        return _this;
    }
    StorageBucketArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.patchValue(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
        return this;
    };
    StorageBucketArray.prototype.createControl = function (value) {
        return new FormControl(isSet(value) ? value : '', Validators.required);
    };
    StorageBucketArray.prototype.appendControl = function (value) {
        var control = this.createControl(value);
        this.push(control);
        return control;
    };
    StorageBucketArray.prototype.removeControl = function (control) {
        var index = this.controls.findIndex(function (item) { return item === control; });
        if (index == -1) {
            return;
        }
        this.removeAt(index);
        this.updateValueAndValidity();
    };
    return StorageBucketArray;
}(FormArray));
export { StorageBucketArray };
export function validateStorageBucketArray() {
    return function (control) {
        if (!control.value || !control.value.length) {
            return { required: true };
        }
    };
}

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ParameterControl } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { isSet } from '@shared';

@Injectable()
export class ImportModelsFieldControl extends FormGroup implements OnDestroy {
  modelDescription: ModelDescription;

  controls: {
    field: ParameterControl;
    source: FormControl;
    active: FormControl;
  };

  constructor() {
    super({
      field: new ParameterControl(),
      source: new FormControl(null),
      active: new FormControl(true)
    });

    this.controls.source.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!this.modelDescription) {
        return;
      }

      const modelCreateParameters = this.modelDescription.createParametersOrDefaults;
      const parameter = isSet(value) ? modelCreateParameters.find(item => item.name == value) : undefined;

      if (parameter) {
        const params = {};

        if (parameter.params['options']) {
          params['options'] = parameter.params['options'];
        }

        if (parameter.params['structure']) {
          params['display_fields'] = parameter.params['display_fields'];
          params['structure'] = parameter.params['structure'];
        }

        this.controls.field.controls.field.patchValue(parameter.field);
        this.controls.field.controls.params.patchValue(params);
      }
    });
  }

  ngOnDestroy(): void {}

  init(modelDescription: ModelDescription) {
    this.modelDescription = modelDescription;

    if (this.modelDescription) {
      this.controls.source.enable();
    } else {
      this.controls.source.disable();
    }
  }
}

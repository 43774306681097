import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, filter, publishLast, refCount, tap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { openUrl } from '@shared';

import { GuideTask } from '../../data/guide-task';
import { TaskScenario, TaskScenarioItem, TaskScenarioPage } from '../../data/task-scenario';
import { TaskType } from '../../data/task-type';
import { GuideSectionStore } from '../../stores/guide-section.store';
import { GuideFocusService } from '../guide-focus/guide-focus.service';
import { GuideTaskProgressService } from '../guide-task-progress/guide-task-progress.service';
import { TaskService } from '../task/task.service';

export interface CurrentTaskState {
  task: GuideTask;
  scenario: TaskScenario;
  page: number;
  pageItem: number;
}

@Injectable()
export class GuideService {
  _state = new BehaviorSubject<CurrentTaskState>(undefined);
  _completedTask = new Subject<CurrentTaskState>();

  constructor(
    private routing: RoutingService,
    private router: Router,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private guideTaskProgressService: GuideTaskProgressService,
    private guideSectionStore: GuideSectionStore,
    private dialogService: DialogService,
    private taskService: TaskService,
    private analyticsService: UniversalAnalyticsService,
    private guideFocusService: GuideFocusService
  ) {
    this.router.events.pipe(filter(item => item instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!this.state) {
        return;
      }

      const currentTaskItemUrl = this.currentPage ? this.currentPage.link : undefined;
      const nextTaskItemPage = this.nextTaskItemPage ? this.nextTaskItemPage.link : undefined;
      const samePage = link => {
        if (!link) {
          return false;
        }

        const currentLink = event.url.toString();
        const regexp = new RegExp(`^${this.routing.appLink(link).join('/')}`);
        return !!currentLink.match(regexp);
      };

      if (!samePage(currentTaskItemUrl) && !samePage(nextTaskItemPage)) {
        this.clearCurrentTask();
      } else if (currentTaskItemUrl != nextTaskItemPage && samePage(nextTaskItemPage)) {
        this.completeTaskProgress(this.state.task.uniqueName, this.state.page, this.statePageItemName);
      }
    });
  }

  get state(): CurrentTaskState {
    return this._state.value;
  }

  get state$(): Observable<CurrentTaskState> {
    return this._state.asObservable();
  }

  set state(value: CurrentTaskState) {
    this._state.next(value);
  }

  get completedTask$(): Observable<CurrentTaskState> {
    return this._completedTask.asObservable();
  }

  setCurrentTask(task: GuideTask): Observable<void> {
    this.clearCurrentTask();

    const obs = this.taskService.getScenario(task.uniqueName as TaskType).pipe(
      tap(scenario => {
        if (isArray(scenario.firstLink)) {
          this.routing.navigateApp(scenario.firstLink);
        } else {
          openUrl(scenario.firstLink, true);
        }
      }),
      delay(1000),
      tap(scenario => {
        if (!scenario.isEmpty) {
          this.dialogService
            .dialog({
              title: `Task – ${task.title}`,
              description: `You are about to start <strong>${task.title}</strong> task. Follow the tips and we will guide you through.`,
              buttons: [
                {
                  name: 'ok',
                  label: 'Get started!',
                  type: DialogButtonType.Primary,
                  hotkey: DialogButtonHotkey.Submit
                }
              ],
              style: 'bright'
            })
            .subscribe(() => {
              this.state = {
                task: task,
                scenario: scenario,
                page: undefined,
                pageItem: undefined
              };
              this.setCurrentTaskProgress(0, 0);
              this.onTaskStarted(task);
            });
        } else {
          this.state = {
            task: task,
            scenario: scenario,
            page: undefined,
            pageItem: undefined
          };
          this.setCurrentTaskProgress(0, 0);
          this.onTaskStarted(task);
        }
      }),
      publishLast(),
      refCount()
    );

    obs.subscribe();

    return obs;
  }

  setCurrentTaskProgress(page: number, pageItem: number) {
    const pageChanged = this.state.page !== page && this.state.page !== undefined;
    const state = clone(this.state);

    state.page = page;
    state.pageItem = pageItem;

    this.state = state;

    if (pageChanged && !this.state.scenario.pages[page].noAutoRedirect) {
      const link = this.state.scenario.pages[page].link;

      if (isArray(link)) {
        this.routing.navigateApp(link);
      } else {
        openUrl(link, true);
      }
    }

    if (page == this.state.scenario.pages.length - 1 && pageItem >= this.state.scenario.pages[page].items.length - 1) {
      this.guideTaskProgressService
        .complete(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          this.state.task
        )
        .subscribe(() => {
          this.guideSectionStore.get(true);
          this.onTaskCompleted(state);
        });
      this.clearCurrentTask();
    }
  }

  completeTaskProgress(task: string, page: number, pageItem: string) {
    if (
      !this.state ||
      this.state.task.uniqueName != task ||
      this.state.page != page ||
      this.statePageItemName != pageItem
    ) {
      return;
    }

    if (
      this.state.pageItem + 1 > this.state.scenario.pages[page].items.length - 1 &&
      page + 1 <= this.state.scenario.pages.length - 1
    ) {
      this.setCurrentTaskProgress(page + 1, 0);
    } else {
      this.setCurrentTaskProgress(page, this.state.pageItem + 1);
    }
  }

  get statePageItem(): TaskScenarioItem {
    if (!this.state || this.state.page == undefined || this.state.pageItem == undefined) {
      return;
    }
    return this.state.scenario.pages[this.state.page].items[this.state.pageItem];
  }

  get statePageItemName(): string {
    return this.statePageItem ? this.statePageItem.name : undefined;
  }

  get currentPage(): TaskScenarioPage {
    if (!this.state || !this.state.scenario.pages.length) {
      return;
    }
    return this.state.scenario.pages[this.state.page];
  }

  get prevTaskItemPage(): TaskScenarioPage {
    if (!this.currentPage) {
      return;
    }

    if (this.state.pageItem == 0 && this.state.page > 0) {
      return this.state.scenario.pages[this.state.page - 1];
    } else if (this.state.pageItem > 0) {
      return this.state.scenario.pages[this.state.page];
    }
  }

  get nextTaskItemPage(): TaskScenarioPage {
    if (!this.currentPage) {
      return;
    }

    if (
      this.state.pageItem == this.currentPage.items.length - 1 &&
      this.state.page < this.state.scenario.pages.length - 1
    ) {
      return this.state.scenario.pages[this.state.page + 1];
    } else if (this.state.pageItem < this.currentPage.items.length - 1) {
      return this.state.scenario.pages[this.state.page];
    }
  }

  clearCurrentTask() {
    this.state = undefined;
    this.guideFocusService.position = undefined;
  }

  onTaskStarted(task: GuideTask) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.TutorialStartTask, { TaskID: task.uniqueName });
  }

  onTaskCompleted(state: CurrentTaskState) {
    this._completedTask.next(state);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.TutorialFinishTask, {
      TaskID: state.task.uniqueName
    });
  }
}

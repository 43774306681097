import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { DeleteComponent } from './components/delete/delete.component';

@NgModule({
  imports: [CommonModule, RoutingModule, ModelQueriesModule, UiModule, SharedModule],
  declarations: [DeleteComponent],
  exports: [DeleteComponent]
})
export class DeleteModule {}

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsPreviousWeekFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_previous_week';
  public param = 'isPreviousWeek';
  public verboseName = localize('is Previous Week');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('week').subtract(1, 'weeks').toISOString();
  }

  to(value: string): string {
    return moment().startOf('week').subtract(1, 'weeks').endOf('week').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not previous week', [field]);
      } else {
        return localize('is not previous week');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is previous week', [field]);
      } else {
        return localize('previous week');
      }
    }
  }
}

<div class="compact-segmented-control" [class.compact-segmented-control_fill]="fill">
  <div
    *ngFor="let item of options"
    class="compact-segmented-control__item compact-segmented-control-item"
    [class.compact-segmented-control-item_active]="item === currentOption"
    [class.compact-segmented-control-item_disabled]="item.disabled"
    [class.compact-segmented-control-item_icon]="(item.icon | appIsSet) && !(item.name | appIsSet)"
    [appTip]="item.subtitle"
    [appTipOptions]="{ side: 'top' }"
    (click)="updateValue(item.value)"
  >
    <div
      *ngIf="item.icon | appIsSet"
      class="compact-segmented-control-item__icon"
      [class.compact-segmented-control-item__icon_left]="item.name | appIsSet"
      [ngClass]="'icon-' + item.icon"
    ></div>

    <div *ngIf="item.name | appIsSet" class="compact-segmented-control-item__label">
      {{ item.name }}
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class SliderFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      tint: new FormControl(null)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const result = {
      tint: value['tint']
    };

    this.control.patchValue({
      ...this.control.value,
      ...result
    });
  }
}

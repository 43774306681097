import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectProtocolService } from './services/project-protocol/project-protocol.service';

@NgModule({
  imports: [CommonModule],
  declarations: []
})
export class ProjectUtilsModule {}

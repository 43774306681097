import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { GuidePopupComponent } from '../../components/guide-popup/guide-popup.component';

@Injectable({
  providedIn: 'root'
})
export class GuidePopupStoreService {
  _component = new BehaviorSubject<GuidePopupComponent>(undefined);

  constructor() {}

  get component(): GuidePopupComponent {
    return this._component.value;
  }

  get component$(): Observable<GuidePopupComponent> {
    return this._component.asObservable();
  }

  registerGuidePopup(component: GuidePopupComponent) {
    this._component.next(component);
  }

  unregisterGuidePopup(component: GuidePopupComponent) {
    if (this._component.value === component) {
      this._component.next(undefined);
    }
  }
}

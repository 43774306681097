import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { PopupRef } from '@common/popups';
import { HotjarIntegration, ProjectSettingsStore } from '@modules/all-project-settings';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { HotjarIntegrationEditPopupForm } from './hotjar-integration-edit-popup.form';

@Component({
  selector: 'app-hotjar-integration-edit-popup',
  templateUrl: './hotjar-integration-edit-popup.component.html',
  providers: [HotjarIntegrationEditPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotjarIntegrationEditPopupComponent implements OnInit, OnDestroy {
  @Input() analyticsSource: string;
  @Output() saved = new EventEmitter<HotjarIntegration>();

  loading = false;
  submitLoading = false;
  createField = createFormFieldFactory();

  constructor(
    @Optional() private popupRef: PopupRef,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public form: HotjarIntegrationEditPopupForm,
    private projectSettingsStore: ProjectSettingsStore,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.projectSettingsStore
      .getAllSettingsFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(
        projectSettings => {
          this.form.init(projectSettings.hotjar);
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.saved.emit(result);
          this.notificationService.success('Saved', 'Hotjar integration was successfully updated');
          this.close();
        },
        () => {
          this.notificationService.error('Error', 'Saving Hotjar integration failed');

          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  cancel() {
    this.close();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../customize-bar-action-edit-parameters/customize-bar-action-edit-parameters.component.ngfactory";
import * as i2 from "../customize-bar-action-edit-parameters/customize-bar-action-edit-parameters.component";
import * as i3 from "../customize-bar-action-edit-type/customize-bar-action-edit-type.component.ngfactory";
import * as i4 from "../customize-bar-action-edit-type/customize-bar-action-edit-type.component";
import * as i5 from "@angular/common";
import * as i6 from "../customize-bar-action-edit-wrapper/customize-bar-action-edit-wrapper.component.ngfactory";
import * as i7 from "../customize-bar-action-edit-wrapper/customize-bar-action-edit-wrapper.component";
import * as i8 from "../../../services/customize-bar-context/customize-bar.context";
import * as i9 from "../../../../queries-components/data/query-builder-context";
import * as i10 from "../../../../users/stores/current-user.store";
import * as i11 from "../../../../projects/stores/current-project.store";
import * as i12 from "../../../../projects/stores/current-environment.store";
import * as i13 from "../../../../domain/stores/domain.store";
import * as i14 from "../../../../api/services/api/api.service";
import * as i15 from "../../../../project-api/services/project-api/project-api.service";
import * as i16 from "./customize-bar-action-edit-operation.component";
var styles_CustomizeBarActionEditOperationComponent = [];
var RenderType_CustomizeBarActionEditOperationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditOperationComponent, data: {} });
export { RenderType_CustomizeBarActionEditOperationComponent as RenderType_CustomizeBarActionEditOperationComponent };
function View_CustomizeBarActionEditOperationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-parameters", [], null, [[null, "addQueryInput"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addQueryInput" === en)) {
        var pd_0 = (_co.addQueryInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CustomizeBarActionEditParametersComponent_0, i1.RenderType_CustomizeBarActionEditParametersComponent)), i0.ɵdid(1, 245760, null, 0, i2.CustomizeBarActionEditParametersComponent, [i0.ChangeDetectorRef], { form: [0, "form"], context: [1, "context"], contextElement: [2, "contextElement"], contextElementPath: [3, "contextElementPath"], contextElementPaths: [4, "contextElementPaths"], userInput: [5, "userInput"], analyticsSource: [6, "analyticsSource"] }, { addQueryInput: "addQueryInput" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.contextElementPath; var currVal_4 = _co.contextElementPaths; var currVal_5 = true; var currVal_6 = _co.object; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CustomizeBarActionEditOperationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submitImmediate.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CustomizeBarActionEditTypeComponent_0, i3.RenderType_CustomizeBarActionEditTypeComponent)), i0.ɵdid(1, 4440064, [[1, 4]], 0, i4.CustomizeBarActionEditTypeComponent, [i0.NgZone], { options: [0, "options"], form: [1, "form"], label: [2, "label"], context: [3, "context"], contextElement: [4, "contextElement"], contextElementPath: [5, "contextElementPath"], contextElementPaths: [6, "contextElementPaths"], firstInit: [7, "firstInit"], setupOnCreate: [8, "setupOnCreate"], analyticsSource: [9, "analyticsSource"] }, { submit: "submit" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomizeBarActionEditOperationComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; var currVal_1 = _co.form; var currVal_2 = _co.label; var currVal_3 = _co.context; var currVal_4 = _co.contextElement; var currVal_5 = _co.contextElementPath; var currVal_6 = _co.contextElementPaths; var currVal_7 = _co.firstInit; var currVal_8 = _co.setupOnCreate; var currVal_9 = _co.object; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform((_co.options.parametersEditable && _co.operationTypeValid$))); _ck(_v, 3, 0, currVal_10); }, null); }
function View_CustomizeBarActionEditOperationComponent_4(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CustomizeBarActionEditOperationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-customize-bar-action-edit-wrapper", [], null, [[null, "submit"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_CustomizeBarActionEditWrapperComponent_0, i6.RenderType_CustomizeBarActionEditWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i7.CustomizeBarActionEditWrapperComponent, [i8.CustomizeBarContext, i0.ChangeDetectorRef], { options: [0, "options"], form: [1, "form"] }, { submit: "submit", cancel: "cancel" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CustomizeBarActionEditOperationComponent_4)), i0.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; var currVal_1 = _co.form; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v.parent, 1); _ck(_v, 3, 0, currVal_2); }, null); }
function View_CustomizeBarActionEditOperationComponent_6(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 1), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_CustomizeBarActionEditOperationComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditOperationComponent_6)), i0.ɵdid(2, 540672, null, 0, i5.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 1); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomizeBarActionEditOperationComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { editTypeComponent: 0 }), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_CustomizeBarActionEditOperationComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditOperationComponent_3)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditOperationComponent_5)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wrapper; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.wrapper; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CustomizeBarActionEditOperationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-customize-bar-action-edit-operation", [], null, null, null, View_CustomizeBarActionEditOperationComponent_0, RenderType_CustomizeBarActionEditOperationComponent)), i0.ɵprd(135680, null, i9.QueryBuilderContext, i9.QueryBuilderContext, [i10.CurrentUserStore, i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i13.DomainStore, i14.ApiService, i15.ProjectApiService]), i0.ɵdid(2, 114688, null, 0, i16.CustomizeBarActionEditOperationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomizeBarActionEditOperationComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-operation", i16.CustomizeBarActionEditOperationComponent, View_CustomizeBarActionEditOperationComponent_Host_0, { wrapper: "wrapper", options: "options", form: "form", label: "label", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", firstInit: "firstInit", setupOnCreate: "setupOnCreate", object: "object" }, { submitImmediate: "submitImmediate", event: "event" }, ["*", "*"]);
export { CustomizeBarActionEditOperationComponentNgFactory as CustomizeBarActionEditOperationComponentNgFactory };

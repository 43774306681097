import { CustomizeBarItem } from './customize-bar-item';

export enum TemplatesSectionGroupDisplay {
  List = 'list',
  Tiles = 'tiles'
}

export interface TemplatesSectionGroup {
  title?: string;
  items: CustomizeBarItem[];
  showAll?: boolean;
  display: TemplatesSectionGroupDisplay;
}

<app-workflow-step-card
  [title]="'Workflow result'"
  [subtitle]="outputs.length | i18nPlural: { '=1': '# output', other: '# outputs' }"
  [icon]="'workflow'"
  [active]="customizing$ | async"
  [indicatorIcon]="statusIcon"
  [indicatorColor]="statusColor"
  [contrast]="true"
  [interactive]="true"
  [connectorTop]="connectorTop"
  [connectorBottom]="false"
  (click)="customize()"
></app-workflow-step-card>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import keys from 'lodash/keys';
import { throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { getCurrentLanguage } from '@common/localize';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { User, UserService } from '@modules/users';
import { isSet, NonEmptyValidator, trimAll } from '@shared';
export function validatePasswordRepeat() {
    return function (control) {
        var group = control.parent;
        if (!group) {
            return null;
        }
        if (isSet(group.controls.password.value) &&
            isSet(group.controls.password_repeat.value) &&
            group.controls.password.value != group.controls.password_repeat.value) {
            return {
                local: ['Passwords do not match']
            };
        }
    };
}
var RegisterCustomFieldsControl = /** @class */ (function (_super) {
    __extends(RegisterCustomFieldsControl, _super);
    function RegisterCustomFieldsControl() {
        return _super.call(this, {}) || this;
    }
    RegisterCustomFieldsControl.prototype.init = function (customFields) {
        var _this = this;
        if (customFields === void 0) { customFields = []; }
        customFields.forEach(function (field) {
            var control = new FormControl(undefined, field.required ? Validators.required : undefined);
            _this.setControl(field.name, control);
        });
        keys(this.controls)
            .filter(function (name) { return !customFields.find(function (item) { return item.name == name; }); })
            .forEach(function (name) { return _this.removeControl(name); });
    };
    return RegisterCustomFieldsControl;
}(FormGroup));
export { RegisterCustomFieldsControl };
var RegisterForm = /** @class */ (function (_super) {
    __extends(RegisterForm, _super);
    function RegisterForm(authService, apiService, userService, formUtils) {
        var _this = _super.call(this, {
            email: new FormControl('', [Validators.required, Validators.email]),
            first_name: new FormControl('', [Validators.required, NonEmptyValidator("This field can't be empty")]),
            last_name: new FormControl(''),
            password: new FormControl('', Validators.required),
            password_repeat: new FormControl('', [Validators.required, validatePasswordRepeat()]),
            no_email_subscription: new FormControl(false),
            agree_with_privacy_policy: new FormControl(true, Validators.requiredTrue),
            custom_fields: new RegisterCustomFieldsControl()
        }) || this;
        _this.authService = authService;
        _this.apiService = apiService;
        _this.userService = userService;
        _this.formUtils = formUtils;
        _this.submit = false;
        return _this;
    }
    RegisterForm.prototype.init = function (customFields) {
        if (customFields === void 0) { customFields = []; }
        this.controls.custom_fields.init(customFields);
    };
    RegisterForm.prototype.markAsSubmit = function () {
        this.submit = true;
        this.markAsDirty();
    };
    RegisterForm.prototype.register = function (projectName, transferDemoProjects) {
        var _this = this;
        var user = new User();
        user.username = trimAll(this.controls.email.value);
        user.email = trimAll(this.controls.email.value);
        user.firstName = trimAll(this.controls.first_name.value);
        user.lastName = trimAll(this.controls.last_name.value);
        user.password = this.controls.password.value;
        user.emailSubscription = !this.controls.no_email_subscription.value;
        var projectToken = this.apiService.getProjectToken();
        var language = getCurrentLanguage();
        var customFields = this.controls.custom_fields.value;
        return this.userService
            .create(user, {
            projectName: projectName,
            projectToken: projectToken,
            transferDemoProjects: transferDemoProjects,
            language: language,
            customFields: customFields
        })
            .pipe(delayWhen(function () {
            return _this.authService.login(_this.controls.email.value, _this.controls.password.value);
        }), catchError(function (error) {
            _this.markAsDirty();
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return RegisterForm;
}(FormGroup));
export { RegisterForm };

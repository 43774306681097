import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionService, WorkflowStepInfo } from '@modules/action-queries';
import { CustomViewSettings, ElementType, ViewSettingsStore } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { AutomationService, WorkflowBackendRunWithRelations, WorkflowRunStatus } from '@modules/workflow';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-automation-runs-item, [app-automation-runs-item]',
  templateUrl: './automation-runs-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomationRunsItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: WorkflowBackendRunWithRelations;

  stepsInfo: WorkflowStepInfo[] = [];
  stepsMore = 0;
  duration: string;
  pageInfo: {
    page: CustomViewSettings;
    element?: {
      type: ElementType;
      label: string;
    };
  };
  hover = false;
  statuses = WorkflowRunStatus;

  @HostListener('mouseenter') onEnter() {
    this.hover = true;
    this.cd.markForCheck();
  }

  @HostListener('mouseleave') onLeave() {
    this.hover = false;
    this.cd.markForCheck();
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionService: ActionService,
    private automationService: AutomationService,
    private viewSettingsStore: ViewSettingsStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.item.isActive()) {
      const subscription = this.automationService
        .subscribeWorkflowRun(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.item = result;
          this.cd.markForCheck();
          this.updateDuration();

          if (!result.isActive()) {
            subscription.unsubscribe();
          }
        });
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AutomationRunsItemComponent>): void {
    if (changes.item) {
      this.updateStepsInfo();
      this.updateDuration();
      this.updatePageInfo();
    }
  }

  updateStepsInfo() {
    const maxSteps = 3;
    const steps = this.item.workflow ? this.item.workflow.flattenSteps() : [];

    this.actionService
      .getStepsInfo(steps.slice(0, maxSteps))
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.stepsInfo = result;
          this.stepsMore = steps.length - maxSteps;
          this.cd.markForCheck();
        },
        () => {
          this.stepsInfo = [];
          this.stepsMore = 0;
          this.cd.markForCheck();
        }
      );
  }

  updateDuration() {
    if (this.item.dateRun && this.item.dateFinished) {
      const seconds = moment(this.item.dateFinished).diff(moment(this.item.dateRun), 'seconds');

      if (seconds == 0) {
        this.duration = undefined;
      } else if (seconds >= 60) {
        this.duration = `${Math.round(seconds / 60)} min.`;
      } else {
        this.duration = `${seconds} sec.`;
      }
    } else {
      this.duration = undefined;
    }

    this.cd.markForCheck();
  }

  updatePageInfo() {
    if (!isSet(this.item.pageUid)) {
      this.pageInfo = undefined;
      this.cd.markForCheck();
      return;
    }

    this.viewSettingsStore
      .getDetailByUidFirst<CustomViewSettings>(this.item.pageUid)
      .pipe(untilDestroyed(this))
      .subscribe(viewSettings => {
        this.pageInfo = viewSettings
          ? {
              page: viewSettings,
              element: this.item.elementType
                ? {
                    type: this.item.elementType,
                    label: this.item.elementLabel
                  }
                : undefined
            }
          : undefined;
        this.cd.markForCheck();
      });
  }
}

import * as i0 from "@angular/core";
var CustomHttpParameterCodec = /** @class */ (function () {
    function CustomHttpParameterCodec() {
    }
    CustomHttpParameterCodec.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    CustomHttpParameterCodec.prototype.encodeValue = function (key) {
        return encodeURIComponent(key);
    };
    CustomHttpParameterCodec.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    CustomHttpParameterCodec.prototype.decodeValue = function (key) {
        return decodeURIComponent(key);
    };
    CustomHttpParameterCodec.ngInjectableDef = i0.defineInjectable({ factory: function CustomHttpParameterCodec_Factory() { return new CustomHttpParameterCodec(); }, token: CustomHttpParameterCodec, providedIn: "root" });
    return CustomHttpParameterCodec;
}());
export { CustomHttpParameterCodec };

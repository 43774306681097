var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { BaseUploadComponent } from '../base-upload.component';
var VideoUploadComponent = /** @class */ (function (_super) {
    __extends(VideoUploadComponent, _super);
    function VideoUploadComponent(sanitizer, currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) || this;
        _this.sanitizer = sanitizer;
        return _this;
    }
    VideoUploadComponent.prototype.onUploadedUpdated = function () {
        if (!this.uploadedFile) {
            return;
        }
        var youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(this.uploadedFile.url);
        var vimeoMatch = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(this.uploadedFile.url);
        var previewFile;
        var previewEmbed;
        if (youtubeMatch) {
            previewEmbed = "https://www.youtube.com/embed/" + youtubeMatch[5] + "?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1";
        }
        else if (vimeoMatch) {
            previewEmbed = "https://player.vimeo.com/video/" + vimeoMatch[4] + "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media";
        }
        else {
            previewFile = this.uploadedFile.url;
        }
        this.previewFile = previewFile;
        this.previewEmbed = previewEmbed ? this.sanitizer.bypassSecurityTrustResourceUrl(previewEmbed) : undefined;
        this.cd.markForCheck();
    };
    return VideoUploadComponent;
}(BaseUploadComponent));
export { VideoUploadComponent };

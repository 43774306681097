import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class ShareMenuItem extends MenuItem {
  public type = MenuItemType.Share;

  clone(): ShareMenuItem {
    const instance = new ShareMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Share, ShareMenuItem);

import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UniqueIdToken } from '@common/unique-id';
import { ViewSettingsAction } from '@modules/actions';
import { AlignHorizontal, ElementType, elementTypeResize, generateElementNameInContext, getElementByType, ViewContext } from '@modules/customize';
import { FieldType, getFieldDescriptionByType, Input as FieldInput } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { defaultComponentTemplateName } from '@modules/template';
import { forceObservable, getFilename, getImageSize, isSet, keyboardChars } from '@shared';
import { ViewEditorContext, ViewEditorTool } from '../../services/view-editor-context/view-editor.context';
var ViewEditorToolsItemComponent = /** @class */ (function () {
    function ViewEditorToolsItemComponent(currentProjectStore, currentEnvironmentStore, editorContext, projectStorageService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.editorContext = editorContext;
        this.projectStorageService = projectStorageService;
        this.injector = injector;
        this.cd = cd;
        this.opened = false;
        this.first = false;
        this.last = false;
        this.elementTemplates = [];
        this.elementTemplatesLoading = false;
        this.openTool = new EventEmitter();
        this.closeTool = new EventEmitter();
        this.toolActive = false;
        this.childToolActive = false;
        this.toolElements = [
            {
                type: ElementType.Action,
                title: 'Button',
                subtitle: 'Basic',
                image: 'button',
                init: function (element) {
                    var name = isSet(element.name) ? element.name : 'Button';
                    if (!element.actionItem) {
                        element.actionItem = new ViewSettingsAction();
                    }
                    element.actionItem.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
                }
            },
            {
                type: ElementType.ActionGroup,
                title: 'Button group',
                subtitle: 'Basic',
                image: 'button_group',
                init: function (element) {
                    var name = isSet(element.name) ? element.name : 'Button group';
                    element.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
                }
            },
            {
                type: ElementType.ActionDropdown,
                title: 'Dropdown',
                subtitle: 'Basic',
                image: 'button_dropdown',
                init: function (element) {
                    var name = isSet(element.name) ? element.name : 'Button';
                    element.verboseNameInput = new FieldInput().deserializeFromStatic('value', name);
                }
            }
        ].concat([
            {
                field: FieldType.Text,
                label: 'Text Field'
            },
            // {
            //   field: FieldType.RichText,
            //   label: 'Rich Text Field'
            // },
            {
                field: FieldType.Boolean
            },
            {
                field: FieldType.RelatedModel,
                fieldParams: {
                    resetEnabled: true
                }
            },
            {
                field: FieldType.Select,
                fieldParams: {
                    resetEnabled: true
                }
            },
            {
                field: FieldType.MultipleSelect,
                fieldParams: {
                    resetEnabled: true
                }
            },
            {
                field: FieldType.RadioButton
            },
            {
                field: FieldType.DateTime
            },
            // {
            //   field: FieldType.Date
            // },
            {
                field: FieldType.Time
            },
            {
                field: FieldType.File,
                label: 'File Picker'
            },
            {
                field: FieldType.Image,
                label: 'Image Picker'
            },
            {
                field: FieldType.Password
            },
            {
                field: FieldType.Number
            },
            {
                field: FieldType.Rating
            },
            {
                field: FieldType.Slider
            },
            {
                field: FieldType.URL
            },
            // {
            //   field: FieldType.Location
            // },
            {
                field: FieldType.Video,
                label: 'Video Picker'
            },
            {
                field: FieldType.Audio,
                label: 'Audio Picker'
            },
            {
                field: FieldType.Color
            }
            // {
            //   field: FieldType.JSON
            // }
        ].map(function (field) {
            var fieldDescription = getFieldDescriptionByType(field.field);
            return {
                type: ElementType.Field,
                title: field.label || fieldDescription.label,
                subtitle: 'Fields',
                image: fieldDescription.image,
                init: function (item) {
                    item.alignHorizontal = AlignHorizontal.Justify;
                    item.settings = {
                        field: field.field,
                        editable: true,
                        params: field.fieldParams || {}
                    };
                }
            };
        }));
        this.dropdownPositions = [
            { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
        ];
        this.viewEditorTools = ViewEditorTool;
        this.idToken = new UniqueIdToken();
    }
    ViewEditorToolsItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editorContext.tool$.pipe(untilDestroyed(this)).subscribe(function (tool) {
            var activeChildTool = _this.tool.children && tool ? _this.tool.children.find(function (item) { return item.tool == tool; }) : undefined;
            _this.toolActive = _this.tool.tool && _this.tool.tool == tool;
            _this.childToolActive = !!_this.tool.children && tool && !!_this.tool.children.find(function (item) { return item.tool == tool; });
            if (activeChildTool) {
                _this.defaultChildTool = activeChildTool;
            }
            _this.cd.markForCheck();
        });
    };
    ViewEditorToolsItemComponent.prototype.ngOnDestroy = function () { };
    ViewEditorToolsItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.tool) {
            this.defaultChildTool = this.tool.children ? this.tool.children[0] : undefined;
        }
    };
    ViewEditorToolsItemComponent.prototype.onToolClick = function (tool, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (tool.tool && !tool.toolFile && !tool.toolElement) {
            this.editorContext.tool$.next(tool.tool);
        }
        if (tool.tool && tool.toolElement && options.element) {
            var elementCls_1 = getElementByType(options.element.type);
            if (elementCls_1) {
                var elementBase_1 = new elementCls_1().deserialize({
                    type: options.element.type
                });
                if (options.element.init) {
                    options.element.init(elementBase_1);
                }
                var resize$ = forceObservable(elementTypeResize(elementBase_1.type, { injector: this.injector, element: elementBase_1 }));
                combineLatest(resize$)
                    .pipe(untilDestroyed(this))
                    .subscribe(function (_a) {
                    var resize = _a[0];
                    var width;
                    var height;
                    var template = _this.elementTemplates.find(function (i) { return i.uniqueName == defaultComponentTemplateName(elementBase_1); });
                    var params = template ? template.element.serialize()['params'] : {};
                    var element = new elementCls_1().deserialize({
                        type: options.element.type,
                        params: params
                    });
                    if (resize.width) {
                        width = element.width;
                        element.width = undefined;
                    }
                    if (resize.height) {
                        height = element.height;
                        element.height = undefined;
                    }
                    if (options.element.init) {
                        options.element.init(element);
                    }
                    generateElementNameInContext(element, _this.viewContext, { name: element.defaultName() });
                    var widthFluid = !resize.width;
                    var heightFluid = !resize.height;
                    _this.editorContext.tool$.next(tool.tool);
                    _this.editorContext.toolElement$.next({
                        element: element,
                        width: width,
                        widthFluid: widthFluid,
                        height: height,
                        heightFluid: heightFluid
                    });
                });
            }
        }
        this.toolForFile = tool.toolFile ? tool : undefined;
    };
    ViewEditorToolsItemComponent.prototype.onRootToolClick = function (tool) {
        if (tool.toolElement && this.elementTemplatesLoading) {
            return;
        }
        this.onToolClick(tool);
        if (!tool.tool && this.defaultChildTool) {
            this.editorContext.tool$.next(this.defaultChildTool.tool);
        }
        if (tool.children || tool.toolElement) {
            this.openTool.emit();
        }
    };
    ViewEditorToolsItemComponent.prototype.onToolFileChange = function (el) {
        var _this = this;
        if (!el.files || !el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        var toolForFile = this.toolForFile;
        if (!toolForFile) {
            return;
        }
        var filePath = ['custom_views', this.editorContext.view$.value.id].join('/');
        var fileName = file.name;
        var fileUrl = URL.createObjectURL(file);
        this.toolFileUploading = toolForFile;
        this.toolForFile = undefined;
        this.cd.markForCheck();
        combineLatest(this.projectStorageService.uploadFile(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, file, filePath, fileName), getImageSize(fileUrl).pipe(catchError(function () { return of(undefined); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var response = _a[0], imageSize = _a[1];
            if (response.result) {
                _this.editorContext.tool$.next(toolForFile.tool);
                _this.editorContext.toolFile$.next({
                    fileName: getFilename(fileName),
                    url: response.result.uploadedUrl,
                    size: imageSize
                });
                _this.toolForFile = undefined;
                _this.toolFileUploading = undefined;
                _this.cd.markForCheck();
            }
        }, function () {
            _this.toolFileUploading = undefined;
            _this.cd.markForCheck();
        });
    };
    ViewEditorToolsItemComponent.prototype.getKeyChar = function (code) {
        var result = keyboardChars.find(function (item) { return item.code == code; });
        return result ? result.char : undefined;
    };
    return ViewEditorToolsItemComponent;
}());
export { ViewEditorToolsItemComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, timer } from 'rxjs';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ParameterArray, ParameterField } from '@modules/fields';
import { Resource } from '@modules/projects';
import { ObjectQueryOperation } from '@modules/queries';
import { FirebaseResourceController, OBJECT_QUERY_KEY_LABEL, OBJECT_QUERY_KEY_NAME, ResourceControllerService } from '@modules/resources';
import { controlValue, filterObject, limitObjectLength } from '@shared';
import { QueryBuilderContext } from '../../data/query-builder-context';
import { QueryBuilderObjectForm } from './query-builder-object.form';
var ResultsSection;
(function (ResultsSection) {
    ResultsSection[ResultsSection["Parameters"] = 0] = "Parameters";
    ResultsSection[ResultsSection["Result"] = 1] = "Result";
})(ResultsSection || (ResultsSection = {}));
var QueryBuilderObjectComponent = /** @class */ (function () {
    function QueryBuilderObjectComponent(firebaseResourceController, form, resourceControllerService, analyticsService, cd) {
        var _this = this;
        this.firebaseResourceController = firebaseResourceController;
        this.form = form;
        this.resourceControllerService = resourceControllerService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.requireResponse = false;
        this.arrayResponse = false;
        this.options = {};
        this.customSections = [];
        this.executed = new EventEmitter();
        this.saved = new EventEmitter();
        this.canceled = new EventEmitter();
        this.objectLoading = false;
        this.loading = false;
        this.requestCreate = false;
        this.responseRaw$ = new BehaviorSubject(undefined);
        this.responseActual = false;
        this.resultsSection = ResultsSection.Result;
        this.resultsSections = ResultsSection;
        this.customSectionComponentsTop = [];
        this.customSectionComponentsBottom = [];
        this.customSectionComponentsPreview = [];
        this.saveHovered = new BehaviorSubject(false);
        this.requireResponseMessageHovered = new BehaviorSubject(false);
        this.requireResponseMessageVisible$ = combineLatest(this.saveHovered.pipe(filter(function (value) { return (value && _this.isResponseMissing()) || !value; }), debounce(function (value) { return timer(value ? 0 : 200); })), this.requireResponseMessageHovered).pipe(map(function (_a) {
            var saveHovered = _a[0], requireResponseMessageHovered = _a[1];
            return saveHovered || requireResponseMessageHovered;
        }));
        this.objectQueryOperations = ObjectQueryOperation;
    }
    QueryBuilderObjectComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = this.control.value;
        this.form.init(this.control.value, this.arrayResponse, this.currentOptions);
        //   this.context.sqlForm = this.form;
        //   this.context.paginationTokens = false;
        //   this.context.sortingTokens = false;
        if (query) {
            this.requestCreate = false;
            this.context.tokenValues = query.requestTokens;
            if (query.requestResponse !== undefined) {
                this.response = query.requestResponse;
                this.responseActual = true;
                this.cd.markForCheck();
            }
        }
        else {
            this.requestCreate = true;
            this.context.tokenValues = {};
        }
        merge.apply(void 0, values(this.form.controls)
            .filter(function (control) {
            return [_this.form.controls.request_response, _this.form.controls.request_tokens].every(function (item) { return item !== control; });
        })
            .map(function (control) { return control.valueChanges; })).pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function () {
            _this.responseActual = false;
            _this.cd.markForCheck();
        });
        this.updateCustomSections();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.StartToSetUp, {
            ResourceType: this.resource.typeItem.name,
            Source: this.source
        });
        this.fetchObject();
        combineLatest(controlValue(this.form.controls.operation), controlValue(this.form.controls.query), controlValue(this.form.controls.object_to_array))
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var operation = _a[0];
            _this.executeDisplay(operation == ObjectQueryOperation.Get);
        });
        controlValue(this.form.controls.operation)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.resultsSection =
                _this.form.controls.operation.value == ObjectQueryOperation.Get
                    ? ResultsSection.Result
                    : ResultsSection.Parameters;
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.form.controls.operation), this.responseRaw$)
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var operation = _a[0], response = _a[1];
            var responseObject = isArray(response) ? response[0] : values(response)[0];
            var objectKeyParameter = function (required, description) {
                if (required === void 0) { required = true; }
                var result = new ParameterField();
                result.name = OBJECT_QUERY_KEY_NAME;
                result.verboseName = OBJECT_QUERY_KEY_LABEL;
                result.required = required;
                result.protected = true;
                result.description = description;
                if (!required) {
                    result.placeholder = 'Populated automatically if empty';
                }
                result.updateFieldDescription();
                return result;
            };
            if (operation == ObjectQueryOperation.Create) {
                var parameters = responseObject
                    ? keys(responseObject).map(function (key) {
                        var result = new ParameterField();
                        result.name = key;
                        result.required = true;
                        result.updateFieldDescription();
                        return result;
                    })
                    : [];
                _this.parametersControl.patchValue([
                    objectKeyParameter(false, 'Create Object with the following key')
                ].concat(parameters));
            }
            else if (operation == ObjectQueryOperation.Update) {
                var parameters = responseObject
                    ? keys(responseObject).map(function (key) {
                        var result = new ParameterField();
                        result.name = key;
                        result.required = true;
                        result.updateFieldDescription();
                        return result;
                    })
                    : [];
                _this.parametersControl.patchValue([
                    objectKeyParameter(true, 'Update Object with the following key')
                ].concat(parameters));
            }
            else if (operation == ObjectQueryOperation.Delete) {
                _this.parametersControl.patchValue([objectKeyParameter(true, 'Delete Object with the following key')]);
            }
            else {
                _this.parametersControl.patchValue([]);
            }
        });
    };
    QueryBuilderObjectComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(QueryBuilderObjectComponent.prototype, "currentOptions", {
        get: function () {
            return __assign({}, this.options);
        },
        enumerable: true,
        configurable: true
    });
    QueryBuilderObjectComponent.prototype.setResultsSection = function (resultsSection) {
        this.resultsSection = resultsSection;
        this.cd.markForCheck();
    };
    QueryBuilderObjectComponent.prototype.updateCustomSections = function () {
        this.customSectionComponentsTop = this.customSections
            .filter(function (item) { return item.position == 'top'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsBottom = this.customSections
            .filter(function (item) { return item.position == 'bottom'; })
            .map(function (item) { return item.component; });
        this.customSectionComponentsPreview = this.customSections
            .filter(function (item) { return item.position == 'preview'; })
            .map(function (item) { return item.component; });
        this.cd.markForCheck();
    };
    QueryBuilderObjectComponent.prototype.fetchObject = function () {
        var _this = this;
        this.objectLoading = true;
        this.cd.markForCheck();
        var controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;
        controller
            .objectGet(this.resource)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.object = result;
            _this.objectLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.object = undefined;
            _this.objectLoading = false;
            _this.cd.markForCheck();
        });
    };
    QueryBuilderObjectComponent.prototype.execute = function () {
        var query = this.form.getInstance();
        if (query.operation == ObjectQueryOperation.Get) {
            return this.executeDisplay();
        }
        else {
            return this.executeOperation();
        }
    };
    QueryBuilderObjectComponent.prototype.executeDisplay = function (showResponse) {
        var _this = this;
        if (showResponse === void 0) { showResponse = true; }
        var query = this.form.getInstance();
        var saveTokens = this.context.serializeTokenValues(true);
        this.loading = true;
        this.response = undefined;
        this.responseRaw$.next(undefined);
        this.error = undefined;
        if (showResponse) {
            this.resultsSection = ResultsSection.Result;
        }
        this.cd.markForCheck();
        var controller = this.resource
            ? this.resourceControllerService.get(this.resource.type)
            : undefined;
        controller
            .objectGet(this.resource, query.query, __assign({}, query.queryOptions, { objectToArray: false }))
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.response = result;
            _this.responseRaw$.next(result);
            _this.response = controller.objectProcess(_this.response, query.queryOptions);
            _this.responseActual = true;
            _this.loading = false;
            _this.context.lastExecutedResponse = _this.response;
            _this.form.controls.request_response.patchValue(limitObjectLength(_this.response, 20));
            _this.form.controls.request_tokens.patchValue(filterObject(saveTokens, function (item) { return !(item instanceof Blob); }));
            _this.executed.emit(query);
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        }, function (error) {
            if (error.fieldErrors['error']) {
                _this.error = error.fieldErrors['error'];
            }
            else {
                _this.error = 'Unknown error';
            }
            _this.responseActual = true;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.UnsuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        });
    };
    QueryBuilderObjectComponent.prototype.executeOperation = function () {
        var _this = this;
        var query = this.form.getInstance();
        var requestTokens = this.context.serializeTokenValues();
        this.loading = true;
        // this.response = undefined;
        this.error = undefined;
        this.cd.markForCheck();
        var controller = this.resource ? this.resourceControllerService.get(this.resource.type) : undefined;
        controller
            .objectQuery(this.resource, query, requestTokens['params'])
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            // this.response = result;
            // this.responseActual = true;
            _this.loading = false;
            // this.context.lastExecutedResponse = this.response;
            // this.form.patchValue({
            //   request_response: limitObjectLength(this.response, 20),
            //   request_tokens: filterObject(tokens, item => !(item instanceof Blob))
            // });
            _this.executed.emit(query);
            _this.cd.markForCheck();
            _this.fetchObject();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        }, function (error) {
            if (error.fieldErrors['error']) {
                _this.error = error.fieldErrors['error'];
            }
            else {
                _this.error = 'Unknown error';
            }
            // this.responseActual = true;
            _this.loading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.UnsuccessfullyPerformed, {
                ResourceType: _this.resource.typeItem.name
            });
        });
    };
    QueryBuilderObjectComponent.prototype.cancel = function () {
        this.canceled.emit();
    };
    QueryBuilderObjectComponent.prototype.saveProcess = function () {
        var query = this.form.getInstance();
        this.control.patchValue(query);
        this.saved.emit();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ObjectBuilder.SuccessfullySetUp, {
            ResourceType: this.resource.typeItem.name
        });
    };
    QueryBuilderObjectComponent.prototype.isResponseMissing = function () {
        return this.requireResponse && !this.responseActual;
    };
    QueryBuilderObjectComponent.prototype.save = function () {
        if (this.isResponseMissing()) {
            return;
        }
        this.saveProcess();
    };
    QueryBuilderObjectComponent.prototype.submit = function () {
        this.save();
    };
    return QueryBuilderObjectComponent;
}());
export { QueryBuilderObjectComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../../fields/components/auto-field/auto-field.component";
import * as i3 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i4 from "../../../../parameters-components/components/input-edit/input-edit.component.ngfactory";
import * as i5 from "../../../../parameters-components/components/input-edit/input-edit.component";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i8 from "@angular/common";
import * as i9 from "../color-with-input-selector/color-with-input-selector.component.ngfactory";
import * as i10 from "../color-with-input-selector/color-with-input-selector.component";
import * as i11 from "./icon-fill-selector.component";
import * as i12 from "../../../services/view-editor-context/view-editor.context";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../projects/stores/current-project.store";
import * as i15 from "../../../../projects/stores/current-environment.store";
import * as i16 from "../../../../resources/services/project-storage/project-storage.service";
var styles_IconFillSelectorComponent = [];
var RenderType_IconFillSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconFillSelectorComponent, data: {} });
export { RenderType_IconFillSelectorComponent as RenderType_IconFillSelectorComponent };
function View_IconFillSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "compact-inputs-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "compact-inputs-row__item compact-inputs-row__item_fill"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "app-auto-field", [], null, null, null, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 2), i0.ɵpod(6, { classes: 0 }), i0.ɵpod(7, { name: 0, label: 1, field: 2, params: 3 }), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "compact-inputs-row__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "compact-button compact-button_primary icon-function"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleImageInputEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 737280, null, 0, i3.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(11, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.createField(_ck(_v, 7, 0, "icon", "Icon", "IconField", _ck(_v, 6, 0, _ck(_v, 5, 0, "select_small", "select_fill")))); var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Set up with Formula"; var currVal_4 = _ck(_v, 11, 0, "top"); _ck(_v, 10, 0, currVal_3, currVal_4); }, null); }
function View_IconFillSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-input-edit", [], null, null, null, i4.View_InputEditComponent_0, i4.RenderType_InputEditComponent)), i0.ɵdid(3, 770048, null, 0, i5.InputEditComponent, [i6.FocusMonitor, i7.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], { itemForm: [0, "itemForm"], context: [1, "context"], staticValueDisabled: [2, "staticValueDisabled"], focusedInitial: [3, "focusedInitial"], formulaPlaceholder: [4, "formulaPlaceholder"], fill: [5, "fill"], dark: [6, "dark"], darker: [7, "darker"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "compact-inputs-element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "compact-button-tag button-tags__item_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleImageInputEnabled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "compact-button-tag__icon icon-function"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "compact-button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Disable Formula"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control.controls.icon_input; var currVal_1 = _co.viewContext; var currVal_2 = true; var currVal_3 = _co.iconInputFocused; var currVal_4 = "Icon with Formula"; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_IconFillSelectorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconFillSelectorComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconFillSelectorComponent_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-color-with-input-selector", [], null, null, null, i9.View_ColorWithInputSelectorComponent_0, i9.RenderType_ColorWithInputSelectorComponent)), i0.ɵdid(5, 114688, null, 0, i10.ColorWithInputSelectorComponent, [i0.ChangeDetectorRef], { colorControl: [0, "colorControl"], colorInputEnabledControl: [1, "colorInputEnabledControl"], colorInputControl: [2, "colorInputControl"], viewContext: [3, "viewContext"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.control.controls.icon_input_enabled.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.control.controls.icon_input_enabled.value; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.control.controls.color; var currVal_3 = _co.control.controls.color_input_enabled; var currVal_4 = _co.control.controls.color_input; var currVal_5 = _co.viewContext; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_IconFillSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-fill-selector", [], null, null, null, View_IconFillSelectorComponent_0, RenderType_IconFillSelectorComponent)), i0.ɵdid(1, 245760, null, 0, i11.IconFillSelectorComponent, [i12.ViewEditorContext, i13.DomSanitizer, i14.CurrentProjectStore, i15.CurrentEnvironmentStore, i16.ProjectStorageService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconFillSelectorComponentNgFactory = i0.ɵccf("app-icon-fill-selector", i11.IconFillSelectorComponent, View_IconFillSelectorComponent_Host_0, { control: "control", viewContext: "viewContext" }, {}, []);
export { IconFillSelectorComponentNgFactory as IconFillSelectorComponentNgFactory };

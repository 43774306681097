import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { ascComparator, isSet, pointsDistance, radToDeg, square } from '@shared';

import { Frame } from './frame';
import { GradientStop } from './gradient-stop';
import { Point } from './point';

export enum GradientType {
  Linear = 'linear',
  Radial = 'radial',
  Angular = 'angular',
  Diamond = 'diamond'
}

export class Gradient {
  type: GradientType = GradientType.Linear;
  from: Point;
  to: Point;
  stops: GradientStop[] = [];
  aspectRatio = 1;

  constructor(options: Partial<Gradient> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['type']) {
      this.type = data['type'];
    }

    if (data['from']) {
      this.from = new Point().deserialize(data['from']);
    }

    if (data['to']) {
      this.to = new Point().deserialize(data['to']);
    }

    if (data['stops']) {
      this.stops = data['stops'].map(item => new GradientStop().deserialize(item));
    }

    if (isSet(data['aspect_ratio'])) {
      this.aspectRatio = data['aspect_ratio'];
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      from: this.from ? this.from.serialize() : undefined,
      to: this.to ? this.to.serialize() : undefined,
      stops: this.stops.map(item => item.serialize()),
      aspect_ratio: this.aspectRatio
    };
  }

  css$(
    options: {
      frame?: Frame;
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<{ background?: string; width?: string; height?: string; transform?: string }> {
    options = {
      frame: new Frame({ width: 1, height: 1 }),
      ...options
    };

    const from = {
      x: this.from.x * options.frame.width,
      y: this.from.y * options.frame.height
    };
    const to = {
      x: this.to.x * options.frame.width,
      y: this.to.y * options.frame.height
    };
    const angle = this.getAngle(options.frame);
    const stops$ = this.stops.length
      ? combineLatest(
          this.stops.map(item => {
            return item
              .cssColor$({
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
              })
              .pipe(
                map(color => {
                  return {
                    stop: item,
                    color: color
                  };
                })
              );
          })
        )
      : of([]);

    return combineLatest(stops$).pipe(
      map(([stops]) => {
        if (this.type == GradientType.Radial) {
          const height = pointsDistance(to.x, to.y, from.x, from.y);
          const width = this.aspectRatio * height;
          const stopsCss = stops
            .filter(item => item.color)
            .sort((lhs, rhs) => ascComparator(lhs.position, rhs.position))
            .map(item => {
              const position = `${item.stop.position * 100}%`;
              return `${item.color} ${position}`;
            });

          if (!stopsCss.length) {
            return {};
          }

          if (angle == 0 || angle == 180) {
            const position = `${this.from.x * 100}% ${this.from.y * 100}%`;

            return {
              background: `radial-gradient(ellipse ${width}px ${height}px at ${position}, ${stopsCss.join(', ')})`
            };
          } else if (angle == 90 || angle == 270) {
            const position = `${this.from.x * 100}% ${this.from.y * 100}%`;

            return {
              background: `radial-gradient(ellipse ${height}px ${width}px at ${position}, ${stopsCss.join(', ')})`
            };
          } else {
            const sizeMultiplier = 5;
            const fromTransform = {
              x: (this.from.x * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100,
              y: (this.from.y * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100
            };

            return {
              background: `radial-gradient(ellipse ${width}px ${height}px at 50% 50%, ${stopsCss.join(', ')})`,
              width: `${sizeMultiplier * 100}%`,
              height: `${sizeMultiplier * 100}%`,
              transform: `translate(-50%, -50%) translate(${fromTransform.x}%, ${fromTransform.y}%) rotate(${angle}deg)`
            };
          }
        } else if (this.type == GradientType.Angular) {
          const stopsCss = stops
            .filter(item => item.color)
            .sort((lhs, rhs) => ascComparator(lhs.position, rhs.position))
            .map(item => {
              const position = `${item.stop.position * 100}%`;
              return `${item.color} ${position}`;
            });

          if (!stopsCss.length) {
            return {};
          }

          if (this.aspectRatio == 1) {
            const position = `${this.from.x * 100}% ${this.from.y * 100}%`;

            return {
              background: `conic-gradient(from ${angle}deg at ${position}, ${stopsCss.join(', ')})`
            };
          } else {
            const aspectRatio = Math.max(this.aspectRatio, 0.01);
            const widthMultiplier = (1 / aspectRatio) * 5;
            const heightMultiplier = 5;
            const fromTransform = {
              x: (this.from.x * (1 / widthMultiplier) - 1 / widthMultiplier / 2) * 100,
              y: (this.from.y * (1 / heightMultiplier) - 1 / heightMultiplier / 2) * 100
            };

            return {
              background: `conic-gradient(at 50% 50%, ${stopsCss.join(', ')})`,
              width: `${widthMultiplier * 100}%`,
              height: `${heightMultiplier * 100}%`,
              transform: `translate(-50%, -50%) translate(${fromTransform.x}%, ${fromTransform.y}%) rotate(${angle}deg) scaleX(${aspectRatio})`
            };
          }
        } else if (this.type == GradientType.Diamond) {
          const aspectRatio = Math.max(this.aspectRatio, 0.01);
          const widthMultiplier = (1 / aspectRatio) * 5;
          const heightMultiplier = 5;
          const sizeMultiplier = Math.ceil(Math.max(widthMultiplier, heightMultiplier));
          const length = pointsDistance(this.to.x, this.to.y, this.from.x, this.from.y);
          const stopsCss = stops
            .filter(item => item.color)
            .sort((lhs, rhs) => ascComparator(lhs.position, rhs.position))
            .map(item => {
              const position = `${((item.stop.position * length) / sizeMultiplier) * 100}%`;
              return `${item.color} ${position}`;
            });

          if (!stopsCss.length) {
            return {};
          }

          const fromTransform = {
            x: (this.from.x * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100,
            y: (this.from.y * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100
          };
          const background = [
            `linear-gradient(to right bottom, ${stopsCss.join(', ')}) right bottom / 50% 50% no-repeat`,
            `linear-gradient(to left bottom, ${stopsCss.join(', ')}) left bottom / 50% 50% no-repeat`,
            `linear-gradient(to left top, ${stopsCss.join(', ')}) left top / 50% 50% no-repeat`,
            `linear-gradient(to right top, ${stopsCss.join(', ')}) right top / 50% 50% no-repeat`
          ].join(', ');
          const scaleToSquare = options.frame.height / options.frame.width;

          return {
            background: background,
            width: `${sizeMultiplier * 100}%`,
            height: `${sizeMultiplier * 100}%`,
            transform: `translate(-50%, -50%) translate(${fromTransform.x}%, ${fromTransform.y}%) rotate(${angle}deg) scaleX(${scaleToSquare}) scaleX(${aspectRatio})`
          };
        } else {
          let getPosition: (position: number) => number;

          if (to.x - from.x == 0) {
            const startOuter = angle == 180 ? from.y < 0 : from.y > options.frame.height;
            const startDistance = angle == 180 ? from.y : options.frame.height - from.y;
            const fromToDistance = Math.abs(to.y - from.y);
            let totalDistance = options.frame.height;

            if (startOuter) {
              totalDistance += Math.abs(startDistance);
            }

            getPosition = position => {
              return (startDistance + position * fromToDistance) / totalDistance;
            };
          } else if (to.y - from.y == 0) {
            const startOuter = angle == 90 ? from.x < 0 : from.x > options.frame.width;
            const startDistance = angle == 90 ? from.x : options.frame.width - from.x;
            const fromToDistance = Math.abs(to.x - from.x);
            let totalDistance = options.frame.width;

            if (startOuter) {
              totalDistance += Math.abs(startDistance);
            }

            getPosition = position => {
              return (startDistance + position * fromToDistance) / totalDistance;
            };
          } else {
            const k = (to.y - from.y) / (to.x - from.x);
            const b = (to.x * from.y - from.x * to.y) / (to.x - from.x);
            const edgeK = -1 / k;
            const edgeB = [
              { x: 0, y: 0 },
              { x: options.frame.width, y: 0 },
              { x: options.frame.width, y: options.frame.height },
              { x: 0, y: options.frame.height }
            ].map(({ x, y }) => {
              return y - edgeK * x;
            });
            const edgeMaxB = Math.max(...edgeB);
            const edgeMinB = Math.min(...edgeB);
            const minB = {
              x: ((edgeMinB - b) * k) / (square(k) + 1),
              y: (edgeMinB * square(k) + b) / (square(k) + 1)
            };
            const maxB = {
              x: ((edgeMaxB - b) * k) / (square(k) + 1),
              y: (edgeMaxB * square(k) + b) / (square(k) + 1)
            };
            const fromB = angle >= 90 && angle <= 270 ? minB : maxB;
            const startB = from.y - edgeK * from.x;
            const startOuter = angle >= 90 && angle <= 270 ? startB < edgeMinB : startB > edgeMaxB;
            let startDistance = Math.sqrt(square(from.x - fromB.x) + square(from.y - fromB.y));
            const fromToDistance = Math.sqrt(square(to.x - from.x) + square(to.y - from.y));
            let totalDistance = Math.sqrt(square(maxB.x - minB.x) + square(maxB.y - minB.y));

            if (startOuter) {
              totalDistance += startDistance;
              startDistance *= -1;
            }

            getPosition = position => {
              return (startDistance + position * fromToDistance) / totalDistance;
            };
          }

          const stopsCss = stops
            .filter(item => item.color)
            .sort((lhs, rhs) => ascComparator(lhs.position, rhs.position))
            .map(item => {
              const position = `${getPosition(item.stop.position) * 100}%`;
              return `${item.color} ${position}`;
            });

          return {
            background: `linear-gradient(${angle}deg, ${stopsCss.join(', ')})`
          };
        }
      })
    );
  }

  getAngle(frame = new Frame({ width: 1, height: 1 })): number {
    const from = {
      x: this.from.x * frame.width,
      y: this.from.y * frame.height
    };
    const to = {
      x: this.to.x * frame.width,
      y: this.to.y * frame.height
    };
    const delta = {
      x: to.x - from.x,
      y: from.y - to.y
    };

    if (delta.y == 0) {
      return delta.x >= 0 ? 90 : 270;
    } else {
      const angle = radToDeg(Math.atan(delta.x / delta.y));

      if (delta.x >= 0 && delta.y >= 0) {
        return angle;
      } else if (delta.x >= 0 && delta.y < 0) {
        return 180 + angle;
      } else if (delta.x < 0 && delta.y < 0) {
        return 180 + angle;
      } else if (delta.x < 0 && delta.y >= 0) {
        return 360 + angle;
      }
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSet } from '@shared';
import { CurrentEnvironmentStore } from './current-environment.store';
var ResourceSelectSource = /** @class */ (function (_super) {
    __extends(ResourceSelectSource, _super);
    function ResourceSelectSource(currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.allowEmpty = false;
        _this.emptyName = '---';
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    Object.defineProperty(ResourceSelectSource.prototype, "emptyOption", {
        get: function () {
            return {
                value: null,
                name: this.emptyName
            };
        },
        enumerable: true,
        configurable: true
    });
    ResourceSelectSource.prototype.image = function (uniqueName) {
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == uniqueName; });
        if (!resource) {
            return;
        }
        return resource.icon;
    };
    ResourceSelectSource.prototype.getResources = function () {
        var _this = this;
        return this.currentEnvironmentStore.getResourcesFirst().pipe(map(function (resources) {
            if (!resources) {
                return [];
            }
            if (_this.filter != undefined) {
                resources = resources.filter(_this.filter);
            }
            return resources;
        }));
    };
    ResourceSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.getResources().pipe(map(function (result) {
            _this.page += 1;
            var results = result
                .filter(function (item) {
                return isSet(searchQuery)
                    ? [item.name, item.uniqueName].some(function (str) { return str.toLowerCase().includes(searchQuery.toLowerCase()); })
                    : true;
            })
                .map(function (item) {
                return {
                    value: item.uniqueName,
                    name: item.name,
                    image: _this.image(item.uniqueName)
                };
            });
            if (!_this.allowEmpty) {
                return results;
            }
            return [_this.emptyOption].concat(results);
        }));
    };
    ResourceSelectSource.prototype.fetchByValue = function (value) {
        var _this = this;
        if (this.allowEmpty && value === null) {
            return of(this.emptyOption);
        }
        if (!value) {
            return of(undefined);
        }
        return this.getResources().pipe(map(function (result) {
            var resource = result.find(function (i) { return i.uniqueName == value; });
            if (!resource) {
                return;
            }
            return {
                value: resource.uniqueName,
                name: resource.name,
                image: _this.image(resource.uniqueName)
            };
        }));
    };
    ResourceSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    ResourceSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    ResourceSelectSource.prototype.setStaticOptions = function (options) { };
    return ResourceSelectSource;
}(SelectSource));
export { ResourceSelectSource };

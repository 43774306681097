import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { Observable, of } from 'rxjs';

import { SUBMIT_RESULT_OUTPUT, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { TransformWorkflowStep, WorkflowStepType } from '@modules/workflow';
import { TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { workflowStepsToken } from '../../workflow/workflow.component';
import { WorkflowRunParams, WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

interface State {
  step?: TransformWorkflowStep;
  name?: string;
  title?: string;
  icon?: string;
  type?: WorkflowStepType;
}

function getStepStateContextInfo(state: State): Object {
  return {
    name: state.name,
    type: state.type,
    icon: state.icon
  };
}

@Component({
  selector: 'app-transform-workflow-step',
  templateUrl: './transform-workflow-step.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransformWorkflowStepComponent extends WorkflowStepComponent<TransformWorkflowStep, State>
  implements OnInit, OnChanges {
  title: string;
  icon: string;

  constructor(
    @Inject(workflowStepsToken) private workflowStepsContextElement: ViewContextElement,
    contextElement: ViewContextElement,
    workflowEditContext: WorkflowEditContext,
    cd: ChangeDetectorRef
  ) {
    super(contextElement, workflowEditContext, cd);
  }

  ngOnInit() {
    this.initContext();
    this.trackRun();
    this.trackExecuteStatus();
    this.stepOnChange(this.step);
    this.trackChanges();

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<TransformWorkflowStepComponent>): void {
    if (changes.step) {
      if (!changes.step.firstChange) {
        this.stepOnChange(this.step);
      }
    }
  }

  getStepState(step: TransformWorkflowStep): Observable<State> {
    return of({
      step: step,
      name: step ? step.name : undefined,
      title: step ? this.getTitle(step) : undefined,
      icon: step ? step.getIcon() : undefined,
      type: step ? step.type : undefined
    });
  }

  onStateUpdated(state: State) {
    this.title = state.title;
    this.icon = this.step.getIcon();

    if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
      this.updateContextInfo(state);
    }
  }

  initContext() {
    this.contextElement.initGlobal(
      {
        uniqueName: this.step.uid,
        name: this.step.name || this.step.type,
        icon: this.step.getIcon(),
        allowSkip: true,
        getFieldValue: (field, outputs) => {
          return outputs[field];
        }
      },
      this.workflowStepsContextElement
    );
  }

  updateContextInfo(state: State) {
    this.contextElement.initInfo(
      {
        name: state.name || state.type,
        icon: state.icon
      },
      true
    );

    this.contextElement.setOutputs([
      {
        uniqueName: SUBMIT_RESULT_OUTPUT,
        name: 'Transform result',
        icon: 'components',
        fieldType: FieldType.JSON,
        external: true
      }
    ]);
  }

  getTitle(step: TransformWorkflowStep) {
    if (step.value) {
      return step.value.valueTypeStr({ context: 'workflow' });
    }
  }

  onRunUpdated(event: WorkflowRunParams) {
    if (this.contextElement) {
      const result = event.success ? event.result : event.error;
      this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
    }
  }
}

registerWorkflowStepComponent(WorkflowStepType.Transform, TransformWorkflowStepComponent);

import { Injectable, OnDestroy } from '@angular/core';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnDestroy {
  private scrollInterval: Subscription;
  private maxSpeed = 20;
  private interval = 1000 / 60;

  constructor() {}

  ngOnDestroy(): void {}

  scrollNearBounds(viewport: HTMLElement, e: MouseEvent) {
    const bounds = viewport.getBoundingClientRect();
    const scrollDistance = 80;
    const scrollMultiplierUp =
      viewport == document.body
        ? clamp((scrollDistance - e.clientY) / scrollDistance, 0, 1)
        : clamp((bounds.top + scrollDistance - e.clientY) / scrollDistance, 0, 1);
    const scrollMultiplierDown =
      viewport == document.body
        ? clamp(((window.innerHeight - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1)
        : clamp(((bounds.bottom - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1);

    if (scrollMultiplierUp > 0) {
      this.startScroll(viewport, false, scrollMultiplierUp);
    } else if (scrollMultiplierDown > 0) {
      this.startScroll(viewport, true, scrollMultiplierDown);
    } else {
      this.stopScroll();
    }
  }

  startScroll(viewport: HTMLElement, down: boolean, power: number) {
    this.stopScroll();

    if (!viewport) {
      return;
    }

    const speed = down ? power * this.maxSpeed : power * this.maxSpeed * -1;

    this.scrollInterval = interval(this.interval)
      .pipe(startWith(0), untilDestroyed(this))
      .subscribe(() => {
        if (viewport == document.body) {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed;
        } else {
          viewport.scrollTop = viewport.scrollTop + speed;
        }
      });
  }

  stopScroll() {
    if (this.scrollInterval) {
      this.scrollInterval.unsubscribe();
      this.scrollInterval = undefined;
    }
  }
}

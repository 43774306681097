<span class="tip__hotkey">
  <ng-container *ngIf="isMac">
    <ng-container *ngIf="shiftKey">⇧</ng-container>
    <ng-container *ngIf="metaKey">⌘</ng-container>
    <ng-container *ngIf="altKey">⌥</ng-container>
    {{ key.toUpperCase() }}
  </ng-container>
  <ng-container *ngIf="!isMac">
    <ng-container *ngIf="metaKey">Ctrl<span class="tip__hotkey-operator">+</span></ng-container>
    <ng-container *ngIf="shiftKey">Shift<span class="tip__hotkey-operator">+</span></ng-container>
    <ng-container *ngIf="altKey">Alt<span class="tip__hotkey-operator">+</span></ng-container>
    {{ key.toUpperCase() }}
  </ng-container>
</span>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement, ViewContextToken } from '@modules/customize';

@Component({
  selector: 'app-customize-bar-action-edit-element-action',
  templateUrl: './customize-bar-action-edit-element-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditElementActionComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];

  componentActionPopoverOpened = false;
  tokenGroup: ViewContextToken[];
  currentTokenGroup: ViewContextToken[];
  selectedToken: ViewContextToken;
  breadcrumbsToken: ViewContextToken[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.context
      .getActionTokens$()
      .pipe(untilDestroyed(this))
      .subscribe(tokens => {
        const groups = tokens;

        const getComponentAction = (token: ViewContextToken): ViewContextToken => {
          // if (token.allowSkip && token.children.length === 1) {
          //   return {
          //     ...getComponentAction(token.children[0]),
          //     name: token.name
          //   };
          // } else if (token.children != undefined) {
          if (token.children != undefined) {
            return {
              ...token,
              children: token.children.map(item => getComponentAction(item))
            };
          } else {
            return token;
          }
        };

        const hasTokens = (token: ViewContextToken) => {
          if (token.children != undefined) {
            return token.children.some(item => hasTokens(item));
          } else {
            return token.token != undefined;
          }
        };

        this.tokenGroup = groups.map(item => getComponentAction(item)).filter(item => hasTokens(item));
        this.currentTokenGroup = this.tokenGroup;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setCurrentTokenGroup(tokenGroup: ViewContextToken) {
    this.currentTokenGroup = [tokenGroup];
    this.breadcrumbsToken.push(tokenGroup);
    this.cd.markForCheck();
  }

  goBack() {
    this.breadcrumbsToken.pop();
    if (this.breadcrumbsToken.length) {
      this.currentTokenGroup = [this.breadcrumbsToken[this.breadcrumbsToken.length - 1]];
    } else {
      this.currentTokenGroup = this.tokenGroup;
    }
  }

  setComponentActionOpen(state: boolean, input = false) {
    if (input) {
      this.componentActionPopoverOpened = !this.componentActionPopoverOpened;
    } else {
      this.componentActionPopoverOpened = state;
    }
    this.cd.markForCheck();
  }

  setComponentAction(componentAction: ViewContextToken) {
    this.selectedToken = componentAction;
    this.currentTokenGroup = this.tokenGroup;
    this.breadcrumbsToken = [];
    this.control.patchValue(componentAction ? componentAction.token : null);
    this.control.markAsDirty();
    this.setComponentActionOpen(false);
    this.cd.markForCheck();
  }

  elementActionLabel(token: string[]): Observable<string> {
    return this.context.elementActionPath$(token).pipe(
      map(result => {
        return [...result]
          .reverse()
          .map(item => item.name)
          .join(' · ');
      })
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AnalyticsModule } from '@modules/analytics';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';

import { DemoWidgetComponent } from './components/demo-widget/demo-widget.component';
import { ProjectJobsComponent } from './components/project-jobs/project-jobs.component';

@NgModule({
  imports: [CommonModule, RoutingModule, AnalyticsModule, TutorialModule],
  declarations: [DemoWidgetComponent, ProjectJobsComponent],
  exports: [DemoWidgetComponent, ProjectJobsComponent],
  entryComponents: [ProjectJobsComponent]
})
export class DemoWidgetModule {}

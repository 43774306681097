import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';
import { localize } from '@common/localize';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { controlValue } from '@shared';
import { ProjectPermissionActionsControl } from '../project-group-change/project-permission-actions.control';
var ValueType;
(function (ValueType) {
    ValueType["NoAccess"] = "NoAccess";
    ValueType["FullAccess"] = "FullAccess";
    ValueType["ReadOnly"] = "ReadOnly";
    ValueType["Custom"] = "Custom";
})(ValueType || (ValueType = {}));
var ProjectPermissionActionsComponent = /** @class */ (function () {
    function ProjectPermissionActionsComponent(mode, analyticsService, cd) {
        this.mode = mode;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.small = false;
        this.options = [
            {
                type: ValueType.FullAccess,
                name: localize('Full Access'),
                description: localize('Read, create, update and delete data'),
                icon: 'magic_wand'
            },
            {
                type: ValueType.ReadOnly,
                name: localize('Read Only'),
                description: localize('Can only view data without modification'),
                icon: 'eye'
            },
            {
                type: ValueType.Custom,
                name: localize('Custom Access'),
                description: localize('Choose allowed operations granularly'),
                icon: 'configure'
            },
            {
                type: ValueType.NoAccess,
                name: localize('No Access'),
                description: localize('Without any access to data'),
                icon: 'close'
            }
        ];
        this.valueTypes = ValueType;
        this.dropdownOpened = false;
        this.custom = false;
        this.popoverPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center'
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center'
            }
        ];
    }
    ProjectPermissionActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.control)
            .pipe(map(function (value, i) { return [value, i]; }), untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0], i = _a[1];
            var valueType;
            if (value.read && value.write && value.delete) {
                valueType = ValueType.FullAccess;
            }
            else if (value.read && !value.write && !value.delete) {
                valueType = ValueType.ReadOnly;
            }
            else if (!value.read && !value.write && !value.delete) {
                valueType = ValueType.NoAccess;
            }
            else {
                valueType = ValueType.Custom;
                if (i == 0) {
                    _this.custom = true;
                }
            }
            _this.currentOption = _this.options.find(function (item) { return item.type == valueType; });
            _this.cd.markForCheck();
        });
    };
    ProjectPermissionActionsComponent.prototype.ngOnDestroy = function () { };
    ProjectPermissionActionsComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    ProjectPermissionActionsComponent.prototype.setValueType = function (type) {
        var wasAccess = this.control.isSelectedAny();
        if (type == ValueType.FullAccess) {
            this.control.setAll(true);
        }
        else if (type == ValueType.ReadOnly) {
            this.control.controls.read.setValue(true);
            this.control.controls.write.setValue(false);
            this.control.controls.delete.setValue(false);
        }
        else if (type == ValueType.NoAccess) {
            this.control.setAll(false);
        }
        else if (type == ValueType.Custom) {
            this.setCustom(true);
        }
        this.cd.markForCheck();
        if (wasAccess && type == ValueType.NoAccess) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageRemoved, {
                Mode: this.mode
            });
        }
        else if (!wasAccess && type != ValueType.NoAccess) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Permission.PageAdded, {
                Mode: this.mode
            });
        }
    };
    ProjectPermissionActionsComponent.prototype.setCustom = function (value) {
        this.custom = value;
        this.cd.markForCheck();
    };
    ProjectPermissionActionsComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    ProjectPermissionActionsComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (this.popoverPositionsSubscription) {
            this.popoverPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return ProjectPermissionActionsComponent;
}());
export { ProjectPermissionActionsComponent };

<div class="popover2__viewport popover2__viewport_scrollable" #viewport>
  <div class="popover2__content" style="min-width: 300px;">
    <div class="token-popup-search">
      <input
        type="text"
        class="token-popup-search__input"
        [formControl]="searchControl"
        [appAutofocus]="true"
        placeholder="Search..."
      />
      <span
        *ngIf="searchControl.value | appIsSet"
        class="token-popup-search__close icon-close"
        (click)="clearSearch()"
        [appTip]="'Clear'"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </div>

    <div class="token-popup__item">
      <div class="token-popup-section">
        <div *ngIf="filteredOptions.length" class="token-popup-section__content">
          <app-font-menu-item
            *ngIf="!(searchControl.value | appIsSet) && defaultOption"
            [font]="defaultOption"
            [description]="'Default font'"
            [active]="value == defaultOption.name"
            (click)="fontSelect.emit(defaultOption.name)"
          ></app-font-menu-item>

          <div *ngFor="let item of filteredOptions; let i = index; trackBy: trackFont" #font_item_element>
            <app-font-menu-item
              *ngIf="isOptionVisible(i)"
              [font]="item"
              [active]="value == item.name"
              [visible]="isOptionVisible(i)"
              (click)="fontSelect.emit(item.name)"
            ></app-font-menu-item>

            <div *ngIf="!isOptionVisible(i)" [style.height.px]="38"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!filteredOptions.length" class="token-popup-list-item">
      <div class="token-popup-list-item__inner">
        <div class="token-popup-list-item__tip">
          No fonts found (<a href="javascript:void(0)" class="link link_orange" (click)="openChat()">Add custom font</a
          >)
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!(searchControl.value | appIsSet) && defaultOption" class="popover2__toolbar">
  <a
    href="javascript:void(0)"
    class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
    [class.token-popup-list-item_orange]="true"
    (click)="openChat()"
  >
    <div class="token-popup-list-item__inner">
      <div class="token-popup-list-item__line">
        <span class="token-popup-list-item__left">
          <span class="token-popup-list-item__icon token-popup-list-item__icon_orange icon-plus"></span>
        </span>

        <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [style.font-size.px]="14">
          Custom font
        </span>
      </div>
    </div>
  </a>
</div>

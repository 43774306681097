import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';

import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { createFormFieldFactory } from '@modules/fields';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { ChangeCustomViewForm } from './change-custom-view.form';

@Component({
  selector: 'app-change-custom-view',
  templateUrl: './change-custom-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangeCustomViewForm]
})
export class ChangeCustomViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container') container: ElementRef;

  loading = false;
  customView: CustomView;
  distPreview: string;
  customViewTypes = CustomViewType;
  createField = createFormFieldFactory();

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private routing: RoutingService,
    private activatedRoute: ActivatedRoute,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private dialogService: DialogService,
    public form: ChangeCustomViewForm,
    private notificationService: NotificationService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.activatedRoute.params
      .pipe(
        filter(params => params['unique_name'] != undefined),
        switchMap(params => {
          this.loading = true;
          this.cd.markForCheck();

          return this.customViewsStore.getDetail(params['unique_name'], true);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.customView = result;
          this.form.init(this.customView);
          this.updateDistPreview();
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  get distFilename() {
    if (!this.distPreview) {
      return;
    }

    return this.distPreview.split('/').pop();
  }

  updateDistPreview() {
    if (this.form.form.value['dist'] instanceof File) {
      this.distPreview = this.form.form.value['dist'].name;
    } else {
      this.distPreview = this.form.form.value['dist'];
    }

    this.cd.markForCheck();
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.form.form.patchValue({ dist: file, params: {} });
    this.form.form.markAsDirty();
    this.updateDistPreview();
  }

  requestDelete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this FlexView?'
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.customViewService
          .delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            this.customView
          )
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.notificationService.success('Deleted', 'FlexView was successfully deleted');

            this.customViewsStore.get(true);
            this.routing.navigateApp(this.currentProjectStore.instance.settingsCustomViewsLink);
          });
      });
  }

  submit() {
    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (this.customView) {
            this.notificationService.success('Saved', 'FlexView was successfully updated');
          } else {
            this.notificationService.success('Created', 'FlexView was successfully added');
          }

          this.routing.navigateApp(result.changeLink);
        },
        () => {
          this.notificationService.error('Error', 'Saving FlexView failed');

          this.cd.markForCheck();
        }
      );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../../../common/dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "@angular/common";
import * as i5 from "./auto-widget.component";
var styles_AutoWidgetComponent = [];
var RenderType_AutoWidgetComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoWidgetComponent, data: {} });
export { RenderType_AutoWidgetComponent as RenderType_AutoWidgetComponent };
function View_AutoWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, [[1, 4]], 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AutoWidgetComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { dynamicComponent: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoWidgetComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.componentData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AutoWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-widget", [], null, null, null, View_AutoWidgetComponent_0, RenderType_AutoWidgetComponent)), i0.ɵdid(1, 704512, null, 0, i5.AutoWidgetComponent, [], null, null)], null, null); }
var AutoWidgetComponentNgFactory = i0.ɵccf("app-auto-widget", i5.AutoWidgetComponent, View_AutoWidgetComponent_Host_0, { dashboard: "dashboard", widget: "widget", context: "context", contextElement: "contextElement", fill: "fill", accentColor: "accentColor" }, {}, []);
export { AutoWidgetComponentNgFactory as AutoWidgetComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import {
  PageTemplate,
  PageTemplatesGeneratorService,
  PageTemplateType,
  PageVariantType
} from '@modules/template-generators';
import { ThemeService } from '@modules/theme';
import { deployUrl } from '@shared';

@Component({
  selector: 'app-page-templates-choose-item-type',
  templateUrl: './page-templates-choose-item-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesChooseItemTypeComponent implements OnInit, OnDestroy {
  @Input() newPage = false;
  @Output() selectTemplate = new EventEmitter<{
    template: PageTemplate;
    templateVariant: PageVariantType;
  }>();
  @Output() cancelClick = new EventEmitter<void>();

  allTemplates: PageTemplate[] = [
    {
      type: PageTemplateType.AdminPanelView,
      image: 'admin_panel_view',
      name: 'Record Review',
      description: 'View details of a record selected from list',
      variants: [
        { type: PageVariantType.SinglePage, label: 'One page' },
        { type: PageVariantType.Popups, label: 'Modals' },
        { type: PageVariantType.SeparatePages, label: 'Multi-page' }
      ]
    },
    {
      type: PageTemplateType.AdminPanelEdit,
      image: 'admin_panel_edit',
      name: 'Record Update',
      description: 'Update data of a record selected from list',
      variants: [
        { type: PageVariantType.SinglePage, label: 'One page' },
        { type: PageVariantType.Popups, label: 'Modals' },
        { type: PageVariantType.SeparatePages, label: 'Multi-page' }
      ]
    },
    {
      type: PageTemplateType.Dashboard,
      image: 'dashboard',
      name: 'Dashboard',
      description: 'Visual display of your data with KPIs and charts'
    },
    {
      type: PageTemplateType.CreateForm,
      image: 'create_form',
      name: 'Form',
      description: 'Create records with a Form'
    },
    {
      image: 'custom_page',
      name: 'Blank',
      description: 'Start with a blank canvas'
    }
  ];
  templates: PageTemplate[] = [];
  selectedTemplate: PageTemplate;
  selectedTemplateVariant: PageVariantType;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private pageTemplatesGeneratorService: PageTemplatesGeneratorService,
    private modelDescriptionStore: ModelDescriptionStore,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateTemplates();
    this.setSelectedTemplate(this.templates[0]);
    this.submit();
  }

  ngOnDestroy(): void {}

  updateTemplates() {
    this.templates = this.allTemplates.filter(item => {
      if (!item.type) {
        return this.newPage;
      } else {
        return true;
      }
    });
    this.cd.markForCheck();
  }

  setSelectedTemplate(template: PageTemplate) {
    if (this.selectedTemplate === template) {
      return;
    }

    this.selectedTemplate = template;
    this.setSelectedTemplateVariant(
      this.selectedTemplate ? this.getDefaultTemplateVariant(this.selectedTemplate) : undefined
    );
    this.cd.markForCheck();
  }

  setSelectedTemplateVariant(variant: PageVariantType) {
    this.selectedTemplateVariant = variant;
    this.cd.markForCheck();
  }

  getDefaultTemplateVariant(template: PageTemplate) {
    return template.variants && template.variants.length ? this.selectedTemplate.variants[0].type : undefined;
  }

  submit() {
    this.selectTemplate.emit({
      template: this.selectedTemplate,
      templateVariant: this.selectedTemplateVariant
    });
  }

  getTemplateIcon(item: PageTemplate): string {
    if (this.themeService.theme == 'dark') {
      return `url(${deployUrl('/assets/images/page-templates/' + item.image + '_dark.svg')})`;
    } else {
      return `url(${deployUrl('/assets/images/page-templates/' + item.image + '.svg')})`;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-text-control',
  templateUrl: './text-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input('control') inputControl: FormControl;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() placeholder: string;
  @Input() autofocus = false;
  @Input() autocomplete: string = null;

  defaultControl = new FormControl();
  control = this.defaultControl;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.inputControl) {
      this.inputControl.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputControl']) {
      this.control = this.inputControl || this.defaultControl;
    }
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService, TokenOptions } from '@modules/api';

import { ProjectPublicAccess } from '../../data/project-public-access';

export class AccessTokenResponse implements TokenOptions {
  accessToken?: string;
  accessTokenExpires?: moment.Moment;
  refreshToken?: string;
  refreshTokenExpires?: moment.Moment;
  serverTime?: moment.Moment;

  deserialize(data: Object): AccessTokenResponse {
    this.accessToken = data['access_token'];
    this.accessTokenExpires = data['access_token_expires'] ? moment(data['access_token_expires']) : undefined;
    this.refreshToken = data['refresh_token'];
    this.refreshTokenExpires = data['refresh_token_expires'] ? moment(data['refresh_token_expires']) : undefined;
    this.serverTime = data['server_time'] ? moment(data['server_time']) : undefined;

    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectPublicAccessService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}): Observable<ProjectPublicAccess[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'public_accesses/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new ProjectPublicAccess().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, environmentName: string, id: string): Observable<ProjectPublicAccess> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `public_accesses/${id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new ProjectPublicAccess().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: ProjectPublicAccess): Observable<ProjectPublicAccess> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'public_accesses/');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, instance.serialize(), { headers: headers });
      }),
      map(result => new ProjectPublicAccess().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, environmentName: string, instance: ProjectPublicAccess): Observable<ProjectPublicAccess> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `public_accesses/${instance.uid}/`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, instance.serialize(), { headers: headers });
      }),
      map(result => new ProjectPublicAccess().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName: string, environmentName: string, invite: ProjectPublicAccess): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `public_accesses/${invite.uid}/`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(result => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getByCode(code: string): Observable<ProjectPublicAccess> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`project_public_accesses/${code}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new ProjectPublicAccess().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  accept(code: string): Observable<{ userToken: AccessTokenResponse; publicAccess: ProjectPublicAccess }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`project_public_accesses/${code}/accept/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(result => {
        return {
          userToken: new AccessTokenResponse().deserialize(result['user_token']),
          publicAccess: new ProjectPublicAccess().deserialize(result['public_access'])
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import * as moment from 'moment';

import { Environment } from './environment';
import { Project } from './project';
import { ProjectGroup, ProjectUser } from './project-user';

export class ProjectPublicInvite {
  public uid: string;
  public project: Project;
  public projectUsers: ProjectUser[] = [];
  public environment: Environment;
  public group: ProjectGroup;
  public code: string;
  public dateAdd: any;

  deserialize(data: Object): ProjectPublicInvite {
    this.uid = data['uid'];
    this.code = data['code'];
    this.dateAdd = moment(data['date_add']);

    if (data['project']) {
      this.project = new Project().deserialize(data['project']);
    }

    if (data['environment']) {
      this.environment = new Environment().deserialize(data['environment']);
    }

    if (data['group']) {
      this.group = new ProjectGroup().deserialize(data['group']);
    } else {
      this.group = new ProjectGroup();
    }

    return this;
  }

  serialize() {
    return {
      group: this.group ? this.group.uid : undefined
    };
  }

  link(webBaseUrl: string) {
    return `${webBaseUrl}/projects/join/${this.code}`;
  }
}

import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PopupService } from '@common/popups';
import { DriftIntegration } from '@modules/all-project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { DriftIntegrationEditPopupComponent } from '../../components/drift-integration-edit-popup/drift-integration-edit-popup.component';

@Injectable()
export class DriftIntegrationEditController implements OnDestroy {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector,
    private popupService: PopupService
  ) {}

  ngOnDestroy(): void {}

  edit(options: { analyticsSource?: string }): Observable<{ result?: DriftIntegration }> {
    const popup = this.popupService.showComponent<DriftIntegrationEditPopupComponent>({
      component: DriftIntegrationEditPopupComponent,
      injector: this.injector,
      inputs: {
        analyticsSource: options.analyticsSource
      },
      scrollable: true
    });

    return popup.instance.saved.asObservable().pipe(map(result => ({ result: result })));
  }
}

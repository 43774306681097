<div
  appDropList
  [appDropListConnectedToGroup]="dropListGroups"
  [appDropListDestroy]="true"
  [appDropListEnterPredicate]="isDroppable"
  [appDropListParams]="{ create_elements: true }"
  class="sidebar"
  [class.sidebar_disabled]="customizeDisabled && settingsComponents.length"
>
  <div class="sidebar__highlight" #highlight></div>

  <div *ngIf="customizeDisabled && settingsComponents.length" class="sidebar__loader">
    <app-loader [fill]="true"></app-loader>
  </div>

  <div class="sidebar__scrollable" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }" #root>
    <div *ngFor="let item of settingsComponents; let last = last" [hidden]="!last">
      <app-dynamic-component [componentData]="item"></app-dynamic-component>
    </div>
    <div [hidden]="settingsComponents.length">
      <!--      <app-customize-bar-header [title]="'Customize Layout'"-->
      <!--                                [subtitle]="title">-->
      <!--      </app-customize-bar-header>-->

      <!--      <div class="sidebar__tabs">-->
      <!--        <div class="sidebar-tabs">-->
      <!--          <a-->
      <!--            *ngFor="let item of tabItems"-->
      <!--            href="javascript:void(0)"-->
      <!--            class="sidebar-tabs__item"-->
      <!--            [class.sidebar-tabs__item_active]="selectedTab == item.tab"-->
      <!--            (click)="setSelectedTab(item.tab)"-->
      <!--          >-->
      <!--            {{ item.label }}-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="sidebar__content">-->
      <app-change-customize-bar-components
        *ngIf="selectedTab == tabs.Components"
        [context]="context"
        [dropListGroups]="dropListGroups"
        (selectTab)="setSelectedTab($event)"
        (selectTemplatesTabResource)="setSelectedTemplatesTabResourceType($event)"
      >
      </app-change-customize-bar-components>
      <app-change-customize-bar-templates
        *ngIf="selectedTab == tabs.Templates"
        [initialResource]="templatesInitialResource"
        [initialResourceType]="templatesInitialResourceType"
        [context]="context"
        [dropListGroups]="dropListGroups"
        [firstVisible]="firstVisible"
        (selectTab)="setSelectedTab($event)"
        (scrollToTop)="scrollToTop($event)"
      >
      </app-change-customize-bar-templates>
      <!--      </div>-->
    </div>
  </div>
</div>

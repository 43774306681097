export enum DateFormat {
  Local = 'local',
  Friendly = 'friendly',
  US = 'us',
  European = 'european',
  ISO = 'iso',
  Custom = 'custom'
}

export const defaultDateFormat = DateFormat.Local;

export const momentDateFormats = {
  [DateFormat.Local]: 'L',
  [DateFormat.Friendly]: 'LL',
  [DateFormat.US]: 'MM/DD/YYYY',
  [DateFormat.European]: 'DD/MM/YYYY',
  [DateFormat.ISO]: 'YYYY-MM-DD'
};

export const momentDateEditFormats = {
  [DateFormat.Local]: 'L',
  [DateFormat.Friendly]: 'L',
  [DateFormat.US]: 'MM/DD/YYYY',
  [DateFormat.European]: 'DD/MM/YYYY',
  [DateFormat.ISO]: 'YYYY-MM-DD'
};

<div [style.width.px]="300">
  <div class="sidebar__element">
    <app-sidebar-field>
      <app-field-type-field
        [form]="control"
        [label]="false"
        [field]="createField({ name: 'field', params: { classes: ['select_fill'] } })"
      ></app-field-type-field>
    </app-sidebar-field>
  </div>

  <div *ngIf="configurable.verboseName" class="sidebar__element">
    <app-sidebar-field [label]="'label'" [additional]="'(optional)'">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'verboseName',
            field: 'CharField',
            placeholder: 'Default is ' + control.controls.name.value,
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>

  <!--    <app-auto-field [form]="form" [field]="createField({ name: 'filterable', label: 'filterable', field: 'BooleanField', params: { classes: ['input_fill'] } })"></app-auto-field>-->

  <div *ngIf="configurable.sortable" class="sidebar__element">
    <app-sidebar-field [label]="'sortable'" [layoutLeft]="true">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'sortable',
            label: 'sortable',
            field: 'BooleanField',
            params: { style: booleanFieldStyle.Toggle, orange: true }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>

  <div *ngIf="configurable.required" class="sidebar__element">
    <app-sidebar-field [label]="'required'" [layoutLeft]="true">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'required',
            label: 'required',
            field: 'BooleanField',
            params: { style: booleanFieldStyle.Toggle, orange: true }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>

  <div *ngIf="configurable.editable" class="sidebar__element">
    <app-sidebar-field [label]="'editable'" [layoutLeft]="true">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'editable',
            label: 'editable',
            field: 'BooleanField',
            params: { style: booleanFieldStyle.Toggle, orange: true }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>

  <ng-container *ngIf="configurable.editable">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'default value'">
        <app-auto-field
          [form]="control"
          [label]="false"
          [field]="
            createField({
              name: 'defaultType',
              field: 'SelectField',
              params: { options: defaultTypeOptions }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div *ngIf="control.controls.defaultType.value == defaultTypes.Value" class="sidebar__element">
      <app-sidebar-field>
        <app-auto-field
          [form]="control"
          [label]="false"
          [field]="
            createField({
              name: 'defaultValue',
              field: control.controls.field.value,
              params: control.controls.params.value
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </ng-container>

  <app-dynamic-component *ngIf="viewParamsComponentData" [componentData]="viewParamsComponentData">
  </app-dynamic-component>

  <app-dynamic-component *ngIf="dataParamsComponentData" [componentData]="dataParamsComponentData">
  </app-dynamic-component>

  <div class="edit-popup__submit">
    <button type="button" class="button button_primary button_orange button_fill" (click)="save()">
      <span class="button__label">Save</span>
    </button>
    <button *ngIf="configurable.add" type="button" class="button button_danger button_fill" (click)="requestDelete()">
      <span class="button__label">Delete</span>
    </button>
  </div>
</div>

import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

export enum ResizeType {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export interface ResizeStartedEvent {
  mouseDownEvent: MouseEvent;
}

export interface ResizeEvent {
  types: ResizeType[];
  widthDelta?: number;
  heightDelta?: number;
}

export interface ResizeFinishedEvent {
  mouseUpEvent: MouseEvent;
  types: ResizeType[];
  widthChanged?: boolean;
  width?: number;
  heightChanged?: boolean;
  height?: number;
}

@Directive({
  selector: '[appResizable]',
  exportAs: 'appResizable'
})
export class ResizableDirective {
  @Input('appResizableResizeElement') resizeElement = true;
  @Output() resizeStarted = new EventEmitter<ResizeStartedEvent>();
  @Output() resize = new EventEmitter<ResizeEvent>();
  @Output() resizeFinished = new EventEmitter<ResizeFinishedEvent>();

  constructor(public readonly el: ElementRef) {}
}

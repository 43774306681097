import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { FillType, Frame, Layer, View } from '@modules/views';
import { controlValue, isSet } from '@shared';

import { FillControl } from '../../controls/fill.control';

@Component({
  selector: 'app-fill-color-button',
  templateUrl: './fill-color-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FillColorButtonComponent implements OnInit, OnDestroy {
  @Input() control: FillControl;
  @Input() viewContext: ViewContext;
  @Input() view: View;
  @Input() layer: Layer;
  @Input() fillTypesEnabled: FillType[];
  @Output() openedChange = new EventEmitter<void>();

  colorBackground: SafeStyle;
  colorWidth?: string;
  colorHeight?: string;
  colorTransform?: SafeStyle;
  colorIcon: {
    icon: string;
    color?: string;
    size: number;
  };
  colorOpened = false;
  colorPreviewSize = new Frame({ width: 32 - 2, height: 26 - 2 });
  colorPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 0 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ];

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(
        switchMap(() => {
          const instance = this.control.getInstance();
          const css$ = instance.css$({ frame: this.colorPreviewSize, context: this.viewContext });
          const iconFill$ = instance.iconFill
            ? instance.iconFill.display$({ context: this.viewContext }).pipe(
                map(value => {
                  return {
                    icon: value.icon,
                    color: value.color
                  };
                })
              )
            : of(undefined);

          return combineLatest(css$, iconFill$);
        }),
        untilDestroyed(this)
      )
      .subscribe(([css, icon]) => {
        this.colorBackground = isSet(css.background)
          ? this.sanitizer.bypassSecurityTrustStyle(css.background)
          : undefined;
        this.colorWidth = css.width;
        this.colorHeight = css.height;
        this.colorTransform = isSet(css.transform) ? this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined;
        this.colorIcon = icon;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {}

  setOpened(value: boolean) {
    this.colorOpened = value;
    this.cd.markForCheck();
    this.openedChange.emit();
  }

  open() {
    this.setOpened(true);
  }

  close() {
    this.setOpened(false);
  }

  isOpened(): boolean {
    return this.colorOpened;
  }
}

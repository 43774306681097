import { Pipe, PipeTransform } from '@angular/core';
import { asyncScheduler, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Pipe({
  name: 'appThrottleTime'
})
export class ThrottleTimePipe<T = any> implements PipeTransform {
  transform(
    value: Observable<T>,
    time: number,
    options: { leading?: boolean; trailing?: boolean } = {}
  ): Observable<T> {
    return value.pipe(throttleTime(time, asyncScheduler, options));
  }
}

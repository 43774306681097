import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopupSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { InputValueType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-parameters-open-popup',
  templateUrl: './customize-bar-action-edit-parameters-open-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditParametersOpenPopupComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() analyticsSource: string;

  inputsCollapseContext = new SidebarCollapseContext();
  inputValueTypes = InputValueType;
  popup: PopupSettings;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form
      .openPopup$()
      .pipe(untilDestroyed(this))
      .subscribe(popup => {
        this.popup = popup;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}

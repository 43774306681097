import { FormControl, FormGroup } from '@angular/forms';

import { FieldActions } from '@modules/customize';

export class FieldActionsControl extends FormGroup {
  controls: {
    name: FormControl;
    actions: FormControl;
  };

  constructor(value?: FieldActions) {
    super({
      name: new FormControl(value ? value.name : undefined),
      actions: new FormControl(value ? value.actions : undefined)
    });
  }

  patchValue(value: FieldActions, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
  }

  setValue(value: FieldActions, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface FocusPosition {
  x: number;
  y: number;
}

@Injectable({
  providedIn: 'root'
})
export class GuideFocusService {
  _position = new BehaviorSubject<FocusPosition>(undefined);

  get position(): FocusPosition {
    return this._position.value;
  }

  get position$(): Observable<FocusPosition> {
    return this._position.asObservable();
  }

  set position(value: FocusPosition) {
    this._position.next(value);
  }
}

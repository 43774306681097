import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { EmailTemplate } from '../../data/email-template';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var EmailTemplateService = /** @class */ (function () {
    function EmailTemplateService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    EmailTemplateService.prototype.get = function (projectName, environmentName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'email_templates/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return result.map(function (item) { return new EmailTemplate().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailTemplateService.prototype.getDetail = function (projectName, environmentName, name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "email_templates/" + name + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new EmailTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailTemplateService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'email_templates/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new EmailTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailTemplateService.prototype.update = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "email_templates/" + instance.name + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new EmailTemplate().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailTemplateService.prototype.delete = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "email_templates/" + instance.name + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailTemplateService.ngInjectableDef = i0.defineInjectable({ factory: function EmailTemplateService_Factory() { return new EmailTemplateService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: EmailTemplateService, providedIn: "root" });
    return EmailTemplateService;
}());
export { EmailTemplateService };

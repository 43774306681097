import { Injectable, Injector } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, ResourceName, SecretTokenService } from '@modules/projects';
import { DjangoGeneratorService, DjangoParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';
import { isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

export interface DjangoResourceParams {
  defaultShowInstructions?: boolean;
  defaultToken?: string;
  defaultApiBaseUrl?: string;
}

@Injectable()
export class DjangoResourceSettingsForm extends BaseResourceSettingsForm<DjangoParamsOptions, DjangoResourceParams> {
  form = new AppFormGroup({
    show_instructions: new FormControl(true),
    jetbridge_manual: new FormControl(false),
    url: new FormControl('', [Validators.required, this.validateUrl()]),
    token: new FormControl('', Validators.required)
  });

  constructor(
    private djangoGeneratorService: DjangoGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  validateUrl(): ValidatorFn {
    return (control: FormControl) => {
      if (control.value && !control.value.match(/\/jet_api\/$/)) {
        return { local: ['URL for Django should end with /jet_api/'] };
      }
    };
  }

  initResourceValue(): Observable<void> {
    return this.djangoGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          show_instructions: false,
          url: result.url,
          token: this.resource.token || ''
        });
      })
    );
  }

  initDefaultValue(): Observable<void> {
    const { defaultShowInstructions, defaultToken, defaultApiBaseUrl } = this.params;

    if (isSet(defaultShowInstructions)) {
      this.form.patchValue({ show_instructions: defaultShowInstructions });
    }

    if (isSet(defaultToken)) {
      this.form.patchValue({ token: defaultToken });
    }

    if (isSet(defaultApiBaseUrl)) {
      this.form.patchValue({ url: defaultApiBaseUrl });
    }

    return of(undefined);
  }

  getOptions(): DjangoParamsOptions {
    return {
      url: this.form.value['url'],
      token: this.form.value['token']
    };
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.djangoGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name']
          };
        })
      );
  }
}

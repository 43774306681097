import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-section-tab',
  templateUrl: './section-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTabComponent implements OnInit {
  @Input() label: string;
  @Input() link: any[];
  @Input() icon: string;
  @Input() active = false;
  @Output() selected = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Option } from '@modules/field-components';

import { FlexLayoutControl, FlexLayoutOrientationOption } from '../../controls/flex-layout';

@Component({
  selector: 'app-flex-layout-section',
  templateUrl: './flex-layout-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexLayoutSectionComponent implements OnInit {
  @Input() enabledControl: FormControl;
  @Input() control: FlexLayoutControl;
  @Input() updateControlsValue = true;
  @Output() selectedOption = new EventEmitter<Option<FlexLayoutOrientationOption>>();

  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 }
  ];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  toggleDropdownOpened() {
    this.setDropdownOpened(!this.dropdownOpened);
  }

  selectOption(option: Option<FlexLayoutOrientationOption>) {
    if (this.updateControlsValue) {
      this.enabledControl.patchValue(true);
      this.control.controls.orientation_option.patchValue(option.value);
    }

    this.selectedOption.emit(option);
  }
}

<div *ngIf="files" class="sidebar__element">
  <app-sidebar-field [label]="'Files to include into page (only JS and CSS, order can be changed)'">
    <button type="button" class="button button_orange-alternative button_small" (click)="setAllSelect(true)">
      Select All
    </button>
    <button type="button" class="button button_orange-alternative button_small" (click)="setAllSelect(false)">
      Deselect All
    </button>
    <app-field-errors [form]="filesControl.parent" [fieldName]="'files'"></app-field-errors>
    <div
      [appDraggable]="'files'"
      [appDraggableOptions]="{ handle: '.field__label' }"
      (appDraggableItemsUpdated)="onOrderingUpdated($event)"
    >
      <div *ngFor="let item of files; let i = index" appDraggableItem [appDraggableItemData]="item" class="handle">
        <div class="field" style="margin-left: 0; margin-right: 0;">
          <input
            type="checkbox"
            [id]="'file_' + i"
            [disabled]="item.disabled"
            [(ngModel)]="item.checked"
            (change)="updateParams()"
          />
          <label
            class="field__label field__label_inline"
            [class.field__label_disabled]="item.disabled"
            [style.cursor]="'move'"
            [for]="'file_' + i"
          >
            {{ item.name }}
          </label>
        </div>
      </div>
    </div>
  </app-sidebar-field>
</div>

<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Enter your credentials to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <ng-template #description>
          You can obtain it here
          <a href="https://console.aws.amazon.com/iam/home#/users" target="_blank"
            >https://console.aws.amazon.com/iam/home#/users</a
          >
        </ng-template>
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'access_key_id',
              label: 'Access Key Id',
              field: 'CharField',
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
          [autofocus]="true"
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'secret_access_key',
              label: 'Secret Access Key',
              field: 'CharField',
              description: description,
              placeholder: defaultPlaceholder,
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div class="field">
          <div class="field__label">
            Buckets
          </div>

          <div
            cdkDropList
            [cdkDropListLockAxis]="'y'"
            class="field__content"
            (cdkDropListDropped)="bucketsDragDrop($event)"
          >
            <app-page-block
              *ngFor="let control of form.form.controls.buckets.controls; let i = index"
              cdkDrag
              [marginSize]="'s'"
              [paddingSize]="'none'"
            >
              <div class="input-icon-wrapper">
                <span class="input-icon input-icon_left icon-dots_small input-icon_draggable"></span>
                <a
                  *ngIf="form.form.controls.buckets.controls.length > 1"
                  href="javascript:void(0)"
                  class="input-icon input-icon_interactive input-icon_right icon-close"
                  tabindex="-1"
                  (click)="removeBucket(control, i)"
                ></a>
                <input
                  [formControl]="control"
                  type="text"
                  class="input input_fill input_draggable input_icon-left input_icon-right"
                  placeholder="Enter bucket name"
                  [matAutocomplete]="auto"
                  [appAutofocus]="control === createdControl"
                />
              </div>
            </app-page-block>

            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let item of buckets" [value]="item.name">
                <span class="mat-menu-item-icon icon-open_folder"></span>
                {{ item.name }}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div class="field__description">
            <a href="javascript:void(0)" class="field__label-link" (click)="createBucket()">
              <span class="field__label-link-icon icon-plus"></span>
              Add bucket
            </a>
          </div>
        </div>
      </app-page-block>

      <app-page-block>
        <a [appDocumentation]="'integrations/amazon-s3'" class="button button_small" style="margin: 0;">
          Read documentation
        </a>
      </app-page-block>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="submit()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>

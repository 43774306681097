import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { SSOSettingsEditController } from '@modules/layout-components';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore, popularSSOProviders } from '@modules/projects';
import {
  createSSOSettings,
  CustomSSOSettings,
  getSSOSettingsTypeLabel,
  SSOSettings,
  SSOSettingsService,
  SSOType
} from '@modules/sso';
import { isSet } from '@shared';

import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';

@Component({
  selector: 'app-customize-bar-auth-edit',
  templateUrl: './customize-bar-auth-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarAuthEditComponent implements OnInit, OnDestroy {
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;

  @ViewChild('dropdown') signUpEnableGroupDropdown: ProjectGroupDropdownComponent;

  domain: Domain;
  isWhiteLabel = false;
  sso: SSOSettings[];
  ssoOrderUpdated = new Subject<void>();
  types = SSOType;
  ssoLoading = false;
  ssoCreateLoading = false;
  popularSSOProviders = popularSSOProviders.slice(0, 3);
  analyticsSource = 'layout_builder_auth';

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private featureService: FeatureService,
    private projectDomainController: ProjectDomainController,
    private ssoSettingsService: SSOSettingsService,
    private ssoSettingsEditController: SSOSettingsEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.isWhiteLabel = this.domain && this.domain.whiteLabel;
        this.cd.markForCheck();
      });

    this.fetchSSO();
  }

  ngOnDestroy(): void {}

  fetchSSO() {
    this.ssoLoading = true;
    this.cd.markForCheck();

    this.ssoSettingsService
      .get(this.currentProjectStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.sso = result.sort((lhs, rhs) => lhs.ordering - rhs.ordering);
          this.ssoLoading = false;
          this.cd.markForCheck();

          this.initSSOOrdering();
        },
        () => {
          this.ssoLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  initSSOOrdering() {
    this.ssoOrderUpdated
      .pipe(
        debounceTime(200),
        map(() => {
          return this.sso
            .map((item, i) => {
              const ordering = i + 1;
              if (item.ordering != ordering) {
                item.ordering = ordering;
                return item;
              }
            })
            .filter(item => item);
        }),
        filter(reorder => reorder.length > 0),
        switchMap(reorder => {
          return combineLatest(
            reorder.map(sso => {
              return this.ssoSettingsService.update(this.currentProjectStore.instance.uniqueName, sso, ['ordering']);
            })
          );
        }),
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_sign_up' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onDomainRequiredClick() {
    if (!this.domain) {
      this.editDomain();
    }
  }

  generateSSOName(defaultName = 'SSO'): string {
    const names = this.sso.reduce((acc, item) => {
      acc[item.name.toLowerCase()] = item;
      return acc;
    }, {});
    let i = 1;
    let newName: string;

    do {
      newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
      ++i;
    } while (names.hasOwnProperty(newName.toLowerCase()));

    return newName;
  }

  showSSOFeatureOverview() {
    this.featureService.showFeatureOverview({
      subtitle: 'Paid Feature',
      title: 'Sign In with <strong>SSO</strong>',
      description: `
          <ul>
            <li>Keep control over who has access to which content among your organization</li>
          </ul>
        `
    });
  }

  createSSOSettings(initialValue: Partial<SSOSettings> = {}) {
    if (!this.currentProjectStore.instance.features.isSSOEnabled()) {
      this.showSSOFeatureOverview();
      return;
    }

    if (isSet(initialValue.name)) {
      initialValue.name = this.generateSSOName(initialValue.name);
    } else if (isSet(initialValue.type)) {
      const typeLabel = getSSOSettingsTypeLabel(initialValue.type);
      initialValue.name = this.generateSSOName(typeLabel);
    }

    const instance = createSSOSettings(initialValue.type);
    const project = this.currentProjectStore.instance;
    const fields = ['domain', 'name', 'type', 'public_params', 'params', 'active'];

    instance.domain = project && isSet(project.domain) ? project.domain.domain : undefined;
    instance.name = initialValue.name;
    instance.image = initialValue.image;
    instance.type = initialValue.type;
    instance.active = true;

    if (initialValue.type == SSOType.OAuth2 && isSet(initialValue.params['backend'])) {
      instance.params = { backend: initialValue.params['backend'] };
    }

    if (instance instanceof CustomSSOSettings && isSet(initialValue.params['shared_custom_sso'])) {
      instance.sharedCustomSSO = initialValue.params['shared_custom_sso'];
      fields.push('shared_custom_sso');
    }

    this.ssoCreateLoading = true;
    this.cd.markForCheck();

    this.ssoSettingsService
      .create(this.currentProjectStore.instance.uniqueName, instance, fields)
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.sso = [...this.sso, result];
          this.ssoCreateLoading = false;
          this.cd.markForCheck();

          this.editSSOSettings(result);
        },
        () => {
          this.ssoCreateLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  editSSOSettings(sso: SSOSettings) {
    if (!this.currentProjectStore.instance.features.isSSOEnabled()) {
      this.showSSOFeatureOverview();
      return;
    }

    this.ssoSettingsEditController
      .edit({ sso: sso, analyticsSource: this.analyticsSource })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.updated) {
          this.sso = this.sso.map(item => {
            if (item.uid == result.updated.uid) {
              return result.updated;
            } else {
              return item;
            }
          });
          this.cd.markForCheck();
        } else if (result.deleted) {
          this.sso = this.sso.filter(item => item.uid != sso.uid);
          this.cd.markForCheck();
        }
      });
  }

  toggleSSOSettingsActive(sso: SSOSettings) {
    const instance = cloneDeep(sso) as SSOSettings;

    instance.active = !instance.active;

    this.sso = this.sso.map(item => {
      if (item === sso) {
        return instance;
      } else {
        return item;
      }
    });
    this.cd.markForCheck();

    return this.ssoSettingsService
      .update(this.currentProjectStore.instance.uniqueName, instance, ['active'])
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe(
        () => {},
        () => {
          this.sso = this.sso.map(item => {
            if (item === instance) {
              return sso;
            } else {
              return item;
            }
          });
          this.cd.markForCheck();
        }
      );
  }

  dragDrop(event: CdkDragDrop<SSOSettings[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.sso, event.previousIndex, event.currentIndex);
      this.ssoOrderUpdated.next();
    }
  }
}

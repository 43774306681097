import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { isSet } from '@shared';

import { ActionOutput } from '../data/action-output';
import { ActionOutputControl } from './action-output.control';

export class ActionOutputArray extends FormArray {
  controls: ActionOutputControl[];

  constructor(controls: ActionOutputControl[] = []) {
    super(controls);
  }

  patchValue(value: ActionOutput[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  setValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.deserialize(value);
  }

  get value(): ActionOutput[] {
    return this.serialize();
  }

  set value(value: ActionOutput[]) {}

  deserialize(value: ActionOutput[]) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.deserialize(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): ActionOutput[] {
    return this.controls.map(control => control.serialize()).filter(item => isSet(item.name));
  }

  setControls(controls: ActionOutputControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: ActionOutputControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: ActionOutput, value?: Object): ActionOutputControl {
    const control = new ActionOutputControl();

    if (item) {
      control.deserialize(item);
    }

    if (value) {
      control.patchValue(value);
      control.markAsPristine();
    }

    return control;
  }

  prependControl(item?: ActionOutput, value?: Object): ActionOutputControl {
    const control = this.createControl(item, value);
    this.insert(0, control);
    return control;
  }

  appendControl(item?: ActionOutput, value?: Object): ActionOutputControl {
    const control = this.createControl(item, value);
    this.push(control);
    return control;
  }
}

import isArray from 'lodash/isArray';

import { exactFieldLookup, inFieldLookup, isNullFieldLookup } from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';

export const relatedModelAirtableFormulas = [
  {
    field: FieldType.RelatedModel,
    lookup: exactFieldLookup,
    formula: (lookupValue: any, field?: BaseField): string => {
      return `"${lookupValue}" = {${field.name}}`;
    }
  },
  {
    field: FieldType.RelatedModel,
    lookup: isNullFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      // return lookupValue ? fieldValue === null : fieldValue !== null;
      return;
    }
  },
  {
    field: FieldType.RelatedModel,
    lookup: inFieldLookup,
    formula: (lookupValue: any[], field?: BaseField): string => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return;
      }

      if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const predicates = lookupValue.map(item => `{${field.name}} = "${item}"`);
      return `OR(${predicates.join(',')})`;
    }
  }
];

<div class="choose-template choose-template_popup">
  <div class="choose-template__header">
    Create a Resource

    <a href="javascript:void(0)" class="choose-template__header-close icon-close" (click)="cancel()"></a>
  </div>

  <div class="choose-template__content">
    <app-create-resource
      class="choose-template__select-item"
      [project]="currentProjectStore.instance$ | async"
      (chosen)="createResource($event)"
    >
    </app-create-resource>
  </div>
</div>

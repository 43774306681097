var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '@core';
import { ProjectDomainController } from '@modules/domain-components';
import { emailTemplates, EmailTemplateService } from '@modules/emails';
import { EmailAddressEditController, EmailTemplateEditController } from '@modules/emails-components';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
var CustomizeBarEmailsEditComponent = /** @class */ (function () {
    function CustomizeBarEmailsEditComponent(currentProjectStore, currentEnvironmentStore, appConfigService, projectDomainController, emailTemplateService, emailTemplateEditController, emailsFromEditController, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.appConfigService = appConfigService;
        this.projectDomainController = projectDomainController;
        this.emailTemplateService = emailTemplateService;
        this.emailTemplateEditController = emailTemplateEditController;
        this.emailsFromEditController = emailsFromEditController;
        this.cd = cd;
        this.isWhiteLabel = false;
        this.emailsFromHover = new BehaviorSubject(false);
        this.emailItemsLoading = false;
        this.analyticsSource = 'layout_builder_emails';
    }
    CustomizeBarEmailsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.isWhiteLabel = _this.domain && _this.domain.whiteLabel;
            _this.cd.markForCheck();
        });
        this.fetchEmailTemplates();
    };
    CustomizeBarEmailsEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarEmailsEditComponent.prototype.editEmailsFrom = function () {
        this.emailsFromEditController.edit().pipe(untilDestroyed(this)).subscribe();
    };
    CustomizeBarEmailsEditComponent.prototype.fetchEmailTemplates = function () {
        var _this = this;
        this.emailItemsLoading = true;
        this.cd.markForCheck();
        this.emailTemplateService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var defaultLanguageEmails = result.filter(function (item) { return !isSet(item.language); });
            _this.emailItems = emailTemplates.map(function (info) {
                return {
                    info: info,
                    base: defaultLanguageEmails.find(function (item) { return item.name == info.name && item.protected; }),
                    custom: defaultLanguageEmails.find(function (item) { return item.name == info.name && !item.protected; })
                };
            });
            _this.emailItemsLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.emailItemsLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarEmailsEditComponent.prototype.editDomain = function () {
        this.projectDomainController
            .edit({ analyticsSource: 'layout_builder_sign_up' })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomizeBarEmailsEditComponent.prototype.onCustomDomainRequiredClick = function () {
        if (!this.domain || !this.domain.custom) {
            this.editDomain();
        }
    };
    CustomizeBarEmailsEditComponent.prototype.editEmailItem = function (emailItem) {
        var _this = this;
        if (emailItem.custom) {
            this.emailTemplateEditController
                .edit({
                emailTemplate: emailItem.custom,
                baseEmailTemplate: emailItem.base,
                analyticsSource: this.analyticsSource
            })
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.emailItems = _this.emailItems.map(function (item) {
                    if (item.info.name == emailItem.info.name) {
                        return __assign({}, item, { custom: result });
                    }
                    else {
                        return item;
                    }
                });
                _this.cd.markForCheck();
            });
        }
        else {
            this.emailTemplateEditController
                .create({ baseEmailTemplate: emailItem.base, analyticsSource: this.analyticsSource })
                .pipe(untilDestroyed(this))
                .subscribe(function (result) {
                _this.emailItems = _this.emailItems.map(function (item) {
                    if (item.info.name == emailItem.info.name) {
                        return __assign({}, item, { custom: result });
                    }
                    else {
                        return item;
                    }
                });
                _this.cd.markForCheck();
            });
        }
    };
    CustomizeBarEmailsEditComponent.prototype.resetEmailItem = function (emailItem) {
        var _this = this;
        this.emailTemplateEditController
            .reset({ emailTemplate: emailItem.custom, analyticsSource: this.analyticsSource })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.emailItems = _this.emailItems.map(function (item) {
                if (item.info.name == emailItem.info.name) {
                    return __assign({}, item, { custom: undefined });
                }
                else {
                    return item;
                }
            });
            _this.cd.markForCheck();
        });
    };
    return CustomizeBarEmailsEditComponent;
}());
export { CustomizeBarEmailsEditComponent };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
var SlackService = /** @class */ (function () {
    function SlackService(http, apiService, projectApiService) {
        this.http = http;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
    }
    SlackService.prototype.getLink = function (projectName, environmentName, name) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "links/" + name + "/get");
            var headers = new HttpHeaders();
            var data = {};
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return result.url; }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    SlackService.prototype.getSlackJoinLink = function (projectName, environmentName) {
        return this.getLink(projectName, environmentName, 'slack_join');
    };
    SlackService.prototype.getSlackChannel = function (projectName, environmentName) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'slack_channel');
            var headers = new HttpHeaders();
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new SlackService.SlackChannelResponse().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    SlackService.prototype.joinSlackChannel = function (projectName, environmentName, email, channel) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'slack_channel/join');
            var headers = new HttpHeaders();
            var data = {
                email: email,
                channel: channel
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new SlackService.SlackChannelResponse().deserialize(result); }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    return SlackService;
}());
export { SlackService };
(function (SlackService) {
    var SlackChannel = /** @class */ (function () {
        function SlackChannel() {
        }
        SlackChannel.prototype.deserialize = function (data) {
            this.id = data['slack_channel_id'];
            this.name = data['slack_channel_name'];
            return this;
        };
        Object.defineProperty(SlackChannel.prototype, "href", {
            get: function () {
                return "https://jetadmin.slack.com/archives/" + this.id;
            },
            enumerable: true,
            configurable: true
        });
        return SlackChannel;
    }());
    SlackService.SlackChannel = SlackChannel;
    var SlackChannelUser = /** @class */ (function () {
        function SlackChannelUser() {
        }
        SlackChannelUser.prototype.deserialize = function (data) {
            this.id = data['slack_user_id'];
            this.email = data['slack_user_email'];
            return this;
        };
        return SlackChannelUser;
    }());
    SlackService.SlackChannelUser = SlackChannelUser;
    var SlackChannelResponse = /** @class */ (function () {
        function SlackChannelResponse() {
        }
        SlackChannelResponse.prototype.deserialize = function (data) {
            this.channelPrefix = data['channel_prefix'];
            if (data['channel']) {
                this.channel = new SlackChannel().deserialize(data['channel']);
            }
            if (data['channel_user']) {
                this.channelUser = new SlackChannelUser().deserialize(data['channel_user']);
            }
            return this;
        };
        SlackChannelResponse.prototype.isJoined = function () {
            return !!this.channel && !!this.channelUser;
        };
        return SlackChannelResponse;
    }());
    SlackService.SlackChannelResponse = SlackChannelResponse;
})(SlackService || (SlackService = {}));

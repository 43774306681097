import { FieldLookup } from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';
import { booleanAirtableFormulas } from './boolean';
import { dateTimeAirtableFormulas } from './date-time';
import { multipleSelectAirtableFormulas } from './multiple-select';
import { numberAirtableFormulas } from './number';
import { passwordAirtableFormulas } from './password';
import { radioButtonAirtableFormulas } from './radio-button';
import { ratingAirtableFormulas } from './rating';
import { relatedModelAirtableFormulas } from './related-model';
import { richTextAirtableFormulas } from './rich-text';
import { selectAirtableFormulas } from './select';
import { sliderAirtableFormulas } from './slider';
import { textAirtableFormulas } from './text';
import { urlAirtableFormulas } from './url';

export interface AirtableFormula {
  field: FieldType;
  lookup: FieldLookup;
  formula: (lookupValue: any, field?: BaseField) => string;
}

export const airtableFormulas: AirtableFormula[] = [];

airtableFormulas.push(...booleanAirtableFormulas);
airtableFormulas.push(...selectAirtableFormulas);
airtableFormulas.push(...multipleSelectAirtableFormulas);
airtableFormulas.push(...radioButtonAirtableFormulas);
airtableFormulas.push(...relatedModelAirtableFormulas);
airtableFormulas.push(...dateTimeAirtableFormulas);
airtableFormulas.push(...textAirtableFormulas);
airtableFormulas.push(...richTextAirtableFormulas);
airtableFormulas.push(...passwordAirtableFormulas);
airtableFormulas.push(...numberAirtableFormulas);
airtableFormulas.push(...ratingAirtableFormulas);
airtableFormulas.push(...sliderAirtableFormulas);
airtableFormulas.push(...urlAirtableFormulas);

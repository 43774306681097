/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/cdk/text-field";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "../../../../shared/components/autofocus/autofocus.directive";
import * as i7 from "../../../../core/services/app-config/app-config.service";
import * as i8 from "./editable-content.component";
var styles_EditableContentComponent = [];
var RenderType_EditableContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditableContentComponent, data: {} });
export { RenderType_EditableContentComponent as RenderType_EditableContentComponent };
function View_EditableContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "editable-content-button"]], [[2, "editable-content-button_hover", null]], null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-pen"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.penTitle; var currVal_2 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.penHover; _ck(_v, 0, 0, currVal_0); }); }
function View_EditableContentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "editable-content-button"], ["style", "cursor: pointer;"]], null, [[null, "click"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "icon icon-bin"]], null, null, null, null, null))], null, null); }
function View_EditableContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [["class", "editable-content-label"]], [[2, "editable-content-label_inline", null], [2, "editable-content-label_outline", null], [2, "editable-content-label_cursor-pointer", null], [2, "editable-content-label_with-button", null]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.startEditing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.pen && !_co.bin); _ck(_v, 3, 0, currVal_5); var currVal_6 = (_co.bin && !_co.pen); _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inline; var currVal_1 = _co.outline; var currVal_2 = _co.cursorPointer; var currVal_3 = (_co.pen || _co.bin); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.value || _co.emptyValue); _ck(_v, 1, 0, currVal_4); }); }
function View_EditableContentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "textarea", [["class", "editable-content-input cdk-textarea-autosize"], ["rows", "1"]], [[2, "editable-content-input_inline", null], [2, "editable-content-input_outline", null], [2, "editable-content-input_outline-background", null], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "keypress"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 6)._noopInputHandler() !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.currentValue = $event) !== false);
        ad = (pd_5 && ad);
    } if (("keyup" === en)) {
        var pd_6 = (_co.onKeyUp($event) !== false);
        ad = (pd_6 && ad);
    } if (("keypress" === en)) {
        var pd_7 = (_co.onKeyPress($event) !== false);
        ad = (pd_7 && ad);
    } if (("blur" === en)) {
        var pd_8 = (_co.finishEditing() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(3, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(6, 4603904, [[1, 4]], 0, i4.CdkTextareaAutosize, [i0.ElementRef, i5.Platform, i0.NgZone], { enabled: [0, "enabled"] }, null), i0.ɵdid(7, 540672, null, 0, i6.AutofocusDirective, [i0.ElementRef, i7.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.currentValue; _ck(_v, 3, 0, currVal_11); var currVal_12 = _co.autogrow; _ck(_v, 6, 0, currVal_12); var currVal_13 = true; _ck(_v, 7, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inline; var currVal_1 = _co.activeTextColor; var currVal_2 = _co.outline; var currVal_3 = _co.placeholder; var currVal_4 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_5 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_6 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_7 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_8 = i0.ɵnov(_v, 5).ngClassValid; var currVal_9 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_10 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }); }
function View_EditableContentComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "input", [["class", "editable-content-input"]], [[2, "editable-content-input_inline", null], [2, "editable-content-input_outline", null], [2, "editable-content-input_outline-background", null], [4, "width", null], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "keypress"], [null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.currentValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.onKeyUp($event) !== false);
        ad = (pd_5 && ad);
    } if (("keypress" === en)) {
        var pd_6 = (_co.onKeyPress($event) !== false);
        ad = (pd_6 && ad);
    } if (("blur" === en)) {
        var pd_7 = (_co.finishEditing() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(3, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(6, 540672, null, 0, i6.AutofocusDirective, [i0.ElementRef, i7.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_12 = _co.currentValue; _ck(_v, 3, 0, currVal_12); var currVal_13 = true; _ck(_v, 6, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inline; var currVal_1 = _co.activeTextColor; var currVal_2 = _co.outline; var currVal_3 = (_co.autogrow ? (((_co.currentValue.length >= 5) ? _co.currentValue.length : 5) + "ch") : "auto"); var currVal_4 = _co.placeholder; var currVal_5 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_6 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_7 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_8 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_9 = i0.ɵnov(_v, 5).ngClassValid; var currVal_10 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_11 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
function View_EditableContentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_5)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiline; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.multiline; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EditableContentComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { autosize: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableContentComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EditableContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-editable-content", [], null, null, null, View_EditableContentComponent_0, RenderType_EditableContentComponent)), i0.ɵdid(1, 770048, null, 0, i8.EditableContentComponent, [i0.ChangeDetectorRef, i0.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditableContentComponentNgFactory = i0.ɵccf("app-editable-content", i8.EditableContentComponent, View_EditableContentComponent_Host_0, { value: "value", emptyValue: "emptyValue", defaultValue: "defaultValue", placeholder: "placeholder", pen: "pen", penHover: "penHover", penTitle: "penTitle", bin: "bin", binTip: "binTip", inline: "inline", multiline: "multiline", activeTextColor: "activeTextColor", outline: "outline", autogrow: "autogrow", cursorPointer: "cursorPointer", cleanValue: "cleanValue", immediateUpdate: "immediateUpdate" }, { valueChange: "valueChange", delete: "delete" }, []);
export { EditableContentComponentNgFactory as EditableContentComponentNgFactory };

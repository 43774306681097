import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TipsModule } from '@common/tips';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeElementsModule } from '@modules/customize-elements';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { PageTemplatePreviewCustomComponent } from './components/page-template-preview-custom/page-template-preview-custom.component';
import { PageTemplatePreviewOtherPageComponent } from './components/page-template-preview-other-page/page-template-preview-other-page.component';
import { PageTemplatePreviewPageComponent } from './components/page-template-preview-page/page-template-preview-page.component';
import { PageTemplatePreviewStartPageComponent } from './components/page-template-preview-start-page/page-template-preview-start-page.component';
import { PageTemplatePreviewStubComponent } from './components/page-template-preview-stub/page-template-preview-stub.component';
import { PageTemplatePreviewComponent } from './components/page-template-preview/page-template-preview.component';
import { PageTemplatesChooseItemModelComponent } from './components/page-templates-choose-item-model/page-templates-choose-item-model.component';
import { PageTemplatesChooseItemTypeComponent } from './components/page-templates-choose-item-type/page-templates-choose-item-type.component';
import { PageTemplatesChooseItemComponent } from './components/page-templates-choose-item/page-templates-choose-item.component';
import { PageTemplatesItemSettingsFieldsComponent } from './components/page-templates-item-settings-fields/page-templates-item-settings-fields.component';
import { PageTemplatesItemSettingsComponent } from './components/page-templates-item-settings/page-templates-item-settings.component';
import { PageTemplatesComponent } from './components/page-templates/page-templates.component';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    CustomizeComponentsModule,
    SharedModule,
    DragDropModule,
    TipsModule,
    CustomizeElementsModule
  ],
  declarations: [
    PageTemplatesComponent,
    PageTemplatesChooseItemTypeComponent,
    PageTemplatesChooseItemModelComponent,
    PageTemplatesItemSettingsComponent,
    PageTemplatePreviewPageComponent,
    PageTemplatePreviewComponent,
    PageTemplatePreviewCustomComponent,
    PageTemplatesItemSettingsFieldsComponent,
    PageTemplatePreviewStubComponent,
    PageTemplatePreviewStartPageComponent,
    PageTemplatePreviewOtherPageComponent,
    PageTemplatesChooseItemComponent
  ],
  entryComponents: [PageTemplatesComponent]
})
export class TemplateGeneratorsComponentsModule {}

import isEqual from 'lodash/isEqual';

import { isSet } from '@shared';

import { SideConstraint, SizeConstraint } from '../data/constraints';
import { Frame } from '../data/frame';
import { FrameTranslate } from '../data/frame-translate';
import { Layer } from '../data/layer';
import { getAllLayers, getAllLayersById } from './traverse';

interface BaseRect {
  bottom: number;
  left: number;
  right: number;
  top: number;
}

export function rectFromFrames(items: FrameTranslate[]): BaseRect {
  return items.reduce<{ top: number; right: number; bottom: number; left: number }>((acc, frame) => {
    acc = acc || Object();

    const right = frame.x + frame.width;
    const bottom = frame.y + frame.height;

    if (!isSet(acc.top) || frame.y < acc.top) {
      acc.top = frame.y;
    }

    if (!isSet(acc.right) || right > acc.right) {
      acc.right = right;
    }

    if (!isSet(acc.bottom) || bottom > acc.bottom) {
      acc.bottom = bottom;
    }

    if (!isSet(acc.left) || frame.x < acc.left) {
      acc.left = frame.x;
    }

    return acc;
  }, undefined);
}

export function frameFromFrames(items: FrameTranslate[]): Frame {
  const rect = rectFromFrames(items);
  if (!rect) {
    return;
  }

  return new Frame({
    x: rect.left,
    y: rect.top,
    width: rect.right - rect.left,
    height: rect.bottom - rect.top
  });
}

export function resizeLayers(options: {
  layers: Layer[];
  originalLayers?: Layer[];
  originalFrame: Frame;
  newFrame: Frame;
  deltaX?: number;
  deltaY?: number;
}): boolean {
  const sizeChanged = !isEqual(options.originalFrame.getSize(), options.newFrame.getSize());
  if (!sizeChanged) {
    return false;
  }

  const originalLayersById = options.originalLayers ? getAllLayersById(options.originalLayers) : undefined;
  const widthMultiplier = options.originalFrame.width ? options.newFrame.width / options.originalFrame.width : 1;
  const heightMultiplier = options.originalFrame.height ? options.newFrame.height / options.originalFrame.height : 1;

  getAllLayers(options.layers, { absoluteLayout: true }).forEach(layer => {
    const originalLayer = originalLayersById ? originalLayersById[layer.id] : layer;
    if (!originalLayer) {
      return;
    }

    const updateFrame: Partial<Frame> = {};
    const originalLayerFrame = new Frame(originalLayer.frame);
    const constraints = layer.getConstraintsOrDefault();

    if (constraints.isHorizontalSides(SideConstraint.Fixed)) {
      const left = originalLayerFrame.x;
      const right = options.originalFrame.width - (originalLayerFrame.x + originalLayerFrame.width);
      updateFrame.width = options.newFrame.width - left - right;
    } else if (constraints.width == SizeConstraint.Fluid) {
      updateFrame.width = Math.round(originalLayerFrame.width * widthMultiplier);
    }

    if (constraints.isVerticalSides(SideConstraint.Fixed)) {
      const top = originalLayerFrame.y;
      const bottom = options.originalFrame.height - (originalLayerFrame.y + originalLayerFrame.height);
      updateFrame.height = options.newFrame.height - top - bottom;
    } else if (constraints.height == SizeConstraint.Fluid) {
      updateFrame.height = Math.round(originalLayerFrame.height * heightMultiplier);
    }

    if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fluid) {
      const originalXCenter = originalLayerFrame.x + originalLayerFrame.width * 0.5;
      updateFrame.x = Math.round(originalXCenter * widthMultiplier - layer.frame.width * 0.5);
    } else if (constraints.left == SideConstraint.Fluid && constraints.right == SideConstraint.Fixed) {
      const right = options.originalFrame.width - (originalLayerFrame.x + originalLayerFrame.width);
      updateFrame.x = options.newFrame.width - right - layer.frame.width;
    }

    if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fluid) {
      const originalYCenter = originalLayerFrame.y + originalLayerFrame.height * 0.5;
      updateFrame.y = Math.round(originalYCenter * heightMultiplier - layer.frame.height * 0.5);
    } else if (constraints.top == SideConstraint.Fluid && constraints.bottom == SideConstraint.Fixed) {
      const bottom = options.originalFrame.height - (originalLayerFrame.y + originalLayerFrame.height);
      updateFrame.y = options.newFrame.height - bottom - layer.frame.height;
    }

    if (isSet(updateFrame.x) && options.deltaX) {
      updateFrame.x += options.deltaX;
    }

    if (isSet(updateFrame.y) && options.deltaY) {
      updateFrame.y += options.deltaY;
    }

    layer.frame.patch(updateFrame);
  });

  return true;
}

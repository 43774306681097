/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./toggle.component";
var styles_ToggleComponent = [];
var RenderType_ToggleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToggleComponent, data: {} });
export { RenderType_ToggleComponent as RenderType_ToggleComponent };
function View_ToggleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ON"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_ToggleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "toggle__label toggle__label_position_left"]], [[2, "toggle__label_disabled", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
function View_ToggleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "toggle__track-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trackLabel; _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["ON"]))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_ToggleComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "toggle__label toggle__label_position_right"]], [[2, "toggle__label_disabled", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
export function View_ToggleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["class", "toggle"]], [[2, "toggle_orange", null], [2, "toggle_disabled", null], [2, "toggle_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "toggle__track"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "toggle__knob"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToggleComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.label && (_co.labelPosition == "left")); _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.trackLabel; _ck(_v, 5, 0, currVal_4); var currVal_5 = (_co.label && (_co.labelPosition == "right")); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.orange; var currVal_2 = _co.disabled; var currVal_3 = _co.selected; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_ToggleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toggle", [], null, null, null, View_ToggleComponent_0, RenderType_ToggleComponent)), i0.ɵdid(1, 114688, null, 0, i2.ToggleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToggleComponentNgFactory = i0.ɵccf("app-toggle", i2.ToggleComponent, View_ToggleComponent_Host_0, { label: "label", labelPosition: "labelPosition", trackLabel: "trackLabel", orange: "orange", selected: "selected", disabled: "disabled", loading: "loading" }, {}, []);
export { ToggleComponentNgFactory as ToggleComponentNgFactory };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, interval, merge, of } from 'rxjs';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { BasePopupComponent } from '@common/popups';

export enum PopoverPosition {
  BottomLeft = 'bottom_left',
  BottomRight = 'bottom_right',
  TopLeft = 'top_left',
  TopRight = 'top_right'
}

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: DynamicComponentArguments;
  @Input() anchor: Element;
  @Output() showed = new BehaviorSubject<boolean>(false);
  @ViewChild('background') background: ElementRef;
  @ViewChild('root') root: ElementRef;

  position: PopoverPosition;
  styles = {};
  tl = new TimelineMax();
  openDuration = 0.3;
  closeDuration = 0.2;

  constructor(private cd: ChangeDetectorRef, private popupComponent: BasePopupComponent) {}

  ngOnInit() {
    this.data.providers = (this.data.providers || []).concat([{ provide: PopoverComponent, useValue: this }]);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.updatePosition();
    this.cd.detectChanges();
    this.open();
  }

  open() {
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        this.openDuration,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        },
        0.1
      )
      .fromTo(
        this.root.nativeElement,
        this.openDuration,
        {
          scale: 0.8,
          opacity: 0
        },
        {
          scale: 1,
          opacity: 1,
          ease: Power2.easeOut
        },
        0.1
      )
      .add(() => {
        this.checkWrongPosition();
        this.showed.next(true);
      });
  }

  checkWrongPosition() {
    merge(of({}), interval(100))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.root.nativeElement.getBoundingClientRect().top < 0) {
          if (this.position == PopoverPosition.TopLeft) {
            this.position = PopoverPosition.BottomLeft;
            this.cd.detectChanges();
            this.updateStyles();
          } else if (this.position == PopoverPosition.TopRight) {
            this.position = PopoverPosition.BottomRight;
            this.cd.detectChanges();
            this.updateStyles();
          }
        }
      });
  }

  updatePosition() {
    const anchorRect = this.anchor.getBoundingClientRect();
    const x = anchorRect.left + anchorRect.width / 2;
    const y = anchorRect.top + anchorRect.height / 2;
    const leftSide = x < window.innerWidth / 2;
    const upperSide = y < window.innerHeight / 2;
    let position: PopoverPosition;

    if (leftSide && upperSide) {
      position = PopoverPosition.BottomRight;
    } else if (!leftSide && upperSide) {
      position = PopoverPosition.BottomLeft;
    } else if (leftSide && !upperSide) {
      position = PopoverPosition.TopRight;
    } else if (!leftSide && !upperSide) {
      position = PopoverPosition.TopLeft;
    }

    this.position = position;
    this.cd.detectChanges();

    this.updateStyles();
  }

  updateStyles() {
    const anchorRect = this.anchor.getBoundingClientRect();
    const x = anchorRect.left + anchorRect.width / 2;
    const y = anchorRect.top + anchorRect.height / 2;

    if (this.position == PopoverPosition.BottomRight) {
      this.styles = { 'left.px': x, 'top.px': y };
    } else if (this.position == PopoverPosition.BottomLeft) {
      this.styles = { 'right.px': window.innerWidth - x, 'top.px': y };
    } else if (this.position == PopoverPosition.TopRight) {
      this.styles = { 'left.px': x, 'bottom.px': window.innerHeight - y };
    } else if (this.position == PopoverPosition.TopLeft) {
      this.styles = { 'right.px': window.innerWidth - x, 'bottom.px': window.innerHeight - y };
    }
  }

  close() {
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        this.closeDuration,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeIn
        }
      )
      .fromTo(
        this.root.nativeElement,
        this.closeDuration,
        {
          scale: 1,
          opacity: 1
        },
        {
          scale: 0.8,
          opacity: 0,
          ease: Power2.easeIn
        },
        0
      )
      .add(() => this.popupComponent.close());
  }

  onBackgroundClick(e) {
    if (e.target === this.background.nativeElement) {
      this.close();
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ShowVideoOptions } from '@modules/tutorial';

@Component({
  selector: 'app-customize-menu-banner',
  templateUrl: './customize-menu-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuBannerComponent implements OnInit {
  @Input() uid: string;
  @Input() title: string;
  @Input() videoPreview: string;
  @Input() showVideoOptions: ShowVideoOptions = {};

  ngOnInit() {}

  getViewPreview(): string {
    if (this.videoPreview) {
      return this.videoPreview;
    } else if (this.showVideoOptions.youtubeId) {
      return `https://img.youtube.com/vi/${this.showVideoOptions.youtubeId}/hqdefault.jpg`;
    }
  }
}

<mat-menu #dropdown="matMenu" [class]="panelClassesStr">
  <ng-template matMenuContent let-menuItems="menuItems">
    <app-custom-select-dropdown
      [items]="menuItems"
      [currentOption]="currentOption"
      [searchEnabled]="searchEnabled"
      [emptyLabel]="emptyLabel"
      [capitalize]="capitalize"
      (optionClick)="optionClick.emit($event)"
      (buttonClick)="buttonClick.emit($event)"
    ></app-custom-select-dropdown>
  </ng-template>
</mat-menu>

<div
  *ngIf="displaySearch"
  class="mat-menu-item-search-container mat-menu-item-search-container_border mat-menu-item_top-sticky"
  (click)="$event.stopPropagation()"
>
  <span class="mat-menu-item-search-icon icon-search"></span>
  <input
    class="mat-menu-item-search mat-menu-item-search_icon"
    [(ngModel)]="search"
    [placeholder]="'Search...' | localize"
    [appAutofocus]="true"
    (keyup)="updateFilteredItems()"
    (keydown)="$event.stopPropagation()"
  />
</div>

<ng-container *ngFor="let item of filteredItems; let i = index">
  <div
    *ngIf="item.item.subtitle && item.item.subtitle != filteredItems[i - 1]?.item.subtitle"
    class="mat-menu-item-subtitle"
  >
    {{ item.item.subtitle }}
  </div>

  <button
    *ngIf="item.item.option as option"
    mat-menu-item
    [class.mat-menu-item-orange]="item.item.orange"
    [class.mat-menu-item-large]="item.item.large"
    [class.mat-menu-item-active]="item.active"
    [class.mat-menu-item_bottom-sticky]="item.item.stickyBottom"
    [class.mat-menu-item-odd]="true"
    [class.mat-menu-item-border]="item.item.stickyBottom"
    [disableRipple]="true"
    (click)="optionClick.next(option)"
  >
    <span
      *ngIf="option.icon"
      class="choose-items-item__dropdown-icon"
      [class.choose-items-item__dropdown-icon_orange]="item.item.orange"
      [ngClass]="['icon-' + option.icon]"
    ></span>
    <img *ngIf="option.image" class="choose-items-item__dropdown-icon" alt="" [src]="option.image" />

    {{ option.name | appCapitalize: capitalize }}
  </button>

  <ng-container *ngIf="item.item.button as button">
    <button
      *ngIf="item.item.children?.length"
      mat-menu-item
      [class.mat-menu-item-orange]="item.item.orange"
      [class.mat-menu-item-large]="item.item.large"
      [class.mat-menu-item-active]="item.active"
      [class.mat-menu-item_top-sticky]="item.item.stickyTop"
      [class.mat-menu-item_bottom-sticky]="item.item.stickyBottom"
      [class.mat-menu-item-odd]="true"
      [class.mat-menu-item-border]="item.item.stickyTop || item.item.stickyBottom"
      [disableRipple]="true"
      [matMenuTriggerFor]="dropdown"
      [matMenuTriggerData]="{ menuItems: item.item.children }"
      (click)="buttonClick.next(button)"
    >
      <span
        *ngIf="button.icon"
        class="choose-items-item__dropdown-icon"
        [class.choose-items-item__dropdown-icon_orange]="item.item.orange"
        [ngClass]="['icon-' + button.icon]"
      ></span>
      <img *ngIf="button.image" class="choose-items-item__dropdown-icon" alt="" [src]="button.image" />

      {{ button.label | appCapitalize: capitalize }}
    </button>

    <button
      *ngIf="!item.item.children?.length"
      mat-menu-item
      [class.mat-menu-item-orange]="item.item.orange"
      [class.mat-menu-item-large]="item.item.large"
      [class.mat-menu-item-active]="item.active"
      [class.mat-menu-item_top-sticky]="item.item.stickyTop"
      [class.mat-menu-item_bottom-sticky]="item.item.stickyBottom"
      [class.mat-menu-item-odd]="true"
      [class.mat-menu-item-border]="item.item.stickyTop || item.item.stickyBottom"
      [disableRipple]="true"
      (click)="buttonClick.next(button)"
    >
      <span
        *ngIf="button.icon"
        class="choose-items-item__dropdown-icon"
        [class.choose-items-item__dropdown-icon_orange]="item.item.orange"
        [ngClass]="['icon-' + button.icon]"
      ></span>
      <img *ngIf="button.image" class="choose-items-item__dropdown-icon" alt="" [src]="button.image" />

      {{ button.label | appCapitalize: capitalize }}
    </button>
  </ng-container>
</ng-container>

<div *ngIf="!filteredItems.length" class="mat-menu-item-message">
  <ng-container *ngIf="search">Nothing found</ng-container>
  <ng-container *ngIf="!search">{{ emptyLabel }}</ng-container>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i2 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i3 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i4 from "../../../../fields/components/auto-field/auto-field.component";
import * as i5 from "../../value-format-edit/value-format-edit.component.ngfactory";
import * as i6 from "../../value-format-edit/value-format-edit.component";
import * as i7 from "@angular/common";
import * as i8 from "../../value-format-edit/value-format.control";
import * as i9 from "../../../../projects/stores/current-project.store";
import * as i10 from "./char-field-view-params.form";
import * as i11 from "@angular/forms";
import * as i12 from "./char-field-view-params.component";
var styles_CharFieldViewParamsComponent = [];
var RenderType_CharFieldViewParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CharFieldViewParamsComponent, data: {} });
export { RenderType_CharFieldViewParamsComponent as RenderType_CharFieldViewParamsComponent };
function View_CharFieldViewParamsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"], additional: [1, "additional"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i3.View_AutoFieldComponent_0, i3.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i4.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { classes: 0 }), i0.ɵpod(7, { name: 0, field: 1, placeholder: 2, params: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editable ? "number of lines" : "limit number of lines"); var currVal_1 = (_co.editable ? undefined : "(optional)"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.form.form; var currVal_3 = _co.createField(_ck(_v, 7, 0, "rows", "IntegerField", (_co.editable ? "Default is 8" : "Leave blank to disable limit"), _ck(_v, 6, 0, _ck(_v, 5, 0, "input_fill")))); var currVal_4 = false; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, null); }
function View_CharFieldViewParamsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-value-format-edit", [], null, null, null, i5.View_ValueFormatEditComponent_0, i5.RenderType_ValueFormatEditComponent)), i0.ɵdid(1, 638976, null, 0, i6.ValueFormatEditComponent, [i0.ChangeDetectorRef], { control: [0, "control"], field: [1, "field"], formatInputEnabled: [2, "formatInputEnabled"], prefixEnabled: [3, "prefixEnabled"], postfixEnabled: [4, "postfixEnabled"], numberFormatOptionsEnabled: [5, "numberFormatOptionsEnabled"], context: [6, "context"], analyticsSource: [7, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.form.controls["value_format"]; var currVal_1 = _co.fieldTypes.Text; var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = _co.context; var currVal_7 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_CharFieldViewParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"], layoutLeft: [1, "layoutLeft"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 3, "app-auto-field", [], null, null, null, i3.View_AutoFieldComponent_0, i3.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i4.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpod(5, { style: 0, orange: 1 }), i0.ɵpod(6, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵeld(7, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Long text field that can span multiple lines "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CharFieldViewParamsComponent_1)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CharFieldViewParamsComponent_2)), i0.ɵdid(12, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "multiple lines"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.form.form; var currVal_3 = _co.createField(_ck(_v, 6, 0, "multiline", "BooleanField", _ck(_v, 5, 0, _co.booleanFieldStyle.Toggle, true))); var currVal_4 = false; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.form.form.value["multiline"]; _ck(_v, 10, 0, currVal_5); var currVal_6 = !_co.editable; _ck(_v, 12, 0, currVal_6); }, null); }
export function View_CharFieldViewParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-char-field-view-params", [], null, null, null, View_CharFieldViewParamsComponent_0, RenderType_CharFieldViewParamsComponent)), i0.ɵprd(131584, null, i8.ValueFormatControl, i8.ValueFormatControl, [i9.CurrentProjectStore]), i0.ɵprd(512, null, i10.CharFieldViewParamsForm, i10.CharFieldViewParamsForm, [i11.FormBuilder, i8.ValueFormatControl]), i0.ɵdid(3, 245760, null, 0, i12.CharFieldViewParamsComponent, [i10.CharFieldViewParamsForm, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CharFieldViewParamsComponentNgFactory = i0.ɵccf("app-char-field-view-params", i12.CharFieldViewParamsComponent, View_CharFieldViewParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CharFieldViewParamsComponentNgFactory as CharFieldViewParamsComponentNgFactory };

<app-sidebar-header [backEnabled]="true" (back)="back()"> </app-sidebar-header>

<div class="sidebar__content" style="margin-bottom: 20px;">
  <app-add-menu-item-menu
    [layout]="layout"
    (selectMenuItem)="addMenuItem(startItemsControl, $event)"
    #add_top_menu_item
  ></app-add-menu-item-menu>

  <app-add-menu-item-menu
    [layout]="layout"
    (selectMenuItem)="addMenuItem(centerItemsControl, $event)"
    #add_center_menu_item
  ></app-add-menu-item-menu>

  <app-add-menu-item-menu
    [layout]="layout"
    (selectMenuItem)="addMenuItem(endItemsControl, $event)"
    #add_bottom_menu_item
  ></app-add-menu-item-menu>

  <div class="sidebar__element" style="height: calc(100vh - 123px); margin-bottom: 0;">
    <div appDropListGroup class="sidebar-containers">
      <div class="sidebar-container sidebar-containers__item">
        <div class="sidebar-container__header">
          <div class="sidebar-container__header-left">
            <div
              class="sidebar-container__header-icon"
              [class.icon-align_left]="horizontal"
              [class.icon-align_vertical_top]="!horizontal"
            ></div>
          </div>
          <div class="sidebar-container__header-main">
            <div class="sidebar-container__header-title">
              <ng-container *ngIf="horizontal">Left</ng-container>
              <ng-container *ngIf="!horizontal">Top</ng-container>
            </div>
          </div>
          <div class="sidebar-container__header-right">
            <div
              class="sidebar-container__header-button sidebar-container__header-item"
              [matMenuTriggerFor]="add_top_menu_item.menu"
              [matMenuTriggerData]="{ prepend: true }"
            >
              <div
                class="sidebar-container__header-button-icon sidebar-container__header-button-icon_left icon-plus"
              ></div>
              <div class="sidebar-container__header-button-label">Add</div>
            </div>
          </div>
        </div>

        <div
          class="sidebar-container__content"
          [class.sidebar-container__content_border]="!startItemsControl.controls.length || true"
        >
          <div
            class="sidebar-container__content-container"
            appDropList
            [appDropListData]="startItemsControl"
            [appDropListLockAxis]="dragAxis.Y"
            (appDropListDropped)="dragDrop($event)"
          >
            <div
              *ngFor="let item of startItemsControl.controls; let i = index; trackBy: trackMenuItemFn"
              appDrag
              [appDragData]="item"
            >
              <app-auto-menu-item-edit
                [control]="item"
                [layout]="layout"
                [createdOptions]="item === createdMenuItemControl ? createdMenuItemControlOptions : undefined"
                (deleteRequested)="startItemsControl.removeAt(i)"
              ></app-auto-menu-item-edit>
            </div>
          </div>

          <div *ngIf="!startItemsControl.controls.length" class="sidebar-container__content-empty">
            Nothing here
          </div>
        </div>
      </div>

      <div class="sidebar-container sidebar-containers__item">
        <div class="sidebar-container__header">
          <div class="sidebar-container__header-left">
            <div
              class="sidebar-container__header-icon"
              [class.icon-align_center]="horizontal"
              [class.icon-align_vertical_center]="!horizontal"
            ></div>
          </div>
          <div class="sidebar-container__header-main">
            <div class="sidebar-container__header-title">
              Center
            </div>
          </div>
          <div class="sidebar-container__header-right">
            <div
              class="sidebar-container__header-button sidebar-container__header-item"
              [matMenuTriggerFor]="add_center_menu_item.menu"
              [matMenuTriggerData]="{ prepend: true }"
            >
              <div
                class="sidebar-container__header-button-icon sidebar-container__header-button-icon_left icon-plus"
              ></div>
              <div class="sidebar-container__header-button-label">Add</div>
            </div>
          </div>
        </div>

        <div
          class="sidebar-container__content"
          [class.sidebar-container__content_border]="!centerItemsControl.controls.length || true"
        >
          <div
            class="sidebar-container__content-container"
            appDropList
            [appDropListData]="centerItemsControl"
            [appDropListLockAxis]="dragAxis.Y"
            (appDropListDropped)="dragDrop($event)"
          >
            <div
              *ngFor="let item of centerItemsControl.controls; let i = index; trackBy: trackMenuItemFn"
              appDrag
              [appDragData]="item"
            >
              <app-auto-menu-item-edit
                [control]="item"
                [layout]="layout"
                [createdOptions]="item === createdMenuItemControl ? createdMenuItemControlOptions : undefined"
                (deleteRequested)="centerItemsControl.removeAt(i)"
              ></app-auto-menu-item-edit>
            </div>
          </div>

          <div *ngIf="!centerItemsControl.controls.length" class="sidebar-container__content-empty">
            Nothing here
          </div>
        </div>
      </div>

      <div class="sidebar-container sidebar-containers__item sidebar-containers__item_fit">
        <div class="sidebar-container__header">
          <div class="sidebar-container__header-left">
            <div
              class="sidebar-container__header-icon"
              [class.icon-align_right]="horizontal"
              [class.icon-align_vertical_bottom]="!horizontal"
            ></div>
          </div>
          <div class="sidebar-container__header-main">
            <div class="sidebar-container__header-title">
              <ng-container *ngIf="horizontal">Right</ng-container>
              <ng-container *ngIf="!horizontal">Bottom</ng-container>
            </div>
          </div>
          <div class="sidebar-container__header-right">
            <div
              class="sidebar-container__header-button sidebar-container__header-item"
              [matMenuTriggerFor]="add_bottom_menu_item.menu"
              [matMenuTriggerData]="{ prepend: true }"
            >
              <div
                class="sidebar-container__header-button-icon sidebar-container__header-button-icon_left icon-plus"
              ></div>
              <div class="sidebar-container__header-button-label">Add</div>
            </div>
          </div>
        </div>

        <div
          class="sidebar-container__content"
          [class.sidebar-container__content_border]="!endItemsControl.controls.length || true"
        >
          <div
            class="sidebar-container__content-container"
            appDropList
            [appDropListData]="endItemsControl"
            [appDropListLockAxis]="dragAxis.Y"
            (appDropListDropped)="dragDrop($event)"
          >
            <div
              *ngFor="let item of endItemsControl.controls; let i = index; trackBy: trackMenuItemFn"
              appDrag
              [appDragData]="item"
            >
              <app-auto-menu-item-edit
                [control]="item"
                [layout]="layout"
                [createdOptions]="item === createdMenuItemControl ? createdMenuItemControlOptions : undefined"
                (deleteRequested)="endItemsControl.removeAt(i)"
              ></app-auto-menu-item-edit>
            </div>
          </div>

          <div *ngIf="!endItemsControl.controls.length" class="sidebar-container__content-empty">
            Nothing here
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

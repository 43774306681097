var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IgnoreScrollTop } from '@common/route-scroll';
import { ChangeComponent } from '@modules/change-routes';
import { ChangeViewSettings, CustomizeService, CustomViewSettings, ListViewSettings, ViewSettingsStore, ViewSettingsType, WithBuilderMode } from '@modules/customize';
import { ListComponent } from '@modules/list-routes';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';
import { CustomPageComponent } from '../custom-page/custom-page.component';
var ɵ0 = function (newSnapshot, prevSnapshot) {
    var page = newSnapshot.params['page'];
    var prevPage = prevSnapshot ? prevSnapshot.params['page'] : undefined;
    return page == prevPage;
};
var PageComponent = /** @class */ (function () {
    function PageComponent(activatedRoute, currentProjectStore, currentEnvironmentStore, customizeService, viewSettingsStore, routing, cd) {
        this.activatedRoute = activatedRoute;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.customizeService = customizeService;
        this.viewSettingsStore = viewSettingsStore;
        this.routing = routing;
        this.cd = cd;
        this.types = ViewSettingsType;
        this.deprecatedTypes = [ViewSettingsType.List, ViewSettingsType.Change];
        this.notFound = false;
        this.notAllowed = false;
    }
    PageComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.activatedRoute.params.pipe(switchMap(function (params) {
            if (isSet(params['page'])) {
                return _this.viewSettingsStore.getDetailFirst(params['page']);
            }
            else {
                return of(new CustomViewSettings());
            }
        })), this.activatedRoute.queryParams, this.currentEnvironmentStore.instance$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var viewSettings = _a[0], queryParams = _a[1], environment = _a[2];
            if (viewSettings) {
                var hasPermission = true;
                if (!_this.customizeService.layoutEnabled) {
                    if (viewSettings instanceof CustomViewSettings) {
                        hasPermission = _this.currentProjectStore.instance.hasEnvironmentPagePermission(environment, viewSettings.uid, 'r');
                    }
                    else if (viewSettings instanceof ChangeViewSettings) {
                        hasPermission = _this.currentProjectStore.instance.hasEnvironmentModelPermission(environment, viewSettings.uid, 'w');
                    }
                    else if (viewSettings instanceof ListViewSettings) {
                        hasPermission = _this.currentProjectStore.instance.hasEnvironmentModelPermission(environment, viewSettings.uid, 'r');
                    }
                }
                if (hasPermission) {
                    _this.type = viewSettings.view;
                    _this.uniqueName = viewSettings.uniqueName;
                    _this.notFound = false;
                    _this.notAllowed = false;
                }
                else {
                    _this.type = undefined;
                    _this.uniqueName = undefined;
                    _this.notFound = false;
                    _this.notAllowed = true;
                }
            }
            else {
                _this.type = undefined;
                _this.uniqueName = undefined;
                _this.notFound = true;
                _this.notAllowed = false;
            }
            if (viewSettings instanceof ChangeViewSettings) {
                _this.modelId =
                    viewSettings.getQuery && viewSettings.getQuery.simpleQuery
                        ? [viewSettings.getResource, viewSettings.getQuery.simpleQuery.model].join('.')
                        : undefined;
                _this.id = queryParams['id'];
            }
            else {
                _this.modelId = undefined;
                _this.id = undefined;
            }
            _this.params = queryParams;
            _this.cd.markForCheck();
        });
    };
    PageComponent.prototype.ngOnDestroy = function () { };
    PageComponent.prototype.onBeforeDestroy = function () {
        if (this.listComponent) {
            this.listComponent.onBeforeDestroy();
        }
        if (this.changeComponent) {
            this.changeComponent.onBeforeDestroy();
        }
        if (this.customPageComponent) {
            this.customPageComponent.onBeforeDestroy();
        }
    };
    PageComponent.prototype.onParamsChanged = function (params) {
        this.routing.navigateApp(['page', this.uniqueName], {
            queryParams: params
        });
    };
    PageComponent = __decorate([
        WithBuilderMode,
        IgnoreScrollTop({
            predicate: ɵ0
        }),
        __metadata("design:paramtypes", [ActivatedRoute,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            CustomizeService,
            ViewSettingsStore,
            RoutingService,
            ChangeDetectorRef])
    ], PageComponent);
    return PageComponent;
}());
export { PageComponent };
export { ɵ0 };

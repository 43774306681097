import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { ViewSettings } from '@modules/customize';
import {
  CreateFormGenerator,
  DataSourceGeneratorService,
  PersistentIdGenerator,
  UpdateFormGenerator,
  ViewSettingsGeneratorService
} from '@modules/customize-generators';
import { ListLayoutType } from '@modules/layouts';
import { ModelDescription } from '@modules/models';
import {
  PageTemplate,
  PageTemplatesCreateResult,
  PageTemplatesGeneratorService,
  PageVariantType,
  TemplateSettings
} from '@modules/template-generators';

import { PageTemplatesItemFieldArray } from './page-templates-item-field.array';

export const validateFields: ValidatorFn = (control: PageTemplatesItemFieldArray) => {
  if (!control.parent) {
    return;
  }

  if (!control.controls.some(fieldControl => fieldControl.controls.enabled.value)) {
    return { required: true };
  }
};

@Injectable()
export class PageTemplatesItemSettingsForm extends FormGroup {
  template: PageTemplate;
  modelDescription: ModelDescription;
  templateVariant: PageVariantType;

  controls: {
    listFields: PageTemplatesItemFieldArray;
    createFields: PageTemplatesItemFieldArray;
    updateFields: PageTemplatesItemFieldArray;
    detailFields: PageTemplatesItemFieldArray;
  };

  constructor(
    private pageTemplatesGeneratorService: PageTemplatesGeneratorService,
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    private createFormGenerator: CreateFormGenerator,
    private updateFormGenerator: UpdateFormGenerator
  ) {
    super({
      listFields: new PageTemplatesItemFieldArray([], validateFields),
      createFields: new PageTemplatesItemFieldArray([]),
      updateFields: new PageTemplatesItemFieldArray([]),
      detailFields: new PageTemplatesItemFieldArray([])
    });
  }

  init(template: PageTemplate, templateVariant: PageVariantType, modelDescription: ModelDescription) {
    this.template = template;
    this.templateVariant = templateVariant;
    this.modelDescription = modelDescription;

    const layoutSettings = this.viewSettingsGeneratorService.layoutSettings.find(
      item => item.layout == ListLayoutType.Table
    );
    const listFields = this.dataSourceGeneratorService.getModelColumns(modelDescription, layoutSettings.columns);
    const detailFields = this.dataSourceGeneratorService.getModelColumns(modelDescription);

    const createFields = this.createFormGenerator.getCreateParameters(modelDescription).map(item => {
      return {
        name: item.name,
        verboseName: item.verboseName,
        field: item.field,
        params: item.params,
        visible: true
      };
    });
    const updateFields = this.updateFormGenerator.getUpdateParameters(modelDescription).map(item => {
      return {
        name: item.name,
        verboseName: item.verboseName,
        field: item.field,
        params: item.params,
        visible: true
      };
    });

    this.controls.listFields.deserialize(listFields);
    this.controls.createFields.deserialize(createFields);
    this.controls.updateFields.deserialize(updateFields);
    this.controls.detailFields.deserialize(detailFields);
  }

  getTemplateSettings(): TemplateSettings {
    return {
      modelDescription: this.modelDescription,
      templateVariant: this.templateVariant,
      listFields: this.controls.listFields.getVisibleNames(),
      createFields: this.controls.createFields.getVisibleNames(),
      updateFields: this.controls.updateFields.getVisibleNames(),
      detailFields: this.controls.detailFields.getVisibleNames()
    };
  }

  createTemplatePages(idGenerator: PersistentIdGenerator): Observable<PageTemplatesCreateResult> {
    const settings = this.getTemplateSettings();
    return this.pageTemplatesGeneratorService.createTemplatePages(this.template, settings, {
      idGenerator: idGenerator
    });
  }

  submit(): Observable<ViewSettings> {
    const settings = this.getTemplateSettings();
    return this.pageTemplatesGeneratorService.applyTemplate(this.template, settings);
  }
}

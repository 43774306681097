export const timezones: {
  name: string;
  tz: string;
  gmt: string;
}[] = [
  {
    name: 'International Date Line West',
    tz: 'Etc/GMT-12',
    gmt: '-12:00'
  },
  {
    name: 'American Samoa',
    tz: 'Pacific/Pago_Pago',
    gmt: '-11:00'
  },
  {
    name: 'Midway Island',
    tz: 'Pacific/Midway',
    gmt: '-11:00'
  },
  {
    name: 'Hawaii',
    tz: 'US/Hawaii',
    gmt: '-10:00'
  },
  {
    name: 'Alaska',
    tz: 'US/Alaska',
    gmt: '-09:00'
  },
  {
    name: 'Pacific Time (US & Canada)',
    tz: 'US/Pacific',
    gmt: '-08:00'
  },
  {
    name: 'Tijuana',
    tz: 'America/Tijuana',
    gmt: '-08:00'
  },
  {
    name: 'Arizona',
    tz: 'US/Arizona',
    gmt: '-07:00'
  },
  {
    name: 'Mazatlan',
    tz: 'America/Mazatlan',
    gmt: '-07:00'
  },
  {
    name: 'Mountain Time (US & Canada)',
    tz: 'US/Mountain',
    gmt: '-07:00'
  },
  {
    name: 'Central America',
    tz: 'America/Chicago',
    gmt: '-06:00'
  },
  {
    name: 'Central Time (US & Canada)',
    tz: 'US/Central',
    gmt: '-06:00'
  },
  {
    name: 'Chihuahua',
    tz: 'America/Chihuahua',
    gmt: '-06:00'
  },
  {
    name: 'Guadalajara',
    tz: 'America/Mexico_City',
    gmt: '-06:00'
  },
  {
    name: 'Mexico City',
    tz: 'America/Mexico_City',
    gmt: '-06:00'
  },
  {
    name: 'Monterrey',
    tz: 'America/Monterrey',
    gmt: '-06:00'
  },
  {
    name: 'Saskatchewan',
    tz: 'Canada/Saskatchewan',
    gmt: '-06:00'
  },
  {
    name: 'Bogota',
    tz: 'America/Bogota',
    gmt: '-05:00'
  },
  {
    name: 'Eastern Time (US & Canada)',
    tz: 'US/Eastern',
    gmt: '-05:00'
  },
  {
    name: 'Indiana (East)',
    tz: 'US/East-Indiana',
    gmt: '-05:00'
  },
  {
    name: 'Lima',
    tz: 'America/Lima',
    gmt: '-05:00'
  },
  {
    name: 'Quito',
    tz: 'America/Guayaquil',
    gmt: '-05:00'
  },
  {
    name: 'Atlantic Time (Canada)',
    tz: 'Canada/Atlantic',
    gmt: '-04:00'
  },
  {
    name: 'Caracas',
    tz: 'America/Caracas',
    gmt: '-04:00'
  },
  {
    name: 'Georgetown',
    tz: 'America/Guyana',
    gmt: '-04:00'
  },
  {
    name: 'La Paz',
    tz: 'America/La_Paz',
    gmt: '-04:00'
  },
  {
    name: 'Puerto Rico',
    tz: 'America/Puerto_Rico',
    gmt: '-04:00'
  },
  {
    name: 'Santiago',
    tz: 'America/Santiago',
    gmt: '-04:00'
  },
  {
    name: 'Newfoundland',
    tz: 'Canada/Newfoundland',
    gmt: '-03:30'
  },
  {
    name: 'Brasilia',
    tz: 'America/Araguaina',
    gmt: '-03:00'
  },
  {
    name: 'Buenos Aires',
    tz: 'America/Argentina/Buenos_Aires',
    gmt: '-03:00'
  },
  {
    name: 'Greenland',
    tz: 'Greenland',
    gmt: '-03:00'
  },
  {
    name: 'Montevideo',
    tz: 'America/Montevideo',
    gmt: '-03:00'
  },
  {
    name: 'Mid-Atlantic',
    tz: 'America/New_York',
    gmt: '-02:00'
  },
  {
    name: 'Azores',
    tz: 'Atlantic/Azores',
    gmt: '-01:00'
  },
  {
    name: 'Cape Verde Is.',
    tz: 'Atlantic/Cape_Verde',
    gmt: '-01:00'
  },
  {
    name: 'Edinburgh',
    tz: 'Europe/London',
    gmt: '+00:00'
  },
  {
    name: 'Lisbon',
    tz: 'Europe/Lisbon',
    gmt: '+00:00'
  },
  {
    name: 'London',
    tz: 'Europe/London',
    gmt: '+00:00'
  },
  {
    name: 'Monrovia',
    tz: 'Africa/Monrovia',
    gmt: '+00:00'
  },
  {
    name: 'UTC',
    tz: 'Etc/UTC',
    gmt: '+00:00'
  },
  {
    name: 'Amsterdam',
    tz: 'Europe/Amsterdam',
    gmt: '+01:00'
  },
  {
    name: 'Belgrade',
    tz: 'Europe/Belgrade',
    gmt: '+01:00'
  },
  {
    name: 'Berlin',
    tz: 'Europe/Berlin',
    gmt: '+01:00'
  },
  {
    name: 'Bern',
    tz: 'Europe/Zurich',
    gmt: '+01:00'
  },
  {
    name: 'Bratislava',
    tz: 'Europe/Bratislava',
    gmt: '+01:00'
  },
  {
    name: 'Brussels',
    tz: 'Europe/Brussels',
    gmt: '+01:00'
  },
  {
    name: 'Budapest',
    tz: 'Europe/Budapest',
    gmt: '+01:00'
  },
  {
    name: 'Casablanca',
    tz: 'Africa/Casablanca',
    gmt: '+01:00'
  },
  {
    name: 'Copenhagen',
    tz: 'Europe/Copenhagen',
    gmt: '+01:00'
  },
  {
    name: 'Dublin',
    tz: 'Europe/Dublin',
    gmt: '+01:00'
  },
  {
    name: 'Ljubljana',
    tz: 'Europe/Ljubljana',
    gmt: '+01:00'
  },
  {
    name: 'Madrid',
    tz: 'Europe/Madrid',
    gmt: '+01:00'
  },
  {
    name: 'Paris',
    tz: 'Europe/Paris',
    gmt: '+01:00'
  },
  {
    name: 'Prague',
    tz: 'Europe/Prague',
    gmt: '+01:00'
  },
  {
    name: 'Rome',
    tz: 'Europe/Rome',
    gmt: '+01:00'
  },
  {
    name: 'Sarajevo',
    tz: 'Europe/Sarajevo',
    gmt: '+01:00'
  },
  {
    name: 'Skopje',
    tz: 'Europe/Skopje',
    gmt: '+01:00'
  },
  {
    name: 'Stockholm',
    tz: 'Europe/Stockholm',
    gmt: '+01:00'
  },
  {
    name: 'Vienna',
    tz: 'Europe/Vienna',
    gmt: '+01:00'
  },
  {
    name: 'Warsaw',
    tz: 'Europe/Warsaw',
    gmt: '+01:00'
  },
  {
    name: 'West Central Africa',
    tz: 'Africa/Bangui',
    gmt: '+01:00'
  },
  {
    name: 'Zagreb',
    tz: 'Europe/Zagreb',
    gmt: '+01:00'
  },
  {
    name: 'Zurich',
    tz: 'Europe/Zurich',
    gmt: '+01:00'
  },
  {
    name: 'Athens',
    tz: 'Europe/Athens',
    gmt: '+02:00'
  },
  {
    name: 'Bucharest',
    tz: 'Europe/Bucharest',
    gmt: '+02:00'
  },
  {
    name: 'Cairo',
    tz: 'Africa/Cairo',
    gmt: '+02:00'
  },
  {
    name: 'Harare',
    tz: 'Africa/Harare',
    gmt: '+02:00'
  },
  {
    name: 'Helsinki',
    tz: 'Europe/Helsinki',
    gmt: '+02:00'
  },
  {
    name: 'Jerusalem',
    tz: 'Asia/Jerusalem',
    gmt: '+02:00'
  },
  {
    name: 'Kaliningrad',
    tz: 'Europe/Kaliningrad',
    gmt: '+02:00'
  },
  {
    name: 'Kyiv',
    tz: 'Europe/Kyiv',
    gmt: '+02:00'
  },
  {
    name: 'Pretoria',
    tz: 'Africa/Johannesburg',
    gmt: '+02:00'
  },
  {
    name: 'Riga',
    tz: 'Europe/Riga',
    gmt: '+02:00'
  },
  {
    name: 'Sofia',
    tz: 'Europe/Sofia',
    gmt: '+02:00'
  },
  {
    name: 'Tallinn',
    tz: 'Europe/Tallinn',
    gmt: '+02:00'
  },
  {
    name: 'Vilnius',
    tz: 'Europe/Vilnius',
    gmt: '+02:00'
  },
  {
    name: 'Baghdad',
    tz: 'Asia/Baghdad',
    gmt: '+03:00'
  },
  {
    name: 'Istanbul',
    tz: 'Asia/Istanbul',
    gmt: '+03:00'
  },
  {
    name: 'Kuwait',
    tz: 'Asia/Kuwait',
    gmt: '+03:00'
  },
  {
    name: 'Minsk',
    tz: 'Europe/Minsk',
    gmt: '+03:00'
  },
  {
    name: 'Moscow',
    tz: 'Europe/Moscow',
    gmt: '+03:00'
  },
  {
    name: 'Nairobi',
    tz: 'Africa/Nairobi',
    gmt: '+03:00'
  },
  {
    name: 'Riyadh',
    tz: 'Asia/Riyadh',
    gmt: '+03:00'
  },
  {
    name: 'St. Petersburg',
    tz: 'Europe/Moscow',
    gmt: '+03:00'
  },
  {
    name: 'Volgograd',
    tz: 'Europe/Volgograd',
    gmt: '+03:00'
  },
  {
    name: 'Tehran',
    tz: 'Asia/Tehran',
    gmt: '+03:30'
  },
  {
    name: 'Abu Dhabi',
    tz: 'Asia/Dubai',
    gmt: '+04:00'
  },
  {
    name: 'Baku',
    tz: 'Asia/Baku',
    gmt: '+04:00'
  },
  {
    name: 'Muscat',
    tz: 'Asia/Muscat',
    gmt: '+04:00'
  },
  {
    name: 'Samara',
    tz: 'Europe/Samara',
    gmt: '+04:00'
  },
  {
    name: 'Tbilisi',
    tz: 'Asia/Tbilisi',
    gmt: '+04:00'
  },
  {
    name: 'Yerevan',
    tz: 'Asia/Yerevan',
    gmt: '+04:00'
  },
  {
    name: 'Kabul',
    tz: 'Asia/Kabul',
    gmt: '+04:30'
  },
  {
    name: 'Ekaterinburg',
    tz: 'Asia/Yekaterinburg',
    gmt: '+05:00'
  },
  {
    name: 'Islamabad',
    tz: 'Asia/Karachi',
    gmt: '+05:00'
  },
  {
    name: 'Karachi',
    tz: 'Asia/Karachi',
    gmt: '+05:00'
  },
  {
    name: 'Tashkent',
    tz: 'Asia/Tashkent',
    gmt: '+05:00'
  },
  {
    name: 'Chennai',
    tz: 'Asia/Kolkata',
    gmt: '+05:30'
  },
  {
    name: 'Kolkata',
    tz: 'Asia/Kolkata',
    gmt: '+05:30'
  },
  {
    name: 'Mumbai',
    tz: 'Asia/Kolkata',
    gmt: '+05:30'
  },
  {
    name: 'New Delhi',
    tz: 'Asia/Kolkata',
    gmt: '+05:30'
  },
  {
    name: 'Sri Jayawardenepura',
    tz: 'Asia/Colombo',
    gmt: '+05:30'
  },
  {
    name: 'Kathmandu',
    tz: 'Asia/Kathmandu',
    gmt: '+05:45'
  },
  {
    name: 'Almaty',
    tz: 'Asia/Almaty',
    gmt: '+06:00'
  },
  {
    name: 'Astana',
    tz: 'Asia/Almaty',
    gmt: '+06:00'
  },
  {
    name: 'Dhaka',
    tz: 'Asia/Dhaka',
    gmt: '+06:00'
  },
  {
    name: 'Urumqi',
    tz: 'Asia/Urumqi',
    gmt: '+06:00'
  },
  {
    name: 'Rangoon',
    tz: 'Asia/Rangoon',
    gmt: '+06:30'
  },
  {
    name: 'Bangkok',
    tz: 'Asia/Bangkok',
    gmt: '+07:00'
  },
  {
    name: 'Hanoi',
    tz: 'Asia/Bangkok',
    gmt: '+07:00'
  },
  {
    name: 'Jakarta',
    tz: 'Asia/Jakarta',
    gmt: '+07:00'
  },
  {
    name: 'Krasnoyarsk',
    tz: 'Asia/Krasnoyarsk',
    gmt: '+07:00'
  },
  {
    name: 'Novosibirsk',
    tz: 'Asia/Novosibirsk',
    gmt: '+07:00'
  },
  {
    name: 'Beijing',
    tz: 'Asia/Shanghai',
    gmt: '+08:00'
  },
  {
    name: 'Chongqing',
    tz: 'Asia/Chongqing',
    gmt: '+08:00'
  },
  {
    name: 'Hong Kong',
    tz: 'Asia/Hong_Kong',
    gmt: '+08:00'
  },
  {
    name: 'Irkutsk',
    tz: 'Asia/Irkutsk',
    gmt: '+08:00'
  },
  {
    name: 'Kuala Lumpur',
    tz: 'Asia/Kuala_Lumpur',
    gmt: '+08:00'
  },
  {
    name: 'Perth',
    tz: 'Australia/Perth',
    gmt: '+08:00'
  },
  {
    name: 'Singapore',
    tz: 'Asia/Singapore',
    gmt: '+08:00'
  },
  {
    name: 'Taipei',
    tz: 'Asia/Taipei',
    gmt: '+08:00'
  },
  {
    name: 'Ulaanbaatar',
    tz: 'Asia/Ulaanbaatar',
    gmt: '+08:00'
  },
  {
    name: 'Osaka',
    tz: 'Asia/Tokyo',
    gmt: '+09:00'
  },
  {
    name: 'Sapporo',
    tz: 'Asia/Tokyo',
    gmt: '+09:00'
  },
  {
    name: 'Seoul',
    tz: 'Asia/Seoul',
    gmt: '+09:00'
  },
  {
    name: 'Tokyo',
    tz: 'Asia/Tokyo',
    gmt: '+09:00'
  },
  {
    name: 'Yakutsk',
    tz: 'Asia/Yakutsk',
    gmt: '+09:00'
  },
  {
    name: 'Adelaide',
    tz: 'Australia/Adelaide',
    gmt: '+09:30'
  },
  {
    name: 'Darwin',
    tz: 'Australia/Darwin',
    gmt: '+09:30'
  },
  {
    name: 'Brisbane',
    tz: 'Australia/Brisbane',
    gmt: '+10:00'
  },
  {
    name: 'Canberra',
    tz: 'Australia/Canberra',
    gmt: '+10:00'
  },
  {
    name: 'Guam',
    tz: 'Pacific/Guam',
    gmt: '+10:00'
  },
  {
    name: 'Hobart',
    tz: 'Australia/Hobart',
    gmt: '+10:00'
  },
  {
    name: 'Melbourne',
    tz: 'Australia/Melbourne',
    gmt: '+10:00'
  },
  {
    name: 'Port Moresby',
    tz: 'Pacific/Port_Moresby',
    gmt: '+10:00'
  },
  {
    name: 'Sydney',
    tz: 'Australia/Sydney',
    gmt: '+10:00'
  },
  {
    name: 'Vladivostok',
    tz: 'Asia/Vladivostok',
    gmt: '+10:00'
  },
  {
    name: 'Magadan',
    tz: 'Asia/Magadan',
    gmt: '+11:00'
  },
  {
    name: 'New Caledonia',
    tz: 'Pacific/Noumea',
    gmt: '+11:00'
  },
  {
    name: 'Solomon Is.',
    tz: 'Pacific/Noumea',
    gmt: '+11:00'
  },
  {
    name: 'Srednekolymsk',
    tz: 'Asia/Srednekolymsk',
    gmt: '+11:00'
  },
  {
    name: 'Auckland',
    tz: 'Pacific/Auckland',
    gmt: '+12:00'
  },
  {
    name: 'Fiji',
    tz: 'Pacific/Fiji',
    gmt: '+12:00'
  },
  {
    name: 'Kamchatka',
    tz: 'Asia/Kamchatka',
    gmt: '+12:00'
  },
  {
    name: 'Marshall Is.',
    tz: 'Pacific/Kwajalein',
    gmt: '+12:00'
  },
  {
    name: 'Wellington',
    tz: 'Pacific/Auckland',
    gmt: '+12:00'
  },
  {
    name: 'Chatham Is.',
    tz: 'Pacific/Chatham',
    gmt: '+12:45'
  },
  {
    name: "Nuku'alofa",
    tz: 'Pacific/Tongatapu',
    gmt: '+13:00'
  },
  {
    name: 'Samoa',
    tz: 'Pacific/Apia',
    gmt: '+13:00'
  },
  {
    name: 'Tokelau Is',
    tz: 'Pacific/Fakaofo',
    gmt: '+13:00'
  }
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { ChartType, getWidgetByType, Widget, WidgetService, WidgetType } from '@modules/dashboard';
import { CurrentProjectStore } from '@modules/projects';
import { scrollTo } from '@shared';
var CustomizeBarItemType;
(function (CustomizeBarItemType) {
    CustomizeBarItemType[CustomizeBarItemType["Heading"] = 0] = "Heading";
    CustomizeBarItemType[CustomizeBarItemType["Item"] = 1] = "Item";
    CustomizeBarItemType[CustomizeBarItemType["Button"] = 2] = "Button";
    CustomizeBarItemType[CustomizeBarItemType["Tile"] = 3] = "Tile";
})(CustomizeBarItemType || (CustomizeBarItemType = {}));
var DashboardCustomizeBarComponent = /** @class */ (function () {
    function DashboardCustomizeBarComponent(customizeService, currentProjectStore, widgetService, cd, customizeBarContext) {
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.widgetService = widgetService;
        this.cd = cd;
        this.customizeBarContext = customizeBarContext;
        this.widgetAdded = new EventEmitter();
        this.settingsComponents = [];
        this.itemTypes = CustomizeBarItemType;
        this.items = [
            {
                context: {
                    heading: 'Line Chart',
                    icon: 'line-chart'
                },
                data: {
                    widget_type: WidgetType.Chart,
                    name: 'Line Chart',
                    width: 4,
                    height: 2,
                    params: JSON.stringify({
                        chart_type: ChartType.Line
                    })
                }
            },
            {
                context: {
                    heading: 'Bar Chart',
                    icon: 'bar-chart'
                },
                data: {
                    widget_type: WidgetType.Chart,
                    name: 'Bar Chart',
                    width: 4,
                    height: 2,
                    params: JSON.stringify({
                        chart_type: ChartType.Bar
                    })
                }
            },
            {
                context: {
                    heading: 'Pie Chart',
                    icon: 'pie-chart'
                },
                data: {
                    widget_type: WidgetType.Chart,
                    name: 'Pie Chart',
                    width: 2,
                    height: 2,
                    params: JSON.stringify({
                        chart_type: ChartType.Pie
                    })
                }
            },
            {
                context: {
                    heading: 'Doughnut Chart',
                    icon: 'doughnut-chart'
                },
                data: {
                    widget_type: WidgetType.Chart,
                    name: 'Doughnut Chart',
                    width: 2,
                    height: 2,
                    params: JSON.stringify({
                        chart_type: ChartType.Doughnut
                    })
                }
            },
            {
                context: {
                    heading: 'Counter',
                    icon: 'counter'
                },
                data: {
                    widget_type: WidgetType.Value,
                    name: 'Counter',
                    width: 2,
                    height: 2,
                    params: JSON.stringify({})
                }
            }
        ];
    }
    DashboardCustomizeBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.detectChanges();
            _this.scrollToTop();
        });
    };
    DashboardCustomizeBarComponent.prototype.ngOnDestroy = function () { };
    DashboardCustomizeBarComponent.prototype.scrollToTop = function () {
        scrollTo(this.root.nativeElement, 0, this.root.nativeElement.scrollTop > 0 ? 0.4 : 0);
    };
    DashboardCustomizeBarComponent.prototype.add = function (item) {
        var data = __assign({}, item.data, { x: this.widgetPosition.x, y: this.widgetPosition.y, dashboard: this.dashboard.id });
        var instance;
        var element = getWidgetByType(data['widget_type']);
        if (element) {
            instance = new element().deserialize(data);
        }
        else {
            instance = new Widget().deserialize(data);
        }
        this.widgetAdded.emit(instance);
    };
    return DashboardCustomizeBarComponent;
}());
export { DashboardCustomizeBarComponent };

import { Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupService } from '@common/popups';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentUserStore } from '@modules/users';
import { OnboardingPopupComponent } from '../../components/onboarding-popup/onboarding-popup.component';
var OnboardingService = /** @class */ (function () {
    function OnboardingService(popupService, modelDescriptionStore, currentUserStore, injector) {
        this.popupService = popupService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.currentUserStore = currentUserStore;
        this.injector = injector;
        this._visible = new BehaviorSubject(false);
    }
    OnboardingService.prototype.isShown = function (project) {
        return this.currentUserStore.instance.params.hasProjectOnboardingShowed(project.uniqueName);
    };
    OnboardingService.prototype.setShown = function (project) {
        this.currentUserStore.instance.params.addProjectOnboardingShowed(project.uniqueName);
        this.currentUserStore.update(['params']).subscribe();
    };
    Object.defineProperty(OnboardingService.prototype, "visible", {
        get: function () {
            return this._visible.value;
        },
        set: function (value) {
            this._visible.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OnboardingService.prototype, "visible$", {
        get: function () {
            return this._visible.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    OnboardingService.prototype.showOnboardingPopup = function () {
        var _this = this;
        this.visible = true;
        this.popupService.push({
            component: OnboardingPopupComponent,
            outputs: {
                closed: [function () { return (_this.visible = false); }]
            },
            injector: this.injector
        });
    };
    OnboardingService.prototype.showOnboardingPopupIfNeeded = function (project) {
        return;
        // combineLatest(this.modelDescriptionStore.getFirst(), this.currentUserStore.getFirst()).subscribe(
        //   ([modelDescriptions, currentUser]) => {
        //     if (
        //       !this.isShown(project) &&
        //       !this.visible &&
        //       modelDescriptions &&
        //       modelDescriptions.length &&
        //       currentUser &&
        //       !project.demo &&
        //       project.hasProjectCustomizationPermission()
        //     ) {
        //       this.setShown(project);
        //       this.showOnboardingPopup();
        //     }
        //   }
        // );
    };
    return OnboardingService;
}());
export { OnboardingService };

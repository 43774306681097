var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { throwError } from 'rxjs';
import { catchError, delayWhen, share } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { EmailTemplate, EmailTemplateService } from '@modules/emails';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var EmailTemplateEditForm = /** @class */ (function (_super) {
    __extends(EmailTemplateEditForm, _super);
    function EmailTemplateEditForm(formUtils, currentProjectStore, currentEnvironmentStore, emailTemplateService) {
        var _this = _super.call(this, {
            name: new FormControl('', Validators.required),
            subject: new FormControl('', Validators.required),
            html_content: new FormControl('', Validators.required)
        }) || this;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.emailTemplateService = emailTemplateService;
        return _this;
    }
    EmailTemplateEditForm.prototype.ngOnDestroy = function () { };
    EmailTemplateEditForm.prototype.init = function (options) {
        if (options === void 0) { options = {}; }
        this.instance = options.instance;
        this.baseInstance = options.baseInstance;
        if (options.instance) {
            this.controls.name.patchValue(options.instance.name);
            this.controls.subject.patchValue(options.instance.subject);
            this.controls.html_content.patchValue(options.instance.htmlContent);
        }
        else if (options.baseInstance) {
            this.controls.name.patchValue(options.baseInstance.name);
            this.controls.subject.patchValue(options.baseInstance.subject);
            this.controls.html_content.patchValue(options.baseInstance.htmlContent);
        }
        this.markAsPristine();
    };
    EmailTemplateEditForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var instance = this.instance ? cloneDeep(this.instance) : new EmailTemplate();
        instance.name = this.controls.name.value;
        instance.subject = this.controls.subject.value;
        instance.htmlContent = this.controls.html_content.value;
        var obs;
        if (this.instance) {
            obs = this.emailTemplateService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        else {
            obs = this.emailTemplateService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance);
        }
        return obs.pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }), share());
    };
    return EmailTemplateEditForm;
}(FormGroup));
export { EmailTemplateEditForm };

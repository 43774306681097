var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { gtFieldLookup, lteFieldLookup } from '../base-lookups';
import { FieldLookup } from './base';
var IsFutureFieldLookup = /** @class */ (function (_super) {
    __extends(IsFutureFieldLookup, _super);
    function IsFutureFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_future';
        _this.param = 'isFuture';
        _this.verboseName = localize('is future');
        _this.icon = 'calendar';
        return _this;
    }
    IsFutureFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not in the future', [field]);
            }
            else {
                return localize('is not in the future');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is in the future', [field]);
            }
            else {
                return localize('in the future');
            }
        }
    };
    IsFutureFieldLookup.prototype.compute = function (field, value, exclude) {
        return exclude
            ? [
                {
                    field: field,
                    lookup: lteFieldLookup,
                    value: moment().toISOString()
                }
            ]
            : [
                {
                    field: field,
                    lookup: gtFieldLookup,
                    value: moment().toISOString()
                }
            ];
    };
    return IsFutureFieldLookup;
}(FieldLookup));
export { IsFutureFieldLookup };

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CHART_COLORS, getDatasetsEffectiveColors } from '@modules/charts';
import { ViewContext } from '@modules/customize';
import { ChartType, Widget } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid, controlValue } from '@shared';

import { CustomizeBarContext } from '../../../../services/customize-bar-context/customize-bar.context';
import { ChartWidgetDataSourceEditComponent } from '../../../chart-widget-data-source-edit/chart-widget-data-source-edit.component';
import { DataSourceInputsEditComponent } from '../../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ChartWidgetEditFormDatasetControl } from '../chart-widget-edit-dataset.control';
import { ChartWidgetGroupArray } from '../chart-widget-group.array';
import { ChartWidgetGroupControl } from '../chart-widget-group.control';

@Component({
  selector: 'app-customize-bar-chart-widget-edit-dataset',
  templateUrl: './customize-bar-chart-widget-edit-dataset.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarChartWidgetEditDatasetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() widget: Widget;
  @Input() chartType: ChartType;
  @Input() form: ChartWidgetEditFormDatasetControl;
  @Input() groupsControl: ChartWidgetGroupArray;
  @Input() datasetColumnEnabled = false;
  @Input() datasetColumnAllowed = false;
  @Input() datasetColumnControl: FormControl;
  @Input() defaultTitle: string;
  @Input() context: ViewContext;
  @Input() deleteEnabled = false;
  @Input() defaultColor: string;
  @Input() singleColorDataset = false;
  @Input() labelEnabled = true;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Input() addInput = false;
  @Output() delete = new EventEmitter<void>();

  @ViewChild(ChartWidgetDataSourceEditComponent) dataSourceEditComponent: ChartWidgetDataSourceEditComponent;
  @ViewChild(DataSourceInputsEditComponent) inputsEditComponent: DataSourceInputsEditComponent;

  createField = createFormFieldFactory();
  dataSourceTypes = DataSourceType;
  colors = CHART_COLORS;
  collapseContext = new SidebarCollapseContext();
  groupsColors: string[] = [];

  constructor(
    private customizeBarContext: CustomizeBarContext,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.groupsControl) {
      controlValue<{ color: string }[]>(this.groupsControl)
        .pipe(untilDestroyed(this))
        .subscribe(datasets => {
          const datasetColors = datasets.map(item => item.color);
          this.groupsColors = getDatasetsEffectiveColors(datasetColors, CHART_COLORS);
          this.cd.markForCheck();
        });
    }

    this.form.controls.data_source
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.widget.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.form.controls.data_source.getResource$().pipe(skip(1)),
      this.form.controls.data_source.getModelDescription$().pipe(skip(1))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.widget.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    controlValid(this.form.controls.data_source)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
          ComponentTypeID: this.widget.analyticsName
        });
      });

    controlValid(this.form.controls.data_source.controls.query_resource)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
          ComponentTypeID: this.widget.analyticsName
        });
      });

    controlValid(this.form.controls.data_source.controls.query_inputs)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
          ComponentTypeID: this.widget.analyticsName
        });
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
      ComponentTypeID: this.widget.analyticsName
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.addInput && this.inputsEditComponent) {
        this.inputsEditComponent.openAddInput();
      }
    }, 0);
  }

  get subtitle() {
    return this.form.controls.name.value || 'New';
  }

  back() {
    this.customizeBarContext.popSettingsComponent();
  }

  cancel() {
    this.back();
  }

  submit() {
    this.back();
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.addQueryInput();
    }
  }

  getGroupDefaultColor(index: number): string {
    const datasetColors = this.groupsControl.controls.map(item => item.controls.color.value);
    return getDatasetsEffectiveColors(datasetColors, CHART_COLORS, index)[index];
  }

  dragDropGroup(event: CdkDragDrop<ChartWidgetGroupControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.groupsControl.controls, event.previousIndex, event.currentIndex);
      this.groupsControl.updateValueAndValidity();
    }
  }
}

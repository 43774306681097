import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';

import { BasePopupComponent } from '@common/popups';

import { AnalyticsEvent } from '@modules/analytics';
import { Project, ProjectJob } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, Link } from '@shared';

@Component({
  selector: 'app-project-jobs',
  templateUrl: './project-jobs.component.html'
})
export class ProjectJobsComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() createDemo = true;
  @Output() opened = new EventEmitter<ProjectJobsComponent>();
  @Output() closed = new EventEmitter<void>();

  itemHovered: any;
  analyticsEvents = AnalyticsEvent;

  constructor(
    @Optional() private popupComponent: BasePopupComponent,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.popupComponent) {
      this.popupComponent.closed
        // .pipe(untilDestroyed(this))
        .subscribe(() => this.closed.emit());
    }

    this.opened.emit(this);
  }

  ngOnDestroy(): void {}

  setItemHovered(value) {
    this.itemHovered = value;
    this.cd.markForCheck();
  }

  projectHome() {
    return this.createDemo ? ['/demo', this.project.uniqueName] : this.routing.appLink(this.project.homeLink);
  }

  jobLink(job: ProjectJob): Link {
    const parts = job.url.split('?', 2);
    const url = parts[0].split('/').filter(item => isSet(item));

    return this.createDemo
      ? { link: ['/demo', this.project.uniqueName], queryParams: { link: job.url } }
      : { link: this.routing.appLink(url) };
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

import { Injectable } from '@angular/core';

import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListStore, ListStoreFetchResponse } from '@shared';

// TODO: Refactor import
import { PAGE_PARAM, PER_PAGE_PARAM } from '../../models/data/params';

import { ProjectInvite } from '../data/project-invite';
import { ProjectInviteService } from '../services/project-invite/project-invite.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class ProjectInviteListStore extends ListStore<ProjectInvite> {
  params = {};

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectInviteService: ProjectInviteService
  ) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    params[PER_PAGE_PARAM] = this.perPage;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<ProjectInvite>> {
    const params = this.paramsForPage(page);

    return this.projectInviteService
      .getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        params
      )
      .pipe(
        map(response => {
          const perPage = this.perPage || 20;
          const totalPages = Math.ceil(response.count / perPage);
          return {
            items: response.results,
            hasMore: !!response.next,
            totalPages: totalPages,
            perPage: perPage,
            count: response.count
          };
        })
      );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';

import { BaseUploadComponent } from '../base-upload.component';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoUploadComponent extends BaseUploadComponent {
  previewFile: string;
  previewEmbed: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    storageService: StorageService,
    cd: ChangeDetectorRef
  ) {
    super(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd);
  }

  onUploadedUpdated() {
    if (!this.uploadedFile) {
      return;
    }

    const youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(
      this.uploadedFile.url
    );
    const vimeoMatch = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(
      this.uploadedFile.url
    );
    let previewFile: string;
    let previewEmbed: string;

    if (youtubeMatch) {
      previewEmbed = `https://www.youtube.com/embed/${youtubeMatch[5]}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`;
    } else if (vimeoMatch) {
      previewEmbed = `https://player.vimeo.com/video/${vimeoMatch[4]}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`;
    } else {
      previewFile = this.uploadedFile.url;
    }

    this.previewFile = previewFile;
    this.previewEmbed = previewEmbed ? this.sanitizer.bypassSecurityTrustResourceUrl(previewEmbed) : undefined;
    this.cd.markForCheck();
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, tap } from 'rxjs/operators';
import { UniqueIdToken } from '@common/unique-id';
import { ActionControllerService } from '@modules/action-queries';
import { CustomizeService, ElementType, FROM_OUTPUT, RangeSliderElementItem, registerElementComponent, TO_OUTPUT, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$, createFormFieldFactory, FieldType, LOADING_VALUE, ParameterField } from '@modules/fields';
import { controlValue, isSet, setControlDisabled } from '@shared';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var RangeSliderElementComponent = /** @class */ (function (_super) {
    __extends(RangeSliderElementComponent, _super);
    function RangeSliderElementComponent(customizeService, viewContextElement, actionControllerService, injector, cd, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.actionControllerService = actionControllerService;
        _this.injector = injector;
        _this.cd = cd;
        _this.popup = popup;
        _this.idToken = new UniqueIdToken();
        _this.createField = createFormFieldFactory();
        _this.fromControl = new FormControl(null);
        _this.toControl = new FormControl(null);
        _this.form = new FormGroup({ from: _this.fromControl, to: _this.toControl });
        _this.defaultMinValue = 1;
        _this.defaultMaxValue = 10;
        _this.defaultStepSize = 1;
        _this.minValue = _this.defaultMinValue;
        _this.maxValue = _this.defaultMaxValue;
        _this.stepSize = _this.defaultStepSize;
        return _this;
    }
    RangeSliderElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.updateParams();
        this.initContext();
        this.updateContextOutputs();
        this.updateContextActions();
        this.initContextObserver();
        controlValue(this.fromControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            var error = !!_this.fromControl.errors;
            _this.viewContextElement.setOutputValue(FROM_OUTPUT, value, { error: error });
        });
        controlValue(this.toControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            var error = !!_this.toControl.errors;
            _this.viewContextElement.setOutputValue(TO_OUTPUT, value, { error: error });
        });
        combineLatest(controlValue(this.fromControl, { debounce: 200 }), controlValue(this.toControl, { debounce: 200 }))
            .pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }), skip(1), untilDestroyed(this))
            .subscribe(function (_a) {
            var from = _a[0], to = _a[1];
            _this.element.onChangeActions.forEach(function (action) {
                var _a;
                _this.actionControllerService
                    .execute(action, {
                    context: _this.context,
                    contextElement: _this.viewContextElement,
                    localContext: (_a = {},
                        _a[FROM_OUTPUT] = from,
                        _a[TO_OUTPUT] = to,
                        _a),
                    injector: _this.injector
                })
                    .subscribe();
            });
        });
    };
    RangeSliderElementComponent.prototype.ngOnDestroy = function () { };
    RangeSliderElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element) {
            this.updateParams();
            this.initContextObserver();
        }
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
        }
    };
    RangeSliderElementComponent.prototype.updateParams = function () {
        var validators = this.element.required ? [Validators.required] : [];
        this.fromControl.setValidators(validators);
        this.fromControl.updateValueAndValidity();
        this.toControl.setValidators(validators);
        this.toControl.updateValueAndValidity();
    };
    RangeSliderElementComponent.prototype.initContext = function () {
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'settings',
            allowSkip: true,
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    RangeSliderElementComponent.prototype.updateContextOutputs = function () {
        this.viewContextElement.setOutputs([
            {
                uniqueName: FROM_OUTPUT,
                name: 'From',
                icon: 'align_horizontal_left',
                fieldType: FieldType.Number,
                external: true
            },
            {
                uniqueName: TO_OUTPUT,
                name: 'To',
                icon: 'align_horizontal_right',
                fieldType: FieldType.Number,
                external: true
            }
        ]);
    };
    RangeSliderElementComponent.prototype.updateContextActions = function () {
        var _this = this;
        var valueParameter = new ParameterField();
        valueParameter.name = 'value';
        valueParameter.field = FieldType.Number;
        this.viewContextElement.setActions([
            {
                uniqueName: 'set_from_value',
                name: 'Set From value',
                icon: 'edit',
                parameters: [valueParameter],
                handler: function (params) {
                    _this.fromControl.setValue(params['value']);
                }
            },
            {
                uniqueName: 'set_to_value',
                name: 'Set To value',
                icon: 'edit',
                parameters: [valueParameter],
                handler: function (params) {
                    _this.fromControl.setValue(params['value']);
                }
            }
        ]);
    };
    RangeSliderElementComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        var from$ = this.element.from
            ? applyParamInput$(this.element.from, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var to$ = this.element.to
            ? applyParamInput$(this.element.to, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(false);
        var minValue$ = this.element.minValueInput
            ? applyParamInput$(this.element.minValueInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var maxValue$ = this.element.maxValueInput
            ? applyParamInput$(this.element.maxValueInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var stepSize$ = this.element.stepSizeInput
            ? applyParamInput$(this.element.stepSizeInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var disabled$ = this.element.disableInput
            ? applyParamInput$(this.element.disableInput, {
                context: this.context,
                defaultValue: false,
                handleLoading: true
            }).pipe(distinctUntilChanged(), map(function (disabled) {
                if (disabled === LOADING_VALUE) {
                    return true;
                }
                else {
                    return disabled;
                }
            }))
            : of(false);
        this.contextSubscription = combineLatest(from$.pipe(filter(function (value) { return isSet(value) && _this.fromControl.value != value; }), tap(function (value) { return _this.fromControl.patchValue(value); })), to$.pipe(filter(function (value) { return isSet(value) && _this.toControl.value != value; }), tap(function (value) { return _this.toControl.patchValue(value); })), minValue$.pipe(tap(function (value) {
            _this.minValue = isSet(value) ? value : _this.defaultMinValue;
            _this.cd.markForCheck();
        })), maxValue$.pipe(tap(function (value) {
            _this.maxValue = isSet(value) ? value : _this.defaultMaxValue;
            _this.cd.markForCheck();
        })), stepSize$.pipe(tap(function (value) {
            _this.stepSize = isSet(value) ? value : _this.defaultStepSize;
            _this.cd.markForCheck();
        })), disabled$.pipe(tap(function (disabled) {
            setControlDisabled(_this.fromControl, disabled);
            setControlDisabled(_this.toControl, disabled);
        })))
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    return RangeSliderElementComponent;
}(BaseElementComponent));
export { RangeSliderElementComponent };
registerElementComponent({
    type: ElementType.RangeSlider,
    component: RangeSliderElementComponent,
    label: 'Range Slider',
    actions: []
});

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio-preview',
  templateUrl: './audio-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPreviewComponent implements OnInit {
  @Input() preview: string;

  constructor() {}

  ngOnInit() {}
}

export const mailchimpResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'send_email',
    params: {
      type: 'query',
      verbose_name: 'Send email',
      dynamic_status: false,
      action_params: [
        {
          name: 'from_email',
          verbose_name: 'from_email',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'subject',
          verbose_name: 'subject',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'text',
          verbose_name: 'text',
          description: '',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'to_email',
          verbose_name: 'to_email',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'api_key',
          verbose_name: 'api_key',
          description: '',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        }
      ],
      outputs: [],
      description: 'Send an email.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://mandrillapp.com/api/1.0/messages/send',
            auth_params: {},
            body_type: 'JSON',
            body:
              '{\n    "key": params.api_key,\n    "message": {\n        "from_email": params.from_email,\n        "subject": params.subject,\n        "text": params.text,\n        "to": [\n            { \n                "email": params.to_email,\n                "type": "to" \n                \n            }\n        ]\n        \n    }\n    \n}',
            headers: [
              {
                name: 'Content-Type',
                value: 'application/json'
              },
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            response_path: '',
            request_tokens: {}
          }
        }
      },
      virtual: false,
      featured: false
    },
    draft: true,
    deleted: false
  }
];

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsCurrentWeekFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_current_week';
  public param = 'isCurrentWeek';
  public verboseName = localize('is Current Week');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('week').toISOString();
  }

  to(value: string): string {
    return moment().endOf('week').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not current week', [field]);
      } else {
        return localize('is not current week');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is current week', [field]);
      } else {
        return localize('current week');
      }
    }
  }
}

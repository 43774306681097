/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./open-slack-popup.component";
import * as i2 from "../../../admin-mode/providers/route-admin-mode";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../slack-components/services/slack-join-popup-controller/slack-join-popup-controller.service";
import * as i5 from "../../../routing/services/routing/routing.service";
var styles_OpenSlackPopupComponent = [];
var RenderType_OpenSlackPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OpenSlackPopupComponent, data: {} });
export { RenderType_OpenSlackPopupComponent as RenderType_OpenSlackPopupComponent };
export function View_OpenSlackPopupComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_OpenSlackPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-open-slack-popup", [], null, null, null, View_OpenSlackPopupComponent_0, RenderType_OpenSlackPopupComponent)), i0.ɵdid(1, 245760, null, 0, i1.OpenSlackPopupComponent, [i2.ROUTE_ADMIN_MODE, i3.CurrentProjectStore, i4.SlackJoinPopupController, i5.RoutingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpenSlackPopupComponentNgFactory = i0.ɵccf("app-open-slack-popup", i1.OpenSlackPopupComponent, View_OpenSlackPopupComponent_Host_0, {}, {}, []);
export { OpenSlackPopupComponentNgFactory as OpenSlackPopupComponentNgFactory };

<ng-template #stub_template>
  <div *ngFor="let item of iconsRange" class="select-icon__item">
    <div class="select-icon-item">
      <span class="select-icon-item__icon select-icon-item__icon_stub" [class.loading-animation]="true"></span>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!sectionTitle">
  <ng-container *ngTemplateOutlet="stub_template"></ng-container>
</ng-container>

<div *ngIf="sectionTitle" class="select-icon__section">
  <div class="select-icon__section-title">
    <span [class.loading-animation]="true">
      <span class="stub-text">category</span>
    </span>
  </div>

  <div class="select-icon__section-content">
    <ng-container *ngTemplateOutlet="stub_template"></ng-container>
  </div>
</div>

<div class="popover2">
  <div class="popover2__inner">
    <div class="popover2__viewport popover2__viewport_scrollable">
      <div class="popover2__content" [style.min-width.px]="300">
        <!--        <ng-container *ngIf="choosePagination">-->
        <!--          <div class="token-popup__item">-->
        <!--            <a-->
        <!--              href="javascript:void(0)"-->
        <!--              class="token-popup-list-item token-popup-list-item_orange"-->
        <!--              (click)="setChoosePagination(false)"-->
        <!--            >-->
        <!--              <div class="token-popup-list-item__inner token-popup-list-item__line">-->
        <!--                <span class="token-popup-list-item__left">-->
        <!--                  <span class="icon-arrow_backward_2"></span>-->
        <!--                </span>-->
        <!--                <span class="token-popup-list-item__main">Back</span>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->

        <!--          <div class="token-popup__item token-popup__border-item">-->
        <!--            <a-->
        <!--              *ngFor="let item of paginationOptions"-->
        <!--              href="javascript:void(0)"-->
        <!--              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"-->
        <!--              (click)="-->
        <!--                context.form.setPagination(item.value); setChoosePagination(false); updatedTokensFiltered(search)-->
        <!--              "-->
        <!--            >-->
        <!--              <div class="token-popup-list-item__inner">-->
        <!--                <div class="token-popup-list-item__line">-->
        <!--                  <span class="token-popup-list-item__left">-->
        <!--                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>-->
        <!--                  </span>-->
        <!--                  <span class="token-popup-list-item__main">{{ item.name }}</span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </ng-container>-->

        <ng-container *ngIf="specifyAddParameter">
          <div class="token-popup__item">
            <div class="token-popup-list-item">
              <div class="token-popup-list-item__inner token-popup-list-item__line">
                <div class="token-popup-list-item__left">
                  <a
                    href="javascript:void(0)"
                    class="button button_orange button_icon button_extra-small"
                    (click)="setSpecifyAddParameter(false)"
                  >
                    <span class="button__icon icon-arrow_backward_2"></span>
                  </a>
                </div>
                <div class="token-popup-list-item__main">
                  <input
                    type="text"
                    class="input input_fill input_small"
                    style="width: 170px;"
                    placeholder="Specify input name"
                    [appAutofocus]="true"
                    appKeyEvent
                    (enterUp)="addParameterFromInput()"
                    [ngModel]="addParameterName"
                    (ngModelChange)="setAddParameterName($event)"
                  />
                </div>
                <div class="token-popup-list-item__right">
                  <button
                    type="button"
                    class="button button_primary button_orange button_small"
                    [disabled]="!addParameterName"
                    (click)="addParameterFromInput()"
                  >
                    <span class="button_label">Add</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!specifyAddParameter">
          <app-input-token-value
            *ngIf="selectedToken"
            [selectedTokenValue]="selectedTokenValue"
            [selectedToken]="selectedToken"
            [useFileObjects]="context.useFileObjects"
            [autofocus]="selectedToken === createdToken"
            (back)="updatedTokensFiltered(undefined)"
            (submit)="submitTokenValue()"
          ></app-input-token-value>

          <ng-container *ngIf="!selectedToken">
            <ng-container *ngIf="!mainTokensFiltered?.length">
              <div *ngIf="searchSuggestAdd" class="token-popup__item">
                <div class="token-popup-list-item">
                  <div class="token-popup-list-item__inner token-popup-list-item__line">
                    <div class="token-popup-list-item__left">
                      <a
                        href="javascript:void(0)"
                        class="button button_orange button_icon button_extra-small"
                        (click)="updatedTokensFiltered(undefined)"
                      >
                        <span class="button__icon icon-arrow_backward_2"></span>
                      </a>
                    </div>
                    <div class="token-popup-list-item__main">No such parameter "{{ search }}"</div>
                    <div class="token-popup-list-item__right">
                      <button
                        type="button"
                        class="button button_primary button_orange button_small"
                        (click)="addParameter(search)"
                      >
                        <span class="button_label">Add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div *ngFor="let parent of mainTokensFiltered" class="token-popup__item token-popup__border-item">
              <div class="token-popup-section">
                <div class="token-popup-section__title">
                  <div class="token-popup-section__title-main">{{ parent.label }}</div>
                </div>
                <div class="token-popup-section__content">
                  <app-input-tokens-list-item
                    *ngFor="let item of parent.children"
                    [item]="item"
                    [tokenValues]="tokenValues"
                    (clickItem)="insert($event)"
                  ></app-input-tokens-list-item>

                  <a
                    *ngIf="parent.data && parent.data['parameter_tokens']"
                    href="javascript:void(0)"
                    class="token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"
                    (click)="setSpecifyAddParameter(true)"
                  >
                    <div class="token-popup-list-item__inner">
                      <div class="token-popup-list-item__line">
                        <span class="token-popup-list-item__left">
                          <span class="token-popup-list-item__icon" [ngClass]="'icon-' + 'plus'"></span>
                        </span>
                        <span class="token-popup-list-item__main">Add Input</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <ng-container *ngIf="context.paginationTokens">
              <div
                *ngIf="
                  (search && context.form.controls.pagination.value && paginationTokensFiltered?.length) || !search
                "
                class="token-popup__item token-popup__border-item"
              >
                <div *ngIf="context.form.controls.pagination.value" class="token-popup-section">
                  <div class="token-popup-section__title">
                    <span
                      class="token-popup-section__title-icon token-popup-section__title-icon_left"
                      [ngClass]="['icon-' + currentPaginationOption?.icon]"
                    ></span>
                    <div class="token-popup-section__title-main">
                      {{ currentPaginationOption?.name }}
                      <a
                        href="javascript:void(0)"
                        class="token-popup-section__title-link token-popup-section__title-link_orange icon-edit"
                        style="margin-left: 12px;"
                        (click)="
                          setChoosePagination(true);
                          context.form.setPagination(undefined);
                          updatedTokensFiltered(search)
                        "
                        [appTip]="'Change pagination'"
                      ></a>
                    </div>
                    <!--                    <div class="token-popup-section__title-right">-->
                    <!--                      <a href="javascript:void(0)">Learn more</a>-->
                    <!--                    </div>-->
                  </div>
                  <div *ngIf="paginationTokensFiltered" class="token-popup-section__content">
                    <app-input-tokens-list-item
                      *ngFor="let item of paginationTokensFiltered"
                      [item]="item"
                      [tokenValues]="tokenValues"
                      (clickItem)="insert($event)"
                    ></app-input-tokens-list-item>
                  </div>
                </div>

                <div *ngIf="!context.form.controls.pagination.value" class="token-popup-section">
                  <div class="token-popup-section__title">
                    <ng-container *ngIf="choosePagination">Pagination → Choose Type</ng-container>
                    <ng-container *ngIf="!choosePagination">Pagination</ng-container>
                  </div>
                  <div class="token-popup-section__content">
                    <a
                      *ngIf="!choosePagination"
                      href="javascript:void(0)"
                      class="token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"
                      (click)="setChoosePagination(true)"
                    >
                      <div class="token-popup-list-item__inner">
                        <div class="token-popup-list-item__line">
                          <span class="token-popup-list-item__left">
                            <span class="token-popup-list-item__icon" [ngClass]="'icon-' + 'plus'"></span>
                          </span>
                          <span class="token-popup-list-item__main">Enable pagination</span>
                        </div>
                      </div>
                    </a>

                    <ng-container *ngIf="choosePagination">
                      <a
                        *ngFor="let item of paginationOptions"
                        href="javascript:void(0)"
                        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                        (click)="setPagination(item.value)"
                      >
                        <div class="token-popup-list-item__inner">
                          <div class="token-popup-list-item__line">
                            <span class="token-popup-list-item__left token-popup-list-item__left_fixed">
                              <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                            </span>
                            <span class="token-popup-list-item__main">{{ item.name }}</span>
                          </div>
                        </div>
                      </a>

                      <a
                        href="javascript:void(0)"
                        class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                        (click)="setChoosePagination(false)"
                      >
                        <div class="token-popup-list-item__inner">
                          <div class="token-popup-list-item__line">
                            <span class="token-popup-list-item__left token-popup-list-item__left_fixed"></span>
                            <span class="token-popup-list-item__main">
                              No pagination
                            </span>
                          </div>
                        </div>
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="context.sortingTokens">
              <div
                *ngIf="(search && context.form.controls.sorting.value && sortingTokensFiltered?.length) || !search"
                class="token-popup__item token-popup__border-item"
              >
                <div class="token-popup-section">
                  <div class="token-popup-section__title">
                    <div class="token-popup-section__title-main">
                      Sorting
                    </div>
                    <div *ngIf="context.form.controls.sorting.value" class="token-popup-section__title-right">
                      <app-toggle [orange]="true" [selected]="true" (click)="setSorting(false)"> </app-toggle>
                    </div>
                  </div>
                  <app-input-tokens-list-item
                    *ngFor="let item of sortingTokensFiltered"
                    [item]="item"
                    [tokenValues]="tokenValues"
                    (clickItem)="insert($event)"
                  ></app-input-tokens-list-item>
                </div>

                <ng-container *ngIf="!context.form.controls.sorting.value">
                  <a
                    href="javascript:void(0)"
                    class="token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"
                    (click)="setSorting(true)"
                  >
                    <div class="token-popup-list-item__inner">
                      <div class="token-popup-list-item__line">
                        <span class="token-popup-list-item__left">
                          <span class="token-popup-list-item__icon" [ngClass]="'icon-' + 'plus'"></span>
                        </span>
                        <span class="token-popup-list-item__main">Enable sorting</span>
                      </div>
                    </div>
                  </a>
                </ng-container>
              </div>
            </ng-container>

            <div *ngFor="let parent of otherTokensFiltered" class="token-popup__item token-popup__border-item">
              <app-input-tokens-list
                [title]="parent.label"
                [items]="parent.children"
                [tokenValues]="tokenValues"
                (clickItem)="insert($event)"
              ></app-input-tokens-list>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!--    <div class="popover2__arrow"></div>-->
  </div>
</div>

import { BillingPeriod } from './billing-period';
var PlanPrice = /** @class */ (function () {
    function PlanPrice() {
    }
    PlanPrice.prototype.deserialize = function (data) {
        this.monthly = data['monthly'];
        this.monthlyAdditional = data['monthly_additional'];
        this.annual = data['annual'];
        this.annualAdditional = data['annual_additional'];
        return this;
    };
    PlanPrice.prototype.get = function (period) {
        if (period == BillingPeriod.Month) {
            return this.monthly;
        }
        else if (period == BillingPeriod.Year) {
            return this.annual;
        }
    };
    return PlanPrice;
}());
export { PlanPrice };
var PlanSuitable = /** @class */ (function () {
    function PlanSuitable() {
    }
    PlanSuitable.prototype.deserialize = function (data) {
        this.value = data['value'];
        this.reason = data['reason'];
        this.reasonFeature = data['reason_feature'];
        return this;
    };
    return PlanSuitable;
}());
export { PlanSuitable };
var Plan = /** @class */ (function () {
    function Plan() {
        this.params = {};
    }
    // public hidden: boolean;
    Plan.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.subtitle = data['subtitle'];
        this.uniqueName = data['unique_name'];
        this.description = data['description'];
        // this.price = parseFloat(data['price']);
        this.activitiesPerPrice = data['activities_per_price'];
        this.activitiesMax = data['activities_max'];
        this.purchasable = data['purchasable'];
        // this.hidden = data['hidden'];
        if (data['suitable']) {
            this.suitable = new PlanSuitable().deserialize(data['suitable']);
        }
        if (data['price']) {
            this.price = new PlanPrice().deserialize(data['price']);
        }
        if (data['params']) {
            this.params = data['params'];
        }
        return this;
    };
    Object.defineProperty(Plan.prototype, "changePlanLink", {
        get: function () {
            return ['project', 'change_plan', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    Plan.prototype.statusLink = function (status) {
        return ['project', 'change_plan', this.uniqueName, status];
    };
    Plan.prototype.isPaid = function () {
        return !!this.params['paddle_product_ids'];
    };
    return Plan;
}());
export { Plan };

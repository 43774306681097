import { Injectable } from '@angular/core';
import { TransferProgressEvent } from '@azure/core-http';
import { BlobClient, BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { from, merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { ResourceController } from '../../data/resource-controller';

@Injectable()
export class AzureBlobResourceController extends ResourceController {
  getBlobUrl(account: string, container: string, blob: string) {
    return `https://${account}.blob.core.windows.net/${container}/${blob}`;
  }

  getBlobServiceClient(account: string, sas: string) {
    return new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
  }

  getContainerClient(account: string, sas: string, container: string) {
    return new ContainerClient(`https://${account}.blob.core.windows.net/${container}${sas}`);
  }

  getBlobClient(account: string, sas: string, container: string, blob: string) {
    return new BlobClient(`https://${account}.blob.core.windows.net/${container}/${blob}${sas}`);
  }

  listContainers(account: string, sas: string): Observable<string[]> {
    const blobServiceClient = this.getBlobServiceClient(account, sas);

    return from(blobServiceClient.listContainers().byPage().next()).pipe(
      map(result => {
        return result.value.containerItems.map(item => item.name);
      })
    );
  }

  supportedQueryTypes(queryClass: any): QueryType[] {
    return [QueryType.Simple, QueryType.Http];
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    const account = resource.params['account'];
    const sas = resource.params['sas'];
    const container = storage.name;
    const blobFileName = isSet(fileName) ? fileName : String(file.name);
    const blobName = [path, blobFileName].join('');
    const client = this.getContainerClient(account, sas, container);
    const progressObs = new Subject<ModelResponse.UploadFileResponse>();

    const uploadObs = from(
      client.uploadBlockBlob(blobName, file, file.size, {
        onProgress: (progress: TransferProgressEvent) => {
          progressObs.next({
            state: {
              uploadProgress: progress.loadedBytes / file.size,
              downloadProgress: 0,
              uploadLoaded: progress.loadedBytes,
              uploadTotal: file.size
            }
          });
        }
      })
    ).pipe(
      map(() => {
        const url = this.getBlobUrl(account, container, blobName);

        return {
          result: {
            uploadedPath: url,
            uploadedUrl: url
          },
          state: {
            downloadProgress: 1,
            uploadProgress: 1
          }
        };
      })
    );

    return merge(progressObs, uploadObs);
  }
}

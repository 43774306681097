import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFormatFormulaDescription'
})
export class FormatFormulaDescriptionPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return value
      .replace(/<arg[^>]*>/g, '<strong class="ref-argument">')
      .replace(/<\/arg>/g, '</strong>')
      .replace(/<quote[^>]*>/g, '<strong>')
      .replace(/<\/quote>/g, '</strong>');
  }
}

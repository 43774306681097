/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i2 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i3 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i4 from "../../../../fields/components/auto-field/auto-field.component";
import * as i5 from "@angular/common";
import * as i6 from "./audio-field-data-params.form";
import * as i7 from "./audio-field-data-params.component";
var styles_AudioFieldDataParamsComponent = [];
var RenderType_AudioFieldDataParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AudioFieldDataParamsComponent, data: {} });
export { RenderType_AudioFieldDataParamsComponent as RenderType_AudioFieldDataParamsComponent };
function View_AudioFieldDataParamsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i3.View_AutoFieldComponent_0, i3.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i4.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 1), i0.ɵpod(6, { options: 0, classes: 1 }), i0.ɵpod(7, { name: 0, field: 1, params: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Data format"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form; var currVal_2 = _co.createField(_ck(_v, 7, 0, "store_format", "SelectField", _ck(_v, 6, 0, _co.form.outputFormatOptions, _ck(_v, 5, 0, "select_fill")))); var currVal_3 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AudioFieldDataParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 3, 6, "div", [["class", "sidebar-icon-buttons sidebar-icon-buttons_border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "sidebar-icon-button"]], [[2, "sidebar-icon-button_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.form.controls.multiple.setValue(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "sidebar-icon-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["single file"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "sidebar-icon-button"]], [[2, "sidebar-icon-button_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.form.controls.multiple.setValue(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "sidebar-icon-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["multiple files"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AudioFieldDataParamsComponent_1)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.form.controls.multiple.value; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.form.controls.multiple.value; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.form.controls.multiple.value; _ck(_v, 7, 0, currVal_1); }); }
export function View_AudioFieldDataParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-audio-field-data-params", [], null, null, null, View_AudioFieldDataParamsComponent_0, RenderType_AudioFieldDataParamsComponent)), i0.ɵprd(512, null, i6.AudioFieldDataParamsForm, i6.AudioFieldDataParamsForm, []), i0.ɵdid(2, 245760, null, 0, i7.AudioFieldDataParamsComponent, [i6.AudioFieldDataParamsForm], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AudioFieldDataParamsComponentNgFactory = i0.ɵccf("app-audio-field-data-params", i7.AudioFieldDataParamsComponent, View_AudioFieldDataParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { AudioFieldDataParamsComponentNgFactory as AudioFieldDataParamsComponentNgFactory };

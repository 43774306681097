import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';

import { MenuGeneratorService, MenuItem, SectionMenuItem } from '@modules/menu';
import { TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-section-menu-item',
  templateUrl: './section-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: SectionMenuItem;

  children: MenuItem[] = [];

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(private menuGeneratorService: MenuGeneratorService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.updateChildren();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SectionMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.updateChildren();
    }
  }

  updateChildren() {
    this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
    this.cd.markForCheck();
  }
}

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomView, CustomViewSource } from '@modules/custom-views';
import { CustomizeService, ElementType } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { CustomViewTemplateCounterStore, Frame, View } from '@modules/views';
import { CustomViewTemplatesController, ImportFigmaNodeController, ImportSketchFileController, ViewEditorController } from '@modules/views-components';
var ChangeCustomizeBarCustomViewsComponent = /** @class */ (function () {
    function ChangeCustomizeBarCustomViewsComponent(viewEditorController, customViewTemplateCounterStore, customViewTemplatesController, importFigmaNodeController, importSketchFileController, customizeService, cd, analyticsService) {
        this.viewEditorController = viewEditorController;
        this.customViewTemplateCounterStore = customViewTemplateCounterStore;
        this.customViewTemplatesController = customViewTemplatesController;
        this.importFigmaNodeController = importFigmaNodeController;
        this.importSketchFileController = importSketchFileController;
        this.customizeService = customizeService;
        this.cd = cd;
        this.analyticsService = analyticsService;
    }
    ChangeCustomizeBarCustomViewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customViewTemplateCounterStore
            .getApproxFirst$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.templatesApprox = value;
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarCustomViewsComponent.prototype.isDroppable = function (item) {
        return false;
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createdViewItem = function (view, template) {
        var customView = new CustomView();
        customView.source = CustomViewSource.View;
        customView.view = view;
        var inputs = template
            ? toPairs(template.view.testParameters).map(function (_a) {
                var name = _a[0], value = _a[1];
                var result = new FieldInput();
                result.name = name;
                result.valueType = InputValueType.StaticValue;
                result.staticValue = value;
                return result;
            })
            : [];
        return {
            title: view.name,
            image: 'canvas',
            purpleGradient: true,
            action: 'Add Custom',
            type: ElementType.Custom,
            defaultParams: {
                width: view.frame.width + 20 * 2,
                height: view.frame.height + 15 * 2,
                source: CustomViewSource.View,
                custom_view_temporary: customView.serialize(),
                parameters: view.parameters.map(function (item) { return item.serialize(); }),
                inputs: inputs.map(function (item) { return item.serialize(); })
            }
        };
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.importFigmaNode = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaOpened, {
            Source: this.analyticsSource
        });
        this.importFigmaNodeController
            .importNode()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                _this.createView(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportFigmaCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.importSketchFile = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchOpened, {
            Source: this.analyticsSource
        });
        this.importSketchFileController
            .importFile()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.view) {
                _this.createView(result.view);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchApplied, {
                    Source: _this.analyticsSource
                });
            }
            else if (result.cancelled) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.ImportSketchCancelled, {
                    Source: _this.analyticsSource
                });
            }
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createNewView = function () {
        var view = new View();
        view.generateId();
        view.name = 'New View';
        view.frame = new Frame({ width: 300, height: 240 });
        this.createView(view);
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.createView = function (view) {
        var _this = this;
        this.viewEditorController
            .open({
            create: true,
            view: view,
            submitLabel: 'Create component',
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            var item = _this.createdViewItem(result.view);
            _this.customizeService.createElement(item);
        });
    };
    ChangeCustomizeBarCustomViewsComponent.prototype.openCustomViewTemplates = function () {
        var _this = this;
        this.customViewTemplatesController
            .chooseTemplate({
            viewCreateEnabled: true,
            analyticsSource: this.analyticsSource
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            var item = _this.createdViewItem(result.view, result.template);
            _this.customizeService.createElement(item);
        });
    };
    return ChangeCustomizeBarCustomViewsComponent;
}());
export { ChangeCustomizeBarCustomViewsComponent };

import * as Sentry from '@sentry/browser';
import { ScopeContext } from '@sentry/types';

import { environment } from '@env/environment';

// TODO: Refactor imports
import { isSet } from '../../../shared/utils/common/common';

export const SENTRY_APP_ERROR = 'app_error';

export function captureException(exception: any, captureContext?: Partial<ScopeContext>): string {
  if (!environment.ravenDSN) {
    return;
  }

  const extra = {};

  try {
    const smartlook = window['smartlook'];
    if (smartlook && isSet(smartlook['playUrl'])) {
      extra['smartlook'] = {
        playUrl: smartlook['playUrl'],
        sessionId: smartlook['sessionId'],
        visitorId: smartlook['visitorId'],
        recordId: smartlook['recordId']
      };
    }
  } catch (e) {}

  return Sentry.captureException(exception, {
    ...captureContext,
    extra: {
      [SENTRY_APP_ERROR]: true,
      ...extra,
      ...(captureContext && captureContext.extra)
    }
  });
}

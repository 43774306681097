var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { delayWhen } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { SessionStorage } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomizeType } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { Project, ProjectsStore, ProjectType, ResourceTypeItem } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { TemplateService } from '@modules/template';
import { TemplateItem, TemplateItemType } from '@modules/template-components';
import { CurrentUserStore, Employment } from '@modules/users';
import { ProductTypeValue, ProjectCreateProjectForm } from './project-create-project.form';
import { ProjectCreateUserForm } from './project-create-user.form';
export var PROJECT_CREATE_TEMPLATE_ITEM = 'project_create_template_item';
var ProjectCreate3Component = /** @class */ (function () {
    function ProjectCreate3Component(currentUserStore, projectForm, userForm, metaService, activatedRoute, notificationService, routing, customizeService, sessionStorage, templateService, analyticsService, cd, projectsStore) {
        this.currentUserStore = currentUserStore;
        this.projectForm = projectForm;
        this.userForm = userForm;
        this.metaService = metaService;
        this.activatedRoute = activatedRoute;
        this.notificationService = notificationService;
        this.routing = routing;
        this.customizeService = customizeService;
        this.sessionStorage = sessionStorage;
        this.templateService = templateService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.projectsStore = projectsStore;
        this.createLoading = false;
        this.userInfoLoading = false;
        this.showButtonBack = true;
        this.createField = createFormFieldFactory();
        this.projectTypes = ProjectType;
        this.employments = Employment;
        this.userInfoStep = false;
        this.hasProjects = false;
        this.productTypeValues = ProductTypeValue;
    }
    ProjectCreate3Component.prototype.ngOnInit = function () {
        var _this = this;
        this.metaService.set({ title: 'New Project' });
        this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(function (data) {
            _this.domain = data.domain;
            _this.cd.markForCheck();
        });
        this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(function (queryParams) {
            var params = keys(queryParams).length ? queryParams : { create: '1' };
            _this.routing.navigate(['/projects'], { queryParams: params });
        });
        // this.activatedRoute.queryParams
        //   .pipe(
        //     switchMap(params => {
        //       if (params['apply_template']) {
        //         return this.templateService.getDetail(params['apply_template']).pipe(
        //           map(template => {
        //             if (!template) {
        //               return;
        //             }
        //
        //             return {
        //               name: template.name,
        //               description: template.description,
        //               type: TemplateItemType.Template,
        //               template: template
        //             } as TemplateItem;
        //           })
        //         );
        //       } else if (params['apply_template_admin_panel']) {
        //         return of(adminPanelTemplateItem);
        //       } else if (params['apply_template_admin_panel_resource']) {
        //         return of(resourceTypeItems.find(item => item.name == params['apply_template_admin_panel_resource'])).pipe(
        //           map(resource => {
        //             return {
        //               ...adminPanelTemplateItem,
        //               resource: resource
        //             };
        //           })
        //         );
        //       } else {
        //         return EMPTY;
        //       }
        //     }),
        //     filter(params => params != undefined),
        //     untilDestroyed(this)
        //   )
        //   .subscribe(item => {
        //     this.applyTemplateItem = item;
        //     this.cd.markForCheck();
        //   });
        this.hasProjects = this.projectsStore.instance && !!this.projectsStore.instance.length;
        this.cd.markForCheck();
    };
    ProjectCreate3Component.prototype.ngOnDestroy = function () { };
    ProjectCreate3Component.prototype.ngAfterViewInit = function () {
        // this.show();
    };
    ProjectCreate3Component.prototype.show = function () {
        new TimelineMax().fromTo(this.root.nativeElement, 0.6, {
            scale: 0.5,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            ease: Power2.easeOut
        }, 0);
    };
    Object.defineProperty(ProjectCreate3Component.prototype, "isWhiteLabel", {
        get: function () {
            return this.domain && this.domain.whiteLabel;
        },
        enumerable: true,
        configurable: true
    });
    ProjectCreate3Component.prototype.createProject = function () {
        var _this = this;
        if (this.createLoading || this.projectForm.invalid) {
            return;
        }
        this.createLoading = true;
        this.cd.markForCheck();
        this.projectForm
            .create()
            .pipe(delayWhen(function (result) {
            if (_this.applyTemplateItem) {
                var serialized = {
                    type: _this.applyTemplateItem.type,
                    resource: _this.applyTemplateItem.resource,
                    template: _this.applyTemplateItem.template ? _this.applyTemplateItem.template.id : undefined
                };
                _this.sessionStorage.set(PROJECT_CREATE_TEMPLATE_ITEM, JSON.stringify(serialized));
            }
            else {
                _this.sessionStorage.remove(PROJECT_CREATE_TEMPLATE_ITEM);
            }
            if (result.page) {
                _this.finishLink = result.page.link;
            }
            else {
                _this.finishLink = result.project.homeLink;
            }
            return of(result);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.createdProject = result.project;
            _this.createdPage = result.page;
            if (_this.currentUserStore.instance.params.userInfo) {
                _this.createLoading = false;
                _this.userInfoStep = true;
                _this.finishCreateProject();
            }
            else {
                _this.createLoading = false;
                _this.userInfoStep = true;
                _this.cd.markForCheck();
            }
        }, function (error) {
            _this.notificationService.error('Error', "Creating failed: " + error);
            _this.createLoading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectCreate3Component.prototype.sendUserInfo = function () {
        var _this = this;
        this.userInfoLoading = true;
        this.cd.markForCheck();
        this.userForm
            .send()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.finishCreateProject();
        }, function (error) {
            _this.notificationService.error('Error', "Send failed: " + error);
            _this.userInfoLoading = false;
            _this.cd.markForCheck();
        });
    };
    ProjectCreate3Component.prototype.skipUserInfo = function () {
        this.finishCreateProject();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UserInfoEntered, {
            Type: 'Skip'
        });
    };
    ProjectCreate3Component.prototype.finishCreateProject = function () {
        if (!this.customizeService.layoutEnabled) {
            this.customizeService.toggleEnabled(CustomizeType.Layout);
        }
        var onboardingEventParams = __assign({}, (this.applyTemplateItem &&
            this.applyTemplateItem.type == TemplateItemType.AdminPanel &&
            !this.applyTemplateItem.resource && {
            OnboardingTemplateAdminPanel: true
        }), (this.applyTemplateItem &&
            this.applyTemplateItem.type == TemplateItemType.AdminPanel &&
            this.applyTemplateItem.resource && {
            OnboardingTemplateAdminPanelResource: this.applyTemplateItem.resource.name
        }));
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.ProjectCreated, __assign({ ProjectID: this.createdProject.uniqueName, About: this.projectForm.getAbout() }, onboardingEventParams
        // Type: value['type']
        ));
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Subscription.SuccessfulSubscribed, {
            ProjectID: this.createdProject.uniqueName,
            Plan: this.createdProject.subscription ? this.createdProject.subscription.plan.uniqueName : undefined,
            Price: this.createdProject.subscription ? this.createdProject.subscription.price : undefined,
            FreeTrial: this.createdProject.subscription ? !!this.createdProject.subscription.freeTrial : undefined,
            DateBegin: this.createdProject.subscription && this.createdProject.subscription.dateBegin
                ? this.createdProject.subscription.dateBegin.toISOString()
                : undefined,
            DateEnd: this.createdProject.subscription && this.createdProject.subscription.dateEnd
                ? this.createdProject.subscription.dateEnd.toISOString()
                : undefined
        });
        if (this.finishLink) {
            this.routing.navigateApp(this.finishLink, undefined, {
                projectName: this.createdProject.uniqueName,
                environmentName: this.createdProject.defaultEnvironment
                    ? this.createdProject.defaultEnvironment.uniqueName
                    : 'prod',
                modeName: AdminMode.Builder
            });
        }
    };
    return ProjectCreate3Component;
}());
export { ProjectCreate3Component };

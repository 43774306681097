import pickBy from 'lodash/pickBy';

import { View } from '@modules/views';
import { isSet } from '@shared';

export enum CustomViewType {
  Common = 'common',
  ModelDescription = 'model_desc',
  Model = 'model',
  Component = 'component',
  ListItem = 'list_item',
  ItemColumn = 'item_column',
  MenuItem = 'menu_item'
}

export enum CustomViewSource {
  View = 'view',
  HTML = 'html',
  CustomElement = 'custom_element'
}

export enum CustomViewFileType {
  JS,
  CSS,
  Unknown
}

export interface CustomViewFile {
  name: string;
  disabled: boolean;
  fileType: CustomViewFileType;
  checked: boolean;
}

export const defaultCustomViewHtml = `<label for="file">
    Downloading progress:
</label>
<br>
<progress id="file" value="32" max="100">
    32%
</progress>`;

export class CustomView {
  public uniqueName: string;
  public name: string;
  public viewType: CustomViewType;
  public source: CustomViewSource = CustomViewSource.View;
  public dist: File | string;
  public distBaseAbsoluteUrl: string;
  public html: string;
  public view: View;
  public params = {};
  public dateAdd: string;
  public tagName: string;
  public filesJs: CustomViewFile[] = [];
  public filesCss: CustomViewFile[] = [];
  public pageUid: string;
  public elementUid: string;
  public columnUniqueName: string;
  public menuItemUid: string;
  public draft = false;
  public deleted = false;

  deserialize(data: Object): CustomView {
    this.uniqueName = data['unique_name'];
    this.name = data['name'];
    this.viewType = data['view_type'];
    this.source = data['source'];
    this.dist = data['dist'];
    this.distBaseAbsoluteUrl = data['dist_base_absolute_url'];
    this.html = data['html'];
    this.dateAdd = data['date_add'];

    if (data['view']) {
      this.view = new View().deserialize(data['view']);
    }

    if (isSet(data['params'])) {
      this.params = JSON.parse(data['params']);
    }

    if (this.params['tag_name']) {
      this.tagName = this.params['tag_name'];
    }

    if (this.params['files']) {
      if (this.params['files']['js']) {
        this.filesJs = this.params['files']['js'];
      }

      if (this.params['files']['css']) {
        this.filesCss = this.params['files']['css'];
      }
    }

    if (this.params['page_uid']) {
      this.pageUid = this.params['page_uid'];
    }

    if (this.params['element_uid']) {
      this.elementUid = this.params['element_uid'];
    }

    if (this.params['column_unique_name']) {
      this.columnUniqueName = this.params['column_unique_name'];
    }

    if (this.params['menu_item_uid']) {
      this.menuItemUid = this.params['menu_item_uid'];
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['tag_name'] = this.tagName;
    this.params['files'] = {
      js: this.filesJs,
      css: this.filesCss
    };
    this.params['page_uid'] = this.pageUid;
    this.params['element_uid'] = this.elementUid;
    this.params['column_unique_name'] = this.columnUniqueName;
    this.params['menu_item_uid'] = this.menuItemUid;

    let data: Object = {
      unique_name: this.uniqueName,
      name: this.name,
      view_type: this.viewType,
      source: this.source,
      dist: this.dist || '',
      html: this.html || '',
      view: this.view ? this.view.serialize() : null,
      params: JSON.stringify(this.params),
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  commonLink() {
    return ['flex', this.uniqueName];
  }

  modelDescriptionLink(modelId: string) {
    return ['models', modelId, 'flex', this.uniqueName];
  }

  modelLink(modelId: string, id: string) {
    return ['models', modelId, id, 'flex', this.uniqueName];
  }

  get link() {
    return this.viewType == CustomViewType.Common ? ['flex', this.uniqueName] : undefined;
  }

  get changeLink() {
    return ['flexviews_edit', this.uniqueName];
  }

  getViewTypeStr(options: { page?: string; element?: string } = {}): string {
    if (this.viewType == CustomViewType.Common) {
      return `Standalone (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.ModelDescription) {
      return `Collection (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.Model) {
      return `Record (${this.uniqueName})`;
    } else if (this.viewType == CustomViewType.Component) {
      return [
        'Page component',
        ...(options.page ? [options.page] : []),
        ...(options.element ? [options.element] : [])
      ].join(' - ');
    } else if (this.viewType == CustomViewType.ListItem) {
      return ['List card', ...(options.page ? [options.page] : []), ...(options.element ? [options.element] : [])].join(
        ' - '
      );
    }
  }
}

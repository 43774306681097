import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

import { RoutingService } from '@modules/routing';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotUserAuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService, private routing: RoutingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const canActivate = new ReplaySubject<boolean>();

    this.authService
      .isUserAuthorized()
      .pipe(first())
      .subscribe(
        authorized => canActivate.next(!authorized),
        () => canActivate.next(true)
      );

    canActivate.subscribe(allow => {
      if (!allow) {
        this.routing.navigate(['/projects']);
      }
    });

    return canActivate;
  }
}

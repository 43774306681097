import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppDrag } from '@common/drag-drop2';
import { DynamicComponentService } from '@common/dynamic-component';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { getElementByType } from '@modules/customize';
import { CustomizeBarService } from '@modules/customize-bar';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  JET_APP_RESOURCE,
  ResourceName,
  ResourceTypeItem,
  resourceTypeItems
} from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { defaultComponentTemplateName, TemplateService, TemplateType } from '@modules/template';
import { deployUrl, isSet } from '@shared';

import { CustomizeBarItem } from '../../../data/customize-bar-item';
import { TemplateProvider } from '../../../services/template-provider/template.provider';
import { ChangeCustomizeBarItemsBase } from '../change-customize-bar-items-base/change-customize-bar-items-base.component';
import { changeCustomizeBarComponentsAdvanced } from './change-customize-bar-components-advanced';
import { changeCustomizeBarComponentsBasic } from './change-customize-bar-components-basic';
import { changeCustomizeBarComponentsCharts } from './change-customize-bar-components-charts';
import { changeCustomizeBarComponentsForms } from './change-customize-bar-components-forms';
import { changeCustomizeBarComponentsLayouts } from './change-customize-bar-components-layouts';
import { changeCustomizeBarComponentsLists } from './change-customize-bar-components-lists';

@Component({
  selector: 'app-change-customize-bar-components',
  templateUrl: './change-customize-bar-components.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarComponentsComponent extends ChangeCustomizeBarItemsBase implements OnInit, OnDestroy {
  loading = false;
  search = '';
  searchUpdated = new Subject<void>();
  collapseContext = new SidebarCollapseContext();
  layouts: CustomizeBarItem[] = changeCustomizeBarComponentsLayouts;
  layoutsFiltered: CustomizeBarItem[] = [];
  basic: CustomizeBarItem[] = changeCustomizeBarComponentsBasic;
  basicFiltered: CustomizeBarItem[] = [];
  lists: CustomizeBarItem[] = changeCustomizeBarComponentsLists;
  listsFiltered: CustomizeBarItem[] = [];
  charts: CustomizeBarItem[] = changeCustomizeBarComponentsCharts;
  chartsFiltered: CustomizeBarItem[] = [];
  forms: CustomizeBarItem[] = changeCustomizeBarComponentsForms;
  formsFiltered: CustomizeBarItem[] = [];
  advanced: CustomizeBarItem[] = changeCustomizeBarComponentsAdvanced;
  advancedFiltered: CustomizeBarItem[] = [];
  mostUsed: CustomizeBarItem[] = [
    ...['Table'].map(item => this.lists.find(i => i.title == item)),
    ...['Form'].map(item => this.forms.find(i => i.title == item)),
    ...['Button'].map(item => this.basic.find(i => i.title == item)),
    ...['Text Field'].map(item => this.forms.find(i => i.title == item)),
    ...['Text'].map(item => this.basic.find(i => i.title == item)),
    ...['Line'].map(item => this.charts.find(i => i.title == item))
  ];
  mostUsedFiltered: CustomizeBarItem[] = [];
  integrations: CustomizeBarItem[] = [];
  integrationsFiltered: CustomizeBarItem[] = [];
  integrationResources: { typeItem: ResourceTypeItem; installed: boolean }[] = [];
  integrationResourcesFiltered: { typeItem: ResourceTypeItem; installed: boolean }[] = [];
  emptyLabel: string;
  analyticsSource = 'builder_components';

  constructor(
    private cd: ChangeDetectorRef,
    private templateService: TemplateService,
    private templateProvider: TemplateProvider,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceEditController: ResourceEditController,
    private intercomService: IntercomService,
    protected injector: Injector,
    protected dynamicComponentService: DynamicComponentService,
    protected customizeBarService: CustomizeBarService,
    protected analyticsService: UniversalAnalyticsService
  ) {
    super(injector, dynamicComponentService, customizeBarService, analyticsService);
  }

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.templateService
      .get({ type: TemplateType.DefaultComponent })
      .pipe(untilDestroyed(this))
      .subscribe(
        templates => {
          [this.layouts, this.basic, this.lists, this.charts, this.forms, this.advanced, this.mostUsed].forEach(
            items => {
              items.forEach(item => {
                const elementCls = getElementByType(item.type);

                if (!elementCls) {
                  return;
                }

                const element = new elementCls().deserialize({
                  type: item.type,
                  params: item.defaultParams
                });
                const template = templates.find(i => i.uniqueName == defaultComponentTemplateName(element));

                if (!template) {
                  return;
                }

                item.alignHorizontal = template.element.alignHorizontal;
                item.alignVertical = template.element.alignVertical;
                item.defaultParams = template.element.serialize()['params'];
              });
            }
          );
          this.loading = false;
          this.cd.markForCheck();
          this.updateFiltered();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.updateIntegrations();
    this.searchUpdated.pipe(untilDestroyed(this)).subscribe(() => this.updateFiltered());
  }

  ngOnDestroy(): void {}

  updateIntegrations() {
    const resourcesInstalled = this.currentEnvironmentStore.resources.filter(
      resource => !resource.demo && resource.uniqueName != JET_APP_RESOURCE
    );

    combineLatest(this.templateProvider.getInstalledGroups(resourcesInstalled), this.templateProvider.getOtherGroups())
      .pipe(
        switchMap(([installedGroups, otherGroups]) => {
          return combineLatest(
            [...installedGroups, ...otherGroups].map(group => {
              return this.templateProvider.getResourceItems(group.resource, this.context).pipe(
                map(resourceItems => {
                  return [...this.templateProvider.getTemplatesItems(group), ...resourceItems];
                })
              );
            })
          ).pipe(
            map(result => {
              return result.reduce((acc, item) => {
                acc.push(...item);
                return acc;
              }, []);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.integrations = result;

        const resourcesItems = resourceTypeItems.filter(item => {
          return !item.hidden && !item.protected && (item.modelsEnabled || item.actionsEnabled);
        });
        const resourcesItemsInstalled = resourcesItems.filter(item =>
          resourcesInstalled.some(i => i.typeItem && i.typeItem.name == item.name)
        );

        this.integrationResources = [
          ...resourcesItemsInstalled.map(item => {
            return {
              typeItem: item,
              installed: true
            };
          }),
          ...resourcesItems
            .filter(item => item.name != ResourceName.JetDatabase)
            .filter(item => !resourcesItemsInstalled.find(i => i.name == item.name))
            .map(item => {
              return {
                typeItem: item,
                installed: false
              };
            })
        ];

        this.updateFiltered();
        this.cd.markForCheck();
      });
  }

  onIntegrationClick(typeItem: ResourceTypeItem, installed: boolean) {
    if (!installed) {
      this.addResource(typeItem);
    } else {
      this.selectTemplatesTabResource.emit(typeItem);
    }
  }

  addResource(typeItem: ResourceTypeItem) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
      Source: 'builder_component_templates_banner',
      ResourceID: typeItem.name
    });

    this.resourceEditController
      .createResource(typeItem, {
        resourceNameEditing: true,
        analyticsSource: 'builder_component_templates'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.resource) {
          return;
        }

        this.selectTemplatesTabResource.emit(typeItem);
      });
  }

  getFeaturedIntegrations(): CustomizeBarItem[] {
    const featured = [
      {
        resource: ResourceName.Stripe,
        title: 'Payments'
      },
      {
        resource: ResourceName.HubSpot,
        title: 'Tickets'
      },
      {
        resource: ResourceName.Intercom,
        title: 'Contacts'
      },
      {
        resource: ResourceName.Zendesk,
        title: 'Tickets'
      },
      {
        resource: ResourceName.Mixpanel,
        title: 'User Events'
      },
      {
        resource: ResourceName.Twilio,
        title: 'Send SMS'
      },
      {
        resource: ResourceName.SendGrid,
        title: 'Send email'
      },
      {
        resource: ResourceName.Mailchimp,
        title: 'Send email'
      },
      {
        resource: ResourceName.Slack,
        title: 'Send message'
      }
    ];

    return featured
      .map(featuredItem => {
        return this.integrations.find(item => {
          let resource: string;

          if (item.resource) {
            resource = item.resource.typeItem.name;
          } else if (item.template && item.template.forResources.length) {
            resource = item.template.forResources[0].typeItem.name;
          }

          return featuredItem.resource == resource && featuredItem.title == item.title;
        });
      })
      .filter(item => item != undefined)
      .slice(0, 6);
  }

  filterItems(items: CustomizeBarItem[]): CustomizeBarItem[] {
    return items.filter(item => {
      if (!this.search) {
        return true;
      }

      let resource = '';

      if (item.resource) {
        resource = item.resource.typeItem.label;
      } else if (item.template && item.template.forResources.length) {
        resource = item.template.forResources[0].typeItem.label;
      }

      return (
        item.title.toLowerCase().includes(this.search.toLowerCase()) ||
        resource.toLowerCase().includes(this.search.toLowerCase())
      );
    });
  }

  filterResourceItems(typeItem: ResourceTypeItem, items: CustomizeBarItem[]): CustomizeBarItem[] {
    return items.filter(integration => {
      if (!this.search) {
        return true;
      }

      if (integration.resource) {
        if (integration.resource.typeItem.name != typeItem.name) {
          return false;
        }
      } else if (integration.template && integration.template.forResources.length) {
        if (!integration.template.forResources.some(i => i.typeItem.name == typeItem.name)) {
          return false;
        }
      }

      return integration.title.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  updateFiltered() {
    this.layoutsFiltered = this.filterItems(this.layouts);
    this.basicFiltered = this.filterItems(this.basic);
    this.listsFiltered = this.filterItems(this.lists);
    this.chartsFiltered = this.filterItems(this.charts);
    this.formsFiltered = this.filterItems(this.forms);
    this.advancedFiltered = this.filterItems(this.advanced);
    this.mostUsedFiltered = this.filterItems(this.mostUsed);

    if (this.search) {
      this.integrationsFiltered = this.filterItems(this.integrations).slice(0, 15);
    } else {
      this.integrationsFiltered = this.getFeaturedIntegrations();
    }

    if (this.search) {
      this.integrationResourcesFiltered = this.integrationResources
        .filter(item => {
          return (
            item.typeItem.label.toLowerCase().includes(this.search.toLowerCase()) ||
            this.filterResourceItems(item.typeItem, this.integrations).length
          );
        })
        .slice(0, 10);
    } else {
      this.integrationResourcesFiltered = this.integrationResources.slice(0, 10);
    }

    if (
      [
        this.layoutsFiltered,
        this.basicFiltered,
        this.listsFiltered,
        this.chartsFiltered,
        this.formsFiltered,
        this.advancedFiltered,
        this.integrationsFiltered
      ].every(item => !item.length)
    ) {
      this.emptyLabel = this.search.length ? 'Nothing found' : 'Nothing here';
    } else {
      this.emptyLabel = undefined;
    }

    this.cd.markForCheck();
  }

  get isSearching() {
    return isSet(this.search);
  }

  getCollapseContext() {
    return this.isSearching ? undefined : this.collapseContext;
  }

  getItemIcon(item: CustomizeBarItem) {
    if (item.resource) {
      return item.resource.icon;
    } else if (item.template && item.template.forResources.length && item.template.forResources[0].typeItem.icon) {
      return deployUrl(`/assets/images/resources/icons/${item.template.forResources[0].typeItem.icon}.svg`);
    }
  }

  isDroppable(item: AppDrag<CustomizeBarItem>): boolean {
    return false;
  }

  clearSearch() {
    this.search = '';
    this.searchUpdated.next();
    this.cd.markForCheck();
  }

  openChat() {
    this.intercomService.openChat();
  }
}

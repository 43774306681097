/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/drag-drop2/directives/drag/drag.token";
import * as i2 from "../../../../common/drag-drop2/directives/drag/drag.directive";
import * as i3 from "../menu-block-edit/menu-block-edit.component.ngfactory";
import * as i4 from "../menu-block-edit/menu-block-edit.component";
import * as i5 from "../../../users/stores/current-user.store";
import * as i6 from "../../../menu-components/data/menu-context";
import * as i7 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i8 from "../menu-block-add/menu-block-add.component.ngfactory";
import * as i9 from "../menu-block-add/menu-block-add.component";
import * as i10 from "../../../sidebar/components/sidebar-header/sidebar-header.component.ngfactory";
import * as i11 from "../../../sidebar/components/sidebar-header/sidebar-header.component";
import * as i12 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i13 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i14 from "../../../../common/drag-drop2/directives/drop-list/drop-list.directive";
import * as i15 from "../../../../common/drag-drop2/directives/drop-list-group/drop-list-group.directive";
import * as i16 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
import * as i17 from "../../../../common/drag-drop2/services/scroll/scroll.service";
import * as i18 from "@angular/common";
import * as i19 from "./customize-bar-menu-edit.component";
import * as i20 from "../../../projects/stores/current-project.store";
import * as i21 from "@angular/router";
import * as i22 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i23 from "../../../routing/services/routing/routing.service";
var styles_CustomizeBarMenuEditComponent = [];
var RenderType_CustomizeBarMenuEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarMenuEditComponent, data: {} });
export { RenderType_CustomizeBarMenuEditComponent as RenderType_CustomizeBarMenuEditComponent };
function View_CustomizeBarMenuEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 6, "div", [["appDrag", ""], ["class", "sidebar__element"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.blockControlHover$.next(_v.context.$implicit.control) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.blockControlHover$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.APP_DRAG, null, [i2.AppDrag]), i0.ɵdid(2, 212992, [[1, 4]], 2, i2.AppDrag, [i0.ElementRef, i0.ViewContainerRef], { data: [0, "data"] }, null), i0.ɵqud(335544320, 2, { placeholderTemplate: 0 }), i0.ɵqud(335544320, 3, { previewTemplate: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-menu-block-edit", [], null, [[null, "edit"], [null, "remove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("edit" === en)) {
        var pd_0 = (_co.navigateMenuBlockItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("remove" === en)) {
        var pd_1 = (_co.menuForm.controls.blocks.removeControl(_v.context.$implicit.control) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MenuBlockEditComponent_0, i3.RenderType_MenuBlockEditComponent)), i0.ɵdid(6, 245760, null, 0, i4.MenuBlockEditComponent, [i5.CurrentUserStore, i6.MenuContext, i7.DialogService, i0.ChangeDetectorRef], { title: [0, "title"], control: [1, "control"], layoutGroups: [2, "layoutGroups"], active: [3, "active"], disabled: [4, "disabled"] }, { edit: "edit", remove: "remove" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; var currVal_2 = _v.context.$implicit.control; var currVal_3 = _co.layoutGroups; var currVal_4 = (_co.blockControlPreviewHover$.value === _v.context.$implicit.control); var currVal_5 = (_co.blockControlPreviewHover$.value && (_co.blockControlPreviewHover$.value !== _v.context.$implicit.control)); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CustomizeBarMenuEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-menu-block-add", [], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.addMenuBlock($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MenuBlockAddComponent_0, i8.RenderType_MenuBlockAddComponent)), i0.ɵdid(2, 245760, null, 0, i9.MenuBlockAddComponent, [i0.ChangeDetectorRef], { layoutGroups: [0, "layoutGroups"] }, { add: "add" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layoutGroups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomizeBarMenuEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-header", [], null, [[null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("back" === en)) {
        var pd_0 = (_co.backToAppSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_SidebarHeaderComponent_0, i10.RenderType_SidebarHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i11.SidebarHeaderComponent, [], { title: [0, "title"], subtitle: [1, "subtitle"], backEnabled: [2, "backEnabled"] }, { back: "back" }), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "app-sidebar-section", [], null, null, null, i12.View_SidebarSectionComponent_0, i12.RenderType_SidebarSectionComponent)), i0.ɵdid(4, 114688, null, 0, i13.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, 3, 4, "div", [["appDropList", ""]], null, [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 4931584, null, 1, i14.AppDropList, [i0.ElementRef, [2, i15.AppDropListGroup], i0.ChangeDetectorRef, i16.DropListService, i17.ScrollService], { lockAxis: [0, "lockAxis"], data: [1, "data"] }, { dropped: "appDropListDropped" }), i0.ɵqud(603979776, 1, { draggables: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarMenuEditComponent_1)), i0.ɵdid(9, 278528, null, 0, i18.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_CustomizeBarMenuEditComponent_2)), i0.ɵdid(11, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Menu customization"; var currVal_1 = (_co.fromAppSettings ? undefined : "Pages"); var currVal_2 = _co.fromAppSettings; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 4, 0); var currVal_3 = _co.dragAxis.Y; var currVal_4 = _co.menuForm.controls.blocks.controls; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.blockItems; var currVal_6 = _co.trackMenuBlockItemFn; _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = (_co.blockItems.length < _co.menuForm.controls.blocks.controlsFixedLength); _ck(_v, 11, 0, currVal_7); }, null); }
export function View_CustomizeBarMenuEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-menu-edit", [], null, null, null, View_CustomizeBarMenuEditComponent_0, RenderType_CustomizeBarMenuEditComponent)), i0.ɵdid(1, 245760, null, 0, i19.CustomizeBarMenuEditComponent, [i20.CurrentProjectStore, i21.ActivatedRoute, i22.CustomizeBarContext, i23.RoutingService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarMenuEditComponentNgFactory = i0.ɵccf("app-customize-bar-menu-edit", i19.CustomizeBarMenuEditComponent, View_CustomizeBarMenuEditComponent_Host_0, { menuForm: "menuForm", settingsForm: "settingsForm", blockControlPreviewHover$: "blockControlPreviewHover$" }, { blockControlHover: "blockControlHover" }, []);
export { CustomizeBarMenuEditComponentNgFactory as CustomizeBarMenuEditComponentNgFactory };

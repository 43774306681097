import { ParameterField } from '@modules/fields';
import { ModelDbField } from '@modules/models';

import { ApiParameter } from '../data/api-parameter';
import { ApiParameterType } from '../data/api-parameter-type';

export function fieldToApiParameter(item: ParameterField | ModelDbField, type: ApiParameterType): ApiParameter {
  return {
    name: item.name,
    type: type,
    fieldTypeLabel: item.field,
    fieldTypeDescription: {
      field: item.field,
      params: item.params
    },
    required: item.required
  };
}

<div
  class="sidebar-list-item sidebar-list-item_clickable"
  [class.sidebar-list-item_draggable]="false"
  [class.sidebar-list-item_disabled]="false"
  (click)="editProperty()"
>
  <div class="sidebar-list-item__drag"></div>

  <div class="sidebar-list-item__left">
    <span
      *ngIf="property?.fieldDescription"
      class="sidebar-list-item__icon"
      [ngClass]="'icon-' + property.fieldDescription.icon"
    ></span>
  </div>
  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">{{ property?.name || 'no name' }}</div>
    <div *ngIf="property?.fieldDescription" class="sidebar-list-item__description">
      {{ property.fieldDescription.label }}
    </div>
  </div>

  <div class="sidebar-list-item__right">
    <a
      href="javascript:void(0)"
      class="sidebar-list-item__action icon-bin"
      (click)="$event.stopPropagation(); remove.emit()"
    >
    </a>
  </div>
</div>

import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';

import { isSet } from '@shared';

import { detectJSONFieldStructure } from '../utils/detect-json-field-structure';
import { BaseField } from './base-field';
import { FieldType } from './field-type';
import { FormFieldSerialized } from './form-field';
import {
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from './json-structure';

export function deserializeFirestoreValue(value: Object): Object {
  if (isPlainObject(value)) {
    if (value['nullValue']) {
      return null;
    } else if (value['integerValue']) {
      return parseInt(value['integerValue'], 10);
    } else if (value['doubleValue']) {
      return parseFloat(value['doubleValue']);
    } else if (value['booleanValue']) {
      return !!value['booleanValue'];
    } else if (value['stringValue']) {
      return value['stringValue'];
    } else if (value['referenceValue']) {
      return value['referenceValue'];
    } else if (value['timestampValue']) {
      return value['timestampValue'];
    } else if (value['geoPointValue']) {
      return value['geoPointValue'];
    }

    if (value['mapValue']) {
      if (!value['mapValue']['fields']) {
        return {};
      }
      const pairs = toPairs(value['mapValue']['fields']).map(([k, v]) => [k, deserializeFirestoreValue(v)]);
      return fromPairs(pairs);
    } else if (value['arrayValue']) {
      if (!value['arrayValue']['values']) {
        return [];
      }
      return value['arrayValue']['values'].map(item => deserializeFirestoreValue(item));
    }
  } else if (isArray(value)) {
    return value.map(item => deserializeFirestoreValue(item));
  } else {
    return value;
  }
}

export function serializeFirestoreValueNode(node: JsonStructureNode, value?: unknown | unknown[]): unknown | unknown[] {
  if (node.type == JsonStructureNodeType.Object) {
    const params = node.params as JsonStructureObjectParams;
    const objectValue: Object = isPlainObject(value) ? (value as Object) : undefined;
    const pairs = params.items
      .map(item => {
        const itemValue = objectValue ? objectValue[item.name] : undefined;
        return [item.name, serializeFirestoreValueNode(item, itemValue)];
      })
      .filter((k, v) => v !== undefined);

    return {
      mapValue: {
        fields: fromPairs(pairs)
      }
    };
  } else if (node.type == JsonStructureNodeType.Array) {
    const params = node.params as JsonStructureArrayParams;
    const arrayValue: Object[] = isArray(value) ? value : [];
    const items = arrayValue
      .map(item => {
        return serializeFirestoreValueNode(params.item, item);
      })
      .filter(item => item !== undefined);

    return {
      arrayValue: {
        values: items
      }
    };
  } else if (node.type == JsonStructureNodeType.Field) {
    const params = node.params as FormFieldSerialized;

    params.name = node.name;
    params.label = params.label || node.label;

    if (value === undefined) {
      return;
    } else if (value === null) {
      return { nullValue: null };
    } else if (params.field == FieldType.RelatedModel) {
      return { referenceValue: value };
    } else if (params.field == FieldType.Location) {
      return { geoPointValue: value };
    } else if (params.field == FieldType.DateTime) {
      return { timestampValue: value };
    } else if (params.field == FieldType.Number) {
      if (Math.round(value as number) === value) {
        return { integerValue: parseInt(value as string, 10) };
      } else {
        return { doubleValue: parseFloat(value as string) };
      }
    } else if (params.field == FieldType.Boolean) {
      return { booleanValue: !!value };
    } else {
      return { stringValue: value };
    }
  }
}

export function serializeFirestoreValue(value: Object, field?: BaseField) {
  if (!isSet(value)) {
    return value;
  }

  let structure = field && field.params ? (field.params['structure'] as JsonStructureNode) : undefined;

  if (!structure) {
    structure = detectJSONFieldStructure(value) as JsonStructureNode;
  }

  if (!structure) {
    return;
  }

  return serializeFirestoreValueNode(structure, value);
}

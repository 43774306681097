import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { PopupRef } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle } from '@modules/field-components';
import {
  createFormFieldFactory,
  FieldType,
  getFieldComponentsByName,
  getFieldDescriptionByType
} from '@modules/fields';
import {
  CurrentEnvironmentStore,
  getProjectPropertyTypeLabel,
  ProjectProperty,
  ProjectPropertyType
} from '@modules/projects';
import { capitalize, controlValue, Singleton } from '@shared';

import { ProjectPropertyEditPopupForm, ProjectPropertyEditResult } from './project-property-edit-popup.form';

@Component({
  selector: 'app-project-property-edit-popup',
  templateUrl: './project-property-edit-popup.component.html',
  providers: [ProjectPropertyEditPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPropertyEditPopupComponent implements OnInit, OnDestroy {
  @Input() type: ProjectPropertyType;
  @Input() property: ProjectProperty;
  @Input() defaultName: string;
  @Input() defaultValueEnabled = false;
  @Input() requiredEnabled = false;
  @Input() required: boolean;
  @Input() placeholderEnabled = false;
  @Input() placeholder: string;
  @Input() pageUid: string;
  @Input() saveLocal: boolean;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() analyticsSource: string;
  @Output() created = new EventEmitter<ProjectPropertyEditResult>();
  @Output() updated = new EventEmitter<ProjectPropertyEditResult>();

  createField = createFormFieldFactory();
  typeLabel: string;
  viewParamsComponentData: DynamicComponentArguments;
  dataParamsComponentData: DynamicComponentArguments;
  defaultValueParams = {};
  contextNew = new Singleton(() => new ViewContext(this.currentEnvironmentStore));
  submitLoading = false;
  booleanFieldStyle = BooleanFieldStyle;

  constructor(
    @Optional() private popupRef: PopupRef,
    public form: ProjectPropertyEditPopupForm,
    private notificationService: NotificationService,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.typeLabel = this.getTypeLabel();
    this.form.init(this.type, this.property, {
      defaultName: this.defaultName,
      defaultValueEnabled: this.defaultValueEnabled,
      requiredEnabled: this.requiredEnabled,
      required: this.required,
      placeholderEnabled: this.placeholderEnabled,
      placeholder: this.placeholder,
      pageUid: this.pageUid,
      saveLocal: this.saveLocal,
      context: this.context
    });

    controlValue(this.form.controls.field)
      .pipe(untilDestroyed(this))
      .subscribe(field => this.updateParamsComponentData(field));

    controlValue(this.form.controls.params)
      .pipe(untilDestroyed(this))
      .subscribe(params => this.updateDefaultValueParams(params));

    if (this.property) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateStarted, {
        Type: this.property.type,
        Source: this.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.AddStarted, {
        Type: this.type,
        Source: this.analyticsSource
      });
    }
  }

  ngOnDestroy(): void {}

  getTypeLabel(): string {
    const type = this.property ? this.property.type : this.type;
    return getProjectPropertyTypeLabel(type);
  }

  getContextProviders() {
    return [
      {
        provide: ViewContext,
        useFactory: () => {
          if (this.context) {
            return this.context;
          } else {
            return this.contextNew.get();
          }
        },
        deps: []
      }
    ];
  }

  updateParamsComponentData(field: FieldType) {
    const item = getFieldDescriptionByType(field);
    const components = getFieldComponentsByName(item.name);

    if (!components || !components.viewParamsComponent) {
      this.viewParamsComponentData = undefined;
    } else {
      this.viewParamsComponentData = {
        component: components.viewParamsComponent,
        inputs: {
          control: this.form.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement
        },
        providers: this.getContextProviders()
      };
    }

    if (!components || !components.dataParamsComponent) {
      this.dataParamsComponentData = undefined;
    } else {
      this.dataParamsComponentData = {
        component: components.dataParamsComponent,
        inputs: {
          control: this.form.controls.params,
          context: this.context || this.contextNew.get(),
          contextElement: this.contextElement
        },
        providers: this.getContextProviders()
      };
    }

    this.cd.markForCheck();
  }

  updateDefaultValueParams(params: Object) {
    const classes = params && params['classes'] ? params['classes'] : [];

    this.defaultValueParams = {
      ...params,
      classes: ['input_fill', 'select_fill', ...classes]
    };
    this.cd.markForCheck();
  }

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (this.property) {
            this.updated.emit(result);
          } else {
            this.created.emit(result);
          }

          this.close();

          if (this.property) {
            this.notificationService.success(
              'Saved',
              `${capitalize(this.typeLabel)} <strong>${this.property.name}</strong> was successfully updated`
            );
          } else {
            this.notificationService.success('Created', `${capitalize(this.typeLabel)} was successfully created`);
          }

          if (this.property) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Updated, {
              Type: this.property.type,
              Source: this.analyticsSource
            });
          } else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Added, {
              Type: this.type,
              Source: this.analyticsSource
            });
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Saved, {
            Type: this.property ? this.property.type : this.type,
            Source: this.analyticsSource
          });
        },
        error => {
          this.notificationService.error('Error', error);
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  cancel() {
    this.close();

    if (this.property) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.UpdateCancelled, {
        Type: this.property.type,
        Source: this.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.AddCancelled, {
        Type: this.type,
        Source: this.analyticsSource
      });
    }

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Property.Cancelled, {
      Type: this.property ? this.property.type : this.type,
      Source: this.analyticsSource
    });
  }
}

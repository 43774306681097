import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { Resource, ResourceType } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import {
  GetObjectUrlResponse,
  JetBridgeResourceController,
  ModelResponse,
  ResourceControllerService,
  RestApiResourceControllerService
} from '@modules/resources';
import { Storage, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';

@Injectable()
export class StorageService {
  constructor(
    private resourceControllerService: ResourceControllerService,
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  upload(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path = ''
  ): Observable<ModelResponse.UploadFileResponse> {
    if (!resource || !query) {
      return of(undefined);
    }

    let fileName: string;

    if (isSet(path) && !path.endsWith('/')) {
      const pathParts = path.split('/');
      fileName = pathParts[pathParts.length - 1];
      path = pathParts.length > 1 ? pathParts.slice(0, -1).join('/') + '/' : '';
    }

    if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.uploadFile(resource, storage, query, file, path, fileName);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      const controller = this.resourceControllerService.get<JetBridgeResourceController>(resource.type);
      return controller.uploadFile(resource, storage, query, file, path, fileName);
    } else if (query.queryType == QueryType.Simple) {
      const controller = this.resourceControllerService.get(resource.type);
      return controller.uploadFile(resource, storage, query, file, path, fileName);
    }
  }

  getStorageObjects(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path = ''
  ): Observable<StorageObjectsResponse> {
    if (!resource || !query) {
      return of(undefined);
    }

    if (isSet(path) && !path.endsWith('/')) {
      path = path + '/';
    }

    if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.getStorageObjects(resource, storage, query, path);
    } else if (query.queryType == QueryType.Simple) {
      const controller = this.resourceControllerService.get(resource.type);
      return controller.getStorageObjects(resource, storage, query, path);
    }
  }

  deleteStorageObject(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    if (!resource || !query) {
      return of(undefined);
    }

    // if (isSet(path) && !path.endsWith('/')) {
    //   path = path + '/';
    // }

    if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.deleteStorageObject(resource, storage, query, path);
    } else if (query.queryType == QueryType.Simple) {
      const controller = this.resourceControllerService.get(resource.type);
      return controller.deleteStorageObject(resource, storage, query, path);
    }
  }

  createStorageFolder(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    if (!resource || !query) {
      return of(undefined);
    }

    // if (isSet(path) && !path.endsWith('/')) {
    //   path = path + '/';
    // }

    if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.createStorageFolder(resource, storage, query, path);
    } else if (query.queryType == QueryType.Simple) {
      const controller = this.resourceControllerService.get(resource.type);
      return controller.createStorageFolder(resource, storage, query, path);
    }
  }

  getObjectUrl(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string,
    expiresInSec?: number
  ): Observable<GetObjectUrlResponse> {
    if (!resource || !query) {
      return of(undefined);
    }

    // if (isSet(path) && !path.endsWith('/')) {
    //   path = path + '/';
    // }

    if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.getObjectUrl(resource, storage, query, path, expiresInSec);
    } else if (query.queryType == QueryType.Simple) {
      const controller = this.resourceControllerService.get(resource.type);
      return controller.getObjectUrl(resource, storage, query, path, expiresInSec);
    }
  }

  createBulk(
    projectName: string,
    environmentName: string,
    resourceName: string,
    instances: Storage[]
  ): Observable<Storage[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `resources/${resourceName}/storages/`
        );
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new Storage().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, resourceName: string, instance: Storage) {
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resourceName}/storages/`
    //     );
    //     let headers = new HttpHeaders();
    //     const data = instance.serialize();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, data, { headers: headers }).pipe(
    //       map(result => new Storage().deserialize(result)),
    //       this.apiService.catchApiError(false),
    //       publishLast(),
    //       refCount()
    //     );
    //   })
    // );
  }

  update(projectName: string, environmentName: string, resourceName: string, instance: Storage): Observable<Storage> {
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resource}/storages/${uniqueName}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, instance.serialize(), { headers: headers });
    //   }),
    //   map(result => new Storage().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, resourceName: string, instance: Storage): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resource}/storages/${uniqueName}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers, params: { resource: resource } });
    //   }),
    //   map(result => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}

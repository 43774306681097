/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sidebar-list-separator.component";
var styles_SidebarListSeparatorComponent = [];
var RenderType_SidebarListSeparatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarListSeparatorComponent, data: {} });
export { RenderType_SidebarListSeparatorComponent as RenderType_SidebarListSeparatorComponent };
export function View_SidebarListSeparatorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar-list-separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "sidebar-list-separator__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "sidebar-list-separator__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "sidebar-list-separator__line"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_SidebarListSeparatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-list-separator", [], null, null, null, View_SidebarListSeparatorComponent_0, RenderType_SidebarListSeparatorComponent)), i0.ɵdid(1, 114688, null, 0, i1.SidebarListSeparatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarListSeparatorComponentNgFactory = i0.ɵccf("app-sidebar-list-separator", i1.SidebarListSeparatorComponent, View_SidebarListSeparatorComponent_Host_0, { title: "title" }, {}, []);
export { SidebarListSeparatorComponentNgFactory as SidebarListSeparatorComponentNgFactory };

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import {
  detectFirestoreJSONFieldStructure,
  FieldType,
  GeographyOutputFormat,
  JsonOutputFormat,
  ParameterField
} from '@modules/fields';
import { MenuGeneratorService } from '@modules/menu';
import {
  ModelDbField,
  modelDbFieldToParameterField,
  ModelDescription,
  ModelField,
  ModelFieldType
} from '@modules/models';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import {
  HttpContentType,
  HttpMethod,
  HttpQuery,
  HttpQueryService,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryPagination,
  QueryType,
  StorageQuery
} from '@modules/queries';
import { FirebaseDatabaseOption, FirebaseDatabaseType, ResourceParamsResult } from '@modules/resources';
import { Storage } from '@modules/storages';
import { AppError, indentLines, isSet, objectsSortPredicate, trimAll } from '@shared';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

const apiBase = 'https://firestore.googleapis.com/v1/';
const externalPrimaryKey = '__jet_pk__';
const externalItemPrimaryKey = '__jet_item_pk__';
const createdTimePk = '__created_time__';
const updatedTimePk = '__updated_time__';
const parentKey = '__parent__';
const ignoreKeys = [externalPrimaryKey, externalItemPrimaryKey, createdTimePk, updatedTimePk, parentKey];
const responseTransformerMapItem = databasePath => `
function mapItem(doc, params) {
  const model = {
    '${externalPrimaryKey}': doc['name'],
    '${externalItemPrimaryKey}': doc['name'].split('/').slice(-1)[0]
  };
  const types = {
    'nullValue': () => null,
    'integerValue': value => parseInt(value, 10),
    'doubleValue': value => parseFloat(value),
    'booleanValue': value => !!value,
    'stringValue': value => String(value),
    'arrayValue': value => value ? { arrayValue: value } : null,
    'referenceValue': value => value,
    'mapValue': value => value ? { mapValue: value } : null,
    'timestampValue': value => value,
    'geoPointValue': value => value
  };

  if (doc['fields']) {
    const fields = Object.keys(doc['fields']).sort((lhs, rhs) => lhs.localeCompare(rhs));
    for (const key of fields) {
      const typeKey = Object.keys(doc['fields'][key])[0];
      model[key] =  (types[typeKey] || String)(doc['fields'][key][typeKey]);
    }
  }

  const parentPath = doc['name'].split('/').slice(0, -2).join('/');

  model['${createdTimePk}'] = doc['createTime'];
  model['${updatedTimePk}'] = doc['updateTime'];
  model['${parentKey}'] = parentPath != "${databasePath}" ? parentPath : '';

  return model;
}`;

const responseTransformerGetItems = `
function getItems(data, params) {
  const items = data.filter(item => item['document']).map(item => mapItem(item['document'], params));
  const fields = Object.keys(items.reduce((acc, model) => {
      Object.keys(model).forEach(key => acc[key] = true);
      return acc;
    }, {}));

  return {
    fields: fields,
    items: items
  };
}`;

const maxRows = 99999;
const listResponseTransformer = databasePath => `${responseTransformerMapItem(databasePath)}
${responseTransformerGetItems}
var result = getItems(data, params);
return result.items;`;

const listBodyWhere = (databasePath: string, collectionId: string, parent?: string) => {
  if (!isSet(parent)) {
    return 'undefined';
  }

  const collectionIdParts = collectionId.split('/');
  const baseCollectionName = collectionIdParts.length > 1 ? collectionIdParts[0] : undefined;
  return `
{
    "compositeFilter": {
        "op": "AND",
        "filters": [
            {
                "fieldFilter": {
                    "field": {
                        "fieldPath": "__name__"
                    },
                    "op": "GREATER_THAN_OR_EQUAL",
                    "value": {
                        "referenceValue": "${databasePath}/${baseCollectionName}/!"
                    }
                }
            },
            {
                "fieldFilter": {
                    "field": {
                        "fieldPath": "__name__"
                    },
                    "op": "LESS_THAN_OR_EQUAL",
                    "value": {
                        "referenceValue": "${databasePath}/${baseCollectionName}/~~~~~~~~~~~~~~~~~~~~"
                    }
                }
            }
        ]
    }
}`;
};
const listBodyJSON = (databasePath: string, collectionId: string, fields: string[], parent?: string) => {
  const collectionIdParts = collectionId.split('/');
  const collectionName = collectionIdParts[collectionIdParts.length - 1];
  const where = listBodyWhere(databasePath, collectionId, parent);
  return `{
    "structuredQuery": {
        "from": [{
            "collectionId": "${collectionName}",
            "allDescendants": ${isSet(parent) ? 'true' : 'false'}
        }],
        "where": ${trimAll(indentLines(trimAll(where), 8))},
        "limit": ${maxRows}
    }
  }`;
};

const detailResponseTransformer = databasePath => `${responseTransformerGetItems}
${responseTransformerMapItem(databasePath)}
var result = getItems(data, params);
return result.items[0];`;

const itemResponseTransformer = databasePath => `${responseTransformerMapItem(databasePath)}

return mapItem(data, params);`;

const itemBodyTransformer = (databasePath, firebaseTypes) => `
const dictOps = [
  { criteria: value => value === null || value === undefined, serialize: () => {
    return { nullValue: null };
  } },
  { criteria: (value, name) => fieldsMeta[name] && fieldsMeta[name].field == 'related_model', serialize: value => {
    return value ? { referenceValue: value } : { nullValue: null };
  } },
  { criteria: value => typeof value == 'number' && value % 1 == 0, serialize: value => {
    return { integerValue: value };
  } },
  { criteria: value => typeof value == 'number' && value % 1 != 0, serialize: value => {
    return { doubleValue: value };
  } },
  { criteria: value => typeof value == 'boolean', serialize: value => {
    return { booleanValue: value };
  } },
];

const fieldsResult = {};
const ignoreKeys = ['${externalPrimaryKey}', '${externalItemPrimaryKey}', '${createdTimePk}', '${updatedTimePk}', '${parentKey}'];
const firebaseTypes = JSON.parse(${JSON.stringify(JSON.stringify(firebaseTypes))});

for (const key of Object.keys(data)) {
  if (ignoreKeys.includes(key) || data[key] === undefined) {
    continue;
  }

  var value = undefined;

  for (const op of dictOps) {
    if (op.criteria(data[key], key)) {
      value = op.serialize(data[key]);
      break;
    }
  }

  if (value === undefined) {
    const type = firebaseTypes[key] || 'stringValue';

    if (type != 'stringValue' && data[key] === '') {
      continue;
    }

    if (type == 'arrayValue' || type == 'mapValue') {
      value = data[key];
    } else {
      value = { [type]: data[key] };
    }
  }

  fieldsResult[key] = value;
}

return {
  fields: fieldsResult
};`;

const errorTransformer = `// add custom transformation here
if (http.code >= 200 && http.code < 400) {
  // no error if success code
  return null;
} else if (data['error'] && data['error']['message']) {
  // display error message if any
  return data['error']['message'];
} else {
  // display error without message otherwise
  return true;
}`;

export interface FirebaseParamsOptions {
  serviceToken: string;
  projectId: string;
  accessToken: string;
  databaseOption: FirebaseDatabaseOption;
}

interface FieldTypeInfo {
  type: FieldType;
  params?: Object;
}

export function chooseDisplayField(fields: ModelField[]): string {
  const modelFields = fields.filter(field => !ignoreKeys.includes(field.name));
  const priorityFields: FieldType[] = [
    FieldType.Text,
    FieldType.URL,
    FieldType.Number,
    FieldType.DateTime,
    FieldType.MultipleSelect,
    FieldType.Select
  ];
  const priorityField = priorityFields.reduce<ModelField>((acc, item) => {
    if (acc) {
      return acc;
    } else {
      return modelFields.find(field => field.item.field == item);
    }
  }, undefined);

  if (priorityField) {
    return priorityField.name;
  }

  return externalItemPrimaryKey;
}

interface FirebaseDocument {
  name: string;
  fields?: { [k: string]: Object };
  createTime: string;
  updateTime: string;
}

type RunQueryResponse = {
  document?: FirebaseDocument;
  readTime: string;
}[];

@Injectable()
export class FirebaseGeneratorService extends ResourceGeneratorService<FirebaseParamsOptions> {
  tokenName = 'access_token';

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private menuGeneratorService: MenuGeneratorService,
    private secretTokenService: SecretTokenService,
    private apiService: ApiService,
    private injector: Injector,
    private http: HttpClient,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<FirebaseParamsOptions> {
    return this.getServiceToken(project.uniqueName, environment.uniqueName, resource.uniqueName).pipe(
      switchMap(serviceToken => {
        return this.readServiceToken(serviceToken).pipe(
          map(result => [serviceToken, result ? result.projectId : undefined, result ? result.accessToken : undefined])
        );
      }),
      map(([serviceToken, projectId, accessToken]) => {
        return {
          serviceToken: serviceToken,
          projectId: projectId,
          accessToken: accessToken,
          databaseOption: resource.params['database_option'] || { type: FirebaseDatabaseType.Firestore }
        };
      })
    );
  }

  getServiceToken(projectName: string, environmentName: string, resourceName: string) {
    return this.secretTokenService
      .getDetail(projectName, environmentName, resourceName, this.tokenName, this.mode == AdminMode.Builder)
      .pipe(
        map(result => {
          if (result.params['service_token']) {
            return JSON.stringify(result.params['service_token']);
          }

          return '';
        })
      );
  }

  readServiceToken(value: string): Observable<{ projectId: string; accessToken: string }> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        // const value = this.form.value['service_token'].trim();

        try {
          const serviceToken = JSON.parse(value.trim());

          const url = this.apiService.methodURL('resource_auth/');
          let headers = new HttpHeaders();
          const data = {
            name: 'firebase',
            service_token: serviceToken
          };

          headers = this.apiService.setHeadersToken(headers);

          return this.http.post(url, data, { headers: headers }).pipe(
            map(
              result => {
                // this.form.patchValue({
                //   project_id: result['project_id'],
                //   access_token: result['token']
                // });
                return {
                  projectId: result['project_id'],
                  accessToken: result['token']
                };
              }
              // () => {
              //   this.form.patchValue({
              //     project_id: '',
              //     access_token: ''
              //   });
              // }
            )
          );
        } catch (e) {
          // this.form.patchValue({
          //   project_id: '',
          //   access_token: ''
          // });
          return of(undefined);
        }
      })
    );
  }

  createModelDescriptionFields(databasePath: string, documents: FirebaseDocument[], parent?: string): ModelField[] {
    const fields: { [k: string]: FieldTypeInfo } = {};
    const typesMap = {
      booleanValue: { type: FieldType.Boolean },
      integerValue: { type: FieldType.Number },
      doubleValue: { type: FieldType.Number },
      timestampValue: { type: FieldType.DateTime },
      stringValue: { type: FieldType.Text },
      // bytesValue: string,
      referenceValue: {
        type: FieldType.RelatedModel,
        paramsResolver: value => {
          if (!isSet(value)) {
            return;
          }

          const relatedModel = String(value)
            .substring(databasePath.length + 1)
            .split('/')
            .slice(0, -1)
            .join('/');

          return {
            related_model: {
              model: `{{resource}}.${relatedModel}`
            }
          };
        }
      },
      geoPointValue: {
        type: FieldType.Location,
        paramsResolver: () => {
          return {
            output_format: GeographyOutputFormat.Object
          };
        }
      },
      arrayValue: {
        type: FieldType.JSON,
        paramsResolver: (value, objectValues) => {
          value = value ? { arrayValue: value } : null;
          objectValues = objectValues.map(item => (item ? { arrayValue: item } : null));

          const mergedValue = {
            ...value,
            ...objectValues.reduce((acc, item) => {
              acc = {
                ...acc,
                ...item
              };
              return acc;
            }, {})
          };
          const structure = detectFirestoreJSONFieldStructure(mergedValue, {
            resource: '{{resource}}',
            databasePath: databasePath
          });

          if (structure) {
            return { output_format: JsonOutputFormat.Firestore, display_fields: true, structure: structure };
          } else {
            return { output_format: JsonOutputFormat.Firestore };
          }
        },
        paramsResolverPassObjectValues: true
      },
      mapValue: {
        type: FieldType.JSON,
        paramsResolver: (value, objectValues) => {
          value = value ? { mapValue: value } : null;
          objectValues = objectValues.map(item => (item ? { mapValue: item } : null));

          const mergedValue = {
            ...value,
            ...objectValues.reduce((acc, item) => {
              acc = {
                ...acc,
                ...item
              };
              return acc;
            }, {})
          };
          const structure = detectFirestoreJSONFieldStructure(mergedValue, {
            resource: '{{resource}}',
            databasePath: databasePath
          });

          if (structure) {
            return { output_format: JsonOutputFormat.Firestore, display_fields: true, structure: structure };
          } else {
            return { output_format: JsonOutputFormat.Firestore };
          }
        },
        paramsResolverPassObjectValues: true
      }
    };
    const typesDefault: FieldTypeInfo = { type: FieldType.Text };

    documents.forEach(model => {
      if (!model.fields) {
        return;
      }

      toPairs(model.fields).forEach(([name, valueObject]) => {
        const objectKey = keys(valueObject)[0];
        const objectValue = values(valueObject)[0];
        const type = typesMap[objectKey] || typesDefault;

        if (!isSet(objectValue) || fields.hasOwnProperty(name)) {
          return;
        }

        fields[name] = { type: type.type };

        if (type.paramsResolver) {
          let objectValues: Object[];

          if (type.paramsResolverPassObjectValues) {
            objectValues = documents.reduce((acc, item) => {
              const value = item.fields ? item.fields[name] : undefined;
              if (isSet(value) && keys(value)[0] == objectKey) {
                acc.push(values(value)[0]);
              }
              return acc;
            }, []);
          }

          const params = type.paramsResolver(objectValue, objectValues);

          if (params) {
            fields[name].params = params;
          }
        }
      });
    });

    if (documents.length && documents[0].fields) {
      keys(documents[0].fields).forEach(name => {
        if (!fields.hasOwnProperty(name)) {
          fields[name] = { type: typesDefault.type };
        }
      });
    }

    return [
      { name: externalPrimaryKey, typeInfo: { type: FieldType.Text } },
      { name: externalItemPrimaryKey, typeInfo: { type: FieldType.Text } },
      ...toPairs(fields)
        .map(([name, typeInfo]) => ({ name: name, typeInfo: typeInfo }))
        .sort(objectsSortPredicate('name')),
      { name: createdTimePk, typeInfo: { type: FieldType.DateTime } },
      { name: updatedTimePk, typeInfo: { type: FieldType.DateTime } },
      ...(isSet(parent) ? [{ name: parentKey, typeInfo: { type: FieldType.RelatedModel } }] : [])
    ].map(item => {
      const field = new ModelField();
      const dbField = new ModelDbField();

      dbField.name = item.name;

      dbField.field = item.typeInfo.type;
      dbField.editable = true;
      dbField.sortable = true;
      dbField.required = false;
      dbField.filterable = true;
      dbField.updateFieldDescription();

      if (item.typeInfo.params) {
        dbField.params = item.typeInfo.params;
      }

      if (dbField.name == externalPrimaryKey) {
        dbField.verboseName = 'document path';
        dbField.editable = false;
        dbField.sortable = false;
        dbField.required = true;
      } else if (dbField.name == externalItemPrimaryKey) {
        dbField.verboseName = 'document ID';
        dbField.editable = false;
        dbField.sortable = false;
        dbField.required = true;
      } else if (dbField.name == parentKey) {
        dbField.verboseName = 'parent path';
        dbField.editable = false;
        dbField.sortable = false;
        dbField.required = true;
        dbField.params = {
          related_model: {
            model: ['{{resource}}', parent].join('.')
          },
          custom_primary_key: externalPrimaryKey
        };
      } else if (dbField.name == createdTimePk) {
        dbField.verboseName = 'created time';
        dbField.editable = false;
        dbField.sortable = true;
        dbField.required = true;
      } else if (dbField.name == updatedTimePk) {
        dbField.verboseName = 'updated time';
        dbField.editable = false;
        dbField.sortable = true;
        dbField.required = true;
      }

      field.name = item.name;
      field.type = ModelFieldType.Db;
      field.item = dbField;

      return field;
    });
  }

  createModelDescription(
    databasePath: string,
    collectionId: string,
    setUpHeaders: { [header: string]: string | string[] },
    headers: { [header: string]: string | string[] }
  ): Observable<ModelDescription> {
    const collectionIdParts = collectionId.split('/');
    const baseCollectionName = collectionIdParts.length > 1 ? collectionIdParts[0] : undefined;
    const collectionName = collectionIdParts[collectionIdParts.length - 1];
    const collectionFullName = collectionIdParts.join('_');
    const parentFullName = collectionIdParts.length > 1 ? collectionIdParts.slice(0, -1).join('_') : undefined;
    const url = `${apiBase}${databasePath}:runQuery?alt=json`;
    const data = {
      structuredQuery: {
        from: [
          {
            collectionId: collectionName,
            allDescendants: true
          }
        ],
        where: isSet(baseCollectionName)
          ? {
              compositeFilter: {
                op: 'AND',
                filters: [
                  {
                    fieldFilter: {
                      field: {
                        fieldPath: '__name__'
                      },
                      op: 'GREATER_THAN_OR_EQUAL',
                      value: {
                        referenceValue: `${databasePath}/${baseCollectionName}/!`
                      }
                    }
                  },
                  {
                    fieldFilter: {
                      field: {
                        fieldPath: '__name__'
                      },
                      op: 'LESS_THAN_OR_EQUAL',
                      value: {
                        referenceValue: `${databasePath}/${baseCollectionName}/~~~~~~~~~~~~~~~~~~~~`
                      }
                    }
                  }
                ]
              }
            }
          : undefined,
        orderBy: [
          {
            field: {
              fieldPath: '__name__'
            }
          }
        ],
        limit: 100
      }
    };

    return this.http
      .post<RunQueryResponse>(url, data, { headers: setUpHeaders })
      .pipe(
        map(result => {
          const documents = result.map(item => item.document).filter(item => item);

          if (!documents.length) {
            return;
          }

          const firebaseTypes = documents.reduce((prev, document) => {
            if (document.fields) {
              toPairs(document.fields).reduce((prevInner, [fieldName, field]) => {
                const type = keys(field)[0];

                if (type != 'nullValue') {
                  prevInner[fieldName] = type;
                }

                return prevInner;
              }, prev);
            }

            return prev;
          }, {});

          const modelDescription = new ModelDescription();

          modelDescription.primaryKeyField = externalPrimaryKey;
          modelDescription.project = '{{project}}';
          modelDescription.resource = '{{resource}}';
          modelDescription.model = collectionFullName;
          modelDescription.fields = this.createModelDescriptionFields(databasePath, documents, parentFullName);
          modelDescription.hidden = false;
          modelDescription.generateVerboseNameIfNeeded();
          modelDescription.displayField = chooseDisplayField(modelDescription.fields);

          const listUrl = `${apiBase}{{params.hasOwnProperty('${parentKey}') && params['${parentKey}'] ? params['${parentKey}'] + '/${collectionName}' : '${databasePath}/${collectionName}'}}`;
          const detailUrl = `${apiBase}{{params.hasOwnProperty('${parentKey}') && params['${parentKey}'] ? params['${parentKey}'] + '/${collectionName}/' + params['${externalItemPrimaryKey}'] : '${databasePath}/${collectionName}/' + params['${externalItemPrimaryKey}']}}`;
          const queryHeaders = toPairs(headers).map(([k, v]) => {
            return { name: k, value: v as string };
          });

          const getQuery = new ListModelDescriptionQuery();
          const getHttpQuery = new HttpQuery();

          // const rootPath = path.substring(0, path.indexOf('/documents/') + '/documents/'.length - 1);
          const parentParameter: ParameterField = modelDescription.dbFields
            .filter(item => item.name == parentKey)
            .map(item => {
              const parameter = modelDbFieldToParameterField(item);
              parameter.required = true;
              return parameter;
            })[0];
          const parentOptionalParameter: ParameterField = modelDescription.dbFields
            .filter(item => item.name == parentKey)
            .map(item => {
              const parameter = modelDbFieldToParameterField(item);
              parameter.required = false;
              return parameter;
            })[0];
          const documentIdRequiredParameter: ParameterField = modelDescription.dbFields
            .filter(item => item.name == externalItemPrimaryKey)
            .map(item => {
              const parameter = modelDbFieldToParameterField(item);
              parameter.required = true;
              return parameter;
            })[0];
          const documentIdOptionalParameter: ParameterField = modelDescription.dbFields
            .filter(item => item.name == externalItemPrimaryKey)
            .map(item => {
              const parameter = modelDbFieldToParameterField(item);
              parameter.required = false;
              return parameter;
            })[0];

          getHttpQuery.method = HttpMethod.POST;
          getHttpQuery.url = `https://content-firestore.googleapis.com/v1beta1/{{params.hasOwnProperty('${parentKey}') && params['${parentKey}'] ? params['${parentKey}'] : '${databasePath}'}}:runQuery?alt=json`;

          getHttpQuery.headers = queryHeaders;
          getHttpQuery.responseTransformer = listResponseTransformer(databasePath);
          getHttpQuery.bodyType = HttpContentType.JSON;
          getHttpQuery.body = listBodyJSON(
            databasePath,
            collectionId,
            modelDescription.dbFields
              .filter(item => item.filterable)
              .filter(item => item.name != parentKey)
              .map(item => item.name),
            parentFullName
          );
          getHttpQuery.errorTransformer = errorTransformer;
          getQuery.queryType = QueryType.Http;
          getQuery.httpQuery = getHttpQuery;
          getQuery.pagination = QueryPagination.Offset;
          getQuery.frontendFiltering = true;
          getQuery.sorting = true;

          modelDescription.getQuery = getQuery;
          modelDescription.getParameters = [...(isSet(parentFullName) ? [parentOptionalParameter] : [])];

          // modelDescription.getParameters = flatten(
          //   modelDescription.dbFields.map(field => {
          //     const fieldLookups = field.lookups.map(lookup => lookup.type.lookup);
          //
          //     return fieldLookups
          //       .filter(lookup => supportedLookups.includes(lookup))
          //       .map(lookup => {
          //         const parameter = new ParameterField();
          //         const lookupItem = lookups.find(i => i.lookup == lookup);
          //
          //         parameter.name = lookup ? [field.name, lookup].join('__') : field.name;
          //         parameter.verboseName = `${field.verboseName || field.name} ${lookupItem.verboseName}`;
          //         parameter.description = field.description;
          //         parameter.field = field.field;
          //         parameter.required = field.required;
          //         parameter.defaultType = field.defaultType;
          //         parameter.defaultValue = field.defaultValue;
          //         parameter.params = field.params;
          //
          //         return parameter;
          //       });
          //   })
          // );
          // modelDescription.getInputs = flatten(
          //   modelDescription.dbFields
          //     .filter(item => !readOnlyFields.includes(item.name))
          //     .map(field => {
          //       const fieldLookups = field.lookups.map(lookup => lookup.type.lookup);
          //
          //       return fieldLookups
          //         .filter(lookup => supportedLookups.includes(lookup))
          //         .map(lookup => {
          //           const parameter = new Input();
          //
          //           parameter.name = lookup ? [field.name, lookup].join('__') : field.name;
          //           parameter.valueType = InputValueType.Filter;
          //           parameter.filterField = field.name;
          //           parameter.filterLookup = lookup;
          //
          //           return parameter;
          //         });
          //     })
          // );

          // const getDetailQuery = new ModelDescriptionQuery();
          // const getDetailHttpQuery = new HttpQuery();
          //
          // getDetailHttpQuery.method = HttpMethod.POST;
          // getDetailHttpQuery.url = getHttpQuery.url;
          // getDetailHttpQuery.headers = queryHeaders;
          // getDetailHttpQuery.responseTransformer = detailResponseTransformer(databasePath);
          // getDetailHttpQuery.bodyType = getHttpQuery.bodyType;
          // getDetailHttpQuery.body = getHttpQuery.body;
          // getDetailQuery.queryType = QueryType.Http;
          // getDetailQuery.httpQuery = getDetailHttpQuery;
          //
          // modelDescription.getDetailQuery = getDetailQuery;
          // modelDescription.getDetailParameters = [...modelDescription.getParameters];

          const createQuery = new ModelDescriptionQuery();
          const createHttpQuery = new HttpQuery();

          createHttpQuery.method = HttpMethod.POST;
          createHttpQuery.url = listUrl;
          createHttpQuery.headers = queryHeaders;
          createHttpQuery.queryParams = [{ name: 'documentId', value: `{{params.${externalItemPrimaryKey}}}` }];
          createHttpQuery.bodyTransformer = itemBodyTransformer(databasePath, firebaseTypes);
          createHttpQuery.responseTransformer = itemResponseTransformer(databasePath);
          createHttpQuery.errorTransformer = errorTransformer;
          createQuery.queryType = QueryType.Http;
          createQuery.httpQuery = createHttpQuery;

          modelDescription.createQuery = createQuery;
          modelDescription.createParameters = [
            ...(isSet(parentFullName) ? [parentParameter] : []),
            ...modelDescription.dbFields
              .filter(item => ![parentKey, externalPrimaryKey].includes(item.name))
              .filter(item => item.editable)
              .map(item => modelDbFieldToParameterField(item))
              .sort(objectsSortPredicate('-required'))
          ];

          const updateQuery = new ModelDescriptionQuery();
          const updateHttpQuery = new HttpQuery();

          updateHttpQuery.method = HttpMethod.PATCH;
          updateHttpQuery.url = [
            detailUrl,
            `{{(fields || []).filter(f => !onlyFields || onlyFields.includes(f)).filter(f => !['${externalPrimaryKey}', '${externalItemPrimaryKey}', '${createdTimePk}', '${updatedTimePk}', '${parentKey}'].includes(f)).map(f => 'updateMask.fieldPaths=\`' + f + '\`').join('&')}}`
          ].join('?');
          updateHttpQuery.headers = queryHeaders;
          updateHttpQuery.bodyTransformer = itemBodyTransformer(databasePath, firebaseTypes);
          updateHttpQuery.responseTransformer = itemResponseTransformer(databasePath);
          updateHttpQuery.errorTransformer = errorTransformer;
          updateQuery.queryType = QueryType.Http;
          updateQuery.httpQuery = updateHttpQuery;

          modelDescription.updateQuery = updateQuery;
          modelDescription.updateParameters = [
            ...(isSet(parentFullName) ? [parentParameter] : []),
            documentIdRequiredParameter,
            ...modelDescription.dbFields
              .filter(item => ![parentKey, externalPrimaryKey, externalItemPrimaryKey].includes(item.name))
              .filter(item => item.editable)
              .map(item => modelDbFieldToParameterField(item))
              .sort(objectsSortPredicate('-required'))
          ];

          const deleteQuery = new ModelDescriptionQuery();
          const deleteHttpQuery = new HttpQuery();

          deleteHttpQuery.method = HttpMethod.DELETE;
          deleteHttpQuery.url = detailUrl;
          deleteHttpQuery.headers = queryHeaders;
          deleteHttpQuery.errorTransformer = errorTransformer;
          deleteQuery.queryType = QueryType.Http;
          deleteQuery.httpQuery = deleteHttpQuery;

          modelDescription.deleteQuery = deleteQuery;
          modelDescription.deleteParameters = [
            ...(isSet(parentFullName) ? [parentParameter] : []),
            documentIdRequiredParameter
          ];

          return modelDescription;
        })
      );
  }

  encodeCollectionPath(path: string) {
    return path
      .split('/')
      .map(item => encodeURIComponent(item))
      .join('/');
  }

  listCollectionIds(
    databasePath: string,
    collectionPath: string,
    setUpHeaders: { [header: string]: string | string[] },
    collectionIds: string[] = [],
    pageToken?: string
  ): Observable<string[]> {
    const url = `${apiBase}${databasePath}:runQuery?alt=json`;
    const data = {
      structuredQuery: {
        select: {
          fields: [
            {
              fieldPath: '__name__'
            }
          ]
        },
        from: [
          {
            allDescendants: true
          }
        ],
        orderBy: [
          {
            field: {
              fieldPath: '__name__'
            }
          }
        ],
        startAt: pageToken
          ? {
              values: [{ referenceValue: pageToken }],
              before: false
            }
          : null,
        limit: maxRows
      }
    };

    return this.http
      .post<RunQueryResponse>(url, data, { headers: setUpHeaders })
      .pipe(
        switchMap(result => {
          collectionIds = result.reduce((acc, item) => {
            if (item.document) {
              const collectionId = item.document.name
                .replace(databasePath, '')
                .split('/')
                .slice(1)
                .filter((_, i) => i % 2 == 0)
                .join('/');
              if (!acc.includes(collectionId)) {
                acc.push(collectionId);
              }
            }
            return acc;
          }, collectionIds);

          const lastDocument = result.length ? result[result.length - 1].document : undefined;
          if (result.length < maxRows || !lastDocument) {
            return of(collectionIds);
          }

          return this.listCollectionIds(databasePath, collectionPath, setUpHeaders, collectionIds, lastDocument.name);
        })
      );
  }

  createModelDescriptions(
    databasePath: string,
    collectionPath: string,
    setUpHeaders: { [header: string]: string | string[] },
    headers: { [header: string]: string | string[] }
  ): Observable<ModelDescription[]> {
    return this.listCollectionIds(databasePath, collectionPath, setUpHeaders).pipe(
      switchMap(collectionIds => {
        if (!collectionIds || !collectionIds.length) {
          return of([]);
        }

        const obs = collectionIds.map(collectionId => {
          const collectionIdParts = collectionId.split('/');
          const parentId = collectionIdParts.length > 1 ? collectionIdParts.slice(0, -1).join('/') : undefined;

          if (isSet(parentId) && !collectionIds.includes(parentId)) {
            return of(undefined);
          }

          return this.createModelDescription(databasePath, collectionId, setUpHeaders, headers).pipe(
            catchError(e => {
              Sentry.captureException(e);
              return of(undefined);
            })
          );
        });

        return (obs.length ? combineLatest(...obs) : of([])).pipe(
          map((modelDescriptions: ModelDescription[]) => {
            return modelDescriptions.filter(item => item != undefined);
          })
        );
      }),
      catchError(e => {
        if (e instanceof HttpErrorResponse && e.error && e.error.error && e.error.error.message) {
          return throwError(new AppError(e.error.error.message));
        } else {
          return throwError(e);
        }
      }),
      this.apiService.catchApiError<ModelDescription[], ModelDescription[]>()
    );
  }

  createStorages(projectId: string, setUpHeaders: { [header: string]: string | string[] }): Observable<Storage[]> {
    const query = new HttpQuery();

    query.url = `https://storage.googleapis.com/storage/v1/b?project=${projectId}`;
    query.headers = toPairs(setUpHeaders).map(([k, v]) => {
      return { name: k, value: v as string };
    });

    return this.httpQueryService.requestBody<Object>(query).pipe(
      map(response => {
        if (!response['items'] || !response['items'].length) {
          return [];
        }

        return response['items'].map((item, i) => {
          const storage = new Storage();
          const bucketId = item['id'];
          const bucketName = item['name'];

          storage.uniqueName = bucketId;
          storage.name = bucketName;

          storage.uploadQuery = new StorageQuery();
          storage.uploadQuery.queryType = QueryType.Http;
          storage.uploadQuery.httpQuery = new HttpQuery();
          storage.uploadQuery.httpQuery.method = HttpMethod.POST;
          storage.uploadQuery.httpQuery.url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketId}/o?uploadType=multipart`;
          storage.uploadQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          storage.uploadQuery.httpQuery.bodyType = HttpContentType.FormData;
          storage.uploadQuery.httpQuery.body = [
            {
              name: 'metadata',
              // value: `{
              //   "name": "{{path}}{{file_name}}",
              //   "predefinedAcl": "publicRead",
              //   "mimeType": "{{file_mime_type}}",
              //   "metadata": { "firebaseStorageDownloadTokens": "{{jet.uuid}}" }
              // }`,
              value: `{
                  "name": "{{path}}{{file_name}}",
                  "predefinedAcl": "publicRead",
                  "metadata": { "firebaseStorageDownloadTokens": "{{jet.uuid}}" }
                }`,
              contentType: 'application/json'
            },
            {
              name: 'file',
              value: '{{file}}'
            }
          ];
          storage.uploadQuery.httpQuery.responseTransformer = `return 'https://firebasestorage.googleapis.com/v0/b/${bucketId}/o/' + encodeURIComponent(data.name) + '?alt=media&token=' + data.metadata.firebaseStorageDownloadTokens;`;

          storage.removeQuery = new StorageQuery();
          storage.removeQuery.queryType = QueryType.Http;
          storage.removeQuery.httpQuery = new HttpQuery();
          storage.removeQuery.httpQuery.method = HttpMethod.DELETE;
          storage.removeQuery.httpQuery.url = `https://storage.googleapis.com/storage/v1/b/${bucketId}/o/{{encodeURIComponent(path)}}`;
          storage.removeQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          storage.removeQuery.httpQuery.bodyType = HttpContentType.Raw;

          storage.createDirectoryQuery = new StorageQuery();
          storage.createDirectoryQuery.queryType = QueryType.Http;
          storage.createDirectoryQuery.httpQuery = new HttpQuery();
          storage.createDirectoryQuery.httpQuery.method = HttpMethod.POST;
          storage.createDirectoryQuery.httpQuery.url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketId}/o`;
          storage.createDirectoryQuery.httpQuery.headers = [
            { name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }
          ];
          storage.createDirectoryQuery.httpQuery.queryParams = [
            {
              name: 'uploadType',
              value: 'media'
            },
            {
              name: 'name',
              value: '{{path}}/'
            }
          ];

          storage.getQuery = new StorageQuery();
          storage.getQuery.queryType = QueryType.Http;
          storage.getQuery.httpQuery = new HttpQuery();
          storage.getQuery.httpQuery.method = HttpMethod.GET;
          storage.getQuery.httpQuery.url = `https://storage.googleapis.com/storage/v1/b/${bucketId}/o`;
          storage.getQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          // storage.getQuery.httpQuery.bodyType = HttpContentType.JSON;
          storage.getQuery.httpQuery.queryParams = [
            {
              name: 'delimiter',
              value: '/'
            },
            {
              name: 'prefix',
              value: '{{path}}'
            }
          ];
          storage.getQuery.httpQuery.responseTransformer = `
const folders = (data.prefixes || []).map(item => {
    return {
      type: 'folder',
      path: item
    };
});

const files = (data.items || []).filter(item => !item['name'].endsWith('/')).map(item => {
    return {
      type: item['name'].substr(-1) == '/' ? 'folder' : 'file',
      path: item['name'],
      url: item.metadata ? 'https://firebasestorage.googleapis.com/v0/b/${bucketId}/o/' + encodeURIComponent(item.name) + '?alt=media&token=' + item.metadata.firebaseStorageDownloadTokens : undefined,
      size: item.size,
      created: item.timeCreated,
      updated: item.updated
    };
});


return [...folders, ...files];`;

          return storage;
        });
      })
    );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: FirebaseParamsOptions
  ): Observable<ResourceParamsResult> {
    const setUpHeaders = {
      Authorization: `Bearer ${options.accessToken}`
    };
    const databaseParams: Observable<ResourceParamsResult> =
      options.databaseOption.type == FirebaseDatabaseType.Realtime
        ? this.generateRealtimeParams(project, typeItem, options)
        : this.generateFirestoreParams(project, typeItem, options);

    return combineLatest(databaseParams, this.createStorages(options.projectId, setUpHeaders)).pipe(
      map(([params, storages]) => {
        const resourceParams = {
          database_option: options.databaseOption
        };

        const token = new SecretToken();

        token.name = this.tokenName;
        token.type = SecretTokenType.Firebase;
        token.value = '';

        try {
          token.params = {
            service_token: JSON.parse(options.serviceToken)
          };
        } catch (e) {
          token.params = {};
        }

        return {
          ...params,
          resourceParams: resourceParams,
          secretTokens: [token.serialize()],
          storages: storages.map(item => item.serialize())
        };
      })
    );
  }

  generateRealtimeParams(
    project: Project,
    typeItem: ResourceTypeItem,
    options: FirebaseParamsOptions
  ): Observable<ResourceParamsResult> {
    const setUpHeaders = {
      Authorization: `Bearer ${options.accessToken}`
    };

    const databaseUrl = `${options.databaseOption.id}/.json`;

    return this.http.get(databaseUrl, { headers: setUpHeaders }).pipe(
      map(data => {
        if (data === null || isEmpty(data)) {
          throw new AppError('Realtime database does not have any Data');
        }

        return {};
      }),
      catchError(e => {
        if (e instanceof HttpErrorResponse && e.error && e.error.error && e.error.error.message) {
          return throwError(new AppError(e.error.error.message));
        } else {
          return throwError(e);
        }
      }),
      this.apiService.catchApiError<ResourceParamsResult, ResourceParamsResult>()
    );
  }

  generateFirestoreParams(
    project: Project,
    typeItem: ResourceTypeItem,
    options: FirebaseParamsOptions
  ): Observable<ResourceParamsResult> {
    const databasePath = `projects/${options.projectId}/databases/(default)/documents`;
    const setUpHeaders = {
      Authorization: `Bearer ${options.accessToken}`
    };
    const headers = {
      Authorization: `Bearer {-${this.tokenName}-}`
    };

    return this.createModelDescriptions(databasePath, '', setUpHeaders, headers).pipe(
      map(modelDescriptions => {
        if (!modelDescriptions.length) {
          throw new ServerRequestError('Firestore database does not have any Collections');
        }

        const token = new SecretToken();

        token.name = this.tokenName;
        token.type = SecretTokenType.Firebase;
        token.value = '';

        try {
          token.params = {
            service_token: JSON.parse(options.serviceToken)
          };
        } catch (e) {
          token.params = {};
        }

        return {
          modelDescriptions: modelDescriptions.map(item => item.serialize())
        };
      })
    );
  }
}

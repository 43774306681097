import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'appSafeAsync'
})
export class SafeAsyncPipe implements PipeTransform {
  asyncPipe: AsyncPipe;

  constructor(private cd: ChangeDetectorRef) {
    this.asyncPipe = new AsyncPipe(cd);
  }

  transform(value: any | Observable<any>): any {
    if (value instanceof Observable) {
      return this.asyncPipe.transform(value);
    } else {
      return value;
    }
  }
}

export enum ImageFieldType {
  Rectangular = 'rectangular',
  Square = 'square',
  Circular = 'circular'
}

export const imageFieldTypes = [
  { value: ImageFieldType.Rectangular, name: 'Rectangular' },
  { value: ImageFieldType.Square, name: 'Square' },
  { value: ImageFieldType.Circular, name: 'Circular' }
];

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';

import { FileOutputFormat } from '../../../fields/data/fields/file-field/file-output-format';
import { UploadFieldComponent } from './upload-field.component';

@Component({
  selector: 'app-file-field',
  templateUrl: 'file-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileFieldComponent extends UploadFieldComponent implements OnInit, OnDestroy, OnChanges {
  outputFormats = FileOutputFormat;

  constructor(
    protected modelService: ModelService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected storageService: StorageService,
    protected resourceControllerService: ResourceControllerService,
    protected cd: ChangeDetectorRef
  ) {
    super(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd);
  }

  ngOnInit(): void {
    this.updateStorage();
    this.updateUploaded();
    this.initOutputs();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateStorage();
    this.updateUploaded();
  }
}

registerFieldComponent(FieldType.File, FileFieldComponent);

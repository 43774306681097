import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalizeModule } from '@common/localize';
import { MenuProfileModule } from '@modules/menu-profile';
import { RoutingModule } from '@modules/routing';
import { TutorialModule } from '@modules/tutorial';

import { HeaderComponent } from './components/header/header.component';

@NgModule({
  imports: [CommonModule, TutorialModule, RoutingModule, MenuProfileModule, LocalizeModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}

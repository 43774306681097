import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { HttpQuery } from './http-query';
import { ObjectQuery } from './object-query';
import { QueryType } from './query-type';
import { SimpleQuery } from './simple-query';
import { SqlQuery } from './sql-query';

type Constructor<T = {}> = new (...args: any[]) => T;

export abstract class Query<S extends SimpleQuery = any> {
  public queryType: QueryType;
  public simpleQuery: SimpleQuery;
  public httpQuery: HttpQuery;
  public sqlQuery: SqlQuery;
  public objectQuery: ObjectQuery;
  public updated: moment.Moment;

  simpleQueryClass: Constructor<S>;

  deserialize(data: Object) {
    this.queryType = data['query_type'];

    if (data['simple_query'] && this.simpleQueryClass) {
      this.simpleQuery = new this.simpleQueryClass().deserialize(data['simple_query']);
    }

    if (data['http_query']) {
      this.httpQuery = new HttpQuery().deserialize(data['http_query']);
    }

    if (data['sql_query']) {
      this.sqlQuery = new SqlQuery().deserialize(data['sql_query']);
    }

    if (data['object_query']) {
      this.objectQuery = new ObjectQuery().deserialize(data['object_query']);
    }

    if (data['updated']) {
      this.updated = moment(data['updated']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      query_type: this.queryType,
      simple_query: this.simpleQuery ? this.simpleQuery.serialize() : undefined,
      http_query: this.httpQuery ? this.httpQuery.serialize() : undefined,
      sql_query: this.sqlQuery ? this.sqlQuery.serialize() : undefined,
      object_query: this.objectQuery ? this.objectQuery.serialize() : undefined,
      updated: this.updated ? this.updated.toISOString() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured(options: { httpBaseQuery?: HttpQuery } = {}): boolean {
    if (this.queryType == QueryType.Http) {
      return this.httpQuery && this.httpQuery.isConfigured(options.httpBaseQuery);
    } else if (this.queryType == QueryType.SQL) {
      return this.sqlQuery && this.sqlQuery.isConfigured();
    } else if (this.queryType == QueryType.Object) {
      return this.objectQuery && this.objectQuery.isConfigured();
    } else if (this.queryType == QueryType.Simple) {
      return this.simpleQuery && this.simpleQuery.isConfigured();
    } else {
      return false;
    }
  }

  getRequestResponse(): any {
    if (this.queryType == QueryType.Http) {
      return this.httpQuery && this.httpQuery.requestResponse;
    } else if (this.queryType == QueryType.SQL) {
      return this.sqlQuery && this.sqlQuery.requestResponse;
    }
  }
}

import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { User } from '@modules/users';
import { isSet } from '@shared';

import { SnapshotStatus } from './snapshot-status';

export class Snapshot {
  public id: string;
  public name: string;
  public systemName: string;
  public nameByUser = false;
  public hash: string;
  public status: SnapshotStatus;
  public latest = false;
  public user: User;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;

  deserialize(data: Object): Snapshot {
    this.id = data['id'];
    this.name = data['name'];
    this.systemName = data['system_name'];
    this.hash = data['hash'];
    this.status = data['status'];
    this.latest = data['latest'];
    this.createdAt = data['created_at'] ? moment(data['created_at']) : undefined;
    this.updatedAt = data['updated_at'] ? moment(data['updated_at']) : undefined;

    if (data['latest'] !== undefined) {
      this.latest = data['latest'];
    }

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      id: this.id,
      name: this.name,
      hash: this.hash,
      status: this.status,
      latest: this.latest
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isProcessing(): boolean {
    return [SnapshotStatus.Pending, SnapshotStatus.Running].includes(this.status);
  }

  isFailed(): boolean {
    return this.status == SnapshotStatus.Failed;
  }

  isSucceeded(): boolean {
    return this.status == SnapshotStatus.Succeeded;
  }

  get shortHash(): string {
    return isSet(this.hash) ? this.hash.substr(0, 8) : undefined;
  }

  get anyName(): string {
    return isSet(this.name) ? this.name : this.systemName;
  }
}

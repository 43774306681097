import { FocusMonitor } from '@angular/cdk/a11y';
import { ContentObserver } from '@angular/cdk/observers';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Range } from 'ace-builds';
import { AceEditorDirective } from 'ng2-ace-editor';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

import { Token } from '@modules/tokens';
import { isElementHasChild } from '@shared';

// Refactor imports
import { CodeFieldComponent } from '@modules/field-components/components/code-field/code-field.component';
import { SqlFieldComponent } from '@modules/field-components/components/sql-field/sql-field.component';
import { AutoFieldComponent } from '@modules/fields/components/auto-field/auto-field.component';

import {
  CONTEXT_TOKEN,
  InputTokensComponent,
  InputTokensEvent,
  InputTokensEventType,
  SEARCH_TOKEN,
  TokenSearchResult
} from '../../components/input-tokens/input-tokens.component';
import { QueryBuilderContext } from '../../data/query-builder-context';

@Directive({
  selector: '[appTokenInput]',
  exportAs: 'appTokenInput'
})
export class TokenInputDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() appTokenInput: {
    control?: AbstractControl;
    context?: QueryBuilderContext;
    origin?: HTMLElement;
    trigger?: HTMLElement;
    quote?: { open: string; close: string };
  } = {};
  @Output() appTokenEvent = new EventEmitter<InputTokensEvent>();

  popoverRef: ComponentRef<InputTokensComponent>;
  contentObserverSubscription: Subscription;
  defaultQuote = { open: '{{', close: '}}' };

  input: HTMLInputElement;
  ace: AceEditorDirective;
  origin: HTMLElement;
  originOffsetX = 0;
  focus$: Observable<boolean> = new BehaviorSubject(false);
  originMove$ = new Subject<boolean>();
  search$ = new BehaviorSubject<TokenSearchResult>(undefined);
  searchRange: any;
  overlayRef: OverlayRef;

  constructor(
    @Optional() private el: ElementRef,
    @Optional() private autoField: AutoFieldComponent,
    private overlay: Overlay,
    private focusMonitor: FocusMonitor,
    private contentObserver: ContentObserver,
    private injector: Injector
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.contentObserverSubscription) {
      this.contentObserverSubscription.unsubscribe();
    }

    if (this.popoverRef) {
      this.popoverRef.destroy();
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  ngAfterViewInit(): void {
    this.initEditor();
    this.initOverlay();
    this.initPopover();
  }

  initEditor() {
    if (
      this.autoField &&
      this.autoField.dynamicComponent.currentComponent &&
      (this.autoField.dynamicComponent.currentComponent.instance instanceof CodeFieldComponent ||
        this.autoField.dynamicComponent.currentComponent.instance instanceof SqlFieldComponent)
    ) {
      this.ace = this.autoField.dynamicComponent.currentComponent.instance.ace;

      if (this.appTokenInput.trigger) {
        this.origin = this.appTokenInput.origin;
        this.focus$ = fromEvent<MouseEvent>(window, 'mousedown').pipe(
          map(e => isElementHasChild(this.appTokenInput.trigger, e.target as HTMLElement, true))
        );
      } else if (this.ace) {
        const focusObs = new BehaviorSubject<boolean>(false);
        let lead;

        this.origin = this.ace.editor.renderer.$cursorLayer.cursor || this.ace.editor.container;
        this.originOffsetX = 0;

        this.ace.editor.on('focus', () => focusObs.next(true));
        this.ace.editor.on('blur', () => focusObs.next(false));
        this.ace.editor.on('click', e => {
          if (lead && e.$pos && lead.row == e.$pos.row && lead.column == e.$pos.column) {
            focusObs.next(!focusObs.value);
          } else {
            focusObs.next(true);
          }
        });
        this.ace.editor.session.selection.on('changeCursor', (...args) => {
          const [_, selection] = args;
          setTimeout(() => this.originMove$.next(), 0);
          lead = selection.getSelectionLead();
          const line = this.ace.editor.session.getLine(lead.row);
          this.updateSearch(line, lead.column);

          // if (this.search$.value !== undefined) {
          //   focusObs.next(true);
          // } else {
          //   focusObs.next(false);
          // }

          focusObs.next(false);
        });
        this.focus$ = focusObs.asObservable();
      }
    } else if (this.el && this.el.nativeElement instanceof HTMLInputElement) {
      this.input = this.el.nativeElement;
      this.origin = this.input;
      this.originOffsetX = 0;
      this.focus$ = merge(of(false), this.focusMonitor.monitor(this.input).pipe(map(origin => !!origin)));

      merge(fromEvent(this.input, 'keyup'), fromEvent(this.input, 'mousedown'), fromEvent(this.input, 'focus'))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          setTimeout(() => {
            const value = this.input.value;
            const position = this.input.selectionStart;
            this.updateSearch(value, position);
          }, 0);
        });
    }
  }

  updateSearch(value: string, position: number) {
    const quote = this.appTokenInput.quote || this.defaultQuote;
    const leftMatch = value.substring(0, position).match(new RegExp(`${quote.open}([\\w.]+)?(${quote.close})?$`));
    const rightMatch = value.substring(position).match(new RegExp(`^([\\w.]+)(?:${quote.close})?`));

    if (leftMatch) {
      const left = leftMatch[1] || '';
      const right = rightMatch ? rightMatch[1] : '';
      const search = leftMatch[2] ? left : left + right;

      this.search$.next({
        query: search,
        exact: !quote.open.length && !quote.close.length
      });
      this.searchRange = [
        position - leftMatch[0].length,
        position + (!leftMatch[2] && rightMatch ? rightMatch[0].length : 0)
      ];
    } else {
      this.search$.next(undefined);
      this.searchRange = undefined;
    }
  }

  initOverlay() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.origin)
      .withPositions([
        {
          panelClass: ['overlay_position_bottom-right'],
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: this.originOffsetX + -8,
          weight: 4
        },
        {
          panelClass: ['overlay_position_bottom-left'],
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: this.originOffsetX + 8,
          weight: 3
        },
        {
          panelClass: ['overlay_position_top-right'],
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: this.originOffsetX + -8,
          weight: 2
        },
        {
          panelClass: ['overlay_position_top-left'],
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: this.originOffsetX + 8,
          weight: 1
        }
      ])
      .withPush(true)
      .withGrowAfterOpen(true);

    this.overlayRef = this.overlay.create({
      panelClass: ['overlay'],
      positionStrategy: positionStrategy
    });

    this.originMove$.pipe(untilDestroyed(this)).subscribe(() => this.overlayRef.updatePosition());
  }

  initPopover() {
    combineLatest(
      this.focus$,
      merge(
        of(false),
        fromEvent<MouseEvent>(window, 'mousedown').pipe(
          map(e => {
            if (
              this.popoverRef &&
              isElementHasChild(this.popoverRef.location.nativeElement, e.target as HTMLElement, true)
            ) {
              return true;
            }

            const overlayContainer = document.querySelector('.cdk-overlay-container');
            if (overlayContainer && isElementHasChild(overlayContainer, e.target as HTMLElement)) {
              return;
            }

            return false;
          }),
          filter(e => e !== undefined)
        )
      )
    )
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe(([inputFocus, clickedPopover]) => {
        const open = inputFocus || clickedPopover;

        if (open && !this.overlayRef.hasAttached()) {
          this.openPopover();
        } else if (!open && this.overlayRef.hasAttached()) {
          this.closePopover();
        }
      });
  }

  openPopover() {
    const injector = Injector.create({
      providers: [
        { provide: CONTEXT_TOKEN, useValue: this.appTokenInput.context },
        { provide: SEARCH_TOKEN, useValue: this.search$ }
      ],
      parent: this.injector
    });
    const portal = new ComponentPortal(InputTokensComponent, null, injector);

    this.popoverRef = this.overlayRef.attach(portal);
    this.popoverRef.instance.inserted.pipe(untilDestroyed(this)).subscribe(item => this.insertToken(item));
    this.popoverRef.instance.event.pipe(untilDestroyed(this)).subscribe(e => {
      if (e.type == InputTokensEventType.Submit) {
        this.closePopover();
      }

      this.appTokenEvent.emit(e);
    });

    this.contentObserverSubscription = this.contentObserver
      .observe(this.popoverRef.location)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.overlayRef.updatePosition());
  }

  closePopover() {
    this.overlayRef.detach();

    if (this.contentObserverSubscription) {
      this.contentObserverSubscription.unsubscribe();
    }
  }

  getReplaceRange(): any {
    if (this.ace) {
      if (this.searchRange) {
        const lead = this.ace.editor.session.selection.getSelectionLead();
        return new Range(lead.row, this.searchRange[0], lead.row, this.searchRange[1]);
      } else {
        return this.ace.editor.selection.getRange();
      }
    } else if (this.input) {
      return [this.input.selectionStart, this.input.selectionEnd];
    }
  }

  insertEditorText(text: string, range: any) {
    if (this.ace) {
      this.ace.editor.session.replace(range, text);
      this.appTokenInput.control.patchValue(this.ace.editor.getValue());
    } else if (this.input) {
      const [selectionStart, selectionEnd] = range;
      const selectionRange = selectionStart != selectionEnd;
      const selectionMin = Math.min(selectionStart, selectionEnd);
      const selectionMax = Math.max(selectionStart, selectionEnd);
      const currentValue = String(this.input.value);

      let value: string;
      let position: number;

      if (this.searchRange && !selectionRange) {
        value = [
          currentValue.substring(0, this.searchRange[0]),
          text,
          currentValue.substring(this.searchRange[1] + 1)
        ].join('');
        position = this.searchRange[0] + text.length;
      } else {
        value = [currentValue.substring(0, selectionMin), text, currentValue.substring(selectionMax)].join('');
        position = selectionEnd >= selectionStart ? selectionStart + text.length : selectionEnd;
      }

      this.appTokenInput.control.patchValue(value);

      this.input.focus();
      this.input.setSelectionRange(position, position);
    }
  }

  quote(token: string) {
    const quote = this.appTokenInput.quote || this.defaultQuote;
    return [quote.open, token, quote.close].join('');
  }

  secretQuote(token: string) {
    return `{-${token}-}`;
  }

  insertToken(token: Token) {
    const insert = token.data && token.data['secret'] ? this.secretQuote(token.name) : this.quote(token.name);
    const selectionRange = this.getReplaceRange();

    this.insertEditorText(insert, selectionRange);
  }

  insertText(text: string) {
    const selectionRange = this.getReplaceRange();
    this.insertEditorText(text, selectionRange);
  }
}

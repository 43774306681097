import clamp from 'lodash/clamp';

export enum ColorModel {
  RGB = 'rgb',
  HSL = 'hsl',
  HSB = 'hsb'
}

export class Color {
  red = 0;
  green = 0;
  blue = 0;
  alpha = 1;

  constructor(options: Partial<Color> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.red = data['red'];
    this.green = data['green'];
    this.blue = data['blue'];
    this.alpha = data['alpha'];

    return this;
  }

  serialize(): Object {
    return {
      red: this.red,
      green: this.green,
      blue: this.blue,
      alpha: this.alpha
    };
  }

  css(): string {
    const red = clamp(255 * this.red, 0, 255);
    const green = clamp(255 * this.green, 0, 255);
    const blue = clamp(255 * this.blue, 0, 255);
    const alpha = clamp(this.alpha, 0, 1);

    if (alpha == 1) {
      return `rgb(${red},${green},${blue})`;
    } else {
      return `rgba(${red},${green},${blue},${alpha})`;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { PopoverComponent } from '@common/popover';

import { RawListViewSettingsColumn } from '@modules/customize';
import { Option } from '@modules/field-components';
import { FieldLookup } from '@modules/field-lookups';
import { createFormFieldFactory, ParameterField } from '@modules/fields';
import { Input as FieldInput } from '@modules/fields';
import { FilterItem } from '@modules/filters';
import { ModelContext } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';

import { AddFilterPopupForm } from './add-filter-popup.form';

// TODO: Remove unused
@Component({
  selector: 'app-add-filter-popup',
  templateUrl: './add-filter-popup.component.html',
  providers: [AddFilterPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFilterPopupComponent implements OnInit, OnDestroy {
  @Input() columns: RawListViewSettingsColumn[] = [];
  @Input() parameters: ParameterField[] = [];
  @Input() inputs: FieldInput[] = [];
  @Input() field: string;
  @Input() value: FilterItem;
  @Input() context: ModelContext;
  @Output() added = new EventEmitter<FilterItem>();

  createField = createFormFieldFactory();
  excludeAllowed = false;
  fieldOptions: Option<string>[] = [];
  lookups = [];
  valueField: string;
  valueFieldParams: Object;
  valueFieldLookup: FieldLookup;

  constructor(
    public form: AddFilterPopupForm,
    private currentProjectStore: CurrentProjectStore,
    private resourceControllerService: ResourceControllerService,
    private cd: ChangeDetectorRef,
    private popoverComponent: PopoverComponent
  ) {}

  ngOnInit() {
    this.form.init(this.field, this.columns, this.parameters, this.inputs, this.value);

    combineLatest(
      this.form.fieldOptions$,
      this.form.lookups$,
      this.form.valueField$,
      this.form.valueFieldParams$,
      this.form.valueFieldLookup$
    )
      .pipe(auditTime(1000 / 60), untilDestroyed(this))
      .subscribe(result => {
        [this.fieldOptions, this.lookups, this.valueField, this.valueFieldParams, this.valueFieldLookup] = result;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  // updateExcludeAllowed(field) {
  //   this.excludeAllowed = field && field.filterExcludable;
  //   this.cd.markForCheck();
  // }

  submit(filter: boolean) {
    const result = this.form.submit(filter);

    if (result) {
      this.added.emit(result);
    }

    this.popoverComponent.close();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { createFormFieldFactory } from '@modules/fields';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-import',
  templateUrl: './customize-bar-action-edit-type-import.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeImportComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();

  constructor() {}

  ngOnInit() {}
}

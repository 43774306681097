import pickBy from 'lodash/pickBy';

import { Workflow } from '@modules/workflow';
import { isSet } from '@shared';

// TODO: Refactor imports
import { ParameterValue } from '../../fields/data/parameter-value';

export enum SSOType {
  SAML2 = 'saml2',
  OAuth2 = 'oauth2',
  Custom = 'custom',
  JetBridge = 'jet_bridge'
}

export enum SharedCustomSSOType {
  Firebase = 'firebase',
  Supabase = 'supabase'
}

export function getSSOSettingsTypeLabel(type: SSOType): string {
  if (type == SSOType.OAuth2) {
    return 'OAuth2';
  } else if (type == SSOType.SAML2) {
    return 'SAML2';
  } else if (type == SSOType.Custom) {
    return 'Custom authentication';
  } else if (type == SSOType.JetBridge) {
    return 'Jet Bridge';
  }
}

export class SSOSettings {
  public uid: string;
  public domain: string;
  public name: string;
  public type: SSOType;
  public defaultGroup: string;
  public publicParams = {};
  public params = {};
  public active = true;
  public image: string;
  public ordering: number;
  public logoutUrl: string;

  deserialize(data: Object): SSOSettings {
    if (!(this instanceof OAuth2SSOSettings) && data['type'] == SSOType.OAuth2) {
      return new OAuth2SSOSettings().deserialize(data);
    } else if (!(this instanceof SAML2SSOSettings) && data['type'] == SSOType.SAML2) {
      return new SAML2SSOSettings().deserialize(data);
    } else if (!(this instanceof CustomSSOSettings) && data['type'] == SSOType.Custom) {
      return new CustomSSOSettings().deserialize(data);
    } else if (!(this instanceof JetBridgeSSOSettings) && data['type'] == SSOType.JetBridge) {
      return new JetBridgeSSOSettings().deserialize(data);
    }

    this.uid = data['uid'];
    this.domain = data['domain'];
    this.name = data['name'];
    this.type = data['type'];
    this.defaultGroup = data['default_group'];
    this.logoutUrl = data['logout_url'];
    this.ordering = data['ordering'];

    if (isSet(data['active'])) {
      this.active = data['active'];
    }

    if (data['public_params']) {
      this.publicParams = data['public_params'];
    }

    if (data['params']) {
      this.params = data['params'];
    }

    this.image = this.publicParams['image'];

    return this;
  }

  serialize(fields?: string[]): Object {
    this.publicParams['image'] = this.image;

    let data: Object = {
      domain: this.domain,
      name: this.name,
      type: this.type,
      default_group: this.defaultGroup,
      public_params: this.publicParams,
      params: this.params,
      active: this.active,
      ordering: this.ordering
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get link() {
    return ['project', 'sso', this.uid];
  }

  getTypeLabel(): string {
    return getSSOSettingsTypeLabel(this.type);
  }

  getSecretTokenContext(): { name: string; label: string } {
    if ([SSOType.OAuth2, SSOType.JetBridge].includes(this.type)) {
      return {
        name: 'access_token',
        label: 'access token'
      };
    } else if ([SSOType.Custom].includes(this.type)) {
      return {
        name: 'token',
        label: 'token'
      };
    }
  }
}

export class OAuth2SSOSettings extends SSOSettings {
  public workflowsProject: string;
  public workflowsEnvironment: string;
  public userDetailsWorkflow: Workflow;
  public userDetailsWorkflowData: Object;

  deserialize(data: Object): SSOSettings {
    super.deserialize(data);
    this.workflowsProject = data['workflows_project'];
    this.workflowsEnvironment = data['workflows_environment'];
    this.userDetailsWorkflowData = data['user_details_workflow'];

    // TODO: Workaround for circular import
    // if (data['user_details_workflow']) {
    //   this.userDetailsWorkflow = new Workflow().deserialize(data['user_details_workflow']);
    // }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      workflows_project: this.workflowsProject,
      workflows_environment: this.workflowsEnvironment,
      user_details_workflow: this.userDetailsWorkflow ? this.userDetailsWorkflow.serialize() : null
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return {
      ...super.serialize(fields),
      ...data
    };
  }
}

export class SAML2SSOSettings extends SSOSettings {
  public metadata: string;
  public metadataFile: File;
  public entityId: string;

  deserialize(data: Object): SSOSettings {
    super.deserialize(data);
    this.metadata = this.params['metadata'];
    this.entityId = this.params['entity_id'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data = super.serialize(fields);

    if (data['params']) {
      delete data['params'];
    }

    data['metadata'] = this.metadataFile;
    data['entity_id'] = this.entityId;

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }

    return data;
  }
}

export class CustomSSOSettings extends SSOSettings {
  public workflowsProject: string;
  public workflowsEnvironment: string;
  public sharedCustomSSO: SharedCustomSSOType;
  public parameters: ParameterValue[] = [];
  public authWorkflow: Workflow;
  public authWorkflowData: Object;
  public refreshTokenWorkflow: Workflow;
  public refreshTokenWorkflowData: Object;

  deserialize(data: Object): SSOSettings {
    super.deserialize(data);
    this.workflowsProject = data['workflows_project'];
    this.workflowsEnvironment = data['workflows_environment'];
    this.sharedCustomSSO = data['shared_custom_sso'];
    this.authWorkflowData = data['auth_workflow'];
    this.refreshTokenWorkflowData = data['refresh_token_workflow'];

    if (data['parameters']) {
      this.parameters = data['parameters'].map(item => new ParameterValue().deserialize(item));
    }

    // TODO: Workaround for circular import
    // if (data['auth_workflow']) {
    //   this.authWorkflow = new Workflow().deserialize(data['auth_workflow']);
    // }
    //
    // if (data['refresh_token_workflow']) {
    //   this.refreshTokenWorkflow = new Workflow().deserialize(data['refresh_token_workflow']);
    // }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      workflows_project: this.workflowsProject,
      workflows_environment: this.workflowsEnvironment,
      shared_custom_sso: this.sharedCustomSSO,
      parameters: this.parameters.map(item => item.serialize()),
      auth_workflow: this.authWorkflow ? this.authWorkflow.serialize() : null,
      refresh_token_workflow: this.refreshTokenWorkflow ? this.refreshTokenWorkflow.serialize() : null
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return {
      ...super.serialize(fields),
      ...data
    };
  }

  getTypeLabel(): string {
    if (isSet(this.sharedCustomSSO)) {
      return 'Built-in authentication';
    } else {
      return super.getTypeLabel();
    }
  }
}

export class JetBridgeSSOSettings extends SSOSettings {
  public url: string;

  deserialize(data: Object): SSOSettings {
    super.deserialize(data);
    this.url = this.publicParams['url'];

    return this;
  }

  serialize(fields?: string[]): Object {
    this.publicParams = JSON.stringify({
      url: this.url
    });

    return super.serialize(fields);
  }
}

export function createSSOSettings(type: SSOType): SSOSettings {
  if (type == SSOType.SAML2) {
    return new SAML2SSOSettings();
  } else if (type == SSOType.JetBridge) {
    return new JetBridgeSSOSettings();
  } else if (type == SSOType.Custom) {
    return new CustomSSOSettings();
  } else {
    return new SSOSettings();
  }
}

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { delayWhen, map, tap } from 'rxjs/operators';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { ViewSettingsStore } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Resource,
  ResourceName,
  ResourceService,
  ResourceTypeItem
} from '@modules/projects';
import { RestAPIResourceParams } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ascComparator, isSet, UnexpectedError } from '@shared';

import { DatabaseResourceParams } from '../../components/resource-settings/databases-resource-settings/databases-resource-settings.form';
import { getResourceSettingsComponent } from '../../data/resource-settings-components';

export interface ResourceEditResult {
  resource?: Resource;
  created?: boolean;
  edited?: boolean;
  cancelled?: boolean;
  deleted?: boolean;
}

@Injectable()
export class ResourceEditController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceService: ResourceService,
    private menuSettingsStore: MenuSettingsStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private popupService: PopupService,
    private dialogService: DialogService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  openEditPopup(
    type: ResourceTypeItem,
    resource?: Resource,
    options: {
      resourceNameEditing?: boolean;
      resourceDelete?: boolean;
      databaseDefaults?: DatabaseResourceParams;
      analyticsSource?: string;
    } = {}
  ): Observable<ResourceEditResult> {
    const result = new Subject<ResourceEditResult>();
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const settings = getResourceSettingsComponent(type.name);

    if (!settings) {
      return throwError(new UnexpectedError(`Unknown resource edit type: ${type.name}`));
    }

    this.popupService.push({
      component: settings.component,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        project: project,
        environment: environment,
        typeItem: type,
        ...(isSet(resource) && { resource: resource }),
        ...(isSet(options.resourceNameEditing) && { resourceNameEditing: options.resourceNameEditing }),
        ...(isSet(options.resourceDelete) && { resourceDelete: options.resourceDelete }),
        ...(isSet(options.databaseDefaults) && { params: options.databaseDefaults }),
        ...(isSet(options.analyticsSource) && { analyticsSource: options.analyticsSource })
      },
      outputs: {
        saved: [
          (savedResource: Resource) => {
            result.next({
              resource: savedResource,
              created: !resource,
              edited: !!resource
            });

            const resourceParams =
              savedResource.typeItem.name == ResourceName.RestApi
                ? savedResource.parseParams<RestAPIResourceParams>(RestAPIResourceParams)
                : undefined;
            const restApiBaseUrl = resourceParams ? resourceParams.baseHttpQuery.url : undefined;

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
              Type: 'data'
            });

            if (!resource) {
              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAdded, {
                Source: options.analyticsSource,
                ResourceID: type.name,
                ResourceDatabaseOptionType: savedResource.params['database_option']
                  ? savedResource.params['database_option']['type']
                  : undefined,
                MethodID: savedResource.params['deploy'],
                ...(restApiBaseUrl ? { RestApiBaseUrl: restApiBaseUrl } : {}),
                Synced: savedResource.isSynced()
              });
            } else {
              this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceUpdated, {
                Source: options.analyticsSource,
                ResourceID: type.name,
                ResourceDatabaseOptionType: savedResource.params['database_option']
                  ? savedResource.params['database_option']['type']
                  : undefined,
                MethodID: savedResource.params['deploy'],
                Synced: savedResource.isSynced()
              });
            }
          }
        ],
        canceled: [
          () => {
            result.next({
              cancelled: true
            });
          }
        ],
        deleteRequested: [
          () => {
            this.requestDeleteResource(resource).subscribe(deleteResult => {
              if (deleteResult) {
                result.next({
                  deleted: true
                });
              }
            });
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          result.next({
            cancelled: true
          });
        }
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return result;
  }

  createResource(
    type: ResourceTypeItem,
    options: {
      resourceNameEditing?: boolean;
      databaseDefaults?: DatabaseResourceParams;
      analyticsSource?: string;
    } = {}
  ): Observable<ResourceEditResult> {
    return this.openEditPopup(type, undefined, options);
  }

  editResource(
    resource: Resource,
    options: {
      resourceNameEditing?: boolean;
      resourceDelete?: boolean;
      databaseDefaults?: DatabaseResourceParams;
      analyticsSource?: string;
    } = {}
  ): Observable<ResourceEditResult> {
    return this.openEditPopup(resource.typeItem, resource, options);
  }

  deleteResource(resource: Resource, options: { redirectToFirstResource?: boolean } = {}): Observable<boolean> {
    return this.resourceService
      .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, resource)
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        tap(() => {
          if (options.redirectToFirstResource) {
            const project = this.currentProjectStore.instance;
            const firstResource = this.currentEnvironmentStore.resources
              .filter(item => !item.typeItem.protected)
              .filter(item => !item.demo || (item.demo && project.uniqueName == DEMO_RESOURCES_PROJECT))
              .sort((lhs, rhs) => ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()))[0];
            if (firstResource) {
              this.routing.navigateApp(firstResource.link);
            }
          }
        }),
        delayWhen(() => this.modelDescriptionStore.getFirst(true)),
        delayWhen(() => this.actionStore.getFirst(true)),
        delayWhen(() => this.viewSettingsStore.getFirst(true)),
        delayWhen(() => this.menuSettingsStore.getFirst(true))
      );
  }

  requestDeleteResource(resource: Resource, options: { redirectToFirstResource?: boolean } = {}): Observable<boolean> {
    return this.dialogService
      .dialog({
        title: 'Deleting',
        description: 'Are you sure want to delete this Resource from App?',
        buttons: [
          {
            name: 'cancel',
            label: 'Cancel',
            type: DialogButtonType.Default,
            hotkey: DialogButtonHotkey.Cancel
          },
          {
            name: 'ok',
            label: 'Delete resource',
            type: DialogButtonType.Danger,
            hotkey: DialogButtonHotkey.Submit,
            executor: () => this.deleteResource(resource, options)
          }
        ]
      })
      .pipe(map(result => result.button == 'ok' && result.executorResult === true));
  }
}

<div
  cdkDropList
  [cdkDropListDisabled]="!configurable.sortable"
  [cdkDropListLockAxis]="'y'"
  class="sidebar-list"
  (cdkDropListDropped)="dragDrop($event)"
>
  <app-parameters-edit-with-inputs-item
    *ngFor="let item of control.controls; let i = index"
    cdkDrag
    class="sidebar-list__item"
    [control]="item"
    [configurable]="configurable"
    [inputsForm]="inputsForm"
    [itemForm]="getItemForm(item.controls.name.value)"
    [openedInitial]="lastAddedForm === item"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueDisabled]="staticValueDisabled"
    [collapseContext]="collapseContext"
    [userInput]="userInput"
    [focusedInitial]="true"
    [placeholder]="placeholder"
    [formulaPlaceholder]="formulaPlaceholder"
    [jsPlaceholder]="jsPlaceholder"
    [analyticsSource]="analyticsSource"
    (deleteRequested)="removeItem(item)"
  ></app-parameters-edit-with-inputs-item>

  <div *ngIf="!control.controls.length && configurable.add" class="sidebar-list__item">
    <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="icon-text button__icon"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">{{ emptyLabel }}</div>
        <div class="sidebar-list-item__description">Not specified</div>
      </div>
    </div>
  </div>

  <div *ngIf="configurable.add" class="sidebar-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">{{ addLabel }}</span>
    </a>
  </div>
</div>

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class StepsItem {
  uid: string;
  value: any;
  name: string;
  description: string;
  color: string;
  visibleInput: Input;

  deserialize(data: Object): StepsItem {
    this.uid = data['uid'];
    this.value = data['value'];
    this.name = data['name'];
    this.description = data['description'];
    this.color = data['color'];

    if (!this.uid) {
      this.generateUid();
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    } else {
      this.visibleInput = undefined;
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      value: this.value,
      name: this.name,
      description: this.description,
      color: this.color,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(4, { letterFirst: true });
  }
}

export class StepsElementItem extends ElementItem {
  public type = ElementType.Steps;
  public items: StepsItem[] = [];
  public currentItem: Input;

  deserialize(data: Object): StepsElementItem {
    super.deserialize(data);

    if (this.params['items']) {
      this.items = this.params['items'].map(item => new StepsItem().deserialize(item));
    }

    if (this.params['current_item']) {
      this.currentItem = new Input().deserialize(this.params['current_item']);
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      items: this.items.map(item => item.serialize()),
      current_item: this.currentItem ? this.currentItem.serialize() : null
    };
    return super.serialize();
  }

  get analyticsName(): string {
    return 'steps';
  }

  defaultName() {
    return 'Steps';
  }
}

registerElementForType(ElementType.Steps, StepsElementItem);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./video-player.component";
var styles_VideoPlayerComponent = [];
var RenderType_VideoPlayerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoPlayerComponent, data: {} });
export { RenderType_VideoPlayerComponent as RenderType_VideoPlayerComponent };
export function View_VideoPlayerComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_VideoPlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-player", [], null, null, null, View_VideoPlayerComponent_0, RenderType_VideoPlayerComponent)), i0.ɵdid(1, 770048, null, 0, i1.VideoPlayerComponent, [i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoPlayerComponentNgFactory = i0.ɵccf("app-video-player", i1.VideoPlayerComponent, View_VideoPlayerComponent_Host_0, { src: "src" }, {}, []);
export { VideoPlayerComponentNgFactory as VideoPlayerComponentNgFactory };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TweenMax } from 'gsap';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PopupRef } from '@common/popups';
import { ResizeEvent, ResizeStartedEvent, ResizeType } from '@common/resizable';
import { LocalStorage } from '@core';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType, InputFilterField, InputValueType } from '@modules/fields';
import { isSet, MouseButton } from '@shared';

interface Bounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

@Component({
  selector: 'app-input-overlay',
  templateUrl: './input-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() itemForm: FormGroup;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueField: FieldType;
  @Input() staticValueParams: Object;
  @Input() staticValueDisabled = false;
  @Input() filterFields: InputFilterField[] = [];
  @Input() userInput = false;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() displayValueTypesEnabled = true;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Input() analyticsSource: string;
  @Output() finished = new EventEmitter<void>();

  @ViewChild('root_element') rootElement: ElementRef;
  @ViewChild('header_element') headerElement: ElementRef;

  windowPadding = 20;
  resizing = false;
  resizeSubscriptions: Subscription[] = [];
  positionX = 0;
  positionY = 0;

  resizeWidth: number;
  resizeHeight: number;
  resizePositionX: number;
  resizePositionY: number;
  resizeTypes = ResizeType;

  constructor(public popupRef: PopupRef, private el: ElementRef, private localStorage: LocalStorage) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.finished.emit();
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.headerElement.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(e => {
        this.initMove(e);
      });

    if (!this.loadBounds()) {
      this.setDefaultBounds();
    }
  }

  initMove(downEvent: MouseEvent) {
    const subscriptions: Subscription[] = [];
    const originalPositionX = this.positionX;
    const originalPositionY = this.positionY;

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();
          moveEvent.stopPropagation();

          const width = this.rootElement.nativeElement.offsetWidth;
          const height = this.rootElement.nativeElement.offsetHeight;
          const leftDelta = moveEvent.clientX - downEvent.clientX;
          const topDelta = moveEvent.clientY - downEvent.clientY;

          this.positionX = Math.round(
            clamp(originalPositionX + leftDelta, this.windowPadding, window.innerWidth - width - this.windowPadding)
          );
          this.positionY = Math.round(
            clamp(originalPositionY + topDelta, this.windowPadding, window.innerHeight - height - this.windowPadding)
          );

          TweenMax.set(this.rootElement.nativeElement, {
            x: this.positionX,
            y: this.positionY
          });

          this.setPosition(originalPositionX + leftDelta, originalPositionY + topDelta);
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(upEvent => {
          upEvent.preventDefault();
          subscriptions.forEach(item => item.unsubscribe());

          this.resizing = false;
          this.saveBounds();
        })
    );

    this.resizeSubscriptions = subscriptions;
  }

  setPosition(x: number, y: number) {
    const width = this.rootElement.nativeElement.offsetWidth;
    const height = this.rootElement.nativeElement.offsetHeight;

    this.positionX = clamp(x, this.windowPadding, window.innerWidth - width - this.windowPadding);
    this.positionY = clamp(y, this.windowPadding, window.innerHeight - height - this.windowPadding);

    TweenMax.set(this.rootElement.nativeElement, {
      x: this.positionX,
      y: this.positionY
    });
  }

  setBounds(x: number, y: number, width: number, height: number) {
    width = clamp(width, window.innerWidth * 0.25, window.innerWidth * 0.6);
    height = clamp(height, window.innerHeight * 0.2, window.innerHeight * 0.7);

    this.positionX = Math.round(clamp(x, this.windowPadding, window.innerWidth - width - this.windowPadding));
    this.positionY = Math.round(clamp(y, this.windowPadding, window.innerHeight - height - this.windowPadding));

    TweenMax.set(this.rootElement.nativeElement, {
      x: this.positionX,
      y: this.positionY,
      width: width,
      height: height
    });
  }

  setDefaultBounds() {
    const width = clamp(window.innerWidth * 0.25, this.rootElement.nativeElement.offsetWidth, window.innerWidth * 0.6);
    const height = clamp(
      window.innerHeight * 0.2,
      this.rootElement.nativeElement.offsetHeight,
      window.innerHeight * 0.7
    );

    this.setBounds(window.innerWidth * 0.5 - width * 0.5, window.innerHeight * 0.5 - height * 0.5, width, height);
  }

  onResizeStarted(e: ResizeStartedEvent) {
    this.resizeWidth = this.rootElement.nativeElement.offsetWidth;
    this.resizeHeight = this.rootElement.nativeElement.offsetHeight;
    this.resizePositionX = this.positionX;
    this.resizePositionY = this.positionY;
  }

  onResize(e: ResizeEvent) {
    const width = this.rootElement.nativeElement.offsetWidth;
    const height = this.rootElement.nativeElement.offsetHeight;

    const x = Math.round(this.resizePositionX - (width - this.resizeWidth) * 0.5);
    const y = Math.round(this.resizePositionY - (height - this.resizeHeight) * 0.5);

    this.setPosition(x, y);
    this.saveBounds();
  }

  loadBounds(): boolean {
    const saved = this.localStorage.get('input_overlay_bounds');

    if (!isSet(saved)) {
      return false;
    }

    try {
      const bounds = JSON.parse(saved) as Bounds;
      this.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);
      return true;
    } catch (e) {
      return false;
    }
  }

  saveBounds() {
    const width = this.rootElement.nativeElement.offsetWidth;
    const height = this.rootElement.nativeElement.offsetHeight;
    const bounds: Bounds = {
      x: this.positionX,
      y: this.positionY,
      width: width,
      height: height
    };

    this.localStorage.set('input_overlay_bounds', JSON.stringify(bounds));
  }
}

import { OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, share, switchMap, tap } from 'rxjs/operators';

import { ServerRequestError } from '@modules/api';

import { isSet } from '../utils/common/common';

export interface ListStoreFetchResponse<M> {
  items: M[];
  hasMore: boolean;
  totalPages?: number;
  fetchPerPage?: number;
  count?: number;
  cursorPrev?: any;
  cursorNext?: any;
}

const DEFAULT_PER_PAGE = 20;

export abstract class ListStore<M> implements OnDestroy {
  protected _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _items: BehaviorSubject<M[]> = new BehaviorSubject(undefined);
  protected _fetchItems: BehaviorSubject<M[]> = new BehaviorSubject(undefined);
  protected _currentPageImmediate: BehaviorSubject<number> = new BehaviorSubject(1);
  protected _currentPage: BehaviorSubject<number> = new BehaviorSubject(1);
  protected _fetchHasMore: BehaviorSubject<boolean> = new BehaviorSubject(true);
  protected _fromPage: BehaviorSubject<number> = new BehaviorSubject(1);
  protected _nextPage: BehaviorSubject<number> = new BehaviorSubject(1);
  protected _fetchNextPage: BehaviorSubject<number> = new BehaviorSubject(1);
  protected _fetchTotalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  protected _count: BehaviorSubject<number> = new BehaviorSubject(undefined);
  protected _perPage: BehaviorSubject<number> = new BehaviorSubject(DEFAULT_PER_PAGE);
  protected subscription: Subscription;
  protected prevPageCursors = {};
  protected nextPageCursors = {};

  hasMore$: Observable<boolean> = combineLatest(this._items, this._fetchItems, this._fetchHasMore).pipe(
    map(([items, fetchItems, fetchHasMore]) => {
      return this.getHasMore(items, fetchItems, fetchHasMore);
    })
  );

  totalPages$: Observable<number> = combineLatest(this._count, this._perPage).pipe(
    map(([count, perPage]) => {
      return this.getTotalPages(count, perPage);
    })
  );

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get hasMore(): boolean {
    return this.getHasMore(this._items.value, this._fetchItems.value, this._fetchHasMore.value);
  }

  get totalPages(): number {
    return this.getTotalPages(this._count.value, this._perPage.value);
  }

  getHasMore(items: M[], fetchItems: M[], fetchHasMore: boolean) {
    const displayCount = items ? items.length : 0;
    const fetchedCount = fetchItems ? fetchItems.length : 0;
    return fetchedCount > displayCount || fetchHasMore;
  }

  getTotalPages(count: number, perPage: number) {
    if (count === undefined || perPage === undefined) {
      return;
    }

    return Math.ceil(count / perPage);
  }

  get loading$() {
    return this._loading.asObservable();
  }

  get loading() {
    return this._loading.value;
  }

  get items$(): Observable<M[]> {
    return this._items.asObservable();
  }

  get items() {
    return this._items.value;
  }

  set items(value: M[]) {
    this._items.next(value);
  }

  get fetchItems$(): Observable<M[]> {
    return this._fetchItems.asObservable();
  }

  get fetchItems() {
    return this._fetchItems.value;
  }

  set fetchItems(value: M[]) {
    this._fetchItems.next(value);
  }

  get currentPage$() {
    return this._currentPage.asObservable();
  }

  get currentPage() {
    return this._currentPage.value;
  }

  set currentPage(value) {
    this._currentPage.next(value);
  }

  get currentPageImmediate$() {
    return this._currentPageImmediate.asObservable();
  }

  get currentPageImmediate() {
    return this._currentPageImmediate.value;
  }

  get fromPage$() {
    return this._fromPage.asObservable();
  }

  get fromPage() {
    return this._fromPage.value;
  }

  get fetchHasMore() {
    return this._fetchHasMore.value;
  }

  set fetchHasMore(value) {
    this._fetchHasMore.next(value);
  }

  get nextPage() {
    return this._nextPage.value;
  }

  set nextPage(value) {
    this._nextPage.next(value);
  }

  get fetchNextPage() {
    return this._fetchNextPage.value;
  }

  set fetchNextPage(value) {
    this._fetchNextPage.next(value);
  }

  get fetchTotalPages() {
    return this._fetchTotalPages.value;
  }

  set fetchTotalPages(value) {
    this._fetchTotalPages.next(value);
  }

  get count$() {
    return this._count.asObservable();
  }

  get count() {
    return this._count.value;
  }

  set count(value) {
    this._count.next(value);
  }

  get perPage$() {
    return this._perPage.asObservable();
  }

  get perPage() {
    return this._perPage.value;
  }

  set perPage(value) {
    value = Number(value);

    if (isNaN(value)) {
      value = undefined;
    }

    if (isSet(value) && value > 0) {
      value = Math.floor(value);
      this._perPage.next(value);
    } else {
      this._perPage.next(DEFAULT_PER_PAGE);
    }
  }

  abstract fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<M>>;

  getNext(): Observable<M[]> {
    if (!this.hasMore || this.loading) {
      return of([]);
    }

    const displayCount = this._items.value ? this._items.value.length : 0;
    const fetchedCount = this._fetchItems.value ? this._fetchItems.value.length : 0;
    const perPage = this._perPage.value;

    const currentPage = this.currentPage;
    const nextPage = this._nextPage.value;
    const fetchNextPage = this.fetchNextPage;

    this._currentPageImmediate.next(currentPage);

    if (fetchedCount - displayCount >= perPage) {
      const fetchedResult = this._fetchItems.value
        ? this._fetchItems.value.slice(displayCount, displayCount + perPage)
        : [];
      this.appendItems(fetchedResult);
      this._currentPage.next(nextPage);
      this._nextPage.next(nextPage + 1);
      return of(fetchedResult);
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    this._loading.next(true);

    const obs = this.fetchPage(fetchNextPage, true).pipe(
      catchError(e => {
        if (this.subscription !== subscription) {
          return EMPTY;
        }

        return throwError(e);
      }),
      filter(() => {
        return this.subscription === subscription;
      }),
      tap(response => {
        if (!response) {
          throw new ServerRequestError('Failed to fetch response');
        }

        this.appendFetchItems(response.items);

        const fetchedResult = this._fetchItems.value
          ? this._fetchItems.value.slice(displayCount, displayCount + perPage)
          : [];

        this.appendItems(fetchedResult);
      }),
      share()
    );

    const subscription = obs.subscribe(
      response => {
        this._loading.next(false);
        this._fetchHasMore.next(response.hasMore);
        this._fetchTotalPages.next(response.totalPages);
        this._currentPage.next(nextPage);
        this._nextPage.next(nextPage + 1);
        this._fetchNextPage.next(fetchNextPage + 1);
        this._count.next(response.count);
        this.prevPageCursors[fetchNextPage] = response.cursorPrev;
        this.nextPageCursors[fetchNextPage] = response.cursorNext;
        this.subscription = undefined;
      },
      () => {
        this._loading.next(false);
        this._currentPageImmediate.next(currentPage);
        this.subscription = undefined;
      }
    );
    this.subscription = subscription;

    return obs.pipe(
      map(() => {
        const fetchedResult = this._fetchItems.value
          ? this._fetchItems.value.slice(displayCount, displayCount + perPage)
          : [];
        return fetchedResult;
      })
    );
  }

  appendItems(appendItems: M[]) {
    const items = this._items.value != null ? this._items.value : [];
    items.push(...appendItems);
    this._items.next(items);
  }

  prependItems(prependItems: M[]) {
    const items = this._items.value != null ? this._items.value : [];
    items.unshift(...prependItems);
    this._items.next(items);
  }

  appendFetchItems(appendItems: M[]) {
    const items = this._fetchItems.value != null ? this._fetchItems.value : [];
    items.push(...appendItems);
    this._fetchItems.next(items);
  }

  getAll(): Observable<M[]> {
    const process = () => {
      if (!this.hasMore) {
        return of(this.items);
      }

      return this.getNext().pipe(switchMap(() => process()));
    };

    return process();
  }

  reset(page?: number) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this._loading.next(false);
    this._items.next(undefined);
    this._fetchItems.next(undefined);
    this._fetchHasMore.next(true);
    this._fetchTotalPages.next(0);
    this._count.next(undefined);

    if (page === undefined) {
      this._currentPage.next(1);
      this._fromPage.next(1);
      this._nextPage.next(1);
      this._fetchNextPage.next(1);
      this.prevPageCursors = {};
      this.nextPageCursors = {};
    } else {
      this._currentPage.next(page);
      this._fromPage.next(page);
      this._nextPage.next(page);
      this._fetchNextPage.next(page);
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';

import { twilioResourceParamsActionDescriptions } from '../../data/twilio/twilio-resource-params-action-descriptions.stub';
import { twilioResourceParamsParams } from '../../data/twilio/twilio-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface TwilioParamsOptions {
  account_sid: string;
  auth_token: string;
}

@Injectable()
export class TwilioGeneratorService extends ResourceGeneratorService<TwilioParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: TwilioParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://api.twilio.com/2010-04-01/Accounts.json';
    query.headers = [{ name: 'Authorization', value: `Basic ${this.authTokenFromOptions(options)}` }];

    return this.httpQueryService.request(query).pipe(
      map(response => {
        return { accountName: response.body['friendly_name'] };
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('Account SID or Auth Token is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<TwilioParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_BASIC_AUTH_VALUE,
        this.mode == AdminMode.Builder
      )
      .pipe(map(result => this.authTokenToOptions(result.value)));
  }

  authTokenFromOptions(options: TwilioParamsOptions): string {
    try {
      return btoa([options.account_sid, options.auth_token].join(':'));
    } catch (e) {}
  }

  authTokenToOptions(token: string): TwilioParamsOptions {
    try {
      const [accountSid, authToken] = atob(token).split(':', 2);
      return {
        account_sid: accountSid,
        auth_token: authToken
      };
    } catch (e) {
      return {
        account_sid: undefined,
        auth_token: undefined
      };
    }
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: TwilioParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = twilioResourceParamsParams;
    const actionDescriptions = twilioResourceParamsActionDescriptions;
    const extraTokens = {
      account_sid: options.account_sid
    };

    const token = new SecretToken();

    token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
    token.type = SecretTokenType.Static;
    token.value = this.authTokenFromOptions(options);

    return of({
      resourceParams: resourceParams,
      actionDescriptions: actionDescriptions,
      secretTokens: [token.serialize()],
      extraTokens: extraTokens
    });
  }
}

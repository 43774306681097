import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { isSet, ListStore, ListStoreFetchResponse } from '@shared';

import { CustomViewTemplate, CustomViewTemplateType } from '../data/custom-view-template';
import { CustomViewTemplateService } from '../services/custom-view-template/custom-view-template.service';

@Injectable()
export class CustomViewTemplateListStore extends ListStore<CustomViewTemplate> {
  featured: boolean;
  all = false;
  active: boolean;
  type: CustomViewTemplateType;
  params = {};

  constructor(private customViewTemplateService: CustomViewTemplateService) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    params[PER_PAGE_PARAM] = this.perPage;

    if (isSet(this.featured)) {
      params['featured'] = this.featured;
    }

    if (this.all) {
      params['all'] = '1';
    }

    if (isSet(this.active)) {
      params['active'] = this.active;
    }

    if (isSet(this.type)) {
      params['types'] = this.type;
    }

    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<CustomViewTemplate>> {
    const params = this.paramsForPage(page);

    return this.customViewTemplateService.get(params).pipe(
      map(response => {
        const perPage = this.perPage || response.perPage;
        return {
          items: response.results,
          hasMore: !!response.next,
          totalPages: response.numPages,
          perPage: perPage,
          count: response.count
        };
      })
    );
  }
}

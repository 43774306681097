<div class="dropdown2" (mousedown)="rootClick.emit($event)" (scroll)="onScroll()" #scrollable>
  <input
    *ngIf="searchEnabled"
    class="dropdown2__search"
    type="text"
    [formControl]="searchControl"
    [placeholder]="'Search...' | localize"
    [appAutofocus]="searchAutofocus"
  />

  <div class="dropdown2__options">
    <ng-content
      *ngIf="(!(source?.loading$ | async) && source?.loaded !== false) || (source?.options$ | async)?.length"
      select="ng-container[dropdown-top]"
    ></ng-content>

    <div *ngIf="!displayOptions.length && source && (source.loading$ | async)" class="dropdown2__message">
      {{ 'Loading...' | localize }}
    </div>

    <div *ngFor="let item of displayOptions" class="dropdown2__option" (click)="select(item)">
      <div *ngIf="item.icon" class="dropdown2__option-icon" [appIcon]="item.icon"></div>

      <div class="dropdown2__option-label">
        {{ item.name }}
      </div>

      <div *ngIf="item.selected" class="dropdown2__option-check icon-check_2"></div>
    </div>

    <ng-container *ngIf="displayOptions.length && source && source.loading$ | async">
      <div *ngFor="let item of ['option', 'last']" class="dropdown2__option dropdown2__option_disabled">
        <div class="dropdown2__option-label">
          <span [class.loading-animation]="true"
            ><span class="stub-text">{{ item }}</span></span
          >
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="!displayOptions.length && (!source || (source && !(source.loading$ | async)))"
      class="dropdown2__message"
    >
      <ng-container *ngIf="searchQuery$.value | appIsSet">
        <ng-container *ngIf="searchQuery$.value.length >= searchMinimumLength">
          <div>{{ 'Nothing found' | localize }}</div>
          <div *ngIf="addValueEnabled">
            <a href="javascript:void(0)" (click)="addValue.emit(searchQuery$.value)">Add "{{ searchQuery$.value }}"</a>
          </div>
        </ng-container>

        <ng-container *ngIf="searchQuery$.value.length < searchMinimumLength">
          {{ 'Enter at least {0} characters' | localize: searchMinimumLength }}
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!(searchQuery$.value | appIsSet)">
        {{ emptyPlaceholder }}
      </ng-container>
    </div>

    <ng-content
      *ngIf="(!(source?.loading$ | async) && source?.loaded !== false) || (source?.options$ | async)?.length"
      select="ng-container[dropdown-bottom]"
    ></ng-content>
  </div>
</div>

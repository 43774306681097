import pickBy from 'lodash/pickBy';

import { isSet } from '@shared';

import { Input } from './input';
import {
  defaultNumberFraction,
  defaultNumberFractionSeparator,
  defaultNumberThousandsSeparator,
  IValueFormat,
  NumberValueFormat
} from './value-format.interface';

export class ValueFormat implements IValueFormat {
  public prefix: string;
  public postfix: string;
  public numberFormat: NumberValueFormat;
  public numberFraction: number;
  public numberFractionSeparator: string;
  public numberThousandsSeparator: string;
  public formatInput: Input;

  constructor(options: Partial<ValueFormat> = {}) {
    if (options.prefix !== undefined) {
      this.prefix = options.prefix;
    }

    if (options.postfix !== undefined) {
      this.postfix = options.postfix;
    }

    if (options.numberFormat !== undefined) {
      this.numberFormat = options.numberFormat;
    }

    if (options.numberFraction !== undefined) {
      this.numberFraction = options.numberFraction;
    }

    if (options.numberFractionSeparator !== undefined) {
      this.numberFractionSeparator = options.numberFractionSeparator;
    }

    if (options.numberThousandsSeparator !== undefined) {
      this.numberThousandsSeparator = options.numberThousandsSeparator;
    }

    if (options.formatInput !== undefined) {
      this.formatInput = options.formatInput;
    }
  }

  deserialize(data: Object): ValueFormat {
    this.prefix = data['prefix'];
    this.postfix = data['postfix'];

    // Backward compatibility
    if (typeof data['number_format'] === 'string') {
      data['number_format'] = data['number_format'].toLowerCase();
    }

    this.numberFormat = data['number_format'];
    this.numberFraction = data['number_fraction'];
    this.numberFractionSeparator = data['number_fraction_separator'];
    this.numberThousandsSeparator = data['number_thousands_separator'];

    if (data['format_input']) {
      this.formatInput = new Input().deserialize(data['format_input']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      prefix: this.prefix,
      postfix: this.postfix,
      number_format: this.numberFormat,
      number_fraction: this.numberFraction,
      number_fraction_separator: this.numberFractionSeparator,
      number_thousands_separator: this.numberThousandsSeparator,
      format_input: this.formatInput ? this.formatInput.serialize() : undefined
    };

    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }

    return data;
  }

  isSet() {
    return (
      isSet(this.formatInput) ||
      isSet(this.prefix) ||
      isSet(this.postfix) ||
      isSet(this.numberFormat) ||
      (isSet(this.numberFraction) && this.numberFraction !== defaultNumberFraction) ||
      (isSet(this.numberFractionSeparator, true) && this.numberFractionSeparator !== defaultNumberFractionSeparator) ||
      (isSet(this.numberThousandsSeparator, true) && this.numberThousandsSeparator !== defaultNumberThousandsSeparator)
    );
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import {
  CustomizeService,
  ElementType,
  getModelAttributesByColumns,
  ModelElementItem,
  rawListViewSettingsColumnsToViewContextOutputs,
  registerElementComponent,
  ViewContextElement
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import {
  AggregateDisplayField,
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  LOADING_VALUE,
  LookupDisplayField,
  NOT_SET_VALUE,
  OptionsType
} from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { ascComparator, TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

export const ITEM_OUTPUT = 'item';

interface ElementState {
  element?: ModelElementItem;
  dataSource?: ModelDescriptionDataSource;
  params?: Object;
  staticData?: Object;
  modelDescription?: ModelDescription;
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
}

export function serializeDataSourceColumns(columns: DisplayField[]): Object[] {
  return columns
    .filter(item => [DisplayFieldType.Base, DisplayFieldType.Lookup, DisplayFieldType.Aggregate].includes(item.type))
    .map(item => {
      return {
        name: item.name,
        ...(item.field == FieldType.RelatedModel && item.params
          ? {
              custom_primary_key: item.params['custom_primary_key'],
              custom_display_field: item.params['custom_display_field'],
              custom_display_field_input: item.params['custom_display_field_input']
            }
          : {}),
        ...([FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton].includes(item.field) &&
        item.params &&
        item.params['options_type'] == OptionsType.Query
          ? {
              value_field: item.params['value_field'],
              label_field: item.params['label_field'],
              label_field_input: item.params['label_field_input']
            }
          : {}),
        ...(item instanceof LookupDisplayField
          ? {
              path: item.path
            }
          : {}),
        ...(item instanceof AggregateDisplayField
          ? {
              path: item.path,
              func: item.func,
              column: item.column
            }
          : {})
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

function getElementStateFetch(state: ElementState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    staticData: state.staticData,
    params: state.params,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet
  };
}

function getElementStateColumns(state: ElementState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

function getElementStateName(state: ElementState): Object {
  return {
    name: state.element ? state.element.name : undefined
  };
}

@Component({
  selector: 'app-model-element',
  templateUrl: './model-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewContextElement]
})
export class ModelElementComponent extends BaseElementComponent<ModelElementItem>
  implements OnInit, OnDestroy, OnChanges {
  firstVisible$ = new BehaviorSubject<boolean>(false);
  elementState: ElementState = {};

  loading = false;
  loadingSubscription: Subscription;
  model: Model;
  visibleColumns: DisplayField[] = [];
  title: string;
  titleSubscription: Subscription;
  stubRangeDefault = range(3);
  stubRange: any[] = this.stubRangeDefault;
  stubs = [
    {
      label: 'label',
      value: 'long value'
    },
    {
      label: 'long label',
      value: 'value'
    },
    {
      label: 'very long label',
      value: 'value'
    },
    {
      label: 'label',
      value: 'very long value'
    }
  ];

  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionService: ActionService,
    private modelDescriptionDataSourceService: ModelDescriptionDataSourceService,
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    public viewContextElement: ViewContextElement,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();
    this.trackModelUpdates();

    this.elementOnChange(this.element);
    this.trackChanges();
    this.initTitle();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ModelElementComponent>): void {
    if (changes.element) {
      this.initContext();
      this.elementOnChange(this.element);
      this.initTitle();
    }
  }

  trackChanges() {
    this.firstVisible$
      .pipe(
        first(value => value),
        switchMap(() => this.element$),
        switchMap(element => {
          return this.dataSourceGeneratorService
            .applyDataSourceDefaults<ModelDescriptionDataSource>(ModelDescriptionDataSource, element.dataSource)
            .pipe(
              map(dataSource => {
                element.dataSource = dataSource;
                return element;
              })
            );
        }),
        switchMap(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.elementState = state;
      });
  }

  getElementState(element: ModelElementItem): Observable<ElementState> {
    const staticData$ =
      element.dataSource && element.dataSource.type == DataSourceType.Input && element.dataSource.input
        ? applyParamInput$<Object>(element.dataSource.input, {
            context: this.context,
            defaultValue: {},
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});
    const dataSourceParams$ = element.dataSource
      ? applyParamInputs$({}, element.dataSource.queryInputs, {
          context: this.context,
          parameters: element.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, dataSourceParams$, this.getQueryModelDescription(element.dataSource)).pipe(
      map(([staticData, dataSourceParams, modelDescription]) => {
        return {
          element: element,
          dataSource: element.dataSource,
          staticData: staticData,
          params: dataSourceParams,
          modelDescription: modelDescription,
          inputsLoading: [dataSourceParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [dataSourceParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          })
        };
      })
    );
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.elementState))) {
      this.updateStubs(state);
      this.updateVisibleColumns(state);
      this.updateContextOutputs(state);
    }

    if (!isEqual(getElementStateName(state), getElementStateName(this.elementState))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
      this.fetch(state);
    }
  }

  fetch(state: ElementState) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = undefined;
    }

    if (!state.dataSource) {
      this.model = undefined;
      this.updateContextValue();
      this.cd.markForCheck();
      return;
    }

    if (state.inputsNotSet) {
      this.model = undefined;
      this.loading = false;
      this.cd.markForCheck();
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: true });

    if (state.inputsLoading) {
      return;
    }

    const queryOptions = paramsToGetQueryOptions(state.params);

    queryOptions.columns = state.dataSource ? state.dataSource.columns : undefined;

    this.loadingSubscription = this.modelDescriptionDataSourceService
      .getDetailAdv({
        project: this.currentProjectStore.instance,
        environment: this.currentEnvironmentStore.instance,
        dataSource: state.dataSource,
        queryOptions: queryOptions,
        staticData: state.staticData,
        context: this.context
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        model => {
          this.model = model;
          this.updateContextValue();
          this.loading = false;
          this.cd.markForCheck();

          this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        },
        () => {
          this.model = undefined;
          this.updateContextValue();
          this.loading = false;
          this.cd.markForCheck();

          this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        }
      );
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (!this.element.titleInput) {
      this.title = undefined;
      this.cd.markForCheck();
      return;
    }

    this.titleSubscription = applyParamInput$<string>(this.element.titleInput, {
      context: this.context,
      contextElement: this.viewContextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }

  getQueryModelDescription(dataSource: ModelDescriptionDataSource) {
    if (
      !dataSource ||
      !dataSource.query ||
      dataSource.query.queryType != QueryType.Simple ||
      !dataSource.query.simpleQuery
    ) {
      return of(undefined);
    }

    const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  reloadData() {
    this.fetch(this.elementState);
  }

  updateStubs(state: ElementState) {
    this.stubRange =
      state.dataSource && state.dataSource.columns.length
        ? range(state.dataSource.columns.filter(item => item.visible).length)
        : this.stubRangeDefault;
    this.cd.markForCheck();
  }

  updateVisibleColumns(state: ElementState) {
    this.visibleColumns = state.dataSource ? state.dataSource.columns.filter(item => item.visible) : [];
    this.cd.markForCheck();
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'pages',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
    this.viewContextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      }
    ]);
  }

  updateContextInfo(state: ElementState) {
    this.viewContextElement.initInfo(
      {
        name: state.element.name,
        getFieldValue: (field, outputs) => {
          return outputs[ITEM_OUTPUT] ? outputs[ITEM_OUTPUT][field] : undefined;
        }
      },
      true
    );
  }

  updateContextOutputs(state: ElementState) {
    const children = state.dataSource
      ? rawListViewSettingsColumnsToViewContextOutputs(
          state.dataSource.columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      : [];

    this.viewContextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Record',
        icon: 'duplicate_2',
        allowSkip: true,
        external: true,
        children: children
      }
    ]);
  }

  updateContextValue() {
    const output =
      this.model && this.elementState.dataSource
        ? getModelAttributesByColumns(this.model, this.elementState.dataSource.columns)
        : undefined;

    this.viewContextElement.setOutputValue(ITEM_OUTPUT, output);
    // Backward compatibility
    this.viewContextElement.setOutputValue('model', output);
  }

  trackModelUpdates() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (isModelUpdateEventMatch(e, this.elementState.modelDescription, this.model)) {
        this.model = patchModel(this.model, e.model);
        this.cd.markForCheck();
        this.updateContextValue();
      }
    });
  }
}

registerElementComponent({
  type: ElementType.Model,
  component: ModelElementComponent,
  alwaysActive: false,
  label: 'Detail',
  actions: []
});

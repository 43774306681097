var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ViewContextElement } from '@modules/customize';
import { AutoElementComponent } from '@modules/customize-elements';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { LayerInteractionType, LayerType } from '@modules/views';
import { registerLayerComponent } from '../../../data/layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';
var ElementLayerComponent = /** @class */ (function (_super) {
    __extends(ElementLayerComponent, _super);
    function ElementLayerComponent(editorContext, contextElement, injector, sanitizer, cd) {
        var _this = _super.call(this, editorContext) || this;
        _this.contextElement = contextElement;
        _this.injector = injector;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        return _this;
    }
    ElementLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.editorContext) {
            this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
            this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
            this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
            this.updating$ = this.getLayerUpdating$(function () { return !_this.editorContext.isCreateTool(); });
            this.trackLayerFluidSize(this.layerElement.nativeElement)
                .pipe(untilDestroyed(this))
                .subscribe(function () { return _this.cd.markForCheck(); });
        }
        this.getLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.updateElement();
            _this.updateLayerContext();
            _this.cd.markForCheck();
        });
    };
    ElementLayerComponent.prototype.ngOnDestroy = function () { };
    ElementLayerComponent.prototype.updateElement = function () {
        if (this.autoElementComponent) {
            this.autoElementComponent.updateConfigured();
            this.autoElementComponent.updateVisible();
            this.autoElementComponent.updateElement({ forceUpdate: true });
        }
    };
    ElementLayerComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: this.layer.id,
                name: this.layer.name,
                icon: this.layer.icon
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: this.layer.name,
                icon: this.layer.icon
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    return ElementLayerComponent;
}(LayerComponent));
export { ElementLayerComponent };
registerLayerComponent(LayerType.Element, ElementLayerComponent);

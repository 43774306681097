<div
  href="javascript:void(0)"
  class="notification"
  [ngClass]="['notification_type_' + notification.type]"
  (click)="close()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  #root
>
  <div *ngIf="notification.icon" class="notification__left">
    <div
      class="notification__icon"
      [appIcon]="notification.icon"
      [style.color]="(notification.color | appIsColorHex) ? notification.color : null"
    ></div>
  </div>

  <div class="notification__main">
    <div *ngIf="notification.title | appIsSet" class="notification__title">{{ notification.title }}</div>
    <div
      *ngIf="notification.description | appIsSet"
      class="notification__description"
      [innerHTML]="notification.description"
    ></div>
  </div>

  <div
    class="notification__badge"
    [style.background-color]="(notification.color | appIsColorHex) ? notification.color : null"
  ></div>

  <div class="notification__close icon-close"></div>
</div>

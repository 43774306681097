import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService, patchModel } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewsStore } from '@modules/custom-views';
import {
  CustomizeService,
  getModelAttributesByColumns,
  getModelBulkAttributesByColumns,
  GridSettings,
  ListDefaultSelection,
  ListGroup,
  rawListViewSettingsColumnsToViewContextOutputs,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import {
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  LOADING_VALUE,
  NOT_SET_VALUE
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  CHECKED_ITEMS_OUTPUT,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { Model, NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { View } from '@modules/views';
import { isSet } from '@shared';

import { GridGroupComponent } from '../grid-group/grid-group.component';
import {
  getListStateColumns,
  getListStateDefaultSelection,
  getListStateFetch,
  getListStateFetchNewParams,
  getListStateSelection,
  GridState
} from './grid-state';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent extends ListLayoutComponent<GridSettings, GridState>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(GridGroupComponent) groupComponents = new QueryList<GridGroupComponent>();
  @ViewChild('viewport_element') viewportElement: ElementRef;

  layout = ListLayoutType.Grid;
  viewportWidth$ = new BehaviorSubject<number>(undefined);
  cardView$ = new BehaviorSubject<View>(undefined);
  visibleColumns: DisplayField[] = [];
  loading = true;
  error: string;
  configured = true;
  selectedItem: ListItem;
  checkedItems: { [k: string]: Model } = {};
  gridWidth: number;
  rowCards = 1;
  cardDefaultHeight = 245;

  constructor(
    public customizeService: CustomizeService,
    public customViewsStore: CustomViewsStore,
    private actionControllerService: ActionControllerService,
    private notificationService: NotificationService,
    injector: Injector,
    cd: ChangeDetectorRef,
    customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initContext();

    this.settings$
      .pipe(
        filter(settings => !!settings),
        switchMap(settings => {
          if (!isSet(settings.cardCustomView)) {
            return of(undefined);
          }

          return this.customViewsStore.getDetail(settings.cardCustomView);
        }),
        untilDestroyed(this)
      )
      .subscribe(customView => {
        this.cardView$.next(customView ? customView.view : undefined);
      });

    combineLatest(this.settings$, this.cardView$, this.viewportWidth$)
      .pipe(untilDestroyed(this))
      .subscribe(([settings, cardView, viewportWidth]) => {
        this.rowCards = this.getRowCards(settings, cardView, viewportWidth);
        this.cardDefaultHeight = cardView ? cardView.frame.height : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getListState(
    settings: GridSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<GridState> {
    const page = parseInt(params[PAGE_PARAM], 10) || 1;

    params = cloneDeep(params);

    delete params[NEXT_PAGE_SCROLL_PARAM];

    if (!sort.length && isSet(settings.sortingField)) {
      sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
    }

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(
      map(([staticData, inputParams, modelDescription]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings.dataSource,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          groupCollapse: settings.groupCollapse,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          page: page,
          perPage: settings ? settings.perPage : undefined,
          sortingField: settings ? settings.sortingField : undefined,
          sortingAsc: settings ? settings.sortingAsc : undefined,
          groupField: settings ? settings.groupField : undefined,
          groups: settings ? settings.groups : undefined,
          multipleSelection: settings.multipleSelection,
          defaultSelection: settings ? settings.defaultSelection : undefined
        };
      })
    );
  }

  onStateUpdated(state: GridState) {
    if (
      !isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
      !isEqual(getListStateSelection(state), getListStateSelection(this.listState))
    ) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
      let paramsNeedUpdate = false;

      if (newParams) {
        this.setSelectedItem(undefined, false);
        this.setChecked([], false);
        this.updateSelectedContext();

        if (this.setPage(1)) {
          paramsNeedUpdate = true;
        }
      }

      if (!paramsNeedUpdate) {
        this.fetch(state);
      }
    } else {
      if (
        !isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
        state.defaultSelection == ListDefaultSelection.First
      ) {
        const component = this.groupComponents.toArray()[0];
        const componentItems = component ? component.getItems() : undefined;
        this.setSelectedItem(componentItems ? componentItems[0] : undefined);
      }
    }
  }

  updateLoading() {
    this.loading = this.groupComponents.toArray().some(item => item.loading);
    this.cd.markForCheck();
  }

  getStateQueryOptions(state: GridState): GetQueryOptions {
    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);

    queryOptions.filters = [...queryOptions.filters, ...state.filters];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    return queryOptions;
  }

  fetch(state: GridState) {
    this.configured = state.dataSource && state.dataSource.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.selectedItem = undefined;
    this.checkedItems = {};
    this.cd.markForCheck();
  }

  onFetch() {
    this.loading = true;
    this.setChecked([]);
    this.cd.markForCheck();

    this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
  }

  onFetched(items: ListItem[]) {
    if (this.selectedItem && items) {
      this.checkSelectedModelIsActual(items);
    }

    this.updateLoading();

    if (!this.loading) {
      const isEmpty = this.groupComponents.toArray().every(item => {
        const groupItems = item.getItems();
        return groupItems && !groupItems.length;
      });
      this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
    }
  }

  reloadData() {
    super.reloadData();
    this.groupComponents.forEach(item => item.reloadData());
  }

  getRowCardMinWidth(cardView: View): number {
    if (cardView && cardView.widthResize.enabled && isSet(cardView.widthResize.min)) {
      return cardView.widthResize.min;
    } else if (cardView && isSet(cardView.frame.width)) {
      return cardView.frame.width;
    } else {
      return 240;
    }
  }

  getRowCards(settings: GridSettings, cardView: View, viewportWidth: number) {
    const cardMinWidth = this.getRowCardMinWidth(cardView);
    const rowMaxCards = isSet(viewportWidth) ? Math.max(Math.floor(viewportWidth / cardMinWidth), 1) : 1;
    return settings && isSet(settings.cardsPerRow) && settings.cardsPerRow <= rowMaxCards
      ? settings.cardsPerRow
      : rowMaxCards;
  }

  trackGroupFn(i, item: ListGroup) {
    return item.uid;
  }

  updateVisibleColumns(state: GridState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Card',
        icon: 'deselect',
        parameters: [],
        handler: () => {
          this.setSelectedItem(undefined);
          this.setChecked([]);
        }
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetFilters()
      }
    ]);
  }

  updateContextOutputs(state: GridState) {
    const columns = state.dataSource ? state.dataSource.columns : [];

    this.contextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Card',
        icon: 'duplicate_2',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: rawListViewSettingsColumnsToViewContextOutputs(
          columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Card',
        icon: 'hand',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Card selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Card selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      ...(state.multipleSelection
        ? [
            {
              uniqueName: CHECKED_ITEMS_OUTPUT,
              name: 'Checked Cards',
              icon: 'check',
              children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
            }
          ]
        : [])
    ]);

    this.updateSelectedContext();
  }

  updateSelectedContext() {
    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    if (this.selectedItem) {
      this.contextElement.setOutputValue(
        SELECTED_ITEM_OUTPUT,
        getModelAttributesByColumns(this.selectedItem.model, columns)
      );
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }

    if (this.settings.multipleSelection) {
      const models: Model[] = values(this.checkedItems);
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
    } else {
      this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
    }
  }

  isItemSelected(item: ListItem, group: number, index: number) {
    if (this.settings.multipleSelection) {
      return this.isItemChecked(item, group, index);
    } else {
      return this.itemEquals(this.selectedItem, item);
    }
  }

  isItemChecked(item: ListItem, group: number, index: number) {
    const pk = item.model.primaryKey || `${index}`;
    return this.checkedItems[pk];
  }

  setSelectedItem(item: ListItem, updateContext = true) {
    this.selectedItem = item;
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  setChecked(value: ListItem[], updateContext = true) {
    this.checkedItems = value.reduce((acc, item) => {
      const pk = item.model.primaryKey;
      acc[pk] = item.model;
      return acc;
    }, {});
    this.cd.markForCheck();

    if (updateContext) {
      this.updateSelectedContext();
    }
  }

  toggleSelectedItem(item: ListItem, group: number, index: number, element: HTMLElement, click = false) {
    if (this.selectedItem === item) {
      this.setSelectedItem(undefined, false);
    } else {
      this.setSelectedItem(item, false);
    }

    if (this.settings.multipleSelection) {
      const pk = item.model.primaryKey || `${group}_${index}`;
      const checked = this.isItemChecked(item, group, index);

      if (!checked) {
        const checkedItems = clone(this.checkedItems);
        checkedItems[pk] = item.model;
        this.checkedItems = checkedItems;
      } else {
        const checkedItems = clone(this.checkedItems);
        delete checkedItems[pk];
        this.checkedItems = checkedItems;
      }
    }

    this.updateSelectedContext();

    if (click && this.settings.cardClickAction && this.selectedItem) {
      this.actionControllerService
        .execute(this.settings.cardClickAction, {
          context: this.contextElement.context,
          contextElement: this.contextElement,
          localContext: {
            [ITEM_OUTPUT]: this.selectedItem.model.getAttributes()
          },
          injector: this.injector,
          origin: element
        })
        .subscribe();
    }
  }

  onModelUpdated(model: Model) {
    if (this.selectedItem && this.selectedItem.model.isSame(model)) {
      this.updateSelectedContext();
    }

    const checkedModels: Model[] = values(this.checkedItems);

    if (checkedModels.some(item => item.isSame(model))) {
      this.updateSelectedContext();
    }
  }

  onResize() {
    const width = this.viewportElement ? this.viewportElement.nativeElement.offsetWidth : undefined;
    this.viewportWidth$.next(width);
  }

  public getAnyModel(): Model {
    for (const component of this.groupComponents.toArray()) {
      const items = component.getItems();
      if (items && items.length) {
        return items[0].model;
      }
    }
  }

  checkSelectedModelIsActual(items: ListItem[]) {
    const actualSelectedItem = items.find(item => item.model.isSame(this.selectedItem.model));

    if (
      actualSelectedItem &&
      !isEqual(actualSelectedItem.model.getAttributes(), this.selectedItem.model.getAttributes())
    ) {
      this.selectedItem.model = patchModel(this.selectedItem.model, actualSelectedItem.model);
      this.cd.markForCheck();
      this.onModelUpdated(this.selectedItem.model);
    }
  }
}

<app-popup2 [size]="'l'">
  <app-popup2-close [outside]="true" (click)="close()"></app-popup2-close>

  <div class="choose-template">
    <ng-container *ngIf="section == sections.Templates">
      <div class="choose-template__content">
        <div *ngIf="false" class="choose-template__content-navigation">
          <div class="choose-template__content-navigation-inner">
            <div class="choose-template__content-navigation-header">
              <!--            <div class="choose-template__content-navigation-title">Choose a Template</div>-->
              <input
                type="text"
                class="choose-template-input"
                [placeholder]="'Search templates...' | localize"
                [(ngModel)]="templateSearch"
                (keyup)="templateSearchUpdated.next()"
                [appAutofocus]="true"
              />
            </div>

            <div class="choose-template__content-navigation-list">
              <div class="choose-template-list">
                <ng-container *ngIf="!loading">
                  <div *ngFor="let item of templatesFiltered" class="choose-template-list__item">
                    <div
                      class="choose-template-list-item choose-template-list-item_interactive"
                      [class.choose-template-list-item_orange]="true"
                      [class.choose-template-list-item_active]="selectedTemplate === item"
                    >
                      <div class="choose-template-list-item__main">{{ item.name }}</div>
                      <div *ngIf="item.type == templateItemTypes.AdminPanel" class="choose-template-list-item__right">
                        <!--                      any resource-->
                        <span class="choose-template-list-item__tag">{{ 'any' | localize }}</span>
                      </div>
                      <div *ngIf="item.type == templateItemTypes.Template" class="choose-template-list-item__right">
                        <span
                          *ngFor="let resource of item.template.forResources.slice(0, 3)"
                          class="choose-template-list-item__icon"
                          [style.background-image]="
                            '/assets/images/resources/icons/' + resource.typeItem.icon + '.svg' | appDeployCssUrl
                          "
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!templatesFiltered?.length" class="choose-template-list__message">
                    {{ 'Nothing found' | localize }}
                  </div>
                </ng-container>

                <ng-container *ngIf="loading">
                  <div
                    *ngFor="
                      let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']
                    "
                    class="choose-template-list__item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="choose-template-list-item"
                      [class.choose-template-list-item_orange]="true"
                    >
                      <div class="choose-template-list-item__main">
                        <span [class.loading-animation]="true"
                          ><span class="stub-text">{{ item }}</span></span
                        >
                      </div>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!--        <div class="choose-template__content-navigation-footer">-->
          <!--          <button type="button" class="button button_fill button_orange-transparent" id="request_template">-->
          <!--            <span class="icon button__icon_left icon-magic_wand button__icon"></span>-->
          <!--            <span class="button__label">Suggest a Template</span>-->
          <!--            <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>-->
          <!--          </button>-->
          <!--        </div>-->
        </div>

        <div class="choose-template__content-main">
          <div *ngIf="selectedTemplate" class="choose-template__detail">
            <div class="choose-template__detail-header">
              <div class="choose-template__detail-header-top">
                <div class="choose-template__detail-header-left">
                  <app-project-logo
                    [color]="selectedTemplate.color"
                    [logo]="selectedTemplate.logo"
                    [icon]="selectedTemplate.icon ? 'icon-' + selectedTemplate.icon : undefined"
                    [initials]="selectedTemplate.name | appInitials"
                    [fill]="selectedTemplate.logoFill"
                    [size]="selectedTemplate.tags.length || true ? 's' : 'xs'"
                    [borderRadius]="'s'"
                  ></app-project-logo>
                </div>

                <div class="choose-template__detail-header-main">
                  <div class="choose-template__detail-title">
                    {{ selectedTemplate.name }}
                  </div>

                  <div *ngIf="selectedTemplate.subtitle" class="choose-template__detail-subtitle">
                    {{ selectedTemplate.subtitle }}
                  </div>

                  <div *ngIf="false && selectedTemplate.tags.length" class="choose-template__detail-tags">
                    <span
                      *ngFor="let item of selectedTemplate.tags.slice(0, tagsMaximum)"
                      class="choose-template-tag choose-template__detail-tags-item"
                      [ngClass]="[
                        !(item.color | appIsColorHex) ? 'color_' + item.color + '_1' : '',
                        !(item.color | appIsColorHex) ? 'border-color_' + item.color + '_1' : ''
                      ]"
                      [style.color]="(item.color | appIsColorHex) ? item.color : null"
                      [style.border-color]="(item.color | appIsColorHex) ? item.color : null"
                    >
                      {{ item.name }}
                    </span>
                    <span
                      *ngIf="selectedTemplate.tags.length > tagsMaximum"
                      class="choose-template-tag choose-template__detail-tags-item"
                    >
                      + {{ selectedTemplate.tags.length - tagsMaximum }} {{ 'more' | localize }}
                    </span>
                  </div>
                </div>

                <div class="choose-template__detail-header-right">
                  <div
                    *ngIf="selectedTemplate.type == templateItemTypes.AdminPanel"
                    class="choose-template__detail-submit-item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="button button_primary button_no-shadow"
                      [class.button_disabled]="!selectedTemplate"
                      (click)="navigate(sections.Resources)"
                    >
                      <span class="button__label">{{ 'Choose Data Source' | localize }}</span>
                      <span class="button__icon button__icon_right icon-arrow_forward_2"></span>
                    </a>
                    <div class="choose-template__button-tip">
                      {{ 'works with 30+ data sources' | localize }}
                    </div>
                  </div>

                  <div
                    *ngIf="selectedTemplate.type == templateItemTypes.Template"
                    class="choose-template__detail-submit-item"
                  >
                    <a
                      href="javascript:void(0)"
                      class="button button_primary button_no-shadow"
                      [class.button_disabled]="!selectedTemplate"
                      (click)="applyTemplate(selectedTemplate)"
                    >
                      <span class="button__icon button__icon_left icon-magic_wand"></span>
                      <span class="button__label">{{ 'Use this template' | localize }}</span>
                    </a>
                    <div class="choose-template__button-tip">
                      {{ 'stores data in Jet Tables' | localize }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="false && selectedTemplate.description" class="choose-template__detail-header-bottom">
                <div class="choose-template__detail-description">{{ selectedTemplate.description }}</div>
              </div>
            </div>

            <div class="choose-template__detail-image choose-template__detail-image_light choose-template-video-bounds">
              <ng-container *ngIf="selectedTemplate.images[0] as image" [ngSwitch]="isVideoUrl(image.image)">
                <div *ngSwitchCase="true" class="choose-template-video choose-template-video_padding">
                  <video class="choose-template-video__inner" [src]="image.image" autoplay loop muted></video>
                </div>
                <div
                  *ngSwitchCase="false"
                  class="choose-template-image"
                  [style.background-image]="'url(\'' + (image.image | appEncodeURI) + '\')' | appSafeStyle"
                ></div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="section == sections.Resources">
      <div class="choose-template__row">
        <div class="choose-template__detail-header">
          <div class="choose-template__detail-header-top">
            <div class="choose-template__detail-header-left">
              <app-project-logo
                [color]="selectedTemplate.color"
                [logo]="selectedTemplate.logo"
                [icon]="selectedTemplate.icon ? 'icon-' + selectedTemplate.icon : undefined"
                [initials]="selectedTemplate.name | appInitials"
                [fill]="selectedTemplate.logoFill"
                [size]="selectedTemplate.tags.length || true ? 's' : 'xs'"
                [borderRadius]="'s'"
              ></app-project-logo>
            </div>

            <div class="choose-template__detail-header-main">
              <div class="choose-template__detail-title">
                <a href="javascript:void(0)" (click)="navigateBack()">{{ selectedTemplate.name }}</a>
                <span class="choose-template__detail-title-separator icon-arrow_forward_2"></span>
                {{ 'Choose Data Source' | localize }}
              </div>

              <div *ngIf="selectedTemplate.subtitle" class="choose-template__detail-subtitle">
                {{ selectedTemplate.subtitle }}
              </div>
            </div>

            <div class="choose-template__detail-header-right" style="height: 56.5px;"></div>
          </div>
        </div>
      </div>

      <div class="choose-template__content">
        <div class="choose-template__select-item">
          <ng-template #custom_resource>
            <div class="select-item-grid__item">
              <a
                href="javascript:void(0)"
                class="select-item-grid-item select-item-grid-item_contrast"
                (click)="setSelectedResource(customResource); applyAdminPanelTemplate(customResource)"
              >
                <span class="select-item-grid-item__image">
                  <span class="select-item-grid-item__image-icon icon-plus"></span>
                </span>
                <span class="select-item-grid-item__title">
                  {{ 'New Data' | localize }}
                  <span
                    *ngIf="customResource.icon"
                    class="select-item-grid-item__subtitle-logo"
                    [style.background-image]="
                      '/assets/images/resources/icons/' + customResource.icon + '.svg' | appDeployCssUrl
                    "
                  >
                  </span>
                </span>
                <span class="select-item-grid-item__subtitle">{{
                  'using {0}' | localize: [customResource.label]
                }}</span>
              </a>
            </div>
          </ng-template>

          <div class="select-item">
            <!--          <div class="select-item__header">-->
            <!--            <div class="select-item__title">Choose Resource</div>-->
            <!--            <div class="select-item__description">Integrate with Database or 3rd party APIs</div>-->
            <!--          </div>-->
            <div class="select-item__main">
              <div class="select-item__main-navigation">
                <div class="select-item-input select-item__main-navigation-input">
                  <input
                    type="text"
                    class="select-item-input__control"
                    [placeholder]="'Search...' | localize"
                    [(ngModel)]="resourceSearch"
                    [appAutofocus]="true"
                    (keyup)="resourceSearchUpdated.next(); onSearchKey($event)"
                  />

                  <span class="select-item-input__icon icon-search"></span>
                  <span
                    *ngIf="resourceSearch | appIsSet"
                    class="select-item-input__close icon-close"
                    (click)="clearSearch()"
                  ></span>
                </div>

                <div class="select-item__main-navigation-list">
                  <div class="select-item-list">
                    <ng-container *ngFor="let item of resourceFilters; let i = index">
                      <div *ngIf="resourceFilterCounts[i]" class="select-item-list__item">
                        <a
                          href="javascript:void(0)"
                          class="select-item-list-item"
                          [class.select-item-list-item_orange]="false"
                          [class.select-item-list-item_active]="resourceFilterIndex == i"
                          (click)="setResourceFilterIndex(i)"
                        >
                          <span class="select-item-list-item__main">{{ item.label }}</span>
                          <span class="select-item-list-item__right">{{ resourceFilterCounts[i] }}</span>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="select-item__main-content">
                <div class="select-item-grid">
                  <ng-container *ngIf="resourcesFiltered?.length">
                    <ng-container *ngTemplateOutlet="custom_resource"></ng-container>
                  </ng-container>

                  <div *ngFor="let item of resourcesFiltered" class="select-item-grid__item">
                    <a
                      href="javascript:void(0)"
                      class="select-item-grid-item"
                      [class.select-item-grid-item_orange]="false"
                      [class.select-item-grid-item_active]="selectedResource === item"
                      (click)="setSelectedResource(item); applyAdminPanelTemplate(item)"
                    >
                      <span class="select-item-grid-item__image">
                        <span
                          class="select-item-grid-item__image-inner"
                          [style.background-image]="
                            '/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl
                          "
                        >
                        </span>
                      </span>
                      <span class="select-item-grid-item__title">{{ item.label }}</span>
                      <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
                      <span *ngIf="item.beta" class="select-item-grid-item__tag background-color_orange_1">
                        <span class="select-item-grid-item__tag-raw">β</span>eta
                      </span>
                    </a>
                  </div>
                  <ng-container *ngIf="resourceSearch && !resourcesFiltered?.length">
                    <div class="select-item-grid__message">
                      <div>
                        {{ "Can't find resource you're looking for?" | localize }}
                      </div>
                      <div>
                        You can integrate any <strong>Rest API</strong>, <strong>GraphQL</strong> or
                        <strong>create new data</strong> using the options below:
                      </div>
                    </div>

                    <ng-container *ngTemplateOutlet="custom_resource"></ng-container>

                    <div *ngFor="let item of resourcesNotFound" class="select-item-grid__item">
                      <a
                        href="javascript:void(0)"
                        class="select-item-grid-item"
                        [class.select-item-grid-item_orange]="false"
                        [class.select-item-grid-item_active]="selectedResource === item"
                        (click)="setSelectedResource(item); applyAdminPanelTemplate(item)"
                      >
                        <span class="select-item-grid-item__image">
                          <span
                            class="select-item-grid-item__image-inner"
                            [style.background-image]="
                              '/assets/images/resources/' + item.image + '-color.svg' | appDeployCssUrl
                            "
                          >
                          </span>
                        </span>
                        <span class="select-item-grid-item__title">{{ item.label }}</span>
                        <span class="select-item-grid-item__subtitle">{{ item.shortDescription }}</span>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-popup2>

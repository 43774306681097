import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlErrorsPipe } from './pipes/control-errors/control-errors.pipe';
import { FormErrorsPipe } from './pipes/form-errors/form-errors.pipe';
import { FormFieldErrorsPipe } from './pipes/form-field-errors/form-field-errors.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ControlErrorsPipe, FormErrorsPipe, FormFieldErrorsPipe, FormErrorsPipe],
  exports: [ControlErrorsPipe, FormErrorsPipe, FormFieldErrorsPipe, FormErrorsPipe]
})
export class FormUtilsModule {}

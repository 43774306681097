import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

import { FeatureOverviewPopupComponent } from '../../components/feature-overview-popup/feature-overview-popup.component';

@Injectable()
export class FeatureService {
  pro = ['permissions', 'flex-views', 'sql-charts', 'sso'];
  unavailable = ['collaboration'];

  constructor(
    private currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private popupService: PopupService,
    private injector: Injector
  ) {}

  isFeatureActive(feature: string): Observable<boolean> {
    const project = this.currentProjectStore.instance;
    return this.currentUserStore.getFirst().pipe(
      map(user => {
        if (!user) {
          return false;
        }

        return user.params.isProjectFeatureActivated(project.uniqueName, feature);
      })
    );
  }

  activateFeature(feature: string): Observable<boolean> {
    const project = this.currentProjectStore.instance;

    if (!this.currentUserStore.instance.params.addActivatedProjectFeature(project.uniqueName, feature)) {
      return of(false);
    }

    return this.currentUserStore.update(['params']).pipe(map(() => true));
  }

  isFeaturePro(feature: string): boolean {
    return this.pro.includes(feature);
  }

  isFeatureAvailable(feature: string): boolean {
    return !this.unavailable.includes(feature);
  }

  showFeatureOverview(options: { subtitle?: string; title: string; description?: string }) {
    this.popupService.push({
      component: FeatureOverviewPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        subtitle: options.subtitle,
        title: options.title,
        description: options.description
      },
      injector: this.injector
    });
  }
}

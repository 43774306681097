import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RoutingService } from '@modules/routing';
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/services/routing/routing.service";
var LicenseCheckInterceptor = /** @class */ (function () {
    function LicenseCheckInterceptor(routing) {
        this.routing = routing;
    }
    LicenseCheckInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(catchError(function (error) {
            if (error instanceof HttpErrorResponse &&
                error.status == 400 &&
                error.error &&
                error.error['license_invalid']) {
                var queryParams = {
                    error: error.error['license_invalid']['error'],
                    error_description: error.error['license_invalid']['error_description']
                };
                _this.routing.navigate(['/license-not-valid'], { queryParams: queryParams, skipLocationChange: true });
            }
            return throwError(error);
        }));
    };
    LicenseCheckInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function LicenseCheckInterceptor_Factory() { return new LicenseCheckInterceptor(i0.inject(i1.RoutingService)); }, token: LicenseCheckInterceptor, providedIn: "root" });
    return LicenseCheckInterceptor;
}());
export { LicenseCheckInterceptor };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../view-editor-parameters-value-item/view-editor-parameters-value-item.component.ngfactory";
import * as i2 from "../view-editor-parameters-value-item/view-editor-parameters-value-item.component";
import * as i3 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i4 from "ng-gxscrollable";
import * as i5 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i6 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i7 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i8 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i9 from "@angular/common";
import * as i10 from "../../../field-components/components/parameters-edit/parameters-edit.component.ngfactory";
import * as i11 from "../../../field-components/components/parameters-edit/parameters-edit.component";
import * as i12 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i13 from "./view-editor-parameters.form";
import * as i14 from "./view-editor-parameters.component";
import * as i15 from "../../../../common/popups/components/base-popup/base-popup.component";
var styles_ViewEditorParametersComponent = [];
var RenderType_ViewEditorParametersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorParametersComponent, data: {} });
export { RenderType_ViewEditorParametersComponent as RenderType_ViewEditorParametersComponent };
function View_ViewEditorParametersComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-view-editor-parameters-value-item", [], null, [[null, "valueUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueUpdated" === en)) {
        var pd_0 = (_co.onStateValueChange(_co.SELECTED_OUTPUT, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ViewEditorParametersValueItemComponent_0, i1.RenderType_ViewEditorParametersValueItemComponent)), i0.ɵdid(2, 770048, null, 0, i2.ViewEditorParametersValueItemComponent, [i0.ChangeDetectorRef], { title: [0, "title"], icon: [1, "icon"], fieldType: [2, "fieldType"], value: [3, "value"], collapseContext: [4, "collapseContext"] }, { valueUpdated: "valueUpdated" }), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.componentLabel)) + " is selected"); var currVal_1 = "select"; var currVal_2 = _co.fieldTypes.Boolean; var currVal_3 = _co.testState[_co.SELECTED_OUTPUT]; var currVal_4 = _co.stateCollapseContext; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ViewEditorParametersComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.CapitalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "view-editor__data-menu"]], [[2, "view-editor__data-menu_visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markClickEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "view-editor__data-menu-viewport"], ["xsScrollable", ""]], null, null, null, null, null)), i0.ɵdid(3, 12664832, null, 0, i4.ScrollableDirective, [i0.ElementRef], { xsScrollableOptions: [0, "xsScrollableOptions"] }, null), i0.ɵpod(4, { stopPropagationAlways: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 16, "app-sidebar-section", [], null, null, null, i5.View_SidebarSectionComponent_0, i5.RenderType_SidebarSectionComponent)), i0.ɵdid(6, 114688, null, 0, i6.SidebarSectionComponent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, 3, 8, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 7, "app-sidebar-field", [], null, null, null, i7.View_SidebarFieldComponent_0, i7.RenderType_SidebarFieldComponent)), i0.ɵdid(9, 114688, null, 0, i8.SidebarFieldComponent, [], { label: [0, "label"], subtitle: [1, "subtitle"] }, null), (_l()(), i0.ɵeld(10, 0, null, 3, 5, "div", [["class", "sidebar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorParametersComponent_1)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 2, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "app-view-editor-parameters-value-item", [], null, [[null, "valueUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueUpdated" === en)) {
        var pd_0 = (_co.onStateValueChange(_co.DARK_THEME_OUTPUT, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ViewEditorParametersValueItemComponent_0, i1.RenderType_ViewEditorParametersValueItemComponent)), i0.ɵdid(15, 770048, null, 0, i2.ViewEditorParametersValueItemComponent, [i0.ChangeDetectorRef], { title: [0, "title"], icon: [1, "icon"], fieldType: [2, "fieldType"], value: [3, "value"], collapseContext: [4, "collapseContext"] }, { valueUpdated: "valueUpdated" }), (_l()(), i0.ɵeld(16, 0, null, 3, 5, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 4, "app-sidebar-field", [], null, null, null, i7.View_SidebarFieldComponent_0, i7.RenderType_SidebarFieldComponent)), i0.ɵdid(18, 114688, null, 0, i8.SidebarFieldComponent, [], { label: [0, "label"], subtitle: [1, "subtitle"] }, null), (_l()(), i0.ɵeld(19, 0, null, 3, 2, "app-parameters-edit", [], null, [[null, "parameterValueUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("parameterValueUpdated" === en)) {
        var pd_0 = (_co.form.updateTestParametersValue($event.name, $event.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ParametersEditComponent_0, i10.RenderType_ParametersEditComponent)), i0.ɵdid(20, 245760, null, 0, i11.ParametersEditComponent, [i12.UniversalAnalyticsService, i0.ChangeDetectorRef], { control: [0, "control"], configurable: [1, "configurable"], parametersValueEditable: [2, "parametersValueEditable"], parametersValue: [3, "parametersValue"], placeholder: [4, "placeholder"], emptyBanner: [5, "emptyBanner"], analyticsSource: [6, "analyticsSource"] }, { parameterValueUpdated: "parameterValueUpdated" }), i0.ɵpod(21, { name: 0, field: 1, required: 2, add: 3, sortable: 4 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_1); _ck(_v, 6, 0); var currVal_2 = "Component State"; var currVal_3 = "You can specify test values below"; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.stateSelectedEnabled; _ck(_v, 12, 0, currVal_4); var currVal_5 = "Dark theme"; var currVal_6 = "toggle_theme"; var currVal_7 = _co.fieldTypes.Boolean; var currVal_8 = _co.testState[_co.DARK_THEME_OUTPUT]; var currVal_9 = _co.stateCollapseContext; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = "Component Parameters"; var currVal_11 = "You can customize parameters and specify test values below"; _ck(_v, 18, 0, currVal_10, currVal_11); var currVal_12 = _co.form.controls.parameters; var currVal_13 = (_co.parametersEditable ? _ck(_v, 21, 0, true, true, true, true, true) : i0.ɵEMPTY_MAP); var currVal_14 = true; var currVal_15 = _co.form.controls.test_parameters.value; var currVal_16 = "Test value"; var currVal_17 = true; var currVal_18 = _co.analyticsSource; _ck(_v, 20, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 1, 0, currVal_0); }); }
export function View_ViewEditorParametersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-view-editor-parameters", [], null, null, null, View_ViewEditorParametersComponent_0, RenderType_ViewEditorParametersComponent)), i0.ɵprd(512, null, i13.ViewEditorParametersForm, i13.ViewEditorParametersForm, []), i0.ɵdid(2, 770048, null, 0, i14.ViewEditorParametersComponent, [[2, i15.BasePopupComponent], i13.ViewEditorParametersForm, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ViewEditorParametersComponentNgFactory = i0.ɵccf("app-view-editor-parameters", i14.ViewEditorParametersComponent, View_ViewEditorParametersComponent_Host_0, { componentLabel: "componentLabel", parameters: "parameters", parametersEditable: "parametersEditable", stateSelectedEnabled: "stateSelectedEnabled", testParameters: "testParameters", testState: "testState", opened: "opened" }, { parametersChanged: "parametersChanged", testParametersChanged: "testParametersChanged", testStateChanged: "testStateChanged", closed: "closed" }, []);
export { ViewEditorParametersComponentNgFactory as ViewEditorParametersComponentNgFactory };

import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FeedItem } from '@modules/collaboration';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
var CollaborationMessagesItemComponent = /** @class */ (function () {
    function CollaborationMessagesItemComponent(el, currentProjectStore, currentEnvironmentStore, projectUserService, cd) {
        this.el = el;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectUserService = projectUserService;
        this.cd = cd;
    }
    CollaborationMessagesItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        var message = this.item.userActivity.params['message'];
        this.message = this.projectUserService.stubTextMentions(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, message);
        this.cd.markForCheck();
        this.projectUserService
            .replaceTextMentions(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, message)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.message = result;
            _this.cd.markForCheck();
        });
    };
    CollaborationMessagesItemComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CollaborationMessagesItemComponent.prototype, "element", {
        get: function () {
            return this.el;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollaborationMessagesItemComponent.prototype, "feedItem", {
        get: function () {
            return this.item;
        },
        enumerable: true,
        configurable: true
    });
    return CollaborationMessagesItemComponent;
}());
export { CollaborationMessagesItemComponent };

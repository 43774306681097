var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, filter, map, skip, switchMap } from 'rxjs/operators';
import { BasePopupComponent, PopupService } from '@common/popups';
import { findLayer, Frame, frameFromFrames, FrameTranslate, getAllLayers } from '@modules/views';
import { capitalize, isControlElement, isSet, KeyboardEventKeyCode } from '@shared';
export var CreatedLayerSource;
(function (CreatedLayerSource) {
    CreatedLayerSource["Tool"] = "tool";
    CreatedLayerSource["Buffer"] = "buffer";
    CreatedLayerSource["Hotkey"] = "hotkey";
})(CreatedLayerSource || (CreatedLayerSource = {}));
export var ViewEditorTool;
(function (ViewEditorTool) {
    ViewEditorTool["Selection"] = "selection";
    ViewEditorTool["Hand"] = "hand";
    ViewEditorTool["Rectangle"] = "rectangle";
    ViewEditorTool["Ellipse"] = "ellipse";
    ViewEditorTool["Line"] = "line";
    ViewEditorTool["Text"] = "text";
    ViewEditorTool["Element"] = "element";
    ViewEditorTool["Image"] = "image";
    ViewEditorTool["Frame"] = "frame";
})(ViewEditorTool || (ViewEditorTool = {}));
export function isViewEditorCreateTool(tool) {
    return [
        ViewEditorTool.Rectangle,
        ViewEditorTool.Ellipse,
        ViewEditorTool.Line,
        ViewEditorTool.Text,
        ViewEditorTool.Image,
        ViewEditorTool.Element,
        ViewEditorTool.Frame
    ].includes(tool);
}
export var ViewEditorCustomizeSource;
(function (ViewEditorCustomizeSource) {
    ViewEditorCustomizeSource["AutoLayer"] = "auto_layer";
    ViewEditorCustomizeSource["AutoLayerBounds"] = "auto_layer_bounds";
    ViewEditorCustomizeSource["Layer"] = "layer";
    ViewEditorCustomizeSource["ParentLayer"] = "parent_layer";
    ViewEditorCustomizeSource["View"] = "view";
    ViewEditorCustomizeSource["CustomizeLayer"] = "customize_layer";
    ViewEditorCustomizeSource["CustomizeMultipleLayers"] = "customize_multiple_layers";
    ViewEditorCustomizeSource["CustomizeView"] = "customize_view";
    ViewEditorCustomizeSource["Navigator"] = "navigator";
    ViewEditorCustomizeSource["HistoryMove"] = "history_move";
    ViewEditorCustomizeSource["ViewParameters"] = "view_parameters";
})(ViewEditorCustomizeSource || (ViewEditorCustomizeSource = {}));
export var ViewEditorGuideColor;
(function (ViewEditorGuideColor) {
    ViewEditorGuideColor["Red"] = "red";
    ViewEditorGuideColor["Blue"] = "blue";
})(ViewEditorGuideColor || (ViewEditorGuideColor = {}));
export var ViewEditorGuideSymbol;
(function (ViewEditorGuideSymbol) {
    ViewEditorGuideSymbol["Line"] = "line";
})(ViewEditorGuideSymbol || (ViewEditorGuideSymbol = {}));
export var defaultViewEditorContainerOptions = {
    translate: { x: 0, y: 0 }
};
var ViewEditorContainer = /** @class */ (function () {
    function ViewEditorContainer(container, options) {
        this.container = container;
        this._options = new BehaviorSubject(defaultViewEditorContainerOptions);
        this.patchOptions(options);
    }
    Object.defineProperty(ViewEditorContainer.prototype, "options", {
        get: function () {
            return this._options.value;
        },
        set: function (value) {
            this._options.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEditorContainer.prototype, "options$", {
        get: function () {
            return this._options.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ViewEditorContainer.prototype.patchOptions = function (options) {
        this._options.next(__assign({}, this._options.value, options));
    };
    return ViewEditorContainer;
}());
export { ViewEditorContainer };
var ViewEditorContext = /** @class */ (function () {
    function ViewEditorContext(popupService, popupComponent) {
        var _this = this;
        this.popupService = popupService;
        this.popupComponent = popupComponent;
        this.view$ = new BehaviorSubject(undefined);
        this.viewportX$ = new BehaviorSubject(0);
        this.viewportY$ = new BehaviorSubject(0);
        this.viewportScale$ = new BehaviorSubject(1);
        this.tool$ = new BehaviorSubject(ViewEditorTool.Selection);
        this.toolFile$ = new BehaviorSubject(undefined);
        this.toolElement$ = new BehaviorSubject(undefined);
        this.isCreateTool$ = this.tool$.pipe(map(function (tool) { return isViewEditorCreateTool(tool); }));
        this.hoverLayers$ = new BehaviorSubject([]);
        this.movingLayer$ = new BehaviorSubject(undefined);
        this.hoverView$ = new BehaviorSubject(false);
        this.resizingLayer$ = new BehaviorSubject(undefined);
        this.customizingLayers$ = new BehaviorSubject([]);
        this.resizingView$ = new BehaviorSubject(false);
        this.customizingView$ = new BehaviorSubject(false);
        this.customizingGradient$ = new BehaviorSubject(undefined);
        this.showInterface$ = new BehaviorSubject(true);
        this.navigateMode$ = new BehaviorSubject(false);
        this.ctrlKeydown$ = new BehaviorSubject(false);
        this.viewportScaleMin = 0.01;
        this.viewportScaleMax = 256;
        this.history$ = new BehaviorSubject([]);
        this.historyIndex$ = new BehaviorSubject(0);
        this.historyMaxSize = 100;
        this.historyChangeDetected$ = new Subject();
        this.containers = new BehaviorSubject([]);
        this.layerContainers = new BehaviorSubject([]);
        this.createdLayers = [];
        this._layerChanged$ = new Subject();
        this._layerContainerChanged$ = new Subject();
        this._globalLayersChange$ = new Subject();
        this._layersBuffer$ = new BehaviorSubject([]);
        this._viewChanged$ = new Subject();
        this._horizontalGuides$ = new BehaviorSubject([]);
        this._verticalGuides$ = new BehaviorSubject([]);
        this.scaleBreakpoints = [0.01, 0.02, 0.03, 0.06, 0.13, 0.25, 0.5, 1, 2, 4, 8, 16, 32, 64, 128, 256];
        this.keydownHandlers = [];
        this.spaceKeydown$ = new BehaviorSubject(false);
        this.initEvents();
        this.historyChangeDetected$.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () { return _this.historySave(); });
        this.horizontalGuides$ = this._horizontalGuides$.asObservable();
        this.verticalGuides$ = this._verticalGuides$.asObservable();
        combineLatest(this.tool$.pipe(map(function (tool) { return tool == ViewEditorTool.Hand; })), this.spaceKeydown$)
            .pipe(map(function (_a) {
            var handTool = _a[0], spaceDown = _a[1];
            return handTool || spaceDown;
        }), untilDestroyed(this))
            .subscribe(function (value) { return _this.navigateMode$.next(value); });
    }
    ViewEditorContext.prototype.ngOnDestroy = function () { };
    ViewEditorContext.prototype.init = function (view) {
        var _this = this;
        this.view$.next(cloneDeep(view));
        this.history$.next([cloneDeep(view)]);
        this.historyIndex$.next(0);
        this.view$.pipe(skip(1), untilDestroyed(this)).subscribe(function (currentView) {
            var customizingLayers = _this.customizingLayers$.value;
            if (customizingLayers.length) {
                var newLayers = customizingLayers
                    .map(function (layer) { return findLayer(currentView.layers, function (item) { return item.isSame(layer); }); })
                    .filter(function (item) { return item; });
                _this.customizingLayers$.next(newLayers);
            }
        });
    };
    ViewEditorContext.prototype.updateView = function (view) {
        this.view$.next(view);
        this.historyChangeDetected$.next();
    };
    ViewEditorContext.prototype.historySave = function () {
        var prevHistory = this.history$.value.slice(0, this.historyIndex$.value + 1).slice(this.historyMaxSize * -1);
        var history = prevHistory.concat([cloneDeep(this.view$.value)]);
        this.history$.next(history);
        this.historyIndex$.next(history.length - 1);
    };
    ViewEditorContext.prototype.historyMove = function (index) {
        var state = this.history$.value[index];
        if (!state) {
            return;
        }
        this.view$.next(cloneDeep(state));
        this.historyIndex$.next(index);
    };
    ViewEditorContext.prototype.isHistoryAvailable = function (delta) {
        var history = this.history$.value;
        var historyIndex = this.historyIndex$.value;
        return history[historyIndex + delta] !== undefined;
    };
    ViewEditorContext.prototype.isHistoryAvailable$ = function (delta) {
        return combineLatest(this.history$, this.historyIndex$).pipe(map(function (_a) {
            var history = _a[0], historyIndex = _a[1];
            return history[historyIndex + delta] !== undefined;
        }));
    };
    ViewEditorContext.prototype.isUndoAvailable = function () {
        return this.isHistoryAvailable(-1);
    };
    ViewEditorContext.prototype.isUndoAvailable$ = function () {
        return this.isHistoryAvailable$(-1);
    };
    ViewEditorContext.prototype.isRedoAvailable = function () {
        return this.isHistoryAvailable(1);
    };
    ViewEditorContext.prototype.isRedoAvailable$ = function () {
        return this.isHistoryAvailable$(1);
    };
    ViewEditorContext.prototype.undo = function () {
        this.historyMove(this.historyIndex$.value - 1);
    };
    ViewEditorContext.prototype.redo = function () {
        this.historyMove(this.historyIndex$.value + 1);
    };
    ViewEditorContext.prototype.customizeView = function () {
        if (this.customizingView$.value) {
            return;
        }
        this.customizingView$.next(true);
        this.resetCustomizingLayers();
    };
    ViewEditorContext.prototype.resetCustomizeView = function () {
        if (!this.customizingView$.value) {
            return;
        }
        this.customizingView$.next(false);
    };
    ViewEditorContext.prototype.startCustomizingGradient = function (value) {
        this.customizingGradient$.next(value);
    };
    ViewEditorContext.prototype.updateCustomizingGradient = function (value) {
        if (!this.customizingGradient$.value) {
            return;
        }
        var currentValue = this.customizingGradient$.value;
        var newValue = __assign({}, currentValue, value);
        if (isEqual(currentValue, newValue)) {
            return;
        }
        this.customizingGradient$.next(newValue);
    };
    ViewEditorContext.prototype.finishCustomizingGradient = function () {
        if (!this.customizingGradient$.value) {
            return;
        }
        this.customizingGradient$.next(undefined);
    };
    ViewEditorContext.prototype.registerContainer = function (container, options) {
        if (options === void 0) { options = {}; }
        var existingContainer = this.getContainer(container);
        if (existingContainer) {
            return;
        }
        var instance = new ViewEditorContainer(container, options);
        this.containers.next(this.containers.value.concat([
            {
                container: container,
                instance: instance
            }
        ]));
        return instance;
    };
    ViewEditorContext.prototype.unregisterContainer = function (container) {
        var existingContainer = this.getContainer(container);
        if (!existingContainer) {
            return;
        }
        this.containers.next(this.containers.value.filter(function (item) { return item.container !== container; }));
        if (this.layerContainers.value.find(function (item) { return item.container === container; })) {
            this.layerContainers.next(this.layerContainers.value.filter(function (item) { return item.container !== container; }));
        }
    };
    ViewEditorContext.prototype.getContainer = function (container) {
        var result = this.containers.value.find(function (item) { return item.container === container; });
        return result ? result.instance : undefined;
    };
    ViewEditorContext.prototype.getContainer$ = function (container) {
        return this.containers.pipe(map(function (value) {
            var result = value.find(function (item) { return item.container === container; });
            return result ? result.instance : undefined;
        }));
    };
    ViewEditorContext.prototype.registerLayerContainer = function (layer, container) {
        if (this.layerContainers.value.find(function (item) { return item.layer.isSame(layer) && item.container === container; })) {
            return;
        }
        this.layerContainers.next(this.layerContainers.value.filter(function (item) { return !item.layer.isSame(layer); }).concat([
            { layer: layer, container: container }
        ]));
    };
    ViewEditorContext.prototype.unregisterLayerContainer = function (layer) {
        if (!this.layerContainers.value.find(function (item) { return item.layer.isSame(layer); })) {
            return;
        }
        this.layerContainers.next(this.layerContainers.value.filter(function (item) { return !item.layer.isSame(layer); }));
    };
    ViewEditorContext.prototype.getLayerContainer = function (layer) {
        var layerContainer = this.layerContainers.value.find(function (item) { return item.layer.isSame(layer); });
        if (!layerContainer) {
            return;
        }
        return this.getContainer(layerContainer.container);
    };
    ViewEditorContext.prototype.getLayerContainer$ = function (layer) {
        var _this = this;
        return this.layerContainers.pipe(switchMap(function (value) {
            var layerContainer = value.find(function (item) { return item.layer.isSame(layer); });
            if (!layerContainer) {
                return of(undefined);
            }
            return _this.getContainer$(layerContainer.container);
        }));
    };
    ViewEditorContext.prototype.getContainerLayer$ = function (layer) {
        return this.getLayerContainer$(layer).pipe(switchMap(function (container) {
            if (!container) {
                return of(undefined);
            }
            return container.options$.pipe(map(function (options) { return options.layer; }));
        }), filter(function (item) { return item; }));
    };
    ViewEditorContext.prototype.generateLayerName = function (layer, options) {
        if (options === void 0) { options = {}; }
        var defaultName = isSet(options.defaultName) ? options.defaultName : capitalize(layer.defaultName);
        var allLayers = getAllLayers(this.view$.value.layers, {
            filter: function (item) {
                if (!options.ignoreLayers) {
                    return true;
                }
                return !options.ignoreLayers.some(function (ignoreLayer) { return ignoreLayer.isSame(item); });
            }
        });
        var names = allLayers.reduce(function (acc, item) {
            acc[item.name] = true;
            return acc;
        }, {});
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join(' ') : defaultName;
            ++i;
        } while (names.hasOwnProperty(newName));
        return newName;
    };
    ViewEditorContext.prototype.registerCreatedLayer = function (layer, source) {
        this.createdLayers.push({
            layer: layer,
            source: source
        });
    };
    ViewEditorContext.prototype.initCreatedLayer = function (layer) {
        var createdElement = this.createdLayers.find(function (item) { return item.layer === layer; });
        if (!createdElement) {
            return;
        }
        this.createdLayers = this.createdLayers.filter(function (item) { return item !== createdElement; });
        return createdElement;
    };
    ViewEditorContext.prototype.isHoverLayer = function (layer, handler) {
        return !!this.hoverLayers$.value.find(function (item) { return item.layer.isSame(layer) && item.handler === handler; });
    };
    ViewEditorContext.prototype.addHoverLayer = function (layer, handler) {
        if (this.isHoverLayer(layer, handler)) {
            return;
        }
        this.hoverLayers$.next(this.hoverLayers$.value.concat([{ layer: layer, handler: handler }]));
    };
    ViewEditorContext.prototype.removeHoverLayer = function (layer, handler) {
        if (!this.isHoverLayer(layer, handler)) {
            return;
        }
        this.hoverLayers$.next(this.hoverLayers$.value.filter(function (item) {
            if (item.layer.isSame(layer) && item.handler === handler) {
                return false;
            }
            else {
                return true;
            }
        }));
    };
    ViewEditorContext.prototype.getTopHoverLayerFromValue = function (value) {
        var lastItem = last(value);
        return lastItem ? lastItem.layer : undefined;
    };
    ViewEditorContext.prototype.getTopHoverLayer = function () {
        return this.getTopHoverLayerFromValue(this.hoverLayers$.value);
    };
    ViewEditorContext.prototype.getTopHoverLayer$ = function () {
        var _this = this;
        return this.hoverLayers$.pipe(map(function (value) { return _this.getTopHoverLayerFromValue(value); }));
    };
    ViewEditorContext.prototype.isTopHoverLayer = function (layer) {
        var item = this.getTopHoverLayer();
        return item && item.isSame(layer);
    };
    ViewEditorContext.prototype.isTopHoverLayer$ = function (layer) {
        return this.getTopHoverLayer$().pipe(map(function (item) { return item && item.isSame(layer); }));
    };
    ViewEditorContext.prototype.getLastCustomizingLayer = function () {
        return last(this.customizingLayers$.value);
    };
    ViewEditorContext.prototype.isCustomizingLayer = function (layer) {
        return !!this.customizingLayers$.value.find(function (item) { return item.isSame(layer); });
    };
    ViewEditorContext.prototype.isCustomizingLayer$ = function (layer) {
        return this.customizingLayers$.pipe(map(function (value) { return !!value.find(function (item) { return item.isSame(layer); }); }));
    };
    ViewEditorContext.prototype.isCustomizingSingleLayer = function () {
        return this.customizingLayers$.value.length == 1;
    };
    ViewEditorContext.prototype.isCustomizingSingleLayer$ = function () {
        return this.customizingLayers$.pipe(map(function (value) { return value.length == 1; }));
    };
    ViewEditorContext.prototype.isCustomizingMultipleLayers = function () {
        return this.customizingLayers$.value.length > 1;
    };
    ViewEditorContext.prototype.isCustomizingMultipleLayers$ = function () {
        return this.customizingLayers$.pipe(map(function (value) { return value.length > 1; }));
    };
    ViewEditorContext.prototype.setCustomizingLayer = function () {
        var layers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            layers[_i] = arguments[_i];
        }
        this.customizingLayers$.next(layers);
        this.resetCustomizeView();
    };
    ViewEditorContext.prototype.addCustomizingLayer = function () {
        var layers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            layers[_i] = arguments[_i];
        }
        var existingItems = this.customizingLayers$.value;
        var addItems = layers.filter(function (newItem) { return !existingItems.find(function (item) { return item.isSame(newItem); }); });
        if (!addItems.length) {
            return;
        }
        var newItems = existingItems.concat(addItems);
        this.customizingLayers$.next(newItems);
        this.resetCustomizeView();
    };
    ViewEditorContext.prototype.removeCustomizingLayer = function () {
        var layers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            layers[_i] = arguments[_i];
        }
        var existingItems = this.customizingLayers$.value;
        var removeItems = layers.filter(function (newItem) { return existingItems.find(function (item) { return item.isSame(newItem); }); });
        if (!removeItems.length) {
            return;
        }
        var newItems = existingItems.filter(function (existingItem) { return !removeItems.find(function (item) { return item.isSame(existingItem); }); });
        this.customizingLayers$.next(newItems);
    };
    ViewEditorContext.prototype.resetCustomizingLayers = function () {
        if (!this.customizingLayers$.value.length) {
            return;
        }
        this.customizingLayers$.next([]);
    };
    ViewEditorContext.prototype.bufferLayers = function (layers) {
        this._layersBuffer$.next(layers);
    };
    ViewEditorContext.prototype.getBufferLayers = function () {
        return this._layersBuffer$.value;
    };
    ViewEditorContext.prototype.layerChanged$ = function () {
        return this._layerChanged$.asObservable();
    };
    ViewEditorContext.prototype.markLayersChanged = function (layers, source) {
        var _this = this;
        if (!layers.length) {
            return;
        }
        layers.forEach(function (layer) {
            _this._layerChanged$.next({ layer: layer, source: source });
        });
        if (source != ViewEditorCustomizeSource.HistoryMove) {
            this.historyChangeDetected$.next();
        }
    };
    ViewEditorContext.prototype.layerContainerChanged$ = function () {
        return this._layerContainerChanged$.asObservable();
    };
    ViewEditorContext.prototype.markLayerContainerChanged = function (container, source) {
        this._layerContainerChanged$.next({ container: container, source: source });
        if (source != ViewEditorCustomizeSource.HistoryMove) {
            this.historyChangeDetected$.next();
        }
    };
    ViewEditorContext.prototype.globalLayersChange$ = function () {
        return this._globalLayersChange$.asObservable();
    };
    ViewEditorContext.prototype.markGlobalLayersChange = function (source) {
        this._globalLayersChange$.next({ source: source });
        if (source != ViewEditorCustomizeSource.HistoryMove) {
            this.historyChangeDetected$.next();
        }
    };
    ViewEditorContext.prototype.viewChanged$ = function () {
        return this._viewChanged$.asObservable();
    };
    ViewEditorContext.prototype.markViewChanged = function (view, source) {
        this._viewChanged$.next({ view: view, source: source });
        if (source != ViewEditorCustomizeSource.HistoryMove) {
            this.historyChangeDetected$.next();
        }
    };
    ViewEditorContext.prototype.isCreateTool = function () {
        return isViewEditorCreateTool(this.tool$.value);
    };
    ViewEditorContext.prototype.zoomIn = function () {
        var _this = this;
        var newScale = this.scaleBreakpoints.find(function (item) { return item > _this.viewportScale$.value; });
        if (isSet(newScale)) {
            this.viewportScale$.next(newScale);
        }
    };
    ViewEditorContext.prototype.zoomOut = function () {
        var _this = this;
        var newScale = this.scaleBreakpoints.slice().reverse().find(function (item) { return item < _this.viewportScale$.value; });
        if (isSet(newScale)) {
            this.viewportScale$.next(newScale);
        }
    };
    ViewEditorContext.prototype.isViewInsideViewport = function (viewport, contentPadding) {
        if (contentPadding === void 0) { contentPadding = 40; }
        var x = this.view$.value.frame.x;
        var y = this.view$.value.frame.y;
        var width = this.view$.value.frame.width;
        var height = this.view$.value.frame.height;
        var viewportX = this.viewportX$.value;
        var viewportY = this.viewportY$.value;
        var viewportScale = this.viewportScale$.value;
        var viewportBounds = viewport.getBoundingClientRect();
        return [
            (x - width * 0.5) / viewportScale >= viewportX - viewportBounds.width * 0.5 + contentPadding,
            (y - height * 0.5) / viewportScale >= viewportY - viewportBounds.height * 0.5 + contentPadding,
            (x + width * 0.5) / viewportScale <= viewportX + viewportBounds.width * 0.5 - contentPadding,
            (y + height * 0.5) / viewportScale <= viewportY + viewportBounds.height * 0.5 - contentPadding
        ].every(function (item) { return item; });
    };
    ViewEditorContext.prototype.fitToContent = function (viewport, contentPadding) {
        if (contentPadding === void 0) { contentPadding = 40; }
        var x = this.view$.value.frame.x;
        var y = this.view$.value.frame.y;
        var width = this.view$.value.frame.width;
        var height = this.view$.value.frame.height;
        var viewportBounds = viewport.getBoundingClientRect();
        var scaleX = width ? (viewportBounds.width - contentPadding * 2) / width : 1;
        var scaleY = height ? (viewportBounds.height - contentPadding * 2) / height : 1;
        var scale = Math.min(scaleX, scaleY);
        this.viewportX$.next(x);
        this.viewportY$.next(y);
        this.viewportScale$.next(scale);
    };
    ViewEditorContext.prototype.initEvents = function () {
        var _this = this;
        fromEvent(document, 'keydown')
            .pipe(filter(function () {
            return _this.popupService.last() === _this.popupComponent.data && !isControlElement(document.activeElement);
        }), untilDestroyed(this))
            .subscribe(function (event) {
            _this.emitKeydownEvent(event);
        });
        this.trackKeydown()
            .pipe(filter(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Space) {
                e.stopPropagation();
                return !e.repeat;
            }
            else {
                return false;
            }
        }), switchMap(function () {
            _this.spaceKeydown$.next(true);
            return fromEvent(document, 'keyup').pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Space; }));
        }), untilDestroyed(this))
            .subscribe(function () { return _this.spaceKeydown$.next(false); });
        merge(fromEvent(document, 'keydown').pipe(filter(function (event) { return [KeyboardEventKeyCode.Ctrl, KeyboardEventKeyCode.Meta].includes(event.keyCode); }), map(function () { return true; })), fromEvent(document, 'keyup').pipe(filter(function (event) { return [KeyboardEventKeyCode.Ctrl, KeyboardEventKeyCode.Meta].includes(event.keyCode); }), map(function () { return false; })))
            .pipe(untilDestroyed(this))
            .subscribe(function (keydown) {
            _this.ctrlKeydown$.next(keydown);
        });
    };
    ViewEditorContext.prototype.emitKeydownEvent = function (event) {
        for (var _i = 0, _a = this.keydownHandlers.slice().reverse(); _i < _a.length; _i++) {
            var handler = _a[_i];
            handler(event);
            if (event.cancelBubble) {
                break;
            }
        }
    };
    ViewEditorContext.prototype.trackKeydown = function () {
        var _this = this;
        return new Observable(function (observer) {
            var handler = function (e) {
                observer.next(e);
            };
            _this.keydownHandlers.push(handler);
            return function () {
                var index = _this.keydownHandlers.findIndex(function (item) { return item === handler; });
                if (index !== -1) {
                    _this.keydownHandlers.splice(index, 1);
                }
            };
        });
    };
    ViewEditorContext.prototype.setHorizontalGuides = function (guides, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (this._horizontalGuidesSubscription) {
            this._horizontalGuidesSubscription.unsubscribe();
            this._horizontalGuidesSubscription = undefined;
        }
        this._horizontalGuides$.next(guides);
        if (isSet(options.durationMs)) {
            var subscription_1 = timer(options.durationMs)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                subscription_1.unsubscribe();
                if (_this._horizontalGuidesSubscription === subscription_1) {
                    _this._horizontalGuidesSubscription = undefined;
                }
                _this.removeHorizontalGuides();
            });
            this._horizontalGuidesSubscription = subscription_1;
        }
    };
    ViewEditorContext.prototype.setVerticalGuides = function (guides, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (this._verticalGuidesSubscription) {
            this._verticalGuidesSubscription.unsubscribe();
            this._verticalGuidesSubscription = undefined;
        }
        this._verticalGuides$.next(guides);
        if (isSet(options.durationMs)) {
            var subscription_2 = timer(options.durationMs)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                subscription_2.unsubscribe();
                if (_this._verticalGuidesSubscription === subscription_2) {
                    _this._verticalGuidesSubscription = undefined;
                }
                _this.removeVerticalGuides();
            });
            this._verticalGuidesSubscription = subscription_2;
        }
    };
    ViewEditorContext.prototype.removeHorizontalGuides = function () {
        this.setHorizontalGuides([]);
    };
    ViewEditorContext.prototype.removeVerticalGuides = function () {
        this.setVerticalGuides([]);
    };
    ViewEditorContext.prototype.getFrames = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var view = this.view$.value;
        var layers;
        var filterLayer = function (layer) {
            if (options.exceptLayers) {
                return !options.exceptLayers.find(function (exceptLayer) { return exceptLayer.isSame(layer); });
            }
            else {
                return true;
            }
        };
        if (options.onlyContainers) {
            layers = options.onlyContainers
                .reduce(function (acc, container) {
                acc.push.apply(acc, container);
                return acc;
            }, [])
                .filter(function (layer) { return filterLayer(layer); });
        }
        else {
            layers = getAllLayers(view.layers, {
                filter: function (layer) { return filterLayer(layer); }
            });
        }
        var result = [];
        if (!options.onlyContainers || options.onlyContainers.includes(view.layers)) {
            result.push(new FrameTranslate({
                frame: view.frame
            }));
        }
        result.push.apply(result, layers.map(function (item) {
            var container = _this.getLayerContainer(item);
            return new FrameTranslate({
                frame: item.frame,
                translate: container ? container.options.translate : undefined
            });
        }));
        return result;
    };
    ViewEditorContext.prototype.fitContainer = function (layer, source) {
        var parentContainer = this.getLayerContainer(layer);
        var frames = layer.layers
            .filter(function (item) { return !item.absoluteLayout; })
            .map(function (item) {
            return new FrameTranslate({
                frame: item.frame,
                translate: parentContainer ? parentContainer.options.translate : undefined
            });
        });
        var frame = frameFromFrames(frames) || new Frame(layer.frame).patch({ width: 0, height: 0 });
        if (layer.flexLayout) {
            frame.x -= layer.flexLayout.paddingLeft;
            frame.y -= layer.flexLayout.paddingTop;
            frame.width += layer.flexLayout.paddingLeft + layer.flexLayout.paddingRight;
            frame.height += layer.flexLayout.paddingTop + layer.flexLayout.paddingBottom;
        }
        if (parentContainer) {
            frame.x -= parentContainer.options.translate.x;
            frame.y -= parentContainer.options.translate.y;
        }
        frame.x += layer.frame.x;
        frame.y += layer.frame.y;
        var change = !isEqual(layer.frame, frame);
        if (!change) {
            return;
        }
        var originalFrameX = layer.frame.x;
        var originalFrameY = layer.frame.y;
        layer.frame.patch(frame);
        var nestedContainer = this.getContainer(layer.layers);
        if (nestedContainer && parentContainer) {
            nestedContainer.patchOptions({
                translate: {
                    x: parentContainer.options.translate.x + layer.frame.x,
                    y: parentContainer.options.translate.y + layer.frame.y
                }
            });
        }
        var frameDeltaX = layer.frame.x - originalFrameX;
        var frameDeltaY = layer.frame.y - originalFrameY;
        if (layer.frame.x != originalFrameX || layer.frame.y != originalFrameY) {
            layer.layers.forEach(function (item) {
                item.frame.x -= frameDeltaX;
                item.frame.y -= frameDeltaY;
            });
            this.markLayersChanged(layer.layers, ViewEditorCustomizeSource.ParentLayer);
        }
        this.markLayersChanged([layer], source);
    };
    return ViewEditorContext;
}());
export { ViewEditorContext };

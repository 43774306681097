import { Input } from '@modules/fields';
import { isSet } from '@shared';

export class ViewMapping {
  public sourceParameter: string;
  public sourceParameterInput: Input;
  public targetParameter: string;

  constructor(options: Partial<ViewMapping> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (isSet(data['source_parameter'])) {
      this.sourceParameter = data['source_parameter'];
    } else if (data['sourceParameter']) {
      // Backward compatibility
      this.sourceParameter = data['sourceParameter'];
    }

    if (isSet(data['target_parameter'])) {
      this.targetParameter = data['target_parameter'];
    } else if (data['targetParameter']) {
      // Backward compatibility
      this.targetParameter = data['targetParameter'];
    }

    if (data['source_parameter_input']) {
      this.sourceParameterInput = new Input().deserialize(data['source_parameter_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      source_parameter: this.sourceParameter,
      source_parameter_input: this.sourceParameterInput ? this.sourceParameterInput.serialize() : undefined,
      target_parameter: this.targetParameter
    };
  }
}

import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class SectionLayoutElementItem extends ElementItem {
  public type = ElementType.Section;
  public children: ElementItem[] = [];

  deserialize(data: Object): SectionLayoutElementItem {
    super.deserialize(data);

    if (this.params['children']) {
      this.children = this.params['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['children'] = this.children.map(item => item.serialize());
    return data;
  }

  get analyticsName(): string {
    return 'action';
  }

  defaultName() {
    return 'Section';
  }

  childrenCount() {
    return this.children.length;
  }
}

registerElementForType(ElementType.Section, SectionLayoutElementItem);

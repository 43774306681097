var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isSet } from '@shared';
import { ProjectUserService } from '../services/project-user/project-user.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var ProjectUserSelectSource = /** @class */ (function (_super) {
    __extends(ProjectUserSelectSource, _super);
    function ProjectUserSelectSource(currentProjectStore, currentEnvironmentStore, projectUserService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectUserService = projectUserService;
        _this.allowEmpty = false;
        _this.emptyName = '---';
        _this.userValue = false;
        _this.page = 1;
        _this.totalPages = 1;
        return _this;
    }
    Object.defineProperty(ProjectUserSelectSource.prototype, "emptyOption", {
        get: function () {
            return {
                value: null,
                name: this.emptyName
            };
        },
        enumerable: true,
        configurable: true
    });
    ProjectUserSelectSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = isSet(searchQuery) ? searchQuery.trim() : '';
        var params = __assign({}, (isSet(searchQuery) && { search: searchQuery }));
        return this.projectUserService
            .getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, params)
            .pipe(map(function (result) {
            var perPage = 20;
            var totalPages = Math.ceil(result.count / perPage);
            _this.page += 1;
            _this.totalPages = totalPages;
            return (_this.allowEmpty ? [_this.emptyOption] : []).concat(result.results
                .filter(function (item) { return !_this.userValue || item.user; })
                .map(function (item) {
                return {
                    value: _this.userValue ? item.user.uid : item.uid,
                    name: item.user ? item.user.firstName + " - " + item.user.email : item.userEmail + " (invited)"
                };
            }));
        }));
    };
    ProjectUserSelectSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        if (this.userValue) {
            return this.projectUserService
                .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
                user_uid: value
            })
                .pipe(map(function (response) {
                var result = response.filter(function (item) { return item.user; })[0];
                if (!result) {
                    return;
                }
                return {
                    value: result.user.uid,
                    name: result.user ? result.user.firstName + " - " + result.user.email : result.userEmail + " (invited)"
                };
            }));
        }
        else {
            return this.projectUserService
                .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, value)
                .pipe(map(function (result) {
                if (!result) {
                    return;
                }
                return {
                    value: result.uid,
                    name: result.user ? result.user.firstName + " - " + result.user.email : result.userEmail + " (invited)"
                };
            }));
        }
    };
    ProjectUserSelectSource.prototype.isFetchAvailable = function () {
        return this.page <= this.totalPages;
    };
    ProjectUserSelectSource.prototype.resetPagination = function () {
        this.page = 1;
        this.totalPages = 1;
    };
    ProjectUserSelectSource.prototype.setStaticOptions = function (options) { };
    return ProjectUserSelectSource;
}(SelectSource));
export { ProjectUserSelectSource };

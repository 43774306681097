import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  controlValue,
  isElementInViewport,
  isSet,
  KeyboardEventKeyCode,
  scrollTo,
  scrollToElement2,
  TypedChanges
} from '@shared';

import { FormulaSection, FormulaSectionItem } from '../../data/formula-section';
import { FormulaInsert } from '../../data/formula-token';
import { FormulaTokenFunction } from '../../data/formula-token-function';
import { FormulaTokenPosition } from '../../data/formula-token-position';
import { ViewContextTokenProvider } from '../../services/view-context-token-provider/view-context-token-provider';
import { findFormulaTokenSibling, findSectionToken, searchFormulaSections } from '../../utils/view-context-tokens';

const referencePopoverMouseEvent = '_referencePopoverMouseEvent';

export function markReferencePopoverMouseEvent(e: MouseEvent, id: string) {
  e[referencePopoverMouseEvent] = id;
}

export function isReferencePopoverMouseEvent(e: MouseEvent, id: string) {
  return e[referencePopoverMouseEvent] == id;
}

export interface ViewContextTokenSearch {
  search: string;
  searchControl?: boolean;
  searchChar?: string;
  tokenPosition?: FormulaTokenPosition;
}

@Component({
  selector: 'app-view-context-token-popover',
  templateUrl: './view-context-token-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverComponent implements OnInit, OnDestroy, OnChanges {
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() id: string;
  @Input() maxHeight: number;
  @Input() extraSections: FormulaSection[] = [];
  @Input() hideSections: string[] = [];
  @Input() search = '';
  @Input() selectFunction: string;
  @Output() selected = new EventEmitter<FormulaInsert>();
  @Output() close = new EventEmitter<void>();

  @ViewChild('reference_popover_overlay', { read: CdkConnectedOverlay }) referencePopoverOverlay: CdkConnectedOverlay;
  @ViewChild('scrollable_element') scrollableElement: ElementRef;

  loading = false;
  sections$ = new BehaviorSubject<FormulaSection[]>(undefined);
  displaySections$ = new BehaviorSubject<FormulaSection[]>([]);
  displayTabSections$ = this.displaySections$.pipe(
    map(sections => {
      return sections.filter(section => section.items.length).filter(section => !section.hideTab && section.icon);
    })
  );
  openedSections: FormulaSection[] = [];
  selectedSectionName$ = new BehaviorSubject<string>(undefined);
  selectedSection$ = new BehaviorSubject<FormulaSection>(undefined);
  activeToken$ = new BehaviorSubject<FormulaSectionItem>(undefined);
  hoverToken$ = new BehaviorSubject<FormulaSectionItem>(undefined);
  searchControl = new FormControl('');
  selectFunction$ = new BehaviorSubject<string>(undefined);
  search$ = new BehaviorSubject<string>('');
  showFunctionReference: FormulaTokenFunction;
  keyboardNavigation = false;

  trackSection = (() => {
    return (i, item: FormulaSection) => item.token || item.label || i;
  })();

  markReferencePopoverMouseEvent = (e: MouseEvent) => markReferencePopoverMouseEvent(e, this.id);

  constructor(private provider: ViewContextTokenProvider, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initSections();
    this.initDisplaySections();
    this.initSelectedSection();
    this.initFunctionReference();
    this.initHotkeys();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ViewContextTokenPopoverComponent>): void {
    if (changes.selectFunction) {
      this.selectFunction$.next(this.selectFunction);
    }

    if (changes.search) {
      this.search$.next(this.search);
      this.searchControl.patchValue('');
    }
  }

  initSections() {
    combineLatest(
      this.provider.getContextElementSection(this.contextElement, this.contextElementPath, this.contextElementPaths),
      this.provider.getSections$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(([elementSection, sections]) => {
        sections = [
          ...this.extraSections.filter(item => item.pinned),
          ...(elementSection && elementSection.items.length ? [elementSection] : []),
          ...sections.map(section => {
            if (this.contextElement) {
              return {
                ...section,
                items: section.items.filter(item => {
                  const itemContextElement = item.data
                    ? (item.data['ignoreContextElement'] as ViewContextElement)
                    : undefined;
                  if (itemContextElement) {
                    return itemContextElement !== this.contextElement;
                  } else {
                    return true;
                  }
                })
              };
            } else {
              return section;
            }
          }),
          ...this.extraSections.filter(item => !item.pinned)
        ];

        if (this.hideSections.length) {
          sections = sections.filter(section => !this.hideSections.includes(section.name));
        }

        this.sections$.next(sections);
      });
  }

  initDisplaySections() {
    this.loading = true;
    this.cd.markForCheck();

    const search$ = combineLatest(controlValue(this.searchControl), this.search$).pipe(
      map(([searchControl, searchInput]) => (isSet(searchControl) ? searchControl : searchInput))
    );

    combineLatest(this.sections$, search$)
      .pipe(untilDestroyed(this))
      .subscribe(([sections, search]) => {
        if (sections) {
          let displaySections: FormulaSection[] = sections;

          if (isSet(search)) {
            const filteredSections = searchFormulaSections(sections, search);
            if (findSectionToken(filteredSections, { nested: true })) {
              displaySections = filteredSections;
            }
          }

          this.displaySections$.next(displaySections);
          this.loading = false;
          this.cd.markForCheck();
        } else {
          this.loading = true;
          this.cd.markForCheck();
        }

        this.resetActiveToken();
        this.resetHoverToken();
      });
  }

  initSelectedSection() {
    combineLatest(this.selectedSectionName$, this.displaySections$)
      .pipe(untilDestroyed(this))
      .subscribe(([sectionName, sections]) => {
        this.selectedSection$.next(sections.find(item => isSet(item.label) && item.label == sectionName));
      });
  }

  initFunctionReference() {
    combineLatest(this.hoverToken$, this.sections$, this.selectFunction$)
      .pipe(untilDestroyed(this))
      .subscribe(([hoverToken, sections, selectFunction]) => {
        const functionsSection =
          selectFunction && sections ? sections.find(item => item.name == 'functions') : undefined;
        const selectFunctionItem =
          selectFunction && functionsSection
            ? functionsSection.items.find(item => {
                return item.item && item.item.function && item.item.function.name == selectFunction;
              })
            : undefined;

        if (hoverToken && hoverToken.item && hoverToken.item.function) {
          this.showFunctionReference = hoverToken.item.function;
        } else if (selectFunctionItem) {
          this.showFunctionReference = selectFunctionItem ? selectFunctionItem.item.function : undefined;
        } else {
          this.showFunctionReference = undefined;
        }

        this.cd.markForCheck();
      });
  }

  initHotkeys() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const activeToken = this.activeToken$.value;

        if (e.keyCode == KeyboardEventKeyCode.Tab && !e.shiftKey && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          if (activeToken.item) {
            this.onItemSelected(activeToken.item);
          } else if (activeToken.section && activeToken.section.items.length) {
            this.addOpenedSection(activeToken.section);
            this.keyboardNavigation = true;
          }
        } else if (e.keyCode == KeyboardEventKeyCode.Tab && e.shiftKey && this.openedSection) {
          e.stopPropagation();
          e.preventDefault();

          this.popOpenedSection();
        } else if (e.keyCode == KeyboardEventKeyCode.ArrowUp && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          const sections = this.getEffectiveSections();
          const sibling = findFormulaTokenSibling(sections, activeToken, false);

          if (sibling) {
            this.activeToken$.next(sibling);
            this.keyboardNavigation = true;
            this.scrollToItem(sibling);
          }
        } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          const sections = this.getEffectiveSections();
          const sibling = findFormulaTokenSibling(sections, activeToken, true);

          if (sibling) {
            this.activeToken$.next(sibling);
            this.keyboardNavigation = true;
            this.scrollToItem(sibling);
          }
        }
      });

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.keyboardNavigation = false;
      });
  }

  getEffectiveSections(): FormulaSection[] {
    if (this.openedSection) {
      return [this.openedSection];
    } else if (this.selectedSection$.value) {
      return [this.selectedSection$.value];
    } else {
      return this.displaySections$.value.map(section => {
        const maxItems = isSet(this.searchControl.value) ? 20 : 6;

        if (section.items.length > maxItems) {
          return {
            ...section,
            items: section.items.slice(0, maxItems)
          };
        } else {
          return section;
        }
      });
    }
  }

  scrollToItem(item: FormulaSectionItem) {
    const selector = `[data-formula-token-path="${item.path}"]`;
    const element = this.scrollableElement.nativeElement.querySelector(selector);

    if (!element) {
      return false;
    }

    if (isElementInViewport(element, this.scrollableElement.nativeElement)) {
      return;
    }

    scrollToElement2(this.scrollableElement.nativeElement, element, 0, -40);
  }

  get openedSection(): FormulaSection {
    return this.openedSections[this.openedSections.length - 1];
  }

  get openedSectionParent(): FormulaSection {
    return this.openedSections[this.openedSections.length - 2];
  }

  setOpenedSections(sections: FormulaSection[]) {
    this.openedSections = sections;
    this.cd.markForCheck();

    this.resetActiveToken();
    this.resetHoverToken();
  }

  addOpenedSection(section: FormulaSection) {
    this.setOpenedSections([...this.openedSections, section]);
    this.cd.detectChanges();

    scrollTo(this.scrollableElement.nativeElement, 0);
  }

  popOpenedSection() {
    this.setOpenedSections(this.openedSections.slice(0, this.openedSections.length - 1));
  }

  clearOpenedSections() {
    this.setOpenedSections([]);
  }

  setSelectedSectionName(name: string) {
    this.selectedSectionName$.next(name);
    this.resetActiveToken();
    this.resetHoverToken();

    this.clearOpenedSections();
    this.cd.detectChanges();
    scrollTo(this.scrollableElement.nativeElement, 0);
  }

  resetActiveToken() {
    const sections = this.getEffectiveSections();
    const token = findSectionToken(
      sections.filter(item => !item.horizontal),
      { nested: false }
    );

    this.activeToken$.next(token);
  }

  resetHoverToken() {
    this.hoverToken$.next(undefined);
  }

  onItemHover(item: FormulaSectionItem) {
    if (!this.keyboardNavigation) {
      this.activeToken$.next(item);
      this.hoverToken$.next(item);
    }
  }

  onItemOut() {
    this.hoverToken$.next(undefined);
  }

  onItemSelected(token: FormulaInsert) {
    this.setSelectedSectionName(undefined);
    this.clearSearch();

    this.selected.emit(token);
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  scrollToTop() {
    scrollTo(this.scrollableElement.nativeElement, 0);
  }

  onReferencePopoverContentChanged() {
    if (this.referencePopoverOverlay && this.referencePopoverOverlay.overlayRef) {
      this.referencePopoverOverlay.overlayRef.updatePosition();
    }
  }
}

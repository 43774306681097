<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated" [class.popover2_header]="headerTitle | appIsSet">
    <div class="popover2__inner">
      <div *ngIf="headerTitle | appIsSet" class="token-popup__header">
        <div class="token-popup-header">
          <span *ngIf="headerIcon" class="token-popup-header__icon" [ngClass]="'icon-' + headerIcon"></span>
          {{ headerTitle }}
        </div>
      </div>

      <div class="popover2__viewport popover2__viewport_scrollable">
        <div class="popover2__content" style="min-width: 300px;">
          <div *ngFor="let section of sections" class="token-popup__item">
            <div class="token-popup-section">
              <div *ngIf="section.name" class="token-popup-section__title">
                <div class="token-popup-section__title-main">{{ section.name }}</div>
                <div *ngIf="section.add" class="token-popup-section__title-right">
                  <a
                    href="javascript:void(0)"
                    class="token-popup-section__title-link token-popup-section__title-link_orange"
                    (click)="createGroup()"
                  >
                    <span class="icon-plus"></span> {{ 'Add Team' | localize }}
                  </a>
                </div>
              </div>
              <div class="token-popup-section__content">
                <a
                  *ngFor="let item of section.items"
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  [class.token-popup-list-item_active]="item.value?.uid == currentOption?.value?.uid"
                  href="javascript:void(0)"
                  (click)="setValue(item)"
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span class="token-popup-list-item__left">
                        <span
                          class="token-popup-list-item__icon"
                          [ngClass]="['icon-' + (item.icon || 'users_teams')]"
                        ></span>
                      </span>
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                        {{ item.name | appCapitalize }}
                      </span>
                      <span *ngIf="section.edit && item.value" class="token-popup-list-item__right">
                        <a
                          href="javascript:void(0)"
                          class="token-popup-list-item__hover token-popup-list-item__icon-action icon-gear_2"
                          [appTip]="'Edit Team' | localize"
                          [appTipOptions]="{ side: 'top' }"
                          (click)="$event.stopPropagation(); editGroup(item.value)"
                        ></a>
                      </span>
                    </div>
                    <div
                      *ngIf="item.description"
                      class="token-popup-list-item__line"
                      [class.token-popup-list-item__line_active]="false"
                    >
                      <div class="token-popup-list-item__description">
                        {{ item.description | appCapitalize }}
                      </div>
                    </div>
                  </div>
                </a>

                <a
                  *ngIf="section.add && !section.name"
                  href="javascript:void(0)"
                  class="token-popup-list-item token-popup-list-item_orange token-popup-list-item_hoverable token-popup-list-item_clickable"
                  (click)="createGroup()"
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span class="token-popup-list-item__left">
                        <span class="token-popup-list-item__icon icon-plus"></span>
                      </span>
                      <span class="token-popup-list-item__main">{{ 'Add Team' | localize }}</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popover2__arrow"></div>
    </div>
  </div>
</ng-template>

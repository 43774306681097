import { ActionItem, ViewSettingsAction } from '@modules/actions';
import {
  EditableFlexField,
  FieldDescription,
  FormField,
  getFieldDescriptionByType,
  Input,
  parseFieldType
} from '@modules/fields';
import { ModelDbField, ModelField, modelFieldToField, ModelFieldType } from '@modules/models';

import { AlignHorizontal } from '../../align-horizontal';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem, Margin } from './base';
import { ResizableElementItem } from './widget';

export const FIELD_ELEMENT_VERSION = 2;

export enum ElementActionsPosition {
  Top = 'top',
  Bottom = 'bottom'
}

export class ElementActions {
  public position: ElementActionsPosition;
  public actions: ViewSettingsAction[] = [];

  constructor(options: Partial<ElementActions> = {}) {
    this.position = options.position;
    this.actions = options.actions || [];
  }

  deserialize(data: Object): ElementActions {
    this.position = data['position'];

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      position: this.position,
      actions: this.actions.map(item => item.serialize())
    };
  }
}

export class FieldElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.Field;
  public alignHorizontalDefault = AlignHorizontal.Left;
  public width: number;
  public height: number;

  public settings: EditableFlexField;
  public relatedModelFromField: string;
  public disableInput: Input;
  public tooltip: string;
  public elementActions: ElementActions[] = [];
  public onChangeActions: ActionItem[] = [];
  public version = 1;
  private _formField: FormField;
  private _fieldDescription: FieldDescription;

  deserialize(data: Object): FieldElementItem {
    super.deserialize(data);
    this.version = this.params['v'] || 1;
    this.height = this.params['height'];
    this.tooltip = this.params['tooltip'];

    if (this.version >= 2) {
      this.width = this.params['width'];
    } else {
      // Backward compatibility
      this.width = 220;
      this.version = FIELD_ELEMENT_VERSION;
    }

    if (this.params['settings']) {
      this.settings = this.params['settings'];
      this.settings.field = parseFieldType(this.settings.field);
      this.settings.valueInput = this.params['settings']['valueInput']
        ? new Input().deserialize(this.params['settings']['valueInput'])
        : undefined;
      this._formField = undefined;
    }

    if (this.params['field']) {
      let modelField: ModelField;

      if (this.params['field']['type'] != undefined) {
        modelField = new ModelField().deserialize(this.params['field']);
      } else {
        modelField = new ModelField();
        modelField.type = ModelFieldType.Db;
        modelField.item = new ModelDbField().deserialize(this.params['field']);
        modelField.name = modelField.item.name;
      }

      this.settings = modelFieldToField(modelField);
      this._formField = undefined;
    }

    this.relatedModelFromField = this.params['related_model_from_field'];

    if (this.params['disable_input']) {
      this.disableInput = new Input().deserialize(this.params['disable_input']);
    }

    if (this.params['element_actions']) {
      this.elementActions = this.params['element_actions'].map(item => new ElementActions().deserialize(item));
    }

    if (this.params['on_change_actions']) {
      this.onChangeActions = this.params['on_change_actions'].map(item => new ActionItem().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    this.params = {
      settings: {
        ...this.settings,
        valueInput: this.settings.valueInput ? this.settings.valueInput.serialize() : undefined
      },
      // field: this.modelField ? this.modelField.serialize() : undefined,
      related_model_from_field: this.relatedModelFromField,
      width: this.width,
      height: this.height,
      disable_input: this.disableInput ? this.disableInput.serialize() : null,
      tooltip: this.tooltip,
      element_actions: this.elementActions.map(item => item.serialize()),
      on_change_actions: this.onChangeActions.map(item => item.serialize()),
      v: this.version
    };

    return super.serialize();
  }

  get formField(): FormField {
    if (!this._formField) {
      this.updateFormField();
    }

    return this._formField;
  }

  get fieldDescription(): FieldDescription {
    if (!this._fieldDescription) {
      this.updateFieldDescription();
    }

    return this._fieldDescription;
  }

  updateFieldDescription() {
    this._fieldDescription = getFieldDescriptionByType(this.formField.field);
  }

  updateFormField() {
    this._formField = new FormField().deserialize({
      name: this.settings.name,
      label: this.settings.verboseName,
      field: this.settings.field,
      required: this.settings.required,
      reset_enabled: this.settings.resetEnabled,
      editable: this.settings.editable,
      placeholder: this.settings.placeholder,
      validator_type: this.settings.validatorType,
      validator_params: this.settings.validatorParams,
      params: this.settings.params,
      description: this.settings.description
    });
  }

  get analyticsName(): string {
    return this.settings ? `field_${this.settings.field}` : 'field';
  }

  defaultName() {
    return 'field';
  }
}

registerElementForType(ElementType.Field, FieldElementItem);

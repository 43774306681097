<div class="compact-heading compact-heading_secondary">
  Auto layout position
</div>

<div class="compact-action-item">
  <div class="compact-action-item__left">
    <div class="compact-action-item__icon" [ngClass]="'icon-' + containerLayer.icon"></div>
  </div>

  <div class="compact-action-item__main">
    <div class="compact-action-item__title">{{ containerLayer.name }}</div>
  </div>

  <div class="compact-action-item__right">
    <div
      class="compact-action-item__toggle"
      [class.compact-action-item__toggle_active]="absoluteControl.value"
      [appTip]="absoluteControl.value ? 'Disable absolute position' : 'Enable absolute position'"
      [appTipOptions]="{ side: 'top' }"
      (click)="absoluteControl.patchValue(!absoluteControl.value)"
    >
      Absolute
    </div>
  </div>
</div>

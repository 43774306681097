import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appInputFocused',
  pure: false
})
export class InputFocusedPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value === document.activeElement;
  }
}

import { OutputData } from '@editorjs/editorjs';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, Input } from '@modules/fields';

export function outputDataToHtml(
  value: OutputData,
  options: { context?: ViewContext; contextElement?: ViewContextElement } = {}
): Observable<string> {
  if (!value) {
    return of(undefined);
  }

  return combineLatest(
    value.blocks.map(block => {
      switch (block.type) {
        case 'header':
          return of(`<h${block.data['level']}>${block.data['text']}</h${block.data['level']}>`);
        case 'paragraph':
          return of(`<p>${block.data['text']}</p>`);
        case 'formula':
          const input = new Input();

          if (block.data['value']) {
            input.deserialize(block.data['value']);
          }

          return applyParamInput$(input, { ...options, defaultValue: '' }).pipe(map(content => `<p>${content}</p>`));
        default:
          console.log('Unknown block type', block.type);
          console.log(block);
          return of('');
      }
    })
  ).pipe(map(blockContents => blockContents.join('')));
}

import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { keyboardChars, KeyboardEventKeyCode } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class ScreenRecordService {
  constructor() {}

  cheatCode$(code: string) {
    const cheatCode = code
      .toUpperCase()
      .split('')
      .map(item => keyboardChars.find(i => i.char == item));
    let step = 0;

    return fromEvent<KeyboardEvent>(window, 'keydown').pipe(
      filter(event => event.keyCode !== KeyboardEventKeyCode.Shift),
      filter(event => {
        const char = cheatCode[step];
        if (event.keyCode === char.code && (!char.shift || event.shiftKey)) {
          step += 1;
          if (step === cheatCode.length) {
            step = 0;
            return true;
          }
        } else {
          step = 0;
        }
        return false;
      })
    );
  }
}

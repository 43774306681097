import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentProjectStore, Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { PageTemplatesController } from '@modules/template-queries';

import { ResourceAddModelAddedEvent, ResourceAddModelComponentItem } from '../../data/resource-add-model-components';

@Injectable()
export class ResourceModelEditController {
  constructor(
    private injector: Injector,
    private currentProjectStore: CurrentProjectStore,
    private popupService: PopupService,
    private pageTemplatesController: PageTemplatesController,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  addModel(
    resource: Resource,
    addModel: ResourceAddModelComponentItem,
    options: { showPageTemplates?: boolean; source?: string } = {}
  ): Observable<ResourceAddModelAddedEvent> {
    const result = new ReplaySubject<ResourceAddModelAddedEvent>();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionStartToAdd, {
      Source: options.source,
      ResourceID: resource.typeItem.name
    });

    this.popupService.push({
      component: addModel.component,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        resource: resource,
        analyticsSource: options.source
      },
      outputs: {
        added: [
          event => {
            result.next(event);

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
              Type: 'data'
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Data.CollectionSuccessfullyAdd, {
              Source: options.source,
              ResourceID: resource.typeItem.name
            });

            if (options.showPageTemplates) {
              this.pageTemplatesController
                .open({
                  cancelEnabled: true,
                  resource: resource,
                  newPage: true,
                  analyticsSource: options.source
                })
                // .pipe(untilDestroyed(this))
                .subscribe(templateResult => {
                  if (templateResult.startPage) {
                    this.routing.navigateApp(templateResult.startPage.link, { replaceUrl: true });
                  } else if (templateResult.newPage) {
                    this.routing.navigateApp(this.currentProjectStore.instance.newPageLink, { replaceUrl: true });
                  }
                });
            }
          }
        ]
      },
      injector: this.injector
    });

    return result.asObservable();
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ChangeViewSettings, ViewSettings } from '@modules/customize';
import { ParameterArray } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ModelDescriptionQuery } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { splitmax } from '@shared';
import { CustomizeBarModelDataArray } from './customize-bar-model-data.array';
var CustomizeBarPageEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarPageEditForm, _super);
    function CustomizeBarPageEditForm(currentEnvironmentStore, modelDescriptionStore, resourceControllerService, customizeBarModelDataArray) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            model: new FormControl(''),
            parameters: new ParameterArray([]),
            queries: customizeBarModelDataArray
        }) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceControllerService = resourceControllerService;
        _this.changeViewSettings = false;
        return _this;
    }
    CustomizeBarPageEditForm.prototype.init = function (viewSettings) {
        this.viewSettings = viewSettings;
        this.changeViewSettings = viewSettings instanceof ChangeViewSettings;
        if (viewSettings) {
            var value = {
                name: viewSettings.name,
                model: undefined,
                parameters: viewSettings.parameters
            };
            if (viewSettings instanceof ChangeViewSettings) {
                if (viewSettings.getResource && viewSettings.getQuery && viewSettings.getQuery.simpleQuery) {
                    value['model'] = { model: [viewSettings.getResource, viewSettings.getQuery.simpleQuery.model].join('.') };
                }
            }
            this.patchValue(value, { emitEvent: false });
            this.controls.queries.deserialize(viewSettings.queries);
        }
    };
    CustomizeBarPageEditForm.prototype.submit = function () {
        var value = this.value;
        var instance = this.viewSettings ? cloneDeep(this.viewSettings) : new ViewSettings();
        // instance.name = value['name'];
        instance.parameters = value['parameters'];
        instance.queries = this.controls.queries.serialize();
        if (instance instanceof ChangeViewSettings) {
            if (value['model']) {
                var _a = splitmax(value['model']['model'], '.', 2), resource = _a[0], model = _a[1];
                instance.getResource = resource;
                if (!instance.getQuery) {
                    instance.getQuery = new ModelDescriptionQuery();
                }
                if (!instance.getQuery.simpleQuery) {
                    instance.getQuery.simpleQuery = new instance.getQuery.simpleQueryClass();
                }
                instance.getQuery.simpleQuery.model = model;
            }
        }
        return instance;
    };
    return CustomizeBarPageEditForm;
}(FormGroup));
export { CustomizeBarPageEditForm };

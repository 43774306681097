<div class="sidebar__element">
  <app-sidebar-field [label]="'Action'" [documentation]="'component-actions'" [documentationLabel]="'See how it works'">
    <app-customize-bar-action-edit-element-action
      [control]="form.controls.element_action"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
    ></app-customize-bar-action-edit-element-action>
    <app-field-errors [form]="form" [fieldName]="'element_action'"></app-field-errors>
  </app-sidebar-field>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./gradient-position-point.component";
import * as i2 from "../../../services/view-editor-context/view-editor.context";
var styles_GradientPositionPointComponent = [];
var RenderType_GradientPositionPointComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GradientPositionPointComponent, data: {} });
export { RenderType_GradientPositionPointComponent as RenderType_GradientPositionPointComponent };
export function View_GradientPositionPointComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_GradientPositionPointComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-gradient-position-point", [], [[2, "view-editor-direction__point", null], [2, "view-editor-direction__point_active", null], [2, "view-editor-direction__point_color", null], [4, "background-color", null], [4, "left", null]], null, null, View_GradientPositionPointComponent_0, RenderType_GradientPositionPointComponent)), i0.ɵdid(1, 4440064, null, 0, i1.GradientPositionPointComponent, [i2.ViewEditorContext, i0.ElementRef, [1, i0.ChangeDetectorRef]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).activeCls; var currVal_2 = i0.ɵnov(_v, 1).colorCls; var currVal_3 = i0.ɵnov(_v, 1).backgroundColor; var currVal_4 = i0.ɵnov(_v, 1).left; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var GradientPositionPointComponentNgFactory = i0.ɵccf("app-gradient-position-point", i1.GradientPositionPointComponent, View_GradientPositionPointComponent_Host_0, { positionPx: "positionPx", positionEm: "positionEm", positionPercent: "positionPercent", active: "active", control: "control", viewContext: "viewContext", frame: "frame", translate: "translate", bounds: "bounds", snap: "snap" }, { positionChange: "positionChange" }, []);
export { GradientPositionPointComponentNgFactory as GradientPositionPointComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../table-stub-content-header/table-stub-content-header.component.ngfactory";
import * as i2 from "../table-stub-content-header/table-stub-content-header.component";
import * as i3 from "@angular/common";
import * as i4 from "../table-stub-content-body/table-stub-content-body.component.ngfactory";
import * as i5 from "../table-stub-content-body/table-stub-content-body.component";
import * as i6 from "./table-stub-content.component";
var styles_TableStubContentComponent = [];
var RenderType_TableStubContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableStubContentComponent, data: {} });
export { RenderType_TableStubContentComponent as RenderType_TableStubContentComponent };
function View_TableStubContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "thead", [["app-table-stub-content-header", ""]], null, null, null, i1.View_TableStubContentHeaderComponent_0, i1.RenderType_TableStubContentHeaderComponent)), i0.ɵdid(1, 638976, null, 0, i2.TableStubContentHeaderComponent, [], { columns: [0, "columns"], animating: [1, "animating"], scrollable: [2, "scrollable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; var currVal_1 = _co.animating; var currVal_2 = _co.scrollable; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TableStubContentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "table", [["class", "table"], ["style", "overflow: hidden;"]], [[2, "table_grid", null], [2, "table_compact", null], [4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TableStubContentComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tbody", [["app-table-stub-content-body", ""]], null, null, null, i4.View_TableStubContentBodyComponent_0, i4.RenderType_TableStubContentBodyComponent)), i0.ɵdid(4, 638976, null, 0, i5.TableStubContentBodyComponent, [], { columns: [0, "columns"], rows: [1, "rows"], rowActions: [2, "rowActions"], rowActionsButtons: [3, "rowActionsButtons"], selection: [4, "selection"], animating: [5, "animating"], scrollable: [6, "scrollable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.header; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.columns; var currVal_5 = _co.rows; var currVal_6 = _co.rowActions; var currVal_7 = _co.rowActionsButtons; var currVal_8 = _co.selection; var currVal_9 = _co.animating; var currVal_10 = _co.scrollable; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.grid; var currVal_1 = _co.compact; var currVal_2 = _co.height; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TableStubContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-stub-content", [], null, null, null, View_TableStubContentComponent_0, RenderType_TableStubContentComponent)), i0.ɵdid(1, 114688, null, 0, i6.TableStubContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableStubContentComponentNgFactory = i0.ɵccf("app-table-stub-content", i6.TableStubContentComponent, View_TableStubContentComponent_Host_0, { grid: "grid", compact: "compact", header: "header", columns: "columns", rows: "rows", rowActions: "rowActions", rowActionsButtons: "rowActionsButtons", selection: "selection", animating: "animating", scrollable: "scrollable", height: "height" }, {}, []);
export { TableStubContentComponentNgFactory as TableStubContentComponentNgFactory };

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ViewContext } from '@modules/customize';
import { FillType, Layer, View } from '@modules/views';

import { FillControl } from '../../controls/fill.control';
import { FillColorButtonComponent } from '../fill-color-button/fill-color-button.component';

@Component({
  selector: 'app-fill-control',
  templateUrl: './fill-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FillControlComponent implements OnInit, OnDestroy {
  @Input() control: FillControl;
  @Input() viewContext: ViewContext;
  @Input() view: View;
  @Input() layer: Layer;
  @Input() fillTypesEnabled: FillType[];
  @Output() colorOpenedChange = new EventEmitter<void>();
  @Output() settingsOpenedChange = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  @ViewChild(FillColorButtonComponent) colorButtonComponent: FillColorButtonComponent;

  settingsOpened = false;
  settingsPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
  ];
  colorInputRepresentation: string;
  fillTypes = FillType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control
      .getColorInputRepresentation()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.colorInputRepresentation = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setSettingsOpened(value: boolean) {
    this.settingsOpened = value;
    this.cd.markForCheck();
    this.settingsOpenedChange.emit();
  }

  toggleSettingsOpened() {
    this.setSettingsOpened(!this.settingsOpened);
  }

  isColorOpened(): boolean {
    return this.colorButtonComponent.isOpened();
  }

  isSettingsOpened(): boolean {
    return this.settingsOpened;
  }
}

import pickBy from 'lodash/pickBy';

import { ProjectGroup, ProjectUser } from './project-user';

export class Environment {
  public uniqueName: string;
  public name: string;
  public color: string;
  public version: string;
  public dateAdd: string;
  public user: ProjectUser;
  public group: ProjectGroup;
  public params = {};

  deserialize(data: Object): Environment {
    this.name = data['name'];
    this.uniqueName = data['unique_name'];
    this.color = data['color'];
    this.dateAdd = data['date_add'];

    if (data['user']) {
      this.user = new ProjectUser().deserialize(data['user']);
    }

    if (data['group']) {
      this.group = new ProjectGroup().deserialize(data['group']);
    }

    if (data['params']) {
      this.params = data['params'];
      this.version = this.params['version'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};

    if (this.version) {
      this.params['version'] = this.version;
    }

    let data: Object = {
      unique_name: this.uniqueName,
      name: this.name,
      color: this.color,
      params: this.params
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get link() {
    return ['project', 'environments', this.uniqueName];
  }
}

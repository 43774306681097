var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest } from 'rxjs';
import { delayWhen, map, switchMap } from 'rxjs/operators';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, QueryAction } from '@modules/actions';
import { FieldType, OptionsType, ParameterField } from '@modules/fields';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, ResourceName, ResourceService, ResourceType, resourceTypeItems } from '@modules/projects';
import { ActionQuery, HttpContentType, HttpMethod, HttpQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
export var MESSAGES_API_RESOURCE_NAME = 'messages_api';
var MessagesConverterForm = /** @class */ (function (_super) {
    __extends(MessagesConverterForm, _super);
    function MessagesConverterForm(currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionService, modelDescriptionStore, actionDescriptionService, actionStore, messageService, apiService, resourceService) {
        var _this = _super.call(this, {
            api_url: new FormControl('', Validators.required)
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceControllerService = resourceControllerService;
        _this.modelDescriptionService = modelDescriptionService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionDescriptionService = actionDescriptionService;
        _this.actionStore = actionStore;
        _this.messageService = messageService;
        _this.apiService = apiService;
        _this.resourceService = resourceService;
        return _this;
    }
    MessagesConverterForm.prototype.syncResource = function (project, environment) {
        var existingResource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == MESSAGES_API_RESOURCE_NAME; });
        var resource = existingResource ? cloneDeep(existingResource) : new Resource();
        var typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.RestApi; });
        resource.uniqueName = MESSAGES_API_RESOURCE_NAME;
        resource.name = 'Messages API';
        resource.type = ResourceType.RestAPI;
        resource.typeItem = typeItem;
        resource.draft = true;
        resource.deleted = false;
        if (!existingResource) {
            return this.resourceService.update(project.uniqueName, environment.uniqueName, resource, [
                'unique_name',
                'name',
                'type',
                'type_item',
                'deploy',
                'token',
                'params',
                'deleted'
            ]);
        }
        else {
            return this.resourceService.create(project.uniqueName, environment.uniqueName, resource);
        }
    };
    MessagesConverterForm.prototype.syncActions = function (project, environment, resource, messagesActions, messagesResource) {
        var _this = this;
        return this.actionStore.getFirst().pipe(map(function (actions) { return actions.filter(function (item) { return item.resource == resource.uniqueName; }); }), switchMap(function (existingActions) {
            var actions = messagesActions.map(function (messagesAction) {
                var existingAction = existingActions.find(function (item) { return item.name == messagesAction.name; });
                var action = existingAction ? cloneDeep(existingAction) : new ActionDescription();
                action.resource = resource.uniqueName;
                action.name = messagesAction.name;
                action.draft = true;
                action.deleted = false;
                _this.syncAction(action, messagesAction, messagesResource);
                return action;
            });
            return _this.actionDescriptionService.createBulk(project.uniqueName, environment.uniqueName, actions);
        }));
    };
    MessagesConverterForm.prototype.syncAction = function (actionDescription, messagesAction, messagesResource) {
        var systemParams = [];
        if (messagesAction.deprecatedModelAction && messagesAction.deprecatedModelAction.for_instance) {
            if (messagesAction.deprecatedModelAction.bulk) {
                systemParams.push.apply(systemParams, [
                    new ParameterField({
                        name: 'ids',
                        verboseName: 'ids',
                        field: FieldType.JSON,
                        required: true
                    }),
                    new ParameterField({
                        name: 'inverseIds',
                        verboseName: 'inverseIds',
                        field: FieldType.Boolean,
                        required: false
                    })
                ]);
            }
            else {
                systemParams.push(new ParameterField({
                    name: 'id',
                    verboseName: 'id',
                    field: FieldType.Text,
                    required: true
                }));
            }
        }
        var userParams = messagesAction.actionParams.map(function (item) {
            item.name = "ext_" + item.name;
            return item;
        });
        var allParams = systemParams.concat(userParams);
        actionDescription.verboseName = messagesAction.verboseName;
        actionDescription.type = ActionType.Query;
        actionDescription.queryAction = new QueryAction();
        actionDescription.queryAction.query = new ActionQuery();
        actionDescription.queryAction.query.queryType = QueryType.Http;
        actionDescription.actionParams = allParams;
        actionDescription.outputs = messagesAction.outputs;
        actionDescription.arrayOutput = messagesAction.arrayOutput;
        var httpQuery = new HttpQuery();
        httpQuery.method = HttpMethod.POST;
        httpQuery.url = this.messageService.messagesUrl(messagesResource);
        httpQuery.headers = [
            { name: 'Authorization', value: 'JWT {{user.token}}' },
            { name: 'Content-Type', value: 'application/json' }
        ];
        var systemParamsBody = systemParams.reduce(function (acc, item) {
            acc[item.name] = "{{params." + item.name + "}}";
            return acc;
        }, {});
        var userParamsBody = userParams.reduce(function (acc, item) {
            acc[item.name] = "{{params." + item.name + "}}";
            return acc;
        }, {});
        httpQuery.bodyType = HttpContentType.JSON;
        httpQuery.body = JSON.stringify({
            name: MessageName.ExecuteAction,
            params: __assign({ name: "" + actionDescription.name }, (messagesAction.deprecatedModelAction && { model: messagesAction.deprecatedModelAction.model }), systemParamsBody, { params: userParamsBody })
        }, undefined, 2).replace(/"{{([^}]+)}}"/g, '$1');
        actionDescription.queryAction.query.httpQuery = httpQuery;
        return actionDescription;
    };
    MessagesConverterForm.prototype.syncModelDescriptions = function (project, environment, resource, jetBridgeModelDescriptions, messagesActions, messagesResource) {
        var _this = this;
        return this.modelDescriptionStore.getFirst().pipe(map(function (modelDescriptions) { return modelDescriptions.filter(function (item) { return item.resource == resource.uniqueName; }); }), switchMap(function (existingModelDescriptions) {
            var optionsModelDescriptions = jetBridgeModelDescriptions.reduce(function (acc, jetBridgeModelDescription) {
                jetBridgeModelDescription.dbFields
                    .filter(function (field) {
                    return field.field == FieldType.Select &&
                        field.params &&
                        field.params['options_type'] == OptionsType.MessagesAPI;
                })
                    .forEach(function (field) {
                    var name = ['field_options', jetBridgeModelDescription.model, field.name].join('_');
                    var existingModelDescription = existingModelDescriptions.find(function (item) { return item.model == name; });
                    var modelDescription = existingModelDescription
                        ? cloneDeep(existingModelDescription)
                        : new ModelDescription();
                    modelDescription.resource = resource.uniqueName;
                    modelDescription.model = name;
                    modelDescription.draft = true;
                    modelDescription.deleted = false;
                    _this.syncOptionsModel(modelDescription, jetBridgeModelDescription, field, messagesResource);
                    acc.push(modelDescription);
                });
                return acc;
            }, []);
            var elementStatusModelDescriptions = messagesActions
                .filter(function (messagesAction) { return messagesAction.deprecatedDynamicStatus; })
                .map(function (messagesAction) {
                var name = ['element_status', messagesAction.name].join('_');
                var existingModelDescription = existingModelDescriptions.find(function (item) { return item.model == name; });
                var modelDescription = existingModelDescription
                    ? cloneDeep(existingModelDescription)
                    : new ModelDescription();
                modelDescription.resource = resource.uniqueName;
                modelDescription.model = name;
                modelDescription.draft = true;
                modelDescription.deleted = false;
                _this.syncElementStatusModel(modelDescription, messagesAction, messagesResource);
                return modelDescription;
            });
            return _this.modelDescriptionService.createBulk(project.uniqueName, environment.uniqueName, optionsModelDescriptions.concat(elementStatusModelDescriptions));
        }));
    };
    MessagesConverterForm.prototype.syncOptionsModel = function (modelDescription, jetBridgeModelDescription, jetBridgeField, messagesResource) {
        var modelIdParam = new ParameterField({
            name: 'model_id',
            verboseName: 'record id',
            field: FieldType.Text,
            required: false
        });
        var systemParams = [modelIdParam];
        modelDescription.verboseName = [
            'options for',
            jetBridgeModelDescription.verboseNamePlural || jetBridgeModelDescription.model,
            jetBridgeField.verboseName || jetBridgeField.name
        ].join(' ');
        modelDescription.fields = ['name', 'value', 'color'].map(function (item) {
            var dbField = new ModelDbField();
            dbField.name = item;
            dbField.field = FieldType.Text;
            dbField.params = {};
            dbField.updateFieldDescription();
            var field = new ModelField();
            field.name = dbField.name;
            field.type = ModelFieldType.Db;
            field.item = dbField;
            return field;
        });
        var httpQuery = new HttpQuery();
        httpQuery.method = HttpMethod.POST;
        httpQuery.url = this.messageService.messagesUrl(messagesResource);
        httpQuery.headers = [
            { name: 'Authorization', value: 'JWT {{user.token}}' },
            { name: 'Content-Type', value: 'application/json' }
        ];
        httpQuery.bodyType = HttpContentType.JSON;
        httpQuery.body = JSON.stringify({
            name: MessageName.GetFieldOptions,
            params: {
                field: jetBridgeField.name,
                params: jetBridgeField.params,
                model: jetBridgeModelDescription.model,
                model_primary_key: "{{params." + modelIdParam.name + "}}"
            }
        }, undefined, 2).replace(/"{{([^}]+)}}"/g, '$1');
        modelDescription.getQuery = new ListModelDescriptionQuery();
        modelDescription.getQuery.queryType = QueryType.Http;
        modelDescription.getQuery.httpQuery = httpQuery;
        modelDescription.getParameters = systemParams;
        modelDescription.getDetailParametersUseDefaults = false;
        return modelDescription;
    };
    MessagesConverterForm.prototype.syncElementStatusModel = function (modelDescription, messagesAction, messagesResource) {
        var modelIdParam = new ParameterField({
            name: 'model_id',
            verboseName: 'record id',
            field: FieldType.Text,
            required: messagesAction.deprecatedModelAction && messagesAction.deprecatedModelAction.for_instance
        });
        var systemParams = [modelIdParam];
        modelDescription.verboseName = ['status for', messagesAction.verboseName || messagesAction.name].join(' ');
        modelDescription.fields = ['hidden', 'disabled'].map(function (item) {
            var dbField = new ModelDbField();
            dbField.name = item;
            dbField.field = FieldType.Text;
            dbField.params = {};
            dbField.updateFieldDescription();
            var field = new ModelField();
            field.name = dbField.name;
            field.type = ModelFieldType.Db;
            field.item = dbField;
            return field;
        });
        var httpQuery = new HttpQuery();
        httpQuery.method = HttpMethod.POST;
        httpQuery.url = this.messageService.messagesUrl(messagesResource);
        httpQuery.headers = [
            { name: 'Authorization', value: 'JWT {{user.token}}' },
            { name: 'Content-Type', value: 'application/json' }
        ];
        httpQuery.bodyType = HttpContentType.JSON;
        httpQuery.body = JSON.stringify({
            name: MessageName.GetElementStatus,
            params: {
                element: 'action_element',
                name: messagesAction.name,
                context: {
                    model: messagesAction.deprecatedModelAction ? messagesAction.deprecatedModelAction.model : undefined,
                    model_primary_key: "{{params." + modelIdParam.name + "}}"
                }
            }
        }, undefined, 2).replace(/"{{([^}]+)}}"/g, '$1');
        modelDescription.getQuery = new ListModelDescriptionQuery();
        modelDescription.getQuery.queryType = QueryType.Http;
        modelDescription.getQuery.httpQuery = httpQuery;
        modelDescription.getParameters = systemParams;
        modelDescription.getDetailParametersUseDefaults = false;
        return modelDescription;
    };
    MessagesConverterForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var project = this.currentProjectStore.instance;
        var environment = this.currentEnvironmentStore.instance;
        var messagesController = this.resourceControllerService.get(ResourceType.MessagesAPI);
        var messagesResource = new Resource();
        var messagesUrl = this.controls.api_url.value + "messages/";
        var jetBridgeController = this.resourceControllerService.get(ResourceType.JetBridge);
        var jetBridgeResource = new Resource();
        var jetBridgeUrl = this.controls.api_url.value;
        jetBridgeResource.typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.Django; });
        jetBridgeResource.params = { url: jetBridgeUrl };
        messagesResource.typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.MessagesApi; });
        messagesResource.params = { url: messagesUrl };
        return combineLatest(this.syncResource(project, environment), jetBridgeController.modelDescriptionGet(jetBridgeResource), messagesController.actionDescriptionGet(messagesResource)).pipe(delayWhen(function (_a) {
            var resource = _a[0], jetBridgeModelDescriptions = _a[1], messagesActions = _a[2];
            return _this.syncActions(project, environment, resource, messagesActions, messagesResource);
        }), delayWhen(function (_a) {
            var resource = _a[0], jetBridgeModelDescriptions = _a[1], messagesActions = _a[2];
            return _this.syncModelDescriptions(project, environment, resource, jetBridgeModelDescriptions, messagesActions, messagesResource);
        }), delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), delayWhen(function () { return _this.modelDescriptionStore.getFirst(true); }), delayWhen(function () { return _this.actionStore.getFirst(true); }), map(function (_a) {
            var resource = _a[0];
            return resource;
        }));
    };
    return MessagesConverterForm;
}(FormGroup));
export { MessagesConverterForm };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from 'lodash/isEqual';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export function editorJsTextToQuillOp(text, extraAttrs) {
    text += '\n';
    var tags = [
        { name: 'bold', regex: /(<b(?:\s([^>]+))?>)(?:(?!<\/b).)*(<\/b>)/g },
        { name: 'italic', regex: /(<i(?:\s([^>]+))?>)(?:(?!<\/i).)*(<\/i>)/g },
        {
            name: 'link',
            regex: /(<a(?:\s([^>]+))?>)(?:(?!<\/a).)*(<\/a>)/g,
            getValue: function (attrs) {
                if (!attrs) {
                    return;
                }
                var match = /href="([^"]+)"/.exec(attrs);
                if (match) {
                    return match[1];
                }
            }
        }
    ];
    var textTags = {};
    for (var _i = 0, tags_1 = tags; _i < tags_1.length; _i++) {
        var tag = tags_1[_i];
        var match = void 0;
        while ((match = tag.regex.exec(text))) {
            var value = tag.getValue ? tag.getValue(match[2]) : true;
            for (var i = match.index; i < match.index + match[1].length; ++i) {
                textTags[i] = { ignore: true };
            }
            for (var i = match.index + match[1].length; i < match.index + match[0].length - match[3].length; ++i) {
                if (!textTags[i]) {
                    textTags[i] = {};
                }
                textTags[i][tag.name] = value;
            }
            for (var i = match.index + match[0].length - match[3].length; i < match.index + match[0].length; ++i) {
                textTags[i] = { ignore: true };
            }
        }
    }
    return text.split('').reduce(function (acc, char, i) {
        var prevOp = acc[acc.length - 1];
        var attributes = textTags[i];
        if (extraAttrs) {
            attributes = __assign({}, attributes, extraAttrs);
        }
        if (!attributes || !attributes['ignore']) {
            var currentOp = void 0;
            if (!prevOp || !isEqual(prevOp.attributes, attributes)) {
                currentOp = __assign({ insert: char }, (attributes ? { attributes: attributes } : {}));
                acc.push(currentOp);
            }
            else {
                currentOp = prevOp;
                currentOp.insert += char;
            }
        }
        return acc;
    }, []);
}
export function editorJsOutputDataToQuillDelta(markup) {
    var result = markup.blocks.reduce(function (acc, item) {
        if (item.type == 'formula') {
            acc.push({
                insert: {
                    'context-formula': item.data['value']
                }
            });
        }
        else if (item.type == 'header') {
            var ops = editorJsTextToQuillOp(item.data['text'], {
                header: item.data['level'] || 2
            });
            acc.push.apply(acc, ops);
        }
        else if (item.type == 'paragraph') {
            var ops = editorJsTextToQuillOp(item.data['text']);
            acc.push.apply(acc, ops);
        }
        return acc;
    }, []);
    var added = 0;
    result.forEach(function (item, i) {
        if (item.insert['context-formula']) {
            var nextIndex = i + 1 + added;
            if (result[nextIndex] && typeof result[nextIndex]['insert'] == 'string') {
                result[nextIndex]['insert'] = "\n" + result[nextIndex]['insert'];
            }
            else {
                result.splice(nextIndex, 0, { insert: '\n' });
                ++added;
            }
        }
    });
    return {
        ops: result
    };
}
var TextElementItem = /** @class */ (function (_super) {
    __extends(TextElementItem, _super);
    function TextElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Text;
        return _this;
    }
    TextElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        // this.text = this.params['text'];
        // if (this.params['markup']) {
        //   this.markup = this.params['markup'];
        // }
        if (this.params['quill_delta']) {
            this.quillDelta = this.params['quill_delta'];
        }
        else if (this.params['markup']) {
            // Backward compatibility
            this.quillDelta = editorJsOutputDataToQuillDelta(this.params['markup']);
        }
        return this;
    };
    TextElementItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        // data['params']['text'] = this.text;
        // data['params']['markup'] = this.markup;
        data['params']['quill_delta'] = this.quillDelta;
        return data;
    };
    Object.defineProperty(TextElementItem.prototype, "analyticsName", {
        get: function () {
            return 'text';
        },
        enumerable: true,
        configurable: true
    });
    TextElementItem.prototype.defaultName = function () {
        return 'Text';
    };
    return TextElementItem;
}(ElementItem));
export { TextElementItem };
registerElementForType(ElementType.Text, TextElementItem);

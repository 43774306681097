<div class="carousel" [style.height.px]="height">
  <div class="carousel__viewport">
    <div class="carousel__layout">
      <app-grid-stub-item
        *ngFor="let item of rowsRange; let i = index"
        [index]="i"
        [columns]="columns"
        [rowCards]="rowCards"
        [height]="cardHeight"
        [gapHorizontal]="gapHorizontal"
        [animating]="animating"
      >
      </app-grid-stub-item>
    </div>
  </div>
</div>

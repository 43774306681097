import Delta from 'quill-delta';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { asyncScheduler, concat, Observable, of } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { isSet } from '@shared';

// TODO: Refactor imports
import { Input } from '../../../modules/fields/data/input';
import { contextInputValueTypes } from '../../../modules/fields/data/input-value-type';

export function getQuillHtmlConverter(
  delta: Delta,
  options: {
    applyContextFormula: (input: Input) => any;
    addContextFormulaClass?: boolean;
  }
): QuillDeltaToHtmlConverter {
  const converter = new QuillDeltaToHtmlConverter(delta.ops, {});

  converter.renderCustomWith((customOp, contextOp) => {
    if (customOp.insert.type === 'context-formula') {
      const input = new Input();

      if (customOp.insert.value) {
        input.deserialize(customOp.insert.value);
      }

      let value: any;

      try {
        value = options.applyContextFormula(input);
      } catch (e) {}

      if (!isSet(value)) {
        value = '';
      }

      const result = options.addContextFormulaClass ? `<span class="inline-input">${value}</span>` : value;
      return customOp.attributes ? surroundHtmlWithQuillAttributesApplied(result, customOp.attributes) : result;
    }
  });

  return converter;
}

export function quillDeltaToHtml(
  delta: Delta,
  options: {
    applyContextFormula: (input: Input) => any;
    addContextFormulaClass?: boolean;
    converter?: QuillDeltaToHtmlConverter;
  }
): string {
  if (!delta) {
    return;
  }

  const converter = options.converter || getQuillHtmlConverter(delta, options);
  return converter.convert();
}

export function quillDeltaToHtml$(
  delta: Delta,
  options: {
    applyContextFormula: (input: Input) => any;
    addContextFormulaClass?: boolean;
    context?: ViewContext;
  }
): Observable<string> {
  if (!delta) {
    return of(undefined);
  }

  const converter = getQuillHtmlConverter(delta, options);
  const hasContextFormula =
    options.context &&
    delta.ops.some(item => {
      return (
        item['insert'] &&
        item['insert']['context-formula'] &&
        contextInputValueTypes.includes(item['insert']['context-formula']['value_type'])
      );
    });
  const obs = hasContextFormula
    ? concat(of(options.context.outputValues), options.context.outputValues$).pipe(
        throttleTime(10, asyncScheduler, { leading: true, trailing: true })
      )
    : of({});

  return obs.pipe(map(() => converter.convert()));
}

export function surroundHtmlWithQuillAttributesApplied(html: string, attributes: Object): string {
  const placeholder = '____jet-context-formula____';
  const ops = [{ insert: placeholder, attributes }];
  const converter = new QuillDeltaToHtmlConverter(ops, { paragraphTag: 'span' });
  const result = converter.convert();

  return result.replace(placeholder, html);
}

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { isSet } from '@shared';

import { OptionEnabledControl, OptionEnabledControlValue } from './option-enabled.control';

export class OptionEnabledArray extends FormArray {
  controls: OptionEnabledControl[];
  defaultDisabled: string[] = [];
  source: string;

  readonly value: OptionEnabledControlValue[];

  patchValue(value: OptionEnabledControlValue[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.setValue(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  setValue(value: OptionEnabledControlValue[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(value, options);
  }

  setControls(controls: OptionEnabledControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: OptionEnabledControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: OptionEnabledControlValue): OptionEnabledControl {
    const control = new OptionEnabledControl(item);
    control.markAsPristine();
    return control;
  }

  appendControl(item?: OptionEnabledControlValue): OptionEnabledControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  syncControls(names: string[], options: { source?: string } = {}) {
    names
      .filter(name => !this.controls.find(control => control.controls.name.value == name))
      .forEach(name => this.appendControl({ name: name }));

    this.controls
      .filter(control => !names.find(name => name == control.controls.name.value))
      .forEach(control => this.removeControl(control));

    this.source = options.source;
  }

  enableAll() {
    this.controls.forEach(item => item.controls.enabled.patchValue(true));
  }

  disableAll() {
    this.controls.forEach(item => item.controls.enabled.patchValue(false));
  }

  enableDefault() {
    this.controls.forEach(item => {
      const defaultDisabled = this.defaultDisabled.includes(item.controls.name.value);
      item.controls.enabled.patchValue(!defaultDisabled);
    });
  }

  isAllEnabled(): boolean {
    return this.controls.every(item => item.controls.enabled.value);
  }

  isAllDisabled(): boolean {
    return this.controls.every(item => !item.controls.enabled.value);
  }

  isSource(source: string): boolean {
    return this.source == source;
  }

  isSourceSet() {
    return this.source !== undefined;
  }
}

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { View } from '@modules/views';

import { ImportSketchFileComponent } from '../../components/import-sketch-file/import-sketch-file.component';

export interface ImportSketchFileEvent {
  view?: View;
  cancelled?: boolean;
}

@Injectable()
export class ImportSketchFileController {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  importFile(options: { analyticsSource?: string } = {}): Observable<ImportSketchFileEvent> {
    const obs$ = new ReplaySubject<ImportSketchFileEvent>();

    this.popupService.push({
      component: ImportSketchFileComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentOrange: true,
      inputs: {
        analyticsSource: options.analyticsSource
      },
      outputs: {
        importView: [
          result => {
            obs$.next({ view: result });
          }
        ],
        cancelled: [
          () => {
            obs$.next({ cancelled: true });
          }
        ]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs$.next({ cancelled: true });
        }
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$;
  }
}

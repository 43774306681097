/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/drag-drop";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../action-outputs-edit-item/action-outputs-edit-item.component.ngfactory";
import * as i6 from "../action-outputs-edit-item/action-outputs-edit-item.component";
import * as i7 from "./action-outputs-edit.component";
var styles_ActionOutputsEditComponent = [];
var RenderType_ActionOutputsEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionOutputsEditComponent, data: {} });
export { RenderType_ActionOutputsEditComponent as RenderType_ActionOutputsEditComponent };
function View_ActionOutputsEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 7, "div", [["cdkDrag", ""], ["class", "sidebar-list__item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i1.ɵb, null, [i1.CdkDrag]), i0.ɵdid(2, 4866048, [[1, 4]], 3, i1.CdkDrag, [i0.ElementRef, [3, i1.CDK_DROP_LIST], i2.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i3.ViewportRuler, i1.DragDropRegistry, i1.CDK_DRAG_CONFIG, [2, i4.Directionality], i1.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 2, { _handles: 1 }), i0.ɵqud(335544320, 3, { _previewTemplate: 0 }), i0.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-action-outputs-edit-item", [], null, [[null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("delete" === en)) {
        var pd_0 = (_co.deleteItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ActionOutputsEditItemComponent_0, i5.RenderType_ActionOutputsEditItemComponent)), i0.ɵdid(7, 114688, null, 0, i6.ActionOutputsEditItemComponent, [i0.ChangeDetectorRef], { control: [0, "control"] }, { delete: "delete" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ActionOutputsEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "sidebar-list-item__drag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "button button_icon button_orange button_extra-small"], ["style", "margin: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button__icon icon-power"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Action"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Not specified"]))], null, null); }
export function View_ActionOutputsEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["cdkDropList", ""], ["class", "sidebar-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.CDK_DROP_LIST, null, [i1.CdkDropList]), i0.ɵdid(2, 1196032, null, 1, i1.CdkDropList, [i0.ElementRef, i1.DragDropRegistry, i0.ChangeDetectorRef, [2, i4.Directionality], [3, i1.CdkDropListGroup], [2, i2.DOCUMENT], i1.DragDrop], { lockAxis: [0, "lockAxis"] }, { dropped: "cdkDropListDropped" }), i0.ɵqud(603979776, 1, { _draggables: 1 }), i0.ɵprd(256, null, i1.CdkDropListGroup, undefined, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionOutputsEditComponent_1)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionOutputsEditComponent_2)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "sidebar-list__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [["class", "button button_orange-transparent button_small sidebar-list__buttons-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "icon-plus button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = "y"; _ck(_v, 2, 0, currVal_4); var currVal_5 = _co.control.controls; _ck(_v, 6, 0, currVal_5); var currVal_6 = !_co.control.controls.length; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = i0.ɵnov(_v, 2).disabled; var currVal_2 = i0.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i0.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = _co.addLabel; _ck(_v, 13, 0, currVal_7); }); }
export function View_ActionOutputsEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-outputs-edit", [], null, null, null, View_ActionOutputsEditComponent_0, RenderType_ActionOutputsEditComponent)), i0.ɵdid(1, 114688, null, 0, i7.ActionOutputsEditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionOutputsEditComponentNgFactory = i0.ɵccf("app-action-outputs-edit", i7.ActionOutputsEditComponent, View_ActionOutputsEditComponent_Host_0, { control: "control", addLabel: "addLabel", addDefaults: "addDefaults", addBaseName: "addBaseName", analyticsSource: "analyticsSource" }, {}, []);
export { ActionOutputsEditComponentNgFactory as ActionOutputsEditComponentNgFactory };

<div class="photo-editor" #viewport>
  <ng-container *ngIf="!loading">
    <div class="photo-editor__image" #image>
      <div class="photo-editor__image-inner">
        <canvas [width]="width" [height]="height" #canvas> </canvas>
      </div>
    </div>
    <div class="photo-editor__bounds" #bounds>
      <div class="photo-editor__bounds-inner">
        <span class="photo-editor__bounds-corner photo-editor__bounds-corner_position_top-left" #corner></span>
        <span class="photo-editor__bounds-corner photo-editor__bounds-corner_position_top-right" #corner></span>
        <span class="photo-editor__bounds-corner photo-editor__bounds-corner_position_bottom-left" #corner></span>
        <span class="photo-editor__bounds-corner photo-editor__bounds-corner_position_bottom-right" #corner></span>
      </div>
    </div>
    <div class="photo-editor__toolbar">
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-item photo-editor__toolbar-item_type_zoom-out"
        (click)="scaleDown()"
      ></a>
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-item photo-editor__toolbar-item_type_zoom-in"
        (click)="scaleUp()"
      ></a>
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-item photo-editor__toolbar-item_type_rotate-left"
        (click)="rotate(false)"
      ></a>
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-item photo-editor__toolbar-item_type_rotate-right"
        (click)="rotate(true)"
      ></a>
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-item photo-editor__toolbar-item_type_fullscreen-exit"
        (click)="scaleFit()"
      ></a>
      <a href="javascript:void(0)" class="photo-editor__toolbar-button" (click)="cancel()">Cancel</a>
      <a
        href="javascript:void(0)"
        class="photo-editor__toolbar-button photo-editor__toolbar-button_primary"
        (click)="save()"
        >Save</a
      >
    </div>
    <div class="photo-editor__preview" #preview>
      <div class="photo-editor__preview-inner" #preview_inner>
        <canvas class="photo-editor__preview-canvas" width="100%" height="100%" #preview_canvas> </canvas>
      </div>
    </div>
  </ng-container>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Frame, FrameTranslate, Layer, Translate } from '@modules/views';
import { isSet } from '@shared';

import { ViewEditorContext } from '../../services/view-editor-context/view-editor.context';
import { snapFrame } from '../../utils/guides';

@Component({
  selector: 'app-view-editor-bounds',
  templateUrl: './view-editor-bounds.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorBoundsComponent implements OnInit, OnDestroy {
  @Input() frame: Frame;
  @Input() translate: Translate = { x: 0, y: 0 };
  @Input() snapFrame = false;
  @Input() snapFrameContainer: Layer[];
  @Input() snapFrameExceptLayers: Layer[];
  @Input() constrainProportions = false;
  @Input() active = false;
  @Input() resizeHorizontal = false;
  @Input() resizeVertical = false;
  @Output() resizeStarted = new EventEmitter<void>();
  @Output() resizeFinished = new EventEmitter<Partial<Frame>>();
  @Output() updateFrame = new EventEmitter<Partial<Frame>>();

  resizeOriginalFrame: Frame;
  resizeNewFrame: Partial<Frame>;
  resizeSnapFrames: FrameTranslate[];
  updateFrameRaw = new Subject<Partial<Frame>>();

  constructor(private editorContext: ViewEditorContext) {}

  ngOnInit() {
    this.updateFrameRaw
      .pipe(
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        untilDestroyed(this)
      )
      .subscribe(frame => this.updateFrame.emit(frame));
  }

  ngOnDestroy(): void {}

  onResizeStarted() {
    this.resizeOriginalFrame = new Frame(this.frame);
    this.resizeStarted.emit();

    if (this.snapFrame) {
      this.resizeSnapFrames = this.editorContext.getFrames({
        onlyContainers: [this.snapFrameContainer],
        exceptLayers: this.snapFrameExceptLayers
      });

      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
    }
  }

  applyResize(
    resize: { top?: number; right?: number; bottom?: number; left?: number; shift?: boolean; alt?: boolean } = {}
  ): Partial<Frame> {
    const frame: Partial<Frame> = {};
    const originalFrame = this.resizeOriginalFrame;
    let xInverse = false;
    let yInverse = false;

    if (resize.left) {
      const movePosition = originalFrame.x + resize.left;
      const stillPosition = originalFrame.x + originalFrame.width;
      xInverse = movePosition < stillPosition;
      const width = Math.abs(movePosition - stillPosition);

      frame.x = xInverse ? stillPosition - width : stillPosition;
      frame.width = width;
    } else if (resize.right) {
      const movePosition = originalFrame.x + originalFrame.width + resize.right;
      const stillPosition = originalFrame.x;
      xInverse = movePosition < stillPosition;
      const width = Math.abs(movePosition - stillPosition);

      frame.x = xInverse ? stillPosition - width : stillPosition;
      frame.width = width;
    }

    if (resize.top) {
      const movePosition = originalFrame.y + resize.top;
      const stillPosition = originalFrame.y + originalFrame.height;
      yInverse = movePosition < stillPosition;
      const height = Math.abs(movePosition - stillPosition);

      frame.y = yInverse ? stillPosition - height : stillPosition;
      frame.height = height;
    } else if (resize.bottom) {
      const movePosition = originalFrame.y + originalFrame.height + resize.bottom;
      const stillPosition = originalFrame.y;
      yInverse = movePosition < stillPosition;
      const height = Math.abs(movePosition - stillPosition);

      frame.y = yInverse ? stillPosition - height : stillPosition;
      frame.height = height;
    }

    const constrainProportions = this.constrainProportions || resize.shift;
    const proportion = this.resizeOriginalFrame.height
      ? this.resizeOriginalFrame.width / this.resizeOriginalFrame.height
      : 1;

    if (constrainProportions) {
      if (frame.width >= frame.height) {
        frame.height = round(frame.width / proportion, 2);
        frame.y = yInverse ? round(originalFrame.y - frame.height, 2) : originalFrame.y;
      } else {
        frame.width = round(frame.height * proportion, 2);
        frame.x = xInverse ? round(originalFrame.x - frame.width, 2) : originalFrame.x;
      }
    }

    if (this.resizeSnapFrames) {
      const newFrame = new Frame(this.resizeOriginalFrame).patch(frame);
      const snap = snapFrame({
        base: new FrameTranslate({
          frame: newFrame,
          translate: this.translate
        }),
        others: this.resizeSnapFrames,
        horizontalSnapX: xInverse,
        horizontalSnapWidth: true,
        horizontalOriginSnaps: xInverse ? [0] : [1],
        verticalSnapY: yInverse,
        verticalSnapHeight: true,
        verticalOriginSnaps: yInverse ? [0] : [1]
      });

      const snapSizes = [snap.updateFrame.width, snap.updateFrame.height].filter(item => isSet(item));

      if (constrainProportions && snapSizes.length) {
        if (isSet(snap.updateFrame.width)) {
          snap.updateFrame.height = round(snap.updateFrame.width / proportion, 2);
          snap.updateFrame.y = yInverse ? round(originalFrame.y - snap.updateFrame.height, 2) : originalFrame.y;
        } else if (isSet(snap.updateFrame.height)) {
          snap.updateFrame.width = round(snap.updateFrame.height * proportion, 2);
          snap.updateFrame.x = xInverse ? round(originalFrame.x - snap.updateFrame.width, 2) : originalFrame.x;
        }
      }

      Object.assign(frame, snap.updateFrame);

      this.editorContext.setHorizontalGuides(snap.horizontalGuides);
      this.editorContext.setVerticalGuides(snap.verticalGuides);
    }

    // TODO: Move alt before snap, integrate shift/alt to snapFrame
    if (resize.alt) {
      if (isSet(frame.x) && isSet(frame.width)) {
        const movePositionX = xInverse ? frame.x : frame.x + frame.width;
        const originalXCenter = originalFrame.x + originalFrame.width * 0.5;
        const halfWidth = Math.abs(movePositionX - originalXCenter);

        frame.x = round(originalXCenter - halfWidth, 2);
        frame.width = round(halfWidth * 2, 2);

        if (constrainProportions) {
          const originalYCenter = originalFrame.y + originalFrame.height * 0.5;

          frame.height = round(frame.width / proportion, 2);
          frame.y = round(originalYCenter - frame.height * 0.5, 2);
        }
      } else if (isSet(frame.y) && isSet(frame.height)) {
        const movePositionY = yInverse ? frame.y : frame.y + frame.height;
        const originalYCenter = originalFrame.y + originalFrame.height * 0.5;
        const halfHeight = Math.abs(movePositionY - originalYCenter);

        frame.y = round(originalYCenter - halfHeight, 2);
        frame.height = round(halfHeight * 2, 2);

        if (constrainProportions) {
          const originalXCenter = originalFrame.x + originalFrame.width * 0.5;

          frame.width = round(frame.height * proportion, 2);
          frame.x = round(originalXCenter - frame.width * 0.5, 2);
        }
      }
    }

    if (isSet(frame.x) && frame.x == originalFrame.x) {
      delete frame.x;
    }

    if (isSet(frame.y) && frame.y == originalFrame.y) {
      delete frame.y;
    }

    if (isSet(frame.width) && frame.width == originalFrame.width) {
      delete frame.width;
    }

    if (isSet(frame.height) && frame.height == originalFrame.height) {
      delete frame.height;
    }

    this.resizeNewFrame = frame;
    this.updateFrameRaw.next(frame);

    return frame;
  }

  onResizeFinished() {
    const frame = this.resizeNewFrame;

    this.resizeOriginalFrame = undefined;
    this.resizeNewFrame = undefined;
    this.resizeSnapFrames = undefined;

    this.resizeFinished.emit(frame);

    if (frame) {
      this.updateFrame.emit(frame);
    }

    if (this.snapFrame) {
      this.editorContext.removeHorizontalGuides();
      this.editorContext.removeVerticalGuides();
    }
  }
}

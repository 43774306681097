<app-project-empty [animating]="opening">
  <app-section [screen]="true">
    <app-table-stub [animating]="opening" [scrollable]="true"></app-table-stub>
  </app-section>
</app-project-empty>

<div class="popups popups_visible">
  <div class="popup-background" #background></div>
  <div class="popup-container" #root>
    <div class="popup popup_shadow">
      <div *ngIf="!project" class="demo-selector">
        <div class="demo-selector__subtitle">Demo Apps</div>
        <div class="demo-selector__title">Welcome to Live Demo!</div>
        <div class="demo-selector__description">
          <div class="demo-selector__description-inner">
            Let's make your experience personal. What are you interested in?
          </div>
        </div>
        <ng-container *ngIf="!other">
          <div *ngIf="items" class="demo-selector__items" [class.demo-selector__items_hovered]="itemHovered">
            <div class="demo-selector__items-inner">
              <a
                *ngFor="let item of items"
                href="javascript:void(0)"
                class="demo-selector__item"
                [ngClass]="['create-demo-' + item.uniqueName]"
                [appClickEvent]="[
                  analyticsEvents.Undocumented.LiveDemoClickIndustry,
                  { IndustryProjectId: item.uniqueName }
                ]"
                (click)="chooseProject(item)"
                (mouseenter)="setItemHovered(true)"
                (mouseleave)="setItemHovered(false)"
              >
                <div class="demo-selector__item-logo" [ngClass]="['demo-selector__item-logo_color_' + item.logoColor]">
                  <span class="demo-selector__item-logo-inner" [style.background-image]="'url(' + item.logo + ')'">
                    <ng-container *ngIf="!item.logo">{{ item.initials }}</ng-container>
                  </span>
                </div>
                <div class="demo-selector__item-title">{{ item.name }}</div>
              </a>
              <!--              <a-->
              <!--                href="javascript:void(0)"-->
              <!--                class="demo-selector__item"-->
              <!--                [ngClass]="['show-other-demo']"-->
              <!--                (click)="setOther(true)"-->
              <!--                (mouseenter)="setItemHovered(true)"-->
              <!--                (mouseleave)="setItemHovered(false)"-->
              <!--              >-->
              <!--                <div class="demo-selector__item-logo demo-selector__item-logo_button">-->
              <!--                  <span class="demo-selector__item-logo-inner icon icon-plus"></span>-->
              <!--                </div>-->
              <!--                <div class="demo-selector__item-title">Other</div>-->
              <!--              </a>-->
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="other">
          <div class="demo-selector__list">
            <div class="demo-selector__list-title">Choose other Industry</div>
            <div class="demo-selector__list-items">
              <a
                *ngFor="let item of otherIndustries"
                href="javascript:void(0)"
                class="demo-selector__list-item"
                [ngClass]="['other-demo-' + item.name]"
                (click)="sendRequest(item.name)"
              >
                <span class="icon demo-selector__list-item-icon" [ngClass]="['icon-' + item.icon]"></span>
                {{ item.name }}
              </a>
            </div>
            <div class="demo-selector__list-form">
              <input
                class="input demo-selector__list-form-input"
                type="text"
                placeholder="Or enter not included in the list..."
                [(ngModel)]="customIndustry"
              />
              <button
                class="button button_primary demo-selector__list-form-submit"
                type="submit"
                [disabled]="!customIndustry"
                (click)="sendRequest(customIndustry)"
              >
                Continue
              </button>
            </div>
            <a href="javascript:void(0)" class="demo-selector__back" (click)="setOther(false)">
              <div class="demo-selector__back-icon icon icon-arrow_backward_2"></div>
            </a>
          </div>
        </ng-container>
      </div>
      <app-project-jobs *ngIf="project" [project]="project" [createDemo]="true"></app-project-jobs>
    </div>
  </div>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Maximum rating'">
    <app-slider
      [baseControl]="form.form.controls['max_value']"
      [min]="1"
      [minLabel]="'1'"
      [max]="10"
      [maxLabel]="'10'"
      [stickToSteps]="true"
      [orange]="true"
      [thumbInside]="true"
    ></app-slider>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Allow half rating'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'allow_half',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

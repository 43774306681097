/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/error/error.component.ngfactory";
import * as i2 from "../../../../shared/components/error/error.component";
import * as i3 from "./redirect.component";
var styles_RedirectComponent = [];
var RenderType_RedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RedirectComponent, data: {} });
export { RenderType_RedirectComponent as RenderType_RedirectComponent };
export function View_RedirectComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "app-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i2.ErrorComponent, [], { title: [0, "title"], fill: [1, "fill"], button: [2, "button"], buttonLink: [3, "buttonLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(-1, 0, [" Unfortunately, app was not found. "])), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Maybe you don't have access to it or followed the wrong link. "])), (_l()(), i0.ɵeld(6, 0, null, 0, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "App not found"; var currVal_1 = true; var currVal_2 = "Go to your Apps"; var currVal_3 = _ck(_v, 2, 0, "/"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_RedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-redirect", [], null, null, null, View_RedirectComponent_0, RenderType_RedirectComponent)), i0.ɵdid(1, 114688, null, 0, i3.RedirectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectComponentNgFactory = i0.ɵccf("app-redirect", i3.RedirectComponent, View_RedirectComponent_Host_0, {}, {}, []);
export { RedirectComponentNgFactory as RedirectComponentNgFactory };

<app-value-format-edit
  [control]="form.form.controls['value_format']"
  [field]="fieldTypes.Number"
  [formatInputEnabled]="!editable"
  [prefixEnabled]="true"
  [postfixEnabled]="!editable"
  [numberFormatOptionsEnabled]="!editable"
  [context]="context"
  [analyticsSource]="analyticsSource"
></app-value-format-edit>

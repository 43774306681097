<div class="overlay-controls overlay-controls_position_right-bottom">
  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button
        *ngFor="let option of languageOptions"
        mat-menu-item
        [class.mat-menu-item-active]="option.value == languageControl.value"
        [class.mat-menu-item-odd]="true"
        [disableRipple]="true"
        (click)="languageControl.patchValue(option.value)"
      >
        {{ option.name | appCapitalize }}
      </button>
    </ng-template>
  </mat-menu>

  <div
    class="overlay-selector overlay-controls__item"
    [class.overlay-selector_active]="trigger.menuOpen"
    [matMenuTriggerFor]="dropdown"
    #trigger="matMenuTrigger"
  >
    <div class="overlay-selector__label">
      <ng-container *ngIf="currentLanguageOption">{{ currentLanguageOption.name }}</ng-container>
      <ng-container *ngIf="!currentLanguageOption">Choose language</ng-container>
    </div>
    <div
      class="overlay-selector__arrow icon-arrow_down_2"
      [class.icon-arrow_up_2]="trigger.menuOpen"
      [class.icon-arrow_down_2]="!trigger.menuOpen"
    ></div>
  </div>
</div>

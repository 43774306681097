<div
  class="chart"
  [class.chart_multiple]="(selectedDatasetIndexes | appIsSet) ? selectedDatasetCount > 1 : data.length > 1"
>
  <div class="chart__canvas" #canvas>
    <svg width="100%" height="100%" #svg></svg>
  </div>

  <div *ngIf="legend && data.length > 1" class="chart__legend">
    <div class="chart-legend">
      <div
        *ngFor="let item of data; let i = index"
        class="chart-legend__item"
        [class.chart-legend__item_interactive]="interactive"
        [class.chart-legend__item_disabled]="(selectedDatasetIndexes | appIsSet) && !selectedDatasetIndexes[i]"
        (click)="toggleSelectedDatasetIndex(i)"
        (mouseenter)="onLegendDatasetMouseEnter(i)"
        (mouseleave)="onLegendDatasetMouseLeave()"
      >
        <div
          class="chart-legend__item-color chart-legend__item-color_line"
          [class.chart-legend__item-color_no-color]="!colorDisplay(item.color)"
          [style.background-color]="
            (selectedDatasetIndexes | appIsSet) && !selectedDatasetIndexes[i] ? null : colorDisplay(item.color)
          "
        ></div>
        <div class="chart-legend__item-label">
          <ng-container *ngIf="item.name | appIsSet">{{ item.name }}</ng-container>
          <ng-container *ngIf="!(item.name | appIsSet)">Dataset {{ i + 1 }}</ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="chart__tooltips" #tooltip_container>
    <ng-template [cdkPortalOutlet]="null"></ng-template>
  </div>
</div>

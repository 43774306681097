import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorsComponent implements OnInit {
  @Input() errors: string[];

  constructor() {}

  ngOnInit() {}
}

import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[appDragPreview]'
})
export class AppDragPreview<T = any> {
  @Input() data: T;

  constructor(public templateRef: TemplateRef<T>) {}
}

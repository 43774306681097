var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import shuffle from 'lodash/shuffle';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, Subject } from 'rxjs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettings, ViewSettingsType } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { FeatureService } from '@modules/features';
import { CurrentEnvironmentStore, CurrentProjectStore, isResourceTypeItem3rdParty, isResourceTypeItemCustom, Resource, ResourceName, resourcesCustom, ResourceTypeItemCategory, resourceTypeItems } from '@modules/projects';
import { ResourceEditController, ResourceSummaryService } from '@modules/projects-components';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { RoutingService } from '@modules/routing';
import { TemplateService, TemplateType } from '@modules/template';
import { CurrentUserStore } from '@modules/users';
import { KeyboardEventKeyCode } from '@shared';
import { adminPanelTemplateItem, toTemplateItem } from '../../data/template-item';
import { TemplateItemType } from '../../data/template-item-type';
import { TemplateApplyController } from '../../services/template-apply-controller/template-apply.controller';
export var ChooseTemplateSection;
(function (ChooseTemplateSection) {
    ChooseTemplateSection["Onboarding"] = "onboarding";
    ChooseTemplateSection["Templates"] = "templates";
    ChooseTemplateSection["Resources"] = "resources";
})(ChooseTemplateSection || (ChooseTemplateSection = {}));
export var CLOSE_BY_BUTTON_CLICK = 'CLOSE_BY_BUTTON_CLICK';
var ChooseTemplateComponent = /** @class */ (function () {
    function ChooseTemplateComponent(popupComponent, resourceGeneratorResolver, injector, currentUserStore, resourceEditController, resourceSummaryService, cd, currentProjectStore, currentEnvironmentStore, featureService, templateService, templateApplyController, notificationService, routing, viewSettingsQueries, analyticsService) {
        this.popupComponent = popupComponent;
        this.resourceGeneratorResolver = resourceGeneratorResolver;
        this.injector = injector;
        this.currentUserStore = currentUserStore;
        this.resourceEditController = resourceEditController;
        this.resourceSummaryService = resourceSummaryService;
        this.cd = cd;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.featureService = featureService;
        this.templateService = templateService;
        this.templateApplyController = templateApplyController;
        this.notificationService = notificationService;
        this.routing = routing;
        this.viewSettingsQueries = viewSettingsQueries;
        this.analyticsService = analyticsService;
        this.title = 'Create an App';
        this.orange = false;
        this.cancel = false;
        this.newPage = false;
        this.header = true;
        this.wide = false;
        this.chooseSection = true;
        this.chosenTemplate = new EventEmitter();
        this.templateApplied = new EventEmitter();
        this.resourceCreated = new EventEmitter();
        this.blankAppCreated = new EventEmitter();
        this.chosenDemoResources = new EventEmitter();
        this.loading = false;
        this.loadingCreateBlankApp = false;
        this.templateItemTypes = TemplateItemType;
        this.templates = [];
        this.templatesFiltered = [];
        this.templateSearch = '';
        this.templateSearchUpdated = new Subject();
        this.tagsMaximum = 4;
        this.section = ChooseTemplateSection.Templates;
        this.sections = ChooseTemplateSection;
        this.breadcrumbs = [];
        this.customResource = resourceTypeItems.find(function (item) { return item.name == ResourceName.JetDatabase; });
        this.resourceFilters = [
            {
                label: 'All Integrations'
            },
            {
                label: 'Databases',
                filter: { category: ResourceTypeItemCategory.Databases }
            },
            {
                label: 'APIs',
                filter: { category: ResourceTypeItemCategory.APIs }
            },
            {
                label: 'Frameworks',
                filter: { category: ResourceTypeItemCategory.Frameworks }
            },
            {
                label: 'Storages',
                filter: { category: ResourceTypeItemCategory.Storages }
            }
        ];
        this.resourceFilterIndex = 0;
        this.resourceFilterCounts = [];
        this.resourceSearchUpdated = new Subject();
        this.analyticsEvents = AnalyticsEvent;
    }
    ChooseTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resources =
            this.selectedTemplate && this.selectedTemplate.selectResources
                ? this.selectedTemplate.selectResources
                : resourceTypeItems.filter(function (item) { return !item.hidden && !item.protected && item.name != ResourceName.JetDatabase; });
        this.resourcesNotFound = resourceTypeItems.filter(function (item) { return resourcesCustom.includes(item.name); });
        this.updateResourcesFiltered();
        if (this.initialSection) {
            this.section = this.initialSection;
        }
        this.adminPanelTemplate = __assign({}, adminPanelTemplateItem, { selectResources: resourceTypeItems
                .filter(function (item) { return !item.hidden && !item.protected; })
                .filter(function (item) { return _this.resourceGeneratorResolver.get(item.name) || isResourceTypeItemCustom(item); }) });
        this.loading = true;
        this.cd.markForCheck();
        this.templateService
            .get({ type: TemplateType.Page })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var items = result
                .filter(function (item) {
                if (!_this.resource) {
                    return true;
                }
                return item.forResources.find(function (resource) { return resource.typeItem.name == _this.resource.typeItem.name; });
            })
                .map(function (item) { return toTemplateItem(item); });
            var featuredTemplates = shuffle([adminPanelTemplateItem].concat(items.filter(function (item) { return item.template.featured; })));
            var otherTemplates = shuffle(items.filter(function (item) { return !item.template.featured; }));
            _this.templates = featuredTemplates.concat(otherTemplates);
            if (_this.type == TemplateItemType.AdminPanel) {
                _this.selectedTemplate = _this.adminPanelTemplate;
                _this.chooseAdminPanelTemplate();
            }
            else if (_this.initialSelected != undefined) {
                _this.selectedTemplate = _this.templates.find(function (item) { return item.template && item.template.id == _this.initialSelected; });
            }
            else {
                _this.selectedTemplate = _this.templates[0];
            }
            _this.loading = false;
            _this.cd.markForCheck();
            _this.updateTemplatesFiltered();
            _this.updateResourcesFiltered();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.templateSearchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateTemplatesFiltered(); });
        this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateResourcesFiltered(); });
    };
    ChooseTemplateComponent.prototype.ngOnDestroy = function () { };
    ChooseTemplateComponent.prototype.updateTemplatesFiltered = function () {
        var _this = this;
        this.templatesFiltered = this.templates
            ? this.templates.filter(function (item) {
                if (!_this.templateSearch) {
                    return true;
                }
                return item.name.toLowerCase().includes(_this.templateSearch.toLowerCase());
            })
            : undefined;
        this.cd.markForCheck();
    };
    ChooseTemplateComponent.prototype.updateResourcesFiltered = function () {
        var _this = this;
        var filterResources = function (resources, filter) {
            return resources.filter(function (item) {
                if (filter) {
                    if (filter.category != undefined) {
                        if (!item.categories.includes(filter.category)) {
                            return false;
                        }
                    }
                }
                if (_this.resourceSearch) {
                    if (item.label.toLowerCase().includes(_this.resourceSearch.toLowerCase()) == false) {
                        return false;
                    }
                }
                return true;
            });
        };
        this.resourceFilterCounts = this.resourceFilters.map(function (item) { return filterResources(_this.resources, item.filter).length; });
        if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
            this.resourceFilterIndex = 0;
        }
        var currentFilter = this.currentResourceFilter;
        this.resourcesFiltered = filterResources(this.resources, currentFilter);
        this.cd.markForCheck();
    };
    Object.defineProperty(ChooseTemplateComponent.prototype, "currentResourceFilter", {
        get: function () {
            if (!this.resourceFilters[this.resourceFilterIndex]) {
                return;
            }
            return this.resourceFilters[this.resourceFilterIndex].filter;
        },
        enumerable: true,
        configurable: true
    });
    ChooseTemplateComponent.prototype.clearSearch = function () {
        this.resourceSearch = '';
        this.resourceSearchUpdated.next();
        this.cd.markForCheck();
    };
    ChooseTemplateComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    ChooseTemplateComponent.prototype.setSelectedTemplate = function (template) {
        this.selectedTemplate = template;
        this.cd.markForCheck();
        if (template.type != TemplateItemType.AdminPanel) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
                TemplateID: template.name
            });
        }
    };
    ChooseTemplateComponent.prototype.setSelectedResource = function (resource) {
        if (isResourceTypeItem3rdParty(resource) &&
            !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Business Apps</strong>',
                description: "\n          Connect data from various business apps. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        else if (isResourceTypeItemCustom(resource) &&
            !this.currentProjectStore.instance.features.isCustomResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Custom Queries</strong>',
                description: "\n          Connect data from custom queries. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        this.selectedResource = resource;
        this.cd.markForCheck();
    };
    ChooseTemplateComponent.prototype.setResourceFilterIndex = function (i) {
        this.resourceFilterIndex = i;
        this.cd.markForCheck();
        this.updateResourcesFiltered();
    };
    ChooseTemplateComponent.prototype.chooseTemplate = function (template) {
        this.selectedTemplate = template ? template : this.templatesFiltered[0];
        this.navigate(ChooseTemplateSection.Templates);
        this.cd.markForCheck();
        if (this.selectedTemplate) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
                TemplateID: this.selectedTemplate.name
            });
        }
    };
    ChooseTemplateComponent.prototype.navigate = function (section) {
        this.breadcrumbs = this.breadcrumbs.concat([this.section]);
        this.section = section;
        this.cd.markForCheck();
    };
    ChooseTemplateComponent.prototype.navigateToProjects = function () {
        this.routing.navigate(['/projects']);
    };
    ChooseTemplateComponent.prototype.navigateBack = function () {
        this.section = this.previousSection;
        this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
        this.cd.markForCheck();
    };
    Object.defineProperty(ChooseTemplateComponent.prototype, "previousSection", {
        get: function () {
            return this.breadcrumbs[this.breadcrumbs.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    ChooseTemplateComponent.prototype.isVideoUrl = function (url) {
        return ['mp4', 'webm', 'ogg'].some(function (item) { return url.toLowerCase().endsWith("." + item); });
    };
    ChooseTemplateComponent.prototype.close = function (data) {
        if (this.popupComponent) {
            this.popupComponent.close(data);
        }
    };
    ChooseTemplateComponent.prototype.onCloseClick = function () {
        this.close(CLOSE_BY_BUTTON_CLICK);
    };
    ChooseTemplateComponent.prototype.createNewPage = function () {
        if (!this.newPage) {
            return of(undefined);
        }
        return this.viewSettingsQueries.createPage({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            view: ViewSettingsType.Custom,
            // uniqueName: 'new-page',
            name: 'New Page',
            addToMenu: true
        });
    };
    ChooseTemplateComponent.prototype.chooseAdminPanelTemplate = function (source) {
        // this.selectedTemplate = this.adminPanelTemplate;
        if (this.resource) {
            this.applyTemplate(__assign({}, this.adminPanelTemplate, { resource: this.resource.typeItem }));
        }
        else {
            this.navigate(ChooseTemplateSection.Resources);
            this.setResourceFilterIndex(undefined);
            this.updateResourcesFiltered();
            // this.selectedResource = this.resourcesFiltered[0];
            this.selectedResource = undefined;
            this.cd.markForCheck();
        }
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
            TemplateID: this.selectedTemplate.name,
            Source: source
        });
    };
    ChooseTemplateComponent.prototype.createBlankApp = function () {
        var _this = this;
        this.loadingCreateBlankApp = true;
        this.cd.markForCheck();
        this.createNewPage()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.close();
            _this.blankAppCreated.emit();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.BlankApp);
        }, function () {
            _this.loadingCreateBlankApp = false;
            _this.cd.markForCheck();
        });
    };
    ChooseTemplateComponent.prototype.applyTemplate = function (template) {
        var _this = this;
        this.templateApplyController
            .applyTemplateFromPage(template, this.injector, this.currentPage, {
            resource: this.resource,
            useDemoResources: true,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (link) {
            _this.close();
            _this.templateApplied.emit(template);
            _this.routing.navigateApp(link);
            // this.notificationService.success(
            //   'Template applied',
            //   `Template <strong>${template.name}</strong> was successfully applied`
            // );
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Applied, {
                TemplateID: template.name,
                ResourceType: template.resource ? template.resource.name : undefined
            });
        });
    };
    ChooseTemplateComponent.prototype.applyAdminPanelTemplate = function (resource) {
        if (!resource) {
            return;
        }
        this.applyTemplate(__assign({}, this.adminPanelTemplate, { resource: resource }));
    };
    return ChooseTemplateComponent;
}());
export { ChooseTemplateComponent };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { DragDropModule } from '@common/drag-drop';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { TimelineItemComponent } from './components/timeline-item/timeline-item.component';
import { TimelineStubContentComponent } from './components/timeline-stub-content/timeline-stub-content.component';
import { TimelineComponent } from './components/timeline/timeline.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    SharedModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    FieldComponentsModule,
    TableModule,
    TipsModule,
    LocalizeModule,
    DialogsModule,
    ModelQueriesModule,
    DragDropModule,
    ExportModule,
    AnalyticsModule,
    FixedComponentModule,
    ViewsComponentsModule
  ],
  declarations: [TimelineComponent, TimelineItemComponent, TimelineStubContentComponent],
  exports: [TimelineComponent, TimelineItemComponent, TimelineStubContentComponent]
})
export class TimelineModule {}

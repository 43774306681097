/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./element-drag-preview.component";
import * as i3 from "../../../theme/services/theme/theme.service";
var styles_ElementDragPreviewComponent = [];
var RenderType_ElementDragPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ElementDragPreviewComponent, data: {} });
export { RenderType_ElementDragPreviewComponent as RenderType_ElementDragPreviewComponent };
function View_ElementDragPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "element-drag-preview__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "element-drag-preview__image-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "element-drag-preview__image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imagePath; _ck(_v, 2, 0, currVal_0); }); }
export function View_ElementDragPreviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "element-drag-preview"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ElementDragPreviewComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "element-drag-preview__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "element-drag-preview__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "element-drag-preview__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "element-drag-preview__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["dragging component"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.name; _ck(_v, 6, 0, currVal_1); }); }
export function View_ElementDragPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-element-drag-preview", [], null, null, null, View_ElementDragPreviewComponent_0, RenderType_ElementDragPreviewComponent)), i0.ɵdid(1, 573440, null, 0, i2.ElementDragPreviewComponent, [i3.ThemeService, i0.ChangeDetectorRef], null, null)], null, null); }
var ElementDragPreviewComponentNgFactory = i0.ɵccf("app-element-drag-preview", i2.ElementDragPreviewComponent, View_ElementDragPreviewComponent_Host_0, { element: "element" }, {}, []);
export { ElementDragPreviewComponentNgFactory as ElementDragPreviewComponentNgFactory };

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomViewSettings, PopupSettings, validateElementNames, VALUE_OUTPUT, ViewSettingsStore } from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { SELECTED_ITEM_OUTPUT } from '@modules/list';
import { capitalize } from '@shared';
import { BackGenerator } from '../back-generator/back-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { TextGenerator } from '../text-generator/text-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';
var AdminPanelPopupsGenerator = /** @class */ (function () {
    function AdminPanelPopupsGenerator(viewSettingsStore, viewSettingsGeneratorService, createFormGenerator, updateFormGenerator, detailGenerator, deleteButtonGenerator, backGenerator, listGenerator, filterGenerator, textGenerator, generatorUtils) {
        this.viewSettingsStore = viewSettingsStore;
        this.viewSettingsGeneratorService = viewSettingsGeneratorService;
        this.createFormGenerator = createFormGenerator;
        this.updateFormGenerator = updateFormGenerator;
        this.detailGenerator = detailGenerator;
        this.deleteButtonGenerator = deleteButtonGenerator;
        this.backGenerator = backGenerator;
        this.listGenerator = listGenerator;
        this.filterGenerator = filterGenerator;
        this.textGenerator = textGenerator;
        this.generatorUtils = generatorUtils;
    }
    AdminPanelPopupsGenerator.prototype.getModelViewPage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        var popup = new PopupSettings();
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = modelDescription.verboseNamePlural;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 1;
        viewSettings.configuredModelElements = 1;
        viewSettings.popups = [popup];
        viewSettings.newlyCreated = true;
        if (options.idGenerator) {
            popup.uid = options.idGenerator ? options.idGenerator.elementId('popup') : undefined;
        }
        else {
            popup.generateUid();
        }
        popup.name = 'Modal';
        var listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
            templates: options.templates,
            fields: options.listFields,
            detailPopup: popup.uid,
            perPage: 15,
            uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
        });
        var filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
            uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
        });
        listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(function (item) {
            var input = new Input();
            input.name = item.name;
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];
            return input;
        });
        viewSettings.elements = [filterElement, listElement];
        var titleElement = this.textGenerator.getElement({
            h3: [capitalize(modelDescription.verboseName), 'Details'].join(' '),
            uid: options.idGenerator ? options.idGenerator.elementId('titleElement') : undefined
        });
        popup.elements.push(titleElement);
        var modelElement = this.detailGenerator.getElement(resource, modelDescription, {
            pkContextValue: ['elements', listElement.uid, '0', SELECTED_ITEM_OUTPUT, modelDescription.primaryKeyField],
            fields: options.detailFields,
            uid: options.idGenerator ? options.idGenerator.elementId('modelElement') : undefined
        });
        popup.elements.push(modelElement);
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelPopupsGenerator.prototype.getModelUpdatePage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        var popup = new PopupSettings();
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = modelDescription.verboseNamePlural;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 1;
        viewSettings.configuredModelElements = 1;
        viewSettings.popups = [popup];
        viewSettings.newlyCreated = true;
        if (options.idGenerator) {
            popup.uid = options.idGenerator ? options.idGenerator.elementId('popup') : undefined;
        }
        else {
            popup.generateUid();
        }
        popup.name = 'Modal';
        var listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
            templates: options.templates,
            fields: options.listFields,
            detailPopup: popup.uid,
            perPage: 15,
            uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
        });
        var filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
            uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
        });
        listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(function (item) {
            var input = new Input();
            input.name = item.name;
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];
            return input;
        });
        viewSettings.elements = [filterElement, listElement];
        var titleElement = this.textGenerator.getElement({
            h3: ['Update', capitalize(modelDescription.verboseName)].join(' '),
            uid: options.idGenerator ? options.idGenerator.elementId('titleElement') : undefined
        });
        popup.elements.push(titleElement);
        var formElement = this.updateFormGenerator.getElement(resource, modelDescription, {
            templates: options.templates,
            pkContextValue: ['elements', listElement.uid, '0', SELECTED_ITEM_OUTPUT, modelDescription.primaryKeyField],
            fields: options.updateFields,
            closePopupOnSuccess: popup.uid,
            idGenerator: options.idGenerator,
            idUniqueName: 'formElement'
        });
        popup.elements.push(formElement);
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelPopupsGenerator.prototype.getPage = function (project, resource, modelDescription, updateEnabled, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var baseUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription);
        return combineLatest(this.viewSettingsStore.getDistinctUniqueName(baseUniqueName), this.generatorUtils.getDefaultComponentTemplates()).pipe(map(function (_a) {
            var uniqueName = _a[0], templates = _a[1];
            if (updateEnabled) {
                return _this.getModelUpdatePage(project, resource, modelDescription, uniqueName, {
                    templates: templates,
                    listFields: options.listFields,
                    updateFields: options.updateFields,
                    idGenerator: options.idGenerator
                });
            }
            else {
                return _this.getModelViewPage(project, resource, modelDescription, uniqueName, {
                    templates: templates,
                    listFields: options.listFields,
                    detailFields: options.detailFields,
                    idGenerator: options.idGenerator
                });
            }
        }));
    };
    return AdminPanelPopupsGenerator;
}());
export { AdminPanelPopupsGenerator };

import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';

const defaultMinValueInput = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: 1
};

const defaultMaxValueInput = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: 10
};

const defaultStepSizeInput = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: 1
};

@Injectable()
export class SliderFieldDataParamsForm extends FormGroup {
  paramsControl: AbstractControl;
  controls: {
    min_value_input: FieldInputControl;
    max_value_input: FieldInputControl;
    step_size_input: FieldInputControl;
  };

  constructor() {
    super({
      min_value_input: new FieldInputControl(defaultMinValueInput),
      max_value_input: new FieldInputControl(defaultMaxValueInput),
      step_size_input: new FieldInputControl(defaultStepSizeInput)
    });
  }

  init(paramsControl: AbstractControl) {
    this.paramsControl = paramsControl;

    if (paramsControl.value) {
      const params = this.getParams(paramsControl.value || {});
      const value = {
        min_value_input: params.minValueInput ? params.minValueInput.serialize() : defaultMinValueInput,
        max_value_input: params.maxValueInput ? params.maxValueInput.serialize() : defaultMaxValueInput,
        step_size_input: params.stepSizeInput ? params.stepSizeInput.serialize() : defaultStepSizeInput
      };

      this.patchValue(value, { emitEvent: false });
    }

    this.valueChanges.subscribe(() => this.submit());
  }

  getParams(params: Object): { minValueInput?: Input; maxValueInput?: Input; stepSizeInput?: Input } {
    const result: { minValueInput?: Input; maxValueInput?: Input; stepSizeInput?: Input } = {};

    if (params['min_value_input']) {
      result.minValueInput = new Input().deserialize(params['min_value_input']);
    } else if (isSet(params['min_value'])) {
      // Backward compatibility
      result.minValueInput = new Input().deserializeFromStatic('value', params['min_value']);
    }

    if (params['max_value_input']) {
      result.maxValueInput = new Input().deserialize(params['max_value_input']);
    } else if (isSet(params['max_value'])) {
      // Backward compatibility
      result.maxValueInput = new Input().deserializeFromStatic('value', params['max_value']);
    }

    if (params['step_size_input']) {
      result.stepSizeInput = new Input().deserialize(params['step_size_input']);
    } else if (isSet(params['step_size'])) {
      // Backward compatibility
      result.stepSizeInput = new Input().deserializeFromStatic('value', params['step_size']);
    }

    return result;
  }

  submit() {
    const result = {
      min_value_input: this.controls.min_value_input.value
        ? new FieldInput().deserialize(this.controls.min_value_input.value).serialize()
        : undefined,
      max_value_input: this.controls.max_value_input.value
        ? new FieldInput().deserialize(this.controls.max_value_input.value).serialize()
        : undefined,
      step_size_input: this.controls.step_size_input.value
        ? new FieldInput().deserialize(this.controls.step_size_input.value).serialize()
        : undefined
    };

    this.paramsControl.patchValue({
      ...this.paramsControl.value,
      ...result
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { BlendingMode } from '@modules/views';
import { controlValue } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { ConstraintsControl } from '../../controls/constraints.control';
import { FrameControl } from '../../controls/frame.control';
import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { blendingModeOptions } from '../base/blending-mode-options';
var ElementCustomizeLayerForm = /** @class */ (function (_super) {
    __extends(ElementCustomizeLayerForm, _super);
    function ElementCustomizeLayerForm(editorContext) {
        var _this = _super.call(this, {
            frame: new FrameControl(),
            absolute_layout: new FormControl(false),
            constrain_proportion: new FormControl(undefined),
            reflect_horizontal: new FormControl(false),
            reflect_vertical: new FormControl(false),
            constraints: new ConstraintsControl(),
            opacity: new FormControl(1),
            blending_mode: new FormControl(BlendingMode.Normal),
            visible_input: new FieldInputControl({ name: 'value' }),
            visible_input_enabled: new FormControl(false),
            interactions: new LayerInteractionArray([])
        }) || this;
        _this.editorContext = editorContext;
        _this.instance$ = new BehaviorSubject(undefined);
        _this.containerLayer$ = _this.instance$.pipe(switchMap(function (instance) {
            return instance ? _this.editorContext.getContainerLayer$(instance) : of(undefined);
        }));
        _this.sizeOptions = [
            {
                value: false,
                name: 'Fixed size',
                nameShort: 'Fixed'
            },
            {
                value: true,
                name: 'Hug content',
                nameShort: 'Hug'
            }
        ];
        _this.blendingModeOptions = blendingModeOptions;
        combineLatest(_this.containerLayer$, controlValue(_this.controls.absolute_layout))
            .pipe(untilDestroyed(_this))
            .subscribe(function (_a) {
            var containerLayer = _a[0], absoluteLayout = _a[1];
            var disabled = containerLayer && !!containerLayer.flexLayout && !absoluteLayout;
            _this.controls.frame.setPositionDisabled(disabled);
        });
        return _this;
    }
    ElementCustomizeLayerForm.prototype.ngOnDestroy = function () { };
    ElementCustomizeLayerForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        this.instance$.next(instance);
        this.controls.frame.deserialize(instance.frame, {
            translate: options.container ? options.container.options.translate : undefined
        });
        this.controls.absolute_layout.patchValue(instance.absoluteLayout);
        this.controls.constrain_proportion.patchValue(instance.constrainProportion);
        this.controls.reflect_horizontal.patchValue(instance.reflectHorizontal);
        this.controls.reflect_vertical.patchValue(instance.reflectVertical);
        if (instance.constraints) {
            this.controls.constraints.deserialize(instance.constraints);
        }
        this.controls.opacity.patchValue(instance.opacity);
        this.controls.blending_mode.patchValue(instance.blendingMode);
        this.controls.visible_input.patchValue(instance.visibleInput ? instance.visibleInput.serialize() : {});
        this.controls.visible_input_enabled.patchValue(instance.visibleInput && instance.visibleInput.isSet());
        this.controls.interactions.deserialize(instance.interactions);
        if (this.visibleInputEnabledInitial === undefined) {
            this.visibleInputEnabledInitial = !!this.controls.visible_input_enabled.value;
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    ElementCustomizeLayerForm.prototype.updateWidthProportional = function () {
        this.controls.frame.updateWidthProportional(this.controls.constrain_proportion.value);
    };
    ElementCustomizeLayerForm.prototype.updateHeightProportional = function () {
        this.controls.frame.updateHeightProportional(this.controls.constrain_proportion.value);
    };
    ElementCustomizeLayerForm.prototype.toggleConstrainProportion = function () {
        if (this.controls.constrain_proportion.value) {
            this.controls.constrain_proportion.patchValue(undefined);
        }
        else {
            this.controls.constrain_proportion.patchValue(this.controls.frame.getProportion());
        }
    };
    ElementCustomizeLayerForm.prototype.submit = function () {
        var instance = this.instance;
        instance.frame = this.controls.frame.serialize();
        instance.absoluteLayout = this.controls.absolute_layout.value;
        instance.constrainProportion = this.controls.constrain_proportion.value;
        instance.reflectHorizontal = this.controls.reflect_horizontal.value;
        instance.reflectVertical = this.controls.reflect_vertical.value;
        instance.constraints = this.controls.constraints.serialize();
        instance.opacity = this.controls.opacity.value;
        instance.blendingMode = this.controls.blending_mode.value;
        instance.visibleInput =
            this.controls.visible_input_enabled.value && this.controls.visible_input.value
                ? new Input().deserialize(this.controls.visible_input.value)
                : undefined;
        instance.interactions = this.controls.interactions.serialize();
        return instance;
    };
    return ElementCustomizeLayerForm;
}(FormGroup));
export { ElementCustomizeLayerForm };

import { DatasetGroupLookup } from '@modules/charts';

export const pieChartSampleData = [
  {
    name: undefined,
    color: undefined,
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: 'Segment A', value: 400, color: 'gray' },
      { group: 'Segment B', value: 500, color: 'gray' },
      { group: 'Segment C', value: 200, color: 'silver' }
    ]
  }
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of, throwError, timer } from 'rxjs';
import { catchError, delayWhen, map, switchMap } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { FormUtils } from '@common/form-utils';
import { AUTO_OPTION_COLORS } from '@modules/colors';
import { CurrentProjectStore, Environment, EnvironmentService } from '@modules/projects';
export function duplicateNameValidator() {
    return function (control) {
        if (!control.value) {
            return of(null);
        }
        var parent = control.parent;
        if (!parent) {
            return;
        }
        return timer(200).pipe(switchMap(function () { return parent.currentProjectStore.getFirst(); }), map(function (project) {
            var uniqueName = slugify(control.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
            var exists = project.environments.find(function (item) {
                return item.uniqueName == uniqueName || item.name.toLowerCase().trim() === control.value.toLowerCase().trim();
            });
            if (exists) {
                return { local: ['Environment with such name already exists'] };
            }
            return null;
        }));
    };
}
var ProjectEnvironmentCreatePopupForm = /** @class */ (function (_super) {
    __extends(ProjectEnvironmentCreatePopupForm, _super);
    function ProjectEnvironmentCreatePopupForm(environmentService, formUtils, currentProjectStore) {
        var _this = _super.call(this, {
            name: new FormControl('', [Validators.required], [duplicateNameValidator()]),
            color: new FormControl('blue', [Validators.required])
        }) || this;
        _this.environmentService = environmentService;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        return _this;
    }
    ProjectEnvironmentCreatePopupForm.prototype.init = function () {
        var colors = this.currentProjectStore.instance.environments.map(function (item) { return item.color; });
        var autoColor = AUTO_OPTION_COLORS.find(function (item) { return !colors.includes(item); });
        this.controls.color.reset(autoColor || 'blue');
    };
    ProjectEnvironmentCreatePopupForm.prototype.duplicateNameValidator = function () {
        var _this = this;
        return function (control) {
            if (control.value === null) {
                return of(null);
            }
            return timer(200).pipe(switchMap(function () { return _this.currentProjectStore.getFirst(); }), map(function (project) {
                var uniqueName = slugify(control.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
                var exists = project.environments.find(function (item) {
                    return (item.uniqueName == uniqueName || item.name.toLowerCase().trim() === control.value.toLowerCase().trim());
                });
                if (exists) {
                    return { local: ['Environment with such name already exists'] };
                }
                return null;
            }));
        };
    };
    ProjectEnvironmentCreatePopupForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var instance = new Environment();
        instance.name = this.controls.name.value;
        instance.uniqueName = slugify(this.controls.name.value, { trim: true, separator: '_' }).replace(/_+/g, '_');
        instance.color = this.controls.color.value;
        return this.environmentService.create(this.currentProjectStore.instance.uniqueName, instance).pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectEnvironmentCreatePopupForm;
}(FormGroup));
export { ProjectEnvironmentCreatePopupForm };

import { NavigationEnd, Router } from '@angular/router';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, publishLast, refCount, tap } from 'rxjs/operators';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { openUrl } from '@shared';
import { GuideSectionStore } from '../../stores/guide-section.store';
import { GuideFocusService } from '../guide-focus/guide-focus.service';
import { GuideTaskProgressService } from '../guide-task-progress/guide-task-progress.service';
import { TaskService } from '../task/task.service';
var GuideService = /** @class */ (function () {
    function GuideService(routing, router, currentProjectStore, currentEnvironmentStore, guideTaskProgressService, guideSectionStore, dialogService, taskService, analyticsService, guideFocusService) {
        var _this = this;
        this.routing = routing;
        this.router = router;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.guideTaskProgressService = guideTaskProgressService;
        this.guideSectionStore = guideSectionStore;
        this.dialogService = dialogService;
        this.taskService = taskService;
        this.analyticsService = analyticsService;
        this.guideFocusService = guideFocusService;
        this._state = new BehaviorSubject(undefined);
        this._completedTask = new Subject();
        this.router.events.pipe(filter(function (item) { return item instanceof NavigationEnd; })).subscribe(function (event) {
            if (!_this.state) {
                return;
            }
            var currentTaskItemUrl = _this.currentPage ? _this.currentPage.link : undefined;
            var nextTaskItemPage = _this.nextTaskItemPage ? _this.nextTaskItemPage.link : undefined;
            var samePage = function (link) {
                if (!link) {
                    return false;
                }
                var currentLink = event.url.toString();
                var regexp = new RegExp("^" + _this.routing.appLink(link).join('/'));
                return !!currentLink.match(regexp);
            };
            if (!samePage(currentTaskItemUrl) && !samePage(nextTaskItemPage)) {
                _this.clearCurrentTask();
            }
            else if (currentTaskItemUrl != nextTaskItemPage && samePage(nextTaskItemPage)) {
                _this.completeTaskProgress(_this.state.task.uniqueName, _this.state.page, _this.statePageItemName);
            }
        });
    }
    Object.defineProperty(GuideService.prototype, "state", {
        get: function () {
            return this._state.value;
        },
        set: function (value) {
            this._state.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "state$", {
        get: function () {
            return this._state.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "completedTask$", {
        get: function () {
            return this._completedTask.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GuideService.prototype.setCurrentTask = function (task) {
        var _this = this;
        this.clearCurrentTask();
        var obs = this.taskService.getScenario(task.uniqueName).pipe(tap(function (scenario) {
            if (isArray(scenario.firstLink)) {
                _this.routing.navigateApp(scenario.firstLink);
            }
            else {
                openUrl(scenario.firstLink, true);
            }
        }), delay(1000), tap(function (scenario) {
            if (!scenario.isEmpty) {
                _this.dialogService
                    .dialog({
                    title: "Task \u2013 " + task.title,
                    description: "You are about to start <strong>" + task.title + "</strong> task. Follow the tips and we will guide you through.",
                    buttons: [
                        {
                            name: 'ok',
                            label: 'Get started!',
                            type: DialogButtonType.Primary,
                            hotkey: DialogButtonHotkey.Submit
                        }
                    ],
                    style: 'bright'
                })
                    .subscribe(function () {
                    _this.state = {
                        task: task,
                        scenario: scenario,
                        page: undefined,
                        pageItem: undefined
                    };
                    _this.setCurrentTaskProgress(0, 0);
                    _this.onTaskStarted(task);
                });
            }
            else {
                _this.state = {
                    task: task,
                    scenario: scenario,
                    page: undefined,
                    pageItem: undefined
                };
                _this.setCurrentTaskProgress(0, 0);
                _this.onTaskStarted(task);
            }
        }), publishLast(), refCount());
        obs.subscribe();
        return obs;
    };
    GuideService.prototype.setCurrentTaskProgress = function (page, pageItem) {
        var _this = this;
        var pageChanged = this.state.page !== page && this.state.page !== undefined;
        var state = clone(this.state);
        state.page = page;
        state.pageItem = pageItem;
        this.state = state;
        if (pageChanged && !this.state.scenario.pages[page].noAutoRedirect) {
            var link = this.state.scenario.pages[page].link;
            if (isArray(link)) {
                this.routing.navigateApp(link);
            }
            else {
                openUrl(link, true);
            }
        }
        if (page == this.state.scenario.pages.length - 1 && pageItem >= this.state.scenario.pages[page].items.length - 1) {
            this.guideTaskProgressService
                .complete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.state.task)
                .subscribe(function () {
                _this.guideSectionStore.get(true);
                _this.onTaskCompleted(state);
            });
            this.clearCurrentTask();
        }
    };
    GuideService.prototype.completeTaskProgress = function (task, page, pageItem) {
        if (!this.state ||
            this.state.task.uniqueName != task ||
            this.state.page != page ||
            this.statePageItemName != pageItem) {
            return;
        }
        if (this.state.pageItem + 1 > this.state.scenario.pages[page].items.length - 1 &&
            page + 1 <= this.state.scenario.pages.length - 1) {
            this.setCurrentTaskProgress(page + 1, 0);
        }
        else {
            this.setCurrentTaskProgress(page, this.state.pageItem + 1);
        }
    };
    Object.defineProperty(GuideService.prototype, "statePageItem", {
        get: function () {
            if (!this.state || this.state.page == undefined || this.state.pageItem == undefined) {
                return;
            }
            return this.state.scenario.pages[this.state.page].items[this.state.pageItem];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "statePageItemName", {
        get: function () {
            return this.statePageItem ? this.statePageItem.name : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "currentPage", {
        get: function () {
            if (!this.state || !this.state.scenario.pages.length) {
                return;
            }
            return this.state.scenario.pages[this.state.page];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "prevTaskItemPage", {
        get: function () {
            if (!this.currentPage) {
                return;
            }
            if (this.state.pageItem == 0 && this.state.page > 0) {
                return this.state.scenario.pages[this.state.page - 1];
            }
            else if (this.state.pageItem > 0) {
                return this.state.scenario.pages[this.state.page];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideService.prototype, "nextTaskItemPage", {
        get: function () {
            if (!this.currentPage) {
                return;
            }
            if (this.state.pageItem == this.currentPage.items.length - 1 &&
                this.state.page < this.state.scenario.pages.length - 1) {
                return this.state.scenario.pages[this.state.page + 1];
            }
            else if (this.state.pageItem < this.currentPage.items.length - 1) {
                return this.state.scenario.pages[this.state.page];
            }
        },
        enumerable: true,
        configurable: true
    });
    GuideService.prototype.clearCurrentTask = function () {
        this.state = undefined;
        this.guideFocusService.position = undefined;
    };
    GuideService.prototype.onTaskStarted = function (task) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.TutorialStartTask, { TaskID: task.uniqueName });
    };
    GuideService.prototype.onTaskCompleted = function (state) {
        this._completedTask.next(state);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Undocumented.TutorialFinishTask, {
            TaskID: state.task.uniqueName
        });
    };
    return GuideService;
}());
export { GuideService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../customize-ui/components/workflow-item-edit/workflow-button.component.ngfactory";
import * as i2 from "../../../../../customize-ui/components/workflow-item-edit/workflow-button.component";
import * as i3 from "../../../../../action-queries/services/action/action.service";
import * as i4 from "./customize-bar-action-edit-type-workflow.component";
import * as i5 from "../../../../services/workflow-edit-controller/workflow-edit.controller";
import * as i6 from "../../../../../parameters-components/services/view-context-token-provider/view-context-token-provider";
var styles_CustomizeBarActionEditTypeWorkflowComponent = [];
var RenderType_CustomizeBarActionEditTypeWorkflowComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeWorkflowComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeWorkflowComponent as RenderType_CustomizeBarActionEditTypeWorkflowComponent };
export function View_CustomizeBarActionEditTypeWorkflowComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-workflow-button", [], null, [[null, "editClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editClick" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_WorkflowButtonComponent_0, i1.RenderType_WorkflowButtonComponent)), i0.ɵdid(2, 770048, null, 0, i2.WorkflowButtonComponent, [i3.ActionService, i0.ChangeDetectorRef], { workflow: [0, "workflow"] }, { editClick: "editClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workflow; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomizeBarActionEditTypeWorkflowComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-workflow", [], null, null, null, View_CustomizeBarActionEditTypeWorkflowComponent_0, RenderType_CustomizeBarActionEditTypeWorkflowComponent)), i0.ɵdid(1, 245760, null, 0, i4.CustomizeBarActionEditTypeWorkflowComponent, [i3.ActionService, i5.WorkflowEditController, i6.ViewContextTokenProvider, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeWorkflowComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-workflow", i4.CustomizeBarActionEditTypeWorkflowComponent, View_CustomizeBarActionEditTypeWorkflowComponent_Host_0, { form: "form", context: "context", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeWorkflowComponentNgFactory as CustomizeBarActionEditTypeWorkflowComponentNgFactory };

<app-element-wrapper
  *ngIf="visible || element.loadInvisible || (customizeEnabled$ | async)"
  [wrap]="element.style == formStyles.Wrap"
>
  <form
    class="form"
    [style.display]="element.loadInvisible && !visible && !(customizeEnabled$ | async) ? 'none' : null"
    appElement
    [appElementDetectVisible]="true"
    (appElementFirstVisible)="firstVisible$.next(true)"
    (ngSubmit)="submit()"
  >
    <div
      *ngIf="element.generated && !elementState.inputsNotSet"
      class="placeholder"
      [class.placeholder_active]="customizeEnabled$ | async"
      appDropList
      [appDropListDisabled]="!(customizeEnabled$ | async)"
      [appDropListEnterPredicate]="canEnter"
      [appDropListData]="element.children"
      [appDropListSwapDistance]="20"
      [appDropListAreaMarginForward]="[-10, 0, 0, 0]"
      [appDropListAreaMarginBackward]="[0, 0, -10, 0]"
      [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
      [appDropListOutsideIndicator]="true"
      (appDropListDragStarted)="updateElementStates()"
      (appDropListEntered)="updateElementStates()"
      (appDropListExited)="updateElementStates()"
      (appDropListSorted)="updateElementStates()"
      (appDropListDragged)="updateElementStatesOnStable()"
      (appDropListDropped)="dragDrop($event)"
      appElementGroupsContainer
    >
      <app-auto-element
        *ngFor="let item of element.children; let i = index; trackBy: trackElement"
        appDrag
        [appDragData]="item"
        [element]="item"
        [context]="context"
        [customizing]="customizeService.layoutEnabled$ | async"
        [accentColor]="'accentColor' | appThemeColorHexOption | async"
        (duplicatedRequested)="duplicateItem(i)"
        (replaceRequested)="replaceItem(i, $event)"
        (deleteRequested)="deleteItem(item)"
        (moveToRequested)="moveItemTo(item, $event)"
        (visibleUpdated)="updateElementStates()"
      >
        <app-element-placeholder-stub
          *appDragPlaceholder
          [elementType]="item.type"
          class="element-placeholder"
        ></app-element-placeholder-stub>

        <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
      </app-auto-element>
      <div class="placeholder__border"></div>
    </div>

    <ng-container *ngIf="(!element.generated && !elementState.configured) || elementState.inputsNotSet">
      <div class="form__fieldset form__fieldset_disabled">
        <div *ngFor="let item of stubRange; let i = index" class="field form__field">
          <label class="field__label">
            <span [class.loading-animation]="loading$ | async">
              <span class="stub-text">Field</span>
            </span>
          </label>
          <input class="input input_stub" type="text" value="Field value" [disabled]="true" />
        </div>
      </div>

      <div class="form__submit">
        <div
          class="button button_disabled form__button"
          [class.button_primary]="!accentColor"
          [appButtonTintColor]="accentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
        >
          <span [class.loading-animation]="loading$ | async">
            <span class="stub-text">Submit</span>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!element.generated && elementState.configured && !elementState.inputsNotSet">
      <div class="form__fieldset form__fieldset_disabled">
        <div *ngFor="let item of elementState.parameters || []; let i = index" class="field form__field">
          <label class="field__label">
            {{ item.verboseName || item.name }}
          </label>
          <input *ngIf="!model" class="input input_stub" type="text" value="Field value" [disabled]="true" />
          <input *ngIf="model" class="input" type="text" [value]="model?.getAttribute(item.name)" [disabled]="true" />
        </div>
      </div>

      <div class="form__submit">
        <div
          class="button button_disabled form__button"
          [class.button_primary]="!accentColor"
          [appButtonTintColor]="accentColor"
          [appButtonTintColorStyle]="tintStyles.Primary"
        >
          <span [class.loading-animation]="loading$ | async">
            <span class="stub-text">Submit</span>
          </span>
        </div>
      </div>
    </ng-container>
  </form>
</app-element-wrapper>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageCardComponent implements OnInit {
  @HostBinding('class.page-card') cls = true;
  @Input() @HostBinding('class.page-card_border') border = false;

  constructor() {}

  ngOnInit() {}
}

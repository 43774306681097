var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { StorageObject, StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';
var ProjectStorageService = /** @class */ (function () {
    function ProjectStorageService(http, apiService, projectApiService) {
        this.http = http;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
    }
    ProjectStorageService.prototype.getUploadUrl = function (projectName) {
        return this.apiService.nodeProjectMethodURL(projectName, 'static_files', true);
    };
    ProjectStorageService.prototype.getUploadHeaders = function () {
        return this.projectApiService.setHeadersToken(new HttpHeaders());
    };
    ProjectStorageService.prototype.getUploadData = function (file, path, fileName) {
        var data = new FormData();
        data.set('file', file);
        if (isSet(path)) {
            data.set('path', path);
        }
        if (isSet(fileName)) {
            data.set('file_name', fileName);
        }
        return data;
    };
    ProjectStorageService.prototype.uploadFile = function (projectName, environmentName, file, path, fileName) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.getUploadUrl(projectName);
            var data = _this.getUploadData(file, path, fileName);
            var headers = _this.getUploadHeaders();
            return _this.http.post(url, data, { headers: headers, observe: 'events' });
        }), map(function (event) {
            if (event.type == HttpEventType.Response) {
                return {
                    result: {
                        uploadedPath: event.body['path'],
                        uploadedUrl: event.body['url'],
                        response: event
                    },
                    state: {
                        downloadProgress: 1,
                        uploadProgress: 1
                    }
                };
            }
            else if (event.type == HttpEventType.UploadProgress) {
                return {
                    state: {
                        uploadProgress: event.loaded / event.total,
                        downloadProgress: 0,
                        uploadLoaded: event.loaded,
                        uploadTotal: event.total
                    }
                };
            }
            else if (event.type == HttpEventType.DownloadProgress) {
                return {
                    state: {
                        uploadProgress: 1,
                        downloadProgress: event.loaded / event.total,
                        downloadLoaded: event.loaded,
                        downloadTotal: event.total
                    }
                };
            }
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectStorageService.prototype.getObjects = function (projectName, environmentName, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeProjectMethodURL(projectName, 'static_files');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({}, (isSet(path) && { path: path }))
            });
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) {
            var response = new StorageObjectsResponse();
            response.objects = result['items'].map(function (item) {
                return new StorageObject({
                    path: item['path'],
                    url: item['url'],
                    type: item['type'],
                    size: item['size'],
                    created: item['created_at'],
                    updated: item['updated_at']
                });
            });
            response.storageSize = result['storage_size'];
            response.storageLimit = result['storage_limit'];
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectStorageService.prototype.createFolder = function (projectName, environmentName, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeProjectMethodURL(projectName, 'static_files/create_folder');
            var data = {
                path: path
            };
            var headers = new HttpHeaders();
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectStorageService.prototype.deleteObject = function (projectName, environmentName, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeProjectMethodURL(projectName, 'static_files');
            var data = {
                path: path
            };
            var headers = new HttpHeaders();
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.request('DELETE', url, { body: data, headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return ProjectStorageService;
}());
export { ProjectStorageService };

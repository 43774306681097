import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';

import { Option } from '@modules/field-components';
import { Color, Gradient, GradientStop, GradientType } from '@modules/views';
import { isSet } from '@shared';

import { GradientStopArray } from './gradient-stop.array';
import { PointControl } from './point';

export class GradientControl extends FormGroup {
  instance: Gradient;

  controls: {
    type: FormControl;
    from: PointControl;
    to: PointControl;
    stops: GradientStopArray;
    aspect_ratio: FormControl;
  };

  typeOptions: Option<GradientType>[] = [
    {
      value: GradientType.Linear,
      name: 'Linear'
    },
    {
      value: GradientType.Radial,
      name: 'Radial'
    },
    {
      value: GradientType.Angular,
      name: 'Angular'
    },
    {
      value: GradientType.Diamond,
      name: 'Diamond'
    }
  ];

  constructor(state: Partial<Gradient> = {}) {
    super({
      type: new FormControl(isSet(state.type) ? state.type : GradientType.Linear),
      from: new PointControl(isSet(state.from) ? state.from : { x: 0.5, y: 0 }),
      to: new PointControl(isSet(state.to) ? state.to : { x: 0.5, y: 1 }),
      stops: new GradientStopArray([]),
      aspect_ratio: new FormControl(1)
    });
  }

  deserialize(value: Gradient, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.type.patchValue(value.type, { emitEvent: options.emitEvent });
    this.controls.from.deserialize(value.from);
    this.controls.to.deserialize(value.to);
    this.controls.stops.deserialize(value.stops);
    this.controls.aspect_ratio.patchValue(value.aspectRatio, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Gradient): Gradient {
    if (!instance) {
      instance = new Gradient();
    }

    instance.type = this.controls.type.value;
    instance.from = this.controls.from.getInstance(instance.from);
    instance.to = this.controls.to.getInstance(instance.to);
    instance.stops = this.controls.stops.getInstance(instance.stops);
    instance.aspectRatio = this.controls.aspect_ratio.value;

    return instance;
  }

  serialize(): Gradient {
    return this.getInstance(this.instance);
  }

  validateStops(defaultColor?: Color) {
    if (this.controls.stops.controls.length < 2) {
      const colorDefaults: Partial<Color> = defaultColor
        ? { red: defaultColor.red, green: defaultColor.green, blue: defaultColor.blue }
        : {};
      const controls = [
        { alpha: 1, position: 0 },
        { alpha: 0, position: 1 }
      ].map(item => {
        const color = new Color({ ...colorDefaults, alpha: item.alpha });
        const gradientStop = new GradientStop({ color: color, position: item.position });

        gradientStop.generateId();

        return this.controls.stops.createControl(gradientStop);
      });

      this.controls.stops.setControls(controls);
    }
  }

  invertStops() {
    range(Math.floor(this.controls.stops.controls.length / 2)).forEach(i => {
      const startControl = this.controls.stops.controls[i];
      const endControl = this.controls.stops.controls[this.controls.stops.controls.length - 1 - i];

      const startControlPosition = startControl.controls.position.value;
      const endControlPosition = endControl.controls.position.value;

      startControl.controls.position.patchValue(endControlPosition);
      endControl.controls.position.patchValue(startControlPosition);
    });
  }

  rotateStops() {
    [this.controls.from, this.controls.to].forEach(control => {
      const currentX = control.controls.x.value;
      const currentY = control.controls.y.value;

      control.controls.x.patchValue(1 - currentY);
      control.controls.y.patchValue(currentX);
    });
  }
}

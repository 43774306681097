import { Directive, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { CustomizeService } from '@modules/customize';
import { TypedChanges } from '@shared';

interface Options {
  enabled?: boolean;
}

@Directive({
  selector: '[appCustomizeHandlerHeaderCenter]'
})
export class CustomizeHandlerHeaderCenterDirective implements OnInit, OnDestroy, OnChanges {
  @Input('appCustomizeHandlerHeaderCenter') options: Options | null;

  constructor(private tpl: TemplateRef<any>, private customizeService: CustomizeService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.customizeService.handlerHeaderCenter === this.tpl) {
      this.customizeService.handlerHeaderCenter = undefined;
    }
  }

  ngOnChanges(changes: TypedChanges<CustomizeHandlerHeaderCenterDirective>): void {
    if (changes.options) {
      this.updateTemplate();
    }
  }

  getOptions(): Options {
    return {
      enabled: true,
      ...this.options
    };
  }

  updateTemplate() {
    const options = this.getOptions();
    this.customizeService.handlerHeaderCenter = options.enabled ? this.tpl : undefined;
  }
}

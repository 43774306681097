export class DataGroup<V = any, G1 = any, G2 = any, G3 = any> {
  public group: G1;
  public group2: G2;
  public group3: G3;
  public value: V;
  public color?: string;

  deserialize(data: Object) {
    this.group = data['group'];
    this.group2 = data['group_2'];
    this.group3 = data['group_3'];
    this.value = data['y_func'];
    this.color = data['color'];

    return this;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./customize-help.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../analytics/services/intercom/intercom.service";
import * as i5 from "../../../slack/stores/slack-channel.store";
import * as i6 from "../../../slack-components/services/slack-join-popup-controller/slack-join-popup-controller.service";
import * as i7 from "../../../features/services/feature/feature.service";
import * as i8 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_CustomizeHelpComponent = [];
var RenderType_CustomizeHelpComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeHelpComponent, data: {} });
export { RenderType_CustomizeHelpComponent as RenderType_CustomizeHelpComponent };
function View_CustomizeHelpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Slack channel"]))], null, null); }
function View_CustomizeHelpComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Join Slack channel"]))], null, null); }
function View_CustomizeHelpComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "live-widget-badge__indicator live-widget-badge__indicator_slack live-widget-badge__indicator_hidden"]], [[2, "live-widget-badge__indicator_hidden", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.slackChannelLoading || ((_co.slackChannel == null) ? null : _co.slackChannel.isJoined())); _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomizeHelpComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { menuElement: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["menu_element", 1]], null, 46, "div", [["class", "live-widget-menu"]], [[2, "live-widget-menu_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 45, "div", [["class", "live-widget-menu__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "live-widget-menu__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "live-widget-menu__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://docs.jetadmin.io/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("documentation") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-documents"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Read Documentation"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://docs.jetadmin.io/videos"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("video_tutorials") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-play"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Watch Video Tutorials"])), (_l()(), i0.ɵeld(13, 0, null, null, 13, "div", [["class", "live-widget-menu__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 12, "div", [["class", "live-widget-menu__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://feedback.jetadmin.io/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("request_feature") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-idea"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Request a Feature"])), (_l()(), i0.ɵeld(19, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://feedback.jetadmin.io/changelog/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("whats_new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-news"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["See What\u2019s New"])), (_l()(), i0.ɵeld(23, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://www.jetadmin.io/changelog"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("whats_new") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-align_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["View Changelog"])), (_l()(), i0.ɵeld(27, 0, null, null, 20, "div", [["class", "live-widget-menu__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 19, "div", [["class", "live-widget-menu__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 6, "a", [["class", "live-widget-menu__item"], ["href", "javascript:void(0)"]], [[2, "live-widget-menu__item_orange", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        _co.joinSlack();
        var pd_0 = (_co.onItemClicked("join_slack") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-dash"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 4, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeHelpComponent_1)), i0.ɵdid(33, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeHelpComponent_2)), i0.ɵdid(35, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(36, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://www.jetadmin.io/experts"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("experts") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-human_being"]], null, null, null, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hire an Expert"])), (_l()(), i0.ɵeld(40, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "https://community.jetadmin.io/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        var pd_0 = (_co.onItemClicked("ask_community") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-teams"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Ask Community"])), (_l()(), i0.ɵeld(44, 0, null, null, 3, "a", [["class", "live-widget-menu__item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setMenuOpened(false);
        _co.openChat();
        var pd_0 = (_co.onItemClicked("chat") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(45, 0, null, null, 0, "span", [["class", "live-widget-menu__item-icon icon-chat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(46, 0, null, null, 1, "span", [["class", "live-widget-menu__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Talk to Support"])), (_l()(), i0.ɵeld(48, 0, null, null, 2, "div", [["class", "live-widget-badge icon-help_filled"]], [[2, "live-widget-badge_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenuOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeHelpComponent_3)), i0.ɵdid(50, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.slackChannelLoading || ((_co.slackChannel == null) ? null : _co.slackChannel.isJoined())); _ck(_v, 33, 0, currVal_2); var currVal_3 = (!_co.slackChannelLoading && !((_co.slackChannel == null) ? null : _co.slackChannel.isJoined())); _ck(_v, 35, 0, currVal_3); var currVal_5 = _co.slackChannelStore.isEnabled(); _ck(_v, 50, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuOpened; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.slackChannelStore.isEnabled() && !_co.slackChannelLoading) && !((_co.slackChannel == null) ? null : _co.slackChannel.isJoined())); _ck(_v, 29, 0, currVal_1); var currVal_4 = _co.menuOpened; _ck(_v, 48, 0, currVal_4); }); }
export function View_CustomizeHelpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-help", [], null, null, null, View_CustomizeHelpComponent_0, RenderType_CustomizeHelpComponent)), i0.ɵdid(1, 245760, null, 0, i2.CustomizeHelpComponent, [i3.CurrentProjectStore, i4.IntercomService, i5.SlackChannelStore, i6.SlackJoinPopupController, i7.FeatureService, i8.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeHelpComponentNgFactory = i0.ɵccf("app-customize-help", i2.CustomizeHelpComponent, View_CustomizeHelpComponent_Host_0, {}, {}, []);
export { CustomizeHelpComponentNgFactory as CustomizeHelpComponentNgFactory };

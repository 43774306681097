import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ViewSettings } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { MenuContext } from '@modules/menu-components';

import { CustomizeBarPagesEditButtonControl } from '../customize-bar-pages-edit/button-control';
import { SystemOption } from '../edit-menu-item-action/edit-menu-item-action.component';

@Component({
  selector: 'app-button-menu-item-popover',
  templateUrl: './button-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonMenuItemPopoverComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditButtonControl;
  @Output() close = new EventEmitter<void>();

  createField = createFormFieldFactory();
  analyticsSource = 'button_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  onPageSelected(page: ViewSettings, finished: boolean) {
    this.control.controls.title.patchValue(page.name);

    if (finished) {
      this.close.emit();
    }
  }

  onSystemSelected(option: SystemOption) {
    this.control.controls.title.patchValue(option.label);
    this.control.controls.icon.patchValue(option.icon);
    this.close.emit();
  }
}

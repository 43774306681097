/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/player/components/video-player/video-player.component.ngfactory";
import * as i2 from "../../../../../common/player/components/video-player/video-player.component";
import * as i3 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./video-preview.component";
import * as i6 from "@angular/platform-browser";
var styles_VideoPreviewComponent = [];
var RenderType_VideoPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoPreviewComponent, data: {} });
export { RenderType_VideoPreviewComponent as RenderType_VideoPreviewComponent };
function View_VideoPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-player", [], null, null, null, i1.View_VideoPlayerComponent_0, i1.RenderType_VideoPlayerComponent)), i0.ɵdid(1, 770048, null, 0, i2.VideoPlayerComponent, [i0.ElementRef, i0.Renderer2], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewFile; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideoPreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [["allow", "autoplay"], ["allowfullscreen", ""], ["allowtransparency", ""], ["style", "border: 0;"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewEmbed; _ck(_v, 0, 0, currVal_0); }); }
export function View_VideoPreviewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_VideoPreviewComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_VideoPreviewComponent_2)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.previewFile)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.previewEmbed)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_VideoPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-video-preview", [], null, null, null, View_VideoPreviewComponent_0, RenderType_VideoPreviewComponent)), i0.ɵdid(1, 573440, null, 0, i5.VideoPreviewComponent, [i6.DomSanitizer, i0.ChangeDetectorRef], null, null)], null, null); }
var VideoPreviewComponentNgFactory = i0.ɵccf("app-video-preview", i5.VideoPreviewComponent, View_VideoPreviewComponent_Host_0, { preview: "preview" }, {}, []);
export { VideoPreviewComponentNgFactory as VideoPreviewComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i5 from "../../../../shared/components/loader/loader.component";
import * as i6 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i7 from "../../../theme-components/data/theme-context";
import * as i8 from "./calendar-year.component";
import * as i9 from "../../../model-queries/services/model/model.service";
import * as i10 from "../../../action-queries/services/action/action.service";
import * as i11 from "../../../projects/stores/current-project.store";
import * as i12 from "../../../projects/stores/current-environment.store";
var styles_CalendarYearComponent = [];
var RenderType_CalendarYearComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarYearComponent, data: {} });
export { RenderType_CalendarYearComponent as RenderType_CalendarYearComponent };
function View_CalendarYearComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [["class", "calendar-year-table__th"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.date.format("dd"); _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarYearComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "calendar-year-table__status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "calendar-year-table__status-item calendar-year-table__status-item--blue"]], null, null, null, null, null))], null, null); }
function View_CalendarYearComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "td", [["class", "calendar-year-table__td"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "calendar-year-table__date-number"]], [[2, "calendar-year-table__date-number--disabled", null], [2, "calendar-year-table__date-number--active", null], [8, "style", 2]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setParams(_co.weekParams(_v.context.$implicit.date)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 540672, null, 0, i1.TintColorDirective, [i2.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(3, 1), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_5)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 0), "accentColor")))); var currVal_4 = _co.tintStyles.Primary; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_6 = _co.items[_v.context.$implicit.date.format(_co.itemsGroupBy)]; _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.currentMonth; var currVal_1 = _v.context.$implicit.today; var currVal_2 = i0.ɵnov(_v, 2).style; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.date.format("D"); _ck(_v, 5, 0, currVal_5); }); }
function View_CalendarYearComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "calendar-year-table__tr"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_4)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarYearComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "calendar-year__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [["class", "calendar-year__item-caption"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "calendar-year__item-caption-link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setParams(_co.monthParams(_v.context.$implicit.date)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "table", [["class", "calendar-year-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "tr", [["class", "calendar-year-table__tr"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_2)), i0.ɵdid(8, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_3)), i0.ɵdid(10, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.weekDays; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.weeks; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.date.format("MMMM"); _ck(_v, 3, 0, currVal_0); }); }
function View_CalendarYearComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "calendar-loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "calendar-loader__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i0.ɵdid(3, 114688, null, 0, i5.LoaderComponent, [], { fill: [0, "fill"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CalendarYearComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.ThemeColorHexOptionPipe, [[2, i7.ThemeContext]]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "calendar-year"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_1)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarYearComponent_6)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.months; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CalendarYearComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-year", [], null, null, null, View_CalendarYearComponent_0, RenderType_CalendarYearComponent)), i0.ɵdid(1, 770048, null, 0, i8.CalendarYearComponent, [i9.ModelService, i10.ActionService, i11.CurrentProjectStore, i12.CurrentEnvironmentStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarYearComponentNgFactory = i0.ɵccf("app-calendar-year", i8.CalendarYearComponent, View_CalendarYearComponent_Host_0, { date: "date", params: "params", settings: "settings", listState: "listState" }, { queryParamsChanged: "queryParamsChanged" }, []);
export { CalendarYearComponentNgFactory as CalendarYearComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { MenuSettings } from '../../data/menu-settings';
var MenuSettingsService = /** @class */ (function () {
    function MenuSettingsService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    MenuSettingsService.prototype.get = function (projectName, environmentName, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'menu_settings/');
            var headers = new HttpHeaders();
            var params = __assign({}, (draft && { draft: '1' }));
            params['project'] = projectName;
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) {
            if (!result.length) {
                return;
            }
            var instance = new MenuSettings().deserialize(result[0]);
            instance.project = projectName;
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    MenuSettingsService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'menu_settings/');
            var headers = new HttpHeaders();
            var params = {
                project: projectName,
                draft: '1'
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers, params: params });
        }), map(function (result) {
            var newInstance = new MenuSettings().deserialize(result);
            newInstance.project = projectName;
            return newInstance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return MenuSettingsService;
}());
export { MenuSettingsService };

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { createFormFieldFactory } from '@modules/fields';
import { LogoOption } from '@modules/layout-components';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { TypedChanges } from '@shared';

import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';

export interface ImageMenuItemPopoverOptions {
  focusTitle?: boolean;
  focusSubtitle?: boolean;
}

@Component({
  selector: 'app-image-menu-item-popover',
  templateUrl: './image-menu-item-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageMenuItemPopoverComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: CustomizeBarPagesEditImageControl;
  @Input() options: ImageMenuItemPopoverOptions = {};
  @Input() imageOptions: LogoOption[] = [];
  @Input() layout: MenuBlockLayout;

  createField = createFormFieldFactory();
  imageMaxSize = 50;
  colors = ['blue', 'teal', 'green', 'red', 'black'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  analyticsSource = 'image_menu_item_popover';

  constructor(public context: MenuContext) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ImageMenuItemPopoverComponent>): void {
    if (changes.layout) {
      this.imageMaxSize = MenuBlockLayouts.getImageMaxSize(this.layout);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

export function formatDate(value: any, format: string, relative = false) {
  // TODO: Optimize when relative=false
  const valueDate = moment(value).startOf('second');
  const valueDateStart = valueDate.clone().startOf('day');
  const nowDate = moment().startOf('second');
  const nowDateStart = nowDate.clone().startOf('day');
  const daysDiff = Math.abs(nowDateStart.diff(valueDateStart, 'days'));

  if (relative && daysDiff < 7) {
    return valueDate.from(nowDate);
  } else {
    return valueDate.format(format);
  }
}

@Pipe({
  name: 'appFormatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any, format: string, relative = false): string {
    return formatDate(value, format, relative);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "./sidebar-image-card.component";
import * as i4 from "../../../theme/services/theme/theme.service";
var styles_SidebarImageCardComponent = [];
var RenderType_SidebarImageCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarImageCardComponent, data: {} });
export { RenderType_SidebarImageCardComponent as RenderType_SidebarImageCardComponent };
function View_SidebarImageCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "sidebar-image-card2__image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imagePath; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarImageCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-image-card2__image-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-image-card2__image-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SidebarImageCardComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "sidebar-image-card2__image-indicator"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indicatorImage; _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarImageCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar-image-card2__image-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarImageCardComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarImageCardComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarImageCardComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.icon && !_co.image); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.indicatorImage; _ck(_v, 6, 0, currVal_2); }, null); }
function View_SidebarImageCardComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-image-card2__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "sidebar-image-card2__title-right sidebar-image-card2__icon icon-dots_small"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "sidebar-image-card2__line"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
function View_SidebarImageCardComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-image-card2__tag background-color_blue_1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "sidebar-image-card2__tag-raw"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u03B2"])), (_l()(), i0.ɵted(-1, null, ["eta "]))], null, null); }
export function View_SidebarImageCardComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "sidebar-image-card2"]], [[2, "sidebar-image-card2_movable", null], [2, "sidebar-image-card2_hoverable", null], [2, "sidebar-image-card2_purple-gradient", null], [2, "sidebar-image-card2_shadow", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarImageCardComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarImageCardComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarImageCardComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.image || _co.icon); _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.title)); _ck(_v, 5, 0, currVal_5); var currVal_6 = _co.beta; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.movable; var currVal_1 = _co.hoverable; var currVal_2 = _co.purpleGradient; var currVal_3 = _co.shadow; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_SidebarImageCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-image-card", [], null, null, null, View_SidebarImageCardComponent_0, RenderType_SidebarImageCardComponent)), i0.ɵdid(1, 770048, null, 0, i3.SidebarImageCardComponent, [i4.ThemeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarImageCardComponentNgFactory = i0.ɵccf("app-sidebar-image-card", i3.SidebarImageCardComponent, View_SidebarImageCardComponent_Host_0, { title: "title", image: "image", icon: "icon", indicatorImage: "indicatorImage", movable: "movable", hoverable: "hoverable", purpleGradient: "purpleGradient", shadow: "shadow", beta: "beta" }, {}, []);
export { SidebarImageCardComponentNgFactory as SidebarImageCardComponentNgFactory };

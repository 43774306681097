var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';
export var TimetableType;
(function (TimetableType) {
    TimetableType["EveryDay"] = "every_day";
    TimetableType["EveryWeek"] = "every_week";
    TimetableType["EveryMonth"] = "every_month";
    TimetableType["Once"] = "once";
    TimetableType["Custom"] = "model";
})(TimetableType || (TimetableType = {}));
var TimetableAutomationTrigger = /** @class */ (function (_super) {
    __extends(TimetableAutomationTrigger, _super);
    function TimetableAutomationTrigger() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = AutomationTriggerType.Timetable;
        return _this;
    }
    TimetableAutomationTrigger.prototype.deserialize = function (data) {
        var timetableTime = data['timetable_time'] ? moment(data['timetable_time'], ['HH:mm:ss', 'HH:mm']) : undefined;
        var timetableDate = data['timetable_date'] ? moment(data['timetable_date']) : undefined;
        this.timetableType = data['timetable_type'];
        this.timetableTime = timetableTime && timetableTime.isValid() ? timetableTime : undefined;
        this.timetableDaysOfWeek = data['timetable_days_of_week'];
        this.timetableDays = data['timetable_days'];
        this.timetableMonths = data['timetable_months'];
        this.timetableDate = timetableDate && timetableDate.isValid() ? timetableDate : undefined;
        return this;
    };
    TimetableAutomationTrigger.prototype.serialize = function () {
        return {
            timetable_type: this.timetableType,
            timetable_time: this.timetableTime ? this.timetableTime.format('HH:mm:ss') : undefined,
            timetable_days_of_week: this.timetableDaysOfWeek,
            timetable_days: this.timetableDays,
            timetable_months: this.timetableMonths,
            timetable_date: this.timetableDate ? this.timetableDate.toISOString() : undefined
        };
    };
    return TimetableAutomationTrigger;
}(AutomationTrigger));
export { TimetableAutomationTrigger };
registerAutomationTriggerForType(AutomationTriggerType.Timetable, TimetableAutomationTrigger);

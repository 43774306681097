var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { rawListViewSettingsColumnToModelField } from '@modules/customize';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { TemplateService } from '@modules/template';
import { isSet } from '@shared';
export function validateVerboseName() {
    return function (control) {
        var parent = control.parent;
        if (!control.value || !parent || !parent.resource) {
            return;
        }
        if (parent.modelDescriptionStore.instance
            .filter(function (item) { return item.resource == parent.resource.uniqueName && !item.deleted; })
            .find(function (item) { return item.verboseNamePlural && item.verboseNamePlural.toLowerCase() == control.value.toLowerCase(); })) {
            return {
                local: ['Collection with such Name already exists']
            };
        }
    };
}
var ModelCreateForm = /** @class */ (function (_super) {
    __extends(ModelCreateForm, _super);
    function ModelCreateForm(modelDescriptionStore, actionStore, modelDescriptionService, resourceControllerService, formUtils, templateService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this, {
            verbose_name: new FormControl('', [Validators.required, validateVerboseName()]),
            verbose_name_plural: new FormControl('')
        }) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionStore = actionStore;
        _this.modelDescriptionService = modelDescriptionService;
        _this.resourceControllerService = resourceControllerService;
        _this.formUtils = formUtils;
        _this.templateService = templateService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.options = {};
        return _this;
    }
    ModelCreateForm.prototype.init = function (resource, options) {
        if (options === void 0) { options = {}; }
        this.resource = resource;
        this.options = options;
    };
    ModelCreateForm.prototype.submit = function () {
        var _this = this;
        if (!this.valid) {
            return throwError('Not all fields are filled in.');
        }
        var instance = new ModelDescription();
        var controller = this.resourceControllerService.get(this.resource.type);
        instance.resource = this.resource.uniqueName;
        instance.model = ModelDescription.generateModel();
        instance.verboseName = this.controls.verbose_name.value;
        instance.verboseNamePlural = this.controls.verbose_name.value;
        instance.queryType = this.options.queryType;
        instance.virtual = !this.options.resourceCreate;
        if (isSet(this.options.primaryKey)) {
            instance.primaryKeyField = this.options.primaryKey;
        }
        if (isSet(this.options.query)) {
            instance.getQuery = cloneDeep(this.options.query);
        }
        if (isSet(this.options.parameters)) {
            instance.getParameters = cloneDeep(this.options.parameters);
        }
        if (this.options.columns) {
            var fields = this.options.columns.map(function (item) {
                var result = rawListViewSettingsColumnToModelField(item);
                if (result.item instanceof ModelDbField) {
                    result.item.editable = true;
                }
                return result;
            });
            if (controller) {
                controller.setUpModelDescriptionBasedOnGetQuery(this.resource, instance, instance.getQuery, fields);
            }
        }
        if (this.options.resourceCreate) {
            instance.dbTable = instance.model;
            return this.templateService.getResourceDefaultModelDescription(this.resource.typeItem.name).pipe(switchMap(function (template) {
                instance.model = ModelDescription.generateModel();
                instance.dbTable = instance.model;
                if (template && template.modelDescription) {
                    instance.fields = instance.fields.filter(function (item) { return item.name == instance.primaryKeyField; }).concat(template.modelDescription.fields.filter(function (item) { return item.name != instance.primaryKeyField; }));
                }
                return controller.modelDescriptionCreate(_this.resource, instance).pipe(switchMap(function () { return _this.modelDescriptionService.getFromResource(_this.resource); }), map(function (resourceModelDescriptions) { return resourceModelDescriptions.find(function (item) { return item.model == instance.model; }); }), tap(function (result) { return _this.modelDescriptionStore.addItem(result); }), delayWhen(function (result) { return _this.actionStore.syncAutoActions(result); }), delayWhen(function (modelDescription) {
                    if (!template || !template.models.length) {
                        return of([]);
                    }
                    var models = template.models.map(function (data) {
                        var model = controller.createModel().deserialize(modelDescription.modelId, data);
                        model.setUp(modelDescription);
                        model.deserializeAttributes(modelDescription.dbFields);
                        return model;
                    });
                    return controller.modelCreateBulk(_this.resource, modelDescription, models);
                }));
            }), catchError(function (error) {
                _this.markAsDirty();
                _this.formUtils.showFormErrors(_this, error);
                return throwError(error);
            }));
        }
        else {
            return this.modelDescriptionService
                .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
                .pipe(tap(function (result) { return _this.modelDescriptionStore.addItem(result); }), delayWhen(function (result) { return _this.actionStore.syncAutoActions(result); }), catchError(function (error) {
                _this.markAsDirty();
                _this.formUtils.showFormErrors(_this, error);
                return throwError(error);
            }));
        }
    };
    return ModelCreateForm;
}(FormGroup));
export { ModelCreateForm };

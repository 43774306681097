import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsPreviousQuarterFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_previous_quarter';
  public param = 'isPreviousQuarter';
  public verboseName = localize('is Previous Quarter');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('quarter').subtract(1, 'quarters').toISOString();
  }

  to(value: string): string {
    return moment().startOf('quarter').subtract(1, 'quarters').endOf('quarter').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not previous quarter', [field]);
      } else {
        return localize('is not previous quarter');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is previous quarter', [field]);
      } else {
        return localize('previous quarter');
      }
    }
  }
}

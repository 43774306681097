/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i3 from "./container-layer-control.component";
var styles_ContainerLayerControlComponent = [];
var RenderType_ContainerLayerControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContainerLayerControlComponent, data: {} });
export { RenderType_ContainerLayerControlComponent as RenderType_ContainerLayerControlComponent };
export function View_ContainerLayerControlComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "compact-heading compact-heading_secondary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Auto layout position\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "compact-action-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-action-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "compact-action-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "compact-action-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "compact-action-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "compact-action-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "compact-action-item__toggle"]], [[2, "compact-action-item__toggle_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.absoluteControl.patchValue(!_co.absoluteControl.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 737280, null, 0, i2.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(12, { side: 0 }), (_l()(), i0.ɵted(-1, null, [" Absolute "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "compact-action-item__icon"; var currVal_1 = ("icon-" + _co.containerLayer.icon); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_4 = (_co.absoluteControl.value ? "Disable absolute position" : "Enable absolute position"); var currVal_5 = _ck(_v, 12, 0, "top"); _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.containerLayer.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.absoluteControl.value; _ck(_v, 10, 0, currVal_3); }); }
export function View_ContainerLayerControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-container-layer-control", [], null, null, null, View_ContainerLayerControlComponent_0, RenderType_ContainerLayerControlComponent)), i0.ɵdid(1, 114688, null, 0, i3.ContainerLayerControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContainerLayerControlComponentNgFactory = i0.ɵccf("app-container-layer-control", i3.ContainerLayerControlComponent, View_ContainerLayerControlComponent_Host_0, { containerLayer: "containerLayer", absoluteControl: "absoluteControl" }, {}, []);
export { ContainerLayerControlComponentNgFactory as ContainerLayerControlComponentNgFactory };

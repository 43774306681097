var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ascComparator, isSet, pointsDistance, radToDeg, square } from '@shared';
import { Frame } from './frame';
import { GradientStop } from './gradient-stop';
import { Point } from './point';
export var GradientType;
(function (GradientType) {
    GradientType["Linear"] = "linear";
    GradientType["Radial"] = "radial";
    GradientType["Angular"] = "angular";
    GradientType["Diamond"] = "diamond";
})(GradientType || (GradientType = {}));
var Gradient = /** @class */ (function () {
    function Gradient(options) {
        if (options === void 0) { options = {}; }
        this.type = GradientType.Linear;
        this.stops = [];
        this.aspectRatio = 1;
        Object.assign(this, options);
    }
    Gradient.prototype.deserialize = function (data) {
        if (data['type']) {
            this.type = data['type'];
        }
        if (data['from']) {
            this.from = new Point().deserialize(data['from']);
        }
        if (data['to']) {
            this.to = new Point().deserialize(data['to']);
        }
        if (data['stops']) {
            this.stops = data['stops'].map(function (item) { return new GradientStop().deserialize(item); });
        }
        if (isSet(data['aspect_ratio'])) {
            this.aspectRatio = data['aspect_ratio'];
        }
        return this;
    };
    Gradient.prototype.serialize = function () {
        return {
            type: this.type,
            from: this.from ? this.from.serialize() : undefined,
            to: this.to ? this.to.serialize() : undefined,
            stops: this.stops.map(function (item) { return item.serialize(); }),
            aspect_ratio: this.aspectRatio
        };
    };
    Gradient.prototype.css$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        options = __assign({ frame: new Frame({ width: 1, height: 1 }) }, options);
        var from = {
            x: this.from.x * options.frame.width,
            y: this.from.y * options.frame.height
        };
        var to = {
            x: this.to.x * options.frame.width,
            y: this.to.y * options.frame.height
        };
        var angle = this.getAngle(options.frame);
        var stops$ = this.stops.length
            ? combineLatest(this.stops.map(function (item) {
                return item
                    .cssColor$({
                    context: options.context,
                    contextElement: options.contextElement,
                    localContext: options.localContext
                })
                    .pipe(map(function (color) {
                    return {
                        stop: item,
                        color: color
                    };
                }));
            }))
            : of([]);
        return combineLatest(stops$).pipe(map(function (_a) {
            var stops = _a[0];
            if (_this.type == GradientType.Radial) {
                var height = pointsDistance(to.x, to.y, from.x, from.y);
                var width = _this.aspectRatio * height;
                var stopsCss = stops
                    .filter(function (item) { return item.color; })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.position, rhs.position); })
                    .map(function (item) {
                    var position = item.stop.position * 100 + "%";
                    return item.color + " " + position;
                });
                if (!stopsCss.length) {
                    return {};
                }
                if (angle == 0 || angle == 180) {
                    var position = _this.from.x * 100 + "% " + _this.from.y * 100 + "%";
                    return {
                        background: "radial-gradient(ellipse " + width + "px " + height + "px at " + position + ", " + stopsCss.join(', ') + ")"
                    };
                }
                else if (angle == 90 || angle == 270) {
                    var position = _this.from.x * 100 + "% " + _this.from.y * 100 + "%";
                    return {
                        background: "radial-gradient(ellipse " + height + "px " + width + "px at " + position + ", " + stopsCss.join(', ') + ")"
                    };
                }
                else {
                    var sizeMultiplier = 5;
                    var fromTransform = {
                        x: (_this.from.x * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100,
                        y: (_this.from.y * (1 / sizeMultiplier) - 1 / sizeMultiplier / 2) * 100
                    };
                    return {
                        background: "radial-gradient(ellipse " + width + "px " + height + "px at 50% 50%, " + stopsCss.join(', ') + ")",
                        width: sizeMultiplier * 100 + "%",
                        height: sizeMultiplier * 100 + "%",
                        transform: "translate(-50%, -50%) translate(" + fromTransform.x + "%, " + fromTransform.y + "%) rotate(" + angle + "deg)"
                    };
                }
            }
            else if (_this.type == GradientType.Angular) {
                var stopsCss = stops
                    .filter(function (item) { return item.color; })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.position, rhs.position); })
                    .map(function (item) {
                    var position = item.stop.position * 100 + "%";
                    return item.color + " " + position;
                });
                if (!stopsCss.length) {
                    return {};
                }
                if (_this.aspectRatio == 1) {
                    var position = _this.from.x * 100 + "% " + _this.from.y * 100 + "%";
                    return {
                        background: "conic-gradient(from " + angle + "deg at " + position + ", " + stopsCss.join(', ') + ")"
                    };
                }
                else {
                    var aspectRatio = Math.max(_this.aspectRatio, 0.01);
                    var widthMultiplier = (1 / aspectRatio) * 5;
                    var heightMultiplier = 5;
                    var fromTransform = {
                        x: (_this.from.x * (1 / widthMultiplier) - 1 / widthMultiplier / 2) * 100,
                        y: (_this.from.y * (1 / heightMultiplier) - 1 / heightMultiplier / 2) * 100
                    };
                    return {
                        background: "conic-gradient(at 50% 50%, " + stopsCss.join(', ') + ")",
                        width: widthMultiplier * 100 + "%",
                        height: heightMultiplier * 100 + "%",
                        transform: "translate(-50%, -50%) translate(" + fromTransform.x + "%, " + fromTransform.y + "%) rotate(" + angle + "deg) scaleX(" + aspectRatio + ")"
                    };
                }
            }
            else if (_this.type == GradientType.Diamond) {
                var aspectRatio = Math.max(_this.aspectRatio, 0.01);
                var widthMultiplier = (1 / aspectRatio) * 5;
                var heightMultiplier = 5;
                var sizeMultiplier_1 = Math.ceil(Math.max(widthMultiplier, heightMultiplier));
                var length_1 = pointsDistance(_this.to.x, _this.to.y, _this.from.x, _this.from.y);
                var stopsCss = stops
                    .filter(function (item) { return item.color; })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.position, rhs.position); })
                    .map(function (item) {
                    var position = ((item.stop.position * length_1) / sizeMultiplier_1) * 100 + "%";
                    return item.color + " " + position;
                });
                if (!stopsCss.length) {
                    return {};
                }
                var fromTransform = {
                    x: (_this.from.x * (1 / sizeMultiplier_1) - 1 / sizeMultiplier_1 / 2) * 100,
                    y: (_this.from.y * (1 / sizeMultiplier_1) - 1 / sizeMultiplier_1 / 2) * 100
                };
                var background = [
                    "linear-gradient(to right bottom, " + stopsCss.join(', ') + ") right bottom / 50% 50% no-repeat",
                    "linear-gradient(to left bottom, " + stopsCss.join(', ') + ") left bottom / 50% 50% no-repeat",
                    "linear-gradient(to left top, " + stopsCss.join(', ') + ") left top / 50% 50% no-repeat",
                    "linear-gradient(to right top, " + stopsCss.join(', ') + ") right top / 50% 50% no-repeat"
                ].join(', ');
                var scaleToSquare = options.frame.height / options.frame.width;
                return {
                    background: background,
                    width: sizeMultiplier_1 * 100 + "%",
                    height: sizeMultiplier_1 * 100 + "%",
                    transform: "translate(-50%, -50%) translate(" + fromTransform.x + "%, " + fromTransform.y + "%) rotate(" + angle + "deg) scaleX(" + scaleToSquare + ") scaleX(" + aspectRatio + ")"
                };
            }
            else {
                var getPosition_1;
                if (to.x - from.x == 0) {
                    var startOuter = angle == 180 ? from.y < 0 : from.y > options.frame.height;
                    var startDistance_1 = angle == 180 ? from.y : options.frame.height - from.y;
                    var fromToDistance_1 = Math.abs(to.y - from.y);
                    var totalDistance_1 = options.frame.height;
                    if (startOuter) {
                        totalDistance_1 += Math.abs(startDistance_1);
                    }
                    getPosition_1 = function (position) {
                        return (startDistance_1 + position * fromToDistance_1) / totalDistance_1;
                    };
                }
                else if (to.y - from.y == 0) {
                    var startOuter = angle == 90 ? from.x < 0 : from.x > options.frame.width;
                    var startDistance_2 = angle == 90 ? from.x : options.frame.width - from.x;
                    var fromToDistance_2 = Math.abs(to.x - from.x);
                    var totalDistance_2 = options.frame.width;
                    if (startOuter) {
                        totalDistance_2 += Math.abs(startDistance_2);
                    }
                    getPosition_1 = function (position) {
                        return (startDistance_2 + position * fromToDistance_2) / totalDistance_2;
                    };
                }
                else {
                    var k = (to.y - from.y) / (to.x - from.x);
                    var b = (to.x * from.y - from.x * to.y) / (to.x - from.x);
                    var edgeK_1 = -1 / k;
                    var edgeB = [
                        { x: 0, y: 0 },
                        { x: options.frame.width, y: 0 },
                        { x: options.frame.width, y: options.frame.height },
                        { x: 0, y: options.frame.height }
                    ].map(function (_a) {
                        var x = _a.x, y = _a.y;
                        return y - edgeK_1 * x;
                    });
                    var edgeMaxB = Math.max.apply(Math, edgeB);
                    var edgeMinB = Math.min.apply(Math, edgeB);
                    var minB = {
                        x: ((edgeMinB - b) * k) / (square(k) + 1),
                        y: (edgeMinB * square(k) + b) / (square(k) + 1)
                    };
                    var maxB = {
                        x: ((edgeMaxB - b) * k) / (square(k) + 1),
                        y: (edgeMaxB * square(k) + b) / (square(k) + 1)
                    };
                    var fromB = angle >= 90 && angle <= 270 ? minB : maxB;
                    var startB = from.y - edgeK_1 * from.x;
                    var startOuter = angle >= 90 && angle <= 270 ? startB < edgeMinB : startB > edgeMaxB;
                    var startDistance_3 = Math.sqrt(square(from.x - fromB.x) + square(from.y - fromB.y));
                    var fromToDistance_3 = Math.sqrt(square(to.x - from.x) + square(to.y - from.y));
                    var totalDistance_3 = Math.sqrt(square(maxB.x - minB.x) + square(maxB.y - minB.y));
                    if (startOuter) {
                        totalDistance_3 += startDistance_3;
                        startDistance_3 *= -1;
                    }
                    getPosition_1 = function (position) {
                        return (startDistance_3 + position * fromToDistance_3) / totalDistance_3;
                    };
                }
                var stopsCss = stops
                    .filter(function (item) { return item.color; })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.position, rhs.position); })
                    .map(function (item) {
                    var position = getPosition_1(item.stop.position) * 100 + "%";
                    return item.color + " " + position;
                });
                return {
                    background: "linear-gradient(" + angle + "deg, " + stopsCss.join(', ') + ")"
                };
            }
        }));
    };
    Gradient.prototype.getAngle = function (frame) {
        if (frame === void 0) { frame = new Frame({ width: 1, height: 1 }); }
        var from = {
            x: this.from.x * frame.width,
            y: this.from.y * frame.height
        };
        var to = {
            x: this.to.x * frame.width,
            y: this.to.y * frame.height
        };
        var delta = {
            x: to.x - from.x,
            y: from.y - to.y
        };
        if (delta.y == 0) {
            return delta.x >= 0 ? 90 : 270;
        }
        else {
            var angle = radToDeg(Math.atan(delta.x / delta.y));
            if (delta.x >= 0 && delta.y >= 0) {
                return angle;
            }
            else if (delta.x >= 0 && delta.y < 0) {
                return 180 + angle;
            }
            else if (delta.x < 0 && delta.y < 0) {
                return 180 + angle;
            }
            else if (delta.x < 0 && delta.y >= 0) {
                return 360 + angle;
            }
        }
    };
    return Gradient;
}());
export { Gradient };

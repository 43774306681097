var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectProperty } from '../../data/project-property';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectPropertyService = /** @class */ (function () {
    function ProjectPropertyService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectPropertyService.prototype.get = function (projectName, environmentName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({}, (draft && { draft: '1' }), params)
                // encoder: this.parameterCodec
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectProperty().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPropertyService.prototype.createBulk = function (projectName, environmentName, instances) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instances.map(function (item) { return item.serialize(); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new ProjectProperty().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPropertyService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'properties/');
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instance.serialize();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return new ProjectProperty().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
        // return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    };
    ProjectPropertyService.prototype.update = function (projectName, environmentName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "properties/" + instance.uid + "/");
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instance.serialize(fields);
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers, params: params });
        }), map(function (result) { return new ProjectProperty().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
        // return this.createBulk(projectName, environmentName, [instance]).pipe(map(result => result[0]));
    };
    ProjectPropertyService.prototype.delete = function (projectName, environmentName, instance) {
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `properties/${instance.uid}`);
        //     let headers = new HttpHeaders();
        //     const params = {
        //       draft: '1'
        //     };
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers, params: params });
        //   }),
        //   map(() => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, [instance]).pipe(map(function () { return true; }));
    };
    ProjectPropertyService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectPropertyService_Factory() { return new ProjectPropertyService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectPropertyService, providedIn: "root" });
    return ProjectPropertyService;
}());
export { ProjectPropertyService };

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueue } from '@modules/collaboration';

import { TaskQueueEditPopupComponent } from '../../components/task-queue-edit-popup/task-queue-edit-popup.component';

export interface TaskQueueEditResult {
  queue?: TaskQueue;
  created?: boolean;
  edited?: boolean;
  deleted?: boolean;
  cancelled?: boolean;
}

@Injectable()
export class TaskQueueEditController {
  constructor(
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  openEditPopup(queue?: TaskQueue, options: { analyticsSource?: string } = {}): Observable<TaskQueueEditResult> {
    const result = new Subject<TaskQueueEditResult>();

    this.popupService.push({
      component: TaskQueueEditPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        queue: queue
      },
      outputs: {
        added: [
          (resultQueue: TaskQueue) => {
            result.next({
              queue: resultQueue,
              created: true
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Approval.TaskQueueCreated, {
              Source: options.analyticsSource,
              TaskQueueID: resultQueue.uid
            });
          }
        ],
        edited: [
          (resultQueue: TaskQueue) => {
            result.next({
              queue: resultQueue,
              edited: true
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Approval.TaskQueueEdited, {
              Source: options.analyticsSource,
              TaskQueueID: resultQueue.uid
            });
          }
        ],
        deleted: [
          () => {
            result.next({
              queue: queue,
              deleted: true
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Approval.TaskQueueDeleted, {
              Source: options.analyticsSource,
              TaskQueueID: queue.uid
            });
          }
        ]
      },
      injector: this.injector
    });

    return result;
  }

  createTaskQueue(options: { analyticsSource?: string } = {}): Observable<TaskQueueEditResult> {
    return this.openEditPopup(undefined, options);
  }

  editTaskQueue(queue: TaskQueue, options: { analyticsSource?: string } = {}): Observable<TaskQueueEditResult> {
    return this.openEditPopup(queue, options);
  }
}

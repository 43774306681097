<div class="compact-inputs-section">
  <div class="compact-inputs-section__header">
    <div class="compact-inputs-section__header-main">
      <div class="compact-inputs-section__header-title">Interactions</div>
    </div>
    <div class="compact-inputs-section__header-right">
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
        [cdkConnectedOverlayPositions]="interactionsDropdownPositions"
        [cdkConnectedOverlayOrigin]="interactions_dropdown_trigger"
        [cdkConnectedOverlayOpen]="interactionsDropdownOpened"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayGrowAfterOpen]="true"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
        (backdropClick)="setInteractionsDropdownOpened(false)"
        (detach)="setInteractionsDropdownOpened(false)"
      >
        <div class="compact-select-options">
          <div
            class="compact-select-options__item compact-select-option"
            [class.compact-select-option_disabled]="hoverOutputExists"
            (click)="addHoverOutputInteraction(); setInteractionsDropdownOpened(false)"
          >
            <div class="compact-select-option__left">
              <div class="compact-select-option__icon icon-target"></div>
            </div>

            <div class="compact-select-option__main">
              <div class="compact-select-option__row">
                <div class="compact-select-option__title">Track hover state</div>
              </div>
            </div>
          </div>

          <div
            class="compact-select-options__item compact-select-option"
            [class.compact-select-option_disabled]="pressedOutputExists"
            (click)="addPressedOutputInteraction(); setInteractionsDropdownOpened(false)"
          >
            <div class="compact-select-option__left">
              <div class="compact-select-option__icon icon-select_all"></div>
            </div>

            <div class="compact-select-option__main">
              <div class="compact-select-option__row">
                <div class="compact-select-option__title">Track pressed state</div>
              </div>
            </div>
          </div>

          <div
            class="compact-select-options__item compact-select-option"
            (click)="addClickInteraction(); setInteractionsDropdownOpened(false)"
          >
            <div class="compact-select-option__left">
              <div class="compact-select-option__icon icon-hand"></div>
            </div>

            <div class="compact-select-option__main">
              <div class="compact-select-option__row">
                <div class="compact-select-option__title">Click action</div>
              </div>
            </div>
          </div>

          <app-interactions-section-cursor-dropdown
            [origin]="cursor_trigger"
            [openOnFocus]="cursor_element"
            (selected)="addCursorInteraction($event); setInteractionsDropdownOpened(false)"
          ></app-interactions-section-cursor-dropdown>

          <div
            class="compact-select-options__item compact-select-option"
            cdkOverlayOrigin
            #cursor_trigger="cdkOverlayOrigin"
            #cursor_element
          >
            <div class="compact-select-option__left">
              <div class="compact-select-option__icon icon-cursor"></div>
            </div>

            <div class="compact-select-option__main">
              <div class="compact-select-option__row">
                <div class="compact-select-option__title">Cursor</div>
              </div>
            </div>

            <div class="compact-select-option__right">
              <div class="compact-select-option__arrow icon-arrow_forward_2"></div>
            </div>
          </div>

          <div
            *ngIf="textInteractions"
            class="compact-select-options__item compact-select-option"
            [class.compact-select-option_disabled]="isTextSelectionEnabled()"
            (click)="enableTextSelection(); setInteractionsDropdownOpened(false)"
          >
            <div class="compact-select-option__left">
              <div class="compact-select-option__icon icon-underline"></div>
            </div>

            <div class="compact-select-option__main">
              <div class="compact-select-option__row">
                <div class="compact-select-option__title">Text selection</div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div
        class="compact-button icon-plus"
        [class.compact-button_primary]="true"
        cdkOverlayOrigin
        #interactions_dropdown_trigger="cdkOverlayOrigin"
        (click)="setInteractionsDropdownOpened(true)"
      ></div>
    </div>
  </div>

  <div
    class="compact-inputs-element"
    cdkDropList
    [cdkDropListLockAxis]="'y'"
    (cdkDropListDropped)="interactionDragDrop($event)"
  >
    <div
      *ngIf="textInteractions && isTextSelectionEnabled()"
      class="compact-inputs-element compact-inputs-element_padding_none compact-inputs-element_margin_s"
    >
      <app-compact-wide-button
        [label]="'Text selection'"
        [leftIcon]="'underline'"
        [leftBackground]="true"
        [rightIcon]="'close'"
        [rightInteractive]="true"
        [rightTooltip]="'Disable selection'"
        (rightClick)="disableTextSelection()"
      ></app-compact-wide-button>
    </div>

    <div
      *ngFor="let item of control.controls"
      class="compact-inputs-element compact-inputs-element_padding_none compact-inputs-element_margin_s"
      cdkDrag
    >
      <app-interactions-section-hover-output
        *ngIf="item.type == types.HoverOutput"
        [control]="item"
        [arrayControl]="control"
      ></app-interactions-section-hover-output>

      <app-interactions-section-pressed-output
        *ngIf="item.type == types.PressedOutput"
        [control]="item"
        [arrayControl]="control"
      ></app-interactions-section-pressed-output>

      <app-interactions-section-cursor
        *ngIf="item.type == types.Cursor"
        [control]="item"
        [arrayControl]="control"
      ></app-interactions-section-cursor>

      <app-interactions-section-click
        *ngIf="item.type == types.Click"
        [control]="item"
        [arrayControl]="control"
        [viewContext]="viewContext"
      ></app-interactions-section-click>
    </div>
  </div>
</div>

<div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDropOption($event)">
  <div *ngFor="let control of form.controls.element_inputs.controls; let i = index" cdkDrag class="sidebar-list__item">
    <div
      class="sidebar-list-item sidebar-list-item_clickable"
      [class.sidebar-list-item_draggable]="true"
      [class.sidebar-list-item_disabled]="false"
      (click)="editItem(control)"
    >
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="icon button__icon" [ngClass]="['icon-' + control.fieldDescription.icon]"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">
          <ng-container *ngIf="control.controls.settings.value && control.controls.settings.value['verboseName']">
            {{ control.controls.settings.value['verboseName'] }}
          </ng-container>

          <ng-container *ngIf="!control.controls.settings.value || !control.controls.settings.value['verboseName']">
            {{ control.field.verboseName }}
          </ng-container>
        </div>

        <div class="sidebar-list-item__description">
          {{ control.fieldDescription.label }}
        </div>
      </div>
      <div class="sidebar-list-item__right">
        <!--        <span-->
        <!--          class="sidebar-list-item__action sidebar-list-item__action_button icon-bin"-->
        <!--          (click)="$event.stopPropagation(); removeItem(control.field.name)"-->
        <!--        ></span>-->

        <app-toggle
          [orange]="true"
          [selected]="true"
          (click)="$event.stopPropagation(); removeItem(control.field.name)"
        >
        </app-toggle>
      </div>
    </div>
  </div>

  <div *ngIf="!form.controls.element_inputs.controls.length" class="sidebar-list__item">
    <div class="sidebar-list-item sidebar-list-item_disabled component-data-button">
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">Filter</div>
      </div>
    </div>
  </div>

  <div class="sidebar-list__buttons">
    <mat-menu #dropdown="matMenu">
      <ng-template matMenuContent>
        <app-custom-select-dropdown
          [items]="addItems"
          [searchEnabled]="true"
          [emptyLabel]="'No inputs'"
          [capitalize]="false"
          (optionClick)="onAddOptionClick($event); trigger.closeMenu()"
        ></app-custom-select-dropdown>
      </ng-template>
    </mat-menu>

    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      [matMenuTriggerFor]="dropdown"
      #trigger="matMenuTrigger"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add a Filter</span>
    </a>

    <app-field-errors
      *ngIf="!form.controls.element_inputs.controls.length"
      [form]="form"
      [fieldName]="'items'"
    ></app-field-errors>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { LayerInteractionType, LayerType } from '@modules/views';
import { isSet, radToDeg, square } from '@shared';
import { registerLayerComponent } from '../../../data/layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';
export function getLineOrientation(from, to) {
    return {
        xInverse: from.x > to.x,
        yInverse: from.y > to.y
    };
}
var LineLayerComponent = /** @class */ (function (_super) {
    __extends(LineLayerComponent, _super);
    function LineLayerComponent(editorContext, contextElement, sanitizer, cd) {
        var _this = _super.call(this, editorContext) || this;
        _this.contextElement = contextElement;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.fromX = 0;
        _this.fromY = 0;
        _this.length = 0;
        _this.angle = 0;
        _this.xInverse = false;
        _this.yInverse = false;
        _this.borders = [];
        return _this;
    }
    LineLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.editorContext) {
            this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
            this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
            this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
            this.updating$ = this.getLayerUpdating$(function () { return !_this.editorContext.isCreateTool(); });
            this.editorContext
                .globalLayersChange$()
                .pipe(filter(function (event) { return event.source != ViewEditorCustomizeSource.Layer; }), untilDestroyed(this))
                .subscribe(function () {
                _this.updateLine();
            });
        }
        this.getLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.updateLine();
            _this.updateBorders();
            _this.updateBoxShadows();
            _this.updateLayerContext();
        });
    };
    LineLayerComponent.prototype.ngOnDestroy = function () { };
    LineLayerComponent.prototype.updateLine = function () {
        var frame = this.layer.frame;
        var fromX = this.layer.points[0] ? this.layer.points[0].x : 0;
        var fromY = this.layer.points[0] ? this.layer.points[0].y : 0;
        var toX = this.layer.points[1] ? this.layer.points[1].x : 0;
        var toY = this.layer.points[1] ? this.layer.points[1].y : 0;
        var deltaX = frame ? (fromX - toX) * frame.width : 0;
        var deltaY = frame ? (toY - fromY) * frame.height : 0;
        var _a = getLineOrientation(this.layer.points[0], this.layer.points[1]), xInverse = _a.xInverse, yInverse = _a.yInverse;
        var angle = this.getLineAngle(deltaX, deltaY);
        this.fromX = fromX;
        this.fromY = fromY;
        this.length = frame ? Math.sqrt(square(deltaX) + square(deltaY)) : 0;
        this.transform = angle ? this.sanitizer.bypassSecurityTrustStyle("rotate(" + angle + "deg)") : undefined;
        this.transformOverlay = this.layer.frame.rotation ? "rotate(" + this.layer.frame.rotation + "deg)" : undefined;
        this.xInverse = xInverse;
        this.yInverse = yInverse;
        this.cd.markForCheck();
    };
    LineLayerComponent.prototype.getLineAngle = function (deltaX, deltaY) {
        if (deltaY != 0) {
            var angle = radToDeg(Math.atan(deltaX / deltaY));
            if (deltaX >= 0 && deltaY >= 0) {
                return 90 + angle;
            }
            else if (deltaX >= 0 && deltaY < 0) {
                return 270 + angle;
            }
            else if (deltaX < 0 && deltaY < 0) {
                return 270 + angle;
            }
            else if (deltaX < 0 && deltaY >= 0) {
                return 90 + angle;
            }
        }
        else {
            return deltaX >= 0 ? 180 : 0;
        }
    };
    LineLayerComponent.prototype.updateBorders = function () {
        var _this = this;
        if (this.bordersSubscription) {
            this.bordersSubscription.unsubscribe();
            this.bordersSubscription = undefined;
        }
        var borders$ = this.layer.borders.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var border$ = item.cssBorder$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(border$, enabled$).pipe(map(function (_a) {
                var border = _a[0], enabled = _a[1];
                return {
                    border: isSet(border) ? _this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
                    thickness: item.thickness,
                    enabled: enabled
                };
            }));
        });
        if (!borders$.length) {
            this.borders = [];
            this.cd.markForCheck();
            return;
        }
        this.bordersSubscription = combineLatest(borders$)
            .pipe(untilDestroyed(this))
            .subscribe(function (borders) {
            _this.borders = borders.filter(function (item) { return item.enabled && isSet(item.border); });
            _this.cd.markForCheck();
        });
    };
    LineLayerComponent.prototype.updateBoxShadows = function () {
        var _this = this;
        if (this.boxShadowSubscription) {
            this.boxShadowSubscription.unsubscribe();
            this.boxShadowSubscription = undefined;
        }
        var shadows$ = this.layer.shadows
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var boxShadow$ = item.cssBoxShadow$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(boxShadow$, enabled$).pipe(map(function (_a) {
                var boxShadow = _a[0], enabled = _a[1];
                return {
                    boxShadow: boxShadow,
                    enabled: enabled
                };
            }));
        });
        if (!shadows$.length) {
            this.boxShadow = undefined;
            this.cd.markForCheck();
            return;
        }
        this.boxShadowSubscription = combineLatest(shadows$)
            .pipe(untilDestroyed(this))
            .subscribe(function (shadows) {
            _this.boxShadow = _this.sanitizer.bypassSecurityTrustStyle(shadows
                .filter(function (item) { return item.enabled; })
                .map(function (item) { return item.boxShadow; })
                .join(','));
            _this.cd.markForCheck();
        });
    };
    LineLayerComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: this.layer.id,
                name: this.layer.name,
                icon: this.layer.icon
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: this.layer.name,
                icon: this.layer.icon
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    LineLayerComponent.prototype.onFrameUpdate = function (frame) {
        _super.prototype.onFrameUpdate.call(this, frame);
        this.updateLine();
    };
    LineLayerComponent.prototype.onLayerUpdate = function (frame, points) {
        this.layer.frame.patch(frame);
        if (points) {
            this.layer.applyPoints(points.from, points.to);
        }
        this.updateLine();
        this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Layer);
    };
    return LineLayerComponent;
}(LayerComponent));
export { LineLayerComponent };
registerLayerComponent(LayerType.Line, LineLayerComponent);

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type Popup2Size = 's' | 'sm' | 'm' | 'ml' | 'l' | 'xl' | 'xxl';

@Component({
  selector: 'app-popup2',
  templateUrl: './popup2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2Component implements OnInit {
  @Input() size: Popup2Size = 'ml';
  @Input() side = false;
  @Input() maxWidth: number;

  constructor() {}

  ngOnInit() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appTimeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any, timeline = false): any {
    const now = moment();
    const date = moment(value);

    const today = now.clone().startOf('day');
    const yesterday = now.clone().subtract(1, 'day').startOf('day');
    const dateDay = moment(value).clone().startOf('day');

    if (!date.isValid()) {
      return null;
    }

    if (timeline) {
      if (dateDay.isSame(today)) {
        return `Today at ${date.format('LT')}`;
      } else if (dateDay.isSame(yesterday)) {
        return `Yesterday at ${date.format('LT')}`;
      } else {
        return `${date.format('lll')}`;
      }
    } else {
      const days = now.diff(date, 'days');
      const hours = now.diff(date, 'hours');
      const minutes = now.diff(date, 'minutes');

      if (days >= 14) {
        return date.format('D MMM');
      } else if (days >= 1) {
        return `${days}d ago`;
      } else if (hours >= 1) {
        return `${hours}h ago`;
      } else if (minutes >= 1) {
        return `${minutes}m ago`;
      } else {
        return 'now';
      }
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
import * as i2 from "../input-edit-formula-value/input-edit-formula-value.component.ngfactory";
import * as i3 from "../input-edit-formula-value/input-edit-formula-value.component";
import * as i4 from "../view-context-token-popover-overlay/view-context-token-popover-overlay.component.ngfactory";
import * as i5 from "../view-context-token-popover-overlay/view-context-token-popover-overlay.component";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i8 from "../../../fields/components/auto-field/auto-field.component";
import * as i9 from "@angular/common";
import * as i10 from "@angular/cdk/observers";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "../../../field-components/pipes/apply-param-input/apply-param-input.pipe";
import * as i13 from "../../../field-components/pipes/field-input/field-input.pipe";
import * as i14 from "../../../../shared/pipes/throttle-time/throttle-time.pipe";
import * as i15 from "../../../../shared/pipes/control-value/control-value.pipe";
import * as i16 from "./inline-input-edit.component";
import * as i17 from "../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_InlineInputEditComponent = [];
var RenderType_InlineInputEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InlineInputEditComponent, data: {} });
export { RenderType_InlineInputEditComponent as RenderType_InlineInputEditComponent };
function View_InlineInputEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["cdkOverlayOrigin", ""], ["class", "inline-input-editor"]], null, null, null, null, null)), i0.ɵdid(2, 16384, [["input_origin", 4]], 0, i1.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "input-icon-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-input-edit-formula-value", [], null, [[null, "popoverOpened"], [null, "switchToTextInputs"], [null, "switchToJs"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("popoverOpened" === en)) {
        var pd_0 = (_co.onEditorOpened() !== false);
        ad = (pd_0 && ad);
    } if (("switchToTextInputs" === en)) {
        var pd_1 = (_co.switchToTextInputs() !== false);
        ad = (pd_1 && ad);
    } if (("switchToJs" === en)) {
        var pd_2 = (_co.switchToJs() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_InputEditFormulaValueComponent_0, i2.RenderType_InputEditFormulaValueComponent)), i0.ɵdid(5, 4440064, [[5, 4]], 0, i3.InputEditFormulaValueComponent, [i0.NgZone, i0.ChangeDetectorRef], { control: [0, "control"], context: [1, "context"], contextElement: [2, "contextElement"], contextElementPath: [3, "contextElementPath"], contextElementPaths: [4, "contextElementPaths"], contextValueEnabled: [5, "contextValueEnabled"], focusedInitial: [6, "focusedInitial"], placeholder: [7, "placeholder"], fill: [8, "fill"], fillVertical: [9, "fillVertical"], inline: [10, "inline"], popoverOrigin: [11, "popoverOrigin"] }, { popoverOpened: "popoverOpened", switchToTextInputs: "switchToTextInputs", switchToJs: "switchToJs" }), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "inline-input-editor__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "button-tags"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "button-tag button-tags__item button-tags__item_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToJs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "button-tag__icon icon-console"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["JavaScript"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.control; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.contextElementPath; var currVal_4 = _co.contextElementPaths; var currVal_5 = false; var currVal_6 = _co.focusedInitial; var currVal_7 = _co.placeholder; var currVal_8 = true; var currVal_9 = false; var currVal_10 = true; var currVal_11 = i0.ɵnov(_v, 2); _ck(_v, 5, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_InlineInputEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "input-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error__inner"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jsError; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.jsError; _ck(_v, 2, 0, currVal_1); }); }
function View_InlineInputEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-view-context-token-popover-overlay", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onJsTokenSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ViewContextTokenPopoverOverlayComponent_0, i4.RenderType_ViewContextTokenPopoverOverlayComponent)), i0.ɵdid(2, 770048, [[3, 4]], 0, i5.ViewContextTokenPopoverOverlayComponent, [i6.FocusMonitor, i0.ChangeDetectorRef], { context: [0, "context"], contextElement: [1, "contextElement"], contextElementPath: [2, "contextElementPath"], contextElementPaths: [3, "contextElementPaths"], origin: [4, "origin"], control: [5, "control"], forceOpened: [6, "forceOpened"], hideSections: [7, "hideSections"] }, { selected: "selected" }), i0.ɵpad(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 14, "div", [["cdkOverlayOrigin", ""], ["class", "inline-input-editor"]], null, null, null, null, null)), i0.ɵdid(5, 16384, [["input_origin", 4]], 0, i1.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "app-auto-field", [], null, null, null, i7.View_AutoFieldComponent_0, i7.RenderType_AutoFieldComponent)), i0.ɵdid(7, 770048, [[4, 4], ["js_field", 4]], 0, i8.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(8, 2), i0.ɵpod(9, { language: 0, autogrow: 1, field: 2, field_contrast: 3, wrap: 4, classes: 5 }), i0.ɵpod(10, { name: 0, field: 1, placeholder: 2, params: 3 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineInputEditComponent_6)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "inline-input-editor__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "button-tags"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "div", [["class", "button-tag button-tags__item button-tags__item_fill"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToFormula() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "button-tag__icon icon-console"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "button-tag__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Disable JavaScript"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _co.contextElement; var currVal_2 = _co.contextElementPath; var currVal_3 = _co.contextElementPaths; var currVal_4 = i0.ɵnov(_v, 5); var currVal_5 = _co.control; var currVal_6 = _co.editorActive; var currVal_7 = _ck(_v, 3, 0, "functions"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.itemForm; var currVal_9 = _co.createField(_ck(_v, 10, 0, "js_value", "CodeField", _co.jsPlaceholder, _ck(_v, 9, 0, "javascript", true, true, true, true, _ck(_v, 8, 0, (true ? "input_fill" : ""), "inline-input-editor__code").concat((_co.classes || i0.ɵEMPTY_ARRAY))))); var currVal_10 = false; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.jsError; _ck(_v, 12, 0, currVal_11); }, null); }
function View_InlineInputEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "inline-input__editor"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineInputEditComponent_4)), i0.ɵdid(2, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineInputEditComponent_5)), i0.ɵdid(4, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.itemForm.value["value_type"] || (_co.itemForm.value["value_type"] == _co.inputValueTypes.Formula)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.itemForm.value["value_type"] == _co.inputValueTypes.Js); _ck(_v, 4, 0, currVal_1); }, null); }
function View_InlineInputEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["input_popover", 1]], null, 3, "div", [], null, [[null, "cdkObserveContent"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkObserveContent" === en)) {
        var pd_0 = (_co.onEditorOverlayContentChanged() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.editorHover$.next(true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.editorHover$.next(false) !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.activateEditor() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 1196032, null, 0, i10.CdkObserveContent, [i10.ContentObserver, i0.ElementRef, i0.NgZone], null, { event: "cdkObserveContent" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InlineInputEditComponent_3)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemForm.value["name"]; _ck(_v, 3, 0, currVal_0); }, null); }
function View_InlineInputEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "inline-input__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "span", [["cdkOverlayOrigin", ""], ["class", "inline-input-content"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateEditor() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.contentHover$.next(true) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.contentHover$.next(false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, [["content_trigger", 4]], 0, i1.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 8, "span", [["class", "inline-input-content__main"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(5, 1), i0.ɵpod(6, { leading: 0, trailing: 1 }), i0.ɵppd(7, 3), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(9, 1), i0.ɵpod(10, { context: 0, defaultValue: 1 }), i0.ɵppd(11, 2), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, [["editor_overlay", 2]], null, 3, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("attach" === en)) {
        var pd_0 = (_co.onEditorOpened() !== false);
        ad = (pd_0 && ad);
    } if (("backdropClick" === en)) {
        var pd_1 = (_co.deactivateEditor() !== false);
        ad = (pd_1 && ad);
    } if (("detach" === en)) {
        var pd_2 = (_co.deactivateEditor() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_InlineInputEditComponent_2)), i0.ɵdid(14, 671744, [[2, 4]], 0, i1.CdkConnectedOverlay, [i1.Overlay, i0.TemplateRef, i0.ViewContainerRef, i1.ɵc, [2, i11.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", attach: "attach", detach: "detach" }), i0.ɵpad(15, 1), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 3); var currVal_2 = _co.editorPositions; var currVal_3 = "popover2-backdrop2"; var currVal_4 = _ck(_v, 15, 0, "overlay"); var currVal_5 = (i0.ɵunv(_v, 14, 4, i0.ɵnov(_v, 16).transform(_co.editorOpened$)) || _co.editorActive); var currVal_6 = true; var currVal_7 = true; var currVal_8 = true; _ck(_v, 14, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 12).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 4, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 1), i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 8).transform(i0.ɵunv(_v, 4, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 2), i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 3), _co.itemForm)), 200, _ck(_v, 6, 0, true, true))))))), _ck(_v, 10, 0, _co.context, ""))))); _ck(_v, 4, 0, currVal_0); }); }
function View_InlineInputEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1), i0.ɵpod(3, { leading: 0, trailing: 1 }), i0.ɵppd(4, 3), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(6, 1), i0.ɵpod(7, { context: 0, defaultValue: 1 }), i0.ɵppd(8, 2), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 9).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 1, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 1), i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 5).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 2), i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 3), _co.itemForm)), 200, _ck(_v, 3, 0, true, true))))))), _ck(_v, 7, 0, _co.context, ""))))); _ck(_v, 1, 0, currVal_0); }); }
export function View_InlineInputEditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i12.ApplyParamInputPipe, []), i0.ɵpid(0, i13.FieldInputPipe, []), i0.ɵpid(0, i14.ThrottleTimePipe, []), i0.ɵpid(0, i15.ControlValuePipe, []), i0.ɵqud(671088640, 1, { inputPopover: 0 }), i0.ɵqud(671088640, 2, { editorOverlay: 0 }), i0.ɵqud(671088640, 3, { tokenPopoverOverlayComponent: 0 }), i0.ɵqud(671088640, 4, { jsField: 0 }), i0.ɵqud(671088640, 5, { formulaValueComponent: 0 }), (_l()(), i0.ɵeld(9, 0, null, null, 7, "div", [["class", "inline-input"]], [[2, "inline-input_edit", null]], null, null, null, null)), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InlineInputEditComponent_1)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InlineInputEditComponent_7)), i0.ɵdid(15, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i9.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.editable$)); _ck(_v, 12, 0, currVal_1); var currVal_2 = !i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform(_co.editable$)); _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editorActive && i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.editable$))); _ck(_v, 9, 0, currVal_0); }); }
export function View_InlineInputEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-inline-input-edit", [], null, null, null, View_InlineInputEditComponent_0, RenderType_InlineInputEditComponent)), i0.ɵdid(1, 4440064, null, 0, i16.InlineInputEditComponent, [i6.FocusMonitor, i17.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InlineInputEditComponentNgFactory = i0.ɵccf("app-inline-input-edit", i16.InlineInputEditComponent, View_InlineInputEditComponent_Host_0, { itemForm: "itemForm", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", editable$: "editable$", focusedInitial: "focusedInitial", placeholder: "placeholder", jsPlaceholder: "jsPlaceholder", classes: "classes", analyticsSource: "analyticsSource" }, {}, []);
export { InlineInputEditComponentNgFactory as InlineInputEditComponentNgFactory };

import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { isSet } from '../utils/common/common';

export abstract class InstanceStore<T> {
  protected value$ = new BehaviorSubject<T>(undefined);
  protected fetchingSubscription: Subscription;
  protected fetchingResult: Observable<T>;
  protected fetched = false;

  get$(forceUpdate = false): Observable<T> {
    if (this.fetchingResult) {
      return this.fetchingResult.pipe(switchMap(() => this.value$.asObservable()));
    } else if (!this.fetched || forceUpdate) {
      return this.fetch().pipe(switchMap(() => this.value$.asObservable()));
    }

    return this.value$.asObservable();
  }

  getFirst$(): Observable<T> {
    return this.get$().pipe(first());
  }

  set(value: T) {
    if (this.fetchingSubscription) {
      this.fetchingSubscription.unsubscribe();
      this.fetchingSubscription = undefined;
    }

    this.fetched = isSet(value, true);
    this.fetchingResult = undefined;
    this.value$.next(value);
  }

  fetch(): Observable<T> {
    const subject = new ReplaySubject<T>();

    this.fetchingSubscription = this.fetchData()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.value$.next(result);
          this.fetched = true;
          this.fetchingResult = undefined;

          this.fetchingSubscription.unsubscribe();
          this.fetchingSubscription = undefined;

          subject.next(result);
        },
        error => {
          this.value$.next(undefined);
          this.fetchingResult = undefined;

          this.fetchingSubscription.unsubscribe();
          this.fetchingSubscription = undefined;

          subject.error(error);
        }
      );

    this.fetchingResult = subject.asObservable();

    return this.fetchingResult;
  }

  abstract fetchData(): Observable<T>;
}

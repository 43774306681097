import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SimplePopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent } from '@modules/analytics';
import { DocsDocumentSelector } from '@modules/docs';
import { LightboxComponent } from '@modules/field-components/components/image-field/lightbox/lightbox.component';
import { isSet, nodeListToArray, scrollToElement, splitmax, stripStart, TypedChanges } from '@shared';

import { TaskService } from '../../../services/task/task.service';

enum Rate {
  Poor = 'poor',
  OK = 'ok',
  Excellent = 'excellent'
}

@Component({
  selector: 'app-guide-popup-documentation',
  templateUrl: './guide-popup-documentation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuidePopupDocumentationComponent implements OnDestroy, OnChanges, AfterContentInit {
  @Input() path: string;
  @Input() pageTitle: string;
  @Output() close = new EventEmitter<void>();

  @ViewChild('popup_scroll_element') scrollElement: ElementRef;
  @ViewChild('popup_content_element') contentElement: ElementRef;

  loading = false;
  pageUrl: string;
  filePath: string;
  error: string;
  title: string;
  description: string;
  history: DocsDocumentSelector[] = [];
  content: SafeHtml;
  subscriptions: Subscription[] = [];
  rate: Rate;
  rates = Rate;
  analyticsEvents = AnalyticsEvent;

  constructor(
    private taskService: TaskService,
    private sanitizer: DomSanitizer,
    private popupService: PopupService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<GuidePopupDocumentationComponent>): void {
    if (changes.path) {
      this.openDocumentation();
    }
  }

  openDocumentation() {
    this.history = [];
    this.openPage({ path: this.path });
  }

  ngAfterContentInit(): void {
    const findRef = (el: HTMLElement): { type: string; element: HTMLElement } => {
      if (el.classList && el.classList.contains('ref')) {
        return {
          type: 'ref',
          element: el
        };
      } else if (el.classList && el.classList.contains('img-ref')) {
        return {
          type: 'img-ref',
          element: el
        };
      } else if (el.parentElement) {
        return findRef(el.parentElement);
      }
    };

    fromEvent<MouseEvent>(this.contentElement.nativeElement, 'click').subscribe(e => {
      const ref = e.target instanceof HTMLElement ? findRef(e.target) : undefined;

      if (ref && ref.type == 'ref') {
        const attrs = ref.element.attributes as NamedNodeMap;
        const page = attrs.getNamedItem('data-page');

        if (isSet(page)) {
          this.openPage({ filePath: this.filePath, relativePath: page.value });
          e.preventDefault();
        }
      } else if (ref && ref.type == 'img-ref') {
        const href = ref.element.getAttribute('href');

        this.openLightbox(href, ref.element);

        e.preventDefault();
      }
    });
  }

  openPage(selector: DocsDocumentSelector) {
    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];

    this.title = undefined;
    this.description = undefined;
    this.content = undefined;
    this.rate = undefined;
    this.loading = true;
    this.error = undefined;
    this.pageUrl = undefined;
    this.cd.markForCheck();

    const fetchPageSubscription = this.taskService
      .getDocumentationContent(selector)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          const [url, anchor] = splitmax(result.filePath, '#', 2);

          this.title = result.title;
          this.description = result.description;
          this.content = this.sanitizer.bypassSecurityTrustHtml(result.content);
          this.rate = undefined;
          this.loading = false;
          this.pageUrl = result.url;
          this.filePath = result.filePath;

          this.history = [...this.history, selector];
          this.cd.detectChanges();

          if (isSet(anchor)) {
            const element = this.scrollElement.nativeElement.querySelector(`#${anchor}`);

            if (element) {
              scrollToElement(this.scrollElement.nativeElement, element, 0, -40);
            }
          }

          const fetchLinkLabelsSubscription = this.fetchLinkLabels();
          if (fetchLinkLabelsSubscription) {
            this.subscriptions.push(fetchLinkLabelsSubscription);
          }
        },
        e => {
          this.content = undefined;
          this.rate = undefined;
          this.loading = false;
          this.history = [...this.history, selector];

          if (e instanceof HttpErrorResponse && e.status == 404) {
            this.error = 'Not Found';
          } else {
            this.error = 'Unknown error';
          }

          this.cd.markForCheck();
          console.error(e);
        }
      );

    this.subscriptions.push(fetchPageSubscription);
  }

  openLightbox(url: string, sourceElement?: HTMLElement) {
    this.popupService.push({
      component: LightboxComponent,
      popupComponent: SimplePopupComponent,
      inputs: {
        url: url,
        origin: sourceElement,
        zoom: false
      },
      injector: this.injector
    });
  }

  back() {
    const selector = this.history[this.history.length - 2];
    this.history = this.history.slice(0, -2);
    this.openPage(selector);
  }

  fetchLinkLabels(): Subscription {
    const linkElements = nodeListToArray<HTMLElement>(
      this.contentElement.nativeElement.querySelectorAll('.ref.ref_styled[data-page]')
    ).reduce((acc, item) => {
      const labelElement = item.querySelector('.ref__label');

      if (!labelElement) {
        return acc;
      }

      const linkPage = item.getAttribute('data-page');

      if (!acc[linkPage]) {
        acc[linkPage] = [];
      }

      acc[linkPage].push(labelElement);
      return acc;
    }, {});

    if (!keys(linkElements).length) {
      return;
    }

    return combineLatest(
      toPairs(linkElements).map(([path, elements]) => {
        return this.taskService
          .getDocumentationContent({ filePath: this.filePath, relativePath: path }, { noContent: true })
          .pipe(
            map(content => {
              return { elements: elements, title: content.title };
            })
          );
      })
    )
      .pipe(untilDestroyed(this))
      .subscribe((items: { elements: HTMLElement[]; title: string }[]) => {
        items.forEach(item => {
          if (!item.title) {
            return;
          }

          item.elements.forEach(element => {
            element.innerText = item.title;
          });
        });
      });
  }

  setRate(value: Rate) {
    this.rate = value;
    this.cd.markForCheck();
  }
}

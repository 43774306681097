/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../image-codes/components/qr-code/qr-code.component.ngfactory";
import * as i2 from "../../../image-codes/components/qr-code/qr-code.component";
import * as i3 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./qr-code-element.component";
import * as i6 from "../../../customize/services/customize/customize.service";
var styles_QrCodeElementComponent = [];
var RenderType_QrCodeElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QrCodeElementComponent, data: {} });
export { RenderType_QrCodeElementComponent as RenderType_QrCodeElementComponent };
function View_QrCodeElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-qr-code", [], [[4, "width", "%"], [4, "height", "%"], [2, "qr-code", null], [4, "min-width", "px"], [4, "background-image", null]], null, null, i1.View_QrCodeComponent_0, i1.RenderType_QrCodeComponent)), i0.ɵdid(1, 770048, null, 0, i2.QrCodeComponent, [i0.ElementRef, i0.ChangeDetectorRef], { value: [0, "value"], fillColor: [1, "fillColor"], backgroundColor: [2, "backgroundColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.value; var currVal_6 = _co.element.fillColor; var currVal_7 = _co.element.backgroundColor; _ck(_v, 1, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = 100; var currVal_1 = 100; var currVal_2 = i0.ɵnov(_v, 1).cls; var currVal_3 = i0.ɵnov(_v, 1).minWidth; var currVal_4 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_QrCodeElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_QrCodeElementComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.visible || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeEnabled$))) && i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.value))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_QrCodeElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-qr-code-element", [], null, null, null, View_QrCodeElementComponent_0, RenderType_QrCodeElementComponent)), i0.ɵdid(1, 770048, null, 0, i5.QrCodeElementComponent, [i6.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QrCodeElementComponentNgFactory = i0.ɵccf("app-qr-code-element", i5.QrCodeElementComponent, View_QrCodeElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { QrCodeElementComponentNgFactory as QrCodeElementComponentNgFactory };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { MetaService } from '@modules/meta';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';

import { ProjectRegisterForm } from './project-register.form';

@Component({
  selector: 'app-project-register',
  templateUrl: './project-register.component.html',
  providers: [ProjectRegisterForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectRegisterComponent implements OnInit, OnDestroy {
  loading = false;

  constructor(
    public form: ProjectRegisterForm,
    public activatedRoute: ActivatedRoute,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private metaService: MetaService,
    private currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    const params = {};

    if (snapshot.queryParams['referrer']) {
      params['install_referrer'] = snapshot.queryParams['referrer'];
    }

    if (snapshot.queryParams['project']) {
      params['install_project'] = snapshot.queryParams['project'];
    }

    this.routing.navigate(['/projects'], { queryParams: params });

    // this.metaService.set({ title: 'New Project' });
    //
    // this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
    //   this.form.form.patchValue({ token: params['token'] });
    // });
    //
    // this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
    //   if (!queryParams['referrer']) {
    //     return;
    //   }
    //
    //   let apiBaseUrl: string;
    //   const djangoJetMatches = /(https?:\/\/(?:.+)\/jet_api\/)/g.exec(queryParams['referrer']);
    //   const jetBridgeMatches = /(https?:\/\/(?:.+)\/)api\/register\//g.exec(queryParams['referrer']);
    //
    //   if (djangoJetMatches) {
    //     apiBaseUrl = djangoJetMatches[1];
    //   } else if (jetBridgeMatches) {
    //     apiBaseUrl = `${jetBridgeMatches[1]}api/`;
    //   } else {
    //     return;
    //   }
    //
    //   this.form.form.patchValue({ api_base_url: apiBaseUrl });
    //   this.projectProtocolService.redirectProtocolIfNeeded([apiBaseUrl]);
    // });
  }

  ngOnDestroy(): void {}

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        project => {
          this.currentUserStore.get().subscribe(user => {
            this.analyticsService.sendEvent(AnalyticsEvent.GA.ProjectRegistration, AnalyticsEventAction.Success, '', {
              userEmail: user.email,
              userFirstName: user.firstName,
              userLastName: user.lastName
            });
          });

          this.routing.navigateApp(project.homeLink, undefined, { projectName: project.uniqueName });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../common/text-editor/components/text-editor/text-editor.component.ngfactory";
import * as i3 from "../../../../common/text-editor/components/text-editor/text-editor.component";
import * as i4 from "../../../theme/services/theme/theme.service";
import * as i5 from "./text-element.component";
import * as i6 from "../../../admin-mode/providers/route-admin-mode";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../../../customize-elements/components/auto-element/auto-element.component";
var styles_TextElementComponent = [];
var RenderType_TextElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextElementComponent, data: {} });
export { RenderType_TextElementComponent as RenderType_TextElementComponent };
function View_TextElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ql-container ql-snow ql-text-paragraphs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "ql-editor"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.html$)); _ck(_v, 1, 0, currVal_0); }); }
function View_TextElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "app-text-editor", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TextEditorComponent_0, i2.RenderType_TextEditorComponent)), i0.ɵdid(1, 4964352, null, 0, i3.TextEditorComponent, [i4.ThemeService, i0.Injector, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.ViewContainerRef, i0.ChangeDetectorRef], { value: [0, "value"], editable: [1, "editable"], textParagraphs: [2, "textParagraphs"], toolbarElement: [3, "toolbarElement"], context: [4, "context"], schema: [5, "schema"], analyticsSource: [6, "analyticsSource"] }, { changed: "changed" }), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.quillDelta; var currVal_1 = _co.isEditable; var currVal_2 = true; var currVal_3 = _co.toolbarElement; var currVal_4 = _co.context; var currVal_5 = (i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 2).transform(_co.themeService.isDarkTheme$)) ? "light" : "dark"); var currVal_6 = _co.element.analyticsGenericName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_TextElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "text-block text-block_no-margin-collapse"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TextElementComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TextElementComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.mode$)) == _co.adminModes.App); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.mode$)) == _co.adminModes.Builder); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TextElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TextElementComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.customizeEnabled$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-text-element", [], null, null, null, View_TextElementComponent_0, RenderType_TextElementComponent)), i0.ɵdid(1, 4964352, null, 0, i5.TextElementComponent, [i6.ROUTE_ADMIN_MODE$, i6.ROUTE_ADMIN_MODE, i7.CustomizeService, i8.AutoElementComponent, i4.ThemeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextElementComponentNgFactory = i0.ɵccf("app-text-element", i5.TextElementComponent, View_TextElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { TextElementComponentNgFactory as TextElementComponentNgFactory };

<td class="table__column table__column_left-padding"></td>

<td class="table__column table__column_accent">
  <div class="table__column-inner">
    <span [ngClass]="'icon-' + property.fieldDescription?.icon"></span> {{ property.name }}
  </div>
</td>

<td class="table__column" [ngClass]="[editing ? 'table__column_input' : '']">
  <div class="table__column-inner">
    <ng-container *ngIf="!editing">
      {{ getPropertyValue() }}
    </ng-container>

    <form *ngIf="editing" [formGroup]="form" (ngSubmit)="finishEditing(true)">
      <app-auto-field
        [form]="form"
        [field]="formField"
        [label]="false"
        [autofocus]="true"
        (click)="$event.stopPropagation()"
      >
      </app-auto-field>
    </form>
  </div>
</td>

<td class="table__column table__column_right">
  <button *ngIf="!editing" type="button" class="button button_icon" (click)="startEditing()">
    <span class="icon icon-pen button__icon"></span>
  </button>

  <button *ngIf="!editing" type="button" class="button button_icon button_transparent" (click)="clearPropertyValue()">
    <span class="icon icon-bin button__icon"></span>
  </button>

  <button *ngIf="editing" type="button" class="button button_primary button_icon" (click)="finishEditing(true)">
    <span class="icon icon-check_2 button__icon"></span>
  </button>

  <button *ngIf="editing" type="button" class="button button_transparent button_icon" (click)="finishEditing(false)">
    <span class="icon icon-close button__icon"></span>
  </button>
</td>

export function getFramePointPosition(frame, point) {
    return {
        x: frame.x + frame.width * point.x,
        y: frame.y + frame.height * point.y
    };
}
var Frame = /** @class */ (function () {
    function Frame(options) {
        if (options === void 0) { options = {}; }
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.rotation = 0;
        this.patch(options);
    }
    Frame.prototype.deserialize = function (data) {
        this.x = data['x'];
        this.y = data['y'];
        this.width = data['width'];
        this.height = data['height'];
        this.rotation = data['rotation'];
        return this;
    };
    Frame.prototype.serialize = function () {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            rotation: this.rotation
        };
    };
    Frame.prototype.getPosition = function () {
        return {
            x: this.x,
            y: this.y
        };
    };
    Frame.prototype.getSize = function () {
        return {
            width: this.width,
            height: this.height
        };
    };
    Frame.prototype.patch = function (options) {
        for (var _i = 0, _a = Object.keys(options); _i < _a.length; _i++) {
            var key = _a[_i];
            if (this.hasOwnProperty(key)) {
                this[key] = options[key];
            }
        }
        return this;
    };
    Frame.prototype.diff = function (frame) {
        var result = {};
        if (this.x != frame.x) {
            result.x = this.x;
        }
        if (this.y != frame.y) {
            result.y = this.y;
        }
        if (this.width != frame.width) {
            result.width = this.width;
        }
        if (this.height != frame.height) {
            result.height = this.height;
        }
        return result;
    };
    Object.defineProperty(Frame.prototype, "top", {
        get: function () {
            return this.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "right", {
        get: function () {
            return this.x + this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "bottom", {
        get: function () {
            return this.y + this.height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Frame.prototype, "left", {
        get: function () {
            return this.x;
        },
        enumerable: true,
        configurable: true
    });
    return Frame;
}());
export { Frame };

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl } from '@angular/forms';
import range from 'lodash/range';
import { debounceTime, map } from 'rxjs/operators';

import { ActionItem } from '@modules/actions';
import { ElementConfigurationService } from '@modules/customize-configuration';

@Injectable()
export class ActionsEditForm {
  control: AbstractControl;

  form = new FormArray([]);

  constructor(private elementConfigurationService: ElementConfigurationService) {}

  init(control: AbstractControl) {
    this.control = control;

    this.updateValueFromControl();

    this.form.valueChanges.pipe(debounceTime(60)).subscribe(value => this.updateValueToControl(value));

    this.form.markAsDirty();
  }

  serializeValue(value: ActionItem[]): ActionItem[] {
    return value.map(item => {
      return item;
    });
  }

  deserializeValue(value: ActionItem[]): ActionItem[] {
    return value.map(item => {
      return item;
    });
  }

  updateValueFromControl() {
    const controlValue = this.control.value as ActionItem[];
    const createItems = this.deserializeValue(controlValue);
    this.arraySet(createItems.map(item => this.createItem(item)));
  }

  updateValueToControl(value: any[]) {
    this.control.patchValue(this.serializeValue(value));
    this.control.markAsDirty();
  }

  createItem(value?: ActionItem): FormControl {
    const form = new FormControl(new ActionItem(), undefined, this.validateAction());

    if (value) {
      form.patchValue(value);
    }

    return form;
  }

  arraySet(controls) {
    range(this.form.controls.length).forEach(() => this.form.removeAt(0));
    controls.forEach(item => this.form.push(item));
    this.form.updateValueAndValidity();
  }

  arrayAppend(control) {
    this.form.push(control);
    this.form.updateValueAndValidity();
  }

  arrayRemove(control) {
    const index = this.form.controls.findIndex(item => item === control);
    this.form.removeAt(index);
    this.form.updateValueAndValidity();
  }

  validateAction(): AsyncValidatorFn {
    return control => {
      return this.elementConfigurationService.isActionConfigured(control.value).pipe(
        map(configured => {
          if (!configured) {
            return { required: true };
          }
        })
      );
    };
  }
}

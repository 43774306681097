import { OnInit } from '@angular/core';
import { Environment, Project } from '@modules/projects';
var InstallInstructionsSqlDockerComponent = /** @class */ (function () {
    function InstallInstructionsSqlDockerComponent() {
    }
    InstallInstructionsSqlDockerComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(InstallInstructionsSqlDockerComponent.prototype, "environmentName", {
        get: function () {
            if (!this.environment) {
                return;
            }
            if (this.project.environments.length <= 1) {
                return;
            }
            return this.environment.uniqueName;
        },
        enumerable: true,
        configurable: true
    });
    return InstallInstructionsSqlDockerComponent;
}());
export { InstallInstructionsSqlDockerComponent };

<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Enter your credentials to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <div *ngIf="accountLoading" class="field">
          <div class="field__label">Hubspot Account</div>
          <div class="field__value">
            <div class="input input_fill">
              <span [class.loading-animation]="true"><span class="stub-text">user@example.com</span></span>
            </div>
          </div>
        </div>
        <div *ngIf="!accountLoading" class="field">
          <div *ngIf="account" class="field__label">
            <span class="field__label-text">Hubspot Account</span>
            <span class="field__label-right">
              <a href="javascript:void(0)" class="field__label-link" (click)="getOAuthToken()">
                <span class="field__label-link-icon icon-pen"></span>
                Change Account
              </a>
            </span>
          </div>
          <div *ngIf="account" class="field__value">
            <div class="input input_fill">{{ account }}</div>
          </div>
          <div *ngIf="!account" class="field__value">
            <button type="button" class="button button_primary button_fill" (click)="getOAuthToken()">
              <span class="button__label">Sign In to Hubspot</span>
            </button>
          </div>
          <div class="field__description">
            Hubspot is a secure partner with Jet Admin. Your credentials are stored on Hubspot side.
          </div>
        </div>
      </app-page-block>

      <app-page-block>
        <a [appDocumentation]="'integrations/hubspot'" class="button button_small" style="margin: 0;">
          Read documentation
        </a>
      </app-page-block>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="resource ? submit() : chooseSync()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>

import pickBy from 'lodash/pickBy';
import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ListModelDescriptionQuery } from '@modules/queries';
import { ViewMapping } from '@modules/views';
import { isSet } from '@shared';
import { migrateModelDescriptionDataSource } from '../utils/migration';
import { KanbanBoardStage } from './kanban-board-stage';
var KanbanBoardSettings = /** @class */ (function () {
    function KanbanBoardSettings() {
        this.type = ListLayoutType.KanbanBoard;
        this.header = true;
        this.cardCustomViewMappings = [];
        this.actions = [];
        this.modelActions = [];
        this.columnActions = [];
        this.customActions = [];
        this.searchEnabled = true;
        this.searchLive = true;
        this.sortingAsc = true;
        this.displayFilters = true;
        this.filterFields = [];
        this.stages = [];
        this.commentsEnabled = false;
        this.perPage = 10;
        this.multipleSelection = false;
    }
    KanbanBoardSettings.prototype.deserialize = function (data) {
        this.cardCustomView = data['card_custom_view'];
        this.searchEnabled = data['search_enabled'];
        this.sortingField = data['sorting_field'];
        this.stageField = data['stage_field'];
        this.commentsEnabled = data['comments_enabled'];
        this.defaultSelection = data['default_selection'];
        this.tooltip = data['tooltip'];
        this.gapHorizontal = data['gap_horizontal'];
        this.gapVertical = data['gap_vertical'];
        if (data['title_input']) {
            this.titleInput = new Input().deserialize(data['title_input']);
        }
        else if (data['title']) {
            // Backward compatibility
            this.titleInput = new Input().deserializeFromStatic('value', data['title']);
        }
        if (data['data_source']) {
            this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
        }
        else if (data['resource']) {
            // Backward compatibility
            this.dataSource = migrateModelDescriptionDataSource(ListModelDescriptionDataSource, ListModelDescriptionQuery, {
                resource: data['resource'],
                query: data['get_query'],
                parameters: data['parameters'],
                inputs: data['inputs'],
                columns: data['columns']
            });
        }
        if (data['card_custom_view_mappings']) {
            this.cardCustomViewMappings = data['card_custom_view_mappings'].map(function (item) { return new ViewMapping().deserialize(item); });
        }
        if (data['display_filters'] !== undefined) {
            this.displayFilters = data['display_filters'];
        }
        if (data['filter_fields']) {
            this.filterFields = data['filter_fields'].map(function (item) {
                return {
                    name: item['name']
                };
            });
        }
        if (data['header'] != undefined) {
            this.header = data['header'];
        }
        if (data['search_live'] !== undefined) {
            this.searchLive = data['search_live'];
        }
        if (data['card_click_action']) {
            this.cardClickAction = new ActionItem().deserialize(data['card_click_action']);
        }
        if (data['card_column_change_action']) {
            this.cardColumnChangeAction = new ActionItem().deserialize(data['card_column_change_action']);
        }
        if (data['card_order_change_action']) {
            this.cardOrderChangeAction = new ActionItem().deserialize(data['card_order_change_action']);
        }
        if (data['stages']) {
            this.stages = data['stages'].map(function (item) { return new KanbanBoardStage().deserialize(item); });
        }
        if (data['actions']) {
            this.actions = data['actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (data['model_actions']) {
            this.modelActions = data['model_actions'].map(function (item) { return new ViewSettingsAction().deserialize(item); });
        }
        if (data['column_actions']) {
            this.columnActions = data['column_actions']
                .filter(function (item) { return item['actions']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: item['actions'].map(function (subItem) { return new ViewSettingsAction().deserialize(subItem); })
                };
            });
        }
        else if (data['data_source'] && data['data_source']['columns']) {
            // Backward compatibility
            this.columnActions = data['data_source']['columns']
                .filter(function (item) { return item['action']; })
                .map(function (item) {
                return {
                    name: item['name'],
                    actions: [new ViewSettingsAction().deserialize(item['action'])]
                };
            });
        }
        if (data['custom_actions']) {
            this.customActions = data['custom_actions'].map(function (item) { return ({
                name: item['name'],
                action: new ActionItem().deserialize(item['action'])
            }); });
        }
        if (data['sorting_asc'] != undefined) {
            this.sortingAsc = data['sorting_asc'];
        }
        if (data['comments_object_type']) {
            this.commentsObjectType = new Input().deserialize(data['comments_object_type']);
        }
        if (data['comments_object_id']) {
            this.commentsObjectId = new Input().deserialize(data['comments_object_id']);
        }
        if (data['per_page'] != undefined) {
            this.perPage = data['per_page'];
        }
        if (isSet(data['multiple_selection'])) {
            this.multipleSelection = data['multiple_selection'];
        }
        return this;
    };
    KanbanBoardSettings.prototype.serialize = function (fields) {
        var data = {
            type: this.type,
            header: this.header,
            title_input: this.titleInput ? this.titleInput.serialize() : null,
            data_source: this.dataSource ? this.dataSource.serialize() : undefined,
            card_custom_view: this.cardCustomView,
            card_custom_view_mappings: this.cardCustomViewMappings.map(function (item) { return item.serialize(); }),
            search_enabled: this.searchEnabled,
            search_live: this.searchLive,
            card_click_action: this.cardClickAction ? this.cardClickAction.serialize() : undefined,
            card_column_change_action: this.cardColumnChangeAction ? this.cardColumnChangeAction.serialize() : undefined,
            card_order_change_action: this.cardOrderChangeAction ? this.cardOrderChangeAction.serialize() : undefined,
            custom_actions: this.customActions.map(function (item) { return ({ name: item.name, action: item.action.serialize() }); }),
            stage_field: this.stageField,
            stages: this.stages.map(function (item) { return item.serialize(); }),
            actions: this.actions.map(function (item) { return item.serialize(); }),
            model_actions: this.modelActions.map(function (item) { return item.serialize(); }),
            column_actions: this.columnActions.map(function (item) {
                return {
                    name: item.name,
                    actions: item.actions.map(function (action) { return action.serialize(); })
                };
            }),
            sorting_field: this.sortingField,
            sorting_asc: this.sortingAsc,
            per_page: this.perPage,
            default_selection: this.defaultSelection,
            comments_enabled: this.commentsEnabled,
            comments_object_type: this.commentsObjectType ? this.commentsObjectType.serialize() : undefined,
            comments_object_id: this.commentsObjectId ? this.commentsObjectId.serialize() : undefined,
            display_filters: this.displayFilters,
            filter_fields: this.filterFields.map(function (item) {
                return {
                    name: item.name
                };
            }),
            multiple_selection: this.multipleSelection,
            tooltip: this.tooltip,
            gap_horizontal: this.gapHorizontal,
            gap_vertical: this.gapVertical
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    KanbanBoardSettings.prototype.isConfigured = function () {
        return (isSet(this.stageField) &&
            isSet(this.stages) &&
            this.stages.length > 0 &&
            this.stages.every(function (item) { return isSet(item.name); }));
    };
    Object.defineProperty(KanbanBoardSettings.prototype, "analyticsName", {
        get: function () {
            return 'kanban';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KanbanBoardSettings.prototype, "analyticsGenericName", {
        get: function () {
            if (!this.analyticsName) {
                return;
            }
            return ['component', this.analyticsName].join('_');
        },
        enumerable: true,
        configurable: true
    });
    return KanbanBoardSettings;
}());
export { KanbanBoardSettings };

import { getJetAppStorage } from '@modules/storages';

import { Resource } from './resource';
import { ResourceName } from './resource-name';
import { ResourceType } from './resource-type';
import { resourceTypeItems } from './resource-type-items';

export const JET_APP_RESOURCE = '__jet_app__';
export const jetAppResource = new Resource();
export const jetAppStorage = getJetAppStorage();

jetAppResource.uniqueName = JET_APP_RESOURCE;
jetAppResource.name = 'App built-ins';
jetAppResource.type = ResourceType.JetApp;
jetAppResource.typeItem = resourceTypeItems.find(item => item.name == ResourceName.JetApp);
jetAppResource.storages = [jetAppStorage];

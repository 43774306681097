import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as QRCode from 'qrcode';

import { getColorHexStr } from '@modules/colors';
import { elementResize$, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value = '';
  @Input() fillColor: string;
  @Input() backgroundColor: string;

  @HostBinding('class.qr-code') cls = true;
  @HostBinding('style.min-width.px') get minWidth() {
    return isSet(this.error) ? 120 : null;
  }
  @HostBinding('style.background-image') get backgroundImage() {
    return `url(${this.imageUrl})`;
  }

  imageUrl: string;
  error: string;

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    elementResize$(this.el.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.render());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<QrCodeComponent>): void {
    if ([changes.value, changes.fillColor, changes.backgroundColor].some(item => item && !item.firstChange)) {
      this.render();
    }
  }

  render() {
    const boundsWidth = this.el.nativeElement.offsetWidth;
    const boundsHeight = this.el.nativeElement.offsetHeight;
    const size = Math.min(boundsWidth, boundsHeight) * window.devicePixelRatio;
    const fillColor = isSet(this.fillColor) ? getColorHexStr(this.fillColor) : undefined;
    const backgroundColor = isSet(this.backgroundColor) ? getColorHexStr(this.backgroundColor) : undefined;

    QRCode.toDataURL(
      this.value,
      {
        width: size,
        margin: 0,
        color: {
          ...(fillColor && { dark: fillColor }),
          light: backgroundColor ? backgroundColor : '#ffffff00'
        }
      },
      (err, url) => {
        if (isSet(err)) {
          console.error(err);

          this.imageUrl = undefined;
          this.error = 'Invalid value';
          this.cd.markForCheck();
        } else {
          this.imageUrl = url;
          this.error = undefined;
          this.cd.markForCheck();
        }
      }
    );
  }
}

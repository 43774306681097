import { BaseField, FieldType } from '@modules/fields';

export function getJetDatabaseType(field: BaseField): { dbColumn: string } {
  if (field.field == FieldType.Text) {
    if (field.params && field.params['multiline']) {
      return { dbColumn: 'TextField' };
    } else {
      return { dbColumn: 'CharField' };
    }
  } else if (field.field == FieldType.RichText) {
    return { dbColumn: 'TextField' };
  } else if (field.field == FieldType.Boolean) {
    return { dbColumn: 'BooleanField' };
  } else if (field.field == FieldType.File) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Image) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.RelatedModel) {
    return { dbColumn: null };
  } else if (field.field == FieldType.Select) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.MultipleSelect) {
    return { dbColumn: 'JSONField' };
  } else if (field.field == FieldType.RadioButton) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.DateTime) {
    if (field.params && field.params['date'] === true && field.params['time'] === false) {
      return { dbColumn: 'DateField' };
    } else {
      return { dbColumn: 'TimestampField' };
    }
  } else if (field.field == FieldType.Time) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.URL) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Password) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Number) {
    return { dbColumn: 'FloatField' };
  } else if (field.field == FieldType.Slider) {
    return { dbColumn: 'FloatField' };
  } else if (field.field == FieldType.Rating) {
    if (field.params && field.params['allow_half']) {
      return { dbColumn: 'FloatField' };
    } else {
      return { dbColumn: 'IntegerField' };
    }
  } else if (field.field == FieldType.Location) {
    return { dbColumn: 'GeographyField' };
  } else if (field.field == FieldType.Video) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Audio) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Signature) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.Color) {
    return { dbColumn: 'CharField' };
  } else if (field.field == FieldType.JSON) {
    return { dbColumn: 'JSONField' };
  }
}

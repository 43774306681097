import { FormArray } from '@angular/forms';
import range from 'lodash/range';

import { RawListViewSettingsColumn } from '@modules/customize';

import { PageTemplatesItemFieldControl } from './page-templates-item-field.control';

export class PageTemplatesItemFieldArray extends FormArray {
  controls: PageTemplatesItemFieldControl[];

  deserialize(instances: RawListViewSettingsColumn[]) {
    const controls = instances.map(item => new PageTemplatesItemFieldControl().deserialize(item));
    this.set(controls);
  }

  serialize(): RawListViewSettingsColumn[] {
    return this.controls.map(item => item.serialize());
  }

  getVisibleNames() {
    return this.controls.filter(control => control.controls.enabled.value).map(control => control.instance.name);
  }

  set(controls: PageTemplatesItemFieldControl[]) {
    range(this.controls.length).forEach(() => this.removeAt(0));
    controls.forEach(item => this.push(item));
    this.updateValueAndValidity();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-transparent-dialog-popup',
  templateUrl: './transparent-dialog-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransparentDialogPopupComponent extends DialogPopupComponent {
  transparent = true;
  wide = false;
}

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export enum FileViewerType {
  Native = 'native',
  Google = 'google'
}

export class FileViewerElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.FileViewer;
  public url: Input;
  public width: number;
  public height: number;
  public border = false;
  public viewerType: FileViewerType = FileViewerType.Native;
  public toolbar = false;

  deserialize(data: Object): FileViewerElementItem {
    super.deserialize(data);
    this.url = this.params['url'];
    this.width = this.params['width'];
    this.height = this.params['height'];
    this.border = this.params['border'];
    this.toolbar = this.params['toolbar'];

    if (this.params['url']) {
      // Backward compatibility
      if (typeof this.params['url'] == 'string') {
        this.url = new Input().deserializeFromStatic('value', this.params['url']);
      } else {
        this.url = new Input().deserialize(this.params['url']);
      }
    }

    if (this.params['viewer_type']) {
      this.viewerType = this.params['viewer_type'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['url'] = this.url ? this.url.serialize() : null;
    this.params['width'] = this.width;
    this.params['height'] = this.height;
    this.params['border'] = this.border;
    this.params['viewer_type'] = this.viewerType;
    this.params['toolbar'] = this.toolbar;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'embed file';
  }

  get analyticsName(): string {
    return 'file_viewer';
  }

  defaultName() {
    return 'File Viewer';
  }
}

registerElementForType(ElementType.FileViewer, FileViewerElementItem);

<div class="marketplace-card">
  <div class="marketplace-card__image">
    <div class="marketplace-card__image-inner marketplace-card__image-inner_background"></div>
  </div>

  <div class="marketplace-card__content">
    <div class="marketplace-card__info">
      <div class="marketplace-card__title">
        <span [class.loading-animation]="true"><span class="stub-text">name</span></span>
      </div>
      <div class="marketplace-card__subtitle">
        <span [class.loading-animation]="true"><span class="stub-text">subtitle</span></span>
      </div>
    </div>
  </div>
</div>

<div
  class="compact-input"
  [class.compact-input_controls]="options.length || (number && numberControls)"
  [class.compact-input_focus]="focus.focused$ | async"
  [class.compact-input_segment-left]="segmentLeft"
  [class.compact-input_segment]="segment"
  [class.compact-input_segment-right]="segmentRight"
  [class.compact-input_interactive]="!valueControl.disabled && !(optionValue && optionsDisabled)"
  [class.compact-input_disabled]="valueControl.disabled || (optionValue && optionsDisabled)"
  [style.width.px]="widthPx"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="input_element.focus()"
>
  <div *ngIf="prefix | appIsSet" class="compact-input__prefix">
    {{ prefix }}
  </div>

  <input
    type="text"
    class="compact-input__control"
    [class.compact-input__control_disabled]="optionValue && optionsDisabled"
    [class.compact-input__control_hidden]="currentOption"
    [formControl]="valueControl"
    [placeholder]="placeholder"
    (keyup)="onControlKey($event)"
    (input)="onControlInput()"
    (change)="onControlChange()"
    appFocusMonitor
    #input_element
    #focus="appFocusMonitor"
  />

  <div *ngIf="currentOption" class="compact-input__value" [class.compact-input__value_disabled]="valueControl.disabled">
    {{ (currentOption.nameShort | appIsSet) ? currentOption.nameShort : currentOption.name }}
  </div>

  <div *ngIf="postfix | appIsSet" class="compact-input__postfix">
    {{ postfix }}
  </div>

  <div *ngIf="options.length" class="compact-input__vertical-controls">
    <div
      class="compact-input__vertical-controls-item icon-arrow_down_2"
      [class.compact-input__vertical-controls-item_disabled]="formControl.disabled || optionsDisabled"
      (click)="setDropdownOpened(true)"
    ></div>
  </div>

  <div *ngIf="!options.length && number && numberControls" class="compact-input__vertical-controls">
    <div
      class="compact-input__vertical-controls-item icon-arrow_up_2"
      [class.compact-input__vertical-controls-item_disabled]="formControl.disabled"
      (click)="increment($event)"
    ></div>

    <div
      class="compact-input__vertical-controls-item icon-arrow_down_2"
      [class.compact-input__vertical-controls-item_disabled]="formControl.disabled"
      (click)="decrement($event)"
    ></div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="compact-select-options">
    <div
      *ngFor="let item of options"
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="item === currentOption"
      [class.compact-select-option_disabled]="item.disabled"
      (click)="selectOption.emit(item.value); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="item === currentOption"
        ></div>

        <div
          *ngIf="item.icon | appIsSet"
          class="compact-select-option__icon compact-select-option__item"
          [ngClass]="'icon-' + item.icon"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div
            class="compact-select-option__title"
            [style]="(item.style | appIsSet) ? sanitizer.bypassSecurityTrustStyle(item.style) : null"
          >
            {{ item.name }}
          </div>

          <div *ngIf="item.nameAdditional | appIsSet" class="compact-select-option__title-additional">
            {{ item.nameAdditional }}
          </div>
        </div>

        <div *ngIf="item.subtitle | appIsSet" class="compact-select-option__row">
          <div class="compact-select-option__description">{{ item.subtitle }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const ROUTE_ENVIRONMENT_UNIQUE_NAME = new InjectionToken<string>('ROUTE_ENVIRONMENT_UNIQUE_NAME');

export function routeProjectUniqueNameFactory(activatedRoute: ActivatedRoute): Observable<string> {
  return activatedRoute.params.pipe(map(params => params['environment']));
}

export const ROUTE_ENVIRONMENT_UNIQUE_NAME_PROVIDER: Provider = {
  provide: ROUTE_ENVIRONMENT_UNIQUE_NAME,
  deps: [ActivatedRoute],
  useFactory: routeProjectUniqueNameFactory
};

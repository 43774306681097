import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MouseButton } from '@shared';

import { StickPosition } from '../columns-layout.component';

@Component({
  selector: 'app-columns-layout-handle',
  templateUrl: './columns-layout-handle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsLayoutHandleComponent implements OnInit {
  @Input() active = false;
  @Input() stick: StickPosition;
  @Output() dragStarted = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}

  onMouseDown(e: MouseEvent) {
    if (e.button == MouseButton.Main) {
      event.preventDefault();
      event.stopPropagation();

      this.dragStarted.emit(e);
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { controlValue, isSet, KeyboardEventKeyCode } from '@shared';
var Side;
(function (Side) {
    Side["Top"] = "top";
    Side["Right"] = "right";
    Side["Bottom"] = "bottom";
    Side["Left"] = "left";
})(Side || (Side = {}));
var NumberControl = /** @class */ (function (_super) {
    __extends(NumberControl, _super);
    function NumberControl(formState, validatorOrOpts, asyncValidator) {
        var _this = _super.call(this, formState, validatorOrOpts, asyncValidator) || this;
        controlValue(_this).subscribe(function (rawValue) {
            var cleanValue = _this.cleanValue(rawValue);
            if (cleanValue !== rawValue) {
                _this.setValue(cleanValue);
            }
        });
        return _this;
    }
    NumberControl.prototype.cleanValue = function (value) {
        if (value === undefined || value === null) {
            return value;
        }
        if (value === '') {
            return undefined;
        }
        value = parseInt(value, 10);
        if (isNaN(value)) {
            value = 0;
        }
        else if (typeof value !== 'number') {
            value = undefined;
        }
        else if (value < 0) {
            value = 0;
        }
        return value;
    };
    return NumberControl;
}(FormControl));
export { NumberControl };
var MarginControl = /** @class */ (function (_super) {
    __extends(MarginControl, _super);
    function MarginControl(value) {
        if (value === void 0) { value = {}; }
        return _super.call(this, {
            top: new NumberControl(value.top),
            right: new NumberControl(value.right),
            bottom: new NumberControl(value.bottom),
            left: new NumberControl(value.left)
        }) || this;
    }
    return MarginControl;
}(FormGroup));
export { MarginControl };
var MarginControlComponent = /** @class */ (function () {
    function MarginControlComponent() {
        this.sides = Side;
    }
    MarginControlComponent.prototype.ngOnInit = function () { };
    MarginControlComponent.prototype.setFocused = function (focused) {
        this.focused = focused;
    };
    MarginControlComponent.prototype.processArrows = function (event, control) {
        var charCode = event.which ? event.which : event.keyCode;
        var delta = event.shiftKey ? 10 : 1;
        if (charCode == KeyboardEventKeyCode.ArrowUp) {
            var cleanValue = control.cleanValue(control.value);
            control.setValue(isSet(cleanValue) ? cleanValue + delta : delta);
            event.preventDefault();
            return false;
        }
        else if (charCode == KeyboardEventKeyCode.ArrowDown) {
            var cleanValue = control.cleanValue(control.value);
            control.setValue(isSet(cleanValue) ? cleanValue - delta : 0);
            return false;
        }
    };
    MarginControlComponent.prototype.validateKey = function (event) {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode >= KeyboardEventKeyCode.Number0 && charCode <= KeyboardEventKeyCode.Number9) {
            return true;
        }
        else {
            return false;
        }
    };
    MarginControlComponent.prototype.selectValue = function (event) {
        event.target.select();
    };
    return MarginControlComponent;
}());
export { MarginControlComponent };

import { Type } from '@angular/core';

import { WorkflowStepType } from '@modules/workflow';

import { CustomizeWorkflowStepComponent } from '../components/workflow/customize-steps/base-customize-workflow-step/base-customize-workflow-step.component';

const customizeWorkflowStepsByType: { type: WorkflowStepType; cls: Type<CustomizeWorkflowStepComponent> }[] = [];

export function getCustomizeWorkflowStepComponent(type: WorkflowStepType): Type<CustomizeWorkflowStepComponent> {
  const instance = customizeWorkflowStepsByType.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerCustomizeWorkflowStepComponent(
  type: WorkflowStepType,
  cls: Type<CustomizeWorkflowStepComponent>
) {
  customizeWorkflowStepsByType.push({ type: type, cls: cls });
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { FormUtils } from '@common/form-utils';
import { AppConfigService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { detectFieldByValue, FieldType, OutputFormat, TimeOutputFormat } from '@modules/fields';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ProjectSettingsService } from '@modules/project-settings';
import { CryptService, ProjectTokenService, SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpContentType, HttpMethod, HttpQuery, ListModelDescriptionQuery, ModelDescriptionQuery, QueryPagination, QueryService, QueryType } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import { AppError, generateUUID, isSet } from '@shared';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var primaryKey = '__jet_pk__';
var readOnlyFields = [primaryKey];
var responseTransformerMapItem = "\nfunction mapItem(item, pk, fields) {\n  const model = {\n    " + primaryKey + ": pk\n  };\n  fields.forEach((name, i) => {\n    if (item[i] !== undefined) {\n      model[name] = item[i];\n    } else {\n      model[name] = '';\n    }\n  });\n  return model;\n}";
var responseTransformerGetItems = "\nfunction isSet(value) {\n  return !(value === null || value === undefined || value === '');\n}\nfunction getItems(valueRanges) {\n  const r = /^[^!]+!+[A-z]+(\\d+):[A-z]+(\\d+)$/.exec(valueRanges[1]['range']);\n  const startFrom = r ? parseInt(r[1]) : 1;\n  const values = valueRanges[0]['values'].concat(valueRanges[1]['values'] || [[]]);\n  const fields = values[0].map((item, i) => isSet(item) ? item : 'field_' + (i + 1));\n  return {\n    fields: fields,\n    items: values.slice(1).map((item, i) => mapItem(item, startFrom + i - 1, fields))\n  };\n}";
var maxRows = 99999;
var listResponseTransformer = responseTransformerMapItem + "\n" + responseTransformerGetItems + "\nvar result = getItems(data['valueRanges']);\nreturn result.items;\n";
var detailResponseTransformer = responseTransformerGetItems + "\n" + responseTransformerMapItem + "\nvar result = getItems(data['valueRanges']);\nreturn result.items[0];";
var createResponseTransformer = function (fieldNames) { return "\nconst range = data['updates']['updatedData']['range'].split('!', 2);\nconst coords = range[1].split(':', 2);\nconst pk = parseInt(coords[0].substring(1)) - 1;\nconst fieldNames = JSON.parse(" + JSON.stringify(JSON.stringify(fieldNames.filter(function (item) { return item != primaryKey; }))) + ");\nconst values = data['updates']['updatedData']['values'][0];\nconst result = {\n  " + primaryKey + ": pk\n};\n\nvalues.forEach((item, i) => result[fieldNames[i]] = item);\n\nreturn result;\n"; };
var ɵ0 = createResponseTransformer;
var updateResponseTransformer = function (fieldNames) { return "\nconst range = data['updatedData']['range'].split('!', 2);\nconst coords = range[1].split(':', 2);\nconst pk = parseInt(coords[0].substring(1)) - 1;\nconst fieldNames = JSON.parse(" + JSON.stringify(JSON.stringify(fieldNames.filter(function (item) { return item != primaryKey; }))) + ");\nconst values = data['updatedData']['values'][0];\nconst result = {\n  " + primaryKey + ": pk\n};\n\nvalues.forEach((item, i) => result[fieldNames[i]] = item);\n\nreturn result;\n"; };
var ɵ1 = updateResponseTransformer;
var bodyTransformer = function (fields) {
    var fieldNames = fields.filter(function (item) { return item.name != primaryKey; }).map(function (item) { return item.name; });
    return "\nconst fieldNames = JSON.parse(" + JSON.stringify(JSON.stringify(fieldNames)) + ");\nreturn {\n  values: [fieldNames.map(field => {\n    if (data[field] === undefined) {\n        return null;\n    } else if (data[field] === null) {\n        return 'NULL';\n    } else {\n        return data[field];\n    }\n  })]\n};\n";
};
var ɵ2 = bodyTransformer;
var errorTransformer = "// add custom transformation here\nif (http.code >= 200 && http.code < 400) {\n  // no error if success code\n  return null;\n} else if (http.code == 400 && data && data['error'] && data['error']['message'] && data['error']['message'].includes('exceeds grid limits')) {\n  return null;\n} else if (data['error'] && data['error']['message']) {\n  // display error message if any\n  return data['error']['message'];\n} else {\n  // display error without message otherwise\n  return true;\n}";
export var googleDriveFileIdParam = 'google_drive_file_id';
export var googleDriveFileUidParam = 'google_drive_file_uid';
var GoogleSheetsGeneratorService = /** @class */ (function (_super) {
    __extends(GoogleSheetsGeneratorService, _super);
    function GoogleSheetsGeneratorService(mode, cryptService, appConfigService, menuGeneratorService, apiService, injector, http, modelDescriptionStore, queryService, queryTokensService, secretTokenService, formUtils, projectSettingsService, projectTokenService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.cryptService = cryptService;
        _this.appConfigService = appConfigService;
        _this.menuGeneratorService = menuGeneratorService;
        _this.apiService = apiService;
        _this.injector = injector;
        _this.http = http;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.queryService = queryService;
        _this.queryTokensService = queryTokensService;
        _this.secretTokenService = secretTokenService;
        _this.formUtils = formUtils;
        _this.projectSettingsService = projectSettingsService;
        _this.projectTokenService = projectTokenService;
        _this.tokenName = 'oauth_access_token';
        return _this;
    }
    GoogleSheetsGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return combineLatest(this.secretTokenService.getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, this.tokenName, this.mode == AdminMode.Builder), this.modelDescriptionStore.getFirst()).pipe(map(function (_a) {
            var secretToken = _a[0], modelDescriptions = _a[1];
            // TODO: Add Google Sheet params class
            var files = resource.params['files']
                .map(function (file) {
                if (!file['file']) {
                    return;
                }
                var modelDescription = modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName && item.params; })
                    .find(function (item) {
                    if (file['uid']) {
                        return item.params[googleDriveFileUidParam] == file['uid'];
                    }
                    else {
                        return item.params[googleDriveFileIdParam] == file['file'].id;
                    }
                });
                if (!modelDescription) {
                    return;
                }
                return {
                    uid: file['uid'] || generateUUID(),
                    unique_name: modelDescription.model,
                    verbose_name: modelDescription.verboseNamePlural,
                    sheet: file['sheet'],
                    range: file['range'],
                    file: file['file']
                };
            })
                .filter(function (item) { return item != undefined; });
            return {
                access_token: secretToken.value,
                token_params: secretToken.params,
                files: files
            };
        }));
    };
    GoogleSheetsGeneratorService.prototype.getUniqueName = function (name) {
        return slugify(name, { trim: true, separator: '_' }).replace(/_+/g, '_');
    };
    GoogleSheetsGeneratorService.prototype.createModelDescriptionFields = function (options, spreadsheetId, modelDescriptionName, sheetName, xFrom, yFrom, xTo) {
        var _this = this;
        var url = "https://sheets.googleapis.com/v4/spreadsheets/" + spreadsheetId;
        var setUpHeaders = [
            {
                name: 'Authorization',
                value: "Bearer " + options.access_token
            }
        ];
        var setUpParams = [
            { name: 'ranges', value: "'" + sheetName + "'!" + xFrom + yFrom + ":" + xTo + yFrom },
            { name: 'ranges', value: "'" + sheetName + "'!" + xFrom + (0 + 2) + ":" + xTo + (0 + 20 + 1) },
            { name: 'includeGridData', value: 'true' }
        ];
        var headers = setUpHeaders.reduce(function (prev, current) { return prev.append(current.name, current.value); }, new HttpHeaders());
        var queryParams = setUpParams.reduce(function (prev, current) { return prev.append(current.name, current.value); }, new HttpParams());
        return this.http
            .get(url, { headers: headers, params: queryParams })
            .pipe(map(function (result) {
            var data = result.sheets[0].data;
            var properties = result.sheets[0].properties;
            var headerRange = data[0];
            var dataRange = data[1];
            _this.latestFileContents = data;
            if (!dataRange.rowData) {
                throw new AppError("File \"" + modelDescriptionName + "\" has no data for selected range. It should contain at least one record (excluding headers).");
            }
            var cleanHeader = function (value, index) {
                if (value && value.formattedValue && isSet(value.formattedValue)) {
                    return value.formattedValue;
                }
                else {
                    return "field_" + (index + 1);
                }
            };
            var rows = (dataRange.rowData || []).map(function (row) { return [
                {
                    name: primaryKey,
                    type: FieldType.Number,
                    params: {},
                    value: 1
                }
            ].concat((row.values || []).map(function (item, col) {
                var _a = item.effectiveValue && keys(item.effectiveValue).length
                    ? toPairs(item.effectiveValue)[0]
                    : [undefined, undefined], valueType = _a[0], value = _a[1];
                var name = cleanHeader(headerRange.rowData[0].values[col], col);
                var type;
                var params = {};
                if (valueType == 'numberValue') {
                    var numberType = item.effectiveFormat.numberFormat
                        ? item.effectiveFormat.numberFormat.type
                        : undefined;
                    if (numberType == 'DATE') {
                        type = FieldType.DateTime;
                        params['output_format'] = OutputFormat.SerialNumberDate;
                        params['date'] = true;
                        params['time'] = false;
                    }
                    else if (numberType == 'DATE_TIME') {
                        type = FieldType.DateTime;
                        params['output_format'] = OutputFormat.SerialNumber;
                    }
                    else if (numberType == 'TIME') {
                        type = FieldType.Time;
                        params['output_format'] = TimeOutputFormat.Number;
                    }
                }
                if (type == undefined) {
                    var valueField = detectFieldByValue(value);
                    type = valueField.field;
                    params = valueField.params || params;
                }
                return {
                    name: name,
                    type: type,
                    params: params,
                    value: value
                };
            })); });
            // Detect fields by first non empty cell
            var fields = rows.reduce(function (acc, row) {
                row.forEach(function (column) {
                    if (acc[column.name] || !isSet(column.value)) {
                        return;
                    }
                    acc[column.name] = {
                        type: column.type,
                        params: column.params
                    };
                });
                return acc;
            }, {});
            // Fallback cells without content to first row settings
            if (rows.length) {
                rows[0].forEach(function (column) {
                    if (fields[column.name]) {
                        return;
                    }
                    fields[column.name] = {
                        type: column.type,
                        params: column.params
                    };
                });
            }
            var resultFields = [
                primaryKey
            ].concat(headerRange.rowData[0].values.map(function (headerItem, i) { return cleanHeader(headerItem, i); })).map(function (name) {
                var item = fields[name];
                var field = new ModelField();
                var dbField = new ModelDbField();
                dbField.name = name;
                if (dbField.name == primaryKey) {
                    dbField.verboseName = 'row';
                }
                if (item) {
                    dbField.field = item['type'];
                    dbField.params = item['params'];
                }
                dbField.editable = !readOnlyFields.includes(name);
                dbField.filterable = !readOnlyFields.includes(name);
                dbField.sortable = !readOnlyFields.includes(name);
                dbField.required = false;
                dbField.updateFieldDescription();
                field.name = name;
                field.type = ModelFieldType.Db;
                field.item = dbField;
                return field;
            });
            resultFields.reduce(function (acc, item) {
                var key = String(item.name).toLowerCase();
                if (acc.hasOwnProperty(key)) {
                    throw new AppError("File \"" + modelDescriptionName + "\" has multiple fields with the same name: " + item.name + ".");
                }
                acc[key] = true;
                return acc;
            }, {});
            return {
                fields: resultFields,
                sheetProperties: properties
            };
        }));
    };
    GoogleSheetsGeneratorService.prototype.createModelDescription = function (options, file) {
        var _a;
        if (!file.file || !file.sheet || !file.range) {
            return of(undefined);
        }
        var modelDescription = new ModelDescription();
        modelDescription.project = '{{project}}';
        modelDescription.resource = '{{resource}}';
        modelDescription.model = file.unique_name || this.getUniqueName(file.verbose_name);
        modelDescription.verboseName = file.verbose_name;
        modelDescription.verboseNamePlural = file.verbose_name;
        modelDescription.primaryKeyField = primaryKey;
        modelDescription.params = (_a = {},
            _a[googleDriveFileUidParam] = file.uid,
            _a);
        var queryHeaders = [
            {
                name: 'Authorization',
                value: "Bearer {-" + this.tokenName + "-}"
            }
        ];
        // const sheet = item.sheet as SheetValue;
        // const file = item.file as GoogleDriveFile;
        var sheetName = file.sheet.name;
        var coords = file.range.match(/^([A-Z]+)(\d+)?:([A-Z]+)(\d+)?$/);
        var xFrom = coords[1];
        var yFrom = parseInt(coords[2], 10) || 1;
        var xTo = coords[3];
        return this.createModelDescriptionFields(options, file.file.id, file.verbose_name, sheetName, xFrom, yFrom, xTo).pipe(map(function (result) {
            modelDescription.fields = result.fields;
            modelDescription.defaultFields = cloneDeep(result.fields);
            modelDescription.getQuery = new ListModelDescriptionQuery();
            var getHttpQuery = new HttpQuery();
            getHttpQuery.method = HttpMethod.GET;
            getHttpQuery.url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.file.id + "/values:batchGet";
            getHttpQuery.headers = queryHeaders;
            getHttpQuery.queryParams = [
                { name: 'ranges', value: "'" + sheetName + "'!" + xFrom + yFrom + ":" + xTo + yFrom },
                { name: 'ranges', value: "'" + sheetName + "'!" + xFrom + (yFrom + 1) + ":" + xTo + maxRows },
                { name: 'valueRenderOption', value: 'UNFORMATTED_VALUE' }
            ];
            getHttpQuery.responseTransformer = listResponseTransformer;
            getHttpQuery.errorTransformer = errorTransformer;
            modelDescription.getQuery.queryType = QueryType.Http;
            modelDescription.getQuery.httpQuery = getHttpQuery;
            modelDescription.getQuery.pagination = QueryPagination.Offset;
            modelDescription.getQuery.frontendFiltering = true;
            modelDescription.getQuery.sorting = true;
            // modelDescription.getDetailQuery = new ModelDescriptionQuery();
            // const getDetailHttpQuery = new HttpQuery();
            //
            // getDetailHttpQuery.method = HttpMethod.GET;
            // getDetailHttpQuery.url = getHttpQuery.url;
            // getDetailHttpQuery.headers = queryHeaders;
            // getDetailHttpQuery.queryParams = [
            //   { name: 'ranges', value: `'${sheetName}'!${xFrom}${yFrom}:${xTo}${yFrom}` },
            //   {
            //     name: 'ranges',
            //     value: `'${sheetName}'!${xFrom}{{(parseInt(params.${primaryKey}) || ${yFrom})+1}}:${xTo}{{(parseInt(params.${primaryKey}) || ${maxRows})+1}}`
            //   },
            //   { name: 'valueRenderOption', value: 'UNFORMATTED_VALUE' }
            // ];
            // getDetailHttpQuery.responseTransformer = detailResponseTransformer;
            // getDetailHttpQuery.errorTransformer = errorTransformer;
            //
            // modelDescription.getDetailQuery.queryType = QueryType.Http;
            // modelDescription.getDetailQuery.httpQuery = getDetailHttpQuery;
            // modelDescription.getDetailParameters = [...modelDescription.getParameters];
            if (file.file.capabilities && file.file.capabilities.canEdit) {
                modelDescription.createQuery = new ModelDescriptionQuery();
                var createHttpQuery = new HttpQuery();
                createHttpQuery.method = HttpMethod.POST;
                createHttpQuery.url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.file.id + "/values/'" + encodeURIComponent(sheetName) + "'!" + file.range + ":append";
                createHttpQuery.headers = queryHeaders;
                createHttpQuery.queryParams = [
                    { name: 'valueInputOption', value: 'raw' },
                    { name: 'includeValuesInResponse', value: 'true' },
                    { name: 'responseValueRenderOption', value: 'UNFORMATTED_VALUE' }
                ];
                createHttpQuery.bodyTransformer = bodyTransformer(result.fields);
                createHttpQuery.responseTransformer = createResponseTransformer(result.fields.map(function (item) { return item.name; }));
                createHttpQuery.errorTransformer = errorTransformer;
                modelDescription.createQuery.queryType = QueryType.Http;
                modelDescription.createQuery.httpQuery = createHttpQuery;
                modelDescription.createParametersUseDefaults = true;
            }
            if (file.file.capabilities && file.file.capabilities.canEdit) {
                modelDescription.updateQuery = new ModelDescriptionQuery();
                var updateHttpQuery = new HttpQuery();
                updateHttpQuery.method = HttpMethod.PUT;
                updateHttpQuery.url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.file.id + "/values/'" + encodeURIComponent(sheetName) + "'!" + xFrom + "{{parseInt(params." + primaryKey + ")+1}}:" + xTo + "{{parseInt(params." + primaryKey + ")+1}}";
                updateHttpQuery.headers = queryHeaders;
                updateHttpQuery.queryParams = [
                    { name: 'valueInputOption', value: 'raw' },
                    { name: 'includeValuesInResponse', value: 'true' },
                    { name: 'responseValueRenderOption', value: 'UNFORMATTED_VALUE' }
                ];
                updateHttpQuery.bodyTransformer = bodyTransformer(result.fields);
                updateHttpQuery.responseTransformer = updateResponseTransformer(result.fields.map(function (item) { return item.name; }));
                updateHttpQuery.errorTransformer = errorTransformer;
                modelDescription.updateQuery.queryType = QueryType.Http;
                modelDescription.updateQuery.httpQuery = updateHttpQuery;
                modelDescription.updateParametersUseDefaults = true;
            }
            if (file.file.capabilities && file.file.capabilities.canEdit) {
                modelDescription.deleteQuery = new ModelDescriptionQuery();
                var deleteHttpQuery = new HttpQuery();
                deleteHttpQuery.method = HttpMethod.POST;
                deleteHttpQuery.url = "https://sheets.googleapis.com/v4/spreadsheets/" + file.file.id + ":batchUpdate";
                deleteHttpQuery.headers = queryHeaders;
                deleteHttpQuery.bodyType = HttpContentType.Raw;
                deleteHttpQuery.body = JSON.stringify({
                    requests: [
                        {
                            deleteDimension: {
                                range: {
                                    sheetId: result.sheetProperties.sheetId,
                                    dimension: 'ROWS',
                                    startIndex: "{{params." + primaryKey + "}}",
                                    endIndex: "{{parseInt(params." + primaryKey + ")+1}}"
                                }
                            }
                        }
                    ]
                }, undefined, 2);
                deleteHttpQuery.errorTransformer = errorTransformer;
                modelDescription.deleteQuery.queryType = QueryType.Http;
                modelDescription.deleteQuery.httpQuery = deleteHttpQuery;
                modelDescription.deleteParametersUseDefaults = true;
            }
            return modelDescription;
        }), this.apiService.catchApiError());
    };
    GoogleSheetsGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var _this = this;
        var obs = options.files.map(function (item) { return _this.createModelDescription(options, item); });
        return combineLatest(obs).pipe(map(function (modelDescriptions) { return modelDescriptions.filter(function (item) { return item != undefined; }); }), map(function (modelDescriptions) {
            var token = new SecretToken();
            token.resource = '{{resource}}';
            token.name = _this.tokenName;
            token.type = SecretTokenType.OAuth;
            token.value = options.access_token;
            try {
                token.params = options.token_params;
            }
            catch (e) {
                token.params = {};
            }
            var resourceParams = {
                files: options.files.map(function (item) {
                    return {
                        uid: item['uid'],
                        sheet: item['sheet'],
                        range: item['range'],
                        file: item['file']
                    };
                })
            };
            return {
                resourceParams: resourceParams,
                modelDescriptions: modelDescriptions.map(function (item) { return item.serialize(); }),
                secretTokens: [token.serialize()]
            };
        }));
    };
    return GoogleSheetsGeneratorService;
}(ResourceGeneratorService));
export { GoogleSheetsGeneratorService };
export { ɵ0, ɵ1, ɵ2 };

import { BillingPeriod } from './billing-period';

export class PlanPrice {
  public monthly: number;
  public monthlyAdditional: number;
  public annual: number;
  public annualAdditional: number;

  deserialize(data: Object): PlanPrice {
    this.monthly = data['monthly'];
    this.monthlyAdditional = data['monthly_additional'];
    this.annual = data['annual'];
    this.annualAdditional = data['annual_additional'];

    return this;
  }

  get(period: BillingPeriod) {
    if (period == BillingPeriod.Month) {
      return this.monthly;
    } else if (period == BillingPeriod.Year) {
      return this.annual;
    }
  }
}

export class PlanSuitable {
  public value: boolean;
  public reason: string;
  public reasonFeature: string;

  deserialize(data: Object): PlanSuitable {
    this.value = data['value'];
    this.reason = data['reason'];
    this.reasonFeature = data['reason_feature'];

    return this;
  }
}

export class Plan {
  public name: string;
  public subtitle: string;
  public uniqueName: string;
  public description: string;
  // public price: number;
  public activitiesPerPrice: number;
  public activitiesMax: number;
  public purchasable: boolean;
  public suitable: PlanSuitable;
  public price: PlanPrice;
  public params = {};
  // public hidden: boolean;

  deserialize(data: Object): Plan {
    this.name = data['name'];
    this.subtitle = data['subtitle'];
    this.uniqueName = data['unique_name'];
    this.description = data['description'];
    // this.price = parseFloat(data['price']);
    this.activitiesPerPrice = data['activities_per_price'];
    this.activitiesMax = data['activities_max'];
    this.purchasable = data['purchasable'];
    // this.hidden = data['hidden'];

    if (data['suitable']) {
      this.suitable = new PlanSuitable().deserialize(data['suitable']);
    }

    if (data['price']) {
      this.price = new PlanPrice().deserialize(data['price']);
    }

    if (data['params']) {
      this.params = data['params'];
    }

    return this;
  }

  get changePlanLink() {
    return ['project', 'change_plan', this.uniqueName];
  }

  statusLink(status) {
    return ['project', 'change_plan', this.uniqueName, status];
  }

  isPaid(): boolean {
    return !!this.params['paddle_product_ids'];
  }
}

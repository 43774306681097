var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/internal/operators';
import { FieldComponent } from '../field/field.component';
var CustomCharFieldComponent = /** @class */ (function (_super) {
    __extends(CustomCharFieldComponent, _super);
    function CustomCharFieldComponent() {
        var _this = _super.call(this) || this;
        _this.standaloneControl = new FormControl();
        return _this;
    }
    CustomCharFieldComponent.prototype.ngOnInit = function () {
        this.standaloneControl.disable();
    };
    CustomCharFieldComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.form) {
            return;
        }
        // fix ios autofill
        this.form.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(function (changes) {
            var passVal = _this.inputElement.nativeElement.value;
            if (passVal && passVal !== changes[_this.field.name]) {
                _this.form.get(_this.field.name).setValue(passVal);
            }
        });
    };
    Object.defineProperty(CustomCharFieldComponent.prototype, "control", {
        get: function () {
            return this.form && this.form.controls[this.field.name]
                ? this.form.controls[this.field.name]
                : this.standaloneControl;
        },
        enumerable: true,
        configurable: true
    });
    CustomCharFieldComponent.prototype.reset = function () {
        this.control.reset(null, { emitEvent: false });
    };
    CustomCharFieldComponent.prototype.ngOnDestroy = function () { };
    return CustomCharFieldComponent;
}(FieldComponent));
export { CustomCharFieldComponent };

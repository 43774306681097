export var TaskCompleteAction;
(function (TaskCompleteAction) {
    TaskCompleteAction["Hover"] = "hover";
    TaskCompleteAction["Click"] = "click";
    TaskCompleteAction["Input"] = "input";
})(TaskCompleteAction || (TaskCompleteAction = {}));
var TaskScenario = /** @class */ (function () {
    function TaskScenario(pages) {
        this.pages = pages;
    }
    Object.defineProperty(TaskScenario.prototype, "firstLink", {
        get: function () {
            if (!this.pages.length) {
                return;
            }
            return this.pages[0].link;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaskScenario.prototype, "isEmpty", {
        get: function () {
            return !this.pages.length || !this.pages[0].items.length;
        },
        enumerable: true,
        configurable: true
    });
    return TaskScenario;
}());
export { TaskScenario };

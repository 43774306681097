<div class="popup-background" [class.popup-background_opaque]="opaque" #background></div>
<div
  class="popup-container"
  (mousedown)="onContainerMouseDown($event)"
  (click)="onContainerMouseClick($event, container)"
  #container
>
  <div
    class="popup"
    [class.popup_wide]="wide"
    [class.popup_shadow]="opaque"
    [class.popup_transparent]="transparent"
    #root
  >
    <app-dynamic-component [componentData]="data"></app-dynamic-component>
  </div>
</div>

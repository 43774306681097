import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-model-field-stub',
  templateUrl: './select-model-field-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectModelFieldStubComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

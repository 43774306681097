/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/error/error.component.ngfactory";
import * as i2 from "../../../../shared/components/error/error.component";
import * as i3 from "./pro-block.component";
var styles_ProBlockComponent = [];
var RenderType_ProBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProBlockComponent, data: {} });
export { RenderType_ProBlockComponent as RenderType_ProBlockComponent };
export function View_ProBlockComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i2.ErrorComponent, [], { title: [0, "title"], fill: [1, "fill"] }, null), (_l()(), i0.ɵted(-1, 0, [" This functionality is not available for Free Plan. "])), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Upgrade to Pro to gain access to advanced functionality.\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Paid Feature"; var currVal_1 = _co.fill; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pro-block", [], null, null, null, View_ProBlockComponent_0, RenderType_ProBlockComponent)), i0.ɵdid(1, 114688, null, 0, i3.ProBlockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProBlockComponentNgFactory = i0.ɵccf("app-pro-block", i3.ProBlockComponent, View_ProBlockComponent_Host_0, { fill: "fill" }, {}, []);
export { ProBlockComponentNgFactory as ProBlockComponentNgFactory };

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName, ResourceType } from '@modules/projects';
import {
  ResourceControllerService,
  StorageBucketResponse,
  SupabaseStorageResourceController
} from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../resource-settings/base-resource-settings/base-resource-settings.component';
import { SupabaseStorageResourceSettingsForm } from './supabase-storage-resource-settings.form';

@Component({
  selector: 'app-supabase-storage-resource-settings',
  templateUrl: './supabase-storage-resource-settings.component.html',
  providers: [SupabaseStorageResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupabaseStorageResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit {
  buckets: StorageBucketResponse[] = [];
  createdControl: FormControl;

  constructor(
    public form: SupabaseStorageResourceSettingsForm,
    private resourceControllerService: ResourceControllerService,
    private dialogService: DialogService,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest(controlValue(this.form.form.controls.url), controlValue(this.form.form.controls.key))
      .pipe(
        debounceTime(200),
        switchMap(([url, key]) => {
          const controller = this.resourceControllerService.get<SupabaseStorageResourceController>(
            ResourceType.SupabaseStorage
          );
          const controlsValid = [this.form.form.controls.url, this.form.form.controls.key].every(item => item.valid);
          const buckets$ = controlsValid
            ? controller.getBuckets(url, key).pipe(
                map(response => response.buckets),
                catchError(() => of<StorageBucketResponse[]>([]))
              )
            : of([]);
          return combineLatest(buckets$, controlValue<string[]>(this.form.form.controls.buckets));
        }),
        untilDestroyed(this)
      )
      .subscribe(([result, usedBuckets]) => {
        this.buckets = result.filter(bucket => usedBuckets.every(item => item != bucket.name));
        this.cd.markForCheck();
      });
  }

  createBucket() {
    this.createdControl = this.form.form.controls.buckets.appendControl();
    this.cd.markForCheck();
  }

  removeBucket(control: FormControl, i: number) {
    if (!isSet(control.value)) {
      this.processRemoveBucket(control);
      return;
    }

    this.dialogService
      .warning({
        title: 'Remove bucket',
        description: `Are you sure want to remove <strong>Bucket ${i + 1}</strong>?`,
        style: 'orange'
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result) {
          this.processRemoveBucket(control);
        }
      });
  }

  processRemoveBucket(control: FormControl) {
    this.form.form.controls.buckets.removeControl(control);
    this.cd.markForCheck();
  }

  bucketsDragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.form.controls.buckets.controls, event.previousIndex, event.currentIndex);
      this.form.form.updateValueAndValidity();
    }
  }
}

registerResourceSettingsComponent(ResourceName.SupabaseStorage, SupabaseStorageResourceSettingsComponent);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
  CustomizeService,
  ElementType,
  FileViewerElementItem,
  FileViewerType,
  registerElementComponent
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-file-viewer-element',
  templateUrl: './file-viewer-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewerElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: FileViewerElementItem;

  customizeEnabled$: Observable<boolean>;
  contextSubscription: Subscription;
  embedUrl = '';
  fileViewerTypes = FileViewerType;
  isSafari = false;

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    this.initContextObserver();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FileViewerElementComponent>): void {
    if (changes.element) {
      this.initContextObserver();
    }
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    this.updateInputs();

    this.contextSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.updateInputs());
  }

  updateInputs() {
    const embedUrl = this.getEmbedUrl();

    if (embedUrl == this.embedUrl) {
      return;
    }

    if (isSet(this.embedUrl)) {
      this.embedUrl = '';
      this.cd.detectChanges();
    }

    this.embedUrl = embedUrl;
    this.cd.markForCheck();
  }

  getEmbedUrl() {
    try {
      const url = applyParamInput(this.element.url, {
        context: this.context,
        defaultValue: ''
      });
      const cleanUrl = this.getCleanUrl(url);
      return this.getFileViewerUrl(cleanUrl);
    } catch (e) {
      return '';
    }
  }

  getCleanUrl(url: string) {
    if (!isSet(url)) {
      return url;
    }

    url = String(url);

    if (!['https://', 'http://', 'file://', 'ftp://', '//'].some(item => url.toLowerCase().startsWith(item))) {
      return `//${url}`;
    } else {
      return url;
    }
  }

  getFileViewerUrl(fileUrl: string) {
    if (!isSet(fileUrl)) {
      return fileUrl;
    }

    if (this.element.viewerType == FileViewerType.Google) {
      const encodedUrl = encodeURIComponent(fileUrl);
      return `https://docs.google.com/gview?url=${encodedUrl}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`;
    } else {
      if (this.element.toolbar) {
        return fileUrl;
      }

      const url = new URL(fileUrl);
      const hashParams = new URLSearchParams(url.hash);

      hashParams.set('toolbar', '0');
      hashParams.set('navpanes', '0');
      url.hash = hashParams.toString();

      return url.toString();
    }
  }
}

registerElementComponent({
  type: ElementType.FileViewer,
  component: FileViewerElementComponent,
  label: 'File Viewer',
  actions: []
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CLOSE_BY_BACKGROUND_CLICK, TransparentDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { isSet } from '@shared';
import { ViewEditorComponent } from '../../components/view-editor/view-editor.component';
var ViewEditorController = /** @class */ (function () {
    function ViewEditorController(popupService, injector, analyticsService) {
        this.popupService = popupService;
        this.injector = injector;
        this.analyticsService = analyticsService;
    }
    ViewEditorController.prototype.ngOnDestroy = function () { };
    ViewEditorController.prototype.open = function (options) {
        var _this = this;
        var result = new ReplaySubject();
        var injector = Injector.create([], this.injector);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.StartedToSetUp, {
            Create: options.create,
            Source: options.analyticsSource
        });
        this.popupService.push({
            component: ViewEditorComponent,
            popupComponent: TransparentDialogPopupComponent,
            popupComponentCloseTitle: 'Unsaved changes',
            popupComponentCloseDescription: "\n        Are you sure want to close Designer?<br>\n        Current changes will be lost.\n      ",
            popupComponentOrange: true,
            inputs: __assign({ view: options.view }, (isSet(options.componentLabel) && { componentLabel: options.componentLabel }), (isSet(options.submitLabel) && { submitLabel: options.submitLabel }), (isSet(options.nameEditingEnabled) && { nameEditingEnabled: options.nameEditingEnabled }), { stateSelectedEnabled: options.stateSelectedEnabled }, (isSet(options.templatesEnabled) && { templatesEnabled: options.templatesEnabled }), { analyticsSource: options.analyticsSource }),
            outputs: {
                result: [
                    function (event) {
                        result.next(event);
                        _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.Saved, {
                            Create: options.create,
                            Source: options.analyticsSource
                        });
                    }
                ]
            },
            popupClosed: function (data) {
                if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
                    result.next({ cancelled: true });
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewEditor.SetUpCancelled, {
                        Create: options.create,
                        Source: options.analyticsSource
                    });
                }
            },
            injector: injector
        });
        return result.asObservable();
    };
    return ViewEditorController;
}());
export { ViewEditorController };

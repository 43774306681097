<div class="card">
  <div class="card__inner">
    <app-auto-field
      [form]="form"
      [field]="
        createField({
          name: 'verbose_name',
          label: 'display name',
          field: 'CharField',
          params: { classes: ['input_fill'] }
        })
      "
    ></app-auto-field>
    <app-auto-field
      [form]="form"
      [field]="
        createField({ name: 'required', label: 'required', field: 'BooleanField', params: { classes: ['input_fill'] } })
      "
    ></app-auto-field>
  </div>
</div>

<app-dynamic-component *ngIf="dataParamsComponentData" [componentData]="dataParamsComponentData">
</app-dynamic-component>
<app-dynamic-component *ngIf="viewParamsComponentData" [componentData]="viewParamsComponentData">
</app-dynamic-component>

<div class="edit-popup__submit">
  <button type="button" class="button button_primary button_fill" (click)="save()">
    <span class="button__label">Save</span>
  </button>
  <button type="button" class="button button_danger button_fill" (click)="requestDelete()">
    <span class="button__label">Delete</span>
  </button>
</div>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
var ActionSingleEditComponent = /** @class */ (function () {
    function ActionSingleEditComponent(customizeBarService, customizeBarContext, cd) {
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.cd = cd;
        this.options = {};
        this.defaultSubtitle = 'Not specified';
        this.defaultDisabled = true;
        this.draggable = false;
        this.remove = false;
        this.pin = false;
        this.pinned = false;
        this.firstInit = false;
    }
    ActionSingleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        if (this.control.parent) {
            this.control.parent.statusChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
        }
    };
    ActionSingleEditComponent.prototype.ngOnDestroy = function () { };
    ActionSingleEditComponent.prototype.editActionItem = function (control, title) {
        this.customizeBarService
            .customizeActionItem({
            options: __assign({ actionItem: control.value, actionLabel: this.title }, this.options),
            context: this.customizeBarContext,
            viewContext: this.context,
            viewContextElement: this.contextElement,
            viewContextElementPath: this.contextElementPath,
            viewContextElementPaths: this.contextElementPaths,
            label: this.label,
            object: this.object,
            append: true,
            firstInit: this.firstInit
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                control.patchValue(instance);
                control.markAsDirty();
            }
            else if (e.type == CustomizeBarEditEventType.Deleted) {
                control.patchValue(undefined);
                control.markAsDirty();
            }
        });
    };
    ActionSingleEditComponent.prototype.removeActionItem = function (control) {
        control.patchValue(undefined);
        control.markAsDirty();
    };
    return ActionSingleEditComponent;
}());
export { ActionSingleEditComponent };

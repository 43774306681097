<app-popup2 [size]="'s'">
  <app-popup2-header>
    <app-popup2-title>Records Export</app-popup2-title>
    <app-popup2-description>
      Downloading records data as a file
    </app-popup2-description>
  </app-popup2-header>

  <ng-container *ngIf="!executeActionStarted">
    <app-loader *ngIf="loading"></app-loader>

    <ng-container *ngIf="!loading">
      <app-page-block>
        <div class="field">
          <label class="field__label field__label_bright">
            Choose Format
          </label>
          <gxs-select [(ngModel)]="formatType" [options]="{ theme: 'jet', labels: {} | localizeSelect }">
            <gxs-option *ngFor="let item of exportFormats" [name]="item.label" [value]="item.type"> </gxs-option>
          </gxs-select>
        </div>
      </app-page-block>

      <app-page-block *ngIf="items">
        <div class="popup-form__description">
          <ng-container *ngIf="count !== undefined">
            You are going to Export <strong>{{ title }}</strong> items: {{ count }}
          </ng-container>
          <ng-container *ngIf="count === undefined">
            You are going to Export <strong>{{ title }}</strong> items
          </ng-container>
        </div>
        <div *ngIf="false" class="card">
          <div class="card__inner">
            <ul>
              <li>The following items will be exported:</li>
              <li>---</li>
              <li *ngFor="let item of items">
                <a [appLink]="item.getLink()" [innerHTML]="item | appModelStr | appStub | async" (click)="close()"></a>
              </li>
              <li *ngIf="items.length < count">...{{ count - items.length }} more</li>
            </ul>
          </div>
        </div>
      </app-page-block>
    </ng-container>
  </ng-container>

  <app-page-block *ngIf="executeActionStarted">
    <app-circle-progress [progress]="executeActionProgress" [label]="executeActionStatus"></app-circle-progress>
  </app-page-block>

  <app-popup2-footer *ngIf="!loading">
    <app-page-block>
      <app-stack [align]="'right'">
        <a href="javascript:void(0)" class="button popup2__footer-item" (click)="close()">
          Cancel
        </a>

        <button
          *ngIf="!executeActionSucceeded"
          type="submit"
          class="button button_primary popup2__footer-item"
          [class.button_disabled]="!formatType || executeActionStarted"
          (click)="exportData()"
        >
          Export
        </button>
        <button
          *ngIf="executeActionSucceeded"
          type="button"
          class="button button_primary popup2__footer-item"
          (click)="close()"
        >
          Continue
        </button>
      </app-stack>
    </app-page-block>
  </app-popup2-footer>
</app-popup2>

<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Fill in the form below to start building internal tools to interact with your API.
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="resourceNameEditing">
        <app-auto-field
          [form]="form.resourceForm"
          [field]="
            createField({
              name: 'name',
              label: 'Resource Name',
              field: 'CharField',
              description: 'This is how you and your team will see it',
              required: true,
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-page-block>
        <app-auto-field
          [form]="form.form"
          [field]="
            createField({
              name: 'url',
              label: 'Base URL',
              required: false,
              field: 'CharField',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-page-block>

      <app-popup-collapse [title]="'Advanced settings'">
        <app-page-block>
          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'auth_type',
                required: false,
                label: 'Authentication',
                field: 'SelectField',
                params: { options: form.authTypeOptions, classes: ['select_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <ng-container *ngIf="form.form.value['auth_type'] == httpQueryAuthTypes.Key">
          <app-page-block>
            <app-auto-field
              [form]="form.keyForm"
              [field]="
                createField({
                  name: 'send_as',
                  label: 'Send As',
                  field: 'SelectField',
                  required: true,
                  params: { options: form.keyAuthSendAsOptions, classes: ['select_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>

          <app-page-block>
            <app-auto-field
              [form]="form.keyForm"
              [field]="
                createField({
                  name: 'name',
                  label: 'Name',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>

          <app-page-block>
            <app-auto-field
              [form]="form.keyForm"
              [field]="
                createField({
                  name: 'value',
                  label: 'Value',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>
        </ng-container>

        <ng-container *ngIf="form.form.value['auth_type'] == httpQueryAuthTypes.BasicAuth">
          <app-page-block>
            <app-auto-field
              [form]="form.basicForm"
              [field]="
                createField({
                  name: 'username',
                  label: 'Username',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>

          <app-page-block>
            <app-auto-field
              [form]="form.basicForm"
              [field]="
                createField({
                  name: 'password',
                  label: 'Password',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>
        </ng-container>

        <ng-container *ngIf="form.form.value['auth_type'] == httpQueryAuthTypes.OAuth2">
          <app-oauth2-params
            [control]="form.form.controls['auth_params']"
            [accessTokenForResource]="resource"
            [accessTokenName]="form.form.value['access_token_name']"
            [fill]="true"
            (resourceAccessToken)="onResourceAccessToken($event)"
          >
          </app-oauth2-params>
        </ng-container>

        <app-page-block>
          <div class="field">
            <div class="field__label">Rate limiting</div>
            <div class="field__value">
              <app-page-columns [innerPaddingSize]="'xs'">
                <app-page-column [fillGrow]="1.5">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input"
                      formControlName="rate_limit_actions"
                    />
                    <div class="input-group__item input-group__item_last input input_disabled">requests</div>
                  </div>
                </app-page-column>

                <app-page-column [fill]="false" [verticalAlign]="'center'">
                  per
                </app-page-column>

                <app-page-column>
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-group__item input-group__item_first input-group__fill input"
                      formControlName="rate_limit_per_seconds"
                    />
                    <div class="input-group__item input-group__item_last input input_disabled">sec.</div>
                  </div>
                </app-page-column>
              </app-page-columns>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'custom_proxy',
                required: false,
                label: 'Custom HTTP proxy',
                field: 'CharField',
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <a [appDocumentation]="'integrations/rest-api'" class="button button_small" style="margin: 0;">
            Read documentation
          </a>
        </app-page-block>
      </app-popup-collapse>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <div class="popup-content__submit">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="submit()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>

import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';
import { toPairs } from 'lodash';

import { isSet } from '../../utils/common/common';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControl], [formControlName]'
})
export class FormControlSelectorDirective implements OnInit {
  constructor(
    private el: ElementRef,
    @Optional() private controlDirective: FormControlDirective,
    @Optional() private controlName: FormControlName
  ) {}

  ngOnInit(): void {
    let name: string;

    if (this.controlDirective && this.controlDirective.control && this.controlDirective.control.parent) {
      const formControl = toPairs(this.controlDirective.control.parent.controls).find(
        ([k, v]) => v === this.controlDirective.control
      );
      if (formControl) {
        name = formControl[0];
      }
    } else if (this.controlName) {
      name = this.controlName.name;
    }

    if (isSet(name)) {
      this.el.nativeElement.setAttribute('data-control', name);
    }
  }
}

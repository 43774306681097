<div *ngIf="nodeType == nodeTypes.Object">
  <div *ngIf="!skipSelf" class="structure-item">
    <div class="structure-item__content">
      <div
        class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
        [class.sidebar-list-item_active]="nodeSelected"
        (click)="select()"
      >
        <div class="sidebar-list-item__left">
          <span class="button button_icon button_orange button_tiny" style="margin: 0;">
            <span class="button__icon">&#123; &#125;</span>
          </span>
        </div>
        <div class="sidebar-list-item__main">
          <div class="sidebar-list-item__title">
            <ng-container *ngIf="deep == 0">ROOT</ng-container>
            <ng-container *ngIf="deep > 0">{{ nodeName || 'No name' }}</ng-container>
            &nbsp;<span class="sidebar-list-item__title-description">OBJECT ({{ nodeObjectItems.length }} items)</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!skipContent">
    <ng-container *ngFor="let item of nodeObjectItems; let i = index; let last = last">
      <div class="structure-item">
        <div class="structure-item__dash">
          <span class="structure-item__dash-vertical"></span>
          <span class="structure-item__dash-horizontal"></span>
          <a
            *ngIf="item.type == nodeTypes.Object || item.type == nodeTypes.Array"
            href="javascript:void(0)"
            class="structure-item__dash-toggle icon-plus"
            [class.structure-item__dash-toggle_active]="childCollapsed[i]"
            [class.icon-plus]="childCollapsed[i]"
            [class.icon-minus]="!childCollapsed[i]"
            (click)="toggleCollapse(i)"
          >
          </a>
        </div>
        <div class="structure-item__content">
          <app-query-builder-object-structure-node
            [node]="item.value"
            [nodeName]="item.key"
            [path]="nodePath"
            [pathSelected]="pathSelected"
            [skipContent]="childCollapsed[i]"
            [deep]="deep + 1"
            (selected)="childSelected($event)"
          ></app-query-builder-object-structure-node>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="nodeType == nodeTypes.Array">
  <div *ngIf="!skipSelf" class="structure-item">
    <div class="structure-item__content">
      <div
        class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
        [class.sidebar-list-item_active]="nodeSelected"
        (click)="select()"
      >
        <div class="sidebar-list-item__left">
          <span class="button button_icon button_orange button_tiny" style="margin: 0;">
            <span class="button__icon">[ ]</span>
          </span>
        </div>
        <div class="sidebar-list-item__main">
          <div class="sidebar-list-item__title">
            <ng-container *ngIf="deep == 0">ROOT</ng-container>
            <ng-container *ngIf="deep > 0">{{ nodeName || 'No name' }}</ng-container>
            &nbsp;<span class="sidebar-list-item__title-description">ARRAY ({{ nodeArrayItems.length }} items)</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!skipContent">
    <ng-container *ngFor="let item of nodeArrayItems; let i = index">
      <ng-container *ngIf="item.type == nodeTypes.Object || item.type == nodeTypes.Array">
        <app-query-builder-object-structure-node
          [node]="item.value"
          [nodeName]="i.toString()"
          [path]="nodePath"
          [pathSelected]="pathSelected"
          [skipSelf]="true"
          [deep]="deep + 1"
          (selected)="childSelected($event)"
        ></app-query-builder-object-structure-node>
      </ng-container>

      <div *ngIf="item.type == nodeTypes.Field" class="structure-item">
        <div class="structure-item__dash structure-item__dash_last">
          <span class="structure-item__dash-vertical"></span>
          <span class="structure-item__dash-horizontal"></span>
        </div>
        <div class="structure-item__content">
          <app-query-builder-object-structure-node
            [node]="item.value"
            [nodeName]="i.toString()"
            [path]="nodePath"
            [pathSelected]="pathSelected"
            [deep]="deep + 1"
            (selected)="childSelected($event)"
          ></app-query-builder-object-structure-node>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="nodeType == nodeTypes.Field">
  <div
    class="sidebar-list-item sidebar-list-item_compact sidebar-list-item_clickable"
    [class.sidebar-list-item_active]="nodeSelected"
    (click)="select()"
  >
    <div class="sidebar-list-item__left">
      <span class="button button_icon button_orange button_tiny" style="margin: 0;">
        <span class="icon button__icon icon-text"></span>
      </span>
    </div>
    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <ng-container *ngIf="deep == 0">ROOT</ng-container>
        <ng-container *ngIf="deep > 0">{{ nodeName || 'No name' }}</ng-container>
        &nbsp;<span class="sidebar-list-item__title-description">{{ node }}</span>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { XMLParser } from 'fast-xml-parser';
import isArray from 'lodash/isArray';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ApiService, DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { coerceArray, EMPTY, getFilenameWithExtension, isSet, objectGet } from '@shared';
import { HttpContentType } from '../../data/http-content-type';
import { HttpParameterType } from '../../data/http-parameter';
import { HttpQuery, HttpResponseType } from '../../data/http-query';
import { QueryService } from '../query/query.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../query/query.service";
import * as i3 from "../../../api/services/api/api.service";
var HttpQueryService = /** @class */ (function () {
    function HttpQueryService(http, queryService, apiService) {
        this.http = http;
        this.queryService = queryService;
        this.apiService = apiService;
    }
    HttpQueryService.prototype.cleanUrl = function (value) {
        var url = new URL(value);
        return "" + url.origin + url.pathname + url.search;
    };
    HttpQueryService.prototype.prepareParameters = function (query, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (options.baseQuery) {
            query.merge(options.baseQuery);
        }
        var url = query.getEffectiveUrl(options.baseQuery);
        url = this.queryService.applyTokens(url, options.tokens, options.raiseErrors);
        url = this.cleanUrl(url);
        var headers = (query.headers || [])
            .filter(function (item) { return item.value !== '' && item.value !== null; })
            .map(function (item) {
            return {
                name: item.name,
                value: _this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)
            };
        })
            .filter(function (item) { return item.value !== ''; });
        var params = query.queryParams
            .filter(function (item) { return item.value !== '' && item.value !== null; })
            .map(function (item) {
            return {
                name: item.name,
                value: _this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)
            };
        })
            .filter(function (item) { return item.value !== ''; });
        var bodyType = query.bodyType;
        var body = query.body;
        if (query.bodyTransformer) {
            body = this.queryService.applyTransformer(body, query.bodyTransformer, url, options.raiseErrors, options.tokens);
        }
        if (query.bodyType == HttpContentType.JSON) {
            if (isSet(body)) {
                if (typeof body == 'string') {
                    body = JSON.stringify(this.queryService.applyTransformer(body, 'return ' + body, url, options.raiseErrors, options.tokens));
                }
                else {
                    body = JSON.stringify(body);
                }
            }
        }
        else if (query.bodyType == HttpContentType.GraphQL) {
            bodyType = HttpContentType.JSON;
            body = JSON.stringify({ query: body });
            body = this.queryService.applyTokens(body, options.tokens, options.raiseErrors);
        }
        else if (query.bodyType == HttpContentType.Raw) {
            body = this.queryService.applyTokens(body, options.tokens, options.raiseErrors);
        }
        else if (query.bodyType == HttpContentType.FormUrlEncoded) {
            body = body
                .filter(function (item) { return item.value !== '' && item.value !== null; })
                .map(function (item) {
                return [item.name, _this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)];
            })
                .filter(function (_a) {
                var k = _a[0], v = _a[1];
                return v !== '';
            })
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return [k, encodeURIComponent(v)].join('=');
            })
                .join('&');
        }
        else if (query.bodyType == HttpContentType.FormData) {
            body = body
                .filter(function (item) { return item.value !== '' && item.value !== null; })
                .map(function (item) {
                var singleToken = String(item.value).match(/^{{([^}]+)}}$/);
                if (singleToken) {
                    var tokenValue = objectGet(options.tokens, singleToken[1]);
                    if (tokenValue instanceof File) {
                        return [item.name, tokenValue];
                    }
                    else if (tokenValue !== EMPTY && isSet(tokenValue)) {
                        return [item.name, tokenValue, item.contentType, item.type];
                    }
                }
                var strValue = _this.queryService.applyTokens(String(item.value), options.tokens, options.raiseErrors);
                return [item.name, strValue, item.contentType, item.type];
            })
                .filter(function (_a) {
                var k = _a[0], v = _a[1];
                return v !== '';
            });
        }
        var secretTokens = [];
        var findSecretTokens = function (str) {
            if (typeof str != 'string') {
                return;
            }
            var regex = new RegExp(/{-([^}]+)-}/g);
            var m;
            while ((m = regex.exec(str))) {
                secretTokens.push(m[1]);
            }
        };
        findSecretTokens(url);
        params.forEach(function (item) { return findSecretTokens(item.value); });
        headers.forEach(function (item) { return findSecretTokens(item.value); });
        findSecretTokens(body);
        return {
            method: query.method,
            url: url,
            queryParams: params,
            headers: headers,
            bodyType: bodyType,
            body: body,
            secretTokens: secretTokens
        };
    };
    HttpQueryService.prototype.parseXML = function (xml) {
        return new XMLParser().parse(xml, true);
    };
    HttpQueryService.prototype.executeRequest = function (query, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var parameters = this.prepareParameters(query, options);
        var blobs$ = [];
        if (query.bodyType == HttpContentType.FormData) {
            blobs$.push.apply(blobs$, parameters.body
                .filter(function (_a) {
                var k = _a[0], v = _a[1], c = _a[2], type = _a[3];
                return type == HttpParameterType.File;
            })
                .map(function (parameter) {
                var k = parameter[0], v = parameter[1], c = parameter[2], type = parameter[3];
                var urls = isSet(v) ? coerceArray(v) : [];
                if (!urls.length ||
                    !urls.every(function (url) {
                        return (typeof url == 'string' &&
                            ['http://', 'https://', 'file://'].some(function (prefix) { return url.toLowerCase().startsWith(prefix); }));
                    })) {
                    return;
                }
                return combineLatest.apply(void 0, urls.map(function (url) {
                    var blobQuery = new HttpQuery();
                    blobQuery.url = url;
                    blobQuery.responseType = HttpResponseType.Blob;
                    return _this.requestBody(blobQuery).pipe(map(function (blob) {
                        return {
                            url: url,
                            blob: blob
                        };
                    }), catchError(function () { return undefined; }));
                })).pipe(tap(function (blobs) {
                    parameter[1] = blobs
                        .filter(function (item) { return item; })
                        .map(function (item) {
                        var filename = getFilenameWithExtension(item.url);
                        return new File([item.blob], filename);
                    });
                }));
            })
                .filter(function (item) { return item; }));
        }
        return (blobs$.length ? combineLatest.apply(void 0, blobs$) : of([])).pipe(switchMap(function () { return _this.apiService.refreshToken(); }), switchMap(function () {
            var url = options.customProxy || _this.apiService.methodURL('proxy_request/');
            var headers = new HttpHeaders();
            var data = new FormData();
            var withCredentials = !!options.customProxy;
            headers = _this.apiService.setHeadersToken(headers);
            // TODO: Refactor project get
            if (options.resource && options.resource.startsWith('demo_')) {
                data.append('project', DEMO_RESOURCES_PROJECT);
            }
            else {
                data.append('project', window['project']);
            }
            // TODO: Refactor project get
            if (options.resource && options.resource.startsWith('demo_')) {
                data.append('environment', 'prod');
            }
            else {
                data.append('environment', window['project_environment']);
            }
            if (options.resource) {
                data.append('resource', options.resource);
            }
            if (window['mode'] == AdminMode.Builder) {
                data.append('draft', '1');
            }
            data.append('method', parameters.method);
            data.append('url', parameters.url);
            data.append('query_params', JSON.stringify(parameters.queryParams));
            data.append('headers', JSON.stringify(parameters.headers));
            data.append('body_type', parameters.bodyType);
            if (query.bodyType == HttpContentType.FormData) {
                parameters.body.forEach(function (_a) {
                    var k = _a[0], v = _a[1], c = _a[2];
                    if (v instanceof Blob) {
                        data.append("body[" + k + "]", v);
                    }
                    else if (isArray(v) && v.every(function (item) { return item instanceof Blob; })) {
                        v.forEach(function (item) {
                            data.append("body[" + k + "]", item);
                        });
                    }
                    else {
                        data.append("body[" + k + "]", v);
                    }
                    if (c) {
                        data.append("body_ct[" + k + "]", c);
                    }
                });
            }
            else {
                data.append('body', parameters.body);
            }
            if (parameters.secretTokens.length) {
                data.append('secret_tokens', parameters.secretTokens.join(','));
            }
            var responseType = 'text';
            if (query.responseType == HttpResponseType.JSON) {
                responseType = 'json';
            }
            else if (query.responseType == HttpResponseType.Blob) {
                responseType = 'blob';
            }
            else if (query.responseType == HttpResponseType.XML) {
                responseType = 'text';
            }
            return _this.http
                .post(url, data, {
                headers: headers,
                withCredentials: withCredentials,
                observe: 'events',
                reportProgress: true,
                responseType: responseType
            })
                .pipe(catchError(function (error) {
                return of(error);
            }), map(function (result) {
                var content;
                if (result instanceof HttpResponse) {
                    if (query.responseType == HttpResponseType.XML) {
                        result = result.clone({ body: _this.parseXML(result.body) });
                    }
                    content = result.body;
                }
                else if (result instanceof HttpErrorResponse) {
                    if (query.responseType == HttpResponseType.XML) {
                        result = new HttpErrorResponse({
                            error: _this.parseXML(result.error),
                            headers: result.headers,
                            status: result.status,
                            statusText: result.statusText,
                            url: result.url
                        });
                    }
                    content = result.error;
                }
                else {
                    return result;
                }
                if (query.errorTransformer) {
                    var error = _this.queryService.applyTransformer(content, query.errorTransformer, parameters.url, false, {
                        http: {
                            code: result.status
                        }
                    });
                    if (error) {
                        var serverError = new ServerRequestError(error);
                        serverError.response = result;
                        serverError.status = result instanceof HttpResponseBase ? result.status : undefined;
                        throw serverError;
                    }
                }
                else {
                    if (result instanceof HttpErrorResponse) {
                        throw new ServerRequestError(result);
                    }
                }
                return result;
            }));
        }));
    };
    HttpQueryService.prototype.request = function (query, options) {
        if (options === void 0) { options = {}; }
        return this.executeRequest(query, options).pipe(filter(function (event) { return event.type == HttpEventType.Response; }), map(function (event) { return event; }));
    };
    HttpQueryService.prototype.requestBody = function (query, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.request(query, options).pipe(map(function (response) {
            var resultBody = response.body;
            var url = query.getEffectiveUrl(options.baseQuery);
            resultBody = _this.queryService.applyTransformer(resultBody, query.responseTransformer, url, options.raiseErrors, options.tokens);
            resultBody = _this.queryService.getPath(resultBody, query.responsePath);
            return resultBody;
        }));
    };
    HttpQueryService.ngInjectableDef = i0.defineInjectable({ factory: function HttpQueryService_Factory() { return new HttpQueryService(i0.inject(i1.HttpClient), i0.inject(i2.QueryService), i0.inject(i3.ApiService)); }, token: HttpQueryService, providedIn: "root" });
    return HttpQueryService;
}());
export { HttpQueryService };

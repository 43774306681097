<div class="popup-content">
  <div class="popup-content__header">
    <div class="popup-content__title">{{ title }}</div>
    <div class="popup-content__description">{{ description }}</div>
  </div>
  <div class="popup-content__content">
    <div class="choose-items">
      <div class="choose-items__inner">
        <ng-container *ngIf="loading">
          <div *ngFor="let item of [1, 2]" class="choose-items__item">
            <div class="choose-items-item">
              <div class="choose-items-item__image">
                <div class="choose-items-item__image-inner loading-animation">
                  <span class="stub-image stub-image_contrast"></span>
                </div>
              </div>
              <div class="choose-items-item__title">
                <span class="loading-animation"><span class="stub-text">Stripe</span></span>
              </div>
              <div class="choose-items-item__description">
                <span class="loading-animation"><span class="stub-text">for Stripe Refund</span></span>
              </div>
              <div class="choose-items-item__action">
                <span class="choose-items-item__action-button choose-items-item__action-button_fill">
                  <span class="button__label">
                    <span class="loading-animation"><span class="stub-text">Configure</span></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loading">
          <div *ngFor="let item of items; let i = index" class="choose-items__item">
            <a
              href="javascript:void(0)"
              class="choose-items-item choose-items-item_clickable"
              [class.choose-items-item_active]="item.updated"
              (click)="openResourceSettings(i)"
            >
              <div class="choose-items-item__image">
                <div
                  class="choose-items-item__image-inner"
                  [style.background-image]="
                    '/assets/images/resources/' + item.resource.typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></div>
              </div>
              <div class="choose-items-item__title">{{ item.resource.name }}</div>
              <div class="choose-items-item__description">
                <ng-container *ngIf="item.template">for {{ item.template.name }}</ng-container>
                <ng-container *ngIf="!item.template">{{ item.resource.typeItem.shortDescription }}</ng-container>
              </div>
              <div class="choose-items-item__action">
                <span
                  class="choose-items-item__action-button choose-items-item__action-button_fill"
                  [class.choose-items-item__action-button_active]="!item.updated"
                >
                  <span class="button__label">
                    <ng-container *ngIf="item.updated">Configured</ng-container>
                    <ng-container *ngIf="!item.updated">Configure</ng-container>
                  </span>
                  <span
                    class="icon icon-gear choose-items-item__action-button-icon choose-items-item__action-button-icon_right"
                  ></span>
                </span>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="popup-content__submit">
    <button
      type="button"
      class="button popup-content__submit-item"
      [class.button_primary]="isAllResourcesUpdated()"
      (click)="close()"
    >
      Done
    </button>
  </div>
</div>

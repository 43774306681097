import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { FigmaExternalImport, FigmaService } from '@modules/figma-integration';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';

@Component({
  selector: 'app-import-figma-node-item, [app-import-figma-node-item]',
  templateUrl: './import-figma-node-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportFigmaNodeItemComponent implements OnInit, OnDestroy {
  @Input() item: FigmaExternalImport;
  @Output() importView = new EventEmitter<View>();

  importLoading = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private figmaService: FigmaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  applyImport(id: string) {
    this.importLoading = true;
    this.cd.markForCheck();

    this.figmaService
      .getImportDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        id
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.importLoading = false;
          this.cd.markForCheck();

          this.importView.emit(result.view);

          this.notificationService.success('Imported', 'Layer was imported successfully');
        },
        error => {
          this.importLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Import failed', error.errors[0]);
          } else {
            this.notificationService.error('Import failed', error);
          }
        }
      );
  }
}

import { Point } from './point';
import { Size } from './size';
import { Translate } from './translate';

export function getFramePointPosition(frame: Partial<Frame>, point: Partial<Point>): Partial<Point> {
  return {
    x: frame.x + frame.width * point.x,
    y: frame.y + frame.height * point.y
  };
}

export class Frame {
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  rotation = 0;

  constructor(options: Partial<Frame> = {}) {
    this.patch(options);
  }

  deserialize(data: Object): this {
    this.x = data['x'];
    this.y = data['y'];
    this.width = data['width'];
    this.height = data['height'];
    this.rotation = data['rotation'];

    return this;
  }

  serialize(): Object {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rotation: this.rotation
    };
  }

  getPosition(): Translate {
    return {
      x: this.x,
      y: this.y
    };
  }

  getSize(): Size {
    return {
      width: this.width,
      height: this.height
    };
  }

  patch(options: Partial<Frame>): this {
    for (const key of Object.keys(options)) {
      if (this.hasOwnProperty(key)) {
        this[key] = options[key];
      }
    }

    return this;
  }

  diff(frame: Frame): Partial<Frame> {
    const result: Partial<Frame> = {};

    if (this.x != frame.x) {
      result.x = this.x;
    }

    if (this.y != frame.y) {
      result.y = this.y;
    }

    if (this.width != frame.width) {
      result.width = this.width;
    }

    if (this.height != frame.height) {
      result.height = this.height;
    }

    return result;
  }

  get top(): number {
    return this.y;
  }

  get right(): number {
    return this.x + this.width;
  }

  get bottom(): number {
    return this.y + this.height;
  }

  get left(): number {
    return this.x;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue } from '@shared';

import { ProjectDomainForm } from './project-domain.form';

@Component({
  selector: 'app-project-domain',
  templateUrl: './project-domain.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectDomainForm]
})
export class ProjectDomainComponent implements OnInit, OnDestroy {
  @Output() created = new EventEmitter<Domain>();
  @Output() deleted = new EventEmitter<Domain>();

  createField = createFormFieldFactory();
  loading = false;
  domain: Domain;
  subdomain: string;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    public form: ProjectDomainForm,
    private notificationService: NotificationService,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.domain = project.domain && project.domain.custom ? project.domain : undefined;
        this.form.init(project);
        this.cd.markForCheck();
      });

    controlValue<string>(this.form.controls.domain)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const parts = value.split('.');

        this.subdomain = parts.length > 2 ? parts.slice(0, -2).join('.') : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  continueSetup() {
    const domain = this.form.controls.domain.value.split('.');
    if (domain.length == 2) {
      this.form.controls.domain.patchValue(['www', ...domain].join('.'));
    }

    this.form.controls.domain_entered.patchValue(true);
  }

  continueSetupOrSubmit() {
    if (!this.domain) {
      if (this.form.controls.domain.valid && !this.form.controls.domain_entered.value) {
        this.continueSetup();
      } else if (this.form.controls.domain.valid && this.form.controls.domain_entered.value && !this.loading) {
        this.submit();
      }
    }
  }

  submit() {
    if (!this.domain) {
      this.loading = true;
      this.cd.markForCheck();

      this.form
        .add()
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            this.created.emit(result);

            this.notificationService.success(
              'Domain connected',
              `Domain <strong>${result.domain}</strong> was successfully connected to current App`
            );

            this.close();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      this.loading = true;
      this.cd.markForCheck();

      const domain = this.form.instance;

      this.form
        .delete()
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            if (result) {
              this.deleted.emit(domain);

              this.notificationService.success(
                'Domain disconnected',
                `Domain <strong>${domain.domain}</strong> was successfully disconnected from current App`
              );
            }

            this.close();
          },
          error => {
            console.error(error);
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  close() {
    this.popupComponent.close();
  }
}

export class GuideTaskProgress {
  public user: number;
  public dateAdd: Date;

  deserialize(data: Object): GuideTaskProgress {
    this.user = data['user'];
    this.dateAdd = data['date_add'];

    return this;
  }
}

export enum FontStyle {
  Normal = 'normal',
  Italic = 'italic'
}

export class Font {
  family: string;
  weight = 400;
  style: FontStyle = FontStyle.Normal;
  size = 14;

  constructor(options: Partial<Font> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.family = data['family'];
    this.weight = data['weight'];
    this.style = data['style'];
    this.size = data['size'];

    return this;
  }

  serialize(): Object {
    return {
      family: this.family,
      weight: this.weight,
      style: this.style,
      size: this.size
    };
  }

  cssFontStyle(): string {
    if (this.style == FontStyle.Normal) {
      return 'normal';
    } else if (this.style == FontStyle.Italic) {
      return 'italic';
    }
  }
}

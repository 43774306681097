/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "./sidebar-tabs.component";
var styles_SidebarTabsComponent = [];
var RenderType_SidebarTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarTabsComponent, data: {} });
export { RenderType_SidebarTabsComponent as RenderType_SidebarTabsComponent };
function View_SidebarTabsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "sidebar-tabs2__item-error icon-warning_filled"]], null, null, null, null, null))], null, null); }
function View_SidebarTabsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar-tabs2__item"]], [[2, "sidebar-tabs2__item_active", null], [2, "sidebar-tabs2__item_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setVisibleTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpod(3, { side: 0 }), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarTabsComponent_3)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.isActive(_v.context.$implicit) ? i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_v.context.$implicit.error$)) : undefined); var currVal_3 = _ck(_v, 3, 0, "top"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_5 = (i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_v.context.$implicit.error$)) && !_co.isActive(_v.context.$implicit)); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive(_v.context.$implicit); var currVal_1 = i0.ɵunv(_v, 0, 1, i0.ɵnov(_v, 4).transform(_v.context.$implicit.disabled$)); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.context.$implicit.label$)); _ck(_v, 5, 0, currVal_4); }); }
function View_SidebarTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-tabs2-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "sidebar-tabs2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarTabsComponent_2)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SidebarTabsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarTabsComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTabsAlways || (_co.tabs.length > 1)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SidebarTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-sidebar-tabs", [], null, null, null, View_SidebarTabsComponent_0, RenderType_SidebarTabsComponent)), i0.ɵdid(1, 1294336, null, 1, i3.SidebarTabsComponent, [i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { tabComponents: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarTabsComponentNgFactory = i0.ɵccf("app-sidebar-tabs", i3.SidebarTabsComponent, View_SidebarTabsComponent_Host_0, { showTabsAlways: "showTabsAlways", initialTabIndex: "initialTabIndex" }, {}, ["*"]);
export { SidebarTabsComponentNgFactory as SidebarTabsComponentNgFactory };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ResourceName } from '@modules/projects';

import { Template, TemplateType } from '../../data/template';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(params = {}): Observable<Template[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL('templates/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Template().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(id: string): Observable<Template> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`templates/${id}/`);
        let headers = new HttpHeaders();
        const params = new HttpParams({
          fromObject: {
            all: '1'
          }
        });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: params });
      }),
      map(result => new Template().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getWithPredicate(type: TemplateType, predicate: (item: Template) => boolean): Observable<Template[]> {
    return this.get({ type: type }).pipe(
      map(result => {
        return result.filter(item => predicate(item));
      })
    );
  }

  getDetailWithPredicate(type: TemplateType, predicate: (item: Template) => boolean): Observable<Template> {
    return this.get({ type: type, all: '1' }).pipe(
      map(result => {
        return result.find(item => predicate(item));
      })
    );
  }

  getResourceDefaultModelDescription(resource: ResourceName): Observable<Template> {
    return this.getDetailWithPredicate(
      TemplateType.ResourceDefaultModelDescription,
      template => template.forResources.find(item => item.typeItem.name == resource) !== undefined
    );
  }

  getResourceModelDescriptionsTemplates(resource: ResourceName): Observable<Template[]> {
    return this.getWithPredicate(
      TemplateType.ResourceModelDescriptionsTemplate,
      template => template.forResources.find(item => item.typeItem.name == resource) !== undefined
    );
  }

  getDetailResourceModelDescriptionsTemplate(resource: ResourceName, name: string): Observable<Template> {
    return this.getDetailWithPredicate(
      TemplateType.ResourceModelDescriptionsTemplate,
      template =>
        template.forResources.find(item => item.typeItem.name == resource) !== undefined && template.uniqueName == name
    );
  }

  getResourceInitialModelDescriptions(resource: ResourceName): Observable<Template> {
    return this.getDetailWithPredicate(
      TemplateType.ResourceInitialModelDescriptions,
      template => template.forResources.find(item => item.typeItem.name == resource) !== undefined
    );
  }

  create(instance: Template, fields?: string[]): Observable<Template> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`templates/`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new Template().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(instance: Template, fields?: string[]): Observable<Template> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`templates/${instance.id}/`);
        const data = instance.serialize(fields);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, data, { headers: headers });
      }),
      map(result => new Template().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(instance: Template): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`templates/${instance.id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

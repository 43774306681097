import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

import { BaseField, createFormFieldFactory, FieldType, FormFieldSerialized, ParameterField } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

import { ImportDataField, JET_IMPORT_PK } from '../import-data/import-data.component';

export interface ImportDataFieldValue {
  field: ParameterField;
  fieldObj: FormFieldSerialized;
  value: any;
  strValue: any;
  stubValue: boolean;
}

@Component({
  selector: 'app-import-data-item, [app-import-data-item]',
  templateUrl: './import-data-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportDataItemComponent implements OnInit, OnChanges {
  @Input() row: Object;
  @Input() index: number;
  @Input() autoValueStub = false;
  @Input() fields: ImportDataField[] = [];

  createField = createFormFieldFactory();
  items: ImportDataFieldValue[] = [];
  fieldTypes = FieldType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ImportDataItemComponent>): void {
    if (changes.row || changes.fields) {
      this.updateItems();
    }
  }

  updateItems() {
    this.items = this.fields.map(dataField => {
      const autoValue = dataField.primaryKey && !isSet(dataField.control.controls.source.value);
      let value = autoValue ? this.index + 1 : this.row[dataField.control.controls.source.value];
      const field: BaseField = {
        name: dataField.field.name,
        field: dataField.field.field,
        params: dataField.field.params
      };

      if (isSet(value)) {
        if (dataField.fieldDescription.deserializeValue) {
          value = dataField.fieldDescription.deserializeValue(value, field);
        }
      } else {
        value = undefined;
      }

      const strValue = dataField.fieldDescription.valueToStr
        ? dataField.fieldDescription.valueToStr(value, { field: field })
        : undefined;

      return {
        field: dataField.field,
        fieldObj: dataField.field.serialize(),
        value: value,
        strValue: strValue,
        stubValue: this.autoValueStub && autoValue
      };
    });
    this.cd.markForCheck();
  }

  trackByFn(i, item: ImportDataFieldValue) {
    return item ? item.field.name : i;
  }
}

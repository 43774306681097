import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { createFormFieldFactory, FieldOutput, FieldType } from '@modules/fields';
import { SidebarCollapseContext } from '@modules/sidebar';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-customize-workflow-trigger-output',
  templateUrl: './customize-workflow-trigger-output.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeWorkflowTriggerOutputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: FieldOutput;
  @Input() value: any;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() openedInitial = false;
  @Input() autofocus = false;
  @Output() valueUpdated = new EventEmitter<any>();

  createField = createFormFieldFactory();
  opened = false;
  itemParams: Object;
  initCollapseContextSubscription: Subscription;
  placeholder = 'Test value';
  fieldTypes = FieldType;

  valueControl = new FormControl('');
  valueControlForm = new FormGroup({ control: this.valueControl });

  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.valueControl.valueChanges
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(value => this.valueUpdated.emit(value));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomizeWorkflowTriggerOutputComponent>): void {
    if (changes.collapseContext) {
      this.initCollapseContext();
    }

    if (changes.item) {
      this.itemParams = this.getItemParams();
    }

    if (changes.value) {
      this.valueControl.patchValue(this.value, { emitEvent: false });
    }
  }

  initCollapseContext() {
    if (this.initCollapseContextSubscription) {
      this.initCollapseContextSubscription.unsubscribe();
      this.initCollapseContextSubscription = undefined;
    }

    if (this.collapseContext) {
      this.initCollapseContextSubscription = this.collapseContext.opened$
        .pipe(untilDestroyed(this))
        .subscribe(opened => {
          this.opened = opened === this;
          this.cd.markForCheck();
        });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  setOpened(opened: boolean) {
    if (this.collapseContext) {
      if (opened && this.collapseContext.opened !== this) {
        this.collapseContext.opened = this;
      } else if (!opened && this.collapseContext.opened === this) {
        this.collapseContext.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  getItemParams(): Object {
    return {
      ...(this.item ? this.item.params : {}),
      classes: [
        'select_fill',
        'input_fill',
        ...(this.item && this.item.params['classes'] ? this.item.params['classes'] : [])
      ],
      background: true
    };
  }
}

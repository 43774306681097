/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../auto-menu-item/auto-menu-item.component.ngfactory";
import * as i3 from "../auto-menu-item/auto-menu-item.component";
import * as i4 from "../../../data/menu-context";
import * as i5 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i6 from "./section-menu-item.component";
import * as i7 from "../../../../menu/services/menu-generator/menu-generator.service";
var styles_SectionMenuItemComponent = [];
var RenderType_SectionMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionMenuItemComponent, data: {} });
export { RenderType_SectionMenuItemComponent as RenderType_SectionMenuItemComponent };
function View_SectionMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "menu2-section__icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu2-section__icon"; var currVal_1 = ("icon-" + _co.menuItem.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SectionMenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItem.title; _ck(_v, 1, 0, currVal_0); }); }
function View_SectionMenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No title"]))], null, null); }
function View_SectionMenuItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-menu-item", [], null, null, null, i2.View_AutoMenuItemComponent_0, i2.RenderType_AutoMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i3.AutoMenuItemComponent, [i4.MenuContext, i0.ChangeDetectorRef], { menuItem: [0, "menuItem"], menuBlock: [1, "menuBlock"], primary: [2, "primary"], horizontal: [3, "horizontal"], forceActive: [4, "forceActive"], forceOpened: [5, "forceOpened"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.menuBlock; var currVal_2 = _co.primary; var currVal_3 = _co.horizontal; var currVal_4 = _co.forceActive; var currVal_5 = _co.forceOpened; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_SectionMenuItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "menu2-list__item menu2-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "menu2-section__header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SectionMenuItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 4, "span", [["class", "menu2-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionMenuItemComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionMenuItemComponent_3)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "menu2-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionMenuItemComponent_4)), i0.ɵdid(13, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.menuItem.icon)); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.menuItem.title; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.menuItem.title; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.children; var currVal_4 = _co.trackMenuItemFn; _ck(_v, 13, 0, currVal_3, currVal_4); }, null); }
export function View_SectionMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section-menu-item", [], null, null, null, View_SectionMenuItemComponent_0, RenderType_SectionMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i6.SectionMenuItemComponent, [i7.MenuGeneratorService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionMenuItemComponentNgFactory = i0.ɵccf("app-section-menu-item", i6.SectionMenuItemComponent, View_SectionMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { SectionMenuItemComponentNgFactory as SectionMenuItemComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./element-wrapper.component";
var styles_ElementWrapperComponent = [];
var RenderType_ElementWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ElementWrapperComponent, data: {} });
export { RenderType_ElementWrapperComponent as RenderType_ElementWrapperComponent };
export function View_ElementWrapperComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "element-wrapper__inner"]], [[2, "element-align", null], [2, "element-align_horizontal_left", null], [2, "element-align_horizontal_center", null], [2, "element-align_horizontal_right", null], [2, "element-align_vertical_top", null], [2, "element-align_vertical_center", null], [2, "element-align_vertical_bottom", null]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alignHorizontal || _co.alignVertical); var currVal_1 = (_co.alignHorizontal == _co.alignsHorizontal.Left); var currVal_2 = (_co.alignHorizontal == _co.alignsHorizontal.Center); var currVal_3 = (_co.alignHorizontal == _co.alignsHorizontal.Right); var currVal_4 = (_co.alignVertical == _co.alignsVertical.Top); var currVal_5 = (_co.alignVertical == _co.alignsVertical.Center); var currVal_6 = (_co.alignVertical == _co.alignsVertical.Bottom); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ElementWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-element-wrapper", [], [[2, "element-wrapper", null], [2, "element-wrapper_wrap", null]], null, null, View_ElementWrapperComponent_0, RenderType_ElementWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i1.ElementWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).wrap; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var ElementWrapperComponentNgFactory = i0.ɵccf("app-element-wrapper", i1.ElementWrapperComponent, View_ElementWrapperComponent_Host_0, { wrap: "wrap", alignHorizontal: "alignHorizontal", alignVertical: "alignVertical" }, {}, ["*"]);
export { ElementWrapperComponentNgFactory as ElementWrapperComponentNgFactory };

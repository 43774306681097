import { Pipe, PipeTransform } from '@angular/core';

import { isInputSet } from '@modules/fields';

@Pipe({
  name: 'appIsFieldInputSet'
})
export class IsFieldInputSetPipe implements PipeTransform {
  transform(value: Object): boolean {
    return isInputSet(value);
  }
}

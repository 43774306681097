/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./layer-interaction.component";
import * as i2 from "../../../action-queries/services/action-controller/action-controller.service";
var styles_LayerInteractionComponent = [];
var RenderType_LayerInteractionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayerInteractionComponent, data: {} });
export { RenderType_LayerInteractionComponent as RenderType_LayerInteractionComponent };
export function View_LayerInteractionComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_LayerInteractionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layer-interaction", [], null, null, null, View_LayerInteractionComponent_0, RenderType_LayerInteractionComponent)), i0.ɵdid(1, 770048, null, 0, i1.LayerInteractionComponent, [i2.ActionControllerService, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayerInteractionComponentNgFactory = i0.ɵccf("app-layer-interaction", i1.LayerInteractionComponent, View_LayerInteractionComponent_Host_0, { interaction: "interaction", element: "element", context: "context", contextElement: "contextElement", analyticsSource: "analyticsSource" }, {}, []);
export { LayerInteractionComponentNgFactory as LayerInteractionComponentNgFactory };

<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup resource-popup_wide">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>

    <div class="resource-popup__content">
      <app-page-block *ngIf="form.form.value['show_instructions']">
        <app-install-instructions-django
          [project]="project"
          [environment]="environment"
          [token]="installToken"
        ></app-install-instructions-django>
      </app-page-block>

      <ng-container *ngIf="!form.form.value['show_instructions']">
        <app-page-block *ngIf="resourceNameEditing">
          <app-auto-field
            [form]="form.resourceForm"
            [field]="
              createField({
                name: 'name',
                label: 'Resource Name',
                field: 'CharField',
                description: 'This is how you and your team will see it',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <ng-container *ngIf="!isParamsStep()">
          <app-page-block>
            <p>
              Finish installation by opening in your browser (usually it opens automatically):<br />
              <strong>localhost</strong> is your <strong>Django</strong> HOST and <strong>8000</strong> is its PORT.
            </p>

            <app-code [language]="'bash'">http://localhost:8000/jet_api/register/</app-code>
          </app-page-block>

          <app-page-block>
            <app-auto-field
              [form]="form.form"
              [field]="
                createField({
                  name: 'jetbridge_manual',
                  label: 'or specify settings manually',
                  field: 'BooleanField',
                  params: { style: booleanFieldStyle.Toggle, orange: true }
                })
              "
            ></app-auto-field>
          </app-page-block>
        </ng-container>

        <div [class.page-disabled]="!isParamsStep() && !form.form.value['jetbridge_manual']">
          <app-page-block>
            <app-auto-field
              [form]="form.form"
              [field]="
                createField({
                  name: 'url',
                  label: 'API URL',
                  field: 'CharField',
                  description: 'Example: http://your_host_name/jet_api/',
                  placeholder: defaultPlaceholder,
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>

          <app-page-block>
            <app-auto-field
              [form]="form.form"
              [field]="
                createField({
                  name: 'token',
                  label: 'Token',
                  field: 'CharField',
                  description: 'Token you used to install Jet Bridge',
                  placeholder: defaultPlaceholder,
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>
        </div>
      </ng-container>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <app-alert
      *ngIf="slowLoading"
      [title]="'Import in progress'"
      [warning]="true"
      [animateShow]="true"
      class="popup-content__content-alert"
    >
      <p>
        It can take 2 minutes or more depending on the size of database
      </p>
    </app-alert>

    <div class="popup-content__submit" [class.popup-content__submit_no-border]="slowLoading">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>
      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>

      <ng-container *ngIf="form.form.value['show_instructions']">
        <button
          type="button"
          class="button button_primary popup-content__submit-item"
          (click)="form.form.patchValue({ show_instructions: false })"
        >
          <span class="button__label">I've installed Jet Django</span>
        </button>
      </ng-container>
      <ng-container *ngIf="!form.form.value['show_instructions']">
        <button
          type="button"
          class="button popup-content__submit-item"
          (click)="form.form.patchValue({ show_instructions: true })"
        >
          <span class="button__label">
            <ng-container *ngIf="resource">Show Instructions</ng-container>
            <ng-container *ngIf="!resource">Back to Instructions</ng-container>
          </span>
        </button>
        <button
          type="button"
          class="button popup-content__submit-item"
          [class.button_disabled]="!form.isValid() || testConnectionLoading || loadingSubmit"
          (click)="testConnection()"
        >
          <app-loader-small *ngIf="testConnectionLoading" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">Test Connection</span>
        </button>
        <button
          type="button"
          class="button button_primary popup-content__submit-item"
          [class.button_disabled]="!form.isValid() || loadingSubmit"
          (click)="submit()"
        >
          <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">
            <ng-container *ngIf="resource">Update resource</ng-container>
            <ng-container *ngIf="!resource">Add resource</ng-container>
          </span>
        </button>
      </ng-container>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem" [wide]="true"></app-resource-settings-stub>

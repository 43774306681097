import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { Margin } from '@modules/customize';
import { controlValue, isSet, KeyboardEventKeyCode } from '@shared';

enum Side {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

export class NumberControl extends FormControl {
  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    controlValue(this).subscribe(rawValue => {
      const cleanValue = this.cleanValue(rawValue);

      if (cleanValue !== rawValue) {
        this.setValue(cleanValue);
      }
    });
  }

  cleanValue(value: any) {
    if (value === undefined || value === null) {
      return value;
    }

    if (value === '') {
      return undefined;
    }

    value = parseInt(value, 10);

    if (isNaN(value)) {
      value = 0;
    } else if (typeof value !== 'number') {
      value = undefined;
    } else if (value < 0) {
      value = 0;
    }

    return value;
  }
}

export class MarginControl extends FormGroup {
  controls: {
    top: NumberControl;
    right: NumberControl;
    bottom: NumberControl;
    left: NumberControl;
  };

  constructor(value: Margin = {}) {
    super({
      top: new NumberControl(value.top),
      right: new NumberControl(value.right),
      bottom: new NumberControl(value.bottom),
      left: new NumberControl(value.left)
    });
  }
}

@Component({
  selector: 'app-margin-control',
  templateUrl: './margin-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarginControlComponent implements OnInit {
  @Input() control: MarginControl;

  focused: Side;
  sides = Side;

  constructor() {}

  ngOnInit() {}

  setFocused(focused: Side) {
    this.focused = focused;
  }

  processArrows(event: KeyboardEvent, control: NumberControl) {
    const charCode = event.which ? event.which : event.keyCode;
    const delta = event.shiftKey ? 10 : 1;

    if (charCode == KeyboardEventKeyCode.ArrowUp) {
      const cleanValue = control.cleanValue(control.value);
      control.setValue(isSet(cleanValue) ? cleanValue + delta : delta);
      event.preventDefault();

      return false;
    } else if (charCode == KeyboardEventKeyCode.ArrowDown) {
      const cleanValue = control.cleanValue(control.value);
      control.setValue(isSet(cleanValue) ? cleanValue - delta : 0);

      return false;
    }
  }

  validateKey(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode >= KeyboardEventKeyCode.Number0 && charCode <= KeyboardEventKeyCode.Number9) {
      return true;
    } else {
      return false;
    }
  }

  selectValue(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
  }
}

import { FieldType } from '@modules/fields';

export interface FieldComponents {
  type: string;
  component?: any;
  viewParamsComponent?: any;
  dataParamsComponent?: any;
}

const fieldComponents: FieldComponents[] = [];

export function getFieldComponentsByName(type: FieldType): FieldComponents {
  return fieldComponents.find(item => item.type == type);
}

export function registerFieldComponent(type: FieldType, component: any) {
  const existing = getFieldComponentsByName(type);

  if (existing) {
    existing.component = component;
  } else {
    fieldComponents.push({
      type: type,
      component: component
    });
  }
}

export function registerFieldViewParamsComponent(type: FieldType, paramsComponent: any) {
  const existing = getFieldComponentsByName(type);

  if (existing) {
    existing.viewParamsComponent = paramsComponent;
  } else {
    fieldComponents.push({
      type: type,
      viewParamsComponent: paramsComponent
    });
  }
}

export function registerFieldDataParamsComponent(type: FieldType, paramsComponent: any) {
  const existing = getFieldComponentsByName(type);

  if (existing) {
    existing.dataParamsComponent = paramsComponent;
  } else {
    fieldComponents.push({
      type: type,
      dataParamsComponent: paramsComponent
    });
  }
}

import { FormulaTokenFunction } from '../../data/formula-token-function';

export enum FormulaCategory {
  General = 'general',
  Math = 'math',
  Logic = 'logic',
  DateTime = 'datetime',
  Permissions = 'permissions'
}

export const viewContextTokenProviderFunctions: {
  description: string;
  function: FormulaTokenFunction;
  category: FormulaCategory;
}[] = [
  {
    category: FormulaCategory.General,
    description: 'Concatenation of two values',
    function: {
      name: 'CONCAT',
      arguments: [
        {
          name: 'value1',
          description: 'The value to which <arg>value2</arg> will be appended'
        },
        {
          name: 'value2',
          description: 'The value to append to <arg>value1</arg>',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns the concatenation of values',
      examples: [`CONCAT(item.width, 'px')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: `Inserts values into template's placeholders`,
    function: {
      name: 'FORMAT',
      arguments: [
        {
          name: 'template',
          description: 'Template with placeholders for <arg>arguments</arg>'
        },
        {
          name: 'argument1',
          description: 'The value to insert into <arg>template</arg> placeholder',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns string with arguments inserted into template placeholders',
      examples: [`FORMAT('{0} of {1}', item.number, data.total)`, `FORMAT('{} of {}', item.number, data.total)`]
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Returns value depending on logical expression',
    function: {
      name: 'IF',
      arguments: [
        {
          name: 'logical_expression',
          description:
            'An expression or variable containing an expression that represents some logical value, i.e. <quote>TRUE</quote> or <quote>FALSE</quote>, or an expression that can be coerced to a logical value'
        },
        {
          name: 'value_if_true',
          description: 'The value the function returns if logical_expression is TRUE'
        },
        {
          name: 'value_if_false',
          description: 'The value the function returns if logical_expression is FALSE'
        }
      ],
      result: 'Returns one value if a logical expression is TRUE and another if it is FALSE',
      examples: [`IF(item.approved, 'Approved', 'Not approved')`]
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Equal',
    function: {
      name: 'EQ',
      arguments: [
        {
          name: 'value1',
          description: 'The first value'
        },
        {
          name: 'value2',
          description: 'The value to test against value1 for equality'
        }
      ],
      result: 'Returns TRUE if two specified values are equal and FALSE otherwise',
      examples: ['EQ(1, 2)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'If dataset contains value',
    function: {
      name: 'CONTAINS',
      arguments: [
        {
          name: 'object',
          description: 'array, object or string'
        },
        {
          name: 'value',
          description: 'The value to test if contained'
        },
        {
          name: 'caseInsensitive',
          description: 'Where comparison should be case insensitive',
          optional: true
        }
      ],
      result: 'Returns true if object contains value',
      examples: [`CONTAINS(item.tags, 'new')`, `CONTAINS(item.name, 'John')`, `CONTAINS(item.name, 'john', true)`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'The number of values in dataset',
    function: {
      name: 'SIZE',
      arguments: [
        {
          name: 'value',
          description: 'Dataset (array) of values'
        }
      ],
      result: 'Returns the number of values in a dataset',
      examples: ['SIZE(item.apartments)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Formats number',
    function: {
      name: 'NUMBER_FORMAT',
      arguments: [
        {
          name: 'value',
          description: 'Number value'
        },
        {
          name: 'format',
          description: 'metric_prefix, currency, percentage, order, file_size or exponential',
          optional: true
        },
        {
          name: 'fraction',
          description: 'Max fractional part, default is 2',
          optional: true
        }
      ],
      result: 'Returns string representation of number with the format',
      examples: [
        `NUMBER_FORMAT(item.amount, 'metric_prefix')`,
        `NUMBER_FORMAT(item.price, 'currency', 2)`,
        `NUMBER_FORMAT(item.complete, 'percentage')`,
        `NUMBER_FORMAT(item.order, 'order')`,
        `NUMBER_FORMAT(item.size, 'file_size', 2)`,
        `NUMBER_FORMAT(item.amplitude, 'exponential', 4)`
      ]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Is value empty',
    function: {
      name: 'EMPTY',
      arguments: [
        {
          name: 'value',
          description: 'Dataset (array) of values or string'
        }
      ],
      result: 'Returns if the value is empty',
      examples: ['EMPTY(item.apartments)']
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Logical AND operator',
    function: {
      name: 'AND',
      arguments: [
        {
          name: 'logical_expression1',
          description:
            'An expression or variable containing an expression that represents some logical value, i.e. <quote>TRUE</quote> or <quote>FALSE</quote>, or an expression that can be coerced to a logical value'
        },
        {
          name: 'logical_expression2',
          description: 'More expressions that represent logical values'
        }
      ],
      result:
        'Returns true if all of the provided arguments are logically true, and false if any of the provided arguments are logically false',
      examples: ['AND(item.approved, NOT(item.processed))']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Rounds a number according to standard rules',
    function: {
      name: 'ROUND',
      arguments: [
        {
          name: 'value',
          description: 'The value to round to <arg>places</arg> number of places'
        },
        {
          name: 'places',
          description: 'The number of decimal places to which to round',
          optional: true
        }
      ],
      result: 'Rounds a number to a certain number of decimal places according to standard rules',
      examples: ['ROUND(2.35)', 'ROUND(2.35, 1)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Rounds number up to nearest multiple of a factor',
    function: {
      name: 'CEIL',
      arguments: [
        {
          name: 'value',
          description: 'The value to round up to the nearest integer multiple of <arg>factor</arg>'
        },
        {
          name: 'factor',
          description: 'The number to whose multiples <arg>value</arg> will be rounded',
          optional: true
        }
      ],
      result: 'Rounds a number up to the nearest integer multiple of specified significance <arg>factor</arg>',
      examples: ['CEIL(2.26)', 'CEIL(2.26, 0.01)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Rounds number down to nearest multiple of a factor',
    function: {
      name: 'FLOOR',
      arguments: [
        {
          name: 'value',
          description: 'The value to round down to the nearest integer multiple of <arg>factor</arg>'
        },
        {
          name: 'factor',
          description: 'The number to whose multiples <arg>value</arg> will be rounded',
          optional: true
        }
      ],
      result: 'Rounds a number down to the nearest integer multiple of specified significance <arg>factor</arg>',
      examples: ['FLOOR(2.56)', 'FLOOR(2.56, 0.1)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'A number raised to a power',
    function: {
      name: 'POW',
      arguments: [
        {
          name: 'base',
          description: 'The number to raise to the <arg>exponent</arg> power'
        },
        {
          name: 'exponent',
          description: 'The exponent to raise <arg>base</arg> to'
        }
      ],
      result: 'Returns a number raised to a power',
      examples: ['POW(2, item.length)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'The logarithm of a number with respect to a base',
    function: {
      name: 'LOG',
      arguments: [
        {
          name: 'value',
          description: 'The value for which to calculate the logarithm'
        },
        {
          name: 'base',
          description: 'The base to use for calculation of the logarithm, default is 10',
          optional: true
        }
      ],
      result: 'Returns the logarithm of a number with respect to a base',
      examples: ['LOG(8, 2)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: `Euler's number, e (~2.718) raised to a power`,
    function: {
      name: 'EXP',
      arguments: [
        {
          name: 'exponent',
          description: 'The exponent to raise e to'
        }
      ],
      result: `Returns Euler's number, e (~2.718) raised to a power`,
      examples: ['EXP(8)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Absolute value of a number',
    function: {
      name: 'ABS',
      arguments: [
        {
          name: 'value',
          description: 'The number of which to return the absolute value'
        }
      ],
      result: 'Returns the absolute value of a number',
      examples: ['ABS(-3)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Positive square root of a positive number',
    function: {
      name: 'SQRT',
      arguments: [
        {
          name: 'value',
          description: 'The number for which to calculate the positive square root'
        }
      ],
      result: 'Returns the positive square root of a positive number',
      examples: ['SQRT(16)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Formats number with fixed number of decimal places',
    function: {
      name: 'FIX',
      arguments: [
        {
          name: 'number',
          description: 'The number to format'
        },
        {
          name: 'places',
          description: 'The number of decimal places to display in the result'
        }
      ],
      result: 'Formats a number with a fixed number of decimal places',
      examples: ['FIX(3.14159265, 2)']
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Returns opposite of provided logical value',
    function: {
      name: 'NOT',
      arguments: [
        {
          name: 'logical_expression',
          description: 'An expression or variable holding an expression that represents some logical value'
        }
      ],
      result:
        'Returns the opposite of a logical value - <quote>NOT(TRUE)</quote> returns <quote>FALSE</quote>; <quote>NOT(FALSE)</quote> returns <quote>TRUE</quote>',
      examples: ['NOT(item.processed)']
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Logical OR operator',
    function: {
      name: 'OR',
      arguments: [
        {
          name: 'logical_expression1',
          description:
            'An expression or variable containing an expression that represents some logical value, i.e. <quote>TRUE</quote> or <quote>FALSE</quote>, or an expression that can be coerced to a logical value'
        },
        {
          name: 'logical_expression2',
          description: 'More expressions that evaluate to logical values'
        }
      ],
      result:
        'Returns true if any of the provided arguments are logically true, and false if all of the provided arguments are logically false',
      examples: ['OR(item.approved, item.processed)']
    }
  },
  {
    category: FormulaCategory.Logic,
    description: 'Logical XOR operator',
    function: {
      name: 'XOR',
      arguments: [
        {
          name: 'logical_expression1',
          description:
            'An expression or variable containing an expression that represents some logical value, i.e. <quote>TRUE</quote> or <quote>FALSE</quote>, or an expression that can be coerced to a logical value'
        },
        {
          name: 'logical_expression2',
          description: 'More expressions that represent logical values'
        }
      ],
      result:
        'Returns true if an odd number of the provided arguments are logically true, and false if an even number of the arguments are logically true',
      examples: ['XOR(item.approved, item.processed)']
    }
  },
  // {
  //   description: 'Sorts dataset by specified column',
  //   function: {
  //     name: 'SORT',
  //     arguments: [
  //       {
  //         name: 'value',
  //         description: 'The dataset to be sorted'
  //       }
  //     ],
  //     result: 'Sorts the dataset (array) ascending',
  //     examples: ['SORT(4, 3, 1, 5)']
  //   }
  // },
  {
    category: FormulaCategory.Math,
    description: 'Minimum value in a numeric dataset',
    function: {
      name: 'MIN',
      arguments: [
        {
          name: 'value1',
          description: 'The first value or range to consider when calculating the minimum value'
        },
        {
          name: 'value2',
          description: 'Additional values or ranges to consider when calculating the minimum value',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns the minimum value in a numeric dataset',
      examples: ['MIN(item.discount, 0.15)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Maximum value in a numeric dataset',
    function: {
      name: 'MAX',
      arguments: [
        {
          name: 'value1',
          description: 'The first value or range to consider when calculating the maximum value'
        },
        {
          name: 'value2',
          description: 'Additional values or ranges to consider when calculating the maximum value',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns the maximum value in a numeric dataset',
      examples: ['MAX(item.events, 9999)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Numerical average value in a dataset, ignoring text',
    function: {
      name: 'AVERAGE',
      arguments: [
        {
          name: 'value1',
          description: 'The first value or range to consider when calculating the average value'
        },
        {
          name: 'value2',
          description: 'Additional values or ranges to consider when calculating the average value',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns the numerical average value in a dataset, ignoring text',
      examples: ['AVERAGE(item.morning, item.afternoon, item.evening)']
    }
  },
  {
    category: FormulaCategory.Math,
    description: 'Sum of numbers',
    function: {
      name: 'SUM',
      arguments: [
        {
          name: 'value1',
          description: 'The first number to add together'
        },
        {
          name: 'value2',
          description: 'Additional numbers to add to <arg>value1</arg>',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns the sum of numbers',
      examples: ['SUM(item.sold, item.reserved)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Get object property',
    function: {
      name: 'GET',
      arguments: [
        {
          name: 'object',
          description: 'Object with properties'
        },
        {
          name: 'property',
          description: 'Property name you want to extract'
        },
        {
          name: 'default_value',
          description: 'Value that will be returned if property not found'
        }
      ],
      result: 'Returns <arg>property</arg> from <arg>object</arg>',
      examples: [`GET(record, 'first_name', 'no name')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'First set value',
    function: {
      name: 'ANY',
      arguments: [
        {
          name: 'value1',
          description: 'The first value to return from'
        },
        {
          name: 'value2',
          description: 'Additional values to return from',
          optional: true,
          repeatable: true
        }
      ],
      result: 'Returns first value which is not empty, null or undefined',
      examples: ['ANY(item.title1, item.title2)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Transform to string',
    function: {
      name: 'STRING',
      arguments: [
        {
          name: 'value',
          description: 'Any value that need to be transformed to string'
        }
      ],
      result: 'Returns <arg>value</arg> as string',
      examples: ['STRING(12.34)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Transform to number',
    function: {
      name: 'NUMBER',
      arguments: [
        {
          name: 'value',
          description: 'Any value that need to be transformed to number'
        }
      ],
      result: 'Returns <arg>value</arg> as number',
      examples: [`NUMBER('12.34')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Parse JSON string',
    function: {
      name: 'JSON',
      arguments: [
        {
          name: 'value',
          description: 'JSON formatted string'
        }
      ],
      result: 'Returns JSON string <arg>value</arg> as object to extract data',
      examples: [`JSON(item.data)`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Is NULL value',
    function: {
      name: 'IS_NULL',
      arguments: [
        {
          name: 'value',
          description: 'Any value type'
        }
      ],
      result: 'Returns if the value is NULL',
      examples: ['IS_NULL(item.address)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Generate unique identifier',
    function: {
      name: 'UUID',
      arguments: [],
      result: 'Returns a random UUID unique identifier',
      examples: ['UUID()']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Generate unique hash',
    function: {
      name: 'HASH',
      arguments: [
        {
          name: 'length',
          description: 'The length of the hash'
        }
      ],
      result: 'Returns a random hash with the specified length',
      examples: ['HASH(32)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Random integer between two values, inclusive',
    function: {
      name: 'RANDOM',
      arguments: [
        {
          name: 'low',
          description: 'The low end of the random range'
        },
        {
          name: 'high',
          description: 'The high end of the random range'
        }
      ],
      result: 'Returns a uniformly random integer between two values, inclusive',
      examples: ['RANDOM(1, 10)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Maps dataset using function',
    function: {
      name: 'MAP',
      arguments: [
        {
          name: 'value',
          description: 'The dataset to be mapped'
        },
        {
          name: 'func',
          description: 'Function for mapping 1 item'
        }
      ],
      result: 'Mapped dataset (array) using provided function',
      examples: ['func(x) = { "foo": x }; MAP([4, 3, 1, 5], func)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Filters dataset using function',
    function: {
      name: 'FILTER',
      arguments: [
        {
          name: 'value',
          description: 'The dataset to be filtered'
        },
        {
          name: 'func',
          description: 'Function for filtering 1 item'
        }
      ],
      result: 'Filtered dataset (array) using provided function',
      examples: ['func(x) = x >= 2; FILTER([4, 3, 1, 5], func)']
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Coverts string to uppercase',
    function: {
      name: 'UPPER',
      arguments: [
        {
          name: 'value',
          description: 'A string that need to be converted to uppercase'
        }
      ],
      result: 'Returns <arg>value</arg> in uppercase',
      examples: [`UPPER('English')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Coverts string to lowercase',
    function: {
      name: 'LOWER',
      arguments: [
        {
          name: 'value',
          description: 'A string that need to be converted to lowercase'
        }
      ],
      result: 'Returns <arg>value</arg> in lowercase',
      examples: [`LOWER('English')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Coverts string to titlecase',
    function: {
      name: 'TITLE_CASE',
      arguments: [
        {
          name: 'value',
          description: 'A string that need to be converted to titlecase'
        }
      ],
      result: 'Returns <arg>value</arg> in titlecase',
      examples: [`TITLE_CASE('English')`]
    }
  },
  {
    category: FormulaCategory.General,
    description: 'Create a slug',
    function: {
      name: 'SLUGIFY',
      arguments: [
        {
          name: 'value',
          description: 'Any value that need to be transformed to slug'
        },
        {
          name: 'separator',
          description: 'symbol which separates words',
          optional: true
        }
      ],
      result: 'Returns <arg>value</arg> as human-readable keywords',
      examples: [`SLUGIFY('Top 10 places to visit')`, `SLUGIFY('Top 10 places to visit', '-')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Transforms value to Date',
    function: {
      name: 'DATE',
      arguments: [
        {
          name: 'value',
          description: 'Date string'
        },
        {
          name: 'format',
          description: '',
          optional: true
        }
      ],
      result: 'Parses date string representation',
      examples: ['DATE(item.date_str)', `DATE(item.date_str, 'DD.MM.YYYY')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Transforms value to Time',
    function: {
      name: 'TIME',
      arguments: [
        {
          name: 'value',
          description: 'Time string'
        },
        {
          name: 'format',
          description: '',
          optional: true
        }
      ],
      result: 'Parses time string representation',
      examples: ['TIME(item.time_str)', `TIME(item.date_str, 'HH:ii:ss')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Current time and date',
    function: {
      name: 'NOW',
      arguments: [],
      result: 'Returns the current time and date',
      examples: ['NOW()']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Day',
    function: {
      name: 'DAY',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the day part of the Date value',
      examples: ['DAY(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Day of week',
    function: {
      name: 'WEEKDAY',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the day of week part of the Date value',
      examples: ['WEEKDAY(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Month',
    function: {
      name: 'MONTH',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the month part of the Date value',
      examples: ['MONTH(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Year',
    function: {
      name: 'YEAR',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the year part of the Date value',
      examples: ['YEAR(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Hour',
    function: {
      name: 'HOUR',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the hour part of the Date value',
      examples: ['HOUR(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Minute',
    function: {
      name: 'MINUTE',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the minute part of the Date value',
      examples: ['MINUTE(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Second',
    function: {
      name: 'SECOND',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the second part of the Date value',
      examples: ['SECOND(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Millisecond',
    function: {
      name: 'MILLISECOND',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        }
      ],
      result: 'Returns the millisecond part of the Date value',
      examples: ['MILLISECOND(item.date_created)']
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is date after another',
    function: {
      name: 'IS_DATE_AFTER',
      arguments: [
        {
          name: 'date1',
          description: 'Date value'
        },
        {
          name: 'date2',
          description: 'Date value'
        },
        {
          name: 'granularity',
          description: 'day, month, year, hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if date1 is after date2',
      examples: ['IS_DATE_AFTER(item.date_expire, NOW())', `IS_DATE_AFTER(item.date_expire, NOW(), 'day')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is date before another',
    function: {
      name: 'IS_DATE_BEFORE',
      arguments: [
        {
          name: 'date1',
          description: 'Date value'
        },
        {
          name: 'date2',
          description: 'Date value'
        },
        {
          name: 'granularity',
          description: 'day, month, year, hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if date1 is before date2',
      examples: ['IS_DATE_BEFORE(item.date_expire, NOW())', `IS_DATE_BEFORE(item.date_expire, NOW(), 'day')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is date the same as another',
    function: {
      name: 'IS_DATE_SAME',
      arguments: [
        {
          name: 'date1',
          description: 'Date value'
        },
        {
          name: 'date2',
          description: 'Date value'
        },
        {
          name: 'granularity',
          description: 'day, month, year, hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if date1 is the same as date2',
      examples: ['IS_DATE_SAME(item.date_expire, NOW())', `IS_DATE_SAME(item.date_expire, NOW(), 'day')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Formats date',
    function: {
      name: 'DATE_FORMAT',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        },
        {
          name: 'format',
          description: ''
        }
      ],
      result: 'Returns string representation of date with the format',
      examples: [
        `DATE_FORMAT(item.date_created, 'YYYY MM DD')`,
        `DATE_FORMAT(item.date_created, 'dddd, MMMM Do YYYY, h:mm:ss a')`,
        `DATE_FORMAT(item.date_created, 'ddd, hA')`,
        `DATE_FORMAT(item.date_created, '[Today is] dddd')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Adds time interval to date',
    function: {
      name: 'DATE_ADD',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        },
        {
          name: 'amount',
          description: ''
        },
        {
          name: 'unit',
          description: 'day, month, year, hour, minute or second'
        }
      ],
      result: 'Returns date with added time interval',
      examples: [
        `DATE_ADD(item.date_created, 3, 'day')`,
        `DATE_ADD(item.date_created, 3, 'days')`,
        `DATE_ADD(item.date_created, -4, 'month')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Subtracts time interval from date',
    function: {
      name: 'DATE_SUBTRACT',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        },
        {
          name: 'amount',
          description: ''
        },
        {
          name: 'unit',
          description: 'day, month, year, hour, minute or second'
        }
      ],
      result: 'Returns date with subtracted time interval',
      examples: [
        `DATE_SUBTRACT(item.date_created, 3, 'day')`,
        `DATE_SUBTRACT(item.date_created, 3, 'days')`,
        `DATE_SUBTRACT(item.date_created, -4, 'month')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Difference between two dates',
    function: {
      name: 'DATE_DIFF',
      arguments: [
        {
          name: 'date1',
          description: 'Date value'
        },
        {
          name: 'date2',
          description: 'Date value'
        },
        {
          name: 'unit',
          description: 'day, month, year, hour, minute, second or milliseconds, default is milliseconds',
          optional: true
        },
        {
          name: 'decimal',
          description: 'Should return decimal or just integer',
          optional: true
        }
      ],
      result: 'Returns difference between date2 and date1 of specified date unit',
      examples: [`DATE_DIFF(NOW(), item.date_expire, 'days')`, `DATE_DIFF(NOW(), item.date_expire, 'days', true)`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Start of date unit',
    function: {
      name: 'DATE_START_OF',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        },
        {
          name: 'unit',
          description: 'day, month, year, hour, minute or second'
        }
      ],
      result: 'Returns the start of specified date unit',
      examples: [
        `DATE_START_OF(item.date_created, 'day')`,
        `DATE_START_OF(item.date_created, 'days')`,
        `DATE_START_OF(item.date_created, 'month')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'End of date unit',
    function: {
      name: 'DATE_END_OF',
      arguments: [
        {
          name: 'value',
          description: 'Date value'
        },
        {
          name: 'unit',
          description: 'day, month, year, hour, minute or second'
        }
      ],
      result: 'Returns the end of specified date unit',
      examples: [
        `DATE_END_OF(item.date_created, 'day')`,
        `DATE_END_OF(item.date_created, 'days')`,
        `DATE_END_OF(item.date_created, 'month')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is time after another',
    function: {
      name: 'IS_TIME_AFTER',
      arguments: [
        {
          name: 'time1',
          description: 'Time value'
        },
        {
          name: 'time2',
          description: 'Time value'
        },
        {
          name: 'granularity',
          description: 'hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if time1 is after time2',
      examples: [`IS_TIME_AFTER(item.time_expire, NOW(), 'hour')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is time before another',
    function: {
      name: 'IS_TIME_BEFORE',
      arguments: [
        {
          name: 'time1',
          description: 'Time value'
        },
        {
          name: 'time2',
          description: 'Time value'
        },
        {
          name: 'granularity',
          description: 'hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if time1 is before time2',
      examples: [`IS_TIME_BEFORE(item.time_expire, NOW(), 'hour')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Is time same as another',
    function: {
      name: 'IS_TIME_SAME',
      arguments: [
        {
          name: 'time1',
          description: 'Time value'
        },
        {
          name: 'time2',
          description: 'Time value'
        },
        {
          name: 'granularity',
          description: 'hour, minute or second',
          optional: true
        }
      ],
      result: 'Checks if time1 is the same as time2',
      examples: [`IS_TIME_SAME(item.time_expire, NOW(), 'hour')`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Formats time',
    function: {
      name: 'TIME_FORMAT',
      arguments: [
        {
          name: 'value',
          description: 'Time value'
        },
        {
          name: 'format',
          description: ''
        }
      ],
      result: 'Returns string representation of time with the format',
      examples: [`TIME_FORMAT(item.time_created, 'h:mm:ss a')`, `TIME_FORMAT(item.time_created, '[Now is] HH:mm`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Adds time interval to time',
    function: {
      name: 'TIME_ADD',
      arguments: [
        {
          name: 'value',
          description: 'Time value'
        },
        {
          name: 'amount',
          description: ''
        },
        {
          name: 'unit',
          description: 'hour, minute or second'
        }
      ],
      result: 'Returns time with added time interval',
      examples: [
        `TIME_ADD(item.time_created, 3, 'hour')`,
        `TIME_ADD(item.time_created, 3, 'hours')`,
        `TIME_ADD(item.time_created, -4, 'minutes')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Subtracts time interval to time',
    function: {
      name: 'TIME_SUBTRACT',
      arguments: [
        {
          name: 'value',
          description: 'Time value'
        },
        {
          name: 'amount',
          description: ''
        },
        {
          name: 'unit',
          description: 'hour, minute or second'
        }
      ],
      result: 'Returns time with subtracted time interval',
      examples: [
        `TIME_SUBTRACT(item.time_created, 3, 'hour')`,
        `TIME_SUBTRACT(item.time_created, 3, 'hours')`,
        `TIME_SUBTRACT(item.time_created, -4, 'minutes')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Difference between two times',
    function: {
      name: 'TIME_DIFF',
      arguments: [
        {
          name: 'time1',
          description: 'Time value'
        },
        {
          name: 'time2',
          description: 'Time value'
        },
        {
          name: 'unit',
          description: 'hour, minute or second or milliseconds, default is milliseconds',
          optional: true
        },
        {
          name: 'decimal',
          description: 'Should return decimal or just integer',
          optional: true
        }
      ],
      result: 'Returns difference between time2 and time1 of specified time unit',
      examples: [`TIME_DIFF(NOW(), item.time_created, 'hour')`, `TIME_DIFF(NOW(), item.time_created, 'hour', true)`]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'Start of time unit',
    function: {
      name: 'TIME_START_OF',
      arguments: [
        {
          name: 'value',
          description: 'Time value'
        },
        {
          name: 'unit',
          description: 'hour, minute or second'
        }
      ],
      result: 'Returns the start of specified time unit',
      examples: [
        `TIME_START_OF(item.time_created, 'hour')`,
        `TIME_START_OF(item.time_created, 'hours')`,
        `TIME_START_OF(item.time_created, 'minutes')`
      ]
    }
  },
  {
    category: FormulaCategory.DateTime,
    description: 'End of time unit',
    function: {
      name: 'TIME_END_OF',
      arguments: [
        {
          name: 'value',
          description: 'Time value'
        },
        {
          name: 'unit',
          description: 'hour, minute or second'
        }
      ],
      result: 'Returns the end of specified time unit',
      examples: [
        `TIME_END_OF(item.time_created, 'hour')`,
        `TIME_END_OF(item.time_created, 'hours')`,
        `TIME_END_OF(item.time_created, 'minute')`
      ]
    }
  },
  {
    category: FormulaCategory.Permissions,
    description: 'If user has project permission',
    function: {
      name: 'HAS_PERMISSION',
      arguments: [
        {
          name: 'jet',
          description: 'Jet global variable'
        },
        {
          name: 'permission',
          description: 'project_settings, project_billing, project_access or project_customization'
        }
      ],
      result: 'Returns true if current user has specified project permission, false otherwise',
      examples: [`HAS_PERMISSION(jet, 'project_access')`]
    }
  },
  {
    category: FormulaCategory.Permissions,
    description: 'If user has collection permission',
    function: {
      name: 'HAS_COLLECTION_PERMISSION',
      arguments: [
        {
          name: 'jet',
          description: 'Jet global variable'
        },
        {
          name: 'collectionId',
          description: 'users or firebase_f73g.users'
        },
        {
          name: 'action',
          description: 'r (read), w (write) or d (delete)'
        }
      ],
      result: 'Returns true if current user has specified collection permission, false otherwise',
      examples: [
        `HAS_COLLECTION_PERMISSION(jet, 'users', 'r')`,
        `HAS_COLLECTION_PERMISSION(jet, 'firebase_f73g.users', 'r')`
      ]
    }
  },
  {
    category: FormulaCategory.Permissions,
    description: 'If user has page permission',
    function: {
      name: 'HAS_PAGE_PERMISSION',
      arguments: [
        {
          name: 'jet',
          description: 'Jet global variable'
        },
        {
          name: 'action',
          description: 'r (read), w (write) or d (delete)'
        },
        {
          name: 'pageUid',
          description: 'page UID, default is current page',
          optional: true
        }
      ],
      result: 'Returns true if current user has specified page permission, false otherwise',
      examples: [
        `HAS_PAGE_PERMISSION(jet, 'w')`,
        `HAS_PAGE_PERMISSION(jet, 'r', '5d12732a-14d2-4335-965a-19ea718dcfaa')`
      ]
    }
  }
];

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MouseButton, pointsDistance } from '@shared';

@Directive({
  selector: '[appGradientStopPosition]',
  exportAs: 'appGradientStopPosition'
})
export class GradientStopPositionDirective implements OnInit, OnDestroy {
  @Input() gradientStopBounds: HTMLElement;
  @Output() gradientStopMouseDown = new EventEmitter<MouseEvent>();
  @Output() gradientStopSetPosition = new EventEmitter<number>();

  moveThreshold = 5;
  dragging$ = new BehaviorSubject<boolean>(false);
  subscriptions: Subscription[] = [];

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main),
        untilDestroyed(this)
      )
      .subscribe(e => this.dragStart(e));
  }

  ngOnDestroy(): void {}

  dragStart(downEvent: MouseEvent) {
    downEvent.preventDefault();
    downEvent.stopPropagation();

    const subscriptions = [];

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();

          if (!this.dragging$.value) {
            const originDistance = pointsDistance(
              downEvent.clientX,
              downEvent.clientY,
              moveEvent.clientX,
              moveEvent.clientY
            );
            if (originDistance >= this.moveThreshold) {
              this.dragging$.next(true);
            }
          }

          if (this.dragging$.value) {
            this.dragMove(moveEvent);
          }
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(e => this.dragFinish(e))
    );

    this.subscriptions = subscriptions;

    this.gradientStopMouseDown.emit(downEvent);
  }

  dragMove(e: MouseEvent) {
    const bounds = this.gradientStopBounds.getBoundingClientRect();
    const position = clamp(round((e.clientX - bounds.left) / bounds.width, 2), 0, 1);

    this.gradientStopSetPosition.emit(position);
  }

  dragFinish(e: MouseEvent) {
    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];

    this.dragging$.next(false);
  }
}

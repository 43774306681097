var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { filter, map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { HttpQueryService, QueryType } from '@modules/queries';
import { StorageObjectsResponse } from '@modules/storages';
import { isSet } from '@shared';
import { ResourceController, StorageBucketsResponse } from '../../data/resource-controller';
var AmazonS3ResourceController = /** @class */ (function (_super) {
    __extends(AmazonS3ResourceController, _super);
    function AmazonS3ResourceController() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AmazonS3ResourceController.prototype.init = function () {
        this.http = this.initService(HttpClient);
        this.httpQueryService = this.initService(HttpQueryService);
        this.apiService = this.initService(ApiService);
        this.projectApiService = this.initService(ProjectApiService);
    };
    AmazonS3ResourceController.prototype.getBuckets = function (accessKeyId, secretAccessKey, endpoint) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, 's3/get_buckets');
            var headers = new HttpHeaders();
            var data = {
                access_key_id: accessKeyId,
                secret_access_key: secretAccessKey,
                endpoint: endpoint
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new StorageBucketsResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AmazonS3ResourceController.prototype.supportedQueryTypes = function (queryClass) {
        return [QueryType.Simple, QueryType.Http];
    };
    AmazonS3ResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, resource.uniqueName + "/storages/" + storage.uniqueName + "/upload_file", true);
            var params = __assign({}, (window['mode'] == AdminMode.Builder && {
                draft: '1'
            }));
            var headers = new HttpHeaders();
            var data = new FormData();
            if (isSet(fileName)) {
                file = new File([file], fileName, { type: file.type });
            }
            if (isSet(path)) {
                data.set('path', path);
            }
            data.set('file', file);
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http
                .post(url, data, {
                params: params,
                headers: headers,
                observe: 'events',
                reportProgress: true
            })
                .pipe(map(function (event) {
                if (event.type == HttpEventType.Response) {
                    return __assign({}, event.body, { result: __assign({}, event.body.result, { response: event }) });
                }
                else if (event.type == HttpEventType.UploadProgress) {
                    return {
                        state: {
                            uploadProgress: event.loaded / event.total,
                            downloadProgress: 0,
                            uploadLoaded: event.loaded,
                            uploadTotal: event.total
                        }
                    };
                }
                else if (event.type == HttpEventType.DownloadProgress) {
                    return {
                        state: {
                            uploadProgress: 1,
                            downloadProgress: event.loaded / event.total,
                            downloadLoaded: event.loaded,
                            downloadTotal: event.total
                        }
                    };
                }
            }), filter(function (item) { return item !== undefined; }));
        }), this.apiService.catchApiError());
    };
    AmazonS3ResourceController.prototype.getStorageObjects = function (resource, storage, query, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, resource.uniqueName + "/storages/" + storage.uniqueName + "/list_objects");
            var params = __assign({}, (window['mode'] == AdminMode.Builder && {
                draft: '1'
            }));
            var headers = new HttpHeaders();
            var data = {
                path: path
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { params: params, headers: headers });
        }), map(function (result) { return new StorageObjectsResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AmazonS3ResourceController.prototype.deleteStorageObject = function (resource, storage, query, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, resource.uniqueName + "/storages/" + storage.uniqueName + "/delete_object");
            var params = __assign({}, (window['mode'] == AdminMode.Builder && {
                draft: '1'
            }));
            var headers = new HttpHeaders();
            var data = {
                path: path
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.request('DELETE', url, { params: params, headers: headers, body: data });
        }), map(function (result) { return result.result; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AmazonS3ResourceController.prototype.createStorageFolder = function (resource, storage, query, path) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, resource.uniqueName + "/storages/" + storage.uniqueName + "/create_folder");
            var params = __assign({}, (window['mode'] == AdminMode.Builder && {
                draft: '1'
            }));
            var headers = new HttpHeaders();
            var data = {
                path: path
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { params: params, headers: headers });
        }), map(function (result) { return result.result; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AmazonS3ResourceController.prototype.getObjectUrl = function (resource, storage, query, path, expiresInSec) {
        var _this = this;
        return this.projectApiService.refreshToken().pipe(switchMap(function () {
            var project = window['project'];
            var environment = window['project_environment'];
            var url = _this.apiService.dataSourcesEnvironmentMethodURL(project, environment, resource.uniqueName + "/storages/" + storage.uniqueName + "/get_object_url");
            var params = __assign({}, (window['mode'] == AdminMode.Builder && {
                draft: '1'
            }));
            var headers = new HttpHeaders();
            var data = {
                path: path,
                expires: expiresInSec
            };
            headers = _this.projectApiService.setHeadersToken(headers);
            return _this.http.post(url, data, { params: params, headers: headers });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return AmazonS3ResourceController;
}(ResourceController));
export { AmazonS3ResourceController };

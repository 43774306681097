var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// TODO: Refactor import
import { getElementByType } from '../../../customize/data/elements/element-items';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
var ElementLayer = /** @class */ (function (_super) {
    __extends(ElementLayer, _super);
    function ElementLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Element;
        _this.icon = 'components_2';
        _this.defaultName = 'component';
        return _this;
    }
    ElementLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['width_fluid']) {
            this.widthFluid = data['width_fluid'];
        }
        if (data['height_fluid']) {
            this.heightFluid = data['height_fluid'];
        }
        if (data['element']) {
            var Element_1 = getElementByType(data['element']['type']);
            this.element = new Element_1().deserialize(data['element']);
        }
        return this;
    };
    ElementLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { width_fluid: this.widthFluid, height_fluid: this.heightFluid, element: this.element ? this.element.serialize() : undefined });
    };
    return ElementLayer;
}(Layer));
export { ElementLayer };
registerLayerType(LayerType.Element, ElementLayer);

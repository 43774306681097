export class PriceFeature {
  public name: string;
  public category: string;
  public description: string;
  public link: string;

  public id: string;
  public attributes = {};

  deserialize(data: object): PriceFeature {
    this.name = data['Feature Name'];
    this.category = data['Category'];
    this.description = data['Description'];
    this.link = data['Link'];
    this.attributes = data;
    return this;
  }
}

import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ascComparator } from '@shared';

import {
  ViewEditorViewportOverlayItemOptions,
  ViewEditorViewportOverlayService
} from '../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service';

interface ViewportOverlay {
  id: string;
  portal: TemplatePortal<unknown>;
  options: ViewEditorViewportOverlayItemOptions;
}

@Component({
  selector: 'app-view-editor-viewport-overlays',
  templateUrl: './view-editor-viewport-overlays.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorViewportOverlaysComponent implements OnInit, OnDestroy {
  viewportOverlays: ViewportOverlay[] = [];

  trackViewportOverlayFn(i, item: ViewportOverlay) {
    return item.id;
  }

  constructor(
    private viewportOverlayService: ViewEditorViewportOverlayService,
    private vcr: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.viewportOverlayService.templates$.pipe(untilDestroyed(this)).subscribe(value => {
      this.viewportOverlays = value
        .sort((lhs, rhs) => ascComparator(lhs.options.order, rhs.options.order))
        .map(overlay => {
          const existingOverlay = this.viewportOverlays.find(item => item.id == overlay.id);
          const portal = existingOverlay ? existingOverlay.portal : new TemplatePortal(overlay.template, this.vcr);
          return {
            id: overlay.id,
            portal: portal,
            options: overlay.options
          };
        });
      this.cd.markForCheck();
    });

    this.viewportOverlayService
      .templateChecked$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        setTimeout(() => this.cd.markForCheck(), 0);
      });
  }

  ngOnDestroy(): void {}
}

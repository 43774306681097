<div class="card-row">
  <div class="card-row__inner">
    <div class="card-row__col user-activities__list-date-col">
      <div class="card-row__col-inner">
        <span class="user-activities__list-date">{{ item.dateAdd | appFormatDate: 'lll' }}</span>
      </div>
    </div>

    <div class="card-row__col">
      <div class="card-row__col-inner">
        <div
          [style.background-image]="item.user.photo ? 'url(' + item.user.photo + ')' : ''"
          class="user-activities__list-avatar"
        ></div>
        <span class="user-activities__list-name">{{ item.user.firstName }} {{ item.user.lastName }}</span>
      </div>
    </div>

    <div class="card-row__col user-activities__list-type-col">
      <div class="card-row__col-inner">
        <span class="user-activities__list-icon" [ngClass]="'icon-' + item.icon"></span>
        <span class="user-activities__list-type">{{ item.label | titlecase }}</span>
      </div>
    </div>

    <div class="card-row__col user-activities__list-description-col">
      <div class="card-row__col-inner">
        <a *ngIf="pageInfo" [appLink]="pageInfo.page.link" style="margin: 0 8px 0 0;">
          <span class="icon-document"></span>
          {{ pageInfo.page.name || 'Page' }}
        </a>

        <span
          class="user-activities__list-description"
          app-user-activities-description
          [relations]="relations"
          [item]="item"
        >
        </span>
      </div>
    </div>
  </div>
</div>

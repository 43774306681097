import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { confirmationDefaultCancelLabel, confirmationDefaultSubmitLabel } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';

import { ConfirmationControl } from '../customize-bar-action-edit/confirmation.control';

@Component({
  selector: 'app-confirmation-edit',
  templateUrl: './confirmation-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationEditComponent implements OnInit {
  @Input() control: ConfirmationControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;
  @Output() disable = new EventEmitter<void>();

  createField = createFormFieldFactory();
  defaultSubmitLabel = confirmationDefaultSubmitLabel;
  defaultCancelLabel = confirmationDefaultCancelLabel;

  constructor() {}

  ngOnInit() {}
}

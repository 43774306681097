var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, InjectionToken, OnDestroy, OnInit, Provider } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEventType } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { Automation, AutomationTrigger, cleanWorkflowStepName, Workflow, WorkflowRun, WorkflowStepType } from '@modules/workflow';
import { isControlElement, isSet, KeyboardEventKeyCode } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { WORKFLOW_CONTROLLER_COMPONENT } from '../../../services/workflow-edit-controller/workflow-edit.controller';
import { isWorkflowElementClick, markWorkflowElementClick } from '../auto-workflow-step/auto-workflow-step.component';
import { WorkflowRunsComponent } from '../workflow-runs/workflow-runs.component';
export var workflowStepsToken = new InjectionToken('workflowStepsToken');
export var workflowAppToken = new InjectionToken('workflowAppToken');
export var WORKFLOW_CONTROLLER_COMPONENT_PROVIDER = {
    provide: WORKFLOW_CONTROLLER_COMPONENT,
    useFactory: workflowControllerComponentFactory
};
export function workflowControllerComponentFactory() {
    return WorkflowComponent;
}
var WorkflowComponent = /** @class */ (function () {
    function WorkflowComponent(currentProjectStore, currentEnvironmentStore, customizeBarContext, workflowEditContext, actionService, popupService, workflowStepsContextElement, workflowAppContextElement, popupComponent, cd, analyticsService) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.customizeBarContext = customizeBarContext;
        this.workflowEditContext = workflowEditContext;
        this.actionService = actionService;
        this.popupService = popupService;
        this.workflowStepsContextElement = workflowStepsContextElement;
        this.workflowAppContextElement = workflowAppContextElement;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.nameEditable = false;
        this.workflowEditable = false;
        this.parametersEnabled = false;
        this.parameters = [];
        this.triggerEditable = false;
        this.customizeTrigger = false;
        this.historyEnabled = false;
        this.historyOpenedInitial = false;
        this.resultEnabled = false;
        this.result = new EventEmitter();
        this.cancel = new EventEmitter();
        this.loadingExecute = false;
        this.nameEditing = false;
        this.trackStep = (function () {
            return function (i, item) {
                return item ? item.uid : i;
            };
        })();
    }
    WorkflowComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.workflowEditContext.init({ workflow: this.workflow, parameters: this.parameters });
        this.workflowEditContext.run$.next(this.workflowRun || new WorkflowRun());
        this.isUndoAvailable$ = this.workflowEditContext.isUndoAvailable$();
        this.isRedoAvailable$ = this.workflowEditContext.isRedoAvailable$();
        this.workflowStepsContextElement.initGlobal({ uniqueName: 'steps', name: 'Steps' });
        this.workflowAppContextElement.initGlobal({ uniqueName: 'app', name: 'Application' });
        this.workflowAppContextElement.setOutputs([
            {
                uniqueName: 'name',
                name: 'App name',
                icon: 'home'
            },
            {
                uniqueName: 'env_name',
                name: 'Environment name',
                icon: 'tag'
            }
        ]);
        combineLatest(this.currentProjectStore.instance$, this.currentEnvironmentStore.instance$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], environment = _a[1];
            _this.workflowAppContextElement.setOutputValues({
                name: project.uniqueName,
                env_name: environment.uniqueName
            });
        });
        if (this.workflowEditable) {
            this.initHotkeys();
        }
        if (this.historyOpenedInitial && this.historyEnabled) {
            this.openRuns();
        }
    };
    WorkflowComponent.prototype.ngOnDestroy = function () {
        this.workflowStepsContextElement.ngOnDestroy();
    };
    WorkflowComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.scrollToCenter(); }, 0);
    };
    WorkflowComponent.prototype.scrollToCenter = function () {
        var element = this.canvasElement.nativeElement;
        element.scrollTo((element.scrollWidth - element.offsetWidth) * 0.5, 0);
    };
    WorkflowComponent.prototype.initHotkeys = function () {
        var _this = this;
        fromEvent(document, 'keydown')
            .pipe(filter(function () { return _this.popupService.last() === _this.popupComponent.data && !isControlElement(document.activeElement); }), map(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
                return -1;
            }
            else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
                return 1;
            }
        }), filter(function (move) { return isSet(move); }), switchMap(function (move) {
            if (move > 0) {
                return _this.isRedoAvailable$.pipe(first(), map(function (allowed) { return [move, allowed]; }));
            }
            else {
                return _this.isUndoAvailable$.pipe(first(), map(function (allowed) { return [move, allowed]; }));
            }
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var move = _a[0], allowed = _a[1];
            if (!allowed) {
                return;
            }
            if (move > 0) {
                _this.workflowEditContext.redo();
            }
            else {
                _this.workflowEditContext.undo();
            }
        });
    };
    WorkflowComponent.prototype.dragDrop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.workflowEditContext.markChanged();
    };
    WorkflowComponent.prototype.addStep = function (step, options) {
        if (options === void 0) { options = {}; }
        this.workflowEditContext.registerCreatedElement(step);
        if (isSet(options.addBeforeIndex)) {
            this.workflow.steps.splice(options.addBeforeIndex, 0, step);
        }
        else {
            this.workflow.steps.push(step);
        }
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    WorkflowComponent.prototype.duplicateStep = function (index) {
        var step = cloneDeep(this.workflow.steps[index]);
        var defaultName = "Copy of " + step.name;
        step.generateUid();
        step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);
        this.workflowEditContext.registerCreatedElement(step);
        this.workflow.steps.splice(index + 1, 0, step);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    WorkflowComponent.prototype.deleteStep = function (index) {
        this.workflow.steps.splice(index, 1);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    WorkflowComponent.prototype.executeWorkflow = function () {
        var _this = this;
        this.loadingExecute = true;
        this.cd.markForCheck();
        var params = this.workflow.testParameters;
        this.workflowEditContext.run$.next(undefined);
        this.actionService
            .executeWorkflow(this.workflow, params, {
            context: this.context,
            showSuccess: false,
            showError: false,
            disableRouting: true,
            disablePopups: true
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (event) {
            _this.workflowEditContext.testExecuteEvents$.next(event);
            if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
                _this.statusIcon = undefined;
                _this.statusColor = undefined;
            }
            else if (event.type == WorkflowExecuteEventType.WorkflowFinished) {
                _this.loadingExecute = false;
                _this.workflowEditContext.run$.next(event.run);
                if (event.success) {
                    _this.statusIcon = 'check_2';
                    _this.statusColor = 'green';
                }
                else {
                    _this.statusIcon = 'close';
                    _this.statusColor = 'red';
                }
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.WorkflowTest, {
                    Success: true,
                    Steps: _this.workflow.getStepsCount(),
                    Source: _this.analyticsSource
                });
            }
            _this.cd.markForCheck();
        }, function (error) {
            _this.loadingExecute = false;
            _this.cd.markForCheck();
            var errorMessage;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                errorMessage = error.nonFieldErrors[0];
            }
            else {
                errorMessage = 'Unknown error';
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.WorkflowTest, {
                Success: false,
                Error: errorMessage,
                Steps: _this.workflow.getStepsCount(),
                Source: _this.analyticsSource
            });
        });
    };
    WorkflowComponent.prototype.submit = function (options) {
        if (options === void 0) { options = {}; }
        this.result.emit(__assign({ name: this.name, trigger: this.trigger, workflow: this.workflow, workflowRun: this.workflowEditContext.run$.value, parameters: this.parameters }, (isSet(options.automationActive) ? { automationActive: options.automationActive } : {})));
        this.close();
    };
    WorkflowComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    WorkflowComponent.prototype.onWorkflowElementClick = function (event) {
        markWorkflowElementClick(event);
    };
    WorkflowComponent.prototype.onTriggerChange = function (trigger) {
        this.trigger = trigger;
        this.cd.markForCheck();
    };
    WorkflowComponent.prototype.setNameEditing = function (value) {
        this.nameEditing = value;
        this.cd.markForCheck();
    };
    WorkflowComponent.prototype.cleanName = function () {
        this.name = isSet(this.name) ? this.name.trim() : '';
        if (!isSet(this.name)) {
            this.name = 'Automation';
        }
        this.cd.markForCheck();
    };
    WorkflowComponent.prototype.closeCustomize = function (event) {
        if (!isWorkflowElementClick(event)) {
            this.customizeBarContext.resetSettingsComponent();
        }
    };
    WorkflowComponent.prototype.openRuns = function () {
        var dynamicComponent = {
            component: WorkflowRunsComponent,
            inputs: {
                automation: this.automation,
                workflow: this.workflow
            }
        };
        this.customizeBarContext.setSettingsComponent(dynamicComponent);
    };
    WorkflowComponent.prototype.stepsHasNextConnection = function () {
        return !this.workflow.steps.length || last(this.workflow.steps).type != WorkflowStepType.Exit;
    };
    return WorkflowComponent;
}());
export { WorkflowComponent };

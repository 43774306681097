var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';
// TODO: Refactor imports
import { isSet } from '../../../shared/utils/common/common';
export var SENTRY_APP_ERROR = 'app_error';
export function captureException(exception, captureContext) {
    var _a;
    if (!environment.ravenDSN) {
        return;
    }
    var extra = {};
    try {
        var smartlook = window['smartlook'];
        if (smartlook && isSet(smartlook['playUrl'])) {
            extra['smartlook'] = {
                playUrl: smartlook['playUrl'],
                sessionId: smartlook['sessionId'],
                visitorId: smartlook['visitorId'],
                recordId: smartlook['recordId']
            };
        }
    }
    catch (e) { }
    return Sentry.captureException(exception, __assign({}, captureContext, { extra: __assign((_a = {}, _a[SENTRY_APP_ERROR] = true, _a), extra, (captureContext && captureContext.extra)) }));
}

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="root_trigger"
  [cdkConnectedOverlayOpen]="editPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="openEditPopover(false)"
  (detach)="openEditPopover(false)"
>
  <app-share-menu-item-popover [control]="control" (close)="openEditPopover(false)"></app-share-menu-item-popover>
</ng-template>

<mat-menu #item_dropdown="matMenu">
  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="openEditPopover(true)">
    <span class="choose-items-item__dropdown-icon icon-gear"></span>
    Customize item
  </button>

  <button
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleteRequested.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete item
  </button>
</mat-menu>

<div class="sidebar-container__element">
  <div class="sidebar-list-item2 sidebar-list-item2_draggable" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <div
      class="sidebar-list-item2__row"
      [class.sidebar-list-item2__row_opened]="false"
      [class.sidebar-list-item2__row_disabled]="editPopoverOpened"
    >
      <div class="sidebar-list-item2__row-left">
        <div class="sidebar-list-item2__drag icon-dots_small"></div>
      </div>
      <div class="sidebar-list-item2__row-main">
        <div *ngIf="!loading" class="user-photos user-photos_small user-photos_background_content">
          <span
            *ngFor="let user of displayUsers"
            [style.background-image]="user?.photo ? 'url(' + user.photo + ')' : ''"
            class="user-photos__item"
          >
          </span>

          <span *ngIf="otherUsersCount" class="user-photos__item user-photos__item_text">
            +{{ otherUsersCount > 99 ? 99 : otherUsersCount }}
          </span>
        </div>

        <div *ngIf="loading" class="user-photos user-photos_small user-photos_background_content loading-animation">
          <span *ngFor="let user of [1, 2, 3]" class="user-photos__item"></span>
        </div>

        <div class="sidebar-list-item2__additional">
          App members
        </div>
      </div>
      <div class="sidebar-list-item2__row-right">
        <div
          class="sidebar-list-item2__action sidebar-list-item2__action_hover sidebar-list-item2__item icon-more"
          [class.sidebar-list-item2__action_active]="item_dropdown_trigger.menuOpen"
          [matMenuTriggerFor]="item_dropdown"
          [matMenuTriggerData]="{ prepend: true }"
          #item_dropdown_trigger="matMenuTrigger"
          (mousedown)="$event.stopPropagation()"
        ></div>
      </div>
    </div>
  </div>
</div>

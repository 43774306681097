import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { readFileText } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { GoogleCloudStorageResourceSettingsForm } from './google-cloud-storage-resource-settings.form';

@Component({
  selector: 'app-google-cloud-storage-resource-settings',
  templateUrl: './google-cloud-storage-resource-settings.component.html',
  providers: [GoogleCloudStorageResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleCloudStorageResourceSettingsComponent extends BaseResourceSettingsComponent
  implements OnInit, OnDestroy {
  constructor(
    public form: GoogleCloudStorageResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    this.form.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  onFileChange(field: string, el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    readFileText(file)
      .pipe(untilDestroyed(this))
      .subscribe(content => {
        this.form.form.patchValue({ [field]: content });
        this.form.form.markAsDirty();
      });
  }
}

registerResourceSettingsComponent(ResourceName.GoogleCloudStorage, GoogleCloudStorageResourceSettingsComponent);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ConditionWorkflowStepType } from '@modules/workflow';
import { ConditionCustomizeWorkflowStepItemArray } from './condition-customize-workflow-step-item.array';
function createItemsControl(injector) {
    return Injector.create({
        providers: [{ provide: ConditionCustomizeWorkflowStepItemArray, deps: [Injector] }],
        parent: injector
    }).get(ConditionCustomizeWorkflowStepItemArray);
}
var ConditionCustomizeWorkflowStepForm = /** @class */ (function (_super) {
    __extends(ConditionCustomizeWorkflowStepForm, _super);
    function ConditionCustomizeWorkflowStepForm(injector) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            condition_type: new FormControl(ConditionWorkflowStepType.Boolean),
            items: createItemsControl(injector),
            else_label: new FormControl('')
        }) || this;
        _this.injector = injector;
        _this.typeOptions = [
            {
                value: ConditionWorkflowStepType.Boolean,
                name: 'Yes/No',
                icon: 'switch'
            },
            {
                value: ConditionWorkflowStepType.Switch,
                name: 'Switch',
                icon: 'versions'
            }
        ];
        return _this;
    }
    ConditionCustomizeWorkflowStepForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        if (instance) {
            this.controls.name.patchValue(instance.name);
            this.controls.condition_type.patchValue(instance.conditionType);
            this.controls.items.init(instance.items);
            this.controls.else_label.patchValue(instance.elseLabel);
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    ConditionCustomizeWorkflowStepForm.prototype.setConditionType = function (value) {
        this.controls.condition_type.patchValue(value);
        if (value == ConditionWorkflowStepType.Boolean) {
            while (this.controls.items.controls.length < 2) {
                var control = this.controls.items.createControl();
                this.controls.items.push(control);
            }
        }
    };
    ConditionCustomizeWorkflowStepForm.prototype.submit = function () {
        var instance = cloneDeep(this.instance);
        instance.name = this.controls.name.value;
        instance.conditionType = this.controls.condition_type.value;
        instance.items = this.controls.items.submit();
        instance.elseLabel = this.controls.else_label.value;
        return instance;
    };
    return ConditionCustomizeWorkflowStepForm;
}(FormGroup));
export { ConditionCustomizeWorkflowStepForm };

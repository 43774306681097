/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../../../shared/components/autofocus/autofocus.directive";
import * as i3 from "../../../../../../core/services/app-config/app-config.service";
import * as i4 from "../../../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i5 from "../../../../../../common/tips/directives/tip/tip.directive";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i8 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i9 from "../../../../../parameters-components/components/input-edit/input-edit.component.ngfactory";
import * as i10 from "../../../../../parameters-components/components/input-edit/input-edit.component";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "../../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i13 from "../../../../../../shared/pipes/is-set/is-set.pipe";
import * as i14 from "./condition-customize-workflow-step-switch-item.component";
var styles_ConditionCustomizeWorkflowStepSwitchItemComponent = [];
var RenderType_ConditionCustomizeWorkflowStepSwitchItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConditionCustomizeWorkflowStepSwitchItemComponent, data: {} });
export { RenderType_ConditionCustomizeWorkflowStepSwitchItemComponent as RenderType_ConditionCustomizeWorkflowStepSwitchItemComponent };
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "input", [["class", "editable-content-input"]], [[2, "editable-content-input_inline", null], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "keypress"], [null, "blur"], [null, "click"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.onKeyUp($event) !== false);
        ad = (pd_4 && ad);
    } if (("keypress" === en)) {
        var pd_5 = (_co.onKeyPress($event) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (_co.setRename(false) !== false);
        ad = (pd_6 && ad);
    } if (("click" === en)) {
        var pd_7 = ($event.stopPropagation() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(3, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(6, 540672, null, 0, i2.AutofocusDirective, [i0.ElementRef, i3.AppConfigService], { appAutofocus: [0, "appAutofocus"] }, null), i0.ɵdid(7, 81920, null, 0, i4.FormControlSelectorDirective, [i0.ElementRef, [2, i1.FormControlDirective], [2, i1.FormControlName]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.renameControl; _ck(_v, 3, 0, currVal_9); var currVal_10 = true; _ck(_v, 6, 0, currVal_10); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.defaultTitle; var currVal_2 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 5).ngClassValid; var currVal_7 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "editable-content-button"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i5.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-pen"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "Rename Condition"; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "editable-content-label"]], [[2, "editable-content-label_inline", null], [2, "editable-content-label_with-button", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.setRename(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_4)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.hovered$)); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 1, 0, currVal_2); }); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar-collapse__header-title sidebar-collapse__header-title_editable"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_2)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_3)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rename; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.rename; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-collapse__header-description"]], [[2, "sidebar-collapse__header-description_wrap", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 1, 0, currVal_1); }); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-collapse__header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_6)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.description)) && !_co.opened); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ConditionCustomizeWorkflowStepSwitchItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "sidebar-collapse__content"]], [[2, "sidebar-collapse__content_padding", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["style", "padding: 10px 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "sidebar__element sidebar__element_margin_xxs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "app-sidebar-field", [], null, null, null, i7.View_SidebarFieldComponent_0, i7.RenderType_SidebarFieldComponent)), i0.ɵdid(4, 114688, null, 0, i8.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, 3, 1, "app-input-edit", [], null, null, null, i9.View_InputEditComponent_0, i9.RenderType_InputEditComponent)), i0.ɵdid(6, 770048, null, 0, i10.InputEditComponent, [i11.FocusMonitor, i12.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], { itemForm: [0, "itemForm"], context: [1, "context"], staticValueDisabled: [2, "staticValueDisabled"], focusedInitial: [3, "focusedInitial"], placeholder: [4, "placeholder"], fill: [5, "fill"] }, null), (_l()(), i0.ɵeld(7, 0, null, 4, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Example: EQ(steps.query1.type, 'new') "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = _co.control.controls.condition; var currVal_2 = _co.context; var currVal_3 = true; var currVal_4 = true; var currVal_5 = "Value"; var currVal_6 = true; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = false; _ck(_v, 0, 0, currVal_0); }); }
export function View_ConditionCustomizeWorkflowStepSwitchItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i13.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "sidebar-collapse"]], [[2, "sidebar-collapse_openable", null], [2, "sidebar-collapse_block", null], [2, "sidebar-collapse_opened", null], [2, "sidebar-collapse_disabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.hovered$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hovered$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "sidebar-collapse__header"]], [[2, "sidebar-collapse__header_error", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "sidebar-collapse__header-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "sidebar-collapse__header-drag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "sidebar-collapse__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_1)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_5)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "sidebar-collapse__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "a", [["class", "sidebar-list-item__action icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.remove.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_7)), i0.ɵdid(13, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.opened; _ck(_v, 7, 0, currVal_5); var currVal_6 = !_co.opened; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.opened; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.opened; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = false; _ck(_v, 2, 0, currVal_4); }); }
export function View_ConditionCustomizeWorkflowStepSwitchItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-condition-customize-workflow-step-switch-item", [], null, null, null, View_ConditionCustomizeWorkflowStepSwitchItemComponent_0, RenderType_ConditionCustomizeWorkflowStepSwitchItemComponent)), i0.ɵdid(1, 770048, null, 0, i14.ConditionCustomizeWorkflowStepSwitchItemComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConditionCustomizeWorkflowStepSwitchItemComponentNgFactory = i0.ɵccf("app-condition-customize-workflow-step-switch-item", i14.ConditionCustomizeWorkflowStepSwitchItemComponent, View_ConditionCustomizeWorkflowStepSwitchItemComponent_Host_0, { control: "control", title: "title", defaultTitle: "defaultTitle", collapseContext: "collapseContext", openedInitial: "openedInitial", context: "context", contextElement: "contextElement" }, { remove: "remove" }, []);
export { ConditionCustomizeWorkflowStepSwitchItemComponentNgFactory as ConditionCustomizeWorkflowStepSwitchItemComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, merge, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { Border, BorderPosition, Color, CustomViewTemplateCounterStore, ElementLayer, EllipseLayer, Fill, FillType, Frame, frameFromFrames, FrameLayer, FrameTranslate, getAllFontFamilies, GroupLayer, ImageFill, isContainerLayer, LayerInteractionType, LineLayer, RectangleLayer, resizeLayers, TextLayer, traverseLayers, View } from '@modules/views';
import { getFilename, getImageSize, isImageMime, isSet, KeyboardEventKeyCode, MouseButton, pointsDistance } from '@shared';
import { CustomViewTemplatesController } from '../../services/custom-view-templates-controller/custom-view-templates.controller';
import { CreatedLayerSource, ViewEditorContext, ViewEditorCustomizeSource, ViewEditorTool } from '../../services/view-editor-context/view-editor.context';
import { snapFrame } from '../../utils/guides';
var markViewViewClickProperty = '_markViewViewClickProperty';
export function markViewViewClick(clickEvent) {
    clickEvent[markViewViewClickProperty] = true;
}
export function isViewViewClick(clickEvent) {
    return !!clickEvent[markViewViewClickProperty];
}
var ViewEditorViewComponent = /** @class */ (function () {
    function ViewEditorViewComponent(editorContext, contextElement, currentProjectStore, currentEnvironmentStore, projectStorageService, customViewTemplatesController, customViewTemplateCounterStore, sanitizer, cd) {
        this.editorContext = editorContext;
        this.contextElement = contextElement;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectStorageService = projectStorageService;
        this.customViewTemplatesController = customViewTemplatesController;
        this.customViewTemplateCounterStore = customViewTemplateCounterStore;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.componentLabel = 'component';
        this.stateSelectedEnabled = false;
        this.templatesEnabled = true;
        this.dark = false;
        this.importFigmaNode = new EventEmitter();
        this.importSketchFile = new EventEmitter();
        this.x = 0;
        this.y = 0;
        this.fills = [];
        this.borders = [];
        this.displayItems = [];
        this.externalFonts = [];
        this.createLayerThreshold = 5;
        this.createToolSubscriptions = [];
        this.prevFrame = new Frame();
    }
    ViewEditorViewComponent.prototype.trackLayerFn = function (i, item) {
        return item.id;
    };
    ViewEditorViewComponent.prototype.trackFillFn = function (i, item) {
        return item.id;
    };
    ViewEditorViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(this.editorContext.layerChanged$().pipe(filter(function (item) { return item.layer instanceof TextLayer; })), this.editorContext.layerContainerChanged$())
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateExternalFonts(); });
        merge(this.editorContext
            .layerChanged$()
            .pipe(filter(function (event) { return !!_this.view.layers.find(function (item) { return item.isSame(event.layer); }); })), this.editorContext.layerContainerChanged$().pipe(filter(function (event) { return _this.view.layers === event.container; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateDisplayItems(); });
        this.editorContext
            .viewChanged$()
            .pipe(filter(function (event) { return event.source != ViewEditorCustomizeSource.View; }), untilDestroyed(this))
            .subscribe(function () {
            _this.updateFills();
            _this.updateBorders();
            _this.updateBorderRadius();
            _this.updateBoxShadows();
            _this.updateDisplayItems();
            _this.updateFrameTransform();
            _this.updateLayerContext();
            _this.prevFrame = cloneDeep(_this.view.frame);
        });
        this.editorContext
            .trackKeydown()
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Escape && _this.editorContext.isCreateTool()) {
                e.stopPropagation();
                _this.editorContext.tool$.next(ViewEditorTool.Selection);
            }
            else if (e.keyCode == KeyboardEventKeyCode.Escape) {
                e.stopPropagation();
                if (_this.editorContext.customizingLayers$.value.length) {
                    _this.editorContext.resetCustomizingLayers();
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.Backspace && !(e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                if (_this.editorContext.customizingLayers$.value.length) {
                    _this.deleteLayers(_this.editorContext.customizingLayers$.value);
                    _this.editorContext.customizingLayers$.next([]);
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.C && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                if (_this.editorContext.customizingLayers$.value.length) {
                    var layers = _this.copyLayers(_this.editorContext.customizingLayers$.value);
                    _this.editorContext.bufferLayers(layers);
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.X && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                if (_this.editorContext.customizingLayers$.value.length) {
                    var layers = _this.copyLayers(_this.editorContext.customizingLayers$.value);
                    _this.editorContext.bufferLayers(layers);
                    _this.deleteLayers(layers);
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.V && (e.metaKey || e.ctrlKey)) {
                e.stopPropagation();
                if (_this.pasteBufferLayers()) {
                    e.preventDefault();
                }
            }
            else if (e.keyCode == KeyboardEventKeyCode.G &&
                (e.metaKey || e.ctrlKey) &&
                e.altKey &&
                _this.editorContext.customizingLayers$.value.length) {
                e.stopPropagation();
                e.preventDefault();
                _this.groupViewLayers(_this.editorContext.customizingLayers$.value, {
                    frame: true,
                    source: CreatedLayerSource.Hotkey
                });
            }
            else if (e.keyCode == KeyboardEventKeyCode.G &&
                (e.metaKey || e.ctrlKey) &&
                _this.editorContext.customizingLayers$.value.length) {
                e.stopPropagation();
                e.preventDefault();
                _this.groupViewLayers(_this.editorContext.customizingLayers$.value, { source: CreatedLayerSource.Hotkey });
            }
            else if (e.keyCode == KeyboardEventKeyCode.Backspace &&
                (e.metaKey || e.ctrlKey) &&
                _this.editorContext.customizingLayers$.value.some(function (item) { return isContainerLayer(item); })) {
                e.stopPropagation();
                e.preventDefault();
                _this.ungroupViewLayers(_this.editorContext.customizingLayers$.value.filter(function (item) { return isContainerLayer(item); }), { source: CreatedLayerSource.Hotkey });
            }
        });
        fromEvent(document, 'paste')
            .pipe(map(function (e) {
            var dt = e.clipboardData || window['clipboardData'];
            return {
                event: e,
                file: dt ? dt.files[0] : undefined
            };
        }), filter(function (_a) {
            var event = _a.event, file = _a.file;
            if (isImageMime(file.type)) {
                event.stopPropagation();
                return true;
            }
            else {
                return false;
            }
        }), switchMap(function (_a) {
            var event = _a.event, file = _a.file;
            var filePath = ['custom_views', _this.editorContext.view$.value.id].join('/');
            var fileName = file.name;
            var fileUrl = URL.createObjectURL(file);
            return combineLatest(_this.projectStorageService.uploadFile(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, file, filePath, fileName), getImageSize(fileUrl).pipe(catchError(function () { return of(undefined); }))).pipe(map(function (_a) {
                var response = _a[0], imageSize = _a[1];
                return {
                    fileName: getFilename(fileName),
                    url: response.result.uploadedUrl,
                    size: imageSize
                };
            }));
        }), catchError(function () { return of(undefined); }), filter(function (item) { return item; }), untilDestroyed(this))
            .subscribe(function (file) {
            _this.createImageFileLayer(file.url, file.fileName, file.size);
        });
        this.customViewTemplateCounterStore
            .getApproxFirst$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.templatesApprox = value;
            _this.cd.markForCheck();
        });
    };
    ViewEditorViewComponent.prototype.ngOnDestroy = function () {
        if (this.editorContext) {
            this.editorContext.unregisterContainer(this.view.layers);
        }
    };
    ViewEditorViewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.view) {
            if (this.editorContext) {
                if (changes.view.previousValue) {
                    this.editorContext.unregisterContainer(changes.view.previousValue.layers);
                }
                this.editorContext.registerContainer(this.view.layers);
            }
            if (!changes.view.firstChange && this.editorContext) {
                this.editorContext.markViewChanged(this.view, ViewEditorCustomizeSource.HistoryMove);
            }
            else {
                this.updateFills();
                this.updateBorders();
                this.updateBorderRadius();
                this.updateBoxShadows();
                this.updateDisplayItems();
                this.updateExternalFonts();
                this.updateFrameTransform();
                this.updateLayerContext();
            }
            this.prevFrame = cloneDeep(this.view.frame);
        }
    };
    ViewEditorViewComponent.prototype.ngAfterViewInit = function () {
        TweenMax.set(this.rootElement.nativeElement, {
            x: this.x,
            y: this.y,
            xPercent: -50,
            yPercent: -50,
            width: this.view.frame.width,
            height: this.view.frame.height
        });
    };
    ViewEditorViewComponent.prototype.updateFrameTransform = function () {
        if (!this.rootElement) {
            return;
        }
        TweenMax.set(this.rootElement.nativeElement, {
            width: this.view.frame.width,
            height: this.view.frame.height
        });
    };
    ViewEditorViewComponent.prototype.updateFills = function () {
        var _this = this;
        if (this.fillsSubscription) {
            this.fillsSubscription.unsubscribe();
            this.fillsSubscription = undefined;
        }
        var fills$ = this.view.fills.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var icon$ = item.type == FillType.Icon && item.iconFill
                ? item.iconFill.display$({ context: _this.viewContext }).pipe(map(function (value) {
                    return {
                        icon: value.icon,
                        color: value.color,
                        size: isSet(item.iconFill.size)
                            ? item.iconFill.size
                            : Math.min(_this.view.frame.width, _this.view.frame.height)
                    };
                }))
                : of(undefined);
            var css$ = item.css$({ frame: _this.view.frame, context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(icon$, css$, enabled$).pipe(map(function (_a) {
                var icon = _a[0], css = _a[1], enabled = _a[2];
                return {
                    id: item.id,
                    background: isSet(css.background) ? _this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
                    width: css.width,
                    height: css.height,
                    transform: isSet(css.transform) ? _this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
                    icon: icon,
                    opacity: item.type != FillType.Color ? item.opacity : null,
                    enabled: enabled
                };
            }));
        });
        if (!fills$.length) {
            this.fills = [];
            this.cd.markForCheck();
            return;
        }
        this.fillsSubscription = combineLatest(fills$)
            .pipe(untilDestroyed(this))
            .subscribe(function (fills) {
            _this.fills = fills.filter(function (item) { return item.enabled; });
            _this.cd.markForCheck();
        });
    };
    ViewEditorViewComponent.prototype.updateBorders = function () {
        var _this = this;
        if (this.bordersSubscription) {
            this.bordersSubscription.unsubscribe();
            this.bordersSubscription = undefined;
        }
        var borders$ = this.view.borders.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var border$ = item.cssBorder$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(border$, enabled$).pipe(map(function (_a) {
                var border = _a[0], enabled = _a[1];
                var position;
                if (item.position == BorderPosition.Center) {
                    position = -item.thickness * 0.5;
                }
                else if (item.position == BorderPosition.Outside) {
                    position = -item.thickness;
                }
                else {
                    position = 0;
                }
                var borderRadius = _this.view.cornerRadius.cssBorderRadius(position * -1);
                return {
                    border: isSet(border) ? _this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
                    position: position,
                    borderRadius: _this.sanitizer.bypassSecurityTrustStyle(borderRadius),
                    enabled: enabled
                };
            }));
        });
        if (!borders$.length) {
            this.borders = [];
            this.cd.markForCheck();
            return;
        }
        this.bordersSubscription = combineLatest(borders$)
            .pipe(untilDestroyed(this))
            .subscribe(function (borders) {
            _this.borders = borders.filter(function (item) { return item.enabled && isSet(item.border); });
            _this.cd.markForCheck();
        });
    };
    ViewEditorViewComponent.prototype.updateBorderRadius = function () {
        this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.view.cornerRadius.cssBorderRadius());
        this.cd.markForCheck();
    };
    ViewEditorViewComponent.prototype.updateBoxShadows = function () {
        var _this = this;
        if (this.boxShadowSubscription) {
            this.boxShadowSubscription.unsubscribe();
            this.boxShadowSubscription = undefined;
        }
        var shadows$ = this.view.shadows
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var boxShadow$ = item.cssBoxShadow$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(boxShadow$, enabled$).pipe(map(function (_a) {
                var boxShadow = _a[0], enabled = _a[1];
                return {
                    boxShadow: boxShadow,
                    enabled: enabled
                };
            }));
        });
        if (!shadows$.length) {
            this.boxShadow = undefined;
            this.cd.markForCheck();
            return;
        }
        this.boxShadowSubscription = combineLatest(shadows$)
            .pipe(untilDestroyed(this))
            .subscribe(function (shadows) {
            _this.boxShadow = _this.sanitizer.bypassSecurityTrustStyle(shadows
                .filter(function (item) { return item.enabled; })
                .map(function (item) { return item.boxShadow; })
                .join(','));
            _this.cd.markForCheck();
        });
    };
    ViewEditorViewComponent.prototype.updateDisplayItems = function () {
        var _this = this;
        if (this.displayItemsSubscription) {
            this.displayItemsSubscription.unsubscribe();
            this.displayItemsSubscription = undefined;
        }
        var items$ = this.view.layers
            .filter(function (item) { return item.visible; })
            .map(function (item) {
            var visible$ = item.visibleInput ? item.visible$({ context: _this.viewContext }) : of(true);
            return combineLatest(visible$).pipe(map(function (_a) {
                var visible = _a[0];
                return {
                    item: item,
                    visible: visible
                };
            }));
        });
        if (!items$.length) {
            this.displayItems = [];
            this.cd.markForCheck();
            return;
        }
        this.displayItemsSubscription = combineLatest(items$)
            .pipe(untilDestroyed(this))
            .subscribe(function (items) {
            _this.displayItems = items.filter(function (item) { return item.visible; }).map(function (item) { return item.item; });
            _this.cd.markForCheck();
        });
    };
    ViewEditorViewComponent.prototype.updateExternalFonts = function () {
        this.externalFonts = getAllFontFamilies(this.view.layers);
        this.cd.markForCheck();
    };
    ViewEditorViewComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.view.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.view.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: 'view',
                name: 'Canvas',
                icon: 'canvas'
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: 'Canvas',
                icon: 'canvas'
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    ViewEditorViewComponent.prototype.createLayerFromTool = function (frame) {
        var tool = this.editorContext.tool$.value;
        var defaultFillColor = new Color({ red: 217 / 255, green: 217 / 255, blue: 217 / 255 });
        if (tool == ViewEditorTool.Rectangle) {
            var layer = new RectangleLayer();
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            var fill = new Fill({ color: defaultFillColor });
            fill.generateId();
            layer.fills = [fill];
            return layer;
        }
        else if (tool == ViewEditorTool.Ellipse) {
            var layer = new EllipseLayer();
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            var fill = new Fill({ color: defaultFillColor });
            fill.generateId();
            layer.fills = [fill];
            return layer;
        }
        else if (tool == ViewEditorTool.Line) {
            var layer = new LineLayer();
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            var border = new Border({ color: defaultFillColor });
            // border.generateId();
            layer.borders = [border];
            return layer;
        }
        else if (tool == ViewEditorTool.Text) {
            var layer = new TextLayer();
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            var color = new Color();
            var fill = new Fill({ color: color });
            fill.generateId();
            fill.color = color;
            layer.fills = [fill];
            return layer;
        }
        else if (tool == ViewEditorTool.Image) {
            var layer = new RectangleLayer();
            var file = this.editorContext.toolFile$.value;
            var imageFill = new ImageFill({ image: file.url });
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer, { defaultName: file.fileName });
            layer.constrainProportion = file.size.height != 0 ? file.size.width / file.size.height : 1;
            var fill = new Fill({ type: FillType.Image, imageFill: imageFill });
            fill.generateId();
            layer.fills = [fill];
            return layer;
        }
        else if (tool == ViewEditorTool.Element) {
            var layer = new ElementLayer();
            var toolElement = this.editorContext.toolElement$.value;
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            layer.widthFluid = toolElement.widthFluid;
            layer.heightFluid = toolElement.heightFluid;
            layer.element = toolElement.element;
            if (toolElement.width) {
                layer.frame.width = toolElement.width;
            }
            if (toolElement.height) {
                layer.frame.height = toolElement.height;
            }
            return layer;
        }
        else if (tool == ViewEditorTool.Frame) {
            var layer = new FrameLayer();
            layer.generateId();
            layer.frame = new Frame(frame);
            layer.name = this.editorContext.generateLayerName(layer);
            return layer;
        }
    };
    ViewEditorViewComponent.prototype.appendViewLayers = function (layers, source) {
        var _this = this;
        var view = this.editorContext.view$.value;
        var targetLayer = this.editorContext.getLastCustomizingLayer();
        var layerContainer;
        if (targetLayer && isContainerLayer(targetLayer)) {
            layerContainer = this.editorContext.getContainer(targetLayer.layers);
        }
        else {
            layerContainer = targetLayer ? this.editorContext.getLayerContainer(targetLayer) : undefined;
        }
        var container = layerContainer ? layerContainer.container : view.layers;
        var targetIndex = targetLayer && container ? container.findIndex(function (item) { return item.isSame(targetLayer); }) : -1;
        layers.forEach(function (item) {
            if (layerContainer) {
                item.frame.x -= layerContainer.options.translate.x || 0;
                item.frame.y -= layerContainer.options.translate.y || 0;
            }
        });
        if (targetIndex != -1) {
            container.splice.apply(container, [targetIndex + 1, 0].concat(layers));
        }
        else {
            container.push.apply(container, layers);
        }
        layers.forEach(function (layer) { return _this.editorContext.registerCreatedLayer(layer, source); });
        this.editorContext.markLayerContainerChanged(container, ViewEditorCustomizeSource.View);
        this.cd.markForCheck();
        return {
            container: layerContainer
        };
    };
    ViewEditorViewComponent.prototype.groupViewLayers = function (childLayers, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var container = this.editorContext.getLayerContainer(childLayers[0]);
        var allContainers = [container.container];
        var index = container.container.findIndex(function (item) { return item.isSame(childLayers[0]); });
        var containerLayer = options.frame ? new FrameLayer() : new GroupLayer();
        var frames = childLayers.map(function (childLayer) {
            var childContainer = _this.editorContext.getLayerContainer(childLayer);
            return new FrameTranslate({
                frame: childLayer.frame,
                translate: childContainer ? childContainer.options.translate : undefined
            });
        });
        var frame = frameFromFrames(frames);
        if (!frame) {
            return;
        }
        containerLayer.generateId();
        containerLayer.frame = frame;
        containerLayer.frame.x -= container.options.translate.x;
        containerLayer.frame.y -= container.options.translate.y;
        containerLayer.name = this.editorContext.generateLayerName(containerLayer);
        childLayers.forEach(function (childLayer) {
            var childContainer = _this.editorContext.getLayerContainer(childLayer);
            var childIndex = childContainer.container.findIndex(function (item) { return item.isSame(childLayer); });
            if (childIndex !== -1) {
                childContainer.container.splice(childIndex, 1);
                containerLayer.layers.push(childLayer);
                var childViewPosition = {
                    x: childLayer.frame.x + childContainer.options.translate.x,
                    y: childLayer.frame.y + childContainer.options.translate.y
                };
                childLayer.frame.x = childViewPosition.x - container.options.translate.x - containerLayer.frame.x;
                childLayer.frame.y = childViewPosition.y - container.options.translate.y - containerLayer.frame.y;
                if (!allContainers.includes(childContainer.container)) {
                    allContainers.push(childContainer.container);
                }
                if (childContainer === container && childIndex < index) {
                    --index;
                }
            }
        });
        container.container.splice(index, 0, containerLayer);
        this.editorContext.registerCreatedLayer(containerLayer, options.source);
        this.editorContext.setCustomizingLayer(containerLayer);
        this.editorContext.markLayersChanged(containerLayer.layers, ViewEditorCustomizeSource.View);
        allContainers.forEach(function (item) { return _this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View); });
        this.cd.markForCheck();
    };
    ViewEditorViewComponent.prototype.ungroupViewLayers = function (containerLayers, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var _a;
        var allContainers = [];
        var allLayers = [];
        containerLayers.forEach(function (groupLayer) {
            var _a;
            var parentContainer = _this.editorContext.getLayerContainer(groupLayer);
            var groupContainer = _this.editorContext.getContainer(groupLayer.layers);
            var groupIndex = parentContainer.container.findIndex(function (item) { return item.isSame(groupLayer); });
            if (groupIndex === -1) {
                return;
            }
            (_a = parentContainer.container).splice.apply(_a, [groupIndex, 1].concat(groupLayer.layers));
            groupLayer.layers.forEach(function (layer) {
                layer.frame.x = layer.frame.x + groupContainer.options.translate.x;
                layer.frame.y = layer.frame.y + groupContainer.options.translate.y;
                allLayers.push(layer);
            });
            if (!allContainers.includes(parentContainer.container)) {
                allContainers.push(parentContainer.container);
            }
        });
        (_a = this.editorContext).setCustomizingLayer.apply(_a, allLayers);
        this.editorContext.markLayersChanged(allLayers, ViewEditorCustomizeSource.View);
        allContainers.forEach(function (item) { return _this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View); });
        this.cd.markForCheck();
    };
    ViewEditorViewComponent.prototype.createViewLayerFromTool = function (frame, source) {
        var layer = this.createLayerFromTool(frame);
        if (!layer) {
            return;
        }
        var append = this.appendViewLayers([layer], source);
        return {
            layer: layer,
            container: append.container
        };
    };
    ViewEditorViewComponent.prototype.createViewLayers = function (layers) {
        var _a;
        traverseLayers(layers, function (item) { return item.generateId(); });
        this.appendViewLayers(layers, CreatedLayerSource.Buffer);
        (_a = this.editorContext).setCustomizingLayer.apply(_a, layers);
    };
    ViewEditorViewComponent.prototype.createImageFileLayer = function (url, fileName, fileSize) {
        var size = fileSize || { width: 64, height: 64 };
        var position = { x: this.view.frame.width * 0.5, y: this.view.frame.height * 0.5 };
        var frame = __assign({ x: position.x - size.width * 0.5, y: position.y - size.height * 0.5 }, size);
        var layer = new RectangleLayer();
        var imageFill = new ImageFill({ image: url });
        layer.generateId();
        layer.frame = new Frame(frame);
        layer.name = this.editorContext.generateLayerName(layer, { defaultName: fileName });
        layer.constrainProportion = size.height != 0 ? size.width / size.height : 1;
        var fill = new Fill({ type: FillType.Image, imageFill: imageFill });
        fill.generateId();
        layer.fills = [fill];
        this.appendViewLayers([layer], CreatedLayerSource.Buffer);
    };
    ViewEditorViewComponent.prototype.deleteLayer = function (layer) {
        var container = this.editorContext.getLayerContainer(layer);
        if (!container) {
            return;
        }
        var index = container.container.findIndex(function (item) { return item.isSame(layer); });
        if (index === -1) {
            return;
        }
        container.container.splice(index, 1);
        this.cd.markForCheck();
        this.editorContext.markLayerContainerChanged(container.container, ViewEditorCustomizeSource.View);
    };
    ViewEditorViewComponent.prototype.deleteLayers = function (layers) {
        var _this = this;
        var containers = [];
        layers.forEach(function (layer) {
            var container = _this.editorContext.getLayerContainer(layer);
            if (!container) {
                return;
            }
            var index = container.container.findIndex(function (item) { return item.isSame(layer); });
            if (index === -1) {
                return;
            }
            container.container.splice(index, 1);
            if (!containers.includes(container.container)) {
                containers.push(container.container);
            }
        });
        this.cd.markForCheck();
        containers.forEach(function (item) { return _this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View); });
    };
    ViewEditorViewComponent.prototype.copyLayers = function (sourceLayers) {
        var _this = this;
        var layers = cloneDeep(sourceLayers);
        layers.forEach(function (layer) {
            var container = _this.editorContext.getLayerContainer(layer);
            if (container) {
                layer.frame.x += container.options.translate.x || 0;
                layer.frame.y += container.options.translate.y || 0;
            }
        });
        return layers;
    };
    ViewEditorViewComponent.prototype.pasteBufferLayers = function () {
        var layers = this.editorContext.getBufferLayers();
        if (!layers.length) {
            return [];
        }
        var newLayers = cloneDeep(layers);
        this.createViewLayers(newLayers);
        return newLayers;
    };
    ViewEditorViewComponent.prototype.getMouseEventViewPosition = function (event, bounds) {
        return {
            x: Math.round((event.clientX - bounds.left) / this.editorContext.viewportScale$.value),
            y: Math.round((event.clientY - bounds.top) / this.editorContext.viewportScale$.value)
        };
    };
    ViewEditorViewComponent.prototype.getMouseEventsFrame = function (downEvent, moveEvent) {
        var bounds = this.rootElement.nativeElement.getBoundingClientRect();
        var downPosition = this.getMouseEventViewPosition(downEvent, bounds);
        var movePosition = this.getMouseEventViewPosition(moveEvent, bounds);
        var xInverse = movePosition.x < downPosition.x;
        var yInverse = movePosition.y < downPosition.y;
        var width = Math.abs(movePosition.x - downPosition.x);
        var height = Math.abs(movePosition.y - downPosition.y);
        if (moveEvent.shiftKey) {
            var max = Math.max(width, height);
            width = height = max;
        }
        var x = xInverse ? downPosition.x - width : downPosition.x;
        var y = yInverse ? downPosition.y - height : downPosition.y;
        return {
            frame: {
                x: x,
                y: y,
                width: width,
                height: height
            },
            downPosition: downPosition,
            movePosition: movePosition,
            xInverse: xInverse,
            yInverse: yInverse
        };
    };
    ViewEditorViewComponent.prototype.onMouseDown = function (downEvent) {
        var _this = this;
        if (!this.editorContext.isCreateTool()) {
            return;
        }
        var subscriptions = [];
        subscriptions.push(fromEvent(document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (moveEvent) {
            moveEvent.preventDefault();
            _this.onMouseMove(downEvent, moveEvent);
        }));
        subscriptions.push(fromEvent(document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (e) { return _this.onMouseUp(e); }));
        this.createToolSubscriptions = subscriptions;
    };
    ViewEditorViewComponent.prototype.onMouseMove = function (downEvent, moveEvent) {
        if (!this.creatingLayer) {
            var originDistance = pointsDistance(downEvent.clientX, downEvent.clientY, moveEvent.clientX, moveEvent.clientY);
            if (originDistance >= this.createLayerThreshold) {
                var eventsFrame = this.getMouseEventsFrame(downEvent, moveEvent);
                this.creatingLayer = this.createViewLayerFromTool(eventsFrame.frame, CreatedLayerSource.Tool);
                this.editorContext.removeHorizontalGuides();
                this.editorContext.removeVerticalGuides();
            }
        }
        if (this.creatingLayer) {
            var eventsFrame = this.getMouseEventsFrame(downEvent, moveEvent);
            var newFrame = new Frame(eventsFrame.frame);
            var otherFrames = this.editorContext.getFrames({
                onlyContainers: [this.view.layers],
                exceptLayers: [this.creatingLayer.layer]
            });
            var snap = snapFrame({
                base: new FrameTranslate({
                    frame: newFrame,
                    translate: this.creatingLayer.container ? this.creatingLayer.container.options.translate : undefined
                }),
                others: otherFrames,
                horizontalSnapX: eventsFrame.xInverse,
                horizontalSnapWidth: true,
                horizontalOriginSnaps: eventsFrame.xInverse ? [0] : [1],
                verticalSnapY: eventsFrame.yInverse,
                verticalSnapHeight: true,
                verticalOriginSnaps: eventsFrame.yInverse ? [0] : [1]
            });
            var snapSizes = [snap.updateFrame.width, snap.updateFrame.height].filter(function (item) { return isSet(item); });
            if (moveEvent.shiftKey && snapSizes.length) {
                var max = Math.max.apply(Math, snapSizes);
                snap.updateFrame.width = snap.updateFrame.height = max;
                snap.updateFrame.x = eventsFrame.xInverse
                    ? eventsFrame.downPosition.x - snap.updateFrame.width
                    : eventsFrame.downPosition.x;
                snap.updateFrame.y = eventsFrame.yInverse
                    ? eventsFrame.downPosition.y - snap.updateFrame.height
                    : eventsFrame.downPosition.y;
            }
            newFrame.patch(snap.updateFrame);
            if (moveEvent.altKey) {
                newFrame.x = eventsFrame.xInverse ? newFrame.x : newFrame.x - newFrame.width;
                newFrame.y = eventsFrame.yInverse ? newFrame.y : newFrame.y - newFrame.height;
                newFrame.width *= 2;
                newFrame.height *= 2;
            }
            this.creatingLayer.layer.applyMouseEventsFrame(newFrame, {
                translate: this.creatingLayer.container ? this.creatingLayer.container.options.translate : undefined,
                xInverse: eventsFrame.xInverse,
                yInverse: eventsFrame.yInverse
            });
            this.editorContext.markLayersChanged([this.creatingLayer.layer], ViewEditorCustomizeSource.View);
            this.editorContext.setHorizontalGuides(snap.horizontalGuides);
            this.editorContext.setVerticalGuides(snap.verticalGuides);
        }
    };
    ViewEditorViewComponent.prototype.onMouseUp = function (e) {
        var tool = this.editorContext.tool$.value;
        if (!this.creatingLayer) {
            var bounds = this.rootElement.nativeElement.getBoundingClientRect();
            var position = this.getMouseEventViewPosition(e, bounds);
            if (tool == ViewEditorTool.Text) {
                this.creatingLayer = this.createViewLayerFromTool({ x: position.x, y: position.y - 20 * 0.5, width: 120, height: 20 }, CreatedLayerSource.Tool);
                this.creatingLayer.layer.widthFluid = true;
                this.creatingLayer.layer.heightFluid = true;
            }
            else if (tool == ViewEditorTool.Image) {
                var file = this.editorContext.toolFile$.value;
                var size = file.size || { width: 64, height: 64 };
                this.creatingLayer = this.createViewLayerFromTool(__assign({ x: position.x - size.width * 0.5, y: position.y - size.height * 0.5 }, size), CreatedLayerSource.Tool);
            }
            else if (tool == ViewEditorTool.Element) {
                var size = { width: 100, height: 60 };
                this.creatingLayer = this.createViewLayerFromTool(__assign({ x: position.x - size.width * 0.5, y: position.y - size.height * 0.5 }, size), CreatedLayerSource.Tool);
            }
        }
        if (this.creatingLayer) {
            this.editorContext.setCustomizingLayer(this.creatingLayer.layer);
        }
        this.createToolSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.createToolSubscriptions = [];
        this.creatingLayer = undefined;
        this.editorContext.tool$.next(ViewEditorTool.Selection);
        this.editorContext.removeHorizontalGuides();
        this.editorContext.removeVerticalGuides();
    };
    ViewEditorViewComponent.prototype.resizeViewLayers = function (originalFrame, originalView) {
        var newFrame = this.view.frame;
        var sizeChanged = !isEqual(newFrame.getSize(), this.prevFrame.getSize());
        if (!sizeChanged) {
            return;
        }
        resizeLayers({
            layers: this.view.layers,
            originalLayers: originalView.layers,
            originalFrame: originalFrame,
            newFrame: newFrame
        });
        this.editorContext.markGlobalLayersChange(ViewEditorCustomizeSource.View);
    };
    ViewEditorViewComponent.prototype.onLabelClick = function (e) {
        if (this.editorContext.navigateMode$.value) {
            return;
        }
        this.editorContext.customizeView();
        markViewViewClick(e);
    };
    ViewEditorViewComponent.prototype.onResizeStarted = function () {
        this.editorContext.resizingView$.next(true);
        this.frameBeforeResize = cloneDeep(this.view.frame);
        this.viewBeforeResize = cloneDeep(this.view);
    };
    ViewEditorViewComponent.prototype.onResizeUpdateFrame = function (frame) {
        this.view.frame.patch(frame);
        this.editorContext.markViewChanged(this.view, ViewEditorCustomizeSource.View);
        this.updateFrameTransform();
        if (this.view.resizeContent) {
            this.resizeViewLayers(this.frameBeforeResize, this.viewBeforeResize);
        }
        this.prevFrame = cloneDeep(this.view.frame);
    };
    ViewEditorViewComponent.prototype.onResizeFinished = function () {
        this.editorContext.resizingView$.next(false);
        this.frameBeforeResize = undefined;
        this.viewBeforeResize = undefined;
    };
    ViewEditorViewComponent.prototype.openCustomViewTemplates = function () {
        var _this = this;
        this.customViewTemplatesController
            .chooseTemplate({
            viewCustomizeEnabled: false,
            stateSelectedEnabled: this.stateSelectedEnabled,
            componentLabel: this.componentLabel,
            analyticsSource: 'view_editor'
        })
            .pipe(filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (viewResult) {
            _this.editorContext.updateView(viewResult.view);
        });
    };
    return ViewEditorViewComponent;
}());
export { ViewEditorViewComponent };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-error-menu',
  templateUrl: './save-error-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveErrorMenuComponent implements OnInit {
  @Input() saveError: string;
  @Output() saveChanges = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}

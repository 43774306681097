import { MatSelect } from '@angular/material';
import isArray from 'lodash/isArray';

export function addMatSelectCoerceArray() {
  if (!MatSelect.prototype['_setSelectionByValue']) {
    return;
  }

  const originalSetSelectionByValue = MatSelect.prototype['_setSelectionByValue'];

  function overrideSetSelectionByValue(value: any) {
    if (this['_multiple'] && value !== undefined && value !== null && !isArray(value)) {
      value = [value];
    }
    return originalSetSelectionByValue.call(this, value);
  }

  MatSelect.prototype['_setSelectionByValue'] = overrideSetSelectionByValue;
}

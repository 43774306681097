import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { Option } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectUniqueNameController } from '@modules/layout-components';
import { MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore, Project } from '@modules/projects';
import { projectThemes, ThemeService } from '@modules/theme';
import { controlValue, isSet } from '@shared';

import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';

@Component({
  selector: 'app-customize-bar-language-region-edit',
  templateUrl: './customize-bar-language-region-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarLanguageRegionEditComponent implements OnInit, OnDestroy {
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;

  domain: Domain;
  createField = createFormFieldFactory();
  currentTimezoneOption: Option;
  currentTimezoneTime$: Observable<moment.Moment>;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private projectDomainController: ProjectDomainController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.domain = project.domain;
        this.cd.markForCheck();
      });

    controlValue(this.projectDomainUpdateForm.controls.timezone)
      .pipe(untilDestroyed(this))
      .subscribe(timezone => {
        const option = isSet(timezone)
          ? this.projectDomainUpdateForm.timezoneOptions.find(item => item.value == timezone)
          : this.projectDomainUpdateForm.timezoneDefaultOption;

        this.currentTimezoneOption = option;
        this.currentTimezoneTime$ = option
          ? timer(0, 1000).pipe(
              map(() => {
                if (!option.data || !isSet(option.data['tz'])) {
                  return;
                }

                return moment().tz(option.data['tz']);
              })
            )
          : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_sharing' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onDomainRequiredClick() {
    if (!this.domain) {
      this.editDomain();
    }
  }
}

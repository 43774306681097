import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ActionService } from '@modules/action-queries';
import { ActionDescription, ActionItem, ActionType, WorkflowAction } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldOutput } from '@modules/fields';
import { Workflow } from '@modules/workflow';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowResultComponent } from '../customize-workflow-result/customize-workflow-result.component';

@Component({
  selector: 'app-workflow-result',
  templateUrl: './workflow-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowResultComponent implements OnInit, OnDestroy {
  @Input() workflow: Workflow;
  @Input() workflowEditable = false;
  @Input() resultOutputs: FieldOutput[];
  @Input() statusIcon: string;
  @Input() statusColor: string;
  @Input() connectorTop = true;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;

  customizeComponentData$ = new BehaviorSubject<DynamicComponentArguments>(undefined);
  customizing$ = new BehaviorSubject<boolean>(false);
  outputs: FieldOutput[] = [];
  outputArray = false;

  constructor(
    public customizeBarContext: CustomizeBarContext,
    private workflowEditContext: WorkflowEditContext,
    private actionService: ActionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initOutputs();

    combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
      .pipe(
        debounceTime(10),
        map(([customizeComponentData, components]) => {
          return customizeComponentData && components[0] === customizeComponentData;
        }),
        startWith(false)
      )
      .pipe(untilDestroyed(this))
      .subscribe(value => this.customizing$.next(value));
  }

  ngOnDestroy(): void {}

  initOutputs() {
    if (this.resultOutputs && this.resultOutputs.length) {
      this.outputs = this.resultOutputs;
      this.outputArray = false;
      this.cd.markForCheck();
    } else {
      this.workflowEditContext
        .state$()
        .pipe(
          switchMap(() => this.actionService.getWorkflowOutputs(this.workflow)),
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.outputs = result.outputs;
          this.outputArray = result.arrayOutput;
          this.cd.markForCheck();
        });
    }
  }

  customize() {
    if (this.customizing$.value) {
      return;
    }

    const dynamicComponent: DynamicComponentArguments<CustomizeWorkflowResultComponent> = {
      component: CustomizeWorkflowResultComponent,
      inputs: {
        result: this.workflow ? this.workflow.result : undefined,
        resultOutputs: this.resultOutputs,
        workflowEditable: this.workflowEditable,
        context: this.context,
        contextElement: this.contextElement
      },
      outputs: {
        resultChange: [
          result => {
            this.workflow.result = result;
            this.cd.markForCheck();
            this.workflowEditContext.markChanged();
          }
        ],
        closeCustomize: [
          () => {
            this.customizeBarContext.closeSettingsComponent(dynamicComponent);
          }
        ]
      }
    };

    this.customizeBarContext.setSettingsComponent(dynamicComponent);
    this.customizeComponentData$.next(dynamicComponent);
  }
}

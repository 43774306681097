var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { isSet, SingletonStore } from '@shared';
import { ProjectPropertyType } from '../data/project-property';
import { ProjectPropertyService } from '../services/project-property/project-property.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';
var ProjectPropertyStore = /** @class */ (function (_super) {
    __extends(ProjectPropertyStore, _super);
    function ProjectPropertyStore(mode$, projectPropertyService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.projectPropertyService = projectPropertyService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    ProjectPropertyStore.prototype.fetch = function () {
        var _this = this;
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.projectPropertyService.get(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, {}, mode == AdminMode.Builder);
        }));
    };
    ProjectPropertyStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ProjectPropertyStore.prototype.getUser = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return item.type == ProjectPropertyType.User; });
        }));
    };
    ProjectPropertyStore.prototype.getGroup = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return item.type == ProjectPropertyType.Group; });
        }));
    };
    ProjectPropertyStore.prototype.getGlobal = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return item.type == ProjectPropertyType.Global; });
        }));
    };
    ProjectPropertyStore.prototype.getPage = function (pageUid, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return item.type == ProjectPropertyType.Page && isSet(item.pageUid) && item.pageUid == pageUid; });
        }));
    };
    ProjectPropertyStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ProjectPropertyStore.prototype.getDetail = function (uid, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (properties) {
            if (!properties) {
                return;
            }
            return properties.find(function (property) { return property.uid === uid; });
        }));
    };
    ProjectPropertyStore.prototype.getDetailFirst = function (uid, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (properties) {
            if (!properties) {
                return;
            }
            return properties.find(function (property) { return property.uid === uid; });
        }));
    };
    return ProjectPropertyStore;
}(SingletonStore));
export { ProjectPropertyStore };

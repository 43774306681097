/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../kanban-board-stub-content/kanban-board-stub-content.component.ngfactory";
import * as i2 from "../kanban-board-stub-content/kanban-board-stub-content.component";
import * as i3 from "@angular/common";
import * as i4 from "./kanban-board-stub.component";
var styles_KanbanBoardStubComponent = [];
var RenderType_KanbanBoardStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KanbanBoardStubComponent, data: {} });
export { RenderType_KanbanBoardStubComponent as RenderType_KanbanBoardStubComponent };
function View_KanbanBoardStubComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "kanban-board__column"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "kanban-board-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "kanban-board-column__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "kanban-board-column__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "kanban-board-column__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Column"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "kanban-board-column__scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "kanban-board-column__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-kanban-board-stub-content", [], null, null, null, i1.View_KanbanBoardStubContentComponent_0, i1.RenderType_KanbanBoardStubContentComponent)), i0.ɵdid(11, 114688, null, 0, i2.KanbanBoardStubContentComponent, [], { items: [0, "items"], animating: [1, "animating"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.animating; _ck(_v, 11, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (100 / 3); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.animating; _ck(_v, 5, 0, currVal_1); }); }
export function View_KanbanBoardStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "kanban-board"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 5, null, View_KanbanBoardStubComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 3), i0.ɵpad(4, 3), i0.ɵpad(5, 2), i0.ɵpad(6, 3)], function (_ck, _v) { var currVal_0 = _ck(_v, 6, 0, _ck(_v, 3, 0, 1, 2, 3), _ck(_v, 4, 0, 1, 2, 3), _ck(_v, 5, 0, 1, 2)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_KanbanBoardStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-kanban-board-stub", [], null, null, null, View_KanbanBoardStubComponent_0, RenderType_KanbanBoardStubComponent)), i0.ɵdid(1, 114688, null, 0, i4.KanbanBoardStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KanbanBoardStubComponentNgFactory = i0.ɵccf("app-kanban-board-stub", i4.KanbanBoardStubComponent, View_KanbanBoardStubComponent_Host_0, { animating: "animating" }, {}, []);
export { KanbanBoardStubComponentNgFactory as KanbanBoardStubComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { salesforceResourceParamsModelDescriptions } from '../../data/salesforce/salesforce-resource-params-model-descriptions.stub';
import { salesforceResourceParamsParams } from '../../data/salesforce/salesforce-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var SalesforceGeneratorService = /** @class */ (function (_super) {
    __extends(SalesforceGeneratorService, _super);
    function SalesforceGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        _this.tokenName = 'oauth_access_token';
        return _this;
    }
    SalesforceGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = "https://" + options.domain + ".my.salesforce.com/services/data/v52.0/";
        query.headers = [{ name: 'Authorization', value: "Bearer " + options.access_token }];
        return this.httpQueryService.request(query).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError &&
                error.response instanceof HttpErrorResponse &&
                error.status >= 401 &&
                error.status < 500) {
                error = new ServerRequestError('API key is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    SalesforceGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, this.tokenName, this.mode == AdminMode.Builder)
            .pipe(map(function (secretToken) {
            return {
                domain: resource.params['domain'],
                access_token: secretToken.value,
                token_params: secretToken.params,
                key: secretToken.params['KEY'],
                secret: secretToken.params['SECRET']
            };
        }));
    };
    SalesforceGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = salesforceResourceParamsParams;
        var modelDescriptions = salesforceResourceParamsModelDescriptions;
        var token = new SecretToken();
        token.resource = '{{resource}}';
        token.name = this.tokenName;
        token.type = SecretTokenType.OAuth;
        token.value = options.access_token;
        try {
            token.params = options.token_params;
        }
        catch (e) {
            token.params = {};
        }
        resourceParams['domain'] = options.domain;
        return of({
            resourceParams: resourceParams,
            modelDescriptions: modelDescriptions,
            secretTokens: [token.serialize()],
            extraTokens: {
                domain: options.domain
            }
        });
    };
    return SalesforceGeneratorService;
}(ResourceGeneratorService));
export { SalesforceGeneratorService };

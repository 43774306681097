<div class="rating rating_interactive" (mouseleave)="clearHoverValue()" (click)="setCurrentValue(hoverValue)">
  <div *ngFor="let i of [0, 1, 2, 3, 4]" class="rating__item">
    <app-star
      [leftActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, true)"
      [rightActive]="isRatingValueGte((displayValue | appIsSet) ? displayValue : currentValue, i, false)"
    ></app-star>

    <div class="rating__item-left" (mouseenter)="setHoverValue(i, true)"></div>
    <div class="rating__item-right" (mouseenter)="setHoverValue(i, false)"></div>
  </div>
</div>

import isArray from 'lodash/isArray';

import {
  exactFieldLookup,
  gteFieldLookup,
  gtFieldLookup,
  inFieldLookup,
  isNullFieldLookup,
  lteFieldLookup,
  ltFieldLookup
} from '@modules/field-lookups';

import { FieldType } from '../field-type';
import { parseInLookupStringValue } from './text';

export const numberLookupMatchers = [
  {
    field: FieldType.Number,
    lookup: exactFieldLookup,
    match: (fieldValue: number, lookupValue: number): boolean => {
      return fieldValue == lookupValue;
    }
  },
  {
    field: FieldType.Number,
    lookup: gteFieldLookup,
    match: (fieldValue: number, lookupValue: number): boolean => {
      return fieldValue >= lookupValue;
    }
  },
  {
    field: FieldType.Number,
    lookup: gtFieldLookup,
    match: (fieldValue: number, lookupValue: number): boolean => {
      return fieldValue > lookupValue;
    }
  },
  {
    field: FieldType.Number,
    lookup: lteFieldLookup,
    match: (fieldValue: number, lookupValue: number): boolean => {
      return fieldValue <= lookupValue;
    }
  },
  {
    field: FieldType.Number,
    lookup: ltFieldLookup,
    match: (fieldValue: number, lookupValue: number): boolean => {
      return fieldValue < lookupValue;
    }
  },
  {
    field: FieldType.Number,
    lookup: isNullFieldLookup,
    match: (fieldValue: number, lookupValue: boolean): boolean => {
      return lookupValue ? fieldValue === null : fieldValue !== null;
    }
  },
  {
    field: FieldType.Number,
    lookup: inFieldLookup,
    match: (fieldValue: number, lookupValue: number[]): boolean => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return false;
      }

      if (typeof lookupValue == 'string') {
        lookupValue = (parseInLookupStringValue(lookupValue as string) as unknown) as number[];
      } else if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      return lookupValue.some(item => fieldValue == item);
    }
  }
];

import { ViewportScroller } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router, Scroll } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import {
  componentHasIgnoreScrollTop,
  getComponentIgnoreScrollTopOptions
} from '../../data/ignore-scroll-top.decorator';

@Injectable({
  providedIn: 'root'
})
export class RouteScrollService implements OnDestroy {
  constructor(private router: Router, private viewportScroller: ViewportScroller) {}

  ngOnDestroy(): void {}

  initScrollRestoration() {
    let prevSnapshot: ActivatedRouteSnapshot;
    let currentSnapshot: ActivatedRouteSnapshot;

    this.router.events.pipe(untilDestroyed(this)).subscribe(e => {
      if (e instanceof ActivationEnd) {
        if (!e.snapshot.firstChild) {
          prevSnapshot = currentSnapshot;
          currentSnapshot = e.snapshot;
        }
      } else if (e instanceof Scroll) {
        if (e.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          try {
            this.viewportScroller.scrollToAnchor(e.anchor);
          } catch (e) {}
        } else {
          // forward navigation

          if (!prevSnapshot || !currentSnapshot) {
            return;
          }

          if (
            prevSnapshot.component == currentSnapshot.component &&
            this.hasIgnoreScrollTop(currentSnapshot, prevSnapshot)
          ) {
            return;
          }

          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }

  hasIgnoreScrollTop(newSnapshot: ActivatedRouteSnapshot, prevSnapshot?: ActivatedRouteSnapshot) {
    if (newSnapshot.component && componentHasIgnoreScrollTop(newSnapshot.component)) {
      const options = getComponentIgnoreScrollTopOptions(newSnapshot.component);

      if (options && options.predicate) {
        return options.predicate(newSnapshot, prevSnapshot);
      }

      return true;
    } else if (newSnapshot.firstChild) {
      return this.hasIgnoreScrollTop(newSnapshot.firstChild, prevSnapshot);
    } else {
      return false;
    }
  }
}

<app-sidebar-field [label]="title" [required]="required" [additional]="additional">
  <div
    class="sidebar-list-item sidebar-list-item_clickable"
    [class.sidebar-list-item_error]="error"
    [ngClass]="classes"
    [appTip]="error"
    (click)="editClick.emit($event)"
  >
    <ng-container *ngIf="dataSourceValue; else empty_content">
      <div class="sidebar-list-item__left">
        <img *ngIf="dataSourceValue.image" class="sidebar-list-item__icon" [src]="dataSourceValue.image" />
        <span
          *ngIf="dataSourceValue.icon"
          class="sidebar-list-item__icon"
          [ngClass]="['icon-' + dataSourceValue.icon]"
        ></span>
        <span
          *ngIf="!dataSourceValue.image && !dataSourceValue.icon"
          class="sidebar-list-item__icon icon-components"
        ></span>
      </div>

      <div class="sidebar-list-item__main">
        <div *ngIf="dataSourceValue.title1 || dataSourceValue.title2" class="sidebar-list-item__title">
          <span *ngIf="dataSourceValue.tag" class="sidebar-list-item__tag">{{ dataSourceValue.tag }}</span>
          {{ dataSourceValue.title1 || dataSourceValue.title2 | appCapitalize }}
        </div>
        <div *ngIf="dataSourceValue.title1 && dataSourceValue.title2" class="sidebar-list-item__description">
          {{ dataSourceValue.title2 | appCapitalize }}
        </div>
      </div>
    </ng-container>

    <ng-template #empty_content>
      <div class="sidebar-list-item__left">
        <span class="sidebar-list-item__icon" [ngClass]="['icon-' + placeholderIcon]"></span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title sidebar-list-item__title_secondary">{{ placeholder }}</div>
      </div>
    </ng-template>

    <div class="sidebar-list-item__right">
      <ng-content select="ng-container[actions]"></ng-content>
      <a
        *ngIf="remove"
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); removeClick.emit()"
      >
      </a>
      <ng-container *ngIf="!remove">
        <span
          *ngIf="error"
          class="sidebar-list-item__action sidebar-list-item__action_error icon-warning_filled"
        ></span>
        <span *ngIf="!error" class="sidebar-list-item__action icon-arrow_forward_2"></span>
      </ng-container>
    </div>
  </div>

  <div *ngIf="dataSourceValue" class="sidebar-list-actions">
    <div class="sidebar-list-actions__item" (click)="onInputsClick($event)">
      <div class="sidebar-list-actions__item-icon icon-filter_2"></div>
      <div class="sidebar-list-actions__item-label">
        <ng-container *ngIf="control.getQueryEditable$() | async">
          <ng-container *ngIf="control.controls.query_inputs.value?.length">
            {{ control.controls.query_inputs.value?.length | i18nPlural: { '=1': '# input', other: '# inputs' } }}
          </ng-container>
          <ng-container *ngIf="!control.controls.query_inputs.value?.length">
            Add input
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!(control.getQueryEditable$() | async)">
          <ng-container *ngIf="control.controls.query_inputs.value?.length">
            {{ control.controls.query_inputs.value?.length | i18nPlural: { '=1': '# filter', other: '# filters' } }}
          </ng-container>
          <ng-container *ngIf="!control.controls.query_inputs.value?.length">
            Filter
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="sortingFieldControl && sortingAscControl"
      class="sidebar-list-actions__item"
      (click)="editSortingClick.emit($event)"
    >
      <div
        class="sidebar-list-actions__item-icon"
        [class.icon-sort]="!(sortingFieldControl.value | appIsSet)"
        [class.icon-filter_down]="(sortingFieldControl.value | appIsSet) && sortingAscControl.value !== false"
        [class.icon-filter_up]="(sortingFieldControl.value | appIsSet) && sortingAscControl.value === false"
      ></div>
      <div class="sidebar-list-actions__item-label">
        <ng-container *ngIf="sortingFieldControl.value | appIsSet">{{
          sortingFieldLabel | appCapitalize
        }}</ng-container>
        <ng-container *ngIf="!(sortingFieldControl.value | appIsSet)">Sort</ng-container>
      </div>
    </div>
  </div>

  <ng-container description>{{ description }}</ng-container>
</app-sidebar-field>

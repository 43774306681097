import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { Resource } from '@modules/projects';

import {
  ResourceChoosePagesComponent,
  ResourceChoosePagesItem
} from '../../components/resource-choose-pages/resource-choose-pages.component';

export interface ResourceChoosePagesOptions {
  resource?: Resource;
  modelPages?: boolean;
  optional?: boolean;
  analyticsSource?: string;
}

export interface ResourceChoosePagesResult<T = Object> {
  pages?: ResourceChoosePagesItem<T>[];
  cancelled?: boolean;
}

@Injectable()
export class ResourceChoosePagesController {
  constructor(private popupService: PopupService, private injector: Injector) {}

  choosePages<T>(
    resource: Resource,
    pages: ResourceChoosePagesItem<T>[],
    options: ResourceChoosePagesOptions = {}
  ): Observable<ResourceChoosePagesResult<T>> {
    const obs = new ReplaySubject<ResourceChoosePagesResult<T>>();

    this.popupService.push({
      component: ResourceChoosePagesComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        resource: resource,
        pages: pages,
        modelPages: options.modelPages,
        optional: options.optional
      },
      outputs: {
        selected: [
          result => {
            obs.next({
              pages: result
            });
          }
        ],
        cancelled: [() => obs.next({ cancelled: true })]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs.next({ cancelled: true });
        }
      },
      injector: this.injector
    });

    return obs;
  }
}

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class SeparatorElementItem extends ElementItem {
  public type = ElementType.Separator;

  get typeStr(): string {
    return 'separate components';
  }

  get analyticsName(): string {
    return 'separator';
  }

  defaultName() {
    return 'Separator';
  }
}

registerElementForType(ElementType.Separator, SeparatorElementItem);

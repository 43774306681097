import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsCurrentYearFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_current_year';
  public param = 'isCurrentYear';
  public verboseName = localize('is Current Year');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('year').toISOString();
  }

  to(value: string): string {
    return moment().endOf('year').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not current year', [field]);
      } else {
        return localize('is not current year');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is current year', [field]);
      } else {
        return localize('current year');
      }
    }
  }
}

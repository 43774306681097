import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { FieldTypeSection, FieldTypeSectionItem } from '@modules/field-components';
import { fieldDescriptions, FieldType, registerFieldComponent } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-field-type-field',
  templateUrl: './field-type-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldTypeFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() fieldTypeSections: FieldTypeSection[];

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  currentValueLabel: string;
  currentValueIcon: string;
  currentValueSubscription: Subscription;
  fieldTypeSectionItems: FieldTypeSectionItem[];
  dropdownOpened = false;
  dropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  dropdownPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initCurrentValue();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FieldTypeFieldComponent>): void {
    if (changes.fieldTypeSections) {
      this.fieldTypeSectionItems = this.fieldTypeSections
        ? this.fieldTypeSections.reduce((acc, section) => {
            const items = section.children.map(item => {
              return {
                ...item,
                icon: item.icon || section.icon
              };
            });

            acc.push(...items);

            return acc;
          }, [])
        : undefined;
    }

    if (changes.value) {
      this.initCurrentValue();
    }
  }

  ngAfterViewInit(): void {
    this.setDropdownPositionObserver();
  }

  initCurrentValue() {
    if (this.currentValueSubscription) {
      this.currentValueSubscription.unsubscribe();
      this.currentValueSubscription = undefined;
    }

    this.currentValueSubscription = this.currentValue$.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.fieldTypeSectionItems) {
        const currentItem = isSet(value) ? this.fieldTypeSectionItems.find(item => item.value == value) : undefined;
        this.currentValueLabel = currentItem ? currentItem.name : undefined;
        this.currentValueIcon = currentItem ? currentItem.icon : undefined;
      } else {
        const fieldDescription = isSet(value) ? fieldDescriptions.find(item => item.name == value) : undefined;
        this.currentValueLabel = fieldDescription ? fieldDescription.label : undefined;
        this.currentValueIcon = fieldDescription ? fieldDescription.icon : undefined;
      }

      this.cd.markForCheck();
    });
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  onInputClick() {
    this.setDropdownOpened(true);
  }

  setDropdownPositionObserver() {
    if (this.dropdownPositionsSubscription) {
      this.dropdownPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.dropdownPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.dropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.dropdownPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

registerFieldComponent(FieldType.FieldType, FieldTypeFieldComponent);

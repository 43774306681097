import { DatasetGroupLookup } from '@modules/charts';

export const lineChartSampleData = [
  {
    name: undefined,
    color: 'gray',
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: '2008-08-12T12:20:30.656234Z', value: 400 },
      { group: '2008-08-13T12:20:30.656234Z', value: 500 },
      { group: '2008-08-14T12:20:30.656234Z', value: 200 },
      { group: '2008-08-15T12:20:30.656234Z', value: 700 },
      { group: '2008-08-16T12:20:30.656234Z', value: 400 },
      { group: '2008-08-17T12:20:30.656234Z', value: 1000 },
      { group: '2008-08-18T12:20:30.656234Z', value: 400 },
      { group: '2008-08-19T12:20:30.656234Z', value: 500 },
      { group: '2008-08-20T12:20:30.656234Z', value: 200 },
      { group: '2008-08-21T12:20:30.656234Z', value: 700 },
      { group: '2008-08-22T12:20:30.656234Z', value: 400 },
      { group: '2008-08-23T12:20:30.656234Z', value: 1000 }
    ]
  }
];

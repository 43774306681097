import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-template-preview-stub',
  templateUrl: './page-template-preview-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewStubComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

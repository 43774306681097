var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { WorkflowStepType } from '../workflow-step-type';
import { registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
var DelayWorkflowStep = /** @class */ (function (_super) {
    __extends(DelayWorkflowStep, _super);
    function DelayWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Delay;
        _this.delay = 3;
        return _this;
    }
    DelayWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.delay = data['delay'];
        return this;
    };
    DelayWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { delay: this.delay });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    DelayWorkflowStep.prototype.defaultName = function () {
        return 'Delay';
    };
    Object.defineProperty(DelayWorkflowStep.prototype, "analyticsName", {
        get: function () {
            return 'delay';
        },
        enumerable: true,
        configurable: true
    });
    DelayWorkflowStep.prototype.getIcon = function () {
        return 'time';
    };
    return DelayWorkflowStep;
}(WorkflowStep));
export { DelayWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Delay, DelayWorkflowStep);

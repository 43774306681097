/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "../../../../../parameters-components/components/input-edit/input-edit.component.ngfactory";
import * as i3 from "../../../../../parameters-components/components/input-edit/input-edit.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i6 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i7 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i8 from "../../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i9 from "../../../../../fields/components/auto-field/auto-field.component";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../field-components/components/custom-select/custom-select.component.ngfactory";
import * as i12 from "../../../../../field-components/components/custom-select/custom-select.component";
import * as i13 from "../../../../../field-components/components/field-errors/field-errors.component.ngfactory";
import * as i14 from "../../../../../field-components/components/field-errors/field-errors.component";
import * as i15 from "./customize-bar-action-edit-type-link.component";
import * as i16 from "../../../../../customize/stores/view-settings.store";
import * as i17 from "../../../../../customize-utils/services/view-settings-queries.service";
import * as i18 from "../../../../../action-queries/services/action-controller/action-controller.service";
var styles_CustomizeBarActionEditTypeLinkComponent = [];
var RenderType_CustomizeBarActionEditTypeLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeLinkComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeLinkComponent as RenderType_CustomizeBarActionEditTypeLinkComponent };
function View_CustomizeBarActionEditTypeLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "control__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["class", "button button_bigger button_icon button_orange-alternative control__button"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-open_folder button__icon"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "Open Page"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomizeBarActionEditTypeLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-input-edit", [], null, null, null, i2.View_InputEditComponent_0, i2.RenderType_InputEditComponent)), i0.ɵdid(1, 770048, null, 0, i3.InputEditComponent, [i4.FocusMonitor, i5.UniversalAnalyticsService, i0.NgZone, i0.ChangeDetectorRef], { itemForm: [0, "itemForm"], context: [1, "context"], contextElement: [2, "contextElement"], contextElementPath: [3, "contextElementPath"], contextElementPaths: [4, "contextElementPaths"], staticValueDisabled: [5, "staticValueDisabled"], classes: [6, "classes"], fill: [7, "fill"], analyticsSource: [8, "analyticsSource"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.controls.new_tab_custom; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.contextElementPath; var currVal_4 = _co.contextElementPaths; var currVal_5 = true; var currVal_6 = _ck(_v, 2, 0, "input_segment-bottom"); var currVal_7 = true; var currVal_8 = (_co.analyticsSource + "_external_link_new_tab"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_CustomizeBarActionEditTypeLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Example: EQ(elements.file_name, \"jpg\") "]))], null, null); }
function View_CustomizeBarActionEditTypeLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "app-sidebar-field", [], null, null, null, i6.View_SidebarFieldComponent_0, i6.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i7.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 4, "app-auto-field", [], null, null, null, i8.View_AutoFieldComponent_0, i8.RenderType_AutoFieldComponent)), i0.ɵdid(4, 770048, null, 0, i9.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"], label: [2, "label"] }, null), i0.ɵpad(5, 2), i0.ɵpod(6, { options: 0, classes: 1 }), i0.ɵpod(7, { name: 0, field: 1, params: 2 }), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_CustomizeBarActionEditTypeLinkComponent_3)), i0.ɵdid(9, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 4, 1, null, View_CustomizeBarActionEditTypeLinkComponent_4)), i0.ɵdid(11, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Open a new tab"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.form; var currVal_2 = _co.createField(_ck(_v, 7, 0, "new_tab", "SelectField", _ck(_v, 6, 0, _co.form.newTabOptions, _ck(_v, 5, 0, "select_fill", ((_co.form.value["new_tab"] === "custom") ? "select_segment-top" : ""))))); var currVal_3 = false; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.form.value["new_tab"] === "custom"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.form.value["new_tab"] === "custom"); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_CustomizeBarActionEditTypeLinkComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "app-sidebar-field", [], null, null, null, i6.View_SidebarFieldComponent_0, i6.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i7.SidebarFieldComponent, [], { label: [0, "label"], required: [1, "required"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 7, "div", [["class", "control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "control__input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "app-custom-select", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.onButtonItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_CustomSelectComponent_0, i11.RenderType_CustomSelectComponent)), i0.ɵdid(6, 770048, null, 0, i12.CustomSelectComponent, [i0.ChangeDetectorRef], { control: [0, "control"], items: [1, "items"], compareWith: [2, "compareWith"], emptyLabel: [3, "emptyLabel"], classes: [4, "classes"], panelClasses: [5, "panelClasses"] }, { buttonClick: "buttonClick" }), i0.ɵpad(7, 1), i0.ɵpad(8, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeLinkComponent_1)), i0.ɵdid(10, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, 3, 1, "app-field-errors", [], null, null, null, i13.View_FieldErrorsComponent_0, i13.RenderType_FieldErrorsComponent)), i0.ɵdid(12, 114688, null, 0, i14.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeLinkComponent_2)), i0.ɵdid(14, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "link"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.form.controls.link; var currVal_3 = _co.linkOptions; var currVal_4 = _co.form.linkValueEquals; var currVal_5 = "No pages found"; var currVal_6 = _ck(_v, 7, 0, "select_fill"); var currVal_7 = _ck(_v, 8, 0, "mat-menu-panel_sidebar"); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.page; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.form; var currVal_10 = "link"; _ck(_v, 12, 0, currVal_9, currVal_10); var currVal_11 = (((_co.form.controls.link.value == null) ? null : _co.form.controls.link.value.type) != _co.segueTypes.PreviousPage); _ck(_v, 14, 0, currVal_11); }, null); }
export function View_CustomizeBarActionEditTypeLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-link", [], null, null, null, View_CustomizeBarActionEditTypeLinkComponent_0, RenderType_CustomizeBarActionEditTypeLinkComponent)), i0.ɵdid(1, 245760, null, 0, i15.CustomizeBarActionEditTypeLinkComponent, [i16.ViewSettingsStore, i17.ViewSettingsQueries, i18.ActionControllerService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeLinkComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-link", i15.CustomizeBarActionEditTypeLinkComponent, View_CustomizeBarActionEditTypeLinkComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeLinkComponentNgFactory as CustomizeBarActionEditTypeLinkComponentNgFactory };

import { QueryType, StorageQuery } from '@modules/queries';

import { Storage } from './storage';

export const JET_APP_STORAGE = 'storage';

export function getJetAppStorage() {
  const result = new Storage();

  result.uniqueName = JET_APP_STORAGE;
  result.name = 'Storage';

  result.uploadQuery = new StorageQuery();
  result.uploadQuery.queryType = QueryType.Simple;
  result.uploadQuery.simpleQuery = new result.uploadQuery.simpleQueryClass();

  result.getQuery = new StorageQuery();
  result.getQuery.queryType = QueryType.Simple;
  result.getQuery.simpleQuery = new result.getQuery.simpleQueryClass();

  result.removeQuery = new StorageQuery();
  result.removeQuery.queryType = QueryType.Simple;
  result.removeQuery.simpleQuery = new result.removeQuery.simpleQueryClass();

  result.createDirectoryQuery = new StorageQuery();
  result.createDirectoryQuery.queryType = QueryType.Simple;
  result.createDirectoryQuery.simpleQuery = new result.createDirectoryQuery.simpleQueryClass();

  return result;
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, ResourceName } from '@modules/projects';
import { TokenStructureItem } from '@modules/tokens';
import { ascComparator, isSet, KeyboardEventKeyCode, splitmax, stripTags } from '@shared';

@Component({
  selector: 'app-table-tokens',
  templateUrl: './table-tokens.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTokensComponent implements OnInit, OnDestroy {
  @Input() resourceUniqueName: string;
  @Output() inserted = new EventEmitter<string>();

  searchControl = new FormControl('');
  resourceStructures: TokenStructureItem[];
  resourceStructuresFiltered: TokenStructureItem[];

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateTables();

    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateResourceStructuresFiltered());
  }

  ngOnDestroy(): void {}

  mapModelDescriptionTables(
    resource: Resource,
    modelDescriptions: ModelDescription[],
    insertFull = false
  ): TokenStructureItem[] {
    const quote = (str: string): string => {
      if ([ResourceName.Oracle].includes(resource.typeItem.name)) {
        return `"${str}"`;
      } else if (str.match(/^[a-z][a-z\d_]*$/)) {
        return str;
      } else if (
        [ResourceName.JetDatabase, ResourceName.PostgreSQL].includes(resource.typeItem.name) ||
        resource.isSynced() ||
        resource.hasCollectionSync()
      ) {
        return `"${str}"`;
      } else {
        return str;
      }
    };

    if (resource.typeItem.name == ResourceName.BigQuery) {
      interface DatasetItem {
        item: ModelDescription;
        name: string;
      }

      const datasets = modelDescriptions
        .filter(item => isSet(item.dbTable) && item.dbTable.includes('.'))
        .sort((lhs, rhs) => ascComparator(lhs.dbTable, rhs.dbTable))
        .reduce<{ [k: string]: DatasetItem[] }>((acc, item) => {
          const [dataset, name] = splitmax(item.dbTable, '.', 2);
          if (!acc[dataset]) {
            acc[dataset] = [];
          }

          acc[dataset].push({
            item: item,
            name: name
          });

          return acc;
        }, {});

      return toPairs<DatasetItem[]>(datasets)
        .map(([dataset, items]) => {
          return {
            name: dataset,
            label: dataset,
            insert: quote(dataset),
            children: items
              .map(item => {
                return {
                  name: item.item.dbTable,
                  label: item.item.verboseNamePlural,
                  insert: insertFull
                    ? [quote(item.item.resource), quote(item.item.dbTable)].join('.')
                    : quote(item.item.dbTable),
                  children: item.item.dbFields
                    .filter(field => isSet(field.dbColumn))
                    .map(field => {
                      return {
                        name: field.dbColumn,
                        label: field.verboseName,
                        insert: quote(field.dbColumn),
                        children: []
                      };
                    })
                };
              })
              .sort((lhs, rhs) => ascComparator(lhs.label, rhs.label))
          };
        })
        .filter(item => item.children.length);
    } else {
      return modelDescriptions
        .filter(item => isSet(item.dbTable))
        .sort((lhs, rhs) => ascComparator(lhs.dbTable, rhs.dbTable))
        .map(item => {
          return {
            name: item.dbTable,
            label: item.verboseNamePlural,
            insert: insertFull ? [quote(item.resource), quote(item.dbTable)].join('.') : quote(item.dbTable),
            children: item.dbFields
              .filter(field => isSet(field.dbColumn))
              .map(field => {
                return {
                  name: field.dbColumn,
                  label: field.verboseName,
                  insert: quote(field.dbColumn),
                  children: []
                };
              })
          };
        })
        .filter(item => item.children.length);
    }
  }

  updateTables() {
    const currentResource = this.currentProjectStore.instance
      .getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName)
      .find(item => item.uniqueName == this.resourceUniqueName);
    const otherProjectDatabasesResources =
      currentResource && currentResource.isUsingProjectDatabase()
        ? this.currentProjectStore.instance
            .getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName)
            .filter(item => !item.demo && item.uniqueName != this.resourceUniqueName && item.isUsingProjectDatabase())
            .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name))
        : [];

    this.modelDescriptionStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const otherResourceTables = otherProjectDatabasesResources
          .map(resource => {
            return {
              name: resource.uniqueName,
              label: resource.name,
              icon: resource.icon,
              children: this.mapModelDescriptionTables(
                resource,
                result.filter(model => model.resource == resource.uniqueName),
                true
              )
            };
          })
          .filter(item => item.children.length);

        this.resourceStructures = [
          {
            name: currentResource.uniqueName,
            ...(otherResourceTables.length
              ? {
                  label: currentResource.name,
                  subtitle: 'current resource',
                  icon: currentResource.icon
                }
              : {}),
            children: this.mapModelDescriptionTables(
              currentResource,
              result.filter(model => model.resource == currentResource.uniqueName)
            )
          },
          ...otherResourceTables
        ];

        this.cd.markForCheck();
        this.updateResourceStructuresFiltered();
      });
  }

  searchStr(str: string, query: string): { match: boolean; marked?: string } {
    if (!isSet(str)) {
      return { match: false };
    }

    const strSafe = stripTags(str);
    const index = strSafe.toLocaleLowerCase().indexOf(query);

    if (index === -1) {
      return { match: false };
    }

    return {
      match: true,
      marked: [
        strSafe.substring(0, index),
        '<mark>',
        strSafe.substring(index, index + query.length),
        '</mark>',
        strSafe.substring(index + query.length)
      ].join('')
    };
  }

  updateResourceStructuresFiltered() {
    if (!isSet(this.searchControl.value)) {
      this.resourceStructuresFiltered = this.resourceStructures;
      this.cd.markForCheck();
      return;
    }

    const query = this.searchControl.value.toLocaleLowerCase();

    this.resourceStructuresFiltered = this.resourceStructures.reduce((resourceStructureAcc, resourceStructure) => {
      const tablesFiltered = resourceStructure.children.reduce((tablesAcc, table) => {
        const tableNameMatch = this.searchStr(table.name, query);
        const tableLabelMatch = this.searchStr(table.label, query);

        if (tableNameMatch.match || tableLabelMatch.match) {
          tablesAcc.push({
            ...table,
            ...(tableNameMatch.match ? { name: tableNameMatch.marked } : {}),
            ...(tableLabelMatch.match ? { label: tableLabelMatch.marked } : {})
          });
        } else {
          const tableColumnsFiltered = table.children.reduce((tableColumnsAcc, tableColumn) => {
            const childNameMatch = this.searchStr(tableColumn.name, query);
            const childLabelMatch = this.searchStr(tableColumn.label, query);

            if (childNameMatch.match || childLabelMatch.match) {
              tableColumnsAcc.push({
                ...tableColumn,
                ...(childNameMatch.match ? { name: childNameMatch.marked } : {}),
                ...(childLabelMatch.match ? { label: childLabelMatch.marked } : {})
              });
            }

            return tableColumnsAcc;
          }, []);

          if (tableColumnsFiltered.length) {
            tablesAcc.push({
              ...table,
              children: tableColumnsFiltered,
              openedInitial: true
            });
          }
        }

        return tablesAcc;
      }, []);

      if (tablesFiltered.length) {
        resourceStructureAcc.push({
          ...resourceStructure,
          children: tablesFiltered,
          openedInitial: true
        });
      }

      return resourceStructureAcc;
    }, []);
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateResourceStructuresFiltered();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';

@Injectable()
export class SlackService {
  constructor(private http: HttpClient, private apiService: ApiService, private projectApiService: ProjectApiService) {}

  getLink(projectName: string, environmentName: string, name: string): Observable<string> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, `links/${name}/get`);
        let headers = new HttpHeaders();
        const data = {};

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post<{ url: string }>(url, data, { headers: headers });
      }),
      map(result => result.url),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  getSlackJoinLink(projectName: string, environmentName: string): Observable<string> {
    return this.getLink(projectName, environmentName, 'slack_join');
  }

  getSlackChannel(projectName: string, environmentName: string): Observable<SlackService.SlackChannelResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'slack_channel');
        let headers = new HttpHeaders();

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers });
      }),
      map(result => new SlackService.SlackChannelResponse().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }

  joinSlackChannel(
    projectName: string,
    environmentName: string,
    email: string,
    channel: string
  ): Observable<SlackService.SlackChannelResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'slack_channel/join');
        let headers = new HttpHeaders();
        const data = {
          email: email,
          channel: channel
        };

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new SlackService.SlackChannelResponse().deserialize(result)),
      this.apiService.catchApiError(false),
      publishLast(),
      refCount()
    );
  }
}

export namespace SlackService {
  export class SlackChannel {
    public id: string;
    public name: string;

    deserialize(data: Object) {
      this.id = data['slack_channel_id'];
      this.name = data['slack_channel_name'];

      return this;
    }

    get href(): string {
      return `https://jetadmin.slack.com/archives/${this.id}`;
    }
  }

  export class SlackChannelUser {
    public id: string;
    public email: string;

    deserialize(data: Object) {
      this.id = data['slack_user_id'];
      this.email = data['slack_user_email'];

      return this;
    }
  }

  export class SlackChannelResponse {
    public channelPrefix: string;
    public channel: SlackChannel;
    public channelUser: SlackChannelUser;

    deserialize(data: Object) {
      this.channelPrefix = data['channel_prefix'];

      if (data['channel']) {
        this.channel = new SlackChannel().deserialize(data['channel']);
      }

      if (data['channel_user']) {
        this.channelUser = new SlackChannelUser().deserialize(data['channel_user']);
      }

      return this;
    }

    isJoined(): boolean {
      return !!this.channel && !!this.channelUser;
    }
  }
}

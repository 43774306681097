import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subject } from 'rxjs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, JET_APP_RESOURCE, Resource } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { TemplateService } from '@modules/template';
import { TemplateProvider } from '../../../services/template-provider/template.provider';
import { CustomizeBarTab } from '../change-customize-bar.component';
var ChangeCustomizeBarTemplatesComponent = /** @class */ (function () {
    function ChangeCustomizeBarTemplatesComponent(currentProjectStore, currentEnvironmentStore, templateService, resourceEditController, templateProvider, analyticsService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.templateService = templateService;
        this.resourceEditController = resourceEditController;
        this.templateProvider = templateProvider;
        this.analyticsService = analyticsService;
        this.injector = injector;
        this.cd = cd;
        this.dropListGroups = [];
        this.firstVisible = false;
        this.selectTab = new EventEmitter();
        this.scrollToTop = new EventEmitter();
        this.loading = false;
        this.installedItems = [];
        this.installedItemsFiltered = [];
        this.otherItems = [];
        this.otherItemsFiltered = [];
        this.search = '';
        this.searchUpdated = new Subject();
        this.customizeBarTabs = CustomizeBarTab;
    }
    ChangeCustomizeBarTemplatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.updateGroups();
        this.searchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFiltered(); });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.IntegrationsViewed);
    };
    ChangeCustomizeBarTemplatesComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarTemplatesComponent.prototype.updateGroups = function () {
        var _this = this;
        var resources = this.currentEnvironmentStore.resources.filter(function (item) { return !item.demo && item.uniqueName != JET_APP_RESOURCE; });
        combineLatest(this.templateProvider.getInstalledGroups(resources), this.templateProvider.getOtherGroups())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var installedItems = _a[0], otherItems = _a[1];
            _this.installedItems = installedItems;
            _this.otherItems = otherItems;
            if (_this.initialResource) {
                var installedItem = _this.installedItems.find(function (item) { return item.resource.uniqueName == _this.initialResource.uniqueName; });
                if (installedItem) {
                    _this.setSelectedItem(installedItem);
                }
            }
            else if (_this.initialResourceType) {
                var installedItem = _this.installedItems.find(function (item) { return item.resource.typeItem.name === _this.initialResourceType.name; });
                var otherItem = _this.otherItems.find(function (item) { return item.resourceTypeItem && item.resourceTypeItem.name === _this.initialResourceType.name; });
                if (installedItem) {
                    _this.setSelectedItem(installedItem);
                }
                else if (installedItem) {
                    _this.setSelectedItem(otherItem);
                }
            }
            else if (_this.firstVisible && _this.context && _this.context.modelDescription) {
                var resource_1 = resources.find(function (item) { return item.uniqueName == _this.context.modelDescription.resource; });
                if (resource_1) {
                    _this.setSelectedItem(_this.installedItems.find(function (item) { return item.resource === resource_1; }));
                }
            }
            if (_this.selectedItem && _this.selectedItem.resource) {
                _this.selectedItem = _this.installedItems.find(function (item) { return item.resource && item.resource.uniqueName == _this.selectedItem.resource.uniqueName; });
            }
            _this.loading = false;
            _this.cd.markForCheck();
            _this.updateFiltered();
        });
    };
    ChangeCustomizeBarTemplatesComponent.prototype.setSelectedItem = function (item) {
        this.selectedItem = item;
        this.selectResourceType = undefined;
        this.selectResourceGroup = undefined;
        this.search = '';
        this.updateFiltered();
        this.cd.detectChanges();
        this.scrollToTop.emit(true);
        if (this.selectedItem) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.IntegrationsResourceViewed, {
                ResourceConnected: !!this.selectedItem.resource,
                ResourceType: this.selectedItem.resourceTypeItem ? this.selectedItem.resourceTypeItem.name : undefined
            });
        }
    };
    ChangeCustomizeBarTemplatesComponent.prototype.setSelectResourceType = function (group, type) {
        this.selectResourceType = type;
        this.selectResourceGroup = group;
        this.selectedItem = undefined;
        this.cd.detectChanges();
        this.scrollToTop.emit(true);
    };
    ChangeCustomizeBarTemplatesComponent.prototype.filterItems = function (items) {
        var _this = this;
        return items.filter(function (item) {
            if (!_this.search) {
                return true;
            }
            if (item.resource && item.resource.name.toLowerCase().includes(_this.search.toLowerCase())) {
                return true;
            }
            return item.title.toLowerCase().includes(_this.search.toLowerCase());
        });
    };
    ChangeCustomizeBarTemplatesComponent.prototype.updateFiltered = function () {
        this.installedItemsFiltered = this.filterItems(this.installedItems);
        this.otherItemsFiltered = this.filterItems(this.otherItems);
        if ([this.installedItemsFiltered, this.otherItemsFiltered].every(function (item) { return !item.length; })) {
            this.emptyLabel = this.search.length ? 'Nothing found' : 'There are no templates';
        }
        else {
            this.emptyLabel = undefined;
        }
        this.cd.markForCheck();
    };
    ChangeCustomizeBarTemplatesComponent.prototype.addResource = function (typeItem) {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
            Source: 'builder_component_templates',
            ResourceID: typeItem.name
        });
        this.resourceEditController
            .createResource(typeItem, {
            resourceNameEditing: true,
            analyticsSource: 'builder_component_templates'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (!result.resource) {
                return;
            }
            _this.updateGroups();
            var selectedItem = _this.installedItems.find(function (item) { return item.resource && item.resource.uniqueName === result.resource.uniqueName; });
            if (selectedItem) {
                _this.setSelectedItem(selectedItem);
            }
        });
    };
    ChangeCustomizeBarTemplatesComponent.prototype.onItemClick = function (group) {
        if (!group.resource && group.requireResource) {
            if (group.resourceTypeItem) {
                this.addResource(group.resourceTypeItem);
            }
            else if (group.resourceType) {
                this.setSelectResourceType(group, group.resourceType);
                this.selectResourceGroup = group;
                this.cd.markForCheck();
            }
        }
        else {
            this.setSelectedItem(group);
        }
    };
    return ChangeCustomizeBarTemplatesComponent;
}());
export { ChangeCustomizeBarTemplatesComponent };

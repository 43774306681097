var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { listLayouts, ListLayoutType } from '@modules/layouts';
import { TableSettings } from '../../table-settings';
import { getListLayoutSettingsClass } from '../../view-settings/list';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var ListElementItem = /** @class */ (function (_super) {
    __extends(ListElementItem, _super);
    function ListElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.List;
        _this.layouts = [];
        return _this;
    }
    ListElementItem.prototype.deserialize = function (data) {
        var _this = this;
        _super.prototype.deserialize.call(this, data);
        this.height = this.params['height'] || 600;
        if (this.params['title']) {
            this.name = this.params['title'];
        }
        if (this.params['layouts'] || this.params['layout']) {
            var layoutsData = this.params['layouts'] || [this.params['layout']];
            this.layouts = layoutsData
                .map(function (item) {
                if (typeof item == 'string') {
                    item = { type: item };
                }
                var cls = getListLayoutSettingsClass(item['type']);
                if (!cls) {
                    return;
                }
                if (item['title'] === undefined && _this.name) {
                    item['title'] = _this.name;
                }
                return new cls().deserialize(item);
            })
                .filter(function (item) { return item != undefined; });
        }
        else {
            this.layouts = [new TableSettings()];
        }
        if (this.params['default_layout']) {
            this.layouts = this.layouts.sort(function (lhs, rhs) {
                var defaultType = _this.params['default_layout'];
                if (lhs.type == defaultType && rhs.type != defaultType) {
                    return -1;
                }
                else if (lhs.type != defaultType && rhs.type == defaultType) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        if (this.params['table_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Table; });
            if (settings) {
                settings.deserialize(this.params['table_settings']);
            }
        }
        if (this.params['map_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Map; });
            if (settings) {
                settings.deserialize(this.params['map_settings']);
            }
        }
        if (this.params['kanban_board_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.KanbanBoard; });
            if (settings) {
                settings.deserialize(this.params['kanban_board_settings']);
            }
        }
        if (this.params['calendar_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Calendar; });
            if (settings) {
                settings.deserialize(this.params['calendar_settings']);
            }
        }
        if (this.params['grid_settings']) {
            var settings = this.layouts.find(function (item) { return item.type == ListLayoutType.Grid; });
            if (settings) {
                settings.deserialize(this.params['grid_settings']);
            }
        }
        return this;
    };
    ListElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['height'] = this.height;
        this.params['layouts'] = this.layouts.map(function (item) { return item.serialize(); });
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    ListElementItem.prototype.getOrCreateLayout = function (layout) {
        var settings = this.layouts.find(function (item) { return item.type == layout; });
        if (settings) {
            return settings;
        }
        else {
            var cls = getListLayoutSettingsClass(layout);
            var instance = new cls();
            this.layouts = this.layouts.concat([instance]);
            return instance;
        }
    };
    Object.defineProperty(ListElementItem.prototype, "listLayout", {
        get: function () {
            var _this = this;
            if (!this.layouts[0]) {
                return;
            }
            return listLayouts.find(function (item) { return item.type == _this.layouts[0].type; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListElementItem.prototype, "typeStr", {
        get: function () {
            var layout = this.listLayout;
            return layout ? layout.description : 'list';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListElementItem.prototype, "analyticsName", {
        get: function () {
            var layout = this.layouts[0];
            if (!layout) {
                return;
            }
            return layout.analyticsName;
        },
        enumerable: true,
        configurable: true
    });
    ListElementItem.prototype.defaultName = function () {
        if (!this.layouts[0]) {
            return 'list';
        }
        var type = this.layouts[0].type;
        if (type == ListLayoutType.Table) {
            return 'Table';
        }
        else if (type == ListLayoutType.Map) {
            return 'Map';
        }
        else if (type == ListLayoutType.KanbanBoard) {
            return 'Kanban Board';
        }
        else if (type == ListLayoutType.Calendar) {
            return 'Calendar';
        }
        else if (type == ListLayoutType.Grid) {
            return 'Gallery';
        }
        else if (type == ListLayoutType.Carousel) {
            return 'Carousel';
        }
        else if (type == ListLayoutType.Timeline) {
            return 'Timeline';
        }
    };
    return ListElementItem;
}(ElementItem));
export { ListElementItem };
registerElementForType(ElementType.List, ListElementItem);

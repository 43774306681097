<mat-menu [overlapTrigger]="false">
  <ng-template matMenuContent>
    <ng-container *ngIf="items">
      <ng-container *ngFor="let item of items">
        <button *ngIf="!item.children?.length" mat-menu-item [disableRipple]="true" (click)="selected.emit(item.path)">
          {{ item.label }}
        </button>

        <ng-container *ngIf="item.children.length">
          <app-query-builder-response-keys [items]="item.children" (selected)="selected.emit($event)" #children>
          </app-query-builder-response-keys>

          <button
            mat-menu-item
            [disableRipple]="true"
            [matMenuTriggerFor]="children.menu"
            (click)="selected.emit(item.path)"
          >
            {{ item.label }}
          </button>
        </ng-container>
      </ng-container>
      <div *ngIf="items?.length == 0" class="mat-menu-item-message">No items</div>
    </ng-container>
    <div *ngIf="!items" class="mat-menu-item-message">Send request first</div>
  </ng-template>
</mat-menu>

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormUtils } from '../../services/form-utils';

@Pipe({
  name: 'appControlErrors',
  pure: false
})
export class ControlErrorsPipe implements PipeTransform {
  constructor(private formUtils: FormUtils) {}

  transform(control: AbstractControl, touchOnly = false): string[] {
    return this.formUtils.getControlErrors(control, { touchOnly: touchOnly });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Subject } from 'rxjs';
import { PopoverService } from '@common/popover';
import { UniversalAnalyticsService } from '@modules/analytics';
import { FieldsEditComponent, FieldsEditForm } from '@modules/field-components';
import { AutofocusDirective, controlValue, isSet } from '@shared';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
// TODO: Remove unused
var CustomizeBarFieldsEditComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarFieldsEditComponent, _super);
    function CustomizeBarFieldsEditComponent(form, customizeBarService, customizeBarContext, analyticsService, popoverService, injector, cd) {
        var _this = _super.call(this, form, analyticsService, popoverService, injector, cd) || this;
        _this.form = form;
        _this.customizeBarService = customizeBarService;
        _this.customizeBarContext = customizeBarContext;
        _this.analyticsService = analyticsService;
        _this.popoverService = popoverService;
        _this.injector = injector;
        _this.cd = cd;
        _this.itemName = 'field';
        _this.collapsible = true;
        _this.humanUniqueName = false;
        _this.toggle = true;
        _this.searchEnabled = false;
        _this.searchFocus = false;
        _this.firstInit = false;
        _this.searchCleared = new EventEmitter();
        _this.displayItems = [];
        _this.maxDisplayInitial = 8;
        _this.collapsed = true;
        _this.search = '';
        _this.searchUpdated = new Subject();
        _this.sendParametersAnalytics = false;
        return _this;
    }
    CustomizeBarFieldsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        merge(controlValue(this.form.form), this.searchUpdated)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateDisplayItems(); });
    };
    CustomizeBarFieldsEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarFieldsEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes['searchEnabled'] && !this.searchEnabled) {
            this.clearSearch();
        }
    };
    CustomizeBarFieldsEditComponent.prototype.editItem = function (itemForm) {
        var initialElement = cloneDeep(itemForm.value);
        this.customizeBarService
            .customizeColumn({
            context: this.customizeBarContext,
            column: itemForm.value,
            configurable: {
                verboseName: true,
                action: this.configurable.action,
                sortable: this.configurable.sortable,
                editable: this.configurable.editable,
                value: !!itemForm.value['flex']
            },
            viewContext: this.context,
            viewContextElement: this.contextElement,
            viewContextElementPath: this.contextElementPath,
            viewContextElementPaths: this.contextElementPaths,
            append: true,
            firstInit: this.firstInit
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Updated) {
                itemForm.patchValue(e.args['result']);
                itemForm.markAsDirty();
            }
            else if (e.type == CustomizeBarEditEventType.Canceled) {
                itemForm.patchValue(initialElement);
                itemForm.markAsDirty();
            }
        });
        this.clearSearch();
    };
    CustomizeBarFieldsEditComponent.prototype.getMoveToTopPosition = function (itemForm) {
        var currentIndex = this.form.form.controls.indexOf(itemForm);
        var lastVisibleIndex = this.form.form.controls
            .map(function (item, i) { return i < currentIndex && !!item.value['visible']; })
            .lastIndexOf(true);
        return lastVisibleIndex != -1 ? lastVisibleIndex + 1 : currentIndex;
    };
    CustomizeBarFieldsEditComponent.prototype.moveToTop = function (itemForm) {
        var currentIndex = this.form.form.controls.indexOf(itemForm);
        var newIndex = this.getMoveToTopPosition(itemForm);
        if (!itemForm.value['visible']) {
            itemForm.patchValue({ visible: true });
        }
        moveItemInArray(this.form.form.controls, currentIndex, newIndex);
        this.updateDisplayItems();
        this.cd.detectChanges();
    };
    CustomizeBarFieldsEditComponent.prototype.removeItem = function (itemForm) {
        this.form.arrayDelete(itemForm);
    };
    CustomizeBarFieldsEditComponent.prototype.getAddItemUniqueField = function (field, prefix, separator, skipFirst) {
        if (separator === void 0) { separator = '_'; }
        if (skipFirst === void 0) { skipFirst = false; }
        var value = this.form.form.value;
        var nextNumber = 1;
        var result;
        do {
            result = skipFirst && nextNumber == 1 ? prefix : "" + prefix + separator + nextNumber;
            ++nextNumber;
        } while (value.find(function (group) { return group[field] == result; }));
        return result;
    };
    CustomizeBarFieldsEditComponent.prototype.addItem = function () {
        var uniqueName = this.humanUniqueName
            ? this.getAddItemUniqueField('name', 'Custom Field', ' ', true)
            : this.getAddItemUniqueField('name', '_jet_field');
        var verboseName = this.getAddItemUniqueField('verboseName', 'Custom Field', ' ', true);
        var item = this.form.createItem({
            name: uniqueName,
            verboseName: verboseName,
            flex: true,
            editable: this.configurable.editable
        });
        this.form.arrayAppend(item);
        this.updateDisplayItems();
        this.editItem(item);
        this.clearSearch();
    };
    CustomizeBarFieldsEditComponent.prototype.updateDisplayItems = function () {
        var processSearch = function (str) { return (str || '').trim().toLowerCase(); };
        var search = processSearch(this.search);
        if (isSet(search)) {
            this.displayItems = this.form.form.controls.filter(function (item) {
                return (processSearch(item.value['verboseName']).indexOf(search) !== -1 ||
                    processSearch(item.value['name']).indexOf(search) !== -1);
            });
        }
        else if (this.collapsible && this.collapsed) {
            this.displayItems = this.form.form.controls.slice(0, this.maxDisplayInitial);
        }
        else {
            this.displayItems = this.form.form.controls;
        }
        this.cd.markForCheck();
    };
    CustomizeBarFieldsEditComponent.prototype.setCollapsed = function (value) {
        this.collapsed = value;
        this.cd.markForCheck();
        this.updateDisplayItems();
    };
    CustomizeBarFieldsEditComponent.prototype.isToggledAll = function () {
        return this.form.isToggledAll();
    };
    CustomizeBarFieldsEditComponent.prototype.toggleAll = function () {
        this.form.toggleAll();
    };
    CustomizeBarFieldsEditComponent.prototype.isEmpty = function () {
        return this.displayItems.length == 0;
    };
    CustomizeBarFieldsEditComponent.prototype.clearSearch = function () {
        if (!isSet(this.search)) {
            return;
        }
        this.search = '';
        this.searchUpdated.next();
        this.cd.markForCheck();
        this.searchCleared.emit();
    };
    CustomizeBarFieldsEditComponent.prototype.onSearchBlur = function () {
        if (!isSet(this.search)) {
            this.searchCleared.emit();
        }
    };
    CustomizeBarFieldsEditComponent.prototype.focusSearch = function () {
        if (this.searchAutoFocus) {
            this.searchAutoFocus.focus();
        }
    };
    return CustomizeBarFieldsEditComponent;
}(FieldsEditComponent));
export { CustomizeBarFieldsEditComponent };

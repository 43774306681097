import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { defaultAccentColor } from '@modules/colors';
import {
  MenuBlock,
  MenuBlockLayout,
  MenuBlockLayouts,
  MenuGeneratorService,
  MenuItem,
  MenuItemType
} from '@modules/menu';
import { MenuContext, MenuPrimary, MenuSecondary } from '@modules/menu-components';
import { ThemeContext } from '@modules/theme-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, fileToBase64, isSet, TypedChanges } from '@shared';

import { MenuBlockControl } from '../../../layout-routes/components/project-settings/menu-block.control';
import { MenuUpdateForm } from '../../../layout-routes/components/project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../../../layout-routes/components/project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../../../layout-routes/components/project-settings/project-update.form';

interface MenuBlockItem {
  block: MenuBlock;
  control: MenuBlockControl;
  firstMenuItem: MenuItem;
  primary: boolean;
  isLight: boolean;
  style: SafeStyle;
}

@Component({
  selector: 'app-admin-template, [app-admin-template]',
  templateUrl: './admin-template.component.html',
  providers: [ThemeContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTemplateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() projectForm: ProjectUpdateForm;
  @Input() menuForm: MenuUpdateForm;
  @Input() settingsForm: ProjectSettingsUpdateForm;
  @Input() blockControlHover: MenuBlockControl;
  @Input() blockControlActive: MenuBlockControl;
  @Input() contentDisabled = false;
  @Output() blockControlPreviewHover = new EventEmitter<MenuBlockControl>();
  @Output() blockControlPreviewClick = new EventEmitter<{ control: MenuBlockControl; event: MouseEvent }>();

  logoUrl: string;
  leftMenuBlocks: MenuBlockItem[] = [];
  topMenuBlocks: MenuBlockItem[] = [];
  topContentMenuBlocks: MenuBlockItem[] = [];
  externalFonts: string[] = [];
  defaultAccentColor = defaultAccentColor;
  stepsProgress = 0;
  blockControlHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlActive$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlPreviewHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  blockControlFocus: MenuBlockControl;

  trackMenuBlockItemFn(i, item: MenuBlockItem) {
    return item.control.instance.uid;
  }

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private context: MenuContext,
    public currentUserStore: CurrentUserStore,
    private menuGeneratorService: MenuGeneratorService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(this.projectForm.valueChanges, this.settingsForm.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cd.markForCheck();
      });

    combineLatest(
      controlValue<string>(this.projectForm.controls.logo),
      controlValue<File>(this.projectForm.controls.logo_file)
    )
      .pipe(
        switchMap(([url, file]) => {
          if (file) {
            return fileToBase64(file);
          } else {
            return of(url);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(url => {
        this.logoUrl = url;
        this.cd.markForCheck();
      });

    controlValue(this.menuForm.controls.blocks)
      .pipe(
        // auditTime(60),
        switchMap(() => {
          return combineLatest(
            this.menuForm.controls.blocks.controls.map(control => {
              return control
                .getEnabled$({ context: this.context })
                .pipe(map(enabled => ({ control: control, enabled: enabled })));
            })
          ).pipe(map(items => items.filter(item => item.enabled).map(item => item.control)));
        }),
        untilDestroyed(this)
      )
      .subscribe(menuBlockControls => {
        const menuBlocks = menuBlockControls.map<MenuBlockItem>(control => {
          const block = control.serialize();
          const color = control.getColor();
          const firstMenuItem = block.getAllItems().filter(item => [MenuItemType.Simple].includes(item.type))[0];
          const isLight = MenuPrimary.isLight(color);
          const primary = MenuBlockLayouts.isPrimary(control.controls.layout.value);
          const style = primary ? this.getPrimaryMenuStyleVars(color) : this.getSecondaryMenuStyleVars(color);

          block.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.startItems);
          block.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.centerItems);
          block.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(block.endItems);

          return {
            block: block,
            control: control,
            firstMenuItem: firstMenuItem,
            primary: primary,
            isLight: isLight,
            style: style
          };
        });

        this.leftMenuBlocks = menuBlocks.filter(item => MenuBlockLayouts.isLeft(item.block.layout));
        this.topMenuBlocks = menuBlocks.filter(item => item.block.layout == MenuBlockLayout.TopThin);
        this.topContentMenuBlocks = menuBlocks.filter(item => item.block.layout == MenuBlockLayout.TopContentThin);
        this.cd.markForCheck();
      });

    combineLatest(
      controlValue<string>(this.settingsForm.controls.font_regular),
      controlValue<string>(this.settingsForm.controls.font_heading)
    )
      .pipe(untilDestroyed(this))
      .subscribe(fonts => {
        this.externalFonts = fonts.filter(item => isSet(item));
        this.cd.markForCheck();
      });

    const stepIndex = 1;
    const itemWidth = 1 / 4;

    this.stepsProgress = itemWidth * stepIndex + itemWidth * 0.5;

    combineLatest(this.blockControlHover$, this.blockControlActive$, this.blockControlPreviewHover$)
      .pipe(untilDestroyed(this))
      .subscribe(([blockControlHover, blockControlActive, blockControlPreviewHover]) => {
        if (blockControlPreviewHover) {
          this.blockControlFocus = blockControlPreviewHover;
        } else if (blockControlHover && blockControlHover.isVisible()) {
          this.blockControlFocus = blockControlHover;
        } else if (blockControlActive && blockControlActive.isVisible()) {
          this.blockControlFocus = blockControlActive;
        } else {
          this.blockControlFocus = undefined;
        }

        this.cd.markForCheck();
      });

    this.blockControlPreviewHover$
      .pipe(untilDestroyed(this))
      .subscribe(value => this.blockControlPreviewHover.emit(value));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AdminTemplateComponent>): void {
    if (changes.blockControlHover) {
      this.blockControlHover$.next(this.blockControlHover);
    }

    if (changes.blockControlActive) {
      this.blockControlActive$.next(this.blockControlActive);
    }
  }

  getPrimaryMenuStyleVars(accentColor: string): SafeStyle {
    const vars = MenuPrimary.getVars(accentColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }

  getSecondaryMenuStyleVars(accentColor: string): SafeStyle {
    const vars = MenuSecondary.getVars(accentColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }

  asMenuBlockItems(value: any): MenuBlockItem[] {
    return value as MenuBlockItem[];
  }
}

import { FieldType } from './field-type';
import { parseFieldType } from './fields';

export class ParameterValue {
  public name: string;
  public value: string;
  public field: FieldType;
  public params: {};
  public icon: string;
  public description: string;
  public placeholder: string;
  public required = true;

  constructor(options: Partial<ParameterValue> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): ParameterValue {
    this.name = data['name'];
    this.value = data['value'];
    this.field = parseFieldType(data['field']);
    this.icon = data['icon'];
    this.description = data['description'];
    this.placeholder = data['placeholder'];

    if (data['required'] == false) {
      this.required = false;
    }

    if (data['params']) {
      this.params = data['params'];
    }

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      value: this.value,
      field: this.field,
      params: this.params,
      icon: this.icon,
      description: this.description,
      placeholder: this.placeholder,
      required: this.required
    };
  }
}

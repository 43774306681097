import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';

import { Color } from './color';

export const defaultIconFillIcon = 'heart';

export class IconFill {
  icon = defaultIconFillIcon;
  iconInput: Input;
  color: Color;
  colorInput: Input;
  size: number;

  constructor(options: Partial<IconFill> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.size = data['size'];

    if (isSet(data['icon'])) {
      this.icon = data['icon'];
    }

    if (data['icon_input']) {
      this.iconInput = new Input().deserialize(data['icon_input']);
    }

    if (data['color']) {
      this.color = new Color().deserialize(data['color']);
    }

    if (data['color_input']) {
      this.colorInput = new Input().deserialize(data['color_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      icon: this.icon,
      icon_input: this.iconInput ? this.iconInput.serialize() : null,
      color: this.color ? this.color.serialize() : undefined,
      color_input: this.colorInput ? this.colorInput.serialize() : null,
      size: this.size
    };
  }

  cssColor$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    if (this.colorInput) {
      return applyParamInput$(this.colorInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });
    } else if (this.color) {
      return of(this.color.css());
    }
  }

  icon$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    if (this.iconInput) {
      return applyParamInput$(this.iconInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      }).pipe(catchError(() => of(undefined)));
    } else {
      return of(this.icon);
    }
  }

  display$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<{ icon: string; color: string }> {
    return combineLatest(this.icon$(options), this.cssColor$(options)).pipe(
      map(([icon, color]) => {
        return { icon: icon, color: color };
      })
    );
  }
}

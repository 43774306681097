<div
  cdkDropList
  [cdkDropListLockAxis]="'y'"
  [cdkDropListDisabled]="!configurable.sortable"
  class="sidebar-list"
  (cdkDropListDropped)="dragDrop($event)"
>
  <div *ngIf="searchEnabled" class="sidebar-list__item">
    <div class="input-icon-wrapper">
      <a
        *ngIf="search"
        href="javascript:void(0)"
        class="input-icon input-icon_toolbar input-icon_interactive input-icon_right icon-close"
        (click)="clearSearch()"
      ></a>
      <input
        class="input input_toolbar input_background input_icon-right sidebar__input-inner"
        type="text"
        [placeholder]="'Search...'"
        [appAutofocus]="searchFocus"
        [(ngModel)]="search"
        (keyup)="searchUpdated.next()"
        (blur)="onSearchBlur()"
        #search_autofocus
      />
    </div>
  </div>

  <div *ngFor="let itemForm of displayItems; let i = index" cdkDrag class="sidebar-list__item">
    <div
      class="sidebar-list-item sidebar-list-item_clickable"
      [class.sidebar-list-item_draggable]="configurable.sortable"
      [class.sidebar-list-item_disabled]="configurable.visible && itemForm.value['visible'] === false"
      (click)="editItem(itemForm)"
    >
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="icon button__icon" [ngClass]="['icon-' + (form.fieldIcon$(itemForm) | async)]"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">
          <app-auto-field
            *ngIf="configurable.name"
            [form]="itemForm"
            [label]="false"
            [field]="
              createField({
                name: 'name',
                field: 'CharField'
              })
            "
            [manualMargin]="true"
          >
          </app-auto-field>
          <ng-container *ngIf="!configurable.name">
            {{ itemForm.value['verboseName'] || itemForm.value['name'] }}
          </ng-container>
        </div>
      </div>
      <div *ngIf="configurable.visible || configurable.sortable" class="sidebar-list-item__right">
        <a
          *ngIf="configurable.sortable && !(search | appIsSet) && getMoveToTopPosition(itemForm) !== i"
          href="javascript:void(0)"
          class="sidebar-list-item__action sidebar-list-item__action_hover-only icon-align_vertical_top"
          [appTip]="'Move to top'"
          [appTipOptions]="{ side: 'top' }"
          (click)="$event.stopPropagation(); moveToTop(itemForm)"
        >
        </a>
        <a
          *ngIf="configurable.add && itemForm.value['flex']"
          href="javascript:void(0)"
          class="sidebar-list-item__action icon-bin"
          (click)="$event.stopPropagation(); removeItem(itemForm)"
        >
        </a>
        <app-toggle
          *ngIf="configurable.visible && !(configurable.add && itemForm.value['flex'])"
          [orange]="true"
          [selected]="itemForm.value['visible']"
          (click)="$event.stopPropagation(); form.toggleItemVisible(itemForm)"
        >
        </app-toggle>
      </div>
    </div>
  </div>

  <!--  <div *ngIf="!form.form.controls.length" class="sidebar__message">-->
  <!--    There are no {{itemName}}-->
  <!--  </div>-->

  <div
    *ngIf="collapsible && !(search | appIsSet) && form.form.controls.length > maxDisplayInitial"
    class="sidebar-list__buttons"
  >
    <a
      *ngIf="collapsed"
      href="javascript:void(0)"
      class="button button_transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(false)"
    >
      <span class="icon-arrow_down_2 button__icon button__icon_left"></span>
      <span class="button__label">Show all ({{ form.form.controls.length }})</span>
    </a>
    <a
      *ngIf="!collapsed"
      href="javascript:void(0)"
      class="button button_transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(true)"
    >
      <span class="icon-arrow_up_2 button__icon button__icon_left"></span>
      <span class="button__label">Collapse</span>
    </a>
  </div>

  <div *ngIf="configurable.add" class="sidebar-list__buttons">
    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label"> Add Custom {{ itemName }} </span>
    </a>

    <a
      [appDocumentation]="'computed-columns'"
      [appDocumentationLeft]="true"
      [appDocumentationSource]="object + '_custom-field'"
      class="button button_orange-alternative button_small button_icon sidebar-list__buttons-item"
      [appTip]="'See how it works'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation()"
    >
      <div class="button__icon icon-help"></div>
    </a>
  </div>
</div>

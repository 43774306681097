<div class="select-icon__upload">
  <div class="field">
    <div class="field__value">
      <div
        class="upload"
        [class.upload_empty]="true"
        [class.upload_uploadable]="true"
        [class.upload_active]="draggingOver"
        style="height: 90px;"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
      >
        <div class="upload__placeholder">
          <div class="upload__placeholder-icon icon-cloud_upload"></div>
          <div class="upload__placeholder-label">
            Drop files here or
            <label [for]="'upload' | appUniqueId: idToken" class="upload__placeholder-button">Choose files</label>
          </div>
        </div>

        <div class="upload__controls">
          <input
            class="input input_file"
            type="file"
            [accept]="extensions.join(',')"
            multiple
            (change)="onFileChanged($event.target)"
            [id]="'upload' | appUniqueId: idToken"
          />
        </div>

        <label class="upload__overlay" [for]="'upload' | appUniqueId: idToken"></label>
      </div>
    </div>

    <div class="field__description">Allowed files are {{ extensions.join(', ') }}. Recommended size is 48x48.</div>
  </div>
</div>

<ng-container *ngIf="!iconsLoading">
  <div *ngFor="let item of filteredIcons" class="select-icon__item">
    <a
      href="javascript:void(0)"
      class="select-icon-item select-icon-item_interactive"
      [class.select-icon-item_current]="current == item.path"
      [appTip]="item.name"
      [appTipOptions]="{ showDelay: 300 }"
      (click)="selected.emit(item.path)"
    >
      <span class="select-icon-item__icon" [style.background-image]="'url(' + item.url + ')'"></span>
    </a>
  </div>

  <div *ngIf="(search | appIsSet) && !filteredIcons.length" class="select-icon__items-empty">
    No uploaded icons found
  </div>
</ng-container>

<app-icon-selector-stub *ngIf="loading || iconsLoading" [icons]="14"></app-icon-selector-stub>

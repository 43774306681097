<div class="sidebar">
  <div class="sidebar__scrollable" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }" #root>
    <app-dynamic-component
      *ngIf="customizeLayerComponent"
      [componentData]="customizeLayerComponent"
    ></app-dynamic-component>

    <app-customize-view
      *ngIf="customizeView"
      [view]="customizeView"
      [viewUpdated$]="viewUpdated$"
      [viewContext]="viewContext"
      (viewChange)="onViewChange($event)"
      (closeCustomize)="onViewCloseCustomize()"
    ></app-customize-view>
  </div>
</div>

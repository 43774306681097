var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import * as moment from 'moment';
import { AutomationTriggerType } from './automation-trigger-type';
import { getAutomationTriggerByType } from './automation-trigger-types';
import { Workflow } from './workflow';
import { WorkflowBackendRun } from './workflow-backend-run';
var Automation = /** @class */ (function () {
    function Automation(options) {
        if (options === void 0) { options = {}; }
        this.draft = false;
        this.deleted = false;
        this.active = false;
        Object.assign(this, options);
    }
    Automation.prototype.deserialize = function (data) {
        this.uid = data['uid'];
        this.name = data['name'];
        this.draft = data['draft'];
        this.deleted = data['deleted'];
        this.active = data['active'];
        this.dateAdd = moment(data['date_add']);
        var triggerTypeCls = getAutomationTriggerByType(data['trigger_type']);
        if (triggerTypeCls) {
            this.trigger = new triggerTypeCls().deserialize(data['trigger_params']);
        }
        else {
            this.trigger = undefined;
        }
        if (data['workflow']) {
            if (typeof data['workflow'] === 'string') {
                this.workflow = new Workflow({ uid: data['workflow'] });
            }
            else {
                this.workflow = new Workflow().deserialize(__assign({}, data['workflow']['params'], { uid: data['workflow']['uid'] }));
            }
        }
        else {
            this.workflow = undefined;
        }
        if (data['last_run']) {
            this.lastRun = new WorkflowBackendRun().deserialize(data['last_run']);
        }
        else {
            this.lastRun = undefined;
        }
        return this;
    };
    Automation.prototype.serialize = function (fields) {
        var data = {
            uid: this.uid,
            name: this.name,
            trigger_type: this.trigger ? this.trigger.type : undefined,
            trigger_params: this.trigger ? this.trigger.serialize() : undefined,
            workflow: this.workflow
                ? {
                    params: this.workflow.serialize()
                }
                : undefined,
            draft: this.draft,
            deleted: this.deleted,
            active: this.trigger && this.trigger.type == AutomationTriggerType.Manual ? true : this.active
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Automation.prototype.getStatusLabel = function () {
        var lastRunStatus = this.lastRun ? this.lastRun.getStatusLabel() : undefined;
        if (lastRunStatus) {
            return lastRunStatus;
        }
        else {
            return 'not run';
        }
    };
    return Automation;
}());
export { Automation };

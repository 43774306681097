import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ViewContext } from '@modules/customize';
import { Color, FrameTranslate, GradientStop, Layer, View } from '@modules/views';
import { ascComparator, controlValue, deployUrl, isSet, KeyboardEventKeyCode } from '@shared';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { GradientControl } from '../../controls/gradient.control';
var GradientSelectorComponent = /** @class */ (function () {
    function GradientSelectorComponent(editorContext, sanitizer, cd) {
        this.editorContext = editorContext;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.overlayClick = false;
    }
    GradientSelectorComponent.prototype.trackStopControlFn = function (i, item) {
        return item.getId() || "index_" + i;
    };
    GradientSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        var frame = this.getFrame();
        if (frame) {
            this.editorContext.startCustomizingGradient({
                frame: frame,
                control: this.control
            });
        }
        if (this.view) {
            this.editorContext
                .viewChanged$()
                .pipe(untilDestroyed(this))
                .subscribe(function (event) {
                var viewFrame = new FrameTranslate({ frame: event.view.frame });
                _this.editorContext.updateCustomizingGradient({ frame: viewFrame });
            });
        }
        else if (this.layer) {
            merge(this.editorContext.layerChanged$().pipe(filter(function (event) { return event.layer.isSame(_this.layer); }), map(function (event) { return event.layer; })), this.editorContext.globalLayersChange$().pipe(map(function () { return _this.layer; })))
                .pipe(switchMap(function (layer) {
                return _this.editorContext.getLayerContainer$(layer).pipe(map(function (container) {
                    return new FrameTranslate({
                        frame: layer.frame,
                        translate: container ? container.options.translate : undefined
                    });
                }));
            }), untilDestroyed(this))
                .subscribe(function (layerFrame) {
                _this.editorContext.updateCustomizingGradient({ frame: layerFrame });
            });
        }
        this.editorContext.customizingGradient$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.activeStopId = value ? value.activeStop : undefined;
            _this.cd.markForCheck();
        });
        combineLatest(this.editorContext.customizingGradient$, controlValue(this.control.controls.stops))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var customizingGradient = _a[0];
            var activeStop = customizingGradient && isSet(customizingGradient.activeStop)
                ? _this.control.controls.stops.controls.find(function (item) { return item.getId() == customizingGradient.activeStop; })
                : undefined;
            var firstStop = _this.control.controls.stops.controls[0];
            if (!activeStop && firstStop) {
                _this.setCurrentStopControl(firstStop);
            }
            else if (!activeStop && customizingGradient && customizingGradient.activeStop) {
                _this.setCurrentStopControl(undefined);
            }
        });
        controlValue(this.control.controls.stops)
            .pipe(switchMap(function () {
            if (!_this.control.controls.stops.controls.length) {
                return of([]);
            }
            return combineLatest(_this.control.controls.stops.controls
                .sort(function (lhs, rhs) { return ascComparator(lhs.controls.position.value, rhs.controls.position.value); })
                .map(function (control) {
                return control.getColor$({ context: _this.viewContext }).pipe(map(function (color) {
                    var position = control.controls.position.value * 100 + "%";
                    return color + " " + position;
                }));
            }));
        }), untilDestroyed(this))
            .subscribe(function (stopsCss) {
            var backgrounds = [];
            if (stopsCss.length) {
                backgrounds.push("linear-gradient(to right, " + stopsCss.join(', ') + ")");
            }
            backgrounds.push("#fff center center / 10px url(" + deployUrl('/assets/images/transparent.svg') + ")");
            _this.trackBackgroundSafe = _this.sanitizer.bypassSecurityTrustStyle(backgrounds.join(', '));
            _this.cd.markForCheck();
        });
        this.editorContext
            .trackKeydown()
            .pipe(filter(function (e) { return e.keyCode == KeyboardEventKeyCode.Backspace; }), untilDestroyed(this))
            .subscribe(function (e) {
            e.stopPropagation();
            var index = _this.control.controls.stops.controls.findIndex(function (item) { return item.getId() === _this.activeStopId; });
            if (_this.control.controls.stops.controls.length > 2 && !!_this.control.controls.stops.controls[index]) {
                var newCurrentStop = index > 0 ? _this.control.controls.stops.controls[index - 1] : undefined;
                _this.control.controls.stops.removeAt(index);
                _this.setCurrentStopControl(newCurrentStop);
            }
        });
    };
    GradientSelectorComponent.prototype.ngOnDestroy = function () {
        this.editorContext.finishCustomizingGradient();
    };
    GradientSelectorComponent.prototype.getFrame = function () {
        if (this.view) {
            return new FrameTranslate({
                frame: this.view.frame
            });
        }
        else if (this.layer) {
            var container = this.editorContext.getLayerContainer(this.layer);
            return new FrameTranslate({
                frame: this.layer.frame,
                translate: container ? container.options.translate : undefined
            });
        }
    };
    GradientSelectorComponent.prototype.setCurrentStopControl = function (control) {
        this.editorContext.updateCustomizingGradient({
            activeStop: control ? control.getId() : undefined
        });
    };
    GradientSelectorComponent.prototype.createGradientStop = function (event) {
        var bounds = this.gradientStopTrack.nativeElement.getBoundingClientRect();
        var position = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);
        var color = new Color({ red: 255, green: 255, blue: 255 });
        var stop = new GradientStop({ position: position, color: color });
        stop.generateId();
        var control = this.control.controls.stops.appendControl(stop);
        this.setCurrentStopControl(control);
    };
    return GradientSelectorComponent;
}());
export { GradientSelectorComponent };

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CurrentUserStore } from '@modules/users';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private currentUserStore: CurrentUserStore, private cookieService: CookieService) {
    const interval = setInterval(() => {
      if (!this.ga || !this.clientId) {
        return;
      }

      this.init();
      this._loaded.next(true);
      clearInterval(interval);
    }, 1000);
  }

  init() {
    this.currentUserStore.instance$.subscribe(user => {
      if (!this.ga || !user) {
        return;
      }

      this.ga('create', 'UA-103103655-25', { alwaysSendReferrer: true });
      this.ga('set', 'userId', user.username);
      this.ga('set', 'dimension2', user.username);
    });
  }

  get ga() {
    return window['ga'];
  }

  get loaded$() {
    return this._loaded.pipe(filter(item => item == true));
  }

  get clientId(): string {
    const value = this.cookieService.get('_ga');

    if (!value) {
      return;
    }

    const parts = value.split('.');

    if (parts.length < 4) {
      return;
    }

    return `${parts[2]}.${parts[3]}`;
  }

  get clientId$(): Observable<string> {
    return this.loaded$.pipe(
      map(() => {
        return this.clientId;
      })
    );
  }

  setLocationReferrer() {
    this.loaded$.subscribe(() => {
      this.ga('set', 'referrer', window.location.origin);
    });
  }
}

<div class="menu2-share">
  <div
    *ngIf="!loading"
    class="user-photos user-photos_small"
    [class.user-photos_clickable]="editAccess"
    [class.user-photos_background_menu-light]="colorLight === true && !dropdown"
    [class.user-photos_background_menu-dark]="colorLight === false && !dropdown"
    [class.user-photos_background_menu-unset]="colorUnset && !dropdown"
    [class.user-photos_background_content]="dropdown"
    (click)="share()"
  >
    <span
      *ngFor="let user of displayUsers"
      [style.background-image]="user?.photo ? 'url(' + user.photo + ')' : ''"
      class="user-photos__item"
    >
    </span>

    <span *ngIf="otherUsersCount" class="user-photos__item user-photos__item_text">
      +{{ otherUsersCount > 99 ? 99 : otherUsersCount }}
    </span>

    <span
      *ngIf="editAccess && !(!this.horizontal && this.primary)"
      class="user-photos__add user-photos__add_menu icon-plus"
    ></span>
  </div>

  <div
    *ngIf="loading"
    class="user-photos user-photos_small loading-animation"
    [class.user-photos_background_menu-light]="colorLight === true && !dropdown"
    [class.user-photos_background_menu-dark]="colorLight === false && !dropdown"
    [class.user-photos_background_menu-unset]="colorUnset && !dropdown"
    [class.user-photos_background_content]="dropdown"
  >
    <span *ngFor="let user of [1, 2, 3]" class="user-photos__item"></span>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { BarCodeElementItem, CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-bar-code-element',
  templateUrl: './bar-code-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarCodeElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: BarCodeElementItem;

  customizeEnabled$: Observable<boolean>;
  contextSubscription: Subscription;
  value = '';

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.initContextObserver();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<BarCodeElementComponent>): void {
    if (changes.element) {
      this.initContextObserver();
    }
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    this.updateInputs();

    this.contextSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.updateInputs());
  }

  updateInputs() {
    try {
      const value = applyParamInput(this.element.value, {
        context: this.context,
        defaultValue: ''
      });

      this.value = isSet(value) ? String(value) : undefined;
    } catch (e) {
      this.value = '';
    }

    this.cd.markForCheck();
  }
}

registerElementComponent({
  type: ElementType.BarCode,
  component: BarCodeElementComponent,
  label: 'Bar code',
  actions: []
});

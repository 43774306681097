import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SlackChannelStore, SlackService } from '@modules/slack';
import { CurrentUserStore } from '@modules/users';
import { setControlDisabled, stripStart } from '@shared';

@Injectable()
export class SlackJoinPopupForm extends FormGroup {
  controls: {
    email: FormControl;
    channel: FormControl;
  };

  channel: SlackService.SlackChannelResponse;
  joined = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private currentUserStore: CurrentUserStore,
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private slackService: SlackService,
    private slackChannelStore: SlackChannelStore
  ) {
    super({
      email: new FormControl('', [Validators.required, Validators.email]),
      channel: new FormControl('', Validators.required)
    });
  }

  init(options: { slackChannel?: SlackService.SlackChannelResponse }) {
    this.channel = options.slackChannel;
    this.joined = options.slackChannel && options.slackChannel.isJoined();

    const channelUserEmail =
      options.slackChannel && options.slackChannel.channelUser
        ? options.slackChannel.channelUser.email
        : this.currentUserStore.instance.email;
    const channelNamePrefix = options.slackChannel ? options.slackChannel.channelPrefix : '';
    const channelName =
      options.slackChannel && options.slackChannel.channel
        ? stripStart(options.slackChannel.channel.name, channelNamePrefix)
        : this.currentProjectStore.instance.uniqueName;

    this.controls.email.patchValue(channelUserEmail);
    this.controls.channel.patchValue(channelName);

    setControlDisabled(this.controls.email, this.joined);
    setControlDisabled(this.controls.channel, this.joined);

    this.markAsPristine();
  }

  getSlackJoinLink(): Observable<string> {
    return this.slackService.getSlackJoinLink(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName
    );
  }

  joinSlackChannel(): Observable<SlackService.SlackChannelResponse> {
    this.markAsDirty();

    const email = this.controls.email.value;
    const channel = this.controls.channel.value;

    return this.slackService
      .joinSlackChannel(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        email,
        channel
      )
      .pipe(
        tap(result => {
          this.slackChannelStore.set(result);
          this.init({ slackChannel: result });
          this.markAsPristine();
        }),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

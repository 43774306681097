import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ModelDataType, ViewContext } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, QueryBuilderService, SqlResultsSection } from '@modules/queries-components';
import { controlValid } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { ModelDescriptionDataSourceEditComponent } from '../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarModelDataControl } from '../customize-bar-model-data.control';
import { CustomizeBarPageEditForm } from '../customize-bar-page-edit.form';

@Component({
  selector: 'app-customize-bar-page-edit-query',
  templateUrl: './customize-bar-page-edit-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarPageEditQueryComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarModelDataControl;
  @Input() parentForm: CustomizeBarPageEditForm;
  @Input() context: ViewContext;
  @Input() deleteEnabled = false;
  @Input() object: string;
  @Output() delete = new EventEmitter<void>();

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;

  modelDataTypes = ModelDataType;
  dataSourceTypes = DataSourceType;

  constructor(
    private queryBuilderService: QueryBuilderService,
    private analyticsService: UniversalAnalyticsService,
    private customizeBarContext: CustomizeBarContext
  ) {}

  ngOnInit() {
    this.control.controls.detail_data_source
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.object,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.control.controls.detail_data_source.getResource$().pipe(skip(1)),
      this.control.controls.detail_data_source.getModelDescription$().pipe(skip(1))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.object,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    controlValid(this.control.controls.detail_data_source)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
          ComponentTypeID: this.object
        });
      });

    controlValid(this.control.controls.detail_data_source.controls.query_resource)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
          ComponentTypeID: this.object
        });
      });

    controlValid(this.control.controls.detail_data_source.controls.query_inputs)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
          ComponentTypeID: this.object
        });
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
      ComponentTypeID: this.object
    });
  }

  ngOnDestroy(): void {}

  back() {
    this.customizeBarContext.popSettingsComponent();
  }

  cancel() {
    this.back();
  }

  submit() {
    this.back();
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

<app-page-template-preview-start-page
  *ngIf="startPage"
  [page]="startPage"
  [fade]="startPage.popups.length > 0 || otherPages.length > 0"
  [startPageFocus]="startPageFocus"
  [popupFocus]="popupFocus"
></app-page-template-preview-start-page>

<div
  *ngIf="otherPages.length"
  class="choose-template__detail-browsers choose-template__detail-browsers_visible"
  [class.choose-template__detail-browsers_visible]="otherPagesVisible"
>
  <app-page-template-preview-other-page
    *ngFor="let page of otherPages; trackBy: trackPage"
    class="choose-template__detail-browsers-item"
    [page]="page"
    [labelsVisible]="otherPagesLabels"
  ></app-page-template-preview-other-page>
</div>

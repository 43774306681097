import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';

import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { Model, ModelDescription, ORDER_BY_PARAM, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';

import { MoveForm } from './move.form';

@Component({
  selector: 'app-move',
  templateUrl: './move.component.html',
  providers: [ModelListStore, MoveForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() modelDescription: ModelDescription;
  @Input() queryParams: Object;
  @Input() ids: number[];
  @Input() inverseIds = false;
  @Output() reordered = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = true;
  firstItems: Model[];
  count: number;
  executeActionStarted = false;
  executeActionProgress = 0;
  executeActionSucceeded = false;

  constructor(
    public form: MoveForm,
    private modelListStore: ModelListStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.modelDescription);
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.modelListStore.dataSource = new ListModelDescriptionDataSource({
      type: DataSourceType.Query,
      queryResource: this.modelDescription.resource,
      query: this.modelDescription.getQuery
    });
    this.modelListStore.params = this.actionQueryParams();

    this.modelListStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.firstItems = this.modelListStore.items;
          this.count = this.modelListStore.count;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  actionQueryParams() {
    const params = { ...this.queryParams };

    if (this.ids) {
      if (this.inverseIds) {
        params[`exclude__${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
      } else {
        params[`${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
      }
    }

    if (params[PAGE_PARAM] != undefined) {
      delete params[PAGE_PARAM];
    }

    if (this.form.isForwardProcessing()) {
      params[ORDER_BY_PARAM] = this.modelDescription.orderingField;
    } else {
      params[ORDER_BY_PARAM] = `-${this.modelDescription.orderingField}`;
    }

    return params;
  }

  processItems(items: Model[], fromIndex: number, processed = 0): Observable<number> {
    return this.form.submit(items[fromIndex].primaryKey).pipe(
      switchMap(() => {
        processed += 1;

        this.executeActionProgress = processed / this.modelListStore.count;
        this.cd.markForCheck();

        if (fromIndex < items.length - 1) {
          return this.processItems(items, fromIndex + 1, processed);
        } else {
          return of(processed);
        }
      })
    );
  }

  processPage(processed = 0): Observable<number> {
    return this.modelListStore.getNext().pipe(
      switchMap(items => {
        return this.processItems(items, 0, processed);
      }),
      switchMap(newProcessed => {
        this.executeActionProgress = newProcessed / this.modelListStore.count;
        this.cd.markForCheck();

        if (this.modelListStore.hasMore) {
          return this.processPage(newProcessed);
        } else {
          return of(newProcessed);
        }
      })
    );
  }

  submit() {
    if (this.form.form.invalid || this.executeActionStarted) {
      return;
    }

    this.executeActionStarted = true;
    this.executeActionProgress = 0;
    this.cd.markForCheck();

    this.modelListStore.params = this.actionQueryParams();
    this.modelListStore.reset();

    this.processPage()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.executeActionSucceeded = true;
          this.executeActionProgress = 1;
          this.cd.markForCheck();
          this.reordered.next();
        },
        () => {
          this.executeActionSucceeded = true;
          this.executeActionProgress = 1;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { controlValid } from '@shared';
var CustomizeBarBaseWidgetEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarBaseWidgetEditForm, _super);
    function CustomizeBarBaseWidgetEditForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomizeBarBaseWidgetEditForm.prototype.controlsValid$ = function (controls) {
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    return CustomizeBarBaseWidgetEditForm;
}(FormGroup));
export { CustomizeBarBaseWidgetEditForm };

<a
  *ngIf="!focus && !active && !(search | appIsSet)"
  href="javascript:void(0)"
  class="button button_icon list__actions-item"
  [appButtonTintColor]="accentColor"
  [appButtonTintColorStyle]="tintStyles.Default"
  [appTip]="placeholder"
  [appTipOptions]="{ side: 'top' }"
  (click)="toggleActive()"
>
  <span class="button__icon icon-search"></span>
</a>

<div *ngIf="focus || (!focus && (active || (search | appIsSet)))" class="input-icon-wrapper input-icon-wrapper_inline">
  <span class="input-icon input-icon_toolbar input-icon_left icon-search"></span>
  <input
    type="text"
    class="input input_toolbar input_background input_icon-left input_icon_right"
    [(ngModel)]="search"
    [placeholder]="placeholder"
    (keyup)="onSearchKey($event)"
    (blur)="onSearchBlur(); toggleActive()"
    [appAutofocus]="true"
  />
  <a
    *ngIf="search | appIsSet"
    href="javascript:void(0)"
    class="input-icon input-icon_right input-icon_toolbar input-icon_interactive icon-close"
    (click)="clear()"
  >
  </a>
</div>

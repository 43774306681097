import { Directive, Input } from '@angular/core';

import { AllProjectSettings } from '@modules/all-project-settings';

import { ThemeOptions } from '../../data/theme-options';
import { ThemeOptionsDirective } from '../theme-options/theme-options.directive';

@Directive({
  selector: '[appProjectThemeOptions]'
})
export class ProjectThemeOptionsDirective extends ThemeOptionsDirective {
  @Input() projectSettings: AllProjectSettings;

  getThemeOptions(): ThemeOptions {
    if (this.projectSettings) {
      return {
        accentColor: this.projectSettings.accentColor,
        fontRegular: this.projectSettings.fontRegular,
        fontHeading: this.projectSettings.fontHeading
      };
    } else {
      return {
        accentColor: this.accentColor,
        fontRegular: this.fontRegular,
        fontHeading: this.fontHeading
      };
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActionItem } from '@modules/actions';
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var BackElementItem = /** @class */ (function (_super) {
    __extends(BackElementItem, _super);
    function BackElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Back;
        return _this;
    }
    BackElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (this.params['title_input']) {
            this.titleInput = new Input().deserialize(this.params['title_input']);
        }
        else {
            this.titleInput = undefined;
        }
        if (this.params['previous_page_action']) {
            this.previousPageAction = new ActionItem().deserialize(this.params['previous_page_action']);
        }
        else {
            this.previousPageAction = undefined;
        }
        if (this.params['previous_page_title_input']) {
            this.previousPageTitleInput = new Input().deserialize(this.params['previous_page_title_input']);
        }
        else {
            this.previousPageTitleInput = undefined;
        }
        return this;
    };
    BackElementItem.prototype.serialize = function () {
        this.params = {
            title_input: this.titleInput ? this.titleInput.serialize() : undefined,
            previous_page_action: this.previousPageAction ? this.previousPageAction.serialize() : undefined,
            previous_page_title_input: this.previousPageTitleInput ? this.previousPageTitleInput.serialize() : undefined
        };
        return _super.prototype.serialize.call(this);
    };
    Object.defineProperty(BackElementItem.prototype, "analyticsName", {
        get: function () {
            return 'back';
        },
        enumerable: true,
        configurable: true
    });
    BackElementItem.prototype.defaultName = function () {
        return 'Back';
    };
    return BackElementItem;
}(ElementItem));
export { BackElementItem };
registerElementForType(ElementType.Back, BackElementItem);

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CardLayoutElementItem, ColumnsLayoutColumnElementItem, ColumnsLayoutElementItem, CustomViewSettings, validateElementNames, VALUE_OUTPUT, ViewSettingsStore } from '@modules/customize';
import { Input, InputValueType } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { capitalize } from '@shared';
import { BackGenerator } from '../back-generator/back-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';
var AdminPanelSeparatePagesGenerator = /** @class */ (function () {
    function AdminPanelSeparatePagesGenerator(viewSettingsStore, viewSettingsGeneratorService, createFormGenerator, updateFormGenerator, detailGenerator, deleteButtonGenerator, backGenerator, listGenerator, filterGenerator, generatorUtils) {
        this.viewSettingsStore = viewSettingsStore;
        this.viewSettingsGeneratorService = viewSettingsGeneratorService;
        this.createFormGenerator = createFormGenerator;
        this.updateFormGenerator = updateFormGenerator;
        this.detailGenerator = detailGenerator;
        this.deleteButtonGenerator = deleteButtonGenerator;
        this.backGenerator = backGenerator;
        this.listGenerator = listGenerator;
        this.filterGenerator = filterGenerator;
        this.generatorUtils = generatorUtils;
    }
    AdminPanelSeparatePagesGenerator.prototype.getListPage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = modelDescription.verboseNamePlural;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 1;
        viewSettings.configuredModelElements = 1;
        viewSettings.newlyCreated = true;
        var listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
            templates: options.templates,
            fields: options.listFields,
            createUniqueName: options.createUniqueName,
            detailUniqueName: options.detailUniqueName,
            perPage: 15,
            uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
        });
        var filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
            uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
        });
        listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(function (item) {
            var input = new Input();
            input.name = item.name;
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];
            return input;
        });
        viewSettings.elements = [filterElement, listElement];
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelSeparatePagesGenerator.prototype.getCreatePage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        var pageName = [modelDescription.verboseName, 'Create'].join(' - ');
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = pageName;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 2;
        viewSettings.configuredActionElements = 1;
        viewSettings.newlyCreated = true;
        var formElement = this.createFormGenerator.getElement(resource, modelDescription, {
            templates: options.templates,
            fields: options.fields,
            detailUniqueName: options.detailUniqueName,
            listUniqueName: options.listUniqueName,
            idGenerator: options.idGenerator,
            idUniqueName: 'formElement'
        });
        var backElement = this.backGenerator.getElement({
            titleStatic: "New " + capitalize(modelDescription.verboseName),
            backStatic: modelDescription.verboseNamePlural,
            backPage: options.listUniqueName,
            uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
        });
        viewSettings.elements = [backElement, formElement];
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelSeparatePagesGenerator.prototype.getUpdatePage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        var pageName = [modelDescription.verboseName, 'Update'].join(' - ');
        var parameter = this.generatorUtils.getModelPkParameter(modelDescription);
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = pageName;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 2;
        viewSettings.configuredActionElements = 1;
        viewSettings.parameters = [parameter];
        viewSettings.newlyCreated = true;
        var formElement = this.updateFormGenerator.getElement(resource, modelDescription, {
            templates: options.templates,
            pkContextValue: ['page', parameter.name],
            fields: options.fields,
            idGenerator: options.idGenerator,
            idUniqueName: 'formElement'
        });
        var headerColumnsElement = new ColumnsLayoutElementItem();
        var backElementColumn = new ColumnsLayoutColumnElementItem();
        var deleteElementColumn = new ColumnsLayoutColumnElementItem();
        headerColumnsElement.uid = options.idGenerator ? options.idGenerator.elementId('headerColumnsElement') : undefined;
        backElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('backElementColumn') : undefined;
        deleteElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('deleteElementColumn') : undefined;
        var displayField = modelDescription.displayField || modelDescription.primaryKeyField;
        var backElement = this.backGenerator.getElement({
            titleFormula: "GET(elements[\"" + formElement.uid + "\"]." + ITEM_OUTPUT + ", \"" + displayField + "\", \"Loading...\")",
            backStatic: modelDescription.verboseNamePlural,
            backPage: options.listUniqueName,
            uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
        });
        backElementColumn.children = [backElement];
        backElementColumn.weight = 1;
        headerColumnsElement.columns.push(backElementColumn);
        if (options.deleteEnabled) {
            var deleteParameterName = this.generatorUtils.getModelDeleteParameter(modelDescription);
            var deleteActionElement = this.deleteButtonGenerator.getElement(resource, modelDescription, {
                pkContextValue: ['elements', formElement.uid, ITEM_OUTPUT, deleteParameterName],
                listUniqueName: options.listUniqueName,
                uid: options.idGenerator ? options.idGenerator.elementId('deleteActionElement') : undefined
            });
            deleteElementColumn.fit = true;
            deleteElementColumn.children = [deleteActionElement];
            headerColumnsElement.columns.push(deleteElementColumn);
        }
        var headerContainer = new CardLayoutElementItem();
        headerContainer.uid = options.idGenerator ? options.idGenerator.elementId('headerContainer') : undefined;
        headerContainer.children = [headerColumnsElement];
        viewSettings.elements = [headerContainer, formElement];
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelSeparatePagesGenerator.prototype.getDetailPage = function (project, resource, modelDescription, uniqueName, options) {
        var viewSettings = new CustomViewSettings();
        var pageName = [modelDescription.verboseName, 'Detail'].join(' - ');
        var parameter = this.generatorUtils.getModelPkParameter(modelDescription);
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = pageName;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 2;
        viewSettings.configuredModelElements = 1;
        viewSettings.parameters = [parameter];
        viewSettings.newlyCreated = true;
        var modelElement = this.detailGenerator.getElement(resource, modelDescription, {
            pkContextValue: ['page', parameter.name],
            fields: options.fields,
            uid: options.idGenerator ? options.idGenerator.elementId('modelElement') : undefined
        });
        var headerColumnsElement = new ColumnsLayoutElementItem();
        var backElementColumn = new ColumnsLayoutColumnElementItem();
        var deleteElementColumn = new ColumnsLayoutColumnElementItem();
        headerColumnsElement.uid = options.idGenerator ? options.idGenerator.elementId('headerColumnsElement') : undefined;
        backElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('backElementColumn') : undefined;
        deleteElementColumn.uid = options.idGenerator ? options.idGenerator.elementId('deleteElementColumn') : undefined;
        var displayField = modelDescription.displayField || modelDescription.primaryKeyField;
        var backElement = this.backGenerator.getElement({
            titleFormula: "GET(elements[\"" + modelElement.uid + "\"].item, \"" + displayField + "\", \"Loading...\")",
            backStatic: modelDescription.verboseNamePlural,
            backPage: options.listUniqueName,
            uid: options.idGenerator ? options.idGenerator.elementId('backElement') : undefined
        });
        backElementColumn.children = [backElement];
        backElementColumn.weight = 1;
        headerColumnsElement.columns.push(backElementColumn);
        if (options.deleteEnabled) {
            var deleteParameterName = this.generatorUtils.getModelDeleteParameter(modelDescription);
            var deleteActionElement = this.deleteButtonGenerator.getElement(resource, modelDescription, {
                pkContextValue: ['elements', modelElement.uid, ITEM_OUTPUT, deleteParameterName],
                listUniqueName: options.listUniqueName
            });
            deleteElementColumn.fit = true;
            deleteElementColumn.children = [deleteActionElement];
            headerColumnsElement.columns.push(deleteElementColumn);
        }
        var headerContainer = new CardLayoutElementItem();
        headerContainer.uid = options.idGenerator ? options.idGenerator.elementId('headerContainer') : undefined;
        headerContainer.children = [headerColumnsElement];
        viewSettings.elements = [headerContainer, modelElement];
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    AdminPanelSeparatePagesGenerator.prototype.getPages = function (project, resource, modelDescription, updateEnabled, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var baseListUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, 'list');
        var baseCreateUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, 'create');
        var baseDetailUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription, updateEnabled && modelDescription.updateQuery ? 'update' : 'detail');
        return combineLatest(this.viewSettingsStore.getDistinctUniqueName(baseListUniqueName), this.viewSettingsStore.getDistinctUniqueName(baseCreateUniqueName), this.viewSettingsStore.getDistinctUniqueName(baseDetailUniqueName), this.generatorUtils.getDefaultComponentTemplates()).pipe(map(function (_a) {
            var listUniqueName = _a[0], createUniqueName = _a[1], detailUniqueName = _a[2], templates = _a[3];
            var pages = [];
            var hasDetail = _this.generatorUtils.isModelDetailSupported(resource, modelDescription);
            if (modelDescription.getQuery) {
                pages.push({
                    page: _this.getListPage(project, resource, modelDescription, listUniqueName, {
                        templates: templates,
                        createUniqueName: updateEnabled && modelDescription.createQuery ? createUniqueName : undefined,
                        detailUniqueName: hasDetail ? detailUniqueName : undefined,
                        listFields: options.listFields,
                        idGenerator: options.idGenerator
                    }),
                    initial: true
                });
            }
            if (updateEnabled && modelDescription.createQuery) {
                pages.push({
                    page: _this.getCreatePage(project, resource, modelDescription, createUniqueName, {
                        templates: templates,
                        detailUniqueName: hasDetail ? detailUniqueName : undefined,
                        listUniqueName: listUniqueName,
                        fields: options.createFields,
                        idGenerator: options.idGenerator
                    })
                });
            }
            if (hasDetail) {
                if (updateEnabled && modelDescription.updateQuery) {
                    pages.push({
                        page: _this.getUpdatePage(project, resource, modelDescription, detailUniqueName, {
                            templates: templates,
                            deleteEnabled: updateEnabled && !!modelDescription.deleteQuery,
                            listUniqueName: listUniqueName,
                            fields: options.updateFields,
                            idGenerator: options.idGenerator
                        })
                    });
                }
                else {
                    pages.push({
                        page: _this.getDetailPage(project, resource, modelDescription, detailUniqueName, {
                            templates: templates,
                            deleteEnabled: updateEnabled && !!modelDescription.deleteQuery,
                            listUniqueName: listUniqueName,
                            fields: options.detailFields,
                            idGenerator: options.idGenerator
                        })
                    });
                }
            }
            return pages;
        }));
    };
    return AdminPanelSeparatePagesGenerator;
}());
export { AdminPanelSeparatePagesGenerator };

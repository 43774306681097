import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsYesterdayFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_yesterday';
  public param = 'isYesterday';
  public verboseName = localize('is Yesterday');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('day').subtract(1, 'days').toISOString();
  }

  to(value: string): string {
    return moment().startOf('day').subtract(1, 'days').endOf('day').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not yesterday', [field]);
      } else {
        return localize('is not yesterday');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is yesterday', [field]);
      } else {
        return localize('yesterday');
      }
    }
  }
}

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { AnalyticsEvent } from '@modules/analytics';
import { DEMO_RESOURCES_PROJECT } from '@modules/api';
import { MenuService } from '@modules/menu';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceCreateController } from '@modules/template-components';
import { ascComparator } from '@shared';
var CustomizeMenuResourcesComponent = /** @class */ (function () {
    function CustomizeMenuResourcesComponent(menuService, currentProjectStore, currentEnvironmentStore, resourceCreateController, cd) {
        this.menuService = menuService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceCreateController = resourceCreateController;
        this.cd = cd;
        this.closeMenu = new EventEmitter();
        this.items = [];
        this.analyticsEvents = AnalyticsEvent;
    }
    CustomizeMenuResourcesComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.currentProjectStore.instance$, this.currentEnvironmentStore.resources$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], resources = _a[1];
            _this.project = project;
            _this.items = resources
                ? resources
                    .filter(function (item) { return !item.typeItem.protected; })
                    .filter(function (item) { return !item.demo || (item.demo && project.uniqueName == DEMO_RESOURCES_PROJECT); })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.name.toLowerCase(), rhs.name.toLowerCase()); })
                : [];
            _this.cd.markForCheck();
        }, function () {
            _this.project = undefined;
            _this.items = [];
            _this.cd.markForCheck();
        });
    };
    CustomizeMenuResourcesComponent.prototype.ngOnDestroy = function () { };
    CustomizeMenuResourcesComponent.prototype.createResource = function () {
        this.resourceCreateController
            .create({ analyticsSource: 'menu_data' })
            // .pipe(untilDestroyed(this))
            .subscribe();
    };
    return CustomizeMenuResourcesComponent;
}());
export { CustomizeMenuResourcesComponent };

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'appFormatText'
})
export class FormatTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    if (typeof value !== 'string') {
      return value;
    }

    const textParsed = value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    return this.sanitizer.sanitize(SecurityContext.HTML, textParsed);
  }
}

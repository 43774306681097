import { Pipe, PipeTransform } from '@angular/core';

import { initials } from '../../utils/text/text';

@Pipe({
  name: 'appInitials'
})
export class InitialsPipe implements PipeTransform {
  transform(value: any): any {
    return initials(value);
  }
}

import { FormControl, FormGroup } from '@angular/forms';

import { Confirmation, confirmationDefaultDescription, confirmationDefaultTitle } from '@modules/actions';
import { Input, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

const defaultTitle = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: confirmationDefaultTitle
};

const defaultDescription = {
  name: 'value',
  value_type: InputValueType.StaticValue,
  static_value: confirmationDefaultDescription
};

export class ConfirmationControl extends FormGroup {
  instance: Confirmation;

  controls: {
    title: FieldInputControl;
    description: FieldInputControl;
    submit_label: FormControl;
    submit_tint: FormControl;
    submit_icon: FormControl;
    cancel_label: FormControl;
    cancel_tint: FormControl;
    cancel_icon: FormControl;
  };

  constructor() {
    super({
      title: new FieldInputControl(defaultTitle),
      description: new FieldInputControl(defaultDescription),
      submit_label: new FormControl(''),
      submit_tint: new FormControl(null),
      submit_icon: new FormControl(null),
      cancel_label: new FormControl(''),
      cancel_tint: new FormControl(null),
      cancel_icon: new FormControl(null)
    });
  }

  deserialize(instance: Confirmation) {
    this.instance = instance;

    this.controls.title.patchValue(instance && instance.title ? instance.title.serialize() : defaultTitle);
    this.controls.description.patchValue(
      instance && instance.description ? instance.description.serialize() : defaultDescription
    );
    this.controls.submit_label.patchValue(instance ? instance.submitLabel : '');
    this.controls.submit_tint.patchValue(instance ? instance.submitTint : '');
    this.controls.submit_icon.patchValue(instance ? instance.submitIcon : '');
    this.controls.cancel_label.patchValue(instance ? instance.cancelLabel : '');
    this.controls.cancel_tint.patchValue(instance ? instance.cancelTint : '');
    this.controls.cancel_icon.patchValue(instance ? instance.cancelIcon : '');
  }

  serialize(): Confirmation {
    const instance = this.instance || new Confirmation();

    instance.title = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
    instance.description = this.controls.description.value
      ? new Input().deserialize(this.controls.description.value)
      : undefined;

    instance.submitLabel = this.controls.submit_label.value;
    instance.submitTint = this.controls.submit_tint.value;
    instance.submitIcon = this.controls.submit_icon.value;
    instance.cancelLabel = this.controls.cancel_label.value;
    instance.cancelTint = this.controls.cancel_tint.value;
    instance.cancelIcon = this.controls.cancel_icon.value;

    return instance;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-timeline-stub-content',
  templateUrl: './timeline-stub-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineStubContentComponent implements OnInit, OnChanges {
  @Input() header = true;
  @Input() columns = 5;
  @Input() rows = 4;
  @Input() animating = false;
  @Input() scrollable = false;
  @Input() height: number;

  columnsRange: number[];
  rowsRange: number[];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<TimelineStubContentComponent>): void {
    if (changes.columns) {
      this.columnsRange = range(1, this.columns + 1);
    }

    if (changes.rows) {
      this.rowsRange = range(1, this.rows + 1);
    }
  }
}

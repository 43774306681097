var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';
var ModelApiGetDetailQueryComponent = /** @class */ (function (_super) {
    __extends(ModelApiGetDetailQueryComponent, _super);
    function ModelApiGetDetailQueryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.parameters = [];
        return _this;
    }
    ModelApiGetDetailQueryComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchModel();
        this.updateParameters();
    };
    ModelApiGetDetailQueryComponent.prototype.updateParameters = function () {
        this.parameters = this.getParameters();
        this.cd.markForCheck();
    };
    ModelApiGetDetailQueryComponent.prototype.updateCurl = function () {
        this.curl = this.getCurlRequest({
            url: this.getAbsoluteApiUrl()
        });
    };
    ModelApiGetDetailQueryComponent.prototype.getParametersAuto = function () {
        var _this = this;
        return this.modelDescription.getDetailParametersOrDefaults.map(function (item) {
            var type = _this.modelDescription.primaryKeyField ? ApiParameterType.Path : ApiParameterType.Body;
            return fieldToApiParameter(item, type);
        });
    };
    ModelApiGetDetailQueryComponent.prototype.getQueryUrl = function () {
        return "/:" + this.getPrimaryKey();
    };
    ModelApiGetDetailQueryComponent.prototype.getResponseData = function () {
        var model = this.model || this.getDefaultModel();
        var modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);
        return JSON.stringify(modelData, null, this.indentSize);
    };
    return ModelApiGetDetailQueryComponent;
}(ModelApiBaseQueryComponent));
export { ModelApiGetDetailQueryComponent };

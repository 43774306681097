import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { MenuItemActionService, SimpleMenuItem } from '@modules/menu';
import { TypedChanges } from '@shared';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-simple-menu-item',
  templateUrl: './simple-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() menuItem: SimpleMenuItem;

  link: any[];
  href: string;
  queryParams: Object;
  handler: () => Observable<any>;

  constructor(private menuItemActionService: MenuItemActionService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.updateLink();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SimpleMenuItemComponent>): void {
    if (changes.menuItem && !changes.menuItem.firstChange) {
      this.updateLink();
    }
  }

  updateLink() {
    this.menuItemActionService
      .getActionExecution(this.menuItem.action, { context: this.context })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.link = result.link;
        this.href = result.href;
        this.queryParams = result.queryParams;
        this.handler = result.handler;
        this.cd.markForCheck();
      });
  }
}

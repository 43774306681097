import { localize } from '@common/localize';
import { isSet } from '@shared';

import { FieldLookup } from './base';

export class LteFieldLookup extends FieldLookup {
  public lookup = 'lte';
  public param = 'lte';
  public verboseName = localize('less than or equals');
  public icon = 'less_than_or_equals';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not less than or equals {1}', [field, value]);
      } else {
        return localize('is not less than or equals');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is less than or equals {1}', [field, value]);
      } else {
        return localize('less than or equals');
      }
    }
  }
}

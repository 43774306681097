import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';

import { CustomView, CustomViewsStore } from '@modules/custom-views';

@Component({
  selector: 'app-common-custom-view',
  templateUrl: './common-custom-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonCustomViewComponent implements OnInit, OnDestroy {
  @ViewChild('container') container: ElementRef;

  loading = false;
  customView: CustomView;

  constructor(
    private customViewsStore: CustomViewsStore,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          this.loading = true;
          this.cd.markForCheck();

          return this.customViewsStore.getDetail(params['unique_name']);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.customView = result;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}
}

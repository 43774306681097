export const VIEW_CONTEXT_OUTPUTS_KEY = '__view_context_outputs__';

export function ViewContextPropertyOutput(options: { uniqueName: string; name: string }) {
  return (target: {} | any, property?: PropertyKey): any => {
    if (!target.hasOwnProperty(VIEW_CONTEXT_OUTPUTS_KEY)) {
      target[VIEW_CONTEXT_OUTPUTS_KEY] = [];
    }

    if (target[VIEW_CONTEXT_OUTPUTS_KEY].find(item => item.uniqueName == options.uniqueName)) {
      return;
    }

    target[VIEW_CONTEXT_OUTPUTS_KEY].push({
      property: property,
      uniqueName: options.uniqueName,
      name: options.name
    });
  };
}

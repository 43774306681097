import { Frame } from './frame';
import { Translate } from './translate';

export class FrameTranslate {
  frame: Frame;
  translate: Translate = { x: 0, y: 0 };

  constructor(options: { frame: Frame; translate?: Partial<Translate> }) {
    this.frame = options.frame;
    this.translate = { x: 0, y: 0, ...options.translate };
  }

  get x(): number {
    return this.frame.x + this.translate.x;
  }

  get y(): number {
    return this.frame.y + this.translate.y;
  }

  get width(): number {
    return this.frame.width;
  }

  get height(): number {
    return this.frame.height;
  }
}

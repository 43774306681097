/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/drag-drop/directives/draggable-item/draggable-item.directive";
import * as i2 from "../../../../../common/drag-drop/services/draggable/draggable.service";
import * as i3 from "../../../../../common/drag-drop/directives/draggable/draggable.directive";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i6 from "../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i7 from "../../../../field-components/components/field-errors/field-errors.component.ngfactory";
import * as i8 from "../../../../field-components/components/field-errors/field-errors.component";
import * as i9 from "@angular/common";
import * as i10 from "./change-custom-view-files.component";
import * as i11 from "../../../../custom-views/services/custom-view/custom-view.service";
var styles_ChangeCustomViewFilesComponent = [];
var RenderType_ChangeCustomViewFilesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeCustomViewFilesComponent, data: {} });
export { RenderType_ChangeCustomViewFilesComponent as RenderType_ChangeCustomViewFilesComponent };
function View_ChangeCustomViewFilesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["appDraggableItem", ""], ["class", "handle"]], null, null, null, null, null)), i0.ɵdid(1, 4341760, null, 0, i1.DraggableItemDirective, [i0.ElementRef, i2.DraggableService, i3.DraggableDirective], { data: [0, "data"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "field"], ["style", "margin-left: 0; margin-right: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.checked = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.updateParams() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i4.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i0.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["class", "field__label field__label_inline"]], [[2, "field__label_disabled", null], [4, "cursor", null], [8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_9 = _v.context.$implicit.disabled; var currVal_10 = _v.context.$implicit.checked; _ck(_v, 6, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_1 = ("file_" + _v.context.index); var currVal_2 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 8).ngClassValid; var currVal_7 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = _v.context.$implicit.disabled; var currVal_12 = "move"; var currVal_13 = ("file_" + _v.context.index); _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13); var currVal_14 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_14); }); }
function View_ChangeCustomViewFilesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "app-sidebar-field", [], null, null, null, i5.View_SidebarFieldComponent_0, i5.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i6.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 1, "button", [["class", "button button_orange-alternative button_small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setAllSelect(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Select All "])), (_l()(), i0.ɵeld(5, 0, null, 3, 1, "button", [["class", "button button_orange-alternative button_small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setAllSelect(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Deselect All "])), (_l()(), i0.ɵeld(7, 0, null, 3, 1, "app-field-errors", [], null, null, null, i7.View_FieldErrorsComponent_0, i7.RenderType_FieldErrorsComponent)), i0.ɵdid(8, 114688, null, 0, i8.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null), (_l()(), i0.ɵeld(9, 0, null, 3, 4, "div", [], null, [[null, "appDraggableItemsUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDraggableItemsUpdated" === en)) {
        var pd_0 = (_co.onOrderingUpdated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 212992, null, 0, i3.DraggableDirective, [i0.ElementRef, i0.ApplicationRef, i2.DraggableService], { name: [0, "name"], options: [1, "options"] }, { itemsUpdated: "appDraggableItemsUpdated" }), i0.ɵpod(11, { handle: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomViewFilesComponent_2)), i0.ɵdid(13, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Files to include into page (only JS and CSS, order can be changed)"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.filesControl.parent; var currVal_2 = "files"; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = "files"; var currVal_4 = _ck(_v, 11, 0, ".field__label"); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _co.files; _ck(_v, 13, 0, currVal_5); }, null); }
export function View_ChangeCustomViewFilesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeCustomViewFilesComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChangeCustomViewFilesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-change-custom-view-files", [], null, null, null, View_ChangeCustomViewFilesComponent_0, RenderType_ChangeCustomViewFilesComponent)), i0.ɵdid(1, 4964352, null, 0, i10.ChangeCustomViewFilesComponent, [i11.CustomViewService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangeCustomViewFilesComponentNgFactory = i0.ɵccf("app-change-custom-view-files", i10.ChangeCustomViewFilesComponent, View_ChangeCustomViewFilesComponent_Host_0, { distControl: "distControl", distFilesControl: "distFilesControl", filesControl: "filesControl" }, {}, []);
export { ChangeCustomViewFilesComponentNgFactory as ChangeCustomViewFilesComponentNgFactory };

import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Injectable()
export class ImportSketchFileForm extends FormGroup implements OnDestroy {
  controls: {
    file: FormControl;
    page: FormControl;
    artboard: FormControl;
  };

  constructor() {
    super({
      file: new FormControl(null, Validators.required),
      page: new FormControl(null, Validators.required),
      artboard: new FormControl(null, Validators.required)
    });

    this.controls.file.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.controls.page.patchValue(null);
      this.controls.artboard.patchValue(null);
    });

    this.controls.page.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.controls.artboard.patchValue(null);
    });
  }

  ngOnDestroy(): void {}
}

<div class="compact-inputs-overlay overlay-content overlay-content_animated theme_dark">
  <div *ngIf="!fillTypesEnabled || fillTypesEnabled.length > 1" class="compact-inputs-element">
    <div class="compact-selector">
      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Color)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="control.controls.type.value == fillTypes.Color"
        (click)="control.controls.type.patchValue(fillTypes.Color)"
      >
        <div class="compact-selector__item-icon compact-selector__item-icon_solid"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Image)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="control.controls.type.value == fillTypes.Image"
        (click)="control.controls.type.patchValue(fillTypes.Image)"
      >
        <div class="compact-selector__item-icon icon-image"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Icon)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="control.controls.type.value == fillTypes.Icon"
        (click)="control.controls.type.patchValue(fillTypes.Icon)"
      >
        <div class="compact-selector__item-icon icon-blocks compact-selector__item-icon_size_s"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="
          control.controls.type.value == fillTypes.Gradient &&
          control.controls.gradient.controls.type.value == gradientTypes.Linear
        "
        (click)="control.setGradientType(gradientTypes.Linear)"
      >
        <div class="compact-selector__item-icon compact-selector__item-icon_linear-gradient"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="
          control.controls.type.value == fillTypes.Gradient &&
          control.controls.gradient.controls.type.value == gradientTypes.Radial
        "
        (click)="control.setGradientType(gradientTypes.Radial)"
      >
        <div class="compact-selector__item-icon compact-selector__item-icon_radial-gradient"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="
          control.controls.type.value == fillTypes.Gradient &&
          control.controls.gradient.controls.type.value == gradientTypes.Angular
        "
        (click)="control.setGradientType(gradientTypes.Angular)"
      >
        <div class="compact-selector__item-icon compact-selector__item-icon_angular-gradient"></div>
      </div>

      <div
        *ngIf="!fillTypesEnabled || fillTypesEnabled.includes(fillTypes.Gradient)"
        class="compact-selector__item"
        [class.compact-selector__item_active]="
          control.controls.type.value == fillTypes.Gradient &&
          control.controls.gradient.controls.type.value == gradientTypes.Diamond
        "
        (click)="control.setGradientType(gradientTypes.Diamond)"
      >
        <div class="compact-selector__item-icon compact-selector__item-icon_diamond"></div>
      </div>
    </div>
  </div>

  <app-color-with-input-selector
    *ngIf="control.controls.type.value == fillTypes.Color"
    [colorControl]="control.controls.color"
    [colorInputEnabledControl]="control.controls.color_input_enabled"
    [colorInputControl]="control.controls.color_input"
    [viewContext]="viewContext"
  ></app-color-with-input-selector>

  <app-gradient-selector
    *ngIf="control.controls.type.value == fillTypes.Gradient"
    [control]="control.controls.gradient"
    [view]="view"
    [layer]="layer"
    [viewContext]="viewContext"
  ></app-gradient-selector>

  <app-image-fill-selector
    *ngIf="control.controls.type.value == fillTypes.Image"
    [control]="control.controls.image_fill"
    [viewContext]="viewContext"
  ></app-image-fill-selector>

  <app-icon-fill-selector
    *ngIf="control.controls.type.value == fillTypes.Icon"
    [control]="control.controls.icon_fill"
    [viewContext]="viewContext"
  ></app-icon-fill-selector>
</div>

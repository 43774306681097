import { ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EmbedScripts, ScriptsService } from '@core';
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/services/scripts/scripts.service";
var SurveyService = /** @class */ (function () {
    function SurveyService(scriptsService) {
        this.scriptsService = scriptsService;
        this.unsubscribeFormId = 'mJbGJm';
    }
    SurveyService.prototype.openUnsubscribe = function (options) {
        var _this = this;
        var result$ = new ReplaySubject();
        return this.scriptsService.loadSingleton(EmbedScripts.Tally).pipe(switchMap(function () {
            Tally.openPopup(_this.unsubscribeFormId, {
                layout: 'modal',
                width: 820,
                overlay: true,
                autoClose: 0,
                hiddenFields: {
                    project_id: options.project.uniqueName,
                    email: options.user.email,
                    user_id: options.user.uid,
                    plan_id: options.project.subscription && options.project.subscription.plan
                        ? options.project.subscription.plan.uniqueName
                        : undefined,
                    subscription_price: options.project.subscription ? options.project.subscription.price : undefined
                },
                onClose: function () {
                    result$.next({ closed: true });
                },
                onSubmit: function () {
                    result$.next({ finished: true });
                }
            });
            return result$.asObservable();
        }));
    };
    SurveyService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyService_Factory() { return new SurveyService(i0.inject(i1.ScriptsService)); }, token: SurveyService, providedIn: "root" });
    return SurveyService;
}());
export { SurveyService };

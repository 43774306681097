import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { CustomHttpParameterCodec } from '@core';
import { ApiService } from '@modules/api';
import { Task } from '../../data/task';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/custom-http-parameter-codec/custom-http-parameter-codec";
var TaskService = /** @class */ (function () {
    function TaskService(http, apiService, parameterCodec) {
        this.http = http;
        this.apiService = apiService;
        this.parameterCodec = parameterCodec;
    }
    TaskService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'tasks/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params, encoder: _this.parameterCodec });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new TaskService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskService.prototype.getDetail = function (projectName, environmentName, uid, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "tasks/" + uid + "/");
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params, encoder: _this.parameterCodec });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new Task().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'tasks/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new Task().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskService.prototype.update = function (projectName, environmentName, instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "tasks/" + instance.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, instance.serialize(fields), { headers: headers });
        }), map(function (result) { return new Task().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TaskService.ngInjectableDef = i0.defineInjectable({ factory: function TaskService_Factory() { return new TaskService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.CustomHttpParameterCodec)); }, token: TaskService, providedIn: "root" });
    return TaskService;
}());
export { TaskService };
(function (TaskService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
            this.perPage = 20;
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new Task().deserialize(item); });
            this.hasMore = !!data['next'];
            this.next = data['next'];
            this.previous = data['previous'];
            this.count = data['count'];
            return this;
        };
        Object.defineProperty(GetResponse.prototype, "numPages", {
            get: function () {
                return Math.ceil(this.count / this.perPage);
            },
            enumerable: true,
            configurable: true
        });
        return GetResponse;
    }());
    TaskService.GetResponse = GetResponse;
})(TaskService || (TaskService = {}));

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { applyBooleanInput$, Input } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';
import { BlendingMode } from './blending-mode';
import { Constraints } from './constraints';
import { Frame } from './frame';
import { CursorLayerInteraction, deserializeLayerInteraction } from './layer-interaction';
import { LayerInteractionType } from './layer-interaction';
import { deserializerLayer } from './layer-types';
export var defaultConstraints = new Constraints();
var Layer = /** @class */ (function () {
    function Layer(options) {
        if (options === void 0) { options = {}; }
        this.widthFluid = false;
        this.heightFluid = false;
        this.absoluteLayout = false;
        this.reflectHorizontal = false;
        this.reflectVertical = false;
        this.opacity = 1;
        this.blendingMode = BlendingMode.Normal;
        this.visible = true;
        this.interactions = [];
        Object.assign(this, options);
    }
    Layer.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.name = data['name'];
        this.opacity = data['opacity'];
        if (data['frame']) {
            this.frame = new Frame().deserialize(data['frame']);
        }
        if (data['constrain_proportion']) {
            this.constrainProportion = data['constrain_proportion'];
        }
        if (data['reflect_horizontal'] != undefined) {
            this.reflectHorizontal = data['reflect_horizontal'];
        }
        if (data['reflect_vertical'] != undefined) {
            this.reflectVertical = data['reflect_vertical'];
        }
        if (data['constraints']) {
            this.constraints = new Constraints().deserialize(data['constraints']);
        }
        if (isSet(data['blending_mode'])) {
            this.blendingMode = data['blending_mode'];
        }
        if (isSet(data['visible'])) {
            this.visible = data['visible'];
        }
        if (data['visible_input']) {
            this.visibleInput = new Input().deserialize(data['visible_input']);
        }
        if (data['interactions']) {
            this.interactions = data['interactions'].map(function (item) { return deserializeLayerInteraction(item); });
        }
        if (!this.id) {
            this.generateId();
        }
        return this;
    };
    Layer.prototype.serialize = function () {
        return {
            type: this.type,
            id: this.id,
            name: this.name,
            frame: this.frame ? this.frame.serialize() : undefined,
            constrain_proportion: this.constrainProportion,
            reflect_horizontal: this.reflectHorizontal,
            reflect_vertical: this.reflectVertical,
            constraints: this.constraints ? this.constraints.serialize() : undefined,
            opacity: this.opacity,
            blending_mode: this.blendingMode,
            visible: this.visible,
            visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
            interactions: this.interactions.map(function (item) { return item.serialize(); })
        };
    };
    Layer.prototype.generateId = function () {
        this.id = generateAlphanumeric(8, { letterFirst: true });
    };
    Layer.prototype.isSame = function (layer) {
        return this.id == layer.id;
    };
    Layer.prototype.getConstraintsOrDefault = function () {
        return this.constraints || defaultConstraints;
    };
    Layer.prototype.cssMixBlendMode = function () {
        return this.blendingMode;
    };
    Layer.prototype.hasDisableTextSelectionInteraction = function () {
        return this.interactions.some(function (item) { return item.type == LayerInteractionType.DisableTextSelection; });
    };
    Layer.prototype.getCursor = function () {
        var interaction = this.interactions.find(function (item) { return item.type == LayerInteractionType.Cursor; });
        if (interaction instanceof CursorLayerInteraction) {
            return interaction.cursor;
        }
    };
    Layer.prototype.visible$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.visibleInput) {
            return applyBooleanInput$(this.visibleInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            }).pipe(catchError(function () { return of(false); }));
        }
        else {
            return of(true);
        }
    };
    Layer.prototype.applyMouseEventsFrame = function (frame, options) {
        if (options === void 0) { options = {}; }
        var translateX = options.translate ? options.translate.x : undefined;
        var translateY = options.translate ? options.translate.y : undefined;
        this.frame.patch(__assign({}, frame, { x: frame.x - (translateX || 0), y: frame.y - (translateY || 0) }));
    };
    return Layer;
}());
export { Layer };
var ContainerLayer = /** @class */ (function (_super) {
    __extends(ContainerLayer, _super);
    function ContainerLayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.layers = [];
        _this.layersOpened = false;
        return _this;
    }
    ContainerLayer.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['layers']) {
            this.layers = data['layers'].map(function (item) { return deserializerLayer(item); }).filter(function (item) { return item; });
        }
        if (isSet(data['layers_opened'])) {
            this.layersOpened = data['layers_opened'];
        }
        return this;
    };
    ContainerLayer.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { layers: this.layers.map(function (item) { return item.serialize(); }), layers_opened: this.layersOpened });
    };
    return ContainerLayer;
}(Layer));
export { ContainerLayer };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alert-element-action/action-group-element-item.component.ngfactory";
import * as i2 from "./alert-element-action/action-group-element-item.component";
import * as i3 from "../../../action-queries/services/action/action.service";
import * as i4 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i5 from "../../../customize/services/customize/customize.service";
import * as i6 from "../../../../common/notifications/services/notification/notification.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i9 from "../../../theme-components/data/theme-context";
import * as i10 from "./action-group-element.component";
var styles_ActionGroupElementComponent = [];
var RenderType_ActionGroupElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionGroupElementComponent, data: {} });
export { RenderType_ActionGroupElementComponent as RenderType_ActionGroupElementComponent };
function View_ActionGroupElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-action-group-element-item", [], null, null, null, i1.View_ActionGroupElementItemComponent_0, i1.RenderType_ActionGroupElementItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.ActionGroupElementItemComponent, [i3.ActionService, i4.ActionControllerService, i5.CustomizeService, i6.NotificationService, i0.Injector, i0.ChangeDetectorRef], { item: [0, "item"], context: [1, "context"], fill: [2, "fill"], left: [3, "left"], right: [4, "right"], top: [5, "top"], bottom: [6, "bottom"], middle: [7, "middle"], accentColor: [8, "accentColor"], analyticsSource: [9, "analyticsSource"] }, null), i0.ɵppd(2, 1), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.context; var currVal_2 = (_co.element.orientation == _co.orientations.Vertical); var currVal_3 = (((_co.element.orientation == _co.orientations.Horizontal) && _v.context.first) && !_v.context.last); var currVal_4 = (((_co.element.orientation == _co.orientations.Horizontal) && !_v.context.first) && _v.context.last); var currVal_5 = (((_co.element.orientation == _co.orientations.Vertical) && _v.context.first) && !_v.context.last); var currVal_6 = (((_co.element.orientation == _co.orientations.Vertical) && !_v.context.first) && _v.context.last); var currVal_7 = (!_v.context.first && !_v.context.last); var currVal_8 = i0.ɵunv(_v, 1, 8, i0.ɵnov(_v, 3).transform(i0.ɵunv(_v, 1, 8, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "accentColor")))); var currVal_9 = _co.element.analyticsGenericName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ActionGroupElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.ThemeColorHexOptionPipe, [[2, i9.ThemeContext]]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "action-button action-button_manual-margin"]], [[2, "action-button_vertical", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionGroupElementComponent_1)), i0.ɵdid(3, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.element.actions; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.element.orientation == _co.orientations.Vertical); _ck(_v, 1, 0, currVal_0); }); }
export function View_ActionGroupElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-action-group-element", [], null, null, null, View_ActionGroupElementComponent_0, RenderType_ActionGroupElementComponent)), i0.ɵdid(1, 245760, null, 0, i10.ActionGroupElementComponent, [i5.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionGroupElementComponentNgFactory = i0.ɵccf("app-action-group-element", i10.ActionGroupElementComponent, View_ActionGroupElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { ActionGroupElementComponentNgFactory as ActionGroupElementComponentNgFactory };

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, Provider } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { UniqueIdToken } from '@common/unique-id';
import { ACTION_SERVICE_IMPORT_COMPONENT } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ExportService } from '@modules/export';
import { createFormFieldFactory, getFieldDescriptionByType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { controlValue, getExtension, getFilenameWithExtension, isSet } from '@shared';
import { ModelImportEventType } from '../../data/model-import.event';
import { IMPORT_OBJECT_ERROR_KEY, ImportService } from '../../services/import/import.service';
import { ImportModelsForm } from './import-models.form';
export var ACTION_SERVICE_IMPORT_COMPONENT_PROVIDER = {
    provide: ACTION_SERVICE_IMPORT_COMPONENT,
    useFactory: actionServiceImportComponentFactory
};
export function actionServiceImportComponentFactory() {
    return ImportModelsComponent;
}
var ImportModelsComponent = /** @class */ (function () {
    function ImportModelsComponent(form, popupComponent, importService, exportService, analyticsService, cd) {
        this.form = form;
        this.popupComponent = popupComponent;
        this.importService = importService;
        this.exportService = exportService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.cancelled = new EventEmitter();
        this.imported = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.idToken = new UniqueIdToken();
        this.submitLoading = false;
        this.draggingOver = false;
        this.sourceOptions = [];
    }
    ImportModelsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.resource, this.modelDescription);
        controlValue(this.form.controls.file)
            .pipe(untilDestroyed(this))
            .subscribe(function (file) {
            if (file) {
                _this.previewFilename = getFilenameWithExtension(file.name);
                _this.previewExtension = getExtension(file.name);
                _this.previewSize = file.size;
            }
            else {
                _this.previewFilename = undefined;
                _this.previewExtension = undefined;
                _this.previewSize = undefined;
            }
            _this.cd.markForCheck();
        });
        this.form
            .getFileContent$()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var fields = result && result.content ? result.content.fields : [];
            _this.fileContent = result;
            _this.fileContentInfo =
                result && result.content
                    ? result.content.data.length + " records, " + result.content.fields.length + " fields"
                    : undefined;
            _this.sourceOptions = fields.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    option: {
                        value: item.name,
                        name: item.verboseName || item.name,
                        icon: fieldDescription.icon
                    }
                };
            });
            _this.cd.markForCheck();
        });
        controlValue(this.form.controls.fields)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.cd.markForCheck(); });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportOpened, {
            ResourceID: this.resource.typeItem.name,
            ResourceType: this.resource.typeItem.resourceType,
            NewCollection: !this.modelDescription,
            CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
            Source: this.analyticsSource
        });
    };
    ImportModelsComponent.prototype.ngOnDestroy = function () { };
    ImportModelsComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.form.controls.file.setValue(file);
        this.form.markAsPristine();
    };
    ImportModelsComponent.prototype.onDragOver = function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.draggingOver = true;
        this.cd.markForCheck();
    };
    ImportModelsComponent.prototype.onDragLeave = function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.draggingOver = false;
        this.cd.markForCheck();
    };
    ImportModelsComponent.prototype.onDrop = function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (!e.dataTransfer.files.length) {
            return;
        }
        var file = e.dataTransfer.files[0];
        this.draggingOver = false;
        this.cd.markForCheck();
        if (file) {
            this.form.controls.file.setValue(file);
            this.form.markAsPristine();
        }
    };
    ImportModelsComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    ImportModelsComponent.prototype.backToUpload = function () {
        if (this.progress === undefined) {
            this.form.controls.file.patchValue(undefined);
        }
    };
    ImportModelsComponent.prototype.submit = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportStarted, {
            ResourceID: this.resource.typeItem.name,
            ResourceType: this.resource.typeItem.resourceType,
            NewCollection: !this.modelDescription,
            CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
            FileName: this.previewFilename,
            FileExtension: this.previewExtension,
            FileSize: this.previewSize,
            Source: this.analyticsSource
        });
        this.submitLoading = true;
        this.cd.markForCheck();
        this.form
            .submit()
            .pipe(untilDestroyed(this))
            .subscribe(function (event) {
            if (event.type == ModelImportEventType.Started) {
                _this.importModelDescription = event.modelDescription;
                _this.successCount = 0;
                _this.failedCount = 0;
                _this.totalCount = event.totalCount;
                _this.progress = 0;
            }
            else if (event.type == ModelImportEventType.Progress) {
                var progressEvent = event;
                _this.importModelDescription = event.modelDescription;
                _this.successCount = progressEvent.successCount;
                _this.failedCount = progressEvent.failedCount;
                _this.progress = progressEvent.totalCount ? progressEvent.processedCount / progressEvent.totalCount : 1;
            }
            else if (event.type == ModelImportEventType.Finished) {
                var finishedEvent = event;
                _this.importModelDescription = event.modelDescription;
                _this.successCount = finishedEvent.successCount;
                _this.failedCount = finishedEvent.failedCount;
                _this.progress = 1;
                _this.result = finishedEvent;
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportFinished, {
                    ResourceID: _this.resource.typeItem.name,
                    ResourceType: _this.resource.typeItem.resourceType,
                    NewCollection: !_this.modelDescription,
                    CollectionID: _this.modelDescription ? _this.modelDescription.model : undefined,
                    FileName: _this.previewFilename,
                    FileExtension: _this.previewExtension,
                    FileSize: _this.previewSize,
                    SuccessCount: finishedEvent.successCount,
                    FailedCount: finishedEvent.failedCount,
                    TotalCount: finishedEvent.totalCount,
                    Source: _this.analyticsSource
                });
            }
            _this.cd.markForCheck();
        }, function (error) {
            var errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
            console.error(error);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportFailed, {
                ResourceID: _this.resource.typeItem.name,
                ResourceType: _this.resource.typeItem.resourceType,
                NewCollection: !_this.modelDescription,
                CollectionID: _this.modelDescription ? _this.modelDescription.model : undefined,
                FileName: _this.previewFilename,
                FileExtension: _this.previewExtension,
                FileSize: _this.previewSize,
                Error: errorMessage,
                Source: _this.analyticsSource
            });
        });
    };
    ImportModelsComponent.prototype.downloadReport = function () {
        var title = ['import_errors'].concat((this.modelDescription ? [this.modelDescription.verboseNamePlural] : [])).join('_');
        var columns = [
            { name: IMPORT_OBJECT_ERROR_KEY, verboseName: 'import error' }
        ].concat(this.fileContent.content.fields);
        var data = this.result.objectResults.map(function (item) {
            return columns.map(function (column) { return item[column.name]; });
        });
        var fileFormatType = this.importService.getFileFormatType(this.form.controls.file.value);
        this.exportService.downloadData(title, columns, data, fileFormatType).pipe(untilDestroyed(this)).subscribe();
    };
    ImportModelsComponent.prototype.finish = function () {
        this.close();
        this.imported.emit(this.result);
    };
    ImportModelsComponent.prototype.cancel = function () {
        var processedCount = isSet(this.successCount) && isSet(this.successCount) ? this.successCount + this.failedCount : undefined;
        this.cancelled.emit({
            type: ModelImportEventType.Cancelled,
            modelDescription: this.importModelDescription,
            processedCount: processedCount,
            successCount: this.successCount,
            failedCount: this.failedCount,
            totalCount: this.totalCount
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ImportRecords.ImportCancelled, {
            ResourceID: this.resource.typeItem.name,
            ResourceType: this.resource.typeItem.resourceType,
            NewCollection: !this.modelDescription,
            CollectionID: this.modelDescription ? this.modelDescription.model : undefined,
            FileName: this.previewFilename,
            FileExtension: this.previewExtension,
            FileSize: this.previewSize,
            SuccessCount: this.successCount,
            FailedCount: this.failedCount,
            TotalCount: this.totalCount,
            Source: this.analyticsSource
        });
        this.close();
    };
    return ImportModelsComponent;
}());
export { ImportModelsComponent };

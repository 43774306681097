import { HttpMethod } from '../data/http-method';

export function submitForm(
  method: HttpMethod,
  url: string,
  data: { name: string; value: string }[],
  htmlDocument = document
) {
  const form = htmlDocument.createElement('form');

  form.method = method;
  form.action = url;

  data.forEach(item => {
    const input = htmlDocument.createElement('input');
    input.type = 'hidden';
    input.name = item.name;
    input.value = item.value;
    form.appendChild(input);
  });

  htmlDocument.body.appendChild(form);
  form.submit();
}

import pickBy from 'lodash/pickBy';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { AccordionItem } from './accordion-item';
import { ElementItem } from './base';
import { CollapseIndicatorPosition } from './collapse';

export class AccordionElementItem extends ElementItem {
  public type = ElementType.Accordion;
  public width: number;
  public items: AccordionItem[] = [];
  public multipleOpened = false;
  public collapseIndicatorPosition: CollapseIndicatorPosition = CollapseIndicatorPosition.Right;

  deserialize(data: Object): AccordionElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.multipleOpened = this.params['multiple_opened'];

    if (this.params['items']) {
      this.items = this.params['items'].map(item => new AccordionItem().deserialize(item));
    }

    if (this.params['collapse_indicator_position']) {
      this.collapseIndicatorPosition = this.params['collapse_indicator_position'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      items: this.items.map(item => item.serialize()),
      multiple_opened: this.multipleOpened,
      collapse_indicator_position: this.collapseIndicatorPosition
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get analyticsName(): string {
    return 'accordion';
  }

  defaultName() {
    return 'Accordion';
  }

  childrenCount() {
    return this.items.reduce((acc, item) => acc + item.children.length, 0);
  }
}

registerElementForType(ElementType.Accordion, AccordionElementItem);

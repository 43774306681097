<app-popup2 [size]="'m'" [side]="true">
  <app-popup2-side>
    <div class="custom-page-popup custom-page-popup_style_background custom-page-popup_border-radius_s">
      <div class="custom-page-popup__header">
        <div class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">Import from Figma</div>
          <div class="custom-page-popup__description">
            Open the Jet Admin
            <a href="https://www.figma.com/community/plugin/1312410537701919252/jet-admin-export" target="_blank"
              >Figma plugin</a
            >
            and export selected layers.<br />
            Here you can view all recent imports.
          </div>
        </div>
        <div class="custom-page-popup__header-right">
          <a href="javascript:void(0)" class="custom-page-popup__close icon-close" (click)="cancel()"></a>
        </div>
      </div>

      <div class="custom-page-popup__content">
        <app-page-block>
          <div class="field">
            <ng-container *ngIf="!importCodeLoading">
              <label class="field__label">
                Import key
                <a
                  *ngIf="importCode"
                  href="javascript:void(0)"
                  class="field__label-link"
                  style="margin-left: 4px;"
                  (click)="copy(importCode, 'Import key')"
                >
                  <span class="field__label-link-icon icon-duplicate"></span>
                  {{ 'Copy' | localize }}
                </a>
              </label>

              <div class="input-icon-wrapper">
                <span class="input-icon input-icon_left icon-link"></span>
                <input
                  class="input input_icon-left input_fill"
                  [value]="importCode ? importCode : ''"
                  (click)="$event.target.select()"
                  readonly
                />
              </div>
            </ng-container>

            <ng-container *ngIf="importCodeLoading">
              <label class="field__label">
                <span [class.loading-animation]="true"><span class="stub-text">Import key</span></span>
              </label>

              <input
                class="input input_stub input_fill"
                [class.loading-animation]="true"
                value="o8fmtzlvu5yc6z2p4esdwda5mez2ln1yy3nw8bivapp1nj9izb1n4b3qlk537knm"
                style="pointer-events: none;"
                readonly
              />
            </ng-container>
          </div>
        </app-page-block>

        <app-page-block>
          <table *ngIf="!items && loading" class="card-table card-table_compact">
            <tr *ngFor="let item of [1, 2, 3, 4]" class="card-table__row" app-import-figma-node-item-stub></tr>
          </table>

          <ng-container *ngIf="items">
            <div class="card-table-title card-table-title_padding_none">
              <div class="card-table-title__main">
                <div class="card-table-title__title card-table-title__title_muted">
                  Figma imports
                  <a
                    *ngIf="importCode"
                    href="javascript:void(0)"
                    class="card-table-title__title-link"
                    style="margin-left: 4px;"
                    (click)="loadImports()"
                  >
                    <span class="card-table-title__title-link-icon icon-repeat"></span>
                    Reload
                  </a>
                </div>
              </div>
            </div>

            <table class="card-table card-table_compact">
              <tbody>
                <tr
                  *ngFor="let item of items"
                  class="card-table__row"
                  app-import-figma-node-item
                  [item]="item"
                  (importView)="importView.emit($event); close()"
                ></tr>

                <tr *ngIf="loading" class="card-table__row" app-import-figma-node-item-stub></tr>

                <tr *ngIf="!items.length" class="card-table__row">
                  <td class="card-table__column" colspan="4">
                    <div class="card-table__column-inner card-table__column-inner_center">
                      No imports yet
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="hasMore && !loading" style="margin-top: 10px; text-align: center;">
              <a
                href="javascript:void(0)"
                class="button button_background button_small button_fill"
                (click)="loadMore()"
              >
                <span class="button__label">Load more</span>
              </a>
            </div>
          </ng-container>
        </app-page-block>
      </div>
    </div>

    <ng-container data-side>
      <p class="resource-popup__banner-description">
        Use Import key in
        <a href="https://www.figma.com/community/plugin/1312410537701919252/jet-admin-export" target="_blank"
          >Figma plugin</a
        >
        to import design to Jet Admin. Select layer > Plugins > Jet Admin Export > Enter Import key > Export selection
      </p>
      <video
        class="resource-popup__banner-video resource-popup__banner-video_zoom"
        [src]="'/assets/video/figma-import.mp4' | appDeployUrl"
        autoplay
        loop
        muted
        [appLightbox]="'/assets/video/figma-import.mp4' | appDeployUrl"
        [appLightboxVideo]="true"
        [appLightboxOrigin]="self"
        #self
      ></video>
    </ng-container>
  </app-popup2-side>
</app-popup2>

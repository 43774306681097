import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { MetaService } from '@modules/meta';
import { capitalize } from '@shared';

import { GuideTask } from '../../data/guide-task';
import { GuidePopupStoreService } from '../../services/guide-popup-store/guide-popup-store.service';

export interface GuidePopupOptions {
  left?: boolean;
}

@Component({
  selector: 'app-guide-popup',
  templateUrl: './guide-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuidePopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() options: GuidePopupOptions = {};

  @ViewChild('root') root: ElementRef;

  tl = new TimelineMax();
  animating = false;
  pageTitle: string;
  openedTask: GuideTask;
  openedDocumentationPath: string;

  constructor(
    private guidePopupStoreService: GuidePopupStoreService,
    private popupComponent: BasePopupComponent,
    private metaService: MetaService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.guidePopupStoreService.registerGuidePopup(this);
  }

  ngOnDestroy(): void {
    this.guidePopupStoreService.unregisterGuidePopup(this);
  }

  ngAfterViewInit(): void {
    this.metaService.title$.pipe(untilDestroyed(this)).subscribe(value => {
      let title = value || [];
      title = title.map(item => capitalize(item));
      this.pageTitle = title.join(' · ');
      this.cd.markForCheck();
    });

    this.show();
  }

  setOptions(options: { left?: boolean } = {}) {
    this.options = options;
    this.cd.markForCheck();
  }

  show() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          xPercent: this.options.left ? -100 : 100
        },
        {
          xPercent: 0,
          ease: Power2.easeOut
        },
        0.1
      )
      .add(() => {
        this.animating = false;
      });
  }

  close() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.root.nativeElement,
        0.4,
        {
          xPercent: 0
        },
        {
          xPercent: this.options.left ? -100 : 100,
          ease: Power2.easeOut
        }
      )
      .add(() => {
        this.animating = false;
        this.popupComponent.close();
      });
  }

  openTask(task: GuideTask) {
    if (this.openedTask === task) {
      return;
    }

    this.openedTask = task;
    this.openedDocumentationPath = undefined;
    this.cd.markForCheck();
  }

  openDocumentation(path: string) {
    if (this.openedDocumentationPath === path) {
      return;
    }

    this.openedTask = undefined;
    this.openedDocumentationPath = path;
    this.cd.markForCheck();
  }
}

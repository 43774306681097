<app-alert
  [title]="title"
  [icon]="element.icon"
  [secondary]="!element.color && element.tint == tints.Default"
  [info]="!element.color && element.tint == tints.Info"
  [danger]="!element.color && element.tint == tints.Danger"
  [warning]="!element.color && element.tint == tints.Warning"
  [success]="!element.color && element.tint == tints.Success"
  [purple]="!element.color && element.tint == tints.Special"
  [color]="element.color"
  [border]="element.style == styles.Border"
>
  <ng-container>{{ message }}</ng-container>

  <ng-container actions>
    <app-alert-element-action
      *ngFor="let item of element.actions"
      [item]="item"
      [context]="context"
      [accentColor]="'accentColor' | appThemeColorHexOption | async"
      [analyticsSource]="element.analyticsGenericName"
    ></app-alert-element-action>
  </ng-container>
</app-alert>

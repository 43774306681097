var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
var SectionMenuItem = /** @class */ (function (_super) {
    __extends(SectionMenuItem, _super);
    function SectionMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.Section;
        return _this;
    }
    SectionMenuItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.title = this.params['title'];
        this.icon = this.params['icon'];
        return this;
    };
    SectionMenuItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['title'] = this.title;
        data['params']['icon'] = this.icon;
        return data;
    };
    SectionMenuItem.prototype.copyFrom = function (item) {
        _super.prototype.copyFrom.call(this, item);
        this.title = item.title;
        this.icon = item.icon;
    };
    SectionMenuItem.prototype.clone = function () {
        var instance = new SectionMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return SectionMenuItem;
}(MenuItem));
export { SectionMenuItem };
registerMenuItemForType(MenuItemType.Section, SectionMenuItem);

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="openOnFocus ? horizontalDropdownPositions : verticalDropdownPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="!openOnFocus"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false); close.emit()"
  (detach)="setDropdownOpened(false); close.emit()"
>
  <div
    class="compact-select-options compact-select-options_min-width_sm"
    (mouseenter)="dropdownHover$.next(true)"
    (mouseleave)="dropdownHover$.next(false)"
  >
    <div
      *ngFor="let cursor of cursors"
      class="compact-select-options__item compact-select-option"
      (click)="selected.emit(cursor); setDropdownOpened(false); close.emit()"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="value == cursor"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">{{ cursor | appCapitalize }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { CornerRadius } from '@modules/views';
import { isSet } from '@shared';
var CornerRadiusControl = /** @class */ (function (_super) {
    __extends(CornerRadiusControl, _super);
    function CornerRadiusControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            top_left: new FormControl(isSet(state.topLeft) ? state.topLeft : 0),
            top_right: new FormControl(isSet(state.topRight) ? state.topRight : 0),
            bottom_right: new FormControl(isSet(state.bottomRight) ? state.bottomRight : 0),
            bottom_left: new FormControl(isSet(state.bottomLeft) ? state.bottomLeft : 0)
        }) || this;
        _this.onDisabledChange = function (value) { return undefined; };
        return _this;
    }
    CornerRadiusControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.top_left.patchValue(value.topLeft, options);
        this.controls.top_right.patchValue(value.topRight, options);
        this.controls.bottom_right.patchValue(value.bottomRight, options);
        this.controls.bottom_left.patchValue(value.bottomLeft, options);
    };
    CornerRadiusControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new CornerRadius();
        }
        instance.topLeft = this.controls.top_left.value;
        instance.topRight = this.controls.top_right.value;
        instance.bottomRight = this.controls.bottom_right.value;
        instance.bottomLeft = this.controls.bottom_left.value;
        return instance;
    };
    CornerRadiusControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    CornerRadiusControl.prototype.registerOnChange = function (fn) {
        this.valueChanges.subscribe(function (value) { return fn(value); });
    };
    CornerRadiusControl.prototype.registerOnDisabledChange = function (fn) {
        this.onDisabledChange = fn;
    };
    CornerRadiusControl.prototype.enable = function (opts) {
        _super.prototype.enable.call(this, opts);
        this.onDisabledChange(false);
    };
    CornerRadiusControl.prototype.disable = function (opts) {
        _super.prototype.disable.call(this, opts);
        this.onDisabledChange(true);
    };
    return CornerRadiusControl;
}(FormGroup));
export { CornerRadiusControl };

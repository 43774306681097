import { Widget } from './items/widget';
import { WidgetType } from './widget-type';

const widgets: { type: WidgetType; cls: typeof Widget }[] = [];

export function getWidgetByType(type: WidgetType): typeof Widget {
  const instance = widgets.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerWidgetForType(type: WidgetType, cls: typeof Widget) {
  widgets.push({ type: type, cls: cls });
}

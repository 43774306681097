import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ColorsSharedModule } from '@modules/colors-shared';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { SlackJoinPopupComponent } from './components/slack-join-popup/slack-join-popup.component';

@NgModule({
  imports: [CommonModule, UiModule, FieldsModule, FormsModule, SharedModule, ReactiveFormsModule, ColorsSharedModule],
  declarations: [SlackJoinPopupComponent],
  entryComponents: [SlackJoinPopupComponent]
})
export class SlackComponentsModule {}
